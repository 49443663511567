import React from 'react'
import PropTypes from 'prop-types'
import { Grid, styled, Typography } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import { useTranslation } from 'react-i18next'
import { is } from 'ramda'

import TextOverflow from '../TextOverflow'

const RootGridStyled = styled(Grid, {
  shouldForwardProp: propName => propName !== 'isSticky'
})(({ theme, isSticky }) => ({
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  flexDirection: 'row',
  paddingTop: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'column'
  },
  '& > *': {
    padding: 0,
    alignItems: 'flex-end'
  },
  [theme.breakpoints.down('lg')]: {
    '& > *': {
      padding: theme.spacing(1),
      alignItems: 'flex-end'
    }
  },
  ...(isSticky && {
    paddingTop: 0,
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      alignItems: 'flex-start',
      '& > *': {
        padding: 0
      }
    }
  })
}))

const LeftTitleGridStyled = styled(Grid, {
  shouldForwardProp: propName => propName !== 'isSticky'
})(({ theme, isSticky }) => ({
  ...(isSticky && {
    [theme.breakpoints.down('lg')]: {
      display: 'none'
    }
  })
}))

const RightButtonStyled = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& > *': {
    marginRight: theme.spacing(2) + ' !important'
  },
  '& > *:last-child': {
    marginRight: 0 + ' !important'
  },
  [theme.breakpoints.down('lg')]: {
    width: '100%'
  }
}))

const MAX_LENGTH_TOOLTIP = 60

const getTooltip = pageTitle => {
  if (typeof pageTitle === 'string') {
    return pageTitle.length > MAX_LENGTH_TOOLTIP ? pageTitle : null
  }
  return null
}

function PageTitle (props) {
  const { pageTitle, isSticky = false } = props

  const tooltip = getTooltip(pageTitle)
  const { t } = useTranslation()
  const title = is(String, pageTitle)
    ? t(pageTitle)
    : pageTitle

  return (
    <RootGridStyled
      container={true}
      spacing={2}
      isSticky={isSticky}
    >
      <LeftTitleGridStyled item={true}>
        {!isSticky && (
          <Typography
            component="h2"
            gutterBottom={true}
            variant="overline"
          >
            {t(props.parentTitle)}
          </Typography>
        )}
        <Typography component="div" variant="h3">
          {props.pageTitleLoading
            ? <Skeleton variant="text" width={300} />
            : <TextOverflow lines={1} tooltip={tooltip}>{title}</TextOverflow>}
        </Typography>

      </LeftTitleGridStyled>
      <RightButtonStyled item={true}>
        {props.rightButton}
      </RightButtonStyled>
    </RootGridStyled>
  )
}

PageTitle.propTypes = {
  pageTitle: PropTypes.any,
  pageTitleLoading: PropTypes.bool,
  parentTitle: PropTypes.any,
  rightButton: PropTypes.node,
  isSticky: PropTypes.bool
}

export default PageTitle
