import { pick, prop } from 'ramda'
import { DEFAULT_SEARCH_PARAMS } from 'storfox-route-hooks'

import { escapeAtob, getDateTimeMidnight } from '~/utils'

export function ShipmentFilterSerializer (params) {
  const defaultParams = pick(DEFAULT_SEARCH_PARAMS, params)
  const direction = prop('direction', params)
  const status = prop('status', params)
  const warehouseIds = escapeAtob(prop('warehouses', params))
  const company = escapeAtob(prop('company', params))
  const orderedAtStart = prop('orderedAtStart', params)
  const orderedAtEnd = prop('orderedAtEnd', params)
  const shippedDateStart = prop('shippedDateStart', params)
  const shippedDateEnd = prop('shippedDateEnd', params)
  const deliveredDateStart = prop('deliveredDateStart', params)
  const deliveredDateEnd = prop('deliveredDateEnd', params)
  const createdAtStart = prop('createdAtStart', params)
  const createdAtEnd = prop('createdAtEnd', params)
  const updatedAtStart = prop('updatedAtStart', params)
  const updatedAtEnd = prop('updatedAtEnd', params)
  const clientCompanyId = prop('id', company)

  return {
    ...defaultParams,
    direction,
    status,
    warehouseIds,
    orderedAtStart,
    orderedAtEnd,
    shippedDateStart,
    shippedDateEnd,
    deliveredDateStart,
    deliveredDateEnd,
    createdAtStart,
    createdAtEnd,
    updatedAtStart,
    updatedAtEnd,
    clientCompanyId: clientCompanyId ? clientCompanyId.toString() : null
  }
}

export const ShipmentExportSerializer = values => {
  const fromDatetime = getDateTimeMidnight(prop('fromDatetime', values))
  const toDatetime = getDateTimeMidnight(prop('toDatetime', values))

  return { fromDatetime, toDatetime }
}
