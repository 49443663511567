import React from 'react'
import { Ordering } from 'storfox-filter'

import { DELIVERY_METHOD_COLUMNS as tableColumns } from './DeliveryMethodList'

import { FILTER_NAME as name } from '../../../constants/DeliveryMethod'

export const deliveryMethodOrderingOptions = {
  name,
  tableColumns
}

function DeliveryMethodOrderingForm (props) {
  return (
    <Ordering {...props} />
  )
}

export default DeliveryMethodOrderingForm
