import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import { Filter } from 'storfox-filter'
import { DateRangeField } from 'storfox-form-fields'
import { prop } from 'ramda'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

import { getSeparatedDateRange } from '~/utils'
import Accordion from '~/components/Accordion'
import StatusField from '~/components/Fields/StatusField'

import { FILTER_NAME as name } from '../../constants'

export const toParams = values => {
  const status = prop('status', values)

  const [createdAtStart, createdAtEnd] = getSeparatedDateRange(prop('createdAt', values))
  const [updatedAtStart, updatedAtEnd] = getSeparatedDateRange(prop('updatedAt', values))

  return {
    status,
    createdAtStart,
    createdAtEnd,
    updatedAtStart,
    updatedAtEnd
  }
}

export const toValues = (params) => {
  const status = prop('status', params)
  const createdAt = [prop('createdAtStart', params), prop('createdAtEnd', params)]
  const updatedAt = [prop('updatedAtStart', params), prop('updatedAtEnd', params)]

  return {
    status,
    createdAt,
    updatedAt
  }
}

export const transformer = { toParams, toValues }

export const customerFilterOptions = {
  name,
  transformer
}

function CustomerFilterForm (props) {
  const { t } = useTranslation()

  return (
    <Filter {...props}>
      <Accordion title="General" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <StatusField />
          </Grid>
        </Grid>
      </Accordion>
      <Accordion title="Date" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <Typography variant="body2" gutterBottom={true}>
              {t('Created')}:
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <DateRangeField name="createdAt" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography variant="body2" gutterBottom={true}>
              {t('Updated')}:
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <DateRangeField name="updatedAt" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Accordion>
    </Filter>
  )
}

CustomerFilterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
}

export default CustomerFilterForm
