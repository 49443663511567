import React from 'react'
import PropTypes from 'prop-types'

import TableSplitButton from '~/components/TableSplitButton'

function BulkActionButton ({ onProcess, loading }) {
  const options = [
    {
      label: 'Process',
      'data-cy': 'processMenu',
      onMenuClick: onProcess
    }
  ]

  return (
    <TableSplitButton
      options={options}
      name="packing"
      loading={loading}
    />
  )
}

BulkActionButton.propTypes = {
  onProcess: PropTypes.func.isRequired,
  loading: PropTypes.bool
}

export default BulkActionButton
