import React from 'react'
import PropTypes from 'prop-types'
import { generatePath, Link as RouteLink } from 'react-router-dom'
import Link from '@mui/material/Link'

import * as ROUTES from '~/constants/routes'
import { VARIANT_DETAIL_TABS } from '~/constants/tabs'

const getVariantLink = (id, productId, isMultiVariant) => {
  const path = generatePath(ROUTES.VARIANT_DETAIL_PATH, { id, productId, tab: VARIANT_DETAIL_TABS.GENERAL })
  return `${path}?isMultiVariant=${isMultiVariant}`
}

function VariantLink ({ name, productId, variantId, isMultiVariant }) {
  if (variantId) {
    return (
      <Link component={RouteLink} to={getVariantLink(variantId, productId, isMultiVariant)}>
        {name}
      </Link>
    )
  }

  return (
    <strong>{name}</strong>
  )
}

VariantLink.propTypes = {
  name: PropTypes.string.isRequired,
  productId: PropTypes.string.isRequired,
  variantId: PropTypes.number,
  isMultiVariant: PropTypes.bool
}

export default VariantLink
