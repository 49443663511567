import React from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'

import { DiscardButton, SaveButton } from '~/components/Buttons'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import { withForm } from '~/components/Form'
import * as NAV from '~/constants/nav-titles'
import Divider from '~/components/Divider'
import { INTEGRATION_TABS } from '~/constants/tabs'
import IntegrationHistory from '~/components/IntegrationHistory/IntegrationHistory'
import * as ROUTES from '~/constants/routes'

import RiderIntegrationUpdateTabs from './RiderIntegrationUpdateTabs'
import RiderIntegrationUpdateFormGeneral from './RiderIntegrationUpdateFormGeneral'

import More from '../IntegrationDelete/More'

function RiderIntegrationUpdateForm ({ pageTitle, pageTitleLoading, isLoading, form, onRemove, history }) {
  const { tab } = useParams()
  const { handleSubmit } = form

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            parentTitle={NAV.INTEGRATION}
            pageTitle={pageTitle}
            pageTitleLoading={pageTitleLoading}
            rightButton={
              <>
                <DiscardButton to={ROUTES.INTEGRATION_LIST_PATH} disabled={isLoading} />
                <SaveButton disabled={isLoading} />
                <More onRemove={onRemove} />
              </>
            }
          />
        </Header>
        <Content>
          <RiderIntegrationUpdateTabs />
          <Divider />
          {tab === INTEGRATION_TABS.GENERAL && <RiderIntegrationUpdateFormGeneral />}
          {tab === INTEGRATION_TABS.LOGS && (
            <IntegrationHistory
              history={history}
              onRefetch={history.getListByParams}
            />
          )}
        </Content>
      </Container>
    </form>
  )
}

RiderIntegrationUpdateForm.propTypes = {
  pageTitle: PropTypes.string,
  pageTitleLoading: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  form: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  history: PropTypes.array.isRequired
}

export default withForm(RiderIntegrationUpdateForm)
