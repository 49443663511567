import React from 'react'

import SelectField from '~/components/Fields/SelectField'
import { BANK_TRANSFER, CASH, CREDIT_CARD, PAYMENT_TYPES, TRADE_FINANCE } from '~/constants/paymentTypes'

const PAYMENT_OPTIONS = [
  { value: CASH, name: PAYMENT_TYPES[CASH] },
  { value: CREDIT_CARD, name: PAYMENT_TYPES[CREDIT_CARD] },
  { value: BANK_TRANSFER, name: PAYMENT_TYPES[BANK_TRANSFER] },
  { value: TRADE_FINANCE, name: PAYMENT_TYPES[TRADE_FINANCE] },
]

function PaymentTypeField () {
  return (
    <SelectField
      data-cy="paymentType"
      name="paymentType"
      label="Payment type"
      options={PAYMENT_OPTIONS}
    />
  )
}

export default PaymentTypeField
