import React from 'react'
import PropTypes from 'prop-types'
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material'

import CheckCircleIcon from '~/components/Icons/CheckCircleIcon'

function Instruction ({ instructions, completedTasks }) {
  return (
    <List>
      {instructions.map((task, index) => (
        <ListItem key={task}>
          {index < completedTasks && (
            <ListItemIcon>
              <CheckCircleIcon />
            </ListItemIcon>
          )}
          <ListItemText>
            {task}
          </ListItemText>
        </ListItem>
      ))}
    </List>
  )
}

Instruction.propTypes = {
  instructions: PropTypes.array.isRequired,
  completedTasks: PropTypes.number.isRequired
}

export default Instruction
