import React from 'react'

import SelectField from '~/components/Fields/SelectField'

import { FEFO, FIFO, LIFO, STRATEGY_TYPE_NAMES } from '../../constants'

const options = [
  { value: null, name: 'None' },
  { value: FIFO, name: STRATEGY_TYPE_NAMES[FIFO] },
  { value: FEFO, name: STRATEGY_TYPE_NAMES[FEFO] },
  { value: LIFO, name: STRATEGY_TYPE_NAMES[LIFO] },
]

function FulfillmentStrategyField ({ ...props }) {
  return (
    <SelectField
      data-cy="strategy"
      name="strategy"
      label="Strategy"
      options={options}
      {...props}
    />
  )
}

export default FulfillmentStrategyField
