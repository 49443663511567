import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card/Card'
import { Divider } from '@mui/material'
import CardContent from '@mui/material/CardContent'
import { useTranslation } from 'react-i18next'

import { CardHeader } from '~/components/Cards'
import TextField from '~/components/Fields/TextField'
import StatusField from '~/components/Fields/StatusField'

import ExpressTypeField from './ExpressTypeField'
import OrderTypeField from './OrderTypeField'
import ServiceTypeField from './ServiceTypeField'

function JTExpressIntegrationUpdateFormGeneral () {
  const { t } = useTranslation()

  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12} lg={8}>
        <Box mb={3}>
          <Card>
            <CardHeader title={t('Integration')} />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12} lg={8}>
                  <TextField
                    data-cy="name"
                    name="name"
                    label="Name"
                  />
                </Grid>

                <Grid item={true} xs={12} lg={4}>
                  <StatusField />
                </Grid>

                <Grid item={true} xs={12} lg={4}>
                  <ExpressTypeField />
                </Grid>

                <Grid item={true} xs={12} lg={4}>
                  <OrderTypeField />
                </Grid>

                <Grid item={true} xs={12} lg={4}>
                  <ServiceTypeField />
                </Grid>

                <Grid item={true} xs={12}>
                  <TextField
                    data-cy="description"
                    name="description"
                    label="Description"
                    multiline={true}
                    rows="4"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>
      <Grid item={true} xs={12} lg={4}>
        <Box mb={3}>
          <Card>
            <CardHeader title={t('Keys')} />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} lg={12}>
                  <TextField
                    data-cy="apiAccount"
                    name="credential.apiAccount"
                    label="API Account"
                  />
                </Grid>
                <Grid item={true} lg={12}>
                  <TextField
                    data-cy="customerCode"
                    name="credential.customerCode"
                    label="Customer Code"
                  />
                </Grid>
                <Grid item={true} lg={12}>
                  <TextField
                    data-cy="password"
                    name="credential.password"
                    label="Password"
                    inputProps={{
                      type: 'password',
                      autocomplete: 'new-password'
                    }}
                  />
                </Grid>
                <Grid item={true} lg={12}>
                  <TextField
                    data-cy="privateKey"
                    name="credential.privateKey"
                    label="Private Key"
                    inputProps={{
                      type: 'password',
                      autocomplete: 'new-password'
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </Grid>
  )
}

export default JTExpressIntegrationUpdateFormGeneral
