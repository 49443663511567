import React, { useCallback, useState } from 'react'
import { generatePath } from 'react-router-dom'
import { map, prop } from 'ramda'
import PropTypes from 'prop-types'

import { MoreListButton, NewButton } from '~/components/Buttons'
import Avatar from '~/components/Avatar/Avatar'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import TextOverflow from '~/components/TextOverflow'
import { useBarcodeSelect } from '~/components/BarcodePrintDialog/hooks'
import { Table, TableHeader } from '~/components/Table'
import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'
import TableLink from '~/components/Link'
import { useTableSelects } from '~/components/TableValues/hooks'
import { unescapeBtoa } from '~/utils'

import BulkActionButton from './BulkActionButton'
import ConditionStatus from './ConditionStatus'

const getDetailPath = id => {
  return generatePath(ROUTES.CONDITION_UPDATE_PATH, { id })
}

export const CONDITION_COLUMNS = [
  {
    width: 75,
    headerName: 'Image',
    field: 'image',
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        <Avatar src={value} alt={row.code} />
      </TableLink>
    )
  },
  {
    width: 200,
    headerName: 'Code',
    field: 'code',
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        <TextOverflow selfTooltip={true}>{value}</TextOverflow>
      </TableLink>
    )
  },
  {
    width: 200,
    headerName: 'Grade',
    field: 'grade'
  },
  {
    width: 200,
    headerName: 'Condition',
    field: 'condition'
  },
  {
    width: 150,
    headerName: 'Status',
    field: 'status',
    renderCell: ({ value }) => (
      <ConditionStatus value={value} />
    )
  },
  {
    width: 300,
    headerName: 'Details',
    field: 'details',
    renderCell: ({ value }) => (
      <TextOverflow selfTooltip={true}>
        {value}
      </TextOverflow>
    )
  },
  {
    width: 200,
    headerName: 'Color',
    field: 'color'
  },
  {
    width: 100,
    headerName: 'Warranty',
    field: 'warranty'
  }
]

const getRowsFromResults = map(item => {
  const id = prop('id', item)
  const code = prop('code', item)
  const image = prop('imagePath', item)
  const grade = prop('grade', item)
  const condition = prop('condition', item)
  const status = prop('status', item)
  const details = prop('description', item)
  const color = prop('color', item)
  const warranty = prop('warranty', item)
  const link = getDetailPath(id)

  return {
    id,
    image,
    code,
    grade,
    status,
    condition,
    details,
    color,
    warranty,
    link
  }
})

function ConditionTable ({ list, ordering, onListRefetch }) {
  const [tableRef, setTableRef] = useState(null)
  const { selects, resetTableSelects } = useTableSelects()
  const results = prop('results', list)
  const isLoading = prop('isLoading', list)
  const count = prop('count', list)
  const columns = prop('filteredColumns', ordering)

  const { selectedRows } = useBarcodeSelect(results, 'id', selects)

  const printLocationBarcode = useCallback(() => {
    let printBarcode = []
    for (const condition of selectedRows) {
      const code = prop('code', condition)
      printBarcode.push({ barcode: code })
    }
    const data = unescapeBtoa(printBarcode)
    window.open(`${ROUTES.BARCODE_GENERATOR_PATH}?barcodes=${data}`, '_blank')
    resetTableSelects()
  }, [selectedRows, resetTableSelects])

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={NAV.CONDITION}
          parentTitle={NAV.CONFIGURATION}
          rightButton={(
            <>
              <BulkActionButton printBarcode={printLocationBarcode} />
              <NewButton path={ROUTES.CONDITION_CREATE_PATH} />
            </>
          )}
        />
      </Header>

      <Content>
        <TableHeader
          orderingOpen={ordering.handleOpen}
          ordering={ordering}
        >
          <MoreListButton tableRef={tableRef} />
        </TableHeader>
        <Table
          isLoading={isLoading}
          columns={columns}
          getRowsFromResults={getRowsFromResults}
          results={results}
          count={count}
          checkboxSelection={true}
          pinnedColumns={['image', 'code']}
          ordering={ordering}
          onRefetch={onListRefetch}
          setTableRef={setTableRef}
        />
      </Content>
    </Container>
  )
}

ConditionTable.propTypes = {
  list: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  onListRefetch: PropTypes.object.isRequired
}

export default ConditionTable
