import { sprintf } from 'sprintf-js'
import { useAllSearchParams } from 'storfox-route-hooks'
import {
  useCreate,
  useDetail,
  useLimitedCountList,
  useUpdate,
  useList,
  useScrollList
} from 'storfox-api-hooks'

import { queryToParams } from '~/utils'
import * as API from '~/constants/api'

import { ReturnFilterSerializer } from '../serializers'

export const useReturnList = () => {
  const searchParams = useAllSearchParams()
  const params = queryToParams(ReturnFilterSerializer, searchParams)

  return useLimitedCountList(API.SALE_RETURN_LIST, params)
}

export const useReturnDetail = (guid) => {
  const url = sprintf(API.SALE_RETURN_DETAIL, guid)
  return useDetail(url)
}

export const useReturnCreate = () => {
  return useCreate(API.SALE_RETURN_CREATE)
}

export const useReturnUpdate = (guid) => {
  const url = sprintf(API.SALE_RETURN_UPDATE, guid)
  return useUpdate(url)
}

export const useReturnIntegrationCheck = () => {
  return useCreate(API.RETURN_INTEGRATION_CHECK)
}

export const useReturnCreateDetail = guid => {
  const url = sprintf(API.RETURN_CREATE_DETAIL, guid)
  return useDetail(url)
}

export const useReturnProcess = guid => {
  const url = sprintf(API.SALE_RETURN_PROCESS, guid)
  const { create, ...state } = useCreate(url)

  return { ...state, process: create }
}

export const useReturnCancel = () => {
  const { create, ...state } = useCreate(API.SALE_RETURN_CANCEL)

  return { ...state, cancel: create }
}

export const useReturnHistory = (guid, autoSend) => {
  const url = sprintf(API.SALE_RETURN_HISTORY, guid)
  const searchParams = useAllSearchParams()

  return useList(url, searchParams, autoSend)
}

export const useReturnVariants = params => {
  return useScrollList(API.RETURN_VARIANTS, params)
}
