import React from 'react'
import { useParams } from 'react-router-dom'
import { prop } from 'ramda'

import { DashboardLayout } from '~/components/Layouts'
import Title from '~/components/Title'
import * as NAV from '~/constants/nav-titles'

import { ADHOC_TYPE, FULFILLMENT_TYPE, PACKING_TYPE, RECEIVING_TYPE, STORAGE_TYPE } from '../constants'
import TplBillingItem from '../components/TplBillingItem/TplBillingItem'
import {
  useTplBillingAdhocDetail,
  useTplBillingFulfillmentDetail,
  useTplBillingPackingDetail,
  useTplBillingReceivingDetail,
  useTplBillingStorageDetail
} from '../hooks'

const PAGE_TITLES = {
  [STORAGE_TYPE]: 'Storage bill',
  [FULFILLMENT_TYPE]: 'Fulfillment bill',
  [PACKING_TYPE]: 'Packing bill',
  [ADHOC_TYPE]: 'Adhoc bill'
}

function BillingItemContainer () {
  const { guid, type } = useParams()

  const billingStorageDetail = useTplBillingStorageDetail(guid, type === STORAGE_TYPE)
  const billingFulfillmentDetail = useTplBillingFulfillmentDetail(guid, type === FULFILLMENT_TYPE)
  const billingPackingDetail = useTplBillingPackingDetail(guid, type === PACKING_TYPE)
  const billingAdhocDetail = useTplBillingAdhocDetail(guid, type === ADHOC_TYPE)
  const billingReceivingDetail = useTplBillingReceivingDetail(guid, type === RECEIVING_TYPE)

  const billingDetails = {
    [STORAGE_TYPE]: billingStorageDetail,
    [FULFILLMENT_TYPE]: billingFulfillmentDetail,
    [PACKING_TYPE]: billingPackingDetail,
    [ADHOC_TYPE]: billingAdhocDetail,
    [RECEIVING_TYPE]: billingReceivingDetail
  }

  const billingDetail = billingDetails[type]

  const title = PAGE_TITLES[type]
  const status = prop('status', billingDetail)

  const pageTitle = <Title title={PAGE_TITLES[type]} status={status} />

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      activeNav={NAV.BILLING}
      isLoading={billingStorageDetail.isLoading}
      breadcrumbs={breadcrumbs}
    >
      <TplBillingItem
        detail={billingDetail.detail}
        pageTitleLoading={billingDetail.isLoading}
        pageTitle={pageTitle}
        isLoading={billingDetail.isLoading}
      />
    </DashboardLayout>
  )
}

export default BillingItemContainer
