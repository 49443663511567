import React, { useCallback } from 'react'
import { useFilter, useOrdering } from 'storfox-filter'
import { useSnackbar } from 'storfox-snackbar'
import { prop, propOr } from 'ramda'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import useMessages from '~/hooks/useMessages'

import {
  UnitFilterForm,
  unitFilterOptions,
  UnitList,
  UnitOrderingForm,
  unitOrderingOptions
} from '../components/UnitList'
import { useUnitList, useUnitGenerateBarcode } from '../hooks'

function UnitListContainer () {
  const unitList = useUnitList()
  const messages = useMessages()
  const snackbar = useSnackbar()

  const filter = useFilter(unitFilterOptions)
  const ordering = useOrdering(unitOrderingOptions)

  const unitGenerateBarcode = useUnitGenerateBarcode()

  const breadcrumbs = { title: NAV.UNITS }

  const handlePrintBarcode = useCallback((values, type) => {
    const units = propOr([], 'units', values)
    const printUnits = units.map((unit) => ({ quantity: propOr(0, 'printQuantity', unit), guid: prop('guid', unit) }))
    const printData = { unitBarcodes: printUnits, type }
    unitGenerateBarcode.generate(printData)
      .then(() => snackbar({ message: messages.NOTIFICATION_WAIT }))
  }, [snackbar, messages, unitGenerateBarcode])

  return (
    <DashboardLayout
      title={NAV.UNITS}
      activeNav={NAV.UNITS}
      breadcrumbs={breadcrumbs}
    >

      <UnitFilterForm {...filter} />
      <UnitOrderingForm {...ordering} />

      <UnitList
        list={unitList}
        filter={filter}
        ordering={ordering}
        onListRefetch={unitList.getListByParams}
        handleOpenFilter={filter.handleOpen}
        onPrintBarcode={handlePrintBarcode}
      />
    </DashboardLayout>
  )
}

export default UnitListContainer
