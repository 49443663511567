import React from 'react'
import PropTypes from 'prop-types'

import WarehouseSearchField from '~/components/Fields/WarehouseSearchField'
import * as API from '~/constants/api'

function WarehouseActionSearchField ({ name, ...props }) {
  return (
    <WarehouseSearchField
      data-cy={name}
      primaryKey="guid"
      name={name}
      label="Warehouse"
      api={API.AUTOMATION_RULE_WAREHOUSE_LIST}
      {...props}
    />
  )
}

WarehouseActionSearchField.propTypes = {
  name: PropTypes.string.isRequired
}

export default WarehouseActionSearchField
