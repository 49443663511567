import React from 'react'
import { PasswordField } from 'storfox-form-fields'
import { useTranslation } from 'react-i18next'

function StrongPasswordField (props) {
  const { t } = useTranslation()

  const isStrongPassword = value => {
    const re = /^(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/
    if (value && !re.test(value)) {
      return `Passwords must contain at least 8 characters, including letters and numbers`
    }
  }

  return (
    <PasswordField
      validate={isStrongPassword}
      name="password"
      label={t('Password')}
      size="medium"
      placeholder={t('At least 8 symbols...')}
      {...props}
    />
  )
}

export default StrongPasswordField
