import React from 'react'
import { useField } from 'react-final-form'
import { isEmpty } from 'ramda'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material'
import { useDropzone } from 'react-dropzone'
import LinearProgress from '@mui/material/LinearProgress'

import { useImageUpload } from '~/components/ImageUpload'
import { generateCdnUrl } from '~/utils'

import { useFileAttachment } from './useFileAttachment'

import Button from '../Buttons/Button'

const LinearProgressStyled = styled(LinearProgress)(({
  height: 10,
  width: '100%',
  margin: '14px 8px'
}))

const RootStyled = styled(Box)(({
  height: '38px',
  border: `1px dashed #d3c6c6`,
  borderRadius: 5,
}))

const DownloadLinkStyled = styled(Link)(({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '8px',
}))

const UploadRootStyled = styled(Box)(({
  display: 'flex',
  alignItems: 'center'
}))

const UploadTextStyled = styled(Typography)(({
  textDecoration: 'underline',
  cursor: 'pointer',
  paddingLeft: '8px',
}))

function FileAttachment ({ name, onRemove, onUpload }) {
  const { upload } = useImageUpload()
  const field = useField(name)
  const { value, onChange } = field.input

  const handleUpload = (data, onProgress) => {
    return upload(data, onProgress)
      .then(response => {
        const filePath = generateCdnUrl(response['ids'][0])
        onUpload(filePath)
        onChange(data)
        return data
      })
      .catch(err => {
        setFile({ loading: false })
        return Promise.reject(err)
      })
  }

  const [file, fileUpload, setFile] = useFileAttachment({ onUpload: handleUpload })
  const { loading } = file

  const handleRemove = () => {
    setFile({})
    onChange(null)
    onRemove()
  }

  const {
    getRootProps,
    getInputProps
  } = useDropzone({ onDrop: fileUpload })

  return (
    <RootStyled display="flex" justifyContent="space-between">
      {loading && (
        <LinearProgressStyled
          variant="determinate"
          value={file.progress}
        />
      )}
      {(value && !loading) && (
        <DownloadLinkStyled
          href={value}
          rel="noopener noreferrer"
          underline="always"
          target="_blank"
        >
          <Typography>
            ATTACHED FILE
          </Typography>
        </DownloadLinkStyled>
      )}
      {(!value && !loading) && (
        <UploadRootStyled {...getRootProps()}>
          <input
            {...getInputProps()}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
          <UploadTextStyled>
            ATTACH FILE
          </UploadTextStyled>
        </UploadRootStyled>
      )}
      {!loading && (
        <Button
          onClick={handleRemove}
          disabled={isEmpty(value)}
        >
          Delete
        </Button>
      )}
    </RootStyled>
  )
}

FileAttachment.propTypes = {
  name: PropTypes.string.isRequired,
  onUpload: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired
}

export default FileAttachment
