import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Status, { ERROR, SUCCESS, WARNING } from '../Status/Status'

export const FULLY_ALLOCATED = 'fully_allocated'
export const UNALLOCATED = 'unallocated'
export const ALLOCATION_PENDING = 'allocation_pending'
export const ALLOCATED = 'allocated'
export const PARTIALLY_ALLOCATED = 'partially_allocated'
export const FAILED = 'failed'

export const ALLOCATION_STATUS = {
  FULLY_ALLOCATED,
  UNALLOCATED,
  ALLOCATION_PENDING,
  ALLOCATED,
  PARTIALLY_ALLOCATED,
  FAILED
}

export const ALLOCATION_STATUS_LABEL = {
  [FULLY_ALLOCATED]: 'Fully allocated',
  [UNALLOCATED]: 'Unallocated',
  [ALLOCATION_PENDING]: 'Pending',
  [ALLOCATED]: 'Allocated',
  [PARTIALLY_ALLOCATED]: 'Partially allocated',
  [FAILED]: 'Failed'
}

const STATUS_COLOR = {
  [ALLOCATION_STATUS.UNALLOCATED]: ERROR,
  [ALLOCATION_STATUS.ALLOCATION_PENDING]: WARNING,
  [ALLOCATION_STATUS.PARTIALLY_ALLOCATED]: WARNING,
  [ALLOCATION_STATUS.ALLOCATED]: SUCCESS,
  [ALLOCATION_STATUS.FULLY_ALLOCATED]: SUCCESS,
  [ALLOCATION_STATUS.FAILED]: ERROR,
}

function AllocationStatus ({ value }) {
  const { t } = useTranslation()

  return (
    <Status
      data-cy="status"
      label={t(ALLOCATION_STATUS_LABEL[value])}
      color={STATUS_COLOR[value]}
    />
  )
}

AllocationStatus.propTypes = {
  value: PropTypes.string.isRequired
}

export default AllocationStatus
