import React from 'react'
import Done from '@mui/icons-material/Done'

import { Button } from '~/components/Buttons'

function CompleteButton (props) {
  return (
    <Button
      variant="contained"
      data-cy="complete"
      startIcon={<Done />}
      {...props}>
      Complete
    </Button>
  )
}

export default CompleteButton
