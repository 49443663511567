import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import ShipmentListContainer from './containers/ShipmentListContainer'
import ShipmentUpdateContainer from './containers/ShipmentUpdateContainer'
import ShipmentDetailContainer from './containers/ShipmentDetailContainer'

export default () => [
  {
    layout: AuthLayout,
    path: ROUTES.SHIPMENT_LIST_PATH,
    component: ShipmentListContainer
  },
  {
    layout: AuthLayout,
    path: ROUTES.SHIPMENT_UPDATE_PATH,
    component: ShipmentUpdateContainer
  },
  {
    layout: AuthLayout,
    path: ROUTES.SHIPMENT_DETAIL_PATH,
    component: ShipmentDetailContainer
  }
]
