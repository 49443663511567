import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { map, path, prop, propOr } from 'ramda'
import { generatePath, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import EditIcon from '@mui/icons-material/Edit'

import { DeleteRowsButton, MoreListButton, NewButton } from '~/components/Buttons'
import Avatar from '~/components/Avatar/Avatar'
import PageTitle from '~/components/PageTitle'
import Tags from '~/components/Tags'
import BooleanStatus from '~/components/BooleanStatus'
import TableDateFormat from '~/components/TableDateFormat'
import Container, { Content, Header } from '~/components/Container'
import TextOverflow from '~/components/TextOverflow'
import { Table, TableHeader } from '~/components/Table'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import TableLink from '~/components/Link'

import BulkActionButton from './BulkActionButton'

export const CATEGORY_COLUMNS = [
  {
    width: 75,
    headerName: 'Image',
    field: 'image',
    sortable: false,
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        <Avatar alt={row.name} src={value} />
      </TableLink>
    )
  },
  {
    width: 200,
    headerName: 'Name',
    field: 'name',
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        <TextOverflow lines={1} selfTooltip={true}>
          {value}
        </TextOverflow>
      </TableLink>
    )
  },
  {
    width: 200,
    headerName: 'Position',
    field: 'position'
  },
  {
    width: 200,
    headerName: 'Parent category',
    field: 'parentCategory',
    sortable: false
  },
  {
    width: 150,
    headerName: 'Status',
    field: 'status',
    sortable: false,
    renderCell: ({ value }) => (
      <BooleanStatus value={value} />
    )
  },
  {
    width: 200,
    headerName: 'Last updated',
    field: 'lastUpdated',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  },
  {
    width: 300,
    headerName: 'Tags',
    field: 'tags',
    sortable: false,
    renderCell: ({ value }) => (
      <Tags items={value} />
    )
  }
]

const getRowsFromResults = map(item => {
  const id = prop('id', item)
  const image = prop('imagePath', item)
  const name = prop('name', item)
  const position = prop('position', item)
  const parentCategory = path(['parent', 'name'], item)

  const tags = propOr([], 'tags', item)

  const lastUpdated = prop('updatedAt', item)

  const status = prop('status', item)
  const link = generatePath(ROUTES.CATEGORY_DETAIL_PATH, { id })

  return {
    id,
    image,
    name,
    position,
    parentCategory,
    tags,
    lastUpdated,
    status,
    link
  }
})

const pinnedColumns = ['image', 'name']

function CategoryTable ({ list, filter, ordering, onListRefetch, onCategoriesDelete, bulkActionLoading }) {
  const [tableRef, setTableRef] = useState(null)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const isLoading = prop('isLoading', list)
  const results = prop('results', list)
  const count = prop('count', list)
  const columns = prop('filteredColumns', ordering)

  const handleEdit = useCallback(id => {
    const editPath = generatePath(ROUTES.CATEGORY_UPDATE_PATH, { id })
    navigate(editPath)
  }, [navigate])

  const actionColumns = useMemo(() => [
    ...columns,
    {
      width: 50,
      resizable: false,
      field: 'actions',
      type: 'actions',
      getActions: ({ row }) => [
        <GridActionsCellItem
          label="Edit"
          onClick={() => handleEdit(row.id)}
          showInMenu={true}
          icon={<EditIcon />}
        />
      ]
    }
  ], [columns, handleEdit])

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={t(NAV.CATEGORIES)}
          parentTitle={t(NAV.CATALOG)}
          rightButton={(
            <>
              <BulkActionButton onCategoriesDelete={onCategoriesDelete} loading={bulkActionLoading} />
              <NewButton path={ROUTES.CATEGORY_CREATE_PATH} />
            </>
          )}
        />
      </Header>

      <Content>
        <TableHeader
          filter={filter}
          filterOpen={filter.handleOpen}
          orderingOpen={ordering.handleOpen}
          ordering={ordering}
        >
          <MoreListButton tableRef={tableRef} />
        </TableHeader>
        <Table
          isLoading={isLoading}
          columns={actionColumns}
          getRowsFromResults={getRowsFromResults}
          results={results}
          count={count}
          checkboxSelection={true}
          pinnedColumns={pinnedColumns}
          ordering={ordering}
          onRefetch={onListRefetch}
          selectComponent={<DeleteRowsButton onDelete={onCategoriesDelete} />}
          setTableRef={setTableRef}
        />
      </Content>
    </Container>
  )
}

CategoryTable.propTypes = {
  list: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  onCategoriesDelete: PropTypes.func.isRequired,
  onListRefetch: PropTypes.func.isRequired,
  bulkActionLoading: PropTypes.bool
}

export default CategoryTable
