import React from 'react'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'
import { useFilter, useOrdering } from 'storfox-filter'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import ErrorLink from '~/components/ErrorLink'
import useMessages from '~/hooks/useMessages'
import { useTableSelects } from '~/components/TableValues/hooks'

import {
  AutomationOrderingForm,
  automationOrderingOptions,
  AutomationRuleFilterForm,
  automationRuleFilterOptions,
  AutomationRuleTable
} from '../../components/AutomationRule/AutomationRuleTable'
import { useAutomationRuleList, useAutomationRulesDelete } from '../../hooks/AutomationRule'

function AutomationRuleListContainer () {
  const snackbar = useSnackbar()
  const messages = useMessages()
  const automationRuleList = useAutomationRuleList()
  const automationRulesDelete = useAutomationRulesDelete()
  const { selects, resetTableSelects } = useTableSelects()

  const filter = useFilter(automationRuleFilterOptions)
  const ordering = useOrdering(automationOrderingOptions)

  const title = 'Automation rules'

  const handleAutomationRulesDelete = () =>
    automationRulesDelete.delete({ guids: selects })
      .then(() => snackbar({ message: messages.DELETE_SUCCESS }))
      .then(() => resetTableSelects())
      .then(() => automationRuleList.getList())
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      activeNav={NAV.CONFIGURATION}
      breadcrumbs={breadcrumbs}
    >
      <AutomationOrderingForm {...ordering} />
      <AutomationRuleFilterForm {...filter} />

      <AutomationRuleTable
        pageTitle={title}
        ordering={ordering}
        automationRuleList={automationRuleList}
        onListRefetch={automationRuleList.getListByParams}
        onAutomationRulesDelete={handleAutomationRulesDelete}
        bulkActionLoading={automationRulesDelete.isLoading}
      />
    </DashboardLayout>
  )
}

export default AutomationRuleListContainer
