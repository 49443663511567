import { useAllSearchParams } from 'storfox-route-hooks'
import { useLimitedCountList } from 'storfox-api-hooks'

import * as API from '~/constants/api'
import { queryToParams } from '~/utils'

import { shipmentFilterSerializer } from '../serializers'

export const useShipmentList = () => {
  const searchParams = useAllSearchParams()
  const params = queryToParams(shipmentFilterSerializer, searchParams)

  return useLimitedCountList(API.CROSS_SHIPMENT_LIST, params)
}
