import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { map, pathOr, prop } from 'ramda'
import { generatePath } from 'react-router-dom'

import PageTitle from '~/components/PageTitle'
import TableDateFormat from '~/components/TableDateFormat'
import Money from '~/components/Money'
import Container, { Content, Header } from '~/components/Container'
import { FulfillmentProgress, SaleOrderStatus, ShipmentStatus } from '~/components/Statuses'
import { Table, TableHeader } from '~/components/Table'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import { MoreListButton } from '~/components/Buttons'
import TextOverflow from '~/components/TextOverflow'
import TableLink from '~/components/Link'

import BulkActionButton from './BulkActionButton'

export const INVOICE_COLUMNS = [
  {
    width: 125,
    headerName: 'Invoice #',
    field: 'number',
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        {value}
      </TableLink>
    )
  },
  {
    width: 125,
    headerName: 'SO #',
    field: 'orderNumber'
  },
  {
    width: 125,
    headerName: 'Reference #',
    field: 'referenceNumber',
    renderCell: ({ value, row }) => (
      <TextOverflow
        tooltip={`Reference number from ${row.channelName}`}
        lines={1}
      >
        {value}
      </TextOverflow>
    )
  },
  {
    width: 175,
    headerName: 'Total',
    field: 'totalPrice',
    renderCell: ({ value }) => (
      <Money value={value} />
    )
  },
  {
    width: 125,
    headerName: 'Status',
    field: 'status',
    renderCell: ({ value }) => (
      <SaleOrderStatus value={value} />
    )
  },
  {
    width: 150,
    headerName: 'Shipment status',
    field: 'shipmentStatus',
    renderCell: ({ value }) => (
      <ShipmentStatus value={value} />
    )
  },
  {
    width: 300,
    headerName: 'Fulfillment progress',
    field: 'progress',
    renderCell: ({ value }) => (
      <FulfillmentProgress status={value} />
    )
  },
  {
    width: 150,
    headerName: 'Created date',
    field: 'createdAt',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  },
  {
    width: 150,
    headerName: 'Last updated',
    field: 'updatedAt',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  }
]

const getRowsFromResults = map(item => {
  const guid = prop('guid', item)
  const number = prop('number', item)
  const order = prop('order', item)
  const orderNumber = prop('number', order)
  const orderGuid = prop('guid', order)
  const channelName = pathOr('channel', ['channel', 'name'], order)
  const referenceNumber = prop('referenceNumber', order)

  const totalPrice = prop('totalPrice', order)
  const progress = prop('fulfillmentStatus', order)
  const status = prop('status', order)
  const shipmentStatus = prop('shipmentStatus', order)
  const createdAt = prop('createdAt', item)
  const updatedAt = prop('updatedAt', item)
  const link = generatePath(ROUTES.INVOICE_DETAIL_PATH, { guid })

  return {
    guid,
    number,
    channelName,
    referenceNumber,
    orderNumber,
    orderGuid,
    totalPrice,
    status,
    shipmentStatus,
    progress,
    createdAt,
    updatedAt,
    link
  }
})

function InvoiceTable ({ list, filter, ordering, onListRefetch, onInvoicesDelete, bulkActionLoading }) {
  const [tableRef, setTableRef] = useState(null)
  const isLoading = prop('isLoading', list)
  const results = prop('results', list)
  const count = prop('count', list)
  const columns = prop('filteredColumns', ordering)

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={NAV.INVOICES}
          parentTitle={NAV.SALES}
          rightButton={(
            <BulkActionButton onInvoicesDelete={onInvoicesDelete} loading={bulkActionLoading} />
          )}
        />
      </Header>

      <Content>
        <TableHeader
          filter={filter}
          filterOpen={filter.handleOpen}
          orderingOpen={ordering.handleOpen}
          ordering={ordering}
        >
          <MoreListButton tableRef={tableRef} />
        </TableHeader>
        <Table
          isLoading={isLoading}
          columns={columns}
          getRowsFromResults={getRowsFromResults}
          results={results}
          pinnedColumns={['number']}
          primaryKey="guid"
          count={count}
          checkboxSelection={true}
          ordering={ordering}
          onRefetch={onListRefetch}
          setTableRef={setTableRef}
        />
      </Content>
    </Container>
  )
}

InvoiceTable.propTypes = {
  list: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  onListRefetch: PropTypes.func.isRequired,
  onInvoicesDelete: PropTypes.func,
  bulkActionLoading: PropTypes.bool
}

export default InvoiceTable
