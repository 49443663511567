import { pathOr } from 'ramda'
import React from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'

import { useBundleDetail, useBundleUpdate } from '../hooks'
import { BundleDetail } from '../components/BundleDetail'

function BundleDetailContainer () {
  const { t } = useTranslation()
  const { guid } = useParams()
  const bundleDetail = useBundleDetail(guid)
  const bundleUpdate = useBundleUpdate(guid)

  const title = pathOr('Bundles', ['detail', 'name'], bundleDetail)

  const pageLoading = bundleDetail.isLoading || bundleUpdate.isLoading

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      isLoading={pageLoading}
      activeNav={t(NAV.BUNDLES)}
      breadcrumbs={breadcrumbs}
    >
      <BundleDetail
        title={title}
        isLoading={bundleUpdate.isLoading}
        bundle={bundleDetail.detail}
      />
    </DashboardLayout>
  )
}

export default BundleDetailContainer
