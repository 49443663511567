import { isEmpty, isNil, path, pipe, prop, reduce, toPairs } from 'ramda'
import * as d3 from 'd3'

import { NODE_WIDTH } from './constants'

const NOT_PROVIDED = 'Not provided'

export const initializeReservedFor = nodeGroup => {
  const offsetX = 20
  const offsetY = 20

  const reservedForGroup = nodeGroup
    .append('g')
    .attr('class', 'reservedForGroup')
    .attr('transform', d => {
      const x = d.position.x + offsetX
      const y = d.position.y + offsetY

      return `translate(${x}, ${y})`
    })

  const labelWrapper = addTextLabel(reservedForGroup, 'Reserved for')

  textWithBackground(
    labelWrapper,
    d => {
      return getTextOrEmpty(prop('destination', d))
    },
    () => ({
      backgroundAttrs: {
        fill: '#2545B8',
        opacity: 0.15
      },
      textAttrs: {
        fill: '#2545B8',
      }
    }))
    .attr('transform', 'translate(0, 12)')

  return reservedForGroup
}

export const initializeQuantity = nodeGroup => {
  const quantityWidth = NODE_WIDTH / 3
  const offsetX = NODE_WIDTH - quantityWidth
  const offsetY = 20

  const quantityGroup = nodeGroup
    .append('g')
    .attr('class', 'quantityGroup')
    .attr('transform', d => {
      const x = d.position.x + offsetX
      const y = d.position.y + offsetY

      return `translate(${x}, ${y})`
    })

  const labelWrapper = addTextLabel(quantityGroup, 'Result Qty')

  labelWrapper
    .append('text')
    .text(d => {
      return getTextOrEmpty(prop('newQuantity', d))
    })
    .attr('transform', `translate(0, 24)`)
    .attr('dy', '.35em')

  return quantityGroup
}

export const initializeLocations = nodeGroup => {
  const offsetX = 20
  const offsetY = 80

  const locationGroup = nodeGroup
    .append('g')
    .attr('class', 'locationGroup')
    .attr('transform', d => {
      const x = d.position.x + offsetX
      const y = d.position.y + offsetY

      return `translate(${x}, ${y})`
    })

  const labelWrapper = addTextLabel(locationGroup, 'Location')

  labelWrapper
    .append('text')
    .text(d => {
      return getTextOrEmpty(path(['location', 'locationId'], d))
    })
    .attr('transform', `translate(0, 24)`)
    .attr('dy', '.35em')

  return locationGroup
}

export const initializeDifference = nodeGroup => {
  const differenceWidth = NODE_WIDTH / 3
  const offsetX = NODE_WIDTH - differenceWidth
  const offsetY = 80

  const differenceGroup = nodeGroup
    .append('g')
    .attr('class', 'difference')
    .attr('transform', d => {
      const x = d.position.x + offsetX
      const y = d.position.y + offsetY

      return `translate(${x}, ${y})`
    })

  const labelWrapper = addTextLabel(differenceGroup, 'Difference')

  textWithBackground(
    labelWrapper,
    d => {
      return prop('newQuantity', d) - prop('oldQuantity', d)
    },
    d => {
      const increase = prop('newQuantity', d) > prop('oldQuantity', d)
      return {
        backgroundAttrs: {
          fill: increase ? '#2FC948' : 'red',
          opacity: 0.15,
        },
        textAttrs: {
          fill: increase ? '#2FC948' : 'red'
        }
      }
    })
    .attr('transform', 'translate(0, 12)')

  return differenceGroup
}

export const initializeContainer = nodeGroup => {
  const offsetX = 20
  const offsetY = 135

  const containerGroup = nodeGroup
    .append('g')
    .attr('class', 'container')
    .attr('transform', d => {
      const x = d.position.x + offsetX
      const y = d.position.y + offsetY

      return `translate(${x}, ${y})`
    })

  const labelWrapper = addTextLabel(containerGroup, 'Container')

  labelWrapper
    .append('text')
    .text(d => {
      return getTextOrEmpty(path(['container', 'number'], d))
    })
    .attr('transform', `translate(0, 24)`)
    .attr('dy', '.35em')

  return containerGroup
}

export const addTextLabel = (group, label) => {
  const labelWrapper = group
    .append('g')
    .attr('class', 'text-group')

  group
    .append('text')
    .text(label)
    .attr('dy', '.5em')
    .attr('fill', '#B0B3C2')

  return labelWrapper
}

export const textWithBackground = (element, textCallback, optionsCallback) => {
  const backgroundWidth = 40
  const backgroundHeight = 10

  const DEFAULT_TEXT_ATTRIBUTES = {
    fill: 'green'
  }

  const DEFAULT_BACKGROUND_ATTRIBUTES = {
    fill: 'green',
    opacity: '0.5'
  }

  const textGroup = element
    .append('g')
    .attr('class', 'text-group')

  const textElement = textGroup
    .append('text')
    .text(textCallback)
    .attr('dy', '.5em')
    .attr('font-size', '12px')

  const widths = []
  const heights = []

  textGroup
    .each(function () {
      widths.push(this.getBBox().width)
      heights.push(this.getBBox().height)
    })

  textElement
    .each(function (d) {
      const currentElement = this
      const options = optionsCallback(d)
      const attributes = options.textAttrs
        ? { ...DEFAULT_TEXT_ATTRIBUTES, ...options.textAttrs }
        : DEFAULT_TEXT_ATTRIBUTES

      pipe(
        toPairs,
        reduce((acc, [key, value]) =>
          acc.attr(key, value)
        , d3.select(currentElement))
      )(attributes)
    })
    .attr(
      'transform',
      `translate(${backgroundWidth / 2}, ${backgroundHeight})`
    )

  const background = textGroup
    .append('rect')
    .attr('rx', 5)
    .attr('ry', 5)
    .each(function (d, index) {
      d3
        .select(this)
        .attr('width', widths[index] + backgroundWidth)
        .attr('height', heights[index] + backgroundHeight)
    })

  background
    .each(function (d) {
      const currentElement = this
      const options = optionsCallback(d)
      const attributes = options.backgroundAttrs
        ? { ...DEFAULT_BACKGROUND_ATTRIBUTES, ...options.backgroundAttrs }
        : DEFAULT_BACKGROUND_ATTRIBUTES

      pipe(
        toPairs,
        reduce((acc, [key, value]) =>
          acc.attr(key, value)
        , d3.select(currentElement))
      )(attributes)
    })

  return textGroup
}

const getTextOrEmpty = text => {
  if (isEmpty(text) || isNil(text)) {
    return NOT_PROVIDED
  }

  return text
}

export const defineFilters = svg => {
  const defs = svg
    .append('defs')

  const filter = defs
    .append('filter')
    .attr('id', 'drop-shadow')
    .attr('height', '130%')

  filter.append('feGaussianBlur')
    .attr('in', 'SourceAlpha')
    .attr('stdDeviation', 5)
    .attr('result', 'blur')

  filter.append('feOffset')
    .attr('in', 'blur')
    .attr('dx', 5)
    .attr('dy', 5)
    .attr('result', 'offsetBlur')

  const feComponentTransfer = filter.append('feComponentTransfer')

  feComponentTransfer
    .append('feFuncA')
    .attr('type', 'linear')
    .attr('slope', '0.2')

  const feMerge = filter.append('feMerge')

  feMerge.append('feMergeNode')
  feMerge.append('feMergeNode')
    .attr('in', 'SourceGraphic')
}

export const defineArrowDefs = svg => {
  const arrowWidth = 5
  const arrowHeight = 5

  const defs = svg
    .append('defs')

  const marker = defs
    .append('marker')
    .attr('id', 'arrowhead')
    .attr('markerWidth', arrowWidth)
    .attr('markerHeight', arrowHeight)
    .attr('refX', arrowWidth)
    .attr('refY', arrowHeight / 2)
    .attr('orient', 'auto')

  marker
    .append('polygon')
    .attr('points', `0,0 ${arrowWidth},${arrowHeight / 2} 0,${arrowHeight}`)
    .attr('class', 'arrow')
}
