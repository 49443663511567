import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { isEmpty, map, pipe, propOr, replace, toLower, toPairs } from 'ramda'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'
import { useUpload } from 'storfox-api-hooks'

import { capitalize } from '~/utils'

import ErrorField from './ErrorField'
import FileUpload from './FileUpload'

import CardHeader from '../Cards/CardHeader'

const getColumn = pipe(
  replace(/(([A-Z])|\(.*\))/g, ' $1'),
  toLower,
  capitalize
)

const getRow = pipe(
  item => item.substr(4),
  number => `Line ${number}`
)

const getFormattedValue = map(item => {
  const [key, value] = toPairs(item)[0]
  const column = getColumn(key)

  return { column, value }
})

const getErrors = (error, errorKey) => pipe(
  propOr([], errorKey),
  map(item => {
    const [key, value] = toPairs(item)[0]
    const row = getRow(key)
    const formattedValue = getFormattedValue(value)
    return { row, values: formattedValue }
  })
)(error)

function SingleFileUpload ({ name, api, errorKey, Component }) {
  const { upload } = useUpload(api)
  const [errors, setErrors] = useState([])
  const snackbar = useSnackbar()
  const message = 'Failed to upload'

  const onUpload = (data, onProgress) => {
    setErrors([])
    return upload(data, onProgress)
      .then(response => {
        return response['result']
      })
      .catch(error => {
        setErrors(getErrors(error, errorKey))
        snackbar({ message, type: ALTER_ERROR })

        return Promise.reject(error)
      })
  }

  return (
    <>
      <Box mb={2}>
        <Card>
          <CardHeader title="Upload" />
          <Divider />
          <CardContent>
            <FileUpload
              name={name}
              Component={Component}
              onUpload={onUpload}
            />
          </CardContent>
        </Card>
      </Box>
      {!isEmpty(errors) && (
        <Box>
          <Card>
            <CardHeader title="Rows with unresolved format issues" />
            <Divider />
            <ErrorField errors={errors} />
          </Card>
        </Box>
      )}
    </>
  )
}

SingleFileUpload.defaultProps = {
  name: 'file',
  errorKey: 'products'
}

SingleFileUpload.propTypes = {
  Component: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  api: PropTypes.string.isRequired,
  errorKey: PropTypes.string.isRequired
}

export default SingleFileUpload
