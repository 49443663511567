import React, { useCallback } from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import { useField } from 'react-final-form'

import { CardHeader } from '~/components/Cards'
import useDidUpdateEffect from '~/hooks/useDidUpdateEffect'
import PositiveNumberField from '~/components/Fields/PositiveNumberField'

import PackingChargeTypeField from './PackingChargeTypeField'
import ContainerTypePricing from './ContainerTypePricing'

import EmptyTypeCard from '../EmptyTypeCard'
import { PACKING_TYPES } from '../../../constants/BillingProfile'

function BillingProfilePacking () {
  const packingBillingField = useField('packingBilling')
  const typeField = useField('packingBilling.type')

  const type = typeField.input.value
  const typeFieldDirty = typeField.meta.dirty

  const handlePackingBillingChange = useCallback(packingBillingField.input.onChange, [])

  useDidUpdateEffect(() => {
    if (typeFieldDirty) {
      handlePackingBillingChange({ type })
    }
  }, [handlePackingBillingChange, type, typeFieldDirty])

  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12} lg={3}>
        <Card>
          <CardHeader title="Settings" />
          <Divider />
          <CardContent>
            <Grid container={true} spacing={3}>
              <Grid item={true} lg={12}>
                <PackingChargeTypeField name="packingBilling.type" />
              </Grid>
              <Grid item={true} lg={12}>
                <PositiveNumberField
                  name="packingBillTax"
                  data-cy="packingBillTax"
                  label="Tax"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item={true} xs={12} lg={9}>
        <Card>
          <CardHeader title="Packing" />
          <Divider />
          <CardContent>
            {!type && <EmptyTypeCard />}
            {type === PACKING_TYPES.PER_CONTAINER_TYPE && <ContainerTypePricing />}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default BillingProfilePacking
