import React from 'react'

import StorfoxContainerStatusField, { OPTIONS as BASE_OPTIONS } from '~/components/Fields/ContainerStatusField'

const OPTIONS = [{ value: '', name: 'All' }, ...BASE_OPTIONS]

function ContainerStatusField (props) {
  return (
    <StorfoxContainerStatusField
      name="status"
      label="Status"
      options={OPTIONS}
      {...props}
    />
  )
}

export default ContainerStatusField
