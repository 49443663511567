import React, { useCallback } from 'react'
import { useField } from 'react-final-form'
import { defaultTo, path, prop, pathEq } from 'ramda'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import { FieldArray } from 'react-final-form-arrays'
import { useValueChanged } from 'storfox-tools'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import AddIcon from '@mui/icons-material/Add'

import TextField from '~/components/Fields/TextField'
import { useProfile } from '~/components/Profile'
import SkeletonList from '~/components/Skeleton'
import EmptyLineItems from '~/components/EmptyLineItems/EmptyLineItems'
import { Button } from '~/components/Buttons'
import useDialog from '~/hooks/useDialog'
import { emptyArray } from '~/constants/empty'

import LineItem from './LineItem'

import PurchaseVariantAddDialog from '../PurchaseVariantAddDialog/PurchaseVariantAddDialog'

function LineItems ({ isLoading, purchaseOrderVariant }) {
  const { t } = useTranslation()
  const { profile } = useProfile()
  const { handleClose, handleOpen, open } = useDialog()
  const defaultTax = path(['salesOrderTax', 'value'], profile)

  const supplierField = useField('supplier')
  const supplierFieldValue = supplierField.input.value
  const supplierGuid = path(['input', 'value', 'guid'], supplierField)

  const companyField = useField('company')
  const companyFieldValue = companyField.input.value
  const companyId = prop('id', companyFieldValue)

  const lineItemsField = useField('lineItems')
  const lineItemsChange = lineItemsField.input.onChange
  const lineItems = lineItemsField.input.value || emptyArray

  const searchField = useField('search')
  const searchFieldChange = searchField.input.onChange

  useValueChanged(() => {
    return lineItemsChange([])
  }, supplierGuid)

  useValueChanged(() => {
    return lineItemsChange([])
  }, companyId)

  const handleOnSearch = useCallback(value => {
    searchFieldChange(value)
    handleOpen()
  }, [handleOpen, searchFieldChange])

  const handleCloseSearch = useCallback(() => {
    searchFieldChange('')
    handleClose()
  }, [handleClose, searchFieldChange])

  const handleVariantsAdd = variants => {
    const newVariants = variants.map(variant => {
      const variantGuid = path(['productVariant', 'guid'], variant)
      const isDuplicate = lineItems.find(item => path(['productVariant', 'guid'], item) === variantGuid)
      if (!isDuplicate && variantGuid) {
        const price = prop('supplyPrice', variant)
        const supplierSku = prop('supplierSku', variant)
        const defaultImage = prop('defaultImage', variant)
        const quantity = 1
        const tax = defaultTax
        const discount = prop('discount', variant)
        const productVariant = { ...variant, defaultImage }
        return {
          productVariant,
          quantity,
          tax,
          discount,
          price,
          supplierSku
        }
      } else return null
    }).filter(value => Boolean(value))

    const otherLineItems = lineItems.filter((lineItem) => {
      return variants.find(pathEq(['productVariant', 'guid'], path(['productVariant', 'guid'], lineItem)))
    })

    lineItemsChange([...otherLineItems, ...newVariants])
    handleClose()
  }

  return (
    <FieldArray name="lineItems">
      {({ fields }) => {
        const values = defaultTo([], fields.value)

        const renderLineItems = Boolean(!isLoading && values.length)
        const renderEmptyLineItems = Boolean(!isLoading && !values.length)

        return <>
          <Box sx={{ padding: '16px' }}>
            <Grid container={true} spacing={3}>
              <Grid item={true} lg={10} xs={12}>
                <TextField
                  data-cy="variant"
                  name="variant"
                  label="Search"
                  onChange={event => supplierFieldValue && handleOnSearch(event.target.value)}
                  disabled={!supplierFieldValue}
                />
              </Grid>
              <Grid item={true} lg={2} xs={12}>
                <Button
                  fullWidth={true}
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={handleOpen}
                  type="button"
                  data-cy="browse"
                  disabled={!supplierFieldValue}
                >
                  Browse
                </Button>
              </Grid>
            </Grid>
            {open && (
              <PurchaseVariantAddDialog
                onClose={handleCloseSearch}
                open={open}
                onAddVariants={handleVariantsAdd}
                params={{ companyId, supplierGuid }}
                purchaseOrderVariant={purchaseOrderVariant}
              />
            )}
          </Box>
          <TableContainer>
            <Table size="small" sx={{ minWidth: 900 }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: 80 }}>{t('Image')}</TableCell>
                  <TableCell sx={{ minWidth: 210 }}>{t('Product')}</TableCell>
                  <TableCell sx={{ minWidth: 175 }}>{t(`Supplier SKU`)}</TableCell>
                  <TableCell sx={{ minWidth: 175 }}>{t('Order Qty')}</TableCell>
                  <TableCell sx={{ minWidth: 175 }}>{t(`Unit cost`)}</TableCell>
                  <TableCell>{t('Total')}</TableCell>
                  <TableCell colSpan={2} />
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading && <SkeletonList columnCount={7} />}
                {renderEmptyLineItems && <EmptyLineItems colSpan={7} />}
                {renderLineItems && fields.map((name, index) => (
                  <LineItem key={name} fields={fields} name={name} index={index} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      }}
    </FieldArray>
  )
}

LineItems.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  purchaseOrderVariant: PropTypes.object.isRequired
}

export default LineItems
