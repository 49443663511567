import React from 'react'
import { isEmpty } from 'ramda'
import isEqual from 'react-fast-compare'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import MUITextField from '@mui/material/TextField'

const REGEX = /^[0-9]*$/g

const inputToNumber = value => {
  if (isEmpty(value)) {
    return undefined
  }

  if (value && value.match(REGEX)) {
    return Number(value)
  }
}

const arrowPress = (key, value) => {
  const num = Number(value)
  if (value && key === 'ArrowUp') {
    return num + 1
  }

  if (value && key === 'ArrowDown') {
    return num > 0 ? num - 1 : 0
  }
}

function PositiveNumberField ({ name, ...defaultProps }) {
  return (
    <Field name={name}>
      {({ input, meta }) => (
        <MUITextField
          onChange={event => input.onChange(inputToNumber(event.target.value))}
          onKeyDown={event => {
            if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
              event.preventDefault()
              input.onChange(arrowPress(event.key, event.target.value))
            }
          }}
          value={String(input.value)}
          error={meta.invalid}
          helperText={meta.submitError || meta.error}
          {...defaultProps}
        />
      )}
    </Field>
  )
}

PositiveNumberField.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
}

PositiveNumberField.defaultProps = {
  size: 'small',
  fullWidth: true,
  variant: 'outlined'
}

export default React.memo(PositiveNumberField, isEqual)
