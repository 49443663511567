import React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { path, prop } from 'ramda'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'
import { useDeepCompareEffect } from 'storfox-api-hooks'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import useMessages from '~/hooks/useMessages'
import * as ROUTES from '~/constants/routes'
import { SALE_ORDER_DETAIL_TABS, SHIPMENT_DETAIL_TABS } from '~/constants/tabs'
import { useNotification } from '~/components/Notification'
import { SHIPMENT_LABEL_CREATE_STATUS } from '~/constants/notification-topics'

import { ShipmentUpdateForm } from '../components/ShipmentUpdate'
import { useShipmentDetail, useShipmentReportGenerate, useShipmentSaveProcess, useShipmentUpdate } from '../hooks'
import { ShipmentInitSerializer, ShipmentUpdateSerializer } from '../serializers'

function ShipmentUpdateContainer () {
  const { guid } = useParams()
  const snackbar = useSnackbar()
  const messages = useMessages()
  const navigate = useNavigate()

  const shipmentDetail = useShipmentDetail(guid)
  const shipmentUpdate = useShipmentUpdate(guid)

  const id = prop('id', shipmentDetail.detail)

  const shipmentSaveProcess = useShipmentSaveProcess(id)
  const shipmentReportGenerate = useShipmentReportGenerate(id)
  const updateNotification = useNotification(SHIPMENT_LABEL_CREATE_STATUS)

  const detailPath = generatePath(ROUTES.SHIPMENT_DETAIL_PATH, { guid, tab: SHIPMENT_DETAIL_TABS.GENERAL })

  useDeepCompareEffect(() => {
    updateNotification.subscribe(guid, payload => {
      updateNotification.setLoading(false)
      const success = path(['payload', 'success'], payload)
      if (success) {
        snackbar({ message: messages.UPDATE_SUCCESS })
        navigate(detailPath)
      } else {
        const message = path(['payload', 'message'], payload)
        snackbar({ message, type: ALTER_ERROR })
      }
    })
  }, [])

  const handleSubmit = formValues =>
    shipmentUpdate.update(ShipmentUpdateSerializer(formValues))
      .then(() => {
        snackbar({ message: messages.NOTIFICATION_WAIT })
        updateNotification.setLoading(true)
      })

  const title = prop('shipmentNumber', shipmentDetail.detail)
  const pageLoading = shipmentDetail.isLoading || shipmentUpdate.isLoading

  const isLoading = (
    shipmentUpdate.isLoading ||
    shipmentDetail.isLoading ||
    shipmentSaveProcess.isLoading ||
    shipmentReportGenerate.isLoading
  )

  const breadcrumbs = {
    title: 'Edit',
    params: [
      { guid, tab: SALE_ORDER_DETAIL_TABS.GENERAL, title }
    ]
  }

  return (
    <DashboardLayout
      title={title}
      isLoading={pageLoading}
      activeNav={NAV.SHIPMENTS}
      breadcrumbs={breadcrumbs}
    >
      <ShipmentUpdateForm
        title={title}
        pageTitleLoading={shipmentDetail.isLoading}
        initialValues={ShipmentInitSerializer(shipmentDetail.detail)}
        isLoading={isLoading}
        onSubmit={handleSubmit}
      />
    </DashboardLayout>
  )
}

export default ShipmentUpdateContainer
