import { skipUndefinedValues } from '~/utils'

import useSearchParams from './useSearchParams'

export const DEFAULT_SEARCH_PARAMS = ['search', 'page', 'limit', 'ordering', 'ids', 'guids']

function usePickSearchParams (keys = DEFAULT_SEARCH_PARAMS) {
  const searchParams = useSearchParams()

  const params = keys.reduce((target, key) => {
    const value = searchParams.get(key)
    return Object.assign(target, { [key]: value })
  }, {})
  return skipUndefinedValues(params)
}

export default usePickSearchParams
