import React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { length, path, prop } from 'ramda'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import Title from '~/components/Title'
import { getNonFieldError } from '~/components/Form'
import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'
import { RECEIVE_TABS } from '~/constants/tabs'
import useMessages from '~/hooks/useMessages'

import ReceivePurchaseOrderDetail from '../components/ReceivePurchaseOrder/ReceivePurchaseOrderDetail'
import { ReceivePurchaseDetailSerializer } from '../serializers/purchaseSerializer'
import {
  useReceiveConditionList,
  useReceiveHistory,
  useReceivePurchaseComplete,
  useReceivePurchaseDetail,
  useReceivePurchaseDetailVariant,
  useReceivePurchaseReceive
} from '../hooks'

function ReceivePurchaseOrderContainer () {
  const messages = useMessages()
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const { guid, tab } = useParams()
  const receiveConditionList = useReceiveConditionList()
  const receivingDetail = useReceivePurchaseDetail(guid)
  const variantDetail = useReceivePurchaseDetailVariant(guid)
  const receivePurchaseComplete = useReceivePurchaseComplete(guid)
  const receivingUpdate = useReceivePurchaseReceive(guid)
  const receiveHistory = useReceiveHistory(guid, tab === RECEIVE_TABS.HISTORY)

  const handleReceive = () => {
    const path = generatePath(ROUTES.RECEIVE_PO_SCAN_DETAIL_PATH, { guid })
    navigate(path)
  }

  const handleComplete = () =>
    receivePurchaseComplete.complete()
      .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
      .then(() => navigate(ROUTES.RECEIVE_LIST_PATH))
      .catch(data => snackbar({ message: getNonFieldError(data), type: ALTER_ERROR }))

  const conditionList = receiveConditionList.results
  const conditionCode = length(conditionList) === 1 ? path([0, 'code'], conditionList) : ''

  const number = prop('number', receivingDetail.detail)
  const status = prop('orderStatus', receivingDetail.detail)

  const pageTitle = (
    <Title title="Receive" status={status} number={number} />
  )

  const values = { detail: receivingDetail.detail, guid, conditionCode }

  const breadcrumbs = { title: number }

  const isLoading = receivingDetail.isLoading || receivingUpdate.isLoading

  return (
    <DashboardLayout
      title={number}
      activeNav={NAV.RECEIVE}
      isLoading={isLoading}
      breadcrumbs={breadcrumbs}
    >
      <ReceivePurchaseOrderDetail
        pageTitle={pageTitle}
        isLoading={isLoading}
        pageTitleLoading={receivingDetail.isLoading}
        completeDisabled={receivePurchaseComplete.isLoading}
        values={ReceivePurchaseDetailSerializer(values)}
        onComplete={handleComplete}
        handleReceive={handleReceive}
        receiveHistory={receiveHistory}
        getVariantDetail={variantDetail.getDetail}
      />
    </DashboardLayout>
  )
}

export default ReceivePurchaseOrderContainer
