import React, { useCallback } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { any, defaultTo, length, map, path, pipe, prop, propOr, reduce } from 'ramda'
import { useConfirm } from 'storfox-confirm-dialog'

import { unescapeBtoa } from '~/utils'
import useDialog from '~/hooks/useDialog'
import Container, { Content, Header } from '~/components/Container'
import PageTitle from '~/components/PageTitle'
import * as NAV from '~/constants/nav-titles'
import { Button, PrimaryButton } from '~/components/Buttons'
import { PACKING_DETAIL_TABS } from '~/constants/tabs'
import History from '~/components/History'
import * as ROUTES from '~/constants/routes'
import { CODE_TYPE, CONTAINER_TYPE_PREFIX } from '~/constants/barcode'
import { useProfile } from '~/components/Profile'
import { useWarehouse } from '~/components/Profile/hooks'
import { emptyArray } from '~/constants/empty'
import { CANCELLED, COMPLETED, IN_PROGRESS, NEW, ON_HOLD } from '~/components/Statuses/PackingStatus'

import More from './More'
import PackerDialogForm from './PackerDialogForm'
import PackingGeneral from './PackingGeneral'
import PackingDetailTabs from './PackingDetailTabs'

import CompleteDialogForm from '../CompleteDialogForm'
import PrintDialog from '../PrintDialog'
import { ACTIONS } from '../../constants'

const getContainerTypePrintPath = pipe(
  map(containerType => {
    const barcode = prop('guid', containerType)
    const name = prop('name', containerType)

    return { barcode: `${CONTAINER_TYPE_PREFIX}${barcode}|${name}` }
  }),
  unescapeBtoa,
  data => `${ROUTES.BARCODE_GENERATOR_PATH}?barcodes=${data}&type=${CODE_TYPE.QRCODE}`
)

const getLineItemsTotalQuantity = pipe(
  defaultTo([]),
  reduce((acc, item) => {
    const quantity = propOr(0, 'quantity', item)
    return acc + quantity
  }, 0)
)

const getPackedCount = reduce((acc, container) => {
  const quantity = getLineItemsTotalQuantity(prop('lineItems', container))
  return acc + quantity
}, 0)

function PackingDetail (props) {
  const {
    pageTitle,
    isLoading,
    values,
    saleOrderNumber,
    onComplete,
    onCompleteAndRedirect,
    packingHistory,
    getDetail,
    onProcess,
    onPackerChange,
    onSkip,
    pageTitleLoading,
    shipmentLocationList,
    containerTypeList,
    onGenerateSlip,
    onPrintInvoice,
    completePacking,
    handleCompleteAndPrintInvoice
  } = props

  const { id, tab } = useParams()
  const navigate = useNavigate()
  const { matchProfile } = useProfile()
  const { hasOwnWarehouseById } = useWarehouse()

  const packerDialog = useDialog()
  const completeDialog = useDialog()
  const printDialog = useDialog()
  const onConfirm = useConfirm()

  const containers = propOr(emptyArray, 'containers', values)
  const warehouseId = prop('warehouseId', values)
  const packer = prop('packer', values)
  const packerId = prop('id', packer)

  const isOwner = hasOwnWarehouseById(warehouseId)

  const containerTypesPrintPath = getContainerTypePrintPath(containerTypeList.results)

  const status = prop('status', values)
  const awbUrl = path(['saleOrder', 'awbUrl'], values)
  const commercialInvoiceUrl = path(['saleOrder', 'commercialInvoiceUrl'], values)

  const hasScannedItemsInContainer = pipe(
    map(propOr([], 'lineItems')),
    any(length)
  )(containers)

  const disabled = (
    isLoading ||
    status === NEW ||
    status === COMPLETED ||
    status === ON_HOLD
  )

  const skipDisabled = (
    isLoading || !isOwner || status === CANCELLED || status === COMPLETED
  )

  const packDisabled = (
    isLoading ||
    status !== IN_PROGRESS ||
    !matchProfile(packerId)
  )

  const packedCount = getPackedCount(containers)
  const totalQuantity = propOr(1, 'totalQuantity', values)

  const completeDisabled = Boolean(
    disabled ||
    packedCount !== totalQuantity ||
    !hasScannedItemsInContainer
  )

  const packerDialogOpenDisabled = (
    isLoading || status === COMPLETED
  )

  const handleComplete = formValues =>
    onComplete(formValues)
      .then(() => completeDialog.handleClose())

  const handleCompleteAndRedirect = formValues =>
    onCompleteAndRedirect(formValues)
      .then(() => completeDialog.handleClose())

  const handlePack = () => {
    const path = generatePath(ROUTES.PACKING_UPDATE_PATH, { id })
    navigate(path)
  }

  const initialDialogValues = {
    packing: { id: parseInt(id) },
    location: prop(0, shipmentLocationList)
  }

  const handlePackerChange = formValues =>
    onPackerChange(formValues)
      .then(() => packerDialog.handleClose())
      .then(() => getDetail())

  const handleConfirm = () =>
    onConfirm({ message: `Are you sure, you want to skip packing for ${saleOrderNumber}?` })
      .agree(onSkip)
      .disagree()

  const handleScanNextOrder = useCallback(() => {
    const url = `${ROUTES.PACKING_LIST_PATH}?action=${ACTIONS.OPEN_SCAN}`
    navigate(url)
  }, [navigate])

  const handleCompleteAndPrint = useCallback((values) => {
    return completePacking(values).then(() => {
      completeDialog.handleClose()
      printDialog.handleOpen()
    })
  }, [completeDialog, completePacking, printDialog])

  const omCompleteAndPrintInvoice = useCallback((values) => {
    return handleCompleteAndPrintInvoice(values).then(() => {
      completeDialog.handleClose()
      printDialog.handleOpen()
    })
  }, [handleCompleteAndPrintInvoice, completeDialog, printDialog])

  return (
    <>
      <Container>
        <Header>
          <PageTitle
            parentTitle={NAV.PACKING}
            pageTitle={pageTitle}
            pageTitleLoading={pageTitleLoading}
            rightButton={(
              <>
                {status === NEW && (
                  <PrimaryButton
                    data-cy="process"
                    onClick={onProcess}
                  >
                    Process
                  </PrimaryButton>
                )}

                {status === IN_PROGRESS && (
                  <Button
                    disabled={completeDisabled}
                    variant="contained"
                    data-cy="complete"
                    onClick={completeDialog.handleOpen}
                  >
                    Complete
                  </Button>
                )}

                <PrimaryButton
                  data-cy="pack"
                  disabled={packDisabled}
                  onClick={handlePack}
                >
                  Pack
                </PrimaryButton>

                <PrimaryButton
                  data-cy="skip"
                  disabled={skipDisabled}
                  onClick={handleConfirm}
                >
                  Skip
                </PrimaryButton>

                <More
                  onGenerateSlip={onGenerateSlip}
                  slipDisabled={isLoading}
                  packerDialogOpenDisabled={packerDialogOpenDisabled}
                  onPackerDialogOpen={packerDialog.handleOpen}
                  containerTypesPrintPath={containerTypesPrintPath}
                  containerTypesPathDisabled={containerTypeList.isLoading}
                />
              </>
            )}
          />
        </Header>
        <Content>
          <PackingDetailTabs id={id} value={tab} />
          {tab === PACKING_DETAIL_TABS.GENERAL && (
            <PackingGeneral
              packedCount={packedCount}
              values={values}
              totalQuantity={totalQuantity}
              detailLoading={pageTitleLoading}
            />
          )}
          {tab === PACKING_DETAIL_TABS.HISTORY && <History history={packingHistory} />}
        </Content>
      </Container>

      <PackerDialogForm
        open={packerDialog.open}
        initialValues={{ packer }}
        onClose={packerDialog.handleClose}
        onSubmit={handlePackerChange}
      />

      <CompleteDialogForm
        open={completeDialog.open}
        onSubmit={handleComplete}
        warehouseId={warehouseId}
        initialValues={initialDialogValues}
        onClose={completeDialog.handleClose}
        onCompleteAndRedirect={handleCompleteAndRedirect}
        awbUrl={awbUrl}
        commercialInvoiceUrl={commercialInvoiceUrl}
        onCompleteAndPrint={handleCompleteAndPrint}
        onCompleteAndPrintInvoice={omCompleteAndPrintInvoice}
      />

      <PrintDialog
        onClose={printDialog.handleClose}
        open={printDialog.open}
        awbUrl={awbUrl}
        commercialInvoiceUrl={commercialInvoiceUrl}
        onScanNextOrder={handleScanNextOrder}
        onPrintInvoice={onPrintInvoice}
      />
    </>
  )
}

PackingDetail.propTypes = {
  values: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onProcess: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  containerTypeList: PropTypes.object.isRequired,
  onCompleteAndRedirect: PropTypes.func.isRequired,
  saleOrderNumber: PropTypes.string.isRequired,
  onPackerChange: PropTypes.func.isRequired,
  onSkip: PropTypes.func.isRequired,
  getDetail: PropTypes.func.isRequired,
  packingHistory: PropTypes.object.isRequired,
  pageTitle: PropTypes.object.isRequired,
  pageTitleLoading: PropTypes.bool.isRequired,
  shipmentLocationList: PropTypes.array.isRequired,
  onGenerateSlip: PropTypes.func.isRequired,
  completePacking: PropTypes.func.isRequired,
  handleCompleteAndPrintInvoice: PropTypes.func.isRequired,
  onPrintInvoice: PropTypes.func.isRequired
}

export default PackingDetail
