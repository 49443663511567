import objectPath from 'object-path'

import useGetApi from '../api/useGetApi'
import useDeepCompareEffect from '../useDeepCompareEffect'

const emptyList = []
const emptyObject = {}

export const toList = state => {
  const results = objectPath.get(state, 'data.results') || emptyList
  const hasNextPage = objectPath.get(state, 'data.hasNextPage') || false
  const hasPrevPage = objectPath.get(state, 'data.hasPrevPage') || false
  const summary = objectPath.get(state, 'data.summary') || emptyObject
  const count = objectPath.get(state, 'data.count') || results.length
  const isLoading = state.isLoading
  const getList = state.getList
  const getListByParams = state.getListByParams

  return { count, results, summary, isLoading, getList, getListByParams, hasNextPage, hasPrevPage }
}

function useList (api, searchParams, autoSend = true, cancelToken = null) {
  const { get, ...state } = useGetApi(api, autoSend)
  const getList = get
  const getListByParams = () => getList(searchParams)

  useDeepCompareEffect(() => {
    if (autoSend) {
      getList(searchParams, { cancelToken: cancelToken && cancelToken.token })
    }
    return () => {
      cancelToken && cancelToken.cancel()
    }
  }, [autoSend, getList, searchParams])

  return toList({ ...state, getList, getListByParams })
}

export default useList
