import { useCallback, useState } from 'react'
import { propOr } from 'ramda'

import useList from './useList'

import useDeepCompareEffect from '../useDeepCompareEffect'

function useScrollList (api, searchParams, limit = 10) {
  const [results, setResults] = useState([])
  const [page, setPage] = useState(1)
  const { getList, ...state } = useList(api, searchParams, false)

  useDeepCompareEffect(() => {
    const nextPage = (results.length / limit) + 1
    setPage(nextPage)
  }, [results])

  const loadList = (params, options) =>
    getList({ limit, page, ...params }, options)
      .then(data => {
        const results = propOr([], 'results', data)
        setResults(prevData => [...prevData, ...results])
        return results
      })

  const reset = useCallback(() => {
    setPage(1)
    setResults([])
  }, [])

  return { ...state, results, getList: loadList, reset }
}

export default useScrollList
