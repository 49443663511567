import React from 'react'
import PropTypes from 'prop-types'

function CenteredLayout ({ children, ...props }) {
  return (
    <div {...props}>{children}</div>
  )
}

CenteredLayout.propTypes = {
  children: PropTypes.node
}

export default CenteredLayout
