import React from 'react'
import PropTypes from 'prop-types'
import { map, path, prop, propOr, replace } from 'ramda'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'
import Box from '@mui/material/Box'

import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import TableDateFormat from '~/components/TableDateFormat'
import { Volume, Weight } from '~/components/Converters'
import Money from '~/components/Money'
import TextOverflow from '~/components/TextOverflow'
import { Table, TableHeader } from '~/components/Table'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import { SHIPMENT_DETAIL_TABS } from '~/constants/tabs'
import CurrencyMoney from '~/components/Money/CurrencyMoney'
import TableLink from '~/components/Link'
import { capitalize } from '~/utils'
import Button from '~/components/Buttons/Button'

import CarrierResponse from './CarrierResponse'
import ShipmentStatus from './ShipmentStatus'

export const SHIPMENT_COLUMNS = [
  {
    width: 150,
    headerName: 'Shipment number',
    field: 'shipmentNumber',
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        <TextOverflow selfTooltip={true} lines={1}>
          {value}
        </TextOverflow>
        {row.shippingMethodError && (
          <Box ml={1}>
            <CarrierResponse value={row.shippingMethodError} />
          </Box>
        )}
      </TableLink>
    )
  },
  {
    width: 200,
    headerName: 'Tracking number',
    field: 'trackingNumber',
    renderCell: ({ value }) => (
      <TextOverflow lines={1} selfTooltip={true}>
        {value}
      </TextOverflow>
    )
  },
  {
    width: 150,
    headerName: 'SO #',
    field: 'orderNumber'
  },
  {
    width: 150,
    headerName: 'Reference #',
    field: 'referenceNumber'
  },
  {
    width: 200,
    headerName: 'Container numbers',
    field: 'containerNumbers',
    renderCell: ({ value }) => (
      <TextOverflow lines={1} selfTooltip={true}>
        {value}
      </TextOverflow>
    )
  },
  {
    width: 150,
    headerName: 'Shipping status',
    field: 'shippingStatus',
    renderCell: ({ value }) => (
      <ShipmentStatus value={value} />
    )
  },
  {
    width: 150,
    headerName: 'Carrier',
    field: 'carrier'
  },
  {
    width: 150,
    headerName: 'Service',
    field: 'service'
  },
  {
    width: 150,
    headerName: 'Direction',
    field: 'direction'
  },
  {
    width: 150,
    headerName: 'Carrier status',
    field: 'carrierStatus'
  },
  {
    width: 150,
    headerName: 'Order date',
    field: 'objectDate',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  },
  {
    width: 150,
    headerName: 'Shipped date',
    field: 'shippedDate',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  },
  {
    width: 150,
    headerName: 'Delivered date',
    field: 'deliveredDate',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  },
  {
    width: 150,
    headerName: 'Created at',
    field: 'createdAt',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  },
  {
    width: 150,
    headerName: 'Last updated',
    field: 'updatedAt',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  },
  {
    width: 150,
    headerName: 'Line items',
    field: 'lineItems'
  },
  {
    width: 150,
    headerName: 'Units',
    field: 'units'
  },
  {
    width: 150,
    headerName: 'Packages',
    field: 'packages'
  },
  {
    width: 150,
    headerName: 'Volume',
    field: 'volume',
    renderCell: ({ value }) => (
      <Volume value={value} />
    )
  },
  {
    width: 150,
    headerName: 'Weight',
    field: 'weight',
    renderCell: ({ value }) => (
      <Weight value={value} />
    )
  },
  {
    width: 150,
    headerName: 'Total value',
    field: 'totalValue',
    valueGetter: ({ row }) => `${row.totalValue} ${row.clientCurrency}`,
    renderCell: ({ row }) => (
      <CurrencyMoney currency={row.clientCurrency} value={row.totalValue} />
    )
  },
  {
    width: 200,
    headerName: 'Gatepass serial number',
    field: 'gatepassSerialNumber'
  },
  {
    width: 200,
    headerName: 'Vehicle number',
    field: 'vehicleNumber'
  },
  {
    width: 150,
    headerName: 'Shipping cost',
    field: 'shippingCost',
    renderCell: ({ value }) => (
      <Money value={value} />
    )
  },
  {
    width: 150,
    headerName: 'From warehouse',
    field: 'fromWarehouse'
  },
  {
    width: 150,
    headerName: 'To city',
    field: 'toCity'
  },
  {
    width: 150,
    headerName: 'To country',
    field: 'toCountry'
  }
]

const getRowsFromResults = map(item => {
  const id = prop('id', item)
  const guid = prop('guid', item)

  const trackingNumber = prop('trackingNumber', item)

  const referenceNumber = prop('toObjectReferenceNumber', item)
  const orderNumber = prop('objectNumber', item)
  const shipmentNumber = prop('shipmentNumber', item)
  const direction = capitalize(prop('direction', item))
  const carrier = path(['shippingMethod', 'name'], item)
  const service = path(['service', 'name'], item)
  const shippingCost = path(['service', 'price'], item)
  const shippingStatus = prop('status', item)
  const carrierStatusTemp = prop('carrierStatus', item)
  const carrierStatus = replace(/[_]/g, ' ', carrierStatusTemp)
  const units = prop('unitsCount', item)
  const packages = prop('containersCount', item)
  const lineItems = prop('variantsCount', item)
  const vehicleNumber = prop('vehicleNumber', item)
  const gatepassSerialNumber = prop('gatepassSerialNumber', item)
  const volume = prop('totalVolume', item)
  const weight = prop('totalWeight', item)
  const clientCurrency = prop('currency', item)
  const totalValue = prop('toObjectTotalPrice', item)
  const fromWarehouse = path(['fromWarehouse', 'name'], item)
  const toCity = path(['shippingAddress', 'city'], item)
  const toCountry = path(['shippingAddress', 'countryName'], item)
  const createdAt = prop('createdAt', item)
  const updatedAt = prop('updatedAt', item)
  const objectDate = prop('objectDate', item)
  const shippedDate = prop('shippedDate', item)
  const deliveredDate = prop('deliveredDate', item)
  const shippingMethodError = prop('shippingMethodError', item)
  const containerNumbers = propOr([], 'containerNumbers', item).join(', ')

  const link = generatePath(ROUTES.SHIPMENT_DETAIL_PATH, {
    guid,
    tab: SHIPMENT_DETAIL_TABS.GENERAL
  })

  return {
    id,
    guid,
    shipmentNumber,
    orderNumber,
    clientCurrency,
    referenceNumber,
    containerNumbers,
    direction,
    carrierStatus,
    trackingNumber,
    shippingStatus,
    shippingMethodError,
    carrier,
    packages,
    lineItems,
    objectDate,
    service,
    shippedDate,
    shippingCost,
    totalValue,
    deliveredDate,
    updatedAt,
    units,
    fromWarehouse,
    toCountry,
    volume,
    weight,
    toCity,
    vehicleNumber,
    gatepassSerialNumber,
    createdAt,
    link
  }
})

function ShipmentTable (props) {
  const {
    list,
    filter,
    ordering,
    onListRefetch,
    onShipmentsExport
  } = props

  const { t } = useTranslation()

  const isLoading = prop('isLoading', list)
  const results = prop('results', list)
  const count = prop('count', list)
  const columns = prop('filteredColumns', ordering)

  return (
    <>
      <Container>
        <Header>
          <PageTitle
            pageTitle={t(NAV.SHIPMENT_REPORT)}
            parentTitle={t(NAV.REPORTS)}
            rightButton={(
              <Button
                variant="contained"
                data-cy="create"
                type="button"
                onClick={onShipmentsExport}
              >
                {t('Generate Report')}
              </Button>
            )}
          />
        </Header>

        <Content>
          <TableHeader
            filter={filter}
            ordering={ordering}
            isLoading={isLoading}
            filterOpen={filter.handleOpen}
            orderingOpen={ordering.handleOpen}
          />
          <Table
            isLoading={isLoading}
            columns={columns}
            getRowsFromResults={getRowsFromResults}
            results={results}
            count={count}
            checkboxSelection={true}
            ordering={ordering}
            pinnedColumns={['shipmentNumber']}
            primaryKey="guid"
            onRefetch={onListRefetch}
          />
        </Content>
      </Container>
    </>
  )
}

ShipmentTable.propTypes = {
  list: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  onShipmentsExport: PropTypes.func.isRequired,
  onListRefetch: PropTypes.func.isRequired
}

export default ShipmentTable
