import React from 'react'
import PropTypes from 'prop-types'
import Chart from 'react-apexcharts'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'

function OrderChart ({ data: dataProp, ...props }) {
  const { t } = useTranslation()
  const data = { labels: [], series: [] }

  for (const item of dataProp) {
    if (item.orders) {
      data.labels.push(item.label)
      data.series.push(item.orders)
    }
  }

  const totalOrders = data.series.reduce((a, b) => a + b, 0)

  const opt = {
    stroke: { width: 0 },
    chart: { type: 'donut' },
    legend: {
      position: 'top',
      labels: {
        color: '#666666',
      },
      onItemClick: {
        toggleDataSeries: false
      },
      itemMargin: {
        horizontal: 20,
        vertical: 5
      }
    },
    labels: data.labels,
    dataLabels: {
      dropShadow: {
        enabled: false
      },
      style: {
        fontWeight: 400
      }
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          labels: {
            show: true,
            name: {
              show: true,
              formatter: () => totalOrders,
              fontSize: 24,
              fontWeight: 500,
              offsetY: 1
            },
            value: {
              show: true,
              fontSize: 16,
              fontWeight: 600,
              offsetY: 5
            },
            total: {
              show: true,
              showAlways: true,
              formatter: () => t('Total Orders'),
              fontSize: 24,
              fontWeight: 600
            }
          }
        }
      }
    }
  }

  return (
    <Box position="relative" {...props}>
      <Chart type="donut" options={opt} series={data.series} width={450} />
    </Box>
  )
}

OrderChart.propTypes = {
  data: PropTypes.array.isRequired
}

export default OrderChart
