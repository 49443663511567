import React from 'react'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import Paper from '@mui/material/Paper'

import SkeletonList from '~/components/Skeleton'

function TableSkeleton () {
  return (
    <Paper>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Variant</TableCell>
              <TableCell>Condition code</TableCell>
              <TableCell>Quality</TableCell>
              <TableCell>Reject</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <SkeletonList
              height={20}
              rowCount={5}
              columnCount={4}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default TableSkeleton
