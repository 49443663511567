import React from 'react'
import { useFilter, useOrdering } from 'storfox-filter'
import { useTranslation } from 'react-i18next'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import { getNonFieldError } from '~/components/Form'
import * as NAV from '~/constants/nav-titles'
import ErrorLink from '~/components/ErrorLink'
import useMessages from '~/hooks/useMessages'
import { useTableSelects } from '~/components/TableValues/hooks'

import {
  ProductFilterForm,
  productFilterOptions,
  ProductOrderingForm,
  productOrderingOptions,
  ProductTable
} from '../components/ProductList'
import { useProductList, useProductsDelete, useProductsExport } from '../hooks'

const exportMessage = 'Success. Wait for the notification'

function ProductListContainer () {
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const messages = useMessages()
  const productList = useProductList()
  const productsDelete = useProductsDelete()
  const productExport = useProductsExport()

  const { selects, resetTableSelects } = useTableSelects()

  const filter = useFilter(productFilterOptions)
  const ordering = useOrdering(productOrderingOptions)

  const handleProductsExport = () =>
    productExport.export()
      .then(() => snackbar({ message: exportMessage }))
      .catch(err => snackbar({ message: getNonFieldError(err), type: ALTER_ERROR }))

  const handleProductsDelete = () =>
    productsDelete.delete({ ids: selects })
      .then(() => productList.getList())
      .then(() => snackbar({ message: messages.DELETE_SUCCESS }))
      .then(() => resetTableSelects())
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const breadcrumbs = { title: NAV.PRODUCTS }

  return (
    <DashboardLayout
      title={t(NAV.PRODUCTS)}
      activeNav={NAV.PRODUCTS}
      breadcrumbs={breadcrumbs}
    >
      <ProductFilterForm {...filter} />
      <ProductOrderingForm {...ordering} />

      <ProductTable
        list={productList}
        filter={filter}
        ordering={ordering}
        onListRefetch={productList.getListByParams}
        onProductsExport={handleProductsExport}
        onProductsDelete={handleProductsDelete}
        bulkActionLoading={productsDelete.isLoading}
      />
    </DashboardLayout>
  )
}

export default ProductListContainer
