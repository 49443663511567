import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDeepCompareEffect } from 'storfox-api-hooks'
import { prop } from 'ramda'

import { getProfile, setProfile } from './store'
import Context from './context'

function ProfileProvider ({ children }) {
  const initialProfile = getProfile()
  const [value, setValue] = useState(initialProfile)

  const profileId = prop('id', value)
  const matchProfile = id => profileId === id

  useDeepCompareEffect(() => {
    setProfile(value)
  }, [value])

  return (
    <Context.Provider
      value={{
        profile: value,
        setProfile: setValue,
        matchProfile
      }}
    >
      {children}
    </Context.Provider>
  )
}

ProfileProvider.propTypes = {
  children: PropTypes.any.isRequired
}

export default ProfileProvider
