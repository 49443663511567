import React from 'react'
import PropTypes from 'prop-types'
import * as colors from '@mui/material/colors'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'

import { getSxArray } from '~/utils'

export const SUCCESS = 'success'
export const WARNING = 'warning'
export const ERROR = 'error'

const RootStyled = styled(Box)(({ theme, color }) => ({
  height: 20,
  display: 'inline-flex',
  padding: theme.spacing(1, 1),
  alignItems: 'center',
  textTransform: 'uppercase',
  justifyContent: 'center',
  fontSize: '0.75rem',
  fontWeight: '500',
  whiteSpace: 'nowrap',
  ...(color === SUCCESS && {
    color: colors.green[500],
    backgroundColor: `${colors.green[500]}0F`,
  }),
  ...(color === WARNING && {
    color: colors.orange[500],
    backgroundColor: `${colors.orange[500]}0F`
  }),
  ...(color === ERROR && {
    color: colors.red[500],
    backgroundColor: `${colors.red[500]}0F`,
  })
}))

function Status ({ label, color, className, sx, ...props }) {
  return (
    <RootStyled
      color={color}
      component="span"
      className={className}
      sx={[...getSxArray(sx)]}
      {...props}
    >
      {label}
    </RootStyled>
  )
}

Status.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(['success', 'warning', 'error']),
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  sx: PropTypes.any
}

export default Status
