import React from 'react'
import { prop, propOr } from 'ramda'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { styled } from '@mui/material'

import Avatar from '~/components/Avatar/Avatar'
import VerticalAlignment from '~/components/VerticalAlignment'
import Subtext from '~/components/Subtext'
import SkeletonList from '~/components/Skeleton'
import TextOverflow from '~/components/TextOverflow'
import Money from '~/components/Money'

import LineItemRow from './LineItemRow'

const WideTableCellStyled = styled(TableCell)({
  minWidth: 200
})

function LineItems ({ isLoading, lineItems }) {
  const { t } = useTranslation()

  return (
    <TableContainer>
      <Table size="small" sx={{ minWidth: '700px' }}>
        <TableHead>
          <TableRow>
            <TableCell>{t('Image')}</TableCell>
            <WideTableCellStyled>{t('Variant')}</WideTableCellStyled>
            <WideTableCellStyled>{t('Quantity')}</WideTableCellStyled>
            <WideTableCellStyled>{t('Retail price')}</WideTableCellStyled>
            <WideTableCellStyled>{t('Total Price')}</WideTableCellStyled>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading
            ? <SkeletonList columnCount={8} />
            : lineItems.map((item, index) => {
              const variant = prop('variant', item)
              const variantName = prop('name', variant)
              const sku = prop('sku', variant)
              const imagePath = prop('imagePath', item)
              const unitPrice = propOr(0, 'price', item)
              const quantity = propOr(0, 'quantity', item)
              const totalPrice = propOr(0, 'totalPrice', item)

              const isLastRow = index === lineItems.length - 1
              const units = propOr([], 'units', item)

              return (
                <LineItemRow key={index} isLastRow={isLastRow} units={units}>
                  <TableCell>
                    <Avatar
                      alt={variantName}
                      src={imagePath}
                    />
                  </TableCell>

                  <TableCell>
                    <VerticalAlignment
                      sx={{ minWidth: 200 }}
                      primary={(
                        <TextOverflow
                          selfTooltip={true}
                        >
                          {variantName}
                        </TextOverflow>
                      )}
                      secondary={<Subtext lines={1}>{sku}</Subtext>}
                    />
                  </TableCell>
                  <TableCell>{quantity}</TableCell>
                  <TableCell>
                    <Money value={unitPrice} />
                  </TableCell>
                  <TableCell>
                    <Money value={totalPrice} />
                  </TableCell>
                </LineItemRow>
              )
            })}
        </TableBody>
      </Table>
    </TableContainer>

  )
}

LineItems.propTypes = {
  lineItems: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired
}

LineItems.defaultProps = {
  lineItems: 0
}

export default LineItems
