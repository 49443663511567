import { colors } from '@mui/material'

import palette from '~/theme/dark/palette'

export default {
  styleOverrides: {
    root: {
      borderRadius: 3,
      overflow: 'hidden'
    },
    colorPrimary: {
      backgroundColor: colors.grey[600]
    },
    barColorPrimary: {
      backgroundColor: palette.text.primary
    }
  }
}
