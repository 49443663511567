import React from 'react'
import { map, pipe, propOr, reduce, sum } from 'ramda'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import Table from '@mui/material/Table'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import Money from '~/components/Money'

const getQuantity = reduce((acc, { quantity }) => Number(acc + quantity), 0)

const getSum = pipe(
  map(item => {
    const barcodeItems = propOr([], 'barcodeItems', item)
    const quantity = getQuantity(barcodeItems)
    const price = propOr(0, 'price', item)
    return price * quantity
  }),
  sum
)

const getTotalQuantity = pipe(
  map(propOr([], 'barcodeItems')),
  reduce((acc, barcodeItems) => {
    return acc + getQuantity(barcodeItems)
  }, 0)
)

function InvoiceSummary ({ lineItems }) {
  const { t } = useTranslation()
  const totalQuantity = getTotalQuantity(lineItems)
  const subTotal = getSum(lineItems)
  const subTotalAll = <Money value={subTotal} />

  return (
    <Table size="small">
      <TableBody>
        <TableRow>
          <TableCell>
            {t('Total transfer quantity')}
          </TableCell>
          <TableCell align="right">
            {totalQuantity}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            {t('Total Transfer value')}
          </TableCell>
          <TableCell align="right">
            {subTotalAll}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

InvoiceSummary.propTypes = {
  lineItems: PropTypes.array.isRequired
}

export default InvoiceSummary
