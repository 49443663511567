import React from 'react'
import PropTypes from 'prop-types'
import { generatePath } from 'react-router-dom'
import DialogContent from '@mui/material/DialogContent'
import Dialog from '@mui/material/Dialog'
import { prop } from 'ramda'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'

import DialogTitle from '~/components/DialogTitle'
import ChoiceCard, { ChoiceCardLink } from '~/components/Cards/ChoiceCard'
import * as ROUTES from '~/constants/routes'

function PutawayWarehouseDialog ({ open, onClose, warehouseList }) {
  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth={true}
      onClose={onClose}
    >
      <DialogTitle>Choose Warehouse</DialogTitle>

      <DialogContent dividers={true}>
        <Grid container={true} spacing={3}>
          {warehouseList.map(warehouse => {
            const warehouseGuid = prop('guid', warehouse)
            const name = prop('name', warehouse)
            const updatePath = generatePath(ROUTES.V2_PUTAWAY_CREATE_PATH, { guid: warehouseGuid })

            return (
              <Grid
                key={warehouseGuid}
                data-cy={name}
                item={true}
                lg={6}
                sm={12}
              >
                <ChoiceCard sx={{ height: '100%' }}>
                  <ChoiceCardLink to={updatePath}>
                    <Typography variant="h6">
                      {name}
                    </Typography>
                  </ChoiceCardLink>
                </ChoiceCard>
              </Grid>
            )
          })}
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

PutawayWarehouseDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  warehouseList: PropTypes.array.isRequired
}

export default PutawayWarehouseDialog
