import React from 'react'
import PropTypes from 'prop-types'
import { prop } from 'ramda'
import Box from '@mui/material/Box'

import { getCurrencySymbol } from '~/utils'
import { useCompany } from '~/components/Profile'

const toMoney = value => value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')

function CurrencyMoney ({ currency, value }) {
  const { company } = useCompany()
  const companyCurrency = prop('currency', company)
  const symbol = getCurrencySymbol(currency) || companyCurrency

  return (
    <Box sx={{ whiteSpace: 'nowrap' }}>
      {`${symbol} ${value ? toMoney(value) : '0.00'}`}
    </Box>
  )
}

CurrencyMoney.propTypes = {
  value: PropTypes.number,
  currency: PropTypes.string
}

export default CurrencyMoney
