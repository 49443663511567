import { useState } from 'react'

export function useUnitsPopover () {
  const [open, setOpen] = useState(false)
  const [items, setItems] = useState([])

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return {
    open,
    items,
    setItems,
    handleOpen,
    handleClose
  }
}
