import React from 'react'
import { path, prop, propOr } from 'ramda'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import Avatar from '~/components/Avatar/Avatar'
import VerticalAlignment from '~/components/VerticalAlignment'
import TextOverflow from '~/components/TextOverflow'
import Subtext from '~/components/Subtext'
import SkeletonList from '~/components/Skeleton'
import CurrencyMoney from '~/components/Money/CurrencyMoney'
import BorderTableRow from '~/components/BorderTableRow'

function LineItems ({ isLoading, lineItems }) {
  const { t } = useTranslation()

  return (
    <TableContainer>
      <Table size="small" sx={{ minWidth: '700px' }}>
        <TableHead>
          <TableRow>
            <TableCell>{t('Image')}</TableCell>
            <TableCell sx={{ width: '250px' }}>{t('Variant')}</TableCell>
            <TableCell sx={{ minWidth: '175px' }}>{t('Unit price')}</TableCell>
            <TableCell sx={{ minWidth: '200px' }}>{t('Condition code')}</TableCell>
            <TableCell>{t('Qty')}</TableCell>
            <TableCell>{t('Reserved')}</TableCell>
            <TableCell>{t('Taxes')}</TableCell>
            <TableCell>{t('Total')}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading
            ? <SkeletonList columnCount={8} />
            : lineItems.map((item, index) => {
              const variant = prop('variant', item)
              const variantName = prop('name', variant)
              const sku = prop('sku', variant)
              const conditionCode = path(['condition', 'name'], item)
              const imagePath = prop('imagePath', item)
              const unitCost = propOr(0, 'price', item)
              const quantity = propOr(0, 'quantity', item)
              const taxPercentage = propOr(0, 'tax', item)
              const tax = propOr(0, 'tax', item) / 100 * quantity * unitCost
              const totalPrice = tax + (quantity * unitCost)
              const reserved = prop('reserved', item)

              const isLastRow = index === lineItems.length - 1

              return (
                <BorderTableRow
                  key={index}
                  isLastRow={isLastRow}
                >
                  <TableCell>
                    <Avatar
                      alt={variantName}
                      src={imagePath}
                    />
                  </TableCell>

                  <TableCell>
                    <VerticalAlignment
                      sx={{ minWidth: 200 }}
                      primary={(
                        <TextOverflow selfTooltip={true}>
                          {variantName}
                        </TextOverflow>
                      )}
                      secondary={<Subtext lines={1}>{sku}</Subtext>}
                    />
                  </TableCell>
                  <TableCell>
                    <CurrencyMoney value={unitCost} />
                  </TableCell>
                  <TableCell>{conditionCode}</TableCell>
                  <TableCell sx={{ width: '150px' }}>{quantity}</TableCell>
                  <TableCell sx={{ width: '150px' }}>{reserved}</TableCell>
                  <TableCell sx={{ width: '150px' }}>{taxPercentage}</TableCell>
                  <TableCell>
                    <CurrencyMoney value={totalPrice} />
                  </TableCell>
                </BorderTableRow>
              )
            })}
        </TableBody>
      </Table>
    </TableContainer>

  )
}

LineItems.propTypes = {
  lineItems: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired
}

LineItems.defaultProps = {
  lineItems: 0
}

export default LineItems
