export const UNSHIPPED = 'unshipped'
export const PRE_TRANSIT = 'pre_transit'
export const IN_TRANSIT = 'in_transit'
export const DELIVERED = 'delivered'
export const RETURNED = 'returned'
export const FAILED = 'failed'
export const CANCELLED = 'cancelled'

export const STATUS_NAMES = {
  [UNSHIPPED]: 'Unshipped',
  [PRE_TRANSIT]: 'Pre-transit',
  [IN_TRANSIT]: 'In transit',
  [DELIVERED]: 'Delivered',
  [RETURNED]: 'Returned',
  [FAILED]: 'Failed',
  [CANCELLED]: 'Cancelled'
}

export const FILTER_NAME = 'shipment'
