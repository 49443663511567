import PropTypes from 'prop-types'
import React from 'react'
import { Card, Divider, styled, TableCell } from '@mui/material'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import { path, prop } from 'ramda'

import { CardHeader } from '~/components/Cards'
import TextLoader from '~/components/TextLoader'
import SourceDestinationLink from '~/components/SourceDestinationLink'

import Type from './Type'

const TableStyled = styled(Table)({
  '& tr': {
    '& > td:first-of-type': {
      width: '40%'
    }
  }
})

function UnitInformationCard ({ activeUnit }) {
  const serialNumber = prop('serialNumber', activeUnit)
  const expiresAt = prop('expiresAt', activeUnit)
  const productionDate = prop('productionDate', activeUnit)
  const sourceGuid = prop('sourceGuid', activeUnit)
  const destinationGuid = prop('destinationGuid', activeUnit)

  return (
    <Card>
      <CardHeader title="Unit information" />
      <Divider />
      <TableContainer>
        <TableStyled size="small">
          <TableBody>
            <TableRow>
              <TableCell>
                <strong>Variant</strong>
              </TableCell>
              <TableCell>
                <TextLoader>{path(['variant', 'name'], activeUnit)}</TextLoader>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <strong>Unit number</strong>
              </TableCell>
              <TableCell>
                <TextLoader>{prop('unitNumber', activeUnit)}</TextLoader>
              </TableCell>
            </TableRow>

            <TableRow>

              <TableCell>
                <strong>Type</strong>
              </TableCell>
              <TableCell>
                <TextLoader>
                  <Type value={prop('type', activeUnit)} />
                </TextLoader>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <strong>Old quantity</strong>
              </TableCell>
              <TableCell>
                <TextLoader>{prop('oldQuantity', activeUnit)}</TextLoader>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <strong>New quantity</strong>
              </TableCell>
              <TableCell>
                <TextLoader>{prop('newQuantity', activeUnit)}</TextLoader>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <strong>Source</strong>
              </TableCell>
              <TableCell>
                <TextLoader>
                  {sourceGuid && (
                    <SourceDestinationLink
                      guid={sourceGuid}
                      id={prop('sourceId', activeUnit)}
                      type={prop('sourceType', activeUnit)}
                      name={prop('source', activeUnit)}
                    />
                  )}
                </TextLoader>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <strong>Destination</strong>
              </TableCell>
              <TableCell>
                <TextLoader>
                  {destinationGuid && (
                    <SourceDestinationLink
                      guid={destinationGuid}
                      id={prop('destinationId', activeUnit)}
                      type={prop('destinationType', activeUnit)}
                      name={prop('destination', activeUnit)}
                    />
                  )}
                </TextLoader>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <strong>Serial number</strong>
              </TableCell>
              <TableCell>
                <TextLoader>{serialNumber}</TextLoader>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <strong>Batch number</strong>
              </TableCell>
              <TableCell>
                <TextLoader>{prop('batchNumber', activeUnit)}</TextLoader>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <strong>Expiry date</strong>
              </TableCell>
              <TableCell>
                <TextLoader>{expiresAt}</TextLoader>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <strong>Production date</strong>
              </TableCell>
              <TableCell>
                <TextLoader>{productionDate}</TextLoader>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <strong>Price</strong>
              </TableCell>
              <TableCell>
                <TextLoader>{prop('price', activeUnit)}</TextLoader>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <strong>Currency</strong>
              </TableCell>
              <TableCell>
                <TextLoader>{prop('currency', activeUnit)}</TextLoader>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <strong>Rejected</strong>
              </TableCell>
              <TableCell>
                <TextLoader>{prop('isRejected', activeUnit)}</TextLoader>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <strong>Company</strong>
              </TableCell>
              <TableCell>
                <TextLoader>{path(['company', 'name'], activeUnit)}</TextLoader>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <strong>Condition code</strong>
              </TableCell>
              <TableCell>
                <TextLoader>{path(['condition', 'code'], activeUnit)}</TextLoader>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <strong>Container</strong>
              </TableCell>
              <TableCell>
                <TextLoader>{path(['container', 'number'], activeUnit)}</TextLoader>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <strong>Warehouse</strong>
              </TableCell>
              <TableCell>
                <TextLoader>{path(['location', 'warehouse', 'name'], activeUnit)}</TextLoader>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <strong>Location ID</strong>
              </TableCell>
              <TableCell>
                <TextLoader>{path(['location', 'locationId'], activeUnit)}</TextLoader>
              </TableCell>
            </TableRow>
          </TableBody>
        </TableStyled>
      </TableContainer>
    </Card>
  )
}

UnitInformationCard.propTypes = {
  activeUnit: PropTypes.object
}

export default UnitInformationCard
