import React from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card/Card'
import CardContent from '@mui/material/CardContent'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import { SwitchField } from 'storfox-form-fields'
import FormControlLabel from '@mui/material/FormControlLabel'
import LinearProgress from '@mui/material/LinearProgress/LinearProgress'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'

import StatusField from '~/components/Fields/StatusField'
import TextField from '~/components/Fields/TextField'
import Divider from '~/components/Divider'
import { CardHeader } from '~/components/Cards'
import TagsField from '~/components/Fields/TagsField'

const getProgress = (current, total) =>
  total >= current ? Math.ceil(current / (total * 0.01)) : 100

function ShopifyCreate ({ syncData }) {
  const { t } = useTranslation()
  const syncProgress = syncData.totalCount > 0 ? getProgress(syncData.fetched, syncData.totalCount) : 0
  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={8}>
        <Box mb={3}>
          <Card>
            <CardHeader title={t('Integration')} />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12} lg={4}>
                  <TextField
                    data-cy="name"
                    name="name"
                    label="Name"
                  />
                </Grid>
                <Grid item={true} xs={12} lg={4}>
                  <TextField
                    data-cy="storeDomain"
                    name="credential.storeDomain"
                    label="Shop"
                    disabled={true}
                  />
                </Grid>

                <Grid item={true} xs={12} lg={4}>
                  <StatusField />
                </Grid>

                <Grid item={true} xs={12}>
                  <TextField
                    data-cy="description"
                    name="description"
                    label="Description"
                    multiline={true}
                    minRows={4}
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <TagsField
                    data-cy="tags"
                    label="Allowed order tags"
                    name="configuration.allowedOrderTags"
                  />
                </Grid>

                <Grid item={true} xs={12} sx={{ display: 'flex' }}>
                  <FormControl style={{ padding: '0 12px' }}>
                    <FormControlLabel
                      data-cy="isProductWebhookEnabled"
                      control={<SwitchField name="configuration.isProductWebhookEnabled" />}
                      label="Enable product webhook"
                    />
                  </FormControl>
                </Grid>

                <Grid item={true} xs={12} sx={{ display: 'flex' }}>
                  <FormControl style={{ padding: '0 12px' }}>
                    <FormControlLabel
                      data-cy="isStockSyncEnabled"
                      control={<SwitchField name="configuration.isStockSyncEnabled" />}
                      label="Enable stock sync"
                    />
                  </FormControl>
                </Grid>

                <Grid item={true} xs={12} sx={{ display: 'flex' }}>
                  <FormControl style={{ padding: '0 12px' }}>
                    <FormControlLabel
                      data-cy="isFulfillmentEnabled"
                      control={<SwitchField name="configuration.isFulfillmentEnabled" />}
                      label="Enable fulfillment"
                    />
                  </FormControl>
                </Grid>

                <Grid item={true} xs={12} sx={{ display: 'flex' }}>
                  <FormControl style={{ padding: '0 12px' }}>
                    <FormControlLabel
                      data-cy="isTagPostingEnabled"
                      control={<SwitchField name="configuration.isTagPostingEnabled" />}
                      label="Enable tag posting"
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>
      <Grid item={true} lg={4}>
        <Card>
          <CardHeader title="Product sync progress" />
          <Divider />
          <CardContent>
            <Box>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={12}>
                  <LinearProgress
                    sx={{ height: 8 }}
                    variant="determinate"
                    value={syncProgress}
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <Box>
                    <Typography variant="body1">Synced: {syncData.fetched}/{syncData.totalCount}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

ShopifyCreate.propTypes = {
  syncData: PropTypes.object
}

export default ShopifyCreate
