import React from 'react'

import SelectField from '~/components/Fields/SelectField'
import { FONT_SIZES } from '~/constants/barcode'

const FONT_SIZE_OPTIONS = [
  { value: FONT_SIZES.SMALL, name: 'Small' },
  { value: FONT_SIZES.MEDIUM, name: 'Medium' },
  { value: FONT_SIZES.LARGE, name: 'Large' }
]

function FontSizeSelectField () {
  return (
    <SelectField
      label="Font-size"
      name="fontSize"
      options={FONT_SIZE_OPTIONS}
    />
  )
}

export default FontSizeSelectField
