import { sprintf } from 'sprintf-js'
import { useAllSearchParams, usePickSearchParams } from 'storfox-route-hooks'
import { useLimitedCountList, useDelete, useCreate, useList, useDetail, useUpdate } from 'storfox-api-hooks'

import * as API from '~/constants/api'
import { orderingToSnackCase, queryToParams } from '~/utils'

import { LotSheetsFilterSerializer } from '../serializers'

export const useLotSheetsList = () => {
  const searchParams = useAllSearchParams()
  const params = queryToParams(LotSheetsFilterSerializer, searchParams)

  return useLimitedCountList(API.LOT_SHEETS_LIST, params)
}

export const useLotSheetsDelete = () => {
  return useDelete(API.BUCKET_DELETE)
}

export const useLotSheetsImport = () => {
  return useCreate(API.LOT_SHEETS_UPLOAD)
}

export const useLotSheetsDetail = (guid) => {
  const searchParams = usePickSearchParams()
  const url = sprintf(API.LOT_SHEETS_DETAIL, guid)
  return useList(url, { ...orderingToSnackCase(searchParams), ...{ limit: 1000 } })
}

export const useLotSheetsSave = (guid) => {
  const url = sprintf(API.LOT_SHEETS_SCAN, guid)
  return useCreate(url)
}

export const useLotShipmentCreate = () => {
  return useCreate(API.LOT_SHIPMENT_CREATE)
}

export const useLotSheetsHistory = (guid, autoSend) => {
  const searchParams = useAllSearchParams()
  const url = sprintf(API.LOT_SHEETS_HISTORY, guid)

  return useList(url, searchParams, autoSend)
}

export const useLotScanReportGenerate = guid => {
  const url = sprintf(API.LOT_SCAN_REPORT_GENERATE, guid)

  return useDetail(url, null, false)
}

export const useLotSummaryReportGenerate = guid => {
  const url = sprintf(API.LOT_SUMMARY_REPORT_GENERATE, guid)

  return useDetail(url, null, false)
}

export const useLotDelete = (guid) => {
  const url = sprintf(API.LOT_SHEETS_DELETE, guid)
  return useDelete(url)
}

export const useLotModify = guid => {
  const url = sprintf(API.LOT_SHEETS_MODIFY_ITEM, guid)
  return useUpdate(url)
}
