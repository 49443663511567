import React from 'react'
import PropTypes from 'prop-types'
import isEqual from 'react-fast-compare'

import { tableDateFormat, tableDateTimeFormat } from '~/utils'

function TableDateFormat ({ date, withTime }) {
  if (!date) {
    return null
  }

  return (
    <span>
      {withTime
        ? tableDateTimeFormat(date)
        : tableDateFormat(date)
      }
    </span>
  )
}

TableDateFormat.propTypes = {
  date: PropTypes.string,
  withTime: PropTypes.bool
}

export default React.memo(TableDateFormat, isEqual)
