import React from 'react'
import PrintIcon from '@mui/icons-material/Print'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import Button from '../Buttons/Button'

function BarcodeButton ({ onClick }) {
  const { t } = useTranslation()

  return (
    <Button
      onClick={onClick}
      variant="contained"

    >
      <PrintIcon />&nbsp;{t('Barcode')}
    </Button>
  )
}

BarcodeButton.propTypes = {
  onClick: PropTypes.func.isRequired
}

export default BarcodeButton
