import React from 'react'
import PropTypes from 'prop-types'

import TableSplitButton from '~/components/TableSplitButton'
import useDeleteConfirm from '~/components/TableSplitButton/useDeleteConfirm'

function BulkActionButton ({ onBundlesDelete, onAddToListingDialogOpen, loading }) {
  const { handleClick } = useDeleteConfirm()

  const options = [
    {
      label: 'Add to listing',
      'data-cy': 'addToListingMenu',
      onMenuClick: onAddToListingDialogOpen
    },
    {
      label: 'Delete',
      'data-cy': 'deleteMenu',
      onMenuClick: () => handleClick(onBundlesDelete)
    },
  ]

  return (
    <TableSplitButton
      options={options}
      name="bundle"
      loading={loading}
    />
  )
}

BulkActionButton.propTypes = {
  onBundlesDelete: PropTypes.func.isRequired,
  onAddToListingDialogOpen: PropTypes.func.isRequired,
  loading: PropTypes.bool
}

export default BulkActionButton
