import React, { useState } from 'react'
import { useField } from 'react-final-form'
import { prop, propEq, propOr } from 'ramda'
import { useDeepCompareEffect } from 'storfox-api-hooks'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'

function OptionSelectField (props) {
  const productOptions = prop('options', props)
  const [value, setValue] = useState('')
  const [options, setOptions] = useState(productOptions)

  const name = prop('name', props)
  const label = prop('label', props)
  const variantOptions = propOr([], 'variantOptions', props)

  const field = useField(name)

  useDeepCompareEffect(() => {
    const currentOption = variantOptions.find(item => propEq('name', label, item))
    if (currentOption) {
      const optionValue = prop('option', currentOption)
      setValue(optionValue)
      const optionByProduct = productOptions.find(propEq('option', optionValue))
      if (!optionByProduct) {
        setOptions(prev => [...prev, { name: optionValue, option: optionValue }])
      }
    }
  }, [])

  const onChange = (value) => {
    field.input.onChange({ name: label, option: value })
    setValue(value)
  }

  return (
    <FormControl fullWidth={true}>
      <InputLabel>{label}</InputLabel>
      <Select
        value={value}
        label={label}
        onChange={(event) => onChange(event.target.value)}
      >
        {options.map(option => (
          <MenuItem value={option.option} disabled={option.disabled}>{option.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default OptionSelectField
