import React from 'react'
import MuiListItemText from '@mui/material/ListItemText'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { is } from 'ramda'

function ListItemText ({ primary, secondary, ...props }) {
  const { t } = useTranslation()
  const primaryText = is(String, primary) ? t(primary) : primary
  const secondaryText = is(String, secondary) ? t(secondary) : secondary

  return (
    <MuiListItemText
      primary={primaryText}
      secondary={secondaryText}
      {...props}
    />
  )
}

ListItemText.propTypes = {
  primary: PropTypes.node,
  secondary: PropTypes.node,
  primaryTypographyProps: PropTypes.object,
  secondaryTypographyProps: PropTypes.object,
  inset: PropTypes.bool,
  disableTypography: PropTypes.bool,
  classes: PropTypes.object,
  children: PropTypes.node,
}

export default ListItemText
