import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import { Filter } from 'storfox-filter'
import { SwitchField } from 'storfox-form-fields'
import { prop } from 'ramda'

import { escapeAtob, unescapeBtoa } from '~/utils'
import Accordion from '~/components/Accordion'
import CountryField from '~/components/Fields/CountryField'

import WarehouseStatusField from './WarehouseStatusField'

import { WAREHOUSE_FILTER_NAME as name } from '../../constants'

export const toParams = values => {
  const status = prop('status', values)
  const country = unescapeBtoa(prop('country', values))
  const isDefault = prop('isDefault', values)

  return {
    status,
    country,
    isDefault,
  }
}

export const toValues = (params) => {
  const status = prop('status', params)
  const country = escapeAtob(prop('country', params))
  const isDefault = prop('isDefault', params)

  return {
    status,
    country,
    isDefault: isDefault && Boolean(isDefault),
  }
}
export const transformer = { toParams, toValues }

export const warehouseFilterOptions = {
  name,
  transformer
}

function WarehouseFilterForm (props) {
  return (
    <Filter {...props}>
      <Accordion title="General" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <WarehouseStatusField />
          </Grid>
          <Grid item={true} xs={12}>
            <CountryField
              name="country"
              data-cy="country"
              label="Country"
            />
          </Grid>
          <Grid item={true} xs={12}>
            <SwitchField
              data-cy="isDefault"
              name="isDefault"
            />
            Default Warehouses
          </Grid>
        </Grid>
      </Accordion>
    </Filter>
  )
}

WarehouseFilterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
}

export default WarehouseFilterForm
