import React from 'react'
import { Box, Card } from '@mui/material'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import PropTypes from 'prop-types'
import { propOr } from 'ramda'

import * as API from '~/constants/api'
import CardHeader from '~/components/Cards/CardHeader'

import LineItems from '../PutawayDetail/LineItems'
import PutawayUserSearchField from '../PutawayList/PutawayUserSearchField'
import PutawayInformation from '../PutawayDetail/PutawayInformation'

function PutawayGeneral ({ isLoading, initialValues, onAssignTo }) {
  const lineItems = propOr([], 'lineItems', initialValues)
  return (
    <>
      <Box mt={3} mb={3}>
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={12} lg={9}>
            <PutawayInformation initialValues={initialValues} />
          </Grid>
          <Grid item={true} xs={12} lg={3}>
            <Card sx={{ height: '100%' }}>
              <CardHeader title="Assigned to" />
              <Divider />
              <CardContent sx={{ height: '100%' }}>
                <PutawayUserSearchField
                  name="assignedTo"
                  label="Assigned to"
                  api={API.V1_PUTAWAY_USERS_LIST}
                  onValueChange={value => onAssignTo(value)}
                  ListboxProps={{ 'data-cy': 'assignList' }}
                  data-cy="assignTo"
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Box mt={3} mb={3}>
        <LineItems isLoading={isLoading} lineItems={lineItems} />
      </Box>
    </>
  )
}
PutawayGeneral.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
  onAssignTo: PropTypes.func.isRequired
}
export default PutawayGeneral
