import { filter, fromPairs, isEmpty, map, pick, pipe, prop, toPairs } from 'ramda'
import { DEFAULT_SEARCH_PARAMS } from 'storfox-route-hooks'

import { orderingToSnackCase } from '~/utils'

import { CUSTOMER_UPDATE_FIELDS } from '../components/CustomerUpdate'
import { CUSTOMER_CREATE_FIELDS } from '../components/CustomerCreate'

const getNonEmptyAddresses = pipe(
  map(pipe(
    toPairs,
    filter(([, value]) => !isEmpty(value)),
    fromPairs
  )),
  filter(value => !isEmpty(value))
)
const defaultValue = [{ isPrimary: true }]

export const CustomerInitialSerializer = initialValues => {
  const deliveryAddresses = prop('deliveryAddresses', initialValues) || defaultValue
  const billingAddresses = prop('billingAddresses', initialValues) || defaultValue
  const bankAccounts = prop('bankAccounts', initialValues) || defaultValue

  return {
    ...initialValues,
    bankAccounts: bankAccounts.length ? bankAccounts : defaultValue,
    deliveryAddresses: deliveryAddresses.length ? deliveryAddresses : defaultValue,
    billingAddresses: billingAddresses.length ? billingAddresses : defaultValue
  }
}

export function CustomerCreateSerializer (formValues) {
  return pick(CUSTOMER_CREATE_FIELDS, formValues)
}

export function CustomerUpdateSerializer (formValues) {
  const fields = pick(CUSTOMER_UPDATE_FIELDS, formValues)

  const billingAddressesArray = prop('billingAddresses', fields)
  const deliveryAddressesArray = prop('deliveryAddresses', fields)

  const billingAddresses = getNonEmptyAddresses(billingAddressesArray)
  const deliveryAddresses = getNonEmptyAddresses(deliveryAddressesArray)

  return { ...fields, billingAddresses, deliveryAddresses }
}

export function CustomerFilterSerializer (params) {
  const defaultParams = pick(DEFAULT_SEARCH_PARAMS, params)
  const status = prop('status', params)
  const createdAtStart = prop('createdAtStart', params)
  const createdAtEnd = prop('createdAtEnd', params)
  const updatedAtStart = prop('updatedAtStart', params)
  const updatedAtEnd = prop('updatedAtEnd', params)

  return orderingToSnackCase({
    ...defaultParams,
    status,
    createdAtStart,
    createdAtEnd,
    updatedAtStart,
    updatedAtEnd
  })
}
