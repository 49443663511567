import React from 'react'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material'
import PropTypes from 'prop-types'
import Skeleton from '@mui/material/Skeleton'

import HtmlTooltip from '~/components/HtmlTooltip/HtmlTooltip'

export const BoxStyled = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
})

export const TitleStyled = styled(Typography)({
  color: '#546E7A',
  textTransform: 'uppercase',
  marginBottom: '8px',
  fontSize: '11px'
})

export const BoxIconStyled = styled(Box)({
  borderRadius: '50%',
  width: '40px',
  height: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
})

function FulfillmentTopInformation ({ title, value, icon, iconColor, isLoading, tooltip }) {
  return (
    <HtmlTooltip title={tooltip}>
      <Card sx={{ padding: '20px 16px' }}>
        <BoxStyled>
          {isLoading && (
            <Box sx={{ width: '70%' }}>
              <Skeleton sx={{ width: '50%', marginBottom: '19px' }} />
              <Skeleton />
            </Box>
          )}
          {!isLoading && (
            <Box>
              <TitleStyled variant="h6">{title}</TitleStyled>
              <Typography variant="h2">{value}</Typography>
            </Box>
          )}
          <BoxIconStyled sx={{ background: iconColor }}>
            {icon}
          </BoxIconStyled>
        </BoxStyled>
      </Card>
    </HtmlTooltip>
  )
}

FulfillmentTopInformation.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  iconColor: PropTypes.string,
  isLoading: PropTypes.bool,
  tooltip: PropTypes.string,
  icon: PropTypes.node
}

export default FulfillmentTopInformation
