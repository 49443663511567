import React from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { defaultTo, find, path, pipe, prop, propOr } from 'ramda'
import { useSnackbar } from 'storfox-snackbar'
import isEqual from 'react-fast-compare'

import { DashboardLayout } from '~/components/Layouts'
import { useCompany, useProfile } from '~/components/Profile'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import useMessages from '~/hooks/useMessages'
import { ADJUSTMENT_DETAIL_TABS } from '~/constants/tabs'

import { AdjustmentCreateForm } from '../components/AdjustmentCreate'
import {
  useAdjustmentCreate,
  useAdjustmentCreateAndComplete,
  useBarcodeCheck,
  useBarcodeGenerate,
  useDefaultConditionList,
  useUnitCheck,
  useAdjustmentVariant
} from '../hooks'
import {
  AdjustmentCreateSerializer,
  BarcodeCheckAddSerializer,
  BarcodeCheckRemoveSerializer,
  UnitCheckSerializer
} from '../serializers'
import { SAVE_COMPLETE } from '../constants'

const getDefaultWarehouse = (warehouses) => {
  return pipe(
    find(prop('isDefault')),
    defaultTo(propOr({}, 0, warehouses))
  )(warehouses)
}

const getInitialValues = warehouses => {
  const defaultWarehouse = getDefaultWarehouse(warehouses || [])

  const warehouse = defaultWarehouse && {
    id: defaultWarehouse.id,
    guid: defaultWarehouse.guid,
    name: defaultWarehouse.name,
  }

  return { warehouse }
}

function AdjustmentCreateContainer () {
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const messages = useMessages()
  const { company } = useCompany()
  const { profile } = useProfile()

  const barcodeGenerate = useBarcodeGenerate()
  const defaultConditionList = useDefaultConditionList()
  const unitCheck = useUnitCheck()
  const barcodeCheck = useBarcodeCheck()

  const adjustmentCreate = useAdjustmentCreate()
  const adjustmentCreateAndComplete = useAdjustmentCreateAndComplete()
  const adjustmentVariant = useAdjustmentVariant()

  const warehouses = prop('warehouses', profile)
  const companyId = prop('id', company)

  const title = 'New adjustment'

  const handleUnitCheck = (values, warehouseGuid) =>
    unitCheck.check(UnitCheckSerializer(values, warehouseGuid))

  const handleBarcodeCheck = values => {
    const Serializer = prop('removeOperation', values)
      ? BarcodeCheckRemoveSerializer
      : BarcodeCheckAddSerializer
    return barcodeCheck.check(Serializer(values))
  }

  const handleSubmit = formValue => {
    const submitType = propOr(SAVE_COMPLETE, 'submitType', formValue)
    if (submitType === SAVE_COMPLETE) {
      return adjustmentCreateAndComplete.create(AdjustmentCreateSerializer(formValue, companyId))
        .then(data => {
          const params = { id: path(['result', 'id'], data), tab: ADJUSTMENT_DETAIL_TABS.GENERAL }
          const redirect = generatePath(ROUTES.ADJUSTMENT_DETAIL_PATH, params)
          navigate(redirect)
        })
        .then(() => snackbar({ message: messages.NOTIFICATION_WAIT }))
    } else {
      return adjustmentCreate.create(AdjustmentCreateSerializer(formValue, companyId))
        .then(data => {
          const params = { id: path(['result', 'id'], data), tab: ADJUSTMENT_DETAIL_TABS.GENERAL }
          const redirect = generatePath(ROUTES.ADJUSTMENT_DETAIL_PATH, params)
          navigate(redirect)
        })
        .then(() => snackbar({ message: messages.CREATE_SUCCESS }))
    }
  }

  const handleGenerate = count => barcodeGenerate.generate({ count })

  const unitFieldMethods = {
    unitCheckLoading: unitCheck.isLoading || barcodeCheck.isLoading,
    handleUnitCheck,
    handleBarcodeCheck
  }

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      activeNav={NAV.ADJUSTMENT}
      title={title}
      breadcrumbs={breadcrumbs}
      isLoading={adjustmentCreate.isLoading}
    >
      <AdjustmentCreateForm
        pageTitle={title}
        pageTitleLoading={false}
        isLoading={adjustmentCreate.isLoading || adjustmentCreateAndComplete.isLoading}
        initialValues={getInitialValues(warehouses)}
        onSubmit={handleSubmit}
        onGenerate={handleGenerate}
        initialValuesEqual={isEqual}
        onConditionGet={defaultConditionList.getList}
        unitFieldMethods={unitFieldMethods}
        adjustmentVariant={adjustmentVariant}
      />
    </DashboardLayout>
  )
}

export default AdjustmentCreateContainer
