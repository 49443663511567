import React from 'react'
import PropTypes from 'prop-types'
import { path, prop } from 'ramda'
import Grid from '@mui/material/Grid'

import TextLabelLoader from '~/components/TextLabelLoader'

function DeliveryAddressInfo ({ address }) {
  const addressInfo = prop('address', address)
  const zipcode = prop('zipcode', address)
  const mobile = prop('mobile', address)
  const country = path(['country', 'name'], address)
  const region = prop('region', address)
  const city = prop('city', address)
  const email = prop('email', address)
  const latitude = prop('latitude', address)
  const longitude = prop('longitude', address)

  return (
    <Grid container={true} spacing={1}>
      <Grid item={true} xs={12}>
        <TextLabelLoader label="Email">
          {email}
        </TextLabelLoader>
      </Grid>
      <Grid item={true} xs={12}>
        <TextLabelLoader label="Address">
          {addressInfo}
        </TextLabelLoader>
      </Grid>
      <Grid item={true} xs={12}>
        <TextLabelLoader label="Region/Neighbouring/State">
          {region}
        </TextLabelLoader>
      </Grid>
      <Grid item={true} xs={12}>
        <TextLabelLoader label="City">
          {city}
        </TextLabelLoader>
      </Grid>
      <Grid item={true} xs={12}>
        <TextLabelLoader label="Zip code">
          {zipcode}
        </TextLabelLoader>
      </Grid>
      <Grid item={true} xs={12}>
        <TextLabelLoader label="Country">
          {country}
        </TextLabelLoader>
      </Grid>
      <Grid item={true} xs={12}>
        <TextLabelLoader label="Phone number">
          {mobile}
        </TextLabelLoader>
      </Grid>
      <Grid item={true} xs={6}>
        <TextLabelLoader label="Latitude">
          {latitude}
        </TextLabelLoader>
      </Grid>
      <Grid item={true} xs={6}>
        <TextLabelLoader label="Longitude">
          {longitude}
        </TextLabelLoader>
      </Grid>
    </Grid>
  )
}

DeliveryAddressInfo.propTypes = {
  address: PropTypes.object
}

export default DeliveryAddressInfo
