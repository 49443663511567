import { prop } from 'ramda'
import { toCamelCase } from 'storfox-api-hooks'

export const getSocketPayload = message => {
  const data = prop('data', message)
  const body = prop('body', message)
  const topic = prop('type', data)

  const payload = toCamelCase(JSON.parse(prop('payload', data)))

  return { topic, body, payload }
}
