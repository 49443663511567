import React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { path } from 'ramda'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import ErrorLink from '~/components/ErrorLink'
import { WAREHOUSE_TABS } from '~/constants/tabs'
import useMessages from '~/hooks/useMessages'

import ContainerDetail from '../components/ContainerDetail'
import { useContainerContent, useContainerDelete, useContainerDetail } from '../hooks'

function ContainerDetailContainer () {
  const navigate = useNavigate()
  const messages = useMessages()
  const { guid } = useParams()
  const containerDetail = useContainerDetail(guid)
  const containerContent = useContainerContent(guid)
  const containerDelete = useContainerDelete(guid)
  const snackbar = useSnackbar()

  const warehouseGuid = path(['warehouse', 'guid'], containerDetail.detail)
  const warehouseName = path(['warehouse', 'name'], containerDetail.detail)

  const title = path(['detail', 'number'], containerDetail)

  const handleDelete = () =>
    containerDelete.delete()
      .then(() => snackbar({ message: messages.DELETE_SUCCESS }))
      .then(() => {
        const params = { guid: warehouseGuid, tab: WAREHOUSE_TABS.CONTAINER }
        const path = generatePath(ROUTES.WAREHOUSE_DETAIL_PATH, params)
        navigate(path, { replace: true })
      })
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const breadcrumbs = {
    title,
    params: [
      { guid: warehouseGuid, tab: WAREHOUSE_TABS.CONTAINER, title: warehouseName }
    ]
  }

  return (
    <DashboardLayout
      title={title}
      isLoading={containerDetail.isLoading}
      activeNav={NAV.WAREHOUSE}
      breadcrumbs={breadcrumbs}
    >
      <ContainerDetail
        title={title}
        titleLoading={containerDetail.isLoading}
        isLoading={containerDetail.isLoading}
        detail={containerDetail.detail}
        onDelete={handleDelete}
        containerContent={containerContent}
      />
    </DashboardLayout>
  )
}

export default ContainerDetailContainer
