import React from 'react'
import { path, prop } from 'ramda'
import PropTypes from 'prop-types'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { useTranslation } from 'react-i18next'
import Badge from '@mui/material/Badge'
import IconButton from '@mui/material/IconButton'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import DeleteIcon from '@mui/icons-material/Delete'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'

import TextField from '~/components/Fields/TextField'
import Avatar from '~/components/Avatar/Avatar'
import VerticalAlignment from '~/components/VerticalAlignment'
import Subtext from '~/components/Subtext'
import ConstrainedField from '~/components/Fields/ConstrainedField'
import HtmlTooltip from '~/components/HtmlTooltip'
import { CardHeader } from '~/components/Cards'

function ProductPreviewCard (props) {
  const {
    product,
    fieldName,
    onBarcodeFocus,
    onActiveItemPut,
    error,
    onRemove,
    maxValue
  } = props

  const { t } = useTranslation()
  const name = path(['variant', 'name'], product)
  const image = path(['variant', 'imagePath'], product)
  const trackSerial = path(['variant', 'trackSerial'], product)
  const unitNumber = prop('unitNumber', product)

  const handleEnter = event => {
    if (event.key === 'Enter') {
      onBarcodeFocus()
      event.preventDefault()
    }
  }

  return (
    <Card>
      <CardHeader title="Active line item" />
      <Divider />
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t('Image')}</TableCell>
              <TableCell>{t('Product')}</TableCell>
              <TableCell>{t('Barcode')}</TableCell>
              <TableCell>{t('Serial number')}</TableCell>
              <TableCell>{t('Quantity')}</TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {product ? (
              <TableRow>
                <TableCell style={{ cursor: 'pointer' }}>
                  <Badge
                    variant="dot"
                    color="primary"
                  >
                    <Avatar src={image} alt="variant-preview" />
                  </Badge>
                </TableCell>
                <TableCell>
                  <VerticalAlignment
                    primary={name}
                    secondary={(
                      <Subtext lines={1}>{unitNumber}</Subtext>
                    )}
                  />
                </TableCell>
                <TableCell>{unitNumber}</TableCell>
                <TableCell>
                  <TextField
                    name={`${fieldName}.serialNumber`}
                    data-cy={`${fieldName}.serialNumber`}
                    fullWidth={true}
                    error={error}
                    helperText={error && 'Invalid serial number'}
                    onKeyDown={handleEnter}
                    disabled={!trackSerial}
                  />
                </TableCell>
                <TableCell>
                  <ConstrainedField
                    name={`${fieldName}.quantity`}
                    onKeyDown={handleEnter}
                    minValue={0}
                    maxValue={maxValue}
                    data-cy={`${fieldName}.quantity`}
                    disabled={trackSerial}
                  />
                </TableCell>
                <TableCell align="right">
                  <HtmlTooltip title="Put to destination.">
                    <IconButton onClick={onActiveItemPut} size="large">
                      <ExitToAppIcon />
                    </IconButton>
                  </HtmlTooltip>
                </TableCell>
                <TableCell align="right">
                  <IconButton onClick={onRemove} size="large">
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell style={{ textAlign: 'center' }} height={56} colSpan={8}>
                  Empty
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  )
}

ProductPreviewCard.propTypes = {
  fieldName: PropTypes.string.isRequired,
  onBarcodeFocus: PropTypes.func.isRequired,
  onActiveItemPut: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  product: PropTypes.object,
  maxValue: PropTypes.number,
  error: PropTypes.bool
}

export default ProductPreviewCard
