import React from 'react'
import { Ordering } from 'storfox-filter'

import { ORDER_UNITS_COLUMNS as tableColumns } from './OrderUnitsTable'

import { FILTER_NAME as name } from '../../constants/OrderUnits'

export const orderUnitsOrderingOptions = {
  name,
  tableColumns
}

function OrderUnitsOrderingForm (props) {
  return (
    <Ordering {...props} />
  )
}

export default OrderUnitsOrderingForm
