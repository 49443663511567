import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'storfox-snackbar'
import { useTranslation } from 'react-i18next'

import { DashboardLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'

import { ConditionCreateSerializer } from '../../serializers/Condition'
import { useConditionCreate } from '../../hooks/Condition'
import ConditionCreateForm from '../../components/Condition/ConditionCreateForm/ConditionCreateForm'

function ConditionCreateContainer () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const conditionCreate = useConditionCreate()

  const message = t('Successfully created')
  const handleSubmit = formValues =>
    conditionCreate.create(ConditionCreateSerializer(formValues))
      .then(() => navigate(ROUTES.CONDITION_LIST_PATH))
      .then(() => snackbar({ message }))

  const title = 'New Product Condition'

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      activeNav={NAV.CONFIGURATION}
      breadcrumbs={breadcrumbs}
    >
      <ConditionCreateForm
        title={title}
        onSubmit={handleSubmit}
        initialValues={{ color: '#000000' }}
        isLoading={conditionCreate.isLoading}
      />
    </DashboardLayout>
  )
}

export default ConditionCreateContainer
