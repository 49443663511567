import PropTypes from 'prop-types'
import React from 'react'
import { generatePath, Link as RouterLink } from 'react-router-dom'
import { prop, propOr } from 'ramda'
import Link from '@mui/material/Link'

import {
  PACKING_DETAIL_TABS,
  PICKLIST_TABS,
  PURCHASE_ORDER_DETAIL_TABS,
  PUTAWAY_DETAIL_TABS,
  SALE_RETURN_TABS,
  SALE_ORDER_DETAIL_TABS,
  SHIPMENT_DETAIL_TABS,
  MOVEMENT_DETAIL_TABS,
  ADJUSTMENT_DETAIL_TABS,
  TRANSFER_DETAIL_TABS
} from '~/constants/tabs'
import * as ROUTES from '~/constants/routes'

export const PURCHASE_ORDER = 'purchase_order'
export const ADJUSTMENT = 'adjustment'
export const SALE_ORDER = 'sale_order'
export const RECEIVE = 'receive'
export const PUTAWAY_V1 = 'putaway_v1'
export const PUTAWAY_V2 = 'putaway_v2'
export const STOCK_COUNT = 'stock_count'
export const MOVEMENT = 'movement'
export const TRANSFER = 'transfer'
export const PICKING = 'picking'
export const PACKING = 'packing'
export const SHIPMENT = 'shipment'
export const RETURN = 'return'

function ActionLink ({ name, type, id, guid }) {
  const routes = (id, guid) => ({
    [PURCHASE_ORDER]: {
      path: ROUTES.PURCHASE_ORDER_DETAIL_PATH,
      params: { guid, tab: PURCHASE_ORDER_DETAIL_TABS.GENERAL }
    },
    [ADJUSTMENT]: {
      path: ROUTES.ADJUSTMENT_DETAIL_PATH,
      params: { id, tab: ADJUSTMENT_DETAIL_TABS.GENERAL }
    },
    [SALE_ORDER]: {
      path: ROUTES.SALE_ORDER_DETAIL_PATH,
      params: { guid, tab: SALE_ORDER_DETAIL_TABS.GENERAL }
    },
    [TRANSFER]: {
      path: ROUTES.TRANSFER_DETAIL_PATH,
      params: { guid, tab: TRANSFER_DETAIL_TABS.GENERAL }
    },
    [PUTAWAY_V1]: {
      path: ROUTES.V1_PUTAWAY_LIST_PATH
    },
    [PUTAWAY_V2]: {
      path: ROUTES.V2_PUTAWAY_DETAIL_PATH,
      params: { guid, tab: PUTAWAY_DETAIL_TABS.GENERAL }
    },
    [RECEIVE]: {
      path: ROUTES.RECEIVE_LIST_PATH
    },
    [STOCK_COUNT]: {
      path: ROUTES.STOCK_COUNT_UPDATE_PATH,
      params: { guid }
    },
    [MOVEMENT]: {
      path: ROUTES.MOVEMENT_DETAIL_PATH,
      params: { id, tab: MOVEMENT_DETAIL_TABS.GENERAL }
    },
    [PICKING]: {
      path: ROUTES.PICKLIST_UPDATE_PATH,
      params: { guid, tab: PICKLIST_TABS.GENERAL }
    },
    [PACKING]: {
      path: ROUTES.PACKING_DETAIL_PATH,
      params: { id, tab: PACKING_DETAIL_TABS.GENERAL }
    },
    [SHIPMENT]: {
      path: ROUTES.SHIPMENT_DETAIL_PATH,
      params: { guid, tab: SHIPMENT_DETAIL_TABS.GENERAL }
    },
    [RETURN]: {
      path: ROUTES.RETURN_DETAIL_PATH,
      params: { guid, tab: SALE_RETURN_TABS.GENERAL }
    }
  })

  const route = prop(type, routes(id, guid))
  const path = prop('path', route)
  const params = propOr({}, 'params', route)
  const generatedPath = path && generatePath(path, params)

  return (
    generatedPath ? (
      <Link
        to={generatedPath}
        component={RouterLink}
        underline="always"
        target="_blank"
        rel="noopener noreferrer"
      >
        {name}
      </Link>
    ) : (
      <>{name}</>
    )
  )
}

ActionLink.propTypes = {
  guid: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
}

export default ActionLink
