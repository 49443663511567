import purchaseOrder from './modules/purchase-order'
import supplier from './modules/supplier'
import returns from './modules/return'

const purchasesRoutes = () => [
  ...purchaseOrder(),
  ...supplier(),
  ...returns()
]

export default purchasesRoutes
