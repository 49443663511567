import React, { useRef, useState } from 'react'
import ReactToPrint from 'react-to-print'
import { pipe, split, trim } from 'ramda'
import Print from '@mui/icons-material/Print'
import PropTypes from 'prop-types'

import { Button, DiscardButton } from '~/components/Buttons'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import * as NAV from '~/constants/nav-titles'
import { CODE_TYPE, BARCODE_TYPE } from '~/constants/barcode'

import CodeForm from './CodeForm'

const textToBarcode = pipe(
  trim,
  split(/\r?\n/)
)

function CodeGenerator ({ initialValues }) {
  const [items, setItems] = useState([])
  const [quantity, setQuantity] = useState('1')
  const [type, setType] = useState(CODE_TYPE.BARCODE)
  const [barcodeType, setBarcodeType] = useState(BARCODE_TYPE.CODE128)
  const [fontSize, setFontSize] = useState(CODE_TYPE.BARCODE)
  const componentRef = useRef(null)

  const disabled = items.length === 0

  const handleSubmit = values => {
    const newItems = values.barcodes ? textToBarcode(values.barcodes) : []

    setQuantity(values.quantity)
    setType(values.type)
    setBarcodeType(values.barcodeType)
    setItems(newItems)
    setFontSize(values.fontSize)

    return Promise.resolve()
  }

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={NAV.BARCODE}
          parentTitle={NAV.SETTINGS}
          rightButton={(
            <>
              <DiscardButton />
              <ReactToPrint
                trigger={() => (
                  <Button
                    disabled={disabled}
                    variant="contained"
                    type="submit"
                    startIcon={<Print />}
                  >
                    Print
                  </Button>
                )}
                content={() => componentRef.current}
              />
            </>
          )}
        />
      </Header>
      <Content>
        <CodeForm
          type={type}
          items={items}
          quantity={quantity}
          fontSize={fontSize}
          componentRef={componentRef}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          barcodeType={barcodeType}
        />
      </Content>
    </Container>
  )
}

CodeGenerator.propTypes = {
  initialValues: PropTypes.object
}

export default CodeGenerator
