import React from 'react'
import isEqual from 'react-fast-compare'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

import ReactPhoneInput from './PhoneInput'

import 'react-phone-input-2/lib/material.css'

function PhoneNumberField ({ name, ...props }) {
  return (
    <Field name={name}>
      {({ input, meta }) => {
        return (
          <ReactPhoneInput
            value={input.value}
            onChange={input.onChange}
            invalid={meta.invalid}
            helperText={meta.submitError || meta.error}
            {...props}
          />
        )
      }}
    </Field>
  )
}

PhoneNumberField.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
}

PhoneNumberField.defaultProps = {
  size: 'small',
  fullWidth: true,
  variant: 'outlined'
}

export default React.memo(PhoneNumberField, isEqual)
