import React from 'react'
import PropTypes from 'prop-types'
import { path } from 'ramda'
import Skeleton from '@mui/material/Skeleton'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'

import Money from '~/components/Money'
import { CardHeader } from '~/components/Cards'

function TplBillingAdhocTable ({ detail, isLoading }) {
  const price = path(['items', 0, 'price'], detail)
  return (
    <Grid container={true}>
      <Grid item={true} lg={4} xs={12}>
        <Card>
          <CardHeader title="Price" />
          <Divider />
          <CardContent>
            <Typography variant="body1">
              {!isLoading ? <Money value={price} /> : <Skeleton />}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

TplBillingAdhocTable.propTypes = {
  detail: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default TplBillingAdhocTable
