import React, { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import { LOT_SHEETS_DETAIL_TABS } from '~/constants/tabs'
import ErrorLink from '~/components/ErrorLink'
import useMessages from '~/hooks/useMessages'
import * as ROUTES from '~/constants/routes'

import LotSheetsDetailForm from '../components/LotSheetsDetail/LotSheetsDetailForm'
import {
  useLotSheetsDetail,
  useLotSheetsHistory,
  useLotScanReportGenerate,
  useLotSummaryReportGenerate,
  useLotDelete,
  useLotModify
} from '../hooks'

function LotSheetsDetailContainer () {
  const { guid, tab } = useParams()
  const snackbar = useSnackbar()
  const messages = useMessages()
  const navigate = useNavigate()

  const lotSheetsDetailList = useLotSheetsDetail(guid)
  const lotScanReportGenerate = useLotScanReportGenerate(guid)
  const lotSummaryReportGenerate = useLotSummaryReportGenerate(guid)
  const lotDelete = useLotDelete(guid)
  const lotModify = useLotModify(guid)
  const historyAutoSend = Boolean(guid && tab === LOT_SHEETS_DETAIL_TABS.HISTORY)

  const history = useLotSheetsHistory(guid, historyAutoSend)

  const handleScanReportGenerate = () =>
    lotScanReportGenerate.getDetail()
      .then(() => snackbar({ message: messages.GENERATE_SUCCESS }))
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
        return Promise.reject(error)
      })

  const handleSummaryReportGenerate = () =>
    lotSummaryReportGenerate.getDetail()
      .then(() => snackbar({ message: messages.GENERATE_SUCCESS }))
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
        return Promise.reject(error)
      })

  const onDelete = () =>
    lotDelete.delete()
      .then(() => navigate(ROUTES.LOT_SHEETS_LIST_PATH))
      .then(() => snackbar({ message: messages.GENERATE_SUCCESS }))
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
        return Promise.reject(error)
      })

  const onModify = useCallback((values) =>
    lotModify.update(values)
      .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
      .then(() => lotSheetsDetailList.getList())
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
        return Promise.reject(error)
      })
  , [lotModify, lotSheetsDetailList, messages, snackbar])

  const breadcrumbs = { title: NAV.LOT_SHEETS }
  const isLoading =
    lotSheetsDetailList.isLoading || lotScanReportGenerate.isLoading || lotSummaryReportGenerate.isLoading

  return (
    <DashboardLayout
      title={NAV.LOT_SHEETS}
      activeNav={NAV.LOT_SHEETS}
      isLoading={lotSheetsDetailList.isLoading}
      breadcrumbs={breadcrumbs}
    >
      <LotSheetsDetailForm
        pageTitle="Detail"
        lotSheetsDetailList={lotSheetsDetailList}
        isLoading={isLoading}
        guid={guid}
        history={history}
        onScanReportGenerate={handleScanReportGenerate}
        onSummaryReportGenerate={handleSummaryReportGenerate}
        onDelete={onDelete}
        onModify={onModify}
        modifyLoading={lotModify.isLoading}
      />
    </DashboardLayout>
  )
}

export default LotSheetsDetailContainer
