import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { Grid } from '@mui/material'
import Divider from '@mui/material/Divider'
import PropTypes from 'prop-types'
import { TextField } from 'storfox-form-fields'

import { CardHeader } from '~/components/Cards'
import ShipmentSearchField from '~/components/Fields/ShipmentSearchField'
import ShippingServiceField from '~/components/Fields/ShippingServiceField'
import TrackingNumber from '~/components/Fields/TrackingNumber'
import * as API from '~/constants/api'
import ShipmentFeeField from '~/components/Fields/ShipmentFeeField'

function ShipmentMethodCard ({ address, currency, ...props }) {
  return (
    <Card>
      <CardHeader title="Method" />
      <Divider />
      <CardContent>
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={12}>
            <ShipmentSearchField label="Carrier" />
          </Grid>
          <Grid item={true} xs={12}>
            <ShippingServiceField
              integrationName="shippingMethod"
              api={API.SHIPMENT_SERVICES}
              {...props}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <TrackingNumber
              integrationName="shippingMethod"
              serviceName="service"
            />
          </Grid>

          <Grid item={true} xs={12}>
            <ShipmentFeeField
              currency={currency}
              name="shippingPrice"
              serviceName="service"
            />
          </Grid>

          <Grid item={true} xs={12}>
            <TextField
              name="vehicleNumber"
              label="Vehicle plate number"
              inputProps={{ maxLength: 20 }}
            />
          </Grid>

        </Grid>
      </CardContent>
    </Card>
  )
}

ShipmentMethodCard.propTypes = {
  address: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired
}

export default ShipmentMethodCard
