import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'storfox-snackbar'
import { useNavigate, useParams } from 'react-router-dom'

import AppContainer from '~/components/AppContainer'
import * as ROUTES from '~/constants/routes'

import { useResetPassword } from '../hooks'
import ResetPassword from '../components/ResetPassword'

function ResetPasswordContainer () {
  const { t } = useTranslation()
  const resetPassword = useResetPassword()
  const snackbar = useSnackbar()
  const { token } = useParams()
  const navigate = useNavigate()

  const handleSubmit = formValues =>
    resetPassword.resetPassword({ token, ...formValues })
      .then(() => {
        const message = t('Password successfully changed.')
        snackbar({ message })
      })
      .then(() => {
        navigate(ROUTES.SIGN_IN)
      })

  return (
    <AppContainer title="Reset password">
      <ResetPassword
        onSubmit={handleSubmit}
        isLoading={resetPassword.isLoading}
      />
    </AppContainer>
  )
}

export default ResetPasswordContainer
