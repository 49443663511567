import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty, prop } from 'ramda'
import Typography from '@mui/material/Typography'
import { cdnImageFilter } from 'storfox-image-upload'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'

import Image from '~/components/Image/Image'

const ImageWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(5,1fr)',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'repeat(4,1fr)',
  },
  gap: '.8rem',
  '& > div:first-of-type': {
    gridColumn: '1/span 2',
    gridRow: '1/span 2',
    '& > div': {
      height: 'calc(200px + 0.8rem)',
      [theme.breakpoints.down('md')]: {
        height: '100%'
      },
    }
  }
}))

const EmptyImageWrapperStyled = styled(Box)({
  width: '100%',
  height: 200,
  color: '#9EA1B6',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px #ECECEC solid'
})

function MultiImagePreview ({ images, alt }) {
  const renderImage = images && !isEmpty(images)
  return (
    <>
      {renderImage
        ? (
          <ImageWrapperStyled>
            {images.map(image => {
              const src = cdnImageFilter(prop('imagePath', image))
              return (
                <Box key={src} position="relative" height={200} width="100%">
                  <Image src={src} alt={alt} />
                </Box>
              )
            })}
          </ImageWrapperStyled>
        ) : (
          <EmptyImageWrapperStyled>
            <Typography variant="subtitle1" color="inherit">
              No image
            </Typography>
          </EmptyImageWrapperStyled>
        )}
    </>
  )
}

MultiImagePreview.defaultProps = {
  alt: 'Image not loaded'
}

MultiImagePreview.propTypes = {
  images: PropTypes.array,
  alt: PropTypes.string
}

export default MultiImagePreview
