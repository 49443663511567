import React from 'react'
import { prop } from 'ramda'
import { MultiSelectSearchField } from 'storfox-form-fields'

import * as API from '~/constants/api'

const getOptionValue = value => {
  if (value) {
    const id = prop('guid', value)
    const name = prop('name', value)

    return { id, name }
  }

  return null
}

function PurchaseOrderVariantsField (props) {
  return (
    <MultiSelectSearchField
      api={API.VARIANT_LIST}
      data-cy="variants"
      name="variants"
      label="Variants"
      getOptionValue={getOptionValue}
      {...props}
    />
  )
}

export default PurchaseOrderVariantsField
