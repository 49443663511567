import React from 'react'

import SelectField from '~/components/Fields/SelectField'

const OPTIONS = [
  {
    value: 'Normal',
    name: 'General goods without electricity'
  },
  {
    value: 'Sensitive',
    name: 'Sensitive goods (cosmetics/liquids, etc.)'
  },
  {
    value: 'Battery',
    name: 'Live goods'
  },
  {
    value: 'PureBattery',
    name: 'Pure battery/charging treasure'
  }
]

function BatteryTypeField (props) {
  return (
    <SelectField
      label="Batter type"
      options={OPTIONS}
      {...props}
    />
  )
}

export default BatteryTypeField
