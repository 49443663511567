import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import { isEmpty, not } from 'ramda'

import Button from '~/components/Buttons/Button'

import DiscardButton from '../../Buttons/DiscardButton'
import { ActionsStyled, ContentStyled, FormStyled } from '../../Filter.styled'

function FilterForm (props) {
  const {
    onSubmit,
    onClose,
    initialValues,
    validate,
    children
  } = props

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={validate}
      render={({ handleSubmit, errors }) => (
        <FormStyled onSubmit={handleSubmit}>
          <ContentStyled>
            {children}
          </ContentStyled>
          <ActionsStyled>
            <DiscardButton onClick={onClose} />
            <Button
              type="submit"
              variant="contained"
              fullWidth={true}
              disabled={not(isEmpty(errors))}
              data-cy="applyFilters"
            >
              Apply filters
            </Button>
          </ActionsStyled>
        </FormStyled>
      )}
    />
  )
}

FilterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  validate: PropTypes.func,
  children: PropTypes.node.isRequired
}

export default FilterForm
