import React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { propOr } from 'ramda'

import Tabs from '~/components/Tabs'
import Divider from '~/components/Divider'
import { SALE_ORDER_DETAIL_PATH } from '~/constants/routes'
import { SALE_ORDER_DETAIL_TABS } from '~/constants/tabs'
import { useCompany } from '~/components/Profile'
import LabelWithChip from '~/components/Tabs/LabelWithChip'

const getTabs = (isRetailer, detail, tab) => {
  const picklistCount = propOr(0, 'picklistCount', detail)
  const packingCount = propOr(0, 'packingCount', detail)
  const shipmentCount = propOr(0, 'shipmentCount', detail)
  const returnCount = propOr(0, 'returnCount', detail)
  const putawayCount = propOr(0, 'putawayCount', detail)
  const lineItems = propOr([], 'lineItems', detail)

  const totalReservedFor = lineItems.reduce((prev, current) => {
    const reserved = propOr(0, 'reserved', current)
    return prev + reserved
  }, 0)

  const general = { value: SALE_ORDER_DETAIL_TABS.GENERAL, label: 'General' }
  const notes = { value: SALE_ORDER_DETAIL_TABS.NOTES, label: 'Notes' }

  const unit = {
    value: SALE_ORDER_DETAIL_TABS.UNIT,
    label: (
      <LabelWithChip
        value={SALE_ORDER_DETAIL_TABS.UNIT}
        chipLabel={totalReservedFor}
        label="Unit"
        activeTab={tab}
      />
    )
  }

  const picklist = {
    value: SALE_ORDER_DETAIL_TABS.PICKLIST,
    label: (
      <LabelWithChip
        value={SALE_ORDER_DETAIL_TABS.PICKLIST}
        chipLabel={picklistCount}
        label="Picklist"
        activeTab={tab}
      />
    )
  }

  const packing = {
    value: SALE_ORDER_DETAIL_TABS.PACKING,
    label: (
      <LabelWithChip
        value={SALE_ORDER_DETAIL_TABS.PACKING}
        chipLabel={packingCount}
        label="Packing"
        activeTab={tab}
      />
    )
  }

  const shipment = {
    value: SALE_ORDER_DETAIL_TABS.SHIPMENT,
    label: (
      <LabelWithChip
        value={SALE_ORDER_DETAIL_TABS.SHIPMENT}
        chipLabel={shipmentCount}
        label="Shipment"
        activeTab={tab}
      />
    )
  }

  const returnTab = {
    value: SALE_ORDER_DETAIL_TABS.RETURN,
    label: (
      <LabelWithChip
        value={SALE_ORDER_DETAIL_TABS.RETURN}
        chipLabel={returnCount}
        label="Return"
        activeTab={tab}
      />
    )
  }

  const putaway = {
    value: SALE_ORDER_DETAIL_TABS.PUTAWAY,
    label: (
      <LabelWithChip
        value={SALE_ORDER_DETAIL_TABS.PUTAWAY}
        chipLabel={putawayCount}
        label="Putaway"
        activeTab={tab}
      />
    )
  }

  const history = { value: SALE_ORDER_DETAIL_TABS.HISTORY, label: 'History' }

  if (isRetailer) {
    return [
      general,
      notes,
      unit,
      returnTab,
      putaway,
      history
    ]
  }

  return [
    general,
    notes,
    unit,
    picklist,
    packing,
    shipment,
    returnTab,
    putaway,
    history
  ]
}

function SaleOrderDetailTabs ({ value, detail }) {
  const navigate = useNavigate()
  const { guid, tab } = useParams()
  const { isRetailer } = useCompany()
  const tabs = getTabs(isRetailer, detail, tab)

  const onChange = (event, tab) => {
    const redirect = generatePath(SALE_ORDER_DETAIL_PATH, { guid, tab })
    navigate(redirect, { replace: true })
  }

  return (
    <>
      <Tabs
        tabs={tabs}
        value={value}
        onChange={onChange}
      />
      <Divider />
    </>
  )
}

SaleOrderDetailTabs.propTypes = {
  value: PropTypes.string.isRequired,
  detail: PropTypes.object.isRequired
}

export default SaleOrderDetailTabs
