import React from 'react'
import { generatePath } from 'react-router-dom'
import PropTypes from 'prop-types'

import BreadcrumbLink from './BreadcrumbLink'

const BreadcrumbDynamicLink = ({ href, param }) => {
  const { title, ...params } = param

  const path = param.id || param.guid ? generatePath(href, params) : ''

  return <BreadcrumbLink href={path} title={title} />
}

BreadcrumbDynamicLink.propTypes = {
  param: PropTypes.object.isRequired,
  href: PropTypes.string.isRequired,
}

export default BreadcrumbDynamicLink
