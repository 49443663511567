import { SUPPLIER_UPDATE_TABS } from '~/constants/tabs'

const DELIVERY_ADDRESS_KEY = 'deliveryAddresses'
const BILLING_ADDRESS_KEY = 'billingAddresses'
const BANK_ACCOUNT_KEY = 'bankAccounts'

export const TABS_WITH_KEYS = [
  {
    key: '',
    tab: SUPPLIER_UPDATE_TABS.GENERAL
  },
  {
    key: DELIVERY_ADDRESS_KEY,
    tab: SUPPLIER_UPDATE_TABS.ADDRESS
  },
  {
    key: BILLING_ADDRESS_KEY,
    tab: SUPPLIER_UPDATE_TABS.ADDRESS
  },
  {
    key: BANK_ACCOUNT_KEY,
    tab: SUPPLIER_UPDATE_TABS.BANK_ACCOUNT
  }
]

export const FILTER_NAME = 'supplier'
