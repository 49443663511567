import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

import SelectField from './SelectField'

function SelectPostField ({ isLoading, ...props }) {
  return (
    <Box position="relative">
      <SelectField {...props} />
      {isLoading && (
        <CircularProgress
          size={20}
          sx={{
            position: 'absolute',
            right: 35,
            top: '25%'
          }}
        />
      )}
    </Box>
  )
}

SelectPostField.propTypes = {
  name: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
}

export default SelectPostField
