import React from 'react'
import PropTypes from 'prop-types'

import { RECEIVING_STATUSES } from '~/constants/statuses'

import Status from '../Status/Status'

const COLOR = {
  [RECEIVING_STATUSES.NEW]: 'error',
  [RECEIVING_STATUSES.IN_PROGRESS]: 'warning',
  [RECEIVING_STATUSES.COMPLETED]: 'success',
}

function ReceivingStatus ({ value }) {
  return (
    <Status label={value} color={COLOR[value]} />
  )
}

ReceivingStatus.propTypes = {
  value: PropTypes.oneOf([
    RECEIVING_STATUSES.NEW,
    RECEIVING_STATUSES.IN_PROGRESS,
    RECEIVING_STATUSES.COMPLETED,
  ]).isRequired
}

export default ReceivingStatus
