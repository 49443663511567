import { createTheme } from '@mui/material/styles'

import lightPalette from './light/palette'
import lightTypography from './light/typography'
import lightComponents from './light/componentOverrides'
import darkPalette from './dark/palette'
import darkTypography from './dark/typography'
import darkComponents from './dark/componentOverrides'

export const createDarkTheme = main => {
  const mainColor = main || darkPalette.primary.main
  const darkTheme = {
    palette: { ...darkPalette, mode: 'dark', primary: { ...darkPalette.primary, main: mainColor } },
    typography: darkTypography,
    components: darkComponents(mainColor)
  }

  return createTheme(darkTheme)
}

export const createLightTheme = main => {
  const mainColor = main || lightPalette.primary.main
  const lightTheme = {
    palette: { ...lightPalette, mode: 'light', primary: { ...lightPalette.primary, main: mainColor } },
    typography: lightTypography,
    components: lightComponents(mainColor)
  }

  return createTheme(lightTheme)
}
