import PropTypes from 'prop-types'
import React, { useCallback, useEffect } from 'react'
import { Paper, styled } from '@mui/material'

import GraphButtons from './GraphButtons'
import { initializeGraph, removeGraph } from './utils/graph'
import {
  defineArrowDefs,
  defineFilters,
  initializeContainer,
  initializeDifference,
  initializeLocations,
  initializeQuantity,
  initializeReservedFor
} from './utils/initializers'
import UnitLoader from './UnitLoader'

const RootPaperStyled = styled(Paper)({
  minHeight: 600,
  height: '100%',
  position: 'relative',
  '& .node-group': {
    '&:hover': {
      '& .node': {
        fill: '#dedfe1',
        cursor: 'pointer'
      }
    }
  },
  '& .node': {
    fill: 'white',
    stroke: '#e0e0e0',
    transition: '.25s ease all'
  },
  '& .node-active': {
    stroke: '#000'
  },
  '& .line': {
    stroke: '#6A7793',
    strokeWidth: 3
  },
  '& .arrow': {
    fill: '#6A7793'
  }
})

function UnitGraph ({ list, isLoading, onUnitClick }) {
  const handleUnitClick = useCallback(onUnitClick, [])

  const handleGraphCreate = useCallback(() => {
    const { nodeGroup, svg } = initializeGraph(list, handleUnitClick)
    defineFilters(svg)
    defineArrowDefs(svg)
    initializeLocations(nodeGroup)
    initializeQuantity(nodeGroup)
    initializeReservedFor(nodeGroup)
    initializeDifference(nodeGroup)
    initializeContainer(nodeGroup)
  }, [list, handleUnitClick])

  const handleGraphReset = event => {
    event.preventDefault()
    event.stopPropagation()
    removeGraph()
    handleGraphCreate()
  }

  useEffect(() => {
    handleGraphCreate()

    return () => {
      removeGraph()
    }
  }, [handleGraphCreate])

  return (
    <RootPaperStyled className="svg-container">
      {isLoading && <UnitLoader />}
      <GraphButtons onReset={handleGraphReset} />
    </RootPaperStyled>
  )
}

UnitGraph.propTypes = {
  list: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onUnitClick: PropTypes.func.isRequired
}

export default UnitGraph
