import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import ArrowBackIos from '@mui/icons-material/ArrowBackIos'

import Button from '../Buttons/Button'

function DiscardButton ({ children, to, ...props }) {
  const navigate = useNavigate()

  return (
    <Button
      type="button"
      variant="outlined"
      data-cy="discard"
      onClick={() => to ? navigate(to) : navigate(-1)}
      startIcon={<ArrowBackIos />}
      {...props}
    >
      {children}
    </Button>
  )
}

DiscardButton.propTypes = {
  children: PropTypes.any.isRequired,
  to: PropTypes.string,
}

DiscardButton.defaultProps = {
  children: 'Discard',
}

export default DiscardButton
