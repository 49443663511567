import palette from '~/theme/dark/palette'

export default {
  styleOverrides: {
    root: {
      '&.Mui-selected': {
        color: palette.text.primary
      }
    }
  }
}
