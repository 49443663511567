import React from 'react'

import MultiSelectSearchField from '~/components/Fields/MultiSelectSearchField'
import * as API from '~/constants/api'

function ReasonMultiSearchField (props) {
  return (
    <MultiSelectSearchField
      api={API.ADJUSTMENT_REASON_LIST}
      data-cy="reasons"
      name="reasons"
      label="Reasons"
      {...props}
    />
  )
}

export default ReasonMultiSearchField
