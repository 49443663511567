import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useField } from 'react-final-form'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import { path, pathOr } from 'ramda'

import PageTitle from '~/components/PageTitle'
import { DiscardButton, SaveButton } from '~/components/Buttons'
import Container, { Content, Header } from '~/components/Container'
import { withForm } from '~/components/Form'
import * as NAV from '~/constants/nav-titles'
import { CardHeader } from '~/components/Cards'
import WarehouseSearchField from '~/components/Fields/WarehouseSearchField'
import * as API from '~/constants/api'
import TextField from '~/components/Fields/TextField'
import TagsField from '~/components/Fields/TagsField'
import CurrencyField from '~/components/Fields/CurrencyField'
import { useCompany } from '~/components/Profile'
import MoneyField from '~/components/Fields/MoneyField'
import DateField from '~/components/Fields/DateField'
import FileAttachment from '~/components/FileAttachment/FileAttachment'

import SaleOrderCustomerInfo from '../CustomerForm/SaleOrderCustomerInfo'
import ChannelField from '../Fields/ChannelField'
import LineItems from '../SaleOrderForm/LineItems'
import InvoiceSummary from '../InvoiceSummary'
import PaymentTypeField from '../Fields/PaymentTypeField'
import SaleOrderDeliveryMethodField from '../Fields/SaleOrderDeliveryMethodField'
import CompanySearchField from '../Fields/CompanySearchField'

export const SALE_ORDER_CREATE_FIELDS = [
  'attachmentUrl',
  'trackingNumber',
  'lineItems',
  'customerNotes',
  'customer',
  'deliveryAddress',
  'billingAddress',
  'channel',
  'orderStatus',
  'paymentType',
  'tags',
  'warehouse',
  'notify',
  'referenceNumber',
  'deliveryMethod',
  'currency',
  'shippingFee',
  'extraFees',
  'awbUrl',
  'commercialInvoiceUrl',
  'dueDate',
  'trackingNumber',
  'groupNumber',
  'company',
  'totalPrice'
]

function SaleOrderCreateForm (props) {
  const {
    pageTitle,
    pageTitleLoading,
    isLoading,
    onCustomerCreate,
    saleOrderVariant,
    onGetOrderCondition,
    form
  } = props

  const { handleSubmit } = form
  const [state, setState] = useState('')

  const company = useCompany()
  const companyCurrency = path(['company', 'currency'], company)

  const lineItemsField = useField('lineItems')
  const lineItems = lineItemsField.input.value || []

  const attachmentUrlField = useField('attachmentUrl')
  const attachmentUrlChange = attachmentUrlField.input.onChange

  const shippingFeeField = useField('shippingFee')
  const shippingFee = shippingFeeField.input.value || 0

  const currencyField = useField('currency')
  const currency = currencyField.input.value || companyCurrency

  const extraFeesField = useField('extraFees')
  const extraFees = extraFeesField.input.value || 0

  const companyField = useField('company')
  const companyGuid = pathOr('', ['input', 'value', 'guid'], companyField)

  useEffect(() => {
    attachmentUrlChange(state)
  }, [attachmentUrlChange, state])

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Container>
        <Header>
          <PageTitle
            pageTitle={pageTitle}
            pageTitleLoading={pageTitleLoading}
            parentTitle={NAV.SALE_ORDERS}
            rightButton={(
              <>
                <DiscardButton disabled={isLoading} />
                <SaveButton disabled={isLoading} />
              </>
            )}
          />
        </Header>

        <Content>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12} lg={8}>
              <Card sx={{ height: '100%' }}>
                <CardHeader title="Customer" required={true} />
                <CardContent sx={{ height: 'calc(100% - 52px)' }}>
                  <SaleOrderCustomerInfo onCustomerCreate={onCustomerCreate} />
                </CardContent>
              </Card>
            </Grid>

            <Grid item={true} xs={12} lg={4}>
              <Card style={{ height: '100%' }}>
                <CardHeader title="Details" />
                <Divider />
                <CardContent style={{ height: '100%' }}>
                  <Grid container={true} spacing={3}>
                    <Grid item={true} xs={12}>
                      <WarehouseSearchField
                        api={API.SALE_ORDER_WAREHOUSE}
                        data-cy="warehouse"
                        ListboxProps={{ 'data-cy': 'warehouseList' }}
                        required={true}
                      />
                    </Grid>
                    <Grid item={true} xs={12}>
                      <CompanySearchField
                        data-cy="company"
                        ListboxProps={{ 'data-cy': 'companyList' }}
                        required={true}
                      />
                    </Grid>

                    <Grid item={true} xs={12}>
                      <SaleOrderDeliveryMethodField />
                    </Grid>

                    <Grid item={true} xs={12}>
                      <ChannelField ListboxProps={{ 'data-cy': 'channelList' }} params={{ companyGuid }} />
                    </Grid>

                    <Grid item={true} xs={12}>
                      <CurrencyField value={currency} />
                    </Grid>

                    <Grid item={true} xs={12}>
                      <PaymentTypeField />
                    </Grid>

                    <Grid item={true} xs={12}>
                      <TextField
                        data-cy="referenceNumber"
                        name="referenceNumber"
                        label="Reference number"
                      />
                    </Grid>

                    <Grid item={true} xs={12}>
                      <TagsField
                        data-cy="tags"
                        name="tags"
                        label="Tags"
                      />
                    </Grid>

                    <Grid item={true} xs={12}>
                      <MoneyField
                        name="shippingFee"
                        currency={currency}
                        label="Shipping fee"
                      />
                    </Grid>

                    <Grid item={true} xs={12}>
                      <MoneyField
                        name="extraFees"
                        currency={currency}
                        label="Extra fee"
                      />
                    </Grid>

                    <Grid item={true} xs={12}>
                      <DateField
                        data-cy="dueDate"
                        name="dueDate"
                        label="Promise Date"
                      />
                    </Grid>

                    <Grid item={true} xs={12}>
                      <TextField
                        data-cy="trackingNumber"
                        name="trackingNumber"
                        label="Tracking number"
                      />
                    </Grid>

                    <Grid item={true} xs={12}>
                      <FileAttachment
                        name="attachmentUrl"
                        onUpload={setState}
                        onRemove={() => setState('')}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

            </Grid>

            <Grid item={true} xs={12}>
              <Card>
                <CardHeader title="Line items" required={true} />
                <Divider />
                <LineItems
                  currency={currency}
                  isLoading={false}
                  saleOrderVariant={saleOrderVariant}
                  onGetOrderCondition={onGetOrderCondition}
                />
              </Card>
            </Grid>

            <Grid item={true} xs={12} lg={6}>
              <Card>
                <CardHeader title="Invoice summary" />
                <Divider />
                <InvoiceSummary
                  currency={currency}
                  lineItems={lineItems}
                  shippingFee={shippingFee}
                  extraFees={extraFees}
                  totalPrice={0}
                  updated={true}
                />
              </Card>
            </Grid>

            <Grid item={true} xs={12} lg={6}>
              <Card>
                <CardHeader title="Customer notes" />
                <Divider />
                <CardContent>
                  <TextField
                    label="Customer notes"
                    name="customerNotes"
                    multiline={true}
                    minRows={8}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Content>
      </Container>
    </form>
  )
}

SaleOrderCreateForm.propTypes = {
  pageTitle: PropTypes.node.isRequired,
  pageTitleLoading: PropTypes.bool,
  form: PropTypes.object,
  onCustomerCreate: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  saleOrderVariant: PropTypes.object.isRequired,
  onGetOrderCondition: PropTypes.func
}

export default withForm(SaleOrderCreateForm)
