import React from 'react'
import { useTranslation } from 'react-i18next'
import SelectField from 'storfox-form-fields/src/SelectField'

export const SIX_HOURS = '1'
export const SAME_DAY = '2'

const options = [
  { value: SIX_HOURS, name: '6 hours' },
  { value: SAME_DAY, name: 'Same day' },
]

function SameDayTimingField ({ ...props }) {
  const { t } = useTranslation()

  return (
    <SelectField
      name="configuration.sameDayTimingId"
      label={t('Same day timing')}
      options={options}
      {...props}
    />
  )
}

export default SameDayTimingField
