import { useCallback, useState } from 'react'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import { toBase64 } from '../utils'

export function useSingleUpload ({ onUpload, accept }) {
  const [file, setFile] = useState({})
  const snackbar = useSnackbar()

  const handleUpload = useCallback(acceptedFiles => {
    const incorrectType = accept ? acceptedFiles.find((file) => !accept.includes(file.type)) : null
    if (!incorrectType) {
      const newItem = {
        obj: acceptedFiles[0],
        src: null,
        start: true,
        finish: false,
        progress: 0
      }

      setFile(newItem)

      toBase64(newItem.obj)
        .then(src => setFile(file => ({ ...file, src })))

      const data = new FormData()
      data.append('files', newItem.obj)
      data.append('resize', 'true')

      onUpload(data, progressEvent => {
        const loaded = progressEvent.loaded
        const total = progressEvent.total
        const progress = Math.round((loaded * 100) / total)
        setFile(file => ({ ...file, progress }))
      })
        .then(() => {
          setFile(file => ({ ...file, finish: true }))
        })
    } else {
      snackbar({ message: 'Incorrect upload type', type: ALTER_ERROR })
    }
  }, [accept, onUpload, snackbar])

  return [handleUpload, file, setFile]
}
