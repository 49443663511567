import React from 'react'
import PropTypes from 'prop-types'
import { prop } from 'ramda'
import Box from '@mui/material/Box'

import useDialog from '~/hooks/useDialog'

import useStorfoxHistory from './hooks'
import HistoryTable from './HistoryTable'
import HistoryChangesDialog from './HistoryChangesDialog'

function History ({ history }) {
  const rows = prop('results', history)
  const isLoading = prop('isLoading', history)
  const count = prop('count', history)

  const { open, handleClose, handleOpen } = useDialog()
  const { changeList, changesHandler } = useStorfoxHistory()

  const handleChangesOpen = values => {
    changesHandler(values)
    handleOpen()
  }

  return (
    <Box width="100%">
      <HistoryTable
        rows={rows}
        count={count}
        isLoading={isLoading}
        onClick={handleChangesOpen}
      />
      <HistoryChangesDialog
        open={open}
        oldChange={changeList.oldChanges}
        newChange={changeList.newChanges}
        onClose={handleClose}
      />
    </Box>
  )
}

History.propTypes = {
  history: PropTypes.object
}
export default History
