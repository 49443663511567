import React from 'react'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'

function OrderSplitSkeleton () {
  return <>
    <Grid item={true} sm={12} lg={6}>
      <Skeleton width="100%" variant="rectangular" height="40px" />
    </Grid>
    <Grid item={true} sm={12} lg={6}>
      <Skeleton width="100%" variant="rectangular" height="40px" />
    </Grid>
  </>
}

export default OrderSplitSkeleton
