import React from 'react'
import PropTypes from 'prop-types'
import { Bar } from 'react-chartjs-2'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, LinearScale, Title, Tooltip } from 'chart.js'

ChartJS.register(BarElement, Title, ArcElement, CategoryScale, Tooltip, LinearScale)

function PackerChart ({ data: dataProp, labels, ...props }) {
  const theme = useTheme()

  const data = {
    datasets: [
      {
        backgroundColor: '#FFA12D',
        data: dataProp,
        barThickness: 12,
        maxBarThickness: 12
      }
    ],
    labels
  }

  const options = {
    indexAxis:'y',
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    cornerRadius: 20,
    layout: {
      padding: 0
    },
    plugins: {
      legend: false,
      tooltip: {
        enabled: true,
        mode: 'index',
        intersect: false,
        caretSize: 10,
        padding: {
          x: 20,
          y: 20
        },
        borderWidth: 1,
        borderColor: theme.palette.divider,
        backgroundColor: theme.palette.common.white,
        titleColor: theme.palette.text.primary,
        bodyColor: theme.palette.text.secondary,
        footerColor: theme.palette.text.secondary,
        callbacks: {
          title: () => {},
          label: tooltipItem => {
            const value = tooltipItem.formattedValue

            return value > 1 ? `${value} units` : `${value} unit`
          }
        }
      }
    },
    scales: {
      x: {
        grid: {
          borderDash: [2],
          borderDashOffset: [2],
          color: '#BDBDBD',
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: '#BDBDBD'
        },
        ticks: {
          padding: 20,
          fontColor: theme.palette.text.secondary
        }
      },
      y: {
        grid: {
          borderDash: [2],
          borderDashOffset: [2],
          color: '#BDBDBD',
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: '#BDBDBD'
        },
        ticks: {
          padding: 20,
          fontColor: theme.palette.text.secondary,
          beginAtZero: true,
          min: 0,
          maxTicksLimit: 5
        }
      }
    },

  }

  return (
    <Box position="relative" {...props}>
      <Bar
        data={data}
        options={options}
      />
    </Box>
  )
}

PackerChart.propTypes = {
  data: PropTypes.array.isRequired,
  labels: PropTypes.array.isRequired
}

export default PackerChart
