import React from 'react'
import { path, prop } from 'ramda'
import { useSnackbar } from 'storfox-snackbar'
import { useTranslation } from 'react-i18next'

import * as NAV from '~/constants/nav-titles'
import { DashboardLayout } from '~/components/Layouts'
import { useMe, useProfile } from '~/components/Profile'
import { set as setLanguage } from '~/components/Language'

import { ProfileUpdateForm } from '../components/ProfileUpdate'
import { useProfileDetail, useProfileUpdate } from '../hooks'
import { ProfileUpdateSerializer } from '../serializers'

const getInitialValues = (value, language) => ({ ...value, language, is_2faEnabled: prop('is2faEnabled', value) })

function ProfileUpdateContainer () {
  const { t } = useTranslation()
  const { i18n } = useTranslation()
  const { setProfile } = useProfile()
  const { getMe } = useMe()
  const profileDetail = useProfileDetail()
  const id = path(['detail', 'id'], profileDetail)

  const showSnackbar = useSnackbar()
  const userUpdate = useProfileUpdate(id)

  const message = t('Successfully updated')

  const handleSubmit = formValues =>
    userUpdate.update(ProfileUpdateSerializer(formValues))
      .then(() => getMe())
      .then(({ result }) => {
        const language = prop('language', result)
        setProfile(result)
        i18n.changeLanguage(language)
          .then(() => {
            setLanguage(language)
          })
      })
      .then(() => showSnackbar({ message }))

  const firstName = path(['detail', 'firstName'], profileDetail)
  const lastName = path(['detail', 'lastName'], profileDetail)
  const title = profileDetail.isLoading ? 'Users' : `${firstName} ${lastName}`
  const pageLoading = profileDetail.isLoading || userUpdate.isLoading

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      activeNav={NAV.USERS}
      isLoading={pageLoading}
      breadcrumbs={breadcrumbs}
    >
      <ProfileUpdateForm
        title={title}
        pageTitleLoading={profileDetail.isLoading}
        initialValues={getInitialValues(profileDetail.detail, i18n.language)}
        onSubmit={handleSubmit}
        isLoading={userUpdate.isLoading}
      />
    </DashboardLayout>
  )
}

export default ProfileUpdateContainer
