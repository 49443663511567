import React from 'react'

import SelectField from '~/components/Fields/SelectField'

const OPTIONS = [
]
function ContainerTypeStatusField (props) {
  return (
    <SelectField
      name="status"
      label="Status"
      options={OPTIONS}
      {...props}
    />
  )
}

export default ContainerTypeStatusField
