import React from 'react'
import PropTypes from 'prop-types'

import TextLabelLoader from '~/components/TextLabelLoader'
import EmptyContent from '~/components/EmptyContent/EmptyContent'
import { EmptyNotesIcon } from '~/components/Icons'

function Notes (props) {
  const {
    notes,
    emptyText = 'Notes not found.'
  } = props

  return (
    <>
      {notes
        ? (
          <TextLabelLoader label="Notes">
            {notes}
          </TextLabelLoader>
        )
        : (
          <EmptyContent
            icon={<EmptyNotesIcon />}
            text={emptyText}
          />
        )}
    </>

  )
}

Notes.propTypes = {
  notes: PropTypes.string,
  emptyText: PropTypes.string
}

export default Notes
