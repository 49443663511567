import React from 'react'
import PropTypes from 'prop-types'
import { useField } from 'react-final-form'
import { prop } from 'ramda'

import ZoneField from '~/components/Fields/ZoneField'
import * as API from '~/constants/api'

function WarehouseField ({ name, areaName, ...props }) {
  const areaField = useField(areaName)
  const area = areaField.input.value
  const areaGuid = prop('guid', area)

  return (
    <ZoneField
      name={name}
      area={areaName}
      disabled={!areaGuid}
      api={`${API.BILLING_PROFILE_ZONE_LIST}?area_guid=${areaGuid}`}
      {...props}
    />
  )
}

WarehouseField.propTypes = {
  name: PropTypes.string.isRequired,
  areaName: PropTypes.string.isRequired
}

export default WarehouseField
