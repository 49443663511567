import React from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Container, { Content, Header } from '~/components/Container'
import PageTitle from '~/components/PageTitle'
import * as NAV from '~/constants/nav-titles'
import { Button, DiscardButton } from '~/components/Buttons'
import { LOT_SHEETS_DETAIL_TABS } from '~/constants/tabs'

import LotSheetsHistory from './LotSheetsHistory'
import LotSheetsDetailTabs from './LotSheetsDetailTabs'
import LotSheetsDetailGeneral from './LotSheetsDetailGeneral'

function LotSheetsDetailForm (props) {
  const {
    lotSheetsDetailList,
    pageTitle,
    isLoading,
    guid,
    history,
    onScanReportGenerate,
    onSummaryReportGenerate,
    onDelete,
    onModify,
    modifyLoading
  } = props
  const { t } = useTranslation()
  const { tab } = useParams()

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={pageTitle}
          pageTitleLoading={isLoading}
          parentTitle={NAV.LOT_SHEETS}
          rightButton={(
            <>
              <DiscardButton disabled={isLoading} />
              <Button
                variant="contained"
                data-cy="create"
                type="button"
                onClick={onDelete}
              >
                {t('Delete')}
              </Button>
              <Button
                variant="contained"
                disabled={isLoading}
                type="button"
                data-cy="done"
                onClick={onScanReportGenerate}
              >
                Lot scan report
              </Button>
              <Button
                variant="contained"
                disabled={isLoading}
                type="button"
                data-cy="done"
                onClick={onSummaryReportGenerate}
              >
                Lot summary report
              </Button>
            </>
          )}
        />
      </Header>

      <Content>
        <LotSheetsDetailTabs value={tab} />
        {tab === LOT_SHEETS_DETAIL_TABS.GENERAL && (
          <LotSheetsDetailGeneral
            guid={guid}
            lotSheetsDetailList={lotSheetsDetailList}
            onModify={onModify}
            modifyLoading={modifyLoading}
          />
        )}
        {tab === LOT_SHEETS_DETAIL_TABS.HISTORY && <LotSheetsHistory history={history} />}
      </Content>
    </Container>
  )
}

LotSheetsDetailForm.propTypes = {
  pageTitle: PropTypes.node.isRequired,
  lotSheetsDetailList: PropTypes.object,
  history: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  guid: PropTypes.string.isRequired,
  onScanReportGenerate: PropTypes.func.isRequired,
  onSummaryReportGenerate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onModify: PropTypes.func.isRequired,
  modifyLoading: PropTypes.bool.isRequired,
}

export default LotSheetsDetailForm
