import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const d = 'M2 6h2v12H2V6m3 0h1v12H5V6m2 0h3v12H7V6m4 0h1v12h-1V6m3 0h2v12h-2V6m3 0h3v12h-3V6m4 0h1v12h-1V6z'

function Barcode (props) {
  return (
    <SvgIcon {...props}>
      <path d={d} />
    </SvgIcon>
  )
}

export default Barcode
