import React, { useCallback } from 'react'
import { useDeepCompareEffect } from 'storfox-api-hooks'
import { useNavigate } from 'react-router-dom'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'
import { prop } from 'ramda'
import { useAllSearchParams } from 'storfox-route-hooks'

import AppContainer from '~/components/AppContainer'
import { CenteredLayout } from '~/components/Layouts'
import Confirmation from '~/components/Confirmation'
import { generatePassword } from '~/utils'
import * as ROUTES from '~/constants/routes'
import { useLogout, useMe, useProfile } from '~/components/Profile'
import { useToken } from '~/components/Token'
import ErrorLink from '~/components/ErrorLink'

import { useSallaUserInfo, useSignUp, useEmailCheck } from '../../hooks'

function SallaInstallContainer () {
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const params = useAllSearchParams()
  const { logout } = useLogout()
  const { getMe } = useMe()
  const { setToken, token } = useToken()
  const { setProfile, profile } = useProfile()
  const sallaUserInfo = useSallaUserInfo()
  const { signUp } = useSignUp()
  const emailCheck = useEmailCheck()

  const emailCheckSignUp = useCallback((email, result, sallaParams) => {
    emailCheck.check({ email }).then(() => {
      signUp({ ...result, password: generatePassword(8, true) })
        .then(({ result }) => setToken(result.token))
        .then(() => getMe())
        .then(({ result }) => setProfile(result))
        .then(() => navigate(`${ROUTES.SALLA_INTEGRATION_CREATE_PATH}?${sallaParams}`))
        .then(() => {
          if (window.gtag) {
            window.gtag('event', 'click', {
              event_category: 'Sign Up',
              event_action: 'Sign Up Throw Salla'
            })
          }
        })
        .catch(error => {
          const message = <ErrorLink error={error} />
          snackbar({ message, type: ALTER_ERROR })
        })
    })
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
        navigate(`${ROUTES.SALLA_INTEGRATION_CREATE_PATH}?${sallaParams}`)
      })
  }, [emailCheck, getMe, navigate, setProfile, setToken, signUp, snackbar])

  useDeepCompareEffect(() => {
    sallaUserInfo.getDetail().then(res => {
      const result = prop('result', res)
      const email = prop('email', result)
      const profileEmail = prop('email', profile)

      const appGuid = prop('appGuid', params)
      const code = prop('code', params)
      const scope = prop('scope', params)
      const state = prop('state', params)
      const sallaParams = `appGuid=${appGuid}&code=${code}&scope=${scope}&state=${state}`

      if (profileEmail === email) {
        navigate(`${ROUTES.SALLA_INTEGRATION_CREATE_PATH}?${sallaParams}`)
      } else {
        if (token) {
          logout().then(() => emailCheckSignUp(email, result, sallaParams))
        } else {
          emailCheckSignUp(email, result, sallaParams)
        }
      }
    })
  }, [])

  return (
    <CenteredLayout>
      <AppContainer>
        <Confirmation />
      </AppContainer>
    </CenteredLayout>
  )
}

export default SallaInstallContainer
