import React from 'react'
import { Box, styled } from '@mui/material'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import { useTranslation } from 'react-i18next'

import CenteredCard from '~/components/CenteredCard'

import Logo from '../Logo'

const CardContentStyled = styled(CardContent)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

function Confirmation () {
  const { t } = useTranslation()

  return (
    <CenteredCard size="xs">
      <CardContentStyled>
        <Box>
          <Grid container={true} spacing={1} justifyContent="center">
            <Grid item={true} xs={12}>
              <Box textAlign="center">
                <Link
                  component="a"
                  href="https://storfox.com/"
                >
                  <Logo />
                </Link>
              </Box>
            </Grid>
            <Grid item={true} xs={12}>
              <Box mb={1}>
                <Typography align="center" variant="h4" color="primary">
                  {t('Please wait while redirecting...')}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </CardContentStyled>
    </CenteredCard>
  )
}

export default Confirmation
