import { useAllSearchParams } from 'storfox-route-hooks'
import { useCreate, useLimitedCountList } from 'storfox-api-hooks'

import { queryToParams } from '~/utils'
import * as API from '~/constants/api'

import { UnitFilterSerializer } from '../../serializers/Inventory'

export const useUnitList = () => {
  const searchParams = useAllSearchParams()

  const params = queryToParams(UnitFilterSerializer, searchParams, 'guid')

  return useLimitedCountList(API.UNIT_NUMBER_LIST, params)
}

export const useUnitReportGenerate = () => {
  const searchParams = useAllSearchParams()
  const params = queryToParams(UnitFilterSerializer, searchParams, 'guid')
  const { create, ...state } = useCreate(API.INVENTORY_LOG_REPORT, { ...searchParams, ...params })

  return { ...state, generate: rest => create({ ...rest, ...params }) }
}
