import React from 'react'

import SelectField from '~/components/Fields/SelectField'

const OPTIONS = [
  {
    value: null,
    name: 'All'
  },
  {
    value: 'empty',
    name: 'Empty'
  },
  {
    value: 'not_empty',
    name: 'Not empty'
  }
]

function ContentSelectField () {
  return (
    <SelectField
      label={'Content'}
      options={OPTIONS}
      name="content"
    />
  )
}

export default ContentSelectField
