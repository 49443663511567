import PropTypes from 'prop-types'
import React from 'react'
import { generatePath, Link as RouterLink } from 'react-router-dom'
import { prop, propOr } from 'ramda'
import Link from '@mui/material/Link'

import {
  ADJUSTMENT_DETAIL_TABS,
  PURCHASE_ORDER_DETAIL_TABS,
  SALE_ORDER_DETAIL_TABS,
  TRANSFER_DETAIL_TABS,
  PUTAWAY_DETAIL_TABS
} from '~/constants/tabs'
import * as ROUTES from '~/constants/routes'

export const PURCHASE_ORDER = 'PurchaseOrder'
export const STOCK_ADJUSTMENT = 'StockAdjustment'
export const SALE_ORDER = 'SaleOrder'
export const STOCK_TRANSFER = 'StockTransfer'
export const PUTAWAY = 'putaway'

function SourceDestinationLink ({ name, type, id, guid }) {
  const routes = (id, guid) => ({
    [PURCHASE_ORDER]: {
      path: ROUTES.PURCHASE_ORDER_DETAIL_PATH,
      params: { guid, tab: PURCHASE_ORDER_DETAIL_TABS.GENERAL }
    },
    [STOCK_ADJUSTMENT]: {
      path: ROUTES.ADJUSTMENT_DETAIL_PATH,
      params: { id, tab: ADJUSTMENT_DETAIL_TABS.GENERAL }
    },
    [SALE_ORDER]: {
      path: ROUTES.SALE_ORDER_DETAIL_PATH,
      params: { guid, tab: SALE_ORDER_DETAIL_TABS.GENERAL }
    },
    [STOCK_TRANSFER]: {
      path: ROUTES.TRANSFER_DETAIL_PATH,
      params: { guid, tab: TRANSFER_DETAIL_TABS.GENERAL }
    },
    [PUTAWAY]: id ? {
      path: ROUTES.V2_PUTAWAY_DETAIL_PATH,
      params: { guid, tab: PUTAWAY_DETAIL_TABS.GENERAL }
    } : { path: ROUTES.V1_PUTAWAY_LIST_PATH }
  })

  const route = prop(type, routes(id, guid))
  const path = prop('path', route)
  const params = propOr({}, 'params', route)
  const generatedPath = path && generatePath(path, params)

  return (
    generatedPath ? (
      <Link
        to={generatedPath}
        component={RouterLink}
        underline="always"
        target="_blank"
        rel="noopener noreferrer"
      >
        {name}
      </Link>
    ) : (
      <>{name}</>
    )
  )
}

SourceDestinationLink.propTypes = {
  guid: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
}

export default SourceDestinationLink
