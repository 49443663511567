import React from 'react'
import PropTypes from 'prop-types'

import * as ROUTES from '~/constants/routes'
import TableSplitButton from '~/components/TableSplitButton'

function BulkActionButton ({ handleReorder, comparisonWarehouse }) {
  const options = [
    {
      label: 'Purchase order',
      'data-cy': 'purchaseOrder',
      onMenuClick: () => handleReorder(ROUTES.PURCHASE_ORDER_CREATE_PATH)
    },
    {
      label: 'Stock transfer',
      'data-cy': 'stockTransfer',
      onMenuClick: () => handleReorder(ROUTES.TRANSFER_CREATE_PATH),
      disabled: !comparisonWarehouse,
      tooltip: 'Select comparison warehouse'
    }
  ]

  return (
    <TableSplitButton
      options={options}
      name="transfer"
    />
  )
}

BulkActionButton.propTypes = {
  handleReorder: PropTypes.func.isRequired,
  comparisonWarehouse: PropTypes.object
}

export default BulkActionButton
