import React from 'react'
import PropTypes from 'prop-types'
import ArrowBackIos from '@mui/icons-material/ArrowBackIos'

import Button from '../Buttons/Button'

function DiscardButtonClick ({ children, onClick, ...props }) {
  return (
    <Button
      type="button"
      variant="outlined"

      data-cy="discard"
      startIcon={<ArrowBackIos />}
      onClick={onClick}
      {...props}
    >
      {children}
    </Button>
  )
}

DiscardButtonClick.propTypes = {
  children: PropTypes.any.isRequired,
  onClick: PropTypes.func,
}

DiscardButtonClick.defaultProps = {
  children: 'Discard',
}

export default DiscardButtonClick
