export const FILTER_LIST = `/v1/filters/list/`
export const FILTER_CREATE = `/v1/filters/create/`
export const FILTER_REMOVE = `/v1/filters/delete/%s/`
export const FILTER_UPDATE = `/v1/filters/update/%s/`

export const ORDERING_LIST = `/v1/filters/orderings/list/`
export const ORDERING_FILTER = `/v1/filters/orderings/filter/`
export const ORDERING_COLUMN = `/v1/filters/orderings/columns/`
export const COLUMN_WIDTH_UPDATE = `/v1/filters/column/update/`
export const ORDERING_RESET = `/v1/filters/reset/`
export const FILTER_CLAUSES_COUNT = '/v1/filters/clauses/%s/count/'
