import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import PurchaseOrderListContainer from './containers/PurchaseOrderListContainer'
import PurchaseOrderCreateContainer from './containers/PurchaseOrderCreateContainer'
import PurchaseOrderUpdateContainer from './containers/PurchaseOrderUpdateContainer'
import PurchaseOrderDetailContainer from './containers/PurchaseOrderDetailContainer'
import PurchaseOrderImportContainer from './containers/PurchaseOrderImportContainer'

export default () => [
  {
    layout: AuthLayout,
    path: ROUTES.PURCHASE_ORDER_LIST_PATH,
    component: PurchaseOrderListContainer
  },
  {
    layout: AuthLayout,
    path: ROUTES.PURCHASE_ORDER_CREATE_PATH,
    component: PurchaseOrderCreateContainer
  },
  {
    layout: AuthLayout,
    path: ROUTES.PURCHASE_ORDER_UPDATE_PATH,
    component: PurchaseOrderUpdateContainer
  },
  {
    layout: AuthLayout,
    path: ROUTES.PURCHASE_ORDER_DETAIL_PATH,
    component: PurchaseOrderDetailContainer
  },
  {
    layout: AuthLayout,
    path: ROUTES.PURCHASE_ORDER_IMPORT_PATH,
    component: PurchaseOrderImportContainer
  },
]
