import React from 'react'
import PropTypes from 'prop-types'
import { map } from 'ramda'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'

function ErrorField ({ errors }) {
  return (
    <>
      {map(({ row, values }) => {
        return (
          <Table size="small" key={row}>
            <TableHead>
              <TableRow>
                <TableCell>{row}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {map(({ column, value }) => (
                <TableRow key={column}>
                  <TableCell width="30%">
                    <strong>{column}</strong>
                  </TableCell>
                  <TableCell>{value[0]}</TableCell>
                </TableRow>
              )
              , values)}
            </TableBody>
          </Table>
        )
      }, errors)}
    </>
  )
}

ErrorField.propTypes = {
  errors: PropTypes.array.isRequired
}

export default ErrorField
