import { filter, map, pick, pipe, prop, propEq, propOr, reduce } from 'ramda'
import { DEFAULT_SEARCH_PARAMS } from 'storfox-route-hooks'

import { escapeAtob, orderingToSnackCase } from '~/utils'

import { WAREHOUSE_CONTENT_FIELDS } from '../components/WarehouseContent'

export function WarehouseCreateSerializer (formValues) {
  return pick(WAREHOUSE_CONTENT_FIELDS, formValues)
}

export function LocationCoordinatesInitSerializer (values) {
  const initialLocations = reduce((acc, value) => {
    const x = prop('x', value)
    const y = prop('y', value)
    const z = prop('z', value)
    const guid = prop('guid', value)

    return { ...acc, [guid]: { x, y, z } }
  }, {}, values)

  return { initialLocations, locations: values }
}

export function LocationCoordinatesUpdateSerializer (formValues) {
  const initialLocations = prop('initialLocations', formValues)
  return pipe(
    propOr([], 'locations'),
    map(location => {
      const x = parseInt(prop('x', location))
      const y = parseInt(prop('y', location))
      const z = parseInt(prop('z', location))
      const guid = prop('guid', location)
      const coordinate = { x, y, z }

      return {
        coordinate,
        location: { guid }
      }
    }),
    filter(locationItem => {
      const location = prop('location', locationItem)
      const coordinate = prop('coordinate', locationItem)
      const guid = prop('guid', location)
      const initialValue = initialLocations[guid]
      const initialX = prop('x', initialValue)
      const initialY = prop('y', initialValue)
      const initialZ = prop('z', initialValue)

      return (
        !propEq('x', initialX, coordinate) ||
        !propEq('y', initialY, coordinate) ||
        !propEq('z', initialZ, coordinate)
      )
    })
  )(formValues)
}

export function WarehouseFilterSerializer (params) {
  const defaultParams = pick(DEFAULT_SEARCH_PARAMS, params)
  const status = prop('status', params)
  const countryId = escapeAtob(prop('country', params))
  const warehouseIds = escapeAtob(prop('warehouses', params))
  const isDefault = prop('isDefault', params)

  return {
    ...defaultParams,
    status,
    countryId,
    warehouseIds,
    isDefault,
  }
}

export function LocationFilterSerializer (params) {
  const defaultParams = pick(DEFAULT_SEARCH_PARAMS, params)
  const status = prop('status', params)
  const warehouseId = prop('warehouseId', params)
  const locationType = prop('locationType', params)
  const areaGuid = escapeAtob(prop('area', params))
  const warehouseGuids = escapeAtob(prop('warehouses', params)) || []
  const zoneGuid = escapeAtob(prop('zone', params))
  const maxLoadStart = prop('maxLoadStart', params)
  const maxLoadEnd = prop('maxLoadEnd', params)
  const usedVolumeStart = prop('usedVolumeStart', params)
  const usedVolumeEnd = prop('usedVolumeEnd', params)
  const utilizationStart = prop('utilizationStart', params)
  const utilizationEnd = prop('utilizationEnd', params)
  const containersCountStart = prop('containersCountStart', params)
  const containersCountEnd = prop('containersCountEnd', params)

  return {
    ...defaultParams,
    status,
    locationType,
    areaGuid,
    warehouseId,
    warehouseGuids,
    zoneGuid,
    maxLoadStart,
    maxLoadEnd,
    usedVolumeStart,
    usedVolumeEnd,
    utilizationStart,
    utilizationEnd,
    containersCountStart,
    containersCountEnd,
  }
}

export const ContainerFilterSerializer = params => {
  const defaultParams = pick(DEFAULT_SEARCH_PARAMS, params)

  const supplierId = escapeAtob(prop('supplier', params))
  const content = prop('content', params)
  const warehouseGuids = escapeAtob(prop('warehouses', params))
  const containerTypeGuids = escapeAtob(prop('containerTypes', params))
  const locationGuids = escapeAtob(prop('locations', params))
  const startDate = escapeAtob(prop('startDate', params))
  const endDate = escapeAtob(prop('endDate', params))
  const minTotal = escapeAtob(prop('minTotal', params))
  const maxTotal = escapeAtob(prop('maxTotal', params))

  return orderingToSnackCase({
    ...defaultParams,
    supplierId,
    content,
    containerTypeGuids,
    locationGuids,
    warehouseGuids,
    startDate,
    endDate,
    minTotal,
    maxTotal
  })
}
