import { useField } from 'react-final-form'
import { useCallback } from 'react'

export function useDestination () {
  const barcodeField = useField('barcode')

  const destinationField = useField('destination')
  const locationIdField = useField('destination.locationId')
  const containerCodeField = useField('destination.containerCode')
  const expiryDateField = useField('destination.expiryDate')

  const handleClear = useCallback(() => destinationField.input.onChange(null), [destinationField.input])

  const clearBarcodeField = () => barcodeField.input.onChange('')

  const handleChange = (event, barcode) => {
    if (!locationIdField.input.value) {
      locationIdField.input.onChange(barcode)
      clearBarcodeField()
      event.preventDefault()
      return
    }

    if (!containerCodeField.input.value) {
      containerCodeField.input.onChange(barcode)
      clearBarcodeField()
      event.preventDefault()
      return
    }

    if (!expiryDateField.input.value) {
      expiryDateField.input.onChange(barcode)
      clearBarcodeField()
      event.preventDefault()
    }
  }

  return {
    handleClear,
    handleChange
  }
}
