import React from 'react'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import { path, prop, propOr } from 'ramda'
import TableBody from '@mui/material/TableBody'
import PropTypes from 'prop-types'
import TableCell from '@mui/material/TableCell'
import Paper from '@mui/material/Paper'
import { usePickSearchParams } from 'storfox-route-hooks'
import Pagination from 'storfox-table/src/Table/Pagination'
import { generatePath, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import TableRow from '@mui/material/TableRow'

import Money from '~/components/Money'
import OrderProgress from '~/components/OrderProgress'
import TableDateFormat from '~/components/TableDateFormat'
import Tags from '~/components/Tags'
import TableNotes from '~/components/TableNotes'
import SkeletonList from '~/components/Skeleton'
import { PurchaseOrderStatus } from '~/components/Statuses'
import { HoverableRow } from '~/components/Table'
import * as ROUTES from '~/constants/routes'
import { PURCHASE_ORDER_DETAIL_TABS } from '~/constants/tabs'

function SupplierPurchases ({ purchaseList }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { limit } = usePickSearchParams(['limit'])
  const rowCount = limit ? parseInt(limit) : 10

  const list = prop('results', purchaseList)
  const count = prop('count', purchaseList)
  const isLoading = prop('isLoading', purchaseList)

  const handleClick = guid => {
    const path = generatePath(ROUTES.PURCHASE_ORDER_DETAIL_PATH, { guid, tab: PURCHASE_ORDER_DETAIL_TABS.GENERAL })
    navigate(path)
  }

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ minWidth: '100px' }}>{t('PO #')}</TableCell>
              <TableCell sx={{ minWidth: '100px' }}>{t('Status')}</TableCell>
              <TableCell sx={{ minWidth: '100px' }} align="center">{t('Products')}</TableCell>
              <TableCell sx={{ minWidth: '150px' }}>{t('Total')}</TableCell>
              <TableCell sx={{ minWidth: '200px' }}>{t('Received/Ordered')}</TableCell>
              <TableCell sx={{ minWidth: '200px' }}>{t('Receiving warehouse')}</TableCell>
              <TableCell sx={{ minWidth: '200px' }}>{t('Expected date')}</TableCell>
              <TableCell sx={{ minWidth: '200px' }}>{t('Created date')}</TableCell>
              <TableCell sx={{ minWidth: '200px' }}>{t('Last updated')}</TableCell>
              <TableCell sx={{ minWidth: '200px' }}>{t('Supplier reference id')}</TableCell>
              <TableCell sx={{ minWidth: '300px' }}>{t('Tags')}</TableCell>
              <TableCell sx={{ minWidth: '75px' }}>{t('Notes')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading
              ? <SkeletonList columnCount={12} rowCount={rowCount} height={20} />
              : list.map(item => {
                const guid = prop('guid', item)
                const number = prop('number', item)
                const orderStatus = <PurchaseOrderStatus value={prop('orderStatus', item)} />
                const products = prop('products', item)
                const ordered = prop('ordered', item)
                const received = prop('received', item)
                const warehouse = path(['warehouse', 'name'], item)
                const supplierReference = prop('supplierReference', item)
                const notesItem = prop('notes', item)

                return (
                  <HoverableRow key={guid} onClick={() => handleClick(guid)}>
                    <TableCell>{number}</TableCell>
                    <TableCell>{orderStatus}</TableCell>
                    <TableCell align="center">{products}</TableCell>
                    <TableCell>
                      <Money value={prop('totalPrice', item)} />
                    </TableCell>
                    <TableCell>
                      <OrderProgress ordered={ordered} received={received} />
                    </TableCell>
                    <TableCell>{warehouse}</TableCell>
                    <TableCell>
                      <TableDateFormat date={prop('expectedDate', item)} />
                    </TableCell>
                    <TableCell>
                      <TableDateFormat withTime={true} date={prop('orderDate', item)} />
                    </TableCell>
                    <TableCell>
                      <TableDateFormat withTime={true} date={prop('updatedAt', item)} />
                    </TableCell>
                    <TableCell>{supplierReference}</TableCell>
                    <TableCell>
                      <Tags items={propOr([], 'tags', item)} />
                    </TableCell>
                    <TableCell>
                      <TableNotes>
                        {notesItem && <pre>{notesItem}</pre>}
                      </TableNotes>
                    </TableCell>
                  </HoverableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination count={count} prefix="" />
    </Paper>
  )
}

SupplierPurchases.propTypes = {
  purchaseList: PropTypes.object.isRequired
}

export default SupplierPurchases
