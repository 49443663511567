import React from 'react'
import TableCell from '@mui/material/TableCell'
import PropTypes from 'prop-types'
import Table from '@mui/material/Table'
import { defaultTo, groupBy, map, path, pipe, uniq } from 'ramda'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import { useTranslation } from 'react-i18next'

import { ExpandableTableRow, ExpandedTableWrapper } from '~/components/Table'

import ConditionRow from './ConditionRow'

import { getStockTotalValues } from '../../../utils'

function WarehouseRow ({ children, warehouses }) {
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(false)

  const groupedConditions = groupBy(path(['condition', 'id']), warehouses)

  const conditionIds = pipe(
    defaultTo([]),
    map(path(['condition', 'id'])),
    uniq
  )(warehouses)

  return (
    <>
      <ExpandableTableRow open={open} onExpandToggle={() => setOpen(!open)}>
        {children}
      </ExpandableTableRow>

      <ExpandedTableWrapper open={open} colSpan={6}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell style={{ minWidth: 400 }}>{t('Condition')}</TableCell>
              <TableCell align="center">{t('Available')}</TableCell>
              <TableCell align="center">{t('Unavailable')}</TableCell>
              <TableCell align="center">{t('On Hand')}</TableCell>
              <TableCell align="center">{t('Allocated')}</TableCell>
              <TableCell align="center">{t('Action')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {conditionIds.map(conditionId => {
              const conditions = groupedConditions[conditionId]
              const firstCondition = conditions[0]
              const condition = path(['condition', 'code'], firstCondition)
              const { allocated, available, unavailable, onHand } = getStockTotalValues(conditions)

              return (
                <ConditionRow key={conditionId} conditions={conditions}>
                  <TableCell>{condition}</TableCell>
                  <TableCell align="center">{available}</TableCell>
                  <TableCell align="center">{unavailable}</TableCell>
                  <TableCell align="center">{onHand}</TableCell>
                  <TableCell align="center">{allocated}</TableCell>
                </ConditionRow>
              )
            })}
          </TableBody>
        </Table>
      </ExpandedTableWrapper>
    </>
  )
}

WarehouseRow.propTypes = {
  children: PropTypes.any.isRequired,
  warehouses: PropTypes.array.isRequired
}

export default WarehouseRow
