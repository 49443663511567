import React from 'react'
import PropTypes from 'prop-types'

import TableSplitIconButton from '~/components/TableSplitButton/TableSplitIconButton'

function BulkActionButton ({ printBarcode }) {
  const options = [
    {
      label: 'Print barcodes',
      'data-cy': 'printBarcodesMenu',
      onMenuClick: printBarcode
    }
  ]

  return (
    <TableSplitIconButton
      options={options}
      name="container"
    />
  )
}

BulkActionButton.propTypes = {
  printBarcode: PropTypes.array.isRequired,
}

export default BulkActionButton
