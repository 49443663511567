import React from 'react'
import Grid from '@mui/material/Grid'
import { Card } from '@mui/material'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import PropTypes from 'prop-types'
import { prop } from 'ramda'

import { CardHeader } from '~/components/Cards'
import TextLabelLoader from '~/components/TextLabelLoader'

function SupplierBankAccount ({ bankAccounts }) {
  return (
    <Grid container={true} spacing={3}>
      {bankAccounts.map((bankAccount, index) => {
        const bankName = prop('bankName', bankAccount)
        const bankBranch = prop('bankBranch', bankAccount)
        const bankCode = prop('bankCode', bankAccount)
        const bankSwift = prop('bankSwift', bankAccount)
        const accountHolder = prop('accountHolder', bankAccount)
        const accountNumber = prop('accountNumber', bankAccount)

        return (
          <Grid item={true} xs={12} lg={6} key={index}>
            <Card>
              <CardHeader title="Bank account" />
              <Divider />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={12}>
                    <TextLabelLoader label="Bank name">
                      {bankName}
                    </TextLabelLoader>
                  </Grid>

                  <Grid item={true} xs={12} lg={4}>
                    <TextLabelLoader label="Bank branch">
                      {bankBranch}
                    </TextLabelLoader>
                  </Grid>

                  <Grid item={true} xs={12} lg={4}>
                    <TextLabelLoader label="Bank code">
                      {bankCode}
                    </TextLabelLoader>
                  </Grid>

                  <Grid item={true} xs={12} lg={4}>
                    <TextLabelLoader label="Bank swift">
                      {bankSwift}
                    </TextLabelLoader>
                  </Grid>

                  <Grid item={true} xs={12} lg={6}>
                    <TextLabelLoader label="Account holder">
                      {accountHolder}
                    </TextLabelLoader>
                  </Grid>

                  <Grid item={true} xs={12} lg={6}>
                    <TextLabelLoader label="Account number">
                      {accountNumber}
                    </TextLabelLoader>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )
      })}
    </Grid>
  )
}

SupplierBankAccount.defaultProps = {
  bankAccounts: []
}

SupplierBankAccount.propTypes = {
  bankAccounts: PropTypes.array.isRequired
}

export default SupplierBankAccount
