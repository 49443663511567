export const DIGITAL = 'digital'
export const PHYSICAL = 'physical'
export const SERVICE = 'service'

export const PRODUCT_TYPE_NAMES = {
  [DIGITAL]: 'Digital product',
  [PHYSICAL]: 'Physical product',
  [SERVICE]: 'Service'
}

export const FEFO = 'fefo'
export const FIFO = 'fifo'
export const LIFO = 'lifo'

export const STRATEGY_TYPE_NAMES = {
  [FEFO]: 'First Expired First Out',
  [FIFO]: 'First In First Out',
  [LIFO]: 'Last In First Out'
}

export const FILTER_NAME = 'product'
