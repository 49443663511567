import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import ShipoxIntegrationUpdateContainer from './ShipoxIntegrationUpdateContainer'
import ShipoxIntegrationCreateContainer from './ShipoxIntegrationCreateContainer'

export default () => {
  return [
    {
      layout: AuthLayout,
      path: ROUTES.SHIPOX_INTEGRATION_CREATE_PATH,
      component: ShipoxIntegrationCreateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.SHIPOX_INTEGRATION_UPDATE_PATH,
      component: ShipoxIntegrationUpdateContainer
    }
  ]
}
