import React from 'react'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import { styled } from '@mui/material'

import Icon from '../Icons/IntegrationIcon'

const RootStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  padding: '8px 0',
  '& > div': {
    height: '100%',
    verticalAlign: 'middle'
  },
  '& > div:first-of-type': {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  }
}))

function ChannelBox ({ name, type, ...props }) {
  return (
    <RootStyled {...props}>
      <div>
        <Icon type={type} width={40} />
      </div>
      <div>
        <Typography>{name}</Typography>
      </div>
    </RootStyled>
  )
}

ChannelBox.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.bool.isRequired
}

export default ChannelBox
