import React from 'react'
import { Link } from 'react-router-dom'
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop'
import { ListItemButton } from '@mui/material'
import PropTypes from 'prop-types'

import ListItemText from '~/components/List/ListItemText'
import { MoreListButton } from '~/components/Buttons'
import * as ROUTES from '~/constants/routes'
import IconText from '~/components/IconText'

function More ({ tableRef }) {
  return (
    <MoreListButton tableRef={tableRef}>
      {onClose => (
        <ListItemButton
          disabled={false}
          component={Link}
          to={ROUTES.PURCHASE_ORDER_IMPORT_PATH}
        >
          <IconText Icon={VerticalAlignTopIcon}>
            <ListItemText primary="Import" />
          </IconText>
        </ListItemButton>
      )}
    </MoreListButton>
  )
}

More.propTypes = {
  tableRef: PropTypes.object
}

export default More
