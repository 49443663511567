import React from 'react'
import { path } from 'ramda'
import { generatePath, useNavigate } from 'react-router-dom'
import { useSnackbar } from 'storfox-snackbar'
import { useTranslation } from 'react-i18next'

import { DashboardLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'
import { COMPANY_TABS } from '~/constants/tabs'

import { CompanyCreateForm } from '../components/CompanyCreate'
import { useCompanyCreate } from '../hooks'

function CompanyCreateContainer () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const snackbar = useSnackbar()

  const companyCreate = useCompanyCreate()
  const title = 'New Company'

  const message = t('Successfully created')

  const handleSubmit = formValues =>
    companyCreate.create(formValues)
      .then(data => {
        const params = {
          id: path(['result', 'id'], data),
          tab: COMPANY_TABS.GENERAL
        }
        const redirect = generatePath(ROUTES.COMPANY_UPDATE_PATH, params)

        navigate(redirect)
      })
      .then(() => snackbar({ message }))

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title="New Company"
      isLoading={companyCreate.isLoading}
      activeNav={NAV.COMPANIES}
      breadcrumbs={breadcrumbs}
    >
      <CompanyCreateForm
        title={title}
        initialValues={{ status: 'active' }}
        onSubmit={handleSubmit}
        isLoading={companyCreate.isLoading}
      />
    </DashboardLayout>
  )
}

export default CompanyCreateContainer
