import React from 'react'

import SelectField from '~/components/Fields/SelectField'
import { ZONE_STATUS } from '~/components/Statuses/ZoneStatus'

export const OPTIONS = [
  { value: ZONE_STATUS.ACTIVE, name: 'Active' },
  { value: ZONE_STATUS.INACTIVE, name: 'Inactive' }
]

function ZoneStatusField (props) {
  return (
    <SelectField
      name="status"
      label="Status"
      options={OPTIONS}
      {...props}
    />
  )
}

export default ZoneStatusField
