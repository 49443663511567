import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import OverviewContainer from './containers/OverviewContainer'

export default () => {
  return [
    {
      layout: AuthLayout,
      path: ROUTES.OVERVIEW_PATH,
      component: OverviewContainer
    }
  ]
}
