import { useAllSearchParams } from 'storfox-route-hooks'
import { sprintf } from 'sprintf-js'
import { useDelete, useDetail, useLimitedCountList } from 'storfox-api-hooks'

import { queryToParams } from '~/utils'
import * as API from '~/constants/api'

import { InvoiceFilterSerializer } from '../serializers'

export const useInvoiceList = () => {
  const searchParams = useAllSearchParams()
  const params = queryToParams(InvoiceFilterSerializer, searchParams, 'guid')

  return useLimitedCountList(API.ORDER_INVOICE_LIST, params)
}

export const useInvoiceDetail = guid => {
  const url = sprintf(API.ORDER_INVOICE_DETAIL, guid)

  return useDetail(url)
}

export const useInvoicesDelete = () => {
  return useDelete(API.ORDER_INVOICES_DELETE)
}
