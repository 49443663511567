import React from 'react'
import Link from '@mui/material/Link'
import PropTypes from 'prop-types'

import Button from '~/components/Buttons/Button'

function LinkButton ({ href, children, ...props }) {
  return (
    <Button
      href={href}
      component={Link}
      variant="contained"
      style={{ color: 'white' }}
      underline="none"
      {...props}
    >
      {children}
    </Button>
  )
}

LinkButton.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired

}

export default LinkButton
