import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { map, path, prop, propOr } from 'ramda'
import { generatePath, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import EditIcon from '@mui/icons-material/Edit'

import Avatar from '~/components/Avatar/Avatar'
import { MoreListButton, NewButton } from '~/components/Buttons'
import PageTitle from '~/components/PageTitle'
import Tags from '~/components/Tags'
import TableDateFormat from '~/components/TableDateFormat'
import Container, { Content, Header } from '~/components/Container'
import TextOverflow from '~/components/TextOverflow'
import { ChannelDialog } from '~/components/ChannelDialog'
import { useChannelSelect } from '~/components/ChannelDialog/hooks'
import { Table, TableHeader } from '~/components/Table'
import useDialog from '~/hooks/useDialog'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import Money from '~/components/Money'
import TableLink from '~/components/Link'
import { BUNDLE_UPDATE_TABS } from '~/constants/tabs'

import BulkActionButton from './BulkActionButton'

const getDetailPath = guid => {
  return generatePath(ROUTES.BUNDLE_DETAIL_PATH, { guid, tab: BUNDLE_UPDATE_TABS.GENERAL })
}

export const BUNDLE_COLUMNS = [
  {
    width: 75,
    headerName: 'Image',
    field: 'image',
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        <Avatar src={value} alt={row.name} />
      </TableLink>
    )
  },
  {
    width: 300,
    headerName: 'Name',
    field: 'name',
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        <TextOverflow selfTooltip={true}>{value}</TextOverflow>
      </TableLink>
    )
  },
  {
    width: 200,
    headerName: 'Retail price',
    field: 'retailPrice',
    renderCell: ({ value }) => (
      <Money value={value} />
    )
  },
  {
    width: 150,
    headerName: 'Category',
    field: 'category',
    renderCell: ({ value }) => (
      <TextOverflow selfTooltip={true}>{value}</TextOverflow>
    )
  },
  {
    width: 150,
    headerName: 'Brand',
    field: 'brand'
  },
  {
    width: 150,
    headerName: 'Company',
    field: 'company'
  },
  {
    width: 300,
    headerName: 'Tags',
    field: 'tags',
    valueFormatter: ({ value }) => value.length > 0 ? value.join(',') : '',
    renderCell: ({ value }) => (
      <Tags items={value} />
    )
  },
  {
    width: 150,
    headerName: 'Last created',
    field: 'createdAt',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  },
  {
    width: 150,
    headerName: 'Last updated',
    field: 'updatedAt',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  }
]

const getRowsFromResults = map(item => {
  const id = prop('id', item)
  const guid = prop('guid', item)
  const name = prop('name', item)
  const image = prop('defaultImage', item)
  const category = path(['category', 'name'], item)

  const brand = path(['brand', 'name'], item)
  const company = path(['company', 'name'], item)
  const retailPrice = prop('retailPrice', item)
  const tags = propOr([], 'tags', item)
  const createdAt = prop('createdAt', item)
  const updatedAt = prop('updatedAt', item)
  const link = getDetailPath(guid)

  return {
    id,
    guid,
    image,
    retailPrice,
    name,
    category,
    brand,
    company,
    tags,
    createdAt,
    updatedAt,
    link
  }
})

function BundleTable (props) {
  const {
    list,
    filter,
    ordering,
    onListRefetch,
    bundleChannelList,
    onListingAdd,
    onBundlesDelete,
    bulkActionLoading
  } = props
  const [tableRef, setTableRef] = useState(null)
  const navigate = useNavigate()
  const { selectedChannelGuids, handleChannelSelect } = useChannelSelect()
  const { open, handleOpen, handleClose } = useDialog()
  const { t } = useTranslation()
  const isLoading = prop('isLoading', list)
  const results = prop('results', list)
  const count = prop('count', list)
  const columns = prop('filteredColumns', ordering)

  const handleOpenDialog = () => {
    bundleChannelList.getList()
    handleOpen()
  }

  const handleListingAdd = () =>
    onListingAdd(selectedChannelGuids)
      .then(() => handleClose())

  const handleEdit = useCallback(guid => {
    const editPath = generatePath(ROUTES.BUNDLE_UPDATE_PATH, { guid, tab: BUNDLE_UPDATE_TABS.GENERAL })
    navigate(editPath)
  }, [navigate])

  const actionColumns = useMemo(() => [
    ...columns,
    {
      width: 50,
      resizable: false,
      field: 'actions',
      type: 'actions',
      getActions: ({ row }) => [
        <GridActionsCellItem
          label="Edit"
          onClick={() => handleEdit(row.guid)}
          showInMenu={true}
          icon={<EditIcon />}
        />
      ]
    }
  ], [columns, handleEdit])

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={t(NAV.BUNDLES)}
          parentTitle={t(NAV.CATALOG)}
          rightButton={(
            <>
              <BulkActionButton
                onBundlesDelete={onBundlesDelete}
                onAddToListingDialogOpen={handleOpenDialog}
                loading={bulkActionLoading}
              />
              <NewButton path={ROUTES.BUNDLE_CREATE_PATH} />
            </>
          )}
        />
      </Header>

      <Content>
        <TableHeader
          filter={filter}
          filterOpen={filter.handleOpen}
          orderingOpen={ordering.handleOpen}
          ordering={ordering}
        >
          <MoreListButton tableRef={tableRef} />
        </TableHeader>
        <Table
          isLoading={isLoading}
          columns={actionColumns}
          getRowsFromResults={getRowsFromResults}
          results={results}
          primaryKey="guid"
          count={count}
          pinnedColumns={['image', 'name']}
          checkboxSelection={true}
          ordering={ordering}
          onRefetch={onListRefetch}
          setTableRef={setTableRef}
        />
        <ChannelDialog
          onSubmit={handleListingAdd}
          onClose={handleClose}
          open={open}
          selectedChannelGuids={selectedChannelGuids}
          onChannelSelect={handleChannelSelect}
          isLoading={bundleChannelList.isLoading}
          channelList={bundleChannelList.results}
        />
      </Content>
    </Container>
  )
}

BundleTable.propTypes = {
  list: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  bundleChannelList: PropTypes.object.isRequired,
  onBundlesDelete: PropTypes.func.isRequired,
  onListRefetch: PropTypes.func.isRequired,
  onListingAdd: PropTypes.func.isRequired,
  bulkActionLoading: PropTypes.bool
}

export default BundleTable
