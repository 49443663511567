import React from 'react'
import PropTypes from 'prop-types'

function Percentage ({ value, decimalScale }) {
  return (
    <>{parseFloat(value.toFixed(decimalScale))}%</>
  )
}

Percentage.defaultProps = {
  value: 0,
  decimalScale: 3
}

Percentage.propTypes = {
  decimalScale: PropTypes.number,
  value: PropTypes.number.isRequired
}

export default Percentage
