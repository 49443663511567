import React from 'react'
import PropTypes from 'prop-types'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import { useTranslation } from 'react-i18next'
import { path, prop } from 'ramda'

import EmailField from '~/components/Fields/EmailField'
import MultiSelectSearchField from '~/components/Fields/MultiSelectSearchField'
import TextField from '~/components/Fields/TextField'
import { CardHeader } from '~/components/Cards'
import { Button, DiscardButton } from '~/components/Buttons'
import { withForm } from '~/components/Form'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import { useCompany } from '~/components/Profile'
import * as NAV from '~/constants/nav-titles'
import * as API from '~/constants/api'

import BillingField from '../Fields/BillingField'
import ShipoxCard from '../ShipoxCard'

function ContractCreateForm (props) {
  const {
    pageTitle,
    pageTitleLoading,
    isLoading,
    form
  } = props

  const { t } = useTranslation()
  const { initialValues } = form
  const { company } = useCompany()
  const companyId = prop('id', company)
  const clientId = path(['client', 'id'], initialValues)

  const isClient = companyId === clientId

  return (
    <form onSubmit={form.handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={pageTitle}
            pageTitleLoading={pageTitleLoading}
            parentTitle={NAV.CONFIGURATION}
            rightButton={(
              <>
                <DiscardButton disabled={isLoading} />
                <Button
                  variant="contained"
                  type="submit"
                  data-cy="submit"
                  disabled={isLoading}
                >
                  {t('Send')}
                </Button>
              </>
            )}
          />
        </Header>

        <Content>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12} lg={8}>
              <Box mb={3}>
                <Card>
                  <CardHeader title={t('Invite')} />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12}>
                        <EmailField
                          data-cy="email"
                          name="toEmail"
                          label="Email"
                        />
                      </Grid>
                      <Grid item={true} xs={12}>
                        <TextField
                          data-cy="message"
                          name="message"
                          label="Message"
                          multiline={true}
                          rows="4"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Grid>

            <Grid item={true} xs={12} lg={4}>
              <Box mb={3}>
                <Card>
                  <CardHeader title={t('Settings')} />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12}>
                        <MultiSelectSearchField
                          api={API.WAREHOUSE_LIST}
                          data-cy="warehouses"
                          name="warehouses"
                          label={t('Shared Warehouses')}
                          ListboxProps={{ 'data-cy': 'warehouseList' }}
                        />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <BillingField />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>

              <Box>
                <ShipoxCard isClient={isClient} />
              </Box>
            </Grid>

          </Grid>
        </Content>
      </Container>
    </form>
  )
}

ContractCreateForm.propTypes = {
  pageTitle: PropTypes.string,
  pageTitleLoading: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default withForm(ContractCreateForm)
