import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import TraxIntegrationCreateContainer from './TraxIntegrationCreateContainer'
import TraxIntegrationUpdateContainer from './TraxIntegrationUpdateContainer'

export default () => {
  return [
    {
      layout: AuthLayout,
      path: ROUTES.TRAX_INTEGRATION_CREATE_PATH,
      component: TraxIntegrationCreateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.TRAX_INTEGRATION_UPDATE_PATH,
      component: TraxIntegrationUpdateContainer
    }
  ]
}
