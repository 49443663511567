import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import TCSIntegrationUpdateContainer from './TCSIntegrationUpdateContainer'
import TCSIntegrationCreateContainer from './TCSIntegrationCreateContainer'

export default () => {
  return [
    {
      layout: AuthLayout,
      path: ROUTES.TCS_INTEGRATION_CREATE_PATH,
      component: TCSIntegrationCreateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.TCS_INTEGRATION_UPDATE_PATH,
      component: TCSIntegrationUpdateContainer
    }
  ]
}
