import React from 'react'
import { useFilter, useOrdering } from 'storfox-filter'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import useMessages from '~/hooks/useMessages'
import ErrorLink from '~/components/ErrorLink'
import { useTableSelects } from '~/components/TableValues/hooks'

import { useUserList, useUsersDelete } from '../hooks'
import {
  UserFilterForm,
  userFilterOptions,
  UserOrderingForm,
  userOrderingOptions,
  UserTable
} from '../components/UserList'

function UserListContainer () {
  const snackbar = useSnackbar()
  const messages = useMessages()
  const userList = useUserList()
  const usersDelete = useUsersDelete()

  const filter = useFilter(userFilterOptions)
  const ordering = useOrdering(userOrderingOptions)
  const { selects, resetTableSelects } = useTableSelects()

  const handleUsersDelete = () =>
    usersDelete.delete({ ids: selects })
      .then(() => snackbar({ message: messages.DELETE_SUCCESS }))
      .then(() => resetTableSelects())
      .then(() => userList.getList())
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const breadcrumbs = { title: NAV.USERS }

  return (
    <DashboardLayout
      title={NAV.USERS}
      activeNav={NAV.USERS}
      breadcrumbs={breadcrumbs}
    >
      <UserFilterForm {...filter} />
      <UserOrderingForm {...ordering} />

      <UserTable
        list={userList}
        filter={filter}
        ordering={ordering}
        onListRefetch={userList.getListByParams}
        onUsersDelete={handleUsersDelete}
        bulkActionLoading={usersDelete.isLoading}
      />
    </DashboardLayout>
  )
}

export default UserListContainer
