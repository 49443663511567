import { pick } from 'ramda'

import { BRAND_CREATE_FIELDS } from '../components/BrandCreate'

export function BrandCreateSerializer (formValues) {
  return pick(
    BRAND_CREATE_FIELDS,
    formValues
  )
}

export function BrandUpdateSerializer (formValues) {
  return pick(
    BRAND_CREATE_FIELDS,
    formValues
  )
}
