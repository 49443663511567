import React from 'react'

import SelectField from '~/components/Fields/SelectField'

export const PER_ORDER = 'per_order'
export const GROUPED_ORDER = 'grouped_order'

const OPTIONS = [
  { value: PER_ORDER, name: 'Create one picklist per order.' },
  { value: GROUPED_ORDER, name: 'Merge multiple orders to create one picklist.' },
]

function PickingStrategyField ({ ...props }) {
  return (
    <SelectField
      data-cy="pickingStrategy"
      name="pickingStrategy"
      label="Picking strategy"
      defaultValue={PER_ORDER}
      options={OPTIONS}
      {...props}
    />
  )
}

export default PickingStrategyField
