import React, { useCallback, useMemo } from 'react'
import { useOrdering } from 'storfox-filter'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import ErrorLink from '~/components/ErrorLink'
import useMessages from '~/hooks/useMessages'
import { useTableSelects } from '~/components/TableValues/hooks'

import { useBrandList, useBrandsDelete } from '../hooks'
import { BrandOrderingForm, brandOrderingOptions, BrandTable } from '../components/BrandList'

function BrandListContainer () {
  const snackbar = useSnackbar()
  const messages = useMessages()

  const brandList = useBrandList()

  const ordering = useOrdering(brandOrderingOptions)
  const brandsDelete = useBrandsDelete()
  const { selects, resetTableSelects } = useTableSelects()

  const handleBrandsDelete = useCallback(
    () => brandsDelete.delete({ ids: selects })
      .then(() => brandList.getList())
      .then(() => snackbar({ message: messages.DELETE_SUCCESS }))
      .then(() => resetTableSelects())
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      }),
    [brandList, brandsDelete, resetTableSelects, messages.DELETE_SUCCESS, selects, snackbar]
  )

  const breadcrumbs = useMemo(() => ({ title: NAV.BRANDS }), [])
  const orderingMemoized = useMemo(() => ordering, [ordering])
  const list = useMemo(() => brandList, [brandList])

  return (
    <DashboardLayout
      title={NAV.BRANDS}
      activeNav={NAV.BRANDS}
      breadcrumbs={breadcrumbs}
    >
      <BrandOrderingForm {...orderingMemoized} />

      <BrandTable
        ordering={orderingMemoized}
        list={list}
        onBrandsDelete={handleBrandsDelete}
        onListRefetch={brandList.getListByParams}
        bulkActionLoading={brandsDelete.isLoading}
      />
    </DashboardLayout>
  )
}

export default BrandListContainer
