import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import { Filter } from 'storfox-filter'
import { pick, pipe, prop, propOr } from 'ramda'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { DateTimeField, PositiveFloatField } from 'storfox-form-fields'

import { arrayToParams, escapeAtob, omitEmptyObject, unescapeBtoa } from '~/utils'
import CountryField from '~/components/Fields/CountryField'
import TextField from '~/components/Fields/TextField'
import Accordion from '~/components/Accordion'
import * as API from '~/constants/api'
import WarehouseMultiSelectField from '~/components/Fields/WarehouseMultiSelectField'
import SearchField from '~/components/Fields/SearchField'
import { getDateTimeGMTDifference, getDateTimeGMTUser } from '~/utils/date'
import MultiSelectSearchField from '~/components/Fields/MultiSelectSearchField'

import {
  AllocationStatusField,
  FulfillmentStatusField,
  PaymentStatusField,
  SaleOrderStatusField,
  ShipmentStatusField,
  PaymentTypeField
} from './FilterFields'

import { FILTER_NAME as name } from '../../constants/SaleOrder'

const getCustomer = pipe(
  propOr({}, 'customer'),
  pick(['guid', 'name']),
  omitEmptyObject,
  unescapeBtoa
)

export const toParams = values => {
  const status = prop('status', values)
  const allocationStatus = prop('allocationStatus', values)
  const fulfillmentStatus = prop('fulfillmentStatus', values)
  const shipmentStatus = prop('shipmentStatus', values)
  const paymentStatus = prop('paymentStatus', values)
  const customer = getCustomer(values)
  const deliveryCountry = unescapeBtoa(prop('deliveryCountry', values))
  const deliveryCity = prop('deliveryCity', values)
  const paymentType = unescapeBtoa(prop('paymentType', values))
  const deliveryMethod = unescapeBtoa(prop('deliveryMethod', values))

  const channels = arrayToParams('channels', values)
  const companies = arrayToParams('companies', values)
  const warehouses = arrayToParams('warehouses', values)

  const totalPriceStart = prop('totalPriceStart', values)
  const totalPriceEnd = prop('totalPriceEnd', values)

  const lineItemsCountStart = prop('lineItemsCountStart', values)
  const lineItemsCountEnd = prop('lineItemsCountEnd', values)

  const createdAtStart = getDateTimeGMTDifference(prop('createdAtStart', values))
  const createdAtEnd = getDateTimeGMTDifference(prop('createdAtEnd', values))
  const updatedAtStart = getDateTimeGMTDifference(prop('updatedAtStart', values))
  const updatedAtEnd = getDateTimeGMTDifference(prop('updatedAtEnd', values))

  return {
    status,
    allocationStatus,
    fulfillmentStatus,
    shipmentStatus,
    paymentStatus,
    warehouses,
    customer,
    channels,
    companies,
    deliveryCountry,
    deliveryCity,
    deliveryMethod,
    createdAtStart,
    createdAtEnd,
    updatedAtStart,
    updatedAtEnd,
    totalPriceStart,
    totalPriceEnd,
    lineItemsCountStart,
    lineItemsCountEnd,
    paymentType
  }
}

export const toValues = params => {
  const status = prop('status', params)
  const allocationStatus = prop('allocationStatus', params)
  const fulfillmentStatus = prop('fulfillmentStatus', params)
  const shipmentStatus = prop('shipmentStatus', params)
  const paymentStatus = prop('paymentStatus', params)

  const customer = escapeAtob(prop('customer', params))
  const channels = escapeAtob(prop('channels', params))
  const companies = escapeAtob(prop('companies', params))
  const warehouses = escapeAtob(prop('warehouses', params))
  const paymentType = escapeAtob(prop('paymentType', params))
  const deliveryCountry = escapeAtob(prop('deliveryCountry', params))
  const deliveryCity = prop('deliveryCity', params)
  const deliveryMethod = escapeAtob(prop('deliveryMethod', params))

  const totalPriceStart = prop('totalPriceStart', params)
  const totalPriceEnd = prop('totalPriceEnd', params)

  const lineItemsCountStart = prop('lineItemsCountStart', params)
  const lineItemsCountEnd = prop('lineItemsCountEnd', params)

  const createdAtStart = getDateTimeGMTUser(prop('createdAtStart', params))
  const createdAtEnd = getDateTimeGMTUser(prop('createdAtEnd', params))
  const updatedAtStart = getDateTimeGMTUser(prop('updatedAtStart', params))
  const updatedAtEnd = getDateTimeGMTUser(prop('updatedAtEnd', params))

  return {
    status,
    allocationStatus,
    fulfillmentStatus,
    shipmentStatus,
    paymentStatus,
    warehouses,
    customer,
    channels,
    companies,
    deliveryCountry,
    deliveryCity,
    deliveryMethod,
    createdAtStart,
    createdAtEnd,
    updatedAtStart,
    updatedAtEnd,
    totalPriceStart,
    totalPriceEnd,
    lineItemsCountStart,
    lineItemsCountEnd,
    paymentType
  }
}
export const transformer = { toParams, toValues }

export const saleOrderFilterOptions = {
  name,
  transformer
}

function SaleOrderFilterForm ({ companyDisabled, ...props }) {
  const { t } = useTranslation()

  return (
    <Filter {...props}>
      <Accordion title="General" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <SaleOrderStatusField />
          </Grid>
          <Grid item={true} xs={12}>
            <WarehouseMultiSelectField api={API.SALE_ORDER_WAREHOUSE} />
          </Grid>
          <Grid item={true} xs={12}>
            <MultiSelectSearchField
              api={API.SALE_ORDER_CHANNEL}
              name="channels"
              data-cy="channel"
              label={t('Channel')}
              params={{
                active: true
              }}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <MultiSelectSearchField
              api={API.ORDER_COMPANIES}
              label="Company"
              name="companies"
              data-cy="companies"
              disabled={companyDisabled}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <CountryField
              data-cy="deliveryCountry"
              name="deliveryCountry"
              label="Delivery Country"
            />
          </Grid>
          <Grid item={true} xs={12}>
            <TextField
              data-cy="deliveryCity"
              name="deliveryCity"
              label="Delivery City"
            />
          </Grid>
          <Grid item={true} xs={12}>
            <SearchField
              api={API.ORDER_DELIVERY_METHOD}
              data-cy="deliveryMethod"
              name="deliveryMethod"
              label="Delivery Method"
            />
          </Grid>
          <Grid item={true} xs={12}>
            <PaymentTypeField />
          </Grid>
        </Grid>
      </Accordion>

      <Accordion title="Date">
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <Typography variant="body2" gutterBottom={true}>
              {t('Created')}:
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <DateTimeField name="createdAtStart" label="Start" />
              </Grid>
              <Grid item={true} xs={12}>
                <DateTimeField name="createdAtEnd" label="End" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography variant="body2" gutterBottom={true}>
              {t('Updated')}:
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <DateTimeField name="updatedAtStart" label="Start" />
              </Grid>
              <Grid item={true} xs={12}>
                <DateTimeField name="updatedAtEnd" label="End" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Accordion>
      <Accordion title="Statuses">
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <AllocationStatusField />
          </Grid>
          <Grid item={true} xs={12}>
            <FulfillmentStatusField />
          </Grid>
          <Grid item={true} xs={12}>
            <ShipmentStatusField />
          </Grid>
          <Grid item={true} xs={12}>
            <PaymentStatusField />
          </Grid>
        </Grid>
      </Accordion>
      <Accordion title="Total ($)">
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={6}>
            <TextField
              data-cy="totalPriceStart"
              name="totalPriceStart"
              label="Min ($)"
            />
          </Grid>
          <Grid item={true} xs={6}>
            <TextField
              data-cy="totalPriceEnd"
              name="totalPriceEnd"
              label="Max ($)"
            />
          </Grid>
        </Grid>
      </Accordion>
      <Accordion title="Line Items Count">
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={6}>
            <PositiveFloatField
              data-cy="lineItemsCountStart"
              name="lineItemsCountStart"
              label="Min count"
            />
          </Grid>
          <Grid item={true} xs={6}>
            <PositiveFloatField
              data-cy="lineItemsCountEnd"
              name="lineItemsCountEnd"
              label="Max count"
            />
          </Grid>
        </Grid>
      </Accordion>
    </Filter>
  )
}

SaleOrderFilterForm.propTypes = {
  companyDisabled: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default SaleOrderFilterForm
