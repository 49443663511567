import React from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop'
import PropTypes from 'prop-types'

import { MoreListButton } from '~/components/Buttons'
import * as ROUTES from '~/constants/routes'
import IconText from '~/components/IconText'

function More ({ tableRef }) {
  const { t } = useTranslation()

  return (
    <MoreListButton tableRef={tableRef}>
      {onClose => (
        <List>
          <ListItem
            disabled={false}
            button={true}
            component={Link}
            to={ROUTES.WAREHOUSE_IMPORT_PATH}
          >
            <IconText Icon={VerticalAlignTopIcon}>
              <ListItemText primary={t('Import')} />
            </IconText>
          </ListItem>
        </List>
      )}
    </MoreListButton>
  )
}

More.propTypes = {
  tableRef: PropTypes.object
}

export default More
