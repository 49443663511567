import {
  useCreate,
  useDelete,
  useDetail,
  useLimitedCountList,
  useUpdate,
  useScrollList,
  useList
} from 'storfox-api-hooks'
import { useAllSearchParams, usePickSearchParams } from 'storfox-route-hooks'
import { sprintf } from 'sprintf-js'

import { orderingToSnackCase, queryToParams } from '~/utils'
import * as API from '~/constants/api'

import { MovementFilterSerializer } from '../serializers'

export const useMovementList = () => {
  const searchParams = useAllSearchParams()
  const params = queryToParams(MovementFilterSerializer, searchParams, 'guid')

  return useLimitedCountList(API.MOVEMENT_LIST, params)
}

export const useMovementCreate = () => {
  return useCreate(API.MOVEMENT_CREATE)
}

export const useMovementCreateAndComplete = () => {
  return useCreate(API.MOVEMENT_CREATE_AND_COMPLETE)
}

export const useMovementUpdate = id => {
  const url = sprintf(API.MOVEMENT_UPDATE, id)

  return useUpdate(url)
}

export const useMovementUpdateAndComplete = id => {
  const url = sprintf(API.MOVEMENT_UPDATE_AND_COMPLETE, id)

  return useUpdate(url)
}

export const useMovementDetail = id => {
  const url = sprintf(API.MOVEMENT_DETAIL, id)

  return useDetail(url)
}

export const useMovementComplete = id => {
  const url = sprintf(API.MOVEMENT_COMPLETE, id)
  const { update, ...state } = useUpdate(url)

  return { ...state, complete: update }
}
export const useMovementCancel = id => {
  const url = sprintf(API.MOVEMENT_CANCEL, id)
  const { update, ...state } = useUpdate(url)

  return { ...state, cancel: update }
}

export const useMovementLineItems = (id, autoSend, params = {}) => {
  const searchParams = usePickSearchParams()
  const url = sprintf(API.MOVEMENT_LINE_ITEMS, id)
  return useList(url, { ...orderingToSnackCase(searchParams), ...params }, autoSend)
}

export const useMovementUnitNumber = () => {
  const { getDetail, ...state } = useDetail(API.UNIT_NUMBER_LIST, {}, false)

  return { ...state, scan: (params) => getDetail(params) }
}

export const useMovementsDelete = () => {
  return useDelete(API.MOVEMENTS_DELETE)
}

export const useMovementUnitList = params => {
  return useScrollList(API.UNIT_NUMBER_LIST, params)
}

export const useMovementHistory = (guid, autoSend) => {
  const searchParams = useAllSearchParams()
  const url = sprintf(API.MOVEMENT_HISTORY, guid)

  return useList(url, searchParams, autoSend)
}

export const useMovementImport = () => {
  return useCreate(API.MOVEMENT_UPLOAD)
}
