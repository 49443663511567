import { filter, includes, join, map, path, pick, pipe, prop, propOr, uniq } from 'ramda'
import { DEFAULT_SEARCH_PARAMS } from 'storfox-route-hooks'

import { escapeAtob } from '~/utils'

export const getUniqOrderNumbers = pipe(
  prop('orderNumbers'),
  uniq,
  join(',')
)

export const getUniqReferenceNumbers = pipe(
  propOr([], 'orderReferenceNumbers'),
  uniq,
  join(',')
)

const getNewToPendingItems = (values, guids) => pipe(
  propOr([], 'pickingJobs'),
  filter(item => includes(prop('guid', item), guids)),
  map(item => prop('guid', item))
)(values)

const getPendingToProgressItems = (values, guids) => pipe(
  propOr([], 'pickingJobs'),
  filter(item => includes(prop('guid', item), guids)),
  map(item => prop('guid', item))
)(values)

export const PicklistNewToPendingSerializer = (formValues, guids) => {
  return { guids: getNewToPendingItems(formValues, guids) }
}

export function PicklistPendingToProgressSerializer (formValues, guids) {
  return { guids: getPendingToProgressItems(formValues, guids) }
}

export const PicklistDetailPickSerializer = (formValues) => {
  const guid = prop('guid', formValues)
  const pickingGuid = prop('pickingGuid', formValues)
  const containerNumber = prop('containerNumber', formValues)
  const lineItems = prop('lineItems', formValues)

  const getLineItems = map(item => ({ containerNumber, guid, quantity: prop('quantity', item) }))

  return {
    guid: pickingGuid,
    lineItems: getLineItems(lineItems)
  }
}

export const PicklistDetailCompleteSerializer = (formValues) => {
  const guid = prop('guid', formValues)
  const toLocationId = path(['toLocationId', 'locationId'], formValues)

  return {
    guid,
    toLocationId
  }
}

export const PicklistUnitAlterSerializer = (values, guid) => {
  const unitGuid = prop('guid', values)
  const reason = prop('reason', values)
  const quantity = prop('alterQuantity', values)

  return {
    guid,
    lineItem: {
      guid: unitGuid,
      quantity
    },
    reason
  }
}

export const PicklistFilterSerializer = params => {
  const defaultParams = pick(DEFAULT_SEARCH_PARAMS, params)
  const status = prop('status', params)
  const warehouseIds = escapeAtob(prop('warehouses', params))
  const assignedToIds = escapeAtob(prop('assignedTo', params))
  const createdAtStart = prop('createdAtStart', params)
  const createdAtEnd = prop('createdAtEnd', params)
  const updatedAtStart = prop('updatedAtStart', params)
  const updatedAtEnd = prop('updatedAtEnd', params)
  const company = escapeAtob(prop('company', params))
  const clientCompanyId = prop('id', company)

  return {
    ...defaultParams,
    status,
    createdAtStart,
    createdAtEnd,
    updatedAtStart,
    updatedAtEnd,
    warehouseIds,
    assignedToIds,
    clientCompanyId
  }
}

export const PicklistAssignToSerializer = (guid, value) => {
  const pickerGuid = prop('guid', value)
  return { pickerGuid, guid }
}

export const PicklistDetailAssignToSerializer = (guid, values) => {
  const pickerGuid = path(['picker', 'guid'], values)

  return { pickerGuid, guid }
}

export function PicklistBatchSerializer (selectGuids) {
  return { guids: map(guid => guid, selectGuids) }
}
