import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

function FieldWrapper (Component) {
  function F ({ name, ...props }) {
    return (
      <Field
        {...props}
        name={name}
        component={Component}
      />
    )
  }

  F.propTypes = {
    name: PropTypes.string.isRequired,
  }

  return F
}

export default FieldWrapper
