import React from 'react'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material'

const CardStyled = styled(Card)(({ disabled }) => ({
  textAlign: 'center',
  height: '100%',
  ...(disabled && {
    background: 'rgba(0, 0, 0, .02)',
    filter: 'grayscale(100%)'
  }),
}))

const IconWrapperStyled = styled(Box)({
  '& > *': {
    width: '40%',
    height: 'auto',
    margin: 'auto'
  }
})

function VerticalTypeCard (props) {
  const {
    name,
    description,
    typeName,
    subtype,
    icon,
    path,
    disabled
  } = props

  const { t } = useTranslation()

  return (
    <CardStyled disabled={disabled}{...props}>
      <CardActionArea
        component={Link}
        disabled={disabled}
        to={path}
        href={path}
        data-cy={subtype}
        sx={{ height: '100%' }}
      >
        <CardContent sx={{ padding: 1 }}>
          <Typography variant="h6" component="h2">{t(name)}</Typography>
          <Typography gutterBottom={true} variant="body1" component="h2">{t(typeName)}</Typography>
          <Divider />
          <IconWrapperStyled>{icon}</IconWrapperStyled>
          <Divider />
          <Typography
            component="h2"
            variant="body1"
            sx={{ margin: 1, textAlign: 'left' }}
          >
            {t(description)}
          </Typography>
        </CardContent>
      </CardActionArea>
    </CardStyled>
  )
}

VerticalTypeCard.propTypes = {
  type: PropTypes.string.isRequired,
  typeName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  subtype: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  externalLink: PropTypes.string,
  disabled: PropTypes.bool
}

export default VerticalTypeCard
