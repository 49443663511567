import React from 'react'

import SelectField from '~/components/Fields/SelectField'

const OPTIONS = [
  { value: null, name: 'All' },
  { value: 'draft', name: 'Draft' },
  { value: 'completed', name: 'Completed' },
  { value: 'cancelled', name: 'Cancelled' },
]

function AdjustmentStatusField (props) {
  return (
    <SelectField
      name="status"
      label="Status"
      options={OPTIONS}
      {...props}
    />
  )
}

export default AdjustmentStatusField
