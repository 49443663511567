import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'

import { getSxArray } from '~/utils'

function VerticalAlignment ({ primary, secondary, className, sx, ...props }) {
  return (
    <Box
      className={className}
      sx={[
        {
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
        },
        ...getSxArray(sx)
      ]}
      {...props}
    >
      <div data-cy="unitName">{primary}</div>
      <div>{secondary}</div>
    </Box>
  )
}

VerticalAlignment.propTypes = {
  primary: PropTypes.any,
  secondary: PropTypes.any,
  className: PropTypes.string,
  sx: PropTypes.any,
}

export default VerticalAlignment
