import React from 'react'
import { prop } from 'ramda'

import * as API from '~/constants/api'
import SearchField from '~/components/Fields/SearchField'

const getOptionValue = item => {
  if (item) {
    const id = prop('id', item)
    const guid = prop('guid', item)
    const name = prop('name', item)

    return { id, guid, name }
  }

  return null
}

function BillingField ({ ...props }) {
  return (
    <SearchField
      data-cy="billingProfile"
      name="billingProfile"
      label="Billing profile"
      api={API.CONTRACT_BILLING_LIST}
      getOptionValue={getOptionValue}
      {...props}
    />
  )
}

export default BillingField
