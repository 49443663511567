import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import ru from './translations/ru.json'
import en from './translations/en.json'
import uz from './translations/uz.json'
import ar from './translations/ar.json'

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: en },
      ru: { translation: ru },
      uz: { translation: uz },
      ar: { translation: ar }
    },
    keySeparator: false,
    lng: 'en',
    fallbackLng: 'en',
    debug: false,
    nsSeparator: ':sep:',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  })

export default i18n
