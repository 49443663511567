import { useContext, useEffect } from 'react'

import ThemeContext from './context'

export const useCurrentTheme = () => {
  const { theme } = useContext(ThemeContext)
  return theme
}

export const useChangeTheme = theme => {
  const { selectTheme } = useContext(ThemeContext)

  useEffect(() => {
    selectTheme(theme)
  }, [selectTheme, theme])
}

export const useTheme = () => {
  const { selectTheme } = useContext(ThemeContext)

  return { handleThemeChange: selectTheme }
}
