import React from 'react'
import { prop } from 'ramda'
import { useAllSearchParams } from 'storfox-route-hooks'
import { useNavigate } from 'react-router-dom'
import { useDeepCompareEffect } from 'storfox-api-hooks'
import { useSnackbar } from 'storfox-snackbar'

import { escapeAtob, unescapeBtoa } from '~/utils'
import { DashboardLayout } from '~/components/Layouts'
import { useNotification } from '~/components/Notification'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import { useProgressCard } from '~/components/ProgressCard'
import {
  WAREHOUSE_BULK_CREATE_COMPLETE,
  WAREHOUSE_BULK_CREATE_STATUS,
  WAREHOUSE_BULK_VALIDATE_COMPLETE,
  WAREHOUSE_BULK_VALIDATE_STATUS
} from '~/constants/notification-topics'
import { useGoogleEvent } from '~/components/GoogleAnalytics/GoogleAnalytics'
import useMessages from '~/hooks/useMessages'

import WarehouseImport from '../components/WarehouseImport/WarehouseImport'
import { useWarehouseImport } from '../hooks'

function WarehouseImportContainer () {
  const warehouseImport = useWarehouseImport()
  const navigate = useNavigate()
  const messages = useMessages()
  const snackbar = useSnackbar()
  const { sendEvent } = useGoogleEvent()
  const { payload } = useAllSearchParams()

  const progressCard = useProgressCard()

  const validateStatus = useNotification(WAREHOUSE_BULK_VALIDATE_STATUS)
  const validateComplete = useNotification(WAREHOUSE_BULK_VALIDATE_COMPLETE)
  const uploadComplete = useNotification(WAREHOUSE_BULK_CREATE_COMPLETE)
  const uploadStatus = useNotification(WAREHOUSE_BULK_CREATE_STATUS)

  const key = prop('key', escapeAtob(payload))

  useDeepCompareEffect(() => {
    if (key) {
      validateStatus.subscribe(key, ({ payload }) => {
        const result = prop('result', payload)
        const validated = prop('totalValidated', result)
        const total = prop('total', result)

        progressCard.handleValidationChange({ current: validated, total })
      })

      validateComplete.subscribe(key, ({ payload }) => {
        navigate(
          `${ROUTES.WAREHOUSE_IMPORT_PATH}?payload=${unescapeBtoa(payload)}`
          , { replace: true })

        validateComplete.setLoading(false)
      })
      uploadStatus.subscribe(key, ({ payload }) => {
        const result = prop('result', payload)
        const completed = prop('totalCreated', result)
        const total = prop('total', result)
        if (!uploadComplete.isLoading) {
          uploadComplete.setLoading(true)
          progressCard.handleValidationChange({ current: total, total })
        }
        progressCard.handleCompletionChange({ current: completed, total })
      })

      uploadComplete.subscribe(key, () => {
        navigate(ROUTES.WAREHOUSE_LIST_PATH, { replace: true })
        uploadComplete.setLoading(false)
        sendEvent({ eventAction: 'Upload File Location', eventCategory: 'Location' })
      })
    }

    return () => {
      validateStatus.unsubscribe()
      validateComplete.unsubscribe()
      uploadStatus.unsubscribe()
      uploadComplete.unsubscribe()
    }
  }, [key])

  const handleSubmit = () => {
    uploadComplete.setLoading(true)
    return warehouseImport.create({ key })
      .then(() => snackbar({ message: messages.IMPORT_REQUEST_SUCCESS }))
  }

  const handleSubscribe = key => {
    validateComplete.setLoading(true)
    navigate(`${ROUTES.WAREHOUSE_IMPORT_PATH}?payload=${unescapeBtoa({ key })}`, { replace: true })
  }

  const handleFileRemove = () => {
    validateComplete.unsubscribe()
    uploadComplete.unsubscribe()
    validateStatus.unsubscribe()
    uploadStatus.unsubscribe()
    progressCard.resetValues()
  }

  const title = 'Import warehouses'

  const isLoading = (
    warehouseImport.isLoading ||
    validateComplete.isLoading ||
    uploadComplete.isLoading
  )

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      activeNav={NAV.WAREHOUSES}
      isLoading={isLoading}
      breadcrumbs={breadcrumbs}
    >
      <WarehouseImport
        isLoading={isLoading}
        title={title}
        onSubscribe={handleSubscribe}
        onSubmit={handleSubmit}
        progressCard={progressCard}
        onFileRemove={handleFileRemove}
      />
    </DashboardLayout>
  )
}

export default WarehouseImportContainer
