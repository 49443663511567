import { defaultTo, filter, includes, map, path, pick, pipe, prop, propOr } from 'ramda'
import { DEFAULT_SEARCH_PARAMS } from 'storfox-route-hooks'

import { escapeAtob, convertToSlug, getDateTimeMidnight } from '~/utils'

const getVariantFromOptions = (guid, values) => pipe(
  defaultTo([]),
  filter(item => item.guid === guid),
  prop(0)
)(values)

const getListingVariants = (variant, values) => {
  const variantGuid = path(['variant', 'guid'], values)

  return pipe(
    propOr([], 'listingVariants'),
    map(item => item.guid === variantGuid ? variant : item)
  )(values)
}

const getListingFromSelected = (guids, list) => pipe(
  defaultTo([]),
  filter(item => includes(prop('guid', item), guids)),
  map(item => {
    const type = prop('type', item)
    const guid = prop('guid', item)

    return { guid, type }
  })
)(list)

export function ListingSerializer (selected, list) {
  const listingList = prop('results', list)
  const listings = getListingFromSelected(selected, listingList)

  return { listings }
}

export function ListingInitSerializer (guid, values) {
  const listingVariants = prop('listingVariants', values)
  const variantFromOptions = getVariantFromOptions(guid, listingVariants)

  return {
    ...values,
    variant: variantFromOptions,
    oldVariant: variantFromOptions
  }
}

export function ListingUpdateSerializer (values) {
  const slug = convertToSlug(path(['variant', 'slug'], values))
  const variant = { ...prop('variant', values), slug }
  const listingVariants = getListingVariants(variant, values)
  const name = prop('name', values)
  const description = prop('description', values)
  const brand = prop('brand', values)
  const category = prop('category', values)
  const images = prop('images', values)
  const maximumRetailPrice = prop('maximumRetailPrice', values)
  const retailPrice = prop('retailPrice', values)
  const tags = prop('tags', values)

  return {
    name,
    description,
    brand,
    category,
    images,
    listingVariants,
    retailPrice,
    maximumRetailPrice,
    tags
  }
}

export function ListingFilterSerializer (params) {
  const defaultParams = pick(DEFAULT_SEARCH_PARAMS, params)
  const status = prop('status', params)
  const categoryGuid = escapeAtob(prop('category', params))
  const brandGuid = escapeAtob(prop('brand', params))
  const channelGuid = escapeAtob(prop('channel', params))
  const updatedAtStart = getDateTimeMidnight(prop('updatedAtStart', params))
  const updatedAtEnd = getDateTimeMidnight(prop('updatedAtEnd', params))
  const retailPriceStart = prop('retailPriceStart', params)
  const retailPriceEnd = prop('retailPriceEnd', params)

  return {
    ...defaultParams,
    status,
    categoryGuid,
    brandGuid,
    channelGuid,
    updatedAtStart,
    updatedAtEnd,
    retailPriceStart,
    retailPriceEnd,
  }
}
