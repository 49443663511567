import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'

import TextOverflow from '../TextOverflow'

function Subtext ({ children, lines, ...props }) {
  return lines ? (
    <TextOverflow lines={lines} {...props}>
      <Typography
        component="h6"
        variant="caption"
        color="textSecondary"
      >
        {children}
      </Typography>
    </TextOverflow>
  ) : (
    <Typography
      component="h6"
      variant="caption"
      color="textSecondary"
    >
      {children}
    </Typography>
  )
}

Subtext.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node
  ]).isRequired,
  lines: PropTypes.number
}

export default Subtext
