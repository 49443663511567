import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'

import Tooltip from '../HtmlTooltip/HtmlTooltip'

const RootStyled = styled(Box, {
  shouldForwardProp: (propName) => propName !== 'lines'
})(({ lines }) => ({
  WebkitLineClamp: `${lines}`,
  WebkitBoxOrient: 'vertical',
  display: '-webkit-box',
  overflow: 'hidden',
  maxWidth: '100%'
}))

const NoWrapStyled = styled('span', {
  shouldForwardProp: propName => propName !== 'noWrap'
})(({ noWrap }) => ({
  ...(noWrap && {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    verticalAlign: 'middle',
    width: '100%'
  })
}))

function TextOverflow (props) {
  const {
    children,
    noWrap,
    selfTooltip,
    tooltip = '',
    lines = 2,
    ...rest
  } = props

  const ttp = selfTooltip ? children : tooltip

  if (ttp) {
    return (
      <Tooltip title={ttp}>
        <RootStyled {...rest} lines={lines}>
          <NoWrapStyled noWrap={noWrap}>{children}</NoWrapStyled>
        </RootStyled>
      </Tooltip>
    )
  }

  return (
    <RootStyled lines={lines} {...rest}>
      <span>{children}</span>
    </RootStyled>
  )
}

TextOverflow.propTypes = {
  children: PropTypes.any,
  noWrap: PropTypes.bool,
  lines: PropTypes.number,
  selfTooltip: PropTypes.bool,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
}

export default TextOverflow
