import React from 'react'
import PropTypes from 'prop-types'

import Status from '~/components/Status'

import { PUTAWAY_LINE_ITEM_LABEL, PUTAWAY_LINE_ITEM_STATUS } from '../../constants'

const COLOR = {
  [PUTAWAY_LINE_ITEM_STATUS.PICKED]: 'warning',
  [PUTAWAY_LINE_ITEM_STATUS.PUT]: 'success',
}

function LineItemStatus ({ value }) {
  return (
    <Status
      label={PUTAWAY_LINE_ITEM_LABEL[value]}
      color={COLOR[value]}
    />
  )
}

LineItemStatus.propTypes = {
  value: PropTypes.oneOf([
    PUTAWAY_LINE_ITEM_STATUS.PICKED,
    PUTAWAY_LINE_ITEM_STATUS.PUT,
  ]).isRequired
}

export default LineItemStatus
