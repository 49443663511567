import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import PropTypes from 'prop-types'
import CardHeader from '@mui/material/CardHeader'

function ContainerizeCard ({ children }) {
  return (
    <Card sx={{
      minHeight: '100vh',
      height: '100%'
    }}>
      <CardHeader title="Containerize" />
      <Divider />
      <CardContent>
        {children}
      </CardContent>
    </Card>
  )
}

ContainerizeCard.propTypes = {
  children: PropTypes.any.isRequired
}

export default ContainerizeCard
