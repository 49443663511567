import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { map, prop } from 'ramda'
import { generatePath } from 'react-router-dom'

import { MoreListButton, NewButton } from '~/components/Buttons'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import TextOverflow from '~/components/TextOverflow'
import { Table, TableHeader } from '~/components/Table'
import * as ROUTES from '~/constants/routes'
import TableLink from '~/components/Link'
import { ROLE_TABS } from '~/constants/tabs'

const getDetailPath = guid => {
  const params = { guid, tab: ROLE_TABS.GENERAL }
  return generatePath(ROUTES.ROLE_UPDATE_PATH, params)
}

export const ROLE_COLUMNS = [
  {
    width: 350,
    headerName: 'Name',
    field: 'name',
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        <TextOverflow selfTooltip={true}>{value}</TextOverflow>
      </TableLink>
    )
  },
  {
    width: 600,
    headerName: 'Description',
    field: 'description',
    renderCell: ({ value }) => (
      <TextOverflow selfTooltip={true}>{value}</TextOverflow>
    )
  },
  {
    width: 200,
    headerName: 'Users',
    field: 'count',
    headerAlign: 'center',
    align: 'center'
  }
]

const getRowsFromResults = map(item => {
  const id = prop('id', item)
  const guid = prop('guid', item)
  const name = prop('name', item)
  const description = prop('description', item)
  const count = prop('usersCount', item)
  const link = getDetailPath(guid)

  return {
    guid,
    id,
    name,
    description,
    count,
    link
  }
})

function RoleTable ({ list, ordering, onListRefetch }) {
  const [tableRef, setTableRef] = useState(null)
  const isLoading = prop('isLoading', list)
  const results = prop('results', list)
  const count = prop('count', list)
  const columns = prop('filteredColumns', ordering)

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle="Roles"
          parentTitle="Settings"
          rightButton={(
            <NewButton path={ROUTES.ROLE_CREATE_PATH} />
          )}
        />
      </Header>

      <Content>
        <TableHeader
          orderingOpen={ordering.handleOpen}
          ordering={ordering}
        >
          <MoreListButton tableRef={tableRef} />
        </TableHeader>
        <Table
          isLoading={isLoading}
          columns={columns}
          getRowsFromResults={getRowsFromResults}
          results={results}
          count={count}
          pinnedColumns={['name']}
          checkboxSelection={true}
          ordering={ordering}
          onRefetch={onListRefetch}
          setTableRef={setTableRef}
        />
      </Content>
    </Container>
  )
}

RoleTable.propTypes = {
  list: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  onListRefetch: PropTypes.func.isRequired,
}

export default RoleTable
