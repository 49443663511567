import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import MuiMenuItem from '@mui/material/MenuItem'

const MenuItem = React.forwardRef(({ children, onClick, ...props }, ref) => {
  return (
    <MuiMenuItem ref={ref} onClick={onClick} {...props}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        color: 'text.disabled'
      }}>
        {children}
      </Box>
    </MuiMenuItem>
  )
})

MenuItem.propTypes = {
  children: PropTypes.any.isRequired,
  onClick: PropTypes.func.isRequired
}

export default MenuItem
