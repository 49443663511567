import React from 'react'
import { MultiSelectField as StorfoxMultiSelectField } from 'storfox-form-fields'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

function MultiSelectField ({ label, options, ...props }) {
  const { t } = useTranslation()

  const translatedOptions = options.map(option => ({ ...option, name: t(option.name) }))

  return (
    <StorfoxMultiSelectField
      label={t(label)}
      options={translatedOptions}
      {...props}
    />
  )
}

MultiSelectField.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array
}

export default MultiSelectField
