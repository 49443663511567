import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActionArea from '@mui/material/CardActionArea'
import Typography from '@mui/material/Typography'
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box'
import { prop } from 'ramda'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material'

import { IntegrationIcon } from '~/components/Icons'

import IntegrationStatus from './IntegrationStatus'

import { NAMES_BY_SUBTYPES } from '../../constants'

const CardStyled = styled(Card)({
  height: '100%',
  '& > *': {
    height: '100%'
  }
})

const IntegrationIconStyled = styled(IntegrationIcon)({
  minWidth: 60,
  minHeight: 60,
  width: 60,
  height: 60
})

const TitleStyled = styled(Typography)({
  overflow: 'hidden',
  whiteSpace: 'pre',
  textOverflow: 'ellipse',
  maxWidth: '240px'
})

const DescriptionStyled = styled(Typography)({
  height: '60px',
  display: '-webkit-box',
  WebkitLineClamp: '3',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden'
})

function IntegrationCard ({ title, path, description, index, type, status }) {
  const { t } = useTranslation()
  const typeName = prop(type, NAMES_BY_SUBTYPES)

  return (
    <CardStyled>
      <CardActionArea component={Link} to={path} data-cy={`integrationCard-${index}`}>
        <CardContent sx={{ p: 2 }}>
          <IntegrationStatus status={status} />
          <Box display="flex" alignItems="flexEnd">
            <IntegrationIconStyled type={type} />
            <Box mb={1} ml={1} display="flex" flexDirection="column" justifyContent="flex-end">
              <TitleStyled component="h3" variant="h5">
                {title}
              </TitleStyled>
              <Typography variant="caption">{t(typeName)}</Typography>
            </Box>
          </Box>
          <DescriptionStyled variant="body1">{description}</DescriptionStyled>
        </CardContent>
      </CardActionArea>
    </CardStyled>
  )
}

IntegrationCard.propTypes = {
  description: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
}

export default IntegrationCard
