import React from 'react'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { prop, path } from 'ramda'
import PropTypes from 'prop-types'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import TableContainer from '@mui/material/TableContainer'
import { generatePath, useNavigate } from 'react-router-dom'
import { styled } from '@mui/material'

import EmptyNotesIcon from '~/components/Icons/EmptyNotesIcon'
import EmptyLineItems from '~/components/EmptyLineItems/EmptyLineItems'
import SkeletonList from '~/components/Skeleton'
import TableDateFormat from '~/components/TableDateFormat/TableDateFormat'
import { SALE_RETURN_TABS } from '~/constants/tabs'
import * as ROUTES from '~/constants/routes'
import Money from '~/components/Money'
import ReturnStatus from '~/components/Statuses/ReturnStatus'
import { ShipmentStatus } from '~/components/Statuses'
import ReceiveStatus from '~/components/Statuses/ReceiveStatus'
import RefundStatus from '~/components/Statuses/RefundStatus'
import TableNotes from '~/components/TableNotes'
import { HoverableRow } from '~/components/Table'

const TableRowStyled = styled(HoverableRow)({
  height: '70px'
})

const TableCellStyled = styled(TableCell)({
  minWidth: '150px'
})

function SaleOrderReturnTable ({ returnList }) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const isLoading = prop('isLoading', returnList)
  const results = prop('results', returnList)

  const renderEmptyLines = Boolean(!isLoading && !results.length)
  const renderLineItems = Boolean(!isLoading && results.length)

  return (
    <Box component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto' }}>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCellStyled>{t('ID')}</TableCellStyled>
              <TableCellStyled>{t('Reference #')}</TableCellStyled>
              <TableCellStyled>{t('Line items')}</TableCellStyled>
              <TableCellStyled>{t('Qty')}</TableCellStyled>
              <TableCellStyled>{t('Total')}</TableCellStyled>
              <TableCellStyled>{t('Status')}</TableCellStyled>
              <TableCellStyled>{t('Shipment status')}</TableCellStyled>
              <TableCellStyled>{t('Receiving status')}</TableCellStyled>
              <TableCellStyled>{t('Refund status')}</TableCellStyled>
              <TableCellStyled>{t('Customer')}</TableCellStyled>
              <TableCellStyled>{t('City')}</TableCellStyled>
              <TableCellStyled>{t('Created date')}</TableCellStyled>
              <TableCellStyled>{t('Last updated')}</TableCellStyled>
              <TableCellStyled>{t('Notes')}</TableCellStyled>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <SkeletonList
                rowCount={3}
                columnCount={13}
                height={20}
                rowHeight={70}
              />
            )}

            {renderEmptyLines && (
              <EmptyLineItems
                colSpan={13}
                icon={<EmptyNotesIcon />}
                text="No return by sale order"
              />
            )}
            {renderLineItems && results.map((row, index) => {
              const guid = prop('guid', row)
              const number = prop('number', row)
              const referenceNumber = path(['order', 'referenceNumber'], row)
              const lineItems = prop('lineItemsQty', row)
              const quantity = prop('itemsQty', row)
              const totalPrice = <Money value={prop('totalPrice', row)} />
              const firstName = path(['customer', 'firstName'], row)
              const lastName = path(['customer', 'lastName'], row)
              const customer = `${firstName} ${lastName}`
              const city = path(['order', 'deliveryAddress', 'city'], row)

              const returnUrl = generatePath(ROUTES.RETURN_DETAIL_PATH, { guid, tab: SALE_RETURN_TABS.GENERAL })

              return (
                <TableRowStyled key={index} onClick={() => navigate(returnUrl)}>
                  <TableCell>{number}</TableCell>
                  <TableCell>{referenceNumber}</TableCell>
                  <TableCell>{lineItems}</TableCell>
                  <TableCell>{quantity}</TableCell>
                  <TableCell>{totalPrice}</TableCell>
                  <TableCell>
                    <ReturnStatus value={prop('status', row)} />
                  </TableCell>
                  <TableCell>
                    <ShipmentStatus value={prop('shipmentStatus', row)} />
                  </TableCell>
                  <TableCell>
                    <ReceiveStatus value={prop('receivingStatus', row)} />
                  </TableCell>
                  <TableCell>
                    <RefundStatus value={prop('refundStatus', row)} />
                  </TableCell>
                  <TableCell>{customer}</TableCell>
                  <TableCell>{city}</TableCell>
                  <TableCell>
                    <TableDateFormat withTime={true} date={prop('createdAt', row)} />
                  </TableCell>
                  <TableCell>
                    <TableDateFormat withTime={true} date={prop('updatedAt', row)} />
                  </TableCell>
                  <TableCell>
                    <TableNotes>{prop('notes', row)}</TableNotes>
                  </TableCell>
                </TableRowStyled>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

SaleOrderReturnTable.propTypes = {
  returnList: PropTypes.object.isRequired
}

export default SaleOrderReturnTable
