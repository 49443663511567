import React from 'react'

import SelectField from '~/components/Fields/SelectField'

const OPTIONS = [
  {
    value: 'D',
    name: 'Delivery only'
  },
  {
    value: 'DA',
    name: 'Delivery and Assembly'
  }
]

function OrderTypeField (props) {
  return (
    <SelectField
      label="Order type"
      options={OPTIONS}
      {...props}
    />
  )
}

export default OrderTypeField
