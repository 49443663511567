import React from 'react'
import { useAllSearchParams } from 'storfox-route-hooks'
import { generatePath, useNavigate } from 'react-router-dom'
import { prop } from 'ramda'
import { useSnackbar } from 'storfox-snackbar'

import AppContainer from '~/components/AppContainer'
import { CenteredLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'
import { INTEGRATION_TABS } from '~/constants/tabs'
import useMessages from '~/hooks/useMessages'

import WooCommerceReturn from '../../components/WooCommerce/WooCommerceReturn'
import { useWooCommerceReturn } from '../../hooks'

function WooCommerceReturnContainer () {
  const params = useAllSearchParams()
  const wooCommerceReturn = useWooCommerceReturn()
  const navigate = useNavigate()
  const messages = useMessages()
  const snackbar = useSnackbar()

  const handleSubmit = () =>
    wooCommerceReturn.create(params)
      .then(({ result }) => {
        const guid = prop('guid', result)
        const type = prop('type', result)
        const params = { guid, type, tab: INTEGRATION_TABS.GENERAL }
        const detailPath = generatePath(ROUTES.INTEGRATION_UPDATE_PATH, params)
        navigate(detailPath)
      })
      .then(() => snackbar({ message: messages.CREATE_SUCCESS }))

  return (
    <CenteredLayout>
      <AppContainer>
        <WooCommerceReturn onSubmit={handleSubmit} />
      </AppContainer>
    </CenteredLayout>
  )
}

export default WooCommerceReturnContainer
