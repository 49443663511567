import React from 'react'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import * as colors from '@mui/material/colors'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { Box, styled } from '@mui/material'

import EmptyImage from '../Icons/empty-image.png'

const DropZoneStyled = styled(Box, {
  shouldForwardProp: propName => propName !== 'isDragActive'
})(({ theme, isDragActive }) => ({
  border: `1px dashed ${theme.palette.divider}`,
  borderRadius: 5,
  height: 200,
  outline: 'none',
  display: 'flex',
  width: '100%',
  textAlign: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
  alignItems: 'center',
  transition: '.25s ease all',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.background.dark,
    cursor: 'pointer'
  },
  '&:hover > div': {
    opacity: 0.5,
  },
  ...(isDragActive && ({
    backgroundColor: colors.grey[50],
    opacity: 0.5
  }))

}))

const CardContentStyled = styled(CardContent)({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  transition: '.25s ease all',
  color: '#9EA1B6'
})

function Dropzone ({ onDrop, accept }) {
  const {
    getRootProps,
    getInputProps,
    isDragActive
  } = useDropzone({ onDrop })

  return (

    <Box display="flex" justifyContent="center">
      <DropZoneStyled isDragActive={isDragActive} {...getRootProps()}>
        <CardContentStyled>
          <input {...getInputProps()} multiple={false} accept={accept} />
          <div>
            <input {...getInputProps()} accept={accept} />
            <Box sx={{
              textAlign: 'center',
              '& > img': {
                width: 20,
                height: 'auto',
              }
            }}>
              <img src={EmptyImage} alt="drop image here" />
            </Box>
            <Typography align="center" variant="subtitle1" color="inherit">
              Drop your image here, or select
            </Typography>
            <Typography align="center" variant="subtitle1" color="secondary">
              click to browse
            </Typography>
          </div>
        </CardContentStyled>
      </DropZoneStyled>
    </Box>
  )
}

Dropzone.propTypes = {
  onDrop: PropTypes.func.isRequired,
  accept: PropTypes.string
}

export default Dropzone
