import React from 'react'
import { prop } from 'ramda'

import * as API from '~/constants/api'
import MultiSelectSearchField from '~/components/Fields/MultiSelectSearchField'

const getOptionValue = value => {
  if (value) {
    const guid = prop('id', value)
    const name = prop('name', value)

    return { guid, name }
  }

  return null
}

function UserRoleField (props) {
  return (
    <MultiSelectSearchField
      api={API.ROLE_LIST}
      data-cy="roles"
      name="roles"
      label="Roles"
      getOptionValue={getOptionValue}
      {...props}
    />
  )
}

export default UserRoleField
