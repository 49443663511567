import React from 'react'
import PropTypes from 'prop-types'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

import CountryField from '~/components/Fields/CountryField'
import CurrencyField from '~/components/Fields/CurrencyField'
import EmailField from '~/components/Fields/EmailField'
import LanguageField from '~/components/Fields/LanguageField'
import PasswordGenerateField from '~/components/Fields/PasswordGenerateField'
import PhoneNumberField from '~/components/Fields/PhoneNumberField'
import StatusField from '~/components/Fields/StatusField'
import TextField from '~/components/Fields/TextField'
import TimeZoneField from '~/components/Fields/TimeZoneField'
import { CardHeader } from '~/components/Cards'
import { withForm } from '~/components/Form'
import { DiscardButton, SaveButton } from '~/components/Buttons'
import PageTitle from '~/components/PageTitle'
import Image from '~/components/CompanyImage'
import Container, { Content, Header } from '~/components/Container'
import * as NAV from '~/constants/nav-titles'

function CompanyCreateForm ({ title, isLoading, form }) {
  const { handleSubmit } = form

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={title}
            parentTitle={NAV.COMPANIES}
            rightButton={(
              <>
                <DiscardButton disabled={isLoading} />
                <SaveButton disabled={isLoading} />
              </>
            )}
          />
        </Header>

        <Content>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12} lg={8}>
              <Box mb={3}>
                <Card>
                  <CardHeader title="Information" />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12}>
                        <TextField
                          data-cy="name"
                          name="name"
                          label="Company Name"
                        />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <EmailField
                          data-cy="email"
                          name="email"
                          label="Email"
                        />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <PhoneNumberField
                          data-cy="phone"
                          name="phone"
                          label="Phone Number"
                        />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <TextField
                          data-cy="address"
                          name="address"
                          label="Address"
                          multiline={true}
                          minRows={3}
                        />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <CountryField
                          data-cy="country"
                          name="country"
                          label="Country"
                        />
                      </Grid>

                      <Grid item={true} xs={12} lg={6}>
                        <TextField
                          data-cy="city"
                          name="city"
                          label="City"
                          country="country"
                        />
                      </Grid>

                      <Grid item={true} xs={12} lg={6}>
                        <TextField
                          data-cy="zipcode"
                          name="zipcode"
                          label="Zipcode"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
              <Card>
                <CardHeader title="Primary account holder" />
                <CardContent>
                  <Grid container={true} spacing={3}>
                    <Grid item={true} xs={12} lg={6}>
                      <TextField
                        data-cy="ownerFirstName"
                        name="ownerFirstName"
                        label="First name"
                      />
                    </Grid>

                    <Grid item={true} xs={12} lg={6}>
                      <TextField
                        data-cy="ownerLastName"
                        name="ownerLastName"
                        label="Last name"
                      />
                    </Grid>

                    <Grid item={true} xs={12}>
                      <EmailField
                        data-cy="ownerEmail"
                        name="ownerEmail"
                        label="Email"
                      />
                    </Grid>

                    <Grid item={true} xs={12}>
                      <PhoneNumberField
                        data-cy="ownerPhone"
                        name="ownerPhone"
                        label="Phone number"
                      />
                    </Grid>

                    <Grid item={true} xs={12}>
                      <PasswordGenerateField
                        data-cy="ownerPassword"
                        name="ownerPassword"
                        label="Password"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item={true} xs={12} lg={4}>
              <Box mb={3}>
                <Card>
                  <Image title="Add Logo" />
                </Card>
              </Box>
              <Box mb={3}>
                <Card>
                  <CardHeader title="Settings" />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12}>
                        <StatusField />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <LanguageField />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <TimeZoneField countryName="country" />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <CurrencyField />
                      </Grid>

                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Grid>

          </Grid>
          <Box mb={3} />
        </Content>
      </Container>
    </form>
  )
}

CompanyCreateForm.propTypes = {
  title: PropTypes.string.isRequired,
  form: PropTypes.object,
  isLoading: PropTypes.bool.isRequired
}

export default withForm(CompanyCreateForm)
