import React from 'react'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'

import ShopifyInstruction from '../../components/Shopify/ShopifyInstruction'

function ShopifyInstructionContainer () {
  const title = 'New Shopify integration'

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      activeNav={NAV.INTEGRATION}
      breadcrumbs={breadcrumbs}
    >
      <ShopifyInstruction />
    </DashboardLayout>
  )
}

export default ShopifyInstructionContainer
