import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import { Filter } from 'storfox-filter'
import { prop } from 'ramda'

import { escapeAtob, unescapeBtoa } from '~/utils'
import CustomerSearchField from '~/components/Fields/CustomerSearchField'
import TextField from '~/components/Fields/TextField'
import WarehouseMultiSelectField from '~/components/Fields/WarehouseMultiSelectField'
import Accordion from '~/components/Accordion'

import ZoneStatusField from './ZoneStatusField'

export const toParams = values => {
  const status = prop('status', values)
  const supplier = unescapeBtoa(prop('supplier', values))
  const warehouse = unescapeBtoa(prop('warehouse', values))
  const minTotal = prop('minTotal', values)
  const maxTotal = prop('maxTotal', values)

  return {
    status,
    supplier,
    warehouse,
    minTotal,
    maxTotal
  }
}

export const toValues = (params) => {
  const status = prop('status', params)
  const supplier = escapeAtob(prop('supplier', params))
  const warehouse = escapeAtob(prop('warehouse', params))
  const minTotal = prop('minTotal', params)
  const maxTotal = prop('maxTotal', params)

  return {
    status,
    supplier,
    warehouse,
    minTotal,
    maxTotal
  }
}

export const name = 'zone'
export const transformer = { toParams, toValues }

export const zoneFilterOptions = {
  name,
  transformer
}

function ZoneFilterForm (props) {
  return (
    <Filter {...props}>
      <Accordion title="General" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <ZoneStatusField />
          </Grid>
          <Grid item={true} xs={12}>
            <CustomerSearchField />
          </Grid>
          <Grid item={true} xs={12}>
            <WarehouseMultiSelectField />
          </Grid>
        </Grid>
      </Accordion>
      <Accordion title="Total ($)">
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={6}>
            <TextField
              data-cy="minTotal"
              name="minTotal"
              label="Min ($)"
            />
          </Grid>
          <Grid item={true} xs={6}>
            <TextField
              data-cy="maxTotal"
              name="maxTotal"
              label="Max ($)"
            />
          </Grid>
        </Grid>
      </Accordion>
    </Filter>
  )
}

ZoneFilterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired
}

export default ZoneFilterForm
