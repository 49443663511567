import { compose, defaultTo, join, map, prop, split } from 'ramda'

const toSnake = str => {
  return str
    .replace(/\./g, '__')
    .replace(/([A-Z])/g, $1 => '_' + $1.toLowerCase())
}

const orderingToSnackCase = obj => {
  const ordering = compose(
    join(','),
    map(toSnake),
    split(','),
    defaultTo(''),
    prop('ordering')
  )(obj)

  if (ordering) {
    return { ...obj, ordering }
  }

  return obj
}

export default orderingToSnackCase
