import React from 'react'
import { useTranslation } from 'react-i18next'
import SelectField from 'storfox-form-fields/src/SelectField'

export const DAMAGED = 'damaged'
export const MISSING = 'missing'

const options = [
  { value: DAMAGED, name: 'Damaged' },
  { value: MISSING, name: 'Missing' },
]

function AlterTypeField ({ ...props }) {
  const { t } = useTranslation()

  return (
    <SelectField
      data-cy="reason"
      name="reason"
      label={t('Reason')}
      defaultValue={DAMAGED}
      options={options}
      required={true}
      {...props}
    />
  )
}

export default AlterTypeField
