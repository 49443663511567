import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'

import BarcodeInfo from '~/components/Icons/BarcodeInfo'

const RootStyled = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '24px',
  marginTop: '12px',
  '& span': {
    marginLeft: '14px',
    color: '#263238',
    fontSize: '24px',
    fontWeight: 500
  }
})

function BarcodeTitle ({ isScanSerial, isReadyToComplete, isScanningFinished }) {
  const scanTitle = () => {
    if (isScanSerial) {
      return 'Scan serial number'
    }

    if (isReadyToComplete) {
      return 'Scan DONE to save'
    }

    if (isScanningFinished) {
      return 'Scan container or DONE'
    }

    return 'Scan barcode or DONE'
  }

  return (
    <RootStyled>
      <BarcodeInfo />
      <span>{scanTitle()}</span>
    </RootStyled>
  )
}

BarcodeTitle.propTypes = {
  isScanSerial: PropTypes.bool.isRequired,
  isReadyToComplete: PropTypes.bool.isRequired,
  isScanningFinished: PropTypes.bool.isRequired
}

export default BarcodeTitle
