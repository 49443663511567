import React from 'react'
import PropTypes from 'prop-types'

import {
  BLIND_RECEIVE,
  INVOICE_RECEIVE,
  OVER_RECEIVE,
  OVER_UNDER_RECEIVE,
  UNDER_RECEIVE
} from '../../Fields/ReceivingTypeField'

const NAMES = {
  [OVER_RECEIVE]: 'Over receive',
  [UNDER_RECEIVE]: 'Under receive',
  [OVER_UNDER_RECEIVE]: 'Over and under receive',
  [INVOICE_RECEIVE]: 'Invoice receive',
  [BLIND_RECEIVE]: 'Blind receive'
}

function ReceivingType ({ value }) {
  return (
    <>{NAMES[value]}</>
  )
}

ReceivingType.propTypes = {
  value: PropTypes.string.isRequired
}

export default ReceivingType
