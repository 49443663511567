import React from 'react'
import PropTypes from 'prop-types'
import { generatePath, useParams } from 'react-router-dom'

import { EditButton } from '~/components/Buttons'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import LoaderProvider from '~/components/Loader'
import { LOCATION_TABS } from '~/constants/tabs'

import LocationContent from './LocationContent'
import LocationDetailTabs from './LocationDetailTabs'
import LocationDetailGeneral from './LocationDetailGeneral'

import Title from '../Title'

function LocationDetail (props) {
  const {
    tab,
    detail,
    isLoading,
    locationContent,
    pageTitleLoading
  } = props

  const { guid } = useParams()

  const updatePath = generatePath(ROUTES.LOCATION_UPDATE_PATH, { guid })

  return (
    <LoaderProvider isLoading={isLoading}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={<Title titleValues={detail} />}
            pageTitleLoading={pageTitleLoading}
            parentTitle={NAV.LOCATIONS}
            rightButton={<EditButton path={updatePath} />}
          />
        </Header>

        <Content>
          <LocationDetailTabs value={tab} />

          {tab === LOCATION_TABS.GENERAL && <LocationDetailGeneral detail={detail} />}
          {tab === LOCATION_TABS.CONTENT && <LocationContent unitList={locationContent} />}
        </Content>
      </Container>
    </LoaderProvider>
  )
}

LocationDetail.propTypes = {
  pageTitleLoading: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  tab: PropTypes.string.isRequired,
  detail: PropTypes.object.isRequired,
  locationContent: PropTypes.object.isRequired
}

export default LocationDetail
