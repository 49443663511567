import React from 'react'
import { path } from 'ramda'
import { useNavigate, useParams } from 'react-router-dom'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'
import { useTranslation } from 'react-i18next'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import ErrorLink from '~/components/ErrorLink'

import { useContainerTypeDetail, useContainerTypeRemove, useContainerTypeUpdate } from '../../hooks/ContainerType'
import { ContainerTypeCreateSerializer } from '../../serializers/ContainerType'
import ContainerTypeUpdateForm from '../../components/ContainerType/ContainerTypeUpdate/ContainerTypeUpdateForm'

function ContainerTypeUpdateContainer () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { id } = useParams()
  const snackbar = useSnackbar()
  const containerTypeDetail = useContainerTypeDetail(id)
  const containerTypeUpdate = useContainerTypeUpdate(id)
  const containerTypeRemove = useContainerTypeRemove(id)

  const message = t('Successfully updated')
  const handleSubmit = formValues =>
    containerTypeUpdate.update(ContainerTypeCreateSerializer(formValues))
      .then(() => snackbar({ message }))
      .then(() => navigate(ROUTES.CONTAINER_TYPE_LIST_PATH))

  const handleRemove = () =>
    containerTypeRemove.delete()
      .then(() => navigate(ROUTES.CONTAINER_TYPE_LIST_PATH, { replace: true }))
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const name = path(['detail', 'name'], containerTypeDetail)
  const title = containerTypeDetail.isLoading ? 'Container Types' : name

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      isLoading={containerTypeUpdate.isLoading || containerTypeUpdate.isLoading}
      breadcrumbs={breadcrumbs}
      activeNav={NAV.CONFIGURATION}
    >
      <ContainerTypeUpdateForm
        pageTitle={title}
        pageTitleLoading={containerTypeDetail.isLoading}
        initialValues={containerTypeDetail.detail}
        onSubmit={handleSubmit}
        onRemove={handleRemove}
        isLoading={containerTypeUpdate.isLoading}
      />
    </DashboardLayout>
  )
}

export default ContainerTypeUpdateContainer
