import React from 'react'

import MultiSelectSearchField from '~/components/Fields/MultiSelectSearchField'
import * as API from '~/constants/api'

function UserMultiSelectField (props) {
  return (
    <MultiSelectSearchField
      api={API.AUTOMATION_RULE_USER_LIST}
      label="Users"
      {...props}
    />
  )
}

export default UserMultiSelectField
