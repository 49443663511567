import React from 'react'
import PropTypes from 'prop-types'
import { Chip } from '@mui/material'
import Typography from '@mui/material/Typography'

import { matchContainer } from '~/utils'
import TextLabel from '~/components/TextLabel'

const getDestinationTitle = (destination) => {
  if (matchContainer(destination)) {
    return `Destination container:`
  }
  return `Destination location:`
}

function DestinationPreview ({ destination, onDestinationRemove }) {
  return (
    <TextLabel label={getDestinationTitle(destination)}>
      {destination ? (
        <Chip
          color="info"
          label={destination}
          onDelete={onDestinationRemove}
        />
      ) : (
        <Typography variant="body1">
          No location scanned
        </Typography>
      )}
    </TextLabel>
  )
}

DestinationPreview.propTypes = {
  destination: PropTypes.string,
  onDestinationRemove: PropTypes.func.isRequired,
}

export default DestinationPreview
