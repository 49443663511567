import React from 'react'
import PropTypes from 'prop-types'
import TableCell from '@mui/material/TableCell'
import Badge from '@mui/material/Badge'

import { ExpandableTableRow, ExpandedTableWrapper } from '~/components/Table'
import Avatar from '~/components/Avatar/Avatar'
import EditIcon from '~/components/Icons/EditIcon'

import UnitTable from './UnitTable'

function ContainerContent ({ container, containerType, containerImage, units, onOpenDialog, getContainer }) {
  const [open, setOpen] = React.useState(false)
  const onEdit = () => {
    onOpenDialog()
    getContainer(container, units)
  }
  return (
    <>
      <ExpandableTableRow
        open={open}
        onExpandToggle={() => setOpen(!open)}
      >
        <TableCell>
          <Badge
            variant="dot"
            color="primary"
          >
            <Avatar
              alt={containerType}
              src={containerImage}
            />
          </Badge>
        </TableCell>

        <TableCell>{container}</TableCell>
        <TableCell>{containerType}</TableCell>
        <TableCell><EditIcon onClick={onEdit} /></TableCell>
      </ExpandableTableRow>

      <ExpandedTableWrapper open={open} colSpan={5}>
        <UnitTable units={units} />
      </ExpandedTableWrapper>

    </>
  )
}

ContainerContent.propTypes = {
  units: PropTypes.array,
  container: PropTypes.string,
  containerType: PropTypes.string,
  containerImage: PropTypes.string,
  onOpenDialog: PropTypes.func.isRequired,
  getContainer: PropTypes.func
}

export default ContainerContent
