import React from 'react'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import PropTypes from 'prop-types'
import { path, pathOr, prop, propOr } from 'ramda'
import { useTranslation } from 'react-i18next'
import Paper from '@mui/material/Paper'
import { generatePath, Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'

import SkeletonList from '~/components/Skeleton'
import Money from '~/components/Money'
import * as ROUTES from '~/constants/routes'
import { SALE_ORDER_DETAIL_TABS } from '~/constants/tabs'

function TplBillingFulfillmentTable ({ detail, isLoading }) {
  const { t } = useTranslation()
  const billItems = propOr([], 'billItems', detail)
  return (
    <Paper>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('Order')}</TableCell>
              <TableCell>{t('Variant Qty')}</TableCell>
              <TableCell>{t('Price')}</TableCell>
              <TableCell>{t('Unit Qty')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? <SkeletonList rowCount={5} columnCount={11} />
              : billItems.map((item, index) => {
                const guid = path(['order', 'guid'], item)
                const order = path(['order', 'number'], item)
                const lineItemCount = pathOr(0, ['order', 'lineItemCount'], item)
                const unitQuantity = prop('unitsQuantity', item)
                const price = prop('price', item)
                const params = { guid, tab: SALE_ORDER_DETAIL_TABS.GENERAL }
                const orderPath = guid && generatePath(ROUTES.SALE_ORDER_UPDATE_PATH, params)

                return (
                  <TableRow key={index}>
                    <TableCell>
                      <Link
                        component={RouterLink}
                        to={orderPath}
                        underline="always"
                      >
                        {order}
                      </Link>
                    </TableCell>
                    <TableCell>{lineItemCount}</TableCell>
                    <TableCell>
                      <Money value={price} />
                    </TableCell>
                    <TableCell>{unitQuantity}</TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

TplBillingFulfillmentTable.propTypes = {
  detail: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default TplBillingFulfillmentTable
