import React from 'react'
import { FieldArray } from 'react-final-form-arrays'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import IconButton from '@mui/material/IconButton'
import { defaultTo, prop, propOr } from 'ramda'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import { styled } from '@mui/material'
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop'

import Avatar from '~/components/Avatar'
import VerticalAlignment from '~/components/VerticalAlignment'
import Subtext from '~/components/Subtext'
import TextOverflow from '~/components/TextOverflow'
import { useScreenOutline } from '~/components/ScreenOutline'

import DimensionStatus from '../DimensionStatus'

const NoBorderTableRowStyled = styled(TableRow)({
  '& td': {
    borderBottom: 'none',
    padding: '6px 24px 6px 16px'
  }
})

function ReceiveDetailLineItems ({ editDimension, onMoveToActive, edit, isBarcodeMode }) {
  const { handleSoundedErrorTrigger } = useScreenOutline()
  const { t } = useTranslation()

  const disableEdit = edit || !isBarcodeMode

  const handleItemEdit = (value) => {
    if (disableEdit) {
      handleSoundedErrorTrigger()
    } else {
      onMoveToActive(value)
    }
  }

  return (
    <TableContainer sx={{ maxHeight: '300px' }}>
      <FieldArray
        name="detailLineItems"
        render={({ fields }) => {
          const values = defaultTo([], fields.value)
          return (
            <Table>
              <TableHead sx={{ position: 'sticky', top: 0, zIndex: 1 }}>
                <TableRow>
                  <TableCell>{t('Image')}</TableCell>
                  <TableCell>{t('Product')}</TableCell>
                  <TableCell>{t('Supplier SKU')}</TableCell>
                  <TableCell>{t('Barcode')}</TableCell>
                  <TableCell>{t('Received')}</TableCell>
                  <TableCell>{t('Ordered')}</TableCell>
                  <TableCell>{t('Action')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.map((value, index) => {
                  const ordered = propOr('0', 'quantity', value)
                  const received = propOr('0', 'received', value)
                  const variant = prop('productVariant', value)
                  const variantName = prop('name', variant)
                  const image = prop('defaultImage', variant)
                  const barcode = prop('barcode', variant)
                  const sku = prop('sku', variant)
                  const supplierSku = prop('supplierSku', value)

                  const width = prop('width', variant)
                  const height = prop('height', variant)
                  const length = prop('length', variant)
                  const weight = prop('weight', variant)
                  const isFilled = Boolean(width || height || length || weight)

                  return (
                    <NoBorderTableRowStyled key={`${image}-${index}`}>
                      <TableCell style={{ position: 'relative' }} width={80}>
                        {editDimension && <DimensionStatus isFilled={isFilled} />}
                        <Avatar src={image} alt={variantName} />
                      </TableCell>
                      <TableCell>
                        <VerticalAlignment
                          primary={<TextOverflow selfTooltip={true} lines={1}>{variantName}</TextOverflow>}
                          secondary={<Subtext lines={1}>{sku}</Subtext>}
                        />
                      </TableCell>
                      <TableCell>{supplierSku}</TableCell>
                      <TableCell>
                        <TextOverflow selfTooltip={true} lines={1}>{barcode}</TextOverflow>
                      </TableCell>
                      <TableCell>{received}</TableCell>
                      <TableCell>{ordered}</TableCell>
                      <TableCell width={80}>
                        <IconButton
                          data-cy={`detailLineItems[${index}].toTop`}
                          onClick={() => handleItemEdit(value)}
                          size="large"
                        >
                          <VerticalAlignTopIcon />
                        </IconButton>
                      </TableCell>
                    </NoBorderTableRowStyled>
                  )
                })}
              </TableBody>
            </Table>
          )
        }}
      />
    </TableContainer>
  )
}

ReceiveDetailLineItems.propTypes = {
  editDimension: PropTypes.bool,
  onMoveToActive: PropTypes.func,
  edit: PropTypes.bool,
  isBarcodeMode: PropTypes.bool
}

export default ReceiveDetailLineItems
