import React from 'react'
import PropTypes from 'prop-types'

import Status from '~/components/Status'

import { TPL_BILLING_STATUS_NAMES, CLOSED, DRAFT } from '../../../constants/TplBilling'

const COLOR = {
  [DRAFT]: 'warning',
  [CLOSED]: 'success'
}

function TplBillingStatus ({ value }) {
  return (
    <Status
      label={TPL_BILLING_STATUS_NAMES[value]}
      color={COLOR[value]}
    />
  )
}

TplBillingStatus.propTypes = {
  value: PropTypes.oneOf([DRAFT, CLOSED]).isRequired
}

export default TplBillingStatus
