import { useState } from 'react'
import { reduce } from 'ramda'

const getInitColumnWidths = reduce((acc, column) => ({
  ...acc, [column.field]: column.width
}), {})

export function useTableColumns (initColumns) {
  const [columnWidths, setColumnWidths] = useState(getInitColumnWidths(initColumns))

  const changeColumnWidths = ({ key, width }) => {
    const newColumnWidths = { ...columnWidths, [key]: width }

    setColumnWidths(newColumnWidths)
  }

  return {
    columnWidths,
    changeColumnWidths
  }
}
