import React from 'react'
import { Filter } from 'storfox-filter'
import Grid from '@mui/material/Grid'
import { prop } from 'ramda'
import { DateRangeField } from 'storfox-form-fields'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

import { escapeAtob, getSeparatedDateRange, unescapeBtoa } from '~/utils'
import Accordion from '~/components/Accordion'
import VariantsMultiSelectField from '~/components/Fields/VariantsMultiSelectField'
import * as API from '~/constants/api'
import WarehouseMultiSelectField from '~/components/Fields/WarehouseMultiSelectField'
import AsyncMultiSelectField from '~/components/Fields/AsyncMultiSelectField'

import { FILTER_NAME as name } from '../../constants'

export const toParams = values => {
  const variants = unescapeBtoa(prop('variants', values))
  const warehouses = unescapeBtoa(prop('warehouses', values))
  const companies = unescapeBtoa(prop('companies', values))
  const [createdAtStart, createdAtEnd] = getSeparatedDateRange(prop('createdAt', values))

  return {
    variants,
    warehouses,
    companies,
    createdAtStart,
    createdAtEnd
  }
}

export const toValues = params => {
  const variants = escapeAtob(prop('variants', params))
  const warehouses = escapeAtob(prop('warehouses', params))
  const companies = escapeAtob(prop('companies', params))
  const createdAt = [prop('createdAtStart', params), prop('createdAtEnd', params)]

  return {
    variants,
    warehouses,
    companies,
    createdAt
  }
}

export const transformer = { toParams, toValues }

export const inventoryLogFilterOptions = {
  name,
  transformer
}

function InventoryLogFilterForm (props) {
  const { t } = useTranslation()
  return (
    <Filter {...props}>
      <Accordion title="General" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <VariantsMultiSelectField label="Variants" name="variants" />
          </Grid>
          <Grid item={true} xs={12}>
            <WarehouseMultiSelectField name="warehouses" api={API.WAREHOUSE_LIST} />
          </Grid>
          <Grid item={true} xs={12}>
            <AsyncMultiSelectField
              api={API.UNIT_COMPANY_LIST}
              label="Companies"
              name="companies"
            />
          </Grid>
        </Grid>
      </Accordion>
      <Accordion title="Date">
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <Typography variant="body2" gutterBottom={true}>
              {t('Created')}:
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <DateRangeField name="createdAt" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Accordion>
    </Filter>
  )
}

export default InventoryLogFilterForm
