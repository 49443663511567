import React from 'react'
import { useSnackbar } from 'storfox-snackbar'
import { useNavigate } from 'react-router-dom'
import { propOr } from 'ramda'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import useMessages from '~/hooks/useMessages'

import StockCountCreate from '../components/StockCountCreate/StockCountCreate'
import { useStockCountCreate, useStockCountCreateAndProcess, useStockCountLocationList } from '../hooks'
import { StockCountCreateSerializer } from '../serializers'
import { SAVE_PROCESS } from '../constants'

function StockCountCreateContainer () {
  const snackbar = useSnackbar()
  const messages = useMessages()
  const navigate = useNavigate()

  const stockCountCreate = useStockCountCreate()
  const stockCountCreateAndProcess = useStockCountCreateAndProcess()
  const stockCountLocationList = useStockCountLocationList()

  const handleSubmit = values => {
    const submitType = propOr(SAVE_PROCESS, 'submitType', values)
    if (submitType === SAVE_PROCESS) {
      return stockCountCreateAndProcess.create(StockCountCreateSerializer(values))
        .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
        .then(() => navigate(ROUTES.STOCK_COUNT_LIST_PATH))
    } else {
      return stockCountCreate.create(StockCountCreateSerializer(values))
        .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
        .then(() => navigate(ROUTES.STOCK_COUNT_LIST_PATH))
    }
  }

  const handleLocationGet = params =>
    stockCountLocationList.getList(params)

  const breadcrumbs = { title: 'Create stock count' }

  return (
    <DashboardLayout
      title="Create stock count"
      activeNav={NAV.STOCK_COUNT}
      breadcrumbs={breadcrumbs}
    >
      <StockCountCreate
        isLoading={stockCountCreate.isLoading || stockCountCreateAndProcess.isLoading}
        onSubmit={handleSubmit}
        initialValues={{ locations: [] }}
        locationList={stockCountLocationList}
        onLocationListGet={handleLocationGet}
      />
    </DashboardLayout>
  )
}

export default StockCountCreateContainer
