import React from 'react'
import PropTypes from 'prop-types'
import { prop } from 'ramda'
import Box from '@mui/material/Box'

import { getCurrencySymbol } from '~/utils'

import { useCompany } from '../Profile'

const toMoney = value => value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')

function Money ({ value }) {
  const { company } = useCompany()
  const currency = prop('currency', company)
  const symbol = getCurrencySymbol(currency)

  return (
    <Box sx={{ whiteSpace: 'nowrap' }}>
      {`${symbol} ${value ? toMoney(value) : '0.00'}`}
    </Box>
  )
}

Money.propTypes = {
  value: PropTypes.number
}

export default Money
