import React, { useCallback } from 'react'
import { all, map, none, prop } from 'ramda'
import PropTypes from 'prop-types'
import { DndProvider } from 'react-dnd'
import TouchBackend from 'react-dnd-touch-backend'
import HTML5Backend from 'react-dnd-html5-backend'
import Paper from '@mui/material/Paper'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import DragHandle from '@mui/icons-material/DragHandle'
import { isMobile } from 'storfox-tools'
import update from 'immutability-helper'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { useField } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { CheckboxField } from 'storfox-form-fields'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material'

import Drag from '../Drag'
import DiscardButton from '../../Buttons/DiscardButton'
import { ActionsStyled, ContentStyled, FormStyled } from '../../Filter.styled'

const ShowAllBoxStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  paddingRight: theme.spacing(2),
  marginBottom: theme.spacing(2)
}))

function Filters (props) {
  const {
    onClose,
    onSubmit,
    isLoading,
    onReset
  } = props

  const filtersField = useField('filters')
  const filters = filtersField.input.value
  const handleChange = filtersField.input.onChange

  const isAllVisible = all(prop('visible'), filters)
  const isNoneVisible = none(prop('visible'), filters)

  const handleAllCheck = () => {
    const newItems = map(filter => ({ ...filter, visible: !isAllVisible }), filters)

    handleChange(newItems)
  }

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    const dragCard = filters[dragIndex]
    const newItems = update(filters, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragCard],
      ],
    })

    handleChange(newItems)
  }, [filters, handleChange])

  return (

    <FormStyled onSubmit={onSubmit}>
      <ContentStyled>
        <ShowAllBoxStyled>
          <Typography variant="button">Show all</Typography>
          <Checkbox
            checked={isAllVisible}
            onChange={handleAllCheck}
            color="secondary"
            size="small"
          />
        </ShowAllBoxStyled>
        <List component="div" sx={{ p: 0 }}>
          <DndProvider backend={isMobile() ? TouchBackend : HTML5Backend}>
            <FieldArray name="filters" render={
              ({ fields }) =>
                fields.map((name, index) => {
                  const id = fields.value[index].id
                  const label = fields.value[index].name

                  return (
                    <Drag
                      key={id}
                      id={id}
                      index={index}
                      moveCard={moveCard}
                    >
                      <Paper
                        variant="outlined"
                        sx={{
                          marginBottom: theme => theme.spacing(2),
                          cursor: 'move'
                        }}
                      >
                        <ListItem component="div">
                          <Box width="100%" display="flex" justifyContent="space-between">
                            <Box display="flex" alignItems="center">
                              <ListItemIcon>
                                <DragHandle />
                              </ListItemIcon>
                              <ListItemText primary={label} />
                            </Box>
                            <CheckboxField
                              name={`${name}.visible`}
                              color="secondary"
                              size="small"
                            />
                          </Box>
                        </ListItem>
                      </Paper>
                    </Drag>
                  )
                })
            }
            />
          </DndProvider>
        </List>
      </ContentStyled>
      <ActionsStyled>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <Button
              type="button"
              variant="outlined"
              fullWidth={true}
              startIcon={<RotateLeftIcon />}
              onClick={onReset}
            >
              Reset
            </Button>
          </Grid>
          <Grid item={true} xs={6}>
            <DiscardButton onClick={onClose} />
          </Grid>
          <Grid item={true} xs={6}>
            <Button
              type="submit"
              variant="contained"
              fullWidth={true}
              autoFocus={true}
              disabled={isLoading || isNoneVisible}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </ActionsStyled>
    </FormStyled>
  )
}

Filters.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired
}

export default Filters
