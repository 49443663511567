import React from 'react'
import { prop, path } from 'ramda'

import EditableSearchField from '~/components/Fields/EditableSearchField'
import * as API from '~/constants/api'

const getOptionValue = value => {
  if (value) {
    const id = prop('id', value)
    const guid = prop('guid', value)
    const name = prop('name', value)
    const companyName = path(['company', 'name'], value)

    return { id, guid, name, companyName }
  }

  return null
}

function ReorderWarehouseSearchField (props) {
  return (
    <EditableSearchField
      name="warehouse"
      label="Warehouse"
      primaryKey="guid"
      api={API.WAREHOUSE_LIST}
      getOptionValue={getOptionValue}
      {...props}
    />
  )
}

export default ReorderWarehouseSearchField
