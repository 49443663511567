import React from 'react'
import { useTranslation } from 'react-i18next'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import Container, { Header } from '~/components/Container'
import PageTitle from '~/components/PageTitle'

import ReportList from '../../components/ReportList/ReportList'

function ReportListContainer () {
  const { t } = useTranslation()
  const breadcrumbs = { title: NAV.REPORTS }

  return (
    <DashboardLayout
      title={NAV.REPORTS}
      activeNav={NAV.REPORTS}
      breadcrumbs={breadcrumbs}
    >
      <Container>
        <Header>
          <PageTitle
            pageTitle={t(NAV.REPORTS)}
            parentTitle={t(NAV.REPORTS)}
          />
        </Header>
        <ReportList />
      </Container>
    </DashboardLayout>
  )
}

export default ReportListContainer
