import React from 'react'
import PropTypes from 'prop-types'
import { map, prop } from 'ramda'
import { useTranslation } from 'react-i18next'

import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import { Table, TableHeader } from '~/components/Table'
import * as NAV from '~/constants/nav-titles'
import Button from '~/components/Buttons/Button'

export const UNIT_COLUMNS = [
  {
    width: 150,
    headerName: 'Sku',
    field: 'sku'
  },
  {
    width: 150,
    headerName: 'Barcode',
    field: 'barcode'
  },
  {
    width: 200,
    headerName: 'Variant name',
    field: 'name'
  },
  {
    width: 150,
    headerName: 'Available',
    field: 'stockAvailable'
  },
  {
    width: 150,
    headerName: 'Quantity',
    field: 'stockOnHand'
  },
  {
    width: 150,
    headerName: 'Pieces',
    field: 'pieces'
  },
  {
    width: 150,
    headerName: 'Total pieces',
    field: 'totalPieces'
  },
  {
    width: 150,
    headerName: 'Total weight',
    field: 'totalWeight'
  },
  {
    width: 150,
    headerName: 'Total volume',
    field: 'totalVolume'
  },
  {
    width: 150,
    headerName: 'Total supply price',
    field: 'totalSupplyPrice'
  },
  {
    width: 200,
    headerName: 'Total number of days',
    field: 'numberOfDays'
  }
]

const getRowsFromResults = map(item => {
  const id = prop('id', item)
  const stockOnHand = prop('stockOnHand', item)
  const stockAvailable = prop('stockAvailable', item)
  const daysSinceReceipt = prop('daysSinceReceipt', item)
  const variant = prop('variant', item)
  const name = prop('name', variant)
  const sku = prop('sku', variant)
  const barcode = prop('barcode', variant)
  const supplyPrice = prop('supplyPrice', variant)
  const pieces = prop('pieces', variant)
  const totalPieces = pieces * stockOnHand
  const weight = prop('weight', variant)
  const totalWeight = weight * stockOnHand
  const totalSupplyPrice = supplyPrice * stockOnHand

  return {
    id,
    name,
    sku,
    barcode,
    stockOnHand,
    stockAvailable,
    pieces,
    totalPieces,
    totalWeight,
    totalSupplyPrice,
    daysSinceReceipt
  }
})

function UnitHistoryList ({ list, filter, ordering, onListRefetch, onReportGenerate }) {
  const { t } = useTranslation()

  const isLoading = prop('isLoading', list)
  const results = prop('results', list)
  const count = prop('count', list)
  const columns = prop('filteredColumns', ordering)

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={t(NAV.UNIT_HISTORY)}
          parentTitle={t(NAV.REPORTS)}
          rightButton={(
            <Button
              variant="contained"
              data-cy="create"
              type="button"
              onClick={onReportGenerate}
            >
              {t('Generate Report')}
            </Button>
          )}
        />
      </Header>

      <Content>
        <TableHeader
          filter={filter}
          filterOpen={filter.handleOpen}
          orderingOpen={ordering.handleOpen}
          ordering={ordering}
        />
        <Table
          isLoading={isLoading}
          columns={columns}
          getRowsFromResults={getRowsFromResults}
          results={results}
          count={count}
          checkboxSelection={false}
          pinnedColumns={['image']}
          ordering={ordering}
          onRefetch={onListRefetch}
        />
      </Content>
    </Container>
  )
}

UnitHistoryList.propTypes = {
  list: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  onListRefetch: PropTypes.func.isRequired,
  onReportGenerate: PropTypes.func.isRequired
}

export default UnitHistoryList
