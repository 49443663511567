import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import FormControlLabel from '@mui/material/FormControlLabel'
import Link from '@mui/material/Link'
import { Box } from '@mui/material'
import Check from '@mui/icons-material/Check'
import { useField } from 'react-final-form'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { CheckboxField } from 'storfox-form-fields'
import { Link as RouterLink } from 'react-router-dom'

import PhoneNumberField from '~/components/Fields/PhoneNumberField'
import TextField from '~/components/Fields/TextField'
import { Button } from '~/components/Buttons'
import * as ROUTES from '~/constants/routes'

import CheckEmailField from '../Fields/CheckEmailField'
import StrongPasswordField from '../Fields/StrongPasswordField'

function PersonalInfo ({ className, submitBtnClassName, onNext, checkEmail, companyName }) {
  const { t } = useTranslation()

  const firstNameField = useField('firstName')
  const lastNameField = useField('lastName')
  const emailField = useField('email')
  const phoneField = useField('phone')
  const passwordField = useField('password')
  const agreeCheckbox = useField('agreement')

  const emailValid = (
    Boolean(emailField.input.value && emailField.meta.valid) ||
    Boolean(emailField.meta.submitFailed && emailField.meta.dirtySinceLastSubmit)
  )

  const phoneValid = (
    Boolean(phoneField.input.value && phoneField.meta.valid) ||
    Boolean(phoneField.meta.submitFailed && phoneField.meta.dirtySinceLastSubmit)
  )

  const passwordValid = (
    Boolean(passwordField.input.value && passwordField.meta.valid) ||
    Boolean(passwordField.meta.submitFailed && passwordField.meta.dirtySinceLastSubmit)
  )

  const disabled = !(
    Boolean(firstNameField.input.value) &&
    Boolean(lastNameField.input.value) &&
    emailValid &&
    phoneValid &&
    passwordValid &&
    Boolean(agreeCheckbox.input.value)
  )

  const whiteLabelDisabled = !(
    Boolean(firstNameField.input.value) &&
    Boolean(lastNameField.input.value) &&
    emailValid &&
    phoneValid &&
    passwordValid
  )

  return (
    <Box className={className}>
      <Grid container={true} spacing={3}>
        <Grid item={true} xs={12} md={6}>
          <TextField
            autoFocus={true}
            name="firstName"
            data-cy="firstName"
            label={t('First name')}
            size="medium"
          />
        </Grid>
        <Grid item={true} xs={12} md={6}>
          <TextField
            name="lastName"
            data-cy="lastName"
            label="Last name"
            size="medium"
          />
        </Grid>
        <Grid item={true} xs={12}>
          <CheckEmailField
            name="email"
            data-cy="email"
            label="Email"
            size="medium"
            checkEmail={checkEmail}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <PhoneNumberField
            name="phone"
            data-cy="phone"
            label={t('Phone Number')}
            size="medium"
          />
        </Grid>
        <Grid item={true} xs={12}>
          <StrongPasswordField
            data-cy="newPassword"
            autoComplete="new-password"
            style={{ fontWeight: 'bold' }}
          />
        </Grid>
      </Grid>
      <Box mt={2}>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={6} xs={12}>
            {!companyName && (
              <FormControlLabel
                control={
                  <CheckboxField
                    size="small"
                    name="agreement"
                    data-cy="agreement"
                  />
                }
                label={(
                  <Typography>
                    I have read and I agree to the&nbsp;
                    <Link
                      component="a"
                      target="_blank"
                      href="https://storfox.com/terms"
                      color="secondary"
                      underline="always"
                    >
                      Terms & Conditions
                    </Link>
                  </Typography>
                )}
              />
            )}
          </Grid>
          <Grid item={true} lg={6} xs={12}>
            <Grid
              container={true}
              justifyContent="flex-end"
              alignItems="center"
              spacing={3}
            >
              <Grid item={true}>
                <Button color="primary" size="large">
                  <Link
                    underline="none"
                    to={ROUTES.SIGN_IN}
                    component={RouterLink}
                  >
                    Back
                  </Link>
                </Button>
              </Grid>
              <Grid item={true}>
                <Button
                  variant="contained"
                  data-cy="next"
                  startIcon={<Check />}
                  disabled={companyName ? whiteLabelDisabled : disabled}
                  size="large"
                  onClick={onNext}
                  className={submitBtnClassName}
                >
                  Continue
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

PersonalInfo.propTypes = {
  className: PropTypes.string,
  submitBtnClassName: PropTypes.string,
  checkEmail: PropTypes.bool,
  companyName: PropTypes.bool,
  onNext: PropTypes.func.isRequired,
}

export default PersonalInfo
