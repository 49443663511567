import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import PackingListContainer from './containers/PackingListContainer'
import PackingBarcodingContainer from './containers/PackingBarcodingContainer'
import PackingDetailContainer from './containers/PackingDetailContainer'

export default () => [
  {
    layout: AuthLayout,
    path: ROUTES.PACKING_LIST_PATH,
    component: PackingListContainer
  },
  {
    layout: AuthLayout,
    path: ROUTES.PACKING_UPDATE_PATH,
    component: PackingBarcodingContainer
  },
  {
    layout: AuthLayout,
    path: ROUTES.PACKING_DETAIL_PATH,
    component: PackingDetailContainer
  }
]
