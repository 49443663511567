import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { isEmpty, map, pipe, prop, replace, toLower, toPairs } from 'ramda'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import { useDeepCompareEffect, useUpload } from 'storfox-api-hooks'
import { useAllSearchParams } from 'storfox-route-hooks'
import { useField } from 'react-final-form'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import { capitalize, escapeAtob } from '~/utils'
import ErrorLink from '~/components/ErrorLink'

import FileUpload from './FileUpload'

import ErrorField from '../FileUpload/ErrorField'
import CardHeader from '../Cards/CardHeader'

const getColumn = pipe(
  replace(/(([A-Z])|\(.*\))/g, ' $1'),
  toLower,
  capitalize
)

const getRow = pipe(
  item => item.substr(4),
  number => `Line ${number}`
)

const getFormattedValue = map(item => {
  const [key, value] = toPairs(item)[0]
  const column = getColumn(key)

  return { column, value }
})

const getErrors = map(item => {
  const [key, value] = toPairs(item)[0]
  const row = getRow(key)
  const formattedValue = getFormattedValue(value)

  return { row, values: formattedValue }
})

function BackgroundSingleFileUpload ({ name, api, Component, onSubscribe, onRemove, setAdditionalData }) {
  const field = useField(name)
  const params = useAllSearchParams()
  const snackbar = useSnackbar()

  const { upload, ...state } = useUpload(api)
  const [errors, setErrors] = useState([])

  useDeepCompareEffect(() => {
    const payload = escapeAtob(params.payload)
    const success = prop('success', payload)

    if (success) {
      setErrors([])
      field.input.onChange(prop('result', payload))
    }
    if (!success && prop('errors', payload)) {
      const errors = prop('errors', payload)
      if (typeof errors === 'string') {
        snackbar({ message: errors, type: ALTER_ERROR })
      } else {
        setErrors(getErrors(errors))
      }
    }
  }, [params.payload])

  useDeepCompareEffect(() => {
    const error = prop('error', state)
    if (error) {
      const message = <ErrorLink error={error} />
      snackbar({ message, type: ALTER_ERROR })
    }
  }, [state])

  const handleUpload = (data, onProgress) => {
    setErrors([])

    return upload(data, onProgress)
      .then(data => {
        const key = prop('key', data)
        onSubscribe(key)

        return data
      })
  }

  return (
    <>
      <Box mb={2}>
        <Card>
          <CardHeader title="Upload" />
          <Divider />
          <CardContent>
            <FileUpload
              name={name}
              Component={Component}
              onUpload={handleUpload}
              onRemove={onRemove}
              setAdditionalData={setAdditionalData}
            />
          </CardContent>
        </Card>
      </Box>
      {!isEmpty(errors) && (
        <Box>
          <Card>
            <CardHeader title="Rows with unresolved format issues" />
            <Divider />
            <ErrorField errors={errors} />
          </Card>
        </Box>
      )}
    </>
  )
}

BackgroundSingleFileUpload.defaultProps = {
  name: 'file'
}

BackgroundSingleFileUpload.propTypes = {
  Component: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  api: PropTypes.string.isRequired,
  onSubscribe: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
  setAdditionalData: PropTypes.func
}

export default BackgroundSingleFileUpload
