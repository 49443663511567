import React from 'react'
import { useField } from 'react-final-form'
import { useDeepCompareEffect } from 'storfox-api-hooks'
import PropTypes from 'prop-types'
import { IntegerField } from 'storfox-form-fields'

function ConstrainedField (props) {
  const {
    name,
    minValue = 0,
    maxValue = 100,
    ...rest
  } = props

  const { input } = useField(name)
  const value = input.value

  useDeepCompareEffect(() => {
    const val = Number(value)

    if (!val) {
      return
    }

    if (val < minValue) {
      input.onChange(minValue)
    }

    if (val > maxValue) {
      input.onChange(maxValue)
    }
  }, [value])

  return (
    <IntegerField name={name} {...rest} />
  )
}

ConstrainedField.propTypes = {
  name: PropTypes.string.isRequired,
  maxValue: PropTypes.number,
  minValue: PropTypes.number
}

export default ConstrainedField
