import React from 'react'

import SelectField from '~/components/Fields/SelectField'
import { PURCHASE_ORDER_TYPE, SALE_ORDER_TYPE } from '~/constants/taxTypes'

export const TYPE_NAMES = {
  [SALE_ORDER_TYPE]: 'Sales',
  [PURCHASE_ORDER_TYPE]: 'Purchases'
}

const OPTIONS = [
  {
    name: TYPE_NAMES[SALE_ORDER_TYPE],
    value: SALE_ORDER_TYPE
  },
  {
    name: TYPE_NAMES[PURCHASE_ORDER_TYPE],
    value: PURCHASE_ORDER_TYPE
  }
]

function TaxTypeField () {
  return (
    <SelectField
      data-cy="taxType"
      name="type"
      label="Tax type"
      options={OPTIONS}
    />
  )
}

export default TaxTypeField
