import React from 'react'

import SelectField from '~/components/Fields/SelectField'

import { STORAGE_TYPES } from '../../../constants/BillingProfile'

const OPTIONS = [
  {
    value: STORAGE_TYPES.PER_VOLUME,
    name: 'Volume'
  }
]

function StorageChargeTypeField (props) {
  return (
    <SelectField
      data-cy="type"
      name="type"
      label="Charge per"
      options={OPTIONS}
      {...props}
    />
  )
}

export default StorageChargeTypeField
