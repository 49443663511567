import React from 'react'

import SelectField from '~/components/Fields/SelectField'

import { PUTAWAY_STATUS } from '../../constants'

const OPTIONS = [
  { value: null, name: 'All' },
  { value: PUTAWAY_STATUS.NEW, name: 'New' },
  { value: PUTAWAY_STATUS.PENDING, name: 'Pending' },
  { value: PUTAWAY_STATUS.IN_PROGRESS, name: 'In Progress' },
  { value: PUTAWAY_STATUS.COMPLETED, name: 'Completed' },
]
function PutawayStatusField (props) {
  return (
    <SelectField
      name="status"
      label="Status"
      options={OPTIONS}
      {...props}
    />
  )
}

export default PutawayStatusField
