import React from 'react'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import { FieldArray, useFieldArray } from 'react-final-form-arrays'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { useField } from 'react-final-form'
import { prop } from 'ramda'

import { CardHeader } from '~/components/Cards'
import { Button } from '~/components/Buttons'

import ActionTitle from './ActionTitle'
import SelectedAction from './SelectedAction'
import ActionSelectField from './ActionFields/ActionSelectField'

import { REPLENISHMENT } from '../../../constants/AutomationRule'

function ActionCard ({ event }) {
  const fieldArray = useFieldArray('actions')
  const actionsField = useField('actions')

  const handleActionAdd = () => {
    fieldArray.fields.push({})
  }

  const handleChange = changedIndex => {
    const newValue = actionsField.input.value.map((item, index) => {
      const action = prop('action', item)

      return index === changedIndex && action ? { action } : item
    })

    actionsField.input.onChange(newValue)
  }

  const handleRemove = index => {
    fieldArray.fields.remove(index)
    if (fieldArray.fields.value.length === 1) {
      fieldArray.fields.push({})
    }
  }

  return (
    <FieldArray
      name="actions"
      render={({ fields }) => (
        fields.map((name, index) => {
          const length = fields.value.length
          const action = fields.value[index].action
          const disabled = index !== length - 1 || event === REPLENISHMENT

          return (
            <Box key={name} mb={2}>
              <Card>
                <CardHeader title={<ActionTitle onRemove={() => handleRemove(index)} />} />
                <Divider />
                <CardContent>
                  <Grid container={true} spacing={2}>
                    {action ? (
                      <Grid item={true} xs={12}>
                        <Box display="flex" alignItems="flex-start" position="relative">
                          <SelectedAction name={name} event={event} />
                        </Box>
                      </Grid>
                    ) : (
                      <Grid item={true} xs={12}>
                        <ActionSelectField
                          event={event}
                          name={`${name}.action`}
                          onChange={() => handleChange(index)}
                        />
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
                <CardActions>
                  <Button onClick={handleActionAdd} disabled={disabled}>
                    And
                  </Button>
                </CardActions>
              </Card>
            </Box>
          )
        }))}
    />
  )
}
ActionCard.propTypes = {
  event: PropTypes.string.isRequired
}

export default ActionCard
