import React from 'react'
import { Card, styled } from '@mui/material'
import PropTypes from 'prop-types'

const CardStyled = styled(Card)(({ size, theme }) => ({
  width: theme.breakpoints.values.sm,
  maxWidth: '100%',
  margin: 'auto',
  overflow: 'visible',
  display: 'flex',
  position: 'relative',
  '& > *': {
    flexGrow: 1,
    flexBasis: '50%',
    width: '50%'
  },
  [theme.breakpoints.down('md')]: {
    minHeight: '100vh'
  },
  ...(size === 'md') && {
    width: theme.breakpoints.values.md,
  },
  ...(size === 'xs') && {
    width: '350px'
  }
}))

function CenteredCard ({ children, size = 'sm' }) {
  return (
    <CardStyled size={size}>{children}</CardStyled>
  )
}

CenteredCard.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['md', 'sm', 'xs'])
}

export default CenteredCard
