import React from 'react'

import SelectField from '~/components/Fields/SelectField'
import { PAUSED, PENDING, PUBLISHED, REJECTED, STATUS_NAMES, UNPUBLISHED } from '~/components/Statuses/ListingStatus'

const OPTIONS = [
  { value: null, name: 'All' },
  { value: UNPUBLISHED, name: STATUS_NAMES[UNPUBLISHED] },
  { value: PENDING, name: STATUS_NAMES[PENDING] },
  { value: PUBLISHED, name: STATUS_NAMES[PUBLISHED] },
  { value: PAUSED, name: STATUS_NAMES[PAUSED] },
  { value: REJECTED, name: STATUS_NAMES[REJECTED] }
]

function ListingStatusField (props) {
  return (
    <SelectField
      name="status"
      label="Status"
      options={OPTIONS}
      {...props}
    />
  )
}

export default ListingStatusField
