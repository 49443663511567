import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import { Field, useField } from 'react-final-form'
import { useDeepCompareEffect, useRequest } from 'storfox-api-hooks'

import useDebounce from '~/hooks/useDebounce'

const defaultGetOptionLabel = option => option.name

const defaultGetOptionValue = value => {
  if (value) {
    const { id, guid, name } = value
    return { id, guid, name }
  }

  return null
}

function MultiSelectSearchField (props) {
  const {
    name,
    api,
    params,
    disabled,
    getOptionLabel,
    renderOption,
    getOptionValue,
    ListboxProps,
    ...rest
  } = props

  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [value, setValue] = useState('')
  const debouncedValue = useDebounce(value)
  const request = useRequest()

  const field = useField(name)
  const handleChange = useCallback(field.input.onChange, [])

  const getOptionSelected = (option, value) => {
    if (option.guid) {
      return option.guid === value.guid
    }
    return option.id === value.id
  }

  useDeepCompareEffect(() => {
    let active = true
    if (open) {
      setLoading(true)

      request.get(api, { ...params, search: value })
        .then(({ data }) => {
          const { results } = data
          setLoading(false)
          if (active) {
            setOptions(Object.keys(results).map(key => getOptionValue(results[key])))
          }
        })
    }

    return () => {
      active = false
    }
  }, [open, debouncedValue])

  React.useEffect(() => {
    if (!open) {
      setOptions([])
    }
  }, [open])

  React.useEffect(() => {
    if (!field.input.value) {
      handleChange([])
    }
  }, [field.input.value, handleChange])

  return (
    <Field name={name}>
      {({ input, meta, }) => {
        return (
          <Autocomplete
            open={open}
            disabled={disabled}
            onOpen={() => {
              setOpen(true)
            }}
            onClose={() => {
              setOpen(false)
            }}
            multiple={true}
            disableCloseOnSelect={true}
            disableClearable={true}
            value={input.value || []}
            isOptionEqualToValue={getOptionSelected}
            getOptionLabel={getOptionLabel}
            renderOption={renderOption}
            onInputChange={(event, value) => {
              setOptions([])
              setValue(value)
            }}
            options={options.length === 0 && !open ? input.value || [] : options}
            loading={loading}
            filterOptions={item => item}
            onChange={(event, value) => input.onChange(value)}
            ListboxProps={ListboxProps}
            renderInput={defaultProps => {
              return (
                <TextField
                  {...defaultProps}
                  {...rest}
                  error={meta.invalid}
                  onBlur={() => setLoading(false)}
                  helperText={meta.submitError || meta.error}
                  InputProps={{
                    ...defaultProps.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {defaultProps.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )
            }}
          />
        )
      }}
    </Field>
  )
}

MultiSelectSearchField.propTypes = {
  api: PropTypes.string.isRequired,
  params: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  getOptionLabel: PropTypes.func.isRequired,
  getOptionValue: PropTypes.func.isRequired,
  renderOption: PropTypes.func,
  ListboxProps: PropTypes.object
}

MultiSelectSearchField.defaultProps = {
  params: { limit: 1000 },
  size: 'small',
  fullWidth: true,
  variant: 'outlined',
  disabled: false,
  getOptionLabel: defaultGetOptionLabel,
  getOptionValue: defaultGetOptionValue
}

export default MultiSelectSearchField
