import { defaultTo, find, isEmpty, map, path, pick, pipe, prop, propOr, reduce } from 'ramda'
import { DEFAULT_SEARCH_PARAMS } from 'storfox-route-hooks'
import { DateTime } from 'luxon'

import { escapeAtob, getDateTimeMidnight, orderingToSnackCase } from '~/utils'
import { CASH } from '~/constants/paymentTypes'
import { DATE_FORMAT_ISO_8601 } from '~/utils/date'

import { SALE_ORDER_CREATE_FIELDS } from '../components/SaleOrderCreate'

const DELIVERY_ADDRESS_FIELDS = [
  'address',
  'city',
  'zipcode',
  'country',
  'region'
]

const getInitLineItems = map(item => {
  const id = path(['condition', 'id'], item)
  const guid = path(['condition', 'guid'], item)
  const name = path(['condition', 'code'], item)
  const variantDetail = prop('variant', item)
  const productSku = prop('productSku', item)
  const variantGuid = prop('guid', variantDetail)
  const variantType = prop('type', variantDetail)
  const variantSku = isEmpty(variantGuid) ? productSku : prop('sku', variantDetail)
  const condition = { id, guid, name }

  return isEmpty(variantGuid)
    ? { ...item, condition, variant : { sku: variantSku, type: variantType } }
    : { ...item, condition, variant: variantDetail }
})

export const SaleOrderDetailSerializer = value => {
  const lineItems = propOr([], 'lineItems', value)

  return {
    ...value,
    lineItems: getInitLineItems(lineItems)
  }
}

export const SaleOrderUpdateInitSerializer = (value, { tax, condition }) => {
  const lineItems = propOr([], 'lineItems', value)
  const newCondition = { ...condition, name: prop('code', condition) }
  const defaultPreview = { tax, condition: newCondition, quantity: 1, price: 0 }

  return {
    ...value,
    defaultPreview,
    lineItems: getInitLineItems(lineItems)
  }
}

export function SaleOrderCreateInitSerializer ({ warehouses, tax, condition, company }) {
  const defaultWarehouse = pipe(
    defaultTo([]),
    find(prop('isDefault')),
    defaultTo(propOr({}, 0, warehouses))
  )(warehouses)

  const warehouse = {
    id: defaultWarehouse.id,
    guid: defaultWarehouse.guid,
    name: defaultWarehouse.name
  }

  const orderDate = DateTime.now()
    .startOf('day')

  const expectedDate = DateTime.now()
    .plus({ days: 14 })
    .startOf('day')

  const newCondition = { ...condition, name: prop('code', condition) }
  const defaultPreview = { tax, condition: newCondition, quantity: 1, price: 0 }

  return {
    paymentType: CASH,
    defaultPreview,
    warehouse,
    orderDate: orderDate,
    expectedDate: expectedDate,
    company
  }
}

const getLineItems = pipe(
  prop('lineItems'),
  defaultTo([]),
  map(({ guid, variant, price, quantity, tax, productSku, condition, discount }) => {
    const variantGuid = prop('guid', variant)
    const sku = prop('sku', variant)
    const type = prop('type', variant)
    const conditionGuid = prop('guid', condition)
    const variantItem = variantGuid ? { guid: variantGuid, type, sku } : { type: 'variant' }

    return {
      guid,
      price,
      quantity,
      productSku,
      tax,
      discount,
      variant: variantItem,
      condition: conditionGuid && { guid: conditionGuid }
    }
  })
)

const getWarehouseGuid = path(['warehouse', 'guid'])
const getCustomerGuid = path(['customer', 'guid'])
const getFieldFromAddress = pick([
  'guid',
  'firstName',
  'lastName',
  'address',
  'zipcode',
  'mobile',
  'country',
  'city',
  'latitude',
  'longitude',
  'region',
  'email'
])

const getDeliveryAddress = pipe(
  prop('deliveryAddress'),
  defaultTo({}),
  getFieldFromAddress,
)

const getBillingAddress = pipe(
  prop('billingAddress'),
  defaultTo({}),
  getFieldFromAddress,
)

export function SaleOrderCreateSerializer (formValues) {
  const deliveryAddress = getDeliveryAddress(formValues)
  const billingAddress = getBillingAddress(formValues)
  const customerGuid = getCustomerGuid(formValues)
  const warehouseGuid = getWarehouseGuid(formValues)
  const channelGuid = path(['channel', 'guid'], formValues)
  const deliveryMethodGuid = path(['deliveryMethod', 'guid'], formValues)
  const shippingFee = propOr(0, 'shippingFee', formValues)
  const extraFees = propOr(0, 'extraFees', formValues)

  const customer = customerGuid && { guid: customerGuid }
  const warehouse = warehouseGuid && { guid: warehouseGuid }
  const channel = channelGuid && { guid: channelGuid }
  const deliveryMethod = deliveryMethodGuid && { guid: deliveryMethodGuid }
  const dueDate = getDateTimeMidnight(prop('dueDate', formValues), DATE_FORMAT_ISO_8601)

  const data = {
    ...formValues,
    lineItems: getLineItems(formValues),
    customer,
    warehouse,
    deliveryAddress,
    billingAddress,
    channel,
    deliveryMethod,
    shippingFee,
    extraFees,
    dueDate
  }
  return pick(SALE_ORDER_CREATE_FIELDS, data)
}

export function SaleOrderProcessSerializer (guid) {
  return { guids: [ guid ] }
}

export const SaleOrderFilterSerializer = params => {
  const defaultParams = pick(DEFAULT_SEARCH_PARAMS, params)
  const status = prop('status', params)
  const isTracking = prop('isTracking', params)
  const deliveryCity = prop('deliveryCity', params)
  const allocationStatus = prop('allocationStatus', params)
  const fulfillmentStatus = prop('fulfillmentStatus', params)
  const shipmentStatus = prop('shipmentStatus', params)
  const paymentStatus = prop('paymentStatus', params)
  const totalPriceStart = prop('totalPriceStart', params)
  const totalPriceEnd = prop('totalPriceEnd', params)
  const lineItemsCountStart = prop('lineItemsCountStart', params)
  const lineItemsCountEnd = prop('lineItemsCountEnd', params)
  const totalWeightStart = prop('totalWeightStart', params)
  const totalWeightEnd = prop('totalWeightEnd', params)

  const customer = escapeAtob(prop('customer', params))
  const customerGuid = prop('guid', customer)
  const channelGuids = escapeAtob(prop('channels', params))
  const companyGuids = escapeAtob(prop('companies', params))
  const variantGuids = escapeAtob(prop('variants', params))
  const warehouseGuids = escapeAtob(prop('warehouses', params))
  const paymentType = escapeAtob(prop('paymentType', params))
  const deliveryCountry = escapeAtob(prop('deliveryCountry', params))
  const deliveryMethod = escapeAtob(prop('deliveryMethod', params))
  const createdAtStart = prop('createdAtStart', params)
  const createdAtEnd = prop('createdAtEnd', params)
  const updatedAtStart = prop('updatedAtStart', params)
  const updatedAtEnd = prop('updatedAtEnd', params)
  const confirmedAtStart = prop('confirmedAtStart', params)
  const confirmedAtEnd = prop('confirmedAtEnd', params)
  const tags = prop('tags', params)
  const trackingNumbers = prop('trackingNumbers', params)

  return orderingToSnackCase({
    ...defaultParams,
    status,
    isTracking,
    allocationStatus,
    fulfillmentStatus,
    shipmentStatus,
    paymentStatus,
    deliveryCountry,
    deliveryCity,
    deliveryMethod,
    customerGuid,
    channelGuids,
    companyGuids,
    totalPriceStart,
    totalPriceEnd,
    createdAtStart,
    createdAtEnd,
    updatedAtStart,
    updatedAtEnd,
    warehouseGuids,
    variantGuids,
    lineItemsCountStart,
    lineItemsCountEnd,
    totalWeightStart,
    totalWeightEnd,
    paymentType,
    tags,
    confirmedAtStart,
    confirmedAtEnd,
    trackingNumbers
  })
}

export function SaleOrderCustomerSerializer (values) {
  const firstName = prop('firstName', values)
  const lastName = prop('lastName', values)
  const email = prop('email', values)
  const mobile = prop('mobile', values)
  const customerAddress = pick(DELIVERY_ADDRESS_FIELDS, values)

  return {
    firstName,
    lastName,
    email,
    mobile,
    customerAddress
  }
}

export function SaleOrderSplitSerializer (guids) {
  return { warehouseGuids: guids }
}

export function PaymentTypeSetSerializer (guid, paymentType) {
  return { order: { guid }, paymentType }
}

export function SaleOrderProcessSkipSerializer (values) {
  return reduce((acc, value) => ({ ...acc, [value.key]: prop('value', value) }), {}, values)
}
