import React from 'react'
import PropTypes from 'prop-types'

import PageTitle from '~/components/PageTitle'
import { DiscardButton, SaveButton } from '~/components/Buttons'
import Container, { Content, Header } from '~/components/Container'
import { withForm } from '~/components/Form'
import * as NAV from '~/constants/nav-titles'

import BundleGeneral from '../BundleGeneral'

export const BUNDLE_CREATE_FIELDS = [
  'name',
  'description',
  'images',
  'sku',
  'lineItems',
  'barcode',
  'category',
  'brand',
  'tags'
]

function BundleCreateForm (props) {
  const {
    form,
    title,
    isLoading,
    onCategoryCreate,
    categoryLoading,
    onBrandCreate,
    brandLoading
  } = props

  const { handleSubmit } = form

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={title}
            parentTitle={NAV.BUNDLES}
            rightButton={(
              <>
                <DiscardButton disabled={isLoading} />
                <SaveButton disabled={isLoading} />
              </>
            )}
          />
        </Header>

        <Content>
          <BundleGeneral
            categoryLoading={categoryLoading}
            brandLoading={brandLoading}
            onBrandCreate={onBrandCreate}
            onCategoryCreate={onCategoryCreate}
          />
        </Content>
      </Container>
    </form>
  )
}

BundleCreateForm.propTypes = {
  form: PropTypes.object,
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onCategoryCreate: PropTypes.func.isRequired,
  onBrandCreate: PropTypes.func.isRequired,
  brandLoading: PropTypes.bool.isRequired,
  categoryLoading: PropTypes.bool.isRequired
}

export default withForm(BundleCreateForm)
