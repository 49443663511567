import React, { useMemo } from 'react'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import DeleteIcon from '@mui/icons-material/Delete'
import { CardContent, styled } from '@mui/material'
import Card from '@mui/material/Card'
import { map, path, prop } from 'ramda'
import Grid from '@mui/material/Grid'
import PropTypes from 'prop-types'

import Avatar from '~/components/Avatar'
import VerticalAlignment from '~/components/VerticalAlignment'
import TextOverflow from '~/components/TextOverflow'
import Subtext from '~/components/Subtext'
import { CardHeader } from '~/components/Cards'
import { TableNoCache } from '~/components/Table'

const CardStyled = styled(Card)({
  borderRadius: '10px',
  height: '100%',
  minHeight: '500px'
})

const CardContentStyled = styled(CardContent)({
  padding: 0,
  height: 'calc(100% - 28px)'
})

export const columns = [
  {
    width: 100,
    headerName: 'Image',
    field: 'image',
    renderCell: ({ value, row }) => (
      <Avatar
        alt={row.product.name}
        src={value}
      />
    )
  },
  {
    width: 200,
    headerName: 'Product',
    field: 'product',
    renderCell: ({ value }) => (
      <VerticalAlignment
        primary={<TextOverflow selfTooltip={true} lines={1}>{value.name}</TextOverflow>}
        secondary={<Subtext textOverflow={true} lines={1}>{value.sku}</Subtext>}
      />
    )
  },
  {
    width: 100,
    headerName: 'Container',
    field: 'container'
  },
  {
    width: 75,
    headerName: 'Qty',
    field: 'quantity',
    headerAlign: 'center',
    align: 'center'
  },
  {
    width: 150,
    headerName: 'Unit number',
    field: 'unitNumber'
  },
  {
    width: 200,
    headerName: 'Serial number',
    field: 'serialNumber'
  }
]

const getRowsFromResults = map(item => {
  const variant = prop('variant', item)
  const guid = prop('guid', item)
  const container = path(['container', 'number'], item)
  const quantity = prop('quantity', item)
  const name = prop('name', variant)
  const sku = prop('sku', variant)
  const unitNumber = prop('unitNumber', item)
  const serialNumber = prop('serialNumber', item)
  const image = prop('imagePath', variant)
  const product = { name, sku }

  return {
    guid,
    container,
    quantity,
    serialNumber,
    unitNumber,
    product,
    image
  }
})

function PutawayCreateTables (props) {
  const {
    sourceUnits,
    destUnits,
    onUnitDelete,
    fromLocation,
    isLoading
  } = props

  const locationRows = getRowsFromResults(sourceUnits)
  const scannedProductRows = getRowsFromResults(destUnits)

  const actionColumns = useMemo(() => [
    ...columns,
    {
      width: 50,
      resizable: false,
      field: 'actions',
      type: 'actions',
      getActions: ({ row }) => [
        <GridActionsCellItem
          label="Delete"
          onClick={() => onUnitDelete(row)}
          icon={<DeleteIcon />}
        />
      ]
    }
  ], [onUnitDelete])
  return (
    <>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12} lg={6}>
          <CardStyled>
            <CardHeader
              title={fromLocation ? `Your items in ${fromLocation} location` : 'Items'}
              sx={{ padding: '16px' }}
            />
            <CardContentStyled>
              <TableNoCache
                primaryKey="guid"
                isLoading={isLoading}
                columns={columns}
                pinnedColumns={['image']}
                rows={locationRows}
                height="100%"
                pagination={false}
              />
            </CardContentStyled>
          </CardStyled>
        </Grid>
        <Grid item={true} xs={12} lg={6}>
          <CardStyled>
            <CardHeader title="Scanned Items" sx={{ p: 2 }} />
            <CardContentStyled>
              <TableNoCache
                primaryKey="guid"
                isLoading={isLoading}
                columns={actionColumns}
                pinnedColumns={['image']}
                rows={scannedProductRows}
                pagination={false}
                height="100%"
              />
            </CardContentStyled>
          </CardStyled>
        </Grid>
      </Grid>
    </>
  )
}

PutawayCreateTables.propTypes = {
  onUnitDelete: PropTypes.func.isRequired,
  fromLocation: PropTypes.string.isRequired,
  sourceUnits: PropTypes.array.isRequired,
  destUnits: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default PutawayCreateTables
