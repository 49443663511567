import React from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import { prop } from 'ramda'
import PropTypes from 'prop-types'
import { SingleImagePreview } from 'storfox-image-upload'

import { CardHeader } from '~/components/Cards'
import TextLabelLoader from '~/components/TextLabelLoader'
import BooleanStatus from '~/components/BooleanStatus'

function SupplierGeneral ({ detail }) {
  const { t } = useTranslation()

  const companyName = prop('companyName', detail)
  const referenceId = prop('referenceId', detail)
  const shortName = prop('shortName', detail)
  const email = prop('email', detail)
  const contactFirstName = prop('contactFirstName', detail)
  const contactLastName = prop('contactLastName', detail)
  const contactEmail = prop('contactEmail', detail)
  const contactPhoneNumber = prop('contactPhoneNumber', detail)
  const status = prop('status', detail)
  const imagePath = prop('imagePath', detail)
  const supplierId = prop('id', detail)

  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12} lg={8}>
        <Box mb={3}>
          <Card>
            <CardHeader title={t('Information')} />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Company name">
                    {companyName}
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Short name">
                    {shortName}
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Supplier reference id">
                    {referenceId}
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Email">
                    {email}
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Supplier id">
                    {supplierId}
                  </TextLabelLoader>
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Box mb={3}>
          <Card>
            <CardHeader title={t('Primary Contact')} />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12} lg={6}>
                  <TextLabelLoader label="First name">
                    {contactFirstName}
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12} lg={6}>
                  <TextLabelLoader label="Last name">
                    {contactLastName}
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Email">
                    {contactEmail}
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Phone number">
                    {contactPhoneNumber}
                  </TextLabelLoader>
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>
      <Grid item={true} xs={12} lg={4}>
        <Box mb={3}>
          <Card>
            <CardHeader title={t('Image')} />
            <Divider />
            <CardContent>
              <SingleImagePreview src={imagePath} alt="Supplier image" />
            </CardContent>
          </Card>
        </Box>
        <Box mb={3}>
          <Card>
            <CardHeader title={t('Settings')} />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Status">
                    <BooleanStatus value={status} />
                  </TextLabelLoader>
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>

    </Grid>
  )
}

SupplierGeneral.propTypes = {
  detail: PropTypes.object.isRequired
}

export default SupplierGeneral
