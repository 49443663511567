import React from 'react'
import { propOr } from 'ramda'
import { MultiSelectSearchField } from 'storfox-form-fields'

import * as API from '~/constants/api'

const getOptionLabel = propOr('', 'companyName')

const getOptionValue = item => {
  if (item) {
    const { id, companyName, guid } = item

    return { id, companyName, guid }
  }

  return null
}

function ReorderSupplierField (props) {
  return (
    <MultiSelectSearchField
      name="suppliers"
      label="Suppliers"
      api={API.SUPPLIER_LIST}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      {...props}
    />
  )
}

export default ReorderSupplierField
