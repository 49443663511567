import React from 'react'
import { BrowserSearchField } from 'storfox-query-builder'

import * as API from '~/constants/api'

function CategorySearchField (props) {
  return (
    <BrowserSearchField
      api={API.CATEGORY_LIST}
      {...props}
    />
  )
}

export default CategorySearchField
