import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSnackbar } from 'storfox-snackbar'
import { useTranslation } from 'react-i18next'

import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'
import DashboardLayout from '~/components/Layouts/DashboardLayout'

import { useCurrencyRateDetail, useCurrencyRateUpdate } from '../../hooks/CurrencyRate'
import CurrencyRateCreateForm from '../../components/CurrencyRate/CurrencyRateCreate/CurrencyRateCreateForm'
import { CurrencyRateSerializer } from '../../serializers/CurrencyRate'

function CurrencyRateUpdateContainer () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { guid } = useParams()
  const snackbar = useSnackbar()
  const currencyRateDetail = useCurrencyRateDetail(guid)
  const currencyRateUpdate = useCurrencyRateUpdate()
  const message = t('Successfully updated')

  const handleSubmit = formValues =>
    currencyRateUpdate.create(CurrencyRateSerializer(formValues))
      .then(() => navigate(ROUTES.CURRENCY_RATE_LIST_PATH))
      .then(() => snackbar({ message }))

  const title = 'Currency Rate Detail'

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      activeNav={NAV.CONFIGURATION}
      breadcrumbs={breadcrumbs}
    >
      <CurrencyRateCreateForm
        title={title}
        onSubmit={handleSubmit}
        isLoading={currencyRateUpdate.isLoading}
        initialValues={currencyRateDetail.detail}
      />
    </DashboardLayout>
  )
}

export default CurrencyRateUpdateContainer
