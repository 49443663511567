import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { requestAnimationFramePolyfill } from './polyfills/raf-polyfill'

function PolyfillWrapper ({ children }) {
  useEffect(() => {
    requestAnimationFramePolyfill()
  }, [])

  return (
    <>{children}</>
  )
}

PolyfillWrapper.propTypes = {
  children: PropTypes.any.isRequired
}

export default PolyfillWrapper
