import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Status, { ERROR, SUCCESS } from '~/components/Status'

export const ACTIVE = 'active'
export const INACTIVE = 'inactive'

export const LOCATION_STATUS_LABEL = {
  [ACTIVE]: 'Active',
  [INACTIVE]: 'Inactive'
}

export const LOCATION_STATUS = {
  ACTIVE,
  INACTIVE
}
const STATUS_COLOR = {
  [LOCATION_STATUS.ACTIVE]: SUCCESS,
  [LOCATION_STATUS.INACTIVE]: ERROR,
}

function LocationStatus ({ value }) {
  const { t } = useTranslation()

  return (
    <Status
      label={t(LOCATION_STATUS_LABEL[value])}
      color={STATUS_COLOR[value]}
    />
  )
}

LocationStatus.propTypes = {
  value: PropTypes.string.isRequired
}

export default LocationStatus
