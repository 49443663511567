import React, { useCallback, useState } from 'react'
import { useFilter, useOrdering } from 'storfox-filter'
import { propEq } from 'ramda'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import useDialog from '~/hooks/useDialog'

import { useShipmentList } from '../hooks'
import {
  ShipmentFilterForm,
  shipmentFilterOptions,
  ShipmentOrderingForm,
  shipmentOrderingOptions,
  ShipmentTable
} from '../components/ShipmentList'
import ShipmentViewDialog from '../components/ShipmentList/ShipmentViewDialog'

function BucketListContainer () {
  const bucketList = useShipmentList()
  const [shipment, setShipment] = useState({})
  const { open, handleOpen, handleClose } = useDialog()

  const filter = useFilter(shipmentFilterOptions)
  const ordering = useOrdering(shipmentOrderingOptions)

  const handleViewShipment = useCallback((guid) => {
    const foundShipment = bucketList.results.find(propEq('guid', guid))
    setShipment(foundShipment)
    handleOpen()
  }, [bucketList.results, handleOpen])

  const breadcrumbs = { title: NAV.CROSS_SHIPMENTS }

  return (
    <DashboardLayout
      title={NAV.CROSS_SHIPMENTS}
      activeNav={NAV.CROSS_SHIPMENTS}
      breadcrumbs={breadcrumbs}
    >
      <ShipmentFilterForm {...filter} />
      <ShipmentOrderingForm {...ordering} />

      <ShipmentTable
        list={bucketList}
        filter={filter}
        ordering={ordering}
        onListRefetch={bucketList.getListByParams}
        onView={handleViewShipment}
      />
      {open && (
        <ShipmentViewDialog
          onClose={handleClose}
          open={open}
          shipment={shipment}
        />
      )}
    </DashboardLayout>
  )
}

export default BucketListContainer
