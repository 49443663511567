import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import EmptyLineItemsIcon from '../Icons/EmptyLineItemsIcon'

function NoRowsOverlayComponent () {
  return (
    <Box
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      padding={1}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: { xs: '60%', lg: '100%' }
        }}>
        <EmptyLineItemsIcon />
        <Typography variant="h6">No rows</Typography>
      </Box>
    </Box>
  )
}

export default NoRowsOverlayComponent
