import React from 'react'
import { Line } from 'react-chartjs-2'
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip
} from 'chart.js'
import PropTypes from 'prop-types'
import { prop } from 'ramda'

ChartJS.register(
  CategoryScale,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend
)

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  scales: {
    y: {
      grid: {
        borderDash: [2],
        borderDashOffset: [2],
        color: '#BDBDBD',
        drawBorder: false,
        zeroLineBorderDash: [2],
        zeroLineBorderDashOffset: [2],
        zeroLineColor: '#BDBDBD'
      },
    },
    x: {
      grid: {
        borderDash: [2],
        borderDashOffset: [2],
        color: '#BDBDBD',
        drawBorder: false,
        zeroLineBorderDash: [2],
        zeroLineBorderDashOffset: [2],
        zeroLineColor: '#BDBDBD'
      },
    }
  },
  plugins: {
    legend: {
      labels: {
        usePointStyle: true
      }
    }
  }
}

function SoChart ({ data: dataProp, labels }) {
  const data = {
    labels,
    datasets: [
      {
        label: 'Orders',
        data: prop('orders', dataProp),
        borderColor: '#4BC0C0',
        backgroundColor: '#4BC0C0',
      },
      {
        label: 'Units',
        data: prop('units', dataProp),
        borderColor: '#656EEA',
        backgroundColor: '#656EEA',
      },
    ],
  }

  return (
    <Line options={options} data={data} height={400} />
  )
}

SoChart.propTypes = {
  labels: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired
}

export default SoChart
