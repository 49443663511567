import { prop, reduce } from 'ramda'

const stockInitValues = {
  available: 0,
  unavailable: 0,
  onHand: 0,
  allocated: 0,
  awaiting: 0
}

const accTotal = (acc, row) => {
  const available = prop('available', row) + prop('available', acc)
  const unavailable = prop('unavailable', row) + prop('unavailable', acc)
  const onHand = prop('onHand', row) + prop('onHand', acc)
  const allocated = prop('allocated', row) + prop('allocated', acc)

  return { available, unavailable, onHand, allocated }
}

export const getStockTotalValues = reduce(accTotal, stockInitValues)
