import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFilter, useOrdering } from 'storfox-filter'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import useMessages from '~/hooks/useMessages'
import ErrorLink from '~/components/ErrorLink'
import { useTableSelects } from '~/components/TableValues/hooks'

import { useAdjustmentReasonBulkRemove, useAdjustmentReasonList } from '../../hooks/AdjustmentReason'
import {
  AdjustmentReasonFilterForm,
  adjustmentReasonFilterOptions,
  AdjustmentReasonOrderingForm,
  adjustmentReasonOrderingOptions,
  AdjustmentReasonTable
} from '../../components/AdjustmentReason/AdjustmentReasonList'

function AdjustmentReasonListContainer () {
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const messages = useMessages()
  const { selects } = useTableSelects()

  const adjustmentReasonList = useAdjustmentReasonList()
  const adjustmentReasonRemove = useAdjustmentReasonBulkRemove()

  const filter = useFilter(adjustmentReasonFilterOptions)
  const ordering = useOrdering(adjustmentReasonOrderingOptions)

  const title = t('Adjustment reasons')

  const handleAdjustmentReasonsDelete = () =>
    adjustmentReasonRemove.delete({ guids: selects })
      .then(() => snackbar({ message: messages.DELETE_SUCCESS }))
      .then(() => adjustmentReasonList.getList())
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      activeNav={NAV.CONFIGURATION}
      breadcrumbs={breadcrumbs}
    >
      <AdjustmentReasonFilterForm {...filter} />
      <AdjustmentReasonOrderingForm {...ordering} />

      <AdjustmentReasonTable
        pageTitle={title}
        filter={filter}
        ordering={ordering}
        adjustmentReasonList={adjustmentReasonList}
        onListRefetch={adjustmentReasonList.getListByParams}
        onAdjustmentReasonsDelete={handleAdjustmentReasonsDelete}
        bulkActionLoading={adjustmentReasonRemove.isLoading}
      />

    </DashboardLayout>
  )
}

export default AdjustmentReasonListContainer
