import React from 'react'
import PropTypes from 'prop-types'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import { generatePath, useParams } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import { path, prop } from 'ramda'

import { CardHeader } from '~/components/Cards'
import { EditButton } from '~/components/Buttons'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import TextLabelLoader from '~/components/TextLabelLoader'
import ZoneStatus from '~/components/Statuses/ZoneStatus'

import More from '../ZoneUpdate/More'

function ZoneDetail (props) {
  const {
    pageTitle,
    pageTitleLoading,
    isLoading,
    detail,
    onRemove
  } = props

  const { guid } = useParams()
  const updatePath = generatePath(ROUTES.ZONE_UPDATE_PATH, { guid })

  const warehouse = path(['warehouse', 'name'], detail)
  const area = path(['area', 'name'], detail)
  const name = prop('name', detail)
  const label = prop('label', detail)
  const status = prop('status', detail)

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={pageTitle}
          pageTitleLoading={pageTitleLoading}
          parentTitle={NAV.ZONES}
          rightButton={(
            <>
              <EditButton disabled={isLoading} path={updatePath} />
              <More onRemove={onRemove} />
            </>
          )}
        />
      </Header>

      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={12} lg={9}>
            <Box mb={3}>
              <Card>
                <CardHeader title="Details" />
                <Divider />
                <CardContent>
                  <Grid container={true} spacing={3}>
                    <Grid item={true} xs={12} lg={6}>
                      <TextLabelLoader label="Warehouse">{warehouse}</TextLabelLoader>
                    </Grid>

                    <Grid item={true} xs={12} lg={6}>
                      <TextLabelLoader label="Area">{area}</TextLabelLoader>
                    </Grid>

                    <Grid item={true} xs={12} lg={6}>
                      <TextLabelLoader label="Label">{label}</TextLabelLoader>
                    </Grid>

                    <Grid item={true} xs={12} lg={6}>
                      <TextLabelLoader label="Name">{name}</TextLabelLoader>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </Grid>

          <Grid item={true} xs={12} lg={3}>
            <Box mb={3}>
              <Card>
                <CardHeader title="Settings" />
                <Divider />
                <CardContent>
                  <Grid container={true} spacing={3}>
                    <Grid item={true} xs={12}>
                      <TextLabelLoader label="Status">
                        <Typography variant="subtitle1">
                          <ZoneStatus value={status} />
                        </Typography>
                      </TextLabelLoader>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

ZoneDetail.propTypes = {
  pageTitle: PropTypes.string,
  pageTitleLoading: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onRemove: PropTypes.func.isRequired,
  detail: PropTypes.object.isRequired
}

export default ZoneDetail
