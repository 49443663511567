import React from 'react'
import PropTypes from 'prop-types'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

import { PICKLIST_TABS } from '~/constants/tabs'
import Tabs from '~/components/Tabs'
import * as ROUTES from '~/constants/routes'

const tabs = [
  { value: PICKLIST_TABS.GENERAL, label: 'General' },
  { value: PICKLIST_TABS.HISTORY, label: 'History' }
]

function PicklistDetailTabs ({ tab }) {
  const { guid } = useParams()
  const navigate = useNavigate()

  const onChange = (event, value) => {
    const path = generatePath(ROUTES.PICKLIST_UPDATE_PATH, { guid, tab: value })
    navigate(path, { replace: true })
  }

  return (
    <Tabs
      value={tab}
      tabs={tabs}
      variant="scrollable"
      scrollButtons={false}
      onChange={onChange}
    />
  )
}

PicklistDetailTabs.propTypes = {
  tab: PropTypes.string.isRequired
}

export default PicklistDetailTabs
