import React from 'react'
import PropTypes from 'prop-types'
import { useRequest } from 'storfox-api-hooks'
import { Field } from 'react-final-form'
import MUITextField from '@mui/material/TextField'
import { path } from 'ramda'
import debounce from 'debounce-promise'
import isEqual from 'react-fast-compare'

import * as API from '~/constants/api'

const simpleMemoize = fn => {
  let lastArg
  let lastResult
  return arg => {
    if (arg !== lastArg) {
      lastArg = arg
      lastResult = fn(arg)
    }
    return lastResult
  }
}

function CheckEmailField ({ name, checkEmail = true, ...props }) {
  const request = useRequest()
  const emailAvailable = async email =>
    request
      .post(API.CHECK_EMAIL, { email })
      .then(() => null)
      .catch(path(['response', 'data', 'email', 0])
      )

  const debouncedEmailAvailable = simpleMemoize(debounce(emailAvailable, 2000))

  return (
    <Field name={name} validate={checkEmail ? debouncedEmailAvailable : null}>
      {({ input, meta }) => {
        return (
          <MUITextField
            {...props}
            error={meta.invalid}
            helperText={meta.submitError || meta.error}
            value={input.value}
            onChange={event => {
              const value = event.target.value
              const trimmedValue = value.trim()
              input.onChange(trimmedValue)
            }}
          />
        )
      }}
    </Field>
  )
}

CheckEmailField.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  checkEmail: PropTypes.bool,
  variant: PropTypes.string.isRequired
}

CheckEmailField.defaultProps = {
  size: 'small',
  fullWidth: true,
  variant: 'outlined'
}

export default React.memo(CheckEmailField, isEqual)
