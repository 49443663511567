import { useEffect, useRef } from 'react'

function useValueChanged (callback, value) {
  const ref = useRef(value)

  useEffect(() => {
    if (ref.current && ref.current !== value) {
      callback(value)
    }
    ref.current = value
  })
}

export default useValueChanged
