import React from 'react'
import Box from '@mui/material/Box'

import ProductUpdateFormVariantsOptions from './ProductUpdateFormVariantsOptions'
import ProductUpdateFormVariantsTable from './ProductUpdateFormVariantsTable'

function ProductUpdateFormVariants () {
  return (
    <>
      <ProductUpdateFormVariantsOptions />

      <Box mt={3}>
        <ProductUpdateFormVariantsTable />
      </Box>
    </>
  )
}

export default ProductUpdateFormVariants
