import React from 'react'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'

import TileCard, { TileCardLink } from '~/components/Cards/TileCard'

const IconStyled = styled('img')(({ theme }) => ({
  ...theme.components.StorfoxMenuCard.styleOverrides.icon,
  fontSize: '2rem'
}))

function MenuItem ({ title, path, description, icon: Icon, disabled, className }) {
  const { t } = useTranslation()

  return (
    <TileCard
      disabled={disabled}
      path={path}
      className={className}
    >
      <TileCardLink to={path} disabled={disabled}>
        <IconStyled as={Icon} />
        <Box width="100%" pl={1}>
          <Typography component="span" variant="h5">
            {t(title)}
          </Typography>
          <Typography>
            {t(description)}
          </Typography>
        </Box>
      </TileCardLink>
    </TileCard>
  )
}

MenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  icon: PropTypes.any
}

export default MenuItem
