import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import { Field } from 'react-final-form'
import { useDeepCompareEffect, useRequest } from 'storfox-api-hooks'

const getDefaultOptionLabel = option => option.name

function AsyncMultiSelectField ({ name, api, params, disabled, getOptionLabel, ...props }) {
  const [open, setOpen] = React.useState(false)
  const [options, setOptions] = React.useState([])
  const request = useRequest()
  const loading = open && options.length === 0

  const getOptionSelected = (option, value) => {
    if (option.guid) {
      return option.guid === value.guid
    }
    return option.id === value.id
  }

  useDeepCompareEffect(() => {
    let active = true

    if (!loading) {
      return undefined
    }

    (async () => {
      const { data } = await request.get(api, params)
      const { results } = data

      if (active) {
        setOptions(Object.keys(results).map(key => results[key]))
      }
    })()

    return () => {
      active = false
    }
  }, [loading])

  React.useEffect(() => {
    if (!open) {
      setOptions([])
    }
  }, [open])

  return (
    <Field name={name}>
      {({ input, meta, }) => {
        return (
          <Autocomplete
            open={open}
            disabled={disabled}
            onOpen={() => {
              setOpen(true)
            }}
            onClose={() => {
              setOpen(false)
            }}
            multiple={true}
            disableCloseOnSelect={true}
            value={input.value || []}
            isOptionEqualToValue={getOptionSelected}
            getOptionLabel={getOptionLabel}
            options={options.length === 0 && !open ? input.value || [] : options}
            loading={loading}
            onChange={(event, value) => input.onChange(value)}
            renderInput={defaultProps => (
              <TextField
                {...defaultProps}
                {...props}
                error={meta.invalid}
                helperText={meta.submitError || meta.error}
                InputProps={{
                  ...defaultProps.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {defaultProps.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        )
      }}
    </Field>
  )
}

AsyncMultiSelectField.propTypes = {
  api: PropTypes.string.isRequired,
  params: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  getOptionLabel: PropTypes.func.isRequired,
}

AsyncMultiSelectField.defaultProps = {
  params: { limit: 1000 },
  size: 'small',
  fullWidth: true,
  variant: 'outlined',
  disabled: false,
  getOptionLabel: getDefaultOptionLabel
}

export default AsyncMultiSelectField
