import * as ROUTES from '~/constants/routes'
import {
  API, JT_EXPRESS,
  MAGENTO,
  POSTMEN,
  SALLA,
  SHIP_ROCKET,
  SHIPOX,
  SHOPIFY,
  STOREFRONT as SF,
  WOO_COMMERCE,
  ZID,
  IMILE,
  ELITE,
  LCS,
  TCS,
  RIDER,
  TRAX,
  CALL_COURIER
} from '~/constants/integrationTypes'

// TYPES
export const STOREFRONT = 'storefront'
export const PAYMENT = 'payment'
export const SHIPMENT = 'shipment'
export const ERP = 'erp'

export const NAMES = {
  [STOREFRONT]: 'Storefront',
  [PAYMENT]: 'Payment',
  [SHIPMENT]: 'Shipment',
  [ERP]: 'ERP'
}

export const NAMES_BY_SUBTYPES = {
  [SF]: NAMES[STOREFRONT],
  [SHOPIFY]: NAMES[STOREFRONT],
  [SHIPOX]: NAMES[SHIPMENT],
  [WOO_COMMERCE]: NAMES[STOREFRONT],
  [API]: NAMES[STOREFRONT],
  [MAGENTO]: NAMES[STOREFRONT],
  [ZID]: NAMES[STOREFRONT],
  [POSTMEN]: NAMES[SHIPMENT],
  [SALLA]: NAMES[STOREFRONT],
  [SHIP_ROCKET]: NAMES[SHIPMENT],
  [JT_EXPRESS]: NAMES[SHIPMENT],
  [IMILE]: NAMES[SHIPMENT],
  [TRAX]: NAMES[SHIPMENT],
  [CALL_COURIER]: NAMES[SHIPMENT]
}

export const INTEGRATIONS = [
  {
    type: STOREFRONT,
    subtype: SF,
    name: 'Storfox Shop',
    description: 'Create a free world-class, fully-featured online stores that are scalable, responsive and secure.',
    disabled: false,
    path: ROUTES.INTEGRATION_CREATE_PATH
  },
  {
    type: STOREFRONT,
    subtype: API,
    name: 'Public API',
    description: 'Connect with our Public MARKETPLACE_API, which allows you to use the system in the full ability',
    disabled: false,
    path: ROUTES.API_INTEGRATION_CREATE_PATH
  },
  {
    type: STOREFRONT,
    subtype: SHOPIFY,
    name: 'Shopify',
    description: `Storfox becomes the source of truth and controls your products and inventory in Shopify,
    while orders are sent back to Storfox for fulfillment.`,
    disabled: false,
    path: ROUTES.SHOPIFY_INSTRUCTION_CONTAINER
  },
  {
    type: STOREFRONT,
    subtype: WOO_COMMERCE,
    name: 'WooCommerce',
    description: `Storfox becomes the source of truth and controls your products and inventory in WooCommerce,
    while orders are sent back to Storfox for fulfillment.`,
    disabled: false,
    path: ROUTES.WOOCOMMERCE_INTEGRATION_CREATE_PATH
  },
  {
    type: SHIPMENT,
    subtype: SHIPOX,
    name: 'Shipox',
    description: `Shipox offers solutions from pickup to last mile delivery. It features driver apps,
    COD processing and real-time tracking of all delivery personnel and vehicles with various mapping integrations.`,
    disabled: false,
    path: ROUTES.SHIPOX_INTEGRATION_CREATE_PATH
  },
  {
    type: STOREFRONT,
    subtype: ZID,
    name: 'Zid',
    description: `Zid offers solutions from pickup to last mile delivery. It features driver apps,
    COD processing and real-time tracking of all delivery personnel and vehicles with various mapping integrations.`,
    disabled: false,
    path: ROUTES.ZID_INTEGRATION_APPLICATION_CREATE_PATH
  },
  {
    type: SHIPMENT,
    subtype: POSTMEN,
    name: 'Postmen',
    description: `Scalable multi-carrier shipping app and shipping API to print labels and calculate rates.
    Supports 66 carriers like UPS, USPS, DHL, China Post, and FedEx.`,
    disabled: false,
    path: ROUTES.POSTMEN_INTEGRATION_CREATE_PATH
  },
  {
    type: STOREFRONT,
    subtype: SALLA,
    name: 'Salla',
    description: `Salla a Saudi-based e-commerce platform that allows users to set up their e-commerce store in Arabic
    and sell their products and services to customers.`,
    disabled: false,
    path: ROUTES.SALLA_INSTRUCTION_CONTAINER
  },
  {
    type: SHIPMENT,
    subtype: SHIP_ROCKET,
    name: 'ShipRocket',
    description: `Multi-Carrier Shipping Solution For eCommerce An all-in-one shipping platform that helps you provide a
    delightful last mile experience using automated operations, multiple channel & carrier integrations, 
    customer-centric features & advanced analytics`,
    disabled: false,
    path: ROUTES.SHIPROCKET_INTEGRATION_CREATE_PATH
  },
  {
    type: SHIPMENT,
    subtype: JT_EXPRESS,
    name: 'J&T Express',
    description: `J&T Express is an express delivery company based on top-notch modernized e-technology with tagline 
    “Express Your Online Business” which positions their brand as the core of e-commerce express.`,
    disabled: false,
    path: ROUTES.JT_EXPRESS_INTEGRATION_CREATE_PATH
  },
  {
    type: SHIPMENT,
    subtype: IMILE,
    name: 'iMile',
    description: `Reliable and efficient eCommerce delivery solutions. iMile uses smart logistics technology to offer 
    unparalleled efficiencies throughout the supply chain.`,
    disabled: false,
    path: ROUTES.IMILE_INTEGRATION_CREATE_PATH
  },
  {
    type: SHIPMENT,
    subtype: ELITE,
    name: 'Elite',
    description: `Elite Co. is a leading logistics technology company in the GCC offering end-to-end supply chain
    solutions across FTL, LTL, last mile delivery & other value added services.`,
    disabled: false,
    path: ROUTES.ELITE_INTEGRATION_CREATE_PATH
  },
  {
    type: SHIPMENT,
    subtype: TCS,
    name: 'TCS',
    description: `As one of the largest logistics organisations in Pakistan, TCS is aware of its responsibility of
    giving back to the society. In fact, helping the less fortunate is one of the founding pillars of our organisation.
    Over the years, we have worked to build systems and programmes that allow us to do good for society and to provide
    assistance to the less fortunate whenever needed..`,
    disabled: false,
    path: ROUTES.TCS_INTEGRATION_CREATE_PATH
  },
  {
    type: SHIPMENT,
    subtype: LCS,
    name: 'LCS',
    description: `Leopards Tracking helps you trace the status of your shipments and parcels. Easily track your
    shipments online in just one click. Simply enter your tracking number and click on the Track Button. Our tracking
    is fast, simple and convenient. You can track your shipment easily from anywhere from any device.`,
    disabled: false,
    path: ROUTES.LCS_INTEGRATION_CREATE_PATH
  },
  {
    type: SHIPMENT,
    subtype: RIDER,
    name: 'Rider',
    description: `Rider is a fast-growing, market-leading e-commerce logistics startup in Pakistan.`,
    disabled: false,
    path: ROUTES.RIDER_INTEGRATION_CREATE_PATH
  },
  {
    type: SHIPMENT,
    subtype: TRAX,
    name: 'Trax',
    description: `Trax is an end-to-end logistics platform that allows you to drive sales, retain or manage them,
    book deliveries, update and track them in real-time, and manage payments & invoices. Furthermore, you can view
    detailed reports and analytics for your shipments & payments, as well as you can manage the customer experience
    through an in-built CRM and other value added services.`,
    disabled: false,
    path: ROUTES.TRAX_INTEGRATION_CREATE_PATH
  },
  {
    type: SHIPMENT,
    subtype: CALL_COURIER,
    name: 'Call Courier',
    description: `Call Courier is group of dedicated, highly professional staff within the field of courier services. 
    We are ready to give solutions tailored to customer needs and requirements. To confirm elevated precision in our
    services, we have developed a complete infrastructure required to handle both national and international
    delivery of shipments`,
    disabled: false,
    path: ROUTES.CALL_COURIER_INTEGRATION_CREATE_PATH
  }
]

export const POSTMEN_TYPES = {
  FEDEX: 'fedex',
  ARAMEX: 'aramex',
  DHL:'dhl'
}

export const POSTMEN_ENVIRONMENT = {
  PRODUCTION: 'production',
  SANDBOX: 'sandbox'
}
