import React from 'react'
import { prop } from 'ramda'
import PropTypes from 'prop-types'

import {
  AllocationStatus,
  FulfillmentStatus,
  PaymentStatus,
  SaleOrderStatus,
  ShipmentStatus
} from '~/components/Statuses'
import StatusGrid, { StatusGridItem } from '~/components/StatusGrid'

function Statuses ({ isLoading, detail }) {
  const orderStatus = prop('status', detail)
  const allocationStatus = prop('allocationStatus', detail)
  const fulfillmentStatus = prop('fulfillmentStatus', detail)
  const shipmentStatus = prop('shipmentStatus', detail)
  const paymentStatus = prop('paymentStatus', detail)

  return (
    <StatusGrid>
      <StatusGridItem
        title="Order"
        isLoading={isLoading}
      >
        {orderStatus && <SaleOrderStatus value={orderStatus} />}
      </StatusGridItem>

      <StatusGridItem
        title="Allocation"
        isLoading={isLoading}
      >
        {allocationStatus && <AllocationStatus value={allocationStatus} />}
      </StatusGridItem>

      <StatusGridItem
        title="Fulfillment"
        isLoading={isLoading}
      >
        {fulfillmentStatus && <FulfillmentStatus value={fulfillmentStatus} />}
      </StatusGridItem>

      <StatusGridItem
        title="Shipment"
        isLoading={isLoading}
      >
        {shipmentStatus && <ShipmentStatus value={shipmentStatus} />}
      </StatusGridItem>

      <StatusGridItem
        title="Payment"
        isLoading={isLoading}
      >
        {paymentStatus && <PaymentStatus value={paymentStatus} />}
      </StatusGridItem>
    </StatusGrid>
  )
}

Statuses.propTypes = {
  detail: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default Statuses
