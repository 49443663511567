import React from 'react'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import Card from '@mui/material/Card'
import PropTypes from 'prop-types'

import { CardHeader } from '~/components/Cards'

import Notes from './Notes'

function NotesCard (props) {
  const {
    title = 'Notes',
    notes,
    emptyText
  } = props

  return (
    <Card style={{ height: '100%' }}>
      <CardHeader title={title} />
      <Divider />
      <CardContent style={{ height: '80%' }}>
        <Notes notes={notes} emptyText={emptyText} />
      </CardContent>
    </Card>
  )
}

NotesCard.propTypes = {
  title: PropTypes.string,
  notes: PropTypes.string,
  emptyText: PropTypes.string
}

export default NotesCard
