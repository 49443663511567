import PropTypes from 'prop-types'
import React, { useEffect, useContext, useCallback } from 'react'
import { isProd } from 'storfox-api-hooks'
import { includes, pathOr } from 'ramda'

import { useProfile } from '~/components/Profile'

import Context from './context'

export const useGoogleEvent = () => {
  const { sendEvent } = useContext(Context)

  return { sendEvent }
}

function GoogleAnalytics ({ children }) {
  const profile = useProfile()
  const isTestAccount = includes('test-', pathOr('', ['profile', 'email'], profile))

  useEffect(() => {
    if (isProd && !isTestAccount) {
      const gaTagManagerScript = document.createElement('script')
      gaTagManagerScript.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${process.env.GA_KEY}');
      `
      document.head.insertBefore(gaTagManagerScript, document.head.childNodes[0])

      const gaAnalyticManagerScript = document.createElement('script')
      gaAnalyticManagerScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', '${process.env.GA_KEY}');
      `
      document.head.insertBefore(gaAnalyticManagerScript, document.head.childNodes[0])

      const gaTagManagerNoscript = document.createElement('noscript')
      const iframe = document.createElement('iframe')
      iframe.src = `https://www.googletagmanager.com/ns.html?id=${process.env.GA_KEY}`
      iframe.height = '0'
      iframe.width = '0'
      iframe.style = 'display: none; visibility: hidden'

      gaTagManagerNoscript.appendChild(iframe)
      document.body.insertBefore(gaTagManagerNoscript, document.body.childNodes[0])
    }
  }, [isTestAccount])

  const sendEvent = useCallback(({ eventType = 'click', eventCategory, eventAction }) => {
    if (isProd && !isTestAccount) {
      window.gtag('event', eventType, {
        event_category: eventCategory,
        event_action: eventAction
      })
    }
  }, [isTestAccount])

  return (
    <Context.Provider value={{ sendEvent }}>
      {children}
    </Context.Provider>
  )
}

GoogleAnalytics.propTypes = {
  children: PropTypes.any.isRequired
}

export default GoogleAnalytics
