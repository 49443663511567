import React from 'react'
import PropTypes from 'prop-types'
import CallReceived from '@mui/icons-material/CallReceived'

import { GoButton } from '~/components/Buttons'
import * as ROUTES from '~/constants/routes'
import { RECEIVE_TABS } from '~/constants/tabs'

function ReceiveButton ({ guid, ...props }) {
  return (
    <GoButton
      variant="contained"
      to={ROUTES.RECEIVE_PURCHASE_ORDER_DETAIL_PATH}
      params={{ guid, tab: RECEIVE_TABS.GENERAL }}
      startIcon={<CallReceived />}
      {...props}
    >
      Receive
    </GoButton>
  )
}

ReceiveButton.propTypes = {
  guid: PropTypes.string
}

export default ReceiveButton
