import React from 'react'
import Card from '@mui/material/Card'
import { useTranslation } from 'react-i18next'
import CardContent from '@mui/material/CardContent'
import { Divider } from '@mui/material'
import { prop } from 'ramda'
import { generatePath, Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import PropTypes from 'prop-types'

import { CardHeader } from '~/components/Cards'
import TableDateFormat from '~/components/TableDateFormat'
import * as ROUTES from '~/constants/routes'
import { PAYMENT_TYPES } from '~/constants/paymentTypes'
import { SALE_RETURN_TABS, SALE_ORDER_DETAIL_TABS } from '~/constants/tabs'
import CurrencyMoney from '~/components/Money/CurrencyMoney'
import { SALE_ORDER } from '~/constants/shipmentTypes'

function ShipmentOrderDetails ({ orderDetail }) {
  const { t } = useTranslation()

  const guid = prop('toObjectGuid', orderDetail) || 'unknown'
  const paymentType = prop('paymentType', orderDetail)
  const referenceNumber = prop('toObjectReferenceNumber', orderDetail)
  const deliveryMethod = prop('deliveryMethod', orderDetail)
  const orderDate = prop('orderDate', orderDetail)
  const toObjectTotalPrice = prop('toObjectTotalPrice', orderDetail)
  const currency = prop('currency', orderDetail)
  const orderNumber = prop('orderNumber', orderDetail)
  const toObjectType = prop('toObjectType', orderDetail)

  const isSaleOrder = toObjectType === SALE_ORDER

  const link = isSaleOrder
    ? generatePath(ROUTES.SALE_ORDER_DETAIL_PATH, { guid, tab: SALE_ORDER_DETAIL_TABS.GENERAL })
    : generatePath(ROUTES.RETURN_DETAIL_PATH, { guid, tab: SALE_RETURN_TABS.GENERAL })

  return (
    <Card>
      <CardHeader title={t('Order')} />
      <Divider />
      <CardContent style={{ padding: 0 }}>
        {orderNumber && (
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell>{t('Sale order')}:&nbsp;</TableCell>
                <TableCell>
                  <Link
                    component={RouterLink}
                    to={link}
                    underline="always"
                  >
                    {orderNumber}
                  </Link>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>{t('Reference number')}</TableCell>
                <TableCell>{referenceNumber}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>{t('Delivery method')}</TableCell>
                <TableCell>{deliveryMethod}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>{t('Payment method')}</TableCell>
                <TableCell>{PAYMENT_TYPES[paymentType]}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>{t('Order price')}</TableCell>
                <TableCell>
                  <CurrencyMoney currency={currency} value={toObjectTotalPrice} />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>{t('Order date')}</TableCell>
                <TableCell>
                  <TableDateFormat date={orderDate} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
      </CardContent>
    </Card>
  )
}

ShipmentOrderDetails.propTypes = {
  orderDetail: PropTypes.object
}

export default ShipmentOrderDetails
