import React from 'react'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'
import { useFilter, useOrdering } from 'storfox-filter'

import { DashboardLayout } from '~/components/Layouts'
import ErrorLink from '~/components/ErrorLink'
import * as NAV from '~/constants/nav-titles'
import useMessages from '~/hooks/useMessages'
import { useTableSelects } from '~/components/TableValues/hooks'

import {
  VariantFilterForm,
  variantFilterOptions,
  VariantOrderingForm,
  variantOrderingOptions,
  VariantTable
} from '../components/VariantList'
import {
  useVariantChannelList,
  useVariantList,
  useVariantListingCreate,
  useVariantStockSync,
  useVariantDelete
} from '../hooks'
import { VariantListingAddSerializer } from '../serializers'

function VariantListContainer () {
  const snackbar = useSnackbar()
  const messages = useMessages()

  const variantList = useVariantList()
  const variantListingCreate = useVariantListingCreate()
  const variantChannelList = useVariantChannelList()
  const variantStockSync = useVariantStockSync()
  const variantDelete = useVariantDelete()

  const { selects, resetTableSelects } = useTableSelects()

  const filter = useFilter(variantFilterOptions)
  const ordering = useOrdering(variantOrderingOptions)

  const handleListingAdd = selectedChannels =>
    variantListingCreate
      .create(VariantListingAddSerializer(selects, selectedChannels))
      .then(() => snackbar({ message: messages.CREATE_SUCCESS }))
      .then(() => resetTableSelects())
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const handleStockSync = () =>
    variantStockSync
      .sync({ guids: selects })
      .then(() => snackbar({ message: messages.SYNC_SUCCESS }))
      .then(() => resetTableSelects())
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const handleVariantDelete = () =>
    variantDelete
      .delete({ guids: selects })
      .then(() => snackbar({ message: messages.DELETE_SUCCESS }))
      .then(() => resetTableSelects())
      .then(() => variantList.getListByParams())
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const breadcrumbs = { title: NAV.VARIANTS }

  return (
    <DashboardLayout
      title={NAV.VARIANTS}
      activeNav={NAV.VARIANTS}
      breadcrumbs={breadcrumbs}
    >
      <VariantFilterForm {...filter} />
      <VariantOrderingForm {...ordering} />

      <VariantTable
        filter={filter}
        ordering={ordering}
        list={variantList}
        onListRefetch={variantList.getListByParams}
        channelList={variantChannelList}
        onListingAdd={handleListingAdd}
        onStockSync={handleStockSync}
        onVariantDelete={handleVariantDelete}
        bulkActionLoading={variantListingCreate.isLoading || variantStockSync.isLoading}
      />
    </DashboardLayout>
  )
}

export default VariantListContainer
