import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import ZidApplicationContainer from './ZidApplicationContainer'
import ZidIntegrationCreateContainer from './ZidIntegrationCreateContainer'
import ZidIntegrationUpdateContainer from './ZidIntegrationUpdateContainer'

export default () => {
  return [
    {
      layout: AuthLayout,
      path: ROUTES.ZID_INTEGRATION_CREATE_PATH,
      component: ZidIntegrationCreateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.ZID_INTEGRATION_APPLICATION_CREATE_PATH,
      component: ZidApplicationContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.ZID_INTEGRATION_UPDATE_PATH,
      component: ZidIntegrationUpdateContainer
    }
  ]
}
