import React from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { path } from 'ramda'
import { useSnackbar } from 'storfox-snackbar'
import { useTranslation } from 'react-i18next'

import { DashboardLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'
import { USER_DETAIL_TABS } from '~/constants/tabs'

import { ACTIVE } from '../constants'
import { useUserCreate } from '../hooks'
import { UserCreateSerializer } from '../serializers'
import { UserCreateForm } from '../components/UserCreate'

function UserCreateContainer () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const snackbar = useSnackbar()

  const { create, isLoading, } = useUserCreate()

  const message = t('Successfully created')
  const title = 'New User'

  const handleSubmit = formValues =>
    create(UserCreateSerializer(formValues))
      .then(data => {
        const id = path(['result', 'id'], data)
        const redirect = generatePath(ROUTES.USER_DETAIL_PATH, { id, tab : USER_DETAIL_TABS.GENERAL })

        navigate(redirect)
      })
      .then(() => snackbar({ message }))

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      activeNav={NAV.USERS}
      breadcrumbs={breadcrumbs}
    >
      <UserCreateForm
        title={title}
        onSubmit={handleSubmit}
        isLoading={isLoading}
        initialValues={{ status: ACTIVE }}
      />
    </DashboardLayout>
  )
}

export default UserCreateContainer
