import { MAC_OS } from '~/utils'
import palette from '~/theme/dark/palette'

export default {
  styleOverrides: !MAC_OS
    ? (`
       :root ::-webkit-scrollbar {
        width: 12px;
        height: 10px;
      }

      :root ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: #b4b4b4;
        border: 2px ${palette.background.paper} solid;
      }
    `)
    : ``
}
