import React from 'react'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import { path, prop, propOr } from 'ramda'
import TableBody from '@mui/material/TableBody'
import PropTypes from 'prop-types'
import TableCell from '@mui/material/TableCell'
import Paper from '@mui/material/Paper'
import { usePickSearchParams } from 'storfox-route-hooks'
import Pagination from 'storfox-table/src/Table/Pagination'
import { generatePath, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material'

import Money from '~/components/Money'
import TableDateFormat from '~/components/TableDateFormat'
import Tags from '~/components/Tags'
import TableNotes from '~/components/TableNotes'
import SkeletonList from '~/components/Skeleton'
import Channel from '~/components/Channel'
import { FulfillmentProgress, SaleOrderStatus, ShipmentStatus } from '~/components/Statuses'
import TextOverflow from '~/components/TextOverflow'
import { HoverableRow } from '~/components/Table'
import * as ROUTES from '~/constants/routes'
import { SALE_ORDER_DETAIL_TABS } from '~/constants/tabs'

const WideTableCellStyled = styled(TableCell)({
  minWidth: 200
})

const MediumTableCellStyled = styled(TableCell)({
  minWidth: 150
})

function SupplierUpdatePurchases ({ orderList }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { limit } = usePickSearchParams(['limit'])

  const rowCount = limit ? parseInt(limit) : 10

  const list = prop('results', orderList)
  const count = prop('count', orderList)
  const isLoading = prop('isLoading', orderList)

  const handleClick = id => {
    const path = generatePath(ROUTES.SALE_ORDER_UPDATE_PATH, { id, tab: SALE_ORDER_DETAIL_TABS.GENERAL })
    navigate(path)
  }

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableCell sx={{ minWidth: '100px' }}>{t('SO #')}</TableCell>
            <TableCell sx={{ minWidth: '75px' }}>{t('Channel')}</TableCell>
            <TableCell sx={{ minWidth: '100px' }} align="center">{t('Line items')}</TableCell>
            <MediumTableCellStyled>{t('Total')}</MediumTableCellStyled>
            <MediumTableCellStyled>{t('Status')}</MediumTableCellStyled>
            <MediumTableCellStyled>{t('Shipment status')}</MediumTableCellStyled>
            <WideTableCellStyled>{t('Fulfillment progress')}</WideTableCellStyled>
            <WideTableCellStyled>{t('City')}</WideTableCellStyled>
            <WideTableCellStyled>{t('Warehouse')}</WideTableCellStyled>
            <WideTableCellStyled>{t('Company')}</WideTableCellStyled>
            <WideTableCellStyled>{t('Order date')}</WideTableCellStyled>
            <WideTableCellStyled>{t('Last updated')}</WideTableCellStyled>
            <WideTableCellStyled>{t('Tags')}</WideTableCellStyled>
            <TableCell sx={{ minWidth: '75px' }}>{t('Notes')}</TableCell>
          </TableHead>
          <TableBody>
            {isLoading
              ? <SkeletonList columnCount={15} rowCount={rowCount} height={20} />
              : list.map(item => {
                const id = prop('id', item)

                return (
                  <HoverableRow key={id} onClick={() => handleClick(id)}>
                    <TableCell>{prop('number', item)}</TableCell>
                    <TableCell align="center">
                      <Channel value={path(['channel', 'type'], item)} />
                    </TableCell>
                    <TableCell align="center">{prop('lineItemsCount', item)}</TableCell>
                    <TableCell>
                      <Money value={prop('totalPrice', item)} />
                    </TableCell>
                    <TableCell>
                      <SaleOrderStatus value={prop('status', item)} />
                    </TableCell>
                    <TableCell>
                      <ShipmentStatus value={prop('shipmentStatus', item)} />
                    </TableCell>
                    <TableCell>
                      <FulfillmentProgress status={prop('fulfillmentStatus', item)} />
                    </TableCell>
                    <TableCell>{path(['deliveryAddress', 'city'], item)}</TableCell>
                    <TableCell>{path(['warehouse', 'name'], item)}</TableCell>
                    <TableCell>
                      <TextOverflow selfTooltip={true}>
                        {path(['company', 'name'], item)}
                      </TextOverflow>
                    </TableCell>
                    <TableCell>
                      <TableDateFormat date={prop('orderDate', item)} />
                    </TableCell>
                    <TableCell>
                      <TableDateFormat date={prop('updatedAt', item)} />
                    </TableCell>
                    <TableCell>
                      <Tags items={propOr([], 'tags', item)} />
                    </TableCell>
                    <TableCell>
                      <TableNotes>{prop('customerNotes', item)}</TableNotes>
                    </TableCell>
                  </HoverableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination count={count} prefix="" />
    </Paper>
  )
}

SupplierUpdatePurchases.propTypes = {
  orderList: PropTypes.object.isRequired
}

export default SupplierUpdatePurchases
