import React from 'react'
import { useFilter, useOrdering } from 'storfox-filter'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'
import { useRequest } from 'storfox-api-hooks'
import { sprintf } from 'sprintf-js'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import useMessages from '~/hooks/useMessages'
import ErrorLink from '~/components/ErrorLink'
import useDialog from '~/hooks/useDialog'
import { useTableSelects } from '~/components/TableValues/hooks'
import * as API from '~/constants/api'

import { useMovementList, useMovementsDelete } from '../hooks'
import {
  MovementFilterForm,
  movementFilterOptions,
  MovementList,
  MovementOrderingForm,
  movementOrderingOptions
} from '../components/MovementList'
import MovementWarehouseDialog from '../components/MovementList/MovementWarehouseDialog'

function MovementListContainer () {
  const snackbar = useSnackbar()
  const messages = useMessages()
  const request = useRequest()

  const { handleClose, handleOpen, open } = useDialog()
  const { selects, resetTableSelects } = useTableSelects()

  const movementList = useMovementList()
  const movementsDelete = useMovementsDelete()

  const filter = useFilter(movementFilterOptions)
  const ordering = useOrdering(movementOrderingOptions)

  const handleMovementsDelete = () =>
    movementsDelete.delete({ guids: selects })
      .then(() => snackbar({ message: messages.DELETE_SUCCESS }))
      .then(() => resetTableSelects())
      .then(() => movementList.getList())
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const handleCancel = id => {
    const url = sprintf(API.MOVEMENT_CANCEL, id)
    return request.put(url)
      .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
      .then(() => movementList.getList())
  }

  const breadcrumbs = { title: NAV.MOVEMENT }

  return (
    <DashboardLayout
      title={NAV.MOVEMENT}
      activeNav={NAV.MOVEMENT}
      breadcrumbs={breadcrumbs}
    >
      <MovementFilterForm {...filter} />
      <MovementOrderingForm {...ordering} />

      <MovementList
        movementList={movementList}
        onMovementsDelete={handleMovementsDelete}
        filter={filter}
        ordering={ordering}
        onNewCreate={handleOpen}
        onListRefetch={movementList.getListByParams}
        onCancel={handleCancel}
        bulkActionLoading={movementsDelete.isLoading}
      />
      <MovementWarehouseDialog
        onClose={handleClose}
        open={open}
      />
    </DashboardLayout>
  )
}

export default MovementListContainer
