import React from 'react'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { prop, propOr } from 'ramda'
import PropTypes from 'prop-types'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import HistoryIcon from '@mui/icons-material/History'
import IconButton from '@mui/material/IconButton'
import ReplayIcon from '@mui/icons-material/Replay'
import Pagination from 'storfox-table/src/Table/Pagination'
import TableContainer from '@mui/material/TableContainer'
import RefreshIcon from '@mui/icons-material/Refresh'
import { styled } from '@mui/material'

import { getFormattedDateTime, safeJsonParse } from '~/utils'
import EmptyNotesIcon from '~/components/Icons/EmptyNotesIcon'
import { HISTORY_FORMAT } from '~/constants/dateFormat'
import EmptyLineItems from '~/components/EmptyLineItems/EmptyLineItems'
import Status from '~/components/Status'
import HistoryTableSearch from '~/components/History/HistoryTableSearch'
import { SALLA, SHOPIFY, WOO_COMMERCE } from '~/constants/integrationTypes'
import HtmlTooltip from '~/components/HtmlTooltip/HtmlTooltip'

import SkeletonList from '../Skeleton/SkeletonList'

const RefreshBoxStyled = styled(Box)({
  position: 'absolute',
  left: '10px',
  bottom: '10px',
  textAlign: 'center',
  zIndex: 5
})

const SUCCESS = 'success'
const FAIL = 'fail'

const COLOR = {
  [SUCCESS]: 'success',
  [FAIL]: 'error',
}

const getStatusLabel = (status) => {
  const statusCode = Number(status)
  if (statusCode) {
    if (status >= 200 && status < 300) {
      return SUCCESS
    } else return FAIL
  } return status
}

function IntegrationHistoryTable (props) {
  const { rows, isLoading, count, onClick, integrationType, onRetry, retryLoading, filterOpen, onRefetch } = props
  const { t } = useTranslation()

  const renderEmptyLines = Boolean(!isLoading && !rows.length)
  const renderLineItems = Boolean(!isLoading && rows.length)

  const isStoreFront = integrationType === SALLA || integrationType === WOO_COMMERCE || integrationType === SHOPIFY

  return (
    <Box component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto', position: 'relative' }}>
      <RefreshBoxStyled>
        <HtmlTooltip title="Refresh rows.">
          <IconButton size="small" onClick={onRefetch} disabled={isLoading}>
            <RefreshIcon />
          </IconButton>
        </HtmlTooltip>
      </RefreshBoxStyled>
      <TableContainer>
        <HistoryTableSearch filterOpen={filterOpen} />
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '25%' }}>{t('Date')}</TableCell>
              <TableCell sx={{ width: '30%' }}>{t('Description')}</TableCell>
              <TableCell sx={{ width: '25%' }}>{t('Status')}</TableCell>
              <TableCell sx={{ width: '25%' }}>{t('Response code')}</TableCell>
              {isStoreFront && (
                <TableCell sx={{ width: '25%' }}>{t('Retry')}</TableCell>
              )}
              <TableCell sx={{ width: '10%' }}>{t('Action')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <SkeletonList
                rowCount={3}
                columnCount={5}
                height={25}
              />
            )}

            {renderEmptyLines && (
              <EmptyLineItems
                colSpan={4}
                icon={<EmptyNotesIcon />}
                text="No recorded history"
              />
            )}
            {renderLineItems && rows.map((row, index) => {
              const dateTime = getFormattedDateTime(prop('createdAt', row), HISTORY_FORMAT)
              const description = prop('description', row)
              const isOrder = description.includes('order')
              const statusCode = prop('status', row)
              const status = getStatusLabel(statusCode)

              const request = propOr('', 'request', row)
              const JsonRequest = safeJsonParse(request)
              const requestBody = propOr('', 'body', JsonRequest)
              const requestBodyJson = safeJsonParse(requestBody, {})

              return (
                <TableRow key={index}>
                  <TableCell>{dateTime}</TableCell>
                  <TableCell>
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                  </TableCell>
                  <TableCell>
                    <Status color={COLOR[status]} label={status} />
                  </TableCell>
                  <TableCell>
                    {statusCode}
                  </TableCell>
                  {isStoreFront && (
                    <IconButton
                      disabled={retryLoading || !isOrder}
                      onClick={() => onRetry(requestBodyJson)} size="large"
                    >
                      <ReplayIcon />
                    </IconButton>
                  )}
                  <TableCell>
                    <IconButton onClick={() => onClick(row)} size="large">
                      <HistoryIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination count={count} prefix="" />
    </Box>
  )
}

IntegrationHistoryTable.defaultProps = {
  rows: []
}

IntegrationHistoryTable.propTypes = {
  rows: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  integrationType: PropTypes.string,
  onRetry: PropTypes.func,
  retryLoading: PropTypes.bool,
  filterOpen: PropTypes.func,
  onRefetch: PropTypes.func
}

export default IntegrationHistoryTable
