import React from 'react'
import Badge from '@mui/material/Badge'
import IconButton from '@mui/material/IconButton'
import PropTypes from 'prop-types'

import Barcode from '../Icons/Barcode'

function CountableBarcode ({ count, disabled, onOpen, isError, ...props }) {
  return (
    <IconButton
      disabled={disabled}
      onClick={onOpen}
      sx={{ color: isError ? 'red' : 'primary' }}
      size="large"
      {...props}
    >
      <Badge badgeContent={count} color="default">
        <Barcode />
      </Badge>
    </IconButton>
  )
}

CountableBarcode.defaultProps = {
  disabled: false
}

CountableBarcode.propTypes = {
  count: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  onOpen: PropTypes.func.isRequired,
  isError: PropTypes.bool
}

export default CountableBarcode
