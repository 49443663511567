import React from 'react'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'
import { useOrdering } from 'storfox-filter'

import * as NAV from '~/constants/nav-titles'
import { DashboardLayout } from '~/components/Layouts'
import useMessages from '~/hooks/useMessages'
import ErrorLink from '~/components/ErrorLink'
import { useTableSelects } from '~/components/TableValues/hooks'

import { TaxOrderingForm, taxOrderingOptions, TaxTable } from '../../components/Tax/TaxList'
import { useTaxesDelete, useTaxList } from '../../hooks/Tax'

function TaxListContainer () {
  const snackbar = useSnackbar()
  const messages = useMessages()
  const taxList = useTaxList()
  const taxesDelete = useTaxesDelete()
  const { selects, resetTableSelects } = useTableSelects()

  const ordering = useOrdering(taxOrderingOptions)

  const handleTaxesDelete = () =>
    taxesDelete.delete({ guids: selects })
      .then(() => snackbar({ message: messages.DELETE_SUCCESS }))
      .then(() => resetTableSelects())
      .then(() => taxList.getList())
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const breadcrumbs = { title: 'Tax' }

  return (
    <DashboardLayout
      activeNav={NAV.CONFIGURATION}
      title="Tax"
      breadcrumbs={breadcrumbs}
    >
      <TaxOrderingForm {...ordering} />

      <TaxTable
        list={taxList}
        ordering={ordering}
        onListRefetch={taxList.getListByParams}
        onTaxesDelete={handleTaxesDelete}
        bulkActionLoading={taxesDelete.isLoading}
      />
    </DashboardLayout>
  )
}

export default TaxListContainer
