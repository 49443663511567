import React from 'react'
import PropTypes from 'prop-types'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { MoreButton } from '~/components/Buttons'

function More ({ conditionPrintPath, conditionPrintPathDisabled, onPrintUnitsBarcode }) {
  const { t } = useTranslation()

  return (
    <>
      <MoreButton>
        {onClose => (
          <List>
            <ListItem
              disabled={conditionPrintPathDisabled}
              button={true}
              component={Link}
              to={conditionPrintPath}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ListItemText primary={t('Print conditions')} />
            </ListItem>
            <ListItem button={true} onClick={() => {
              onPrintUnitsBarcode()
              onClose()
            }}>
              <ListItemText primary={t('Print units barcode')} />
            </ListItem>
          </List>
        )}
      </MoreButton>
    </>
  )
}

More.propTypes = {
  conditionPrintPath: PropTypes.string.isRequired,
  conditionPrintPathDisabled: PropTypes.bool.isRequired,
  onPrintUnitsBarcode: PropTypes.func.isRequired
}

export default More
