import React, { useMemo, useState } from 'react'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'
import { generatePath, useNavigate } from 'react-router-dom'
import { pathOr, prop } from 'ramda'
import { useAllSearchParams } from 'storfox-route-hooks'
import { useDeepCompareEffect, useRequest } from 'storfox-api-hooks'
import { sprintf } from 'sprintf-js'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import useMessages from '~/hooks/useMessages'
import ErrorLink from '~/components/ErrorLink'
import { SUPPLIER_RETURN_TABS } from '~/constants/tabs'
import * as API from '~/constants/api'

import ReturnCreateForm from '../components/ReturnCreate/ReturnCreateForm'
import { useReturnCreate, usePurchaseSupplierCreate, useReturnVariants } from '../hooks'
import { ReturnCreateSerializer } from '../serializers'

function ReturnCreateContainer () {
  const messages = useMessages()
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const request = useRequest()
  const returnCreate = useReturnCreate()
  const returnVariants = useReturnVariants()
  const purchaseSupplierCreate = usePurchaseSupplierCreate()
  const { purchaseGuid } = useAllSearchParams()

  const [poValues, setPoValues] = useState({})
  const [loading, setLoading] = useState(false)

  const handleSubmit = formValues =>
    returnCreate.create(ReturnCreateSerializer(formValues))
      .then(({ result }) => {
        const guid = prop('guid', result)
        const path = generatePath(ROUTES.SUPPLIER_RETURN_DETAIL_PATH, { guid, tab: SUPPLIER_RETURN_TABS.GENERAL })
        snackbar({ message: messages.CREATE_SUCCESS })
        return navigate(path)
      })
      .catch(error => {
        snackbar({ type: ALTER_ERROR, message: <ErrorLink error={error} /> })
        throw error
      })

  const handleSupplierCreate = values =>
    purchaseSupplierCreate.create(values)
      .then(res => {
        snackbar({ message: messages.CREATE_SUCCESS })
        return res
      })

  useDeepCompareEffect(() => {
    if (purchaseGuid) {
      setLoading(true)
      const url = sprintf(API.PURCHASE_ORDERS_DETAIL, purchaseGuid)
      request.get(url).then(purchaseOrder => {
        const result = pathOr({}, ['data', 'result'], purchaseOrder)
        const warehouse = prop('warehouse', result)
        const supplier = prop('supplier', result)
        const billingAddress = prop('billingAddress', result)
        const deliveryAddress = prop('deliveryAddress', result)
        const warehouseGuid = prop('guid', warehouse)
        returnVariants.getList({ warehouseGuid, purchaseGuid, page: 1 }).then((returnUnits) => {
          const lineItems = returnUnits.map(variant => {
            return {
              price: prop('price', variant),
              guid: prop('guid', variant),
              id: prop('id', variant),
              unit: variant,
              totalQuantity: prop('quantity', variant),
              quantity: 1
            }
          })
          setPoValues({ lineItems, warehouse, supplier, deliveryAddress, billingAddress })
          setLoading(false)
        })
      })
    }
  }, [purchaseGuid])

  const initialValues = useMemo(() => {
    if (purchaseGuid) {
      return { ...poValues, notes: '' }
    } else return { notes: '' }
  }, [poValues, purchaseGuid])

  const title = 'New return'

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      isLoading={returnCreate.isLoading || loading}
      activeNav={NAV.SUPPLIER_RETURNS}
      breadcrumbs={breadcrumbs}
    >
      <ReturnCreateForm
        pageTitle={title}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        isLoading={returnCreate.isLoading || loading}
        onSupplierCreate={handleSupplierCreate}
        returnVariants={returnVariants}
      />
    </DashboardLayout>
  )
}

export default ReturnCreateContainer
