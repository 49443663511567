import { path, pathOr } from 'ramda'
import React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useSnackbar } from 'storfox-snackbar'
import * as yup from 'yup'

import { DashboardLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'
import useMessages from '~/hooks/useMessages'
import { PRODUCT_DETAIL_TABS } from '~/constants/tabs'

import { ProductUpdateForm } from '../components/ProductUpdate'
import { useProductBrandCreate, useProductCategoryCreate, useProductDetail, useProductUpdate } from '../hooks'
import { ProductUpdateInitialSerializer, ProductUpdateSerializer } from '../serializers'

const validationSchema = yup.object({
  variants: yup.array()
    .of(
      yup.object().shape({
        barcode: yup.string().required('Barcode is required'),
      })
    )
})

function ProductUpdateContainer () {
  const { id } = useParams()
  const messages = useMessages()
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const productDetail = useProductDetail(id)

  const productUpdate = useProductUpdate(id)
  const productCategoryCreate = useProductCategoryCreate()
  const productBrandCreate = useProductBrandCreate()

  const title = pathOr('Products', ['detail', 'name'], productDetail)

  const handleCategoryCreate = name =>
    productCategoryCreate.create({ name })
      .then(res => {
        snackbar({ message: messages.CREATE_SUCCESS })
        return res
      })

  const handleBrandCreate = name =>
    productBrandCreate.create({ name })
      .then(res => {
        snackbar({ message: messages.CREATE_SUCCESS })
        return res
      })

  const handleSubmit = formValues =>
    productUpdate.update(ProductUpdateSerializer(formValues))
      .then(() => productDetail.getDetail())
      .then(data => {
        const params = {
          id: path(['result', 'id'], data),
          tab: PRODUCT_DETAIL_TABS.GENERAL
        }
        const redirect = generatePath(ROUTES.PRODUCT_DETAIL_PATH, params)

        navigate(redirect, { replace: true })
      })
      .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))

  const pageLoading = productDetail.isLoading || productUpdate.isLoading

  const breadcrumbs = {
    title: 'Edit',
    params: [
      { id, tab: PRODUCT_DETAIL_TABS.GENERAL, title }
    ]
  }

  return (
    <DashboardLayout
      title={title}
      isLoading={pageLoading}
      activeNav={NAV.PRODUCTS}
      breadcrumbs={breadcrumbs}
    >
      <ProductUpdateForm
        title={title}
        pageTitleLoading={productDetail.isLoading}
        initialValues={ProductUpdateInitialSerializer(productDetail.detail)}
        onSubmit={handleSubmit}
        isLoading={productUpdate.isLoading}
        categoryLoading={productCategoryCreate.isLoading}
        brandLoading={productBrandCreate.isLoading}
        onCategoryCreate={handleCategoryCreate}
        onBrandCreate={handleBrandCreate}
        validationSchema={validationSchema}
      />
    </DashboardLayout>
  )
}

export default ProductUpdateContainer
