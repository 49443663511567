import React from 'react'
import { useParams } from 'react-router-dom'
import { prop } from 'ramda'

import { DashboardLayout } from '~/components/Layouts'
import Title from '~/components/Title'
import { STATUS_NAME } from '~/components/Statuses/SaleOrderStatus'
import * as NAV from '~/constants/nav-titles'

import InvoiceDetail from '../components/InvoiceDetail'
import { useInvoiceDetail } from '../hooks'
import { SaleOrderInitSerializer } from '../serializers'

function OrderUpdateForm () {
  const { guid, tab } = useParams()

  const invoiceDetail = useInvoiceDetail(guid)

  const number = prop('number', invoiceDetail.detail)

  const title = invoiceDetail.isLoading ? 'Invoice' : number
  const pageTitle = (
    <Title title="Invoice" number={number} status={STATUS_NAME[status]} />
  )

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      activeNav={NAV.INVOICES}
      isLoading={invoiceDetail.isLoading}
      breadcrumbs={breadcrumbs}
    >
      <InvoiceDetail
        tab={tab}
        pageTitle={pageTitle}
        pageTitleLoading={invoiceDetail.isLoading}
        isLoading={invoiceDetail.isLoading}
        values={SaleOrderInitSerializer(invoiceDetail.detail)}
      />
    </DashboardLayout>
  )
}

export default OrderUpdateForm
