import React from 'react'
import PropTypes from 'prop-types'

function Hide ({ children, hidden }) {
  return !hidden ? <>{children}</> : null
}

export default Hide

Hide.defaultProps = {
  hidden: true
}

Hide.propTypes = {
  children: PropTypes.object.isRequired,
  hidden: PropTypes.bool.isRequired
}
