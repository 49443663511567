import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card/Card'
import { Divider } from '@mui/material'
import CardContent from '@mui/material/CardContent'
import { useTranslation } from 'react-i18next'

import { CardHeader } from '~/components/Cards'
import TextField from '~/components/Fields/TextField'

import ShipRocketUpdate from './ShipRocketUpdate'

function ShipRocketIntegrationUpdateFormGeneral () {
  const { t } = useTranslation()

  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12} lg={8}>
        <Box mb={3}>
          <Card>
            <CardHeader title={t('Integration')} />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <ShipRocketUpdate />
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>
      <Grid item={true} xs={12} lg={4}>
        <Box mb={3}>
          <Card>
            <CardHeader title={t('Keys')} />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} lg={12}>
                  <TextField
                    data-cy="apiKey"
                    name="credential.clientId"
                    label="API Key"
                  />
                </Grid>
                <Grid item={true} lg={12}>
                  <TextField
                    data-cy="secretKey"
                    name="credential.clientSecret"
                    label="Secret Key"
                    inputProps={{
                      type: 'password',
                      autocomplete: 'new-password'
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </Grid>
  )
}

export default ShipRocketIntegrationUpdateFormGeneral
