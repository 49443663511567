import React from 'react'
import PropTypes from 'prop-types'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'

import Button from '../Buttons/Button'
import Avatar from '../Avatar/Avatar'

function Image ({ title }) {
  return (
    <CardContent
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center'
      }}
    >
      <Avatar
        alt="blank"
        sx={{ height: 145, width: 145 }}
      />
      <CardActions>
        <Button
          variant="outlined"
        >
          {title}
        </Button>
      </CardActions>
    </CardContent>
  )
}

Image.defaultProps = {
  title: 'Add logo'
}

Image.propTypes = {
  title: PropTypes.string.isRequired
}

export default Image
