import { defaultTo, filter, map, path, pick, pipe, prop, propEq, propOr, uniq } from 'ramda'
import { DEFAULT_SEARCH_PARAMS } from 'storfox-route-hooks'

import { escapeAtob, matchContainer } from '~/utils'
import { emptyArray } from '~/constants/empty'

const getPickedLineItems = filter(propEq('status', 'picked'))

const getDestination = (destination) => {
  if (matchContainer(destination)) {
    return {
      toContainer: destination
    }
  }

  return { locationId: destination }
}

export function PutawayCreateSerializer (formValues) {
  const getUnits = pipe(
    defaultTo([]),
    filter(unit => !prop('container', unit)),
    map(unit => {
      const quantity = prop('quantity', unit)
      const guid = prop('guid', unit)
      return { guid, quantity }
    })
  )

  const getContainers = pipe(
    defaultTo([]),
    filter(prop('container')),
    map(item => {
      const guid = path(['container', 'guid'], item)
      return { guid }
    }),
    uniq
  )

  const locationId = prop('location', formValues)
  const scannedUnits = prop('units', formValues)
  const units = getUnits(scannedUnits)
  const containers = getContainers(scannedUnits)
  return {
    locationId,
    units,
    containers
  }
}

export function PutawayInitSerializer (values) {
  const fromLocation = path(['fromLocation', 'locationId'], values)
  const lineItems = propOr(emptyArray, 'lineItems', values)
  const pickedLineItems = getPickedLineItems(lineItems)

  const locationContent = map(item => ({
    ...item,
    ...prop('unit', item),
    guid: prop('guid', item)
  }), pickedLineItems)

  return { locationContent, fromLocation }
}

export function PutawayPutSerializer (formValues) {
  const getUnits = pipe(
    defaultTo([]),
    filter(prop('isOutsideContainer')),
    map(item => {
      const quantity = prop('quantity', item)
      const guid = prop('guid', item)
      return { guid, quantity }
    })
  )

  const getContainers = pipe(
    defaultTo([]),
    filter(item => prop('container', item) && !prop('isOutsideContainer', item)),
    map(item => {
      const guid = path(['container', 'guid'], item)
      return { guid }
    }),
    uniq
  )

  const scannedUnits = prop('units', formValues)
  const units = getUnits(scannedUnits)
  const containers = getContainers(scannedUnits)
  const destination = getDestination(prop('destination', formValues))

  return { ...destination, units, containers }
}

export const PutawayFilterSerializer = params => {
  const defaultParams = pick(DEFAULT_SEARCH_PARAMS, params)
  const status = prop('status', params)
  const totalPriceStart = prop('totalPriceStart', params)
  const totalPriceEnd = prop('totalPriceEnd', params)
  const warehouseIds = escapeAtob(prop('warehouses', params))
  const assignedToGuids = escapeAtob(prop('assigned', params))
  const variantGuidsParams = escapeAtob(prop('variants', params)) || []
  const variantGuids = variantGuidsParams.map(variant => ({ id: variant.guid }))
  const updatedAtStart = prop('updatedAtStart', params)
  const updatedAtEnd = prop('updatedAtEnd', params)
  const createdAtStart = prop('createdAtStart', params)
  const createdAtEnd = prop('createdAtEnd', params)

  return {
    ...defaultParams,
    variantGuids,
    status,
    totalPriceStart,
    totalPriceEnd,
    updatedAtStart,
    updatedAtEnd,
    warehouseIds,
    assignedToGuids,
    createdAtStart,
    createdAtEnd
  }
}

export const PutawayAssignToSerializer = (value) => {
  const assignedToGuid = prop('guid', value)
  return { assignedToGuid }
}
