import React, { useMemo } from 'react'
import Grid from '@mui/material/Grid'
import { CardContent, Divider, styled } from '@mui/material'
import Card from '@mui/material/Card'
import { filter, map, path, prop, propEq } from 'ramda'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import PropTypes from 'prop-types'
import DeleteIcon from '@mui/icons-material/Delete'

import { CardHeader } from '~/components/Cards'
import { TableNoCache } from '~/components/Table'
import Avatar from '~/components/Avatar'
import VerticalAlignment from '~/components/VerticalAlignment'
import TextOverflow from '~/components/TextOverflow'
import Subtext from '~/components/Subtext'
import Tooltip from '~/components/HtmlTooltip'

const CardStyled = styled(Card)({
  borderRadius: '10px',
  height: '100%',
  minHeight: '500px'
})

const CardContentStyled = styled(CardContent)({
  padding: 0,
  height: 'calc(100% - 28px)'
})

export const columns = [
  {
    width: 100,
    headerName: 'Image',
    field: 'image',
    renderCell: ({ value, row }) => (
      <Avatar
        alt={row.product.name}
        src={value}
      />
    )
  },
  {
    width: 200,
    headerName: 'Product',
    field: 'product',
    renderCell: ({ value }) => (
      <VerticalAlignment
        primary={<TextOverflow selfTooltip={true} lines={1}>{value.name}</TextOverflow>}
        secondary={<Subtext textOverflow={true} lines={1}>{value.sku}</Subtext>}
      />
    )
  },
  {
    width: 100,
    headerName: 'Container',
    field: 'container',
    renderCell: ({ value }) => (
      <Tooltip title={value.title}>
        {value.number}
      </Tooltip>
    )
  },
  {
    width: 75,
    headerName: 'Qty',
    field: 'quantity',
    headerAlign: 'center',
    align: 'center'
  },
  {
    width: 150,
    headerName: 'Unit number',
    field: 'unitNumber'
  },
  {
    width: 200,
    headerName: 'Serial number',
    field: 'serialNumber'
  }
]

const getRowsFromResults = map(item => {
  const variant = prop('variant', item)
  const guid = prop('guid', item)
  const isOutsideContainer = prop('isOutsideContainer', item)
  const containerNumber = path(['container', 'number'], item)
  const container = {
    number: containerNumber,
    title: isOutsideContainer ? 'Not in container' : 'Inside container'
  }

  const quantity = prop('quantity', item)
  const name = prop('name', variant)
  const sku = prop('sku', variant)
  const unitNumber = prop('unitNumber', item)
  const serialNumber = prop('serialNumber', item)
  const image = prop('imagePath', variant)
  const product = { name, sku }

  return {
    guid,
    containerNumber,
    container,
    quantity,
    serialNumber,
    unitNumber,
    product,
    image
  }
})

function PutawayPutTables (props) {
  const {
    sourceUnits,
    destUnits,
    activeContainerNumber,
    onUnitDelete,
    fromLocation,
    isLoading
  } = props

  const sourceRows = activeContainerNumber
    ? filter(propEq('containerNumber', activeContainerNumber), getRowsFromResults(sourceUnits))
    : getRowsFromResults(sourceUnits)

  const destRows = getRowsFromResults(destUnits, onUnitDelete)

  const actionColumns = useMemo(() => [
    ...columns,
    {
      width: 50,
      resizable: false,
      field: 'actions',
      type: 'actions',
      getActions: ({ row }) => [
        <GridActionsCellItem
          label="Delete"
          onClick={() => onUnitDelete(row)}
          icon={<DeleteIcon />}
        />
      ]
    }
  ], [onUnitDelete])

  return (
    <Grid container={true} spacing={2}>
      <Grid item={true} xs={12} lg={6}>
        <CardStyled>
          <CardHeader
            title={fromLocation ? `Your picked items in ${fromLocation} location` : 'No items'}
            sx={{ p: 2 }}
          />
          <CardContentStyled>
            <TableNoCache
              primaryKey="guid"
              isLoading={isLoading}
              columns={columns}
              height="100%"
              rows={sourceRows}
              pagination={null}
            />
          </CardContentStyled>
        </CardStyled>
      </Grid>
      <Grid item={true} xs={12} lg={6}>
        <CardStyled>
          <CardHeader title="Scanned Items" sx={{ p: 2 }} />
          <Divider />
          <CardContentStyled>
            <TableNoCache
              primaryKey="guid"
              isLoading={isLoading}
              columns={actionColumns}
              height="100%"
              rows={destRows}
              pagination={null}
            />
          </CardContentStyled>
        </CardStyled>
      </Grid>
    </Grid>
  )
}

PutawayPutTables.propTypes = {
  onUnitDelete: PropTypes.func.isRequired,
  fromLocation: PropTypes.string,
  sourceUnits: PropTypes.array.isRequired,
  destUnits: PropTypes.array.isRequired,
  activeContainerNumber: PropTypes.string,
  isLoading: PropTypes.bool.isRequired
}

export default PutawayPutTables
