import customer from './modules/customer'
import saleOrder from './modules/sale-order'
import invoices from './modules/invoice'
import returns from './modules/return'

const FulfillmentRoutes = () => [
  ...saleOrder(),
  ...invoices(),
  ...customer(),
  ...returns()
]

export default FulfillmentRoutes
