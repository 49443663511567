import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActionArea from '@mui/material/CardActionArea'
import Typography from '@mui/material/Typography'
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'

function TemplateCard ({ title, path, description }) {
  return (
    <Card sx={{
      height: '100%',
      '& > *': {
        height: '100%'
      }
    }}>
      <CardActionArea component={Link} to={path}>
        <CardContent sx={{ p: 2 }}>
          <Box display="flex" alignItems="flexEnd">
            <Box mb={1} display="flex" flexDirection="column" justifyContent="flex-end">
              <Typography component="h3" variant="h5">
                {title}
              </Typography>
            </Box>
          </Box>
          <Typography variant="body1">{description}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

TemplateCard.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description:PropTypes.string.isRequired
}

export default TemplateCard
