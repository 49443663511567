import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Skeleton from '@mui/material/Skeleton'
import { compose, filter, find, fromPairs, map, not, pipe, pluck, propEq, range } from 'ramda'

import MultiGrid from './MultiGrid'

const getRow = pipe(
  pluck('field'),
  map(field => ([field, ''])),
  fromPairs
)

const defaultRenderCell = ({ value }) => value

const getSkeletonColumns = columns => {
  const actionColumn = find(propEq('type', 'actions'), columns)

  const columnsWithoutAction = pipe(
    filter(compose(not, propEq('type', 'actions'))),
    map(column => ({
      ...column,
      renderCell: () => (
        <Skeleton
          animation="wave"
          height={20}
          width="80%"
          style={{ marginBottom: 5, marginTop: 5 }}
        />
      )
    }))
  )(columns)

  return actionColumn ? [...columnsWithoutAction, actionColumn] : columnsWithoutAction
}

const getColumns = columns => {
  const actionColumn = find(propEq('type', 'actions'), columns)

  const columnsWithoutAction = pipe(
    filter(compose(not, propEq('type', 'actions'))),
    map(column => ({
      ...column,
      renderCell: column.renderCell || defaultRenderCell
    }))
  )(columns)

  return actionColumn ? [...columnsWithoutAction, actionColumn] : columnsWithoutAction
}

function DataGrid (props) {
  const {
    rows,
    columns,
    onRowClick,
    count,
    rowHeight,
    ToolbarComponent,
    pinnedColumns,
    checkboxSelection,
    isLoading,
    headerColor,
    pagination,
    ordering,
    generateLink,
    height,
    primaryKey,
    space,
    autoPageSize,
    isNewPagination,
    hasNextPage,
    hasPrevPage
  } = props

  const newColumns = useMemo(() => isLoading
    ? getSkeletonColumns(columns)
    : getColumns(columns)
  , [columns, isLoading])

  const newRows = useMemo(() => isLoading
    ? map(number => ({ ...getRow(newColumns), id: number, guid: number }), range(0, 50))
    : rows
  , [isLoading, newColumns, rows])

  const newCount = useMemo(() => isLoading && !count ? 50 : count, [count, isLoading])

  return (
    <MultiGrid
      rows={newRows}
      onRowClick={onRowClick}
      columns={newColumns}
      count={newCount}
      ToolbarComponent={ToolbarComponent}
      pinnedColumns={pinnedColumns}
      headerColor={headerColor}
      space={space}
      generateLink={generateLink}
      rowHeight={rowHeight}
      height={height}
      ordering={ordering}
      pagination={pagination}
      checkboxSelection={checkboxSelection}
      primaryKey={primaryKey}
      autoPageSize={autoPageSize}
      isNewPagination={isNewPagination}
      hasNextPage={hasNextPage}
      hasPrevPage={hasPrevPage}
    />
  )
}

DataGrid.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  height: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  onRowClick: PropTypes.func,
  ToolbarComponent: PropTypes.any,
  generateLink: PropTypes.func,
  headerColor: PropTypes.bool,
  rowHeight: PropTypes.number,
  pinnedColumns: PropTypes.array,
  space: PropTypes.bool,
  pagination: PropTypes.bool,
  checkboxSelection: PropTypes.bool,
  primaryKey: PropTypes.string,
  ordering: PropTypes.object,
  count: PropTypes.number,
  autoPageSize: PropTypes.bool,
  isNewPagination: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  hasPrevPage: PropTypes.bool
}

export default React.memo(DataGrid)
