import React from 'react'
import PropTypes from 'prop-types'
import { generatePath, useNavigate } from 'react-router-dom'

import * as ROUTES from '~/constants/routes'

import Button from '../Buttons/Button'

function GoButton ({ to, params, children, className, ...props }) {
  const navigate = useNavigate()

  const goReceive = () => {
    const redirect = generatePath(ROUTES.RECEIVE_PURCHASE_ORDER_DETAIL_PATH, params)
    navigate(redirect)
  }

  return (
    <Button
      className={className}
      type="button"
      variant="outlined"

      onClick={goReceive}
      {...props}
    >
      {children}
    </Button>
  )
}

GoButton.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string.isRequired,
  params: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
}

export default GoButton
