import React from 'react'
import PropTypes from 'prop-types'

import TableSplitButton from '~/components/TableSplitButton/TableSplitButton'
import useDeleteConfirm from '~/components/TableSplitButton/useDeleteConfirm'

function BulkActionButton (props) {
  const {
    onOrdersDelete,
    onGenerateExcelReport,
    onGeneratePdfReport,
    loading
  } = props

  const { handleClick } = useDeleteConfirm()

  const options = [
    {
      label: 'Excel Report',
      'data-cy': 'excel-report-menu',
      onMenuClick: onGenerateExcelReport
    },
    {
      label: 'Pdf Report',
      'data-cy': 'pdf-report-menu',
      onMenuClick: onGeneratePdfReport
    },
    {
      label: 'Delete',
      'data-cy': 'deleteMenu',
      onMenuClick: () => handleClick(onOrdersDelete)
    }
  ]

  return (
    <TableSplitButton
      options={options}
      name="purchaseOrder"
      loading={loading}
    />
  )
}

BulkActionButton.propTypes = {
  onOrdersDelete: PropTypes.func.isRequired,
  onGeneratePdfReport: PropTypes.func.isRequired,
  onGenerateExcelReport: PropTypes.func.isRequired,
  loading: PropTypes.bool
}

export default BulkActionButton
