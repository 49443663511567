import React from 'react'

import SelectField from '~/components/Fields/SelectField'

const OPTIONS = [
  { value: null, name: 'All' },
  { value: 'new', name: 'New' },
  { value: 'progress', name: 'In Progress' },
  { value: 'completed', name: 'Completed' }
]

function ReceivingStatusField (props) {
  return (
    <SelectField
      name="status"
      label="Status"
      options={OPTIONS}
      {...props}
    />
  )
}

export default ReceivingStatusField
