import React, { useMemo, useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { path, prop } from 'ramda'
import { useSnackbar } from 'storfox-snackbar'
import { useAllSearchParams } from 'storfox-route-hooks'
import { useDeepCompareEffect, useRequest } from 'storfox-api-hooks'

import { DashboardLayout } from '~/components/Layouts'
import { useProfile } from '~/components/Profile'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import { PURCHASE_ORDER_DETAIL_TABS } from '~/constants/tabs'
import useMessages from '~/hooks/useMessages'
import { escapeAtob } from '~/utils'
import * as API from '~/constants/api'

import { PurchaseOrderCreateForm } from '../components/PurchaseOrderCreate'
import { usePurchaseOrderCreate, usePurchaseOrderSupplierCreate, usePurchaseOrderVariant } from '../hooks'
import {
  PurchaseOrderCreateInitSerializer,
  PurchaseOrderCreateSerializer,
  PurchaseOrderSupplierCreateSerializer
} from '../serializers'

function PurchaseOrderCreateContainer () {
  const navigate = useNavigate()
  const messages = useMessages()
  const snackbar = useSnackbar()
  const request = useRequest()
  const searchParams = useAllSearchParams()
  const purchaseOrderCreate = usePurchaseOrderCreate()
  const purchaseOrderSupplierCreate = usePurchaseOrderSupplierCreate()
  const purchaseOrderVariant = usePurchaseOrderVariant()

  const [loading, setLoading] = useState(false)
  const [reorderValues, setReorderValues] = useState({})

  const { profile } = useProfile()

  const warehouses = prop('warehouses', profile)
  const defaultTax = path(['salesOrderTax', 'value'], profile)

  const handleSubmit = formValue =>
    purchaseOrderCreate.create(PurchaseOrderCreateSerializer(formValue))
      .then(data => {
        snackbar({ message: messages.CREATE_SUCCESS })
        return data
      })
      .then(data => {
        const guid = path(['result', 'guid'], data)
        const params = { guid, tab: PURCHASE_ORDER_DETAIL_TABS.GENERAL }
        const redirect = generatePath(ROUTES.PURCHASE_ORDER_DETAIL_PATH, params)

        navigate(redirect)
      })

  const handleSupplierCreate = values =>
    purchaseOrderSupplierCreate
      .create(PurchaseOrderSupplierCreateSerializer(values))
      .then(res => {
        snackbar({ message: messages.CREATE_SUCCESS })
        return res
      })

  const breadcrumbs = { title: NAV.PURCHASE_ORDER }

  useDeepCompareEffect(() => {
    const body = prop('body', searchParams)
    if (body) {
      setLoading(true)
      const escapeBody = escapeAtob(body)
      const variants = prop('variants', escapeBody)
      const warehouse = prop('warehouse', escapeBody)
      const variantIds = variants.map(variant => variant.id)
      request.get(API.PURCHASE_ORDERS_VARIANTS, { ids: variantIds.join(',') }).then(response => {
        const results = path(['data', 'results'], response)
        const lineItems = results.map((item) => {
          const bodyVariant = variants.find(variant => variant.id === item.id)
          const needed = prop('needed', bodyVariant)
          const supplyPrice = prop('retailPrice', item)
          const supplierSku = prop('supplierSku', item)
          const discount = prop('discount', item)
          return {
            productVariant: item,
            discount,
            price: supplyPrice,
            quantity: needed,
            supplierSku,
            tax: defaultTax
          }
        })
        setReorderValues({ warehouse, lineItems })
        setLoading(false)
      })
    }
  }, [request, searchParams, defaultTax])

  const initialValues = useMemo(() => {
    const reorderWarehouse = prop('warehouse', reorderValues)
    const initial = PurchaseOrderCreateInitSerializer(warehouses, defaultTax)
    if (reorderWarehouse) {
      return {
        ...initial,
        warehouse: reorderWarehouse,
        lineItems: prop('lineItems', reorderValues)
      }
    } else return initial
  }, [defaultTax, reorderValues, warehouses])

  return (
    <DashboardLayout
      activeNav={NAV.PURCHASE_ORDER}
      title="New Purchase Order"
      isLoading={purchaseOrderCreate.isLoading || loading}
      breadcrumbs={breadcrumbs}
    >
      <PurchaseOrderCreateForm
        initialValues={initialValues}
        pageTitle="New Purchase Order"
        parentTitle={NAV.PURCHASES}
        isLoading={purchaseOrderCreate.isLoading || loading}
        onSubmit={handleSubmit}
        onSupplierCreate={handleSupplierCreate}
        purchaseOrderVariant={purchaseOrderVariant}
      />
    </DashboardLayout>
  )
}

export default PurchaseOrderCreateContainer
