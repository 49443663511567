import { useLimitedCountList, useCreate } from 'storfox-api-hooks'
import useAllSearchParams from 'storfox-route-hooks/src/useAllSearchParams'

import { queryToParams } from '~/utils'
import * as API from '~/constants/api'

import { SalesOverviewFilterSerializer } from '../../serializers/SalesOverview'

export const useSaleOverviewList = () => {
  const searchParams = useAllSearchParams()
  const params = queryToParams(SalesOverviewFilterSerializer, searchParams, 'guid')
  const { getList, ...state } = useLimitedCountList(API.SALES_OVERVIEW, params)

  return { ...state, getList: rest => getList({ ...rest, ...params }) }
}

export const useSalesExport = () => {
  const searchParams = useAllSearchParams()
  const params = queryToParams(SalesOverviewFilterSerializer, searchParams, 'guid')
  const { create, ...rest } = useCreate(API.SALES_OVERVIEW_EXPORT)

  return { ...rest, export: rest => create({ ...rest, ...params }) }
}
