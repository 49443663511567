import React from 'react'
import PropTypes from 'prop-types'

import Tabs from '~/components/Tabs'

export const RECEIVING_STATISTIC_TAB = 'receiving-view'
export const PUTAWAY_STATISTIC_TAB = 'putaway-view'

const tabs = [
  { value: RECEIVING_STATISTIC_TAB, label: 'Receiving' },
  { value: PUTAWAY_STATISTIC_TAB, label: 'Put Away' }
]

function ReceivingPutawayTabs ({ value, onChange }) {
  return (
    <Tabs
      tabs={tabs}
      value={value}
      onChange={onChange}
    />
  )
}

ReceivingPutawayTabs.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func
}

export default ReceivingPutawayTabs
