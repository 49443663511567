import React from 'react'
import PropTypes from 'prop-types'

function Copy ({ textToCopy, children }) {
  const handleClick = event => {
    event.preventDefault()
    return navigator.clipboard.writeText(textToCopy)
  }

  return (
    <>
      {children(handleClick)}
    </>
  )
}

Copy.propTypes = {
  children: PropTypes.func.isRequired,
  textToCopy: PropTypes.string.isRequired
}

export default Copy
