import React from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'

import EmptyLineItemsIcon from '~/components/Icons/EmptyLineItemsIcon'
import EmptyContent from '~/components/EmptyContent/EmptyContent'

function EmptyLineItems ({ colSpan, text, icon }) {
  return (
    <TableRow>
      <TableCell colSpan={colSpan} style={{ borderBottom: 'none' }}>
        <Box pt={4} pb={4}>
          <EmptyContent
            icon={icon}
            text={text}
          />
        </Box>
      </TableCell>
    </TableRow>
  )
}

EmptyLineItems.defaultProps = {
  text: 'No scanned items.',
  icon: <EmptyLineItemsIcon />,
  colSpan: 8
}

EmptyLineItems.propTypes = {
  icon: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
  colSpan: PropTypes.number.isRequired
}

export default EmptyLineItems
