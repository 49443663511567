import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import isEqual from 'react-fast-compare'
import { defaultTo, filter, includes, map, pluck, prop } from 'ramda'
import Grid from '@mui/material/Grid'
import { useFieldArray } from 'react-final-form-arrays'
import { useField, useForm } from 'react-final-form'
import { Button, styled } from '@mui/material'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import DialogTitle from '~/components/DialogTitle'
import { ActionButtons, ScannerDialogContent, ScannerDialogTitle } from '~/components/ScannerDialog'
import MobileFullWidthDialog from '~/components/MobileFullWidthDialog'
import { withForm } from '~/components/Form'
import { useUnitsPopover } from '~/components/DuplicateUnitsTable/hooks'
import UnitPopoverField from '~/components/Fields/UnitPopoverField'
import DuplicateUnitsTable from '~/components/DuplicateUnitsTable'

import BarcodeList from './BarcodeList'

const FormStyled = styled('form')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    height: 'calc(100vh - 130px)'
  },
  [theme.breakpoints.down('sm')]: {
    height: 'calc(100vh - 170px)'
  }
}))

const getItemsWithTotalQuantity = map(item => ({ ...item, totalQuantity: prop('quantity', item) }))

function DialogForm (props) {
  const {
    open,
    form,
    ownerId,
    isLoading,
    dialogTitle,
    trackSerial,
    condition,
    onClose,
    onUnitNumberCheck,
    removeOperation,
    trackBatch
  } = props

  const formInstance = useForm()
  const snackbar = useSnackbar()
  const barcodeRef = useRef({})
  const popover = useUnitsPopover()

  const { initialValues, handleSubmit } = form

  const id = prop('id', initialValues)

  const image = prop('image', dialogTitle)
  const title = prop('title', dialogTitle)
  const sku = prop('sku', dialogTitle)
  const barcode = prop('barcode', dialogTitle)

  const itemsArray = useFieldArray('items')
  const itemsValue = defaultTo([], itemsArray.fields.value)

  const itemsField = useField('items')

  const getItems = filter(({ id }) => !includes(id, pluck('id', itemsValue)))

  const cleanErrors = () => {
    formInstance.mutators.setFieldData('unitNumber', { error: null })
  }

  const setError = error => {
    formInstance.mutators.setFieldData('unitNumber', { error })
  }

  const addUnitNumber = item => {
    const id = prop('id', item)
    const condition = prop('condition', item)
    const unitNumber = prop('unitNumber', item)
    const serialNumber = prop('serialNumber', item)
    const batchNumber = prop('batchNumber', item)
    const totalQuantity = prop('quantity', item)
    const location = prop('location', item)

    const unit = { id }
    const formattedCondition = { ...condition, name: prop('code', condition) }

    const value = {
      id,
      unitNumber,
      serialNumber,
      batchNumber,
      condition: formattedCondition,
      unit: id && unit,
      totalQuantity,
      location,
      quantity: 1
    }

    itemsArray.fields.push(value)
  }

  const handleItemClick = item => {
    popover.handleClose()
    if (popover.open) {
      addUnitNumber(item)
    }
  }

  const handleBarcodeFocus = () => {
    barcodeRef.current.focus()
  }

  const onChange = unitNumber => {
    cleanErrors()
    return onUnitNumberCheck(unitNumber, id)
      .then(({ units }) => {
        const isRemove = Boolean(units)
        if (isRemove) {
          const results = getItems(units)
          if (results.length === 1) {
            addUnitNumber(results[0])
          }
          if (results.length > 1) {
            const items = getItemsWithTotalQuantity(results)
            popover.setItems(items)
            popover.handleOpen()
          }
          if (results.length === 0) {
            snackbar({ message: 'No units found', type: ALTER_ERROR })
          }
        }
        if (!isRemove) {
          const result = { unitNumber, condition, quantity: 1 }
          addUnitNumber(result)
        }
      })
      .catch(error => {
        const unitNumber = prop('unitNumber', error)
        setError(unitNumber)
      })
  }

  const handleConditionCopy = () => {
    if (itemsValue.length) {
      const itemsValueCopy = [...itemsValue]

      const firstItemWithCondition = itemsValueCopy.reverse().find(prop('condition'))
      const firstCondition = prop('condition', firstItemWithCondition)

      const newItems = itemsValue.map(item => ({
        ...item,
        condition: firstCondition
      }))

      itemsField.input.onChange(newItems)
    }
  }

  return (
    <MobileFullWidthDialog open={open} onBackdropClick={onClose}>
      <FormStyled onSubmit={handleSubmit}>
        <DialogTitle>
          <ScannerDialogTitle
            image={image}
            title={title}
            sku={sku}
            barcode={barcode}
          />
        </DialogTitle>

        <ScannerDialogContent>
          <Grid item={true} xs={12}>
            <Grid container={true} spacing={3}>
              <Grid item={true} xs={11}>
                <UnitPopoverField
                  data-cy="unitNumber"
                  name="unitNumber"
                  label="Scan unit number"
                  barcodeRef={barcodeRef}
                  onChange={onChange}
                  disabled={isLoading}
                  onClose={popover.handleClose}
                  open={popover.open}
                  popoverContent={(
                    <DuplicateUnitsTable
                      items={popover.items}
                      onClick={handleItemClick}
                    />
                  )}
                />
              </Grid>
              <Grid item={true} xs={1}>
                <Button
                  variant="outlined"
                  onClick={() => onChange(barcode)}
                  fullWidth={true}
                >
                  Add item
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true} xs={12}>
            <BarcodeList
              removeOperation={removeOperation}
              ownerId={ownerId}
              trackSerial={trackSerial}
              onBarcodeFocus={handleBarcodeFocus}
              onConditionCopy={handleConditionCopy}
              trackBatch={trackBatch}
            />
          </Grid>
        </ScannerDialogContent>

        <ActionButtons
          onClose={onClose}
          disabled={!itemsValue || isLoading}
        />
      </FormStyled>
    </MobileFullWidthDialog>
  )
}

DialogForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  initialValues: PropTypes.object,
  onUnitNumberCheck: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  condition: PropTypes.object,
  ownerId: PropTypes.number.isRequired,
  dialogTitle: PropTypes.object.isRequired,
  trackSerial: PropTypes.bool,
  removeOperation: PropTypes.bool,
  trackBatch: PropTypes.bool
}

export default React.memo(withForm(DialogForm), isEqual)
