import React from 'react'
import { prop } from 'ramda'
import { generatePath, useNavigate } from 'react-router-dom'
import { useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import { INTEGRATION_TABS } from '~/constants/tabs'
import * as ROUTES from '~/constants/routes'
import useMessages from '~/hooks/useMessages'

import { useSallaIntegrationCreate } from '../../hooks'
import SallaInstruction from '../../components/Salla/SallaInstruction'

function SallaInstructionContainer () {
  const messages = useMessages()
  const snackbar = useSnackbar()
  const navigate = useNavigate()

  const integrationCreate = useSallaIntegrationCreate()

  const handleSubmit = formValues =>
    integrationCreate
      .create(formValues)
      .then(({ result }) => {
        const guid = prop('guid', result)
        const type = 'salla'
        const params = { guid, type, tab: INTEGRATION_TABS.GENERAL }
        const detailPath = generatePath(ROUTES.INTEGRATION_UPDATE_PATH, params)
        navigate(detailPath)
      })
      .then(() => snackbar({ message: messages.CREATE_SUCCESS }))

  const title = 'New Salla integration'

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      activeNav={NAV.INTEGRATION}
      breadcrumbs={breadcrumbs}
    >
      <SallaInstruction
        initialValues={{ appId: '', type: 'salla' }}
        onSubmit={handleSubmit}
      />
    </DashboardLayout>
  )
}

export default SallaInstructionContainer
