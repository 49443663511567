import React from 'react'
import PropTypes from 'prop-types'
import FormControl from '@mui/material/FormControl'
import { TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { prop } from 'ramda'

import FieldWrapper from './FieldWrapper'

const DateField = React.forwardRef((props, ref) => {
  const {
    input,
    meta,
    inputFormat = 'dd.MM.yyyy',
    mask = '__.__.____',
    error,
    label,
    required,
    ...defaultProps
  } = props

  const dataCy = prop('data-cy', props)

  const restProps = meta.invalid
    ? ({ ...defaultProps, error: true, helperText: meta.submitError || meta.error })
    : defaultProps

  const handleChange = date => {
    input.onChange(date)
  }

  const labelRequired = required ? label + ' *' : label

  return (
    <FormControl style={{ width: '100%' }} error={meta.invalid}>
      <DatePicker
        ref={ref}
        value={input.value ? input.value : null}
        onChange={handleChange}
        inputFormat={inputFormat}
        mask={mask}
        OpenPickerButtonProps={{ sx: { mr: 0 } }}
        renderInput={props => <TextField {...props} error={error} data-cy={dataCy} size="small" />}
        InputProps={{ sx: { pr: 0 } }}
        closeOnSelect={true}
        label={labelRequired}
        {...restProps}
      />
    </FormControl>
  )
})

DateField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  size: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  mask: PropTypes.string,
  inputVariant: PropTypes.string,
  inputFormat: PropTypes.string,
  error: PropTypes.bool,
  label: PropTypes.string,
  required: PropTypes.bool
}

DateField.defaultProps = {
  size: 'small',
  fullWidth: true
}

export default FieldWrapper(DateField)
