import React from 'react'
import { SelectPostField } from 'storfox-form-fields'
import PropTypes from 'prop-types'

import { BANK_TRANSFER, CASH, CREDIT_CARD, PAYMENT_TYPES, TRADE_FINANCE } from '~/constants/paymentTypes'

const PAYMENT_OPTIONS = [
  { value: CASH, name: PAYMENT_TYPES[CASH] },
  { value: CREDIT_CARD, name: PAYMENT_TYPES[CREDIT_CARD] },
  { value: BANK_TRANSFER, name: PAYMENT_TYPES[BANK_TRANSFER] },
  { value: TRADE_FINANCE, name: PAYMENT_TYPES[TRADE_FINANCE] }
]

function PaymentTypeUpdateField ({ isLoading, onChange, ...props }) {
  return (
    <SelectPostField
      isLoading={isLoading}
      onChange={onChange}
      data-cy="paymentType"
      name="paymentType"
      label="Payment type"
      options={PAYMENT_OPTIONS}
      {...props}
    />
  )
}

PaymentTypeUpdateField.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
}

export default PaymentTypeUpdateField
