import React from 'react'
import { Card } from '@mui/material'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import { path, prop, propOr } from 'ramda'
import PropTypes from 'prop-types'

import { toDuration } from '~/utils'
import TextLabelLoader from '~/components/TextLabelLoader'
import TableDateFormat from '~/components/TableDateFormat'
import CardHeader from '~/components/Cards/CardHeader'

import PutawayStatus from '../PutawayList/PutawayStatus'

function PutawayInformation ({ initialValues }) {
  const status = propOr('', 'status', initialValues)
  const lineItemQuantity = prop('lineItemQuantity', initialValues)
  const duration = toDuration(propOr('', 'duration', initialValues))
  const createdAt = prop('createdAt', initialValues)
  const lineItemCount = propOr(0, 'lineItemCount', initialValues)
  const fromLocation = path(['fromLocation', 'locationId'], initialValues)
  const warehouse = path(['fromLocation', 'warehouse', 'name'], initialValues)
  return (
    <Card>
      <CardHeader title="Putaway Information" />
      <Divider />
      <CardContent>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={2} xs={12}>
            <TextLabelLoader label="Line item">
              {lineItemCount}
            </TextLabelLoader>
          </Grid>

          <Grid item={true} lg={2} xs={12}>
            <TextLabelLoader label="Qty">
              {lineItemQuantity}
            </TextLabelLoader>
          </Grid>

          <Grid item={true} lg={2} xs={12}>
            <TextLabelLoader label="Status">
              <PutawayStatus value={status} />
            </TextLabelLoader>
          </Grid>

          <Grid item={true} lg={2} xs={12}>
            <TextLabelLoader label="From location">
              {fromLocation}
            </TextLabelLoader>
          </Grid>

          <Grid item={true} lg={4} xs={12}>
            <TextLabelLoader label="Warehouse">
              {warehouse}
            </TextLabelLoader>
          </Grid>

          <Grid item={true} lg={2} xs={12}>
            <TextLabelLoader label="Duration">
              {duration}
            </TextLabelLoader>
          </Grid>

          <Grid item={true} lg={2} xs={12}>
            <TextLabelLoader label="Created date">
              <TableDateFormat date={createdAt} />
            </TextLabelLoader>
          </Grid>

        </Grid>
      </CardContent>
    </Card>
  )
}

PutawayInformation.propTypes = {
  initialValues: PropTypes.object.isRequired
}

export default PutawayInformation
