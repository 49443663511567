import React from 'react'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import PropTypes from 'prop-types'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import TableContainer from '@mui/material/TableContainer'
import { path, prop, propOr } from 'ramda'

import EmptyNotesIcon from '~/components/Icons/EmptyNotesIcon'
import EmptyLineItems from '~/components/EmptyLineItems/EmptyLineItems'
import HistoryTableSearch from '~/components/History/HistoryTableSearch'
import SkeletonList from '~/components/Skeleton'
import { NAMES } from '~/constants/barcodingStrategies'
import Avatar from '~/components/Avatar'
import VerticalAlignment from '~/components/VerticalAlignment'
import TextOverflow from '~/components/TextOverflow'
import Subtext from '~/components/Subtext'
import CurrencyMoney from '~/components/Money/CurrencyMoney'
import TableDateFormat from '~/components/TableDateFormat/TableDateFormat'

import PaginationNewType from './PaginationNewType'

function AdjustmentLineItemsTable ({ rows, isLoading, hasNextPage, hasPrevPage }) {
  const { t } = useTranslation()

  const renderEmptyLines = Boolean(!isLoading && !rows.length)
  const renderLineItems = Boolean(!isLoading && rows.length)

  return (
    <Box component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto' }}>
      <TableContainer>
        <HistoryTableSearch />
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: 75 }}>{t('Image')}</TableCell>
              <TableCell>{t('Product')}</TableCell>
              <TableCell sx={{ minWidth: 200 }}>{t('Barcoding strategy')}</TableCell>
              <TableCell sx={{ width: 150 }}>{t('Unit cost')}</TableCell>
              <TableCell sx={{ minWidth: 100 }}>{t('Qty')}</TableCell>
              <TableCell sx={{ width: 150 }}>{t('Container number')}</TableCell>
              <TableCell sx={{ width: 150 }}>{t('Expiry date')} </TableCell>
              <TableCell sx={{ width: 150 }}>{t('Production date')}</TableCell>
              <TableCell sx={{ width: 150 }}>{t('Location')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <SkeletonList
                rowCount={3}
                columnCount={9}
                height={25}
              />
            )}

            {renderEmptyLines && (
              <EmptyLineItems
                colSpan={4}
                icon={<EmptyNotesIcon />}
                text="No recorded history"
              />
            )}
            {renderLineItems && rows.map((row, index) => {
              const variant = prop('variant', row)
              const variantName = prop('name', variant)
              const variantGuid = prop('guid', variant)
              const barcodingStrategy = NAMES[prop('barcodingStrategy', variant)]
              const sku = prop('sku', variant)
              const imagePath = prop('defaultImage', variant)
              const unitCost = propOr(0, 'price', row)
              const quantity = propOr(0, 'quantity', row)
              const containerNumber = path(['container', 'number'], row)
              const expiresAt = prop('expiresAt', row)
              const productionDate = prop('productionDate', row)
              const location = path(['location', 'locationId'], row)

              return (
                <TableRow key={index}>
                  <TableCell>
                    <Avatar
                      alt={variantName}
                      src={imagePath}
                    />
                  </TableCell>

                  <TableCell>
                    <VerticalAlignment
                      primary={(
                        <TextOverflow
                          selfTooltip={true}
                          condition={variantGuid}
                        >
                          {variantName}
                        </TextOverflow>
                      )}
                      secondary={<Subtext lines={1}>{sku}</Subtext>}
                    />
                  </TableCell>
                  <TableCell>{barcodingStrategy}</TableCell>
                  <TableCell>
                    <CurrencyMoney value={unitCost} />
                  </TableCell>
                  <TableCell>{quantity}</TableCell>
                  <TableCell>{containerNumber}</TableCell>
                  <TableCell>
                    <TableDateFormat date={expiresAt} />
                  </TableCell>
                  <TableCell>
                    <TableDateFormat date={productionDate} />
                  </TableCell>
                  <TableCell>{location}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationNewType hasNextPage={hasNextPage} hasPrevPage={hasPrevPage} prefix="" rowCount={rows.length} />
    </Box>
  )
}

AdjustmentLineItemsTable.defaultProps = {
  rows: []
}

AdjustmentLineItemsTable.propTypes = {
  rows: PropTypes.array.isRequired,
  hasPrevPage: PropTypes.bool.isRequired,
  hasNextPage: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default AdjustmentLineItemsTable
