import React, { useState } from 'react'
import { useSnackbar } from 'storfox-snackbar'
import { generatePath, useNavigate } from 'react-router-dom'
import { prop, propOr } from 'ramda'
import { sprintf } from 'sprintf-js'
import { useRequest } from 'storfox-api-hooks'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import useMessages from '~/hooks/useMessages'
import { MOVEMENT_DETAIL_TABS } from '~/constants/tabs'
import * as API from '~/constants/api'

import { MovementScanCreateForm } from '../components/MovementScanCreate'
import { useMovementCreate, useMovementCreateAndComplete, useMovementUnitNumber } from '../hooks'
import { MovementCreateSerializer } from '../serializers'
import { SAVE_COMPLETE } from '../constants'

function MovementScanCreateContainer () {
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const messages = useMessages()
  const request = useRequest()

  const movementCreate = useMovementCreate()
  const movementCreateAndComplete = useMovementCreateAndComplete()
  const movementUnitNumber = useMovementUnitNumber()

  const [containerLoading, setContainerLoading] = useState(false)

  const handleSubmit = formValues => {
    const submitType = propOr(SAVE_COMPLETE, 'submitType', formValues)
    if (submitType === SAVE_COMPLETE) {
      return movementCreateAndComplete.create(MovementCreateSerializer(formValues, 'scan'))
        .then(({ result }) => {
          const id = prop('id', result)
          const path = generatePath(ROUTES.MOVEMENT_DETAIL_PATH, { id, tab: MOVEMENT_DETAIL_TABS.GENERAL })

          navigate(path)
        })
        .then(() => snackbar({ message: messages.CREATE_SUCCESS }))
    } else {
      return movementCreate.create(MovementCreateSerializer(formValues, 'scan'))
        .then(({ result }) => {
          const id = prop('id', result)
          const path = generatePath(ROUTES.MOVEMENT_DETAIL_PATH, { id, tab: MOVEMENT_DETAIL_TABS.GENERAL })

          navigate(path)
        })
        .then(() => snackbar({ message: messages.CREATE_SUCCESS }))
    }
  }

  const handleVariantScan = (params) =>
    movementUnitNumber.scan(params)

  const handleContainerScan = containerNumber => {
    setContainerLoading(true)
    const url = sprintf(API.CONTAINER_BY_NUMBER, containerNumber)
    return request.get(url).then((res) => {
      setContainerLoading(false)
      return res
    }).catch((err) => {
      setContainerLoading(false)
      throw err
    })
  }

  const isLoading = (
    movementUnitNumber.isLoading ||
    containerLoading ||
    movementCreate.isLoading ||
    movementCreateAndComplete.isLoading
  )

  const scanMethods = {
    isLoading,
    handleVariantScan,
    handleContainerScan
  }

  const breadcrumbs = { title: 'New movement' }

  return (
    <DashboardLayout
      title="New movement"
      activeNav={NAV.MOVEMENT}
      breadcrumbs={breadcrumbs}
    >
      <MovementScanCreateForm
        onSubmit={handleSubmit}
        scanMethods={scanMethods}
        initialValues={{}}
      />
    </DashboardLayout>
  )
}

export default MovementScanCreateContainer
