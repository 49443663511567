import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import ShipmentListContainer from './containers/ShipmentListContainer'

export default () => {
  return [
    {
      layout: AuthLayout,
      path: ROUTES.CROSS_SHIPMENT_LIST_PATH,
      component: ShipmentListContainer
    },
  ]
}
