import React from 'react'
import PropTypes from 'prop-types'
import { prop, propOr } from 'ramda'
import { useParams } from 'react-router-dom'

import { DiscardButton, SaveButton } from '~/components/Buttons'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import { withForm } from '~/components/Form'
import * as NAV from '~/constants/nav-titles'
import Divider from '~/components/Divider'
import { INTEGRATION_TABS } from '~/constants/tabs'
import IntegrationHistory from '~/components/IntegrationHistory/IntegrationHistory'
import * as ROUTES from '~/constants/routes'

import ShipoxIntegrationUpdateTabs from './ShipoxIntegrationUpdateTabs'
import ShipoxIntegrationUpdateFormGeneral from './ShipoxIntegrationUpdateFormGeneral'

import More from '../IntegrationDelete/More'

function ShipoxIntegrationUpdateForm ({ pageTitle, pageTitleLoading, isLoading, form, onRemove, history }) {
  const { tab } = useParams()
  const { handleSubmit, initialValues } = form

  const isWebhookEnabled = propOr(true, 'isWebhookEnabled', initialValues)
  const webhookUrl = prop('webhookUrl', initialValues)
  const showWebhookInstruction = !isLoading && !isWebhookEnabled

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            parentTitle={NAV.INTEGRATION}
            pageTitle={pageTitle}
            pageTitleLoading={pageTitleLoading}
            rightButton={
              <>
                <DiscardButton to={ROUTES.INTEGRATION_LIST_PATH} disabled={isLoading} />
                <SaveButton disabled={isLoading} />
                <More onRemove={onRemove} />
              </>
            }
          />
        </Header>
        <Content>
          <ShipoxIntegrationUpdateTabs />
          <Divider />
          {tab === INTEGRATION_TABS.GENERAL && (
            <ShipoxIntegrationUpdateFormGeneral
              showWebhookInstruction={showWebhookInstruction}
              webhookUrl={webhookUrl}
            />
          )}
          {tab === INTEGRATION_TABS.LOGS && (
            <IntegrationHistory
              history={history}
              onRefetch={history.getListByParams}
            />
          )}
        </Content>
      </Container>
    </form>
  )
}

ShipoxIntegrationUpdateForm.propTypes = {
  pageTitle: PropTypes.string,
  pageTitleLoading: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  form: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  history: PropTypes.array.isRequired
}

export default withForm(ShipoxIntegrationUpdateForm)
