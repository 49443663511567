import React from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'

import EmailField from '~/components/Fields/EmailField'
import PasswordGenerateField from '~/components/Fields/PasswordGenerateField'
import PhoneNumberField from '~/components/Fields/PhoneNumberField'
import SingleImageUploadField from '~/components/Fields/SingleImageUploadField'
import TextField from '~/components/Fields/TextField'
import { CardHeader } from '~/components/Cards'

function CustomerUpdateFormGeneral () {
  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12} lg={8}>
        <Box mb={3}>
          <Card>
            <CardHeader title="Information" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12} lg={6}>
                  <TextField
                    data-cy="firstName"
                    name="firstName"
                    label="First name"
                  />
                </Grid>

                <Grid item={true} xs={12} lg={6}>
                  <TextField
                    data-cy="lastName"
                    name="lastName"
                    label="Last name"
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <EmailField
                    data-cy="email"
                    name="email"
                    label="Email"
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <PhoneNumberField
                    data-cy="mobile"
                    autoComplete="none"
                    name="mobile"
                    label="Phone number"
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <PasswordGenerateField
                    data-cy="password"
                    name="password"
                    label="Password"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>

      </Grid>
      <Grid item={true} xs={12} lg={4}>
        <Box mb={3}>
          <Card>
            <CardHeader title="Image" />
            <Divider />
            <CardContent>
              <SingleImageUploadField name="imagePath" />
            </CardContent>
          </Card>
        </Box>
      </Grid>

    </Grid>
  )
}

export default CustomerUpdateFormGeneral
