import React from 'react'
import PropTypes from 'prop-types'

import Context from './context'

function LoaderProvider ({ isLoading, children }) {
  const value = { isLoading }

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  )
}

LoaderProvider.propTypes = {
  children: PropTypes.any.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default LoaderProvider
