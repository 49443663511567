import objectPath from 'object-path'

import useGetApi from '../api/useGetApi'
import useDeepCompareEffect from '../useDeepCompareEffect'

const emptyObj = {}

export const toDetail = state => {
  const detail = objectPath.get(state, 'data.result') || emptyObj
  const isLoading = state.isLoading
  const getDetail = state.getDetail
  return { detail, isLoading, getDetail }
}

function useDetail (api, params, autoSend = true) {
  const { get, ...state } = useGetApi(api, autoSend)
  const getDetail = get

  useDeepCompareEffect(() => {
    if (autoSend) {
      getDetail(params)
    }
  }, [params, autoSend])

  return toDetail({ ...state, getDetail })
}

export default useDetail
