import React from 'react'
import { useFilter, useOrdering } from 'storfox-filter'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'
import useAllSearchParams from 'storfox-route-hooks/src/useAllSearchParams'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import ErrorLink from '~/components/ErrorLink'
import useMessages from '~/hooks/useMessages'
import { useGoogleEvent } from '~/components/GoogleAnalytics/GoogleAnalytics'
import { queryToParams } from '~/utils'

import { OrderUnitsFilterSerializer } from '../../serializers/OrderUnits'
import { useOrderUnitsList, useOrderUnitsReportGenerate } from '../../hooks/OrderUnits'
import OrderUnitsTable from '../../components/OrderUnits/OrderUnitsTable'
import OrderUnitsOrderingForm, { orderUnitsOrderingOptions } from '../../components/OrderUnits/OrderUnitsOrderingForm'
import OrderUnitsFilterForm, { orderUnitsFilterOptions } from '../../components/OrderUnits/OrderUnitsFilterForm'

function OrderUnitsListContainer () {
  const messages = useMessages()
  const snackbar = useSnackbar()
  const { sendEvent } = useGoogleEvent()
  const searchParams = useAllSearchParams()

  const orderUnitsList = useOrderUnitsList()
  const orderUnitsReportsGenerate = useOrderUnitsReportGenerate()

  const filter = useFilter(orderUnitsFilterOptions)
  const ordering = useOrdering(orderUnitsOrderingOptions)

  const breadcrumbs = { title: NAV.ORDER_UNITS_REPORT }

  const handleReportGenerate = () => {
    const params = queryToParams(OrderUnitsFilterSerializer, searchParams, 'guid')
    return orderUnitsReportsGenerate.generate(params)
      .then(() => {
        sendEvent({ eventAction: 'Report Bulk Generate Order Units', eventCategory: 'Report' })
        snackbar({ message: messages.NOTIFICATION_WAIT })
      })
      .catch(response => {
        const message = <ErrorLink error={response} />
        snackbar({ message, type: ALTER_ERROR })
      })
  }

  return (
    <DashboardLayout
      title={NAV.ORDER_UNITS_REPORT}
      activeNav={NAV.REPORTS}
      breadcrumbs={breadcrumbs}
    >
      <OrderUnitsFilterForm {...filter} />
      <OrderUnitsOrderingForm {...ordering} />

      <OrderUnitsTable
        onListRefetch={orderUnitsList.getListByParams}
        filter={filter}
        ordering={ordering}
        list={orderUnitsList}
        onReportGenerate={handleReportGenerate}
      />
    </DashboardLayout>
  )
}

export default OrderUnitsListContainer
