import React from 'react'
import { Ordering } from 'storfox-filter'

import { FILTER_NAME as name } from '../../constants'
import { CATEGORY_COLUMNS as tableColumns } from '../CategoryList/CategoryTable'

export const categoryOrderingOptions = {
  name,
  tableColumns
}

function CategoryOrderingForm (props) {
  return (
    <Ordering {...props} />
  )
}

export default CategoryOrderingForm
