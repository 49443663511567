import { sprintf } from 'sprintf-js'
import { useAllSearchParams } from 'storfox-route-hooks'
import { useLimitedCountList, useCreate, useDelete, useDetail, useUpdate } from 'storfox-api-hooks'

import * as API from '~/constants/api'
import { queryToParams } from '~/utils'

import { BucketFilterSerializer } from '../serializers'

export const useBucketList = () => {
  const searchParams = useAllSearchParams()
  const params = queryToParams(BucketFilterSerializer, searchParams)

  return useLimitedCountList(API.BUCKET_LIST, params)
}

export const useBucketDelete = (guid) => {
  const url = sprintf(API.BUCKET_DELETE, guid)
  return useDelete(url)
}

export const useBucketDetail = guid => {
  const url = sprintf(API.BUCKET_DETAIL, guid)
  return useDetail(url)
}

export const useBucketCreate = () => {
  return useCreate(API.BUCKET_CREATE)
}

export const useBucketUpdate = guid => {
  const url = sprintf(API.BUCKET_UPDATE, guid)
  return useUpdate(url)
}
