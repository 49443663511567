import React from 'react'
import SearchField from 'storfox-form-fields/src/SearchField'

const getOptionValue = value => {
  if (value) {
    const { id, guid, name } = value
    return { id, guid, name }
  }

  return null
}

function BucketField (props) {
  return (
    <SearchField
      data-cy="bucket"
      name="bucket"
      label="Store"
      getOptionValue={getOptionValue}
      {...props}
    />
  )
}

export default BucketField
