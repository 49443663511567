import { useLimitedCountList, useCreate } from 'storfox-api-hooks'
import useAllSearchParams from 'storfox-route-hooks/src/useAllSearchParams'

import { queryToParams } from '~/utils'
import * as API from '~/constants/api'

import { StockAgingFilterSerializer } from '../../serializers/StockAging'

export const useStockAgingList = () => {
  const searchParams = useAllSearchParams()
  const params = queryToParams(StockAgingFilterSerializer, searchParams, 'guid')
  const { getList, ...state } = useLimitedCountList(API.STOCK_AGING_LIST, params)

  return { ...state, getList: rest => getList({ ...rest, ...params }) }
}

export const useStockAgingExport = () => {
  const searchParams = useAllSearchParams()
  const params = queryToParams(StockAgingFilterSerializer, searchParams, 'guid')
  const { create, ...rest } = useCreate(API.STOCK_AGING_EXPORT)

  return { ...rest, export: rest => create({ ...rest, ...params }) }
}
