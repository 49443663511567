import { sprintf } from 'sprintf-js'
import { useAllSearchParams } from 'storfox-route-hooks'
import {
  useLimitedCountList,
  useCreate,
  useDelete,
  useDetail,
  useList,
  useUpdate,
  useScrollList
} from 'storfox-api-hooks'

import { queryToParams } from '~/utils'
import * as API from '~/constants/api'

import { SaleOrderFilterSerializer } from '../serializers'

export const useSaleOrderList = () => {
  const { select, ...searchParams } = useAllSearchParams()
  const params = queryToParams(SaleOrderFilterSerializer, searchParams, 'guid')

  const { getList, ...state } = useLimitedCountList(API.SALE_ORDER_LIST, { ...params })
  return { ...state, getList: () => getList(params) }
}

export const useSaleOrderDetail = guid => {
  const url = sprintf(API.SALE_ORDER_DETAIL, guid)
  return useDetail(url)
}

export const useSaleOrderCreate = () => {
  return useCreate(API.SALE_ORDER_CREATE)
}

export const useSaleOrderBulkWarehouse = () => {
  return useCreate(API.SALE_ORDER_BULK_WAREHOUSE_SET)
}

export const useSaleOrderUpdate = guid => {
  const url = sprintf(API.SALE_ORDER_UPDATE, guid)
  return useUpdate(url)
}

export const useSaleOrderListProcess = (id) => {
  const url = sprintf(API.SALE_ORDERS_PROCESS, id)
  return useUpdate(url)
}

export const useSaleOrdersDelete = () => {
  return useDelete(API.SALE_ORDER_DELETE)
}

export const useSaleOrderProcess = (id) => {
  const url = sprintf(API.SALE_ORDER_PROCESS, id)
  const { update, ...state } = useUpdate(url)

  return { ...state, process: update }
}

export const useSaleOrderListAllocate = () => {
  return useUpdate(API.SALE_ORDERS_ALLOCATE)
}

export const useSaleOrderRelease = (guid) => {
  const url = sprintf(API.SALE_ORDERS_RELEASE, guid)
  return useCreate(url)
}

export const useManualAllocation = (guid) => {
  const url = sprintf(API.SALE_ORDER_MANUAL_ALLOCATE, guid)
  return useCreate(url)
}

export const useSaleOrderResolve = (id) => {
  const url = sprintf(API.SALE_ORDERS_RESOLVE, id)
  const { update, ...state } = useUpdate(url)

  return { ...state, resolve: update }
}

export const useSaleOrderSkipProcess = guid => {
  const url = sprintf(API.SALE_ORDER_SKIP_PROCESS, guid)

  return useUpdate(url)
}

export const useSaleOrderHistory = (guid, autoSend) => {
  const url = sprintf(API.SALE_ORDER_HISTORY, guid)
  const searchParams = useAllSearchParams()

  return useList(url, searchParams, autoSend)
}

export const useSaleOrderPicklist = (searchParams, autoSend) => {
  const url = sprintf(API.PICKLIST_LIST)

  return useList(url, searchParams, autoSend)
}

export const useSaleOrderPackingList = (searchParams, autoSend) => {
  const url = sprintf(API.PACKING_LIST)

  return useList(url, searchParams, autoSend)
}

export const useSaleOrderShipmentList = (searchParams, autoSend) => {
  const url = sprintf(API.SHIPMENT_LIST)

  return useList(url, searchParams, autoSend)
}

export const useSaleOrderReturnList = (searchParams, autoSend) => {
  const url = sprintf(API.SALE_RETURN_LIST)

  return useList(url, searchParams, autoSend)
}

export const useSaleOrderUnitList = (searchParams, autoSend) => {
  const url = sprintf(API.UNIT_NUMBER_LIST)

  return useList(url, searchParams, autoSend)
}

export const useSaleOrderPutawayList = (searchParams, autoSend) => {
  const url = sprintf(API.V1_PUTAWAY_LIST)

  return useList(url, searchParams, autoSend)
}

export const useSaleOrderComments = (guid, autoSend) => {
  const url = sprintf(API.ORDER_COMMENTS, guid)
  return useList(url, {}, autoSend)
}

export const useSaleOrderCommentsSend = (guid) => {
  const url = sprintf(API.ORDER_SEND_COMMENTS, guid)
  const { create, ...state } = useCreate(url)

  return { ...state, send: create }
}

export const useSaleOrderImport = () => {
  return useCreate(API.SALE_ORDER_FILE_UPLOAD)
}

export const useSaleOrderCancel = (id) => {
  const url = sprintf(API.SALE_ORDER_CANCEL, id)
  const { update, ...state } = useUpdate(url)

  return { ...state, cancel: update }
}

export const useSaleOrderCustomerCreate = () => {
  return useCreate(API.CUSTOMER_CREATE)
}

export const useSaleOrderMarkAsPaid = guid => {
  const url = sprintf(API.SALE_ORDER_MARK_AS_PAID, guid)
  const { update, ...state } = useUpdate(url)

  return { ...state, mark: update }
}

export const useInvoiceGenerate = guid => {
  const url = sprintf(API.ORDER_INVOICE_GENERATE, guid)
  const { create, ...state } = useCreate(url)

  return { ...state, generate: create }
}

export const useHealthCareInvoice = guid => {
  const url = sprintf(API.ORDER_HEALTH_CARE_INVOICE_GENERATE, guid)
  const { create, ...state } = useCreate(url)

  return { ...state, generate: create }
}

export const useWarehouseList = () => {
  const { getList, ...state } = useList(API.SALE_ORDER_WAREHOUSE, {}, false)
  return { ...state, getList: () => getList({ limit: 1000 }) }
}

export const useManualUnitList = (guid) => {
  const url = sprintf(API.SALE_ORDER_MANUAL_UNITS, guid)
  const { getList, ...state } = useScrollList(url, {}, 1000)
  return { ...state, getList }
}

export const useSaleOrderSplit = guid => {
  const url = sprintf(API.ORDER_SPLIT, guid)
  const { create, ...state } = useCreate(url)

  return { ...state, split: create }
}

export const usePaymentTypeSet = () => {
  const { create, ...state } = useCreate(API.ORDER_PAYMENT_TYPE_UPDATE)
  return { ...state, set: create }
}

export const useSaleOrderReportGenerate = () => {
  const { create, ...state } = useCreate(API.ORDER_REPORT_GENERATE)
  return { ...state, generate: create }
}

export const useSaleOrderBulkCancel = () => {
  const { create, ...state } = useCreate(API.ORDER_BULK_CANCEL)
  return { ...state, cancel: create }
}

export const useSaleOrderVariant = params => {
  return useScrollList(API.SALE_ORDER_VARIANTS, params)
}

export const useSaleOrderFileAttach = guid => {
  const url = sprintf(API.SALE_ORDER_FILE_ATTACH, guid)
  const { create, ...state } = useCreate(url)

  return { ...state, attach: create }
}

export const useSaleOrderFileAttachRemove = guid => {
  const url = sprintf(API.SALE_ORDER_FILE_ATTACH_REMOVE, guid)
  return useCreate(url)
}

export const useOrderConditionList = () => {
  const { getList, ...state } = useList(API.ORDER_CONDITIONS, {}, false)
  return { ...state, getList: (params) => getList({ limit: 1000, ...params }) }
}
