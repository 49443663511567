import React from 'react'

import SelectField from '~/components/Fields/SelectField'
import { CANCELLED, COMPLETED, IN_PROGRESS, NEW, PENDING } from '~/components/Statuses/PicklistStatus'

const OPTIONS = [
  { value: null, name: 'All' },
  { value: NEW, name: 'New' },
  { value: PENDING, name: 'Pending' },
  { value: IN_PROGRESS, name: 'In Progress' },
  { value: COMPLETED, name: 'Completed' },
  { value: CANCELLED, name: 'Cancelled' }
]

function PicklistStatusField (props) {
  return (
    <SelectField
      name="status"
      label="Status"
      options={OPTIONS}
      {...props}
    />
  )
}

export default PicklistStatusField
