import { sprintf } from 'sprintf-js'
import { usePickSearchParams } from 'storfox-route-hooks'
import { useCreate, useDelete, useDetail, useList, useUpdate, useLimitedCountList } from 'storfox-api-hooks'
import useAllSearchParams from 'storfox-route-hooks/src/useAllSearchParams'

import { queryToParams } from '~/utils'
import * as API from '~/constants/api'

import { UserFilterSerializer } from '../serializers'

export const useUserList = () => {
  const searchParams = useAllSearchParams()
  const params = queryToParams(UserFilterSerializer, searchParams, 'guid')

  return useLimitedCountList(API.USER_LIST, params)
}

export const useUserDetail = id => {
  const url = sprintf(API.USER_DETAIL, id)
  return useDetail(url)
}

export const useUserHistory = (guid, autoSend) => {
  const url = sprintf(API.USER_HISTORY, guid)
  const searchParams = usePickSearchParams()

  return useList(url, searchParams, autoSend)
}

export const useUserCreate = () => {
  return useCreate(API.USER_CREATE)
}

export const useUserUpdate = id => {
  const url = sprintf(API.USER_UPDATE, id)
  return useUpdate(url)
}

export const useUsersDelete = () => {
  return useDelete(API.USERS_DELETE)
}
