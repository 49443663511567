import React from 'react'
import PropTypes from 'prop-types'
import MUITab from '@mui/material/Tab'
import MUITabs from '@mui/material/Tabs'
import { useTranslation } from 'react-i18next'
import { is } from 'ramda'

function Tabs ({ tabs, value, onChange, ...props }) {
  const { t } = useTranslation()

  return (
    <MUITabs
      onChange={onChange}
      scrollButtons="auto"
      value={value}
      indicatorColor="primary"
      textColor="inherit"
      {...props}
    >
      {tabs.map((tab, index) => (
        <MUITab
          data-cy={`tab-${tab.value}`}
          key={index}
          label={is(String, tab.label) ? t(tab.label) : tab.label}
          value={tab.value}
        />
      ))}
    </MUITabs>
  )
}

Tabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string
}

export default Tabs
