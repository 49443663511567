import React from 'react'
import Grid from '@mui/material/Grid'
import { FieldArray } from 'react-final-form-arrays'
import { Card } from '@mui/material'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box'
import { useField } from 'react-final-form'

import { CardHeader } from '~/components/Cards'
import TextField from '~/components/Fields/TextField'
import { Button, DeleteConfirmButton } from '~/components/Buttons'

import PrimarySwitchField from '../Fields/PrimarySwitchField'

const EMPTY_ADDRESS = {
  accountHolder: '',
  accountNumber: '',
  bankBranch: '',
  bankCode: '',
  bankName: '',
  bankSwift: ''
}

function SupplierUpdateBankAccount () {
  const bankAccountsField = useField('bankAccounts')
  const bankAccounts = bankAccountsField.input.value

  const handleBankAccountsChange = bankAccountsField.input.onChange

  const handlePrimaryChange = primaryIndex => {
    const newBankAccounts = bankAccounts.map((address, index) => {
      return { ...address, isPrimary: index === primaryIndex }
    })

    handleBankAccountsChange(newBankAccounts)
  }

  return (
    <Grid container={true} spacing={3}>
      <FieldArray
        name="bankAccounts"
        render={({ fields }) => {
          const accountsCount = fields.length
          const onAdd = () => fields.push(EMPTY_ADDRESS)
          const onRemove = index => fields.remove(index)

          return fields.map((name, index) => (
            <Grid item={true} xs={12} lg={6} key={index}>
              <Card>
                <CardHeader title="Bank account" />
                <Divider />
                <CardContent>
                  <Grid container={true} spacing={3}>
                    <Grid item={true} xs={12}>
                      <TextField
                        data-cy={`${name}.bankName`}
                        name={`${name}.bankName`}
                        label="Bank name"
                      />
                    </Grid>

                    <Grid item={true} xs={12} lg={4}>
                      <TextField
                        data-cy={`${name}.bankBranch`}
                        name={`${name}.bankBranch`}
                        label="Bank branch"
                      />
                    </Grid>

                    <Grid item={true} xs={12} lg={4}>
                      <TextField
                        data-cy={`${name}.bankCode`}
                        name={`${name}.bankCode`}
                        label="Bank code"
                      />
                    </Grid>

                    <Grid item={true} xs={12} lg={4}>
                      <TextField
                        data-cy={`${name}.bankSwift`}
                        name={`${name}.bankSwift`}
                        label="Bank swift"
                      />
                    </Grid>

                    <Grid item={true} xs={12} lg={6}>
                      <TextField
                        data-cy={`${name}.accountHolder`}
                        name={`${name}.accountHolder`}
                        label="Account holder"
                      />
                    </Grid>

                    <Grid item={true} xs={12} lg={6}>
                      <TextField
                        data-cy={`${name}.accountNumber`}
                        name={`${name}.accountNumber`}
                        label="Account number"
                      />
                    </Grid>

                    <Grid item={true} xs={12}>
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        {accountsCount > 1 && <DeleteConfirmButton onClick={() => onRemove(index)} />}

                        {index === accountsCount - 1 && (
                          <Button onClick={onAdd} >
                            Add another address
                          </Button>
                        )}

                        <PrimarySwitchField
                          name={`${name}.isPrimary`}
                          onChange={() => handlePrimaryChange(index)}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))
        }}
      />
    </Grid>
  )
}

export default SupplierUpdateBankAccount
