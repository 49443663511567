import React from 'react'
import { path, prop } from 'ramda'
import PropTypes from 'prop-types'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import { styled } from '@mui/material'

import Avatar from '../Avatar/Avatar'

const TableRowStyled = styled(TableRow)(({ theme }) => ({
  transition: '.25s ease background',
  cursor: 'pointer',
  '&:hover': {
    background: theme.palette.hover.color
  }
}))

function DuplicateUnitsTable ({ items, onClick }) {
  return (
    <TableContainer sx={{ width: '100%' }}>
      <Table size="small" sx={{ width: '100%' }}>
        <TableHead>
          <TableRow>
            <TableCell width={75}>Image</TableCell>
            <TableCell>Variant</TableCell>
            <TableCell>Serial number</TableCell>
            <TableCell>Batch number</TableCell>
            <TableCell>Condition</TableCell>
            <TableCell>Quantity</TableCell>
            <TableCell>Container</TableCell>
            <TableCell>Location</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, index) => {
            const variant = prop('variant', item)
            const name = prop('name', variant)
            const image = prop('defaultImage', variant)
            const serialNumber = prop('serialNumber', item)
            const batchNumber = prop('batchNumber', item)
            const condition = path(['condition', 'code'], item)
            const quantity = prop('totalQuantity', item)
            const container = path(['container', 'number'], item)
            const location = path(['location', 'locationId'], item)

            return (
              <TableRowStyled
                key={index}
                onClick={() => onClick(item)}
                data-cy={`grid-row-${index}`}
              >
                <TableCell>
                  <Avatar src={image} alt={name} />
                </TableCell>
                <TableCell>{name}</TableCell>
                <TableCell>{serialNumber}</TableCell>
                <TableCell>{batchNumber}</TableCell>
                <TableCell>{condition}</TableCell>
                <TableCell>{quantity}</TableCell>
                <TableCell>{container}</TableCell>
                <TableCell>{location}</TableCell>
              </TableRowStyled>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

DuplicateUnitsTable.propTypes = {
  items: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired
}

export default DuplicateUnitsTable
