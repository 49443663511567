import React from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { prop } from 'ramda'

import { DiscardButton, SaveButton } from '~/components/Buttons'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import { withForm } from '~/components/Form'
import * as NAV from '~/constants/nav-titles'
import Divider from '~/components/Divider'
import { INTEGRATION_TABS } from '~/constants/tabs'
import IntegrationHistory from '~/components/IntegrationHistory/IntegrationHistory'
import { SALLA } from '~/constants/integrationTypes'
import * as ROUTES from '~/constants/routes'

import SallaUpdate from './SallaUpdate'
import SallaIntegrationUpdateTabs from './SallaIntegrationUpdateTabs'

import More from '../../IntegrationDelete/More'

function SallaIntegrationUpdateForm (props) {
  const { pageTitle, pageTitleLoading, isLoading, form, onRemove, history, onRetry, retryLoading } = props
  const { tab } = useParams()

  const { handleSubmit, values } = form
  const type = prop('type', values)

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            parentTitle={NAV.INTEGRATION}
            pageTitle={pageTitle}
            pageTitleLoading={pageTitleLoading}
            rightButton={
              <>
                <DiscardButton to={ROUTES.INTEGRATION_LIST_PATH} disabled={isLoading} />
                <SaveButton disabled={isLoading} />
                <More onRemove={onRemove} />
              </>
            }
          />
        </Header>
        <Content>
          <SallaIntegrationUpdateTabs />
          <Divider />
          {tab === INTEGRATION_TABS.GENERAL && <SallaUpdate type={type} />}
          {tab === INTEGRATION_TABS.LOGS && (
            <IntegrationHistory
              history={history}
              integrationType={SALLA}
              onRetry={onRetry}
              retryLoading={retryLoading}
              onRefetch={history.getListByParams}
            />
          )}
        </Content>
      </Container>
    </form>
  )
}

SallaIntegrationUpdateForm.propTypes = {
  pageTitle: PropTypes.string,
  pageTitleLoading: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  form: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  onRetry: PropTypes.func,
  retryLoading: PropTypes.bool
}

export default withForm(SallaIntegrationUpdateForm)
