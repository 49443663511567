import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { Grid } from '@mui/material'
import Divider from '@mui/material/Divider'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useField } from 'react-final-form'
import { propOr } from 'ramda'

import { CardHeader } from '~/components/Cards'
import ShipmentSearchField from '~/components/Fields/ShipmentSearchField'
import TrackingNumber from '~/components/Fields/TrackingNumber'
import * as API from '~/constants/api'

import ShippingServiceField from './ShippingServiceField'

function ShippingMethodCard (props) {
  const { guid } = useParams()

  const shipmentField = useField('shipment')
  const shipmentFieldValue = shipmentField.input.value

  const id = propOr(0, 'id', shipmentFieldValue)

  const params = {
    limit: 1000,
    returnGuid: guid
  }

  return (
    <Card>
      <CardHeader title="Method" />
      <Divider />
      <CardContent>
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={12}>
            <ShipmentSearchField
              label="Carrier"
              name="carrier"
              api={API.RETURN_CARRIERS}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <ShippingServiceField
              guid={guid}
              integrationName="carrier"
              api={API.RETURN_SERVICES_CHECK}
              params={params}
              {...props}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <TrackingNumber
              integrationName="carrier"
              serviceName="service"
              disabled={Boolean(id)}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

ShippingMethodCard.propTypes = {
  onIntegrationCheck: PropTypes.func.isRequired
}

export default ShippingMethodCard
