import React from 'react'

import SelectField from '~/components/Fields/SelectField'

const OPTIONS = [
  {
    value: 'D',
    name: 'Document'
  },
  {
    value: 'X',
    name: 'Non-Document'
  }
]

function ItemTypeField (props) {
  return (
    <SelectField
      label="Item type"
      options={OPTIONS}
      {...props}
    />
  )
}

export default ItemTypeField
