import account from './modules/account'
import user from './modules/user'
import role from './modules/role'
import company from './modules/company'
import configuration from './modules/configuration'
import integration from './modules/integration'
import billing from './modules/billing'
import barcode from './modules/barcode'

const SettingsRoutes = () => [
  ...account(),
  ...user(),
  ...role(),
  ...company(),
  ...configuration(),
  ...integration(),
  ...billing(),
  ...barcode()
]

export default SettingsRoutes
