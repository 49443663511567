import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

function EmptyTypeCard () {
  return (
    <Card>
      <CardContent>
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={12}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              minHeight={100}
            >
              <Typography variant="h6">Please, choose Type</Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default EmptyTypeCard
