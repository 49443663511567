import { defaultTo, pipe, startsWith, toLower } from 'ramda'

export const matchContainer = pipe(
  defaultTo(''),
  toLower,
  startsWith('ct-')
)

export const matchCondition = pipe(
  defaultTo(''),
  toLower,
  startsWith('cd-')
)

export const matchContainerType = pipe(
  defaultTo(''),
  toLower,
  startsWith('ctt-')
)
