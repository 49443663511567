import React from 'react'
import { path, pathOr, prop, propOr } from 'ramda'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { styled } from '@mui/material'
import { generatePath } from 'react-router-dom'

import Avatar from '~/components/Avatar/Avatar'
import VerticalAlignment from '~/components/VerticalAlignment'
import Subtext from '~/components/Subtext'
import SkeletonList from '~/components/Skeleton'
import CurrencyMoney from '~/components/Money/CurrencyMoney'
import Percentage from '~/components/Percentage'
import TextOverflow from '~/components/TextOverflow'
import TableLink from '~/components/Link'
import * as ROUTES from '~/constants/routes'
import { VARIANT_DETAIL_TABS } from '~/constants/tabs'

import BundleRow from '../SaleOrderDetail/BundleRow'
import TextUnknown from '../TextUnknown'

const MediumTableCellStyled = styled(TableCell)({
  width: 150
})

function LineItems ({ isLoading, currency, lineItems }) {
  const { t } = useTranslation()

  return (
    <TableContainer>
      <Table size="small" sx={{ minWidth: '700px' }}>
        <TableHead>
          <TableRow>
            <TableCell>{t('Image')}</TableCell>
            <TableCell sx={{ width: '250px' }}>{t('Variant')}</TableCell>
            <TableCell sx={{ minWidth: '175px' }}>{t('Unit price')}</TableCell>
            <TableCell sx={{ minWidth: '200px' }}>{t('Condition code')}</TableCell>
            <TableCell>{t('Qty')}</TableCell>
            <TableCell>{t('Reserved')}</TableCell>
            <TableCell>{t('Discounts')}</TableCell>
            <TableCell>{t('Taxes')}</TableCell>
            <TableCell>{t('Total')}</TableCell>
            <TableCell>{t('Action')}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading
            ? <SkeletonList columnCount={8} />
            : lineItems.map(item => {
              const variant = prop('variant', item)
              const productSku = prop('productSku', item)
              const productId = pathOr(0, ['product', 'id'], variant)
              const isMultiVariant = pathOr(false, ['product', 'isMultiVariant'], variant)
              const variantId = propOr(0, 'id', variant)
              const variantName = prop('name', variant)
              const variantGuid = prop('guid', variant)
              const sku = prop('sku', variant)
              const conditionCode = path(['condition', 'name'], item)
              const imagePath = prop('imagePath', item)
              const alt = variantName || productSku || 'Unknown'
              const unitCost = propOr(0, 'price', item)
              const quantity = propOr(0, 'quantity', item)
              const discount = propOr(0, 'discount', item)
              const tax = propOr(0, 'tax', item)
              const totalCost = propOr(0, 'totalCost', item)
              const reserved = prop('reserved', item)

              const params = { id: variantId, productId, tab: VARIANT_DETAIL_TABS.GENERAL }
              const variantLink = `${generatePath(ROUTES.VARIANT_DETAIL_PATH, params)}?isMultiVariant=${isMultiVariant}`

              return (
                <BundleRow variant={variant} key={variantId}>
                  <TableCell>
                    <Avatar
                      alt={alt}
                      src={imagePath}
                    />
                  </TableCell>

                  <TableCell>
                    {variantGuid ? (
                      <VerticalAlignment
                        sx={{ minWidth: '200px' }}
                        primary={(
                          <TableLink to={variantLink}>
                            <TextOverflow selfTooltip={true}>
                              {variantName}
                            </TextOverflow>
                          </TableLink>
                        )}
                        secondary={<Subtext lines={1}>{sku}</Subtext>}
                      />
                    ) : (
                      <TextUnknown>{productSku}</TextUnknown>
                    )}
                  </TableCell>
                  <TableCell>
                    <CurrencyMoney currency={currency} value={unitCost} />
                  </TableCell>
                  <TableCell>{conditionCode}</TableCell>
                  <MediumTableCellStyled>{quantity}</MediumTableCellStyled>
                  <MediumTableCellStyled>{reserved}</MediumTableCellStyled>
                  <MediumTableCellStyled>
                    <Percentage value={discount} />
                  </MediumTableCellStyled>
                  <MediumTableCellStyled>
                    <Percentage value={tax} />
                  </MediumTableCellStyled>
                  <TableCell>
                    <CurrencyMoney currency={currency} value={totalCost} />
                  </TableCell>
                </BundleRow>
              )
            })}
        </TableBody>
      </Table>
    </TableContainer>

  )
}

LineItems.propTypes = {
  lineItems: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  currency: PropTypes.string,
}

LineItems.defaultProps = {
  lineItems: 0
}

export default LineItems
