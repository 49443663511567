import React from 'react'
import MuiMenu, { menuClasses } from '@mui/material/Menu'
import PropTypes from 'prop-types'
import { styled } from '@mui/material'

const MenuStyled = styled(MuiMenu)({
  [`& .${menuClasses.list}`]: {
    minWidth: 275,
    paddingTop: 0,
    paddingBottom: 0
  }
})

const Menu = React.forwardRef((props, ref) => {
  const {
    anchorEl,
    open,
    onClose,
    children,
    ...rest
  } = props

  return (
    <MenuStyled
      ref={ref}
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      keepMounted={true}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      disableScrollLock={true}
      {...rest}
    >
      {children}
    </MenuStyled>
  )
})

Menu.propTypes = {
  children: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.any
}

export default Menu
