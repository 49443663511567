import React from 'react'
import { prop } from 'ramda'
import { BrowserSearchField } from 'storfox-query-builder'

import * as API from '~/constants/api'

const getOptionValue = value => {
  const id = prop('id', value)
  const guid = prop('guid', value)
  const name = prop('name', value)

  return { id, guid, name }
}

function CountrySearchField (props) {
  return (
    <BrowserSearchField
      api={`${API.REGION_LIST}?type=country`}
      getOptionValue={getOptionValue}
      {...props}
    />
  )
}

export default CountrySearchField
