import React from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Button, Collapse, ListItem, styled } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

const TypographyStyled = styled(Typography, {
  shouldForwardProp: (propName) => propName !== 'isActive'
})({
  position: 'absolute',
  top: '5px',
  fontSize: '10px',
  color: '#bdb5b5'
})

const ButtonStyled = styled(Button, {
  shouldForwardProp: (propName) => propName !== 'isActive'
})(({ theme, isActive }) => ({
  padding: theme.spacing(2),
  paddingTop: 10,
  paddingBottom: 10,
  justifyContent: 'flex-start',
  textTransform: 'none',
  letterSpacing: 0,
  width: '100%',
  display: 'inline-flex',
  color: theme.palette.primary.contrastText,
  '&:hover': {
    background: theme.palette.primary.dark
  },
  ...(isActive && {
    background: theme.palette.primary.dark,
    position: 'relative',
    '&:before': {
      content: "''",
      position: 'absolute',
      left: 0,
      top: 'calc(50% - 12px)',
      width: 3,
      height: 24,
      background: 'white',
      borderRadius: '0 3px 3px 0'
    }
  })
}))

const ButtonLeafStyled = styled(Button, {
  shouldForwardProp: (propName) => propName !== 'isActive'
})(({ theme, isActive }) => ({
  padding: '5px 14px',
  justifyContent: 'flex-start',
  textTransform: 'none',
  letterSpacing: 0,
  width: '100%',
  color: theme.palette.primary.contrastText,
  fontWeight: theme.typography.fontWeightRegular,
  '&.depth-0': {
    paddingTop: 10,
    paddingBottom: 10,
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    color: theme.palette.primary.contrastText,
    '&:hover': {
      background: theme.palette.primary.dark
    }
  },
  '&.Mui-disabled': {
    color: theme.components.StorfoxSidebar.styleOverrides.disabled.color
  },
  '&:hover': {
    background: theme.palette.primary.dark
  },
  ...(isActive && {
    background: theme.palette.primary.dark,
    '&.depth-0': {
      padding: '10px 16px',
      position: 'relative',
      '&:before': {
        content: "''",
        position: 'absolute',
        left: 0,
        top: 'calc(50% - 12px)',
        width: 3,
        height: 24,
        background: 'white',
        borderRadius: '0 3px 3px 0'
      }
    },
    '&.Mui-disabled': {
      color: theme.components.StorfoxSidebar.styleOverrides.disabled.color
    }
  })
}))

const TitleStyled = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  width: '100%',
  fontWeight: theme.typography.fontWeightMedium
}))

const TitleLeafStyled = styled(Typography)(({ theme }) => ({
  position: 'relative',
  display: 'inline-block',
  '&:before': {
    content: "''",
    position: 'absolute',
    left: '-16px',
    top: 'calc(50% - 1px)',
    width: 7,
    height: 2,
    background: theme.palette.primary.contrastText
  },
  '&.depth-0': {
    textAlign: 'left',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontSize: 14,
    overflow: 'hidden',
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  }
}))

const ItemLeafStyled = styled(ListItem)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(1, 2),
  paddingTop: 6,
  paddingBottom: 6,
  '&.depth-0': {
    display: 'block',
    padding: 0
  }
}))

function NavItem ({
  title,
  href,
  depth,
  children,
  handleToggleMenuItem,
  open,
  enabled,
  icon: Icon,
  className,
  active,
  label: Label,
  beta = false,
  betaRightPx = 0,
  ...rest
}) {
  let paddingLeft = 16

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth
  }

  const style = { paddingLeft }

  if (children) {
    return (
      <ListItem
        {...rest}
        sx={{
          display: 'block',
          paddingTop: 0,
          paddingBottom: 0
        }}
        className={className}
        disableGutters={true}
        key={title}
      >
        <ButtonStyled
          isActive={open}
          onClick={() => handleToggleMenuItem(href)}
          style={style}
          disabled={!enabled}
        >
          {Icon && (
            <Box sx={{
              minWidth: 24,
              width: 24,
              display: 'flex',
              marginRight: theme => theme.spacing(1)
            }}>
              <Icon />
            </Box>
          )}
          <TitleStyled
            color="inherit"
            variant="body1"
          >
            {title}
          </TitleStyled>
          {beta && (
            <TypographyStyled sx={{ right: betaRightPx }} color="white">(Beta)</TypographyStyled>
          )}
          {open ? (
            <ExpandLessIcon
              color="inherit"
              sx={{
                marginLeft: 'auto',
                height: 16,
                width: 16
              }}
            />
          ) : (
            <ExpandMoreIcon
              color="inherit"
              sx={{
                marginLeft: 'auto',
                height: 16,
                width: 16
              }}
            />
          )}
        </ButtonStyled>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    )
  }

  return (
    <ItemLeafStyled
      {...rest}
      className={clsx(className, `depth-${depth}`)}
      disableGutters={true}
      key={title}
    >
      <ButtonLeafStyled
        isActive={active}
        className={`depth-${depth}`}
        component={Link}
        style={style}
        disabled={!enabled}
        onClick={() => href.includes('https') ? window.open(href, '_self') : null}
        to={href.includes('https') ? '' : href}
      >

        {Icon && (
          <Box sx={{
            minWidth: 24,
            width: 24,
            marginRight: theme => theme.spacing(1),
            display: 'flex'
          }}>
            <Icon />
          </Box>
        )}
        <TitleLeafStyled
          color="inherit"
          variant="body2"
          className={`depth-${depth}`}
        >
          {title}
        </TitleLeafStyled>
        {beta && (
          <TypographyStyled sx={{ right: betaRightPx, top: '-2px' }} color="white">(Beta)</TypographyStyled>
        )}
        {Label && (
          <Box
            component="span"
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: 'auto'
            }}
          >
            <Label />
          </Box>
        )}
      </ButtonLeafStyled>
    </ItemLeafStyled>
  )
}

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  label: PropTypes.any,
  open: PropTypes.bool,
  handleToggleMenuItem: PropTypes.func,
  title: PropTypes.string.isRequired,
  active: PropTypes.bool,
  enabled: PropTypes.bool,
  beta: PropTypes.bool,
  betaRightPx: PropTypes.string
}

NavItem.defaultProps = {
  open: false,
  handleToggleMenuItem: null,
}

export default NavItem
