import React from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import { useField } from 'react-final-form'
import { prop } from 'ramda'
import PropTypes from 'prop-types'

import SearchField from '~/components/Fields/SearchField'
import TextField from '~/components/Fields/TextField'
import { CardHeader } from '~/components/Cards'
import { useCompany } from '~/components/Profile'
import Hide from '~/components/Hide'
import * as API from '~/constants/api'
import { RETAILER } from '~/constants/companyTypes'

import LineItems from './LineItems'

import AdjustmentReasonSearchField from '../Fields/AdjustmentReasonSearchField'
import StockOwnerField from '../Fields/StockOwnerField'

const ADD = 'add'

function Adjustment ({ unitFieldMethods, adjustmentVariant }) {
  const { company } = useCompany()
  const companyType = prop('type', company)

  const adjustmentReasonField = useField('reason')
  const adjustmentReason = adjustmentReasonField.input.value

  const disabled = prop('operation', adjustmentReason) !== ADD
  const isRetailer = companyType === RETAILER
  const lg = isRetailer ? 4 : 3

  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12} lg={12}>
        <Card>
          <CardHeader title="Details" />
          <Divider />

          <CardContent>
            <Grid container={true} spacing={3}>
              <Grid item={true} xs={12} lg={lg}>
                <SearchField
                  data-cy="warehouse"
                  name="warehouse"
                  label="Warehouse"
                  api={API.ADJUSTMENT_WAREHOUSE_LIST}
                  ListboxProps={{ 'data-cy': 'warehouseList' }}
                  required={true}
                />
              </Grid>

              <Grid item={true} xs={12} lg={lg}>
                <AdjustmentReasonSearchField required={true} />
              </Grid>

              <Grid item={true} xs={12} lg={lg}>
                <TextField
                  data-cy="reference"
                  name="referenceId"
                  label="Reference ID"
                />
              </Grid>
              <Grid item={true} xs={12} lg={3}>
                <Hide hidden={isRetailer}>
                  <StockOwnerField disabled={disabled} ListboxProps={{ 'data-cy': 'ownerList' }} />
                </Hide>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item={true} xs={12}>
        <Card>
          <CardHeader title="Stocks" required={true} />
          <Divider />
          <LineItems
            unitFieldMethods={unitFieldMethods}
            adjustmentVariant={adjustmentVariant}
          />
        </Card>
      </Grid>

      <Grid item={true} xs={12} lg={6}>
        <Card>
          <CardHeader title="Notes" />
          <Divider />
          <CardContent>
            <TextField
              label="Notes"
              name="notes"
              multiline={true}
              minRows={8}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

Adjustment.propTypes = {
  unitFieldMethods: PropTypes.object.isRequired,
  adjustmentVariant: PropTypes.object.isRequired
}

export default Adjustment
