import React from 'react'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material'
import PropTypes from 'prop-types'
import Skeleton from '@mui/material/Skeleton'

import HtmlTooltip from '~/components/HtmlTooltip/HtmlTooltip'

export const BoxStyled = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '8px'
})

export const TitleStyled = styled(Typography)({
  color: '#546E7A',
  textTransform: 'uppercase',
  marginBottom: '8px',
  fontSize: '11px'
})

function OrderInformationCard ({ title, value, isLoading, chart, tooltip }) {
  return (
    <HtmlTooltip title={tooltip}>
      <Card sx={{ padding: '20px 16px' }}>
        <BoxStyled>
          {isLoading && (
            <Box sx={{ width: '70%' }}>
              <Skeleton sx={{ width: '50%', marginBottom: '19px' }} />
              <Skeleton />
            </Box>
          )}
          {!isLoading && (
            <Box>
              <TitleStyled variant="h6">{title}</TitleStyled>
              <Typography variant="h2">{value}</Typography>
            </Box>
          )}
        </BoxStyled>
        {chart}
      </Card>
    </HtmlTooltip>
  )
}

OrderInformationCard.propTypes = {
  title: PropTypes.string,
  tooltip: PropTypes.string,
  value: PropTypes.any,
  isLoading: PropTypes.bool,
  chart: PropTypes.node
}

export default OrderInformationCard
