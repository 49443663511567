import React from 'react'
import PropTypes from 'prop-types'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

import Tabs from '~/components/Tabs'
import { CUSTOMER_DETAIL_PATH } from '~/constants/routes'
import { CUSTOMER_DETAIL_TABS } from '~/constants/tabs'

const tabs = [
  { value: CUSTOMER_DETAIL_TABS.GENERAL, label: 'General' },
  { value: CUSTOMER_DETAIL_TABS.ADDRESS, label: 'Address' },
  { value: CUSTOMER_DETAIL_TABS.ORDERS, label: 'Orders' },
  { value: CUSTOMER_DETAIL_TABS.HISTORY, label: 'History' },
]

function CustomerDetailTabs ({ value }) {
  const navigate = useNavigate()
  const { guid } = useParams()
  const onChange = (event, tab) => {
    const redirect = generatePath(CUSTOMER_DETAIL_PATH, { guid, tab })

    navigate(redirect, { replace: true })
  }

  return (
    <Tabs
      tabs={tabs}
      value={value}
      onChange={onChange}
    />
  )
}

CustomerDetailTabs.propTypes = {
  value: PropTypes.string.isRequired
}

export default CustomerDetailTabs
