import React from 'react'
import { DateField as StorfoxDateField } from 'storfox-form-fields'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

function DateField ({ label, ...props }) {
  const { t } = useTranslation()
  return (
    <StorfoxDateField
      label={t(label)}
      {...props}
    />
  )
}

DateField.propTypes = {
  label: PropTypes.string
}

export default DateField
