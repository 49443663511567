import React, { startTransition, useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { useToken } from '~/components/Token'

import Context from './context'

import { useFilterList, useStorage } from '../../index'
import { FILTER_KEY } from '../../constants'

function FilterProvider ({ children }) {
  const [state, setState] = useState([])
  const filterList = useFilterList()
  const filterStorage = useStorage(FILTER_KEY)
  const { token } = useToken()
  const getFilterList = useCallback(filterList.getList, [])
  const setFilterStorage = useCallback(filterStorage.set, [])

  const value = { items: state, setItems: setState }

  useEffect(() => {
    if (token) {
      getFilterList()
        .then(({ results }) => {
          startTransition(() => setFilterStorage(results))
        })
    } else {
      startTransition(() => setFilterStorage(null))
    }
  }, [getFilterList, setFilterStorage, token])

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  )
}

FilterProvider.propTypes = {
  children: PropTypes.any.isRequired
}

export default FilterProvider
