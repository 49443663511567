import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import { useParams } from 'react-router-dom'

import Container, { Content, Header } from '~/components/Container'
import PageTitle from '~/components/PageTitle'
import { DiscardButton } from '~/components/Buttons'
import * as NAV from '~/constants/nav-titles'

import TplBillingStorageTable from './TplBillingStorageTable'
import TplBillingFulfillmentTable from './TplBillingFulfillmentTable'
import TplBillingPackingTable from './TplBillingPackingTable'
import TplBillingAdhocTable from './TplBillingAdhocTable'
import TplBillingReceivingTable from './TplBillingReceivingTable'

import { ADHOC_TYPE, FULFILLMENT_TYPE, PACKING_TYPE, RECEIVING_TYPE, STORAGE_TYPE } from '../../../constants/TplBilling'

function TplBillingItem ({ pageTitle, pageTitleLoading, isLoading, detail }) {
  const { type } = useParams()

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={pageTitle}
          pageTitleLoading={pageTitleLoading}
          parentTitle={NAV.TPL_BILLING}
          rightButton={(
            <>
              <DiscardButton />
            </>
          )}
        />
      </Header>
      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={12}>
            {type === STORAGE_TYPE && (
              <TplBillingStorageTable
                detail={detail}
                isLoading={isLoading}
              />
            )}
            {type === FULFILLMENT_TYPE && (
              <TplBillingFulfillmentTable
                detail={detail}
                isLoading={isLoading}
              />
            )}
            {type === PACKING_TYPE && (
              <TplBillingPackingTable
                detail={detail}
                isLoading={isLoading}
              />
            )}
            {type === ADHOC_TYPE && (
              <TplBillingAdhocTable
                detail={detail}
                isLoading={isLoading}
              />
            )}
            {type === RECEIVING_TYPE && (
              <TplBillingReceivingTable
                detail={detail}
                isLoading={isLoading}
              />
            )}
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

TplBillingItem.propTypes = {
  detail: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  pageTitle: PropTypes.object.isRequired,
  pageTitleLoading: PropTypes.bool.isRequired
}

export default TplBillingItem
