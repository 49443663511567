import React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

import Tabs from '~/components/Tabs'
import Divider from '~/components/Divider'
import { CONTRACT_UPDATE_PATH } from '~/constants/routes'
import { CONTRACT_UPDATE_TABS } from '~/constants/tabs'

const tabs = [
  { value: CONTRACT_UPDATE_TABS.GENERAL, label: 'General' },
  { value: CONTRACT_UPDATE_TABS.INVOICE, label: 'Invoice' }
]

function ContractUpdateTabs () {
  const navigate = useNavigate()
  const { id, tab } = useParams()

  const onChange = (event, tab) => {
    const redirect = generatePath(CONTRACT_UPDATE_PATH, { id, tab })
    navigate(redirect, { replace: true })
  }

  return (
    <>
      <Tabs
        tabs={tabs}
        value={tab}
        onChange={onChange}
      />
      <Divider />
    </>
  )
}

export default ContractUpdateTabs
