import { useLimitedCountList, useCreate } from 'storfox-api-hooks'
import useAllSearchParams from 'storfox-route-hooks/src/useAllSearchParams'

import { queryToParams } from '~/utils'
import * as API from '~/constants/api'

import { PurchaseFilterSerializer } from '../../serializers/Purchase'

export const usePurchaseList = () => {
  const { select, ...searchParams } = useAllSearchParams()
  const params = queryToParams(PurchaseFilterSerializer, searchParams)

  const { getList, ...state } = useLimitedCountList(API.PURCHASE_REPORT_LIST, { ...params })
  return { ...state, getList: () => getList(params) }
}

export const usePurchaseReportGenerate = () => {
  const { create, ...state } = useCreate(API.PURCHASE_REPORT_CREATE)
  return { ...state, generate: create }
}
