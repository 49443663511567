import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import PutawayListContainer from './containers/PutawayListContainer'
import PutawayDetailContainer from './containers/PutawayDetailContainer'
import PutawayCreateContainer from './containers/PutawayCreateContainer'
import PutawayPutContainer from './containers/PutawayPutContainer'

export default () => [
  {
    layout: AuthLayout,
    path: ROUTES.V2_PUTAWAY_LIST_PATH,
    component: PutawayListContainer,
  },
  {
    layout: AuthLayout,
    path: ROUTES.V2_PUTAWAY_CREATE_PATH,
    component: PutawayCreateContainer,
  },
  {
    layout: AuthLayout,
    path: ROUTES.V2_PUTAWAY_DETAIL_PATH,
    component: PutawayDetailContainer,
  },
  {
    layout: AuthLayout,
    path: ROUTES.V2_PUTAWAY_UPDATE_PATH,
    component: PutawayPutContainer,
  }
]
