import React from 'react'
import { Filter } from 'storfox-filter'
import Grid from '@mui/material/Grid'
import { prop } from 'ramda'
import { DateRangeField } from 'storfox-form-fields'

import { escapeAtob, getSeparatedDateRange, unescapeBtoa } from '~/utils'
import Accordion from '~/components/Accordion'

import UserWarehouseField from './UserWarehouseField'
import UserStatusField from './UserStatusField'
import UserRoleField from './UserRoleField'

export const toParams = values => {
  const status = prop('status', values)
  const warehouses = unescapeBtoa(prop('warehouses', values))
  const roles = unescapeBtoa(prop('roles', values))
  const [updatedAtStart, updatedAtEnd] = getSeparatedDateRange(prop('updatedAt', values))

  return {
    roles,
    status,
    warehouses,
    updatedAtStart,
    updatedAtEnd
  }
}

export const toValues = params => {
  const status = prop('status', params)
  const warehouses = escapeAtob(prop('warehouses', params))
  const roles = escapeAtob(prop('roles', params))
  const updatedAt = [prop('updatedAtStart', params), prop('updatedAtEnd', params)]

  return {
    status,
    warehouses,
    roles,
    updatedAt
  }
}

export const name = 'user'
export const transformer = { toParams, toValues }

export const userFilterOptions = {
  name,
  transformer
}

function UserFilterForm (props) {
  return (
    <Filter {...props}>
      <Accordion title="General" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <UserStatusField />
          </Grid>
          <Grid item={true} xs={12}>
            <UserWarehouseField />
          </Grid>
          <Grid item={true} xs={12}>
            <UserRoleField />
          </Grid>
        </Grid>
      </Accordion>
      <Accordion title="Date">
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <DateRangeField name="updatedAt" />
          </Grid>
        </Grid>
      </Accordion>
    </Filter>
  )
}

export default UserFilterForm
