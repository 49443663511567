import React from 'react'
import PropTypes from 'prop-types'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { useTranslation } from 'react-i18next'

import { MoreButton } from '~/components/Buttons'

function More ({ onDelete }) {
  const { t } = useTranslation()

  return (
    <MoreButton>
      {onClose => (
        <List>
          <ListItem
            button={true}
            onClick={onDelete}
            data-cy="delete"
          >
            <ListItemText primary={t('Delete')} />
          </ListItem>
        </List>
      )}
    </MoreButton>
  )
}

More.propTypes = {
  onDelete: PropTypes.func.isRequired,
}

export default More
