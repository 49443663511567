import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import { Divider } from '@mui/material'
import CardContent from '@mui/material/CardContent'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import DateField from '~/components/Fields/DateField'
import TextField from '~/components/Fields/TextField'
import WarehouseSearchField from '~/components/Fields/WarehouseSearchField'
import { CardHeader } from '~/components/Cards'
import * as API from '~/constants/api'

import InvoiceSummary from '../components/InvoiceSummary'
import LineItems from '../components/LineItems'

function TransferContent ({ transferOrderVariant }) {
  const { t } = useTranslation()

  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12}>
        <Box>
          <Card>
            <CardHeader title={t('Details')} />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12} lg={3}>
                  <WarehouseSearchField
                    label={t('From warehouse')}
                    name="fromWarehouse"
                    api={API.TRANSFER_TO_WAREHOUSE}
                    required={true}
                  />
                </Grid>
                <Grid item={true} xs={12} lg={3}>
                  <WarehouseSearchField
                    label="To warehouse"
                    name="toWarehouse"
                    api={API.TRANSFER_TO_WAREHOUSE}
                    required={true}
                  />
                </Grid>
                <Grid item={true} xs={12} lg={3}>
                  <DateField
                    data-cy="transferDate"
                    label={t('Transfer date')}
                    name="transferDate"
                  />
                </Grid>
                <Grid item={true} xs={12} lg={3}>
                  <DateField
                    data-cy="expectedDate"
                    label={t('Expected Date')}
                    name="expectedDate"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>

      </Grid>

      <Grid item={true} xs={12}>
        <Card>
          <CardHeader title={t('Stock')} required={true} />
          <Divider />
          <LineItems transferOrderVariant={transferOrderVariant} />
        </Card>
      </Grid>
      <Grid item={true} lg={6} xs={12}>
        <Card style={{ height:'100%' }}>
          <CardHeader title={t('Transfer summary')} />
          <Divider />
          <InvoiceSummary />
        </Card>
      </Grid>
      <Grid item={true} lg={6} xs={12}>
        <Card>
          <CardHeader title={t('Notes')} />
          <Divider />
          <CardContent>
            <Grid container={true} spacing={3}>
              <Grid item={true} xs={12}>
                <TextField
                  label="Notes"
                  name="notes"
                  data-cy="notes"
                  multiline={true}
                  minRows={11}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

TransferContent.propTypes = {
  transferOrderVariant: PropTypes.object.isRequired
}

export default TransferContent
