import React from 'react'
import { __, all, assocPath, filter, includes, pathOr, pluck, prop, map, reduce, toPairs } from 'ramda'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel, { formControlLabelClasses } from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Grid from '@mui/material/Grid'
import { useField } from 'react-final-form'
import Box from '@mui/material/Box'
import { styled } from '@mui/material'

import { CardHeader } from '~/components/Cards'

const FormControlLabelHeaderStyled = styled(FormControlLabel)(({ theme }) => ({
  [`& .${formControlLabelClasses.label}`]: {
    fontSize: 14,
    fontWeight: 500,
    textTransform: 'capitalize',
  }
}))

const FormControlLabelStyled = styled(FormControlLabel)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  [`& .${formControlLabelClasses.label}`]: {
    textTransform: 'capitalize'
  }
}))

const getGroupedPermission = reduce((result, item) => {
  const category = prop('category', item)
  const entity = prop('entity', item)

  const items = pathOr([], [category, entity], result)

  return assocPath([category, entity], [...items, item], result)
}, {})

const getEnabledPerms = filter(prop('enabled'))

function RoleUpdateFormPermission () {
  const permissionsField = useField('permissions')
  const permissionsFieldValue = permissionsField.input.value || []
  const permissionsFieldChange = permissionsField.input.onChange

  const list = getGroupedPermission(permissionsFieldValue)
  const categories = toPairs(list)

  const hasCheckedMultiple = perms => {
    const activePerms = getEnabledPerms(permissionsFieldValue)

    const ids = pluck('id', perms)
    const valueIds = pluck('id', activePerms)

    return all(includes(__, valueIds), ids)
  }

  const handleChangeMultiple = (perms, enabled) => {
    const ids = pluck('id', perms)
    const newValue = map(
      item => includes(item.id, ids) ? ({ ...item, enabled }) : item,
      permissionsFieldValue
    )
    permissionsFieldChange(newValue)
  }

  const handleChangeSingle = (perm, enabled) => {
    const id = prop('id', perm)
    const newValue = map(
      item => item.id === id ? ({ ...item, enabled }) : item,
      permissionsFieldValue
    )
    permissionsFieldChange(newValue)
  }

  return (
    <>
      {categories.map(([title, values], index) => {
        const entities = toPairs(values)
        return (
          <Box mb={3} key={index}>
            <Card>
              <CardHeader sx={{ textTransform: 'capitalize' }} title={title} />
              <Divider />
              <CardContent>
                <Grid container={true} spacing={3}>
                  {entities.map(([label, perms], index) => {
                    return (
                      <Grid key={index} item={true} xs={12} lg={3}>
                        <FormControl component="fieldset">
                          <FormGroup>
                            <FormControlLabelHeaderStyled
                              label={label}
                              control={
                                <Checkbox
                                  color="secondary"
                                  checked={hasCheckedMultiple(perms)}
                                  onChange={event => handleChangeMultiple(perms, event.target.checked)}
                                />
                              }
                            />
                            {perms.map((perm, index) => (
                              <FormControlLabelStyled
                                key={index}
                                label={perm.name}
                                control={
                                  <Checkbox
                                    color="secondary"
                                    checked={perm.enabled}
                                    onChange={event => handleChangeSingle(perm, event.target.checked)}
                                  />
                                }
                              />
                            ))}
                          </FormGroup>
                        </FormControl>
                      </Grid>
                    )
                  })}
                </Grid>
              </CardContent>
            </Card>
          </Box>
        )
      })}
    </>
  )
}

export default RoleUpdateFormPermission
