import React from 'react'
import PropTypes from 'prop-types'

import Status from '~/components/Status'

const DRAFT = 'draft'
const UNPAID = 'unpaid'
const PAID = 'paid'

const COLOR = {
  [UNPAID]: 'warning',
  [DRAFT]: 'warning',
  [PAID]: 'success',
}

function InvoiceStatus ({ value }) {
  return (
    <Status label={value} color={COLOR[value]} />
  )
}

InvoiceStatus.propTypes = {
  value: PropTypes.string
}

export default InvoiceStatus
