import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { path, pathOr, prop, propOr } from 'ramda'
import { useField } from 'react-final-form'
import PropTypes from 'prop-types'

import TextField from '~/components/Fields/TextField'
import MultiImageUploadField from '~/components/ImageUpload/MultiImageUpload'
import TextLabelLoader from '~/components/TextLabelLoader'
import MoneyField from '~/components/Fields/MoneyField'
import { Button } from '~/components/Buttons'
import useDialog from '~/hooks/useDialog'
import { CardHeader } from '~/components/Cards'
import SlugField from '~/components/Fields/SlugField'
import ListingStatus from '~/components/Statuses/ListingStatus'

import ConditionModal from './Condition/ConditionModal'

function ListingVariantUpdate ({ isLoading, initialValues }) {
  const { open, handleOpen, handleClose } = useDialog()

  const conditionField = useField('conditions')
  const conditionValue = conditionField.input.value
  const condition = propOr('', 'code', conditionValue)
  const sku = prop('sku', initialValues)
  const channel = path(['channel', 'name'], initialValues)
  const status = pathOr('', ['variant', 'status'], initialValues)
  const conditionPrices = path(['variant', 'conditionPrices'], initialValues)

  return (
    <Box mt={3} mb={3}>
      <Grid container={true} spacing={3}>
        <ConditionModal
          isLoading={isLoading}
          onClose={handleClose}
          open={open}
        />

        <Grid item={true} xs={12} lg={8}>
          <Box mb={3}>
            <Card>
              <CardHeader title="Details" />
              <Divider />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={12}>
                    <TextField
                      data-cy="name"
                      name="variant.name"
                      label="Name"
                    />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <SlugField
                      data-cy="slug"
                      name="variant.slug"
                      label="Slug"
                    />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <TextField
                      data-cy="description"
                      name="variant.description"
                      label="Description"
                      multiline={true}
                      rows="6"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
          <Box mb={3}>
            <Card>
              <CardHeader title="Image" />
              <Divider />
              <CardContent>
                <MultiImageUploadField name="variant.images" />
              </CardContent>
            </Card>
          </Box>
        </Grid>
        <Grid item={true} xs={12} lg={4}>
          <Box mb={3}>
            <Card>
              <CardHeader title="Information" />
              <Divider />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={12} lg={12}>
                    <TextLabelLoader label="SKU">
                      {sku}
                    </TextLabelLoader>
                  </Grid>

                  <Grid item={true} xs={12} lg={12}>
                    <TextLabelLoader label="Channel">
                      {channel}
                    </TextLabelLoader>
                  </Grid>
                  <Grid item={true} xs={12} lg={12}>
                    <TextLabelLoader label="Status">
                      <ListingStatus value={status} />
                    </TextLabelLoader>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
          <Box mb={3}>
            <Card>
              <CardHeader title={(
                <>
                  Pricing <br />
                  <Typography variant="caption">Default condition: {condition}</Typography>
                </>
              )} />
              <Divider />
              <CardContent>
                <Grid container={true} spacing={3}>

                  <Grid item={true} xs={12}>
                    <MoneyField
                      data-cy="retailPrice"
                      name="variant.conditionPrices[0].retailPrice"
                      label="Retail price"
                    />
                  </Grid>

                  <Grid item={true} xs={12}>
                    <MoneyField
                      data-cy="maximumRetailPrice"
                      name="variant.conditionPrices[0].maximumRetailPrice"
                      label="Maximum Retail price"
                    />
                  </Grid>
                  {conditionPrices && (
                    <Grid item={true} xs={12}>
                      <Box align="center">
                        <Button
                          onClick={handleOpen}
                        >
                          Add pricing for conditions
                        </Button>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

ListingVariantUpdate.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired
}
export default ListingVariantUpdate
