import React from 'react'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box'
import TableContainer from '@mui/material/TableContainer'
import PropTypes from 'prop-types'
import { map, path, prop, reduce } from 'ramda'
import Typography from '@mui/material/Typography'
import { IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import InfoIcon from '@mui/icons-material/Info'

import { CardHeader } from '~/components/Cards'
import { emptyArray } from '~/constants/empty'
import { getFormattedDate } from '~/utils'
import HtmlTooltip from '~/components/HtmlTooltip/HtmlTooltip'

import Chart from './Chart'

import { generateDays, getLabelsFromDateRange } from '../../../utils'

const getData = (items, days) => {
  const keyValue = reduce((acc, item) => {
    return { ...acc, [prop('date', item)]: item }
  }, {}, items)

  const inbound = map(day => {
    const date = getFormattedDate(day)

    return path([date, 'inbound'], keyValue) || 0
  }, days)

  const outbound = map(day => {
    const date = getFormattedDate(day)

    return path([date, 'outbound'], keyValue) || 0
  }, days)

  return { inbound, outbound }
}

function StockInOut ({ items, dateRange }) {
  const { t } = useTranslation()
  const days = generateDays(dateRange)
  const labels = getLabelsFromDateRange(dateRange) || emptyArray
  const data = getData(items, days)

  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader
        title={
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h4">{t('Inbound & Outbound Stock')}</Typography>
            <HtmlTooltip title="Quantity of stock items arriving and leaving the inventory for stock distribution.">
              <IconButton size="small">
                <InfoIcon sx={{ color: '#BDBDBD' }} />
              </IconButton>
            </HtmlTooltip>
          </Box>
        }
      />
      <Divider />
      <CardContent sx={{ p: 0 }}>
        <TableContainer>
          <Box sx={{ minWidth: 700 }}>
            <Chart
              labels={labels}
              data={data}
              sx={{
                padding: theme => theme.spacing(4, 2, 0, 2),
                height: 400
              }}
            />
          </Box>
        </TableContainer>
      </CardContent>
    </Card>
  )
}

StockInOut.propTypes = {
  dateRange: PropTypes.array,
  items: PropTypes.array,
}

export default StockInOut
