import React from 'react'
import { Grid } from '@mui/material'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import Paper from '@mui/material/Paper'
import TableHead from '@mui/material/TableHead'
import TableCell from '@mui/material/TableCell'
import { useTranslation } from 'react-i18next'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import Link from '@mui/material/Link'
import PropTypes from 'prop-types'
import { path, pathOr, prop, propOr } from 'ramda'
import { generatePath, Link as RouterLink } from 'react-router-dom'
import Typography from '@mui/material/Typography'

import { tableDateFormat, getCurrencySymbol } from '~/utils'
import { useCompany } from '~/components/Profile'
import * as ROUTES from '~/constants/routes'
import { PURCHASE_ORDER_DETAIL_TABS, SUPPLIER_DETAIL_TABS } from '~/constants/tabs'

function VariantSuppliersList ({ list }) {
  const { company } = useCompany()
  const currency = prop('currency', company)
  const symbol = getCurrencySymbol(currency)

  const isLoading = prop('isLoading', list)
  const rows = prop('results', list)

  const { t } = useTranslation()
  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('Purchase order')}</TableCell>
                <TableCell>{t('Supplier')}</TableCell>
                <TableCell>{t('Supplier price')}</TableCell>
                <TableCell>{t('Created date')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoading && (
              <>
                {rows.map(row => {
                  const id = prop('id', row)
                  const supplierName = path(['supplier', 'companyName'], row)
                  const supplierGuid = pathOr(0, ['supplier', 'guid'], row)
                  const supplyPrice = prop('supplyPrice', row)
                  const purchaseGuid = propOr(0, 'purchaseGuid', row)
                  const purchaseNumber = prop('purchaseNumber', row)
                  const createdAt = tableDateFormat(prop('createdAt', row))

                  const purchaseParams = { guid: purchaseGuid, tab: PURCHASE_ORDER_DETAIL_TABS.GENERAL }
                  const supplierParams = { guid: supplierGuid, tab: SUPPLIER_DETAIL_TABS.GENERAL }

                  const purchaseLink = generatePath(ROUTES.PURCHASE_ORDER_DETAIL_PATH, purchaseParams)
                  const supplierLink = generatePath(ROUTES.SUPPLIER_DETAIL_PATH, supplierParams)

                  return (
                    <TableRow key={id}>
                      <TableCell>
                        {purchaseGuid ? (
                          <Link
                            component={RouterLink}
                            to={purchaseLink}
                            underline="always"
                          >
                            {purchaseNumber}
                          </Link>
                        ) : (
                          <Typography>N/A</Typography>
                        )}
                      </TableCell>
                      <TableCell>
                        <Link
                          component={RouterLink}
                          to={supplierLink}
                          underline="always"
                        >
                          {supplierName}
                        </Link>
                      </TableCell>
                      <TableCell>{supplyPrice} {symbol}</TableCell>
                      <TableCell>{createdAt}</TableCell>
                    </TableRow>
                  )
                })}
              </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}
VariantSuppliersList.propTypes = {
  list: PropTypes.object.isRequired
}
export default VariantSuppliersList
