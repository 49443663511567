import { useDeepCompareEffect } from 'storfox-api-hooks'

import { useTableHeight } from '../TableHeight'

const getMinHeight = () => {
  const height = window.innerHeight

  if (height > 1440) {
    return 1235
  }

  if (height > 1080) {
    return 875
  }

  if (height > 960) {
    return 755
  }

  if (height > 900) {
    return 695
  }

  if (height > 800) {
    return 595
  }

  if (height > 720) {
    return 515
  }

  if (height > 650) {
    return 420
  }

  if (height > 575) {
    return 340
  }

  return 270
}

function useCalculatedHeight (tableRootId, space) {
  const { tableHeight, setTableHeight } = useTableHeight()

  const getRootBoxHeight = () => {
    const minHeight = getMinHeight()

    const element = document.querySelector(`#${tableRootId}`)
    const position = element && element.getBoundingClientRect()
    const fullHeight = position ? window.innerHeight - position.y : null
    const bottomSpace = space ? 0 : 25
    const calculatedHeight = fullHeight - bottomSpace
    return calculatedHeight > minHeight ? calculatedHeight : minHeight
  }

  useDeepCompareEffect(() => {
    setTableHeight(getRootBoxHeight())
  }, [])

  const calculatedTableHeight = tableHeight || getRootBoxHeight()

  return { calculatedTableHeight, tableHeight }
}

export default useCalculatedHeight
