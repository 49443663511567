import React, { useCallback, useEffect } from 'react'
import { useField } from 'react-final-form'
import PropTypes from 'prop-types'
import { pathOr } from 'ramda'

import TextField from './TextField'

function TrackingNumber (props) {
  const {
    name = 'trackingNumber',
    integrationName,
    serviceName,
    disabled,
    ...rest
  } = props

  const field = useField(name)
  const handleChange = useCallback(field.input.onChange, [])

  const methodField = useField(integrationName)
  const methodValue = pathOr('', ['input', 'value', 'id'], methodField)

  const serviceField = useField(serviceName)
  const serviceValue = pathOr('', ['input', 'value', 'id'], serviceField)

  useEffect(() => {
    if (field.meta.dirty && (methodValue || serviceValue)) {
      handleChange('')
    }
  }, [field.meta.dirty, handleChange, methodValue, serviceValue])

  return (
    <TextField
      data-cy={name}
      name={name}
      label="Tracking number"
      disabled={Boolean(methodValue || serviceValue || disabled)}
      {...rest}
    />
  )
}

TrackingNumber.propTypes = {
  name: PropTypes.string,
  integrationName: PropTypes.string.isRequired,
  serviceName: PropTypes.string.isRequired,
  disabled: PropTypes.bool
}

export default TrackingNumber
