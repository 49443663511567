import React from 'react'
import TableRow from '@mui/material/TableRow'
import PropTypes from 'prop-types'
import { styled } from '@mui/material'

const TableRowStyled = styled(TableRow)(({ theme }) => ({
  cursor: 'pointer',
  '&:hover': {
    background: theme.palette.hover.color
  },
  '& > td': {
    whiteSpace: 'nowrap'
  }
}))

function HoverableRow ({ children, ...props }) {
  return (
    <TableRowStyled {...props}>
      {children}
    </TableRowStyled>
  )
}

HoverableRow.propTypes = {
  children: PropTypes.array.isRequired
}

export default HoverableRow
