import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { map, prop } from 'ramda'
import { generatePath } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import TableContainer from '@mui/material/TableContainer'

import { MoreListButton, NewButton } from '~/components/Buttons'
import Container, { Content, Header } from '~/components/Container'
import PageTitle from '~/components/PageTitle'
import { Table, TableHeader } from '~/components/Table'
import TextOverflow from '~/components/TextOverflow'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import TableLink from '~/components/Link'

import BulkActionButton from './BulkActionButton'
import RuleStatus from './RuleStatus'

import { EVENT_NAMES } from '../../../constants/AutomationRule'

const getDetailPath = guid => {
  return generatePath(ROUTES.AUTOMATION_UPDATE_PATH, { guid })
}

export const AUTOMATION_COLUMNS = [
  {
    width: 300,
    headerName: 'Title',
    field: 'title',
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        <TextOverflow selfTooltip={true} lines={1}>{value}</TextOverflow>
      </TableLink>
    )
  },
  {
    width: 200,
    headerName: 'Type',
    field: 'type'
  },
  {
    width: 200,
    headerName: 'Status',
    field: 'status',
    renderCell: ({ value }) => (
      <RuleStatus value={value} />
    )
  },
  {
    width: 300,
    headerName: 'Description',
    field: 'description',
    renderCell: ({ value }) => (
      <TextOverflow lines={2} selfTooltip={true}>{value}</TextOverflow>
    )
  }
]

const getRowsFromResults = map(item => {
  const guid = prop('guid', item)
  const title = prop('title', item)
  const status = prop('status', item)
  const event = prop('event', item)
  const type = EVENT_NAMES[event]
  const description = prop('description', item)
  const link = getDetailPath(guid)

  return {
    guid,
    title,
    type,
    status,
    description,
    link
  }
})

function AutomationRuleTable (props) {
  const { pageTitle, ordering, automationRuleList, onListRefetch, onAutomationRulesDelete, bulkActionLoading } = props
  const [tableRef, setTableRef] = useState(null)
  const results = prop('results', automationRuleList)
  const isLoading = prop('isLoading', automationRuleList)
  const count = prop('count', automationRuleList)
  const columns = prop('filteredColumns', ordering)

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={pageTitle}
          parentTitle={NAV.CONFIGURATION}
          rightButton={(
            <>
              <BulkActionButton onAutomationRulesDelete={onAutomationRulesDelete} loading={bulkActionLoading} />
              <NewButton path={ROUTES.AUTOMATION_CREATE_PATH} />
            </>
          )}
        />
      </Header>
      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={12}>
            <TableContainer>
              <TableHeader
                orderingOpen={ordering.handleOpen}
                ordering={ordering}
              >
                <MoreListButton tableRef={tableRef} />
              </TableHeader>
              <Table
                count={count}
                columns={columns}
                isLoading={isLoading}
                getRowsFromResults={getRowsFromResults}
                results={results}
                primaryKey="guid"
                pinnedColumns={['title']}
                checkboxSelection={true}
                ordering={ordering}
                onRefetch={onListRefetch}
                setTableRef={setTableRef}
              />
            </TableContainer>
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

AutomationRuleTable.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  ordering: PropTypes.object.isRequired,
  automationRuleList: PropTypes.object.isRequired,
  onAutomationRulesDelete: PropTypes.func.isRequired,
  onListRefetch: PropTypes.func.isRequired,
  bulkActionLoading: PropTypes.bool
}

export default AutomationRuleTable
