import React from 'react'
import Grid from '@mui/material/Grid'
import { find, pipe, prop, propEq, propOr } from 'ramda'
import { isProd } from 'storfox-api-hooks'
import { styled } from '@mui/material'

import PageTitle from '~/components/PageTitle'
import { Content } from '~/components/Container'
import { useCompany } from '~/components/Profile'
import * as NAV from '~/constants/nav-titles'

import MenuItem from './MenuItem'

import { menuItems } from '../../constants/'

const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  listStyle: 'none',
  flex: '0 0 33.333333%',
  [theme.breakpoints.down('md')]: {
    flex: '0 0 50%'
  },
  [theme.breakpoints.down('sm')]: {
    flex: '0 0 100%'
  }
}))

const getReducedMenuItems = companyType => {
  return menuItems.reduce((acc, item) => {
    const disabled = pipe(
      propOr([], 'companyTypes'),
      find(propEq('companyType', companyType)),
      disabled => prop('disabled', item) || disabled
    )(item)

    if (!isProd) {
      return [...acc, { ...item, disabled }]
    }

    if (isProd && !disabled) {
      return [...acc, { ...item, disabled }]
    }

    return acc
  }, []
  )
}
function Configuration () {
  const { company } = useCompany()
  const companyType = prop('type', company)

  const reducedMenuItems = getReducedMenuItems(companyType)

  return (
    <Content>
      <PageTitle
        pageTitle="Configuration"
        parentTitle={NAV.SETTINGS}
      />
      <Grid container={true} spacing={3} sx={{ mt: 1 }} alignItems="stretch">
        {reducedMenuItems.map(item => (
          <Grid key={item.title} item={true} xs={12} sm={6} md={4} lg={3}>
            <MenuItemStyled
              {...item}
              disabled={prop('disabled', item)}
            />
          </Grid>
        ))}
      </Grid>
    </Content>

  )
}

export default Configuration
