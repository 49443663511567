import { useAllSearchParams } from 'storfox-route-hooks'
import { sprintf } from 'sprintf-js'
import { useDetail, useLimitedCountList, useUpdate } from 'storfox-api-hooks'

import { queryToParams } from '~/utils'
import * as API from '~/constants/api'

import { ListingFilterSerializer } from '../serializers/index'

export const useListingList = () => {
  const searchParams = useAllSearchParams()
  const params = queryToParams(ListingFilterSerializer, searchParams, 'guid')
  const { getList, ...state } = useLimitedCountList(API.LISTING_LIST, params)
  return { ...state, getList: () => getList(params) }
}

export const useListingPublish = () => {
  return useUpdate(API.LISTING_PUBLISH)
}

export const useListingReject = () => {
  return useUpdate(API.LISTING_REJECT)
}

export const useListingUnpublish = () => {
  return useUpdate(API.LISTING_UNPUBLISH)
}

export const useListingPause = () => {
  return useUpdate(API.LISTING_PAUSE)
}

export const useListingDetail = guid => {
  const url = sprintf(API.LISTING_DETAIL, guid)
  return useDetail(url)
}

export const useListingUpdate = guid => {
  const url = sprintf(API.LISTING_UPDATE, guid)
  return useUpdate(url)
}
