import React from 'react'
import PropTypes from 'prop-types'
import { useAllSearchParams, useRoute } from 'storfox-route-hooks'

import useDialog from '~/hooks/useDialog'

import PackingTableForm from './PackingTableForm'

import { ACTIONS } from '../../constants'
import ContainerBarcodeScanDialog from '../ContainerBarcodeScanDialog'

function PackingList (props) {
  const {
    pageTitle,
    packingList,
    filter,
    ordering,
    disabled,
    onPackerChange,
    initialTableValues,
    onListRefetch,
    onBarcodeScan,
    onSubmit,
    bulkActionLoading
  } = props

  const { action } = useAllSearchParams()
  const { removeParams } = useRoute()
  const containerBarcodeScanDialog = useDialog()

  const handleClose = () => {
    removeParams('action')
    containerBarcodeScanDialog.handleClose()
  }
  return (
    <>
      <PackingTableForm
        pageTitle={pageTitle}
        packingList={packingList}
        filter={filter}
        ordering={ordering}
        disabled={disabled}
        onRefetch={onListRefetch}
        initialValues={initialTableValues}
        onPackerChange={onPackerChange}
        onContainerScanDialogOpen={containerBarcodeScanDialog.handleOpen}
        onSubmit={onSubmit}
        bulkActionLoading={bulkActionLoading}
      />

      <ContainerBarcodeScanDialog
        onSubmit={onBarcodeScan}
        open={containerBarcodeScanDialog.open || action === ACTIONS.OPEN_SCAN}
        initialValues={{ open: containerBarcodeScanDialog.open }}
        handleClose={handleClose}
      />
    </>
  )
}

PackingList.propTypes = {
  disabled: PropTypes.bool.isRequired,
  filter: PropTypes.object.isRequired,
  onBarcodeScan: PropTypes.func.isRequired,
  onPackerChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  ordering: PropTypes.object.isRequired,
  initialTableValues: PropTypes.object.isRequired,
  onListRefetch: PropTypes.func.isRequired,
  packingList: PropTypes.object,
  pageTitle: PropTypes.string,
  bulkActionLoading: PropTypes.bool
}

export default PackingList
