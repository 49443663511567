import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'

const RootStyled = styled(Box)(({ theme }) => ({
  maxWidth: 'calc(100% - 240px)',
  [theme.breakpoints.down('lg')]: {
    maxWidth: 'calc(100% - 240px)'
  }
}))

function TableHeaderLeft ({ children }) {
  return (
    <RootStyled>
      {children}
    </RootStyled>
  )
}

TableHeaderLeft.propTypes = {
  children: PropTypes.node
}

export default TableHeaderLeft
