import React from 'react'
import { useSnackbar } from 'storfox-snackbar'
import { generatePath, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { path, prop } from 'ramda'
import { useAllSearchParams } from 'storfox-route-hooks'
import { useDeepCompareEffect, isProd } from 'storfox-api-hooks'
import useRequest from 'storfox-api-hooks/src/useRequest'

import { DashboardLayout } from '~/components/Layouts'
import { ACTIVE } from '~/components/Fields/StatusField'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import { INTEGRATION_TABS } from '~/constants/tabs'
import useMessages from '~/hooks/useMessages'
import * as API from '~/constants/api'

import { useZidCreate } from '../../hooks'
import ZidIntegrationCreateForm from '../../components/Zid/ZidIntegrationCreateForm'

function ZidIntegrationCreateContainer () {
  const { t } = useTranslation()
  const request = useRequest()
  const params = useAllSearchParams()

  const zidCreate = useZidCreate()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const messages = useMessages()

  const title = 'New Integration'
  const appGuid = prop('appGuid', params)
  const code = prop('code', params)

  useDeepCompareEffect(() => {
    if (!code) {
      request.get(`${API.ZID_CLIENT_ID_DETAIL}${appGuid}/`).then((res) => {
        const clientId = path(['data', 'result', 'credential', 'clientId'], res)
        const envUrl = isProd ? 'app' : 'app-dev'
        const redirectUrl = `https://${envUrl}.storfox.com/settings/integration/create/zid/?appGuid=${appGuid}`
        const queryParams = `?client_id=${clientId}&redirect_uri=${redirectUrl}&response_type=code`
        window.open(`https://oauth.zid.sa/oauth/authorize${queryParams}`, '_self')
      })
    }
  }, [code, appGuid])

  const handleSubmit = formValues =>
    zidCreate
      .create({ ...formValues, app: { guid: appGuid }, code })
      .then(({ result }) => {
        const guid = prop('guid', result)
        const type = prop('type', result)
        const params = { guid, type, tab: INTEGRATION_TABS.GENERAL }
        const detailPath = generatePath(ROUTES.INTEGRATION_UPDATE_PATH, params)
        navigate(detailPath)
      })
      .then(() => snackbar({ message: messages.CREATE_SUCCESS }))

  const isLoading = zidCreate.isLoading

  const initialValues = {
    status: ACTIVE,
    type: 'zid'
  }

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={t(title)}
      isLoading={zidCreate.isLoading}
      activeNav={NAV.INTEGRATION}
      breadcrumbs={breadcrumbs}
    >
      <ZidIntegrationCreateForm
        onSubmit={handleSubmit}
        initialValues={initialValues}
        pageTitle={t(title)}
        parentTitle={t(NAV.SETTINGS)}
        isLoading={isLoading}
      />
    </DashboardLayout>
  )
}

export default ZidIntegrationCreateContainer
