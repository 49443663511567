import { colors } from '@mui/material'

export default {
  styleOverrides: {
    colorPrimary: {
      '&.Mui-checked': {
        color: colors.blue[200],
        '& + $track': {
          backgroundColor: colors.blue[400]
        }
      }
    }
  }
}
