import React from 'react'
import DialogContent from '@mui/material/DialogContent'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import { find, length } from 'ramda'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import DialogTitle from '~/components/DialogTitle'
import { Button } from '~/components/Buttons'
import SelectableBox from '~/components/SelectableBox'
import TextOverflow from '~/components/TextOverflow'

import OrderSplitSkeleton from './OrderSplitSkeleton'
import OrderSplitInstruction from './OrderSplitInstruction'

function OrderSplitDialog (props) {
  const {
    open,
    onClose,
    selectedWarehouseGuids,
    onWarehouseSelect,
    onSubmit,
    isLoading,
    warehouseList
  } = props

  const disabled = !length(selectedWarehouseGuids) || isLoading
  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth={true}
      onClose={onClose}
    >
      <DialogTitle>Choose warehouses</DialogTitle>

      <DialogContent dividers={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <OrderSplitInstruction />
          </Grid>
          {!isLoading
            ? warehouseList.map(warehouse => {
              const selected = find(guid => warehouse.guid === guid, selectedWarehouseGuids)

              return (
                <Grid item={true} xs={12} lg={6} key={warehouse.guid}>
                  <SelectableBox
                    onClick={() => onWarehouseSelect(warehouse.guid)}
                    selected={Boolean(selected)}
                  >
                    <TextOverflow tooltip={warehouse.name} lines={1}>
                      <Typography color="inherit">{warehouse.name}</Typography>
                    </TextOverflow>
                  </SelectableBox>
                </Grid>
              )
            })
            : (
              <OrderSplitSkeleton />
            )}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>
          Close
        </Button>
        <Button disabled={disabled} onClick={onSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

OrderSplitDialog.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onWarehouseSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedWarehouseGuids: PropTypes.array.isRequired,
  warehouseList: PropTypes.array
}

export default OrderSplitDialog
