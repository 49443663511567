import { useCreate, useLimitedCountList } from 'storfox-api-hooks'
import useAllSearchParams from 'storfox-route-hooks/src/useAllSearchParams'

import { queryToParams } from '~/utils'
import * as API from '~/constants/api'

import { TeamMetricsFilterSerializer } from '../../serializers/TeamMetrics'

export const useTeamMetricsList = () => {
  const searchParams = useAllSearchParams()
  const params = queryToParams(TeamMetricsFilterSerializer, searchParams, 'guid')
  const { getList, ...state } = useLimitedCountList(API.TEAM_METRICS_LIST, params)

  return { ...state, getList: rest => getList({ ...rest, ...params }) }
}

export const useTeamMetricsExport = () => {
  const searchParams = useAllSearchParams()
  const params = queryToParams(TeamMetricsFilterSerializer, searchParams, 'guid')
  const { create, ...state } = useCreate(API.TEAM_METRICS_EXPORT)

  return { ...state, export: rest => create({ ...rest, ...params }) }
}
