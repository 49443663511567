import { AuthLayout, BlankLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import ShopifyIntegrationCreateContainer from './ShopifyIntegrationCreateContainer'
import ShopifyIntegrationUpdateContainer from './ShopifyIntegrationUpdateContainer'
import ShopifyInstructionContainer from './ShopifyInstructionContainer'
import ShopifyInstallContainer from './ShopifyInstallContainer'

export default () => {
  return [
    {
      layout: AuthLayout,
      path: ROUTES.SHOPIFY_INSTRUCTION_CONTAINER,
      component: ShopifyInstructionContainer
    },
    {
      layout: BlankLayout,
      path: ROUTES.SHOPIFY_INSTALL_PATH,
      component: ShopifyInstallContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.SHOPIFY_INTEGRATION_CREATE_PATH,
      component: ShopifyIntegrationCreateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.SHOPIFY_INTEGRATION_UPDATE_PATH,
      component: ShopifyIntegrationUpdateContainer
    }
  ]
}
