import React from 'react'
import { TextField } from 'storfox-form-fields'

function SubmitBlockedTextField (props) {
  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault()
    }
  }

  return (
    <TextField
      onKeyPress={handleKeyPress}
      {...props}
    />

  )
}

export default SubmitBlockedTextField
