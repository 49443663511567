import { pick, prop } from 'ramda'
import { DEFAULT_SEARCH_PARAMS } from 'storfox-route-hooks'

import { getDateTimeResetZone } from '~/utils'

import { CATEGORY_CREATE_FIELDS } from '../components/CategoryCreate'

export function CategoryCreateSerializer (formValues) {
  return pick(
    CATEGORY_CREATE_FIELDS,
    formValues
  )
}

export function CategoryFilterSerializer (params) {
  const defaultParams = pick(DEFAULT_SEARCH_PARAMS, params)
  const status = prop('status', params)
  const createdAtStart = getDateTimeResetZone(prop('createdAtStart', params))
  const createdAtEnd = getDateTimeResetZone(prop('createdAtEnd', params))

  return { ...defaultParams, status, createdAtStart, createdAtEnd }
}
