import React, { useState } from 'react'
import { map, prop } from 'ramda'
import { generatePath } from 'react-router-dom'
import PropTypes from 'prop-types'

import { MoreListButton, NewButton } from '~/components/Buttons'
import Container, { Content, Header } from '~/components/Container'
import PageTitle from '~/components/PageTitle'
import TableDateFormat from '~/components/TableDateFormat'
import TextOverflow from '~/components/TextOverflow'
import { ActiveStatus } from '~/components/ActiveStatus'
import { Table, TableHeader } from '~/components/Table'
import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'
import TableLink from '~/components/Link'

import { GENERAL } from '../../../constants/BillingProfile'

const getDetailPath = guid => {
  return generatePath(ROUTES.BILLING_PROFILE_UPDATE_PATH, { tab: GENERAL, guid })
}

export const BILLING_PROFILE_COLUMNS = [
  {
    width: 200,
    headerName: 'Name',
    field: 'name',
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        <TextOverflow selfTooltip={true}>{value}</TextOverflow>
      </TableLink>
    )
  },
  {
    width: 150,
    headerName: 'Status',
    field: 'status',
    renderCell: ({ value }) => (
      <ActiveStatus value={value} />
    )
  },
  {
    width: 150,
    headerName: 'Created date',
    field: 'createdAt',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  },
]

const getRowsFromResults = map(item => {
  const guid = prop('guid', item)
  const name = prop('name', item)
  const status = prop('status', item)
  const createdAt = prop('createdAt', item)
  const link = getDetailPath(guid)

  return { guid, name, status, createdAt, link }
})

function BillingProfileList ({ list, filter, ordering, onListRefetch }) {
  const [tableRef, setTableRef] = useState(null)
  const results = prop('results', list)
  const isLoading = prop('isLoading', list)
  const count = prop('count', list)
  const columns = prop('filteredColumns', ordering)
  const createPath = generatePath(ROUTES.BILLING_PROFILE_CREATE_PATH, { tab: GENERAL })

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle="Billing profiles"
          parentTitle={NAV.CONFIGURATION}
          rightButton={(
            <NewButton path={createPath} />
          )}
        />
      </Header>
      <Content>
        <TableHeader
          filter={filter}
          filterOpen={filter.handleOpen}
          orderingOpen={ordering.handleOpen}
          ordering={ordering}
        >
          <MoreListButton tableRef={tableRef} />
        </TableHeader>
        <Table
          columns={columns}
          isLoading={isLoading}
          getRowsFromResults={getRowsFromResults}
          results={results}
          count={count}
          primaryKey="guid"
          checkboxSelection={true}
          ordering={ordering}
          pinnedColumns={['name']}
          onRefetch={onListRefetch}
          setTableRef={setTableRef}
        />
      </Content>
    </Container>
  )
}

BillingProfileList.propTypes = {
  list: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  onListRefetch: PropTypes.func.isRequired
}

export default BillingProfileList
