import React from 'react'

import SelectField from '~/components/Fields/SelectField'

const options = [
  { value: null, name: 'All' },
  { value: 'Active', name: 'Active' },
  { value: 'Inactive', name: 'Inactive' },
]

function CategoryStatusField (props) {
  return (
    <SelectField
      name="status"
      label="Status"
      options={options}
      {...props}
    />
  )
}

export default CategoryStatusField
