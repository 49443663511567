import React from 'react'

import SelectField from '~/components/Fields/SelectField'
import { COMPLETED, NAMES, NEW, PROCESSING } from '~/components/Statuses/ReturnStatus'

const OPTIONS = [
  { value: null, name: 'All' },
  { value: NEW, name: NAMES[NEW] },
  { value: PROCESSING, name: NAMES[PROCESSING] },
  { value: COMPLETED, name: NAMES[COMPLETED] }
]

function ReturnStatusField (props) {
  return (
    <SelectField
      name="status"
      label="Status"
      options={OPTIONS}
      {...props}
    />
  )
}

export default ReturnStatusField
