import React, { useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

function ScrollToTop ({ children }) {
  const { pathname } = useLocation()

  useLayoutEffect(() => {
    const hasWindowObject = typeof window !== 'undefined'

    if (hasWindowObject) {
      window.scrollTo(0, 0)
    }
  }, [pathname])

  return (
    <>{children}</>
  )
}

ScrollToTop.propTypes = {
  children: PropTypes.node.isRequired
}

export default ScrollToTop
