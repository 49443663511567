import React from 'react'

import SelectField from '~/components/Fields/SelectField'

const OPTIONS = [
  { value: null, name: 'All' },
  { value: 'outbound', name: 'Outbound' },
  { value: 'inbound', name: 'Inbound' }
]

function ShipmentDirectionField (props) {
  return (
    <SelectField
      name="direction"
      label="Direction"
      options={OPTIONS}
      {...props}
    />
  )
}

export default ShipmentDirectionField
