import React from 'react'
import { FieldArray } from 'react-final-form-arrays'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { CheckboxField } from 'storfox-form-fields'
import IconButton from '@mui/material/IconButton'
import { path, prop, propOr } from 'ramda'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import { styled } from '@mui/material'

import Avatar from '~/components/Avatar'
import VerticalAlignment from '~/components/VerticalAlignment'
import Subtext from '~/components/Subtext'
import EditIcon from '~/components/Icons/EditIcon'
import TextOverflow from '~/components/TextOverflow'
import DeleteIconButton from '~/components/DeleteIconButton'
import { useScreenOutline } from '~/components/ScreenOutline'

import DimensionStatus from '../DimensionStatus'

const TableRowStyled = styled(TableRow)({
  '& td': {
    borderBottom: 'none',
    padding: '6px 24px 6px 16px'
  }
})

function LineItems ({ data, editDimension, onEditUnit, edit, isBarcodeMode, deleteEnabled }) {
  const { handleSoundedErrorTrigger } = useScreenOutline()
  const { t } = useTranslation()

  const disableEdit = edit || !isBarcodeMode
  const lineItems = propOr([], 'value', data)
  const trackSerialColumn = lineItems.find(path(['variant', 'trackSerialNumbers']))

  const handleItemEdit = (value) => {
    if (disableEdit) {
      handleSoundedErrorTrigger()
    } else {
      onEditUnit(value)
    }
  }

  return (
    <TableContainer>
      <FieldArray
        name="lineItems"
        render={({ fields }) => (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('Image')}</TableCell>
                <TableCell>{t('Product')}</TableCell>
                <TableCell>{t('Barcode')}</TableCell>
                {trackSerialColumn && (
                  <TableCell>{t('Serial number')}</TableCell>
                )}
                <TableCell>{t('Batch number')}</TableCell>
                <TableCell>{t('Condition code')}</TableCell>
                <TableCell>{t('Scanned')}</TableCell>
                <TableCell>{t('Received')}</TableCell>
                <TableCell>{t('Ordered')}</TableCell>
                <TableCell colSpan={2}>{t('Reject')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.map((name, index) => {
                const value = data.value[index]
                const guid = prop('guid', value)
                const ordered = propOr('0', 'quantity', value)
                const received = propOr('0', 'received', value)
                const variant = prop('variant', value)
                const variantName = prop('name', variant)
                const image = prop('defaultImage', variant)
                const barcode = prop('barcode', variant)
                const sku = prop('sku', variant)
                const serial = prop('serial', value)
                const serialNumber = prop('serialNumber', serial)
                const conditionCode = propOr('', 'conditionCode', serial)
                const quantity = propOr('0', 'quantity', serial)
                const batchNumber = propOr('', 'batchNumber', serial)

                const width = prop('width', variant)
                const height = prop('height', variant)
                const length = prop('length', variant)
                const weight = prop('weight', variant)
                const isFilled = Boolean(width || height || length || weight)

                return (
                  <TableRowStyled key={`${image}-${index}`}>
                    <TableCell style={{ position: 'relative' }}>
                      {editDimension && <DimensionStatus isFilled={isFilled} />}
                      <Avatar src={image} alt={variantName} />
                    </TableCell>
                    <TableCell width={200}>
                      <VerticalAlignment
                        primary={<TextOverflow selfTooltip={true} lines={1}>{variantName}</TextOverflow>}
                        secondary={<Subtext lines={1}>{sku}</Subtext>}
                      />
                    </TableCell>
                    <TableCell width={200}>
                      <TextOverflow selfTooltip={true} lines={1}>{barcode}</TextOverflow>
                    </TableCell>
                    {trackSerialColumn && (
                      <TableCell>{serialNumber}</TableCell>
                    )}
                    <TableCell style={{ minWidth: 130 }}>
                      {batchNumber}
                    </TableCell>
                    <TableCell>{conditionCode}</TableCell>
                    <TableCell>{quantity}</TableCell>
                    <TableCell>{received}</TableCell>
                    <TableCell>{ordered}</TableCell>
                    <TableCell>
                      <CheckboxField name={`${name}.serial.isRejected`} />
                    </TableCell>
                    <TableCell align="right" style={{ minWidth: 140 }}>
                      <IconButton
                        data-cy={`${name}.edit`}
                        onClick={() => handleItemEdit(value)}
                        size="large">
                        <EditIcon />
                      </IconButton>
                      <DeleteIconButton
                        data-cy={`${name}.delete`}
                        onClick={() => fields.remove(index)}
                        disabled={Boolean(guid || !deleteEnabled)}
                      />
                    </TableCell>
                  </TableRowStyled>
                )
              })}
            </TableBody>
          </Table>
        )}
      />
    </TableContainer>
  )
}

LineItems.propTypes = {
  data: PropTypes.object,
  editDimension: PropTypes.bool,
  onEditUnit: PropTypes.func,
  edit: PropTypes.bool,
  isBarcodeMode: PropTypes.bool,
  deleteEnabled: PropTypes.bool
}

export default LineItems
