export const UNPUBLISHED = 'unpublished'
export const PENDING = 'pending'
export const PUBLISHED = 'published'
export const PAUSED = 'paused'
export const REJECTED = 'rejected'

export const STATUS_NAMES = {
  [UNPUBLISHED]: 'Unpublished',
  [PENDING]: 'Pending',
  [PUBLISHED]: 'Published',
  [PAUSED]: 'Paused',
  [REJECTED]: 'Rejected'
}

export const FILTER_NAME = 'listing'
