import React from 'react'
import { useTranslation } from 'react-i18next'
import SelectField from 'storfox-form-fields/src/SelectField'

export const SHOW = '0'
export const HIDE = '1'

const options = [
  { value: SHOW, name: 'Show contact details on airway bill' },
  { value: HIDE, name: 'Hide contact details on airway bill' },
]

function InformationDisplayField ({ ...props }) {
  const { t } = useTranslation()

  return (
    <SelectField
      name="configuration.informationDisplay"
      label={t('Information display')}
      options={options}
      {...props}
    />
  )
}

export default InformationDisplayField
