import React, { useRef } from 'react'
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck'
import IconButton from '@mui/material/IconButton'
import PropTypes from 'prop-types'

import { useTableSelects } from '~/components/TableValues/hooks'
import HtmlTooltip from '~/components/HtmlTooltip'

import useTableBulkActions from './useTableBulkActions'
import TableSplitPopper from './TableSplitPopper'

function TableSplitIconButton ({ name, options }) {
  const { selects } = useTableSelects()
  const disabled = !selects.length

  const anchorRef = useRef(null)

  const {
    open,
    selected,
    handleClose,
    handleToggle,
    handleMenuItemClick
  } = useTableBulkActions(anchorRef, name, options)

  const title = disabled ? 'Select at least one row for bulk actions' : 'Bulk actions'

  return (
    <>
      <HtmlTooltip title={title}>
        <IconButton
          ref={anchorRef}
          disabled={disabled}
          aria-haspopup="menu"
          onClick={handleToggle}
          data-cy="bulk-action-button-menu"
        >
          <LibraryAddCheckIcon />
        </IconButton>
      </HtmlTooltip>

      <TableSplitPopper
        open={open}
        options={options}
        anchorRef={anchorRef}
        onClose={handleClose}
        disabled={disabled}
        selected={selected}
        onMenuItemClick={handleMenuItemClick}
      />
    </>
  )
}

TableSplitIconButton.propTypes = {
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired
}

export default TableSplitIconButton
