import React from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { prop } from 'ramda'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'

import PageTitle from '~/components/PageTitle'
import { DiscardButton, SaveButton } from '~/components/Buttons'
import Container, { Content, Header } from '~/components/Container'
import { withForm } from '~/components/Form'
import * as NAV from '~/constants/nav-titles'
import { CUSTOMER_UPDATE_TABS } from '~/constants/tabs'

import CustomerUpdateFormTabs from './CustomerUpdateFormTabs'
import CustomerUpdateFormGeneral from './CustomerUpdateFormGeneral'
import CustomerUpdateFormAddress from './CustomerUpdateFormAddress'

export const CUSTOMER_UPDATE_FIELDS = [
  'firstName',
  'lastName',
  'email',
  'mobile',
  'deliveryAddresses',
  'billingAddresses',
  'status',
  'imagePath',
  'language',
  'tags',
  'imagePath'
]

export const getInitialValue = initialValues => {
  const deliveryAddresses = prop('deliveryAddresses', initialValues) || []
  const billingAddresses = prop('billingAddresses', initialValues) || []

  return {
    ...initialValues,
    deliveryAddresses: deliveryAddresses.length ? deliveryAddresses : [{}],
    billingAddresses: billingAddresses.length ? billingAddresses : [{}]
  }
}

function CustomerUpdateForm (props) {
  const {
    pageTitle,
    pageTitleLoading,
    isLoading,
    form
  } = props

  const { handleSubmit } = form
  const { tab } = useParams()

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={pageTitle}
            pageTitleLoading={pageTitleLoading}
            parentTitle={NAV.CUSTOMERS}
            rightButton={
              <>
                <DiscardButton disabled={isLoading} />
                <SaveButton disabled={isLoading} />
              </>
            }
          />
        </Header>

        <Content>
          <CustomerUpdateFormTabs value={tab} />

          <Divider />
          <Box mb={3} />

          {CUSTOMER_UPDATE_TABS.GENERAL === tab && <CustomerUpdateFormGeneral />}
          {CUSTOMER_UPDATE_TABS.ADDRESS === tab && <CustomerUpdateFormAddress />}
          <Box mb={3} />
        </Content>
      </Container>
    </form>
  )
}

CustomerUpdateForm.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  pageTitleLoading: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default withForm(CustomerUpdateForm)
