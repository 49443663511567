import React from 'react'
import PropTypes from 'prop-types'
import Drawer, { drawerClasses } from '@mui/material/Drawer'
import Box from '@mui/material/Box'
import { styled } from '@mui/material'

import FilterForm from './FilterForm'

const DrawerStyled = styled(Drawer)({
  [`& .${drawerClasses.paper}`]: {
    width: 420,
    maxWidth: '100%',
    overflow: 'hidden'
  }
})

function FilterNoSave (props) {
  const {
    open,
    handleClose,
    handleClear,
    onSubmit,
    initialValues,
    validate,
    children,
  } = props

  return (
    <DrawerStyled
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      <Box sx={{ height: '100%' }}>
        <FilterForm
          onSubmit={onSubmit}
          onClear={handleClear}
          onClose={handleClose}
          initialValues={initialValues}
          validate={validate}
        >
          {children}
        </FilterForm>
      </Box>
    </DrawerStyled>
  )
}

FilterNoSave.propTypes = {
  initialValues: PropTypes.object,
  validate: PropTypes.func,
  children: PropTypes.node.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleClear: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default FilterNoSave
