import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import PropTypes from 'prop-types'
import { path, prop } from 'ramda'

import { CardHeader } from '~/components/Cards'
import TextLabelLoader from '~/components/TextLabelLoader'
import Money from '~/components/Money'
import MultiImagePreview from '~/components/ImageUpload/MultiImagePreview'

function BundleGeneral ({ retailPrice, bundle }) {
  const name = prop('name', bundle)
  const description = prop('description', bundle)
  const images = prop('images', bundle)
  const barcode = prop('barcode', bundle)
  const sku = prop('sku', bundle)
  const category = path(['category', 'name'], bundle)
  const brand = path(['brand', 'name'], bundle)
  const tags = prop('tags', bundle)

  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12} lg={8}>
        <Box mb={3}>
          <Card>
            <CardHeader title="Details" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Name">
                    {name}
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Description">
                    {description}
                  </TextLabelLoader>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>

        <Box mb={3}>
          <Card>
            <CardHeader title="Images" />
            <Divider />
            <CardContent>
              <MultiImagePreview images={images} />
            </CardContent>
          </Card>
        </Box>

      </Grid>

      <Grid item={true} xs={12} lg={4}>
        <Box mb={3}>
          <Card>
            <CardHeader title="Tracking" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <TextLabelLoader label="SKU">
                    {sku}
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Barcode">
                    {barcode}
                  </TextLabelLoader>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>

        {Boolean(retailPrice) && (
          <Box mb={3}>
            <Card>
              <CardHeader title="Pricing" />
              <Divider />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={12}>
                    <TextLabelLoader label="Retail price">
                      <Money value={retailPrice} />
                    </TextLabelLoader>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        )}

        <Box mb={3}>
          <Card>
            <CardHeader title="Organization" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Category">
                    {category}
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Brand">
                    {brand}
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Tags">
                    {tags}
                  </TextLabelLoader>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>

      </Grid>
    </Grid>
  )
}

BundleGeneral.propTypes = {
  retailPrice: PropTypes.number.isRequired,
  bundle: PropTypes.object.isRequired
}

export default BundleGeneral
