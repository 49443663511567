import React from 'react'
import PropTypes from 'prop-types'
import { prop } from 'ramda'
import CheckCircle from '@mui/icons-material/CheckCircle'
import { Box, styled } from '@mui/material'
import Card from '@mui/material/Card'

import CircularProgressWithLabel from '~/components/CircularProgressWithLabel'

const CardStyled = styled(Card, {
  shouldForwardProp: (propName) => propName !== 'isSelected'
})(({ theme, isSelected }) => ({
  height: 200,
  position: 'relative',
  cursor: 'pointer',
  backgroundColor: theme.palette.hover.color,
  [theme.breakpoints.down('md')]: {
    height: '100%'
  },
  ...(isSelected && {
    border: `1px solid ${theme.palette.primary.main}`
  })
}))

const ImageStyled = styled('img', {
  shouldForwardProp: (propName) => propName !== 'isLoading'
})(({ isLoading }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  ...(isLoading && {
    opacity: 0.1
  })
}))

function Image ({ file, selected, onSelect }) {
  const start = prop('start', file)
  const finish = prop('finish', file)
  const progress = prop('progress', file)
  const src = prop('src', file)
  const loading = start && !finish

  return (
    <CardStyled
      variant="outlined"
      isSelected={selected}
      onClick={onSelect}
    >
      <ImageStyled
        isLoading={loading}
        src={src}
        alt="Image not loaded"
      />
      {loading && (
        <CircularProgressWithLabel
          value={progress}
          sx={{
            position: 'absolute',
            zIndex: 4,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        />
      )}
      {selected && (
        <Box sx={{
          position: 'absolute',
          top: 0,
          right: 0
        }}>
          <CheckCircle />
        </Box>
      )}
    </CardStyled>
  )
}

Image.propTypes = {
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
  file: PropTypes.object.isRequired
}

export default Image
