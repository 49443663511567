import React from 'react'
import { useTranslation } from 'react-i18next'
import { prop } from 'ramda'
import TableCell from '@mui/material/TableCell'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import PropTypes from 'prop-types'

import { ExpandableTableRow, ExpandedTableWrapper } from '~/components/Table'

function LocationRow ({ children, locations }) {
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(false)

  return (
    <>
      <ExpandableTableRow open={open} onExpandToggle={() => setOpen(!open)}>
        {children}
      </ExpandableTableRow>

      <ExpandedTableWrapper open={open} colSpan={6}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell style={{ minWidth: 400 }}>{t('Container number')}</TableCell>
              <TableCell align="center">{t('Available')}</TableCell>
              <TableCell align="center">{t('Unavailable')}</TableCell>
              <TableCell align="center">{t('On Hand')}</TableCell>
              <TableCell align="center">{t('Allocated')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {locations.map(location => {
              const containerNumber = prop('containerNumber', location) || 'No container'
              const allocated = prop('allocated', location)
              const onHand = prop('onHand', location)
              const available = prop('available', location)
              const unavailable = prop('unavailable', location)

              return (
                <TableRow>
                  <TableCell>{containerNumber}</TableCell>
                  <TableCell align="center">{available}</TableCell>
                  <TableCell align="center">{unavailable}</TableCell>
                  <TableCell align="center">{onHand}</TableCell>
                  <TableCell align="center">{allocated}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </ExpandedTableWrapper>
    </>
  )
}

LocationRow.propTypes = {
  children: PropTypes.any.isRequired,
  locations: PropTypes.array.isRequired
}

export default LocationRow
