import React from 'react'
import { compose, filter, find, not, pipe, prepend, prop, propEq } from 'ramda'

import * as API from '~/constants/api'
import SearchField from '~/components/Fields/SearchField'
import { useCompany } from '~/components/Profile'

const getOptionValue = value => {
  const id = prop('id', value)
  const guid = prop('guid', value)
  const name = prop('name', value)
  const timezones = prop('timezones', value)
  const currencies = prop('currencies', value)

  return { id, guid, name, timezones, currencies }
}

const prepareOptions = (options, country) => {
  const countryGuid = prop('guid', country)
  const countryOption = find(propEq('guid', countryGuid), options)

  return countryOption
    ? pipe(
      filter(compose(not, propEq('guid', countryGuid))),
      prepend(countryOption)
    )(options)
    : options
}

function CountryField ({ ...props }) {
  const { company } = useCompany()
  const country = prop('country', company)

  return (
    <SearchField
      {...props}
      prepareOptions={options => prepareOptions(options, country)}
      api={API.REGION_LIST}
      params={{ type: 'country', limit: '1000' }}
      getOptionValue={getOptionValue}
      ListboxProps={{ 'data-cy': 'countryList' }}
    />
  )
}

export default CountryField
