import React from 'react'
import { Filter } from 'storfox-filter'
import Grid from '@mui/material/Grid'
import { prop } from 'ramda'

import { escapeAtob, unescapeBtoa } from '~/utils'
import CustomerSearchField from '~/components/Fields/CustomerSearchField'
import WarehouseMultiSelectField from '~/components/Fields/WarehouseMultiSelectField'
import Accordion from '~/components/Accordion'
import { ActiveStatusField } from '~/components/ActiveStatus'

import { FILTER_NAME as name } from '../../../constants/BillingProfile'

export const toParams = values => {
  const status = prop('status', values)
  const supplier = unescapeBtoa(prop('supplier', values))
  const warehouse = unescapeBtoa(prop('warehouse', values))

  return {
    status,
    supplier,
    warehouse
  }
}

export const toValues = params => {
  const status = prop('status', params)
  const supplier = escapeAtob(prop('supplier', params))
  const warehouse = escapeAtob(prop('warehouse', params))

  return {
    status,
    supplier,
    warehouse
  }
}
export const transformer = { toParams, toValues }

export const billingProfileFilterOptions = {
  name,
  transformer
}

function BillingProfileFilterForm (props) {
  return (
    <Filter {...props}>
      <Accordion title="General" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <ActiveStatusField />
          </Grid>
          <Grid item={true} xs={12}>
            <CustomerSearchField />
          </Grid>
          <Grid item={true} xs={12}>
            <WarehouseMultiSelectField />
          </Grid>
        </Grid>
      </Accordion>
    </Filter>
  )
}

export default BillingProfileFilterForm
