import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import { prop } from 'ramda'
import { Filter } from 'storfox-filter'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { PositiveFloatField, DateRangeField } from 'storfox-form-fields'

import { escapeAtob, getSeparatedDateRange, unescapeBtoa } from '~/utils'
import WarehouseSearchField from '~/components/Fields/WarehouseSearchField'
import Accordion from '~/components/Accordion'

import { FILTER_NAME as name } from '../../constants'
import { TransferStatusField } from '../Fields'

export const toParams = values => {
  const status = prop('status', values)
  const totalPriceStart = prop('totalPriceStart', values)
  const totalPriceEnd = prop('totalPriceEnd', values)

  const fromWarehouse = unescapeBtoa(prop('fromWarehouse', values))
  const toWarehouse = unescapeBtoa(prop('toWarehouse', values))

  const [createdAtStart, createdAtEnd] = getSeparatedDateRange(prop('createdAt', values))
  const [updatedAtStart, updatedAtEnd] = getSeparatedDateRange(prop('updatedAt', values))

  return {
    status,
    totalPriceStart,
    totalPriceEnd,
    fromWarehouse,
    toWarehouse,
    createdAtStart,
    createdAtEnd,
    updatedAtStart,
    updatedAtEnd
  }
}

export const toValues = (params) => {
  const status = prop('status', params)
  const fromWarehouse = escapeAtob(prop('fromWarehouse', params))
  const toWarehouse = escapeAtob(prop('toWarehouse', params))

  const totalPriceStart = prop('totalPriceStart', params)
  const totalPriceEnd = prop('totalPriceEnd', params)

  const createdAt = [prop('createdAtStart', params), prop('createdAtEnd', params)]
  const updatedAt = [prop('updatedAtStart', params), prop('updatedAtEnd', params)]

  return {
    status,
    totalPriceStart,
    totalPriceEnd,
    fromWarehouse,
    toWarehouse,
    createdAt,
    updatedAt
  }
}

export const transformer = { toParams, toValues }

export const transferFilterOptions = {
  name,
  transformer
}

function TransferFilterForm (props) {
  const { t } = useTranslation()

  return (
    <Filter {...props}>
      <Accordion title="General" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <TransferStatusField />
          </Grid>
          <Grid item={true} xs={12}>
            <WarehouseSearchField
              name="fromWarehouse"
              label="From warehouse"
            />
          </Grid>
          <Grid item={true} xs={12}>
            <WarehouseSearchField
              name="toWarehouse"
              label="To warehouse"
            />
          </Grid>
        </Grid>
      </Accordion>
      <Accordion title="Date" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <Typography variant="body2" gutterBottom={true}>
              {t('Created')}:
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <DateRangeField name="createdAt" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography variant="body2" gutterBottom={true}>
              {t('Updated')}:
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <DateRangeField name="updatedAt" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Accordion>
      <Accordion title="Value ($)">
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={6}>
            <PositiveFloatField
              data-cy="totalPriceStart"
              name="totalPriceStart"
              label="Min ($)"
            />
          </Grid>
          <Grid item={true} xs={6}>
            <PositiveFloatField
              data-cy="totalPriceEnd"
              name="totalPriceEnd"
              label="Max ($)"
            />
          </Grid>
        </Grid>
      </Accordion>
    </Filter>
  )
}

TransferFilterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired
}

export default TransferFilterForm
