import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useDeepCompareEffect } from 'storfox-api-hooks'
import { useField } from 'react-final-form'

import SelectField from '~/components/Fields/SelectField'

import {
  LISTING_CREATED,
  LOW_STOCK,
  ORDER_CHANGED,
  ORDER_CREATED,
  PACKING_CREATED,
  PICKING_CREATED,
  PURCHASE_CHANGE,
  PUTAWAY_CREATED,
  PUTAWAY_V2_CREATED,
  REPLENISHMENT,
  SHIPMENT_CREATED,
  SHIPMENT_SET_STATUS
} from '../../../../constants/AutomationRule'

export const TO_WAREHOUSE = 'toWarehouse'
export const AUTO_CONFIRM = 'autoConfirm'
export const AUTO_CREATE_SHIPMENT = 'autoCreateShipment'
export const AUTO_PROCESS = 'autoProcess'
export const ASSIGN_TO = 'assignTo'
export const MERGE_PICKLIST = 'mergePicklist'
export const ASSIGN_TO_CARRIER = 'assignToCarrier'
export const TO_LOCATION = 'toLocation'
export const SET_STRATEGY = 'setStrategy'
export const SPLIT_TO = 'splitTo'
export const SEND_TO_USERS = 'sendToUsers'
export const PUTAWAY = 'putaway'
export const AUTO_PUBLISH = 'autoPublish'
export const REALLOCATION_SALE_ORDER = 'reallocationSaleOrder'
export const SHIPMENT_CHANGE_STATUS = 'status'

const ORDER_CREATE_OPTIONS = [
  {
    name: 'To warehouse',
    value: TO_WAREHOUSE
  },
  {
    name: 'Auto-confirm',
    value: AUTO_CONFIRM
  }
]

const ORDER_CHANGED_OPTIONS = [
  {
    name: 'Split to',
    value: SPLIT_TO
  },
  {
    name: 'To warehouse',
    value: TO_WAREHOUSE
  },
  {
    name: 'Auto process',
    value: AUTO_PROCESS
  },
  {
    name: 'Auto-create-shipment',
    value: AUTO_CREATE_SHIPMENT
  }
]

const PUTAWAY_CREATE_OPTIONS = [
  {
    name: 'Assign to',
    value: ASSIGN_TO
  },
  {
    name: 'To location',
    value: TO_LOCATION
  },
  {
    name: 'Set strategy',
    value: SET_STRATEGY
  }
]

const PUTAWAY_V2_CREATE_OPTIONS = [
  {
    name: 'Assign to',
    value: ASSIGN_TO
  },
  {
    name: 'To location',
    value: TO_LOCATION
  }
]

const PICKING_CREATE_OPTIONS = [
  {
    name: 'Assign to',
    value: ASSIGN_TO
  },
  {
    name: 'Merge picking',
    value: MERGE_PICKLIST
  }
]

const PACKING_CREATE_OPTIONS = [
  {
    name: 'Assign to',
    value: ASSIGN_TO
  },
  {
    name: 'Auto-create-shipment',
    value: AUTO_CREATE_SHIPMENT
  }
]

const SHIPMENT_CREATE_OPTIONS = [
  {
    name: 'Assign to Carrier',
    value: ASSIGN_TO_CARRIER
  }
]

const LOW_STOCK_OPTIONS = [
  {
    name: 'Send to Users',
    value: SEND_TO_USERS
  }
]

const REPLENISHMENT_OPTIONS = [
  {
    name: 'Putaway',
    value: PUTAWAY
  }
]

const LISTING_CREATED_OPTIONS = [
  {
    name: 'Auto publish',
    value: AUTO_PUBLISH
  }
]

const PURCHASE_CHANGE_OPTIONS = [
  {
    name: 'Reallocation sale order',
    value: REALLOCATION_SALE_ORDER
  }
]

const SHIPMENT_STATUS_CHANGE_OPTIONS = [
  {
    name: 'Shipment set status',
    value: SHIPMENT_CHANGE_STATUS
  }
]

const OPTIONS = {
  [ORDER_CREATED]: ORDER_CREATE_OPTIONS,
  [ORDER_CHANGED]: ORDER_CHANGED_OPTIONS,
  [PUTAWAY_CREATED]: PUTAWAY_CREATE_OPTIONS,
  [PUTAWAY_V2_CREATED]: PUTAWAY_V2_CREATE_OPTIONS,
  [PICKING_CREATED]: PICKING_CREATE_OPTIONS,
  [PACKING_CREATED]: PACKING_CREATE_OPTIONS,
  [SHIPMENT_CREATED]: SHIPMENT_CREATE_OPTIONS,
  [LOW_STOCK] : LOW_STOCK_OPTIONS,
  [REPLENISHMENT] : REPLENISHMENT_OPTIONS,
  [LISTING_CREATED]: LISTING_CREATED_OPTIONS,
  [PURCHASE_CHANGE]: PURCHASE_CHANGE_OPTIONS,
  [SHIPMENT_SET_STATUS]: SHIPMENT_STATUS_CHANGE_OPTIONS
}

function ActionSelectField ({ name, event, onChange, ...props }) {
  const { t } = useTranslation()
  const field = useField(name)
  const value = field.input.value

  useDeepCompareEffect(() => {
    onChange()
  }, [value])

  return (
    <SelectField
      label={t('Action')}
      name={name}
      options={OPTIONS[event]}
      {...props}
    />
  )
}

ActionSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  event: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

export default ActionSelectField
