import React from 'react'
import InputAdornment from '@mui/material/InputAdornment'

import TextField from './TextField'

function ColorField (props) {
  return (
    <TextField
      {...props}
      InputProps={{
        type: 'color',
        startAdornment: (
          <InputAdornment position="start">#</InputAdornment>
        )
      }}
    />
  )
}

export default ColorField
