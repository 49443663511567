import React from 'react'
import PropTypes from 'prop-types'

import TableSplitButton from '~/components/TableSplitButton'

function BulkActionButton ({ onStockSync, onAddToListingDialogOpen, loading, onVariantDelete }) {
  const options = [
    {
      label: 'Sync. stock',
      'data-cy': 'stockSyncMenu',
      onMenuClick: onStockSync
    },
    {
      label: 'Add to listing',
      'data-cy': 'addToListingMenu',
      onMenuClick: onAddToListingDialogOpen
    },
    {
      label: 'Delete',
      'data-cy': 'delete',
      onMenuClick: onVariantDelete
    }
  ]

  return (
    <TableSplitButton
      options={options}
      name="variant"
      loading={loading}
    />
  )
}

BulkActionButton.propTypes = {
  onStockSync: PropTypes.func.isRequired,
  onAddToListingDialogOpen: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  onVariantDelete: PropTypes.func.isRequired
}

export default BulkActionButton
