import { styled } from '@mui/material'

export const IFrameStyled = styled('iframe')(({ theme }) => ({
  width: '560px',
  height: '315px',
  [theme.breakpoints.down('sm')]: {
    width: '480px',
    height: '260px'
  },
  [theme.breakpoints.down('xs')]: {
    width: '320px',
    height: '190px'
  }
}))
