import React from 'react'
import { styled } from '@mui/material'
import Grid from '@mui/material/Grid'
import PropTypes from 'prop-types'

const GridStyled = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(3),
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    '&:not(:last-of-type)': {
      borderRight: `1px solid ${theme.palette.divider}`
    }
  },
  [theme.breakpoints.down('md')]: {
    '&:not(:last-of-type)': {
      borderBottom: `1px solid ${theme.palette.divider}`
    }
  }
}))

function TopInformationWrapper ({ children, ...props }) {
  return (
    <GridStyled item={true} {...props}>
      {children}
    </GridStyled>
  )
}

TopInformationWrapper.propTypes = {
  children: PropTypes.node.isRequired
}

export default TopInformationWrapper
