import React from 'react'
import { useTranslation } from 'react-i18next'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'
import { useNavigate, useParams } from 'react-router-dom'
import { prop } from 'ramda'

import { DashboardLayout } from '~/components/Layouts'
import Title from '~/components/Title'
import ErrorLink from '~/components/ErrorLink'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import useMessages from '~/hooks/useMessages'
import { TRANSFER_DETAIL_TABS } from '~/constants/tabs'

import { STATUSES } from '../constants'
import TransferDetail from '../components/TransferDetail/TransferDetail'
import {
  useTransferCancel,
  useTransferDetail,
  useTransferDetailComplete,
  useTransferDetailProcess,
  useTransferHistory
} from '../hooks'
import { TransferInitUpdateSerializer } from '../serializers'

function TransferDetailContainer () {
  const { t } = useTranslation()
  const { guid, tab } = useParams()
  const transferDetail = useTransferDetail(guid)
  const transferCancel = useTransferCancel()
  const transferDetailComplete = useTransferDetailComplete(guid)
  const transferDetailProcess = useTransferDetailProcess(guid)

  const historyAutoSend = Boolean(guid && tab === TRANSFER_DETAIL_TABS.HISTORY)

  const transferHistory = useTransferHistory(guid, historyAutoSend)

  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const messages = useMessages()

  const number = prop('number', transferDetail.detail)
  const status = prop('status', transferDetail.detail)

  const title = number || `${t('Stock transfer')}`
  const pageTitle = (
    <Title
      title={t('Stock transfer')}
      number={number}
      status={STATUSES[status]}
    />
  )

  const handleCancel = () =>
    transferCancel.update({ guid })
      .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
      .then(() => navigate(ROUTES.TRANSFER_LIST_PATH))
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
        return Promise.reject(error)
      })

  const handleProcess = () =>
    transferDetailProcess.process()
      .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
      .then(() => navigate(ROUTES.TRANSFER_LIST_PATH))
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
        return Promise.reject(error)
      })

  const handleComplete = () =>
    transferDetailComplete.complete()
      .then(() => snackbar({ message: messages.COMPLETE_SUCCESS }))
      .then(() => navigate(ROUTES.TRANSFER_LIST_PATH))

  const isLoading = (
    transferDetail.isLoading ||
    transferDetailProcess.isLoading ||
    transferDetailComplete.isLoading
  )

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      isLoading={transferDetail.isLoading}
      activeNav={NAV.TRANSFER}
      breadcrumbs={breadcrumbs}
    >
      <TransferDetail
        parentTitle={NAV.TRANSFER}
        pageTitle={pageTitle}
        pageTitleLoading={transferDetail.isLoading}
        isLoading={isLoading}
        initialValues={TransferInitUpdateSerializer(transferDetail.detail)}
        onCancel={handleCancel}
        onProcess={handleProcess}
        onComplete={handleComplete}
        history={transferHistory}
      />
    </DashboardLayout>
  )
}

export default TransferDetailContainer
