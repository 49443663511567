import { assoc, find, map, pathEq, pick, prop, propEq, propOr, reduce } from 'ramda'

import { getDateTimeMidnight } from '~/utils'

import { PURCHASE_ORDER } from '../constants'

const getLineItem = pick(['guid', 'containerCode', 'supplierSku'])

const getExpiry = (trackExpiry, expiresAt) => trackExpiry ? expiresAt : null

const getProductionDate = (trackProductionDates, productionDate) => trackProductionDates ? productionDate : null

export function ReceivePurchaseDetailSerializer ({ detail, id, conditionCode }) {
  const lineItems = propOr([], 'lineItems', detail)
  const toLineItems = map(item => {
    const location = prop('defaultReceivingLocation', item)
    return assoc('location', location, item)
  })

  return {
    ...detail,
    objId: id,
    conditionCode,
    objType: PURCHASE_ORDER,
    lineItems: toLineItems(lineItems)
  }
}

export function ReceivingPurchaseSerializer (formValues, receiptGuid) {
  const destination = prop('destination', formValues)
  const containerCode = prop('containerCode', destination)
  const locationId = prop('locationId', destination)
  const lineItems = prop('lineItems', formValues)

  const formattedLineItems = reduce((acc, item) => {
    const serial = prop('serial', item)
    if (serial) {
      const itemGuid = prop('guid', item)
      const variant = prop('productVariant', item)
      const guid = prop('guid', variant)
      const variantGuid = prop('guid', variant)
      const trackExpiry = prop('trackExpiry', variant)
      const trackProductionDate = prop('trackProductionDates', variant)

      const accItem = find(item => {
        const sameGuid = propEq('guid', itemGuid, item)
        const sameVariant = pathEq(['variant', 'guid'], variantGuid, item)
        return sameGuid && sameVariant
      }, acc)

      const expiryAt = prop('expiryAt', formValues) || []
      const itemExpiryAt = expiryAt.find(item => item.guid === guid)
      const expiresAt = getDateTimeMidnight(prop('expiresAt', itemExpiryAt))

      const productionAt = prop('productionAt', formValues) || []
      const itemProductionDate = productionAt.find(item => item.guid === guid)
      const productionDate = getDateTimeMidnight(prop('productionDate', itemProductionDate))

      if (accItem) {
        const inAccSerials = propOr([], 'serials', accItem)
        const newQuantity = prop('quantity', accItem) + 1
        const newItem = { ...accItem, serials: [...inAccSerials, item.serial], quantity: newQuantity }
        const otherItems = acc.filter(item => !pathEq(['variant', 'guid'], variantGuid, item))
        return [...otherItems, newItem]
      }

      const newLineItem = getLineItem(item)
      const newItem = {
        ...newLineItem,
        serials: [item.serial],
        variant,
        quantity: 1,
        containerCode,
        locationId,
        expiresAt: getExpiry(trackExpiry, expiresAt),
        productionDate: getProductionDate(trackProductionDate, productionDate)
      }

      return [newItem, ...acc]
    }
    return acc
  }, [], lineItems)

  return { lineItems: formattedLineItems, guid: receiptGuid }
}

export function ReceivePurchaseInitSerializer (values) {
  const { lineItems, ...oldValues } = values
  const newValues = { ...oldValues, detailLineItems: lineItems, lineItems: [] }

  return {
    previousValues: { ...newValues, lineItems },
    ...newValues
  }
}
