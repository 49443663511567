import React from 'react'
import { prop } from 'ramda'
import { MultiSelectSearchField } from 'storfox-form-fields'

import * as API from '~//constants/api'

const getOptionValue = value => {
  if (value) {
    const guid = prop('id', value)
    const code = prop('code', value)

    return { guid, name: code }
  }

  return null
}

function ConditionMultiSelectField (props) {
  return (
    <MultiSelectSearchField
      api={API.CONDITION_LIST}
      label="Condition"
      name="condition"
      getOptionValue={getOptionValue}
      {...props}
    />
  )
}

export default ConditionMultiSelectField
