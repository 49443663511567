import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { path, prop } from 'ramda'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import { PICKLIST_TABS } from '~/constants/tabs'
import { DashboardLayout } from '~/components/Layouts'
import ErrorLink from '~/components/ErrorLink'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import useMessages from '~/hooks/useMessages'
import { PICKING_ALTER_UNIT } from '~/constants/notification-topics'
import { useNotification } from '~/components/Notification'
import { useGoogleEvent } from '~/components/GoogleAnalytics/GoogleAnalytics'

import {
  PicklistDetailAssignToSerializer,
  PicklistDetailCompleteSerializer,
  PicklistDetailPickSerializer,
  PicklistUnitAlterSerializer
} from '../serializers'
import {
  usePackingLocationList,
  usePickingJobCreate,
  usePicklistDetail,
  usePicklistDetailComplete,
  usePicklistDetailPick,
  usePicklistDetailProcess,
  usePicklistDetailProgress,
  usePicklistHistory,
  usePicklistPdfReport,
  usePicklistSkip,
  usePicklistUnitAlter,
  usePicklistCancel
} from '../hooks'
import { PicklistDetail } from '../components/PicklistDetail'

const getError = error => {
  const containerNumber = path(['lineItems', 0, 'container'], error)

  return { ...error, containerNumber }
}

function PicklistDetailContainer () {
  const [notificationPayload, setNotificationPayload] = useState(null)
  const { guid, tab } = useParams()
  const { sendEvent } = useGoogleEvent()
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const messages = useMessages()
  const alterUnitNotification = useNotification(PICKING_ALTER_UNIT)

  const picklistDetail = usePicklistDetail(guid)

  const warehouseId = path(['warehouse', 'id'], picklistDetail.detail)

  const picklistCancel = usePicklistCancel(guid)
  const picklistDetailProcess = usePicklistDetailProcess(guid)
  const picklistDetailProgress = usePicklistDetailProgress(guid)
  const picklistDetailPick = usePicklistDetailPick()
  const picklistDetailComplete = usePicklistDetailComplete()
  const picklistUnitAlter = usePicklistUnitAlter()
  const pickingJobCreate = usePickingJobCreate()
  const picklistSkip = usePicklistSkip(guid)
  const picklistGeneratePDF = usePicklistPdfReport()
  const packingLocationList = usePackingLocationList(warehouseId)
  const picklistHistory = usePicklistHistory(guid, tab === PICKLIST_TABS.HISTORY)

  const isLoading = (
    picklistDetail.isLoading ||
    picklistDetailComplete.isLoading ||
    picklistDetailProcess.isLoading ||
    picklistDetailProgress.isLoading
  )

  const number = prop('number', picklistDetail.detail)

  const handleSubmit = formValues => {
    const data = PicklistDetailPickSerializer({ ...formValues, pickingGuid: guid })
    return picklistDetailPick.create(data)
      .then(() => {
        sendEvent({ eventAction: 'Pick Picklist', eventCategory: 'Picklist' })
        snackbar({ message: messages.UPDATE_SUCCESS })
      })
      .then(() => picklistDetail.getDetail())
      .catch(error => {
        const newError = getError(error)
        return Promise.reject(newError)
      })
  }

  const handleProcess = () =>
    picklistDetailProcess.process()
      .then(() => {
        sendEvent({ eventAction: 'Process Picklist', eventCategory: 'Picklist' })
        snackbar({ message: messages.UPDATE_SUCCESS })
      })
      .then(() => picklistDetail.getDetail())
      .catch(error => {
        snackbar({ message: <ErrorLink error={error} />, type: ALTER_ERROR })
        throw error
      })

  const handleCancel = () =>
    picklistCancel.cancel()
      .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
      .then(() => picklistDetail.getDetail())
      .catch(error => {
        snackbar({ message: <ErrorLink error={error} />, type: ALTER_ERROR })
        throw error
      })

  const handleProgress = () =>
    picklistDetailProgress.progress()
      .then(() => {
        sendEvent({ eventAction: 'Progress Picklist', eventCategory: 'Picklist' })
        snackbar({ message: messages.UPDATE_SUCCESS })
      })
      .then(() => picklistDetail.getDetail())
      .catch(error => {
        snackbar({ message: <ErrorLink error={error} />, type: ALTER_ERROR })
        throw error
      })

  const handleComplete = formValues =>
    picklistDetailComplete.create(PicklistDetailCompleteSerializer(formValues))
      .then(() => {
        sendEvent({ eventAction: 'Complete Picklist', eventCategory: 'Picklist' })
        snackbar({ message: messages.COMPLETE_SUCCESS })
      })
      .then(() => navigate(ROUTES.PICKLIST_LIST_PATH))
      .catch(error => {
        snackbar({ message: <ErrorLink error={error} />, type: ALTER_ERROR })
        throw error
      })

  const processPendingNotifications = payload => {
    const success = path(['payload', 'isSuccess'])

    if (success) {
      snackbar({ message: messages.UPDATE_SUCCESS })
      picklistDetail.getDetail()
    }

    if (!success) {
      const message = path(['payload', 'errorMsg'], payload)
      snackbar({ message, type: ALTER_ERROR })
    }

    setNotificationPayload(null)
  }

  const handleUnitAlter = values =>
    picklistUnitAlter.alter(PicklistUnitAlterSerializer(values, guid))
      .then(() => {
        sendEvent({ eventAction: 'Alter Units Picklist', eventCategory: 'Picklist' })
        snackbar({ message: messages.NOTIFICATION_WAIT })
        alterUnitNotification.setLoading(true)

        alterUnitNotification.subscribe(guid, payload => {
          setNotificationPayload(payload)
        })
      })
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
        return Promise.reject(error)
      })

  const handlePickerChange = formValues =>
    pickingJobCreate.create(PicklistDetailAssignToSerializer(guid, formValues))
      .then(() => {
        sendEvent({ eventAction: 'Assign Picklist', eventCategory: 'Picklist' })
        snackbar({ message: messages.UPDATE_SUCCESS })
      })
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const handleSkip = () =>
    picklistSkip.skip()
      .then(() => {
        sendEvent({ eventAction: 'Skip Picklist', eventCategory: 'Picklist' })
        snackbar({ message: messages.UPDATE_SUCCESS })
      })
      .then(() => picklistDetail.getDetail())
      .catch(error => snackbar({
        message: <ErrorLink error={error} />,
        type: ALTER_ERROR
      }))

  const handleGeneratePdfReport = () =>
    picklistGeneratePDF.generate({ guids: [guid] })
      .then(() => {
        sendEvent({ eventAction: 'PDF Report Picklist', eventCategory: 'Picklist' })
        snackbar({ message: messages.NOTIFICATION_WAIT })
      })
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const breadcrumbs = { title: number }

  return (
    <DashboardLayout
      title={number}
      activeNav={NAV.PICKLISTS}
      isLoading={isLoading}
      breadcrumbs={breadcrumbs}
    >
      <PicklistDetail
        pageTitle={number}
        picklistNumber={number}
        pageTitleLoading={picklistDetail.isLoading}
        picklistDetail={picklistDetail}
        packingLocationList={packingLocationList.results}
        history={picklistHistory}
        notificationPayload={notificationPayload}
        onSkip={handleSkip}
        onSubmit={handleSubmit}
        onProcess={handleProcess}
        onProgress={handleProgress}
        onComplete={handleComplete}
        onUnitAlter={handleUnitAlter}
        processPendingNotifications={processPendingNotifications}
        validationLoading={picklistDetailPick.isLoading}
        isCompleteLoading={picklistDetailComplete.isLoading}
        onPickerChange={handlePickerChange}
        getDetail={picklistDetail.getDetail}
        onGeneratePdf={handleGeneratePdfReport}
        alterLoading={picklistUnitAlter.isLoading}
        onCancel={handleCancel}
      />

    </DashboardLayout>

  )
}

export default PicklistDetailContainer
