import React from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import PropTypes from 'prop-types'
import { useField } from 'react-final-form'
import { useValueChanged } from 'storfox-tools'
import { prop } from 'ramda'

import DateField from '~/components/Fields/DateField'
import TagsField from '~/components/Fields/TagsField'
import TextField from '~/components/Fields/TextField'
import { CardHeader } from '~/components/Cards'
import WarehouseSearchField from '~/components/Fields/WarehouseSearchField'
import * as API from '~/constants/api'
import FileAttachment from '~/components/FileAttachment/FileAttachment'

import LineItems from './LineItems'
import PurchaseOrderSupplierInfo from './Supplier/PurchaseOrderSupplierInfo'
import CompanySearchField from './PurchaseCompanyList'

import InvoiceSummary from '../InvoiceSummary'
import PaymentTypeField from '../Fields/PaymentTypeField'
import PaymentTermField from '../Fields/PaymentTermField'

const emptyArray = []

function PurchaseOrderForm (props) {
  const {
    onSupplierCreate,
    isLoading,
    companyDisabled = false,
    purchaseOrderVariant,
    onFileAttach,
    onFileAttachRemove
  } = props

  const lineItemsField = useField('lineItems')
  const lineItems = lineItemsField.input.value || emptyArray

  const companyField = useField('company')
  const companyFieldValue = companyField.input.value
  const companyId = prop('id', companyFieldValue)

  const supplierField = useField('supplier')
  const supplierFieldChange = supplierField.input.onChange

  const deliveryAddressField = useField('deliveryAddress')
  const deliveryAddressFieldChange = deliveryAddressField.input.onChange

  const billingAddressField = useField('billingAddress')
  const billingAddressFieldChange = billingAddressField.input.onChange

  useValueChanged(() => {
    deliveryAddressFieldChange({})
    billingAddressFieldChange({})
    supplierFieldChange(null)
  }, companyId)

  return (
    <Grid container={true} spacing={2}>
      <Grid item={true} xs={12} lg={8}>
        <Card>
          <CardHeader title="Supplier" required={true} />
          <Divider />
          <CardContent>
            <PurchaseOrderSupplierInfo onSupplierCreate={onSupplierCreate} />
          </CardContent>
        </Card>
      </Grid>

      <Grid item={true} xs={12} lg={4}>
        <Card style={{ height: '100%' }}>
          <CardHeader title="Details" />
          <Divider />
          <CardContent style={{ height: '100%' }}>
            <Grid container={true} spacing={3}>
              <Grid item={true} xs={12}>
                <WarehouseSearchField
                  data-cy="warehouse"
                  name="warehouse"
                  label="Ship to warehouse"
                  api={API.PURCHASE_ORDER_WAREHOUSE_LIST}
                  required={true}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <CompanySearchField disabled={companyDisabled} required={true} />
              </Grid>

              <Grid item={true} xs={12}>
                <DateField
                  data-cy="orderDate"
                  name="orderDate"
                  label="Order date"
                  required={true}
                />
              </Grid>

              <Grid item={true} xs={12}>
                <DateField
                  data-cy="expectedDate"
                  name="expectedDate"
                  label="Expected Date"
                  required={true}
                />
              </Grid>

              <Grid item={true} xs={12}>
                <PaymentTypeField />
              </Grid>

              <Grid item={true} xs={12}>
                <PaymentTermField />
              </Grid>

              <Grid item={true} xs={12}>
                <TextField
                  data-cy="supplierReference"
                  name="supplierReference"
                  label="Supplier reference id"
                />
              </Grid>

              <Grid item={true} xs={12}>
                <TagsField
                  data-cy="tags"
                  name="tags"
                  label="Tags"
                />
              </Grid>

              {onFileAttach && (
                <Grid item={true} xs={12}>
                  <FileAttachment
                    name="attachmentUrl"
                    onUpload={onFileAttach}
                    onRemove={onFileAttachRemove}
                  />
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item={true} xs={12}>
        <Card>
          <CardHeader title="Line items" />
          <Divider />
          <LineItems isLoading={isLoading} purchaseOrderVariant={purchaseOrderVariant} />
        </Card>
      </Grid>

      <Grid item={true} xs={12} lg={6}>
        <Card>
          <CardHeader title="Invoice summary" />
          <Divider />
          <InvoiceSummary lineItems={lineItems} />
        </Card>
      </Grid>

      <Grid item={true} xs={12} lg={6}>
        <Card style={{ height: '100%' }}>
          <CardHeader title="Supplier notes" />
          <Divider />
          <CardContent style={{ height: '100%' }}>
            <TextField
              label="Notes"
              name="notes"
              data-cy="notes"
              multiline={true}
              minRows={8}
            />
          </CardContent>
        </Card>
      </Grid>

    </Grid>
  )
}

PurchaseOrderForm.propTypes = {
  onSupplierCreate: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  companyDisabled: PropTypes.bool,
  purchaseOrderVariant: PropTypes.object.isRequired,
  onFileAttach: PropTypes.func,
  onFileAttachRemove: PropTypes.func
}

export default PurchaseOrderForm
