import React from 'react'
import { Filter } from 'storfox-filter'
import Grid from '@mui/material/Grid'
import { prop } from 'ramda'

import { escapeAtob, unescapeBtoa } from '~/utils'
import TextField from '~/components/Fields/TextField'
import Accordion from '~/components/Accordion'

import LocationStatusField from './LocationStatusField'

import LocationTypeField from '../../Fields/LocationTypeField'
import ZoneField from '../../Fields/ZoneField'
import AreaField from '../../Fields/AreaField'
import { LOCATION_FILTER_NAME as name } from '../../../constants'

export const toParams = values => {
  const status = prop('status', values)
  const locationType = prop('locationType', values)
  const area = unescapeBtoa(prop('area', values))
  const zone = unescapeBtoa(prop('zone', values))
  const maxLoadStart = prop('maxLoadStart', values)
  const maxLoadEnd = prop('maxLoadEnd', values)
  const usedVolumeStart = prop('usedVolumeStart', values)
  const usedVolumeEnd = prop('usedVolumeEnd', values)
  const utilizationStart = prop('utilizationStart', values)
  const utilizationEnd = prop('utilizationEnd', values)
  const containersCountStart = prop('containersCountStart', values)
  const containersCountEnd = prop('containersCountEnd', values)

  return {
    status,
    locationType,
    area,
    zone,
    maxLoadStart,
    maxLoadEnd,
    usedVolumeStart,
    usedVolumeEnd,
    utilizationStart,
    utilizationEnd,
    containersCountStart,
    containersCountEnd
  }
}

export const toValues = params => {
  const status = prop('status', params)
  const locationType = prop('locationType', params)
  const area = escapeAtob(prop('area', params))
  const zone = escapeAtob(prop('zone', params))
  const maxLoadStart = prop('maxLoadStart', params)
  const maxLoadEnd = prop('maxLoadEnd', params)
  const usedVolumeStart = prop('usedVolumeStart', params)
  const usedVolumeEnd = prop('usedVolumeEnd', params)
  const utilizationStart = prop('utilizationStart', params)
  const utilizationEnd = prop('utilizationEnd', params)
  const containersCountStart = prop('containersCountStart', params)
  const containersCountEnd = prop('containersCountEnd', params)

  return {
    status,
    locationType,
    area,
    zone,
    maxLoadStart,
    maxLoadEnd,
    usedVolumeStart,
    usedVolumeEnd,
    utilizationStart,
    utilizationEnd,
    containersCountStart,
    containersCountEnd
  }
}

export const transformer = { toParams, toValues }

export const locationFilterOptions = {
  name,
  transformer
}

function LocationFilterForm (props) {
  return (
    <Filter {...props}>
      <Accordion title="General" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <LocationStatusField />
          </Grid>
          <Grid item={true} xs={12}>
            <LocationTypeField />
          </Grid>
          <Grid item={true} xs={12}>
            <AreaField
              data-cy="area"
              name="area"
              warehouse="warehouses"
              label="Area"
            />
          </Grid>
          <Grid item={true} xs={12}>
            <ZoneField
              data-cy="zone"
              name="zone"
              label="Zone"
              area="area"
            />
          </Grid>
        </Grid>
      </Accordion>
      <Accordion title="Utilization">
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={6}>
            <TextField
              data-cy="maxLoadStart"
              name="maxLoadStart"
              label="Max Load Start"
            />
          </Grid>
          <Grid item={true} xs={6}>
            <TextField
              data-cy="maxLoadEnd"
              name="maxLoadEnd"
              label="Max Load End"
            />
          </Grid>
          <Grid item={true} xs={6}>
            <TextField
              data-cy="usedVolumeStart"
              name="usedVolumeStart"
              label="Min Used Volume"
            />
          </Grid>
          <Grid item={true} xs={6}>
            <TextField
              data-cy="usedVolumeEnd"
              name="usedVolumeEnd"
              label="Max Used Volume"
            />
          </Grid>
          <Grid item={true} xs={6}>
            <TextField
              data-cy="utilizationStart"
              name="utilizationStart"
              label="Min Utilization"
            />
          </Grid>
          <Grid item={true} xs={6}>
            <TextField
              data-cy="utilizationEnd"
              name="utilizationEnd"
              label="Max Utilization"
            />
          </Grid>
          <Grid item={true} xs={6}>
            <TextField
              data-cy="containersCountStart"
              name="containersCountStart"
              label="Min Containers Count"
            />
          </Grid>
          <Grid item={true} xs={6}>
            <TextField
              data-cy="containersCountEnd"
              name="containersCountEnd"
              label="Max Containers Count"
            />
          </Grid>
        </Grid>
      </Accordion>
    </Filter>
  )
}

export default LocationFilterForm
