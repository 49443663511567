import React from 'react'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'

import Configuration from '../../components/Configuration'

function ConfigurationContainer () {
  const breadcrumbs = { title: NAV.CONFIGURATION }

  return (
    <DashboardLayout
      activeNav={NAV.CONFIGURATION}
      title="Configuration menu"
      breadcrumbs={breadcrumbs}
    >
      <Configuration />
    </DashboardLayout>
  )
}

export default ConfigurationContainer
