import React from 'react'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import PropTypes from 'prop-types'

const MenuHeader = React.forwardRef((props, ref) => {
  const {
    children,
    onClick,
    boxClassName,
    ...rest
  } = props

  return (
    <MenuItem onClick={onClick} {...rest} ref={ref}>
      <Box
        className={boxClassName}
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          minHeight: 50
        }}
      >
        {children}
      </Box>
    </MenuItem>
  )
})

MenuHeader.propTypes = {
  children: PropTypes.any.isRequired,
  onClick: PropTypes.func.isRequired,
  boxClassName: PropTypes.string
}

export default MenuHeader
