import React from 'react'
import PropTypes from 'prop-types'
import { Chip } from '@mui/material'
import Typography from '@mui/material/Typography'

import TextLabel from '~/components/TextLabel'

function DestinationPreview ({ sourceLocation, onSourceLocationRemove }) {
  return (
    <TextLabel label="Source location">
      {sourceLocation ? (
        <Chip
          color="info"
          label={sourceLocation}
          onDelete={onSourceLocationRemove}
        />
      ) : (
        <Typography variant="body1">
          No location scanned
        </Typography>
      )}
    </TextLabel>
  )
}

DestinationPreview.propTypes = {
  sourceLocation: PropTypes.string,
  onSourceLocationRemove: PropTypes.func.isRequired
}

export default DestinationPreview
