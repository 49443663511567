import React from 'react'
import { Ordering } from 'storfox-filter'

import { LISTING_COLUMNS as tableColumns } from './ListingTable'

import { FILTER_NAME as name } from '../../constants'

export const listingOrderingOptions = {
  name,
  tableColumns
}

function ListingOrderingForm (props) {
  return (
    <Ordering {...props} />
  )
}

export default ListingOrderingForm
