import React from 'react'

import * as API from '~/constants/api'

import MultiSelectSearchField from './MultiSelectSearchField'

function VariantsMultiSelectField (props) {
  return (
    <MultiSelectSearchField
      api={API.VARIANT_LIST}
      label="Users"
      params={{ limit: 10 }}
      {...props}
    />
  )
}

export default VariantsMultiSelectField
