import React, { useRef, useState } from 'react'
import { defaultTo, equals, find, map, path, pathOr, pipe, prop, propOr } from 'ramda'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import { getCancelToken, useDeepCompareEffect, useRequest } from 'storfox-api-hooks'

import useDebounce from '~/hooks/useDebounce'

import FieldWrapper from './FieldWrapper'

const defaultGetOptionValue = value => {
  if (value) {
    const { id, guid, name } = value
    return { id, guid, name }
  }

  return null
}

const defaultGetOptionLabel = value => {
  if (value) {
    return propOr('', 'name', value)
  }

  return ''
}

const getItemFromResults = (results, id, key) =>
  find(
    pipe(
      prop(key),
      equals(id)
    )
  )(results)

function SearchField (props) {
  const {
    api,
    input,
    meta,
    InputProps,
    PaperComponent,
    ListboxProps,
    ListboxComponent,
    renderOption,
    searchFieldProps,
    disableCloseOnSelect,
    primaryKey = 'id',
    params = { limit: 30 },
    disabled = false,
    isLoading = false,
    disableClearable = false,
    fullWidth = true,
    size = 'small',
    prepareOptions = options => options,
    onChange = () => {},
    onInputChange = () => {},
    onValueChange = () => {},
    getOptionLabel = defaultGetOptionLabel,
    getOptionValue = defaultGetOptionValue,
    ...defaultProps
  } = props

  const request = useRequest()
  const [value, setValue] = useState('')
  const debouncedValue = useDebounce(value)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [searching, setSearching] = useState(false)
  const ref = useRef([])

  useDeepCompareEffect(() => {
    let active = true

    const { token, cancel } = getCancelToken()

    if (open) {
      setLoading(true)
      onInputChange(debouncedValue)
      const searchValue = searching ? debouncedValue : ''
      request.get(api, { ...params, search: searchValue }, { cancelToken: token })
        .then(response => {
          const results = defaultTo([], path(['data', 'results'], response))

          if (active) {
            ref.current = results
            const newOptions = pipe(
              prepareOptions,
              map(getOptionValue)
            )(results)

            setOptions(newOptions)
            setLoading(false)
          }
        })
    }

    return () => {
      active = false
      cancel()
    }
  }, [open, debouncedValue, searching])

  React.useEffect(() => {
    if (!open) {
      ref.current = []
      setOptions([])
    }
  }, [open])

  const submitError = pathOr(null, ['submitError', 0], meta)

  return (
    <Autocomplete
      open={open}
      disabled={disabled}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      disableClearable={disableClearable}
      disableCloseOnSelect={disableCloseOnSelect}
      value={input.value}
      isOptionEqualToValue={(option, value) => option[primaryKey] === value[primaryKey]}
      getOptionLabel={getOptionLabel}
      options={options.length === 0 && !open ? [input.value] : options}
      loading={loading}
      filterOptions={item => item}
      onChange={(event, value) => {
        if (value) {
          onChange(event, getItemFromResults(ref.current, value[primaryKey], primaryKey))
        } else {
          onChange(event, null)
        }
        input.onChange(value)
        onValueChange(value)
        setSearching(false)
      }}
      PaperComponent={PaperComponent}
      ListboxComponent={ListboxComponent}
      size={size}
      fullWidth={fullWidth}
      ListboxProps={ListboxProps}
      renderOption={renderOption}
      onInputChange={(event, value) => {
        setValue(value)
        setOptions([])
      }}
      onKeyDown={event => {
        if (event.key === 'Enter') {
          event.preventDefault()
        }
      }}
      renderInput={params => {
        return (
          <TextField
            error={meta.invalid}
            helperText={submitError || meta.error}
            {...params}
            {...defaultProps}
            onBlur={() => {
              setSearching(false)
              setLoading(false)
            }}
            onKeyDown={() => setSearching(true)}
            InputProps={{
              ...params.InputProps,
              ...InputProps,
              endAdornment: (
                <>
                  {(isLoading || loading) ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )
      }}
      {...searchFieldProps}
    />
  )
}

SearchField.propTypes = {
  api: PropTypes.string.isRequired,
  input: PropTypes.object,
  meta: PropTypes.object,
  primaryKey: PropTypes.string,
  params: PropTypes.object,
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  renderOption: PropTypes.func,
  isLoading: PropTypes.bool,
  getOptionValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
  disableClearable: PropTypes.bool,
  InputProps: PropTypes.object,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  PaperComponent: PropTypes.func,
  ListboxComponent: PropTypes.func,
  ListboxProps: PropTypes.object,
  onValueChange: PropTypes.func,
  prepareOptions: PropTypes.func,
  disableCloseOnSelect: PropTypes.bool,
  searchFieldProps: PropTypes.shape({
    PopperComponent: PropTypes.func
  })
}

export default FieldWrapper(SearchField)
