import React from 'react'
import PropTypes from 'prop-types'

import * as API from '~/constants/api'
import SearchField from '~/components/Fields/SearchField'

const getOptionValue = value => {
  if (value) {
    const { id, guid, name, label } = value
    return { id, guid, name, label }
  }

  return null
}

function ZoneField ({ params, ...props }) {
  return (
    <SearchField
      name="zone"
      data-cy="zone"
      label="Zone"
      api={API.ZONE_LIST}
      ListboxProps={{ 'data-cy': 'zoneList' }}
      getOptionValue={getOptionValue}
      params={params}
      {...props}
    />
  )
}

ZoneField.propTypes = {
  params: PropTypes.object
}

export default ZoneField
