import React from 'react'
import PropTypes from 'prop-types'

import TableSplitButton from '~/components/TableSplitButton'
import useDeleteConfirm from '~/components/TableSplitButton/useDeleteConfirm'

function BulkActionButton ({ onContainerTypesDelete, loading }) {
  const { handleClick } = useDeleteConfirm()

  const options = [
    {
      label: 'Delete',
      'data-cy': 'deleteMenu',
      onMenuClick: () => handleClick(onContainerTypesDelete)
    },
  ]

  return (
    <TableSplitButton
      options={options}
      name="containerType"
      loading={loading}
    />
  )
}

BulkActionButton.propTypes = {
  onContainerTypesDelete: PropTypes.func.isRequired,
  loading: PropTypes.bool
}

export default BulkActionButton
