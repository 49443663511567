import React from 'react'
import { useField } from 'react-final-form'
import PropTypes from 'prop-types'
import { QueryBuilder as StorfoxQueryBuilder } from 'storfox-query-builder'

import ValueEditorField from './ValueEditorField'

import {
  LISTING_CREATED,
  LOW_STOCK,
  ORDER_CHANGED,
  ORDER_CREATED,
  PACKING_CREATED,
  PICKING_CREATED,
  PURCHASE_CHANGE,
  PUTAWAY_CREATED,
  PUTAWAY_V2_CREATED,
  REPLENISHMENT,
  SHIPMENT_CREATED,
  SHIPMENT_SET_STATUS
} from '../../../constants/AutomationRule'
import * as TYPES from '../valueEditorTypes'

const OPERATORS = [
  { name: 'equals', label: 'Equals' },
  { name: 'not equal', label: 'Not equal' },
  { name: 'in', label: 'In' },
]

const PUTAWAY_FIELDS = [
  { name: 'warehouse', operators: OPERATORS, label: 'Warehouse', valueEditorType: TYPES.WAREHOUSE },
  { name: 'company', operators: OPERATORS, label: 'Company', valueEditorType: TYPES.COMPANY }
]

const PUTAWAY_V2_FIELDS = [
  { name: 'warehouse', operators: OPERATORS, label: 'Warehouse', valueEditorType: TYPES.WAREHOUSE },
  { name: 'company', operators: OPERATORS, label: 'Company', valueEditorType: TYPES.COMPANY },
  { name: 'category', operators: OPERATORS, label: 'Category', valueEditorType: TYPES.CATEGORY },
  { name: 'variant', operators: OPERATORS, label: 'Variant', valueEditorType: TYPES.VARIANT }
]

const PICKING_FIELDS = [
  { name: 'warehouse', operators: OPERATORS, label: 'Warehouse', valueEditorType:TYPES.WAREHOUSE },
  { name: 'company', operators: OPERATORS, label: 'Company', valueEditorType: TYPES.COMPANY }
]

const PACKING_FIELDS = [
  { name: 'warehouse', operators: OPERATORS, label: 'Warehouse', valueEditorType: TYPES.WAREHOUSE },
  { name: 'company', operators: OPERATORS, label: 'Company', valueEditorType: TYPES.COMPANY }
]

const ORDER_CREATED_FIELDS = [
  {
    name: 'deliveryCountry',
    operators: OPERATORS,
    label: 'Delivery country',
    fieldLabel: 'Country',
    valueEditorType: TYPES.COUNTRY
  },
  {
    name: 'channel',
    operators: OPERATORS,
    label: 'Channel',
    valueEditorType: TYPES.CHANNEL
  },
  {
    name: 'orderStatus',
    operators: OPERATORS,
    label: 'Order status',
    valueEditorType: TYPES.ORDER_STATUS
  },
  {
    name: 'shipmentStatus',
    operators: OPERATORS,
    label: 'Shipment status',
    valueEditorType: TYPES.ORDER_SHIPMENT_STATUS
  },
  {
    name: 'paymentStatus',
    operators: OPERATORS,
    label: 'Payment status',
    valueEditorType: TYPES.ORDER_PAYMENT_STATUS
  },
  {
    name: 'allocationStatus',
    operators: OPERATORS,
    label: 'Allocation status',
    valueEditorType: TYPES.ORDER_ALLOCATION_STATUS
  },
  {
    name: 'fulfillmentStatus',
    operators: OPERATORS,
    label: 'Fulfillment status',
    valueEditorType: TYPES.ORDER_FULFILLMENT_STATUS
  }
]

const ORDER_CHANGED_FIELDS = [
  {
    name: 'deliveryCountry',
    operators: OPERATORS,
    label: 'Delivery country',
    fieldLabel: 'Country',
    valueEditorType: TYPES.COUNTRY
  },
  {
    name: 'channel',
    operators: OPERATORS,
    label: 'Channel',
    valueEditorType: TYPES.CHANNEL
  },
  {
    name: 'orderStatus',
    operators: OPERATORS,
    label: 'Order status',
    valueEditorType: TYPES.ORDER_STATUS
  },
  {
    name: 'shipmentStatus',
    operators: OPERATORS,
    label: 'Shipment status',
    valueEditorType: TYPES.ORDER_SHIPMENT_STATUS
  },
  {
    name: 'paymentStatus',
    operators: OPERATORS,
    label: 'Payment status',
    valueEditorType: TYPES.ORDER_PAYMENT_STATUS
  },
  {
    name: 'allocationStatus',
    operators: OPERATORS,
    label: 'Allocation status',
    valueEditorType: TYPES.ORDER_ALLOCATION_STATUS
  },
  {
    name: 'fulfillmentStatus',
    operators: OPERATORS,
    label: 'Fulfillment status',
    valueEditorType: TYPES.ORDER_FULFILLMENT_STATUS
  },
  {
    name: 'deliveryMethod',
    operators: OPERATORS,
    label: 'Delivery method',
    valueEditorType: TYPES.DELIVERY_METHOD
  }
]

const SHIPMENT_FIELDS = [
  { name: 'deliveryCountry', operators: OPERATORS, label: 'Delivery Country', valueEditorType: TYPES.COUNTRY },
  { name: 'company', operators: OPERATORS, label: 'Company', valueEditorType: TYPES.COMPANY },
  { name: 'deliveryCity', operators: OPERATORS, label: 'Delivery City', valueEditorType: TYPES.CITY },
  { name: 'deliveryMethod', operators: OPERATORS, label: 'Delivery Method', valueEditorType: TYPES.DELIVERY_METHOD },
  { name: 'warehouse', operators: OPERATORS, label: 'Warehouse', valueEditorType: TYPES.WAREHOUSE },
  { name: 'paymentMethod', operators: OPERATORS, label: 'Payment Method', valueEditorType: TYPES.PAYMENT_METHOD },
  { name: 'carrier', operators: OPERATORS, label: 'Carrier', valueEditorType: TYPES.SHIPMENT_CARRIER },
  { name: 'carrierCity', operators: OPERATORS, label: 'Carrier City', valueEditorType: TYPES.SHIPMENT_CARRIER_CITY },
]

const LOW_STOCK_FIELDS = [
  { name: 'warehouse', operators: OPERATORS, label: 'Warehouse', valueEditorType: TYPES.WAREHOUSE },
  { name: 'condition', operators: OPERATORS, label: 'Condition', valueEditorType: TYPES.CONDITION },
]

const REPLENISHMENT_FIELDS = [
  { name: 'warehouse', operators: OPERATORS, label: 'Warehouse', valueEditorType: TYPES.WAREHOUSE },
]

const LISTING_CREATED_FIELDS = [
  { name: 'channel', operators: OPERATORS, label: 'Channel', valueEditorType: TYPES.CHANNEL },
  { name: 'listingType', operators: OPERATORS, label: 'Listing type', valueEditorType: TYPES.LISTING_TYPE },
  { name: 'listingStatus', operators: OPERATORS, label: 'Listing status', valueEditorType: TYPES.LISTING_STATUS },
  { name: 'company', operators: OPERATORS, label: 'Company', valueEditorType: TYPES.COMPANY },
]

const PURCHASE_CHANGE_FIELDS = [
  { name: 'purchaseStatus', operators: OPERATORS, label: 'Purchase Status', valueEditorType: TYPES.PURCHASE_STATUS },
  { name: 'supplier', operators: OPERATORS, label: 'Supplier', valueEditorType: TYPES.SUPPLIER },
  { name: 'company', operators: OPERATORS, label: 'Company', valueEditorType: TYPES.COMPANY },
  { name: 'warehouse', operators: OPERATORS, label: 'Warehouse', valueEditorType: TYPES.WAREHOUSE },
]

const SHIPMENT_CHANGE_FIELDS = [
  { name: 'status', operators: OPERATORS, label: 'Shipment Status', valueEditorType: TYPES.SHIPMENT_STATUS },
]

const FIELDS = {
  [ORDER_CREATED]: ORDER_CREATED_FIELDS,
  [ORDER_CHANGED]: ORDER_CHANGED_FIELDS,
  [PICKING_CREATED]: PICKING_FIELDS,
  [PUTAWAY_CREATED]: PUTAWAY_FIELDS,
  [PUTAWAY_V2_CREATED]: PUTAWAY_V2_FIELDS,
  [PACKING_CREATED]: PACKING_FIELDS,
  [SHIPMENT_CREATED]: SHIPMENT_FIELDS,
  [LOW_STOCK]: LOW_STOCK_FIELDS,
  [REPLENISHMENT]: REPLENISHMENT_FIELDS,
  [LISTING_CREATED]: LISTING_CREATED_FIELDS,
  [PURCHASE_CHANGE]: PURCHASE_CHANGE_FIELDS,
  [SHIPMENT_SET_STATUS]: SHIPMENT_CHANGE_FIELDS
}

function QueryBuilder ({ event }) {
  const field = useField('rule')

  function handleQueryChange (value) {
    field.input.onChange(value)
  }

  return (
    <StorfoxQueryBuilder
      fields={FIELDS[event]}
      query={field.input.value}
      onQueryChange={handleQueryChange}
      valueEditor={ValueEditorField}
    />
  )
}

QueryBuilder.propTypes = {
  event: PropTypes.string.isRequired
}

export default QueryBuilder
