import { path, prop } from 'ramda'

export function ContainerCreateSerializer (warehouseGuid, formValues) {
  const location = prop('location', formValues)
  const containerTypeGuid = path(['containerType', 'guid'], formValues)
  const quantity = prop('quantity', formValues)
  const containerType = containerTypeGuid && { guid : containerTypeGuid }
  const warehouse = { guid: warehouseGuid }

  return { containerType, warehouse, quantity, location }
}
