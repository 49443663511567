import { Card } from '@mui/material'
import { prop } from 'ramda'
import React from 'react'
import PropTypes from 'prop-types'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import { useField } from 'react-final-form'
import { FieldArrayWrapper } from 'storfox-form-fields'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import Add from '@mui/icons-material/Add'

import { Button, DeleteConfirmButton } from '~/components/Buttons'
import TextField from '~/components/Fields/TextField'
import CountryField from '~/components/Fields/CountryField'
import { CardHeader } from '~/components/Cards'

import PrimarySwitchField from '../Fields/PrimarySwitchField'

const EMPTY_ADDRESS = {
  firstName: '',
  lastName: '',
  address: '',
  country: '',
  city: '',
  zipcode: ''
}

function SupplierAddresses ({ fields, title, onCopy }) {
  const { t } = useTranslation()

  const addressesField = useField(fields.name)
  const addresses = addressesField.input.value

  const onAdd = () => fields.push(EMPTY_ADDRESS)
  const onRemove = index => fields.remove(index)
  const addressCount = prop('length', fields)

  const handleAddressChange = addressesField.input.onChange

  const handlePrimaryChange = primaryIndex => {
    const newAddresses = addresses.map((address, index) => {
      return { ...address, isPrimary: index === primaryIndex }
    })

    handleAddressChange(newAddresses)
  }

  return (
    <>
      {fields.map((name, index) => (
        <Box mb={3} key={index}>
          <Card>
            <CardHeader
              title={(
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="h6">{title}</Typography>
                  {onCopy && (
                    <IconButton
                      title="Copy to billing address"
                      size="small"
                      onClick={() => onCopy(index)}
                    >
                      <FileCopyIcon fontSize="inherit" />
                    </IconButton>
                  )}
                </Box>
              )}
            />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3} key={index}>
                <Grid item={true} xs={12} lg={6}>
                  <TextField
                    data-cy={`${name}.firstName`}
                    name={`${name}.firstName`}
                    label="First name"
                  />
                </Grid>

                <Grid item={true} xs={12} lg={6}>
                  <TextField
                    data-cy={`${name}.lastName`}
                    name={`${name}.lastName`}
                    label="Last name"
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <TextField
                    data-cy={`${name}.address`}
                    name={`${name}.address`}
                    label={t('Address')}
                    multiline={true}
                    minRows={3}
                  />
                </Grid>

                <Grid item={true} xs={6}>
                  <TextField
                    data-cy={`${name}.city`}
                    name={`${name}.city`}
                    label={t('City')}
                    country={`${name}.country`}
                  />
                </Grid>

                <Grid item={true} xs={6}>
                  <TextField
                    data-cy={`${name}.zipcode`}
                    name={`${name}.zipcode`}
                    label={t('Zipcode')}
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <CountryField
                    data-cy={`${name}.country`}
                    name={`${name}.country`}
                    label={t('Country')}
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    {addressCount > 1 && <DeleteConfirmButton onClick={() => onRemove(index)} />}

                    {index === addressCount - 1 && (
                      <Button
                        startIcon={<Add />}
                        onClick={onAdd}

                      >
                        Add another address
                      </Button>
                    )}

                    <PrimarySwitchField
                      name={`${name}.isPrimary`}
                      onChange={() => handlePrimaryChange(index)}
                    />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      )
      )}
    </>
  )
}

SupplierAddresses.propTypes = {
  fields: PropTypes.object,
  title: PropTypes.string,
  onCopy: PropTypes.func
}

export default FieldArrayWrapper(SupplierAddresses)
