import React from 'react'

import MinLogoIcon from './min-logo-1.svg'

function MinLogo (props) {
  return (
    <MinLogoIcon {...props} />
  )
}

export default MinLogo
