import React from 'react'
import PropTypes from 'prop-types'
import { generatePath, useParams } from 'react-router-dom'
import { prop } from 'ramda'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'

import { getRouteTab } from '~/utils'
import PageTitle from '~/components/PageTitle'
import { EditButton } from '~/components/Buttons'
import Container, { Content, Header } from '~/components/Container'
import History from '~/components/History'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import { CUSTOMER_DETAIL_TABS, CUSTOMER_UPDATE_TABS } from '~/constants/tabs'
import LoaderProvider from '~/components/Loader'

import CustomerDetailTabs from './CustomerDetailTabs'
import CustomerDetailGeneral from './CustomerDetailGeneral'
import CustomerDetailAddress from './CustomerDetailAddress'
import CustomerUpdateOrders from './CustomerDetailOrders'

export const getInitialValue = initialValues => {
  const deliveryAddresses = prop('deliveryAddresses', initialValues) || []
  const billingAddresses = prop('billingAddresses', initialValues) || []

  return {
    ...initialValues,
    deliveryAddresses: deliveryAddresses.length ? deliveryAddresses : [{}],
    billingAddresses: billingAddresses.length ? billingAddresses : [{}]
  }
}

function CustomerDetail (props) {
  const {
    pageTitle,
    pageTitleLoading,
    isLoading,
    orderList,
    customerHistory,
    detail
  } = props

  const { guid, tab } = useParams()

  const editTab = getRouteTab(tab, CUSTOMER_UPDATE_TABS, CUSTOMER_UPDATE_TABS.GENERAL)
  const editPath = generatePath(ROUTES.CUSTOMER_UPDATE_PATH, { guid, tab: editTab })

  const billingAddresses = prop('billingAddresses', detail)
  const deliveryAddresses = prop('deliveryAddresses', detail)

  return (
    <LoaderProvider isLoading={isLoading}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={pageTitle}
            pageTitleLoading={pageTitleLoading}
            parentTitle={NAV.CUSTOMERS}
            rightButton={<EditButton path={editPath} disabled={isLoading} />}
          />
        </Header>

        <Content>
          <CustomerDetailTabs value={tab} />

          <Divider />
          <Box mb={3} />

          {CUSTOMER_DETAIL_TABS.GENERAL === tab && <CustomerDetailGeneral detail={detail} />}
          {CUSTOMER_DETAIL_TABS.ADDRESS === tab && (
            <CustomerDetailAddress
              billingAddresses={billingAddresses}
              deliveryAddresses={deliveryAddresses}
            />
          )}
          {CUSTOMER_DETAIL_TABS.ORDERS === tab && <CustomerUpdateOrders orderList={orderList} />}
          {CUSTOMER_DETAIL_TABS.HISTORY === tab && <History history={customerHistory} />}
          <Box mb={3} />
        </Content>
      </Container>
    </LoaderProvider>
  )
}

CustomerDetail.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  pageTitleLoading: PropTypes.bool.isRequired,
  detail: PropTypes.object.isRequired,
  orderList: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  customerHistory: PropTypes.object
}

export default CustomerDetail
