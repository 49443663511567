import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useField } from 'react-final-form'

import SplitButton from '~/components/Buttons/SplitButton'
import { SaveButton } from '~/components/Buttons'

import { SAVE_DRAFT, SAVE_PROCESS } from '../../constants'

function StockCountCreateButton ({ onSubmit, isLoading }) {
  const submitTypeField = useField('submitType')
  const submitTypeFieldChange = submitTypeField.input.onChange

  const onClickSaveDraft = useCallback(() => {
    submitTypeFieldChange(SAVE_DRAFT)
    onSubmit()
  }, [onSubmit, submitTypeFieldChange])

  const onClickSaveProcess = useCallback(() => {
    submitTypeFieldChange(SAVE_PROCESS)
    onSubmit()
  }, [onSubmit, submitTypeFieldChange])

  const splitButtonOptions = [
    {
      label: 'Save draft',
      button: (
        <SaveButton
          disabled={isLoading}
          children="Save draft"
          type="button"
          onClick={onClickSaveDraft}
        />
      ),
      'data-cy': 'saveDraftMenu',
      onMenuClick: onClickSaveDraft
    },
    {
      label: 'Save & process',
      button: (
        <SaveButton
          type="button"
          onClick={onClickSaveProcess}
          disabled={isLoading}
          data-cy="save-process"
          children="Save & process"
        />
      ),
      'data-cy': 'saveProcessMenu',
      onMenuClick: onClickSaveProcess
    }
  ]

  return (
    <SplitButton
      name="stockCountCreate"
      options={splitButtonOptions}
    />
  )
}

StockCountCreateButton.propTypes = {
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func
}

export default StockCountCreateButton
