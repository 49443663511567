import { pathOr, prop } from 'ramda'
import React from 'react'
import { useParams } from 'react-router-dom'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import { PRODUCT_DETAIL_TABS } from '~/constants/tabs'

import ProductDetail from '../components/ProductDetail'
import { useProductDetail, useProductHistory } from '../hooks'

function ProductUpdateContainer () {
  const { id, tab } = useParams()
  const productDetail = useProductDetail(id)

  const guid = prop('guid', productDetail.detail)
  const historyAutoSend = Boolean(guid && tab === PRODUCT_DETAIL_TABS.HISTORY)

  const productHistory = useProductHistory(guid, historyAutoSend)

  const title = pathOr('Products', ['detail', 'name'], productDetail)

  const isLoading = productDetail.isLoading
  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      isLoading={isLoading}
      activeNav={NAV.PRODUCTS}
      breadcrumbs={breadcrumbs}
    >
      <ProductDetail
        title={title}
        detail={productDetail.detail}
        productHistory={productHistory}
        isLoading={productDetail.isLoading}
      />
    </DashboardLayout>
  )
}

export default ProductUpdateContainer
