import { useContext } from 'react'

import { TableValuesContext } from './context'

export const useTableValues = () => {
  const {
    getTableValues,
    handleTableValuesAdd,
    getTableValuesByName,
    resetTableValues
  } = useContext(TableValuesContext)

  return { getTableValues, handleTableValuesAdd, getTableValuesByName, resetTableValues }
}

export const useTableSelects = () => {
  const {
    selects,
    handleTableSelectsAdd,
    resetTableSelects
  } = useContext(TableValuesContext)

  return { selects, handleTableSelectsAdd, resetTableSelects }
}
