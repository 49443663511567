import React from 'react'
import { useParams } from 'react-router-dom'
import { useSnackbar } from 'storfox-snackbar'
import { prop } from 'ramda'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import useMessages from '~/hooks/useMessages'
import { useImageUpload } from '~/components/ImageUpload'
import { generateCdnUrl } from '~/utils'

import { TemplateUpdateSerializer } from '../../serializers/Template'
import { useTemplateDetail, useTemplatePreview, useTemplateUpdate } from '../../hooks/Template'
import TemplateUpdateForm, { TEMPLATE_VIEW } from '../../components/Templates/TemplateUpdateForm'

function TemplateUpdateContainer () {
  const { type } = useParams()
  const snackbar = useSnackbar()
  const messages = useMessages()
  const templateDetail = useTemplateDetail(type)
  const templateUpdate = useTemplateUpdate(type)
  const templatePreview = useTemplatePreview()
  const { upload } = useImageUpload()
  const onUpload = (data) => {
    const formData = new FormData()
    formData.append('files', data)
    formData.append('resize', true)

    return upload(formData)
      .then(response => {
        return generateCdnUrl(response['ids'][0])
      })
  }

  const html = prop('htmlBody', templateDetail.detail)

  const handleSubmit = (values) =>
    templateUpdate.update(TemplateUpdateSerializer(html, values))
      .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
      .then(() => templateDetail.getDetail())

  const handlePreview = (values) =>
    templatePreview.preview(TemplateUpdateSerializer(html, values))

  const isLoading = templateDetail.isLoading
  const title = isLoading ? 'Template' : prop('name', templateDetail.detail)
  const breadcrumbs = { title: 'Template' }

  return (
    <DashboardLayout
      breadcrumbs={breadcrumbs}
      title={title}
      isLoading={isLoading}
      activeNav={NAV.CONFIGURATION}
    >
      <TemplateUpdateForm
        isLoading={isLoading}
        title={title}
        type={type}
        initialValues={{ ...templateDetail.detail, editor: TEMPLATE_VIEW.CODE }}
        onSubmit={handleSubmit}
        onPreview={handlePreview}
        onImageUpload={onUpload}
      />
    </DashboardLayout>
  )
}

export default TemplateUpdateContainer
