import React from 'react'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { path, prop } from 'ramda'
import PropTypes from 'prop-types'

import { CardHeader } from '~/components/Cards'
import TextLabelLoader from '~/components/TextLabelLoader'
import LoaderProvider from '~/components/Loader'
import MultiImagePreview from '~/components/ImageUpload/MultiImagePreview'

function ListingProductDetail ({ isLoading, detail }) {
  const name = prop('name', detail)
  const type = prop('type', detail)
  const tags = prop('tags', detail)
  const description = prop('description', detail)
  const brand = path(['brand', 'name'], detail)
  const category = path(['category', 'name'], detail)
  const imagePath = prop('imagePath', detail)

  return (
    <LoaderProvider isLoading={isLoading}>
      <Grid>
        <Box mt={3} mb={3}>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12} lg={8}>
              <Box mb={3}>
                <Card>
                  <CardHeader title="Details" />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12}>
                        <TextLabelLoader label="Name">
                          {name}
                        </TextLabelLoader>
                      </Grid>

                      <Grid item={true} xs={12}>
                        <TextLabelLoader label="Type">
                          {type}
                        </TextLabelLoader>
                      </Grid>

                      <Grid item={true} xs={12}>
                        <TextLabelLoader label="Description">
                          {description}
                        </TextLabelLoader>
                      </Grid>

                    </Grid>
                  </CardContent>
                </Card>
              </Box>
              <Box mb={3}>
                <Card>
                  <CardHeader title="Image" />
                  <Divider />
                  <CardContent>
                    <MultiImagePreview images={imagePath} alt="Image" />
                  </CardContent>
                </Card>
              </Box>
            </Grid>
            <Grid item={true} xs={12} lg={4}>
              <Box mb={3}>
                <Card>
                  <CardHeader title="Organization" />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12} lg={12}>
                        <TextLabelLoader label="Category">
                          {category}
                        </TextLabelLoader>
                      </Grid>

                      <Grid item={true} xs={12} lg={12}>
                        <TextLabelLoader label="Brand">
                          {brand}
                        </TextLabelLoader>
                      </Grid>

                      <Grid item={true} xs={12} lg={12}>
                        <TextLabelLoader label="Tags">
                          {tags}
                        </TextLabelLoader>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Grid>

          </Grid>
        </Box>
      </Grid>
    </LoaderProvider>
  )
}

ListingProductDetail.propTypes = {
  detail: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default ListingProductDetail
