import React from 'react'
import { useConfirm } from 'storfox-confirm-dialog'
import PropTypes from 'prop-types'

function ConfirmDialog ({ message, onAgree, children, ...props }) {
  const onConfirm = useConfirm()

  const handleClick = () =>
    onConfirm({ message })
      .agree(() => {
        onAgree()
      })
      .disagree()

  return (
    <>
      {children({ onClick: handleClick, ...props })}
    </>
  )
}

ConfirmDialog.defaultProps = {
  message: 'Are you sure?'
}

ConfirmDialog.propTypes = {
  message: PropTypes.string.isRequired,
  onAgree: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired
}

export default ConfirmDialog
