import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'storfox-snackbar'
import { prop } from 'ramda'
import { useTranslation } from 'react-i18next'

import { DashboardLayout } from '~/components/Layouts'
import { useNotification } from '~/components/Notification'
import { ACTIVE } from '~/components/Fields/StatusField'
import { useMe, useProfile } from '~/components/Profile'
import * as NAV from '~/constants/nav-titles'
import { WAREHOUSE_LIST_PATH } from '~/constants/routes'
import { WAREHOUSE_CREATE } from '~/constants/notification-topics'

import { useWarehouseCreate } from '../hooks'
import { WarehouseCreateSerializer } from '../serializers'
import WarehouseCreateForm from '../components/WarehouseCreate'
import { PER_ORDER } from '../components/Fields/PickingStrategyField'
import { TO_ANY_LOCATION } from '../components/Fields/ReceivingStrategyField'

function WarehouseCreateContainer () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const warehouseCreate = useWarehouseCreate()
  const notification = useNotification(WAREHOUSE_CREATE)
  const { getMe } = useMe()
  const { setProfile } = useProfile()

  const initialValues = {
    status: ACTIVE,
    pickingStrategy: PER_ORDER,
    receivingStrategy: TO_ANY_LOCATION,
    scanUniqueContainer: false,
    scanUniqueLocation: false
  }

  const snackbarMessage = t('Successfully created')
  const handleSubmit = formValues =>
    warehouseCreate.create(WarehouseCreateSerializer(formValues))
      .then(({ result }) => {
        snackbar({ message: snackbarMessage })
        const key = prop('guid', result)

        notification.subscribe(key, () => {
          navigate(WAREHOUSE_LIST_PATH)
          return Promise.resolve()
        })
      })
      .then(() => getMe())
      .then(({ result }) => setProfile(result))

  const title = 'New warehouse'

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      activeNav={NAV.WAREHOUSE}
      isLoading={warehouseCreate.isLoading || notification.isLoading}
      breadcrumbs={breadcrumbs}
    >
      <WarehouseCreateForm
        pageTitle={title}
        pageTitleLoading={false}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        isLoading={warehouseCreate.isLoading || notification.isLoading}
      />
    </DashboardLayout>
  )
}

export default WarehouseCreateContainer
