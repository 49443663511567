import { useLimitedCountList, useCreate } from 'storfox-api-hooks'
import useAllSearchParams from 'storfox-route-hooks/src/useAllSearchParams'

import { queryToParams } from '~/utils'
import * as API from '~/constants/api'

import { OrderUnitsFilterSerializer } from '../../serializers/OrderUnits'

export const useOrderUnitsList = () => {
  const searchParams = useAllSearchParams()
  const params = queryToParams(OrderUnitsFilterSerializer, searchParams, 'guid')

  const { getList, ...state } = useLimitedCountList(API.ORDER_UNITS_REPORT_LIST, { ...params })
  return { ...state, getList: () => getList(params) }
}

export const useOrderUnitsReportGenerate = () => {
  const { create, ...state } = useCreate(API.ORDER_UNITS_REPORT_CREATE)
  return { ...state, generate: create }
}
