import React from 'react'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'

function ActionTitle ({ onRemove }) {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="h5">Choose action</Typography>
      <IconButton onClick={onRemove} size="small">
        <DeleteIcon />
      </IconButton>
    </Box>
  )
}

ActionTitle.propTypes = {
  onRemove: PropTypes.func.isRequired
}

export default ActionTitle
