import React from 'react'
import Chip from '@mui/material/Chip'
import PropTypes from 'prop-types'
import { eqBy, last, prop, unionWith } from 'ramda'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'

const RootStyled = styled(Box)(({ theme }) => ({
  '& > div': {
    marginRight: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    '& > div:last-child': {
      marginRight: 0
    }
  }
}))

const UNFULFILLED = 'unfulfilled'

const items = [
  { name: 'Pick', value: ['pick', 'partially_picked'], finish: false },
  { name: 'Pack', value: ['pack'], finish: false },
  { name: 'Ship', value: ['ship'], finish: false }
]

const getStatus = status => {
  const matchedValues = valueCheck(status)

  return unionWith(eqBy(prop('value')), matchedValues, items)
}

const valueCheck = status => {
  if (status === UNFULFILLED) {
    return []
  }

  return items.reduce((acc, item) => {
    const lastEl = last(acc)

    if (!status) {
      return acc
    }

    if (lastEl && lastEl.finish) {
      return acc
    }

    return [...acc, { ...item, finish: item.value.includes(status) }]
  }, [])
    .map(item => ({ ...item, finish: true }))
}

function FulfillmentProgress ({ status }) {
  return (
    <RootStyled>
      {getStatus(status).map((item, index) => (
        <Chip
          key={index}
          size="small"
          label={item.name}
          disabled={!item.finish}
        />
      ))}
    </RootStyled>
  )
}

FulfillmentProgress.propTypes = {
  status: PropTypes.string.isRequired
}

export default FulfillmentProgress
