import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useField } from 'react-final-form'

import SearchCreateField from '~/components/Fields/SearchCreateField'
import * as API from '~/constants/api'

function BundleSearchCreateField ({ name, isLoading, onClick, ...props }) {
  const [value, setValue] = useState('')
  const field = useField(name)

  const handleAddClick = value =>
    onClick(value).then(({ result }) => {
      return field.input.onChange(result)
    })

  return (
    <SearchCreateField
      name={name}
      api={API.CATEGORY_LIST}
      onAddClick={() => handleAddClick(value)}
      addText="Add new category"
      InputProps={{ disabled: isLoading }}
      onInputChange={val => setValue(val)}
      {...props}
    />
  )
}

BundleSearchCreateField.propTypes = {
  onClick: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  isLoading: PropTypes.bool
}

export default BundleSearchCreateField
