import palette from '~/theme/dark/palette'

export default {
  styleOverrides: {
    root: {
      color: palette.text.primary,
      backgroundColor: '#282C34',
      '&.Mui-focused': {
        color: 'white'
      },
      '&.Mui-disabled': {
        color: palette.text.secondary
      }
    }
  }
}
