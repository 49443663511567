import { all, is, join } from 'ramda'

function toHelperText (helperText) {
  const helper = helperText || []
  const isValid = all(is(String), helper)

  if (isValid) {
    return join(', ', helper)
  }

  return []
}

export default toHelperText
