export const useStorage = key => {
  const set = items => {
    if (items) {
      localStorage.setItem(key, JSON.stringify(items))
    } else {
      localStorage.removeItem(key)
    }
  }
  const get = JSON.parse(localStorage.getItem(key))

  return { set, get }
}

export default useStorage
