import React from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'

import { CardHeader } from '~/components/Cards'
import TextField from '~/components/Fields/TextField'

import ShippingMethodCard from './ShippingMethodCard'
import ShipmentDetail from './ShipmentDetail'

import DeliveryAddressCard from '../DeliveryAddressCard'
import LineItems from '../LineItems'
import InvoiceSummary from '../InvoiceSummary'
import OrderCard from '../OrderCard'
import AddressCard from '../AddressCard'

function ReturnUpdateFormGeneral (props) {
  const { onIntegrationCheck, returnVariants } = props

  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} lg={9} xs={12}>
        <Card>
          <CardHeader title="Line items" />
          <Divider />
          <CardContent>
            <LineItems returnVariants={returnVariants} />
          </CardContent>

          <CardContent>
            <Divider />
            <Grid container={true} spacing={3} style={{ paddingTop: 20 }}>
              <Grid item={true} xs={12} lg={6}>
                <TextField
                  label="Customer notes"
                  name="notes"
                  multiline={true}
                  minRows={11}
                />
              </Grid>

              <Grid item={true} xs={12} lg={6}>
                <InvoiceSummary />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Box mt={3}>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12} lg={6}>
              <AddressCard />
            </Grid>

            <Grid item={true} xs={12} lg={6}>
              <DeliveryAddressCard />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item={true} lg={3} xs={12}>
        <Box mb={3}>
          <OrderCard />
        </Box>
        <Box mb={3}>
          <ShippingMethodCard onIntegrationCheck={onIntegrationCheck} />
        </Box>
        <Box mb={3}>
          <ShipmentDetail />
        </Box>
      </Grid>
    </Grid>

  )
}

ReturnUpdateFormGeneral.propTypes = {
  onIntegrationCheck: PropTypes.func.isRequired,
  returnVariants: PropTypes.object.isRequired
}

export default ReturnUpdateFormGeneral
