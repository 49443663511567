import React from 'react'
import { Ordering } from 'storfox-filter'

import { CUSTOMER_COLUMNS as tableColumns } from './CustomerTable'

import { FILTER_NAME as name } from '../../constants'

export const customerOrderingOptions = {
  name,
  tableColumns
}

function CustomerOrderingForm (props) {
  return (
    <Ordering {...props} />
  )
}

export default CustomerOrderingForm
