import React from 'react'
import PropTypes from 'prop-types'

import Button from '~/components/Buttons/Button'

function ActionButton ({ label, handleOnClick, ...props }) {
  return (
    <Button onClick={handleOnClick} variant="contained" {...props}>
      {label}
    </Button>
  )
}

ActionButton.propTypes = {
  handleOnClick: PropTypes.func.isRequired,
  label: PropTypes.any.isRequired
}

export default ActionButton
