import { prop } from 'ramda'

export const getArrayWithDynamicIndex = (array) => {
  let index = 0

  return array.map(item => {
    if (prop('dynamic', item)) {
      const result = { ...item, index }
      index++

      return result
    }

    return item
  })
}
