import { sprintf } from 'sprintf-js'
import { useAllSearchParams, usePickSearchParams } from 'storfox-route-hooks'
import { useLimitedCountList, useCreate, useDelete, useDetail, useList, useUpdate } from 'storfox-api-hooks'

import * as API from '~/constants/api'
import { queryToParams } from '~/utils'

import { CustomerFilterSerializer } from '../serializers'

export const useCustomerList = () => {
  const searchParams = useAllSearchParams()
  const params = queryToParams(CustomerFilterSerializer, searchParams)

  return useLimitedCountList(API.CUSTOMER_LIST, params)
}

export const useCustomersDelete = () => {
  return useDelete(API.CUSTOMER_DELETE)
}

export const useCustomerDetail = guid => {
  const url = sprintf(API.CUSTOMER_DETAIL, guid)
  return useDetail(url)
}

export const useCustomerCreate = () => {
  return useCreate(API.CUSTOMER_CREATE)
}

export const useCustomerUpdate = guid => {
  const url = sprintf(API.CUSTOMER_UPDATE, guid)
  return useUpdate(url)
}

export const useCustomerOrderList = (customerGuid, autoSend) => {
  const { page, limit } = usePickSearchParams(['page', 'limit'])

  return useList(API.SALE_ORDER_LIST, { customerGuid, page, limit }, autoSend)
}

export const useCustomerHistory = (customerGuid, autoSend) => {
  const searchParams = useAllSearchParams()
  const url = sprintf(API.CUSTOMER_HISTORY, customerGuid)

  return useList(url, searchParams, autoSend)
}
