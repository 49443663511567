import { sprintf } from 'sprintf-js'
import { usePickSearchParams } from 'storfox-route-hooks'
import { useCreate, useDelete, useDetail, useList, useUpdate } from 'storfox-api-hooks'

import { orderingToSnackCase } from '~/utils'
import * as API from '~/constants/api'

export const useDeliveryMethodList = () => {
  const searchParams = usePickSearchParams()
  return useList(API.DELIVERY_METHOD_LIST, orderingToSnackCase(searchParams))
}

export const useDeliveryMethodCreate = () => {
  return useCreate(API.DELIVERY_METHOD_CREATE)
}

export const useDeliveryMethodsDelete = () => {
  return useDelete(API.DELIVERY_METHODS_DELETE)
}

export const useDeliveryMethodUpdate = id => {
  const url = sprintf(API.DELIVERY_METHOD_UPDATE, id)
  return useUpdate(url)
}

export const useDeliveryMethodDetail = id => {
  const url = sprintf(API.DELIVERY_METHOD_DETAIL, id)
  return useDetail(url)
}
