import React from 'react'
import { useFilter, useOrdering } from 'storfox-filter'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'
import { useRequest } from 'storfox-api-hooks'
import { sprintf } from 'sprintf-js'
import { path, pathOr } from 'ramda'
import { getDataFromError } from 'storfox-api-hooks/src/api/utils'
import { useRoute } from 'storfox-route-hooks'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import ErrorLink from '~/components/ErrorLink'
import useMessages from '~/hooks/useMessages'
import { useTableSelects } from '~/components/TableValues/hooks'
import * as API from '~/constants/api'
import { generateCdnUrl } from '~/utils'

import {
  ShipmentFilterForm,
  shipmentFilterOptions,
  ShipmentOrderingForm,
  shipmentOrderingOptions,
  ShipmentTable
} from '../components/ShipmentList'
import { useShipmentGatepassGenerate, useShipmentList, useShipmentListSetStatus, useShipmentsExport } from '../hooks'
import { ShipmentExportSerializer } from '../serializers'

function ShipmentListContainer () {
  const snackbar = useSnackbar()
  const messages = useMessages()
  const request = useRequest()
  const { replaceParams } = useRoute()

  const { selects, resetTableSelects } = useTableSelects()

  const shipmentList = useShipmentList()
  const shipmentExport = useShipmentsExport()
  const shipmentGatepassGenerate = useShipmentGatepassGenerate()
  const shipmentListSetStatus = useShipmentListSetStatus()

  const filter = useFilter(shipmentFilterOptions)
  const ordering = useOrdering(shipmentOrderingOptions)

  const breadcrumbs = { title: NAV.SHIPMENTS }

  const handleGatepassGenerate = () =>
    shipmentGatepassGenerate.generate({ guids: selects })
      .then(() => resetTableSelects())
      .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
      .then(() => shipmentList.getList())
      .catch((response) => {
        const message = <ErrorLink error={response} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const handleListStatusSet = ({ status }) =>
    shipmentListSetStatus.set({ guids: selects, status })
      .then(() => resetTableSelects())
      .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
      .then(() => shipmentList.getList())
      .catch((response) => {
        const message = <ErrorLink error={response} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const handleShipmentsExport = (values) =>
    shipmentExport.export(ShipmentExportSerializer(values))
      .then(() => snackbar({ message: messages.GENERATE_SUCCESS }))
      .catch((error) => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const handleReportGenerate = (id) =>
    request.post(sprintf(API.SHIPMENT_REPORT_GENERATE, id))
      .then(() => snackbar({ message: messages.NOTIFICATION_WAIT }))
      .catch((error) => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
        return Promise.reject(error)
      })

  const handleCancelCarrier = (guid) =>
    request.post(sprintf(API.SHIPMENT_CANCEL_CARRIER, guid))
      .then(() => snackbar({ message: messages.NOTIFICATION_WAIT }))
      .catch((response) => {
        const error = getDataFromError(response)
        return Promise.reject(error)
      })
      .catch((error) => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
        return Promise.reject(error)
      })

  const handleCommercialInvoiceGenerate = guid =>
    request.get(sprintf(API.SHIPMENT_GENERATE_COMMERCIAL_INVOICE, guid))
      .then(() => snackbar({ message: messages.NOTIFICATION_WAIT }))
      .catch((response) => {
        const error = getDataFromError(response)
        return Promise.reject(error)
      })
      .catch((error) => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
        return Promise.reject(error)
      })

  const handleRecalculate = (guid) =>
    request.post(sprintf(API.SHIPMENT_RECALCULATE, guid))
      .then(() => shipmentList.getList())
      .then(() => snackbar({ message: messages.RECALCULATE_SUCCESS }))
      .catch((error) => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })

        return Promise.reject(error)
      })

  const handleAwbGet = (guid, awbUrl) => {
    if (awbUrl) {
      window.open(generateCdnUrl(awbUrl), '_blank')
    } else {
      request.get(sprintf(API.SHIPMENT_AWB_DETAIL, guid))
        .then((response) => {
          const url = path(['data', 'result', 'url'], response)
          window.open(generateCdnUrl(url), '_blank')
        })
        .catch((error) => {
          const message = <ErrorLink error={error} />
          snackbar({ message, type: ALTER_ERROR })
          return Promise.reject(error)
        })
    }
  }

  const handleProDispatch = (form) => {
    const url = sprintf(API.SHIPMENT_LIST + '?search=' + form.referenceNumber)
    return request.get(url)
      .then((response) => {
        const results = pathOr([], ['data', 'results'], response)
        if (results.length === 1) {
          const guid = path([0, 'guid'], results)
          const url = sprintf(API.SHIPMENT_SET_STATUS, guid)
          return request.post(url, { guid, status: 'in_transit' })
            .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
            .then(() => replaceParams({ search: form.referenceNumber }))
            .then(() => shipmentList.getList())
            .catch((error) => {
              const message = <ErrorLink error={error} />
              snackbar({ message, type: ALTER_ERROR })
              throw error
            })
        } else {
          snackbar({ message: 'No shipment was found', type: ALTER_ERROR })
          replaceParams({ search: form.referenceNumber })
        }
      })
      .catch((error) => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
        throw error
      })
  }

  return (
    <DashboardLayout title={NAV.SHIPMENTS} activeNav={NAV.SHIPMENTS} breadcrumbs={breadcrumbs}>
      <ShipmentFilterForm {...filter} />
      <ShipmentOrderingForm {...ordering} />

      <ShipmentTable
        filter={filter}
        ordering={ordering}
        list={shipmentList}
        handleOpenFilter={filter.handleOpen}
        onGatepassGenerate={handleGatepassGenerate}
        onShipmentsExport={handleShipmentsExport}
        onListRefetch={shipmentList.getListByParams}
        onListStatusSet={handleListStatusSet}
        statusSetLoading={shipmentListSetStatus.isLoading}
        onReportGenerate={handleReportGenerate}
        onCancelCarrier={handleCancelCarrier}
        onCommercialInvoiceGenerate={handleCommercialInvoiceGenerate}
        onRecalculate={handleRecalculate}
        onAwbGet={handleAwbGet}
        statusSetDisabled={selects.length <= 100}
        onProDispatch={handleProDispatch}
        bulkActionLoading={shipmentListSetStatus.isLoading || shipmentGatepassGenerate.isLoading}
      />
    </DashboardLayout>
  )
}

export default ShipmentListContainer
