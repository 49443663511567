import React from 'react'
import { Ordering } from 'storfox-filter'

import { ADJUSTMENT_COLUMNS as tableColumns } from './AdjustmentTable'

import { FILTER_NAME as name } from '../../constants'

export const adjustmentOrderingOptions = {
  name,
  tableColumns
}

function AdjustmentOrderingForm (props) {
  return (
    <Ordering {...props} />
  )
}

export default AdjustmentOrderingForm
