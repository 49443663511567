import React, { useCallback } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useRequest } from 'storfox-api-hooks'
import { prop } from 'ramda'
import { useSnackbar } from 'storfox-snackbar'

import * as ROUTES from '~/constants/routes'
import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import * as API from '~/constants/api'
import useMessages from '~/hooks/useMessages'

import LotSheetsShipmentForm from '../components/LotSheetsShipment/LotSheetsShipmentForm'
import { useLotSheetsDetail, useLotShipmentCreate } from '../hooks'
import { lotShipmentCreateSerializer } from '../serializers'

function LotSheetsShipmentContainer () {
  const { guid } = useParams()
  const request = useRequest()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const messages = useMessages()

  const lotSheetsDetailList = useLotSheetsDetail(guid)
  const lotShipmentCreate = useLotShipmentCreate()

  const breadcrumbs = { title: NAV.LOT_SHEETS }

  const onSubmit = useCallback((values) =>
    lotShipmentCreate.create(lotShipmentCreateSerializer(values, guid))
      .then(() => snackbar({ message: messages.CREATE_SUCCESS }))
      .then(() => navigate(ROUTES.LOT_SHEETS_LIST_PATH))
  , [guid, lotShipmentCreate, messages.CREATE_SUCCESS, navigate, snackbar])

  const getBucketList = useCallback((value) => {
    const bucketGuid = prop('guid', value)
    const lotBucketListUrl = generatePath(API.LOT_NUMBER_BUCKET_LIST, { guid, bucketGuid })
    return request.get(lotBucketListUrl)
  }, [guid, request])

  return (
    <DashboardLayout
      title={NAV.LOT_SHEETS}
      activeNav={NAV.LOT_SHEETS}
      isLoading={lotSheetsDetailList.isLoading}
      breadcrumbs={breadcrumbs}
    >
      <LotSheetsShipmentForm
        initialValues={{}}
        pageTitle="Create shipment"
        isLoading={lotSheetsDetailList.isLoading}
        onSubmit={onSubmit}
        guid={guid}
        getBucketList={getBucketList}
      />
    </DashboardLayout>
  )
}

export default LotSheetsShipmentContainer
