import React from 'react'
import PropTypes from 'prop-types'
import { path, prop } from 'ramda'
import Grid from '@mui/material/Grid'

import TextLabelLoader from '~/components/TextLabelLoader'

function AddressInfo ({ address }) {
  const addressInfo = prop('address', address)
  const zipcode = prop('zipcode', address)
  const country = path(['country', 'name'], address)
  const city = prop('city', address)

  return (
    <Grid container={true} spacing={1}>
      <Grid item={true} xs={12}>
        <TextLabelLoader label="Address">
          {addressInfo}
        </TextLabelLoader>
      </Grid>
      <Grid item={true} xs={12}>
        <TextLabelLoader label="City">
          {city}
        </TextLabelLoader>
      </Grid>
      <Grid item={true} xs={12}>
        <TextLabelLoader label="Zip code">
          {zipcode}
        </TextLabelLoader>
      </Grid>
      <Grid item={true} xs={12}>
        <TextLabelLoader label="Country">
          {country}
        </TextLabelLoader>
      </Grid>
    </Grid>
  )
}

AddressInfo.propTypes = {
  address: PropTypes.object
}

export default AddressInfo
