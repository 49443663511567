import React, { useState } from 'react'
import TableCell from '@mui/material/TableCell'
import PropTypes from 'prop-types'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import { useTranslation } from 'react-i18next'
import { useDeepCompareEffect, useRequest } from 'storfox-api-hooks'
import { sprintf } from 'sprintf-js'
import { path, pathOr, prop, propOr } from 'ramda'
import { generatePath } from 'react-router-dom'

import { ExpandableTableRow, ExpandedTableWrapper } from '~/components/Table'
import * as API from '~/constants/api'
import Avatar from '~/components/Avatar'
import VerticalAlignment from '~/components/VerticalAlignment'
import TableLink from '~/components/Link'
import TextOverflow from '~/components/TextOverflow'
import Subtext from '~/components/Subtext'
import { VARIANT_DETAIL_TABS } from '~/constants/tabs'
import * as ROUTES from '~/constants/routes'
import Money from '~/components/Money'
import Percentage from '~/components/Percentage'

function BundleRow ({ children, variant }) {
  const { t } = useTranslation()
  const request = useRequest()
  const [lineItems, setLineItems] = useState([])
  const [open, setOpen] = React.useState(false)

  const type = prop('type', variant)
  const isBundle = type === 'bundle'

  useDeepCompareEffect(() => {
    if (lineItems.length === 0 && isBundle) {
      const guid = prop('guid', variant)
      const url = sprintf(API.BUNDLE_DETAIL, guid)
      request.get(url).then(response => {
        const bundleItems = pathOr([], ['data', 'result', 'lineItems'], response)
        setLineItems(bundleItems)
      })
    }
  }, [isBundle, variant, lineItems])

  return (
    <>
      <ExpandableTableRow open={open} onExpandToggle={() => setOpen(!open)} disabled={!isBundle}>
        {children}
      </ExpandableTableRow>

      <ExpandedTableWrapper open={open} colSpan={11}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t('Image')}</TableCell>
              <TableCell sx={{ width: '250px' }}>{t('Product')}</TableCell>
              <TableCell>{t('Unit price')}</TableCell>
              <TableCell sx={{ width: '200px' }}>{t('Condition code')}</TableCell>
              <TableCell sx={{ width: '200px' }}>{t('Discount')}</TableCell>
              <TableCell sx={{ width: '100px' }}>{t('Qty')}</TableCell>
              <TableCell>{t('Total')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lineItems.map(item => {
              const conditionCode = path(['condition', 'code'], item)
              const variantName = path(['variant', 'name'], item)
              const variantId = pathOr(0, ['variant', 'id'], item)
              const productId = pathOr(0, ['product', 'id'], variant)
              const isMultiVariant = pathOr(0, ['product', 'isMultiVariant'], variant)
              const sku = path(['variant', 'sku'], item)
              const imagePath = path(['variant', 'defaultImage'], item)
              const totalPrice = propOr(0, 'price', item)
              const unitCost = pathOr(0, ['variant', 'retailPrice'], item)
              const quantity = propOr(0, 'quantity', item)
              const discount = propOr(0, 'discount', item)
              const unitDiscount = unitCost * (1 - discount / 100)
              const discountPrice = unitDiscount < 0 ? 0 : unitDiscount

              const params = { id: variantId, productId, tab: VARIANT_DETAIL_TABS.GENERAL }
              const variantLink = `${generatePath(ROUTES.VARIANT_DETAIL_PATH, params)}?isMultiVariant=${isMultiVariant}`
              return (
                <TableRow key={variantId}>
                  <TableCell>
                    <Avatar
                      alt={variantName}
                      src={imagePath}
                    />
                  </TableCell>
                  <TableCell>
                    <VerticalAlignment
                      sx={{ minWidth: '200px' }}
                      primary={(
                        <TableLink to={variantLink}>
                          <TextOverflow selfTooltip={true}>
                            {variantName}
                          </TextOverflow>
                        </TableLink>
                      )}
                      secondary={<Subtext lines={1}>{sku}</Subtext>}
                    />
                  </TableCell>
                  <TableCell>
                    <VerticalAlignment
                      primary={<Money value={discountPrice} />}
                      secondary={(
                        <del>
                          <Subtext>
                            <Money value={unitCost} />
                          </Subtext>
                        </del>
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    {conditionCode}
                  </TableCell>
                  <TableCell sx={{ width: '150px' }}>
                    <Percentage value={discount} />
                  </TableCell>
                  <TableCell>
                    {quantity}
                  </TableCell>
                  <TableCell>
                    <Money value={totalPrice} />
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </ExpandedTableWrapper>
    </>
  )
}

BundleRow.propTypes = {
  children: PropTypes.any.isRequired,
  variant: PropTypes.object.isRequired
}

export default BundleRow
