import React from 'react'

import StorfoxFulfillmentStatusField, { OPTIONS as BASE_OPTIONS } from '~/components/Fields/FulfillmentStatusField'

const OPTIONS = [{ value: '', name: 'All' }, ...BASE_OPTIONS]

function FulfillmentStatusField (props) {
  return (
    <StorfoxFulfillmentStatusField
      name="fulfillmentStatus"
      label="Fulfillment status"
      options={OPTIONS}
      {...props}
    />
  )
}

export default FulfillmentStatusField
