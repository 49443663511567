import React from 'react'
import PropTypes from 'prop-types'

import { TO_ANY_LOCATION, TO_RECEIVING_LOCATION } from '../../Fields/ReceivingStrategyField'

const NAMES = {
  [TO_ANY_LOCATION]: 'Receive to any location',
  [TO_RECEIVING_LOCATION]: 'Receive to receiving location'
}

function ReceivingStrategy ({ value }) {
  return (
    <>{NAMES[value]}</>
  )
}

ReceivingStrategy.propTypes = {
  value: PropTypes.string.isRequired
}

export default ReceivingStrategy
