import React from 'react'
import PropTypes from 'prop-types'

import Chip, { ERROR, SUCCESS } from '~/components/Chip'

function QuantityChange ({ children }) {
  const color = children >= 0 ? SUCCESS : ERROR
  const label = children > 0 ? `+${children}` : children

  return (
    <Chip label={label} color={color} />
  )
}

QuantityChange.propTypes = {
  children: PropTypes.number.isRequired
}

export default QuantityChange
