import * as API from '../constant'

export const isProd = (
  !location.hostname.includes('dev.') &&
  location.hostname !== 'localhost'
)

export const isStg = (
  location.hostname === API.APP_STG_URL
)

export const isDev = (
  location.hostname.includes('dev.') ||
  location.hostname.includes('aggrak.storfox') ||
  location.hostname === 'localhost'
)

export const isAggrak = location.hostname.includes('aggrak')

export const getApiUrl = () => {
  if (isDev) {
    return `${API.PROTOCOL}//${API.API_DEV_URL}`
  }
  if (isProd) {
    return `${API.PROTOCOL}//${API.API_PROD_URL}`
  }
  if (isStg) {
    return `${API.PROTOCOL}//${API.API_STG_URL}`
  }
}

export const getSocketUrl = () => {
  if (isDev) {
    return `${API.SOCKET_PROTOCOL}//${API.SOCKET_DEV_URL}`
  }
  if (isProd) {
    return `${API.SOCKET_PROTOCOL}//${API.SOCKET_PROD_URL}`
  }
  if (isStg) {
    return `${API.SOCKET_PROTOCOL}//${API.SOCKET_STG_URL}`
  }
}

export const getCdnUrl = () => {
  if (isDev) {
    return `${API.PROTOCOL}//${API.CDN_DEV_URL}`
  }
  if (isProd) {
    return `${API.PROTOCOL}//${API.CDN_PROD_URL}`
  }
  if (isStg) {
    return `${API.PROTOCOL}//${API.CDN_STG_URL}`
  }
}
