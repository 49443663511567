import { useEffect, useRef, useState } from 'react'
import { propEq } from 'ramda'
import PubSub from 'pubsub-js'

const defaultOptions = { timeoutTime: 10000 }

export function useNotification (topic, { timeoutTime = 10000 } = defaultOptions) {
  const [isLoading, setLoading] = useState(false)
  const subscriptionRef = useRef(null)
  const timeoutRef = useRef(null)

  useEffect(() => {
    const subscription = subscriptionRef.current

    return () => {
      PubSub.unsubscribe(subscription)
      subscriptionRef.current = null
    }
  }, [])

  const startTimeout = callback => {
    clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(callback, timeoutTime)
  }

  const stopTimeout = () => {
    clearTimeout(timeoutRef.current)
  }

  const subscribe = (key, callback) => {
    subscriptionRef.current = PubSub.subscribe(
      topic,
      (message, { payload }) => {
        if (propEq('key', key, payload)) {
          stopTimeout()
          const newMessage = { message, payload }
          return callback(newMessage)
        }
      }
    )
  }

  const unsubscribe = () => {
    PubSub.unsubscribe(subscriptionRef.current)
    subscriptionRef.current = null
  }

  return {
    isLoading,
    setLoading,
    backupSubscribe: startTimeout,
    subscribe,
    unsubscribe
  }
}
