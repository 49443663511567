import { defaultTo, map, pipe, prop, range } from 'ramda'
import { DateTime } from 'luxon'

import { base64ToObject } from '~/utils'

const DEFAULT_RANGE = 12

export const generateDays = ([startDate, endDate]) => {
  if (startDate && endDate && !startDate.invalid && !endDate.invalid) {
    const difference = endDate.diff(startDate, ['days'])

    const daysDiff = prop('days', difference)

    const finalDifference = daysDiff > DEFAULT_RANGE ? daysDiff : DEFAULT_RANGE

    return range(1, finalDifference + 1)
      .map(i => endDate.minus({ days: finalDifference - i }))
  }

  return range(1, DEFAULT_RANGE + 1)
    .map(i => DateTime.now().minus({ days: DEFAULT_RANGE - i }))
}

const getDateLabels = map(
  day => day.toFormat('dd MMM')
)

export const getLabelsFromDateRange = dateRange => {
  const days = generateDays(dateRange)

  return getDateLabels(days)
}

export const getDateRangeFromParams = pipe(
  base64ToObject,
  defaultTo([null, null]),
  map(date => date ? DateTime.fromISO(date) : null)
)
