import React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { path, propOr } from 'ramda'
import { useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import { PURCHASE_ORDER_DETAIL_TABS } from '~/constants/tabs'
import useMessages from '~/hooks/useMessages'
import { useProfile } from '~/components/Profile'
import * as ROUTES from '~/constants/routes'

import {
  usePurchaseOrderDetail,
  usePurchaseOrderSupplierCreate,
  usePurchaseOrderUpdate,
  usePurchaseOrderVariant,
  usePurchaseOrderFileAttach,
  usePurchaseOrderFileAttachRemove
} from '../hooks'
import { PurchaseOrderUpdateForm } from '../components/PurchaseOrderUpdate'
import Title from '../components/Title'
import {
  PurchaseOrderCreateSerializer,
  PurchaseOrderSupplierCreateSerializer,
  PurchaseOrderUpdateInitSerializer
} from '../serializers'

function PurchaseOrderUpdateContainer () {
  const { guid } = useParams()
  const navigate = useNavigate()
  const messages = useMessages()
  const snackbar = useSnackbar()
  const { profile } = useProfile()
  const defaultTax = path(['purchaseOrderTax', 'value'], profile)

  const purchaseOrderDetail = usePurchaseOrderDetail(guid)
  const purchaseOrderUpdate = usePurchaseOrderUpdate(guid)
  const purchaseOrderSupplierCreate = usePurchaseOrderSupplierCreate()
  const purchaseOrderVariant = usePurchaseOrderVariant()
  const purchaseOrderFileAttach = usePurchaseOrderFileAttach(guid)
  const purchaseOrderFileAttachRemove = usePurchaseOrderFileAttachRemove(guid)

  const handleSubmit = formValues =>
    purchaseOrderUpdate.update(PurchaseOrderCreateSerializer(formValues))
      .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
      .then(() => {
        const params = { guid, tab: PURCHASE_ORDER_DETAIL_TABS.GENERAL }
        const redirect = generatePath(ROUTES.PURCHASE_ORDER_DETAIL_PATH, params)

        navigate(redirect)
      })

  const handleSupplierCreate = values =>
    purchaseOrderSupplierCreate
      .create(PurchaseOrderSupplierCreateSerializer(values))
      .then(res => {
        snackbar({ message: messages.CREATE_SUCCESS })
        return res
      })

  const handleFileAttach = (url) =>
    purchaseOrderFileAttach.attach({ url })
      .then(() => snackbar({ message: messages.ATTACH_SUCCESS }))

  const handleFileAttachRemove = () =>
    purchaseOrderFileAttachRemove.create()
      .then(() => snackbar({ message: messages.ATTACH_REMOVE_SUCCESS }))

  const isLoading = (
    purchaseOrderDetail.isLoading ||
    purchaseOrderUpdate.isLoading
  )

  const number = propOr('PO', 'number', purchaseOrderDetail.detail)

  const pageTitle = <Title number={number} />

  const pageTitleLoading = purchaseOrderDetail.isLoading

  const breadcrumbs = {
    title: 'Edit',
    params: [
      { guid, tab: PURCHASE_ORDER_DETAIL_TABS.GENERAL, title: number }
    ]
  }

  return (
    <DashboardLayout
      title={number}
      activeNav={NAV.PURCHASE_ORDER}
      isLoading={isLoading}
      breadcrumbs={breadcrumbs}
    >
      <PurchaseOrderUpdateForm
        pageTitle={pageTitle}
        pageTitleLoading={pageTitleLoading}
        initialValues={PurchaseOrderUpdateInitSerializer(purchaseOrderDetail.detail, defaultTax)}
        isLoading={isLoading}
        detailLoading={purchaseOrderDetail.isLoading}
        onSubmit={handleSubmit}
        onSupplierCreate={handleSupplierCreate}
        purchaseOrderVariant={purchaseOrderVariant}
        onFileAttach={handleFileAttach}
        onFileAttachRemove={handleFileAttachRemove}
      />
    </DashboardLayout>
  )
}

export default PurchaseOrderUpdateContainer
