import React from 'react'
import { useFilter, useOrdering } from 'storfox-filter'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import ErrorLink from '~/components/ErrorLink'
import useMessages from '~/hooks/useMessages'

import { TplBillingExportSerializer } from '../serializers'
import {
  TplBillingFilterForm,
  tplBillingFilterOptions,
  TplBillingList,
  TplBillingOrderingForm,
  tplBillingOrderingOptions
} from '../components/TplBillingList'
import { useTplBillingList, useTplBillingsExport } from '../hooks'

function BillingListContainer () {
  const snackbar = useSnackbar()
  const messages = useMessages()

  const billingList = useTplBillingList()
  const billingsExport = useTplBillingsExport()

  const filter = useFilter(tplBillingFilterOptions)
  const ordering = useOrdering(tplBillingOrderingOptions)

  const handleBillingsExport = values =>
    billingsExport.export(TplBillingExportSerializer(values))
      .then(() => snackbar({ message: messages.NOTIFICATION_WAIT }))
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const breadcrumbs = { title: NAV.BILLING }

  return (
    <DashboardLayout
      title={NAV.BILLING}
      activeNav={NAV.BILLING}
      breadcrumbs={breadcrumbs}
    >
      <TplBillingFilterForm {...filter} />
      <TplBillingOrderingForm {...ordering} />

      <TplBillingList
        list={billingList}
        filter={filter}
        ordering={ordering}
        isLoading={billingList.isLoading}
        onBillingsExport={handleBillingsExport}
        onListRefetch={billingList.getListByParams}
      />
    </DashboardLayout>
  )
}

export default BillingListContainer
