import React from 'react'
import Grid from '@mui/material/Grid'

import StatusField from '~/components/Fields/StatusField'
import TextField from '~/components/Fields/TextField'

function ApiCreate () {
  return (
    <>
      <Grid item={true} xs={12} lg={8}>
        <TextField
          data-cy="name"
          name="name"
          label="Name"
        />
      </Grid>

      <Grid item={true} xs={12} lg={4}>
        <StatusField />
      </Grid>

      <Grid item={true} xs={12}>
        <TextField
          data-cy="description"
          name="description"
          label="Description"
          multiline={true}
          rows="4"
        />
      </Grid>
    </>
  )
}

export default ApiCreate
