import React from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'

import { DiscardButton, SaveButton } from '~/components/Buttons'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import { withForm } from '~/components/Form'
import * as NAV from '~/constants/nav-titles'
import Divider from '~/components/Divider'
import { INTEGRATION_TABS } from '~/constants/tabs'
import IntegrationHistory from '~/components/IntegrationHistory/IntegrationHistory'
import { SHOPIFY } from '~/constants/integrationTypes'
import * as ROUTES from '~/constants/routes'

import ShopifyIntegrationUpdateTabs from './ShopifyIntegrationUpdateTabs'
import ShopifyUpdate from './ShopifyUpdate'
import More from './More'

function ShopifyIntegrationUpdateForm (props) {
  const {
    pageTitle,
    pageTitleLoading,
    isLoading,
    form,
    onRemove,
    history,
    onRetry,
    retryLoading,
    onProductsSync,
    syncData
  } = props
  const { tab } = useParams()
  const { handleSubmit } = form

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            parentTitle={NAV.INTEGRATION}
            pageTitle={pageTitle}
            pageTitleLoading={pageTitleLoading}
            rightButton={
              <>
                <DiscardButton to={ROUTES.INTEGRATION_LIST_PATH} disabled={isLoading} />
                <SaveButton disabled={isLoading} />
                <More
                  onRemove={onRemove}
                  onProductsSync={onProductsSync}
                />
              </>
            }
          />
        </Header>
        <Content>
          <ShopifyIntegrationUpdateTabs />
          <Divider />
          {tab === INTEGRATION_TABS.GENERAL && <ShopifyUpdate syncData={syncData} />}
          {tab === INTEGRATION_TABS.LOGS && (
            <>
              <IntegrationHistory
                history={history}
                integrationType={SHOPIFY}
                onRetry={onRetry}
                retryLoading={retryLoading}
                onRefetch={history.getListByParams}
              />
            </>
          )}
        </Content>
      </Container>
    </form>
  )
}

ShopifyIntegrationUpdateForm.propTypes = {
  pageTitle: PropTypes.string,
  pageTitleLoading: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  form: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  onProductsSync: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  onRetry: PropTypes.func,
  retryLoading: PropTypes.bool,
  syncData: PropTypes.object
}

export default withForm(ShopifyIntegrationUpdateForm)
