import React from 'react'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Typography from '@mui/material/Typography'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { useLocation, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import Skeleton from '@mui/material/Skeleton'
import { prop, propOr } from 'ramda'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'

import breadcrumbPaths from '~/constants/breadcrumb-paths'
import { emptyArray } from '~/constants/empty'

import { getArrayWithDynamicIndex } from './utils'
import BreadcrumbLink from './BreadcrumbLink'
import BreadcrumbDynamicLink from './BreadcrumbDynamicLink'

const RootStyled = styled(Box)(({ theme }) => ({
  overflow: 'hidden',
  '& > * + *': {
    marginTop: theme.spacing(2)
  }
}))

const getRoutePath = (location, params) => {
  const { pathname } = location

  if (!Object.keys(params).length) {
    return pathname
  }

  let path = pathname
  Object.entries(params).forEach(([paramName, paramValue]) => {
    if (paramValue) {
      path = path.replace(paramValue, `:${paramName}`)
    }
  })
  return path
}

function Breadcrumb ({ breadcrumbs, isLoading, className, ...props }) {
  const { t } = useTranslation()
  const location = useLocation()
  const param = useParams()
  const pathname = getRoutePath(location, param) || ''

  const items = breadcrumbPaths[pathname] || emptyArray

  const title = prop('title', breadcrumbs)
  const params = propOr([], 'params', breadcrumbs)

  const indexedItems = getArrayWithDynamicIndex(items)

  return (
    <RootStyled className={className}>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
        {...props}
      >
        {isLoading && <Skeleton width={400} />}

        {!isLoading && indexedItems.map(({ dynamic, title, href, index }) => (
          dynamic
            ? (
              <BreadcrumbDynamicLink
                key={title}
                href={href}
                param={params[index]}
              />
            )
            : (
              <BreadcrumbLink
                key={title}
                href={href}
                title={title}
              />
            )
        ))}

        {!isLoading && <Typography component="div" color="textPrimary">{t(title)}</Typography>}
      </Breadcrumbs>
    </RootStyled>
  )
}

Breadcrumb.defaultProps = {
  breadcrumbs: {}
}

Breadcrumb.propTypes = {
  breadcrumbs: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  className: PropTypes.string
}

export default Breadcrumb
