import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { defaultTo, map, prop } from 'ramda'
import { generatePath } from 'react-router-dom'

import { MoreListButton, NewButton } from '~/components/Buttons'
import PageTitle from '~/components/PageTitle'
import Avatar from '~/components/Avatar/Avatar'
import { CommaSeparatedList } from '~/components/Text'
import Container, { Content, Header } from '~/components/Container'
import TextOverflow from '~/components/TextOverflow'
import { Dimensions, Volume, Weight } from '~/components/Converters'
import { Table, TableHeader } from '~/components/Table'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import TableLink from '~/components/Link'

import BulkActionButton from './BulkActionButton'

const getDetailPath = id => {
  return generatePath(ROUTES.CONTAINER_TYPE_UPDATE_PATH, { id })
}

export const CONTAINER_TYPE_COLUMNS = [
  {
    width: 75,
    headerName: 'Image',
    field: 'image',
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        <Avatar src={value} alt={row.name} />
      </TableLink>
    )
  },
  {
    width: 200,
    headerName: 'Name',
    field: 'name',
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        <TextOverflow selfTooltip={true}>{value}</TextOverflow>
      </TableLink>
    )
  },
  {
    width: 300,
    headerName: 'Operations',
    field: 'operations',
    renderCell: ({ value }) => (
      <CommaSeparatedList
        list={value}
      />
    )
  },
  {
    width: 200,
    headerName: 'Dimensions',
    field: 'dimensions',
    valueFormatter: ({ value }) => (
      `${value.width}x${value.length}x${value.height}`
    ),
    renderCell: ({ value }) => (
      <Dimensions
        width={value.width}
        height={value.height}
        length={value.length}
      />
    )
  },
  {
    width: 200,
    headerName: 'Volume',
    field: 'volume',
    renderCell: ({ value }) => (
      <Volume value={value} />
    )
  },
  {
    width: 200,
    headerName: 'Max. Load',
    field: 'maxLoad',
    renderCell: ({ value }) => (
      <Weight value={value} />
    )
  }
]

const getRowsFromResults = map(item => {
  const id = prop('id', item)
  const guid = prop('guid', item)
  const name = prop('name', item)
  const image = prop('imagePath', item)
  const length = prop('length', item)
  const height = prop('height', item)
  const width = prop('width', item)
  const dimensions = { length, width, height }
  const volume = prop('volume', item)
  const maxLoad = prop('maxLoad', item)
  const operations = defaultTo([], prop('operations', item))
  const link = getDetailPath(id)

  return {
    id,
    guid,
    image,
    name,
    dimensions,
    volume,
    maxLoad,
    operations,
    link
  }
})

function ContainerTypeTable (props) {
  const {
    list,
    filter,
    ordering,
    onContainerTypesDelete,
    onListRefetch,
    bulkActionLoading
  } = props
  const [tableRef, setTableRef] = useState(null)

  const isLoading = prop('isLoading', list)
  const results = prop('results', list)
  const count = prop('count', list)
  const columns = prop('filteredColumns', ordering)

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle="Container Types"
          parentTitle={NAV.CONFIGURATION}
          rightButton={(
            <>
              <BulkActionButton onContainerTypesDelete={onContainerTypesDelete} loading={bulkActionLoading} />
              <NewButton path={ROUTES.CONTAINER_TYPE_CREATE_PATH} />
            </>
          )}
        />
      </Header>

      <Content>
        <TableHeader
          filter={filter}
          filterOpen={filter.handleOpen}
          orderingOpen={ordering.handleOpen}
          ordering={ordering}
        >
          <MoreListButton tableRef={tableRef} />
        </TableHeader>
        <Table
          isLoading={isLoading}
          columns={columns}
          getRowsFromResults={getRowsFromResults}
          results={results}
          count={count}
          pinnedColumns={['image', 'name']}
          checkboxSelection={true}
          primaryKey="guid"
          ordering={ordering}
          onRefetch={onListRefetch}
          setTableRef={setTableRef}
        />
      </Content>
    </Container>
  )
}

ContainerTypeTable.propTypes = {
  list: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  onContainerTypesDelete: PropTypes.func.isRequired,
  onListRefetch: PropTypes.func.isRequired,
  bulkActionLoading: PropTypes.bool
}

export default ContainerTypeTable
