import React from 'react'
import { useTranslation } from 'react-i18next'

import SelectField from './SelectField'

const options = [
  { value:'AED', name:'UAE dirham (AED)' },
  { value:'AFN', name:'Afghan afghani (AFN)' },
  { value:'ALL', name:'Albanian lek (ALL)' },
  { value:'AMD', name:'Armenian dram (AMD)' },
  { value:'ANG', name:'Netherlands Antillean gulden (ANG)' },
  { value:'AOA', name:'Angolan kwanza (AOA)' },
  { value:'ARS', name:'Argentine peso (ARS)' },
  { value:'AUD', name:'Australian dollar (AUD)' },
  { value:'AWG', name:'Aruban florin (AWG)' },
  { value:'AZN', name:'Azerbaijani manat (AZN)' },
  { value:'BAM', name:'Bosnia and Herzegovina konvertibilna marka (BAM)' },
  { value:'BBD', name:'Barbadian dollar (BBD)' },
  { value:'BDT', name:'Bangladeshi taka (BDT)' },
  { value:'BGN', name:'Bulgarian lev (BGN)' },
  { value:'BHD', name:'Bahraini dinar (BHD)' },
  { value:'BIF', name:'Burundi franc (BIF)' },
  { value:'BMD', name:'Bermudian dollar (BMD)' },
  { value:'BND', name:'Brunei dollar (BND)' },
  { value:'BOB', name:'Bolivian boliviano (BOB)' },
  { value:'BRL', name:'Brazilian real (BRL)' },
  { value:'BSD', name:'Bahamian dollar (BSD)' },
  { value:'BTN', name:'Bhutanese ngultrum (BTN)' },
  { value:'BWP', name:'Botswana pula (BWP)' },
  { value:'BYR', name:'Belarusian ruble (BYR)' },
  { value:'BZD', name:'Belize dollar (BZD)' },
  { value:'CAD', name:'Canadian dollar (CAD)' },
  { value:'CDF', name:'Congolese franc (CDF)' },
  { value:'CHF', name:'Swiss franc (CHF)' },
  { value:'CLP', name:'Chilean peso (CLP)' },
  { value:'CNY', name:'Chinese/Yuan renminbi (CNY)' },
  { value:'COP', name:'Colombian peso (COP)' },
  { value:'CRC', name:'Costa Rican colon (CRC)' },
  { value:'CUC', name:'Cuban peso (CUC)' },
  { value:'CVE', name:'Cape Verdean escudo (CVE)' },
  { value:'CZK', name:'Czech koruna (CZK)' },
  { value:'DJF', name:'Djiboutian franc (DJF)' },
  { value:'DKK', name:'Danish krone (DKK)' },
  { value:'DOP', name:'Dominican peso (DOP)' },
  { value:'DZD', name:'Algerian dinar (DZD)' },
  { value:'EEK', name:'Estonian kroon (EEK)' },
  { value:'EGP', name:'Egyptian pound (EGP)' },
  { value:'ERN', name:'Eritrean nakfa (ERN)' },
  { value:'ETB', name:'Ethiopian birr (ETB)' },
  { value:'EUR', name:'European Euro (EUR)' },
  { value:'FJD', name:'Fijian dollar (FJD)' },
  { value:'FKP', name:'Falkland Islands pound (FKP)' },
  { value:'GBP', name:'British pound (GBP)' },
  { value:'GEL', name:'Georgian lari (GEL)' },
  { value:'GHS', name:'Ghanaian cedi (GHS)' },
  { value:'GIP', name:'Gibraltar pound (GIP)' },
  { value:'GMD', name:'Gambian dalasi (GMD)' },
  { value:'GNF', name:'Guinean franc (GNF)' },
  { value:'GQE', name:'Central African CFA franc (GQE)' },
  { value:'GTQ', name:'Guatemalan quetzal (GTQ)' },
  { value:'GYD', name:'Guyanese dollar (GYD)' },
  { value:'HKD', name:'Hong Kong dollar (HKD)' },
  { value:'HNL', name:'Honduran lempira (HNL)' },
  { value:'HRK', name:'Croatian kuna (HRK)' },
  { value:'HTG', name:'Haitian gourde (HTG)' },
  { value:'HUF', name:'Hungarian forint (HUF)' },
  { value:'IDR', name:'Indonesian rupiah (IDR)' },
  { value:'ILS', name:'Israeli new sheqel (ILS)' },
  { value:'INR', name:'Indian rupee (INR)' },
  { value:'IQD', name:'Iraqi dinar (IQD)' },
  { value:'IRR', name:'Iranian rial (IRR)' },
  { value:'ISK', name:'Icelandic kr\u00f3na (ISK)' },
  { value:'JMD', name:'Jamaican dollar (JMD)' },
  { value:'JOD', name:'Jordanian dinar (JOD)' },
  { value:'JPY', name:'Japanese yen (JPY)' },
  { value:'KES', name:'Kenyan shilling (KES)' },
  { value:'KGS', name:'Kyrgyzstani som (KGS)' },
  { value:'KHR', name:'Cambodian riel (KHR)' },
  { value:'KMF', name:'Comorian franc (KMF)' },
  { value:'KPW', name:'North Korean won (KPW)' },
  { value:'KRW', name:'South Korean won (KRW)' },
  { value:'KWD', name:'Kuwaiti dinar (KWD)' },
  { value:'KYD', name:'Cayman Islands dollar (KYD)' },
  { value:'KZT', name:'Kazakhstani tenge (KZT)' },
  { value:'LAK', name:'Lao kip (LAK)' },
  { value:'LBP', name:'Lebanese lira (LBP)' },
  { value:'LKR', name:'Sri Lankan rupee (LKR)' },
  { value:'LRD', name:'Liberian dollar (LRD)' },
  { value:'LSL', name:'Lesotho loti (LSL)' },
  { value:'LTL', name:'Lithuanian litas (LTL)' },
  { value:'LVL', name:'Latvian lats (LVL)' },
  { value:'LYD', name:'Libyan dinar (LYD)' },
  { value:'MAD', name:'Moroccan dirham (MAD)' },
  { value:'MDL', name:'Moldovan leu (MDL)' },
  { value:'MGA', name:'Malagasy ariary (MGA)' },
  { value:'MKD', name:'Macedonian denar (MKD)' },
  { value:'MMK', name:'Myanma kyat (MMK)' },
  { value:'MNT', name:'Mongolian tugrik (MNT)' },
  { value:'MOP', name:'Macanese pataca (MOP)' },
  { value:'MRO', name:'Mauritanian ouguiya (MRO)' },
  { value:'MUR', name:'Mauritian rupee (MUR)' },
  { value:'MVR', name:'Maldivian rufiyaa (MVR)' },
  { value:'MWK', name:'Malawian kwacha (MWK)' },
  { value:'MXN', name:'Mexican peso (MXN)' },
  { value:'MYR', name:'Malaysian ringgit (MYR)' },
  { value:'MZM', name:'Mozambican metical (MZM)' },
  { value:'NAD', name:'Namibian dollar (NAD)' },
  { value:'NGN', name:'Nigerian naira (NGN)' },
  { value:'NIO', name:'Nicaraguan c\u00f3rdoba (NIO)' },
  { value:'NOK', name:'Norwegian krone (NOK)' },
  { value:'NPR', name:'Nepalese rupee (NPR)' },
  { value:'NZD', name:'New Zealand dollar (NZD)' },
  { value:'OMR', name:'Omani rial (OMR)' },
  { value:'PAB', name:'Panamanian balboa (PAB)' },
  { value:'PEN', name:'Peruvian nuevo sol (PEN)' },
  { value:'PGK', name:'Papua New Guinean kina (PGK)' },
  { value:'PHP', name:'Philippine peso (PHP)' },
  { value:'PKR', name:'Pakistani rupee (PKR)' },
  { value:'PLN', name:'Polish zloty (PLN)' },
  { value:'PYG', name:'Paraguayan guarani (PYG)' },
  { value:'QAR', name:'Qatari riyal (QAR)' },
  { value:'RON', name:'Romanian leu (RON)' },
  { value:'RSD', name:'Serbian dinar (RSD)' },
  { value:'RUB', name:'Russian ruble (RUB)' },
  { value:'SAR', name:'Saudi riyal (SAR)' },
  { value:'SBD', name:'Solomon Islands dollar (SBD)' },
  { value:'SCR', name:'Seychellois rupee (SCR)' },
  { value:'SDG', name:'Sudanese pound (SDG)' },
  { value:'SEK', name:'Swedish krona (SEK)' },
  { value:'SGD', name:'Singapore dollar (SGD)' },
  { value:'SHP', name:'Saint Helena pound (SHP)' },
  { value:'SLL', name:'Sierra Leonean leone (SLL)' },
  { value:'SOS', name:'Somali shilling (SOS)' },
  { value:'SRD', name:'Surinamese dollar (SRD)' },
  { value:'SYP', name:'Syrian pound (SYP)' },
  { value:'SZL', name:'Swazi lilangeni (SZL)' },
  { value:'THB', name:'Thai baht (THB)' },
  { value:'TJS', name:'Tajikistani somoni (TJS)' },
  { value:'TMT', name:'Turkmen manat (TMT)' },
  { value:'TND', name:'Tunisian dinar (TND)' },
  { value:'TRY', name:'Turkish new lira (TRY)' },
  { value:'TTD', name:'Trinidad and Tobago dollar (TTD)' },
  { value:'TWD', name:'New Taiwan dollar (TWD)' },
  { value:'TZS', name:'Tanzanian shilling (TZS)' },
  { value:'UAH', name:'Ukrainian hryvnia (UAH)' },
  { value:'UGX', name:'Ugandan shilling (UGX)' },
  { value:'USD', name:'United States dollar (USD)' },
  { value:'UYU', name:'Uruguayan peso (UYU)' },
  { value:'UZS', name:'Uzbekistani som (UZS)' },
  { value:'VEB', name:'Venezuelan bolivar (VEB)' },
  { value:'VND', name:'Vietnamese dong (VND)' },
  { value:'VUV', name:'Vanuatu vatu (VUV)' },
  { value:'WST', name:'Samoan tala (WST)' },
  { value:'XAF', name:'Central African CFA franc (XAF)' },
  { value:'XCD', name:'East Caribbean dollar (XCD)' },
  { value:'XDR', name:'Special Drawing Rights (XDR)' },
  { value:'XOF', name:'West African CFA franc (XOF)' },
  { value:'XPF', name:'CFP franc (XPF)' },
  { value:'YER', name:'Yemeni rial (YER)' },
  { value:'ZAR', name:'South African rand (ZAR)' },
  { value:'ZMK', name:'Zambian kwacha (ZMK)' },
  { value:'ZWR', name:'Zimbabwean dollar (ZWR)' }
]

function CurrencyField (props) {
  const { t } = useTranslation()

  return (
    <SelectField
      data-cy="currency"
      name="currency"
      label={t('Currency')}
      options={options}
      {...props}
    />
  )
}

export default CurrencyField
