import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import { Field } from 'react-final-form'
import { useDeepCompareEffect, useRequest } from 'storfox-api-hooks'
import { defaultTo, path, prop } from 'ramda'

const defaultGetOptionValue = value => {
  if (value) {
    const { id, guid, name } = value
    return { id, guid, name }
  }

  return null
}

const defaultGetOptionLabel = option => option.name || ''

function BrowserCodeSearchField (props) {
  const {
    api,
    name,
    disabled,
    params,
    renderOption,
    isLoading,
    getOptionValue,
    InputProps,
    getOptionLabel,
    getOnChangeValue,
    onValueChange,
    autocompleteProps,
    ListboxProps,
    ...defaultProps
  } = props

  const request = useRequest()
  const [open, setOpen] = React.useState(false)
  const [options, setOptions] = React.useState([])
  const [loading, setLoading] = React.useState(false)

  useDeepCompareEffect(() => {
    let active = true

    if (open) {
      setLoading(true)
      request.get(api, params)
        .then(response => {
          const results = defaultTo([], path(['data', 'results'], response))

          if (active) {
            setOptions(results.map(getOptionValue))
          }
        })
        .finally(() => setLoading(false))
    }

    return () => {
      active = false
    }
  }, [open])

  React.useEffect(() => {
    if (!open) {
      setOptions([])
    }
  }, [open])

  const getOptionSelected = (option, value) => {
    const id = prop('id', option)
    const guid = prop('guid', option)
    if (id) {
      return id === prop('id', value)
    }
    return guid === prop('guid', value)
  }

  return (
    <Field name={name}>
      {({ input, meta, }) => {
        return (
          <Autocomplete
            open={open}
            disabled={disabled}
            onOpen={() => {
              setOpen(true)
            }}
            onClose={() => {
              setOpen(false)
            }}
            freeSolo={true}
            value={input.value}
            isOptionEqualToValue={getOptionSelected}
            getOptionLabel={getOptionLabel}
            options={options.length === 0 && !open ? [input.value] : options}
            renderOption={renderOption}
            loading={loading}
            onChange={(event, value) => {
              input.onChange(getOnChangeValue(value))
              onValueChange(value)
            }}
            ListboxProps={ListboxProps}
            renderInput={params => (
              <TextField
                {...params}
                {...defaultProps}
                onChange={(event) => {
                  const value = Number(event.target.value)
                  if (!isNaN(value)) {
                    input.onChange(value)
                  } else {
                    params.inputProps.onChange({ target: { value: '' } })
                    input.onChange(0)
                  }
                }}
                error={meta.invalid}
                helperText={meta.submitError || meta.error}
                InputProps={{
                  ...params.InputProps,
                  ...InputProps,
                  endAdornment: (
                    <>
                      {(loading || isLoading) ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                      {InputProps && InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
            {...autocompleteProps}
          />
        )
      }}
    </Field>
  )
}

BrowserCodeSearchField.propTypes = {
  api: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
  getOptionValue: PropTypes.func.isRequired,
  getOptionLabel: PropTypes.func.isRequired,
  renderOption: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  onValueChange: PropTypes.func,
  autocompleteProps: PropTypes.object,
  InputProps: PropTypes.object,
  getOnChangeValue: PropTypes.func,
  ListboxProps: PropTypes.object
}

BrowserCodeSearchField.defaultProps = {
  size: 'small',
  fullWidth: true,
  variant: 'outlined',
  disabled: false,
  params: { limit: 1000 },
  isLoading: false,
  autocompleteProps: {},
  onValueChange: () => {},
  getOptionValue: defaultGetOptionValue,
  getOptionLabel: defaultGetOptionLabel,
  getOnChangeValue: () => {}
}

export default BrowserCodeSearchField
