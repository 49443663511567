import React from 'react'
import { Ordering } from 'storfox-filter'

import { UNIT_COLUMNS as tableColumns } from './UnitHistoryList'

import { FILTER_NAME as name } from '../../constants/Unit'

export const unitHistoryOrderingOptions = {
  name,
  tableColumns
}

function UnitHistoryOrderingForm (props) {
  return (
    <Ordering {...props} />
  )
}

export default UnitHistoryOrderingForm
