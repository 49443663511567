import React from 'react'
import PropTypes from 'prop-types'
import { SwitchField } from 'storfox-form-fields'
import Typography from '@mui/material/Typography'

import { REALLOCATION_SALE_ORDER } from '../ActionFields/ActionSelectField'

export const PURCHASE_CHANGE_FIELDS = ['reallocationSaleOrder']

function PurchaseChangeAction ({ action, actionName }) {
  return (
    <>
      {action === REALLOCATION_SALE_ORDER && (
        <SwitchField
          name={actionName}
          label={<Typography display="inline" align="center">Reallocation sale order</Typography>}
        />
      )}
    </>
  )
}

PurchaseChangeAction.propTypes = {
  action: PropTypes.string.isRequired,
  actionName: PropTypes.string.isRequired
}

export default PurchaseChangeAction
