import React from 'react'
import PropTypes from 'prop-types'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'

import { MoreButton } from '~/components/Buttons'

function More ({ onTransferCancel }) {
  return (
    <>
      <MoreButton>
        {onClose => (
          <List>
            <ListItem
              button={true}
              onClick={() => {
                onTransferCancel()
                onClose()
              }}
            >
              <ListItemText primary="Cancel" />
            </ListItem>
          </List>
        )}
      </MoreButton>
    </>
  )
}

More.propTypes = {
  onTransferCancel: PropTypes.func.isRequired
}

export default More
