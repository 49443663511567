import { pick, prop } from 'ramda'
import { DEFAULT_SEARCH_PARAMS } from 'storfox-route-hooks'

import { escapeAtob } from '~/utils'

export const LocationUnitFilterSerializer = params => {
  const defaultParams = pick(DEFAULT_SEARCH_PARAMS, params)
  const companyGuids = escapeAtob(prop('companies', params))
  const warehouseGuids = escapeAtob(prop('warehouses', params))

  return {
    ...defaultParams,
    companyGuids,
    warehouseGuids
  }
}
