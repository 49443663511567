import { useEffect } from 'react'
import { sprintf } from 'sprintf-js'
import { useAllSearchParams } from 'storfox-route-hooks'
import { useCreate, useDetail, useList, useUpdate, useLimitedCountList, useDelete } from 'storfox-api-hooks'

import { queryToParams } from '~/utils'
import * as API from '~/constants/api'
import * as RETAILER_API from '~/constants/api-retailer'

import { VariantFilterSerializer } from '../serializers'

export const useVariantListingCreate = () => {
  return useCreate(RETAILER_API.VARIANT_LISTING_CREATE)
}

export const useVariantList = () => {
  const { select, ...searchParams } = useAllSearchParams()
  const params = queryToParams(VariantFilterSerializer, searchParams)
  return useLimitedCountList(API.VARIANT_LIST, params)
}

export const useVariantChannelList = () => {
  return useList(RETAILER_API.VARIANT_CHANNEL_LIST, {}, false)
}

export const useVariantDetail = id => {
  const url = sprintf(API.VARIANT_DETAIL, id)
  const { getDetail, ...detail } = useDetail(url, {}, false)

  useEffect(() => {
    getDetail()
  }, [getDetail, id])

  return { getDetail, ...detail }
}

export const useVariantHistory = (guid, autoSend) => {
  const url = sprintf(API.VARIANT_HISTORY, guid)
  const { page, limit } = useAllSearchParams()

  return useList(url, { page, limit }, autoSend)
}

export const useVariantUpdate = id => {
  const url = sprintf(API.VARIANT_UPDATE, id)
  return useUpdate(url)
}

export const useProductVariantList = productId => {
  const searchParams = { productId, limit: 1000 }
  const list = useList(API.VARIANT_LIST, searchParams)

  return {
    ...list,
    getList: () => list.getList(searchParams)
  }
}

export const useProductImageList = productId => {
  const url = sprintf(API.PRODUCT_IMAGE_LIST, productId)
  return useList(url, {}, false)
}

export const useProductAddImage = id => {
  const url = sprintf(API.PRODUCT_IMAGE_UPDATE, id)
  return useCreate(url)
}

export const useVariantStockList = (id, autoSend) => {
  const url = sprintf(API.VARIANT_STOCK_LIST, id)

  return useList(url, {}, autoSend)
}

export const useVariantSuppliersList = (id, autoSend) => {
  return useList(API.PURCHASE_ORDER_SUPPLIER_LIST, { variantId: id }, autoSend)
}

export const useVariantPriceList = (id, autoSend) => {
  const url = sprintf(API.CONDITION_PRICING_LIST, id)

  return useList(url, {}, autoSend)
}

export const useVariantPricesUpdate = id => {
  const url = sprintf(API.CONDITION_PRICING_UPDATE, id)

  return useUpdate(url)
}

export const useVariantStockSync = () => {
  const { create, ...state } = useCreate(API.STOCK_SYNC)
  return { ...state, sync: create }
}

export const useVariantInventoryLogList = (guid, autoSend) => {
  const { page, limit } = useAllSearchParams()

  return useList(API.INVENTORY_LOG_LIST, { variantGuids: guid, page, limit }, autoSend)
}

export const useVariantDelete = () => {
  return useDelete(API.VARIANT_DELETE)
}
