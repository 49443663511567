import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles'
import { path, prop } from 'ramda'

import { get, set } from '~/theme/utils/state'
import { createDarkTheme, createLightTheme } from '~/theme'
import { useWhiteLabel } from '~/components/WhiteLabel/WhiteLabel'

import Context from './context'

import { useCompany } from '../Profile'

export const DARK = 'dark'
export const LIGHT = 'light'

function ThemeProvider ({ children }) {
  const initialState = get()
  const [theme, setTheme] = useState(initialState)
  const { company } = useCompany()
  const { domainValues } = useWhiteLabel()
  const domainPrimaryColor = prop('primary_color', domainValues)

  const primaryColor = path(['branding', 'primaryColor'], company)
  const primaryDarkColor = path(['branding', 'primaryDarkColor'], company)

  const THEME = {
    [LIGHT]: createLightTheme(domainPrimaryColor || primaryColor),
    [DARK]: createDarkTheme(primaryDarkColor)
  }

  const selectTheme = theme => {
    setTheme(theme || get())
    set(theme)
  }

  const value = { theme, selectTheme }

  return (
    <MUIThemeProvider theme={THEME[theme]}>
      <Context.Provider value={value}>
        {children}
      </Context.Provider>
    </MUIThemeProvider>
  )
}

ThemeProvider.propTypes = {
  children: PropTypes.any.isRequired
}

export default ThemeProvider
