import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'storfox-snackbar'

import AppContainer from '~/components/AppContainer'
import * as ROUTES from '~/constants/routes'

import ForgotPassword from '../components/ForgotPassword'
import { useForgotPassword } from '../hooks'

function ForgotPasswordContainer () {
  const { t } = useTranslation()
  const forgotPassword = useForgotPassword()
  const snackbar = useSnackbar()
  const navigate = useNavigate()

  const handleSubmit = formValues =>
    forgotPassword.forgotPassword(formValues)
      .then(() => {
        const message = t('We just sent you an email with a password reset link')
        snackbar({ message })
      })
      .then(() => {
        navigate(ROUTES.SIGN_IN)
      })

  return (
    <AppContainer title="Reset password">
      <ForgotPassword
        onSubmit={handleSubmit}
        isLoading={forgotPassword.isLoading}
      />
    </AppContainer>
  )
}

export default ForgotPasswordContainer
