import { useAllSearchParams } from 'storfox-route-hooks'
import { useLimitedCountList } from 'storfox-api-hooks'

import { queryToParams } from '~/utils'
import * as API from '~/constants/api'

import { InventoryLogFilterSerializer } from '../serializers'

export const useInventoryLogList = () => {
  const searchParams = useAllSearchParams()
  const params = queryToParams(InventoryLogFilterSerializer, searchParams, 'guid')

  return useLimitedCountList(API.INVENTORY_LOG_LIST, params)
}
