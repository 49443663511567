import React, { useCallback, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { path, pathOr, prop } from 'ramda'
import { useAllSearchParams } from 'storfox-route-hooks'
import { useDeepCompareEffect } from 'storfox-api-hooks'

import useDialog from '~/hooks/useDialog'
import * as ROUTES from '~/constants/routes'

import PackingBarcodingForm from './PackingBarcodingForm'

import CompleteDialogForm from '../CompleteDialogForm'
import SkipCompleteDialogForm from '../SkipCompleteDialogForm'
import { ACTIONS, COMPLETE } from '../../constants'
import PrintDialog from '../PrintDialog'
import GoToNextOrderScanDialog from '../GoToNextOrderScanDialog'

function PackingBarcoding (props) {
  const {
    onSave,
    onComplete,
    onCompleteAndRedirect,
    packingHistory,
    containerLoading,
    initialPackingValues,
    containerTypeList,
    isLoading,
    onContainerCreate,
    onContainerBarcodeScan,
    pageTitleLoading,
    shipmentLocationList,
    scanContainerBarcode,
    pageTitle,
    completePacking,
    onSkip,
    onGenerateSlip,
    handleCompleteAndPrintInvoice,
    onPrintInvoice
  } = props

  const [containerWithNextOrder, setContainerWithNextOrder] = useState(null)
  const { initiallyScannedContainer } = useAllSearchParams()
  const skipCompleteDialog = useDialog()
  const completeDialog = useDialog()
  const commentDialog = useDialog()
  const printDialog = useDialog()
  const containerBarcodeScanDialog = useDialog()
  const navigate = useNavigate()

  const { id } = useParams()
  const warehouseId = prop('warehouseId', initialPackingValues)
  const awbUrl = path(['saleOrder', 'awbUrl'], initialPackingValues)
  const commercialInvoiceUrl = path(['saleOrder', 'commercialInvoiceUrl'], initialPackingValues)

  const initialDialogValues = {
    packing: { id: parseInt(id) },
    location: prop(0, shipmentLocationList)
  }

  useDeepCompareEffect(() => {
    if (initiallyScannedContainer) {
      scanContainerBarcode(initiallyScannedContainer)
        .then(response => {
          const results = pathOr([], ['data', 'results'], response)
          const nextOrder = results.length > 1 ? initiallyScannedContainer : null

          setContainerWithNextOrder(nextOrder)
        })
    }
  }, [id])

  const handleNextSaleOrderMove = formValues =>
    completePacking(formValues)
      .then(() => completeDialog.handleClose())
      .then(() => containerBarcodeScanDialog.handleOpen())

  const handleSubmit = formValues =>
    onSave(formValues)
      .then(() => {
        if (formValues.type === COMPLETE) {
          completeDialog.handleOpen()
        }
      })

  const handleComplete = formValues =>
    onComplete(formValues)
      .then(() => completeDialog.handleClose())

  const handleCompleteAndPrint = useCallback((values) => {
    return completePacking(values).then(() => {
      completeDialog.handleClose()
      printDialog.handleOpen()
    })
  }, [completeDialog, completePacking, printDialog])

  const omCompleteAndPrintInvoice = useCallback((values) => {
    return handleCompleteAndPrintInvoice(values).then(() => {
      completeDialog.handleClose()
      printDialog.handleOpen()
    })
  }, [handleCompleteAndPrintInvoice, completeDialog, printDialog])

  const handleSkip = useCallback(() => {
    onSkip().then(() => {
      if (containerWithNextOrder) {
        skipCompleteDialog.handleOpen()
      } else {
        if (awbUrl || commercialInvoiceUrl) {
          printDialog.handleOpen()
        } else {
          navigate(ROUTES.PACKING_LIST_PATH)
        }
      }
    })
  }, [containerWithNextOrder, skipCompleteDialog, awbUrl, commercialInvoiceUrl, navigate, onSkip, printDialog])

  const handleContainerScanDialog = useCallback((values) => {
    return onContainerBarcodeScan(values).then(() => containerBarcodeScanDialog.handleClose())
  }, [onContainerBarcodeScan, containerBarcodeScanDialog])

  const handleScanNextOrder = useCallback(() => {
    const url = `${ROUTES.PACKING_LIST_PATH}?action=${ACTIONS.OPEN_SCAN}`
    navigate(url)
  }, [navigate])

  return (
    <>
      <PackingBarcodingForm
        pageTitle={pageTitle}
        isLoading={isLoading}
        onSubmit={handleSubmit}
        packingHistory={packingHistory}
        containerLoading={containerLoading}
        pageTitleLoading={pageTitleLoading}
        initialValues={initialPackingValues}
        onContainerCreate={onContainerCreate}
        containerTypeList={containerTypeList}
        onHoldDialogOpen={commentDialog.handleOpen}
        onSkip={handleSkip}
        onGenerateSlip={onGenerateSlip}
      />

      <CompleteDialogForm
        open={completeDialog.open}
        onSubmit={handleComplete}
        initialValues={initialDialogValues}
        onClose={completeDialog.handleClose}
        warehouseId={warehouseId}
        onNextSaleOrderMove={handleNextSaleOrderMove}
        awbUrl={awbUrl}
        containerWithNextOrder={containerWithNextOrder}
        commercialInvoiceUrl={commercialInvoiceUrl}
        onCompleteAndRedirect={onCompleteAndRedirect}
        onCompleteAndPrint={handleCompleteAndPrint}
        onCompleteAndPrintInvoice={omCompleteAndPrintInvoice}
      />

      <SkipCompleteDialogForm
        open={skipCompleteDialog.open}
        initialValues={initialDialogValues}
        onClose={skipCompleteDialog.handleClose}
        warehouseId={warehouseId}
        onNextSaleOrderMove={() => {
          containerBarcodeScanDialog.handleOpen()
          skipCompleteDialog.handleClose()
        }}
        awbUrl={awbUrl}
        containerWithNextOrder={containerWithNextOrder}
        commercialInvoiceUrl={commercialInvoiceUrl}
        onCompleteAndRedirect={onCompleteAndRedirect}
        onCompleteAndPrint={() => {
          skipCompleteDialog.handleClose()
          printDialog.handleOpen()
        }}
      />

      <PrintDialog
        onClose={printDialog.handleClose}
        open={printDialog.open}
        awbUrl={awbUrl}
        commercialInvoiceUrl={commercialInvoiceUrl}
        onScanNextOrder={handleScanNextOrder}
        onPrintInvoice={onPrintInvoice}
      />

      {containerBarcodeScanDialog.open && (
        <GoToNextOrderScanDialog
          onSubmit={handleContainerScanDialog}
          open={containerBarcodeScanDialog.open}
          initialValues={{ open: containerBarcodeScanDialog.open, containerNumber: containerWithNextOrder }}
          handleClose={containerBarcodeScanDialog.handleClose}
        />
      )}
    </>
  )
}

PackingBarcoding.propTypes = {
  containerLoading: PropTypes.bool.isRequired,
  containerTypeList: PropTypes.object.isRequired,
  initialPackingValues: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,
  onCompleteAndRedirect: PropTypes.func.isRequired,
  onContainerCreate: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  packingHistory: PropTypes.object.isRequired,
  pageTitle: PropTypes.object.isRequired,
  pageTitleLoading: PropTypes.bool.isRequired,
  shipmentLocationList: PropTypes.array.isRequired,
  completePacking: PropTypes.func.isRequired,
  onContainerBarcodeScan: PropTypes.func.isRequired,
  scanContainerBarcode: PropTypes.func.isRequired,
  onSkip: PropTypes.func.isRequired,
  onGenerateSlip: PropTypes.func.isRequired,
  handleCompleteAndPrintInvoice: PropTypes.func.isRequired,
  onPrintInvoice: PropTypes.func.isRequired
}

export default PackingBarcoding
