import React from 'react'
import AccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'

function Accordion ({ title, children, mb, defaultExpanded, ...props }) {
  const { t } = useTranslation()
  return (
    <Box width="100%" mb={mb}>
      <MuiAccordion defaultExpanded={defaultExpanded} {...props}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography variant="subtitle1">
            {t(title)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {children}
        </AccordionDetails>
      </MuiAccordion>
    </Box>
  )
}

Accordion.defaultProps = {
  mb: 1,
  defaultExpanded: false
}

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  defaultExpanded: PropTypes.bool,
  mb: PropTypes.number
}

export default Accordion
