import React from 'react'
import PropTypes from 'prop-types'

import WarehouseSearchField from '~/components/Fields/WarehouseSearchField'
import * as API from '~/constants/api'

function BillingWarehouseField ({ name, ...props }) {
  return (
    <WarehouseSearchField
      name={name}
      api={API.BILLING_PROFILE_WAREHOUSE_LIST}
      primaryKey="guid"
      {...props}
    />
  )
}

BillingWarehouseField.propTypes = {
  name: PropTypes.string.isRequired
}

export default BillingWarehouseField
