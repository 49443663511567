import React from 'react'
import { useSnackbar } from 'storfox-snackbar'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { prop } from 'ramda'
import { useAllSearchParams } from 'storfox-route-hooks'

import { DashboardLayout } from '~/components/Layouts'
import { ACTIVE } from '~/components/Fields/StatusField'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import { INTEGRATION_TABS } from '~/constants/tabs'
import useMessages from '~/hooks/useMessages'

import { useStoreFrontCreate } from '../../hooks/'
import StorefrontIntegrationCreateForm from '../../components/Storefront/StorefrontIntegrationCreateForm'

function StorefrontIntegrationCreateContainer () {
  const { t } = useTranslation()
  const params = useAllSearchParams()
  const { type } = useParams()
  const storeFrontCreate = useStoreFrontCreate()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const messages = useMessages()

  const channelAccessToken = prop('token', params)

  const title = 'New Integration'

  const handleSubmit = formValues =>
    storeFrontCreate
      .create(formValues)
      .then(({ result }) => {
        const guid = prop('guid', result)
        const type = prop('type', result)
        const params = { guid, type, tab: INTEGRATION_TABS.GENERAL }
        const detailPath = generatePath(ROUTES.INTEGRATION_UPDATE_PATH, params)
        navigate(detailPath)
      })
      .then(() => snackbar({ message: messages.CREATE_SUCCESS }))

  const isLoading = storeFrontCreate.isLoading

  const initialValues = {
    type,
    status: ACTIVE,
    storeDomain: prop('shop', params),
    channelAccessToken
  }

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={t(title)}
      isLoading={storeFrontCreate.isLoading}
      activeNav={NAV.INTEGRATION}
      breadcrumbs={breadcrumbs}
    >
      <StorefrontIntegrationCreateForm
        onSubmit={handleSubmit}
        initialValues={initialValues}
        pageTitle={t(title)}
        parentTitle={t(NAV.SETTINGS)}
        isLoading={isLoading}
      />
    </DashboardLayout>
  )
}

export default StorefrontIntegrationCreateContainer
