import React from 'react'
import PropTypes from 'prop-types'
import { SwitchField } from 'storfox-form-fields'
import Typography from '@mui/material/Typography'

import { AUTO_PUBLISH } from '../ActionFields/ActionSelectField'

export const LISTING_CREATED_FIELDS = [
  'autoPublish'
]

function ListingCreatedAction ({ action, actionName }) {
  return (
    <>
      {action === AUTO_PUBLISH && (
        <SwitchField
          name={actionName}
          label={<Typography display="inline" align="center">Auto publish</Typography>}
        />
      )}
    </>
  )
}

ListingCreatedAction.propTypes = {
  action: PropTypes.string.isRequired,
  actionName: PropTypes.string.isRequired
}

export default ListingCreatedAction
