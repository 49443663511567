import { sprintf } from 'sprintf-js'
import { useDelete } from 'storfox-api-hooks'

import * as API from '../constants/api'

function useFilterRemove (id) {
  const url = sprintf(API.FILTER_REMOVE, id)
  return useDelete(url)
}

export default useFilterRemove
