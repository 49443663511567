import React from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { useSnackbar } from 'storfox-snackbar'
import { path } from 'ramda'
import { useTranslation } from 'react-i18next'

import { DashboardLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'
import { BUNDLE_UPDATE_TABS } from '~/constants/tabs'

import { useBundleBrandCreate, useBundleCategoryCreate, useBundleCreate } from '../hooks'
import { BundleCreateSerializer } from '../serializers'
import { BundleCreateForm } from '../components/BundleCreate'

function BundleCreateContainer () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const bundleCreate = useBundleCreate()
  const bundleCategoryCreate = useBundleCategoryCreate()
  const bundleBrandCreate = useBundleBrandCreate()

  const message = t('Successfully created')

  const title = 'New Bundle'

  const handleCategoryCreate = name =>
    bundleCategoryCreate.create({ name })

  const handleBrandCreate = name =>
    bundleBrandCreate.create({ name })

  const handleSubmit = formValues =>
    bundleCreate.create(BundleCreateSerializer(formValues))
      .then(res => {
        snackbar({ message })
        return res
      })
      .then(res => {
        const guid = path(['result', 'guid'], res)
        const updatePath = generatePath(ROUTES.BUNDLE_UPDATE_PATH, { guid, tab: BUNDLE_UPDATE_TABS.COMPOSITION })
        navigate(updatePath)
      })

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      isLoading={bundleCreate.isLoading}
      activeNav={NAV.BUNDLES}
      breadcrumbs={breadcrumbs}
    >

      <BundleCreateForm
        title={title}
        onSubmit={handleSubmit}
        isLoading={bundleCreate.isLoading}
        categoryLoading={bundleCategoryCreate.isLoading}
        brandLoading={bundleBrandCreate.isLoading}
        onCategoryCreate={handleCategoryCreate}
        onBrandCreate={handleBrandCreate}
      />

    </DashboardLayout>
  )
}

export default BundleCreateContainer
