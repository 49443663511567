import { defaultTo, flatten, map, pipe, range, split } from 'ramda'

import { FONT_SIZES } from '~/constants/barcode'

export const multiSku = (item, quantity) =>
  pipe(
    Number,
    range(0),
    map(() => item),
    flatten
  )(quantity)

export const getCodes = map(item => {
  const [code, titlesString, image] = split(/\|/g, item)
  const titles = pipe(
    defaultTo(''),
    split(',')
  )(titlesString)

  return { code, titles, image }
})

export const getFontSize = fontSize => {
  if (fontSize === FONT_SIZES.SMALL) {
    return 12
  }
  if (fontSize === FONT_SIZES.LARGE) {
    return 28
  }

  return 20
}
