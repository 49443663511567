import React, { useCallback } from 'react'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { path, pathOr, prop, propOr } from 'ramda'
import PropTypes from 'prop-types'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import TableContainer from '@mui/material/TableContainer'
import { Divider, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'
import CardContent from '@mui/material/CardContent'
import Card from '@mui/material/Card'
import { generatePath } from 'react-router-dom'

import SkeletonList from '~/components/Skeleton'
import { HoverableRow } from '~/components/Table'
import Container, { Content, Header } from '~/components/Container'
import PageTitle from '~/components/PageTitle'
import * as NAV from '~/constants/nav-titles'
import { Button, DiscardButton } from '~/components/Buttons'
import { CardHeader } from '~/components/Cards'
import TextLabelLoader from '~/components/TextLabelLoader'
import TableLink from '~/components/Link'
import * as ROUTES from '~/constants/routes'
import { generateCdnUrl } from '~/utils'
import { useCompany } from '~/components/Profile'
import { getDateTimeGMTUser } from '~/utils/date'
import { DATE_FORMAT_ISO_8601_TIME } from '~/constants/dateFormat'

import InvoiceStatus from './InvoiceStatus'

const TableRowStyled = styled(HoverableRow)({
  height: '70px'
})

const TableCellStyled = styled(TableCell)({
  minWidth: '150px'
})

const StatusesBoxStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      textAlign: 'center',
      marginBottom: theme.spacing(3)
    },
    '& > *:last-child': {
      marginBottom: 0
    }
  },
  '& p': {
    minWidth: '150px'
  }
}))

function ContractInvoiceDetail ({ pageTitle, detail, onGenerateInvoice, onGenerateExcel, onMarkAsPaid }) {
  const { t } = useTranslation()
  const { isThreePl } = useCompany()

  const isLoading = prop('isLoading', detail)
  const result = prop('detail', detail)
  const bills = propOr([], 'bills', result)
  const status = prop('paymentStatus', result)
  const period = prop('period', result)
  const totalPrice = prop('totalPrice', result)
  const invoiceUrl = prop('invoiceUrl', result)
  const guid = prop('guid', result)
  const profileGuid = pathOr('', ['profile', 'guid'], result)
  const profileName = path(['profile', 'name'], result)
  const profileLink = generatePath(ROUTES.BILLING_PROFILE_UPDATE_PATH, { guid: profileGuid, tab: 'general' })

  const handleGenerateInvoice = useCallback(() => {
    if (invoiceUrl) {
      window.open(generateCdnUrl(invoiceUrl), '_blank')
    } else {
      onGenerateInvoice(guid)
    }
  }, [guid, invoiceUrl, onGenerateInvoice])

  const isPaid = status === 'paid'

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={pageTitle}
          pageTitleLoading={isLoading}
          parentTitle={NAV.CONFIGURATION}
          rightButton={(
            <>
              <DiscardButton disabled={isLoading} />
              <Button
                variant="contained"
                onClick={handleGenerateInvoice}
                data-cy="submit"
              >
                Generate invoice
              </Button>
              <Button
                variant="contained"
                onClick={onGenerateExcel}
                data-cy="submit"
              >
                Generate excel
              </Button>
              {isThreePl && (
                <Button
                  variant="contained"
                  onClick={onMarkAsPaid}
                  data-cy="submit"
                  disabled={isPaid}
                >
                  Mark as paid
                </Button>
              )}
            </>
          )}
        />
      </Header>

      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={12}>
            <Box mb={3}>
              <Card>
                <CardHeader title="Invoice information" />
                <Divider />
                <CardContent>
                  <StatusesBoxStyled>
                    <TextLabelLoader label="Status">
                      <InvoiceStatus value={status} />
                    </TextLabelLoader>
                    <TextLabelLoader label="Period">
                      {period}
                    </TextLabelLoader>
                    <TextLabelLoader label="Subtotal price">
                      {totalPrice}
                    </TextLabelLoader>
                    <TextLabelLoader label="Profile">
                      <TableLink to={profileLink}>{profileName}</TableLink>
                    </TextLabelLoader>
                  </StatusesBoxStyled>
                </CardContent>
              </Card>
            </Box>
          </Grid>
          <Grid item={true} lg={12}>
            <Box component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto' }}>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCellStyled>{t('Number')}</TableCellStyled>
                      <TableCellStyled>{t('Period start')}</TableCellStyled>
                      <TableCellStyled>{t('Period end')}</TableCellStyled>
                      <TableCellStyled>{t('Total price')}</TableCellStyled>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoading && (
                      <SkeletonList
                        rowCount={3}
                        columnCount={13}
                        height={20}
                        rowHeight={70}
                      />
                    )}
                    {bills.map((row, index) => {
                      const number = prop('number', row)
                      const guid = propOr('', 'guid', row)
                      const periodStartDate = getDateTimeGMTUser(prop('periodStart', row))
                      const periodEndDate = getDateTimeGMTUser(prop('periodEnd', row))
                      const periodStart = periodStartDate.toFormat(DATE_FORMAT_ISO_8601_TIME)
                      const periodEnd = periodEndDate.toFormat(DATE_FORMAT_ISO_8601_TIME)
                      const totalPrice = prop('totalPrice', row)
                      const billingUrl = generatePath(ROUTES.BILLING_DETAIL_PATH, { guid })
                      return (
                        <TableRowStyled key={index}>
                          <TableCell>
                            <TableLink to={billingUrl}>
                              {number}
                            </TableLink>
                          </TableCell>
                          <TableCell>{periodStart}</TableCell>
                          <TableCell>{periodEnd}</TableCell>
                          <TableCell>{totalPrice}</TableCell>
                        </TableRowStyled>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

ContractInvoiceDetail.propTypes = {
  pageTitle: PropTypes.object.isRequired,
  detail: PropTypes.object,
  onGenerateInvoice: PropTypes.func,
  onGenerateExcel: PropTypes.func,
  onMarkAsPaid: PropTypes.func
}

export default ContractInvoiceDetail
