import React from 'react'

import SelectField from '~/components/Fields/SelectField'

import {
  ADJUSTMENT,
  MOVEMENT,
  PACKING,
  PICKING,
  PURCHASE_ORDER,
  PUTAWAY_V1,
  PUTAWAY_V2,
  RECEIVE,
  RETURN,
  SALE_ORDER,
  SHIPMENT,
  STOCK_COUNT,
  TRANSFER
} from './ActionLink'

export const OPTIONS = [
  {
    value: SALE_ORDER,
    name: 'Sale order'
  },
  {
    value: PURCHASE_ORDER,
    name: 'Purchase order'
  },
  {
    value: PUTAWAY_V1,
    name: 'Putaway v1'
  },
  {
    value: PUTAWAY_V2,
    name: 'Putaway v2'
  },
  {
    value: ADJUSTMENT,
    name: 'Adjustment'
  },
  {
    value: TRANSFER,
    name: 'Transfer'
  },
  {
    value: MOVEMENT,
    name: 'Movement'
  },
  {
    value: PACKING,
    name: 'Packing'
  },
  {
    value: PICKING,
    name: 'Picking'
  },
  {
    value: RECEIVE,
    name: 'Receive'
  },
  {
    value: RETURN,
    name: 'Return'
  },
  {
    value: SHIPMENT,
    name: 'Shipment'
  },
  {
    value: STOCK_COUNT,
    name: 'Stock count'
  },
]

function ActionTypeField () {
  return (
    <SelectField
      label="Action"
      name="action"
      options={OPTIONS}
    />
  )
}

export default ActionTypeField
