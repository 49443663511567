import React from 'react'
import PropTypes from 'prop-types'

import Status from '~/components/Status'

export const NEW = 'new'
export const PROGRESS = 'progress'
export const COMPLETED = 'completed'

const NAMES = {
  [NEW]: 'New',
  [PROGRESS]: 'In progress',
  [COMPLETED]: 'Completed'
}

const COLORS = {
  [NEW]: 'success',
  [PROGRESS]: 'warning',
  [COMPLETED]: 'success'
}

function ReceiveStatus ({ value }) {
  return (
    <Status label={NAMES[value]} color={COLORS[value]} />
  )
}

ReceiveStatus.propTypes = {
  value: PropTypes.string.isRequired
}

export default ReceiveStatus
