import React from 'react'
import List from '@mui/material/List'
import PropTypes from 'prop-types'
import { styled } from '@mui/material'

const ListStyled = styled(List, {
  shouldForwardProp: (propName) => propName !== 'isShrinkChild'
})(({ theme, isShrinkChild }) => ({
  ...(isShrinkChild && {
    display: 'none',
    position: 'absolute',
    left: 'calc(100%)',
    top: 0,
    zIndex: 9999,
    padding: 0,
    minWidth: 200,
    background: theme.palette.primary.main
  })
}))

function NavList ({ children, className, isShrink, isChild, ...props }) {
  return (
    <ListStyled
      isShrinkChild={isShrink && isChild}
      className={className}
      {...props}
    >
      {children}
    </ListStyled>
  )
}

NavList.propTypes = {
  children: PropTypes.any.isRequired,
  isShrink: PropTypes.bool.isRequired,
  isChild: PropTypes.bool.isRequired,
  className: PropTypes.string
}

export default NavList
