import { useCallback, useEffect, useState } from 'react'

import * as cookie from '~/utils/cookies'

const KEY = 'sidebar'
const DEFAULT = true

const set = value => cookie.set(KEY, JSON.stringify(value))
const get = () => {
  const value = cookie.get(KEY)

  return value ? JSON.parse(value) : DEFAULT
}

export function useSidebar () {
  const initialState = get()
  const [open, setOpen] = useState(initialState)

  useEffect(() => {
    set(open)
  }, [open])

  const handleOpen = useCallback(() => setOpen(true), [])
  const handleClose = useCallback(() => setOpen(false), [])
  const handleToggle = useCallback(() => setOpen(oldOpen => !oldOpen), [])

  return {
    open,
    handleOpen,
    handleClose,
    handleToggle
  }
}
