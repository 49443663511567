import { AuthLayout, BlankLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import WooCommerceIntegrationUpdateContainer from './WooCommerceIntegrationUpdateContainer'
import WooCommerceIntegrationCreateContainer from './WooCommerceIntegrationCreateContainer'
import WooCommerceReturnContainer from './WooCommerceReturnContainer'

export default () => {
  return [
    {
      layout: BlankLayout,
      path: ROUTES.WOOCOMMERCE_INSTALL_PATH,
      component: WooCommerceReturnContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.WOOCOMMERCE_INTEGRATION_CREATE_PATH,
      component: WooCommerceIntegrationCreateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.WOOCOMMERCE_INTEGRATION_UPDATE_PATH,
      component: WooCommerceIntegrationUpdateContainer
    }
  ]
}
