import React from 'react'
import { prop, propOr } from 'ramda'
import PropTypes from 'prop-types'
import Divider from '@mui/material/Divider'
import { Card, CardContent } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Link from '@mui/material/Link'
import { generatePath, Link as RouterLink } from 'react-router-dom'

import { Volume, Weight } from '~/components/Converters'
import Money from '~/components/Money'
import { CardHeader } from '~/components/Cards'
import * as ROUTES from '~/constants/routes'
import { SHIPMENT_DETAIL_TABS } from '~/constants/tabs'
import TextLoader from '~/components/TextLoader'

function ShipmentCard ({ detail }) {
  const shipmentAddress = prop('shipmentAddress', detail)
  const shipment = prop('shipment', detail)

  const service = prop('service', detail)

  const guid = prop('guid', shipment)
  const shipmentNumber = prop('shipmentNumber', shipment)

  const length = propOr(0, 'length', shipmentAddress)
  const width = propOr(0, 'width', shipmentAddress)
  const height = propOr(0, 'height', shipmentAddress)
  const weight = propOr(0, 'weight', shipmentAddress)
  const volume = Number(width) * Number(height) * Number(length)

  const shippingFee = propOr(0, 'price', service)

  const path = guid && generatePath(ROUTES.SHIPMENT_DETAIL_PATH, { guid, tab: SHIPMENT_DETAIL_TABS.GENERAL })

  return (
    <Card>
      <CardHeader title="Shipment" />
      <Divider />
      <CardContent style={{ padding: 0 }}>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell>Shipment number:</TableCell>
              <TableCell>
                {guid ? (
                  <Link
                    component={RouterLink}
                    to={path}
                    underline="always"
                  >
                    {shipmentNumber}
                  </Link>
                ) : (
                  <TextLoader>{shipmentNumber}</TextLoader>
                )}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Net weight:</TableCell>
              <TableCell>
                <Weight value={weight} />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Dimensions:</TableCell>
              <TableCell>
                <Volume value={volume} />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Shipping fee:</TableCell>
              <TableCell>
                <Money value={shippingFee} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}

ShipmentCard.propTypes = {
  detail: PropTypes.object.isRequired
}

export default ShipmentCard
