import { colors } from '@mui/material'

const white = '#ffffff'

export default {
  primary: {
    main: '#476CBD'
  },
  secondary: {
    contrastText: white,
    dark: colors.amber[900],
    main: '#172B54',
    light: colors.amber.A400
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: '#1B2546',
    secondary: colors.blueGrey[600],
    link: colors.blue[600]
  },
  link: colors.blue[800],
  icon: {
    primary: colors.blueGrey[600]
  },
  background: {
    default: '#F4F6F8',
    paper: white,
    inversePaper: '#282C34',
    tableBorder: '#eeeeee'
  },
  inputHover: {
    color: '#000'
  },
  hover: {
    color: '#0000000A'
  },
  divider: colors.grey[200],
  border: {
    color: 'transparent'
  }
}
