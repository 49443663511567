import React from 'react'
import { useField } from 'react-final-form'
import { path, prop, propOr } from 'ramda'
import { generatePath } from 'react-router-dom'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import { Divider } from '@mui/material'
import { useRequest } from 'storfox-api-hooks'
import { sprintf } from 'sprintf-js'

import * as API from '~/constants/api'
import * as ROUTES from '~/constants/routes'
import useDialog from '~/hooks/useDialog'
import { SUPPLIER_DETAIL_TABS } from '~/constants/tabs'
import { EmptyCustomerIcon } from '~/components/Icons'
import EmptyContent from '~/components/EmptyContent/EmptyContent'
import { emptyObject } from '~/constants/empty'

import SupplierAddresses from './SupplierAddresses'
import SupplierDialogForm from './SupplierDialogForm'

import SupplierInfoRow from '../../SupplierInfoRow'
import SupplierCreateSearchField from '../../Fields/SupplierSearchCreateField'

function PurchaseOrderSupplierInfo ({ onSupplierCreate }) {
  const { open, handleClose, handleOpen } = useDialog()
  const request = useRequest()

  const supplierField = useField('supplier')
  const onSupplierChange = supplierField.input.onChange
  const supplier = supplierField.input.value
  const supplierGuid = prop('guid', supplier)

  const deliveryAddresses = propOr([], 'deliveryAddresses', supplier)
  const billingAddresses = propOr([], 'billingAddresses', supplier)

  const deliveryAddressField = useField('deliveryAddress')
  const onDeliveryAddressChange = deliveryAddressField.input.onChange
  const deliveryAddress = deliveryAddressField.input.value || emptyObject

  const companyField = useField('company')
  const companyFieldValue = companyField.input.value
  const companyGuid = prop('guid', companyFieldValue)

  const billingAddressField = useField('billingAddress')
  const onBillingAddressChange = billingAddressField.input.onChange
  const billingAddress = billingAddressField.input.value || emptyObject

  const params = { guid: supplierGuid, tab: SUPPLIER_DETAIL_TABS.GENERAL }
  const link = supplierGuid && generatePath(ROUTES.SUPPLIER_DETAIL_PATH, params)

  const handleAddressSelect = (event, value) => {
    if (value) {
      const deliveryAddress = path(['deliveryAddresses', 0], value)
      const billingAddress = path(['billingAddresses', 0], value)
      onDeliveryAddressChange(deliveryAddress)
      onBillingAddressChange(billingAddress)
      onSupplierChange(value)
    }
  }

  const handleSupplierCreate = values =>
    onSupplierCreate({ ...values, status: 'active' })
      .then(({ result }) => {
        const guid = prop('guid', result)
        const url = sprintf(API.SUPPLIER_DETAIL, guid)
        request.get(url).then((res) => {
          const resResult = path(['data', 'result'], res)
          handleAddressSelect(null, resResult)
        })

        return handleClose()
      })

  return (
    <>
      <SupplierDialogForm
        open={open}
        onClose={handleClose}
        initialValues={{ open }}
        onSubmit={handleSupplierCreate}
      />

      <SupplierCreateSearchField
        error={supplierField.meta.invalid}
        helperText={supplierField.meta.submitError}
        onChange={handleAddressSelect}
        onClick={handleOpen}
        name="searchSupplier"
        disabled={!companyFieldValue}
        params={{ companyGuid }}
      />

      <Box mt={2} mb={2}>
        <Divider />
      </Box>
      {supplier
        ? (
          <>
            <SupplierInfoRow supplier={supplier} link={link} />

            <Box mt={2}>
              <SupplierAddresses
                billingAddress={billingAddress}
                deliveryAddress={deliveryAddress}
                supplierDeliveryAddresses={deliveryAddresses}
                supplierBillingAddresses={billingAddresses}
                onBillingAddressChange={onBillingAddressChange}
                onDeliveryAddressChange={onDeliveryAddressChange}
              />
            </Box>
          </>)
        : (
          <EmptyContent
            icon={<EmptyCustomerIcon />}
            text="A supplier is not added"
            pt={9}
            pb={9}
          />
        )}
    </>
  )
}

PurchaseOrderSupplierInfo.propTypes = {
  onSupplierCreate: PropTypes.func.isRequired
}

export default PurchaseOrderSupplierInfo
