import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button, ListItem, styled } from '@mui/material'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

const ButtonStyled = styled(Button, {
  shouldForwardProp: (propName) => propName !== 'isActive'
})(({ theme, isActive }) => ({
  padding: theme.spacing(2),
  paddingTop: 10,
  paddingBottom: 10,
  justifyContent: 'flex-start',
  textTransform: 'none',
  letterSpacing: 0,
  width: '100%',
  minWidth: 'auto',
  color: theme.palette.primary.contrastText,
  '&:hover': {
    background: theme.palette.primary.dark
  },
  ...(isActive && {
    background: theme.palette.primary.dark,
    position: 'relative',
    '&:before': {
      content: "''",
      position: 'absolute',
      left: 0,
      top: 'calc(50% - 12px)',
      width: 3,
      height: 24,
      background: 'white',
      borderRadius: '0 3px 3px 0'
    }
  })
}))

const ButtonLeafStyled = styled(Button, {
  shouldForwardProp: (propName) => propName !== 'isActive'
})(({ theme, isActive }) => ({
  padding: '5px 14px',
  justifyContent: 'flex-start',
  textTransform: 'none',
  letterSpacing: 0,
  width: '100%',
  color: theme.palette.primary.contrastText,
  fontWeight: theme.typography.fontWeightRegular,
  '&.depth-0': {
    fontWeight: theme.typography.fontWeightMedium
  },
  '&.Mui-disabled': {
    color: theme.components.StorfoxSidebar.styleOverrides.disabled.color
  },
  '&:hover': {
    background: theme.palette.primary.dark
  },
  ...(isActive && {
    background: theme.palette.primary.dark
  })
}))

const TitleLeafStyled = styled(Typography)(({ theme }) => ({
  position: 'relative',
  display: 'inline-block',
  '&:before': {
    content: "''",
    position: 'absolute',
    left: '-16px',
    top: 'calc(50% - 1px)',
    width: 7,
    height: 2,
    background: theme.palette.primary.contrastText
  }
}))

const ListItemStyled = styled(ListItem)(({ theme }) => ({
  display: 'block',
  paddingTop: 0,
  paddingBottom: 0,
  '&:hover > ul': {
    display: 'block'
  }
}))

const ListItemLeafStyled = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  paddingTop: 6,
  paddingBottom: 6,
}))

function ShrinkNavItem ({
  title,
  href,
  depth,
  children,
  handleToggleMenuItem,
  open,
  enabled,
  icon: Icon,
  className,
  active,
  label: Label,
  ...rest
}) {
  let paddingLeft = 16

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth
  }

  const style = { paddingLeft }

  const rootWithoutChildren = depth === 0 && !children

  if (rootWithoutChildren) {
    return (
      <ListItemStyled
        {...rest}
        className={className}
        disableGutters={true}
        key={title}
      >
        <ButtonStyled
          isActive={open}
          component={Link}
          to={href}
          style={style}
          disabled={!enabled}
        >
          {Icon && (
            <Box sx={{ minWidth: 24, width: 24, display: 'flex' }}>
              <Icon />
            </Box>
          )}
        </ButtonStyled>
        {children}
      </ListItemStyled>
    )
  }

  if (children) {
    return (
      <ListItemStyled
        {...rest}
        className={className}
        disableGutters={true}
        key={title}
      >
        <ButtonStyled
          isActive={open}
          onClick={() => handleToggleMenuItem(href)}
          style={style}
          disabled={!enabled}
        >
          {Icon && (
            <Box sx={{ minWidth: 24, width: 24, display: 'flex' }}>
              <Icon />
            </Box>
          )}
        </ButtonStyled>
        {children}
      </ListItemStyled>
    )
  }

  return (
    <ListItemLeafStyled
      {...rest}
      className={className}
      disableGutters={true}
      key={title}
    >
      <ButtonLeafStyled
        isActive={active}
        className={`depth-${depth}`}
        component={Link}
        style={style}
        disabled={!enabled}
        to={href}
      >
        {Icon && <Icon />}
        <TitleLeafStyled
          color="inherit"
          variant="body2"
        >
          {title}
        </TitleLeafStyled>
        {Label && (
          <Box
            component="span"
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: 'auto'
            }}>
            <Label />
          </Box>
        )}
      </ButtonLeafStyled>
    </ListItemLeafStyled>
  )
}

ShrinkNavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  label: PropTypes.any,
  open: PropTypes.bool,
  handleToggleMenuItem: PropTypes.func,
  title: PropTypes.string.isRequired,
  active: PropTypes.bool,
  enabled: PropTypes.bool,
}

ShrinkNavItem.defaultProps = {
  open: false,
  handleToggleMenuItem: null,
}

export default ShrinkNavItem
