export const ORDER_CREATED = 'order_create'
export const ORDER_CHANGED = 'order_change'
export const PICKING_CREATED = 'picking_create'
export const PUTAWAY_CREATED = 'putaway_create'
export const PUTAWAY_V2_CREATED = 'putaway2_create'
export const PACKING_CREATED = 'packing_create'
export const SHIPMENT_CREATED = 'shipment_create'
export const LOW_STOCK = 'low_stock'
export const REPLENISHMENT = 'replenishment'
export const LISTING_CREATED = 'listing_create'
export const PURCHASE_CHANGE = 'purchase_change'
export const SHIPMENT_SET_STATUS = 'shipment_status_change'

export const EVENT_NAMES = {
  [ORDER_CREATED]: 'Order created',
  [ORDER_CHANGED]: 'Order changed',
  [PICKING_CREATED]: 'Picking created',
  [PUTAWAY_CREATED]: 'Putaway created',
  [PUTAWAY_V2_CREATED]: 'Putaway v2 created',
  [PACKING_CREATED]: 'Packing created',
  [SHIPMENT_CREATED]: 'Shipment created',
  [LOW_STOCK]: 'Low stock',
  [REPLENISHMENT]: 'Replenishment',
  [LISTING_CREATED]: 'Listing created',
  [PURCHASE_CHANGE]: 'Purchase change',
  [SHIPMENT_SET_STATUS]: 'Shipment set status'
}

export const FILTER_NAME = 'automation'
