import PropTypes from 'prop-types'
import React from 'react'
import { styled } from '@mui/material'
import Link from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'

const LinkStyled = styled(Link)(({ theme }) => ({
  width: '100%',
  height: '100%',
  padding: '40px 30px',
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('lg')]: {
    padding: '80px 30px'
  }
}))

function ChoiceCardLink ({ children, disabled, ...props }) {
  return (
    <LinkStyled
      component={disabled ? 'div' : RouterLink}
      underline="none"
      {...props}
    >
      {children}
    </LinkStyled>
  )
}

ChoiceCardLink.propTypes = {
  children: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
  to: PropTypes.string
}

export default ChoiceCardLink
