import palette from '../palette'

export default {
  styleOverrides: {
    root: {
      color: palette.icon.primary,
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.03)'
      }
    },
    '&.MuiIconButton-colorInherit': {
      color: 'inherit !important'
    }
  }
}
