import React from 'react'
import { useSnackbar } from 'storfox-snackbar'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { prop } from 'ramda'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import { SALE_RETURN_TABS } from '~/constants/tabs'
import useMessages from '~/hooks/useMessages'

import { ReturnCreateForm } from '../components/ReturnCreate'
import { useReturnCreate, useReturnCreateDetail, useReturnVariants } from '../hooks'
import { ReturnCreateSerializer, ReturnInitCreateSerializer } from '../serializers'

function ReturnCreateContainer () {
  const { guid } = useParams()
  const messages = useMessages()
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const returnCreate = useReturnCreate()
  const returnVariants = useReturnVariants()
  const returnCreateDetail = useReturnCreateDetail(guid)

  const initialValues = ReturnInitCreateSerializer(returnCreateDetail.detail)

  const handleSubmit = formValues =>
    returnCreate.create(ReturnCreateSerializer(formValues))
      .then(({ result }) => {
        const guid = prop('guid', result)
        const path = generatePath(ROUTES.RETURN_UPDATE_PATH, { guid, tab: SALE_RETURN_TABS.GENERAL })
        return navigate(path)
      })
      .then(() => snackbar({ message: messages.CREATE_SUCCESS }))

  const title = 'New return'

  const isLoading = returnCreate.isLoading || returnCreateDetail.isLoading

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      isLoading={isLoading}
      activeNav={NAV.SALE_RETURNS}
      breadcrumbs={breadcrumbs}
    >
      <ReturnCreateForm
        pageTitle={title}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        isLoading={isLoading}
        returnVariants={returnVariants}
      />
    </DashboardLayout>
  )
}

export default ReturnCreateContainer
