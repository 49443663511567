import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { GridCell } from '@mui/x-data-grid-pro'

const GridCellStyled = styled(GridCell)(({ theme }) => ({
  overflow: 'hidden',
  paddingLeft: 10,
  paddingRight: 10,
  display: 'flex',
  alignItems: 'center',
  fontSize: 14,
  borderBottom: '1px solid',
  borderBottomColor: theme.components.StorfoxTable.styleOverrides.row.color + ' !important',
  fontWeight: 400,
  '&:focus': {
    outline: 'none !important'
  },
  '&:focus-within': {
    outline: 'none !important'
  }
}))

const CellComponent = ({ children, colIndex, ...props }) => {
  return (
    <GridCellStyled data-cy={`grid-col-${colIndex}`} colIndex={colIndex} {...props}>
      {children}
    </GridCellStyled>
  )
}

CellComponent.propTypes = {
  children: PropTypes.any,
  colIndex: PropTypes.number.isRequired,
}

export default CellComponent
