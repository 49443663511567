import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material'

import CheckCircle from './svg/check-circle.svg'

const CheckCircleStyled = styled(CheckCircle, {
  shouldForwardProp: (propName) => propName !== 'color'
})(({ theme, color }) => ({
  ...(color === 'primary' && {
    fill: theme.palette.primary.main
  }),
  ...(color === 'secondary' && {
    fill: theme.palette.secondary.main
  })
}))

function CheckCircleIcon ({ color = 'secondary', ...props }) {
  return (
    <CheckCircleStyled{...props} />
  )
}

CheckCircleIcon.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary'])
}

export default CheckCircleIcon
