import React from 'react'
import { useField } from 'react-final-form'
import PropTypes from 'prop-types'

import TextField from '~/components/Fields/TextField'

const BarcodeField = React.forwardRef((props, ref) => {
  const { name, onChange, error, ...defaultProps } = props
  const { input, meta } = useField(name)

  const enterPressed = (event) => {
    if (event.key === 'Enter' && input.value !== '') {
      event.preventDefault()
      onChange(input.value)
    }
  }

  return (
    <TextField
      name={name}
      inputProps={{ ref }}
      error={Boolean(meta.data.error)}
      helperText={meta.data.error}
      onKeyPress={enterPressed}
      {...defaultProps}
    />
  )
})

BarcodeField.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool,
  error: PropTypes.string
}
export default React.memo(BarcodeField)
