import React from 'react'
import PropTypes from 'prop-types'
import { Beforeunload } from 'react-beforeunload'
import { useField } from 'react-final-form'
import { isEmpty } from 'ramda'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import { withForm } from '~/components/Form'
import PageTitle from '~/components/PageTitle'
import { Button, DiscardButton } from '~/components/Buttons'
import FluidContainer, { FluidContent, FluidHeader } from '~/components/FluidContainer'
import * as NAV from '~/constants/nav-titles'

import LineItemsGroup from './LineItemsGroup'

const message = 'You have active line item not proceeded'

function PutawayCreateForm (props) {
  const {
    form,
    pageTitle,
    isLoading,
    onScanItem,
  } = props

  const snackbar = useSnackbar()
  const activeLineItemField = useField('activeLineItem')
  const activeLineItem = activeLineItemField.input.value
  const { handleSubmit } = form
  const handlePick = isEmpty(activeLineItem) ? handleSubmit : () => snackbar({ message, type: ALTER_ERROR, })
  return (
    <form onSubmit={handleSubmit}>
      <FluidContainer>
        <FluidHeader>
          <PageTitle
            pageTitle={pageTitle}
            parentTitle={NAV.PUTAWAY}
            rightButton={(
              <>
                <DiscardButton />
                <Button
                  variant="contained"
                  onClick={handlePick}
                  data-cy="submit"
                >
                  Pick
                </Button>
              </>
            )}
          />
        </FluidHeader>
        <FluidContent>
          <LineItemsGroup
            isLoading={isLoading}
            onScanItem={onScanItem}
            handleSubmit={handleSubmit}
          />
        </FluidContent>
      </FluidContainer>
      <Beforeunload onBeforeunload={(event) => event.preventDefault()} />
    </form>
  )
}

PutawayCreateForm.propTypes = {
  form: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  pageTitle: PropTypes.string.isRequired,
  onScanItem: PropTypes.func.isRequired,
}

export default withForm(PutawayCreateForm)
