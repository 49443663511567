import React from 'react'
import AlertTitle from '@mui/material/AlertTitle'
import Alert from '@mui/material/Alert'
import PropTypes from 'prop-types'
import { useSnackbar } from 'storfox-snackbar'
import Box from '@mui/material/Box'
import { styled } from '@mui/material'

import { unescapeBtoa } from '~/utils'
import CopyIcon from '~/components/Icons/CopyIcon'
import * as ROUTES from '~/constants/routes'
import useMessages from '~/hooks/useMessages'
import PrintLink from '~/components/Instructions/PrintLink'

const getDoneBarcodePath = doneText => {
  const barcode = [{ barcode: doneText }]

  return `${ROUTES.BARCODE_GENERATOR_PATH}?barcodes=${unescapeBtoa(barcode)}`
}

const InfoWrapperAlertStyled = styled(Alert)({
  maxWidth: '400px',
  backgroundColor: '#D9E8F3 !important',
  borderRadius: '4px',
  marginLeft: '12px'
})

function BarcodeDoneInstruction ({ doneText, children }) {
  const messages = useMessages()
  const snackbar = useSnackbar()
  const doneBarcodePath = getDoneBarcodePath(doneText)

  return (
    <InfoWrapperAlertStyled severity="info" icon={false}>
      <AlertTitle sx={{
        color: '#273257 !important',
        lineHeight: '21px',
        marginBottom: '0'
      }}>
        Next step
      </AlertTitle>

      <Box component="span" sx={{
        color: 'rgba(39, 50, 87, 0.7)',
        fontSize: '14px',
        lineHeight: '21px'
      }}>
        {children}
      </Box>
      <Box display="flex" justifyContent="space-between" mt={1}>
        <PrintLink href={doneBarcodePath}>{doneText}</PrintLink>
        <CopyIcon onClick={() => {
          navigator.clipboard.writeText(doneText)
          snackbar({ message: messages.COPY_SUCCESS })
        }} />
      </Box>
    </InfoWrapperAlertStyled>
  )
}

BarcodeDoneInstruction.defaultProps = {
  children: 'Scan "Done" barcode to proceed to the next step.'
}

BarcodeDoneInstruction.propTypes = {
  doneText: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired
}

export default BarcodeDoneInstruction
