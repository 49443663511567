import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import CallCourierIntegrationUpdateContainer from './CallCourierIntegrationUpdateContainer'
import CallCourierIntegrationCreateContainer from './CallCourierIntegrationCreateContainer'

export default () => {
  return [
    {
      layout: AuthLayout,
      path: ROUTES.CALL_COURIER_INTEGRATION_CREATE_PATH,
      component: CallCourierIntegrationCreateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.CALL_COURIER_INTEGRATION_UPDATE_PATH,
      component: CallCourierIntegrationUpdateContainer
    }
  ]
}
