import React, { useCallback } from 'react'
import Dialog from '@mui/material/Dialog'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'
import { DialogActions } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import DialogTitle from '~/components/DialogTitle'
import { Button, PrimaryButton } from '~/components/Buttons'
import * as ROUTES from '~/constants/routes'
import { generateCdnUrl } from '~/utils'

function SkipCompleteDialogForm (props) {
  const {
    open,
    onClose,
    containerWithNextOrder,
    onNextSaleOrderMove,
    onCompleteAndPrint,
    awbUrl,
    commercialInvoiceUrl
  } = props
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handlePrintAwb = useCallback(() => {
    onCompleteAndPrint()
    window.open(generateCdnUrl(awbUrl), '_blank')
  }, [awbUrl, onCompleteAndPrint])

  const handlePrintCommercial = useCallback(() => {
    onCompleteAndPrint()
    window.open(generateCdnUrl(commercialInvoiceUrl), '_blank')
  }, [commercialInvoiceUrl, onCompleteAndPrint])

  return (
    <Dialog
      open={open}
      fullWidth={true}
      onClose={onClose}
      maxWidth="sm"
    >
      <DialogTitle component="div">
        <Typography
          component="h2"
          variant="h6"
        >
          {t('Complete Packing')}
        </Typography>
        <Typography
          component="h6"
          variant="subtitle2"
        >
          {t('Move containers to shipment location and complete packing')}
        </Typography>
      </DialogTitle>
      <DialogActions>
        <Grid container={true} spacing={1}>
          {awbUrl && (
            <Grid item={true} xs={12}>
              <PrimaryButton
                fullWidth={true}
                onClick={handlePrintAwb}
              >
                Done. Print AWB
              </PrimaryButton>
            </Grid>
          )}
          {commercialInvoiceUrl && (
            <Grid item={true} xs={12}>
              <PrimaryButton
                fullWidth={true}
                onClick={handlePrintCommercial}
              >
                Done. Print Invoice
              </PrimaryButton>
            </Grid>
          )}
          {containerWithNextOrder && (
            <Grid item={true} xs={12}>
              <PrimaryButton
                fullWidth={true}
                onClick={() => onNextSaleOrderMove()}
              >
                Move to next order in {containerWithNextOrder}
              </PrimaryButton>
            </Grid>
          )}
          <Grid item={true} xs={12}>
            <Button
              fullWidth={true}
              variant="outlined"
              type="button"
              onClick={() => navigate(ROUTES.PACKING_LIST_PATH)}
              data-cy="done"
            >
              Done
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

SkipCompleteDialogForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  containerWithNextOrder: PropTypes.string,
  awbUrl: PropTypes.string,
  onCompleteAndPrint: PropTypes.func.isRequired,
  onNextSaleOrderMove: PropTypes.func.isRequired,
  commercialInvoiceUrl: PropTypes.string
}

export default SkipCompleteDialogForm
