import React from 'react'
import PropTypes from 'prop-types'

import Instruction from '~/components/Instruction'

export const SALLA_INSTRUCTIONS = [
  <>1. Enter your app id.</>,
  <>2. Add Storfox app to your Salla account by clicking on
    &nbsp;<strong>"Install"</strong> button on top-right corner.</>,
  <>3. Choose store, to which you want to integrate Storfox app.</>,
  <>4. Install the app.</>,
  <>5. After installation, you will be redirected to the app's page.</>,
  <>
    6. Now, you need to fill up all the fields:
    <br />
    <strong>Name</strong> - Name of the integration, user can provide any name - not mandatory,
    <br />
    <strong>Client ID</strong> - Should be received from 3pl user or salla app owner company,
    <br />
    <strong>Client Secret Key</strong> - Should be received from 3pl user or salla app owner company,
    <br />
    <strong>Webhook Secret</strong> - Should be received from 3pl user or salla app owner company,
    <br />
    <strong>Description</strong> - Description of the integration, user can provide any description - not mandatory.
  </>,
  <>7. Click save button on the top-right corner.</>
]

function SallaInstructionCard ({ completedTasks }) {
  return (
    <Instruction
      completedTasks={completedTasks}
      instructions={SALLA_INSTRUCTIONS}
    />
  )
}

SallaInstructionCard.propTypes = {
  completedTasks: PropTypes.number.isRequired
}

SallaInstructionCard.defaultProps = {
  completedTasks: 0
}

export default SallaInstructionCard
