import React, { useMemo } from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import { useField } from 'react-final-form'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'

import { CardHeader } from '~/components/Cards'

import TableSkeleton from '../TableSkeleton'
import LineItems from '../../ReceivePurchaseOrder/LineItems'

function PurchaseLineItemGroups (props) {
  const {
    isBarcodeMode,
    detailLoading,
    onEditUnit,
    disableEdit
  } = props

  const lineItemsField = useField('lineItems')
  const lineItems = useMemo(() => lineItemsField.input.value || [], [lineItemsField.input.value])

  return (
    <Box mt={3}>
      <Card>
        <CardHeader title="Scanned units" />
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            {!detailLoading && lineItems.length > 0 && (
              <LineItems
                lineItems={lineItems}
                onEditUnit={onEditUnit}
                edit={disableEdit}
                isBarcodeMode={isBarcodeMode}
                editDimension={true}
                deleteEnabled={true}
              />
            )}
            {detailLoading && <TableSkeleton />}
          </Grid>
        </Grid>
      </Card>
    </Box>
  )
}

PurchaseLineItemGroups.propTypes = {
  detailLoading: PropTypes.bool.isRequired,
  isBarcodeMode: PropTypes.bool,
  onEditUnit: PropTypes.func,
  disableEdit: PropTypes.bool
}

export default PurchaseLineItemGroups
