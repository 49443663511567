import React from 'react'
import PropTypes from 'prop-types'
import { SwitchField } from 'storfox-form-fields'
import Typography from '@mui/material/Typography'

import UserMultiSelectField from '../ActionFields/UserMultiSelectField'
import { ASSIGN_TO, AUTO_CREATE_SHIPMENT } from '../ActionFields/ActionSelectField'

export const PACKING_CREATED_FIELDS = [
  'assignTo',
  'autoCreateShipment'
]

function PackingCreatedAction ({ action, actionName }) {
  return (
    <>
      {action === ASSIGN_TO && <UserMultiSelectField name={`${actionName}.users`} />}
      {action === AUTO_CREATE_SHIPMENT && (
        <SwitchField
          name={actionName}
          label={<Typography display="inline" align="center">Auto create shipment</Typography>}
        />
      )}
    </>
  )
}

PackingCreatedAction.propTypes = {
  action: PropTypes.string.isRequired,
  actionName: PropTypes.string.isRequired
}

export default PackingCreatedAction
