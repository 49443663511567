import React from 'react'
import PropTypes from 'prop-types'
import { useField } from 'react-final-form'
import TextField from '@mui/material/TextField'
import Switch from '@mui/material/Switch'
import ListItemText from '@mui/material/ListItemText'
import Grid from '@mui/material/Grid'
import { useConfirm } from 'storfox-confirm-dialog'

import { Button } from '~/components/Buttons'

import DiscardButton from '../../Buttons/DiscardButton'
import DeleteButton from '../../Buttons/DeleteButton'
import { ActionsStyled, ContentStyled, FormStyled } from '../../Filter.styled'

function Save (props) {
  const {
    handleSubmit,
    onClose,
    onDelete,
    current,
    isLoading,
  } = props
  const name = useField('name')
  const enabled = useField('enabled')
  const onConfirm = useConfirm()
  const message = (
    <>
      Are you sure you want to delete {current.type}  filter <strong>{current.name}</strong>?
    </>
  )

  const onDeleteConfirm = () => {
    onConfirm({ message })
      .agree(onDelete)
      .disagree()
  }

  return (
    <FormStyled onSubmit={handleSubmit}>
      <ContentStyled>
        <Grid container={true}>
          <Grid item={true} xs={12}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth={true}
              label="Name"
              value={name.input.value}
              onChange={event => name.input.onChange(event.target.value)}
            />
          </Grid>
          <Grid item={true} xs={12} sx={{ mt: 1 }}>
            <Grid container={true}>
              <Grid
                item={true}
                xs={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Switch
                  size="small"
                  color="primary"
                  checked={enabled.input.value}
                  disabled={current.type === 'public'}
                  onChange={event => enabled.input.onChange(event.target.checked)}
                />
              </Grid>
              <Grid item={true} xs={10}>
                <ListItemText
                  primary="Display to everyone in your company"
                  secondary="Filters are saved as a new tab at the top of this list."
                />
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </ContentStyled>
      <ActionsStyled>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <DeleteButton
              disabled={!current.name || isLoading}
              onClick={onDeleteConfirm}
            />
          </Grid>
          <Grid item={true} xs={6}>
            <DiscardButton onClick={onClose} />
          </Grid>
          <Grid item={true} xs={6}>
            <Button
              type="submit"
              variant="contained"
              disabled={!name.input.value || isLoading}
              fullWidth={true}
              autoFocus={true}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </ActionsStyled>
    </FormStyled>
  )
}

Save.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  current: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default Save
