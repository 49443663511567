import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import CategoryListContainer from './containers/CategoryListContainer'
import CategoryCreateContainer from './containers/CategoryCreateContainer'
import CategoryUpdateContainer from './containers/CategoryUpdateContainer'
import CategoryDetailContainer from './containers/CategoryDetailContainer'

export default () => {
  return [
    {
      layout: AuthLayout,
      path: ROUTES.CATEGORY_CREATE_PATH,
      component: CategoryCreateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.CATEGORY_DETAIL_PATH,
      component: CategoryDetailContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.CATEGORY_UPDATE_PATH,
      component: CategoryUpdateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.CATEGORY_LIST_PATH,
      component: CategoryListContainer
    },
  ]
}
