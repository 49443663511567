import React from 'react'
import { EmailField as StorfoxEmailField } from 'storfox-form-fields'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

function EmailField ({ label, ...props }) {
  const { t } = useTranslation()

  return (
    <StorfoxEmailField label={t(label)} {...props} />
  )
}

EmailField.propTypes = {
  label: PropTypes.string
}

export default EmailField
