import { path, pick, prop } from 'ramda'
import { DEFAULT_SEARCH_PARAMS } from 'storfox-route-hooks'

import { escapeAtob, getDateTimeMidnight } from '~/utils'

export const TplBillingDetailSerializer = values => {
  const totalFulfillment = prop('totalFulfillment', values)
  const totalPaid = prop('totalPaid', values)
  const totalPrice = prop('totalPrice', values)
  const totalStorage = prop('totalStorage', values)

  const summary = {
    totalPrice,
    totalPaid,
    totalStorage,
    totalFulfillment
  }

  return { ...values, summary }
}

export const TplBillingExportSerializer = values => {
  const clientGuid = path(['client', 'guid'], values)
  const fromDatetime = getDateTimeMidnight(path(['date', 0], values))
  const toDatetime = getDateTimeMidnight(path(['date', 1], values))

  return { clientGuid, fromDatetime, toDatetime }
}

export const TplBillingFilterSerializer = params => {
  const defaultParams = pick(DEFAULT_SEARCH_PARAMS, params)
  const status = prop('status', params)
  const fromDatetime = prop('fromDateTime', params)
  const toDatetime = prop('toDateTime', params)
  const client = escapeAtob(prop('client', params))
  const clientGuid = prop('guid', client)

  return {
    ...defaultParams,
    status,
    fromDatetime,
    toDatetime,
    clientGuid
  }
}
