import React from 'react'
import PropTypes from 'prop-types'

import Status from '~/components/Status'

const ACTIVE = 'active'
const INACTIVE = 'inactive'

const COLOR = {
  [ACTIVE]: 'success',
  [INACTIVE]: 'danger'
}

function ConditionStatus ({ value }) {
  return (
    <Status
      label={value}
      color={COLOR[value]}
    />
  )
}

ConditionStatus.propTypes = {
  value: PropTypes.oneOf([ACTIVE, INACTIVE])
}

export default ConditionStatus
