import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import ReceiveListContainer from './containers/ReceiveListContainer'
import ReceivePurchaseOrderContainer from './containers/ReceivePurchaseOrderContainer'
import ReceiveTransferContainer from './containers/ReceiveTransferContainer'
import ReceiveReturnContainer from './containers/ReceiveReturnContainer'
import ReceivePurchaseBarcodingContainer from './containers/ReceivePurchaseBarcodingContainer'
import ReceiveStockBarcodingContainer from './containers/ReceiveStockBarcodingContainer'
import ReceiveReturnBarcodingContainer from './containers/ReceiveReturnBarcodingContainer'

export default () => {
  return [
    {
      layout: AuthLayout,
      path: ROUTES.RECEIVE_LIST_PATH,
      component: ReceiveListContainer,
    },
    {
      layout: AuthLayout,
      path: ROUTES.RECEIVE_PURCHASE_ORDER_DETAIL_PATH,
      component: ReceivePurchaseOrderContainer,
    },
    {
      layout: AuthLayout,
      path: ROUTES.RECEIVE_TRANSFER_DETAIL_PATH,
      component: ReceiveTransferContainer,
    },
    {
      layout: AuthLayout,
      path: ROUTES.RECEIVE_RETURN_DETAIL_PATH,
      component: ReceiveReturnContainer,
    },
    {
      layout: AuthLayout,
      path: ROUTES.RECEIVE_PO_SCAN_DETAIL_PATH,
      component: ReceivePurchaseBarcodingContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.RECEIVE_ST_SCAN_DETAIL_PATH,
      component: ReceiveStockBarcodingContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.RECEIVE_RT_SCAN_DETAIL_PATH,
      component: ReceiveReturnBarcodingContainer
    }
  ]
}
