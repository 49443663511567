import React from 'react'
import { FieldArray } from 'react-final-form-arrays'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { CheckboxField } from 'storfox-form-fields'
import IconButton from '@mui/material/IconButton'
import { defaultTo, path, prop, propOr } from 'ramda'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import { styled } from '@mui/material'
import { useField } from 'react-final-form'

import Avatar from '~/components/Avatar'
import VerticalAlignment from '~/components/VerticalAlignment'
import Subtext from '~/components/Subtext'
import EditIcon from '~/components/Icons/EditIcon'
import TextOverflow from '~/components/TextOverflow'
import DeleteIconButton from '~/components/DeleteIconButton'
import { useScreenOutline } from '~/components/ScreenOutline'
import { getFormattedDate } from '~/utils'

import DimensionStatus from '../DimensionStatus'

const NoBorderTableRowStyled = styled(TableRow)({
  '& td': {
    borderBottom: 'none',
    padding: '6px 24px 6px 16px'
  }
})

function LineItems ({ lineItems, editDimension, onEditUnit, edit, isBarcodeMode, deleteEnabled }) {
  const { handleSoundedErrorTrigger } = useScreenOutline()
  const { t } = useTranslation()

  const trackExpiryColumn = lineItems.find(path(['productVariant', 'trackExpiry']))
  const trackSerialColumn = lineItems.find(path(['productVariant', 'trackSerial']))
  const trackBatchColumn = lineItems.find(path(['productVariant', 'trackBatchNumbers']))

  const expiryAtField = useField('expiryAt')
  const expiryAtFieldValue = expiryAtField.input.value || []

  const disableEdit = edit || !isBarcodeMode

  const handleItemEdit = (value) => {
    if (disableEdit) {
      handleSoundedErrorTrigger()
    } else {
      onEditUnit(value)
    }
  }

  return (
    <TableContainer sx={{ maxHeight: '300px' }}>
      <FieldArray
        name="lineItems"
        render={({ fields }) => {
          const values = defaultTo([], fields.value)
          return (
            <Table>
              <TableHead sx={{ position: 'sticky', top: 0, zIndex: 1 }}>
                <TableRow>
                  <TableCell>{t('Image')}</TableCell>
                  <TableCell>{t('Product')}</TableCell>
                  <TableCell>{t('Supplier SKU')}</TableCell>
                  <TableCell>{t('Barcode')}</TableCell>
                  {trackSerialColumn && (
                    <TableCell>{t('Serial number')}</TableCell>
                  )}
                  {trackExpiryColumn && (
                    <TableCell>{t('Lot expiry date')}</TableCell>
                  )}
                  {trackBatchColumn && (
                    <TableCell>{t('Batch number')}</TableCell>
                  )}
                  <TableCell>{t('Condition code')}</TableCell>
                  <TableCell>{t('Scanned')}</TableCell>
                  <TableCell>{t('Received')}</TableCell>
                  <TableCell>{t('Ordered')}</TableCell>
                  <TableCell colSpan={2}>{t('Reject')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.map((item, index) => {
                  const guid = prop('guid', item)
                  const ordered = propOr('0', 'quantity', item)
                  const received = propOr('0', 'received', item)
                  const variant = prop('productVariant', item)
                  const supplierSku = prop('supplierSku', item)
                  const variantName = prop('name', variant)
                  const image = prop('defaultImage', variant)
                  const variantGuid = prop('guid', variant)
                  const sku = prop('sku', variant)
                  const serial = prop('serial', item)
                  const serialNumber = prop('serialNumber', serial)
                  const unitNumber = prop('unitNumber', serial)
                  const itemExpiresAt = expiryAtFieldValue.find(item => item.guid === variantGuid)
                  const expiresAt = getFormattedDate(prop('expiresAt', itemExpiresAt))
                  const conditionCode = propOr('', 'conditionCode', serial)
                  const quantity = propOr('0', 'quantity', serial)
                  const batchNumber = propOr('', 'batchNumber', serial)

                  const width = prop('width', variant)
                  const height = prop('height', variant)
                  const length = prop('length', variant)
                  const weight = prop('weight', variant)
                  const isFilled = Boolean(width || height || length || weight)

                  return (
                    <NoBorderTableRowStyled key={`${image}-${index}`}>
                      <TableCell style={{ position: 'relative' }}>
                        {editDimension && <DimensionStatus isFilled={isFilled} />}
                        <Avatar src={image} alt={variantName} />
                      </TableCell>
                      <TableCell width={200}>
                        <VerticalAlignment
                          primary={<TextOverflow selfTooltip={true} lines={1}>{variantName}</TextOverflow>}
                          secondary={<Subtext lines={1}>{sku}</Subtext>}
                        />
                      </TableCell>
                      <TableCell>{supplierSku}</TableCell>
                      <TableCell width={200}>
                        <TextOverflow selfTooltip={true} lines={1}>{unitNumber}</TextOverflow>
                      </TableCell>
                      {trackSerialColumn && (
                        <TableCell>{serialNumber}</TableCell>
                      )}
                      {trackExpiryColumn && (
                        <TableCell>{expiresAt}</TableCell>
                      )}
                      {trackBatchColumn && (
                        <TableCell style={{ minWidth: 130 }}>
                          {batchNumber}
                        </TableCell>
                      )}
                      <TableCell>{conditionCode}</TableCell>
                      <TableCell>{quantity}</TableCell>
                      <TableCell>{received}</TableCell>
                      <TableCell>{ordered}</TableCell>
                      <TableCell>
                        <CheckboxField name={`lineItems[${index}].serial.isRejected`} />
                      </TableCell>
                      <TableCell align="right" width={140}>
                        <IconButton
                          data-cy={`lineItems[${index}].edit`}
                          onClick={() => handleItemEdit(item)}
                          size="large"
                        >
                          <EditIcon />
                        </IconButton>
                        <DeleteIconButton
                          data-cy={`lineItems[${index}].delete`}
                          onClick={() => fields.remove(index)}
                          disabled={Boolean(guid || !deleteEnabled)}
                        />
                      </TableCell>
                    </NoBorderTableRowStyled>
                  )
                })}
              </TableBody>
            </Table>
          )
        }}
      />
    </TableContainer>
  )
}

LineItems.propTypes = {
  lineItems: PropTypes.array,
  editDimension: PropTypes.bool,
  onEditUnit: PropTypes.func,
  edit: PropTypes.bool,
  isBarcodeMode: PropTypes.bool,
  deleteEnabled: PropTypes.bool
}

export default LineItems
