import React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { path } from 'ramda'
import { useSnackbar } from 'storfox-snackbar'
import { useTranslation } from 'react-i18next'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import { WAREHOUSE_TABS } from '~/constants/tabs'

import ZoneUpdateForm from '../components/ZoneUpdate/ZoneUpdateForm'
import { useZoneDetail, useZoneUpdate } from '../hooks'
import { ZoneCreateSerializer } from '../serializers'

function ZoneUpdateContainer () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { guid } = useParams()
  const snackbar = useSnackbar()

  const zoneDetail = useZoneDetail(guid)
  const zoneUpdate = useZoneUpdate(guid)

  const warehouseGuid = path(['warehouse', 'guid'], zoneDetail.detail)
  const warehouseName = path(['warehouse', 'name'], zoneDetail.detail)

  const message = t('Successfully updated')
  const handleSubmit = formValues =>
    zoneUpdate.update(ZoneCreateSerializer(formValues))
      .then(() => snackbar({ message }))
      .then(() => {
        const detailPath = generatePath(ROUTES.ZONE_DETAIL_PATH, { guid })
        return navigate(detailPath)
      })

  const name = path(['detail', 'name'], zoneDetail)
  const title = zoneDetail.isLoading ? 'Zones' : name

  const breadcrumbs = {
    title: 'Edit',
    params: [
      { guid: warehouseGuid, tab: WAREHOUSE_TABS.ZONE, title: warehouseName },
      { guid, title }
    ]
  }

  return (
    <DashboardLayout
      title={title}
      isLoading={zoneDetail.isLoading || zoneUpdate.isLoading}
      activeNav={NAV.WAREHOUSE}
      breadcrumbs={breadcrumbs}
    >
      <ZoneUpdateForm
        pageTitle={title}
        pageTitleLoading={zoneDetail.isLoading}
        initialValues={zoneDetail.detail}
        onSubmit={handleSubmit}
        isLoading={zoneUpdate.isLoading}
      />
    </DashboardLayout>
  )
}

export default ZoneUpdateContainer
