import React from 'react'
import Typography from '@mui/material/Typography'
import { prop } from 'ramda'
import PropTypes from 'prop-types'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'

import { UploadedFilePlaceholder } from '~/components/FileUpload'

function UploadedFile ({ file, information }) {
  const lotsCount = prop('lotsCount', information)

  return (
    <UploadedFilePlaceholder file={file}>
      <Typography>
        You will be importing:
      </Typography>
      <List>
        <ListItem component={Typography}>
          <strong>{lotsCount} lots</strong>
        </ListItem>
      </List>
    </UploadedFilePlaceholder>
  )
}

export default UploadedFile

UploadedFile.propTypes = {
  file: PropTypes.object,
  information: PropTypes.object
}
