import React from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import { SwitchField } from 'storfox-form-fields'
import PropTypes from 'prop-types'
import { useField } from 'react-final-form'
import { prop } from 'ramda'

import ConstrainedField from '~/components/Fields/ConstrainedField'
import DimensionField from '~/components/Fields/DimensionField'
import TagsField from '~/components/Fields/TagsField'
import TextField from '~/components/Fields/TextField'
import WeightField from '~/components/Fields/WeightField'
import { CardHeader } from '~/components/Cards'
import * as API from '~/constants/api'
import MultiImageUploadField from '~/components/ImageUpload/MultiImageUpload'

import SupplierSearchField from '../../Fields/SupplierSearchField'
import CompanySearchField from '../../Fields/CompanySearchField'
import ProductSearchCreateField from '../../Fields/ProductSearchCreateField'
import ExpiryDateField from '../../Fields/ExpiryDateField'
import ProductTypeField from '../../Fields/ProductTypeField'
import FulfillmentStrategyField from '../../Fields/FulfillmentStrategyField'

function ProductUpdateFormGeneral ({ onCategoryCreate, categoryLoading, onBrandCreate, brandLoading }) {
  const { t } = useTranslation()
  const trackExpiryField = useField('trackExpiryDates')
  const trackExpiry = trackExpiryField.input.value

  const companyField = useField('company')
  const companyFieldValue = companyField.input.value
  const companyId = prop('id', companyFieldValue)

  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12} lg={8}>
        <Box mb={3}>
          <Card>
            <CardHeader title={t('Details')} />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <TextField
                    data-cy="name"
                    name="name"
                    label={t('Name')}
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <ProductTypeField />
                </Grid>
                <Grid item={true} xs={12}>
                  <TextField
                    data-cy="sku"
                    name="sku"
                    label={t('SKU')}
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <TextField
                    data-cy="barcode"
                    name="barcode"
                    label={t('Barcode')}
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <TextField
                    data-cy="description"
                    name="description"
                    label="Description"
                    multiline={true}
                    minRows={4}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Box mb={3}>
          <Card>
            <CardHeader title={t('Images')} />
            <Divider />
            <CardContent>
              <MultiImageUploadField />
            </CardContent>
          </Card>
        </Box>
      </Grid>

      <Grid item={true} xs={12} lg={4}>
        <Box mb={3}>
          <Card>
            <CardHeader title="Company" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <CompanySearchField disabled={true} />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Box mb={3}>
          <Card>
            <CardHeader title="Supply" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <SupplierSearchField params={{ companyId }} />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Box mb={3}>
          <Card>
            <CardHeader title={t('Organization')} />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <ProductSearchCreateField
                    data-cy="category"
                    name="category"
                    label={t('Category')}
                    api={API.CATEGORY_LIST}
                    disabled={categoryLoading}
                    onClick={onCategoryCreate}
                    ListboxProps={{ 'data-cy': 'categoryList' }}
                    params={{ companyId }}
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <ProductSearchCreateField
                    data-cy="brand"
                    name="brand"
                    label={t('Brand')}
                    api={API.BRAND_LIST}
                    disabled={brandLoading}
                    onClick={onBrandCreate}
                    ListboxProps={{ 'data-cy': 'brandList' }}
                    params={{ companyId }}
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <TagsField
                    data-cy="tags"
                    label="Tags"
                    name="tags"
                    placeholder={t('Tags')}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Box mb={3}>
          <Card>
            <CardHeader title={t('Dimensions')} />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={6}>
                  <DimensionField
                    data-cy="height"
                    name="height"
                    label={t('Height')}
                  />
                </Grid>

                <Grid item={true} xs={6}>
                  <DimensionField
                    data-cy="width"
                    name="width"
                    label={t('Width')}
                  />
                </Grid>

                <Grid item={true} xs={6}>
                  <DimensionField
                    data-cy="length"
                    name="length"
                    label={t('Length')}
                  />
                </Grid>

                <Grid item={true} xs={6}>
                  <WeightField
                    data-cy="weight"
                    name="weight"
                    label="Weight"
                  />
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Box mb={3}>
          <Card>
            <CardHeader title={t('Fulfillment')} />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <FulfillmentStrategyField />
                </Grid>

                <Grid item={true} xs={12}>
                  <ConstrainedField
                    name="minExpiryDays"
                    label="Min expiry days"
                    disabled={!trackExpiry}
                    minValue={0}
                    maxValue={3650}
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <FormControl style={{ padding: '0 12px' }}>
                    <FormControlLabel
                      control={<SwitchField data-cy="trackSerialNumbers" name="trackSerialNumbers" />}
                      label={t('Track Serial numbers')}
                    />
                  </FormControl>
                </Grid>

                <Grid item={true} xs={12}>
                  <FormControl style={{ padding: '0 12px' }}>
                    <FormControlLabel
                      control={<SwitchField data-cy="trackBatchNumbers" name="trackBatchNumbers" />}
                      label={t('Track Batch numbers')}
                    />
                  </FormControl>
                </Grid>

                <Grid item={true} xs={12}>
                  <FormControl style={{ padding: '0 12px' }}>
                    <FormControlLabel
                      control={<SwitchField data-cy="trackProductionDates" name="trackProductionDates" />}
                      label={t('Track Production dates')}
                    />
                  </FormControl>
                </Grid>

                <Grid item={true} xs={12}>
                  <ExpiryDateField strategyName="strategy" />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </Grid>
  )
}

ProductUpdateFormGeneral.propTypes = {
  onCategoryCreate: PropTypes.func.isRequired,
  categoryLoading: PropTypes.bool.isRequired,
  onBrandCreate: PropTypes.func.isRequired,
  brandLoading: PropTypes.bool.isRequired
}

export default ProductUpdateFormGeneral
