import React from 'react'
import { Filter } from 'storfox-filter'
import Grid from '@mui/material/Grid'
import { pick, pipe, prop, propOr } from 'ramda'

import { escapeAtob, omitEmptyObject, unescapeBtoa } from '~/utils'
import Accordion from '~/components/Accordion'
import * as API from '~/constants/api'
import MultiSelectSearchField from '~/components/Fields/MultiSelectSearchField'

import ProductTypeFilterField from './ProductTypeFilterField'

import { FILTER_NAME as name } from '../../constants'
import CompanySearchField from '../Fields/CompanySearchField'

const getCompany = pipe(
  propOr({}, 'company'),
  pick(['id', 'name']),
  omitEmptyObject,
  unescapeBtoa
)

export const toParams = values => {
  const types = unescapeBtoa(prop('types', values))
  const categories = unescapeBtoa(prop('categories', values))
  const brands = unescapeBtoa(prop('brands', values))
  const company = getCompany(values)

  return {
    types,
    categories,
    brands,
    company
  }
}

export const toValues = params => {
  const types = escapeAtob(prop('types', params))
  const categories = escapeAtob(prop('categories', params))
  const brands = escapeAtob(prop('brands', params))
  const company = escapeAtob(prop('company', params))

  return {
    types,
    categories,
    brands,
    company
  }
}
export const transformer = { toParams, toValues }

export const productFilterOptions = {
  name,
  transformer
}

function ProductFilterForm (props) {
  return (
    <Filter {...props}>
      <Accordion title="General" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <ProductTypeFilterField />
          </Grid>
          <Grid item={true} xs={12}>
            <MultiSelectSearchField
              data-cy="categories"
              name="categories"
              label="Category"
              api={API.CATEGORY_LIST}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <MultiSelectSearchField
              data-cy="brands"
              name="brands"
              label="Brand"
              api={API.BRAND_LIST}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <CompanySearchField />
          </Grid>
        </Grid>
      </Accordion>
    </Filter>
  )
}

export default ProductFilterForm
