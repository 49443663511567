import { useEffect, useRef } from 'react'

function useDidUpdateEffect (callback, dependencies) {
  const isMounted = useRef(false)

  useEffect(() => {
    if (isMounted.current) {
      callback()
    }

    if (!isMounted.current) {
      isMounted.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies)
}

export default useDidUpdateEffect
