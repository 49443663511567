import React from 'react'
import { useGridApiContext } from '@mui/x-data-grid-pro'
import { useDeepCompareEffect } from 'storfox-api-hooks'
import PropTypes from 'prop-types'

function TableToolbar ({ setTableRef }) {
  const tableRef = useGridApiContext()

  useDeepCompareEffect(() => {
    setTableRef && setTableRef(tableRef)
  }, [setTableRef, tableRef])
  return <div />
}

TableToolbar.propTypes = {
  setTableRef: PropTypes.func
}

export default TableToolbar
