import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import AnnouncementIcon from '@mui/icons-material/Announcement'
import { useField } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { includes, prop, propOr } from 'ramda'
import { styled } from '@mui/material'

import { MARKETPLACE, RETAILER, THREE_PL } from '~/constants/companyTypes'

import ShoppingBagSvg from './shopping-bag.svg'
import MegaphoneSvg from './megaphone.svg'
import WarehouseSvg from './warehouse.svg'

const CardStyled = styled(Card, {
  shouldForwardProp:(propName) => (
    propName !== 'isActive' &&
    propName !== 'isDisabled'
  )
})(({ theme, isActive, isDisabled }) => ({
  minHeight: 100,
  cursor: 'pointer',
  transition: '.25s ease all',
  textAlign: 'center',
  padding: '10px 0',
  '& path': {
    fill: theme.palette.secondary.main
  },
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
    '& path, & *': {
      color: 'white',
      fill: 'white'
    }
  },
  ...(isActive && {
    backgroundColor: theme.palette.secondary.main,
    '& path, & *': {
      color: 'white',
      fill: 'white'
    }
  }),
  ...(isDisabled && {
    filter: 'grayscale(100%)'
  })
}))

const InfoBoxStyled = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  background: '#F0F0F0',
  borderRadius: '8px',
  padding: '12px 10px'
})

export const INFORMATION_CONTENT = {
  [RETAILER]: `You need an
    inventory and fulfillment management
    solution, integrated to one or many sales channels.`,
  [MARKETPLACE]: `You need an e-commerce marketplace
    platform to manage yours as well as your merchant’s inventory,
    fulfillment and product listings.`,
  [THREE_PL]: `You need a warehouse and
     fulfillment management solution to store inventory
     as well as to fulfill orders on behalf of your clients.`
}

function CompanyTypeField ({ name = 'type' }) {
  const { t } = useTranslation()

  const field = useField(name)
  const value = field.input.value

  const contractField = useField('contractInfo')
  const contract = contractField.input.value

  const types = propOr([RETAILER, MARKETPLACE, THREE_PL], 'types', contract)

  const title = prop(value, INFORMATION_CONTENT)

  const onChange = type => {
    if (includes(type, types)) {
      field.input.onChange(type)
    }
  }

  return (
    <>
      <Grid container={true} spacing={1}>
        <Grid lg={4} sx={12} item={true}>
          <CardStyled
            isActive={RETAILER === value}
            isDisabled={!includes(RETAILER, types)}
            variant="elevation"
            data-cy="typeRetailer"
            onClick={() => onChange(RETAILER)}
          >
            <ShoppingBagSvg />
            <Box mt={2}>
              <Typography align="center" color="inherit">Retailer</Typography>
            </Box>
          </CardStyled>
        </Grid>
        <Grid lg={4} xs={12} item={true}>
          <CardStyled
            isActive={MARKETPLACE === value}
            isDisabled={!includes(MARKETPLACE, types)}
            variant="elevation"
            data-cy="typeMarket"
            onClick={() => onChange(MARKETPLACE)}
          >
            <MegaphoneSvg />
            <Box mt={2}>
              <Typography align="center" color="inherit">Marketplace</Typography>
            </Box>
          </CardStyled>
        </Grid>
        <Grid lg={4} xs={12} item={true}>
          <CardStyled
            isActive={THREE_PL === value}
            isDisabled={!includes(THREE_PL, types)}
            variant="elevation"
            data-cy="typeThreePl"
            onClick={() => onChange(THREE_PL)}
          >
            <WarehouseSvg />
            <Box mt={2}>
              <Typography align="center" color="inherit">3PL Company</Typography>
            </Box>
          </CardStyled>
        </Grid>
      </Grid>

      <br />

      <InfoBoxStyled>
        <Box mr={1}>
          <AnnouncementIcon color="secondary" />
        </Box>
        <Box>
          <Typography sx={{ color: 'background.dark' }}>
            {t(title)}
          </Typography>
        </Box>
      </InfoBoxStyled>
    </>
  )
}

CompanyTypeField.propTypes = {
  name: PropTypes.string
}

export default CompanyTypeField
