import { sprintf } from 'sprintf-js'
import { useLimitedCountList, useCreate, useDelete, useDetail, useUpdate } from 'storfox-api-hooks'
import useAllSearchParams from 'storfox-route-hooks/src/useAllSearchParams'
import { pick } from 'ramda'
import { DEFAULT_SEARCH_PARAMS } from 'storfox-route-hooks'

import { orderingToSnackCase } from '~/utils'
import * as API from '~/constants/api'

export const useBrandList = () => {
  const searchParams = useAllSearchParams()
  return useLimitedCountList(API.BRAND_LIST, orderingToSnackCase(pick(DEFAULT_SEARCH_PARAMS, searchParams)))
}

export const useBrandDetail = id => {
  const url = sprintf(API.BRAND_DETAIL, id)
  return useDetail(url)
}

export const useBrandCreate = () => {
  return useCreate(API.BRAND_CREATE)
}

export const useBrandUpdate = id => {
  const url = sprintf(API.BRAND_UPDATE, id)
  return useUpdate(url)
}
export const useBrandsDelete = () => {
  return useDelete(API.BRANDS_DELETE)
}
