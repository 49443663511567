import React, { useState } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import { useRoute } from 'storfox-route-hooks'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'
import { useTranslation } from 'react-i18next'
import { useDeepCompareEffect } from 'storfox-api-hooks'
import { styled } from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList'
import PropTypes from 'prop-types'

const SearchBoxStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  right:0,
  top:0,
  left:0,
  background: theme.palette.background.paper,
  zIndex: 999,
  padding:'0 10px'
}))

const RootStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: theme.spacing(6),
  width: '100%'
}))

function HistoryTableSearch ({ filterOpen }) {
  const { replaceParams, getParams } = useRoute()
  const [value, setValue] = useState('')

  const { t } = useTranslation()

  useDeepCompareEffect(() => {
    const search = getParams().search
    setValue(search)
  }, [])

  const handleSearch = () => {
    replaceParams({ search: encodeURIComponent(value.trim()), page: 1 })
  }

  const handleClickSearch = event => {
    event.preventDefault()
    handleSearch()
  }

  const handlePressEnter = event => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleSearch()
    }
  }
  const handlePressEscape = event => {
    if (event.key === 'Escape') {
      event.preventDefault()
    }
  }

  return (
    <RootStyled>
      <SearchBoxStyled
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <IconButton size="small" onClick={handleClickSearch} >
          <SearchIcon size="small" />
        </IconButton>
        <InputBase
          name="search"
          data-cy="search"
          variant="outlined"
          size="small"
          placeholder={t('Search')}
          value={value}
          onChange={event => setValue(event.target.value)}
          onKeyPress={handlePressEnter}
          onKeyDown={handlePressEscape}
          sx={{ width: '100%' }}
          inputProps={{ 'aria-label': 'search' }}
        />
        {filterOpen && (
          <IconButton
            data-cy="tableFilter"
            title="Filter"
            size="small"
            onClick={filterOpen}
          >
            <FilterListIcon />
          </IconButton>
        )}
      </SearchBoxStyled>
    </RootStyled>
  )
}

export default HistoryTableSearch

HistoryTableSearch.propTypes = {
  filterOpen: PropTypes.func
}
