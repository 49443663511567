import React from 'react'
import { PhoneNumberField as StorfoxPhoneNumberField } from 'storfox-form-fields'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

function PhoneNumberField ({ label, ...props }) {
  const { t } = useTranslation()

  return (
    <StorfoxPhoneNumberField
      label={t(label)}
      {...props}
    />
  )
}

PhoneNumberField.propTypes = {
  label: PropTypes.string
}

export default PhoneNumberField
