import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import { generatePath, useParams } from 'react-router-dom'

import Container, { Content, Header } from '~/components/Container'
import PageTitle from '~/components/PageTitle'
import { EditButton } from '~/components/Buttons'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import LoaderProvider from '~/components/Loader'
import { USER_DETAIL_TABS } from '~/constants/tabs'
import UserHistory from '~/components/History/UserHistory'

import UserDetailTabs from './UserDetailTabs'
import UserDetailGeneral from './UserDetailGeneral'

function UserDetail ({ title, userHistory, isLoading, detail }) {
  const { id, tab } = useParams()

  const editPath = generatePath(ROUTES.USER_UPDATE_PATH, { id })

  return (
    <LoaderProvider isLoading={isLoading}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={title}
            parentTitle={NAV.USERS}
            pageTitleLoading={isLoading}
            rightButton={(
              <EditButton path={editPath} disabled={isLoading} />
            )}
          />
        </Header>

        <Content>
          <UserDetailTabs id={id} />
          {tab === USER_DETAIL_TABS.GENERAL && <UserDetailGeneral user={detail} />}
          {tab === USER_DETAIL_TABS.HISTORY && <UserHistory history={userHistory} />}
          <Box mb={3} />
        </Content>
      </Container>
    </LoaderProvider>
  )
}

UserDetail.propTypes = {
  detail: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  userHistory: PropTypes.object.isRequired,
}

export default UserDetail
