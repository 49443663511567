import { prop } from 'ramda'
import React from 'react'
import { useParams } from 'react-router-dom'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import Title from '~/components/Title'
import * as NAV from '~/constants/nav-titles'
import ErrorLink from '~/components/ErrorLink'
import useMessages from '~/hooks/useMessages'
import { SUPPLIER_RETURN_TABS } from '~/constants/tabs'

import ReturnDetail from '../components/ReturnDetail/ReturnDetail'
import { useReturnDetail, useReturnProcess, useReturnAllocate, useReturnHistory } from '../hooks'
import { ReturnInitUpdateSerializer } from '../serializers'

function ReturnDetailContainer () {
  const { guid, tab } = useParams()
  const snackbar = useSnackbar()
  const messages = useMessages()
  const returnDetail = useReturnDetail(guid)
  const returnProcess = useReturnProcess(guid)
  const returnAllocate = useReturnAllocate(guid)

  const historyAutoSend = Boolean(guid && tab === SUPPLIER_RETURN_TABS.HISTORY)

  const returnHistory = useReturnHistory(guid, historyAutoSend)

  const initialValues = ReturnInitUpdateSerializer(returnDetail.detail)

  const pageLoading = returnDetail.isLoading

  const isLoading = returnDetail.isLoading

  const handleProcess = () =>
    returnProcess.create({ return: { guid } })
      .then(() => returnDetail.getDetail())
      .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
        return Promise.reject(error)
      })

  const handleAllocate = () =>
    returnAllocate.create()
      .then(() => returnDetail.getDetail())
      .then(() => snackbar({ message: messages.ALLOCATE_SUCCESS }))
      .catch(error => {
        snackbar({
          type: ALTER_ERROR,
          message: <ErrorLink error={error} />
        })
        returnDetail.getDetail()
      })

  const number = prop('number', returnDetail.detail)
  const pageTitle = (
    <Title title="Return" number={number} />
  )

  const breadcrumbs = { title: number }

  return (
    <DashboardLayout
      title={number}
      isLoading={pageLoading}
      activeNav={NAV.SUPPLIER_RETURNS}
      breadcrumbs={breadcrumbs}
    >
      <ReturnDetail
        pageTitle={pageTitle}
        pageTitleLoading={returnDetail.isLoading}
        detail={initialValues}
        isLoading={isLoading}
        onProcess={handleProcess}
        onAllocate={handleAllocate}
        history={returnHistory}
      />
    </DashboardLayout>
  )
}

export default ReturnDetailContainer
