import React from 'react'
import { useSnackbar } from 'storfox-snackbar'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { path, pathOr, prop } from 'ramda'
import { useRequest } from 'storfox-api-hooks'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import { useToken } from '~/components/Token'
import { useCompany, useMe, useProfile } from '~/components/Profile'
import { set as setLanguage } from '~/components/Language'
import useMessages from '~/hooks/useMessages'
import { CONTRACT_UPDATE_TABS, OVERVIEW_TABS } from '~/constants/tabs'
import * as API from '~/constants/api'

import ContractUpdateForm from '../../components/Contract/ContractUpdate'
import {
  ContractCreateSerializer,
  ContractDecideSerializer,
  ContractDetailInitSerializer
} from '../../serializers/Contract'
import {
  useContractAccept,
  useContractDetail,
  useContractUpdate,
  useLoginByContract,
  useContractInvoices
} from '../../hooks/Contract'

const ContractUpdateContainer = () => {
  const { id, tab } = useParams()
  const navigate = useNavigate()
  const messages = useMessages()
  const { setToken } = useToken()
  const { getMe } = useMe()
  const { i18n } = useTranslation()
  const { setProfile } = useProfile()
  const { hasBelongCompany, isThreePl } = useCompany()
  const request = useRequest()

  const companyTab = isThreePl ? OVERVIEW_TABS.FULFILLMENT : OVERVIEW_TABS.SALES
  const redirectTo = generatePath(ROUTES.OVERVIEW_PATH, { tab: companyTab })

  const contractDetail = useContractDetail(id)
  const contractUpdate = useContractUpdate(id)
  const contractAccept = useContractAccept()
  const loginByContract = useLoginByContract()

  const snackbar = useSnackbar()
  const clientGuid = path(['detail', 'client', 'guid'], contractDetail)
  const contractGuid = path(['detail', 'guid'], contractDetail)
  const invoiceAutoSend = Boolean(contractGuid && tab === CONTRACT_UPDATE_TABS.INVOICE)

  const invoiceList = useContractInvoices(contractGuid, invoiceAutoSend)

  const handleGenerateInvoice = guid => {
    const url = generatePath(API.CONTRACT_INVOICE_GENERATE, { contractGuid, guid })
    request.post(url).then(() => snackbar({ message: messages.NOTIFICATION_WAIT }))
  }

  const handleGenerateExcel = guid => {
    const url = generatePath(API.TPL_BILLING_EXCEL_GENERATE, { contractGuid, guid })
    request.post(url).then(() => snackbar({ message: messages.NOTIFICATION_WAIT }))
  }

  const handleMarkAsPaid = guid => {
    const url = generatePath(API.TPL_BILLING_INVOICE_MARK_AS_PAID, { contractGuid, guid })
    request.put(url).then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
  }

  const handleSubmit = formValues => {
    return contractUpdate.update(ContractCreateSerializer(formValues))
      .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
      .then(() => navigate(ROUTES.CONTRACT_LIST_PATH))
  }
  const handleDecide = (formValues, decision) => {
    return contractAccept.accept(ContractDecideSerializer(formValues, decision))
      .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
      .then(() => navigate(ROUTES.CONTRACT_LIST_PATH))
  }

  const handleLoginByContract = () =>
    loginByContract.login({ contractGuid, companyGuid: clientGuid })
      .then((result) => {
        const token = prop('token', result)
        setToken(token)
      })
      .then(() => getMe())
      .then(({ result }) => {
        const language = prop('language', result)
        i18n.changeLanguage(language)
          .then(() => setLanguage(language))
        setProfile(result)
      })
      .then(() => snackbar({ message : messages.SWITCH_ACCOUNT_SUCCESS }))
      .then(() => navigate(redirectTo, { replace: true }))

  const detail = contractDetail.detail
  const detailCompanyId = path(['company', 'id'], detail)
  const belongsToCompany = hasBelongCompany(detailCompanyId)

  const title = belongsToCompany
    ? pathOr('Invitation pending', ['client', 'name'], detail)
    : path(['company', 'name'], detail)

  const isLoading = contractDetail.isLoading || contractUpdate.isLoading

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      activeNav={NAV.CONFIGURATION}
      breadcrumbs={breadcrumbs}
    >
      <ContractUpdateForm
        initialValues={ContractDetailInitSerializer(contractDetail.detail)}
        isLoading={isLoading}
        pageTitle={title}
        pageTitleLoading={contractDetail.isLoading}
        onDecide={handleDecide}
        onSubmit={handleSubmit}
        onLoginByContract={handleLoginByContract}
        invoiceList={invoiceList}
        contractGuid={contractGuid}
        onGenerateInvoice={handleGenerateInvoice}
        onGenerateExcel={handleGenerateExcel}
        onMarkAsPaid={handleMarkAsPaid}
      />
    </DashboardLayout>
  )
}

export default ContractUpdateContainer
