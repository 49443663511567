import palette from '~/theme/dark/palette'

export default {
  styleOverrides: {
    icon: {
      color: palette.text.primary
    },
    outlined: {
      border: `1px solid #ced4da`
    }
  }
}
