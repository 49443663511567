import React from 'react'
import PropTypes from 'prop-types'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Add from '@mui/icons-material/Add'
import { FieldArrayWrapper, FloatField } from 'storfox-form-fields'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import { useField } from 'react-final-form'
import { prop } from 'ramda'
import EmailField from 'storfox-form-fields/src/EmailField'

import PhoneNumberField from '~/components/Fields/PhoneNumberField'
import CardHeader from '~/components/Cards/CardHeader'
import TextField from '~/components/Fields/TextField'
import Button from '~/components/Buttons/Button'
import CountryField from '~/components/Fields/CountryField'
import DeleteConfirmButton from '~/components/Buttons/DeleteConfirmButton'

import PrimarySwitchField from '../Fields/PrimarySwitchField'

const initialAddress = {
  firstName: '',
  lastName: '',
  address: '',
  country: '',
  city: ''
}

function DeliveryAddressFields ({ fields, title, onCopy }) {
  const addressesField = useField(fields.name)
  const addresses = addressesField.input.value

  const addressCount = prop('length', fields)

  const onAdd = () => fields.push(initialAddress)
  const onRemove = index => fields.remove(index)
  const lastIndex = fields.length - 1

  const handleAddressChange = addressesField.input.onChange

  const handlePrimaryChange = primaryIndex => {
    const newAddresses = addresses.map((address, index) => {
      return { ...address, isPrimary: index === primaryIndex }
    })

    handleAddressChange(newAddresses)
  }

  return (
    <>
      {fields.map((name, index) => (
        <Box mb={3} key={index}>
          <Card>
            <CardHeader
              sx={{ height: '56px' }}
              title={(
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="h6">{title}</Typography>
                  {onCopy && (
                    <IconButton
                      title="Copy to billing address"
                      size="small"
                      onClick={() => onCopy(index)}
                    >
                      <FileCopyIcon fontSize="inherit" />
                    </IconButton>
                  )}
                </Box>
              )}

            />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12} lg={6}>
                  <TextField
                    data-cy={`${name}.firstName`}
                    name={`${name}.firstName`}
                    label="First name"
                  />
                </Grid>

                <Grid item={true} xs={12} lg={6}>
                  <TextField
                    data-cy={`${name}.lastName`}
                    name={`${name}.lastName`}
                    label="Last name"
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <PhoneNumberField
                    data-cy={`${name}.mobile`}
                    name={`${name}.mobile`}
                    label="Phone number"
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <EmailField
                    data-cy={`${name}.email`}
                    name={`${name}.email`}
                    label="Email"
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <TextField
                    data-cy={`${name}.address`}
                    name={`${name}.address`}
                    label="Address"
                    multiline={true}
                    minRows={3}
                  />
                </Grid>

                <Grid item={true} xs={6}>
                  <TextField
                    data-cy={`${name}.region`}
                    name={`${name}.region`}
                    label="Region/Neighbouring/State"
                  />
                </Grid>

                <Grid item={true} xs={6}>
                  <TextField
                    data-cy={`${name}.city`}
                    name={`${name}.city`}
                    label="City"
                    country={`${name}.country`}
                  />
                </Grid>

                <Grid item={true} xs={6}>
                  <TextField
                    data-cy={`${name}.zipcode`}
                    name={`${name}.zipcode`}
                    label="Zipcode"
                  />
                </Grid>

                <Grid item={true} xs={6}>
                  <CountryField
                    data-cy={`${name}.country`}
                    name={`${name}.country`}
                    label="Country"
                  />
                </Grid>

                <Grid item={true} xs={6}>
                  <FloatField
                    data-cy={`${name}.longitude`}
                    name={`${name}.longitude`}
                    label="Longitude"
                    inputProps={{ decimalScale: 30 }}
                  />
                </Grid>

                <Grid item={true} xs={6}>
                  <FloatField
                    data-cy={`${name}.latitude`}
                    name={`${name}.latitude`}
                    label="Latitude"
                    inputProps={{ decimalScale: 30 }}
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    {addressCount > 1 && <DeleteConfirmButton onClick={() => onRemove(index)} />}

                    {lastIndex === index && (
                      <Button
                        startIcon={<Add />}
                        onClick={onAdd}

                      >
                        Add another address
                      </Button>
                    )}

                    <PrimarySwitchField
                      name={`${name}.isPrimary`}
                      onChange={() => handlePrimaryChange(index)}
                    />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

        </Box>
      ))}
    </>
  )
}

DeliveryAddressFields.propTypes = {
  fields: PropTypes.object,
  title: PropTypes.string,
  onCopy: PropTypes.func
}

export default FieldArrayWrapper(DeliveryAddressFields)
