import React from 'react'

import SelectField from './SelectField'

const options = [
  { value: 'cm', name: 'Centimeter (cm)' },
  { value: 'ft', name: 'Foot (ft)' },
  { value: 'in', name: 'Inch (in)' }
]

function SizeUnitField ({ ...props }) {
  return (
    <SelectField
      data-cy="sizeUnit"
      name="sizeUnit"
      label="Size Unit"
      options={options}
      {...props}
    />
  )
}

export default SizeUnitField
