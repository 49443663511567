import React from 'react'
import PropTypes from 'prop-types'
import { Bar } from 'react-chartjs-2'
import { useTheme } from '@mui/material/styles'
import { prop } from 'ramda'
import Box from '@mui/material/Box'
import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, LinearScale, Title, Tooltip } from 'chart.js'

import { getCurrencySymbol } from '~/utils'
import { useCompany } from '~/components/Profile'

ChartJS.register(BarElement, Title, ArcElement, CategoryScale, Tooltip, LinearScale)

function Chart ({ data: dataProp, labels, ...props }) {
  const theme = useTheme()
  const { company } = useCompany()
  const currency = prop('currency', company)
  const symbol = getCurrencySymbol(currency)

  const data = {
    datasets: [
      {
        backgroundColor: '#656EEA',
        data: dataProp.days,
        barThickness: 20,
        maxBarThickness: 20,
        barPercentage: 0.5,
        categoryPercentage: 0.5
      }
    ],
    labels
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    cornerRadius: 20,
    layout: {
      padding: 0
    },
    plugins: {
      legend: false,
      tooltip: {
        enabled: true,
        mode: 'index',
        intersect: false,
        caretSize: 10,
        padding: {
          x: 20,
          y: 20
        },
        borderWidth: 1,
        borderColor: theme.palette.divider,
        backgroundColor: theme.palette.common.white,
        titleColor: theme.palette.text.primary,
        bodyColor: theme.palette.text.secondary,
        footerColor: theme.palette.text.secondary,
        callbacks: {
          title: () => {},
          label: tooltipItem => {
            return `${symbol} ${tooltipItem.formattedValue}`
          }
        }
      }
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false
        },
        ticks: {
          padding: 20,
          fontColor: theme.palette.text.secondary
        }
      },
      y: {
        grid: {
          borderDash: [2],
          borderDashOffset: [2],
          color: '#BDBDBD',
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: theme.palette.divider
        },
        ticks: {
          padding: 20,
          fontColor: theme.palette.text.secondary,
          beginAtZero: true,
          min: 0,
          maxTicksLimit: 5,
          callback: value => {
            return value > 0 ? value / 1000 + 'K' : value
          }
        }
      }
    },

  }

  return (
    <Box position="relative" {...props}>
      <Bar
        data={data}
        options={options}
      />
    </Box>
  )
}

Chart.propTypes = {
  data: PropTypes.object.isRequired,
  labels: PropTypes.array.isRequired
}

export default Chart
