import React from 'react'
import { useDeepCompareEffect } from 'storfox-api-hooks'
import { useField } from 'react-final-form'
import { prop } from 'ramda'
import PropTypes from 'prop-types'

import MoneyField from '~/components/Fields/MoneyField'

function ShipmentFeeField ({ name, serviceName, currency, ...props }) {
  const field = useField(name)
  const serviceField = useField(serviceName)
  const service = serviceField.input.value

  useDeepCompareEffect(() => {
    const price = prop('price', service)

    if (price && serviceField.meta.dirty) {
      field.input.onChange(price)
    }
  }, [service])

  return (
    <MoneyField
      currency={currency}
      name={name}
      label="Shipment fee"
      {...props}
    />
  )
}

ShipmentFeeField.propTypes = {
  name: PropTypes.string.isRequired,
  serviceName: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
}

export default ShipmentFeeField
