import * as d3 from 'd3'

import { MARGINS, NODE_HEIGHT, NODE_WIDTH } from './constants'
import { getLeftLines, getNodes, getRightLines, getVerticalLines } from './utils'

export const initializeGraph = (list, onUnitClick) => {
  const svgClassName = '.svg'
  const nodeGroupClassName = '.node-group'

  let view = null
  let focus = null
  let svgWidth = null
  let svgHeight = null

  const zoomEvent = d3.zoom()
    .extent([[0, 0], [svgWidth, svgHeight]])
    .scaleExtent([1, 8])
    .on('zoom', zoomHandler)

  const { mainNodes, leftNodes, rightNodes } = getNodes(list)

  const nodes = [...mainNodes, ...leftNodes, ...rightNodes]

  const verticalLines = getVerticalLines(mainNodes)
  const leftLines = getLeftLines(leftNodes, mainNodes)
  const rightLines = getRightLines(rightNodes, mainNodes)

  const svg = d3
    .select('.svg-container')
    .append('svg')
    .attr('class', 'svg')
    .attr('width', '100%')
    .attr('height', '100%')
    .call(zoomEvent)
    .append('g')
    .attr('class', 'unit-group')
    .attr('transform', `translate(${MARGINS.left}, ${MARGINS.top})`)

  svgWidth = d3.select('.svg').node().clientWidth
  svgHeight = d3.select('.svg').node().clientHeight

  const mainLineGroup = d3
    .select('.unit-group')
    .selectAll('.main-line-group')
    .data(verticalLines)
    .join('g')

  const leftLineGroup = d3
    .select('.unit-group')
    .selectAll('.left-line-group')
    .data(leftLines)
    .join('g')

  const rightLineGroup = d3
    .select('.unit-group')
    .selectAll('.right-line-group')
    .data(rightLines)
    .join('g')

  drawLines(mainLineGroup)
  drawLines(leftLineGroup)
  drawLines(rightLineGroup)

  const { nodeElements, nodeGroup } = drawNodes(nodes)

  nodeElements
    .on('click', (event, d) => {
      zoom(event, d)
      onUnitClick(event, d)
      event.stopPropagation()
    })

  initialZoomTo()

  function initialZoomTo () {
    zoomTo([
      svgWidth / 2 - MARGINS.left,
      svgHeight / 2 - MARGINS.top,
      svgWidth
    ])
  }

  function zoomHandler ({ transform }) {
    const translateX = transform.x + MARGINS.left
    const translateY = transform.y + MARGINS.top

    view = [svgWidth / 2 - translateX, svgHeight / 2 - translateY, svgWidth]
    svg.attr('transform', `translate(${translateX}, ${translateY})scale(${transform.k})`)
  }

  function zoomTo (v) {
    const k = svgWidth / v[2]

    view = v

    const translateX = (svgWidth / 2 - v[0]) * k
    const translateY = (svgHeight / 2 - v[1]) * k

    svg.attr('transform', () => {
      return `translate(${translateX}, ${translateY})scale(${k})`
    })
  }

  function zoom (event, d) {
    focus = d

    const nodeOffsetX = NODE_WIDTH / 2
    const nodeOffsetY = NODE_HEIGHT / 2 + 100

    const finalPosition = {
      x: svgWidth / 2 - (focus.position.x + nodeOffsetX + MARGINS.left),
      y: svgHeight / 2 - (focus.position.y + nodeOffsetY + MARGINS.top)
    }
    const targetPosition = [
      focus.position.x + nodeOffsetX,
      focus.position.y + nodeOffsetY,
      svgWidth
    ]
    svg.transition()
      .duration(700)
      .tween('zoom', () => {
        const i = d3.interpolateZoom(view, targetPosition)
        return t => {
          return zoomTo(i(t))
        }
      })
      .on('end', () => d3.select('.svg')
        .call(
          zoomEvent.transform,
          d3.zoomIdentity
            .translate(0, 0)
            .scale(1)
            .translate(finalPosition.x, finalPosition.y)
        ))
  }

  return { svg, svgClassName, nodeGroup, nodeGroupClassName, nodes }
}

function drawNodes (nodeData) {
  const nodeGroup = d3
    .select('.unit-group')
    .selectAll('.node-group')
    .data(nodeData)
    .join('g')
    .attr('class', 'node-group')

  const nodeElements = nodeGroup
    .append('rect')
    .attr('class', d => {
      return d.isActive ? 'node node-active' : 'node'
    })
    .attr('rx', 10)
    .attr('ry', 10)
    .attr('width', NODE_WIDTH)
    .attr('height', NODE_HEIGHT)
    .style('filter', 'url(#drop-shadow)')
    .attr('transform', function (d) {
      return `translate(${d.position.x}, ${d.position.y})`
    })

  return { nodeGroup, nodeElements }
}

function drawLines (lineGroup) {
  return lineGroup
    .append('line')
    .attr('class', 'line')
    .attr('marker-end', 'url(#arrowhead)')
    .attr('x1', function (d) {
      return d.position1.x
    })
    .attr('y1', function (d) {
      return d.position1.y
    })
    .attr('x2', function (d) {
      return d.position2.x
    })
    .attr('y2', function (d) {
      return d.position2.y
    })
}

export const removeGraph = () => {
  d3.select('.svg')
    .remove()
}
