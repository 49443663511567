import React from 'react'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { is, isEmpty, isNil } from 'ramda'
import PropTypes from 'prop-types'

function Text ({ children, fullWidthSkeleton, isLoading, ...props }) {
  const { t } = useTranslation()

  const emptyChild = isEmpty(children) || isNil(children)

  const component = is(String, children) ? 'p' : 'div'
  const content = emptyChild ? t('Not provided') : children

  const skeletonWidth = fullWidthSkeleton ? '100%' : 150

  return (
    <>
      {isLoading
        ? <Skeleton variant="text" width={skeletonWidth} height={25} />
        : (
          <Typography
            variant="subtitle1"
            component={component}
            sx={{ opacity: emptyChild ? 0.2 : 1 }}
            {...props}
          >
            {content}
          </Typography>
        )}
    </>
  )
}

Text.propTypes = {
  fullWidthSkeleton: PropTypes.bool,
  children: PropTypes.any,
  isLoading: PropTypes.bool
}

export default Text
