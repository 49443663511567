import React from 'react'
import TableCell from '@mui/material/TableCell'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import TableRow from '@mui/material/TableRow'
import PropTypes from 'prop-types'

function ExpandableTableRow ({ children, open, onExpandToggle, disabled }) {
  return (
    <TableRow>
      {children}
      <TableCell align="center">
        <IconButton aria-label="expand row" size="small" onClick={onExpandToggle} disabled={disabled}>
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

ExpandableTableRow.propTypes = {
  children: PropTypes.any.isRequired,
  onExpandToggle: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  disabled: PropTypes.bool
}

export default ExpandableTableRow
