import React from 'react'
import PropTypes from 'prop-types'

import PageTitle from '~/components/PageTitle'
import { withForm } from '~/components/Form'
import * as NAV from '~/constants/nav-titles'
import FluidContainer, { FluidContent, FluidHeader } from '~/components/FluidContainer'
import DiscardButton from '~/components/Buttons/DiscardButton'
import SaveButton from '~/components/Buttons/SaveButton'

import LotSheetScanGeneral from './LotSheetScanGeneral'

function LotSheetScanForm (props) {
  const { pageTitle, form, isLoading, handleSave } = props

  const { handleSubmit, initialValues } = form

  return (
    <form onSubmit={handleSubmit}>
      <FluidContainer>
        <FluidHeader>
          <PageTitle
            parentTitle={NAV.LOT_SHEETS}
            pageTitle={pageTitle}
            pageTitleLoading={isLoading}
            rightButton={(
              <>
                <DiscardButton disabled={isLoading} />
                <SaveButton disabled={isLoading} />
              </>
            )}
          />
        </FluidHeader>
        <FluidContent>
          <LotSheetScanGeneral
            isLoading={isLoading}
            detail={initialValues}
            handleSave={handleSave}
          />
        </FluidContent>
      </FluidContainer>
    </form>
  )
}

LotSheetScanForm.propTypes = {
  form: PropTypes.object.isRequired,
  pageTitle: PropTypes.object,
  isLoading: PropTypes.bool,
  handleSave: PropTypes.func,
}

export default withForm(LotSheetScanForm)
