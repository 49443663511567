import React, { useRef } from 'react'
import ButtonGroup from '@mui/material/ButtonGroup'
import { Button } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { defaultTo, find, pipe, prop, propEq, propOr } from 'ramda'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'

import { useTableSelects } from '~/components/TableValues/hooks'
import Tooltip from '~/components/HtmlTooltip'

import useTableBulkActions from './useTableBulkActions'
import TableSplitPopper from './TableSplitPopper'

function TableSplitButton (props) {
  const {
    name,
    options,
    loading
  } = props

  const { selects } = useTableSelects()
  const disabled = !selects.length

  const anchorRef = useRef(null)

  const {
    open,
    selected,
    handleClose,
    handleToggle,
    handleMenuItemClick
  } = useTableBulkActions(anchorRef, name, options)

  const selectedButton = pipe(
    find(propEq('label', selected)),
    defaultTo(prop(0, options))
  )(options)

  const selectedDisabled = prop('disabled', selectedButton)
  const selectedTooltip = propOr('', 'tooltip', selectedButton)
  const selectedLabel = prop('label', selectedButton)

  return (
    <Grid alignItems="center">
      <Grid item={true} xs={12}>
        <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
          <Button
            disabled={disabled || selectedDisabled || loading}
            onClick={(event) => handleMenuItemClick(event, selectedButton)}
          >
            {disabled && (
              <Tooltip title={selectedDisabled ? selectedTooltip : 'Select at least one row.'}>
                Bulk actions
              </Tooltip>
            )}
            {!disabled && (
              selectedLabel
            )}
          </Button>
          <Button
            size="small"
            aria-haspopup="menu"
            onClick={handleToggle}
            style={{ padding: 0 }}
            data-cy="bulk-action-button-menu"
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>

        <TableSplitPopper
          open={open}
          options={options}
          anchorRef={anchorRef}
          onClose={handleClose}
          disabled={disabled}
          selected={selected}
          onMenuItemClick={handleMenuItemClick}
          loading={loading}
        />

      </Grid>
    </Grid>
  )
}

TableSplitButton.propTypes = {
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  loading: PropTypes.bool
}

export default TableSplitButton
