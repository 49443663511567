import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import UnitListContainer from './containers/UnitListContainer'
import UnitHistoryContainer from './containers/UnitHistoryContainer'

export default () => {
  return [
    {
      layout: AuthLayout,
      path: ROUTES.UNIT_LIST_PATH,
      component: UnitListContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.UNIT_HISTORY_PATH,
      component: UnitHistoryContainer
    }
  ]
}
