import React from 'react'
import { prop } from 'ramda'
import PropTypes from 'prop-types'

import { unescapeBtoa } from '~/utils'
import { ShipmentStatus } from '~/components/Statuses'
import StatusGrid, { StatusGridItem, StatusGridLink, StatusGridPopover } from '~/components/StatusGrid'
import * as ROUTES from '~/constants/routes'
import RefundStatus from '~/components/Statuses/RefundStatus'
import ReturnStatus from '~/components/Statuses/ReturnStatus'
import ReceiveStatus from '~/components/Statuses/ReceiveStatus'

function ReturnStatuses ({ isLoading, detail }) {
  const returnStatus = prop('status', detail)
  const receiveStatus = prop('receivingStatus', detail)
  const shipmentStatus = prop('shipmentStatus', detail)
  const refundStatus = prop('refundStatus', detail)

  const id = prop('id', detail)
  const number = prop('number', detail)

  const order = prop('order', detail)
  const orderId = prop('id', order)
  const orderNumber = prop('number', order)

  const receiveObject = unescapeBtoa([{ id, number }])
  const orderObject = unescapeBtoa([{ id: orderId, number: orderNumber }])
  const objectTypeForReceive = unescapeBtoa([{ value: 'Returns', name: 'Return' }])

  const receiveListLink = `${ROUTES.RECEIVE_LIST_PATH}?objectTypes=${objectTypeForReceive}&objects=${receiveObject}`
  const shipmentListLink = `${ROUTES.SHIPMENT_LIST_PATH}?objects=${orderObject}`

  const shipmentPopover = (
    <StatusGridPopover title="Shipment">
      <StatusGridLink href={shipmentListLink}>
        Shipment
      </StatusGridLink>
    </StatusGridPopover>
  )

  const receivePopover = (
    <StatusGridPopover title="Receive">
      <StatusGridLink href={receiveListLink}>
        Receive
      </StatusGridLink>
    </StatusGridPopover>
  )

  return (
    <StatusGrid>
      <StatusGridItem title="Return status" isLoading={isLoading}>
        {returnStatus && <ReturnStatus value={returnStatus} />}
      </StatusGridItem>
      <StatusGridItem title={shipmentPopover} isLoading={isLoading}>
        {shipmentStatus && <ShipmentStatus value={shipmentStatus} />}
      </StatusGridItem>
      <StatusGridItem title={receivePopover} isLoading={isLoading}>
        {receiveStatus && <ReceiveStatus value={receiveStatus} />}
      </StatusGridItem>
      <StatusGridItem title="Refund status" isLoading={isLoading}>
        {refundStatus && <RefundStatus value={refundStatus} />}
      </StatusGridItem>
    </StatusGrid>
  )
}

ReturnStatuses.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  detail: PropTypes.object.isRequired
}

export default ReturnStatuses
