import React from 'react'
import { path, prop, propOr } from 'ramda'
import { useAllSearchParams } from 'storfox-route-hooks'
import { useFilter, useOrdering } from 'storfox-filter'
import { generatePath, useNavigate } from 'react-router-dom'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import useDialog from '~/hooks/useDialog'
import * as ROUTES from '~/constants/routes'

import { usePutawayList, useWarehouseList } from '../hooks'
import PutawayWarehouseDialog from '../components/PutawayList/PutawayWarehouseDialog'
import PutawayTable, {
  PutawayFilterForm,
  putawayFilterOptions,
  PutawayOrderingForm,
  putawayOrderingOptions
} from '../components/PutawayList'

function PutawayListContainer () {
  const allSearchParams = useAllSearchParams()
  const navigate = useNavigate()
  const { handleClose, handleOpen, open } = useDialog()

  const putawayList = usePutawayList()
  const filter = useFilter(putawayFilterOptions)
  const ordering = useOrdering(putawayOrderingOptions)
  const warehouseList = useWarehouseList()

  const { status } = allSearchParams

  const isLoading = prop('isLoading', putawayList)

  const breadcrumbs = { title: NAV.PUTAWAY }

  const handleNewCreate = () => {
    const warehousesCount = propOr(0, 'count', warehouseList)
    if (warehousesCount > 1) {
      handleOpen()
    } else {
      const warehouseGuid = path(['results', 0, 'guid'], warehouseList)
      const updatePath = generatePath(ROUTES.V2_PUTAWAY_CREATE_PATH, { guid: warehouseGuid })
      navigate(updatePath)
    }
  }
  return (
    <DashboardLayout
      isLoading={isLoading}
      title={NAV.PUTAWAY}
      activeNav={NAV.V2_PUTAWAY}
      breadcrumbs={breadcrumbs}
    >
      <PutawayFilterForm {...filter} />
      <PutawayOrderingForm {...ordering} />

      <PutawayTable
        status={status}
        list={putawayList}
        filter={filter}
        ordering={ordering}
        onListRefetch={putawayList.getListByParams}
        onNewCreate={handleNewCreate}
      />
      <PutawayWarehouseDialog
        onClose={handleClose}
        open={open}
        warehouseList={warehouseList.results}
      />
    </DashboardLayout>
  )
}

export default PutawayListContainer
