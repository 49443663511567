import React from 'react'

import SelectField from '~/components/Fields/SelectField'

const OPTIONS = [
  { value: 'accepted', name: 'Accepted' },
  { value: 'pending', name: 'Pending' },
  { value: 'rejected', name: 'Rejected' }
]

function InvitationStatusField (props) {
  return (
    <SelectField
      name="invitationStatus"
      label="Invitation Status"
      options={OPTIONS}
      {...props}
    />
  )
}

export default InvitationStatusField
