import equal from 'fast-deep-equal'
import { filter, pipe, prop, propEq, sortBy } from 'ramda'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'
import React from 'react'

import ErrorLink from '~/components/ErrorLink'

import useFilterCreate from './useFilterCreate'
import useFilterUpdate from './useFilterUpdate'
import useFilterRemove from './useFilterRemove'
import useFilterList from './useFilterList'
import { useStorage } from './useStorage'
import useOrderingBackend from './useOrderingBackend'

import { FILTER_KEY } from '../constants'
import { useFilterItems } from '../components/FilterProvider'

const getCurrentFilter = (value, items) => {
  const data = filter(item => equal(item.values, value), items)
  if (data.length) {
    return data[0]
  }

  return {}
}

const getFilterItems = (name, items) => pipe(
  filter(propEq('group', name)),
  sortBy(prop('position'))
)(items)

function useFilterBackend ({ name, values }) {
  const { items, setItems } = useFilterItems()
  const ordering = useOrderingBackend({ name })

  const snackbar = useSnackbar()
  const filterItems = getFilterItems(name, items)

  const current = getCurrentFilter(values, filterItems)
  const id = prop('id', current)

  const filterList = useFilterList()

  const create = useFilterCreate()
  const update = useFilterUpdate(id)
  const filterRemove = useFilterRemove(id)
  const filterStorage = useStorage(FILTER_KEY)

  const isLoading = (
    create.isLoading ||
    update.isLoading ||
    filterRemove.isLoading
  )

  const onCreate = values => {
    return create.create({ ...values, group: name })
      .then(() => filterList.getList())
      .then(data => {
        setItems(data.results)
        return data
      })
      .then(data => filterStorage.set(data.results))
      .then(() => ordering.update())
      .then(() => snackbar({ message:'Successfully created' }))
  }

  const onUpdate = values => {
    return update.update(values)
      .then(() => filterList.getList())
      .then(data => {
        setItems(data.results)
        return data
      })
      .then(data => filterStorage.set(data.results))
      .then(() => ordering.update())
      .then(() => snackbar({ message:'Successfully updated' }))
  }

  const onSave = values => {
    if (id) {
      return onUpdate(values)
    }

    return onCreate(values)
  }

  const onDelete = () => {
    return filterRemove.delete()
      .then(() => filterList.getList())
      .then(data => {
        setItems(data.results)
        return data
      })
      .then(data => filterStorage.set(data.results))
      .then(() => ordering.update())
      .then(() => snackbar({ message: 'Successfully deleted' }))
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })
  }

  return {
    current,
    isLoading,
    items: filterItems,
    onSave,
    onDelete
  }
}

export default useFilterBackend
