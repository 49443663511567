import React from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { useSnackbar } from 'storfox-snackbar'
import { useTranslation } from 'react-i18next'
import { pathOr } from 'ramda'

import { DashboardLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'

import { ACTIVE } from '../constants'
import { useCategoryCreate } from '../hooks'
import { CategoryCreateSerializer } from '../serializers'
import { CategoryCreateForm } from '../components/CategoryCreate'

function CategoryCreateContainer () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const categoryCreate = useCategoryCreate()

  const message = t('Successfully created')

  const handleSubmit = formValues =>
    categoryCreate.create(CategoryCreateSerializer(formValues))
      .then((response) => {
        const id = pathOr(0, ['result', 'id'], response)
        const detailUrl = generatePath(ROUTES.CATEGORY_DETAIL_PATH, { id })
        navigate(detailUrl)
      })
      .then(() => snackbar({ message }))

  const title = t('New category')

  const breadcrumbs = { title }
  return (
    <DashboardLayout
      title={title}
      activeNav={NAV.CATEGORIES}
      breadcrumbs={breadcrumbs}
    >
      <CategoryCreateForm
        title={title}
        onSubmit={handleSubmit}
        isLoading={categoryCreate.isLoading}
        initialValues={{ status: ACTIVE }}
      />
    </DashboardLayout>
  )
}

export default CategoryCreateContainer
