import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'

const RootStyled = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 1),
  display: 'flex',
  alignItems: 'center'
}))

function TableHeaderRight ({ children }) {
  return (
    <RootStyled>
      {children}
    </RootStyled>
  )
}

TableHeaderRight.propTypes = {
  children: PropTypes.node
}

export default TableHeaderRight
