export const PICKING = 'picking'
export const PACKING = 'packing'
export const SHIPPING = 'shipping'
export const OVERFLOW = 'overflow'
export const STAGING = 'staging'
export const SORTING = 'sorting'
export const RECEIVING = 'receiving'
export const RETURNS = 'returns'
export const QUARANTINE = 'quarantine'
export const RESERVE = 'reserve'

export const LOCATION_TYPES = [
  { value: PICKING, name: 'Picking' },
  { value: PACKING, name: 'Packing' },
  { value: SHIPPING, name: 'Shipping' },
  { value: OVERFLOW, name: 'Overflow' },
  { value: STAGING, name: 'Staging' },
  { value: SORTING, name: 'Sorting' },
  { value: RECEIVING, name: 'Receiving' },
  { value: RETURNS, name: 'Returns' },
  { value: QUARANTINE, name: 'Quarantine' },
  { value: RESERVE, name: 'Reserve' },
]
