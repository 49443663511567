import React from 'react'

import SearchField from '~/components/Fields/SearchField'
import * as API from '~/constants/api'

function CompanySearchField (props) {
  return (
    <SearchField
      api={API.ORDER_COMPANIES}
      label="Company"
      name="company"
      {...props}
    />
  )
}

export default CompanySearchField
