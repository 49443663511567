import React from 'react'
import Card from '@mui/material/Card'
import { useTranslation } from 'react-i18next'
import CardContent from '@mui/material/CardContent'
import { useField } from 'react-final-form'
import { prop } from 'ramda'
import Divider from '@mui/material/Divider'
import { generatePath, Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'

import { CardHeader } from '~/components/Cards'
import TableDateFormat from '~/components/TableDateFormat'
import * as ROUTES from '~/constants/routes'

import { RETURN_TYPE } from '../constants'

function OrderCard () {
  const { t } = useTranslation()
  const orderField = useField('order')
  const orderValue = orderField.input.value
  const guid = prop('guid', orderValue) || 'unknown'

  const number = prop('number', orderValue)
  const type = prop('returnType', orderValue)
  const method = prop('paymentMethod', orderValue)
  const returnTo = prop('returnTo', orderValue)
  const orderDate = prop('createdAt', orderValue)
  const path = generatePath(ROUTES.SALE_ORDER_UPDATE_PATH, { guid, tab: 'detail' })

  return (
    <Card>
      <CardHeader title={t('Order')} />
      <Divider />
      <CardContent style={{ padding: 0 }}>
        {number && (
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell>
                  {t('Sale order')}:
                </TableCell>
                <TableCell>
                  <Link
                    component={RouterLink}
                    to={path}
                    underline="always"
                  >
                    {number}
                  </Link>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  {t('Return type')}:
                </TableCell>
                <TableCell>
                  {RETURN_TYPE[type]}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  {t('Payment type')}:
                </TableCell>
                <TableCell>
                  {method}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  {t('Return to')}:
                </TableCell>
                <TableCell>
                  {returnTo}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  {t('Order date')}:
                </TableCell>
                <TableCell>
                  <TableDateFormat date={orderDate} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
      </CardContent>
    </Card>
  )
}

export default OrderCard
