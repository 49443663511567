import React from 'react'
import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'

import LinkButton from '~/components/Buttons/LinkButton'
import * as ROUTES from '~/constants/routes'

import ActionButtons from './ActionButtons'

import { IFrameStyled } from '../styled/IFrame.styled'

function UserStep ({ onSkip }) {
  return (
    <Box>
      <Typography variant="h5" gutterBottom={true}>
        Add your first user
      </Typography>
      <Box>
        <IFrameStyled
          src="https://www.youtube.com/embed/YKFAzTUnm4U"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen={true}
        />
      </Box>
      <ActionButtons onSkip={onSkip}>
        <LinkButton href={ROUTES.USER_CREATE_PATH} target="_blank">Add</LinkButton>
      </ActionButtons>
    </Box>
  )
}

UserStep.propTypes = {
  onSkip: PropTypes.func.isRequired
}

export default UserStep
