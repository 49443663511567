import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useField } from 'react-final-form'
import { path } from 'ramda'
import { useValueChanged } from 'storfox-tools'

import * as API from '~/constants/api'
import SearchField from '~/components/Fields/SearchField'

const getOptionValue = value => {
  const { id, guid, name, label } = value
  return { id, guid, name, label }
}

function ZoneField ({ area, name, ...props }) {
  const areaField = useField(area)
  const zoneField = useField(name)

  const areaGuid = path(['input', 'value', 'guid'], areaField)

  const onChange = useCallback(
    path(['input', 'onChange'], zoneField),
    []
  )
  useValueChanged(() => {
    onChange(undefined)
  }, areaGuid)

  return (
    <SearchField
      name={name}
      disabled={!areaGuid}
      getOptionValue={getOptionValue}
      api={API.ZONE_LIST}
      params={{ areaGuid }}
      ListboxProps={{ 'data-cy': 'zoneList' }}
      {...props}
    />
  )
}

ZoneField.propTypes = {
  name: PropTypes.string.isRequired,
  area: PropTypes.string.isRequired
}

export default ZoneField
