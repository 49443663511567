import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import EliteIntegrationUpdateContainer from './EliteIntegrationUpdateContainer'
import EliteIntegrationCreateContainer from './EliteIntegrationCreateContainer'

export default () => {
  return [
    {
      layout: AuthLayout,
      path: ROUTES.ELITE_INTEGRATION_CREATE_PATH,
      component: EliteIntegrationCreateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.ELITE_INTEGRATION_UPDATE_PATH,
      component: EliteIntegrationUpdateContainer
    }
  ]
}
