import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import { useField } from 'react-final-form'

import { CardHeader } from '~/components/Cards'
import TextField from '~/components/Fields/TextField'
import { Button } from '~/components/Buttons'
import { withForm } from '~/components/Form'
import { CODE_TYPE } from '~/constants/barcode'

import CodeVisualized from './CodeVisualized'
import CodeEmpty from './CodeEmpty'

import BarcodeSelectField from '../Fields/BarcodeSelectField'
import BarcodeTypeSelectField from '../Fields/BarcodeTypeSelectField'
import FontSizeSelectField from '../Fields/FontSizeSelectField'

function CodeForm ({ form, items, type, quantity, fontSize, componentRef, barcodeType }) {
  const typeField = useField('type')
  const typeValue = typeField.input.value

  const { handleSubmit } = form

  return (
    <form onSubmit={handleSubmit}>
      <Grid container={true} spacing={3}>
        <Grid item={true} xs={12} lg={6}>
          <Box mb={3}>
            <Card>
              <CardHeader title="Information" />
              <Divider />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={12}>
                    <TextField
                      data-cy="sku"
                      name="barcodes"
                      label="Barcodes"
                      multiline={true}
                      minRows={4}
                    />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <TextField
                      data-cy="quantity"
                      name="quantity"
                      label="Quantity"
                    />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <BarcodeSelectField />
                  </Grid>

                  {typeValue === CODE_TYPE.BARCODE && (
                    <Grid item={true} xs={12}>
                      <BarcodeTypeSelectField />
                    </Grid>
                  )}

                  <Grid item={true} xs={12}>
                    <FontSizeSelectField />
                  </Grid>

                  <Grid container={true} item={true} xs={12} justifyContent="flex-end">
                    <Button
                      type="submit"
                      variant="contained"

                    >
                      Generate Code
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Grid>
        <Grid item={true} xs={6}>
          <Card>
            <CardHeader title="Barcode visualization" />
            <Divider />

            <CardContent>
              {items.length
                ? (
                  <CodeVisualized
                    type={type}
                    items={items}
                    quantity={quantity}
                    fontSize={fontSize}
                    componentRef={componentRef}
                    barcodeType={barcodeType}
                  />
                )
                : <CodeEmpty type={typeValue} />}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </form>
  )
}

CodeForm.propTypes = {
  form: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  fontSize: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  quantity: PropTypes.string.isRequired,
  componentRef: PropTypes.any.isRequired,
  barcodeType: PropTypes.string.isRequired
}

export default withForm(CodeForm)
