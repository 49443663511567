import React from 'react'

import StorfoxAllocationStatusField, { OPTIONS as BASE_OPTIONS } from '~/components/Fields/AllocationStatusField'

const OPTIONS = [{ value: '', name: 'All' }, ...BASE_OPTIONS]

function AllocationStatusField (props) {
  return (
    <StorfoxAllocationStatusField
      name="allocationStatus"
      label="Allocation status"
      options={OPTIONS}
      {...props}
    />
  )
}

export default AllocationStatusField
