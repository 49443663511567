import React from 'react'
import Avatar, { avatarClasses } from '@mui/material/Avatar'
import { createSmallImageUrl } from 'storfox-image-upload'
import PropTypes from 'prop-types'
import { styled } from '@mui/material'

import blank from '~/assets/images/avatar/profile.png'

const AvatarStyled = styled(Avatar)(({ size }) => ({
  ...(size === TYPES.md && {
    width: 30,
    height: 30
  }),
  ...(size === TYPES.lg && {
    width: 44,
    height: 44
  }),
  [`& .${avatarClasses}`]: {
    objectFit: 'cover'
  }
}))

const TYPES = {
  md: 'md',
  lg: 'lg'
}

function ProfileAvatar ({ src, alt, type, ...props }) {
  return (
    <AvatarStyled
      variant="circular"
      src={src ? createSmallImageUrl(src) : blank}
      alt="Person"
      size={type}
      {...props}
    />
  )
}

ProfileAvatar.defaultProps = {
  type: TYPES.md
}

ProfileAvatar.propTypes = {
  alt: PropTypes.string.isRequired,
  src: PropTypes.string,
  type: PropTypes.oneOf(['md', 'lg']),
}

export default ProfileAvatar
