import React from 'react'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import ErrorLink from '~/components/ErrorLink'

import { useStorageReportDetail } from '../../hooks/StorageReport'
import StorageReportDetail from '../../components/StorageReport/StorageReportDetail'
import { StorageDetailSerializer } from '../../serializers/StorageReport'

function StorageReportDetailContainer () {
  const snackbar = useSnackbar()
  const storageReportDetail = useStorageReportDetail()

  const breadcrumbs = { title: NAV.STORAGE_REPORT }

  const handleDetailLoad = params =>
    storageReportDetail.getDetail(StorageDetailSerializer(params))
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  return (
    <DashboardLayout
      title={NAV.STORAGE_REPORT}
      activeNav={NAV.REPORTS}
      breadcrumbs={breadcrumbs}
    >
      <StorageReportDetail
        onDetailLoad={handleDetailLoad}
        detail={storageReportDetail.detail}
        isLoading={storageReportDetail.isLoading}
      />
    </DashboardLayout>
  )
}

export default StorageReportDetailContainer
