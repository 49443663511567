import { useField } from 'react-final-form'
import { equals, find, path, pathEq, prop, propEq } from 'ramda'

function useActiveLineItem () {
  const activeItemField = useField('activeLineItem')

  const clearActiveLineItemField = () => activeItemOnChange(null)
  const activeLineItem = activeItemField.input.value || null
  const activeItemOnChange = activeItemField.input.onChange
  const activeLineItemGuid = prop('guid', activeLineItem)

  const activeItemQuantity = prop('quantity', activeLineItem)
  const activeItemTotalQuantity = prop('totalQuantity', activeLineItem)
  const activeItemTrackSerial = path(['variant', 'trackSerial'], activeLineItem)
  const activeItemUnitNumber = prop('unitNumber', activeLineItem)
  const activeItemSerialNumber = prop('serialNumber', activeLineItem)
  const isActiveItemFullyScanned = equals(activeItemQuantity, activeItemTotalQuantity)

  const isActiveItemWithSerialNumber = activeItemTrackSerial && activeItemSerialNumber

  const getNewActiveLineItem = unit => {
    const guid = prop('guid', unit)
    const unitNumber = prop('unitNumber', unit)
    const totalQuantity = prop('quantity', unit)
    const variant = prop('variant', unit)
    const container = prop('container', unit)

    return { guid, unitNumber, variant, container, totalQuantity, quantity: 1, isOutsideContainer: true }
  }

  const getIsUnitSerialFoundOutsideContainer = sourceUnits => {
    return find(unit => (
      propEq('serialNumber', activeItemSerialNumber, unit) &&
      propEq('unitNumber', activeItemUnitNumber, unit) &&
      !prop('container', unit)
    ), sourceUnits)
  }

  const getIsUnitSerialFoundInsideContainer = (sourceUnits, activeContainerNumber) => {
    return find(unit => (
      propEq('serialNumber', activeItemSerialNumber, unit) &&
      propEq('unitNumber', activeItemUnitNumber, unit) &&
      pathEq(['container', 'number'], activeContainerNumber, unit)
    ), sourceUnits)
  }

  return {
    activeLineItem,
    activeLineItemGuid,
    activeItemOnChange,
    isActiveItemWithSerialNumber,
    isActiveItemFullyScanned,
    clearActiveLineItemField,
    getNewActiveLineItem,
    getIsUnitSerialFoundInsideContainer,
    getIsUnitSerialFoundOutsideContainer,
  }
}

export default useActiveLineItem
