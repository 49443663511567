import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { map, path, prop, propOr } from 'ramda'
import { useTranslation } from 'react-i18next'
import HistoryIcon from '@mui/icons-material/History'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import { useNavigate } from 'react-router-dom'

import Avatar from '~/components/Avatar/Avatar'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import VerticalAlignment from '~/components/VerticalAlignment'
import TableDateFormat from '~/components/TableDateFormat'
import Subtext from '~/components/Subtext'
import TextOverflow from '~/components/TextOverflow'
import { Table, TableHeader } from '~/components/Table'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import TableLink from '~/components/Link'
import AvailabilityStatus from '~/components/Statuses/AvailabilityStatus'
import { capitalize } from '~/utils'
import Button from '~/components/Buttons/Button'

export const UNIT_COLUMNS = [
  {
    width: 75,
    headerName: 'Image',
    field: 'image',
    sortable: false,
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        <Avatar alt={row.alt} src={value} />
      </TableLink>
    )
  },
  {
    width: 300,
    headerName: 'Variant',
    field: 'variant',
    valueGetter: ({ value }) => value.name,
    renderCell: ({ value, row }) => (
      <VerticalAlignment
        primary={(
          <TextOverflow selfTooltip={true} lines={1}>
            {value}
          </TextOverflow>
        )}
        secondary={(
          <Subtext lines={1}>{row.variant.sku}</Subtext>
        )}
      />
    )
  },
  {
    width: 200,
    headerName: 'Unit number',
    field: 'unitNumber',
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        <TextOverflow lines={1} selfTooltip={true}>
          {value}
        </TextOverflow>
      </TableLink>
    )
  },
  {
    width: 100,
    headerName: 'Available',
    field: 'available',
    valueFormatter: ({ value }) => value ? 'Yes' : 'No',
    renderCell: ({ value }) => (
      <AvailabilityStatus value={value} />
    )
  },
  {
    width: 150,
    headerName: 'Quantity',
    field: 'quantity',
    headerAlign: 'center',
    align: 'center'
  },
  {
    width: 150,
    headerName: 'Total weight',
    field: 'totalWeight',
  },
  {
    width: 150,
    headerName: 'Total Pieces',
    field: 'totalPieces',
  },
  {
    width: 150,
    headerName: 'Total volume',
    field: 'totalVolume'
  },
  {
    width: 100,
    headerName: 'UoM',
    field: 'unitOfMeasurement',
    sortable: false
  },
  {
    width: 200,
    headerName: 'Reserved for',
    field: 'reservedFor',
    sortable: false
  },
  {
    width: 200,
    headerName: 'Serial number',
    field: 'serialNumber',
    sortable: false
  },
  {
    width: 200,
    headerName: 'Batch number',
    field: 'batchNumber',
    sortable: false
  },
  {
    width: 200,
    headerName: 'Production date',
    field: 'productionDate',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  },
  {
    width: 200,
    headerName: 'Condition',
    field: 'conditionCode'
  },
  {
    width: 200,
    headerName: 'Container',
    field: 'container'
  },
  {
    width: 200,
    headerName: 'Expiry date',
    field: 'expiryDate',
    renderCell: ({ value }) => (
      <TableDateFormat date={value} />
    )
  },
  {
    width: 200,
    headerName: 'Warehouse',
    field: 'warehouse',
    valueGetter: ({ row }) => prop('name', row.warehouse),
    renderCell: ({ value, row }) => {
      const companyName = propOr('', 'companyName', row.warehouse)
      return (
        <VerticalAlignment
          primary={value}
          secondary={(
            <Subtext lines={1}>{companyName}</Subtext>
          )}
        />
      )
    }
  },
  {
    width: 200,
    headerName: 'Location',
    field: 'location'
  },
  {
    width: 200,
    headerName: 'Company',
    field: 'companyName',
    sortable: false
  },
  {
    width: 200,
    headerName: 'Created date',
    field: 'createdAt',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  },
  {
    width: 200,
    headerName: 'Last updated',
    field: 'updatedAt',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  }
]

const getRowsFromResults = map(item => {
  const id = prop('id', item)
  const guid = prop('guid', item)
  const variant = prop('variant', item)
  const name = prop('name', variant)

  const image = prop('defaultImage', variant)
  const unitNumber = prop('unitNumber', item)
  const serialNumber = prop('serialNumber', item)
  const quantity = prop('quantity', item)
  const unitOfMeasurement = capitalize(prop('unitOfMeasurement', variant))
  const conditionCode = path(['condition', 'code'], item)
  const container = path(['container', 'number'], item)
  const expiryDate = propOr('', 'expiresAt', item)
  const productionDate = propOr('', 'productionDate', item)
  const location = path(['location', 'locationId'], item)
  const warehouse = path(['location', 'warehouse'], item)
  const companyName = path(['company', 'name'], item)
  const reservedFor = prop('reservedFor', item)
  const batchNumber = prop('batchNumber', item)
  const available = prop('isAvailable', item)
  const createdAt = prop('createdAt', item)
  const updatedAt = prop('updatedAt', item)
  const weight = prop('weight', variant)
  const pieces = prop('pieces', variant)
  const length = prop('length', variant)
  const width = prop('width', variant)
  const height = prop('height', variant)
  const totalWeight = quantity * weight
  const totalPieces = quantity * pieces
  const totalVolume = quantity * (length * width & height)
  const link = `${ROUTES.UNIT_HISTORY_PATH}?guid=${guid}`

  return {
    id,
    guid,
    name,
    unitNumber,
    available,
    batchNumber,
    quantity,
    unitOfMeasurement,
    reservedFor,
    serialNumber,
    productionDate,
    image,
    variant,
    expiryDate,
    conditionCode,
    container,
    warehouse,
    location,
    companyName,
    createdAt,
    updatedAt,
    link,
    totalPieces,
    totalWeight,
    totalVolume
  }
})

function UnitList ({ list, filter, ordering, onListRefetch, onReportGenerate }) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const isLoading = prop('isLoading', list)
  const results = prop('results', list)
  const hasNextPage = prop('hasNextPage', list)
  const hasPrevPage = prop('hasPrevPage', list)
  const count = prop('count', list)
  const columns = prop('filteredColumns', ordering)

  const handleHistoryRedirect = useCallback(guid => {
    navigate(`${ROUTES.UNIT_HISTORY_PATH}?guid=${guid}`)
  }, [navigate])

  const actionColumns = useMemo(() => [
    ...columns,
    {
      width: 50,
      resizable: false,
      field: 'actions',
      type: 'actions',
      getActions: ({ row }) => [
        <GridActionsCellItem
          label="History"
          onClick={() => handleHistoryRedirect(row.guid)}
          showInMenu={true}
          icon={<HistoryIcon />}
        />
      ]
    }
  ], [columns, handleHistoryRedirect])

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={t(NAV.RETAILER_UNITS_REPORT)}
          parentTitle={t(NAV.REPORTS)}
          rightButton={(
            <Button
              variant="contained"
              data-cy="create"
              type="button"
              onClick={onReportGenerate}
            >
              {t('Generate Report')}
            </Button>
          )}
        />
      </Header>

      <Content>
        <TableHeader
          filter={filter}
          filterOpen={filter.handleOpen}
          orderingOpen={ordering.handleOpen}
          ordering={ordering}
        />
        <Table
          isLoading={isLoading}
          columns={actionColumns}
          getRowsFromResults={getRowsFromResults}
          results={results}
          count={count}
          checkboxSelection={false}
          pinnedColumns={['image']}
          ordering={ordering}
          onRefetch={onListRefetch}
          isNewPagination={true}
          hasNextPage={hasNextPage}
          hasPrevPage={hasPrevPage}
        />
      </Content>
    </Container>
  )
}

UnitList.propTypes = {
  list: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  onListRefetch: PropTypes.func.isRequired,
  onReportGenerate: PropTypes.func.isRequired
}

export default UnitList
