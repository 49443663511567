import React from 'react'
import { useFilter, useOrdering } from 'storfox-filter'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'
import useAllSearchParams from 'storfox-route-hooks/src/useAllSearchParams'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import ErrorLink from '~/components/ErrorLink'
import useMessages from '~/hooks/useMessages'
import { useGoogleEvent } from '~/components/GoogleAnalytics/GoogleAnalytics'
import { useTableSelects } from '~/components/TableValues/hooks'
import { queryToParams } from '~/utils'

import { SaleOrderFilterSerializer } from '../../serializers/SaleOrder'
import { useSaleOrderList, useSaleOrderReportGenerate } from '../../hooks/SaleOrder'
import SaleOrderTable from '../../components/SaleOrder/SaleOrderTable'
import SaleOrderOrderingForm, { saleOrderOrderingOptions } from '../../components/SaleOrder/SaleOrderOrderingForm'
import SaleOrderFilterForm, { saleOrderFilterOptions } from '../../components/SaleOrder/SaleOrderFilterForm'

function SaleOrderListContainer () {
  const saleOrderList = useSaleOrderList()
  const saleOrderReportsGenerate = useSaleOrderReportGenerate()
  const messages = useMessages()
  const snackbar = useSnackbar()
  const { sendEvent } = useGoogleEvent()
  const { selects } = useTableSelects()
  const searchParams = useAllSearchParams()

  const filter = useFilter(saleOrderFilterOptions)
  const ordering = useOrdering(saleOrderOrderingOptions)

  const breadcrumbs = { title: NAV.SALE_ORDER_REPORT }

  const handleSalesExport = () => {
    const params = selects.length === 0
      ? queryToParams(SaleOrderFilterSerializer, searchParams, 'guid')
      : { guids: selects.join(',') }
    return saleOrderReportsGenerate.generate(params)
      .then(() => {
        sendEvent({ eventAction: 'Report Bulk Generate Sale Order', eventCategory: 'Sale Order' })
        snackbar({ message: messages.NOTIFICATION_WAIT })
      })
      .catch(response => {
        const message = <ErrorLink error={response} />
        snackbar({ message, type: ALTER_ERROR })
      })
  }

  return (
    <DashboardLayout
      title={NAV.SALE_ORDER_REPORT}
      activeNav={NAV.REPORTS}
      breadcrumbs={breadcrumbs}
    >
      <SaleOrderFilterForm {...filter} />
      <SaleOrderOrderingForm {...ordering} />

      <SaleOrderTable
        onListRefetch={saleOrderList.getListByParams}
        filter={filter}
        ordering={ordering}
        list={saleOrderList}
        onReportGenerate={handleSalesExport}
      />
    </DashboardLayout>
  )
}

export default SaleOrderListContainer
