export const CODE_TYPE = {
  QRCODE: 'qr',
  BARCODE: 'bar'
}

export const BARCODE_TYPE = {
  EON13: 'eon13',
  CODE128: 'code128'
}

export const FONT_SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large'
}

export const IGNORE_SCAN = 'n0-$cAn*'
export const DONE = '*&D0nE-@1'
export const SKIP = '^@5k1p$^'
export const CONDITION_PREFIX = 'CD-'
export const CONTAINER_TYPE_PREFIX = 'CTT-'
export const CONTAINER_PREFIX = 'CT-'
