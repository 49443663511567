import React from 'react'
import { Ordering } from 'storfox-filter'

import { TRANSFER_COLUMNS as tableColumns } from './TransferTable'

import { FILTER_NAME as name } from '../../constants'

export const transferOrderingOptions = {
  name,
  tableColumns
}

function TransferOrderingForm (props) {
  return (
    <Ordering {...props} />
  )
}

export default TransferOrderingForm
