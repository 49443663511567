import React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { prop } from 'ramda'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import Title from '~/components/Title'
import { getNonFieldError } from '~/components/Form'
import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'
import { RECEIVE_TABS } from '~/constants/tabs'
import useMessages from '~/hooks/useMessages'

import ReceiveTransferTableForm from '../components/ReceiveStockTransfer/ReceiveTransferDetail'
import { ReceiveTransferInitSerializer } from '../serializers/transferSerializer'
import {
  useReceiveHistory,
  useReceivePurchaseComplete,
  useReceiveTransferDetail,
  useReceiveTransferUpdate,
  useStockDetailVariant
} from '../hooks'

function ReceiveTransferContainer () {
  const messages = useMessages()
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const { guid, tab } = useParams()
  const receiveTransferUpdate = useReceiveTransferUpdate()
  const receivingDetail = useReceiveTransferDetail(guid)
  const receiveComplete = useReceivePurchaseComplete(guid)
  const variantDetail = useStockDetailVariant(guid)
  const receiveHistory = useReceiveHistory(guid, tab === RECEIVE_TABS.HISTORY)

  const handleReceive = () => {
    const path = generatePath(ROUTES.RECEIVE_ST_SCAN_DETAIL_PATH, { guid })
    navigate(path)
  }

  const handleComplete = () =>
    receiveComplete.complete()
      .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
      .then(() => navigate(ROUTES.RECEIVE_LIST_PATH))
      .catch(data => snackbar({ message: getNonFieldError(data), type: ALTER_ERROR }))

  const isLoading = receivingDetail.isLoading || receiveTransferUpdate.isLoading

  const number = prop('number', receivingDetail.detail)
  const status = prop('receivingStatus', receivingDetail.detail)
  const id = prop('id', receivingDetail.detail)

  const pageTitle = (
    <Title title="Receive" status={status} number={number} />
  )

  const pageTitleLoading = receivingDetail.isLoading

  const breadcrumbs = { title: number }

  return (
    <DashboardLayout
      title={number}
      activeNav={NAV.RECEIVE}
      isLoading={isLoading}
      breadcrumbs={breadcrumbs}
    >
      <ReceiveTransferTableForm
        pageTitle={pageTitle}
        pageTitleLoading={pageTitleLoading}
        isLoading={isLoading}
        values={ReceiveTransferInitSerializer(receivingDetail.detail, id)}
        receiveHistory={receiveHistory}
        completeDisabled={receiveComplete.isLoading}
        onComplete={handleComplete}
        handleReceive={handleReceive}
        getVariantDetail={variantDetail.getDetail}
      />
    </DashboardLayout>
  )
}

export default ReceiveTransferContainer
