import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { isEmpty, isNil } from 'ramda'
import { useField } from 'react-final-form'
import { useDeepCompareEffect } from 'storfox-api-hooks'

import PositiveFloatField from './PositiveFloatField'

function ConverterField ({ name, ratio, ...rest }) {
  const [value, setValue] = useState(null)
  const { input } = useField(name)
  const fieldOnChange = input.onChange
  const fieldValue = input.value

  useDeepCompareEffect(() => {
    if (!isNil(value)) {
      fieldOnChange(Number((value / ratio).toFixed(3)))
    }
  }, [value])

  useDeepCompareEffect(() => {
    if (!isEmpty(fieldValue)) {
      setValue(Number((fieldValue * ratio).toFixed(3)))
    }
  }, [fieldValue])

  return (
    <PositiveFloatField
      name={name}
      value={value}
      onChange={event => {
        const val = event.target.value
        setValue(val)
      }}
      {...rest}
    />
  )
}

ConverterField.propTypes = {
  name: PropTypes.string.isRequired,
  ratio: PropTypes.number.isRequired
}

export default ConverterField
