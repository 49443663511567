import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import { prop } from 'ramda'

import { DiscardButton, SaveButton } from '~/components/Buttons'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import { withForm } from '~/components/Form'
import * as NAV from '~/constants/nav-titles'
import { COMPLETED, PROCESSING } from '~/components/Statuses/ReturnStatus'

import ReturnUpdateFormGeneral from './ReturnUpdateFormGeneral'

export const RETURN_UPDATE_FIELDS = [
  'carrier',
  'lineItems',
  'notes',
  'order',
  'service',
  'trackingNumber',
  'pickupAddress',
  'warehouse',
  'deliveryAddress'
]

function ReturnUpdateForm (props) {
  const {
    pageTitle,
    pageTitleLoading,
    isLoading,
    onIntegrationCheck,
    returnVariants,
    form
  } = props

  const { initialValues, handleSubmit } = form

  const status = prop('status', initialValues)

  const isProcessing = status === PROCESSING
  const isCompleted = status === COMPLETED

  const disabled = (
    isProcessing ||
    isCompleted ||
    isLoading
  )

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={pageTitle}
            pageTitleLoading={pageTitleLoading}
            parentTitle={NAV.SALE_RETURNS}
            rightButton={
              <>
                <DiscardButton disabled={isLoading} />
                <SaveButton disabled={disabled} />
              </>
            }
          />
        </Header>

        <Content>
          <ReturnUpdateFormGeneral returnVariants={returnVariants} onIntegrationCheck={onIntegrationCheck} />
          <Box mb={3} />
        </Content>
      </Container>
    </form>
  )
}

ReturnUpdateForm.propTypes = {
  pageTitle: PropTypes.node,
  pageTitleLoading: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onIntegrationCheck: PropTypes.func.isRequired,
  returnVariants: PropTypes.object.isRequired
}

export default withForm(ReturnUpdateForm)
