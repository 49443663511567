import React from 'react'
import { useSnackbar } from 'storfox-snackbar'
import { generatePath, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { prop } from 'ramda'

import { DashboardLayout } from '~/components/Layouts'
import { ACTIVE } from '~/components/Fields/StatusField'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import useMessages from '~/hooks/useMessages'
import { INTEGRATION_TABS } from '~/constants/tabs'

import EliteIntegrationCreateForm from '../../components/Elite/EliteIntegrationCreateForm'
import { useEliteCreate } from '../../hooks'

function EliteIntegrationCreateContainer () {
  const { t } = useTranslation()

  const integrationCreate = useEliteCreate()
  const snackbar = useSnackbar()
  const navigate = useNavigate()

  const messages = useMessages()

  const title = 'New Integration'

  const handleSubmit = formValues =>
    integrationCreate.create(formValues)
      .then((data) => {
        snackbar({ message : messages.CREATE_SUCCESS })
        return data
      })
      .then(({ result }) => {
        const guid = prop('guid', result)
        const type = prop('type', result)
        const params = { guid, type, tab: INTEGRATION_TABS.GENERAL }
        const updatePath = generatePath(ROUTES.INTEGRATION_UPDATE_PATH, params)
        navigate(updatePath)
      })

  const isLoading = integrationCreate.isLoading

  const initialValues = {
    status: ACTIVE,
  }

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={t(title)}
      isLoading={isLoading}
      activeNav={NAV.INTEGRATION}
      breadcrumbs={breadcrumbs}
    >
      <EliteIntegrationCreateForm
        onSubmit={handleSubmit}
        initialValues={initialValues}
        pageTitle={t(title)}
        parentTitle={t(NAV.SETTINGS)}
        isLoading={isLoading}
      />
    </DashboardLayout>
  )
}

export default EliteIntegrationCreateContainer
