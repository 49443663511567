import React from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'

import { CardHeader } from '~/components/Cards'
import MoneyField from '~/components/Fields/MoneyField'
import PositiveNumberField from '~/components/Fields/PositiveNumberField'

function BillingProfileAdhoc () {
  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12} lg={6}>
        <Card>
          <CardHeader title="Adhoc" />
          <Divider />
          <CardContent>
            <Grid container={true} spacing={3}>
              <Grid item={true} lg={12}>
                <MoneyField name="adhocBilling.price" label="Price" />
              </Grid>
              <Grid item={true} lg={12}>
                <PositiveNumberField
                  name="adhocBillTax"
                  data-cy="adhocBillTax"
                  label="Tax"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default BillingProfileAdhoc
