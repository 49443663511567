import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card/Card'
import { Divider } from '@mui/material'
import CardContent from '@mui/material/CardContent'
import { useTranslation } from 'react-i18next'

import { CardHeader } from '~/components/Cards'
import TextField from '~/components/Fields/TextField'

import ApiUpdate from './ApiUpdate'

function ApiIntegrationUpdateFormGeneral () {
  const { t } = useTranslation()
  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12} lg={8}>
        <Box mb={3}>
          <Card>
            <CardHeader title={t('Integration')} />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <ApiUpdate />
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>
      <Grid item={true} xs={12} lg={4}>
        <Box mb={3}>
          <Card>
            <CardHeader title={t('Webhooks')} />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} lg={12}>
                  <TextField
                    data-cy="saleOrderWebhookUrl"
                    name="credential.saleOrderWebhookUrl"
                    label="Sale Order Webhook URL"
                  />
                </Grid>
                <Grid item={true} lg={12}>
                  <TextField
                    data-cy="stockWebhookUrl"
                    name="credential.stockWebhookUrl"
                    label="Stock Webhook URL"
                  />
                </Grid>
                <Grid item={true} lg={12}>
                  <TextField
                    data-cy="listingWebhookUrl"
                    name="credential.listingWebhookUrl"
                    label="Listing Webhook URL"
                  />
                </Grid>
                <Grid item={true} lg={12}>
                  <TextField
                    data-cy="variantWebhookUrl"
                    name="credential.variantWebhookUrl"
                    label="Variant Webhook URL"
                  />
                </Grid>
                <Grid item={true} lg={12}>
                  <TextField
                    data-cy="purchaseOrderWebhookUrl"
                    name="credential.purchaseOrderWebhookUrl"
                    label="Purchase Order Webhook URL"
                  />
                </Grid>
                <Grid item={true} lg={12}>
                  <TextField
                    data-cy="salesReturnsWebhookUrl"
                    name="credential.salesReturnsWebhookUrl"
                    label="Sales Returns Webhook URL"
                  />
                </Grid>
                <Grid item={true} lg={12}>
                  <TextField
                    data-cy="receiveWebhookUrl"
                    name="credential.receiveWebhookUrl"
                    label="Receive Webhook URL"
                  />
                </Grid>
                <Grid item={true} lg={12}>
                  <TextField
                    data-cy="unitWebhookUrl"
                    name="credential.unitWebhookUrl"
                    label="Unit Webhook URL"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </Grid>
  )
}

export default ApiIntegrationUpdateFormGeneral
