import React from 'react'
import PropTypes from 'prop-types'

import Status from '~/components/Status'

export const UNPUBLISHED = 'unpublished'
export const PENDING = 'pending'
export const PUBLISHED = 'published'
export const PAUSED = 'paused'
export const REJECTED = 'rejected'

export const STATUS_NAMES = {
  [UNPUBLISHED]: 'Unpublished',
  [PENDING]: 'Pending',
  [PUBLISHED]: 'Published',
  [PAUSED]: 'Paused',
  [REJECTED]: 'Rejected'
}

const COLOR = {
  [UNPUBLISHED]: 'warning',
  [PENDING]: 'warning',
  [PUBLISHED]: 'success',
  [PAUSED]: 'warning',
  [REJECTED]: 'error',
}

function ListingStatus ({ value }) {
  return (
    <Status
      label={STATUS_NAMES[value]}
      color={COLOR[value]}
    />
  )
}

ListingStatus.propTypes = {
  value: PropTypes.oneOf([
    '',
    UNPUBLISHED,
    PENDING,
    PUBLISHED,
    PAUSED,
    REJECTED
  ]).isRequired
}

export default ListingStatus
