import React from 'react'
import { useDeepCompareEffect } from 'storfox-api-hooks'
import PropTypes from 'prop-types'

import Confirmation from '~/components/Confirmation'

function WooCommerceReturn ({ onSubmit }) {
  useDeepCompareEffect(() => {
    setTimeout(() => {
      onSubmit()
    }, 2000)
  }, [])

  return (
    <Confirmation />
  )
}

WooCommerceReturn.propTypes = {
  onSubmit: PropTypes.func.isRequired
}

export default WooCommerceReturn
