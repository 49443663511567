export const DRAFT = 'draft'
export const CANCELLED = 'cancelled'
export const COMPLETED = 'completed'

export const SAVE_DRAFT = 'save-draft'
export const SAVE_COMPLETE = 'save-complete'

export const ADJUSTMENT_STATUS = {
  DRAFT,
  CANCELLED,
  COMPLETED,
}

export const FILTER_NAME = 'adjustment'
