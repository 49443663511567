import React from 'react'
import { useSnackbar } from 'storfox-snackbar'
import { generatePath, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { prop } from 'ramda'

import { DashboardLayout } from '~/components/Layouts'
import { ACTIVE } from '~/components/Fields/StatusField'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import { INTEGRATION_TABS } from '~/constants/tabs'
import useMessages from '~/hooks/useMessages'

import { useTraxCreate } from '../../hooks'
import TraxIntegrationCreateForm from '../../components/Trax/TraxIntegrationCreateForm'

function TraxIntegrationCreateContainer () {
  const { t } = useTranslation()
  const traxCreate = useTraxCreate()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const messages = useMessages()

  const title = 'New Integration'

  const handleSubmit = formValues =>
    traxCreate
      .create(formValues)
      .then(({ result }) => {
        const guid = prop('guid', result)
        const type = prop('type', result)
        const params = { guid, type, tab: INTEGRATION_TABS.GENERAL }
        const detailPath = generatePath(ROUTES.INTEGRATION_UPDATE_PATH, params)
        navigate(detailPath)
      })
      .then(() => snackbar({ message: messages.CREATE_SUCCESS }))

  const isLoading = traxCreate.isLoading

  const initialValues = {
    status: ACTIVE,
  }

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={t(title)}
      isLoading={traxCreate.isLoading}
      activeNav={NAV.INTEGRATION}
      breadcrumbs={breadcrumbs}
    >
      <TraxIntegrationCreateForm
        onSubmit={handleSubmit}
        initialValues={initialValues}
        pageTitle={t(title)}
        parentTitle={t(NAV.SETTINGS)}
        isLoading={isLoading}
      />
    </DashboardLayout>
  )
}

export default TraxIntegrationCreateContainer
