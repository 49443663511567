import React from 'react'
import Card from '@mui/material/Card'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'
import PropTypes from 'prop-types'
import { path, prop } from 'ramda'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import TextLabelLoader from '~/components/TextLabelLoader'
import Money from '~/components/Money'
import CheckCircleIcon from '~/components/Icons/CheckCircleIcon'
import MinusCircleIcon from '~/components/Icons/MinusCircleIcon'

function VariantInternationalDetail ({ values }) {
  const { t } = useTranslation()

  const isInternational = prop('isInternational', values)
  const nameAlias = prop('nameAlias', values)
  const hsCode = prop('hsCode', values)
  const originCountry = path(['originCountry', 'name'], values)
  const insuranceValue = prop('insuranceValue', values)
  const sellingCost = prop('sellingCost', values)
  const customsDescription = prop('customsDescription', values)

  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12} lg={8}>
        <Card>
          <CardContent>
            {isInternational ? (
              <Box display="flex" alignItems="center">
                <Box mr={1}>
                  <CheckCircleIcon />
                </Box>
                <Typography>
                  {t('This is international variant')}
                </Typography>
              </Box>
            ) : (
              <Box display="flex" alignItems="center">
                <Box mr={1}>
                  <MinusCircleIcon />
                </Box>
                <Typography>
                  {t('This is not international variant')}
                </Typography>
              </Box>
            )}
          </CardContent>

          {isInternational && (
          <>
            <Divider />
            <CardContent>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Name alias">
                    {nameAlias}
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12}>
                  <TextLabelLoader label="HS code">
                    {hsCode}
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Origin country">
                    {originCountry}
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12} lg={6}>
                  <TextLabelLoader label="Insurance value">
                    <Money value={insuranceValue} />
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12} lg={6}>
                  <TextLabelLoader label="Selling cost">
                    <Money value={sellingCost} />
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Customs description">
                    {customsDescription}
                  </TextLabelLoader>
                </Grid>
              </Grid>
            </CardContent>
          </>
          )}
        </Card>
      </Grid>
    </Grid>
  )
}

VariantInternationalDetail.propTypes = {
  values: PropTypes.object.isRequired
}

export default VariantInternationalDetail
