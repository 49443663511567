import React from 'react'
import { generatePath, Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import PropTypes from 'prop-types'
import { styled } from '@mui/material'

import * as ROUTES from '~/constants/routes'
import { TOP_BAR_HEIGHT } from '~/constants/settings'
import Logo from '~/components/Logo/Logo'
import { useCompany } from '~/components/Profile'
import { OVERVIEW_TABS } from '~/constants/tabs'

import ToggleButton from './ToggleButton'

const RootStyled = styled(Box, {
  shouldForwardProp: (propName) => propName !== 'isDesktopClosed'
})(({ theme, isDesktopClosed }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(1, 2),
  minHeight: TOP_BAR_HEIGHT.lg,
  maxHeight: TOP_BAR_HEIGHT.lg,
  '@media (max-width: 600px) and (orientation: landscape)': {
    minHeight: TOP_BAR_HEIGHT.sm,
    maxHeight: TOP_BAR_HEIGHT.sm
  },
  ...(isDesktopClosed && {
    padding: 0,
    justifyContent: 'center'
  })
}))

const LogoStyled = styled(Logo)({
  width: 125,
  '& .st8': {
    fill: 'white'
  }
})

const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: `calc(${theme.spacing(1)} + 2px)`,
  width: '34px',
  height: '34px'
}))

function TopMenu ({ openDesktop, onDesktopToggle }) {
  const { isThreePl } = useCompany()

  const tab = isThreePl ? OVERVIEW_TABS.FULFILLMENT : OVERVIEW_TABS.SALES
  const to = generatePath(ROUTES.OVERVIEW_PATH, { tab })

  return (
    <RootStyled isDesktopClosed={!openDesktop}>
      {openDesktop && (
        <Link
          component={RouterLink}
          to={to}
          underline="none"
          color="initial"
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <LogoStyled />
        </Link>
      )}
      <IconButtonStyled size="small" onClick={onDesktopToggle}>
        <ToggleButton isRight={!openDesktop} />
      </IconButtonStyled>
    </RootStyled>
  )
}

TopMenu.propTypes = {
  openDesktop: PropTypes.bool.isRequired,
  onDesktopToggle: PropTypes.func.isRequired
}

export default TopMenu
