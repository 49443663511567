import React from 'react'
import PropTypes from 'prop-types'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { useTranslation } from 'react-i18next'

import { MoreButton } from '~/components/Buttons'

function More (props) {
  const {
    returnDisabled,
    cancelDisabled,
    saleOrderSplitDisabled,
    markAsPaidDisabled,
    invoiceGenerateDisabled,
    processSkipDisabled,
    onReturnClick,
    onCancelClick,
    onMarkAsPaidClick,
    onInvoiceGenerateClick,
    onSaleOrderSplitClick,
    onProcessSkipClick,
    onCommercialGenerate,
    commercialDisabled,
    onManualAllocate,
    manualEnabled,
    onHealthCareInvoice,
    healthCareDisabled
  } = props
  const { t } = useTranslation()

  return (
    <MoreButton>
      {onClose => (
        <List>
          <ListItem
            button={true}
            disabled={returnDisabled}
            onClick={onReturnClick}
            data-cy="return"
          >
            <ListItemText primary={t('Request return')} />
          </ListItem>
          <ListItem
            button={true}
            disabled={saleOrderSplitDisabled}
            onClick={onSaleOrderSplitClick}
          >
            <ListItemText primary={t('Split order')} />
          </ListItem>
          <ListItem
            button={true}
            disabled={cancelDisabled}
            onClick={onCancelClick}
            data-cy="cancel"
          >
            <ListItemText primary={t('Cancel')} />
          </ListItem>
          <ListItem
            button={true}
            disabled={processSkipDisabled}
            onClick={() => {
              onProcessSkipClick()
              onClose()
            }}
            data-cy="skipProcess"
          >
            <ListItemText primary={t('Skip process')} />
          </ListItem>
          <ListItem
            button={true}
            disabled={markAsPaidDisabled}
            onClick={onMarkAsPaidClick}
          >
            <ListItemText primary={t('Mark as paid')} />
          </ListItem>
          <ListItem
            button={true}
            disabled={invoiceGenerateDisabled}
            onClick={() => {
              onInvoiceGenerateClick()
              onClose()
            }}
          >
            <ListItemText primary={t('Generate invoice')} />
          </ListItem>
          <ListItem
            button={true}
            disabled={commercialDisabled}
            onClick={() => {
              onCommercialGenerate()
              onClose()
            }}
          >
            <ListItemText primary={t('Generate commercial invoice')} />
          </ListItem>
          <ListItem
            button={true}
            disabled={healthCareDisabled}
            onClick={() => {
              onHealthCareInvoice()
              onClose()
            }}
          >
            <ListItemText primary={t('Generate health care invoice')} />
          </ListItem>
          <ListItem
            button={true}
            disabled={!manualEnabled}
            onClick={() => {
              onManualAllocate()
              onClose()
            }}
          >
            <ListItemText primary={t('Allocate Manually')} />
          </ListItem>
        </List>
      )}
    </MoreButton>
  )
}

More.propTypes = {
  returnDisabled: PropTypes.bool.isRequired,
  cancelDisabled: PropTypes.bool.isRequired,
  commercialDisabled: PropTypes.bool.isRequired,
  markAsPaidDisabled: PropTypes.bool.isRequired,
  saleOrderSplitDisabled: PropTypes.bool.isRequired,
  invoiceGenerateDisabled: PropTypes.bool.isRequired,
  processSkipDisabled: PropTypes.bool.isRequired,
  onReturnClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  onMarkAsPaidClick: PropTypes.func.isRequired,
  onProcessSkipClick: PropTypes.func.isRequired,
  onInvoiceGenerateClick: PropTypes.func.isRequired,
  onCommercialGenerate: PropTypes.func.isRequired,
  onSaleOrderSplitClick: PropTypes.func.isRequired,
  onManualAllocate: PropTypes.func.isRequired,
  manualEnabled: PropTypes.bool,
  onHealthCareInvoice: PropTypes.func.isRequired,
  healthCareDisabled: PropTypes.bool
}

export default More
