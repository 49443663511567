import React from 'react'
import PropTypes from 'prop-types'
import { path, prop } from 'ramda'
import { generatePath, useNavigate } from 'react-router-dom'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import TableContainer from '@mui/material/TableContainer'
import InfoIcon from '@mui/icons-material/Info'
import Box from '@mui/material/Box'
import { IconButton } from '@mui/material'

import { CardHeader } from '~/components/Cards'
import { Button } from '~/components/Buttons'
import TextOverflow from '~/components/TextOverflow'
import * as ROUTES from '~/constants/routes'
import { VARIANT_DETAIL_TABS } from '~/constants/tabs'
import HtmlTooltip from '~/components/HtmlTooltip/HtmlTooltip'

import Loader from './Loader'

function LowStock ({ className, items, isLoading, ...rest }) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleSeeAllClick = () => {
    return navigate(`${ROUTES.VARIANT_LIST_URL}?needReorder=1`)
  }

  const handleItemClick = item => {
    const id = prop('id', item)
    const productId = path(['product', 'id'], item)
    const isMultiVariant = path(['product', 'isMultiVariant'], item)
    const tab = VARIANT_DETAIL_TABS.GENERAL

    const url = generatePath(ROUTES.VARIANT_DETAIL_PATH, { id, productId, tab })
    return navigate(`${url}?needReorder=1&isMultiVariant=${isMultiVariant}`)
  }

  return (
    <Card
      {...rest}
      sx={{ display: 'flex', flexDirection: 'column' }}
      className={className}
    >
      <CardHeader
        title={
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h4">{t('Low Stock')}</Typography>
            <HtmlTooltip title="Trending items with total order qty and ordered qty.">
              <IconButton size="small">
                <InfoIcon sx={{ color: '#BDBDBD' }} />
              </IconButton>
            </HtmlTooltip>
          </Box>
        }
      />
      <Divider />
      <CardContent sx={{ flexGrow: 1, padding: 0 }}>
        <div>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('Name')}</TableCell>
                  <TableCell>{t('Order Qty')}</TableCell>
                  <TableCell>{t('Stock Qty')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading && <Loader />}
                {!isLoading && items.map(item => {
                  const id = prop('id', item)
                  const name = path(['variant', 'name'], item)
                  const available = prop('available', item)
                  const ordered = prop('ordered', item)

                  return (
                    <TableRow
                      hover={true}
                      key={id}
                      sx={{ cursor: 'pointer' }}
                      onClick={() => handleItemClick(item)}
                    >
                      <TableCell align="left">
                        <Typography color="inherit" component="div">
                          <TextOverflow selfTooltip={true}>
                            {t(name)}
                          </TextOverflow>
                        </Typography>
                      </TableCell>
                      <TableCell align="center">{ordered}</TableCell>
                      <TableCell align="center">{available}</TableCell>
                    </TableRow>
                  )
                })}
                {!isLoading && items.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={3}>{t('No low stock')}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </CardContent>

      <Divider />
      {!isLoading && items.length > 0 && (
        <CardActions sx={{ justifyContent: 'center' }}>
          <Button
            size="small"
            disabled={isLoading}
            onClick={handleSeeAllClick}
            sx={{ textTransform: 'capitalize' }}
          >
            View all
          </Button>
        </CardActions>
      )}
    </Card>
  )
}

LowStock.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default LowStock
