import React from 'react'
import { Ordering } from 'storfox-filter'

import { UNIT_COLUMNS as tableColumns } from './UnitList'

import { FILTER_NAME as name } from '../../constants/RetailerUnit'

export const inventoryOrderingOptions = {
  name,
  tableColumns
}

function InventoryOrderingForm (props) {
  return (
    <Ordering {...props} />
  )
}

export default InventoryOrderingForm
