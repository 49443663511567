import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import MagentoIntegrationUpdateContainer from './MagentoIntegrationUpdateContainer'
import MagentoIntegrationCreateContainer from './MagentoIntegrationCreateContainer'

export default () => {
  return [
    {
      layout: AuthLayout,
      path: ROUTES.MAGENTO_INTEGRATION_CREATE_PATH,
      component: MagentoIntegrationCreateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.MAGENTO_INTEGRATION_UPDATE_PATH,
      component: MagentoIntegrationUpdateContainer
    }
  ]
}
