import React from 'react'
import { path, prop } from 'ramda'
import PropTypes from 'prop-types'
import { generatePath, useNavigate } from 'react-router-dom'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Divider from '@mui/material/Divider'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableContainer from '@mui/material/TableContainer'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { IconButton, styled } from '@mui/material'
import Box from '@mui/material/Box'
import InfoIcon from '@mui/icons-material/Info'

import { CardHeader } from '~/components/Cards'
import { Button } from '~/components/Buttons'
import Money from '~/components/Money'
import TableDateFormat from '~/components/TableDateFormat'
import * as ROUTES from '~/constants/routes'
import { PURCHASE_ORDER_DETAIL_TABS } from '~/constants/tabs'
import HtmlTooltip from '~/components/HtmlTooltip/HtmlTooltip'

import Loader from './Loader'

const CardContentStyled = styled(CardContent)({
  padding: 0,
  '&:last-child': {
    paddingBottom: 0
  }
})

function Purchase ({ className, items, isLoading, ...rest }) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleItemClick = guid => {
    const url = generatePath(ROUTES.PURCHASE_ORDER_DETAIL_PATH, { guid, tab: PURCHASE_ORDER_DETAIL_TABS.GENERAL })
    return navigate(url)
  }

  const handleSeeAllClick = () => {
    return navigate(ROUTES.PURCHASE_ORDER_LIST_PATH)
  }

  return (
    <Card
      {...rest}
      className={className}
    >
      <CardHeader
        title={
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h4">{t('Purchases')}</Typography>
            <HtmlTooltip title="Detail view of received Purchase orders.">
              <IconButton size="small">
                <InfoIcon sx={{ color: '#BDBDBD' }} />
              </IconButton>
            </HtmlTooltip>
          </Box>
        }
      />
      <Divider />
      <CardContentStyled>
        <TableContainer>
          <Box sx={{ minWidth: 700 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>{t('Supplier')}</TableCell>
                  <TableCell>{t('Total')}</TableCell>
                  <TableCell>{t('Expected')}</TableCell>
                  <TableCell>{t('Received')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading && <Loader />}
                {!isLoading && items.map(item => {
                  const guid = prop('guid', item)
                  const number = prop('number', item)
                  const name = path(['supplier', 'shortName'], item)
                  const totalPrice = prop('totalPrice', item)
                  const ordered = prop('ordered', item)
                  const received = prop('received', item)
                  const expectedDate = prop('expectedDate', item)

                  return (
                    <TableRow
                      hover={true}
                      key={guid}
                      sx={{ cursor: 'pointer' }}
                      onClick={() => handleItemClick(guid)}
                    >
                      <TableCell>
                        {number}
                      </TableCell>
                      <TableCell>
                        <Typography>{t(name)}</Typography>
                      </TableCell>
                      <TableCell>
                        <Money value={totalPrice} />
                      </TableCell>
                      <TableCell>
                        <TableDateFormat date={expectedDate} />
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{ whiteSpace: 'nowrap' }}
                          variant="subtitle2"
                        >
                          {received}/{ordered}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Box>
        </TableContainer>
      </CardContentStyled>
      <Divider />
      <CardActions sx={{ justifyContent: 'center' }}>
        <Button
          size="small"
          onClick={handleSeeAllClick}
          disabled={isLoading}
          sx={{ textTransform: 'capitalize' }}
        >
          View all
        </Button>
      </CardActions>
    </Card>
  )
}

Purchase.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default Purchase
