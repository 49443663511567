import { sprintf } from 'sprintf-js'
import { usePickSearchParams } from 'storfox-route-hooks'
import { useCreate, useDetail, useList } from 'storfox-api-hooks'

import { orderingToSnackCase } from '~/utils'
import * as API from '~/constants/api'

export const useCurrencyRateList = () => {
  const searchParams = usePickSearchParams()
  return useList(API.CURRENCY_RATE_LIST, orderingToSnackCase(searchParams))
}

export const useCurrencyRateCreate = () => {
  return useCreate(API.CURRENCY_RATE_CREATE)
}

export const useCurrencyRateDetail = guid => {
  const url = sprintf(API.CURRENCY_RATE_DETAIL, guid)
  return useDetail(url)
}

export const useCurrencyRateUpdate = () => {
  return useCreate(API.CURRENCY_RATE_CREATE)
}
