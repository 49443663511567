import React from 'react'
import { defaultTo, map, pluck, propOr, sum } from 'ramda'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import Table from '@mui/material/Table'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import Money from '~/components/Money'

function InvoiceSummary ({ lineItems = [] }) {
  const { t } = useTranslation()

  const lineItemsQuantity = lineItems.length

  const unitsOrdered = pluck('quantity', lineItems)
  const unitsOrderedInt = map(defaultTo(0), unitsOrdered)
  const unitsOrderedTotal = sum(unitsOrderedInt)
  const taxes = map(
    item => {
      const quantity = defaultTo(0, item.quantity)
      const price = propOr(0, 'price', item)
      const tax = propOr(0, 'tax', item) / 100
      const discount = propOr(0, 'discount', item)
      const discountPrice = price * (1 - discount / 100)

      return quantity * discountPrice * tax
    },
    lineItems
  )
  const taxesAll = sum(taxes)

  const discounts = map(item => {
    const quantity = defaultTo(0, item.quantity)
    const discount = propOr(0, 'discount', item)
    const price = propOr(0, 'price', item)
    const discountPrice = (discount / 100) * price

    return quantity * discountPrice
  }, lineItems)
  const discountsAll = sum(discounts)

  const subTotal = map(item => {
    const quantity = defaultTo(0, item.quantity)
    const price = propOr(0, 'price', item)
    return quantity * price
  }, lineItems)

  const receivedItems =
    lineItems.reduce((totalReceived, lineItem) => totalReceived + propOr(0, 'received', lineItem), 0)

  const subTotalSum = sum(subTotal)
  const subTotalAll = subTotalSum + taxesAll - discountsAll

  return (
    <Table size="small">
      <TableBody>
        <TableRow>
          <TableCell>{t('Line items')}</TableCell>
          <TableCell />
          <TableCell align="right">
            {lineItemsQuantity}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('Units ordered')}</TableCell>
          <TableCell />
          <TableCell align="right">
            {unitsOrderedTotal}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('Received items')}</TableCell>
          <TableCell />
          <TableCell align="right">
            {receivedItems}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('Subtotal')}</TableCell>
          <TableCell />
          <TableCell align="right">
            <Money value={subTotalSum} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('Discount price')}</TableCell>
          <TableCell />
          <TableCell align="right">
            <Money value={discountsAll} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('Taxes')}</TableCell>
          <TableCell />
          <TableCell align="right">
            <Money value={taxesAll} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('Shipping charges')}</TableCell>
          <TableCell />
          <TableCell align="right">
            <Money value={0} />
          </TableCell>
        </TableRow>
        <TableRow sx={{
          '& td': {
            borderBottom: 'none'
          }
        }}>
          <TableCell>
            <strong>{t('Total')}</strong>
          </TableCell>
          <TableCell />
          <TableCell align="right">
            <Money value={subTotalAll} />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

InvoiceSummary.propTypes = {
  lineItems: PropTypes.array.isRequired
}

export default InvoiceSummary
