import React from 'react'
import PropTypes from 'prop-types'
import TableCell from '@mui/material/TableCell/TableCell'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import { prop, propOr } from 'ramda'
import Paper from '@mui/material/Paper'
import { useTranslation } from 'react-i18next'

import HistoryTableSearch from '~/components/History/HistoryTableSearch'

import LotDetailTableRow from './LotDetailTableRow'

function LotSheetsDetailGeneral ({ lotSheetsDetailList, guid, onModify, modifyLoading }) {
  const { t } = useTranslation()

  const results = propOr([], 'results', lotSheetsDetailList)
  return (
    <Paper>
      <TableContainer>
        <HistoryTableSearch />
        <Table size="small" sx={{ minWidth: '700px' }}>
          <TableHead>
            <TableRow>
              <TableCell>{t('Lot Number')}</TableCell>
              <TableCell>{t('Barcode')}</TableCell>
              <TableCell>{t('Unscanned Quantity')}</TableCell>
              <TableCell>{t('Scanned Quantity')}</TableCell>
              <TableCell>{t('Actual Total')}</TableCell>
              <TableCell>{t('Input SKU')}</TableCell>
              <TableCell>{t('Damage')}</TableCell>
              <TableCell>{t('Lack')}</TableCell>
              <TableCell>{t('Status')}</TableCell>
              <TableCell>{t('Actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results.map((item) => (
              <LotDetailTableRow
                guid={guid}
                item={item}
                key={prop('guid', item)}
                onModify={onModify}
                modifyLoading={modifyLoading}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

LotSheetsDetailGeneral.propTypes = {
  guid: PropTypes.number.isRequired,
  onModify: PropTypes.func.isRequired,
  modifyLoading: PropTypes.bool.isRequired,
  lotSheetsDetailList: PropTypes.object.isRequired
}

export default LotSheetsDetailGeneral
