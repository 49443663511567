import React from 'react'
import DialogContent from '@mui/material/DialogContent'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'

const DialogContentStyled = styled(DialogContent)(({ theme }) => ({
  minHeight: '70vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  overflow: 'visible',
  [theme.breakpoints.down('md')]: {
    minHeight: '100%'
  }
}))

function ScannerDialogContent ({ children }) {
  return (
    <DialogContentStyled>
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
      >
        <Grid container={true} spacing={3}>
          {children}
        </Grid>
      </Box>
    </DialogContentStyled>
  )
}

ScannerDialogContent.propTypes = {
  children: PropTypes.any.isRequired
}

export default ScannerDialogContent
