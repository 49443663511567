import { useAllSearchParams } from 'storfox-route-hooks'

import useCountList from './useCountList'

import { getCancelToken } from '../index'

function useLimitedCountList (api, searchParams, autoSend = true) {
  const cancelToken = getCancelToken()
  const { limit } = useAllSearchParams()
  const autoSendLimit = autoSend && Boolean(limit)

  const {
    getList,
    count,
    getListByParams,
    results,
    isLoading,
    summary,
    hasNextPage,
    hasPrevPage
  } = useCountList(api, searchParams, autoSendLimit, cancelToken)

  return { getList, count, getListByParams, results, isLoading, summary, hasNextPage, hasPrevPage }
}

export default useLimitedCountList
