import { sprintf } from 'sprintf-js'
import { usePickSearchParams } from 'storfox-route-hooks'
import { useCreate, useDetail, useList, useUpdate } from 'storfox-api-hooks'

import { orderingToSnackCase } from '~/utils'
import * as API from '~/constants/api'

export const useCompanyList = () => {
  const searchParams = usePickSearchParams()
  return useList(API.COMPANY_LIST, orderingToSnackCase(searchParams))
}

export const useCompanyDetail = id => {
  const url = sprintf(API.COMPANY_DETAIL, id)
  return useDetail(url)
}

export const useCompanyCreate = () => {
  return useCreate(API.COMPANY_CREATE)
}

export const useCompanyUpdate = id => {
  const url = sprintf(API.COMPANY_UPDATE, id)
  return useUpdate(url)
}
