import React from 'react'
import PropTypes from 'prop-types'

export const EN = 'en'
export const RU = 'ru'
export const UZ = 'uz'
export const AR = 'ar'

export const NAMES = {
  [EN]: 'English',
  [RU]: 'Русский',
  [UZ]: 'O\'zbekcha',
  [AR]: 'عربى'
}

function Language ({ value }) {
  return (
    <>{NAMES[value]}</>
  )
}

Language.propTypes = {
  value: PropTypes.string.isRequired
}

export default Language
