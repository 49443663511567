import React from 'react'
import Box from '@mui/material/Box'
import { prop } from 'ramda'
import { CheckboxField } from 'storfox-form-fields'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import PropTypes from 'prop-types'

import { UploadedFilePlaceholder } from '~/components/FileUpload'

function UploadedFile ({ information, file }) {
  const productNumber = prop('productsCount', information)
  const skuNumber = prop('variantsCount', information)

  return (
    <UploadedFilePlaceholder file={file}>
      <Box>
        <Typography>
          You will be importing {productNumber} with a total of {skuNumber} SKUs
        </Typography>
      </Box>
      <Box>
        <FormControlLabel
          control={
            <CheckboxField
              size="small"
              name="attachImages"
            />
          }
          label={(
            <Typography>Fetch images from links and attach to products</Typography>
          )}
        />
        <FormControlLabel
          control={
            <CheckboxField
              size="small"
              name="attachDescription"
            />
          }
          label={(
            <Typography>
              Fetch product details such as description,
              dimensions, images by ASIN. Data repopulation
              might take from 5-15 minutes
            </Typography>
          )}
        />
      </Box>
    </UploadedFilePlaceholder>

  )
}

UploadedFile.propTypes = {
  file: PropTypes.object.isRequired,
  information: PropTypes.object
}

export default UploadedFile
