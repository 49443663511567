import React from 'react'
import Grid from '@mui/material/Grid'
import PropTypes from 'prop-types'
import { propOr } from 'ramda'

import MoneyField from '~/components/Fields/MoneyField'
import WeightField from '~/components/Fields/WeightField'
import { useCompany } from '~/components/Profile'
import WarehouseSearchField from '~/components/Fields/WarehouseSearchField'

import ContainerTypeField from '../../Fields/ContainerTypeField'

function StoragePerContainer ({ name }) {
  const company = useCompany()
  const weightUnit = propOr('kg', 'weightUnit', company).toString()
  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12} lg={4}>
        <WarehouseSearchField
          name={`${name}.warehouse`}
        />
      </Grid>

      <Grid item={true} xs={12} lg={4}>
        <ContainerTypeField
          name={`${name}.containerType`}
          warehouse={`${name}.warehouse`}
        />
      </Grid>

      <Grid item={true} xs={12} lg={4}>
        <WeightField
          label="Maximum weight"
          name={`${name}.maxWeight`}
        />
      </Grid>

      <Grid item={true} xs={12} lg={4}>
        <MoneyField
          label="Price"
          name={`${name}.price`}
        />
      </Grid>

      <Grid item={true} xs={12} lg={4}>
        <MoneyField
          label={`Fee per additional ${weightUnit} per hour`}
          name={`${name}.additionalPrice`}
        />
      </Grid>

    </Grid>
  )
}

StoragePerContainer.propTypes = {
  name: PropTypes.string.isRequired
}

export default StoragePerContainer
