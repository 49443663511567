import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'

function BarcodeInformation ({ fromContainer, unitNumber, serialNumber, trackSerial, fromLocation }) {
  const { t } = useTranslation()

  return (
    <Alert severity="info" sx={{ mb: 2 }}>
      <AlertTitle>{t('Instructions')}</AlertTitle>
      {fromLocation && (
        <Box>
          <Typography component="h3" variant="subtitle2">
            Pick from Location: {fromLocation}
          </Typography>
        </Box>
      )}
      {fromContainer && (
        <Box>
          <Typography component="h3" variant="subtitle2">
            Pick from container:{' '}
            {fromContainer}
          </Typography>
        </Box>
      )}
      <Box>
        <Typography component="h3" variant="subtitle2" data-cy="unitNumberExample">
          {t('Unit number')}:{' '}
          {unitNumber}
        </Typography>
      </Box>
      {trackSerial && (
        <Box>
          <Typography component="h3" variant="subtitle2">
            Serial number:{' '}
            {serialNumber}
          </Typography>
        </Box>
      )}
    </Alert>
  )
}

BarcodeInformation.propTypes = {
  fromContainer: PropTypes.string,
  unitNumber: PropTypes.string,
  serialNumber: PropTypes.string,
  trackSerial: PropTypes.bool,
  fromLocation: PropTypes.string
}

export default React.memo(BarcodeInformation)
