import React from 'react'
import { Ordering } from 'storfox-filter'

import { FILTER_NAME as name } from '../../../constants/SalesMargin'
import { SALES_MARGIN_COLUMNS as tableColumns } from '../../../components/SalesMargin/SalesMarginList/SalesMarginTable'

export const salesMarginOrderingOptions = {
  name,
  tableColumns
}

export function SalesMarginOrderingForm (props) {
  return (
    <Ordering {...props} />
  )
}
