import React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

import Tabs from '~/components/Tabs'
import Divider from '~/components/Divider'
import { RETURN_DETAIL_PATH } from '~/constants/routes'
import { SALE_ORDER_DETAIL_TABS } from '~/constants/tabs'

const tabs = [
  { value: SALE_ORDER_DETAIL_TABS.GENERAL, label: 'General' },
  { value: SALE_ORDER_DETAIL_TABS.HISTORY, label: 'History' }
]

function ReturnDetailTabs ({ value }) {
  const navigate = useNavigate()
  const { guid } = useParams()

  const onChange = (event, tab) => {
    const redirect = generatePath(RETURN_DETAIL_PATH, { guid, tab })
    navigate(redirect, { replace: true })
  }

  return (
    <>
      <Tabs
        tabs={tabs}
        value={value}
        onChange={onChange}
      />
      <Divider />
    </>
  )
}

ReturnDetailTabs.propTypes = {
  value: PropTypes.string.isRequired
}

export default ReturnDetailTabs
