import React from 'react'
import PropTypes from 'prop-types'

import HtmlLayout from '../Layouts/HtmlLayout'

function AppContainer ({ title, children, ...rest }) {
  return (
    <HtmlLayout title={title}>
      <div {...rest} style={{ padding: '0 0 0 0', width: '100%' }}>
        {children}
      </div>
    </HtmlLayout>
  )
}

AppContainer.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
}

export default AppContainer
