import React from 'react'
import { useFilter, useOrdering } from 'storfox-filter'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import ErrorLink from '~/components/ErrorLink'
import useMessages from '~/hooks/useMessages'

import SalesMarginTable from '../../components/SalesMargin/SalesMarginList/SalesMarginTable'
import {
  SalesMarginFilterForm,
  salesMarginFilterOptions
} from '../../components/SalesMargin/SalesMarginList/SalesMarginFilterForm'
import {
  SalesMarginOrderingForm,
  salesMarginOrderingOptions
} from '../../components/SalesMargin/SalesMarginList/SalesMarginOrderingForm'
import { useSaleMarginList, useSalesExport } from '../../hooks/SalesMargin'

function SalesMarginListContainer () {
  const salesMarginList = useSaleMarginList()
  const salesExport = useSalesExport()
  const messages = useMessages()
  const snackbar = useSnackbar()

  const filter = useFilter(salesMarginFilterOptions)
  const ordering = useOrdering(salesMarginOrderingOptions)

  const breadcrumbs = { title: NAV.SALES_OVERVIEW }

  const handleSalesExport = () =>
    salesExport.export()
      .then(() => snackbar({ message: messages.NOTIFICATION_WAIT }))
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  return (
    <DashboardLayout
      title={NAV.SALES_MARGIN_REPORT}
      activeNav={NAV.REPORTS}
      breadcrumbs={breadcrumbs}
    >
      <SalesMarginFilterForm {...filter} />
      <SalesMarginOrderingForm {...ordering} />

      <SalesMarginTable
        onListRefetch={salesMarginList.getListByParams}
        filter={filter}
        ordering={ordering}
        list={salesMarginList}
        onSalesExport={handleSalesExport}
      />
    </DashboardLayout>
  )
}

export default SalesMarginListContainer
