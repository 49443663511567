import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAllSearchParams } from 'storfox-route-hooks'
import { prop } from 'ramda'
import { useDeepCompareEffect } from 'storfox-api-hooks'
import { useSnackbar } from 'storfox-snackbar'

import { escapeAtob, unescapeBtoa } from '~/utils'
import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import { useProgressCard } from '~/components/ProgressCard'
import { useNotification } from '~/components/Notification'
import {
  PURCHASE_ORDERS_BULK_CREATE_COMPLETE,
  PURCHASE_ORDERS_BULK_CREATE_STATUS,
  PURCHASE_ORDERS_BULK_VALIDATE_COMPLETE,
  PURCHASE_ORDERS_BULK_VALIDATE_STATUS
} from '~/constants/notification-topics'
import { useGoogleEvent } from '~/components/GoogleAnalytics/GoogleAnalytics'
import useMessages from '~/hooks/useMessages'

import { usePurchaseOrderImport } from '../hooks'
import PurchaseOrderImport from '../components/PurchaseOrderImport'

function PurchaseOrderImportContainer () {
  const purchaseOrderImport = usePurchaseOrderImport()
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const messages = useMessages()
  const { payload } = useAllSearchParams()
  const progressCard = useProgressCard()
  const { sendEvent } = useGoogleEvent()

  const validateStatus = useNotification(PURCHASE_ORDERS_BULK_VALIDATE_STATUS)
  const validateComplete = useNotification(PURCHASE_ORDERS_BULK_VALIDATE_COMPLETE)
  const uploadComplete = useNotification(PURCHASE_ORDERS_BULK_CREATE_COMPLETE)
  const uploadStatus = useNotification(PURCHASE_ORDERS_BULK_CREATE_STATUS)

  const key = prop('key', escapeAtob(payload))

  useDeepCompareEffect(() => {
    if (key) {
      validateStatus.subscribe(key, ({ payload }) => {
        const result = prop('result', payload)
        const validated = prop('totalValidated', result)
        const total = prop('total', result)

        progressCard.handleValidationChange({ current: validated, total })
      })

      validateComplete.subscribe(key, ({ payload }) => {
        navigate(
          `${ROUTES.PURCHASE_ORDER_IMPORT_PATH}?payload=${unescapeBtoa(payload)}`
          , { replace: true })

        validateComplete.setLoading(false)
      })

      uploadStatus.subscribe(key, ({ payload }) => {
        const result = prop('result', payload)
        const completed = prop('totalCreated', result)
        const total = prop('total', result)
        if (!uploadComplete.isLoading) {
          uploadComplete.setLoading(true)
          progressCard.handleValidationChange({ current: total, total })
        }
        progressCard.handleCompletionChange({ current: completed, total })
      })

      uploadComplete.subscribe(key, () => {
        navigate(ROUTES.PURCHASE_ORDER_LIST_PATH, { replace: true })
        uploadComplete.setLoading(false)
        sendEvent({ eventAction: 'Upload File Purchase Order', eventCategory: 'Purchase Order' })
      })
    }

    return () => {
      validateStatus.unsubscribe()
      validateComplete.unsubscribe()
    }
  }, [key])

  const handleSubmit = () => {
    uploadComplete.setLoading(true)
    return purchaseOrderImport.create({ key })
      .then(() => snackbar({ message: messages.IMPORT_REQUEST_SUCCESS }))
  }

  const handleSubscribe = key => {
    snackbar({ message: messages.NOTIFICATION_WAIT })
    validateComplete.setLoading(true)
    navigate(`${ROUTES.PURCHASE_ORDER_IMPORT_PATH}?payload=${unescapeBtoa({ key })}`, { replace: true })
    sendEvent({ eventAction: 'Validate File Purchase Order', eventCategory: 'Purchase Order' })
  }

  const handleFileRemove = () => {
    validateComplete.unsubscribe()
    uploadComplete.unsubscribe()
    validateStatus.unsubscribe()
    uploadStatus.unsubscribe()
    progressCard.resetValues()
  }

  const title = 'Import purchase orders'

  const isLoading = (
    purchaseOrderImport.isLoading ||
    validateComplete.isLoading ||
    uploadComplete.isLoading
  )

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      activeNav={NAV.PURCHASE_ORDER}
      isLoading={validateComplete.isLoading || uploadComplete.isLoading}
      breadcrumbs={breadcrumbs}
    >
      <PurchaseOrderImport
        title={title}
        isLoading={isLoading}
        progressCard={progressCard}
        onSubmit={handleSubmit}
        onSubscribe={handleSubscribe}
        onFileRemove={handleFileRemove}
      />
    </DashboardLayout>
  )
}

export default PurchaseOrderImportContainer
