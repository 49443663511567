import React, { useState } from 'react'
import { descend, map, prop, propOr, sort } from 'ramda'
import PropTypes from 'prop-types'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import TableContainer from '@mui/material/TableContainer'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import InfoIcon from '@mui/icons-material/Info'
import { IconButton } from '@mui/material'

import { CardHeader } from '~/components/Cards'
import { getFullName } from '~/utils'
import HtmlTooltip from '~/components/HtmlTooltip/HtmlTooltip'

import PickerChart from './PickerChart'
import PackerChart from './PackerChart'
import PickerPackerTabs, { PACKER_STATISTIC_TAB, PICKER_STATISTIC_TAB } from './PickerPackerTabs'

const getLabelsAndPickerStatistics = items => {
  const sortedItems = sort(descend(prop('rank')), items)

  const labels = map(item => getFullName(prop('picker', item)), sortedItems)
  const pickerLevels = map(prop('score'), sortedItems)

  return { labels, pickerLevels }
}

const getLabelsAndPackerStatistics = items => {
  const sortedItems = sort(descend(prop('rank')), items)

  const labels = map(item => getFullName(prop('packer', item)), sortedItems)
  const packerLevels = map(prop('score'), sortedItems)

  return { labels, packerLevels }
}

function PickerStatistics ({ pickerStatistic, packerStatistic }) {
  const { t } = useTranslation()
  const [tab, setTab] = useState(PICKER_STATISTIC_TAB)

  const pickerResults = propOr([], 'results', pickerStatistic)
  const packerResults = propOr([], 'results', packerStatistic)

  const { labels: pickerLabels, pickerLevels } = getLabelsAndPickerStatistics(pickerResults)
  const { labels: packerLabels, packerLevels } = getLabelsAndPackerStatistics(packerResults)

  return (
    <Card>
      <CardHeader
        title={
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h4">{t('Top 10 Picker & Packer')}</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <PickerPackerTabs value={tab} onChange={(e, tab) => setTab(tab)} />
              <HtmlTooltip
                title="Picker and packer graph will contain tooltip,
                 displaying: Create separate IDs for pickers and packers."
              >
                <IconButton size="small">
                  <InfoIcon sx={{ color: '#BDBDBD' }} />
                </IconButton>
              </HtmlTooltip>
            </Box>
          </Box>
        }
        sx={{ paddingBottom: 0 }}
      />
      <Divider />
      <CardContent sx={{ p: 0 }}>
        <TableContainer>
          <Box sx={{ minWidth: 700 }}>
            {tab === PICKER_STATISTIC_TAB && (
              <PickerChart
                data={pickerLevels}
                labels={pickerLabels}
                sx={{
                  padding: theme => theme.spacing(4, 2, 0, 2),
                  height: 400
                }}
              />
            )}
            {tab === PACKER_STATISTIC_TAB && (
              <PackerChart
                data={packerLevels}
                labels={packerLabels}
                sx={{
                  padding: theme => theme.spacing(4, 2, 0, 2),
                  height: 400
                }}
              />
            )}
          </Box>
        </TableContainer>
      </CardContent>
    </Card>
  )
}

PickerStatistics.propTypes = {
  pickerStatistic: PropTypes.object,
  packerStatistic: PropTypes.object,
}

export default PickerStatistics
