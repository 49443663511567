import React from 'react'
import PropTypes from 'prop-types'
import { always } from 'ramda'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import Copy from '~/components/Copy'
import useMessages from '~/hooks/useMessages'

import Button from './Button'

function CopyButton ({ textToCopy, onCopy, children, ...props }) {
  const messages = useMessages()
  const snackbar = useSnackbar()

  return (
    <Copy textToCopy={textToCopy}>
      {onClick => {
        const handleClick = event =>
          onClick(event)
            .then(() => snackbar({ message: messages.COPY_SUCCESS }))
            .catch(() => snackbar({ message: messages.COPY_FAIL, type: ALTER_ERROR }))

        return (
          <Button
            startIcon={<FileCopyIcon />}
            onClick={handleClick}
            variant="outlined"

            {...props}
          >
            {children}
          </Button>
        )
      }
      }
    </Copy>
  )
}

CopyButton.defaultProps = {
  onCopy: always(null),
  children: 'Copy'
}

CopyButton.propTypes = {
  children: PropTypes.any.isRequired,
  onCopy: PropTypes.func.isRequired,
  textToCopy: PropTypes.string.isRequired
}

export default CopyButton
