import React from 'react'
import AlertTitle from '@mui/material/AlertTitle'
import Alert from '@mui/material/Alert'

function OrderSplitInstruction () {
  return (
    <Alert severity="warning" icon={false}>
      <AlertTitle>Warning</AlertTitle>
      Bundles will be also split among warehouses
    </Alert>
  )
}

export default OrderSplitInstruction
