import { defaultTo, filter, map, pipe, prop, propOr } from 'ramda'

const getEnabledColumns = pipe(
  defaultTo([]),
  filter(prop('enabled')),
  map(prop('key'))
)

export function FilterCreateSerializer ({ enabled, ...formValues }) {
  const group = prop('group', formValues)
  const name = prop('name', formValues)
  const values = JSON.stringify(prop('values', formValues))
  const columns = getEnabledColumns(formValues.columns)

  return {
    group,
    name,
    type: enabled ? 'public' : 'private',
    values,
    columns
  }
}

export function FilterUpdateSerializer ({ position, enabled, ...formValues }) {
  const name = prop('name', formValues)
  const values = JSON.stringify(prop('values', formValues))
  const columns = getEnabledColumns(formValues.columns)

  return {
    name,
    type: enabled ? 'public' : 'private',
    values,
    columns
  }
}

export function FilterOrderSerializer (values) {
  const filterValues = propOr([], 'filters', values)

  return {
    positions: filterValues.map((item, index) => ({ id: item.id, position: index, visible: item.visible }))
  }
}

export function ColumnVisibilitySerializer ({ name, params, columns = [] }) {
  return {
    group: name,
    columns: columns.map((item, index) => ({
      id: item.id,
      key: item.field,
      position: index,
      visible: propOr(true, item.field, params),
      width: item.width
    }))
  }
}

export function ColumnOrderSerializer ({ name, columns = [] }) {
  return {
    group: name,
    columns: columns.map((item, index) => ({
      key: item.field,
      position: index,
      visible: item.visible,
      width: item.width
    }))
  }
}

export function ColumnWidthUpdateSerializer ({ name, column }) {
  const width = prop('width', column)
  const key = prop('field', column)

  return { group: name, key, width }
}
