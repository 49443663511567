import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { path } from 'ramda'
import { useSnackbar } from 'storfox-snackbar'
import { useTranslation } from 'react-i18next'

import { DashboardLayout } from '~/components/Layouts'
import { useMe, useProfile } from '~/components/Profile'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import { WAREHOUSE_TABS } from '~/constants/tabs'

import WarehouseUpdateForm from '../components/WarehouseUpdate'
import { useWarehouseDetail, useWarehouseUpdate } from '../hooks'
import { WarehouseCreateSerializer } from '../serializers'

function WarehouseUpdateContainer () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { guid } = useParams()
  const snackbar = useSnackbar()
  const warehouseDetail = useWarehouseDetail(guid)
  const warehouseUpdate = useWarehouseUpdate(guid)
  const { getMe } = useMe()
  const { setProfile } = useProfile()

  const message = t('Successfully updated')

  const handleSubmit = formValues =>
    warehouseUpdate.update(WarehouseCreateSerializer(formValues))
      .then(() => navigate(ROUTES.WAREHOUSE_LIST_PATH))
      .then(() => snackbar({ message }))
      .then(() => getMe())
      .then(({ result }) => setProfile(result))

  const name = path(['detail', 'name'], warehouseDetail)
  const title = warehouseDetail.isLoading ? 'Warehouses' : name

  const breadcrumbs = {
    title: 'Edit',
    params: [
      { guid, tab: WAREHOUSE_TABS.GENERAL, title },
    ]
  }

  return (
    <DashboardLayout
      title={NAV.WAREHOUSE}
      activeNav={NAV.WAREHOUSE}
      breadcrumbs={breadcrumbs}
      isLoading={warehouseDetail.isLoading}
    >
      <WarehouseUpdateForm
        pageTitle={title}
        pageTitleLoading={warehouseDetail.isLoading}
        initialValues={warehouseDetail.detail}
        onSubmit={handleSubmit}
        isLoading={warehouseUpdate.isLoading}
      />
    </DashboardLayout>
  )
}

export default WarehouseUpdateContainer
