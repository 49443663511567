import React from 'react'
import PropTypes from 'prop-types'

import TableSplitIconButton from '~/components/TableSplitButton/TableSplitIconButton'
import useDeleteConfirm from '~/components/TableSplitButton/useDeleteConfirm'

function BulkActionButton ({ onLocationPrint, onLocationsDelete }) {
  const { handleClick } = useDeleteConfirm()

  const options = [
    {
      label: 'Print barcodes',
      'data-cy': 'printBarcodesMenu',
      onMenuClick: onLocationPrint
    },
    {
      label: 'Delete',
      'data-cy': 'deleteMenu',
      onMenuClick: () => handleClick(onLocationsDelete)
    }
  ]

  return (
    <TableSplitIconButton
      options={options}
      name="location"
    />
  )
}

BulkActionButton.propTypes = {
  onLocationPrint: PropTypes.func.isRequired,
  onLocationsDelete: PropTypes.func.isRequired
}

export default BulkActionButton
