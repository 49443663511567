import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import RoleListContainer from './containers/RoleListContainer'
import RoleCreateContainer from './containers/RoleCreateContainer'
import RoleUpdateContainer from './containers/RoleUpdateContainer'

export default () => {
  return [
    {
      layout: AuthLayout,
      path: ROUTES.ROLE_CREATE_PATH,
      component: RoleCreateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.ROLE_UPDATE_PATH,
      component: RoleUpdateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.ROLE_LIST_PATH,
      component: RoleListContainer
    },
  ]
}
