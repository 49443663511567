import { defaultTo, path, pick, pipe, pluck, prop, trim } from 'ramda'

import { LOCATION_CREATE_FIELDS } from '../components/LocationCreate'
import { LOCATION_UPDATE_FIELDS } from '../components/LocationUpdate'

const getWarehouse = path(['warehouse', 'guid'])
const getArea = path(['area', 'guid'])
const getZone = path(['zone', 'guid'])
const getAllowedOperations = pipe(
  prop('allowedOperations'),
  defaultTo([]),
  pluck('value')
)

export function LocationCreateSerializer (formValues) {
  const warehouseGuid = getWarehouse(formValues)
  const areaGuid = getArea(formValues)
  const zoneGuid = getZone(formValues)
  const allowedOperations = getAllowedOperations(formValues)
  const volume = prop('vol', formValues)
  const locationAlias = prop('locationAlias', formValues)
  const trimmedLocationAlias = locationAlias && trim(locationAlias)
  const data = {
    ...formValues,
    allowedOperations,
    volume,
    locationAlias: trimmedLocationAlias,
    warehouse: warehouseGuid && { guid: warehouseGuid },
    area: areaGuid && { guid: areaGuid },
    zone: zoneGuid && { guid: zoneGuid },
  }

  return pick(LOCATION_CREATE_FIELDS, data)
}

export function LocationUpdateSerializer (formValues) {
  const warehouseGuid = getWarehouse(formValues)
  const areaGuid = getArea(formValues)
  const zoneGuid = getZone(formValues)
  const allowedOperations = getAllowedOperations(formValues)
  const volume = prop('vol', formValues)
  const locationAlias = trim(prop('locationAlias', formValues))
  const data = {
    ...formValues,
    allowedOperations,
    volume,
    locationAlias,
    warehouse: warehouseGuid && { guid: warehouseGuid },
    area: areaGuid && { guid: areaGuid },
    zone: zoneGuid && { guid: zoneGuid },
  }

  return pick(LOCATION_UPDATE_FIELDS, data)
}
