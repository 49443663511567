import React from 'react'
import { find, length, path, prop } from 'ramda'
import PropTypes from 'prop-types'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Grid from '@mui/material/Grid'
import Dialog from '@mui/material/Dialog'
import Skeleton from '@mui/material/Skeleton'

import ChannelCompanyBox from './ChannelCompanyBox'

import DialogTitle from '../DialogTitle/DialogTitle'
import Button from '../Buttons/Button'
import SelectableBox from '../SelectableBox'

function ChannelDialog (props) {
  const {
    open,
    onClose,
    selectedChannelGuids,
    onChannelSelect,
    onSubmit,
    isLoading,
    channelList
  } = props

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth={true}
      onClose={onClose}
    >
      <DialogTitle>Choose channel</DialogTitle>

      <DialogContent dividers={true}>
        <Grid container={true} spacing={3}>
          {!isLoading
            ? channelList.map(channel => {
              const guid = prop('guid', channel)
              const name = prop('name', channel)
              const company = path(['company', 'name'], channel)
              const type = prop('type', channel)

              return (
                <Grid item={true} sm={12} lg={6} key={guid}>
                  <SelectableBox
                    onClick={() => onChannelSelect(guid)}
                    selected={Boolean(find(selectedGuid => selectedGuid === guid, selectedChannelGuids))}
                  >
                    <ChannelCompanyBox
                      name={name}
                      company={company}
                      type={type}
                    />
                  </SelectableBox>
                </Grid>
              )
            })
            : (
              <>
                <Grid item={true} sm={12} lg={6}>
                  <Skeleton width="100%" height="78px" />
                </Grid>
                <Grid item={true} sm={12} lg={6}>
                  <Skeleton width="100%" height="78px" />
                </Grid>
              </>
            )}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>
          Close
        </Button>
        <Button
          onClick={onSubmit}
          disabled={!length(selectedChannelGuids)}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ChannelDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChannelSelect: PropTypes.func.isRequired,
  selectedChannelGuids: PropTypes.array.isRequired,
  channelList: PropTypes.array,
  isLoading: PropTypes.bool
}

export default ChannelDialog
