export const NEW = 'new'
export const PENDING = 'pending'
export const IN_PROGRESS = 'in_progress'
export const COMPLETED = 'completed'
export const CANCELLED = 'cancelled'

export const STATUSES = {
  [NEW]: 'New',
  [PENDING]: 'Pending',
  [IN_PROGRESS]: 'In progress',
  [COMPLETED]: 'Completed',
  [CANCELLED]: 'Cancelled'
}

export const FILTER_NAME = 'inventory_log'
