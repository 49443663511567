import React from 'react'
import { prop } from 'ramda'

import EditableSearchField from '~/components/Fields/EditableSearchField'
import * as API from '~/constants/api'

const getOptionLabel = item => {
  if (item) {
    const firstName = prop('firstName', item)
    const lastName = prop('lastName', item)
    return `${firstName} ${lastName}`
  }

  return ''
}

const getOptionValue = item => {
  if (item) {
    const guid = prop('guid', item)
    const firstName = prop('firstName', item)
    const lastName = prop('lastName', item)

    return { guid, firstName, lastName }
  }

  return null
}

function PutawayUserSearchField (props) {
  return (
    <EditableSearchField
      name="user"
      label="User"
      primaryKey="guid"
      api={API.V1_PUTAWAY_USERS_LIST}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      {...props}
    />
  )
}

export default PutawayUserSearchField
