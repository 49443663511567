import { defaultTo, map, path, pick, pipe, prop } from 'ramda'

import { PROFILE_UPDATE_FIELDS } from '../components/ProfileUpdate'

const getRoles = pipe(
  prop('roles'),
  defaultTo([]),
  map(pick(['id']))
)

const getWarehouses = pipe(
  prop('warehouses'),
  defaultTo([]),
  map(pick(['id']))
)

export function ProfileUpdateSerializer (formValues) {
  const roles = getRoles(formValues)
  const warehouses = getWarehouses(formValues)
  const status = 'active'

  const values = { ...formValues, roles, warehouses, status }

  return pick(PROFILE_UPDATE_FIELDS, values)
}

export const SignUpContractSerializer = formValues => {
  const contractNumber = path(['contractInfo', 'contractNumber'], formValues)

  return { ...formValues, contractNumber }
}
