import React from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Link from '@mui/material/Link'
import Card from '@mui/material/Card'
import PropTypes from 'prop-types'

import Avatar from '../Avatar/Avatar'
import TextOverflow from '../TextOverflow'

function CustomerInfoCard ({ avatar, fullName, link, email, phone }) {
  return (
    <Card variant="outlined">
      <List>
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar src={avatar} alt={fullName} />
          </ListItemAvatar>
          <ListItemText
            primary={
              <Link
                target="_blank"
                data-cy="customerName"
                href={link}
              >
                <TextOverflow selfTooltip={true} lines={1}>
                  {fullName}
                </TextOverflow>
              </Link>
            }
            secondary={
              <>
                <TextOverflow selfTooltip={true} lines={1}>
                  {email}
                </TextOverflow>
                {phone}
              </>
            }
            secondaryTypographyProps={{ component: 'span' }}
          />
        </ListItem>
      </List>
    </Card>
  )
}

CustomerInfoCard.propTypes = {
  avatar: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired
}

export default CustomerInfoCard
