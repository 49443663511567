import PropTypes from 'prop-types'
import React from 'react'
import { Dialog, DialogActions, DialogContent } from '@mui/material'

import DialogTitle from '~/components/DialogTitle'
import { Button, DiscardButton } from '~/components/Buttons'
import { withForm } from '~/components/Form'

import ShipmentField from './ShipmentField'

function ShipmentDialogForm ({ open, form, onClose, id, isLoading }) {
  const { handleSubmit } = form

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>Select Shipment</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent dividers={true} sx={{ minHeight: '30vh' }}>
          <ShipmentField params={{ objectIds: id }} />
        </DialogContent>
        <DialogActions>
          <DiscardButton
            onClick={onClose}
            fullWidth={true}
          >
            Discard
          </DiscardButton>

          <Button
            variant="contained"
            fullWidth={true}
            type="submit"
            data-cy="done"
            disabled={isLoading}
          >
            Generate
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

ShipmentDialogForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default withForm(ShipmentDialogForm)
