import React from 'react'
import PropTypes from 'prop-types'

import Status from '~/components/Status'

export const PAID = 'paid'
export const UNPAID = 'unpaid'
export const PARTIALLY_PAID = 'partially_paid'

const NAMES = {
  [PAID]: 'Paid',
  [UNPAID]: 'Unpaid',
  [PARTIALLY_PAID]: 'Partially paid'
}

const COLORS = {
  [UNPAID]: 'error',
  [PAID]: 'success',
  [PARTIALLY_PAID]: 'warning'
}

function RefundStatus ({ value }) {
  return (
    <Status label={NAMES[value]} color={COLORS[value]} />
  )
}

RefundStatus.propTypes = {
  value: PropTypes.string.isRequired
}

export default RefundStatus
