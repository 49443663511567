import React from 'react'

import SelectField from '~/components/Fields/SelectField'

const OPTIONS = [{ value: true, name: 'Tracking' }, { value: false, name: 'No Tracking' }]

function TrackingStatusField (props) {
  return (
    <SelectField
      name="isTracking"
      label="Tracking"
      options={OPTIONS}
      {...props}
    />
  )
}

export default TrackingStatusField
