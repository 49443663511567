import React from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { Grid, styled } from '@mui/material'
import Box from '@mui/material/Box'

import Container, { Content, Header } from '~/components/Container'
import PageTitle from '~/components/PageTitle'
import { DiscardButton, SaveButton } from '~/components/Buttons'
import { withForm } from '~/components/Form'
import * as NAV from '~/constants/nav-titles'
import { VARIANT_UPDATE_TABS } from '~/constants/tabs'

import VariantUpdateTabs from './VariantUpdateTabs'
import VariantGeneralForm from './General/VariantGeneralForm'
import VariantListItems from './VariantListItems'
import VariantInternational from './International'

const ContainerBoxStyled = styled(Box, {
  shouldForwardProp: (propName) => propName !== 'isMultiVariant'
})(({ theme, isMultiVariant }) => ({
  ...(isMultiVariant && {
    paddingRight: 0,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 300,
      maxWidth: '100%'
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0
    }
  })
}))

function VariantUpdate (props) {
  const {
    results,
    isLoading,
    variantId,
    pageTitle,
    pageTitleLoading,
    isMultiVariant,
    variantPriceList,
    onPricesUpdate,
    form
  } = props

  const { initialValues } = form
  const { tab } = useParams()

  const { handleSubmit } = form

  return (
    <Box display="flex" height="100%">
      {isMultiVariant && (
        <VariantListItems
          results={results}
          variantId={variantId}
          tab={tab}
          edit={true}
        />
      )}
      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
        <ContainerBoxStyled isMultiVariant={isMultiVariant}>
          <Container>
            <Header>
              <PageTitle
                pageTitle={pageTitle}
                pageTitleLoading={pageTitleLoading}
                parentTitle={NAV.VARIANTS}
                rightButton={
                  <>
                    <DiscardButton disabled={isLoading} />
                    <SaveButton disabled={isLoading} />
                  </>
                }
              />
            </Header>

            <Content>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <VariantUpdateTabs
                    value={tab}
                    isMultiVariant={isMultiVariant}
                  />
                </Grid>
                {tab === VARIANT_UPDATE_TABS.GENERAL && (
                  <VariantGeneralForm
                    initialValues={initialValues}
                    variantPriceList={variantPriceList}
                    onPricesUpdate={onPricesUpdate}
                    isLoading={isLoading}
                  />
                )}

                {tab === VARIANT_UPDATE_TABS.INTERNATIONAL && <VariantInternational />}
              </Grid>
            </Content>
          </Container>
        </ContainerBoxStyled>
      </form>
    </Box>
  )
}

VariantUpdate.propTypes = {
  results: PropTypes.array.isRequired,
  form: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  variantId: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  pageTitleLoading: PropTypes.bool.isRequired,
  isMultiVariant: PropTypes.bool.isRequired,
  variantPriceList: PropTypes.object.isRequired,
  onPricesUpdate: PropTypes.func.isRequired,
}

export default withForm(VariantUpdate)
