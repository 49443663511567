import React from 'react'
import { Filter } from 'storfox-filter'
import Grid from '@mui/material/Grid'
import { prop } from 'ramda'
import { DateRangeField } from 'storfox-form-fields'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

import { escapeAtob, getSeparatedDateRange, unescapeBtoa } from '~/utils'
import Accordion from '~/components/Accordion'
import AreaField from '~/components/Fields/AreaField'
import WarehouseSearchField from '~/components/Fields/WarehouseSearchField'
import ZoneField from '~/components/Fields/ZoneField'

import StockCountStatusField from '../Fields/StockCountStatusField'
import { FILTER_NAME as name } from '../../constants'

export const toParams = values => {
  const status = prop('status', values)
  const areaGuid = unescapeBtoa(prop('areaGuid', values))
  const warehouse = unescapeBtoa(prop('warehouse', values))
  const zoneGuid = unescapeBtoa(prop('zoneGuid', values))

  const [createdAtStart, createdAtEnd] = getSeparatedDateRange(prop('createdAt', values))

  return {
    status,
    areaGuid,
    warehouse,
    zoneGuid,
    createdAtStart,
    createdAtEnd
  }
}

export const toValues = params => {
  const status = prop('status', params)
  const areaGuid = escapeAtob(prop('areaGuid', params))
  const warehouse = escapeAtob(prop('warehouse', params))
  const zoneGuid = escapeAtob(prop('zoneGuid', params))

  const createdAt = [prop('createdAtStart', params), prop('createdAtEnd', params)]

  return {
    status,
    areaGuid,
    warehouse,
    zoneGuid,
    createdAt
  }
}

export const transformer = { toParams, toValues }

export const stockCountFilterOptions = {
  name,
  transformer
}

function StockCountFilterForm (props) {
  const { t } = useTranslation()
  return (
    <Filter {...props}>
      <Accordion title="General" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <StockCountStatusField />
          </Grid>
          <Grid item={true} xs={12}>
            <WarehouseSearchField />
          </Grid>
          <Grid item={true} xs={12}>
            <AreaField
              disabled={false}
              data-cy="areaGuid"
              name="areaGuid"
              warehouse="warehouses"
              label="Area"
            />
          </Grid>
          <Grid item={true} xs={12}>
            <ZoneField
              disabled={false}
              data-cy="zoneGuid"
              name="zoneGuid"
              label="Zone"
              area="area"
            />
          </Grid>
        </Grid>
      </Accordion>
      <Accordion title="Date">
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <Typography variant="body2" gutterBottom={true}>
              {t('Created')}:
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <DateRangeField name="createdAt" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Accordion>
    </Filter>
  )
}

export default StockCountFilterForm
