import React from 'react'
import { IconButton, Tooltip } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import PropTypes from 'prop-types'
import { getCancelToken, useDeepCompareEffect } from 'storfox-api-hooks'
import { useField } from 'react-final-form'

import useDebounce from '~/hooks/useDebounce'
import TextField from '~/components/Fields/TextField'

function InfinityScrollSearch ({ onEffect, getList, params }) {
  const searchField = useField('search')
  const searchFieldValue = searchField.input.value
  const debouncedValue = useDebounce(searchFieldValue)

  useDeepCompareEffect(() => {
    const { token, cancel } = getCancelToken()
    onEffect()
    getList({ search: searchFieldValue, page: 1, ...params }, { cancelToken: token })
    return () => {
      cancel()
    }
  }, [debouncedValue, params])

  return (
    <TextField
      data-cy="searchVariant"
      name="search"
      label="Search"
      size="medium"
      autoFocus={true}
      InputProps={{
        endAdornment: (
          <Tooltip title="Search">
            <IconButton
              edge="end"
              size="medium"
              sx={{ margin: 0, padding: '5px' }}
            >
              <SearchIcon />
            </IconButton>
          </Tooltip>
        )
      }}
    />
  )
}

InfinityScrollSearch.propTypes = {
  getList: PropTypes.func.isRequired,
  onEffect: PropTypes.func,
  params: PropTypes.object
}

export default InfinityScrollSearch
