import { useCreate, useDetail, useList, useUpdate } from 'storfox-api-hooks'
import { sprintf } from 'sprintf-js'
import { DEFAULT_SEARCH_PARAMS, usePickSearchParams } from 'storfox-route-hooks'

import { orderingToSnackCase } from '~/utils'
import * as API from '~/constants/api'

export const useBillingProfileCreate = () => {
  return useCreate(API.BILLING_PROFILE_CREATE)
}

export const useBillingProfileList = () => {
  const searchParams = usePickSearchParams([...DEFAULT_SEARCH_PARAMS, 'status'])
  return useList(API.BILLING_PROFILE_LIST, orderingToSnackCase(searchParams))
}

export const useBillingProfileUpdate = guid => {
  const url = sprintf(API.BILLING_PROFILE_UPDATE, guid)

  return useUpdate(url)
}

export const useBillingProfileDetail = guid => {
  const url = sprintf(API.BILLING_PROFILE_DETAIL, guid)

  return useDetail(url)
}
