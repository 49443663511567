import React from 'react'
import PropTypes from 'prop-types'

import { Loader } from '~/components/Loader'
import TextLabel from '~/components/TextLabel'

function BooleanLabel ({ label, children, ...props }) {
  return (
    <Loader>
      {isLoading => (
        <TextLabel isLoading={isLoading} {...props} children={children ? 'Enabled' : 'Disabled'} label={label} />
      )}
    </Loader>
  )
}

BooleanLabel.propTypes = {
  children: PropTypes.bool,
  label: PropTypes.string.isRequired
}

export default BooleanLabel
