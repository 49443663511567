import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import { IntegerField, PrependField } from 'storfox-form-fields'
import { useFormState } from 'react-final-form'

import { DiscardButton, SaveButton } from '~/components/Buttons'
import { withForm } from '~/components/Form'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import * as NAV from '~/constants/nav-titles'
import { CardHeader } from '~/components/Cards'
import WarehouseSearchField from '~/components/Fields/WarehouseSearchField'
import AreaField from '~/components/Fields/AreaField'
import ZoneField from '~/components/Fields/ZoneField'
import TextField from '~/components/Fields/TextField'
import DimensionField from '~/components/Fields/DimensionField'
import WeightField from '~/components/Fields/WeightField'
import VolumeField from '~/components/Fields/VolumeField'
import StatusField from '~/components/Fields/StatusField'
import PositiveNumberField from '~/components/Fields/PositiveNumberField'

import LocationTypeField from '../Fields/LocationTypeField'
import OperationsField from '../Fields/OperationsField'
import Title from '../Title'

export const LOCATION_UPDATE_FIELDS = [
  'warehouse',
  'area',
  'zone',
  'aisle',
  'bay',
  'level',
  'bin',
  'length',
  'height',
  'width',
  'maxLoad',
  'volume',
  'locationAlias',
  'locationType',
  'allowedOperations',
  'status',
  'id',
  'x',
  'y',
  'z',
  'pickingOrder'
]

function LocationUpdateForm (props) {
  const {
    pageTitleLoading,
    isLoading,
    form
  } = props

  const formState = useFormState()

  return (
    <form onSubmit={form.handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={<Title titleValues={formState.values} />}
            pageTitleLoading={pageTitleLoading}
            parentTitle={NAV.LOCATIONS}
            rightButton={(
              <>
                <DiscardButton disabled={isLoading} />
                <SaveButton disabled={isLoading} />
              </>
            )}
          />
        </Header>

        <Content>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12} lg={9}>
              <Box mb={3}>
                <Card>
                  <CardHeader title="Area/Zone" />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12} lg={4}>
                        <WarehouseSearchField disabled={true} />
                      </Grid>

                      <Grid item={true} xs={12} lg={4}>
                        <AreaField
                          data-cy="area"
                          name="area"
                          label="Area"
                          disabled={true}
                          warehouse="warehouse"
                        />
                      </Grid>

                      <Grid item={true} xs={12} lg={4}>
                        <ZoneField
                          data-cy="zone"
                          name="zone"
                          label="Zone"
                          area="area"
                          disabled={true}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>

              <Box mb={3}>
                <Card>
                  <CardHeader title="Shelf/Bin" />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12} lg={3}>
                        <PrependField
                          data-cy="aisle"
                          name="aisle"
                          label="Aisle"
                          prependChar="0"
                          valueLength={3}
                          inputProps={{ maxLength: 3 }}
                        />
                      </Grid>

                      <Grid item={true} xs={12} lg={3}>
                        <PrependField
                          data-cy="bay"
                          name="bay"
                          label="Bay/Rack"
                          prependChar="0"
                          valueLength={3}
                          inputProps={{ maxLength: 3 }}
                        />
                      </Grid>

                      <Grid item={true} xs={12} lg={3}>
                        <PrependField
                          data-cy="level"
                          name="level"
                          label="Level/Shelf"
                          prependChar="0"
                          valueLength={3}
                          inputProps={{ maxLength: 3 }}
                        />
                      </Grid>

                      <Grid item={true} xs={12} lg={3}>
                        <PrependField
                          data-cy="bin"
                          name="bin"
                          label="Bin/Position"
                          prependChar="0"
                          valueLength={3}
                          inputProps={{ maxLength: 3 }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>

              <Box mb={3}>
                <Card>
                  <CardHeader title="Dimension" />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>

                      <Grid item={true} xs={12} lg={4}>
                        <DimensionField
                          data-cy="height"
                          name="height"
                          label="Height"
                        />
                      </Grid>

                      <Grid item={true} xs={12} lg={4}>
                        <DimensionField
                          data-cy="width"
                          name="width"
                          label="Width"
                        />
                      </Grid>

                      <Grid item={true} xs={12} lg={4}>
                        <DimensionField
                          data-cy="length"
                          name="length"
                          label="Length"
                        />
                      </Grid>

                      <Grid item={true} xs={12} lg={4}>
                        <WeightField
                          data-cy="maxLoad"
                          name="maxLoad"
                          label="Max. Load"
                        />
                      </Grid>

                      <Grid item={true} xs={12} lg={4}>
                        <VolumeField disabled={true} />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Grid>

            <Grid item={true} xs={12} lg={3}>
              <Box mb={3}>
                <Card>
                  <CardHeader title="Settings" />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12}>
                        <TextField
                          data-cy="locationAlias"
                          name="locationAlias"
                          label="Location alias"
                        />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <LocationTypeField />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <OperationsField />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <StatusField />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <PositiveNumberField
                          name="pickingOrder"
                          data-cy="pickingOrder"
                          label="Picking order"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>

              <Box>
                <Card>
                  <CardHeader title="Coordinates" />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12}>
                        <IntegerField name="x" label="X" />
                      </Grid>
                      <Grid item={true} xs={12}>
                        <IntegerField name="y" label="Y" />
                      </Grid>
                      <Grid item={true} xs={12}>
                        <IntegerField name="z" label="Z" />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Content>
      </Container>
    </form>
  )
}

LocationUpdateForm.propTypes = {
  pageTitleLoading: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired
}

export default withForm(LocationUpdateForm)
