import React from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'

import PageTitle from '~/components/PageTitle'
import { DiscardButton, SaveButton } from '~/components/Buttons'
import Container, { Content, Header } from '~/components/Container'
import { withForm } from '~/components/Form'
import * as NAV from '~/constants/nav-titles'
import { SUPPLIER_UPDATE_TABS } from '~/constants/tabs'

import SupplierUpdateFormTabs from './SupplierUpdateFormTabs'
import SupplierUpdateFormGeneral from './SupplierUpdateFormGeneral'
import SupplierUpdateFormAddress from './SupplierUpdateFormAddress'
import SupplierUpdateBankAccount from './SupplierUpdateBankAccount'

export const SUPPLIER_UPDATE_FIELDS = [
  'companyName',
  'shortName',
  'email',
  'phoneNumber',
  'address',
  'country',
  'city',
  'zipcode',
  'contactFirstName',
  'contactLastName',
  'imagePath',
  'contactEmail',
  'contactPhoneNumber',
  'status',
  'deliveryAddresses',
  'billingAddresses',
  'bankAccounts',
  'referenceId'
]

function SupplierUpdateForm (props) {
  const {
    pageTitle,
    pageTitleLoading,
    form,
    isLoading
  } = props

  const { tab } = useParams()
  const { handleSubmit } = form

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={pageTitle}
            pageTitleLoading={pageTitleLoading}
            parentTitle={NAV.SUPPLIERS}
            rightButton={
              <>
                <DiscardButton disabled={isLoading} />
                <SaveButton disabled={isLoading} />
              </>
            }
          />
        </Header>

        <Content>
          <SupplierUpdateFormTabs value={tab} />

          <Divider />
          <Box mt={3} mb={3}>

            {tab === SUPPLIER_UPDATE_TABS.GENERAL && <SupplierUpdateFormGeneral />}
            {tab === SUPPLIER_UPDATE_TABS.ADDRESS && <SupplierUpdateFormAddress />}
            {tab === SUPPLIER_UPDATE_TABS.BANK_ACCOUNT && <SupplierUpdateBankAccount />}
          </Box>
        </Content>
      </Container>
    </form>
  )
}

SupplierUpdateForm.propTypes = {
  pageTitleLoading: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  pageTitle: PropTypes.string,
  form: PropTypes.object
}

export default withForm(SupplierUpdateForm)
