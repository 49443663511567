import palette from '~/theme/dark/palette'

export default {
  styleOverrides: ({ ownerState }) => ({
    ...ownerState.color === 'primary' && {
      color: palette.text.primary
    },
    gutterBottom: {
      marginBottom: 8
    },
    root: {
      color: palette.text.primary
    },
  })
}
