import React from 'react'
import { useFilter, useOrdering } from 'storfox-filter'
import { useRoute } from 'storfox-route-hooks'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import ErrorLink from '~/components/ErrorLink'
import useMessages from '~/hooks/useMessages'
import { useTableSelects } from '~/components/TableValues/hooks'

import {
  ListingFilterForm,
  listingFilterOptions,
  ListingOrderingForm,
  listingOrderingOptions,
  ListingTable
} from '../components/ListingList'
import { useListingList, useListingPause, useListingPublish, useListingReject, useListingUnpublish } from '../hooks'
import { ListingSerializer } from '../serializers'

function ListingListContainer () {
  const messages = useMessages()
  const snackbar = useSnackbar()
  const listingList = useListingList()
  const listingPublish = useListingPublish()
  const listingUnpublish = useListingUnpublish()
  const listingPause = useListingPause()
  const listingReject = useListingReject()
  const { replaceParams } = useRoute()
  const { selects } = useTableSelects()

  const filter = useFilter(listingFilterOptions)
  const ordering = useOrdering(listingOrderingOptions)

  const handlePublish = () =>
    listingPublish.update(ListingSerializer(selects, listingList))
      .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
      .then(() => listingList.getList())
      .then(() => replaceParams({ select: '' }))
      .catch(response => {
        const message = <ErrorLink error={response} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const handleUnpublish = () =>
    listingUnpublish.update(ListingSerializer(selects, listingList))
      .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
      .then(() => listingList.getList())
      .then(() => replaceParams({ select: '' }))
      .catch(response => {
        const message = <ErrorLink error={response} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const handlePause = () =>
    listingPause.update(ListingSerializer(selects, listingList))
      .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
      .then(() => listingList.getList())
      .then(() => replaceParams({ select: '' }))
      .catch(response => {
        const message = <ErrorLink error={response} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const handleReject = () =>
    listingReject.update(ListingSerializer(selects, listingList))
      .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
      .then(() => listingList.getList())
      .then(() => replaceParams({ select: '' }))
      .catch(response => {
        const message = <ErrorLink error={response} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const bulkActionLoading = (
    listingPublish.isLoading ||
    listingUnpublish.isLoading ||
    listingPause.isLoading ||
    listingReject.isLoading
  )

  const breadcrumbs = { title: NAV.LISTING }

  return (
    <DashboardLayout
      title={NAV.LISTING}
      activeNav={NAV.LISTING}
      breadcrumbs={breadcrumbs}
    >
      <ListingFilterForm {...filter} />
      <ListingOrderingForm {...ordering} />

      <ListingTable
        list={listingList}
        ordering={ordering}
        onPublish={handlePublish}
        onUnpublish={handleUnpublish}
        onListRefetch={listingList.getListByParams}
        onReject={handleReject}
        onPause={handlePause}
        filter={filter}
        bulkActionLoading={bulkActionLoading}
      />
    </DashboardLayout>
  )
}

export default ListingListContainer
