import React from 'react'
import { prop, propOr } from 'ramda'
import PropTypes from 'prop-types'

import { useCompany } from '../Profile'

const ratios = {
  'cm': 1,
  'ft': 0.0328084,
  'in': 0.393701,
}

function Dimension ({ value }) {
  const { company } = useCompany()
  const unit = propOr('cm', 'sizeUnit', company)
  const ratio = prop(unit, ratios)
  const length = Number(value * ratio).toFixed(2)

  return (
    <>{length}&nbsp;{unit}</>
  )
}

Dimension.defaultProps = {
  value: 0
}

Dimension.propTypes = {
  value: PropTypes.number.isRequired
}

export default Dimension
