import React from 'react'
import PropTypes from 'prop-types'

import DangerButton from '~/components/Buttons/DangerButton'
import ConfirmDialog from '~/components/ConfirmDialog'

function DeleteRowsButton ({ onDelete }) {
  return (
    <ConfirmDialog onAgree={onDelete} message="Are you sure, you want to delete selected rows?">
      {({ onClick }) => (
        <DangerButton onClick={onClick} data-cy="delete">
          Delete
        </DangerButton>
      )}
    </ConfirmDialog>

  )
}

DeleteRowsButton.propTypes = {
  onDelete: PropTypes.func.isRequired
}

export default DeleteRowsButton
