import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'
import { prop, propOr } from 'ramda'

import { DashboardLayout } from '~/components/Layouts'
import ErrorLink from '~/components/ErrorLink'
import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'
import useMessages from '~/hooks/useMessages'
import { MOVEMENT_DETAIL_TABS } from '~/constants/tabs'

import {
  useMovementCancel,
  useMovementComplete,
  useMovementDetail,
  useMovementHistory,
  useMovementLineItems
} from '../hooks'
import { MovementDetailInitSerializer } from '../serializers'
import MovementDetail from '../components/MovementDetail/MovementDetail'

function MovementDetailContainer () {
  const { id, tab } = useParams()
  const messages = useMessages()
  const navigate = useNavigate()
  const movementDetail = useMovementDetail(id)
  const movementComplete = useMovementComplete(id)
  const movementCancel = useMovementCancel(id)
  const movementLineItems = useMovementLineItems(id)

  const guid = prop('guid', movementDetail.detail)
  const historyAutoSend = Boolean(guid && tab === MOVEMENT_DETAIL_TABS.HISTORY)

  const movementHistory = useMovementHistory(guid, historyAutoSend)

  const snackbar = useSnackbar()

  const pageTitle = propOr('', 'number', movementDetail.detail)

  const handleComplete = () =>
    movementComplete.complete()
      .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
      .then(() => navigate(ROUTES.MOVEMENT_LIST_PATH))
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
        Promise.reject(error)
      })

  const handleCancel = () =>
    movementCancel.cancel()
      .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
      .then(() => navigate(ROUTES.MOVEMENT_LIST_PATH))

  const isLoading = (
    movementDetail.isLoading ||
    movementComplete.isLoading
  )

  const breadcrumbs = { title: pageTitle }

  return (
    <DashboardLayout
      title={pageTitle}
      activeNav={NAV.MOVEMENT}
      breadcrumbs={breadcrumbs}
    >
      <MovementDetail
        pageTitle={pageTitle}
        initialValues={MovementDetailInitSerializer(movementDetail.detail)}
        pageTitleLoading={movementDetail.isLoading}
        isLoading={isLoading}
        onComplete={handleComplete}
        onCancel={handleCancel}
        cancelLoading={movementCancel.isLoading}
        history={movementHistory}
        movementLineItems={movementLineItems}
      />
    </DashboardLayout>
  )
}

export default MovementDetailContainer
