import React from 'react'
import PropTypes from 'prop-types'

import Status from '../Status/Status'

export const ACTIVE = 'active'
export const INACTIVE = 'inactive'

const NAMES = {
  [ACTIVE]: 'Active',
  [INACTIVE]: 'Inactive'
}

const COLOR = {
  [ACTIVE]: 'success',
  [INACTIVE]: 'error'
}

function ActiveStatus ({ value }) {
  return (
    <Status
      label={NAMES[value]}
      color={COLOR[value]}
    />
  )
}

ActiveStatus.propTypes = {
  value: PropTypes.string.isRequired

}

export default ActiveStatus
