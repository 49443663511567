import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { path, prop, propOr } from 'ramda'
import Grid from '@mui/material/Grid'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell/TableCell'
import TableBody from '@mui/material/TableBody'
import { Card, styled } from '@mui/material'
import Link from '@mui/material/Link'

import VerticalAlignment from '~/components/VerticalAlignment'
import Avatar from '~/components/Avatar/Avatar'
import Subtext from '~/components/Subtext'
import * as ROUTES from '~/constants/routes'

import LineItemStatus from './LineItemStatus'

const TableRowStyled = styled(TableRow)({
  backgroundColor: 'white',
  '& td': {
    color: 'black',
    fontSize: '14px',
    fontWeight: 500
  }
})

function LineItems ({ lineItems }) {
  const { t } = useTranslation()

  return (
    <Card sx={{ borderRadius: '10px' }}>
      <Grid item={true} xs={12}>
        <Table size="small">
          <TableHead>
            <TableRowStyled>
              <TableCell>{t('Image')}</TableCell>
              <TableCell>{t('From numbers')}</TableCell>
              <TableCell>{t('Product')}</TableCell>
              <TableCell>{t('To location')}</TableCell>
              <TableCell>{t('To container')}</TableCell>
              <TableCell>{t('To zone')}</TableCell>
              <TableCell>{t('Qty')}</TableCell>
              <TableCell>{t('Status')}</TableCell>
              <TableCell>{t('Container')}</TableCell>
            </TableRowStyled>
          </TableHead>
          <TableBody>
            {lineItems.map((item, index) => {
              const unit = prop('unit', item)
              const fromObjectNumber = propOr(0, 'fromObjectNumber', item)
              const quantity = propOr(0, 'quantity', item)
              const toContainer = path(['toContainer', 'number'], item)
              const container = path(['container', 'number'], unit)
              const toLocation = path(['toLocation', 'locationId'], item)
              const toZone = path(['toZone', 'label'], item)
              const variant = prop('variant', unit)
              const name = prop('name', variant)
              const imagePath = prop('imagePath', variant)
              const sku = prop('sku', variant)
              const status = prop('status', item)
              const unitGuid = prop('guid', unit)

              return (
                <TableRow key={index} sx={{
                  backgroundColor: 'white',
                  height: '56px'
                }}>
                  <TableCell>
                    <Avatar
                      alt={name}
                      src={imagePath}
                    />
                  </TableCell>
                  <TableCell>
                    {fromObjectNumber}
                  </TableCell>
                  <TableCell>
                    <Link
                      target="_blank"
                      href={`${ROUTES.UNIT_HISTORY_PATH}?guid=${unitGuid}`}
                      rel="noopener noreferrer"
                      underline="always"
                    >
                      <VerticalAlignment
                        primary={name}
                        secondary={<Subtext lines={1}>{sku}</Subtext>}
                      />
                    </Link>
                  </TableCell>
                  <TableCell>{toLocation}</TableCell>
                  <TableCell>{toContainer}</TableCell>
                  <TableCell>{toZone}</TableCell>
                  <TableCell>{quantity}</TableCell>
                  <TableCell>
                    <LineItemStatus value={status} />
                  </TableCell>
                  <TableCell>{container}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </Grid>
    </Card>
  )
}

LineItems.propTypes = {
  lineItems: PropTypes.array.isRequired,
}

export default LineItems
