export const OVERVIEW_TABS = {
  SALES: 'sales',
  FULFILLMENT: 'fulfillment'
}

export const PURCHASE_ORDER_DETAIL_TABS = {
  GENERAL: 'general',
  GATE_PASS: 'gate-pass',
  HISTORY: 'history'
}

export const SUPPLIER_DETAIL_TABS = {
  GENERAL: 'general',
  ADDRESS: 'address',
  BANK_ACCOUNT: 'bank',
  PURCHASES: 'purchases',
  HISTORY: 'history'
}

export const SUPPLIER_UPDATE_TABS = {
  GENERAL: 'general',
  ADDRESS: 'address',
  BANK_ACCOUNT: 'bank',
}

export const SALE_ORDER_DETAIL_TABS = {
  GENERAL: 'general',
  NOTES: 'notes',
  UNIT: 'unit',
  PICKLIST: 'picklist',
  PACKING: 'packing',
  SHIPMENT: 'shipment',
  RETURN: 'return',
  HISTORY: 'history',
  PUTAWAY: 'putaway'
}

export const CONTRACT_UPDATE_TABS = {
  GENERAL: 'general',
  INVOICE: 'invoice',
}

export const TRANSFER_DETAIL_TABS = {
  GENERAL: 'general',
  HISTORY: 'history'
}

export const MOVEMENT_DETAIL_TABS = {
  GENERAL: 'general',
  HISTORY: 'history'
}

export const ADJUSTMENT_DETAIL_TABS = {
  GENERAL: 'general',
  HISTORY: 'history'
}

export const SUPPLIER_RETURN_TABS = {
  GENERAL: 'general',
  HISTORY: 'history'
}

export const PRODUCT_DETAIL_TABS = {
  GENERAL: 'general',
  VARIANTS: 'variants',
  HISTORY: 'history'
}

export const PRODUCT_UPDATE_TABS = {
  GENERAL: 'general',
  VARIANTS: 'variants'
}

export const VARIANT_UPDATE_TABS = {
  GENERAL: 'general',
  INTERNATIONAL: 'international'
}

export const VARIANT_DETAIL_TABS = {
  GENERAL: 'general',
  STOCK: 'stock',
  SUPPLIERS: 'suppliers',
  HISTORY: 'history',
  INTERNATIONAL: 'international',
  INVENTORY_LOG: 'inventoryLog'
}

export const BUNDLE_UPDATE_TABS = {
  GENERAL: 'general',
  COMPOSITION: 'composition',
}

export const BUNDLE_DETAIL_TABS = {
  GENERAL: 'general',
  COMPOSITION: 'composition',
}

export const LISTING_TABS = {
  VARIANT: 'variant',
  PRODUCT: 'product',
}

export const SALE_RETURN_TABS = {
  GENERAL: 'general',
  HISTORY: 'history'
}

export const CUSTOMER_UPDATE_TABS = {
  GENERAL: 'general',
  ADDRESS: 'address',
}

export const CUSTOMER_DETAIL_TABS = {
  GENERAL: 'general',
  ADDRESS: 'address',
  ORDERS: 'orders',
  HISTORY: 'history'
}

export const PICKLIST_TABS = {
  GENERAL: 'general',
  HISTORY: 'history'
}

export const PACKING_DETAIL_TABS = {
  GENERAL: 'general',
  HISTORY: 'history'
}

export const LOT_SHEETS_DETAIL_TABS = {
  GENERAL: 'general',
  HISTORY: 'history',
}

export const SHIPMENT_DETAIL_TABS = {
  GENERAL: 'general',
  CONTENT: 'content',
  HISTORY: 'history',
  SHIPPING_INFO: 'shipping-info',
}

export const RECEIVE_TABS = {
  GENERAL: 'general',
  HISTORY: 'history'
}

export const LOCATION_TABS = {
  GENERAL: 'general',
  CONTENT: 'content'
}

export const WAREHOUSE_TABS = {
  GENERAL: 'general',
  AREA: 'area',
  ZONE: 'zone',
  LOCATION: 'location',
  CONTAINER: 'container'
}

export const PUTAWAY_DETAIL_TABS = {
  GENERAL: 'general',
  HISTORY: 'history'
}

export const USER_DETAIL_TABS = {
  GENERAL: 'general',
  HISTORY: 'history'
}

export const INTEGRATION_TABS = {
  GENERAL: 'general',
  LOGS: 'logs'
}

export const COMPANY_TABS = {
  GENERAL: 'general',
  VARIANTS: 'variants',
  INVENTORY: 'inventory',
  LISTINGS: 'listings',
  TIMELINE_AND_NOTES: 'timelineAndNotes',
  USERS: 'users'
}

export const ROLE_TABS = {
  GENERAL: 'general',
  PERMISSIONS: 'permissions'
}
