import React from 'react'
import PropTypes from 'prop-types'
import { Alert } from '@mui/lab'
import Typography from '@mui/material/Typography'

function PutawayTitle ({ isLocationEmpty, trackSerial, activeContainerNumber, isContainerAblePut }) {
  const getTitle = () => {
    if (isLocationEmpty) {
      return 'Scan destination location or container.'
    }

    if (trackSerial) {
      return 'Scan serial number.'
    }

    if (activeContainerNumber) {
      return `Inside ${activeContainerNumber}:`
    }

    return 'Scan unit or container.'
  }

  const getSubtitle = () => {
    if (activeContainerNumber && isContainerAblePut) {
      return (
        <>
          To put particular unit to the location, scan unit barcode.<br />
          To put whole container to the location, scan <strong>DONE</strong>.
        </>
      )
    }

    if (activeContainerNumber && !isContainerAblePut) {
      return (
        <>
          To put particular unit to the location, scan unit barcode.<br />
          To proceed to other units, scan <strong>DONE</strong>.
        </>
      )
    }

    return null
  }

  return (
    <Alert variant="standard" severity="info">
      <Typography variant="h5">{getTitle()}</Typography>
      <Typography variant="subtitle2">{getSubtitle()}</Typography>
    </Alert>
  )
}

PutawayTitle.propTypes = {
  isLocationEmpty: PropTypes.bool.isRequired,
  trackSerial: PropTypes.bool.isRequired,
  isContainerAblePut: PropTypes.bool,
  activeContainerNumber: PropTypes.string
}

export default PutawayTitle
