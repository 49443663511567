import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import { Filter } from 'storfox-filter'
import { prop } from 'ramda'
import Typography from '@mui/material/Typography'
import { DateRangeField } from 'storfox-form-fields'
import { useTranslation } from 'react-i18next'

import { escapeAtob, getSeparatedDateRange, unescapeBtoa } from '~/utils'
import Accordion from '~/components/Accordion'

import LotCompanySearchField from './LotCompanySearchField'
import AllocationModeField from './AllocationModeField'
import LotStatusField from './LotStatusField'

import { FILTER_NAME as name } from '../../constants'

export const toParams = values => {
  const company = unescapeBtoa(prop('company', values))
  const allocationMode = prop('allocationMode', values)
  const status = prop('status', values)
  const [createdAtStart, createdAtEnd] = getSeparatedDateRange(prop('createdAt', values))

  return { company, allocationMode, createdAtStart, createdAtEnd, status }
}

export const toValues = (values) => {
  const company = escapeAtob(prop('company', values))
  const allocationMode = prop('allocationMode', values)
  const status = prop('status', values)
  const createdAt = [prop('createdAtStart', values), prop('createdAtEnd', values)]

  return { company, allocationMode, createdAt, status }
}

export const transformer = { toParams, toValues }

export const lotSheetsFilterOptions = {
  name,
  transformer
}

function LotSheetsFilterForm (props) {
  const { t } = useTranslation()
  return (
    <Filter {...props}>
      <Accordion title="General" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <LotCompanySearchField />
          </Grid>
          <Grid item={true} xs={12}>
            <LotStatusField />
          </Grid>
          <Grid item={true} xs={12}>
            <AllocationModeField />
          </Grid>
        </Grid>
      </Accordion>
      <Accordion title="Date">
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <Typography variant="body2" gutterBottom={true}>
              {t('Created')}:
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <DateRangeField name="createdAt" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Accordion>
    </Filter>
  )
}

LotSheetsFilterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
}

export default LotSheetsFilterForm
