import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import ListingUpdateContainer from './containers/ListingUpdateContainer'
import ListingListContainer from './containers/ListingListContainer'
import ListingDetailContainer from './containers/ListingDetailContainer'

export default () => [
  {
    layout: AuthLayout,
    path: ROUTES.LISTING_LIST_PATH,
    component: ListingListContainer
  },
  {
    layout: AuthLayout,
    path: ROUTES.LISTING_DETAIL_PATH,
    component: ListingDetailContainer
  },
  {
    layout: AuthLayout,
    path: ROUTES.LISTING_UPDATE_PATH,
    component: ListingUpdateContainer
  }
]
