import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import Typography from '@mui/material/Typography'
import { Box, styled } from '@mui/material'

import { createMediumImageUrl } from '../../utils'

const ImageBoxStyled = styled(Box)({
  position: 'relative',
  height: 200,
  width: '100%',
  '& > img': {
    objectFit: 'contain',
    width: '100%',
    height: '100%'
  }
})

const EmptyImageStyled = styled(Box)({
  height: 200,
  color: '#9EA1B6',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px #ECECEC solid'
})

function SingleImagePreview ({ className, src, alt }) {
  const renderImage = src && !isEmpty(src)
  return (
    <Box position="relative" className={className}>
      {renderImage ? (
        <ImageBoxStyled>
          <img src={src ? createMediumImageUrl(src) : ''} alt={alt} />
        </ImageBoxStyled>
      ) : (
        <EmptyImageStyled>
          <Typography variant="subtitle1" color="inherit">
            No image
          </Typography>
        </EmptyImageStyled>
      )}
    </Box>
  )
}

SingleImagePreview.defaultProps = {
  alt: 'Image not loaded'
}

SingleImagePreview.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string
}

export default SingleImagePreview
