import React from 'react'
import { MultiSelectField } from 'storfox-form-fields'

const options = [
  { value: 'picking', name: 'Picking' },
  { value: 'putaway', name: 'Putaway' },
]

function OperationsField ({ ...props }) {
  return (
    <MultiSelectField
      data-cy="allowedOperations"
      name="allowedOperations"
      label="Operations"
      options={options}
      {...props}
    />
  )
}

export default OperationsField
