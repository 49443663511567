import { map, path, pick, pipe, prop } from 'ramda'
import { DEFAULT_SEARCH_PARAMS } from 'storfox-route-hooks'

import { escapeAtob } from '~/utils'

const getLocations = pipe(
  prop('locations'),
  map(location => ({
    guid: prop('guid', location)
  }))
)

export const StockCountCreateSerializer = values => {
  const warehouseGuid = path(['warehouse', 'guid'], values)
  const areaGuid = path(['area', 'guid'], values)
  const zoneGuid = path(['zone', 'guid'], values)
  const locations = getLocations(values)

  return {
    warehouse: warehouseGuid && { guid: warehouseGuid },
    zone: zoneGuid && { guid: zoneGuid },
    area: areaGuid && { guid: areaGuid },
    locations
  }
}

export function StockCountFilterSerializer (params) {
  const defaultParams = pick(DEFAULT_SEARCH_PARAMS, params)
  const status = prop('status', params)
  const warehouseGuid = escapeAtob(prop('warehouse', params))
  const areaGuid = escapeAtob(prop('area', params))
  const zoneGuid = escapeAtob(prop('zone', params))
  const createdAtStart = prop('createdAtStart', params)
  const createdAtEnd = prop('createdAtEnd', params)

  return {
    ...defaultParams,
    status,
    warehouseGuid,
    areaGuid,
    zoneGuid,
    createdAtStart,
    createdAtEnd
  }
}
