import React from 'react'
import PropTypes from 'prop-types'

import Status from '~/components/Status'

import { COMPLETED, NEW, PROGRESS } from '../../../constants/Purchase'

const COLOR = {
  [NEW]: 'success',
  [PROGRESS]: 'warning',
  [COMPLETED]: 'success',
}

function PurchaseReceiveStatus ({ value }) {
  return (
    <Status label={value} color={COLOR[value]} />
  )
}

PurchaseReceiveStatus.propTypes = {
  value: PropTypes.oneOf([
    NEW,
    PROGRESS,
    COMPLETED,
  ])
}

export default PurchaseReceiveStatus
