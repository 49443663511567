import React from 'react'
import { prop } from 'ramda'
import PropTypes from 'prop-types'
import { generatePath, useParams } from 'react-router-dom'
import { Divider } from '@mui/material'
import Box from '@mui/material/Box'

import PageTitle from '~/components/PageTitle'
import { EditButton } from '~/components/Buttons'
import Container, { Content, Header } from '~/components/Container'
import { BarcodeGeneratorDialogForm } from '~/components/BarcodeGeneratorDialog'
import * as ROUTES from '~/constants/routes'
import useDialog from '~/hooks/useDialog'
import * as NAV from '~/constants/nav-titles'
import LoaderProvider from '~/components/Loader'
import { ADJUSTMENT_DETAIL_TABS } from '~/constants/tabs'
import History from '~/components/History'

import AdjustmentGeneral from './AdjustmentGeneral'
import AdjustmentDetailTabs from './AdjustmentDetailTabs'

import More from '../AdjustmentUpdate/More'
import { ADJUSTMENT_STATUS } from '../../constants'
import CompleteButton from '../Buttons/CompleteButton'

function AdjustmentDetail (props) {
  const {
    pageTitle,
    pageTitleLoading,
    isLoading,
    onCancel,
    onComplete,
    onGenerate,
    initialValues,
    onPrint,
    history,
    adjustmentLineItems
  } = props

  const { id, tab } = useParams()

  const status = prop('status', initialValues)
  const source = prop('source', initialValues)

  const disabled = (
    status === ADJUSTMENT_STATUS.CANCELLED ||
    status === ADJUSTMENT_STATUS.COMPLETED
  )

  const barcodeDialog = useDialog()
  const editPath = generatePath(ROUTES.ADJUSTMENT_UPDATE_PATH, { id })

  return (
    <LoaderProvider isLoading={isLoading}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={pageTitle}
            pageTitleLoading={pageTitleLoading}
            parentTitle={NAV.ADJUSTMENT}
            rightButton={(
              <>
                <CompleteButton
                  onClick={onComplete}
                  disabled={isLoading || disabled}
                />
                <EditButton path={editPath} disabled={isLoading || disabled || source !== 'manual'} />
                <More
                  onCancel={onCancel}
                  onCancelDisabled={disabled}
                  onBarcodeGeneratorOpen={barcodeDialog.handleOpen}
                  onPrint={onPrint}
                />
              </>
            )}
          />
        </Header>

        <Content>
          <AdjustmentDetailTabs value={tab} />
          <Divider />
          <Box mb={3} />
          {tab === ADJUSTMENT_DETAIL_TABS.GENERAL && (
            <>
              <AdjustmentGeneral detail={initialValues} adjustmentLineItems={adjustmentLineItems} />
              <BarcodeGeneratorDialogForm
                onGenerate={onGenerate}
                onSubmit={() => {}}
                open={barcodeDialog.open}
                initialValues={{ count: 0 }}
                onClose={barcodeDialog.handleClose}
              />
            </>
          )}
          {tab === ADJUSTMENT_DETAIL_TABS.HISTORY && <History history={history} />}
        </Content>
      </Container>
    </LoaderProvider>
  )
}

AdjustmentDetail.propTypes = {
  pageTitle: PropTypes.node.isRequired,
  pageTitleLoading: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onGenerate: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  adjustmentLineItems: PropTypes.object.isRequired,
  onPrint: PropTypes.func.isRequired,
  history: PropTypes.object
}

export default AdjustmentDetail
