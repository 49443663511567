import React from 'react'
import PropTypes from 'prop-types'
import List from '@mui/material/List'
import ListItemText from '@mui/material/ListItemText'
import { useTranslation } from 'react-i18next'
import { ListItemButton } from '@mui/material'

import { MoreButton } from '~/components/Buttons'

function More (props) {
  const {
    onSave,
    saveDisabled,
    slipDisabled,
    onGenerateSlip
  } = props

  const { t } = useTranslation()

  return (
    <MoreButton>
      {onClose => (
        <List>
          <ListItemButton
            type="submit"
            form="packingUpdateForm"
            disabled={saveDisabled}
            onClick={() => {
              onSave()
              onClose()
            }}
          >
            <ListItemText primary={t('Save')} />
          </ListItemButton>

          <ListItemButton onClick={onGenerateSlip} disabled={slipDisabled}>
            <ListItemText primary={t('Print packing slip')} />
          </ListItemButton>
        </List>
      )}
    </MoreButton>
  )
}

More.propTypes = {
  saveDisabled: PropTypes.bool.isRequired,
  slipDisabled: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onGenerateSlip: PropTypes.func.isRequired
}

export default More
