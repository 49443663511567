import PropTypes from 'prop-types'
import React from 'react'
import MuiTableRow from '@mui/material/TableRow'
import { styled } from '@mui/material'

const TableRowStyled = styled(MuiTableRow, {
  shouldForwardProp: (propName) => propName !== 'isLastRow'
})(({ isLastRow }) => ({
  ...(isLastRow && {
    '& td': {
      borderBottom: 'none'
    }
  })
}))

function BorderTableRow ({ isLastRow, ...props }) {
  return (
    <TableRowStyled isLastRow={isLastRow} {...props} />
  )
}

BorderTableRow.propTypes = {
  isLastRow: PropTypes.bool
}

export default BorderTableRow
