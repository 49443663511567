import { find, map, path, pathEq, pick, pipe, prop, propOr, reduce, uniqBy } from 'ramda'

import { getDateTimeMidnight } from '~/utils'

import { RETURN } from '../constants'

const getLineItem = pick(['guid', 'containerCode', 'supplierSku'])

const getExpiry = (trackExpiry, expiresAt) => trackExpiry ? expiresAt : null

export function ReceivingReturnSerializer (formValues, receiptGuid) {
  const destination = prop('destination', formValues)
  const containerCode = prop('containerCode', destination)
  const locationId = prop('locationId', destination)
  const expiresAt = getDateTimeMidnight(prop('expiresAt', destination))
  const lineItems = prop('lineItems', formValues)

  const formattedLineItems = reduce((acc, item) => {
    const serial = prop('serial', item)
    if (serial) {
      const variant = prop('variant', item)
      const variantGuid = prop('guid', variant)
      const trackExpiry = prop('trackExpiry', variant)

      const accItem = find(item => {
        return pathEq(['variant', 'guid'], variantGuid, item)
      }, acc)

      if (accItem) {
        const inAccSerials = propOr([], 'serials', accItem)
        const newQuantity = prop('quantity', accItem) + 1
        const newItem = { ...accItem, serials: [...inAccSerials, item.serial], quantity: newQuantity }
        const otherItems = acc.filter(item => !pathEq(['variant', 'guid'], variantGuid, item))
        return [...otherItems, newItem]
      }

      const newLineItem = getLineItem(item)
      const newItem = {
        ...newLineItem,
        serials: [item.serial],
        variant,
        quantity: 1,
        containerCode,
        locationId,
        expiresAt: getExpiry(trackExpiry, expiresAt)
      }

      return [newItem, ...acc]
    }
    return acc
  }, [], lineItems)

  return { lineItems: formattedLineItems, guid: receiptGuid }
}

export const ReceiveReturnDetailInitSerializer = values => {
  const lineItems = propOr([], 'lineItems', values)
  return {
    ...values,
    objType: RETURN,
    lineItems: map(
      item => ({ ...item, location: prop('defaultReceivingLocation', item) }),
      lineItems
    )
  }
}

export function ReceiveReturnInitSerializer (values) {
  const lineItems = pipe(
    propOr([], 'lineItems'),
    uniqBy(path(['variant', 'barcode'])),
  )(values)

  const newValues = { ...values, lineItems }

  return {
    previousValues: newValues,
    ...newValues
  }
}
