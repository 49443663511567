import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import { prop } from 'ramda'
import { useParams } from 'react-router-dom'

import { Button } from '~/components/Buttons'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import * as NAV from '~/constants/nav-titles'
import { RECEIVE_TABS } from '~/constants/tabs'
import History from '~/components/History'
import LoaderProvider from '~/components/Loader'

import ReceiveReturnTabs from './ReceiveReturnTabs'
import DetailLineItems from './DetailLineItems'

import { COMPLETED } from '../../constants'

function ReceiveReturnDetail (props) {
  const {
    pageTitle,
    pageTitleLoading,
    isLoading,
    values,
    onComplete,
    completeDisabled,
    receiveHistory,
    handleReceive,
    getVariantDetail
  } = props

  const { tab } = useParams()

  const status = prop('status', values)
  const lineItems = prop('lineItems', values)

  const disabled = (
    isLoading ||
    completeDisabled ||
    status === COMPLETED
  )

  return (
    <LoaderProvider isLoading={isLoading}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={pageTitle}
            pageTitleLoading={pageTitleLoading}
            parentTitle={NAV.RECEIVE}
            rightButton={(
              <>
                <Button
                  variant="contained"
                  data-cy="receive"
                  type="button"
                  onClick={handleReceive}
                  disabled={disabled}
                >
                  Receive
                </Button>
                <Button
                  disabled={disabled}
                  variant="contained"
                  data-cy="complete"
                  onClick={onComplete}
                >
                  Complete
                </Button>
              </>
            )}
          />
        </Header>

        <Content>
          <ReceiveReturnTabs value={tab} />

          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12}>
              {tab === RECEIVE_TABS.GENERAL && (
                <DetailLineItems
                  lineItems={lineItems}
                  isLoading={isLoading}
                  getVariantDetail={getVariantDetail}
                />
              )}

              {tab === RECEIVE_TABS.HISTORY && <History history={receiveHistory} />}
            </Grid>
          </Grid>
        </Content>
      </Container>
    </LoaderProvider>
  )
}

ReceiveReturnDetail.propTypes = {
  values: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  pageTitle: PropTypes.node.isRequired,
  handleReceive: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  pageTitleLoading: PropTypes.bool.isRequired,
  completeDisabled: PropTypes.bool.isRequired,
  receiveHistory: PropTypes.object.isRequired,
  getVariantDetail: PropTypes.func.isRequired
}

export default ReceiveReturnDetail
