import React from 'react'
import { useParams } from 'react-router-dom'
import { pathOr } from 'ramda'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'

import BrandDetail from '../components/BrandDetail/BrandDetail'
import { useBrandDetail } from '../hooks'

function BrandDetailContainer () {
  const { id } = useParams()
  const brandDetail = useBrandDetail(id)

  const title = pathOr('Brands', ['detail', 'name'], brandDetail)
  const pageLoading = brandDetail.isLoading
  const isLoading = brandDetail.isLoading

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      isLoading={pageLoading}
      activeNav={NAV.BRANDS}
      breadcrumbs={breadcrumbs}
    >
      <BrandDetail
        title={title}
        pageTitleLoading={isLoading}
        detail={brandDetail.detail}
        isLoading={isLoading}
      />
    </DashboardLayout>
  )
}

export default BrandDetailContainer
