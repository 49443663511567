import React from 'react'
import { path, prop } from 'ramda'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import { Card, CardContent } from '@mui/material'

import TextLabelLoader from '~/components/TextLabelLoader'
import { CardHeader } from '~/components/Cards'

function MethodCard ({ detail }) {
  const carrier = path(['carrier', 'name'], detail)
  const service = path(['service', 'name'], detail)
  const trackingNumber = prop('trackingNumber', detail)

  return (
    <Box mt={3}>
      <Card>
        <CardHeader title="Method" />
        <Divider />
        <CardContent>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12}>
              <TextLabelLoader label="Carrier">
                {carrier}
              </TextLabelLoader>
            </Grid>

            <Grid item={true} xs={12}>
              <TextLabelLoader label="Service">
                {service}
              </TextLabelLoader>
            </Grid>

            <Grid item={true} xs={12}>
              <TextLabelLoader label="Tracking number">
                {trackingNumber}
              </TextLabelLoader>
            </Grid>

          </Grid>
        </CardContent>
      </Card>
    </Box>
  )
}

MethodCard.propTypes = {
  detail: PropTypes.object.isRequired
}

export default MethodCard
