import React from 'react'
import { useNavigate, useParams, generatePath } from 'react-router-dom'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'
import { prop, propOr } from 'ramda'

import { DashboardLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'
import ErrorLink from '~/components/ErrorLink'
import { INTEGRATION_TABS } from '~/constants/tabs'
import useMessages from '~/hooks/useMessages'

import { WooCommerceCreateSerializer } from '../../serializers'
import {
  useIntegrationHistory,
  useWooCommerceDetail,
  useWooCommerceRemove,
  useWooCommerceUpdate,
  useWooCommerceRetryOrder
} from '../../hooks'
import WooCommerceIntegrationUpdateForm from '../../components/WooCommerce/WooCommerceIntegrationUpdateForm'

function WooCommerceIntegrationUpdateContainer () {
  const { guid, tab } = useParams()

  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const messages = useMessages()

  const wooCommerceDetail = useWooCommerceDetail(guid)
  const wooCommerceUpdate = useWooCommerceUpdate(guid)
  const wooCommerceRetryOrder = useWooCommerceRetryOrder(guid)
  const wooCommerceRemove = useWooCommerceRemove()

  const title = prop('name', wooCommerceDetail.detail)
  const type = propOr('', 'type', wooCommerceDetail.detail)

  const detailPath = generatePath(ROUTES.INTEGRATION_UPDATE_PATH, { guid, type, tab: INTEGRATION_TABS.GENERAL })

  const historyAutoSend = Boolean(guid && tab === INTEGRATION_TABS.LOGS)
  const integrationHistory = useIntegrationHistory(guid, historyAutoSend)

  const handleSubmit = formValues =>
    wooCommerceUpdate.update(WooCommerceCreateSerializer(formValues))
      .then(() => {
        snackbar({ message: messages.UPDATE_SUCCESS })
        navigate(detailPath)
      })
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const isLoading = (
    wooCommerceDetail.isLoading ||
    wooCommerceUpdate.isLoading
  )

  const handleRemove = () =>
    wooCommerceRemove.delete({ guids: [guid] })
      .then(() => navigate(ROUTES.INTEGRATION_LIST_PATH, { replace: true }))

  const handleRetry = requestBody => {
    wooCommerceRetryOrder.create(requestBody)
      .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })
  }

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      isLoading={wooCommerceDetail.isLoading}
      activeNav={NAV.INTEGRATION}
      breadcrumbs={breadcrumbs}
    >
      <WooCommerceIntegrationUpdateForm
        isLoading={isLoading}
        pageTitle={title}
        pageTitleLoading={wooCommerceDetail.isLoading}
        onSubmit={handleSubmit}
        onRemove={handleRemove}
        initialValues={wooCommerceDetail.detail}
        history={integrationHistory}
        onRetry={handleRetry}
        retryLoading={wooCommerceRetryOrder.isLoading}
      />
    </DashboardLayout>
  )
}

export default WooCommerceIntegrationUpdateContainer
