import React from 'react'
import PropTypes from 'prop-types'

import PositiveNumberField from '~/components/Fields/PositiveNumberField'

import UserMultiSelectField from '../ActionFields/UserMultiSelectField'
import { ASSIGN_TO, MERGE_PICKLIST } from '../ActionFields/ActionSelectField'

export const PICKING_CREATED_FIELDS = [
  'assignTo',
  'mergePicking'
]

function PickingCreatedAction ({ action, actionName }) {
  return (
    <>
      {action === ASSIGN_TO && <UserMultiSelectField name={`${actionName}.users`} />}
      {action === MERGE_PICKLIST && <PositiveNumberField name={`${actionName}.orderCount`} label="Order count" />}
    </>
  )
}

PickingCreatedAction.propTypes = {
  action: PropTypes.string.isRequired,
  actionName: PropTypes.string.isRequired
}

export default PickingCreatedAction
