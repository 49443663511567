import React from 'react'
import PropTypes from 'prop-types'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

import Tabs from '~/components/Tabs'
import { SUPPLIER_RETURN_DETAIL_PATH } from '~/constants/routes'
import { SUPPLIER_RETURN_TABS } from '~/constants/tabs'

function ReturnDetailTabs ({ value }) {
  const navigate = useNavigate()
  const { guid } = useParams()
  const onChange = (event, tab) => {
    const redirect = generatePath(SUPPLIER_RETURN_DETAIL_PATH, { guid, tab })

    navigate(redirect, { replace: true })
  }

  const tabs = [
    { value: SUPPLIER_RETURN_TABS.GENERAL, label: 'General' },
    { value: SUPPLIER_RETURN_TABS.HISTORY, label: `History` }
  ]

  return (
    <Tabs
      tabs={tabs}
      value={value}
      onChange={onChange}
    />
  )
}

ReturnDetailTabs.propTypes = {
  value: PropTypes.string.isRequired
}

export default ReturnDetailTabs
