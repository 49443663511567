import React from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { prop } from 'ramda'

import PageTitle from '~/components/PageTitle'
import { DiscardButton, SaveButton } from '~/components/Buttons'
import Container, { Content, Header } from '~/components/Container'
import { withForm } from '~/components/Form'
import * as NAV from '~/constants/nav-titles'
import LoaderProvider from '~/components/Loader'
import { BUNDLE_UPDATE_TABS } from '~/constants/tabs'

import BundleComposition from './BundleComposition'
import BundleTabs from './BundleUpdateTabs'

import BundleGeneral from '../BundleGeneral'

export const BUNDLE_UPDATE_FIELDS = [
  'name',
  'description',
  'images',
  'lineItems',
  'sku',
  'barcode',
  'category',
  'brand',
  'tags'
]

function BundleUpdateForm (props) {
  const {
    form,
    title,
    isLoading,
    onCategoryCreate,
    categoryLoading,
    onBrandCreate,
    brandLoading
  } = props

  const { handleSubmit, initialValues } = form

  const retailPrice = prop('retailPrice', initialValues)

  const { tab } = useParams()
  return (
    <LoaderProvider isLoading={isLoading}>
      <form onSubmit={handleSubmit}>
        <Container>
          <Header>
            <PageTitle
              pageTitle={title}
              parentTitle={NAV.BUNDLES}
              rightButton={(
                <>
                  <DiscardButton disabled={isLoading} />
                  <SaveButton disabled={isLoading} />
                </>
              )}
            />
          </Header>

          <Content>
            <BundleTabs />

            {tab === BUNDLE_UPDATE_TABS.GENERAL && (
              <BundleGeneral
                retailPrice={retailPrice}
                onCategoryCreate={onCategoryCreate}
                categoryLoading={categoryLoading}
                onBrandCreate={onBrandCreate}
                brandLoading={brandLoading}
              />
            )}

            {tab === BUNDLE_UPDATE_TABS.COMPOSITION && <BundleComposition />}

          </Content>
        </Container>
      </form>
    </LoaderProvider>
  )
}

BundleUpdateForm.propTypes = {
  form: PropTypes.object,
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onCategoryCreate: PropTypes.func.isRequired,
  onBrandCreate: PropTypes.func.isRequired,
  brandLoading: PropTypes.bool.isRequired,
  categoryLoading: PropTypes.bool.isRequired
}

export default withForm(BundleUpdateForm)
