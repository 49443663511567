import React from 'react'
import PropTypes from 'prop-types'
import Popover from '@mui/material/Popover'
import MoreIcon from '@mui/icons-material/MoreVert'
import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks'
import IconButton from '@mui/material/IconButton'

function MoreButton ({ children, ...props }) {
  const popupState = usePopupState({ variant: 'popover', popupId: 'more' })
  const { onClick, ...restTrigger } = bindTrigger(popupState)

  return (
    <div
      onClick={event => event.stopPropagation()}>
      <IconButton
        data-cy="more"
        sx={{ fontSize: '1.5rem' }}
        size="small"
        onClick={event => {
          onClick(event)
          event.stopPropagation()
          event.preventDefault()
        }}
        {...restTrigger}
        {...props}
      >
        <MoreIcon fontSize="inherit" />
      </IconButton>
      <Popover
        {...bindPopover(popupState)}
        disableScrollLock={true}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        {children(popupState.close)}
      </Popover>
    </div>
  )
}

MoreButton.propTypes = {
  children: PropTypes.func.isRequired,
}

export default MoreButton
