import React from 'react'
import MUITooltip, { tooltipClasses } from '@mui/material/Tooltip'
import PropTypes from 'prop-types'
import { styled } from '@mui/material'

const TooltipStyled = styled(({ className, title, children, ...props }) => (
  <MUITooltip
    title={title}
    disableInteractive={true}
    followCursor={true}
    children={children}
    {...props}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  cursor: 'pointer',
  maxWidth: '100%',
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black
  }
}))

function Tooltip ({ title, children, ...props }) {
  return (
    <TooltipStyled
      arrow={true}
      title={title}
      {...props}
    >
      <div>{children}</div>
    </TooltipStyled>
  )
}

Tooltip.defaultProps = {
  title: ''
}

Tooltip.propTypes = {
  title: PropTypes.any,
  children: PropTypes.any
}
export default Tooltip
