import React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useSnackbar } from 'storfox-snackbar'
import { useTranslation } from 'react-i18next'
import { prop } from 'ramda'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import { WAREHOUSE_TABS } from '~/constants/tabs'

import { useContainerCreate, useWarehouseDetail } from '../hooks'
import { ContainerCreateSerializer } from '../serializers'
import { ContainerCreateForm } from '../components/ContainerCreate'

function ContainerCreateContainer () {
  const { t } = useTranslation()
  const { warehouseGuid } = useParams()
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const containerCreate = useContainerCreate()
  const warehouseDetail = useWarehouseDetail(warehouseGuid)

  const warehouseName = prop('name', warehouseDetail.detail)

  const initialValues = {
    quantity: 1,
    warehouse: { guid: warehouseGuid, name: warehouseName }
  }

  const message = t('Successfully created')
  const title = 'New container'

  const params = { guid: warehouseGuid, tab: WAREHOUSE_TABS.CONTAINER }
  const redirectPath = generatePath(ROUTES.WAREHOUSE_DETAIL_PATH, params)

  const handleSubmit = formValues =>
    containerCreate.create(ContainerCreateSerializer(warehouseGuid, formValues))
      .then(() => navigate(redirectPath))
      .then(() => snackbar({ message }))

  const breadcrumbs = {
    title,
    params: [
      { guid: warehouseGuid, tab: WAREHOUSE_TABS.AREA, title: warehouseName }
    ]
  }

  return (
    <DashboardLayout
      title={title}
      isLoading={containerCreate.isLoading}
      activeNav={NAV.WAREHOUSE}
      breadcrumbs={breadcrumbs}
    >
      <ContainerCreateForm
        title={title}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        isLoading={containerCreate.isLoading}
      />
    </DashboardLayout>
  )
}

export default ContainerCreateContainer
