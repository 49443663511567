import React from 'react'

import * as API from '~/constants/api'
import SearchField from '~/components/Fields/SearchField'

function DeliveryMethodField ({ ...props }) {
  return (
    <SearchField
      api={API.DELIVERY_METHOD_LIST}
      primaryKey="guid"
      name="deliveryMethod"
      data-cy="deliveryMethod"
      label="Delivery method"
      {...props}
    />
  )
}

export default DeliveryMethodField
