import React from 'react'
import Grid from '@mui/material/Grid'
import { defaultTo, groupBy, map, path, pipe, prop, uniq } from 'ramda'
import PropTypes from 'prop-types'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material'

import VerticalAlignment from '~/components/VerticalAlignment'
import Subtext from '~/components/Subtext'
import SkeletonList from '~/components/Skeleton'

import WarehouseRow from './WarehouseRow'

import { getStockTotalValues } from '../../../utils'

const TableStyled = styled(Table)({
  '& th': {
    whiteSpace: 'nowrap'
  }
})

const TableRowStyled = styled(TableRow)({
  '& td': {
    fontWeight: 'bold'
  }
})

function VariantStock ({ list }) {
  const { t } = useTranslation()

  const isLoading = prop('isLoading', list)
  const rows = prop('results', list)

  const total = getStockTotalValues(rows)

  const groupedWarehouses = groupBy(path(['warehouse', 'id']), rows)

  const warehouseIds = pipe(
    defaultTo([]),
    map(path(['warehouse', 'id'])),
    uniq
  )(rows)

  const isTableEmpty = !isLoading && !warehouseIds.length
  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12}>
        <TableContainer component={Paper}>
          <TableStyled size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ minWidth: 400 }}>{t('Warehouse')}</TableCell>
                <TableCell align="center">{t('Available')}</TableCell>
                <TableCell align="center">{t('Unavailable')}</TableCell>
                <TableCell align="center">{t('On Hand')}</TableCell>
                <TableCell align="center">{t('Allocated')}</TableCell>
                <TableCell align="center">{t('Action')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoading && (
              <>
                {warehouseIds.map(warehouseId => {
                  const warehouses = groupedWarehouses[warehouseId]
                  const firstWarehouse = warehouses[0]
                  const warehouseName = path(['warehouse', 'name'], firstWarehouse)
                  const companyName = (
                    <Subtext lines={1}>{path(['warehouse', 'company', 'name'], firstWarehouse)}</Subtext>
                  )

                  const { allocated, available, unavailable, onHand } = getStockTotalValues(warehouses)

                  const warehouse = <VerticalAlignment primary={warehouseName} secondary={companyName} />

                  return (
                    <WarehouseRow key={warehouseId} warehouses={warehouses}>
                      <TableCell>{warehouse}</TableCell>
                      <TableCell align="center">{available}</TableCell>
                      <TableCell align="center">{unavailable}</TableCell>
                      <TableCell align="center">{onHand}</TableCell>
                      <TableCell align="center">{allocated}</TableCell>
                    </WarehouseRow>
                  )
                })}

                {isTableEmpty && (
                  <TableRow>
                    <TableCell colSpan={6}>No data in the table</TableCell>
                  </TableRow>
                )}

                <TableRowStyled>
                  <TableCell align="right">{t('Total')}</TableCell>
                  <TableCell align="center">{prop('available', total)}</TableCell>
                  <TableCell align="center">{prop('unavailable', total)}</TableCell>
                  <TableCell align="center">{prop('onHand', total)}</TableCell>
                  <TableCell align="center">{prop('allocated', total)}</TableCell>
                </TableRowStyled>
              </>
              )}
              {isLoading && <SkeletonList columnCount={5} />}
            </TableBody>
          </TableStyled>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

VariantStock.propTypes = {
  list: PropTypes.object.isRequired
}

export default VariantStock
