import React from 'react'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell/TableCell'
import IconButton from '@mui/material/IconButton'
import { prop, propOr } from 'ramda'
import Delete from '@mui/icons-material/Delete'
import PropTypes from 'prop-types'
import EditIcon from '@mui/icons-material/Edit'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Box from '@mui/material/Box'

import Avatar from '~/components/Avatar'
import VerticalAlignment from '~/components/VerticalAlignment'
import Subtext from '~/components/Subtext'
import PositiveNumberField from '~/components/Fields/PositiveNumberField'
import useDialog from '~/hooks/useDialog'
import CurrencyMoney from '~/components/Money/CurrencyMoney'
import Tooltip from '~/components/HtmlTooltip'
import TextOverflow from '~/components/TextOverflow'

import LineItemDetailEdit from './LineItemDetailEdit'
import SaleOrderMoney from './SaleOrderMoney'

import { BUNDLE_TYPE } from '../../constants'
import TextUnknown from '../TextUnknown'

function LineItem ({ fields, index, name, isProcess, currency, companyGuid }) {
  const { handleClose, handleOpen, open } = useDialog()

  const value = prop('value', fields)
  const item = prop(index, value)
  const variant = prop('variant', item)
  const productSku = prop('productSku', item)
  const variantGuid = prop('guid', variant)
  const variantName = prop('name', variant)
  const sku = prop('sku', variant)
  const imagePath = prop('imagePath', item)
  const alt = variantName || productSku || 'Unknown'
  const price = propOr(0, 'price', item)
  const quantity = propOr(0, 'quantity', item)
  const discount = propOr(0, 'discount', item)
  const discountPrice = (discount / 100) * price
  const unitCost = price - discountPrice
  const tax = propOr(0, 'tax', item) / 100 * quantity * unitCost
  const totalPrice = tax + (quantity * unitCost)

  const withDiscountOrTax = totalPrice !== price * quantity

  const type = prop('type', variant)
  const isBundle = type === BUNDLE_TYPE

  const condition = prop('condition', item)
  const isFull = Boolean(condition)
  return (
    <TableRow key={`${name}${sku}`}>
      <TableCell>
        <Avatar
          alt={alt}
          src={imagePath}
        />
      </TableCell>

      <TableCell>
        {variantGuid ? (
          <VerticalAlignment
            sx={{ minWidth: '200px' }}
            primary={<TextOverflow selfTooltip={true}>{variantName}</TextOverflow>}
            secondary={<Subtext lines={1}>{sku}</Subtext>}
          />
        ) : (
          <TextUnknown>{productSku}</TextUnknown>
        )}

      </TableCell>

      <TableCell>
        <SaleOrderMoney
          name={`${name}.price`}
          conditionFieldName={`${name}.condition`}
          value={price}
          disabled={isBundle}
          currency={currency}
        />
      </TableCell>

      <TableCell>
        <PositiveNumberField
          data-cy={`${name}.quantity`}
          name={`${name}.quantity`}
          disabled={isProcess}
          inputProps={{ maxLength: 6 }}
        />
      </TableCell>

      <TableCell>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <CurrencyMoney currency={currency} value={totalPrice} />
          {withDiscountOrTax && (
            <Tooltip
              title="The final price is based on tax and discount"
              style={{ display: 'flex', marginLeft: '10px' }}
            >
              <InfoOutlinedIcon color="info" />
            </Tooltip>
          )}
        </Box>
      </TableCell>

      <TableCell>
        <IconButton onClick={handleOpen} size="large" data-cy={`variant-edit-${index}`}>
          <EditIcon color={isFull ? 'inherit' : 'error'} />
        </IconButton>
      </TableCell>

      <TableCell align="right">
        <IconButton disabled={isProcess} onClick={() => fields.remove(index)} size="large">
          <Delete />
        </IconButton>
      </TableCell>

      <LineItemDetailEdit
        item={item}
        name={name}
        open={open}
        onClose={handleClose}
        isProcess={isProcess}
        companyGuid={companyGuid}
      />
    </TableRow>
  )
}

LineItem.propTypes = {
  fields: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  isProcess: PropTypes.bool.isRequired,
  companyGuid: PropTypes.string
}

export default LineItem
