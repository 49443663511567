import React from 'react'
import PropTypes from 'prop-types'

import Tabs from '~/components/Tabs'
import Divider from '~/components/Divider'

function VisualizationTabs ({ tab, options = [], setTab }) {
  return (
    <>
      <Tabs
        tabs={options}
        value={tab}
        onChange={(event, tab) => setTab(tab)}
        variant="scrollable"
      />
      <Divider />
    </>
  )
}

VisualizationTabs.propTypes = {
  tab: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  setTab: PropTypes.func.isRequired
}

export default VisualizationTabs
