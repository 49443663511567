import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import * as API from '~/constants/api'
import SearchField from '~/components/Fields/SearchField'

function ShipmentSearchField ({ params, ...props }) {
  const { t } = useTranslation()

  return (
    <SearchField
      api={API.SHIPMENT_INTEGRATIONS}
      name="shippingMethod"
      label={t('Integration')}
      params={{ limit: 1000, ...params }}
      {...props}
    />
  )
}

ShipmentSearchField.defaultProps = {
  params: {}
}

ShipmentSearchField.propTypes = {
  params: PropTypes.object.isRequired
}

export default ShipmentSearchField
