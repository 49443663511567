import React from 'react'
import { generatePath, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import EditButton from '~/components/Buttons/EditButton'
import LoaderProvider from '~/components/Loader'
import { WAREHOUSE_TABS } from '~/constants/tabs'
import Button from '~/components/Buttons/Button'

import WarehouseDetailTabs from './WarehouseDetailTabs'
import WarehouseContent from './WarehouseContent'
import { AreaTable } from './AreaContent'
import { ZoneTable } from './ZoneContent'
import { LocationTable } from './LocationContent'
import { ContainerTable } from './ContainerContent'
import More from './More'

function WarehouseDetail (props) {
  const {
    pageTitle,
    pageTitleLoading,
    isLoading,
    warehouseDetail,
    areaList,
    onAreaListRefetch,
    areaFilter,
    areaOrdering,
    zoneList,
    onZoneListRefetch,
    zoneFilter,
    zoneOrdering,
    locationList,
    onLocationListRefetch,
    locationFilter,
    locationOrdering,
    onLocationsDelete,
    containerList,
    onContainerListRefetch,
    containerFilter,
    containerOrdering,
    onVisualizationClick,
    onWarehouseDelete
  } = props

  const { guid, tab } = useParams()

  const warehouseEditPath = generatePath(ROUTES.WAREHOUSE_UPDATE_PATH, { guid })

  return (
    <LoaderProvider isLoading={isLoading}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={pageTitle}
            pageTitleLoading={pageTitleLoading}
            parentTitle={NAV.WAREHOUSES}
            rightButton={(
              <>
                <Button
                  variant="contained"
                  type="button"
                  onClick={onVisualizationClick}
                  {...props}
                >
                  Visualization
                </Button>

                <EditButton
                  path={warehouseEditPath}
                  disabled={isLoading}
                />
                <More onDelete={onWarehouseDelete} />
              </>
            )}
          />
        </Header>

        <Content>
          <WarehouseDetailTabs guid={guid} />

          {tab === WAREHOUSE_TABS.GENERAL && <WarehouseContent warehouse={warehouseDetail} />}
          {tab === WAREHOUSE_TABS.AREA && (
            <AreaTable
              filter={areaFilter}
              ordering={areaOrdering}
              list={areaList}
              onListRefetch={onAreaListRefetch}
            />
          )}
          {tab === WAREHOUSE_TABS.ZONE && (
            <ZoneTable
              list={zoneList}
              filter={zoneFilter}
              ordering={zoneOrdering}
              onListRefetch={onZoneListRefetch}
            />
          )}
          {tab === WAREHOUSE_TABS.LOCATION && (
            <LocationTable
              list={locationList}
              filter={locationFilter}
              ordering={locationOrdering}
              onLocationsDelete={onLocationsDelete}
              onListRefetch={onLocationListRefetch}
            />
          )}
          {tab === WAREHOUSE_TABS.CONTAINER && (
            <ContainerTable
              list={containerList}
              filter={containerFilter}
              ordering={containerOrdering}
              onListRefetch={onContainerListRefetch}
            />
          )}
        </Content>
      </Container>
    </LoaderProvider>
  )
}

WarehouseDetail.propTypes = {
  pageTitle: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  warehouseDetail: PropTypes.object.isRequired,
  pageTitleLoading: PropTypes.bool.isRequired,
  areaList: PropTypes.object.isRequired,
  onAreaListRefetch: PropTypes.object.isRequired,
  areaFilter: PropTypes.object.isRequired,
  areaOrdering: PropTypes.object.isRequired,
  zoneList: PropTypes.object.isRequired,
  onZoneListRefetch: PropTypes.object.isRequired,
  zoneFilter: PropTypes.object.isRequired,
  zoneOrdering: PropTypes.object.isRequired,
  locationList: PropTypes.object.isRequired,
  onLocationListRefetch: PropTypes.object.isRequired,
  locationFilter: PropTypes.object.isRequired,
  locationOrdering: PropTypes.object.isRequired,
  onLocationsDelete: PropTypes.func.isRequired,
  containerList: PropTypes.object.isRequired,
  onContainerListRefetch: PropTypes.object.isRequired,
  containerFilter: PropTypes.object.isRequired,
  containerOrdering: PropTypes.object.isRequired,
  onVisualizationClick: PropTypes.func.isRequired,
  onWarehouseDelete: PropTypes.func.isRequired
}

export default WarehouseDetail
