import { sprintf } from 'sprintf-js'
import { useCreate, useDetail, useList, useUpdate } from 'storfox-api-hooks'
import { useAllSearchParams } from 'storfox-route-hooks'

import * as API from '~/constants/api'

export const useConditionList = () => {
  const params = useAllSearchParams()
  return useList(API.CONDITION_LIST, params)
}
export const useConditionCreate = () => {
  return useCreate(API.CONDITION_CREATE)
}

export const useConditionUpdate = (id) => {
  const api = sprintf(API.CONDITION_UPDATE, id)
  return useUpdate(api)
}

export const useConditionDetail = (id) => {
  const api = sprintf(API.CONDITION_DETAIL, id)
  return useDetail(api)
}
