import { sprintf } from 'sprintf-js'
import { DEFAULT_SEARCH_PARAMS, useAllSearchParams, usePickSearchParams } from 'storfox-route-hooks'
import { useLimitedCountList, useCreate, useDetail, useList } from 'storfox-api-hooks'

import { queryToParams, skipNullValues } from '~/utils'
import * as API from '~/constants/api'
import { PACKING } from '~/constants/locationTypes'

import { PicklistFilterSerializer } from '../serializers'

export const usePicklistList = () => {
  const searchParams = useAllSearchParams()
  const params = queryToParams(PicklistFilterSerializer, searchParams)

  return useLimitedCountList(API.PICKLIST_LIST, params)
}

export const usePicklistProcess = () => {
  const { create, ...state } = useCreate(API.PICKLIST_PROCESS)
  return { ...state, process: create }
}

export const usePicklistProgress = () => {
  const { create, ...state } = useCreate(API.PICKLIST_PROGRESS)
  return { ...state, progress: create }
}

export const usePicklistDetail = guid => {
  const url = sprintf(API.PICKLIST_DETAIL, guid)
  const params = skipNullValues(usePickSearchParams(['status', ...DEFAULT_SEARCH_PARAMS]))
  return useDetail(url, params)
}

export const usePicklistDetailPick = () => {
  return useCreate(API.PICKLIST_DETAIL_PICK)
}

export const usePicklistDetailComplete = () => {
  return useCreate(API.PICKLIST_DETAIL_COMPLETE)
}

export const usePicklistSkip = guid => {
  const url = sprintf(API.PICKLIST_SKIP_PROCESS, guid)
  const { create, ...state } = useCreate(url)

  return { ...state, skip: create }
}

export const usePicklistPdfReport = () => {
  const { create, ...state } = useCreate(API.PICKLIST_PDF_REPORT)
  return { ...state, generate: create }
}

export const usePicklistHistory = (guid, autoSend) => {
  const url = sprintf(API.PICKLIST_HISTORY, guid)
  const searchParams = useAllSearchParams()

  return useList(url, searchParams, autoSend)
}

export const usePicklistSetPicker = () => {
  const { state, create } = useCreate(API.PICKLIST_SET_PICKER)

  return { ...state, set: create }
}

export const usePicklistSetBulkPicker = () => {
  const { state, create } = useCreate(API.PICKLIST_SET_PICKER_BULK)

  return { ...state, set: create }
}

export const usePicklistDetailProcess = guid => {
  const url = sprintf(API.PICKLIST_DETAIL_PROCESS, guid)
  const { state, create } = useCreate(url)

  return { ...state, process: create }
}

export const usePicklistCancel = guid => {
  const url = sprintf(API.PICKLIST_CANCEL, guid)
  const { state, create } = useCreate(url)

  return { ...state, cancel: create }
}

export const usePicklistDetailProgress = guid => {
  const url = sprintf(API.PICKLIST_DETAIL_PROGRESS, guid)
  const { state, create } = useCreate(url)

  return { ...state, progress: create }
}

export const usePickingJobCreate = () => {
  return useCreate(API.PICKLIST_SET_PICKER)
}

export const usePicklistBatch = () => {
  const { create, ...state } = useCreate(API.PICKLIST_BATCH)

  return { ...state, batch: create }
}

export const usePicklistImport = () => {
  return useCreate(API.PCIKLIST_FILE_UPLOAD)
}

export const usePackingLocationList = warehouseId => {
  const params = { warehouseId, locationType: PACKING }
  return useList(API.LOCATION_LIST, params, warehouseId)
}

export const usePicklistUnitAlter = () => {
  const { create, ...state } = useCreate(API.PICKLIST_UNIT_ALTER)

  return { ...state, alter: create }
}
