import { assocPath, path, prop } from 'ramda'

import { orderingToSnackCase, toHostnameProtocol } from '~/utils'

export const ShipoxCreateSerializer = formValues => {
  const storeDomain = path(['credential', 'storeDomain'], formValues)

  return assocPath(['credential', 'storeDomain'], toHostnameProtocol(storeDomain), formValues)
}

export const WooCommerceCreateSerializer = formValues => {
  const storeDomain = prop('storeDomain', formValues)

  return { ...formValues, storeDomain: toHostnameProtocol(storeDomain) }
}

export const StoreDomainCheckSerializer = formValues => {
  const storeDomain = prop('storeDomain', formValues)

  return {
    storeDomain: `${storeDomain}.myshopify.com`
  }
}

export const PostmenCreateSerializer = formValues => {
  const name = prop('name', formValues)
  const region = prop('region', formValues)
  const description = prop('description', formValues)
  const type = path(['credential', 'type'], formValues)

  const credentialValues = prop('credential', formValues)
  const apiKey = prop('apiKey', credentialValues)
  const accountId = prop('accountId', credentialValues)
  const billingPaidBy = prop('billingPaidBy', credentialValues)
  const termsOfTrade = prop('termsOfTrade', credentialValues)

  return {
    name,
    description,
    credential: {
      apiKey,
      accountId,
      billingPaidBy,
      termsOfTrade,
      type,
      region
    }
  }
}

export const PostmenUpdateSerializer = (formValues) => {
  const { storfoxAccessToken, type, id, company, ...rest } = formValues

  return {
    ...rest
  }
}

export const ShipRocketUpdateSerializer = formValues => {
  const { credential, description, guid, name, status } = formValues

  return { credential, description, guid, name, status }
}

export const JTExpressUpdateSerializer = formValues => {
  const { configuration, credential, description, guid, name, status } = formValues

  return { configuration, credential, description, guid, name, status }
}

export const ShopifyFilterSerializer = params => {
  const search = prop('search', params)
  const createdAtStart = prop('createdAtStart', params)
  const createdAtEnd = prop('createdAtEnd', params)
  const limit = prop('limit', params)
  const page = prop('page', params)

  return orderingToSnackCase({
    createdAtStart,
    createdAtEnd,
    search,
    page,
    limit
  })
}
