import React from 'react'

import SelectField from '~/components/Fields/SelectField'

import { RECEIVING_TYPES } from '../../../constants/BillingProfile'

const OPTIONS = [
  {
    value: RECEIVING_TYPES.PER_CONTAINER_WEIGHT,
    name: 'Container weight'
  }
]

function ReceivingChargeTypeField (props) {
  return (
    <SelectField
      label="Charge per"
      name="type"
      data-cy="type"
      options={OPTIONS}
      {...props}
    />
  )
}

export default ReceivingChargeTypeField
