import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import { IntegerField, PrependField } from 'storfox-form-fields'

import { CardHeader } from '~/components/Cards'
import AreaField from '~/components/Fields/AreaField'
import DimensionField from '~/components/Fields/DimensionField'
import StatusField from '~/components/Fields/StatusField'
import TextField from '~/components/Fields/TextField'
import VolumeField from '~/components/Fields/VolumeField'
import WarehouseSearchField from '~/components/Fields/WarehouseSearchField'
import WeightField from '~/components/Fields/WeightField'
import ZoneField from '~/components/Fields/ZoneField'
import PositiveNumberField from '~/components/Fields/PositiveNumberField'

import LocationTypeField from './Fields/LocationTypeField'
import OperationsField from './Fields/OperationsField'

function LocationGeneral () {
  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12} lg={9}>
        <Box mb={3}>
          <Card>
            <CardHeader title="Area/Zone" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12} lg={4}>
                  <WarehouseSearchField disabled={true} />
                </Grid>

                <Grid item={true} xs={12} lg={4}>
                  <AreaField
                    data-cy="area"
                    name="area"
                    label="Area"
                    warehouse="warehouse"
                    required={true}
                  />
                </Grid>

                <Grid item={true} xs={12} lg={4}>
                  <ZoneField
                    data-cy="zone"
                    name="zone"
                    label="Zone"
                    area="area"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>

        <Box mb={3}>
          <Card>
            <CardHeader title="Shelf/Bin" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12} lg={3}>
                  <PrependField
                    data-cy="aisle"
                    name="aisle"
                    label="Aisle"
                    prependChar="0"
                    valueLength={3}
                    inputProps={{ maxLength: 3 }}
                  />
                </Grid>

                <Grid item={true} xs={12} lg={3}>
                  <PrependField
                    data-cy="bay"
                    name="bay"
                    label="Bay/Rack"
                    prependChar="0"
                    valueLength={3}
                    inputProps={{ maxLength: 3 }}
                  />
                </Grid>

                <Grid item={true} xs={12} lg={3}>
                  <PrependField
                    data-cy="level"
                    name="level"
                    label="Level/Shelf"
                    prependChar="0"
                    valueLength={3}
                    inputProps={{ maxLength: 3 }}
                  />
                </Grid>

                <Grid item={true} xs={12} lg={3}>
                  <PrependField
                    data-cy="bin"
                    name="bin"
                    label="Bin/Position"
                    prependChar="0"
                    valueLength={3}
                    inputProps={{ maxLength: 3 }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>

        <Box mb={3}>
          <Card>
            <CardHeader title="Dimension" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>

                <Grid item={true} xs={12} lg={4}>
                  <DimensionField
                    data-cy="height"
                    name="height"
                    label="Height"
                  />
                </Grid>

                <Grid item={true} xs={12} lg={4}>
                  <DimensionField
                    data-cy="width"
                    name="width"
                    label="Width"
                  />
                </Grid>

                <Grid item={true} xs={12} lg={4}>
                  <DimensionField
                    data-cy="length"
                    name="length"
                    label="Length"
                  />
                </Grid>

                <Grid item={true} xs={12} lg={4}>
                  <WeightField
                    data-cy="maxLoad"
                    name="maxLoad"
                    label="Max. Load"
                  />
                </Grid>

                <Grid item={true} xs={12} lg={4}>
                  <VolumeField disabled={true} />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>

      <Grid item={true} xs={12} lg={3}>
        <Box mb={3}>
          <Card>
            <CardHeader title="Settings" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <TextField
                    data-cy="locationAlias"
                    name="locationAlias"
                    label="Location alias"
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <LocationTypeField required={true} />
                </Grid>

                <Grid item={true} xs={12}>
                  <OperationsField />
                </Grid>

                <Grid item={true} xs={12}>
                  <StatusField />
                </Grid>
                <Grid item={true} xs={12}>
                  <PositiveNumberField
                    name="pickingOrder"
                    data-cy="pickingOrder"
                    label="Picking order"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Box>
          <Card>
            <CardHeader title="Coordinates" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <IntegerField name="x" label="X" />
                </Grid>
                <Grid item={true} xs={12}>
                  <IntegerField name="y" label="Y" />
                </Grid>
                <Grid item={true} xs={12}>
                  <IntegerField name="z" label="Z" />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </Grid>
  )
}

export default LocationGeneral
