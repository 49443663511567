import React from 'react'
import { useSnackbar } from 'storfox-snackbar'
import { useTranslation } from 'react-i18next'

import { DashboardLayout } from '~/components/Layouts'
import { useUpdateProfile } from '~/components/Profile'
import * as NAV from '~/constants/nav-titles'

import { AccountInitSerializer, AccountUpdateSerializer } from '../serializers'
import AccountUpdateForm from '../components/AccountUpdate'
import { useAccountDetail, useAccountUpdate } from '../hooks'

function AccountUpdateContainer () {
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const { updateProfile } = useUpdateProfile()
  const accountDetail = useAccountDetail()
  const accountUpdate = useAccountUpdate()

  const message = t('Successfully updated')

  const handleSubmit = formValues =>
    accountUpdate.update(AccountUpdateSerializer(formValues))
      .then(() => accountDetail.getDetail())
      .then(() => updateProfile())
      .then(() => snackbar({ message }))

  const isLoading = accountDetail.isLoading || accountUpdate.isLoading

  const breadcrumbs = { title: NAV.ACCOUNT }

  return (
    <DashboardLayout
      title={t(NAV.ACCOUNT)}
      activeNav={NAV.ACCOUNT}
      breadcrumbs={breadcrumbs}
    >
      <AccountUpdateForm
        initialValues={AccountInitSerializer(accountDetail.detail)}
        isLoading={isLoading}
        onSubmit={handleSubmit}
      />
    </DashboardLayout>
  )
}

export default AccountUpdateContainer
