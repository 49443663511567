import React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { path } from 'ramda'
import { useSnackbar } from 'storfox-snackbar'
import { useTranslation } from 'react-i18next'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import { LOCATION_TABS, WAREHOUSE_TABS } from '~/constants/tabs'

import { CONTENT } from '../constants'
import { LocationUpdateForm } from '../components/LocationUpdate'
import { useLocationContent, useLocationDetail, useLocationUpdate } from '../hooks'
import { LocationUpdateSerializer } from '../serializers'

function LocationUpdateContainer () {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { guid, tab } = useParams()
  const snackbar = useSnackbar()
  const locationDetail = useLocationDetail(guid)
  const locationUpdate = useLocationUpdate(guid)

  const locationContentAutoSend = Boolean(guid && tab === CONTENT)
  const locationContent = useLocationContent(guid, locationContentAutoSend)

  const warehouseGuid = path(['warehouse', 'guid'], locationDetail.detail)
  const warehouseName = path(['warehouse', 'name'], locationDetail.detail)

  const message = t('Successfully updated')

  const handleSubmit = formValues =>
    locationUpdate.update(LocationUpdateSerializer(formValues))
      .then(() => snackbar({ message }))
      .then(() => {
        const detailPath = generatePath(ROUTES.LOCATION_DETAIL_PATH, { guid, tab: LOCATION_TABS.GENERAL })
        return navigate(detailPath)
      })

  const title = path(['detail', 'locationId'], locationDetail)

  const breadcrumbs = {
    title: 'Edit',
    params: [
      { guid: warehouseGuid, tab: WAREHOUSE_TABS.LOCATION, title: warehouseName },
      { guid, tab: LOCATION_TABS.GENERAL, title }
    ]
  }

  return (
    <DashboardLayout
      title={title}
      isLoading={locationDetail.isLoading || locationUpdate.isLoading}
      activeNav={NAV.WAREHOUSE}
      breadcrumbs={breadcrumbs}
    >
      <LocationUpdateForm
        title={title}
        pageTitleLoading={locationDetail.isLoading}
        isLoading={locationUpdate.isLoading}
        initialValues={locationDetail.detail}
        onSubmit={handleSubmit}
        locationContent={locationContent}
      />
    </DashboardLayout>
  )
}

export default LocationUpdateContainer
