import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useField } from 'react-final-form'

import SearchField from './SearchField'

function SearchAutoClearField (props) {
  const { input } = useField(props.name)
  const { value, onChange } = input

  useEffect(() => {
    onChange(null)
  }, [value, onChange])

  return (
    <SearchField disableClearable={true} {...props} />
  )
}

SearchAutoClearField.propTypes = {
  name: PropTypes.string.isRequired
}

export default SearchAutoClearField
