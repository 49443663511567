import React from 'react'
import { useField } from 'react-final-form'
import PropTypes from 'prop-types'
import InfoIcon from '@mui/icons-material/Info'
import LoopIcon from '@mui/icons-material/Loop'
import InputAdornment from '@mui/material/InputAdornment'
import { IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { prop } from 'ramda'

import SubmitBlockedTextField from '~/components/Fields/SubmitBlockedTextField'
import HtmlTooltip from '~/components/HtmlTooltip'

import { generateSkuFromString } from '../../utils/sku'

function SkuField ({ name = 'sku', parentName = 'name', ...props }) {
  const { t } = useTranslation()
  const field = useField(name)
  const value = field.input.value || ''
  const isSpaces = prop(0, value) === ' ' || prop(value.length - 1, value) === ' '

  const parentField = useField(parentName)
  const parentValue = parentField.input.value

  const handleGenerate = () => {
    const randomNumber = Math.ceil(Math.random() * 1000)
    const parentSubValue = generateSkuFromString(parentValue, 6)

    const value = `${parentSubValue}-${randomNumber}`.toUpperCase()

    field.input.onChange(value)
  }

  return (
    <>
      <SubmitBlockedTextField
        name={name}
        label="SKU"
        data-cy="sku"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {isSpaces && (
                <HtmlTooltip title="Leading and trailing spaces will be removed">
                  <IconButton size="small">
                    <InfoIcon color="warning" />
                  </IconButton>
                </HtmlTooltip>
              )}

              <IconButton
                size="small"
                disabled={!parentValue}
                title={t('Generate SKU')}
                onClick={handleGenerate}
              >
                <LoopIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
        {...props}
      />
    </>
  )
}

SkuField.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
  required: PropTypes.bool
}

export default SkuField
