import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Card, Divider } from '@mui/material'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box'
import AutorenewIcon from '@mui/icons-material/Autorenew'

import { CardHeader } from '~/components/Cards'

import AddressDialog from './AddressDialog'

function EditableAddress ({ title, addressFields, options, value, onChange }) {
  const [open, setOpen] = useState(false)

  const handleClose = () => setOpen(false)
  const handleOpen = () => setOpen(true)

  const multiple = options.length > 1

  return (
    <>
      <AddressDialog
        title={`Choose ${title}`}
        open={open}
        handleClose={handleClose}
        onChange={onChange}
        value={value}
        options={options}
      />
      <Card style={{ height: '100%' }}>
        <CardHeader
          style={{
            paddingTop: 8,
            paddingBottom: 8
          }}
          title={(
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              {title}
              <Button
                disabled={!multiple}
                color="secondary"
                variant="text"
                startIcon={<AutorenewIcon />}
                onClick={handleOpen}
              >
                Change
              </Button>
            </Box>
          )}
        />
        <Divider />
        <CardContent>
          {addressFields}
        </CardContent>
      </Card>
    </>
  )
}

EditableAddress.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.array,
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  addressFields: PropTypes.node.isRequired
}

export default EditableAddress
