import React from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import PropTypes from 'prop-types'

function EmptyRow ({ colSpan, ...props }) {
  return (
    <TableRow>
      <TableCell
        colSpan={colSpan}
        sx={{ height: '40px' }}
        {...props}
      />
    </TableRow>
  )
}

EmptyRow.defaultProps = {
  colSpan: 1
}

EmptyRow.propTypes = {
  colSpan: PropTypes.number,
}

export default EmptyRow
