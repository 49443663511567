export const MARGINS = {
  left: 10,
  top: 10
}

export const NODE_WIDTH = 280
export const NODE_HEIGHT = 180

export const V_SPACE_BETWEEN_NODES = 100
export const H_SPACE_BETWEEN_NODES = 100
