import React from 'react'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import PropTypes from 'prop-types'
import { pluck, prop, propOr } from 'ramda'

import { CardHeader } from '~/components/Cards'
import { Button, DiscardButton } from '~/components/Buttons'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import AreaField from '~/components/Fields/AreaField'
import WarehouseSearchField from '~/components/Fields/WarehouseSearchField'
import ZoneField from '~/components/Fields/ZoneField'
import { withForm } from '~/components/Form'
import * as NAV from '~/constants/nav-titles'
import * as API from '~/constants/api'

import LocationsUpdateTable from './LocationsUpdateTable'

import { CANCELLED, COMPLETED, IN_PROGRESS, PENDING } from '../../constants'

function StockCountUpdate ({ form, isLoading, pageTitle, detailLoading, onProcess, onCancel }) {
  const { initialValues, handleSubmit } = form

  const status = prop('status', initialValues)
  const lineItems = propOr([], 'lineItems', initialValues)
  const locations = pluck('location', lineItems)

  const cancelDisabled = (
    isLoading ||
    status === CANCELLED ||
    status === COMPLETED
  )

  const processDisabled = (
    isLoading ||
    status === CANCELLED ||
    status === COMPLETED ||
    status === PENDING ||
    status === IN_PROGRESS
  )

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={pageTitle}
            pageTitleLoading={detailLoading}
            parentTitle={NAV.STOCK_COUNT}
            rightButton={(
              <>
                <DiscardButton disabled={isLoading} />
                <Button
                  variant="contained"
                  disabled={cancelDisabled}
                  onClick={onCancel}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  disabled={processDisabled}
                  onClick={onProcess}
                >
                  Process
                </Button>
              </>
            )}
          />
        </Header>
        <Content>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12}>
              <Card>
                <CardHeader title="Location" />
                <Divider />
                <CardContent>
                  <Grid container={true} spacing={3}>
                    <Grid item={true} lg={4} xs={12}>
                      <WarehouseSearchField
                        api={API.STOCK_COUNT_WAREHOUSE_LIST}
                        disabled={true}
                      />
                    </Grid>
                    <Grid item={true} lg={4} xs={12}>
                      <AreaField
                        name="area"
                        label="Area"
                        warehouse="warehouse"
                        api={API.STOCK_COUNT_AREA_LIST}
                        disabled={true}
                      />
                    </Grid>
                    <Grid item={true} lg={4} xs={12}>
                      <ZoneField
                        name="zone"
                        label="Zone"
                        area="area"
                        disabled={true}
                        api={API.STOCK_COUNT_ZONE_LIST}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item={true} xs={12}>
              <Card>
                <CardHeader title="Locations" />
                <Divider />
                <LocationsUpdateTable
                  isLoading={detailLoading}
                  locations={locations}
                />
              </Card>
            </Grid>

          </Grid>
        </Content>
      </Container>
    </form>
  )
}

StockCountUpdate.propTypes = {
  form: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  pageTitle: PropTypes.string,
  detailLoading: PropTypes.bool.isRequired,
  onProcess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}

export default withForm(StockCountUpdate)
