import React from 'react'
import PropTypes from 'prop-types'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { path, prop } from 'ramda'

import CardHeader from '~/components/Cards/CardHeader'
import TextLabelLoader from '~/components/TextLabelLoader'

function BillingAddressDetail ({ addresses, title }) {
  return (
    <>
      {addresses.map((address, index) => {
        const firstName = prop('firstName', address)
        const lastName = prop('lastName', address)
        const mobile = prop('mobile', address)
        const addressName = prop('address', address)
        const region = prop('region', address)
        const city = prop('city', address)
        const zipcode = prop('zipcode', address)
        const country = path(['country', 'name'], address)

        return (
          <Box mb={3} key={index}>
            <Card>
              <CardHeader
                sx={{ height: 56 }}
                title={(
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">{title}</Typography>
                  </Box>
                )}

              />
              <Divider />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={12} lg={6}>
                    <TextLabelLoader label="First name">
                      {firstName}
                    </TextLabelLoader>

                  </Grid>

                  <Grid item={true} xs={12} lg={6}>
                    <TextLabelLoader label="Last name">
                      {lastName}
                    </TextLabelLoader>
                  </Grid>

                  <Grid item={true} xs={12}>
                    <TextLabelLoader label="Phone number">
                      {mobile}
                    </TextLabelLoader>
                  </Grid>

                  <Grid item={true} xs={12}>
                    <TextLabelLoader label="Address">
                      {addressName}
                    </TextLabelLoader>
                  </Grid>

                  <Grid item={true} xs={6}>
                    <TextLabelLoader label="Region/Neighbouring/State">
                      {region}
                    </TextLabelLoader>
                  </Grid>

                  <Grid item={true} xs={6}>
                    <TextLabelLoader label="City">
                      {city}
                    </TextLabelLoader>
                  </Grid>

                  <Grid item={true} xs={6}>
                    <TextLabelLoader label="Zipcode">
                      {zipcode}
                    </TextLabelLoader>
                  </Grid>

                  <Grid item={true} xs={6}>
                    <TextLabelLoader label="Country">
                      {country}
                    </TextLabelLoader>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

          </Box>
        )
      }
      )}
    </>
  )
}

BillingAddressDetail.defaultProps = {
  addresses: []
}

BillingAddressDetail.propTypes = {
  addresses: PropTypes.array.isRequired,
  title: PropTypes.string,
}

export default BillingAddressDetail
