import React, { Suspense } from 'react'
import PropTypes from 'prop-types'
import LinearProgress from '@mui/material/LinearProgress'
import MUIContainer from '@mui/material/Container'
import { styled } from '@mui/material'

const RootStyled = styled(MUIContainer)(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    padding: 0
  }
}))

const ContentStyled = styled(MUIContainer)(({ theme }) => ({
  flexGrow: 1,
  maxWidth: '100%',
  padding: '5px',
  [theme.breakpoints.down('md')]: {
    left: 0,
    top: 0,
    transform: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    padding: 0
  }
}))

function CenteredLayout ({ children }) {
  return (
    <RootStyled>
      <ContentStyled>
        <Suspense fallback={<LinearProgress />}>
          {children}
        </Suspense>
      </ContentStyled>
    </RootStyled>
  )
}

CenteredLayout.propTypes = {
  children: PropTypes.node
}

export default CenteredLayout
