import React from 'react'
import Grid from '@mui/material/Grid'

import TextField from '~/components/Fields/TextField'

function MagentoCreate () {
  return (
    <>
      <Grid item={true} xs={12} lg={12}>
        <TextField
          data-cy="name"
          name="name"
          label="Name"
        />
      </Grid>

      <Grid item={true} xs={12}>
        <TextField
          data-cy="description"
          name="description"
          label="Description"
          multiline={true}
          rows="4"
        />
      </Grid>
    </>
  )
}

export default MagentoCreate
