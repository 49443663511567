import React, { useCallback } from 'react'
import { FieldArray } from 'react-final-form-arrays'
import { defaultTo, path, pathOr, prop, propOr } from 'ramda'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell/TableCell'
import TableBody from '@mui/material/TableBody'
import { useField } from 'react-final-form'
import AddIcon from '@mui/icons-material/Add'
import IconButton from '@mui/material/IconButton'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import Delete from '@mui/icons-material/Delete'

import SkeletonList from '~/components/Skeleton'
import EmptyLineItems from '~/components/EmptyLineItems'
import { Button } from '~/components/Buttons'
import TextField from '~/components/Fields/TextField'
import useDialog from '~/hooks/useDialog'
import Avatar from '~/components/Avatar'
import VerticalAlignment from '~/components/VerticalAlignment'
import TextOverflow from '~/components/TextOverflow'
import Subtext from '~/components/Subtext'
import TableDateFormat from '~/components/TableDateFormat/TableDateFormat'
import ConstrainedField from '~/components/Fields/ConstrainedField'
import LocationField from '~/components/Fields/LocationField'
import * as API from '~/constants/api'
import ContainerField from '~/components/Fields/ContainerField'

import MovementUnitAddDialog from '../MovementUnitAdd/MovementUnitAddDialog'

function LineItems ({ isLoading, movementUnitList }) {
  const { t } = useTranslation()
  const { handleClose, handleOpen, open } = useDialog()

  const warehouse = useField('warehouse')
  const warehouseId = path(['input', 'value', 'id'], warehouse)
  const warehouseGuid = path(['input', 'value', 'guid'], warehouse)

  const lineItemsField = useField('lineItems')
  const lineItemsFieldValue = lineItemsField.input.value
  const lineItemsFieldError = pathOr('', ['meta', 'submitError', 0], lineItemsField)
  const lineItemsFieldChange = lineItemsField.input.onChange

  const searchField = useField('search')
  const searchFieldChange = searchField.input.onChange

  const fromLocationField = useField('fromLocation')
  const locationId = path(['input', 'value', 'id'], fromLocationField)

  const handleOnSearch = useCallback(value => {
    searchFieldChange(value)
    handleOpen()
  }, [handleOpen, searchFieldChange])

  const handleOnAddClose = useCallback(() => {
    searchFieldChange('')
    handleClose()
  }, [handleClose, searchFieldChange])

  const handleAddUnits = useCallback(units => {
    const newUnits = units.map(unit => ({
      totalQuantity: propOr(1, 'quantity', unit),
      unit,
      quantity: 1
    }))
    lineItemsFieldChange([...lineItemsFieldValue, ...newUnits])
    handleClose()
  }, [lineItemsFieldValue, lineItemsFieldChange, handleClose])

  return (
    <FieldArray name="lineItems">
      {({ fields }) => {
        const values = defaultTo([], fields.value)

        const renderLineItems = Boolean(!isLoading && values.length)
        const renderEmptyLineItems = Boolean(!isLoading && !values.length)

        return <>
          <Box sx={{ padding: '16px' }}>
            <Grid container={true} spacing={3}>
              <Grid item={true} lg={10} xs={12}>
                <TextField
                  data-cy="variant"
                  name="variant"
                  label="Search"
                  onChange={event => warehouseId && handleOnSearch(event.target.value)}
                  disabled={!warehouseId}
                  error={lineItemsFieldError}
                />
              </Grid>
              <Grid item={true} lg={2} xs={12}>
                <Button
                  fullWidth={true}
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={handleOpen}
                  type="button"
                  data-cy="browse"
                  disabled={!warehouseId}
                >
                  Browse
                </Button>
              </Grid>
            </Grid>
            {open && (
              <MovementUnitAddDialog
                onClose={handleOnAddClose}
                open={open}
                onAddVariants={handleAddUnits}
                params={{ warehouseId, locationId }}
                movementUnitList={movementUnitList}
              />
            )}
          </Box>
          <TableContainer>
            <Table size="small" sx={{ minWidth: '700px' }}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('Image')}</TableCell>
                  <TableCell sx={{ minWidth: 250 }}>{t('Variant')}</TableCell>
                  <TableCell sx={{ minWidth: 150 }}>{t('Unit number')}</TableCell>
                  <TableCell>{t('Total quantity')}</TableCell>
                  <TableCell>{t('Quantity')}</TableCell>
                  <TableCell sx={{ minWidth: 150 }}>{t('Serial number')}</TableCell>
                  <TableCell sx={{ minWidth: 150 }}>{t('Batch number')}</TableCell>
                  <TableCell>{t('Condition code')}</TableCell>
                  <TableCell sx={{ minWidth: 150 }}>{t('Expiry date')}</TableCell>
                  <TableCell sx={{ minWidth: 150 }}>{t('From location')}</TableCell>
                  <TableCell sx={{ minWidth: 150 }}>{t('From container')}</TableCell>
                  <TableCell sx={{ minWidth: 150 }}>{t('To location')}</TableCell>
                  <TableCell sx={{ minWidth: 150 }}>{t('To container')}</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading && <SkeletonList columnCount={7} />}
                {renderEmptyLineItems && <EmptyLineItems colSpan={7} />}
                {renderLineItems && values.map((item, index) => {
                  const unit = prop('unit', item)
                  const variantName = path(['variant', 'name'], unit)
                  const sku = path(['variant', 'sku'], unit)
                  const imagePath = path(['variant', 'defaultImage'], unit)
                  const unitNumber = prop('unitNumber', unit)
                  const totalQuantity = prop('totalQuantity', item)
                  const serialNumber = prop('serialNumber', unit)
                  const batchNumber = prop('batchNumber', unit)
                  const conditionCode = path(['condition', 'code'], unit)
                  const expiresAt = prop('expiresAt', unit)
                  const fromContainer = path(['container', 'number'], unit)
                  const fromLocation = path(['location', 'locationId'], unit)
                  const toLocationGuid = path(['toLocation', 'guid'], item)

                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <Avatar
                          alt={variantName}
                          src={imagePath}
                        />
                      </TableCell>
                      <TableCell>
                        <VerticalAlignment
                          primary={(
                            <TextOverflow selfTooltip={true}>
                              {variantName}
                            </TextOverflow>
                          )}
                          secondary={<Subtext lines={1}>{sku}</Subtext>}
                        />
                      </TableCell>
                      <TableCell>{unitNumber}</TableCell>
                      <TableCell align="center">{totalQuantity}</TableCell>
                      <TableCell align="center">
                        <ConstrainedField
                          data-cy={`lineItems[${index}].quantity`}
                          name={`lineItems[${index}].quantity`}
                          maxValue={totalQuantity}
                        />
                      </TableCell>
                      <TableCell>{serialNumber}</TableCell>
                      <TableCell>{batchNumber}</TableCell>
                      <TableCell>{conditionCode}</TableCell>
                      <TableCell>
                        {expiresAt && <TableDateFormat date={expiresAt} />}
                      </TableCell>
                      <TableCell>{fromLocation}</TableCell>
                      <TableCell>{fromContainer}</TableCell>
                      <TableCell>
                        <LocationField
                          data-cy={`lineItems[${index}].toLocation`}
                          name={`lineItems[${index}].toLocation`}
                          api={API.STOCKS_LOCATION_LIST}
                          params={{ warehouseId }}
                          ListboxProps={{ 'data-cy': 'locationList' }}
                        />
                      </TableCell>
                      <TableCell>
                        <ContainerField
                          data-cy={`lineItems[${index}].toContainer`}
                          name={`lineItems[${index}].toContainer`}
                          api={API.CONTAINER_LIST}
                          params={{ warehouseGuid, locationGuid: toLocationGuid }}
                          ListboxProps={{ 'data-cy': 'containerList' }}
                          disabled={!toLocationGuid}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <IconButton onClick={() => fields.remove(index)} size="large">
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      }}
    </FieldArray>
  )
}

LineItems.propTypes = {
  isLoading: PropTypes.bool,
  movementUnitList: PropTypes.object.isRequired
}

export default LineItems
