const KEY = 'token'

const toObject = JSON.parse
const toJSON = JSON.stringify

export const setToken = token => {
  if (token) {
    localStorage.setItem(KEY, toJSON(token))
  } else {
    localStorage.removeItem(KEY)
  }
}

export const getToken = () => {
  const token = localStorage.getItem(KEY)

  if (token) {
    return toObject(token)
  }

  return null
}
