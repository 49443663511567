import React from 'react'
import { generatePath, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { propEq } from 'ramda'

import * as ROUTES from '~/constants/routes'
import { Button, EditButton } from '~/components/Buttons'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import * as NAV from '~/constants/nav-titles'
import { SALE_RETURN_TABS } from '~/constants/tabs'
import History from '~/components/History'
import LoaderProvider from '~/components/Loader'
import { NEW } from '~/components/Statuses/ReturnStatus'

import ReturnDetailGeneral from './ReturnDetailGeneral'
import ReturnDetailTabs from './ReturnDetailTabs'

function ReturnDetail (props) {
  const {
    orderReturnHistory,
    detail,
    pageTitle,
    pageTitleLoading,
    isLoading,
    onProcess
  } = props

  const { guid, tab } = useParams()

  const isNew = propEq('status', NEW, detail)
  const editPath = generatePath(ROUTES.RETURN_UPDATE_PATH, { guid, tab })

  return (
    <LoaderProvider isLoading={isLoading}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={pageTitle}
            pageTitleLoading={pageTitleLoading}
            parentTitle={NAV.SALE_ORDERS}
            rightButton={(
              <>
                {isNew && (
                  <Button
                    variant="contained"

                    data-cy="process"
                    onClick={() => onProcess()}
                  >
                    Process
                  </Button>
                )}
                <EditButton path={editPath} disabled={isLoading} />
              </>
            )}
          />
        </Header>
        <Content>
          <ReturnDetailTabs value={tab} />
          {tab === SALE_RETURN_TABS.GENERAL && (
            <ReturnDetailGeneral
              detail={detail}
              isLoading={pageTitleLoading}
            />
          )}
          {SALE_RETURN_TABS.HISTORY === tab && <History history={orderReturnHistory} />}
        </Content>
      </Container>
    </LoaderProvider>
  )
}

ReturnDetail.propTypes = {
  orderReturnHistory: PropTypes.object,
  detail: PropTypes.object.isRequired,
  pageTitle: PropTypes.object.isRequired,
  pageTitleLoading:PropTypes.bool,
  isLoading: PropTypes.bool,
  onProcess: PropTypes.func
}

export default ReturnDetail
