import React from 'react'
import { useSnackbar } from 'storfox-snackbar'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { prop } from 'ramda'
import { useAllSearchParams, useRoute } from 'storfox-route-hooks'
import useDeepCompareEffect from 'storfox-api-hooks/src/useDeepCompareEffect'

import { DashboardLayout } from '~/components/Layouts'
import { ACTIVE } from '~/components/Fields/StatusField'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import useMessages from '~/hooks/useMessages'
import { INTEGRATION_TABS } from '~/constants/tabs'

import ShopifyIntegrationCreateForm from '../../components/Shopify/ShopifyCreate/ShopifyIntegrationCreateForm'
import { useShopifyIntegrationCreate, useShopifyCallback } from '../../hooks/'

function ShopifyIntegrationCreateContainer () {
  const { t } = useTranslation()
  const { type } = useParams()
  const { replaceParams } = useRoute()
  const messages = useMessages()
  const params = useAllSearchParams()
  const integrationCreate = useShopifyIntegrationCreate()
  const shopifyCallback = useShopifyCallback()
  const snackbar = useSnackbar()
  const navigate = useNavigate()

  const channelAccessToken = prop('token', params)
  const title = 'New Integration'

  const handleSubmit = formValues =>
    integrationCreate
      .create(formValues)
      .then(({ result }) => {
        const guid = prop('guid', result)
        const type = prop('type', result)
        const params = { guid, type, tab: INTEGRATION_TABS.GENERAL }
        const detailPath = generatePath(ROUTES.INTEGRATION_UPDATE_PATH, params)
        navigate(detailPath)
      })
      .then(() => snackbar({ message: messages.CREATE_SUCCESS }))

  useDeepCompareEffect(() => {
    shopifyCallback.create(params)
      .then(data => {
        const token = prop('channelAccessToken', data)

        if (!channelAccessToken) {
          replaceParams({ token })
        }
      })
  }, [])

  const isLoading = (
    integrationCreate.isLoading ||
    shopifyCallback.isLoading
  )

  const initialValues = {
    type,
    status: ACTIVE,
    credential: {
      storeDomain: prop('shop', params),
      channelAccessToken
    },
    configuration: {
      isProductWebhookEnabled: false,
      isStockSyncEnabled: true
    }
  }

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={t(title)}
      isLoading={integrationCreate.isLoading}
      activeNav={NAV.INTEGRATION}
      breadcrumbs={breadcrumbs}
    >
      <ShopifyIntegrationCreateForm
        onSubmit={handleSubmit}
        initialValues={initialValues}
        pageTitle={t(title)}
        parentTitle={t(NAV.SETTINGS)}
        isLoading={isLoading}
      />
    </DashboardLayout>
  )
}

export default ShopifyIntegrationCreateContainer
