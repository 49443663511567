import React from 'react'
import { prop } from 'ramda'
import { SearchField } from 'storfox-form-fields'

import * as API from '~/constants/api'

const getOptionValue = value => {
  if (value) {
    const id = prop('id', value)
    const guid = prop('guid', value)
    const name = prop('name', value)

    return { id, guid, name }
  }

  return null
}

function OverviewWarehouseSearchField (props) {
  return (
    <SearchField
      name="warehouse"
      label="Warehouse"
      primaryKey="guid"
      api={API.WAREHOUSE_LIST}
      getOptionValue={getOptionValue}
      {...props}
    />
  )
}

export default OverviewWarehouseSearchField
