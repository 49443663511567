import React from 'react'
import { useFilter, useOrdering } from 'storfox-filter'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import { useCompany } from '~/components/Profile'
import * as NAV from '~/constants/nav-titles'
import useMessages from '~/hooks/useMessages'
import ErrorLink from '~/components/ErrorLink'
import { useTableSelects } from '~/components/TableValues/hooks'

import {
  InvoiceFilterForm,
  invoiceFilterOptions,
  InvoiceOrderingForm,
  invoiceOrderingOptions,
  InvoiceTable
} from '../components/InvoiceList'
import { useInvoiceList, useInvoicesDelete } from '../hooks'

function InvoiceListContainer () {
  const snackbar = useSnackbar()
  const messages = useMessages()
  const { isRetailer } = useCompany()
  const { selects, resetTableSelects } = useTableSelects()

  const invoiceList = useInvoiceList()
  const invoicesDelete = useInvoicesDelete()

  const filter = useFilter(invoiceFilterOptions)
  const ordering = useOrdering(invoiceOrderingOptions)

  const handleInvoicesDelete = () =>
    invoicesDelete.delete({ guids: selects })
      .then(() => snackbar({ message: messages.DELETE_SUCCESS }))
      .then(() => resetTableSelects())
      .then(() => invoiceList.getList())
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const breadcrumbs = { title: NAV.INVOICES }
  return (
    <DashboardLayout
      activeNav={NAV.INVOICES}
      title={NAV.INVOICES}
      breadcrumbs={breadcrumbs}
    >
      <InvoiceFilterForm {...filter} companyDisabled={isRetailer} />
      <InvoiceOrderingForm {...ordering} />

      <InvoiceTable
        list={invoiceList}
        filter={filter}
        ordering={ordering}
        onListRefetch={invoiceList.getListByParams}
        onInvoicesDelete={handleInvoicesDelete}
        bulkActionLoading={invoicesDelete.isLoading}
      />
    </DashboardLayout>
  )
}

export default InvoiceListContainer
