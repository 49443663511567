import { sprintf } from 'sprintf-js'
import { useCreate, useDelete, useDetail, useList, useUpdate } from 'storfox-api-hooks'
import { usePickSearchParams } from 'storfox-route-hooks'

import { orderingToSnackCase } from '~/utils'
import * as API from '~/constants/api'

export const useAreaList = () => {
  const searchParams = usePickSearchParams()
  return useList(API.AREA_LIST, orderingToSnackCase(searchParams))
}

export const useAreaDetail = guid => {
  const url = sprintf(API.AREA_DETAIL, guid)
  return useDetail(url)
}

export const useAreaCreate = () => {
  return useCreate(API.AREA_CREATE)
}

export const useAreaUpdate = guid => {
  const url = sprintf(API.AREA_UPDATE, guid)
  return useUpdate(url)
}

export const useAreaRemove = guid => {
  const url = sprintf(API.AREA_DELETE, guid)
  return useDelete(url)
}

export const useWarehouseDetail = guid => {
  const url = sprintf(API.WAREHOUSE_DETAIL, guid)
  return useDetail(url)
}
