import React, { useMemo } from 'react'
import { useField } from 'react-final-form'
import { prop } from 'ramda'

import MultiSelectSearchField from '~/components/Fields/MultiSelectSearchField'
import * as API from '~/constants/api'

const getOptionLabel = item => {
  if (item) {
    return prop('number', item)
  }

  return ''
}

const getOptionValue = item => {
  if (item) {
    const id = prop('objectId', item)
    const number = prop('number', item)

    return { id, number }
  }

  return null
}

function ReceivingItemField (props) {
  const objectTypesField = useField('objectTypes')
  const objectTypesFieldValue = objectTypesField.input.value

  const receiveType = useMemo(() => {
    if (objectTypesFieldValue) {
      return objectTypesFieldValue.map((objectType) => objectType.value).join(',')
    } else return `PurchaseOrder,StockTransfer,Return`
  }, [objectTypesFieldValue])

  return (
    <MultiSelectSearchField
      name="objects"
      label="Receive numbers"
      api={API.RECEIVE_LIST}
      params={{ objectTypes: receiveType, limit: 10 }}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      {...props}
    />
  )
}

export default ReceivingItemField
