import React, { useCallback, useState } from 'react'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Drawer, { drawerClasses } from '@mui/material/Drawer'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { useDeepCompareEffect } from 'storfox-api-hooks'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'

import { MENU_WIDTH, SHRINK_MENU_WIDTH } from '~/constants/settings'

import NavItem from './NavItem'
import ShrinkNavItem from './ShrinkNavItem'
import Nav from './Nav'
import TopMenu from './TopMenu'
import MobileTopMenu from './MobileTopMenu'

const DesktopDrawerStyled = styled(Drawer, {
  shouldForwardProp: propName => propName !== 'isShrink'
})(({ theme, isShrink }) => ({
  [`& .${drawerClasses.paper}`]: {
    width: MENU_WIDTH,
    border: 'none',
    borderRight: '1px solid',
    borderRightColor: theme.components.StorfoxSidebar.styleOverrides.border.color,
    background: theme.components.StorfoxSidebar.styleOverrides.background.color,
    height: '100%',
    zIndex: 1102,
    overflow: 'visible',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    ...(isShrink && {
      width: SHRINK_MENU_WIDTH,
      overflow: 'visible'
    })
  }
}))

const MobileSwipeableDrawerStyled = styled(({ className, ...props }) => (
  <SwipeableDrawer {...props} classes={{ paper: className }} />
))(({ theme }) => ({
  border: 'none',
  width: 256,
  overflow: 'auto',
  background: theme.components.StorfoxSidebar.styleOverrides.background.color,
  '&::-webkit-scrollbar': {
    display: 'none'
  }
}))

function Sidebar ({
  openDesktop,
  openMobile,
  onDesktopToggle,
  onMobileClose,
  className,
  activeNav,
  onMobileOpen,
  ...rest
}) {
  const location = useLocation()
  const [openPath, setOpenPath] = useState('')

  const handleToggleMenuItem = useCallback((pathName) => {
    setOpenPath(openPath !== pathName ? pathName : '')
  }, [openPath])

  useDeepCompareEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose()
    }
  }, [location.pathname])

  return (
    <>
      <MobileSwipeableDrawerStyled
        anchor="left"
        onClose={onMobileClose}
        SwipeAreaProps={{ sx: { display: { xs: 'block', lg: 'none' } } }}
        open={openMobile}
        onOpen={onMobileOpen}
        variant="temporary"
        sx={{ display: { xs: 'block', lg: 'none' } }}
      >
        <Box
          {...rest}
          display="flex"
          flexDirection="column"
          height="auto"
          className={className}
        >
          <MobileTopMenu />
          <Nav
            openPath={openPath}
            handleToggleMenuItem={handleToggleMenuItem}
            activeNav={activeNav}
            NavItem={NavItem}
            isShrink={false}
          />
        </Box>
      </MobileSwipeableDrawerStyled>

      <DesktopDrawerStyled
        anchor="left"
        isShrink={!openDesktop}
        open={openDesktop}
        variant="permanent"
        sx={{ display: { xs: 'none', lg: 'block' } }}
      >
        <Box
          {...rest}
          display="flex"
          flexDirection="column"
          height="auto"
          sx={{ height: openDesktop ? 'auto' : '100%' }}
        >
          <TopMenu openDesktop={openDesktop} onDesktopToggle={onDesktopToggle} />
          <Nav
            openPath={openPath}
            handleToggleMenuItem={handleToggleMenuItem}
            activeNav={activeNav}
            NavItem={openDesktop ? NavItem : ShrinkNavItem}
            isShrink={!openDesktop}
          />
        </Box>
      </DesktopDrawerStyled>
    </>
  )
}

Sidebar.propTypes = {
  onDesktopToggle: PropTypes.func,
  openDesktop: PropTypes.bool,
  onMobileClose: PropTypes.func,
  onMobileOpen: PropTypes.func,
  openMobile: PropTypes.bool,
  closeDrawer: PropTypes.func,
  className: PropTypes.object,
  activeNav: PropTypes.string
}

export default React.memo(Sidebar)
