import React from 'react'
import { useSnackbar } from 'storfox-snackbar'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'

import * as EVENTS from '../../constants/AutomationRule'
import AutomationRuleCreate from '../../components/AutomationRule/AutomationRuleCreate'
import { useAutomationRuleCreate } from '../../hooks/AutomationRule'
import { AutomationRuleCreateSerializer } from '../../serializers/AutomationRule'

function AutomationRuleCreateContainer () {
  const { t } = useTranslation()
  const automationRuleCreate = useAutomationRuleCreate()
  const snackbar = useSnackbar()
  const navigate = useNavigate()

  const message = t('Successfully created')

  const handleSubmit = formValues =>
    automationRuleCreate.create(AutomationRuleCreateSerializer(formValues))
      .then(() => snackbar({ message }))
      .then(() => navigate(ROUTES.AUTOMATION_LIST_PATH))

  const title = 'Create automation rule'

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      activeNav={NAV.CONFIGURATION}
      breadcrumbs={breadcrumbs}
    >
      <AutomationRuleCreate
        pageTitle={title}
        isLoading={automationRuleCreate.isLoading}
        initialValues={{ event: EVENTS.ORDER_CREATED, actions: [{}] }}
        onSubmit={handleSubmit}
      />
    </DashboardLayout>
  )
}

export default AutomationRuleCreateContainer
