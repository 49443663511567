import React from 'react'
import { Dialog, DialogActions, DialogContent } from '@mui/material'
import Grid from '@mui/material/Grid'
import PropTypes from 'prop-types'
import { useField, useFormState } from 'react-final-form'
import { isNil, prop } from 'ramda'
import { DateTime } from 'luxon'

import DialogTitle from '~/components/DialogTitle'
import { withForm } from '~/components/Form'
import { DiscardButton, PrimaryButton } from '~/components/Buttons'
import DateField from '~/components/Fields/DateField'

function ShipmentExportDialogForm ({ open, onClose, form }) {
  const { handleSubmit } = form
  const { values } = useFormState()

  const fromDateField = useField('fromDatetime')
  const fromDate = fromDateField.input.value

  const minDate = DateTime.fromISO(fromDate).plus({ days: 1 })
  const maxDate = DateTime.fromISO(fromDate).plus({ days: 30 })

  const disabled = (
    isNil(prop('fromDatetime', values)) ||
    isNil(prop('toDatetime', values))
  )

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          Export billing
        </DialogTitle>
        <DialogContent dividers={true} sx={{ minHeight: '30vh' }}>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={6}>
              <DateField
                data-cy="fromDatetime"
                name="fromDatetime"
                label="From Date"
              />
            </Grid>
            <Grid item={true} xs={6}>
              <DateField
                data-cy="toDatetime"
                name="toDatetime"
                label="To Date"
                disabled={maxDate.invalid}
                minDate={minDate}
                maxDate={maxDate}
                maxDateMessage="Range must be maximum 30 days"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DiscardButton onClick={onClose} fullWidth={true} />
          <PrimaryButton
            type="submit"
            fullWidth={true}
            disabled={disabled}
          >
            Export
          </PrimaryButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}

ShipmentExportDialogForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  form: PropTypes.shape({
    initialValues: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired
  }).isRequired
}

export default withForm(ShipmentExportDialogForm)
