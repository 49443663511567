import React from 'react'

import StorfoxShipmentStatusField, { OPTIONS as BASE_OPTIONS } from '~/components/Fields/ShipmentStatusField'

const OPTIONS = [{ value: '', name: 'All' }, ...BASE_OPTIONS]

function ShipmentStatusField (props) {
  return (
    <StorfoxShipmentStatusField
      name="shipmentStatus"
      label="Shipment status"
      options={OPTIONS}
      {...props}
    />
  )
}

export default ShipmentStatusField
