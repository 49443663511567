import React from 'react'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import PropTypes from 'prop-types'
import { useField } from 'react-final-form'
import { any, isEmpty, propOr } from 'ramda'

import { CardHeader } from '~/components/Cards'
import TextField from '~/components/Fields/TextField'
import { withForm } from '~/components/Form'
import { DiscardButton, SaveButton } from '~/components/Buttons'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import * as NAV from '~/constants/nav-titles'
import StatusField from '~/components/Fields/StatusField'

import QueryBuilder from '../Fields/QueryBuilder'
import EventSelectField from '../Fields/EventSelectField'
import ActionCard from '../Actions/ActionCard'
import SkeletonCard from '../Actions/SkeletonCard'
import { LOW_STOCK } from '../../../constants/AutomationRule'

function AutomationRuleCreate ({ pageTitle, pageTitleLoading, isLoading, detailLoading, form }) {
  const eventField = useField('event')
  const event = eventField.input.value || ''
  const ruleField = useField('rule')
  const rule = ruleField.input.value
  const rules = propOr([], 'rules', rule)
  const actionsField = useField('actions')
  const actions = actionsField.input.value

  const hasAction = any(el => !isEmpty(el), actions)
  const lowStockDisabled = isLoading || !hasAction
  const createDisabled = isLoading || !rules.length
  const disabled = event === LOW_STOCK ? lowStockDisabled : createDisabled

  const { handleSubmit } = form

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={pageTitle}
            pageTitleLoading={pageTitleLoading}
            parentTitle={NAV.CONFIGURATION}
            rightButton={(
              <>
                <DiscardButton />
                <SaveButton disabled={disabled} />
              </>
            )}
          />
        </Header>
        <Content>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12}>
              <Card>
                <CardHeader title="General" />
                <Divider />
                <CardContent>
                  <Grid container={true} spacing={3}>
                    <Grid item={true} xs={12} lg={4}>
                      <TextField
                        name="title"
                        label="Title"
                      />
                    </Grid>
                    <Grid item={true} xs={12} lg={4}>
                      <EventSelectField />
                    </Grid>
                    <Grid item={true} xs={12} lg={4}>
                      <StatusField />
                    </Grid>
                    <Grid item={true} xs={12}>
                      <TextField
                        name="description"
                        label="Description"
                        multiline={true}
                        minRows={4}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

            </Grid>
            <Grid item={true} xs={12} lg={7}>
              <Card>
                <CardHeader title="Condition" />
                <Divider />
                <QueryBuilder event={event} />
              </Card>
            </Grid>
            <Grid item={true} xs={12} lg={5}>
              {!detailLoading ? <ActionCard event={event} /> : <SkeletonCard />}
            </Grid>
          </Grid>
        </Content>
      </Container>
    </form>
  )
}

AutomationRuleCreate.propTypes = {
  pageTitle: PropTypes.string,
  pageTitleLoading: PropTypes.bool,
  detailLoading: PropTypes.bool,
  form: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default withForm(AutomationRuleCreate)
