import { compose, defaultTo, filter, find, not, path, pathEq, pipe, prop, propEq, propOr, replace } from 'ramda'

export const getConditionCodeWithoutPrefix = replace(/^cd-|^CD-/, '')

const getItemMatchingActiveItem = (activeItem, items) => {
  const barcode = path(['variant', 'barcode'], activeItem)

  const foundItemsByBarcode = filter(pathEq(['variant', 'barcode'], barcode), items)
  const foundItem = foundItemsByBarcode[0]
  const foundItemTrackSerial = path(['variant', 'trackSerial'], foundItem)
  const foundItemSerialNumber = path(['serial', 'serialNumber'], foundItem)

  if (foundItemsByBarcode.length === 1 && !foundItemSerialNumber) {
    return foundItem
  }

  if (foundItemsByBarcode.length > 1 && !foundItemTrackSerial) {
    const foundByConditionCode = filter(item => {
      const itemConditionCode = path(['serial', 'conditionCode'], item)
      return pathEq(['serial', 'conditionCode'], itemConditionCode, activeItem)
    }, foundItemsByBarcode)
    return foundByConditionCode[0]
  }

  return null
}

export const getSerialWithNewQuantity = (serial, activeSerial) => {
  const lineItemQuantity = propOr(0, 'quantity', serial)
  const activeQuantity = prop('quantity', activeSerial)
  const lineItemCondition = prop('conditionCode', serial)

  return propEq('conditionCode', lineItemCondition, activeSerial)
    ? { ...activeSerial, quantity: lineItemQuantity + activeQuantity }
    : { ...activeSerial, quantity: activeQuantity }
}

export const getActiveItemMergedWithLineItems = ({ activeLineItem, lineItems }) => {
  const activeBarcode = path(['variant', 'barcode'], activeLineItem)
  const activeSerial = prop('serial', activeLineItem)
  const activeTrackSerial = path(['variant', 'trackSerial'], activeLineItem)

  const itemMatchingActiveItem = getItemMatchingActiveItem(activeLineItem, lineItems)
  const serial = prop('serial', itemMatchingActiveItem)

  if (itemMatchingActiveItem && serial) {
    const serial = propOr({}, 'serial', itemMatchingActiveItem)
    const newSerial = activeTrackSerial ? activeSerial : getSerialWithNewQuantity(serial, activeSerial)

    const newLineItem = { ...itemMatchingActiveItem, serial: newSerial }

    const otherLineItems = filter(item => {
      const differentBarcode = !pathEq(['variant', 'barcode'], activeBarcode, item)

      const itemCondition = path(['serial', 'conditionCode'], item)
      const differentCondition = !pathEq(['serial', 'conditionCode'], itemCondition, activeLineItem)

      const itemSerialNumber = path(['serial', 'serialNumber'], item)
      const differentSerialNumber = !pathEq(['serial', 'serialNumber'], itemSerialNumber, activeLineItem)

      return differentBarcode || differentCondition || differentSerialNumber
    }, lineItems)
    return [newLineItem, ...otherLineItems]
  }

  if (itemMatchingActiveItem && !serial) {
    const serial = propOr({}, 'serial', itemMatchingActiveItem)
    const newSerial = activeTrackSerial ? activeSerial : getSerialWithNewQuantity(serial, activeSerial)

    const newLineItem = { ...itemMatchingActiveItem, serial: newSerial }
    const filteredLineItems = filter(compose(not, pathEq(['variant', 'barcode'], activeBarcode)), lineItems)
    return [newLineItem, ...filteredLineItems]
  }

  const newLineItem = { ...activeLineItem, serial: activeSerial }

  return [ newLineItem, ...lineItems ]
}

export const trackExpiry = pipe(
  defaultTo([]),
  find(item => {
    const scanned = path(['serial', 'quantity'], item)
    if (scanned) {
      return path(['variant', 'trackExpiry'], item)
    } else return null
  }),
  Boolean
)
