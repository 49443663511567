import React, { useCallback, useMemo } from 'react'
import { path, pathEq, pathOr, prop, propEq, propOr } from 'ramda'
import PropTypes from 'prop-types'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { CheckboxField } from 'storfox-form-fields'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'storfox-snackbar'
import Badge from '@mui/material/Badge'
import { useField } from 'react-final-form'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import { styled } from '@mui/material'
import { DateTime } from 'luxon'

import PositiveNumberField from '~/components/Fields/PositiveNumberField'
import TextField from '~/components/Fields/TextField'
import Avatar from '~/components/Avatar/Avatar'
import VerticalAlignment from '~/components/VerticalAlignment'
import Subtext from '~/components/Subtext'
import useDialog from '~/hooks/useDialog'
import Tooltip from '~/components/HtmlTooltip'
import DeleteIconButton from '~/components/DeleteIconButton'
import TextOverflow from '~/components/TextOverflow'
import useMessages from '~/hooks/useMessages'

import ActiveSerialNumberField from '../ReceiveBarcoding/ActiveSerialNumberField'
import ActiveExpiresAtField from '../ReceiveBarcoding/ActiveExpiresAtField'
import ItemDimensionsEdit from '../ReceiveBarcoding/ItemDimensionsEdit'
import { useReceiveUpdateDimensions } from '../../hooks'
import { ReceiveDimensionsInitSerializer } from '../../serializers'

const TableRowStyled = styled(TableRow)({
  '& td': {
    borderBottom: 'none'
  }
})

function VariantPreview (props) {
  const { lineItem, fieldName, onBarcodeFocus, onPreviewDelete, editDimension, lineItemsStack } = props
  const { open, handleOpen, handleClose } = useDialog()
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const messages = useMessages()
  const variant = prop('productVariant', lineItem)
  const serial = prop('serial', lineItem)

  const activeLineItemField = useField('activeLineItem')
  const activeLineItem = activeLineItemField.input.value
  const handleActiveLineItemChange = activeLineItemField.input.onChange

  const expiryAtField = useField('expiryAt')
  const expiryAtFieldValue = expiryAtField.input.value || []
  const expiryAtFieldChange = expiryAtField.input.onChange
  const expiryAtError = pathOr(false, ['meta', 'data', 'error'], expiryAtField)

  const productionDateField = useField('productionAt')
  const productionDateFieldValue = productionDateField.input.value || []
  const productionDateFieldChange = productionDateField.input.onChange
  const productionDateError = pathOr(false, ['meta', 'data', 'error'], productionDateField)

  const lineItemsField = useField('lineItems')
  const lineItems = lineItemsField.input.value

  const minExpiryDate = pathOr(0, ['productVariant', 'minExpiryDays'], activeLineItem)
  const supplierSku = prop('supplierSku', activeLineItem)

  const name = prop('name', variant)
  const image = prop('defaultImage', variant)
  const barcode = prop('unitNumber', serial)
  const guid = prop('guid', variant)
  const sku = prop('sku', variant)
  const trackProductionDates = prop('trackProductionDates', variant)
  const trackSerial = prop('trackSerial', variant)
  const trackExpiry = prop('trackExpiry', variant)
  const trackBatchNumbers = prop('trackBatchNumbers', variant)
  const variantGuid = prop('guid', variant)
  const width = prop('width', variant)
  const height = prop('height', variant)
  const length = prop('length', variant)
  const weight = prop('weight', variant)

  const receiveUpdateDimensions = useReceiveUpdateDimensions(variantGuid)

  const handleEnter = event => {
    if (event.key === 'Enter') {
      onBarcodeFocus()
      event.preventDefault()
    }
  }

  const handleSubmit = useCallback((values) => {
    const newDimensions = ReceiveDimensionsInitSerializer(values)
    return receiveUpdateDimensions.update(newDimensions)
      .then(() => {
        handleClose()
        snackbar({ message: messages.UPDATE_SUCCESS })
        const newActiveItem = {
          ...activeLineItem,
          productVariant: {
            ...variant,
            ...newDimensions
          }
        }
        handleActiveLineItemChange(newActiveItem)
      })
  }, [
    receiveUpdateDimensions,
    handleClose,
    snackbar,
    messages,
    activeLineItem,
    variant,
    handleActiveLineItemChange
  ])

  const initialValues = { width, height, length, weight }

  const isFilled = Boolean(width || height || length || weight)

  const badgeColor = isFilled ? 'primary' : 'error'

  const toScanQuantity = useMemo(() => {
    const itemFromStack = prop(barcode, lineItemsStack)
    const itemsAsActive = lineItems.filter(item => {
      const sameGuid = propEq('guid', prop('guid', item), activeLineItem)
      const sameVariant = pathEq(['productVariant', 'guid'], path(['productVariant', 'guid'], activeLineItem), item)
      return sameGuid && sameVariant
    })
    const scannedQuantity = itemsAsActive.reduce((acc, item) => acc + pathOr(0, ['serial', 'quantity'], item), 0)
    const receivedQuantity = propOr(0, 'received', itemFromStack)
    const orderedQuantity = propOr(0, 'quantity', itemFromStack)
    const result = orderedQuantity - (scannedQuantity + receivedQuantity)
    return result < 0 ? 0 : result
  }, [lineItems, activeLineItem, lineItemsStack, barcode])

  const minDate = DateTime.now().plus({ days: minExpiryDate + 1 })

  const currentExpiry = useMemo(() => {
    if (trackExpiry) {
      const currentItem = expiryAtFieldValue.find(item => item.guid === guid)
      if (currentItem) {
        return currentItem.expiresAt
      }
    }
  }, [trackExpiry, expiryAtFieldValue, guid])

  const handleChangeExpiry = useCallback(value => {
    if (trackExpiry) {
      const newExpiryAt = { guid, expiresAt: value }
      const others = expiryAtFieldValue.filter(item => item.guid !== guid)
      expiryAtFieldChange([...others, newExpiryAt])
    }
  }, [expiryAtFieldChange, expiryAtFieldValue, guid, trackExpiry])

  const currentProductionDate = useMemo(() => {
    if (trackProductionDates) {
      const currentItem = productionDateFieldValue.find(item => item.guid === guid)
      if (currentItem) {
        return currentItem.productionDate
      }
    }
  }, [trackProductionDates, productionDateFieldValue, guid])

  const handleChangeProductionDate = useCallback(value => {
    if (trackProductionDates) {
      const newproductionDate = { guid, productionDate: value }
      const others = productionDateFieldValue.filter(item => item.guid !== guid)
      productionDateFieldChange([...others, newproductionDate])
    }
  }, [productionDateFieldChange, productionDateFieldValue, guid, trackProductionDates])

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('Image')}</TableCell>
              <TableCell>{t('Product')}</TableCell>
              <TableCell>{t('Supplier SKU')}</TableCell>
              <TableCell>{t('Barcode')}</TableCell>
              {trackSerial && (
                <TableCell>{t('Serial number')}</TableCell>
              )}
              {trackExpiry && (
                <TableCell sx={{ minWidth: 130 }}>{t('Lot expiry date')}</TableCell>
              )}
              {trackProductionDates && (
                <TableCell sx={{ minWidth: 130 }}>{t('Production date')}</TableCell>
              )}
              {trackBatchNumbers && (
                <TableCell sx={{ minWidth: 100 }}>
                  {t('Batch number')}
                </TableCell>
              )}
              <TableCell>{t('Condition code')}</TableCell>
              <TableCell>{t('Scanned')}</TableCell>
              <TableCell sx={{ minWidth: 80 }}>{t('To scan')}</TableCell>
              <TableCell colSpan={2}>{t('Reject')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {variant ? (
              <TableRowStyled>
                <TableCell sx={{ cursor: 'pointer' }}>
                  <Badge
                    variant="dot"
                    color={badgeColor}
                    invisible={!editDimension}
                    onClick={() => editDimension && handleOpen()}
                    data-cy="dimensionButton"
                  >
                    <Avatar src={image} alt="variant-preview" />
                  </Badge>
                </TableCell>
                <TableCell>
                  <Tooltip title={name}>
                    <VerticalAlignment
                      primary={<TextOverflow selfTooltip={true} lines={1}>{name}</TextOverflow>}
                      secondary={<Subtext lines={1}>{sku}</Subtext>}
                    />
                  </Tooltip>
                </TableCell>
                <TableCell>{supplierSku}</TableCell>
                <TableCell>
                  <TextOverflow selfTooltip={true} lines={1}>
                    {barcode}
                  </TextOverflow>
                </TableCell>
                {trackSerial && (
                  <TableCell>
                    <ActiveSerialNumberField
                      name={`${fieldName}.serialNumber`}
                      data-cy={`${fieldName}.serialNumber`}
                      fullWidth={true}
                      onKeyDown={handleEnter}
                      disabled={!trackSerial}
                    />
                  </TableCell>
                )}
                {trackExpiry && (
                  <TableCell>
                    <ActiveExpiresAtField
                      error={expiryAtError}
                      value={currentExpiry}
                      onChange={handleChangeExpiry}
                      dataCy={`${fieldName}.expiresAt`}
                      minDate={minDate}
                      autoOk={true}
                      disabled={!trackExpiry}
                    />
                  </TableCell>
                )}
                {trackProductionDates && (
                  <TableCell>
                    <ActiveExpiresAtField
                      error={productionDateError}
                      value={currentProductionDate}
                      onChange={handleChangeProductionDate}
                      dataCy={`${fieldName}.productionDate`}
                      autoOk={true}
                      disabled={!trackProductionDates}
                    />
                  </TableCell>
                )}
                {trackBatchNumbers && (
                  <TableCell>
                    <TextField
                      name={`${fieldName}.batchNumber`}
                      data-cy={`${fieldName}.batchNumber`}
                    />
                  </TableCell>
                )}
                <TableCell>
                  <TextField name={`${fieldName}.conditionCode`} />
                </TableCell>
                <TableCell>
                  <PositiveNumberField
                    name={`${fieldName}.quantity`}
                    onKeyDown={handleEnter}
                    data-cy={`${fieldName}.quantity`}
                    disabled={trackSerial}
                  />
                </TableCell>
                <TableCell>
                  {toScanQuantity || 0}
                </TableCell>
                <TableCell>
                  <CheckboxField
                    name={`${fieldName}.isRejected`}
                    data-cy={`${fieldName}.isRejected`}
                  />
                </TableCell>
                <TableCell align="right">
                  <DeleteIconButton data-cy="activeDelete" onClick={() => onPreviewDelete(variant)} />
                </TableCell>
              </TableRowStyled>
            ) : (
              <TableRow>
                <TableCell height={56} colSpan={11}>Empty</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <ItemDimensionsEdit
        onSubmit={handleSubmit}
        initialValues={initialValues}
        variant={variant}
        onClose={handleClose}
        open={open}
        isLoading={receiveUpdateDimensions.isLoading}
      />
    </>
  )
}

VariantPreview.propTypes = {
  fieldName: PropTypes.string.isRequired,
  onBarcodeFocus: PropTypes.func.isRequired,
  lineItem: PropTypes.object,
  editDimension: PropTypes.bool,
  onPreviewDelete: PropTypes.func.isRequired,
  lineItemsStack: PropTypes.object
}

export default VariantPreview
