import { map } from 'ramda'

const convertToBoolean = (string) => {
  if (string === 'true') {
    return true
  } else if (string === 'false') {
    return false
  } else return string
}

export const stringToBoolean = (values) => map(convertToBoolean, values)
