import React from 'react'
import { useFilter, useOrdering } from 'storfox-filter'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import useMessages from '~/hooks/useMessages'
import ErrorLink from '~/components/ErrorLink'
import { useTableSelects } from '~/components/TableValues/hooks'

import {
  ContainerTypeFilterForm,
  containerTypeFilterOptions,
  ContainerTypeOrderingForm,
  containerTypeOrderingOptions,
  ContainerTypeTable
} from '../../components/ContainerType/ContainerTypeList'
import { useContainerTypeBulkRemove, useContainerTypeList } from '../../hooks/ContainerType'

function ContainerTypeListContainer () {
  const containerTypeList = useContainerTypeList()
  const containerTypeBulkRemove = useContainerTypeBulkRemove()
  const snackbar = useSnackbar()
  const messages = useMessages()
  const { selects, resetTableSelects } = useTableSelects()

  const filter = useFilter(containerTypeFilterOptions)
  const ordering = useOrdering(containerTypeOrderingOptions)

  const breadcrumbs = { title: 'Container type' }

  const handleContainerTypesDelete = () =>
    containerTypeBulkRemove.delete({ guids : selects })
      .then(() => snackbar({ message: messages.DELETE_SUCCESS }))
      .then(() => resetTableSelects())
      .then(() => containerTypeList.getList())
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  return (
    <DashboardLayout
      title="Container type"
      breadcrumbs={breadcrumbs}
      activeNav={NAV.CONFIGURATION}
    >
      <ContainerTypeFilterForm {...filter} />
      <ContainerTypeOrderingForm {...ordering} />

      <ContainerTypeTable
        list={containerTypeList}
        filter={filter}
        onContainerTypesDelete={handleContainerTypesDelete}
        onListRefetch={containerTypeList.getListByParams}
        ordering={ordering}
        bulkActionLoading={containerTypeBulkRemove.isLoading}
      />
    </DashboardLayout>
  )
}

export default ContainerTypeListContainer
