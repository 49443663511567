import React from 'react'

import SelectField from '~/components/Fields/SelectField'

const OPTIONS = [
  {
    value: 'production',
    name: 'Production'
  },
  {
    value: 'testing',
    name: 'Testing'
  }
]

function ServiceTypeField (props) {
  return (
    <SelectField
      label="Service type"
      options={OPTIONS}
      {...props}
    />
  )
}

export default ServiceTypeField
