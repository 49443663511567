import React from 'react'
import AlertTitle from '@mui/material/AlertTitle'
import Alert from '@mui/material/Alert'
import { find } from 'ramda'
import PropTypes from 'prop-types'

import { SKIP_PROCESS_KEYS } from '../../../constants'

function OrderSplitInstruction ({ keys }) {
  const getInstruction = () => {
    const isPicklist = find(key => key === SKIP_PROCESS_KEYS.PICKLIST, keys)
    const isPacking = find(key => key === SKIP_PROCESS_KEYS.PACKING, keys)

    if (isPicklist && isPacking) {
      return (
        `Skip both picking and packing steps
        (unit/container scanning, packaging) and get
        your packages directly to shipping location.`
      )
    }

    if (isPicklist) {
      return 'Skip digital picking so that users do not need to process/scan units during picking process.'
    }

    if (isPacking) {
      return (
        `Skip the extra packing steps
        (unit/container scanning) and get your packages
        to the shipping location directly.`
      )
    }

    return 'Forces users to go through both picking and packing steps. (Digital picking, packing)'
  }

  return (
    <>
      <Alert severity="info" icon={false}>
        <AlertTitle>Information</AlertTitle>
        {getInstruction()}
      </Alert>
    </>
  )
}

OrderSplitInstruction.propTypes = {
  keys: PropTypes.array.isRequired
}

export default OrderSplitInstruction
