import { pick, prop } from 'ramda'
import { DEFAULT_SEARCH_PARAMS } from 'storfox-route-hooks'

import { escapeAtob, orderingToSnackCase } from '~/utils'

export const OrderUnitsFilterSerializer = params => {
  const defaultParams = pick(DEFAULT_SEARCH_PARAMS, params)
  const warehouseGuids = escapeAtob(prop('warehouses', params))
  const companyGuids = escapeAtob(prop('company', params))
  const createdAtStart = prop('createdAtStart', params)
  const createdAtEnd = prop('createdAtEnd', params)
  const toObjectGuids = escapeAtob(prop('toObjectGuids', params))

  return orderingToSnackCase({
    ...defaultParams,
    warehouseGuids,
    companyGuids,
    createdAtStart,
    createdAtEnd,
    toObjectGuids
  })
}
