import { filter, find, map, path, pathEq, pipe, prop, propEq } from 'ramda'

import { matchContainer } from '~/utils'

export const findUnitByGuid = (guid, units) => find(propEq('guid', guid), units)

export const getContainerUnits = (containerNumber, units) => filter(
  pathEq(['container', 'number'], containerNumber)
  , units)

export const getUnitsFromContainer = (barcode, containerNumber, units) =>
  filter(unit => (
    pathEq(['container', 'number'], containerNumber, unit) &&
        propEq('unitNumber', barcode, unit)
  ), units)

export const removeUnitsFromSource = (barcode, units) => filter(product => {
  const serialNumber = prop('serialNumber', product)
  const containerNumber = path(['container', 'number'], product)

  if (matchContainer(barcode)) {
    return barcode !== containerNumber
  }
  if (serialNumber === barcode) {
    return barcode !== serialNumber
  }
}, units)

export const getNewLocationUnits = (deletedUnit, locationUnit, units) => map(unit => {
  if (unit === locationUnit) {
    const itemQuantity = prop('quantity', deletedUnit)
    const foundUnitQuantity = prop('quantity', locationUnit)
    const newQuantity = foundUnitQuantity + itemQuantity

    return { ...locationUnit, quantity: newQuantity }
  }
  return unit
}, units)

export const increaseUnitQuantity = (unit, units) => map(item => {
  const activeItemGuid = prop('guid', unit)
  const activeItemQuantity = prop('quantity', unit)

  return propEq('guid', activeItemGuid, item)
    ? { ...item, quantity: item.quantity + activeItemQuantity }
    : item
}, units)

export const decreaseUnitQuantity = (unit, units) => pipe(
  map(item => {
    const unitGuid = prop('guid', unit)
    const unitQuantity = prop('quantity', unit)

    return propEq('guid', unitGuid, item)
      ? { ...item, quantity: item.quantity - unitQuantity }
      : item
  }),
  filter(prop('quantity'))
)(units)

export const getUnitsAfterSerialRemove = (unit, units) => filter(item => {
  const unitGuid = prop('guid', unit)

  return !propEq('guid', unitGuid, item)
}, units)

export const getSourceUnitsAfterSerialRemove = ({ unitNumber, serialNumber, units }) =>
  filter(item => {
    return !(
      propEq('unitNumber', unitNumber, item) &&
      propEq('serialNumber', serialNumber, item)
    )
  }, units)

export const getContainerUnitsAfterSerialRemove = ({ unitNumber, serialNumber, units, containerNumber }) =>
  filter(item => {
    return !(
      propEq('unitNumber', unitNumber, item) &&
      propEq('serialNumber', serialNumber, item) &&
      pathEq(['container', 'number'], containerNumber, item)
    )
  }, units)
