import React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import PropTypes from 'prop-types'
import { FloatField } from 'storfox-form-fields'
import InputAdornment from '@mui/material/InputAdornment'

import PositiveNumberField from '~/components/Fields/PositiveNumberField'
import MoneyField from '~/components/Fields/MoneyField'

import FilterLocationFields from '../../Fields/FilterLocationFields'

function StoragePerVolume ({ name }) {
  return (
    <>
      <Typography gutterBottom={true}>Conditions</Typography>
      <FilterLocationFields name={name} />

      <Box mt={2}>
        <Typography gutterBottom={true}>Pricing</Typography>
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={12} lg={4}>
            <MoneyField
              name={`${name}.price`}
              label="Price"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">/ m<sup>3</sup></InputAdornment>
                )
              }}
            />
          </Grid>

          <Grid item={true} xs={12} lg={4}>
            <PositiveNumberField
              label="Standard rate days"
              name={`${name}.longTermDuration`}
            />
          </Grid>

          <Grid item={true} xs={12} lg={4}>
            <FloatField
              label="Rate for excess of days"
              name={`${name}.longTermPercentage`}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">%</InputAdornment>
                )
              }}
            />
          </Grid>

        </Grid>
      </Box>
    </>
  )
}

StoragePerVolume.propTypes = {
  name: PropTypes.string.isRequired
}

export default StoragePerVolume
