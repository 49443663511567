import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import { Filter } from 'storfox-filter'
import { DateRangeField } from 'storfox-form-fields'
import { prop, propOr } from 'ramda'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

import { escapeAtob, getSeparatedDateRange, unescapeBtoa } from '~/utils'
import TextField from '~/components/Fields/TextField'
import Accordion from '~/components/Accordion'

import SupplierReturnStatusField from './SupplierReturnStatusField'
import SupplierReturnWarehousesField from './SupplierReturnWarehousesField'
import ReturnSupplierField from './ReturnSupplierField'

import { FILTER_NAME as name } from '../../constants'

export const toParams = values => {
  const status = prop('status', values)
  const totalPriceStart = prop('totalPriceStart', values)
  const totalPriceEnd = prop('totalPriceEnd', values)

  const warehouseList = propOr([], 'warehouses', values)
  const supplierList = propOr([], 'supplier', values)
  const warehouses = warehouseList.length ? unescapeBtoa(warehouseList) : null
  const supplier = supplierList.length ? unescapeBtoa(supplierList) : null

  const [createdAtStart, createdAtEnd] = getSeparatedDateRange(prop('createdAt', values))

  return {
    status,
    warehouses,
    supplier,
    createdAtStart,
    createdAtEnd,
    totalPriceStart,
    totalPriceEnd
  }
}

export const toValues = (params) => {
  const status = prop('status', params)
  const totalPriceStart = prop('totalPriceStart', params)
  const totalPriceEnd = prop('totalPriceEnd', params)

  const supplier = escapeAtob(prop('supplier', params))
  const warehouses = escapeAtob(prop('warehouses', params))

  const createdAtStart = prop('createdAtStart', params)
  const createdAtEnd = prop('createdAtEnd', params)

  const createdAt = [createdAtStart, createdAtEnd]

  return {
    status,
    supplier,
    warehouses,
    createdAt,
    totalPriceStart,
    totalPriceEnd
  }
}
export const transformer = { toParams, toValues }

export const returnFilterOptions = {
  name,
  transformer
}

function ReturnFilterForm (props) {
  const { t } = useTranslation()

  return (
    <Filter {...props}>
      <Accordion title="General" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <SupplierReturnStatusField />
          </Grid>
          <Grid item={true} xs={12}>
            <ReturnSupplierField />
          </Grid>
          <Grid item={true} xs={12}>
            <SupplierReturnWarehousesField />
          </Grid>
        </Grid>
      </Accordion>
      <Accordion title="Date">
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <Typography variant="body2" gutterBottom={true}>
              {t('Created')}:
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <DateRangeField name="createdAt" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Accordion>
      <Accordion title="Total ($)">
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={6}>
            <TextField
              data-cy="totalPriceStart"
              name="totalPriceStart"
              label="Min ($)"
            />
          </Grid>
          <Grid item={true} xs={6}>
            <TextField
              data-cy="totalPriceEnd"
              name="totalPriceEnd"
              label="Max ($)"
            />
          </Grid>
        </Grid>
      </Accordion>
    </Filter>
  )
}

ReturnFilterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
}

export default ReturnFilterForm
