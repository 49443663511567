import React from 'react'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import Autocomplete from '@mui/material/Autocomplete'
import { useDeepCompareEffect, useRequest } from 'storfox-api-hooks'
import { defaultTo, path, prop } from 'ramda'
import PropTypes from 'prop-types'

const defaultGetOptionValue = value => {
  if (value) {
    const { id, guid, name } = value
    return { id, guid, name }
  }

  return null
}

function BrowserSearchField (props) {
  const {
    api,
    name,
    disabled,
    value,
    onChange,
    params,
    renderOption,
    getOptionValue,
    sx,
    ...defaultProps
  } = props

  const request = useRequest()
  const [open, setOpen] = React.useState(false)
  const [options, setOptions] = React.useState([])
  const [loading, setLoading] = React.useState(false)

  useDeepCompareEffect(() => {
    let active = true

    if (open) {
      setLoading(true)
      request.get(api, params)
        .then(response => {
          const results = defaultTo([], path(['data', 'results'], response))

          if (active) {
            setOptions(results.map(getOptionValue))
          }
        })
        .finally(() => setLoading(false))
    }

    return () => {
      active = false
    }
  }, [open])

  React.useEffect(() => {
    if (!open) {
      setOptions([])
    }
  }, [open])

  const getOptionSelected = (option, value) => {
    const id = prop('id', option)
    const guid = prop('guid', option)
    if (id) {
      return id === prop('id', value)
    }
    return guid === prop('guid', value)
  }
  return (
    <Autocomplete
      open={open}
      disabled={disabled}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      value={value}
      onChange={onChange}
      isOptionEqualToValue={getOptionSelected}
      getOptionLabel={option => option.name || ''}
      options={options.length === 0 && !open ? [value] : options}
      renderOption={renderOption}
      loading={loading}
      sx={{
        display: 'inline-block',
        minWidth: '200px',
        ...sx
      }}
      renderInput={params => (
        <TextField
          {...params}
          {...defaultProps}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  )
}

BrowserSearchField.propTypes = {
  api: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
  getOptionValue: PropTypes.func.isRequired,
  renderOption: PropTypes.func,
  sx: PropTypes.object
}

BrowserSearchField.defaultProps = {
  size: 'small',
  fullWidth: true,
  variant: 'outlined',
  disabled: false,
  params: { limit: 1000 },
  getOptionValue: defaultGetOptionValue,
  sx: {}
}

export default BrowserSearchField
