import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import StorfoxTable from 'storfox-table'

import { useTableSelects } from '~/components/TableValues/hooks'

function TableNoCache ({ columns, ...props }) {
  const {
    prefix = '',
    rowHeight = 48,
    pagination = true,
    ...rest
  } = props

  const { t } = useTranslation()
  const { selects } = useTableSelects()

  const selectedMessage = t('selected', { number: selects.length })

  const translatedColumns = columns.map(column => ({
    ...column,
    headerName: t(column.headerName)
  }))

  return (
    <StorfoxTable
      columns={translatedColumns}
      selectedMessage={selectedMessage}
      prefix={prefix}
      rowHeight={rowHeight}
      pagination={pagination}
      selects={selects}
      autoPageSize={false}
      {...rest}
    />
  )
}

TableNoCache.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  pagination: PropTypes.bool,
  primaryKey: PropTypes.string,
  pinnedColumns: PropTypes.array,
  header: PropTypes.node,
  prefix: PropTypes.string,
  rowHeight: PropTypes.number,
  count: PropTypes.number,
  onRowClick: PropTypes.func,
  ordering: PropTypes.object,
  checkboxSelection: PropTypes.bool,
  height: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  selectComponent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.array
  ])
}

export default TableNoCache
