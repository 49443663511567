import React from 'react'
import { prop } from 'ramda'

import * as API from '~/constants/api'

import SearchField from './SearchField'

const getOptionLabel = item => {
  if (item) {
    return prop('number', item)
  }

  return ''
}

const getOptionValue = value => {
  if (value) {
    const { id, guid, number } = value
    return { id, guid, number }
  }

  return null
}

function ContainerField (props) {
  return (
    <SearchField
      data-cy="container"
      name="container"
      label="Container"
      api={API.CONTAINER_LIST}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      {...props}
    />
  )
}

export default ContainerField
