import React from 'react'
import { pathOr } from 'ramda'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'

import PageTitle from '~/components/PageTitle'
import { DiscardButton, SaveButton } from '~/components/Buttons'
import Container, { Content, Header } from '~/components/Container'
import { withForm } from '~/components/Form'
import * as NAV from '~/constants/nav-titles'
import { PRODUCT_UPDATE_TABS } from '~/constants/tabs'

import ProductUpdateFormTabs from './ProductUpdateFormTabs'
import ProductUpdateFormGeneral from './General/ProductUpdateFormGeneral'
import ProductUpdateFormVariants from './Variants/ProductUpdateFormVariants'

export const PRODUCT_UPDATE_FIELDS = [
  'name',
  'type',
  'description',
  'images',
  'category',
  'brand',
  'tags',
  'length',
  'width',
  'height',
  'weight',
  'strategy',
  'trackSerialNumbers',
  'trackExpiryDates',
  'options',
  'variants',
  'minExpiryDays',
  'supplier',
  'company',
  'trackBatchNumbers',
  'trackProductionDates',
  'sku',
  'barcode'
]

function ProductUpdateForm ({ title, form, isLoading, pageTitleLoading, ...props }) {
  const { t } = useTranslation()
  const { tab } = useParams()

  const variantCount = pathOr(0, ['values', 'variants', 'length'], form)

  const { handleSubmit } = form

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={title}
            parentTitle={t(NAV.PRODUCTS)}
            pageTitleLoading={pageTitleLoading}
            rightButton={
              <>
                <DiscardButton disabled={isLoading} />
                <SaveButton disabled={isLoading} />
              </>
            }
          />
        </Header>

        <Content>
          <ProductUpdateFormTabs count={variantCount} value={tab} />

          <Divider />
          <Box mb={3} />
          {tab === PRODUCT_UPDATE_TABS.GENERAL && <ProductUpdateFormGeneral {...props} />}
          {tab === PRODUCT_UPDATE_TABS.VARIANTS && <ProductUpdateFormVariants />}
          <Box mb={3} />
        </Content>
      </Container>
    </form>
  )
}

ProductUpdateForm.propTypes = {
  form: PropTypes.object.isRequired,
  pageTitleLoading: PropTypes.bool.isRequired,
  title: PropTypes.string,
  isLoading: PropTypes.bool.isRequired
}

export default withForm(ProductUpdateForm)
