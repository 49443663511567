import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import PropTypes from 'prop-types'
import { is, length, match, pipe, toPairs, toString } from 'ramda'
import { styled } from '@mui/material'

import { snakeCaseToReadable, tableDateFormat } from '~/utils'

const TableStyled = styled(Table)(({ theme }) => ({
  minWidth: '100%',
  '& td': {
    border: '1px solid',
    borderColor: theme.components.StorfoxTable.styleOverrides.row.color
  },
  '& > tbody > tr:last-child > td': {
    borderBottom: 'none',
  },
  '& > tbody > tr > td': {
    borderLeft: 'none',
    borderBottom: 'none'
  }
}))

const TableCellStyled = styled(TableCell, {
  shouldForwardProp: (propName) => propName !== 'isObject'
})(({ isObject }) => ({
  borderRight: 'none !important',
  ...(isObject && {
    padding: '0 !important',
    border: 'none !important'
  })
}))

const dateRegex = /[0-9]{4}-[0-9]{2}-[0-9]{2}.[0-9]{2}:[0-9]{2}:[0-9]{2}\.[0-9]+/g

const matchDate = pipe(
  toString,
  match(dateRegex),
  length
)

const getFormattedValue = value => {
  return matchDate(value) ? tableDateFormat(value) : value
}

function List ({ rowsObject = {} }) {
  const rows = toPairs(rowsObject)

  return (
    <TableStyled size="small">
      <TableBody>
        {rows.map(([key, value], index) => {
          const isObject = is(Object, value)
          const readableKey = snakeCaseToReadable(key)
          const formattedValue = isObject ? value : getFormattedValue(value)

          return (
            <TableRow key={index}>
              <TableCell sx={{ overflow: 'hidden', minWidth: 150 }} title={readableKey}>
                {readableKey}
              </TableCell>
              <TableCellStyled isObject={isObject}>
                {isObject
                  ? <List rowsObject={value} />
                  : <b>{formattedValue}</b>}
              </TableCellStyled>
            </TableRow>
          )
        })}
      </TableBody>
    </TableStyled>
  )
}

List.propTypes = {
  rowsObject: PropTypes.object
}

export default List
