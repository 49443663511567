import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import { useDropzone } from 'react-dropzone'
import * as colors from '@mui/material/colors'
import { Box, styled } from '@mui/material'

import EmptyImage from './Icons/empty-image.png'

const RootStyled = styled(Box, {
  shouldForwardProp: propName => propName !== 'isDragActive'

})(({ theme, isDragActive }) => ({
  border: `1px dashed ${theme.palette.divider}`,
  borderRadius: 5,
  height: 100,
  width: '100%',
  outline: 'none',
  display: 'flex',
  textAlign: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
  alignItems: 'center',
  transition: '.25s ease all',
  '&:hover': {
    backgroundColor: theme.palette.background.dark,
    opacity: 0.5,
    cursor: 'pointer'
  },
  ...(isDragActive && {
    backgroundColor: colors.grey[50],
    opacity: 0.5
  })
}))

function Dropzone ({ onDrop, accept }) {
  const {
    getRootProps,
    getInputProps,
    isDragActive
  } = useDropzone({ onDrop })

  return (
    <RootStyled isDragActive={isDragActive} {...getRootProps()}>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        height: '100% !important',
        color: '#9EA1B6'
      }}>
        <div>
          <input {...getInputProps()} accept={accept} />
          <Box sx={{
            textAlign: 'center',
            '& > img': {
              width: 20,
              height: 'auto',
            }
          }}>
            <img src={EmptyImage} alt="drop image here" />
          </Box>
          <Typography align="center" variant="subtitle1" color="inherit">
            Drop your image here, or select
          </Typography>
          <Typography align="center" variant="subtitle1" color="secondary">
            click to browse
          </Typography>
        </div>
      </Box>
    </RootStyled>
  )
}

Dropzone.propTypes = {
  onDrop: PropTypes.func.isRequired,
  accept: PropTypes.string
}

export default Dropzone
