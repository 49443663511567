import { sprintf } from 'sprintf-js'
import { usePickSearchParams } from 'storfox-route-hooks'
import { useCreate, useDetail, useList, useLimitedCountList, useUpdate } from 'storfox-api-hooks'

import { orderingToSnackCase } from '~/utils'
import * as API from '~/constants/api'

export const useRoleList = () => {
  const searchParams = usePickSearchParams()
  return useLimitedCountList(API.ROLE_LIST, orderingToSnackCase(searchParams))
}

export const useRoleDetail = guid => {
  const url = sprintf(API.ROLE_DETAIL, guid)
  return useDetail(url)
}

export const usePermissionList = () => {
  return useList(API.PERMISSION_LIST, { limit: 1000 })
}

export const useRoleCreate = () => {
  return useCreate(API.ROLE_CREATE)
}

export const useRoleUpdate = (guid) => {
  const url = sprintf(API.ROLE_UPDATE, guid)
  return useUpdate(url)
}
