import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter, generatePath, Navigate, Route, Routes } from 'react-router-dom'
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import CssBaseline from '@mui/material/CssBaseline'
import { FilterProvider, OrderingProvider } from 'storfox-filter'
import { Provider as ConfirmDialogProvider } from 'storfox-confirm-dialog'
import { Provider as SnackbarProvider } from 'storfox-snackbar'
import { TableHeightProvider } from 'storfox-table'

import PolyfillWrapper from '~/components/PolyfillWrapper'
import NotificationProvider from '~/components/Notification/NotificationProvider'
import GoogleAnalyticsProvider from '~/components/GoogleAnalytics'
import WhiteLabelProvider from '~/components/WhiteLabel'
import ScreenOutlineProvider from '~/components/ScreenOutline'
import RouterProvider from '~/components/Router'
import CornerSnackbarProvider from '~/components/CornerSnackbar'
import SocketProvider from '~/components/Socket'
import TokenProvider from '~/components/Token'
import ProfileProvider from '~/components/Profile'
import Compose from '~/components/Compose'
import ScrollToTop from '~/components/ScrollToTop'
import ThemeProvider from '~/components/Theme'
import LanguageProvider from '~/components/Language'
import * as ROUTES from '~/constants/routes'
import TableValuesProvider from '~/components/TableValues/TableValuesProvider'
import { OVERVIEW_TABS } from '~/constants/tabs'

const App = ({ routes }) => {
  const components = [
    WhiteLabelProvider,
    PolyfillWrapper,
    TokenProvider,
    ProfileProvider,
    ThemeProvider,
    CssBaseline,
    ({ children }) => (
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        {children}
      </LocalizationProvider>
    ),
    TableValuesProvider,
    TableHeightProvider,
    LanguageProvider,
    FilterProvider,
    OrderingProvider,
    ConfirmDialogProvider,
    CornerSnackbarProvider,
    SocketProvider,
    NotificationProvider,
    SnackbarProvider,
    ScreenOutlineProvider,
    GoogleAnalyticsProvider
  ]

  const isIframe = window.location !== window.parent.location

  if (isIframe) {
    window.top.location.href = window.location.href
  }

  const routesMemoized = useMemo(() => routes, [routes])

  const to = generatePath(ROUTES.OVERVIEW_PATH, { tab: OVERVIEW_TABS.SALES })

  return (
    <BrowserRouter>
      <Compose components={components}>
        <RouterProvider routes={routesMemoized}>
          {routesByCompanyTypes => (
            <ScrollToTop>
              <Routes>
                <Route
                  exact={true}
                  path="/"
                  element={<Navigate to={to} replace={true} />}
                />
                <React.Fragment>
                  {routesByCompanyTypes.map((route, i) => (
                    <Route
                      key={i}
                      path={route.path}
                      element={(
                        <route.layout key={i}>
                          <route.component />
                        </route.layout>
                      )}
                    />
                  ))}
                </React.Fragment>
              </Routes>
            </ScrollToTop>
          )}
        </RouterProvider>
      </Compose>
    </BrowserRouter>
  )
}

App.propTypes = {
  routes: PropTypes.object
}

export default React.memo(App)
