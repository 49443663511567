import React from 'react'
import PropTypes from 'prop-types'
import { prop, propOr } from 'ramda'
import Toolbar from '@mui/material/Toolbar'
import Divider from '@mui/material/Divider'
import PerfectScrollbar from 'react-perfect-scrollbar'
import List from '@mui/material/List'
import { FieldArray } from 'react-final-form-arrays'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material'
import { ClassNames } from '@emotion/react'

import OrderProgress from '~/components/OrderProgress'
import TextOverflow from '~/components/TextOverflow'
import EditIcon from '~/components/Icons/EditIcon'

import PackingListItemsLoading from './PackingListItemsLoading'

import AvatarProductPreview from '../AvatarProductPreview'

const CardStyled = styled(Card)(({ theme }) => ({
  ...theme.components.StorfoxListPanel.styleOverrides.root,
  minHeight: '100vh',
  height: '100%',
  flexBasis: 300,
  '@media (min-width: 864px)': {
    borderRight: `1px solid ${theme.palette.divider}`
  }
}))

const ListItemStyled = styled(ListItem, {
  shouldForwardProp: (propName) => (
    propName !== 'isScanned' &&
    propName !== 'isFullyScanned' &&
    propName !== 'isActive'
  )
})(({ theme, isScanned, isFullyScanned, isActive }) => ({
  ...(isScanned && theme.components.StorfoxListPanel.styleOverrides.scanned),
  ...(isFullyScanned && theme.components.StorfoxListPanel.styleOverrides.fullyScanned),
  ...(isActive && theme.components.StorfoxListPanel.styleOverrides.active)
}))

function PackingListItems ({ scannedCount, totalScanCount, onProductEdit, isLoading }) {
  return (
    <CardStyled>
      <Toolbar sx={{ minHeight: 'auto', height: '56px' }}>
        <ClassNames>
          {({ css }) => (
            <OrderProgress
              received={scannedCount}
              ordered={totalScanCount}
              className={css({ width: '100%' })}
              progressClasses={css({ width: '100%' })}
            />
          )}
        </ClassNames>
      </Toolbar>

      <Divider />

      <FieldArray name="lineItems">
        {({ fields, meta }) => (
          <Box sx={{
            overflowY: 'auto',
            height: '100%',
            flexShrink: 0
          }}>
            <PerfectScrollbar>
              <List component="div" disablePadding={true}>
                {!isLoading
                  ? fields.map((name, index) => {
                    const value = prop('value', fields)
                    const packingItem = prop(index, value)
                    const id = prop('id', packingItem)
                    const unit = prop('unit', packingItem)
                    const selectedQuantity = propOr(0, 'selectedQuantity', packingItem)
                    const totalQuantity = prop('quantity', packingItem)
                    const isScanned = prop('isScanned', packingItem)
                    const isFullyScanned = selectedQuantity === totalQuantity
                    const unitNumber = prop('unitNumber', unit)
                    const variant = prop('variant', unit)
                    const variantName = prop('name', variant)
                    const src = prop('defaultImage', variant)
                    const sku = prop('sku', variant)
                    const isActiveLineItem = prop('isActiveLineItem', packingItem)
                    const disableEdit = selectedQuantity === 0 || totalQuantity === 0

                    return (
                      <ListItemStyled
                        key={id}
                        isActive={isActiveLineItem}
                        isScanned={isScanned}
                        isFullyScanned={isFullyScanned}
                      >
                        <Grid container={true} alignItems="center">
                          <Grid item={true} xs={12}>
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                              <Box display="flex" alignItems="center" maxWidth="75%">
                                <Box>
                                  <ListItemAvatar>
                                    <AvatarProductPreview unit={unit} alt={variantName} src={src} />
                                  </ListItemAvatar>
                                </Box>

                                <Box>
                                  <Typography variant="body1" component="div">
                                    <TextOverflow selfTooltip={true} lines={1}>
                                      {variantName}
                                    </TextOverflow>
                                  </Typography>
                                  <Typography variant="caption" display="block">{sku}</Typography>
                                  <Typography variant="caption">{unitNumber}</Typography>
                                </Box>

                              </Box>

                              <Box display="flex" alignItems="center">
                                <Typography align="right">
                                  {selectedQuantity}/{totalQuantity}
                                </Typography>

                                <Box ml={1} />

                                <IconButton
                                  size="small"
                                  disabled={disableEdit}
                                  onClick={() => onProductEdit(packingItem)}
                                  data-cy={`${unitNumber}.edit`}
                                >
                                  <EditIcon disabled={disableEdit} />
                                </IconButton>
                              </Box>
                            </Box>
                          </Grid>

                        </Grid>
                      </ListItemStyled>
                    )
                  })
                  : (
                    <PackingListItemsLoading numberOfItems={8} />
                  )}
              </List>
            </PerfectScrollbar>
          </Box>
        )}
      </FieldArray>
    </CardStyled>
  )
}

PackingListItems.propTypes = {
  scannedCount: PropTypes.number,
  totalScanCount: PropTypes.number,
  isLoading: PropTypes.bool.isRequired,
  onProductEdit: PropTypes.func.isRequired
}

export default PackingListItems
