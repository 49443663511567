import React from 'react'
import List from '@mui/material/List'
import { Link } from 'react-router-dom'
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop'
import { ListItemButton } from '@mui/material'
import PropTypes from 'prop-types'

import ListItemText from '~/components/List/ListItemText'
import { MoreListButton } from '~/components/Buttons'
import IconText from '~/components/IconText'
import * as ROUTES from '~/constants/routes'

function More ({ tableRef }) {
  return (
    <MoreListButton tableRef={tableRef}>
      {onClose => (
        <List>
          <ListItemButton
            disabled={false}
            component={Link}
            to={ROUTES.ADJUSTMENT_IMPORT_PATH}
          >
            <IconText Icon={VerticalAlignTopIcon}>
              <ListItemText primary="Import" />
            </IconText>
          </ListItemButton>
        </List>
      )}
    </MoreListButton>
  )
}

More.propTypes = {
  tableRef: PropTypes.object
}

export default More
