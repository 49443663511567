import React from 'react'
import { prop } from 'ramda'
import AppBar from '@mui/material/AppBar'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import Grid from '@mui/material/Grid'
import SvgIcon from '@mui/material/SvgIcon'
import { isProd } from 'storfox-api-hooks'
import PropTypes from 'prop-types'
import MenuIcon from '@mui/icons-material/Menu'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'

import { MENU_WIDTH, SHRINK_MENU_WIDTH, TOP_BAR_HEIGHT } from '~/constants/settings'

import Breadcrumb from './Breadcrumb'
import Notification from './Notification'
import Profile from './Profile'

import Lifebuoy from '../Icons/Lifebuoy'
import { useProfile } from '../Profile'

const AppBarStyled = styled(AppBar, {
  shouldForwardProp: (propName) => propName !== 'isSidebarShrink'
})(({ theme, isSidebarShrink }) => ({
  borderBottom: `1px solid ${theme.palette.background.tableBorder}`,
  borderRight: 'none',
  borderLeft: 'none',
  borderTop: 'none',
  paddingLeft: MENU_WIDTH + 8,
  background: theme.palette.background.paper,
  color: theme.palette.text.primary,
  transition: '.25s ease padding',
  [theme.breakpoints.down('lg')]: {
    paddingLeft: 0
  },
  ...(isSidebarShrink && {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: SHRINK_MENU_WIDTH + 8
    }
  })
}))

const ToolbarStyled = styled(Toolbar)({
  minHeight: TOP_BAR_HEIGHT.lg,
  '@media (max-width: 600px) and (orientation: landscape)': {
    minHeight: TOP_BAR_HEIGHT.sm
  }
})

function TopBar (props) {
  const {
    className,
    isLoading,
    breadcrumbs,
    onOpenNavBarMobile,
    sidebarOpen,
    ...rest
  } = props

  const { profile } = useProfile()

  const handleHelpClick = () => {
    if (isProd && typeof window.Tawk_API !== 'undefined') {
      const firstName = prop('firstName', profile)
      const lastName = prop('lastName', profile)
      const email = prop('email', profile)

      window.Tawk_API.setAttributes({
        'name' : `${firstName} ${lastName}`,
        'email': email,
      })
      window.Tawk_API.toggle()
    } else {
      alert('This feature work only on production')
    }
  }

  return (
    <AppBarStyled
      className={className}
      isSidebarShrink={!sidebarOpen}
      {...rest}
    >
      <ToolbarStyled variant="regular">
        <Grid container={true} justifyContent="space-between" alignItems="center">
          <Grid item={true} sm={6} lg={8}>
            <Grid container={true} alignItems="center">
              <Grid item={true}>
                <IconButton
                  color="inherit"
                  sx={{ mr: 1, display: { xs: 'block', lg: 'none' } }}
                  onClick={onOpenNavBarMobile}
                  size="large"
                >
                  <MenuIcon />
                </IconButton>
              </Grid>

              <Grid item={true}>
                <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                  <Breadcrumb
                    isLoading={isLoading}
                    breadcrumbs={breadcrumbs}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true} sm={6} lg={4}>
            <Grid container={true} alignItems="center" justifyContent="flex-end">
              <Grid>
                <IconButton color="inherit" onClick={handleHelpClick} size="large">
                  <SvgIcon>
                    <Lifebuoy />
                  </SvgIcon>
                </IconButton>
              </Grid>
              <Grid>
                <Notification sx={{ mx: 1 }} />
              </Grid>
              <Grid>
                <Profile />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ToolbarStyled>
    </AppBarStyled>
  )
}

TopBar.propTypes = {
  sidebarOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func,
  breadcrumbs: PropTypes.object.isRequired
}

export default React.memo(TopBar)
