import { find, pipe, toPairs } from 'ramda'

export const getRouteTab = (currentTab, targetTabs, fallbackTab) => {
  const hasTab = pipe(
    toPairs,
    find(([, targetTab]) => currentTab === targetTab),
    Boolean
  )(targetTabs)

  return hasTab ? currentTab : fallbackTab
}
