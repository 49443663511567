import { colors } from '@mui/material'

const white = '#FFFFFF'

export default {
  primary: {
    main: '#282C34'
  },
  secondary: {
    contrastText: white,
    dark: colors.amber[900],
    main: '#8a85ff',
    light: colors.amber.A400,
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: '#e6e5e8',
    secondary: '#adb0bb',
    link: colors.orange[600]
  },
  link: colors.blue[800],
  icon: {
    primary: '#737681'
  },
  background: {
    default: '#282C34',
    dark: '#1c2025',
    paper: '#1E243A',
    tableBorder: 'rgb(57 63 82)',
    inversePaper: white
  },
  inputHover: {
    color: white
  },
  hover: {
    color: 'rgba(255, 255, 255, 0.04)'
  },
  divider: 'rgba(255, 255, 255, .12)',
  border: {
    color: 'rgba(255, 255, 255, .12)',
  },
  action: {
    disabled: '#686868'
  }
}
