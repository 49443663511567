import React from 'react'
import { useField } from 'react-final-form'
import PropTypes from 'prop-types'

import TextField from '~/components/Fields/TextField'

function ActiveSerialNumberField ({ name, ...props }) {
  const { meta } = useField(name)
  return (
    <TextField
      name={name}
      error={Boolean(meta.data.error)}
      {...props}
    />
  )
}

ActiveSerialNumberField.propTypes = {
  name: PropTypes.string.isRequired,
}

export default ActiveSerialNumberField
