import React from 'react'
import PropTypes from 'prop-types'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

import Tabs from '~/components/Tabs'
import { CUSTOMER_UPDATE_PATH } from '~/constants/routes'
import { CUSTOMER_UPDATE_TABS } from '~/constants/tabs'

const tabs = [
  { value: CUSTOMER_UPDATE_TABS.GENERAL, label: 'General' },
  { value: CUSTOMER_UPDATE_TABS.ADDRESS, label: 'Address' },
]

function CustomerUpdateFormTabs ({ value }) {
  const navigate = useNavigate()
  const { guid } = useParams()
  const onChange = (event, tab) => {
    const redirect = generatePath(CUSTOMER_UPDATE_PATH, { guid, tab })

    navigate(redirect, { replace: true })
  }

  return (
    <Tabs
      tabs={tabs}
      value={value}
      onChange={onChange}
    />
  )
}

CustomerUpdateFormTabs.propTypes = {
  value: PropTypes.string.isRequired
}

export default CustomerUpdateFormTabs
