import React from 'react'
import { Box } from '@mui/material'
import PropTypes from 'prop-types'

import { Button } from '~/components/Buttons'

function ActionButtons ({ onSkip, children, ...props }) {
  return (
    <Box display="flex" justifyContent="flex-end" mt={2} {...props}>
      <Button onClick={onSkip}>Skip</Button>
      <Box mr={1} />
      {children}
    </Box>
  )
}

ActionButtons.propTypes = {
  onSkip: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
}

export default ActionButtons
