import React from 'react'
import { useField } from 'react-final-form'
import PropTypes from 'prop-types'
import LoopIcon from '@mui/icons-material/Loop'
import InputAdornment from '@mui/material/InputAdornment'
import { IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'

import SubmitBlockedTextField from '~/components/Fields/SubmitBlockedTextField'

function SkuField ({ name, parentName, ...props }) {
  const { t } = useTranslation()
  const field = useField(name)
  const parentField = useField(parentName)
  const parentValue = parentField.input.value

  const handleGenerate = () => {
    const randomNumber = Math.ceil(Math.random() * 1000)
    const parentSubValue = parentValue.substr(0, 5)
    const value = `${parentSubValue}-${randomNumber}`

    field.input.onChange(value)
  }

  return (
    <>
      <SubmitBlockedTextField
        name={name}
        label="SKU"
        data-cy="sku"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                size="small"
                disabled={!parentValue}
                title={t('Generate SKU')}
                onClick={handleGenerate}
              >
                <LoopIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
        {...props}
      />
    </>
  )
}

SkuField.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired
}

SkuField.defaultProps = {
  name: 'sku',
  parentName: 'name'
}

export default SkuField
