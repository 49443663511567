import React from 'react'
import { Filter } from 'storfox-filter'
import Grid from '@mui/material/Grid'
import { DateRangeField } from 'storfox-form-fields'
import { map, pick, pipe, prop, propOr } from 'ramda'

import { escapeAtob, getSeparatedDateRange, unescapeBtoa } from '~/utils'
import Accordion from '~/components/Accordion'
import WarehouseMultiSelectField from '~/components/Fields/WarehouseMultiSelectField'
import TagsField from '~/components/Fields/TagsField'
import VariantsMultiSelectField from '~/components/Fields/VariantsMultiSelectField'

import ChannelField from './ChannelField'

const getWarehouseGuids = pipe(
  prop('warehouses'),
  map(pick(['guid', 'name']))
)

const getVariantGuids = pipe(
  prop('variants'),
  map(pick(['guid', 'name']))
)

const getChannelGuids = pipe(
  prop('channels'),
  map(pick(['guid', 'name']))
)

export const toParams = values => {
  const tags = prop('tags', values) ? prop('tags', values).join(',') : null
  const warehouseList = propOr([], 'warehouses', values)
  const warehouses = warehouseList.length ? unescapeBtoa(getWarehouseGuids(values)) : null
  const variantList = propOr([], 'variants', values)
  const variants = variantList.length ? unescapeBtoa(getVariantGuids(values)) : null
  const channelList = propOr([], 'channels', values)
  const channels = channelList.length ? unescapeBtoa(getChannelGuids(values)) : null

  const [startDate, endDate] = getSeparatedDateRange(prop('date', values))

  return {
    warehouses,
    variants,
    tags,
    channels,
    startDate,
    endDate
  }
}

export const toValues = params => {
  const warehouses = escapeAtob(prop('warehouses', params))
  const variants = escapeAtob(prop('variants', params))
  const channels = escapeAtob(prop('channels', params))
  const tags = prop('tags', params) ? prop('tags', params).split(',') : ''
  const date = [prop('startDate', params), prop('endDate', params)]

  return {
    warehouses,
    variants,
    tags,
    channels,
    date
  }
}
export const transformer = { toParams, toValues }

export const salesMarginFilterOptions = {
  name,
  transformer
}

export function SalesMarginFilterForm (props) {
  return (
    <Filter {...props}>
      <Accordion title="General" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <WarehouseMultiSelectField />
          </Grid>
          <Grid item={true} xs={12}>
            <TagsField
              data-cy="tags"
              label="Tags"
              name="tags"
            />
          </Grid>
          <Grid item={true} xs={12}>
            <VariantsMultiSelectField label="Variants" name="variants" />
          </Grid>
          <Grid item={true} xs={12}>
            <ChannelField />
          </Grid>
        </Grid>
      </Accordion>
      <Accordion title="Date">
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <DateRangeField name="date" />
          </Grid>
        </Grid>
      </Accordion>
    </Filter>
  )
}
