import React from 'react'

import SelectField from '~/components/Fields/SelectField'

import { POSTMEN_ENVIRONMENT } from '../../constants'

const OPTIONS = [
  {
    value: POSTMEN_ENVIRONMENT.PRODUCTION,
    name: 'Production'
  },
  {
    value: POSTMEN_ENVIRONMENT.SANDBOX,
    name: 'Sandbox'
  }
]
function EnvironmentSelectField (props) {
  return (
    <SelectField
      data-cy="type"
      name="credential.type"
      label="Postmen types"
      options={OPTIONS}
      {...props}
    />
  )
}

export default EnvironmentSelectField
