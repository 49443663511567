import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { map, path, prop } from 'ramda'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import EditIcon from '@mui/icons-material/Edit'

import BooleanStatus from '~/components/BooleanStatus'
import TextOverflow from '~/components/TextOverflow'
import { Table, TableHeader } from '~/components/Table'
import * as ROUTES from '~/constants/routes'
import TableLink from '~/components/Link'
import { MoreListButton } from '~/components/Buttons'

const getDetailPath = guid => {
  return generatePath(ROUTES.AREA_DETAIL_PATH, { guid })
}

export const AREA_COLUMNS = [
  {
    width: 500,
    headerName: 'Name',
    field: 'name',
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        <TextOverflow selfTooltip={true}>{value}</TextOverflow>
      </TableLink>
    )
  },
  {
    width: 200,
    headerName: 'Label',
    field: 'label'
  },
  {
    width: 300,
    headerName: 'Warehouse',
    field: 'warehouse',
    renderCell: ({ value }) => (
      <TextOverflow selfTooltip={true}>{value}</TextOverflow>
    )
  },
  {
    width: 200,
    headerName: 'Status',
    field: 'status',
    renderCell: ({ value }) => (
      <BooleanStatus value={value} />
    )
  }
]

const getRowsFromResults = map(item => {
  const id = prop('id', item)
  const guid = prop('guid', item)
  const name = prop('name', item)
  const label = prop('label', item)
  const warehouse = path(['warehouse', 'name'], item)
  const status = prop('status', item)
  const link = getDetailPath(guid)

  return {
    id,
    guid,
    name,
    label,
    warehouse,
    status,
    link
  }
})

function AreaTable ({ list, filter, ordering, onListRefetch }) {
  const { guid } = useParams()
  const [tableRef, setTableRef] = useState(null)
  const navigate = useNavigate()
  const isLoading = prop('isLoading', list)
  const results = prop('results', list)
  const count = prop('count', list)
  const columns = prop('filteredColumns', ordering)

  const createPath = generatePath(ROUTES.AREA_CREATE_PATH, { warehouseGuid: guid })

  const handleEdit = useCallback(guid => {
    const editPath = generatePath(ROUTES.AREA_UPDATE_PATH, { guid })
    navigate(editPath)
  }, [navigate])

  const actionColumns = useMemo(() => [
    ...columns,
    {
      width: 50,
      resizable: false,
      field: 'actions',
      type: 'actions',
      getActions: ({ row }) => [
        <GridActionsCellItem
          label="Edit"
          onClick={() => handleEdit(row.guid)}
          showInMenu={true}
          icon={<EditIcon />}
        />
      ]
    }
  ], [columns, handleEdit])

  return (
    <>
      <TableHeader
        filter={filter}
        filterOpen={filter.handleOpen}
        orderingOpen={ordering.handleOpen}
        ordering={ordering}
        onCreateClick={() => navigate(createPath)}
      >
        <MoreListButton tableRef={tableRef} />
      </TableHeader>
      <Table
        isLoading={isLoading}
        columns={actionColumns}
        results={results}
        getRowsFromResults={getRowsFromResults}
        count={count}
        pinnedColumns={['name']}
        primaryKey="guid"
        checkboxSelection={true}
        ordering={ordering}
        onRefetch={onListRefetch}
        setTableRef={setTableRef}
      />
    </>
  )
}

AreaTable.propTypes = {
  list: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  onListRefetch: PropTypes.func.isRequired
}

export default AreaTable
