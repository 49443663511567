import React from 'react'

import SelectField from '~/components/Fields/SelectField'
import { NET30, NET45, PAYMENT_TERMS } from '~/constants/paymentTerms'

const PAYMENT_TERM = [
  { value: NET30, name: PAYMENT_TERMS[NET30] },
  { value: NET45, name: PAYMENT_TERMS[NET45] }
]

function PaymentTermField (props) {
  return (
    <SelectField
      data-cy="paymentTerm"
      name="paymentTerm"
      label="Payment Term"
      options={PAYMENT_TERM}
      {...props}
    />
  )
}

export default PaymentTermField
