import React from 'react'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import { Card, Divider, Radio, styled } from '@mui/material'
import CardContent from '@mui/material/CardContent'

import { CardHeader } from '~/components/Cards'

const CardStyled = styled(Card, {
  shouldForwardProp: (propName) => propName !== 'isSelected'
})(({ theme, isSelected }) => ({
  cursor: 'pointer',
  transition: 'all .25s ease',
  '&:hover': {
    border: `1px solid ${theme.palette.secondary.main}`
  },
  ...(isSelected && {
    border: `1px solid ${theme.palette.secondary.main}`
  })
}))

function SelectableAddressCard ({ title, children, selected, onClick }) {
  return (
    <CardStyled
      isSelected={selected}
      onClick={onClick}
    >
      <CardHeader
        style={{ padding: 0 }}
        title={(
          <Box display="flex" alignItems="center">
            <Radio checked={selected} />
            {title}
          </Box>
        )}
      />
      <Divider />
      <CardContent>
        {children}
      </CardContent>
    </CardStyled>
  )
}

SelectableAddressCard.propTypes = {
  children: PropTypes.any.isRequired,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
}

export default SelectableAddressCard
