import React from 'react'

import SelectField from '~/components/Fields/SelectField'

import { CANCELLED, COMPLETED, DRAFT, IN_PROGRESS } from '../../constants'

const OPTIONS = [
  { value: null, name: 'All' },
  { value: DRAFT, name: 'Draft' },
  { value: IN_PROGRESS, name: 'In Progress' },
  { value: COMPLETED, name: 'Completed' },
  { value: CANCELLED, name: 'Cancelled' },
]

function TransferStatusField (props) {
  return (
    <SelectField
      name="status"
      label="Status"
      options={OPTIONS}
      {...props}
    />
  )
}

export default TransferStatusField
