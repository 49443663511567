import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import { Field } from 'react-final-form'
import { useDeepCompareEffect, useRequest } from 'storfox-api-hooks'
import { always, defaultTo, path, prop } from 'ramda'

const defaultGetOptionValue = value => {
  if (value) {
    const { id, guid, name } = value
    return { id, guid, name }
  }

  return null
}

const defaultGetOptionLabel = option => option.name || ''

const emptyObject = {}
const defaultParams = { limit: 1000 }

function BrowserSearchField (props) {
  const {
    api,
    name,
    renderOption,
    ListboxProps,
    size = 'small',
    fullWidth = true,
    variant = 'outlined',
    disabled = false,
    params = defaultParams,
    isLoading = false,
    getOptionValue = defaultGetOptionValue,
    getOptionLabel = defaultGetOptionLabel,
    onValueChange = always(null),
    autocompleteProps = emptyObject,
    ...defaultProps
  } = props

  const request = useRequest()
  const [open, setOpen] = React.useState(false)
  const [options, setOptions] = React.useState([])
  const [loading, setLoading] = React.useState(false)

  useDeepCompareEffect(() => {
    let active = true

    if (open) {
      setLoading(true)
      request.get(api, params)
        .then(response => {
          const results = defaultTo([], path(['data', 'results'], response))

          if (active) {
            setOptions(results.map(getOptionValue))
          }
        })
        .finally(() => setLoading(false))
    }

    return () => {
      active = false
    }
  }, [open])

  React.useEffect(() => {
    if (!open) {
      setOptions([])
    }
  }, [open])

  const getOptionSelected = (option, value) => {
    const id = prop('id', option)
    const guid = prop('guid', option)
    if (id) {
      return id === prop('id', value)
    }
    return guid === prop('guid', value)
  }

  return (
    <Field name={name}>
      {({ input, meta, }) => {
        return (
          <Autocomplete
            open={open}
            disabled={disabled}
            onOpen={() => {
              setOpen(true)
            }}
            onClose={() => {
              setOpen(false)
            }}
            value={input.value}
            isOptionEqualToValue={getOptionSelected}
            getOptionLabel={getOptionLabel || ''}
            options={options.length === 0 && !open ? [input.value] : options}
            renderOption={renderOption}
            loading={loading}
            onChange={(event, value) => {
              input.onChange(value)
              onValueChange(value)
            }}
            ListboxProps={ListboxProps}
            renderInput={params => (
              <TextField
                {...params}
                {...defaultProps}
                size={size}
                fullWidth={fullWidth}
                variant={variant}
                error={meta.invalid}
                helperText={meta.submitError || meta.error}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {(loading || isLoading) ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
            {...autocompleteProps}
          />
        )
      }}
    </Field>
  )
}

BrowserSearchField.propTypes = {
  api: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  size: PropTypes.string,
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  params: PropTypes.object,
  getOptionValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
  renderOption: PropTypes.func,
  isLoading: PropTypes.bool,
  onValueChange: PropTypes.func,
  autocompleteProps: PropTypes.object,
  ListboxProps: PropTypes.object
}

export default BrowserSearchField
