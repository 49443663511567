import { useNavigate } from 'react-router-dom'
import { useDeepCompareEffect } from 'storfox-api-hooks'
import { useAllSearchParams, useRoute } from 'storfox-route-hooks'
import { all, filter, fromPairs, is, pipe, toPairs } from 'ramda'
import { useState } from 'react'

import { skipUndefinedValues } from '~/utils'

import useFilterBackend from './useFilterBackend'
import { useStorage } from './useStorage'

import { FILTER_KEY } from '../constants'
import skipNullValues from '../utils/skipNullValues'
import { useFilterItems } from '../components/FilterProvider'
import { stringToBoolean } from '../utils'

const defaultsProps = {
  autoClose: true
}

const skipNilValues = pipe(
  toPairs,
  filter(([, value]) => is(Array, value) ? !all(val => !val, value) : true),
  fromPairs,
  skipUndefinedValues,
  skipNullValues
)

function useFilter (props) {
  const {
    name,
    transformer,
    autoClose
  } = { ...defaultsProps, ...props }

  const [open, setOpen] = useState(false)
  const route = useRoute()
  const navigate = useNavigate()
  const params = useAllSearchParams()
  const initialValues = skipNilValues(transformer.toValues(stringToBoolean(params)))
  const backend = useFilterBackend({ name, values: initialValues })
  const filterStorage = useStorage(FILTER_KEY)
  const { setItems } = useFilterItems()
  const filterList = filterStorage.get

  useDeepCompareEffect(() => {
    setItems(filterList || [])
  }, [])

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleClear = () => {
    const pathname = history.location.pathname
    navigate(pathname)
  }

  const onSubmit = formValues => {
    const params = transformer.toParams(formValues) || {}
    route.changeParams({ ...params, page: 1 })

    if (autoClose) {
      handleClose()
    }
  }

  return {
    open,
    backend,
    transformer,
    initialValues,
    handleOpen,
    handleClose,
    handleClear,
    onSubmit
  }
}

export default useFilter
