import React from 'react'
import PropTypes from 'prop-types'
import isEqual from 'react-fast-compare'
import { setIn } from 'final-form'

import Form from './Form'

function withForm (Component, FormProps) {
  const validateFormValues = (schema) => async (values) => {
    if (typeof schema === 'function') {
      schema = schema()
    }
    try {
      await schema.validateSync(values, { abortEarly: false })
    } catch (err) {
      return err.inner.reduce((formError, innerError) => {
        return setIn(formError, innerError.path, innerError.message)
      }, {})
    }
  }

  const StorfoxForm = props => {
    const { onSubmit, initialValues, validationSchema, ...rest } = props
    const validate = validationSchema && validateFormValues(validationSchema)

    return (
      <Form
        onSubmit={onSubmit}
        initialValuesEqual={isEqual}
        initialValues={initialValues}
        validate={validate}
        validateOnBlur={true}
        {...FormProps}
        render={form => (
          <Component form={form} {...rest} />
        )}
      />
    )
  }

  StorfoxForm.propTypes = {
    onSubmit: PropTypes.func,
    validationSchema: PropTypes.obj,
    initialValues: PropTypes.any,
  }

  return React.memo(StorfoxForm, isEqual)
}

export default withForm
