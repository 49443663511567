import React from 'react'

import AppContainer from '~/components/AppContainer'
import { CenteredLayout } from '~/components/Layouts'

import ShopifyInstall from '../../components/Shopify/ShopifyInstall'
import { useShopifyInstall } from '../../hooks'

function ShopifyInstallContainer () {
  const shopifyInstall = useShopifyInstall()

  const getDetail = () =>
    shopifyInstall.getDetail()
      .then(({ url }) => {
        window.top.location.href = url
      })

  return (
    <CenteredLayout>
      <AppContainer>
        <ShopifyInstall onDetailGet={getDetail} />
      </AppContainer>
    </CenteredLayout>
  )
}

export default ShopifyInstallContainer
