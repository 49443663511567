export const PURCHASE_ORDER_STATUSES = {
  NEW :'new',
  PROGRESS :'progress',
  CLOSED :'closed',
  CANCELLED: 'cancelled'
}

export const RECEIVING_STATUSES = {
  NEW :'new',
  IN_PROGRESS :'progress',
  COMPLETED: 'completed'
}

export const SHIPMENT_STATUSES = {
  UNSHIPPED :'unshipped',
  PRE_TRANSIT :'pre_transit',
  IN_TRANSIT: 'in_transit',
  DELIVERED: 'delivered',
  RETURNED: 'returned',
  FAILED: 'failed',
  CANCELLED: 'cancelled'
}

export const SHIPMENT_STATUS_NAMES = {
  [SHIPMENT_STATUSES.UNSHIPPED]: 'Unshipped',
  [SHIPMENT_STATUSES.PRE_TRANSIT]: 'Pre-transit',
  [SHIPMENT_STATUSES.IN_TRANSIT]: 'In transit',
  [SHIPMENT_STATUSES.DELIVERED]: 'Delivered',
  [SHIPMENT_STATUSES.RETURNED]: 'Returned',
  [SHIPMENT_STATUSES.FAILED]: 'Failed',
  [SHIPMENT_STATUSES.CANCELLED]: 'Cancelled'
}

export const SHIPMENT_STATUS_COLORS = {
  [SHIPMENT_STATUSES.UNSHIPPED]: 'error',
  [SHIPMENT_STATUSES.DELIVERED]: 'success',
  [SHIPMENT_STATUSES.PRE_TRANSIT]: 'warning',
  [SHIPMENT_STATUSES.IN_TRANSIT]: 'warning',
  [SHIPMENT_STATUSES.RETURNED]: 'error',
  [SHIPMENT_STATUSES.FAILED]: 'error',
  [SHIPMENT_STATUSES.CANCELLED]: 'error',
}
