import React from 'react'
import { generatePath } from 'react-router-dom'
import Link from '@mui/material/Link'
import PropTypes from 'prop-types'
import { propEq, prop, pathOr } from 'ramda'
import { toSnake } from 'storfox-api-hooks/src/utils/toSnakeCase'

import * as ROUTES from '~/constants/routes'
import { errors } from '~/constants/errors'

import { NONE_FIELD_ERROR } from '../Form'

const getErrorByCode = code => {
  const error = errors.find(propEq('code', code))

  return error ? prop('title', error) : 'Failed to save'
}

const getMessageByKey = (message) => {
  const isStringMessage = typeof message === 'string'
  if (isStringMessage) {
    return message
  } else {
    const keys = Object.keys(message)
    const values = Object.values(message)
    return keys.map((key, index) => {
      const keyMessage = pathOr('', [index, 0], values)
      const snakeKey = toSnake(key).replace('_', ' ')
      const capKey = snakeKey.charAt(0).toUpperCase() + snakeKey.slice(1)
      return `${capKey}: ${keyMessage}`
    }).join(' ')
  }
}

function ErrorLink ({ error }) {
  const nonFieldError = prop(NONE_FIELD_ERROR, error)

  if (nonFieldError) {
    const errorMessage = nonFieldError[0]
    const isErrorCode = errorMessage.match('^E[A-Z0-9]{2,10}')
    if (isErrorCode) {
      const url = generatePath(ROUTES.ERROR_DEFINITION, { code: errorMessage })
      return (
        <Link
          component="a"
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          underline="always"
          sx={{ color: 'inherit' }}
        >
          {getErrorByCode(errorMessage)}
        </Link>
      )
    } else {
      return errorMessage
    }
  } else {
    const keys = Object.keys(error)
    const values = Object.values(error)
    return (
      <>
        {keys.map((key, index) => {
          const keyMessage = pathOr('', [index, 0], values)
          const snakeKey = toSnake(key).replace('_', ' ')
          const capKey = snakeKey.charAt(0).toUpperCase() + snakeKey.slice(1)
          const message = getMessageByKey(keyMessage)
          return (
            <>
              {`${capKey}: ${message}`}
              <br />
            </>
          )
        })}
      </>
    )
  }
}

ErrorLink.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]).isRequired
}

export default ErrorLink
