import { map, path, pick, prop, propOr } from 'ramda'
import { DEFAULT_SEARCH_PARAMS } from 'storfox-route-hooks'

import { escapeAtob, getDateTimeResetZone, orderingToSnackCase } from '~/utils'

const getInitLineItems = map(item => {
  const id = path(['condition', 'id'], item)
  const name = path(['condition', 'code'], item)
  const condition = { id, name }

  return { ...item, condition }
})

export const SaleOrderInitSerializer = value => {
  const lineItems = propOr([], 'lineItems', value)
  return {
    ...value,
    lineItems: getInitLineItems(lineItems)
  }
}

export const InvoiceFilterSerializer = params => {
  const defaultParams = pick(DEFAULT_SEARCH_PARAMS, params)
  const totalPriceStart = prop('totalPriceStart', params)
  const totalPriceEnd = prop('totalPriceEnd', params)

  const warehouseGuids = escapeAtob(prop('warehouses', params))
  const customerGuids = escapeAtob(prop('customers', params))
  const createdAtStart = getDateTimeResetZone(prop('createdAtStart', params))
  const createdAtEnd = getDateTimeResetZone(prop('createdAtEnd', params))
  const updatedAtStart = getDateTimeResetZone(prop('updatedAtStart', params))
  const updatedAtEnd = getDateTimeResetZone(prop('updatedAtEnd', params))

  return orderingToSnackCase({
    ...defaultParams,
    warehouseGuids,
    customerGuids,
    totalPriceStart,
    totalPriceEnd,
    createdAtStart,
    createdAtEnd,
    updatedAtStart,
    updatedAtEnd,
  })
}
