import React from 'react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import { styled } from '@mui/material'

const RootStyled = styled(Box, {
  shouldForwardProp: (propName) => propName !== 'isRight'
})(({ theme, isRight }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  maxWidth: 15,
  fontSize: 14,
  color: theme.palette.primary.contrastText,
  transition: 'transform .25s ease',
  ...(isRight && {
    transform: 'rotate(180deg)'
  })
}))

function ToggleButton ({ isRight }) {
  return (
    <RootStyled isRight={isRight}>
      <ArrowBackIosIcon fontSize="small" sx={{ position: 'relative', right: 2 }} />
      <ArrowBackIosIcon fontSize="small" sx={{ position: 'relative', right: 16 }} />
    </RootStyled>
  )
}

ToggleButton.propTypes = {
  isRight: PropTypes.bool.isRequired
}

export default ToggleButton
