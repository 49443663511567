import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import LocationDetailContainer from './containers/LocationDetailContainer'
import LocationCreateContainer from './containers/LocationCreateContainer'
import LocationUpdateContainer from './containers/LocationUpdateContainer'

export default () => {
  return [
    {
      layout: AuthLayout,
      path: ROUTES.LOCATION_CREATE_PATH,
      component: LocationCreateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.LOCATION_UPDATE_PATH,
      component: LocationUpdateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.LOCATION_DETAIL_PATH,
      component: LocationDetailContainer
    }
  ]
}
