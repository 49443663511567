import React from 'react'

import SelectField from '~/components/Fields/SelectField'

const OPTIONS = [
  { value: 'linear', name: 'Linear' },
  { value: 'round_robin', name: 'Round robin' }
]

function UploadOptionField (props) {
  return (
    <SelectField
      name="allocationMode"
      label="Allocation mode"
      options={OPTIONS}
      {...props}
    />
  )
}

export default UploadOptionField
