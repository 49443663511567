import React from 'react'
import PropTypes from 'prop-types'

import { PURCHASE_ORDER_STATUSES } from '~/constants/statuses'

import Status from '../Status/Status'

const COLOR = {
  [PURCHASE_ORDER_STATUSES.NEW]: 'success',
  [PURCHASE_ORDER_STATUSES.PROGRESS]: 'warning',
  [PURCHASE_ORDER_STATUSES.CLOSED]: 'success',
  [PURCHASE_ORDER_STATUSES.CANCELLED]: 'error'
}

function PurchaseOrderStatus ({ value }) {
  return (
    <Status label={value} color={COLOR[value]} />
  )
}

PurchaseOrderStatus.propTypes = {
  value: PropTypes.oneOf([
    PURCHASE_ORDER_STATUSES.NEW,
    PURCHASE_ORDER_STATUSES.PROGRESS,
    PURCHASE_ORDER_STATUSES.CLOSED,
    PURCHASE_ORDER_STATUSES.CANCELLED
  ]).isRequired
}

export default PurchaseOrderStatus
