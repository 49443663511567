import React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

import * as ROUTES from '~/constants/routes'
import Tabs from '~/components/Tabs'
import Divider from '~/components/Divider'
import { USER_DETAIL_TABS } from '~/constants/tabs'

export const TABS = [
  { value: USER_DETAIL_TABS.GENERAL, label: 'General' },
  { value: USER_DETAIL_TABS.HISTORY, label: 'History' }
]

function UserDetailTabs ({ id }) {
  const navigate = useNavigate()
  const { tab } = useParams()

  const handleChange = (event, tab) => {
    const path = generatePath(ROUTES.USER_DETAIL_PATH, { id, tab })

    navigate(path, { replace: true })
  }

  return (
    <>
      <Tabs
        tabs={TABS}
        value={tab}
        onChange={handleChange}
      />
      <Divider />
    </>
  )
}

UserDetailTabs.propTypes = {
  id: PropTypes.number.isRequired
}

export default UserDetailTabs
