import React, { useState } from 'react'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import PropTypes from 'prop-types'
import { map, path, prop, reduce } from 'ramda'
import TableContainer from '@mui/material/TableContainer'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import InfoIcon from '@mui/icons-material/Info'
import { IconButton } from '@mui/material'

import { CardHeader } from '~/components/Cards'
import { emptyArray } from '~/constants/empty'
import { getFormattedDate } from '~/utils'
import HtmlTooltip from '~/components/HtmlTooltip/HtmlTooltip'

import SoChart from './SoChart'
import PoChart from './PoChart'
import OrderTabs, { ORDER_BY_UNITS_DATE_TAB, PO_BY_UNITS_DATE_TAB } from './OrderTabs'

import { generateDays, getLabelsFromDateRange } from '../../../utils'

const getPOData = (items, days) => {
  const keyValue = reduce((acc, item) => {
    return { ...acc, [prop('date', item)]: item }
  }, {}, items)

  const purchases = map(day => {
    const date = getFormattedDate(day)

    return path([date, 'purchases'], keyValue) || 0
  }, days)

  const units = map(day => {
    const date = getFormattedDate(day)

    return path([date, 'units'], keyValue) || 0
  }, days)

  return { purchases, units }
}

const getSOData = (items, days) => {
  const keyValue = reduce((acc, item) => {
    return { ...acc, [prop('date', item)]: item }
  }, {}, items)

  const orders = map(day => {
    const date = getFormattedDate(day)

    return path([date, 'orders'], keyValue) || 0
  }, days)

  const units = map(day => {
    const date = getFormattedDate(day)

    return path([date, 'units'], keyValue) || 0
  }, days)

  return { orders, units }
}

function OrdersByDateAndUnit ({ ordersByUnitsOverview, dateRange, poByUnitsOverview }) {
  const { t } = useTranslation()
  const [tab, setTab] = useState(ORDER_BY_UNITS_DATE_TAB)

  const soItems = prop('results', ordersByUnitsOverview)
  const poItems = prop('results', poByUnitsOverview)

  const days = generateDays(dateRange)
  const labels = getLabelsFromDateRange(dateRange) || emptyArray

  const soData = getSOData(soItems, days)
  const poData = getPOData(poItems, days)

  return (
    <Card>
      <CardHeader
        title={
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h4">{t('Orders - PO By Dates & Units')}</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <OrderTabs value={tab} onChange={(e, tab) => setTab(tab)} />
              <HtmlTooltip title="Orders frequency and quantity over time.">
                <IconButton size="small">
                  <InfoIcon sx={{ color: '#BDBDBD' }} />
                </IconButton>
              </HtmlTooltip>
            </Box>
          </Box>
        }
        sx={{ paddingBottom: 0 }}
      />
      <Divider />
      <CardContent sx={{ p: 0 }}>
        <TableContainer>
          <Box sx={{ minWidth: 700, padding: '24px' }}>
            {tab === ORDER_BY_UNITS_DATE_TAB && (
              <SoChart
                labels={labels}
                data={soData}
              />
            )}
            {tab === PO_BY_UNITS_DATE_TAB && (
              <PoChart
                labels={labels}
                data={poData}
              />
            )}
          </Box>
        </TableContainer>
      </CardContent>
    </Card>
  )
}

OrdersByDateAndUnit.propTypes = {
  dateRange: PropTypes.array,
  ordersByUnitsOverview: PropTypes.object,
  poByUnitsOverview: PropTypes.object
}

export default OrdersByDateAndUnit
