import React from 'react'
import PropTypes from 'prop-types'
import { useField } from 'react-final-form'
import { useDeepCompareEffect } from 'storfox-api-hooks'

import SelectField from '~/components/Fields/SelectField'

import * as EVENTS from '../../../constants/AutomationRule'

const OPTIONS = [
  {
    name: 'Order created',
    value: EVENTS.ORDER_CREATED
  },
  {
    name: 'Order changed',
    value: EVENTS.ORDER_CHANGED
  },
  {
    name: 'Picking created',
    value: EVENTS.PICKING_CREATED
  },
  {
    name: 'Putaway created',
    value: EVENTS.PUTAWAY_CREATED
  },
  {
    name: 'Packing created',
    value: EVENTS.PACKING_CREATED
  },
  {
    name: 'Shipment created',
    value: EVENTS.SHIPMENT_CREATED
  },
  {
    name: 'Low stock',
    value: EVENTS.LOW_STOCK
  },
  {
    name: 'Replenishment',
    value: EVENTS.REPLENISHMENT
  },
  {
    name: 'Listing created',
    value: EVENTS.LISTING_CREATED
  },
  {
    name: 'Purchase change',
    value: EVENTS.PURCHASE_CHANGE
  },
  {
    name: 'Shipment set status',
    value: EVENTS.SHIPMENT_SET_STATUS
  }
]

function EventSelectField (props) {
  const {
    name = 'event',
    ...rest
  } = props

  const field = useField(name)
  const actionsField = useField('actions')
  const ruleField = useField('rule')

  const value = field.input.value

  useDeepCompareEffect(() => {
    if (field.meta.dirty) {
      actionsField.input.onChange([{}])
      ruleField.input.onChange({ ...ruleField.input.value, rules: [] })
    }
  }, [value])

  return (
    <SelectField
      name={name}
      label="Starts when"
      options={OPTIONS}
      {...rest}
    />
  )
}

EventSelectField.propTypes = {
  name: PropTypes.string
}

export default EventSelectField
