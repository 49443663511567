import React, { useCallback, useContext, useRef } from 'react'
import PropTypes from 'prop-types'
import { getToken, useDeepCompareEffect, useGetApi } from 'storfox-api-hooks'
import PubSub from 'pubsub-js'
import Box from '@mui/material/Box'
import { prop } from 'ramda'
import { useTranslation } from 'react-i18next'

import { useCornerSnackbar } from '~/components/CornerSnackbar'
import {
  ADJUSTMENT_BULK_CREATE_COMPLETE,
  ADJUSTMENT_BULK_VALIDATE_COMPLETE,
  LINK,
  PACKING_ASSIGN,
  PAGE_REFRESH,
  PICKING_ASSIGN,
  PURCHASE_ORDERS_BULK_CREATE_COMPLETE,
  PURCHASE_ORDERS_BULK_VALIDATE_COMPLETE,
  PUTAWAY,
  SALE_ORDER_FAILED,
  SALE_ORDER_BULK_CREATE_COMPLETE,
  SALE_ORDER_ALLOCATION_STATUS,
  SALE_ORDER_BULK_VALIDATE_COMPLETE,
  SHIPMENT_CREATED,
  SHOPIFY_PRODUCTS_SYNC_STATUS,
  WAREHOUSE_CREATE,
  WAREHOUSE_BULK_CREATE_COMPLETE,
  WAREHOUSE_BULK_VALIDATE_COMPLETE,
  USER_RESET,
  PRODUCT_BULK_UPLOAD_VALIDATION_COMPLETE,
  PRODUCT_BULK_UPLOAD_VALIDATION_STATUS,
  PRODUCT_BULK_UPLOAD_CREATE_STATUS,
  PRODUCT_BULK_UPLOAD_CREATE_COMPLETE
} from '~/constants/notification-topics'
import * as API from '~/constants/api'
import { set as setLanguage } from '~/components/Language'
import { useMe, useProfile } from '~/components/Profile'

import NotificationMessage from './NotificationMessage'
import Context from './context'

import { useNotificationMethods } from '../Socket'

export const useNotificationCountUpdate = () => {
  const { updateCount, topics } = useContext(Context)

  return { updateCount, topics }
}

function NotificationProvider ({ children }) {
  const snackbar = useCornerSnackbar()
  const token = getToken()
  const { i18n } = useTranslation()
  const { setProfile } = useProfile()
  const { getMe } = useMe()
  const notificationCount = useGetApi(API.UNREAD_NOTIFICATION_COUNT, false)

  const subscriptionRef = useRef([])
  const { onChange } = useNotificationMethods()

  const topics = [
    LINK,
    PAGE_REFRESH,
    PACKING_ASSIGN,
    PICKING_ASSIGN,
    PUTAWAY,
    WAREHOUSE_CREATE,
    SALE_ORDER_FAILED,
    ADJUSTMENT_BULK_CREATE_COMPLETE,
    ADJUSTMENT_BULK_VALIDATE_COMPLETE,
    PURCHASE_ORDERS_BULK_VALIDATE_COMPLETE,
    PURCHASE_ORDERS_BULK_CREATE_COMPLETE,
    SALE_ORDER_BULK_VALIDATE_COMPLETE,
    SALE_ORDER_BULK_CREATE_COMPLETE,
    SALE_ORDER_ALLOCATION_STATUS,
    WAREHOUSE_BULK_VALIDATE_COMPLETE,
    WAREHOUSE_BULK_CREATE_COMPLETE,
    SHIPMENT_CREATED,
    SHOPIFY_PRODUCTS_SYNC_STATUS,
    PRODUCT_BULK_UPLOAD_VALIDATION_COMPLETE,
    PRODUCT_BULK_UPLOAD_VALIDATION_STATUS,
    PRODUCT_BULK_UPLOAD_CREATE_STATUS,
    PRODUCT_BULK_UPLOAD_CREATE_COMPLETE
  ]

  useDeepCompareEffect(() => {
    if (token) {
      notificationCount.get()
        .then(({ result }) => {
          onChange(result)
        })
    }
  }, [token])

  useDeepCompareEffect(() => {
    PubSub.subscribe(USER_RESET, () => {
      getMe().then(({ result }) => {
        const language = prop('language', result)
        i18n.changeLanguage(language)
          .then(() => setLanguage(language))
        setProfile(result)
      })
    })
  }, [])

  useDeepCompareEffect(() => {
    const subscriptions = subscriptionRef.current

    topics.forEach((topic, index) => {
      subscriptionRef.current[index] = PubSub.subscribe(topic, updateNotificationCount)

      return () => {
        subscriptions.forEach(subscription => {
          PubSub.unsubscribe(subscription)
        })
      }
    })
  }, [])

  const updateCount = useCallback(() => {
    notificationCount.get().then(({ result }) => { onChange(result) })
  }, [notificationCount, onChange])

  const updateNotificationCount = (topic, message) => {
    showSnackbar(topic, message)
  }

  const showSnackbar = (topic, { body, payload }) => {
    const newMessage = (
      <>
        <Box>{body}</Box>
        <NotificationMessage topic={topic} payload={payload} />
      </>
    )

    snackbar({ message: newMessage })
  }

  return (
    <Context.Provider value={{ updateCount, topics }}>
      {children}
    </Context.Provider>
  )
}

NotificationProvider.propTypes = {
  children: PropTypes.any.isRequired
}

export default NotificationProvider
