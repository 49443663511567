import React from 'react'
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { prop } from 'ramda'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)

function Chart ({ labels, data: dataProp, ...props }) {
  const theme = useTheme()

  const options = {
    plugins: {
      legend: {
        labels: {
          usePointStyle: true
        }
      }
    },
    cornerRadius: 20,
    maintainAspectRatio: false,
    animation: false,
    responsive: true,
    layout: {
      padding: 0
    },
    interaction: {
      mode: 'index',
      intersect: false
    },
    datasets: {
      bar: {
        backgroundColor: theme.palette.primary.light,
        barPercentage: 1,
        categoryPercentage: 0.7
      }
    },
    scales: {
      x: {
        stacked: false,
        grid: {
          display: false,
          drawBorder: false
        },
        ticks: {
          padding: 20,
          fontColor: theme.palette.text.secondary
        }
      },
      y: {
        stacked: false,
        grid: {
          borderDash: [2],
          borderDashOffset: [2],
          color: '#BDBDBD',
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: '#BDBDBD'
        },
        ticks: {
          padding: 20,
          fontColor: theme.palette.text.secondary,
          beginAtZero: true,
          min: 0,
          maxTicksLimit: 5,
          callback: value => {
            return value > 0 ? value / 1000 + 'K' : value
          }
        }
      }
    }
  }

  const data = {
    labels,
    datasets: [
      {
        label: 'Inbound',
        data: prop('inbound', dataProp),
        backgroundColor: '#4BC0C0',
        order: 1
      },
      {
        label: 'Outbound',
        data: prop('outbound', dataProp),
        backgroundColor: '#656EEA',
        order: 2,
      }
    ],
  }

  return (
    <Box position="relative" {...props}>
      <Bar options={options} data={data} />
    </Box>
  )
}

Chart.propTypes = {
  labels: PropTypes.array.isRequired,
  data: PropTypes.object,
}

export default Chart
