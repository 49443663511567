import React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import HtmlTooltip from '~/components/HtmlTooltip/HtmlTooltip'

import Label from '../Label'

function TopInformationItem ({ title, value, percent, children, tooltip }) {
  const { t } = useTranslation()

  return (
    <HtmlTooltip title={tooltip}>
      <Box position="relative">
        {children}
        <Typography
          component="h2"
          gutterBottom={true}
          variant="overline"
        >
          {t(title)}
        </Typography>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography variant="h3">
            {value}
          </Typography>
          <Label
            color="#203D77"
            variant="contained"
            sx={{ ml: 1 }}
          >
            +{percent}%
          </Label>
        </Box>
      </Box>
    </HtmlTooltip>
  )
}

TopInformationItem.propTypes = {
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  percent: PropTypes.number.isRequired,
  children: PropTypes.any,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]).isRequired
}

export default TopInformationItem
