import React, { useState } from 'react'
import { prop, propOr } from 'ramda'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import Barcode from 'react-barcode'
import JsBarcode from 'jsbarcode'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'
import { useDeepCompareEffect } from 'storfox-api-hooks'

function BarcodePrintEan13 ({ item, fontSize }) {
  const snackbar = useSnackbar()
  const barcode = prop('code', item)
  const titles = propOr([], 'titles', item)
  const [error, setError] = useState(false)

  useDeepCompareEffect(() => {
    if (barcode.length === 13) {
      try {
        setError(false)
        JsBarcode({}, barcode, { format: 'EAN13' })
      } catch (e) {
        setError(true)
        snackbar({ message: e, type: ALTER_ERROR })
      }
    } else {
      setError(true)
      snackbar({ message: 'Barcode length should be 13 for EON13 type', type: ALTER_ERROR })
    }
  }, [barcode])

  return (
    <Box mb={2}>
      <Card>
        <Box sx={{
          pageBreakAfter: 'always',
          pageBreakInside: 'avoid',
          '& svg': {
            width: '100% !important'
          }
        }}>
          {!error && (
            <Barcode value={barcode} width={3} fontSize={fontSize} format="EAN13" />
          )}
          {titles.map((title, index) => (
            <Typography key={index} align="center">
              <strong>{title}</strong>
            </Typography>
          ))}
        </Box>
      </Card>
    </Box>
  )
}

BarcodePrintEan13.propTypes = {
  item: PropTypes.object,
  fontSize: PropTypes.number
}

export default BarcodePrintEan13
