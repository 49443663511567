import React from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { defaultTo, fromPairs, includes, isEmpty, map, path, pipe, prop, propOr } from 'ramda'
import ListSubheader from '@mui/material/ListSubheader'
import { isProd } from 'storfox-api-hooks'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material'

import retailerNav from '~/constants/nav-retailer'
import marketplaceNav from '~/constants/nav-marketplace'
import threeplNav from '~/constants/nav-threepl'
import { MARKETPLACE, RETAILER, THREE_PL } from '~/constants/companyTypes'
import { TOP_BAR_HEIGHT } from '~/constants/settings'

import NavList from './NavList'
import NavParentTitle from './NavParentTitle'

import { useCompany, useProfile } from '../Profile'

const navConfig = {
  [RETAILER]: retailerNav,
  [MARKETPLACE]: marketplaceNav,
  [THREE_PL]: threeplNav
}

const NavStyled = styled('nav', {
  shouldForwardProp: (propName) => propName !== 'isShrink'
})(({ theme, isShrink }) => ({
  overflow: 'auto',
  height: `calc(100vh - ${TOP_BAR_HEIGHT.lg}px)`,
  maxHeight: `calc(100% - ${TOP_BAR_HEIGHT.lg}px)`,
  padding: theme.spacing(0, 0, 2, 0),
  flexGrow: 1,
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none'
  },
  [theme.breakpoints.down('lg')]: {
    maxHeight: '100%'
  },
  ...(isShrink && {
    overflow: 'visible',
    maxHeight: 'auto'
  })
}))

const hasEnabled = (perms, items) => pipe(
  map(item => prop(item.perm, perms)),
  includes(true)
)(items)

function renderNavItems ({ t, items, subheader, key, isShrink, ...rest }) {
  const isChild = rest.depth >= 1

  return (
    <NavList key={key} isChild={isChild} isShrink={isShrink}>
      {subheader && <ListSubheader disableSticky={true}>{t(subheader)}</ListSubheader>}

      {isShrink && rest.parentTitle && (
        <NavParentTitle>{rest.parentTitle}</NavParentTitle>
      )}

      {items.reduce((acc, item) => {
        const params = { t, acc, item, isShrink, ...rest }
        return reduceChildRoutes(params)
      }, [])}
    </NavList>
  )
}

function reduceChildRoutes ({
  t,
  openPath,
  handleToggleMenuItem,
  activeNav,
  acc,
  pathname,
  perms,
  item,
  NavItem,
  isShrink,
  depth = 0
}) {
  if (item.items) {
    const isActivePage = element => activeNav === element.name

    const enabled = hasEnabled(perms, item.items)

    const open = !isEmpty(openPath)
      ? openPath === item.href
      : item.items.some(isActivePage)

    if (enabled) {
      acc.push(
        <NavItem
          depth={depth}
          href={item.href}
          icon={item.icon}
          key={item.href}
          label={item.label}
          enabled={item.enabled}
          open={open}
          title={t(item.title)}
          handleToggleMenuItem={handleToggleMenuItem}
          beta={item.beta}
          betaRightPx={item.betaRightPx}
        >
          {renderNavItems({
            t,
            depth: depth + 1,
            pathname,
            activeNav,
            perms,
            parentTitle: t(item.title),
            items: item.items,
            NavItem,
            isShrink
          })}
        </NavItem>
      )
    }
  } else {
    const active = item.name === activeNav

    const enabled = Boolean(
      item.enabled &&
      prop(item.perm, perms)
    )

    if (enabled || !isProd) {
      acc.push(
        <NavItem
          depth={depth}
          href={item.href}
          icon={item.icon}
          key={item.href}
          enabled={enabled}
          label={item.label}
          active={active}
          title={t(item.title)}
          beta={item.beta}
          betaRightPx={item.betaRightPx}
        />
      )
    }
  }

  return acc
}

const getPerms = pipe(
  path(['profile', 'permissions']),
  defaultTo([]),
  map(item => [item, true]),
  fromPairs
)

function Nav ({ openPath, handleToggleMenuItem, activeNav, NavItem, isShrink }) {
  const { t } = useTranslation()
  const location = useLocation()
  const profile = useProfile()
  const perms = getPerms(profile)
  const { company } = useCompany()
  const companyType = propOr(RETAILER, 'type', company)

  return (
    <NavStyled isShrink={isShrink}>
      {navConfig[companyType].map(list => renderNavItems({
        t,
        openPath,
        handleToggleMenuItem,
        items: list.items,
        subheader: list.subheader,
        pathname: location.pathname,
        key: location.pathname,
        activeNav,
        perms,
        NavItem,
        isShrink
      }))}
    </NavStyled>
  )
}

renderNavItems.propTypes = {
  items: PropTypes.array,
  subheader: PropTypes.string,
  key: PropTypes.number
}

Nav.propTypes = {
  NavItem: PropTypes.func.isRequired,
  activeNav: PropTypes.string,
  handleToggleMenuItem: PropTypes.func.isRequired,
  openPath: PropTypes.string.isRequired,
  isShrink: PropTypes.bool.isRequired
}

export default React.memo(Nav)
