import React from 'react'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'

import { CardHeader } from '~/components/Cards'
import { Button } from '~/components/Buttons'
import Hide from '~/components/Hide'

function PricingCard ({ children, index, withAddButton, onTierAdd, onTierRemove, addDisabled }) {
  return (
    <Card>
      <CardHeader
        variant="h6"
        component={Typography}
        title={`Pricing Tier #${index + 1}`}
        action={(
          <Box>
            <Hide hidden={!withAddButton}>
              <Button onClick={onTierAdd} disabled={addDisabled}>
                Add
              </Button>
            </Hide>

            <Button onClick={onTierRemove}>
              Remove
            </Button>
          </Box>
        )}
      />
      <Divider />
      <CardContent>
        {children}
      </CardContent>
    </Card>
  )
}

PricingCard.propTypes = {
  children: PropTypes.any.isRequired,
  index: PropTypes.number.isRequired,
  onTierAdd: PropTypes.func.isRequired,
  onTierRemove: PropTypes.func.isRequired,
  withAddButton: PropTypes.bool.isRequired,
  addDisabled: PropTypes.bool
}

export default PricingCard
