import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDeepCompareEffect } from 'storfox-api-hooks'
import useRequest from 'storfox-api-hooks/src/useRequest'
import { sprintf } from 'sprintf-js'
import { path, pathOr, prop } from 'ramda'
import Dialog from '@mui/material/Dialog/Dialog'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableCell from '@mui/material/TableCell/TableCell'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import { useTranslation } from 'react-i18next'

import SkeletonList from '~/components/Skeleton'
import Avatar from '~/components/Avatar'
import VerticalAlignment from '~/components/VerticalAlignment'
import DialogTitle from '~/components/DialogTitle'
import * as API from '~/constants/api'

function LocationDialog ({ open, onClose, location }) {
  const request = useRequest()
  const { t } = useTranslation()
  const [content, setContent] = useState([])
  const [loading, setLoading] = useState(false)

  const locationId = prop('locationId', location)
  const guid = prop('guid', location)

  useDeepCompareEffect(() => {
    if (guid) {
      setLoading(true)
      const url = sprintf(API.LOCATION_CONTENT, guid)
      request.get(url).then(response => {
        const results = pathOr([], ['data', 'results'], response)
        setContent(results)
        setLoading(false)
      })
    }
  }, [guid])

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth={true}>
      <DialogTitle>{locationId}</DialogTitle>
      <TableContainer>
        <Table>
          <TableHead>
            <TableCell>{t('Image')}</TableCell>
            <TableCell>{t('Variant')}</TableCell>
            <TableCell>{t('Unit number')}</TableCell>
            <TableCell>{t('Quantity')}</TableCell>
            <TableCell>{t('Container')}</TableCell>
          </TableHead>
          <TableBody>
            {loading && <SkeletonList columnCount={5} rowCount={5} height={20} />}
            {!loading && content.map(item => {
              const guid = prop('guid', item)
              const variantName = path(['variant', 'name'], item)
              const variantSku = path(['variant', 'sku'], item)
              const variantImage = path(['variant', 'defaultImage'], item)
              const unitNumber = prop('unitNumber', item)

              const quantity = prop('quantity', item)
              const container = path(['container', 'number'], item)

              return (
                <TableRow key={guid}>
                  <TableCell>
                    <Avatar src={variantImage} alt={variantName} />
                  </TableCell>
                  <TableCell>
                    <VerticalAlignment
                      primary={variantName}
                      secondary={variantSku}
                    />
                  </TableCell>
                  <TableCell>{unitNumber}</TableCell>
                  <TableCell align="center">{quantity}</TableCell>
                  <TableCell>{container}</TableCell>
                </TableRow>
              )
            })}
            {!loading && content.length === 0 && (
              <TableRow>
                <TableCell>
                  {t('No content')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Dialog>

  )
}

LocationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  location: PropTypes.object
}

export default LocationDialog
