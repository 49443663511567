import React from 'react'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'

const DimensionStatusStyled = styled(Box, {
  shouldForwardProp: (propName) => propName !== 'isFilled'
})(({ isFilled }) => ({
  width: '3px',
  height: '50px',
  position: 'absolute',
  left: '0',
  top: '6px',
  backgroundColor: '#e53935',
  ...(isFilled && {
    backgroundColor: '#2e3456'
  })
}))

function DimensionStatus ({ isFilled }) {
  return (
    <DimensionStatusStyled isFilled={isFilled} />
  )
}

DimensionStatus.propTypes = {
  isFilled: PropTypes.bool.isRequired
}

export default DimensionStatus
