import React, { useState } from 'react'
import { is, isEmpty, slice, uniq, without } from 'ramda'
import PropTypes from 'prop-types'
import MUITextField from '@mui/material/TextField'
import Chip from '@mui/material/Chip'
import isEqual from 'react-fast-compare'
import { ClassNames } from '@emotion/react'

import FieldWrapper from './FieldWrapper'

const getChips = input => is(Array, input.value) ? input.value : []

const getKey = event => {
  if (event.key !== 'Unidentified') {
    return event.key
  }

  const ss = event.target.selectionStart - 1
  const ssv = ss || 0
  return event.target.value.substr(ssv, 1)
}

const TagsField = ({ input, meta, ...props }) => {
  const [value, setValue] = useState('')
  const chips = getChips(input)

  const onChange = event => {
    event.preventDefault()
    setValue(event.target.value)
  }

  const onBlur = (event) => {
    if (!isEmpty(value)) {
      event.preventDefault()
      input.onChange(uniq([...input.value, value]))
      setValue('')
    }
  }

  const onKeyUp = event => {
    const key = getKey(event)
    const isSeparator = key === ','

    if (isSeparator && !isEmpty(value)) {
      event.preventDefault()
      input.onChange(uniq([...input.value, value.replace(/,/g, '')]))
      setValue('')
    }
  }

  const onKeyDown = event => {
    const isBackspace = event.key === 'Backspace'
    const isSeparator = event.key === 'Tab' || event.key === 'Enter'

    if (isSeparator && !isEmpty(value)) {
      event.preventDefault()
      input.onChange(uniq([...input.value, value]))
      setValue('')
    }

    if (isBackspace && isEmpty(value)) {
      event.preventDefault()
      input.onChange(slice(0, -1, input.value))
    }
  }

  const renderAdornment = () => {
    if (chips.length) {
      return chips.map((label, index) => (
        <Chip
          key={index}
          size="small"
          label={label}
          onDelete={() => input.onChange(without([label], input.value))}
          sx={{
            borderRadius: '5px',
            marginRight: '5px',
            marginBottom: '5px',
            marginTop: '5px'
          }}
        />
      )
      )
    }
  }

  return (
    <ClassNames>
      {({ css }) => {
        const rootClass = css({ flexWrap: 'wrap' })
        const inputClass = css({
          flexGrow: 1,
          width: 0,
          minWidth: 30
        })

        return (
          <MUITextField
            fullWidth={true}
            variant="outlined"
            size="small"
            error={meta.invalid}
            helperText={meta.submitError || meta.error}
            InputProps={{
              startAdornment: renderAdornment(),
              classes: {
                root: rootClass,
                input: inputClass
              }
            }}
            value={value}
            onKeyUp={onKeyUp}
            onKeyDown={onKeyDown}
            onChange={onChange}
            onBlur={onBlur}
            {...props}
          />
        )
      }}
    </ClassNames>
  )
}

TagsField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired
}

export default FieldWrapper(React.memo(TagsField, isEqual))
