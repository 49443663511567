import React from 'react'
import { generatePath, useLocation, useNavigate } from 'react-router-dom'
import { useDeepCompareEffect } from 'storfox-api-hooks'
import { includes, prop, propOr } from 'ramda'
import { useAllSearchParams } from 'storfox-route-hooks'

import AppContainer from '~/components/AppContainer'
import { useToken } from '~/components/Token'
import { useCompany, useMe, useProfile } from '~/components/Profile'
import * as ROUTES from '~/constants/routes'
import { RETAILER } from '~/constants/companyTypes'
import { OVERVIEW_TABS } from '~/constants/tabs'
import { escapeAtob } from '~/utils'
import { useWhiteLabel } from '~/components/WhiteLabel/WhiteLabel'

import SignUp from '../components/SignUp'
import { useSignUp } from '../hooks'

const defaultState = {
  from: { pathname: ROUTES.INTRODUCTION_GUIDE }
}

function SignUpContainer () {
  const params = useAllSearchParams()
  const navigate = useNavigate()
  const location = useLocation()
  const { signUp, isLoading } = useSignUp()
  const { getMe } = useMe()
  const { token, setToken } = useToken()
  const { setProfile } = useProfile()
  const { domainValues } = useWhiteLabel()
  const companyName = prop('name', domainValues)

  const { isThreePl } = useCompany()

  const aggrakProfile = escapeAtob(prop('profile', params))

  const tab = isThreePl ? OVERVIEW_TABS.FULFILLMENT : OVERVIEW_TABS.SALES
  const redirectTo = generatePath(ROUTES.OVERVIEW_PATH, { tab })

  useDeepCompareEffect(() => {
    if (token) {
      navigate(redirectTo)
    }
  }, [])

  const { from } = (
    location.state ||
    defaultState
  )

  const fromPathname = propOr('', 'pathname', from)
  const isIntegrationCreate = includes('/settings/integration/create/', fromPathname)
  const contractInfo = isIntegrationCreate ? { types: [RETAILER] } : null

  const handleSubmit = formValues =>
    signUp(formValues)
      .then(({ result }) => setToken(result.token))
      .then(() => getMe())
      .then(({ result }) => setProfile(result))
      .then(() => {
        if (companyName) {
          navigate(redirectTo, { replace: true })
        } else {
          navigate(from, { replace: true })
        }
      })
      .then(() => {
        if (window.gtag) {
          window.gtag('event', 'click', {
            event_category: 'Sign up',
            event_action: 'Sign up'
          })
        }
      })
      .catch(err => {
        navigate({
          pathname: ROUTES.SIGN_UP,
          state: { from }
        }, { replace: true })
        throw err
      })

  return (
    <AppContainer title="Sign Up">
      <SignUp
        initialValues={{
          type: RETAILER,
          contractInfo,
          firstName: prop('firstName', aggrakProfile),
          lastName: prop('lastName', aggrakProfile),
          email: prop('email', aggrakProfile),
          phone: prop('phone', aggrakProfile)
        }}
        checkEmail={!prop('firstName', aggrakProfile)}
        isLoading={isLoading}
        onSubmit={handleSubmit}
        from={from}
      />
    </AppContainer>
  )
}

export default SignUpContainer
