import { join, map, pick, pipe, prop } from 'ramda'
import { DEFAULT_SEARCH_PARAMS } from 'storfox-route-hooks'

import { escapeAtob } from '~/utils'

export const UnitFilterSerializer = params => {
  const defaultParams = pick(DEFAULT_SEARCH_PARAMS, params)

  const isAvailable = prop('isAvailable', params)
  const isArchived = prop('isArchived', params)
  const expiresAt = prop('expiresAt', params)
  const companyGuids = escapeAtob(prop('companies', params))
  const locationGuids = escapeAtob(prop('locations', params))
  const warehouseGuids = escapeAtob(prop('warehouses', params))
  const variantGuids = escapeAtob(prop('variants', params))
  const toObjectIds = escapeAtob(prop('objects', params))

  const toObjectTypes = pipe(
    prop('toObjectTypes'),
    objectType => objectType ? escapeAtob(objectType) : [],
    map(prop('value')),
    join(',')
  )(params)

  return {
    ...defaultParams,
    isAvailable,
    isArchived,
    companyGuids,
    locationGuids,
    warehouseGuids,
    variantGuids,
    toObjectIds,
    toObjectTypes: toObjectTypes || null,
    expiresAt
  }
}
