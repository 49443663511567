import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { TextField } from '@mui/material'
import { prop } from 'ramda'
import FormControl from '@mui/material/FormControl'
import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import EventIcon from '@mui/icons-material/Event'

import FieldWrapper from './FieldWrapper'

function DateTimeField (props) {
  const {
    input,
    meta,
    inputFormat = 'dd.MM.yyyy HH:mm',
    mask = '__.__.____ __:__',
    ...defaultProps
  } = props
  const [open, setOpen] = useState(false)

  const dataCy = prop('data-cy', props)

  const restProps = meta.invalid
    ? ({ ...defaultProps, error: true, helperText: meta.submitError })
    : defaultProps

  return (
    <FormControl style={{ width: '100%' }} error={meta.invalid}>
      <DateTimePicker
        value={input.value ? input.value : null}
        onChange={date => input.onChange(date)}
        inputFormat={inputFormat}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        mask={mask}
        OpenPickerButtonProps={{ sx: { mr: 0 } }}
        renderInput={props => (
          <TextField
            {...props}
            data-cy={dataCy}
            size="small"
            autocomplete="off"
            InputProps={{
              endAdornment: (
                <Box sx={{ display: 'flex' }}>
                  <CloseIcon sx={{ marginRight: '5px', cursor: 'pointer' }} onClick={() => input.onChange(null)} />
                  <EventIcon sx={{ cursor: 'pointer' }} onClick={() => setOpen(true)} />
                </Box>
              )
            }}
          />
        )}
        InputProps={{ sx: { pr: 0 } }}
        {...restProps}
      />
    </FormControl>
  )
}

DateTimeField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  size: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  inputVariant: PropTypes.string.isRequired,
  inputFormat: PropTypes.string.isRequired,
  mask: PropTypes.string
}

DateTimeField.defaultProps = {
  size: 'small',
  fullWidth: true,
  inputVariant: 'outlined'
}

export default FieldWrapper(DateTimeField)
