import React, { useCallback } from 'react'
import Grid from '@mui/material/Grid'
import { SwitchField } from 'storfox-form-fields'
import { useField } from 'react-final-form'

const AVAILABLE = 'available'
const ARCHIVED = 'archived'

function UnitSwitchFActionFilter () {
  const isAvailableField = useField('isAvailable')
  const availableFieldValue = isAvailableField.input.value
  const availableFieldChange = isAvailableField.input.onChange
  const isArchivedField = useField('isArchived')
  const archivedFieldValue = isArchivedField.input.value
  const archivedFieldChange = isArchivedField.input.onChange
  const expiresAtField = useField('expiresAt')
  const expiresAtFieldValue = expiresAtField.input.value
  const expiresAtFieldChange = expiresAtField.input.onChange

  const handleChange = useCallback(value => {
    if (value === AVAILABLE) {
      if (availableFieldValue) {
        availableFieldChange(null)
      } else {
        availableFieldChange(true)
        archivedFieldChange(null)
      }
    } else if (value === ARCHIVED) {
      if (archivedFieldValue) {
        archivedFieldChange(null)
      } else {
        archivedFieldChange(true)
        availableFieldChange(null)
      }
    }
  }, [archivedFieldChange, archivedFieldValue, availableFieldChange, availableFieldValue])

  const handleExpireAtChange = useCallback(() => {
    if (expiresAtFieldValue) {
      expiresAtFieldChange(null)
    } else {
      expiresAtFieldChange(true)
    }
  }, [expiresAtFieldChange, expiresAtFieldValue])

  return (
    <>
      <Grid item={true} xs={12}>
        <SwitchField
          data-cy="isAvailable"
          name="isAvailable"
          label="Available"
          checked={availableFieldValue}
          onChange={() => handleChange(AVAILABLE)}
        />
      </Grid>
      <Grid item={true} xs={12}>
        <SwitchField
          data-cy="isArchived"
          name="isArchived"
          label="Archived"
          checked={archivedFieldValue}
          onChange={() => handleChange(ARCHIVED)}
        />
      </Grid>
      <Grid item={true} xs={12}>
        <SwitchField
          data-cy="expiresAt"
          name="expiresAt"
          label="Is expiry"
          checked={expiresAtFieldValue}
          onChange={handleExpireAtChange}
        />
      </Grid>
    </>
  )
}

export default UnitSwitchFActionFilter
