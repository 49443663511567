import React from 'react'

import SelectField from '~/components/Fields/SelectField'

const OPTIONS = [
  { value: 'add', name: 'Increase' },
  { value: 'remove', name: 'Decrease' }
]
function OperationField (props) {
  return (
    <SelectField
      label="Operation"
      name="operation"
      options={OPTIONS}
      {...props}
    />
  )
}

export default OperationField
