import React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useAllSearchParams } from 'storfox-route-hooks'
import { useSnackbar } from 'storfox-snackbar'
import { propOr } from 'ramda'

import { getRouteTab } from '~/utils'
import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import useMessages from '~/hooks/useMessages'
import { VARIANT_DETAIL_TABS } from '~/constants/tabs'
import * as ROUTES from '~/constants/routes'

import { VariantUpdate } from '../components/VariantUpdate'
import {
  useProductVariantList,
  useVariantDetail,
  useVariantPriceList,
  useVariantPricesUpdate,
  useVariantUpdate
} from '../hooks'
import { ConditionPricesSerializer, VariantUpdateSerializer, VariantUpdateInitialSerializer } from '../serializers'

function VariantUpdateContainer () {
  const { id, productId, tab } = useParams()
  const snackbar = useSnackbar()

  const variantDetail = useVariantDetail(id)

  const variantList = useProductVariantList(productId)
  const variantUpdate = useVariantUpdate(id)
  const variantPriceList = useVariantPriceList(id, false)
  const variantPricesUpdate = useVariantPricesUpdate(id)
  const navigate = useNavigate()

  const messages = useMessages()

  const { isMultiVariant } = useAllSearchParams()

  const detailTab = getRouteTab(tab, VARIANT_DETAIL_TABS, VARIANT_DETAIL_TABS.GENERAL)
  const detailUrl = generatePath(ROUTES.VARIANT_DETAIL_PATH, { id, productId, tab: detailTab })
  const detailPath = `${detailUrl}?isMultiVariant=${isMultiVariant}`

  const handleSubmit = formValues =>
    variantUpdate
      .update(VariantUpdateSerializer(formValues))
      .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
      .then(() => navigate(detailPath))

  const handlePricesUpdate = values =>
    variantPricesUpdate.update(ConditionPricesSerializer(id, values)).then(() => variantDetail.getDetail())

  const title = propOr('', 'name', variantDetail.detail)

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title="Update variant"
      space={false}
      isLoading={variantDetail.isLoading || variantUpdate.isLoading}
      activeNav={NAV.VARIANTS}
      breadcrumbs={breadcrumbs}
    >
      <VariantUpdate
        results={variantList.results}
        isMultiVariant={JSON.parse(isMultiVariant)}
        variantId={id}
        pageTitle={title}
        pageTitleLoading={variantDetail.isLoading}
        initialValues={VariantUpdateInitialSerializer(variantDetail.detail)}
        onSubmit={handleSubmit}
        variantPriceList={variantPriceList}
        onPricesUpdate={handlePricesUpdate}
        isLoading={variantUpdate.isLoading}
      />
    </DashboardLayout>
  )
}

export default VariantUpdateContainer
