import React from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

import Tabs from '~/components/Tabs'
import Divider from '~/components/Divider'
import * as ROUTES from '~/constants/routes'
import { PACKING_DETAIL_TABS } from '~/constants/tabs'

const tabs = [
  { value: PACKING_DETAIL_TABS.GENERAL, label: 'General' },
  { value: PACKING_DETAIL_TABS.HISTORY, label: 'History' },
]

function PackingDetailTabs ({ id, value }) {
  const navigate = useNavigate()

  const onChange = (event, tab) => {
    const redirect = generatePath(ROUTES.PACKING_DETAIL_PATH, { id, tab })
    navigate(redirect, { replace: true })
  }

  return (
    <>
      <Tabs
        tabs={tabs}
        value={value}
        onChange={onChange}
      />
      <Divider />
    </>
  )
}

PackingDetailTabs.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
}

export default PackingDetailTabs
