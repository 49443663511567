import React from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import { ColorField, MonthsField } from 'storfox-form-fields'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import { propOr } from 'ramda'

import { CardHeader } from '~/components/Cards'
import SingleImageUploadField from '~/components/Fields/SingleImageUploadField'
import TextField from '~/components/Fields/TextField'
import Container, { Content, Header } from '~/components/Container'
import PageTitle from '~/components/PageTitle'
import { DiscardButton, SaveButton } from '~/components/Buttons'
import { withForm } from '~/components/Form'
import * as NAV from '~/constants/nav-titles'

import DefaultConditionSwitchField from '../Fields/DefaultConditionSwitchField'

export const CONDITION_FIELDS = [
  'code',
  'color',
  'condition',
  'description',
  'grade',
  'isDefault',
  'warranty',
  'imagePath'
]

function ConditionCreateForm ({ title, isLoading, pageTitleLoading, form }) {
  const { initialValues, handleSubmit } = form
  const isDefault = propOr(false, 'isDefault', initialValues)

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={title}
            parentTitle={NAV.CONFIGURATION}
            pageTitleLoading={pageTitleLoading}
            rightButton={(
              <>
                <DiscardButton disabled={isLoading} />
                <SaveButton disabled={isLoading} />
              </>
            )}
          />
        </Header>

        <Content bottomSpace={true}>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12} lg={8}>
              <Card sx={{ overflow: 'visible' }}>
                <CardHeader title="Condition Info" />
                <Divider />
                <CardContent>
                  <Grid container={true} spacing={3}>
                    <Grid item={true} xs={12} lg={6}>
                      <TextField
                        data-cy="code"
                        name="code"
                        label="Code"
                      />
                    </Grid>
                    <Grid item={true} xs={12} lg={6}>
                      <TextField
                        inputProps={{ maxLength: 200 }}
                        data-cy="condition"
                        name="condition"
                        label="Condition"
                      />
                    </Grid>
                    <Grid item={true} xs={12} lg={4}>
                      <TextField
                        inputProps={{ maxLength: 100 }}
                        data-cy="grade"
                        name="grade"
                        label="Grade"
                      />
                    </Grid>
                    <Grid item={true} xs={12} lg={4}>
                      <MonthsField
                        inputProps={{ maxLength: 2 }}
                        data-cy="warranty"
                        name="warranty"
                        label="Warranty"
                      />
                    </Grid>
                    <Grid item={true} xs={12} lg={4}>
                      <ColorField
                        data-cy="color"
                        name="color"
                        label="Color"
                      />
                    </Grid>
                    <Grid item={true} xs={12}>
                      <TextField
                        data-cy="description"
                        name="description"
                        label="Description"
                        multiline={true}
                        rows="4"
                      />
                    </Grid>
                    <Grid item={true} xs={12}>
                      <DefaultConditionSwitchField
                        name="isDefault"
                        initialValue={isDefault}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item={true} xs={12} lg={4}>
              <Box>
                <Card>
                  <CardHeader title="Image" />
                  <Divider />
                  <CardContent>
                    <SingleImageUploadField name="imagePath" />
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Content>
      </Container>
    </form>
  )
}
ConditionCreateForm.propTypes = {
  title: PropTypes.string.isRequired,
  form: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  pageTitleLoading: PropTypes.bool
}
export default withForm(ConditionCreateForm)
