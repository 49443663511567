export const GENERAL = 'general'
export const STORAGE = 'storage'
export const FULFILLMENT = 'fulfillment'
export const PACKING = 'packing'
export const ADHOC = 'adhoc'
export const RECEIVING = 'receiving'

export const TABS = [
  { value: GENERAL, label: 'General' },
  { value: STORAGE, label: 'Storage' },
  { value: FULFILLMENT, label: 'Fulfillment' },
  { value: PACKING, label: 'Packing' },
  { value: ADHOC, label: 'Ad hoc' }
]

export const STORAGE_TYPES = {
  PER_VOLUME: 'per_volume',
  PER_WEIGHT: 'per_weight'
}

export const FULFILLMENT_TYPES = {
  FIXED: 'fixed',
  PER_ORDER: 'per_order',
  PER_ORDER_UNIT_QTY: 'per_order_unit',
  PER_ORDER_LINE_ITEM_COUNT: 'per_line_item'
}

export const PACKING_TYPES = {
  PER_CONTAINER_TYPE: 'per_container_type'
}

export const RECEIVING_TYPES = {
  PER_CONTAINER_WEIGHT: 'container_weight'
}

const STORAGE_KEY = 'storageBilling'
const PACKING_KEY = 'packingBilling'
const FULFILLMENT_KEY = 'fulfillmentBilling'
const ADHOC_KEY = 'adhocBilling'
const RECEIVING_KEY = 'receivingBilling'

export const TABS_WITH_KEYS = [
  {
    key: '',
    tab: GENERAL
  },
  {
    key: STORAGE_KEY,
    tab: STORAGE
  },
  {
    key: FULFILLMENT_KEY,
    tab: FULFILLMENT
  },
  {
    key: PACKING_KEY,
    tab: PACKING
  },
  {
    key: ADHOC_KEY,
    tab: ADHOC
  },
  {
    key: RECEIVING_KEY,
    tab: RECEIVING
  }
]

export const FILTER_NAME = 'billing_profile'
