import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'

import TextField from '~/components/Fields/TextField'
import CountryField from '~/components/Fields/CountryField'

function AddressInfo ({ name }) {
  return (
    <Grid container={true} spacing={2}>
      <Grid item={true} xs={12}>
        <TextField
          label="First name"
          name={`${name}.firstName`}
          multiline={true}
          minRows={2}
          required={true}
        />
      </Grid>
      <Grid item={true} xs={12}>
        <TextField
          label="Last name"
          name={`${name}.lastName`}
          multiline={true}
          minRows={2}
          required={true}
        />
      </Grid>
      <Grid item={true} xs={12}>
        <TextField
          label="Address"
          name={`${name}.address`}
          multiline={true}
          minRows={2}
          required={true}
        />
      </Grid>

      <Grid item={true} xs={12}>
        <TextField
          label="City"
          name={`${name}.city`}
          required={true}
        />
      </Grid>

      <Grid item={true} xs={12}>
        <TextField
          label="Zip code"
          name={`${name}.zipcode`}
        />
      </Grid>

      <Grid item={true} xs={12}>
        <CountryField
          label="Country"
          name={`${name}.country`}
          required={true}
        />
      </Grid>
    </Grid>
  )
}

AddressInfo.propTypes = {
  name: PropTypes.string.isRequired
}

export default AddressInfo
