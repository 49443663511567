import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

import { DiscardButton } from '~/components/Buttons'
import Container, { Content, Header } from '~/components/Container'
import PageTitle from '~/components/PageTitle'
import * as NAV from '~/constants/nav-titles'
import LinkButton from '~/components/Buttons/LinkButton'

import ShopifyInstructionCard from '../ShopifyInstructionCard'

function ShopifyInstruction () {
  return (
    <Container>
      <Header>
        <PageTitle
          parentTitle={NAV.INTEGRATION}
          pageTitle="New integration"
          rightButton={(
            <>
              <DiscardButton />
              <LinkButton href="https://apps.shopify.com/storfox/">
                Install
              </LinkButton>
            </>
          )}
        />
      </Header>
      <Content>
        <Grid container={true}>
          <Grid item={true} xs={12} lg={8}>
            <Box mb={3}>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <ShopifyInstructionCard completedTasks={0} />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Content>
    </Container>

  )
}

export default ShopifyInstruction
