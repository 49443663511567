import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import Check from '@mui/icons-material/Check'
import { useTranslation } from 'react-i18next'
import { useField } from 'react-final-form'

import CountryField from '~/components/Fields/CountryField'
import TextField from '~/components/Fields/TextField'
import { Button } from '~/components/Buttons'

function CompanyInfoContract ({ className, submitBtnClassName, onBack, isLoading }) {
  const { t } = useTranslation()
  const companyNameField = useField('name')
  const countryField = useField('country')

  const disabled = !(
    companyNameField.input.value &&
    countryField.input.value
  )

  return (
    <Box className={className}>
      <Grid container={true} spacing={3}>
        <Grid item={true} xs={12}>
          <TextField
            autoFocus={true}
            name="name"
            data-cy="name"
            label={t('Company name')}
            size="medium"
          />
        </Grid>
        <Grid item={true} xs={12}>
          <CountryField
            name="country"
            data-cy="country"
            label={t('Country')}
            size="medium"
          />
        </Grid>
      </Grid>
      <Box mt={2}>
        <Grid
          container={true}
          justifyContent="flex-end"
          alignItems="center"
          spacing={3}>
          <Grid item={true}>
            <Button
              size="large"
              onClick={onBack}
            >
              Previous
            </Button>
          </Grid>

          <Grid item={true}>
            <Button
              variant="contained"
              data-cy="submit"
              startIcon={<Check />}
              size="large"
              type="submit"
              className={submitBtnClassName}
              disabled={disabled || isLoading}
            >
              Create an Account
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

CompanyInfoContract.propTypes = {
  className: PropTypes.string,
  submitBtnClassName: PropTypes.string,
  onBack: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
}
export default CompanyInfoContract
