import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import AlertTitle from '@mui/material/AlertTitle'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'

import { CODE_TYPE } from '~/constants/barcode'

import BarcodePlaceholder from './Barcode/BarcodePlaceholder'
import QrCodePlaceholder from './QrCode/QrCodePlaceholder'

function CodeEmpty ({ type }) {
  return (
    <>
      <Box mb={2}>
        {type === CODE_TYPE.BARCODE
          ? <BarcodePlaceholder />
          : <QrCodePlaceholder />
        }
      </Box>
      <Alert severity="info">
        <AlertTitle>Generate barcode information</AlertTitle>
        <Typography>
          Fill barcode field, then click "Generate code"
        </Typography>
      </Alert>
    </>
  )
}

CodeEmpty.propTypes = {
  type: PropTypes.string.isRequired
}

export default CodeEmpty
