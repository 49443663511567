import React from 'react'
import { useFilter, useOrdering } from 'storfox-filter'
import { useAllSearchParams } from 'storfox-route-hooks'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import ErrorLink from '~/components/ErrorLink'
import * as NAV from '~/constants/nav-titles'
import useMessages from '~/hooks/useMessages'
import { useTableSelects } from '~/components/TableValues/hooks'

import {
  useTransferCancel,
  useTransferList,
  useTransferListComplete,
  useTransferListProcess,
  useTransfersDelete
} from '../hooks'
import {
  TransferFilterForm,
  transferFilterOptions,
  TransferOrderingForm,
  transferOrderingOptions,
  TransferTable
} from '../components/TransferList'

function TransferListContainer () {
  const snackbar = useSnackbar()
  const messages = useMessages()
  const { status } = useAllSearchParams()

  const transferList = useTransferList()
  const transferListProcess = useTransferListProcess()
  const transferListComplete = useTransferListComplete()
  const transfersDelete = useTransfersDelete()
  const transferCancel = useTransferCancel()

  const filter = useFilter(transferFilterOptions)
  const ordering = useOrdering(transferOrderingOptions)

  const { selects, resetTableSelects } = useTableSelects()

  const title = 'Stock Transfers'

  const handleProcess = () =>
    transferListProcess.update({ guids: selects })
      .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
      .then(() => resetTableSelects())
      .then(() => transferList.getList())
      .catch(error => {
        snackbar({ message: <ErrorLink error={error} />, type: ALTER_ERROR })
        return Promise.reject(error)
      })

  const handleComplete = () =>
    transferListComplete.complete({ guids: selects })
      .then(() => snackbar({ message: messages.COMPLETE_SUCCESS }))
      .then(() => resetTableSelects())
      .then(() => transferList.getListByParams())
      .catch(error => {
        snackbar({ message: <ErrorLink error={error} />, type: ALTER_ERROR })
        return Promise.reject(error)
      })

  const handleRemove = () =>
    transfersDelete.delete({ guids: selects })
      .then(() => snackbar({ message: messages.DELETE_SUCCESS }))
      .then(() => resetTableSelects())
      .then(() => transferList.getListByParams())
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const handleCancel = guid =>
    transferCancel.update({ guid })
      .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
      .then(() => transferList.getList())
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
        return Promise.reject(error)
      })

  const isLoading = (
    transferListProcess.isLoading ||
    transferListComplete.isLoading ||
    transferList.isLoading
  )

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      activeNav={NAV.TRANSFER}
      breadcrumbs={breadcrumbs}
    >
      <TransferFilterForm {...filter} />
      <TransferOrderingForm {...ordering} />

      <TransferTable
        pageTitle={title}
        parentTitle={NAV.TRANSFER}
        isLoading={isLoading}
        status={status}
        filter={filter}
        ordering={ordering}
        transferList={transferList}
        onProcess={handleProcess}
        onComplete={handleComplete}
        onTransfersDelete={handleRemove}
        onListRefetch={transferList.getListByParams}
        onCancel={handleCancel}
      />
    </DashboardLayout>
  )
}

export default TransferListContainer
