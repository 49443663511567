import { useCreate, useDelete, useDetail, useLimitedCountList, useUpdate } from 'storfox-api-hooks'
import { sprintf } from 'sprintf-js'
import { usePickSearchParams } from 'storfox-route-hooks'

import * as API from '~/constants/api'
import { orderingToSnackCase } from '~/utils'

export const useAutomationRuleList = () => {
  const searchParams = usePickSearchParams()
  return useLimitedCountList(API.AUTOMATION_RULE_LIST, orderingToSnackCase(searchParams))
}

export const useAutomationRulesDelete = () => {
  return useDelete(API.AUTOMATION_RULE_DELETE)
}

export const useAutomationRuleCreate = () => {
  return useCreate(API.AUTOMATION_RULE_CREATE)
}

export const useAutomationRuleUpdate = guid => {
  const url = sprintf(API.AUTOMATION_RULE_UPDATE, guid)

  return useUpdate(url)
}

export const useAutomationRuleDetail = guid => {
  const url = sprintf(API.AUTOMATION_RULE_DETAIL, guid)

  return useDetail(url)
}
