// VARIANT
export const VARIANT_LISTING_CREATE = `/v1/retailer-listings/variants/add/`
export const VARIANT_CHANNEL_LIST = `/v1/retailer-listings/channels/list/`

// LISTING
export const LISTING_LIST = `/v1/retailer-listings/list/`
export const LISTING_PUBLISH = `/v1/retailer-listings/publish/`
export const LISTING_UNPUBLISH = `/v1/retailer-listings/unpublish/`
export const LISTING_PAUSE = `/v1/retailer-listings/pause/`
export const LISTING_REJECT = `/v1/retailer-listings/reject/`
export const LISTING_PRODUCT_DETAIL = `/v1/retailer-listings/products/%s/detail/`
export const LISTING_PRODUCT_UPDATE = `/v1/retailer-listings/products/%s/update/`
export const LISTING_BRANDS_LIST = `/v1/retailer-listings/brands/list/`
export const LISTING_CATEGORIES_LIST = `/v1/retailer-listings/categories/list/`
