import React from 'react'
import { useTranslation } from 'react-i18next'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'

import IntegrationType from '../../components/IntegrationType'

function IntegrationTypeContainer () {
  const { t } = useTranslation()

  const title = t('Integrations')

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      activeNav={NAV.INTEGRATION}
      breadcrumbs={breadcrumbs}
    >
      <IntegrationType />
    </DashboardLayout>
  )
}

export default IntegrationTypeContainer
