import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Button, Typography, useMediaQuery, useTheme } from '@mui/material'

import {
  BlankLayoutStyled,
  ButtonContainerStyled,
  ImageContainerStyled
} from '../components/styled/Error.styled'
import ImageNotFound from '../images/image_page_not_found.svg'

function Error404 () {
  const theme = useTheme()
  const mobileDevice = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <BlankLayoutStyled title="Error 404">
      <Typography
        align="center"
        variant={mobileDevice ? 'h4' : 'h1'}
      >
        404: The page you are looking for isn’t here
      </Typography>
      <Typography
        align="center"
        variant="subtitle2"
      >
        You either tried some shady route or you came here by mistake. Whichever
        it is, try using the navigation
      </Typography>
      <ImageContainerStyled>
        <ImageNotFound />
      </ImageContainerStyled>
      <ButtonContainerStyled>
        <Button
          component={RouterLink}
          to="/"
          variant="outlined"
        >
          Back to home
        </Button>
      </ButtonContainerStyled>
    </BlankLayoutStyled>
  )
}

export default Error404
