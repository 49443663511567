import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, useLocation } from 'react-router-dom'

import * as ROUTES from '~/constants/routes'

import { useToken } from '../Token'

function AuthLayout ({ children }) {
  const { token } = useToken()
  const location = useLocation()

  return (
    <>
      {token
        ? children
        : (
          <Navigate
            replace={true}
            state={{ from: location }}
            to={{
              pathname: ROUTES.SIGN_IN,
              search: encodeURIComponent(`redirectUrl=${location.pathname}${location.search}`),
            }}
          />
        )
      }
    </>
  )
}

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired
}

export default AuthLayout
