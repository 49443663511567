import React from 'react'
import { prop } from 'ramda'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { useAllSearchParams } from 'storfox-route-hooks'

import TplGeneral from './General/TplGeneral'
import OrdersGeneral from './Orders/OrdersGeneral'
import OrdersPercentage from './Orders/OrdersPercentage'

import { getDateRangeFromParams } from '../../../utils'
import PickerStatistics from '../PickerStatistics'
import ReceivingStatistics from '../ReceivingStatistics'
import OrdersByDateAndUnit from '../OrdersByDateAndUnit'
import StockInOut from '../StockInOut'
import Shipments from '../Shipments'

function TplOverview (props) {
  const {
    stockOverview,
    stockFlow,
    orderOverview,
    shipmentOverview,
    ordersByUnitsOverview,
    poByUnitsOverview,
    pickerStatistic,
    packerStatistic,
    receiveStatistic,
    putawayStatistic
  } = props

  const { dateRange: initialDateRange } = useAllSearchParams()
  const dateRange = getDateRangeFromParams(initialDateRange)

  const isLoading = prop('isLoading', stockOverview)
  const general = prop('detail', stockOverview)

  const stockFlowList = prop('results', stockFlow)

  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12}>
        <TplGeneral data={general} isLoading={isLoading} />
      </Grid>
      <Grid item={true} xs={12}>
        <OrdersGeneral orderOverview={orderOverview} isLoading={orderOverview.isLoading} />
      </Grid>
      <Grid item={true} xs={12}>
        <OrdersPercentage orderOverview={orderOverview} isLoading={orderOverview.isLoading} />
      </Grid>
      <Grid item={true} xs={12}>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={8} xs={12}>
            <StockInOut dateRange={dateRange} items={stockFlowList} />
          </Grid>
          <Grid item={true} lg={4} xs={12}>
            <Shipments isLoading={shipmentOverview.isLoading} shipmentOverview={shipmentOverview} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item={true} xs={12}>
        <OrdersByDateAndUnit
          dateRange={dateRange}
          ordersByUnitsOverview={ordersByUnitsOverview}
          poByUnitsOverview={poByUnitsOverview}
        />
      </Grid>
      <Grid item={true} xs={12}>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={6} xs={12}>
            <PickerStatistics pickerStatistic={pickerStatistic} packerStatistic={packerStatistic} />
          </Grid>
          <Grid item={true} lg={6} xs={12}>
            <ReceivingStatistics receiveStatistic={receiveStatistic} putawayStatistic={putawayStatistic} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

TplOverview.propTypes = {
  stockOverview: PropTypes.object.isRequired,
  stockFlow: PropTypes.object.isRequired,
  shipmentOverview: PropTypes.object.isRequired,
  ordersByUnitsOverview: PropTypes.object.isRequired,
  poByUnitsOverview: PropTypes.object.isRequired,
  pickerStatistic: PropTypes.object.isRequired,
  packerStatistic: PropTypes.object.isRequired,
  putawayStatistic: PropTypes.object.isRequired,
  receiveStatistic: PropTypes.object.isRequired,
  orderOverview: PropTypes.object.isRequired
}

export default TplOverview
