import React from 'react'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box'
import TableContainer from '@mui/material/TableContainer'
import PropTypes from 'prop-types'
import { prop, propOr, reduce } from 'ramda'
import Typography from '@mui/material/Typography'
import { IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import InfoIcon from '@mui/icons-material/Info'

import { CardHeader } from '~/components/Cards'
import { emptyArray } from '~/constants/empty'
import { getFormattedDate } from '~/utils'
import HtmlTooltip from '~/components/HtmlTooltip/HtmlTooltip'

import Chart from './Chart'

import { generateDays, getLabelsFromDateRange } from '../../../utils'

const getData = (items, days) => {
  const keyValue = reduce((acc, item) => {
    return { ...acc, [prop('date', item)]: item }
  }, {}, items)
  let data = []

  for (let i = 0; i < days.length; i++) {
    const day = days[i]
    const date = getFormattedDate(day)
    const item = prop(date, keyValue)
    const divisions = propOr([], 'divisions', item)
    divisions.map((div) => {
      const foundDiv = data.find((d) => d.carrier === div.carrier)
      if (foundDiv) {
        foundDiv.items[i] = propOr(0, 'shipments', div)
        const otherDiv = data.filter((d) => d.carrier !== div.carrier)
        data = [foundDiv, ...otherDiv]
      } else {
        const newDiv = { carrier: div.carrier, items: [] }
        newDiv.items = Array.from({ length: days.length }).map(() => 0)
        newDiv.items[i] = propOr(0, 'shipments', div)
        data = [newDiv, ...data]
      }
    })
  }

  return data
}

function CarrierDivision ({ items, dateRange }) {
  const { t } = useTranslation()
  const days = generateDays(dateRange)
  const labels = getLabelsFromDateRange(dateRange) || emptyArray
  const data = getData(items, days)

  return (
    <Card>
      <CardHeader
        title={
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h4">{t('Carrier Division')}</Typography>
            <HtmlTooltip title="Shipments received on daily basis, weekly or past 30 days.">
              <IconButton size="small">
                <InfoIcon sx={{ color: '#BDBDBD' }} />
              </IconButton>
            </HtmlTooltip>
          </Box>
        }
      />
      <Divider />
      <CardContent sx={{ p: 0 }}>
        <TableContainer>
          <Box sx={{ minWidth: 700 }}>
            <Chart
              labels={labels}
              data={data}
              sx={{
                padding: theme => theme.spacing(4, 2, 0, 2),
                height: 400
              }}
            />
          </Box>
        </TableContainer>
      </CardContent>
    </Card>
  )
}

CarrierDivision.propTypes = {
  dateRange: PropTypes.array,
  items: PropTypes.array,
}

export default CarrierDivision
