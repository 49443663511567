import { path } from 'ramda'
import React from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import { UNIT } from '~/components/Fields/MethodOfMeasurementField'
import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'
import { PRODUCT_DETAIL_TABS } from '~/constants/tabs'
import useMessages from '~/hooks/useMessages'
import { useCompany } from '~/components/Profile'

import { useProductBrandCreate, useProductCategoryCreate, useProductCreate } from '../hooks'
import { FIFO, PHYSICAL } from '../constants'
import { ProductCreateSerializer } from '../serializers'
import { ProductCreateForm } from '../components/ProductCreate'

const getInitialValues = (company) => ({
  type: PHYSICAL,
  strategy: FIFO,
  unitOfMeasurement: UNIT,
  pieces: 1,
  company: company
})

function ProductCreateContainer () {
  const messages = useMessages()
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const { company } = useCompany()
  const productCreate = useProductCreate()
  const productCategoryCreate = useProductCategoryCreate()
  const productBrandCreate = useProductBrandCreate()
  const title = 'New Product'

  const handleCategoryCreate = name =>
    productCategoryCreate.create({ name, status: 'active' })
      .then(res => {
        snackbar({ message: messages.CREATE_SUCCESS })
        return res
      })

  const handleBrandCreate = name =>
    productBrandCreate.create({ name, status: 'active' })
      .then(res => {
        snackbar({ message: messages.CREATE_SUCCESS })
        return res
      })

  const handleSubmit = formValues =>
    productCreate.create(ProductCreateSerializer(formValues))
      .then(data => {
        const params = {
          id: path(['result', 'id'], data),
          tab: PRODUCT_DETAIL_TABS.GENERAL
        }
        const redirect = generatePath(ROUTES.PRODUCT_DETAIL_PATH, params)

        navigate(redirect)
      })
      .then(() => snackbar({ message: messages.CREATE_SUCCESS }))

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      isLoading={productCreate.isLoading}
      activeNav={NAV.PRODUCTS}
      breadcrumbs={breadcrumbs}
    >

      <ProductCreateForm
        title={title}
        onSubmit={handleSubmit}
        initialValues={getInitialValues(company)}
        isLoading={productCreate.isLoading}
        categoryLoading={productCategoryCreate.isLoading}
        brandLoading={productBrandCreate.isLoading}
        onCategoryCreate={handleCategoryCreate}
        onBrandCreate={handleBrandCreate}
      />

    </DashboardLayout>
  )
}

export default ProductCreateContainer
