import React from 'react'

import SelectField from '~/components/Fields/SelectField'
import { DELIVERED, IN_TRANSIT, PRE_TRANSIT, RETURNED, UNSHIPPED } from '~/components/Statuses/ShipmentStatus'

export const OPTIONS = [
  { value: UNSHIPPED, name: 'Unshipped' },
  { value: PRE_TRANSIT, name: 'Pre-transit' },
  { value: IN_TRANSIT, name: 'In transit' },
  { value: DELIVERED, name: 'Delivered' },
  { value: RETURNED, name: 'Returned' },
]

function ShipmentStatusField (props) {
  return (
    <SelectField
      name="status"
      label="Shipment status"
      options={OPTIONS}
      MenuProps={{
        'data-cy': 'shipmentStatusMenu'
      }}
      SelectDisplayProps={{
        'data-cy': 'shipmentStatusField'
      }}
      {...props}
    />
  )
}

export default ShipmentStatusField
