import React, { useCallback, useEffect } from 'react'
import { useField } from 'react-final-form'
import { prop } from 'ramda'
import PropTypes from 'prop-types'

import ConstrainedField from '~/components/Fields/ConstrainedField'

function ReturnQuantityField ({ name, conditionName, ...props }) {
  const { input } = useField(name)
  const conditionField = useField(conditionName)
  const condition = conditionField.input.value

  const handleChange = useCallback(input.onChange, [])

  useEffect(() => {
    const quantity = prop('quantity', condition)
    if (quantity) {
      handleChange(1)
    }
  }, [condition, handleChange])

  return (
    <ConstrainedField
      name={name}
      {...props}
    />
  )
}

ReturnQuantityField.propTypes = {
  name: PropTypes.string.isRequired,
  conditionName: PropTypes.string.isRequired
}
export default ReturnQuantityField
