import React from 'react'

import SelectField from '~/components/Fields/SelectField'

import { CANCELLED, COMPLETED, IN_PROGRESS, NEW, PENDING, STATUSES } from '../../constants'

const OPTIONS = [
  {
    value: '',
    name: 'All'
  },
  {
    value: NEW,
    name: STATUSES[NEW]
  },
  {
    value: PENDING,
    name: STATUSES[PENDING]
  },
  {
    value: IN_PROGRESS,
    name: STATUSES[IN_PROGRESS]
  },
  {
    value: COMPLETED,
    name: STATUSES[COMPLETED]
  },
  {
    value: CANCELLED,
    name: STATUSES[CANCELLED]
  },
]

function StockCountStatusField ({ ...props }) {
  return (
    <SelectField
      name="status"
      label="Status"
      options={OPTIONS}
      {...props}
    />
  )
}

export default StockCountStatusField
