import React from 'react'

import SelectField from '~/components/Fields/SelectField'

import { ALLOCATED, ALLOCATION_PENDING, PARTIALLY_ALLOCATED, UNALLOCATED, FAILED } from '../Statuses/AllocationStatus'

export const OPTIONS = [
  {
    value: UNALLOCATED,
    name: 'Unallocated'
  },
  {
    value: ALLOCATION_PENDING,
    name: 'Pending'
  },
  {
    value: ALLOCATED,
    name: 'Allocated'
  },
  {
    value: PARTIALLY_ALLOCATED,
    name: 'Partially allocated'
  },
  {
    value: FAILED,
    name: 'Failed'
  }
]

function AllocationStatusField (props) {
  return (
    <>
      <SelectField
        name="allocationStatus"
        label="Allocation status"
        options={OPTIONS}
        {...props}
      />
    </>
  )
}

export default AllocationStatusField
