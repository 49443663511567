import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import VariantListContainer from './containers/VariantListContainer'
import VariantUpdateContainer from './containers/VariantUpdateContainer'
import VariantDetailContainer from './containers/VariantDetailContainer'

export default () => [
  {
    layout: AuthLayout,
    path: ROUTES.VARIANT_LIST_PATH,
    component: VariantListContainer
  },
  {
    layout: AuthLayout,
    path: ROUTES.VARIANT_DETAIL_PATH,
    component: VariantDetailContainer
  },
  {
    layout: AuthLayout,
    path: ROUTES.VARIANT_UPDATE_PATH,
    component: VariantUpdateContainer
  }
]
