import React from 'react'
import PropTypes from 'prop-types'

import { PUTAWAY } from '../ActionFields/ActionSelectField'
import UserSelectField from '../ActionFields/UserSelectField'

export const REPLENISHMENT_FIELDS = [
  'putaway'
]

function ReplenishmentAction ({ action, actionName }) {
  return (
    <>
      {action === PUTAWAY && <UserSelectField name={`${actionName}.assignTo`} />}
    </>
  )
}

ReplenishmentAction.propTypes = {
  action: PropTypes.string.isRequired,
  actionName: PropTypes.string.isRequired
}

export default ReplenishmentAction
