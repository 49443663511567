import { colors } from '@mui/material'

import palette from '~/theme/light/palette'

export default {
  styleOverrides: {
    root: {
      backgroundColor: 'white'
    },
    scanned: {
      boxShadow: `inset 4px 0px 0px ${colors.yellow[200]}`,
      backgroundColor: `${colors.grey[100]}59`
    },
    fullyScanned: {
      boxShadow: `inset 4px 0px 0px ${palette.primary.main}`,
      backgroundColor: `${colors.grey[100]}59`
    },
    active: {
      boxShadow: `inset 4px 0px 0px ${colors.grey[500]}`,
      backgroundColor: `${colors.grey[100]}59`
    }
  }
}
