import axios from 'axios'

import useDeepCompareEffect, { useDeepCompareCallback } from './useDeepCompareEffect'
import Request from './request'
import useRequest from './useRequest'
import useGetApi from './api/useGetApi'
import usePostApi from './api/usePostApi'
import usePutApi from './api/usePutApi'
import useDeleteApi from './api/useDeleteApi'
import useUpload from './api/useUpload'
import useDetail from './crud/useDetail'
import useDelete from './crud/useDelete'
import useList from './crud/useList'
import useLimitedCountList from './crud/useLimitedCountList'
import useCountList, { getCount } from './crud/useCountList'
import useScrollList from './crud/useScrollList'
import useCreate from './crud/useCreate'
import useUpdate from './crud/useUpdate'
import toCamelCase from './utils/toCamelCase'
import toSnakeCase from './utils/toSnakeCase'
import { getToken, setToken } from './utils/token'
import { getApiUrl, getCdnUrl, getSocketUrl, isDev, isProd } from './utils/environment'

const { CancelToken } = axios
const getCancelToken = () => CancelToken.source()

export {
  useDeepCompareCallback,
  useDeepCompareEffect,

  useGetApi,
  usePostApi,
  usePutApi,
  useDeleteApi,
  useUpload,

  useDetail,
  useList,
  useLimitedCountList,
  useCountList,
  useScrollList,
  useCreate,
  useUpdate,
  useDelete,

  Request,
  useRequest,

  setToken,
  getToken,
  toCamelCase,
  toSnakeCase,

  getApiUrl,
  getSocketUrl,
  getCdnUrl,

  getCount,
  getCancelToken,
  isProd,
  isDev
}
