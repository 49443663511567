import React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

import * as ROUTES from '~/constants/routes'
import Tabs from '~/components/Tabs'
import Divider from '~/components/Divider'
import { WAREHOUSE_TABS } from '~/constants/tabs'

export const TABS = [
  { value: WAREHOUSE_TABS.GENERAL, label: 'General' },
  { value: WAREHOUSE_TABS.AREA, label: 'Areas' },
  { value: WAREHOUSE_TABS.ZONE, label: 'Zones' },
  { value: WAREHOUSE_TABS.LOCATION, label: 'Locations' },
  { value: WAREHOUSE_TABS.CONTAINER, label: 'Containers' }
]

function WarehouseDetailTabs ({ guid }) {
  const navigate = useNavigate()
  const { tab } = useParams()

  const handleChange = (event, tab) => {
    const path = generatePath(ROUTES.WAREHOUSE_DETAIL_PATH, { guid, tab })

    navigate(path, { replace: true })
  }

  return (
    <>
      <Tabs
        tabs={TABS}
        value={tab}
        onChange={handleChange}
      />
      <Divider />
    </>
  )
}

WarehouseDetailTabs.propTypes = {
  guid: PropTypes.string.isRequired
}

export default WarehouseDetailTabs
