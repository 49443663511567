import React, { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { propOr } from 'ramda'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import useDialog from '~/hooks/useDialog'

import Warehouse2DVisaulizationForm from '../components/Warehouse2DVisualization/Warehouse2DVisaulizationForm'
import { useWarehouseDetail, useWarehouseLocationList } from '../hooks'
import LocationDialog from '../components/Warehouse2DVisualization/LocationDialog'

function Warehouse2DVisualizationContainer () {
  const { guid } = useParams()
  const [location, setLocation] = useState({})
  const { open, handleClose, handleOpen } = useDialog()
  const warehouseDetail = useWarehouseDetail(guid)
  const locationList = useWarehouseLocationList(guid)

  const handleChooseLocation = useCallback((value) => {
    setLocation(value)
    handleOpen()
  }, [handleOpen])

  const handleCloseDialog = useCallback(() => {
    setLocation({})
    handleClose()
  }, [handleClose])

  const title = propOr('Warehouse schema', 'name', warehouseDetail.detail)

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      activeNav={NAV.WAREHOUSES}
      isLoading={warehouseDetail.isLoading}
      breadcrumbs={breadcrumbs}
    >
      <Warehouse2DVisaulizationForm
        onSubmit={null}
        guid={guid}
        locations={locationList.results}
        getList={locationList.getList}
        isLoading={locationList.isLoading}
        onChooseLocation={handleChooseLocation}
      />
      <LocationDialog open={open} location={location} onClose={handleCloseDialog} />
    </DashboardLayout>
  )
}

export default Warehouse2DVisualizationContainer
