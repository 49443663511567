import React from 'react'
import { prop } from 'ramda'
import { BrowserSearchField } from 'storfox-query-builder'

import * as API from '~/constants/api'

const getOptionValue = item => {
  const guid = prop('guid', item)
  const name = prop('code', item)

  return { guid, name }
}

const getOptionLabel = (props, item) => {
  if (item) {
    return <li {...props}>{prop('name', item)}</li>
  }

  return <li {...props} />
}

function ConditionSearchField (props) {
  return (
    <BrowserSearchField
      api={API.AUTOMATION_RULE_CONDITION_LIST}
      getOptionValue={getOptionValue}
      renderOption={getOptionLabel}
      {...props}
    />
  )
}

export default ConditionSearchField
