import { compose, defaultTo, filter, isEmpty, join, map, not, pipe, prop, slice, split } from 'ramda'
import { useAllSearchParams, useRoute } from 'storfox-route-hooks'
import equals from 'ramda/src/equals'
import { useCallback, useMemo } from 'react'

const getSortModel = pipe(
  defaultTo(''),
  split(','),
  map(item => {
    const firstChar = prop(0, item)
    return equals('-', firstChar)
      ? { sort: 'asc', field: slice(1, Infinity, item) }
      : { sort: 'desc', field: item }
  }),
  filter(compose(not, isEmpty, prop('field')))
)

const getNewSortingParams = pipe(
  map(({ field, sort }) => equals('asc', sort) ? `-${field}` : field),
  join(',')
)

function useSorting ({ prefix = '' } = {}) {
  const orderingKey = `${prefix}ordering`
  const searchParams = useAllSearchParams()
  const sortParam = prop(orderingKey, searchParams)
  const { replaceParams, removeParams } = useRoute()
  const initialSortModel = useMemo(() => getSortModel(sortParam), [sortParam])

  const onSortModelChange = useCallback(model => {
    const newSortingParams = getNewSortingParams(model)

    if (!isEmpty(newSortingParams)) {
      replaceParams({ [orderingKey]: newSortingParams })
    }

    if (isEmpty(newSortingParams)) {
      removeParams(orderingKey)
    }
  }, [orderingKey, removeParams, replaceParams])

  return { sortModel: initialSortModel, onSortModelChange }
}

export default useSorting
