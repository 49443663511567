import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import CompanyListContainer from './containers/CompanyListContainer'
import CompanyCreateContainer from './containers/CompanyCreateContainer'
import CompanyUpdateContainer from './containers/CompanyUpdateContainer'

export default () => {
  return [
    {
      layout: AuthLayout,
      path: ROUTES.COMPANY_CREATE_PATH,
      component: CompanyCreateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.COMPANY_UPDATE_PATH,
      component: CompanyUpdateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.COMPANY_LIST_PATH,
      component: CompanyListContainer
    },
  ]
}
