import React from 'react'

import SelectField from '~/components/Fields/SelectField'

const OPTIONS = [
  { value: null, name: 'None' },
  {
    value: 'non-cod',
    name: 'Non cash on Delivery'
  },
  {
    value: 'cod',
    name: 'Cash on Delivery'
  }
]

function PaymentTypeField (props) {
  return (
    <SelectField
      label="Payment type"
      options={OPTIONS}
      {...props}
    />
  )
}

export default PaymentTypeField
