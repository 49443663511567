import palette from '../palette'

export default {
  styleOverrides: {
    textPrimary: {
      color: palette.text.primary
    },
    contained: {
      color: palette.text.primary,
      boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14)',

    },
    containedPrimary: {
      color: palette.text.primary,
      backgroundColor: '#5854bd',
      '&:hover': {
        backgroundColor: '#5854bdAA',
      }
    },
    outlined: {
      border: '1px solid rgba(255, 255, 255, .12)'
    },
    outlinedPrimary: {
      color: palette.text.primary,
      borderColor: palette.text.secondary,
      '&:hover': {
        borderColor: palette.text.primary,
      }
    },
    root: {
      '&.MuiActive': '#e0e0e0'
    }
  }
}
