import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import BundleListContainer from './containers/BundleListContainer'
import BundleCreateContainer from './containers/BundleCreateContainer'
import BundleUpdateContainer from './containers/BundleUpdateContainer'
import BundleDetailContainer from './containers/BundleDetailContainer'

export default () => {
  return [
    {
      layout: AuthLayout,
      path: ROUTES.BUNDLE_CREATE_PATH,
      component: BundleCreateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.BUNDLE_UPDATE_PATH,
      component: BundleUpdateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.BUNDLE_LIST_PATH,
      component: BundleListContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.BUNDLE_DETAIL_PATH,
      component: BundleDetailContainer
    }
  ]
}
