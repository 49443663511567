import { sprintf } from 'sprintf-js'
import { useAllSearchParams, usePickSearchParams } from 'storfox-route-hooks'
import {
  useCreate,
  useDelete,
  useDetail,
  useList,
  useUpdate,
  useLimitedCountList,
  useScrollList
} from 'storfox-api-hooks'

import { orderingToSnackCase, queryToParams } from '~/utils'
import * as API from '~/constants/api'

import { AdjustmentFilterSerializer } from '../serializers'

export const useAdjustmentList = () => {
  const searchParams = useAllSearchParams()
  const params = queryToParams(AdjustmentFilterSerializer, searchParams)

  return useLimitedCountList(API.ADJUSTMENT_LIST, params)
}

export const useAdjustmentDetail = id => {
  const url = sprintf(API.ADJUSTMENT_DETAIL, id)
  return useDetail(url)
}

export const useAdjustmentLineItems = (id, autoSend, params = {}) => {
  const searchParams = usePickSearchParams()
  const url = sprintf(API.ADJUSTMENT_LINE_ITEMS, id)
  return useList(url, { ...orderingToSnackCase(searchParams), ...params }, autoSend)
}

export const useAdjustmentReport = (id) => {
  const url = sprintf(API.ADJUSTMENT_GENERATE_REPORT, id)
  const { create, ...state } = useCreate(url)
  return { ...state, generate: create }
}

export const useAdjustmentCreate = () => {
  return useCreate(API.ADJUSTMENT_CREATE)
}

export const useAdjustmentCreateAndComplete = () => {
  return useCreate(API.ADJUSTMENT_CREATE_AND_COMPLETE)
}

export const useAdjustmentComplete = id => {
  const url = sprintf(API.ADJUSTMENT_COMPLETE, id)
  const { update, ...state } = useUpdate(url)
  return { ...state, complete: update }
}

export const useAdjustmentsDelete = () => {
  return useDelete(API.ADJUSTMENTS_DELETE)
}

export const useAdjustmentCancel = id => {
  const url = sprintf(API.ADJUSTMENT_CANCEL, id)
  const { update, ...state } = useUpdate(url)
  return { ...state, cancel: update }
}

export const useAdjustmentUpdate = id => {
  const url = sprintf(API.ADJUSTMENT_UPDATE, id)
  return useUpdate(url)
}

export const useAdjustmentUpdateAndComplete = id => {
  const url = sprintf(API.ADJUSTMENT_UPDATE_AND_COMPLETE, id)
  return useUpdate(url)
}

export const useBarcodeGenerate = () => {
  const { create, ...state } = useCreate(API.BARCODE_GENERATE)
  return { ...state, generate: create }
}

export const useDefaultConditionList = () => {
  const params = { isDefault: true }
  return useList(API.ADJUSTMENT_CONDITIONS, params, false)
}

export const useUnitCheck = () => {
  const { create, ...state } = useCreate(API.ADJUSTMENT_UNIT_CHECK)

  return { ...state, check: create }
}

export const useBarcodeUpdateCheck = () => {
  const { update, ...state } = useUpdate(API.ADJUSTMENT_BARCODE_UPDATE_CHECK)

  return { ...state, check: update }
}

export const useBarcodeCheck = () => {
  const { create, ...state } = useCreate(API.ADJUSTMENT_BARCODE_CHECK)

  return { ...state, check: create }
}

export const useAdjustmentImport = () => {
  return useCreate(API.ADJUSTMENT_FILE_UPLOAD)
}

export const useAdjustmentVariant = (params) => {
  return useScrollList(API.ADJUSTMENT_VARIANTS, params)
}

export const useAdjustmentHistory = (guid, autoSend) => {
  const searchParams = useAllSearchParams()
  const url = sprintf(API.ADJUSTMENT_HISTORY, guid)

  return useList(url, searchParams, autoSend)
}
