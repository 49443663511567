import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import { Alert } from '@mui/material'

function PutawayTitle ({ isLocationEmpty, trackSerial }) {
  const getTitle = () => {
    if (isLocationEmpty) {
      return 'Scan location'
    }

    if (trackSerial) {
      return 'Scan serial number'
    }

    return 'Scan unit or container'
  }

  return (
    <Alert variant="standard" severity="info">
      <Typography variant="h5">{getTitle()}</Typography>
    </Alert>
  )
}
PutawayTitle.propTypes = {
  isLocationEmpty: PropTypes.bool.isRequired,
  trackSerial: PropTypes.bool.isRequired
}

export default PutawayTitle
