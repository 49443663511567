import React from 'react'
import PropTypes from 'prop-types'
import InputAdornment from '@mui/material/InputAdornment'
import { prop } from 'ramda'
import { useTranslation } from 'react-i18next'
import { PositiveFloatField } from 'storfox-form-fields'

import { getCurrencySymbol } from '~/utils'

import { useCompany } from '../Profile'

function MoneyField ({ label, currency, InputProps, ...props }) {
  const { company } = useCompany()
  const { t } = useTranslation()
  const companyCurrency = prop('currency', company)
  const symbol = currency || getCurrencySymbol(companyCurrency)

  return (
    <PositiveFloatField
      label={t(label)}
      {...props}
      InputProps={{
        ...InputProps,
        startAdornment: (
          <InputAdornment position="start">{symbol}</InputAdornment>
        ),
      }}
    />
  )
}

MoneyField.propTypes = {
  label: PropTypes.string,
  currency: PropTypes.string,
  InputProps: PropTypes.object
}

export default MoneyField
