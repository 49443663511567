import React from 'react'
import { prop } from 'ramda'

import * as API from '~/constants/api'
import SearchField from '~/components/Fields/SearchField'

const getOptionLabel = item => {
  if (item) {
    return prop('shipmentNumber', item)
  }

  return ''
}

const getOptionValue = item => {
  if (item) {
    const id = prop('id', item)
    const guid = prop('guid', item)
    const shipmentNumber = prop('shipmentNumber', item)

    return { id, guid, shipmentNumber }
  }

  return null
}

function ShipmentField (props) {
  return (
    <SearchField
      name="shipment"
      label="Shipment"
      api={API.SHIPMENT_LIST}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      {...props}
    />
  )
}

export default ShipmentField
