import React from 'react'

import SelectField from '~/components/Fields/SelectField'

import { FULFILLMENT_TYPES } from '../../../constants/BillingProfile'

const OPTIONS = [
  {
    value: FULFILLMENT_TYPES.FIXED,
    name: 'Fixed'
  },
  {
    value: FULFILLMENT_TYPES.PER_ORDER,
    name: 'Per order'
  },
  {
    value: FULFILLMENT_TYPES.PER_ORDER_UNIT_QTY,
    name: 'Per order unit quantity'
  },
  {
    value: FULFILLMENT_TYPES.PER_ORDER_LINE_ITEM_COUNT,
    name: 'Pre order line item count'
  }
]

function FulfillmentChargeTypeField ({ ...props }) {
  return (
    <SelectField
      label="Charge per"
      name="type"
      data-cy="type"
      options={OPTIONS}
      {...props}
    />
  )
}

export default FulfillmentChargeTypeField
