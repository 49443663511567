import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { map, path, prop, propOr } from 'ramda'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import EditIcon from '@mui/icons-material/Edit'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

import { capitalize, unescapeBtoa } from '~/utils'
import { MoreListButton } from '~/components/Buttons'
import BooleanStatus from '~/components/BooleanStatus'
import { CommaSeparatedList } from '~/components/Text'
import TextOverflow from '~/components/TextOverflow'
import { Dimensions, Volume, Weight } from '~/components/Converters'
import { useBarcodeSelect } from '~/components/BarcodePrintDialog/hooks'
import { Table, TableHeader } from '~/components/Table'
import * as ROUTES from '~/constants/routes'
import { LOCATION_TABS } from '~/constants/tabs'
import TableLink from '~/components/Link'
import { useTableSelects } from '~/components/TableValues/hooks'

import BulkActionButton from './BulkActionButton'

const getDetailPath = guid => {
  const params = { guid, tab: LOCATION_TABS.GENERAL }
  return generatePath(ROUTES.LOCATION_DETAIL_PATH, params)
}

export const LOCATION_COLUMNS = [
  {
    width: 200,
    headerName: 'Location label',
    field: 'locationId',
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        <TextOverflow selfTooltip={true}>{value}</TextOverflow>
      </TableLink>
    )
  },
  {
    width: 150,
    headerName: 'Area',
    field: 'area',
    renderCell: ({ value }) => (
      <TextOverflow selfTooltip={true}>{value}</TextOverflow>
    )

  },
  {
    width: 150,
    headerName: 'Zone',
    field: 'zone',
    renderCell: ({ value }) => (
      <TextOverflow selfTooltip={true}>{value}</TextOverflow>
    )
  },
  {
    width: 75,
    headerName: 'Aisle',
    field: 'aisle',
    ordering: true
  },
  {
    width: 75,
    headerName: 'Bay',
    field: 'bay',
    ordering: true
  },
  {
    width: 75,
    headerName: 'Level',
    field: 'level',
    ordering: true
  },
  {
    width: 75,
    headerName: 'Bin',
    field: 'bin',
    ordering: true
  },
  {
    width: 75,
    headerName: 'Picking order',
    field: 'pickingOrder',
    ordering: true
  },
  {
    width: 100,
    headerName: 'Status',
    field: 'status',
    renderCell: ({ value }) => (
      <BooleanStatus value={value} />
    )
  },
  {
    width: 100,
    headerName: 'In use',
    field: 'inUse',
    renderCell: ({ value }) => {
      if (value) {
        return <CheckIcon sx={{ color: 'green' }} />
      } else {
        return <CloseIcon sx={{ color: 'red' }} />
      }
    }
  },
  {
    width: 150,
    headerName: 'Operations',
    field: 'operations',
    renderCell: ({ value }) => (
      <CommaSeparatedList list={value} />
    )
  },
  {
    width: 150,
    headerName: 'Location type',
    field: 'locationType',
    ordering: true
  },
  {
    width: 150,
    headerName: 'Location alias',
    field: 'locationAlias'
  },
  {
    width: 100,
    headerName: 'Containers',
    field: 'containers',
    headerAlign: 'center',
    align: 'center'
  },
  {
    width: 100,
    headerName: 'Available',
    field: 'available',
    headerAlign: 'center',
    align: 'center',
    renderCell: ({ value }) => (
      <TextOverflow selfTooltip={true} lines={1}>
        {value}
      </TextOverflow>
    )
  },
  {
    width: 100,
    headerName: 'Volume',
    field: 'volume',
    renderCell: ({ value }) => (
      <Volume value={value} />
    )
  },
  {
    width: 150,
    headerName: 'Used Volume',
    field: 'usedVolume',
    headerAlign: 'center',
    align: 'center',
    renderCell: ({ value }) => (
      <Volume value={value} />
    )
  },
  {
    width: 100,
    headerName: 'Dimensions',
    field: 'dimensions',
    valueFormatter: ({ value }) => (
      `${value.length}x${value.width}x${value.height}`
    ),
    renderCell: ({ value }) => (
      <Dimensions
        length={value.length}
        width={value.width}
        height={value.height}
      />
    )
  },
  {
    width: 100,
    headerName: 'Max. Load',
    field: 'maxLoad',
    renderCell: ({ value }) => (
      <Weight value={value} />
    )
  },
  {
    width: 100,
    headerName: 'Used Load',
    field: 'usedLoad',
    renderCell: ({ value }) => (
      <Weight value={value} />
    )
  },
  {
    width: 100,
    headerName: 'Utilization',
    field: 'utilization',
    headerAlign: 'center',
    align: 'center'
  }
]

const getRowsFromResults = map(item => {
  const id = prop('id', item)
  const guid = prop('guid', item)
  const locationId = prop('locationId', item)
  const area = path(['area', 'name'], item)
  const zone = path(['zone', 'name'], item) || '-'
  const aisle = prop('aisle', item) || '-'
  const bay = prop('bay', item) || '-'
  const level = prop('level', item) || '-'
  const bin = prop('bin', item) || '-'
  const status = prop('status', item)

  const locationAlias = prop('locationAlias', item)
  const locationType = capitalize(prop('locationType', item))
  const operations = propOr([], 'allowedOperations', item)

  const containers = prop('containersCount', item)

  const length = prop('length', item)
  const width = prop('width', item)
  const height = prop('height', item)

  const available = prop('available', item)
  const pickingOrder = prop('pickingOrder', item)

  const dimensions = { length, width, height }
  const maxLoad = prop('maxLoad', item)
  const usedLoad = prop('usedLoad', item)
  const volume = prop('volume', item)
  const usedVolume = prop('usedVolume', item)
  const utilization = prop('utilization', item)
  const link = getDetailPath(guid)

  return {
    id,
    guid,
    locationId,
    area,
    zone,
    aisle,
    bay,
    level,
    bin,
    status,
    available,
    pickingOrder,
    operations,
    locationAlias,
    locationType,
    containers,
    dimensions,
    maxLoad,
    usedLoad,
    volume,
    usedVolume,
    inUse: utilization,
    link
  }
})

function LocationTable ({ list, filter, ordering, onListRefetch, onLocationsDelete }) {
  const [tableRef, setTableRef] = useState(null)
  const { guid } = useParams()
  const navigate = useNavigate()
  const { selects, resetTableSelects } = useTableSelects()

  const isLoading = prop('isLoading', list)
  const results = prop('results', list)
  const count = prop('count', list)
  const columns = prop('filteredColumns', ordering)

  const { selectedRows } = useBarcodeSelect(results, 'guid', selects)

  const createPath = generatePath(ROUTES.LOCATION_CREATE_PATH, { warehouseGuid: guid })

  const handleEdit = useCallback(guid => {
    const editPath = generatePath(ROUTES.LOCATION_UPDATE_PATH, { guid })
    navigate(editPath)
  }, [navigate])

  const printLocationBarcode = useCallback(() => {
    let printBarcode = []
    for (const location of selectedRows) {
      const locationId = prop('locationId', location)
      printBarcode.push({ barcode: locationId })
    }
    const data = unescapeBtoa(printBarcode)
    window.open(`${ROUTES.BARCODE_GENERATOR_PATH}?barcodes=${data}`, '_blank')
    resetTableSelects()
  }, [selectedRows, resetTableSelects])

  const actionColumns = useMemo(() => [
    ...columns,
    {
      width: 50,
      resizable: false,
      field: 'actions',
      type: 'actions',
      getActions: ({ row }) => [
        <GridActionsCellItem
          label="Edit"
          onClick={() => handleEdit(row.guid)}
          showInMenu={true}
          icon={<EditIcon />}
        />
      ]
    }
  ], [columns, handleEdit])

  return (
    <>
      <TableHeader
        filter={filter}
        filterOpen={filter.handleOpen}
        orderingOpen={ordering.handleOpen}
        ordering={ordering}
        onCreateClick={() => navigate(createPath)}
      >
        <BulkActionButton
          onLocationPrint={printLocationBarcode}
          onLocationsDelete={onLocationsDelete}
        />
        <MoreListButton tableRef={tableRef} />
      </TableHeader>
      <Table
        isLoading={isLoading}
        columns={actionColumns}
        getRowsFromResults={getRowsFromResults}
        results={results}
        count={count}
        pinnedColumns={['locationId']}
        checkboxSelection={true}
        primaryKey="guid"
        ordering={ordering}
        onRefetch={onListRefetch}
        setTableRef={setTableRef}
      />
    </>
  )
}

LocationTable.propTypes = {
  list: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  onLocationsDelete: PropTypes.func.isRequired,
  onListRefetch: PropTypes.func.isRequired
}

export default LocationTable
