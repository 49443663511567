import React from 'react'
import PropTypes from 'prop-types'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import { SingleImagePreview } from 'storfox-image-upload'
import { prop } from 'ramda'
import { generatePath, useParams } from 'react-router-dom'

import { CardHeader } from '~/components/Cards'
import { DiscardButton, EditButton } from '~/components/Buttons'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import * as NAV from '~/constants/nav-titles'
import LoaderProvider from '~/components/Loader'
import TextLabelLoader from '~/components/TextLabelLoader'
import * as ROUTES from '~/constants/routes'

function BrandDetail ({ title, isLoading, detail, pageTitleLoading }) {
  const { id } = useParams()
  const name = prop('name', detail)
  const slug = prop('slug', detail)
  const imagePath = prop('imagePath', detail)
  const editPath = generatePath(ROUTES.BRAND_UPDATE_PATH, { id })
  return (
    <LoaderProvider isLoading={isLoading}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={title}
            parentTitle={NAV.BRANDS}
            pageTitleLoading={pageTitleLoading}
            rightButton={(
              <>
                <DiscardButton disabled={isLoading} />
                <EditButton data-cy="edit" disabled={isLoading} path={editPath} />
              </>
            )}
          />
        </Header>

        <Content>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12} lg={8}>
              <Box mb={3}>
                <Card>
                  <CardHeader title="Details" />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12}>
                        <TextLabelLoader label="Name">
                          {name}
                        </TextLabelLoader>
                      </Grid>

                      <Grid item={true} xs={12}>
                        <TextLabelLoader label="Slug">
                          {slug}
                        </TextLabelLoader>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
            <Grid item={true} xs={12} lg={4}>
              <Box mb={3}>
                <Card>
                  <CardHeader title="Image" />
                  <Divider />
                  <CardContent>
                    <SingleImagePreview src={imagePath} />
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
          <Box mb={3} />
        </Content>
      </Container>
    </LoaderProvider>
  )
}

BrandDetail.propTypes = {
  title: PropTypes.string,
  pageTitleLoading: PropTypes.bool,
  detail: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default BrandDetail
