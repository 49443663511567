import React from 'react'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'
import { useOrdering } from 'storfox-filter'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import ErrorLink from '~/components/ErrorLink'
import useMessages from '~/hooks/useMessages'
import { useTableSelects } from '~/components/TableValues/hooks'

import {
  DeliveryMethodList,
  DeliveryMethodOrderingForm,
  deliveryMethodOrderingOptions
} from '../../components/DeliveryMethod/DeliveryMethodList'
import { useDeliveryMethodList, useDeliveryMethodsDelete } from '../../hooks/DeliveryMethod'

function DeliveryMethodListContainer () {
  const snackbar = useSnackbar()
  const messages = useMessages()

  const deliveryMethodList = useDeliveryMethodList()
  const deliveryMethodsDelete = useDeliveryMethodsDelete()

  const { selects, resetTableSelects } = useTableSelects()

  const ordering = useOrdering(deliveryMethodOrderingOptions)

  const title = 'Delivery methods'

  const handleDeliveryMethodsDelete = () =>
    deliveryMethodsDelete.delete({ guids: selects })
      .then(() => snackbar({ message: messages.DELETE_SUCCESS }))
      .then(() => resetTableSelects())
      .then(() => deliveryMethodList.getList())
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const breadcrumbs = { title }

  return (

    <DashboardLayout
      title={title}
      activeNav={NAV.CONFIGURATION}
      breadcrumbs={breadcrumbs}
    >
      <DeliveryMethodOrderingForm {...ordering} />

      <DeliveryMethodList
        pageTitle={title}
        ordering={ordering}
        deliveryMethodList={deliveryMethodList}
        onListRefetch={deliveryMethodList.getListByParams}
        onDeliveryMethodsDelete={handleDeliveryMethodsDelete}
        bulkActionLoading={deliveryMethodsDelete.isLoading}
      />

    </DashboardLayout>
  )
}

export default DeliveryMethodListContainer
