import React from 'react'

import SelectField from '~/components/Fields/SelectField'

const OPTIONS = [
  {
    name: 'Active',
    value: 'active'
  },
  {
    name: 'Inactive',
    value: 'inactive'
  }
]

function AutomationRuleStatusField () {
  return (
    <SelectField
      label="Status"
      name="status"
      options={OPTIONS}
    />
  )
}

export default AutomationRuleStatusField
