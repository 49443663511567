import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card/Card'
import CardContent from '@mui/material/CardContent'
import { useTranslation } from 'react-i18next'
import { PasswordField } from 'storfox-form-fields'
import PropTypes from 'prop-types'

import StatusField from '~/components/Fields/StatusField'
import TextField from '~/components/Fields/TextField'
import Divider from '~/components/Divider'
import { CardHeader } from '~/components/Cards'

import SallaCreateType, { SALLA_APP_TYPE } from '../SallaCreateType'

function SallaUpdate ({ type }) {
  const { t } = useTranslation()
  return (
    <>
      <Grid container={true}>
        <Grid item={true} xs={12}>
          <Box mb={3}>
            <Card>
              <CardHeader title={t('Integration')} />
              <Divider />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={12} lg={3}>
                    <TextField
                      data-cy="name"
                      name="name"
                      label="Name"
                    />
                  </Grid>
                  <Grid item={true} xs={12} lg={3}>
                    <TextField
                      data-cy="storeDomain"
                      name="storeDomain"
                      label="Shop"
                      disabled={true}
                    />
                  </Grid>

                  <Grid item={true} xs={12} lg={3}>
                    <StatusField />
                  </Grid>

                  <Grid item={true} xs={12} lg={3}>
                    <SallaCreateType />
                  </Grid>

                  {type === SALLA_APP_TYPE && (
                    <>
                      <Grid item={true} xs={12} lg={3}>
                        <TextField
                          data-cy="credential.sallaClientId"
                          name="credential.sallaClientId"
                          label="Client ID"
                          autocomplete="off"
                          disabled={true}
                        />
                      </Grid>
                      <Grid item={true} xs={12} lg={3}>
                        <PasswordField
                          data-cy="credential.sallaClientSecretKey"
                          name="credential.sallaClientSecretKey"
                          label="Client secret key"
                          autocomplete="off"
                          disabled={true}
                        />
                      </Grid>
                      <Grid item={true} xs={12} lg={3}>
                        <PasswordField
                          data-cy="credential.sallaWebhookSecret"
                          name="credential.sallaWebhookSecret"
                          label="Webhook secret"
                          autocomplete="off"
                          disabled={true}
                        />
                      </Grid>
                      <Grid item={true} xs={12} lg={3}>
                        <TextField
                          data-cy="id"
                          name="id"
                          label="Integration ID"
                          disabled={true}
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item={true} xs={12}>
                    <TextField
                      data-cy="description"
                      name="description"
                      label="Description"
                      multiline={true}
                      minRows={4}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

SallaUpdate.propTypes = {
  type: PropTypes.string
}

export default SallaUpdate
