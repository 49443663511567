import { MAC_OS } from '~/utils'

export default {
  styleOverrides: !MAC_OS
    ? (`
      :root ::-webkit-scrollbar {
        width: 12px;
        height: 10px;
      }

      :root ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: #b4b4b4;
        border: 2px white solid;
        outline:  2px white solid;
      }
  `)
    : ``

}
