import React from 'react'
import { useFilter, useOrdering } from 'storfox-filter'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'

import {
  BillingProfileFilterForm,
  billingProfileFilterOptions,
  BillingProfileList,
  BillingProfileOrderingForm,
  billingProfileOrderingOptions
} from '../../components/BillingProfile/BillingProfileList'
import { useBillingProfileList } from '../../hooks/BillingProfile'

function BillingProfileListContainer () {
  const billingProfileList = useBillingProfileList()

  const filter = useFilter(billingProfileFilterOptions)
  const ordering = useOrdering(billingProfileOrderingOptions)

  const breadcrumbs = { title: 'Billing profiles' }

  return (
    <DashboardLayout
      title="Billing profiles"
      activeNav={NAV.CONFIGURATION}
      isLoading={billingProfileList.isLoading}
      breadcrumbs={breadcrumbs}
    >
      <BillingProfileFilterForm {...filter} />
      <BillingProfileOrderingForm {...ordering} />

      <BillingProfileList
        filter={filter}
        ordering={ordering}
        list={billingProfileList}
        onListRefetch={billingProfileList.getListByParams}
        isLoading={billingProfileList.isLoading}
      />
    </DashboardLayout>
  )
}

export default BillingProfileListContainer
