import { path } from 'ramda'
import { useEffect, useState } from 'react'

function useScanSerial (activeLineItem, variantType) {
  const [scanSerial, setScanSerial] = useState(false)

  const trackSerial = path([variantType, 'trackSerial'], activeLineItem)
  const activeSerial = path(['serial', 'serialNumber'], activeLineItem)

  useEffect(() => {
    const shouldScanSerial = activeLineItem && trackSerial && !activeSerial
    const haveSerial = activeLineItem && trackSerial && activeSerial

    if (!activeLineItem) {
      setScanSerial(false)
    }

    if (shouldScanSerial) {
      setScanSerial(true)
    }

    if (haveSerial) {
      setScanSerial(false)
    }
  }, [activeLineItem, activeSerial, trackSerial])

  return { scanSerial }
}

export default useScanSerial
