import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'storfox-snackbar'
import { useNavigate, useParams } from 'react-router-dom'
import { path, prop } from 'ramda'

import { DashboardLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'

import { useAdjustmentReasonDetail, useAdjustmentReasonUpdate } from '../../hooks/AdjustmentReason'
import AdjustmentReasonCreate from '../../components/AdjustmentReason/AdjustmentReasonCreate'

function AdjustmentReasonUpdateContainer () {
  const { t } = useTranslation()
  const { id } = useParams()
  const adjustmentReasonDetail = useAdjustmentReasonDetail(id)
  const adjustmentReasonUpdate = useAdjustmentReasonUpdate(id)
  const snackbar = useSnackbar()
  const navigate = useNavigate()

  const title = path(['detail', 'name'], adjustmentReasonDetail)
  const message = t('Successfully created')

  const handleSubmit = formValues => {
    return adjustmentReasonUpdate.update(formValues)
      .then(() => snackbar({ message }))
      .then(() => navigate(ROUTES.ADJUSTMENT_REASON_LIST_PATH))
  }

  const isLoading = adjustmentReasonDetail.isLoading || adjustmentReasonUpdate.isLoading
  const pageLoading = prop('isLoading', adjustmentReasonDetail)

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      isLoading={pageLoading}
      activeNav={NAV.CONFIGURATION}
      breadcrumbs={breadcrumbs}
    >
      <AdjustmentReasonCreate
        pageTitle={title}
        pageTitleLoading={pageLoading}
        initialValues={prop('detail', adjustmentReasonDetail)}
        isLoading={isLoading}
        onSubmit={handleSubmit}
      />
    </DashboardLayout>
  )
}

export default AdjustmentReasonUpdateContainer
