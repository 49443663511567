import React from 'react'
import PropTypes from 'prop-types'
import TablePagination, { tablePaginationClasses } from '@mui/material/TablePagination'
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions'
import { styled } from '@mui/material/styles'
import { useRoute, useSearchParams } from 'storfox-route-hooks'

const TablePaginationStyled = styled(TablePagination)({
  [`& .${tablePaginationClasses.spacer}`]: {
    flex: 'auto'
  }
})

const rowsPerPageOptions = [10, 25, 50, 100, 250, 500, 1000]

const DEFAULT_PAGE_SIZE = 10

function PaginationNewType ({ prefix, hasNextPage, hasPrevPage, rowCount }) {
  const searchParams = useSearchParams()
  const { changeParams } = useRoute()

  const pageKey = `${prefix}page`
  const pageSizeKey = `${prefix}limit`
  const selectKey = 'select'

  const pageStr = searchParams.get(pageKey)
  const pageSizeStr = searchParams.get(pageSizeKey)

  const page = pageStr ? Number(pageStr) : 1
  const pageSize = pageSizeStr ? Number(pageSizeStr) : DEFAULT_PAGE_SIZE

  const ActionsComponent = () => {
    const possibleCount = hasNextPage ? (pageSize * (page + 2)) : pageSize * page
    return (
      <TablePaginationActions
        count={possibleCount}
        page={page}
        getItemAriaLabel={() => {}}
        onPageChange={(event, value) => {
          const newPage = value > 0 ? value : 1
          changeParams({ [pageKey]: newPage, [selectKey]: null })
        }}
        rowsPerPage={pageSize}
        backIconButtonProps={{ disabled: !hasPrevPage }}
      />
    )
  }

  const countForNewPagination = hasNextPage ? -1 : pageSize * (page - 1) + rowCount

  return (
    <TablePaginationStyled
      component="div"
      count={countForNewPagination}
      page={page - 1}
      rowsPerPage={pageSize}
      onPageChange={() => {}}
      getItemAriaLabel={() => {}}
      onRowsPerPageChange={(event) => {
        changeParams({ [pageSizeKey]: event.target.value, [selectKey]: null, [pageKey]: 1 })
      }}
      rowsPerPageOptions={rowsPerPageOptions}
      ActionsComponent={ActionsComponent}
      backIconButtonProps={{ disabled: true }}
    />
  )
}

PaginationNewType.propTypes = {
  prefix: PropTypes.string.isRequired,
  hasNextPage: PropTypes.bool.isRequired,
  hasPrevPage: PropTypes.bool.isRequired,
  rowCount: PropTypes.string.isRequired,
}

export default PaginationNewType
