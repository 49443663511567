import React, { useCallback, useEffect, useRef } from 'react'
import DialogContent from '@mui/material/DialogContent'
import Dialog from '@mui/material/Dialog'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell/TableCell'
import TableBody from '@mui/material/TableBody'
import { useTranslation } from 'react-i18next'
import { path, prop } from 'ramda'
import { useField } from 'react-final-form'
import Grid from '@mui/material/Grid'
import DialogActions from '@mui/material/DialogActions/DialogActions'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import DialogTitle from '~/components/DialogTitle'
import { withForm } from '~/components/Form'
import BarcodeField from '~/components/BarcodeField'
import { useScreenOutline } from '~/components/ScreenOutline'
import { Button, DiscardButton } from '~/components/Buttons'

function AslScanTableDialog ({ onClose, open, unit, form, isLoading, allAslNumbers, ...props }) {
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const { handleSoundedTrigger, handleSoundedErrorTrigger } = useScreenOutline()
  const { handleSubmit } = form

  const quantity = prop('quantity', unit)

  const barcodeRef = useRef(null)
  const barcodeField = useField('barcode')
  const clearBarcodeField = useCallback(() => barcodeField.input.onChange(''), [barcodeField])
  const focusBarcodeField = () => barcodeRef.current.focus()
  const scannedField = useField('scanned')
  const scannedFieldValue = scannedField.input.value || []
  const scannedFieldChange = scannedField.input.onChange
  const unitGuidField = useField('unitGuid')
  const unitGuidFieldChange = unitGuidField.input.onChange

  useEffect(() => {
    unitGuidFieldChange(prop('guid', unit))
  }, [unitGuidFieldChange, unit])

  const onBarcodeScan = useCallback((event) => {
    const barcode = event.target.value
    const sameBarcode = [...allAslNumbers, ...scannedFieldValue].includes(barcode)
    if (!sameBarcode) {
      if (barcode.length === 37) {
        if (scannedFieldValue.length < quantity) {
          handleSoundedTrigger()
          clearBarcodeField()
          scannedFieldChange([...scannedFieldValue, barcode])
        } else {
          handleSoundedErrorTrigger()
          clearBarcodeField()
        }
      } else {
        handleSoundedErrorTrigger()
        clearBarcodeField()
        snackbar({ message: 'ASL should be 37 character length', type: ALTER_ERROR })
      }
    } else {
      handleSoundedErrorTrigger()
      clearBarcodeField()
      snackbar({ message: 'ASL Already scanned', type: ALTER_ERROR })
    }
    event.preventDefault()
  }, [
    scannedFieldValue,
    quantity,
    handleSoundedTrigger,
    clearBarcodeField,
    scannedFieldChange,
    handleSoundedErrorTrigger,
    snackbar,
    allAslNumbers
  ])

  return (
    <Dialog
      maxWidth="md"
      onClose={onClose}
      open={open}
      fullWidth={true}
      {...props}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          <Grid container={true} spacing={3}>
            <Grid item={true} lg={6}>
              <Typography component="h3" variant="h6">
                {path(['variant', 'name'], unit)}
              </Typography>
            </Grid>
            <Grid item={true} lg={6}>
              <Typography component="h3" variant="h6" sx={{ justifySelf: 'right' }}>
                {`${scannedFieldValue.length}/${quantity}`}
              </Typography>
            </Grid>
            <Grid item={true} lg={12}>
              <BarcodeField
                barcodeRef={barcodeRef}
                focusBarcodeField={focusBarcodeField}
                onEnter={(e) => onBarcodeScan(e)}
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers={true}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('Barcode')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {scannedFieldValue.map((item) => {
                  return (
                    <TableRow>
                      <TableCell>
                        {item}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <DiscardButton
            fullWidth={true}
            onClick={onClose}
          >
            Discard
          </DiscardButton>
          <Button
            variant="contained"
            disabled={isLoading || scannedFieldValue.length !== quantity}
            type="submit"
            fullWidth={true}
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

AslScanTableDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  unit: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  allAslNumbers: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired
}

export default withForm(AslScanTableDialog)
