import PropTypes from 'prop-types'
import React from 'react'
import { styled, Typography } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import { is } from 'ramda'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

const RootStyled = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  textAlign: 'center',
  borderLeft: `1px solid ${theme.palette.divider}`,
  flexBasis: '100%',
  [theme.breakpoints.down('md')]: {
    '&:not(:last-of-type)': {
      borderBottom: `1px solid ${theme.palette.divider}`
    }
  }
}))

function StatusGridItem ({ title, isLoading, children }) {
  const { t } = useTranslation()

  const translatedTitle = is(String, title) ? t(title) : title

  return (
    <RootStyled>
      <Typography
        component="div"
        variant="overline"
        sx={{ lineHeight: '26px' }}
      >
        {translatedTitle}
      </Typography>
      <Typography variant="h3">
        {isLoading ? <Skeleton /> : children}
      </Typography>
    </RootStyled>
  )
}

StatusGridItem.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  title: PropTypes.any.isRequired,
  children: PropTypes.any
}

export default StatusGridItem
