import React from 'react'
import { useFilter, useOrdering } from 'storfox-filter'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'

import { useBucketList } from '../hooks'
import {
  BucketFilterForm,
  bucketFilterOptions,
  BucketOrderingForm,
  bucketOrderingOptions,
  BucketTable
} from '../components/BucketList'

function BucketListContainer () {
  const bucketList = useBucketList()

  const filter = useFilter(bucketFilterOptions)
  const ordering = useOrdering(bucketOrderingOptions)

  const breadcrumbs = { title: NAV.BUCKETS }

  return (
    <DashboardLayout
      title={NAV.BUCKETS}
      activeNav={NAV.BUCKETS}
      breadcrumbs={breadcrumbs}
    >
      <BucketFilterForm {...filter} />
      <BucketOrderingForm {...ordering} />

      <BucketTable
        list={bucketList}
        filter={filter}
        ordering={ordering}
        onListRefetch={bucketList.getListByParams}
      />
    </DashboardLayout>
  )
}

export default BucketListContainer
