import { defaultTo, filter, find, map, path, pick, pipe, prop, propOr } from 'ramda'
import { DEFAULT_SEARCH_PARAMS } from 'storfox-route-hooks'
import { DateTime } from 'luxon'

import { escapeAtob, getDateTimeMidnight, getFormattedDateTime, orderingToSnackCase } from '~/utils'
import { useCompany } from '~/components/Profile'

import { PURCHASE_ORDER_CREATE_FIELDS } from '../components/PurchaseOrderCreate'
import { CASH } from '../components/Fields/PaymentTypeField'

const getSupplierGuid = path(['supplier', 'guid'])

const getLineItemGuidAndQuantity = (lineItems) => map(item => {
  const guid = prop('guid', item)
  const quantity = prop('quantity', item)
  return { purchaseLine: { guid }, quantity }
},
lineItems)

const getLineItemsWithQuantity = (lineItems) => filter(item => {
  const quantity = prop('quantity', item)
  return quantity > 0
}, lineItems)

export function PurchaseOrderCreateInitSerializer (warehouses, tax) {
  const firstWarehouse = propOr({}, 0, warehouses)
  const { company } = useCompany()

  const warehouse = pipe(
    defaultTo([]),
    find(prop('isDefault')),
    defaultTo(firstWarehouse)
  )(warehouses)

  const orderDate = DateTime.now()
    .startOf('day')

  const expectedDate = DateTime.now()
    .plus({ days: 14 })
    .startOf('day')

  const defaultPreview = { tax, quantity: 1, price: 0 }

  return {
    paymentType: CASH,
    company,
    warehouse,
    orderDate,
    expectedDate,
    defaultPreview
  }
}

export function PurchaseOrderUpdateInitSerializer (values, tax) {
  const defaultPreview = { tax, quantity: 1, price: 0 }

  return { ...values, defaultPreview }
}

export function PurchaseOrderFilterSerializer (params) {
  const defaultParams = pick(DEFAULT_SEARCH_PARAMS, params)
  const status = prop('status', params)
  const supplierId = escapeAtob(prop('supplier', params))
  const warehouseIds = escapeAtob(prop('warehouses', params))
  const variantGuids = escapeAtob(prop('variants', params))
  const createdAtEnd = prop('createdAtEnd', params)
  const createdAtStart = prop('createdAtStart', params)
  const totalPriceStart = prop('totalPriceStart', params)
  const totalPriceEnd = prop('totalPriceEnd', params)

  return orderingToSnackCase({
    ...defaultParams,
    status,
    supplierId,
    warehouseIds,
    variantGuids,
    createdAtStart,
    createdAtEnd,
    totalPriceStart,
    totalPriceEnd
  })
}

const getLineItems = pipe(
  prop('lineItems'),
  defaultTo([]),
  map(lineItem => {
    const guid = prop('guid', lineItem)
    const productVariant = prop('productVariant', lineItem)
    const quantity = prop('quantity', lineItem)
    const tax = prop('tax', lineItem)
    const price = prop('price', lineItem)
    const supplierSku = prop('supplierSku', lineItem)
    const variantGuid = prop('guid', productVariant)
    const discount = prop('discount', lineItem)

    return {
      guid,
      price,
      quantity,
      supplierSku,
      tax,
      discount,
      productVariant: variantGuid && { guid: variantGuid },
    }
  })
)

export function PurchaseOrderCreateSerializer (formValues) {
  const supplierGuid = getSupplierGuid(formValues)
  const warehouseGuid = path(['warehouse', 'guid'], formValues)

  const data = {
    ...formValues,
    supplier: supplierGuid && { guid: supplierGuid },
    expectedDate: getDateTimeMidnight(prop('expectedDate', formValues)),
    orderDate: getDateTimeMidnight(prop('orderDate', formValues)),
    lineItems: getLineItems(formValues),
    warehouse: warehouseGuid && { guid: warehouseGuid }
  }

  return pick(PURCHASE_ORDER_CREATE_FIELDS, data)
}

export function PurchaseOrderSupplierCreateSerializer (formValues) {
  return formValues
}

export function PurchaseOrderGatePassSerializer (formValues) {
  const invoiceNumber = prop('invoiceNumber', formValues)
  const purchaseGuid = prop('guid', formValues)
  const numberPlate = path(['vehicle', 'numberPlate'], formValues)
  const lineItems = prop('lineItems', formValues)
  const driver = prop('driver', formValues)
  const timeIn = getFormattedDateTime(path(['driver', 'timeIn'], formValues))
  const timeOut = getFormattedDateTime(path(['driver', 'timeOut'], formValues))
  const lineItemsWithQuantity = getLineItemsWithQuantity(lineItems)
  const guidAndQuantity = getLineItemGuidAndQuantity(lineItemsWithQuantity)

  const purchase = { guid: purchaseGuid }
  const vehicle = numberPlate && { numberPlate }

  return {
    invoiceNumber,
    lines: guidAndQuantity,
    purchase,
    vehicle,
    driver: { ...driver, timeIn, timeOut }
  }
}
