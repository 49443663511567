import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { BrowserCodeSearchField as StorfoxBrowserCodeSearchField } from 'storfox-form-fields'

function BrowserSearchField ({ label, ...props }) {
  const { t } = useTranslation()

  return (
    <StorfoxBrowserCodeSearchField
      label={t(label)}
      {...props}
    />
  )
}

BrowserSearchField.propTypes = {
  api: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  size: PropTypes.string,
  variant: PropTypes.string,
  params: PropTypes.object,
  getOptionValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
  renderOption: PropTypes.func,
  onValueChange: PropTypes.func,
  autocompleteProps: PropTypes.object,
  getOnChangeValue: PropTypes.func
}

export default BrowserSearchField
