import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import CodeGeneratorContainer from './containers/CodeGeneratorContainer'

export default () => {
  return [
    {
      layout: AuthLayout,
      path: ROUTES.BARCODE_GENERATOR_PATH,
      component: CodeGeneratorContainer
    }
  ]
}
