import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { path, prop, propOr } from 'ramda'
import { Card, Divider } from '@mui/material'
import CardContent from '@mui/material/CardContent'

import { getFullName } from '~/utils'
import LoaderProvider from '~/components/Loader'
import { CardHeader } from '~/components/Cards'
import TextLabelLoader from '~/components/TextLabelLoader'
import NotesCard from '~/components/NotesCard'

import ContainerizeCard from './ContainerizeCard'
import PackingListItems from './PackingListItems'
import PackingInformation from './PackingInformation'

import ContainerItemsLoading from '../ContainerizeCard/ContainerItemsLoading'
import ContainerItems from '../ContainerizeCard/ContainerItems'

function PackingGeneral ({ values, packedCount, detailLoading, totalQuantity }) {
  const containers = propOr([], 'containers', values)
  const lineItems = propOr([], 'lineItems', values)
  const packer = getFullName(prop('packer', values))
  const notes = prop('note', values)

  return (
    <LoaderProvider isLoading={detailLoading}>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12} lg={9}>
          <PackingInformation values={values} />
        </Grid>
        <Grid item={true} xs={12} lg={3}>
          <Card style={{ height: '100%' }}>
            <CardHeader title="Packer" />
            <Divider />
            <CardContent style={{ height: '100%' }}>
              <TextLabelLoader label="Packer">
                {packer}
              </TextLabelLoader>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Box mb={2} />
      <Grid container={true} spacing={2}>
        <Grid item={true} lg={4} xs={12}>
          <PackingListItems
            lineItems={lineItems}
            isLoading={detailLoading}
          />
        </Grid>
        <Grid item={true} lg={8} xs={12}>
          <Box sx={{ display: 'grid', gridTemplateRows: '3fr 1fr', height: '100%' }}>
            <ContainerizeCard packedCount={packedCount} totalQuantity={totalQuantity}>
              <Box mt={2}>
                <Grid container={true} spacing={3}>
                  {!detailLoading
                    ? containers.map((container, index) => {
                      const containerNumber = prop('number', container)
                      const containerType = path(['containerType', 'name'], container)
                      const products = propOr([], 'lineItems', container)
                      const length = containers.length

                      return (
                        <Grid key={containerNumber} item={true} xs={12}>
                          <ContainerItems
                            totalContainers={containers.length}
                            currentNumber={index + 1}
                            containerNumber={containerNumber}
                            containerType={containerType}
                            products={products}
                            length={length}
                            removable={false}
                          />
                        </Grid>
                      )
                    }).reverse()
                    : <ContainerItemsLoading numberOfItems={1} />
                  }
                </Grid>
              </Box>
            </ContainerizeCard>

            <Box mt={2}>
              <NotesCard notes={notes} />
            </Box>
          </Box>

          <Box mt={2} />
        </Grid>
      </Grid>
    </LoaderProvider>
  )
}

PackingGeneral.propTypes = {
  detailLoading: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  packedCount: PropTypes.number,
  totalQuantity: PropTypes.number
}

export default PackingGeneral
