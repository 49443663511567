import React from 'react'
import PropTypes from 'prop-types'

import TableSplitButton from '~/components/TableSplitButton'

function BulkActionButton (props) {
  const {
    onProcess,
    onProgress,
    onBatchClick,
    onGeneratePdf,
    onBulkAssign,
    loading
  } = props

  const options = [
    {
      label: 'Process new',
      'data-cy': 'processMenu',
      onMenuClick: onProcess
    },
    {
      label: 'Progress pending',
      'data-cy': 'progressMenu',
      onMenuClick: onProgress
    },
    {
      label: 'Batch',
      'data-cy': 'batchMenu',
      onMenuClick: onBatchClick
    },
    {
      label: 'Generate PDF',
      'data-cy': 'generatePdf',
      onMenuClick: onGeneratePdf
    },
    {
      label: 'Bulk assign',
      'data-cy': 'bulkAssign',
      onMenuClick: onBulkAssign
    },
  ]

  return (
    <TableSplitButton
      options={options}
      name="picklist"
      loading={loading}
    />
  )
}

BulkActionButton.propTypes = {
  onProcess: PropTypes.func.isRequired,
  onProgress: PropTypes.func.isRequired,
  onBatchClick: PropTypes.func.isRequired,
  onGeneratePdf: PropTypes.func.isRequired,
  onBulkAssign: PropTypes.func.isRequired,
  loading: PropTypes.bool
}

export default BulkActionButton
