import React from 'react'
import PropTypes from 'prop-types'
import { useField } from 'react-final-form'
import Box from '@mui/material/Box'

import PutawayCreatedAction from './PutawayCreatedAction'
import PutawayV2CreatedAction from './PutawayV2CreatedAction'
import PickingCreatedAction from './PickingCreatedAction'
import PackingCreatedAction from './PackingCreatedAction'
import OrderCreatedAction from './OrderCreatedAction'
import OrderChangedAction from './OrderChangedAction'
import ShipmentCreatedAction from './ShipmentCreatedAction'
import LowStockAction from './LowStockAction'
import ReplenishmentAction from './ReplenishmentAction'
import ListingCreatedAction from './RuleListingCreatedAction'
import PurchaseChangeAction from './PurchaseChangeAction'
import ShipmentStatusChangeAction from './ShipmentStatusChangeAction'

import {
  LISTING_CREATED,
  LOW_STOCK,
  ORDER_CHANGED,
  ORDER_CREATED,
  PACKING_CREATED,
  PICKING_CREATED,
  PUTAWAY_CREATED,
  PUTAWAY_V2_CREATED,
  REPLENISHMENT,
  SHIPMENT_CREATED,
  PURCHASE_CHANGE,
  SHIPMENT_SET_STATUS
} from '../../../constants/AutomationRule'

const ACTIONS = {
  [ORDER_CREATED] : OrderCreatedAction,
  [ORDER_CHANGED]: OrderChangedAction,
  [PICKING_CREATED]: PickingCreatedAction,
  [PUTAWAY_CREATED]: PutawayCreatedAction,
  [PUTAWAY_V2_CREATED]: PutawayV2CreatedAction,
  [PACKING_CREATED]: PackingCreatedAction,
  [SHIPMENT_CREATED] : ShipmentCreatedAction,
  [LOW_STOCK]: LowStockAction,
  [REPLENISHMENT]: ReplenishmentAction,
  [LISTING_CREATED]: ListingCreatedAction,
  [PURCHASE_CHANGE]: PurchaseChangeAction,
  [SHIPMENT_SET_STATUS]: ShipmentStatusChangeAction
}

function SelectedAction ({ name, event }) {
  const actionField = useField(`${name}.action`)
  const action = actionField.input.value
  const Component = ACTIONS[event]

  return (
    <Box width="100%">
      <Component
        name={name}
        event={event}
        action={action}
        actionName={`${name}.${action}`}
      />
    </Box>
  )
}

SelectedAction.propTypes = {
  name: PropTypes.string.isRequired,
  event: PropTypes.string.isRequired,
}

export default SelectedAction
