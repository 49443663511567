import React from 'react'
import { range } from 'ramda'
import Skeleton from '@mui/material/Skeleton'
import Divider from '@mui/material/Divider'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'

const StatsBoxStyled = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  maxWidth: 150,
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(3, 2),
  '&:not(:last-of-type)': {
    borderRight: `1px solid ${theme.palette.divider}`
  }
}))

function Loader () {
  const items = range(0, 3)

  return <>
    <Box p={3} display="flex" justifyContent="center">
      <Skeleton
        variant="circular"
        width={270}
        height={270}
      />
    </Box>
    <Divider />
    <Box display="flex">
      {items.map(index => {
        return (
          <StatsBoxStyled key={index}>
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </StatsBoxStyled>
        )
      })}
    </Box>
  </>
}

export default Loader
