import React from 'react'

import SelectField from '~/components/Fields/SelectField'

import { PACKING_TYPES } from '../../../constants/BillingProfile'

const OPTIONS = [
  {
    value: PACKING_TYPES.PER_CONTAINER_TYPE,
    name: 'Container type'
  }
]

function PackingChargeTypeField (props) {
  return (
    <SelectField
      label="Charge per"
      name="type"
      data-cy="type"
      options={OPTIONS}
      {...props}
    />
  )
}

export default PackingChargeTypeField
