import React from 'react'
import { SelectField } from 'storfox-query-builder'

import { PAUSED, PENDING, PUBLISHED, REJECTED, STATUS_NAMES, UNPUBLISHED } from '~/components/Statuses/ListingStatus'

const OPTIONS = [
  { value: UNPUBLISHED, name: STATUS_NAMES[UNPUBLISHED] },
  { value: PENDING, name: STATUS_NAMES[PENDING] },
  { value: PUBLISHED, name: STATUS_NAMES[PUBLISHED] },
  { value: PAUSED, name: STATUS_NAMES[PAUSED] },
  { value: REJECTED, name: STATUS_NAMES[REJECTED] }
]

function ListingStatusField (props) {
  return (
    <SelectField
      options={OPTIONS}
      controlSx={{
        width: 'auto',
        minWidth: 160
      }}
      {...props}
    />
  )
}

export default ListingStatusField
