import React from 'react'
import { useField } from 'react-final-form'
import { isEmpty } from 'ramda'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'

import { useFileUpload } from './useFileUpload'
import Dropzone from './Dropzone'

import Button from '../Buttons/Button'

function FileUpload ({ className, name, Component, ...props }) {
  const field = useField(name)
  const onChange = field.input.onChange

  const onUpload = (data, onProgress) => {
    return props.onUpload(data, onProgress)
      .then(data => {
        onChange(data)
        return data
      })
      .catch(err => Promise.reject(err))
  }

  const [file, handleUpload, setFile] = useFileUpload({ onUpload })

  const handleRemove = () => {
    setFile({})
    onChange(null)
  }

  return (
    <Box position="relative" className={className}>
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Button
          onClick={handleRemove}
          disabled={isEmpty(file)}
        >
          Delete
        </Button>
      </Box>
      {!isEmpty(file) && <Component file={file} information={field.input.value} />}
      {isEmpty(file) && <Dropzone onDrop={handleUpload} />}
    </Box>
  )
}

FileUpload.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  onUpload: PropTypes.func.isRequired,
  Component: PropTypes.func.isRequired
}

export default FileUpload
