import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog/Dialog'
import DialogContent from '@mui/material/DialogContent/DialogContent'
import DialogActions from '@mui/material/DialogActions/DialogActions'
import Grid from '@mui/material/Grid'
import { prop, propOr } from 'ramda'
import { useField } from 'react-final-form'

import DialogTitle from '~/components/DialogTitle'
import { DiscardButton, PrimaryButton } from '~/components/Buttons'
import { withForm } from '~/components/Form'

import OptionSelectField from './OptionSelectField'

function VariantOptionsDialogForm ({ open, onClose, options, variantOptions, form }) {
  const selfOptionsField = useField('selfOptions')
  const selfOptionsValue = selfOptionsField.input.value || []

  return (
    <form onSubmit={form.handleSubmit}>
      <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="xs">
        <DialogTitle>Set options</DialogTitle>
        <DialogContent dividers={true}>
          <Grid container={true} spacing={3}>
            {options.map((item, index) => {
              const label = prop('label', item)
              const optionOptions = propOr([], 'values', item)
              return (
                <Grid item={true} lg={12} key={label}>
                  <OptionSelectField
                    name={`options[${index}]`}
                    options={optionOptions}
                    label={label}
                    variantOptions={variantOptions}
                  />
                </Grid>
              )
            })}
            {selfOptionsValue.map((item, index) => {
              const name = prop('name', item)
              const option = prop('option', item)
              return (
                <Grid item={true} lg={12} key={name}>
                  <OptionSelectField
                    name={`selfOptions[${index}]`}
                    options={[{ name: option, option }]}
                    label={name}
                    variantOptions={variantOptions}
                  />
                </Grid>
              )
            })}
          </Grid>
        </DialogContent>

        <DialogActions>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12} lg={6}>
              <DiscardButton fullWidth={true} onClick={onClose} />
            </Grid>
            <Grid item={true} xs={12} lg={6}>
              <PrimaryButton fullWidth={true} onClick={form.handleSubmit}>Done</PrimaryButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </form>
  )
}

VariantOptionsDialogForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  variantOptions: PropTypes.object.isRequired
}

export default withForm(VariantOptionsDialogForm)
