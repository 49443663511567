import React from 'react'
import Grid from '@mui/material/Grid'
import { FieldArray } from 'react-final-form-arrays'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'

import MoneyField from '~/components/Fields/MoneyField'
import WeightField from '~/components/Fields/WeightField'
import { Button } from '~/components/Buttons'

function ContainerWeightPricing () {
  return (
    <FieldArray
      name="receivingBilling.conditions"
      render={({ fields }) => {
        const handlePricingAdd = () => {
          fields.push({})
        }

        const handlePricingRemove = index => {
          fields.remove(index)

          if (fields.value.length < 1) {
            fields.push([{}])
          }
        }

        return (
          <Grid container={true} spacing={2}>
            {fields.map((name, index) => {
              return (
                <React.Fragment key={index}>
                  <Grid item={true} xs={12}>
                    <Grid container={true} spacing={3}>

                      <Grid item={true} xs={12} lg={6}>
                        <WeightField
                          label=" Weight"
                          name={`${name}.weight`}
                        />
                      </Grid>

                      <Grid item={true} xs={12} lg={5}>
                        <MoneyField
                          label=" Price"
                          name={`${name}.price`}
                        />
                      </Grid>

                      <Grid item={true} xs={12} lg={1}>
                        <IconButton onClick={() => handlePricingRemove(index)} size="large">
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </React.Fragment>
              )
            })}
            <Grid item={true} xs={12}>
              <Button
                onClick={handlePricingAdd}
              >
                Add type
              </Button>
            </Grid>
          </Grid>
        )
      }}
    />
  )
}

export default ContainerWeightPricing
