import React from 'react'
import { Ordering } from 'storfox-filter'

import { SALES_OVERVIEW_COLUMNS as tableColumns } from './SalesOverviewTable'

import { FILTER_NAME as name } from '../../../constants/SalesOverview'

export const salesOverviewOrderingOptions = {
  name,
  tableColumns
}

function SalesOverviewOrderingForm (props) {
  return (
    <Ordering {...props} />
  )
}

export default SalesOverviewOrderingForm
