import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useField, useForm } from 'react-final-form'
import { map, path, pathEq, pathOr, pipe, prop, propOr, reduce, uniqBy } from 'ramda'
import { Beforeunload } from 'react-beforeunload'
import { useNavigate } from 'react-router-dom'
import { useConfirm } from 'storfox-confirm-dialog'

import { matchCondition, unescapeBtoa } from '~/utils'
import { withForm } from '~/components/Form'
import PageTitle from '~/components/PageTitle'
import { Button } from '~/components/Buttons'
import FluidContainer, { FluidContent, FluidHeader } from '~/components/FluidContainer'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import { useProfile } from '~/components/Profile'
import DiscardButtonClick from '~/components/Buttons/DiscardButtonClick'
import { CONDITION_PREFIX, DONE } from '~/constants/barcode'
import { useScreenOutline } from '~/components/ScreenOutline'
import useDialog from '~/hooks/useDialog'

import More from './More'
import TransferLineItemGroups from './LineItemGroups/TransferLineItemGroups'

import UnitsBarcodePrintDialogue from '../ReceiveStockTransfer/UnitsBarcodePrintDialogue'
import useScanSerial from '../../hooks/useScanSerial'
import { useDestination } from '../../components/ReceiveBarcoding/LineItemGroups/hooks'
import { getActiveItemMergedWithLineItems, getConditionCodeWithoutPrefix } from '../ReceiveStockTransfer/utils'

const getConditionPrintPath = pipe(
  map(condition => {
    const barcode = prop('code', condition)
    return { barcode: `${CONDITION_PREFIX}${barcode}` }
  }),
  unescapeBtoa,
  data => `${ROUTES.BARCODE_GENERATOR_PATH}?barcodes=${data}`
)

const getInitialSerial = (unitNumber, conditionCode) => ({
  unitNumber,
  quantity: 1,
  conditionCode
})

const getUnitInActive = (active, unit, conditionCode) => {
  const guid = path(['variant', 'guid'], unit)
  const activeGuid = path(['variant', 'guid'], active)
  const activeConditionCode = path(['serial', 'conditionCode'], active)

  const trackSerial = path(['variant', 'trackSerial'], active)
  const activeSerial = path(['serial', 'serialNumber'], active)
  const checkSerial = trackSerial ? !activeSerial : true

  return (
    guid === activeGuid &&
    conditionCode === activeConditionCode &&
    checkSerial
  )
}

function ReceiveTransferBarcoding (props) {
  const {
    form,
    pageTitle,
    conditions,
    conditionListLoading,
    isLoading,
    detailLoading
  } = props
  const { handleSubmit, values } = form

  const { profile } = useProfile()
  const { mutators } = useForm()
  const navigate = useNavigate()
  const onConfirm = useConfirm()
  const destination = useDestination()
  const { handleSoundedTrigger, handleSoundedErrorTrigger } = useScreenOutline()
  const { open, handleOpen, handleClose } = useDialog()

  const defaultConditionCode = path(['condition', 'code'], profile)

  const barcodeRef = useRef(null)
  const [isBarcodeMode, setIsBarcodeMode] = useState(true)
  const [lineItemsStack, setLineItemsStack] = useState({})
  const [activeConditionCode, setActiveConditionCode] = useState(defaultConditionCode)

  const barcodeField = useField('barcode')
  const focusBarcodeField = () => barcodeRef.current.focus()
  const clearBarcodeField = () => barcodeField.input.onChange('')

  const lineItemsField = useField('lineItems')
  const lineItems = lineItemsField.input.value || []

  const activeLineItemField = useField('activeLineItem')
  const activeLineItem = activeLineItemField.input.value
  const handleActiveLineItemChange = activeLineItemField.input.onChange
  const handleActiveLineItemRemove = () => handleActiveLineItemChange(null)

  const previousLineItemsField = useField('previousValues.lineItems')
  const previousLineItems = previousLineItemsField.input.value

  const handleDestinationClear = useCallback(destination.handleClear, [])

  const conditionPrintPath = getConditionPrintPath(conditions)

  const { scanSerial } = useScanSerial(activeLineItem, 'variant')

  useEffect(() => {
    if (!detailLoading) {
      focusBarcodeField()
    }
  }, [detailLoading])

  useEffect(() => {
    if (!isBarcodeMode) {
      handleDestinationClear()
    }
  }, [handleDestinationClear, isBarcodeMode])

  useEffect(() => {
    if (previousLineItems.length) {
      const newLineItemsStack = reduce((acc, item) => {
        const key = path(['variant', 'barcode'], item)
        return { ...acc, [key]: item }
      }, {}, previousLineItems)

      setLineItemsStack(newLineItemsStack)
    }
  }, [previousLineItems])

  const handleDestinationRemove = () => {
    clearBarcodeField()
    handleDestinationClear()
    setIsBarcodeMode(true)
  }

  const handleDone = event => {
    if (!isLoading) {
      if (isBarcodeMode) {
        setIsBarcodeMode(false)
        event.preventDefault()
      }

      if (activeLineItem) {
        const newLineItems = getActiveItemMergedWithLineItems({ activeLineItem, lineItems })
        lineItemsField.input.onChange(newLineItems)
        handleActiveLineItemRemove()
        event.preventDefault()
      }

      if (!isBarcodeMode) {
        setIsBarcodeMode(true)
        handleSubmit()
      }

      clearBarcodeField()
    }
  }

  const addScannedUnit = unit => {
    const unitInActiveItem = getUnitInActive(activeLineItem, unit, activeConditionCode)

    handleSoundedTrigger()

    if (activeLineItem && unitInActiveItem) {
      const serial = prop('serial', activeLineItem)
      const newQuantity = prop('quantity', serial) + 1

      const newSerial = { ...serial, quantity: newQuantity }
      const variant = activeLineItem.variant

      const unitLineItem = { variant, serial: newSerial }

      handleActiveLineItemChange(unitLineItem)
    }

    if (activeLineItem && !unitInActiveItem) {
      const barcode = path(['serial', 'unitNumber'], unit)
      const otherLineItems = lineItems.filter((item) => path(['serial', 'unitNumber'], item) !== barcode)
      const newLineItems = getActiveItemMergedWithLineItems({ activeLineItem, lineItems: otherLineItems })
      lineItemsField.input.onChange(newLineItems)
      mutators.setFieldData('activeLineItem.serial.serialNumber', { error: null })

      const serial = prop('serial', unit)
      const newSerial = { ...serial, quantity: pathOr(0, ['serial', 'quantity'], unit) }

      const newActiveLineItem = { ...unit, serial: newSerial }

      handleActiveLineItemChange(newActiveLineItem)
    }

    if (!activeLineItem) {
      handleActiveLineItemChange(unit)
      focusBarcodeField()
    }

    clearBarcodeField()
  }

  const handleBarcodeScan = event => {
    const barcode = pathOr('', ['target', 'value'], event).trim()
    const isCondition = matchCondition(barcode)
    const isDone = barcode === DONE
    const destinationMode = !isBarcodeMode

    const lineItemGuid = path([barcode, 'guid'], lineItemsStack)
    const variantInStack = path([barcode, 'variant'], lineItemsStack)
    const trackSerial = path(['variant', 'trackSerial'], activeLineItem)
    const activeSerial = path(['serial', 'serialNumber'], activeLineItem)
    const lineItemItem = lineItems.find(pathEq(['serial', 'unitNumber'], barcode))

    if (trackSerial && !activeSerial) {
      const serial = prop('serial', activeLineItem)

      const newSerial = { ...serial, serialNumber: barcode }

      const unitLineItem = { ...activeLineItem, serial: newSerial }

      clearBarcodeField()
      return handleActiveLineItemChange(unitLineItem)
    }

    if (isDone) {
      return handleDone(event)
    }

    if (destinationMode) {
      return destination.handleChange(event, barcode)
    }

    if (isCondition) {
      const conditionValue = getConditionCodeWithoutPrefix(barcode)
      setActiveConditionCode(conditionValue)
      clearBarcodeField()
      focusBarcodeField()
      return
    }

    if (lineItemItem) {
      event.preventDefault()
      const itemSerial = prop('serial', lineItemItem)
      const serialQuantity = propOr(0, 'quantity', itemSerial)

      const scannedUnit = {
        ...lineItemItem,
        serial: {
          ...itemSerial,
          quantity: serialQuantity + 1
        }
      }

      return addScannedUnit(scannedUnit)
    }

    if (variantInStack) {
      event.preventDefault()

      const scannedUnit = {
        guid: lineItemGuid,
        variant: variantInStack,
        serial: getInitialSerial(barcode, activeConditionCode)
      }

      return addScannedUnit(scannedUnit)
    }

    handleSoundedErrorTrigger()
    focusBarcodeField()
    clearBarcodeField()
    event.preventDefault()
  }

  const handleButtonDone = () => {
    if (!scanSerial) {
      if (isBarcodeMode && activeLineItem) {
        const newLineItems = getActiveItemMergedWithLineItems({ activeLineItem, lineItems })
        lineItemsField.input.onChange(newLineItems)
        handleActiveLineItemRemove()
      }

      if (isBarcodeMode) {
        setIsBarcodeMode(false)
      }

      if (!isBarcodeMode) {
        setIsBarcodeMode(true)
        handleSubmit(values)
      }
    } else {
      handleSoundedErrorTrigger()
      mutators.setFieldData('activeLineItem.serial.serialNumber', { error: 'error' })
    }
  }

  const handleUnitsBarcodePrint = useCallback(({ lineItems }) => {
    const printBarcodeData = []
    for (const item of lineItems) {
      const printQuantity = prop('printQuantity', item)
      const variantBarcode = path(['variant', 'barcode'], item)
      for (let i = 0; i < printQuantity; i++) {
        printBarcodeData.push({ barcode: variantBarcode })
      }
    }
    const data = unescapeBtoa(printBarcodeData)
    window.open(`${ROUTES.BARCODE_GENERATOR_PATH}?barcodes=${data}`, '_blank')
    handleClose()
  }, [handleClose])

  const printInitialValues = useMemo(() => {
    const uniqueLineItems = uniqBy(path(['variant', 'barcode']), lineItems)
    const itemsWithPrintQuantity = uniqueLineItems.map(item => ({ ...item, printQuantity: item.received }))
    return { lineItems: itemsWithPrintQuantity }
  }, [lineItems])

  const handleDiscard = () => {
    onConfirm({ title: 'Go back?', message: 'Leaving this page will discard unsaved changes.' })
      .agree(() => navigate(-1))
      .disagree()
  }

  return (
    <FluidContainer>
      <FluidHeader>
        <PageTitle
          pageTitle={pageTitle}
          parentTitle={NAV.RECEIVE}
          rightButton={(
            <>
              <DiscardButtonClick onClick={handleDiscard} />
              <Button
                variant="contained"
                type="button"
                data-cy="done"
                disabled={isLoading}
                onClick={handleButtonDone}
              >
                Done
              </Button>
              <More
                conditionPrintPath={conditionPrintPath}
                conditionPrintPathDisabled={conditionListLoading}
                onPrintUnitsBarcode={handleOpen}
              />
            </>
          )}
        />
      </FluidHeader>
      <FluidContent>
        <TransferLineItemGroups
          isLoading={detailLoading}
          barcodeRef={barcodeRef}
          handleBarcodeScan={handleBarcodeScan}
          activeConditionCode={activeConditionCode}
          isBarcodeMode={isBarcodeMode}
          activeLineItem={activeLineItem}
          handleActiveLineItemRemove={handleActiveLineItemRemove}
          focusBarcodeField={focusBarcodeField}
          handleDestinationRemove={handleDestinationRemove}
          DONE={DONE}
          scanSerial={scanSerial}
        />
        <Beforeunload onBeforeunload={event => event.preventDefault()} />
      </FluidContent>
      {open && (
        <UnitsBarcodePrintDialogue
          onSubmit={handleUnitsBarcodePrint}
          initialValues={printInitialValues}
          open={open}
          onClose={handleClose}
        />
      )}
    </FluidContainer>
  )
}

ReceiveTransferBarcoding.propTypes = {
  form: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  detailLoading: PropTypes.bool.isRequired,
  conditions: PropTypes.array.isRequired,
  conditionListLoading: PropTypes.bool.isRequired,
  pageTitle: PropTypes.string
}

export default withForm(ReceiveTransferBarcoding)
