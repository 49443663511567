import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { path, prop, propOr } from 'ramda'
import { generatePath, useParams } from 'react-router-dom'
import MuiAlert from '@mui/material/Alert'

import { Button, EditButton } from '~/components/Buttons'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import * as NAV from '~/constants/nav-titles'
import {
  SHIPMENT_STATUS
} from '~/components/Statuses/ShipmentStatus'
import { SHIPMENT_DETAIL_TABS } from '~/constants/tabs'
import History from '~/components/History'
import * as ROUTES from '~/constants/routes'
import useDialog from '~/hooks/useDialog'
import { generateCdnUrl } from '~/utils'

import UnitTableDialog from './UnitTableDialog'
import AslScanTableDialog from './AslScanTableDialog'
import ShipmentDetailTabs from './ShipmentDetailTabs'
import ShipmentDetailContent from './ShipmentDetailContent'
import ShipmentDetailGeneral from './ShipmentDetailGeneral'
import More from './More'
import OutboundGatePassDialog from './OutboundGatePassDialog'
import ShipmentSetStatusDialog from './ShipmentSetStatusDialog'
import ShippingInfo from './ShippingInfo'

const Alert = React.forwardRef(function Alert (
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

function ShipmentDetail (props) {
  const {
    title,
    isLoading,
    pageTitleLoading,
    onProcess,
    shipmentHistory,
    onUpdateContainer,
    shipment,
    onReportGenerate,
    onAwbGet,
    onCommercialInvoiceGenerate,
    onRecalculate,
    onOutboundGatePassGenerate,
    onCancelCarrier,
    onSetStatus,
    integrationList,
    setCourierLoading,
    onCourierUpdate,
    aslScanLoading,
    aslScan
  } = props

  const { tab, guid } = useParams()
  const gatepassDialog = useDialog()
  const unitTable = useDialog()
  const aslScanDialog = useDialog()
  const setStatusDialog = useDialog()
  const [aslUnit, setAslUnit] = useState(null)

  const status = prop('status', shipment)
  const units = propOr([], 'units', shipment)
  const isIntegration = Boolean(path(['service', 'id'], shipment))
  const uniqueContainers = prop('uniqueContainers', shipment)
  const shippingMethodError = prop('shippingMethodError', shipment)
  const gatepass = propOr({}, 'gatepass', shipment)
  const trackingUrl = propOr('', 'trackingUrl', shipment)
  const awbUrl = prop('awbUrl', shipment)
  const allAslNumbers = units.reduce((a, b) => [...a, ...propOr([], 'aslNumbers', b)], [])

  const handleOutboundGatePassGenerate = values =>
    onOutboundGatePassGenerate(values)
      .then(() => {
        gatepassDialog.handleClose()
        return Promise.resolve()
      })

  const handleSetStatus = values =>
    onSetStatus(values)
      .then(() => {
        setStatusDialog.handleClose()
        return Promise.resolve()
      })

  const disabled = (
    isLoading ||
    status === SHIPMENT_STATUS.CANCELLED ||
    status === SHIPMENT_STATUS.FAILED ||
    status === SHIPMENT_STATUS.RETURNED ||
    status === SHIPMENT_STATUS.DELIVERED
  )

  const withIntegrationDisabled = isIntegration && (
    disabled ||
    status === SHIPMENT_STATUS.IN_TRANSIT ||
    status === SHIPMENT_STATUS.PRE_TRANSIT
  )

  const processDisabled = withIntegrationDisabled || (
    !isIntegration && disabled
  )

  const recalculateDisabled = (
    isLoading ||
    status !== SHIPMENT_STATUS.UNSHIPPED
  )

  const cancelDisabled = (
    isLoading ||
    status === SHIPMENT_STATUS.CANCELLED ||
    status === SHIPMENT_STATUS.DELIVERED ||
    status === SHIPMENT_STATUS.FAILED ||
    status === SHIPMENT_STATUS.IN_TRANSIT ||
    status === SHIPMENT_STATUS.RETURNED
  )

  const gatepassDisabled = (
    isLoading ||
    status === SHIPMENT_STATUS.IN_TRANSIT ||
    status === SHIPMENT_STATUS.DELIVERED ||
    status === SHIPMENT_STATUS.RETURNED ||
    status === SHIPMENT_STATUS.FAILED ||
    status === SHIPMENT_STATUS.CANCELLED
  )

  const editPath = generatePath(ROUTES.SHIPMENT_UPDATE_PATH, { guid })

  const handleAWBCreate = useCallback(() => {
    if (awbUrl) {
      window.open(generateCdnUrl(awbUrl), '_blank')
    } else {
      onAwbGet()
    }
  }, [awbUrl, onAwbGet])

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={title}
          parentTitle={NAV.SHIPMENTS}
          pageTitleLoading={pageTitleLoading}
          rightButton={(
            <>
              <Button
                variant="contained"
                data-cy="process"
                onClick={() => onProcess(shipment)}
                disabled={processDisabled}
              >
                Process
              </Button>
              <EditButton path={editPath} disabled={disabled} />
              <More
                disableAwbGet={isLoading}
                disableReportGenerate={isLoading}
                disableCommercialInvoice={isLoading}
                disableSetStatus={isLoading}
                disableRecalculate={recalculateDisabled}
                onAwbGet={handleAWBCreate}
                onReportGenerate={onReportGenerate}
                onCommercialInvoiceGenerate={onCommercialInvoiceGenerate}
                onRecalculate={onRecalculate}
                onOutboundGatePassOpen={gatepassDialog.handleOpen}
                onSetStatusOpen={setStatusDialog.handleOpen}
                onCancelCarrier={onCancelCarrier}
                cancelDisabled={cancelDisabled}
                gatepassDisabled={gatepassDisabled}
                trackingUrlDisabled={!trackingUrl}
                onTrackingUrlClick={() => window.open(generateCdnUrl(trackingUrl), '_blank')}
                onASLClick={unitTable.handleOpen}
              />
            </>
          )}
        />
      </Header>

      <OutboundGatePassDialog
        open={gatepassDialog.open}
        onClose={gatepassDialog.handleClose}
        initialValues={{ open: gatepassDialog.open, ...gatepass }}
        onSubmit={handleOutboundGatePassGenerate}
        isLoading={isLoading}
      />

      <UnitTableDialog
        units={units}
        onClose={unitTable.handleClose}
        open={unitTable.open}
        onScanClick={(item) => {
          setAslUnit(item)
          aslScanDialog.handleOpen()
          unitTable.handleClose()
        }}
      />

      {aslScanDialog.open && (
        <AslScanTableDialog
          open={aslScanDialog.open}
          onClose={aslScanDialog.handleClose}
          unit={aslUnit}
          initialValues={{}}
          isLoading={aslScanLoading}
          onSubmit={(values) => {
            aslScan(values)
              .then(() => aslScanDialog.handleClose())
              .then(() => unitTable.handleOpen())
              .then(() => setAslUnit(null))
          }}
          allAslNumbers={allAslNumbers}
        />
      )}

      <ShipmentSetStatusDialog
        open={setStatusDialog.open}
        onClose={setStatusDialog.handleClose}
        initialValues={{ status }}
        onSubmit={handleSetStatus}
        isLoading={isLoading}
      />

      <Content>
        {shippingMethodError && <Alert severity="error">{shippingMethodError}</Alert>}
        <ShipmentDetailTabs value={tab} />

        {tab === SHIPMENT_DETAIL_TABS.GENERAL && <ShipmentDetailGeneral shipment={shipment} />}
        {tab === SHIPMENT_DETAIL_TABS.SHIPPING_INFO && !isLoading && (
          <ShippingInfo
            initialValues={shipment}
            integrationList={integrationList}
            onSubmit={onCourierUpdate}
            isLoading={setCourierLoading}
          />
        )}

        {tab === SHIPMENT_DETAIL_TABS.CONTENT && (
          <ShipmentDetailContent
            uniqueContainers={uniqueContainers}
            onUpdateContainer={onUpdateContainer}
            units={units}
            isLoading={isLoading}
          />
        )}
        {tab === SHIPMENT_DETAIL_TABS.HISTORY && <History history={shipmentHistory} />}
      </Content>
    </Container>
  )
}

ShipmentDetail.propTypes = {
  title: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  onProcess: PropTypes.func.isRequired,
  pageTitleLoading: PropTypes.bool,
  shipmentHistory: PropTypes.object.isRequired,
  onUpdateContainer: PropTypes.func.isRequired,
  shipment: PropTypes.object.isRequired,
  onCommercialInvoiceGenerate: PropTypes.func.isRequired,
  onReportGenerate: PropTypes.func.isRequired,
  onAwbGet: PropTypes.func.isRequired,
  onRecalculate: PropTypes.func.isRequired,
  onOutboundGatePassGenerate: PropTypes.func.isRequired,
  onCancelCarrier: PropTypes.func.isRequired,
  onSetStatus: PropTypes.func.isRequired,
  integrationList: PropTypes.array.isRequired,
  onCourierUpdate: PropTypes.func.isRequired,
  aslScan: PropTypes.func.isRequired,
  aslScanLoading: PropTypes.bool.isRequired,
  setCourierLoading: PropTypes.bool.isRequired
}

export default ShipmentDetail
