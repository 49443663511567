import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import { prop } from 'ramda'
import { Filter } from 'storfox-filter'
import { DateRangeField } from 'storfox-form-fields'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

import { getSeparatedDateRange } from '~/utils'
import Accordion from '~/components/Accordion'

import AutomationRuleStatusField from '../Fields/AutomationRuleStatusField'
import { FILTER_NAME as name } from '../../../constants/AutomationRule'

export const toParams = values => {
  const status = prop('status', values)
  const [createdAtStart, createdAtEnd] = getSeparatedDateRange(prop('createdAt', values))

  return {
    status,
    createdAtStart,
    createdAtEnd
  }
}

export const toValues = params => {
  const status = prop('status', params)
  const createdAt = [prop('createdAtStart', params), prop('createdAtEnd', params)]

  return {
    status,
    createdAt
  }
}

export const transformer = { toParams, toValues }

export const automationRuleFilterOptions = {
  name,
  transformer
}

function AutomationRuleFilterForm (props) {
  const { t } = useTranslation()
  return (
    <Filter {...props}>
      <Accordion title="General" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <AutomationRuleStatusField />
          </Grid>
        </Grid>
      </Accordion>
      <Accordion title="Date" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <Typography variant="body2" gutterBottom={true}>
              {t('Created')}:
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <DateRangeField name="createdAt" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Accordion>
    </Filter>
  )
}

AutomationRuleFilterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
}

export default AutomationRuleFilterForm
