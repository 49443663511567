import { sprintf } from 'sprintf-js'
import { usePickSearchParams } from 'storfox-route-hooks'
import { useCreate, useDelete, useDetail, useList, useUpdate } from 'storfox-api-hooks'

import { orderingToSnackCase } from '~/utils'
import * as API from '~/constants/api'

export const useContainerTypeList = () => {
  const searchParams = usePickSearchParams()
  return useList(API.CONTAINER_TYPE_LIST, orderingToSnackCase(searchParams))
}

export const useContainerTypeDetail = (id) => {
  const url = sprintf(API.CONTAINER_TYPE_DETAIL, id)
  return useDetail(url)
}

export const useContainerTypeCreate = () => {
  return useCreate(API.CONTAINER_TYPE_CREATE)
}

export const useContainerTypeUpdate = (id) => {
  const url = sprintf(API.CONTAINER_TYPE_UPDATE, id)
  return useUpdate(url)
}

export const useContainerTypeRemove = id => {
  const url = sprintf(API.CONTAINER_TYPE_DELETE, id)
  return useDelete(url)
}

export const useContainerTypeBulkRemove = () => {
  return useDelete(API.CONTAINER_TYPE_BULK_DELETE)
}
