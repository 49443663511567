import React from 'react'
import isEqual from 'react-fast-compare'
import PropTypes from 'prop-types'
import Switch from '@mui/material/Switch'
import { Field } from 'react-final-form'

function SwitchField ({ name, label, ...props }) {
  return (
    <Field name={name}>
      {({ input, meta }) => (
        <>
          <Switch
            id={name}
            checked={input.value || false}
            onChange={event => input.onChange(event.target.checked)}
            {...props}
          />
          {label && <label htmlFor={name}>{label}</label>}
        </>
      )}
    </Field>
  )
}

SwitchField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string
  ])
}

SwitchField.defaultProps = {
  color: 'primary',
  size: 'small',
}

export default React.memo(SwitchField, isEqual)
