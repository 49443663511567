import React from 'react'
import { path, prop, propOr } from 'ramda'
import { useTranslation } from 'react-i18next'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'

import CardHeader from './CardHeader'

function CustomerAddressCard ({ shippingAddress }) {
  const { t } = useTranslation()
  const firstName = propOr('', 'firstName', shippingAddress)
  const lastName = propOr('', 'lastName', shippingAddress)
  const fullName = `${firstName} ${lastName}`
  const address = prop('address', shippingAddress)
  const city = prop('city', shippingAddress)
  const country = path(['country', 'name'], shippingAddress)
  const zipcode = prop('zipcode', shippingAddress)
  const phone = prop('mobile', shippingAddress)

  return (
    <Card>
      <CardHeader title={t('Address')} />
      <Divider />
      <CardContent>
        <Grid container={true} spacing={3}>
          <Grid item={true} sm={12}>
            <Typography>{fullName}</Typography>
            <Typography>{address}</Typography>
            <Typography>{city}</Typography>
            <Typography>{country}</Typography>
            <Typography>{zipcode}</Typography>
            <Typography>{phone}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

CustomerAddressCard.propTypes = {
  shippingAddress: PropTypes.object
}

export default CustomerAddressCard
