import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { styled } from '@mui/material'

const TextWrapperStyled = styled(Box)({
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
})

function CircularOrderProgress ({ value, color, ...props }) {
  return (
    <Box position="relative" display="inline-flex" sx={{ zIndex: 5 }}>
      <CircularProgress variant="determinate" value={value} sx={{ color }} {...props} />
      <TextWrapperStyled>
        <Typography variant="caption" component="div" color="textSecondary" sx={{ fontSize: '22px' }}>
          {`${Math.round(value)}%`}
        </Typography>
      </TextWrapperStyled>
    </Box>
  )
}

CircularOrderProgress.propTypes = {
  value: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired
}

export default CircularOrderProgress
