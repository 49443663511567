import React from 'react'
import { Field, useField } from 'react-final-form'
import { useDeepCompareEffect } from 'storfox-api-hooks'
import PropTypes from 'prop-types'
import MUITextField from '@mui/material/TextField'
import { NumberFormat } from 'storfox-form-fields'

const InputComponent = React.forwardRef((props, ref) => (
  <NumberFormat {...props} ref={ref} allowNegative={false} />
))

function ConstrainedFloatField (props) {
  const {
    name,
    allowEmpty,
    minValue = 0,
    maxValue = 100,
    size = 'small',
    fullWidth = true,
    decimalScale = 3,
    InputProps = {},
    ...rest
  } = props

  const { input } = useField(name)
  const value = input.value

  useDeepCompareEffect(() => {
    const val = Number(value)

    if (!val) {
      input.onChange(minValue)
    }

    if (val < minValue) {
      input.onChange(minValue)
    }

    if (val > maxValue) {
      input.onChange(maxValue)
    }
  }, [value])

  return (
    <Field name={name}>
      {({ input, meta }) => (
        <MUITextField
          onChange={event => input.onChange(event.target.value)}
          value={input.value}
          error={meta.invalid}
          helperText={meta.submitError || meta.error}
          fullWidth={fullWidth}
          size={size}
          InputProps={{
            inputComponent: InputComponent,
            ...InputProps
          }}
          inputProps={{
            decimalScale
          }}
          {...rest}
        />
      )}
    </Field>
  )
}

ConstrainedFloatField.propTypes = {
  name: PropTypes.string.isRequired,
  maxValue: PropTypes.number,
  allowEmpty: PropTypes.bool,
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
  minValue: PropTypes.number,
  decimalScale: PropTypes.number,
  InputProps: PropTypes.object,
}

export default ConstrainedFloatField
