import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import { styled } from '@mui/material'

const RootStyled = styled(Box)({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'

})

const BackdropStyled = styled(Box)({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  width: '100%',
  height: '100%',
  opacity: 0.5,
  background: 'white'
})

function UnitLoader () {
  return (
    <RootStyled>
      <BackdropStyled />
      <CircularProgress />
    </RootStyled>
  )
}

export default UnitLoader
