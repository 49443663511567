import { sprintf } from 'sprintf-js'
import { usePickSearchParams } from 'storfox-route-hooks'
import { useCreate, useDelete, useDetail, useList, useUpdate } from 'storfox-api-hooks'

import { orderingToSnackCase } from '~/utils'
import * as API from '~/constants/api'

export const useZoneList = () => {
  const searchParams = usePickSearchParams()
  return useList(API.ZONE_LIST, orderingToSnackCase(searchParams))
}

export const useZoneDetail = guid => {
  const url = sprintf(API.ZONE_DETAIL, guid)
  return useDetail(url)
}

export const useZoneCreate = () => {
  return useCreate(API.ZONE_CREATE)
}

export const useZoneUpdate = guid => {
  const url = sprintf(API.ZONE_UPDATE, guid)
  return useUpdate(url)
}

export const useZoneRemove = guid => {
  const url = sprintf(API.ZONE_DELETE, guid)
  return useDelete(url)
}

export const useWarehouseDetail = guid => {
  const url = sprintf(API.WAREHOUSE_DETAIL, guid)
  return useDetail(url)
}
