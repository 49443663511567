import React from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import { path, pathOr, prop } from 'ramda'
import PropTypes from 'prop-types'

import { CardHeader } from '~/components/Cards'
import { useCompany } from '~/components/Profile'
import Hide from '~/components/Hide'
import { RETAILER } from '~/constants/companyTypes'
import TextLabelLoader from '~/components/TextLabelLoader'
import NotesCard from '~/components/NotesCard'

import AdjustmentLineItemsTable from './AdjustmentLineItemsTable'

function Adjustment ({ detail, adjustmentLineItems }) {
  const { company } = useCompany()
  const companyType = prop('type', company)
  const isRetailer = companyType === RETAILER
  const warehouse = path(['warehouse', 'name'], detail)
  const referenceId = prop('referenceId', detail)
  const reason = path(['reason', 'name'], detail)
  const owner = path(['owner', 'name'], detail)
  const userFirstName = pathOr('', ['user', 'firstName'], detail)
  const userLastName = pathOr('', ['user', 'lastName'], detail)
  const userFullName = userFirstName + ' ' + userLastName
  const notes = prop('notes', detail)

  const rows = prop('results', adjustmentLineItems)
  const isLoading = prop('isLoading', adjustmentLineItems)
  const hasNextPage = prop('hasNextPage', adjustmentLineItems)
  const hasPrevPage = prop('hasPrevPage', adjustmentLineItems)

  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12} lg={12}>
        <Card>
          <CardHeader title="Details" />
          <Divider />

          <CardContent>
            <Grid container={true} spacing={3}>
              <Grid item={true} xs={12} lg={2}>
                <TextLabelLoader label="Warehouse">
                  {warehouse}
                </TextLabelLoader>
              </Grid>

              <Grid item={true} xs={12} lg={2}>
                <TextLabelLoader label="Reason">
                  {reason}
                </TextLabelLoader>
              </Grid>

              <Grid item={true} xs={12} lg={2}>
                <TextLabelLoader label="Reference id">
                  {referenceId}
                </TextLabelLoader>
              </Grid>
              <Grid item={true} xs={12} lg={2}>
                <TextLabelLoader label="User">
                  {userFullName}
                </TextLabelLoader>
              </Grid>
              <Grid item={true} xs={12} lg={2}>
                <Hide hidden={isRetailer}>
                  <TextLabelLoader label="Owner">
                    {owner}
                  </TextLabelLoader>
                </Hide>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item={true} xs={12} lg={12}>
        <Card>
          <CardHeader title="Stock" />

          <Divider />

          <CardContent style={{ padding: 0 }}>
            <AdjustmentLineItemsTable
              rows={rows}
              hasPrevPage={hasPrevPage}
              hasNextPage={hasNextPage}
              isLoading={isLoading}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item={true} xs={12} lg={6}>
        <NotesCard notes={notes} />
      </Grid>
    </Grid>
  )
}

Adjustment.propTypes = {
  detail: PropTypes.object.isRequired,
  adjustmentLineItems: PropTypes.object.isRequired
}

export default Adjustment
