import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import { FloatField } from 'storfox-form-fields'

import TextField from '~/components/Fields/TextField'
import CountryField from '~/components/Fields/CountryField'
import PhoneNumberField from '~/components/Fields/PhoneNumberField'
import EmailField from '~/components/Fields/EmailField'

function DeliveryAddressInfo ({ name }) {
  return (
    <Grid container={true} spacing={2}>
      <Grid item={true} xs={12}>
        <EmailField
          data-cy="email"
          name={`${name}.email`}
          label="Email"
        />
      </Grid>
      <Grid item={true} xs={12}>
        <TextField
          label="Address"
          name={`${name}.address`}
          multiline={true}
          minRows={2}
        />
      </Grid>

      <Grid item={true} xs={12}>
        <TextField
          label="Region/Neighbouring/State"
          name={`${name}.region`}
        />
      </Grid>

      <Grid item={true} xs={12}>
        <TextField
          label="City"
          name={`${name}.city`}
        />
      </Grid>

      <Grid item={true} xs={12}>
        <TextField
          label="Zip code"
          name={`${name}.zipcode`}
        />
      </Grid>

      <Grid item={true} xs={12}>
        <CountryField
          label="Country"
          name={`${name}.country`}
        />
      </Grid>

      <Grid item={true} xs={12}>
        <PhoneNumberField
          data-cy="mobile"
          name={`${name}.mobile`}
          label="Phone number"
        />
      </Grid>

      <Grid item={true} xs={6}>
        <FloatField
          label="Latitude"
          name={`${name}.latitude`}
          inputProps={{ decimalScale: 30 }}
        />
      </Grid>

      <Grid item={true} xs={6}>
        <FloatField
          label="Longitude"
          name={`${name}.longitude`}
          inputProps={{ decimalScale: 30 }}
        />
      </Grid>

    </Grid>
  )
}

DeliveryAddressInfo.propTypes = {
  name: PropTypes.string.isRequired
}

export default DeliveryAddressInfo
