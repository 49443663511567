import React from 'react'
import { useSnackbar } from 'storfox-snackbar'
import { generatePath, useNavigate } from 'react-router-dom'
import { prop, propOr } from 'ramda'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import useMessages from '~/hooks/useMessages'
import { MOVEMENT_DETAIL_TABS } from '~/constants/tabs'

import { MovementBrowseCreateForm } from '../components/MovementBrowseCreate'
import { useMovementCreate, useMovementCreateAndComplete, useMovementUnitList } from '../hooks'
import { MovementCreateSerializer } from '../serializers'
import { SAVE_COMPLETE } from '../constants'

function MovementBrowseCreateContainer () {
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const messages = useMessages()

  const movementUnitList = useMovementUnitList()
  const movementCreate = useMovementCreate()
  const movementCreateAndComplete = useMovementCreateAndComplete()

  const handleSubmit = formValues => {
    const submitType = propOr(SAVE_COMPLETE, 'submitType', formValues)
    if (submitType === SAVE_COMPLETE) {
      return movementCreateAndComplete.create(MovementCreateSerializer(formValues, 'browse'))
        .then(({ result }) => {
          const id = prop('id', result)
          const path = generatePath(ROUTES.MOVEMENT_DETAIL_PATH, { id, tab: MOVEMENT_DETAIL_TABS.GENERAL })

          navigate(path)
        })
        .then(() => snackbar({ message: messages.CREATE_SUCCESS }))
    } else {
      return movementCreate.create(MovementCreateSerializer(formValues, 'browse'))
        .then(({ result }) => {
          const id = prop('id', result)
          const path = generatePath(ROUTES.MOVEMENT_DETAIL_PATH, { id, tab: MOVEMENT_DETAIL_TABS.GENERAL })

          navigate(path)
        })
        .then(() => snackbar({ message: messages.CREATE_SUCCESS }))
    }
  }

  const isLoading = (
    movementCreate.isLoading ||
    movementCreateAndComplete.isLoading
  )

  const breadcrumbs = { title: 'New movement' }

  return (
    <DashboardLayout
      title="New movement"
      activeNav={NAV.MOVEMENT}
      breadcrumbs={breadcrumbs}
    >
      <MovementBrowseCreateForm
        onSubmit={handleSubmit}
        isLoading={isLoading}
        initialValues={{}}
        movementUnitList={movementUnitList}
      />
    </DashboardLayout>
  )
}

export default MovementBrowseCreateContainer
