import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { equals, filter, isEmpty, map, prop, propEq, propOr } from 'ramda'
import Box from '@mui/material/Box'

import { emptyArray } from '~/constants/empty'
import LoaderProvider from '~/components/Loader'
import Container, { Content, Header } from '~/components/Container'
import * as NAV from '~/constants/nav-titles'
import PageTitle from '~/components/PageTitle'

import StorageReportTableSkeleton from './StorageReportTableSkeleton'
import CompanyAccordionTable from './CompanyAccordionTable'

import { TYPES } from '../../../constants/StorageReport'

const getHierarchy = (childName, parents, children) =>
  map(parent => {
    const guid = prop('guid', parent)
    const directChildren = filter(propEq('parentGuid', guid), children)

    return { ...parent, [childName]: directChildren }
  }, parents)

const getCompanyHierarchy = ({ companies, warehouses, locations, units }) => {
  const locationHierarchy = getHierarchy('units', locations, units)
  const warehouseHierarchy = getHierarchy('locations', warehouses, locationHierarchy)

  return getHierarchy('warehouses', companies, warehouseHierarchy)
}

function StorageReportDetail ({ detail, isLoading, onDetailLoad }) {
  const [companies, setCompanies] = useState(emptyArray)
  const [warehouses, setWarehouses] = useState(emptyArray)
  const [locations, setLocations] = useState(emptyArray)
  const [units, setUnits] = useState(emptyArray)

  const companyHierarchy = getCompanyHierarchy({
    companies,
    warehouses,
    locations,
    units
  })

  useEffect(() => {
    if (!companies.length && !isEmpty(detail)) {
      const initialCompanies = propOr([], 'companies', detail)
      setCompanies(initialCompanies)
    }
  }, [companies, detail])

  const handleDetailLoad = params =>
    onDetailLoad(params)
      .then(({ result }) => {
        const type = prop('type', result)
        const companyGuid = prop('clientGuid', params)
        const warehouseGuid = prop('warehouseGuid', params)
        const locationGuid = prop('locationGuid', params)

        if (equals(type, TYPES.WAREHOUSE)) {
          const warehouses = prop('warehouses', result)
          const newWarehouses = map(warehouse => ({ ...warehouse, parentGuid: companyGuid }), warehouses)
          setWarehouses(prevWarehouses => [...prevWarehouses, ...newWarehouses])
        }

        if (equals(type, TYPES.LOCATION)) {
          const locations = prop('locations', result)
          const newLocations = map(location => ({ ...location, parentGuid: warehouseGuid }), locations)
          setLocations(prevLocations => ([...prevLocations, ...newLocations]))
        }

        if (equals(type, TYPES.UNIT)) {
          const units = prop('units', result)
          const newUnits = map(unit => ({ ...unit, parentGuid: locationGuid }), units)
          setUnits(prevUnits => ([...prevUnits, ...newUnits]))
        }
      })

  return (
    <LoaderProvider isLoading={isLoading}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={NAV.STORAGE_REPORT}
            parentTitle={NAV.REPORTS}
          />
        </Header>
        <Content>
          {!companyHierarchy.length && isLoading && (
            <StorageReportTableSkeleton />
          )}
          {companyHierarchy.map(company => (
            <Box key={prop('guid', company)} mb={2}>
              <CompanyAccordionTable
                company={company}
                onDetailLoad={handleDetailLoad}
              />
            </Box>
          ))}
        </Content>
      </Container>
    </LoaderProvider>
  )
}

StorageReportDetail.propTypes = {
  detail: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onDetailLoad: PropTypes.func.isRequired
}

export default StorageReportDetail
