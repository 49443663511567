import React from 'react'

import SelectField from './SelectField'

export const BOX = 'box'
export const METER = 'meter'
export const PIECE = 'piece'
export const PACK = 'pack'
export const BOTTLE = 'bottle'
export const CAN = 'can'
export const LITRE = 'litre'
export const UNIT = 'unit'
export const IBCS = 'ibc'
export const DRUM = 'drum'
export const BAGS = 'bag'

export const measurementOptions = [
  { value: BOX, name: 'Box' },
  { value: BOTTLE, name: 'Bottle' },
  { value: CAN, name: 'Can' },
  { value: LITRE, name: 'Litre' },
  { value: METER, name: 'Meter' },
  { value: PIECE, name: 'Piece' },
  { value: PACK, name: 'Pack' },
  { value: UNIT, name: 'Unit' },
  { value: IBCS, name: 'IBCs' },
  { value: DRUM, name: 'Drum' },
  { value: BAGS, name: 'Bags' }
]

function MethodOfMeasurementField (props) {
  return (
    <SelectField
      label="UoM"
      name="unitOfMeasurement"
      data-cy="unitOfMeasurement"
      options={measurementOptions}
      {...props}
    />
  )
}

export default MethodOfMeasurementField
