import { join, map, pipe, prop, propOr } from 'ramda'

import { escapeAtob } from '~/utils'
import { BARCODE_TYPE, CODE_TYPE, FONT_SIZES } from '~/constants/barcode'

const getTitles = pipe(
  propOr([], 'titles'),
  join(',')
)

const getBarcodes = pipe(
  escapeAtob,
  map(barcodeItem => {
    const barcode = prop('barcode', barcodeItem)
    const titles = getTitles(barcodeItem)
    const image = prop('image', barcodeItem)

    if (titles && image) {
      return `${barcode}|${titles}|${image}`
    }
    if (image) {
      return `${barcode}||${image}`
    }
    if (titles) {
      return `${barcode}|${titles}`
    }

    return barcode
  }),
  join('\n')
)

export const initialValuesSerializer = (barcodes, type) => {
  const barcodeType = type === CODE_TYPE.QRCODE ? CODE_TYPE.QRCODE : CODE_TYPE.BARCODE

  const initialValues = {
    quantity: 1,
    type: barcodeType,
    barcodeType: BARCODE_TYPE.CODE128,
    fontSize: FONT_SIZES.MEDIUM
  }

  if (barcodes) {
    return {
      ...initialValues,
      barcodes: getBarcodes(barcodes)
    }
  }

  return initialValues
}
