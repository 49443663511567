import PropTypes from 'prop-types'
import React from 'react'
import AlertTitle from '@mui/material/AlertTitle'
import { useTranslation } from 'react-i18next'
import { Alert } from '@mui/lab'
import Typography from '@mui/material/Typography'

const STATUSES = {
  new: 'New',
  pending: 'Pending'
}

function ReadonlyInstruction ({ status }) {
  const { t } = useTranslation()

  return (
    <Alert severity="info">
      <AlertTitle>{t('Not in progress.')}</AlertTitle>
      <Typography>
        This picklist has status <strong>"{STATUSES[status]}"</strong>.
        To start picking, it should have status <strong>"In progress"</strong>
      </Typography>
    </Alert>
  )
}

ReadonlyInstruction.propTypes = {
  status: PropTypes.string.isRequired
}

export default ReadonlyInstruction
