import React from 'react'

import SelectField from '~/components/Fields/SelectField'

const OPTIONS = [
  {
    value: 'EZ',
    name: 'EZ: standard (UAE)'
  },
  {
    value: 'ReverseUAE',
    name: 'Reverse (UAE)'
  },
  {
    value: 'EZKSA',
    name: 'EZKSA: standard (KSA)'
  },
  {
    value: 'Reverse',
    name: 'Reverse (KSA)'
  }
]

function ExpressTypeField (props) {
  return (
    <SelectField
      name="configuration.expressType"
      label="Express Type"
      options={OPTIONS}
      {...props}
    />
  )
}

export default ExpressTypeField
