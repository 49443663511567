import React from 'react'
import PropTypes from 'prop-types'
import { path, prop } from 'ramda'
import { createOriginImageUrl } from 'storfox-image-upload'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'

import { useCompany } from '~/components/Profile'
import Image from '~/components/Image/Image'
import { useWhiteLabel } from '~/components/WhiteLabel/WhiteLabel'

import StorfoxLogo from './logo.svg'

const StorfoxLogoStyled = styled(StorfoxLogo)(({ theme }) => ({
  width: 125,
  height: 30,
  fontSize: 32,
  '& .st8': {
    fill: theme.typography.subtitle1.color
  }
}))

function Logo ({ className }) {
  const { company } = useCompany()
  const { domainValues } = useWhiteLabel()
  const brandingLogo = path(['branding', 'logo'], company)
  const domainLogo = prop('logo', domainValues)
  const logo = brandingLogo || domainLogo

  return (
    <>
      {logo
        ? (
          <Box width="125px" height="30px">
            <Image
              src={createOriginImageUrl(logo)}
              objectFit="cover"
              alt="logo"
            />
          </Box>
        )
        : <StorfoxLogoStyled className={className} />
      }
    </>
  )
}

Logo.propTypes = {
  className: PropTypes.string
}

export default Logo
