import React from 'react'
import { useOrdering } from 'storfox-filter'

import * as NAV from '~/constants/nav-titles'
import { DashboardLayout } from '~/components/Layouts'

import { useCurrencyRateList } from '../../hooks/CurrencyRate'
import {
  CurrencyRateOrderingForm,
  currencyRateOrderingOptions,
  CurrencyRateTable
} from '../../components/CurrencyRate/CurrencyRateList'

function CurrencyRateListContainer () {
  const currencyRateList = useCurrencyRateList()

  const ordering = useOrdering(currencyRateOrderingOptions)

  const breadcrumbs = { title: 'Currency rate' }

  return (
    <DashboardLayout
      activeNav={NAV.CONFIGURATION}
      title="Currency rate"
      breadcrumbs={breadcrumbs}
    >
      <CurrencyRateOrderingForm {...ordering} />

      <CurrencyRateTable
        list={currencyRateList}
        ordering={ordering}
        onListRefetch={currencyRateList.getListByParams}
      />
    </DashboardLayout>
  )
}

export default CurrencyRateListContainer
