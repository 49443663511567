import React from 'react'
import DialogContent from '@mui/material/DialogContent'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import DialogTitle from '~/components/DialogTitle'
import { Button, DiscardButton } from '~/components/Buttons'
import { withForm } from '~/components/Form'

import ShipmentStatusField from '../ShipmentUpdate/ShipmentStatusField'

function ShipmentSetStatusDialog ({ onClose, form, open, isLoading, ...props }) {
  const { handleSubmit } = form
  return (
    <Dialog
      maxWidth="sm"
      onClose={onClose}
      open={open}
      fullWidth={true}
      {...props}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          <Typography component="h3" variant="h6">Set shipment status manually</Typography>
        </DialogTitle>
        <DialogContent dividers={true} style={{ minHeight: '30vh' }}>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12}>
              <ShipmentStatusField />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DiscardButton
            fullWidth={true}
            onClick={onClose}
          >
            Discard
          </DiscardButton>
          <Button
            variant="contained"
            type="submit"
            fullWidth={true}
            data-cy="confirm"
          >
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

ShipmentSetStatusDialog.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
}

export default withForm(ShipmentSetStatusDialog)
