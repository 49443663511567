import React from 'react'
import { Ordering } from 'storfox-filter'

import { FILTER_NAME as name } from '../../constants'
import { PRODUCT_COLUMNS as tableColumns } from '../ProductList/ProductTable'

export const productOrderingOptions = {
  name,
  tableColumns
}

function ProductOrderingForm (props) {
  return (
    <Ordering {...props} />
  )
}

export default ProductOrderingForm
