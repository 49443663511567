import React from 'react'
import PropTypes from 'prop-types'

import TableSplitButton from '~/components/TableSplitButton'

const BARCODE_2UP_TYPE = '2up_barcode'
const BARCODE_3UP_TYPE = '3up_barcode'

function BulkActionButton ({ printBarcode, onPrintBarcode }) {
  const options = [
    {
      label: 'Print barcodes',
      'data-cy': 'printBarcodesMenu',
      onMenuClick: printBarcode
    },
    {
      label: 'Print barcodes (2UP)',
      'data-cy': 'printBarcodesMenu2up',
      onMenuClick: () => onPrintBarcode(BARCODE_2UP_TYPE)
    },
    {
      label: 'Print barcodes (3UP)',
      'data-cy': 'printBarcodesMenu',
      onMenuClick: () => onPrintBarcode(BARCODE_3UP_TYPE)
    }
  ]

  return (
    <TableSplitButton
      options={options}
      name="unit"
    />
  )
}

BulkActionButton.propTypes = {
  printBarcode: PropTypes.func.isRequired,
  onPrintBarcode: PropTypes.func.isRequired
}

export default BulkActionButton
