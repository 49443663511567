import React from 'react'
import { TagsField as StorfoxTagsField } from 'storfox-form-fields'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

function TagsField ({ label, placeholder, ...props }) {
  const { t } = useTranslation()

  return (
    <StorfoxTagsField
      label={t(label)}
      placeholder={t(placeholder)}
      {...props}
    />
  )
}
TagsField.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
}
export default TagsField
