import React from 'react'
import PropTypes from 'prop-types'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'

import { CardHeader } from '~/components/Cards'
import AreaField from '~/components/Fields/AreaField'
import StatusField from '~/components/Fields/StatusField'
import TextField from '~/components/Fields/TextField'
import WarehouseSearchField from '~/components/Fields/WarehouseSearchField'
import { DiscardButton, SaveButton } from '~/components/Buttons'
import { withForm } from '~/components/Form'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import * as NAV from '~/constants/nav-titles'

export const ZONE_CREATE_FIELDS = [
  'warehouse',
  'area',
  'label',
  'name',
  'status'
]

function ZoneCreateForm (props) {
  const {
    pageTitle,
    pageTitleLoading,
    isLoading,
    form,
  } = props

  return (
    <form onSubmit={form.handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={pageTitle}
            pageTitleLoading={pageTitleLoading}
            parentTitle={NAV.ZONES}
            rightButton={(
              <>
                <DiscardButton disabled={isLoading} />
                <SaveButton disabled={isLoading} />
              </>
            )}
          />
        </Header>

        <Content>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12} lg={9}>
              <Box mb={3}>
                <Card>
                  <CardHeader title="Details" />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12} lg={6}>
                        <WarehouseSearchField disabled={true} />
                      </Grid>

                      <Grid item={true} xs={12} lg={6}>
                        <AreaField
                          data-cy="area"
                          name="area"
                          label="Area"
                          warehouse="warehouse"
                          required={true}
                        />
                      </Grid>

                      <Grid item={true} xs={12} lg={6}>
                        <TextField
                          data-cy="label"
                          name="label"
                          label="Label"
                          inputProps={{ maxLength: 3 }}
                          required={true}
                        />
                      </Grid>

                      <Grid item={true} xs={12} lg={6}>
                        <TextField
                          data-cy="name"
                          name="name"
                          label="Name"
                          required={true}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Grid>

            <Grid item={true} xs={12} lg={3}>
              <Box mb={3}>
                <Card>
                  <CardHeader title="Settings" />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12}>
                        <StatusField />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Content>
      </Container>
    </form>
  )
}

ZoneCreateForm.propTypes = {
  pageTitle: PropTypes.string,
  pageTitleLoading: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default withForm(ZoneCreateForm)
