import React from 'react'
import { path, prop, propOr } from 'ramda'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import Money from '~/components/Money'
import TextOverflow from '~/components/TextOverflow'
import VerticalAlignment from '~/components/VerticalAlignment'
import Subtext from '~/components/Subtext'
import Avatar from '~/components/Avatar/Avatar'
import SkeletonList from '~/components/Skeleton'
import EmptyLineItems from '~/components/EmptyLineItems/EmptyLineItems'
import Percentage from '~/components/Percentage'

function LineItems ({ isLoading, lineItems }) {
  const { t } = useTranslation()

  const renderEmptyLineItems = Boolean(!isLoading && !lineItems.length)
  const renderLineItems = Boolean(!isLoading && lineItems.length)

  return (
    <TableContainer>
      <Table sx={{ minWidth: '900px' }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ minWidth: '80px' }}>{t('Image')}</TableCell>
            <TableCell sx={{ minWidth: '250px' }}>{t('Product')}</TableCell>
            <TableCell sx={{ minWidth: '200px' }}>{t(`Supplier SKU`)}</TableCell>
            <TableCell>{t('Order Qty')}</TableCell>
            <TableCell>{t('Discount')}</TableCell>
            <TableCell>{t('Taxes')}</TableCell>
            <TableCell>{t('Receive Qty')}</TableCell>
            <TableCell>{t(`Unit cost`)}</TableCell>
            <TableCell>{t('Total')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading && <SkeletonList columnCount={8} />}
          {renderEmptyLineItems && <EmptyLineItems />}
          {renderLineItems && lineItems.map((item, index) => {
            const variantName = path(['productVariant', 'name'], item)
            const sku = path(['productVariant', 'sku'], item)
            const imagePath = path(['productVariant', 'defaultImage'], item)
            const quantity = propOr(0, 'quantity', item)
            const received = propOr(0, 'received', item)
            const supplierSku = prop('supplierSku', item)
            const tax = propOr(0, 'tax', item)
            const discount = propOr(0, 'discount', item)
            const price = prop('price', item)
            const totalPrice = prop('totalPrice', item)
            const lastRow = index === lineItems.length - 1

            return (
              <TableRow
                key={index}
                sx={[
                  lastRow && {
                    '& td': {
                      borderBottom: 'none'
                    }
                  }
                ]}
              >
                <TableCell>
                  <Avatar src={imagePath} alt={variantName} />
                </TableCell>

                <TableCell>
                  <VerticalAlignment
                    primary={(
                      <TextOverflow selfTooltip={true}>
                        {variantName}
                      </TextOverflow>
                    )}
                    secondary={(
                      <Subtext lines={1}>{sku}</Subtext>
                    )}
                  />
                </TableCell>
                <TableCell>{supplierSku}</TableCell>
                <TableCell>{quantity}</TableCell>
                <TableCell>
                  <Percentage value={discount} />
                </TableCell>
                <TableCell>
                  <Percentage value={tax} />
                </TableCell>
                <TableCell>{received}</TableCell>
                <TableCell>
                  <Money value={price} />
                </TableCell>
                <TableCell>
                  <Money value={totalPrice} />
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

LineItems.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  lineItems: PropTypes.array.isRequired
}

export default LineItems
