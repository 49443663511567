import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { clamp, find, length, map, path, pathEq, prop, propEq, propOr, reduce, replace } from 'ramda'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { useField } from 'react-final-form'
import Card from '@mui/material/Card'
import { useDeepCompareEffect } from 'storfox-api-hooks'
import { FieldArray } from 'react-final-form-arrays'
import { useAllSearchParams } from 'storfox-route-hooks'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'

import { matchContainer, matchContainerType } from '~/utils'
import { CardHeader } from '~/components/Cards'
import { DONE, IGNORE_SCAN, SKIP } from '~/constants/barcode'
import BarcodeField from '~/components/BarcodeField'
import BarcodeDoneInstruction from '~/components/Instructions/BarcodeDoneInstruction'
import BarcodeSkipInstruction from '~/components/Instructions/BarcodeSkipInstruction'
import ContainerTypeInstruction from '~/components/Instructions/ContainerTypeInstruction'
import { useScreenOutline } from '~/components/ScreenOutline'
import { emptyArray } from '~/constants/empty'
import TextField from '~/components/Fields/TextField'

import BarcodeTitle from './BarcodeTitle'
import PackingListItems from './PackingListItems'
import VariantPreview from './VariantPreview'
import ActiveContainerCard from './ActiveContainerCard'
import ContainerizeCard from './ContainerizeCard'

import ContainerItemsLoading from '../ContainerizeCard/ContainerItemsLoading'
import ContainerItems from '../ContainerizeCard/ContainerItems'
import {
  getContainerByNumber,
  getContainerProductsAfterRemove,
  getNewContainers,
  getNewExistingProductsForContainer,
  getNewProducts,
  getNewProductsForContainer,
  getNextProductWithSerial,
  getPartiallyScannedProductByUnitNumber,
  getProductMatchingActiveItem,
  getProductMatchingSerialActiveItem,
  getProductsWithActiveItem,
  getProductsWithResetQuantity,
  getSimilarUnscannedProduct,
  getUnscannedProductMatchedSerial
} from '../../utils'

export const getContainerTypeWithoutPrefix = replace(/^ctt-|^CTT-/, '')

const getScannedCount = reduce((acc, item) => {
  return acc + propOr(0, 'selectedQuantity', item)
}, 0)

function PackingGeneral (props) {
  const {
    onSave,
    onComplete,
    canComplete,
    packedCount,
    detailLoading,
    warehouseGuid,
    totalQuantity,
    containerLoading,
    onContainerCreate,
    initialLineItems,
    containerTypesPrintPath,
    containerTypesPathDisabled,
    onSkip
  } = props

  const [completed, setCompleted] = useState(false)
  const { initiallyScannedBarcode } = useAllSearchParams()

  const containerNumberRef = useRef(null)
  const barcodeRef = useRef(null)
  const serialNumberRef = useRef({})

  const barcodeField = useField('barcode')
  const barcode = barcodeField.input.value

  const handleBarcodeClear = () => barcodeField.input.onChange('')

  const activeLineItemField = useField('activeLineItem')
  const activeLineItem = activeLineItemField.input.value || null
  const activeItemTotalQuantity = prop('quantity', activeLineItem)

  const activeSerialNumberField = useField('activeLineItem.unit.serialNumber')
  const activeSerialNumber = activeSerialNumberField.input.value
  const handleActiveSerialNumberChange = activeSerialNumberField.input.onChange

  const productsField = useField('lineItems')
  const products = productsField.input.value || emptyArray

  const containerField = useField('containers')
  const containers = containerField.input.value || emptyArray

  const { handleSoundedTrigger, handleSoundedErrorTrigger } = useScreenOutline()

  const isScanSerial = Boolean(
    path(['unit', 'trackSerialNumbers'], activeLineItem) &&
    !path(['unit', 'serialNumber'], activeLineItem)
  )

  const scannedCount = getScannedCount(products) + propOr(0, 'selectedQuantity', activeLineItem)
  const totalScanCount = totalQuantity - packedCount
  const isFullyScanned = scannedCount === totalScanCount

  const lastContainerIndex = length(containers) - 1
  const activeContainerNumber = path([lastContainerIndex, 'number'], containers)

  useDeepCompareEffect(() => {
    if (initialLineItems.length && initiallyScannedBarcode) {
      scanProductFromList(initiallyScannedBarcode)
    }
  }, [initialLineItems])

  useDeepCompareEffect(() => {
    handleBarcodeFocus()
  }, [containerLoading])

  useDeepCompareEffect(() => {
    if (initialLineItems.length && canComplete && !completed) {
      setCompleted(true)
      onComplete()
    }
  }, [initialLineItems, containers, completed])

  useDeepCompareEffect(() => {
    if (activeLineItem) {
      const activeGuid = path(['unit', 'guid'], activeLineItem)
      const newProducts = map(product => ({
        ...product,
        isActiveLineItem: pathEq(['unit', 'guid'], activeGuid, product)
      }), products)

      productsField.input.onChange(newProducts)
    }
  }, [activeLineItem])

  useDeepCompareEffect(() => {
    if (matchContainer(containerNumberRef.current)) {
      handleProductsInContainerPut(containerNumberRef.current)
    }
  }, [activeLineItem])

  useDeepCompareEffect(() => {
    if (!barcode && serialNumberRef.current && serialNumberRef.current.focus) {
      handleSerialNumberFocus()
    }
  }, [barcode, activeLineItem])

  const handleActiveLineItemChange = product => {
    const unit = prop('unit', product)
    const activeProduct = { ...product, unit: { ...unit, serialNumber: '' } }

    handleSoundedTrigger()
    activeLineItemField.input.onChange(activeProduct)
  }

  const handleProductEdit = product => {
    const productsAfterJoiningActiveItem = getProductsAfterJoiningActiveItem()

    if (!productsAfterJoiningActiveItem) {
      return
    }

    const newProduct = { ...product, isScanned: false, selectedQuantity: 0 }
    const newProducts = getNewProducts(newProduct, productsAfterJoiningActiveItem)
    handleSoundedTrigger()

    productsField.input.onChange(newProducts)
    activeLineItemField.input.onChange(product)
  }

  const getProductsAfterJoiningActiveItem = () => {
    if (!activeLineItem) {
      return products
    }

    const barcode = path(['unit', 'unitNumber'], activeLineItem)
    const serialNumber = path(['unit', 'serialNumber'], activeLineItem)
    const trackSerial = path(['unit', 'trackSerialNumbers'], activeLineItem)

    if (trackSerial) {
      const foundProduct = getProductMatchingSerialActiveItem(barcode, serialNumber, products)
      const productUnitGuid = path(['unit', 'guid'], foundProduct)
      if (!productUnitGuid) {
        handleSoundedErrorTrigger()
        return null
      }

      return getProductsWithActiveItem(activeLineItem, productUnitGuid, products)
    }

    const foundProduct = getProductMatchingActiveItem(barcode, products)
    const productUnitGuid = path(['unit', 'guid'], foundProduct)
    if (!productUnitGuid) {
      handleSoundedErrorTrigger()
      return null
    }

    return getProductsWithActiveItem(activeLineItem, productUnitGuid, products)
  }

  const addActiveLineItemToProducts = products => {
    handleSoundedTrigger()
    productsField.input.onChange(products)
    activeLineItemField.input.onChange(null)
  }

  const handleProductsInContainerPut = barcode => {
    const container = getContainerByNumber(barcode, containers)
    const containerProducts = propOr([], 'lineItems', container)

    const newExistingContainerProducts = getNewExistingProductsForContainer(containerProducts, products)
    const newProductsForContainer = getNewProductsForContainer(containerProducts, products)

    const newContainerProducts = [...newExistingContainerProducts, ...newProductsForContainer]
    const newContainer = { ...container, lineItems: newContainerProducts }
    const newContainers = getNewContainers(newContainer, containers)
    const newProducts = getProductsWithResetQuantity(products)

    handleSoundedTrigger()

    productsField.input.onChange(newProducts)
    containerField.input.onChange(newContainers)
    containerNumberRef.current = null
  }

  const handleDone = () => {
    if (activeLineItem) {
      const productsAfterJoiningActiveItem = getProductsAfterJoiningActiveItem()
      if (!productsAfterJoiningActiveItem) {
        return
      }
      addActiveLineItemToProducts(productsAfterJoiningActiveItem)
    }

    if (!activeLineItem) {
      handleSoundedTrigger()
      return onSave()
    }
  }

  const handleProductWithSerialScan = barcode => {
    const unscannedProduct = getNextProductWithSerial(barcode, activeSerialNumber, products)
    if (!unscannedProduct) {
      handleSoundedErrorTrigger()
      return
    }

    const productsAfterJoiningActiveItem = getProductsAfterJoiningActiveItem()

    if (!productsAfterJoiningActiveItem) {
      return
    }

    addActiveLineItemToProducts(productsAfterJoiningActiveItem)
    const newActiveLineItem = { ...unscannedProduct, selectedQuantity: 1 }
    handleActiveLineItemChange(newActiveLineItem)
  }

  const handleProductScan = (barcode, product) => {
    const matchActiveLineItem = pathEq(['unit', 'unitNumber'], barcode, activeLineItem)
    const activeItemQuantity = prop('quantity', activeLineItem)

    const activeItemQuantityFull = (
      activeLineItem &&
      propEq('selectedQuantity', activeItemQuantity, activeLineItem)
    )

    const unitGuid = path(['unit', 'guid'], activeLineItem)
    const unscannedProduct = getSimilarUnscannedProduct(barcode, unitGuid, products)

    if (activeItemQuantityFull && !unscannedProduct) {
      handleSoundedErrorTrigger()
      return
    }

    if (activeItemQuantityFull && unscannedProduct) {
      const productsAfterJoiningActiveItem = getProductsAfterJoiningActiveItem()
      if (!productsAfterJoiningActiveItem) {
        return
      }

      addActiveLineItemToProducts(productsAfterJoiningActiveItem)
      const newActiveLineItem = { ...unscannedProduct, selectedQuantity: 1 }
      handleActiveLineItemChange(newActiveLineItem)
      return
    }

    const productWithExactUnit = find(pathEq(['unit', 'guid'], unitGuid), products)

    if (matchActiveLineItem && !activeItemQuantityFull && productWithExactUnit) {
      const selectedQuantity = propOr(0, 'selectedQuantity', productWithExactUnit)
      const activeSelectedQuantity = propOr(0, 'selectedQuantity', activeLineItem)

      const totalQuantity = prop('quantity', productWithExactUnit) - selectedQuantity
      const newSelectedQuantity = clamp(0, totalQuantity, activeSelectedQuantity + 1)

      const newActiveLineItem = { ...productWithExactUnit, selectedQuantity: newSelectedQuantity }
      handleActiveLineItemChange(newActiveLineItem)
      handleSoundedTrigger()
      return
    }

    const productsAfterJoiningActiveItem = getProductsAfterJoiningActiveItem()

    if (!productsAfterJoiningActiveItem) {
      return
    }

    addActiveLineItemToProducts(productsAfterJoiningActiveItem)
    handleActiveLineItemChange({ ...product, selectedQuantity: 1 })
  }

  const handleBarcodingContainerCreate = containerTypeGuid =>
    onContainerCreate(getContainerTypeWithoutPrefix(containerTypeGuid))
      .then(({ result }) => {
        handleSoundedTrigger()
        const newContainers = [...containers, result]
        containerField.input.onChange(newContainers)
      })
      .catch((err) => {
        handleSoundedErrorTrigger()
        throw err
      })
      .finally(() => {
        handleBarcodeFocus()
      })

  const handleScanSerial = serialNumber => {
    const activeUnitNumber = path(['unit', 'unitNumber'], activeLineItem)
    const matchProduct = getUnscannedProductMatchedSerial(activeUnitNumber, serialNumber, products)

    if (!matchProduct) {
      handleSoundedErrorTrigger()
      return
    }

    handleSoundedTrigger()
    handleActiveSerialNumberChange(serialNumber)
  }

  const handleItemFromContainerRemove = (containerNumber, containerProduct) => {
    const unit = prop('unit', containerProduct)
    const unitGuid = prop('guid', unit)
    const product = find(pathEq(['unit', 'guid'], unitGuid), products)
    const containerProductQuantity = prop('quantity', containerProduct)

    if (product) {
      const productTotalQuantity = prop('quantity', product)
      const totalQuantity = containerProductQuantity + productTotalQuantity

      const newProduct = {
        ...containerProduct,
        selectedQuantity: 0,
        quantity: totalQuantity,
        isPacked: false
      }

      const newProducts = getNewProducts(newProduct, products)

      productsField.input.onChange(newProducts)
    }

    if (!product) {
      const newProduct = {
        ...containerProduct,
        selectedQuantity: 0,
        quantity: containerProductQuantity,
        isPacked: false
      }

      const newProducts = [...products, newProduct]

      productsField.input.onChange(newProducts)
    }

    const container = getContainerByNumber(containerNumber, containers)

    const newContainerProducts = getContainerProductsAfterRemove(unitGuid, container)

    const newContainer = { ...container, lineItems: newContainerProducts }
    const newContainers = getNewContainers(newContainer, containers)

    containerField.input.onChange(newContainers)
  }

  const handleBarcodeFocus = () => {
    barcodeRef.current.focus()
  }

  const handleSerialNumberFocus = () => {
    serialNumberRef.current.focus()
  }

  const handlePreviewDelete = () => {
    const newProducts = map(product => ({ ...product, isActiveLineItem: false }), products)
    productsField.input.onChange(newProducts)
    activeLineItemField.input.onChange(null)
  }

  const handleBarcodeScan = event => {
    event.preventDefault()
    const barcode = event.target.value
    const isContainer = matchContainer(barcode) && getContainerByNumber(barcode, containers)
    const isContainerType = matchContainerType(barcode)

    if (barcode === SKIP) {
      if (isFullyScanned) {
        return onSkip()
      } else {
        handleSoundedErrorTrigger()
        return handleBarcodeClear()
      }
    }

    if (barcode === IGNORE_SCAN) {
      return handleBarcodeClear()
    }

    if (barcode === DONE) {
      handleDone()
      return handleBarcodeClear()
    }

    if (isContainerType) {
      handleBarcodingContainerCreate(barcode)
      return handleBarcodeClear()
    }

    if (isContainer && activeLineItem) {
      containerNumberRef.current = barcode
      const productsAfterJoiningActiveItem = getProductsAfterJoiningActiveItem()

      if (!productsAfterJoiningActiveItem) {
        return handleBarcodeClear()
      }
      addActiveLineItemToProducts(productsAfterJoiningActiveItem)
      return handleBarcodeClear()
    }

    if (isContainer && !activeLineItem) {
      handleProductsInContainerPut(barcode)
      return handleBarcodeClear()
    }

    if (isScanSerial) {
      handleScanSerial(barcode)
      return handleBarcodeClear()
    }

    const product = scanProductFromList(barcode)

    if (product) {
      return
    }

    handleBarcodeClear()
    handleSoundedErrorTrigger()
  }

  const scanProductFromList = barcode => {
    const product = getPartiallyScannedProductByUnitNumber(barcode, products)
    const trackSerial = path(['unit', 'trackSerialNumbers'], product)

    if (product && trackSerial) {
      handleProductWithSerialScan(barcode)
      handleBarcodeClear()
    }

    if (product) {
      handleProductScan(barcode, product)
      handleBarcodeClear()
    }

    if (!product) {
      handleBarcodeClear()
    }

    return product
  }

  return (
    <>
      <Box mt={3}>
        <Grid container={true} spacing={3} alignItems="flex-end">
          <Grid item={true} xs={12} lg={4}>
            <BarcodeTitle
              isScanSerial={isScanSerial}
              isReadyToComplete={totalScanCount === 0}
              isScanningFinished={scannedCount === totalScanCount}
            />
            <BarcodeField
              barcodeRef={barcodeRef}
              disabled={containerLoading}
              onEnter={handleBarcodeScan}
              focusBarcodeField={handleBarcodeFocus}
            />
          </Grid>
          <Grid item={true} xs={12} lg={8}>
            <Box lg={6} display="flex" justifyContent="flex-end">
              <BarcodeSkipInstruction skipText={SKIP}>
                Scan <strong style={{ color: 'black' }}>
                  "Skip"
                </strong> to skip packing container generation and scanning.
                <br />
                process and complete packing (places all units to new one auto generated container)
              </BarcodeSkipInstruction>
              <BarcodeDoneInstruction doneText={DONE}>
                Scan <strong style={{ color: 'black' }}>"Done"</strong> to add active item to scanned products.<br />
                Scan <strong style={{ color: 'black' }}>"Done"</strong> again to save packing.
              </BarcodeDoneInstruction>
              <ContainerTypeInstruction
                containerTypePrintLink={containerTypesPrintPath}
                disabled={containerTypesPathDisabled}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box mt={3} mb={2}>
        <Grid container={true} spacing={2}>
          <Grid item={true} lg={9} xs={12}>
            <Card sx={{ height: '100%' }}>
              <CardHeader title="Active line item" />
              <VariantPreview
                fieldName="activeLineItem"
                serialNumberRef={serialNumberRef}
                activeLineItem={activeLineItem}
                onBarcodeFocus={handleBarcodeFocus}
                onPreviewDelete={handlePreviewDelete}
                maxValue={activeItemTotalQuantity}
              />
            </Card>
          </Grid>
          <Grid item={true} lg={3} xs={12}>
            <ActiveContainerCard activeContainerNumber={activeContainerNumber} />
          </Grid>
        </Grid>
      </Box>
      <Grid container={true} spacing={2}>
        <Grid item={true} lg={4} xs={12}>
          <PackingListItems
            isLoading={detailLoading}
            scanInputRef={barcodeRef}
            scannedCount={scannedCount}
            totalScanCount={totalScanCount}
            onProductEdit={handleProductEdit}
          />
        </Grid>
        <Grid item={true} lg={8} xs={12}>
          <Box sx={{ display: 'grid', gridTemplateRows: '3fr 1fr' }}>
            <ContainerizeCard
              packedCount={packedCount}
              totalQuantity={totalQuantity}
              onBarcodeFocus={handleBarcodeFocus}
              onContainerCreate={onContainerCreate}
              warehouseGuid={warehouseGuid}
            >
              <Box mt={2}>
                <Grid container={true} spacing={3}>
                  <FieldArray name="containers">
                    {({ fields }) => (
                      <>
                        {!detailLoading
                          ? fields.map((name, index) => {
                            const container = fields.value[index]
                            const containerNumber = prop('number', container)
                            const containerType = path(['containerType', 'name'], container)
                            const products = propOr([], 'lineItems', container)

                            return (
                              <Grid key={index} item={true} xs={12}>
                                <ContainerItems
                                  currentNumber={index + 1}
                                  containerNumber={containerNumber}
                                  containerType={containerType}
                                  products={products}
                                  totalContainers={fields.value.length}
                                  onRemove={handleItemFromContainerRemove}
                                />
                              </Grid>
                            )
                          }).reverse()
                          : <ContainerItemsLoading numberOfItems={1} />}
                      </>
                    )}
                  </FieldArray>
                </Grid>
              </Box>
            </ContainerizeCard>

            <Box mt={2}>
              <Card>
                <CardHeader title="Notes" />
                <Divider />
                <CardContent>
                  <TextField
                    label="Notes"
                    name="notes"
                    multiline={true}
                    minRows={6}
                  />
                </CardContent>
              </Card>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

PackingGeneral.propTypes = {
  containerLoading: PropTypes.bool.isRequired,
  containerTypesPathDisabled: PropTypes.bool.isRequired,
  detailLoading: PropTypes.bool.isRequired,
  containerTypesPrintPath: PropTypes.string.isRequired,
  onContainerCreate: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  packedCount: PropTypes.number,
  totalQuantity: PropTypes.number,
  warehouseGuid: PropTypes.string,
  initialLineItems: PropTypes.array,
  onComplete: PropTypes.func.isRequired,
  canComplete: PropTypes.bool,
  onSkip: PropTypes.func.isRequired
}

export default PackingGeneral
