import React from 'react'
import isEqual from 'react-fast-compare'
import PropTypes from 'prop-types'
import MUITextField from '@mui/material/TextField'
import { useTranslation } from 'react-i18next'

import FieldWrapper from './FieldWrapper'

const getError = ({ submitError, error }) => (
  submitError ? submitError[0] : error
)

function TextField (props) {
  const {
    input,
    meta,
    size = 'small',
    fullWidth = true,
    error,
    required = false,
    label,
    ...rest
  } = props

  const { t } = useTranslation()
  const propsError = typeof error === 'string' ? error : ''
  const metaError = getError(meta) || propsError
  const labelWithReq = required ? label + ' *' : label

  return (
    <MUITextField
      onChange={event => input.onChange(event.target.value)}
      error={meta.invalid || Boolean(propsError)}
      value={input.value}
      helperText={t(metaError)}
      size={size}
      fullWidth={fullWidth}
      {...rest}
      label={labelWithReq}
    />
  )
}

TextField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  fullWidth: PropTypes.bool,
  variant: PropTypes.string,
  size: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string
}

export default FieldWrapper(React.memo(TextField, isEqual))
