import React from 'react'
import PropTypes from 'prop-types'
import isEqual from 'react-fast-compare'
import Dialog from '@mui/material/Dialog'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material'

function MobileFullWidthDialog (props) {
  const {
    open,
    children,
    onClose
  } = props

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Dialog
      open={open}
      fullWidth={true}
      fullScreen={fullScreen}
      maxWidth="xl"
      onClose={onClose}
      {...props}
    >
      {children}
    </Dialog>
  )
}
MobileFullWidthDialog.defaultProps = {
  onClose: () => {}
}
MobileFullWidthDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
  onClose: PropTypes.func

}
export default React.memo(MobileFullWidthDialog, isEqual)
