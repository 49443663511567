import { filter, not, path, pathEq, pipe, prop, propEq, propOr, replace } from 'ramda'

export const getConditionCodeWithoutPrefix = replace(/^cd-|^CD-/, '')

const getItemMatchingActiveItem = (activeItem, items) => {
  const barcode = path(['serial', 'unitNumber'], activeItem)
  const activeGuid = prop('guid', activeItem)

  const foundItemsByBarcode = filter(pathEq(['serial', 'unitNumber'], barcode), items)
  const foundItem = foundItemsByBarcode[0]
  const foundItemTrackSerial = path(['productVariant', 'trackSerial'], foundItem)
  const foundItemGuid = prop('guid', foundItem)
  const foundItemSerialNumber = path(['serial', 'serialNumber'], foundItem)

  if (foundItemsByBarcode.length === 1 && !foundItemSerialNumber && foundItemGuid === activeGuid) {
    return foundItem
  }

  if (foundItemsByBarcode.length > 1 && !foundItemTrackSerial) {
    const foundByConditionCodeAndGuid = filter(item => {
      const itemConditionCode = path(['serial', 'conditionCode'], item)
      const sameCondition = pathEq(['serial', 'conditionCode'], itemConditionCode, activeItem)
      const itemGuid = prop('guid', item)
      const sameGuid = propEq('guid', itemGuid, activeItem)
      return sameCondition && sameGuid
    }, foundItemsByBarcode)

    if (foundByConditionCodeAndGuid.length === 0) {
      const foundNoCondition = filter(item => {
        const noCondition = !path(['serial', 'conditionCode'], item)
        const itemGuid = prop('guid', item)
        const sameGuid = propEq('guid', itemGuid, activeItem)
        return noCondition && sameGuid
      }, foundItemsByBarcode)
      return foundNoCondition[0]
    }

    return foundByConditionCodeAndGuid[0]
  }

  return null
}

export const getSerialWithNewQuantity = (serial, activeSerial) => {
  const lineItemQuantity = propOr(0, 'quantity', serial)
  const activeQuantity = prop('quantity', activeSerial)
  const lineItemCondition = prop('conditionCode', serial)

  return propEq('conditionCode', lineItemCondition, activeSerial)
    ? { ...activeSerial, quantity: lineItemQuantity + activeQuantity }
    : { ...activeSerial, quantity: activeQuantity }
}

export const getActiveItemMergedWithLineItems = ({ activeLineItem, lineItems }) => {
  const activeBarcode = path(['serial', 'barcode'], activeLineItem)
  const activeSerial = prop('serial', activeLineItem)
  const activeTrackSerial = path(['productVariant', 'trackSerial'], activeLineItem)

  const itemMatchingActiveItem = getItemMatchingActiveItem(activeLineItem, lineItems)
  const matchingItemGuid = prop('guid', itemMatchingActiveItem)
  const serial = prop('serial', itemMatchingActiveItem)

  if (itemMatchingActiveItem && serial) {
    const serial = propOr({}, 'serial', itemMatchingActiveItem)
    const newSerial = activeTrackSerial ? activeSerial : getSerialWithNewQuantity(serial, activeSerial)

    const newLineItem = { ...itemMatchingActiveItem, serial: newSerial }

    const otherLineItems = filter(item => {
      const differentBarcode = !pathEq(['productVariant', 'barcode'], activeBarcode, item)
      const differentGuid = !propEq('guid', matchingItemGuid, item)

      const itemCondition = path(['serial', 'conditionCode'], item)
      const differentCondition = !pathEq(['serial', 'conditionCode'], itemCondition, activeLineItem)

      const itemSerialNumber = path(['serial', 'serialNumber'], item)
      const differentSerialNumber = !pathEq(['serial', 'serialNumber'], itemSerialNumber, activeLineItem)

      return differentGuid || differentBarcode || differentCondition || differentSerialNumber
    }, lineItems)
    return [newLineItem, ...otherLineItems]
  }

  if (itemMatchingActiveItem && !serial) {
    const serial = propOr({}, 'serial', itemMatchingActiveItem)
    const newSerial = activeTrackSerial ? activeSerial : getSerialWithNewQuantity(serial, activeSerial)

    const newLineItem = { ...itemMatchingActiveItem, serial: newSerial }
    const filteredLineItems = filter(pipe(propEq('guid', matchingItemGuid), not), lineItems)
    return [newLineItem, ...filteredLineItems]
  }

  const newLineItem = { ...activeLineItem, serial: activeSerial }

  return [ newLineItem, ...lineItems ]
}
