import React from 'react'
import { Filter } from 'storfox-filter'
import Grid from '@mui/material/Grid'
import { DateRangeField, PositiveFloatField } from 'storfox-form-fields'
import { prop } from 'ramda'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'

import { escapeAtob, getSeparatedDateRange, unescapeBtoa } from '~/utils'
import Accordion from '~/components/Accordion'
import * as API from '~/constants/api'
import MultiSelectSearchField from '~/components/Fields/MultiSelectSearchField'

import { FILTER_NAME as name } from '../../constants'

export const toParams = values => {
  const categories = unescapeBtoa(prop('categories', values))
  const brands = unescapeBtoa(prop('brands', values))
  const [createdAtStart, createdAtEnd] = getSeparatedDateRange(prop('createdAt', values))
  const [updatedAtStart, updatedAtEnd] = getSeparatedDateRange(prop('updatedAt', values))
  const retailPriceStart = prop('retailPriceStart', values)
  const retailPriceEnd = prop('retailPriceEnd', values)

  return {
    categories,
    brands,
    createdAtStart,
    createdAtEnd,
    updatedAtStart,
    updatedAtEnd,
    retailPriceStart,
    retailPriceEnd
  }
}

export const toValues = params => {
  const categories = escapeAtob(prop('categories', params))
  const brands = escapeAtob(prop('brands', params))
  const retailPriceStart = prop('retailPriceStart', params)
  const retailPriceEnd = prop('retailPriceEnd', params)

  const createdAt = [prop('createdAtStart', params), prop('createdAtEnd', params)]
  const updatedAt = [prop('updatedAtStart', params), prop('updatedAtEnd', params)]

  return {
    categories,
    brands,
    createdAt,
    updatedAt,
    retailPriceStart,
    retailPriceEnd
  }
}

export const transformer = { toParams, toValues }

export const bundleFilterOptions = {
  name,
  transformer
}

function BundleFilterForm ({ ...props }) {
  const { t } = useTranslation()

  return (
    <Filter {...props}>
      <Accordion title="General" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <MultiSelectSearchField
              data-cy="categories"
              name="categories"
              label="Category"
              api={API.CATEGORY_LIST}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <MultiSelectSearchField
              data-cy="brands"
              name="brands"
              label="Brand"
              api={API.BRAND_LIST}
            />
          </Grid>
        </Grid>
      </Accordion>
      <Accordion title="Date">
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <Typography variant="body2" gutterBottom={true}>
              {t('Created')}:
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <DateRangeField name="createdAt" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography variant="body2" gutterBottom={true}>
              {t('Updated')}:
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <DateRangeField name="updatedAt" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Accordion>
      <Accordion title="Price">
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={6}>
            <PositiveFloatField
              data-cy="retailPriceStart"
              name="retailPriceStart"
              label="Min Retail Price"
            />
          </Grid>
          <Grid item={true} xs={6}>
            <PositiveFloatField
              data-cy="retailPriceEnd"
              name="retailPriceEnd"
              label="Max Retail Price"
            />
          </Grid>
        </Grid>
      </Accordion>
    </Filter>
  )
}

export default BundleFilterForm
