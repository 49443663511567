import React from 'react'
import PropTypes from 'prop-types'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'

import PositiveNumberField from '~/components/Fields/PositiveNumberField'
import SingleImageUploadField from '~/components/Fields/SingleImageUploadField'
import StatusField from '~/components/Fields/StatusField'
import TagsField from '~/components/Fields/TagsField'
import TextField from '~/components/Fields/TextField'
import { CardHeader } from '~/components/Cards'
import PageTitle from '~/components/PageTitle'
import { DiscardButton, SaveButton } from '~/components/Buttons'
import Container, { Content, Header } from '~/components/Container'
import { withForm } from '~/components/Form'
import * as NAV from '~/constants/nav-titles'
import * as API from '~/constants/api'
import SearchField from '~/components/Fields/SearchField'

export const CATEGORY_CREATE_FIELDS = [
  'name',
  'slug',
  'detail',
  'parent',
  'status',
  'position',
  'tags',
  'imagePath'
]

function CategoryCreateForm ({ title, form, isLoading, pageTitleLoading }) {
  const { t } = useTranslation()
  const { handleSubmit } = form
  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={title}
            parentTitle={t(NAV.CATEGORIES)}
            pageTitleLoading={pageTitleLoading}
            rightButton={(
              <>
                <DiscardButton disabled={isLoading} />
                <SaveButton disabled={isLoading} />
              </>
            )}
          />
        </Header>

        <Content>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12} lg={8}>
              <Box mb={3}>
                <Card>
                  <CardHeader title="Details" />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12}>
                        <TextField
                          data-cy="name"
                          name="name"
                          label="Name"
                          required={true}
                        />
                      </Grid>l

                      <Grid item={true} xs={12}>
                        <TextField
                          data-cy="slug"
                          name="slug"
                          label="Slug"
                        />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <TextField
                          data-cy="detail"
                          name="detail"
                          label="Detail"
                          multiline={true}
                          rows="4"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
            <Grid item={true} xs={12} lg={4}>
              <Box mb={3}>
                <Card>
                  <CardHeader title="Image" />
                  <Divider />
                  <CardContent>
                    <SingleImageUploadField name="imagePath" accept="image/png, image/jpeg" />
                  </CardContent>
                </Card>
              </Box>
              <Box mb={3}>
                <Card>
                  <CardHeader title="Organization" />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12}>
                        <SearchField
                          data-cy="parent"
                          name="parent"
                          label="Parent Category"
                          api={API.CATEGORY_LIST}
                          onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                              event.preventDefault()
                            }
                          }}
                          ListboxProps={{ 'data-cy': 'categoryList' }}
                        />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <PositiveNumberField
                          data-cy="position"
                          name="position"
                          label="Position"
                        />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <StatusField />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <TagsField
                          data-cy="tags"
                          label={t('Tags')}
                          name="tags"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
          <Box mb={3} />
        </Content>
      </Container>
    </form>
  )
}

CategoryCreateForm.propTypes = {
  title: PropTypes.string,
  pageTitleLoading: PropTypes.bool,
  form: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
}

export default withForm(CategoryCreateForm)
