import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import { Filter } from 'storfox-filter'
import { map, pick, pipe, prop, propOr } from 'ramda'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { DateTimeField } from 'storfox-form-fields'

import { escapeAtob, unescapeBtoa } from '~/utils'
import UserMultiSelectField from '~/components/Fields/UserMultiSelectField'
import WarehouseMultiSelectField from '~/components/Fields/WarehouseMultiSelectField'
import Accordion from '~/components/Accordion'
import { getDateTimeGMTDifference, getDateTimeGMTUser } from '~/utils/date'

import PicklistStatusField from './PicklistStatusField'
import PicklistCompanyList from './PicklistCompanyList'

import { FILTER_NAME as name } from '../../constants'

const getAssignedToIds = pipe(
  prop('assignedTo'),
  map(pick(['id', 'firstName', 'lastName']))
)

const getWarehouseIds = pipe(
  prop('warehouses'),
  map(pick(['id', 'name']))
)

export const toParams = values => {
  const status = prop('status', values)

  const assignedToList = propOr([], 'assignedTo', values)
  const warehouseList = propOr([], 'warehouses', values)
  const companyObject = prop('company', values)

  const assignedTo = assignedToList.length ? unescapeBtoa(getAssignedToIds(values)) : null
  const warehouses = warehouseList.length ? unescapeBtoa(getWarehouseIds(values)) : null
  const company = unescapeBtoa(companyObject)

  const createdAtStart = getDateTimeGMTDifference(prop('createdAtStart', values))
  const createdAtEnd = getDateTimeGMTDifference(prop('createdAtEnd', values))
  const updatedAtStart = getDateTimeGMTDifference(prop('updatedAtStart', values))
  const updatedAtEnd = getDateTimeGMTDifference(prop('updatedAtEnd', values))

  return {
    status,
    warehouses,
    assignedTo,
    createdAtStart,
    createdAtEnd,
    updatedAtStart,
    updatedAtEnd,
    company
  }
}

export const toValues = params => {
  const status = prop('status', params)
  const warehouses = escapeAtob(prop('warehouses', params))
  const assignedTo = escapeAtob(prop('assignedTo', params))
  const company = escapeAtob(prop('company', params))

  const createdAtStart = getDateTimeGMTUser(prop('createdAtStart', params))
  const createdAtEnd = getDateTimeGMTUser(prop('createdAtEnd', params))
  const updatedAtStart = getDateTimeGMTUser(prop('updatedAtStart', params))
  const updatedAtEnd = getDateTimeGMTUser(prop('updatedAtEnd', params))

  return {
    status,
    warehouses,
    assignedTo,
    createdAtStart,
    createdAtEnd,
    updatedAtStart,
    updatedAtEnd,
    company
  }
}
export const transformer = { toParams, toValues }

export const picklistFilterOptions = {
  name,
  transformer
}

function PicklistFilterForm (props) {
  const { t } = useTranslation()
  const params = { perms: 'picklist.complete' }

  return (
    <Filter {...props}>
      <Accordion title="General" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <PicklistStatusField />
          </Grid>
          <Grid item={true} xs={12}>
            <WarehouseMultiSelectField />
          </Grid>
          <Grid item={true} xs={12}>
            <UserMultiSelectField params={params} />
          </Grid>
          <Grid item={true} xs={12}>
            <PicklistCompanyList />
          </Grid>
        </Grid>
      </Accordion>
      <Accordion title="Date" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <Typography variant="body2" gutterBottom={true}>
              {t('Created')}:
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <DateTimeField name="createdAtStart" label="Start" />
              </Grid>
              <Grid item={true} xs={12}>
                <DateTimeField name="createdAtEnd" label="End" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography variant="body2" gutterBottom={true}>
              {t('Updated')}:
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <DateTimeField name="updatedAtStart" label="Start" />
              </Grid>
              <Grid item={true} xs={12}>
                <DateTimeField name="updatedAtEnd" label="End" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Accordion>
    </Filter>

  )
}

PicklistFilterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired
}
export default PicklistFilterForm
