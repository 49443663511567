export const NEW = 'new'
export const PENDING = 'pending'
export const IN_PROGRESS = 'in_progress'
export const COMPLETED = 'completed'
export const PICKED = 'picked'
export const PUT = 'put'

export const PUTAWAY_STATUS = {
  NEW,
  PENDING,
  IN_PROGRESS,
  COMPLETED
}

export const PUTAWAY_LINE_ITEM_STATUS = {
  PICKED,
  PUT
}

export const PUTAWAY_STATUS_LABEL = {
  [NEW]: 'New',
  [PENDING]: 'Pending',
  [IN_PROGRESS]: 'In progress',
  [COMPLETED]: 'Completed'
}
export const PUTAWAY_LINE_ITEM_LABEL = {
  [PICKED]: 'picked',
  [PUT]: 'put',
}

export const FILTER_NAME = 'putaway'
