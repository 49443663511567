import React from 'react'
import { useFilter, useOrdering } from 'storfox-filter'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import ErrorLink from '~/components/ErrorLink'
import useMessages from '~/hooks/useMessages'

import { useUnitReportList, useRetailerUnitGenerate } from '../../hooks/RetailerUnit'
import UnitList from '../../components/RetailerUnit/UnitList'
import UnitOrderingForm, { inventoryOrderingOptions } from '../../components/RetailerUnit/UnitOrderingForm'
import UnitFilterForm, { inventoryFilterOptions } from '../../components/RetailerUnit/UnitFilterForm'

function RetailerUnitReportListContainer () {
  const messages = useMessages()

  const unitList = useUnitReportList()
  const unitReportGenerate = useRetailerUnitGenerate()

  const snackbar = useSnackbar()

  const filter = useFilter(inventoryFilterOptions)
  const ordering = useOrdering(inventoryOrderingOptions)

  const handleReportGenerate = () =>
    unitReportGenerate.export()
      .then(() => snackbar({ message: messages.NOTIFICATION_WAIT }))
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const breadcrumbs = { title: NAV.RETAILER_UNITS_REPORT }

  return (
    <DashboardLayout
      title={NAV.RETAILER_UNITS_REPORT}
      activeNav={NAV.REPORTS}
      breadcrumbs={breadcrumbs}
    >

      <UnitFilterForm {...filter} />
      <UnitOrderingForm {...ordering} />

      <UnitList
        list={unitList}
        filter={filter}
        ordering={ordering}
        onListRefetch={unitList.getListByParams}
        handleOpenFilter={filter.handleOpen}
        onReportGenerate={handleReportGenerate}
      />
    </DashboardLayout>
  )
}

export default RetailerUnitReportListContainer
