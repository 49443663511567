import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card/Card'
import { Divider } from '@mui/material'
import CardContent from '@mui/material/CardContent'
import { useTranslation } from 'react-i18next'

import { CardHeader } from '~/components/Cards'
import TextField from '~/components/Fields/TextField'
import StatusField from '~/components/Fields/StatusField'

import OrderTypeField from './Fields/OrderTypeField'
import ItemTypeField from './Fields/ItemTypeField'

function EliteIntegrationUpdateFormGeneral () {
  const { t } = useTranslation()

  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={8}>
        <Box mb={3}>
          <Card>
            <CardHeader title={t('Integration')} />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12} lg={6}>
                  <TextField
                    data-cy="name"
                    name="name"
                    label="Name"
                  />
                </Grid>

                <Grid item={true} xs={12} lg={6}>
                  <StatusField />
                </Grid>

                <Grid item={true} xs={12} lg={6}>
                  <ItemTypeField name="configuration.itemType" />
                </Grid>

                <Grid item={true} xs={12} lg={6}>
                  <OrderTypeField name="configuration.orderType" />
                </Grid>

                <Grid item={true} xs={12}>
                  <TextField
                    data-cy="description"
                    name="description"
                    label="Description"
                    multiline={true}
                    rows="4"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>
      <Grid item={true} xs={4}>
        <Box mb={3}>
          <Card>
            <CardHeader title={t('Credential')} />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <TextField
                    data-cy="username"
                    name="credential.username"
                    label="Username"
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <TextField
                    data-cy="password"
                    inputProps={{
                      type: 'password'
                    }}
                    name="credential.password"
                    label="Password"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </Grid>
  )
}

export default EliteIntegrationUpdateFormGeneral
