import React from 'react'
import { useSnackbar } from 'storfox-snackbar'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { compose, find, not, pathOr, pipe, propEq, propOr } from 'ramda'
import { useRequest } from 'storfox-api-hooks'

import { HtmlLayout } from '~/components/Layouts'
import useMessages from '~/hooks/useMessages'
import * as ROUTES from '~/constants/routes'
import { emptyArray } from '~/constants/empty'
import { PUTAWAY_DETAIL_TABS } from '~/constants/tabs'
import * as API from '~/constants/api'

import { PutawayInitSerializer, PutawayPutSerializer } from '../serializers'
import { usePutawayDetail, usePutawayItemPut } from '../hooks'
import PutawayPutForm from '../components/PutawayPut'

function PutawayPutContainer () {
  const { guid } = useParams()
  const putawayItemPut = usePutawayItemPut(guid)
  const putawayDetail = usePutawayDetail(guid)
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const messages = useMessages()
  const request = useRequest()

  const handleGetSuggestedLocations = (variantGuid) =>
    request.get(`${API.V1_PUTAWAY_LOCATION_SUGGEST}?product_variant_guid=${variantGuid}`)

  const handleSubmit = values =>
    putawayItemPut.update(PutawayPutSerializer(values))
      .then(() => putawayDetail.getDetail())
      .then(({ result }) => {
        snackbar({ message: messages.PUT_SUCCESS })
        const itemToPut = pipe(
          propOr(emptyArray, 'lineItems'),
          find(compose(not, propEq('status', 'put'))),
        )(result)

        if (!itemToPut) {
          const params = { guid, tab: PUTAWAY_DETAIL_TABS.GENERAL }
          const redirectPath = generatePath(ROUTES.V2_PUTAWAY_DETAIL_PATH, params)

          navigate(redirectPath)
        }
      })

  const title = pathOr('Putaway put', ['detail', 'number'], putawayDetail)

  return (
    <HtmlLayout title={title}>
      <PutawayPutForm
        initialValues={PutawayInitSerializer(putawayDetail.detail)}
        onSubmit={handleSubmit}
        isLoading={putawayDetail.isLoading}
        pageTitleLoading={putawayDetail.isLoading}
        pageTitle={title}
        guid={guid}
        onGetSuggestedLocations={handleGetSuggestedLocations}
      />
    </HtmlLayout>
  )
}

export default PutawayPutContainer
