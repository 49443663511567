import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import { length, path, propOr } from 'ramda'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { FieldArray } from 'react-final-form-arrays'
import PropTypes from 'prop-types'
import DialogActions from '@mui/material/DialogActions'
import TableBody from '@mui/material/TableBody'

import DialogTitle from '~/components/DialogTitle'
import { Button, DiscardButton } from '~/components/Buttons'
import MoneyField from '~/components/Fields/MoneyField'

import ConditionSkeleton from './ConditionSkeleton'

function ConditionModal ({ open, onClose, onPricesUpdate, isOwner, isLoading }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth="lg"
    >
      <DialogTitle>
        Other conditions
      </DialogTitle>
      <DialogContent>
        <FieldArray name="conditions">
          {({ fields }) => {
            const isEmpty = !length(propOr([], 'value', fields))

            return (
              <TableContainer>
                <Table>
                  <TableBody>
                    {fields.map((name, index) => {
                      const item = fields.value[index]

                      const conditionName = path(['condition', 'code'], item)
                      const isDefault = path(['condition', 'isDefault'], item)

                      return (
                        <TableRow key={index}>
                          <TableCell>
                            {isDefault ? <strong>{conditionName}</strong> : conditionName}
                          </TableCell>
                          <TableCell sx={{ minWidth: 250 }}>
                            {isOwner && (
                              <MoneyField
                                data-cy="supplyPrice"
                                name={`${name}.supplyPrice`}
                                label="Supply price"
                              />
                            )}
                          </TableCell>
                          <TableCell sx={{ minWidth: 250 }}>
                            <MoneyField
                              data-cy="retailPrice"
                              name={`${name}.retailPrice`}
                              label="Retail price"
                            />
                          </TableCell>
                          <TableCell sx={{ minWidth: 250 }}>
                            <MoneyField
                              data-cy="maximumRetailPrice"
                              name={`${name}.maximumRetailPrice`}
                              label="Maximum Retail price"
                            />
                          </TableCell>
                        </TableRow>
                      )
                    })}
                    {isLoading && isEmpty && <ConditionSkeleton />}
                  </TableBody>
                </Table>
              </TableContainer>
            )
          }}
        </FieldArray>
      </DialogContent>
      <DialogActions>
        <DiscardButton onClick={onClose} fullWidth={true}>
          Discard
        </DiscardButton>

        <Button variant="contained" fullWidth={true} onClick={onPricesUpdate}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConditionModal

ConditionModal.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isOwner: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onPricesUpdate: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
}
