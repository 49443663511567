import { equals, find, flatten, isEmpty, join, map, pick, pipe, prop, propOr, reduce, sortBy } from 'ramda'

const generateNewOptions = options => {
  const firstOptionToVariants = item => {
    const key = item.key
    const name = item.name

    const options = propOr([], 'options', item)
    return options.map(option => ({ options: [{ key, name, option }] }))
  }

  const toMultipleOptionVariants = (others, item) => {
    const options = others.map(i => {
      const key = item.key
      const name = item.name
      const items = propOr([], 'options', item)

      return items.map(j => ({
        options: [...i.options, { key, name, option: j }]
      }))
    })

    return flatten(options)
  }

  const makeOptions = pipe(
    reduce((others, item) => {
      if (others.length) {
        const values = toMultipleOptionVariants(others, item)
        return isEmpty(values) ? others : values
      }

      return firstOptionToVariants(item)
    }, []),
    map(prop('options'))
  )

  return makeOptions(options)
}

const merge = (newOptions, oldVariants) => {
  const findVariant = options =>
    pipe(
      propOr([], 'options'),
      map(pick(['key', 'option'])),
      sortBy(prop('key')),
      equals(
        pipe(
          map(pick(['key', 'option'])),
          sortBy(prop('key'))
        )(options)
      )
    )

  return newOptions.map(option => {
    const variant = find(findVariant(option), oldVariants)

    return { enabled: true, ...variant, options: option }
  })
}

export const getVariants = (options, oldVariants) => {
  const newOptions = generateNewOptions(options)

  return merge(newOptions, oldVariants)
}

export const addVariantName = (name, variants) => {
  return variants.map(variant => {
    const options = map(item => item.option, variant.options)
    const fullName = `${name} ${join(', ', options)}`
    const barcode = prop('barcode', variant)
    const sku = prop('sku', variant)

    return { ...variant, name: fullName, barcode, sku }
  })
}
