import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'

import TextField from '~/components/Fields/TextField'
import BarcodeScan from '~/components/Icons/BarcodeScan'

import useScannerMode from './useScannerMode'

function BarcodeField ({ barcodeRef, onEnter, focusBarcodeField, ...props }) {
  const { scannerMode, onChangeScannerMode } = useScannerMode(focusBarcodeField)

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      onEnter(event)
    }
  }

  return (
    <Paper>
      <TextField
        name="barcode"
        data-cy="barcodeScan"
        onKeyDown={handleKeyDown}
        autoFocus={true}
        InputProps={{
          endAdornment: (
            <IconButton
              onClick={onChangeScannerMode}
              size="small"
              data-cy="barcodeMode"
              sx={{
                zIndex: 1,
                backgroundColor: 'white'
              }}
            >
              <BarcodeScan style={{ fill: scannerMode ? 'blue' : 'black' }} />
            </IconButton>
          )
        }}
        inputProps={{
          ref: barcodeRef
        }}
        {...props}
      />
    </Paper>
  )
}

BarcodeField.propTypes = {
  barcodeRef: PropTypes.object.isRequired,
  onEnter: PropTypes.func.isRequired,
  focusBarcodeField: PropTypes.func.isRequired
}

export default React.memo(BarcodeField)
