import useSearchParams from './useSearchParams'

function useAllSearchParams () {
  const searchParams = useSearchParams()
  const params = {}

  for (let pair of searchParams.entries()) {
    params[pair[0]] = pair[1]
  }

  return params
}

export default useAllSearchParams
