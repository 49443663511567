import React from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

import Tabs from '~/components/Tabs'
import Divider from '~/components/Divider'
import * as ROUTES from '~/constants/routes'
import { RECEIVE_TABS } from '~/constants/tabs'

const tabs = [
  { value: RECEIVE_TABS.GENERAL, label: 'General' },
  { value: RECEIVE_TABS.HISTORY, label: 'History' },
]

function ReceiveTransferTabs ({ guid, value }) {
  const navigate = useNavigate()

  const onChange = (event, tab) => {
    const redirect = generatePath(ROUTES.RECEIVE_TRANSFER_DETAIL_PATH, { guid, tab })
    navigate(redirect, { replace: true })
  }

  return (
    <>
      <Tabs
        tabs={tabs}
        value={value}
        onChange={onChange}
      />
      <Divider />
    </>
  )
}

ReceiveTransferTabs.propTypes = {
  guid: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
}

export default ReceiveTransferTabs
