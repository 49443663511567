import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Barcode from 'react-barcode'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'

function ContainerCardHeader (props) {
  const {
    containerNumber,
    containerType,
    totalContainers,
    currentNumber
  } = props

  return (
    <Box pt={2} pb={2} pl={3} pr={3}>
      <Grid container={true}>
        <Grid item={true} xs={12}>
          <Box display="flex" alignItems="center">
            <Box>
              <Barcode
                height={30}
                width={2}
                displayValue={false}
                value={containerNumber}
              />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              ml={2}
            >
              <div>
                <Typography variant="body2" component="h4">{containerNumber}</Typography>
                <Typography variant="body2" component="h5">{containerType}</Typography>
              </div>
              <div>
                <Typography variant="subtitle1">{currentNumber}/{totalContainers}</Typography>
              </div>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

ContainerCardHeader.propTypes = {
  containerNumber: PropTypes.string.isRequired,
  containerType: PropTypes.string.isRequired,
  totalContainers: PropTypes.number.isRequired,
  currentNumber: PropTypes.number.isRequired
}

export default ContainerCardHeader
