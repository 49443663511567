import React from 'react'
import Box from '@mui/material/Box'
import { prop } from 'ramda'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'

import { UploadedFilePlaceholder } from '~/components/FileUpload'

function UploadedFile ({ information, file }) {
  const stockAdjustmentsCount = prop('stockAdjustmentsCount', information)
  const lineItemsCount = prop('lineItemsCount', information)

  return (
    <UploadedFilePlaceholder file={file}>
      <Box>
        <Typography>
          You will be importing {stockAdjustmentsCount} adjustments with a total of {lineItemsCount} SKUs
        </Typography>
      </Box>
    </UploadedFilePlaceholder>

  )
}

UploadedFile.propTypes = {
  file: PropTypes.object.isRequired,
  information: PropTypes.object
}

export default UploadedFile
