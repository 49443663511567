import React from 'react'
import { useTranslation } from 'react-i18next'
import SelectField from 'storfox-form-fields/src/SelectField'

export const DOORSTEP = '1'
export const HUB_TO_HUB = '2'

const options = [
  { value: DOORSTEP, name: 'Doorstep' },
  { value: HUB_TO_HUB, name: 'Hub to hub' },
]

function DeliveryTypeField ({ ...props }) {
  const { t } = useTranslation()

  return (
    <SelectField
      name="configuration.deliveryTypeId"
      label={t('Delivery type')}
      options={options}
      {...props}
    />
  )
}

export default DeliveryTypeField
