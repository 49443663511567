import React from 'react'
import ArrowBackIos from '@mui/icons-material/ArrowBackIos'

import Button from '~/components/Buttons/Button'

function DiscardButton (props) {
  return (
    <Button
      type="button"
      variant="outlined"

      fullWidth={true}
      startIcon={<ArrowBackIos />}
      {...props}
    >
      Discard
    </Button>
  )
}

export default DiscardButton
