import React from 'react'
import { path, pathOr, propOr } from 'ramda'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import Card from '@mui/material/Card/Card'

import Avatar from '~/components/Avatar/Avatar'
import Money from '~/components/Money'
import VerticalAlignment from '~/components/VerticalAlignment'
import TextOverflow from '~/components/TextOverflow'
import Subtext from '~/components/Subtext'
import EmptyRow from '~/components/EmptyRow'
import { CardHeader } from '~/components/Cards'
import Percentage from '~/components/Percentage'

function DetailLineItems ({ bundle }) {
  const { t } = useTranslation()

  const lineItems = propOr([], 'lineItems', bundle)

  return (
    <Card>
      <CardHeader title={t('Line items')} />
      <TableContainer>
        <Table size="small" sx={{ minWidth: '700px' }}>
          <TableHead>
            <TableRow>
              <TableCell>{t('Image')}</TableCell>
              <TableCell sx={{ width: '250px' }}>{t('Product')}</TableCell>
              <TableCell>{t('Unit price')}</TableCell>
              <TableCell sx={{ width: '200px' }}>{t('Condition code')}</TableCell>
              <TableCell sx={{ width: '200px' }}>{t('Discount')}</TableCell>
              <TableCell sx={{ width: '100px' }}>{t('Qty')}</TableCell>
              <TableCell>{t('Total')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {lineItems.map((item, index) => {
              const conditionCode = path(['condition', 'code'], item)
              const variantName = path(['variant', 'name'], item)
              const sku = path(['variant', 'sku'], item)
              const imagePath = path(['variant', 'defaultImage'], item)
              const totalPrice = propOr(0, 'price', item)
              const unitCost = pathOr(0, ['variant', 'retailPrice'], item)
              const quantity = propOr(0, 'quantity', item)
              const discount = propOr(0, 'discount', item)
              const unitDiscount = unitCost * (1 - discount / 100)
              const discountPrice = unitDiscount < 0 ? 0 : unitDiscount

              return (
                <TableRow key={index}>
                  <TableCell>
                    <Avatar
                      alt={variantName}
                      src={imagePath}
                    />
                  </TableCell>
                  <TableCell>
                    <VerticalAlignment
                      sx={{ minWidth: '200px' }}
                      primary={(
                        <TextOverflow selfTooltip={true}>
                          {variantName}
                        </TextOverflow>
                      )}
                      secondary={<Subtext lines={1}>{sku}</Subtext>}
                    />
                  </TableCell>
                  <TableCell>
                    <VerticalAlignment
                      primary={<Money value={discountPrice} />}
                      secondary={(
                        <del>
                          <Subtext>
                            <Money value={unitCost} />
                          </Subtext>
                        </del>
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    {conditionCode}
                  </TableCell>
                  <TableCell sx={{ width: '150px' }}>
                    <Percentage value={discount} />
                  </TableCell>
                  <TableCell>
                    {quantity}
                  </TableCell>
                  <TableCell>
                    <Money value={totalPrice} />
                  </TableCell>
                </TableRow>
              )
            })}
            {lineItems.length === 0 && <EmptyRow colSpan={7} />}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  )
}

DetailLineItems.propTypes = {
  bundle: PropTypes.object.isRequired
}

export default DetailLineItems
