import React from 'react'
import { TextField as MUITextField } from '@mui/material'
import PropTypes from 'prop-types'

function BarcodeField ({ onChange, ...props }) {
  const handleChange = event => {
    const value = event.target.value
    onChange(value)
  }

  return (
    <MUITextField
      onChange={handleChange}
      {...props}
    />

  )
}

BarcodeField.defaultProps = {
  size: 'small',
  fullWidth: true,
  variant: 'outlined'
}

BarcodeField.propTypes = {
  onChange: PropTypes.func.isRequired
}

export default BarcodeField
