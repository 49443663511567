import React from 'react'
import PropTypes from 'prop-types'
import { colors, styled } from '@mui/material'
import Typography from '@mui/material/Typography'

const LabelStyled = styled(Typography, {
  shouldForwardProp: propName => propName !== 'color' && propName !== 'isContained' && propName !== 'isRounded'
})(({ theme, isContained, color, isRounded }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexGrow: 0,
  flexShrink: 0,
  borderRadius: 2,
  lineHeight: '10px',
  fontSize: '10px',
  height: 20,
  minWidth: 20,
  whiteSpace: 'nowrap',
  padding: theme.spacing(0.5, 1),
  border: `1px solid ${color}`,
  color,
  ...(isRounded && ({
    borderRadius: '10px',
    padding: theme.spacing(0.5)
  })),
  ...(isContained && ({
    backgroundColor: color,
    color: '#FFF'
  }))
}))

function Label ({
  className,
  variant,
  color,
  shape,
  children,
  style,
  ...rest
}) {
  return (
    <LabelStyled
      {...rest}
      isRounded={shape === 'rounded'}
      isContained={variant === 'contained'}
      color={color}
      variant="overline"
    >
      {children}
    </LabelStyled>
  )
}

Label.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  shape: PropTypes.oneOf(['square', 'rounded']),
  style: PropTypes.object,
  variant: PropTypes.oneOf(['contained', 'outlined'])
}

Label.defaultProps = {
  style: {},
  color: colors.grey[600],
  variant: 'contained',
  shape: 'square'
}

export default Label
