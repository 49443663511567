import React from 'react'
import { useParams } from 'react-router-dom'
import { path } from 'ramda'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'

import ListingDetail from '../components/ListingDetail/ListingDetail'
import { useListingDetail } from '../hooks'
import { ListingInitSerializer } from '../serializers'

function ListingDetailContainer () {
  const { guid } = useParams()
  const listingDetail = useListingDetail(guid)

  const name = path(['detail', 'name'], listingDetail)
  const title = listingDetail.isLoading ? 'Listing' : name

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      isLoading={listingDetail.isLoading}
      activeNav={NAV.LISTING}
      breadcrumbs={breadcrumbs}
    >
      <ListingDetail
        pageTitle={title}
        pageTitleLoading={listingDetail.isLoading}
        detail={ListingInitSerializer(guid, listingDetail.detail)}
        isLoading={listingDetail.isLoading}
      />
    </DashboardLayout>
  )
}

export default ListingDetailContainer
