import React from 'react'

import SelectField from '~/components/Fields/SelectField'

export const CASH = 'cash'
export const CREDIT_CARD = 'card'
export const BANK_TRANSFER = 'bank_transfer'

const OPTIONS = [
  { value: CASH, name: 'Cash' },
  { value: CREDIT_CARD, name: 'Credit Card' },
  { value: BANK_TRANSFER, name: 'Bank transfer' }
]

function PaymentTypeField (props) {
  return (
    <SelectField
      data-cy="paymentType"
      name="paymentType"
      label="Payment type"
      options={OPTIONS}
      {...props}
    />
  )
}

export default PaymentTypeField
