import React from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'

import { OVERVIEW_TABS } from '~/constants/tabs'
import Container, { Content } from '~/components/Container'

import OverviewTabs from './OverviewTabs'
import RetailerOverview from './RetailerOverview'
import TplOverview from './TplOverview'
import OverviewHeader from './OverviewHeader'

function Overview (props) {
  const {
    stockOverview,
    lowStock,
    onSubmit,
    gmsdtd,
    orderByChannel,
    carrierDivision,
    stockFlow,
    overviewInformation,
    orderOverview,
    shipmentOverview,
    ordersByUnitsOverview,
    poByUnitsOverview,
    purchaseOrder,
    pickerStatistic,
    packerStatistic,
    receiveStatistic,
    putawayStatistic
  } = props

  const { tab } = useParams()

  return (
    <Container>
      <Content>
        <OverviewHeader onSubmit={onSubmit} />

        <OverviewTabs value={tab} />

        {tab === OVERVIEW_TABS.SALES && (
          <RetailerOverview
            purchaseOrder={purchaseOrder}
            lowStock={lowStock}
            gmsdtd={gmsdtd}
            orderByChannel={orderByChannel}
            carrierDivision={carrierDivision}
            overviewInformation={overviewInformation}
          />
        )}

        {tab === OVERVIEW_TABS.FULFILLMENT && (
          <TplOverview
            stockOverview={stockOverview}
            stockFlow={stockFlow}
            orderOverview={orderOverview}
            shipmentOverview={shipmentOverview}
            ordersByUnitsOverview={ordersByUnitsOverview}
            poByUnitsOverview={poByUnitsOverview}
            pickerStatistic={pickerStatistic}
            packerStatistic={packerStatistic}
            receiveStatistic={receiveStatistic}
            putawayStatistic={putawayStatistic}
          />
        )}
      </Content>
    </Container>
  )
}

Overview.propTypes = {
  stockOverview: PropTypes.object.isRequired,
  poByUnitsOverview: PropTypes.object.isRequired,
  purchaseOrder: PropTypes.object.isRequired,
  lowStock: PropTypes.object.isRequired,
  gmsdtd: PropTypes.object.isRequired,
  orderByChannel: PropTypes.object.isRequired,
  carrierDivision: PropTypes.object.isRequired,
  stockFlow: PropTypes.object.isRequired,
  overviewInformation: PropTypes.object.isRequired,
  orderOverview: PropTypes.object.isRequired,
  shipmentOverview: PropTypes.object.isRequired,
  ordersByUnitsOverview: PropTypes.object.isRequired,
  pickerStatistic: PropTypes.object.isRequired,
  packerStatistic: PropTypes.object.isRequired,
  receiveStatistic: PropTypes.object.isRequired,
  putawayStatistic: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default Overview
