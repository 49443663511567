import { defaultTo, map, path, pick, pipe, prop, propOr } from 'ramda'
import { DEFAULT_SEARCH_PARAMS } from 'storfox-route-hooks'

import { escapeAtob, getDateTimeMidnight } from '~/utils'

const ADJUSTMENT_ADD = 'add'
const ADJUSTMENT_REMOVE = 'remove'
const FIELDS = [
  'id',
  'variant',
  'quantity',
  'price',
  'location',
  'units',
  'expiresAt',
  'containerNumber',
  'productionDate'
]

const getWarehouse = path(['warehouse', 'id'])
const getReason = path(['reason', 'id'])

const getUnits = pipe(
  defaultTo([]),
  map(item => {
    const conditionId = path(['condition', 'id'], item)
    const condition = conditionId && prop('condition', item)

    return { ...item, condition }
  })
)

const getLineItems = pipe(
  prop('lineItems'),
  defaultTo([]),
  map(pick(FIELDS)),
  map(item => {
    const quantity = prop('quantity', item)
    const conditionId = path(['condition', 'id'], item)
    const variantId = path(['variant', 'id'], item)
    const unitList = prop('units', item)
    const units = getUnits(unitList)

    const expireDate = prop('expiresAt', item)
    const expiresAt = getDateTimeMidnight(expireDate)
    const productionDateForm = prop('productionDate', item)
    const productionDate = getDateTimeMidnight(productionDateForm)

    return {
      ...item,
      units,
      expiresAt,
      productionDate,
      quantity: quantity && Number(quantity),
      variant: variantId && { id: variantId },
      condition: conditionId && { id: conditionId },
    }
  })
)

const getLineItemsGroup = (items) => {
  let data = []
  for (let i = 0; i < items.length; i++) {
    const item = items[i]
    const foundItem = data.find((prev) => {
      const sameVariant = path(['variant', 'id'], prev) === path(['variant', 'id'], item)
      const sameLocation = path(['location', 'id'], prev) === path(['location', 'id'], item)
      const sameContainer = prop('containerNumber', prev) === path(['container', 'number'], item)
      const samePrice = prop('price', prev) === prop('price', item)
      return sameVariant && sameLocation && sameContainer && samePrice
    })
    if (foundItem) {
      const units = propOr([], 'units', foundItem)
      const condition = prop('condition', item)
      const conditionCode = prop('code', condition)
      const conditionId = prop('id', condition)
      const batchNumber = prop('batchNumber', item)
      const serialNumber = prop('serialNumber', item)
      const quantity = prop('quantity', item)
      const unitNumber = prop('unitNumber', item)
      const newItem = {
        ...foundItem,
        units: [
          {
            condition: {
              name: conditionCode,
              id: conditionId
            },
            batchNumber,
            serialNumber,
            quantity,
            unitNumber
          },
          ...units
        ]
      }
      const otherData = data.filter((prev) => {
        const sameVariant = path(['variant', 'id'], prev) === path(['variant', 'id'], item)
        const sameLocation = path(['location', 'id'], prev) === path(['location', 'id'], item)
        const sameContainer = prop('containerNumber', prev) === path(['container', 'number'], item)
        const samePrice = prop('price', prev) === prop('price', item)
        return !sameVariant || !sameLocation || !sameContainer || !samePrice
      })
      data = [...otherData, newItem]
    } else {
      const containerNumber = path(['container', 'number'], item)
      const condition = prop('condition', item)
      const conditionCode = prop('code', condition)
      const conditionId = prop('id', condition)
      const batchNumber = prop('batchNumber', item)
      const expiresAt = prop('expiresAt', item)
      const guid = prop('guid', item)
      const id = prop('id', item)
      const location = prop('location', item)
      const price = prop('price', item)
      const productionDate = prop('productionDate', item)
      const quantity = prop('quantity', item)
      const serialNumber = prop('serialNumber', item)
      const unitNumber = prop('unitNumber', item)
      const variant = prop('variant', item)
      const variantName = prop('name', variant)
      const variantSku = prop('sku', variant)
      const product = prop('product', variant)
      const trackExpiryDates = prop('trackExpiryDates', product)
      const trackSerialNumbers = prop('trackSerialNumbers', product)
      const trackProductionDates = prop('trackProductionDates', product)

      data.push({
        containerNumber,
        expiryDate: expiresAt,
        guid,
        id,
        location,
        price,
        product: {
          variantName,
          sku: variantSku
        },
        productionDate,
        sku: variantSku,
        trackExpiry: trackExpiryDates,
        trackSerial: trackSerialNumbers,
        unitNumber: { variant },
        units: [{
          condition: { name: conditionCode, id: conditionId },
          batchNumber,
          serialNumber,
          quantity,
          unitNumber
        }],
        variant: { ...variant, trackExpiryDates, trackSerialNumbers, trackProductionDates },
        variantName
      })
    }
  }
  return data
}

export const AdjustmentInitSerializer = (initialValues, adjustmentLineItems = []) => {
  const lineItems = getLineItemsGroup(adjustmentLineItems)

  return { ...initialValues, lineItems }
}

export function UnitCheckSerializer ({ unitNumber, removeOperation, variant }, warehouseGuid) {
  const type = removeOperation ? ADJUSTMENT_REMOVE : ADJUSTMENT_ADD
  const id = prop('id', variant)

  return { type, unitNumber, variant: { id }, warehouse: { guid: warehouseGuid } }
}

const getBarcodeCheckValues = values => {
  const id = prop('id', values)
  const ownerId = prop('ownerId', values)
  const reasonId = prop('reasonId', values)
  const warehouseId = prop('warehouseId', values)
  const owner = { id: ownerId }
  const warehouse = warehouseId && { id: warehouseId }
  const reason = reasonId && { id: reasonId }

  return { id, owner, warehouse, reason }
}

const getExpiresAt = map(item => {
  const expiresAt = getDateTimeMidnight(prop('expiresAt', item))
  return { ...item, expiresAt }
})

const getBarcodeRemoveLineItems = pipe(
  propOr([], 'lineItems'),
  getExpiresAt,
  map(({ units, ...rest }) => ({
    ...rest,
    units: map(pick([
      'id',
      'unit',
      'unitNumber',
      'quantity'
    ]), units)
  })),
)

export function BarcodeCheckAddSerializer (values) {
  const lineItems = getExpiresAt(prop('lineItems', values))

  return { ...getBarcodeCheckValues(values), lineItems }
}

export function BarcodeCheckRemoveSerializer (values) {
  const lineItems = getBarcodeRemoveLineItems(values)

  return { ...getBarcodeCheckValues(values), lineItems }
}

export function AdjustmentCreateSerializer (formValues, companyId) {
  const notes = prop('notes', formValues)
  const referenceId = prop('referenceId', formValues)
  const reasonId = getReason(formValues)
  const warehouseId = getWarehouse(formValues)
  const lineItems = getLineItems(formValues)
  const ownerId = path(['owner', 'id'], formValues)
  const owner = ownerId ? { id: ownerId } : { id: companyId }

  return {
    owner,
    notes,
    lineItems,
    referenceId,
    warehouse: warehouseId && { id: warehouseId },
    reason: reasonId && { id: reasonId }
  }
}

export const AdjustmentFilterSerializer = params => {
  const defaultParams = pick(DEFAULT_SEARCH_PARAMS, params)
  const status = prop('status', params)
  const totalPriceStart = prop('totalPriceStart', params)
  const totalPriceEnd = prop('totalPriceEnd', params)
  const warehouseids = escapeAtob(prop('warehouses', params))
  const reasonIds = escapeAtob(prop('reasons', params))
  const updatedAtStart = prop('updatedAtStart', params)
  const updatedAtEnd = prop('updatedAtEnd', params)

  return {
    ...defaultParams,
    status,
    totalPriceStart,
    totalPriceEnd,
    updatedAtStart,
    updatedAtEnd,
    warehouseids,
    reasonIds
  }
}
