import { useCreate } from 'storfox-api-hooks'

import * as API from '../constants/api'
import { FilterCreateSerializer } from '../serializers'

const useFilterCreate = () => {
  const { create, ...state } = useCreate(API.FILTER_CREATE)

  const onSubmit = formValues => {
    const values = FilterCreateSerializer(formValues)
    return create(values)
  }

  return { ...state, create: onSubmit }
}

export default useFilterCreate
