import { map, pick, pipe, prop, propOr, trim } from 'ramda'
import { DEFAULT_SEARCH_PARAMS } from 'storfox-route-hooks'

import { escapeAtob, getDateTimeResetZone } from '~/utils'

import { BUNDLE_CREATE_FIELDS } from '../components/BundleCreate'
import { BUNDLE_UPDATE_FIELDS } from '../components/BundleUpdate'

const getLineItems = pipe(
  propOr([], 'lineItems'),
  map(item => {
    const condition = prop('condition', item)
    const name = prop('code', condition)
    return { ...item, condition: { ...condition, name } }
  })
)

export function BundleUpdateInitialSerializer (detail) {
  const lineItems = getLineItems(detail)
  const newDetails = { ...detail, lineItems }
  return { ...newDetails, defaultValues: newDetails }
}

export function BundleCreateSerializer (formValues) {
  const values = pick(BUNDLE_CREATE_FIELDS, formValues)
  const barcodeValue = prop('barcode', values)
  const barcode = barcodeValue && trim(barcodeValue)
  const skuValue = prop('sku', values)
  const sku = skuValue && trim(skuValue)
  return { ...values, barcode, sku }
}

export function BundleUpdateSerializer (formValues) {
  const values = pick(BUNDLE_UPDATE_FIELDS, formValues)
  const barcodeValue = prop('barcode', values)
  const barcode = barcodeValue && trim(barcodeValue)
  const skuValue = prop('sku', values)
  const sku = skuValue && trim(skuValue)
  return { ...values, barcode, sku }
}

export function BundleListingCreateSerializer (selectedGuids, channelGuids) {
  const bundles = map(guid => ({ guid }), selectedGuids)
  const channels = map(guid => ({ guid }), channelGuids)

  return { channels, bundles }
}

export function BundleFilterSerializer (params) {
  const defaultParams = pick(DEFAULT_SEARCH_PARAMS, params)
  const categoryGuids = escapeAtob(prop('categories', params))
  const brandGuids = escapeAtob(prop('brands', params))
  const createdAtStart = getDateTimeResetZone(prop('createdAtStart', params))
  const createdAtEnd = getDateTimeResetZone(prop('createdAtEnd', params))
  const updatedAtStart = getDateTimeResetZone(prop('updatedAtStart', params))
  const updatedAtEnd = getDateTimeResetZone(prop('updatedAtEnd', params))
  const retailPriceStart = prop('retailPriceStart', params)
  const retailPriceEnd = prop('retailPriceEnd', params)

  return {
    ...defaultParams,
    categoryGuids,
    brandGuids,
    createdAtStart,
    createdAtEnd,
    updatedAtStart,
    updatedAtEnd,
    retailPriceStart,
    retailPriceEnd
  }
}
