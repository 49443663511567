import React from 'react'
import PropTypes from 'prop-types'
import Chip from '@mui/material/Chip'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'

const RootStyled = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 0),
  '& > div': {
    marginRight: 5,
    '&:last-child': {
      marginRight: 0,
    }
  }
}))

function Tags ({ items, ...props }) {
  return (
    <RootStyled>
      {items.map((item, index) => (
        <Chip
          key={index}
          label={item}
          {...props}
        />
      ))}
    </RootStyled>
  )
}

Tags.defaultProps = {
  size: 'small',
  items: []
}

Tags.propTypes = {
  items: PropTypes.array.isRequired,
  size: PropTypes.string.isRequired
}

export default Tags
