import React from 'react'
import { Filter } from 'storfox-filter'
import Grid from '@mui/material/Grid'
import { prop } from 'ramda'
import { DateTime } from 'luxon'
import Typography from '@mui/material/Typography'
import { DateRangeField } from 'storfox-form-fields'
import { useTranslation } from 'react-i18next'

import { escapeAtob, unescapeBtoa } from '~/utils'
import TextField from '~/components/Fields/TextField'
import Accordion from '~/components/Accordion'
import { ActiveStatusField } from '~/components/ActiveStatus'
import * as DATE_FORMAT from '~/constants/dateFormat'

import { FILTER_NAME as name } from '../../../constants/TplBilling'
import CustomerSearchField from '../Fields/CustomerSearchField'

export const toParams = values => {
  const status = prop('status', values)
  const client = unescapeBtoa(prop('client', values))
  const dateTime = prop('dateTime', values)
  const fromDateTime = prop(0, dateTime) &&
    DateTime
      .fromISO(prop(0, dateTime))
      .startOf('day')
      .toFormat(DATE_FORMAT.DATE_FORMAT_ISO_8601_TIME_SECONDS)
  const toDateTime = prop(1, dateTime) &&
    DateTime
      .fromISO(prop(1, dateTime))
      .plus({ days: 1 })
      .startOf('day')
      .toFormat(DATE_FORMAT.DATE_FORMAT_ISO_8601_TIME_SECONDS)
  const minTotal = prop('minTotal', values)
  const maxTotal = prop('maxTotal', values)

  return {
    status,
    client,
    fromDateTime,
    toDateTime,
    minTotal,
    maxTotal
  }
}

export const toValues = params => {
  const status = prop('status', params)
  const client = escapeAtob(prop('client', params))
  const dateTime = [prop('fromDateTime', params), prop('toDateTime', params)]
  const minTotal = prop('minTotal', params)
  const maxTotal = prop('maxTotal', params)

  return {
    status,
    client,
    dateTime,
    minTotal,
    maxTotal
  }
}
export const transformer = { toParams, toValues }

export const tplBillingFilterOptions = {
  name,
  transformer
}

function TplBillingFilterForm (props) {
  const { t } = useTranslation()
  return (
    <Filter {...props}>
      <Accordion title="General" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <ActiveStatusField />
          </Grid>
          <Grid item={true} xs={12}>
            <CustomerSearchField />
          </Grid>
        </Grid>
      </Accordion>
      <Accordion title="Date">
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <Typography variant="body2" gutterBottom={true}>
              {t('Interval:')}:
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <DateRangeField name="dateTime" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Accordion>
      <Accordion title="Total ($)">
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={6}>
            <TextField
              data-cy="minTotal"
              name="minTotal"
              label="Min ($)"
            />
          </Grid>
          <Grid item={true} xs={6}>
            <TextField
              data-cy="maxTotal"
              name="maxTotal"
              label="Max ($)"
            />
          </Grid>
        </Grid>
      </Accordion>
    </Filter>
  )
}

export default TplBillingFilterForm
