import { path } from 'ramda'
import React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useSnackbar } from 'storfox-snackbar'
import { useTranslation } from 'react-i18next'

import { DashboardLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'
import { CUSTOMER_DETAIL_TABS } from '~/constants/tabs'

import { CustomerUpdateForm, getInitialValue } from '../components/CustomerUpdate'
import { useCustomerDetail, useCustomerUpdate } from '../hooks'
import { CustomerUpdateSerializer } from '../serializers'

function CustomerUpdateContainer () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { guid } = useParams()
  const snackbar = useSnackbar()
  const customerDetail = useCustomerDetail(guid)
  const customerUpdate = useCustomerUpdate(guid)

  const message = t('Successfully updated')

  const handleSubmit = formValues =>
    customerUpdate.update(CustomerUpdateSerializer(formValues))
      .then(() => snackbar({ message }))
      .then(() => {
        const params = { guid, tab: CUSTOMER_DETAIL_TABS.GENERAL }
        const redirect = generatePath(ROUTES.CUSTOMER_DETAIL_PATH, params)

        navigate(redirect)
      })

  const pageLoading = customerDetail.isLoading ||
    customerUpdate.isLoading

  const firstName = path(['detail', 'firstName'], customerDetail)
  const lastName = path(['detail', 'lastName'], customerDetail)
  const fullName = `${firstName} ${lastName}`
  const title = customerDetail.isLoading ? 'Customers' : fullName

  const breadcrumbs = {
    title: 'Edit',
    params: [
      { guid, tab: CUSTOMER_DETAIL_TABS.GENERAL, title }
    ]
  }

  return (
    <DashboardLayout
      title={title}
      isLoading={pageLoading}
      activeNav={NAV.CUSTOMERS}
      breadcrumbs={breadcrumbs}
    >
      <CustomerUpdateForm
        pageTitle={title}
        pageTitleLoading={customerDetail.isLoading}
        initialValues={getInitialValue(customerDetail.detail)}
        onSubmit={handleSubmit}
        isLoading={customerUpdate.isLoading}
      />
    </DashboardLayout>
  )
}

export default CustomerUpdateContainer
