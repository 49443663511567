import React from 'react'
import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import DialogTitle from '~/components/DialogTitle'
import CountryField from '~/components/Fields/CountryField'
import EmailField from '~/components/Fields/EmailField'
import PhoneNumberField from '~/components/Fields/PhoneNumberField'
import TextField from '~/components/Fields/TextField'
import { Button, DiscardButton } from '~/components/Buttons'
import { withForm } from '~/components/Form'

function CustomerDialogForm ({ onClose, form, ...props }) {
  const { handleSubmit } = form
  const { t } = useTranslation()

  return (
    <Dialog maxWidth="md" onClose={onClose} {...props}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          <Typography component="h3" variant="h6">Create customer</Typography>
        </DialogTitle>
        <DialogContent dividers={true}>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12}>
              <Typography variant="h6" gutterBottom={true}>{t('GENERAL')}</Typography>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12} lg={6}>
                  <TextField
                    data-cy="firstName"
                    name="firstName"
                    label="First name"
                  />
                </Grid>

                <Grid item={true} xs={12} lg={6}>
                  <TextField
                    data-cy="lastName"
                    name="lastName"
                    label="Last name"
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <EmailField
                    data-cy="email"
                    name="email"
                    label="Email"
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <PhoneNumberField
                    data-cy="mobile"
                    name="mobile"
                    label="Phone number"
                  />
                </Grid>
              </Grid>
              <Box mt={3}>
                <Typography variant="h6" gutterBottom={true}>{t('ADDRESS')}</Typography>
              </Box>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <TextField
                    data-cy="address"
                    name="address"
                    label="Address"
                    multiline={true}
                    minRows={3}
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <TextField
                    data-cy="region"
                    name="region"
                    label="Region/Neighbouring/State"
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <CountryField
                    data-cy="country"
                    name="country"
                    label="Country"
                  />
                </Grid>

                <Grid item={true} xs={6}>
                  <TextField
                    data-cy="city"
                    name="city"
                    label="City"
                  />
                </Grid>

                <Grid item={true} xs={6}>
                  <TextField
                    data-cy="zipcode"
                    name="zipcode"
                    label="Zipcode"
                  />
                </Grid>

              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DiscardButton
            fullWidth={true}
            onClick={onClose}
          >
            Discard
          </DiscardButton>
          <Button
            variant="contained"
            type="submit"
            fullWidth={true}
            data-cy="save"
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

CustomerDialogForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired
}

export default withForm(CustomerDialogForm)
