import React from 'react'
import PropTypes from 'prop-types'
import SaveIcon from '@mui/icons-material/Save'

import Button from '../Buttons/Button'

function SaveButton ({ children, ...props }) {
  return (
    <Button
      variant="contained"
      type="submit"
      data-cy="submit"
      startIcon={<SaveIcon />}
      {...props}
    >
      {children}
    </Button>
  )
}

SaveButton.propTypes = {
  children: PropTypes.any.isRequired
}

SaveButton.defaultProps = {
  children: 'Save'
}

export default SaveButton
