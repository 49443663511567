export function objectToBase64 (obj) {
  if (obj) {
    try {
      return encodeURIComponent(btoa(JSON.stringify(obj)))
    } catch (e) {}
  }
}

export function base64ToObject (b64) {
  if (b64) {
    try {
      return JSON.parse(atob(decodeURIComponent(b64)))
    } catch (e) {}
  }
}

export function unescapeBtoa (obj) {
  if (obj) {
    try {
      return btoa(encodeURIComponent(unescape(JSON.stringify(obj))))
    } catch (e) {}
  }
}

export function escapeAtob (obj) {
  if (obj) {
    try {
      return JSON.parse(decodeURIComponent(atob(obj)))
    } catch (e) {}
  }
}
