import PropTypes from 'prop-types'
import React, { useContext, useEffect } from 'react'
import { prop } from 'ramda'
import { createOriginImageUrl } from 'storfox-image-upload'

import Context from './context'

export const useWhiteLabel = () => {
  const { domainValues } = useContext(Context)

  return { domainValues }
}

function WhiteLabel ({ children }) {
  const domainValues = JSON.parse(localStorage.getItem('domain'))

  useEffect(() => {
    const favicon = prop('favicon', domainValues)
    if (favicon) {
      const link = document.createElement('link')
      link.rel = 'icon'
      document.head.appendChild(link)
      link.href = createOriginImageUrl(favicon)
    }
  }, [domainValues])

  return (
    <Context.Provider value={{ domainValues }}>
      {children}
    </Context.Provider>
  )
}

WhiteLabel.propTypes = {
  children: PropTypes.any.isRequired
}

export default WhiteLabel
