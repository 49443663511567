import React from 'react'
import AlertTitle from '@mui/material/AlertTitle'
import Alert from '@mui/material/Alert'
import { Box, styled, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useSnackbar } from 'storfox-snackbar'

import useMessages from '~/hooks/useMessages'

const InfoWrapperAlertStyled = styled(Alert)({
  borderRadius: '4px',
  '& strong': {
    color: 'black'
  }
})

function WebHookSetInstruction ({ webHookUrl }) {
  const snackbar = useSnackbar()
  const messages = useMessages()

  return (
    <InfoWrapperAlertStyled severity="error" >
      <AlertTitle>Set Webhook manually</AlertTitle>
      <Box sx={{
        color: 'rgba(39, 50, 87, 0.7)',
        fontSize: '14px',
        lineHeight: '22px'
      }}>
        Your integration is created but an attempt to register {' '}
        the customer webhook on <strong>"Shipox"</strong>&nbsp;
        is failed. Please reach Shipox support and register this webhook
      </Box>
      <Box mt={2}>
        <Typography
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            navigator.clipboard.writeText(webHookUrl)
            snackbar({ message: messages.COPY_SUCCESS })
          }}
          variant="subtitle2">
          <strong>
            {webHookUrl}
          </strong>
        </Typography>
      </Box>
    </InfoWrapperAlertStyled>
  )
}

WebHookSetInstruction.propTypes = {
  webHookUrl: PropTypes.string
}

export default WebHookSetInstruction
