import React from 'react'
import { useFilter, useOrdering } from 'storfox-filter'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'
import { prop } from 'ramda'
import { useAllSearchParams, useRoute } from 'storfox-route-hooks'
import { useRequest } from 'storfox-api-hooks'
import { sprintf } from 'sprintf-js'
import { getDataFromError } from 'storfox-api-hooks/src/api/utils'
import { useNavigate } from 'react-router-dom'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import useMessages from '~/hooks/useMessages'
import ErrorLink from '~/components/ErrorLink'
import { useTableSelects } from '~/components/TableValues/hooks'
import * as API from '~/constants/api'
import * as ROUTES from '~/constants/routes'

import {
  PurchaseOrderFilterForm,
  purchaseOrderFilterOptions,
  PurchaseOrderOrderingForm,
  purchaseOrderOrderingOptions,
  PurchaseOrderTable
} from '../components/PurchaseOrderList'
import {
  usePurchaseOrderExcelReport,
  usePurchaseOrderList,
  usePurchaseOrderPdfReport,
  usePurchaseOrdersDelete
} from '../hooks'

function PurchaseOrderListContainer () {
  const snackbar = useSnackbar()
  const messages = useMessages()
  const route = useRoute()
  const request = useRequest()
  const navigate = useNavigate()

  const purchaseOrderList = usePurchaseOrderList()
  const purchaseOrdersDelete = usePurchaseOrdersDelete()
  const purchaseOrderExcelReport = usePurchaseOrderExcelReport()
  const purchaseOrderPdfReport = usePurchaseOrderPdfReport()

  const { selects, resetTableSelects } = useTableSelects()

  const filter = useFilter(purchaseOrderFilterOptions)
  const allSearchParams = useAllSearchParams()
  const status = prop('status', allSearchParams)
  const page = prop('page', allSearchParams)
  const ordering = useOrdering(purchaseOrderOrderingOptions)

  const getOrderList = () => {
    if (page === '1') {
      purchaseOrderList.getList({ status, page: 1 })
      return
    }

    route.changeParams({ ...allSearchParams, page: 1 })
  }

  const handlePurchaseOrdersDelete = () =>
    purchaseOrdersDelete.delete({ guids: selects })
      .then(() => getOrderList())
      .then(() => snackbar({ message: messages.DELETE_SUCCESS }))
      .then(() => resetTableSelects())
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const handleGenerateExcelReport = () =>
    purchaseOrderExcelReport.generate({ purchaseOrderGuids: selects })
      .then(() => snackbar({ message: messages.GENERATE_SUCCESS }))
      .then(() => resetTableSelects())
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const handleGeneratePdfReport = () =>
    purchaseOrderPdfReport.generate({ purchaseOrderGuids: selects })
      .then(() => snackbar({ message: messages.GENERATE_SUCCESS }))
      .then(() => resetTableSelects())
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const handleCloseOrder = guid =>
    request.put(sprintf(API.PURCHASE_ORDERS_CLOSE, guid))
      .then(() => snackbar({ message: messages.CLOSE_SUCCESS }))
      .then(() => purchaseOrderList.getList())
      .catch(response => {
        const error = getDataFromError(response)
        return Promise.reject(error)
      })
      .catch(error => snackbar({
        type: ALTER_ERROR,
        message: <ErrorLink error={error} />
      }))

  const handleCancelOrder = guid =>
    request.put(sprintf(API.PURCHASE_ORDERS_CANCEL, guid))
      .then(() => snackbar({ message: messages.CANCEL_SUCCESS }))
      .then(() => purchaseOrderList.getList())
      .catch(response => {
        const error = getDataFromError(response)
        return Promise.reject(error)
      })
      .catch(error => snackbar({
        type: ALTER_ERROR,
        message: <ErrorLink error={error} />
      }))

  const handleDownloadOrder = guid => {
    request.post(sprintf(API.PURCHASE_ORDER_GENERATE_REPORT, guid))
      .then(() => snackbar({ message: messages.NOTIFICATION_WAIT }))
      .catch(response => {
        const error = getDataFromError(response)
        return Promise.reject(error)
      })
      .catch(response => {
        const message = <ErrorLink error={response} />
        snackbar({ message, type: ALTER_ERROR })
      })
  }

  const handleEmailSent = guid => {
    request.post(sprintf(API.PURCHASE_ORDER_EMAIL_TO_SUPPLIER, guid))
      .then(() => snackbar({ message: messages.EMAIL_SENT }))
      .catch(response => {
        const error = getDataFromError(response)
        return Promise.reject(error)
      })
      .catch(response => {
        const message = <ErrorLink error={response} />
        snackbar({ message, type: ALTER_ERROR })
      })
  }

  const handleInvoiceGenerate = guid =>
    request.post(sprintf(API.PURCHASE_ORDER_INVOICE_GENERATE, guid))
      .then(() => snackbar({ message: messages.NOTIFICATION_WAIT }))
      .catch(response => {
        const error = getDataFromError(response)
        return Promise.reject(error)
      })
      .catch(response => {
        const message = <ErrorLink error={response} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const handleMarkAsPaid = guid =>
    request.put(sprintf(API.PURCHASE_ORDERS_MARK_AS_PAID, guid))
      .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
      .then(() => purchaseOrderList.getList())
      .catch(error => snackbar({
        type: ALTER_ERROR,
        message: <ErrorLink error={error} />
      }))

  const handleReturnCreate = guid => {
    const supplierReturnCreatePath = `${ROUTES.SUPPLIER_RETURN_CREATE_PATH}?purchaseGuid=${guid}`
    navigate(supplierReturnCreatePath)
  }

  const bulkActionLoading = (
    purchaseOrderExcelReport.isLoading ||
    purchaseOrderPdfReport.isLoading ||
    purchaseOrdersDelete.isLoading
  )

  const breadcrumbs = { title: NAV.PURCHASE_ORDER }

  return (
    <DashboardLayout
      title={NAV.PURCHASE_ORDER}
      activeNav={NAV.PURCHASE_ORDER}
      breadcrumbs={breadcrumbs}
    >
      <PurchaseOrderFilterForm {...filter} />
      <PurchaseOrderOrderingForm {...ordering} />

      <PurchaseOrderTable
        filter={filter}
        ordering={ordering}
        list={purchaseOrderList}
        onListRefetch={purchaseOrderList.getListByParams}
        onPurchaseOrdersDelete={handlePurchaseOrdersDelete}
        onGenerateExcelReport={handleGenerateExcelReport}
        onGeneratePdfReport={handleGeneratePdfReport}
        onCloseOrder={handleCloseOrder}
        onCancelOrder={handleCancelOrder}
        onDownloadOrder={handleDownloadOrder}
        onEmailSent={handleEmailSent}
        onInvoiceGenerate={handleInvoiceGenerate}
        onMarkPaid={handleMarkAsPaid}
        onReturnCreate={handleReturnCreate}
        bulkActionLoading={bulkActionLoading}
      />
    </DashboardLayout>
  )
}

export default PurchaseOrderListContainer
