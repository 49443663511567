import React, { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSnackbar } from 'storfox-snackbar'
import { prop, propOr } from 'ramda'
import { useRequest } from 'storfox-api-hooks'
import { sprintf } from 'sprintf-js'

import { DashboardLayout } from '~/components/Layouts'
import Title from '~/components/Title'
import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'
import useMessages from '~/hooks/useMessages'
import * as API from '~/constants/api'

import MovementUpdateForm from '../components/MovementUpdate'
import {
  useMovementDetail, useMovementLineItems,
  useMovementUnitNumber,
  useMovementUpdate,
  useMovementUpdateAndComplete
} from '../hooks'
import { MovementCreateSerializer, MovementInitialValuesSerializer } from '../serializers'
import { SAVE_COMPLETE } from '../constants'

function MovementScanUpdateContainer () {
  const { id } = useParams()
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const messages = useMessages()
  const request = useRequest()

  const movementDetail = useMovementDetail(id)
  const movementUpdate = useMovementUpdate(id)
  const movementUpdateAndComplete = useMovementUpdateAndComplete(id)
  const movementUnitNumber = useMovementUnitNumber()
  const movementLineItems = useMovementLineItems(id)

  const [containerLoading, setContainerLoading] = useState(false)

  const title = prop('number', movementDetail.detail)
  const status = prop('status', movementDetail.detail)

  const pageTitle = <Title title={title} status={status} />

  const handleSubmit = formValues => {
    const submitType = propOr(SAVE_COMPLETE, 'submitType', formValues)
    if (submitType === SAVE_COMPLETE) {
      return movementUpdateAndComplete.update(MovementCreateSerializer(formValues, 'scan'))
        .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
        .then(() => navigate(ROUTES.MOVEMENT_LIST_PATH))
    } else {
      return movementUpdate.update(MovementCreateSerializer(formValues, 'scan'))
        .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
        .then(() => navigate(ROUTES.MOVEMENT_LIST_PATH))
    }
  }

  const handleVariantScan = params =>
    movementUnitNumber.scan(params)

  const handleContainerScan = containerNumber => {
    setContainerLoading(true)
    const url = sprintf(API.CONTAINER_BY_NUMBER, containerNumber)
    return request.get(url).then((res) => {
      setContainerLoading(false)
      return res
    }).catch((err) => {
      setContainerLoading(false)
      throw err
    })
  }

  const scanMethods = {
    isLoading: movementUnitNumber.isLoading || containerLoading,
    handleVariantScan,
    handleContainerScan
  }

  const isLoading = (
    movementDetail.isLoading ||
    movementUpdate.isLoading ||
    movementUpdateAndComplete.isLoading
  )

  const initialValues = useMemo(() => {
    return MovementInitialValuesSerializer(movementDetail.detail, movementLineItems.results)
  }, [movementDetail.detail, movementLineItems.results])

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      activeNav={NAV.MOVEMENT}
      breadcrumbs={breadcrumbs}
    >
      <MovementUpdateForm
        pageTitle={pageTitle}
        initialValues={initialValues}
        pageTitleLoading={movementDetail.isLoading}
        isLoading={isLoading}
        onSubmit={handleSubmit}
        scanMethods={scanMethods}
      />
    </DashboardLayout>
  )
}

export default MovementScanUpdateContainer
