import React from 'react'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import { path, prop } from 'ramda'
import Pagination from 'storfox-table/src/Table/Pagination'
import Paper from '@mui/material/Paper'
import { useTranslation } from 'react-i18next'
import TableRow from '@mui/material/TableRow'
import PropTypes from 'prop-types'

import SkeletonList from '~/components/Skeleton'
import VerticalAlignment from '~/components/VerticalAlignment'
import Avatar from '~/components/Avatar/Avatar'

function LocationContent ({ unitList }) {
  const { t } = useTranslation()

  const list = prop('results', unitList)
  const count = prop('count', unitList)
  const isLoading = prop('isLoading', unitList)

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableCell />
            <TableCell>{t('Variant')}</TableCell>
            <TableCell>{t('Unit number')}</TableCell>
            <TableCell>{t('Quantity')}</TableCell>
            <TableCell>{t('Container')}</TableCell>
          </TableHead>
          <TableBody>
            {isLoading
              ? <SkeletonList columnCount={5} rowCount={5} height={20} />
              : list.map(item => {
                const guid = prop('guid', item)
                const variantName = path(['variant', 'name'], item)
                const variantSku = path(['variant', 'sku'], item)
                const variantImage = path(['variant', 'defaultImage'], item)
                const unitNumber = prop('unitNumber', item)

                const quantity = prop('quantity', item)
                const container = path(['container', 'number'], item)

                return (
                  <TableRow key={guid}>
                    <TableCell>
                      <Avatar src={variantImage} alt={variantName} />
                    </TableCell>
                    <TableCell>
                      <VerticalAlignment
                        primary={variantName}
                        secondary={variantSku}
                      />
                    </TableCell>
                    <TableCell>{unitNumber}</TableCell>
                    <TableCell>{quantity}</TableCell>
                    <TableCell>{container}</TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination count={count} prefix="" />
    </Paper>
  )
}

LocationContent.propTypes = {
  unitList: PropTypes.object
}

export default LocationContent
