import React from 'react'
import PropTypes from 'prop-types'

function Compose ({ components, children }) {
  return (
    <>
      {components.reduceRight((acc, Component) => {
        return <Component>{acc}</Component>
      }, children)}
    </>
  )
}

Compose.propTypes = {
  components: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
}

export default Compose
