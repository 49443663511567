import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import { path } from 'ramda'
import Grid from '@mui/material/Grid'

import { withForm } from '~/components/Form'
import { Button, DiscardButton } from '~/components/Buttons'
import DialogTitle from '~/components/DialogTitle'
import ConstrainedField from '~/components/Fields/ConstrainedField'

import AlterTypeField from './AlterPicking/AlterTypeField'

function AlterPickingDialog ({ open, handleClose, form, isLoading }) {
  const { handleSubmit, values } = form
  const unitName = path(['unit', 'variant', 'name'], values)

  return (
    <Dialog
      open={open}
      fullWidth={true}
      onClose={handleClose}
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          <Typography
            component="h2"
            variant="h6"
          >
            Alter picking for {unitName}
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ minHeight: '30vh', paddingTop: '10px !important' }}>
          <Grid container={true} spacing={3}>
            <Grid item={true} lg={12}>
              <AlterTypeField />
            </Grid>
            <Grid item={true} lg={12}>
              <ConstrainedField
                label="Quantity"
                name="alterQuantity"
                data-cy="alterQuantity"
                allowEmpty={true}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DiscardButton
            onClick={handleClose}
            fullWidth={true}
          >
            Discard
          </DiscardButton>

          <Button
            variant="contained"
            fullWidth={true}
            type="submit"
            data-cy="done"
            disabled={isLoading}
          >
            Done
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

AlterPickingDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  form: PropTypes.object,
  isLoading: PropTypes.bool
}

export default withForm(AlterPickingDialog)
