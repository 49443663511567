import React from 'react'
import PropTypes from 'prop-types'
import { map, path, pathOr, prop, propOr } from 'ramda'
import { useTranslation } from 'react-i18next'

import PageTitle from '~/components/PageTitle'
import TableDateFormat from '~/components/TableDateFormat'
import Tags from '~/components/Tags'
import TableNotes from '~/components/TableNotes'
import Container, { Content, Header } from '~/components/Container'
import Channel from '~/components/Channel'
import TextOverflow from '~/components/TextOverflow'
import { Table, TableHeader } from '~/components/Table'
import { AllocationStatus, FulfillmentProgress, SaleOrderStatus, ShipmentStatus } from '~/components/Statuses'
import * as NAV from '~/constants/nav-titles'
import CurrencyMoney from '~/components/Money/CurrencyMoney'
import Button from '~/components/Buttons/Button'

export const SALE_ORDER_COLUMNS = [
  {
    width: 125,
    headerName: 'SO #',
    field: 'number',
    renderCell: ({ value }) => {
      return (
        <TextOverflow lines={1} selfTooltip={true}>
          {value}
        </TextOverflow>
      )
    }
  },
  {
    width: 125,
    headerName: 'Reference #',
    field: 'referenceNumber',
    renderCell: ({ value, row }) => (
      <TextOverflow
        tooltip={`Reference number from ${row.channelName}`}
        lines={1}
      >
        {value}
      </TextOverflow>
    )
  },
  {
    width: 75,
    headerName: 'Channel',
    field: 'channel',
    ordering: true,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    renderCell: ({ value }) => (
      <Channel value={value} />
    ),
  },
  {
    width: 150,
    headerName: 'Line items',
    field: 'lineItemsCount',
    headerAlign: 'center',
    align: 'center',
  },
  {
    width: 175,
    headerName: 'Total',
    field: 'totalPrice',
    renderCell: ({ value, row }) => (
      <CurrencyMoney currency={row.currency} value={value} />
    )
  },
  {
    width: 125,
    headerName: 'Status',
    field: 'status',
    renderCell: ({ value }) => (
      <SaleOrderStatus value={value} />
    )
  },
  {
    width: 200,
    headerName: 'Allocation status',
    field: 'allocationStatus',
    renderCell: ({ value }) => (
      <AllocationStatus value={value} />
    )
  },
  {
    width: 200,
    headerName: 'Shipment status',
    field: 'shipmentStatus',
    renderCell: ({ value }) => (
      <ShipmentStatus value={value} />
    )
  },
  {
    width: 300,
    headerName: 'Fulfillment progress',
    field: 'progress',
    renderCell: ({ value }) => (
      <FulfillmentProgress status={value} />
    ),
    sortable: false
  },
  {
    width: 200,
    headerName: 'Customer',
    field: 'customer',
    renderCell: ({ value }) => (
      <TextOverflow selfTooltip={true}>
        {value}
      </TextOverflow>
    )
  },
  {
    width: 150,
    headerName: 'City',
    field: 'city'
  },
  {
    width: 200,
    headerName: 'Warehouse',
    field: 'warehouse'
  },
  {
    width: 150,
    headerName: 'Company',
    field: 'company',
    renderCell: ({ value }) => (
      <TextOverflow selfTooltip={true}>{value}</TextOverflow>
    )
  },
  {
    width: 200,
    headerName: 'Order date',
    field: 'createdAt',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  },
  {
    width: 200,
    headerName: 'Last updated',
    field: 'updatedAt',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  },
  {
    width: 200,
    headerName: 'Confirmation date',
    field: 'confirmedAt',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  },
  {
    width: 300,
    headerName: 'Tags',
    field: 'tags',
    sortable: false,
    renderCell: ({ value }) => (
      <Tags items={value} />
    )
  },
  {
    width: 125,
    field: 'notes',
    headerAlign: 'center',
    align: 'center',
    headerName: 'Notes',
    sortable: false,
    renderCell: ({ value }) => (
      <TableNotes>
        {value}
      </TableNotes>
    )
  }
]

const getRowsFromResults = map(result => {
  const id = prop('id', result)
  const guid = prop('guid', result)
  const number = prop('number', result)

  const channelName = pathOr('channel', ['channel', 'name'], result)
  const referenceNumber = prop('referenceNumber', result)

  const totalPrice = prop('totalPrice', result)
  const currency = prop('currency', result)

  const channel = path(['channel', 'type'], result)
  const lineItemsCount = prop('lineItemsCount', result)
  const progress = prop('fulfillmentStatus', result)
  const status = prop('status', result)
  const allocationStatus = prop('allocationStatus', result)
  const shipmentStatus = prop('shipmentStatus', result)
  const warehouse = path(['warehouse', 'name'], result)
  const company = path(['company', 'name'], result)
  const customerFirstName = path(['customer', 'firstName'], result)
  const customerLastName = path(['customer', 'lastName'], result)
  const customer = `${customerFirstName} ${customerLastName}`
  const city = path(['deliveryAddress', 'city'], result)
  const createdAt = prop('createdAt', result)
  const updatedAt = prop('updatedAt', result)
  const notes = prop('customerNotes', result)
  const tags = propOr([], 'tags', result)
  const confirmedAt = prop('confirmedAt', result)

  return {
    id,
    guid,
    number,
    channelName,
    referenceNumber,
    channel,
    lineItemsCount,
    currency,
    totalPrice,
    status,
    allocationStatus,
    shipmentStatus,
    progress,
    customer,
    city,
    warehouse,
    company,
    createdAt,
    updatedAt,
    tags,
    notes,
    confirmedAt
  }
})

function SaleOrderTable (props) {
  const {
    list,
    filter,
    ordering,
    onReportGenerate,
    onListRefetch
  } = props

  const { t } = useTranslation()
  const isLoading = prop('isLoading', list)
  const results = prop('results', list)
  const hasNextPage = prop('hasNextPage', list)
  const hasPrevPage = prop('hasPrevPage', list)
  const count = prop('count', list)
  const columns = prop('filteredColumns', ordering)

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={NAV.SALE_ORDER_REPORT}
          parentTitle={NAV.REPORTS}
          rightButton={(
            <Button
              variant="contained"
              data-cy="create"
              type="button"
              onClick={onReportGenerate}
            >
              {t('Generate Report')}
            </Button>
          )}
        />
      </Header>

      <Content>
        <TableHeader
          filter={filter}
          ordering={ordering}
          isLoading={isLoading}
          filterOpen={filter.handleOpen}
          orderingOpen={ordering.handleOpen}
        />
        <Table
          isLoading={isLoading}
          columns={columns}
          getRowsFromResults={getRowsFromResults}
          results={results}
          count={count}
          pinnedColumns={['number']}
          primaryKey="guid"
          ordering={ordering}
          checkboxSelection={true}
          onRefetch={onListRefetch}
          isNewPagination={true}
          hasNextPage={hasNextPage}
          hasPrevPage={hasPrevPage}
        />
      </Content>
    </Container>
  )
}

SaleOrderTable.propTypes = {
  list: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  onReportGenerate: PropTypes.func.isRequired,
  onListRefetch: PropTypes.func.isRequired
}

export default SaleOrderTable
