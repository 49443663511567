import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'

const LineStyled = styled(Box)(({ color }) => ({
  backgroundColor: color,
  position: 'absolute',
  left: 0,
  top: 0,
  bottom: 0,
  width: 5,
  height: 48,
  borderTopRightRadius: '20px',
  borderBottomRightRadius: '20px',
}))

function ColoredStatusBox (props) {
  const {
    children,
    color
  } = props

  return (
    <>
      <LineStyled color={color} />
      {children}
    </>
  )
}

ColoredStatusBox.propTypes = {
  children: PropTypes.any,
  color: PropTypes.string.isRequired
}

export default ColoredStatusBox
