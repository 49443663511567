import React from 'react'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import DialogContent from '@mui/material/DialogContent'
import { Grid } from '@mui/material'
import DialogActions from '@mui/material/DialogActions'
import { useTranslation } from 'react-i18next'
import { propOr } from 'ramda'
import { useSnackbar } from 'storfox-snackbar'

import { safeJsonParse } from '~/utils'
import useMessages from '~/hooks/useMessages'

import IntegrationHistoryDialogTable from './IntegrationHistoryDialogTable'

import DialogTitle from '../../DialogTitle/DialogTitle'
import Button from '../../Buttons/Button'
import MobileFullWidthDialog from '../../MobileFullWidthDialog'

function IntegrationHistoryDialog ({ open, onClose, selected }) {
  const { t } = useTranslation()
  const message = useMessages()
  const snackbar = useSnackbar()

  const request = propOr('', 'request', selected)
  const response = propOr('', 'response', selected)

  const JsonRequest = safeJsonParse(request)
  const JsonResponse = safeJsonParse(response)

  const requestHeader = propOr('', 'headers', JsonRequest)
  const requestHeaderJson = safeJsonParse(requestHeader, {})
  const requestBody = propOr('', 'body', JsonRequest)
  const requestBodyJson = safeJsonParse(requestBody, {})
  const requestUrl = propOr('', 'url', JsonRequest)

  const requestMethod = propOr('', 'method', JsonRequest)

  const responseHeader = propOr('', 'headers', JsonResponse)
  const responseHeaderJson = safeJsonParse(responseHeader, {})
  const responseBody = propOr('', 'body', JsonResponse)
  const responseBodyJson = safeJsonParse(responseBody, {})

  const handleCopyJson = copy => {
    const copySrc = propOr('', 'src', copy)
    navigator.clipboard.writeText(JSON.stringify(copySrc))
    snackbar({ message: message.COPY_SUCCESS })
  }

  return (
    <MobileFullWidthDialog open={open} onClose={onClose}>
      <DialogTitle>
        <Grid container={true}>
          <Grid item={true} xs={12}>
            <Typography variant="h4">
              {t('Action')}
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers={true}>
        <Grid container={true}>
          <Grid item={true} xs={12} md={6}>
            <IntegrationHistoryDialogTable
              header="Request"
              jsonHeader={requestHeaderJson}
              jsonBody={requestBodyJson}
              onJsonCopy={handleCopyJson}
              url={`${requestMethod} ${requestUrl}`}
            />
          </Grid>
          <Grid item={true} xs={12} md={6}>
            <IntegrationHistoryDialogTable
              header="Response"
              jsonHeader={responseHeaderJson}
              jsonBody={responseBodyJson}
              onJsonCopy={handleCopyJson}
              url={`${requestMethod} ${requestUrl}`}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          variant="outlined"
          onClick={onClose}
        >
          Close
        </Button>
      </DialogActions>
    </MobileFullWidthDialog>
  )
}

IntegrationHistoryDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selected: PropTypes.object.isRequired
}

export default IntegrationHistoryDialog
