import React from 'react'
import { useFilter, useOrdering } from 'storfox-filter'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import ErrorLink from '~/components/ErrorLink'
import useMessages from '~/hooks/useMessages'
import { useTableSelects } from '~/components/TableValues/hooks'

import { useCustomerList, useCustomersDelete } from '../hooks'
import {
  CustomerFilterForm,
  customerFilterOptions,
  CustomerOrderingForm,
  customerOrderingOptions,
  CustomerTable
} from '../components/CustomerList'

function CustomerListContainer () {
  const snackbar = useSnackbar()
  const messages = useMessages()

  const customerList = useCustomerList()
  const customersDelete = useCustomersDelete()
  const { selects, resetTableSelects } = useTableSelects()

  const filter = useFilter(customerFilterOptions)
  const ordering = useOrdering(customerOrderingOptions)

  const breadcrumbs = { title: NAV.CUSTOMERS }

  const handleCustomerDelete = () =>
    customersDelete.delete({ guids: selects })
      .then(() => snackbar({ message: messages.DELETE_SUCCESS }))
      .then(() => resetTableSelects())
      .then(() => customerList.getList())
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  return (
    <DashboardLayout
      title={NAV.CUSTOMERS}
      activeNav={NAV.CUSTOMERS}
      breadcrumbs={breadcrumbs}
    >
      <CustomerFilterForm {...filter} />
      <CustomerOrderingForm {...ordering} />

      <CustomerTable
        list={customerList}
        filter={filter}
        ordering={ordering}
        onListRefetch={customerList.getListByParams}
        onCustomersDelete={handleCustomerDelete}
        bulkActionLoading={customersDelete.isLoading}
      />
    </DashboardLayout>
  )
}

export default CustomerListContainer
