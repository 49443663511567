import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { map, path, prop } from 'ramda'
import { generatePath } from 'react-router-dom'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'

import Container, { Content, Header } from '~/components/Container'
import PageTitle from '~/components/PageTitle'
import Money from '~/components/Money'
import TableNotes from '~/components/TableNotes'
import { MoreListButton, NewButton } from '~/components/Buttons'
import TableDateFormat from '~/components/TableDateFormat'
import { Table, TableHeader } from '~/components/Table'
import * as ROUTES from '~/constants/routes'
import TableLink from '~/components/Link'
import { TRANSFER_DETAIL_TABS } from '~/constants/tabs'

import BulkActionButton from './BulkActionButton'
import TransferStatus from './TransferStatus'

import { COMPLETED, IN_PROGRESS } from '../../constants'

const getDetailPath = guid => {
  return generatePath(ROUTES.TRANSFER_DETAIL_PATH, { guid, tab: TRANSFER_DETAIL_TABS.GENERAL })
}

export const TRANSFER_COLUMNS = [
  {
    width: 100,
    headerName: '#',
    field: 'number',
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        {value}
      </TableLink>
    )
  },
  {
    width: 100,
    headerName: 'Line items',
    field: 'lineItems'
  },
  {
    width: 100,
    headerName: 'Qty',
    field: 'quantity'
  },
  {
    width: 150,
    headerName: 'Value',
    field: 'value',
    renderCell: ({ value }) => (
      <Money value={value} />
    )
  },
  {
    width: 200,
    headerName: 'From warehouse',
    field: 'fromWarehouse'
  },
  {
    width: 200,
    headerName: 'To warehouse',
    field: 'toWarehouse'
  },
  {
    width: 150,
    headerName: 'Status',
    field: 'status',
    renderCell: ({ value }) => (
      <TransferStatus status={value} />
    )
  },
  {
    width: 150,
    headerName: 'Expected date',
    field: 'expectedDate',
    renderCell: ({ value }) => (
      <TableDateFormat date={value} withTime={true} />
    )
  },
  {
    width: 150,
    headerName: 'Created date',
    field: 'created',
    renderCell: ({ value }) => (
      <TableDateFormat date={value} withTime={true} />
    )
  },
  {
    width: 150,
    headerName: 'Updated date',
    field: 'updatedAt',
    renderCell: ({ value }) => (
      <TableDateFormat date={value} withTime={true} />
    )
  },
  {
    width: 125,
    headerName: 'Notes',
    field: 'notes',
    renderCell: ({ value }) => (
      <TableNotes>{value}</TableNotes>
    )
  }
]

const getRowsFromResults = map(result => {
  const id = prop('id', result)
  const guid = prop('guid', result)
  const number = prop('number', result)
  const lineItems = prop('lineItemsCount', result)
  const quantity = prop('totalQuantity', result)
  const value = prop('totalPrice', result)
  const fromWarehouse = path(['fromWarehouse', 'name'], result)
  const toWarehouse = path(['toWarehouse', 'name'], result)
  const status = prop('status', result)
  const expectedDate = prop('expectedDate', result)
  const updatedAt = prop('updatedAt', result)
  const created = prop('createdAt', result)
  const notes = prop('notes', result)
  const link = getDetailPath(guid)

  return {
    id,
    guid,
    number,
    lineItems,
    quantity,
    value,
    fromWarehouse,
    toWarehouse,
    status,
    expectedDate,
    updatedAt,
    created,
    notes,
    link
  }
})

function TransferTable (props) {
  const {
    filter,
    ordering,
    pageTitle,
    parentTitle,
    transferList,
    onProcess,
    onComplete,
    onListRefetch,
    onTransfersDelete,
    onCancel
  } = props
  const [tableRef, setTableRef] = useState(null)
  const results = prop('results', transferList)
  const isLoading = prop('isLoading', transferList)
  const count = prop('count', transferList)
  const columns = prop('filteredColumns', ordering)

  const actionColumns = useMemo(() => [
    ...columns,
    {
      width: 80,
      resizable: false,
      headerName: 'Actions',
      field: 'actions',
      type: 'actions',
      getActions: ({ row }) => {
        const status = prop('status', row)
        const cancelDisable = status === IN_PROGRESS || status === COMPLETED

        return [
          <GridActionsCellItem
            label="Cancel"
            disabled={cancelDisable}
            onClick={() => onCancel(row.guid)}
            showInMenu={true}
          />
        ]
      }
    }
  ], [columns, onCancel])

  return (
    <Container>
      <Header>
        <PageTitle
          parentTitle={parentTitle}
          pageTitle={pageTitle}
          rightButton={(
            <>
              <BulkActionButton
                onProcess={onProcess}
                onComplete={onComplete}
                onTransfersDelete={onTransfersDelete}
              />
              <NewButton path={ROUTES.TRANSFER_CREATE_PATH} />
            </>
          )}
        />
      </Header>
      <Content>
        <TableHeader
          filter={filter}
          filterOpen={filter.handleOpen}
          orderingOpen={ordering.handleOpen}
          ordering={ordering}
        >
          <MoreListButton tableRef={tableRef} />
        </TableHeader>
        <Table
          count={count}
          getRowsFromResults={getRowsFromResults}
          results={results}
          checkboxSelection={true}
          columns={actionColumns}
          isLoading={isLoading}
          primaryKey="guid"
          ordering={ordering}
          pinnedColumns={['number']}
          onRefetch={onListRefetch}
          setTableRef={setTableRef}
        />
      </Content>
    </Container>
  )
}

TransferTable.propTypes = {
  filter: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  parentTitle: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  transferList: PropTypes.object,
  onProcess: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  onTransfersDelete: PropTypes.func.isRequired,
  onListRefetch: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}

export default TransferTable
