import React from 'react'
import { prop } from 'ramda'
import PropTypes from 'prop-types'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { useTranslation } from 'react-i18next'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'

import TextField from '~/components/Fields/TextField'
import VerticalAlignment from '~/components/VerticalAlignment'
import Subtext from '~/components/Subtext'
import Tooltip from '~/components/HtmlTooltip'
import DeleteIconButton from '~/components/DeleteIconButton'
import TextOverflow from '~/components/TextOverflow'
import ConstrainedField from '~/components/Fields/ConstrainedField'
import BorderTableRow from '~/components/BorderTableRow'

import AvatarProductPreview from '../AvatarProductPreview'

function VariantPreview (props) {
  const { activeLineItem, serialNumberRef, fieldName, onBarcodeFocus, onPreviewDelete, maxValue } = props
  const { t } = useTranslation()

  const unit = prop('unit', activeLineItem)
  const variant = prop('variant', unit)

  const name = prop('name', variant)
  const barcode = prop('unitNumber', unit)
  const sku = prop('sku', variant)
  const trackSerial = prop('trackSerialNumbers', unit)

  const handleEnter = event => {
    if (event.key === 'Enter') {
      onBarcodeFocus()
      event.preventDefault()
    }
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('Image')}</TableCell>
            <TableCell>{t('Product')}</TableCell>
            <TableCell>{t('Barcode')}</TableCell>
            <TableCell>{t('Serial number')}</TableCell>
            <TableCell colSpan={2}>{t('Scanned')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {variant ? (
            <BorderTableRow isLastRow={true}>
              <TableCell style={{ cursor: 'pointer' }}>
                <AvatarProductPreview unit={unit} />
              </TableCell>
              <TableCell>
                <Tooltip title={name}>
                  <VerticalAlignment
                    primary={<TextOverflow selfTooltip={true} lines={1}>{name}</TextOverflow>}
                    secondary={<Subtext lines={1}>{sku}</Subtext>}
                  />
                </Tooltip>
              </TableCell>
              <TableCell>
                <TextOverflow selfTooltip={true} lines={1}>
                  {barcode}
                </TextOverflow>
              </TableCell>
              <TableCell>
                <TextField
                  name={`${fieldName}.unit.serialNumber`}
                  data-cy={`${fieldName}.unit.serialNumber`}
                  fullWidth={true}
                  autoFocus={true}
                  inputRef={serialNumberRef}
                  onKeyDown={handleEnter}
                  disabled={!trackSerial}
                />
              </TableCell>
              <TableCell>
                <ConstrainedField
                  name={`${fieldName}.selectedQuantity`}
                  data-cy={`${fieldName}.selectedQuantity`}
                  onKeyDown={handleEnter}
                  disabled={trackSerial}
                  minValue={0}
                  maxValue={maxValue}
                />
              </TableCell>
              <TableCell align="right">
                <DeleteIconButton onClick={() => onPreviewDelete(variant)} />
              </TableCell>
            </BorderTableRow>
          ) : (
            <BorderTableRow isLastRow={true}>
              <TableCell height={56} colSpan={8}>Empty</TableCell>
            </BorderTableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

VariantPreview.propTypes = {
  fieldName: PropTypes.string.isRequired,
  serialNumberRef: PropTypes.object.isRequired,
  onBarcodeFocus: PropTypes.func.isRequired,
  onPreviewDelete: PropTypes.func.isRequired,
  activeLineItem: PropTypes.object,
  maxValue: PropTypes.number
}

export default VariantPreview
