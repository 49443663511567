import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { includes, isNil } from 'ramda'
import Grid from '@mui/material/Grid'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import ArrowBackIos from '@mui/icons-material/ArrowBackIos'

import DialogTitle from '~/components/DialogTitle/DialogTitle'
import Button from '~/components/Buttons/Button'

import SkeletonList from '../ImageUpload/SkeletonList'
import ImageItem from '../Image'
import Dropzone from '../Dropzone'

function ImageAttachDialog (props) {
  const {
    onAttach,
    onClose,
    open,
    onDrop,
    images,
    onChange,
    isLoading
  } = props

  const [select, setSelect] = useState([])

  const handleAttach = () => {
    const newFiles = images
      .filter((item, index) => includes(index, select))
      .map(({ imagePath }) => ({ imagePath }))

    onChange(newFiles)
    onAttach(newFiles)
  }

  const handleSelect = index => {
    const selected = !isNil(select.find(item => item === index))

    if (selected) {
      const newSelect = select.filter(i => i !== index)
      setSelect(newSelect)
    } else {
      const newSelect = [...select, index]
      setSelect(newSelect)
    }
  }

  const handleUpload = useCallback(async (values) => {
    await onDrop(values)
    values.map((file, index) => {
      setSelect(prevSelect => [...prevSelect, images.length + index])
    })
  }, [onDrop, images])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      fullWidth={true}
    >
      <DialogTitle>Product Images</DialogTitle>
      <DialogContent>
        {isLoading
          ? <SkeletonList count={8} xs={4} md={3} />
          : <Grid container={true} spacing={3} sx={{ margin: '0px -12px' }}>
            {images.map((item, index) => {
              return (
                <Grid item={true} xs={4} md={3} key={index}>
                  <ImageItem
                    file={item}
                    selected={!isNil(select.find(item => item === index))}
                    onSelect={() => handleSelect(index)}
                  />
                </Grid>
              )
            })}
            <Grid item={true} xs={4} md={3}>
              <Dropzone onDrop={handleUpload} />
            </Grid>
          </Grid>
        }
      </DialogContent>
      <DialogActions>
        <Grid container={true} spacing={3} sx={{ margin: '0px' }}>
          <Grid item={true} xs={6}>
            <Button
              variant="outlined"
              onClick={onClose}
              sx={{ width: '100%' }}
              startIcon={<ArrowBackIos />}
            >
              Discard
            </Button>
          </Grid>
          <Grid item={true} xs={6}>
            <Button
              variant="contained"
              type="submit"
              disabled={select.length === 0}
              onClick={handleAttach}
              startIcon={<AttachFileIcon />}
              sx={{ width: '100%' }}
            >
              Attach
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

ImageAttachDialog.propTypes = {
  onDrop: PropTypes.func,
  onAttach: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  images: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default ImageAttachDialog
