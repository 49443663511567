import { pick, prop } from 'ramda'

import { CONDITION_FIELDS } from '../../components/Condition/ConditionCreateForm/ConditionCreateForm'

export const ConditionCreateSerializer = formValues => {
  const values = pick(CONDITION_FIELDS, formValues)
  const warranty = parseInt(prop('warranty', formValues))

  return { ...values, warranty }
}
