import React from 'react'
import { SelectField } from 'storfox-query-builder'

import { OPTIONS } from '~/components/Fields/AllocationStatusField'

function AllocationStatusField (props) {
  return (
    <SelectField
      options={OPTIONS}
      controlSx={{
        width: 'auto',
        minWidth: 160
      }}
      {...props}
    />
  )
}

export default AllocationStatusField
