import React from 'react'
import { pick, prop } from 'ramda'

import * as API from '~/constants/api'
import WarehouseSearchField from '~/components/Fields/WarehouseSearchField'

const WAREHOUSE_ADDRESS_FIELDS = [
  'contactFirstName',
  'contactLastName',
  'contactPhoneNumber',
  'address',
  'region',
  'city',
  'zipcode',
  'country'
]

const getOptionValue = value => {
  if (value) {
    const id = prop('id', value)
    const guid = prop('guid', value)
    const name = prop('name', value)
    const addressValues = pick(WAREHOUSE_ADDRESS_FIELDS, value)

    return { ...addressValues, id, guid, name }
  }

  return null
}

function ReturnWarehouseSearchField (props) {
  return (
    <WarehouseSearchField
      api={API.RETURN_WAREHOUSE_LIST}
      getOptionValue={getOptionValue}
      ListboxProps={{ 'data-cy': 'warehouseList' }}
      {...props}
    />
  )
}

export default ReturnWarehouseSearchField
