import React from 'react'
import PropTypes from 'prop-types'

import Status from '~/components/Status'

import { ADJUSTMENT_STATUS } from '../../constants'

const COLOR = {
  [ADJUSTMENT_STATUS.DRAFT]: 'warning',
  [ADJUSTMENT_STATUS.COMPLETED]: 'success',
  [ADJUSTMENT_STATUS.CANCELLED]: 'error',
}

function AdjustmentStatus ({ value }) {
  return (
    <Status
      label={value}
      color={COLOR[value]}
    />
  )
}

AdjustmentStatus.propTypes = {
  value: PropTypes.oneOf([
    ADJUSTMENT_STATUS.DRAFT,
    ADJUSTMENT_STATUS.COMPLETED,
    ADJUSTMENT_STATUS.CANCELLED
  ]).isRequired
}

export default AdjustmentStatus
