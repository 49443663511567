import React from 'react'
import { TextField as StorfoxTextField } from 'storfox-form-fields'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

function TextField ({ label, ...props }) {
  const { t } = useTranslation()

  return (
    <StorfoxTextField
      label={t(label)}
      {...props}
    />
  )
}

TextField.propTypes = {
  label: PropTypes.string,
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
  variant: PropTypes.string
}

export default TextField
