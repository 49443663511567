import MuiCssBaseline from './MuiCssBaseline'
import MuiButton from './MuiButton'
import MuiPickersCalendarHeader from './MuiPickersCalendarHeader'
import MuiCheckbox from './MuiCheckbox'
import MuiCardActions from './MuiCardActions'
import MuiCardContent from './MuiCardContent'
import MuiCardHeader from './MuiCardHeader'
import MuiChip from './MuiChip'
import MuiDivider from './MuiDivider'
import MuiIconButton from './MuiIconButton'
import MuiInputBase from './MuiInputBase'
import MuiLink from './MuiLink'
import MuiLinearProgress from './MuiLinearProgress'
import MuiListItem from './MuiListItem'
import MuiListItemIcon from './MuiListItemIcon'
import MuiOutlinedInput from './MuiOutlinedInput'
import MuiPaper from './MuiPaper'
import MuiTableCell from './MuiTableCell'
import MuiTableHead from './MuiTableHead'
import MuiTableRow from './MuiTableRow'
import MuiToggleButton from './MuiToggleButton'
import MuiTypography from './MuiTypography'
import MuiTab from './MuiTab'
import MuiInputLabel from './MuiInputLabel'
import MuiTabs from './MuiTabs'
import MuiTextField from './MuiTextField'
import MuiSelect from './MuiSelect'
import MuiSwitch from './MuiSwitch'
import MuiAvatar from './MuiAvatar'
import MuiPickersDay from './MuiPickersDay'
import StorfoxTable from './StorfoxTable'
import StorfoxSidebar from './StorfoxSidebar'
import StorfoxHeader from './StorfoxHeader'
import StorfoxListPanel from './StorfoxListPanel'
import StorfoxMenuCard from './StorfoxMenuCard'

export default primaryColor => ({
  MuiCssBaseline,
  MuiButton,
  MuiCardActions,
  MuiCardContent,
  MuiCardHeader,
  MuiChip,
  MuiDivider,
  MuiIconButton,
  MuiPickersCalendarHeader,
  MuiInputBase,
  MuiLinearProgress,
  MuiListItem,
  MuiLink,
  MuiListItemIcon,
  MuiOutlinedInput,
  MuiPaper,
  MuiTableCell,
  MuiTableHead,
  MuiTableRow,
  MuiToggleButton,
  MuiTypography,
  MuiTab,
  MuiTabs,
  MuiTextField,
  MuiPickersDay,
  MuiInputLabel,
  MuiSelect,
  MuiSwitch,
  MuiAvatar,
  MuiCheckbox,
  StorfoxTable,
  StorfoxSidebar: StorfoxSidebar(primaryColor),
  StorfoxHeader,
  StorfoxListPanel,
  StorfoxMenuCard
})
