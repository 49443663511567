import { useState, useCallback, useEffect } from 'react'
import { includes, prop, filter } from 'ramda'

export const useBarcodeSelect = (results, primaryKey = 'id', selects) => {
  const [selectedRows, setSelectedRows] = useState([])

  const getSelectedRows = () => {
    const newSelectedRows = filter(result => includes(prop(primaryKey, result), selects), results)
    setSelectedRows(newSelectedRows)
  }

  const handleSelectedRows = useCallback(getSelectedRows, [selects, results])

  useEffect(() => {
    handleSelectedRows()
  }, [handleSelectedRows])

  return { selectedRows }
}
