import React, { useEffect } from 'react'
import { generatePath, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { prop } from 'ramda'

import AppContainer from '~/components/AppContainer'
import { useToken } from '~/components/Token'
import { useCompany, useMe, useProfile } from '~/components/Profile'
import { set as setLanguage } from '~/components/Language'
import * as ROUTES from '~/constants/routes'
import { OVERVIEW_TABS } from '~/constants/tabs'

import { useLogin } from '../hooks'
import SignIn from '../components/SignIn'

const defaultState = {
  from: { pathname: generatePath(ROUTES.OVERVIEW_PATH, { tab: OVERVIEW_TABS.SALES }) }
}

function SignInContainer () {
  const navigate = useNavigate()
  const location = useLocation()
  const { i18n } = useTranslation()
  const { login, isLoading } = useLogin()
  const { from } = location.state || defaultState

  const { getMe } = useMe()
  const { token, setToken } = useToken()
  const { setProfile } = useProfile()
  const { isThreePl } = useCompany()

  const tab = isThreePl ? OVERVIEW_TABS.FULFILLMENT : OVERVIEW_TABS.SALES
  const redirectTo = generatePath(ROUTES.OVERVIEW_PATH, { tab })

  useEffect(() => {
    if (token) {
      navigate(redirectTo)
    }
  }, [token, navigate, redirectTo])

  const handleSubmit = formValues =>
    login(formValues)
      .then((res) => {
        const token2fa = prop('token2fa', res)
        if (token2fa) {
          const redirectTo = generatePath(ROUTES.AUTH_2_FACTOR_PATH, { token: token2fa })
          navigate(redirectTo)
        } else {
          const token = prop('token', res)
          setToken(token)
          getMe().then(({ result }) => {
            const language = prop('language', result)
            i18n.changeLanguage(language)
              .then(() => setLanguage(language))
            setProfile(result)
            navigate(from, { replace: true })
          })
        }
      })

  return (
    <AppContainer title="Sign In">
      <SignIn
        isLoading={isLoading}
        onSubmit={handleSubmit}
        from={from}
      />
    </AppContainer>
  )
}

export default SignInContainer
