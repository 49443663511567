import React from 'react'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { prop } from 'ramda'
import PropTypes from 'prop-types'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import HistoryIcon from '@mui/icons-material/History'
import IconButton from '@mui/material/IconButton'
import Pagination from 'storfox-table/src/Table/Pagination'
import TableContainer from '@mui/material/TableContainer'

import { getFormattedDateTime } from '~/utils'
import { HISTORY_FORMAT } from '~/constants/dateFormat'
import EmptyLineItems from '~/components/EmptyLineItems/EmptyLineItems'

import HistoryLink from './HistoryLink'

import SkeletonList from '../Skeleton/SkeletonList'

function UserHistoryTable ({ rows, isLoading, count, onClick }) {
  const { t } = useTranslation()

  const renderEmptyLines = Boolean(!isLoading && !rows.length)
  const renderLineItems = Boolean(!isLoading && rows.length)

  return (
    <Box component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto' }}>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '25%' }}>{t('Date')}</TableCell>
              <TableCell sx={{ width: '25%' }}>{t('Link')}</TableCell>
              <TableCell sx={{ width: '40%' }}>{t('Description')}</TableCell>
              <TableCell sx={{ width: '10%' }}>{t('Action')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && <SkeletonList rowCount={3} columnCount={4} />}
            {renderEmptyLines && <EmptyLineItems colSpan={4} />}
            {renderLineItems && rows.map((row, index) => {
              const dateTime = getFormattedDateTime(prop('createdAt', row), HISTORY_FORMAT)
              const description = prop('description', row)
              const entity = prop('entity', row)
              const objectId = prop('objectId', row)
              const objectGuid = prop('objectGuid', row)
              const objectNumber = prop('objectNumber', row)
              const changes = prop('changes', row)

              return (
                <TableRow key={index}>
                  <TableCell>{dateTime}</TableCell>
                  <TableCell>
                    <HistoryLink
                      entity={entity}
                      id={objectId}
                      guid={objectGuid}
                    >
                      {objectNumber}
                    </HistoryLink>
                  </TableCell>
                  <TableCell>
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => onClick(changes)} size="large">
                      <HistoryIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination count={count} prefix="" />
    </Box>
  )
}

UserHistoryTable.defaultProps = {
  rows: []
}

UserHistoryTable.propTypes = {
  rows: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default UserHistoryTable
