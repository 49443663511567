import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import ReportListContainer from './containers/ReportListContainers/ReportListContainer'
import StockAgingListContainer from './containers/StockAgingContainers/StockAgingListContainer'
import SalesMarginListContainer from './containers/SalesMarginContainers/SalesMarginListContainer'
import TplBillingListContainer from './containers/TplBillingContainers/TplBillingListContainer'
import TplBillingDetailContainer from './containers/TplBillingContainers/TplBillingDetailContainer'
import TplBillingItemContainer from './containers/TplBillingContainers/TplBillingItemContainer'
import SalesOverviewListContainer from './containers/SalesOverviewContainers/SalesOverviewListContainer'
import TeamMetricsListContainer from './containers/TeamMetricsContainers/TeamMetricsListContainer'
import StorageReportDetailContainer from './containers/StorageReportContainers/StorageReportDetailContainer'
import SaleOrderListContainer from './containers/SaleOrderContainers/SaleOrderListContainer'
import InventoryListContainer from './containers/InventoryContainers/InventoryListContainer'
import UnitReportListContainer from './containers/UnitReportContainers/UnitReportListContainer'
import RetailerUnitReportListContainer from './containers/RetailerUnitReportContainers/RetailerUnitReportListContainer'
import LocationUnitReportListContainer from './containers/UnitLocationReportContainers/LocationUnitReportListContainer'
import ShipmentListContainer from './containers/ShipmentContainers/ShipmentListContainer'
import UnitHistoryReportContainer from './containers/UnitHistoryReport/UnitHistoryReportContainer'
import PurchaseListContainer from './containers/PurchaseContainers/PurchaseListContainer'
import OrderUnitsListContainer from './containers/OrderUnitsContainers/OrderUnitsListContainer'

const stockAging = () => [
  {
    layout: AuthLayout,
    path: ROUTES.REPORT_STOCK_AGING_LIST_PATH,
    component: StockAgingListContainer
  }
]

const salesMargin = () => [
  {
    layout: AuthLayout,
    path: ROUTES.REPORT_SALES_MARGIN_LIST_PATH,
    component: SalesMarginListContainer
  }
]

const tplBilling = () => [
  {
    component: TplBillingListContainer,
    path: ROUTES.TPL_BILLING_LIST_PATH,
    layout: AuthLayout
  },
  {
    component: TplBillingDetailContainer,
    path: ROUTES.TPL_BILLING_DETAIL_PATH,
    layout: AuthLayout
  },
  {
    component: TplBillingItemContainer,
    path: ROUTES.TPL_BILLING_ITEM_PATH,
    layout: AuthLayout
  }
]

const salesOverview = () => [
  {
    layout: AuthLayout,
    path: ROUTES.SALES_OVERVIEW_LIST_PATH,
    component: SalesOverviewListContainer
  }
]

const teamMetrics = () => [
  {
    layout: AuthLayout,
    path: ROUTES.TEAM_METRICS_LIST_PATH,
    component: TeamMetricsListContainer
  }
]

const storageReport = () => [
  {
    layout: AuthLayout,
    path: ROUTES.STORAGE_REPORT_PATH,
    component: StorageReportDetailContainer
  }
]

const saleOrder = () => [
  {
    layout: AuthLayout,
    path: ROUTES.SALE_ORDER_REPORT_LIST_PATH,
    component: SaleOrderListContainer
  }
]

const inventory = () => [
  {
    layout: AuthLayout,
    path: ROUTES.INVENTORY_REPORT_LIST_PATH,
    component: InventoryListContainer
  }
]

const unit = () => [
  {
    layout: AuthLayout,
    path: ROUTES.UNIT_REPORT_LIST_PATH,
    component: UnitReportListContainer
  }
]

const shipment = () => [
  {
    layout: AuthLayout,
    path: ROUTES.SHIPMENT_REPORT_LIST_PATH,
    component: ShipmentListContainer
  }
]

const unitHistory = () => [
  {
    layout: AuthLayout,
    path: ROUTES.UNIT_HISTORY_REPORT_LIST_PATH,
    component: UnitHistoryReportContainer
  }
]

const purchase = () => [
  {
    layout: AuthLayout,
    path: ROUTES.PURCHASE_REPORT_LIST_PATH,
    component: PurchaseListContainer
  }
]

const orderUnits = () => [
  {
    layout: AuthLayout,
    path: ROUTES.ORDER_UNITS_REPORT_LIST_PATH,
    component: OrderUnitsListContainer
  }
]

const retailerUnit = () => [
  {
    layout: AuthLayout,
    path: ROUTES.RETAILER_UNIT_REPORT_LIST_PATH,
    component: RetailerUnitReportListContainer
  }
]

const locationUnit = () => [
  {
    layout: AuthLayout,
    path: ROUTES.LOCATION_UNIT_REPORT_LIST_PATH,
    component: LocationUnitReportListContainer
  }
]

export default () => [
  {
    layout: AuthLayout,
    path: ROUTES.REPORT_LIST_PATH,
    component: ReportListContainer
  },
  ...stockAging(),
  ...salesMargin(),
  ...tplBilling(),
  ...salesOverview(),
  ...teamMetrics(),
  ...storageReport(),
  ...saleOrder(),
  ...inventory(),
  ...unit(),
  ...shipment(),
  ...unitHistory(),
  ...purchase(),
  ...orderUnits(),
  ...retailerUnit(),
  ...locationUnit()
]
