import { outlinedInputClasses } from '@mui/material/OutlinedInput'

import palette from '~/theme/dark/palette'

export default {
  styleOverrides: {
    root: {
      '&.Mui-focused': {
        borderColor: 'white',
        [`& .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: palette.text.primary
        }
      }
    },
    notchedOutline: {
      borderColor: palette.text.secondary
    }
  }
}
