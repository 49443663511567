import React, { useState } from 'react'
import { useField } from 'react-final-form'
import { path, pathOr, prop, propOr } from 'ramda'
import { generatePath } from 'react-router-dom'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import { Divider } from '@mui/material'
import { sprintf } from 'sprintf-js'
import { useRequest } from 'storfox-api-hooks'

import * as ROUTES from '~/constants/routes'
import useDialog from '~/hooks/useDialog'
import { CUSTOMER_DETAIL_TABS } from '~/constants/tabs'
import EmptyContent from '~/components/EmptyContent/EmptyContent'
import { EmptyCustomerIcon } from '~/components/Icons'
import * as API from '~/constants/api'

import CustomerDialogForm from './CustomerDialogForm'
import CustomerAddresses from './CustomerAddresses'

import CustomerInfoRow from '../CustomerInfoRow'
import CustomerCreateSearchField from '../Fields/CustomerCreateSearchField'

function SaleOrderCustomerInfo ({ onCustomerCreate }) {
  const request = useRequest()
  const [loading, setLoading] = useState(false)
  const { open, handleOpen, handleClose } = useDialog()
  const customerField = useField('customer')
  const onCustomerChange = customerField.input.onChange
  const customer = customerField.input.value
  const customerGuid = prop('guid', customer)
  const deliveryAddresses = propOr([], 'deliveryAddresses', customer)
  const billingAddresses = propOr([], 'billingAddresses', customer)

  const deliveryAddressField = useField('deliveryAddress')
  const onDeliveryAddressChange = deliveryAddressField.input.onChange
  const deliveryAddress = deliveryAddressField.input.value

  const billingAddressField = useField('billingAddress')
  const onBillingAddressChange = billingAddressField.input.onChange
  const billingAddress = billingAddressField.input.value

  const companyField = useField('company')
  const companyId = pathOr('', ['input', 'value', 'id'], companyField)

  const link = customer && generatePath(
    ROUTES.CUSTOMER_DETAIL_PATH,
    { guid: customerGuid, tab: CUSTOMER_DETAIL_TABS.GENERAL }
  )

  const handleAddressSelect = (event, value) => {
    if (value) {
      setLoading(true)
      const guid = prop('guid', value)
      const url = sprintf(API.CUSTOMER_DETAIL, guid)
      request.get(url).then((response) => {
        const result = path(['data', 'result'], response)
        const deliveryAddress = path(['deliveryAddresses', 0], result)
        const billingAddress = path(['billingAddresses', 0], result)
        onDeliveryAddressChange(deliveryAddress)
        onBillingAddressChange(billingAddress)
        onCustomerChange(result)
        setLoading(false)
      })
    }
  }

  const handleCustomerCreate = values =>
    onCustomerCreate(values)
      .then(({ result }) => {
        handleAddressSelect(null, result)
        return handleClose()
      })

  return (
    <>
      <CustomerDialogForm
        open={open}
        onClose={handleClose}
        initialValues={{ open }}
        onSubmit={handleCustomerCreate}
      />

      <CustomerCreateSearchField
        error={customerField.meta.invalid}
        helperText={customerField.meta.submitError}
        onChange={handleAddressSelect}
        onClick={handleOpen}
        name="searchCustomer"
        ListboxProps={{ 'data-cy': 'customerList' }}
        params={{ companyId }}
        isLoading={loading}
      />

      <Box mt={2} mb={2}>
        <Divider />
      </Box>

      {customer ? (
        <>
          <CustomerInfoRow customer={customer} link={link} />
          <Box mt={2}>
            <CustomerAddresses
              billingAddress={billingAddress}
              deliveryAddress={deliveryAddress}
              customerDeliveryAddresses={deliveryAddresses}
              customerBillingAddresses={billingAddresses}
              onBillingAddressChange={onBillingAddressChange}
              onDeliveryAddressChange={onDeliveryAddressChange}
            />
          </Box>
        </>
      ) : (
        <EmptyContent
          icon={<EmptyCustomerIcon />}
          text="A customer is not added"
          pt={9}
          pb={9}
        />
      )}

    </>
  )
}

SaleOrderCustomerInfo.propTypes = {
  onCustomerCreate: PropTypes.func.isRequired
}

export default SaleOrderCustomerInfo
