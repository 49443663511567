import React from 'react'
import { useField } from 'react-final-form'
import { path, prop, propOr } from 'ramda'
import { generatePath } from 'react-router-dom'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import { Divider } from '@mui/material'

import * as ROUTES from '~/constants/routes'
import useDialog from '~/hooks/useDialog'
import { SUPPLIER_DETAIL_TABS } from '~/constants/tabs'
import { EmptyCustomerIcon } from '~/components/Icons'

import SupplierAddresses from './SupplierAddresses'
import SupplierDialogForm from './SupplierDialogForm'
import SupplierInfoRow from './SupplierInfoRow'
import SupplierCreateSearchField from './SupplierSearchCreateField'
import SupplierEmptyContent from './SupplierEmptyContent'

function PurchaseReturnSupplierInfo ({ onSupplierCreate }) {
  const { open, handleClose, handleOpen } = useDialog()
  const lineItemsField = useField('lineItems')
  const lineItemsChange = lineItemsField.input.onChange
  const supplierField = useField('supplier')
  const onSupplierChange = supplierField.input.onChange
  const supplier = supplierField.input.value
  const supplierGuid = prop('guid', supplier)
  const deliveryAddresses = propOr([], 'deliveryAddresses', supplier)
  const billingAddresses = propOr([], 'billingAddresses', supplier)

  const deliveryAddressField = useField('deliveryAddress')
  const onDeliveryAddressChange = deliveryAddressField.input.onChange
  const deliveryAddress = deliveryAddressField.input.value

  const billingAddressField = useField('billingAddress')
  const onBillingAddressChange = billingAddressField.input.onChange
  const billingAddress = billingAddressField.input.value

  const params = { guid: supplierGuid, tab: SUPPLIER_DETAIL_TABS.GENERAL }
  const link = supplierGuid && generatePath(ROUTES.SUPPLIER_DETAIL_PATH, params)

  const handleAddressSelect = (event, value) => {
    if (value) {
      const deliveryAddress = path(['deliveryAddresses', 0], value)
      const billingAddress = path(['billingAddresses', 0], value)
      onDeliveryAddressChange(deliveryAddress)
      onBillingAddressChange(billingAddress)
      onSupplierChange(value)
      lineItemsChange([])
    }
  }

  const handleSupplierCreate = values =>
    onSupplierCreate({ ...values, status: 'active' })
      .then(({ result }) => {
        handleAddressSelect(null, result)

        return handleClose()
      })

  return (
    <>
      <SupplierDialogForm
        open={open}
        onClose={handleClose}
        initialValues={{ open }}
        onSubmit={handleSupplierCreate}
      />

      <SupplierCreateSearchField
        error={supplierField.meta.invalid}
        helperText={supplierField.meta.submitError}
        onChange={handleAddressSelect}
        onClick={handleOpen}
        name="searchSupplier"
      />

      <Box mt={2} mb={2}>
        <Divider />
      </Box>
      {supplier
        ? (
          <>
            <SupplierInfoRow supplier={supplier} link={link} />

            <Box mt={2}>
              <SupplierAddresses
                billingAddress={billingAddress}
                deliveryAddress={deliveryAddress}
                supplierDeliveryAddresses={deliveryAddresses}
                supplierBillingAddresses={billingAddresses}
                onBillingAddressChange={onBillingAddressChange}
                onDeliveryAddressChange={onDeliveryAddressChange}
              />
            </Box>
          </>)
        : (
          <SupplierEmptyContent
            icon={<EmptyCustomerIcon />}
            text="A customer is not added"
            pt={9}
            pb={9}
          />
        )}
    </>
  )
}

PurchaseReturnSupplierInfo.propTypes = {
  onSupplierCreate: PropTypes.func.isRequired
}

export default PurchaseReturnSupplierInfo
