import { useLimitedCountList, useCreate, useDetail, useList, useRequest, useUpdate } from 'storfox-api-hooks'
import { useAllSearchParams } from 'storfox-route-hooks'
import { sprintf } from 'sprintf-js'

import { queryToParams } from '~/utils'
import * as API from '~/constants/api'

import { PutawayFilterSerializer } from '../serializers'

export const usePutawayList = () => {
  const searchParams = useAllSearchParams()
  const params = queryToParams(PutawayFilterSerializer, searchParams)

  const { getList, ...state } = useLimitedCountList(API.V1_PUTAWAY_LIST, params)

  return { ...state, getList: () => getList(params) }
}

export const usePutawayDetail = guid => {
  const url = sprintf(API.V1_PUTAWAY_DETAIL, guid)
  return useDetail(url)
}
export const usePutawayAssignTo = guid => {
  const url = sprintf(API.V1_PUTAWAY_ASSIGN_TO, guid)
  const { update, ...state } = useUpdate(url)
  return { ...state, assign: update }
}

export const usePutawayProgress = guid => {
  const url = sprintf(API.V1_PUTAWAY_PROGRESS, guid)
  const { update, ...state } = useUpdate(url)
  return { ...state, progress: update }
}

export const usePutawayComplete = guid => {
  const url = sprintf(API.V1_PUTAWAY_COMPLETE, guid)
  const { update, ...state } = useUpdate(url)
  return { ...state, complete: update }
}

export const usePutawayCreate = () => {
  return useCreate(API.V1_PUTAWAY_CREATE)
}

export const usePutawayScanLocation = () => {
  const request = useRequest()

  return {
    scan: (warehouseGuid, id) => {
      const url = sprintf(API.V1_PUTAWAY_LOCATION, warehouseGuid, id)
      return request.get(url)
    }
  }
}

export const usePutawayLineItems = guid => {
  const url = sprintf(API.V1_PUTAWAY_LINE_ITEMS, guid)
  return useDetail(url)
}

export const usePutawayItemPut = guid => {
  const url = sprintf(API.V1_PUTAWAY_ITEM_PUT, guid)

  return useUpdate(url)
}

export const usePutawayHistory = (guid, autoSend) => {
  const url = sprintf(API.V2_PUTAWAY_HISTORY, guid)
  const searchParams = useAllSearchParams()

  return useList(url, searchParams, autoSend)
}

export const useWarehouseList = () => {
  return useList(API.WAREHOUSE_LIST, { limit: 1000 })
}
