import React, { useCallback } from 'react'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { pathOr, prop, propOr } from 'ramda'
import PropTypes from 'prop-types'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import TableContainer from '@mui/material/TableContainer'
import { generatePath } from 'react-router-dom'
import { ListItemButton, styled } from '@mui/material'

import EmptyNotesIcon from '~/components/Icons/EmptyNotesIcon'
import EmptyLineItems from '~/components/EmptyLineItems/EmptyLineItems'
import SkeletonList from '~/components/Skeleton'
import * as ROUTES from '~/constants/routes'
import { HoverableRow } from '~/components/Table'
import { MoreButton } from '~/components/Buttons/index'
import TableLink from '~/components/Link'
import { generateCdnUrl } from '~/utils'
import Button from '~/components/Buttons/Button'
import { useCompany } from '~/components/Profile'

const TableRowStyled = styled(HoverableRow)({
  height: '70px'
})

const TableCellStyled = styled(TableCell)({
  minWidth: '150px'
})

function ContractUpdateInvoiceTable ({ invoiceList, contractGuid, onGenerateInvoice, onGenerateExcel, onMarkAsPaid }) {
  const { t } = useTranslation()
  const { isThreePl } = useCompany()

  const isLoading = prop('isLoading', invoiceList)
  const results = prop('results', invoiceList)

  const renderEmptyLines = Boolean(!isLoading && !results.length)
  const renderLineItems = Boolean(!isLoading && results.length)

  const handleGenerateInvoice = useCallback((guid, invoiceUrl) => {
    if (invoiceUrl) {
      window.open(generateCdnUrl(invoiceUrl), '_blank')
    } else {
      onGenerateInvoice(guid)
    }
  }, [onGenerateInvoice])

  return (
    <Box component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto' }}>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCellStyled>{t('ID')}</TableCellStyled>
              <TableCellStyled>{t('Invoice')}</TableCellStyled>
              <TableCellStyled>{t('Customer name')}</TableCellStyled>
              <TableCellStyled>{t('Billing profile')}</TableCellStyled>
              <TableCellStyled>{t('Period')}</TableCellStyled>
              <TableCellStyled>{t('Status')}</TableCellStyled>
              <TableCellStyled>{t('Action')}</TableCellStyled>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <SkeletonList
                rowCount={3}
                columnCount={13}
                height={20}
                rowHeight={70}
              />
            )}

            {renderEmptyLines && (
              <EmptyLineItems
                colSpan={24}
                icon={<EmptyNotesIcon />}
                text="No invoice for this contract"
              />
            )}
            {renderLineItems && results.map((row, index) => {
              const guid = prop('guid', row)
              const id = prop('id', row)
              const period = prop('period', row)
              const invoiceUrl = propOr('', 'invoiceUrl', row)
              const paymentStatus = prop('paymentStatus', row)
              const clientName = pathOr('', ['client', 'name'], row)
              const profileName = pathOr('', ['profile', 'name'], row)

              const billingUrl = generatePath(ROUTES.CONTRACT_INVOICE_DETAIL_PATH, { contractGuid, guid })

              return (
                <TableRowStyled key={index}>
                  <TableCell>
                    <TableLink to={billingUrl}>{id}</TableLink>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="text"
                      size="small"
                      disabled={!invoiceUrl}
                      onClick={() => window.open(generateCdnUrl(invoiceUrl), '_blank')}
                    >
                      Download
                    </Button>
                  </TableCell>
                  <TableCell>{clientName}</TableCell>
                  <TableCell>{profileName}</TableCell>
                  <TableCell>{period}</TableCell>
                  <TableCell>{paymentStatus}</TableCell>
                  <TableCell>
                    <MoreButton>
                      {() => (
                        <>
                          <ListItemButton onClick={() => handleGenerateInvoice(guid, invoiceUrl)}>
                            Generate invoice receipt
                          </ListItemButton>
                          <ListItemButton onClick={() => onGenerateExcel(guid, invoiceUrl)}>
                            Generate excel
                          </ListItemButton>
                          {isThreePl && (
                            <ListItemButton onClick={() => onMarkAsPaid(guid, invoiceUrl)}>
                              Mark as paid
                            </ListItemButton>
                          )}
                        </>
                      )}
                    </MoreButton>
                  </TableCell>
                </TableRowStyled>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

ContractUpdateInvoiceTable.propTypes = {
  invoiceList: PropTypes.object.isRequired,
  contractGuid: PropTypes.string,
  onGenerateInvoice: PropTypes.func,
  onGenerateExcel: PropTypes.func,
  onMarkAsPaid: PropTypes.func
}

export default ContractUpdateInvoiceTable
