import { useAllSearchParams } from 'storfox-route-hooks'
import { useCreate, useLimitedCountList } from 'storfox-api-hooks'

import { queryToParams } from '~/utils'
import * as API from '~/constants/api'

import { UnitFilterSerializer } from '../../serializers/Inventory'

export const useUnitReportList = () => {
  const searchParams = useAllSearchParams()

  const params = queryToParams(UnitFilterSerializer, searchParams, 'guid')

  return useLimitedCountList(API.UNIT_NUMBER_LIST, params)
}

export const useUnitExportGenerate = () => {
  const searchParams = useAllSearchParams()
  const params = queryToParams(UnitFilterSerializer, searchParams, 'guid')
  const { create, ...state } = useCreate(API.UNIT_EXPORT)

  return { ...state, export: () => create(params) }
}
