import React from 'react'
import { useFilter, useOrdering } from 'storfox-filter'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'

import { useWarehouseList } from '../hooks'
import {
  WarehouseFilterForm,
  warehouseFilterOptions,
  WarehouseOrderingForm,
  warehouseOrderingOptions,
  WarehouseTable
} from '../components/WarehouseList'

function WarehouseListContainer () {
  const warehouseList = useWarehouseList()

  const filter = useFilter(warehouseFilterOptions)
  const ordering = useOrdering(warehouseOrderingOptions)

  const breadcrumbs = { title: NAV.WAREHOUSES }

  return (
    <DashboardLayout
      title={NAV.WAREHOUSES}
      activeNav={NAV.WAREHOUSE}
      breadcrumbs={breadcrumbs}
    >
      <WarehouseFilterForm {...filter} />
      <WarehouseOrderingForm {...ordering} />

      <WarehouseTable
        filter={filter}
        ordering={ordering}
        list={warehouseList}
        onListRefetch={warehouseList.getListByParams}
      />
    </DashboardLayout>
  )
}

export default WarehouseListContainer
