import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { join, map, path, pathOr, pipe, prop, propOr, uniq } from 'ramda'
import { generatePath } from 'react-router-dom'

import { MoreListButton, NewButton } from '~/components/Buttons'
import PageTitle from '~/components/PageTitle'
import TableDateFormat from '~/components/TableDateFormat'
import Container, { Content, Header } from '~/components/Container'
import { Table, TableHeader } from '~/components/Table'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import { PUTAWAY_DETAIL_TABS } from '~/constants/tabs'
import TableLink from '~/components/Link'
import { toDuration } from '~/utils'
import TextOverflow from '~/components/TextOverflow'

import PutawayStatus from './PutawayStatus'

export const getUniqOrderNumbers = pipe(
  propOr([], 'fromObjectNumbers'),
  uniq,
  join(',')
)

const getDetailPath = guid => {
  const params = { guid, tab: PUTAWAY_DETAIL_TABS.GENERAL }
  return generatePath(ROUTES.V2_PUTAWAY_DETAIL_PATH, params)
}

export const PUTAWAY_COLUMNS = [
  {
    width: 150,
    headerName: 'ID',
    field: 'number',
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        {value}
      </TableLink>
    )
  },
  {
    width: 150,
    headerName: 'From numbers',
    field: 'fromObjectNumbers',
    renderCell: ({ value }) => (
      <TextOverflow selfTooltip={true}>
        {value}
      </TextOverflow>
    )
  },
  {
    width: 100,
    headerName: 'Line Items',
    field: 'lineItems',
  },
  {
    width: 100,
    headerName: 'Qty',
    field: 'qty',
  },
  {
    width: 150,
    headerName: 'Status',
    field: 'status',
    renderCell: ({ value }) => (
      <PutawayStatus value={value} />
    )
  },
  {
    width: 200,
    headerName: 'From location',
    field: 'fromLocation'
  },
  {
    width: 200,
    headerName: 'Warehouse',
    field: 'warehouse'
  },
  {
    width: 250,
    headerName: 'Assigned to',
    field: 'assignedTo'
  },
  {
    width: 150,
    headerName: 'Duration',
    field: 'duration'
  },
  {
    width: 150,
    headerName: 'Created date',
    field: 'createdAt',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  }
]

const getRowsFromResults = map(item => {
  const id = prop('id', item)
  const guid = prop('guid', item)
  const number = prop('number', item)
  const fromObjectNumbers = getUniqOrderNumbers(item)
  const fromLocation = path(['fromLocation', 'locationId'], item)
  const warehouse = path(['fromLocation', 'warehouse', 'name'], item)
  const lineItems = propOr(0, 'lineItemCount', item)
  const assignedTo = pathOr('Not Assigned', ['assignedTo', 'firstName'], item)
  const status = prop('status', item)
  const duration = toDuration(prop('duration', item))
  const createdAt = prop('createdAt', item)
  const updatedAt = prop('updatedAt', item)
  const qty = propOr(0, 'lineItemQuantity', item)
  const link = getDetailPath(guid)

  return {
    id,
    guid,
    fromObjectNumbers,
    lineItems,
    number,
    status,
    qty,
    fromLocation,
    assignedTo,
    duration,
    createdAt,
    updatedAt,
    link,
    warehouse
  }
})

function PutawayTable (props) {
  const {
    list,
    filter,
    ordering,
    onNewCreate,
    onListRefetch
  } = props
  const [tableRef, setTableRef] = useState(null)
  const isLoading = prop('isLoading', list)
  const count = prop('count', list)
  const columns = prop('filteredColumns', ordering)
  const results = prop('results', list)

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={NAV.PUTAWAY}
          parentTitle={NAV.STOCK}
          rightButton={(
            <NewButton onClick={onNewCreate} />
          )}
        />
      </Header>

      <Content>
        <TableHeader
          filter={filter}
          filterOpen={filter.handleOpen}
          orderingOpen={ordering.handleOpen}
          ordering={ordering}
        >
          <MoreListButton tableRef={tableRef} />
        </TableHeader>
        <Table
          getRowsFromResults={getRowsFromResults}
          results={results}
          columns={columns}
          isLoading={isLoading}
          count={count}
          primaryKey="guid"
          pinnedColumns={['number']}
          checkboxSelection={true}
          ordering={ordering}
          rowHeight={70}
          onRefetch={onListRefetch}
          setTableRef={setTableRef}
        />
      </Content>
    </Container>
  )
}

PutawayTable.propTypes = {
  list: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  onNewCreate: PropTypes.func.isRequired,
  onListRefetch: PropTypes.func.isRequired
}

export default PutawayTable
