import React from 'react'
import { prop } from 'ramda'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import { useField } from 'react-final-form'

function CompanyUpdateFormUsers () {
  const ownerField = useField('owner')
  const owner = ownerField.input.value
  const firstName = prop('firstName', owner)
  const lastName = prop('lastName', owner)
  const email = prop('email', owner)

  return (
    <TableContainer component={Paper} sx={{ mt: '30px' }}>
      <Table sx={{ minWidth: '900px' }}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Roles</TableCell>
            <TableCell>Warehouse</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow >
            <TableCell align="left">
              {firstName} {lastName}
            </TableCell>
            <TableCell>
              {email}
            </TableCell>
            <TableCell>
              Account owner
            </TableCell>
            <TableCell>
              Default Warehouse
            </TableCell>
            <TableCell>
              Active
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default CompanyUpdateFormUsers
