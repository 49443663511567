import React from 'react'
import { generatePath, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { prop } from 'ramda'
import { Divider } from '@mui/material'
import Box from '@mui/material/Box'

import * as ROUTES from '~/constants/routes'
import { Button, EditButton } from '~/components/Buttons'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import * as NAV from '~/constants/nav-titles'
import LoaderProvider from '~/components/Loader'
import { CANCELLED, COMPLETED, PROCESSING, NEW_ORDER } from '~/components/Statuses/SaleOrderStatus'
import * as ALLOCATION from '~/components/Statuses/AllocationStatus'
import { SUPPLIER_RETURN_TABS } from '~/constants/tabs'
import History from '~/components/History'

import ReturnDetailGeneral from './ReturnDetailGeneral'
import ReturnDetailTabs from './ReturnDetailTabs'

function ReturnDetail (props) {
  const {
    detail,
    pageTitle,
    pageTitleLoading,
    isLoading,
    onProcess,
    onAllocate,
    history
  } = props

  const { guid, tab } = useParams()

  const status = prop('status', detail)
  const allocationStatus = prop('allocationStatus', detail)

  const editDisable = (
    isLoading ||
    status !== NEW_ORDER
  )

  const processDisabled = (
    isLoading ||
    status === PROCESSING ||
    status === COMPLETED ||
    status === CANCELLED
  )

  const reallocationStatus = (
    allocationStatus === ALLOCATION.UNALLOCATED ||
    allocationStatus === ALLOCATION.ALLOCATION_PENDING ||
    allocationStatus === ALLOCATION.PARTIALLY_ALLOCATED ||
    allocationStatus === ALLOCATION.FAILED
  )

  const handleProcess = reallocationStatus ? onAllocate : onProcess

  const editPath = generatePath(ROUTES.SUPPLIER_RETURN_UPDATE_PATH, { guid })

  return (
    <LoaderProvider isLoading={isLoading}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={pageTitle}
            pageTitleLoading={pageTitleLoading}
            parentTitle={NAV.SUPPLIER_RETURNS}
            rightButton={(
              <>
                <Button
                  data-cy={reallocationStatus ? 'allocate' : 'process'}
                  variant="contained"
                  disabled={processDisabled}
                  onClick={handleProcess}
                >
                  {reallocationStatus ? 'Allocate' : 'Process'}
                </Button>
                <EditButton path={editPath} disabled={editDisable} />
              </>
            )}
          />
        </Header>
        <Content>
          <ReturnDetailTabs value={tab} />
          <Divider />
          <Box mb={3} />
          {tab === SUPPLIER_RETURN_TABS.GENERAL && (
            <ReturnDetailGeneral
              detail={detail}
              isLoading={pageTitleLoading}
            />
          )}
          {tab === SUPPLIER_RETURN_TABS.HISTORY && <History history={history} />}
        </Content>
      </Container>
    </LoaderProvider>
  )
}

ReturnDetail.propTypes = {
  detail: PropTypes.object.isRequired,
  pageTitle: PropTypes.string.isRequired,
  pageTitleLoading:PropTypes.bool,
  isLoading: PropTypes.bool,
  onProcess: PropTypes.func,
  onAllocate: PropTypes.func,
  history: PropTypes.object
}

export default ReturnDetail
