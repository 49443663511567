import { sprintf } from 'sprintf-js'
import { useAllSearchParams } from 'storfox-route-hooks'
import { useCreate, useDelete, useDetail, useList, useUpdate, useLimitedCountList } from 'storfox-api-hooks'

import { queryToParams } from '~/utils'
import * as API from '~/constants/api'

import { BundleFilterSerializer } from '../serializers/index'

export const useBundleList = () => {
  const searchParams = useAllSearchParams()
  const params = queryToParams(BundleFilterSerializer, searchParams, 'guid')
  const { getList, ...state } = useLimitedCountList(API.BUNDLE_LIST, params)

  return { ...state, getList: rest => getList({ ...rest, ...params }) }
}

export const useBundleDetail = guid => {
  const url = sprintf(API.BUNDLE_DETAIL, guid)
  return useDetail(url)
}

export const useBundleCreate = () => {
  return useCreate(API.BUNDLE_CREATE)
}

export const useBundleUpdate = guid => {
  const url = sprintf(API.BUNDLE_UPDATE, guid)
  return useUpdate(url)
}

export const useBundleCategoryCreate = () => {
  return useCreate(API.CATEGORY_CREATE)
}

export const useBundleBrandCreate = () => {
  return useCreate(API.BRAND_CREATE)
}

export const useBundlesDelete = () => {
  return useDelete(API.BUNDLES_DELETE)
}

export const useBundleChannelList = () => {
  return useList(API.VARIANT_CHANNEL_LIST, {}, false)
}

export const useBundleListingCreate = () => {
  return useCreate(API.VARIANT_LISTING_CREATE)
}
