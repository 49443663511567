import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import { SwitchField, FloatField } from 'storfox-form-fields'
import { useTranslation } from 'react-i18next'

import { CardHeader } from '~/components/Cards'
import CountryField from '~/components/Fields/CountryField'
import EmailField from '~/components/Fields/EmailField'
import PhoneNumberField from '~/components/Fields/PhoneNumberField'
import StatusField from '~/components/Fields/StatusField'
import TextField from '~/components/Fields/TextField'
import TimeZoneField from '~/components/Fields/TimeZoneField'

import ReceivingTypeField from './Fields/ReceivingTypeField'
import PickingStrategyField from './Fields/PickingStrategyField'
import ReceivingStrategyField from './Fields/ReceivingStrategyField'

export const WAREHOUSE_CONTENT_FIELDS = [
  'name',
  'label',
  'shortName',
  'email',
  'phoneNumber',
  'address',
  'country',
  'city',
  'zipcode',
  'longitude',
  'latitude',
  'contactFirstName',
  'contactLastName',
  'contactEmail',
  'contactPhoneNumber',
  'status',
  'pickingStrategy',
  'receivingStrategy',
  'timezone',
  'isVirtual',
  'isDefault',
  'partialPackingAllowed',
  'receivingType',
  'scanUniqueContainer',
  'scanUniqueLocation',
  'region'
]

function WarehouseContent () {
  const { t } = useTranslation()

  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12} lg={8}>
        <Box mb={3}>
          <Card>
            <CardHeader title="Information" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <TextField
                    data-cy="name"
                    name="name"
                    label="Warehouse name"
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <TextField
                    data-cy="label"
                    disabled={true}
                    inputProps={{ maxLength: 3 }}
                    name="label"
                    label="Label"
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <EmailField
                    data-cy="email"
                    name="email"
                    label="Email"
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <PhoneNumberField
                    data-cy="phoneNumber"
                    name="phoneNumber"
                    label="Phone number"
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <TextField
                    data-cy="address"
                    name="address"
                    label="Address"
                    multiline={true}
                    minRows={3}
                  />
                </Grid>

                <Grid item={true} xs={12} lg={6}>
                  <CountryField
                    data-cy="country"
                    name="country"
                    label="Country"
                  />
                </Grid>

                <Grid item={true} xs={12} lg={6}>
                  <TextField
                    data-cy="city"
                    name="city"
                    label="City"
                    country="country"
                  />
                </Grid>

                <Grid item={true} xs={6}>
                  <TextField
                    data-cy="zipcode"
                    name="zipcode"
                    label="Zipcode"
                  />
                </Grid>

                <Grid item={true} xs={6}>
                  <TextField
                    data-cy="region"
                    name="region"
                    label="Region"
                  />
                </Grid>

                <Grid item={true} xs={6}>
                  <FloatField
                    data-cy="longitude"
                    name="longitude"
                    label="Longitude"
                    inputProps={{ decimalScale: 30 }}
                  />
                </Grid>

                <Grid item={true} xs={6}>
                  <FloatField
                    data-cy="latitude"
                    name="latitude"
                    label="Latitude"
                    inputProps={{ decimalScale: 30 }}
                  />
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </Box>

        <Box mb={3}>
          <Card>
            <CardHeader title="Primary Contact" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12} lg={6}>
                  <TextField
                    data-cy="contactFirstName"
                    name="contactFirstName"
                    label="First name"
                  />
                </Grid>
                <Grid item={true} xs={12} lg={6}>
                  <TextField
                    data-cy="contactLastName"
                    name="contactLastName"
                    label="Last name"
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <EmailField
                    data-cy="contactEmail"
                    name="contactEmail"
                    label="Email"
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <PhoneNumberField
                    data-cy="contactPhoneNumber"
                    name="contactPhoneNumber"
                    label="Phone number"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>

      <Grid item={true} xs={12} lg={4}>
        <Box mb={3}>
          <Card>
            <CardHeader title="Settings" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <StatusField />
                </Grid>
                <Grid item={true} xs={12}>
                  <PickingStrategyField />
                </Grid>
                <Grid item={true} xs={12}>
                  <ReceivingStrategyField />
                </Grid>
                <Grid item={true} xs={12}>
                  <TimeZoneField countryName="country" />
                </Grid>
                <Grid item={true} xs={12}>
                  <ReceivingTypeField />
                </Grid>
                <Grid item={true} xs={12}>
                  <SwitchField
                    data-cy="isDefault"
                    name="isDefault"
                  />
                  {t('Default Warehouse')}
                </Grid>
                <Grid item={true} xs={12}>
                  <SwitchField
                    data-cy="partialPackingAllowed"
                    name="partialPackingAllowed"
                  />
                  {t('Allow partial packing')}
                </Grid>
                <Grid item={true} xs={12}>
                  <SwitchField
                    data-cy="scanUniqueLocation"
                    name="scanUniqueLocation"
                  />
                  {t('Scan unique location')}
                </Grid>
                <Grid item={true} xs={12}>
                  <SwitchField
                    data-cy="scanUniqueContainer"
                    name="scanUniqueContainer"
                  />
                  {t('Scan unique container')}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </Grid>
  )
}

export default WarehouseContent
