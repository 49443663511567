import React from 'react'

import SelectField from '~/components/Fields/SelectField'

import { POSTMEN_TYPES } from '../../constants'

const OPTIONS = [
  {
    value: POSTMEN_TYPES.FEDEX,
    name: 'Fedex'
  },
  {
    value: POSTMEN_TYPES.ARAMEX,
    name: 'Aramex'
  },
  {
    value: POSTMEN_TYPES.DHL,
    name: 'DHL'
  }
]
function PostmenTypeField (props) {
  return (
    <SelectField
      data-cy="type"
      name="credential.type"
      label="Postmen types"
      options={OPTIONS}
      {...props}
    />
  )
}

export default PostmenTypeField
