import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import { path, prop } from 'ramda'

import * as API from '~/constants/api'
import SearchField from '~/components/Fields/SearchField'

const getOptionValue = value => {
  if (value) {
    const id = prop('id', value)
    const guid = prop('guid', value)
    const name = prop('name', value)
    const companyName = path(['company', 'name'], value)

    return { id, guid, name, companyName }
  }

  return null
}

function WarehouseSearchField ({ name, ...props }) {
  return (
    <SearchField
      data-cy={name}
      name={name}
      api={API.WAREHOUSE_LIST}
      getOptionValue={getOptionValue}
      renderOption={(props, option) => (
        <li {...props} style={{ display: 'block' }}>
          <Typography>
            {option.name}
          </Typography>
          <Typography variant="body2">
            {option.companyName}
          </Typography>
        </li>
      )}
      {...props}
    />
  )
}

WarehouseSearchField.defaultProps = {
  name: 'warehouse',
  label: 'Warehouse'
}

WarehouseSearchField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string
}

export default WarehouseSearchField
