import React from 'react'
import { pathOr } from 'ramda'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'

import { useUnitHistoryList } from '../hooks'
import UnitHistory from '../components/UnitHistory'

function UnitHistoryContainer () {
  const unitHistoryList = useUnitHistoryList()
  const title = pathOr('Unit', [0, 'variant', 'name'], unitHistoryList.results)

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      breadcrumbs={breadcrumbs}
      isLoading={unitHistoryList.isLoading}
      activeNav={NAV.UNITS}
    >
      <UnitHistory
        pageTitle={title}
        pageTitleLoading={unitHistoryList.isLoading}
        listObject={unitHistoryList}
      />
    </DashboardLayout>
  )
}

export default UnitHistoryContainer
