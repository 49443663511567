import React from 'react'
import { prop } from 'ramda'
import PropTypes from 'prop-types'
import { useField } from 'react-final-form'
import { useDeepCompareEffect } from 'storfox-api-hooks'

import PageTitle from '~/components/PageTitle'
import { DiscardButton } from '~/components/Buttons'
import Container, { Content, Header } from '~/components/Container'
import { withForm } from '~/components/Form'
import { BarcodeGeneratorDialogForm } from '~/components/BarcodeGeneratorDialog'
import * as ROUTES from '~/constants/routes'
import useDialog from '~/hooks/useDialog'
import * as NAV from '~/constants/nav-titles'

import AdjustmentUpdateButton from './AdjustmentUpdateButton'

import { ADJUSTMENT_STATUS } from '../../constants'
import Adjustment from '../AdjustmentForm'

export const ADJUSTMENT_UPDATE_FIELDS = [
  'notes',
  'warehouse',
  'reason',
  'status',
  'amount',
  'productVariant'
]

function AdjustmentUpdateForm (props) {
  const {
    pageTitle,
    pageTitleLoading,
    form,
    isLoading,
    detailLoading,
    onGenerate,
    unitFieldMethods,
    onConditionGet,
    adjustmentVariant
  } = props

  const { initialValues, handleSubmit } = form
  const status = prop('status', initialValues)

  const disabled = (
    status === ADJUSTMENT_STATUS.CANCELLED ||
    status === ADJUSTMENT_STATUS.COMPLETED
  )
  const ownerField = useField('owner')
  const ownerValue = ownerField.input.value
  const ownerId = prop('id', ownerValue)

  const barcodeDialog = useDialog()

  useDeepCompareEffect(() => {
    onConditionGet({ isDefault: true, companyId: ownerId })
      .then(({ results }) => {
        const data = prop(0, results)
        const condition = { ...data, name: prop('code', data) }

        return form.form.change('condition', condition)
      })
  }, [ownerId])

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Container>
        <Header>
          <PageTitle
            pageTitle={pageTitle}
            pageTitleLoading={pageTitleLoading}
            parentTitle={NAV.ADJUSTMENT}
            rightButton={(
              <>
                <DiscardButton disabled={isLoading} to={ROUTES.ADJUSTMENT_LIST_PATH} />
                <AdjustmentUpdateButton
                  isLoading={isLoading || disabled}
                  onSubmit={handleSubmit}
                />
              </>
            )}
          />
        </Header>

        <Content>
          <Adjustment
            isLoading={detailLoading}
            unitFieldMethods={unitFieldMethods}
            adjustmentVariant={adjustmentVariant}
          />
          <BarcodeGeneratorDialogForm
            onGenerate={onGenerate}
            onSubmit={() => {}}
            open={barcodeDialog.open}
            initialValues={{ count: 0 }}
            onClose={barcodeDialog.handleClose}
          />
        </Content>
      </Container>
    </form>
  )
}

AdjustmentUpdateForm.propTypes = {
  pageTitle: PropTypes.node.isRequired,
  pageTitleLoading: PropTypes.bool.isRequired,
  onGenerate: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  form: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  detailLoading: PropTypes.bool.isRequired,
  onConditionGet: PropTypes.func.isRequired,
  unitFieldMethods: PropTypes.object.isRequired,
  adjustmentVariant: PropTypes.object.isRequired
}

export default withForm(AdjustmentUpdateForm)
