import React from 'react'
import PropTypes from 'prop-types'

import ShipmentStatusField from './ShipmentStatusField'

import { SHIPMENT_CHANGE_STATUS } from '../ActionFields/ActionSelectField'

export const SHIPMENT_STATUS = ['status']

function ShipmentStatusChangeAction ({ action, actionName }) {
  return (
    <>
      {action === SHIPMENT_CHANGE_STATUS && (
        <ShipmentStatusField name={actionName} />
      )}
    </>
  )
}

ShipmentStatusChangeAction.propTypes = {
  action: PropTypes.string.isRequired,
  actionName: PropTypes.string.isRequired
}

export default ShipmentStatusChangeAction
