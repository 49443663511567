import React, { useCallback, useEffect, useRef, useState } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import { equals, filter, find, isEmpty, map, not, path, pathEq, pathOr, pipe, prop, propEq } from 'ramda'
import { useField } from 'react-final-form'
import { useConfirm } from 'storfox-confirm-dialog'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import { matchContainer } from '~/utils'
import { DONE } from '~/constants/barcode'
import BarcodeDoneInstruction from '~/components/Instructions/BarcodeDoneInstruction'
import BarcodeField from '~/components/BarcodeField'
import { useScreenOutline } from '~/components/ScreenOutline'

import DestinationPreview from './DestinationPreview'
import ContainerPreviewCard from './ContainerPreviewCard'
import useActiveLineItem from './useActiveLineItem'
import PutawayPutTables from './PutawayPutTables'
import PutawayTitle from './PutawayTitle'
import SuggestedLocation from './SuggestedLocation'

import {
  decreaseUnitQuantity,
  findUnitByGuid,
  getContainerUnits,
  getContainerUnitsAfterSerialRemove,
  getNewLocationUnits,
  getSourceUnitsAfterSerialRemove,
  getUnitsFromContainer,
  increaseUnitQuantity,
  removeUnitsFromSource
} from '../../utils'
import ProductPreviewCard from '../ProductPreviewCard'

const throwError = errorMessage => {
  throw Error(errorMessage)
}

function LineItemsGroup ({ isLoading, initialValues, handleSubmit, onGetSuggestedLocations }) {
  const snackbar = useSnackbar()
  const barcodeRef = useRef(null)
  const { handleSoundedTrigger, handleSoundedErrorTrigger } = useScreenOutline()
  const onConfirm = useConfirm()
  const [suggestedLocations, setSuggestedLocations] = useState([])
  const [isSuggested, setIsSuggested] = useState(false)
  const [loading, setLoading] = useState(true)

  const [activeContainerNumber, setActiveContainerNumber] = useState(null)
  const activeContainerUnitsRef = useRef([])

  const barcodeField = useField('barcode')
  const fromLocation = prop('fromLocation', initialValues)

  const destinationField = useField('destination')
  const destination = destinationField.input.value
  const destinationOnChange = destinationField.input.onChange

  const {
    activeLineItem,
    activeLineItemGuid,
    activeItemOnChange,
    isActiveItemFullyScanned,
    getNewActiveLineItem,
    clearActiveLineItemField,
    isActiveItemWithSerialNumber,
    getIsUnitSerialFoundInsideContainer,
    getIsUnitSerialFoundOutsideContainer
  } = useActiveLineItem()

  const sourceUnitsField = useField('locationContent')
  const sourceUnitsOnChange = sourceUnitsField.input.onChange
  const sourceUnits = sourceUnitsField.input.value

  const destUnitsField = useField('units')
  const destUnitsOnChange = destUnitsField.input.onChange
  const destUnits = destUnitsField.input.value || []

  const activeItemQuantity = prop('quantity', activeLineItem)
  const activeItemTotalQuantity = prop('totalQuantity', activeLineItem)
  const activeItemTrackSerial = path(['variant', 'trackSerial'], activeLineItem)
  const activeItemUnitNumber = prop('unitNumber', activeLineItem)
  const activeItemSerialNumber = prop('serialNumber', activeLineItem)

  const focusBarcodeField = () => barcodeRef.current.focus()
  const clearBarcodeField = () => barcodeField.input.onChange('')
  const handleDestinationRemove = () => destinationOnChange(null)

  useEffect(() => {
    if (!isSuggested && sourceUnits.length > 0) {
      const variantGuid = path([0, 'variant', 'guid'], sourceUnits)
      onGetSuggestedLocations(variantGuid).then((response) => {
        const results = pathOr([], ['data', 'results'], response)
        const filteredResults = results.filter((location) => location.locationId !== fromLocation)
        setSuggestedLocations(filteredResults)
        setLoading(false)
      }).catch(() => setLoading(false))
      setIsSuggested(true)
    }
  }, [sourceUnits, isSuggested, onGetSuggestedLocations, fromLocation])

  useEffect(() => {
    if (!isLoading) {
      focusBarcodeField()
    }
  }, [isLoading])

  const removeAllUnitsInsideContainer = useCallback(containerNumber => {
    const newDestUnits = filter(unit => (
      !pathEq(['container', 'number'], containerNumber, unit)
    ), destUnits)

    const unitsInsideContainer = pipe(
      filter(pathEq(['container', 'number'], containerNumber)),
      map(unit => ({ ...unit, isOutsideContainer: false }))
    )(destUnits)

    const newSourceUnits = [...unitsInsideContainer, ...sourceUnits]

    sourceUnitsOnChange(newSourceUnits)
    destUnitsOnChange(newDestUnits)
  }, [destUnits, destUnitsOnChange, sourceUnits, sourceUnitsOnChange])

  const handleContainerUnitsRemove = useCallback(containerNumber =>
    onConfirm({ message: 'All the units inside this container will be removed' })
      .agree(() => {
        removeAllUnitsInsideContainer(containerNumber)
      })
      .disagree()
  , [onConfirm, removeAllUnitsInsideContainer])

  const handleUnitDelete = useCallback(item => {
    const itemGuid = prop('guid', item)
    const newDestUnits = filter(unit => !propEq('guid', itemGuid, unit), destUnits)
    const foundUnit = findUnitByGuid(itemGuid, sourceUnits)

    const trackSerial = path(['variant', 'trackSerial'], item)

    const containerNumber = path(['container', 'number'], item)

    if (containerNumber) {
      handleContainerUnitsRemove(containerNumber)
      return
    }

    if (foundUnit) {
      const newSourceUnits = getNewLocationUnits(item, foundUnit, sourceUnits)
      destUnitsOnChange(newDestUnits)
      sourceUnitsOnChange(newSourceUnits)
    }

    if (!foundUnit || trackSerial) {
      destUnitsOnChange(newDestUnits)
      sourceUnitsOnChange([item, ...sourceUnits])
    }
  }, [destUnits, destUnitsOnChange, handleContainerUnitsRemove, sourceUnits, sourceUnitsOnChange])

  const scanUnitWithoutSerial = (guid, units) => {
    const fromUnit = findUnitByGuid(guid, units)
    const unitGuid = prop('guid', fromUnit)
    const newActiveLineItem = getNewActiveLineItem(fromUnit)

    if (equals(guid, activeLineItemGuid)) {
      const newQuantity = activeItemQuantity + 1
      activeItemOnChange({ ...activeLineItem, quantity: newQuantity })
      handleSoundedTrigger()
      return
    }

    if (equals(guid, unitGuid)) {
      const unitsMergedActiveItem = getUnitsMergedActiveItem()
      if (!unitsMergedActiveItem) {
        return
      }

      sourceUnitsOnChange(unitsMergedActiveItem.sourceUnits)
      destUnitsOnChange(unitsMergedActiveItem.destUnits)
      activeItemOnChange(newActiveLineItem)
      handleSoundedTrigger()
    }
  }

  const validateUnitSerialInSourceAndValid = () => {
    if (activeItemTrackSerial && !activeItemSerialNumber) {
      throwError('Check serial number in active unit.')
    }

    const isUnitSerialFoundOutsideContainer = getIsUnitSerialFoundOutsideContainer(sourceUnits)
    const isUnitSerialFoundInsideContainer = getIsUnitSerialFoundInsideContainer(sourceUnits, activeContainerNumber)

    const isWrongSerialNumberInsideContainer = (
      activeContainerNumber &&
      !isUnitSerialFoundInsideContainer &&
      isActiveItemWithSerialNumber
    )

    const isWrongSerialNumberOutsideContainer = (
      !activeContainerNumber &&
      !isUnitSerialFoundOutsideContainer &&
      isActiveItemWithSerialNumber
    )

    if (isWrongSerialNumberInsideContainer || isWrongSerialNumberOutsideContainer) {
      throwError('Wrong serial number in active unit.')
    }
  }

  const getUnitsMergedActiveItem = () => {
    if (!activeLineItem) {
      return { sourceUnits, destUnits }
    }

    validateUnitSerialInSourceAndValid()

    if (isActiveItemWithSerialNumber) {
      const newSourceUnits = activeContainerNumber
        ? getContainerUnitsAfterSerialRemove({
          unitNumber: activeItemUnitNumber,
          serialNumber: activeItemSerialNumber,
          containerNumber: activeContainerNumber,
          units: sourceUnits
        })
        : getSourceUnitsAfterSerialRemove({
          unitNumber: activeItemUnitNumber,
          serialNumber: activeItemSerialNumber,
          units: sourceUnits
        })

      const newDestUnits = [activeLineItem, ...destUnits]

      return { sourceUnits: newSourceUnits, destUnits: newDestUnits }
    }

    const isInDestUnits = find(unit => {
      const activeLineItemGuid = prop('guid', activeLineItem)
      return propEq('guid', activeLineItemGuid, unit)
    }, destUnits)

    if (isInDestUnits) {
      const newSourceUnits = decreaseUnitQuantity(activeLineItem, sourceUnits)
      const newDestUnits = increaseUnitQuantity(activeLineItem, destUnits)

      return { sourceUnits: newSourceUnits, destUnits: newDestUnits }
    }

    const newSourceUnits = decreaseUnitQuantity(activeLineItem, sourceUnits)
    const newDestUnits = [activeLineItem, ...destUnits]

    return { sourceUnits: newSourceUnits, destUnits: newDestUnits }
  }

  const scanContainer = containerNumber => {
    const isContainerExist = find(pathEq(['container', 'number'], containerNumber), sourceUnits)

    if (!isContainerExist) {
      throwError('Container does not exist.')
    }

    const unitsMergedActiveItem = getUnitsMergedActiveItem()

    const unitsWithoutScannedContainer = filter(
      pipe(pathEq(['container', 'number'], containerNumber), not
      ), unitsMergedActiveItem.sourceUnits)

    const unitsWithScannedContainer = getContainerUnits(containerNumber, unitsMergedActiveItem.sourceUnits)
    const orderedSourceUnits = [...unitsWithScannedContainer, ...unitsWithoutScannedContainer]
    sourceUnitsOnChange(orderedSourceUnits)
    destUnitsOnChange(unitsMergedActiveItem.destUnits)
    clearActiveLineItemField()

    setActiveContainerNumber(containerNumber)
    activeContainerUnitsRef.current = unitsWithScannedContainer
    handleSoundedTrigger()
  }

  const putActiveItemToDest = () => {
    const unitsMergedActiveItem = getUnitsMergedActiveItem()
    sourceUnitsOnChange(unitsMergedActiveItem.sourceUnits)
    destUnitsOnChange(unitsMergedActiveItem.destUnits)
    clearActiveLineItemField()
    handleSoundedTrigger()
  }

  const putContainerToDest = () => {
    const scannedUnitsWithContainer = getContainerUnits(activeContainerNumber, sourceUnits)
    const newSourceUnits = removeUnitsFromSource(activeContainerNumber, sourceUnits)
    sourceUnitsOnChange(newSourceUnits)
    destUnitsOnChange([...scannedUnitsWithContainer, ...destUnits])
    setActiveContainerNumber(null)
    handleSoundedTrigger()
  }

  const canPutContainerToDest = () => {
    const containerUnits = getContainerUnits(activeContainerNumber, sourceUnits)
    const isContainerWithUnscannedUnits = equals(activeContainerUnitsRef.current.length, containerUnits.length)

    return (
      !activeLineItem &&
      activeContainerNumber &&
      isContainerWithUnscannedUnits
    )
  }

  const handleDone = () => {
    if (activeLineItem) {
      putActiveItemToDest()
    }

    if (canPutContainerToDest()) {
      putContainerToDest()
    }

    if (activeContainerNumber) {
      setActiveContainerNumber(null)
    }

    if (!activeLineItem && !activeContainerNumber) {
      handleSubmit()
      clearActiveLineItemField()
    }
  }

  const handleSerialNumberScan = serialNumber => {
    const matchedUnit = find(unit => (
      propEq('serialNumber', serialNumber, unit) &&
      propEq('unitNumber', activeItemUnitNumber, unit) &&
      !prop('container', unit)
    ), sourceUnits)

    if (matchedUnit) {
      const totalQuantity = prop('quantity', matchedUnit)
      activeItemOnChange({ ...matchedUnit, isOutsideContainer: true, totalQuantity })
      handleSoundedTrigger()
      return
    }

    throwError('Wrong serial number.')
  }

  const handleSerialNumberContainerScan = serialNumber => {
    const matchedUnit = find(unit => (
      propEq('serialNumber', serialNumber, unit) &&
      propEq('unitNumber', activeItemUnitNumber, unit) &&
      pathEq(['container', 'number'], activeContainerNumber, unit)
    ), sourceUnits)

    if (matchedUnit) {
      const totalQuantity = prop('quantity', matchedUnit)
      activeItemOnChange({ ...matchedUnit, isOutsideContainer: true, totalQuantity })
      handleSoundedTrigger()
      return
    }

    throwError('Wrong serial number.')
  }

  const handleUnitScan = (barcode, units) => {
    const unit = units.find(pathEq(['unit', 'unitNumber'], barcode))
    const unitGuid = prop('guid', unit)
    const unitContainerNumber = path(['container', 'number'], unit)
    const unitTrackSerial = path(['variant', 'trackSerial'], unit)

    if (!activeContainerNumber && unitContainerNumber) {
      setActiveContainerNumber(unitContainerNumber)
    }

    if (activeContainerNumber !== unitContainerNumber) {
      setActiveContainerNumber(unitContainerNumber)
    }

    if (!unit) {
      throwError('Unit not found. Check unit number.')
    }

    if (!unitTrackSerial && !isActiveItemFullyScanned) {
      scanUnitWithoutSerial(unitGuid, units)
      return
    }

    if (unit) {
      const unitsMergedActiveItem = getUnitsMergedActiveItem()
      const newActiveLineItem = getNewActiveLineItem(unit)
      activeItemOnChange(newActiveLineItem)
      sourceUnitsOnChange(unitsMergedActiveItem.sourceUnits)
      destUnitsOnChange(unitsMergedActiveItem.destUnits)
      handleSoundedTrigger()
      return
    }

    throwError('Unit not found. Check unit number.')
  }

  const handleBarcodeScan = event => {
    try {
      const barcode = event.target.value.trim()
      event.preventDefault()

      if (equals(barcode, DONE)) {
        handleDone(barcode)
        clearBarcodeField()
        return
      }

      if (!destination) {
        if (fromLocation !== barcode) {
          destinationOnChange(barcode)
          handleSoundedTrigger()
          clearBarcodeField()
          return
        } else {
          handleSoundedErrorTrigger()
          clearBarcodeField()
          return
        }
      }

      if (!activeContainerNumber && matchContainer(barcode)) {
        scanContainer(barcode)
        clearBarcodeField()
        return
      }

      const isSerialNumber = activeItemTrackSerial && !activeItemSerialNumber

      if (activeContainerNumber && isSerialNumber) {
        handleSerialNumberContainerScan(barcode)
        clearBarcodeField()
        return
      }

      if (!activeContainerNumber && isSerialNumber) {
        handleSerialNumberScan(barcode)
        clearBarcodeField()
        return
      }

      if (activeContainerNumber) {
        const containerUnits = getUnitsFromContainer(barcode, activeContainerNumber, sourceUnits)
        handleUnitScan(barcode, containerUnits)
        clearBarcodeField()
        return
      }

      if (sourceUnits) {
        handleUnitScan(barcode, sourceUnits)
        clearBarcodeField()
      }
    } catch (error) {
      catchError(error.message)
    }
  }

  const catchError = message => {
    handleSoundedErrorTrigger()
    snackbar({ message, type: ALTER_ERROR })
    clearBarcodeField()
  }

  const handleActiveItemPut = () => {
    try {
      putActiveItemToDest()
    } catch (error) {
      catchError(error.message)
    }
  }

  const handleActiveContainerPut = () => {
    if (canPutContainerToDest()) {
      putContainerToDest()
    }
  }

  const handleActiveContainerRemove = () => {
    setActiveContainerNumber(null)
    clearActiveLineItemField()
    focusBarcodeField()
  }

  const isContainerAblePut = canPutContainerToDest()

  return (
    <>
      <Grid container={true} spacing={2} alignItems="flex-end">
        <Grid item={true} lg={4} xs={12}>
          <PutawayTitle
            isContainerAblePut={isContainerAblePut}
            isLocationEmpty={isEmpty(destination)}
            trackSerial={Boolean(activeItemTrackSerial)}
            activeContainerNumber={activeContainerNumber}
          />
          <Box mt={2}>
            <BarcodeField
              focusBarcodeField={focusBarcodeField}
              barcodeRef={barcodeRef}
              disabled={isLoading}
              onEnter={handleBarcodeScan}
            />
          </Box>
        </Grid>

        {destination && (
          <Grid item={true} lg={4} xs={12}>
            <DestinationPreview
              destination={destination}
              onDestinationRemove={handleDestinationRemove}
            />
          </Grid>
        )}
        {!destination && (
          <Grid item={true} lg={4} xs={12}>
            <SuggestedLocation
              locations={suggestedLocations}
              isLoading={loading}
              onSelectLocation={destinationOnChange}
            />
          </Grid>
        )}

        <Grid item={true} lg={4} xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <BarcodeDoneInstruction doneText={DONE} />
          </Box>
        </Grid>
      </Grid>

      <Box mt={2} />
      <Grid container={true} spacing={3}>
        <Grid item={true} xs={12} lg={9}>
          <ProductPreviewCard
            maxValue={activeItemTotalQuantity}
            fieldName="activeLineItem"
            onActiveItemPut={handleActiveItemPut}
            onRemove={clearActiveLineItemField}
            onBarcodeFocus={focusBarcodeField}
            product={activeLineItem}
          />
        </Grid>
        <Grid item={true} xs={12} lg={3}>
          <ContainerPreviewCard
            containerNumber={activeContainerNumber}
            onContainerPut={handleActiveContainerPut}
            containerPutDisabled={!isContainerAblePut}
            onRemove={handleActiveContainerRemove}
          />
        </Grid>
      </Grid>

      <Box mt={1}>
        <PutawayPutTables
          destUnits={destUnits}
          sourceUnits={sourceUnits}
          activeContainerNumber={activeContainerNumber}
          isLoading={isLoading}
          fromLocation={fromLocation}
          onUnitDelete={handleUnitDelete}
        />
      </Box>
    </>
  )
}

LineItemsGroup.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onGetSuggestedLocations: PropTypes.func
}

export default LineItemsGroup
