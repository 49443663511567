import React from 'react'
import { useTranslation } from 'react-i18next'

import SelectField from '~/components/Fields/SelectField'

export const OVER_RECEIVE = 'over'
export const UNDER_RECEIVE = 'under'
export const OVER_UNDER_RECEIVE = 'over_under'
export const INVOICE_RECEIVE = 'invoice'
export const BLIND_RECEIVE = 'blind'

const options = [
  { value: OVER_RECEIVE, name: 'Over receive' },
  { value: UNDER_RECEIVE, name: 'Under receive' },
  { value: OVER_UNDER_RECEIVE, name: 'Over and under receive' },
  { value: INVOICE_RECEIVE, name: 'Invoice receive' },
  { value: BLIND_RECEIVE, name: 'Blind receive' },
]
function ReceivingTypeField (props) {
  const { t } = useTranslation()

  return (
    <SelectField
      data-cy="receivingType"
      name="receivingType"
      label={t('Receiving type')}
      options={options}
      {...props}
    />
  )
}

export default ReceivingTypeField
