import React from 'react'
import { path } from 'ramda'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useSnackbar } from 'storfox-snackbar'
import { useTranslation } from 'react-i18next'

import { DashboardLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'
import { USER_DETAIL_TABS } from '~/constants/tabs'

import { UserCreateForm } from '../components/UserCreate'
import { useUserDetail, useUserUpdate } from '../hooks'
import { UserCreateSerializer } from '../serializers'

function UserUpdateContainer () {
  const { t } = useTranslation()
  const { id } = useParams()
  const navigate = useNavigate()
  const userDetail = useUserDetail(id)
  const snackbar = useSnackbar()
  const userUpdate = useUserUpdate(id)

  const message = t('Successfully updated')

  const handleSubmit = formValues =>
    userUpdate.update(UserCreateSerializer(formValues))
      .then(() => snackbar({ message }))
      .then(() => {
        const path = generatePath(ROUTES.USER_DETAIL_PATH, { id, tab: USER_DETAIL_TABS.GENERAL })
        navigate(path)
      })

  const firstName = path(['detail', 'firstName'], userDetail)
  const lastName = path(['detail', 'lastName'], userDetail)
  const title = userDetail.isLoading ? 'Users' : `${firstName} ${lastName}`

  const pageLoading = userDetail.isLoading || userUpdate.isLoading

  const breadcrumbs = {
    title: 'Edit',
    params: [{ id, tab: USER_DETAIL_TABS.GENERAL, title }]
  }

  return (
    <DashboardLayout
      title={title}
      activeNav={NAV.USERS}
      isLoading={pageLoading}
      breadcrumbs={breadcrumbs}
    >
      <UserCreateForm
        title={title}
        pageTitleLoading={userDetail.isLoading}
        initialValues={userDetail.detail}
        onSubmit={handleSubmit}
        isLoading={userUpdate.isLoading || userDetail.isLoading}
      />
    </DashboardLayout>
  )
}

export default UserUpdateContainer
