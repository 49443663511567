import React, { useMemo } from 'react'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import PropTypes from 'prop-types'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import Pagination from 'storfox-table/src/Table/Pagination'
import TableContainer from '@mui/material/TableContainer'
import { path, prop } from 'ramda'
import Checkbox from '@mui/material/Checkbox/Checkbox'
import { useField } from 'react-final-form'

import EmptyNotesIcon from '~/components/Icons/EmptyNotesIcon'
import EmptyLineItems from '~/components/EmptyLineItems/EmptyLineItems'
import HistoryTableSearch from '~/components/History/HistoryTableSearch'
import SkeletonList from '~/components/Skeleton'
import Capitalize from '~/components/Text'

function StockCountLineItems ({ rows, isLoading, allChecked, onAllCheck, count, onCheck }) {
  const { t } = useTranslation()

  const locationsField = useField('locations')
  const locations = useMemo(() => locationsField.input.value, [locationsField])

  const renderEmptyLines = Boolean(!isLoading && !rows.length)
  const renderLineItems = Boolean(!isLoading && rows.length)

  return (
    <Box component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto' }}>
      <TableContainer>
        <HistoryTableSearch />
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell width={75}>
                <Checkbox checked={Boolean(allChecked && rows.length)} onChange={onAllCheck} />
              </TableCell>
              <TableCell>{t('Location')}</TableCell>
              <TableCell>{t('Location type')}</TableCell>
              <TableCell>{t('Area')}</TableCell>
              <TableCell>{t('Zone')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <SkeletonList
                rowCount={3}
                columnCount={9}
                height={25}
              />
            )}

            {renderEmptyLines && (
              <EmptyLineItems
                colSpan={4}
                icon={<EmptyNotesIcon />}
                text="No recorded history"
              />
            )}
            {renderLineItems && rows.map((location, index) => {
              const locationGuid = prop('guid', location)
              const locationName = prop('locationId', location)
              const area = path(['area', 'name'], location)
              const zone = path(['zone', 'name'], location)
              const locationType = <Capitalize>{prop('locationType', location)}</Capitalize>
              const checked = locations.find(loc => prop('guid', loc) === locationGuid)

              return (
                <TableRow key={locationName}>
                  <TableCell width={75}>
                    <Checkbox
                      data-cy={`checkbox-${index}`}
                      checked={Boolean(checked)}
                      onChange={() => onCheck(locationGuid)}
                    />
                  </TableCell>
                  <TableCell>{locationName}</TableCell>
                  <TableCell>{locationType}</TableCell>
                  <TableCell>{area}</TableCell>
                  <TableCell>{zone}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination count={count} prefix="" />
    </Box>
  )
}

StockCountLineItems.defaultProps = {
  rows: []
}

StockCountLineItems.propTypes = {
  rows: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  allChecked: PropTypes.bool.isRequired,
  onCheck: PropTypes.func.isRequired,
  onAllCheck: PropTypes.func.isRequired
}

export default StockCountLineItems
