import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { map, path, prop, propOr, replace } from 'ramda'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'
import Box from '@mui/material/Box'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'

import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import TableDateFormat from '~/components/TableDateFormat'
import { Volume, Weight } from '~/components/Converters'
import Money from '~/components/Money'
import TextOverflow from '~/components/TextOverflow'
import { Table, TableHeader } from '~/components/Table'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import { SHIPMENT_DETAIL_TABS } from '~/constants/tabs'
import CurrencyMoney from '~/components/Money/CurrencyMoney'
import useDialog from '~/hooks/useDialog'
import TableLink from '~/components/Link'
import { capitalize } from '~/utils'
import { emptyObject } from '~/constants/empty'
import { SHIPMENT_STATUS } from '~/components/Statuses/ShipmentStatus'
import { PrimaryButton } from '~/components/Buttons'

import ShipmentListSetStatusDialog from './ShipmentListSetStatusDialog'
import BulkActionButton from './BulkActionButton'
import CarrierResponse from './CarrierResponse'
import More from './More'
import ShipmentExportDialogForm from './ShipmentExportDialogForm'

import ShipmentStatus from '../ShipmentStatus'
import TrackingStatus from '../TrackingStatus'
import ProDispatchScanDialog from '../ProDispatchScanDialog'

export const SHIPMENT_COLUMNS = [
  {
    width: 150,
    headerName: 'Shipment number',
    field: 'shipmentNumber',
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        <TextOverflow selfTooltip={true} lines={1}>
          {value}
        </TextOverflow>
        {row.shippingMethodError && (
          <Box ml={1}>
            <CarrierResponse value={row.shippingMethodError} />
          </Box>
        )}
      </TableLink>
    )
  },
  {
    width: 200,
    headerName: 'Tracking number',
    field: 'trackingNumber',
    renderCell: ({ value }) => (
      <TextOverflow lines={1} selfTooltip={true}>
        {value}
      </TextOverflow>
    )
  },
  {
    width: 150,
    headerName: 'SO #',
    field: 'orderNumber'
  },
  {
    width: 150,
    headerName: 'Reference #',
    field: 'referenceNumber'
  },
  {
    width: 200,
    headerName: 'Container numbers',
    field: 'containerNumbers',
    renderCell: ({ value }) => (
      <TextOverflow lines={1} selfTooltip={true}>
        {value}
      </TextOverflow>
    )
  },
  {
    width: 150,
    headerName: 'Shipping status',
    field: 'shippingStatus',
    renderCell: ({ value }) => <ShipmentStatus value={value} />
  },
  {
    width: 150,
    headerName: 'Carrier',
    field: 'carrier'
  },
  {
    width: 150,
    headerName: 'Service',
    field: 'service'
  },
  {
    width: 150,
    headerName: 'Direction',
    field: 'direction'
  },
  {
    width: 150,
    headerName: 'Carrier status',
    field: 'carrierStatus'
  },
  {
    width: 150,
    headerName: 'Tracking status',
    field: 'shippingMethodError',
    renderCell: ({ value, row }) => <TrackingStatus value={value} row={row} />
  },
  {
    width: 200,
    headerName: 'Order date',
    field: 'objectDate',
    renderCell: ({ value }) => <TableDateFormat withTime={true} date={value} />
  },
  {
    width: 200,
    headerName: 'Shipped date',
    field: 'shippedDate',
    renderCell: ({ value }) => <TableDateFormat withTime={true} date={value} />
  },
  {
    width: 200,
    headerName: 'Delivered date',
    field: 'deliveredDate',
    renderCell: ({ value }) => <TableDateFormat withTime={true} date={value} />
  },
  {
    width: 200,
    headerName: 'Created at',
    field: 'createdAt',
    renderCell: ({ value }) => <TableDateFormat withTime={true} date={value} />
  },
  {
    width: 200,
    headerName: 'Last updated',
    field: 'updatedAt',
    renderCell: ({ value }) => <TableDateFormat withTime={true} date={value} />
  },
  {
    width: 150,
    headerName: 'Line items',
    field: 'lineItems'
  },
  {
    width: 150,
    headerName: 'Units',
    field: 'units'
  },
  {
    width: 150,
    headerName: 'Packages',
    field: 'packages'
  },
  {
    width: 150,
    headerName: 'Volume',
    field: 'volume',
    renderCell: ({ value }) => <Volume value={value} />
  },
  {
    width: 150,
    headerName: 'Weight',
    field: 'weight',
    renderCell: ({ value }) => <Weight value={value} />
  },
  {
    width: 150,
    headerName: 'Total value',
    field: 'totalValue',
    valueGetter: ({ row }) => `${row.totalValue} ${row.clientCurrency}`,
    renderCell: ({ row }) => <CurrencyMoney currency={row.clientCurrency} value={row.totalValue} />
  },
  {
    width: 200,
    headerName: 'Gatepass serial number',
    field: 'gatepassSerialNumber'
  },
  {
    width: 200,
    headerName: 'Vehicle number',
    field: 'vehicleNumber'
  },
  {
    width: 150,
    headerName: 'Shipping cost',
    field: 'shippingCost',
    renderCell: ({ value }) => <Money value={value} />
  },
  {
    width: 150,
    headerName: 'From warehouse',
    field: 'fromWarehouse'
  },
  {
    width: 150,
    headerName: 'To city',
    field: 'toCity'
  },
  {
    width: 150,
    headerName: 'To country',
    field: 'toCountry'
  }
]

const getRowsFromResults = map((item) => {
  const id = prop('id', item)
  const guid = prop('guid', item)

  const trackingNumber = prop('trackingNumber', item)
  const awbUrl = prop('awbUrl', item)

  const referenceNumber = prop('toObjectReferenceNumber', item)
  const orderNumber = prop('objectNumber', item)
  const shipmentNumber = prop('shipmentNumber', item)
  const direction = capitalize(prop('direction', item))
  const carrier = path(['shippingMethod', 'name'], item)
  const service = path(['service', 'name'], item)
  const shippingCost = path(['service', 'price'], item)
  const shippingStatus = prop('status', item)
  const carrierStatusTemp = prop('carrierStatus', item)
  const carrierStatus = replace(/[_]/g, ' ', carrierStatusTemp)
  const units = prop('unitsCount', item)
  const packages = prop('containersCount', item)
  const lineItems = prop('variantsCount', item)
  const vehicleNumber = prop('vehicleNumber', item)
  const gatepassSerialNumber = prop('gatepassSerialNumber', item)
  const volume = prop('totalVolume', item)
  const weight = prop('totalWeight', item)
  const clientCurrency = prop('currency', item)
  const totalValue = prop('toObjectTotalPrice', item)
  const fromWarehouse = path(['fromWarehouse', 'name'], item)
  const toCity = path(['shippingAddress', 'city'], item)
  const toCountry = path(['shippingAddress', 'countryName'], item)
  const createdAt = prop('createdAt', item)
  const updatedAt = prop('updatedAt', item)
  const objectDate = prop('objectDate', item)
  const shippedDate = prop('shippedDate', item)
  const deliveredDate = prop('deliveredDate', item)
  const shippingMethodError = prop('shippingMethodError', item)
  const containerNumbers = propOr([], 'containerNumbers', item).join(', ')

  const link = generatePath(ROUTES.SHIPMENT_DETAIL_PATH, {
    guid,
    tab: SHIPMENT_DETAIL_TABS.GENERAL
  })

  return {
    id,
    guid,
    awbUrl,
    shipmentNumber,
    orderNumber,
    clientCurrency,
    referenceNumber,
    containerNumbers,
    direction,
    carrierStatus,
    trackingNumber,
    shippingStatus,
    shippingMethodError,
    carrier,
    packages,
    lineItems,
    objectDate,
    service,
    shippedDate,
    shippingCost,
    totalValue,
    deliveredDate,
    updatedAt,
    units,
    fromWarehouse,
    toCountry,
    volume,
    weight,
    toCity,
    vehicleNumber,
    gatepassSerialNumber,
    createdAt,
    link
  }
})

function ShipmentTable (props) {
  const {
    list,
    filter,
    ordering,
    onListRefetch,
    onGatepassGenerate,
    onShipmentsExport,
    onListStatusSet,
    statusSetLoading,
    onReportGenerate,
    onCancelCarrier,
    onCommercialInvoiceGenerate,
    onRecalculate,
    onAwbGet,
    statusSetDisabled,
    onProDispatch
  } = props

  const [tableRef, setTableRef] = useState(null)
  const { t } = useTranslation()

  const setStatusDialog = useDialog()
  const exportDialog = useDialog()
  const proDispatchScanDialog = useDialog()
  const isLoading = prop('isLoading', list)
  const results = prop('results', list)
  const count = prop('count', list)
  const columns = prop('filteredColumns', ordering)
  const hasNextPage = prop('hasNextPage', list)
  const hasPrevPage = prop('hasPrevPage', list)

  const handleStatusSet = () => {
    setStatusDialog.handleOpen()
  }

  const handleListStatusSet = (values) => onListStatusSet(values).then(() => setStatusDialog.handleClose())

  const actionColumns = useMemo(
    () => [
      ...columns,
      {
        width: 80,
        resizable: false,
        headerName: 'Actions',
        field: 'actions',
        type: 'actions',
        getActions: ({ row }) => {
          const status = prop('shippingStatus', row)
          const id = prop('id', row)
          const guid = prop('guid', row)
          const awbUrl = prop('awbUrl', row)

          const cancelDisabled =
            status === SHIPMENT_STATUS.CANCELLED ||
            status === SHIPMENT_STATUS.DELIVERED ||
            status === SHIPMENT_STATUS.FAILED ||
            status === SHIPMENT_STATUS.IN_TRANSIT ||
            status === SHIPMENT_STATUS.RETURNED

          const recalculateDisabled = status !== SHIPMENT_STATUS.UNSHIPPED

          return [
            <GridActionsCellItem label="Generate report" onClick={() => onReportGenerate(id)} showInMenu={true} />,
            <GridActionsCellItem
              label="Cancel carrier"
              onClick={() => onCancelCarrier(guid)}
              showInMenu={true}
              disabled={cancelDisabled}
            />,
            <GridActionsCellItem
              label="Commercial invoice"
              onClick={() => onCommercialInvoiceGenerate(guid)}
              showInMenu={true}
            />,
            <GridActionsCellItem
              label="Recalculate"
              onClick={() => onRecalculate(guid)}
              showInMenu={true}
              disabled={recalculateDisabled}
            />,
            <GridActionsCellItem label="AWB" onClick={() => onAwbGet(guid, awbUrl)} showInMenu={true} />
          ]
        }
      }
    ],
    [columns, onReportGenerate, onCancelCarrier, onCommercialInvoiceGenerate, onRecalculate, onAwbGet]
  )

  return (
    <>
      <Container>
        <Header>
          <PageTitle
            pageTitle={t(NAV.SHIPMENTS)}
            parentTitle={t(NAV.FULFILLMENT)}
            rightButton={
              <>
                <BulkActionButton
                  onStatusSet={handleStatusSet}
                  onGatepassGenerate={onGatepassGenerate}
                  statusSetDisabled={statusSetDisabled}
                />
                <PrimaryButton
                  children="PRO DISPATCH"
                  onClick={proDispatchScanDialog.handleOpen}
                />
              </>
            }
          />
        </Header>

        <Content>
          <TableHeader
            filter={filter}
            ordering={ordering}
            isLoading={isLoading}
            filterOpen={filter.handleOpen}
            orderingOpen={ordering.handleOpen}
          >
            <More onShipmentExport={exportDialog.handleOpen} tableRef={tableRef} />
          </TableHeader>
          <Table
            isLoading={isLoading}
            columns={actionColumns}
            getRowsFromResults={getRowsFromResults}
            results={results}
            count={count}
            checkboxSelection={true}
            ordering={ordering}
            pinnedColumns={['shipmentNumber']}
            primaryKey="guid"
            onRefetch={onListRefetch}
            setTableRef={setTableRef}
            isNewPagination={true}
            hasNextPage={hasNextPage}
            hasPrevPage={hasPrevPage}
          />
        </Content>
      </Container>

      {setStatusDialog.open && (
        <ShipmentListSetStatusDialog
          open={setStatusDialog.open}
          onClose={setStatusDialog.handleClose}
          initialValues={{ status: emptyObject, open: setStatusDialog.open }}
          onSubmit={handleListStatusSet}
          isLoading={statusSetLoading}
        />
      )}

      {exportDialog.open && (
        <ShipmentExportDialogForm
          open={exportDialog.open}
          onClose={exportDialog.handleClose}
          initialValues={{ open: exportDialog.open }}
          onSubmit={(values) => {
            exportDialog.handleClose()
            onShipmentsExport(values)
          }}
        />
      )}
      {proDispatchScanDialog.open && (
        <ProDispatchScanDialog
          open={proDispatchScanDialog.open}
          initialValues={{ open: proDispatchScanDialog.open }}
          handleClose={proDispatchScanDialog.handleClose}
          onSubmit={onProDispatch}
        />
      )}
    </>
  )
}

ShipmentTable.propTypes = {
  list: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  onGatepassGenerate: PropTypes.func.isRequired,
  onShipmentsExport: PropTypes.func.isRequired,
  onListRefetch: PropTypes.func.isRequired,
  onListStatusSet: PropTypes.func.isRequired,
  statusSetLoading: PropTypes.bool.isRequired,
  onReportGenerate: PropTypes.func.isRequired,
  onCancelCarrier: PropTypes.func.isRequired,
  onCommercialInvoiceGenerate: PropTypes.func.isRequired,
  onRecalculate: PropTypes.func.isRequired,
  onAwbGet: PropTypes.func.isRequired,
  statusSetDisabled: PropTypes.bool,
  onProDispatch: PropTypes.func.isRequired
}

export default ShipmentTable
