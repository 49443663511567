import { join, map, pick, pipe, prop } from 'ramda'
import { DEFAULT_SEARCH_PARAMS } from 'storfox-route-hooks'

import { escapeAtob } from '~/utils'

export const ReceiveFilterSerializer = params => {
  const defaultParams = pick(DEFAULT_SEARCH_PARAMS, params)
  const status = prop('status', params)
  const warehouseIds = escapeAtob(prop('warehouses', params))
  const objectIds = escapeAtob(prop('objects', params))
  const expectedAtStart = prop('expectedAtStart', params)
  const expectedAtEnd = prop('expectedAtEnd', params)
  const updatedAtStart = prop('updatedAtStart', params)
  const updatedAtEnd = prop('updatedAtEnd', params)

  const objectTypes = pipe(
    prop('objectTypes'),
    objectType => objectType ? escapeAtob(objectType) : [],
    map(prop('value')),
    join(',')
  )(params)

  return {
    ...defaultParams,
    status,
    expectedAtStart,
    expectedAtEnd,
    updatedAtStart,
    updatedAtEnd,
    warehouseIds,
    objectTypes: objectTypes || null,
    objectIds
  }
}

export function ReceiveDimensionsInitSerializer (values) {
  const width = prop('width', values)
  const height = prop('height', values)
  const length = prop('length', values)
  const weight = prop('weight', values)

  if (width > length) {
    return { width: length, length: width, height, weight }
  } else return values
}
