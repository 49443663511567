import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useSnackbar } from 'storfox-snackbar'
import { useTranslation } from 'react-i18next'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'

import { RoleUpdateForm } from '../components/RoleUpdate'
import { usePermissionList, useRoleDetail, useRoleUpdate } from '../hooks'
import { RoleUpdateSerializer, RoleInitialValues } from '../serializers'

function RoleUpdateContainer () {
  const { t } = useTranslation()
  const { guid } = useParams()
  const roleUpdate = useRoleUpdate(guid)
  const roleDetail = useRoleDetail(guid)
  const permissionList = usePermissionList()
  const snackbar = useSnackbar()
  const pageLoading = roleUpdate.isLoading || roleDetail.isLoading

  const title = pageLoading ? 'Roles' : roleDetail.detail.name
  const message = t('Successfully updated')

  const handleSubmit = formValues =>
    roleUpdate.update(RoleUpdateSerializer(formValues))
      .then(() => roleDetail.getDetail())
      .then(() => snackbar({ message }))

  const breadcrumbs = { title }

  const initialValues = useMemo(() => {
    if (!roleDetail.isLoading && !permissionList.isLoading) {
      return RoleInitialValues(roleDetail.detail, permissionList.results)
    } else return {}
  }, [roleDetail, permissionList])

  return (
    <DashboardLayout
      title={title}
      activeNav={NAV.ROLES}
      isLoading={pageLoading}
      breadcrumbs={breadcrumbs}
    >
      <RoleUpdateForm
        title={title}
        pageTitleLoading={roleDetail.isLoading || permissionList.isLoading}
        initialValues={initialValues}
        isLoading={roleUpdate.isLoading}
        onSubmit={handleSubmit}
      />
    </DashboardLayout>
  )
}

export default RoleUpdateContainer
