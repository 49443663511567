import React from 'react'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { prop, path, pathOr, propOr, replace } from 'ramda'
import PropTypes from 'prop-types'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import TableContainer from '@mui/material/TableContainer'
import { generatePath, useNavigate } from 'react-router-dom'
import { styled } from '@mui/material'

import { capitalize } from '~/utils'
import EmptyNotesIcon from '~/components/Icons/EmptyNotesIcon'
import EmptyLineItems from '~/components/EmptyLineItems/EmptyLineItems'
import SkeletonList from '~/components/Skeleton'
import TableDateFormat from '~/components/TableDateFormat/TableDateFormat'
import { SHIPMENT_DETAIL_TABS } from '~/constants/tabs'
import * as ROUTES from '~/constants/routes'
import ShipmentStatus from '~/components/Statuses/ShipmentStatus'
import { Volume, Weight } from '~/components/Converters'
import CurrencyMoney from '~/components/Money/CurrencyMoney'
import Money from '~/components/Money'
import { HoverableRow } from '~/components/Table'

const TableRowStyled = styled(HoverableRow)({
  height: '70px'
})

const TableCellStyled = styled(TableCell)({
  minWidth: '150px'
})

function SaleOrderShipmentTable ({ shipmentList }) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const isLoading = prop('isLoading', shipmentList)
  const results = prop('results', shipmentList)

  const renderEmptyLines = Boolean(!isLoading && !results.length)
  const renderLineItems = Boolean(!isLoading && results.length)

  return (
    <Box component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto' }}>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCellStyled>{t('Shipment number')}</TableCellStyled>
              <TableCellStyled>{t('Tracking number')}</TableCellStyled>
              <TableCellStyled>{t('Reference #')}</TableCellStyled>
              <TableCellStyled>{t('Container number')}</TableCellStyled>
              <TableCellStyled>{t('Shipment status')}</TableCellStyled>
              <TableCellStyled>{t('Carrier')}</TableCellStyled>
              <TableCellStyled>{t('Service')}</TableCellStyled>
              <TableCellStyled>{t('Direction')}</TableCellStyled>
              <TableCellStyled>{t('Carrier status')}</TableCellStyled>
              <TableCellStyled>{t('Order date')}</TableCellStyled>
              <TableCellStyled>{t('Shipped date')}</TableCellStyled>
              <TableCellStyled>{t('Delivered date')}</TableCellStyled>
              <TableCellStyled>{t('Created at')}</TableCellStyled>
              <TableCellStyled>{t('Last updated')}</TableCellStyled>
              <TableCellStyled>{t('Line items')}</TableCellStyled>
              <TableCellStyled>{t('Units')}</TableCellStyled>
              <TableCellStyled>{t('Packages')}</TableCellStyled>
              <TableCellStyled>{t('Volume')}</TableCellStyled>
              <TableCellStyled>{t('Weight')}</TableCellStyled>
              <TableCellStyled>{t('Total value')}</TableCellStyled>
              <TableCellStyled sx={{ minWidth: '200px' }}>{t('Gatepass serial number')}</TableCellStyled>
              <TableCellStyled>{t('Vehicle number')}</TableCellStyled>
              <TableCellStyled>{t('Shipping cost')}</TableCellStyled>
              <TableCellStyled>{t('From warehouse')}</TableCellStyled>
              <TableCellStyled>{t('To city')}</TableCellStyled>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <SkeletonList
                rowCount={3}
                columnCount={13}
                height={20}
                rowHeight={70}
              />
            )}

            {renderEmptyLines && (
              <EmptyLineItems
                colSpan={24}
                icon={<EmptyNotesIcon />}
                text="No shipment by sale order"
              />
            )}
            {renderLineItems && results.map((row, index) => {
              const guid = prop('guid', row)
              const shipmentNumber = prop('shipmentNumber', row)
              const trackingNumber = prop('trackingNumber', row)
              const referenceNumber = prop('toObjectReferenceNumber', row)
              const containerNumbers = propOr([], 'containerNumbers', row).join(', ')
              const status = prop('status', row)
              const carrierFirstName = pathOr('', ['carrier', 'firstName'], row)
              const carrierLastName = pathOr('', ['carrier', 'lastName'], row)
              const service = path(['service', 'name'], row)
              const direction = capitalize(prop('direction', row))
              const carrierStatusTemp = prop('carrierStatus', row)
              const carrierStatus = replace(/[_]/g, ' ', carrierStatusTemp)
              const lineItems = prop('variantsCount', row)
              const units = prop('unitsCount', row)
              const packages = prop('containersCount', row)
              const volume = <Volume value={prop('totalVolume', row)} />
              const weight = <Weight value={prop('totalWeight', row)} />
              const clientCurrency = prop('currency', row)
              const gatepassSerialNumber = prop('gatepassSerialNumber', row)
              const vehicleNumber = prop('vehicleNumber', row)
              const fromWarehouse = path(['fromWarehouse', 'name'], row)
              const toCity = path(['shippingAddress', 'city'], row)

              const shipmentUrl = generatePath(ROUTES.SHIPMENT_DETAIL_PATH, { guid, tab: SHIPMENT_DETAIL_TABS.GENERAL })

              return (
                <TableRowStyled key={index} onClick={() => navigate(shipmentUrl)}>
                  <TableCell>{shipmentNumber}</TableCell>
                  <TableCell>{trackingNumber}</TableCell>
                  <TableCell>{referenceNumber}</TableCell>
                  <TableCell>{containerNumbers}</TableCell>
                  <TableCell>
                    <ShipmentStatus value={status} />
                  </TableCell>
                  <TableCell>{`${carrierFirstName} ${carrierLastName}`}</TableCell>
                  <TableCell>{service}</TableCell>
                  <TableCell>{direction}</TableCell>
                  <TableCell>{carrierStatus}</TableCell>
                  <TableCell>
                    <TableDateFormat withTime={true} date={prop('orderDate', row)} />
                  </TableCell>
                  <TableCell>
                    <TableDateFormat withTime={true} date={prop('shippedDate', row)} />
                  </TableCell>
                  <TableCell>
                    <TableDateFormat withTime={true} date={prop('deliveredDate', row)} />
                  </TableCell>
                  <TableCell>
                    <TableDateFormat withTime={true} date={prop('createdAt', row)} />
                  </TableCell>
                  <TableCell>
                    <TableDateFormat withTime={true} date={prop('updatedAt', row)} />
                  </TableCell>
                  <TableCell>{lineItems}</TableCell>
                  <TableCell>{units}</TableCell>
                  <TableCell>{packages}</TableCell>
                  <TableCell>{volume}</TableCell>
                  <TableCell>{weight}</TableCell>
                  <TableCell>
                    <CurrencyMoney currency={clientCurrency} value={prop('toObjectTotalPrice', row)} />
                  </TableCell>
                  <TableCell>{gatepassSerialNumber}</TableCell>
                  <TableCell>{vehicleNumber}</TableCell>
                  <TableCell>
                    <Money value={path(['service', 'price'], row)} />
                  </TableCell>
                  <TableCell>{fromWarehouse}</TableCell>
                  <TableCell>{toCity}</TableCell>
                </TableRowStyled>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

SaleOrderShipmentTable.propTypes = {
  shipmentList: PropTypes.object.isRequired
}

export default SaleOrderShipmentTable
