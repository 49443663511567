import React from 'react'
import { prop } from 'ramda'

import * as API from '~/constants/api'

import SearchField from './SearchField'

const getOptionLabel = item => {
  if (item) {
    return prop('name', item)
  }

  return ''
}

const getOptionValue = item => {
  if (item) {
    const id = prop('id', item)
    const guid = prop('guid', item)
    const firstName = prop('firstName', item)
    const lastName = prop('lastName', item)
    const name = `${firstName} ${lastName}`

    return { id, guid, name }
  }

  return null
}

function CustomerSearchField (props) {
  return (
    <SearchField
      name="customer"
      label="Customer"
      api={API.CUSTOMER_LIST}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      {...props}
    />
  )
}

export default CustomerSearchField
