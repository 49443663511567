import React from 'react'
import { Ordering } from 'storfox-filter'

import { FILTER_NAME as name } from '../../constants'
import { UNIT_COLUMNS as tableColumns } from '../UnitList/UnitList'

export const unitOrderingOptions = {
  name,
  tableColumns
}

function UnitOrderingForm (props) {
  return (
    <Ordering {...props} />
  )
}

export default UnitOrderingForm
