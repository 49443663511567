import product from './modules/product'
import category from './modules/category'
import brand from './modules/brand'
import unit from './modules/unit'
import variantRetailer from './modules/variant-retailer'
import variantThreepl from './modules/variant-threepl'
import bundleRetailer from './modules/bundle-retailer'
import bundleThreepl from './modules/bundle-threepl'
import listingRetailer from './modules/listing-retailer'
import listingThreepl from './modules/listing-threepl'

export default () => [
  ...product(),
  ...category(),
  ...brand(),
  ...unit()
]

export const catalogRetailer = () => [
  ...bundleRetailer(),
  ...listingRetailer(),
  ...variantRetailer()
]

export const catalogMarketplace = () => [
  ...bundleThreepl(),
  ...listingThreepl(),
  ...variantThreepl()
]

export const catalogThreepl = () => [
  ...bundleThreepl(),
  ...listingThreepl(),
  ...variantThreepl()
]
