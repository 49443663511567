import React from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { propOr } from 'ramda'

import Divider from '~/components/Divider'
import Tabs from '~/components/Tabs'
import { OVERVIEW_TABS } from '~/constants/tabs'
import * as ROUTES from '~/constants/routes'

const tabs = [
  { value: OVERVIEW_TABS.SALES, label: 'Sales' },
  { value: OVERVIEW_TABS.FULFILLMENT, label: 'Fulfillment' }
]

function OverviewTabs ({ value }) {
  const navigate = useNavigate()
  const queryParams = propOr('', 'search', location)

  const onChange = (event, tab) => {
    const redirect = `${generatePath(ROUTES.OVERVIEW_PATH, { tab })}${queryParams}`
    navigate(redirect, { replace: true })
  }

  return (
    <>
      <Tabs
        tabs={tabs}
        value={value}
        onChange={onChange}
      />

      <Divider />
    </>
  )
}
OverviewTabs.propTypes = {
  value: PropTypes.string.isRequired
}
export default OverviewTabs
