import React from 'react'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { pathEq, prop } from 'ramda'
import PropTypes from 'prop-types'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import HistoryIcon from '@mui/icons-material/History'
import IconButton from '@mui/material/IconButton'
import Pagination from 'storfox-table/src/Table/Pagination'
import TableContainer from '@mui/material/TableContainer'

import { getFormattedDateTime } from '~/utils'
import EmptyNotesIcon from '~/components/Icons/EmptyNotesIcon'
import { HISTORY_FORMAT } from '~/constants/dateFormat'
import EmptyLineItems from '~/components/EmptyLineItems/EmptyLineItems'
import VerticalAlignment from '~/components/VerticalAlignment'
import SkeletonList from '~/components/Skeleton/SkeletonList'

function SaleOrderHistoryTable ({ rows, isLoading, count, onClick }) {
  const { t } = useTranslation()

  const renderEmptyLines = Boolean(!isLoading && !rows.length)
  const renderLineItems = Boolean(!isLoading && rows.length)

  return (
    <Box component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto' }}>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '25%' }}>{t('Date')}</TableCell>
              <TableCell sx={{ width: '40%' }}>{t('Description')}</TableCell>
              <TableCell sx={{ width: '25%' }}>{t('User')}</TableCell>
              <TableCell sx={{ width: '10%' }}>{t('Action')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <SkeletonList
                rowCount={3}
                columnCount={4}
                height={25}
              />
            )}

            {renderEmptyLines && (
              <EmptyLineItems
                colSpan={4}
                icon={<EmptyNotesIcon />}
                text="No recorded history"
              />
            )}
            {renderLineItems && rows.map((row, index) => {
              const dateTime = getFormattedDateTime(prop('createdAt', row), HISTORY_FORMAT)
              const description = prop('description', row)
              const user = prop('user', row)
              const firstName = prop('firstName', user)
              const lastName = prop('lastName', user)
              const fullName = `${firstName} ${lastName}`
              const changes = prop('changes', row)
              const statusChange = changes.find(pathEq(['path', 0], 'status'))
              const newStatus = prop('newValue', statusChange)
              const statusChangeText = statusChange &&
                `Sale order status changed to <b>${newStatus.substr(1, newStatus.length - 2)}</b>`

              return (
                <TableRow key={index}>
                  <TableCell>{dateTime}</TableCell>
                  <TableCell>
                    {statusChange ? (
                      <VerticalAlignment
                        primary={<div dangerouslySetInnerHTML={{ __html: description }} />}
                        secondary={<div dangerouslySetInnerHTML={{ __html: statusChangeText }} />}
                      />
                    ) : (
                      <div dangerouslySetInnerHTML={{ __html: description }} />
                    )}
                  </TableCell>
                  <TableCell>{fullName}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => onClick(changes)} size="large">
                      <HistoryIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination count={count} prefix="" />
    </Box>
  )
}

SaleOrderHistoryTable.defaultProps = {
  rows: []
}

SaleOrderHistoryTable.propTypes = {
  rows: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default SaleOrderHistoryTable
