import React from 'react'

import MultiSelectField from '~/components/Fields/MultiSelectField'

const options = [
  { value: 'PurchaseOrder', name: 'Purchase order' },
  { value: 'StockTransfer', name: 'Stock transfer' },
  { value: 'Returns', name: 'Return' }
]

function ReceivingTypeField (props) {
  return (
    <MultiSelectField
      name="objectTypes"
      label="Receive type"
      options={options}
      {...props}
    />
  )
}

export default ReceivingTypeField
