import React from 'react'
import Skeleton from '@mui/material/Skeleton'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import { map, range } from 'ramda'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { Avatar } from '@mui/material'

function SkeletonCards ({ quantity }) {
  return map(index => (
    <Grid key={index} item={true} xs={12} sm={6} md={4} lg={3}>
      <Card>
        <CardContent>
          <Skeleton variant="rectangular">
            <Avatar />
          </Skeleton>

          <Skeleton variant="rectangular" style={{ marginTop: 16 }} />
          <Skeleton variant="rectangular" style={{ marginTop: 12 }} />
        </CardContent>
      </Card>
    </Grid>
  ), range(0, quantity))
}

SkeletonCards.defaultProps = {
  quantity: 4
}

SkeletonCards.propTypes = {
  quantity: PropTypes.number
}
export default SkeletonCards
