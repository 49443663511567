import { useUpdate } from 'storfox-api-hooks'

import * as API from '../constants/api'

export const useFilterOrderingUpdate = () => {
  return useUpdate(API.ORDERING_FILTER)
}

export const useColumnOrderingUpdate = () => {
  return useUpdate(API.ORDERING_COLUMN)
}

export const useColumnWidthUpdate = () => {
  return useUpdate(API.COLUMN_WIDTH_UPDATE)
}
