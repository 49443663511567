import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material'
import PropTypes from 'prop-types'
import Skeleton from '@mui/material/Skeleton'
import CircularProgress from '@mui/material/CircularProgress'

import HtmlTooltip from '~/components/HtmlTooltip/HtmlTooltip'

export const BoxStyled = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 50px'
})

export const TitleStyled = styled(Typography)({
  color: '#546E7A',
  textTransform: 'uppercase',
  marginBottom: '8px',
  fontSize: '11px'
})

function OrderPercentage ({ title, value, isLoading, circle, sx, tooltip }) {
  return (
    <HtmlTooltip title={tooltip}>
      <BoxStyled sx={sx}>
        {isLoading && (
          <Box sx={{ width: '70%' }}>
            <Skeleton sx={{ width: '50%', marginBottom: '19px' }} />
            <Skeleton />
          </Box>
        )}
        {!isLoading && (
          <Box>
            <TitleStyled variant="h6">{title}</TitleStyled>
            <Typography variant="h2">{value}</Typography>
          </Box>
        )}
        <Box sx={{ position: 'relative', height: '76px' }}>
          {circle}
          <CircularProgress
            variant="determinate"
            sx={{
              color: (theme) =>
                theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
              position: 'absolute',
              right: 0,
              zIndex: 4
            }}
            size={76}
            thickness={4}
            value={100}
          />
        </Box>
      </BoxStyled>
    </HtmlTooltip>
  )
}

OrderPercentage.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  tooltip: PropTypes.string,
  circle: PropTypes.node,
  isLoading: PropTypes.bool,
  sx: PropTypes.object
}

export default OrderPercentage
