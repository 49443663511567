import React from 'react'
import { useTranslation } from 'react-i18next'
import { is } from 'ramda'
import PropTypes from 'prop-types'
import MuiDialogTitle from '@mui/material/DialogTitle'

function DialogTitle ({ children, ...props }) {
  const { t } = useTranslation()
  const content = is(String, children) ? t(children) : children

  return (
    <MuiDialogTitle {...props}>
      {content}
    </MuiDialogTitle>
  )
}

DialogTitle.propTypes = {
  children: PropTypes.any.isRequired
}

export default DialogTitle
