import React from 'react'
import { FieldArray } from 'react-final-form-arrays'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'

import MoneyField from '~/components/Fields/MoneyField'
import { Button } from '~/components/Buttons'

import { FulfillmentUnitOrderField } from '../Fields'

function UnitPricing () {
  return (
    <FieldArray
      name="fulfillmentBilling.ranges"
      render={({ fields }) => {
        const handlePricingAdd = () => {
          fields.push({})
        }

        const handlePricingRemove = (index) => {
          fields.remove(index)

          if (fields.value.length < 1) {
            fields.push([{}])
          }
        }

        return <>
          {fields.map((name, index) => {
            return (
              <Grid item={true} xs={12} key={index}>
                <Grid container={true} spacing={3}>

                  <Grid item={true} xs={12} lg={4}>
                    <FulfillmentUnitOrderField name={`${name}.fromPer`} label="From" />
                  </Grid>

                  <Grid item={true} xs={12} lg={4}>
                    <FulfillmentUnitOrderField name={`${name}.toPer`} label="To" />
                  </Grid>
                  <Grid item={true} xs={12} lg={3}>
                    <MoneyField name={`${name}.price`} label="Price" />
                  </Grid>

                  <Grid item={true} xs={12} lg={1}>
                    <IconButton onClick={() => handlePricingRemove(index)} size="large">
                      <DeleteIcon />
                    </IconButton>
                  </Grid>

                </Grid>
              </Grid>
            )
          })}
          <Grid item={true} xs={12}>
            <Button
              onClick={handlePricingAdd}
            >
              Add another range
            </Button>
          </Grid>
        </>
      }}
    />
  )
}

export default UnitPricing
