import React from 'react'
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js'
import { Line } from 'react-chartjs-2'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { prop, propOr } from 'ramda'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)

const dynamicColors = () => {
  const r = Math.floor(Math.random() * 255)
  const g = Math.floor(Math.random() * 255)
  const b = Math.floor(Math.random() * 255)
  return 'rgb(' + r + ',' + g + ',' + b + ')'
}

function Chart ({ labels, data: dataProp, ...props }) {
  const theme = useTheme()

  const options = {
    cornerRadius: 20,
    maintainAspectRatio: false,
    animation: false,
    responsive: true,
    layout: {
      padding: 0
    },
    interaction: {
      mode: 'index',
      intersect: false
    },
    datasets: {
      bar: {
        backgroundColor: theme.palette.primary.light,
        barPercentage: 1,
        categoryPercentage: 0.7
      }
    },
    scales: {
      x: {
        stacked: false,
        grid: {
          display: true
        },
        ticks: {
          padding: 20,
          fontColor: theme.palette.text.secondary
        }
      },
      y: {
        stacked: false,
        grid: {
          borderDash: [2],
          borderDashOffset: [2],
          color: theme.palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: theme.palette.divider
        },
        ticks: {
          padding: 20,
          fontColor: theme.palette.text.secondary,
          beginAtZero: true,
          min: 0,
          maxTicksLimit: 5,
          callback: value => {
            return value > 0 ? value / 1000 + 'K' : value
          }
        }
      }
    },
    plugins: {
      legend: {
        labels: {
          usePointStyle: true
        }
      }
    }
  }

  const data = {
    labels,
    datasets: dataProp.map((item) => {
      const color = dynamicColors()
      return {
        label: prop('carrier', item),
        data: propOr([], 'items', item),
        tension: 0.5,
        backgroundColor: color,
        borderColor: color
      }
    }),
  }

  return (
    <Box position="relative" {...props}>
      <Line options={options} data={data} />
    </Box>
  )
}

Chart.propTypes = {
  labels: PropTypes.array.isRequired,
  data: PropTypes.object,
}

export default Chart
