import React from 'react'
import Link from '@mui/material/Link'
import { generatePath, Link as RouterLink } from 'react-router-dom'
import { prop } from 'ramda'
import PropTypes from 'prop-types'

import * as ROUTES from '~/constants/routes'
import {
  BUNDLE_DETAIL_TABS,
  CUSTOMER_DETAIL_TABS,
  LISTING_TABS,
  LOCATION_TABS,
  PACKING_DETAIL_TABS,
  PICKLIST_TABS,
  PRODUCT_UPDATE_TABS,
  PURCHASE_ORDER_DETAIL_TABS,
  RECEIVE_TABS,
  SALE_RETURN_TABS,
  SALE_ORDER_DETAIL_TABS,
  SHIPMENT_DETAIL_TABS,
  SUPPLIER_DETAIL_TABS,
  USER_DETAIL_TABS,
  WAREHOUSE_TABS,
  MOVEMENT_DETAIL_TABS,
  ADJUSTMENT_DETAIL_TABS
} from '~/constants/tabs'

const SALE_ORDER = 'sale-order'
const TRANSFER = 'stock-transfer'

const routes = (id, guid) => ({
  purchase: {
    path: ROUTES.PURCHASE_ORDER_DETAIL_PATH,
    params: { guid, tab: PURCHASE_ORDER_DETAIL_TABS.GENERAL }
  },
  product: { path: ROUTES.PRODUCT_UPDATE_PATH, params: { id, tab: PRODUCT_UPDATE_TABS.GENERAL } },
  bundle: { path: ROUTES.BUNDLE_DETAIL_PATH, params: { guid, tab: BUNDLE_DETAIL_TABS.GENERAL } },
  category: { path: ROUTES.CATEGORY_UPDATE_PATH, params: { id } },
  brand: { path: ROUTES.BRAND_UPDATE_PATH, params: { id } },
  listing: { path: ROUTES.LISTING_DETAIL_PATH, params: { guid, tab: LISTING_TABS.VARIANT } },
  supplier: { path: ROUTES.SUPPLIER_DETAIL_PATH, params: { guid, tab: SUPPLIER_DETAIL_TABS.GENERAL } },
  receive_purchase: { path: ROUTES.RECEIVE_PURCHASE_ORDER_DETAIL_PATH, params: { guid, tab: RECEIVE_TABS.GENERAL } },
  receive_transfer: { path: ROUTES.RECEIVE_TRANSFER_DETAIL_PATH, params: { guid, tab: RECEIVE_TABS.GENERAL } },
  receive_return: { path: ROUTES.RECEIVE_RETURN_DETAIL_PATH, params: { guid, tab: RECEIVE_TABS.GENERAL } },
  adjustment: { path: ROUTES.ADJUSTMENT_DETAIL_PATH, params: { id, tab: ADJUSTMENT_DETAIL_TABS.GENERAL } },
  stock_count: { path: ROUTES.STOCK_COUNT_UPDATE_PATH, params: { guid } },
  movement: { path: ROUTES.MOVEMENT_DETAIL_PATH, params: { id, tab: MOVEMENT_DETAIL_TABS.GENERAL } },
  customer: { path: ROUTES.CUSTOMER_DETAIL_PATH, params: { guid, tab: CUSTOMER_DETAIL_TABS.GENERAL } },
  [TRANSFER]: { path: ROUTES.TRANSFER_UPDATE_PATH, params: { guid } },
  [SALE_ORDER]: { path: ROUTES.SALE_ORDER_DETAIL_PATH, params: { guid, tab: SALE_ORDER_DETAIL_TABS.GENERAL } },
  invoice: { path: ROUTES.INVOICE_DETAIL_PATH, params: { guid } },
  picklist: { path: ROUTES.PICKLIST_UPDATE_PATH, params: { guid, tab: PICKLIST_TABS.GENERAL } },
  packing: { path: ROUTES.PACKING_DETAIL_PATH, params: { id, tab: PACKING_DETAIL_TABS.GENERAL } },
  shipment: { path: ROUTES.SHIPMENT_DETAIL_PATH, params: { guid, tab: SHIPMENT_DETAIL_TABS.GENERAL } },
  return: { path: ROUTES.RETURN_UPDATE_PATH, params: { guid, tab: SALE_RETURN_TABS.GENERAL } },
  warehouse: { path: ROUTES.WAREHOUSE_DETAIL_PATH, params: { guid, tab: WAREHOUSE_TABS.GENERAL } },
  area: { path: ROUTES.AREA_DETAIL_PATH, params: { guid } },
  zone: { path: ROUTES.ZONE_DETAIL_PATH, params: { guid } },
  location: { path: ROUTES.LOCATION_DETAIL_PATH, params: { guid, tab: LOCATION_TABS.GENERAL } },
  container: { path: ROUTES.CONTAINER_DETAIL_PATH, params: { guid } },
  user: { path: ROUTES.USER_DETAIL_PATH, params: { id, tab: USER_DETAIL_TABS.GENERAL } }
})

function HistoryLink ({ id, guid, entity, children }) {
  const route = prop(entity, routes(id, guid))
  const path = prop('path', route)
  const params = prop('params', route)
  const generatedPath = generatePath(path, params)

  return (
    <Link
      to={generatedPath}
      component={RouterLink}
      underline="always"
    >
      {children}
    </Link>
  )
}

HistoryLink.propTypes = {
  children: PropTypes.string.isRequired,
  entity: PropTypes.string.isRequired,
  guid: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired
}

export default HistoryLink
