import React from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import { FieldArray } from 'react-final-form-arrays'
import IconButton from '@mui/material/IconButton'
import Delete from '@mui/icons-material/Delete'
import { prop } from 'ramda'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'

import ConstrainedField from '~/components/Fields/ConstrainedField'

const RootStyled = styled(Box)({
  maxHeight: 'calc(80vh - 200px)',
  overflow: 'auto',
  marginTop: 20,
  minHeight: 100
})

const TableStyled = styled(Table)({
  width: '100%',
  '& th': {
    textAlign: 'left'
  }
})

function BarcodeList () {
  const { t } = useTranslation()

  return (
    <RootStyled>
      <TableContainer component={Paper} sx={{ minWidth: '700px' }}>
        <TableStyled>
          <TableHead>
            <TableRow>
              <TableCell>{t('Unit number')}</TableCell>
              <TableCell>{t('Serial number')}</TableCell>
              <TableCell>{t('Batch number')}</TableCell>
              <TableCell>{t('Condition code')}</TableCell>
              <TableCell>{t('Total Qty')}</TableCell>
              <TableCell colSpan={2}>{t('Qty')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <FieldArray name="barcodeItems">
              {({ fields }) => {
                const value = fields.value
                return fields
                  .map((name, index) => {
                    const item = value[index]
                    const unitNumber = prop('unitNumber', item)
                    const serialNumber = prop('serialNumber', item)
                    const batchNumber = prop('batchNumber', item)
                    const conditionCode = prop('conditionCode', item)
                    const totalQuantity = prop('totalQuantity', item)

                    return (
                      <TableRow key={index}>
                        <TableCell>{unitNumber}</TableCell>
                        <TableCell>{serialNumber}</TableCell>
                        <TableCell>{batchNumber}</TableCell>
                        <TableCell>{conditionCode}</TableCell>
                        <TableCell>{totalQuantity}</TableCell>
                        <TableCell sx={{ width: '100px' }}>
                          <ConstrainedField
                            data-cy={`${index}.quantity`}
                            name={`${name}.quantity`}
                            maxValue={totalQuantity}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <IconButton onClick={() => fields.remove(index)} size="large">
                            <Delete />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  })
                  .reverse()
              }}
            </FieldArray>
          </TableBody>
        </TableStyled>
      </TableContainer>
    </RootStyled>
  )
}

export default BarcodeList
