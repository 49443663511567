import { flatten, map, path, pathOr, pick, pipe, prop, propOr } from 'ramda'
import { DEFAULT_SEARCH_PARAMS } from 'storfox-route-hooks'

import { escapeAtob } from '~/utils'

const getUnitsLineItems = map(item => ({
  id: prop('id', item),
  unit: { id: pathOr(prop('id', item), ['unit', 'id'], item) },
  quantity: prop('quantity', item),
  toLocation: path(['toLocation', 'id'], item) ? { id: path(['toLocation', 'id'], item) } : null,
  toContainer: prop('toContainer', item) && { id: path(['toContainer', 'id'], item) }
}))

const getLineItems = pipe(
  map(lineItem => {
    const unit = prop('unit', lineItem)
    const quantity = prop('quantity', lineItem)
    const toLocation = prop('toLocation', lineItem)
    const toContainer = prop('toContainer', lineItem)

    return { ...unit, quantity, toLocation, toContainer }
  }),
  flatten
)

export const MovementDetailInitSerializer = detail => {
  const lineItems = getLineItems(propOr([], 'lineItems', detail))

  return { ...detail, lineItems }
}

export const MovementCreateSerializer = (formValues, mode) => {
  const warehouse = prop('warehouse', formValues)
  const lineItems = propOr([], 'lineItems', formValues)
  const notes = prop('notes', formValues)

  return {
    lineItems: getUnitsLineItems(lineItems).reverse(),
    notes,
    mode,
    warehouse
  }
}

export const MovementFilterSerializer = params => {
  const defaultParams = pick(DEFAULT_SEARCH_PARAMS, params)
  const status = prop('status', params)
  const totalPriceStart = prop('totalPriceStart', params)
  const totalPriceEnd = prop('totalPriceEnd', params)
  const warehouseGuids = escapeAtob(prop('warehouses', params))
  const toLocationId = escapeAtob(prop('toLocationId', params))
  const updatedAtStart = prop('updatedAtStart', params)
  const updatedAtEnd = prop('updatedAtEnd', params)

  return {
    ...defaultParams,
    status,
    totalPriceStart,
    totalPriceEnd,
    updatedAtStart,
    updatedAtEnd,
    warehouseGuids,
    toLocationId
  }
}

export const MovementInitialValuesSerializer = (values, lineItems = []) => {
  const newLineItems = lineItems.map(item => {
    const unit = prop('unit', item)
    const id = prop('id', item)
    return { ...item, ...unit, id }
  })
  return { ...values, lineItems: newLineItems }
}
