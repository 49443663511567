import { BlankLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import MovementScanCreateContainer from './containers/MovementScanCreateContainer'
import MovementScanUpdateContainer from './containers/MovementScanUpdateContainer'
import MovementListContainer from './containers/MovementListContainer'
import MovementDetailContainer from './containers/MovementDetailContainer'
import MovementBrowseCreateContainer from './containers/MovementBrowseCreateContainer'
import MovementBrowseUpdateContainer from './containers/MovementBrowseUpdateContainer'
import MovementImportContainer from './containers/MovementImportContainer'

export default () => [
  {
    path: ROUTES.MOVEMENT_LIST_PATH,
    component: MovementListContainer,
    layout: BlankLayout
  },
  {
    path: ROUTES.MOVEMENT_SCAN_CREATE_PATH,
    component: MovementScanCreateContainer,
    layout: BlankLayout
  },
  {
    path: ROUTES.MOVEMENT_SCAN_UPDATE_PATH,
    component: MovementScanUpdateContainer,
    layout: BlankLayout
  },
  {
    path: ROUTES.MOVEMENT_BROWSE_CREATE_PATH,
    component: MovementBrowseCreateContainer,
    layout: BlankLayout
  },
  {
    path: ROUTES.MOVEMENT_BROWSE_UPDATE_PATH,
    component: MovementBrowseUpdateContainer,
    layout: BlankLayout
  },
  {
    path: ROUTES.MOVEMENT_DETAIL_PATH,
    component: MovementDetailContainer,
    layout: BlankLayout
  },
  {
    path: ROUTES.MOVEMENT_IMPORT_PATH,
    component: MovementImportContainer,
    layout: BlankLayout
  },
]
