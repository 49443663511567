import React from 'react'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import Card from '@mui/material/Card'

import { CardHeader } from '~/components/Cards'

import LineItems from '../LineItems'

function BundleComposition () {
  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12}>
        <Card>
          <CardHeader title="Units" />
          <Divider />
          <CardContent>
            <Grid container={true} spacing={3}>
              <Grid item={true} xs={12}>
                <LineItems />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default BundleComposition
