import { colors } from '@mui/material'

import palette from '~/theme/dark/palette'

export default {
  styleOverrides: {
    root: {
      backgroundColor: palette.background.paper
    },
    scanned: {
      boxShadow: `inset 4px 0px 0px ${colors.yellow[200]}`,
      backgroundColor: `${colors.grey[900]}41`
    },
    fullyScanned: {
      boxShadow: `inset 4px 0px 0px ${palette.secondary.main}`,
      backgroundColor: `${colors.grey[900]}41`
    },
    active: {
      boxShadow: `inset 4px 0px 0px ${colors.grey[500]}`,
      backgroundColor: `${colors.grey[900]}41`
    }
  }
}
