import React from 'react'
import { useTranslation } from 'react-i18next'
import { MultiSelectSearchField } from 'storfox-form-fields'

import * as API from '~/constants/api'

function ChannelField ({ ...props }) {
  const { t } = useTranslation()

  return (
    <MultiSelectSearchField
      api={API.SALE_ORDER_CHANNEL}
      name="channels"
      data-cy="channels"
      label={t('Channels')}
      params={{
        active: true
      }}
      {...props}
    />
  )
}

export default ChannelField
