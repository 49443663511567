import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { map, pathOr, prop, propOr } from 'ramda'
import { useAllSearchParams } from 'storfox-route-hooks'
import { useTranslation } from 'react-i18next'

import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import { Table, TableHeader } from '~/components/Table'
import * as NAV from '~/constants/nav-titles'
import { capitalize, safeJsonParse } from '~/utils'
import CurrencyMoney from '~/components/Money/CurrencyMoney'
import Button from '~/components/Buttons/Button'

import StockAgingRangeRow from './StockAgingRangeRow'

const defaultRange = {
  quantity: 0,
  price: 0
}

export const STOCK_AGING_COLUMNS = [
  {
    width: 150,
    headerName: 'SKU',
    field: 'sku'
  },
  {
    width: 300,
    headerName: 'Name',
    field: 'name'
  },
  {
    width: 150,
    headerName: 'UoM',
    field: 'unitOfMeasurement'
  }
]

const getColumnsFromParams = (columns, params) => {
  const range1 = propOr('0-30', 'range1', params)
  const range2 = propOr('30-60', 'range2', params)
  const range3 = propOr('60-90', 'range3', params)
  const range4 = propOr('90-120', 'range4', params)
  const range5 = propOr('120-150', 'range5', params)
  const range6 = propOr('150-1000', 'range6', params)

  return [
    ...columns,
    {
      width: 200,
      headerName: `${range1} Cost`,
      field: 'range1.price',
      valueGetter: ({ row }) => pathOr(0, ['range1', 'price'], row),
      renderCell: ({ value, row }) => (
        <StockAgingRangeRow isTotal={row.isTotal}>
          <CurrencyMoney value={value} />
        </StockAgingRangeRow>
      )
    },
    {
      width: 200,
      headerName: `${range1} Quantity`,
      field: 'range1.quantity',
      valueGetter: ({ row }) => pathOr(0, ['range1', 'quantity'], row),
      renderCell: ({ value, row }) => (
        <StockAgingRangeRow isTotal={row.isTotal}>
          {value}
        </StockAgingRangeRow>
      )
    },
    {
      width: 200,
      headerName: `${range2} Cost`,
      field: 'range2.price',
      valueGetter: ({ row }) => pathOr(0, ['range2', 'price'], row),
      renderCell: ({ value, row }) => (
        <StockAgingRangeRow isTotal={row.isTotal}>
          <CurrencyMoney value={value} />
        </StockAgingRangeRow>
      )
    },
    {
      width: 200,
      headerName: `${range2} Quantity`,
      field: 'range2.quantity',
      valueGetter: ({ row }) => pathOr(0, ['range2', 'quantity'], row),
      renderCell: ({ value, row }) => (
        <StockAgingRangeRow isTotal={row.isTotal}>
          {value}
        </StockAgingRangeRow>
      )
    },
    {
      width: 200,
      headerName: `${range3} Cost`,
      field: 'range3.price',
      valueGetter: ({ row }) => pathOr(0, ['range3', 'price'], row),
      renderCell: ({ value, row }) => (
        <StockAgingRangeRow isTotal={row.isTotal}>
          <CurrencyMoney value={value} />
        </StockAgingRangeRow>
      )
    },
    {
      width: 200,
      headerName: `${range3} Quantity`,
      field: 'range3.quantity',
      valueGetter: ({ row }) => pathOr(0, ['range3', 'quantity'], row),
      renderCell: ({ value, row }) => (
        <StockAgingRangeRow isTotal={row.isTotal}>
          {value}
        </StockAgingRangeRow>
      )
    },
    {
      width: 200,
      headerName: `${range4} Cost`,
      field: 'range4.price',
      valueGetter: ({ row }) => pathOr(0, ['range4', 'price'], row),
      renderCell: ({ value, row }) => (
        <StockAgingRangeRow isTotal={row.isTotal}>
          <CurrencyMoney value={value} />
        </StockAgingRangeRow>
      )
    },
    {
      width: 200,
      headerName: `${range4} Quantity`,
      field: 'range4.quantity',
      valueGetter: ({ row }) => pathOr(0, ['range4', 'quantity'], row),
      renderCell: ({ value, row }) => (
        <StockAgingRangeRow isTotal={row.isTotal}>
          {value}
        </StockAgingRangeRow>
      )
    },
    {
      width: 200,
      headerName: `${range5} Cost`,
      field: 'range5.price',
      valueGetter: ({ row }) => pathOr(0, ['range5', 'price'], row),
      renderCell: ({ value, row }) => (
        <StockAgingRangeRow isTotal={row.isTotal}>
          <CurrencyMoney value={value} />
        </StockAgingRangeRow>
      )
    },
    {
      width: 200,
      headerName: `${range5} Quantity`,
      field: 'range5.quantity',
      valueGetter: ({ row }) => pathOr(0, ['range5', 'quantity'], row),
      renderCell: ({ value, row }) => (
        <StockAgingRangeRow isTotal={row.isTotal}>
          {value}
        </StockAgingRangeRow>
      )
    },
    {
      width: 200,
      headerName: `${range6} Cost`,
      field: 'range6.price',
      valueGetter: ({ row }) => pathOr(0, ['range6', 'price'], row),
      renderCell: ({ value, row }) => (
        <StockAgingRangeRow isTotal={row.isTotal}>
          <CurrencyMoney value={value} />
        </StockAgingRangeRow>
      )
    },
    {
      width: 200,
      headerName: `${range6} Quantity`,
      field: 'range6.quantity',
      valueGetter: ({ row }) => pathOr(0, ['range6', 'quantity'], row),
      renderCell: ({ value, row }) => (
        <StockAgingRangeRow isTotal={row.isTotal}>
          {value}
        </StockAgingRangeRow>
      )
    },
    {
      width: 200,
      headerName: `Total Cost`,
      field: 'total.price',
      valueGetter: ({ row }) => pathOr(0, ['total', 'price'], row),
      renderCell: ({ value, row }) => (
        <StockAgingRangeRow isTotal={row.isTotal}>
          <CurrencyMoney value={value} />
        </StockAgingRangeRow>
      )
    },
    {
      width: 200,
      headerName: `Total Quantity`,
      field: 'total.quantity',
      valueGetter: ({ row }) => pathOr(0, ['total', 'quantity'], row),
      renderCell: ({ value, row }) => (
        <StockAgingRangeRow isTotal={row.isTotal}>
          {value}
        </StockAgingRangeRow>
      )
    }
  ]
}

const getRowsFromResults = map(item => {
  const guid = propOr('', 'guid', item)
  const sku = propOr('', 'sku', item)
  const name = propOr('', 'name', item)
  const unitOfMeasurement = capitalize(propOr('', 'unitOfMeasurement', item))
  const isTotal = propOr(false, 'isTotal', item)
  const range1 = pathOr(defaultRange, ['ranges', 'range1'], item)
  const range2 = pathOr(defaultRange, ['ranges', 'range2'], item)
  const range3 = pathOr(defaultRange, ['ranges', 'range3'], item)
  const range4 = pathOr(defaultRange, ['ranges', 'range4'], item)
  const range5 = pathOr(defaultRange, ['ranges', 'range5'], item)
  const range6 = pathOr(defaultRange, ['ranges', 'range6'], item)
  const total = pathOr(defaultRange, ['ranges', 'total'], item)

  return {
    guid,
    sku,
    name,
    unitOfMeasurement,
    isTotal,
    range1,
    range2,
    range3,
    range4,
    range5,
    range6,
    total
  }
})

function StockAgingTable ({ list, ordering, filter, onListRefetch, onStockAgingExport }) {
  const { t } = useTranslation()
  const params = useAllSearchParams()
  const isLoading = prop('isLoading', list)
  const results = prop('results', list)
  const count = prop('count', list)
  const filteredColumns = prop('filteredColumns', ordering)

  const summaryJSON = prop('summary', list)
  const summary = safeJsonParse(summaryJSON, [])
  const summaryForTable = useMemo(() => summary.map((value, idx) => ({
    ranges: value,
    isTotal: true,
    guid: `total-${idx}`,
    unitOfMeasurement: propOr('', 'unit_of_measurement', value)
  })), [summary])

  const columns = getColumnsFromParams(filteredColumns, params)
  const resultWithSummary = [...summaryForTable, ...results]

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={NAV.STOCK_AGING_REPORT}
          parentTitle={NAV.REPORTS}
          rightButton={(
            <Button
              variant="contained"
              data-cy="create"
              type="button"
              onClick={onStockAgingExport}
            >
              {t('Generate Report')}
            </Button>
          )}
        />
      </Header>

      <Content>
        <TableHeader
          filter={filter}
          filterOpen={filter.handleOpen}
          orderingOpen={ordering.handleOpen}
          ordering={ordering}
        />
        <Table
          isLoading={isLoading}
          columns={columns}
          getRowsFromResults={getRowsFromResults}
          results={resultWithSummary}
          count={count}
          pinnedColumns={['sku']}
          primaryKey="guid"
          ordering={ordering}
          onRefetch={onListRefetch}
        />
      </Content>
    </Container>
  )
}

StockAgingTable.propTypes = {
  list: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  onStockAgingExport: PropTypes.func.isRequired,
  onListRefetch: PropTypes.func.isRequired
}

export default StockAgingTable
