import React from 'react'
import IconButton from '@mui/material/IconButton'
import PropTypes from 'prop-types'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'

import TopInformationItem from './TopInformationItem'

const BoxStyled = styled(Box)({
  position: 'absolute',
  right: '0',
  top: '-10px'
})

function TopChangeableInformationItem ({ title, value, percent, onChange }) {
  return (
    <TopInformationItem title={title} value={value} percent={percent}>
      <BoxStyled>
        <IconButton size="small" onClick={onChange}>
          <ChangeCircleIcon />
        </IconButton>
      </BoxStyled>
    </TopInformationItem>
  )
}

TopChangeableInformationItem.propTypes = {
  title: PropTypes.string.isRequired,
  percent: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]).isRequired
}

export default TopChangeableInformationItem
