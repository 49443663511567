import React from 'react'
import isEqual from 'react-fast-compare'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import MUITextField from '@mui/material/TextField'

import { emptyObject } from '~/constants/empty'

import NumberFormat from './NumberFormat'

const InputComponent = React.forwardRef((props, ref) => (
  <NumberFormat {...props} ref={ref} allowNegative={true} decimalScale={0} />
))

function IntegerField (props) {
  const {
    name,
    InputProps = emptyObject,
    size = 'small',
    fullWidth = true,
    variant = 'outlined',
    ...rest
  } = props

  return (
    <Field name={name}>
      {({ input, meta }) => (
        <MUITextField
          value={input.value}
          onChange={event => input.onChange(event.target.value)}
          error={meta.invalid}
          helperText={meta.submitError || meta.error}
          size={size}
          fullWidth={fullWidth}
          variant={variant}
          {...rest}
          InputProps={{
            inputComponent: InputComponent,
            ...InputProps
          }}
        />
      )}
    </Field>
  )
}

IntegerField.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
  variant: PropTypes.string,
  InputProps: PropTypes.object
}

export default React.memo(IntegerField, isEqual)
