import React from 'react'

import MultiSelectField from '~/components/Fields/MultiSelectField'

const options = [
  { value: 'storage', name: 'Storage' },
  { value: 'picking', name: 'Picking' },
  { value: 'packing', name: 'Packing' },
  { value: 'handling', name: 'Handling' },
]

function OperationsField (props) {
  return (
    <MultiSelectField
      data-cy="operations"
      name="operations"
      label="Operations"
      options={options}
      {...props}
    />
  )
}

export default OperationsField
