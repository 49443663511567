import { prop } from 'ramda'

const KEY = 'theme'
const DEFAULT = 'light'

const BRANDING_KEY = 'branding'
export const set = value => localStorage.setItem(KEY, JSON.stringify(value))

export const get = () => {
  const value = localStorage.getItem(KEY)

  return value ? JSON.parse(value) : DEFAULT
}

export const getBranding = () => {
  return localStorage.getItem(BRANDING_KEY)
}

export const getPrimaryColor = () => {
  return prop('primaryColor', getBranding())
}
