import React from 'react'
import { SwitchField } from 'storfox-form-fields'
import { useField } from 'react-final-form'
import { useDeepCompareEffect } from 'storfox-api-hooks'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

function DefaultConditionSwitchField ({ name, initialValue, ...props }) {
  const { t } = useTranslation()
  const { input } = useField(name)

  useDeepCompareEffect(() => {
    if (initialValue && !input.value) {
      input.onChange(true)
    }
  }, [input.value])
  return (
    <>
      <SwitchField
        data-cy={name}
        name={name}
        {...props}
      />
      {t('Default condition')}
    </>
  )
}

DefaultConditionSwitchField.propTypes = {
  initialValue: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired
}

export default DefaultConditionSwitchField
