import React from 'react'

import SelectField from '~/components/Fields/SelectField'
import { COMPLETED, IN_PROGRESS, NEW, ON_HOLD } from '~/components/Statuses/PackingStatus'

const OPTIONS = [
  { value: null, name: 'All' },
  { value: NEW, name: 'New' },
  { value: IN_PROGRESS, name: 'In Progress' },
  { value: ON_HOLD, name: 'On hold' },
  { value: COMPLETED, name: 'Completed' },
]
function PackingStatusField (props) {
  return (
    <SelectField
      name="status"
      label="Status"
      options={OPTIONS}
      {...props}
    />
  )
}

export default PackingStatusField
