import React from 'react'
import Grid from '@mui/material/Grid'
import { Filter } from 'storfox-filter'
import { prop } from 'ramda'
import { DateTimeField } from 'storfox-form-fields'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

import { escapeAtob, unescapeBtoa } from '~/utils'
import WarehouseMultiSelectField from '~/components/Fields/WarehouseMultiSelectField'
import Accordion from '~/components/Accordion'
import { getDateTimeGMTDifference, getDateTimeGMTUser } from '~/utils/date'

import PackingStatusField from './PackingStatusField'
import PackingItemField from './PackingItemField'

export const toParams = values => {
  const status = prop('status', values)
  const objects = unescapeBtoa(prop('objects', values))
  const warehouses = unescapeBtoa(prop('warehouses', values))

  const createdAtStart = getDateTimeGMTDifference(prop('createdAtStart', values))
  const createdAtEnd = getDateTimeGMTDifference(prop('createdAtEnd', values))
  const updatedAtStart = getDateTimeGMTDifference(prop('updatedAtStart', values))
  const updatedAtEnd = getDateTimeGMTDifference(prop('updatedAtEnd', values))

  return {
    status,
    objects,
    warehouses,
    createdAtStart,
    createdAtEnd,
    updatedAtStart,
    updatedAtEnd
  }
}

export const toValues = params => {
  const status = prop('status', params)
  const objects = escapeAtob(prop('objects', params))
  const warehouses = escapeAtob(prop('warehouses', params))

  const createdAtStart = getDateTimeGMTUser(prop('createdAtStart', params))
  const createdAtEnd = getDateTimeGMTUser(prop('createdAtEnd', params))
  const updatedAtStart = getDateTimeGMTUser(prop('updatedAtStart', params))
  const updatedAtEnd = getDateTimeGMTUser(prop('updatedAtEnd', params))

  return {
    status,
    objects,
    warehouses,
    createdAtStart,
    createdAtEnd,
    updatedAtStart,
    updatedAtEnd
  }
}
export const name = 'packing'
export const transformer = { toParams, toValues }

export const packingFilterOptions = {
  name,
  transformer
}

function PackingFilterForm (props) {
  const { t } = useTranslation()
  return (
    <Filter {...props}>
      <Accordion title="General" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <PackingStatusField />
          </Grid>
          <Grid item={true} xs={12}>
            <PackingItemField />
          </Grid>
          <Grid item={true} xs={12}>
            <WarehouseMultiSelectField />
          </Grid>
        </Grid>
      </Accordion>
      <Accordion title="Date">
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <Typography variant="body2" gutterBottom={true}>
              {t('Created')}:
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <DateTimeField name="createdAtStart" label="Start" />
              </Grid>
              <Grid item={true} xs={12}>
                <DateTimeField name="createdAtEnd" label="End" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography variant="body2" gutterBottom={true}>
              {t('Updated')}:
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <DateTimeField name="updatedAtStart" label="Start" />
              </Grid>
              <Grid item={true} xs={12}>
                <DateTimeField name="updatedAtEnd" label="End" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Accordion>
    </Filter>
  )
}

export default PackingFilterForm
