import React from 'react'
import { GridToolbarContainer, GridToolbarDensitySelector } from '@mui/x-data-grid-pro'
import { styled } from '@mui/material/styles'

const GridToolbarContainerStyled = styled(GridToolbarContainer)(({ theme }) => ({
  padding: theme.spacing(1, 2)
}))

function GridToolbarComponent () {
  return (
    <GridToolbarContainerStyled>
      <GridToolbarDensitySelector />
    </GridToolbarContainerStyled>
  )
}

export default GridToolbarComponent
