import { useAllSearchParams } from 'storfox-route-hooks'
import { useList, useLimitedCountList, useCreate } from 'storfox-api-hooks'
import { prop } from 'ramda'

import { queryToParams } from '~/utils'
import * as API from '~/constants/api'

import { UnitFilterSerializer } from '../serializers'

export const useUnitList = () => {
  const searchParams = useAllSearchParams()

  const params = queryToParams(UnitFilterSerializer, searchParams, 'guid')

  return useLimitedCountList(API.UNIT_NUMBER_LIST, params)
}

export const useUnitHistoryList = () => {
  const searchParams = useAllSearchParams()
  const params = queryToParams(params => ({ guid: prop('guid', params) }), searchParams)
  const { getList, ...state } = useList(API.UNIT_HISTORY, { ...params, limit: 1000, page: 0 })

  return {
    ...state,
    getList: restParams => getList({ ...params, ...restParams })
  }
}

export const useUnitGenerateBarcode = () => {
  const { state, create } = useCreate(API.UNIT_BARCODE_GENERATE)
  return { ...state, generate: create }
}
