import PropTypes from 'prop-types'
import React from 'react'
import { GridRow } from '@mui/x-data-grid-pro'

function RowComponent ({ children, index, ...props }) {
  return (
    <GridRow data-cy={`grid-row-${index}`} index={index} {...props}>
      {children}
    </GridRow>
  )
}

RowComponent.propTypes = {
  children: PropTypes.any,
  index: PropTypes.number.isRequired
}

export default RowComponent
