import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import AreaCreateContainer from './containers/AreaCreateContainer'
import AreaUpdateContainer from './containers/AreaUpdateContainer'
import AreaDetailContainer from './containers/AreaDetailContainer'

export default () => {
  return [
    {
      layout: AuthLayout,
      path: ROUTES.AREA_CREATE_PATH,
      component: AreaCreateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.AREA_UPDATE_PATH,
      component: AreaUpdateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.AREA_DETAIL_PATH,
      component: AreaDetailContainer
    }
  ]
}
