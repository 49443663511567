import React from 'react'
import PropTypes from 'prop-types'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel'
import { SwitchField } from 'storfox-form-fields'
import { useTranslation } from 'react-i18next'

import { CardHeader } from '~/components/Cards'
import DimensionField from '~/components/Fields/DimensionField'
import StatusField from '~/components/Fields/StatusField'
import WarehouseMultiSelectField from '~/components/Fields/WarehouseMultiSelectField'
import TextField from '~/components/Fields/TextField'
import VolumeField from '~/components/Fields/VolumeField'
import WeightField from '~/components/Fields/WeightField'
import { withForm } from '~/components/Form'
import PageTitle from '~/components/PageTitle'
import { DiscardButton, SaveButton } from '~/components/Buttons'
import Container, { Content, Header } from '~/components/Container'
import * as NAV from '~/constants/nav-titles'

import ContainerTypeImageUpload from './ContainerTypeImageUpload'

import OperationsField from '../Fields/OperationsField'

function ContainerTypeCreateForm (props) {
  const { t } = useTranslation()
  const {
    pageTitle,
    pageTitleLoading,
    isLoading,
    form
  } = props

  return (
    <form onSubmit={form.handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={pageTitle}
            pageTitleLoading={pageTitleLoading}
            parentTitle={NAV.CONFIGURATION}
            rightButton={(
              <>
                <DiscardButton disabled={isLoading} />
                <SaveButton disabled={isLoading} />
              </>
            )}
          />
        </Header>

        <Content>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12} lg={8}>
              <Box mb={3}>
                <Card>
                  <CardHeader title="Information" />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12}>
                        <WarehouseMultiSelectField />
                      </Grid>
                      <Grid item={true} xs={12}>
                        <TextField
                          data-cy="name"
                          name="name"
                          label="Name"
                        />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <OperationsField />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <StatusField />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <FormControl style={{ padding: '0 12px' }}>
                          <FormControlLabel
                            control={<SwitchField data-cy="is-default" name="isDefault" />}
                            label={t('Default Container Type')}
                          />
                        </FormControl>
                      </Grid>

                    </Grid>
                  </CardContent>
                </Card>
              </Box>

              <Box mb={3}>
                <Card>
                  <CardHeader title="Profile Photo" />
                  <Divider />
                  <CardContent>
                    <ContainerTypeImageUpload />
                  </CardContent>
                </Card>
              </Box>
            </Grid>

            <Grid item={true} xs={12} lg={4}>
              <Box mb={3}>
                <Card>
                  <CardHeader title="Dimensions" />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12} lg={6}>
                        <DimensionField
                          data-cy="height"
                          name="height"
                          label="Height"
                        />
                      </Grid>

                      <Grid item={true} xs={12} lg={6}>
                        <DimensionField
                          data-cy="width"
                          name="width"
                          label="Width"
                        />
                      </Grid>

                      <Grid item={true} xs={12} lg={6}>
                        <DimensionField
                          data-cy="length"
                          name="length"
                          label="Length"
                        />
                      </Grid>

                      <Grid item={true} xs={12} lg={6}>
                        <WeightField
                          data-cy="maxLoad"
                          name="maxLoad"
                          label="Max. Load"
                        />
                      </Grid>

                      <Grid item={true} xs={12} lg={12}>
                        <VolumeField disabled={true} />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Content>
      </Container>
    </form>
  )
}

ContainerTypeCreateForm.propTypes = {
  pageTitle: PropTypes.string,
  pageTitleLoading: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default withForm(ContainerTypeCreateForm)
