import React, { useRef } from 'react'
import { FieldArray, useFieldArray } from 'react-final-form-arrays'
import Grid from '@mui/material/Grid'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import { find, length, map, path, pathEq, prop, propEq, propOr, pathOr, defaultTo } from 'ramda'
import PropTypes from 'prop-types'
import { useField } from 'react-final-form'
import IconButton from '@mui/material/IconButton'
import Delete from '@mui/icons-material/Delete'
import { useTranslation } from 'react-i18next'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import { matchContainer } from '~/utils'
import Avatar from '~/components/Avatar/Avatar'
import TextOverflow from '~/components/TextOverflow'
import Subtext from '~/components/Subtext'
import VerticalAlignment from '~/components/VerticalAlignment'
import TableDateFormat from '~/components/TableDateFormat'
import EmptyRow from '~/components/EmptyRow'
import { useUnitsPopover } from '~/components/DuplicateUnitsTable/hooks'
import ErrorLink from '~/components/ErrorLink'
import DuplicateUnitsTable from '~/components/DuplicateUnitsTable'
import UnitPopoverField from '~/components/Fields/UnitPopoverField'
import useMessages from '~/hooks/useMessages'
import LocationField from '~/components/Fields/LocationField'
import * as API from '~/constants/api'
import ContainerField from '~/components/Fields/ContainerField'
import ConstrainedField from '~/components/Fields/ConstrainedField'

const getResult = result => {
  const totalQuantity = prop('quantity', result)

  return { ...result, totalQuantity, quantity: 1 }
}

function MovementTable ({ scanMethods }) {
  const { t } = useTranslation()
  const barcodeRef = useRef({})
  const popover = useUnitsPopover()
  const snackbar = useSnackbar()
  const messages = useMessages()

  const warehouse = useField('warehouse')
  const warehouseId = path(['input', 'value', 'id'], warehouse)
  const warehouseGuid = path(['input', 'value', 'guid'], warehouse)

  const barcodeField = useField('barcode')
  const handleBarcodeClear = () => barcodeField.input.onChange('')

  const lineItemsFieldArray = useFieldArray('lineItems')
  const push = lineItemsFieldArray.fields.push
  const lineItemsField = useField('lineItems')
  const lineItemsFieldError = pathOr('', ['meta', 'submitError', 0], lineItemsField)
  const lineItemsError =
    typeof lineItemsFieldError === 'string' ? lineItemsFieldError : JSON.stringify(lineItemsFieldError)
  const lineItems = lineItemsField.input.value

  const {
    isLoading,
    handleVariantScan,
    handleContainerScan
  } = scanMethods

  const handleUnitAdd = unit => {
    const duplicate = find(unitItem => {
      const unitNumber = prop('unitNumber', unitItem)
      const serialNumber = prop('serialNumber', unitItem)
      const batchNumber = prop('batchNumber', unitItem)
      const expiresAt = prop('expiresAt', unitItem)
      const isRejected = prop('isRejected', unitItem)
      const currency = prop('currency', unitItem)
      const price = prop('price', unitItem)
      const conditionCode = path(['condition', 'code'], unitItem)
      const containerNumber = path(['container', 'number'], unitItem)
      const location = path(['location', 'locationId'], unitItem)

      return (
        propEq('unitNumber', unitNumber, unit) &&
        propEq('serialNumber', serialNumber, unit) &&
        propEq('batchNumber', batchNumber, unit) &&
        propEq('isRejected', isRejected, unit) &&
        propEq('expiresAt', expiresAt, unit) &&
        propEq('currency', currency, unit) &&
        propEq('price', price, unit) &&
        pathEq(['condition', 'code'], conditionCode, unit) &&
        pathEq(['container', 'number'], containerNumber, unit) &&
        pathEq(['location', 'locationId'], location, unit)
      )
    }, lineItems)

    if (!duplicate) {
      push(unit)
    }
  }

  const handleClick = unit => {
    popover.handleClose()
    if (popover.open) {
      handleUnitAdd(unit)
      handleBarcodeFocus()
    }
  }

  const handleBarcodeFocus = () => {
    barcodeRef.current.focus()
  }

  const handleVariantAdd = results => {
    if (results.length > 1) {
      popover.handleOpen()
      const items = map(getResult, results)
      popover.setItems(items)
    } else {
      const item = getResult(results[0])
      handleUnitAdd(item)
    }
  }

  const handleContainer = value => {
    return handleContainerScan(value)
      .then((response) => {
        const result = path(['data', 'result'], response)
        const units = propOr([], 'units', result)
        const unitsWithLocation = units.map((unit) => ({ ...unit, location: prop('location', result) }))
        if (units.length === 0) {
          snackbar({ message: messages.NO_UNITS, type: ALTER_ERROR })
        } else {
          handleVariantAdd(unitsWithLocation)
        }
      })
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })
      .finally(() => {
        handleBarcodeClear()
        handleBarcodeFocus()
      })
  }

  const handleVariant = value => {
    return handleVariantScan({ unitNumber: value, warehouseId })
      .then((response) => {
        const results = propOr([], 'results', response)
        if (results.length === 0) {
          snackbar({ message: messages.NO_UNITS, type: ALTER_ERROR })
        } else {
          handleVariantAdd(results)
        }
      })
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })
      .finally(() => {
        handleBarcodeClear()
        handleBarcodeFocus()
      })
  }

  const handleChange = value => {
    const isContainer = matchContainer(value)

    if (isContainer) {
      handleContainer(value)
    } else {
      handleVariant(value)
    }
  }

  return (
    <FieldArray
      name="lineItems"
      render={({ fields }) => {
        const info = !length(propOr([], 'value', fields))
        const values = defaultTo([], fields.value)
        return (
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12}>
              <UnitPopoverField
                barcodeRef={barcodeRef}
                autoFocus={true}
                name="barcode"
                data-cy="barcode"
                disabled={isLoading || !warehouseId}
                open={popover.open}
                onClose={popover.handleClose}
                onChange={handleChange}
                popoverContent={(
                  <DuplicateUnitsTable
                    items={popover.items}
                    onClick={handleClick}
                  />
                )}
                error={lineItemsError}
              />
            </Grid>
            <Grid item={true} xs={12}>
              <TableContainer>
                <Table size="small" sx={{ minWidth: '700px' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('Image')}</TableCell>
                      <TableCell sx={{ width: 250 }}>{t('Variant')}</TableCell>
                      <TableCell>{t('Unit number')}</TableCell>
                      <TableCell>{t('quantity')}</TableCell>
                      <TableCell>{t('Total quantity')}</TableCell>
                      <TableCell>{t('Serial number')}</TableCell>
                      <TableCell sx={{ minWidth: 150 }}>{t('Batch number')}</TableCell>
                      <TableCell>{t('Condition code')}</TableCell>
                      <TableCell sx={{ minWidth: 150 }}>{t('Expiry date')}</TableCell>
                      <TableCell sx={{ minWidth: 150 }}>{t('From location')}</TableCell>
                      <TableCell sx={{ minWidth: 150 }}>{t('From container')}</TableCell>
                      <TableCell sx={{ minWidth: 250 }}>{t('To location')}</TableCell>
                      <TableCell sx={{ minWidth: 250 }}>{t('To container')}</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values.map((item, index) => {
                      const variantName = path(['variant', 'name'], item)
                      const sku = path(['variant', 'sku'], item)
                      const imagePath = path(['variant', 'defaultImage'], item)
                      const unitNumber = prop('unitNumber', item)
                      const totalQuantity = prop('totalQuantity', item)
                      const serialNumber = prop('serialNumber', item)
                      const batchNumber = prop('batchNumber', item)
                      const conditionCode = path(['condition', 'code'], item)
                      const expiresAt = prop('expiresAt', item)
                      const containerNumber = path(['container', 'number'], item)
                      const location = path(['location', 'locationId'], item)
                      const toLocationGuid = path(['toLocation', 'guid'], item)

                      return (
                        <TableRow key={index}>
                          <TableCell>
                            <Avatar
                              alt={variantName}
                              src={imagePath}
                            />
                          </TableCell>
                          <TableCell>
                            <VerticalAlignment
                              sx={{ minWidth: 200 }}
                              primary={(
                                <TextOverflow selfTooltip={true}>
                                  {variantName}
                                </TextOverflow>
                              )}
                              secondary={<Subtext lines={1}>{sku}</Subtext>}
                            />
                          </TableCell>
                          <TableCell>{unitNumber}</TableCell>
                          <TableCell>
                            <ConstrainedField
                              data-cy={`lineItems[${index}].quantity`}
                              name={`lineItems[${index}].quantity`}
                              minValue={0}
                              maxValue={totalQuantity}
                            />
                          </TableCell>
                          <TableCell align="center">{totalQuantity}</TableCell>
                          <TableCell>{serialNumber}</TableCell>
                          <TableCell>{batchNumber}</TableCell>
                          <TableCell>{conditionCode}</TableCell>
                          <TableCell>{expiresAt && <TableDateFormat date={expiresAt} />}</TableCell>
                          <TableCell>{location}</TableCell>
                          <TableCell>{containerNumber}</TableCell>
                          <TableCell>
                            <LocationField
                              data-cy={`lineItems[${index}].toLocation`}
                              name={`lineItems[${index}].toLocation`}
                              api={API.STOCKS_LOCATION_LIST}
                              params={{ warehouseId }}
                              ListboxProps={{ 'data-cy': 'locationList' }}
                            />
                          </TableCell>
                          <TableCell>
                            <ContainerField
                              data-cy={`lineItems[${index}].toContainer`}
                              name={`lineItems[${index}].toContainer`}
                              api={API.CONTAINER_LIST}
                              params={{ warehouseGuid, locationGuid: toLocationGuid }}
                              ListboxProps={{ 'data-cy': 'containerList' }}
                              disabled={!toLocationGuid}
                            />
                          </TableCell>
                          <TableCell align="right">
                            <IconButton onClick={() => fields.remove(index)} size="large">
                              <Delete />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                    {info && <EmptyRow colSpan={9} />}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        )
      }}
    />
  )
}

MovementTable.propTypes = {
  scanMethods: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    handleVariantScan: PropTypes.func.isRequired,
    handleContainerScan: PropTypes.func.isRequired
  }).isRequired
}

export default MovementTable
