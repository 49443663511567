import { AuthLayout, BlankLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import SallaIntegrationCreateContainer from './SallaIntegrationCreateContainer'
import SallaIntegrationUpdateContainer from './SallaIntegrationUpdateContainer'
import SallaInstructionContainer from './SallaInstructionContainer'
import SallaInstallContainer from './SallaInstallContainer'

export default () => {
  return [
    {
      layout: AuthLayout,
      path: ROUTES.SALLA_INSTRUCTION_CONTAINER,
      component: SallaInstructionContainer
    },
    {
      layout: BlankLayout,
      path: ROUTES.SALLA_INSTALL_PATH,
      component: SallaInstallContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.SALLA_INTEGRATION_CREATE_PATH,
      component: SallaIntegrationCreateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.SALLA_INTEGRATION_UPDATE_PATH,
      component: SallaIntegrationUpdateContainer
    }
  ]
}
