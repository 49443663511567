import { useAllSearchParams } from 'storfox-route-hooks'
import { useCreate, useLimitedCountList } from 'storfox-api-hooks'

import { queryToParams } from '~/utils'
import * as API from '~/constants/api'

import { UnitHistoryFilterSerializer } from '../../serializers/UnitHistory'

export const useUnitHistoryList = (autoSend) => {
  const searchParams = useAllSearchParams()

  const params = queryToParams(UnitHistoryFilterSerializer, searchParams, 'guid')

  return useLimitedCountList(API.UNIT_HISTORY_REPORT_LIST, params, autoSend)
}

export const useUnitHistoryReportGenerate = () => {
  const searchParams = useAllSearchParams()
  const params = queryToParams(UnitHistoryFilterSerializer, searchParams, 'guid')
  const { create, ...state } = useCreate(API.UNIT_HISTORY_REPORT_CREATE, { ...searchParams, ...params })

  return { ...state, generate: rest => create({ ...rest, ...params }) }
}
