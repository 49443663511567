import React from 'react'
import { range } from 'ramda'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Skeleton from '@mui/material/Skeleton'

function Loader () {
  const items = range(0, 5)

  return <>
    {items.map((index) => (
      <TableRow
        hover={true}
        key={index}
      >
        <TableCell>
          <Skeleton
            sx={{ borderRadius: '5px' }}
            variant="rectangular"
            height={40}
            width={40}
          />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
      </TableRow>
    ))}
  </>
}

export default Loader
