import React from 'react'
import PropTypes from 'prop-types'
import { prop } from 'ramda'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { Divider } from '@mui/material'
import Box from '@mui/material/Box'

import { Button, EditButton } from '~/components/Buttons'
import Container, { Content, Header } from '~/components/Container'
import PageTitle from '~/components/PageTitle'
import Hide from '~/components/Hide'
import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'
import { RECEIVE_TABS, TRANSFER_DETAIL_TABS } from '~/constants/tabs'
import LoaderProvider from '~/components/Loader'
import History from '~/components/History'

import More from './More'
import TransferContent from './TransferContent'
import TransferDetailTabs from './TransferDetailTabs'

import { CANCELLED, COMPLETED, DRAFT, IN_PROGRESS } from '../../constants'

function TransferDetail (props) {
  const {
    pageTitle,
    pageTitleLoading,
    isLoading,
    onProcess,
    onComplete,
    onCancel,
    initialValues,
    history
  } = props

  const { guid, tab } = useParams()
  const navigate = useNavigate()

  const status = prop('status', initialValues)
  const isReceiver = prop('isReceiver', initialValues)
  const receiptGuid = prop('receiptGuid', initialValues)

  const handleReceive = () => {
    const path = generatePath(ROUTES.RECEIVE_TRANSFER_DETAIL_PATH, { guid: receiptGuid, tab: RECEIVE_TABS.GENERAL })

    return navigate(path)
  }

  const disableReceive = !(
    status === COMPLETED ||
    status === IN_PROGRESS
  )

  const disableEdit = (
    status === COMPLETED ||
    status === CANCELLED ||
    status === IN_PROGRESS
  )

  const cancelDisable = status === IN_PROGRESS || status === COMPLETED

  const editPath = generatePath(ROUTES.TRANSFER_UPDATE_PATH, { guid })
  return (
    <LoaderProvider isLoading={isLoading}>
      <Container>
        <Header>
          <PageTitle
            parentTitle={NAV.TRANSFER}
            pageTitle={pageTitle}
            pageTitleLoading={pageTitleLoading}
            rightButton={(
              <>
                <Hide hidden={!isReceiver || status !== DRAFT}>
                  <Button
                    variant="contained"
                    data-cy="process"
                    onClick={onProcess}
                    disabled={isLoading}
                  >
                    Process
                  </Button>
                </Hide>
                <Hide hidden={!isReceiver}>
                  <Button
                    variant="contained"
                    data-cy="receive"
                    disabled={disableReceive}
                    onClick={handleReceive}
                  >
                    Receive
                  </Button>
                </Hide>
                <Hide hidden={!isReceiver || status !== IN_PROGRESS}>
                  <Button
                    variant="contained"
                    onClick={onComplete}
                    disabled={isLoading}
                  >
                    Complete
                  </Button>
                </Hide>
                <EditButton path={editPath} disabled={disableEdit} />
                <More onTransferCancel={onCancel} cancelDisable={cancelDisable} />
              </>
            )}
          />
        </Header>
        <Content>
          <TransferDetailTabs value={tab} />
          <Divider />
          <Box mb={3} />
          {tab === TRANSFER_DETAIL_TABS.GENERAL && (
            <TransferContent isLoading={isLoading} content={initialValues} />
          )}
          {tab === TRANSFER_DETAIL_TABS.HISTORY && <History history={history} />}
        </Content>
      </Container>
    </LoaderProvider>
  )
}

TransferDetail.propTypes = {
  pageTitleLoading: PropTypes.bool.isRequired,
  pageTitle: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onProcess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

export default TransferDetail
