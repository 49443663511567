import { useState } from 'react'
import { path, propOr } from 'ramda'

import { emptyObject } from '~/constants/empty'

function useTableBulkActions (name, anchorRef, options) {
  const [open, setOpen] = useState(false)

  const bulkActionButtons = JSON.parse(localStorage.getItem('bulkActionButtons')) || emptyObject
  const defaultLabel = path([0, 'label'], options)
  const initialSelected = propOr(defaultLabel, name, bulkActionButtons)

  const [selected, setSelected] = useState(initialSelected)

  const handleMenuItemClick = (event, option) => {
    setSelected(option.label)

    const newSplitButtons = {
      ...bulkActionButtons,
      [name]: option.label
    }

    localStorage.setItem('tableBulkActionButtons', JSON.stringify(newSplitButtons))
    option.onMenuClick()
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  return {
    open,
    selected,
    handleMenuItemClick,
    handleToggle,
    handleClose
  }
}

export default useTableBulkActions
