import React from 'react'
import { propOr } from 'ramda'
import { MultiSelectSearchField } from 'storfox-form-fields'

import * as API from '~/constants/api'

const getOptionLabel = propOr('', 'companyName')

const getOptionValue = item => {
  if (item) {
    const { id, companyName, guid } = item

    return { id, companyName, guid }
  }

  return null
}

function ReturnSupplierField (props) {
  return (
    <MultiSelectSearchField
      name="supplier"
      label="Supplier"
      api={API.SUPPLIER_RETURN_SUPPLIERS}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      {...props}
    />
  )
}

export default ReturnSupplierField
