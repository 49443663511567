import React from 'react'
import PropTypes from 'prop-types'

import TableSplitButton from '~/components/TableSplitButton'
import useDeleteConfirm from '~/components/TableSplitButton/useDeleteConfirm'

function BulkActionButton ({ onProcess, onComplete, onTransfersDelete }) {
  const { handleClick } = useDeleteConfirm()

  const options = [
    {
      label: 'Process',
      'data-cy': 'processMenu',
      onMenuClick: onProcess
    },
    {
      label: 'Complete',
      'data-cy': 'completeMenu',
      onMenuClick: onComplete
    },
    {
      label: 'Delete',
      'data-cy': 'deleteMenu',
      onMenuClick: () => handleClick(onTransfersDelete)
    }
  ]

  return (
    <TableSplitButton
      options={options}
      name="transfer"
    />
  )
}

BulkActionButton.propTypes = {
  onProcess: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  onTransfersDelete: PropTypes.func.isRequired
}

export default BulkActionButton
