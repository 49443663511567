import React, { useEffect } from 'react'
import TableRow from '@mui/material/TableRow'
import { CheckboxField } from 'storfox-form-fields'
import TableCell from '@mui/material/TableCell/TableCell'
import { path, pathOr, prop } from 'ramda'
import PropTypes from 'prop-types'
import { useField } from 'react-final-form'

import ConstrainedField from '~/components/Fields/ConstrainedField'

function ManualAllocationRow ({ lineItems, item }) {
  const id = prop('id', item)
  const guid = prop('guid', item)
  const quantity = prop('quantity', item)
  const serialNumber = prop('serialNumber', item)
  const batchNumber = prop('batchNumber', item)
  const location = pathOr('', ['location', 'locationId'], item)
  const unitNumber = prop('unitNumber', item)
  const expiresAt = prop('expiresAt', item)
  const conditionCode = pathOr('', ['condition', 'code'], item)
  const checkedItem = path([guid, 'checked'], lineItems)

  const itemField = useField(`lineItems[${guid}]`)
  const itemFieldValue = itemField.input.value

  useEffect(() => {
    const itemChecked = prop('checked', itemFieldValue)
    const unitNumber = prop('unitNumber', itemFieldValue)
    if (itemChecked && !unitNumber) {
      itemField.input.onChange({ ...item, ...itemFieldValue })
    }
  }, [itemField, item, itemFieldValue])

  return (
    <TableRow key={id}>
      <TableCell>
        <CheckboxField name={`lineItems[${guid}].checked`} />
      </TableCell>
      <TableCell align="left">{unitNumber}</TableCell>
      <TableCell>{serialNumber}</TableCell>
      <TableCell>{batchNumber}</TableCell>
      <TableCell>{location}</TableCell>
      <TableCell>{conditionCode}</TableCell>
      <TableCell>{expiresAt}</TableCell>
      <TableCell>{quantity}</TableCell>
      <TableCell>
        <ConstrainedField
          disabled={!checkedItem}
          name={`lineItems[${guid}].allocateQuantity`}
          maxValue={quantity}
        />
      </TableCell>
    </TableRow>
  )
}

ManualAllocationRow.propTypes = {
  lineItems: PropTypes.array,
  item: PropTypes.object
}

export default ManualAllocationRow
