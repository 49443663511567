import React from 'react'
import { prop } from 'ramda'
import PropTypes from 'prop-types'

import StatusGrid, { StatusGridItem } from '~/components/StatusGrid'
import { FulfillmentStatus, ShipmentStatus, AllocationStatus } from '~/components/Statuses'

import RefundStatus from '../RefundStatus'
import ReturnStatus from '../ReturnStatus'

function ReturnStatuses ({ isLoading, detail }) {
  const returnStatus = prop('status', detail)
  const allocationStatus = prop('allocationStatus', detail)
  const fulfillmentStatus = prop('fulfillmentStatus', detail)
  const shipmentStatus = prop('shipmentStatus', detail)
  const refundStatus = prop('refundStatus', detail)

  return (
    <StatusGrid>
      <StatusGridItem title="Return" isLoading={isLoading}>
        <ReturnStatus value={returnStatus} />
      </StatusGridItem>
      <StatusGridItem title="Allocation" isLoading={isLoading}>
        <AllocationStatus value={allocationStatus} />
      </StatusGridItem>
      <StatusGridItem title="Fulfillment" isLoading={isLoading}>
        <FulfillmentStatus value={fulfillmentStatus} />
      </StatusGridItem>
      <StatusGridItem title="Shipment" isLoading={isLoading}>
        <ShipmentStatus value={shipmentStatus} />
      </StatusGridItem>
      <StatusGridItem title="Refund" isLoading={isLoading}>
        <RefundStatus value={refundStatus} />
      </StatusGridItem>
    </StatusGrid>
  )
}

ReturnStatuses.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  detail: PropTypes.object.isRequired
}

export default ReturnStatuses
