import React from 'react'
import PropTypes from 'prop-types'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { prop } from 'ramda'
import { Alert, AlertTitle } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import { ColorField } from 'storfox-form-fields'

import { CardHeader } from '~/components/Cards'
import CountryField from '~/components/Fields/CountryField'
import CurrencyField from '~/components/Fields/CurrencyField'
import LanguageField from '~/components/Fields/LanguageField'
import PhoneNumberField from '~/components/Fields/PhoneNumberField'
import SingleImageUploadField from '~/components/Fields/SingleImageUploadField'
import SizeUnitField from '~/components/Fields/SizeUnitField'
import TextField from '~/components/Fields/TextField'
import TimeZoneField from '~/components/Fields/TimeZoneField'
import WeightUnitField from '~/components/Fields/WeightUnitField'
import PageTitle from '~/components/PageTitle'
import { DiscardButton, SaveButton } from '~/components/Buttons'
import Container, { Content, Header } from '~/components/Container'
import { withForm } from '~/components/Form'
import * as NAV from '~/constants/nav-titles'

import AccountTypeField from '../Fields/AccountTypeField'

function AccountUpdateForm ({ isLoading, form }) {
  const { initialValues, handleSubmit } = form

  const isInvited = prop('isInvited', initialValues)

  const { t } = useTranslation()

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={NAV.ACCOUNT}
            parentTitle={NAV.SETTINGS}
            rightButton={(
              <>
                <DiscardButton disabled={isLoading} />
                <SaveButton disabled={isLoading} />
              </>
            )}
          />
        </Header>

        <Content>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12} lg={8} >
              <Box mb={3}>
                <Card>
                  <CardHeader title="Company Information" />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12}>
                        <TextField
                          data-cy="name"
                          name="name"
                          label="Company Name"
                        />
                      </Grid>
                      <Grid item={true} xs={12}>
                        <AccountTypeField
                          data-cy="type"
                          name="type"
                          label="Company Type"
                          disabled={isInvited}
                        />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <TextField
                          data-cy="email"
                          name="email"
                          label="Email"
                        />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <PhoneNumberField
                          data-cy="phone"
                          name="phone"
                          label="Phone number"
                        />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <TextField
                          data-cy="address"
                          name="address"
                          label="Address"
                          multiline={true}
                          minRows={3}
                        />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <CountryField
                          data-cy="country"
                          name="country"
                          label="Country"
                        />
                      </Grid>

                      <Grid item={true} xs={12} lg={6}>
                        <TextField
                          data-cy="city"
                          name="city"
                          label="City"
                          country="country"
                        />
                      </Grid>

                      <Grid item={true} xs={12} lg={6}>
                        <TextField
                          data-cy="zipcode"
                          name="zipcode"
                          label="Zipcode"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Grid>

            <Grid item={true} xs={12} lg={4}>
              <Box mb={3}>
                <Card>
                  <CardHeader title="Logo" />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12}>
                        <SingleImageUploadField name="logo" accept="image/svg+xml" original={true} />
                        <Box mt={1}>
                          <Alert severity="info">
                            <AlertTitle>
                              {t('Image format')}
                            </AlertTitle>
                            <Typography gutterBottom={true}>
                              Extension: {t('.SVG (Dark version of the Logo)')}
                            </Typography>
                            <Typography>
                              {t('Aspect ratio')}: 25:6
                            </Typography>
                          </Alert>
                        </Box>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
              <Box mb={3}>
                <Card>
                  <CardHeader title="Favicon" />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12}>
                        <SingleImageUploadField name="favicon" accept="image/x-icon" />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
              <Box mb={3}>
                <Card>
                  <CardHeader title="Branding" />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12}>
                        <ColorField
                          name="primaryColor"
                          label={t('Primary color')}
                        />
                      </Grid>
                      <Grid item={true} xs={12}>
                        <ColorField
                          name="secondaryColor"
                          label={t('Secondary color')}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
              <Box mb={3}>
                <Card>
                  <CardHeader title="Settings" />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12}>
                        <LanguageField label="Default Language" />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <TimeZoneField
                          countryName="country"
                          label="Default Timezone"
                        />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <CurrencyField
                          label="Default Currency"
                        />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <WeightUnitField label="Default Weight Unit" />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <SizeUnitField label="Default Size Unit" />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Content>
      </Container>
    </form>
  )
}

AccountUpdateForm.propTypes = {
  form: PropTypes.object,
  isLoading: PropTypes.bool.isRequired
}

export default withForm(AccountUpdateForm)
