import React from 'react'
import PropTypes from 'prop-types'

import PositiveNumberField from './PositiveNumberField'

function PieceQuantityField ({ name, ...props }) {
  return (
    <PositiveNumberField
      name={name}
      inputProps={{ maxLength: 4 }}
      {...props}
    />
  )
}

PieceQuantityField.propTypes = {
  name: PropTypes.string.isRequired
}

export default PieceQuantityField
