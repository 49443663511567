import React from 'react'
import { Ordering } from 'storfox-filter'

import { COMPANY_COLUMNS as tableColumns } from './CompanyTable'

import { FILTER_NAME as name } from '../../constants'

export const companyOrderingOptions = {
  name,
  tableColumns
}

function CompanyOrderingForm (props) {
  return (
    <Ordering {...props} />
  )
}

export default CompanyOrderingForm
