import React from 'react'
import PropTypes from 'prop-types'
import ErrorIcon from '@mui/icons-material/Error'

import Tooltip from '~/components/HtmlTooltip'

function CarrierResponse ({ value }) {
  return (
    <Tooltip title={value}>
      <ErrorIcon fontSize="small" color="error" />
    </Tooltip>
  )
}

CarrierResponse.propTypes = {
  value: PropTypes.string.isRequired
}

export default CarrierResponse
