import React from 'react'
import { pathOr, prop } from 'ramda'
import Link from '@mui/material/Link'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import printJS from 'print-js'
import { styled } from '@mui/material'

import {
  LINK,
  PAGE_REFRESH,
  SHOPIFY_PRODUCTS_SYNC_STATUS
} from '~/constants/notification-topics'
import { generateCdnUrl } from '~/utils'
import TextOverflow from '~/components/TextOverflow'

const BoxStyled = styled(Box)({
  display: 'flex',
})

const TextButtonStyled = styled(TextOverflow)({
  textDecoration: 'underline',
  marginLeft: '10px',
  cursor: 'pointer'
})

const NotificationMessage = ({ topic, payload }) => {
  switch (topic) {
    case LINK: {
      const url = generateCdnUrl(prop('url', payload))

      return (
        <BoxStyled mt={1}>
          <Link
            href={url}
            target="_blank"
            variant="body2"
            color="secondary"
            underline="always"
            rel="noopener noreferrer"
            sx={{ color: 'white' }}
          >
            Download
          </Link>
          <TextButtonStyled onClick={() => printJS({ printable: url, showModal: true, type: 'pdf' })}>
            Print
          </TextButtonStyled>
        </BoxStyled>
      )
    }
    case PAGE_REFRESH: {
      return (
        <Box mt={1}>
          <Link
            onClick={() => window.location.reload()}
            variant="body2"
            color="secondary"
            underline="always"
            sx={{ cursor: 'pointer' }}
          >
            Reload
          </Link>
        </Box>
      )
    }
    case SHOPIFY_PRODUCTS_SYNC_STATUS: {
      const productsCount = pathOr(0, ['result', 'fetched'], payload)

      return (
        <>
          {`${productsCount} products were synced`}
        </>
      )
    }

    default:
      return <></>
  }
}

NotificationMessage.propTypes = {
  topic: PropTypes.string.isRequired,
  payload: PropTypes.object.isRequired
}

export default NotificationMessage
