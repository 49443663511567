import React from 'react'
import { find, length } from 'ramda'
import PropTypes from 'prop-types'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Grid from '@mui/material/Grid'
import Dialog from '@mui/material/Dialog'

import ChannelBox from './ChannelBox'
import ChannelSkeleton from './ChannelSkeleton'

import DialogTitle from '../DialogTitle/DialogTitle'
import Button from '../Buttons/Button'
import SelectableBox from '../SelectableBox'

function ChannelDialog (props) {
  const {
    open,
    onClose,
    selectedChannelGuids,
    onChannelSelect,
    onSubmit,
    isLoading,
    channelList
  } = props

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth={true}
      onClose={onClose}
    >
      <DialogTitle>Choose channel</DialogTitle>

      <DialogContent dividers={true}>
        <Grid container={true} spacing={3}>
          {!isLoading
            ? channelList.map((channel, idx) => (
              <Grid item={true} sm={12} lg={6} key={channel.guid}>
                <SelectableBox
                  onClick={() => onChannelSelect(channel.guid)}
                  selected={Boolean(find(guid => channel.guid === guid, selectedChannelGuids))}
                >
                  <ChannelBox data-cy={`channel-${idx}`} name={channel.name} type={channel.type} />
                </SelectableBox>
              </Grid>
            ))
            : (
              <ChannelSkeleton />
            )
          }
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>
          Close
        </Button>
        <Button
          data-cy="submit"
          onClick={onSubmit}
          disabled={!length(selectedChannelGuids)}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ChannelDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChannelSelect: PropTypes.func.isRequired,
  selectedChannelGuids: PropTypes.array.isRequired,
  channelList: PropTypes.array,
  isLoading: PropTypes.bool
}

export default ChannelDialog
