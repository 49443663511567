import { pick, propOr } from 'ramda'

import { PERM_CREATE_FIELDS } from '../components/RoleCreate'
import { PERM_UPDATE_FIELDS } from '../components/RoleUpdate'

export const RoleCreateSerializer = formValues =>
  pick(PERM_CREATE_FIELDS, formValues)

export const RoleUpdateSerializer = formValues => {
  const permissions = propOr([], 'permissions', formValues)
  const enabledPermissions = permissions.filter((perm) => perm.enabled)
  const dataPermissions = enabledPermissions.map((enabledPerm) => enabledPerm.code)

  return pick(PERM_UPDATE_FIELDS, { ...formValues, permissions: dataPermissions })
}

export const RoleInitialValues = (detail, permissions = []) => {
  const enabledPermissions = permissions.map((perm) => {
    const permissions = propOr([], 'permissions', detail)
    const isEnabled = permissions.filter((detailPerm) => detailPerm.code === perm.code).length > 0
    if (isEnabled) {
      return { ...perm, enabled: true }
    } else return { ...perm, enabled: false }
  })
  return { ...detail, permissions: enabledPermissions }
}
