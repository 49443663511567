import React from 'react'
import { ImageAttach } from 'storfox-image-upload'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'

import { useImageUpload } from '~/components/ImageUpload'

import { useProductAddImage, useProductImageList } from '../../../hooks'
import { ProductImageUpdateSerializer } from '../../../serializers'

function VariantImageUploadField ({ name }) {
  const { upload } = useImageUpload()
  const { productId } = useParams()
  const productAddImage = useProductAddImage(productId)
  const productImageList = useProductImageList(productId)

  const handleGetImages = () => productImageList.getList()

  const onUpload = (data, onProgress) =>
    upload(data, onProgress).then(response => {
      const imagePath = response['ids'][0]
      const isDefault = length === 0
      const value = { imagePath, isDefault }
      return productAddImage.create(ProductImageUpdateSerializer(value)).then(() => value)
    })

  return <ImageAttach name={name} onGetImages={handleGetImages} onUpload={onUpload} />
}

VariantImageUploadField.defaultProps = {
  name: 'images'
}

VariantImageUploadField.propTypes = {
  name: PropTypes.string
}

export default VariantImageUploadField
