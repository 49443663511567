export const PROTOCOL = 'https:'
export const SOCKET_PROTOCOL = 'https:'

// PRODUCTION
export const APP_PROD_URL = 'app.storfox.com'
export const API_PROD_URL = 'dashboard-api.storfox.com'
export const CDN_PROD_URL = 'storfox-prod-cdn.s3.eu-central-1.amazonaws.com'
export const SOCKET_PROD_URL = 'websocket.storfox.com'

// STAGING
export const APP_STG_URL = 'app-stg.storfox.com'
export const API_STG_URL = 'dashboard-api-stg.storfox.com'
export const CDN_STG_URL = 'storfox-stg-cdn.s3.eu-central-1.amazonaws.com'
export const SOCKET_STG_URL = 'websocket-stg.storfox.com'

// DEVELOPMENT
export const APP_DEV_URL = 'app-dev.storfox.com'
export const API_DEV_URL = 'dashboard-api-dev.storfox.com'
export const CDN_DEV_URL = 'storfox-dev-cdn.s3.eu-central-1.amazonaws.com'
export const SOCKET_DEV_URL = 'websocket-dev.storfox.com'
