import React from 'react'
import PropTypes from 'prop-types'
import { propOr } from 'ramda'
import { useField } from 'react-final-form'
import equal from 'fast-deep-equal'
import { useConfirm } from 'storfox-confirm-dialog'

import ListingListItems, { useSearchListItems } from '../ListingListItems'

function ListingUpdateListItems ({ detail, listingGuid, onVariantOptionChange }) {
  const results = propOr([], 'listingVariants', detail)
  const { search, handleSearch, filteredResults } = useSearchListItems(results)
  const onConfirm = useConfirm()
  const oldVariantField = useField('oldVariant')
  const variantField = useField('variant')
  const oldVariant = oldVariantField.input.value
  const variant = variantField.input.value

  const handleClick = guid => {
    if (equal(oldVariant, variant)) {
      onVariantOptionChange(guid)
    }

    if (!equal(oldVariant, variant)) {
      onConfirm({ message: 'Leaving this page will discard unsaved changes.' })
        .agree(() => {
          onVariantOptionChange(guid)
        })
        .disagree()
    }
  }

  return (
    <ListingListItems
      listingGuid={listingGuid}
      filteredResults={filteredResults}
      search={search}
      onClick={handleClick}
      onSearch={handleSearch}
    />
  )
}

ListingUpdateListItems.propTypes = {
  detail: PropTypes.object.isRequired,
  listingGuid: PropTypes.string.isRequired,
  onVariantOptionChange: PropTypes.func.isRequired
}

export default ListingUpdateListItems
