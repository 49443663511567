import React from 'react'
import { useFilter, useOrdering } from 'storfox-filter'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import ErrorLink from '~/components/ErrorLink'
import useMessages from '~/hooks/useMessages'
import { useTableSelects } from '~/components/TableValues/hooks'

import { useLotSheetsList, useLotSheetsDelete } from '../hooks'
import {
  LotSheetsFilterForm,
  lotSheetsFilterOptions,
  LotSheetsOrderingForm,
  lotSheetsOrderingOptions,
  LotSheetsTable
} from '../components/LotSheetsList'

function LotSheetsListContainer () {
  const snackbar = useSnackbar()
  const messages = useMessages()

  const lotSheetsList = useLotSheetsList()
  const lotSheetsDelete = useLotSheetsDelete()
  const { selects, resetTableSelects } = useTableSelects()

  const filter = useFilter(lotSheetsFilterOptions)
  const ordering = useOrdering(lotSheetsOrderingOptions)

  const breadcrumbs = { title: NAV.BUCKETS }

  const handleDelete = () =>
    lotSheetsDelete.delete({ guids: selects })
      .then(() => snackbar({ message: messages.DELETE_SUCCESS }))
      .then(() => resetTableSelects())
      .then(() => lotSheetsList.getList())
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  return (
    <DashboardLayout
      title={NAV.LOT_SHEETS}
      activeNav={NAV.LOT_SHEETS}
      breadcrumbs={breadcrumbs}
    >
      <LotSheetsFilterForm {...filter} />
      <LotSheetsOrderingForm {...ordering} />

      <LotSheetsTable
        list={lotSheetsList}
        filter={filter}
        ordering={ordering}
        onListRefetch={lotSheetsList.getListByParams}
        onBucketDelete={handleDelete}
        bulkActionLoading={lotSheetsDelete.isLoading}
      />
    </DashboardLayout>
  )
}

export default LotSheetsListContainer
