import React from 'react'
import MUITextField from '@mui/material/TextField'
import { length, trim } from 'ramda'
import PropTypes from 'prop-types'

import FieldWrapper from './FieldWrapper'

function PrependField ({ input, meta, prependChar, valueLength, ...props }) {
  const handleChange = event => {
    const value = trim(event.target.value)

    input.onChange(value)
  }

  const handlePrepend = () => {
    const value = input.value
    const prependLength = valueLength - length(value)

    if (length(value) > 0) {
      const prependValue = new Array(prependLength).fill(prependChar).join('')

      const newValue = `${prependValue}${value}`

      input.onChange(newValue)
    }
  }

  const handleEnter = event => {
    if (event.key === 'Enter') {
      handlePrepend()
    }
  }

  return (
    <MUITextField
      onBlur={handlePrepend}
      onKeyPress={handleEnter}
      value={input.value}
      onChange={handleChange}
      {...props}
    />
  )
}

PrependField.defaultProps = {
  prependChar: '0',
  valueLength: 1,
  size: 'small',
  fullWidth: true,
  variant: 'outlined'
}

PrependField.propTypes = {
  name: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  prependChar: PropTypes.string.isRequired,
  valueLength: PropTypes.number.isRequired
}

export default FieldWrapper(PrependField)
