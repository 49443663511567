import React from 'react'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

import Container, { Content, Header } from '~/components/Container'
import PageTitle from '~/components/PageTitle'
import { DiscardButton, SaveButton } from '~/components/Buttons'
import { withForm } from '~/components/Form'
import * as NAV from '~/constants/nav-titles'

import BillingProfileUpdateTabs from './BillingProfileUpdateTabs'

import BillingProfilePacking from '../BillingProfilePacking'
import BillingProfileReceiving from '../BillingProfileReceiving'
import BillingProfileStorage from '../BillingProfileStorage'
import BillingProfileGeneral from '../BillingProfileGeneral'
import BillingProfileFulfillment from '../BillingProfileFulfillment'
import BillingProfileAdhoc from '../BillingProfileAdhoc'
import { ADHOC, FULFILLMENT, GENERAL, PACKING, RECEIVING, STORAGE } from '../../../constants/BillingProfile'

function BillingProfileUpdate ({ form, pageTitle, pageTitleLoading, isLoading }) {
  const { tab } = useParams()
  const { handleSubmit } = form

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={pageTitle}
            pageTitleLoading={pageTitleLoading}
            parentTitle={NAV.CONFIGURATION}
            rightButton={(
              <>
                <DiscardButton />
                <SaveButton disabled={isLoading} />
              </>
            )}
          />
        </Header>
        <Content>
          <BillingProfileUpdateTabs />

          {tab === GENERAL && <BillingProfileGeneral />}
          {tab === STORAGE && <BillingProfileStorage />}
          {tab === FULFILLMENT && <BillingProfileFulfillment />}
          {tab === PACKING && <BillingProfilePacking />}
          {tab === ADHOC && <BillingProfileAdhoc />}
          {tab === RECEIVING && <BillingProfileReceiving />}
        </Content>
      </Container>
    </form>
  )
}

BillingProfileUpdate.propTypes = {
  form: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  pageTitleLoading: PropTypes.bool.isRequired,
  pageTitle: PropTypes.string
}

export default withForm(BillingProfileUpdate)
