import React from 'react'
import { prop } from 'ramda'
import Grid from '@mui/material/Grid'
import InfiniteScroll from 'react-infinite-scroll-component'
import PropTypes from 'prop-types'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'

import Container, { Content, Header } from '~/components/Container'
import PageTitle from '~/components/PageTitle'
import { DiscardButton } from '~/components/Buttons'

import NotificationSkeletonList from './NotificationSkeletonList'
import NotificationItem from './NotificationItem'
import More from './More'

function NotificationList ({ list, onListLoad, unreadCount, onAllRead, readAllLoading }) {
  const results = prop('results', list)
  const isLoading = prop('isLoading', list)
  const count = prop('count', list)

  const emptyNotificationList = !results.length && !isLoading
  const initialLoading = !results.length && isLoading

  const readAllDisabled = readAllLoading || unreadCount === 0

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle="Notification"
          parentTitle="Settings"
          rightButton={(
            <>
              <DiscardButton />
              <More onAllRead={onAllRead} readAllDisabled={readAllDisabled} />
            </>
          )}
        />
      </Header>
      <Content>
        <Grid container={true}>
          <Grid item={true} xs={12}>
            <Card>
              <CardContent>

                <InfiniteScroll
                  dataLength={results.length}
                  hasMore={count > results.length}
                  loader={<NotificationSkeletonList count={1} />}
                  next={!isLoading && onListLoad}
                >
                  <Grid container={true}>
                    {emptyNotificationList && <Typography>No notifications yet</Typography>}

                    {results.map((result, index) => (
                      <Grid item={true} xs={12} key={prop('id', result)}>
                        <NotificationItem item={result} hasDivider={index !== count - 1} />
                      </Grid>
                    ))}
                  </Grid>
                </InfiniteScroll>

                {initialLoading && (
                  <Grid container={true}>
                    <Grid item={true} xs={12}>
                      <NotificationSkeletonList />
                    </Grid>
                  </Grid>
                )}

              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

NotificationList.propTypes = {
  list: PropTypes.object.isRequired,
  onListLoad: PropTypes.func.isRequired,
  unreadCount: PropTypes.number.isRequired,
  onAllRead: PropTypes.func.isRequired,
  readAllLoading: PropTypes.bool.isRequired
}

export default NotificationList
