import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material'
import * as colors from '@mui/material/colors'
import { propOr, prop } from 'ramda'

import SearchField from '~/components/Fields/SearchField'
import * as API from '~/constants/api'
import { ERROR, SUCCESS } from '~/components/Status'

const BoxStyled = styled(Box)(({ theme, color }) => ({
  height: 20,
  display: 'inline-flex',
  padding: theme.spacing(1, 1),
  marginLeft: '5px',
  alignItems: 'center',
  textTransform: 'uppercase',
  justifyContent: 'center',
  fontSize: '0.60rem',
  fontWeight: '500',
  whiteSpace: 'nowrap',
  ...(color === SUCCESS && {
    color: colors.green[500],
    backgroundColor: `${colors.green[500]}0F`,
  }),
  ...(color === ERROR && {
    color: colors.red[500],
    backgroundColor: `${colors.red[500]}0F`,
  })
}))

function AdjustmentReasonSearchField (props) {
  const getOptionValue = value => {
    if (value) {
      const { id, name, operation } = value
      return { id, name, operation }
    }

    return null
  }

  return (
    <SearchField
      data-cy="reason"
      name="reason"
      label="Adjustment Reasons"
      api={API.ADJUSTMENT_REASON_LIST}
      getOptionValue={getOptionValue}
      ListboxProps={{ 'data-cy': 'reasonList' }}
      renderOption={(props, option) => {
        const operation = propOr('success', 'operation', option)
        const name = prop('name', option)
        return (
          <li {...props}>
            <Box display="flex" alignItems="center">
              <Typography variant="body1">
                {name}
              </Typography>
              <BoxStyled color={operation === 'add' ? 'success' : 'error'}>
                {operation}
              </BoxStyled>
            </Box>
          </li>
        )
      }}
      {...props}
    />
  )
}

export default AdjustmentReasonSearchField
