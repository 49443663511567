import React from 'react'
import PropTypes from 'prop-types'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'

import { CardHeader } from '~/components/Cards'
import TextField from '~/components/Fields/TextField'
import PageTitle from '~/components/PageTitle'
import { DiscardButton, SaveButton } from '~/components/Buttons'
import Container, { Content, Header } from '~/components/Container'
import { withForm } from '~/components/Form'
import * as NAV from '~/constants/nav-titles'

import DeliveryAddressCard from '../DeliveryAddressCard'
import OrderCard from '../OrderCard'
import AddressCard from '../AddressCard'
import InvoiceSummary from '../InvoiceSummary'
import LineItems from '../LineItems'

export const RETURN_CREATE_FIELDS = [
  'lineItems',
  'pickupAddress',
  'warehouse',
  'deliveryAddress',
  'notes',
  'order',
]

function ReturnCreateForm ({ pageTitle, isLoading, returnVariants, form }) {
  const { handleSubmit } = form

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={pageTitle}
            parentTitle={NAV.SALE_RETURNS}
            rightButton={(
              <>
                <DiscardButton disabled={isLoading} />
                <SaveButton disabled={isLoading} />
              </>
            )}
          />
        </Header>

        <Content>
          <Grid container={true} spacing={3}>
            <Grid item={true} lg={9} xs={12}>
              <Card>
                <CardHeader title="Line items" required={true} />
                <Divider />
                <CardContent>
                  <LineItems returnVariants={returnVariants} />
                </CardContent>

                <CardContent>
                  <Divider />
                  <Grid container={true} spacing={3} style={{ paddingTop: 20 }}>
                    <Grid item={true} xs={12} lg={6}>
                      <TextField
                        label="Customer notes"
                        name="notes"
                        data-cy="notes"
                        multiline={true}
                        minRows={11}
                        required={true}
                      />
                    </Grid>

                    <Grid item={true} xs={12} lg={6}>
                      <InvoiceSummary />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <Box mt={3}>
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={12} lg={6}>
                    <AddressCard />
                  </Grid>

                  <Grid item={true} xs={12} lg={6}>
                    <DeliveryAddressCard />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item={true} lg={3} xs={12}>
              <Box mb={3}>
                <OrderCard />
              </Box>
            </Grid>
          </Grid>
        </Content>
      </Container>
    </form>
  )
}

ReturnCreateForm.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func,
  form: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  returnVariants: PropTypes.object.isRequired
}

export default withForm(ReturnCreateForm)
