import React from 'react'
import { sprintf } from 'sprintf-js'
import PropTypes from 'prop-types'

import ConditionCodeField from '~/components/Fields/ConditionCodeField'
import * as API from '~/constants/api'

const getOptionValue = (value) => {
  const { id, guid, code, retailPrice, quantity } = value

  return { id, guid, name: code, retailPrice, quantity }
}

function ConditionField ({ variantGuid, ...props }) {
  const url = sprintf(API.BUNDLE_CONDITION_LIST, variantGuid)

  return (
    <ConditionCodeField
      api={url}
      getOptionValue={getOptionValue}
      {...props}
    />
  )
}

export default ConditionField

ConditionField.propTypes = {
  variantGuid: PropTypes.string.isRequired
}
