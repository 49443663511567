import React from 'react'
import { Ordering } from 'storfox-filter'

import { AREA_COLUMNS as tableColumns } from './AreaTable'

import { AREA_FILTER_NAME as name } from '../../../constants'

export const areaOrderingOptions = {
  name,
  tableColumns
}

function AreaOrderingForm (props) {
  return (
    <Ordering {...props} />
  )
}

export default AreaOrderingForm
