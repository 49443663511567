import React from 'react'
import PropTypes from 'prop-types'
import PrintIcon from '@mui/icons-material/Print'
import { useField } from 'react-final-form'
import { prop } from 'ramda'
import { useDeepCompareEffect } from 'storfox-api-hooks'

import { Button, DiscardButton } from '~/components/Buttons'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import { withForm } from '~/components/Form'
import { BarcodeGeneratorDialogForm } from '~/components/BarcodeGeneratorDialog'
import * as NAV from '~/constants/nav-titles'
import useDialog from '~/hooks/useDialog'

import AdjustmentCreateButton from './AdjustmentCreateButton'

import Adjustment from '../AdjustmentForm'

export const ADJUSTMENT_CREATE_FIELDS = [
  'notes',
  'warehouse',
  'reason',
  'status',
  'amount',
  'productVariant'
]

function AdjustmentCreateForm (props) {
  const {
    pageTitle,
    pageTitleLoading,
    isLoading,
    onGenerate,
    form,
    onConditionGet,
    unitFieldMethods,
    adjustmentVariant
  } = props
  const { handleSubmit } = form

  const barcodeDialog = useDialog()

  const ownerField = useField('owner')
  const ownerValue = ownerField.input.value
  const ownerId = prop('id', ownerValue)

  useDeepCompareEffect(() => {
    onConditionGet({ isDefault: true, companyId: ownerId })
      .then(({ results }) => {
        const data = prop(0, results)
        const condition = data && { ...data, name: prop('code', data) }

        return form.form.change('condition', condition)
      })
  }, [ownerId])

  return (
    <>
      <form onSubmit={handleSubmit} autoComplete="off">
        <Container>
          <Header>
            <PageTitle
              pageTitle={pageTitle}
              pageTitleLoading={pageTitleLoading}
              parentTitle={NAV.ADJUSTMENT}
              rightButton={
                <>
                  <DiscardButton disabled={isLoading} />
                  <Button
                    onClick={barcodeDialog.handleOpen}
                    variant="contained"

                  >
                    <PrintIcon />&nbsp;Barcode
                  </Button>
                  <AdjustmentCreateButton
                    isLoading={isLoading}
                    onSubmit={handleSubmit}
                  />
                </>
              }
            />
          </Header>
          <Content>
            <Adjustment unitFieldMethods={unitFieldMethods} adjustmentVariant={adjustmentVariant} />
          </Content>
        </Container>
      </form>

      <BarcodeGeneratorDialogForm
        onGenerate={onGenerate}
        open={barcodeDialog.open}
        initialValues={{ count: 0 }}
        onClose={barcodeDialog.handleClose}
      />
    </>
  )
}

AdjustmentCreateForm.propTypes = {
  pageTitle: PropTypes.node.isRequired,
  pageTitleLoading: PropTypes.bool.isRequired,
  form: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  onGenerate: PropTypes.func.isRequired,
  onConditionGet: PropTypes.func.isRequired,
  unitFieldMethods: PropTypes.object.isRequired,
  adjustmentVariant: PropTypes.object.isRequired
}

export default withForm(AdjustmentCreateForm)
