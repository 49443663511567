import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import UserListContainer from './containers/UserListContainer'
import UserCreateContainer from './containers/UserCreateContainer'
import UserUpdateContainer from './containers/UserUpdateContainer'
import UserDetailContainer from './containers/UserDetailContainer'

export default () => {
  return [
    {
      layout: AuthLayout,
      path: ROUTES.USER_CREATE_PATH,
      component: UserCreateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.USER_DETAIL_PATH,
      component: UserDetailContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.USER_UPDATE_PATH,
      component: UserUpdateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.USER_LIST_PATH,
      component: UserListContainer
    }
  ]
}
