import React from 'react'
import PropTypes from 'prop-types'

import Status from '~/components/Status'

import { ACCEPTED, PENDING, REJECTED } from '../../../constants/Contract'

const NAMES = {
  [ACCEPTED]: 'Accepted',
  [PENDING]: 'Pending',
  [REJECTED]: 'Rejected',
}

const COLOR = {
  [ACCEPTED]: 'success',
  [PENDING]: 'warning',
  [REJECTED]: 'error',
}
function InvitationStatus ({ value }) {
  return (
    <Status
      label={NAMES[value]}
      color={COLOR[value]}
    />
  )
}

InvitationStatus.propTypes = {
  value: PropTypes.string.isRequired

}

export default InvitationStatus
