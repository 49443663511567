import React from 'react'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import Table from '@mui/material/Table'
import PropTypes from 'prop-types'
import { prop } from 'ramda'
import { useTranslation } from 'react-i18next'

import Money from '~/components/Money'

function InvoiceSummary ({ summary }) {
  const { t } = useTranslation()
  const totalStorage = prop('totalStorage', summary)
  const totalFulfillment = prop('totalFulfillment', summary)
  const totalPaid = prop('totalPaid', summary)
  const totalPrice = prop('totalPrice', summary)

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>{t('Invoice Summary')}</TableCell>
          <TableCell />
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>{t('Storage')}</TableCell>
          <TableCell />
          <TableCell align="right">
            <Money value={totalStorage} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('Fulfillment')}</TableCell>
          <TableCell />
          <TableCell align="right">
            <Money value={totalFulfillment} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('Total paid')}</TableCell>
          <TableCell />
          <TableCell align="right">
            <Money value={totalPaid} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell><strong>{t('Total')}</strong></TableCell>
          <TableCell />
          <TableCell align="right">
            <Money value={totalPrice} />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

InvoiceSummary.propTypes = {
  summary: PropTypes.object.isRequired
}

export default InvoiceSummary
