import React from 'react'
import PropTypes from 'prop-types'

import TableSplitButton from '~/components/TableSplitButton'
import useDeleteConfirm from '~/components/TableSplitButton/useDeleteConfirm'

function BulkActionButton (props) {
  const {
    onAllocate,
    onProcess,
    onCancel,
    onReportGenerate,
    onOrdersDelete,
    loading,
    onSetWarehouse
  } = props

  const { handleClick } = useDeleteConfirm()

  const options = [
    {
      label: 'Allocate',
      'data-cy': 'allocateMenu',
      onMenuClick: onAllocate
    },
    {
      label: 'Process',
      'data-cy': 'processMenu',
      onMenuClick: onProcess
    },
    {
      label: 'Cancel',
      'data-cy': 'cancelMenu',
      onMenuClick: onCancel
    },
    {
      label: 'Generate report',
      'data-cy': 'generateReportMenu',
      onMenuClick: onReportGenerate
    },
    {
      label: 'Delete',
      'data-cy': 'deleteMenu',
      onMenuClick: () => handleClick(onOrdersDelete)
    },
    {
      label: 'Set warehouse',
      'data-cy': 'setWarehouse',
      onMenuClick: () => onSetWarehouse()
    },
  ]

  return (
    <TableSplitButton
      options={options}
      name="return"
      loading={loading}
    />
  )
}

BulkActionButton.propTypes = {
  onAllocate: PropTypes.func.isRequired,
  onProcess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onReportGenerate: PropTypes.func.isRequired,
  onOrdersDelete: PropTypes.func.isRequired,
  onSetWarehouse: PropTypes.func.isRequired,
  loading: PropTypes.bool
}

export default BulkActionButton
