import React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { path, prop } from 'ramda'
import { useSnackbar } from 'storfox-snackbar'
import { useTranslation } from 'react-i18next'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import { WAREHOUSE_TABS } from '~/constants/tabs'

import { useAreaDetail, useAreaUpdate } from '../hooks'
import { AreaCreateSerializer } from '../serializers'
import AreaUpdateForm from '../components/AreaUpdate/AreaUpdateForm'

function AreaUpdateContainer () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { guid } = useParams()
  const snackbar = useSnackbar()
  const areaDetail = useAreaDetail(guid)
  const areaUpdate = useAreaUpdate(guid)

  const warehouseGuid = path(['warehouse', 'guid'], areaDetail.detail)
  const warehouseName = path(['warehouse', 'name'], areaDetail.detail)
  const areaName = prop('name', areaDetail.detail)

  const message = t('Successfully updated')
  const handleSubmit = formValues =>
    areaUpdate.update(AreaCreateSerializer(formValues))
      .then(() => snackbar({ message }))
      .then(() => {
        const detailPath = generatePath(ROUTES.AREA_DETAIL_PATH, { guid })
        return navigate(detailPath)
      })

  const pageLoading = areaDetail.isLoading ||
    areaUpdate.isLoading

  const name = path(['detail', 'name'], areaDetail)
  const title = areaDetail.isLoading ? 'Areas' : name

  const breadcrumbs = {
    title: 'Edit',
    params: [
      { guid: warehouseGuid, tab: WAREHOUSE_TABS.AREA, title: warehouseName },
      { guid, title: areaName }
    ]
  }

  return (
    <DashboardLayout
      title={title}
      isLoading={pageLoading}
      breadcrumbs={breadcrumbs}
      activeNav={NAV.WAREHOUSE}
    >
      <AreaUpdateForm
        pageTitle={title}
        pageTitleLoading={areaDetail.isLoading}
        initialValues={areaDetail.detail}
        onSubmit={handleSubmit}
        isLoading={areaUpdate.isLoading}
      />
    </DashboardLayout>
  )
}

export default AreaUpdateContainer
