import debounce from 'debounce-promise'

const simpleMemoize = fn => {
  let lastArg
  let lastResult

  return arg => {
    if (arg !== lastArg) {
      lastArg = arg
      lastResult = fn(arg)
    }
    return lastResult
  }
}

function useDebounce () {
  const getDebouncedFunction = (func, timeout) => simpleMemoize(debounce(func, timeout))

  return { getDebouncedFunction }
}

export default useDebounce
