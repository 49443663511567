import React from 'react'
import { path, prop } from 'ramda'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Pagination from 'storfox-table/src/Table/Pagination'

import Avatar from '~/components/Avatar/Avatar'
import VerticalAlignment from '~/components/VerticalAlignment'
import Subtext from '~/components/Subtext'
import SkeletonList from '~/components/Skeleton'
import TextOverflow from '~/components/TextOverflow'
import BorderTableRow from '~/components/BorderTableRow'
import HistoryTableSearch from '~/components/History/HistoryTableSearch'
import { tableDateFormat } from '~/utils'

function LineItems ({ rows, isLoading, count }) {
  const { t } = useTranslation()

  return (
    <Box component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto' }}>
      <TableContainer>
        <HistoryTableSearch />
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t('Image')}</TableCell>
              <TableCell sx={{ minWidth: 200 }}>{t('Variant')}</TableCell>
              <TableCell>{t('Unit number')}</TableCell>
              <TableCell>{t('Quantity')}</TableCell>
              <TableCell>{t('Serial number')}</TableCell>
              <TableCell sx={{ minWidth: 150 }}>{t('Batch number')}</TableCell>
              <TableCell>{t('Condition code')}</TableCell>
              <TableCell sx={{ minWidth: 150 }}>{t('Expiry date')}</TableCell>
              <TableCell sx={{ minWidth: 150 }}>{t('From location')}</TableCell>
              <TableCell sx={{ minWidth: 150 }}>{t('From container')}</TableCell>
              <TableCell sx={{ minWidth: 150 }}>{t('To location')}</TableCell>
              <TableCell sx={{ minWidth: 150 }}>{t('To container')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading
              ? <SkeletonList columnCount={8} />
              : rows.map((item, index) => {
                const unit = prop('unit', item)
                const variant = prop('variant', unit)
                const variantName = prop('name', variant)
                const sku = prop('sku', variant)
                const unitNumber = prop('unitNumber', unit)
                const imagePath = prop('imagePath', variant)
                const quantity = prop('quantity', item)
                const serialNumber = prop('serialNumber', unit)
                const batchNumber = prop('batchNumber', unit)
                const condition = path(['condition', 'code'], unit)
                const expiresAt = prop('expiresAt', unit)
                const fromLocation = path(['location', 'locationId'], unit)
                const fromContainer = path(['container', 'number'], unit)
                const toLocation = path(['toLocation', 'locationId'], item)
                const toContainer = path(['toContainer', 'number'], item)

                const isLastRow = index === rows.length - 1

                return (
                  <BorderTableRow key={index} isLastRow={isLastRow}>
                    <TableCell>
                      <Avatar alt={variantName} src={imagePath} />
                    </TableCell>

                    <TableCell>
                      <VerticalAlignment
                        sx={{ minWidth: 200 }}
                        primary={(
                          <TextOverflow
                            selfTooltip={true}
                          >
                            {variantName}
                          </TextOverflow>
                        )}
                        secondary={<Subtext lines={1}>{sku}</Subtext>}
                      />
                    </TableCell>
                    <TableCell>{unitNumber}</TableCell>
                    <TableCell>{quantity}</TableCell>
                    <TableCell>{serialNumber}</TableCell>
                    <TableCell>{batchNumber}</TableCell>
                    <TableCell>{condition}</TableCell>
                    <TableCell>{expiresAt && tableDateFormat(expiresAt)}</TableCell>
                    <TableCell>{fromLocation}</TableCell>
                    <TableCell>{fromContainer}</TableCell>
                    <TableCell>{toLocation}</TableCell>
                    <TableCell>{toContainer}</TableCell>
                  </BorderTableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination count={count} prefix="" />
    </Box>
  )
}

LineItems.propTypes = {
  rows: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

LineItems.defaultProps = {
  lineItems: 0
}

export default LineItems
