import React from 'react'
import { Ordering } from 'storfox-filter'

import { TEAM_METRICS_COLUMNS as tableColumns } from './TeamMetricsTable'

import { FILTER_NAME as name } from '../../../constants/TeamMetrics'

export const teamMetricsOrderingOptions = {
  name,
  tableColumns
}

function TeamMetricsOrderingForm (props) {
  return (
    <Ordering {...props} />
  )
}

export default TeamMetricsOrderingForm
