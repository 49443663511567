import PropTypes from 'prop-types'
import React from 'react'
import { DateRangePicker } from '@mui/x-date-pickers-pro'
import { Field } from 'react-final-form'
import { TextField } from '@mui/material'
import Box from '@mui/material/Box'
import { always } from 'ramda'
import { useTranslation } from 'react-i18next'

function DateRangeField ({
  name,
  inputFormat = 'dd.MM.yyyy',
  mask = '__.__.____',
  size = 'small',
  onAccept = always(null),
  startLabel = 'Start',
  endLabel = 'End',
  ...props
}) {
  const { t } = useTranslation()

  return (
    <Field name={name}
      render={({ input }) => (
        <DateRangePicker
          mask={mask}
          value={input.value || [null, null]}
          onChange={input.onChange}
          inputFormat={inputFormat}
          onAccept={onAccept}
          size={size}
          renderInput={(startProps, endProps) => (
            <>
              <TextField {...startProps} label={t(startLabel)} data-cy={`${name}-start`} size="small" />
              <Box sx={{ mx: 2 }}> to </Box>
              <TextField {...endProps} label={t(endLabel)} data-cy={`${name}-end`} size="small" />
            </>
          )}
          {...props}
        />
      )}
    />
  )
}

DateRangeField.propTypes = {
  name: PropTypes.string.isRequired,
  inputFormat: PropTypes.string,
  startLabel: PropTypes.string,
  endLabel: PropTypes.string,
  mask: PropTypes.string,
  size: PropTypes.string,
  onAccept: PropTypes.func
}

export default DateRangeField
