import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'

import ShipmentSearchField from '~/components/Fields/ShipmentSearchField'
import * as API from '~/constants/api'
import ShippingServiceField from '~/components/Fields/ShippingServiceField'

import { ASSIGN_TO_CARRIER } from '../ActionFields/ActionSelectField'

export const SHIPMENT_CREATED_FIELDS = [
  'assignToCarrier',
]

function ShipmentCreatedAction ({ action, actionName }) {
  return (
    <>
      {action === ASSIGN_TO_CARRIER && (
        <>
          <Box mb={2}>
            <ShipmentSearchField
              label="Carrier"
              name={`${actionName}.carrier`}
            />
          </Box>
          <ShippingServiceField
            name={`${actionName}.serviceType`}
            integrationName={`${actionName}.carrier`}
            api={API.SHIPMENT_SERVICES}
          />
        </>
      )}
    </>
  )
}

ShipmentCreatedAction.propTypes = {
  action: PropTypes.string.isRequired,
  actionName: PropTypes.string.isRequired
}

export default ShipmentCreatedAction
