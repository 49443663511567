import React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

import Tabs from '~/components/Tabs'
import Divider from '~/components/Divider'
import * as ROUTES from '~/constants/routes'

import { TABS } from '../../../constants/BillingProfile'

function BillingProfileCreateTabs () {
  const navigate = useNavigate()
  const { tab } = useParams()

  const handleChange = (event, tab) => {
    const path = generatePath(ROUTES.BILLING_PROFILE_CREATE_PATH, { tab })

    navigate(path, { replace: true })
  }

  return (
    <>
      <Tabs
        tabs={TABS}
        value={tab}
        onChange={handleChange}
      />
      <Divider />
    </>
  )
}

export default BillingProfileCreateTabs
