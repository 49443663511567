import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { map, path, prop } from 'ramda'
import { generatePath, useNavigate } from 'react-router-dom'

import { Table, TableHeader } from '~/components/Table'
import { Button, MoreListButton } from '~/components/Buttons'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import TextOverflow from '~/components/TextOverflow'
import TableDateFormat from '~/components/TableDateFormat'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import { TYPES } from '~/constants/companyTypes'
import TableLink from '~/components/Link'
import { COMPANY_TABS } from '~/constants/tabs'

const getDetailPath = id => {
  const params = { id, tab: COMPANY_TABS.GENERAL }
  return generatePath(ROUTES.COMPANY_UPDATE_PATH, params)
}

export const COMPANY_COLUMNS = [
  {
    width: 300,
    headerName: 'Company',
    field: 'name',
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        <TextOverflow selfTooltip={true}>{value}</TextOverflow>
      </TableLink>
    )
  },
  {
    width: 150,
    headerName: 'Company type',
    field: 'type'
  },
  {
    width: 200,
    headerName: 'Email',
    field: 'email',
    renderCell: ({ value }) => (
      <TextOverflow selfTooltip={true}>{value}</TextOverflow>
    )
  },
  {
    width: 200,
    headerName: 'Country',
    field: 'country'
  },
  {
    width: 200,
    headerName: 'Address',
    field: 'address'
  },
  {
    width: 200,
    headerName: 'Phone',
    field: 'phone'
  },
  {
    width: 150,
    headerName: 'Created date',
    field: 'createdAt',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  }
]

const getRowsFromResults = map(item => {
  const id = prop('id', item)
  const name = prop('name', item)
  const email = prop('email', item)
  const country = path(['country', 'name'], item)
  const type = TYPES[prop('type', item)]
  const phone = prop('phone', item)
  const address = prop('address', item)
  const createdAt = prop('createdAt', item)
  const link = getDetailPath(id)

  return {
    id,
    name,
    email,
    country,
    type,
    phone,
    address,
    createdAt,
    link
  }
})

function CompanyTable ({ list, filter, ordering, onListRefetch }) {
  const [tableRef, setTableRef] = useState(null)
  const navigate = useNavigate()
  const isLoading = prop('isLoading', list)
  const results = prop('results', list)
  const count = prop('count', list)
  const columns = prop('filteredColumns', ordering)

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={NAV.COMPANIES}
          parentTitle={NAV.SETTINGS}
          rightButton={(
            <Button
              variant="contained"
              onClick={() => navigate(ROUTES.COMPANY_CREATE_URL)}
            >
              New Company
            </Button>
          )}
        />
      </Header>

      <Content>
        <TableHeader
          filter={filter}
          filterOpen={filter.handleOpen}
          orderingOpen={ordering.handleOpen}
          ordering={ordering}
        >
          <MoreListButton tableRef={tableRef} />
        </TableHeader>
        <Table
          isLoading={isLoading}
          columns={columns}
          getRowsFromResults={getRowsFromResults}
          results={results}
          count={count}
          checkboxSelection={true}
          pinnedColumns={['name']}
          ordering={ordering}
          onRefetch={onListRefetch}
          setTableRef={setTableRef}
        />
      </Content>
    </Container>
  )
}

CompanyTable.propTypes = {
  list: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  onListRefetch: PropTypes.func.isRequired,
}

export default CompanyTable
