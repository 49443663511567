import React from 'react'
import PropTypes from 'prop-types'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import { prop } from 'ramda'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'

import PageTitle from '~/components/PageTitle'
import { EditButton } from '~/components/Buttons'
import Container, { Content, Header } from '~/components/Container'
import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'
import LoaderProvider from '~/components/Loader'
import { LISTING_TABS } from '~/constants/tabs'

import ListingProductDetail from './ListingProductDetail'
import ListingVariantDetail from './ListingVariantDetail'
import ListingDetailTabs from './ListingDetailTabs'
import ListingDetailListItems from './ListingDetailListItems'

const ContainerBoxStyled = styled(Box)(({ theme }) => ({
  paddingRight: 0,
  [theme.breakpoints.up('md')]: {
    paddingLeft: 300,
    width: '100%',
    maxWidth: '100%'
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: 0
  }
}))

function ListingDetail (props) {
  const {
    pageTitle,
    pageTitleLoading,
    detail,
    isLoading
  } = props

  const { guid, tab } = useParams()
  const navigate = useNavigate()

  const isUpdatable = prop('updatable', detail)
  const editPath = generatePath(ROUTES.LISTING_UPDATE_PATH, { guid, tab: LISTING_TABS.VARIANT })

  const handleVariantOptionChange = guid => {
    const path = generatePath(ROUTES.LISTING_DETAIL_PATH, { guid, tab: LISTING_TABS.VARIANT })
    navigate(path, { replace: true })
  }

  return (
    <LoaderProvider isLoading={isLoading}>
      <Box sx={{
        height: '100%',
        display: 'flex'
      }}>
        <ListingDetailListItems
          detail={detail}
          listingGuid={guid}
          onVariantOptionChange={handleVariantOptionChange}
        />
        <ContainerBoxStyled>
          <Container>
            <Header>
              <PageTitle
                pageTitle={pageTitle}
                pageTitleLoading={pageTitleLoading}
                parentTitle={NAV.LISTING}
                rightButton={
                  isUpdatable && (
                    <>
                      <EditButton path={editPath} disabled={isLoading} />
                    </>
                  )
                }
              />
            </Header>

            <Content>
              <Grid>
                <Grid item={true} xs={12}>
                  <ListingDetailTabs
                    value={tab}
                  />
                  {tab === LISTING_TABS.VARIANT && <ListingVariantDetail detail={detail} />}
                  {tab === LISTING_TABS.PRODUCT && (
                    <ListingProductDetail
                      isLoading={isLoading}
                      detail={detail}
                    />
                  )}
                </Grid>
              </Grid>
            </Content>
          </Container>
        </ContainerBoxStyled>
      </Box>
    </LoaderProvider>
  )
}

ListingDetail.propTypes = {
  pageTitleLoading: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  detail: PropTypes.object.isRequired,
  pageTitle: PropTypes.string,
}

export default ListingDetail
