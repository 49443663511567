import React from 'react'
import PropTypes from 'prop-types'
import InputAdornment from '@mui/material/InputAdornment'
import { prop, propOr } from 'ramda'
import { ConverterField } from 'storfox-form-fields'
import { useTranslation } from 'react-i18next'

import { useCompany } from '../Profile'

const ratios = {
  'g': 1,
  'kg': 0.001,
  'lb': 0.00220462,
  'oz': 0.035274
}

function WeightField ({ name, label, ...rest }) {
  const { company } = useCompany()
  const weightUnit = propOr('kg', 'weightUnit', company).toString()
  const ratio = prop(weightUnit, ratios)
  const { t } = useTranslation()

  return (
    <ConverterField
      name={name}
      label={t(label)}
      ratio={ratio}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">{t(weightUnit)}</InputAdornment>
        )
      }}
      {...rest}
    />
  )
}

WeightField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
}

export default WeightField
