import React from 'react'
import PropTypes from 'prop-types'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'

import EmailField from '~/components/Fields/EmailField'
import LanguageField from '~/components/Fields/LanguageField'
import MultiSelectSearchField from '~/components/Fields/MultiSelectSearchField'
import PasswordGenerateField from '~/components/Fields/PasswordGenerateField'
import PhoneNumberField from '~/components/Fields/PhoneNumberField'
import SingleImageUploadField from '~/components/Fields/SingleImageUploadField'
import StatusField from '~/components/Fields/StatusField'
import TextField from '~/components/Fields/TextField'
import { CardHeader } from '~/components/Cards'
import { withForm } from '~/components/Form'
import Container, { Content, Header } from '~/components/Container'
import PageTitle from '~/components/PageTitle'
import { DiscardButton, SaveButton } from '~/components/Buttons'
import * as NAV from '~/constants/nav-titles'
import * as API from '~/constants/api'

export const USER_CREATE_FIELDS = [
  'firstName',
  'lastName',
  'email',
  'phone',
  'imagePath',
  'password',
  'roles',
  'warehouses',
  'status',
  'tags',
  'language'
]

function UserCreateForm ({ title, isLoading, pageTitleLoading, form }) {
  const { handleSubmit } = form
  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={title}
            parentTitle={NAV.USERS}
            pageTitleLoading={pageTitleLoading}
            rightButton={(
              <>
                <DiscardButton disabled={isLoading} />
                <SaveButton disabled={isLoading} />
              </>
            )}
          />
        </Header>

        <Content>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12} lg={8}>
              <Box mb={3}>
                <Card>
                  <CardHeader title="Information" />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12} lg={6}>
                        <TextField
                          data-cy="firstName"
                          name="firstName"
                          label="First name"
                        />
                      </Grid>
                      <Grid item={true} xs={12} lg={6}>
                        <TextField
                          data-cy="lastName"
                          name="lastName"
                          label="Last name"
                        />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <EmailField
                          data-cy="email"
                          name="email"
                          label="Email"
                        />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <PhoneNumberField
                          data-cy="phone"
                          name="phone"
                          label="Phone number"
                        />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <PasswordGenerateField
                          data-cy="password"
                          name="password"
                          label="Password"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
              <Box mb={3}>
                <Card>
                  <CardHeader title="Access" />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12}>
                        <MultiSelectSearchField
                          api={API.COMPANY_WAREHOUSE_LIST}
                          data-cy="warehouses"
                          name="warehouses"
                          label="Warehouses"
                        />
                      </Grid>
                      <Grid item={true} xs={12}>
                        <MultiSelectSearchField
                          api={API.ROLE_LIST}
                          data-cy="roles"
                          name="roles"
                          label="Roles"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
            <Grid item={true} xs={12} lg={4}>
              <Box mb={3}>
                <Card>
                  <CardHeader title="Profile Photo" />
                  <Divider />
                  <CardContent>
                    <SingleImageUploadField name="imagePath" />
                  </CardContent>
                </Card>
              </Box>
              <Box mb={3}>
                <Card>
                  <CardHeader title="Organization" />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12}>
                        <StatusField />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <LanguageField />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
          <Box mb={3} />
        </Content>
      </Container>
    </form>
  )
}

UserCreateForm.propTypes = {
  pageTitleLoading: PropTypes.bool,
  form: PropTypes.object,
  isLoading: PropTypes.bool,
  title: PropTypes.string.isRequired
}

export default withForm(UserCreateForm)
