import React, { useRef } from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import { FieldArray } from 'react-final-form-arrays'
import PropTypes from 'prop-types'
import IconButton from '@mui/material/IconButton'
import Delete from '@mui/icons-material/Delete'
import Link from '@mui/material/Link'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'
import { path, prop } from 'ramda'

import ConditionCodeField from '~/components/Fields/ConditionCodeField'
import PositiveNumberField from '~/components/Fields/PositiveNumberField'
import TextField from '~/components/Fields/TextField'
import * as API from '~/constants/api'
import ConstrainedField from '~/components/Fields/ConstrainedField'

const RootStyled = styled(Box)({
  maxHeight: 'calc(80vh - 200px)',
  overflow: 'auto',
  marginTop: 20,
  minHeight: 100
})

const TableStyled = styled(Table)({
  width: '100%'
})

function BarcodeList (props) {
  const {
    trackSerial,
    ownerId,
    removeOperation,
    onBarcodeFocus,
    onConditionCopy,
    trackBatch
  } = props

  const { t } = useTranslation()
  const serialRef = useRef(null)
  const batchNumberRef = useRef(null)

  const handleSerialNumberKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault()
      batchNumberRef.current.focus()
    }
  }

  const handleBatchNumberKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault()
      onBarcodeFocus()
    }
  }

  const quantityColumnTitle = removeOperation ? 'Decrease quantity' : 'increase quantity'

  return (
    <RootStyled>
      <TableContainer component={Paper} sx={{ minWidth: '700px' }}>
        <TableStyled>
          <TableHead>
            <TableRow>
              <TableCell>{t('Unit number')}</TableCell>
              {trackSerial && (
                <TableCell>{t('Serial number')}</TableCell>
              )}
              {trackBatch && (
                <TableCell width={300}>{t('Batch number')}</TableCell>
              )}
              {removeOperation && <TableCell sx={{ width: '100px' }}>{t('Total Qty')}</TableCell>}
              <TableCell sx={{ width: '150px' }}>{t(quantityColumnTitle)}</TableCell>

              <TableCell width={200}>
                {t('Condition code')} <br />
                {!removeOperation && (
                  <Link
                    component="button"
                    underline="always"
                    type="button"
                    onClick={onConditionCopy}
                  >
                    {t('Copy to all')}
                  </Link>
                )}
              </TableCell>

              {removeOperation && <TableCell>{t('Location')}</TableCell>}
              <TableCell align="right">{t('Delete')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <FieldArray name="items">
              {({ fields }) =>
                fields.map((name, index) => {
                  const value = path(['value', index], fields)
                  const unitNumber = prop('unitNumber', value)
                  const totalQuantity = prop('totalQuantity', value)
                  const location = path(['location', 'locationId'], value)

                  return (
                    <TableRow key={index}>
                      <TableCell>{unitNumber}</TableCell>
                      {trackSerial && (
                        <TableCell>
                          <TextField
                            fullWidth={true}
                            inputProps={{ ref: serialRef }}
                            name={`${name}.serialNumber`}
                            data-cy={`${name}.serialNumber`}
                            autoFocus={true}
                            label="Serial number"
                            disabled={!trackSerial}
                            onKeyPress={handleSerialNumberKeyPress}
                          />
                        </TableCell>
                      )}
                      {trackBatch && (
                        <TableCell>
                          <TextField
                            name={`${name}.batchNumber`}
                            autoFocus={!trackSerial}
                            label="Batch number"
                            inputProps={{ ref: batchNumberRef }}
                            onKeyPress={handleBatchNumberKeyPress}
                          />
                        </TableCell>
                      )}
                      {removeOperation && (
                        <TableCell>{totalQuantity}</TableCell>
                      )}
                      <TableCell>
                        {removeOperation && (
                          <ConstrainedField
                            data-cy={`${name}.quantity`}
                            name={`${name}.quantity`}
                            minValue={0}
                            maxValue={totalQuantity}
                          />
                        )}
                        {!removeOperation && (
                          <PositiveNumberField
                            data-cy={`${name}.quantity`}
                            name={`${name}.quantity`}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        <ConditionCodeField
                          name={`${name}.condition`}
                          params={{ companyId: ownerId, limit: 30 }}
                          api={API.ADJUSTMENT_CONDITIONS}
                          fullWidth={true}
                          label="Condition code"
                          ListboxProps={{ 'data-cy': 'conditionList' }}
                          disabled={removeOperation}
                          size="small"
                        />
                      </TableCell>
                      {removeOperation && (
                        <TableCell>
                          {location}
                        </TableCell>
                      )}
                      <TableCell align="right">
                        <IconButton onClick={() => fields.remove(index)} size="large">
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                })
                  .reverse()
              }
            </FieldArray>
          </TableBody>
        </TableStyled>
      </TableContainer>
    </RootStyled>
  )
}

BarcodeList.propTypes = {
  ownerId: PropTypes.number.isRequired,
  onBarcodeFocus: PropTypes.func.isRequired,
  onConditionCopy: PropTypes.func.isRequired,
  trackSerial: PropTypes.bool,
  removeOperation: PropTypes.bool,
  trackBatch: PropTypes.bool
}

export default BarcodeList
