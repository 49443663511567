import React from 'react'
import { useFilter, useOrdering } from 'storfox-filter'
import { useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import useMessages from '~/hooks/useMessages'
import { useTableSelects } from '~/components/TableValues/hooks'

import { useReceiveList, useReceiveListComplete } from '../hooks'
import {
  ReceiveFilterForm,
  receiveFilterOptions,
  ReceiveOrderingForm,
  receiveOrderingOptions,
  ReceiveTable
} from '../components/ReceiveList'

function ReceiveListContainer () {
  const messages = useMessages()
  const snackbar = useSnackbar()

  const { selects, resetTableSelects } = useTableSelects()

  const receiveList = useReceiveList()
  const receiveListComplete = useReceiveListComplete()

  const filter = useFilter(receiveFilterOptions)
  const ordering = useOrdering(receiveOrderingOptions)

  const handleComplete = () =>
    receiveListComplete.complete({ guids: selects })
      .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
      .then(() => resetTableSelects())
      .then(() => receiveList.getList())

  const breadcrumbs = { title: NAV.RECEIVE }

  return (
    <DashboardLayout
      title={NAV.RECEIVE}
      activeNav={NAV.RECEIVE}
      breadcrumbs={breadcrumbs}
    >
      <ReceiveFilterForm {...filter} />
      <ReceiveOrderingForm {...ordering} />

      <ReceiveTable
        filter={filter}
        ordering={ordering}
        list={receiveList}
        onComplete={handleComplete}
        onListRefetch={receiveList.getListByParams}
      />
    </DashboardLayout>
  )
}

export default ReceiveListContainer
