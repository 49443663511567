import { useAllSearchParams } from 'storfox-route-hooks'
import {
  useLimitedCountList,
  useCreate,
  useDetail,
  useList,
  useScrollList,
  useUpdate
} from 'storfox-api-hooks'
import { sprintf } from 'sprintf-js'

import { queryToParams } from '~/utils'
import * as API from '~/constants/api'

import { ReturnFilterSerializer } from '../serializers'

export const useReturnList = () => {
  const searchParams = useAllSearchParams()
  const params = queryToParams(ReturnFilterSerializer, searchParams, 'guid')

  return useLimitedCountList(API.SUPPLIER_RETURN_LIST, params)
}

export const useReturnCreate = () => {
  return useCreate(API.SUPPLIER_RETURN_CREATE)
}

export const useReturnDetail = guid => {
  const url = sprintf(API.SUPPLIER_RETURN_DETAIL, guid)
  return useDetail(url)
}

export const useReturnUpdate = guid => {
  const url = sprintf(API.SUPPLIER_RETURN_UPDATE, guid)
  return useUpdate(url)
}

export const useReturnAllocate = guid => {
  const url = sprintf(API.SUPPLIER_RETURN_ALLOCATE, guid)
  return useCreate(url)
}

export const useReturnProcess = guid => {
  const url = sprintf(API.SUPPLIER_RETURN_PROCESS, guid)
  return useCreate(url)
}

export const usePurchaseSupplierCreate = () => {
  return useCreate(API.SUPPLIER_CREATE)
}

export const useReturnVariants = params => {
  return useScrollList(API.SUPPLIER_RETURN_UNITS, params)
}

export const useReturnHistory = (guid, autoSend) => {
  const searchParams = useAllSearchParams()
  const url = sprintf(API.SUPPLIER_RETURN_HISTORY, guid)

  return useList(url, searchParams, autoSend)
}
