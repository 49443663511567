import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'
import { useTheme } from '@mui/material'

const d = 'M12 22.019l-3.717-2.146V9.863l2.479-1.43v10.01' +
  'l1.238.753l1.238-.753V8.434l2.479 1.43v10.01L12 22.019zm8' +
  '.666-15.014v10.009l-2.475 1.43V8.434L12 4.861L5.807 8.434v1' +
  '0.01l-2.473-1.43V7.005L12 2l8.666 5.005z'

function Magento (props) {
  const theme = useTheme()

  return (
    <SvgIcon style={{ color: theme.palette.icon.primary }} {...props}>
      <path d={d} />
    </SvgIcon>
  )
}

export default Magento
