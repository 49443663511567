import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { path } from 'ramda'
import { useFilter, useOrdering } from 'storfox-filter'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import { WAREHOUSE_TABS } from '~/constants/tabs'
import useMessages from '~/hooks/useMessages'
import ErrorLink from '~/components/ErrorLink'
import { useTableSelects } from '~/components/TableValues/hooks'
import useDialog from '~/hooks/useDialog'
import { useMe, useProfile } from '~/components/Profile'
import * as ROUTES from '~/constants/routes'

import WarehouseDetail from '../components/WarehouseDetail'
import {
  useAreaList,
  useContainerList,
  useLocationList,
  useLocationsDelete,
  useWarehouseDetail,
  useZoneList,
  useWarehousesDelete
} from '../hooks'
import {
  AreaFilterForm,
  areaFilterOptions,
  AreaOrderingForm,
  areaOrderingOptions
} from '../components/WarehouseDetail/AreaContent'
import {
  ZoneFilterForm,
  zoneFilterOptions,
  ZoneOrderingForm,
  zoneOrderingOptions
} from '../components/WarehouseDetail/ZoneContent'
import {
  LocationFilterForm,
  locationFilterOptions,
  LocationOrderingForm,
  locationOrderingOptions
} from '../components/WarehouseDetail/LocationContent'
import {
  ContainerFilterForm,
  containerFilterOptions,
  ContainerOrderingForm,
  containerOrderingOptions
} from '../components/WarehouseDetail/ContainerContent'
import WarehouseVisualizationModeDialog from '../components/WarehouseDetail/WarehouseVisualizationModeDialog'

function OwnWarehouseDetailContainer () {
  const { guid, tab } = useParams()
  const snackbar = useSnackbar()
  const messages = useMessages()
  const { selects, resetTableSelects } = useTableSelects()
  const { handleClose, handleOpen, open } = useDialog()

  const warehouseDetail = useWarehouseDetail(guid)
  const warehousesDelete = useWarehousesDelete(guid)
  const { getMe } = useMe()
  const { setProfile } = useProfile()
  const navigate = useNavigate()

  const areaList = useAreaList(guid, tab === WAREHOUSE_TABS.AREA)
  const areaFilter = useFilter(areaFilterOptions)
  const areaOrdering = useOrdering(areaOrderingOptions)

  const zoneList = useZoneList(guid, tab === WAREHOUSE_TABS.ZONE)
  const zoneFilter = useFilter(zoneFilterOptions)
  const zoneOrdering = useOrdering(zoneOrderingOptions)

  const locationList = useLocationList(guid, tab === WAREHOUSE_TABS.LOCATION)
  const locationFilter = useFilter(locationFilterOptions)
  const locationOrdering = useOrdering(locationOrderingOptions)
  const locationsDelete = useLocationsDelete()

  const containerList = useContainerList(guid, tab === WAREHOUSE_TABS.CONTAINER)
  const containerFilter = useFilter(containerFilterOptions)
  const containerOrdering = useOrdering(containerOrderingOptions)

  const name = path(['detail', 'name'], warehouseDetail)
  const title = warehouseDetail.isLoading ? 'Warehouses' : name

  const handleLocationsDelete = () =>
    locationsDelete.delete({ guids: selects })
      .then(() => snackbar({ message: messages.DELETE_SUCCESS }))
      .then(() => resetTableSelects())
      .then(() => locationList.getList())
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const handleWarehousesDelete = () =>
    warehousesDelete.delete()
      .then(() => snackbar({ message: messages.DELETE_SUCCESS }))
      .then(() => navigate(ROUTES.WAREHOUSE_LIST_PATH))
      .then(() => resetTableSelects())
      .then(() => getMe())
      .then(({ result }) => setProfile(result))
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const breadcrumbs = {
    title,
    params:[{ guid, tab: WAREHOUSE_TABS.GENERAL, title }]
  }

  return (
    <DashboardLayout
      title={NAV.WAREHOUSE}
      activeNav={NAV.WAREHOUSE}
      breadcrumbs={breadcrumbs}
      isLoading={warehouseDetail.isLoading}
    >

      <AreaFilterForm {...areaFilter} />
      <ZoneFilterForm {...zoneFilter} />
      <LocationFilterForm {...locationFilter} />
      <ContainerFilterForm {...containerFilter} />

      <AreaOrderingForm {...areaOrdering} />
      <ZoneOrderingForm {...zoneOrdering} />
      <LocationOrderingForm {...locationOrdering} />
      <ContainerOrderingForm {...containerOrdering} />

      <WarehouseDetail
        pageTitle={title}
        pageTitleLoading={warehouseDetail.isLoading}
        warehouseDetail={warehouseDetail.detail}
        isLoading={warehouseDetail.isLoading}

        areaList={areaList}
        onAreaListRefetch={areaList.getListByParams}
        areaFilter={areaFilter}
        areaOrdering={areaOrdering}

        zoneList={zoneList}
        onZoneListRefetch={zoneList.getListByParams}
        zoneFilter={zoneFilter}
        zoneOrdering={zoneOrdering}

        locationList={locationList}
        onLocationListRefetch={locationList.getListByParams}
        locationFilter={locationFilter}
        locationOrdering={locationOrdering}
        onLocationsDelete={handleLocationsDelete}

        containerList={containerList}
        onContainerListRefetch={containerList.getListByParams}
        containerFilter={containerFilter}
        containerOrdering={containerOrdering}
        onVisualizationClick={handleOpen}
        onWarehouseDelete={handleWarehousesDelete}
      />
      <WarehouseVisualizationModeDialog onClose={handleClose} open={open} guid={guid} />
    </DashboardLayout>
  )
}

export default OwnWarehouseDetailContainer
