import React from 'react'
import { useFilter, useOrdering } from 'storfox-filter'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import ErrorLink from '~/components/ErrorLink'
import * as NAV from '~/constants/nav-titles'
import useMessages from '~/hooks/useMessages'
import { useTableSelects } from '~/components/TableValues/hooks'

import { BundleListingCreateSerializer } from '../serializers'
import {
  BundleFilterForm,
  bundleFilterOptions,
  BundleOrderingForm,
  bundleOrderingOptions,
  BundleTable
} from '../components/BundleList'
import { useBundleChannelList, useBundleList, useBundleListingCreate, useBundlesDelete } from '../hooks'

function BundleListContainer () {
  const messages = useMessages()
  const snackbar = useSnackbar()
  const bundleList = useBundleList()
  const bundlesDelete = useBundlesDelete()
  const bundleListingCreate = useBundleListingCreate()
  const bundleChannelList = useBundleChannelList()
  const { selects, resetTableSelects } = useTableSelects()

  const filter = useFilter(bundleFilterOptions)
  const ordering = useOrdering(bundleOrderingOptions)

  const handleListingAdd = selectedChannels =>
    bundleListingCreate
      .create(BundleListingCreateSerializer(selects, selectedChannels))
      .then(() => snackbar({ message: messages.CREATE_SUCCESS }))
      .then(() => resetTableSelects())
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const handleBundlesDelete = () =>
    bundlesDelete.delete({ guids: selects })
      .then(() => snackbar({ message: messages.DELETE_SUCCESS }))
      .then(() => resetTableSelects())
      .then(() => bundleList.getList())
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const breadcrumbs = { title: NAV.BUNDLES }

  return (
    <DashboardLayout
      title={NAV.BUNDLES}
      activeNav={NAV.BUNDLES}
      breadcrumbs={breadcrumbs}
    >
      <BundleFilterForm {...filter} />
      <BundleOrderingForm {...ordering} />

      <BundleTable
        list={bundleList}
        filter={filter}
        ordering={ordering}
        onListRefetch={bundleList.getListByParams}
        bundleChannelList={bundleChannelList}
        onBundlesDelete={handleBundlesDelete}
        onListingAdd={handleListingAdd}
        bulkActionLoading={bundlesDelete.isLoading || bundleListingCreate.isLoading}
      />
    </DashboardLayout>
  )
}

export default BundleListContainer
