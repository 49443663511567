import React from 'react'
import PropTypes from 'prop-types'
import { SwitchField } from 'storfox-form-fields'
import Typography from '@mui/material/Typography'

import { AUTO_CONFIRM, TO_WAREHOUSE } from '../ActionFields/ActionSelectField'
import WarehouseActionSearchField from '../ActionFields/WarehouseActionSearchField'

export const ORDER_CREATED_FIELDS = [
  'toWarehouse',
  'autoConfirm'
]

function OrderCreatedAction ({ action, actionName }) {
  return (
    <>
      {action === TO_WAREHOUSE && <WarehouseActionSearchField name={`${actionName}.warehouse`} />}
      {action === AUTO_CONFIRM && (
        <SwitchField
          name={actionName}
          label={<Typography display="inline" align="center">Auto confirm</Typography>}
        />
      )}
    </>
  )
}

OrderCreatedAction.propTypes = {
  action: PropTypes.string.isRequired,
  actionName: PropTypes.string.isRequired
}

export default OrderCreatedAction
