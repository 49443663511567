import React, { Suspense } from 'react'
import { useParams } from 'react-router-dom'
import { propOr } from 'ramda'

import { HtmlLayout } from '~/components/Layouts'

import { useWarehouseDetail, useWarehouseLocationList } from '../hooks'

const WarehouseVisualization = React.lazy(
  () => import(
    /* webpackChunkName: "visualization" */
    '../components/WarehouseVisualization'
  )
)

function WarehouseVisualizationContainer () {
  const { guid } = useParams()
  const warehouseDetail = useWarehouseDetail(guid)
  const locationList = useWarehouseLocationList(guid, true)

  const title = propOr('Warehouse schema', 'name', warehouseDetail.detail)

  return (
    <HtmlLayout title={title}>
      <Suspense fallback="Loading...">
        <WarehouseVisualization
          pageTitle={title}
          pageTitleLoading={warehouseDetail.isLoading}
          isLoading={locationList.isLoading}
          locationList={locationList}
        />
      </Suspense>
    </HtmlLayout>
  )
}

export default WarehouseVisualizationContainer
