import React from 'react'
import { generatePath, useParams } from 'react-router-dom'
import { useRequest } from 'storfox-api-hooks'
import { useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import * as API from '~/constants/api'
import useMessages from '~/hooks/useMessages'

import ContractInvoiceDetail from '../../components/Contract/ContractInvoice/ContractInvoiceDetail'
import { useInvoiceDetail } from '../../hooks/Contract'

const ContractUpdateContainer = () => {
  const { contractGuid, guid } = useParams()
  const messages = useMessages()
  const request = useRequest()
  const snackbar = useSnackbar()

  const contractDetail = useInvoiceDetail(contractGuid, guid)

  const title = 'Contract invoice'

  const handleGenerateInvoice = () => {
    const url = generatePath(API.CONTRACT_INVOICE_GENERATE, { contractGuid, guid })
    request.post(url).then(() => snackbar({ message: messages.NOTIFICATION_WAIT }))
  }

  const handleGenerateExcel = () => {
    const url = generatePath(API.TPL_BILLING_EXCEL_GENERATE, { contractGuid, guid })
    request.post(url).then(() => snackbar({ message: messages.NOTIFICATION_WAIT }))
  }

  const handleMarkAsPaid = () => {
    const url = generatePath(API.TPL_BILLING_INVOICE_MARK_AS_PAID, { contractGuid, guid })
    request.put(url)
      .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
      .then(() => contractDetail.getDetail())
  }

  return (
    <DashboardLayout
      title={NAV.CONFIGURATION}
      activeNav={NAV.CONFIGURATION}
      breadcrumbs={{ title }}
    >
      <ContractInvoiceDetail
        pageTitle={title}
        detail={contractDetail}
        onGenerateInvoice={handleGenerateInvoice}
        onGenerateExcel={handleGenerateExcel}
        onMarkAsPaid={handleMarkAsPaid}
      />
    </DashboardLayout>
  )
}

export default ContractUpdateContainer
