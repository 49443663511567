import React from 'react'
import { prop } from 'ramda'
import PropTypes from 'prop-types'

import PageTitle from '~/components/PageTitle'
import { DiscardButton, SaveButton } from '~/components/Buttons'
import Container, { Content, Header } from '~/components/Container'
import { withForm } from '~/components/Form'
import * as NAV from '~/constants/nav-titles'
import { PURCHASE_ORDER_STATUSES } from '~/constants/statuses'

import PurchaseOrderForm from '../PurchaseOrderForm/PurchaseOrderForm'

export const PURCHASE_ORDER_UPDATE_FIELDS = [
  'supplier',
  'lineItems',
  'notes',
  'warehouse',
  'orderDate',
  'expectedDate',
  'paymentType',
  'paymentTerm',
  'notifySupplier',
  'supplierReference',
  'tags'
]

function PurchaseOrderUpdateForm (props) {
  const {
    pageTitle,
    isLoading,
    detailLoading,
    pageTitleLoading,
    onSupplierCreate,
    purchaseOrderVariant,
    onFileAttach,
    onFileAttachRemove,
    form
  } = props

  const { initialValues, handleSubmit } = form

  const status = prop('orderStatus', initialValues)

  const disabled = (
    isLoading ||
    status === PURCHASE_ORDER_STATUSES.CANCELLED ||
    status === PURCHASE_ORDER_STATUSES.CLOSED
  )

  return (
    <>
      <form onSubmit={handleSubmit} autoComplete="off">
        <Container>
          <Header>
            <PageTitle
              pageTitle={pageTitle}
              pageTitleLoading={pageTitleLoading}
              parentTitle={NAV.PURCHASE_ORDER}
              rightButton={(
                <>
                  <DiscardButton disabled={isLoading} />
                  <SaveButton disabled={isLoading || disabled} />
                </>
              )}
            />
          </Header>

          <Content>
            <PurchaseOrderForm
              onSupplierCreate={onSupplierCreate}
              isLoading={detailLoading}
              companyDisabled={true}
              purchaseOrderVariant={purchaseOrderVariant}
              onFileAttach={onFileAttach}
              onFileAttachRemove={onFileAttachRemove}
            />
          </Content>
        </Container>
      </form>

    </>
  )
}

PurchaseOrderUpdateForm.propTypes = {
  form: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  detailLoading: PropTypes.bool.isRequired,
  pageTitle: PropTypes.node.isRequired,
  pageTitleLoading: PropTypes.bool.isRequired,
  onSupplierCreate: PropTypes.func.isRequired,
  purchaseOrderVariant: PropTypes.object.isRequired,
  onFileAttach: PropTypes.func.isRequired,
  onFileAttachRemove: PropTypes.func.isRequired
}

export default withForm(PurchaseOrderUpdateForm)
