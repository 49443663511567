import React from 'react'
import { useOrdering } from 'storfox-filter'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'

import { useConditionList } from '../../hooks/Condition'
import {
  ConditionOrderingForm,
  conditionOrderingOptions,
  ConditionTable
} from '../../components/Condition/ConditionList'

function ConditionListContainer () {
  const conditionList = useConditionList()

  const ordering = useOrdering(conditionOrderingOptions)

  const breadcrumbs = { title: 'Condition' }

  return (
    <DashboardLayout
      activeNav={NAV.CONFIGURATION}
      title="Condition"
      breadcrumbs={breadcrumbs}
    >
      <ConditionOrderingForm {...ordering} />

      <ConditionTable
        list={conditionList}
        ordering={ordering}
        onListRefetch={conditionList.getListByParams}
      />
    </DashboardLayout>
  )
}

export default ConditionListContainer
