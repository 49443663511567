import React, { useCallback } from 'react'
import { length, path, pathOr, prop, propOr } from 'ramda'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import IconButton from '@mui/material/IconButton'
import Delete from '@mui/icons-material/Delete'
import { useField } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { useValueChanged } from 'storfox-tools'
import { useTranslation } from 'react-i18next'
import AddIcon from '@mui/icons-material/Add'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import PropTypes from 'prop-types'

import Avatar from '~/components/Avatar/Avatar'
import Money from '~/components/Money'
import VerticalAlignment from '~/components/VerticalAlignment'
import TextOverflow from '~/components/TextOverflow'
import Subtext from '~/components/Subtext'
import EmptyRow from '~/components/EmptyRow'
import { COMPLETED, PROCESSING } from '~/components/Statuses/ReturnStatus'
import TextField from '~/components/Fields/TextField'
import { Button } from '~/components/Buttons'
import useDialog from '~/hooks/useDialog'
import { emptyArray } from '~/constants/empty'

import ReturnMoney from './ReturnMoney'
import ReturnQuantityField from './Fields/ReturnQuantityField'
import ReturnVariantAddDialog from './ReturnVariantAdd/ReturnVariantAddDialog'

function OrderCreateFormLineItems ({ returnVariants }) {
  const { t } = useTranslation()
  const { handleClose, handleOpen, open } = useDialog()

  const lineItemsField = useField('lineItems')
  const lineItemsFieldError = pathOr('', ['meta', 'submitError', 0], lineItemsField)
  const lineItemsValue = lineItemsField.input.value || emptyArray
  const lineItemsChange = lineItemsField.input.onChange

  const orderField = useField('order')
  const orderGuid = prop('guid', orderField.input.value)

  const statusField = useField('status')

  const searchField = useField('search')
  const searchFieldChange = searchField.input.onChange

  const status = statusField.input.value
  const quantityDisabled = (
    status === COMPLETED || status === PROCESSING
  )

  useValueChanged(() => {
    lineItemsField.input.onChange([])
  }, orderGuid)

  const saleOrderSearchParams = {
    limit: 30,
    orderGuid
  }

  const handleOnSearch = useCallback(value => {
    searchFieldChange(value)
    handleOpen()
  }, [handleOpen, searchFieldChange])

  const handleOnAddClose = useCallback(() => {
    searchFieldChange('')
    handleClose()
  }, [handleClose, searchFieldChange])

  const handleVariantsAdd = useCallback(variants => {
    const newVariants = variants.map(variant => {
      const id = prop('id', variant)
      const quantity = prop('quantity', variant)
      const condition = prop('condition', variant)
      const conditionId = prop('id', condition)
      const price = prop('retailPrice', condition)
      return {
        variant,
        quantity: 1,
        totalQuantity: quantity,
        price,
        condition,
        key: `${id}-${conditionId}`
      }
    })
    lineItemsChange([...lineItemsValue, ...newVariants])
    handleClose()
  }, [lineItemsChange, handleClose, lineItemsValue])

  return (
    <FieldArray name="lineItems">
      {({ fields }) => {
        const info = !length(propOr([], 'value', fields))

        return <>
          <Box>
            <Grid container={true} spacing={3}>
              <Grid item={true} lg={10} xs={12}>
                <TextField
                  data-cy="variant"
                  name="variant"
                  label="Search"
                  onChange={event => orderGuid && handleOnSearch(event.target.value)}
                  disabled={!orderGuid}
                  error={lineItemsFieldError}
                />
              </Grid>
              <Grid item={true} lg={2} xs={12}>
                <Button
                  fullWidth={true}
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={handleOpen}
                  type="button"
                  data-cy="browse"
                  disabled={!orderGuid}
                >
                  Browse
                </Button>
              </Grid>
            </Grid>
          </Box>
          {open && (
            <ReturnVariantAddDialog
              onClose={handleOnAddClose}
              open={open}
              onAddVariants={handleVariantsAdd}
              params={saleOrderSearchParams}
              returnVariants={returnVariants}
            />
          )}

          <br />

          <TableContainer>
            <Table size="small" sx={{ minWidth: '700px' }}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('Image')}</TableCell>
                  <TableCell sx={{ width: '250px' }}>{t('Product')}</TableCell>
                  <TableCell>{t('Unit price')}</TableCell>
                  <TableCell sx={{ minWidth: '200px' }}>{t('Condition')}</TableCell>
                  <TableCell>{t('Total Qty')}</TableCell>
                  <TableCell>{t('Return Qty')}</TableCell>
                  <TableCell>{t('Total')}</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {fields.map((name, index) => {
                  const value = prop('value', fields)
                  const item = prop(index, value)

                  const variant = prop('variant', item)
                  const condition = path(['condition', 'code'], item)
                  const variantName = prop('name', variant)
                  const sku = prop('sku', variant)
                  const imagePath = prop('defaultImage', variant)

                  const unitCost = propOr(0, 'price', item)
                  const quantity = propOr(0, 'quantity', item)
                  const totalPrice = quantity * unitCost

                  const totalQuantity = prop('totalQuantity', item)

                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <Avatar
                          alt={variantName}
                          src={imagePath}
                        />
                      </TableCell>
                      <TableCell>
                        <VerticalAlignment
                          sx={{ minWidth: '200px' }}
                          primary={(
                            <TextOverflow selfTooltip={true}>
                              {variantName}
                            </TextOverflow>
                          )}
                          secondary={<Subtext lines={1}>{sku}</Subtext>}
                        />
                      </TableCell>
                      <TableCell>
                        <ReturnMoney
                          name={`${name}.price`}
                          conditionFieldName={`${name}.condition`}
                          value={unitCost}
                        />
                      </TableCell>
                      <TableCell>
                        {condition}
                      </TableCell>
                      <TableCell align="center">{totalQuantity}</TableCell>
                      <TableCell sx={{ width: '50px' }}>
                        <ReturnQuantityField
                          conditionName={`${name}.condition`}
                          name={`${name}.quantity`}
                          data-cy={`${name}.quantity`}
                          disabled={quantityDisabled}
                          maxValue={totalQuantity}
                        />
                      </TableCell>
                      <TableCell>
                        <Money value={totalPrice} />
                      </TableCell>
                      <TableCell align="right">
                        <IconButton onClick={() => fields.remove(index)} size="large">
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                })}
                {info && <EmptyRow colSpan={7} />}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      }}
    </FieldArray>
  )
}

OrderCreateFormLineItems.propTypes = {
  returnVariants: PropTypes.object.isRequired
}

export default OrderCreateFormLineItems
