import React, { useState } from 'react'
import { descend, map, prop, propOr, sort } from 'ramda'
import PropTypes from 'prop-types'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import TableContainer from '@mui/material/TableContainer'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import InfoIcon from '@mui/icons-material/Info'
import { IconButton } from '@mui/material'

import { CardHeader } from '~/components/Cards'
import { getFullName } from '~/utils'
import HtmlTooltip from '~/components/HtmlTooltip/HtmlTooltip'

import ReceivingChart from './ReceivingChart'
import PutawayChart from './PutawayChart'
import ReceivingPutawayTabs, { RECEIVING_STATISTIC_TAB, PUTAWAY_STATISTIC_TAB } from './ReceivingPutawayTabs'

const getLabelsAndReceiverStatistics = items => {
  const sortedItems = sort(descend(prop('rank')), items)

  const labels = map(item => getFullName(prop('receiver', item)), sortedItems)
  const receiverLevels = map(prop('score'), sortedItems)

  return { labels, receiverLevels }
}

const getLabelsAndPutterStatistics = items => {
  const sortedItems = sort(descend(prop('rank')), items)

  const labels = map(item => getFullName(prop('putter', item)), sortedItems)
  const putterLevels = map(prop('score'), sortedItems)

  return { labels, putterLevels }
}

function ReceivingStatistics ({ receiveStatistic, putawayStatistic }) {
  const { t } = useTranslation()
  const [tab, setTab] = useState(RECEIVING_STATISTIC_TAB)

  const pickerResults = propOr([], 'results', receiveStatistic)
  const packerResults = propOr([], 'results', putawayStatistic)

  const { labels: receiveLabels, receiverLevels } = getLabelsAndReceiverStatistics(pickerResults)
  const { labels: putterLabels, putterLevels } = getLabelsAndPutterStatistics(packerResults)

  return (
    <Card>
      <CardHeader
        title={
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h4">{t('Top 10 Receiving & Put Away')}</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ReceivingPutawayTabs value={tab} onChange={(e, tab) => setTab(tab)} />
              <HtmlTooltip
                title="Receiving and putaway graph will contain tooltip, displaying:
                 Create separate IDs for receiving and putaway."
              >
                <IconButton size="small">
                  <InfoIcon sx={{ color: '#BDBDBD' }} />
                </IconButton>
              </HtmlTooltip>
            </Box>
          </Box>
        }
        sx={{ paddingBottom: 0 }}
      />
      <Divider />
      <CardContent sx={{ p: 0 }}>
        <TableContainer>
          <Box sx={{ minWidth: 700 }}>
            {tab === RECEIVING_STATISTIC_TAB && (
              <ReceivingChart
                data={receiverLevels}
                labels={receiveLabels}
                sx={{
                  padding: theme => theme.spacing(4, 2, 0, 2),
                  height: 400
                }}
              />
            )}
            {tab === PUTAWAY_STATISTIC_TAB && (
              <PutawayChart
                data={putterLevels}
                labels={putterLabels}
                sx={{
                  padding: theme => theme.spacing(4, 2, 0, 2),
                  height: 400
                }}
              />
            )}
          </Box>
        </TableContainer>
      </CardContent>
    </Card>
  )
}

ReceivingStatistics.propTypes = {
  receiveStatistic: PropTypes.object,
  putawayStatistic: PropTypes.object,
}

export default ReceivingStatistics
