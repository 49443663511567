import React from 'react'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'

import Chip from '~/components/Chip'

function ConditionTitle ({ activeConditionCode }) {
  return (
    <Box sx={{ pt: '70px', ml: 3 }}>
      <Typography noWrap={true}>
        Condition: <Chip label={activeConditionCode} color="success" />
      </Typography>
    </Box>
  )
}

ConditionTitle.propTypes = {
  activeConditionCode: PropTypes.string.isRequired
}

export default ConditionTitle
