import { useCallback, useContext } from 'react'
import { compose, filter, find, flatten, isNil, map, not, pipe, pluck, prop, propEq, sortBy } from 'ramda'

import Context from './context'

const getFiltersByName = (name, ordering) => pipe(
  filter(propEq('group', name)),
  pluck('filters'),
  flatten,
  sortBy(prop('position'))
)(ordering)

const getColumnsByName = (name, ordering) => pipe(
  filter(propEq('group', name)),
  pluck('columns'),
  flatten,
  sortBy(prop('position')),
  filter(compose(not, isNil))
)(ordering)

export function useFilterOrderingItems (name) {
  const { items, setItems } = useContext(Context)

  const filterItems = getFiltersByName(name, items)

  const setFilterOrdering = ({ name, filters }) => {
    const newItems = items.map(
      item => propEq('group', name, item) ? ({ ...item, filters }) : item
    )
    setItems(newItems)
  }

  return { filterItems, setFilterOrdering }
}

export function useColumnOrderingItems (name) {
  const { items, setItems } = useContext(Context)
  const columnItems = getColumnsByName(name, items)
  const foundItem = items.find(item => item.group === name)

  const getItemsWithAppendedItem = useCallback((columns = [], filters = []) => {
    return [...items, { group: name, columns, filters }]
  }, [items, name])

  const setColumnOrder = useCallback(({ name, columns }) => {
    const newColumnItems = columns.map((item, index) => ({
      key: prop('field', item),
      position: index,
      visible: prop('visible', item),
      width: prop('width', item)
    }))

    if (!foundItem) {
      const newItems = getItemsWithAppendedItem(newColumnItems, [])
      setItems(newItems)
    }

    if (foundItem) {
      const newItems = map(
        item => propEq('group', name, item) ? { ...item, columns: newColumnItems } : item
        , items)

      setItems(newItems)
    }
  }, [foundItem, getItemsWithAppendedItem, items, setItems])

  const setColumnWidth = useCallback(({ name, column }) => {
    const field = prop('field', column)
    const position = prop('position', column)
    const visible = prop('visible', column)
    const width = prop('width', column)

    const newColumn = { key: field, position, visible, width }

    if (!foundItem) {
      const newColumnItems = [newColumn]
      const newItems = getItemsWithAppendedItem(newColumnItems, [])
      setItems(newItems)
      return
    }

    const foundColumn = find(propEq('key', field))(columnItems)

    if (!foundColumn) {
      const newColumnItems = [...columnItems, newColumn]
      const newItems = map(
        item => propEq('group', name, item) ? { ...item, columns: [...newColumnItems] } : item
        , items)

      setItems(newItems)
    }

    if (foundColumn) {
      const newColumnItems = map(
        columnItem => propEq('key', field, columnItem) ? { ...columnItem, width } : columnItem,
        columnItems)

      const newItems = map(
        item => propEq('group', name, item) ? { ...item, columns: [...newColumnItems] } : item
        , items)

      setItems(newItems)
    }
  }, [columnItems, foundItem, getItemsWithAppendedItem, items, setItems])

  return { columnItems, setColumnOrder, setColumnWidth }
}

export function useOrderingItems () {
  const { items, setItems } = useContext(Context)

  return { items, setItems }
}
