import React from 'react'
import Grid from '@mui/material/Grid'
import PropTypes from 'prop-types'

import { BillingAreaField, BillingWarehouseField, BillingZoneField } from './index'

function FilterLocationFields ({ name }) {
  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12} lg={4}>
        <BillingWarehouseField name={`${name}.warehouse`} />
      </Grid>

      <Grid item={true} xs={12} lg={4}>
        <BillingAreaField
          name={`${name}.area`}
          warehouseName={`${name}.warehouse`}
          label="Area"
          primaryKey="guid"
        />
      </Grid>

      <Grid item={true} xs={12} lg={4}>
        <BillingZoneField
          name={`${name}.zone`}
          areaName={`${name}.area`}
          label="Zone"
          primaryKey="guid"
        />
      </Grid>
    </Grid>
  )
}

FilterLocationFields.propTypes = {
  name: PropTypes.string.isRequired
}

export default FilterLocationFields
