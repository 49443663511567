import React from 'react'
import { useFilter, useOrdering } from 'storfox-filter'
import { objectToSearchParams, useAllSearchParams } from 'storfox-route-hooks'
import { path } from 'ramda'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'
import { generatePath, useNavigate } from 'react-router-dom'

import { DashboardLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'
import useMessages from '~/hooks/useMessages'
import ErrorLink from '~/components/ErrorLink'
import { useTableSelects } from '~/components/TableValues/hooks'
import { useGoogleEvent } from '~/components/GoogleAnalytics/GoogleAnalytics'

import {
  PackingFilterForm,
  packingFilterOptions,
  PackingList,
  PackingOrderingForm,
  packingOrderingOptions
} from '../components/PackingList'
import { usePackingContainerBarcodeScan, usePackingJobCreate, usePackingList, usePackingProcessBulk } from '../hooks'
import { PackingJobListCreateSerializer } from '../serializers'

function PackingListContainer () {
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const messages = useMessages()
  const { status } = useAllSearchParams()
  const { sendEvent } = useGoogleEvent()
  const { selects, resetTableSelects } = useTableSelects()

  const packingList = usePackingList()
  const packingProcess = usePackingProcessBulk()
  const packingJobCreate = usePackingJobCreate()
  const packingContainerBarcodeScan = usePackingContainerBarcodeScan()

  const filter = useFilter(packingFilterOptions)
  const ordering = useOrdering(packingOrderingOptions)

  const handleProcess = () =>
    packingProcess.update({ ids: selects })
      .then(() => {
        sendEvent({ eventAction: 'Process Packing', eventCategory: 'Packing' })
        snackbar({ message: messages.UPDATE_SUCCESS })
      })
      .then(() => resetTableSelects())
      .then(() => packingList.getList())

  const handleContainerBarcodeScan = ({ containerNumber, barcode }) =>
    packingContainerBarcodeScan.scan(containerNumber, { unitNumber: barcode })
      .then(data => {
        sendEvent({ eventAction: 'Container Scan Packing', eventCategory: 'Packing' })
        const results = path(['data', 'results'], data)
        const id = path([0, 'id'], results)

        const url = generatePath(ROUTES.PACKING_UPDATE_PATH, { id })
        const queryParams = {
          initiallyScannedContainer: containerNumber,
          initiallyScannedBarcode: barcode
        }

        const urlPath = `${url}?${objectToSearchParams(queryParams)}`
        navigate(urlPath)
      })
      .catch(() => snackbar({
        message: messages.CONTAINER_FIND_FAIL,
        type: ALTER_ERROR
      }))

  const handlePackerChange = (id, values) =>
    packingJobCreate.create(PackingJobListCreateSerializer(id, values))
      .then(() => {
        sendEvent({ eventAction: 'Assign Packer Packing', eventCategory: 'Packing' })
        snackbar({ message: messages.UPDATE_SUCCESS })
      })
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ type: ALTER_ERROR, message })
        throw error
      })

  const breadcrumbs = { title: NAV.PACKING }

  return (
    <DashboardLayout
      title={NAV.PACKING}
      activeNav={NAV.PACKING}
      breadcrumbs={breadcrumbs}
    >
      <PackingFilterForm {...filter} />
      <PackingOrderingForm {...ordering} />

      <PackingList
        pageTitle={NAV.PACKING}
        parentTitle={NAV.FULFILLMENT}
        packingList={packingList}
        disabled={selects.length === 0}
        onPackerChange={handlePackerChange}
        initialTableValues={{ packData: packingList.results }}
        filter={filter}
        ordering={ordering}
        onListRefetch={packingList.getListByParams}
        status={status}
        onSubmit={handleProcess}
        onBarcodeScan={handleContainerBarcodeScan}
        bulkActionLoading={packingProcess.isLoading}
      />
    </DashboardLayout>
  )
}

export default PackingListContainer
