export const WAREHOUSE = 'warehouse'
export const CHANNEL = 'channel'
export const COUNTRY = 'country'
export const CITY = 'city'
export const DELIVERY_METHOD = 'deliveryMethod'
export const COMPANY = 'company'
export const CATEGORY = 'category'
export const VARIANT = 'variant'
export const CONDITION = 'condition'
export const PAYMENT_METHOD = 'paymentMethod'
export const SUPPLIER = 'supplier'
export const PURCHASE_STATUS = 'purchaseStatus'
export const SHIPMENT_STATUS = 'shipmentStatus'
export const SHIPMENT_CARRIER = 'shipmentCarrier'
export const SHIPMENT_CARRIER_CITY = 'shipmentCarrierCity'

export const ORDER_STATUS = 'sale-order.status'
export const ORDER_ALLOCATION_STATUS = 'sale-order.allocation_status'
export const ORDER_SHIPMENT_STATUS = 'sale-order.shipment_status'
export const ORDER_PAYMENT_STATUS = 'sale-order.payment_status'
export const ORDER_FULFILLMENT_STATUS = 'sale-order.fulfillment_status'

export const LISTING_STATUS = 'listing.status'
export const LISTING_TYPE = 'listing.type'
