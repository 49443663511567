import { prop } from 'ramda'

import { unescapeBtoa } from './base64'

export const arrayToParams = (key, values) => {
  try {
    const value = prop(key, values)
    return value.length ? unescapeBtoa(value) : null
  } catch (err) {
    return null
  }
}
