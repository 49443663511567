import React from 'react'
import { Autocomplete as MUIAutocomplete } from '@mui/lab'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import { find, prop } from 'ramda'

import FieldWrapper from './FieldWrapper'

const defaultGetOptionValue = val => {
  if (val) {
    const { name, value } = val
    return { name, value }
  }

  return null
}

function Autocomplete (props) {
  const {
    name,
    meta,
    disabled,
    getOptionValue,
    options,
    input,
    ...defaultProps
  } = props

  const getCurrentOptionLabel = () => {
    const currentOption = find(option => input.value === option.value, options)

    return prop('name', currentOption)
  }
  return (
    <MUIAutocomplete
      disabled={disabled}
      value={input.value}
      inputValue={input.value}
      onChange={(event, newValue) => {
        input.onChange(newValue)
      }}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={option => option.name || ''}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          {...defaultProps}
          error={meta.invalid}
          helperText={meta.submitError || meta.error}
          InputProps={{
            ...params.InputProps,
            value: getCurrentOptionLabel()
          }}
        />
      )}
    />
  )
}

Autocomplete.propTypes = {
  input: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  getOptionValue: PropTypes.func,
  options: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired
}

Autocomplete.defaultProps = {
  size: 'small',
  fullWidth: true,
  variant: 'outlined',
  disabled: false,
  params: { limit: 1000 },
  getOptionValue: defaultGetOptionValue,
}

export default FieldWrapper(Autocomplete)
