import React from 'react'
import Card from '@mui/material/Card'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import { path, pathOr, prop } from 'ramda'
import PropTypes from 'prop-types'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material'

import Avatar from '~/components/Avatar'
import Money from '~/components/Money'
import { CardHeader } from '~/components/Cards'
import CheckCircleIcon from '~/components/Icons/CheckCircleIcon'
import MinusCircleIcon from '~/components/Icons/MinusCircleIcon'

import VariantLink from './VariantLink'
import VariantOptionsLabel from './VariantOptionsLabel'

const TableStyled = styled(Table)({
  minWidth: 900,
  '& th': {
    whiteSpace: 'nowrap'
  }
})

const getOptions = (index, variants) => pathOr([], [index, 'options'], variants)

function ProductDetailVariants ({ variants, isMultiVariant }) {
  const { t } = useTranslation()
  const { id } = useParams()

  return (
    <>
      <Card>
        <CardContent>
          {isMultiVariant
            ? (
              <Box display="flex" alignItems="center">
                <Box mr={1}>
                  <CheckCircleIcon />
                </Box>
                <Typography>This is multi-variant product</Typography>
              </Box>
            )
            : (
              <Box display="flex" alignItems="center">
                <Box mr={1}>
                  <MinusCircleIcon />
                </Box>
                <Typography>This is single-variant product</Typography>
              </Box>
            )}
        </CardContent>
      </Card>

      <Box mt={3}>
        <Card>
          <CardHeader title="Items" />
          <TableContainer>
            <TableStyled>
              <TableHead>
                <TableRow>
                  <TableCell>{t('Image')}</TableCell>
                  <TableCell>{t('Variant')}</TableCell>
                  <TableCell>{t('SKU')}</TableCell>
                  <TableCell>{t('Barcode')}</TableCell>
                  <TableCell>{t('Supply price')}</TableCell>
                  <TableCell>
                    <div title={t('Maximum Retail price')}>
                      {t('M.R.P')}
                    </div>
                  </TableCell>
                  <TableCell>{t('Retail price')}</TableCell>
                  <TableCell align="center">{t('Cross docking')}</TableCell>
                  <TableCell align="center">{t('Enabled')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {variants.map((variant, index) => {
                  const options = getOptions(index, variants)
                  const variantId = prop('id', variant)
                  const variantName = prop('name', variant)

                  const defaultImage = path([index, 'defaultImage'], variant)
                  const sku = prop('sku', variant)
                  const barcode = prop('barcode', variant)
                  const supplyPrice = prop('supplyPrice', variant)
                  const maximumRetailPrice = prop('maximumRetailPrice', variant)
                  const retailPrice = prop('retailPrice', variant)
                  const isCrossDocking = prop('isCrossDocking', variant)
                  const enabled = prop('enabled', variant)

                  return (
                    <TableRow key={index}>
                      <TableCell scope="row">
                        <Avatar
                          alt={variantName}
                          src={defaultImage}
                        />
                      </TableCell>
                      <TableCell scope="row" sx={{ minWidth: 250 }}>
                        <Box width="100%">
                          <VariantLink
                            name={variantName}
                            productId={id}
                            variantId={variantId}
                            isMultiVariant={isMultiVariant}
                          />
                          <br />
                          <VariantOptionsLabel options={options} />
                        </Box>
                      </TableCell>
                      <TableCell sx={{ minWidth: 150 }}>
                        {sku}
                      </TableCell>
                      <TableCell sx={{ minWidth: 150 }}>
                        {barcode}
                      </TableCell>
                      <TableCell sx={{ minWidth: 75 }}>
                        <Money value={supplyPrice} />
                      </TableCell>
                      <TableCell sx={{ minWidth: 75 }}>
                        <Money value={maximumRetailPrice} />
                      </TableCell>
                      <TableCell sx={{ minWidth: 75 }}>
                        <Money value={retailPrice} />
                      </TableCell>
                      <TableCell align="center">
                        {isCrossDocking
                          ? <CheckCircleIcon />
                          : <MinusCircleIcon />}
                      </TableCell>
                      <TableCell align="center">
                        {enabled
                          ? <CheckCircleIcon />
                          : <MinusCircleIcon />}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </TableStyled>
          </TableContainer>
        </Card>
      </Box>
    </>
  )
}

ProductDetailVariants.propTypes = {
  variants: PropTypes.array.isRequired,
  isMultiVariant: PropTypes.bool
}

export default ProductDetailVariants
