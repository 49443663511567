import React, { useState } from 'react'
import { Alert, Box, Radio } from '@mui/material'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import Grid from '@mui/material/Grid'
import { AlertTitle } from '@mui/lab'

import { API, MAGENTO, SALLA, SHOPIFY, WOO_COMMERCE, ZID } from '~/constants/integrationTypes'
import IntegrationIcon from '~/components/Icons/IntegrationIcon'
import LinkButton from '~/components/Buttons/LinkButton'
import * as ROUTES from '~/constants/routes'

import ActionButtons from './ActionButtons'

export const INTEGRATIONS = [
  {
    type: API,
    name: 'Public API',
    path: ROUTES.API_INTEGRATION_CREATE_PATH
  },
  {
    type: SHOPIFY,
    name: 'Shopify',
    path: ROUTES.SHOPIFY_INSTRUCTION_CONTAINER
  },
  {
    type: WOO_COMMERCE,
    name: 'WooCommerce',
    path: ROUTES.WOOCOMMERCE_INTEGRATION_CREATE_PATH
  },
  {
    type: MAGENTO,
    name: 'Magento',
    path: ROUTES.MAGENTO_INTEGRATION_CREATE_PATH
  },
  {
    type: ZID,
    name: 'Zid',
    path: ROUTES.ZID_INTEGRATION_APPLICATION_CREATE_PATH
  },
  {
    type: SALLA,
    name: 'Salla',
    path: ROUTES.SALLA_INSTRUCTION_CONTAINER
  }
]

function IntegrationStep ({ onSkip }) {
  const [selectedPath, setSelectedPath] = useState(ROUTES.API_INTEGRATION_CREATE_PATH)

  const handleSelect = path => {
    setSelectedPath(path)
  }

  return (
    <Box>
      <Typography variant="h5" gutterBottom={true}>Which store do you want to set up?</Typography>
      <Grid container={true} spacing={2}>
        {INTEGRATIONS.map(({ type, name, path }) => (
          <Grid key={name} item={true} xs={6} lg={4}>
            <Card>
              <CardActionArea onClick={() => handleSelect(path)}>
                <Box
                  sx={{
                    p: 1,
                    textAlign: 'center',
                    '& > svg': {
                      width: '50px',
                      height: '50px'
                    }
                  }}
                >
                  <IntegrationIcon type={type} />
                  <Typography>{name}</Typography>
                  <Radio size="small" value={path} checked={path === selectedPath} />
                </Box>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Alert severity="info" sx={{ mt: 2 }}>
        <AlertTitle>I have multiple stores, which should I connect first?</AlertTitle>
        <Typography>Connect the store with the most complete product data.</Typography>
      </Alert>

      <ActionButtons onSkip={onSkip}>
        <LinkButton target="_blank" href={selectedPath}>Set up</LinkButton>
      </ActionButtons>
    </Box>
  )
}

IntegrationStep.propTypes = {
  onSkip: PropTypes.func.isRequired
}

export default IntegrationStep
