import React from 'react'
import { useFilter, useOrdering } from 'storfox-filter'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import ErrorLink from '~/components/ErrorLink'
import useMessages from '~/hooks/useMessages'

import TeamMetricsTable, {
  TeamMetricsFilterForm,
  teamMetricsFilterOptions,
  TeamMetricsOrderingForm,
  teamMetricsOrderingOptions
} from '../../components/TeamMetrics/TeamMetricsList'
import { useTeamMetricsExport, useTeamMetricsList } from '../../hooks/TeamMetrics'

function TeamMetricsListContainer () {
  const teamMetricsList = useTeamMetricsList()
  const teamMetricsExport = useTeamMetricsExport()
  const messages = useMessages()
  const snackbar = useSnackbar()

  const filter = useFilter(teamMetricsFilterOptions)
  const ordering = useOrdering(teamMetricsOrderingOptions)

  const breadcrumbs = { title: NAV.TEAM_METRICS }

  const handleTeamMetricsExport = () =>
    teamMetricsExport.export()
      .then(() => snackbar({ message: messages.NOTIFICATION_WAIT }))
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  return (
    <DashboardLayout
      title={NAV.TEAM_METRICS}
      activeNav={NAV.REPORTS}
      breadcrumbs={breadcrumbs}
    >
      <TeamMetricsFilterForm {...filter} />
      <TeamMetricsOrderingForm {...ordering} />

      <TeamMetricsTable
        onListRefetch={teamMetricsList.getListByParams}
        filter={filter}
        ordering={ordering}
        list={teamMetricsList}
        onTeamMetricsExport={handleTeamMetricsExport}
      />
    </DashboardLayout>
  )
}

export default TeamMetricsListContainer
