import React from 'react'
import { useParams } from 'react-router-dom'
import { path } from 'ramda'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import { WAREHOUSE_TABS } from '~/constants/tabs'

import { CONTENT } from '../constants'
import LocationDetail from '../components/LocationDetail'
import { useLocationContent, useLocationDetail } from '../hooks'

function LocationUpdateContainer () {
  const { guid, tab } = useParams()
  const locationDetail = useLocationDetail(guid)

  const locationContent = useLocationContent(guid, tab === CONTENT)

  const warehouseGuid = path(['warehouse', 'guid'], locationDetail.detail)
  const warehouseName = path(['warehouse', 'name'], locationDetail.detail)

  const title = path(['detail', 'locationId'], locationDetail)

  const breadcrumbs = {
    title,
    params: [
      { guid: warehouseGuid, tab: WAREHOUSE_TABS.LOCATION, title: warehouseName }
    ]
  }

  return (
    <DashboardLayout
      title={title}
      isLoading={locationDetail.isLoading}
      activeNav={NAV.WAREHOUSE}
      breadcrumbs={breadcrumbs}
    >
      <LocationDetail
        tab={tab}
        title={title}
        pageTitleLoading={locationDetail.isLoading}
        isLoading={locationDetail.isLoading}
        detail={locationDetail.detail}
        locationContent={locationContent}
      />
    </DashboardLayout>
  )
}

export default LocationUpdateContainer
