import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { pathOr } from 'ramda'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'
import { useTranslation } from 'react-i18next'

import { DashboardLayout } from '~/components/Layouts'
import { useUpdateProfile } from '~/components/Profile'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import ErrorLink from '~/components/ErrorLink'

import { TaxCreateSerializer } from '../../serializers/Tax'
import { useTaxDetail, useTaxRemove, useTaxUpdate } from '../../hooks/Tax'
import TaxUpdateForm from '../../components/Tax/TaxUpdateForm'

function TaxUpdateContainer () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { guid } = useParams()
  const snackbar = useSnackbar()
  const taxDetail = useTaxDetail(guid)
  const taxUpdate = useTaxUpdate(guid)
  const taxRemove = useTaxRemove(guid)
  const { updateProfile } = useUpdateProfile()

  const message = t('Successfully updated')
  const title = pathOr('Taxes', ['detail', 'name'], taxDetail)
  const pageLoading = taxDetail.isLoading || taxUpdate.isLoading

  const handleSubmit = formValues =>
    taxUpdate.update(TaxCreateSerializer(formValues))
      .then(() => snackbar({ message }))
      .then(() => updateProfile())
      .then(() => navigate(ROUTES.TAX_LIST_PATH))

  const handleRemove = () =>
    taxRemove.delete()
      .then(() => {
        navigate(ROUTES.TAX_LIST_PATH, { replace: true })
      })
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      isLoading={pageLoading}
      activeNav={NAV.CONFIGURATION}
      breadcrumbs={breadcrumbs}
    >
      <TaxUpdateForm
        title={title}
        onSubmit={handleSubmit}
        onRemove={handleRemove}
        pageTitleLoading={taxDetail.isLoading}
        isLoading={taxUpdate.isLoading}
        initialValues={taxDetail.detail}
      />
    </DashboardLayout>
  )
}

export default TaxUpdateContainer
