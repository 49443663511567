import React from 'react'

import StorfoxPaymentStatusField, { OPTIONS as BASE_OPTIONS } from '~/components/Fields/PaymentStatusField'

const OPTIONS = [{ value: '', name: 'All' }, ...BASE_OPTIONS]

function PaymentStatusField (props) {
  return (
    <StorfoxPaymentStatusField
      name="paymentStatus"
      label="Payment status"
      options={OPTIONS}
      {...props}
    />
  )
}

export default PaymentStatusField
