import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import StockCountListContainer from './containers/StockCountListContainer'
import StockCountCreateContainer from './containers/StockCountCreateContainer'
import StockCountUpdateContainer from './containers/StockCountUpdateContainer'

export default () => [
  {
    layout: AuthLayout,
    path: ROUTES.STOCK_COUNT_LIST_PATH,
    component: StockCountListContainer
  },
  {
    layout: AuthLayout,
    path: ROUTES.STOCK_COUNT_CREATE_PATH,
    component: StockCountCreateContainer
  },
  {
    layout: AuthLayout,
    path: ROUTES.STOCK_COUNT_UPDATE_PATH,
    component: StockCountUpdateContainer
  }
]
