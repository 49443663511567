import React from 'react'
import { useParams } from 'react-router-dom'
import { path } from 'ramda'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import { SUPPLIER_DETAIL_TABS } from '~/constants/tabs'

import SupplierDetail from '../components/SupplierDetail'
import { useSupplierDetail, useSupplierHistory, useSupplierPurchaseList } from '../hooks'
import { SupplierInitialSerializer } from '../serializers'

function SupplierUpdateContainer () {
  const { guid, tab } = useParams()

  const purchasesTab = tab === SUPPLIER_DETAIL_TABS.PURCHASES
  const historyTab = tab === SUPPLIER_DETAIL_TABS.HISTORY

  const supplierDetail = useSupplierDetail(guid)
  const supplierHistory = useSupplierHistory(guid, historyTab)
  const supplierPurchasesList = useSupplierPurchaseList(guid, purchasesTab)

  const companyName = path(['detail', 'companyName'], supplierDetail)
  const title = supplierDetail.isLoading ? 'Suppliers' : companyName

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      isLoading={supplierDetail.isLoading}
      activeNav={NAV.SUPPLIERS}
      breadcrumbs={breadcrumbs}
    >
      <SupplierDetail
        pageTitle={title}
        pageTitleLoading={supplierDetail.isLoading}
        detail={SupplierInitialSerializer(supplierDetail.detail)}
        isLoading={supplierDetail.isLoading}
        supplierHistory={supplierHistory}
        purchaseList={supplierPurchasesList}
      />
    </DashboardLayout>
  )
}

export default SupplierUpdateContainer
