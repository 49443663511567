import React from 'react'
import { propOr } from 'ramda'

import MoneyField from '~/components/Fields/MoneyField'
import { useCompany } from '~/components/Profile'

function PricingWeightField (props) {
  const company = useCompany()
  const weightUnit = propOr('kg', 'weightUnit', company).toString()

  return (
    <MoneyField
      label={`Fee per additional ${weightUnit}`}
      {...props}
    />
  )
}

export default PricingWeightField
