import React from 'react'
import { useFilter, useOrdering } from 'storfox-filter'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'

import {
  CompanyFilterForm,
  companyFilterOptions,
  CompanyOrderingForm,
  companyOrderingOptions,
  CompanyTable
} from '../components/CompanyList'
import { useCompanyList } from '../hooks'

function CompanyListContainer () {
  const companyList = useCompanyList()

  const filter = useFilter(companyFilterOptions)
  const ordering = useOrdering(companyOrderingOptions)

  const breadcrumbs = { title: NAV.COMPANIES }

  return (
    <DashboardLayout
      title={NAV.COMPANIES}
      activeNav={NAV.COMPANIES}
      breadcrumbs={breadcrumbs}
    >
      <CompanyFilterForm {...filter} />
      <CompanyOrderingForm {...ordering} />

      <CompanyTable
        filter={filter}
        ordering={ordering}
        list={companyList}
        onListRefetch={companyList.getListByParams}
      />
    </DashboardLayout>
  )
}

export default CompanyListContainer
