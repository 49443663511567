import { useAllSearchParams } from 'storfox-route-hooks'
import { useDetail, useList } from 'storfox-api-hooks'

import * as API from '~/constants/api'
import { queryToParams } from '~/utils'

import { OverviewFilterSerializer, OverviewPurchaseFilterSerializer } from '../serializers'

export const useGMSDTD = (params) => {
  const searchParams = useAllSearchParams()
  const filterParams = queryToParams(OverviewFilterSerializer, { ...params, ...searchParams })

  return useList(API.OVERVIEW_GMS_DTD, filterParams)
}

export const useOrderByChannel = (params) => {
  const searchParams = useAllSearchParams()
  const filterParams = queryToParams(OverviewFilterSerializer, { ...params, ...searchParams })

  return useList(API.OVERVIEW_ORDER_BY_CHANNEL, filterParams)
}

export const useCarrierDivision = (params) => {
  const searchParams = useAllSearchParams()
  const filterParams = queryToParams(OverviewFilterSerializer, { ...params, ...searchParams })

  return useList(API.OVERVIEW_CARRIER_DIVISION, filterParams)
}

export const useStockFlow = (params) => {
  const searchParams = useAllSearchParams()
  const filterParams = queryToParams(OverviewFilterSerializer, { ...params, ...searchParams })

  return useList(API.OVERVIEW_FLOW_OVERVIEW, filterParams)
}

export const useOverviewInformation = (params) => {
  const searchParams = useAllSearchParams()
  const filterParams = queryToParams(OverviewFilterSerializer, { ...params, ...searchParams })

  return useDetail(API.OVERVIEW_INFORMATION, filterParams)
}

export const useStockOverview = (params) => {
  const searchParams = useAllSearchParams()
  const filterParams = queryToParams(OverviewFilterSerializer, { ...params, ...searchParams })

  return useDetail(API.OVERVIEW_STOCK, filterParams)
}

export const useShipmentOverview = (params) => {
  const searchParams = useAllSearchParams()
  const filterParams = queryToParams(OverviewFilterSerializer, { ...params, ...searchParams })

  return useDetail(API.OVERVIEW_SHIPMENTS, filterParams)
}

export const useOrdersByUnitsOverview = (params) => {
  const searchParams = useAllSearchParams()
  const filterParams = queryToParams(OverviewFilterSerializer, { ...params, ...searchParams })

  return useList(API.OVERVIEW_ORDERS_BY_UNITS, filterParams)
}

export const usePOByUnitsOverview = (params) => {
  const searchParams = useAllSearchParams()
  const filterParams = queryToParams(OverviewFilterSerializer, { ...params, ...searchParams })

  return useList(API.OVERVIEW_PO_BY_UNITS, filterParams)
}

export const usePickerStatistic = (params) => {
  const searchParams = useAllSearchParams()
  const filterParams = queryToParams(OverviewFilterSerializer, { ...params, ...searchParams })

  return useList(API.OVERVIEW_PICKER_STATISTIC, filterParams)
}

export const usePackerStatistic = (params) => {
  const searchParams = useAllSearchParams()
  const filterParams = queryToParams(OverviewFilterSerializer, { ...params, ...searchParams })

  return useList(API.OVERVIEW_PACKER_STATISTIC, filterParams)
}

export const useReceiveStatistic = (params) => {
  const searchParams = useAllSearchParams()
  const filterParams = queryToParams(OverviewFilterSerializer, { ...params, ...searchParams })

  return useList(API.OVERVIEW_RECEIVE_STATISTIC, filterParams)
}

export const usePutawayStatistic = (params) => {
  const searchParams = useAllSearchParams()
  const filterParams = queryToParams(OverviewFilterSerializer, { ...params, ...searchParams })

  return useList(API.OVERVIEW_PUTAWAY_STATISTIC, filterParams)
}

export const useOrderOverview = (params) => {
  const searchParams = useAllSearchParams()
  const filterParams = queryToParams(OverviewFilterSerializer, { ...params, ...searchParams })

  return useDetail(API.OVERVIEW_ORDER, filterParams)
}

export const usePurchaseOrder = (params) => {
  const searchParams = useAllSearchParams()
  const filterParams = queryToParams(OverviewPurchaseFilterSerializer, { ...params, ...searchParams })
  const otherParams = {
    limit: 5,
    status: 'new',
    ordering: 'expected_date'
  }

  return useList(API.PURCHASE_ORDERS_LIST, { ...otherParams, filterParams })
}

export const useLowStock = (params) => {
  const searchParams = useAllSearchParams()
  const filterParams = queryToParams(OverviewFilterSerializer, { ...params, ...searchParams, limit: 5 })

  return useList(API.OVERVIEW_LOW_STOCK, filterParams)
}
