import React from 'react'
import { path, prop } from 'ramda'
import { useParams } from 'react-router-dom'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import { USER_DETAIL_TABS } from '~/constants/tabs'

import UserDetail from '../components/UserDetail'
import { useUserDetail, useUserHistory } from '../hooks'

function UserDetailContainer () {
  const { id, tab } = useParams()
  const userDetail = useUserDetail(id)
  const guid = prop('guid', userDetail.detail)

  const historyAutoSend = Boolean(guid && tab === USER_DETAIL_TABS.HISTORY)
  const userHistory = useUserHistory(guid, historyAutoSend)

  const firstName = path(['detail', 'firstName'], userDetail)
  const lastName = path(['detail', 'lastName'], userDetail)
  const title = userDetail.isLoading ? 'Users' : `${firstName} ${lastName}`
  const pageLoading = userDetail.isLoading

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      activeNav={NAV.USERS}
      isLoading={pageLoading}
      breadcrumbs={breadcrumbs}
    >
      <UserDetail
        title={title}
        isLoading={userDetail.isLoading}
        userHistory={userHistory}
        detail={userDetail.detail}
        initialValues={userDetail.detail}
      />
    </DashboardLayout>
  )
}

export default UserDetailContainer
