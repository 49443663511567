import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import { SwitchField } from 'storfox-form-fields'
import FormControl from '@mui/material/FormControl'
import { useTranslation } from 'react-i18next'
import { useField } from 'react-final-form'
import PropTypes from 'prop-types'
import { useDeepCompareEffect } from 'storfox-api-hooks'

import { FEFO } from '../../constants'

function ExpiryDateField ({ strategyName }) {
  const { t } = useTranslation()
  const strategyField = useField(strategyName)
  const strategyValue = strategyField.input.value

  const field = useField('trackExpiryDates')
  const value = field.input.value

  useDeepCompareEffect(() => {
    if (!value) {
      field.input.onChange(strategyValue === FEFO)
    }
  }, [strategyValue])

  return (
    <FormControl style={{ padding: '0 12px' }}>
      <FormControlLabel
        data-cy="trackExpiryDates"
        control={<SwitchField name="trackExpiryDates" />}
        label={t('Track Expiry dates')}
      />
    </FormControl>
  )
}

export default ExpiryDateField

ExpiryDateField.propTypes = {
  strategyName: PropTypes.string.isRequired
}
