import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'

import { UploadedFilePlaceholder } from '~/components/FileUpload'

function UploadedFile ({ file }) {
  return (
    <UploadedFilePlaceholder file={file}>
      <Box>
        <Typography>
          Successfully validated
        </Typography>
      </Box>
    </UploadedFilePlaceholder>

  )
}

UploadedFile.propTypes = {
  file: PropTypes.object.isRequired
}

export default UploadedFile
