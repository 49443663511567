import React from 'react'
import DialogContent from '@mui/material/DialogContent'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import PropTypes from 'prop-types'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell/TableCell'
import TableBody from '@mui/material/TableBody'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import { useField } from 'react-final-form'

import { withForm } from '~/components/Form'
import { Button } from '~/components/Buttons'
import DialogTitle from '~/components/DialogTitle'
import InfinityScrollSearch from '~/components/InfinityScrollSearch'

import Loader from './Loader'
import ManualAllocationRow from './ManualAllocationRow'

function ManualAllocationDialog ({ open, onClose, form, manualUnitList }) {
  const { t } = useTranslation()
  const { handleSubmit } = form

  const lineItemsField = useField('lineItems')
  const lineItemsFieldValue = lineItemsField.input.value || []

  const isLoading = manualUnitList.isLoading

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      fullWidth={true}
      onClose={onClose}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle variant="h6">
          {t('Manual Allocation')}
          <Box sx={{ padding: '24px 0' }}>
            <InfinityScrollSearch
              onEffect={manualUnitList.reset}
              getList={manualUnitList.getList}
            />
          </Box>
        </DialogTitle>
        <DialogContent dividers={true}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>{t('Unit number')}</TableCell>
                  <TableCell>{t('Serial number')}</TableCell>
                  <TableCell>{t('Batch number')}</TableCell>
                  <TableCell>{t('Location')}</TableCell>
                  <TableCell>{t('Condition code')}</TableCell>
                  <TableCell>{t('Expiry date')}</TableCell>
                  <TableCell>{t('Available')}</TableCell>
                  <TableCell>{t('Allocate quantity')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading && <Loader />}
                {!isLoading && manualUnitList.results.map((item) => (
                  <ManualAllocationRow item={item} lineItems={lineItemsFieldValue} />
                ))}
                {!isLoading && manualUnitList.results.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={3}>{t('No units')}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>
            Close
          </Button>
          <Button onClick={handleSubmit}>
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

ManualAllocationDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  form: PropTypes.object,
  manualUnitList: PropTypes.object
}

export default withForm(ManualAllocationDialog)
