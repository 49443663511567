import React from 'react'
import ReactDOM from 'react-dom/client'
import axios from 'axios'
import { LicenseInfo } from '@mui/x-license-pro'
import '~/i18n'
import { APP_DEV_URL, APP_PROD_URL } from 'storfox-api-hooks/src/constant'
import { getApiUrl } from 'storfox-api-hooks'
import { prop } from 'ramda'

LicenseInfo.setLicenseKey(process.env.MUI_LICENSE)

const MOUNT_NODE = document.getElementById('app')
let root = null;

(function () {
  const App = require('./App').default
  const RootRoutes = require('./routes').default
  root = ReactDOM.createRoot(MOUNT_NODE)

  const hostname = location.hostname
  const isDefaultDomain = hostname === APP_DEV_URL || hostname === APP_PROD_URL || hostname === 'localhost'
  if (isDefaultDomain) {
    root.render(<App routes={RootRoutes()} />)
  } else {
    const url = `${getApiUrl()}/theme/?domain=${hostname}`
    axios.get(url).then((response) => {
      const domainValues = prop('data', response)
      localStorage.setItem('domain', JSON.stringify(domainValues))
      root.render(<App routes={RootRoutes()} />)
    })
  }
})()
