import React from 'react'
import PropTypes from 'prop-types'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import { SwitchField } from 'storfox-form-fields'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import { useField } from 'react-final-form'
import { prop } from 'ramda'
import { useValueChanged } from 'storfox-tools'

import ConstrainedField from '~/components/Fields/ConstrainedField'
import DimensionField from '~/components/Fields/DimensionField'
import MoneyField from '~/components/Fields/MoneyField'
import TagsField from '~/components/Fields/TagsField'
import TextField from '~/components/Fields/TextField'
import WeightField from '~/components/Fields/WeightField'
import { CardHeader } from '~/components/Cards'
import PageTitle from '~/components/PageTitle'
import { DiscardButton, SaveButton } from '~/components/Buttons'
import Container, { Content, Header } from '~/components/Container'
import { withForm } from '~/components/Form'
import * as NAV from '~/constants/nav-titles'
import * as API from '~/constants/api'
import MultiImageUploadField from '~/components/ImageUpload/MultiImageUpload'
import PieceQuantityField from '~/components/Fields/PieceQuantityField'
import MethodOfMeasurementField from '~/components/Fields/MethodOfMeasurementField'

import ProductSearchCreateField from '../Fields/ProductSearchCreateField'
import ExpiryDateField from '../Fields/ExpiryDateField'
import ProductTypeField from '../Fields/ProductTypeField'
import FulfillmentStrategyField from '../Fields/FulfillmentStrategyField'
import SkuField from '../Fields/SkuField'
import SupplierSearchField from '../Fields/SupplierSearchField'
import CompanySearchField from '../Fields/CompanySearchField'
import BarcodeField from '../Fields/BarcodeField'

export const PRODUCT_CREATE_FIELDS = [
  'name',
  'type',
  'description',
  'images',
  'supplyPrice',
  'maximumRetailPrice',
  'retailPrice',
  'sku',
  'barcode',
  'category',
  'brand',
  'tags',
  'length',
  'width',
  'height',
  'weight',
  'unitOfMeasurement',
  'barcodingStrategy',
  'strategy',
  'trackSerialNumbers',
  'trackExpiryDates',
  'pieces',
  'minExpiryDays',
  'supplier',
  'syncSupplyPrice',
  'company',
  'trackBatchNumbers',
  'trackProductionDates'
]

function ProductCreateForm (props) {
  const {
    form,
    title,
    isLoading,
    onCategoryCreate,
    categoryLoading,
    onBrandCreate,
    brandLoading
  } = props

  const { t } = useTranslation()
  const { handleSubmit } = form
  const trackExpiryField = useField('trackExpiryDates')
  const trackExpiry = trackExpiryField.input.value

  const companyField = useField('company')
  const companyFieldValue = companyField.input.value
  const companyId = prop('id', companyFieldValue)

  const supplierField = useField('supplier')
  const supplierFieldChange = supplierField.input.onChange

  useValueChanged(() => {
    supplierFieldChange(null)
  }, companyId)

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={title}
            parentTitle={NAV.PRODUCTS}
            rightButton={(
              <>
                <DiscardButton disabled={isLoading} />
                <SaveButton disabled={isLoading} />
              </>
            )}
          />
        </Header>

        <Content>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12} lg={8}>
              <Box mb={3}>
                <Card>
                  <CardHeader title="Details" />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12}>
                        <TextField
                          data-cy="name"
                          name="name"
                          label="Name"
                          required={true}
                        />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <ProductTypeField />
                      </Grid>
                      <Grid item={true} xs={12}>
                        <TextField
                          data-cy="description"
                          name="description"
                          label="Description"
                          multiline={true}
                          minRows={4}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
              <Box mb={3}>
                <Card>
                  <CardHeader title="Pricing" />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={6}>
                        <MoneyField
                          data-cy="supplyPrice"
                          name="supplyPrice"
                          label="Supply price"
                        />
                      </Grid>

                      <Grid item={true} xs={6}>
                        <MoneyField
                          data-cy="maximumRetailPrice"
                          name="maximumRetailPrice"
                          label="Maximum Retail price"
                        />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <MoneyField
                          data-cy="retailPrice"
                          name="retailPrice"
                          label="Retail price"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
              <Box mb={3}>
                <Card>
                  <CardHeader title="Images" />
                  <Divider />
                  <CardContent>
                    <MultiImageUploadField accept="image/png, image/jpeg" />
                  </CardContent>
                </Card>
              </Box>
            </Grid>

            <Grid item={true} xs={12} lg={4}>
              <Box mb={3}>
                <Card>
                  <CardHeader title="Company" />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12}>
                        <CompanySearchField />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
              <Box mb={3}>
                <Card>
                  <CardHeader title="Tracking" />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12}>
                        <SkuField required={true} />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <BarcodeField required={true} />
                      </Grid>
                      <Grid item={true} xs={12}>
                        <Grid container={true} spacing={3}>
                          <Grid item={true} lg={6} xs={12}>
                            <PieceQuantityField
                              label="Quantity"
                              name="pieces"
                              data-cy="pieces"
                            />
                          </Grid>
                          <Grid item={true} lg={6} xs={12}>
                            <MethodOfMeasurementField />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
              <Box mb={3}>
                <Card>
                  <CardHeader title="Supply" />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12}>
                        <SupplierSearchField params={{ companyId }} />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
              <Box mb={3}>
                <Card>
                  <CardHeader title="Organization" />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12}>
                        <ProductSearchCreateField
                          data-cy="category"
                          name="category"
                          label="Category"
                          api={API.CATEGORY_LIST}
                          isLoading={categoryLoading}
                          onClick={onCategoryCreate}
                          ListboxProps={{ 'data-cy': 'categoryList' }}
                          params={{ companyId }}
                        />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <ProductSearchCreateField
                          data-cy="brand"
                          name="brand"
                          label="Brand"
                          addText="Add new brand"
                          api={API.BRAND_LIST}
                          isLoading={brandLoading}
                          onClick={onBrandCreate}
                          ListboxProps={{ 'data-cy': 'brandList' }}
                          params={{ companyId }}
                        />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <TagsField
                          data-cy="tags"
                          label="Tags"
                          name="tags"
                          placeholder="Tags"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
              <Box mb={3}>
                <Card>
                  <CardHeader title="Dimensions" />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={6}>
                        <DimensionField
                          data-cy="height"
                          name="height"
                          label="Height"
                        />
                      </Grid>

                      <Grid item={true} xs={6}>
                        <DimensionField
                          data-cy="width"
                          name="width"
                          label="Width"
                        />
                      </Grid>

                      <Grid item={true} xs={6}>
                        <DimensionField
                          data-cy="length"
                          name="length"
                          label="Length"
                        />
                      </Grid>

                      <Grid item={true} xs={6}>
                        <WeightField
                          data-cy="weight"
                          name="weight"
                          label="Weight"
                        />
                      </Grid>

                    </Grid>
                  </CardContent>
                </Card>
              </Box>
              <Box mb={3}>
                <Card>
                  <CardHeader title="Fulfillment" />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12}>
                        <FulfillmentStrategyField />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <ConstrainedField
                          name="minExpiryDays"
                          label="Min expiry days"
                          disabled={!trackExpiry}
                          minValue={0}
                          maxValue={3650}
                        />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <FormControl style={{ padding: '0 12px' }}>
                          <FormControlLabel
                            data-cy="trackSerialNumbers"
                            control={<SwitchField name="trackSerialNumbers" />}
                            label={t('Track Serial numbers')}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item={true} xs={12}>
                        <FormControl style={{ padding: '0 12px' }}>
                          <FormControlLabel
                            data-cy="trackButch"
                            control={<SwitchField name="trackBatchNumbers" />}
                            label={t('Track Batch numbers')}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item={true} xs={12}>
                        <FormControl style={{ padding: '0 12px' }}>
                          <FormControlLabel
                            data-cy="trackProductionDates"
                            control={<SwitchField name="trackProductionDates" />}
                            label={t('Track Production dates')}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item={true} xs={12}>
                        <ExpiryDateField strategyName="strategy" />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <FormControl style={{ padding: '0 12px' }}>
                          <FormControlLabel
                            data-cy="syncSupplyPrice"
                            control={<SwitchField name="syncSupplyPrice" />}
                            label={t('Sync Supply Price')}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
          <Box mb={3} />
        </Content>
      </Container>
    </form>
  )
}

ProductCreateForm.propTypes = {
  form: PropTypes.object,
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onCategoryCreate: PropTypes.func.isRequired,
  onBrandCreate: PropTypes.func.isRequired,
  brandLoading: PropTypes.bool.isRequired,
  categoryLoading: PropTypes.bool.isRequired
}

export default withForm(ProductCreateForm)
