import React from 'react'

import SelectField from '~/components/Fields/SelectField'

export const OPTIONS = [
  {
    value: 'web',
    name: 'Web'
  },
  {
    value: 'android',
    name: 'Android'
  },
  {
    value: 'ios',
    name: 'IOS'
  }
]

function DeviceSelectField () {
  return (
    <SelectField
      label="Device"
      name="device"
      options={OPTIONS}
    />
  )
}

export default DeviceSelectField
