import React, { useCallback, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import uuid from 'uuid/v1'
import { useDeepCompareEffect } from 'storfox-api-hooks'
import { all, equals, find, includes, isNil, pluck, prop, propEq } from 'ramda'
import { DndProvider } from 'react-dnd'
import TouchBackend from 'react-dnd-touch-backend'
import HTML5Backend from 'react-dnd-html5-backend'
import { useField } from 'react-final-form'
import { useFieldArray } from 'react-final-form-arrays'
import { isMobile } from 'storfox-tools'
import update from 'immutability-helper'
import { Box, styled } from '@mui/material'

import Button from '~/components/Buttons/Button'
import { emptyArray } from '~/constants/empty'

import ImageItem from '../Image'
import Dropzone from '../Dropzone'
import Drag from '../Drag'
import { useUpload } from '../../hooks'
import { cdnImageFilter } from '../../utils'

const GridBoxStyled = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(5,1fr)',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'repeat(4,1fr)',
  },
  gap: '.8rem',
  '& > div:first-of-type': {
    gridColumn: '1/span 2',
    gridRow: '1/span 2',
    '& > div': {
      height: 'calc(200px + 0.8rem)',
      [theme.breakpoints.down('md')]: {
        height: '100%'
      },
    }
  }

}))

const ActionsStyled = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  display: 'flex',
  justifyContent: 'flex-end',
  '& > * + *': {
    marginLeft: theme.spacing(2)
  }
}))

const toFieldValue = ({ id, imagePath }) => {
  return { id, imagePath, isDefault: false }
}

function ImageUpload ({ className, name, accept, ...props }) {
  const initialized = useRef(true)
  const [select, setSelect] = useState([])
  const { fields } = useFieldArray(name)
  const field = useField(name)
  const onChange = field.input.onChange
  const value = fields.value || emptyArray

  const onUpload = (data, onProgress) => {
    initialized.current = false
    return props.onUpload(data, onProgress)
      .then(data => {
        fields.push(data)
        return data
      })
  }

  const [handleUpload, files, setFiles] = useUpload({ onUpload, accept })

  useDeepCompareEffect(() => {
    if (initialized.current && value.length) {
      const newFiles = value.map(img => {
        const path = prop('imagePath', img)
        const item = find(propEq('imagePath', path), files)
        const src = cdnImageFilter(path)

        return { key: uuid(), obj: null, src, ...img, ...item }
      })

      setFiles(newFiles)
      initialized.current = false
    }
  }, [value])

  useDeepCompareEffect(() => {
    const itemsDefault = pluck('isDefault', value)
    const checkDefault = all(equals(false), itemsDefault)

    if (checkDefault && value.length) {
      const item = prop(0, value)
      fields.update(0, { ...item, isDefault: true })
    }
  }, [value])

  const handleRemove = () => {
    const newFiles = files.filter((item, index) => !includes(index, select))
    const newFieldValue = newFiles.map(toFieldValue)
    setSelect([])
    setFiles(newFiles)
    onChange(newFieldValue)
  }

  const handleSelect = index => {
    const selected = !isNil(select.find(item => item === index))

    if (selected) {
      const newSelect = select.filter(i => i !== index)
      setSelect(newSelect)
    } else {
      const newSelect = [...select, index]
      setSelect(newSelect)
    }
  }

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    const dragCard = files[dragIndex]
    const newState = update(files, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragCard],
      ]
    })

    const fieldValue = newState.map(toFieldValue)

    onChange(fieldValue)
    setFiles(newState)
  }, [files, onChange, setFiles])

  return (
    <Box className={className}>
      {files.length > 0 && (
        <ActionsStyled>
          <Button
            size="small"
            disabled={select.length === 0}
            onClick={handleRemove}
          >
            Delete selected
          </Button>
        </ActionsStyled>
      )}

      <GridBoxStyled>
        <DndProvider backend={isMobile() ? TouchBackend : HTML5Backend}>
          {files.map((item, index) => {
            return (
              <Drag
                key={item.key}
                id={item.key}
                index={index}
                moveCard={moveCard}
              >
                <div>
                  <ImageItem
                    file={item}
                    selected={!isNil(select.find(item => item === index))}
                    onSelect={() => handleSelect(index)}
                  />
                </div>
              </Drag>
            )
          })}
        </DndProvider>

        <Dropzone onDrop={handleUpload} accept={accept} />
      </GridBoxStyled>
    </Box>
  )
}

ImageUpload.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  onUpload: PropTypes.func.isRequired,
  accept: PropTypes.string
}

export default ImageUpload
