import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import TextOverflow from '~/components/TextOverflow'

function TextUnknown (props) {
  const {
    children,
    tooltip = 'Order contains line item(s) with variants not present in our system.',
    ...rest
  } = props

  const { t } = useTranslation()

  const text = children || t('Unknown')
  const ttp = children || t(tooltip)

  return (
    <TextOverflow sx={{ color: '#e53935' }} tooltip={ttp} {...rest}>
      {text}
    </TextOverflow>
  )
}

TextUnknown.propTypes = {
  children: PropTypes.any,
  condition: PropTypes.bool,
  tooltip: PropTypes.string
}

export default TextUnknown
