import React from 'react'
import { length, path, prop, propOr } from 'ramda'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import { FieldArray } from 'react-final-form-arrays'
import TableHead from '@mui/material/TableHead'
import { useTranslation } from 'react-i18next'

import Avatar from '~/components/Avatar'
import VerticalAlignment from '~/components/VerticalAlignment'
import TextOverflow from '~/components/TextOverflow'
import Subtext from '~/components/Subtext'
import PositiveNumberField from '~/components/Fields/PositiveNumberField'
import Money from '~/components/Money'
import EmptyRow from '~/components/EmptyRow'

function LineItems () {
  const { t } = useTranslation()

  return (
    <FieldArray name="lineItems" >
      {({ fields }) => {
        const info = !length(propOr([], 'value', fields))
        return (
          <>
            <TableContainer>
              <Table size="small" sx={{ minWidth: 700 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('Image')}</TableCell>
                    <TableCell sx={{ width: '350px' }}>{t('Product')}</TableCell>
                    <TableCell sx={{ width: '350px' }}>{t('Supplier SKU')}</TableCell>
                    <TableCell>{t('Unit cost')}</TableCell>
                    <TableCell sx={{ width: '150px' }}>{t('Qty')}</TableCell>
                    <TableCell>{t('Total')}</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fields.map((name, index) => {
                    const value = prop('value', fields)
                    const item = prop(index, value)
                    const productName = path(['productVariant', 'name'], item)
                    const sku = path(['productVariant', 'sku'], item)
                    const imagePath = path(['productVariant', 'defaultImage'], item)
                    const unitCost = propOr(0, 'price', item)
                    const supplierSku = propOr(0, 'supplierSku', item)
                    const quantity = propOr(0, 'quantity', item)
                    const totalPrice = quantity * unitCost

                    return (
                      <TableRow key={index}>
                        <TableCell>
                          <Avatar
                            alt={productName}
                            src={imagePath}
                          />
                        </TableCell>
                        <TableCell>
                          <VerticalAlignment
                            sx={{ minWidth: 200 }}
                            primary={(
                              <TextOverflow selfTooltip={true}>
                                {productName}
                              </TextOverflow>
                            )}
                            secondary={<Subtext lines={1}>{sku}</Subtext>}
                          />
                        </TableCell>
                        <TableCell>{supplierSku}</TableCell>
                        <TableCell>
                          <Money
                            name={`${name}.price`}
                            conditionFieldName={`${name}.condition`}
                            value={unitCost}
                          />
                        </TableCell>
                        <TableCell>
                          <PositiveNumberField
                            data-cy={`${name}.quantity`}
                            name={`${name}.quantity`}
                            inputProps={{ maxLength: 7 }}
                            sx={{ width: '150px' }}
                          />
                        </TableCell>
                        <TableCell>
                          <Money value={totalPrice} />
                        </TableCell>
                      </TableRow>
                    )
                  })
                    .reverse()}
                  {info && <EmptyRow colSpan={7} />}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )
      }}
    </FieldArray>
  )
}

export default LineItems
