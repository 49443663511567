import React from 'react'
import { SelectField } from 'storfox-query-builder'

export const OPTIONS = [
  {
    value: 'listing_variant',
    name: 'Variant'
  },
  {
    value: 'listing_bundle',
    name: 'Bundle'
  }
]

function StatusField (props) {
  return (
    <SelectField
      options={OPTIONS}
      controlSx={{
        width: 'auto',
        minWidth: 160
      }}
      {...props}
    />
  )
}

export default StatusField
