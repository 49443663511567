import React from 'react'
import Skeleton from '@mui/material/Skeleton'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'

function NotificationSkeleton () {
  return (
    <Box position="relative">
      <Skeleton variant="text" width={300} height={25} />
      <Skeleton variant="text" width={300} height={25} />
      <Divider sx={{ marginTop: '10px' }} />
    </Box>
  )
}

export default NotificationSkeleton
