import { pick, prop } from 'ramda'
import { DEFAULT_SEARCH_PARAMS } from 'storfox-route-hooks'

import { escapeAtob } from '~/utils'

export function StockAgingFilterSerializer (params) {
  const defaultParams = pick(DEFAULT_SEARCH_PARAMS, params)
  const warehouseGuids = escapeAtob(prop('warehouses', params))
  const companyGuids = escapeAtob(prop('company', params))
  const conditionIds = escapeAtob(prop('condition', params))
  const range1 = prop('range1', params)
  const range2 = prop('range2', params)
  const range3 = prop('range3', params)
  const range4 = prop('range4', params)
  const range5 = prop('range5', params)
  const range6 = prop('range6', params)

  return {
    ...defaultParams,
    warehouseGuids,
    companyGuids,
    conditionIds,
    range1,
    range2,
    range3,
    range4,
    range5,
    range6
  }
}
