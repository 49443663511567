import React from 'react'

import SelectField from '~/components/Fields/SelectField'

import { SHIP, PICK, PACK, UNFULFILLED } from '../Statuses/FulfillmentStatus'

export const OPTIONS = [
  {
    value: UNFULFILLED,
    name: 'Unfulfilled'
  },
  {
    value: PICK,
    name: 'Pick'
  },
  {
    value: PACK,
    name: 'Pack'
  },
  {
    value: SHIP,
    name: 'Ship'
  },
]

function FulfillmentStatusField (props) {
  return (
    <SelectField
      name="fulfillmentStatus"
      label="Fulfillment status"
      options={OPTIONS}
      {...props}
    />
  )
}

export default FulfillmentStatusField
