import React from 'react'
import { useSnackbar } from 'storfox-snackbar'
import { useNavigate, useParams } from 'react-router-dom'
import { prop } from 'ramda'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import useMessages from '~/hooks/useMessages'

import StockCountUpdate from '../components/StockCountUpdate/StockCountUpdate'
import { useStockCountCancel, useStockCountDetail, useStockCountProcess } from '../hooks'

function StockCountUpdateContainer () {
  const { guid } = useParams()
  const messages = useMessages()
  const snackbar = useSnackbar()
  const navigate = useNavigate()

  const stockCountDetail = useStockCountDetail(guid)
  const stockCountProcess = useStockCountProcess(guid)
  const stockCountCancel = useStockCountCancel(guid)

  const pageTitle = prop('number', stockCountDetail.detail)

  const handleProcess = () =>
    stockCountProcess.process()
      .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
      .then(() => navigate(ROUTES.STOCK_COUNT_LIST_PATH))

  const handleCancel = () =>
    stockCountCancel.cancel()
      .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
      .then(() => navigate(ROUTES.STOCK_COUNT_LIST_PATH))

  const isLoading = (
    stockCountDetail.isLoading ||
    stockCountProcess.isLoading ||
    stockCountCancel.isLoading
  )

  const breadcrumbs = { title: pageTitle }

  return (
    <DashboardLayout
      title={`Stock Count ${pageTitle}`}
      activeNav={NAV.STOCK_COUNT}
      breadcrumbs={breadcrumbs}
    >
      <StockCountUpdate
        initialValues={stockCountDetail.detail}
        isLoading={isLoading}
        detailLoading={stockCountDetail.isLoading}
        pageTitle={pageTitle}
        onSubmit={() => {}}
        onProcess={handleProcess}
        onCancel={handleCancel}
      />
    </DashboardLayout>
  )
}

export default StockCountUpdateContainer
