import { useContext } from 'react'

import Context from './context'

export const useTableHeight = () => {
  const { tableHeight, setTableHeight } = useContext(Context)

  return { tableHeight, setTableHeight }
}

export const useIsHeightSet = () => {
  const { isHeightSet } = useContext(Context)

  return isHeightSet
}
