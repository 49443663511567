import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import StorfoxTable from 'storfox-table'
import { useDeepCompareEffect } from 'storfox-api-hooks'
import CircularProgress from '@mui/material/CircularProgress'
import { useLocation } from 'react-router-dom'
import { IconButton, styled } from '@mui/material'
import Box from '@mui/material/Box'
import { useAllSearchParams } from 'storfox-route-hooks'
import RefreshIcon from '@mui/icons-material/Refresh'

import { useTableValues } from '~/components/TableValues/hooks'
import HtmlTooltip from '~/components/HtmlTooltip/HtmlTooltip'

export const getTableKeyWithQuery = (pathname, params) => {
  const queryKeys = Object.entries({ page: '1', search: '', limit: '10', ...params }).sort()
  const queryKeysString = queryKeys.map((qk) => qk.join('=')).join('&')
  return `${pathname}?${queryKeysString}`
}

const TableRoot = styled(Box)(({ theme }) => ({
  position: 'relative',
  '.MuiDataGrid-footerContainer': {
    borderTop: `1px solid ${theme.palette.background.tableBorder}`
  }
}))

const DataLoading = styled(Box)(({ theme }) => ({
  display: 'flex',
  zIndex: 5,
  alignItems: 'center',
  position: 'absolute',
  bottom: '16px',
  left: '9px',
  background: theme.palette.background.paper,
  'span': {
    marginLeft: '10px',
    color: theme.palette.text.primary,
  }
}))

const RefreshBoxStyled = styled(Box)({
  position: 'absolute',
  left: '10px',
  bottom: '10px',
  textAlign: 'center',
  zIndex: 5
})

function Table ({ columns, ...props }) {
  const {
    prefix = '',
    rowHeight = 48,
    pagination = true,
    getRowsFromResults,
    results,
    count,
    ordering,
    isLoading,
    args = {},
    onRefetch,
    setTableRef,
    isNewPagination,
    hasNextPage,
    hasPrevPage,
    ...rest
  } = props

  const location = useLocation()
  const { t } = useTranslation()
  const { handleTableValuesAdd, getTableValues, getTableValuesByName } = useTableValues()
  const params = useAllSearchParams()

  const tableKey = getTableKeyWithQuery(location.pathname, params)

  const translatedColumns = columns.map(column => ({
    ...column,
    headerName: t(column.headerName)
  }))

  useDeepCompareEffect(() => {
    if (!isLoading) {
      handleTableValuesAdd({ name: ordering.name, key: tableKey, results, count })
    }
  }, [isLoading])

  const oldTableValue = getTableValues(tableKey)
  const tableValueByName = getTableValuesByName(ordering.name)

  const resultWithOldValues = useMemo(() => {
    return isLoading ? oldTableValue.results : results
  }, [oldTableValue, isLoading, results])

  const countWithOldValues = useMemo(() => {
    return isLoading ? tableValueByName.count : count
  }, [count, isLoading, tableValueByName])

  return (
    <TableRoot>
      {isLoading && oldTableValue.count > 0 && (
        <DataLoading>
          <CircularProgress disableShrink={true} size={16} />
          <span>Loading...</span>
        </DataLoading>
      )}
      {!isLoading && onRefetch && (
        <RefreshBoxStyled>
          <HtmlTooltip title="Refresh rows.">
            <IconButton size="small" onClick={onRefetch}>
              <RefreshIcon />
            </IconButton>
          </HtmlTooltip>
        </RefreshBoxStyled>
      )}
      <StorfoxTable
        columns={translatedColumns}
        prefix={prefix}
        rowHeight={rowHeight}
        pagination={pagination}
        rows={getRowsFromResults(resultWithOldValues, args)}
        count={countWithOldValues}
        ordering={ordering}
        isLoading={isLoading && oldTableValue.count === 0}
        setTableRef={setTableRef}
        isNewPagination={isNewPagination}
        hasNextPage={hasNextPage}
        hasPrevPage={hasPrevPage}
        {...rest}
      />
    </TableRoot>
  )
}

Table.propTypes = {
  getRowsFromResults: PropTypes.func,
  results: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  args: PropTypes.object,
  columns: PropTypes.array.isRequired,
  pagination: PropTypes.bool,
  primaryKey: PropTypes.string,
  pinnedColumns: PropTypes.array,
  header: PropTypes.node,
  prefix: PropTypes.string,
  rowHeight: PropTypes.number,
  count: PropTypes.number,
  onRowClick: PropTypes.func,
  ordering: PropTypes.object,
  checkboxSelection: PropTypes.bool,
  onRefetch: PropTypes.func,
  height: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  selectComponent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.array
  ]),
  setTableRef: PropTypes.func,
  isNewPagination: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  hasPrevPage: PropTypes.bool
}

export default Table
