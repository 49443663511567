import React, { useCallback, useRef } from 'react'
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined'
import Badge, { badgeClasses } from '@mui/material/Badge'
import * as colors from '@mui/material/colors'
import IconButton from '@mui/material/IconButton'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/material'
import { isMobile } from 'storfox-tools'
import { useDeepCompareEffect } from 'storfox-api-hooks'
import PubSub from 'pubsub-js'

import NotificationPopup from '~/components/TopBar/Popups/NotificationPopup'
import { usePopup } from '~/components/TopBar/Popups'
import * as ROUTES from '~/constants/routes'
import { useNotificationCountUpdate } from '~/components/Notification/NotificationProvider'
import { useNotificationCount } from '~/components/Socket'

import { useNotificationPopupList } from './hooks'

const BadgeStyled = styled(Badge)({
  [`& .${badgeClasses.badge}`]: {
    backgroundColor: colors.orange[600]
  }
})

function Notification ({ className }) {
  const notificationPopup = usePopup()
  const { open } = notificationPopup

  const navigate = useNavigate()
  const subscriptionRef = useRef([])
  const { updateCount, topics } = useNotificationCountUpdate()

  const anchorRef = useRef(null)

  const { count } = useNotificationCount()

  const notificationList = useNotificationPopupList()

  const handleOpenPopup = useCallback(() => {
    notificationList.getList({ limit: 5 })
    notificationPopup.handleOpen()
  }, [notificationList, notificationPopup])

  useDeepCompareEffect(() => {
    const subscriptions = subscriptionRef.current

    topics.forEach((topic, index) => {
      subscriptionRef.current[index] = PubSub.subscribe(topic)

      return () => {
        subscriptions.forEach(subscription => {
          PubSub.unsubscribe(subscription)
        })
      }
    })
  }, [open])

  const handleClick = useCallback(() => {
    if (isMobile()) {
      navigate(ROUTES.NOTIFICATION_LIST_PATH)
    } else {
      handleOpenPopup()
    }
  }, [handleOpenPopup, navigate])

  const handleClosePopup = useCallback(() => {
    notificationPopup.handleClose()
    updateCount()
  }, [updateCount, notificationPopup])

  return (
    <>
      <IconButton ref={anchorRef} className={className} color="inherit" onClick={handleClick} size="large">
        <BadgeStyled
          badgeContent={count}
          variant="standard"
        >
          <NotificationsIcon />
        </BadgeStyled>
      </IconButton>
      <NotificationPopup
        onClose={handleClosePopup}
        open={notificationPopup.open}
        anchorEl={anchorRef.current}
        notifications={notificationList.results}
        isLoading={notificationList.isLoading && notificationList.results.length === 0}
      />
    </>
  )
}

Notification.propTypes = {
  className: PropTypes.string
}

export default Notification
