import React from 'react'
import PropTypes from 'prop-types'

import { useLoader } from './hooks'

function Loader ({ children }) {
  const isLoading = useLoader()

  return (
    <>
      {children(isLoading)}
    </>
  )
}

Loader.propTypes = {
  children: PropTypes.any.isRequired
}

export default Loader
