import React from 'react'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { CardHeader } from '~/components/Cards'
import TextField from '~/components/Fields/TextField'
import * as API from '~/constants/api-retailer'
import TagsField from '~/components/Fields/TagsField'
import MultiImageUploadField from '~/components/ImageUpload/MultiImageUpload'
import SearchField from '~/components/Fields/SearchField'

function ListingProductUpdate ({ productGuid }) {
  const { t } = useTranslation()
  return (
    <Grid>
      <Box mt={3} mb={3}>
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={12} lg={8}>
            <Box mb={3}>
              <Card>
                <CardHeader title="Details" />
                <Divider />
                <CardContent>
                  <Grid container={true} spacing={3}>
                    <Grid item={true} xs={12}>
                      <TextField
                        data-cy="name"
                        name="name"
                        label="Name"
                      />
                    </Grid>

                    <Grid item={true} xs={12}>
                      <TextField
                        data-cy="description"
                        name="description"
                        label="Description"
                        multiline={true}
                        minRows={4}
                      />
                    </Grid>

                  </Grid>
                </CardContent>
              </Card>
            </Box>
            <Box mb={3}>
              <Card>
                <CardHeader title="Image" />
                <Divider />
                <MultiImageUploadField name="images" />
                <CardContent />
              </Card>
            </Box>
          </Grid>
          <Grid item={true} xs={12} lg={4}>
            <Box mb={3}>
              <Card>
                <CardHeader title="Organization" />
                <Divider />
                <CardContent>
                  <Grid container={true} spacing={3}>
                    <Grid item={true} xs={12}>
                      <SearchField
                        data-cy="category"
                        name="category"
                        label="Category"
                        api={API.LISTING_CATEGORIES_LIST}
                        params={{ listingProductGuid: productGuid }}
                        ListboxProps={{ 'data-cy': 'categoryList' }}
                      />
                    </Grid>

                    <Grid item={true} xs={12}>
                      <SearchField
                        data-cy="brand"
                        name="brand"
                        label="Brand"
                        api={API.LISTING_BRANDS_LIST}
                        params={{ listingProductGuid: productGuid }}
                        ListboxProps={{ 'data-cy': 'brandList' }}
                      />
                    </Grid>

                    <Grid item={true} xs={12}>
                      <TagsField
                        data-cy="tags"
                        label="Tags"
                        name="tags"
                        placeholder={t('Tags')}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  )
}

ListingProductUpdate.propTypes = {
  productGuid: PropTypes.string.isRequired
}
export default ListingProductUpdate
