import picklist from './modules/picklist'
import packing from './modules/packing'
import shipment from './modules/shipment'

const FulfillmentRoutes = () => [
  ...picklist(),
  ...packing(),
  ...shipment()
]

export default FulfillmentRoutes
