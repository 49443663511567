import { sprintf } from 'sprintf-js'
import { useAllSearchParams, usePickSearchParams } from 'storfox-route-hooks'
import { useLimitedCountList, useCreate, useDelete, useDetail, useList, useUpdate } from 'storfox-api-hooks'

import * as API from '~/constants/api'
import { queryToParams } from '~/utils'

import { SupplierFilterSerializer } from '../serializers'

export const useSupplierList = () => {
  const searchParams = useAllSearchParams()
  const params = queryToParams(SupplierFilterSerializer, searchParams)

  return useLimitedCountList(API.SUPPLIER_LIST, params)
}

export const useSupplierCreate = () => {
  return useCreate(API.SUPPLIER_CREATE)
}

export const useSupplierDetail = guid => {
  const url = sprintf(API.SUPPLIER_DETAIL, guid)
  return useDetail(url)
}

export const useSupplierUpdate = guid => {
  const url = sprintf(API.SUPPLIER_UPDATE, guid)
  return useUpdate(url)
}

export const useSupplierPurchaseList = (supplierGuid, autoSend) => {
  const { page, limit } = usePickSearchParams(['page', 'limit'])

  return useList(API.PURCHASE_ORDERS_LIST, { supplierGuid, page, limit }, autoSend)
}

export const useSupplierHistory = (supplierId, autoSend) => {
  const url = sprintf(API.SUPPLIER_HISTORY, supplierId)
  const searchParams = useAllSearchParams()

  return useList(url, searchParams, autoSend)
}

export const useSuppliersDelete = () => {
  return useDelete(API.SUPPLIERS_DELETE)
}
