import React from 'react'
import { useParams } from 'react-router-dom'
import { useAllSearchParams } from 'storfox-route-hooks'
import { prop, propOr } from 'ramda'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import { VARIANT_DETAIL_TABS } from '~/constants/tabs'

import {
  useProductVariantList,
  useVariantDetail,
  useVariantHistory,
  useVariantStockList,
  useVariantSuppliersList,
  useVariantInventoryLogList
} from '../hooks'
import VariantDetail from '../components/VariantDetail/VariantDetail'

function VariantDetailContainer () {
  const { id, productId, tab } = useParams()
  const { isMultiVariant } = useAllSearchParams()

  const variantDetail = useVariantDetail(id)

  const guid = prop('guid', variantDetail.detail)
  const historyAutoSend = Boolean(guid && tab === VARIANT_DETAIL_TABS.HISTORY)
  const inventoryLogAutoSend = Boolean(guid && tab === VARIANT_DETAIL_TABS.INVENTORY_LOG)

  const variantHistory = useVariantHistory(guid, historyAutoSend)

  const variantList = useProductVariantList(productId)
  const variantStockList = useVariantStockList(id, tab === VARIANT_DETAIL_TABS.STOCK)
  const variantSuppliersList = useVariantSuppliersList(id, tab === VARIANT_DETAIL_TABS.SUPPLIERS)
  const variantInventoryLog = useVariantInventoryLogList(guid, inventoryLogAutoSend)

  const title = propOr('', 'name', variantDetail.detail)

  const breadcrumbs = { title }
  return (
    <DashboardLayout
      title="Variant detail"
      space={false}
      isLoading={variantDetail.isLoading}
      activeNav={NAV.VARIANTS}
      breadcrumbs={breadcrumbs}
    >
      <VariantDetail
        results={variantList.results}
        isMultiVariant={JSON.parse(isMultiVariant)}
        variantId={id}
        pageTitle={title}
        pageTitleLoading={variantDetail.isLoading}
        values={variantDetail.detail}
        stockList={variantStockList}
        variantHistory={variantHistory}
        supplierList={variantSuppliersList}
        isLoading={variantDetail.isLoading}
        id={id}
        productId={productId}
        inventoryLog={variantInventoryLog}
      />
    </DashboardLayout>
  )
}

export default VariantDetailContainer
