import React from 'react'

import AppContainer from '~/components/AppContainer'

import IntroductionGuide from '../components/IntroductionGuide'

function SignUpContainer () {
  return (
    <AppContainer title="Introduction guide">
      <IntroductionGuide />
    </AppContainer>
  )
}

export default SignUpContainer
