import React from 'react'
import PropTypes from 'prop-types'

import Button from '../Buttons/Button'

function PrimaryButton ({ children, ...props }) {
  return (
    <Button
      variant="contained"
      {...props}
    >
      {children}
    </Button>
  )
}

PrimaryButton.propTypes = {
  children: PropTypes.any.isRequired,
  onClick: PropTypes.func
}

export default PrimaryButton
