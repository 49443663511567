
import React from 'react'
import { useTranslation } from 'react-i18next'
import { prop } from 'ramda'

import { DashboardLayout } from '~/components/Layouts'
import { ACTIVE } from '~/components/Fields/StatusField'
import * as NAV from '~/constants/nav-titles'

import { useWooCommerceCreate } from '../../hooks'
import WooCommerceIntegrationCreateForm from '../../components/WooCommerce/WooCommerceIntegrationCreateForm'
import { WooCommerceCreateSerializer } from '../../serializers'

function WooCommerceIntegrationCreateContainer () {
  const { t } = useTranslation()

  const wooCommerceCreate = useWooCommerceCreate()

  const title = 'New Integration'

  const handleSubmit = formValues =>
    wooCommerceCreate.create(WooCommerceCreateSerializer(formValues))
      .then(({ result }) => {
        window.location = prop('url', result)
      })

  const isLoading = wooCommerceCreate.isLoading

  const initialValues = {
    status: ACTIVE
  }

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={t(title)}
      isLoading={isLoading}
      activeNav={NAV.INTEGRATION}
      breadcrumbs={breadcrumbs}
    >
      <WooCommerceIntegrationCreateForm
        onSubmit={handleSubmit}
        initialValues={initialValues}
        pageTitle={t(title)}
        parentTitle={t(NAV.SETTINGS)}
        isLoading={isLoading}
      />
    </DashboardLayout>
  )
}

export default WooCommerceIntegrationCreateContainer
