import React from 'react'
import { useFilter, useOrdering } from 'storfox-filter'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'
import { useAllSearchParams } from 'storfox-route-hooks'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import ErrorLink from '~/components/ErrorLink'
import useMessages from '~/hooks/useMessages'
import { queryToParams } from '~/utils'

import { useShipmentList, useShipmentsExport } from '../../hooks/Shipment'
import { ShipmentFilterSerializer } from '../../serializers/Shipment'
import ShipmentTable from '../../components/Shipment/ShipmentTable'
import ShipmentOrderingForm, { shipmentOrderingOptions } from '../../components/Shipment/ShipmentOrderingForm'
import ShipmentFilterForm, { shipmentFilterOptions } from '../../components/Shipment/ShipmentFilterForm'

function ShipmentListContainer () {
  const snackbar = useSnackbar()
  const messages = useMessages()
  const filterParams = useAllSearchParams()

  const shipmentList = useShipmentList()
  const shipmentExport = useShipmentsExport()

  const filter = useFilter(shipmentFilterOptions)
  const ordering = useOrdering(shipmentOrderingOptions)

  const handleShipmentsExport = () => {
    const filterValues = queryToParams(ShipmentFilterSerializer, filterParams)
    return shipmentExport.export(filterValues)
      .then(() => snackbar({ message: messages.NOTIFICATION_WAIT }))
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })
  }

  const breadcrumbs = { title: NAV.SHIPMENT_REPORT }

  return (
    <DashboardLayout
      title={NAV.SHIPMENT_REPORT}
      activeNav={NAV.REPORTS}
      breadcrumbs={breadcrumbs}
    >

      <ShipmentFilterForm {...filter} />
      <ShipmentOrderingForm {...ordering} />

      <ShipmentTable
        filter={filter}
        ordering={ordering}
        list={shipmentList}
        handleOpenFilter={filter.handleOpen}
        onShipmentsExport={handleShipmentsExport}
        onListRefetch={shipmentList.getListByParams}
      />
    </DashboardLayout>
  )
}

export default ShipmentListContainer
