import React from 'react'
import { Ordering } from 'storfox-filter'

import { ROLE_COLUMNS as tableColumns } from './RoleTable'

import { FILTER_NAME as name } from '../../constants'

export const roleOrderingOptions = {
  name,
  tableColumns
}

function RoleOrderingForm (props) {
  return (
    <Ordering {...props} />
  )
}

export default RoleOrderingForm
