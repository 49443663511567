import React from 'react'
import PropTypes from 'prop-types'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import { ClassNames } from '@emotion/react'

import { BootstrapInput } from './InputBase'

function ValueEditorField (props) {
  const {
    operator,
    value,
    handleOnChange,
    title,
    className,
    type,
    inputType,
    values,
    variant,
    ...rest
  } = props

  if (operator === 'null' || operator === 'notNull') {
    return null
  }

  const handleChange = e => handleOnChange(e.target.value)
  const handleCheckboxChange = e => handleOnChange(e.target.checked)
  switch (type) {
    case 'select':
      return (
        <FormControl className={className} variant={variant}>
          <InputLabel>{title}</InputLabel>
          <Select
            value={value}
            onChange={handleChange}
            input={<BootstrapInput />}
            {...rest}
          >
            {values.map((v) => (
              <MenuItem key={v.name} value={v.name}>
                {v.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )

    case 'checkbox':
      return (
        <Checkbox
          className={className}
          onChange={handleCheckboxChange}
          checked={Boolean(value)}
          {...rest}
        />
      )

    case 'radio':
      return (
        <FormControl className={className} title={title} component="fieldset" variant={variant}>
          <RadioGroup value={value} onChange={handleChange}>
            {values.map((v) => (
              <FormControlLabel
                key={v.name}
                value={v.name}
                label={v.label}
                control={<Radio {...rest} />}
              />
            ))}
          </RadioGroup>
        </FormControl>
      )

    default:
      return (
        <ClassNames>
          {({ css, theme }) => (
            <TextField
              label={title}
              className={className}
              type={inputType || 'text'}
              variant={variant}
              value={value}
              onChange={handleChange}
              InputProps={{ classes: { root: css({ background: theme.palette.background.paper }) } }}
              {...rest}
            />
          )}
        </ClassNames>
      )
  }
}

ValueEditorField.defaultProps = {
  variant: 'outlined',
  size: 'small'
}

ValueEditorField.propTypes = {
  value: PropTypes.any.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  inputType: PropTypes.string,
  className: PropTypes.string,
  operator: PropTypes.string,
  values: PropTypes.array
}

export default ValueEditorField
