import React from 'react'
import PropTypes from 'prop-types'

import Status from '~/components/Status'

import { COMPLETED, NEW, PROGRESS } from '../../constants'

const COLOR = {
  [NEW]: 'success',
  [PROGRESS]: 'warning',
  [COMPLETED]: 'success',
}

function ReceiveStatus ({ value }) {
  return (
    <Status label={value} color={COLOR[value]} />
  )
}

ReceiveStatus.propTypes = {
  value: PropTypes.oneOf([
    NEW,
    PROGRESS,
    COMPLETED,
  ])
}

export default ReceiveStatus
