import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { map, path, prop } from 'ramda'
import { generatePath } from 'react-router-dom'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'

import { NewButton } from '~/components/Buttons'
import PageTitle from '~/components/PageTitle'
import TableNotes from '~/components/TableNotes'
import Money from '~/components/Money'
import TableDateFormat from '~/components/TableDateFormat'
import Container, { Content, Header } from '~/components/Container'
import { Table, TableHeader } from '~/components/Table'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import TableLink from '~/components/Link'
import { ADJUSTMENT_DETAIL_TABS } from '~/constants/tabs'

import BulkActionButton from './BulkActionButton'
import AdjustmentReasonStatus from './AdjustmentStatus'
import More from './More'

import { ADJUSTMENT_STATUS } from '../../constants'

const getDetailPath = id => {
  return generatePath(ROUTES.ADJUSTMENT_DETAIL_PATH, { id, tab: ADJUSTMENT_DETAIL_TABS.GENERAL })
}

export const ADJUSTMENT_COLUMNS = [
  {
    width: 100,
    headerName: 'SA #',
    field: 'number',
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        {value}
      </TableLink>
    )
  },
  {
    width: 150,
    headerName: 'Line items',
    field: 'lineItems',
    alignHeader: 'center',
    align: 'center'
  },
  {
    width: 150,
    headerName: 'Qty change',
    field: 'totalQuantity',
    alignHeader: 'center',
    align: 'center'
  },
  {
    width: 150,
    headerName: 'Total',
    field: 'totalPrice',
    renderCell: ({ value }) => (
      <Money value={value} />
    )
  },
  {
    width: 150,
    headerName: 'Reason',
    field: 'reason'
  },
  {
    width: 150,
    headerName: 'Reference ID',
    field: 'referenceId'
  },
  {
    width: 150,
    headerName: 'Status',
    field: 'status',
    renderCell: ({ value }) => (
      <AdjustmentReasonStatus value={value} />
    )
  },
  {
    width: 150,
    headerName: 'Last updated',
    field: 'updatedAt',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  },
  {
    width: 125,
    headerName: 'Notes',
    field: 'notes',
    renderCell: ({ value }) => (
      <TableNotes>
        {value}
      </TableNotes>
    )
  }
]

const getRowsFromResults = map(item => {
  const id = prop('id', item)
  const guid = prop('guid', item)
  const number = prop('number', item)
  const lineItems = prop('lineItemsCount', item)
  const totalQuantity = prop('totalQuantity', item)
  const totalPrice = prop('totalPrice', item)
  const reason = path(['reason', 'name'], item)
  const status = prop('status', item)

  const referenceId = prop('referenceId', item)
  const updatedAt = prop('updatedAt', item)
  const notes = prop('notes', item)
  const link = getDetailPath(id)

  return {
    id,
    guid,
    number,
    lineItems,
    totalQuantity,
    totalPrice,
    reason,
    status,
    updatedAt,
    referenceId,
    notes,
    link
  }
})

function AdjustmentTable (props) {
  const {
    list,
    filter,
    ordering,
    onListRefetch,
    onAdjustmentsDelete,
    onGenerateReport,
    onCancel,
    bulkActionLoading
  } = props

  const [tableRef, setTableRef] = useState(null)
  const isLoading = prop('isLoading', list)
  const results = prop('results', list)
  const count = prop('count', list)
  const columns = prop('filteredColumns', ordering)

  const actionColumns = useMemo(() => [
    ...columns,
    {
      width: 80,
      resizable: false,
      headerName: 'Actions',
      field: 'actions',
      type: 'actions',
      getActions: ({ row }) => {
        const status = prop('status', row)

        const disabled = status === ADJUSTMENT_STATUS.CANCELLED || status === ADJUSTMENT_STATUS.COMPLETED

        return [
          <GridActionsCellItem
            label="Cancel"
            disabled={disabled}
            onClick={() => onCancel(row.id)}
            showInMenu={true}
          />,
          <GridActionsCellItem
            label="Print"
            onClick={() => onGenerateReport(row.id)}
            showInMenu={true}
          />
        ]
      }
    }
  ], [columns, onGenerateReport, onCancel])

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={NAV.ADJUSTMENT}
          parentTitle={NAV.STOCK}
          rightButton={(
            <>
              <BulkActionButton onAdjustmentsDelete={onAdjustmentsDelete} loading={bulkActionLoading} />
              <NewButton path={ROUTES.ADJUSTMENT_CREATE_PATH} />
            </>
          )}
        />
      </Header>

      <Content>
        <TableHeader
          filter={filter}
          filterOpen={filter.handleOpen}
          orderingOpen={ordering.handleOpen}
          ordering={ordering}
        >
          <More tableRef={tableRef} />
        </TableHeader>
        <Table
          isLoading={isLoading}
          columns={actionColumns}
          getRowsFromResults={getRowsFromResults}
          results={results}
          pinnedColumns={['number']}
          primaryKey="guid"
          count={count}
          checkboxSelection={true}
          ordering={ordering}
          onRefetch={onListRefetch}
          setTableRef={setTableRef}
        />
      </Content>
    </Container>
  )
}

AdjustmentTable.propTypes = {
  list: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  onAdjustmentsDelete: PropTypes.func.isRequired,
  onListRefetch: PropTypes.func.isRequired,
  onGenerateReport: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  bulkActionLoading: PropTypes.bool
}

export default AdjustmentTable
