import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card/Card'
import { Divider } from '@mui/material'
import CardContent from '@mui/material/CardContent'
import { useTranslation } from 'react-i18next'
import { PasswordField } from 'storfox-form-fields'

import { CardHeader } from '~/components/Cards'
import TextField from '~/components/Fields/TextField'
import StatusField from '~/components/Fields/StatusField'

import ChargesModeField from './ChargesModeField'
import DeliveryTypeField from './DeliveryTypeField'
import InformationDisplayField from './InformationDisplayField'
import ShippingModeField from './ShippingModeField'
import SameDayTimingField from './SameDayTimingField'

function TraxIntegrationUpdateFormGeneral () {
  const { t } = useTranslation()
  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12} lg={8}>
        <Box mb={3}>
          <Card>
            <CardHeader title={t('Integration')} />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12} lg={4}>
                  <TextField
                    data-cy="name"
                    name="name"
                    label="Name"
                  />
                </Grid>
                <Grid item={true} xs={12} lg={4}>
                  <PasswordField
                    name="credential.apiKey"
                    label="API key"
                    iconSize="small"
                  />
                </Grid>
                <Grid item={true} xs={12} lg={4}>
                  <StatusField />
                </Grid>
                <Grid item={true} xs={12}>
                  <TextField
                    data-cy="description"
                    name="description"
                    label="Description"
                    multiline={true}
                    rows="4"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>
      <Grid item={true} xs={12} lg={4}>
        <Box mb={3}>
          <Card>
            <CardHeader title={t('Configuration')} />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} lg={12}>
                  <ChargesModeField />
                </Grid>
                <Grid item={true} lg={12}>
                  <DeliveryTypeField />
                </Grid>
                <Grid item={true} lg={12}>
                  <InformationDisplayField />
                </Grid>
                <Grid item={true} lg={12}>
                  <ShippingModeField />
                </Grid>
                <Grid item={true} lg={12}>
                  <SameDayTimingField />
                </Grid>
                <Grid item={true} xs={12}>
                  <TextField
                    name="configuration.pickupAddressId"
                    label="Pickup address id"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </Grid>
  )
}

export default TraxIntegrationUpdateFormGeneral
