import React from 'react'
import PropTypes from 'prop-types'
import { prop } from 'ramda'

import { DiscardButton, SaveButton } from '~/components/Buttons'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import { withForm } from '~/components/Form'
import * as NAV from '~/constants/nav-titles'
import { SHIPMENT_STATUS } from '~/components/Statuses/ShipmentStatus'

import ShipmentGeneral from './ShipmentGeneral'

export const SHIPMENT_UPDATE_FIELDS = [
  'id',
  'service',
  'vehicleNumber',
  'shippingMethod',
  'fromAddress',
  'trackingNumber',
  'shippingPrice',
]

function ShipmentUpdateForm (props) {
  const {
    form,
    title,
    isLoading,
    pageTitleLoading
  } = props

  const { initialValues, handleSubmit } = form

  const status = prop('status', initialValues)

  const shippingAddress = prop('shippingAddress', initialValues)

  const disabled = (
    isLoading ||
    status === SHIPMENT_STATUS.CANCELLED ||
    status === SHIPMENT_STATUS.FAILED ||
    status === SHIPMENT_STATUS.RETURNED ||
    status === SHIPMENT_STATUS.DELIVERED
  )

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Container>
          <Header>
            <PageTitle
              pageTitle={title}
              parentTitle={NAV.SHIPMENTS}
              pageTitleLoading={pageTitleLoading}
              rightButton={(
                <>
                  <DiscardButton disabled={isLoading} />
                  <SaveButton disabled={disabled} />
                </>
              )}
            />
          </Header>

          <Content>
            <ShipmentGeneral shippingAddress={shippingAddress} />
          </Content>
        </Container>
      </form>
    </>
  )
}

ShipmentUpdateForm.propTypes = {
  form: PropTypes.object,
  title: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  pageTitleLoading: PropTypes.bool
}

export default withForm(ShipmentUpdateForm)
