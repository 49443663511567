import React from 'react'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import PropTypes from 'prop-types'
import { find, propEq, propOr } from 'ramda'

function SplitButton ({ options, name }) {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)

  const splitButtons = JSON.parse(localStorage.getItem('splitButtons')) || {}
  const initialSelected = propOr(options[0].label, name, splitButtons)
  const [selected, setSelected] = React.useState(initialSelected)

  const handleMenuItemClick = (event, option) => {
    setSelected(option.label)
    const newSplitButtons = {
      ...splitButtons,
      [name]: option.label
    }
    localStorage.setItem('splitButtons', JSON.stringify(newSplitButtons))
    setOpen(false)
    option.onMenuClick()
  }

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  const selectedButton = find(propEq('label', selected))(options) || options[0]

  return (
    <Grid alignItems="center">
      <Grid item={true} xs={12}>
        <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
          {selectedButton.button}
          <Button
            size="small"
            aria-haspopup="menu"
            onClick={handleToggle}
            style={{ padding: 0 }}
            data-cy="split-button-menu"
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper open={open} anchorEl={anchorRef.current} transition={true}>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {options.map(option => (
                      <MenuItem
                        key={option.label}
                        selected={option.label === selected}
                        onClick={event => handleMenuItemClick(event, option)}
                        data-cy={option['data-cy']}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  )
}

SplitButton.propTypes = {
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired
}

export default SplitButton
