import React from 'react'
import InputAdornment from '@mui/material/InputAdornment'

import PositiveNumberField from './PositiveNumberField'

function MonthsField (props) {
  return (
    <PositiveNumberField
      {...props}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">months</InputAdornment>
        )
      }}
    />
  )
}

export default MonthsField
