import palette from '~/theme/light/palette'

export default {
  styleOverrides: {
    root: {},
    row: {
      color: '#eee',
      hover: palette.hover.color,
      checked: `${palette.secondary.main}14`,
      hoverSecond: 'rgba(248, 140, 42, 0.05)'
    },
    header: {
      backgroundColor: `${palette.secondary.main}14`
    },
    shadow: {
      backgroundColor: '#e0e0e0'
    }
  }
}
