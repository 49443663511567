import { sprintf } from 'sprintf-js'
import { useAllSearchParams } from 'storfox-route-hooks'
import { useCreate, useDetail, useList, useUpdate, useLimitedCountList } from 'storfox-api-hooks'

import { queryToParams } from '~/utils'
import * as API from '~/constants/api'

import { StockCountFilterSerializer } from '../serializers'

export const useStockCountList = () => {
  const searchParams = useAllSearchParams()
  const params = queryToParams(StockCountFilterSerializer, searchParams, 'guid')

  return useLimitedCountList(API.STOCK_COUNT_LIST, params)
}

export const useStockCountCreate = () => {
  return useCreate(API.STOCK_COUNT_CREATE)
}

export const useStockCountCreateAndProcess = () => {
  return useCreate(API.STOCK_COUNT_CREATE_AND_PROCESS)
}

export const useStockCountDetail = guid => {
  const url = sprintf(API.STOCK_COUNT_DETAIL, guid)

  return useDetail(url)
}

export const useStockCountCancel = guid => {
  const url = sprintf(API.STOCK_COUNT_CANCEL, guid)
  const { update, ...state } = useUpdate(url)

  return { ...state, cancel: update }
}

export const useStockCountProcess = guid => {
  const url = sprintf(API.STOCK_COUNT_PROCESS, guid)
  const { update, ...state } = useUpdate(url)

  return { ...state, process: update }
}

export const useStockCountLocationList = () => {
  return useList(API.STOCK_COUNT_LOCATION_LIST, {}, false)
}
