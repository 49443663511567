import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import Card from '@mui/material/Card'
import { path, prop } from 'ramda'

import TextLabelLoader from '~/components/TextLabelLoader'
import CardHeader from '~/components/Cards/CardHeader'

function WarehouseContent ({ warehouse }) {
  const name = prop('name', warehouse)
  const label = prop('label', warehouse)
  const phone = prop('phoneNumber', warehouse)
  const address = prop('address', warehouse)
  const country = path(['country', 'name'], warehouse)
  const city = prop('city', warehouse)
  const zipcode = prop('zipcode', warehouse)
  const latitude = prop('latitude', warehouse)
  const longitude = prop('longitude', warehouse)
  const firstName = prop('contactFirstName', warehouse)
  const lastName = prop('contactLastName', warehouse)
  const email = prop('contactEmail', warehouse)
  const region = prop('region', warehouse)

  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} lg={6} xs={12}>
        <Box mb={3}>
          <Card>
            <CardHeader title="Information" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Warehouse name">{name}</TextLabelLoader>
                </Grid>
                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Label">{label}</TextLabelLoader>
                </Grid>
                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Phone number">{phone}</TextLabelLoader>
                </Grid>
                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Address">{address}</TextLabelLoader>
                </Grid>
                <Grid item={true} xs={12} lg={6}>
                  <TextLabelLoader label="Country">{country}</TextLabelLoader>
                </Grid>
                <Grid item={true} xs={12} lg={6}>
                  <TextLabelLoader label="City">{city}</TextLabelLoader>
                </Grid>
                <Grid item={true} xs={6}>
                  <TextLabelLoader label="Zipcode">{zipcode}</TextLabelLoader>
                </Grid>
                <Grid item={true} xs={6}>
                  <TextLabelLoader label="Region">{region}</TextLabelLoader>
                </Grid>
                <Grid item={true} xs={6}>
                  <TextLabelLoader label="Longitude">{longitude}</TextLabelLoader>
                </Grid>
                <Grid item={true} xs={6}>
                  <TextLabelLoader label="Latitude">{latitude}</TextLabelLoader>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>

      <Grid item={true} lg={6} xs={12}>
        <Card>
          <CardHeader title="Primary contact" />
          <Divider />
          <CardContent>
            <Grid container={true} spacing={3}>
              <Grid item={true} xs={12} lg={6}>
                <TextLabelLoader label="First name">{firstName}</TextLabelLoader>
              </Grid>
              <Grid item={true} xs={12} lg={6}>
                <TextLabelLoader label="Last name">{lastName}</TextLabelLoader>
              </Grid>
              <Grid item={true} xs={12}>
                <TextLabelLoader label="Email">{email}</TextLabelLoader>
              </Grid>
              <Grid item={true} xs={12}>
                <TextLabelLoader label="Phone number">{phone}</TextLabelLoader>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

WarehouseContent.propTypes = {
  warehouse: PropTypes.object.isRequired
}

export default WarehouseContent
