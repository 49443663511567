import { DateTime } from 'luxon'
import { prop } from 'ramda'

export const DATE_FORMAT_ISO_8601 = 'yyyy-MM-dd'
export const DATE_TABLE = 'MMM d, yyyy'
export const TIME_FORMAT = 'HH:mm'
export const TABLE_DATETIME_FORMAT = 'MMM d, yyyy HH:mm:ss'
export const DATE_FORMAT_ISO_8601_TIME = "yyyy-MM-dd'T'HH:mm:ss"
export const DEFAULT_FORMAT = 'dd.MM.yyyy HH:mm'

// DATE
export function tableDateFormat (date) {
  return DateTime.fromISO(resetTimeZone(date)).toFormat(DATE_TABLE)
}

export const getFormattedDate = (date, dateFormat = DATE_FORMAT_ISO_8601) => {
  const dateTimeFromISO = DateTime.fromISO(date)
  const dateTimeFromSQL = DateTime.fromSQL(date)

  if (date && dateTimeFromISO.isValid) {
    return dateTimeFromISO.toFormat(dateFormat)
  }

  if (date && dateTimeFromSQL.isValid) {
    return dateTimeFromSQL.toFormat(dateFormat)
  }

  return null
}

export const isSameDate = (date1, date2) => {
  if (!date1 && !date2) {
    return true
  }
  if ((date1 && !date2) || (!date1 && date2)) {
    return false
  }

  const date1Str = getDateTimeMidnight(getDateTimeResetZone(date1))
  const date2Str = getDateTimeMidnight(getDateTimeResetZone(date2))

  return date1Str === date2Str
}

export const isSameDateRange = (dateRange1, dateRange2) => {
  const startDateRange1 = prop(0, dateRange1)
  const endDateRange1 = prop(1, dateRange1)

  const startDateRange2 = prop(0, dateRange2)
  const endDateRange2 = prop(1, dateRange2)

  return isSameDate(startDateRange1, startDateRange2) && isSameDate(endDateRange1, endDateRange2)
}

// DATETIME
export function tableDateTimeFormat (datetime) {
  return DateTime.fromISO(resetTimeZone(datetime)).toFormat(TABLE_DATETIME_FORMAT)
}

export const getFormattedDateTime = (date, dateFormat = DATE_FORMAT_ISO_8601_TIME) => {
  const dateTimeFromISO = DateTime.fromISO(date)
  const dateTimeFromSQL = DateTime.fromSQL(date)

  if (date && dateTimeFromISO.isValid) {
    return dateTimeFromISO.toFormat(dateFormat)
  }

  if (date && dateTimeFromSQL.isValid) {
    return dateTimeFromSQL.toFormat(dateFormat)
  }

  return null
}

export const getDateTimeResetZone = (date, dateTimeFormat = DATE_FORMAT_ISO_8601_TIME) => {
  const dateTimeFromISO = DateTime.fromISO(date)
  const dateTimeFromSQL = DateTime.fromSQL(date)

  if (date && dateTimeFromISO.isValid) {
    return dateTimeFromISO.setZone('UTC+0').toFormat(dateTimeFormat)
  }

  if (date && dateTimeFromSQL.isValid) {
    return dateTimeFromSQL.setZone('UTC+0').toFormat(dateTimeFormat)
  }

  return null
}

export const getDateTimeMidnight = (date, dateFormat = DATE_FORMAT_ISO_8601_TIME) => {
  const dateTimeFromISO = DateTime.fromISO(date)
  const dateTimeFromSQL = DateTime.fromSQL(date)

  if (date && dateTimeFromISO.isValid) {
    return dateTimeFromISO.startOf('day').toFormat(dateFormat)
  }

  if (date && dateTimeFromSQL.isValid) {
    return dateTimeFromSQL.startOf('day').toFormat(dateFormat)
  }

  return null
}

export const getDateTimeMidnightResetZone = (date, dateFormat = DATE_FORMAT_ISO_8601_TIME) => {
  return getDateTimeMidnight(getDateTimeResetZone(date, dateFormat), dateFormat)
}

export const getDateTimeGMTDifference = (date) => {
  if (date) {
    const userOffset = DateTime.now().o
    const userHourDiff = Math.round(userOffset / 60)
    const userOffsetString = userOffset.toString()
    const isMinusOffset = userOffsetString.includes('-')
    if (isMinusOffset) {
      const newDate = new Date(date)
      newDate.setHours(newDate.getHours() + Math.abs(userHourDiff))
      return DateTime.fromJSDate(newDate).toFormat(DATE_FORMAT_ISO_8601_TIME)
    } else {
      const newDate = new Date(date)
      newDate.setHours(newDate.getHours() - userHourDiff)
      return DateTime.fromJSDate(newDate).toFormat(DATE_FORMAT_ISO_8601_TIME)
    }
  } return null
}

export const getDateTimeGMTUser = (date) => {
  if (date) {
    const userOffset = DateTime.now().o
    const userHourDiff = Math.round(userOffset / 60)
    const userOffsetString = userOffset.toString()
    const isMinusOffset = userOffsetString.includes('-')
    if (isMinusOffset) {
      const newDate = new Date(date)
      newDate.setHours(newDate.getHours() - Math.abs(userHourDiff))
      return DateTime.fromJSDate(newDate)
    } else {
      const newDate = new Date(date)
      newDate.setHours(newDate.getHours() + userHourDiff)
      return DateTime.fromJSDate(newDate)
    }
  } return null
}

export const getSeparatedDateRange = (dateRange, options = { isMidnight: true }) => {
  if (!dateRange) {
    return [null, null]
  }

  if (dateRange.length < 2) {
    return [null, null]
  }

  const startDate = dateRange[0]
  const endDate = dateRange[1]

  if (options.isMidnight) {
    return [getDateTimeMidnight(startDate), getDateTimeMidnight(endDate)]
  }

  return [getFormattedDateTime(startDate), getFormattedDateTime(endDate)]
}

// TIME
export const getFormattedTime = (time, timeFormat = TIME_FORMAT) => {
  const dateTimeFromISO = DateTime.fromISO(time)
  const dateTimeFromSQL = DateTime.fromSQL(time)

  if (time && dateTimeFromISO.isValid) {
    return dateTimeFromISO.toFormat(timeFormat)
  }

  if (time && dateTimeFromSQL.isValid) {
    return dateTimeFromSQL.toFormat(timeFormat)
  }

  return null
}

export const getTimeFromString = (time, timeFormat = TIME_FORMAT) => {
  if (time) {
    return DateTime.fromISO(time).toFormat(timeFormat)
  }

  return undefined
}

export function resetTimeZone (datetime) {
  return `${datetime}+0000`
}
