import { sprintf } from 'sprintf-js'
import { useCreate, useDelete, useDetail, useList } from 'storfox-api-hooks'

import * as API from '~/constants/api'

export const useContainerDetail = guid => {
  const url = sprintf(API.CONTAINER_DETAIL, guid)
  return useDetail(url)
}

export const useContainerCreate = () => {
  return useCreate(API.CONTAINER_BULK_CREATE)
}

export const useContainerDelete = guid => {
  const url = sprintf(API.CONTAINER_DELETE, guid)

  return useDelete(url)
}

export const useContainerContent = guid => {
  const url = sprintf(API.CONTAINER_CONTENT, guid)

  return useList(url)
}

export const useWarehouseDetail = guid => {
  const url = sprintf(API.WAREHOUSE_DETAIL, guid)
  return useDetail(url)
}
