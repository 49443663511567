import React from 'react'
import { Card, Divider } from '@mui/material'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import PropTypes from 'prop-types'

import { CardHeader } from '~/components/Cards'

import DeliveryAddressInfo from './DeliveryAddressInfo'
import BillingAddressInfo from './BillingAddressInfo'

function CustomerAddresses ({ billingAddress, deliveryAddress }) {
  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12} lg={6}>
        <Card>
          <CardHeader title="Delivery address" />
          <Divider />
          <CardContent>
            <DeliveryAddressInfo address={deliveryAddress} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item={true} xs={12} lg={6}>
        <Card style={{ height: '100%' }}>
          <CardHeader title="Billing address" />
          <Divider />
          <CardContent>
            <BillingAddressInfo address={billingAddress} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

CustomerAddresses.propTypes = {
  billingAddress: PropTypes.object.isRequired,
  deliveryAddress: PropTypes.object.isRequired
}

export default CustomerAddresses
