import React from 'react'
import Card from '@mui/material/Card/Card'
import { Divider, styled } from '@mui/material'
import CardContent from '@mui/material/CardContent'
import PropTypes from 'prop-types'
import { prop } from 'ramda'
import Box from '@mui/material/Box'

import { CardHeader } from '~/components/Cards'
import OrderProgress from '~/components/OrderProgress'
import TableDateFormat from '~/components/TableDateFormat/TableDateFormat'
import TextLabelLoader from '~/components/TextLabelLoader'

import DetailLineItems from './DetailLineItems'

import ReceiveStatus from '../ReceiveList/ReceiveStatus'

const StatusesBoxStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      textAlign: 'center',
      marginBottom: theme.spacing(3)
    },
    '& > *:last-child': {
      marginBottom: 0
    }
  },
  '& p': {
    minWidth: '150px'
  }
}))

function PurchaseReceiveGeneral ({ isLoading, values, getVariantDetail }) {
  const lineItems = prop('lineItems', values)
  const receiveStatus = prop('receiptStatus', values)
  const ordered = prop('ordered', values)
  const received = prop('received', values)

  return (
    <>
      <Box mb={3}>
        <Card>
          <CardHeader title="Receiving information" />
          <Divider />
          <CardContent>
            <StatusesBoxStyled>
              <TextLabelLoader label="Status">
                <ReceiveStatus value={receiveStatus} />
              </TextLabelLoader>
              <TextLabelLoader label="Received/Expected">
                <OrderProgress ordered={ordered} received={received} />
              </TextLabelLoader>
              <TextLabelLoader label="Expected date">
                <TableDateFormat date={prop('expectedDate', values)} />
              </TextLabelLoader>
              <TextLabelLoader label="Last update">
                <TableDateFormat date={prop('updatedAt', values)} withTime={true} />
              </TextLabelLoader>
              <Box />
            </StatusesBoxStyled>
          </CardContent>
        </Card>
      </Box>

      <DetailLineItems
        isLoading={isLoading}
        lineItems={lineItems}
        getVariantDetail={getVariantDetail}
      />
    </>
  )
}

PurchaseReceiveGeneral.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  getVariantDetail: PropTypes.func.isRequired
}

export default PurchaseReceiveGeneral
