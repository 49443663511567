import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'
import { useTheme } from '@mui/material'

const d = 'M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 ' +
  '4.477 10 10s-4.477 10-10 10zm-3.11-8.83l.013-.007l.87 ' +
  '2.87c.112.311.266.367.453.341c.188-.025.287-.126.41-.244l1.188-1.148l2.55 ' +
  '1.888c.466.257.801.124.917-.432l1.657-7.822c.183-.728-.137-1.02-.702' +
  '-.788l-9.733 3.76c-.664.266-.66.638-.12.803l2.497.78z'

function Telegram (props) {
  const theme = useTheme()

  return (
    <SvgIcon style={{ color: theme.palette.icon.primary }} {...props}>
      <path d={d} />
    </SvgIcon>
  )
}

export default Telegram
