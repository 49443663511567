import React from 'react'
import { prop } from 'ramda'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import PropTypes from 'prop-types'

import { getFullName } from '~/utils'
import Avatar from '~/components/Avatar'
import TextLabelLoader from '~/components/TextLabelLoader'

function CustomerInfoRow ({ customer, link }) {
  const avatar = prop('imagePath', customer)
  const fullName = getFullName(customer)
  const email = prop('email', customer)
  const phone = prop('mobile', customer)

  return (
    <Box display="flex" justifyContent="space-between">
      <Box display="flex">
        <Avatar src={avatar} alt={fullName} />
        <Box ml={1}>
          <TextLabelLoader label="Name">
            {link && (
              <Link
                color="secondary"
                target="_blank"
                rel="noreferrer noopener"
                href={link}
              >
                {fullName}
              </Link>
            )}
          </TextLabelLoader>
        </Box>
      </Box>
      <TextLabelLoader label="Email">
        {email}
      </TextLabelLoader>
      <TextLabelLoader label="Phone number">
        {phone}
      </TextLabelLoader>
    </Box>
  )
}

CustomerInfoRow.propTypes = {
  customer: PropTypes.object.isRequired,
  link: PropTypes.string
}

export default CustomerInfoRow
