import React, { useEffect } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { toCamelCase, useDeepCompareEffect } from 'storfox-api-hooks'

import { escapeAtob } from '~/utils'
import { useCompany, useMe, useProfile } from '~/components/Profile'
import { useToken } from '~/components/Token'
import AppContainer from '~/components/AppContainer'
import * as ROUTES from '~/constants/routes'
import { RETAILER } from '~/constants/companyTypes'
import { OVERVIEW_TABS } from '~/constants/tabs'

import SignUpContractForm from '../components/SignUp/SignUpContractForm'
import { SignUpContractSerializer } from '../serializers'
import { useSignUp } from '../hooks'

const toObject = (data) => {
  try {
    return toCamelCase(escapeAtob(data))
  } catch (e) {}
}

function SignUpContractContainer () {
  const navigate = useNavigate()
  const { signUp, isLoading } = useSignUp()
  const { getMe } = useMe()
  const { token, setToken } = useToken()
  const { setProfile } = useProfile()
  const { contract } = useParams()

  const contractInfo = toObject(contract)

  const { isThreePl } = useCompany()

  const tab = isThreePl ? OVERVIEW_TABS.FULFILLMENT : OVERVIEW_TABS.SALES
  const redirectTo = generatePath(ROUTES.OVERVIEW_PATH, { tab })

  useEffect(() => {
    if (!contractInfo) {
      navigate(ROUTES.SIGN_UP, { replace: true })
    }
  }, [contractInfo, navigate])

  useDeepCompareEffect(() => {
    if (token) {
      navigate(redirectTo)
    }
  }, [])

  const handleSubmit = formValues =>
    signUp(SignUpContractSerializer(formValues))
      .then(({ result }) => setToken(result.token))
      .then(() => getMe())
      .then(({ result }) => setProfile(result))
      .then(() => navigate(ROUTES.CONTRACT_LIST_PATH, { replace: true }))
      .catch(err => {
        const path = generatePath(ROUTES.SIGN_UP_CONTRACT_NUMBER, { contract })
        navigate(path, { replace: true })
        throw err
      })

  return (
    <AppContainer title="Sign Up">
      <SignUpContractForm
        initialValues={{ type: RETAILER, contractInfo }}
        isLoading={isLoading}
        onSubmit={handleSubmit}
      />
    </AppContainer>
  )
}

export default SignUpContractContainer
