import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import { PasswordField } from 'storfox-form-fields'
import { useField } from 'react-final-form'
import Link from '@mui/material/Link'
import { useTranslation } from 'react-i18next'

import * as ROUTES from '~/constants/routes'
import TextField from '~/components/Fields/TextField'
import { Button } from '~/components/Buttons'
import { withForm } from '~/components/Form'

function SignInForm ({ isLoading, form }) {
  const { t } = useTranslation()
  const { handleSubmit } = form
  const emailField = useField('email')
  const passwordField = useField('password')

  const disabled = (
    isLoading || !emailField.input.value || !passwordField.input.value
  )

  return (
    <form onSubmit={handleSubmit}>
      <Grid container={true} spacing={3}>
        <Grid item={true} xs={12}>
          <TextField
            autoComplete="username"
            data-cy="email"
            autoFocus={true}
            name="email"
            label="Email"
            size="medium"
          />
        </Grid>
        <Grid item={true} xs={12}>
          <PasswordField
            autoComplete="current-password"
            data-cy="password"
            name="password"
            label="Password"
            size="medium"
          />
        </Grid>
        <Grid item={true} xs={12}>
          <Button
            type="submit"
            size="large"
            data-cy="submit"
            variant="contained"

            disabled={disabled}
            fullWidth={true}>
            Login
          </Button>
        </Grid>
        <Grid item={true} xs={12} sx={{ textAlign: 'center' }}>
          <Link
            rel="noreferrer noopener"
            href={ROUTES.FORGOT_PASSWORD}
          >
            {t('Forgot Password?')}
          </Link>
        </Grid>
      </Grid>
    </form>
  )
}

SignInForm.propTypes = {
  form: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
}

export default withForm(SignInForm)
