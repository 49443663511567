import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { buttonClasses } from '@mui/material/Button'
import { useRoute } from 'storfox-route-hooks'
import { append, isEmpty, pipe, propOr } from 'ramda'
import Box from '@mui/material/Box'
import { CircularProgress, styled } from '@mui/material'
import Chip from '@mui/material/Chip'
import { getCount } from 'storfox-api-hooks'

const TabsStyled = styled(({ className, ...props }) => (
  <Tabs TabScrollButtonProps={{ className }} {...props} />
))({
  cursor: 'pointer',
  [`&.${buttonClasses.disabled}`]: {
    opacity: '.3 !important'
  }
})

const ChipStyled = styled(Chip, {
  shouldForwardProp: propName => propName !== 'isActive'
})({
  cursor: 'pointer',
  borderRadius: '5px',
  marginLeft: '5px',
  height: '20px',
  transition: '0.3s !important'
})

const ID = 'untitled'

const UNTITLED = {
  id: ID, name: 'Untitled', count: 0
}

const nameToCode = pipe(
  name => name.toLowerCase(),
  name => name.replace(/\s/g, '-')
)

function StatusBar ({ filter, ordering, isLoading }) {
  const { replaceParams } = useRoute()
  const { current, items } = filter.backend
  const { visibleFilters, countResults } = ordering

  const value = propOr(ID, 'id', current)

  const untitled = {
    ...UNTITLED,
    count: getCount()
  }

  const filtersWithUntitled = append(untitled, visibleFilters)
  const tabs = isEmpty(current) ? filtersWithUntitled : visibleFilters

  const onChange = (event, value) => {
    if (value !== ID) {
      const selectItem = items.find(item => item.id === value)
      const params = filter.transformer.toParams(selectItem.values)
      replaceParams({ ...params, select: null, page: 1 })
    }
  }

  const isCount = countResults.length > 0

  const shouldRenderProgress = useCallback(selectedId => {
    return isCount && isLoading && selectedId === ID
  }, [isCount, isLoading])

  const shouldRenderChip = useCallback(selectedId => {
    const isChipUntitled = isCount && !isLoading && selectedId === ID
    const isChip = isCount && selectedId !== ID

    return isChipUntitled || isChip
  }, [isLoading, isCount])

  return (
    <Box display="flex" justifyContent="space-between">
      <TabsStyled
        value={value}
        indicatorColor="primary"
        variant="scrollable"
        scrollButtons={true}
        onChange={onChange}
      >
        {tabs.map((item, index) => (
          <Tab
            data-cy={`tab-${nameToCode(item.name)}`}
            key={index}
            label={
              <Box component="span">
                {item.name}
                {shouldRenderProgress(item.id) && <CircularProgress sx={{ ml: 1 }} size="12px" color="primary" />}
                {shouldRenderChip(item.id) && (
                  <ChipStyled
                    size="small"
                    label={item.count}
                    color={item.id === value ? 'primary' : 'default'}
                  />
                )}
              </Box>
            }
            value={item.id}
          />
        ))}
      </TabsStyled>
    </Box>
  )
}

StatusBar.propTypes = {
  filter: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  isLoading: PropTypes.bool
}

export default StatusBar
