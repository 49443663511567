import React from 'react'
import { useSnackbar } from 'storfox-snackbar'
import { useParams } from 'react-router-dom'
import { prop } from 'ramda'
import { useTranslation } from 'react-i18next'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'

import AutomationRuleCreate from '../../components/AutomationRule/AutomationRuleCreate'
import { useAutomationRuleDetail, useAutomationRuleUpdate } from '../../hooks/AutomationRule'
import { AutomationRuleCreateSerializer, AutomationRuleInitSerializer } from '../../serializers/AutomationRule'

function AutomationRuleUpdateContainer () {
  const { t } = useTranslation()
  const { guid } = useParams()
  const automationRuleUpdate = useAutomationRuleUpdate(guid)
  const automationRuleDetail = useAutomationRuleDetail(guid)
  const snackbar = useSnackbar()

  const message = t('Successfully updated')

  const handleSubmit = formValues =>
    automationRuleUpdate.update(AutomationRuleCreateSerializer(formValues))
      .then(() => automationRuleDetail.getDetail())
      .then(() => snackbar({ message }))

  const title = prop('title', automationRuleDetail.detail)

  const isLoading = (
    automationRuleDetail.isLoading ||
    automationRuleUpdate.isLoading
  )

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      activeNav={NAV.CONFIGURATION}
      isLoading={automationRuleDetail.isLoading}
      title={title}
      breadcrumbs={breadcrumbs}
    >
      <AutomationRuleCreate
        pageTitle={title}
        isLoading={isLoading}
        detailLoading={automationRuleDetail.isLoading}
        pageTitleLoading={automationRuleDetail.isLoading}
        initialValues={AutomationRuleInitSerializer(automationRuleDetail.detail)}
        onSubmit={handleSubmit}
      />
    </DashboardLayout>
  )
}

export default AutomationRuleUpdateContainer
