import React, { Suspense } from 'react'
import PropTypes from 'prop-types'

import DecoupledDocumentEditor from './DecoupledDocumentEditor'

function DocumentRichTextField ({ initialData, keywords, onImageUpload, ...props }) {
  return (
    <Suspense fallback="Loading...">
      {initialData && <DecoupledDocumentEditor initialData={initialData} {...props} />}
    </Suspense>
  )
}
DocumentRichTextField.propTypes = {
  name: PropTypes.string.isRequired,
  keywords: PropTypes.array.isRequired,
  onImageUpload: PropTypes.func,
  initialData: PropTypes.string
}
export default DocumentRichTextField
