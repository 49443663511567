import React from 'react'
import PropTypes from 'prop-types'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import { styled } from '@mui/material'

import { getSxArray } from './utils'
import { BootstrapInput } from './InputBase'

const InputLabelStyled = styled(InputLabel)({
  '&:not(.Mui-focused):not(.MuiFormLabel-filled)': {
    transform: 'translate(14px, 10px) scale(1)',
    paddingLeft: '5px',
    paddingRight: '5px'
  }
})

function SelectField (props) {
  const {
    variant = 'outlined',
    label,
    options,
    allowNull,
    name,
    disabled,
    className,
    value,
    onChange,
    controlSx,
    ...defaultProps
  } = props

  const children = []

  if (options) {
    if (allowNull) {
      children.push(
        <MenuItem key="null" value={null} primaryText="">
          None
        </MenuItem>
      )
    }
  }

  options.forEach(item => children.push(
    <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>
  ))

  return (
    <FormControl variant={variant} sx={[{ minWidth: '100%' }, ...getSxArray(controlSx)]} className={className}>
      <InputLabelStyled id={`${name}-label`}>
        {label}
      </InputLabelStyled>
      <Select
        labelId={`${name}-label`}
        id={name}
        displayEmpty={true}
        value={value || ''}
        disabled={disabled}
        onChange={event => onChange(event, event.target.value)}
        input={<BootstrapInput />}
        {...defaultProps}
      >
        {children}
      </Select>
    </FormControl>
  )
}

SelectField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  variant: PropTypes.string,
  options: PropTypes.array,
  allowNull: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  controlSx: PropTypes.object
}

export default SelectField
