import React from 'react'
import PropTypes from 'prop-types'
import { propOr } from 'ramda'
import Grid from '@mui/material/Grid'

import TotalUnitsIcon from '../../FulfillmentGeneral/icons/TotalUnitsIcon.svg'
import OnHandIcon from '../../FulfillmentGeneral/icons/OnHandIcon.svg'
import AwaitingIcon from '../../FulfillmentGeneral/icons/AwaitingIcon.svg'
import ReservedIcon from '../../FulfillmentGeneral/icons/ReservedIcon.svg'
import DamageIcon from '../../FulfillmentGeneral/icons/DamageIcon.svg'
import FulfillmentTopInformation from '../../FulfillmentGeneral/FulfillmentTopInformation'

function TplGeneral ({ data, isLoading }) {
  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} md={12 / 5}>
        <FulfillmentTopInformation
          title="Total units"
          value={propOr(0, 'total', data)}
          icon={<TotalUnitsIcon />}
          iconColor="#FFC42E"
          isLoading={isLoading}
          tooltip="Overall count of all units of the products."
        />
      </Grid>
      <Grid item={true} md={12 / 5}>
        <FulfillmentTopInformation
          title="On hand"
          value={propOr(0, 'onHand', data)}
          icon={<OnHandIcon />}
          iconColor="#3CC7F4"
          isLoading={isLoading}
          tooltip="No. of units currently available in inventory for immediate use."
        />
      </Grid>
      <Grid item={true} md={12 / 5}>
        <FulfillmentTopInformation
          title="Awaiting"
          value={propOr(0, 'awaiting', data)}
          icon={<AwaitingIcon />}
          iconColor="#FFA12D"
          isLoading={isLoading}
          tooltip="Quantity of units pending arrival or processing."
        />
      </Grid>
      <Grid item={true} md={12 / 5}>
        <FulfillmentTopInformation
          title="Reserved"
          value={propOr(0, 'reserved', data)}
          icon={<ReservedIcon />}
          iconColor="#656EEA"
          isLoading={isLoading}
          tooltip="Units allocated for specific purposes, such as pending orders or reservations."
        />
      </Grid>
      <Grid item={true} md={12 / 5}>
        <FulfillmentTopInformation
          title="Damaged"
          value={propOr(0, 'damaged', data)}
          icon={<DamageIcon />}
          iconColor="#F9597D"
          isLoading={isLoading}
          tooltip="Number of units identified as defective for regular use."
        />
      </Grid>
    </Grid>
  )
}

TplGeneral.propTypes = {
  data: PropTypes.object,
  isLoading: PropTypes.bool.isRequired
}

export default TplGeneral
