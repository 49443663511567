import React from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { useSnackbar } from 'storfox-snackbar'
import { useTranslation } from 'react-i18next'
import { pathOr } from 'ramda'

import { DashboardLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'

import { useBrandCreate } from '../hooks'
import { BrandCreateSerializer } from '../serializers'
import { BrandCreateForm } from '../components/BrandCreate'

function BrandCreateContainer () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const brandCreate = useBrandCreate()

  const message = t('Successfully created')

  const handleSubmit = formValues =>
    brandCreate.create(BrandCreateSerializer(formValues))
      .then((response) => {
        const id = pathOr(0, ['result', 'id'], response)
        const detailUrl = generatePath(ROUTES.BRAND_DETAIL_PATH, { id })
        navigate(detailUrl)
      })
      .then(() => snackbar({ message }))

  const title = t('New brand')

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      isLoading={brandCreate.isLoading}
      activeNav={NAV.BRANDS}
      breadcrumbs={breadcrumbs}
    >
      <BrandCreateForm
        title={title}
        onSubmit={handleSubmit}
        isLoading={brandCreate.isLoading}
      />
    </DashboardLayout>
  )
}

export default BrandCreateContainer
