import { filter, map, path, pick, pipe, prop, propOr } from 'ramda'
import { DEFAULT_SEARCH_PARAMS } from 'storfox-route-hooks'

import { escapeAtob, getDateTimeMidnight } from '~/utils'

const getLineItems = pipe(
  propOr([], 'lineItems'),
  map(item => {
    const units = prop('units', item)
    const barcodeItems = map(unitItem => {
      const unit = prop('unit', unitItem)
      const quantity = prop('quantity', unitItem)
      const conditionCode = path(['condition', 'code'], unit)
      const totalQuantity = prop('quantity', unit)
      return { ...unit, totalQuantity, quantity, conditionCode }
    }, units)

    return { ...item, barcodeItems }
  })
)

export const TransferInitUpdateSerializer = detail => {
  const lineItems = getLineItems(detail)

  return {
    ...detail,
    lineItems
  }
}

const getTransferLineItems = pipe(
  propOr([], 'lineItems'),
  map(item => {
    const variant = prop('variant', item)
    const id = prop('id', item)
    const retailPrice = prop('price', item)
    const available = prop('available', item)

    const barcodeItems = propOr([], 'barcodeItems', item)
    const units = map(item => {
      const id = prop('id', item)
      const quantity = prop('quantity', item)

      return { unit: id && { id }, quantity }
    }, barcodeItems)

    const quantity = units.length

    return {
      id,
      available,
      quantity,
      retailPrice,
      units,
      variant
    }
  }),
  filter(el => el.quantity)
)

export const TransferCreateSerializer = formValues => {
  const lineItems = getTransferLineItems(formValues)

  return {
    ...formValues,
    expectedDate: getDateTimeMidnight(prop('expectedDate', formValues)),
    transferDate: getDateTimeMidnight(prop('transferDate', formValues)),
    lineItems
  }
}

export const TransferFilterSerializer = params => {
  const defaultParams = pick(DEFAULT_SEARCH_PARAMS, params)
  const status = prop('status', params)
  const totalPriceStart = prop('totalPriceStart', params)
  const totalPriceEnd = prop('totalPriceEnd', params)
  const fromWarehouseGuid = escapeAtob(prop('fromWarehouse', params))
  const toWarehouseGuid = escapeAtob(prop('toWarehouse', params))
  const createdAtStart = prop('createdAtStart', params)
  const createdAtEnd = prop('createdAtEnd', params)
  const updatedAtStart = prop('updatedAtStart', params)
  const updatedAtEnd = prop('updatedAtEnd', params)

  return {
    ...defaultParams,
    status,
    totalPriceStart,
    totalPriceEnd,
    updatedAtStart,
    updatedAtEnd,
    createdAtStart,
    createdAtEnd,
    fromWarehouseGuid,
    toWarehouseGuid,
  }
}
