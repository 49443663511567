import React from 'react'
import { useSnackbar } from 'storfox-snackbar'
import { generatePath, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'

import { useBillingProfileCreate } from '../../hooks/BillingProfile'
import {
  BillingProfileCreateSerializer,
  BillingProfileErrorSerializer,
  getInitialValues
} from '../../serializers/BillingProfile'
import BillingProfileCreate from '../../components/BillingProfile/BillingProfileCreate'

function BillingProfileCreateContainer () {
  const { t } = useTranslation()
  const billingProfileCreate = useBillingProfileCreate()
  const snackbar = useSnackbar()
  const navigate = useNavigate()

  const message = t('Successfully created')
  const handleSubmit = formValues =>
    billingProfileCreate.create(BillingProfileCreateSerializer(formValues))
      .then(() => snackbar({ message }))
      .then(() => navigate(ROUTES.BILLING_PROFILE_LIST_PATH))
      .catch(error => {
        const tab = BillingProfileErrorSerializer(error)
        const path = generatePath(ROUTES.BILLING_PROFILE_CREATE_PATH, { tab })
        navigate(path, { replace: true })

        return Promise.reject(error)
      })

  const breadcrumbs = { title: NAV.TPL_BILLING }

  return (
    <DashboardLayout
      title="Billing"
      activeNav={NAV.CONFIGURATION}
      isLoading={billingProfileCreate.isLoading}
      breadcrumbs={breadcrumbs}
    >
      <BillingProfileCreate
        initialValues={getInitialValues()}
        onSubmit={handleSubmit}
        isLoading={billingProfileCreate.isLoading}
      />
    </DashboardLayout>
  )
}

export default BillingProfileCreateContainer
