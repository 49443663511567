import React from 'react'
import PropTypes from 'prop-types'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

import Tabs from '~/components/Tabs'
import { COMPANY_UPDATE_PATH } from '~/constants/routes'
import { COMPANY_TABS } from '~/constants/tabs'

const tabs = [
  { value: COMPANY_TABS.GENERAL, label: 'General' },
  { value: COMPANY_TABS.USERS, label: 'Users' }
]

function CompanyUpdateFormTabs ({ value }) {
  const navigate = useNavigate()
  const { id } = useParams()

  return (
    <Tabs
      tabs={tabs}
      value={value}
      onChange={(event, tab) => {
        const redirect = generatePath(COMPANY_UPDATE_PATH, { id, tab })

        navigate(redirect, { replace: true })
      }}
    />
  )
}

CompanyUpdateFormTabs.propTypes = {
  value: PropTypes.string.isRequired
}

export default CompanyUpdateFormTabs
