import React from 'react'
import PropTypes from 'prop-types'
import SettingsBrightnessTwoToneIcon from '@mui/icons-material/SettingsBrightnessTwoTone'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import Divider from '@mui/material/Divider'

import { getFullName } from '~/utils'
import * as ROUTES from '~/constants/routes'
import { DARK, LIGHT, useCurrentTheme } from '~/components/Theme'

import Menu, { MenuHeader, MenuItem, MenuItemContent } from '../Menu'
import ProfileAvatar from '../ProfileAvatar'

const THEME_NAMES = {
  [LIGHT]: 'Light',
  [DARK]: 'Dark'
}

function ProfilePopup ({ profile, avatar, open, anchorEl, onAppearanceClick, onClose, onLogout }) {
  const navigate = useNavigate()
  const currentTheme = useCurrentTheme()

  const fullName = getFullName(profile)

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
    >
      <MenuHeader onClick={() => navigate(ROUTES.PROFILE_PATH)}>
        <Box>
          <ProfileAvatar
            variant="circular"
            src={avatar}
            alt={fullName}
            type="lg"
          />

        </Box>
        <Box ml={2}>
          <Link
            component={RouterLink}
            to={ROUTES.PROFILE_PATH}
            variant="subtitle1"
            underline="none"
          >
            {fullName}
          </Link>

          <br />

          <Link
            component={RouterLink}
            to={ROUTES.PROFILE_PATH}
            variant="body1"
            underline="none"
          >
            Profile
          </Link>
        </Box>
      </MenuHeader>
      <Divider />
      <MenuItem onClick={onAppearanceClick}>
        <MenuItemContent hasArrow={true} icon={<SettingsBrightnessTwoToneIcon />}>
          Appearance: {THEME_NAMES[currentTheme]}
        </MenuItemContent>

      </MenuItem>
      <MenuItem onClick={onLogout}>
        <MenuItemContent icon={<ExitToAppIcon />}>
          Logout
        </MenuItemContent>
      </MenuItem>
    </Menu>
  )
}

ProfilePopup.propTypes = {
  avatar: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  onAppearanceClick: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  anchorEl: PropTypes.any
}

export default ProfilePopup
