import React from 'react'
import AddIcon from '@mui/icons-material/Add'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

import Button from '~/components/Buttons/Button'

function NewButton ({ children, path, onClick, ...props }) {
  const navigate = useNavigate()

  return (
    <Button
      variant="contained"
      data-cy="create"
      type="button"
      onClick={() => {
        onClick && onClick()
        navigate(path)
      }}
      startIcon={<AddIcon />}
      {...props}
    >
      {children}
    </Button>
  )
}

NewButton.propTypes = {
  children: PropTypes.any.isRequired,
  path: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
}

NewButton.defaultProps = {
  children: 'New'
}

export default NewButton
