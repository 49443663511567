import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { getToken, setToken } from 'storfox-api-hooks'

import Context from './context'

function Token ({ children }) {
  const initialToken = getToken()
  const [value, setValue] = useState(initialToken)

  return (
    <Context.Provider
      value={{
        token: value,
        setToken: value => {
          setToken(value)
          setValue(value)
        }
      }}
    >
      {children}
    </Context.Provider>
  )
}

Token.propTypes = {
  children: PropTypes.any.isRequired
}

export default Token
