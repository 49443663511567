import React from 'react'
import Grid from '@mui/material/Grid'
import PropTypes from 'prop-types'
import { map, range } from 'ramda'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import Divider from '@mui/material/Divider'
import Card from '@mui/material/Card'

function SkeletonCards ({ grid, count }) {
  return map(index => (
    <Grid item={true} key={index} {...grid}>
      <Card sx={{ padding: '8px 16px', textAlign: 'center' }}>
        <Box mb={2} width={150} display="inline-block">
          <Skeleton />
          <Skeleton />
        </Box>
        <Divider />
        <Box pt={1} pb={1}>
          <Skeleton variant="rectangular" height={95} />
        </Box>
        <Divider />
        <Box mt={1}>
          <Skeleton height={35} />
        </Box>
      </Card>
    </Grid>
  )
  , range(0, count))
}

SkeletonCards.defaultProps = {
  grid: { xs: 12 },
  count: 4
}

SkeletonCards.propTypes = {
  grid: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired
}

export default SkeletonCards
