import React from 'react'
import { Divider } from '@mui/material'
import { prop, propOr } from 'ramda'
import { generatePath, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'

import Container, { Content, Header } from '~/components/Container'
import PageTitle from '~/components/PageTitle'
import * as NAV from '~/constants/nav-titles'
import { Button, EditButton } from '~/components/Buttons'
import * as ROUTES from '~/constants/routes'
import LoaderProvider from '~/components/Loader'
import { MOVEMENT_DETAIL_TABS } from '~/constants/tabs'
import History from '~/components/History'

import MovementDetailTabs from './MovementDetailTabs'
import MovementDetailGeneral from './MovementDetailGeneral'

import { DRAFT, COMPLETED } from '../MovementStatus'
import More from '../MovementUpdate/More'

function MovementDetail (props) {
  const {
    initialValues,
    pageTitle,
    pageTitleLoading,
    isLoading,
    onComplete,
    onCancel,
    cancelLoading,
    history,
    movementLineItems
  } = props

  const { id, tab } = useParams()

  const notes = prop('notes', initialValues)
  const status = prop('status', initialValues)
  const mode = propOr('scan', 'mode', initialValues)

  const disabled = isLoading || status !== DRAFT
  const isCompleted = status === COMPLETED
  const isCancelLoading = disabled || cancelLoading
  const updateRoute = mode === 'scan' ? ROUTES.MOVEMENT_SCAN_UPDATE_PATH : ROUTES.MOVEMENT_BROWSE_UPDATE_PATH
  const path = generatePath(updateRoute, { id })

  const rows = prop('results', movementLineItems)
  const lineItemsLoading = prop('isLoading', movementLineItems)
  const count = propOr(0, 'count', movementLineItems)

  return (
    <LoaderProvider isLoading={isLoading || lineItemsLoading}>
      <Container>
        <Header>
          <PageTitle
            parentTitle={NAV.MOVEMENT}
            pageTitle={pageTitle}
            pageTitleLoading={pageTitleLoading}
            rightButton={
              <>
                <Button
                  variant="contained"
                  data-cy="complete"
                  disabled={disabled}
                  onClick={onComplete}
                >
                  Complete
                </Button>
                <EditButton disabled={isCompleted} path={path} />
                <More onCancel={onCancel} cancelLoading={isCancelLoading} />
              </>
            }
          />
        </Header>
        <Content>
          <MovementDetailTabs value={tab} />
          <Divider />
          <Box mb={3} />
          {tab === MOVEMENT_DETAIL_TABS.GENERAL && (
            <MovementDetailGeneral
              isLoading={lineItemsLoading}
              initialValues={initialValues}
              rows={rows}
              notes={notes}
              count={count}
              status={status}
            />
          )}
          {tab === MOVEMENT_DETAIL_TABS.HISTORY && <History history={history} />}
        </Content>
      </Container>
    </LoaderProvider>
  )
}

MovementDetail.propTypes = {
  initialValues: PropTypes.object.isRequired,
  pageTitle: PropTypes.string.isRequired,
  pageTitleLoading: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  cancelLoading: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  movementLineItems: PropTypes.object.isRequired
}
export default MovementDetail
