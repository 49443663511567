import PropTypes from 'prop-types'
import React from 'react'
import {
  createLargeImageUrl,
  createMediumImageUrl,
  createOriginImageUrl,
  createSmallImageUrl
} from 'storfox-image-upload'

import blank from '~/assets/images/avatar/blank.png'

import Image from './Image'

const SMALL = 'small'
const MEDIUM = 'medium'
const LARGE = 'large'
const ORIGINAL = 'original'

function ImageCdn ({ src, size, alt, className, ...props }) {
  const getSrcBySize = {
    [SMALL]: () => createSmallImageUrl(src),
    [MEDIUM]: () => createMediumImageUrl(src),
    [LARGE]: () => createLargeImageUrl(src),
    [ORIGINAL]: () => createOriginImageUrl(src)
  }

  return (
    <Image
      src={src ? getSrcBySize[size]() : blank}
      alt={alt}
      className={className}
      {...props}
    />
  )
}

ImageCdn.defaultProps = {
  size: MEDIUM
}

ImageCdn.propTypes = {
  alt: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
  size: PropTypes.oneOf([SMALL, MEDIUM, LARGE, ORIGINAL]).isRequired
}

export default ImageCdn
