import React from 'react'
import { prop } from 'ramda'

import * as API from '~/constants/api'

import MultiSelectSearchField from './MultiSelectSearchField'

const getOptionLabel = item => {
  if (item) {
    return prop('name', item)
  }

  return ''
}

const getOptionValue = item => {
  if (item) {
    const id = prop('id', item)
    const guid = prop('guid', item)
    const locationId = prop('locationId', item)

    return { id, guid, name: locationId }
  }

  return null
}

function LocationMultiSelectField (props) {
  return (
    <>
      <MultiSelectSearchField
        api={API.LOCATION_LIST}
        data-cy="locations"
        name="locations"
        label="Locations"
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        {...props}
      />

    </>
  )
}

export default LocationMultiSelectField
