import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { useFieldArray } from 'react-final-form-arrays'
import { useField } from 'react-final-form'
import Grid from '@mui/material/Grid'
import { prop } from 'ramda'
import { ClassNames } from '@emotion/react'

import ContainerTypeField from '~/components/Fields/ContainerTypeField'
import OrderProgress from '~/components/OrderProgress'
import { Button } from '~/components/Buttons'

function ContainerizeCard (props) {
  const {
    children,
    packedCount,
    totalQuantity,
    warehouseGuid,
    onContainerCreate
  } = props
  const containers = useFieldArray('containers')
  const containerTypeField = useField('containerType')
  const containerType = containerTypeField.input.value
  const containerTypeGuid = prop('guid', containerType)

  const params = {
    limit: 1000,
    operations: 'packing',
    warehouseGuids: warehouseGuid
  }

  const disableCreate = containerTypeField.input.value === ''

  const handleContainerCreate = () =>
    onContainerCreate(containerTypeGuid)
      .then(({ result }) => {
        containers.fields.push(result)
        containerTypeField.input.onChange('')
      })

  return (
    <Card>
      <Toolbar sx={{ minHeight: 'auto', height: 56 }}>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Typography variant="h5">Containerize</Typography>
          <Box>
            <ClassNames>
              {({ css }) => (
                <OrderProgress
                  received={packedCount}
                  ordered={totalQuantity}
                  progressClasses={css({ width: '250px' })}
                />
              )}
            </ClassNames>
          </Box>
        </Box>
      </Toolbar>
      <Divider />
      <CardContent>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={8} lg={10}>
            <ContainerTypeField params={params} ListboxProps={{ 'data-cy': 'containerList' }} />
          </Grid>
          <Grid item={true} xs={4} lg={2}>
            <Button
              variant="contained"

              data-cy="create"
              disabled={disableCreate}
              onClick={handleContainerCreate}
              fullWidth={true}
            >
              Create
            </Button>
          </Grid>
        </Grid>
        {children}
      </CardContent>
    </Card>
  )
}

ContainerizeCard.propTypes = {
  children: PropTypes.any.isRequired,
  onContainerCreate: PropTypes.func.isRequired,
  packedCount: PropTypes.number,
  totalQuantity: PropTypes.number,
  warehouseGuid: PropTypes.string
}

export default ContainerizeCard
