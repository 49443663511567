import React from 'react'
import {
  Divider,
  IconButton,
  Input,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  styled,
  Toolbar,
  Tooltip
} from '@mui/material'
import Box from '@mui/material/Box'
import SearchIcon from '@mui/icons-material/Search'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { prop } from 'ramda'
import PropTypes from 'prop-types'

import Subtext from '~/components/Subtext'
import Avatar from '~/components/Avatar'
import TextOverflow from '~/components/TextOverflow'

const RootStyled = styled(Box)(({ theme }) => ({
  ...theme.components.StorfoxListPanel.styleOverrides.root,
  position: 'fixed',
  zIndex: '999',
  height: '100vh',
  width: 300,
  flexBasis: 300,
  [theme.breakpoints.down('md')]: {
    display: 'none'
  },
  '@media (min-width: 864px)': {
    borderRight: `1px solid ${theme.palette.divider}`
  }
}))

const ItemsBoxStyled = styled(Box)({
  position: 'fixed',
  zIndex: '999',
  width: 300,
  overflowY: 'auto',
  height: 'calc(100vh - 128px)',
  flexShrink: 0
})

const ListItemButtonStyled = styled(ListItemButton, {
  shouldForwardProp: (propName) => propName !== 'isActive'
})(({ theme, isActive }) => ({
  ...(isActive && theme.components.StorfoxListPanel.styleOverrides.active)
}))

function ListingListItems ({ search, filteredResults, listingGuid, onSearch, onClick }) {
  return (
    <RootStyled>
      <Toolbar sx={{
        minHeight: 'auto',
        height: 58,
        padding: 2
      }}>
        <Input
          disableUnderline={true}
          fullWidth={true}
          placeholder="Filter by name, SKU, barcode"
          onChange={onSearch}
          value={search}
          sx={{
            border: '1px solid gray',
            borderRadius: '4px',
            flexGrow: 1
          }}
          startAdornment={
            <Tooltip title="Search">
              <IconButton
                edge="end"
                size="medium"
                sx={{ margin: 0, padding: '5px' }}
              >
                <SearchIcon />
              </IconButton>
            </Tooltip>
          }
        />
      </Toolbar>

      <Divider />

      <ItemsBoxStyled>
        <PerfectScrollbar>
          <List component="div" disablePadding={true}>
            {filteredResults
              .map((listing, index) => {
                const guid = prop('guid', listing)
                const name = prop('name', listing)
                const src = prop('defaultImage', listing)
                const sku = <Subtext lines={1}>{prop('sku', listing)}</Subtext>

                return (
                  <ListItemButtonStyled
                    onClick={() => onClick(guid)}
                    data-cy={`variant-${index}`}
                    key={guid}
                    isActive={guid === listingGuid}
                  >
                    <Box display="flex" alignItems="center">
                      <ListItemAvatar>
                        <Avatar alt={name} src={src} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={<TextOverflow selfTooltip={true}>{name}</TextOverflow>}
                        primaryTypographyProps={{ variant: 'h6', style: { fontWeight: 'normal' } }}
                        secondary={sku}
                        secondaryTypographyProps={{ variant: 'caption' }}
                      />
                    </Box>
                  </ListItemButtonStyled>
                )
              })}
          </List>
        </PerfectScrollbar>
      </ItemsBoxStyled>
    </RootStyled>
  )
}

ListingListItems.propTypes = {
  search: PropTypes.string.isRequired,
  filteredResults: PropTypes.array.isRequired,
  listingGuid: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default ListingListItems
