import React from 'react'
import Box from '@mui/material/Box'
import { DateRangeField } from 'storfox-form-fields'
import Paper from '@mui/material/Paper'
import { propOr, prop, propEq } from 'ramda'
import Grid from '@mui/material/Grid'
import PropTypes from 'prop-types'
import { useAllSearchParams } from 'storfox-route-hooks'

import { withForm } from '~/components/Form'
import { base64ToObject, escapeAtob, isSameDateRange } from '~/utils'

import OverviewWarehouseSearchField from './OverviewWarehouseSearchField'
import OverviewCompanySearchField from './OverviewCompanySearchField'

function OverviewFilter ({ form }) {
  const params = useAllSearchParams()
  const { handleSubmit } = form

  const handleDateRangeSubmit = values => {
    const dateRangeParams = propOr([], 'dateRange', params)
    const dateRange = dateRangeParams ? base64ToObject(dateRangeParams) : [null, null]

    if (!isSameDateRange(values, dateRange)) {
      form.form.submit()
    }
  }

  const handleCompanySubmit = values => {
    const paramsCompany = escapeAtob(prop('company', params))
    const sameCompany = propEq('id', prop('id', paramsCompany), values)

    if (!sameCompany) {
      form.form.submit()
    }
  }

  const handleWarehouseSubmit = values => {
    const paramsWarehouse = escapeAtob(prop('warehouse', params))
    const sameWarehouse = propEq('id', prop('id', paramsWarehouse), values)

    if (!sameWarehouse) {
      form.form.submit()
    }
  }

  return (
    <Paper>
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            m: 1,
            width: '40vw',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Grid container={true} spacing={3}>
            <Grid lg={3} item={true}>
              <OverviewCompanySearchField
                onValueChange={handleCompanySubmit}
                searchFieldProps={{ onKeyDown: event => event.stopPropagation() }}
              />
            </Grid>
            <Grid lg={3} item={true}>
              <OverviewWarehouseSearchField
                onValueChange={handleWarehouseSubmit}
                searchFieldProps={{ onKeyDown: event => event.stopPropagation() }}
              />
            </Grid>
            <Grid lg={6} item={true}>
              <DateRangeField
                name="dateRange"
                onAccept={handleDateRangeSubmit}
                startLabel="Start Date"
                endLabel="End Date"
              />
            </Grid>
          </Grid>
        </Box>
      </form>
    </Paper>
  )
}

OverviewFilter.propTypes = {
  form: PropTypes.shape({
    values: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired
  }).isRequired
}

export default withForm(OverviewFilter)
