import { complement, filter, is, isNil, map, path, pick, pipe, prop, propOr, reduce, toPairs } from 'ramda'
import { toCamelCase } from 'storfox-api-hooks'

import { skipNullValues } from '~/utils'

import { FIELDS_TO_PICK } from '../../components/AutomationRule/fieldsToPick'

const CREATE_FIELDS = [
  'action',
  'description',
  'event',
  'rule',
  'title',
  'status'
]

const STRING = 'str'
const INTEGER = 'int'
const OBJECT = 'obj'
const BOOL = 'bool'
const ARRAY_OF_STRINGS = 'listStr'
const ARRAY_OF_INTEGERS = 'listInt'
const ARRAY_OF_OBJECTS = 'listObj'

const isNotNil = complement(isNil)

const getParsedValue = (value, type) => {
  switch (type) {
    case INTEGER:
      return parseInt(value)
    case ARRAY_OF_INTEGERS:
      return map(parseInt, value)
    default:
      return value
  }
}

const getArrayType = value => {
  if (!value.length) {
    return null
  } else if (is(Number, value)) {
    return ARRAY_OF_INTEGERS
  } else if (is(String, value)) {
    return ARRAY_OF_STRINGS
  }

  return ARRAY_OF_OBJECTS
}

const getType = value => {
  if (is(String, value)) {
    return STRING
  } else if (is(Number, value)) {
    return INTEGER
  } else if (is(Boolean, value)) {
    return BOOL
  } else if (is(Array, value)) {
    return getArrayType(value[0])
  } else if (isNil(value)) {
    return null
  }

  return OBJECT
}

const getRules = rule => {
  const rules = propOr([], 'rules', rule)
  if (!rules.length) {
    const val = prop('value', rule)
    const valueType = getType(val)
    const parsedValue = getParsedValue(val, valueType)
    const value = { [valueType]: parsedValue }

    if (!valueType) {
      return null
    }

    return { ...rule, valueType, value }
  } else {
    return { ...rule, rules: map(getRules, rules) }
  }
}

const getActions = (values, fields) => pipe(
  propOr([], 'actions'),
  map(pick(fields)),
  filter(Boolean),
  reduce((acc, value) => ({ ...acc, ...value }), {})
)(values)

const getInitActions = pipe(
  toPairs,
  filter(item => isNotNil(prop(1, item))),
  path([0, 1]),
  toPairs,
  filter(item => isNotNil(prop(1, item))),
  map(([action, value]) => ({ action, [action]: value }))
)

const getInitRules = rule => {
  const rules = prop('rules', rule)
  if (!rules) {
    const valueType = prop('valueType', rule)
    return { ...rule, value: path(['value', valueType], rule) }
  }
  return { ...rule, rules: map(getInitRules, rules) }
}

export const AutomationRuleInitSerializer = values => {
  const action = prop('action', values)
  const ruleObj = prop('rule', values)

  const rule = (prop('rules', ruleObj) || prop('value', ruleObj))
    ? getInitRules(prop('rule', values))
    : null

  const actions = getInitActions(action)

  return { ...skipNullValues({ ...values, rule, actions }), rule }
}

export const AutomationRuleCreateSerializer = values => {
  const rule = getRules(prop('rule', values))
  const event = prop('event', values)
  const ACTION_TYPE = toCamelCase(event)

  const formattedAction = getActions(values, FIELDS_TO_PICK[event])
  const action = { [ACTION_TYPE]: formattedAction }
  return pick(CREATE_FIELDS, { ...values, rule, action })
}
