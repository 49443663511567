import React, { useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import { pathOr, prop, propEq, propOr, uniqBy } from 'ramda'
import { useDeepCompareEffect } from 'storfox-api-hooks'
import { useValueChanged } from 'storfox-tools'
import { useField } from 'react-final-form'

import { withForm } from '~/components/Form'
import Container, { Content } from '~/components/Container'
import { CardHeader } from '~/components/Cards'

import ZoneField from './Fields/ZoneField'
import AreaField from './Fields/AreaField'
import VisualizationTabs from './VisualizationTabs'
import VisualizationTable from './VisualizationTable'

const getUniqueAisleLocations = (locations) => {
  const uniqueAisles = uniqBy(prop('aisle'), locations)
  return uniqueAisles.map(location => {
    const aisle = prop('aisle', location)
    return {
      aisle: aisle,
      locations: locations.filter(loc => propEq('aisle', aisle, loc))
    }
  })
}

function Warehouse2DVisualizationForm ({ form, guid, locations = [], getList, isLoading, onChooseLocation }) {
  const [tab, setTab] = useState('')
  const [isReady, setIsReady] = useState(false)

  const { handleSubmit } = form

  const areaField = useField('area')
  const areaFieldValue = areaField.input.value

  const zoneField = useField('zone')
  const zoneFieldChange = zoneField.input.onChange
  const zoneFieldValue = zoneField.input.value

  const locationByAisle = useMemo(() => getUniqueAisleLocations(locations), [locations])
  const tabOptions = useMemo(() => locationByAisle.map(location => {
    const aisle = prop('aisle', location)
    return {
      label: aisle || 'UNASSIGNED',
      value: aisle
    }
  }), [locationByAisle])
  const selectedLocations = useMemo(() => locationByAisle.find(propEq('aisle', tab)), [tab, locationByAisle])

  const areaId = propOr(0, 'id', areaFieldValue)
  const zoneId = propOr(0, 'id', zoneFieldValue)

  useDeepCompareEffect(() => {
    setTab(pathOr('', [0, 'value'], tabOptions))
  }, [tabOptions])

  useDeepCompareEffect(() => {
    if (zoneId) {
      getList({ zoneId })
      setIsReady(true)
    } else {
      setIsReady(false)
    }
  }, [zoneId])

  useValueChanged(() => {
    zoneFieldChange(undefined)
  }, areaId)

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Container>
        <Content>
          <Box>
            <Grid container={true} spacing={3}>
              <Grid item={true} lg={12}>
                <Card>
                  <CardHeader title="Warehouse Map By" />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} lg={4}>
                        <AreaField params={{ warehouseGuid: guid }} />
                      </Grid>
                      <Grid item={true} lg={4}>
                        <ZoneField params={{ warehouseGuid: guid, areaId }} />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              {(selectedLocations && isReady && !isLoading) && (
                <Grid item={true} lg={12}>
                  <Card>
                    <CardHeader title="Visualization" />
                    <Divider />
                    <CardContent>
                      <VisualizationTabs tab={tab} setTab={setTab} options={tabOptions} />
                      <Divider />
                      <VisualizationTable selected={selectedLocations} onChooseLocation={onChooseLocation} />
                    </CardContent>
                  </Card>
                </Grid>
              )}
            </Grid>
          </Box>
        </Content>
      </Container>
    </form>
  )
}

Warehouse2DVisualizationForm.propTypes = {
  form: PropTypes.object.isRequired,
  guid: PropTypes.string.isRequired,
  locations: PropTypes.array.isRequired,
  getList: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onChooseLocation: PropTypes.func.isRequired
}

export default withForm(Warehouse2DVisualizationForm)
