import React from 'react'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import { is, isEmpty, isNil } from 'ramda'
import Skeleton from '@mui/material/Skeleton'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

function TextLabel ({ children, isLoading, label, ...props }) {
  const { t } = useTranslation()

  const emptyChild = isEmpty(children) || isNil(children)

  const component = is(String, children) ? 'p' : 'div'
  const content = emptyChild ? t('Not provided') : children

  return (
    <Box>
      {label && (
        <Typography
          variant="body1"
          sx={{ color: '#9EA1B6', marginBottom: '4px' }}
        >
          {label}
        </Typography>
      )}
      {isLoading
        ? <Skeleton variant="text" width={150} height={25} />
        : (
          <Typography
            variant="subtitle1"
            component={component}
            sx={{ opacity: emptyChild ? 0.2 : 1 }}
            {...props}
          >
            {content}
          </Typography>
        )}
    </Box>
  )
}

TextLabel.propTypes = {
  label: PropTypes.string,
  children: PropTypes.any,
  isLoading: PropTypes.bool
}

export default TextLabel
