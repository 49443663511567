import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import uuid from 'uuid/v1'
import { includes, isNil, pluck, prop, propOr } from 'ramda'
import { DndProvider } from 'react-dnd'
import TouchBackend from 'react-dnd-touch-backend'
import HTML5Backend from 'react-dnd-html5-backend'
import { useField } from 'react-final-form'
import { useFieldArray } from 'react-final-form-arrays'
import { isMobile } from 'storfox-tools'
import update from 'immutability-helper'
import { useDeepCompareEffect } from 'storfox-api-hooks'
import { Box, styled } from '@mui/material'

import Button from '~/components/Buttons/Button'
import { emptyArray } from '~/constants/empty'

import ImageAttachDialog from './ImageAttachDialog'
import AttachButton from './AttachButton'

import ImageItem from '../Image'
import Drag from '../Drag'
import { useUpload } from '../../hooks'
import { createMediumImageUrl, createSmallImageUrl } from '../../utils'

const GridBoxStyled = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(4,1fr)',
  gap: '.8rem',
  '& > div:first-of-type': {
    gridColumn: '1/span 2',
    gridRow: '1/span 2',
    '& > div': {
      height: 'calc(200px + 0.8rem)',
      [theme.breakpoints.down('md')]: {
        height: '100%'
      },
    }
  }
}))

const ActionsStyled = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  display: 'flex',
  justifyContent: 'flex-end',
  '& > * + *': {
    marginLeft: theme.spacing(2)
  }
}))

const toFieldValue = ({ id, imagePath }, index) => {
  return { id, imagePath, isDefault: index === 0 }
}

function ImageAttach ({ className, name, onGetImages, onUpload }) {
  const [open, setOpen] = useState(false)
  const [items, setItems] = useState(emptyArray)
  const [select, setSelect] = useState(emptyArray)
  const [isLoading, setIsLoading] = useState(false)
  const [handleUpload, files, setFiles] = useUpload({ onUpload })
  const { fields } = useFieldArray(name)
  const value = fields.value || emptyArray
  const field = useField(name)
  const onChange = field.input.onChange

  useDeepCompareEffect(() => {
    const newItems = value.map((img, key) => {
      const imagePath = prop('imagePath', img)
      const isDefault = files.length === 0
      const src = createMediumImageUrl(imagePath)
      return {
        imagePath,
        src,
        key,
        isDefault
      }
    })

    setItems(newItems)
  }, [value])

  const handleOpen = () => {
    setIsLoading(true)

    onGetImages().then(data => {
      const results = propOr([], 'results', data)
      const items = results.map(item => ({
        id: item.id,
        key: item.id,
        imagePath: item.imagePath,
        src: createSmallImageUrl(item.imagePath),
      }))

      setFiles(items)
      setIsLoading(false)
    })

    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOnChange = values => setFiles(values)

  const handleRemove = () => {
    const newFiles = items.filter((item, index) => !includes(index, select))
    const newFieldValue = newFiles.map(toFieldValue)

    setItems(newFiles)
    onChange(newFieldValue)

    setSelect([])
  }

  const handleAttach = (images) => {
    const imagePaths = pluck('imagePath', value)

    const newItems = images
      .filter(img => {
        const imagePath = prop('imagePath', img)
        return !includes(imagePath, imagePaths)
      })
      .map((img) => {
        const path = prop('imagePath', img)
        const src = createSmallImageUrl(path)
        return { ...img, obj: null, src, key: uuid() }
      })

    const length = fields.value.length
    newItems.forEach((item, index) => {
      fields.push({ imagePath: item.imagePath, isDefault: !length && index === 0 })
    })
    setItems(prevItems => [...prevItems, ...newItems])

    handleClose()
  }

  const handleSelect = index => {
    const selected = !isNil(select.find(item => item === index))

    if (selected) {
      const newSelect = select.filter(i => i !== index)
      setSelect(newSelect)
    } else {
      const newSelect = [...select, index]
      setSelect(newSelect)
    }
  }

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    const dragCard = items[dragIndex]
    const newState = update(items, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragCard],
      ],
    })

    const fieldValue = newState.map(toFieldValue)

    onChange(fieldValue)
    setItems(newState)
  }, [items, onChange])

  return (
    <Box className={clsx(className)}>
      <ActionsStyled>
        {items.length > 0 && (
          <Button
            size="small"
            disabled={select.length === 0}
            onClick={handleRemove}
          >
            Detach
          </Button>
        )}
      </ActionsStyled>

      <GridBoxStyled>
        <DndProvider backend={isMobile() ? TouchBackend : HTML5Backend}>
          {items.map((item, index) => {
            return (
              <Drag
                key={index}
                id={item.key}
                index={index}
                moveCard={moveCard}
              >
                <div>
                  <ImageItem
                    file={item}
                    selected={!isNil(select.find(item => item === index))}
                    onSelect={() => handleSelect(index)}
                  />
                </div>
              </Drag>
            )
          })}
          <AttachButton onClick={handleOpen} />
        </DndProvider>
      </GridBoxStyled>

      <ImageAttachDialog
        open={open}
        onAttach={handleAttach}
        onClose={handleClose}
        onDrop={handleUpload}
        onChange={handleOnChange}
        images={files}
        isLoading={isLoading}
      />
    </Box>
  )
}

ImageAttach.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  onGetImages: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired
}

export default ImageAttach
