import React from 'react'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import { map, range } from 'ramda'
import PropTypes from 'prop-types'
import { styled } from '@mui/material'

const SkeletonStyled = styled(Skeleton)({
  marginBottom: '3px'
})

function NotificationSkeletonList ({ count = 10 }) {
  return (
    map(index => (
      <Box key={index}>
        <SkeletonStyled />
        <SkeletonStyled />
        <SkeletonStyled />
        {index !== count - 1 && <Divider sx={{ mt: 2, mb: 2 }} />}
      </Box>
    ), range(0, count))
  )
}

NotificationSkeletonList.propTypes = {
  count: PropTypes.number
}

export default NotificationSkeletonList
