import React, { useCallback, useMemo } from 'react'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import PropTypes from 'prop-types'
import { useField, useFormState } from 'react-final-form'
import { path, prop, propOr } from 'ramda'
import { useAllSearchParams } from 'storfox-route-hooks'
import { useDeepCompareEffect } from 'storfox-api-hooks'

import { CardHeader } from '~/components/Cards'
import { DiscardButton } from '~/components/Buttons'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import WarehouseSearchField from '~/components/Fields/WarehouseSearchField'
import ZoneField from '~/components/Fields/ZoneField'
import { withForm } from '~/components/Form'
import * as API from '~/constants/api'

import StockCountLineItems from './StockCountLineItems'
import StockCountCreateButton from './StockCountCreateButton'

import StockCountAreaField from '../Fields/StockCountAreaField'

function StockCountCreate ({ form, locationList, onLocationListGet, isLoading }) {
  const { handleSubmit } = form
  const searchParams = useAllSearchParams()
  const formState = useFormState()

  const locationLoading = prop('isLoading', locationList)
  const locationResult = prop('results', locationList)
  const count = prop('count', locationList)

  const warehouseGuid = path(['warehouse', 'guid'], formState.values)
  const areaGuid = path(['area', 'guid'], formState.values)
  const zoneGuid = path(['zone', 'guid'], formState.values)

  const locationsField = useField('locations')
  const locations = useMemo(() => locationsField.input.value, [locationsField])
  const allChecked = JSON.stringify(locationResult) === JSON.stringify(locations)

  const handleLocationListGet = useCallback(onLocationListGet, [])
  const handleLocationsChange = useCallback(locationsField.input.onChange, [])

  useDeepCompareEffect(() => {
    if (warehouseGuid) {
      const limit = propOr('10', 'limit', searchParams)
      const page = propOr('1', 'page', searchParams)
      const search = propOr('', 'search', searchParams)
      handleLocationListGet({ warehouseGuid, areaGuid, zoneGuid, limit, page, search })
    }
  }, [warehouseGuid, areaGuid, zoneGuid, handleLocationListGet, handleLocationsChange, searchParams])

  const handleCheck = locationGuid => {
    const isChecked = locations.find(location => prop('guid', location) === locationGuid)
    if (isChecked) {
      const otherLocations = locations.filter(location => prop('guid', location) !== locationGuid)
      handleLocationsChange(otherLocations)
    } else {
      const foundLocation = locationResult.find(location => prop('guid', location) === locationGuid)
      handleLocationsChange([ ...locations, foundLocation ])
    }
  }

  const handleAllCheck = () => {
    const checked = locationResult.length === locations.length
    if (checked) {
      handleLocationsChange([])
    } else {
      handleLocationsChange(locationResult)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            pageTitle="Create stock count"
            parentTitle="Stock Count"
            rightButton={(
              <>
                <DiscardButton disabled={isLoading} />
                <StockCountCreateButton isLoading={isLoading} onSubmit={handleSubmit} />
              </>
            )}
          />
        </Header>
        <Content>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12}>
              <Card>
                <CardHeader title="Location" />
                <Divider />
                <CardContent>
                  <Grid container={true} spacing={3}>
                    <Grid item={true} lg={4} xs={12}>
                      <WarehouseSearchField
                        primaryKey="guid"
                        api={API.STOCK_COUNT_WAREHOUSE_LIST}
                        required={true}
                      />
                    </Grid>
                    <Grid item={true} lg={4} xs={12}>
                      <StockCountAreaField
                        name="area"
                        label="Area"
                        warehouse="warehouse"
                        api={API.STOCK_COUNT_AREA_LIST}
                      />
                    </Grid>
                    <Grid item={true} lg={4} xs={12}>
                      <ZoneField
                        name="zone"
                        label="Zone"
                        area="area"
                        api={API.STOCK_COUNT_ZONE_LIST}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item={true} xs={12}>
              <Card>
                <CardHeader title="Locations" required={true} />
                <Divider />
                <StockCountLineItems
                  count={count}
                  allChecked={allChecked}
                  isLoading={locationLoading}
                  rows={locationResult}
                  onCheck={handleCheck}
                  onAllCheck={handleAllCheck}
                />
              </Card>
            </Grid>

          </Grid>
        </Content>
      </Container>
    </form>
  )
}

StockCountCreate.propTypes = {
  form: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onLocationListGet: PropTypes.func.isRequired,
  locationList: PropTypes.object.isRequired
}

export default withForm(StockCountCreate)
