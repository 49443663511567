import React from 'react'
import ListItemText from '@mui/material/ListItemText'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom'
import PropTypes from 'prop-types'
import { ListItemButton } from '@mui/material'

import { MoreListButton } from '~/components/Buttons'
import * as ROUTES from '~/constants/routes'
import IconText from '~/components/IconText'

function More ({ onProductsExport, tableRef }) {
  const { t } = useTranslation()

  return (
    <MoreListButton tableRef={tableRef}>
      {onClose => (
        <>
          <ListItemButton
            disabled={false}
            component={Link}
            to={ROUTES.PRODUCT_IMPORT_PATH}
          >
            <ListItemText primary={t('Bulk upload/update')} />
          </ListItemButton>
          <ListItemButton
            onClick={() =>
              onProductsExport()
                .finally(() => onClose())
            }
          >
            <IconText Icon={VerticalAlignBottomIcon}>
              <ListItemText primary={t('Export')} />
            </IconText>
          </ListItemButton>
        </>
      )}
    </MoreListButton>
  )
}

More.propTypes = {
  onProductsExport: PropTypes.func.isRequired,
  tableRef: PropTypes.object
}

export default More
