import React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useSnackbar } from 'storfox-snackbar'
import { path, propOr } from 'ramda'

import { HtmlLayout } from '~/components/Layouts'
import useMessages from '~/hooks/useMessages'
import * as ROUTES from '~/constants/routes'
import { RECEIVE_TABS } from '~/constants/tabs'

import { ReceiveBarcodeTransferInitSerializer, ReceivingTransferSerializer } from '../serializers/transferSerializer'
import ReceiveTransferBarcoding from '../components/ReceiveBarcoding/ReceiveTransferBarcoding'
import { useReceiveBarcodeConditionList, useReceiveStockDetail, useReceiveStockScanReceive } from '../hooks'

function ReceiveStockBarcodingContainer () {
  const { guid } = useParams()
  const snackbar = useSnackbar()
  const messages = useMessages()
  const navigate = useNavigate()
  const receiveConditionList = useReceiveBarcodeConditionList()
  const receiveStockDetail = useReceiveStockDetail(guid)
  const receiveStockScanReceive = useReceiveStockScanReceive(guid)

  const number = propOr('Receive PO', 'number', receiveStockDetail.detail)
  const objectGuid = path(['detail', 'guid'], receiveStockDetail)
  const conditions = receiveConditionList.results

  const handleSubmit = values =>
    receiveStockScanReceive.receive(ReceivingTransferSerializer(values, objectGuid))
      .then(() => receiveStockDetail.getDetail().then((response) => {
        const received = path(['result', 'received'], response)
        const ordered = path(['result', 'ordered'], response)
        if (received === ordered) {
          const url = generatePath(ROUTES.RECEIVE_TRANSFER_DETAIL_PATH, { guid, tab: RECEIVE_TABS.GENERAL })
          navigate(url)
        }
      }))
      .then(() => snackbar({ message: messages.RECEIVE_SUCCESS }))

  const isLoading = receiveStockDetail.isLoading || receiveStockScanReceive.isLoading

  return (
    <HtmlLayout title={number}>
      <ReceiveTransferBarcoding
        initialValues={ReceiveBarcodeTransferInitSerializer(receiveStockDetail.detail)}
        conditions={conditions}
        pageTitle={number}
        conditionListLoading={receiveConditionList.isLoading}
        isLoading={isLoading}
        detailLoading={receiveStockDetail.isLoading}
        onSubmit={handleSubmit}
      />
    </HtmlLayout>
  )
}

export default ReceiveStockBarcodingContainer
