import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { prop } from 'ramda'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'
import { getDataFromError } from 'storfox-api-hooks/src/api/utils'

import { DashboardLayout } from '~/components/Layouts'
import ErrorLink from '~/components/ErrorLink'
import * as NAV from '~/constants/nav-titles'
import { PURCHASE_ORDER_DETAIL_TABS } from '~/constants/tabs'
import useMessages from '~/hooks/useMessages'
import * as ROUTES from '~/constants/routes'

import PurchaseOrderDetail from '../components/PurchaseOrderDetail'
import {
  useInvoiceGenerate,
  usePurchaseOrderCancel,
  usePurchaseOrderClose,
  usePurchaseOrderDetail,
  usePurchaseOrderEmailToSupplier,
  usePurchaseOrderGatePassList,
  usePurchaseOrderGenerateGatePass,
  usePurchaseOrderGenerateReport,
  usePurchaseOrderHistory,
  usePurchaseOrderMarkAsPaid,
  usePurchaseOrderProgress
} from '../hooks'
import Title from '../components/Title'
import { PurchaseOrderGatePassSerializer } from '../serializers'

function PurchaseOrderDetailContainer () {
  const { guid, tab } = useParams()
  const messages = useMessages()
  const navigate = useNavigate()
  const fetchHistory = tab === PURCHASE_ORDER_DETAIL_TABS.HISTORY
  const fetchGatePass = tab === PURCHASE_ORDER_DETAIL_TABS.GATE_PASS

  const snackbar = useSnackbar()
  const purchaseOrderDetail = usePurchaseOrderDetail(guid)
  const purchaseOrderEmailToSupplier = usePurchaseOrderEmailToSupplier(guid)
  const purchaseOrderClose = usePurchaseOrderClose(guid)
  const purchaseOrderCancel = usePurchaseOrderCancel(guid)
  const purchaseOrderHistory = usePurchaseOrderHistory(guid, fetchHistory)

  const purchaseOrderGatePassList = usePurchaseOrderGatePassList(guid, fetchGatePass)
  const purchaseOrderGenerateGatePass = usePurchaseOrderGenerateGatePass()
  const purchaseOrderMarkAsPaid = usePurchaseOrderMarkAsPaid(guid)
  const purchaseOrderGenerateReport = usePurchaseOrderGenerateReport(guid)
  const purchaseOrderProgress = usePurchaseOrderProgress(guid)
  const invoiceGenerate = useInvoiceGenerate(guid)

  const handleGenerateGatePass = formValues => {
    const values = { ...formValues, guid }
    return purchaseOrderGenerateGatePass.generate(PurchaseOrderGatePassSerializer(values))
      .then(() => snackbar({ message: messages.GENERATE_SUCCESS }))
      .then(() => purchaseOrderGatePassList.getList())
  }

  const onProgress = () => {
    purchaseOrderProgress.progress()
      .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
      .then(() => purchaseOrderDetail.getDetail())
      .catch(response => {
        const message = <ErrorLink error={response} />
        snackbar({ message, type: ALTER_ERROR })
      })
  }

  const purchaseOrderOnClose = () =>
    purchaseOrderClose.close()
      .then(() => snackbar({ message: messages.CLOSE_SUCCESS }))
      .then(() => purchaseOrderDetail.getDetail())
      .catch(error => snackbar({
        type: ALTER_ERROR,
        message: <ErrorLink error={error} />
      }))

  const onEmailSend = () => {
    purchaseOrderEmailToSupplier.create()
      .then(() => snackbar({ message: messages.EMAIL_SENT }))
      .catch(response => {
        const message = <ErrorLink error={response} />
        snackbar({ message, type: ALTER_ERROR })
      })
  }
  const onDownload = () => {
    purchaseOrderGenerateReport.create()
      .then(() => snackbar({ message: messages.GENERATE_SUCCESS }))
      .catch(response => {
        const message = <ErrorLink error={response} />
        snackbar({ message, type: ALTER_ERROR })
      })
  }

  const purchaseOrderOnCancel = () =>
    purchaseOrderCancel.cancel()
      .then(() => snackbar({ message: messages.CANCEL_SUCCESS }))
      .then(() => purchaseOrderDetail.getDetail())
      .catch(error => snackbar({
        type: ALTER_ERROR,
        message: <ErrorLink error={error} />
      }))

  const handleMarkAsPaid = () =>
    purchaseOrderMarkAsPaid.mark()
      .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
      .then(() => purchaseOrderDetail.getDetail())
      .catch(error => snackbar({
        type: ALTER_ERROR,
        message: <ErrorLink error={error} />
      }))

  const handleInvoiceGenerate = () =>
    invoiceGenerate.generate()
      .then(() => snackbar({ message: messages.NOTIFICATION_WAIT }))
      .catch(response => {
        const error = getDataFromError(response)
        return Promise.reject(error)
      })

  const onReturnCreate = () => {
    const supplierReturnCreatePath = `${ROUTES.SUPPLIER_RETURN_CREATE_PATH}?purchaseGuid=${guid}`
    navigate(supplierReturnCreatePath)
  }

  const isLoading = (
    purchaseOrderDetail.isLoading ||
    purchaseOrderProgress.isLoading ||
    purchaseOrderClose.isLoading ||
    purchaseOrderCancel.isLoading ||
    purchaseOrderMarkAsPaid.isLoading ||
    purchaseOrderGenerateGatePass.isLoading
  )

  const number = prop('number', purchaseOrderDetail.detail)

  const pageTitle = (
    <Title number={number} />
  )
  const pageTitleLoading = purchaseOrderDetail.isLoading

  const breadcrumbs = { title: number }

  return (
    <DashboardLayout
      title={number}
      activeNav={NAV.PURCHASE_ORDER}
      isLoading={isLoading}
      breadcrumbs={breadcrumbs}
    >
      <PurchaseOrderDetail
        tab={tab}
        detail={purchaseOrderDetail.detail}
        pageTitle={pageTitle}
        pageTitleLoading={pageTitleLoading}
        isLoading={isLoading}
        purchaseOrderOnClose={purchaseOrderOnClose}
        purchaseOrderOnCancel={purchaseOrderOnCancel}
        onEmailSend={onEmailSend}
        onDownload={onDownload}
        onProgress={onProgress}
        onMarkAsPaid={handleMarkAsPaid}
        onInvoiceGenerate={handleInvoiceGenerate}
        purchaseHistory={purchaseOrderHistory}
        purchaseGatePassList={purchaseOrderGatePassList}
        onGatepassGenerate={handleGenerateGatePass}
        onReturnCreate={onReturnCreate}
      />
    </DashboardLayout>
  )
}

export default PurchaseOrderDetailContainer
