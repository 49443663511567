import React from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'

import PageTitle from '~/components/PageTitle'
import { DiscardButton, SaveButton } from '~/components/Buttons'
import Container, { Content, Header } from '~/components/Container'
import { withForm } from '~/components/Form'
import * as NAV from '~/constants/nav-titles'
import { ROLE_TABS } from '~/constants/tabs'

import RoleUpdateFormTabs from './RoleUpdateFormTabs'
import RoleUpdateFormGeneral from './RoleUpdateFormGeneral'
import RoleUpdateFormPermission from './RoleUpdateFormPermission'

export const PERM_UPDATE_FIELDS = [
  'name',
  'description',
  'permissions',
]

function RoleUpdateForm ({ title, isLoading, form, pageTitleLoading }) {
  const { tab } = useParams()
  const { handleSubmit } = form
  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={title}
            parentTitle={NAV.ROLES}
            pageTitleLoading={pageTitleLoading}
            rightButton={(
              <>
                <DiscardButton disabled={isLoading} />
                <SaveButton disabled={isLoading} />
              </>
            )}
          />
        </Header>

        <Content>
          <RoleUpdateFormTabs value={tab} />

          <Divider />
          <Box mb={3} />

          {ROLE_TABS.GENERAL === tab && <RoleUpdateFormGeneral />}
          {ROLE_TABS.PERMISSIONS === tab && <RoleUpdateFormPermission />}
          <Box mb={3} />
        </Content>
      </Container>
    </form>
  )
}

RoleUpdateForm.propTypes = {
  title: PropTypes.string,
  pageTitleLoading: PropTypes.bool.isRequired,
  form: PropTypes.object,
  isLoading: PropTypes.bool.isRequired
}

export default withForm(RoleUpdateForm)
