import React from 'react'
import PropTypes from 'prop-types'
import { useDeepCompareEffect } from 'storfox-api-hooks'
import { isEmpty, propOr } from 'ramda'
import { red } from '@mui/material/colors'
import { useField } from 'react-final-form'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Button, styled } from '@mui/material'

import Dropzone from './Dropzone'
import ImageItem from './Image'

import { useSingleUpload } from '../../hooks'
import { createMediumImageUrl, createOriginImageUrl } from '../../utils'

const ButtonStyled = styled(Button)(({ theme }) => ({
  background: red[50],
  color: theme.palette.error.main,
  '&:hover': {
    background: red[100]
  },
  '&.Mui-disabled': {
    background: red[100],
    color: theme.palette.error.main,
    opacity: '0.4'
  }
}))

function SingleImageUpload ({ className, name, accept, original, ...props }) {
  const field = useField(name)
  const onChange = field.input.onChange
  const value = field.input.value

  const onUpload = (data, onProgress) => {
    return props.onUpload(data, onProgress)
      .then(data => {
        onChange(data)
        return data
      })
  }

  const [handleUpload, file, setFile] = useSingleUpload({ onUpload, accept })

  useDeepCompareEffect(() => {
    if (value) {
      const mdSrc = original ? createOriginImageUrl(value) : createMediumImageUrl(value)
      setFile(file => {
        const src = propOr(mdSrc, 'src', file)
        return { obj: null, src }
      })
    }
  }, [value, original])

  const handleRemove = () => {
    setFile({})
    onChange(null)
  }

  return (
    <Box position="relative" className={className}>
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <ButtonStyled
          startIcon={<DeleteIcon />}
          onClick={handleRemove}
          disabled={isEmpty(file)}
          variant="contained"
        >
          Delete
        </ButtonStyled>
      </Box>
      {!isEmpty(file)
        ? (
          <Box position="relative">
            <ImageItem file={file} />
          </Box>
        )
        : (
          <Dropzone onDrop={handleUpload} accept={accept} />
        )}
    </Box>
  )
}

SingleImageUpload.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  original: PropTypes.bool,
  onUpload: PropTypes.func.isRequired,
  accept: PropTypes.string
}

export default SingleImageUpload
