import React from 'react'
import { useTranslation } from 'react-i18next'

import * as API from '~/constants/api'
import SearchField from '~/components/Fields/SearchField'

function ChannelField ({ ...props }) {
  const { t } = useTranslation()

  return (
    <SearchField
      api={API.SALE_ORDER_CHANNEL}
      name="channel"
      data-cy="channel"
      label={t('Channel')}
      params={{
        active: true
      }}
      {...props}
    />
  )
}

export default ChannelField
