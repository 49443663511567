import React from 'react'
import PropTypes from 'prop-types'
import Divider from '@mui/material/Divider'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

import Tabs from '~/components/Tabs'
import { VARIANT_DETAIL_PATH } from '~/constants/routes'
import { VARIANT_DETAIL_TABS } from '~/constants/tabs'

function VariantDetailTabs ({ value, isMultiVariant }) {
  const navigate = useNavigate()
  const { id, productId } = useParams()
  const onChange = (event, tab) => {
    const redirect = generatePath(VARIANT_DETAIL_PATH, { id, productId, tab })
    navigate({
      pathname: redirect,
      search: `?isMultiVariant=${isMultiVariant}`
    }, { replace: true })
  }

  const tabs = [
    { value: VARIANT_DETAIL_TABS.GENERAL, label: 'General' },
    { value: VARIANT_DETAIL_TABS.STOCK, label: 'Stock' },
    { value: VARIANT_DETAIL_TABS.SUPPLIERS, label: 'Suppliers' },
    { value: VARIANT_DETAIL_TABS.HISTORY, label: 'History' },
    { value: VARIANT_DETAIL_TABS.INTERNATIONAL, label: 'International' },
    { value: VARIANT_DETAIL_TABS.INVENTORY_LOG, label: 'Inventory Log' }
  ]

  return (
    <>
      <Tabs tabs={tabs} value={value} onChange={onChange} />
      <Divider />
    </>
  )
}

VariantDetailTabs.propTypes = {
  value: PropTypes.string.isRequired,
  isMultiVariant: PropTypes.bool.isRequired
}

export default VariantDetailTabs
