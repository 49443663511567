import React from 'react'
import { prop } from 'ramda'

import * as API from '~/constants/api'
import SearchField from '~/components/Fields/SearchField'

function CountryField ({ ...props }) {
  const getOptionValue = value => {
    const id = prop('id', value)
    const guid = prop('guid', value)
    const name = prop('name', value)
    const code = prop('code', value)

    return { id, guid, name, code }
  }

  return (
    <SearchField
      {...props}
      api={`${API.REGION_LIST}?type=country`}
      getOptionValue={getOptionValue}
    />
  )
}

export default CountryField
