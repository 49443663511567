import React from 'react'
import { path } from 'ramda'
import { generatePath, useNavigate } from 'react-router-dom'
import { useSnackbar } from 'storfox-snackbar'
import { useTranslation } from 'react-i18next'

import { DashboardLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'
import { ROLE_TABS } from '~/constants/tabs'

import { RoleCreateForm } from '../components/RoleCreate'
import { useRoleCreate } from '../hooks'
import { RoleCreateSerializer } from '../serializers'

function RoleCreateContainer () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const roleCreate = useRoleCreate()

  const title = 'New Role'

  const message = t('Successfully created')
  const handleSubmit = formValues =>
    roleCreate.create(RoleCreateSerializer(formValues))
      .then(data => {
        const params = {
          guid: path(['result', 'guid'], data),
          tab: ROLE_TABS.GENERAL
        }
        const redirect = generatePath(ROUTES.ROLE_UPDATE_PATH, params)

        navigate(redirect)
      })
      .then(() => snackbar({ message }))

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      isLoading={roleCreate.isLoading}
      activeNav={NAV.ROLES}
      breadcrumbs={breadcrumbs}
    >
      <RoleCreateForm
        title={title}
        onSubmit={handleSubmit}
        isLoading={roleCreate.isLoading}
      />
    </DashboardLayout>
  )
}

export default RoleCreateContainer
