import React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { path } from 'ramda'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import { WAREHOUSE_TABS } from '~/constants/tabs'

import { useZoneDetail, useZoneRemove } from '../hooks'
import ZoneDetail from '../components/ZoneDetail'

function ZoneUpdateContainer () {
  const navigate = useNavigate()
  const { guid } = useParams()

  const zoneDetail = useZoneDetail(guid)
  const zoneRemove = useZoneRemove(guid)

  const warehouseGuid = path(['warehouse', 'guid'], zoneDetail.detail)
  const warehouseName = path(['warehouse', 'name'], zoneDetail.detail)

  const handleRemove = () =>
    zoneRemove.delete()
      .then(() => {
        const params = { guid: warehouseGuid, tab: WAREHOUSE_TABS.ZONE }
        const zoneListPath = generatePath(ROUTES.WAREHOUSE_DETAIL_PATH, params)

        return navigate(zoneListPath, { replace: true })
      })

  const name = path(['detail', 'name'], zoneDetail)
  const title = zoneDetail.isLoading ? 'Zones' : name

  const breadcrumbs = {
    title,
    params: [
      { guid: warehouseGuid, tab: WAREHOUSE_TABS.ZONE, title: warehouseName }
    ]
  }

  return (
    <DashboardLayout
      title={title}
      isLoading={zoneDetail.isLoading}
      activeNav={NAV.WAREHOUSE}
      breadcrumbs={breadcrumbs}
    >
      <ZoneDetail
        pageTitle={title}
        pageTitleLoading={zoneDetail.isLoading}
        detail={zoneDetail.detail}
        onRemove={handleRemove}
        isLoading={zoneDetail.isLoading}
      />
    </DashboardLayout>
  )
}

export default ZoneUpdateContainer
