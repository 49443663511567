import { sprintf } from 'sprintf-js'
import { usePickSearchParams } from 'storfox-route-hooks'
import { useCreate, useDelete, useDetail, useList, useUpdate } from 'storfox-api-hooks'

import { orderingToSnackCase } from '~/utils'
import * as API from '~/constants/api'

export const useAdjustmentReasonList = () => {
  const searchParams = usePickSearchParams()
  return useList(API.ADJUSTMENT_REASON_LIST, orderingToSnackCase(searchParams))
}

export const useAdjustmentReasonCreate = () => {
  return useCreate(API.ADJUSTMENT_REASON_CREATE)
}

export const useAdjustmentReasonBulkRemove = () => {
  return useDelete(API.ADJUSTMENT_REASON_BULK_REMOVE)
}

export const useAdjustmentReasonUpdate = id => {
  const url = sprintf(API.ADJUSTMENT_REASON_UPDATE, id)
  return useUpdate(url)
}

export const useAdjustmentReasonDetail = id => {
  const url = sprintf(API.ADJUSTMENT_REASON_DETAIL, id)
  return useDetail(url)
}
