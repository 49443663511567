import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import ProductListContainer from './containers/ProductListContainer'
import ProductCreateContainer from './containers/ProductCreateContainer'
import ProductDetailContainer from './containers/ProductDetailContainer'
import ProductUpdateContainer from './containers/ProductUpdateContainer'
import ProductImportContainer from './containers/ProductImportContainer'

export default () => {
  return [
    {
      layout: AuthLayout,
      path: ROUTES.PRODUCT_CREATE_PATH,
      component: ProductCreateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.PRODUCT_DETAIL_PATH,
      component: ProductDetailContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.PRODUCT_UPDATE_PATH,
      component: ProductUpdateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.PRODUCT_LIST_PATH,
      component: ProductListContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.PRODUCT_IMPORT_PATH,
      component: ProductImportContainer
    }
  ]
}
