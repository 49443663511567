import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'
import * as CONFIGURATION from '~/constants/configuration-titles'

// DASHBOARD
export const OVERVIEW_PATHS = {}

export const SALES_PATHS = {}

export const FULFILLMENT_PATHS = {}

// CATALOG
export const PRODUCT_PATHS = {
  [ROUTES.PRODUCT_CREATE_PATH]: [
    { title: NAV.PRODUCTS, href: ROUTES.PRODUCT_LIST_PATH, dynamic: false }
  ],
  [ROUTES.PRODUCT_DETAIL_PATH]: [
    { title: NAV.PRODUCTS, href: ROUTES.PRODUCT_LIST_PATH, dynamic: false }
  ],
  [ROUTES.PRODUCT_UPDATE_PATH]: [
    { title: NAV.PRODUCTS, href: ROUTES.PRODUCT_LIST_PATH, dynamic: false },
    { title: NAV.PRODUCTS, href: ROUTES.PRODUCT_DETAIL_PATH, dynamic: true }
  ],
  [ROUTES.PRODUCT_IMPORT_PATH]: [
    { title: NAV.PRODUCTS, href: ROUTES.PRODUCT_LIST_PATH, dynamic: false }
  ],
}

export const REPORT_PATHS = {
  [ROUTES.REPORT_SALES_MARGIN_LIST_PATH]: [
    { title: NAV.REPORTS, href: ROUTES.REPORT_LIST_PATH, dynamic: false }
  ],
  [ROUTES.REPORT_STOCK_AGING_LIST_PATH]: [
    { title: NAV.REPORTS, href: ROUTES.REPORT_LIST_PATH, dynamic: false }
  ],
  [ROUTES.TPL_BILLING_LIST_PATH]: [
    { title: NAV.REPORTS, href: ROUTES.REPORT_LIST_PATH, dynamic: false }
  ],
  [ROUTES.TPL_BILLING_DETAIL_PATH]: [
    { title: NAV.REPORTS, href: ROUTES.REPORT_LIST_PATH, dynamic: false },
    { title: NAV.TPL_BILLING, href: ROUTES.TPL_BILLING_LIST_PATH, dynamic: false }
  ],
  [ROUTES.TPL_BILLING_ITEM_PATH]: [
    { title: NAV.REPORTS, href: ROUTES.REPORT_LIST_PATH, dynamic: false },
    { title: NAV.TPL_BILLING, href: ROUTES.TPL_BILLING_LIST_PATH, dynamic: false }
  ],
  [ROUTES.SALES_OVERVIEW_LIST_PATH]: [
    { title: NAV.REPORTS, href: ROUTES.REPORT_LIST_PATH, dynamic: false }
  ],
  [ROUTES.TEAM_METRICS_LIST_PATH]: [
    { title: NAV.REPORTS, href: ROUTES.REPORT_LIST_PATH, dynamic: false }
  ]
}

export const VARIANT_PATHS = {
  [ROUTES.VARIANT_UPDATE_PATH]: [
    { title: NAV.VARIANTS, href: ROUTES.VARIANT_LIST_PATH }
  ],
  [ROUTES.VARIANT_DETAIL_PATH]: [
    { title: NAV.VARIANTS, href: ROUTES.VARIANT_LIST_PATH }
  ]
}

export const UNIT_PATHS = {
  [ROUTES.UNIT_HISTORY_PATH]: [
    { title: NAV.UNITS, href: ROUTES.UNIT_LIST_PATH }
  ]
}

export const BUNDLE_PATHS = {
  [ROUTES.BUNDLE_CREATE_PATH]: [
    { title: NAV.BUNDLES, href: ROUTES.BUNDLE_LIST_PATH }
  ],
  [ROUTES.BUNDLE_DETAIL_PATH]: [
    { title: NAV.BUNDLES, href: ROUTES.BUNDLE_LIST_PATH }
  ],
  [ROUTES.BUNDLE_UPDATE_PATH]: [
    { title: NAV.BUNDLES, href: ROUTES.BUNDLE_LIST_PATH },
    { title: NAV.BUNDLES, href: ROUTES.BUNDLE_DETAIL_PATH, dynamic: true }
  ]
}

export const CATEGORY_PATHS = {
  [ROUTES.CATEGORY_CREATE_PATH]: [
    { title: NAV.CATEGORIES, href: ROUTES.CATEGORY_LIST_PATH, dynamic: false }
  ],
  [ROUTES.CATEGORY_UPDATE_PATH]: [
    { title: NAV.CATEGORIES, href: ROUTES.CATEGORY_LIST_PATH, dynamic: false }
  ],
  [ROUTES.CATEGORY_DETAIL_PATH]: [
    { title: NAV.CATEGORIES, href: ROUTES.CATEGORY_LIST_PATH, dynamic: false }
  ]
}

export const BRAND_PATHS = {
  [ROUTES.BRAND_CREATE_PATH]: [
    { title: NAV.BRANDS, href: ROUTES.BRAND_LIST_PATH }
  ],
  [ROUTES.BRAND_UPDATE_PATH]: [
    { title: NAV.BRANDS, href: ROUTES.BRAND_LIST_PATH }
  ],
  [ROUTES.BRAND_DETAIL_PATH]: [
    { title: NAV.BRANDS, href: ROUTES.CATEGORY_LIST_PATH }
  ]
}

export const COLLECTION_PATHS = {}

export const LISTING_PATHS = {
  [ROUTES.LISTING_DETAIL_PATH]: [
    { title: NAV.LISTING, href: ROUTES.LISTING_LIST_PATH, dynamic: false }
  ],
  [ROUTES.LISTING_UPDATE_PATH]: [
    { title: NAV.LISTING, href: ROUTES.LISTING_LIST_PATH, dynamic: false },
    { title: NAV.LISTING, href: ROUTES.LISTING_DETAIL_PATH, dynamic: true }
  ]
}

// PURCHASES
export const PURCHASE_ORDER_PATHS = {
  [ROUTES.PURCHASE_ORDER_CREATE_PATH]: [
    { title: NAV.PURCHASE_ORDER, href: ROUTES.PURCHASE_ORDER_LIST_PATH, dynamic: false }
  ],
  [ROUTES.PURCHASE_ORDER_DETAIL_PATH]: [
    { title: NAV.PURCHASE_ORDER, href: ROUTES.PURCHASE_ORDER_LIST_PATH, dynamic: false }
  ],
  [ROUTES.PURCHASE_ORDER_UPDATE_PATH]: [
    { title: NAV.PURCHASE_ORDER, href: ROUTES.PURCHASE_ORDER_LIST_PATH, dynamic: false },
    { title: NAV.PURCHASE_ORDER, href: ROUTES.PURCHASE_ORDER_DETAIL_PATH, dynamic: true }
  ]
}

export const SUPPLIER_PATHS = {
  [ROUTES.SUPPLIER_CREATE_PATH]: [
    { title: NAV.SUPPLIERS, href: ROUTES.SUPPLIER_LIST_PATH, dynamic: false }
  ],
  [ROUTES.SUPPLIER_DETAIL_PATH]: [
    { title: NAV.SUPPLIERS, href: ROUTES.SUPPLIER_LIST_PATH, dynamic: false }
  ],
  [ROUTES.SUPPLIER_UPDATE_PATH]: [
    { title: NAV.SUPPLIERS, href: ROUTES.SUPPLIER_LIST_PATH, dynamic: false },
    { title: NAV.SUPPLIERS, href: ROUTES.SUPPLIER_DETAIL_PATH, dynamic: true }
  ]
}

export const SUPPLIER_RETURN_PATHS = {
  [ROUTES.SUPPLIER_RETURN_CREATE_PATH]: [
    { title: NAV.SUPPLIER_RETURNS, href: ROUTES.SUPPLIER_RETURN_LIST_PATH, dynamic: false }
  ],
  [ROUTES.SUPPLIER_RETURN_DETAIL_PATH]: [
    { title: NAV.SUPPLIER_RETURNS, href: ROUTES.SUPPLIER_RETURN_LIST_PATH, dynamic: false }
  ],
  [ROUTES.SUPPLIER_RETURN_UPDATE_PATH]: [
    { title: NAV.SUPPLIER_RETURNS, href: ROUTES.SUPPLIER_RETURN_LIST_PATH, dynamic: false },
    { title: NAV.SUPPLIER_RETURNS, href: ROUTES.SUPPLIER_RETURN_DETAIL_PATH, dynamic: true }
  ]
}

// STOCK CONTROL

export const RECEIVE_PATHS = {
  [ROUTES.RECEIVE_PURCHASE_ORDER_DETAIL_PATH]: [
    { title: NAV.RECEIVE, href: ROUTES.RECEIVE_LIST_PATH }
  ],
  [ROUTES.RECEIVE_PO_SCAN_DETAIL_PATH]: [
    { title: NAV.RECEIVE, href: ROUTES.RECEIVE_LIST_PATH }
  ],
  [ROUTES.RECEIVE_TRANSFER_DETAIL_PATH]: [
    { title: NAV.RECEIVE, href: ROUTES.RECEIVE_LIST_PATH }
  ],
  [ROUTES.RECEIVE_RETURN_DETAIL_PATH]: [
    { title: NAV.RECEIVE, href: ROUTES.RECEIVE_LIST_PATH }
  ]
}

export const PUTAWAY_PATHS = {
  [ROUTES.V2_PUTAWAY_DETAIL_PATH]:[
    { title: NAV.PUTAWAY, href: ROUTES.V2_PUTAWAY_LIST_PATH }
  ]
}

export const ADJUSTMENT_PATHS = {
  [ROUTES.ADJUSTMENT_CREATE_PATH]: [
    { title: NAV.ADJUSTMENT, href: ROUTES.ADJUSTMENT_LIST_PATH }
  ],
  [ROUTES.ADJUSTMENT_DETAIL_PATH]: [
    { title: NAV.ADJUSTMENT, href: ROUTES.ADJUSTMENT_LIST_PATH }
  ],
  [ROUTES.ADJUSTMENT_UPDATE_PATH]: [
    { title: NAV.ADJUSTMENT, href: ROUTES.ADJUSTMENT_LIST_PATH },
    { title: NAV.ADJUSTMENT, href: ROUTES.ADJUSTMENT_DETAIL_PATH, dynamic: true }
  ],
  [ROUTES.ADJUSTMENT_IMPORT_PATH]: [
    { title: NAV.ADJUSTMENT, href: ROUTES.ADJUSTMENT_LIST_PATH }
  ]
}

export const REPLENISHMENT_PATHS = {}

export const STOCK_COUNT_PATHS = {
  [ROUTES.STOCK_COUNT_CREATE_PATH]: [
    { title: NAV.STOCK_COUNT, href: ROUTES.STOCK_COUNT_LIST_PATH }
  ],
  [ROUTES.STOCK_COUNT_UPDATE_PATH]: [
    { title: NAV.STOCK_COUNT, href: ROUTES.STOCK_COUNT_LIST_PATH }
  ]
}

export const MOVEMENT_PATHS = {
  [ROUTES.MOVEMENT_SCAN_CREATE_PATH]: [
    { title: NAV.MOVEMENT, href: ROUTES.MOVEMENT_LIST_PATH }
  ],
  [ROUTES.MOVEMENT_BROWSE_CREATE_PATH]: [
    { title: NAV.MOVEMENT, href: ROUTES.MOVEMENT_LIST_PATH }
  ],
  [ROUTES.MOVEMENT_DETAIL_PATH]: [
    { title: NAV.MOVEMENT, href: ROUTES.MOVEMENT_LIST_PATH }
  ],
  [ROUTES.MOVEMENT_SCAN_UPDATE_PATH]: [
    { title: NAV.MOVEMENT, href: ROUTES.MOVEMENT_LIST_PATH }
  ],
  [ROUTES.MOVEMENT_BROWSE_UPDATE_PATH]: [
    { title: NAV.MOVEMENT, href: ROUTES.MOVEMENT_LIST_PATH }
  ]
}

export const TRANSFER_PATHS = {
  [ROUTES.TRANSFER_CREATE_PATH]: [
    { title: NAV.TRANSFER, href: ROUTES.TRANSFER_LIST_PATH }
  ],
  [ROUTES.TRANSFER_DETAIL_PATH]: [
    { title: NAV.TRANSFER, href: ROUTES.TRANSFER_LIST_PATH }
  ],
  [ROUTES.TRANSFER_UPDATE_PATH]: [
    { title: NAV.TRANSFER, href: ROUTES.TRANSFER_LIST_PATH }
  ]
}

// FULFILLMENT
export const CUSTOMER_PATHS = {
  [ROUTES.CUSTOMER_CREATE_PATH]: [
    { title: NAV.CUSTOMERS, href: ROUTES.CUSTOMER_LIST_PATH, dynamic: false }
  ],
  [ROUTES.CUSTOMER_DETAIL_PATH]: [
    { title: NAV.CUSTOMERS, href: ROUTES.CUSTOMER_LIST_PATH, dynamic: false },
  ],
  [ROUTES.CUSTOMER_UPDATE_PATH]: [
    { title: NAV.CUSTOMERS, href: ROUTES.CUSTOMER_LIST_PATH, dynamic: false },
    { title: NAV.CUSTOMERS, href: ROUTES.CUSTOMER_DETAIL_PATH, dynamic: true }
  ]
}

export const SALE_ORDER_PATHS = {
  [ROUTES.SALE_ORDER_CREATE_PATH]: [
    { title: NAV.SALE_ORDERS, href: ROUTES.SALE_ORDER_LIST_PATH, dynamic: false }
  ],
  [ROUTES.SALE_ORDER_DETAIL_PATH]: [
    { title: NAV.SALE_ORDERS, href: ROUTES.SALE_ORDER_LIST_PATH, dynamic: false }
  ],
  [ROUTES.SALE_ORDER_UPDATE_PATH]: [
    { title: NAV.SALE_ORDERS, href: ROUTES.SALE_ORDER_LIST_PATH, dynamic: false },
    { title: NAV.SALE_ORDERS, href: ROUTES.SALE_ORDER_DETAIL_PATH, dynamic: true }
  ],
  [ROUTES.SALE_ORDER_IMPORT_PATH]: [
    { title: NAV.SALE_ORDERS, href: ROUTES.SALE_ORDER_LIST_PATH, dynamic: false }
  ]
}

export const INVOICE_PATHS = {
  [ROUTES.INVOICE_DETAIL_PATH]: [
    { title: NAV.INVOICES, href: ROUTES.INVOICE_LIST_PATH }
  ]
}

export const PICKLIST_PATHS = {
  [ROUTES.PICKLIST_UPDATE_PATH]: [
    { title: NAV.PICKLISTS, href: ROUTES.PICKLIST_LIST_PATH }
  ]
}

export const PACKING_PATHS = {
  [ROUTES.PACKING_UPDATE_PATH]: [
    { title: NAV.PACKING, href: ROUTES.PACKING_LIST_PATH }
  ],
  [ROUTES.PACKING_DETAIL_PATH]: [
    { title: NAV.PACKING, href: ROUTES.PACKING_LIST_PATH }
  ]
}

// SHIPMENT
export const SHIPMENT_PATHS = {
  [ROUTES.SHIPMENT_UPDATE_PATH]: [
    { title: NAV.SHIPMENTS, href: ROUTES.SHIPMENT_LIST_PATH },
    { title: NAV.SHIPMENTS, href: ROUTES.SHIPMENT_DETAIL_PATH, dynamic: true }
  ],
  [ROUTES.SHIPMENT_DETAIL_PATH]: [
    { title: NAV.SHIPMENTS, href: ROUTES.SHIPMENT_LIST_PATH }
  ],
}

// RETURN
export const RETURN_PATHS = {
  [ROUTES.RETURN_CREATE_PATH]: [
    { title: NAV.SALE_RETURNS, href: ROUTES.RETURN_LIST_PATH }
  ],
  [ROUTES.RETURN_DETAIL_PATH]: [
    { title: NAV.SALE_RETURNS, href: ROUTES.RETURN_LIST_PATH }
  ],
  [ROUTES.RETURN_UPDATE_PATH]: [
    { title: NAV.SALE_RETURNS, href: ROUTES.RETURN_LIST_PATH },
    { title: NAV.SALE_RETURNS, href: ROUTES.RETURN_DETAIL_PATH, dynamic: true }
  ]
}

// GENERAL
export const WAREHOUSE_PATHS = {
  [ROUTES.WAREHOUSE_DETAIL_PATH]: [
    { title: NAV.WAREHOUSES, href: ROUTES.WAREHOUSE_LIST_PATH, dynamic: false }
  ],
  [ROUTES.SHARED_WAREHOUSE_DETAIL_PATH]: [
    { title: NAV.WAREHOUSES, href: ROUTES.WAREHOUSE_LIST_PATH, dynamic: false }
  ],
  [ROUTES.WAREHOUSE_UPDATE_PATH]: [
    { title: NAV.WAREHOUSES, href: ROUTES.WAREHOUSE_LIST_PATH, dynamic: false },
    { title: NAV.WAREHOUSES, href: ROUTES.WAREHOUSE_DETAIL_PATH, dynamic: true },
  ],
  [ROUTES.WAREHOUSE_CREATE_PATH]: [
    { title: NAV.WAREHOUSES, href: ROUTES.WAREHOUSE_LIST_PATH, dynamic: false }
  ]
}

export const AREA_PATHS = {
  [ROUTES.AREA_CREATE_PATH]: [
    { title: NAV.WAREHOUSES, href: ROUTES.WAREHOUSE_LIST_PATH, dynamic: false },
    { title: NAV.WAREHOUSES, href: ROUTES.WAREHOUSE_DETAIL_PATH, dynamic: true }
  ],
  [ROUTES.AREA_DETAIL_PATH]: [
    { title: NAV.WAREHOUSES, href: ROUTES.WAREHOUSE_LIST_PATH, dynamic: false },
    { title: NAV.WAREHOUSES, href: ROUTES.WAREHOUSE_DETAIL_PATH, dynamic: true },
  ],
  [ROUTES.AREA_UPDATE_PATH]: [
    { title: NAV.WAREHOUSES, href: ROUTES.WAREHOUSE_LIST_PATH, dynamic: false },
    { title: NAV.WAREHOUSES, href: ROUTES.WAREHOUSE_DETAIL_PATH, dynamic: true },
    { title: NAV.AREAS, href: ROUTES.AREA_DETAIL_PATH, dynamic: true }
  ],
}

export const ZONE_PATHS = {
  [ROUTES.ZONE_CREATE_PATH]: [
    { title: NAV.WAREHOUSES, href: ROUTES.WAREHOUSE_LIST_PATH, dynamic: false },
    { title: NAV.WAREHOUSES, href: ROUTES.WAREHOUSE_DETAIL_PATH, dynamic: true }
  ],
  [ROUTES.ZONE_DETAIL_PATH]: [
    { title: NAV.WAREHOUSES, href: ROUTES.WAREHOUSE_LIST_PATH, dynamic: false },
    { title: NAV.WAREHOUSES, href: ROUTES.WAREHOUSE_DETAIL_PATH, dynamic: true }
  ],
  [ROUTES.ZONE_UPDATE_PATH]: [
    { title: NAV.WAREHOUSES, href: ROUTES.WAREHOUSE_LIST_PATH, dynamic: false },
    { title: NAV.WAREHOUSES, href: ROUTES.WAREHOUSE_DETAIL_PATH, dynamic: true },
    { title: NAV.ZONES, href: ROUTES.ZONE_DETAIL_PATH, dynamic: true }
  ]
}

export const LOCATION_PATHS = {
  [ROUTES.LOCATION_CREATE_PATH]: [
    { title: NAV.WAREHOUSES, href: ROUTES.WAREHOUSE_LIST_PATH, dynamic: false },
    { title: NAV.WAREHOUSES, href: ROUTES.WAREHOUSE_DETAIL_PATH, dynamic: true }
  ],
  [ROUTES.LOCATION_DETAIL_PATH]: [
    { title: NAV.WAREHOUSES, href: ROUTES.WAREHOUSE_LIST_PATH, dynamic: false },
    { title: NAV.WAREHOUSES, href: ROUTES.WAREHOUSE_DETAIL_PATH, dynamic: true },
  ],
  [ROUTES.LOCATION_UPDATE_PATH]: [
    { title: NAV.WAREHOUSES, href: ROUTES.WAREHOUSE_LIST_PATH, dynamic: false },
    { title: NAV.WAREHOUSES, href: ROUTES.WAREHOUSE_DETAIL_PATH, dynamic: true },
    { title: NAV.LOCATIONS, href: ROUTES.LOCATION_DETAIL_PATH, dynamic: true }
  ],
}

export const CONTAINER_PATHS = {
  [ROUTES.CONTAINER_CREATE_PATH]: [
    { title: NAV.WAREHOUSES, href: ROUTES.WAREHOUSE_LIST_PATH, dynamic: false },
    { title: NAV.WAREHOUSES, href: ROUTES.WAREHOUSE_DETAIL_PATH, dynamic: true }
  ],
  [ROUTES.CONTAINER_DETAIL_PATH]: [
    { title: NAV.WAREHOUSES, href: ROUTES.WAREHOUSE_LIST_PATH, dynamic: false },
    { title: NAV.WAREHOUSES, href: ROUTES.WAREHOUSE_DETAIL_PATH, dynamic: true },
  ]
}

// SETTINGS

export const ACCOUNT_PATHS = {}

export const INTEGRATION_PATHS = {
  [ROUTES.INTEGRATION_CREATE_PATH]: [
    { title: NAV.INTEGRATION, href: ROUTES.INTEGRATION_LIST_PATH }
  ],
  [ROUTES.INTEGRATION_UPDATE_PATH]: [
    { title: NAV.INTEGRATION, href: ROUTES.INTEGRATION_LIST_PATH }
  ],
  [ROUTES.INTEGRATION_TYPE_PATH]: [
    { title: NAV.INTEGRATION, href: ROUTES.INTEGRATION_LIST_PATH }
  ],
  [ROUTES.API_INTEGRATION_CREATE_PATH]: [
    { title: NAV.INTEGRATION, href: ROUTES.INTEGRATION_LIST_PATH }
  ],
  [ROUTES.API_INTEGRATION_UPDATE_PATH]: [
    { title: NAV.INTEGRATION, href: ROUTES.INTEGRATION_LIST_PATH }
  ],
  [ROUTES.MAGENTO_INTEGRATION_CREATE_PATH]: [
    { title: NAV.INTEGRATION, href: ROUTES.INTEGRATION_LIST_PATH }
  ],
  [ROUTES.MAGENTO_INTEGRATION_UPDATE_PATH]: [
    { title: NAV.INTEGRATION, href: ROUTES.INTEGRATION_LIST_PATH }
  ],
  [ROUTES.SHIPOX_INTEGRATION_CREATE_PATH]: [
    { title: NAV.INTEGRATION, href: ROUTES.INTEGRATION_LIST_PATH }
  ],
  [ROUTES.SHIPOX_INTEGRATION_UPDATE_PATH]: [
    { title: NAV.INTEGRATION, href: ROUTES.INTEGRATION_LIST_PATH }
  ],
  [ROUTES.STOREFRONT_INTEGRATION_CREATE_PATH]: [
    { title: NAV.INTEGRATION, href: ROUTES.INTEGRATION_LIST_PATH }
  ],
  [ROUTES.STOREFRONT_INTEGRATION_UPDATE_PATH]: [
    { title: NAV.INTEGRATION, href: ROUTES.INTEGRATION_LIST_PATH }
  ],
  [ROUTES.SHOPIFY_INTEGRATION_CREATE_PATH]: [
    { title: NAV.INTEGRATION, href: ROUTES.INTEGRATION_LIST_PATH }
  ],
  [ROUTES.SHOPIFY_INTEGRATION_UPDATE_PATH]: [
    { title: NAV.INTEGRATION, href: ROUTES.INTEGRATION_LIST_PATH }
  ],
  [ROUTES.SHOPIFY_INSTRUCTION_CONTAINER]: [
    { title: NAV.INTEGRATION, href: ROUTES.INTEGRATION_LIST_PATH }
  ],
  [ROUTES.SHOPIFY_INSTALL_PATH]: [
    { title: NAV.INTEGRATION, href: ROUTES.INTEGRATION_LIST_PATH }
  ],
  [ROUTES.SALLA_INSTRUCTION_CONTAINER]: [
    { title: NAV.INTEGRATION, href: ROUTES.INTEGRATION_LIST_PATH }
  ],
  [ROUTES.SALLA_INSTALL_PATH]: [
    { title: NAV.INTEGRATION, href: ROUTES.INTEGRATION_LIST_PATH }
  ],
  [ROUTES.SALLA_INTEGRATION_CREATE_PATH]: [
    { title: NAV.INTEGRATION, href: ROUTES.INTEGRATION_LIST_PATH }
  ],
  [ROUTES.SALLA_INTEGRATION_UPDATE_PATH]: [
    { title: NAV.INTEGRATION, href: ROUTES.INTEGRATION_LIST_PATH }
  ],
  [ROUTES.ZID_INTEGRATION_APPLICATION_CREATE_PATH]: [
    { title: NAV.INTEGRATION, href: ROUTES.INTEGRATION_LIST_PATH }
  ],
  [ROUTES.ZID_INTEGRATION_UPDATE_PATH]: [
    { title: NAV.INTEGRATION, href: ROUTES.INTEGRATION_LIST_PATH }
  ],
  [ROUTES.POSTMEN_INTEGRATION_CREATE_PATH]: [
    { title: NAV.INTEGRATION, href: ROUTES.INTEGRATION_LIST_PATH }
  ],
  [ROUTES.POSTMEN_INTEGRATION_UPDATE_PATH]: [
    { title: NAV.INTEGRATION, href: ROUTES.INTEGRATION_LIST_PATH }
  ],
  [ROUTES.WOOCOMMERCE_INTEGRATION_CREATE_PATH]: [
    { title: NAV.INTEGRATION, href: ROUTES.INTEGRATION_LIST_PATH }
  ],
  [ROUTES.WOOCOMMERCE_INTEGRATION_UPDATE_PATH]: [
    { title: NAV.INTEGRATION, href: ROUTES.INTEGRATION_LIST_PATH }
  ],
  [ROUTES.WOOCOMMERCE_INSTALL_PATH]: [
    { title: NAV.INTEGRATION, href: ROUTES.INTEGRATION_LIST_PATH }
  ]
}

// CONFIGURATION START

export const CURRENCY_RATE_PATHS = {
  [ROUTES.CURRENCY_RATE_LIST_PATH]: [
    { title: NAV.CONFIGURATION, href: ROUTES.CONFIGURATION_MENU_PATH }
  ],
  [ROUTES.CURRENCY_RATE_CREATE_PATH]: [
    { title: NAV.CONFIGURATION, href: ROUTES.CONFIGURATION_MENU_PATH },
    { title: CONFIGURATION.CURRENCY_RATE, href: ROUTES.CURRENCY_RATE_LIST_PATH }
  ],
  [ROUTES.CURRENCY_RATE_UPDATE_PATH]: [
    { title: NAV.CONFIGURATION, href: ROUTES.CONFIGURATION_MENU_PATH },
    { title: CONFIGURATION.CURRENCY_RATE, href: ROUTES.CURRENCY_RATE_LIST_PATH }
  ],
}

export const ADJUSTMENT_REASON_PATHS = {
  [ROUTES.ADJUSTMENT_REASON_LIST_PATH]: [
    { title: NAV.CONFIGURATION, href: ROUTES.CONFIGURATION_MENU_PATH }
  ],
  [ROUTES.ADJUSTMENT_REASON_CREATE_PATH]: [
    { title: NAV.CONFIGURATION, href: ROUTES.CONFIGURATION_MENU_PATH },
    { title: CONFIGURATION.ADJUSTMENT_REASON, href: ROUTES.ADJUSTMENT_REASON_LIST_PATH }

  ],
  [ROUTES.ADJUSTMENT_REASON_UPDATE_PATH]: [
    { title: NAV.CONFIGURATION, href: ROUTES.CONFIGURATION_MENU_PATH },
    { title: CONFIGURATION.ADJUSTMENT_REASON, href: ROUTES.ADJUSTMENT_REASON_LIST_PATH }
  ],
}

export const TAX_PATHS = {
  [ROUTES.TAX_LIST_PATH]: [
    { title: NAV.CONFIGURATION, href: ROUTES.CONFIGURATION_MENU_PATH }
  ],
  [ROUTES.TAX_CREATE_PATH]: [
    { title: NAV.CONFIGURATION, href: ROUTES.CONFIGURATION_MENU_PATH },
    { title: CONFIGURATION.TAX, href: ROUTES.TAX_LIST_PATH }
  ],
  [ROUTES.TAX_UPDATE_PATH]: [
    { title: NAV.CONFIGURATION, href: ROUTES.CONFIGURATION_MENU_PATH },
    { title: CONFIGURATION.TAX, href: ROUTES.TAX_LIST_PATH }
  ],
}

export const CONDITION_PATHS = {
  [ROUTES.CONDITION_LIST_PATH]: [
    { title: NAV.CONFIGURATION, href: ROUTES.CONFIGURATION_MENU_PATH }
  ],
  [ROUTES.CONDITION_CREATE_PATH]: [
    { title: NAV.CONFIGURATION, href: ROUTES.CONFIGURATION_MENU_PATH },
    { title: CONFIGURATION.PRODUCT_CONDITION, href: ROUTES.CONDITION_LIST_PATH }
  ],
  [ROUTES.CONDITION_UPDATE_PATH]: [
    { title: NAV.CONFIGURATION, href: ROUTES.CONFIGURATION_MENU_PATH },
    { title: CONFIGURATION.PRODUCT_CONDITION, href: ROUTES.CONDITION_LIST_PATH }
  ],
}

export const CONTAINER_TYPE_PATHS = {
  [ROUTES.CONTAINER_TYPE_LIST_PATH]: [
    { title: NAV.CONFIGURATION, href: ROUTES.CONFIGURATION_MENU_PATH }
  ],
  [ROUTES.CONTAINER_TYPE_CREATE_PATH]: [
    { title: NAV.CONFIGURATION, href: ROUTES.CONFIGURATION_MENU_PATH },
    { title: CONFIGURATION.CONTAINER_TYPE, href: ROUTES.CONTAINER_TYPE_LIST_PATH }
  ],
  [ROUTES.CONTAINER_TYPE_UPDATE_PATH]: [
    { title: NAV.CONFIGURATION, href: ROUTES.CONFIGURATION_MENU_PATH },
    { title: CONFIGURATION.CONTAINER_TYPE, href: ROUTES.CONTAINER_TYPE_LIST_PATH }
  ],
}

export const TEMPLATE_PATHS = {}

export const CONTRACT_PATHS = {
  [ROUTES.CONTRACT_LIST_PATH]: [
    { title: NAV.CONFIGURATION, href: ROUTES.CONFIGURATION_MENU_PATH }
  ],
  [ROUTES.CONTRACT_CREATE_PATH]: [
    { title: NAV.CONFIGURATION, href: ROUTES.CONFIGURATION_MENU_PATH },
    { title: CONFIGURATION.CONTRACT, href: ROUTES.CONTRACT_LIST_PATH }
  ],
  [ROUTES.CONTRACT_UPDATE_PATH]: [
    { title: NAV.CONFIGURATION, href: ROUTES.CONFIGURATION_MENU_PATH },
    { title: CONFIGURATION.CONTRACT, href: ROUTES.CONTRACT_LIST_PATH }
  ],
}

export const AUTOMATION_PATHS = {
  [ROUTES.AUTOMATION_LIST_PATH]: [
    { title: NAV.CONFIGURATION, href: ROUTES.CONFIGURATION_MENU_PATH }
  ],
  [ROUTES.AUTOMATION_CREATE_PATH]: [
    { title: NAV.CONFIGURATION, href: ROUTES.CONFIGURATION_MENU_PATH },
    { title: CONFIGURATION.AUTOMATION_RULE, href: ROUTES.AUTOMATION_LIST_PATH }
  ],
  [ROUTES.AUTOMATION_UPDATE_PATH]: [
    { title: NAV.CONFIGURATION, href: ROUTES.CONFIGURATION_MENU_PATH },
    { title: CONFIGURATION.AUTOMATION_RULE, href: ROUTES.AUTOMATION_LIST_PATH }
  ],
}

export const BILLING_PROFILE_PATHS = {
  [ROUTES.BILLING_PROFILE_LIST_PATH]: [
    { title: NAV.CONFIGURATION, href: ROUTES.CONFIGURATION_MENU_PATH }
  ],
  [ROUTES.BILLING_PROFILE_CREATE_PATH]: [
    { title: NAV.CONFIGURATION, href: ROUTES.CONFIGURATION_MENU_PATH },
    { title: CONFIGURATION.BILLING_PROFILE, href: ROUTES.BILLING_PROFILE_LIST_PATH }
  ],
  [ROUTES.BILLING_PROFILE_UPDATE_PATH]: [
    { title: NAV.CONFIGURATION, href: ROUTES.CONFIGURATION_MENU_PATH },
    { title: CONFIGURATION.BILLING_PROFILE, href: ROUTES.BILLING_PROFILE_LIST_PATH }
  ],
}

export const DELIVERY_METHOD_PATHS = {
  [ROUTES.DELIVERY_METHOD_LIST_PATH]: [
    { title: NAV.CONFIGURATION, href: ROUTES.CONFIGURATION_MENU_PATH }
  ],
  [ROUTES.DELIVERY_METHOD_CREATE_PATH]: [
    { title: NAV.CONFIGURATION, href: ROUTES.CONFIGURATION_MENU_PATH },
    { title: CONFIGURATION.DELIVERY_METHOD, href: ROUTES.DELIVERY_METHOD_LIST_PATH }
  ],
  [ROUTES.DELIVERY_METHOD_UPDATE_PATH]: [
    { title: NAV.CONFIGURATION, href: ROUTES.CONFIGURATION_MENU_PATH },
    { title: CONFIGURATION.DELIVERY_METHOD, href: ROUTES.DELIVERY_METHOD_LIST_PATH }
  ],
}

// CONFIGURATION END

export const USER_PATHS = {
  [ROUTES.USER_LIST_PATH]: [],
  [ROUTES.USER_CREATE_PATH]: [
    { title: NAV.USERS, href: ROUTES.USER_LIST_PATH, dynamic: false }
  ],
  [ROUTES.USER_DETAIL_PATH]: [
    { title: NAV.USERS, href: ROUTES.USER_LIST_PATH, dynamic: false }
  ],
  [ROUTES.USER_UPDATE_PATH]: [
    { title: NAV.USERS, href: ROUTES.USER_LIST_PATH, dynamic: false },
    { title: NAV.USERS, href: ROUTES.USER_DETAIL_PATH, dynamic: true }
  ]
}

export const ROLE_PATHS = {
  [ROUTES.ROLE_LIST_PATH]: [],
  [ROUTES.ROLE_CREATE_PATH]: [
    { title: NAV.ROLES, href: ROUTES.ROLE_LIST_PATH }
  ],
  [ROUTES.ROLE_UPDATE_PATH]: [
    { title: NAV.ROLES, href: ROUTES.ROLE_LIST_PATH }
  ]
}

export const BARCODE_PATHS = {
  [ROUTES.BARCODE_GENERATOR_PATH]: []
}

export default {
  ...OVERVIEW_PATHS,
  ...REPORT_PATHS,
  ...SALES_PATHS,
  ...FULFILLMENT_PATHS,
  ...PRODUCT_PATHS,
  ...VARIANT_PATHS,
  ...UNIT_PATHS,
  ...BUNDLE_PATHS,
  ...CATEGORY_PATHS,
  ...BRAND_PATHS,
  ...COLLECTION_PATHS,
  ...LISTING_PATHS,
  ...PURCHASE_ORDER_PATHS,
  ...SUPPLIER_PATHS,
  ...SUPPLIER_RETURN_PATHS,
  ...RECEIVE_PATHS,
  ...PUTAWAY_PATHS,
  ...ADJUSTMENT_PATHS,
  ...REPLENISHMENT_PATHS,
  ...STOCK_COUNT_PATHS,
  ...MOVEMENT_PATHS,
  ...TRANSFER_PATHS,
  ...CUSTOMER_PATHS,
  ...SALE_ORDER_PATHS,
  ...INVOICE_PATHS,
  ...PICKLIST_PATHS,
  ...PACKING_PATHS,
  ...SHIPMENT_PATHS,
  ...RETURN_PATHS,
  ...WAREHOUSE_PATHS,
  ...AREA_PATHS,
  ...ZONE_PATHS,
  ...LOCATION_PATHS,
  ...CONTAINER_PATHS,
  ...ACCOUNT_PATHS,
  ...INTEGRATION_PATHS,
  ...CURRENCY_RATE_PATHS,
  ...ADJUSTMENT_REASON_PATHS,
  ...TAX_PATHS,
  ...CONDITION_PATHS,
  ...CONTAINER_TYPE_PATHS,
  ...TEMPLATE_PATHS,
  ...CONTRACT_PATHS,
  ...AUTOMATION_PATHS,
  ...BILLING_PROFILE_PATHS,
  ...DELIVERY_METHOD_PATHS,
  ...USER_PATHS,
  ...ROLE_PATHS,
  ...BARCODE_PATHS
}
