import React from 'react'
import SelectField from 'storfox-form-fields/src/SelectField'

const OPTIONS = [
  { value: null, name: 'All' },
  { value: 'created', name: 'Created' },
  { value: 'completed', name: 'Completed' }
]

function LotStatusField (props) {
  return (
    <SelectField
      name="status"
      label="Status"
      options={OPTIONS}
      {...props}
    />
  )
}

export default LotStatusField
