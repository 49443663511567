import React from 'react'
import { prop, propOr } from 'ramda'
import PropTypes from 'prop-types'

import { useCompany } from '../Profile'
import TextOverflow from '../TextOverflow'

const ratios = {
  'cm': 1,
  'ft': 0.0328084,
  'in': 0.393701,
}

function Dimensions ({ length, width, height }) {
  const { company } = useCompany()
  const sizeUnit = propOr('cm', 'sizeUnit', company)
  const ratio = prop(sizeUnit, ratios)

  const newLength = Number(length * ratio).toFixed(0)
  const newWidth = Number(width * ratio).toFixed(0)
  const newHeight = Number(height * ratio).toFixed(0)

  const dimensions = `${newLength}x${newWidth}x${newHeight}`

  return (
    <TextOverflow selfTooltip={true} lines={1}>
      {dimensions} {sizeUnit}
    </TextOverflow>
  )
}

Dimensions.defaultProps = {
  height: 0,
  length: 0,
  width: 0
}

Dimensions.propTypes = {
  height: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired
}

export default Dimensions
