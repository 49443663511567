import React from 'react'
import PropTypes from 'prop-types'
import Chart from 'react-apexcharts'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import { propOr } from 'ramda'

function OrderChart ({ data: dataProp, ...props }) {
  const { t } = useTranslation()

  const unshipped = propOr(0, 'unshipped', dataProp)
  const preTransit = propOr(0, 'preTransit', dataProp)
  const inTransit = propOr(0, 'inTransit', dataProp)
  const delivered = propOr(0, 'delivered', dataProp)
  const returned = propOr(0, 'returned', dataProp)

  const labels = [t('Unshipped'), t('Pre-transit'), t('In transit'), t('Delivered'), t('Returned')]
  const series = [unshipped, preTransit, inTransit, delivered, returned]

  const opt = {
    stroke: { width: 0 },
    chart: { type: 'donut' },
    legend: {
      position: 'top',
      labels: {
        color: '#666666',
      },
      fontSize: 16,
      onItemClick: {
        toggleDataSeries: false
      },
      itemMargin: {
        horizontal: 20,
        vertical: 12
      }
    },
    labels,
    dataLabels: {
      dropShadow: {
        enabled: false
      },
      style: {
        fontWeight: 400
      }
    },
    plotOptions: {
      pie: {
        expandOnClick: false
      }
    }
  }

  return (
    <Box position="relative" sx={{ display: 'flex', justifyContent: 'center' }} {...props}>
      <Chart type="donut" options={opt} series={series} width={500} />
    </Box>
  )
}

OrderChart.propTypes = {
  data: PropTypes.array.isRequired
}

export default OrderChart
