import * as cookie from '~/utils/cookies'

const KEY = 'language'

export const set = value => cookie.set(KEY, JSON.stringify(value))

export const get = () => {
  const value = cookie.get(KEY)

  return value ? JSON.parse(value) : ''
}
