import React from 'react'
import PropTypes from 'prop-types'
import { map, path, prop, propOr } from 'ramda'
import { useTranslation } from 'react-i18next'

import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import { Table, TableHeader } from '~/components/Table'
import * as NAV from '~/constants/nav-titles'
import Button from '~/components/Buttons/Button'
import { FulfillmentProgress, ShipmentStatus } from '~/components/Statuses'
import TableDateFormat from '~/components/TableDateFormat/TableDateFormat'

export const ORDER_UNITS_COLUMNS = [
  {
    width: 125,
    headerName: 'SO #',
    field: 'number'
  },
  {
    width: 150,
    headerName: 'SKU',
    field: 'sku'
  },
  {
    width: 125,
    headerName: 'Barcode',
    field: 'barcode'
  },
  {
    width: 100,
    headerName: 'Quantity',
    field: 'quantity'
  },
  {
    width: 100,
    headerName: 'Expiry date',
    field: 'expiryDate',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  },
  {
    width: 150,
    headerName: 'Production date',
    field: 'productionDate',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  },
  {
    width: 150,
    headerName: 'Batch number',
    field: 'batchNumber'
  },
  {
    width: 150,
    headerName: 'Serial number',
    field: 'serialNumber'
  },
  {
    width: 150,
    headerName: 'Tracking number',
    field: 'trackingNumber'
  },
  {
    width: 150,
    headerName: 'Customer reference',
    field: 'customerReference'
  },
  {
    width: 150,
    headerName: 'SO order date',
    field: 'orderDate',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  },
  {
    width: 150,
    headerName: 'Fulfillment status',
    field: 'fulfillmentStatus',
    renderCell: ({ value }) => (
      <FulfillmentProgress status={value} />
    ),
    sortable: false
  },
  {
    width: 150,
    headerName: 'Shipment status',
    field: 'shipmentStatus',
    renderCell: ({ value }) => (
      <ShipmentStatus value={value} />
    )
  },
  {
    width: 200,
    headerName: 'Shipping date last updated',
    field: 'shippedDate'
  },
  {
    width: 150,
    headerName: 'Warehouse',
    field: 'warehouse'
  },
  {
    width: 100,
    headerName: 'Company',
    field: 'company'
  },
  {
    width: 150,
    headerName: 'Customer name',
    field: 'customerName'
  }
]

const getRowsFromResults = map(result => {
  const id = prop('id', result)
  const guid = prop('guid', result)

  const order = prop('order', result)
  const variant = prop('variant', result)
  const customer = prop('customer', result)

  const number = prop('number', order)
  const sku = prop('sku', variant)
  const barcode = prop('barcode', variant)
  const quantity = prop('quantity', result)
  const expiryDate = prop('expiryDate', result)
  const productionDate = prop('productionDate', result)
  const batchNumber = prop('batchNumber', result)
  const serialNumber = prop('serialNumber', result)
  const trackingNumber = prop('trackingNumber', result)
  const customerReference = prop('referenceNumber', result)
  const orderDate = prop('createdAt', order)
  const fulfillmentStatus = prop('fulfillmentStatus', order)
  const shipmentStatus = prop('shipmentStatus', order)
  const shippedDate = prop('shippedDate', result)
  const warehouse = path(['warehouse', 'name'], result)
  const company = path(['company', 'name'], result)
  const customerFirstName = propOr('', 'firstName', customer)
  const customerLastName = propOr('', 'lastName', customer)
  const customerName = customerFirstName + ' ' + customerLastName

  return {
    id,
    guid,
    number,
    sku,
    barcode,
    quantity,
    expiryDate,
    productionDate,
    batchNumber,
    serialNumber,
    trackingNumber,
    customerReference,
    orderDate,
    fulfillmentStatus,
    shipmentStatus,
    shippedDate,
    warehouse,
    company,
    customerName
  }
})

function OrderUnitsTable ({ list, filter, ordering, onReportGenerate, onListRefetch }) {
  const { t } = useTranslation()

  const isLoading = prop('isLoading', list)
  const results = prop('results', list)
  const count = prop('count', list)
  const columns = prop('filteredColumns', ordering)

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={NAV.ORDER_UNITS_REPORT}
          parentTitle={NAV.REPORTS}
          rightButton={(
            <Button
              variant="contained"
              data-cy="create"
              type="button"
              onClick={onReportGenerate}
            >
              {t('Generate Report')}
            </Button>
          )}
        />
      </Header>

      <Content>
        <TableHeader
          filter={filter}
          ordering={ordering}
          isLoading={isLoading}
          filterOpen={filter.handleOpen}
          orderingOpen={ordering.handleOpen}
        />
        <Table
          isLoading={isLoading}
          columns={columns}
          getRowsFromResults={getRowsFromResults}
          results={results}
          count={count}
          pinnedColumns={['number']}
          primaryKey="guid"
          ordering={ordering}
          checkboxSelection={true}
          onRefetch={onListRefetch}
        />
      </Content>
    </Container>
  )
}

OrderUnitsTable.propTypes = {
  list: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  onReportGenerate: PropTypes.func.isRequired,
  onListRefetch: PropTypes.func.isRequired
}

export default OrderUnitsTable
