import { path, prop } from 'ramda'
import React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import Title from '~/components/Title'
import ErrorLink from '~/components/ErrorLink'
import * as NAV from '~/constants/nav-titles'
import useMessages from '~/hooks/useMessages'
import { SALE_ORDER_DETAIL_TABS, SALE_RETURN_TABS } from '~/constants/tabs'
import * as ROUTES from '~/constants/routes'
import { useNotification } from '~/components/Notification'
import { RETURN_LABEL_CREATE_STATUS } from '~/constants/notification-topics'

import { ReturnUpdateForm } from '../components/ReturnUpdate'
import { useReturnDetail, useReturnIntegrationCheck, useReturnUpdate, useReturnVariants } from '../hooks'
import { ReturnInitUpdateSerializer, ReturnIntegrationCheckSerializer, ReturnUpdateSerializer } from '../serializers'

function ReturnUpdateContainer () {
  const { guid } = useParams()
  const snackbar = useSnackbar()
  const messages = useMessages()
  const returnDetail = useReturnDetail(guid)
  const returnUpdate = useReturnUpdate(guid)
  const returnIntegrationCheck = useReturnIntegrationCheck()
  const returnVariants = useReturnVariants()
  const navigate = useNavigate()

  const initialValues = ReturnInitUpdateSerializer(returnDetail.detail)
  const updateNotification = useNotification(RETURN_LABEL_CREATE_STATUS)

  const handleSubmit = formValues =>
    returnUpdate.update(ReturnUpdateSerializer(formValues))
      .then(() => {
        const params = { guid, tab: SALE_RETURN_TABS.GENERAL }
        const redirect = generatePath(ROUTES.RETURN_DETAIL_PATH, params)
        snackbar({ message: messages.NOTIFICATION_WAIT })
        updateNotification.setLoading(true)
        updateNotification.subscribe(guid, payload => {
          updateNotification.setLoading(false)
          const success = path(['payload', 'success'], payload)
          if (success) {
            snackbar({ message: messages.UPDATE_SUCCESS })
            navigate(redirect)
          } else {
            const message = path(['payload', 'message'], payload)
            snackbar({ message, type: ALTER_ERROR })
          }
        })
      })

  const handleIntegrationCheck = values => {
    return returnIntegrationCheck.create(ReturnIntegrationCheckSerializer(values))
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
        return Promise.reject(error)
      })
  }

  const pageLoading =
    returnDetail.isLoading ||
    returnUpdate.isLoading ||
    updateNotification.isLoading

  const title = prop('number', returnDetail.detail)
  const pageTitle = (
    <Title title="Return" number={title} />
  )

  const isLoading = returnDetail.isLoading || returnUpdate.isLoading

  const breadcrumbs = {
    title: 'Edit',
    params: [
      { guid, tab: SALE_ORDER_DETAIL_TABS.GENERAL, title }
    ]
  }

  return (
    <DashboardLayout
      title={title}
      isLoading={pageLoading}
      activeNav={NAV.SALE_RETURNS}
      breadcrumbs={breadcrumbs}
    >
      <ReturnUpdateForm
        pageTitle={pageTitle}
        pageTitleLoading={returnDetail.isLoading}
        onIntegrationCheck={handleIntegrationCheck}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        isLoading={isLoading || updateNotification.isLoading}
        returnVariants={returnVariants}
      />
    </DashboardLayout>
  )
}

export default ReturnUpdateContainer
