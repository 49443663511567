import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'

const RootStyled = styled(Box, {
  shouldForwardProp: propName => propName !== 'topSpace' && propName !== 'bottomSpace'
})(({ theme, topSpace, bottomSpace }) => ({
  [theme.breakpoints.up('lg')]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  [theme.breakpoints.down('lg')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  paddingTop: 10,
  paddingBottom: 20,
  ...(topSpace && ({
    paddingTop: 20
  })),
  ...(bottomSpace && ({
    paddingBottom: 200
  }))
}))

function Content ({ children, space, bottomSpace }) {
  return (
    <RootStyled topSpace={space} bottomSpace={bottomSpace}>
      {children}
    </RootStyled>
  )
}

Content.defaultProps = {
  space: false,
  bottomSpace: false
}

Content.propTypes = {
  children: PropTypes.any.isRequired,
  space: PropTypes.bool,
  bottomSpace: PropTypes.bool
}

export default Content
