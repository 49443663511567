import React, { useContext, useReducer } from 'react'
import Snackbar from '@mui/material/Snackbar'
import PropTypes from 'prop-types'
import { styled } from '@mui/material'
import { ClassNames } from '@emotion/react'

import Context from './context'

const OPEN = 'open'
const CLOSE = 'close'
const DURATION = 6000

const SnackbarStyled = styled(Snackbar)(({ theme }) => ({
  border: 'none',
  '& > div': {
    color: theme.palette.background.paper,
    background: theme.palette.background.inversePaper
  }
}))

const initialState = {
  open: false,
  message: null,
  duration: DURATION
}

export const useCornerSnackbar = () => {
  const { handleOpen } = useContext(Context)

  return handleOpen
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'open':
      return { ...state, ...action.payload, open: true }
    case 'close':
      return { ...state, open: false }
    default:
      return state
  }
}

function CornerSnackbar ({ children }) {
  const [ state, dispatch ] = useReducer(reducer, initialState)

  const value = {
    handleOpen: payload => dispatch({ type: OPEN, payload })
  }

  return (
    <Context.Provider value={value}>
      <ClassNames>
        {({ css, theme }) => (
          <SnackbarStyled
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={state.open}
            onClose={() => dispatch({ type: CLOSE })}
            message={state.message}
            ContentProps={{
              classes: {
                root: css({
                  border: 'none',
                  '& > div': {
                    color: theme.palette.background.paper,
                    background: theme.palette.background.inversePaper
                  }
                })
              }
            }}
          />
        )}
      </ClassNames>
      {children}
    </Context.Provider>
  )
}

CornerSnackbar.propTypes = {
  children: PropTypes.any.isRequired
}

export default CornerSnackbar
