import React from 'react'
import PropTypes from 'prop-types'
import List from '@mui/material/List'
import ListItemText from '@mui/material/ListItemText'
import { ListItemButton } from '@mui/material'

import { MoreButton } from '~/components/Buttons'

function More ({ onCancelDisabled, onCancel, onBarcodeGeneratorOpen, onPrint }) {
  return (
    <MoreButton>
      {onClose => (
        <List>
          <ListItemButton disabled={onCancelDisabled} onClick={() => {
            onCancel()
            onClose()
          }}>
            <ListItemText primary="Cancel" />
          </ListItemButton>
          <ListItemButton onClick={onPrint}>
            <ListItemText primary="Print" />
          </ListItemButton>
          <ListItemButton onClick={() => {
            onBarcodeGeneratorOpen()
            onClose()
          }}>
            <ListItemText primary="Generate barcode" />
          </ListItemButton>
        </List>
      )}
    </MoreButton>
  )
}

More.propTypes = {
  onCancelDisabled: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onBarcodeGeneratorOpen: PropTypes.func.isRequired,
  onPrint: PropTypes.func.isRequired
}

export default More
