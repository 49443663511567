import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import ReorderListContainer from './containers/ReorderListContainer'

export default () => [
  {
    component: ReorderListContainer,
    path: ROUTES.REORDER_LIST_PATH,
    layout: AuthLayout
  }
]
