import React from 'react'
import { useFilter, useOrdering } from 'storfox-filter'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'

import { useStockCountList } from '../hooks'
import {
  StockCountFilterForm,
  stockCountFilterOptions,
  StockCountOrderingForm,
  stockCountOrderingOptions,
  StockCountTable
} from '../components/StockCountList'

function StockCountListContainer () {
  const stockCountList = useStockCountList()

  const filter = useFilter(stockCountFilterOptions)
  const ordering = useOrdering(stockCountOrderingOptions)

  const breadcrumbs = { title: NAV.STOCK_COUNT }

  return (
    <DashboardLayout
      title={NAV.STOCK_COUNT}
      activeNav={NAV.STOCK_COUNT}
      isLoading={stockCountList.isLoading}
      breadcrumbs={breadcrumbs}
    >
      <StockCountFilterForm {...filter} />
      <StockCountOrderingForm {...ordering} />

      <StockCountTable
        filter={filter}
        ordering={ordering}
        list={stockCountList}
        onListRefetch={stockCountList.getListByParams}
      />
    </DashboardLayout>
  )
}

export default StockCountListContainer
