import React from 'react'

import SelectField from '~/components/Fields/SelectField'

const OPTIONS = [
  {
    value: 'template1',
    name: 'Template 1'
  },
  {
    value: 'template2',
    name: 'Template 2'
  }
]

function TemplateField (props) {
  return (
    <SelectField
      name="credential.template"
      label="Template"
      options={OPTIONS}
      {...props}
    />
  )
}

export default TemplateField
