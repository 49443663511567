import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Status, { ERROR, SUCCESS } from '../Status/Status'

export const ACTIVE = 'active'
export const INACTIVE = 'inactive'

export const AREA_STATUS = {
  ACTIVE,
  INACTIVE,
}

export const AREA_STATUS_LABEL = {
  [ACTIVE]: 'Active',
  [INACTIVE]: 'Inactive',
}

const STATUS_COLOR = {
  [AREA_STATUS.ACTIVE]: SUCCESS,
  [AREA_STATUS.INACTIVE]: ERROR,
}

function AreaStatus ({ value }) {
  const { t } = useTranslation()

  return (
    <Status
      label={t(AREA_STATUS_LABEL[value])}
      color={STATUS_COLOR[value]}
    />
  )
}

AreaStatus.propTypes = {
  value: PropTypes.string.isRequired
}

export default AreaStatus
