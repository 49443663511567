import React from 'react'
import { Filter } from 'storfox-filter'
import Grid from '@mui/material/Grid'
import { prop } from 'ramda'
import Typography from '@mui/material/Typography'
import { DateRangeField } from 'storfox-form-fields'
import { useTranslation } from 'react-i18next'

import { escapeAtob, getSeparatedDateRange, unescapeBtoa } from '~/utils'
import CustomerSearchField from '~/components/Fields/CustomerSearchField'
import TextField from '~/components/Fields/TextField'
import WarehouseMultiSelectField from '~/components/Fields/WarehouseMultiSelectField'
import Accordion from '~/components/Accordion'

import CompanyStatusField from './CompanyStatusField'

import { FILTER_NAME as name } from '../../constants'

export const toParams = values => {
  const status = prop('status', values)
  const supplier = unescapeBtoa(prop('supplier', values))
  const warehouse = unescapeBtoa(prop('warehouse', values))
  const [createdAtStart, createdAtEnd] = getSeparatedDateRange(prop('createdAt', values))
  const minTotal = prop('minTotal', values)
  const maxTotal = prop('maxTotal', values)

  return {
    status,
    supplier,
    warehouse,
    createdAtStart,
    createdAtEnd,
    minTotal,
    maxTotal
  }
}

export const toValues = params => {
  const status = prop('status', params)
  const supplier = escapeAtob(prop('supplier', params))
  const warehouse = escapeAtob(prop('warehouse', params))
  const createdAt = [prop('createdAtStart', params), prop('createdAtEnd', params)]
  const minTotal = prop('minTotal', params)
  const maxTotal = prop('maxTotal', params)

  return {
    status,
    supplier,
    warehouse,
    createdAt,
    minTotal,
    maxTotal
  }
}
export const transformer = { toParams, toValues }

export const companyFilterOptions = {
  name,
  transformer
}

function CompanyFilterForm (props) {
  const { t } = useTranslation()
  return (
    <Filter {...props}>
      <Accordion title="General">
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <CompanyStatusField />
          </Grid>
          <Grid item={true} xs={12}>
            <CustomerSearchField />
          </Grid>
          <Grid item={true} xs={12}>
            <WarehouseMultiSelectField />
          </Grid>
        </Grid>
      </Accordion>
      <Accordion title="Date">
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <Typography variant="body2" gutterBottom={true}>
              {t('Created')}:
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <DateRangeField name="createdAt" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Accordion>
      <Accordion title="Total ($)">
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={6}>
            <TextField
              data-cy="minTotal"
              name="minTotal"
              label="Min ($)"
            />
          </Grid>
          <Grid item={true} xs={6}>
            <TextField
              data-cy="maxTotal"
              name="maxTotal"
              label="Max ($)"
            />
          </Grid>
        </Grid>
      </Accordion>
    </Filter>
  )
}

export default CompanyFilterForm
