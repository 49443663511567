import React from 'react'
import { Card, Divider } from '@mui/material'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import PropTypes from 'prop-types'

import { CardHeader } from '~/components/Cards'

import AddressInfo from './AddressInfo'

function SupplierAddresses ({ billingAddress, deliveryAddress }) {
  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12} lg={6}>
        <Card>
          <CardHeader title="Supplier address" />
          <Divider />
          <CardContent>
            <AddressInfo address={deliveryAddress} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item={true} xs={12} lg={6}>
        <Card>
          <CardHeader title="Billing address" />
          <Divider />
          <CardContent>
            <AddressInfo address={billingAddress} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

SupplierAddresses.propTypes = {
  billingAddress: PropTypes.object,
  deliveryAddress: PropTypes.object
}

export default SupplierAddresses
