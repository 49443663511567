import { pick, prop } from 'ramda'
import { DEFAULT_SEARCH_PARAMS } from 'storfox-route-hooks'

import { escapeAtob, getDateTimeResetZone } from '~/utils'

export function SalesMarginFilterSerializer (params) {
  const defaultParams = pick(DEFAULT_SEARCH_PARAMS, params)
  const warehouseGuids = escapeAtob(prop('warehouses', params))
  const channelGuids = escapeAtob(prop('channels', params))
  const variantGuids = escapeAtob(prop('variants', params))
  const startDate = getDateTimeResetZone(prop('startDate', params))
  const endDate = getDateTimeResetZone(prop('endDate', params))
  const tags = prop('tags', params)

  return {
    ...defaultParams,
    warehouseGuids,
    channelGuids,
    variantGuids,
    tags,
    startDate,
    endDate
  }
}
