export const NEW = 'new'
export const PROCESSING = 'processing'
export const COMPLETED = 'completed'
export const CANCELLED = 'cancelled'

export const NAMES = {
  [NEW]: 'New',
  [PROCESSING]: 'Processing',
  [COMPLETED]: 'Completed',
  [CANCELLED]: 'cancelled'
}

export const FILTER_NAME = 'supplier_returns'
