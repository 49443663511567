import React from 'react'
import PropTypes from 'prop-types'

import Status from '../Status/Status'

const ACTIVE = 'active'
const INACTIVE = 'inactive'

const COLOR = {
  [ACTIVE]: 'success',
  [INACTIVE]: 'error',
}

function BooleanStatus ({ value }) {
  return (
    <Status label={value} color={COLOR[value]} />
  )
}

BooleanStatus.propTypes = {
  value: PropTypes.oneOf([
    ACTIVE, INACTIVE
  ])
}

export default BooleanStatus
