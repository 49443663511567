import React from 'react'
import PropTypes from 'prop-types'
import CreateIcon from '@mui/icons-material/Create'
import { useNavigate } from 'react-router-dom'

import Button from '../Buttons/Button'

function EditButton ({ children, path, ...props }) {
  const navigate = useNavigate()
  return (
    <Button
      variant="contained"
      type="button"
      data-cy="edit"
      onClick={() => navigate(path)}
      startIcon={<CreateIcon />}
      {...props}
    >
      {children}
    </Button>
  )
}

EditButton.propTypes = {
  children: PropTypes.any.isRequired,
  path: PropTypes.string.isRequired,
  disabled: PropTypes.bool
}

EditButton.defaultProps = {
  children: 'Edit'
}

export default EditButton
