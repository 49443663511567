import React from 'react'
import PropTypes from 'prop-types'
import arrayMutators from 'final-form-arrays'

import Form from '~/components/Form'

import Filters from './Filters'

function FiltersTab ({ backend, onClose, onReset }) {
  const { filterItems, onFiltersUpdate, isLoading } = backend

  const handleSubmit = values =>
    onFiltersUpdate(values)
      .then(() => onClose())

  return (
    <Form
      initialValues={{ filters: filterItems }}
      onSubmit={handleSubmit}
      mutators={{
        ...arrayMutators
      }}
    >
      {({ handleSubmit }) => (
        <Filters
          isLoading={isLoading}
          onSubmit={handleSubmit}
          onClose={onClose}
          onReset={onReset}
        />
      )}
    </Form>
  )
}

FiltersTab.propTypes = {
  backend: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired
}

export default FiltersTab
