import React, { useEffect } from 'react'
import { useFilter, useOrdering } from 'storfox-filter'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'
import { DateTime } from 'luxon'
import { useAllSearchParams, useRoute } from 'storfox-route-hooks'
import { prop } from 'ramda'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import ErrorLink from '~/components/ErrorLink'
import useMessages from '~/hooks/useMessages'
import { getDateTimeGMTDifference } from '~/utils/date'

import { useUnitHistoryList, useUnitHistoryReportGenerate } from '../../hooks/UnitHistory'
import UnitHistoryList from '../../components/UnitHistory/UnitHistoryList'
import UnitOrderingForm, { unitHistoryOrderingOptions } from '../../components/UnitHistory/UnitHistoryOrderingForm'
import UnitFilterForm, { unitHistoryFilterOptions } from '../../components/UnitHistory/UnitHistoryFilterForm'

function UnitHistoryReportContainer () {
  const { replaceParams } = useRoute()
  const messages = useMessages()
  const params = useAllSearchParams()

  const datetime = prop('datetime', params)

  const unitList = useUnitHistoryList(Boolean(datetime))
  const inventoryReportGenerate = useUnitHistoryReportGenerate()

  useEffect(() => {
    if (!datetime) {
      replaceParams({ datetime: getDateTimeGMTDifference(DateTime.now()) })
    }
  }, [replaceParams, datetime])

  const snackbar = useSnackbar()

  const filter = useFilter(unitHistoryFilterOptions)
  const ordering = useOrdering(unitHistoryOrderingOptions)

  const handleReportGenerate = () =>
    inventoryReportGenerate.generate()
      .then(() => snackbar({ message: messages.NOTIFICATION_WAIT }))
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const breadcrumbs = { title: NAV.UNIT_HISTORY }

  return (
    <DashboardLayout
      title={NAV.UNIT_HISTORY}
      activeNav={NAV.REPORTS}
      breadcrumbs={breadcrumbs}
    >

      <UnitFilterForm {...filter} />
      <UnitOrderingForm {...ordering} />

      <UnitHistoryList
        list={unitList}
        filter={filter}
        ordering={ordering}
        onListRefetch={unitList.getListByParams}
        handleOpenFilter={filter.handleOpen}
        onReportGenerate={handleReportGenerate}
      />
    </DashboardLayout>
  )
}

export default UnitHistoryReportContainer
