import React from 'react'
import PropTypes from 'prop-types'

import Capitalize from './Capitalize'

function CommaSeparatedList ({ list }) {
  return (
    <>
      {list.map((item, index) => (
        <div key={index}>
          <Capitalize>
            {item}
          </Capitalize>
          {list.length && index !== (list.length - 1) && (<>,&nbsp;</>)}
        </div>
      ))}
    </>
  )
}

CommaSeparatedList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.string.isRequired
  ).isRequired
}

export default CommaSeparatedList
