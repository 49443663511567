import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { defaultTo, map, path, pathOr, prop, propOr } from 'ramda'
import { generatePath } from 'react-router-dom'

import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import Money from '~/components/Money'
import TableDateFormat from '~/components/TableDateFormat'
import TableNotes from '~/components/TableNotes'
import { ShipmentStatus } from '~/components/Statuses'
import { Table, TableHeader } from '~/components/Table'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import TextOverflow from '~/components/TextOverflow'
import { SALE_ORDER_DETAIL_TABS, SALE_RETURN_TABS } from '~/constants/tabs'
import { MoreListButton } from '~/components/Buttons'
import TableLink from '~/components/Link'
import ReceiveStatus from '~/components/Statuses/ReceiveStatus'
import ReturnStatus from '~/components/Statuses/ReturnStatus'
import RefundStatus from '~/components/Statuses/RefundStatus'

import BulkActionButton from './BulkActionButton'

export const RETURN_COLUMNS = [
  {
    width: 125,
    headerName: 'ID',
    field: 'number',
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        <TextOverflow selfTooltip={true} lines={1}>
          {value}
        </TextOverflow>
      </TableLink>
    )
  },
  {
    width: 125,
    headerName: 'Order #',
    field: 'orderNumber',
    renderCell: ({ value, row }) => (
      <TableLink to={row.detailLink} target="_blank">
        <TextOverflow selfTooltip={true} lines={1}>
          {value}
        </TextOverflow>
      </TableLink>
    )
  },
  {
    width: 125,
    headerName: 'Reference #',
    field: 'referenceNumber',
    renderCell: ({ value, row }) => (
      <TableLink to={row.link} target="_blank">
        <TextOverflow
          tooltip={`Reference number from ${row.channelName}`}
          lines={1}
        >
          {value}
        </TextOverflow>
      </TableLink>
    )
  },
  {
    width: 150,
    headerName: 'Line items',
    field: 'lineItems',
    headerAlign: 'center',
    align: 'center'
  },
  {
    width: 100,
    headerName: 'Qty',
    field: 'quantity',
    headerAlign: 'center',
    align: 'center'
  },
  {
    width: 150,
    headerName: 'Total',
    field: 'totalPrice',
    renderCell: ({ value }) => (
      <Money value={value} />
    )
  },
  {
    width: 150,
    headerName: 'Status',
    field: 'status',
    renderCell: ({ value }) => (
      <ReturnStatus value={value} />
    )
  },
  {
    width: 150,
    headerName: 'Shipping status',
    field: 'shippingStatus',
    renderCell: ({ value }) => (
      <ShipmentStatus value={value} />
    )
  },
  {
    width: 150,
    headerName: 'Receiving status',
    field: 'receivingStatus',
    renderCell: ({ value }) => (
      <ReceiveStatus value={value} />
    )
  },
  {
    width: 150,
    headerName: 'Refund status',
    field: 'refundStatus',
    renderCell: ({ value }) => (
      <RefundStatus value={value} />
    )
  },
  {
    width: 250,
    headerName: 'Customer',
    field: 'customer'
  },
  {
    width: 150,
    headerName: 'City',
    field: 'city',
    ordering: true
  },
  {
    width: 200,
    headerName: 'Created date',
    field: 'createdAt',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  },
  {
    width: 200,
    headerName: 'Last updated',
    field: 'updatedAt',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  },
  {
    width: 125,
    headerName: 'Notes',
    field: 'notes',
    renderCell: ({ value }) => (
      <TableNotes>{value}</TableNotes>
    )
  }
]

const getRowsFromResults = map(item => {
  const id = prop('id', item)
  const guid = prop('guid', item)

  const number = prop('number', item)

  const orderNumber = path(['order', 'number'], item)
  const orderGuid = path(['order', 'guid'], item)
  const channelName = pathOr('channel', ['order', 'channel', 'name'], item)

  const referenceNumber = propOr('', 'referenceNumber', item)

  const lineItems = prop('lineItemsQty', item)
  const quantity = prop('itemsQty', item)
  const totalPrice = prop('totalPrice', item)
  const status = prop('status', item)
  const shippingStatus = prop('shipmentStatus', item)
  const receivingStatus = prop('receivingStatus', item)
  const refundStatus = prop('refundStatus', item)

  const firstName = pathOr('', ['pickupAddress', 'firstName'], item)
  const lastName = pathOr('', ['pickupAddress', 'lastName'], item)
  const customer = `${firstName} ${lastName}`

  const city = path(['order', 'deliveryAddress', 'city'], item)
  const createdAt = prop('createdAt', item)
  const updatedAt = prop('updatedAt', item)

  const notes = prop('notes', item)
  const detailLink = generatePath(ROUTES.SALE_ORDER_DETAIL_PATH, {
    guid: defaultTo('', orderGuid),
    tab: SALE_ORDER_DETAIL_TABS.GENERAL
  })
  const link = generatePath(ROUTES.RETURN_DETAIL_PATH, {
    guid,
    tab: SALE_RETURN_TABS.GENERAL
  })

  return {
    id,
    guid,
    orderGuid,
    number,
    orderNumber,
    referenceNumber,
    channelName,
    lineItems,
    quantity,
    totalPrice,
    status,
    shippingStatus,
    receivingStatus,
    refundStatus,
    customer,
    city,
    createdAt,
    updatedAt,
    notes,
    link,
    detailLink
  }
})

function ReturnTable ({ list, filter, ordering, onListRefetch, onReturnsCancel, bulkActionLoading }) {
  const [tableRef, setTableRef] = useState(null)
  const isLoading = prop('isLoading', list)
  const results = prop('results', list)
  const count = prop('count', list)
  const columns = prop('filteredColumns', ordering)

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={NAV.SALE_RETURNS}
          parentTitle={NAV.SALES}
          rightButton={(
            <BulkActionButton onReturnsCancel={onReturnsCancel} loading={bulkActionLoading} />
          )}
        />
      </Header>

      <Content>
        <TableHeader
          filter={filter}
          filterOpen={filter.handleOpen}
          orderingOpen={ordering.handleOpen}
          ordering={ordering}
        >
          <MoreListButton tableRef={tableRef} />
        </TableHeader>
        <Table
          isLoading={isLoading}
          columns={columns}
          getRowsFromResults={getRowsFromResults}
          results={results}
          count={count}
          pinnedColumns={['number']}
          checkboxSelection={true}
          primaryKey="guid"
          ordering={ordering}
          onRefetch={onListRefetch}
          setTableRef={setTableRef}
        />
      </Content>
    </Container>
  )
}

ReturnTable.propTypes = {
  list: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  onReturnsCancel: PropTypes.func.isRequired,
  onListRefetch: PropTypes.func.isRequired,
  bulkActionLoading: PropTypes.bool
}

export default ReturnTable
