import React from 'react'
import Grid from '@mui/material/Grid'
import PropTypes from 'prop-types'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { prop } from 'ramda'
import { useField } from 'react-final-form'
import { sprintf } from 'sprintf-js'
import { BrowserSearchField } from 'storfox-query-builder'

import { CardHeader } from '~/components/Cards'
import ShippingServiceField from '~/components/Fields/ShippingServiceField'
import * as API from '~/constants/api'
import { withForm } from '~/components/Form'
import ChoiceCard from '~/components/Cards/ChoiceCard'
import { Button } from '~/components/Buttons'

const getOptionValue = item => {
  const id = prop('id', item)
  const name = prop('name', item)

  return { id, name }
}

function ShippingInfo ({ integrationList, isLoading, form }) {
  const { handleSubmit } = form

  const shippingAddressField = useField('shippingAddress')
  const shippingAddressValue = shippingAddressField.input.value
  const shippingCity = prop('city', shippingAddressValue)

  const shippingCitiesField = useField('shippingCities')
  const shippingCitiesValue = shippingCitiesField.input.value
  const shippingCitiesChange = shippingCitiesField.input.onChange

  const shippingMethodField = useField('shippingMethod')
  const shippingMethodValue = shippingMethodField.input.value
  const shippingMethodChange = shippingMethodField.input.onChange
  const methodGuid = prop('guid', shippingMethodValue)

  const citiesApi = methodGuid && sprintf(API.SHIPMENT_INTEGRATION_CITIES, methodGuid)

  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={8}>
        <Card>
          <CardHeader
            title={(
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography component="span" variant="h6">
                  Last Mile
                </Typography>
                <Typography component="span" variant="h6">
                  Order Courier (Auto)
                </Typography>
              </Box>
            )}
          />
          <Divider />
          <CardContent>
            <Grid container={true} spacing={3}>
              {integrationList.map(integration => {
                const name = prop('name', integration)
                const guid = prop('guid', integration)
                return (
                  <Grid
                    key={guid}
                    item={true}
                    xs={12}
                    lg={6}
                  >
                    <ChoiceCard
                      active={guid === methodGuid} onClick={() => shippingMethodChange(integration)}
                      sx={{ padding: '15px' }}
                    >
                      <Typography ml={1} variant="h5">{name}</Typography>
                    </ChoiceCard>
                  </Grid>
                )
              })}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item={true} xs={4}>
        <Card>
          <CardHeader
            title={(
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography component="span" variant="h6">
                  Updated City
                </Typography>
                <Typography component="span" variant="h6">
                  {`Destination (${shippingCity})`}
                </Typography>
              </Box>
            )}
          />
          <Divider />
          <CardContent>
            <Grid container={true} spacing={3}>
              <Grid item={true} lg={12}>
                <ShippingServiceField
                  integrationName="shippingMethod"
                  api={API.SHIPMENT_SERVICES}
                  disabled={!methodGuid}
                />
              </Grid>
              <Grid item={true} lg={12}>
                <BrowserSearchField
                  sx={{ width: '100%' }}
                  disabled={!methodGuid}
                  api={citiesApi}
                  data-cy="shippingCities"
                  name="shippingCities"
                  label="Cities"
                  value={shippingCitiesValue}
                  onChange={(e, value) => shippingCitiesChange(value)}
                  getOptionValue={getOptionValue}
                />
              </Grid>
              <Grid item={true} lg={12}>
                <Button
                  variant="contained"
                  type="submit"
                  fullWidth={true}
                  loading={isLoading}
                  onClick={handleSubmit}
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

ShippingInfo.propTypes = {
  integrationList: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  form: PropTypes.object
}

export default withForm(ShippingInfo)
