import React from 'react'
import PropTypes from 'prop-types'

import Status from '~/components/Status'

import { CANCELLED, COMPLETED, IN_PROGRESS, NEW, PENDING, STATUSES } from '../../constants'

const COLOR = {
  [NEW]: 'success',
  [PENDING]: 'warning',
  [IN_PROGRESS]: 'warning',
  [COMPLETED]: 'success',
  [CANCELLED]: 'error',
}

function StockCountStatus ({ value }) {
  return (
    <Status
      label={STATUSES[value]}
      color={COLOR[value]}
    />
  )
}

StockCountStatus.propTypes = {
  value: PropTypes.oneOf([
    NEW,
    PENDING,
    IN_PROGRESS,
    COMPLETED,
    CANCELLED,
  ]).isRequired
}

export default StockCountStatus
