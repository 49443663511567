import React, { useCallback, useEffect } from 'react'
import { useField } from 'react-final-form'
import PropTypes from 'prop-types'
import { prop } from 'ramda'

import Money from '~/components/Money'

function ReturnMoney ({ name, conditionFieldName, value }) {
  const field = useField(name)
  const conditionField = useField(conditionFieldName)
  const condition = conditionField.input.value

  const handleChange = useCallback(field.input.onChange, [])

  useEffect(() => {
    const retailerPrice = prop('retailPrice', condition)
    if (retailerPrice) {
      handleChange(retailerPrice)
    }
  }, [condition, handleChange])
  return (
    <Money value={value} />
  )
}

ReturnMoney.propTypes = {
  value: PropTypes.number,
  name: PropTypes.string.isRequired,
  conditionFieldName: PropTypes.string.isRequired
}

export default ReturnMoney
