import React from 'react'
import { prop, propOr } from 'ramda'
import PropTypes from 'prop-types'
import { generatePath, useParams } from 'react-router-dom'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'

import { getRouteTab } from '~/utils'
import * as ROUTES from '~/constants/routes'
import PageTitle from '~/components/PageTitle'
import { DiscardButton, EditButton } from '~/components/Buttons'
import Container, { Content, Header } from '~/components/Container'
import * as NAV from '~/constants/nav-titles'
import { PRODUCT_DETAIL_TABS, PRODUCT_UPDATE_TABS } from '~/constants/tabs'
import History from '~/components/History'
import LoaderProvider from '~/components/Loader'

import ProductDetailVariants from './Variants/ProductDetailVariants'
import ProductDetailTabs from './ProductDetailTabs'
import ProductDetailGeneral from './General/ProductDetailGeneral'

function ProductDetail ({ title, detail, isLoading, productHistory }) {
  const { t } = useTranslation()
  const { id, tab } = useParams()

  const variants = propOr([], 'variants', detail)
  const variantCount = propOr(0, 'variantCount', detail)
  const isMultiVariant = prop('isMultiVariant', detail)

  const editTab = getRouteTab(tab, PRODUCT_UPDATE_TABS, PRODUCT_UPDATE_TABS.GENERAL)
  const editPath = generatePath(ROUTES.PRODUCT_UPDATE_PATH, { id, tab: editTab })

  return (
    <LoaderProvider isLoading={isLoading}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={title}
            parentTitle={t(NAV.PRODUCTS)}
            pageTitleLoading={isLoading}
            rightButton={(
              <>
                <DiscardButton disabled={isLoading} />
                <EditButton disabled={isLoading} path={editPath} />
              </>
            )}
          />
        </Header>

        <Content>
          <ProductDetailTabs count={variantCount} value={tab} />
          <Divider />
          <Box mb={3} />

          {tab === PRODUCT_DETAIL_TABS.GENERAL && <ProductDetailGeneral detail={detail} />}
          {tab === PRODUCT_DETAIL_TABS.VARIANTS && (
            <ProductDetailVariants
              variants={variants}
              isMultiVariant={isMultiVariant}
            />
          )}
          {tab === PRODUCT_DETAIL_TABS.HISTORY && <History history={productHistory} />}
          <Box mb={3} />
        </Content>
      </Container>
    </LoaderProvider>
  )
}

ProductDetail.propTypes = {
  detail: PropTypes.object.isRequired,
  productHistory: PropTypes.object.isRequired,
  title: PropTypes.string,
  isLoading: PropTypes.bool.isRequired
}

export default ProductDetail
