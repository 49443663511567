import React from 'react'
import Grid from '@mui/material/Grid'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import LinearProgress from '@mui/material/LinearProgress'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import useAllSearchParams from 'storfox-route-hooks/src/useAllSearchParams'
import { propOr } from 'ramda'

import { CardHeader } from '~/components/Cards'
import { escapeAtob } from '~/utils'
import TextLabelLoader from '~/components/TextLabelLoader'

const getProgress = (current, total) =>
  total >= current ? Math.ceil(current / (total * 0.01)) : 100

function ProgressCard ({ title, validation, completion }) {
  const { payload } = useAllSearchParams()

  const validationProgress = validation.total > 0 ? getProgress(validation.current, validation.total) : 0
  const completionProgress = completion.total > 0 ? getProgress(completion.current, completion.total) : 0

  const key = propOr('', 'key', escapeAtob(payload))

  return (
    <Card>
      <CardHeader title={title} />
      <Divider />
      <CardContent>
        <Box>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12}>
              <LinearProgress
                sx={{ height: 8 }}
                variant="determinate"
                value={validationProgress}
              />
            </Grid>
            <Grid item={true} xs={12}>
              <Box>
                <Typography variant="body1">Validated: {validation.current}/{validation.total}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box mt={2}>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12}>
              <LinearProgress
                sx={{ height: 8 }}
                variant="determinate"
                value={completionProgress}
              />
            </Grid>
            <Grid item={true} xs={12}>
              <Box>
                <Typography variant="body1">Uploaded: {completion.current}/{completion.total}</Typography>
              </Box>
            </Grid>
            {key && (
              <Grid item={true} xs={12}>
                <TextLabelLoader label="Bulk upload key">
                  {key}
                </TextLabelLoader>
              </Grid>
            )}
          </Grid>
        </Box>
      </CardContent>
    </Card>
  )
}

ProgressCard.defaultProps = {
  title: 'Progress'
}

ProgressCard.propTypes = {
  title: PropTypes.string.isRequired,
  validation: PropTypes.shape({
    total: PropTypes.number.isRequired,
    current: PropTypes.number.isRequired
  }),
  completion: PropTypes.shape({
    total: PropTypes.number.isRequired,
    current: PropTypes.number.isRequired
  }),
}

export default ProgressCard
