import { useTranslation } from 'react-i18next'

function useMessages () {
  const { t } = useTranslation()

  const SUCCESS = {
    CREATE_SUCCESS: t('Successfully created.'),
    REQUEST_SUBMITTED: t('Request submitted.'),
    SWITCH_ACCOUNT_SUCCESS: t('Successfully switched account.'),
    UPDATE_SUCCESS: t('Successfully updated.'),
    COMPLETE_SUCCESS: t('Successfully completed.'),
    DELETE_SUCCESS: t('Successfully deleted.'),
    ALLOCATE_SUCCESS: t('Sent for allocation.'),
    GENERATE_SUCCESS: t('Successfully generated.'),
    RECALCULATE_SUCCESS: t('Successfully recalculated.'),
    COPY_SUCCESS: t('Successfully copied.'),
    RESET_SUCCESS: t('Successfully reset.'),
    SET_SUCCESS: t('Successfully set.'),
    CLOSE_SUCCESS: t('Successfully closed.'),
    CANCEL_SUCCESS: t('Successfully cancelled.'),
    PROCESS_SUCCESS: t('Successfully processed.'),
    PROGRESS_SUCCESS: t('Successfully progressed.'),
    ASSIGN_SUCCESS: t('Successfully assigned.'),
    SYNC_SUCCESS: t('Successfully synchronized.'),
    PUT_SUCCESS: t('Successfully put.'),
    RECEIVE_SUCCESS: t('Successfully received.'),
    REDIRECT_SUCCESS: t('Redirecting. Do not leave the page.'),
    READ_SUCCESS: t('Successfully marked as read.'),
    ATTACH_SUCCESS: t('Successfully attached'),
    ATTACH_REMOVE_SUCCESS: t('Successfully removed attachment'),
    IMPORT_REQUEST_SUCCESS: t('Import request submitted successfully')
  }

  const FAIL = {
    CREATE_FAIL: t('Failed to create.'),
    UPDATE_FAIL: t('Failed to update.'),
    DELETE_FAIL: t('Failed to delete.'),
    ALLOCATE_FAIL: t('Failed to allocate.'),
    GENERATE_FAIL: t('Failed to generate.'),
    RECALCULATE_FAIL: t('Failed to recalculate.'),
    SET_FAIL: t('Failed to set.'),
    COPY_FAIL: t('Failed to copy.'),
    RESET_FAIL: t('Failed to reset.'),
    COMPLETE_FAIL: t('Failed to complete.'),
    CONTAINER_FIND_FAIL: t('Container not found.'),
    EXIST_FAIL: t('Does not exist.'),
    PROGRESS_FAIL: t('Failed to progress.'),
    WEBHOOK_FAIL: t('Failed to progress.'),
    NOTIFICATION_FAIL: t('Failed to receive notification.'),
    NO_UNITS: t('No units was found')
  }

  const OTHER = {
    NOTIFICATION_WAIT: t('Wait for notification.'),
    EMAIL_SENT: t('Email is sent.'),
  }

  const getUploadNotification = (listingName) => {
    return `Import request submitted successfully. Process runs in the background. Entries will be added to
     ${listingName} once completed. Process duration varies based on import file volume.`
  }

  return {
    ...SUCCESS,
    ...FAIL,
    ...OTHER,
    getUploadNotification
  }
}

export default useMessages
