import { pick, prop } from 'ramda'
import { DEFAULT_SEARCH_PARAMS } from 'storfox-route-hooks'

import { orderingToSnackCase, escapeAtob } from '~/utils'

export function BucketFilterSerializer (params) {
  const defaultParams = pick(DEFAULT_SEARCH_PARAMS, params)
  const companyId = escapeAtob(prop('company', params))

  return orderingToSnackCase({
    ...defaultParams,
    companyId
  })
}

export function BucketCreateSerializer (data) {
  const name = prop('name', data)
  const company = prop('company', data)
  return { name, company }
}
