import React from 'react'
import TableCell from '@mui/material/TableCell'
import PropTypes from 'prop-types'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import { useTranslation } from 'react-i18next'
import { path, prop } from 'ramda'

import { ExpandableTableRow, ExpandedTableWrapper } from '~/components/Table'

function LineItemRow ({ children, units }) {
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(false)

  return (
    <>
      <ExpandableTableRow open={open} onExpandToggle={() => setOpen(!open)}>
        {children}
      </ExpandableTableRow>

      <ExpandedTableWrapper open={open} colSpan={11}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t('Unit number')}</TableCell>
              <TableCell>{t('condition')}</TableCell>
              <TableCell>{t('Quantity')}</TableCell>
              <TableCell>{t('Location')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {units.map(item => {
              const unit = prop('unit', item)
              const condition = path(['condition', 'code'], unit)
              const location = path(['location', 'locationId'], unit)
              const quantity = prop('quantity', unit)
              const unitNumber = path(['unitNumber'], unit)
              return (
                <TableRow key="unit">
                  <TableCell>
                    {unitNumber}
                  </TableCell>
                  <TableCell>
                    {condition}
                  </TableCell>
                  <TableCell>
                    {quantity}
                  </TableCell>
                  <TableCell>
                    {location}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </ExpandedTableWrapper>
    </>
  )
}

LineItemRow.propTypes = {
  children: PropTypes.any.isRequired,
  units: PropTypes.object.isRequired
}

export default LineItemRow
