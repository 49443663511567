import React from 'react'
import { useDeepCompareEffect } from 'storfox-api-hooks'
import { useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import { useNotificationCountUpdate } from '~/components/Notification/NotificationProvider'
import useMessages from '~/hooks/useMessages'

import { useNotificationList, useNotificationUnread, useReadAllNotifications } from '../hooks'
import NotificationList from '../components/NotificationList/NotificationList'

function NotificationListContainer () {
  const notificationList = useNotificationList()
  const notificationUnread = useNotificationUnread()
  const readAllNotifications = useReadAllNotifications()

  const messages = useMessages()
  const snackbar = useSnackbar()
  const { updateCount } = useNotificationCountUpdate()

  const handleListLoad = () =>
    notificationList.getList()
      .then(() => updateCount())

  const handleAllNotificationsRead = () =>
    readAllNotifications.create()
      .then(() => handleListLoad())
      .then(() => snackbar({ message: messages.READ_SUCCESS }))

  useDeepCompareEffect(() => {
    handleListLoad()
  }, [])

  const breadcrumbs = { title: 'Notifications' }

  return (
    <DashboardLayout
      title="Notifications"
      activeNav={null}
      breadcrumbs={breadcrumbs}
    >
      <NotificationList
        list={notificationList}
        unreadCount={notificationUnread.detail}
        onListLoad={handleListLoad}
        onAllRead={handleAllNotificationsRead}
        readAllLoading={readAllNotifications.isLoading}
      />
    </DashboardLayout>
  )
}

export default NotificationListContainer
