import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Drawer, { drawerClasses } from '@mui/material/Drawer'
import Fade from '@mui/material/Fade'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import LinearProgress from '@mui/material/LinearProgress'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import { styled } from '@mui/material'

import FilterForm from './FilterForm'
import FilterSave from './FilterSave'

const SliderBoxStyled = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(6),
  zIndex: 9,
  background: theme.palette.background.paper,
  width: '100%',
  height: `calc(100% - ${theme.spacing(6)})`
}))

const DrawerStyled = styled(Drawer)({
  [`& .${drawerClasses.paper}`]: {
    width: 420,
    maxWidth: '100%',
    overflow: 'hidden'
  }
})

const FORM = 'form'
const SAVE = 'save'

function FilterDialog (props) {
  const {
    open,
    handleClose,
    handleClear,
    onSubmit,
    initialValues,
    backend,
    children,
  } = props

  const [active, setActive] = useState(FORM)

  return (
    <DrawerStyled
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      <Paper
        variant="outlined"
        square={true}
        sx={{
          zIndex: 10,
          width: '100%',
          position: 'relative',
        }}
      >
        <Tabs
          onChange={(event, value) => setActive(value)}
          value={active}
          variant="fullWidth"
          indicatorColor="primary"
        >
          <Tab sx={{ minWidth: theme => theme.spacing(6) }} label="Filter" value={FORM} />
          <Tab sx={{ minWidth: theme => theme.spacing(6) }} label="Save" value={SAVE} />
        </Tabs>
        {backend.isLoading && (
          <LinearProgress sx={{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            height: 2
          }} />
        )}
      </Paper>
      <Box sx={{ height: 'calc(100% - 50px)' }}>
        <Fade
          in={active === FORM}
          mountOnEnter={true}
          unmountOnExit={true}
        >
          <Box sx={{ height: '100%' }}>
            <FilterForm
              onSubmit={onSubmit}
              onClear={handleClear}
              onClose={handleClose}
              initialValues={initialValues}
            >
              {children}
            </FilterForm>
          </Box>
        </Fade>
        <Fade
          in={active === SAVE}
          mountOnEnter={true}
          unmountOnExit={true}
        >
          <SliderBoxStyled>
            <FilterSave
              backend={backend}
              currentValue={initialValues}
              onClose={handleClose}
            />
          </SliderBoxStyled>
        </Fade>
      </Box>
    </DrawerStyled>
  )
}

FilterDialog.propTypes = {
  initialValues: PropTypes.object,
  children: PropTypes.node.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleClear: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  backend: PropTypes.object.isRequired,
}

export default FilterDialog
