import React from 'react'
import Grid from '@mui/material/Grid'

import StatusField from '~/components/Fields/StatusField'
import TextField from '~/components/Fields/TextField'

function SallaCreateInstruction () {
  return (
    <>
      <Grid item={true} xs={12} lg={8}>
        <TextField
          data-cy="name"
          name="name"
          label="Name"
        />
      </Grid>

      <Grid item={true} xs={12} lg={4}>
        <StatusField />
      </Grid>

      <Grid item={true} xs={12} lg={4}>
        <TextField
          data-cy="salla-client-id"
          name="sallaClientId"
          label="Client ID"
        />
      </Grid>

      <Grid item={true} xs={12} lg={4}>
        <TextField
          data-cy="salla-client-secret-key"
          name="sallaClientSecretKey"
          label="Client Secret Key"
        />
      </Grid>

      <Grid item={true} xs={12} lg={4}>
        <TextField
          data-cy="salla-webhook-secret"
          name="sallaWebhookSecret"
          label="Webhook Secret"
        />
      </Grid>

      <Grid item={true} xs={12}>
        <TextField
          data-cy="description"
          name="description"
          label="Description"
          multiline={true}
          minRows={4}
        />
      </Grid>
    </>
  )
}

export default SallaCreateInstruction
