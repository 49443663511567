import { useCallback, useMemo, useReducer } from 'react'

import { FAIL, initialState, PENDING, reducer, SUCCESS } from './state'
import { getDataFromError, getDataFromSuccess } from './utils'

import useRequest from '../useRequest'

function useGetApi (url, isLoading = true) {
  const initState = useMemo(() => ({ ...initialState, isLoading }), [isLoading])
  const request = useRequest()
  const [state, dispatch] = useReducer(reducer, initState)

  const requestCallback = useCallback((params, options) => {
    dispatch({ type: PENDING })

    return request.get(url, params, options)
      .then(response => {
        const data = getDataFromSuccess(response)
        dispatch({ type: SUCCESS, payload: data })

        return data
      })
      .catch(response => {
        const error = getDataFromError(response)
        dispatch({ type: FAIL, payload: error })

        return Promise.reject(error)
      })
  }, [request, url])

  return { ...state, get: requestCallback }
}

export default useGetApi
