import React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useSnackbar } from 'storfox-snackbar'
import { path, propOr } from 'ramda'

import { HtmlLayout } from '~/components/Layouts'
import useMessages from '~/hooks/useMessages'
import * as ROUTES from '~/constants/routes'
import { RECEIVE_TABS } from '~/constants/tabs'

import { ReceiveReturnInitSerializer, ReceivingReturnSerializer } from '../serializers/returnSerializer'
import ReceiveReturnBarcoding from '../components/ReceiveBarcoding/ReceiveReturnBarcoding'
import { useReceiveBarcodeConditionList, useReceiveReturnsDetail, useReceiveReturnsScanReceive } from '../hooks'

function ReceiveReturnBarcodingContainer () {
  const { guid } = useParams()
  const snackbar = useSnackbar()
  const messages = useMessages()
  const navigate = useNavigate()
  const receiveConditionList = useReceiveBarcodeConditionList()
  const receiveReturnsDetail = useReceiveReturnsDetail(guid)
  const receiveReturnsScanReceive = useReceiveReturnsScanReceive(guid)

  const number = propOr('Receive PO', 'number', receiveReturnsDetail.detail)
  const conditions = receiveConditionList.results

  const handleSubmit = values =>
    receiveReturnsScanReceive.receive(ReceivingReturnSerializer(values, guid))
      .then(() => receiveReturnsDetail.getDetail().then((response) => {
        const received = path(['result', 'received'], response)
        const ordered = path(['result', 'ordered'], response)
        if (received === ordered) {
          const url = generatePath(ROUTES.RECEIVE_RETURN_DETAIL_PATH, { guid, tab: RECEIVE_TABS.GENERAL })
          navigate(url)
        }
      }))
      .then(() => snackbar({ message: messages.RECEIVE_SUCCESS }))

  const isLoading = receiveReturnsDetail.isLoading || receiveReturnsScanReceive.isLoading

  return (
    <HtmlLayout title={number}>
      <ReceiveReturnBarcoding
        initialValues={ReceiveReturnInitSerializer(receiveReturnsDetail.detail)}
        conditions={conditions}
        pageTitle={number}
        conditionListLoading={receiveConditionList.isLoading}
        isLoading={isLoading}
        detailLoading={receiveReturnsDetail.isLoading}
        onSubmit={handleSubmit}
      />
    </HtmlLayout>
  )
}

export default ReceiveReturnBarcodingContainer
