import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import { Filter } from 'storfox-filter'
import { prop } from 'ramda'

import Accordion from '~/components/Accordion'

import AreaStatusField from './AreaStatusField'

import { AREA_FILTER_NAME as name } from '../../../constants'

export const toParams = values => {
  const status = prop('status', values)

  return { status }
}

export const toValues = (params) => {
  const status = prop('status', params)

  return { status }
}
export const transformer = { toParams, toValues }

export const areaFilterOptions = {
  name,
  transformer
}

function AreaFilterForm (props) {
  return (
    <Filter {...props}>
      <Accordion title="General" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <AreaStatusField />
          </Grid>
        </Grid>
      </Accordion>
    </Filter>
  )
}

AreaFilterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
}

export default AreaFilterForm
