import React from 'react'
import isEqual from 'react-fast-compare'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import MUITextField from '@mui/material/TextField'

import NumberFormat from './NumberFormat'

function FloatField ({ name, InputProps, ...defaultProps }) {
  return (
    <Field name={name}>
      {({ input, meta }) => (
        <MUITextField
          value={input.value}
          onChange={event => input.onChange(event.target.value)}
          error={meta.invalid}
          helperText={meta.submitError || meta.error}
          {...defaultProps}
          InputProps={{
            inputComponent: NumberFormat,
            ...InputProps
          }}
        />
      )}
    </Field>
  )
}

FloatField.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  InputProps: PropTypes.object.isRequired
}

FloatField.defaultProps = {
  size: 'small',
  fullWidth: true,
  variant: 'outlined',
  InputProps: {}
}

export default React.memo(FloatField, isEqual)
