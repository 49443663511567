import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { Grid } from '@mui/material'
import Divider from '@mui/material/Divider'
import PropTypes from 'prop-types'
import { path, prop } from 'ramda'

import { CardHeader } from '~/components/Cards'
import TextLabelLoader from '~/components/TextLabelLoader'

function ShipmentDetailMethodCard ({ shipment }) {
  const shippingMethodName = path(['shippingMethod', 'name'], shipment)
  const serviceName = path(['service', 'name'], shipment)
  const trackingNumber = prop('trackingNumber', shipment)
  const shippingPriceValue = prop('shippingPrice', shipment)
  const currency = prop('currency', shipment)
  const shippingPrice = shippingPriceValue ? `${currency} ${shippingPriceValue}` : ''
  const vehicleNumber = prop('vehicleNumber', shipment)

  return (
    <Card>
      <CardHeader title="Method" />
      <Divider />
      <CardContent>
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={12}>
            <TextLabelLoader label="Carrier">
              {shippingMethodName}
            </TextLabelLoader>
          </Grid>
          <Grid item={true} xs={12}>
            <TextLabelLoader label="Service">
              {serviceName}
            </TextLabelLoader>
          </Grid>
          <Grid item={true} xs={12}>
            <TextLabelLoader label="Tracking number">
              {trackingNumber}
            </TextLabelLoader>
          </Grid>

          <Grid item={true} xs={12}>
            <TextLabelLoader label="Shipment fee">
              {shippingPrice}
            </TextLabelLoader>
          </Grid>

          <Grid item={true} xs={12}>
            <TextLabelLoader label="Vehicle plate number">
              {vehicleNumber}
            </TextLabelLoader>
          </Grid>

        </Grid>
      </CardContent>
    </Card>
  )
}

ShipmentDetailMethodCard.propTypes = {
  shipment: PropTypes.object.isRequired
}

export default ShipmentDetailMethodCard
