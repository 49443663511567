import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import BucketListContainer from './containers/BucketListContainer'
import BucketCreateContainer from './containers/BucketCreateContainer'
import BucketUpdateContainer from './containers/BucketUpdateContainer'

export default () => {
  return [
    {
      layout: AuthLayout,
      path: ROUTES.BUCKETS_LIST_PATH,
      component: BucketListContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.BUCKETS_CREATE_PATH,
      component: BucketCreateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.BUCKETS_UPDATE_PATH,
      component: BucketUpdateContainer
    }
  ]
}
