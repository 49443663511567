import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import PropTypes from 'prop-types'
import Link from '@mui/material/Link'
import { pathOr, prop, propOr } from 'ramda'

import { CardHeader } from '~/components/Cards'
import { Volume, Weight } from '~/components/Converters'
import { ShipmentStatus } from '~/components/Statuses'
import TextOverflow from '~/components/TextOverflow'
import CurrencyMoney from '~/components/Money/CurrencyMoney'
import { generateCdnUrl } from '~/utils'

function ShipmentDetailInfo ({ shipment }) {
  const { t } = useTranslation()

  const shippingDetail = prop('shippingDetail', shipment)
  const status = propOr('', 'status', shipment)
  const carrierStatus = prop('carrierStatus', shipment)
  const trackingUrl = propOr('', 'trackingUrl', shipment)
  const awbUrl = prop('awbUrl', shipment)
  const gatepass = pathOr('', ['gatepass', 'fileUrl'], shipment)

  const totalWeight = prop('totalWeight', shippingDetail)
  const volume = propOr(0, 'totalVolume', shippingDetail)
  const shippingFee = propOr(0, 'toObjectShippingFee', shipment)
  const containers = propOr(0, 'containersCount', shippingDetail)
  const currency = prop('currency', shippingDetail)

  const fromCountryId = pathOr('', ['fromAddress', 'country', 'id'], shipment)
  const toCountryId = pathOr('', ['shippingAddress', 'country', 'id'], shipment)

  const shipmentType = useMemo(() => {
    if (fromCountryId === toCountryId) {
      return 'Local'
    } else {
      return 'International'
    }
  }, [fromCountryId, toCountryId])

  return (
    <Card>
      <CardHeader title={t('Shipment')} />
      <Divider />
      <CardContent style={{ padding: 0 }}>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell>{t('Status')}</TableCell>
              <TableCell>
                <ShipmentStatus value={status} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('Carrier status')}</TableCell>
              <TableCell>
                {carrierStatus}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('Shipment type')}</TableCell>
              <TableCell>
                {t(shipmentType)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('Net weight')}</TableCell>
              <TableCell>
                <Weight value={totalWeight} />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>{t('Dimensions')}</TableCell>
              <TableCell>
                <Volume value={volume} />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>{t('Shipping fee')}</TableCell>
              <TableCell>
                <CurrencyMoney currency={currency} value={shippingFee} />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>{t('Containers')}</TableCell>
              <TableCell>{containers}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Tracking url</TableCell>
              <TableCell>
                <Link
                  href={generateCdnUrl(trackingUrl)}
                  rel="noopener noreferrer"
                  underline="always"
                  target="_blank"
                >
                  <TextOverflow selfTooltip={true} lines={1}>
                    {generateCdnUrl(trackingUrl)}
                  </TextOverflow>
                </Link>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>AWB url</TableCell>
              <TableCell>
                <Link
                  href={generateCdnUrl(awbUrl)}
                  rel="noopener noreferrer"
                  underline="always"
                  target="_blank"
                >
                  <TextOverflow selfTooltip={true} lines={1}>
                    {generateCdnUrl(awbUrl)}
                  </TextOverflow>
                </Link>
              </TableCell>
            </TableRow>

            {gatepass && (
              <TableRow>
                <TableCell>Gatepass</TableCell>
                <TableCell>
                  <Link
                    href={generateCdnUrl(gatepass)}
                    rel="noopener noreferrer"
                    underline="always"
                    target="_blank"
                  >
                    <TextOverflow selfTooltip={true} lines={1}>
                      {generateCdnUrl(gatepass)}
                    </TextOverflow>
                  </Link>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}

ShipmentDetailInfo.propTypes = {
  shipment: PropTypes.object
}

export default ShipmentDetailInfo
