import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import PicklistListContainer from './containers/PicklistListContainer'
import PicklistDetailContainer from './containers/PicklistDetailContainer'
import PicklistImportContainer from './containers/PicklistImportContainer'

export default () => {
  return [
    {
      layout: AuthLayout,
      path: ROUTES.PICKLIST_LIST_PATH,
      component: PicklistListContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.PICKLIST_UPDATE_PATH,
      component: PicklistDetailContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.PICKLIST_IMPORT_PATH,
      component: PicklistImportContainer
    },
  ]
}
