import React, { useCallback, useEffect } from 'react'
import { useField } from 'react-final-form'
import PropTypes from 'prop-types'
import { prop } from 'ramda'

import MoneyField from '~/components/Fields/MoneyField'

function SaleOrderMoney ({ name, conditionFieldName, ...props }) {
  const field = useField(name)
  const conditionField = useField(conditionFieldName)
  const conditionDirty = conditionField.meta.dirty && conditionField.meta.modified
  const condition = conditionField.input.value

  const handleChange = useCallback(field.input.onChange, [])

  useEffect(() => {
    const retailPrice = prop('retailPrice', condition)
    if (conditionDirty && retailPrice) {
      handleChange(retailPrice)
    }
  }, [condition, conditionDirty, handleChange])

  return (
    <MoneyField
      name={name}
      data-cy={name}
      {...props}
    />
  )
}

SaleOrderMoney.propTypes = {
  name: PropTypes.string.isRequired,
  conditionFieldName: PropTypes.string.isRequired
}

export default SaleOrderMoney
