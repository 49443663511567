import React from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import { prop } from 'ramda'
import { SingleImagePreview } from 'storfox-image-upload'
import PropTypes from 'prop-types'

import { CardHeader } from '~/components/Cards'
import TextLabelLoader from '~/components/TextLabelLoader'

function CustomerDetailGeneral ({ detail }) {
  const firstName = prop('firstName', detail)
  const lastName = prop('lastName', detail)
  const email = prop('email', detail)
  const mobile = prop('mobile', detail)
  const imagePath = prop('imagePath', detail)

  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12} lg={8}>
        <Box mb={3}>
          <Card>
            <CardHeader title="Information" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12} lg={6}>
                  <TextLabelLoader label="First name">
                    {firstName}
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12} lg={6}>
                  <TextLabelLoader label="Last name">
                    {lastName}
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Email">
                    {email}
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Phone number">
                    {mobile}
                  </TextLabelLoader>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>

      </Grid>
      <Grid item={true} xs={12} lg={4}>
        <Box mb={3}>
          <Card>
            <CardHeader title="Image" />
            <Divider />
            <CardContent>
              <SingleImagePreview src={imagePath} />
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </Grid>
  )
}

CustomerDetailGeneral.propTypes = {
  detail: PropTypes.object.isRequired
}

export default CustomerDetailGeneral
