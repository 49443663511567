import React from 'react'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

import Container, { Content, Header } from '~/components/Container'
import PageTitle from '~/components/PageTitle'
import { DiscardButton, SaveButton } from '~/components/Buttons'
import { withForm } from '~/components/Form'
import * as NAV from '~/constants/nav-titles'

import BillingProfileCreateTabs from './BillingProfileCreateTabs'

import BillingProfileStorage from '../BillingProfileStorage'
import BillingProfileGeneral from '../BillingProfileGeneral'
import BillingProfileFulfillment from '../BillingProfileFulfillment'
import BillingProfilePacking from '../BillingProfilePacking'
import BillingProfileAdhoc from '../BillingProfileAdhoc'
import { ADHOC, FULFILLMENT, GENERAL, PACKING, STORAGE } from '../../../constants/BillingProfile'

function BillingProfileCreate ({ form, isLoading }) {
  const { tab } = useParams()
  const { handleSubmit } = form

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            pageTitle="New billing profile"
            parentTitle={NAV.CONFIGURATION}
            rightButton={(
              <>
                <DiscardButton />
                <SaveButton disabled={isLoading} />
              </>
            )}
          />
        </Header>
        <Content>
          <BillingProfileCreateTabs />

          {tab === GENERAL && <BillingProfileGeneral />}
          {tab === STORAGE && <BillingProfileStorage />}
          {tab === FULFILLMENT && <BillingProfileFulfillment />}
          {tab === PACKING && <BillingProfilePacking />}
          {tab === ADHOC && <BillingProfileAdhoc />}
        </Content>
      </Container>
    </form>
  )
}

BillingProfileCreate.propTypes = {
  form: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default withForm(BillingProfileCreate)
