import React from 'react'
import QRCode from 'qrcode.react'
import PropTypes from 'prop-types'
import { prop, propOr } from 'ramda'
import Typography from '@mui/material/Typography'
import { Card, styled } from '@mui/material'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

import { getCodes, multiSku } from '../../../utils'

const TypographyStyled = styled(Typography)({
  textAlign: 'center',
  paddingTop: 10,
  color: 'black',
  fontSize: '16px',
  fontWeight: 'bold'
})

function QrCodeItems ({ items, quantity, fontSize }) {
  const multiQrCodes = multiSku(items, quantity)
  const qrCodes = getCodes(multiQrCodes)

  return (
    <Grid container={true} spacing={2}>
      {qrCodes.map((item, index) => {
        const qrCode = prop('code', item)
        const qrCodeLength = qrCode.length
        const titles = propOr([], 'titles', item)
        const fontSizeResize = qrCodeLength > 40 ? '4px' : qrCodeLength > 20 ? '8px' : `${fontSize}px`

        return (
          <Grid key={index} item={true} xs={12} lg={6}>
            <Card sx={{ textAlign: 'center', py: 2 }}>
              <Box display="inline-block" py={1} px={2} textAlign="center">
                <QRCode value={qrCode} />
                <TypographyStyled style={{ fontSize: fontSizeResize }}>{qrCode}</TypographyStyled>

                {titles.map((title, index) => (
                  <Typography key={index} align="center">
                    <strong>{title}</strong>
                  </Typography>
                ))}
              </Box>
            </Card>
          </Grid>
        )
      })}
    </Grid>
  )
}

QrCodeItems.propTypes = {
  items: PropTypes.array.isRequired,
  quantity: PropTypes.number.isRequired,
  fontSize: PropTypes.number.isRequired
}

export default QrCodeItems
