import React, { useEffect } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { toCamelCase } from 'storfox-api-hooks'
import { prop } from 'ramda'

import { escapeAtob } from '~/utils'
import AppContainer from '~/components/AppContainer'
import { useToken } from '~/components/Token'
import { useCompany, useMe, useProfile } from '~/components/Profile'
import * as ROUTES from '~/constants/routes'
import { OVERVIEW_TABS } from '~/constants/tabs'

import { useLogin } from '../hooks'
import SignIn from '../components/SignIn'

const toObject = data => {
  try {
    return toCamelCase(escapeAtob(data))
  } catch (e) {}
}

function SignInContractContainer () {
  const navigate = useNavigate()
  const { login, isLoading } = useLogin()
  const { getMe } = useMe()
  const { token, setToken } = useToken()
  const { setProfile } = useProfile()
  const { contract } = useParams()
  const contractInfo = toObject(contract) || {}

  const email = prop('email', contractInfo)

  const { isThreePl } = useCompany()

  const tab = isThreePl ? OVERVIEW_TABS.FULFILLMENT : OVERVIEW_TABS.SALES
  const redirectTo = generatePath(ROUTES.OVERVIEW_PATH, { tab })

  useEffect(() => {
    if (!contractInfo) {
      navigate(ROUTES.SIGN_IN, { replace: true })
    }
  }, [contractInfo, navigate])

  useEffect(() => {
    if (token) {
      navigate(redirectTo)
    }
  }, [navigate, redirectTo, token])

  const handleSubmit = formValues =>
    login(formValues)
      .then(({ token }) => setToken(token))
      .then(() => getMe())
      .then(({ result }) => setProfile(result))
      .then(() => {
        const path = generatePath(ROUTES.CONFIRMATION, { contract })
        navigate(path, { replace: true })
      })

  return (
    <AppContainer title="Sign In">
      <SignIn
        initialValues={{ email }}
        contractInfo={contractInfo}
        isLoading={isLoading}
        onSubmit={handleSubmit}
      />
    </AppContainer>
  )
}

export default SignInContractContainer
