import React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import PropTypes from 'prop-types'

import MoneyField from '~/components/Fields/MoneyField'

import FilterLocationFields from '../../Fields/FilterLocationFields'

function StoragePerCubicMeter ({ name }) {
  return (
    <>
      <Typography gutterBottom={true}>Conditions</Typography>

      <FilterLocationFields name={name} />

      <Box mt={2}>
        <Typography gutterBottom={true}>Pricing</Typography>
        <Grid container={true}>

          <Grid item={true} xs={12} lg={4}>
            <MoneyField name={`${name}.price`} label="Price per cubic meter" />
          </Grid>

        </Grid>
      </Box>
    </>
  )
}

StoragePerCubicMeter.propTypes = {
  name: PropTypes.string.isRequired
}

export default StoragePerCubicMeter
