import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import PropTypes from 'prop-types'

import EmailField from '~/components/Fields/EmailField'
import MultiSelectSearchField from '~/components/Fields/MultiSelectSearchField'
import StatusField from '~/components/Fields/StatusField'
import TextField from '~/components/Fields/TextField'
import { CardHeader } from '~/components/Cards'
import * as API from '~/constants/api'
import { RETAILER } from '~/constants/companyTypes'
import UserSearchField from '~/components/Fields/UserField'

import BillingField from '../Fields/BillingField'
import DelegatedAccessSwitchField from '../Fields/DelegatedAccessSwitchField'
import ShipoxCard from '../ShipoxCard'

function ContractUpdateFormGeneral ({ isClient, companyType, delegatedAccess }) {
  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12} lg={8}>
        <Box mb={3}>
          <Card>
            <CardHeader title="Invite" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <EmailField
                    disabled={true}
                    data-cy="email"
                    name="toEmail"
                    label="Email"
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <TextField
                    disabled={true}
                    data-cy="type"
                    name="type"
                    label="Type"
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <TextField
                    data-cy="message"
                    name="message"
                    label="Message"
                    disabled={isClient}
                    multiline={true}
                    rows="4"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>

      <Grid item={true} xs={12} lg={4}>
        <Box mb={3}>
          <Card>
            <CardHeader title="Settings" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <MultiSelectSearchField
                    api={API.WAREHOUSE_LIST}
                    data-cy="warehouses"
                    name="warehouses"
                    label="Shared Warehouses"
                    disabled={isClient}
                    ListboxProps={{ 'data-cy': 'warehouseList' }}
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <BillingField disabled={isClient} />
                </Grid>

                <Grid item={true} xs={12}>
                  <StatusField disabled={isClient} />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>

        {companyType === RETAILER && (
          <Box mb={3}>
            <Card>
              <CardHeader title="Delegated Access" />
              <Divider />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={12}>
                    <DelegatedAccessSwitchField
                      isClient={isClient}
                    />
                  </Grid>

                  <Grid item={true} xs={12}>
                    <UserSearchField
                      name="delegatedUser"
                      data-cy="delegatedUser"
                      label="User with access"
                      ListboxProps={{ 'data-cy': 'userList' }}
                      disabled={!delegatedAccess}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        )}

        <Box>
          <ShipoxCard isClient={isClient} />
        </Box>
      </Grid>
    </Grid>
  )
}

ContractUpdateFormGeneral.propTypes = {
  isClient: PropTypes.bool.isRequired,
  companyType: PropTypes.string.isRequired,
  delegatedAccess: PropTypes.bool.isRequired
}

export default ContractUpdateFormGeneral
