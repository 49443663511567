import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'

const RootStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  paddingTop: 56,
  [theme.breakpoints.up('sm')]: {
    paddingTop: 64,
  }
}))

function Content ({ children, className, ...props }) {
  return (
    <RootStyled
      className={className}
      {...props}
    >
      {children}
    </RootStyled>
  )
}

Content.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
}

export default Content
