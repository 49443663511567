import React from 'react'
import { Card, CardContent, Divider, Grid } from '@mui/material'
import PropTypes from 'prop-types'
import { path } from 'ramda'

import { CardHeader } from '~/components/Cards'
import TextLabelLoader from '~/components/TextLabelLoader'

import LineItems from './LineItems'
import MovementSummary from './MovementSummary'
import CustomerNotes from './CustomerNotes'

import MovementStatus from '../../components/MovementStatus'

function MovementDetailGeneral ({ isLoading, rows, notes, initialValues, count, status }) {
  const warehouse = path(['warehouse', 'name'], initialValues)
  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} lg={12}>
        <Card>
          <CardHeader title="Move in" />
          <Divider />
          <CardContent>
            <Grid container={true} spacing={3}>
              <Grid item={true} xs={3}>
                <TextLabelLoader label="Warehouse">
                  {warehouse}
                </TextLabelLoader>
              </Grid>
              <Grid item={true} xs={3}>
                <TextLabelLoader label="Status">
                  <MovementStatus value={status} />
                </TextLabelLoader>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item={true} lg={12}>
        <Card>
          <CardHeader title="Stock" />
          <Divider />
          <LineItems isLoading={isLoading} rows={rows} count={count} />
        </Card>
      </Grid>
      <Grid item={true} lg={12}>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={6}>
            <Card>
              <CardHeader title="Invoice Summary" />
              <Divider />
              <MovementSummary lineItems={rows} />
            </Card>
          </Grid>
          <Grid item={true} lg={6}>
            <Card>
              <CardHeader title="Customer Notes" />
              <Divider />
              <CardContent>
                <CustomerNotes notes={notes} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

MovementDetailGeneral.propTypes = {
  isLoading: PropTypes.bool,
  rows: PropTypes.array,
  notes: PropTypes.string,
  initialValues: PropTypes.object,
  count: PropTypes.number,
  status: PropTypes.string
}

export default MovementDetailGeneral
