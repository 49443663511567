import React, { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { always, path, prop, propOr } from 'ramda'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'
import { useDeepCompareEffect, useRequest } from 'storfox-api-hooks'
import { sprintf } from 'sprintf-js'

import { DashboardLayout } from '~/components/Layouts'
import Title from '~/components/Title'
import { COMPLETED, DRAFT, NEW_ORDER } from '~/components/Statuses/SaleOrderStatus'
import ErrorLink from '~/components/ErrorLink'
import { SALE_ORDER_DETAIL_TABS } from '~/constants/tabs'
import * as NAV from '~/constants/nav-titles'
import useMessages from '~/hooks/useMessages'
import { useNotification } from '~/components/Notification'
import { SALE_ORDER_ALLOCATION_STATUS } from '~/constants/notification-topics'
import { useGoogleEvent } from '~/components/GoogleAnalytics/GoogleAnalytics'
import * as API from '~/constants/api'
import useDialog from '~/hooks/useDialog'

import ShipmentDialogForm from '../components/ShipmentDialogForm'
import {
  SaleOrderDetailSerializer,
  SaleOrderProcessSerializer,
  SaleOrderProcessSkipSerializer,
  SaleOrderSplitSerializer
} from '../serializers'
import {
  useInvoiceGenerate,
  useSaleOrderCancel,
  useSaleOrderComments,
  useSaleOrderCommentsSend,
  useSaleOrderDetail,
  useSaleOrderHistory,
  useSaleOrderListAllocate,
  useSaleOrderMarkAsPaid,
  useSaleOrderPackingList,
  useSaleOrderPicklist,
  useSaleOrderProcess,
  useSaleOrderResolve,
  useSaleOrderReturnList,
  useSaleOrderShipmentList,
  useSaleOrderSkipProcess,
  useSaleOrderSplit,
  useSaleOrderUnitList,
  useWarehouseList,
  useSaleOrderPutawayList,
  useSaleOrderRelease,
  useManualUnitList,
  useManualAllocation,
  useHealthCareInvoice
} from '../hooks'
import SaleOrderDetail from '../components/SaleOrderDetail'

function SaleOrderUpdateContainer () {
  const { guid, tab } = useParams()
  const messages = useMessages()
  const snackbar = useSnackbar()
  const { sendEvent } = useGoogleEvent()
  const request = useRequest()
  const shipmentDialog = useDialog()
  const [invoiceLoading, setInvoiceLoading] = useState(false)

  const saleOrderAllocationNotification = useNotification(SALE_ORDER_ALLOCATION_STATUS)

  const saleOrderAllocate = useSaleOrderListAllocate()
  const saleOrderRelease = useSaleOrderRelease(guid)
  const saleOrderDetail = useSaleOrderDetail(guid)
  const saleOrderMarkAsPaid = useSaleOrderMarkAsPaid(guid)
  const saleOrderSplit = useSaleOrderSplit(guid)
  const saleOrderProcessSkip = useSaleOrderSkipProcess(guid)
  const saleOrderCommentsSend = useSaleOrderCommentsSend(guid)
  const warehouseList = useWarehouseList()
  const manualUnitList = useManualUnitList(guid)
  const manualAllocation = useManualAllocation(guid)

  const id = prop('id', saleOrderDetail.detail)
  const saleOrderNumber = prop('number', saleOrderDetail.detail)
  const status = prop('status', saleOrderDetail.detail)
  const historyAutoSend = Boolean(id && tab === SALE_ORDER_DETAIL_TABS.HISTORY)
  const notesAutoSend = Boolean(id && tab === SALE_ORDER_DETAIL_TABS.NOTES)
  const picklistAutoSend = Boolean(id && tab === SALE_ORDER_DETAIL_TABS.PICKLIST)
  const packingListAutoSend = Boolean(id && tab === SALE_ORDER_DETAIL_TABS.PACKING)
  const shipmentListAutoSend = Boolean(id && tab === SALE_ORDER_DETAIL_TABS.SHIPMENT)
  const returnListAutoSend = Boolean(id && tab === SALE_ORDER_DETAIL_TABS.RETURN)
  const unitListAutoSend = Boolean(id && tab === SALE_ORDER_DETAIL_TABS.UNIT)
  const putawayListAutoSend = Boolean(id && tab === SALE_ORDER_DETAIL_TABS.PUTAWAY)

  const saleOrderHistory = useSaleOrderHistory(guid, historyAutoSend)
  const saleOrderCancel = useSaleOrderCancel(id)
  const saleOrderNotes = useSaleOrderComments(guid, notesAutoSend)
  const saleOrderResolve = useSaleOrderResolve(id)
  const saleOrderProcess = useSaleOrderProcess(id)
  const invoiceGenerate = useInvoiceGenerate(guid)
  const healthCareInvoice = useHealthCareInvoice(guid)

  const listSearchParams = { objectIds: id, limit: 1000 }
  const picklistSearchParams = { orderNumber: saleOrderNumber, limit: 1000 }
  const unitListSearchParams = status === COMPLETED
    ? { toObjectIds: id, toObjectTypes: 'SaleOrder', limit: 1000, isArchived: true }
    : { toObjectIds: id, toObjectTypes: 'SaleOrder', limit: 1000 }
  const putawayListParams = { objectType: 'SaleOrder', objectGuid: guid, limit: 1000 }

  const saleOrderPicklist = useSaleOrderPicklist(picklistSearchParams, picklistAutoSend)
  const saleOrderPackingList = useSaleOrderPackingList(listSearchParams, packingListAutoSend)
  const saleOrderShipmentList = useSaleOrderShipmentList(listSearchParams, shipmentListAutoSend)
  const saleOrderReturnList = useSaleOrderReturnList({ orderIds: id, limit: 1000 }, returnListAutoSend)
  const saleOrderUnitList = useSaleOrderUnitList(unitListSearchParams, unitListAutoSend)
  const saleOrderPutawayList = useSaleOrderPutawayList(putawayListParams, putawayListAutoSend)

  useDeepCompareEffect(() => {
    saleOrderAllocationNotification.subscribe(guid, () => {
      saleOrderAllocationNotification.setLoading(false)
      saleOrderDetail.getDetail()
    })

    return () => {
      saleOrderAllocationNotification.unsubscribe()
    }
  }, [guid])

  const handleAllocate = () => {
    saleOrderAllocate.update(SaleOrderProcessSerializer(guid))
      .then(() => {
        saleOrderAllocationNotification.backupSubscribe(() => {
          saleOrderAllocationNotification.setLoading(false)
          saleOrderDetail.getDetail()
          snackbar({ message: messages.NOTIFICATION_FAIL, type: ALTER_ERROR })
        })
        saleOrderAllocationNotification.setLoading(true)
        sendEvent({ eventAction: 'Allocate Sale Order', eventCategory: 'Sale Order' })
        snackbar({ message: messages.ALLOCATE_SUCCESS })
      })
      .catch(error => {
        snackbar({
          type: ALTER_ERROR,
          message: <ErrorLink error={error} />
        })
      })
  }

  const handleRelease = () => {
    saleOrderRelease.create()
      .then(() => snackbar({ message: messages.REQUEST_SUBMITTED }))
      .then(() => saleOrderDetail.getDetail())
      .catch(error => {
        snackbar({
          type: ALTER_ERROR,
          message: <ErrorLink error={error} />
        })
      })
  }

  const handleGenerate = () =>
    saleOrderProcess.process()
      .then(() => {
        sendEvent({ eventAction: 'Process New Sale Order', eventCategory: 'Sale Order' })
        snackbar({ message: messages.GENERATE_SUCCESS })
      })
      .then(() => saleOrderDetail.getDetail())
      .catch(error => snackbar({
        type: ALTER_ERROR,
        message: <ErrorLink error={error} />
      }))

  const handleResolve = () =>
    saleOrderResolve.resolve()
      .then(() => saleOrderDetail.getDetail())
      .then(() => {
        sendEvent({ eventAction: 'Resolve Sale Order', eventCategory: 'Sale Order' })
        snackbar({ message: messages.UPDATE_SUCCESS })
      })
      .catch(error => snackbar({
        type: ALTER_ERROR,
        message: <ErrorLink error={error} />
      }))

  const handleCancel = () =>
    saleOrderCancel.cancel()
      .then(() => saleOrderDetail.getDetail())
      .then(() => {
        sendEvent({ eventAction: 'Cancel Sale Order', eventCategory: 'Sale Order' })
        snackbar({ message: messages.CANCEL_SUCCESS })
      })
      .catch(error => snackbar({
        type: ALTER_ERROR,
        message: <ErrorLink error={error} />
      }))

  const handleCommentSend = value =>
    saleOrderCommentsSend
      .send({ comment: value })
      .then(() => {
        sendEvent({ eventAction: 'Note Sale Order', eventCategory: 'Sale Order' })
        saleOrderNotes.getList()
      })
      .catch(error => snackbar({
        type: ALTER_ERROR,
        message: <ErrorLink error={error} />
      }))

  const handleMarkAsPaid = () =>
    saleOrderMarkAsPaid.mark()
      .then(() => {
        sendEvent({ eventAction: 'Mark Paid Sale Order', eventCategory: 'Sale Order' })
        snackbar({ message: messages.UPDATE_SUCCESS })
      })
      .then(() => saleOrderDetail.getDetail())
      .catch(error => snackbar({
        type: ALTER_ERROR,
        message: <ErrorLink error={error} />
      }))

  const handleInvoiceGenerate = () =>
    invoiceGenerate.generate()
      .then(() => snackbar({ message: messages.NOTIFICATION_WAIT }))
      .then(() => sendEvent({ eventAction: 'Invoice Generate Sale Order', eventCategory: 'Sale Order' }))
      .catch(error => snackbar({
        type: ALTER_ERROR,
        message: <ErrorLink error={error} />
      }))

  const handleHealthCareGenerate = () =>
    healthCareInvoice.generate()
      .then(() => snackbar({ message: messages.NOTIFICATION_WAIT }))
      .catch(error => snackbar({
        type: ALTER_ERROR,
        message: <ErrorLink error={error} />
      }))

  const handleSaleOrderSplit = values =>
    saleOrderSplit.split(SaleOrderSplitSerializer(values))
      .then(() => {
        sendEvent({ eventAction: 'Split Sale Order', eventCategory: 'Sale Order' })
        snackbar({ message: messages.NOTIFICATION_WAIT })
      })
      .catch(error => snackbar({
        type: ALTER_ERROR,
        message: <ErrorLink error={error} />
      }))

  const handleProcessSkip = values =>
    saleOrderProcessSkip.update(SaleOrderProcessSkipSerializer(values))
      .then(() => {
        sendEvent({ eventAction: 'Skip Sale Order', eventCategory: 'Sale Order' })
        snackbar({ message: messages.UPDATE_SUCCESS })
      })
      .then(() => saleOrderDetail.getDetail())
      .catch(error => snackbar({
        type: ALTER_ERROR,
        message: <ErrorLink error={error} />
      }))

  const handlePrintInvoice = (values) => {
    const shipmentGuid = path(['shipment', 'guid'], values)
    if (shipmentGuid) {
      setInvoiceLoading(true)
      const url = sprintf(API.SHIPMENT_GENERATE_COMMERCIAL_INVOICE, shipmentGuid)
      request.get(url)
        .then(() => snackbar({ message: messages.NOTIFICATION_WAIT }))
        .then(() => setInvoiceLoading(false))
        .then(() => shipmentDialog.handleClose())
        .catch(error => {
          const message = <ErrorLink error={error} />
          snackbar({ message, type: ALTER_ERROR })
          setInvoiceLoading(false)
          return Promise.reject(error)
        })
    } else {
      snackbar({ message: 'Select Shipment', type: ALTER_ERROR })
    }
  }

  const onManualAllocate = useCallback((values) => {
    const lineItems = propOr({}, 'lineItems', values)
    const lineItemsValues = Object.values(lineItems)
    const checkedItems = lineItemsValues.filter((item) => prop('checked', item))
    const units = checkedItems.map((item) => ({
      guid: prop('guid', item),
      quantity: propOr(0, 'allocateQuantity', item)
    }))
    return manualAllocation.create({ units })
      .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
      .then(() => saleOrderDetail.getDetail())
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
        return Promise.reject(error)
      })
  }, [saleOrderDetail, manualAllocation, messages, snackbar])

  const processMethods = {
    [DRAFT]: handleAllocate,
    [NEW_ORDER]: handleGenerate
  }

  const handleProcess = propOr(always(null), status, processMethods)

  const referenceNumber = prop('referenceNumber', saleOrderDetail.detail)
  const number = referenceNumber ? `${saleOrderNumber} / Reference Number ${referenceNumber}` : saleOrderNumber
  const title = saleOrderDetail.isLoading ? 'Sale orders' : number

  const pageTitle = <Title title="Sale Order" number={number} />

  const isLoading = saleOrderDetail.isLoading
  const pageTitleLoading = saleOrderDetail.isLoading || saleOrderAllocationNotification.isLoading

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      activeNav={NAV.SALE_ORDERS}
      isLoading={pageTitleLoading}
      breadcrumbs={breadcrumbs}
    >
      <SaleOrderDetail
        tab={tab}
        pageTitle={pageTitle}
        pageTitleLoading={pageTitleLoading}
        isLoading={isLoading}
        orderHistory={saleOrderHistory}
        notes={saleOrderNotes}
        warehouseList={warehouseList}
        detail={SaleOrderDetailSerializer(saleOrderDetail.detail)}
        onProcess={handleProcess}
        onResolve={handleResolve}
        onCancel={handleCancel}
        onCommentSend={handleCommentSend}
        onMarkAsPaid={handleMarkAsPaid}
        onInvoiceGenerate={handleInvoiceGenerate}
        onSaleOrderSplit={handleSaleOrderSplit}
        onAllocate={handleAllocate}
        onRelease={handleRelease}
        onProcessSkip={handleProcessSkip}
        picklist={saleOrderPicklist}
        packingList={saleOrderPackingList}
        shipmentList={saleOrderShipmentList}
        returnList={saleOrderReturnList}
        unitList={saleOrderUnitList}
        putawayList={saleOrderPutawayList}
        onPrintInvoice={shipmentDialog.handleOpen}
        manualUnitList={manualUnitList}
        onManualAllocate={onManualAllocate}
        onHealthCareInvoice={handleHealthCareGenerate}
      />
      <ShipmentDialogForm
        onClose={shipmentDialog.handleClose}
        onSubmit={handlePrintInvoice}
        initialValues={{}}
        open={shipmentDialog.open}
        id={id}
        isLoading={invoiceLoading}
      />
    </DashboardLayout>
  )
}

export default SaleOrderUpdateContainer
