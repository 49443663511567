import React from 'react'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'

import BarcodeInfo from '~/components/Icons/BarcodeInfo'

const RootStyled = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '24px',
  marginTop: '12px',
  '& span': {
    marginLeft: '14px',
    color: '#263238',
    fontSize: '24px',
    fontWeight: 500
  }
})

function BarcodeTitle () {
  return (
    <RootStyled>
      <BarcodeInfo />
      <span>Scan barcode</span>
    </RootStyled>
  )
}

export default BarcodeTitle
