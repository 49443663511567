import React from 'react'
import ReactPhoneInput from 'react-phone-input-2'
import PropTypes from 'prop-types'
import { prop } from 'ramda'
import FormHelperText from '@mui/material/FormHelperText'
import { styled } from '@mui/material'
import { ClassNames } from '@emotion/react'

const SMALL = 'small'
const MEDIUM = 'medium'

const PhoneInputStyled = styled(
  ({ className, ...props }) => (
    <ReactPhoneInput
      containerClass={className}
      {...props}
    />
  ))(({ theme }) => ({
  '& > .form-control': {
    background: 'transparent',
    color: 'black'
  },
  '& > .special-label': {
    fontSize: '10.5px',
    top: '-11px',
    background: theme.palette.background.paper
  },
  '& .form-control:focus, & .form-control:hover': {
    boxShadow: 'none',
    border: `1px solid ${theme.palette.inputHover.color}`
  },
  '& .form-control.invalid-number': {
    border: `1px solid ${theme.palette.error.main}`,
    '&:focus': {
      borderWidth: '2px',
      boxShadow: 'none'
    }
  },
  inputMyProps: {
    display: 'block'
  }
}))

function PhoneInput ({ size, value, label, invalid, helperText, required, ...props }) {
  const dataCy = prop('data-cy', props)

  const labelWithReq = required ? label + ' *' : label

  return (
    <>
      <ClassNames>
        {({ css, cx, theme }) => {
          const inputClass = css({
            outline: 'none !important',
            border: 'none',
            fontSize: '14px !important',
            color: `${theme.palette.text.primary} !important`,
            width: '100% !important',
          })

          const smallClass = css({
            height: '37px',
            paddingTop: '18px',
            paddingBottom: '17px'
          })

          return (
            <PhoneInputStyled
              inputExtraProps={{
                required: true,
                autoFocus: true,
              }}
              specialLabel={labelWithReq}
              inputClass={cx({
                [inputClass]: true,
                [smallClass]: size === SMALL
              }
              )}
              inputProps={{
                'data-cy': dataCy,
                'data-phone-value': value
              }}
              masks={{ 'uz': '(..) ...-..-..' }}
              placeholder="+998 (91) 234-56-78"
              disableDropdown={true}
              value={value}
              isValid={!invalid}
              {...props}
            />
          )
        }}
      </ClassNames>
      {invalid && (
        <FormHelperText sx={{ color: 'error.main' }}>{helperText}</FormHelperText>
      )}
    </>
  )
}

PhoneInput.propTypes = {
  size: PropTypes.oneOf([SMALL, MEDIUM]).isRequired,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  invalid: PropTypes.bool,
  helperText: PropTypes.string,
  'data-cy': PropTypes.string,
  required: PropTypes.bool
}

PhoneInput.defaultProps = {
  size: MEDIUM,
  label: ''
}

export default PhoneInput
