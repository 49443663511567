import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useField } from 'react-final-form'

import SplitButton from '~/components/Buttons/SplitButton'
import { SaveButton } from '~/components/Buttons'

import { SAVE_DRAFT, SAVE_COMPLETE } from '../constants'

function MovementUpdateButton ({ onSubmit, isLoading }) {
  const submitTypeField = useField('submitType')
  const submitTypeFieldChange = submitTypeField.input.onChange

  const onClickSaveDraft = useCallback(() => {
    submitTypeFieldChange(SAVE_DRAFT)
    onSubmit()
  }, [onSubmit, submitTypeFieldChange])

  const onClickSaveComplete = useCallback(() => {
    submitTypeFieldChange(SAVE_COMPLETE)
    onSubmit()
  }, [onSubmit, submitTypeFieldChange])

  const splitButtonOptions = [
    {
      label: 'Save draft',
      button: (
        <SaveButton
          disabled={isLoading}
          children="Save draft"
          type="button"
          onClick={onClickSaveDraft}
        />
      ),
      'data-cy': 'saveDraftMenu',
      onMenuClick: onClickSaveDraft
    },
    {
      label: 'Save & complete',
      button: (
        <SaveButton
          type="button"
          onClick={onClickSaveComplete}
          disabled={isLoading}
          children="Save & complete"
          data-cy="save-complete"
        />
      ),
      'data-cy': 'saveCompleteMenu',
      onMenuClick: onClickSaveComplete
    }
  ]

  return (
    <SplitButton
      name="movementUpdate"
      options={splitButtonOptions}
    />
  )
}

MovementUpdateButton.propTypes = {
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func
}

export default MovementUpdateButton
