import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { useDropzone } from 'react-dropzone'
import * as colors from '@mui/material/colors'
import Box from '@mui/material/Box'
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop'

import IconText from '~/components/IconText'

const RootStyled = styled(Box, {
  shouldForwardProp: propName => propName !== 'isDragActive'
})(({ theme, isDragActive }) => ({
  border: `1px dashed ${theme.palette.divider}`,
  borderRadius: 5,
  height: 200,
  width: '100%',
  outline: 'none',
  display: 'flex',
  textAlign: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
  alignItems: 'center',
  transition: '.25s ease all',
  '&:hover': {
    backgroundColor: theme.palette.background.dark,
    opacity: 0.5,
    cursor: 'pointer'
  },
  ...(isDragActive && ({
    backgroundColor: colors.grey[50],
    opacity: 0.5
  }))
}))

function Dropzone ({ onDrop }) {
  const {
    getRootProps,
    getInputProps,
    isDragActive
  } = useDropzone({ onDrop })

  return (
    <RootStyled isDragActive={isDragActive} {...getRootProps()}>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        height: '100% !important'
      }}>
        <div>
          <input
            {...getInputProps()}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />

          <IconText Icon={VerticalAlignTopIcon} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography align="center" variant="button">
              Add File
            </Typography>
            <Typography align="center" variant="caption" display="block">
              or drop files to upload
            </Typography>
          </IconText>
        </div>
      </Box>
    </RootStyled>
  )
}

Dropzone.propTypes = {
  onDrop: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
}

export default Dropzone
