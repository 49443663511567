import React from 'react'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import { ClickAwayListener } from '@mui/material'
import MenuList from '@mui/material/MenuList'
import MenuItem from '@mui/material/MenuItem'
import Popper from '@mui/material/Popper'
import PropTypes from 'prop-types'
import { prop } from 'ramda'

import Tooltip from '~/components/HtmlTooltip'

function TableSplitPopper ({ open, anchorRef, options, onClose, selected, disabled, onMenuItemClick, loading }) {
  return (
    <Popper sx={{ zIndex: 1000 }} open={open} anchorEl={anchorRef.current} transition={true}>
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={onClose}>
              <MenuList id="bulk-action-button-menu">
                {options.map(option => {
                  const optionDisabled = prop('disabled', option)
                  return (
                    <Tooltip title={optionDisabled ? option.tooltip : ''}>
                      <MenuItem
                        key={option.label}
                        disabled={disabled || optionDisabled || loading}
                        selected={option.label === selected}
                        onClick={event => onMenuItemClick(event, option)}
                        data-cy={option['data-cy']}
                      >
                        {option.component || option.label}
                      </MenuItem>
                    </Tooltip>
                  )
                })}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  )
}

TableSplitPopper.propTypes = {
  open: PropTypes.bool.isRequired,
  selected: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  anchorRef: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onMenuItemClick: PropTypes.func.isRequired,
  loading: PropTypes.bool
}

export default TableSplitPopper
