import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useDeepCompareEffect } from 'storfox-api-hooks'
import { pathOr } from 'ramda'
import { useAllSearchParams } from 'storfox-route-hooks'

import { TableValuesContext } from './context'

function TableValuesProvider ({ children }) {
  const [tables, setTables] = useState({})
  const [selects, setSelects] = useState([])

  const { page, limit } = useAllSearchParams()
  const pathname = pathOr('', ['location', 'pathname'], window)

  useDeepCompareEffect(() => {
    setSelects([])
  }, [pathname, page, limit])

  const getTableValues = useCallback((key) => {
    return tables[key] || { results: [], count: 0 }
  }, [tables])

  const handleTableValuesAdd = useCallback((tableValues) => {
    setTables(prevTable => ({ ...prevTable, [tableValues.key]: tableValues }))
  }, [])

  const resetTableValues = useCallback(() => {
    setTables({})
  }, [])

  const handleTableSelectsAdd = useCallback(values => {
    setSelects(values.filter(value => Boolean(value)))
  }, [])

  const resetTableSelects = useCallback(() => setSelects([]), [])

  const getTableValuesByName = useCallback((name) => {
    const tablesArray = Object.values(tables) || []
    const foundTableValue = tablesArray.find((table) => table.name === name)
    return foundTableValue || { results: [], count: 0 }
  }, [tables])

  const value = {
    getTableValues,
    handleTableValuesAdd,
    getTableValuesByName,
    resetTableValues,
    selects,
    handleTableSelectsAdd,
    resetTableSelects
  }

  return (
    <TableValuesContext.Provider value={value}>
      {children}
    </TableValuesContext.Provider>
  )
}

TableValuesProvider.propTypes = {
  children: PropTypes.node
}

export default TableValuesProvider
