import React from 'react'
import isEqual from 'react-fast-compare'
import PropTypes from 'prop-types'
import MUITextField from '@mui/material/TextField'
import { useTranslation } from 'react-i18next'
import { FieldWrapper } from 'storfox-form-fields'
import { IconButton, InputAdornment, Tooltip } from '@mui/material'
import Info from '@mui/icons-material/Info'

import { convertToSlug } from '~/utils'

const getError = ({ submitError, error }) => (
  submitError ? submitError[0] : error
)

function SlugField ({ input, meta, ...props }) {
  const { t } = useTranslation()
  const error = getError(meta)

  return (
    <MUITextField
      onChange={event => input.onChange(event.target.value)}
      error={meta.invalid}
      value={input.value}
      helperText={t(error)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip
              title={`Slug will be changed to "${convertToSlug(input.value)}"`}
            >
              <IconButton
                edge="end"
                size="small"
                sx={{ color: 'rgba(158, 161, 182, 1)' }}
              >
                <Info />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        )
      }}
      {...props}
    />
  )
}

SlugField.propTypes = {
  size: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired
}

SlugField.defaultProps = {
  size: 'small',
  fullWidth: true,
  variant: 'outlined'
}

export default FieldWrapper(React.memo(SlugField, isEqual))
