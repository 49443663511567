import React, { useMemo, useState } from 'react'
import { always, prop, propOr } from 'ramda'
import { generatePath, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import { useField } from 'react-final-form'
import { useDeepCompareEffect } from 'storfox-api-hooks'
import { useAllSearchParams } from 'storfox-route-hooks'

import { getFullName, toDuration } from '~/utils'
import PageTitle from '~/components/PageTitle'
import UserUpdateSearchField from '~/components/Fields/UserUpdateSearchField'
import TableDateFormat from '~/components/TableDateFormat'
import OrderProgress from '~/components/OrderProgress'
import Container, { Content, Header } from '~/components/Container'
import { withForm } from '~/components/Form'
import { Table, TableHeader } from '~/components/Table'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import { PICKLIST_TABS } from '~/constants/tabs'
import TextOverflow from '~/components/TextOverflow'
import TableLink from '~/components/Link'
import PicklistStatus, { NEW, PENDING } from '~/components/Statuses/PicklistStatus'
import { useTableValues } from '~/components/TableValues/hooks'
import { getTableKeyWithQuery } from '~/components/Table/Table'

import BulkActionButton from './BulkActionButton'
import More from './More'

import { getUniqOrderNumbers, getUniqReferenceNumbers } from '../../serializers/index'

export const PICKLIST_COLUMNS = [
  {
    width: 100,
    headerName: 'Pickings',
    field: 'number',
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        {value}
      </TableLink>
    )
  },
  {
    width: 150,
    headerName: 'Sale order numbers',
    field: 'saleOrderNumbers',
    renderCell: ({ value }) => (
      <TextOverflow selfTooltip={true}>
        {value}
      </TextOverflow>
    )
  },
  {
    width: 150,
    headerName: 'Reference numbers',
    field: 'referenceNumbers',
    renderCell: ({ value }) => (
      <TextOverflow selfTooltip={true}>
        {value}
      </TextOverflow>
    )
  },
  {
    width: 150,
    headerName: 'Orders',
    field: 'orders',
    headerAlign: 'center',
    align: 'center'
  },
  {
    width: 150,
    headerName: 'Line items',
    field: 'lineItems',
    headerAlign: 'center',
    align: 'center'
  },
  {
    width: 100,
    headerName: 'Qty',
    field: 'totalQuantity',
    headerAlign: 'center',
    align: 'center'
  },
  {
    width: 200,
    headerName: 'Progress',
    field: 'pickingStatus',
    valueFormatter: ({ value }) => `${value.pickedCount}|${value.totalQuantity}`,
    renderCell: ({ value }) => (
      <OrderProgress
        received={value.pickedCount}
        ordered={value.totalQuantity}
      />
    )
  },
  {
    width: 150,
    headerName: 'Status',
    field: 'status',
    renderCell: ({ value }) => (
      <PicklistStatus value={value} />
    )
  },
  {
    width: 250,
    headerName: 'Assigned to',
    field: 'picker',
    linkDisabled: true,
    valueGetter: ({ row }) => getFullName(row.picker),
    renderCell: ({ row }) => {
      const onAssignToChange = propOr(always(null), 'onAssignToChange', row.functions)
      return (
        <Box width="100%">
          <UserUpdateSearchField
            primaryKey="guid"
            data-cy={`${row.fieldName}.picker`}
            name={`${row.fieldName}.picker`}
            params={{ perms: 'picklist.complete' }}
            label={null}
            searchFieldProps={{ onKeyDown: event => event.stopPropagation() }}
            onValueChange={inputValue => onAssignToChange(row.guid, inputValue)}
            ListboxProps={{ 'data-cy': 'userList' }}
          />
        </Box>
      )
    }
  },
  {
    width: 150,
    headerName: 'Duration',
    field: 'duration'
  },
  {
    width: 150,
    headerName: 'Created at',
    field: 'createdAt',
    ordering: true,
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  },
  {
    width: 150,
    headerName: 'Last updated',
    field: 'updatedAt',
    ordering: true,
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  }
]

const getRowsFromResults = (results, functions) => {
  return results.map((item, index) => {
    const id = prop('id', item)
    const guid = prop('guid', item)
    const number = prop('number', item)
    const saleOrderNumbers = getUniqOrderNumbers(item)
    const referenceNumbers = getUniqReferenceNumbers(item)
    const totalQuantity = prop('totalQuantity', item)
    const pickedCount = prop('pickedQuantity', item)
    const pickingStatus = { pickedCount, totalQuantity }
    const orders = prop('orderCount', item)
    const lineItems = prop('lineItemCount', item)
    const status = prop('status', item)

    const picker = prop('picker', item)

    const duration = toDuration(prop('duration', item))

    const updatedAt = prop('updatedAt', item)
    const createdAt = prop('createdAt', item)
    const link = generatePath(ROUTES.PICKLIST_UPDATE_PATH, {
      guid,
      tab: PICKLIST_TABS.GENERAL
    })

    return {
      id,
      guid,
      number,
      saleOrderNumbers,
      referenceNumbers,
      orders,
      lineItems,
      totalQuantity,
      pickingStatus,
      picker,
      duration,
      status,
      createdAt,
      updatedAt,
      functions,
      fieldName: `pickingJobs[${index}]`,
      link
    }
  })
}

function PicklistTable (props) {
  const {
    list,
    isLoading,
    filter,
    ordering,
    form,
    onAssignToChange,
    onBatchClick,
    onListRefetch,
    onGeneratePdf,
    onBulkAssign,
    bulkActionLoading
  } = props
  const [tableRef, setTableRef] = useState(null)
  const location = useLocation()
  const { getTableValues } = useTableValues()
  const params = useAllSearchParams()

  const { handleSubmit } = form

  const results = prop('results', list)
  const columns = prop('filteredColumns', ordering)
  const hasNextPage = prop('hasNextPage', list)
  const hasPrevPage = prop('hasPrevPage', list)

  const pickingJobsField = useField('pickingJobs')
  const pickingJobsFieldChange = pickingJobsField.input.onChange

  const tableKey = getTableKeyWithQuery(location.pathname, params)

  useDeepCompareEffect(() => {
    const tableOldValue = getTableValues(tableKey)
    pickingJobsFieldChange(tableOldValue.results)
  }, [])

  const functions = useMemo(() => ({ onAssignToChange }), [onAssignToChange])

  const handleProcess = () => {
    form.form.change('status', NEW)
    form.form.submit()
  }

  const handleProgress = () => {
    form.form.change('status', PENDING)
    form.form.submit()
  }

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={NAV.PICKLISTS}
            parentTitle={NAV.FULFILLMENT}
            rightButton={(
              <BulkActionButton
                onProcess={handleProcess}
                onProgress={handleProgress}
                onBatchClick={onBatchClick}
                onGeneratePdf={onGeneratePdf}
                onBulkAssign={onBulkAssign}
                loading={bulkActionLoading}
              />
            )}
          />
        </Header>

        <Content>
          <TableHeader
            filter={filter}
            filterOpen={filter.handleOpen}
            orderingOpen={ordering.handleOpen}
            ordering={ordering}
          >
            <More tableRef={tableRef} />
          </TableHeader>
          <Table
            getRowsFromResults={getRowsFromResults}
            results={results}
            primaryKey="guid"
            columns={columns}
            isLoading={isLoading}
            args={functions}
            pinnedColumns={['number']}
            initialRows={list.results}
            checkboxSelection={true}
            rowHeight={70}
            ordering={ordering}
            onRefetch={onListRefetch}
            setTableRef={setTableRef}
            isNewPagination={true}
            hasNextPage={hasNextPage}
            hasPrevPage={hasPrevPage}
          />
        </Content>
      </Container>
    </form>
  )
}

PicklistTable.propTypes = {
  form: PropTypes.object,
  list: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onBatchClick: PropTypes.func.isRequired,
  onAssignToChange: PropTypes.func.isRequired,
  onListRefetch: PropTypes.func.isRequired,
  onGeneratePdf: PropTypes.func.isRequired,
  onBulkAssign: PropTypes.func.isRequired,
  bulkActionLoading: PropTypes.bool
}

export default withForm(PicklistTable)
