import React from 'react'
import PropTypes from 'prop-types'
import { prop } from 'ramda'
import { SearchAutoClearField } from 'storfox-form-fields'
import Grid from '@mui/material/Grid'
import AddIcon from '@mui/icons-material/Add'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import * as API from '~/constants/api'
import { Button } from '~/components/Buttons'
import Avatar from '~/components/Avatar'

const getOptionValue = item => {
  if (item) {
    const id = prop('id', item)
    const guid = prop('guid', item)
    const name = prop('shortName', item)
    const imagePath = prop('imagePath', item)

    return { id, guid, name, imagePath }
  }

  return null
}

function SupplierSearchCreateField ({ onChange, onClick, ...props }) {
  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12} sm={7} lg={8}>
        <SearchAutoClearField
          data-cy="supplier"
          name="supplier"
          api={API.SUPPLIER_LIST}
          placeholder="Search by supplier name, email, phone number"
          getOptionValue={getOptionValue}
          onChange={onChange}
          renderOption={(props, option) => (
            <li {...props}>
              <Box display="flex" alignItems="center">
                <Avatar src={option.imagePath} alt={option.name} />
                <Box mr={2} />
                <Typography variant="body1">
                  {option.name}
                </Typography>
              </Box>
            </li>
          )}
          ListboxProps={{ 'data-cy': 'supplierList' }}
          {...props}
        />
      </Grid>
      <Grid item={true} xs={12} sm={5} lg={4}>
        <Button
          variant="outlined"

          startIcon={<AddIcon />}
          onClick={onClick}
          fullWidth={true}
        >
          New supplier
        </Button>
      </Grid>
    </Grid>
  )
}

SupplierSearchCreateField.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired
}

export default SupplierSearchCreateField
