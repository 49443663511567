import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import JsBarcode from 'jsbarcode'
import Barcode from 'react-barcode'
import Box from '@mui/material/Box'

const BarcodeItems = React.forwardRef(({ items }, ref) => {
  const count = items.length
  useEffect(() => {
    if (count) {
      JsBarcode('.barcode').init()
    }
  }, [count])

  return (
    <div ref={ref}>
      {items.map(item => (
        <Box key={item} sx={{
          pageBreakAfter: 'always',
          pageBreakInside: 'avoid'
        }}>
          <Barcode value={item} width={4} />
        </Box>
      ))}
    </div>
  )
})

BarcodeItems.propTypes = {
  items: PropTypes.array.isRequired,
}

export default BarcodeItems
