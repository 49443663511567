import React, { useState } from 'react'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import PropTypes from 'prop-types'
import Paper from '@mui/material/Paper'
import { defaultTo, filter, find, groupBy, map, path, pathEq, pipe, prop, propEq, uniq } from 'ramda'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'

import SkeletonList from '~/components/Skeleton'
import useDialog from '~/hooks/useDialog'

import DimensionUpdateDialog from './DimensionUpdateDialog'
import ContainerContent from './ContainerContent'

const getUnits = pipe(
  defaultTo([]),
  groupBy(path(['container', 'number']))
)

const getContainerNumbers = pipe(
  defaultTo([]),
  map(path(['container', 'number'])),
  uniq
)

const getContainerGuidByNumber = (containerNumber, units) => pipe(
  defaultTo([]),
  filter(item => path(['container', 'number'], item) === containerNumber)
)(units)

const getContainerType = (containerNumber, units) =>
  pipe(
    find(unit => pathEq(['container', 'number'], containerNumber, unit)),
    path(['container', 'containerType', 'name'])
  )(units)

const getContainerImage = (containerNumber, units) =>
  pipe(
    find(unit => pathEq(['container', 'number'], containerNumber, unit)),
    path(['container', 'containerType', 'imagePath'])
  )(units)

const getContainerValuesByGuid = (guid, containers) => find(propEq('guid', guid))(containers)

function ShipmentContent ({ units, isLoading, onUpdateContainer, uniqueContainers }) {
  const dimensionDialog = useDialog()
  const [containerGuid, setContainerGuid] = useState('')
  const containerNumbers = getContainerNumbers(units)
  const containerUnits = getUnits(units)
  const containerInitialValues = getContainerValuesByGuid(containerGuid, uniqueContainers)

  const handleGetContainer = (container, units) => {
    const containerGuidByNumber = getContainerGuidByNumber(container, units)
    const containerGuid = path([0, 'container', 'guid'], containerGuidByNumber)
    setContainerGuid(containerGuid)
  }

  const handleUpdateContainer = (values) => {
    onUpdateContainer(containerGuid, values)
    dimensionDialog.handleClose()
  }

  return (
    <>
      <DimensionUpdateDialog
        isLoading={isLoading}
        open={dimensionDialog.open}
        initialValues={{ ...containerInitialValues }}
        onSubmit={(values) => handleUpdateContainer(values)}
        onClose={dimensionDialog.handleClose}
      />
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Container Image</TableCell>
              <TableCell>Container ID</TableCell>
              <TableCell>Container Type</TableCell>
              <TableCell>Edit</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (<SkeletonList columnCount={5} rowCount={3} />)
              : (
                containerNumbers.map(containerNumber => {
                  const units = prop(containerNumber, containerUnits)
                  const containerType = getContainerType(containerNumber, units)
                  const containerImage = getContainerImage(containerNumber, units)

                  return (
                    <ContainerContent
                      onOpenDialog={dimensionDialog.handleOpen}
                      key={containerNumber}
                      container={containerNumber}
                      containerImage={containerImage}
                      containerType={containerType}
                      units={units}
                      getContainer={(container, units) => handleGetContainer(container, units)}
                    />
                  )
                })
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

ShipmentContent.propTypes = {
  units: PropTypes.array,
  isLoading: PropTypes.bool,
  onUpdateContainer: PropTypes.func.isRequired,
  uniqueContainers: PropTypes.array.isRequired
}

export default ShipmentContent
