import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import { Filter } from 'storfox-filter'
import { prop } from 'ramda'

import Accordion from '~/components/Accordion'

import ZoneStatusField from './ZoneStatusField'

export const toParams = values => {
  const status = prop('status', values)

  return { status }
}

export const toValues = (params) => {
  const status = prop('status', params)

  return { status }
}

export const name = 'zone'
export const transformer = { toParams, toValues }

export const zoneFilterOptions = {
  name,
  transformer
}

function ZoneFilterForm (props) {
  return (
    <Filter {...props}>
      <Accordion title="General" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <ZoneStatusField />
          </Grid>
        </Grid>
      </Accordion>
    </Filter>
  )
}

ZoneFilterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired
}

export default ZoneFilterForm
