import React from 'react'
import { useTranslation } from 'react-i18next'
import SelectField from 'storfox-form-fields/src/SelectField'

export const RUSH = '1'
export const SAVER_PLUS = '2'
export const SWIFT = '3'
export const SAME_DAY = '4'

const options = [
  { value: RUSH, name: 'Rush' },
  { value: SAVER_PLUS, name: 'Saver plus' },
  { value: SWIFT, name: 'Swift' },
  { value: SAME_DAY, name: 'Same day' },
]

function ShippingModeField ({ ...props }) {
  const { t } = useTranslation()

  return (
    <SelectField
      name="configuration.shippingModeId"
      label={t('Shipping mode')}
      options={options}
      {...props}
    />
  )
}

export default ShippingModeField
