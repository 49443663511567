import React from 'react'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import Link from '@mui/material/Link'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'

import { FILE_URL } from '~/constants/files'

import CardHeader from '../Cards/CardHeader'

export const uploadUUID = '25d939b0-fd6c-493c-a3e8-97f5b56b6567'

function TemplateCard ({ baseUrl, fileName, onClick }) {
  const { t } = useTranslation()

  return (
    <Card>
      <CardHeader title={t('Template')} />
      <Divider />
      <CardContent>
        <Typography gutterBottom={true} color="error">
          Columns highlighted in red are required.
        </Typography>
        <Link
          href={`${baseUrl}/${fileName}?version=${uploadUUID}`}
          underline="always"
          variant="body1"
          style={{ cursor: 'pointer' }}
          onClick={onClick}
        >
          Download a spreadsheet template
        </Link>
      </CardContent>
    </Card>
  )
}

TemplateCard.defaultProps = {
  baseUrl: FILE_URL
}

TemplateCard.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  onClick: PropTypes.func
}

export default TemplateCard
