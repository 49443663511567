import React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

import Tabs from '~/components/Tabs'
import Divider from '~/components/Divider'
import * as ROUTES from '~/constants/routes'
import { LOCATION_TABS } from '~/constants/tabs'

const tabs = [
  { value: LOCATION_TABS.GENERAL, label: 'General' },
  { value: LOCATION_TABS.CONTENT, label: 'Content' },
]

function LocationDetailTabs ({ value }) {
  const navigate = useNavigate()
  const { guid } = useParams()

  const onChange = (event, tab) => {
    const redirect = generatePath(ROUTES.LOCATION_DETAIL_PATH, { guid, tab })
    navigate(redirect, { replace: true })
  }

  return (
    <>
      <Tabs
        tabs={tabs}
        value={value}
        onChange={onChange}
      />
      <Divider />
    </>
  )
}

LocationDetailTabs.propTypes = {
  value: PropTypes.string.isRequired
}

export default LocationDetailTabs
