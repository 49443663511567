import React from 'react'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { prop, pathOr } from 'ramda'
import PropTypes from 'prop-types'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import TableContainer from '@mui/material/TableContainer'
import { generatePath, useNavigate } from 'react-router-dom'
import { styled } from '@mui/material'

import { toDuration } from '~/utils'
import EmptyNotesIcon from '~/components/Icons/EmptyNotesIcon'
import EmptyLineItems from '~/components/EmptyLineItems/EmptyLineItems'
import SkeletonList from '~/components/Skeleton'
import OrderProgress from '~/components/OrderProgress'
import PicklistStatus from '~/components/Statuses/PicklistStatus'
import { PICKLIST_TABS } from '~/constants/tabs'
import * as ROUTES from '~/constants/routes'
import { HoverableRow } from '~/components/Table'

const TableRowStyled = styled(HoverableRow)({
  height: '70px'
})

const TableCellStyled = styled(TableCell)({
  minWidth: '150px'
})

function SaleOrderPicklistTable ({ picklist }) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const isLoading = prop('isLoading', picklist)
  const results = prop('results', picklist)

  const renderEmptyLines = Boolean(!isLoading && !results.length)
  const renderLineItems = Boolean(!isLoading && results.length)

  return (
    <Box component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto' }}>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCellStyled>{t('Picklist')}</TableCellStyled>
              <TableCellStyled>{t('Orders')}</TableCellStyled>
              <TableCellStyled>{t('Line Items')}</TableCellStyled>
              <TableCellStyled>{t('Qty')}</TableCellStyled>
              <TableCellStyled>{t('Progress')}</TableCellStyled>
              <TableCellStyled>{t('Status')}</TableCellStyled>
              <TableCellStyled>{t('Assigned to')}</TableCellStyled>
              <TableCellStyled>{t('Duration')}</TableCellStyled>
              <TableCellStyled>{t('Created at')}</TableCellStyled>
              <TableCellStyled>{t('Last updated')}</TableCellStyled>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <SkeletonList
                rowCount={3}
                columnCount={13}
                height={20}
                rowHeight={70}
                columnWidth={165.39}
              />
            )}

            {renderEmptyLines && (
              <EmptyLineItems
                colSpan={10}
                icon={<EmptyNotesIcon />}
                text="No picklist by sale order"
              />
            )}
            {renderLineItems && results.map((row, index) => {
              const number = prop('number', row)
              const pickedCount = prop('pickedQuantity', row)
              const orders = prop('orderCount', row)
              const lineItems = prop('lineItemCount', row)
              const status = prop('status', row)
              const duration = toDuration(prop('duration', row))
              const updatedAt = prop('updatedAt', row)
              const createdAt = prop('createdAt', row)
              const quantity = prop('totalQuantity', row)
              const assignedToFirstName = pathOr('', ['picker', 'firstName'], row)
              const assignedToLastName = pathOr('', ['picker', 'lastName'], row)

              const params = { guid: prop('guid', row), tab: PICKLIST_TABS.GENERAL }
              const updateUrl = generatePath(ROUTES.PICKLIST_UPDATE_PATH, params)

              return (
                <TableRowStyled key={index} onClick={() => navigate(updateUrl)}>
                  <TableCell>{number}</TableCell>
                  <TableCell>{orders}</TableCell>
                  <TableCell>{lineItems}</TableCell>
                  <TableCell>{quantity}</TableCell>
                  <TableCell>
                    <OrderProgress received={pickedCount} ordered={quantity || 4} />
                  </TableCell>
                  <TableCell>
                    <PicklistStatus value={status} />
                  </TableCell>
                  <TableCell>{`${assignedToFirstName} ${assignedToLastName}`}</TableCell>
                  <TableCell>{duration}</TableCell>
                  <TableCell>{updatedAt}</TableCell>
                  <TableCell>{createdAt}</TableCell>
                </TableRowStyled>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

SaleOrderPicklistTable.propTypes = {
  picklist: PropTypes.object.isRequired
}

export default SaleOrderPicklistTable
