import { sprintf } from 'sprintf-js'
import { useAllSearchParams } from 'storfox-route-hooks'
import {
  useCreate,
  useDelete,
  useDetail,
  useList,
  useUpdate,
  useLimitedCountList,
  useScrollList
} from 'storfox-api-hooks'

import { queryToParams } from '~/utils'
import * as API from '~/constants/api'

import { PurchaseOrderFilterSerializer } from '../serializers'

export const usePurchaseOrderList = () => {
  const searchParams = useAllSearchParams()

  const params = queryToParams(PurchaseOrderFilterSerializer, searchParams)

  return useLimitedCountList(API.PURCHASE_ORDERS_LIST, params)
}

export const usePurchaseOrderDetail = guid => {
  const url = sprintf(API.PURCHASE_ORDERS_DETAIL, guid)
  return useDetail(url)
}
export const usePurchaseOrderExcelReport = () => {
  const { create, ...state } = useCreate(API.PURCHASE_ORDER_GENERATE_EXCEL_REPORT)
  return { ...state, generate : create }
}
export const usePurchaseOrderPdfReport = () => {
  const { create, ...state } = useCreate(API.PURCHASE_ORDER_GENERATE_PDF_REPORT)
  return { ...state, generate : create }
}

export const usePurchaseOrderGenerateGatePass = () => {
  const { state, create } = useCreate(API.PURCHASE_ORDERS_CREATE_GATE_PASS)
  return { ...state, generate: create }
}
export const usePurchaseOrderGatePassList = (guid, autoSend) => {
  const params = { purchaseGuid: guid }
  const { getList, ...state } = useList(API.PURCHASE_ORDERS_GATE_PASS_LIST, params, autoSend)
  return { ...state, getList: () => getList(params) }
}
export const usePurchaseOrderCreate = () => {
  return useCreate(API.PURCHASE_ORDERS_CREATE)
}
export const usePurchaseOrdersDelete = () => {
  return useDelete(API.PURCHASE_ORDERS_DELETE)
}

export const usePurchaseOrderUpdate = guid => {
  const url = sprintf(API.PURCHASE_ORDERS_UPDATE, guid)
  return useUpdate(url)
}

export const usePurchaseOrderProgress = guid => {
  const url = sprintf(API.PURCHASE_ORDER_PROGRESS, guid)
  const { state, create } = useCreate(url)
  return { ...state, progress: create }
}

export const usePurchaseOrderEmailToSupplier = guid => {
  const url = sprintf(API.PURCHASE_ORDER_EMAIL_TO_SUPPLIER, guid)
  return useCreate(url)
}
export const usePurchaseOrderGenerateReport = guid => {
  const url = sprintf(API.PURCHASE_ORDER_GENERATE_REPORT, guid)
  return useCreate(url)
}

export const usePurchaseOrderClose = guid => {
  const url = sprintf(API.PURCHASE_ORDERS_CLOSE, guid)
  const { update, ...state } = useUpdate(url)
  return { ...state, close: update }
}

export const usePurchaseOrderCancel = guid => {
  const url = sprintf(API.PURCHASE_ORDERS_CANCEL, guid)
  const { update, ...state } = useUpdate(url)
  return { ...state, cancel: update }
}

export const usePurchaseOrderMarkAsPaid = guid => {
  const url = sprintf(API.PURCHASE_ORDERS_MARK_AS_PAID, guid)
  const { update, ...state } = useUpdate(url)

  return { ...state, mark: update }
}

export const usePurchaseOrderHistory = (guid, autoSend) => {
  const url = sprintf(API.PURCHASE_ORDERS_HISTORY, guid)
  const searchParams = useAllSearchParams()

  return useList(url, searchParams, autoSend)
}

export const usePurchaseOrderSupplierCreate = () => {
  return useCreate(API.SUPPLIER_CREATE)
}

export const usePurchaseOrderImport = () => {
  return useCreate(API.PURCHASE_ORDER_FILE_UPLOAD)
}
export const useInvoiceGenerate = guid => {
  const url = sprintf(API.PURCHASE_ORDER_INVOICE_GENERATE, guid)
  const { create, ...state } = useCreate(url)

  return { ...state, generate: create }
}

export const usePurchaseOrderVariant = (params) => {
  return useScrollList(API.PURCHASE_ORDERS_VARIANTS, params)
}

export const usePurchaseOrderFileAttach = guid => {
  const url = sprintf(API.PURCHASE_ORDER_FILE_ATTACH, guid)
  const { create, ...state } = useCreate(url)

  return { ...state, attach: create }
}

export const usePurchaseOrderFileAttachRemove = guid => {
  const url = sprintf(API.PURCHASE_ORDER_FILE_ATTACH_REMOVE, guid)
  return useCreate(url)
}
