import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import { find, prop, propEq } from 'ramda'
import { useAllSearchParams, useRoute } from 'storfox-route-hooks'

import PageTitle from '~/components/PageTitle'
import LoaderProvider from '~/components/Loader'
import * as NAV from '~/constants/nav-titles'
import Container, { Content, Header } from '~/components/Container'

import UnitInformationCard from './UnitInformationCard'
import UnitGraph from './UnitGraph'

function UnitHistory ({ pageTitle, pageTitleLoading, listObject }) {
  const { replaceParams } = useRoute()
  const { guid } = useAllSearchParams()
  const isLoading = listObject.isLoading
  const results = listObject.results
  const initialActiveUnit = find(propEq('unitGuid', guid), results)

  const [activeUnit, setActiveUnit] = useState(null)

  useEffect(() => {
    setActiveUnit(initialActiveUnit)
  }, [initialActiveUnit])

  const handleUnitClick = (event, unit) => {
    setActiveUnit(unit)
    const unitGuid = prop('unitGuid', unit)

    replaceParams({ guid: unitGuid })
  }

  return (
    <LoaderProvider isLoading={isLoading}>
      <Container>
        <Header>
          <PageTitle
            parentTitle={NAV.CATALOG}
            pageTitle={pageTitle}
            pageTitleLoading={pageTitleLoading}
          />
        </Header>
        <Content>
          <Grid container={true} spacing={3}>
            <Grid item={true} lg={8} xs={12}>
              <UnitGraph
                list={results}
                onUnitClick={handleUnitClick}
                isLoading={isLoading}
              />
            </Grid>
            <Grid item={true} lg={4} xs={12}>
              <UnitInformationCard activeUnit={activeUnit} />
            </Grid>
          </Grid>
        </Content>
      </Container>
    </LoaderProvider>
  )
}

UnitHistory.propTypes = {
  listObject: PropTypes.shape({
    results: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    count: PropTypes.number.isRequired,
    getList: PropTypes.func.isRequired
  }).isRequired,
  pageTitle: PropTypes.string.isRequired,
  pageTitleLoading: PropTypes.bool.isRequired
}

export default UnitHistory
