import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { getSocketUrl, useDeepCompareEffect } from 'storfox-api-hooks'
import { io } from 'socket.io-client'
import PubSub from 'pubsub-js'
import { useLocation } from 'react-router-dom'

import { SOCKET_EVENTS } from '~/constants/socket-events'
import { getSocketPayload } from '~/components/Socket/utils'
import { SALE_ORDER_ALLOCATION } from '~/constants/notification-topics'

import Context from './context'

import { useToken } from '../Token'

export const socket = io(getSocketUrl(), {
  path: '/websocket',
  transports: ['websocket', 'polling'],
  autoConnect: false
})

function SocketProvider ({ children }) {
  const { token } = useToken()
  const [count, setCount] = useState(0)
  const location = useLocation()

  const isSaleOrderDetailPage = useMemo(() => {
    const isSaleOrder = location.pathname.includes('sale-order')
    const isDetail = location.pathname.includes('detail')
    return isSaleOrder && isDetail
  }, [location])

  useDeepCompareEffect(() => {
    if (!token) {
      socket.disconnect()
    }
    if (token) {
      socket.connect()
      socket.on('connect', () => {
        socket.emit(SOCKET_EVENTS.REGISTER_CLIENT, { token }, () => {})
      })

      socket.on('connect_error', () => {
        socket.io.opts.transports = ['polling', 'websocket']
      })

      socket.onAny((topic, message) => {
        if (topic === SALE_ORDER_ALLOCATION) {
          if (isSaleOrderDetailPage) {
            const { body, payload } = getSocketPayload(message)

            PubSub.publish(topic, { body, payload })
          }
        } else {
          const { body, payload } = getSocketPayload(message)

          PubSub.publish(topic, { body, payload })
        }
      })
    }

    return () => {
      socket.off()
    }
  }, [token])

  const value = {
    count,
    onChange: setCount,
    onCountReset: () => setCount(0)
  }

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  )
}

SocketProvider.propTypes = {
  children: PropTypes.any.isRequired
}

export default SocketProvider
