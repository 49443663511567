import React from 'react'
import isEqual from 'react-fast-compare'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import MUITextField from '@mui/material/TextField'

const REGEX = /^(-?)(?!0)\d+$/g

const inputToNumber = value => {
  if (value && value.match(REGEX)) {
    return Number(value)
  }
  if (value === '-') {
    return '-'
  }
}

function QuantityField ({ name, ...defaultProps }) {
  return (
    <Field name={name}>
      {({ input, meta }) => (
        <MUITextField
          onChange={event => input.onChange(inputToNumber(event.target.value))}
          error={meta.invalid}
          value={String(input.value)}
          helperText={meta.submitError || meta.error}
          {...defaultProps}
        />
      )}
    </Field>
  )
}

QuantityField.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
}

QuantityField.defaultProps = {
  size: 'small',
  fullWidth: true,
  variant: 'outlined'
}

export default React.memo(QuantityField, isEqual)
