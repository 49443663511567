import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import IMileIntegrationUpdateContainer from './IMileIntegrationUpdateContainer'
import IMileIntegrationCreateContainer from './IMileIntegrationCreateContainer'

export default () => {
  return [
    {
      layout: AuthLayout,
      path: ROUTES.IMILE_INTEGRATION_CREATE_PATH,
      component: IMileIntegrationCreateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.IMILE_INTEGRATION_UPDATE_PATH,
      component: IMileIntegrationUpdateContainer
    }
  ]
}
