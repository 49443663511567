import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import { useAllSearchParams } from 'storfox-route-hooks'
import { prop } from 'ramda'

import * as NAV from '~/constants/nav-titles'
import { escapeAtob } from '~/utils'
import { useCompany } from '~/components/Profile'

import OverviewFilter from './OverviewFilter'

import { getDateRangeFromParams } from '../../../utils'

const getInitialValues = (params, defaultCompany) => ({
  dateRange: getDateRangeFromParams(prop('dateRange', params)),
  company: escapeAtob(prop('company', params)) || defaultCompany,
  warehouse: escapeAtob(prop('warehouse', params))
})

function OverviewHeader ({ className, onSubmit, ...rest }) {
  const { t } = useTranslation()
  const params = useAllSearchParams()
  const { company } = useCompany()

  const defaultCompany = {
    guid: prop('guid', company),
    name: prop('name', company),
    id: prop('id', company)
  }

  const initialValues = getInitialValues(params, defaultCompany)
  return (
    <Box {...rest} pt={1} pb={1} className={className}>
      <Grid
        container={true}
        justifyContent="space-between"
        spacing={3}
      >
        <Grid item={true} xs={12} lg={6}>
          <Typography
            component="h2"
            gutterBottom={true}
            variant="overline"
          >
            {t(NAV.DASHBOARD)}
          </Typography>
          <Typography
            component="h1"
            gutterBottom={true}
            variant="h3"
          >
            {t(NAV.OVERVIEW)}
          </Typography>
        </Grid>
        <Grid
          item={true}
          lg={6}
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          <OverviewFilter
            initialValues={initialValues}
            onSubmit={onSubmit}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

OverviewHeader.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func.isRequired
}

export default OverviewHeader
