import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'storfox-snackbar'
import { generatePath, useNavigate } from 'react-router-dom'
import { path, pathOr, prop, propOr } from 'ramda'
import { useDeepCompareEffect, useRequest } from 'storfox-api-hooks'
import { useAllSearchParams } from 'storfox-route-hooks'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import useMessages from '~/hooks/useMessages'
import { TRANSFER_DETAIL_TABS } from '~/constants/tabs'
import { escapeAtob } from '~/utils'
import * as API from '~/constants/api'

import TransferCreateForm from '../components/TransferCreate'
import { useTransferCreate, useTransferCreateAndProcess, useTransferOrderVariant } from '../hooks'
import { TransferCreateSerializer } from '../serializers'
import { SAVE_PROCESS } from '../constants'

function TransferCreateContainer () {
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const messages = useMessages()
  const navigate = useNavigate()
  const request = useRequest()
  const searchParams = useAllSearchParams()

  const transferCreate = useTransferCreate()
  const transferCreateAndCreate = useTransferCreateAndProcess()
  const transferOrderVariant = useTransferOrderVariant()

  const [loading, setLoading] = useState(false)
  const [reorderValues, setReorderValues] = useState({})

  const title = t('New transfer')

  const handleSubmit = formValues => {
    const submitType = propOr(SAVE_PROCESS, 'submitType', formValues)
    if (submitType === SAVE_PROCESS) {
      return transferCreateAndCreate.create(TransferCreateSerializer(formValues))
        .then((response) => {
          snackbar({ message: messages.CREATE_SUCCESS })
          return response
        })
        .then(data => {
          const guid = path(['result', 'guid'], data)
          const detailPath = generatePath(ROUTES.TRANSFER_DETAIL_PATH, { guid, tab: TRANSFER_DETAIL_TABS.GENERAL })
          navigate(detailPath)
        })
    } else {
      return transferCreate.create(TransferCreateSerializer(formValues))
        .then((response) => {
          snackbar({ message: messages.CREATE_SUCCESS })
          return response
        })
        .then(data => {
          const guid = path(['result', 'guid'], data)
          const detailPath = generatePath(ROUTES.TRANSFER_DETAIL_PATH, { guid, tab: TRANSFER_DETAIL_TABS.GENERAL })
          navigate(detailPath)
        })
    }
  }

  const breadcrumbs = { title }

  useDeepCompareEffect(() => {
    const body = prop('body', searchParams)
    if (body) {
      setLoading(true)
      const escapeBody = escapeAtob(body)
      const variants = prop('variants', escapeBody)
      const warehouse = prop('warehouse', escapeBody)
      const comparisonWarehouse = prop('comparisonWarehouse', escapeBody)
      const comparisonWarehouseId = prop('id', comparisonWarehouse)
      const variantIds = variants.map(variant => variant.id)
      request.get(API.TRANSFER_VARIANTS, { ids: variantIds.join(','), availableWarehouseId: comparisonWarehouseId })
        .then(response => {
          const results = pathOr([], ['data', 'results'], response)
          const lineItems = results.map((item) => {
            const id = prop('id', item)
            const name = prop('name', item)
            const price = prop('retailPrice', item)
            const available = prop('available', item)
            const sku = prop('sku', item)
            const barcode = prop('barcode', item)
            const total = prop('allocated', item)
            const defaultImage = prop('defaultImage', item)

            return { variant: { id, name, defaultImage, sku, barcode }, available, price, total }
          })
          setLoading(false)
          setReorderValues({ warehouse, lineItems, comparisonWarehouse })
        })
    }
  }, [request, searchParams])

  const initialValues = useMemo(() => {
    const reorderWarehouse = prop('warehouse', reorderValues)
    if (reorderWarehouse) {
      const reorderComparisonWarehouse = prop('comparisonWarehouse', reorderValues)
      const reorderLineItems = prop('lineItems', reorderValues)
      return { fromWarehouse: reorderComparisonWarehouse, toWarehouse: reorderWarehouse, lineItems: reorderLineItems }
    } else return null
  }, [reorderValues])

  return (
    <DashboardLayout
      title={title}
      activeNav={NAV.TRANSFER}
      breadcrumbs={breadcrumbs}
      isLoading={loading}
    >
      <TransferCreateForm
        isLoading={transferCreate.isLoading || transferCreateAndCreate.isLoading || loading}
        pageTitle={title}
        onSubmit={handleSubmit}
        transferOrderVariant={transferOrderVariant}
        initialValues={initialValues}
      />
    </DashboardLayout>
  )
}

export default TransferCreateContainer
