import React, { useMemo } from 'react'
import { useField } from 'react-final-form'
import PropTypes from 'prop-types'

import TableSkeleton from '../TableSkeleton'
import ReceiveDetailLineItems from '../../ReceivePurchaseOrder/ReceiveDetailLineItems'

function PurchaseDetailLineItemGroups (props) {
  const {
    isBarcodeMode,
    detailLoading,
    onMoveToActive,
    disableEdit
  } = props
  const detailLineItemsField = useField('detailLineItems')
  const detailLineItems = useMemo(() => detailLineItemsField.input.value || [], [detailLineItemsField.input.value])

  return (
    <>
      {!detailLoading && detailLineItems.length > 0 && (
        <ReceiveDetailLineItems
          onMoveToActive={onMoveToActive}
          edit={disableEdit}
          isBarcodeMode={isBarcodeMode}
          editDimension={true}
        />
      )}
      {detailLoading && <TableSkeleton />}
    </>
  )
}

PurchaseDetailLineItemGroups.propTypes = {
  detailLoading: PropTypes.bool.isRequired,
  isBarcodeMode: PropTypes.bool,
  onMoveToActive: PropTypes.func,
  disableEdit: PropTypes.bool
}

export default PurchaseDetailLineItemGroups
