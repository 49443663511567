import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { map, prop } from 'ramda'
import { generatePath } from 'react-router-dom'

import Container, { Content, Header } from '~/components/Container'
import PageTitle from '~/components/PageTitle'
import { MoreListButton, NewButton } from '~/components/Buttons'
import TextOverflow from '~/components/TextOverflow'
import { Table, TableHeader } from '~/components/Table'
import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'
import TableLink from '~/components/Link'

import BulkActionButton
from './BulkActionButton'
import AdjustmentReasonStatus from './AdjustmentReasonStatus'

const getDetailPath = id => {
  return generatePath(ROUTES.ADJUSTMENT_REASON_UPDATE_PATH, { id })
}

export const ADJUSTMENT_REASON_COLUMNS = [
  {
    field: 'name',
    headerName: 'Name',
    width: 400,
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        <TextOverflow selfTooltip={true}>{value}</TextOverflow>
      </TableLink>
    )
  },
  {
    field: 'operation',
    headerName: 'Operation',
    width: 900,
    renderCell: ({ value }) => (
      <AdjustmentReasonStatus value={value} />
    )
  }
]

const getRowsFromResults = map(item => {
  const id = prop('id', item)
  const guid = prop('guid', item)
  const name = prop('name', item)
  const operation = prop('operation', item)
  const link = getDetailPath(id)

  return {
    id,
    guid,
    name,
    operation,
    link
  }
})

function AdjustmentReasonTable (props) {
  const {
    pageTitle,
    adjustmentReasonList,
    filter,
    ordering,
    onListRefetch,
    onAdjustmentReasonsDelete,
    bulkActionLoading
  } = props
  const [tableRef, setTableRef] = useState(null)
  const results = prop('results', adjustmentReasonList)
  const isLoading = prop('isLoading', adjustmentReasonList)
  const count = prop('count', adjustmentReasonList)
  const columns = prop('filteredColumns', ordering)

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={pageTitle}
          parentTitle={NAV.CONFIGURATION}
          rightButton={(
            <>
              <BulkActionButton onAdjustmentReasonsDelete={onAdjustmentReasonsDelete} loading={bulkActionLoading} />
              <NewButton path={ROUTES.ADJUSTMENT_REASON_CREATE_PATH} />
            </>
          )}
        />
      </Header>
      <Content>
        <TableHeader
          filter={filter}
          filterOpen={filter.handleOpen}
          orderingOpen={ordering.handleOpen}
          ordering={ordering}
        >
          <MoreListButton tableRef={tableRef} />
        </TableHeader>
        <Table
          getRowsFromResults={getRowsFromResults}
          results={results}
          columns={columns}
          isLoading={isLoading}
          count={count}
          primaryKey="guid"
          pinnedColumns={['name']}
          ordering={ordering}
          checkboxSelection={true}
          onRefetch={onListRefetch}
          setTableRef={setTableRef}
        />
      </Content>
    </Container>
  )
}

AdjustmentReasonTable.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  onAdjustmentReasonsDelete: PropTypes.func.isRequired,
  adjustmentReasonList: PropTypes.object,
  filter: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  onListRefetch: PropTypes.func.isRequired,
  bulkActionLoading: PropTypes.bool
}

export default AdjustmentReasonTable
