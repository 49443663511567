import React from 'react'
import PropTypes from 'prop-types'
import { Chip, styled } from '@mui/material'

import { capitalize } from '~/utils'

const ChipStyled = styled(Chip)({
  status: {
    marginLeft: 10,
    border: '1px solid #ccc',
    borderRadius: 5,
    textTransform: 'uppercase',
    fontSize: 12,
  }
})

function TitleChip ({ label }) {
  return (
    <ChipStyled
      label={capitalize(label)}
      size="small"
    />
  )
}

TitleChip.propTypes = {
  label: PropTypes.string.isRequired
}

export default TitleChip
