import React from 'react'
import Grid from '@mui/material/Grid'
import PropTypes from 'prop-types'
import { TimeField } from 'storfox-form-fields'

import MoneyField from '~/components/Fields/MoneyField'
import WeightField from '~/components/Fields/WeightField'

import PricingWeightField from '../Fields/PricingWeightField'

function StoragePerStorageUnit ({ name }) {
  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12} lg={4}>
        <WeightField
          label="Maximum weight"
          name={`${name}.maxWeight`}
        />
      </Grid>

      <Grid item={true} xs={12} lg={4}>
        <TimeField label="Boundary time" name={`${name}.boundaryTime`} />
      </Grid>

      <Grid item={true} xs={12} lg={4}>
        <MoneyField
          label="Price"
          name={`${name}.price`}
        />
      </Grid>

      <Grid item={true} xs={12} lg={12}>
        <PricingWeightField name={`${name}.additionalPrice`} />
      </Grid>
    </Grid>
  )
}

StoragePerStorageUnit.propTypes = {
  name: PropTypes.string.isRequired
}

export default StoragePerStorageUnit
