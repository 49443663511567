import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import ReturnCreateContainer from './containers/ReturnCreateContainer'
import ReturnListContainer from './containers/ReturnListContainer'
import ReturnUpdateContainer from './containers/ReturnUpdateContainer'
import ReturnDetailContainer from './containers/ReturnDetailContainer'

export default () => {
  return [
    {
      layout: AuthLayout,
      path: ROUTES.RETURN_CREATE_PATH,
      component: ReturnCreateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.RETURN_DETAIL_PATH,
      component: ReturnDetailContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.RETURN_UPDATE_PATH,
      component: ReturnUpdateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.RETURN_LIST_PATH,
      component: ReturnListContainer
    },
  ]
}
