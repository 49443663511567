import React from 'react'
import { useDeepCompareEffect } from 'storfox-api-hooks'
import PropTypes from 'prop-types'

import Confirmation from '~/components/Confirmation'

function ShopifyInstall ({ onDetailGet }) {
  useDeepCompareEffect(() => {
    onDetailGet()
  }, [])

  return (
    <Confirmation />
  )
}

ShopifyInstall.propTypes = {
  onDetailGet: PropTypes.func.isRequired
}

export default ShopifyInstall
