import React, { useRef, useState } from 'react'
import { defaultTo, equals, find, path, pipe, prop, propOr } from 'ramda'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Chip from '@mui/material/Chip'
import { getCancelToken, useDeepCompareEffect, useRequest } from 'storfox-api-hooks'
import CircularProgress from '@mui/material/CircularProgress/CircularProgress'

import useDebounce from '~/hooks/useDebounce'

const getItemFromResults = (results, id, key) =>
  find(
    pipe(
      prop(key),
      equals(id)
    )
  )(results)

function MultiSearchChipField (props) {
  const {
    api,
    input,
    meta,
    InputProps,
    PaperComponent,
    ListboxProps,
    ListboxComponent,
    renderOption,
    searchFieldProps,
    disableCloseOnSelect,
    primaryKey = 'id',
    params = { limit: 30 },
    fullWidth = true,
    isLoading = false,
    size = 'small',
    variant = 'outlined',
    onChange = () => {},
    onInputChange = () => {},
    onValueChange = () => {},
    value = [],
    ...defaultProps
  } = props

  const request = useRequest()
  const [inputValue, setInputValue] = useState('')
  const debouncedValue = useDebounce(inputValue)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [searching, setSearching] = useState(false)
  const ref = useRef([])

  useDeepCompareEffect(() => {
    let active = true

    const { token, cancel } = getCancelToken()

    if (open && api) {
      setLoading(true)
      onInputChange(debouncedValue)
      const searchValue = searching ? debouncedValue : ''
      request.get(api, { ...params, search: searchValue }, { cancelToken: token })
        .then(response => {
          const results = defaultTo([], path(['data', 'results'], response))

          if (active) {
            ref.current = results
            const newResults = results.map((item) => propOr('', 'name', item))
            setOptions(newResults)
            setLoading(false)
          }
        })
    }

    return () => {
      active = false
      cancel()
    }
  }, [open, debouncedValue, searching])

  React.useEffect(() => {
    if (!open) {
      ref.current = []
      setOptions([])
    }
  }, [open])

  return (
    <Autocomplete
      multiple={true}
      options={options}
      freeSolo={true}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip size="small" label={option} {...getTagProps({ index })} />
        ))
      }
      value={value || []}
      onInputChange={(event, value) => {
        setInputValue(value)
        setOptions([])
      }}
      onChange={(event, value) => {
        if (value) {
          onChange(event, getItemFromResults(ref.current, value[primaryKey], primaryKey))
        } else {
          onChange(event, null)
        }
        onValueChange(value)
        setSearching(false)
      }}
      sx={{
        display: 'inline-block',
        minWidth: '300px',
        maxWidth: '300px'
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          {...defaultProps}
          size={size}
          variant={variant}
          fullWidth={fullWidth}
          onBlur={() => {
            setSearching(false)
            setLoading(false)
          }}
          onKeyDown={() => setSearching(true)}
          InputProps={{
            ...params.InputProps,
            ...InputProps,
            endAdornment: (
              <>
                {(isLoading || loading) ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  )
}

MultiSearchChipField.propTypes = {
  primaryKey: PropTypes.string.isRequired,
  api: PropTypes.string.isRequired,
  params: PropTypes.object.isRequired,
  size: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  input: PropTypes.object.isRequired,
  renderOption: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  meta: PropTypes.object.isRequired,
  disableClearable: PropTypes.bool.isRequired,
  InputProps: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  PaperComponent: PropTypes.func,
  ListboxComponent: PropTypes.func,
  ListboxProps: PropTypes.object,
  onValueChange: PropTypes.func,
  disableCloseOnSelect: PropTypes.bool,
  value: PropTypes.string.isRequired,
  searchFieldProps: PropTypes.shape({
    PopperComponent: PropTypes.func
  }),
}

MultiSearchChipField.defaultProps = {
  primaryKey: 'id',
  disabled: false,
  isLoading: false,
  disableClearable: false,
  onChange: () => {},
  onValueChange: () => {},
  onInputChange: () => {}
}

export default MultiSearchChipField
