import retailerWarehouse from './modules/warehouse-retailer'
import warehouse from './modules/warehouse'
import area from './modules/area'
import zone from './modules/zone'
import location from './modules/location'
import container from './modules/container'

export const retailerWarehouseRoutes = () => [
  ...retailerWarehouse(),
  ...area(),
  ...zone(),
  ...location(),
  ...container()
]

export const warehouseRoutes = () => [
  ...warehouse(),
  ...area(),
  ...zone(),
  ...location(),
  ...container()
]
