import React from 'react'
import { useFilter, useOrdering } from 'storfox-filter'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'
import useAllSearchParams from 'storfox-route-hooks/src/useAllSearchParams'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import ErrorLink from '~/components/ErrorLink'
import useMessages from '~/hooks/useMessages'
import { useGoogleEvent } from '~/components/GoogleAnalytics/GoogleAnalytics'
import { useTableSelects } from '~/components/TableValues/hooks'
import { queryToParams } from '~/utils'

import { PurchaseFilterSerializer } from '../../serializers/Purchase'
import { usePurchaseList, usePurchaseReportGenerate } from '../../hooks/Purchase'
import PurchaseTable from '../../components/Purchase/PurchaseTable'
import PurchaseOrderingForm, { purchaseOrderingOptions } from '../../components/Purchase/PurchaseOrderingForm'
import PurchaseFilterForm, { purchaseFilterOptions } from '../../components/Purchase/PurchaseFilterForm'

function PurchaseListContainer () {
  const purchaseList = usePurchaseList()
  const purchaseReportsGenerate = usePurchaseReportGenerate()
  const messages = useMessages()
  const snackbar = useSnackbar()
  const { sendEvent } = useGoogleEvent()
  const { selects } = useTableSelects()
  const searchParams = useAllSearchParams()

  const filter = useFilter(purchaseFilterOptions)
  const ordering = useOrdering(purchaseOrderingOptions)

  const breadcrumbs = { title: NAV.PURCHASE_REPORT }

  const handleSalesExport = () => {
    const params = selects.length === 0
      ? queryToParams(PurchaseFilterSerializer, searchParams)
      : { guids: selects.join(',') }
    return purchaseReportsGenerate.generate(params)
      .then(() => {
        sendEvent({ eventAction: 'Report Bulk Generate Sale Order', eventCategory: 'Sale Order' })
        snackbar({ message: messages.NOTIFICATION_WAIT })
      })
      .catch(response => {
        const message = <ErrorLink error={response} />
        snackbar({ message, type: ALTER_ERROR })
      })
  }

  return (
    <DashboardLayout
      title={NAV.PURCHASE_REPORT}
      activeNav={NAV.REPORTS}
      breadcrumbs={breadcrumbs}
    >
      <PurchaseFilterForm {...filter} />
      <PurchaseOrderingForm {...ordering} />

      <PurchaseTable
        onListRefetch={purchaseList.getListByParams}
        filter={filter}
        ordering={ordering}
        list={purchaseList}
        onReportGenerate={handleSalesExport}
      />
    </DashboardLayout>
  )
}

export default PurchaseListContainer
