import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import PropTypes from 'prop-types'

import ContainerCardHeader from './ContainerCardHeader'
import PackingContainerUnitList from './PackingContainerUnitList'

function ContainerItems (props) {
  const {
    currentNumber,
    containerType,
    containerNumber,
    products,
    onRemove,
    totalContainers,
    removable
  } = props

  return (
    <Card variant="outlined">
      <ContainerCardHeader
        currentNumber={currentNumber}
        containerNumber={containerNumber}
        totalContainers={totalContainers}
        containerType={containerType}
        {...props}
      />

      <Divider />

      <CardContent style={{ padding: 0 }}>
        <PackingContainerUnitList
          onRemove={onRemove}
          containerNumber={containerNumber}
          products={products}
          removable={removable}
        />
      </CardContent>
    </Card>

  )
}

ContainerItems.defaultProps = {
  onRemove: () => null
}

ContainerItems.propTypes = {
  currentNumber: PropTypes.number.isRequired,
  totalContainers: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
  containerType: PropTypes.string.isRequired,
  containerNumber: PropTypes.string.isRequired,
  products: PropTypes.array.isRequired,
  removable: PropTypes.bool,

}

export default ContainerItems
