import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import PostmenIntegrationUpdateContainer from './PostmenIntegrationUpdateContainer'
import PostmenIntegrationCreateContainer from './PostmenIntegrationCreateContainer'

export default () => {
  return [
    {
      layout: AuthLayout,
      path: ROUTES.POSTMEN_INTEGRATION_CREATE_PATH,
      component: PostmenIntegrationCreateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.POSTMEN_INTEGRATION_UPDATE_PATH,
      component: PostmenIntegrationUpdateContainer
    }
  ]
}
