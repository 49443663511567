import React from 'react'
import { path, prop } from 'ramda'
import Typography from '@mui/material/Typography'
import { MultiSelectSearchField } from 'storfox-form-fields'

import * as API from '~/constants/api'

const getOptionValue = value => {
  if (value) {
    const id = prop('id', value)
    const guid = prop('guid', value)
    const name = prop('name', value)
    const companyName = path(['company', 'name'], value)

    return { id, guid, name, companyName }
  }

  return null
}

function PurchaseOrderWarehousesField (props) {
  return (
    <MultiSelectSearchField
      api={API.WAREHOUSE_LIST}
      data-cy="warehouses"
      name="warehouses"
      label="Warehouses"
      getOptionValue={getOptionValue}
      renderOption={(props, option) => (
        <li {...props}>
          <Typography>
            {option.name}
          </Typography>
          <Typography variant="body2">
            {option.companyName}
          </Typography>
        </li>
      )}
      {...props}
    />
  )
}

export default PurchaseOrderWarehousesField
