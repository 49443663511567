import React, { useCallback, useMemo } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { prop, propOr } from 'ramda'
import { useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import Title from '~/components/Title'
import { useCompany } from '~/components/Profile'
import * as NAV from '~/constants/nav-titles'
import useMessages from '~/hooks/useMessages'
import * as ROUTES from '~/constants/routes'
import { ADJUSTMENT_DETAIL_TABS } from '~/constants/tabs'

import { SAVE_COMPLETE } from '../constants'
import { AdjustmentUpdateForm } from '../components/AdjustmentUpdate'
import {
  useAdjustmentCancel,
  useAdjustmentComplete,
  useAdjustmentDetail,
  useAdjustmentLineItems,
  useAdjustmentUpdate,
  useAdjustmentUpdateAndComplete,
  useAdjustmentVariant,
  useBarcodeGenerate,
  useBarcodeUpdateCheck,
  useDefaultConditionList,
  useUnitCheck
} from '../hooks'
import {
  AdjustmentCreateSerializer,
  AdjustmentInitSerializer,
  BarcodeCheckAddSerializer,
  BarcodeCheckRemoveSerializer,
  UnitCheckSerializer
} from '../serializers'

function OrderCreateContainer () {
  const { id } = useParams()
  const { company } = useCompany()
  const snackbar = useSnackbar()
  const messages = useMessages()
  const navigate = useNavigate()

  const adjustmentDetail = useAdjustmentDetail(id)
  const adjustmentCancel = useAdjustmentCancel(id)
  const adjustmentUpdate = useAdjustmentUpdate(id)
  const adjustmentUpdateAndComplete = useAdjustmentUpdateAndComplete(id)
  const adjustmentComplete = useAdjustmentComplete(id)
  const adjustmentVariant = useAdjustmentVariant()
  const adjustmentLineItems = useAdjustmentLineItems(id, true, { limit: 1000 })

  const defaultConditionList = useDefaultConditionList()
  const unitCheck = useUnitCheck()
  const barcodeUpdateCheck = useBarcodeUpdateCheck()
  const barcodeGenerate = useBarcodeGenerate()

  const companyId = prop('id', company)

  const handleUnitCheck = useCallback((values, warehouseGuid) =>
    unitCheck.check(UnitCheckSerializer(values, warehouseGuid))
  , [unitCheck])

  const handleBarcodeCheck = useCallback(values => {
    const Serializer = prop('removeOperation', values)
      ? BarcodeCheckRemoveSerializer
      : BarcodeCheckAddSerializer
    return barcodeUpdateCheck.check(Serializer(values))
  }, [barcodeUpdateCheck])

  const handleSubmit = formValue => {
    const submitType = propOr(SAVE_COMPLETE, 'submitType', formValue)
    if (submitType === SAVE_COMPLETE) {
      return adjustmentUpdateAndComplete.update(AdjustmentCreateSerializer(formValue, companyId))
        .then(() => snackbar({ message: messages.NOTIFICATION_WAIT }))
        .then(() => {
          const redirect = generatePath(ROUTES.ADJUSTMENT_DETAIL_PATH, { id, tab: ADJUSTMENT_DETAIL_TABS.GENERAL })
          navigate(redirect)
        })
    } else {
      return adjustmentUpdate.update(AdjustmentCreateSerializer(formValue, companyId))
        .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
        .then(() => {
          const redirect = generatePath(ROUTES.ADJUSTMENT_DETAIL_PATH, { id, tab: ADJUSTMENT_DETAIL_TABS.GENERAL })
          navigate(redirect)
        })
    }
  }

  const handleGenerate = count => barcodeGenerate.generate({ count })

  const number = prop('number', adjustmentDetail.detail)
  const status = prop('status', adjustmentDetail.detail)
  const title = adjustmentDetail.isLoading ? 'Stock Adjustment' : `Stock Adjustment ${number}`

  const pageTitle = (
    <Title
      title="Stock Adjustment"
      number={number}
      status={status}
    />
  )

  const unitCheckLoading = unitCheck.isLoading
  const unitFieldMethods = useMemo(() => ({
    unitCheckLoading,
    handleUnitCheck,
    handleBarcodeCheck
  }), [handleBarcodeCheck, handleUnitCheck, unitCheckLoading])

  const breadcrumbs = {
    title: 'Edit',
    params: [
      { id, title, tab: ADJUSTMENT_DETAIL_TABS.GENERAL }
    ]
  }

  const initValues = useMemo(
    () => AdjustmentInitSerializer(adjustmentDetail.detail, adjustmentLineItems.results),
    [adjustmentDetail.detail, adjustmentLineItems]
  )

  const isLoading = (
    adjustmentUpdate.isLoading ||
    adjustmentComplete.isLoading ||
    adjustmentCancel.isLoading ||
    adjustmentUpdateAndComplete.isLoading
  )

  return (
    <DashboardLayout
      title={title}
      isLoading={adjustmentDetail.isLoading}
      activeNav={NAV.ADJUSTMENT}
      breadcrumbs={breadcrumbs}
    >
      <AdjustmentUpdateForm
        pageTitle={pageTitle}
        detailLoading={adjustmentDetail.isLoading}
        pageTitleLoading={adjustmentDetail.isLoading}
        onGenerate={handleGenerate}
        initialValues={initValues}
        isLoading={isLoading}
        onConditionGet={defaultConditionList.getList}
        unitFieldMethods={unitFieldMethods}
        onSubmit={handleSubmit}
        adjustmentVariant={adjustmentVariant}
      />
    </DashboardLayout>
  )
}

export default OrderCreateContainer
