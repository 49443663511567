import React, { useCallback } from 'react'
import TableRow from '@mui/material/TableRow'
import { pathOr, prop } from 'ramda'
import { generatePath, useNavigate } from 'react-router-dom'
import TableCell from '@mui/material/TableCell/TableCell'
import ListItemText from '@mui/material/ListItemText'
import PropTypes from 'prop-types'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { useTranslation } from 'react-i18next'

import * as ROUTES from '~/constants/routes'
import useDialog from '~/hooks/useDialog'
import { MoreButton } from '~/components/Buttons'

import LotModifyDialog from './LotModifyDialog'

function LotDetailTableRow ({ item, guid, onModify, modifyLoading }) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { open, handleOpen, handleClose } = useDialog()

  const handleModify = useCallback((values) => {
    onModify(values).then(() => handleClose())
  }, [handleClose, onModify])

  const lotNumber = pathOr('', ['lot', 'number'], item)
  const barcode = prop('barcode', item)
  const lotGuid = prop('guid', item)
  const totalQuantity = prop('totalQuantity', item)
  const scannedQuantity = prop('scannedQuantity', item)
  const damagedQuantity = prop('damagedQuantity', item)
  const lackedQuantity = prop('lackedQuantity', item)
  const status = prop('status', item)
  const unscannedQuantity = totalQuantity - scannedQuantity
  const lineItemGuid = prop('guid', item)
  const link = generatePath(ROUTES.LOT_SHEETS_SCAN_PATH, { guid, lineItemGuid })
  return (
    <>
      <TableRow>
        <TableCell>{lotNumber}</TableCell>
        <TableCell>{barcode}</TableCell>
        <TableCell>{unscannedQuantity}</TableCell>
        <TableCell>{scannedQuantity}</TableCell>
        <TableCell>{totalQuantity}</TableCell>
        <TableCell>{barcode}</TableCell>
        <TableCell>{damagedQuantity}</TableCell>
        <TableCell>{lackedQuantity}</TableCell>
        <TableCell>{status}</TableCell>
        <TableCell>
          <MoreButton>
            {() => (
              <List>
                <ListItem
                  button={true}
                  onClick={() => navigate(link)}
                >
                  <ListItemText primary={t('Lot scan')} />
                </ListItem>
                <ListItem
                  button={true}
                  onClick={() => handleOpen()}
                >
                  <ListItemText primary={t('Lot modify')} />
                </ListItem>
              </List>
            )}
          </MoreButton>
        </TableCell>
      </TableRow>
      {open && (
        <LotModifyDialog
          isLoading={modifyLoading}
          open={open}
          onClose={handleClose}
          onSubmit={handleModify}
          initialValues={{ guid: lotGuid }}
          title={`${lotNumber} - ${barcode}`}
        />
      )}
    </>
  )
}

LotDetailTableRow.propTypes = {
  guid: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  modifyLoading: PropTypes.bool.isRequired,
  onModify: PropTypes.func.isRequired
}

export default LotDetailTableRow
