import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import InvoiceListContainer from './containers/InvoiceListContainer'
import InvoiceDetailContainer from './containers/InvoiceDetailContainer'

export default () => [
  {
    layout: AuthLayout,
    path: ROUTES.INVOICE_LIST_PATH,
    component: InvoiceListContainer
  },
  {
    layout: AuthLayout,
    path: ROUTES.INVOICE_DETAIL_PATH,
    component: InvoiceDetailContainer
  }
]
