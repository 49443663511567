import React from 'react'
import { useField } from 'react-final-form'
import { includes, length, map, path, pipe, reduce } from 'ramda'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import { useTranslation } from 'react-i18next'

const getVariants = pipe(
  map(path(['variant', 'id'])),
  reduce((acc, item) => {
    return includes(item, acc) ? acc : [...acc, item]
  }, []),
  length
)

function MovementSummary () {
  const { t } = useTranslation()
  const lineItemsField = useField('lineItems')
  const lineItemsValue = path(['input', 'value'], lineItemsField) || []
  const quantity = lineItemsValue.reduce((quantity, item) => quantity + item.quantity, 0)

  const lineItems = getVariants(lineItemsValue)

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>{t('Movement summary')}</TableCell>
          <TableCell />
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>{t('Line items')}</TableCell>
          <TableCell />
          <TableCell align="right">
            {lineItems}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell><strong>{t('Total quantity')}</strong></TableCell>
          <TableCell />
          <TableCell align="right">
            {quantity}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export default MovementSummary
