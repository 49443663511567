import React from 'react'
import { useParams } from 'react-router-dom'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'
import { always, pathOr } from 'ramda'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import useMessages from '~/hooks/useMessages'
import ErrorLink from '~/components/ErrorLink'
import { PUTAWAY_DETAIL_TABS } from '~/constants/tabs'

import { PutawayAssignToSerializer } from '../serializers'
import PutawayDetail from '../components/PutawayDetail/PutawayDetail'
import {
  usePutawayAssignTo,
  usePutawayComplete,
  usePutawayDetail,
  usePutawayHistory,
  usePutawayProgress
} from '../hooks'

function PutawayDetailContainer () {
  const { guid, tab } = useParams()
  const putawayDetail = usePutawayDetail(guid)
  const putawayAssignTo = usePutawayAssignTo(guid)
  const putawayProgress = usePutawayProgress(guid)
  const putawayComplete = usePutawayComplete(guid)
  const putawayHistory = usePutawayHistory(guid, tab === PUTAWAY_DETAIL_TABS.HISTORY)
  const snackbar = useSnackbar()
  const messages = useMessages()

  const title = pathOr('Putaway', ['detail', 'number'], putawayDetail)

  const isLoading =
    putawayDetail.isLoading ||
    putawayComplete.isLoading ||
    putawayProgress.isLoading ||
    putawayAssignTo.isLoading

  const handleAssignTo = values =>
    putawayAssignTo.assign(PutawayAssignToSerializer(values))
      .then(() => snackbar({ message: messages.ASSIGN_SUCCESS }))
      .catch(response => {
        const message = <ErrorLink error={response} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const handleProgress = () =>
    putawayProgress.progress()
      .then(() => snackbar({ message: messages.PROGRESS_SUCCESS }))
      .then(() => putawayDetail.getDetail())
      .catch(response => {
        const message = <ErrorLink error={response} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const handleComplete = () =>
    putawayComplete.complete()
      .then(() => {
        snackbar({ message: messages.COMPLETE_SUCCESS })
      })
      .then(() => putawayDetail.getDetail())
      .catch(response => {
        const message = <ErrorLink error={response} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const breadcrumbs = { title }
  return (
    <DashboardLayout
      breadcrumbs={breadcrumbs}
      isLoading={isLoading}
      title={title}
      activeNav={NAV.V2_PUTAWAY}
    >
      <PutawayDetail
        tab={tab}
        initialValues={putawayDetail.detail}
        isLoading={putawayDetail.isLoading}
        pageTitleLoading={putawayDetail.isLoading}
        onSubmit={always(null)}
        onProgress={handleProgress}
        onComplete={handleComplete}
        onAssignTo={handleAssignTo}
        putawayHistory={putawayHistory}
        pageTitle={title}
      />
    </DashboardLayout>
  )
}

export default PutawayDetailContainer
