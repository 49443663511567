import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'

function FluidContainer ({ children }) {
  return (
    <Box mt={1} px={2} py={1}>
      {children}
    </Box>
  )
}

FluidContainer.propTypes = {
  children: PropTypes.any.isRequired
}

export default FluidContainer
