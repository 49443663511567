import React from 'react'
import Grid from '@mui/material/Grid'
import { Filter } from 'storfox-filter'
import { map, pick, pipe, prop, propOr } from 'ramda'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { DateRangeField } from 'storfox-form-fields'

import { arrayToParams, escapeAtob, getSeparatedDateRange, unescapeBtoa } from '~/utils'
import Accordion from '~/components/Accordion'
import MultiSelectSearchField from '~/components/Fields/MultiSelectSearchField'
import * as API from '~/constants/api'

import PutawayStatusField from './PutawayStatusField'
import PutawayUserField from './PutawayUserField'
import PutawayWarehouseField from './PutawayWarehouseField'

import { FILTER_NAME as name } from '../../constants'

const getAssignedToIds = pipe(
  prop('assigned'),
  map(pick(['id', 'firstName', 'lastName']))
)

const getWarehouseIds = pipe(
  prop('warehouses'),
  map(pick(['id', 'name']))
)

export const toParams = values => {
  const status = prop('status', values)
  const assignedToList = propOr([], 'assigned', values)
  const warehouseList = propOr([], 'warehouses', values)
  const variants = arrayToParams('variants', values)

  const assigned = assignedToList.length ? unescapeBtoa(getAssignedToIds(values)) : null
  const warehouses = warehouseList.length ? unescapeBtoa(getWarehouseIds(values)) : null

  const [createdAtStart, createdAtEnd] = getSeparatedDateRange(prop('createdAt', values))
  const [updatedAtStart, updatedAtEnd] = getSeparatedDateRange(prop('updatedAt', values))

  return {
    status,
    warehouses,
    assigned,
    variants,
    updatedAtStart,
    updatedAtEnd,
    createdAtStart,
    createdAtEnd
  }
}

export const toValues = params => {
  const status = prop('status', params)
  const warehouses = escapeAtob(prop('warehouses', params))
  const assigned = escapeAtob(prop('assigned', params))
  const variants = escapeAtob(prop('variants', params))

  const updatedAt = [prop('updatedAtStart', params), prop('updatedAtEnd', params)]
  const createdAt = [prop('createdAtStart', params), prop('createdAtEnd', params)]

  return {
    status,
    warehouses,
    assigned,
    variants,
    updatedAt,
    createdAt
  }
}

export const transformer = { toParams, toValues }

export const putawayFilterOptions = {
  name,
  transformer
}

function PutawayFilterForm (props) {
  const params = { perms: 'putaway-job-complete' }
  const { t } = useTranslation()

  return (
    <Filter {...props}>
      <Accordion title="General" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <PutawayStatusField />
          </Grid>
          <Grid item={true} xs={12}>
            <PutawayWarehouseField />
          </Grid>
          <Grid item={true} xs={12}>
            <PutawayUserField params={params} />
          </Grid>
          <Grid item={true} xs={12}>
            <MultiSelectSearchField
              api={API.VARIANT_LIST}
              data-cy="variants"
              name="variants"
              label="Variants"
            />
          </Grid>
        </Grid>
      </Accordion>
      <Accordion title="Date" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <Typography variant="body2" gutterBottom={true}>
              {t('Created')}:
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <DateRangeField name="createdAt" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography variant="body2" gutterBottom={true}>
              {t('Updated')}:
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <DateRangeField name="updatedAt" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Accordion>
    </Filter>
  )
}

export default PutawayFilterForm
