import PropTypes from 'prop-types'
import React from 'react'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import { map, range } from 'ramda'
import CardHeader from '@mui/material/CardHeader'
import Skeleton from '@mui/material/Skeleton'
import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import { TableCell } from '@mui/material'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import Box from '@mui/material/Box'

import SkeletonList from '~/components/Skeleton'

function ContainerItemsLoading ({ numberOfItems }) {
  return <>
    {map(index => (
      <Grid key={index} item={true} xs={12}>
        <Card variant="outlined">
          <CardHeader title={(
            <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
              <Box display="flex" alignItems="center">
                <Skeleton variant="rectangular" height={35} width={180} />
                <Box ml={2}>
                  <Skeleton width={200} />
                  <Skeleton />
                </Box>
              </Box>
              <Skeleton variant="rectangular" height={20} width={50} />
            </Box>
          )}
          />

          <Divider />

          <CardContent style={{ padding: 0 }}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Image</TableCell>
                    <TableCell>Variant</TableCell>
                    <TableCell>Serial number</TableCell>
                    <TableCell colSpan={2}>Quantity</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <SkeletonList
                    height={20}
                    rowCount={4}
                    columnCount={5}
                    lastRowBorderHidden={true}
                  />
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Grid>
    ), range(0, numberOfItems))}
  </>
}

ContainerItemsLoading.propTypes = {
  numberOfItems: PropTypes.number
}

ContainerItemsLoading.defaultProps = {
  numberOfItems: 1
}

export default ContainerItemsLoading
