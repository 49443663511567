import { path, prop } from 'ramda'

export const AccountUpdateSerializer = formValue => {
  const logo = prop('logo', formValue)
  const favicon = prop('favicon', formValue)
  const primaryColor = prop('primaryColor', formValue)
  const secondaryColor = prop('secondaryColor', formValue)
  const branding = { logo, primaryColor, secondaryColor, favicon }

  return { ...formValue, branding }
}

export const AccountInitSerializer = values => {
  const logo = path(['branding', 'logo'], values)
  const favicon = path(['branding', 'favicon'], values)
  const primaryColor = path(['branding', 'primaryColor'], values)
  const secondaryColor = path(['branding', 'secondaryColor'], values)
  return { ...values, logo, primaryColor, secondaryColor, favicon }
}
