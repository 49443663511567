import React from 'react'
import PropTypes from 'prop-types'

import { withForm } from '~/components/Form'
import PageTitle from '~/components/PageTitle'
import { DiscardButton, SaveButton } from '~/components/Buttons'
import Container, { Content, Header } from '~/components/Container'
import * as NAV from '~/constants/nav-titles'

import WarehouseContent from '../WarehouseContent'

function WarehouseUpdateForm (props) {
  const {
    pageTitle,
    pageTitleLoading,
    isLoading,
    form
  } = props

  return (
    <form onSubmit={form.handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={pageTitle}
            pageTitleLoading={pageTitleLoading}
            parentTitle={NAV.WAREHOUSES}
            rightButton={(
              <>
                <DiscardButton disabled={isLoading} />
                <SaveButton disabled={isLoading} />
              </>
            )}
          />
        </Header>

        <Content>
          <WarehouseContent />
        </Content>
      </Container>
    </form>
  )
}

WarehouseUpdateForm.propTypes = {
  pageTitle: PropTypes.string,
  pageTitleLoading: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default withForm(WarehouseUpdateForm)
