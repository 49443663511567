import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'

import Save from './Save'

function FilterSave ({ backend, currentValue, ...props }) {
  const current = backend.current

  const onSubmit = values => {
    backend.onSave({ ...values, values: currentValue })
      .then(() => props.onClose())
  }

  const onDelete = () => {
    backend.onDelete()
      .then(() => props.onClose())
  }

  const initialValues = {
    ...current,
    enabled: current.type === 'public',
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators
      }}
    >
      {({ handleSubmit }) => (
        <Save
          {...props}
          onDelete={onDelete}
          current={current}
          isLoading={backend.isLoading}
          handleSubmit={handleSubmit}
        />
      )}
    </Form>
  )
}

FilterSave.propTypes = {
  backend: PropTypes.object.isRequired,
  currentValue: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
}

export default FilterSave
