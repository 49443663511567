import { useList } from 'storfox-api-hooks'
import { sprintf } from 'sprintf-js'

import * as API from '../constants/api'

const useFilterItemsCount = group => {
  const url = sprintf(API.FILTER_CLAUSES_COUNT, group)
  const { getList, ...state } = useList(url, null, false)

  return { ...state, getList }
}

export default useFilterItemsCount
