import React from 'react'
import { Ordering } from 'storfox-filter'

import { FILTER_NAME as name } from '../../constants'
import { BRAND_COLUMNS as tableColumns } from '../BrandList/BrandTable'

export const brandOrderingOptions = {
  name,
  tableColumns
}

function BrandOrderingForm (props) {
  return (
    <Ordering {...props} />
  )
}

export default BrandOrderingForm
