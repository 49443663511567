import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import LCSIntegrationUpdateContainer from './LCSIntegrationUpdateContainer'
import LCSIntegrationCreateContainer from './LCSIntegrationCreateContainer'

export default () => {
  return [
    {
      layout: AuthLayout,
      path: ROUTES.LCS_INTEGRATION_CREATE_PATH,
      component: LCSIntegrationCreateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.LCS_INTEGRATION_UPDATE_PATH,
      component: LCSIntegrationUpdateContainer
    }
  ]
}
