import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'storfox-snackbar'
import { useNavigate, useParams } from 'react-router-dom'
import { prop, propOr } from 'ramda'

import { DashboardLayout } from '~/components/Layouts'
import Title from '~/components/Title'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import useMessages from '~/hooks/useMessages'

import { STATUSES, SAVE_PROCESS } from '../constants'
import TransferUpdateForm from '../components/TransferUpdate'
import { useTransferDetail, useTransferOrderVariant, useTransferUpdate, useTransferUpdateAndProcess } from '../hooks'
import { TransferCreateSerializer, TransferInitUpdateSerializer } from '../serializers'

function TransferUpdateContainer () {
  const { t } = useTranslation()
  const { guid } = useParams()

  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const messages = useMessages()

  const transferUpdate = useTransferUpdate(guid)
  const transferUpdateAndProcess = useTransferUpdateAndProcess(guid)
  const transferDetail = useTransferDetail(guid)
  const transferOrderVariant = useTransferOrderVariant()

  const number = prop('number', transferDetail.detail)
  const status = prop('status', transferDetail.detail)

  const title = `${t('Stock transfer')} ${number}`
  const pageTitle = (
    <Title
      title={t('Stock transfer')}
      number={number}
      status={STATUSES[status]}
    />
  )

  const handleSubmit = formValues => {
    const submitType = propOr(SAVE_PROCESS, 'submitType', formValues)
    if (submitType === SAVE_PROCESS) {
      return transferUpdateAndProcess.update(TransferCreateSerializer(formValues))
        .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
        .then(() => navigate(ROUTES.TRANSFER_LIST_PATH))
    } else {
      return transferUpdate.update(TransferCreateSerializer(formValues))
        .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
        .then(() => navigate(ROUTES.TRANSFER_LIST_PATH))
    }
  }

  const isLoading = (
    transferDetail.isLoading ||
    transferUpdate.isLoading ||
    transferUpdateAndProcess.isLoading
  )

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      isLoading={transferDetail.isLoading}
      activeNav={NAV.TRANSFER}
      breadcrumbs={breadcrumbs}
    >
      <TransferUpdateForm
        parentTitle={NAV.TRANSFER}
        pageTitle={pageTitle}
        pageTitleLoading={transferDetail.isLoading}
        isLoading={isLoading}
        initialValues={TransferInitUpdateSerializer(transferDetail.detail)}
        onSubmit={handleSubmit}
        transferOrderVariant={transferOrderVariant}
      />
    </DashboardLayout>
  )
}

export default TransferUpdateContainer
