import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import { prop, propOr } from 'ramda'

import { ExpandableTableRow, ExpandedTableWrapper } from '~/components/Table'
import SkeletonList from '~/components/Skeleton'
import { Volume } from '~/components/Converters'

function LocationAccordionTable ({ location, onUnitsLoad }) {
  const [open, setOpen] = useState(false)
  const locationId = prop('locationId', location)
  const unitCount = prop('unitCount', location)
  const usedVolume = prop('usedVolume', location)

  const units = propOr([], 'units', location)

  const handleExpandToggle = () => {
    setOpen(prevState => !prevState)
    if (!units.length) {
      onUnitsLoad()
    }
  }

  return (
    <>
      <ExpandableTableRow open={open} onExpandToggle={handleExpandToggle}>
        <TableCell>{locationId}</TableCell>
        <TableCell align="center">
          <Volume value={usedVolume} />
        </TableCell>
        <TableCell align="center">{unitCount}</TableCell>
      </ExpandableTableRow>

      <ExpandedTableWrapper open={open} colSpan={4} depth={3}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>SKU</TableCell>
              <TableCell>Unit number</TableCell>
              <TableCell align="center">Used volume</TableCell>
              <TableCell align="center">Unit count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!units.length && <SkeletonList columnCount={4} rowCount={1} />}
            {units.map(unit => {
              const guid = prop('guid', unit)
              const unitNumber = prop('unitNumber', unit)
              const sku = prop('sku', unit)
              const usedVolume = prop('totalVolume', unit)
              const unitCount = prop('quantity', unit)

              return (
                <TableRow key={guid}>
                  <TableCell>{sku}</TableCell>
                  <TableCell>{unitNumber}</TableCell>
                  <TableCell align="center">
                    <Volume value={usedVolume} />
                  </TableCell>
                  <TableCell align="center">{unitCount}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </ExpandedTableWrapper>
    </>
  )
}

LocationAccordionTable.propTypes = {
  location: PropTypes.object.isRequired,
  onUnitsLoad: PropTypes.func.isRequired
}

export default LocationAccordionTable
