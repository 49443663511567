import React from 'react'
import { useSnackbar } from 'storfox-snackbar'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { prop } from 'ramda'

import { HtmlLayout } from '~/components/Layouts'
import useMessages from '~/hooks/useMessages'
import * as ROUTES from '~/constants/routes'
import { PUTAWAY_DETAIL_TABS } from '~/constants/tabs'

import { PutawayCreateSerializer } from '../serializers'
import { usePutawayCreate, usePutawayScanLocation } from '../hooks'
import PutawayCreateForm from '../components/PutawayCreate'

function PutawayCreateContainer () {
  const { guid } = useParams()
  const putawayCreate = usePutawayCreate()
  const putawayScanItem = usePutawayScanLocation()
  const snackbar = useSnackbar()
  const messages = useMessages()
  const navigate = useNavigate()

  const handleSubmit = (values) =>
    putawayCreate.create(PutawayCreateSerializer(values))
      .then(({ result }) => {
        snackbar({ message: messages.CREATE_SUCCESS })
        const guid = prop('guid', result)
        const url = generatePath(ROUTES.V2_PUTAWAY_DETAIL_PATH, { guid, tab: PUTAWAY_DETAIL_TABS.GENERAL })
        navigate(url)
      })

  const handleScanItem = (value) =>
    putawayScanItem.scan(guid, value)

  const title = 'Putaway'
  return (
    <HtmlLayout title={title}>
      <PutawayCreateForm
        pageTitle="Create putaway"
        onScanItem={handleScanItem}
        onSubmit={handleSubmit}
        isLoading={putawayCreate.isLoading}
      />
    </HtmlLayout>
  )
}

export default PutawayCreateContainer
