import React from 'react'
import CardContent from '@mui/material/CardContent'
import Card from '@mui/material/Card'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { Button } from '~/components/Buttons'

function AddCard ({ primary, onClick }) {
  return (
    <Card>
      <CardContent>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '200px'
        }}>
          <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            <Typography variant="h6" gutterBottom={true}>{primary}</Typography>
            <Button onClick={onClick}>Add tier</Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}

export default AddCard

AddCard.propTypes = {
  primary: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
}
