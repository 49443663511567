import React from 'react'
import PropTypes from 'prop-types'
import { prop } from 'ramda'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { ScannerClearSearchField, toHelperText } from 'storfox-form-fields'
import { useTranslation } from 'react-i18next'

import * as API from '~/constants/api'

import Avatar from '../Avatar/Avatar'
import TextOverflow from '../TextOverflow'

const getOptionValue = item => {
  if (item) {
    const id = prop('id', item)
    const guid = prop('guid', item)
    const name = prop('name', item)
    const sku = prop('sku', item)
    const img = prop('defaultImage', item)

    return { id, guid, name, sku, img }
  }

  return null
}

function VariantsAutoClearField ({ push, error, helperText, ...props }) {
  const { t } = useTranslation()

  return (
    <ScannerClearSearchField
      data-cy="searchVariant"
      name="searchVariant"
      api={API.VARIANT_LIST}
      placeholder={t('Search by product name, SKU, barcode')}
      getOptionValue={getOptionValue}
      onChange={(event, value) => value && push(value)}
      error={error}
      helperText={toHelperText(helperText)}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <Grid container={true} spacing={2}>
              <Grid item={true}>
                <Avatar
                  src={option.img}
                  alt={option.name}
                />
              </Grid>
              <Grid item={true} sx={{ width: 'calc(100% - 75px)' }}>
                <TextOverflow selfTooltip={true} lines={1}>
                  {option.name}
                </TextOverflow>
                <Typography variant="body2">
                  {option.sku}
                </Typography>
              </Grid>
            </Grid>
          </li>
        )
      }}
      {...props}
    />
  )
}

VariantsAutoClearField.propTypes = {
  error: PropTypes.bool.isRequired,
  helperText: PropTypes.array,
  push: PropTypes.func.isRequired
}

export default VariantsAutoClearField
