import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Divider,
  IconButton,
  Input,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  styled,
  Toolbar,
  Tooltip
} from '@mui/material'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { join, path, pathOr, pluck, prop } from 'ramda'
import SearchIcon from '@mui/icons-material/Search'
import { generatePath, Link } from 'react-router-dom'
import Box from '@mui/material/Box'

import Avatar from '~/components/Avatar/Avatar'
import Subtext from '~/components/Subtext'
import TextOverflow from '~/components/TextOverflow'
import * as ROUTES from '~/constants/routes'

const RootBoxStyled = styled(Box)(({ theme }) => ({
  ...theme.components.StorfoxListPanel.styleOverrides.root,
  position: 'fixed',
  zIndex: '999',
  height: '100vh',
  width: 300,
  flexBasis: 300,
  [theme.breakpoints.down('md')]: {
    display: 'none'
  },
  '@media (min-width: 864px)': {
    borderRight: `1px solid ${theme.palette.divider}`
  }
}))

const ItemsStyled = styled(Box)({
  position: 'fixed',
  zIndex: '999',
  width: 300,
  overflowY: 'auto',
  height: 'calc(100vh - 128px)',
  flexShrink: 0
})

const ListItemButtonStyled = styled(ListItemButton, {
  shouldForwardProp: (propName) => propName !== 'isActive'
})(({ theme, isActive }) => ({
  ...(isActive && theme.components.StorfoxListPanel.styleOverrides.active)
}))

function VariantListItems ({ results, tab, variantId, edit }) {
  const [search, setSearch] = useState('')
  const resultsSearch = results.map(variant => {
    const options = pathOr([], ['options'], variant)
    const variantOption = pluck('option', options)
    const variantOptions = join(' ', variantOption)
    const sku = prop('sku', variant)
    const barcode = prop('barcode', variant)
    return {
      ...variant,
      fullName: `${variant.name} ${variantOptions} ${sku} ${barcode}`.toLowerCase()
    }
  })

  const itemRoute = edit ? ROUTES.VARIANT_UPDATE_PATH : ROUTES.VARIANT_DETAIL_PATH

  return (
    <RootBoxStyled>
      <Toolbar sx={{
        minHeight: 'auto',
        height: 58,
        p: 2
      }}>
        <Input
          disableUnderline={true}
          fullWidth={true}
          placeholder="Filter by name, SKU, barcode"
          onChange={event => setSearch(event.target.value)}
          value={search}
          sx={{
            border: '1px solid gray',
            borderRadius: '4px',
            flexGrow: 1
          }}
          startAdornment={(
            <Tooltip title="Search">
              <IconButton
                edge="end"
                sx={{ margin: 0, padding: '5px' }}
                size="medium"
              >
                <SearchIcon />
              </IconButton>
            </Tooltip>
          )}
        />
      </Toolbar>

      <Divider />

      <ItemsStyled>
        <PerfectScrollbar>
          <List component="div" disablePadding={true}>
            {resultsSearch
              .filter(item => item.fullName.search(search.toLowerCase()) !== -1)
              .map((variant, index) => {
                const id = prop('id', variant)
                const name = prop('name', variant)
                const src = prop('defaultImage', variant)
                const sku = <Subtext lines={1}>{prop('sku', variant)}</Subtext>
                const productId = path(['product', 'id'], variant)
                const to = `${generatePath(itemRoute, { id, productId, tab })}?isMultiVariant=true`

                return (
                  <ListItemButtonStyled
                    component={Link}
                    data-cy={`variant-${index}`}
                    to={to}
                    key={id}
                    isActive={String(id) === variantId}
                  >
                    <Box display="flex" alignItems="center">
                      <ListItemAvatar>
                        <Avatar alt={name} src={src} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={<TextOverflow selfTooltip={true}>{name}</TextOverflow>}
                        primaryTypographyProps={{ variant: 'h6', style: { fontWeight: 'normal' } }}
                        secondary={sku}
                        secondaryTypographyProps={{ variant: 'caption' }}
                      />
                    </Box>
                  </ListItemButtonStyled>
                )
              })}
          </List>
        </PerfectScrollbar>
      </ItemsStyled>
    </RootBoxStyled>
  )
}

VariantListItems.propTypes = {
  results: PropTypes.array.isRequired,
  variantId: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
  edit: PropTypes.bool
}

export default VariantListItems
