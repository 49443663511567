import React from 'react'
import isEqual from 'react-fast-compare'
import PropTypes from 'prop-types'
import MUITextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import VpnKey from '@mui/icons-material/VpnKey'
import Tooltip from '@mui/material/Tooltip'
import { FieldWrapper } from 'storfox-form-fields'
import { useTranslation } from 'react-i18next'

import { generatePassword } from '~/utils'

function PasswordGenerateField ({ input, meta, label, ...props }) {
  const { t } = useTranslation()

  return (
    <MUITextField
      onChange={event => input.onChange(event.target.value)}
      error={meta.invalid}
      value={input.value}
      label={t(label)}
      helperText={meta.submitError || meta.error}
      InputProps={{
        endAdornment: (
          <Tooltip title="Auto generate password">
            <IconButton onClick={() => input.onChange(generatePassword(8, true))} size="small">
              <VpnKey />
            </IconButton>
          </Tooltip>
        )
      }}
      {...props}
    />
  )
}

PasswordGenerateField.propTypes = {
  size: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string
}

PasswordGenerateField.defaultProps = {
  size: 'small',
  fullWidth: true,
  variant: 'outlined'
}

export default FieldWrapper(React.memo(PasswordGenerateField, isEqual))
