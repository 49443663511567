import React, { useCallback, useMemo } from 'react'
import { FieldArray } from 'react-final-form-arrays'
import Grid from '@mui/material/Grid'
import { isNil, path, pathEq, pick, prop } from 'ramda'
import Card from '@mui/material/Card'
import { useField } from 'react-final-form'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import equal from 'fast-deep-equal/react'
import Box from '@mui/material/Box'

import { CardHeader } from '~/components/Cards'
import BarcodeDoneInstruction from '~/components/Instructions/BarcodeDoneInstruction'
import ConditionInstruction from '~/components/Instructions/ConditionInstruction'
import BarcodeField from '~/components/BarcodeField/BarcodeField'

import BarcodeTitle from '../../../components/ReceiveBarcoding/BarcodeTitle'
import ConditionTitle from '../../../components/ReceiveBarcoding/ConditionTitle'
import TableSkeleton from '../TableSkeleton'
import VariantPreview from '../../ReceiveReturn/VariantPreview'
import LineItems from '../../ReceiveReturn/LineItems'
import DestinationPreview from '../DestinationPreview'

const emptyObject = {}

function ReturnLineItemGroups (props) {
  const {
    isLoading,
    barcodeRef,
    handleBarcodeScan,
    activeConditionCode,
    isBarcodeMode,
    handleActiveLineItemRemove,
    focusBarcodeField,
    handleDestinationRemove,
    DONE,
    scanLoading,
    scanSerial
  } = props
  const { t } = useTranslation()

  const lineItemsField = useField('lineItems')
  const lineItems = lineItemsField.input.value

  const activeLineItemField = useField('activeLineItem')
  const activeLineItem = activeLineItemField.input.value

  const previousLineItemsField = useField('previousValues.lineItems')
  const previousLineItems = previousLineItemsField.input.value

  const destinationField = useField('destination')
  const destination = destinationField.input.value || emptyObject

  const onEditUnit = unit => {
    const unitBarcode = path(['variant', 'barcode'], unit)
    const unitSerial = prop('serial', unit)

    if (unitSerial) {
      activeLineItemField.input.onChange(unit)
    } else {
      const newValues = {
        ...unit,
        serial: {
          conditionCode: activeConditionCode,
          quantity: 1,
          unitNumber: unitBarcode,
          ...unitSerial
        }
      }
      activeLineItemField.input.onChange(newValues)
    }

    const otherLineItems = lineItems.filter((item) => !equal(item, unit))
    const newLineItems = activeLineItem ? [activeLineItem, ...otherLineItems] : otherLineItems
    lineItemsField.input.onChange(newLineItems)
  }

  const disableEdit = useMemo(() => {
    const trackSerialNumbers = path(['variant', 'trackSerialNumbers'], activeLineItem)

    if (trackSerialNumbers) {
      return isNil(path(['serial', 'serialNumber'], activeLineItem))
    }

    return false
  }, [activeLineItem])

  const onPreviewDelete = useCallback(() => {
    const valueBarcode = path(['variant', 'barcode'], activeLineItem)
    const valueLineItem = lineItems.filter(pathEq(['variant', 'barcode'], valueBarcode))

    if (valueLineItem.length > 0) {
      handleActiveLineItemRemove()
    }

    if (valueLineItem.length === 0) {
      const previousItem = previousLineItems.filter(pathEq(['variant', 'barcode'], valueBarcode))
      const oldItemValues = {
        ...previousItem[0],
        variant: {
          ...previousItem[0].variant,
          ...pick(['width', 'height', 'length', 'weight'], activeLineItem.variant)
        }
      }
      const newLineItems = [...lineItems, oldItemValues]
      lineItemsField.input.onChange(newLineItems)
      handleActiveLineItemRemove()
    }
  }, [lineItemsField, handleActiveLineItemRemove, activeLineItem, lineItems, previousLineItems])

  return (
    <FieldArray
      name="lineItems"
      render={({ fields }) => {
        return <>
          <Grid container={true} spacing={3} alignItems="flex-end">
            <Grid item={true} lg={5}>
              <Grid container={true}>
                <Grid item={true} xs={12} lg={8}>
                  <BarcodeTitle
                    destination={destination}
                    isBarcodeMode={isBarcodeMode}
                    scanSerial={scanSerial}
                  />
                  <BarcodeField
                    barcodeRef={barcodeRef}
                    disabled={isLoading || scanLoading}
                    onEnter={handleBarcodeScan}
                    focusBarcodeField={focusBarcodeField}
                  />
                </Grid>
                <Grid item={true} xs={12} lg={4}>
                  <ConditionTitle activeConditionCode={activeConditionCode} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item={true} xs={12} lg={7}>
              <Box display="flex" justifyContent="flex-end">
                <BarcodeDoneInstruction doneText={DONE} />
                <ConditionInstruction />
              </Box>
            </Grid>
          </Grid>

          <Box mt={3}>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <Card>
                  <CardHeader title={isBarcodeMode ? 'Scanning' : 'Destination'} />
                  {isBarcodeMode ? (
                    <VariantPreview
                      fieldName="activeLineItem.serial"
                      variant={prop('variant', activeLineItem)}
                      onPreviewDelete={onPreviewDelete}
                      onBarcodeFocus={focusBarcodeField}
                      editDimension={false}
                    />
                  ) : (
                    <DestinationPreview
                      name="destination"
                      onRemove={handleDestinationRemove}
                      onBarcodeFocus={focusBarcodeField}
                    />
                  )}
                </Card>
              </Grid>
            </Grid>
          </Box>

          {lineItems.length > 0 && (
            <Box mt={3}>
              <Card>
                <CardHeader title={t('Units')} />
                <Grid container={true} spacing={2}>
                  <Grid item={true} xs={12}>
                    {!isLoading ? (
                      <LineItems
                        data={fields}
                        onEditUnit={onEditUnit}
                        edit={disableEdit}
                        isBarcodeMode={isBarcodeMode}
                        editDimension={false}
                        deleteEnabled={false}
                      />
                    ) : (
                      <TableSkeleton />
                    )}
                  </Grid>
                </Grid>
              </Card>
            </Box>
          )}
        </>
      }}
    />
  )
}

ReturnLineItemGroups.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  barcodeRef: PropTypes.any,
  handleBarcodeScan: PropTypes.func,
  activeConditionCode: PropTypes.string,
  isBarcodeMode: PropTypes.bool,
  handleActiveLineItemRemove: PropTypes.func,
  focusBarcodeField: PropTypes.func,
  handleDestinationRemove: PropTypes.func,
  DONE: PropTypes.string,
  scanLoading: PropTypes.bool,
  scanSerial: PropTypes.bool
}

export default ReturnLineItemGroups
