import React from 'react'
import { range } from 'ramda'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'

function SkeletonList (props) {
  const { count, xs, md } = props

  const items = range(0, count)

  return (
    <Grid container={true} spacing={3} sx={{ margin: '0px -12px' }}>
      {items.map((index) => (
        <Grid item={true} xs={xs} md={md} key={index}>
          <Skeleton variant="rectangular" sx={{ width: '100%' }} animation="wave" height={90} />
        </Grid>
      ))}
    </Grid>
  )
}

SkeletonList.defaultProps = {
  count: 4
}

SkeletonList.propTypes = {
  count: PropTypes.number,
  xs: PropTypes.number,
  md: PropTypes.number,
}

export default SkeletonList
