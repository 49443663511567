import React from 'react'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import PropTypes from 'prop-types'
import { path, prop } from 'ramda'

import { CardHeader } from '~/components/Cards'
import TextLabelLoader from '~/components/TextLabelLoader'

function ShipmentDetailFromAddress ({ fromAddress }) {
  const firstName = prop('firstName', fromAddress)
  const lastName = prop('lastName', fromAddress)
  const email = prop('email', fromAddress)
  const phone = prop('mobile', fromAddress)
  const address = prop('address', fromAddress)
  const region = prop('region', fromAddress)
  const city = prop('city', fromAddress)
  const zipcode = prop('zipcode', fromAddress)
  const country = path(['country', 'name'], fromAddress)
  const longitude = prop('longitude', fromAddress)
  const latitude = prop('latitude', fromAddress)

  return (
    <Card>
      <CardHeader title="Pickup address" />
      <Divider />
      <CardContent>
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={12} lg={6}>
            <TextLabelLoader label="First name">
              {firstName}
            </TextLabelLoader>
          </Grid>

          <Grid item={true} xs={12} lg={6}>
            <TextLabelLoader label="Last name">
              {lastName}
            </TextLabelLoader>
          </Grid>

          <Grid item={true} xs={12}>
            <TextLabelLoader label="Email">
              {email}
            </TextLabelLoader>
          </Grid>

          <Grid item={true} xs={12}>
            <TextLabelLoader label="Phone">
              {phone}
            </TextLabelLoader>
          </Grid>

          <Grid item={true} xs={12}>
            <TextLabelLoader label="Address">
              {address}
            </TextLabelLoader>
          </Grid>

          <Grid item={true} xs={12}>
            <TextLabelLoader label="Region">
              {region}
            </TextLabelLoader>
          </Grid>

          <Grid item={true} xs={12} lg={6}>
            <TextLabelLoader label="City">
              {city}
            </TextLabelLoader>
          </Grid>

          <Grid item={true} xs={12} lg={6}>
            <TextLabelLoader label="Zipcode">
              {zipcode}
            </TextLabelLoader>
          </Grid>

          <Grid item={true} xs={12}>
            <TextLabelLoader label="Country">
              {country}
            </TextLabelLoader>
          </Grid>

          <Grid item={true} xs={6}>
            <TextLabelLoader label="Longitude">
              {longitude}
            </TextLabelLoader>
          </Grid>

          <Grid item={true} xs={6}>
            <TextLabelLoader label="Latitude">
              {latitude}
            </TextLabelLoader>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

ShipmentDetailFromAddress.propTypes = {
  fromAddress: PropTypes.object
}

export default ShipmentDetailFromAddress
