import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { map, prop } from 'ramda'
import { generatePath, useNavigate } from 'react-router-dom'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import EditIcon from '@mui/icons-material/Edit'

import { NewButton } from '~/components/Buttons'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import { Table, TableHeader } from '~/components/Table'
import usePerm from '~/hooks/usePerm'
import { CUSTOMER_ADD } from '~/constants/permissions'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import { CUSTOMER_DETAIL_TABS, CUSTOMER_UPDATE_TABS } from '~/constants/tabs'
import TextOverflow from '~/components/TextOverflow'
import TableLink from '~/components/Link'
import { getFullName } from '~/utils'

import BulkActionButton from './BulkActionButton'

export const CUSTOMER_COLUMNS = [
  {
    width: 300,
    headerName: 'Full name',
    field: 'fullName',
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        <TextOverflow selfTooltip={true}>
          {value}
        </TextOverflow>
      </TableLink>
    )
  },
  {
    width: 300,
    headerName: 'First name',
    field: 'firstName'
  },
  {
    width: 300,
    headerName: 'Last name',
    field: 'lastName',
    renderCell: ({ value }) => (
      <TextOverflow selfTooltip={true}>{value}</TextOverflow>
    )
  },
  {
    width: 300,
    headerName: 'Email',
    field: 'email'
  },
  {
    width: 300,
    headerName: 'Mobile',
    field: 'mobile'
  }
]

const getRowsFromResults = map(item => {
  const id = prop('id', item)
  const guid = prop('guid', item)
  const fullName = getFullName(item)
  const firstName = prop('firstName', item)
  const lastName = prop('lastName', item)
  const email = prop('email', item)
  const mobile = prop('mobile', item)
  const link = generatePath(ROUTES.CUSTOMER_DETAIL_PATH, {
    guid,
    tab: CUSTOMER_DETAIL_TABS.GENERAL
  })

  return {
    id,
    guid,
    fullName,
    firstName,
    lastName,
    email,
    mobile,
    link
  }
})

function CustomerTable ({ list, filter, ordering, onListRefetch, onCustomersDelete, bulkActionLoading }) {
  const perm = usePerm()
  const navigate = useNavigate()

  const isLoading = prop('isLoading', list)
  const results = prop('results', list)
  const hasNextPage = prop('hasNextPage', list)
  const hasPrevPage = prop('hasPrevPage', list)
  const count = prop('count', list)
  const columns = prop('filteredColumns', ordering)

  const handleEdit = useCallback(guid => {
    const editPath = generatePath(ROUTES.CUSTOMER_UPDATE_PATH, {
      guid,
      tab: CUSTOMER_UPDATE_TABS.GENERAL
    })

    navigate(editPath)
  }, [navigate])

  const actionColumns = useMemo(() => [
    ...columns,
    {
      width: 50,
      resizable: false,
      field: 'actions',
      type: 'actions',
      getActions: ({ row }) => [
        <GridActionsCellItem
          label="Edit"
          onClick={() => handleEdit(row.guid)}
          showInMenu={true}
          icon={<EditIcon />}
        />
      ]
    }
  ], [columns, handleEdit])

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={NAV.CUSTOMERS}
          parentTitle={NAV.SALES}
          rightButton={(
            <>
              <BulkActionButton onCustomersDelete={onCustomersDelete} loading={bulkActionLoading} />
              <NewButton
                disabled={!perm.hasPerm(CUSTOMER_ADD)}
                path={ROUTES.CUSTOMER_CREATE_PATH}
              />
            </>
          )}
        />
      </Header>

      <Content>
        <TableHeader
          filter={filter}
          filterOpen={filter.handleOpen}
          orderingOpen={ordering.handleOpen}
          ordering={ordering}
        />
        <Table
          isLoading={isLoading}
          columns={actionColumns}
          results={results}
          getRowsFromResults={getRowsFromResults}
          count={count}
          checkboxSelection={true}
          primaryKey="guid"
          ordering={ordering}
          pinnedColumns={['fullName']}
          onRefetch={onListRefetch}
          isNewPagination={true}
          hasNextPage={hasNextPage}
          hasPrevPage={hasPrevPage}
        />
      </Content>
    </Container>
  )
}

CustomerTable.propTypes = {
  list: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  onCustomersDelete: PropTypes.func.isRequired,
  onListRefetch: PropTypes.func.isRequired,
  bulkActionLoading: PropTypes.bool
}

export default CustomerTable
