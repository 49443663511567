const KEY = 'profile'

const toObject = JSON.parse
const toJSON = JSON.stringify

export const setProfile = data => {
  if (data) {
    localStorage.setItem(KEY, toJSON(data))
  } else {
    localStorage.removeItem(KEY)
  }
}

export const getProfile = () => {
  const data = localStorage.getItem(KEY)

  if (data) {
    return toObject(data)
  }

  return null
}
