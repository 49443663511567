import React from 'react'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'

import SkeletonList from '~/components/Skeleton'

function StorageReportTableSkeleton () {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Company</TableCell>
            <TableCell>Used volume</TableCell>
            <TableCell>Unit count</TableCell>
            <TableCell>Warehouses</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <SkeletonList rowCount={1} columnCount={4} />
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default StorageReportTableSkeleton
