import React from 'react'
import { prop } from 'ramda'
import { MultiSelectSearchField } from 'storfox-form-fields'

import * as API from '~//constants/api'

const getOptionValue = value => {
  if (value) {
    const id = prop('id', value)
    const guid = prop('guid', value)
    const name = prop('name', value)

    return { id, guid, name }
  }

  return null
}

function CategoryMultiSelectField (props) {
  return (
    <MultiSelectSearchField
      api={API.CATEGORY_LIST}
      label="Category"
      name="category"
      getOptionValue={getOptionValue}
      {...props}
    />
  )
}

export default CategoryMultiSelectField
