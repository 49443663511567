import React from 'react'
import Delete from '@mui/icons-material/Delete'

import Button from '~/components/Buttons/Button'

function DeleteButton (props) {
  return (
    <Button
      type="button"
      variant="outlined"

      fullWidth={true}
      startIcon={<Delete />}
      {...props}
    >
      Delete
    </Button>
  )
}

export default DeleteButton
