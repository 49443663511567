import { map, path, pick, pipe, prop, propOr } from 'ramda'
import { DEFAULT_SEARCH_PARAMS } from 'storfox-route-hooks'

import { escapeAtob, orderingToSnackCase } from '~/utils'

export const RETURN_CREATE_FIELDS = [
  'lineItems',
  'billingAddress',
  'deliveryAddress',
  'warehouse',
  'supplier',
  'notes',
]

const getLineItems = pipe(
  propOr([], 'lineItems'),
  map(({ quantity, unit }) => ({
    quantity,
    unit: { guid: prop('guid', unit) },
  }))
)

const getCountry = country => {
  const guid = path(['country', 'guid'], country)

  return guid && { guid }
}

export function ReturnCreateSerializer (formValues) {
  const values = pick(RETURN_CREATE_FIELDS, formValues)
  const notes = prop('notes', values)
  const supplier = prop('supplier', values)
  const lineItems = getLineItems(values)

  const fromAddress = prop('billingAddress', values)
  const toAddress = prop('deliveryAddress', values)

  const warehouse = prop('warehouse', values)

  const billingAddress = { ...fromAddress, country: getCountry(fromAddress) }
  const deliveryAddress = { ...toAddress, country: getCountry(toAddress) }

  return {
    supplier,
    lineItems,
    notes,
    billingAddress,
    deliveryAddress,
    warehouse: warehouse && pick(['guid'], warehouse)
  }
}

export const ReturnInitUpdateSerializer = detail => {
  const deliveryAddress = prop('deliveryAddress', detail)
  const billingAddress = prop('billingAddress', detail)

  return {
    ...detail,
    deliveryAddress,
    billingAddress
  }
}

export const ReturnFilterSerializer = params => {
  const defaultParams = pick(DEFAULT_SEARCH_PARAMS, params)
  const status = prop('status', params)
  const supplierGuids = escapeAtob(prop('supplier', params))
  const warehouseGuids = escapeAtob(prop('warehouses', params))

  const createdAtEnd = prop('createdAtEnd', params)
  const createdAtStart = prop('createdAtStart', params)
  const totalPriceStart = prop('totalPriceStart', params)
  const totalPriceEnd = prop('totalPriceEnd', params)

  return orderingToSnackCase({
    ...defaultParams,
    status,
    supplierGuids,
    warehouseGuids,
    createdAtStart,
    createdAtEnd,
    totalPriceStart,
    totalPriceEnd
  })
}
