import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { useTranslation } from 'react-i18next'
import { Divider } from '@mui/material'

import { CardHeader } from '~/components/Cards'
import { DiscardButton, SaveButton } from '~/components/Buttons'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import { withForm } from '~/components/Form'
import * as NAV from '~/constants/nav-titles'

import ShopifyCreate from './ShopifyCreate'

import ShopifyInstructionCard from '../ShopifyInstructionCard'

function ShopifyIntegrationCreateForm ({ pageTitle, isLoading, form }) {
  const { t } = useTranslation()

  const { handleSubmit } = form

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            parentTitle={NAV.INTEGRATION}
            pageTitle={pageTitle}
            rightButton={
              <>
                <DiscardButton disabled={isLoading} />
                <SaveButton disabled={isLoading} />
              </>
            }
          />
        </Header>
        <Content>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12}>
              <ShopifyInstructionCard completedTasks={4} />
            </Grid>
            <Grid item={true} xs={12}>
              <Card>
                <CardHeader title={t('Integration')} />
                <Divider />
                <CardContent>
                  <Grid container={true} spacing={3}>
                    <ShopifyCreate />
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Content>
      </Container>
    </form>
  )
}

ShopifyIntegrationCreateForm.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  form: PropTypes.object.isRequired
}

export default withForm(ShopifyIntegrationCreateForm)
