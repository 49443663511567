import React from 'react'
import PropTypes from 'prop-types'
import { propOr } from 'ramda'

import ListingListItems, { useSearchListItems } from '../ListingListItems'

function ListingUpdateListItems ({ detail, listingGuid, onVariantOptionChange }) {
  const results = propOr([], 'listingVariants', detail)
  const { search, handleSearch, filteredResults } = useSearchListItems(results)

  const handleClick = guid => {
    onVariantOptionChange(guid)
  }

  return (
    <ListingListItems
      listingGuid={listingGuid}
      filteredResults={filteredResults}
      search={search}
      onClick={handleClick}
      onSearch={handleSearch}
    />
  )
}

ListingUpdateListItems.propTypes = {
  detail: PropTypes.object.isRequired,
  listingGuid: PropTypes.string.isRequired,
  onVariantOptionChange: PropTypes.func.isRequired
}

export default ListingUpdateListItems
