import React from 'react'
import { prop } from 'ramda'
import PropTypes from 'prop-types'

function Title ({ titleValues }) {
  const guid = prop('guid', titleValues)
  const area = prop('area', titleValues)
  const areaLabel = prop('label', area)
  const zone = prop('zone', titleValues)
  const zoneLabel = prop('label', zone)
  const aisle = prop('aisle', titleValues)
  const bay = prop('bay', titleValues)
  const level = prop('level', titleValues)
  const bin = prop('bin', titleValues)
  const locationName = prop('locationId', titleValues)

  const locationId = [areaLabel, zoneLabel, aisle, bay, level, bin].filter(Boolean).join('-')
  const isNewLocation = !guid

  return (
    <>
      {isNewLocation && 'New Location'} {locationName || locationId}
    </>
  )
}

Title.propTypes = {
  titleValues: PropTypes.object.isRequired
}

export default Title
