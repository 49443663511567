import React from 'react'
import PropTypes from 'prop-types'
import { pathOr, prop, propOr } from 'ramda'
import Barcode from 'react-barcode'

import Logo from '~/components/Logo'

import DarkLogo from '../../../../../../assets/images/storfox-logo.svg'

function ShipmentViewPrint ({ componentRef, shipment, container }) {
  const companyName = pathOr('', ['company', 'name'], shipment)
  const lotNumber = pathOr('', ['lot', 'number'], shipment)
  const bucket = pathOr('', ['bucket', 'name'], shipment)
  const destination = propOr('', 'destination', shipment)
  const containers = propOr([], 'containers', shipment)
  const currentContainer = containers[container]
  const lotVariantItems = propOr([], 'lotVariantItems', currentContainer)
  const containerNumber = propOr('', 'number', currentContainer)
  return (
    <div style={{ position: 'absolute' }}>
      <div ref={componentRef} style={{ padding: '50px 30px' }}>
        <table style={{ border: '1px solid black', borderCollapse: 'collapse', width: '100%' }}>
          <tr
            style={{
              borderBottom: '1px solid black',
              borderCollapse: 'collapse',
              padding: '25px 0',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Logo />
          </tr>
          <tr
            style={{
              borderBottom: '1px solid black',
              borderCollapse: 'collapse',
              padding: '20px 0',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <span style={{ fontWeight: 600 }}>Customer Name:</span>{' '}<span>{companyName}</span>
          </tr>
          <tr
            style={{
              borderBottom: '1px solid black',
              borderCollapse: 'collapse',
              padding: '20px 0',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <span style={{ fontWeight: 600 }}>Lot Number:</span>{' '}<span>{lotNumber}</span>
          </tr>
          <tr
            style={{
              borderBottom: '1px solid black',
              borderCollapse: 'collapse',
              padding: '20px 0',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <span style={{ fontWeight: 600 }}>Store Name:</span>{' '}<span>{bucket}</span>
          </tr>
          <tr
            style={{
              borderBottom: '1px solid black',
              borderCollapse: 'collapse',
              padding: '20px 0',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <span style={{ fontWeight: 600 }}>Destination:</span>{' '}<span>{destination}</span>
          </tr>
          <tr
            style={{
              borderBottom: '1px solid black',
              borderCollapse: 'collapse',
              padding: '20px 0',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <span style={{ fontWeight: 600 }}>Total Items:</span>{' '}<span>{lotVariantItems.length}</span>
          </tr>
          <tr
            style={{
              borderBottom: '1px solid black',
              borderCollapse: 'collapse',
              padding: '20px 0',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Barcode value={containerNumber} width={4} fontSize={20} />
          </tr>
          <tr
            style={{
              borderBottom: '1px solid black',
              borderCollapse: 'collapse',
              padding: '20px 0',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <span style={{ fontWeight: 600 }}>Cartoons:</span>{' '}<span>1/{containers.length}</span>
          </tr>
          <tr
            style={{
              borderBottom: '1px solid black',
              borderCollapse: 'collapse',
              padding: '20px 0',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <span style={{ fontWeight: 600 }}>SKU</span>
          </tr>
          <tr
            style={{
              borderBottom: '1px solid black',
              borderCollapse: 'collapse',
              padding: '20px 0',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <span>{lotVariantItems.map(prop('inputBarcode')).join(',')}</span>
          </tr>
          <tr
            style={{
              borderBottom: '1px solid #d7d7d7',
              borderCollapse: 'collapse',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <span style={{ textAlign: 'center', width: '150px' }}>Address</span>
            <span style={{ width: '80%', borderLeft: '1px solid black', padding: '20px 0', textAlign: 'center' }}>
              -
            </span>
          </tr>
          <tr
            style={{
              borderBottom: '1px solid black',
              borderCollapse: 'collapse',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <span style={{ textAlign: 'center', width: '150px' }}>Remarks</span>
            <span style={{ width: '80%', borderLeft: '1px solid black', padding: '20px 0', textAlign: 'center' }}>
              -
            </span>
          </tr>
          <tr
            style={{
              borderBottom: '1px solid black',
              borderCollapse: 'collapse',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div style={{ width: '150px' }}>
              <DarkLogo />
            </div>
          </tr>
        </table>
      </div>
    </div>
  )
}

ShipmentViewPrint.propTypes = {
  componentRef: PropTypes.object,
  shipment: PropTypes.object,
  container: PropTypes.object
}

export default ShipmentViewPrint
