import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import JsBarcode from 'jsbarcode'
import Barcode from 'react-barcode'
import { prop, propOr } from 'ramda'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'

import { BARCODE_TYPE } from '~/constants/barcode'

import BarcodePrintEan13 from './BarcodePrintEan13'

import { getCodes, multiSku } from '../../../utils'

const BarcodePrint = React.forwardRef(({ classes, items, quantity, fontSize, barcodeType }, ref) => {
  const multiBarcodes = multiSku(items, quantity)
  const barcodes = getCodes(multiBarcodes)

  useEffect(() => {
    JsBarcode('.barcode').init()
  }, [barcodes])

  return (
    <div ref={ref}>
      {barcodeType === BARCODE_TYPE.CODE128 && barcodes.map((item, index) => {
        const barcode = prop('code', item)
        const titles = propOr([], 'titles', item)
        return (
          <Box key={index} mb={2}>
            <Card>
              <Box sx={{
                pageBreakAfter: 'always',
                pageBreakInside: 'avoid',
                '& svg': {
                  width: '100% !important'
                }
              }}>
                <Barcode value={barcode} width={4} fontSize={fontSize} />
                {titles.map((title, index) => (
                  <Typography key={index} align="center">
                    <strong>{title}</strong>
                  </Typography>
                ))}
              </Box>
            </Card>
          </Box>
        )
      })}
      {barcodeType === BARCODE_TYPE.EON13 && barcodes.map((item, index) => (
        <BarcodePrintEan13 item={item} fontSize={fontSize} key={index} />
      ))}
    </div>
  )
})

BarcodePrint.propTypes = {
  classes: PropTypes.object,
  items: PropTypes.array.isRequired,
  quantity: PropTypes.number.isRequired,
  fontSize: PropTypes.number.isRequired,
  barcodeType: PropTypes.string.isRequired
}

export default BarcodePrint
