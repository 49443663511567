import React from 'react'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { path, pathOr, prop } from 'ramda'
import PropTypes from 'prop-types'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import TableContainer from '@mui/material/TableContainer'
import { generatePath } from 'react-router-dom'
import { styled } from '@mui/material'

import { capitalize, tableDateFormat } from '~/utils'
import EmptyNotesIcon from '~/components/Icons/EmptyNotesIcon'
import EmptyLineItems from '~/components/EmptyLineItems/EmptyLineItems'
import SkeletonList from '~/components/Skeleton'
import Avatar from '~/components/Avatar'
import * as ROUTES from '~/constants/routes'
import TableLink from '~/components/Link'
import { VARIANT_DETAIL_TABS } from '~/constants/tabs'

const TableRowStyled = styled(TableRow)({
  height: '70px'
})

const TableCellStyled = styled(TableCell)({
  minWidth: '150px'
})

function SaleOrderUnitTable ({ unitList }) {
  const { t } = useTranslation()

  const isLoading = prop('isLoading', unitList)
  const results = prop('results', unitList)

  const renderEmptyLines = Boolean(!isLoading && !results.length)
  const renderLineItems = Boolean(!isLoading && results.length)

  return (
    <Box component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto' }}>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCellStyled>{t('Image')}</TableCellStyled>
              <TableCellStyled>{t('Variant')}</TableCellStyled>
              <TableCellStyled>{t('Unit number')}</TableCellStyled>
              <TableCellStyled>{t('Quantity')}</TableCellStyled>
              <TableCellStyled>{t('UoM')}</TableCellStyled>
              <TableCellStyled>{t('Reserved for')}</TableCellStyled>
              <TableCellStyled>{t('Serial number')}</TableCellStyled>
              <TableCellStyled>{t('Expiry date')}</TableCellStyled>
              <TableCellStyled>{t('Production date')}</TableCellStyled>
              <TableCellStyled>{t('Batch number')}</TableCellStyled>
              <TableCellStyled>{t('Condition')}</TableCellStyled>
              <TableCellStyled>{t('Location')}</TableCellStyled>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <SkeletonList
                rowCount={3}
                columnCount={13}
                height={20}
                rowHeight={70}
              />
            )}

            {renderEmptyLines && (
              <EmptyLineItems
                colSpan={10}
                icon={<EmptyNotesIcon />}
                text="No units by sale order"
              />
            )}

            {renderLineItems && results.map((row, index) => {
              const guid = prop('guid', row)
              const variant = prop('variant', row)
              const variantId = prop('id', variant)
              const variantName = prop('name', variant)
              const productId = pathOr(0, ['product', 'id'], variant)
              const src = prop('defaultImage', variant)
              const unitNumber = prop('unitNumber', row)
              const quantity = prop('quantity', row)
              const unitOfMeasurement = capitalize(prop('unitOfMeasurement', variant))
              const reservedFor = prop('reservedFor', row)
              const serialNumber = prop('serialNumber', row)
              const expiresAt = prop('expiresAt', row)
              const productionDate = prop('productionDate', row)
              const batchNumber = prop('batchNumber', row)
              const conditionCode = path(['condition', 'code'], row)
              const location = pathOr('', ['location', 'locationId'], row)

              const params = { id: variantId, productId, tab: VARIANT_DETAIL_TABS.GENERAL }
              const variantPath = generatePath(ROUTES.VARIANT_DETAIL_PATH, params)

              return (
                <TableRowStyled key={index}>
                  <TableCell>
                    <TableLink to={variantPath}>
                      <Avatar alt={variantName} src={src} />
                    </TableLink>
                  </TableCell>
                  <TableCell>
                    <TableLink to={variantPath}>{variantName}</TableLink>
                  </TableCell>
                  <TableCell>
                    <TableLink to={`${ROUTES.UNIT_HISTORY_PATH}?guid=${guid}`}>
                      {unitNumber}
                    </TableLink>
                  </TableCell>
                  <TableCell>{quantity}</TableCell>
                  <TableCell>{unitOfMeasurement}</TableCell>
                  <TableCell>{reservedFor}</TableCell>
                  <TableCell>{serialNumber}</TableCell>
                  <TableCell>{expiresAt && tableDateFormat(expiresAt)}</TableCell>
                  <TableCell>{productionDate && tableDateFormat(productionDate)}</TableCell>
                  <TableCell>{batchNumber}</TableCell>
                  <TableCell>{conditionCode}</TableCell>
                  <TableCell>{location}</TableCell>
                </TableRowStyled>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

SaleOrderUnitTable.propTypes = {
  unitList: PropTypes.object.isRequired
}

export default SaleOrderUnitTable
