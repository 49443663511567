import React from 'react'
import { useOrdering } from 'storfox-filter'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'

import { RoleOrderingForm, roleOrderingOptions, RoleTable } from '../components/RoleList'
import { useRoleList } from '../hooks'

function RoleListContainer () {
  const roleList = useRoleList()

  const ordering = useOrdering(roleOrderingOptions)

  const breadcrumbs = { title: NAV.ROLES }

  return (
    <DashboardLayout
      title={NAV.ROLES}
      activeNav={NAV.ROLES}
      breadcrumbs={breadcrumbs}
    >
      <RoleOrderingForm {...ordering} />

      <RoleTable
        list={roleList}
        ordering={ordering}
        onListRefetch={roleList.getListByParams}
      />
    </DashboardLayout>
  )
}

export default RoleListContainer
