import React, { useCallback, useRef, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'
import PropTypes from 'prop-types'

import { Button } from '~/components/Buttons'
import TextField from '~/components/Fields/TextField'
import { withForm } from '~/components/Form'
import { Scanner } from '~/components/Icons'

function ProDispatchScanDialog ({ handleClose, open, form }) {
  const [loading, setLoading] = useState(false)
  const itemRef = useRef(null)
  const numberRef = useRef(null)
  const { handleSubmit } = form

  const handleFormSubmit = useCallback((values) => {
    setLoading(true)
    handleSubmit(values)
      .then(() => {
        form.form.reset()
        numberRef.current.focus()
        setLoading(false)
      })
      .catch(() => setLoading(false))
  }, [form.form, handleSubmit, numberRef])

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth={true}>
      <DialogContent>
        <form onSubmit={handleFormSubmit}>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12}>
              <TextField
                autoFocus={true}
                name="referenceNumber"
                label="Enter Tracking number or Reference ID"
                data-cy="containerId"
                inputProps={{
                  ref: numberRef
                }}
              />
            </Grid>
            <Grid
              item={true}
              xs={12}
              sx={{ padding: '50px', display: 'flex', justifyContent: 'center' }}
            >
              <Scanner />
            </Grid>
            <Grid
              item={true}
              xs={12}
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              <Button type="submit" disabled={loading}>
                Scan reference ID
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ margin: 'auto' }}>
        <Button ref={itemRef} onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ProDispatchScanDialog.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  form: PropTypes.object.isRequired
}

export default withForm(ProDispatchScanDialog)
