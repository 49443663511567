import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { map, path, prop } from 'ramda'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

import Avatar from '~/components/Avatar/Avatar'
import { Dimensions, Volume, Weight } from '~/components/Converters'
import { useBarcodeSelect } from '~/components/BarcodePrintDialog/hooks'
import { Table, TableHeader } from '~/components/Table'
import * as ROUTES from '~/constants/routes'
import TableLink from '~/components/Link'
import { MoreListButton } from '~/components/Buttons'
import { useTableSelects } from '~/components/TableValues/hooks'
import { unescapeBtoa } from '~/utils'

import BulkActionButton from './BulkActionButton'

const getDetailPath = guid => {
  return generatePath(ROUTES.CONTAINER_DETAIL_PATH, { guid })
}

export const CONTAINER_COLUMNS = [
  {
    width: 75,
    headerName: 'Image',
    field: 'image',
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        <Avatar
          alt={`${row.number}`}
          src={value}
        />
      </TableLink>
    )
  },
  {
    width: 150,
    headerName: 'ID',
    field: 'number',
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        {value}
      </TableLink>
    )
  },
  {
    width: 200,
    headerName: 'Container type',
    field: 'containerType'
  },
  {
    width: 200,
    headerName: 'Location',
    field: 'location'
  },
  {
    width: 100,
    headerName: 'SKUs',
    field: 'skusCount'
  },
  {
    width: 100,
    headerName: 'Qty',
    field: 'quantity'
  },
  {
    width: 200,
    headerName: 'Dimensions',
    field: 'dimensions',
    valueFormatter: ({ value }) => (
      `${value.length}x${value.width}x${value.height}`
    ),
    renderCell: ({ value }) => (
      <Dimensions
        length={value.length}
        height={value.height}
        width={value.width}
      />
    )
  },
  {
    width: 150,
    headerName: 'Volume',
    field: 'volume',
    renderCell: ({ value }) => (
      <Volume value={value} />
    )
  },
  {
    width: 150,
    headerName: 'Max. Load',
    field: 'maxLoad',
    renderCell: ({ value }) => (
      <Weight value={value} />
    )
  }
]

const getRowsFromResults = map(item => {
  const id = prop('id', item)
  const guid = prop('guid', item)
  const number = prop('number', item)
  const containerType = path(['containerType', 'name'], item)
  const skusCount = prop('skusCount', item)
  const quantity = prop('quantity', item)
  const length = prop('length', item)
  const location = path(['location', 'locationId'], item)
  const width = prop('width', item)
  const height = prop('height', item)
  const dimensions = { length, width, height }
  const volume = prop('volume', item)
  const maxLoad = prop('maxLoad', item)
  const image = path(['containerType', 'imagePath'], item)
  const link = getDetailPath(guid)

  return {
    id,
    guid,
    image,
    number,
    containerType,
    location,
    skusCount,
    quantity,
    dimensions,
    volume,
    maxLoad,
    link
  }
})

function ContainerTable ({ list, filter, ordering, onListRefetch }) {
  const [tableRef, setTableRef] = useState(null)
  const { guid } = useParams()
  const navigate = useNavigate()
  const { selects, resetTableSelects } = useTableSelects()

  const isLoading = prop('isLoading', list)
  const results = prop('results', list)
  const count = prop('count', list)
  const columns = prop('filteredColumns', ordering)

  const { selectedRows } = useBarcodeSelect(results, 'id', selects)

  const printLocationBarcode = useCallback(() => {
    let printBarcode = []
    for (const container of selectedRows) {
      const number = prop('number', container)
      printBarcode.push({ barcode: number })
    }
    const data = unescapeBtoa(printBarcode)
    window.open(`${ROUTES.BARCODE_GENERATOR_PATH}?barcodes=${data}`, '_blank')
    resetTableSelects()
  }, [selectedRows, resetTableSelects])

  const createPath = generatePath(ROUTES.CONTAINER_CREATE_PATH, { warehouseGuid: guid })

  return (
    <>
      <TableHeader
        filter={filter}
        filterOpen={filter.handleOpen}
        orderingOpen={ordering.handleOpen}
        ordering={ordering}
        onCreateClick={() => navigate(createPath)}
      >
        <BulkActionButton printBarcode={printLocationBarcode} />
        <MoreListButton tableRef={tableRef} />
      </TableHeader>
      <Table
        isLoading={isLoading}
        columns={columns}
        results={results}
        getRowsFromResults={getRowsFromResults}
        pinnedColumns={['image', 'number']}
        count={count}
        checkboxSelection={true}
        ordering={ordering}
        onRefetch={onListRefetch}
        setTableRef={setTableRef}
      />
    </>
  )
}

ContainerTable.propTypes = {
  list: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  onListRefetch: PropTypes.func.isRequired
}

export default ContainerTable
