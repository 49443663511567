import React from 'react'
import { useField } from 'react-final-form'
import PropTypes from 'prop-types'
import { prop } from 'ramda'
import { useDeepCompareEffect } from 'storfox-api-hooks'

import ConditionCodeField from './ConditionCodeField'

const getOptionValue = (value) => {
  const { id, guid, code, retailPrice, quantity } = value
  return { id, guid, name: code, retailPrice, quantity }
}

function PricingConditionField ({ name, totalQuantityName, quantityName, ...props }) {
  const field = useField(name)
  const value = field.input.value
  const totalQuantityField = useField(totalQuantityName)

  useDeepCompareEffect(() => {
    const totalQuantity = prop('quantity', value)

    if (totalQuantity) {
      totalQuantityField.input.onChange(totalQuantity)
    }
  }, [value])

  return (
    <ConditionCodeField
      name={name}
      getOptionValue={getOptionValue}
      {...props}
    />
  )
}

PricingConditionField.defaultProps = {
  totalQuantityName: 'totalQuantity',
  quantityName: 'quantity'
}

PricingConditionField.propTypes = {
  name: PropTypes.string.isRequired,
  totalQuantityName: PropTypes.string.isRequired,
  quantityName: PropTypes.string.isRequired,
  params: PropTypes.object
}

export default PricingConditionField
