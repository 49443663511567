import React from 'react'
import PropTypes from 'prop-types'

import TableSplitButton from '~/components/TableSplitButton'

function BulkActionButton ({ onReturnsCancel, loading }) {
  const options = [
    {
      label: 'Cancel',
      'data-cy': 'cancel-menu',
      onMenuClick: onReturnsCancel
    }
  ]

  return (
    <TableSplitButton
      options={options}
      name="sale-return"
      loading={loading}
    />
  )
}

BulkActionButton.propTypes = {
  onReturnsCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool
}

export default BulkActionButton
