import React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

import Tabs from '~/components/Tabs'
import Divider from '~/components/Divider'
import * as ROUTES from '~/constants/routes'
import { BUNDLE_UPDATE_TABS } from '~/constants/tabs'

const tabs = [
  { value: BUNDLE_UPDATE_TABS.GENERAL, label: 'General' },
  { value: BUNDLE_UPDATE_TABS.COMPOSITION, label: 'Composition' }
]

function BundleUpdateTabs () {
  const navigate = useNavigate()
  const { guid, tab } = useParams()

  const handleChange = (event, tab) => {
    const path = generatePath(ROUTES.BUNDLE_UPDATE_PATH, { guid, tab })

    navigate(path, { replace: true })
  }

  return (
    <>
      <Tabs
        data-cy="bundleTabs"
        tabs={tabs}
        value={tab}
        onChange={handleChange}
      />
      <Divider />
    </>
  )
}

export default BundleUpdateTabs
