import React, { useCallback, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import PropTypes from 'prop-types'
import { useField } from 'react-final-form'
import { path, prop, propEq, propOr } from 'ramda'
import { Divider } from '@mui/material'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'

import BarcodeField from '~/components/BarcodeField'
import { CardHeader } from '~/components/Cards'
import { useScreenOutline } from '~/components/ScreenOutline'
import Button from '~/components/Buttons/Button'

import LineItems from './LineItems'
import BarcodeTitle from './BarcodeTitle'
import ContainerItems from './ContainerItems'
import ScanTypeRadioButtons, {
  SCAN_TYPE_FULL_BARCODE,
  SCAN_TYPE_POSTFIX,
  SCAN_TYPE_PREFIX
} from './ScanTypeRadioButtons'

function LotSheetScanGeneral ({ isLoading, detail }) {
  const barcodeRef = useRef(null)
  const [container, setContainer] = useState('')
  const { handleSoundedTrigger, handleSoundedErrorTrigger } = useScreenOutline()

  const mainBarcode = prop('mainBarcode', detail)
  const allocationMode = path(['lot', 'allocationMode'], detail)

  const itemsField = useField('items')
  const itemsValue = itemsField.input.value || []
  const itemsFieldChange = itemsField.input.onChange

  const scanTypeField = useField('scanType')
  const scanTypeValue = scanTypeField.input.value

  const barcodeField = useField('barcode')
  const clearBarcodeField = useCallback(() => barcodeField.input.onChange(''), [barcodeField])

  const correctBarcodeCheck = (barcode) => {
    if (scanTypeValue === SCAN_TYPE_FULL_BARCODE) {
      return barcode === mainBarcode
    }
    if (scanTypeValue === SCAN_TYPE_PREFIX && barcode.length === 8) {
      const prefixBarcode = mainBarcode.substr(0, 8)
      return barcode === prefixBarcode
    }
    if (scanTypeValue === SCAN_TYPE_POSTFIX && barcode.length === 8) {
      const postfixBarcode = mainBarcode.substr(mainBarcode.length - 8, 8)
      return barcode === postfixBarcode
    }
    return false
  }

  const handleBarcodeFocus = () => {
    barcodeRef.current.focus()
  }

  const getContainersQuantity = (item) => {
    const containers = propOr([], 'containers', item)
    return containers.reduce((acc, item) => acc + propOr(0, 'quantity', item) + propOr(0, 'scannedQuantity', item), 0)
  }

  const findNotScannedItem = () => {
    return itemsValue.find(item => {
      const totalQuantity = prop('totalQuantity', item)
      const quantity = getContainersQuantity(item)
      return quantity < totalQuantity
    })
  }

  const findMinQuantityItem = () => {
    const notCompletedItems = itemsValue.filter((item) => {
      const containerQuantity = getContainersQuantity(item)
      const totalQuantity = prop('totalQuantity', item)
      return containerQuantity < totalQuantity
    })
    const minQuantity = Math.min(...notCompletedItems.map(item => {
      return getContainersQuantity(item)
    }))
    return notCompletedItems.find((item) => {
      const containersQuantity = getContainersQuantity(item)
      if (containersQuantity < propOr(0, 'totalQuantity', item)) {
        return containersQuantity === minQuantity
      }
    })
  }

  const onAddQuantity = (lineItem, barcode) => {
    const containers = propOr([], 'containers', lineItem)
    const bucketName = path(['bucket', 'name'], lineItem)
    if (containers.length > 0) {
      const foundContainer = containers.find(propEq('number', container)) || containers[containers.length - 1]
      if (foundContainer) {
        const otherContainers = containers.filter((cont) => prop('number', cont) !== prop('number', foundContainer))
        const containerNumber = prop('number', foundContainer)
        const itemAfterScan = {
          ...lineItem,
          containers: [
            ...otherContainers,
            {
              ...foundContainer,
              quantity: prop('quantity', foundContainer) + 1,
              number: containerNumber
            }
          ],
          inputBarcode: barcode
        }
        const newItems = itemsValue.map((item) => {
          if (prop('id', item) !== prop('id', lineItem)) {
            return item
          } else return itemAfterScan
        })
        itemsFieldChange(newItems)
        setContainer(containerNumber)
        handleSoundedTrigger()
      }
    } else {
      const newContainer = `${bucketName}-001`
      const itemAfterScan = {
        ...lineItem,
        containers: [{ quantity: 1, number: newContainer }],
        inputBarcode: barcode
      }
      const newItems = itemsValue.map((item) => {
        if (prop('id', item) !== prop('id', lineItem)) {
          return item
        } else return itemAfterScan
      })
      itemsFieldChange(newItems)
      setContainer(newContainer)
      handleSoundedTrigger()
    }
  }

  const onBarcodeScan = (event) => {
    const barcode = event.target.value.trim()
    const checkBarcode = correctBarcodeCheck(barcode)
    if (checkBarcode) {
      if (allocationMode === 'round_robin') {
        const minQuantityItem = findMinQuantityItem()
        if (minQuantityItem) {
          onAddQuantity(minQuantityItem, barcode)
        } else {
          handleSoundedErrorTrigger()
        }
      } else {
        const notScannedQuantity = findNotScannedItem()
        if (notScannedQuantity) {
          onAddQuantity(notScannedQuantity, barcode)
        } else {
          handleSoundedErrorTrigger()
        }
      }
    } else {
      handleSoundedErrorTrigger()
    }
    clearBarcodeField()
    event.preventDefault()
  }

  const handleContainerCreate = () => {
    const lineItem = allocationMode === 'round_robin' ? findMinQuantityItem() : findNotScannedItem()
    if (lineItem) {
      const containers = propOr([], 'containers', lineItem)
      const bucketName = path(['bucket', 'name'], lineItem)
      const numberString = `000${containers.length + 1}`
      const containerQuantity = numberString.substr(numberString.length - 3, 3)
      const newContainerNumber = `${bucketName}-${containerQuantity}`
      const itemAfterScan = {
        ...lineItem,
        containers: [...containers, { quantity: 1, number: newContainerNumber }],
        inputBarcode: mainBarcode
      }
      const newItems = itemsValue.map((item) => {
        if (prop('id', item) !== prop('id', lineItem)) {
          return item
        } else return itemAfterScan
      })
      itemsFieldChange(newItems)
      setContainer(newContainerNumber)
      handleSoundedTrigger()
    } else {
      handleSoundedErrorTrigger()
    }
  }

  return (
    <>
      <Box mt={3}>
        <Grid container={true} spacing={3} alignItems="flex-end">
          <Grid item={true} xs={12} lg={4}>
            <BarcodeTitle />
            <BarcodeField
              barcodeRef={barcodeRef}
              onEnter={onBarcodeScan}
              focusBarcodeField={handleBarcodeFocus}
            />
            <ScanTypeRadioButtons />
          </Grid>
          <Grid item={true} lg={4} />
          <Grid item={true} lg={4}>
            <Card style={{ height: '100%' }}>
              <CardHeader
                title={
                  <>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Typography variant="h4">Active container</Typography>
                      <Button
                        variant="contained"
                        data-cy="create"
                        type="button"
                        size="small"
                        onClick={handleContainerCreate}
                      >
                        Create and scan
                      </Button>
                    </Box>
                  </>
                }
              />
              <Divider />
              <CardContent style={{ height: '100%' }}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Typography variant="h2">
                    {container || 'No container.'}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Box mt={3}>
        <Grid container={true} spacing={3}>
          <Grid lg={8} item={true}>
            <Card>
              <CardHeader title="Items" />
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={12}>
                  {!isLoading && itemsValue.length > 0 && (
                    <LineItems detail={detail} />
                  )}
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid lg={4} item={true}>
            <Card>
              <CardHeader title="Containers" />
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={12}>
                  {!isLoading && itemsValue.length > 0 && (
                    <ContainerItems />
                  )}
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

LotSheetScanGeneral.propTypes = {
  isLoading: PropTypes.bool,
  detail: PropTypes.object
}

export default LotSheetScanGeneral
