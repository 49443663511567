import React from 'react'
import PropTypes from 'prop-types'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

function ToggleField ({ className, handleOnChange, checked, ...props }) {
  const handleChange = e => handleOnChange(e.target.checked)

  return (
    <FormControlLabel
      className={className}
      control={<Checkbox checked={Boolean(checked)} onChange={handleChange} />}
      label="Not"
      {...props}
    />
  )
}

ToggleField.defaultProps = {}

ToggleField.propTypes = {
  checked: PropTypes.bool.isRequired,
  className: PropTypes.string,
  handleOnChange: PropTypes.func.isRequired,

}

export default ToggleField
