import React from 'react'
import PropTypes from 'prop-types'
import { Chip } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import Box from '@mui/material/Box'

import TextLabel from '~/components/TextLabel'

function SuggestedLocation ({ locations, isLoading, onSelectLocation }) {
  return (
    <TextLabel label="Suggested destination locations">
      {locations.map((location) => (
        <Chip
          key={location.locationId}
          color="info"
          label={location.locationId}
          style={{ marginRight: '5px', cursor: 'pointer', marginTop: '5px' }}
          onClick={() => onSelectLocation(location.locationId)}
        />
      ))}
      {isLoading && (
        <Skeleton variant="rectangular" height={32} width={75} style={{ borderRadius: '30px' }} />
      )}
      {!isLoading && locations.length === 0 && (
        <Box sx={{ height: '32px', width: '75px' }} />
      )}
    </TextLabel>
  )
}

SuggestedLocation.propTypes = {
  locations: PropTypes.array,
  isLoading: PropTypes.bool,
  onSelectLocation: PropTypes.func
}

export default SuggestedLocation
