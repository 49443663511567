import React from 'react'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import FormControl from '@mui/material/FormControl'
import PropTypes from 'prop-types'

import FieldWrapper from './FieldWrapper'

function TimeField ({ input, meta, format, ...defaultProps }) {
  const props = meta.invalid
    ? ({ ...defaultProps, error: true, helperText: meta.submitError || meta.error })
    : defaultProps

  return (
    <FormControl style={{ width: '100%' }} error={meta.invalid}>
      <DateTimePicker
        ampm={false}
        placeholder="08:00:00"
        value={input.value || null}
        onChange={time => input.onChange(time)}
        InputProps={{ sx: { pr: 0 } }}
        {...props}
      />
    </FormControl>
  )
}

TimeField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  format: PropTypes.string.isRequired
}

TimeField.defaultProps = {
  fullWidth: true,
  inputVariant: 'outlined',
  format: 'HH:mm',
  mask: '__:__',
  size: 'small'
}

export default FieldWrapper(TimeField)
