import React from 'react'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { pathOr, prop, propOr } from 'ramda'
import PropTypes from 'prop-types'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import Pagination from 'storfox-table/src/Table/Pagination'
import TableContainer from '@mui/material/TableContainer'

import { getFormattedDateTime } from '~/utils'
import EmptyNotesIcon from '~/components/Icons/EmptyNotesIcon'
import { HISTORY_FORMAT } from '~/constants/dateFormat'
import EmptyLineItems from '~/components/EmptyLineItems/EmptyLineItems'
import SkeletonList from '~/components/Skeleton'

function LotSheetsHistory ({ history }) {
  const { t } = useTranslation()

  const rows = prop('results', history)
  const isLoading = prop('isLoading', history)
  const count = prop('count', history)

  const renderEmptyLines = Boolean(!isLoading && !rows.length)
  const renderLineItems = Boolean(!isLoading && rows.length)

  return (
    <Box component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto' }}>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '20%' }}>{t('Date')}</TableCell>
              <TableCell sx={{ width: '20%' }}>{t('Scanned quantity')}</TableCell>
              <TableCell sx={{ width: '20%' }}>{t('Bucket')}</TableCell>
              <TableCell sx={{ width: '10%' }}>{t('Lot number')}</TableCell>
              <TableCell sx={{ width: '10%' }}>{t('Container')}</TableCell>
              <TableCell sx={{ width: '30%' }}>{t('User')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <SkeletonList
                rowCount={3}
                columnCount={3}
                height={25}
              />
            )}

            {renderEmptyLines && (
              <EmptyLineItems
                colSpan={3}
                icon={<EmptyNotesIcon />}
                text="No recorded history"
              />
            )}
            {renderLineItems && rows.map((row, index) => {
              const dateTime = getFormattedDateTime(prop('createdAt', row), HISTORY_FORMAT)
              const scannedQuantity = prop('scannedQuantity', row)
              const user = prop('user', row)
              const firstName = prop('firstName', user)
              const lastName = prop('lastName', user)
              const fullName = `${firstName} ${lastName}`
              const bucket = pathOr('', ['bucket', 'name'], row)
              const lot = pathOr('', ['lot', 'number'], row)
              const containerNumber = propOr('', 'containerNumber', row)

              return (
                <TableRow key={index}>
                  <TableCell>{dateTime}</TableCell>
                  <TableCell>{scannedQuantity}</TableCell>
                  <TableCell>{bucket}</TableCell>
                  <TableCell>{lot}</TableCell>
                  <TableCell>{containerNumber}</TableCell>
                  <TableCell>{fullName}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination count={count} prefix="" />
    </Box>
  )
}

LotSheetsHistory.defaultProps = {
  rows: []
}

LotSheetsHistory.propTypes = {
  history: PropTypes.object.isRequired
}

export default LotSheetsHistory
