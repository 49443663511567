import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import CustomerCreateContainer from './containers/CustomerCreateContainer'
import CustomerListContainer from './containers/CustomerListContainer'
import CustomerDetailContainer from './containers/CustomerDetailContainer'
import CustomerUpdateContainer from './containers/CustomerUpdateContainer'

export default () => {
  return [
    {
      layout: AuthLayout,
      path: ROUTES.CUSTOMER_CREATE_PATH,
      component: CustomerCreateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.CUSTOMER_DETAIL_PATH,
      component: CustomerDetailContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.CUSTOMER_UPDATE_PATH,
      component: CustomerUpdateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.CUSTOMER_LIST_PATH,
      component: CustomerListContainer
    },
  ]
}
