import React from 'react'
import { defaultTo, fromPairs, map, pipe, prop } from 'ramda'
import PropTypes from 'prop-types'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import TableContainer from '@mui/material/TableContainer'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import InfoIcon from '@mui/icons-material/Info'
import { IconButton } from '@mui/material'

import HtmlTooltip from '~/components/HtmlTooltip'
import { CardHeader } from '~/components/Cards'
import { emptyArray } from '~/constants/empty'

import Chart from './Chart'

import { generateDays, getLabelsFromDateRange } from '../../../utils'

const getGmsdtd = (days, data) => {
  const dmsKeyValue = pipe(
    defaultTo([]),
    map(item => [
      prop('date', item),
      prop('gms', item)
    ]),
    fromPairs
  )(data)

  const dmsdtd = days.map(day => {
    const key = day.toFormat('yyyy-MM-dd')
    return prop(key, dmsKeyValue) || 0
  })

  return {
    days: dmsdtd
  }
}

function GMSDayToDay ({ items, dateRange }) {
  const { t } = useTranslation()

  const days = generateDays(dateRange)
  const labels = getLabelsFromDateRange(dateRange) || emptyArray
  const gsmdtd = getGmsdtd(days, items)

  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader
        title={
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h4">{t('GMS DTD')}</Typography>
            <HtmlTooltip title="Total Gross value of stock">
              <IconButton size="small">
                <InfoIcon sx={{ color: '#BDBDBD' }} />
              </IconButton>
            </HtmlTooltip>
          </Box>
        }
      />
      <Divider />
      <CardContent sx={{ p: 0 }}>
        <TableContainer>
          <Box sx={{ minWidth: 700 }}>
            <Chart
              data={gsmdtd}
              labels={labels}
              sx={{
                padding: theme => theme.spacing(4, 2, 0, 2),
                height: 450
              }}
            />
          </Box>
        </TableContainer>
      </CardContent>
    </Card>
  )
}

GMSDayToDay.propTypes = {
  items: PropTypes.array,
  dateRange: PropTypes.array
}

export default GMSDayToDay
