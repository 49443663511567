import React from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import { path, prop, propOr } from 'ramda'

import { CardHeader } from '~/components/Cards'
import TextLabelLoader from '~/components/TextLabelLoader'
import Tags from '~/components/Tags'
import { Dimension, Weight } from '~/components/Converters'
import MultiImagePreview from '~/components/ImageUpload/MultiImagePreview'
import BooleanLabel from '~/components/BooleanLabel'

import { PRODUCT_TYPE_NAMES, STRATEGY_TYPE_NAMES } from '../../../constants'

function ProductDetailGeneral ({ detail }) {
  const name = prop('name', detail)
  const sku = prop('sku', detail)
  const barcode = prop('barcode', detail)
  const totalAvailable = prop('totalAvailable', detail)
  const type = prop('type', detail)
  const description = prop('description', detail)
  const images = prop('images', detail)
  const category = path(['category', 'name'], detail)
  const brand = path(['brand', 'name'], detail)
  const tags = propOr([], 'tags', detail)
  const height = prop('height', detail)
  const width = prop('width', detail)
  const length = prop('length', detail)
  const weight = prop('weight', detail)
  const strategy = prop('strategy', detail)
  const trackSerialNumbers = prop('trackSerialNumbers', detail)
  const trackBatchNumbers = prop('trackBatchNumbers', detail)
  const trackProductionDates = prop('trackProductionDates', detail)
  const trackExpiryDates = prop('trackExpiryDates', detail)
  const minExpiryDays = prop('minExpiryDays', detail)
  const supplier = path(['supplier', 'companyName'], detail)
  const company = path(['company', 'name'], detail)

  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12} lg={8}>
        <Box mb={3}>
          <Card>
            <CardHeader title="Details" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Name">
                    {name}
                  </TextLabelLoader>
                </Grid>
                <Grid item={true} xs={12}>
                  <TextLabelLoader label="SKU">
                    {sku}
                  </TextLabelLoader>
                </Grid>
                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Barcode">
                    {barcode}
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Type">
                    {PRODUCT_TYPE_NAMES[type]}
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Description">
                    {description}
                  </TextLabelLoader>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Box mb={3}>
          <Card>
            <CardHeader title="Images" />
            <Divider />
            <CardContent>
              <MultiImagePreview images={images} alt={name} />
            </CardContent>
          </Card>
        </Box>
      </Grid>

      <Grid item={true} xs={12} lg={4}>
        <Box mb={3}>
          <Card>
            <CardHeader title="Company" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Company">
                    {company}
                  </TextLabelLoader>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Box mb={3}>
          <Card>
            <CardHeader title="Supply" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Supplier">
                    {supplier}
                  </TextLabelLoader>
                </Grid>
                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Available">
                    {totalAvailable}
                  </TextLabelLoader>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Box mb={3}>
          <Card>
            <CardHeader title="Organization" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Category">
                    {category}
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Brand">
                    {brand}
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Tags">
                    <Tags items={tags} />
                  </TextLabelLoader>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Box mb={3}>
          <Card>
            <CardHeader title="Dimensions" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={6}>
                  <TextLabelLoader label="Height">
                    <Dimension value={height} />
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={6}>
                  <TextLabelLoader label="Width">
                    <Dimension value={width} />
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={6}>
                  <TextLabelLoader label="Length">
                    <Dimension value={length} />
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={6}>
                  <TextLabelLoader label="Weight">
                    <Weight value={weight} />
                  </TextLabelLoader>
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Box mb={3}>
          <Card>
            <CardHeader title="Fulfillment" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Strategy">
                    {STRATEGY_TYPE_NAMES[strategy]}
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Min expiry days">
                    {minExpiryDays}
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12}>
                  <BooleanLabel label="Track serial numbers">
                    {trackSerialNumbers}
                  </BooleanLabel>
                </Grid>

                <Grid item={true} xs={12}>
                  <BooleanLabel label="Track batch numbers">
                    {trackBatchNumbers}
                  </BooleanLabel>
                </Grid>

                <Grid item={true} xs={12}>
                  <BooleanLabel label="Track production dates">
                    {trackProductionDates}
                  </BooleanLabel>
                </Grid>

                <Grid item={true} xs={12}>
                  <BooleanLabel label="Track expiry dates">
                    {trackExpiryDates}
                  </BooleanLabel>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </Grid>
  )
}

ProductDetailGeneral.propTypes = {
  detail: PropTypes.object.isRequired
}

export default ProductDetailGeneral
