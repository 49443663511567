import React from 'react'
import PropTypes from 'prop-types'

import * as API from '~/constants/api'
import SearchField from '~/components/Fields/SearchField'

function ConditionCodeField ({ name, ...props }) {
  const getOptionValue = (value) => {
    const { id, code } = value
    return { id, name: code }
  }

  return (
    <SearchField
      data-cy={name}
      name={name}
      getOptionValue={getOptionValue}
      api={API.AVAILABLE_CONDITIONS}
      {...props}
    />
  )
}

ConditionCodeField.propTypes = {
  name: PropTypes.string.isRequired,
  warehouseId: PropTypes.number,
  variantId: PropTypes.number
}
export default ConditionCodeField
