import React from 'react'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { CardHeader } from '~/components/Cards'
import ShipmentSearchField from '~/components/Fields/ShipmentSearchField'

import ClientField from './Fields/ClientField'

function ShipoxCard ({ isClient }) {
  const { t } = useTranslation()

  return (
    <Card>
      <CardHeader title={t('Shipox')} />
      <Divider />
      <CardContent>
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={12}>
            <ShipmentSearchField
              name="shipmentIntegration"
              params={{ type: 'shipox' }}
              disabled={isClient}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <ClientField
              label={t('Client')}
              name="shipmentClient"
              integrationName="shipmentIntegration"
              disabled={isClient}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

ShipoxCard.propTypes = {
  isClient: PropTypes.bool.isRequired
}

export default ShipoxCard
