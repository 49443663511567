import { path, pick } from 'ramda'

import { ZONE_CREATE_FIELDS } from '../components/ZoneCreate'

const getWarehouse = path(['warehouse', 'guid'])
const getArea = path(['area', 'guid'])

export function ZoneCreateSerializer (formValues) {
  const warehouseGuid = getWarehouse(formValues)
  const areaGuid = getArea(formValues)

  const data = {
    ...formValues,
    warehouse: warehouseGuid && { guid: warehouseGuid },
    area: areaGuid && { guid: areaGuid },
  }

  return pick(ZONE_CREATE_FIELDS, data)
}
