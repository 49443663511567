import { pick, prop } from 'ramda'
import { DEFAULT_SEARCH_PARAMS } from 'storfox-route-hooks'

import { escapeAtob } from '~/utils'

export function TeamMetricsFilterSerializer (params) {
  const defaultParams = pick(DEFAULT_SEARCH_PARAMS, params)
  const warehouseGuids = escapeAtob(prop('warehouses', params))
  const userGuids = escapeAtob(prop('users', params))
  const device = prop('device', params)
  const objectType = prop('action', params)

  const createdAtStart = prop('createdAtStart', params)
  const createdAtEnd = prop('createdAtEnd', params)
  const startedAtStart = prop('startedAtStart', params)
  const startedAtEnd = prop('startedAtEnd', params)
  const completedAtStart = prop('completedAtStart', params)
  const completedAtEnd = prop('completedAtEnd', params)
  const assignedAtStart = prop('assignedAtStart', params)
  const assignedAtEnd = prop('assignedAtEnd', params)

  const tags = prop('tags', params)

  return {
    ...defaultParams,
    warehouseGuids,
    userGuids,
    device,
    objectType,
    tags,
    startedAtStart,
    startedAtEnd,
    completedAtEnd,
    completedAtStart,
    createdAtStart,
    createdAtEnd,
    assignedAtStart,
    assignedAtEnd
  }
}
