export const generateBarcode = () => {
  const randomLongPrefix = Math.floor(Math.random() * 100000)
  const randomLongPostfix = Math.floor(Math.random() * 100000)
  const randomShortPrefix = Math.floor(Math.random() * 10)
  const randomShortPostfix = Math.floor(Math.random() * 10)
  const randomNumber = Math.floor(Math.random() * 10)

  return [
    randomShortPrefix,
    randomLongPrefix,
    randomNumber,
    randomLongPostfix,
    randomShortPostfix
  ].join('')
}
