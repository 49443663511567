import { sprintf } from 'sprintf-js'
import { useUpdate } from 'storfox-api-hooks'

import * as API from '../constants/api'
import { FilterUpdateSerializer } from '../serializers'

const useFilterUpdate = (id) => {
  const url = sprintf(API.FILTER_UPDATE, id)
  const { update, ...state } = useUpdate(url)

  const onSubmit = formValues => {
    const values = FilterUpdateSerializer(formValues)
    return update(values)
  }

  return { ...state, update: onSubmit }
}

export default useFilterUpdate
