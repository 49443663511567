import React from 'react'
import PropTypes from 'prop-types'
import { path, prop } from 'ramda'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import { useField } from 'react-final-form'
import { useDeepCompareEffect } from 'storfox-api-hooks'
import { useParams } from 'react-router-dom'

import { Button, DiscardButton, SaveButton } from '~/components/Buttons'
import { withForm } from '~/components/Form'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import { useCompany } from '~/components/Profile'
import * as NAV from '~/constants/nav-titles'
import useDialog from '~/hooks/useDialog'
import { THREE_PL } from '~/constants/companyTypes'
import { CONTRACT_UPDATE_TABS } from '~/constants/tabs'

import SwitchAccountDialog from './SwitchAccountDialog'
import ContractUpdateTabs from './ContractUpdateTabs'
import ContractUpdateFormGeneral from './ContractUpdateFormGeneral'
import ContractUpdateInvoiceTable from './ContractUpdateInvoiceTable'

import { ACCEPTED, PENDING, REJECTED } from '../../../constants/Contract'

const ACCEPT = 'accept'
const REJECT = 'reject'

function ContractUpdateForm (props) {
  const {
    pageTitle,
    pageTitleLoading,
    isLoading,
    onDecide,
    form,
    onLoginByContract,
    invoiceList,
    contractGuid,
    onGenerateInvoice,
    onGenerateExcel,
    onMarkAsPaid
  } = props

  const { tab } = useParams()
  const { initialValues, values, handleSubmit } = form
  const { company } = useCompany()
  const switchAccountDialog = useDialog()
  const delegatedAccessField = useField('delegatedAccess')
  const delegatedUserField = useField('delegatedUser')
  const delegatedAccess = delegatedAccessField.input.value
  const delegatedUserOnChange = delegatedUserField.input.onChange
  const companyId = prop('id', company)
  const companyType = prop('type', company)
  const clientId = path(['client', 'id'], initialValues)
  const clientName = path(['client', 'name'], initialValues)
  const companyName = path(['company', 'name'], initialValues)

  const isClient = companyId === clientId

  const isOwner = prop('isOwner', initialValues)
  const invitationStatus = prop('invitationStatus', initialValues)
  const delegatedUserGuid = path(['delegatedUser', 'guid'], initialValues)

  useDeepCompareEffect(() => {
    if (!delegatedAccess) {
      delegatedUserOnChange(null)
    }
  }, [delegatedAccess])

  const renderSave = isOwner || invitationStatus === ACCEPTED
  const renderDecision = !isOwner && invitationStatus === PENDING

  const disabled = (
    invitationStatus === ACCEPTED ||
    invitationStatus === REJECTED ||
    isLoading
  )

  const handleAccept = () =>
    onDecide(values, ACCEPT)

  const handleReject = () =>
    onDecide(values, REJECT)

  return (
    <form onSubmit={handleSubmit}>
      <SwitchAccountDialog
        handleClose={switchAccountDialog.handleClose}
        open={switchAccountDialog.open}
        companyName={companyName}
        clientName={clientName}
        onLoginByContract={onLoginByContract}
      />
      <Container>
        <Header>
          <PageTitle
            pageTitle={pageTitle}
            pageTitleLoading={pageTitleLoading}
            parentTitle={NAV.CONFIGURATION}
            rightButton={(
              <>
                <DiscardButton disabled={isLoading} />
                {!renderDecision && <SaveButton type="submit" disabled={isLoading || !renderSave} />}
                {renderDecision && (
                  <>
                    <Button
                      variant="contained"
                      disabled={disabled}
                      onClick={handleReject}
                    >
                      Reject
                    </Button>
                    <Button
                      variant="contained"
                      disabled={disabled}
                      data-cy="accept"
                      onClick={handleAccept}
                    >
                      Accept
                    </Button>
                  </>
                )}
                {companyType === THREE_PL && (
                  <Button
                    variant="contained"
                    disabled={!delegatedUserGuid}
                    onClick={switchAccountDialog.handleOpen}
                    startIcon={<AccountBoxIcon />}
                  >
                    Switch Account
                  </Button>
                )}
              </>
            )}
          />
        </Header>

        <Content>
          <ContractUpdateTabs />
          {tab === CONTRACT_UPDATE_TABS.GENERAL && (
            <ContractUpdateFormGeneral
              companyType={companyType}
              isClient={isClient}
              delegatedAccess={delegatedAccess}
            />
          )}
          {tab === CONTRACT_UPDATE_TABS.INVOICE && (
            <ContractUpdateInvoiceTable
              invoiceList={invoiceList}
              contractGuid={contractGuid}
              onGenerateInvoice={onGenerateInvoice}
              onGenerateExcel={onGenerateExcel}
              onMarkAsPaid={onMarkAsPaid}
            />
          )}
        </Content>
      </Container>
    </form>
  )
}

ContractUpdateForm.propTypes = {
  form: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onDecide: PropTypes.func.isRequired,
  pageTitle: PropTypes.string,
  pageTitleLoading: PropTypes.bool.isRequired,
  onLoginByContract: PropTypes.func.isRequired,
  invoiceList: PropTypes.object.isRequired,
  contractGuid: PropTypes.string,
  onGenerateInvoice: PropTypes.func,
  onGenerateExcel: PropTypes.func,
  onMarkAsPaid: PropTypes.func
}

export default withForm(ContractUpdateForm)
