import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import { prop } from 'ramda'

import * as API from '~/constants/api'
import SearchField from '~/components/Fields/SearchField'

const getOptionLabel = item => {
  if (item) {
    return prop('companyName', item)
  }

  return ''
}

const getOptionValue = value => {
  if (value) {
    const id = prop('id', value)
    const guid = prop('guid', value)
    const companyName = prop('companyName', value)

    return { id, guid, companyName }
  }

  return null
}

function SupplierSearchField ({ name, ...props }) {
  return (
    <SearchField
      data-cy={name}
      name={name}
      api={API.PRODUCT_SUPPLIER_LIST}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      renderOption={(props, option) => (
        <li {...props} style={{ display: 'block' }}>
          <Typography>
            {option.companyName}
          </Typography>
        </li>
      )}
      ListboxProps={{ 'data-cy': 'supplierList' }}
      {...props}
    />
  )
}

SupplierSearchField.defaultProps = {
  name: 'supplier',
  label: 'Supplier'
}

SupplierSearchField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string
}

export default SupplierSearchField
