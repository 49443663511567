import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import DialogContent from '@mui/material/DialogContent'
import Dialog from '@mui/material/Dialog'
import { pathOr, prop, propOr } from 'ramda'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import Checkbox from '@mui/material/Checkbox/Checkbox'
import DialogActions from '@mui/material/DialogActions/DialogActions'
import InfiniteScroll from 'react-infinite-scroll-component'
import { styled } from '@mui/material'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useField } from 'react-final-form'
import TableHead from '@mui/material/TableHead'
import { useTranslation } from 'react-i18next'

import DialogTitle from '~/components/DialogTitle'
import TextOverflow from '~/components/TextOverflow'
import Avatar from '~/components/Avatar'
import { Button, DiscardButton } from '~/components/Buttons'
import InfinityScrollSearch from '~/components/InfinityScrollSearch'

import VariantTableSkeletonList from './VariantTableSkeletonList'

const TableRowStyled = styled(TableRow)({
  cursor: 'pointer',
  '&:hover': {
    background: '#0000000A'
  }
})

const DialogContentStyled = styled(DialogContent)({
  padding: '0',
  position: 'relative',
  height: 'calc(100vh / 2)'
})

function MovementUnitAddDialog ({ open, onClose, onAddVariants, params, movementUnitList }) {
  const { t } = useTranslation()
  const lineItemsField = useField('units')
  const lineItemsFieldValue = pathOr([], ['input', 'value'], lineItemsField)
  const [selected, setSelected] = useState(lineItemsFieldValue || [])

  const results = propOr([], 'results', movementUnitList)
  const hasNextPage = prop('hasNextPage', movementUnitList)

  const searchField = useField('search')
  const searchFieldValue = searchField.input.value

  const handleCheck = (checked, unit) => {
    if (checked) {
      setSelected(prevSelected => prevSelected.filter(select => prop('id', select) !== prop('id', unit)))
    } else {
      setSelected(prevSelected => [...prevSelected, unit])
    }
  }

  const onEscape = useCallback(event => {
    if (event.code === 'Escape') {
      onClose()
    }
  }, [onClose])

  return (
    <Dialog open={open} maxWidth="lg" fullWidth={true} onKeyDown={onEscape}>
      <DialogTitle>
        <Typography
          component="h2"
          variant="h6"
        >
          {`${t('Browse Units')} - ${t('Selected')} (${selected.length})`}
        </Typography>
        <Box sx={{ padding: '24px 0' }}>
          <InfinityScrollSearch
            onEffect={movementUnitList.reset}
            getList={movementUnitList.getList}
            params={params}
          />
        </Box>
      </DialogTitle>
      <DialogContentStyled id="movementVariantTable">
        <InfiniteScroll
          dataLength={results.length}
          hasMore={hasNextPage}
          loader=""
          next={() => movementUnitList.getList({ search: searchFieldValue, ...params })}
          scrollableTarget="movementVariantTable"
        >
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('Select')}</TableCell>
                  <TableCell>{t('Image')}</TableCell>
                  <TableCell>{t('Unit')}</TableCell>
                  <TableCell>{t('Serial number')}</TableCell>
                  <TableCell>{t('Batch number')}</TableCell>
                  <TableCell>{t('Condition')}</TableCell>
                  <TableCell>{t('Quantity')}</TableCell>
                  <TableCell>{t('Container')}</TableCell>
                  <TableCell>{t('Location')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {results.map((unit, index) => {
                  const variant = prop('variant', unit)
                  const checked = selected.find(select => prop('id', unit) === prop('id', select))
                  const defaultImage = prop('defaultImage', variant)
                  const name = prop('name', variant)
                  const serialNumber = prop('serialNumber', unit)
                  const batchNumber = prop('batchNumber', unit)
                  const condition = pathOr('', ['condition', 'code'], unit)
                  const quantity = propOr(0, 'quantity', unit)
                  const container = pathOr('', ['container', 'number'], unit)
                  const location = pathOr('', ['location', 'locationId'], unit)
                  return (
                    <TableRowStyled onClick={() => handleCheck(checked, unit)}>
                      <TableCell width={70} data-cy={`variant-${index}`}>
                        <Checkbox checked={Boolean(checked)} color="secondary" />
                      </TableCell>
                      <TableCell width={70}>
                        <Avatar
                          alt={name}
                          src={defaultImage}
                          variant="rounded"
                        />
                      </TableCell>
                      <TableCell width={380}>
                        <TextOverflow
                          lines={1}
                          selfTooltip={true}
                        >
                          {name}
                        </TextOverflow>
                      </TableCell>
                      <TableCell>{serialNumber}</TableCell>
                      <TableCell>{batchNumber}</TableCell>
                      <TableCell>{condition}</TableCell>
                      <TableCell>{quantity}</TableCell>
                      <TableCell>{container}</TableCell>
                      <TableCell>{location}</TableCell>
                    </TableRowStyled>
                  )
                })}
                {movementUnitList.isLoading && (
                  <VariantTableSkeletonList />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </InfiniteScroll>
      </DialogContentStyled>
      <DialogActions>
        <DiscardButton
          onClick={onClose}
          fullWidth={true}
        >
          Discard
        </DiscardButton>
        <Button
          variant="contained"
          data-cy="add"
          fullWidth={true}
          onClick={() => onAddVariants(selected)}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}

MovementUnitAddDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAddVariants: PropTypes.func.isRequired,
  params: PropTypes.object,
  movementUnitList: PropTypes.object.isRequired
}

export default MovementUnitAddDialog
