import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'

import DialogTitle from '~/components/DialogTitle'
import { Button, DiscardButton } from '~/components/Buttons'
import { withForm } from '~/components/Form'
import { PACKING } from '~/constants/locationTypes'

import LocationSearchField from './LocationSearchField'

function CompleteDialogForm ({ open, handleClose, warehouseId, form, disabled }) {
  const { handleSubmit } = form

  const params = {
    warehouseId,
    locationType: PACKING
  }

  return (
    <Dialog
      open={open}
      fullWidth={true}
      onClose={handleClose}
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          <Typography
            component="h2"
            variant="h6"
          >
            Complete Picklist
          </Typography>
          <Typography
            component="h6"
            variant="subtitle2"
          >
            Move containers to packing location and complete picklist
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ minHeight: '30vh', paddingTop: '10px !important' }}>
          <LocationSearchField
            name="toLocationId"
            data-cy="toLocationId"
            label="Packing location"
            InputProps={{ autoFocus: true }}
            params={params}
            ListboxProps={{ 'data-cy': 'locationList' }}
          />
        </DialogContent>
        <DialogActions>
          <DiscardButton
            onClick={handleClose}
            fullWidth={true}
          >
            Discard
          </DiscardButton>

          <Button
            variant="contained"
            fullWidth={true}
            disabled={disabled}
            type="submit"
            data-cy="done"
          >
            Done
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

CompleteDialogForm.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  form: PropTypes.object,
  warehouseId: PropTypes.number,
  disabled: PropTypes.bool.isRequired
}

export default withForm(CompleteDialogForm)
