import React, { useMemo } from 'react'
import { propOr } from 'ramda'
import PropTypes from 'prop-types'

import { RETAILER } from '~/constants/companyTypes'

import { useCompany } from '../Profile'

function RouterProvider ({ children, routes }) {
  const { company } = useCompany()
  const companyType = propOr(RETAILER, 'type', company)
  const routesByCompanyType = useMemo(() => routes[companyType], [companyType, routes])

  return (
    <>
      {children(routesByCompanyType)}
    </>
  )
}

RouterProvider.propTypes = {
  children: PropTypes.any.isRequired,
  routes: PropTypes.object.isRequired
}

export default React.memo(RouterProvider)
