import React from 'react'
import PropTypes from 'prop-types'

import * as API from '~/constants/api'
import SearchField from '~/components/Fields/SearchField'

const getOptionValue = value => {
  if (value) {
    const { id, guid, name, label } = value
    return { id, guid, name, label }
  }

  return null
}

function AreaField ({ params, ...props }) {
  return (
    <SearchField
      name="area"
      data-cy="area"
      label="Area"
      getOptionValue={getOptionValue}
      api={API.AREA_LIST}
      params={params}
      {...props}
    />
  )
}

AreaField.propTypes = {
  params: PropTypes.object.isRequired
}

export default AreaField
