import React from 'react'
import Dialog from '@mui/material/Dialog/Dialog'
import DialogContent from '@mui/material/DialogContent/DialogContent'
import DialogActions from '@mui/material/DialogActions/DialogActions'
import { prop } from 'ramda'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import { dialogClasses, styled } from '@mui/material'
import Box from '@mui/material/Box'

import { Button } from '~/components/Buttons'
import Divider from '~/components/Divider'
import Avatar from '~/components/Avatar'
import DiscardButton from '~/components/Buttons/DiscardButton'
import * as API from '~/constants/api'
import ConstrainedFloatField from '~/components/Fields/ConstrainedFloatField'
import TaxField from '~/components/Fields/TaxField'

const DialogNameBoxStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '10px',
  justifyContent: 'space-between',
  '& span:first-of-type': {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '16px'
  },
  '& span:last-child': {
    color: 'rgba(39, 50, 87, 0.7)',
    fontSize: '12px'
  }
})

const DialogStyled = styled(Dialog)({
  [`& .${dialogClasses.paper}`]: {
    maxWidth: '500px'
  },
})

function LineItemDetailEdit ({ open, name, onClose, item }) {
  const variant = prop('productVariant', item)
  const image = prop('defaultImage', variant)
  const variantName = prop('name', variant)
  const sku = prop('sku', variant)

  return (
    <DialogStyled
      open={open}
      onClose={onClose}
      fullWidth={true}
    >
      <Box display="flex" p={3}>
        <Avatar
          alt="dimension-edit"
          src={image}
        />
        <DialogNameBoxStyled>
          <span>{variantName}</span>
          <span>{sku}</span>
        </DialogNameBoxStyled>
      </Box>
      <Divider />
      <DialogContent>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <ConstrainedFloatField
              label="Discount"
              name={`${name}.discount`}
              data-cy={`${name}.discount`}
              InputProps={{
                endAdornment: (
                  <span>%</span>
                )
              }}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <TaxField
              label="Tax"
              name={`${name}.tax`}
              api={API.PURCHASE_ORDER_TAX_LIST}
              ListboxProps={{ 'data-cy': 'taxList' }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <DiscardButton data-cy="variantDiscard" fullWidth={true} onClick={onClose} />
        <Button
          variant="contained"
          type="button"
          onClick={onClose}
          data-cy="variantSave"
          fullWidth={true}
        >
          Save
        </Button>
      </DialogActions>
    </DialogStyled>
  )
}

LineItemDetailEdit.propTypes = {
  open: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired
}

export default LineItemDetailEdit
