import React from 'react'
import { useFilter, useOrdering } from 'storfox-filter'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'

import {
  ReturnFilterForm,
  returnFilterOptions,
  ReturnOrderingForm,
  returnOrderingOptions,
  ReturnTable
} from '../components/ReturnList'
import { useReturnList } from '../hooks'

function ReturnListContainer () {
  const returnList = useReturnList()

  const filter = useFilter(returnFilterOptions)
  const ordering = useOrdering(returnOrderingOptions)

  const breadcrumbs = { title: NAV.SUPPLIER_RETURNS }

  return (
    <DashboardLayout
      title={NAV.SUPPLIER_RETURNS}
      activeNav={NAV.SUPPLIER_RETURNS}
      breadcrumbs={breadcrumbs}
    >
      <ReturnFilterForm {...filter} />
      <ReturnOrderingForm {...ordering} />

      <ReturnTable
        list={returnList}
        filter={filter}
        ordering={ordering}
        onListRefetch={returnList.getListByParams}
      />
    </DashboardLayout>
  )
}

export default ReturnListContainer
