import React, { useCallback } from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import { FieldArray, useFieldArray } from 'react-final-form-arrays'
import { useField } from 'react-final-form'

import { CardHeader } from '~/components/Cards'
import useDidUpdateEffect from '~/hooks/useDidUpdateEffect'
import PositiveNumberField from '~/components/Fields/PositiveNumberField'

import PricingCard from './PricingCard'
import {
  StoragePerVolume,
  StoragePerWeight
} from './CardContent'
import StorageChargeTypeField from './StorageChargeTypeField'

import EmptyTypeCard from '../EmptyTypeCard'
import AddCard from '../AddCard'
import { STORAGE_TYPES } from '../../../constants/BillingProfile'

function BillingProfileStorage () {
  const typeField = useField('storageBilling.type')
  const type = typeField.input.value
  const tiersArray = useFieldArray('storageBilling.tiers')
  const tiersField = useField('storageBilling.tiers')
  const tiers = tiersArray.fields.value
  const renderTiers = tiers && tiers.length

  const handleClick = () => {
    tiersArray.fields.push({})
  }

  const addDisabled = Boolean(renderTiers)

  const typeFieldDirty = typeField.meta.dirty
  const handleTiersChange = useCallback(tiersField.input.onChange, [])

  useDidUpdateEffect(() => {
    if (typeFieldDirty) {
      handleTiersChange([])
    }
  }, [handleTiersChange, type, typeFieldDirty])

  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12} lg={3}>
        <Card>
          <CardHeader title="Settings" />
          <Divider />
          <CardContent>
            <Grid container={true} spacing={3}>
              <Grid item={true} lg={12}>
                <StorageChargeTypeField name="storageBilling.type" />
              </Grid>
              <Grid item={true} xs={12}>
                <PositiveNumberField
                  name="storageBillTax"
                  data-cy="storageBillTax"
                  label="Tax"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item={true} xs={12} lg={9}>
        {type ? (
          <FieldArray
            name="storageBilling.tiers"
            render={({ fields }) => {
              const handleTierAdd = () => {
                fields.push({ ranges: [{}] })
              }

              const handleTierRemove = (index) => {
                fields.remove(index)
              }

              return (
                renderTiers ? (
                  <Grid container={true} spacing={3}>
                    {fields.map((name, index) => {
                      const withButton = index === tiers.length - 1

                      return (
                        <Grid key={name} item={true} xs={12}>
                          <PricingCard
                            index={index}
                            addDisabled={addDisabled}
                            onTierAdd={handleTierAdd}
                            withAddButton={withButton}
                            onTierRemove={() => handleTierRemove(index)}
                          >
                            {type === STORAGE_TYPES.PER_VOLUME && <StoragePerVolume name={name} type={type} />}
                            {type === STORAGE_TYPES.PER_WEIGHT && <StoragePerWeight name={name} type={type} />}
                          </PricingCard>
                        </Grid>
                      )
                    })}
                  </Grid>
                ) : (
                  <AddCard
                    primary="Create new Storage pricing tier"
                    onClick={handleClick}
                  />
                ))
            }}
          />
        )
          : (
            <Card>
              <CardHeader title="Storage" />
              <Divider />
              <CardContent>
                <EmptyTypeCard />
              </CardContent>
            </Card>
          )}
      </Grid>
    </Grid>
  )
}

export default BillingProfileStorage
