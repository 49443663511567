import React, { useCallback } from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import { useField } from 'react-final-form'

import { CardHeader } from '~/components/Cards'
import MoneyField from '~/components/Fields/MoneyField'
import useDidUpdateEffect from '~/hooks/useDidUpdateEffect'
import PositiveNumberField from '~/components/Fields/PositiveNumberField'

import OrderPricing from './OrderPricing'
import UnitPricing from './UnitPricing'
import FulfillmentChargeTypeField from './FulfillmentChargeTypeField'

import { FULFILLMENT_TYPES } from '../../../constants/BillingProfile'
import EmptyTypeCard from '../EmptyTypeCard'

function BillingProfileFulfillment () {
  const fulfillmentField = useField('fulfillmentBilling')
  const typeField = useField('fulfillmentBilling.type')

  const type = typeField.input.value
  const handleFulfillmentChange = useCallback(fulfillmentField.input.onChange, [])

  const typeFieldDirty = typeField.meta.dirty

  useDidUpdateEffect(() => {
    const withRange = (
      type === FULFILLMENT_TYPES.PER_ORDER ||
      type === FULFILLMENT_TYPES.PER_ORDER_UNIT_QTY
    )

    if (type === FULFILLMENT_TYPES.PER_ORDER_UNIT_QTY || type === FULFILLMENT_TYPES.PER_ORDER_LINE_ITEM_COUNT) {
      return handleFulfillmentChange({ type, baseFrom: 1 })
    }

    if (typeFieldDirty && withRange) {
      handleFulfillmentChange({ type, ranges: [{}] })
    }

    if (typeFieldDirty && !withRange) {
      handleFulfillmentChange({ type })
    }
  }, [handleFulfillmentChange, type, typeFieldDirty])

  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12} lg={3}>
        <Card>
          <CardHeader title="Settings" />
          <Divider />
          <CardContent>
            <Grid container={true} spacing={3}>
              <Grid item={true} lg={12}>
                <FulfillmentChargeTypeField name="fulfillmentBilling.type" />
              </Grid>
              <Grid item={true} lg={12}>
                <PositiveNumberField
                  name="fulfillmentBillTax"
                  data-cy="fulfillmentBillTax"
                  label="Tax"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item={true} xs={12} lg={9}>
        <Card>
          <CardHeader title="Fulfillment" />
          <Divider />
          <CardContent>
            {!type && <EmptyTypeCard />}
            {type === FULFILLMENT_TYPES.FIXED && <MoneyField name="fulfillmentBilling.price" />}
            {type === FULFILLMENT_TYPES.PER_ORDER_UNIT_QTY && <UnitPricing type={type} />}
            {(type === FULFILLMENT_TYPES.PER_ORDER_LINE_ITEM_COUNT || type === FULFILLMENT_TYPES.PER_ORDER) && (
              <OrderPricing type={type} />
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default BillingProfileFulfillment
