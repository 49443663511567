import React from 'react'
import { styled } from '@mui/material'
import PropTypes from 'prop-types'

const ImgStyled = styled('img', {
  shouldForwardProp: (propName) => propName !== 'objectFit'
})(({ objectFit }) => ({
  objectFit,
  width: '100%',
  height: '100%'
}))

function Image (props) {
  const {
    src,
    objectFit = 'contain',
    alt
  } = props

  return (
    <ImgStyled
      src={src}
      objectFit={objectFit}
      alt={alt}
      {...props}
    />
  )
}

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  objectFit: PropTypes.string
}

export default Image
