import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { map, prop } from 'ramda'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'

import { capitalize, getFullName, toDuration } from '~/utils'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import { Table, TableHeader } from '~/components/Table'
import * as NAV from '~/constants/nav-titles'
import TableDateFormat from '~/components/TableDateFormat'
import TextLabelLoader from '~/components/TextLabelLoader'
import LoaderProvider from '~/components/Loader'
import CardHeader from '~/components/Cards/CardHeader'

import ActionLink from './ActionLink'
import More from './More'

export const TEAM_METRICS_COLUMNS = [
  {
    width: 150,
    headerName: 'Action',
    field: 'action',
    valueFormatter: ({ value }) => value.objectName,
    renderCell: ({ value }) => (
      <ActionLink
        name={value.objectName}
        guid={value.objectGuid}
        id={value.objectId}
        type={value.objectType}
      />
    )
  },
  {
    width: 200,
    headerName: 'User',
    field: 'user'
  },
  {
    width: 150,
    headerName: 'Device',
    field: 'device'
  },
  {
    width: 200,
    headerName: 'Session duration',
    field: 'sessionDuration'
  },
  {
    width: 200,
    headerName: 'Start date',
    field: 'startDate',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  },
  {
    width: 200,
    headerName: 'Completion date',
    field: 'completionDate',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  },
  {
    width: 200,
    headerName: 'Assignment date',
    field: 'assignmentDate',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  },
  {
    width: 200,
    headerName: 'Line items',
    field: 'lineItemsCount',
    headerAlign: 'center',
    align: 'center'
  },
  {
    width: 200,
    headerName: 'Item duration',
    field: 'perLineItemDuration'
  },
  {
    width: 200,
    headerName: 'Units Qty',
    field: 'totalUnitsQuantity',
    headerAlign: 'center',
    align: 'center'
  }
]

const getRowsFromResults = map(item => {
  const id = prop('id', item)

  const objectId = prop('objectId', item)
  const objectGuid = prop('objectGuid', item)
  const objectType = prop('objectType', item)
  const objectName = prop('objectName', item)

  const action = {
    objectId,
    objectGuid,
    objectType,
    objectName
  }

  const user = getFullName(prop('user', item))
  const sessionDuration = toDuration(prop('duration', item))
  const completionDate = prop('completedAt', item)
  const startDate = prop('startedAt', item)
  const assignmentDate = prop('assignedAt', item)
  const device = capitalize(prop('device', item))
  const lineItemsCount = prop('lineItemsCount', item)
  const perLineItemDuration = prop('perLineItemDuration', item)
  const totalUnitsQuantity = prop('totalUnitsQuantity', item)

  return {
    id,
    user,
    action,
    device,
    startDate,
    sessionDuration,
    completionDate,
    assignmentDate,
    lineItemsCount,
    perLineItemDuration,
    totalUnitsQuantity
  }
})

function TeamMetricsTable ({ list, filter, ordering, onListRefetch, onTeamMetricsExport }) {
  const [tableRef, setTableRef] = useState(null)
  const { t } = useTranslation()
  const isLoading = prop('isLoading', list)
  const results = prop('results', list)
  const summary = prop('summary', list)
  const count = prop('count', list)
  const columns = prop('filteredColumns', ordering)

  const averageSessionDuration = toDuration(prop('averageSessionDuration', summary))
  const totalUserCount = prop('totalUserCount', summary)

  return (
    <LoaderProvider isLoading={isLoading}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={t(NAV.TEAM_METRICS)}
            parentTitle={t(NAV.REPORTS)}
          />
        </Header>

        <Content>
          <Box mb={2}>
            <Card>
              <CardHeader title="Summary" />
              <Divider />
              <CardContent>
                <Grid container={true} spacing={2}>
                  <Grid item={true} xs={12} lg={4}>
                    <TextLabelLoader label="Total user count">
                      {totalUserCount}
                    </TextLabelLoader>
                  </Grid>
                  <Grid item={true} xs={12} lg={4}>
                    <TextLabelLoader label="Avg. session duration">
                      {averageSessionDuration}
                    </TextLabelLoader>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
          <TableHeader
            filter={filter}
            filterOpen={filter.handleOpen}
            orderingOpen={ordering.handleOpen}
            ordering={ordering}
          >
            <More onTeamMetricsExport={onTeamMetricsExport} tableRef={tableRef} />
          </TableHeader>
          <Table
            isLoading={isLoading}
            columns={columns}
            getRowsFromResults={getRowsFromResults}
            results={results}
            count={count}
            ordering={ordering}
            onRefetch={onListRefetch}
            setTableRef={setTableRef}
          />
        </Content>
      </Container>
    </LoaderProvider>
  )
}

TeamMetricsTable.propTypes = {
  list: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  onTeamMetricsExport: PropTypes.func.isRequired,
  onListRefetch: PropTypes.func.isRequired
}

export default TeamMetricsTable
