import React from 'react'
import FormControl from '@mui/material/FormControl'
import RadioGroup from '@mui/material/RadioGroup/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel'
import Radio from '@mui/material/Radio/Radio'
import { useField } from 'react-final-form'

export const SCAN_TYPE_PREFIX = 'prefix'
export const SCAN_TYPE_POSTFIX = 'postfix'
export const SCAN_TYPE_FULL_BARCODE = 'fullBarcode'

function ScanTypeRadioButtons () {
  const scanTypeField = useField('scanType')
  const scanTypeValue = scanTypeField.input.value
  const scanTypeChange = scanTypeField.input.onChange

  const handleChange = (event) => {
    scanTypeChange(event.target.value)
  }

  const controlProps = (item) => ({
    checked: scanTypeValue === item,
    onChange: handleChange,
    value: item,
    name: 'scanType',
    inputProps: { 'aria-label': item },
  })

  return (
    <FormControl>
      <RadioGroup
        row={true}
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="scanType"
      >
        <FormControlLabel
          value={SCAN_TYPE_PREFIX}
          control={<Radio size="small" {...controlProps(SCAN_TYPE_PREFIX)} />}
          label="Prefix"
        />
        <FormControlLabel
          value={SCAN_TYPE_POSTFIX}
          control={<Radio size="small" {...controlProps(SCAN_TYPE_POSTFIX)} />}
          label="Postfix"
        />
        <FormControlLabel
          value={SCAN_TYPE_FULL_BARCODE}
          control={<Radio size="small" {...controlProps(SCAN_TYPE_FULL_BARCODE)} />}
          label="Full Barcode"
        />
      </RadioGroup>
    </FormControl>
  )
}

export default ScanTypeRadioButtons
