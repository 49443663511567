import PropTypes from 'prop-types'
import React from 'react'
import { Dialog, DialogActions, DialogContent } from '@mui/material'

import DialogTitle from '~/components/DialogTitle'
import { withForm } from '~/components/Form'
import { Button, DiscardButton } from '~/components/Buttons'
import UserSearchField from '~/components/Fields/UserField'

function PickerDialogForm ({ open, form, onClose }) {
  const { handleSubmit } = form

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>Change picker</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent dividers={true} sx={{ minHeight: '30vh' }}>
          <UserSearchField
            name="picker"
            label="Picker"
            params={{ perms: 'picking.complete' }}
            data-cy="picker"
            ListboxProps={{ 'data-cy': 'pickerList' }}
          />
        </DialogContent>
        <DialogActions>
          <DiscardButton
            onClick={onClose}
            fullWidth={true}
          >
            Discard
          </DiscardButton>

          <Button
            variant="contained"
            fullWidth={true}
            type="submit"
            data-cy="done"
          >
            Done
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

PickerDialogForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired
}

export default withForm(PickerDialogForm)
