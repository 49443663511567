import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Status, { ERROR, SUCCESS } from '~/components/Status'

export const ACTIVE = 'active'
export const INACTIVE = 'inactive'

export const CONTAINER_STATUS_LABEL = {
  [ACTIVE]: 'Active',
  [INACTIVE]: 'Inactive'
}

export const CONTAINER_STATUS = {
  ACTIVE,
  INACTIVE
}
const STATUS_COLOR = {
  [CONTAINER_STATUS.ACTIVE]: SUCCESS,
  [CONTAINER_STATUS.INACTIVE]: ERROR,
}

function ContainerStatus ({ value }) {
  const { t } = useTranslation()

  return (
    <Status
      label={t(CONTAINER_STATUS_LABEL[value])}
      color={STATUS_COLOR[value]}
    />
  )
}

ContainerStatus.propTypes = {
  value: PropTypes.string.isRequired
}

export default ContainerStatus
