import React from 'react'
import PropTypes from 'prop-types'
import { generatePath, useParams } from 'react-router-dom'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import { prop } from 'ramda'

import { getRouteTab } from '~/utils'
import PageTitle from '~/components/PageTitle'
import { EditButton } from '~/components/Buttons'
import Container, { Content, Header } from '~/components/Container'
import History from '~/components/History'
import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'
import LoaderProvider from '~/components/Loader'
import { SUPPLIER_DETAIL_TABS, SUPPLIER_UPDATE_TABS } from '~/constants/tabs'

import SupplierDetailTabs from './SupplierDetailTabs'
import SupplierGeneral from './SupplierGeneral'
import SupplierAddress from './SupplierAddress'
import SupplierPurchases from './SupplierPurchases'
import SupplierBankAccount from './SupplierBankAccount'

function SupplierDetail (props) {
  const {
    pageTitle,
    pageTitleLoading,
    detail,
    isLoading,
    purchaseList,
    supplierHistory
  } = props

  const { guid, tab } = useParams()

  const editTab = getRouteTab(tab, SUPPLIER_UPDATE_TABS, SUPPLIER_UPDATE_TABS.GENERAL)
  const editPath = generatePath(ROUTES.SUPPLIER_UPDATE_PATH, { guid, tab: editTab })

  const deliveryAddresses = prop('deliveryAddresses', detail)
  const billingAddresses = prop('billingAddresses', detail)
  const bankAccounts = prop('bankAccounts', detail)

  return (
    <LoaderProvider isLoading={isLoading}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={pageTitle}
            pageTitleLoading={pageTitleLoading}
            parentTitle={NAV.SUPPLIERS}
            rightButton={
              <>
                <EditButton path={editPath} disabled={isLoading} />
              </>
            }
          />
        </Header>

        <Content>
          <SupplierDetailTabs value={tab} />

          <Divider />
          <Box mt={3} mb={3}>

            {tab === SUPPLIER_DETAIL_TABS.GENERAL && <SupplierGeneral detail={detail} />}
            {tab === SUPPLIER_DETAIL_TABS.ADDRESS && (
              <SupplierAddress
                deliveryAddresses={deliveryAddresses}
                billingAddresses={billingAddresses}
              />
            )}
            {tab === SUPPLIER_DETAIL_TABS.BANK_ACCOUNT && <SupplierBankAccount bankAccounts={bankAccounts} />}
            {tab === SUPPLIER_DETAIL_TABS.PURCHASES && <SupplierPurchases purchaseList={purchaseList} />}
            {tab === SUPPLIER_DETAIL_TABS.HISTORY && <History history={supplierHistory} />}
          </Box>
        </Content>
      </Container>
    </LoaderProvider>
  )
}

SupplierDetail.propTypes = {
  pageTitleLoading: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  detail: PropTypes.object.isRequired,
  pageTitle: PropTypes.string,
  purchaseList: PropTypes.object,
  supplierHistory: PropTypes.object
}

export default SupplierDetail
