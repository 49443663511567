import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { generatePath, useNavigate } from 'react-router-dom'

import Logo from '~/components/Logo'
import * as ROUTES from '~/constants/routes'
import { useCompany } from '~/components/Profile'
import { OVERVIEW_TABS } from '~/constants/tabs'
import CenteredCard from '~/components/CenteredCard'

import ProductImportStep from './ProductImportStep'
import WarehouseImportStep from './WarehouseImportStep'
import AdjustmentImportStep from './AdjustmentImportStep'
import UserStep from './UserStep'
import AutomationRuleStep from './AutomationRuleStep'
import IntegrationStep from './IntegrationStep'

import { CardContentStyled } from '../styled/Auth.styled'

function IntroductionGuide () {
  const { isThreePl } = useCompany()
  const [step, setStep] = useState(0)
  const navigate = useNavigate()

  const handleSkip = () => {
    setStep(prevStep => prevStep + 1)
  }

  const handleLastSkip = () => {
    const tab = isThreePl ? OVERVIEW_TABS.FULFILLMENT : OVERVIEW_TABS.SALES
    const path = generatePath(ROUTES.OVERVIEW_PATH, { tab })
    navigate(path, { replace: true })
  }

  const steps = [
    <IntegrationStep onSkip={handleSkip} />,
    <ProductImportStep onSkip={handleSkip} />,
    <WarehouseImportStep onSkip={handleSkip} />,
    <AdjustmentImportStep onSkip={handleSkip} />,
    <UserStep onSkip={handleSkip} />,
    <AutomationRuleStep onSkip={handleLastSkip} />,
  ]

  return (
    <Box>
      <Typography
        variant="h4"
        gutterBottom={true}
        sx={{
          width: theme => theme.breakpoints.values.sm,
          margin: 'auto',
          marginBottom: '16px'
        }}
      >
        Introduction guide
      </Typography>
      <CenteredCard>
        <CardContentStyled>
          <Box>
            <Box sx={{ mb: 3, textAlign: 'center' }}>
              <Logo />
            </Box>

            <Box sx={{
              width: '100%',
              position: 'relative',
              paddingTop: theme => theme.spacing(1)
            }}>
              {steps[step]}
            </Box>
          </Box>
        </CardContentStyled>
      </CenteredCard>
    </Box>
  )
}

export default IntroductionGuide
