import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { map, path, prop } from 'ramda'
import { generatePath, useNavigate } from 'react-router-dom'
import Link from '@mui/material/Link'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'

import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import { Table, TableHeader } from '~/components/Table'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import { generateCdnUrl } from '~/utils'
import TableDateFormat from '~/components/TableDateFormat/TableDateFormat'
import TableLink from '~/components/Link'
import TextOverflow from '~/components/TextOverflow'
import { LOT_SHEETS_DETAIL_TABS } from '~/constants/tabs'

import More from './More'

export const BUCKET_COLUMNS = [
  {
    width: 200,
    headerName: 'Lot Number',
    field: 'number',
    renderCell: ({ value, row }) => {
      return (
        <TableLink to={row.link}>
          <TextOverflow lines={1} selfTooltip={true}>
            {value}
          </TextOverflow>
        </TableLink>
      )
    }
  },
  {
    width: 100,
    headerName: 'Uploaded File',
    field: 'fileUrl',
    renderCell: ({ value }) => (
      <Link
        component="a"
        href={generateCdnUrl(value)}
        variant="subtitle2"
        underline="always"
      >
        Download
      </Link>
    )
  },
  {
    width: 200,
    headerName: 'Client',
    field: 'company'
  },
  {
    width: 100,
    headerName: 'Items',
    field: 'totalItems'
  },
  {
    width: 100,
    headerName: 'Quantity',
    field: 'totalQuantity'
  },
  {
    width: 200,
    headerName: 'Status',
    field: 'status'
  },
  {
    width: 200,
    headerName: 'Allocation Mode',
    field: 'allocationMode'
  },
  {
    width: 200,
    headerName: 'Created By',
    field: 'userName'
  },
  {
    width: 200,
    headerName: 'Created On',
    field: 'createdAt',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  }
]

const getRowsFromResults = map(item => {
  const id = prop('id', item)
  const guid = prop('guid', item)
  const name = prop('name', item)
  const number = prop('number', item)
  const totalItems = prop('totalItems', item)
  const totalQuantity = prop('totalQuantity', item)
  const allocationMode = prop('allocationMode', item)
  const email = prop('email', item)
  const fileUrl = prop('fileUrl', item)
  const status = prop('status', item)
  const createdAt = prop('createdAt', item)
  const user = prop('user', item)
  const company = path(['company', 'name'], item)
  const userName = `${prop('firstName', user)} ${prop('lastName', user)}`
  const link = generatePath(ROUTES.LOT_SHEETS_DETAIL_PATH, { guid, tab: LOT_SHEETS_DETAIL_TABS.GENERAL })

  return {
    id,
    guid,
    name,
    company,
    link,
    number,
    fileUrl,
    totalItems,
    totalQuantity,
    status,
    allocationMode,
    email,
    createdAt,
    userName
  }
})

function LotSheetsTable ({ list, filter, ordering, onListRefetch }) {
  const [tableRef, setTableRef] = useState(null)
  const navigate = useNavigate()

  const isLoading = prop('isLoading', list)
  const results = prop('results', list)
  const count = prop('count', list)
  const columns = prop('filteredColumns', ordering)
  const hasNextPage = prop('hasNextPage', list)
  const hasPrevPage = prop('hasPrevPage', list)

  const actionColumns = useMemo(() => [
    ...columns,
    {
      width: 80,
      resizable: false,
      headerName: 'Actions',
      field: 'actions',
      type: 'actions',
      getActions: ({ row }) => {
        const guid = prop('guid', row)
        const status = prop('status', row)
        const redirectTo = generatePath(ROUTES.LOT_SHEETS_SHIPMENT_PATH, { guid })
        const isCompleted = status === 'completed'
        return [
          <GridActionsCellItem
            label="Create shipment"
            onClick={() => navigate(redirectTo)}
            disabled={!isCompleted}
            showInMenu={true}
          />
        ]
      }
    }
  ], [navigate, columns])

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={NAV.LOT_SHEETS}
          parentTitle={NAV.CROSS_DOCKS}
        />
      </Header>

      <Content>
        <TableHeader
          filter={filter}
          filterOpen={filter.handleOpen}
          orderingOpen={ordering.handleOpen}
          ordering={ordering}
        >
          <More tableRef={tableRef} />
        </TableHeader>
        <Table
          isLoading={isLoading}
          columns={actionColumns}
          results={results}
          getRowsFromResults={getRowsFromResults}
          count={count}
          checkboxSelection={true}
          primaryKey="guid"
          ordering={ordering}
          onRefetch={onListRefetch}
          setTableRef={setTableRef}
          isNewPagination={true}
          hasNextPage={hasNextPage}
          hasPrevPage={hasPrevPage}
        />
      </Content>
    </Container>
  )
}

LotSheetsTable.propTypes = {
  list: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  onListRefetch: PropTypes.func.isRequired,
}

export default LotSheetsTable
