import React, { useMemo } from 'react'
import { useSnackbar } from 'storfox-snackbar'
import { useNavigate, useParams } from 'react-router-dom'
import { prop, propOr } from 'ramda'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import useMessages from '~/hooks/useMessages'
import Title from '~/components/Title'

import {
  useMovementDetail, useMovementLineItems, useMovementUnitList,
  useMovementUpdate,
  useMovementUpdateAndComplete
} from '../hooks'
import { MovementCreateSerializer, MovementInitialValuesSerializer } from '../serializers'
import MovementBrowseUpdateForm from '../components/MovementBrowseUpdate/MovementBrowseUpdateForm'
import { SAVE_COMPLETE } from '../constants'

function MovementBrowseUpdateContainer () {
  const { id } = useParams()
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const messages = useMessages()

  const movementUnitList = useMovementUnitList()
  const movementDetail = useMovementDetail(id)
  const movementUpdate = useMovementUpdate(id)
  const movementUpdateAndComplete = useMovementUpdateAndComplete(id)
  const movementLineItems = useMovementLineItems(id)

  const title = prop('number', movementDetail.detail)
  const status = prop('status', movementDetail.detail)

  const pageTitle = <Title title={title} status={status} />

  const handleSubmit = formValues => {
    const submitType = propOr(SAVE_COMPLETE, 'submitType', formValues)
    if (submitType === SAVE_COMPLETE) {
      return movementUpdateAndComplete.update(MovementCreateSerializer(formValues, 'browse'))
        .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
        .then(() => navigate(ROUTES.MOVEMENT_LIST_PATH))
    } else {
      return movementUpdate.update(MovementCreateSerializer(formValues, 'browse'))
        .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
        .then(() => navigate(ROUTES.MOVEMENT_LIST_PATH))
    }
  }

  const isLoading = (
    movementDetail.isLoading ||
    movementUpdate.isLoading ||
    movementUpdateAndComplete.isLoading
  )

  const initialValues = useMemo(() => {
    return MovementInitialValuesSerializer(movementDetail.detail, movementLineItems.results)
  }, [movementDetail.detail, movementLineItems.results])

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title="New movement"
      activeNav={NAV.MOVEMENT}
      breadcrumbs={breadcrumbs}
    >
      <MovementBrowseUpdateForm
        pageTitle={pageTitle}
        onSubmit={handleSubmit}
        isLoading={isLoading}
        pageTitleLoading={movementDetail.isLoading}
        initialValues={initialValues}
        movementUnitList={movementUnitList}
      />
    </DashboardLayout>
  )
}

export default MovementBrowseUpdateContainer
