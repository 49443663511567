export const DRAFT = 'draft'
export const IN_PROGRESS = 'progress'
export const COMPLETED = 'completed'
export const CANCELLED = 'cancelled'

export const SAVE_DRAFT = 'save-draft'
export const SAVE_PROCESS = 'save-process'

export const STATUSES = {
  [DRAFT]: 'Draft',
  [IN_PROGRESS]: 'In Progress',
  [COMPLETED]: 'Completed',
  [CANCELLED]: 'Cancelled'
}

export const FILTER_NAME = 'transfer'
export const TRANSFER_COLUMNS = [
  {
    width: 100,
    label: '#',
    key: 'number'
  },
  {
    width: 100,
    label: 'Line items',
    key: 'lineItems'
  },
  {
    width: 100,
    label: 'Qty',
    key: 'quantity'
  },
  {
    width: 150,
    label: 'Value',
    key: 'value'
  },
  {
    width: 200,
    label: 'From warehouse',
    key: 'fromWarehouse'
  },
  {
    width: 200,
    label: 'To warehouse',
    key: 'toWarehouse'
  },
  {
    width: 150,
    label: 'Status',
    key: 'status'
  },
  {
    width: 150,
    label: 'Expected date',
    key: 'expectedDate'
  },
  {
    width: 150,
    label: 'Created date',
    key: 'created'
  },
  {
    width: 150,
    label: 'Updated date',
    key: 'updatedAt'
  },
  {
    width: 125,
    label: 'Notes',
    key: 'notes'
  }
]
