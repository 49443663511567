import React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { pathOr } from 'ramda'
import { useSnackbar } from 'storfox-snackbar'
import { useTranslation } from 'react-i18next'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import { SUPPLIER_DETAIL_TABS } from '~/constants/tabs'

import { SupplierUpdateForm } from '../components/SupplierUpdate'
import { useSupplierDetail, useSupplierUpdate } from '../hooks'
import { SupplierErrorSerializer, SupplierInitialSerializer, SupplierUpdateSerializer } from '../serializers'

function SupplierUpdateContainer () {
  const { t } = useTranslation()
  const { guid } = useParams()
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const supplierDetail = useSupplierDetail(guid)
  const supplierUpdate = useSupplierUpdate(guid)

  const message = t('Successfully updated')

  const handleSubmit = formValues =>
    supplierUpdate.update(SupplierUpdateSerializer(formValues))
      .then(() => snackbar({ message }))
      .then(() => {
        const tab = SUPPLIER_DETAIL_TABS.GENERAL
        const path = generatePath(ROUTES.SUPPLIER_DETAIL_PATH, { guid, tab })
        navigate(path)
      })
      .catch(error => {
        const tab = SupplierErrorSerializer(error)
        const path = generatePath(ROUTES.SUPPLIER_UPDATE_PATH, { guid, tab })
        navigate(path, { replace: true })

        return Promise.reject(error)
      })

  const pageLoading = supplierDetail.isLoading || supplierUpdate.isLoading

  const companyName = pathOr('', ['detail', 'companyName'], supplierDetail)
  const title = supplierDetail.isLoading ? 'Suppliers' : companyName

  const breadcrumbs = {
    title: 'Edit',
    params: [
      { guid, tab: SUPPLIER_DETAIL_TABS.GENERAL, title }
    ]
  }

  return (
    <DashboardLayout
      title={title}
      isLoading={pageLoading}
      activeNav={NAV.SUPPLIERS}
      breadcrumbs={breadcrumbs}
    >
      <SupplierUpdateForm
        pageTitle={title}
        pageTitleLoading={supplierDetail.isLoading}
        initialValues={SupplierInitialSerializer(supplierDetail.detail)}
        onSubmit={handleSubmit}
        isLoading={supplierUpdate.isLoading}
      />
    </DashboardLayout>
  )
}

export default SupplierUpdateContainer
