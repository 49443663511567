import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import TransferCreateContainer from './containers/TransferCreateContainer'
import TransferListContainer from './containers/TransferListContainer'
import TransferUpdateContainer from './containers/TransferUpdateContainer'
import TransferDetailContainer from './containers/TransferDetailContainer'

export default () => [
  {
    component: TransferListContainer,
    path: ROUTES.TRANSFER_LIST_PATH,
    layout: AuthLayout
  },
  {
    component: TransferCreateContainer,
    path: ROUTES.TRANSFER_CREATE_PATH,
    layout: AuthLayout
  },
  {
    component: TransferDetailContainer,
    path: ROUTES.TRANSFER_DETAIL_PATH,
    layout: AuthLayout
  },
  {
    component: TransferUpdateContainer,
    path: ROUTES.TRANSFER_UPDATE_PATH,
    layout: AuthLayout
  }
]
