import React from 'react'
import { useParams } from 'react-router-dom'
import { prop } from 'ramda'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import Title from '~/components/Title'
import { getNonFieldError } from '~/components/Form'
import * as NAV from '~/constants/nav-titles'
import useMessages from '~/hooks/useMessages'
import { ADJUSTMENT_DETAIL_TABS } from '~/constants/tabs'

import {
  useAdjustmentCancel,
  useAdjustmentComplete,
  useAdjustmentDetail,
  useAdjustmentReport,
  useAdjustmentUpdate,
  useBarcodeGenerate,
  useAdjustmentHistory,
  useAdjustmentLineItems
} from '../hooks'
import AdjustmentDetail from '../components/AdjustmentDetail/AdjustmentDetail'
import { AdjustmentInitSerializer } from '../serializers'

function OrderCreateContainer () {
  const { id, tab } = useParams()
  const snackbar = useSnackbar()
  const messages = useMessages()
  const adjustmentDetail = useAdjustmentDetail(id)
  const adjustmentUpdate = useAdjustmentUpdate(id)
  const adjustmentCancel = useAdjustmentCancel(id)
  const adjustmentComplete = useAdjustmentComplete(id)
  const barcodeGenerate = useBarcodeGenerate()
  const adjustmentReport = useAdjustmentReport(id)
  const adjustmentLineItems = useAdjustmentLineItems(id)

  const guid = prop('guid', adjustmentDetail.detail)
  const historyAutoSend = Boolean(guid && tab === ADJUSTMENT_DETAIL_TABS.HISTORY)

  const adjustmentHistory = useAdjustmentHistory(guid, historyAutoSend)

  const isLoading = (
    adjustmentDetail.isLoading ||
    adjustmentCancel.isLoading ||
    adjustmentComplete.isLoading
  )

  const handleGenerateReport = () =>
    adjustmentReport.generate()
      .then(() => snackbar({ message: messages.GENERATE_SUCCESS }))

  const handleCancel = () =>
    adjustmentCancel.cancel()
      .then(() => snackbar({ message: messages.CANCEL_SUCCESS }))
      .then(() => adjustmentDetail.getDetail())
      .catch(err => {
        const errorMessage = getNonFieldError(err)
        snackbar({ message: errorMessage, type: ALTER_ERROR })
        throw err
      })

  const handleComplete = () =>
    adjustmentComplete.complete({ id: prop('id', adjustmentDetail.detail) })
      .then(() => snackbar({ message: messages.NOTIFICATION_WAIT }))
      .then(() => adjustmentDetail.getDetail())
      .catch(err => {
        const errorMessage = getNonFieldError(err)
        snackbar({ message: errorMessage, type: ALTER_ERROR })
        throw err
      })

  const handleGenerate = count => barcodeGenerate.generate({ count })

  const number = prop('number', adjustmentDetail.detail)
  const status = prop('status', adjustmentDetail.detail)
  const title = adjustmentDetail.isLoading ? 'Stock Adjustment' : `Stock Adjustment ${number}`

  const pageTitle = (
    <Title
      title="Stock Adjustment"
      number={number}
      status={status}
    />
  )

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      isLoading={isLoading || adjustmentUpdate.isLoading}
      activeNav={NAV.ADJUSTMENT}
      breadcrumbs={breadcrumbs}
    >
      <AdjustmentDetail
        pageTitle={pageTitle}
        pageTitleLoading={adjustmentDetail.isLoading}
        onCancel={handleCancel}
        onGenerate={handleGenerate}
        onComplete={handleComplete}
        initialValues={AdjustmentInitSerializer(adjustmentDetail.detail)}
        isLoading={adjustmentUpdate.isLoading || adjustmentComplete.isLoading}
        onPrint={handleGenerateReport}
        history={adjustmentHistory}
        adjustmentLineItems={adjustmentLineItems}
      />
    </DashboardLayout>
  )
}

export default OrderCreateContainer
