import React from 'react'
import { SearchField as StorfoxSearchField } from 'storfox-form-fields'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

function SearchField ({ label, ...props }) {
  const { t } = useTranslation()

  return (
    <StorfoxSearchField
      label={t(label)}
      {...props}
    />
  )
}

SearchField.propTypes = {
  api: PropTypes.string.isRequired,
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.string,
  primaryKey: PropTypes.string,
  params: PropTypes.object,
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  renderOption: PropTypes.func,
  isLoading: PropTypes.bool,
  getOptionValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
  disableClearable: PropTypes.bool,
  InputProps: PropTypes.object,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  PaperComponent: PropTypes.func,
  ListboxComponent: PropTypes.func,
  ListboxProps: PropTypes.object,
  onValueChange: PropTypes.func,
  prepareOptions: PropTypes.func,
  disableCloseOnSelect: PropTypes.bool,
  searchFieldProps: PropTypes.shape({
    PopperComponent: PropTypes.func
  })
}

export default SearchField
