import React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { pathOr, prop } from 'ramda'
import { useSnackbar } from 'storfox-snackbar'

import * as NAV from '~/constants/nav-titles'
import DashboardLayout from '~/components/Layouts/DashboardLayout'
import * as ROUTES from '~/constants/routes'
import { LISTING_TABS } from '~/constants/tabs'
import useMessages from '~/hooks/useMessages'

import { ListingUpdateForm } from '../components/ListingUpdate'
import { useListingDetail, useListingUpdate } from '../hooks'
import { ListingInitSerializer, ListingUpdateSerializer } from '../serializers'

function ListingUpdateContainer () {
  const navigate = useNavigate()
  const { guid } = useParams()
  const snackbar = useSnackbar()
  const listingDetail = useListingDetail(guid)
  const listingGuid = prop('guid', listingDetail.detail)
  const listingUpdate = useListingUpdate(listingGuid)
  const messages = useMessages()
  const handleSubmit = formValues =>
    listingUpdate.update(ListingUpdateSerializer(formValues))
      .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
      .then(() => {
        const url = generatePath(ROUTES.LISTING_DETAIL_PATH, { guid, tab: LISTING_TABS.VARIANT })
        return navigate(url)
      })

  const title = pathOr('Listing', ['detail', 'name'], listingDetail)
  const pageLoading = listingDetail.isLoading || listingUpdate.isLoading

  const breadcrumbs = {
    title,
    params : [
      { guid, tab: LISTING_TABS.VARIANT, title:'Listing Detail' }
    ]
  }

  return (
    <DashboardLayout
      title={title}
      isLoading={pageLoading}
      activeNav={NAV.LISTING}
      breadcrumbs={breadcrumbs}
    >
      <ListingUpdateForm
        title={title}
        pageTitleLoading={listingDetail.isLoading}
        initialValues={ListingInitSerializer(guid, listingDetail.detail)}
        detail={listingDetail.detail}
        onSubmit={handleSubmit}
        isLoading={listingUpdate.isLoading}
      />
    </DashboardLayout>
  )
}

export default ListingUpdateContainer
