import React from 'react'
import PropTypes from 'prop-types'
import { is } from 'ramda'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

function Title ({ title, number }) {
  const { t } = useTranslation()
  const translatedTitle = is(String, title) ? t(title) : title

  return (
    <Box display="flex" alignItems="center">
      <div>
        {translatedTitle} {number}
      </div>
    </Box>
  )
}

Title.propTypes = {
  title: PropTypes.string,
  number: PropTypes.string
}

export default Title
