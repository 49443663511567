import { colors } from '@mui/material'

import palette from '~/theme/dark/palette'

export default {
  styleOverrides: {
    root: {
      '&.MuiChip-colorDefault': {
        backgroundColor: palette.text.primary,
        color: colors.blueGrey[900]
      } },
    deletable: {
      '&:focus': {
        backgroundColor: colors.blueGrey[100]
      }
    }
  }
}
