import React from 'react'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import { FloatField } from 'storfox-form-fields'

import CountryField from '~/components/Fields/CountryField'
import PhoneNumberField from '~/components/Fields/PhoneNumberField'
import TextField from '~/components/Fields/TextField'
import { CardHeader } from '~/components/Cards'
import EmailField from '~/components/Fields/EmailField'

function ShipmentAddress () {
  return (
    <Card>
      <CardHeader title="Shipping address" />
      <Divider />
      <CardContent>
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={12} lg={6}>
            <TextField
              data-cy="shippingAddress.firstName"
              name="shippingAddress.firstName"
              label="First name" />
          </Grid>

          <Grid item={true} xs={12} lg={6}>
            <TextField
              data-cy="shippingAddress.lastName"
              name="shippingAddress.lastName"
              label="Last name"
            />
          </Grid>

          <Grid item={true} xs={12}>
            <EmailField
              data-cy="shippingAddress.email"
              name="shippingAddress.email"
              label="Email"
            />
          </Grid>

          <Grid item={true} xs={12}>
            <PhoneNumberField
              data-cy="shippingAddress.mobile"
              name="shippingAddress.mobile"
              label="Phone"
            />
          </Grid>

          <Grid item={true} xs={12}>
            <TextField
              data-cy="shippingAddress.address"
              name="shippingAddress.address"
              label="Address"
              multiline={true}
              minRows={3}
            />
          </Grid>

          <Grid item={true} xs={12}>
            <TextField
              data-cy="shippingAddress.region"
              name="shippingAddress.region"
              label="Region/Neighbouring/State"
            />
          </Grid>

          <Grid item={true} xs={12} lg={6}>
            <TextField
              data-cy="shippingAddress.city"
              name="shippingAddress.city"
              label="City"
            />
          </Grid>

          <Grid item={true} xs={12} lg={6}>
            <TextField
              data-cy="shippingAddress.zipcode"
              name="shippingAddress.zipcode"
              label="Zipcode"
            />
          </Grid>

          <Grid item={true} xs={12}>
            <CountryField
              data-cy="shippingAddress.country"
              name="shippingAddress.country"
              label="Country"
            />
          </Grid>

          <Grid item={true} xs={6}>
            <FloatField
              data-cy={`shippingAddress.longitude`}
              name={`shippingAddress.longitude`}
              label="Longitude"
              inputProps={{ decimalScale: 30 }}
            />
          </Grid>

          <Grid item={true} xs={6}>
            <FloatField
              data-cy={`shippingAddress.latitude`}
              name={`shippingAddress.latitude`}
              label="Latitude"
              inputProps={{ decimalScale: 30 }}
            />
          </Grid>

        </Grid>
      </CardContent>
    </Card>
  )
}

export default ShipmentAddress
