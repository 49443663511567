import Cookies from 'js-cookie'

export const set = (key, value) => Cookies.set(key, value)

export const get = key => Cookies.get(key)

export const remove = key => Cookies.remove(key)

export const removeAll = () => {
  const cookies = Cookies.get()
  const keys = Object.keys(cookies)

  keys.forEach(remove)
}
