import { filter, length, map, path, pick, pipe, prop, propOr } from 'ramda'
import { DEFAULT_SEARCH_PARAMS } from 'storfox-route-hooks'

import { escapeAtob } from '~/utils'

export const ContainerCreateSerializer = (locationGuid, guid) => {
  return { containerType: { guid }, location: { guid: locationGuid } }
}

const getContainerUnits = pipe(
  propOr([], 'containers'),
  map(container => {
    const containerNumber = prop('number', container)
    const lineItems = propOr([], 'lineItems', container)

    const units = map(item => {
      const guid = path(['unit', 'guid'], item)
      const quantity = prop('quantity', item)

      return { guid, quantity }
    }, lineItems)

    return { containerNumber, units }
  }),
  filter(container => length(prop('units', container)))
)

export const PackingSaveSerializer = formValues => {
  const id = prop('id', formValues)
  const containerUnits = getContainerUnits(formValues)
  const note = prop('notes', formValues)

  return { id, containerUnits, note }
}

export const PackingCompleteSerializer = values => {
  const location = prop('location', values)
  const packing = prop('packing', values)

  return { location, packing }
}

export const PackingJobDetailCreateSerializer = (id, values) => {
  const packingId = parseInt(id)
  const packerId = path(['packer', 'id'], values)
  const packer = packerId ? { id: packerId } : null

  return { packer, packingJob: { id: packingId } }
}

export const PackingJobListCreateSerializer = (id, values) => {
  const packerId = prop('id', values)
  const packer = packerId ? { id: packerId } : null

  return { packer, packingJob: { id } }
}

export const PackingFilterSerializer = params => {
  const defaultParams = pick(DEFAULT_SEARCH_PARAMS, params)
  const status = prop('status', params)
  const objectIds = escapeAtob(prop('objects', params))
  const warehouseIds = escapeAtob(prop('warehouses', params))
  const createdAtStart = prop('createdAtStart', params)
  const createdAtEnd = prop('createdAtEnd', params)
  const updatedAtStart = prop('updatedAtStart', params)
  const updatedAtEnd = prop('updatedAtEnd', params)

  return {
    ...defaultParams,
    status,
    objectIds,
    warehouseIds,
    createdAtStart,
    createdAtEnd,
    updatedAtStart,
    updatedAtEnd
  }
}
