import React from 'react'
import PropTypes from 'prop-types'
import { prop } from 'ramda'
import Box from '@mui/material/Box'

import useDialog from '~/hooks/useDialog'
import useStorfoxHistory from '~/components/History/hooks'
import HistoryChangesDialog from '~/components/History/HistoryChangesDialog'

import SaleOrderHistoryTable from './SaleOrderHistoryTable'

function SaleOrderHistory ({ history }) {
  const rows = prop('results', history)
  const isLoading = prop('isLoading', history)
  const count = prop('count', history)

  const { open, handleClose, handleOpen } = useDialog()
  const { changeList, changesHandler } = useStorfoxHistory()

  const handleChangesOpen = values => {
    changesHandler(values)
    handleOpen()
  }

  return (
    <Box width="100%">
      <SaleOrderHistoryTable
        rows={rows}
        count={count}
        isLoading={isLoading}
        onClick={handleChangesOpen}
      />
      <HistoryChangesDialog
        open={open}
        oldChange={changeList.oldChanges}
        newChange={changeList.newChanges}
        onClose={handleClose}
      />
    </Box>
  )
}

SaleOrderHistory.propTypes = {
  history: PropTypes.object
}

export default SaleOrderHistory
