import { styled } from '@mui/material'
import Box from '@mui/material/Box'

export const ContentStyled = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 3),
  height: '100%',
  overflow: 'scroll',
  flexGrow: 1
}))

export const FormStyled = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'space-between'
})

export const ActionsStyled = styled(Box)(({ theme }) => ({
  background: theme.palette.background.paper,
  zIndex: 1,
  display: 'flex',
  padding: 24,
  bottom: 0,
  width: '100%',
  '& > button': {
    marginRight: theme.spacing(2)
  },
  '& > button:last-child': {
    marginRight: 0
  }
}))
