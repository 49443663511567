import React from 'react'

import SelectField from '~/components/Fields/SelectField'

const DAT = 'dat'
const DDU = 'ddu'
const DDP = 'ddp'
const DAP = 'dap'

const OPTIONS = [
  {
    value: DAT,
    name: 'dat'
  },
  {
    value: DDU,
    name: 'ddu'
  },
  {
    value: DDP,
    name: 'ddp'
  },
  {
    value: DAP,
    name: 'dap'
  }
]
function TermsOfTradeField (props) {
  return (
    <SelectField
      data-cy="termsOfTrade"
      label="Terms of trade"
      options={OPTIONS}
      {...props}
    />
  )
}

export default TermsOfTradeField
