import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import ShipRocketIntegrationUpdateContainer from './ShipRocketIntegrationUpdateContainer'
import ShipRocketIntegrationCreateContainer from './ShipRocketIntegrationCreateContainer'

export default () => {
  return [
    {
      layout: AuthLayout,
      path: ROUTES.SHIPROCKET_INTEGRATION_CREATE_PATH,
      component: ShipRocketIntegrationCreateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.SHIPROCKET_INTEGRATION_UPDATE_PATH,
      component: ShipRocketIntegrationUpdateContainer
    }
  ]
}
