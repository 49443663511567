import React from 'react'
import { Filter } from 'storfox-filter'
import Grid from '@mui/material/Grid'
import { prop } from 'ramda'
import { PositiveFloatField } from 'storfox-form-fields'

import { escapeAtob, unescapeBtoa } from '~/utils'
import Accordion from '~/components/Accordion'
import * as API from '~/constants/api'
import MultiSelectSearchField from '~/components/Fields/MultiSelectSearchField'

import CompanySearchField from './VariantCompanyList'

import { FILTER_NAME as name } from '../../constants'

export const toParams = values => {
  const categories = unescapeBtoa(prop('categories', values))
  const brands = unescapeBtoa(prop('brands', values))
  const company = unescapeBtoa(prop('company', values))
  const supplierPriceStart = prop('supplierPriceStart', values)
  const supplierPriceEnd = prop('supplierPriceEnd', values)
  const retailPriceStart = prop('retailPriceStart', values)
  const retailPriceEnd = prop('retailPriceEnd', values)
  const maximumRetailPriceStart = prop('maximumRetailPriceStart', values)
  const maximumRetailPriceEnd = prop('maximumRetailPriceEnd', values)
  const availableStart = prop('availableStart', values)
  const availableEnd = prop('availableEnd', values)
  const onHandStart = prop('onHandStart', values)
  const onHandEnd = prop('onHandEnd', values)
  const allocatedStart = prop('allocatedStart', values)
  const allocatedEnd = prop('allocatedEnd', values)
  const awaitingStart = prop('awaitingStart', values)
  const awaitingEnd = prop('awaitingEnd', values)
  const inTransferStart = prop('inTransferStart', values)
  const inTransferEnd = prop('inTransferEnd', values)

  return {
    categories,
    brands,
    company,
    supplierPriceStart,
    supplierPriceEnd,
    retailPriceStart,
    retailPriceEnd,
    maximumRetailPriceStart,
    maximumRetailPriceEnd,
    availableStart,
    availableEnd,
    onHandStart,
    onHandEnd,
    allocatedStart,
    allocatedEnd,
    awaitingStart,
    awaitingEnd,
    inTransferStart,
    inTransferEnd
  }
}

export const toValues = params => {
  const categories = escapeAtob(prop('categories', params))
  const brands = escapeAtob(prop('brands', params))
  const company = escapeAtob(prop('company', params))
  const supplierPriceStart = prop('supplierPriceStart', params)
  const supplierPriceEnd = prop('supplierPriceEnd', params)
  const retailPriceStart = prop('retailPriceStart', params)
  const retailPriceEnd = prop('retailPriceEnd', params)
  const maximumRetailPriceStart = prop('maximumRetailPriceStart', params)
  const maximumRetailPriceEnd = prop('maximumRetailPriceEnd', params)
  const availableStart = prop('availableStart', params)
  const availableEnd = prop('availableEnd', params)
  const onHandStart = prop('onHandStart', params)
  const onHandEnd = prop('onHandEnd', params)
  const allocatedStart = prop('allocatedStart', params)
  const allocatedEnd = prop('allocatedEnd', params)
  const awaitingStart = prop('awaitingStart', params)
  const awaitingEnd = prop('awaitingEnd', params)
  const inTransferStart = prop('inTransferStart', params)
  const inTransferEnd = prop('inTransferEnd', params)

  return {
    categories,
    brands,
    company,
    supplierPriceStart,
    supplierPriceEnd,
    retailPriceStart,
    retailPriceEnd,
    maximumRetailPriceStart,
    maximumRetailPriceEnd,
    availableStart,
    availableEnd,
    onHandStart,
    onHandEnd,
    allocatedStart,
    allocatedEnd,
    awaitingStart,
    awaitingEnd,
    inTransferStart,
    inTransferEnd
  }
}

export const transformer = { toParams, toValues }

export const variantFilterOptions = {
  name,
  transformer
}

function VariantFilterForm (props) {
  return (
    <Filter {...props}>
      <Accordion title="General" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <MultiSelectSearchField
              data-cy="categories"
              name="categories"
              label="Category"
              api={API.CATEGORY_LIST}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <MultiSelectSearchField
              data-cy="brands"
              name="brands"
              label="Brand"
              api={API.BRAND_LIST}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <CompanySearchField />
          </Grid>
        </Grid>
      </Accordion>

      <Accordion title="Price">
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={6}>
            <PositiveFloatField
              data-cy="supplierPriceStart"
              name="supplierPriceStart"
              label="Min Supplier Price"
            />
          </Grid>

          <Grid item={true} xs={6}>
            <PositiveFloatField
              data-cy="supplierPriceEnd"
              name="supplierPriceEnd"
              label="Max Supplier Price"
            />
          </Grid>

          <Grid item={true} xs={6}>
            <PositiveFloatField
              data-cy="retailPriceStart"
              name="retailPriceStart"
              label="Min Retail Price"
            />
          </Grid>

          <Grid item={true} xs={6}>
            <PositiveFloatField
              data-cy="retailPriceEnd"
              name="retailPriceEnd"
              label="Max Retail Price"
            />
          </Grid>

          <Grid item={true} xs={6}>
            <PositiveFloatField
              data-cy="maximumRetailPriceStart"
              name="maximumRetailPriceStart"
              label="Min M.R.P"
            />
          </Grid>

          <Grid item={true} xs={6}>
            <PositiveFloatField
              data-cy="maximumRetailPriceEnd"
              name="maximumRetailPriceEnd"
              label="Max M.R.P"
            />
          </Grid>
        </Grid>
      </Accordion>
      <Accordion title="Stock">
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={6}>
            <PositiveFloatField
              data-cy="availableStart"
              name="availableStart"
              label="Min Available"
            />
          </Grid>

          <Grid item={true} xs={6}>
            <PositiveFloatField
              data-cy="availableEnd"
              name="availableEnd"
              label="Max Available"
            />
          </Grid>

          <Grid item={true} xs={6}>
            <PositiveFloatField
              data-cy="onHandStart"
              name="onHandStart"
              label="Min On Hand"
            />
          </Grid>

          <Grid item={true} xs={6}>
            <PositiveFloatField
              data-cy="onHandEnd"
              name="onHandEnd"
              label="Max On Hand"
            />
          </Grid>

          <Grid item={true} xs={6}>
            <PositiveFloatField
              data-cy="allocatedStart"
              name="allocatedStart"
              label="Min Allocated"
            />
          </Grid>

          <Grid item={true} xs={6}>
            <PositiveFloatField
              data-cy="allocatedEnd"
              name="allocatedEnd"
              label="Max Allocated"
            />
          </Grid>

          <Grid item={true} xs={6}>
            <PositiveFloatField
              data-cy="awaitingStart"
              name="awaitingStart"
              label="Min Awaiting"
            />
          </Grid>

          <Grid item={true} xs={6}>
            <PositiveFloatField
              data-cy="awaitingEnd"
              name="awaitingEnd"
              label="Max Awaiting"
            />
          </Grid>

          <Grid item={true} xs={6}>
            <PositiveFloatField
              data-cy="inTransferStart"
              name="inTransferStart"
              label="Min In Transfer"
            />
          </Grid>

          <Grid item={true} xs={6}>
            <PositiveFloatField
              data-cy="inTransferEnd"
              name="inTransferEnd"
              label="Max In Transfer"
            />
          </Grid>
        </Grid>
      </Accordion>
    </Filter>
  )
}

export default VariantFilterForm
