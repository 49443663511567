import React from 'react'
import InputAdornment from '@mui/material/InputAdornment'
import PropTypes from 'prop-types'

import PositiveNumberField from '~/components/Fields/PositiveNumberField'

function FulfillmentUnitOrderField ({ adornmentText, ...props }) {
  return (
    <PositiveNumberField
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">{adornmentText}</InputAdornment>
        )
      }}
      {...props}
    />
  )
}

FulfillmentUnitOrderField.defaultProps = {
  adornmentText: 'units'
}

FulfillmentUnitOrderField.propTypes = {
  adornmentText: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
}

export default FulfillmentUnitOrderField
