import { useDetail, useUpdate } from 'storfox-api-hooks'

import * as API from '~/constants/api'

export const useAccountDetail = () => {
  return useDetail(API.ACCOUNT_DETAIL)
}

export const useAccountUpdate = () => {
  return useUpdate(API.ACCOUNT_UPDATE)
}
