import React from 'react'
import { useField } from 'react-final-form'
import PropTypes from 'prop-types'
import { map, prop } from 'ramda'

import LocationMultiSelectField from './LocationMultiSelectField'

function LocationWarehouseMultiSelectField ({ warehousesName, ...props }) {
  const warehousesField = useField(warehousesName)
  const warehouses = warehousesField.input.value || []
  const warehouseGuids = map(prop('guid'), warehouses)

  const params = {
    warehouseGuids: warehouseGuids.length ? warehouseGuids.join(',') : null
  }

  return (
    <LocationMultiSelectField params={params} {...props} />
  )
}

LocationWarehouseMultiSelectField.propTypes = {
  warehousesName: PropTypes.string.isRequired
}

export default LocationWarehouseMultiSelectField
