import React from 'react'
import { SelectField } from 'storfox-query-builder'

import { PURCHASE_ORDER_STATUSES } from '~/constants/statuses'

const PURCHASE_STATUSES = [
  { value: PURCHASE_ORDER_STATUSES.NEW, name: 'New' },
  { value: PURCHASE_ORDER_STATUSES.CLOSED, name: 'Closed' },
  { value: PURCHASE_ORDER_STATUSES.CANCELLED, name: 'Cancelled' },
  { value: PURCHASE_ORDER_STATUSES.PROGRESS, name: 'Progress' }
]

function PurchaseStatusField (props) {
  return (
    <SelectField
      options={PURCHASE_STATUSES}
      controlSx={{
        width: 'auto',
        minWidth: 160
      }}
      {...props}
    />
  )
}

export default PurchaseStatusField
