import React, { useCallback } from 'react'
import { useFilter, useOrdering } from 'storfox-filter'
import { useAllSearchParams, useRoute } from 'storfox-route-hooks'
import { path, pick, prop } from 'ramda'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import ErrorLink from '~/components/ErrorLink'
import * as NAV from '~/constants/nav-titles'
import useMessages from '~/hooks/useMessages'
import { NEW, PENDING } from '~/components/Statuses/PicklistStatus'
import { useTableSelects } from '~/components/TableValues/hooks'
import { useGoogleEvent } from '~/components/GoogleAnalytics/GoogleAnalytics'
import useDialog from '~/hooks/useDialog'

import {
  PicklistFilterForm,
  picklistFilterOptions,
  PicklistOrderingForm,
  picklistOrderingOptions,
  PicklistTable
} from '../components/PicklistList'
import {
  usePicklistBatch,
  usePicklistList,
  usePicklistProcess,
  usePicklistProgress,
  usePicklistSetPicker,
  usePicklistPdfReport,
  usePicklistSetBulkPicker
} from '../hooks'
import {
  PicklistAssignToSerializer,
  PicklistBatchSerializer,
  PicklistNewToPendingSerializer,
  PicklistPendingToProgressSerializer
} from '../serializers'
import PickerDialogForm from '../components/PicklistDetail/PickerDialogForm'

function PicklistListContainer () {
  const messages = useMessages()
  const allSearchParams = useAllSearchParams()
  const route = useRoute()
  const snackbar = useSnackbar()
  const { status } = allSearchParams
  const { selects, resetTableSelects } = useTableSelects()
  const { sendEvent } = useGoogleEvent()
  const pickerDialog = useDialog()

  const picklistList = usePicklistList()
  const picklistProcess = usePicklistProcess()
  const picklistProgress = usePicklistProgress()
  const picklistPdfReport = usePicklistPdfReport()
  const picklistSetPicker = usePicklistSetPicker()
  const picklistSetBulkPicker = usePicklistSetBulkPicker()
  const picklistBatch = usePicklistBatch()

  const filter = useFilter(picklistFilterOptions)
  const ordering = useOrdering(picklistOrderingOptions)

  const selectedGuids = selects
  const searchParams = pick(['status', 'page'], allSearchParams)

  const getPicklistList = () => {
    if (searchParams.page !== 1) {
      return picklistList.getList({ ...searchParams, page: 1 })
    }

    route.changeParams({ ...allSearchParams, page: 1 })
  }

  const handleBatchClick = () =>
    picklistBatch.batch(PicklistBatchSerializer(selectedGuids))
      .then(() => {
        sendEvent({ eventAction: 'Batch Picklist', eventCategory: 'Picklist' })
        snackbar({ message: messages.UPDATE_SUCCESS })
      })
      .then(() => resetTableSelects())
      .then(() => getPicklistList())
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const handleProcess = formValues =>
    picklistProcess.process(PicklistNewToPendingSerializer(formValues, selectedGuids))
      .then(() => {
        sendEvent({ eventAction: 'Bulk Process Picklist', eventCategory: 'Picklist' })
        snackbar({ message: messages.UPDATE_SUCCESS })
      })
      .then(() => resetTableSelects())
      .then(() => getPicklistList())
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const handleProgress = formValues =>
    picklistProgress.progress(PicklistPendingToProgressSerializer(formValues, selectedGuids))
      .then(() => {
        sendEvent({ eventAction: 'Bulk Progress Picklist', eventCategory: 'Picklist' })
        snackbar({ message: messages.UPDATE_SUCCESS })
      })
      .then(() => resetTableSelects())
      .then(() => getPicklistList())
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const handleGeneratePdf = () =>
    picklistPdfReport.generate({ guids: selectedGuids })
      .then(() => resetTableSelects())
      .then(() => snackbar({ message: messages.NOTIFICATION_WAIT }))
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const handleSubmit = formValues => {
    const formStatus = prop('status', formValues)
    const methods = {
      [NEW]: handleProcess,
      [PENDING]: handleProgress
    }
    const handleSubmit = prop(formStatus, methods)

    return handleSubmit(formValues)
  }

  const handleAssignToChange = (guid, value) =>
    picklistSetPicker.set(PicklistAssignToSerializer(guid, value))
      .then(() => {
        sendEvent({ eventAction: 'Assign Picklist', eventCategory: 'Picklist' })
        snackbar({ message: messages.UPDATE_SUCCESS })
      })

  const onPickerSelect = useCallback((values) => {
    const assignedToGuid = path(['picker', 'guid'], values)
    return picklistSetBulkPicker.set({ pickerGuid: assignedToGuid, guids: selectedGuids })
      .then(() => {
        sendEvent({ eventAction: 'Assign Picklist', eventCategory: 'Picklist' })
        snackbar({ message: messages.UPDATE_SUCCESS })
        pickerDialog.handleClose()
        picklistList.getListByParams()
        resetTableSelects()
      })
  }, [
    resetTableSelects,
    messages,
    pickerDialog,
    picklistList,
    picklistSetBulkPicker,
    selectedGuids,
    sendEvent,
    snackbar
  ])

  const bulkActionLoading = (
    picklistProcess.isLoading ||
    picklistProgress.isLoading ||
    picklistBatch.isLoading ||
    picklistPdfReport.isLoading ||
    picklistSetBulkPicker.isLoading
  )

  const isLoading = (
    picklistList.isLoading ||
    picklistProcess.isLoading ||
    picklistProgress.isLoading
  )

  const breadcrumbs = { title: NAV.PICKLISTS }

  return (
    <DashboardLayout
      title={NAV.PICKLISTS}
      activeNav={NAV.PICKLISTS}
      breadcrumbs={breadcrumbs}
    >
      <PicklistFilterForm {...filter} />
      <PicklistOrderingForm {...ordering} />

      <PicklistTable
        list={picklistList}
        filter={filter}
        ordering={ordering}
        status={status}
        selectedLength={selectedGuids.length}
        isLoading={isLoading}
        onBatchClick={handleBatchClick}
        onListRefetch={picklistList.getListByParams}
        initialValues={{ pickingJobs: picklistList.results }}
        onAssignToChange={handleAssignToChange}
        onSubmit={handleSubmit}
        onGeneratePdf={handleGeneratePdf}
        onBulkAssign={pickerDialog.handleOpen}
        bulkActionLoading={bulkActionLoading}
      />

      <PickerDialogForm
        open={pickerDialog.open}
        initialValues={{}}
        onClose={pickerDialog.handleClose}
        onSubmit={onPickerSelect}
      />
    </DashboardLayout>
  )
}

export default PicklistListContainer
