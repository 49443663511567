import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import { Filter } from 'storfox-filter'
import { prop } from 'ramda'
import { escapeAtob, unescapeBtoa } from '~/utils'

import Accordion from '~/components/Accordion'

import { FILTER_NAME as name } from '../../constants'
import BucketCompanyListField from "./BucketCompanyListField";

export const toParams = values => {
  const company = unescapeBtoa(prop('company', values))

  return { company }
}

export const toValues = (params) => {
  const company = escapeAtob(prop('company', params))

  return { company }
}

export const transformer = { toParams, toValues }

export const bucketFilterOptions = {
  name,
  transformer
}

function BucketFilterForm (props) {
  return (
    <Filter {...props}>
      <Accordion title="General" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <BucketCompanyListField />
          </Grid>
        </Grid>
      </Accordion>
    </Filter>
  )
}

BucketFilterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
}

export default BucketFilterForm
