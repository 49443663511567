import React from 'react'
import Card from '@mui/material/Card'
import { prop, propOr } from 'ramda'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import Link from '@mui/material/Link'
import { generatePath, Link as RouterLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { CardHeader } from '~/components/Cards'
import * as ROUTES from '~/constants/routes'

function ClientCard ({ client, profile }) {
  const { t } = useTranslation()
  const clientName = prop('name', client)
  const profileName = propOr('', 'name', profile)
  const guid = prop('guid', profile)

  const profilePath = guid && generatePath(ROUTES.BILLING_PROFILE_UPDATE_PATH, { guid, tab: 'general' })

  return (
    <Card>
      <CardHeader title="Client information" />
      <Divider />
      <CardContent>

        <Typography variant="body1" gutterBottom={true}>
          {t('Client')}: {clientName}
        </Typography>

        <Typography variant="body1">
          {t('Billing profile')}:&nbsp;
          {guid && (
            <Link
              component={RouterLink}
              to={profilePath}
              underline="always"
            >
              {profileName}
            </Link>
          )}
        </Typography>

      </CardContent>
    </Card>
  )
}

ClientCard.propTypes = {
  client: PropTypes.object,
  profile: PropTypes.object
}

export default ClientCard
