import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'

import PageTitle from '~/components/PageTitle'
import { DiscardButton, SaveButton } from '~/components/Buttons'
import Container, { Content, Header } from '~/components/Container'
import { withForm } from '~/components/Form'
import * as NAV from '~/constants/nav-titles'
import { CardHeader } from '~/components/Cards'
import TextField from '~/components/Fields/TextField'

import CompanySearchField from './CompanySearchField'

function SaleOrderCreateForm (props) {
  const {
    pageTitle,
    pageTitleLoading,
    isLoading,
    form
  } = props

  const { handleSubmit } = form

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Container>
        <Header>
          <PageTitle
            pageTitle={pageTitle}
            pageTitleLoading={pageTitleLoading}
            parentTitle={NAV.SALE_ORDERS}
            rightButton={(
              <>
                <DiscardButton disabled={isLoading} />
                <SaveButton disabled={isLoading} />
              </>
            )}
          />
        </Header>

        <Content>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12} lg={12}>
              <Card style={{ height: '100%' }}>
                <CardHeader title="Details" />
                <Divider />
                <CardContent style={{ height: '100%' }}>
                  <Grid container={true} spacing={3}>
                    <Grid item={true} lg={6}>
                      <TextField
                        data-cy="name"
                        name="name"
                        label="Name"
                      />
                    </Grid>
                    <Grid item={true} lg={6}>
                      <CompanySearchField />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

            </Grid>
          </Grid>
        </Content>
      </Container>
    </form>
  )
}

SaleOrderCreateForm.propTypes = {
  pageTitle: PropTypes.node.isRequired,
  pageTitleLoading: PropTypes.bool,
  form: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
}

export default withForm(SaleOrderCreateForm)
