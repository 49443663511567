import { includes, map, propOr } from 'ramda'

import { useProfile } from '~/components/Profile'

export default function usePerm () {
  const { profile } = useProfile()
  const permissions = propOr([], 'permissions', profile)

  const hasPerm = perm => {
    return includes(perm, permissions)
  }
  const hasPerms = perms => {
    return map(perm => includes(perm, permissions), perms)
  }
  return { hasPerm, hasPerms }
}
