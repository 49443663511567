import React from 'react'
import { path, prop, propOr } from 'ramda'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'

import { CardHeader } from '~/components/Cards'
import { withForm } from '~/components/Form'

import Comment from './Comment'

import CommentField from '../Fields/CommentField'

function Notes ({ form, notes, onCommentSend }) {
  const { handleSubmit } = form
  const list = propOr([], 'results', notes)
  const isLoading = prop('isLoading', notes)

  const handleChange = value => onCommentSend(value)

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <CardHeader title="Notes" />
        <Divider />
        <CardContent>
          <Box mb={1}>
            <CommentField
              name="comment"
              isLoading={isLoading}
              onChange={handleChange}
            />
          </Box>
          <Grid container={true} spacing={2}>
            {list.map(item => {
              const comment = prop('comment', item)
              const firstName = path(['user', 'firstName'], item)
              const lastName = path(['user', 'lastName'], item)
              const author = `${firstName} ${lastName}`
              const date = prop('createdAt', item)
              const id = prop('id', item)

              return (
                <Grid item={true} xs={12} key={id}>
                  <Comment
                    date={date}
                    author={author}
                    comment={comment}
                  />
                </Grid>
              )
            })}
          </Grid>
        </CardContent>
      </Card>
    </form>
  )
}

Notes.propTypes = {
  notes: PropTypes.object.isRequired,
  onCommentSend: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired
}

export default withForm(Notes)
