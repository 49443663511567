import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import { Filter } from 'storfox-filter'
import { prop } from 'ramda'

import { escapeAtob, getDateTimeMidnight, unescapeBtoa } from '~/utils'
import CustomerSearchField from '~/components/Fields/CustomerSearchField'
import DateField from '~/components/Fields/DateField'
import TextField from '~/components/Fields/TextField'
import WarehouseMultiSelectField from '~/components/Fields/WarehouseMultiSelectField'
import Accordion from '~/components/Accordion'

import AreaStatusField from './AreaStatusField'

import { AREA_FILTER_NAME as name } from '../../../constants'

export const toParams = values => {
  const status = prop('status', values)
  const supplier = unescapeBtoa(prop('supplier', values))
  const warehouse = unescapeBtoa(prop('warehouse', values))
  const startDate = getDateTimeMidnight(prop('startDate', values))
  const endDate = getDateTimeMidnight(prop('endDate', values))
  const minTotal = prop('minTotal', values)
  const maxTotal = prop('maxTotal', values)

  return {
    status,
    supplier,
    warehouse,
    startDate,
    endDate,
    minTotal,
    maxTotal
  }
}

export const toValues = (params) => {
  const status = prop('status', params)
  const supplier = escapeAtob(prop('supplier', params))
  const warehouse = escapeAtob(prop('warehouse', params))
  const startDate = prop('startDate', params)
  const endDate = prop('endDate', params)
  const minTotal = prop('minTotal', params)
  const maxTotal = prop('maxTotal', params)

  return {
    status,
    supplier,
    warehouse,
    startDate,
    endDate,
    minTotal,
    maxTotal
  }
}
export const transformer = { toParams, toValues }

export const areaFilterOptions = {
  name,
  transformer
}

function AreaFilterForm (props) {
  return (
    <Filter {...props}>
      <Accordion title="General" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <AreaStatusField />
          </Grid>
          <Grid item={true} xs={12}>
            <CustomerSearchField />
          </Grid>
          <Grid item={true} xs={12}>
            <WarehouseMultiSelectField />
          </Grid>
        </Grid>
      </Accordion>
      <Accordion title="Date">
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={6}>
            <DateField
              data-cy="startDate"
              name="startDate"
              label="From"
            />
          </Grid>
          <Grid item={true} xs={6}>
            <DateField
              data-cy="endDate"
              name="endDate"
              label="To"
            />
          </Grid>
        </Grid>
      </Accordion>
      <Accordion title="Total ($)">
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={6}>
            <TextField
              data-cy="minTotal"
              name="minTotal"
              label="Min ($)"
            />
          </Grid>
          <Grid item={true} xs={6}>
            <TextField
              data-cy="maxTotal"
              name="maxTotal"
              label="Max ($)"
            />
          </Grid>
        </Grid>
      </Accordion>
    </Filter>
  )
}

AreaFilterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
}

export default AreaFilterForm
