import React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

import Tabs from '~/components/Tabs'
import Divider from '~/components/Divider'
import * as ROUTES from '~/constants/routes'
import { RECEIVE_TABS } from '~/constants/tabs'

const tabs = [
  { value: RECEIVE_TABS.GENERAL, label: 'General' },
  { value: RECEIVE_TABS.HISTORY, label: 'History' },
]

function ReceiveReturnTabs ({ value }) {
  const { guid } = useParams()
  const navigate = useNavigate()

  const onChange = (event, tab) => {
    const redirect = generatePath(ROUTES.RECEIVE_RETURN_DETAIL_PATH, { guid, tab })
    navigate(redirect, { replace: true })
  }

  return (
    <>
      <Tabs
        tabs={tabs}
        value={value}
        onChange={onChange}
      />
      <Divider />
    </>
  )
}

ReceiveReturnTabs.propTypes = {
  value: PropTypes.string.isRequired
}

export default ReceiveReturnTabs
