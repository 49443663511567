import usePostApi from '../api/usePostApi'

function useCreate (api) {
  const {
    post,
    data,
    isLoading,
    ...state
  } = usePostApi(api)

  return { ...state, data, isLoading, create: post }
}

export default useCreate
