import React from 'react'
import PropTypes from 'prop-types'
import { path, propOr } from 'ramda'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import { useField } from 'react-final-form'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel'
import { SwitchField } from 'storfox-form-fields'
import { useTranslation } from 'react-i18next'

import DimensionField from '~/components/Fields/DimensionField'
import MoneyField from '~/components/Fields/MoneyField'
import PositiveNumberField from '~/components/Fields/PositiveNumberField'
import TextField from '~/components/Fields/TextField'
import WeightField from '~/components/Fields/WeightField'
import { CardHeader } from '~/components/Cards'
import { Button } from '~/components/Buttons'
import { useCompany } from '~/components/Profile'
import useDialog from '~/hooks/useDialog'
import PieceQuantityField from '~/components/Fields/PieceQuantityField'
import MethodOfMeasurementField from '~/components/Fields/MethodOfMeasurementField'

import ConditionModal from './ConditionModal'

import VariantImageUploadField from '../Fields/VariantImageUploadField'
import SupplierSearchField from '../Fields/SupplierSearchField'
import BarcodeAliasesField from '../Fields/BarcodeAliasesField'

export const VARIANT_UPDATE_FIELDS = [
  'name',
  'description',
  'images',
  'supplyPrice',
  'maximumRetailPrice',
  'retailPrice',
  'sku',
  'barcode',
  'length',
  'width',
  'height',
  'weight',
  'reorderPoint',
  'reorderQuantity',
  'unitOfMeasurement',
  'pieces',
  'isInternational',
  'hsCode',
  'insuranceValue',
  'sellingCost',
  'customsDescription',
  'originCountry',
  'nameAlias',
  'minReplenishmentPoint',
  'maxReplenishmentPoint',
  'syncSupplyPrice',
  'supplier',
  'isCrossDocking',
  'options',
  'barcodeAliases'
]

function VariantGeneralForm (props) {
  const { initialValues, variantPriceList, onPricesUpdate } = props

  const { t } = useTranslation()
  const { hasBelongCompany } = useCompany()

  const optionsField = useField('options')
  const optionsValue = optionsField.input.value || []
  const optionsChange = optionsField.input.onChange

  const conditionsField = useField('conditions')

  const conditionField = useField('condition')
  const conditionValue = conditionField.input.value
  const condition = propOr('', 'code', conditionValue)

  const { handleClose, handleOpen, open } = useDialog()
  const { getList, isLoading } = variantPriceList

  const handlePricingOpen = () => {
    handleOpen()
    return getList().then(({ results }) => conditionsField.input.onChange(results))
  }

  const handlePricesUpdate = () => onPricesUpdate(conditionsField.input.value).then(() => handleClose())

  const companyId = path(['company', 'id'], initialValues)
  const isOwner = hasBelongCompany(companyId)

  return (
    <>
      <ConditionModal
        open={open}
        isLoading={isLoading}
        onClose={handleClose}
        onPricesUpdate={handlePricesUpdate}
        isOwner={isOwner}
      />
      <Grid item={true} xs={12} lg={8}>
        <Box mb={3}>
          <Card>
            <CardHeader title="Details" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <TextField
                    data-cy="name"
                    name="name"
                    label="Name"
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <TextField
                    data-cy="description"
                    name="description"
                    label="Description"
                    multiline={true}
                    minRows={4}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Box mb={3}>
          <Card>
            <CardHeader title="Images" />
            <Divider />
            <CardContent>
              <VariantImageUploadField />
            </CardContent>
          </Card>
        </Box>
        <Box mb={3}>
          <Card>
            <CardHeader title="Options" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={6}>
                {optionsValue.map((option, index) => (
                  <Grid key={index} item={true} xs={6} lg={4}>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} lg={12}>
                        <TextField
                          name={`options[${index}].name`}
                          label="Option name"
                        />
                      </Grid>
                      <Grid item={true} lg={12}>
                        <TextField
                          name={`options[${index}].option`}
                          label="Option value"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
                <Grid item={true} lg={4}>
                  <Button
                    variant="outlined"
                    type="button"
                    fullWidth={true}
                    onClick={() => optionsChange([ ...optionsValue, { name: '', option: '' } ])}
                  >
                    Create option
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>

      <Grid item={true} xs={12} lg={4}>
        <Box mb={3}>
          <Card>
            <CardHeader title="Supply" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <SupplierSearchField params={{ companyId }} />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Box mb={3}>
          <Card>
            <CardHeader title="Tracking" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <TextField
                    data-cy="sku"
                    name="sku"
                    label="SKU"
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <TextField
                    data-cy="barcode"
                    name="barcode"
                    label="Barcode"
                    placeholder="UPC/EAN/GTIN/ISBN/ASIN"
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <BarcodeAliasesField name="barcodeAliases" label="Barcode aliases" />
                </Grid>
                <Grid item={true} xs={12}>
                  <Grid container={true} spacing={3}>
                    <Grid item={true} lg={6} xs={12}>
                      <PieceQuantityField
                        label="Quantity"
                        name="pieces"
                        data-cy="pieces"
                      />
                    </Grid>
                    <Grid item={true} lg={6} xs={12}>
                      <MethodOfMeasurementField />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Box mb={3}>
          <Card>
            <CardHeader title="Dimensions" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12} lg={6}>
                  <DimensionField
                    data-cy="height"
                    name="height"
                    label="Height"
                  />
                </Grid>

                <Grid item={true} xs={12} lg={6}>
                  <DimensionField
                    data-cy="width"
                    name="width"
                    label="Width"
                  />
                </Grid>

                <Grid item={true} xs={12} lg={6}>
                  <DimensionField
                    data-cy="length"
                    name="length"
                    label="Length"
                  />
                </Grid>

                <Grid item={true} xs={12} lg={6}>
                  <WeightField
                    data-cy="weight"
                    name="weight"
                    label="Weight"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Box mb={3}>
          <Card>
            <CardHeader
              title={
                <>
                  Pricing <br />
                  <Typography variant="caption">
                    Default condition: {condition}
                  </Typography>
                </>
              }
            />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                {isOwner && (
                  <Grid item={true} xs={12}>
                    <MoneyField
                      data-cy="supplyPrice"
                      name="supplyPrice"
                      label="Supply price"
                    />
                  </Grid>
                )}

                <Grid item={true} xs={12}>
                  <MoneyField
                    data-cy="retailPrice"
                    name="retailPrice"
                    label="Retail price"
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <MoneyField
                    data-cy="maximumRetailPrice"
                    name="maximumRetailPrice"
                    label="Maximum Retail price"
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <Box align="left">
                    <Button onClick={handlePricingOpen} disabled={!isOwner}>
                      Add pricing for other conditions
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Box mb={3}>
          <Card>
            <CardHeader title="Reorder" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <PositiveNumberField
                    data-cy="reorderPoint"
                    name="reorderPoint"
                    label="Reorder Point"
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <PositiveNumberField
                    data-cy="reorderQuantity"
                    name="reorderQuantity"
                    label="Reorder Quantity"
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <PositiveNumberField
                    data-cy="minReplenishmentPoint"
                    name="minReplenishmentPoint"
                    label="Min Replenishment Point"
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <PositiveNumberField
                    data-cy="maxReplenishmentPoint"
                    name="maxReplenishmentPoint"
                    label="Max Replenishment Point"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Box mb={3}>
          <Card>
            <CardHeader title="Fulfillment" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <FormControl style={{ padding: '0 12px' }}>
                    <FormControlLabel
                      data-cy="isCrossDocking"
                      control={<SwitchField name="isCrossDocking" />}
                      label={t('Cross docking')}
                    />
                  </FormControl>
                </Grid>
                <Grid item={true} xs={12}>
                  <FormControl style={{ padding: '0 12px' }}>
                    <FormControlLabel
                      data-cy="syncSupplyPrice"
                      control={<SwitchField name="syncSupplyPrice" />}
                      label={t('Sync Supply Price')}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>
      <Box mb={3} />
    </>
  )
}

VariantGeneralForm.propTypes = {
  initialValues: PropTypes.object,
  onPricesUpdate: PropTypes.func.isRequired,
  variantPriceList: PropTypes.shape({
    getList: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    results: PropTypes.array.isRequired
  }).isRequired
}

export default VariantGeneralForm
