import React from 'react'
import DialogContent from '@mui/material/DialogContent'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { reduce } from 'ramda'

import DialogTitle from '~/components/DialogTitle'
import { Button } from '~/components/Buttons'
import SelectableBox from '~/components/SelectableBox'
import TextOverflow from '~/components/TextOverflow'

import SkipProcessInstruction from './SkipProcessInstruction'

function SkipProcessDialog (props) {
  const {
    open,
    onClose,
    onProcessSelect,
    processList,
    onSubmit,
  } = props

  const keys = reduce((acc, process) => process.value ? [...acc, process.key] : acc, [], processList)

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth={true}
      onClose={onClose}
    >
      <DialogTitle>Skip processes</DialogTitle>
      <DialogContent dividers={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <SkipProcessInstruction keys={keys} />
          </Grid>
          {processList.map(process => {
            return (
              <Grid item={true} xs={12} lg={6} key={process.key} data-cy={process.key}>
                <SelectableBox
                  isPadding={true}
                  onClick={() => onProcessSelect(process.key)}
                  selected={process.value}
                >
                  <TextOverflow tooltip={process.name} lines={1}>
                    <Typography color="inherit">{process.name}</Typography>
                  </TextOverflow>
                </SelectableBox>
              </Grid>
            )
          })}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>
          Close
        </Button>
        <Button disabled={false} onClick={onSubmit} data-cy="skip-submit">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

SkipProcessDialog.propTypes = {
  onProcessSelect: PropTypes.func.isRequired,
  processList: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default SkipProcessDialog
