import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { map, prop } from 'ramda'
import { generatePath, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import EditIcon from '@mui/icons-material/Edit'

import { MoreListButton, NewButton } from '~/components/Buttons'
import Avatar from '~/components/Avatar/Avatar'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import { Table, TableHeader } from '~/components/Table'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import TableLink from '~/components/Link'
import TextOverflow from '~/components/TextOverflow'

import BulkActionButton from './BulkActionButton'

const getDetailPath = id => {
  return generatePath(ROUTES.BRAND_DETAIL_PATH, { id })
}

export const BRAND_COLUMNS = [
  {
    width: 75,
    headerName: 'Image',
    field: 'image',
    sortable: false,
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        <Avatar src={value} alt={row.alt} />
      </TableLink>
    )
  },
  {
    width: 1100,
    headerName: 'Name',
    field: 'name',
    ordering: true,
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        <TextOverflow lines={1} selfTooltip={true}>
          {value}
        </TextOverflow>
      </TableLink>
    )
  }
]

const getRowsFromResults = map(item => {
  const id = prop('id', item)
  const name = prop('name', item)
  const image = prop('imagePath', item)
  const link = getDetailPath(id)

  return { id, image, name, link }
})

const pinnedColumns = ['image', 'name']

function BrandTable ({ list, onListRefetch, ordering, onBrandsDelete, bulkActionLoading }) {
  const [tableRef, setTableRef] = useState(null)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const isLoading = prop('isLoading', list)
  const results = prop('results', list)
  const count = prop('count', list)
  const columns = prop('filteredColumns', ordering)

  const handleOrderingOpen = useCallback(ordering.handleOpen, [])
  const orderingMemoized = useMemo(() => ordering, [ordering])

  const handleEdit = useCallback(id => {
    const editPath = generatePath(ROUTES.BRAND_UPDATE_PATH, { id })
    navigate(editPath)
  }, [navigate])

  const actionColumns = useMemo(() => [
    ...columns,
    {
      width: 50,
      resizable: false,
      field: 'actions',
      type: 'actions',
      getActions: ({ row }) => [
        <GridActionsCellItem
          label="Edit"
          onClick={() => handleEdit(row.id)}
          showInMenu={true}
          icon={<EditIcon />}
        />
      ]
    }
  ], [columns, handleEdit])

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={t(NAV.BRANDS)}
          parentTitle={t(NAV.CATALOG)}
          rightButton={(
            <>
              <BulkActionButton onBrandsDelete={onBrandsDelete} loading={bulkActionLoading} />
              <NewButton path={ROUTES.BRAND_CREATE_PATH} />
            </>
          )}
        />
      </Header>

      <Content>
        <TableHeader
          orderingOpen={handleOrderingOpen}
          ordering={orderingMemoized}
        >
          <MoreListButton tableRef={tableRef} />
        </TableHeader>
        <Table
          isLoading={isLoading}
          columns={actionColumns}
          getRowsFromResults={getRowsFromResults}
          results={results}
          count={count}
          checkboxSelection={true}
          onRefetch={onListRefetch}
          ordering={ordering}
          pinnedColumns={pinnedColumns}
          setTableRef={setTableRef}
        />
      </Content>
    </Container>
  )
}

BrandTable.propTypes = {
  list: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  onBrandsDelete: PropTypes.func,
  onListRefetch: PropTypes.func,
  bulkActionLoading: PropTypes.bool
}

export default BrandTable
