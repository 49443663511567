import { sprintf } from 'sprintf-js'
import { useAllSearchParams } from 'storfox-route-hooks'
import {
  useCreate,
  useDelete,
  useDetail,
  useLimitedCountList,
  useList,
  useScrollList,
  useUpdate
} from 'storfox-api-hooks'

import { queryToParams } from '~/utils'
import * as API from '~/constants/api'

import { TransferFilterSerializer } from '../serializers'

export const useTransferCreate = () => {
  return useCreate(API.TRANSFER_CREATE)
}

export const useTransferCreateAndProcess = () => {
  return useCreate(API.TRANSFER_CREATE_AND_PROCESS)
}

export const useTransferList = () => {
  const searchParams = useAllSearchParams()
  const params = queryToParams(TransferFilterSerializer, searchParams, 'guid')

  return useLimitedCountList(API.TRANSFER_LIST, params)
}

export const useTransferListProcess = () => {
  return useUpdate(API.TRANSFER_LIST_PROCESS)
}

export const useTransferListComplete = () => {
  const { update, ...state } = useUpdate(API.TRANSFER_LIST_COMPLETE)

  return { ...state, complete: update }
}

export const useTransferUpdate = guid => {
  const url = sprintf(API.TRANSFER_UPDATE, guid)
  return useUpdate(url)
}

export const useTransferUpdateAndProcess = guid => {
  const url = sprintf(API.TRANSFER_UPDATE_AND_PROCESS, guid)
  return useUpdate(url)
}

export const useTransferDetail = guid => {
  const url = sprintf(API.TRANSFER_DETAIL, guid)
  return useDetail(url)
}

export const useTransferBarcodeValidate = () => {
  return useList(API.TRANSFER_UNITS)
}

export const useTransferCancel = () => {
  return useUpdate(API.TRANSFER_LIST_CANCEL)
}

export const useTransferDetailProcess = guid => {
  const url = sprintf(API.TRANSFER_DETAIL_PROCESS, guid)
  const { update, ...state } = useUpdate(url)

  return { ...state, process: update }
}

export const useTransferDetailComplete = (guid) => {
  const url = sprintf(API.TRANSFER_DETAIL_COMPLETE, guid)
  const { update, ...state } = useUpdate(url)

  return { ...state, complete: update }
}

export const useTransfersDelete = () => {
  return useDelete(API.TRANSFERS_DELETE)
}

export const useTransferOrderVariant = (params) => {
  return useScrollList(API.TRANSFER_VARIANTS, params)
}

export const useTransferHistory = (guid, autoSend) => {
  const searchParams = useAllSearchParams()
  const url = sprintf(API.TRANSFER_HISTORY, guid)

  return useList(url, searchParams, autoSend)
}
