import React from 'react'

import SelectField from '~/components/Fields/SelectField'
import { CODE_TYPE } from '~/constants/barcode'

const BARCODE_TYPE = [
  { value: CODE_TYPE.BARCODE, name: 'Bar-Code' },
  { value: CODE_TYPE.QRCODE, name: 'QR-Code' }
]

function BarcodeSelectField () {
  return (
    <SelectField
      label="Type"
      name="type"
      options={BARCODE_TYPE}
    />
  )
}

export default BarcodeSelectField
