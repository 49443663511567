import React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'
import { prop, propOr } from 'ramda'

import { DashboardLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'
import ErrorLink from '~/components/ErrorLink'
import { INTEGRATION_TABS } from '~/constants/tabs'
import useMessages from '~/hooks/useMessages'

import { PostmenUpdateSerializer } from '../../serializers'
import { useIntegrationHistory, usePostmenDetail, usePostmenRemove, usePostmenUpdate } from '../../hooks'
import PostmenIntegrationUpdateForm from '../../components/Postmen/PostmenIntegrationUpdateForm'

function PostmenIntegrationUpdateContainer () {
  const { guid, tab } = useParams()

  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const messages = useMessages()
  const postmenDetail = usePostmenDetail(guid)
  const postmenUpdate = usePostmenUpdate(guid)
  const postmenRemove = usePostmenRemove()

  const historyAutoSend = Boolean(guid && tab === INTEGRATION_TABS.LOGS)
  const integrationHistory = useIntegrationHistory(guid, historyAutoSend)

  const title = prop('name', postmenDetail.detail)
  const type = propOr('', 'type', postmenDetail.detail)

  const detailPath = generatePath(ROUTES.INTEGRATION_UPDATE_PATH, { guid, type, tab: INTEGRATION_TABS.GENERAL })

  const handleSubmit = formValues =>
    postmenUpdate.update(PostmenUpdateSerializer(formValues))
      .then(() => {
        snackbar({ message: messages.UPDATE_SUCCESS })
        navigate(detailPath)
      })
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const isLoading = (
    postmenDetail.isLoading ||
    postmenUpdate.isLoading
  )

  const handleRemove = () =>
    postmenRemove.delete({ guids: [guid] })
      .then(() => navigate(ROUTES.INTEGRATION_LIST_PATH, { replace: true }))

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      isLoading={postmenDetail.isLoading}
      activeNav={NAV.INTEGRATION}
      breadcrumbs={breadcrumbs}
    >
      <PostmenIntegrationUpdateForm
        isLoading={isLoading}
        pageTitle={title}
        pageTitleLoading={postmenDetail.isLoading}
        onSubmit={handleSubmit}
        onRemove={handleRemove}
        initialValues={postmenDetail.detail}
        history={integrationHistory}
      />
    </DashboardLayout>
  )
}

export default PostmenIntegrationUpdateContainer
