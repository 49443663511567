import React from 'react'
import { Filter } from 'storfox-filter'
import Grid from '@mui/material/Grid'
import { DateRangeField, PositiveFloatField } from 'storfox-form-fields'
import { prop } from 'ramda'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'

import { escapeAtob, getSeparatedDateRange, unescapeBtoa } from '~/utils'
import Accordion from '~/components/Accordion'
import * as API from '~/constants/api'
import SearchField from '~/components/Fields/SearchField'

import ListingStatusField from './ListingStatusField'

import { FILTER_NAME as name } from '../../constants'

export const toParams = values => {
  const status = prop('status', values)
  const category = unescapeBtoa(prop('category', values))
  const brand = unescapeBtoa(prop('brand', values))
  const channel = unescapeBtoa(prop('channel', values))

  const [updatedAtStart, updatedAtEnd] = getSeparatedDateRange(prop('updatedAt', values))
  const retailPriceStart = prop('retailPriceStart', values)
  const retailPriceEnd = prop('retailPriceEnd', values)

  return {
    status,
    category,
    brand,
    channel,
    updatedAtStart,
    updatedAtEnd,
    retailPriceStart,
    retailPriceEnd,
  }
}

export const toValues = params => {
  const status = prop('status', params)
  const category = escapeAtob(prop('category', params))
  const brand = escapeAtob(prop('brand', params))
  const channel = escapeAtob(prop('channel', params))
  const updatedAt = [prop('updatedAtStart', params), prop('updatedAtEnd', params)]
  const retailPriceStart = prop('retailPriceStart', params)
  const retailPriceEnd = prop('retailPriceEnd', params)

  return {
    status,
    category,
    brand,
    channel,
    updatedAt,
    retailPriceStart,
    retailPriceEnd,
  }
}
export const transformer = { toParams, toValues }

export const listingFilterOptions = {
  name,
  transformer
}

function ListingFilterForm (props) {
  const { t } = useTranslation()
  return (
    <Filter {...props}>
      <Accordion title="General" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <ListingStatusField />
          </Grid>
          <Grid item={true} xs={12}>
            <SearchField
              data-cy="category"
              name="category"
              label="Category"
              api={API.CATEGORY_LIST}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <SearchField
              data-cy="brand"
              name="brand"
              label="Brand"
              api={API.BRAND_LIST}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <SearchField
              data-cy="channel"
              name="channel"
              label="Channel"
              api={API.SALE_ORDER_CHANNEL}
            />
          </Grid>
        </Grid>
      </Accordion>
      <Accordion title="Date">
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <Typography variant="body2" gutterBottom={true}>
              {t('Updated')}:
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <DateRangeField name="updatedAt" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Accordion>
      <Accordion title="Price">
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={6}>
            <PositiveFloatField
              data-cy="retailPriceStart"
              name="retailPriceStart"
              label="Min Retail Price"
            />
          </Grid>
          <Grid item={true} xs={6}>
            <PositiveFloatField
              data-cy="retailPriceEnd"
              name="retailPriceEnd"
              label="Max Retail Price"
            />
          </Grid>
        </Grid>
      </Accordion>
    </Filter>
  )
}

export default ListingFilterForm
