import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { DateTimeField } from 'storfox-form-fields'
import { FilterNoSave } from 'storfox-filter'

import Accordion from '~/components/Accordion'

function IntegrationHistoryFilterForm (props) {
  const { t } = useTranslation()

  return (
    <FilterNoSave {...props}>
      <Accordion title="Date" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <Typography variant="body2" gutterBottom={true}>
              {t('Created')}:
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <DateTimeField name="createdAtStart" label="Start" />
              </Grid>
              <Grid item={true} xs={12}>
                <DateTimeField name="createdAtEnd" label="End" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Accordion>
    </FilterNoSave>
  )
}

IntegrationHistoryFilterForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default IntegrationHistoryFilterForm
