import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material'

import DialogTitle from '~/components/DialogTitle'
import { Button, DiscardButton } from '~/components/Buttons'
import SwitchAccountIcon from '~/components/Icons/SwitchAccountIcon'
import Divider from '~/components/Divider'

function SwitchAccountDialog ({ open, handleClose, clientName, companyName, onLoginByContract }) {
  return (
    <Dialog
      open={open}
      fullWidth={true}
      onClose={handleClose}
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        <Typography
          component="h2"
          variant="h6"
        >
          Switch Account
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ minHeight: '30vh', paddingTop: '10px !important' }}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          height="100%"
          width="100%"
        >
          <SwitchAccountIcon />
          <Box mt={2}>
            <Typography
              align="center"
              component="h6"
              gutterBottom={true}
            >
              Do you want to switch account from <b>{companyName}</b> to <b>{clientName}</b>?
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <DiscardButton
          onClick={handleClose}
          fullWidth={true}
        >
          Disagree
        </DiscardButton>

        <Button
          variant="contained"
          fullWidth={true}
          onClick={onLoginByContract}
          data-cy="done"
        >
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  )
}

SwitchAccountDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onLoginByContract: PropTypes.func.isRequired,
  companyName: PropTypes.string.isRequired,
  clientName: PropTypes.string.isRequired,
}

export default SwitchAccountDialog
