import React, { useState } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { path, prop, propOr } from 'ramda'
import { useTranslation } from 'react-i18next'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'
import { sprintf } from 'sprintf-js'
import { useRequest } from 'storfox-api-hooks'

import * as ROUTES from '~/constants/routes'
import { PACKING_DETAIL_TABS, SHIPMENT_DETAIL_TABS } from '~/constants/tabs'
import { useNotification } from '~/components/Notification'
import { SHIPMENT_CREATED } from '~/constants/notification-topics'
import useMessages from '~/hooks/useMessages'
import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import ErrorLink from '~/components/ErrorLink'
import { useGoogleEvent } from '~/components/GoogleAnalytics/GoogleAnalytics'
import * as API from '~/constants/api'

import {
  usePackingComplete,
  usePackingContainerTypeList,
  usePackingSlipPdf,
  usePackingDetail,
  usePackingHistory,
  usePackingJobCreate,
  usePackingProcess,
  usePackingSkip,
  useShipmentLocationList
} from '../hooks'
import PackingDetail from '../components/PackingDetail'
import {
  PackingCompleteSerializer,
  PackingJobDetailCreateSerializer
} from '../serializers'

function PackingDetailContainer () {
  const { t } = useTranslation()
  const { id, tab } = useParams()
  const messages = useMessages()
  const { sendEvent } = useGoogleEvent()
  const request = useRequest()
  const [shipmentGuid, setShipmentGuid] = useState(null)

  const packingDetail = usePackingDetail(id)
  const packingSkip = usePackingSkip(id)

  const guid = prop('guid', packingDetail.detail)
  const historyAutoSend = Boolean(guid && tab === PACKING_DETAIL_TABS.HISTORY)
  const warehouseId = prop('warehouseId', packingDetail.detail)

  const packingComplete = usePackingComplete()
  const packingJobCreate = usePackingJobCreate()
  const packingProcess = usePackingProcess(id)
  const containerTypeList = usePackingContainerTypeList()
  const packingGeneratePDF = usePackingSlipPdf(guid)

  const shipmentLocationList = useShipmentLocationList(warehouseId)
  const packingHistory = usePackingHistory(guid, historyAutoSend)

  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const notification = useNotification(SHIPMENT_CREATED)

  const toObjectNumber = propOr('', 'toObjectNumber', packingDetail.detail)
  const toObjectReferenceNumber = propOr('', 'toObjectReferenceNumber', packingDetail.detail)
  const referenceNumber = toObjectReferenceNumber ? `/${toObjectReferenceNumber}` : ''

  const title = `${t('Packing')} ${toObjectNumber}${referenceNumber}`

  const completePacking = formValues =>
    packingComplete.update(PackingCompleteSerializer(formValues))
      .then(res => {
        snackbar({ message: messages.NOTIFICATION_WAIT })
        return res
      })
      .then(res => {
        sendEvent({
          eventAction: 'Complete Packing',
          eventCategory: 'Packing'
        })
        const guid = path(['result', 'key'], res)
        setShipmentGuid(guid)
        return res
      })

  const handleComplete = formValues =>
    completePacking(formValues)
      .then(({ result }) => {
        const key = prop('key', result)
        notification.subscribe(key, () => packingDetail.getDetail())
      })

  const handleCompleteAndRedirect = formValues =>
    completePacking(formValues)
      .then(({ result }) => {
        const key = prop('key', result)
        notification.subscribe(key, () => {
          const params = { guid: key, tab: SHIPMENT_DETAIL_TABS.GENERAL }
          const route = generatePath(ROUTES.SHIPMENT_DETAIL_PATH, params)
          navigate(route)
          return Promise.resolve()
        })
      })
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const handlePackerChange = formValues =>
    packingJobCreate.create(PackingJobDetailCreateSerializer(id, formValues))
      .then(() => {
        sendEvent({ eventAction: 'Assign Packer Packing', eventCategory: 'Packing' })
        snackbar({ message: messages.UPDATE_SUCCESS })
      })
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const handleProcess = () =>
    packingProcess.update()
      .then(() => {
        sendEvent({ eventAction: 'Process Packing', eventCategory: 'Packing' })
        snackbar({ message: messages.UPDATE_SUCCESS })
      })
      .then(() => packingDetail.getDetail())
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const handleSkip = () =>
    packingSkip.skip()
      .then(() => {
        sendEvent({ eventAction: 'Skip Packing', eventCategory: 'Packing' })
        snackbar({ message: messages.UPDATE_SUCCESS })
      })
      .then(() => packingDetail.getDetail())
      .catch(error => snackbar({
        message: <ErrorLink error={error} />,
        type: ALTER_ERROR
      }))

  const handleGenerateSlipPdf = () =>
    packingGeneratePDF.generate()
      .then(() => snackbar({ message: messages.NOTIFICATION_WAIT }))
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const handlePrintInvoice = () => {
    const url = sprintf(API.SHIPMENT_GENERATE_COMMERCIAL_INVOICE, shipmentGuid)
    return request.get(url)
      .then(() => snackbar({ message: messages.GENERATE_SUCCESS }))
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
        return Promise.reject(error)
      })
  }

  const handleCompleteAndPrintInvoice = formValues =>
    completePacking(formValues)
      .then(({ result }) => {
        snackbar({ message: messages.NOTIFICATION_WAIT })
        const key = prop('key', result)
        const url = sprintf(API.SHIPMENT_GENERATE_COMMERCIAL_INVOICE, key)
        notification.subscribe(key, () => {
          return request.get(url)
            .then(() => snackbar({ message: messages.GENERATE_SUCCESS }))
            .catch(error => {
              const message = <ErrorLink error={error} />
              snackbar({ message, type: ALTER_ERROR })
              return Promise.reject(error)
            })
        })
      })

  const isLoading = (
    packingDetail.isLoading ||
    notification.isLoading
  )

  const breadcrumbs = { title }
  return (
    <DashboardLayout
      title={title}
      isLoading={packingDetail.isLoading}
      breadcrumbs={breadcrumbs}
      activeNav={NAV.PACKING}
    >
      <PackingDetail
        pageTitle={title}
        isLoading={isLoading}
        saleOrderNumber={title}
        packingHistory={packingHistory}
        containerTypeList={containerTypeList}
        pageTitleLoading={packingDetail.isLoading}
        values={packingDetail.detail}
        getDetail={packingDetail.getDetail}
        shipmentLocationList={shipmentLocationList.results}
        onProcess={handleProcess}
        onComplete={handleComplete}
        onCompleteAndRedirect={handleCompleteAndRedirect}
        onPackerChange={handlePackerChange}
        onSkip={handleSkip}
        onGenerateSlip={handleGenerateSlipPdf}
        onPrintInvoice={handlePrintInvoice}
        completePacking={completePacking}
        handleCompleteAndPrintInvoice={handleCompleteAndPrintInvoice}
      />
    </DashboardLayout>
  )
}

export default PackingDetailContainer
