import React from 'react'
import Grid from '@mui/material/Grid'
import { PasswordField } from 'storfox-form-fields'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card/Card'
import CardContent from '@mui/material/CardContent'
import { useTranslation } from 'react-i18next'

import Divider from '~/components/Divider'
import { CardHeader } from '~/components/Cards'
import TextField from '~/components/Fields/TextField'

function MagentoCreate () {
  const { t } = useTranslation()
  return (
    <Grid container={true}>
      <Grid item={true} xs={12}>
        <Box mb={3}>
          <Card>
            <CardHeader title={t('Integration')} />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12} lg={6}>
                  <TextField
                    data-cy="name"
                    name="name"
                    label="Name"
                  />
                </Grid>

                <Grid item={true} xs={12} lg={6}>
                  <PasswordField
                    name="storfoxAccessToken"
                    label="token"
                    iconSize="small"
                    disabled={true}
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <TextField
                    data-cy="description"
                    name="description"
                    label="Description"
                    multiline={true}
                    rows="4"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </Grid>
  )
}

export default MagentoCreate
