import React from 'react'
import PropTypes from 'prop-types'
import { prop, propOr } from 'ramda'
import Grid from '@mui/material/Grid'
import { Filter } from 'storfox-filter'
import { DateRangeField, PositiveFloatField } from 'storfox-form-fields'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

import { arrayToParams, escapeAtob, getSeparatedDateRange, unescapeBtoa } from '~/utils'
import SupplierSearchField from '~/components/Fields/SupplierSearchField'
import Accordion from '~/components/Accordion'

import PurchaseOrderWarehousesField from './PurchaseOrderWarehousesField'
import PurchaseOrderVariantsField from './PurchaseOrderVariantsField'

import PurchaseOrderStatusField from '../Fields/PurchaseOrderStatusField'
import { FILTER_NAME as name } from '../../constants'

export const toParams = values => {
  const status = prop('status', values)
  const supplier = unescapeBtoa(prop('supplier', values))
  const totalPriceStart = prop('totalPriceStart', values)
  const totalPriceEnd = prop('totalPriceEnd', values)
  const warehouseList = propOr([], 'warehouses', values)
  const variants = arrayToParams('variants', values)

  const warehouses = warehouseList.length ? unescapeBtoa(warehouseList) : null

  const [createdAtStart, createdAtEnd] = getSeparatedDateRange(prop('createdAt', values))

  return {
    status,
    supplier,
    warehouses,
    variants,
    createdAtStart,
    createdAtEnd,
    totalPriceStart,
    totalPriceEnd
  }
}

export const toValues = params => {
  const status = prop('status', params)
  const supplier = escapeAtob(prop('supplier', params))
  const warehouses = escapeAtob(prop('warehouses', params))
  const variants = escapeAtob(prop('variants', params))
  const totalPriceStart = prop('totalPriceStart', params)
  const totalPriceEnd = prop('totalPriceEnd', params)

  const createdAt = [prop('createdAtStart', params), prop('createdAtEnd', params)]

  return {
    status,
    supplier,
    warehouses,
    variants,
    createdAt,
    totalPriceStart,
    totalPriceEnd
  }
}

export const transformer = { toParams, toValues }

export const purchaseOrderFilterOptions = {
  name,
  transformer
}

function PurchaseOrderFilterForm (props) {
  const { t } = useTranslation()

  return (
    <Filter {...props}>
      <Accordion title="General" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <PurchaseOrderStatusField />
          </Grid>
          <Grid item={true} xs={12}>
            <SupplierSearchField />
          </Grid>
          <Grid item={true} xs={12}>
            <PurchaseOrderWarehousesField />
          </Grid>
          <Grid item={true} xs={12}>
            <PurchaseOrderVariantsField />
          </Grid>
        </Grid>
      </Accordion>
      <Accordion title="Date">
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <Typography variant="body2" gutterBottom={true}>
              {t('Created')}:
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <DateRangeField name="createdAt" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Accordion>
      <Accordion title="Total ($)">
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={6}>
            <PositiveFloatField
              data-cy="totalPriceStart"
              name="totalPriceStart"
              label="Min ($)"
            />
          </Grid>
          <Grid item={true} xs={6}>
            <PositiveFloatField
              data-cy="totalPriceEnd"
              name="totalPriceEnd"
              label="Max ($)"
            />
          </Grid>
        </Grid>
      </Accordion>
    </Filter>
  )
}

PurchaseOrderFilterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
}

export default PurchaseOrderFilterForm
