import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import RiderIntegrationUpdateContainer from './RiderIntegrationUpdateContainer'
import RiderIntegrationCreateContainer from './RiderIntegrationCreateContainer'

export default () => {
  return [
    {
      layout: AuthLayout,
      path: ROUTES.RIDER_INTEGRATION_CREATE_PATH,
      component: RiderIntegrationCreateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.RIDER_INTEGRATION_UPDATE_PATH,
      component: RiderIntegrationUpdateContainer
    }
  ]
}
