import React from 'react'
import Dialog from '@mui/material/Dialog/Dialog'
import DialogContent from '@mui/material/DialogContent/DialogContent'
import Grid from '@mui/material/Grid'
import DialogActions from '@mui/material/DialogActions/DialogActions'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'

import DimensionField from '~/components/Fields/DimensionField'
import WeightField from '~/components/Fields/WeightField'
import { Button } from '~/components/Buttons'
import { withForm } from '~/components/Form'
import DiscardButton from '~/components/Buttons/DiscardButton'
import DialogTitle from '~/components/DialogTitle'

function DimensionUpdateDialog ({ open, onClose, isLoading, form, ...props }) {
  const { handleSubmit } = form

  return (
    <Dialog
      maxWidth="md"
      onClose={onClose}
      open={open}
      fullWidth={true}
      {...props}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          <Typography component="h2" variant="h6">Update container dimensions</Typography>
        </DialogTitle>
        <DialogContent dividers={true}>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={6}>
              <DimensionField
                data-cy="width"
                name="containerType.width"
                label="Width"
              />
            </Grid>
            <Grid item={true} xs={6}>
              <DimensionField
                data-cy="height"
                name="containerType.height"
                label="Height"
              />
            </Grid>
            <Grid item={true} xs={6}>
              <DimensionField
                data-cy="length"
                name="containerType.length"
                label="Length"
              />
            </Grid>
            <Grid item={true} xs={6}>
              <WeightField
                data-cy="weight"
                name="weight"
                label="Weight"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DiscardButton
            fullWidth={true}
            onClick={onClose}
          >
            Discard
          </DiscardButton>
          <Button
            variant="contained"
            type="submit"
            fullWidth={true}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

DimensionUpdateDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  variant: PropTypes.object,
  form: PropTypes.object,
  isLoading: PropTypes.bool,
}

export default withForm(DimensionUpdateDialog)
