import React, { useCallback } from 'react'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell/TableCell'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { path, prop, propOr } from 'ramda'
import { SwitchField } from 'storfox-form-fields'
import Delete from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import PropTypes from 'prop-types'
import { useField } from 'react-final-form'

import SubmitBlockedTextField from '~/components/Fields/SubmitBlockedTextField'
import TextField from '~/components/Fields/TextField'
import Avatar from '~/components/Avatar/Avatar'
import MoneyField from '~/components/Fields/MoneyField'
import useDialog from '~/hooks/useDialog'

import VariantOptionsDialogForm from './VariantOptionsDialogForm'

import { VariantOptionsInitialSerializer, VariantOptionsUpdateSerializer } from '../../../serializers'

function VariantRow ({ index, name, fields, variantsValue, optionSelectPickerValues }) {
  const optionsDialogue = useDialog()

  const variantNameField = useField(`${name}.name`)
  const variantName = variantNameField.input.value
  const variantNameChange = variantNameField.input.onChange

  const productNameField = useField('name')
  const productNameValue = productNameField.input.value

  const optionsField = useField(`${name}.options`)
  const optionsValue = optionsField.input.value || []
  const optionsChange = optionsField.input.onChange

  const variant = path(['value', index], fields)
  const disabled = !prop('enabled', variant)
  const defaultImage = path([index, 'defaultImage'], variantsValue)

  const initialValues = VariantOptionsInitialSerializer(optionSelectPickerValues, optionsValue)

  const handleSaveOptions = useCallback((values) => {
    const options = VariantOptionsUpdateSerializer(values)
    const optionsForName = propOr([], 'options', values).map(opt => propOr('', 'option', opt))
    const newName = `${productNameValue} ${optionsForName.join(' ')}`
    optionsChange(options)
    optionsDialogue.handleClose()
    if (variantName !== newName) {
      variantNameChange(newName)
    }
  }, [productNameValue, variantName, optionsChange, optionsDialogue, variantNameChange])

  return (
    <TableRow key={index}>
      <TableCell scope="row">
        <Avatar
          alt={name}
          src={defaultImage}
        />
      </TableCell>
      <TableCell scope="row" sx={{ minWidth: 250 }}>
        <Box width="100%">
          <TextField
            disabled={disabled}
            name={`${name}.name`}
            data-cy={`${name}.name`}
          />
        </Box>
      </TableCell>
      <TableCell align="right" sx={{ minWidth: 150 }}>
        <Box width="100%">
          <TextField
            disabled={disabled}
            name={`${name}.sku`}
            data-cy={`${name}.sku`}
          />
        </Box>
      </TableCell>
      <TableCell align="right" sx={{ minWidth: 150 }}>
        <Box width="100%">
          <SubmitBlockedTextField
            data-cy={`${name}.barcode`}
            name={`${name}.barcode`}
            label="Barcode"
            required={true}
          />
        </Box>
      </TableCell>
      <TableCell align="right">
        <Box width="100%" sx={{ minWidth: 75 }}>
          <MoneyField
            disabled={disabled}
            name={`${name}.supplyPrice`}
            data-cy={`${name}.supplyPrice`}
          />
        </Box>
      </TableCell>
      <TableCell align="right" sx={{ minWidth: 75 }}>
        <Box width="100%">
          <MoneyField
            disabled={disabled}
            name={`${name}.retailPrice`}
            data-cy={`${name}.retailPrice`}
          />
        </Box>
      </TableCell>
      <TableCell align="center" sx={{ maxWidth: 25 }}>
        <IconButton onClick={() => optionsDialogue.handleOpen()}>
          <EditIcon />
        </IconButton>
      </TableCell>
      <TableCell align="right">
        <SwitchField name={`${name}.enabled`} />
      </TableCell>
      <TableCell>
        <IconButton data-cy={`delete${index}`} size="small" onClick={() => fields.remove(index)}>
          <Delete />
        </IconButton>
      </TableCell>
      {optionsDialogue.open && (
        <VariantOptionsDialogForm
          open={optionsDialogue.open}
          onClose={optionsDialogue.handleClose}
          onSubmit={handleSaveOptions}
          options={optionSelectPickerValues}
          variantOptions={optionsValue}
          initialValues={initialValues}
        />
      )}
    </TableRow>
  )
}

VariantRow.propTypes = {
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  fields: PropTypes.object.isRequired,
  variantsValue: PropTypes.object.isRequired,
  optionSelectPickerValues: PropTypes.array.isRequired
}

export default VariantRow
