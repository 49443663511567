import React from 'react'
import { prop, propOr } from 'ramda'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { useCompany } from '../Profile'

const ratios = {
  'cm': 1,
  'm': 0.000001,
  'ft': 0.0000353,
  'in': 0.0610237,
}

function Volume ({ isMeter, value }) {
  const { company } = useCompany()
  const sizeUnit = propOr('cm', 'sizeUnit', company)
  const { t } = useTranslation()
  const unit = isMeter && sizeUnit === 'cm' ? 'm' : sizeUnit
  const ratio = prop(unit, ratios)

  const dimension = Number(value * ratio).toFixed(0)

  return (
    <>{dimension}&nbsp;{t(unit)}<sup>3</sup></>
  )
}

Volume.defaultProps = {
  value: 0
}

Volume.propTypes = {
  value: PropTypes.number.isRequired,
  isMeter: PropTypes.bool
}

export default Volume
