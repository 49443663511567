import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'storfox-snackbar'
import { useTranslation } from 'react-i18next'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'

import { ACTIVE } from '../../constants/ContainerType'
import { useContainerTypeCreate } from '../../hooks/ContainerType'
import { ContainerTypeCreateSerializer } from '../../serializers/ContainerType'
import ContainerTypeCreateForm from '../../components/ContainerType/ContainerTypeCreate'

const initialValues = { status: ACTIVE }

function ContainerTypeCreateContainer () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const containerTypeCreate = useContainerTypeCreate()

  const message = t('Successfully created')
  const handleSubmit = formValues =>
    containerTypeCreate.create(ContainerTypeCreateSerializer(formValues))
      .then(() => snackbar({ message }))
      .then(() => navigate(ROUTES.CONTAINER_TYPE_LIST_PATH))

  const title = 'New container Type'

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      isLoading={containerTypeCreate.isLoading}
      activeNav={NAV.CONFIGURATION}
      breadcrumbs={breadcrumbs}
    >
      <ContainerTypeCreateForm
        pageTitle={title}
        pageTitleLoading={false}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        isLoading={containerTypeCreate.isLoading}
      />
    </DashboardLayout>
  )
}

export default ContainerTypeCreateContainer
