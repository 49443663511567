import palette from '../palette'

export default primaryColor => ({
  styleOverrides: {
    background: {
      color: palette.background.paper
    },
    item: {
      color: '#fff'
    },
    icon: {
      color: '#fff'
    },
    itemLeaf: {
      color: 'rgba(255, 255, 255, 0.65)'
    },
    disabled: {
      color: 'rgba(255, 255, 255, 0.35)'
    },
    border: {
      color: 'rgba(255, 255, 255, .12)'
    },
    active: {
      color: '#171350'
    }
  }
})
