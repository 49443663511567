import React from 'react'
import { pathOr, pipe, prop } from 'ramda'
import { toCamelCase } from 'storfox-api-hooks'
import Link from '@mui/material/Link'
import { generatePath, Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import printJS from 'print-js'
import Box from '@mui/material/Box'
import { styled } from '@mui/material'

import { unescapeBtoa, generateCdnUrl } from '~/utils'
import {
  ADJUSTMENT_DETAIL_TABS,
  PACKING_DETAIL_TABS,
  PICKLIST_TABS,
  PUTAWAY_DETAIL_TABS,
  SALE_ORDER_DETAIL_TABS
} from '~/constants/tabs'
import {
  ADJUSTMENT_COMPLETE,
  ADJUSTMENT_BULK_VALIDATE_COMPLETE,
  LINK,
  PACKING_ASSIGN,
  PUTAWAY,
  PICKING_ASSIGN,
  PRODUCT_BULK_UPLOAD_VALIDATION_COMPLETE,
  PURCHASE_ORDERS_BULK_VALIDATE_COMPLETE,
  SALE_ORDER_FAILED,
  SALE_ORDER_BULK_VALIDATE_COMPLETE,
  SHOPIFY_PRODUCTS_SYNC_STATUS,
  WAREHOUSE_CREATE,
  WAREHOUSE_BULK_VALIDATE_COMPLETE,
  SALE_ORDER_ALLOCATION
} from '~/constants/notification-topics'
import * as ROUTES from '~/constants/routes'
import { CopyButton } from '~/components/Buttons'
import TextOverflow from '~/components/TextOverflow'

const BoxStyled = styled(Box)({
  display: 'flex',
})

const TextButtonStyled = styled(TextOverflow)({
  textDecoration: 'underline',
  marginLeft: '10px',
  cursor: 'pointer'
})

const NotificationLink = ({ children, to, ...props }) => (
  <Link
    target="_blank"
    rel="noopener noreferrer"
    component={RouterLink}
    to={to}
    variant="subtitle2"
    underline="always"
    {...props}
  >
    {children}
  </Link>
)

const getPayload = data => {
  try {
    return pipe(
      prop('payload'),
      JSON.parse,
      toCamelCase
    )(data)
  } catch (e) {}
}

const Content = ({ type, payload }) => {
  switch (type) {
    case LINK: {
      const url = generateCdnUrl(prop('url', payload))

      return (
        <BoxStyled>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            component="a"
            href={url}
            variant="subtitle2"
            underline="always"
          >
            Download
          </Link>
          <TextButtonStyled onClick={() => printJS({ printable: url, showModal: true, type: 'pdf' })}>
            Print
          </TextButtonStyled>
        </BoxStyled>
      )
    }
    case PUTAWAY: {
      const guid = prop('objectGuid', payload)
      const url = generatePath(ROUTES.V2_PUTAWAY_DETAIL_PATH, { guid, tab: PUTAWAY_DETAIL_TABS.GENERAL })

      return (
        <NotificationLink to={url}>
          Open putaway
        </NotificationLink>
      )
    }
    case SALE_ORDER_ALLOCATION: {
      const guid = prop('key', payload)
      const url = generatePath(ROUTES.SALE_ORDER_DETAIL_PATH, { guid, tab: SALE_ORDER_DETAIL_TABS.GENERAL })

      return (
        <NotificationLink to={url}>
          Open sale order
        </NotificationLink>
      )
    }
    case PACKING_ASSIGN: {
      const id = prop('objectId', payload)
      const url = generatePath(ROUTES.PACKING_DETAIL_PATH, { id, tab: PACKING_DETAIL_TABS.GENERAL })

      return (
        <NotificationLink to={url}>
          Open packing
        </NotificationLink>
      )
    }
    case PICKING_ASSIGN: {
      const guid = prop('objectGuid', payload)
      const url = guid && generatePath(ROUTES.PICKLIST_UPDATE_PATH, { guid, tab: PICKLIST_TABS.GENERAL })

      return guid ? (
        <NotificationLink to={url}>
          Open picking
        </NotificationLink>
      ) : null
    }

    case SALE_ORDER_FAILED: {
      return (
        <CopyButton
          textToCopy={JSON.stringify(payload)}
        >
          Copy log
        </CopyButton>
      )
    }

    case ADJUSTMENT_COMPLETE: {
      const id = prop('objectId', payload)
      const url = generatePath(ROUTES.ADJUSTMENT_DETAIL_PATH, { id, tab: ADJUSTMENT_DETAIL_TABS.GENERAL })

      return (
        <NotificationLink to={url}>
          See completed adjustment
        </NotificationLink>
      )
    }

    case PRODUCT_BULK_UPLOAD_VALIDATION_COMPLETE: {
      const url = `${ROUTES.PRODUCT_IMPORT_PATH}?payload=${unescapeBtoa(toCamelCase(payload))}`
      const key = prop('key', payload)

      return (
        <NotificationLink to={url}>
          See details {key}
        </NotificationLink>
      )
    }

    case ADJUSTMENT_BULK_VALIDATE_COMPLETE: {
      const url = `${ROUTES.ADJUSTMENT_IMPORT_PATH}?payload=${unescapeBtoa(toCamelCase(payload))}`

      return (
        <NotificationLink to={url}>
          See details
        </NotificationLink>
      )
    }

    case WAREHOUSE_CREATE: {
      const warehouseGuid = prop('objectGuid', payload)
      const url = generatePath(ROUTES.WAREHOUSE_UPDATE_PATH, { guid: warehouseGuid })

      return (
        <NotificationLink to={url}>
          See warehouse
        </NotificationLink>
      )
    }

    case PURCHASE_ORDERS_BULK_VALIDATE_COMPLETE: {
      const url = `${ROUTES.PURCHASE_ORDER_IMPORT_PATH}?payload=${unescapeBtoa(toCamelCase(payload))}`

      return (
        <NotificationLink to={url}>
          See details
        </NotificationLink>
      )
    }

    case WAREHOUSE_BULK_VALIDATE_COMPLETE: {
      const url = `${ROUTES.WAREHOUSE_IMPORT_PATH}?payload=${unescapeBtoa(toCamelCase(payload))}`

      return (
        <NotificationLink to={url}>
          See details
        </NotificationLink>
      )
    }

    case SALE_ORDER_BULK_VALIDATE_COMPLETE: {
      const url = `${ROUTES.SALE_ORDER_IMPORT_PATH}?payload=${unescapeBtoa(toCamelCase(payload))}`

      return (
        <NotificationLink to={url}>
          See details
        </NotificationLink>
      )
    }

    case SHOPIFY_PRODUCTS_SYNC_STATUS: {
      const productsCount = pathOr(0, ['result', 'fetched'], payload)

      return (
        <>
          {`${productsCount} products were synced`}
        </>
      )
    }
    default:
      return <></>
  }
}

const AdditionalContent = ({ data }) => {
  const payload = getPayload(data)
  const type = prop('type', data)

  return (
    <Content type={type} payload={payload} />
  )
}

NotificationLink.propTypes = {
  children: PropTypes.any.isRequired,
  to: PropTypes.string.isRequired
}

Content.propTypes = {
  type: PropTypes.string.isRequired,
  payload: PropTypes.object
}

AdditionalContent.propTypes = {
  data: PropTypes.object.isRequired
}

export default AdditionalContent
