import React from 'react'
import { useField } from 'react-final-form'
import { useDeepCompareEffect } from 'storfox-api-hooks'
import PropTypes from 'prop-types'

import MultiSelectSearchField from '~/components/Fields/MultiSelectSearchField'
import * as API from '~/constants/api'

const getOptionValue = item => {
  if (item) {
    const { id, locationId, guid } = item

    return { id, name: locationId, guid }
  }

  return null
}

function LocationMultiSelectField ({ name, parentName, ...props }) {
  const field = useField(name)
  const parentField = useField(parentName)
  const parent = parentField.input.value

  useDeepCompareEffect(() => {
    if (parentField.meta.dirty) {
      field.input.onChange([])
    }
  }, [parent])

  return (
    <MultiSelectSearchField
      api={API.LOCATION_LIST}
      data-cy="locations"
      name={name}
      disabled={!parent}
      label="Locations"
      getOptionValue={getOptionValue}
      {...props}
    />
  )
}

LocationMultiSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired
}

export default LocationMultiSelectField
