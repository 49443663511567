import React from 'react'
import { useFilter, useOrdering } from 'storfox-filter'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'
import { useRequest } from 'storfox-api-hooks'
import { getDataFromError } from 'storfox-api-hooks/src/api/utils'
import { sprintf } from 'sprintf-js'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import useMessages from '~/hooks/useMessages'
import ErrorLink from '~/components/ErrorLink'
import { useTableSelects } from '~/components/TableValues/hooks'
import { getNonFieldError } from '~/components/Form'
import * as API from '~/constants/api'

import {
  AdjustmentFilterForm,
  adjustmentFilterOptions,
  AdjustmentOrderingForm,
  adjustmentOrderingOptions,
  AdjustmentTable
} from '../components/AdjustmentList'
import { useAdjustmentList, useAdjustmentsDelete } from '../hooks'

function AdjustmentListContainer () {
  const snackbar = useSnackbar()
  const messages = useMessages()
  const request = useRequest()

  const adjustmentList = useAdjustmentList()
  const adjustmentRemove = useAdjustmentsDelete()
  const { selects, resetTableSelects } = useTableSelects()

  const filter = useFilter(adjustmentFilterOptions)
  const ordering = useOrdering(adjustmentOrderingOptions)

  const handleAdjustmentsDelete = () =>
    adjustmentRemove.delete({ guids: selects })
      .then(() => snackbar({ message: messages.DELETE_SUCCESS }))
      .then(() => resetTableSelects())
      .then(() => adjustmentList.getList())
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const handleGenerateReport = id => {
    const url = sprintf(API.ADJUSTMENT_GENERATE_REPORT, id)
    return request.post(url).then(() => snackbar({ message: messages.NOTIFICATION_WAIT }))
  }

  const handleCancel = id => {
    const url = sprintf(API.ADJUSTMENT_CANCEL, id)
    request.put(url)
      .then(() => snackbar({ message: messages.CANCEL_SUCCESS }))
      .then(() => adjustmentList.getList())
      .catch(response => {
        const error = getDataFromError(response)
        return Promise.reject(error)
      })
      .catch(err => {
        const errorMessage = getNonFieldError(err)
        snackbar({ message: errorMessage, type: ALTER_ERROR })
        throw err
      })
  }

  const breadcrumbs = { title: NAV.ADJUSTMENT }

  return (
    <DashboardLayout
      title={NAV.ADJUSTMENT}
      activeNav={NAV.ADJUSTMENT}
      breadcrumbs={breadcrumbs}
    >
      <AdjustmentFilterForm {...filter} />
      <AdjustmentOrderingForm {...ordering} />

      <AdjustmentTable
        list={adjustmentList}
        filter={filter}
        ordering={ordering}
        onListRefetch={adjustmentList.getListByParams}
        onAdjustmentsDelete={handleAdjustmentsDelete}
        onGenerateReport={handleGenerateReport}
        onCancel={handleCancel}
        bulkActionLoading={adjustmentRemove.isLoading}
      />
    </DashboardLayout>
  )
}

export default AdjustmentListContainer
