import React from 'react'
import MuiButton from '@mui/material/Button'
import { useTranslation } from 'react-i18next'
import { is } from 'ramda'
import PropTypes from 'prop-types'

const Button = React.forwardRef(({ children, ...props }, ref) => {
  const { t } = useTranslation()

  const content = is(String, children) ? t(children) : children

  return (
    <MuiButton ref={ref} {...props}>
      {content}
    </MuiButton>
  )
})

Button.propTypes = {
  variant: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.any.isRequired
}

export default Button
