import { useDeepCompareEffect, useList } from '../index'

const KEY = 'list-count'

export const getCount = () => localStorage.getItem(KEY) || 0
const setCount = value => localStorage.setItem(KEY, value)

function useLimitedCountList (api, searchParams, autoSend = true, cancelToken) {
  const { count, ...params } = searchParams

  const {
    count: listCount = 0,
    getList,
    getListByParams,
    results,
    isLoading,
    summary,
    hasNextPage,
    hasPrevPage
  } = useList(api, params, autoSend, cancelToken)

  useDeepCompareEffect(() => {
    setCount(listCount)
  }, [listCount])

  return { getList, count: listCount, getListByParams, results, isLoading, summary, hasNextPage, hasPrevPage }
}

export default useLimitedCountList
