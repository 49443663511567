import { sprintf } from 'sprintf-js'
import { useCreate, useDetail, useLimitedCountList, useUpdate } from 'storfox-api-hooks'
import { useAllSearchParams } from 'storfox-route-hooks'

import { queryToParams } from '~/utils'
import * as API from '~/constants/api'

import { TplBillingFilterSerializer } from '../../serializers/TplBilling'

export const useTplBillingList = () => {
  const searchParams = useAllSearchParams()
  const params = queryToParams(TplBillingFilterSerializer, searchParams)

  return useLimitedCountList(API.TPL_BILLING_LIST, params)
}

export const useTplBillingsExport = () => {
  const { create, ...rest } = useCreate(API.TPL_BILLING_GENERATE_EXCEL)

  return { ...rest, export: create }
}

export const useTplBillingDetail = guid => {
  const url = sprintf(API.TPL_BILLING_DETAIL, guid)

  return useDetail(url)
}

export const useTplBillingMarkAsPaid = guid => {
  const url = sprintf(API.TPL_BILLING_MARK_AS_PAID, guid)
  const { update, ...state } = useUpdate(url)

  return { ...state, mark: update }
}

export const useTplBillingStorageDetail = (guid, autoSend) => {
  const url = sprintf(API.TPL_BILLING_STORAGE_DETAIL, guid)

  return useDetail(url, {}, autoSend)
}

export const useTplBillingFulfillmentDetail = (guid, autoSend) => {
  const url = sprintf(API.TPL_BILLING_FULFILLMENT_DETAIL, guid)

  return useDetail(url, {}, autoSend)
}

export const useTplBillingPackingDetail = (guid, autoSend) => {
  const url = sprintf(API.TPL_BILLING_PACKING_DETAIL, guid)

  return useDetail(url, {}, autoSend)
}

export const useTplBillingAdhocDetail = (guid, autoSend) => {
  const url = sprintf(API.TPL_BILLING_ADHOC_DETAIL, guid)

  return useDetail(url, {}, autoSend)
}

export const useTplBillingReceivingDetail = (guid, autoSend) => {
  const url = sprintf(API.TPL_BILLING_RECEIVING_DETAIL, guid)

  return useDetail(url, {}, autoSend)
}
