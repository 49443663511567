import React from 'react'
import PropTypes from 'prop-types'

import Status from '~/components/Status'
import { SHIPMENT_STATUSES, SHIPMENT_STATUS_NAMES, SHIPMENT_STATUS_COLORS } from '~/constants/statuses'

function ShipmentStatus ({ value }) {
  return (
    <Status
      label={SHIPMENT_STATUS_NAMES[value]}
      color={SHIPMENT_STATUS_COLORS[value]}
    />
  )
}

ShipmentStatus.propTypes = {
  value: PropTypes.oneOf([
    SHIPMENT_STATUSES.DELIVERED,
    SHIPMENT_STATUSES.UNSHIPPED,
    SHIPMENT_STATUSES.PRE_TRANSIT,
    SHIPMENT_STATUSES.IN_TRANSIT,
    SHIPMENT_STATUSES.RETURNED,
    SHIPMENT_STATUSES.FAILED,
    SHIPMENT_STATUSES.CANCELLED
  ]).isRequired
}

export default ShipmentStatus
