import React from 'react'
import { Ordering } from 'storfox-filter'

import { BUNDLE_COLUMNS as tableColumns } from './BundleTable'

import { FILTER_NAME as name } from '../../constants'

export const bundleOrderingOptions = {
  name,
  tableColumns
}

function BundleOrderingForm (props) {
  return (
    <Ordering {...props} />
  )
}

export default BundleOrderingForm
