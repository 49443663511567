import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'

const RootStyled = styled(Box, {
  shouldForwardProp: propName => propName !== 'topSpace' && propName !== 'bottomSpace'
})(({ theme, topSpace, bottomSpace }) => ({
  padding: theme.spacing(1, 4),
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(1, 2)
  },
  ...(topSpace && ({
    paddingTop: theme.spacing(3)
  })),
  ...(bottomSpace && ({
    paddingBottom: theme.spacing(3)
  }))
}))

function FluidContent ({ children, space, bottomSpace }) {
  return (
    <RootStyled topSpace={space} bottomSpace={bottomSpace}>
      {children}
    </RootStyled>
  )
}

FluidContent.defaultProps = {
  space: false,
  bottomSpace: false
}

FluidContent.propTypes = {
  children: PropTypes.any.isRequired,
  space: PropTypes.bool,
  bottomSpace: PropTypes.bool
}

export default FluidContent
