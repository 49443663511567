import React from 'react'

import SelectField from './SelectField'

const options = [
  { value: 'g', name: 'Gram (g)' },
  { value: 'kg', name: 'Kilogram (kg)' },
  { value: 'oz', name: 'Ounce (oz)' },
  { value: 'lb', name: 'Pound (lb)' },
]

function WeightUnitField ({ ...props }) {
  return (
    <SelectField
      data-cy="weightUnit"
      name="weightUnit"
      label="Weight Unit"
      options={options}
      {...props}
    />
  )
}

export default WeightUnitField
