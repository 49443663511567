import React, { useState } from 'react'
import PropTypes from 'prop-types'
import isEqual from 'react-fast-compare'
import IconButton from '@mui/material/IconButton'
import MUITextField from '@mui/material/TextField'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import FieldWrapper from './FieldWrapper'

function PasswordField ({ input, meta, iconSize, ...defaultProps }) {
  const [visible, setVisible] = useState(false)

  return (
    <MUITextField
      type={visible ? 'text' : 'password'}
      onChange={event => input.onChange(event.target.value)}
      InputProps={{
        endAdornment: (
          <IconButton onClick={() => setVisible(!visible)} size={iconSize}>
            {visible ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        ),
        sx: { fontSize: '16px' }
      }}
      error={meta.invalid}
      value={input.value}
      helperText={meta.submitError || meta.error}
      {...defaultProps}
    />
  )
}

PasswordField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  size: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  iconSize: PropTypes.string
}

PasswordField.defaultProps = {
  size: 'small',
  fullWidth: true,
  variant: 'outlined',
  iconSize: 'medium'
}

export default FieldWrapper(React.memo(PasswordField, isEqual))
