import React from 'react'
import { prop } from 'ramda'
import { generatePath } from 'react-router-dom'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'

import Container, { Content, Header } from '~/components/Container'
import PageTitle from '~/components/PageTitle'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'

import TemplateCard from './TemplateCard'
import TemplateSkeletonCards from './TemplateSkeletonCards'

function TemplateList ({ list }) {
  const results = prop('results', list)
  const isLoading = prop('isLoading', list)

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={NAV.CONFIGURATION}
          parentTitle={NAV.SETTINGS}
        />
      </Header>

      <Content>

        <Grid container={true} spacing={3}>
          {isLoading
            ? <TemplateSkeletonCards />
            : results.map(result => {
              const name = prop('name', result)
              const description = prop('description', result)
              const type = prop('type', result)

              const path = type && generatePath(ROUTES.TEMPLATE_UPDATE_PATH, { type })

              return (
                <Grid key={result.type} item={true} xs={12} sm={6} md={4} lg={3}>
                  <TemplateCard
                    title={name}
                    path={path}
                    description={description}
                  />
                </Grid>
              )
            })}
        </Grid>
      </Content>
    </Container>
  )
}
TemplateList.propTypes = {
  list : PropTypes.object.isRequired
}
export default TemplateList
