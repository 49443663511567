import React from 'react'
import Skeleton from '@mui/material/Skeleton'

function EmptyTextLabelLoader () {
  return (
    <>
      <Skeleton variant="text" width={100} height={23} />
      <Skeleton variant="text" width={150} height={26} />
    </>
  )
}

export default EmptyTextLabelLoader
