import { useEffect, useState } from 'react'

function useShadow (apiRef, { rowWidth }) {
  const [tableViewportWidth, setTableViewportWidth] = useState(0)
  const [isLeftShadow, setIsLeftShadow] = useState(false)
  const [isRightShadow, setIsRightShadow] = useState(true)

  useEffect(() => {
    const handleViewportWidthChange = params => {
      setTableViewportWidth(params.width)
    }

    return apiRef.current.subscribeEvent('viewportInnerSizeChange', handleViewportWidthChange)
  }, [apiRef, rowWidth])

  useEffect(() => {
    const handleScroll = params => {
      const left = params.left
      const right = Math.ceil(left + tableViewportWidth)

      if (left === 0 && isLeftShadow) {
        setIsLeftShadow(false)
      }

      if (left > 0 && !isLeftShadow) {
        setIsLeftShadow(true)
      }

      if (right >= rowWidth && isRightShadow) {
        setIsRightShadow(false)
      }

      if (right < rowWidth && !isRightShadow) {
        setIsRightShadow(true)
      }
    }

    return apiRef.current.subscribeEvent('rowsScroll', handleScroll)
  }, [apiRef, isLeftShadow, isRightShadow, rowWidth, tableViewportWidth])

  return { isLeftShadow, isRightShadow }
}

export default useShadow
