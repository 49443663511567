import React from 'react'
import { prop } from 'ramda'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import { styled } from '@mui/material'

import TableDateFormat from '~/components/TableDateFormat'
import NotificationContent from '~/components/NotificationContent/NotificationContent'

const DotBoxStyled = styled(Box)(({ theme, isUnread }) => ({
  marginLeft: 8,
  borderRadius: '50%',
  width: 8,
  height: 8,
  background: theme.palette.white,
  border: `1px solid ${theme.palette.grey[500]}`,
  ...(isUnread && {
    background: theme.palette.success.main
  })
}))

function NotificationItem ({ item, hasDivider }) {
  const title = prop('title', item)
  const body = prop('body', item)
  const isRead = prop('isRead', item)
  const createdAt = <TableDateFormat withTime={true} date={prop('createdAt', item)} />

  return (
    <Box position="relative">
      <Box display="flex" justifyContent="space-between">
        <Box display="flex">
          <Typography variant="h6" gutterBottom={true}>{title}</Typography>
          <Typography sx={{ ml: 1 }} variant="subtitle2">{createdAt}</Typography>
        </Box>
        <DotBoxStyled
          title={isRead ? 'Already read' : 'Unread notification'}
          isUnread={!isRead}
        />
      </Box>
      <Typography variant="subtitle2" gutterBottom={true}>{body}</Typography>
      <NotificationContent data={prop('data', item)} />

      {hasDivider && <Divider sx={{ mt: 2, mb: 2 }} />}
    </Box>
  )
}

NotificationItem.propTypes = {
  item: PropTypes.object.isRequired,
  hasDivider: PropTypes.bool
}

export default NotificationItem
