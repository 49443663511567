import palette from '../palette'

export default {
  styleOverrides: {
    root: {
      color: palette.icon.primary,
      '&:hover': {
        backgroundColor: 'rgba(208, 208, 208, 0.20)'
      },
      '&.Mui-selected': {
        backgroundColor: 'rgba(208, 208, 208, 0.20)',
        color: palette.primary.main,
        '&:hover': {
          backgroundColor: 'rgba(208, 208, 208, 0.30)',
        }
      },
      '&:first-of-type': {
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4
      },
      '&:last-child': {
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4
      }
    }
  }
}
