import palette from '../palette'

export default primaryColor => ({
  styleOverrides: {
    root: {},
    background: {
      color: primaryColor
    },
    item: {
      color: 'white'
    },
    itemLeaf: {
      color: 'rgba(255, 255, 255, 0.65)'
    },
    icon: {
      color: 'white'
    },
    disabled: {
      color: 'rgba(255, 255, 255, 0.35)'
    },
    border: {
      color: palette.divider
    },
    active: {
      color: '#171350'
    }
  }
})
