import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import ApiIntegrationCreateContainer from './ApiIntegrationCreateContainer'
import ApiIntegrationUpdateContainer from './ApiIntegrationUpdateContainer'

export default () => {
  return [
    {
      layout: AuthLayout,
      path: ROUTES.API_INTEGRATION_CREATE_PATH,
      component: ApiIntegrationCreateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.API_INTEGRATION_UPDATE_PATH,
      component: ApiIntegrationUpdateContainer
    }
  ]
}
