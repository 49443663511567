import React from 'react'
import PropTypes from 'prop-types'

import Status from '~/components/Status'

const YES = 'Yes'
const NO = 'No'

const COLORS = {
  [YES]: 'success',
  [NO]: 'error'
}

function AvailabilityStatus ({ value }) {
  const label = value ? YES : NO
  return (
    <Status label={label} color={COLORS[label]} />
  )
}

export default AvailabilityStatus

AvailabilityStatus.propTypes = {
  value: PropTypes.bool.isRequired
}
