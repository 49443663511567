import React from 'react'
import PropTypes from 'prop-types'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'

import { CardHeader } from '~/components/Cards'
import SingleImageUploadField from '~/components/Fields/SingleImageUploadField'
import TextField from '~/components/Fields/TextField'
import { DiscardButton, SaveButton } from '~/components/Buttons'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import { withForm } from '~/components/Form'
import * as NAV from '~/constants/nav-titles'
import usePerm from '~/hooks/usePerm'
import { BRAND_ADD } from '~/constants/permissions'

export const BRAND_CREATE_FIELDS = [
  'name',
  'slug',
  'imagePath'
]

function BrandCreateForm ({ title, form, isLoading, pageTitleLoading }) {
  const { t } = useTranslation()
  const { handleSubmit } = form
  const { hasPerm } = usePerm()
  const disabled = !hasPerm(BRAND_ADD) || isLoading

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={title}
            parentTitle={t(NAV.BRANDS)}
            pageTitleLoading={pageTitleLoading}
            rightButton={(
              <>
                <DiscardButton disabled={isLoading} />
                <SaveButton data-cy="save" disabled={disabled} />
              </>
            )}
          />
        </Header>

        <Content>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12} lg={8}>
              <Box mb={3}>
                <Card>
                  <CardHeader title={t('Details')} />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12}>
                        <TextField
                          data-cy="name"
                          name="name"
                          label={t('Name')}
                        />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <TextField
                          data-cy="slug"
                          name="slug"
                          label="Slug"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
            <Grid item={true} xs={12} lg={4}>
              <Box mb={3}>
                <Card>
                  <CardHeader title="Image" />
                  <Divider />
                  <CardContent>
                    <SingleImageUploadField name="imagePath" accept="image/png, image/jpeg" />
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
          <Box mb={3} />
        </Content>
      </Container>
    </form>
  )
}

BrandCreateForm.propTypes = {
  title: PropTypes.string,
  pageTitleLoading: PropTypes.bool,
  form: PropTypes.object,
  isLoading: PropTypes.bool.isRequired
}

export default withForm(BrandCreateForm)
