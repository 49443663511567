import { useContext } from 'react'

import Context from './context'

export const useNotificationCount = () => {
  const { count, onCountReset } = useContext(Context)

  return { count, onCountReset }
}

export const useNotificationMethods = () => {
  const { count, ...methods } = useContext(Context)

  return methods
}
