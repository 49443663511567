import React from 'react'
import PropTypes from 'prop-types'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

import Tabs from '~/components/Tabs'
import { PRODUCT_DETAIL_PATH } from '~/constants/routes'
import { PRODUCT_DETAIL_TABS } from '~/constants/tabs'

function ProductDetailTabs ({ count, value }) {
  const navigate = useNavigate()
  const { id } = useParams()
  const onChange = (event, tab) => {
    const redirect = generatePath(PRODUCT_DETAIL_PATH, { id, tab })

    navigate(redirect, { replace: true })
  }

  const tabs = [
    { value: PRODUCT_DETAIL_TABS.GENERAL, label: 'General' },
    { value: PRODUCT_DETAIL_TABS.VARIANTS, label: `Variants (${count})` },
    { value: PRODUCT_DETAIL_TABS.HISTORY, label: `History` }
  ]

  return (
    <Tabs
      tabs={tabs}
      value={value}
      onChange={onChange}
    />
  )
}

ProductDetailTabs.propTypes = {
  value: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
}

export default ProductDetailTabs
