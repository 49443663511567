import React from 'react'
import PropTypes from 'prop-types'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { useTranslation } from 'react-i18next'

import { MoreButton } from '~/components/Buttons'

function More (props) {
  const {
    purchaseOrderOnCloseDisabled,
    purchaseOrderOnClose,
    purchaseOrderOnCancelDisabled,
    purchaseOrderOnCancel,
    markAsPaidDisabled,
    onMarkAsPaid,
    onGatepassGenerate,
    onInvoiceGenerate,
    onEmailSend,
    onDownload,
    onReturnCreate,
    returnDisabled
  } = props

  const { t } = useTranslation()

  return (
    <>
      <MoreButton>
        {onClose => (
          <List>
            <ListItem
              disabled={purchaseOrderOnCloseDisabled}
              button={true}
              onClick={() => {
                purchaseOrderOnClose()
                onClose()
              }}
            >
              <ListItemText primary={t('Close')} />
            </ListItem>
            <ListItem
              disabled={purchaseOrderOnCancelDisabled}
              button={true}
              onClick={() => {
                purchaseOrderOnCancel()
                onClose()
              }}
            >
              <ListItemText primary={t('Cancel')} />
            </ListItem>
            <ListItem
              button={true}
              onClick={() => {
                onDownload()
                onClose()
              }}
            >
              <ListItemText primary={t('Download')} />
            </ListItem>
            <ListItem
              button={true}
              onClick={() => {
                onEmailSend()
                onClose()
              }}>
              <ListItemText primary={t('Email to supplier')} />
            </ListItem>
            <ListItem
              button={true}
              onClick={() => {
                onGatepassGenerate()
                onClose()
              }}>
              <ListItemText primary={t('Generate Inbound Gate Pass')} />
            </ListItem>
            <ListItem
              button={true}
              onClick={() => {
                onInvoiceGenerate()
                onClose()
              }}>
              <ListItemText primary={t('Generate Invoice Receipt')} />
            </ListItem>
            <ListItem
              disabled={markAsPaidDisabled}
              button={true}
              onClick={() => {
                onMarkAsPaid()
                onClose()
              }}
            >
              <ListItemText primary={t('Mark As Paid')} />
            </ListItem>
            <ListItem
              disabled={returnDisabled}
              button={true}
              onClick={() => {
                onReturnCreate()
                onClose()
              }}
              data-cy="requestReturn"
            >
              <ListItemText primary={t('Request Return')} />
            </ListItem>
          </List>
        )}
      </MoreButton>
    </>
  )
}

More.propTypes = {
  purchaseOrderOnCloseDisabled: PropTypes.bool.isRequired,
  purchaseOrderOnCancel: PropTypes.func.isRequired,
  purchaseOrderOnCancelDisabled: PropTypes.bool.isRequired,
  purchaseOrderOnClose: PropTypes.func.isRequired,
  markAsPaidDisabled: PropTypes.bool.isRequired,
  onMarkAsPaid: PropTypes.func.isRequired,
  onInvoiceGenerate: PropTypes.func.isRequired,
  onGatepassGenerate: PropTypes.func.isRequired,
  onEmailSend: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  onReturnCreate: PropTypes.func.isRequired,
  returnDisabled: PropTypes.bool.isRequired
}

export default More
