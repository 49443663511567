import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { path, prop, propOr } from 'ramda'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell/TableCell'
import { styled } from '@mui/material'
import Link from '@mui/material/Link'
import Pagination from 'storfox-table/src/Table/Pagination'

import SkeletonList from '~/components/Skeleton'
import EmptyLineItems from '~/components/EmptyLineItems'
import EmptyNotesIcon from '~/components/Icons/EmptyNotesIcon'
import * as ROUTES from '~/constants/routes'
import { HoverableRow } from '~/components/Table'
import Avatar from '~/components/Avatar'
import VerticalAlignment from '~/components/VerticalAlignment'
import TextOverflow from '~/components/TextOverflow'
import Subtext from '~/components/Subtext'
import CurrencyMoney from '~/components/Money/CurrencyMoney'
import SourceDestinationLink from '~/components/SourceDestinationLink'
import TableDateFormat from '~/components/TableDateFormat/TableDateFormat'

import QuantityChange from './QuantityChange'

const TableRowStyled = styled(HoverableRow)({
  height: '70px'
})

const TableCellStyled = styled(TableCell)({
  minWidth: '150px'
})

const TableContainerStyled = styled(TableContainer)({
  maxHeight: 'calc(100vh - 350px)'
})

const TableHeadStyled = styled(TableHead)({
  position: 'sticky',
  zIndex: 1,
  top: 0
})

function VariantInventoryLog ({ inventoryLog }) {
  const { t } = useTranslation()

  const isLoading = prop('isLoading', inventoryLog)
  const results = prop('results', inventoryLog)
  const count = prop('count', inventoryLog)

  const renderEmptyLines = Boolean(!isLoading && !results.length)
  const renderLineItems = Boolean(!isLoading && results.length)

  return (
    <Box component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto' }}>
      <TableContainerStyled>
        <Table size="small">
          <TableHeadStyled>
            <TableRow>
              <TableCellStyled>{t('Image')}</TableCellStyled>
              <TableCellStyled>{t('Variant')}</TableCellStyled>
              <TableCellStyled>{t('Supply rate')}</TableCellStyled>
              <TableCellStyled>{t('Change')}</TableCellStyled>
              <TableCellStyled>{t('Destination')}</TableCellStyled>
              <TableCellStyled>{t('Location')}</TableCellStyled>
              <TableCellStyled>{t('Warehouse')}</TableCellStyled>
              <TableCellStyled>{t('Available')}</TableCellStyled>
              <TableCellStyled>{t('On hand')}</TableCellStyled>
              <TableCellStyled>{t('Source type')}</TableCellStyled>
              <TableCellStyled>{t('Source number')}</TableCellStyled>
              <TableCellStyled>{t('Created date')}</TableCellStyled>
            </TableRow>
          </TableHeadStyled>
          <TableBody>
            {isLoading && (
              <SkeletonList
                rowCount={3}
                columnCount={13}
                height={20}
                rowHeight={70}
              />
            )}

            {renderEmptyLines && (
              <EmptyLineItems
                colSpan={10}
                icon={<EmptyNotesIcon />}
                text="No units in inventory log."
              />
            )}
            {renderLineItems && results.map(item => {
              const id = prop('id', item)
              const variantName = path(['variant', 'name'], item)
              const variantSku = path(['variant', 'sku'], item)
              const unitGuid = prop('unitGuid', item)
              const image = path(['variant', 'image'], item)
              const quantityChange = prop('quantity', item)
              const price = prop('price', item)

              const currency = prop('currency', item)
              const destination = prop('destination', item)
              const destinationNumber = prop('number', destination)
              const destinationId = prop('id', destination)
              const destinationGuid = prop('guid', destination)
              const destinationType = prop('type', destination)
              const warehouse = path(['location', 'warehouse', 'name'], item)
              const location = path(['location', 'locationId'], item)
              const createdAt = prop('createdAt', item)
              const stockAvailable = prop('stockAvailable', item)
              const stockOnHand = prop('stockOnHand', item)

              const source = prop('source', item)
              const sourceType = propOr('', 'type', source)
              const sourceNumber = propOr('', 'number', source)

              return (
                <TableRowStyled key={id}>
                  <TableCell>
                    <Avatar alt={variantName} src={image} />
                  </TableCell>
                  <TableCell>
                    <VerticalAlignment
                      primary={(
                        <Link
                          target="_blank"
                          href={`${ROUTES.UNIT_HISTORY_PATH}?guid=${unitGuid}`}
                          rel="noopener noreferrer"
                          underline="always"
                        >
                          <TextOverflow selfTooltip={true}>{variantName}</TextOverflow>
                        </Link>
                      )}
                      secondary={<Subtext>{variantSku}</Subtext>}
                    />
                  </TableCell>
                  <TableCell>
                    <CurrencyMoney
                      currency={currency}
                      value={price}
                    />
                  </TableCell>
                  <TableCell>
                    <QuantityChange>{quantityChange}</QuantityChange>
                  </TableCell>
                  <TableCell>
                    <SourceDestinationLink
                      name={destinationNumber}
                      id={destinationId}
                      guid={destinationGuid}
                      type={destinationType}
                    />
                  </TableCell>
                  <TableCell>{location}</TableCell>
                  <TableCell>{warehouse}</TableCell>
                  <TableCell>{stockAvailable}</TableCell>
                  <TableCell>{stockOnHand}</TableCell>
                  <TableCell>{sourceType}</TableCell>
                  <TableCell>{sourceNumber}</TableCell>
                  <TableCell><TableDateFormat withTime={true} date={createdAt} /></TableCell>
                </TableRowStyled>
              )
            })}
          </TableBody>
        </Table>
      </TableContainerStyled>
      <Pagination count={count} prefix="" />
    </Box>
  )
}

VariantInventoryLog.propTypes = {
  inventoryLog: PropTypes.object.isRequired
}

export default VariantInventoryLog
