import React from 'react'
import { Ordering } from 'storfox-filter'

import { REORDER_COLUMNS as tableColumns } from './ReorderTable'

import { FILTER_NAME as name } from '../../constants'

export const reorderOrderingOptions = {
  name,
  tableColumns
}

function ReorderOrderingForm (props) {
  return (
    <Ordering {...props} />
  )
}

export default ReorderOrderingForm
