import { styled } from '@mui/material'
import CardContent from '@mui/material/CardContent'

export const CardContentStyled = styled(CardContent)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))
