import React from 'react'
import PropTypes from 'prop-types'
import QRCode from 'qrcode.react'
import { prop, propOr } from 'ramda'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import { getCodes, multiSku } from '../../../utils'

const QrCodeGenerator = React.forwardRef(({ classes, items, quantity, fontSize }, ref) => {
  const multiQrCodes = multiSku(items, quantity)
  const qrCodes = getCodes(multiQrCodes)

  return (
    <div ref={ref}>
      {qrCodes.map((item, index) => {
        const qrCode = prop('code', item)
        const qrCodeLength = qrCode.length
        const titles = propOr([], 'titles', item)
        const fontSizeResize = qrCodeLength > 40 ? '4px' : qrCodeLength > 20 ? '8px' : `${fontSize}px`
        return (
          <Box key={index} sx={{
            pageBreakAfter: 'always',
            pageBreakInside: 'avoid',
            '& svg': {
              width: '100% !important'
            },
            paddingTop: 2
          }}>
            <QRCode value={qrCode} renderAs="svg" />
            <Typography
              sx={{
                textAlign: 'center',
                paddingTop: 1,
                color:'black',
                fontSize: fontSizeResize,
                fontWeight:'bold'
              }}
            >
              {qrCode}
            </Typography>
            {titles.map((title, index) => (
              <Typography key={index} align="center">
                <strong>{title}</strong>
              </Typography>
            ))}
          </Box>
        )
      })}
    </div>
  )
})

QrCodeGenerator.propTypes = {
  items: PropTypes.array.isRequired,
  classes: PropTypes.object,
  quantity: PropTypes.number.isRequired,
  fontSize: PropTypes.number.isRequired
}

export default QrCodeGenerator
