import React from 'react'
import { FieldArray } from 'react-final-form-arrays'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { useTranslation } from 'react-i18next'
import { defaultTo, prop, propOr } from 'ramda'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import { styled } from '@mui/material'

import PositiveNumberField from '~/components/Fields/PositiveNumberField'

const NoBorderTableRowStyled = styled(TableRow)({
  '& td': {
    height: '50px',
    borderBottom: 'none',
    padding: '6px 24px 6px 16px'
  }
})

function ContainerItems () {
  const { t } = useTranslation()

  return (
    <TableContainer sx={{ maxHeight: '300px' }}>
      <FieldArray
        name="items"
        render={({ fields }) => {
          const values = defaultTo([], fields.value)
          return (
            <Table>
              <TableHead sx={{ position: 'sticky', top: 0, zIndex: 1 }}>
                <TableRow>
                  <TableCell>{t('Container')}</TableCell>
                  <TableCell>{t('Scanned quantity')}</TableCell>
                  <TableCell>{t('Quantity')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.map((item, itemIndex) => {
                  const containers = propOr([], 'containers', item)
                  return containers.map((container, containerIndex) => {
                    const number = prop('number', container)
                    const scannedQuantity = propOr(0, 'scannedQuantity', container)
                    return (
                      <NoBorderTableRowStyled>
                        <TableCell>{number}</TableCell>
                        <TableCell>{scannedQuantity}</TableCell>
                        <TableCell>
                          <PositiveNumberField name={`items[${itemIndex}].containers[${containerIndex}].quantity`} />
                        </TableCell>
                      </NoBorderTableRowStyled>
                    )
                  })
                })}
              </TableBody>
            </Table>
          )
        }}
      />
    </TableContainer>
  )
}

export default ContainerItems
