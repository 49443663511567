import React from 'react'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import PropTypes from 'prop-types'
import { path, prop, propOr } from 'ramda'
import { useTranslation } from 'react-i18next'
import Paper from '@mui/material/Paper'

import SkeletonList from '~/components/Skeleton'
import Money from '~/components/Money'

function TplBillingPackingTable ({ detail, isLoading }) {
  const { t } = useTranslation()
  const containerBills = propOr([], 'containerBills', detail)

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('Container')}</TableCell>
              <TableCell>{t('Container type')}</TableCell>
              <TableCell>{t('Price')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? <SkeletonList rowCount={5} columnCount={11} />
              : containerBills.map((item, index) => {
                const container = path(['container', 'number'], item)
                const containerType = path(['container', 'containerType', 'name'], item)
                const price = prop('price', item)

                return (
                  <TableRow key={index}>
                    <TableCell>{container}</TableCell>
                    <TableCell>{containerType}</TableCell>
                    <TableCell>
                      <Money value={price} />
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

TplBillingPackingTable.propTypes = {
  detail: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default TplBillingPackingTable
