import React from 'react'
import { prop } from 'ramda'
import PropTypes from 'prop-types'

import { useCompany } from '../Profile'

const ratios = {
  'g': 1,
  'kg': 0.001,
  'lb': 0.00220462,
  'oz': 0.035274
}

function Weight ({ value }) {
  const { company } = useCompany()
  const unit = prop('weightUnit', company)
  const ratio = prop(unit, ratios)
  const weight = Number(value * ratio).toFixed(2)

  return (
    <>{weight}&nbsp;{unit}</>
  )
}

Weight.defaultProps = {
  value: 0
}

Weight.propTypes = {
  value: PropTypes.number.isRequired
}

export default Weight
