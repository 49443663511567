import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'storfox-snackbar'
import { useNavigate } from 'react-router-dom'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'

import ContractCreateForm from '../../components/Contract/ContractCreate'
import { useContractCreate } from '../../hooks/Contract'
import { ContractCreateSerializer } from '../../serializers/Contract'

const ContractCreateContainer = () => {
  const contractCreate = useContractCreate()
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const navigate = useNavigate()

  const title = t('New contract')
  const message = t('Successfully created')

  const handleSubmit = formValues =>
    contractCreate.create(ContractCreateSerializer(formValues))
      .then(() => snackbar({ message }))
      .then(() => navigate(ROUTES.CONTRACT_LIST_PATH))

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      activeNav={NAV.CONFIGURATION}
      breadcrumbs={breadcrumbs}
    >
      <ContractCreateForm
        pageTitle={title}
        pageTitleLoading={false}
        isLoading={contractCreate.isLoading}
        onSubmit={handleSubmit}
      />

    </DashboardLayout>
  )
}

export default ContractCreateContainer
