import { defaultTo, map, path, pick, pipe, prop, propOr } from 'ramda'
import { DEFAULT_SEARCH_PARAMS } from 'storfox-route-hooks'

import { escapeAtob } from '~/utils'

import { RETURN_UPDATE_FIELDS } from '../components/ReturnUpdate'
import { RETURN_CREATE_FIELDS } from '../components/ReturnCreate'
import { CUSTOMER_RETURN } from '../constants'

const getLineItems = pipe(
  propOr([], 'lineItems'),
  map(({ quantity, variant, condition }) => ({
    quantity,
    condition: condition && { guid: prop('guid', condition) },
    variant: { guid: prop('guid', variant) },
  }))
)

const getCountry = country => {
  const guid = path(['country', 'guid'], country)

  return guid && { guid }
}

export function ReturnCreateSerializer (formValues) {
  const values = pick(RETURN_CREATE_FIELDS, formValues)
  const notes = prop('notes', values)
  const lineItems = getLineItems(values)
  const orderGuid = path(['order', 'guid'], values)

  const fromAddress = prop('pickupAddress', values)
  const toAddress = prop('deliveryAddress', values)

  const warehouse = prop('warehouse', values)

  const pickupAddress = { ...fromAddress, country: getCountry(fromAddress) }
  const deliveryAddress = { ...toAddress, country: getCountry(toAddress), id: prop('id', warehouse) }

  return {
    lineItems,
    notes,
    pickupAddress,
    deliveryAddress,
    order: orderGuid && { guid: orderGuid },
    warehouse: warehouse && pick(['guid'], warehouse)
  }
}

export function ReturnUpdateSerializer (formValues) {
  const values = pick(RETURN_UPDATE_FIELDS, formValues)
  const carrierGuid = path(['carrier', 'guid'], values)
  const orderGuid = path(['order', 'guid'], values)
  const lineItems = getLineItems(values)
  const guid = prop('guid', formValues)

  const fromAddress = prop('pickupAddress', values)
  const toAddress = prop('deliveryAddress', values)

  const pickupAddress = { ...fromAddress, country: getCountry(fromAddress) }
  const deliveryAddress = { ...toAddress, country: getCountry(toAddress) }

  const warehouse = prop('warehouse', values)

  return {
    ...values,
    lineItems,
    guid,
    pickupAddress,
    deliveryAddress,
    order: orderGuid && { guid: orderGuid },
    carrier: carrierGuid && { guid: carrierGuid },
    warehouse: warehouse && pick(['guid'], warehouse)
  }
}

export const ReturnIntegrationCheckSerializer = ({ integrationGuid, guid, fromAddress }) => {
  return {
    returnGuid: guid,
    integrationGuid,
    fromAddress
  }
}

export const getDeliveryAddress = address => ({
  firstName: prop('contactFirstName', address),
  lastName: prop('contactLastName', address),
  mobile: prop('contactPhoneNumber', address),
  address: prop('address', address),
  region: prop('region', address),
  city: prop('city', address),
  zipcode: prop('zipcode', address),
  country: prop('country', address),
})

export const ReturnInitCreateSerializer = detail => {
  const id = prop('id', detail)
  const guid = prop('guid', detail)
  const pickupAddress = prop('deliveryAddress', detail)
  const paymentMethod = prop('paymentType', detail)
  const number = prop('number', detail)
  const returnTo = path(['warehouse', 'name'], detail)
  const createdAt = prop('createdAt', detail)
  const returnType = CUSTOMER_RETURN
  const warehouse = prop('warehouse', detail)
  const deliveryAddress = getDeliveryAddress(warehouse)

  const order = { id, guid, number, paymentMethod, returnType, returnTo, createdAt }

  return { order, pickupAddress, warehouse, deliveryAddress }
}

const getInitLineItems = pipe(
  propOr([], 'lineItems'),
  map(lineItem => {
    const guid = path(['condition', 'guid'], lineItem)
    const code = path(['condition', 'code'], lineItem)
    const condition = { guid, code }

    return { ...lineItem, condition }
  })
)

export const ReturnInitUpdateSerializer = detail => {
  const pickupAddress = prop('pickupAddress', detail)
  const shipmentAddress = pick(['weight', 'height', 'length', 'width'], detail)
  const paymentMethod = path(['order', 'paymentType'], detail)
  const returnTo = path(['warehouse', 'name'], detail)
  const orderDetail = prop('order', detail)
  const returnType = prop('type', detail)
  const lineItems = getInitLineItems(detail)

  const order = { ...orderDetail, returnType, returnTo, paymentMethod }

  return {
    ...detail,
    order,
    lineItems,
    paymentMethod,
    returnTo,
    pickupAddress,
    shipmentAddress
  }
}

export const ReturnFilterSerializer = params => {
  const defaultParams = pick(DEFAULT_SEARCH_PARAMS, params)
  const status = prop('status', params)
  const createdAtStart = prop('createdAtStart', params)
  const createdAtEnd = prop('createdAtEnd', params)
  const totalPriceStartValue = prop('minTotal', params)
  const totalPriceStart = totalPriceStartValue && totalPriceStartValue.toString()
  const totalPriceEndValue = prop('maxTotal', params)
  const totalPriceEnd = totalPriceEndValue && totalPriceEndValue.toString()
  const warehouses = defaultTo([], escapeAtob(prop('warehouses', params)))
  const customerIds = escapeAtob(prop('customers', params))
  const warehouseGuids = warehouses.length ? map(prop('guid'), warehouses).join(',') : null

  return {
    ...defaultParams,
    status,
    createdAtStart,
    createdAtEnd,
    totalPriceStart,
    totalPriceEnd,
    customerIds,
    warehouseGuids
  }
}
