import React from 'react'
import PropTypes from 'prop-types'
import Storefront from '@mui/icons-material/Storefront'
import Phone from '@mui/icons-material/Phone'

import Shopify from '../Icons/Shopify'
import Magento from '../Icons/Magento'
import Telegram from '../Icons/Telegram'
import Zid from '../Icons/svg/integration/zid.svg'
import Api from '../Icons/svg/integration/api.svg'
import WooCommerce from '../Icons/svg/integration/woocommerce.svg'
import Shipox from '../Icons/svg/integration/shipox.svg'
import Salla from '../Icons/svg/integration/salla.svg'

const SHOPIFY = 'shopify'
const MAGENTO = 'magento'
const TELEGRAM = 'telegram'
const PHONE = 'phone'
const STOREFRONT = 'storefront'
const ZID = 'zid'
const API = 'api'
const WOO_COMMERCE = 'woocommerce'
const SHIPOX = 'shipox'
const SALLA = 'salla'

const ICONS = {
  [SHOPIFY]: Shopify,
  [MAGENTO]: Magento,
  [TELEGRAM]: Telegram,
  [PHONE]: props => <Phone style={{ color: '#546E7A' }} {...props} />,
  [STOREFRONT]: props => <Storefront style={{ color: '#546E7A' }} {...props} />,
  [ZID]: Zid,
  [API]: Api,
  [WOO_COMMERCE]: WooCommerce,
  [SHIPOX]: Shipox,
  [SALLA]: Salla
}

function Channel ({ value }) {
  const Component = ICONS[value]

  if (Component) {
    return <Component style={{ width: 35 }} color="action" />
  }

  return null
}

Channel.propTypes = {
  value: PropTypes.string
}

Channel.defaultProps = {
  value: 'phone'
}

export default Channel
