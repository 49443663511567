import React from 'react'
import PropTypes from 'prop-types'

import Tabs from '~/components/Tabs'

export const PICKER_STATISTIC_TAB = 'picker-view'
export const PACKER_STATISTIC_TAB = 'packer-view'

const tabs = [
  { value: PICKER_STATISTIC_TAB, label: 'Picker' },
  { value: PACKER_STATISTIC_TAB, label: 'Packer' }
]

function PickerPackerTabs ({ value, onChange }) {
  return (
    <Tabs
      tabs={tabs}
      value={value}
      onChange={onChange}
    />
  )
}

PickerPackerTabs.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func
}

export default PickerPackerTabs
