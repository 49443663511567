import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import { path, prop, propOr } from 'ramda'
import { generatePath } from 'react-router-dom'

import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import { DiscardButton } from '~/components/Buttons'
import { CardHeader } from '~/components/Cards'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import { PAYMENT_TYPES } from '~/constants/paymentTypes'
import NotesCard from '~/components/NotesCard'
import TextLabelLoader from '~/components/TextLabelLoader'
import { CUSTOMER_DETAIL_TABS } from '~/constants/tabs'
import LoaderProvider from '~/components/Loader'

import CustomerInfoRow from './CustomerInfoRow'
import Statuses from './Statuses'

import LineItems from '../LineItems'
import InvoiceSummary from '../InvoiceSummary'

function InvoiceDetail (props) {
  const {
    pageTitle,
    pageTitleLoading,
    isLoading,
    values
  } = props

  const order = prop('order', values)
  const customer = propOr({}, 'customer', order)
  const lineItems = prop('lineItems', order)

  const customerGuid = prop('guid', customer)

  const params = { guid: customerGuid, tab: CUSTOMER_DETAIL_TABS.GENERAL }
  const customerLink = customerGuid && generatePath(ROUTES.CUSTOMER_DETAIL_PATH, params)

  return (
    <LoaderProvider isLoading={isLoading}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={pageTitle}
            pageTitleLoading={pageTitleLoading}
            parentTitle={NAV.INVOICES}
            rightButton={<DiscardButton disabled={isLoading} />}
          />
        </Header>
        <Content>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12} lg={8}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="stretch"
                height="100%"
              >
                <Box mb={3}>
                  <Statuses order={order} isLoading={isLoading} />
                </Box>
                <Box flexGrow={1}>
                  <Card style={{ height: '100%' }}>
                    <CardHeader title="Customer" />
                    <Divider />
                    <CardContent>
                      <CustomerInfoRow customer={customer} link={customerLink} />
                    </CardContent>
                  </Card>
                </Box>
              </Box>
            </Grid>
            <Grid item={true} xs={12} lg={4}>
              <Card style={{ height: '100%' }}>
                <CardHeader title="Details" />
                <Divider />
                <CardContent style={{ height: '100%' }}>
                  <Grid container={true} spacing={3}>
                    <Grid item={true} xs={12}>
                      <TextLabelLoader label="Warehouse">
                        {path(['warehouse', 'name'], order)}
                      </TextLabelLoader>
                    </Grid>

                    <Grid item={true} xs={12}>
                      <TextLabelLoader label="Channel">
                        {path(['channel', 'name'], order)}
                      </TextLabelLoader>
                    </Grid>

                    <Grid item={true} xs={12}>
                      <TextLabelLoader label="Payment type">
                        {PAYMENT_TYPES[prop('paymentType', order)]}
                      </TextLabelLoader>
                    </Grid>

                    <Grid item={true} xs={12}>
                      <TextLabelLoader label="Reference number">
                        {prop('referenceNumber', order)}
                      </TextLabelLoader>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Box mb={3} />

          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12}>
              <Card>
                <CardHeader title="Line items" />
                <LineItems lineItems={lineItems} />
              </Card>
            </Grid>
            <Grid item={true} xs={12} lg={6}>
              <Card>
                <CardHeader title="Invoice summary" />
                <Divider />
                <InvoiceSummary lineItems={lineItems} />
              </Card>
            </Grid>

            <Grid item={true} xs={12} lg={6}>
              <NotesCard title="Customer notes" notes={prop('customerNotes', values)} />
            </Grid>

          </Grid>
        </Content>
      </Container>
    </LoaderProvider>
  )
}

InvoiceDetail.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  pageTitle: PropTypes.object.isRequired,
  pageTitleLoading: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired
}
export default InvoiceDetail
