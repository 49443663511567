import React from 'react'
import PropTypes from 'prop-types'
import Divider from '@mui/material/Divider'
import Card from '@mui/material/Card'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'

import CardHeader from '../Cards/CardHeader'

const ContentBoxStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'stretch',
  [theme.breakpoints.down('md')]: {
    overflow: 'auto'
  },
  '& > *:first-of-type': {
    border: 0
  }
}))

function StatusGrid ({ children }) {
  return (
    <Card>
      <CardHeader title="Status" />
      <Divider />
      <ContentBoxStyled>
        {children}
      </ContentBoxStyled>
    </Card>
  )
}

StatusGrid.propTypes = {
  children: PropTypes.any.isRequired
}

export default StatusGrid
