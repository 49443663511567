import React from 'react'
import { Ordering } from 'storfox-filter'

import { CURRENCY_RATE_COLUMNS as tableColumns } from './CurrencyRateTable'

import { FILTER_NAME as name } from '../../../constants/CurrencyRate'

export const currencyRateOrderingOptions = {
  name,
  tableColumns
}

function CurrencyRateOrderingForm (props) {
  return (
    <Ordering {...props} />
  )
}

export default CurrencyRateOrderingForm
