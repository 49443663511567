import React, { useCallback, useEffect } from 'react'
import { useField } from 'react-final-form'
import PropTypes from 'prop-types'
import { prop } from 'ramda'

import Money from '~/components/Money'
import VerticalAlignment from '~/components/VerticalAlignment'
import Subtext from '~/components/Subtext'

function BundleMoney ({ name, conditionFieldName, value, discountPrice }) {
  const field = useField(name)
  const conditionField = useField(conditionFieldName)
  const conditionDirty = conditionField.meta.dirty
  const condition = conditionField.input.value

  const handleChange = useCallback(field.input.onChange, [])

  useEffect(() => {
    const retailPrice = prop('retailPrice', condition)

    if (conditionDirty) {
      handleChange(retailPrice)
    }
  }, [condition, conditionDirty, handleChange])

  return (
    <VerticalAlignment
      primary={<Money value={discountPrice} />}
      secondary={(
        <del>
          <Subtext>
            <Money value={value} />
          </Subtext>
        </del>
      )}
    />
  )
}

BundleMoney.propTypes = {
  value: PropTypes.number,
  name: PropTypes.string.isRequired,
  conditionFieldName: PropTypes.string.isRequired,
  discountPrice: PropTypes.number.isRequired
}

export default BundleMoney
