import React from 'react'
import { prop } from 'ramda'
import { useRoute } from 'storfox-route-hooks'

import { objectToBase64, unescapeBtoa } from '~/utils'
import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import { useCompany } from '~/components/Profile'

import Overview from '../components/Overview'
import {
  useLowStock,
  usePurchaseOrder,
  useStockOverview,
  useGMSDTD,
  useOrderByChannel,
  useCarrierDivision,
  useStockFlow,
  useOverviewInformation,
  useOrderOverview,
  useShipmentOverview,
  useOrdersByUnitsOverview,
  usePOByUnitsOverview,
  usePickerStatistic,
  usePackerStatistic,
  useReceiveStatistic,
  usePutawayStatistic
} from '../hooks'

const OverviewContainer = () => {
  const { company } = useCompany()
  const companyId = prop('id', company)

  const gmsdtd = useGMSDTD({ companyId })
  const orderByChannel = useOrderByChannel({ companyId })
  const carrierDivision = useCarrierDivision({ companyId })
  const stockFlow = useStockFlow({ companyId })
  const overviewInformation = useOverviewInformation({ companyId })
  const stockOverview = useStockOverview({ companyId })
  const orderOverview = useOrderOverview({ companyId })
  const purchaseOrder = usePurchaseOrder({ companyId })
  const lowStock = useLowStock({ companyId })
  const shipmentOverview = useShipmentOverview({ companyId })
  const ordersByUnitsOverview = useOrdersByUnitsOverview({ companyId })
  const poByUnitsOverview = usePOByUnitsOverview({ companyId })
  const pickerStatistic = usePickerStatistic({ companyId })
  const packerStatistic = usePackerStatistic({ companyId })
  const receiveStatistic = useReceiveStatistic({ companyId })
  const putawayStatistic = usePutawayStatistic({ companyId })
  const { replaceParams } = useRoute()

  const title = 'Dashboard'

  const handleSubmit = values => {
    const range = prop('dateRange', values)
    const [startDate, endDate] = range

    const dateRange = prop(0, range) ? objectToBase64([startDate, endDate]) : null
    const company = unescapeBtoa(prop('company', values))
    const warehouse = unescapeBtoa(prop('warehouse', values))

    replaceParams({ dateRange, company, warehouse })

    return Promise.resolve()
  }

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      activeNav={NAV.OVERVIEW}
      breadcrumbs={breadcrumbs}
    >
      <Overview
        onSubmit={handleSubmit}
        stockOverview={stockOverview}
        purchaseOrder={purchaseOrder}
        lowStock={lowStock}
        gmsdtd={gmsdtd}
        orderByChannel={orderByChannel}
        carrierDivision={carrierDivision}
        stockFlow={stockFlow}
        overviewInformation={overviewInformation}
        orderOverview={orderOverview}
        shipmentOverview={shipmentOverview}
        ordersByUnitsOverview={ordersByUnitsOverview}
        poByUnitsOverview={poByUnitsOverview}
        pickerStatistic={pickerStatistic}
        packerStatistic={packerStatistic}
        receiveStatistic={receiveStatistic}
        putawayStatistic={putawayStatistic}
      />
    </DashboardLayout>
  )
}

export default OverviewContainer
