import React from 'react'
import { useTranslation } from 'react-i18next'

import SelectField from '~/components/Fields/SelectField'

export const TO_RECEIVING_LOCATION = 'to_receiving_location'
export const TO_ANY_LOCATION = 'to_any_location'

const options = [
  { value: TO_RECEIVING_LOCATION, name: 'Receive to receiving location' },
  { value: TO_ANY_LOCATION, name: 'Receive to any location' },
]
function ReceivingStrategyField (props) {
  const { t } = useTranslation()

  return (
    <SelectField
      data-cy="receivingStrategy"
      name="receivingStrategy"
      label={t('Receiving strategy')}
      options={options}
      {...props}
    />
  )
}

export default ReceivingStrategyField
