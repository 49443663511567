import React from 'react'
import { useParams } from 'react-router-dom'
import { pathOr } from 'ramda'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'

import { useCategoryDetail } from '../hooks'
import CategoryDetail from '../components/CategoryDetail/CategoryDetail'

function CategoryDetailContainer () {
  const { id } = useParams()
  const categoryDetail = useCategoryDetail(id)

  const title = pathOr('Categories', ['detail', 'name'], categoryDetail)

  const pageLoading = categoryDetail.isLoading

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      isLoading={pageLoading}
      activeNav={NAV.CATEGORIES}
      breadcrumbs={breadcrumbs}
    >
      <CategoryDetail
        title={title}
        detail={categoryDetail.detail}
        isLoading={categoryDetail.isLoading}
      />
    </DashboardLayout>
  )
}

export default CategoryDetailContainer
