import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import ContainerDetailContainer from './containers/ContainerDetailContainer'
import ContainerCreateContainer from './containers/ContainerCreateContainer'

export default () => {
  return [
    {
      layout: AuthLayout,
      path: ROUTES.CONTAINER_CREATE_PATH,
      component: ContainerCreateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.CONTAINER_DETAIL_PATH,
      component: ContainerDetailContainer
    }
  ]
}
