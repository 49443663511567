import React from 'react'
import PropTypes from 'prop-types'
import { prop } from 'ramda'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import { useField } from 'react-final-form'
import { useDeepCompareEffect } from 'storfox-api-hooks'
import { useParams } from 'react-router-dom'

import { CardHeader } from '~/components/Cards'
import { withForm } from '~/components/Form'
import ContainerTypeField from '~/components/Fields/ContainerTypeField'
import DimensionField from '~/components/Fields/DimensionField'
import PositiveNumberField from '~/components/Fields/PositiveNumberField'
import VolumeField from '~/components/Fields/VolumeField'
import WeightField from '~/components/Fields/WeightField'
import PageTitle from '~/components/PageTitle'
import { DiscardButton, SaveButton } from '~/components/Buttons'
import Container, { Content, Header } from '~/components/Container'
import * as NAV from '~/constants/nav-titles'

import ContainerLocationField from '../Fields/ContainerLocationField'

export const CONTAINER_CREATE_FIELDS = [
  'containerType',
  'parent',
  'quantity',
  'length',
  'width',
  'height',
  'maxLoad',
  'volume',
]

function ContainerCreateForm (props) {
  const {
    title,
    isLoading,
    form
  } = props

  const { warehouseGuid } = useParams()
  const containerTypeField = useField('container.containerType')
  const containerTypeValue = containerTypeField.input.value

  useDeepCompareEffect(() => {
    form.form.batch(() => {
      form.form.change('length', prop('length', containerTypeValue))
      form.form.change('width', prop('width', containerTypeValue))
      form.form.change('height', prop('height', containerTypeValue))
      form.form.change('maxLoad', prop('maxLoad', containerTypeValue))
    })
  }, [containerTypeValue])

  return (
    <form onSubmit={form.handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={title}
            parentTitle={NAV.CONTAINER}
            rightButton={(
              <>
                <DiscardButton disabled={isLoading} />
                <SaveButton disabled={isLoading} />
              </>
            )}
          />
        </Header>

        <Content>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12} lg={8}>
              <Box mb={3}>
                <Card>
                  <CardHeader title="Information" />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12} lg={12}>
                        <ContainerLocationField params={{ warehouseGuid }} />
                      </Grid>

                      <Grid item={true} xs={12} lg={12}>
                        <ContainerTypeField
                          name="containerType"
                          params={{ warehouseGuids: warehouseGuid }}
                          required={true}
                        />
                      </Grid>

                      <Grid item={true} xs={12} lg={12}>
                        <PositiveNumberField
                          data-cy="quantity"
                          name="quantity"
                          label="Quantity"
                          inputProps={{ maxLength: 3 }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Grid>

            <Grid item={true} xs={12} lg={4}>
              <Card>
                <CardHeader title="Dimensions" />
                <Divider />
                <CardContent>
                  <Grid container={true} spacing={3}>
                    <Grid item={true} xs={12} lg={6}>
                      <DimensionField
                        data-cy="height"
                        name="height"
                        label="Height"
                        disabled={true}
                      />
                    </Grid>

                    <Grid item={true} xs={12} lg={6}>
                      <DimensionField
                        data-cy="width"
                        name="width"
                        label="Width"
                        disabled={true}
                      />
                    </Grid>

                    <Grid item={true} xs={12} lg={6}>
                      <DimensionField
                        data-cy="length"
                        name="length"
                        label="Length"
                        disabled={true}
                      />
                    </Grid>

                    <Grid item={true} xs={12} lg={6}>
                      <WeightField
                        data-cy="maxLoad"
                        name="maxLoad"
                        label="Max. Load"
                        disabled={true}
                      />
                    </Grid>

                    <Grid item={true} xs={12} lg={12}>
                      <VolumeField disabled={true} />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Content>
      </Container>
    </form>
  )
}

ContainerCreateForm.propTypes = {
  title: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default withForm(ContainerCreateForm)
