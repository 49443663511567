import React from 'react'
import { prop } from 'ramda'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { useAllSearchParams } from 'storfox-route-hooks'

import RetailerGeneral from './General/RetailerGeneral'

import { getDateRangeFromParams } from '../../../utils'
import GMSDayToDay from '../GMSDayToDay'
import CarrierDivision from '../CarrierDivision'
import Orders from '../Orders'
import LowStock from '../LowStock'
import Purchases from '../Purchases'

function RetailerOverview (props) {
  const { purchaseOrder, lowStock, gmsdtd, orderByChannel, carrierDivision, overviewInformation } = props
  const { dateRange: initialDateRange } = useAllSearchParams()
  const dateRange = getDateRangeFromParams(initialDateRange)

  const isLoading = prop('isLoading', overviewInformation)
  const general = prop('detail', overviewInformation)
  const gmsdtdList = prop('results', gmsdtd)
  const orderChannels = prop('results', orderByChannel)
  const carrierDivisionList = prop('results', carrierDivision)
  const purchaseOrderItems = prop('results', purchaseOrder)
  const purchaseOrderIsLoading = prop('isLoading', purchaseOrder)

  const lowStockItems = prop('results', lowStock)
  const lowStockIsLoading = prop('isLoading', lowStock)

  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12}>
        <RetailerGeneral
          data={general}
          isLoading={isLoading}
        />
      </Grid>
      <Grid item={true} lg={8} xs={12}>
        <GMSDayToDay dateRange={dateRange} items={gmsdtdList} />
      </Grid>
      <Grid item={true} lg={4} xs={12}>
        <Orders
          items={orderChannels}
          isLoading={isLoading}
        />
      </Grid>
      <Grid item={true} xs={12}>
        <CarrierDivision dateRange={dateRange} items={carrierDivisionList} />
      </Grid>
      <Grid item={true} lg={8} xs={12}>
        <Purchases
          items={purchaseOrderItems}
          isLoading={purchaseOrderIsLoading}
        />
      </Grid>
      <Grid item={true} lg={4} xs={12}>
        <LowStock
          items={lowStockItems}
          isLoading={lowStockIsLoading}
        />
      </Grid>
    </Grid>
  )
}

RetailerOverview.propTypes = {
  purchaseOrder: PropTypes.object.isRequired,
  lowStock: PropTypes.object.isRequired,
  gmsdtd: PropTypes.object.isRequired,
  orderByChannel: PropTypes.object.isRequired,
  carrierDivision: PropTypes.object.isRequired,
  overviewInformation: PropTypes.object.isRequired
}

export default RetailerOverview
