import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card/Card'
import CardContent from '@mui/material/CardContent'
import { useTranslation } from 'react-i18next'
import { SwitchField } from 'storfox-form-fields'
import PropTypes from 'prop-types'

import { CardHeader } from '~/components/Cards'
import TextField from '~/components/Fields/TextField'
import StatusField from '~/components/Fields/StatusField'
import Divider from '~/components/Divider'

function ZidUpdate ({ type }) {
  const { t } = useTranslation()
  return (
    <>
      <Grid container={true}>
        <Grid item={true} xs={12}>
          <Box mb={3}>
            <Card>
              <CardHeader title={t('Integration')} />
              <Divider />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={12} lg={6}>
                    <TextField
                      data-cy="name"
                      name="name"
                      label="Name"
                    />
                  </Grid>

                  <Grid item={true} xs={12} lg={6}>
                    <StatusField />
                  </Grid>

                  {type === 'zid_app' && (
                    <>
                      <Grid item={true} xs={12} lg={6}>
                        <TextField
                          data-cy="clientId"
                          name="credential.clientId"
                          label="Client ID"
                          disabled={true}
                        />
                      </Grid>

                      <Grid item={true} xs={12} lg={6}>
                        <TextField
                          disabled={true}
                          data-cy="clientSecret"
                          name="credential.clientSecret"
                          label="Client secret"
                        />
                      </Grid>
                    </>
                  )}

                  {type === 'zid' && (
                    <>
                      <Grid item={true} xs={12} lg={6}>
                        <TextField
                          data-cy="storeDomain"
                          name="storeDomain"
                          label="Store domain"
                          disabled={true}
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item={true} xs={12}>
                    <TextField
                      data-cy="description"
                      name="description"
                      label="Description"
                      multiline={true}
                      rows="4"
                    />
                  </Grid>

                  <Grid item={true} xs={12} sx={{ display: 'flex' }}>
                    <SwitchField name="matchSku" />
                    {t('Match SKU')}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

ZidUpdate.propTypes = {
  type: PropTypes.string.isRequired
}

export default ZidUpdate
