import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import { propOr } from 'ramda'

import { CardHeader } from '~/components/Cards'
import TextField from '~/components/Fields/TextField'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import { withForm } from '~/components/Form'
import { DiscardButton, SaveButton } from '~/components/Buttons'
import * as NAV from '~/constants/nav-titles'

import DefaultDeliveryMethodSwitchField from '../Fields/DefaultDeliveryMethodSwitchField'

function DeliveryMethodUpdateForm ({ pageTitle, pageTitleLoading, isLoading, form }) {
  const { t } = useTranslation()
  const { initialValues, handleSubmit } = form

  const isDefault = propOr(false, 'isDefault', initialValues)

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            parentTitle={t(NAV.CONFIGURATION)}
            pageTitle={pageTitle}
            pageTitleLoading={pageTitleLoading}
            rightButton={(
              <>
                <DiscardButton />
                <SaveButton disabled={isLoading} />
              </>
            )}
          />
        </Header>
        <Content>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12} lg={6}>
              <Card>
                <CardHeader title={t('Details')} />
                <Divider />
                <CardContent>
                  <Grid container={true} spacing={3}>
                    <Grid item={true} xs={12}>
                      <TextField
                        name="name"
                        data-cy="name"
                        label="Name"
                      />
                    </Grid>
                    <Grid item={true} xs={12}>
                      <TextField
                        name="code"
                        data-cy="code"
                        label="Code"
                      />
                    </Grid>
                    <Grid item={true} xs={12}>
                      <TextField
                        name="description"
                        data-cy="description"
                        label="Description"
                        multiline={true}
                        minRows={4}
                      />
                    </Grid>
                    <Grid item={true} xs={12}>
                      <DefaultDeliveryMethodSwitchField
                        name="isDefault"
                        initialValue={isDefault}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Content>
      </Container>
    </form>
  )
}

DeliveryMethodUpdateForm.propTypes = {
  pageTitle: PropTypes.string,
  form: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  pageTitleLoading: PropTypes.bool
}

export default withForm(DeliveryMethodUpdateForm)
