import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDeepCompareEffect } from 'storfox-api-hooks'

import { get } from './cookies'

const LanguageProvider = ({ children }) => {
  const { i18n } = useTranslation()

  useDeepCompareEffect(() => {
    const language = get()
    i18n.changeLanguage(language)
  }, [])

  return (
    <>
      {children}
    </>
  )
}

LanguageProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default LanguageProvider
