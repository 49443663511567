import React from 'react'
import PropTypes from 'prop-types'

function Duration ({ value }) {
  return (
    <p>{value / 60} min(s)</p>
  )
}

Duration.defaultProps = {
  value: 0
}

Duration.propTypes = {
  value: PropTypes.number.isRequired
}

export default Duration
