import React from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import { SwitchField } from 'storfox-form-fields'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'

import { CardHeader } from '~/components/Cards'
import TextField from '~/components/Fields/TextField'
import Container, { Content, Header } from '~/components/Container'
import PageTitle from '~/components/PageTitle'
import { DiscardButton, SaveButton } from '~/components/Buttons'
import { withForm } from '~/components/Form'
import * as NAV from '~/constants/nav-titles'
import ConstrainedFloatField from '~/components/Fields/ConstrainedFloatField'

import TaxTypeField from '../Fields/TaxTypeField'

export const TAX_FIELDS = [
  'name',
  'type',
  'value',
  'isDefault'
]

function TaxCreateForm ({ title, isLoading, pageTitleLoading, form }) {
  const { handleSubmit } = form

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={title}
            parentTitle={NAV.CONFIGURATION}
            pageTitleLoading={pageTitleLoading}
            rightButton={(
              <>
                <DiscardButton disabled={isLoading} />
                <SaveButton disabled={isLoading} />
              </>
            )}
          />
        </Header>

        <Content bottomSpace={true}>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12} lg={8}>
              <Box>

                <Card>
                  <CardHeader title="Tax info" />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12}>
                        <TextField
                          inputProps={{ maxLength: 200 }}
                          data-cy="name"
                          name="name"
                          label="Name"
                        />
                      </Grid>

                      <Grid item={true} xs={12} lg={6}>
                        <TaxTypeField />
                      </Grid>

                      <Grid item={true} xs={12} lg={6}>
                        <ConstrainedFloatField
                          name="value"
                          data-cy="taxValue"
                          InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>
                          }}
                        />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <SwitchField
                          data-cy="isDefault"
                          name="isDefault"
                        />
                        Default tax
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Content>
      </Container>
    </form>
  )
}

TaxCreateForm.propTypes = {
  title: PropTypes.string.isRequired,
  form: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  pageTitleLoading: PropTypes.bool
}

export default withForm(TaxCreateForm)
