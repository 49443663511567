import React from 'react'
import PropTypes from 'prop-types'

import SearchField from './SearchField'

function EditableSearchField ({ onValueChange, ...props }) {
  const [isLoading, setIsLoading] = React.useState(false)

  const handleValueChange = value => {
    setIsLoading(true)
    onValueChange(value)
      .finally(() => setIsLoading(false))
  }

  return (
    <SearchField
      onValueChange={handleValueChange}
      isLoading={isLoading}
      {...props}
    />
  )
}

EditableSearchField.propTypes = {
  onValueChange: PropTypes.func.isRequired
}

export default EditableSearchField
