import { colors } from '@mui/material'

import palette from '../palette'

export default {
  styleOverrides: {
    root: {
      '&.Mui-disabled': {
        background: colors.grey[100]
      }
    },
    input: {
      '&::placeholder': {
        opacity: 1,
        color: palette.text.secondary
      }
    }
  }
}
