import { path } from 'ramda'
import React from 'react'
import { useParams } from 'react-router-dom'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import { CUSTOMER_DETAIL_TABS } from '~/constants/tabs'

import CustomerDetail from '../components/CustomerDetail'
import { useCustomerDetail, useCustomerHistory, useCustomerOrderList } from '../hooks'
import { CustomerInitialSerializer } from '../serializers'

function CustomerDetailContainer () {
  const { guid, tab } = useParams()
  const customerDetail = useCustomerDetail(guid)

  const isOrderTab = tab === CUSTOMER_DETAIL_TABS.ORDERS
  const isHistoryTab = tab === CUSTOMER_DETAIL_TABS.HISTORY

  const customerHistory = useCustomerHistory(guid, isHistoryTab)
  const customerOrderList = useCustomerOrderList(guid, isOrderTab)

  const pageLoading = customerDetail.isLoading

  const firstName = path(['detail', 'firstName'], customerDetail)
  const lastName = path(['detail', 'lastName'], customerDetail)
  const fullName = `${firstName} ${lastName}`
  const title = customerDetail.isLoading ? 'Customers' : fullName

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      isLoading={pageLoading}
      activeNav={NAV.CUSTOMERS}
      breadcrumbs={breadcrumbs}
    >
      <CustomerDetail
        pageTitle={title}
        pageTitleLoading={customerDetail.isLoading}
        detail={CustomerInitialSerializer(customerDetail.detail)}
        orderList={customerOrderList}
        customerHistory={customerHistory}
        isLoading={customerDetail.isLoading}
      />
    </DashboardLayout>
  )
}

export default CustomerDetailContainer
