import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { uniqBy, prop, propEq, propOr } from 'ramda'
import Box from '@mui/material/Box'
import { styled } from '@mui/material'
import { useTranslation } from 'react-i18next'

const RootBox = styled(Box)({
  display: 'flex',
  borderBottom: '1px solid #eeeeee',
  borderRight: '1px solid #eeeeee',
  width: 'max-content',
  fontWeight: 500
})

const BayBox = styled(Box)({
  width: 'auto',
  height: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderBottom: '1px solid #eeeeee',
  borderLeft: '1px solid #eeeeee'
})

const BayTitleBox = styled(Box)({
  width: 'auto',
  minHeight: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderLeft: '1px solid #eeeeee'
})

const LevelTitleBox = styled(Box)({
  width: 'auto',
  padding: '0 5px',
  height: '-webkit-fill-available',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderTop: '1px solid #eeeeee',
  borderLeft: '1px solid #eeeeee'
})

const LevelBox = styled(Box)(({ utilization }) => ({
  width: '50px',
  height: '50px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderTop: '1px solid #eeeeee',
  borderLeft: '1px solid #eeeeee',
  textAlign: 'center',
  cursor: 'pointer',
  ...(utilization ? {
    background: 'red'
  } : {
    background: 'green'
  })
}))

const NoLocationBox = styled(Box)({
  width: '50px',
  cursor: 'not-allowed',
  height: '50px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '25px',
  borderTop: '1px solid #eeeeee',
  borderLeft: '1px solid #eeeeee'
})

const getUniqueBayLocations = (locations) => {
  const uniqueBay = uniqBy(prop('bay'), locations)
  return uniqueBay.map(location => {
    const bay = prop('bay', location)
    return {
      bay,
      locations: locations.filter(loc => propEq('bay', bay, loc))
    }
  })
}

function VisualizationTable ({ selected, onChooseLocation }) {
  const { t } = useTranslation()
  const locationsByAisle = propOr([], 'locations', selected)
  const locationByBay = useMemo(() => getUniqueBayLocations(locationsByAisle), [locationsByAisle])

  const uniqueByLevel = uniqBy(prop('level'), locationsByAisle)

  return (
    <RootBox>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <BayTitleBox>{t('Bay/Reck')}</BayTitleBox>
        <LevelTitleBox>{t('Level/Shelf')}</LevelTitleBox>
      </Box>
      {locationByBay.map(locByBay => {
        const bay = prop('bay', locByBay)
        const locationsByBay = prop('locations', locByBay)
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <BayBox>{bay}</BayBox>
            {uniqueByLevel.map(uniqueLevel => {
              const uniqueLocation = locationsByBay.find(propEq('level', prop('level', uniqueLevel)))
              if (uniqueLocation) {
                const bin = prop('bin', uniqueLevel)
                const levelByUniqueLocation = prop('level', uniqueLevel)
                const locationId = prop('locationId', uniqueLevel)
                const utilization = prop('utilization', uniqueLevel)
                if (bin) {
                  const uniqueByLevelBin = locationsByAisle.filter(propEq('level', levelByUniqueLocation))
                  return (
                    <Box sx={{ display: 'flex' }}>
                      {uniqueByLevelBin.map(uniqueLevelBin => {
                        const uniqueLevelByBin = prop('bin', uniqueLevelBin)
                        return (
                          <LevelBox utilization={utilization} onClick={() => onChooseLocation(uniqueLevelBin)}>
                            {`${levelByUniqueLocation} ${uniqueLevelByBin}`}
                          </LevelBox>
                        )
                      })}
                    </Box>
                  )
                }
                return (
                  <LevelBox
                    utilization={utilization}
                    onClick={() => onChooseLocation(uniqueLevel)}
                  >
                    {levelByUniqueLocation || locationId}
                  </LevelBox>
                )
              } else {
                return (
                  <NoLocationBox>X</NoLocationBox>
                )
              }
            })}
          </Box>
        )
      })}
    </RootBox>
  )
}

VisualizationTable.propTypes = {
  selected: PropTypes.object.isRequired,
  onChooseLocation: PropTypes.func.isRequired
}

export default VisualizationTable
