import PropTypes from 'prop-types'
import React from 'react'
import Link from '@mui/material/Link'
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded'
import { Box, styled } from '@mui/material'

const LinkStyled = styled(Link)({
  display: 'flex',
  marginBottom: 8,
  '&:last-child': {
    marginBottom: 0
  }
})

function StatusGridLink ({ href, children, ...props }) {
  return (
    <LinkStyled
      target="_blank"
      display="block"
      variant="body2"
      rel="noopener noreferrer"
      href={href}
      {...props}
    >
      <Box sx={{
        fontSize: 16,
        color: '#198CFF',
        marginRight: 4
      }}>
        <LaunchRoundedIcon color="inherit" fontSize="inherit" />
      </Box>
      {children}
    </LinkStyled>
  )
}

StatusGridLink.propTypes = {
  children: PropTypes.any.isRequired,
  href: PropTypes.string.isRequired
}

export default StatusGridLink
