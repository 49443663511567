import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Status, { ERROR, SUCCESS, WARNING } from '../Status/Status'

export const UNPAID = 'unpaid'
export const FULLY_PAID = 'fully_paid'
export const PAID = 'paid'
export const REFUNDED = 'refunded'
export const PARTIALLY_REFUNDED = 'partially_refunded'
export const CASH_ON_DELIVERY = 'cash_on_delivery'

export const PAYMENT_STATUS = {
  UNPAID,
  FULLY_PAID,
  PAID,
  REFUNDED,
  PARTIALLY_REFUNDED,
  CASH_ON_DELIVERY
}

export const PAYMENT_STATUS_LABEL = {
  [UNPAID]: 'Unpaid',
  [FULLY_PAID]: 'Fully paid',
  [PAID]: 'Paid',
  [REFUNDED]: 'Refunded',
  [PARTIALLY_REFUNDED]: 'Partially refunded',
  [CASH_ON_DELIVERY]: 'Cash on delivery'
}

const STATUS_COLOR = {
  [PAYMENT_STATUS.UNPAID]: ERROR,
  [PAYMENT_STATUS.FULLY_PAID]: SUCCESS,
  [PAYMENT_STATUS.PAID]: SUCCESS,
  [PAYMENT_STATUS.REFUNDED]: WARNING,
  [PAYMENT_STATUS.PARTIALLY_REFUNDED]: WARNING,
  [PAYMENT_STATUS.CASH_ON_DELIVERY]: WARNING
}

function PaymentStatus ({ value }) {
  const { t } = useTranslation()
  return (
    <Status
      label={t(PAYMENT_STATUS_LABEL[value])}
      color={STATUS_COLOR[value]}
    />
  )
}

PaymentStatus.propTypes = {
  value: PropTypes.string.isRequired
}

export default PaymentStatus
