import React from 'react'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import { useTranslation } from 'react-i18next'
import { useField } from 'react-final-form'
import { useDeepCompareEffect } from 'storfox-api-hooks'
import { prop } from 'ramda'

import TextField from '~/components/Fields/TextField'
import PhoneNumberField from '~/components/Fields/PhoneNumberField'
import { CardHeader } from '~/components/Cards'

import ReturnWarehouseSearchField from './Fields/ReturnWarehouseSearchField'
import CountryField from './Fields/CountryField'

import { getDeliveryAddress } from '../serializers'

function DeliveryAddressCard () {
  const { t } = useTranslation()

  const warehouseField = useField('warehouse')
  const warehouse = warehouseField.input.value
  const warehouseDirty = warehouseField.meta.dirty

  const addressField = useField('deliveryAddress')
  const handleAddressChange = addressField.input.onChange

  useDeepCompareEffect(() => {
    if (warehouseDirty && prop('guid', warehouse)) {
      const deliveryAddress = getDeliveryAddress(warehouse)
      handleAddressChange(deliveryAddress)
    }
  }, [warehouse])

  return (
    <Card>
      <CardHeader title={t('Delivery address')} />
      <Divider />
      <CardContent>
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={12} lg={12}>
            <ReturnWarehouseSearchField name="warehouse" />
          </Grid>
          <Grid item={true} xs={12} lg={12}>
            <TextField
              data-cy="deliveryAddress.firstName"
              name="deliveryAddress.firstName"
              label="First name"
            />
          </Grid>

          <Grid item={true} xs={12} lg={12}>
            <TextField
              data-cy="deliveryAddress.lastName"
              name="deliveryAddress.lastName"
              label="Last name"
            />
          </Grid>

          <Grid item={true} xs={12}>
            <PhoneNumberField
              data-cy="deliveryAddress.mobile"
              name="deliveryAddress.mobile"
              label="Phone number"
            />
          </Grid>

          <Grid item={true} xs={12}>
            <TextField
              data-cy="deliveryAddress.address"
              name="deliveryAddress.address"
              label="Address"
              multiline={true}
              minRows={3}
            />
          </Grid>

          <Grid item={true} xs={12}>
            <TextField
              data-cy="deliveryAddress.region"
              name="deliveryAddress.region"
              label="Region/Neighbouring/State"
            />
          </Grid>

          <Grid item={true} xs={12}>
            <TextField
              data-cy="deliveryAddress.city"
              name="deliveryAddress.city"
              label="City"
            />
          </Grid>

          <Grid item={true} xs={12}>
            <TextField
              data-cy="deliveryAddress.zipcode"
              name="deliveryAddress.zipcode"
              label="Zipcode"
            />
          </Grid>

          <Grid item={true} xs={12}>
            <CountryField
              data-cy="deliveryAddress.country"
              name="deliveryAddress.country"
              label="Country"
              ListboxProps={{ 'data-cy': 'countryList' }}
            />
          </Grid>

          <Grid item={true} xs={6}>
            <TextField
              data-cy="pickupAddress.latitude"
              name="pickupAddress.latitude"
              label="Latitude"
            />
          </Grid>

          <Grid item={true} xs={6}>
            <TextField
              data-cy="pickupAddress.longitude"
              name="pickupAddress.longitude"
              label="Longitude"
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default DeliveryAddressCard
