import React from 'react'
import Grid from '@mui/material/Grid'

import StatusField from '~/components/Fields/StatusField'
import TextField from '~/components/Fields/TextField'

import EnvironmentSelectField from './EnviromentSelectField'
import PostmenTypeField from './PostmenTypeField'

import BillingPaidField from '../../components/Postmen/BillingPaidField'
import TermsOfTradeField from '../../components/Postmen/TermsOfTradeField'

function PostmenCreate () {
  return (
    <>

      <Grid item={true} xs={6} lg={6}>
        <TextField
          data-cy="name"
          name="name"
          label="Name"
        />
      </Grid>

      <Grid item={true} xs={6} lg={6}>
        <EnvironmentSelectField
          data-cy="region"
          name="region"
          label="Environment"
        />
      </Grid>

      <Grid item={true} xs={12} lg={6}>
        <StatusField />
      </Grid>

      <Grid item={true} xs={12} lg={6}>
        <PostmenTypeField />
      </Grid>

      <Grid item={true} xs={12}>
        <TextField
          data-cy="description"
          name="description"
          label="Description"
          multiline={true}
          rows="4"
        />
      </Grid>

      <Grid item={true} lg={12}>
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={12} lg={6}>
            <TextField
              data-cy="api"
              name="credential.apiKey"
              label="Postmen Api Key"
            />
          </Grid>
          <Grid item={true} xs={12} lg={6}>
            <TextField
              data-cy="accountId"
              name="credential.accountId"
              label="Account Id"
            />
          </Grid>
          <Grid item={true} xs={12} lg={6}>
            <BillingPaidField
              name="credential.billingPaidBy"
            />
          </Grid>
          <Grid item={true} xs={12} lg={6}>
            <TermsOfTradeField
              name="credential.termsOfTrade"
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default PostmenCreate
