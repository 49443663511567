import React from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { path } from 'ramda'
import { useSnackbar } from 'storfox-snackbar'
import { useTranslation } from 'react-i18next'

import { DashboardLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'
import { SUPPLIER_DETAIL_TABS } from '~/constants/tabs'
import { ACTIVE } from '~/components/Fields/StatusField'

import { SupplierCreateForm } from '../components/SupplierCreate'
import { useSupplierCreate } from '../hooks'
import { SupplierCreateSerializer } from '../serializers'

function SupplierCreateContainer () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const supplierCreate = useSupplierCreate()

  const message = t('Successfully created')
  const handleSubmit = formValues =>
    supplierCreate.create(SupplierCreateSerializer(formValues))
      .then(data => {
        const guid = path(['result', 'guid'], data)
        const tab = SUPPLIER_DETAIL_TABS.GENERAL
        const redirect = generatePath(ROUTES.SUPPLIER_DETAIL_PATH, { guid, tab })

        navigate(redirect)
      })
      .then(() => snackbar({ message }))

  const title = 'New supplier'

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={t('New Supplier')}
      isLoading={supplierCreate.isLoading}
      activeNav={NAV.SUPPLIERS}
      breadcrumbs={breadcrumbs}
    >
      <SupplierCreateForm
        initialValues={{ status: ACTIVE }}
        onSubmit={handleSubmit}
        autoComplete={false}
        isLoading={supplierCreate.isLoading}
      />
    </DashboardLayout>
  )
}

export default SupplierCreateContainer
