import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { map, path, prop, propOr } from 'ramda'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'

import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import { Table, TableHeader } from '~/components/Table'
import * as NAV from '~/constants/nav-titles'
import TableDateFormat from '~/components/TableDateFormat/TableDateFormat'
import Tags from '~/components/Tags'

export const SHIPMENT_COLUMNS = [
  {
    width: 200,
    headerName: 'Number',
    field: 'lot'
  },
  {
    width: 300,
    headerName: 'Date',
    field: 'createdAt',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  },
  {
    width: 200,
    headerName: 'Shipment number',
    field: 'number'
  },
  {
    width: 100,
    headerName: 'Shop',
    field: 'bucket'
  },
  {
    width: 200,
    headerName: 'Scanned items',
    field: 'totalQuantity'
  },
  {
    width: 300,
    headerName: 'Carton',
    field: 'containers',
    valueFormatter: ({ value }) => value.length > 0 ? value.join(',') : '',
    renderCell: ({ value }) => (
      <Tags items={value} />
    )
  },
  {
    width: 200,
    headerName: 'Carton total',
    field: 'containersTotal'
  },
]

const getRowsFromResults = map(item => {
  const id = prop('id', item)
  const guid = prop('guid', item)
  const lot = path(['lot', 'number'], item)
  const bucket = path(['bucket', 'name'], item)
  const number = prop('number', item)
  const createdAt = prop('createdAt', item)
  const totalQuantity = prop('totalQuantity', item)
  const containers = propOr([], 'containers', item).map((item) => prop('number', item))
  const containersTotal = containers.length

  return {
    id,
    guid,
    createdAt,
    lot,
    number,
    bucket,
    totalQuantity,
    containers,
    containersTotal
  }
})

function ShipmentTable ({ list, filter, ordering, onListRefetch, onView }) {
  const isLoading = prop('isLoading', list)
  const results = prop('results', list)
  const count = prop('count', list)
  const columns = prop('filteredColumns', ordering)
  const hasNextPage = prop('hasNextPage', list)
  const hasPrevPage = prop('hasPrevPage', list)

  const actionColumns = useMemo(() => [
    ...columns,
    {
      width: 80,
      resizable: false,
      headerName: 'Actions',
      field: 'actions',
      type: 'actions',
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            label="View"
            onClick={() => onView(row.guid)}
            showInMenu={true}
          />
        ]
      }
    }
  ], [columns, onView])

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={NAV.CROSS_SHIPMENTS}
          parentTitle={NAV.CROSS_DOCKS}
        />
      </Header>

      <Content>
        <TableHeader
          filter={filter}
          filterOpen={filter.handleOpen}
          orderingOpen={ordering.handleOpen}
          ordering={ordering}
        />
        <Table
          isLoading={isLoading}
          columns={actionColumns}
          results={results}
          getRowsFromResults={getRowsFromResults}
          count={count}
          primaryKey="guid"
          ordering={ordering}
          onRefetch={onListRefetch}
          isNewPagination={true}
          hasNextPage={hasNextPage}
          hasPrevPage={hasPrevPage}
        />
      </Content>
    </Container>
  )
}

ShipmentTable.propTypes = {
  list: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  onListRefetch: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired
}

export default ShipmentTable
