import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAllSearchParams } from 'storfox-route-hooks'
import { path, pathOr, propOr } from 'ramda'
import { useDeepCompareEffect } from 'storfox-api-hooks'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import { VARIANT_DETAIL_TABS } from '~/constants/tabs'

import {
  useProductVariantList,
  useVariantDetail,
  useVariantHistory,
  useVariantStockList,
  useVariantSuppliersList,
  useVariantInventoryLogList
} from '../hooks'
import VariantDetail from '../components/VariantDetail/VariantDetail'

function VariantDetailContainer () {
  const { id, productId, tab } = useParams()
  const { isMultiVariant } = useAllSearchParams()

  const [variantProductId, setVariantProductId] = useState(productId)
  const [multiVariant, setMultiVariant] = useState(JSON.parse(isMultiVariant))

  const variantDetail = useVariantDetail(id)

  useDeepCompareEffect(() => {
    const variantId = path(['detail', 'id'], variantDetail)
    if (variantProductId === '0' && variantId) {
      setVariantProductId(pathOr(0, ['detail', 'product', 'id'], variantDetail))
      setMultiVariant(pathOr(0, ['detail', 'product', 'isMultiVariant'], variantDetail))
    }
  }, [variantDetail, variantProductId])

  const guid = propOr('', 'guid', variantDetail.detail)
  const historyAutoSend = Boolean(guid && tab === VARIANT_DETAIL_TABS.HISTORY)

  const variantHistory = useVariantHistory(guid, historyAutoSend)

  const variantList = useProductVariantList(variantProductId)
  const variantStockList = useVariantStockList(id, tab === VARIANT_DETAIL_TABS.STOCK)
  const variantSuppliersList = useVariantSuppliersList(id, tab === VARIANT_DETAIL_TABS.SUPPLIERS)
  const variantInventoryLog = useVariantInventoryLogList(guid, guid && tab === VARIANT_DETAIL_TABS.INVENTORY_LOG)

  const title = propOr('', 'name', variantDetail.detail)

  const breadcrumbs = { title }
  return (
    <DashboardLayout
      title="Variant detail"
      space={false}
      isLoading={variantDetail.isLoading}
      activeNav={NAV.VARIANTS}
      breadcrumbs={breadcrumbs}
    >
      <VariantDetail
        results={variantList.results}
        isMultiVariant={multiVariant}
        variantId={id}
        pageTitle={title}
        pageTitleLoading={variantDetail.isLoading}
        values={variantDetail.detail}
        stockList={variantStockList}
        variantHistory={variantHistory}
        supplierList={variantSuppliersList}
        isLoading={variantDetail.isLoading}
        id={id}
        productId={variantProductId}
        inventoryLog={variantInventoryLog}
      />
    </DashboardLayout>
  )
}

export default VariantDetailContainer
