import React from 'react'
import { Dialog, DialogActions, DialogContent } from '@mui/material'
import Grid from '@mui/material/Grid'
import PropTypes from 'prop-types'
import { useField, useFormState } from 'react-final-form'
import { isNil, path } from 'ramda'
import { DateTime } from 'luxon'
import { DateRangeField } from 'storfox-form-fields'

import DialogTitle from '~/components/DialogTitle'
import { DiscardButton, PrimaryButton } from '~/components/Buttons'
import { withForm } from '~/components/Form'

import CustomerSearchField from '../../Fields/CustomerSearchField'

function TplBillingExportDialogForm ({ open, onClose, form }) {
  const { handleSubmit } = form
  const { values } = useFormState()

  const fromDateField = useField('fromDatetime')
  const fromDate = fromDateField.input.value

  const maxDate = DateTime.fromISO(fromDate).plus({ days: 30 })

  const disabled = (
    isNil(path(['client', 'guid'], values)) ||
    isNil(path(['date', 0], values)) ||
    isNil(path(['date', 1], values))
  )

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          Export billing
        </DialogTitle>
        <DialogContent dividers={true} sx={{ minHeight: '30vh' }}>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12}>
              <CustomerSearchField />
            </Grid>
            <Grid item={true} xs={12}>
              <DateRangeField
                name="date"
                toDateProps={{
                  maxDate,
                  maxDateMessage: 'Range must be maximum 30 days'
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DiscardButton onClick={onClose} fullWidth={true} />
          <PrimaryButton
            type="submit"
            fullWidth={true}
            disabled={disabled}
          >
            Export
          </PrimaryButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}

TplBillingExportDialogForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  form: PropTypes.shape({
    initialValues: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired
  }).isRequired
}

export default withForm(TplBillingExportDialogForm)
