import React from 'react'
import Card from '@mui/material/Card'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import { prop } from 'ramda'
import PropTypes from 'prop-types'
import { generatePath, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { HoverableRow } from '~/components/Table'
import SkeletonList from '~/components/Skeleton'
import Money from '~/components/Money'
import TableDateFormat from '~/components/TableDateFormat'
import * as ROUTES from '~/constants/routes'

import { TPL_BILLING_TYPES, TPL_BILLING_URL_TYPES } from '../../constants'

function TplBillingTable ({ bills, isLoading }) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <Card>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('Type')}</TableCell>
              <TableCell>{t('From')}</TableCell>
              <TableCell>{t('To')}</TableCell>
              <TableCell>{t('Total price')}</TableCell>
              <TableCell>{t('Total paid')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? <SkeletonList rowCount={3} columnCount={5} />
              : bills.map(bill => {
                const guid = prop('guid', bill)
                const type = prop('type', bill)
                const totalPaid = prop('totalPaid', bill)
                const totalPrice = prop('totalPrice', bill)
                const periodStart = prop('periodStart', bill)
                const periodEnd = prop('periodEnd', bill)

                const pathType = TPL_BILLING_URL_TYPES[type]
                const path = generatePath(ROUTES.BILLING_ITEM_PATH, { guid, type: pathType })

                return (
                  <HoverableRow key={guid} onClick={() => navigate(path)}>
                    <TableCell>{TPL_BILLING_TYPES[type]}</TableCell>
                    <TableCell>
                      <TableDateFormat date={periodStart} withTime={true} />
                    </TableCell>
                    <TableCell>
                      <TableDateFormat date={periodEnd} withTime={true} />
                    </TableCell>
                    <TableCell>
                      <Money value={totalPrice} />
                    </TableCell>
                    <TableCell>
                      <Money value={totalPaid} />
                    </TableCell>
                  </HoverableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  )
}

TplBillingTable.defaultProps = {
  bills: []
}

TplBillingTable.propTypes = {
  bills: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default TplBillingTable
