import React from 'react'
import Grid from '@mui/material/Grid'
import PropTypes from 'prop-types'
import { useFormState } from 'react-final-form'

import Container, { Content, Header } from '~/components/Container'
import PageTitle from '~/components/PageTitle'
import { DiscardButton, SaveButton } from '~/components/Buttons'
import { withForm } from '~/components/Form'
import { TemplateCard } from '~/components/FileUpload'
import { BackgroundSingleFileUpload } from '~/components/BackgroundFileUpload'
import * as NAV from '~/constants/nav-titles'
import * as API from '~/constants/api'
import ProgressCard from '~/components/ProgressCard'
import { useGoogleEvent } from '~/components/GoogleAnalytics/GoogleAnalytics'

import UploadedFile from './UploadedFile'

function PurchaseOrderImportForm ({ title, form, isLoading, progressCard, onSubscribe, onFileRemove }) {
  const { handleSubmit } = form
  const { sendEvent } = useGoogleEvent()
  const formState = useFormState()

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={title}
            parentTitle={NAV.PURCHASE_ORDER}
            rightButton={(
              <>
                <DiscardButton disabled={isLoading} />
                <SaveButton disabled={isLoading || (!formState.values.file)} />
              </>
            )}
          />
        </Header>

        <Content>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12} lg={8}>
              <BackgroundSingleFileUpload
                name="file"
                api={API.PURCHASE_ORDER_FILE_VALIDATE}
                onSubscribe={onSubscribe}
                Component={UploadedFile}
                onRemove={onFileRemove}
              />
            </Grid>
            <Grid item={true} xs={12} lg={4}>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <TemplateCard
                    fileName="Purchase%20Orders.xlsx"
                    onClick={() => sendEvent({
                      eventAction: 'Template File Download Purchase Order',
                      eventCategory: 'Purchase Order'
                    })}
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <ProgressCard
                    title="Progress"
                    validation={progressCard.validation}
                    completion={progressCard.completion}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Content>
      </Container>
    </form>
  )
}

PurchaseOrderImportForm.propTypes = {
  form: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  progressCard: PropTypes.object.isRequired,
  onSubscribe: PropTypes.func.isRequired,
  onFileRemove: PropTypes.func.isRequired
}

export default withForm(PurchaseOrderImportForm)
