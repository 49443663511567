import React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'
import { prop } from 'ramda'

import { DashboardLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'
import ErrorLink from '~/components/ErrorLink'
import { INTEGRATION_TABS } from '~/constants/tabs'
import useMessages from '~/hooks/useMessages'

import { useIntegrationHistory, useCallCourierDetail, useCallCourierRemove, useCallCourierUpdate } from '../../hooks'
import LCSIntegrationUpdateForm from '../../components/LCS/LCSIntegrationUpdateForm'

function CallCourierIntegrationUpdateContainer () {
  const { guid, tab } = useParams()

  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const messages = useMessages()

  const integrationDetail = useCallCourierDetail(guid)
  const integrationUpdate = useCallCourierUpdate(guid)
  const integrationRemove = useCallCourierRemove()

  const title = prop('name', integrationDetail.detail)
  const params = { guid, type: 'call-courier', tab: INTEGRATION_TABS.GENERAL }

  const detailPath = generatePath(ROUTES.INTEGRATION_UPDATE_PATH, params)

  const historyAutoSend = Boolean(guid && tab === INTEGRATION_TABS.LOGS)
  const integrationHistory = useIntegrationHistory(guid, historyAutoSend)

  const handleSubmit = ({ type, ...formValues }) =>
    integrationUpdate.update(formValues)
      .then(() => {
        snackbar({ message: messages.UPDATE_SUCCESS })
        navigate(detailPath)
      })
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const isLoading = (
    integrationDetail.isLoading ||
    integrationUpdate.isLoading
  )

  const handleRemove = () =>
    integrationRemove.delete({ guids: [guid] })
      .then(() => navigate(ROUTES.INTEGRATION_LIST_PATH, { replace: true }))

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      isLoading={integrationDetail.isLoading}
      activeNav={NAV.INTEGRATION}
      breadcrumbs={breadcrumbs}
    >
      <LCSIntegrationUpdateForm
        isLoading={isLoading}
        pageTitle={title}
        pageTitleLoading={integrationDetail.isLoading}
        onSubmit={handleSubmit}
        onRemove={handleRemove}
        initialValues={integrationDetail.detail}
        history={integrationHistory}
      />
    </DashboardLayout>
  )
}

export default CallCourierIntegrationUpdateContainer
