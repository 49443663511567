import React from 'react'

import SelectField from '~/components/Fields/SelectField'

const OPTIONS = [
  {
    value: '1',
    name: 'Same Day Delivery'
  },
  {
    value: '2',
    name: '1-3 days Delivery'
  },
  {
    value: '3',
    name: 'Reverse Pickup'
  }
]

function DeliveryTypeField (props) {
  return (
    <SelectField
      label="Delivery type"
      options={OPTIONS}
      {...props}
    />
  )
}

export default DeliveryTypeField
