import React from 'react'
import { useField } from 'react-final-form'
import PropTypes from 'prop-types'
import { prop } from 'ramda'
import Box from '@mui/material/Box'

import LocationMultiSelectField from '../ActionFields/LocationMultiSelectField'
import UserMultiSelectField from '../ActionFields/UserMultiSelectField'
import { ASSIGN_TO, SET_STRATEGY, TO_LOCATION } from '../ActionFields/ActionSelectField'
import WarehouseActionSearchField from '../ActionFields/WarehouseActionSearchField'
import PutawayStrategyField from '../ActionFields/PutawayStrategyField'

export const PUTAWAY_CREATED_FIELDS = [
  'assignTo',
  'toLocation',
  'setStrategy'
]

function PutawayCreatedAction ({ action, actionName }) {
  const warehouseField = useField(`${actionName}.warehouse`)
  const warehouse = warehouseField.input.value
  const warehouseGuid = prop('guid', warehouse)

  const params = { limit: 1000, warehouseGuid }

  return (
    <>
      {action === ASSIGN_TO && <UserMultiSelectField name={`${actionName}.users`} />}
      {action === TO_LOCATION && (
        <>
          <Box mb={2}>
            <WarehouseActionSearchField name={`${actionName}.warehouse`} />
          </Box>
          <LocationMultiSelectField
            parentName={`${actionName}.warehouse`}
            name={`${actionName}.locations`}
            params={params}
          />
        </>
      )}
      {action === SET_STRATEGY && <PutawayStrategyField name={actionName} />}
    </>
  )
}

PutawayCreatedAction.propTypes = {
  action: PropTypes.string.isRequired,
  actionName: PropTypes.string.isRequired
}

export default PutawayCreatedAction
