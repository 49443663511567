import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import { Filter } from 'storfox-filter'
import { prop } from 'ramda'

import { arrayToParams, escapeAtob, unescapeBtoa } from '~/utils'
import LocationWarehouseMultiSelectField from '~/components/Fields/LocationWarehouseMultiSelectField'
import TextField from '~/components/Fields/TextField'
import WarehouseMinimalSearchField from '~/components/Fields/WarehouseMinimalSearchField'
import Accordion from '~/components/Accordion'
import ContainerTypeMultiSelectField from '~/components/Fields/ContainerTypeMultiSelectField'

import ContainerStatusField from './ContainerStatusField'
import ContentSelectField from './ContentSelectField'

import { CONTAINER_FILTER_NAME as name } from '../../../constants'

export const toParams = values => {
  const status = prop('status', values)
  const content = prop('content', values)
  const supplier = unescapeBtoa(prop('supplier', values))
  const warehouses = arrayToParams('warehouses', values)
  const containerTypes = arrayToParams('containerTypes', values)
  const locations = arrayToParams('locations', values)
  const minTotal = prop('minTotal', values)
  const maxTotal = prop('maxTotal', values)

  return {
    status,
    content,
    supplier,
    warehouses,
    containerTypes,
    locations,
    minTotal,
    maxTotal
  }
}

export const toValues = params => {
  const status = prop('status', params)
  const content = prop('content', params)
  const supplier = escapeAtob(prop('supplier', params))
  const warehouses = escapeAtob(prop('warehouses', params))
  const containerTypes = escapeAtob(prop('containerTypes', params))
  const locations = escapeAtob(prop('locations', params))
  const minTotal = prop('minTotal', params)
  const maxTotal = prop('maxTotal', params)

  return {
    status,
    content,
    supplier,
    warehouses,
    containerTypes,
    locations,
    minTotal,
    maxTotal
  }
}

export const transformer = { toParams, toValues }

export const containerFilterOptions = {
  name,
  transformer
}

function ContainerFilterForm (props) {
  return (
    <Filter {...props}>
      <Accordion title="General" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <ContainerStatusField />
          </Grid>
          <Grid item={true} xs={12}>
            <ContainerTypeMultiSelectField />
          </Grid>
          <Grid item={true} xs={12}>
            <WarehouseMinimalSearchField />
          </Grid>
          <Grid item={true} xs={12}>
            <LocationWarehouseMultiSelectField
              warehousesName="warehouses"
              name="locations"
            />
          </Grid>
          <Grid item={true} xs={12}>
            <ContentSelectField name="content" />
          </Grid>
        </Grid>
      </Accordion>
      <Accordion title="Total ($)">
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={6}>
            <TextField
              data-cy="minTotal"
              name="minTotal"
              label="Min ($)"
            />
          </Grid>
          <Grid item={true} xs={6}>
            <TextField
              data-cy="maxTotal"
              name="maxTotal"
              label="Max ($)"
            />
          </Grid>
        </Grid>
      </Accordion>
    </Filter>
  )
}

ContainerFilterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
}

export default ContainerFilterForm
