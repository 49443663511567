import PropTypes from 'prop-types'
import React from 'react'
import { path, prop } from 'ramda'
import Box from '@mui/material/Box'
import { TableCell } from '@mui/material'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'

import ImageCdn from '~/components/Image'
import Avatar from '~/components/Avatar'
import usePreview from '~/hooks/usePreview'
import PreviewCard from '~/components/Preview'

function AvatarProductPreview ({ unit }) {
  const {
    isOpen,
    previewPositionX,
    previewPositionY,
    handleMouseEnter,
    handleMouseLeave
  } = usePreview()

  const unitNumber = prop('unitNumber', unit)
  const trackSerialNumbers = prop('trackSerialNumbers', unit)
  const serialNumber = prop('serialNumber', unit)
  const variant = prop('variant', unit)
  const variantName = prop('name', variant)
  const src = prop('defaultImage', variant)
  const sku = prop('sku', variant)
  const fromContainer = path(['container', 'number'], unit)

  return (
    <Box
      position="relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Box position="relative" sx={{ width: '40px' }}>
        <Avatar alt={variantName} src={src} />
      </Box>
      {isOpen && (
        <PreviewCard positionY={previewPositionY} positionX={previewPositionX}>
          <Box width="100%" height="140px">
            <ImageCdn src={src} alt={variantName} />
          </Box>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell colSpan={2}>
                  <Box mb={1} mt={1}>
                    <Typography variant="h5" component="h2">
                      {variantName}
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>SKU</TableCell>
                <TableCell style={{ minWidth: 180 }}>{sku}</TableCell>
              </TableRow>
              {fromContainer && (
                <TableRow>
                  <TableCell>From container</TableCell>
                  <TableCell>{fromContainer}</TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell>Barcode</TableCell>
                <TableCell>{unitNumber}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Track serial</TableCell>
                <TableCell>{trackSerialNumbers ? 'Yes' : 'No'}</TableCell>
              </TableRow>
              {trackSerialNumbers ? (
                <TableRow>
                  <TableCell>Serial Number</TableCell>
                  <TableCell>{serialNumber}</TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        </PreviewCard>
      )}
    </Box>
  )
}

AvatarProductPreview.propTypes = {
  unit: PropTypes.object.isRequired
}

export default AvatarProductPreview
