import React from 'react'
import { useField } from 'react-final-form'
import { CheckboxField } from 'storfox-form-fields'
import FormControlLabel from '@mui/material/FormControlLabel'
import Card from '@mui/material/Card'
import { useTranslation } from 'react-i18next'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'

import CardHeader from '~/components/Cards/CardHeader'
import TextField from '~/components/Fields/TextField'
import MoneyField from '~/components/Fields/MoneyField'
import CountryField from '~/components/Fields/CountryField'

function VariantInternational () {
  const { t } = useTranslation()
  const isInternationalField = useField('isInternational')
  const isInternational = isInternationalField.input.value

  return (
    <Grid item={true} xs={12} lg={8}>
      <Card>
        <CardHeader
          title={
            <>
              <FormControlLabel
                control={<CheckboxField size="small" name="isInternational" data-cy="isInternational" />}
                label={t('This is an international variant')}
              />
            </>
          }
        />
        {isInternational && (
          <>
            <Divider />
            <CardContent>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={12}>
                  <TextField
                    data-cy="nameAlias"
                    name="nameAlias"
                    label="Name alias"
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <TextField
                    data-cy="name"
                    name="hsCode"
                    label="HS code"
                    required={true}
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <CountryField
                    data-cy="originCountry"
                    name="originCountry"
                    label="Origin country"
                  />
                </Grid>

                <Grid item={true} xs={12} lg={6}>
                  <MoneyField
                    data-cy="insuranceValue"
                    name="insuranceValue"
                    label="Insurance value"
                    required={true}
                  />
                </Grid>

                <Grid item={true} xs={12} lg={6}>
                  <MoneyField
                    data-cy="sellingCost"
                    name="sellingCost"
                    label="Selling cost"
                    required={true}
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <TextField
                    data-cy="customsDescription"
                    name="customsDescription"
                    label="Customs description"
                    multiline={true}
                    minRows={3}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </>
        )}
      </Card>
    </Grid>
  )
}

export default VariantInternational
