import React from 'react'
import PropTypes from 'prop-types'

import Status from '~/components/Status'

export const NEW = 'new'
export const PENDING = 'pending'
export const IN_PROGRESS = 'in_progress'
export const COMPLETED = 'completed'
export const PICKED = 'picked'
export const PUT = 'put'

export const PUTAWAY_STATUS = {
  NEW,
  PENDING,
  IN_PROGRESS,
  COMPLETED
}

export const PUTAWAY_STATUS_LABEL = {
  [NEW]: 'New',
  [PENDING]: 'Pending',
  [IN_PROGRESS]: 'In progress',
  [COMPLETED]: 'Completed'
}

const COLOR = {
  [PUTAWAY_STATUS.NEW]: 'success',
  [PUTAWAY_STATUS.PENDING]: 'warning',
  [PUTAWAY_STATUS.IN_PROGRESS]: 'warning',
  [PUTAWAY_STATUS.COMPLETED]: 'success',
}

function PutawayStatus ({ value }) {
  return (
    <Status
      label={PUTAWAY_STATUS_LABEL[value]}
      color={COLOR[value]}
    />
  )
}

PutawayStatus.propTypes = {
  value: PropTypes.oneOf([
    '',
    PUTAWAY_STATUS.NEW,
    PUTAWAY_STATUS.PENDING,
    PUTAWAY_STATUS.IN_PROGRESS,
    PUTAWAY_STATUS.COMPLETED
  ]).isRequired
}

export default PutawayStatus
