import React from 'react'
import PropTypes from 'prop-types'

import Status from '~/components/Status'

export const NEW = 'new'
export const PROCESSING = 'processing'
export const COMPLETED = 'completed'
export const CANCELLED = 'cancelled'

export const NAMES = {
  [NEW]: 'New',
  [PROCESSING]: 'Processing',
  [COMPLETED]: 'Completed',
  [CANCELLED]: 'cancelled'
}
const COLORS = {
  [NEW]: 'success',
  [PROCESSING]: 'warning',
  [COMPLETED]: 'success',
  [CANCELLED]: 'error',
}

function ReturnStatus ({ value }) {
  return (
    <Status label={NAMES[value]} color={COLORS[value]} />
  )
}

ReturnStatus.propTypes = {
  value: PropTypes.string.isRequired
}

export default ReturnStatus
