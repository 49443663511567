import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAllSearchParams } from 'storfox-route-hooks'
import { prop } from 'ramda'

import { escapeAtob, unescapeBtoa } from '~/utils'
import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import { useGoogleEvent } from '~/components/GoogleAnalytics/GoogleAnalytics'

import PicklistImportForm from '../components/PicklistImport'
import { usePicklistImport } from '../hooks'

function PicklistImportContainer () {
  const picklistImport = usePicklistImport()
  const navigate = useNavigate()
  const { payload } = useAllSearchParams()
  const { sendEvent } = useGoogleEvent()

  const key = prop('key', escapeAtob(payload))

  const handleSubmit = () => {
    return picklistImport.create({ key }).then(() => {
      navigate(ROUTES.PICKLIST_LIST_PATH)
      sendEvent({ eventAction: 'Upload File Picklist', eventCategory: 'Picklist' })
    })
  }

  const handleSubscribe = key => {
    navigate(`${ROUTES.PICKLIST_IMPORT_PATH}?payload=${unescapeBtoa({ key })}`, { replace: true })
    sendEvent({ eventAction: 'Validate File Picklist', eventCategory: 'Picklist' })
  }

  const title = 'Import picklists'

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      activeNav={NAV.PICKLISTS}
      isLoading={picklistImport.isLoading}
      breadcrumbs={breadcrumbs}
    >
      <PicklistImportForm
        title={title}
        isLoading={picklistImport.isLoading}
        onSubmit={handleSubmit}
        onSubscribe={handleSubscribe}
      />
    </DashboardLayout>
  )
}

export default PicklistImportContainer
