import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card/Card'
import { Divider } from '@mui/material'
import CardContent from '@mui/material/CardContent'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { CardHeader } from '~/components/Cards'
import WebHookSetInstruction from '~/components/Instructions/WebHookSetInstruction'

import ShipoxUpdate from './ShipoxUpdate'

function ShipoxIntegrationUpdateFormGeneral ({ showWebhookInstruction, webhookUrl }) {
  const { t } = useTranslation()

  return (
    <>
      {showWebhookInstruction && (
        <Box lg={6} mb={2} mt={2}>
          <WebHookSetInstruction
            webHookUrl={webhookUrl}
          />
        </Box>
      )}
      <Grid container={true}>
        <Grid item={true} xs={12}>
          <Box mb={3}>
            <Card>
              <CardHeader title={t('Integration')} />
              <Divider />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <ShipoxUpdate />
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

ShipoxIntegrationUpdateFormGeneral.propTypes = {
  showWebhookInstruction: PropTypes.bool,
  webhookUrl: PropTypes.string
}

export default ShipoxIntegrationUpdateFormGeneral
