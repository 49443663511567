import React from 'react'
import { FieldWrapper } from 'storfox-form-fields'
import { prop, propOr } from 'ramda'
import { useGetApi } from 'storfox-api-hooks'
import PropTypes from 'prop-types'

import CountableBarcode from '~/components/CountableBarcode'
import * as API from '~/constants/api'
import useDialog from '~/hooks/useDialog'

import TransferDialogForm from './TransferDialogForm'

function UnitsField ({ input, meta, item, dataCy, units, conditionCode, warehouseId }) {
  const unitListAPI = useGetApi(API.TRANSFER_UNITS, false)
  const { open, handleClose, handleOpen } = useDialog()

  const variant = prop('variant', item)
  const title = prop('name', variant)
  const image = prop('defaultImage', variant)
  const variantId = prop('id', variant)
  const sku = prop('sku', variant)
  const barcode = prop('barcode', variant)

  const count = propOr([], 'value', input).length

  const dialogTitle = { title, sku, barcode, image }

  const handleUnitNumberCheck = unitNumber => {
    const params = unitNumber ? {
      isAvailable: true,
      unitNumber,
      variantId,
      warehouseId,
      limit: 1000
    } : {
      isAvailable: true,
      variantId,
      warehouseId,
      limit: 1000
    }

    return unitListAPI.get(params)
  }

  const handleBarcodeCheck = value => {
    const barcodeItems = prop('barcodeItems', value)
    input.onChange(barcodeItems)
    handleClose()
    return Promise.resolve()
  }

  const barcodeItems = input.value

  return (
    <>
      <CountableBarcode
        count={count}
        onOpen={handleOpen}
        data-cy={dataCy}
        isError={meta.invalid}
      />
      <TransferDialogForm
        open={open}
        isLoading={unitListAPI.isLoading}
        conditionCode={conditionCode}
        units={units}
        initialValues={{ barcodeItems, open }}
        dialogTitle={dialogTitle}
        onClose={handleClose}
        onUnitNumberCheck={handleUnitNumberCheck}
        onSubmit={handleBarcodeCheck}
      />
    </>
  )
}

UnitsField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  units: PropTypes.array,
  conditionCode: PropTypes.string,
  dataCy: PropTypes.string,
  warehouseId: PropTypes.number
}

export default FieldWrapper(UnitsField)
