import React from 'react'
import PropTypes from 'prop-types'

import Status from '../Status/Status'

const ACTIVE = 'active'
const INACTIVE = 'inactive'

const NAMES = {
  [ACTIVE]: 'Active',
  [INACTIVE]: 'Inactive'
}

const COLORS = {
  [ACTIVE]: 'success',
  [INACTIVE]: 'error'
}

function ActivityStatus ({ value }) {
  return (
    <Status label={NAMES[value]} color={COLORS[value]} />
  )
}

ActivityStatus.propTypes = {
  value: PropTypes.oneOf([ACTIVE, INACTIVE]).isRequired
}

export default ActivityStatus
