export const CREDIT_CARD = 'card'
export const CASH = 'cash'
export const BANK_TRANSFER = 'bank'
export const TRADE_FINANCE = 'trade_finance'

export const PAYMENT_TYPES = {
  [CREDIT_CARD]: 'Credit card',
  [CASH]: 'Cash',
  [BANK_TRANSFER]: 'Bank transfer',
  [TRADE_FINANCE]:  'Trade finance'
}
