import React, { useCallback, useEffect, useState } from 'react'
import { path, pathOr, prop, propOr } from 'ramda'
import PropTypes from 'prop-types'
import Paper from '@mui/material/Paper'
import { useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import { useConfirm } from 'storfox-confirm-dialog'

import { PICKLIST_TABS } from '~/constants/tabs'
import { DataGrid } from '~/components/Table'
import { Button, DiscardButton } from '~/components/Buttons'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import Divider from '~/components/Divider'
import VerticalAlignment from '~/components/VerticalAlignment'
import Subtext from '~/components/Subtext'
import TextOverflow from '~/components/TextOverflow'
import useDialog from '~/hooks/useDialog'
import * as NAV from '~/constants/nav-titles'
import History from '~/components/History'
import ColoredStatusBox from '~/components/StatusBox/ColoredStatusBox'
import { useWarehouse } from '~/components/Profile/hooks'
import { COMPLETED, IN_PROGRESS, NEW, PENDING, CANCELLED } from '~/components/Statuses/PicklistStatus'

import CompleteDialogForm from './CompleteDialogForm'
import PickingDialogForm from './PickingDialogForm'
import ReadonlyInstruction from './ReadonlyInstruction'
import More from './More'
import PickerDialogForm from './PickerDialogForm'

import { PicklistDetailTabs } from './'

const colors = {
  success: '#4caf50',
  danger: '#ff9800'
}

const getRowItem = (lineItems) => {
  return lineItems.map((item) => {
    const id = prop('id', item)
    const guid = prop('guid', item)
    const unit = prop('unit', item)
    const lineItemQuantity = prop('quantity', item)
    const variant = prop('variant', unit)
    const productName = prop('name', variant)
    const sku = prop('sku', variant)
    const unitNumber = prop('unitNumber', unit)
    const orderReferenceNumber = prop('orderReferenceNumber', item)
    const product = { productName, sku }

    const serialNumber = prop('serialNumber', unit)
    const batchNumber = prop('batchNumber', unit)

    const picked = prop('pickedQuantity', item)
    const toPick = lineItemQuantity - picked
    const location = {
      color: toPick ? colors['danger'] : colors['success'],
      location: pathOr('', ['location', 'locationId'], unit)
    }

    const fromContainer = pathOr('', ['container', 'number'], unit)
    const toContainer = pathOr('', ['container', 'number'], item)
    const orderId = prop('orderNumber', item)

    return {
      id,
      serialNumber,
      batchNumber,
      guid,
      unitNumber,
      location,
      product,
      fromContainer,
      picked,
      toPick,
      toContainer,
      orderId,
      orderReferenceNumber,
      item
    }
  })
}

const columns = [
  {
    width: 210,
    headerName: 'Location',
    field: 'location',
    sortable: false,
    renderCell: ({ value }) => (
      <ColoredStatusBox color={value.color}>
        {value.location}
      </ColoredStatusBox>
    )
  },
  {
    width: 150,
    headerName: 'Order #',
    field: 'orderId',
    sortable: false
  },
  {
    width: 150,
    headerName: 'Reference #',
    field: 'orderReferenceNumber',
    sortable: false
  },
  {
    width: 300,
    headerName: 'Product',
    field: 'product',
    sortable: false,
    renderCell: ({ value }) => (
      <VerticalAlignment
        primary={(
          <TextOverflow selfTooltip={true} data-cy="variantName">
            {value.productName}
          </TextOverflow>
        )}
        secondary={<Subtext lines={1}>{value.sku}</Subtext>}
      />
    )
  },
  {
    width: 300,
    headerName: 'Serial number',
    field: 'serialNumber',
    sortable: false,
    renderCell: ({ value }) => (
      <TextOverflow data-cy="serialNumber">{value}</TextOverflow>
    )
  },
  {
    width: 300,
    headerName: 'Batch number',
    field: 'batchNumber',
    sortable: false,
    renderCell: ({ value }) => (
      <TextOverflow data-cy="batchNumber">{value}</TextOverflow>
    )
  },
  {
    width: 200,
    headerName: 'From container',
    field: 'fromContainer',
    sortable: false,
  },
  {
    width: 150,
    headerName: 'To pick',
    field: 'toPick',
    sortable: false
  },
  {
    width: 100,
    headerName: 'Picked',
    field: 'picked',
    sortable: false
  },
  {
    width: 250,
    headerName: 'To container',
    field: 'toContainer',
    sortable: false
  }
]

function PicklistDetail (props) {
  const {
    pageTitle,
    pageTitleLoading,
    picklistDetail,
    picklistNumber,
    packingLocationList,
    validationLoading,
    notificationPayload,
    history,
    onSubmit,
    onProcess,
    onProgress,
    onComplete,
    processPendingNotifications,
    isCompleteLoading,
    onPickerChange,
    onUnitAlter,
    onSkip,
    getDetail,
    onGeneratePdf,
    alterLoading,
    onCancel
  } = props

  const { guid, tab } = useParams()
  const [initialValues, setInitialValues] = useState(null)
  const pickingDialog = useDialog()
  const completeDialog = useDialog()
  const pickerDialog = useDialog()
  const onConfirm = useConfirm()
  const { hasOwnWarehouseById } = useWarehouse()

  const pickingDialogOpen = pickingDialog.open

  const isLoading = prop('isLoading', picklistDetail)
  const detail = prop('detail', picklistDetail)
  const lineItemGroups = propOr([], 'lineItems', detail)
  const picker = prop('picker', detail)
  const status = prop('status', detail)
  const pickedQuantity = prop('pickedQuantity', detail)

  const rows = getRowItem(lineItemGroups)

  const warehouseId = path(['warehouse', 'id'], detail)
  const isClickable = status === IN_PROGRESS
  const totalLineItemCount = lineItemGroups.reduce((a, b) => pathOr(0, ['unit', 'quantity'], b) + a, 0)
  const totalLineItemPickedCount = lineItemGroups.reduce((a, b) => propOr(0, 'pickedQuantity', b) + a, 0)

  const isOwner = hasOwnWarehouseById(warehouseId)

  const pickerDialogOpenDisabled = (
    isLoading || status === COMPLETED
  )

  const skipDisabled = (
    isLoading || !isOwner || status === COMPLETED || status === CANCELLED
  )

  const initialCompleteDialogValues = {
    open: completeDialog.open,
    guid,
    toLocationId: prop(0, packingLocationList)
  }

  const handleUnitAlterNotificationReceive = useCallback(processPendingNotifications, [])

  useEffect(() => {
    if (!pickingDialogOpen && notificationPayload) {
      handleUnitAlterNotificationReceive(notificationPayload)
    }
  }, [handleUnitAlterNotificationReceive, notificationPayload, pickingDialogOpen])

  const handleSubmit = formValues =>
    onComplete(formValues)
      .then(() => completeDialog.handleClose())

  const handleSetInitValues = values => {
    const row = values.row
    const item = prop('item', row)
    const picked = prop('pickedQuantity', item)
    const toPick = path(['unit', 'quantity'], item)

    if (picked < toPick && isClickable) {
      setInitialValues(item)
      pickingDialog.handleOpen()
    }
  }

  const handleSubmitPicklistDetail = formValues =>
    onSubmit(formValues).then(() => pickingDialog.handleClose())

  const handleComplete = () => {
    return completeDialog.handleOpen()
  }

  const handlePickerChange = formValues =>
    onPickerChange(formValues)
      .then(() => pickerDialog.handleClose())
      .then(() => getDetail())

  const isBeforeProgress = status === NEW || status === PENDING

  const disabled = (
    status === COMPLETED ||
    status === PENDING ||
    isLoading ||
    totalLineItemCount !== totalLineItemPickedCount
  )

  const handleConfirm = () =>
    onConfirm({ message: `Are you sure, you want to skip picklist ${picklistNumber}?` })
      .agree(onSkip)
      .disagree()

  const handleUnitAlter = values =>
    onUnitAlter(values)
      .then(() => pickingDialog.handleClose())

  return (
    <>
      <Container>
        <Header>
          <PageTitle
            pageTitle={pageTitle}
            parentTitle={NAV.PICKLISTS}
            pageTitleLoading={pageTitleLoading}
            rightButton={(
              <>
                <DiscardButton disabled={isLoading} />
                {status === NEW && (
                  <Button
                    data-cy="process"
                    variant="contained"
                    type="submit"
                    onClick={onProcess}
                  >
                    Process
                  </Button>
                )}
                {status === IN_PROGRESS && (
                  <Button
                    data-cy="complete"
                    variant="contained"
                    type="submit"
                    disabled={disabled}
                    onClick={handleComplete}
                  >
                    Complete
                  </Button>
                )}
                {status === PENDING && (
                  <Button
                    data-cy="progress"
                    variant="contained"
                    type="submit"
                    onClick={onProgress}
                  >
                    Progress
                  </Button>
                )}
                <Button
                  data-cy="skip"
                  variant="contained"
                  type="button"
                  disabled={skipDisabled}
                  onClick={handleConfirm}
                >
                  Skip
                </Button>
                <More
                  onPickerDialogOpen={pickerDialog.handleOpen}
                  pickerDialogOpenDisabled={pickerDialogOpenDisabled}
                  onGeneratePdf={onGeneratePdf}
                  onComplete={handleComplete}
                  onCancel={onCancel}
                  cancelDisabled={pickedQuantity || status === CANCELLED || status === COMPLETED}
                />
              </>
            )}
          />
        </Header>

        <Content>
          <PicklistDetailTabs tab={tab} />
          <Divider />
          {isBeforeProgress && (
            <Box mb={2}>
              <ReadonlyInstruction status={status} />
            </Box>
          )}

          {tab === PICKLIST_TABS.GENERAL && (
            <Paper>
              <DataGrid
                name="picklist"
                columns={columns}
                onRowClick={isClickable ? handleSetInitValues : null}
                isLoading={isLoading}
                fixedColumnCount={1}
                space={true}
                rowHeight={48}
                rows={rows}
                pagination={false}
                autoPageSize={false}
              />
            </Paper>
          )}

          {tab === PICKLIST_TABS.HISTORY && <History history={history} />}
        </Content>
      </Container>

      <PickingDialogForm
        isLoading={validationLoading}
        initialValues={initialValues}
        onSubmit={handleSubmitPicklistDetail}
        onClose={pickingDialog.handleClose}
        open={pickingDialog.open}
        onUnitAlter={handleUnitAlter}
        alterLoading={alterLoading}
      />

      <CompleteDialogForm
        onSubmit={handleSubmit}
        disabled={isCompleteLoading}
        initialValues={initialCompleteDialogValues}
        handleClose={completeDialog.handleClose}
        open={completeDialog.open}
        warehouseId={warehouseId}
      />

      <PickerDialogForm
        open={pickerDialog.open}
        initialValues={{ picker }}
        onClose={pickerDialog.handleClose}
        onSubmit={handlePickerChange}
      />
    </>
  )
}

PicklistDetail.propTypes = {
  picklistDetail: PropTypes.object.isRequired,
  pageTitleLoading: PropTypes.bool.isRequired,
  validationLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onProgress: PropTypes.func.isRequired,
  onUnitAlter: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  onProcess: PropTypes.func.isRequired,
  notificationPayload: PropTypes.object,
  isCompleteLoading: PropTypes.bool.isRequired,
  getDetail: PropTypes.func.isRequired,
  processPendingNotifications: PropTypes.func.isRequired,
  onPickerChange: PropTypes.func.isRequired,
  onSkip: PropTypes.func.isRequired,
  onGeneratePdf: PropTypes.func.isRequired,
  pageTitle: PropTypes.string,
  history: PropTypes.object,
  picklistNumber: PropTypes.string,
  packingLocationList: PropTypes.array.isRequired,
  alterLoading: PropTypes.bool,
  onCancel: PropTypes.func
}

export default PicklistDetail
