import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { map, prop } from 'ramda'
import { generatePath, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import EditIcon from '@mui/icons-material/Edit'

import { MoreListButton, NewButton } from '~/components/Buttons'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import TextOverflow from '~/components/TextOverflow'
import ActivityStatus from '~/components/ActivityStatus'
import { Table, TableHeader } from '~/components/Table'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import { SUPPLIER_DETAIL_TABS, SUPPLIER_UPDATE_TABS } from '~/constants/tabs'
import TableLink from '~/components/Link'

import BulkActionButton from './BulkActionButton'

const getDetailPath = guid => {
  const params = { guid, tab: SUPPLIER_DETAIL_TABS.GENERAL }
  return generatePath(ROUTES.SUPPLIER_DETAIL_PATH, params)
}

export const SUPPLIER_COLUMNS = [
  {
    width: 250,
    headerName: 'Name',
    field: 'name',
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        <TextOverflow selfTooltip={true}>{value}</TextOverflow>
      </TableLink>
    )
  },
  {
    width: 150,
    headerName: 'Short name',
    field: 'shortName',
    renderCell: ({ value }) => (
      <TextOverflow selfTooltip={true}>{value}</TextOverflow>
    )
  },
  {
    width: 150,
    headerName: 'Supplier ID',
    field: 'id'
  },
  {
    width: 150,
    headerName: 'City',
    field: 'city'
  },
  {
    width: 200,
    headerName: 'Email',
    field: 'email'
  },
  {
    width: 150,
    headerName: 'Phone',
    field: 'phone'
  },
  {
    width: 200,
    headerName: 'Primary contact',
    field: 'primaryName',
    renderCell: ({ value }) => (
      <TextOverflow selfTooltip={true}>{value}</TextOverflow>
    )
  },
  {
    width: 100,
    headerName: 'Status',
    field: 'status',
    renderCell: ({ value }) => (
      <ActivityStatus value={value} />
    )
  }
]

const getRowsFromResults = map(item => {
  const id = prop('id', item)
  const guid = prop('guid', item)
  const name = prop('companyName', item)
  const shortName = prop('shortName', item)
  const city = prop('city', item)
  const email = prop('email', item)
  const phone = prop('phoneNumber', item)
  const firstName = prop('contactFirstName', item)
  const lastName = prop('contactLastName', item)
  const primaryName = `${firstName} ${lastName}`
  const status = prop('status', item)
  const link = getDetailPath(guid)

  return {
    id,
    guid,
    name,
    shortName,
    city,
    email,
    phone,
    primaryName,
    status,
    link
  }
})

function SupplierTable ({ list, filter, ordering, onListRefetch, onSuppliersDelete, bulkActionLoading }) {
  const [tableRef, setTableRef] = useState(null)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const isLoading = prop('isLoading', list)
  const results = prop('results', list)
  const count = prop('count', list)
  const columns = prop('filteredColumns', ordering)

  const handleEdit = useCallback(guid => {
    const editPath = generatePath(ROUTES.SUPPLIER_UPDATE_PATH, { guid, tab: SUPPLIER_UPDATE_TABS.GENERAL })
    navigate(editPath)
  }, [navigate])

  const actionColumns = useMemo(() => [
    ...columns,
    {
      width: 50,
      resizable: false,
      field: 'actions',
      type: 'actions',
      getActions: ({ row }) => [
        <GridActionsCellItem
          label="Edit"
          onClick={() => handleEdit(row.guid)}
          showInMenu={true}
          icon={<EditIcon />}
        />
      ]
    }
  ], [columns, handleEdit])

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={t(NAV.SUPPLIERS)}
          parentTitle={t(NAV.PURCHASES)}
          rightButton={(
            <>
              <BulkActionButton onSuppliersDelete={onSuppliersDelete} loading={bulkActionLoading} />
              <NewButton path={ROUTES.SUPPLIER_CREATE_PATH} />
            </>
          )}
        />
      </Header>

      <Content>
        <TableHeader
          filter={filter}
          filterOpen={filter.handleOpen}
          orderingOpen={ordering.handleOpen}
          ordering={ordering}
        >
          <MoreListButton tableRef={tableRef} />
        </TableHeader>
        <Table
          isLoading={isLoading}
          columns={actionColumns}
          getRowsFromResults={getRowsFromResults}
          results={results}
          count={count}
          checkboxSelection={true}
          primaryKey="guid"
          ordering={ordering}
          pinnedColumns={['name']}
          onRefetch={onListRefetch}
          setTableRef={setTableRef}
        />
      </Content>
    </Container>
  )
}

SupplierTable.propTypes = {
  list: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  onSuppliersDelete: PropTypes.func.isRequired,
  onListRefetch: PropTypes.func.isRequired,
  bulkActionLoading: PropTypes.bool
}

export default SupplierTable
