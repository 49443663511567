import React from 'react'
import { Ordering } from 'storfox-filter'

import { FILTER_NAME as name } from '../../constants'
import { SALE_ORDER_COLUMNS as tableColumns } from '../SaleOrderList/SaleOrderTable'

export const saleOrderOrderingOptions = {
  name,
  tableColumns
}

function SaleOrderOrderingForm (props) {
  return (
    <Ordering {...props} />
  )
}

export default SaleOrderOrderingForm
