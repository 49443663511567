import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { path, prop, propOr } from 'ramda'
import Card from '@mui/material/Card'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'

import { CardHeader } from '~/components/Cards'
import Subtext from '~/components/Subtext'
import VerticalAlignment from '~/components/VerticalAlignment'
import Avatar from '~/components/Avatar/Avatar'
import useDialog from '~/hooks/useDialog'
import { HoverableRow } from '~/components/Table'
import SkeletonList from '~/components/Skeleton'

import ColoredStatusBox from '../ColoredStatusBox'
import ReceiveItemDialog from '../../components/ReceivePurchaseOrder/ReceiveItemDialog'

export const getStatus = (ordered, received) => {
  if (received > ordered) {
    return 'danger'
  }
  if (ordered === received) {
    return 'success'
  }
  return 'warning'
}

const colors = {
  success: '#3b35bb',
  danger: 'red',
  warning: '#F88C2A'
}

function DetailLineItems ({ isLoading, lineItems, getVariantDetail }) {
  const { t } = useTranslation()
  const { open, handleClose, handleOpen } = useDialog()
  const [variant, setVariant] = useState(null)
  const [dialogLoading, setDialogLoading] = useState(false)

  const handleRowClick = values => {
    const ordered = prop('quantity', values)
    const variantGuid = path(['variant', 'guid'], values)

    handleOpen()
    setDialogLoading(true)
    getVariantDetail(variantGuid)
      .then(res => setVariant({ ...res.data.result, ordered }))
      .finally(() => setDialogLoading(false))
  }

  const handleDialogClose = () => {
    handleClose()
    setVariant(null)
  }

  return (
    <>
      <Card>
        <CardHeader title={t('Line items')} />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('Image')}</TableCell>
                <TableCell>{t('Product')}</TableCell>
                <TableCell>{t('Ordered')}</TableCell>
                <TableCell>{t('Received')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoading
                ? lineItems.map((item, index) => {
                  const variant = prop('variant', item)
                  const ordered = propOr(0, 'quantity', item)
                  const received = propOr(0, 'received', item)
                  const sku = prop('sku', variant)
                  const imagePath = prop('defaultImage', variant)
                  const name = path(['variant', 'name'], item)

                  return (
                    <HoverableRow key={index} onClick={() => handleRowClick(item)}>
                      <TableCell style={{ position: 'relative' }}>
                        <ColoredStatusBox color={colors[getStatus(ordered, received)]}>
                          <Avatar
                            alt={name}
                            src={imagePath}
                          />
                        </ColoredStatusBox>
                      </TableCell>
                      <TableCell style={{ minWidth: 400 }}>
                        <VerticalAlignment
                          primary={name}
                          secondary={<Subtext lines={1}>{sku}</Subtext>}
                        />
                      </TableCell>
                      <TableCell>{ordered}</TableCell>
                      <TableCell>{received}</TableCell>
                    </HoverableRow>
                  )
                })
                : (
                  <SkeletonList
                    rowCount={5}
                    columnCount={4}
                    lastRowBorderHidden={true}
                  />
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <ReceiveItemDialog
        open={open}
        isLoading={dialogLoading}
        variantDetail={variant}
        onClose={handleDialogClose}
      />
    </>
  )
}

DetailLineItems.defaultProps = {
  lineItems: []
}

DetailLineItems.propTypes = {
  isLoading: PropTypes.bool,
  lineItems: PropTypes.array.isRequired,
  getVariantDetail: PropTypes.func.isRequired
}

export default DetailLineItems
