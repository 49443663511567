import React from 'react'
import { SearchField } from 'storfox-query-builder'
import { prop } from 'ramda'
import PropTypes from 'prop-types'

import * as API from '~/constants/api'

const getOptionValue = item => {
  if (item) {
    const guid = prop('guid', item)
    const name = prop('name', item)

    return { guid, name }
  }

  return null
}

function CarrierSearchField ({ onChange, ...props }) {
  return (
    <SearchField
      label="Carrier"
      api={API.SHIPMENT_INTEGRATIONS}
      primaryKey="guid"
      getOptionValue={getOptionValue}
      {...props}
      onValueChange={(value) => {
        onChange(null, value)
      }}
    />
  )
}

CarrierSearchField.propTypes = {
  onChange: PropTypes.func.isRequired
}

export default CarrierSearchField
