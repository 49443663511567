import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import { map, pick, pipe, prop, propOr } from 'ramda'
import { Filter } from 'storfox-filter'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { PositiveFloatField, DateRangeField } from 'storfox-form-fields'

import { escapeAtob, getSeparatedDateRange, unescapeBtoa } from '~/utils'
import WarehouseMultiSelectField from '~/components/Fields/WarehouseMultiSelectField'
import Accordion from '~/components/Accordion'

import ReasonMultiSearchField from '../Fields/ReasonMultiSearchField'
import AdjustmentStatusField from '../Fields/AdjustmentStatusField'
import { FILTER_NAME as name } from '../../constants'

const getWarehouseIds = pipe(
  prop('warehouses'),
  map(pick(['id', 'name']))
)

const getReasonIds = pipe(
  prop('reasons'),
  map(pick(['id', 'name']))
)

export const toParams = values => {
  const status = prop('status', values)
  const totalPriceStart = prop('totalPriceStart', values)
  const totalPriceEnd = prop('totalPriceEnd', values)
  const warehouseList = propOr([], 'warehouses', values)
  const reasonList = propOr([], 'reasons', values)

  const warehouses = warehouseList.length ? unescapeBtoa(getWarehouseIds(values)) : null
  const reasons = reasonList.length ? unescapeBtoa(getReasonIds(values)) : null

  const [updatedAtStart, updatedAtEnd] = getSeparatedDateRange(prop('updatedAt', values))

  return {
    status,
    warehouses,
    reasons,
    updatedAtStart,
    updatedAtEnd,
    totalPriceStart,
    totalPriceEnd
  }
}

export const toValues = params => {
  const status = prop('status', params)
  const totalPriceStart = prop('totalPriceStart', params)
  const totalPriceEnd = prop('totalPriceEnd', params)
  const reasons = escapeAtob(prop('reasons', params))
  const warehouses = escapeAtob(prop('warehouses', params))

  const updatedAt = [prop('updatedAtStart', params), prop('updatedAtEnd', params)]

  return {
    status,
    warehouses,
    reasons,
    updatedAt,
    totalPriceStart,
    totalPriceEnd
  }
}

export const transformer = { toParams, toValues }

export const adjustmentFilterOptions = {
  name,
  transformer
}

function AdjustmentFilterForm (props) {
  const { t } = useTranslation()

  return (
    <Filter {...props}>
      <Accordion title="General" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <AdjustmentStatusField />
          </Grid>
          <Grid item={true} xs={12}>
            <WarehouseMultiSelectField />
          </Grid>
          <Grid item={true} xs={12}>
            <ReasonMultiSearchField />
          </Grid>
        </Grid>
      </Accordion>
      <Accordion title="Date" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <Typography variant="body2" gutterBottom={true}>
              {t('Updated')}:
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <DateRangeField name="updatedAt" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Accordion>
      <Accordion title="Total ($)">
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={6}>
            <PositiveFloatField
              data-cy="totalPriceStart"
              name="totalPriceStart"
              label="Min ($)"
            />
          </Grid>
          <Grid item={true} xs={6}>
            <PositiveFloatField
              data-cy="totalPriceEnd"
              name="totalPriceEnd"
              label="Max ($)"
            />
          </Grid>
        </Grid>
      </Accordion>
    </Filter>
  )
}

AdjustmentFilterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
}

export default AdjustmentFilterForm
