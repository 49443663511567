import { sprintf } from 'sprintf-js'
import { usePickSearchParams } from 'storfox-route-hooks'
import { useCreate, useDelete, useDetail, useList, useUpdate } from 'storfox-api-hooks'

import * as API from '~/constants/api'

export const useLocationDetail = guid => {
  const url = sprintf(API.LOCATION_DETAIL, guid)
  return useDetail(url)
}

export const useLocationCreate = () => {
  return useCreate(API.LOCATION_CREATE)
}

export const useLocationUpdate = guid => {
  const url = sprintf(API.LOCATION_UPDATE, guid)
  return useUpdate(url)
}

export const useLocationContent = (guid, autoSend) => {
  const { page, limit } = usePickSearchParams(['page', 'limit'])
  const url = sprintf(API.LOCATION_CONTENT, guid)

  return useList(url, { page, limit }, autoSend)
}

export const useLocationBulkRemove = () => {
  return useDelete(API.LOCATIONS_DELETE)
}

export const useWarehouseDetail = guid => {
  const url = sprintf(API.WAREHOUSE_DETAIL, guid)
  return useDetail(url)
}
