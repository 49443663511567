import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { map, prop } from 'ramda'
import { generatePath } from 'react-router-dom'

import Container, { Content, Header } from '~/components/Container'
import PageTitle from '~/components/PageTitle'
import { MoreListButton, NewButton } from '~/components/Buttons'
import TextOverflow from '~/components/TextOverflow'
import { Table, TableHeader } from '~/components/Table'
import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'
import TableLink from '~/components/Link'

import BulkActionButton
from './BulkActionButton'

const getDetailPath = guid => {
  return generatePath(ROUTES.DELIVERY_METHOD_UPDATE_PATH, { guid })
}

export const DELIVERY_METHOD_COLUMNS = [
  {
    field: 'name',
    headerName: 'Name',
    width: 200,
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        <TextOverflow selfTooltip={true}>{value}</TextOverflow>
      </TableLink>
    )
  },
  {
    field: 'code',
    headerName: 'Code',
    width: 200
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 400,
    renderCell: ({ value }) => (
      <TextOverflow selfTooltip={true}>{value}</TextOverflow>
    )
  }
]

const getRowsFromResults = map(item => {
  const guid = prop('guid', item)
  const code = prop('code', item)
  const name = prop('name', item)
  const description = prop('description', item)
  const link = getDetailPath(guid)

  return {
    guid,
    code,
    name,
    description,
    link
  }
})

function DeliveryMethodList (props) {
  const { pageTitle, ordering, deliveryMethodList, onListRefetch, onDeliveryMethodsDelete, bulkActionLoading } = props
  const [tableRef, setTableRef] = useState(null)
  const results = prop('results', deliveryMethodList)
  const isLoading = prop('isLoading', deliveryMethodList)
  const count = prop('count', deliveryMethodList)
  const columns = prop('filteredColumns', ordering)

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={pageTitle}
          parentTitle={NAV.CONFIGURATION}
          rightButton={(
            <>
              <BulkActionButton onDeliveryMethodsDelete={onDeliveryMethodsDelete} loading={bulkActionLoading} />
              <NewButton path={ROUTES.DELIVERY_METHOD_CREATE_PATH} />
            </>
          )}
        />
      </Header>
      <Content>
        <TableHeader
          orderingOpen={ordering.handleOpen}
          ordering={ordering}
        >
          <MoreListButton tableRef={tableRef} />
        </TableHeader>
        <Table
          getRowsFromResults={getRowsFromResults}
          results={results}
          columns={columns}
          isLoading={isLoading}
          count={count}
          primaryKey="guid"
          fixedColumnCount={['name']}
          checkboxSelection={true}
          ordering={ordering}
          onRefetch={onListRefetch}
          setTableRef={setTableRef}
        />
      </Content>
    </Container>
  )
}

DeliveryMethodList.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  onDeliveryMethodsDelete: PropTypes.func.isRequired,
  onListRefetch: PropTypes.func.isRequired,
  ordering: PropTypes.object.isRequired,
  deliveryMethodList: PropTypes.object,
  bulkActionLoading: PropTypes.bool
}

export default DeliveryMethodList
