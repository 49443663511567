import React from 'react'
import { useFilter, useOrdering } from 'storfox-filter'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import ErrorLink from '~/components/ErrorLink'
import useMessages from '~/hooks/useMessages'
import { useTableSelects } from '~/components/TableValues/hooks'

import { useSupplierList, useSuppliersDelete } from '../hooks'
import {
  SupplierFilterForm,
  supplierFilterOptions,
  SupplierOrderingForm,
  supplierOrderingOptions,
  SupplierTable
} from '../components/SupplierList'

function SupplierListContainer () {
  const snackbar = useSnackbar()
  const messages = useMessages()
  const { selects, resetTableSelects } = useTableSelects()

  const supplierList = useSupplierList()
  const suppliersDelete = useSuppliersDelete()

  const filter = useFilter(supplierFilterOptions)
  const ordering = useOrdering(supplierOrderingOptions)

  const handleSuppliersDelete = () =>
    suppliersDelete.delete({ guids: selects })
      .then(() => supplierList.getList())
      .then(() => snackbar({ message: messages.DELETE_SUCCESS }))
      .then(() => resetTableSelects())
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const breadcrumbs = { title: NAV.SUPPLIERS }

  return (
    <DashboardLayout
      title={NAV.SUPPLIERS}
      activeNav={NAV.SUPPLIERS}
      breadcrumbs={breadcrumbs}
    >
      <SupplierFilterForm {...filter} />
      <SupplierOrderingForm {...ordering} />

      <SupplierTable
        list={supplierList}
        filter={filter}
        ordering={ordering}
        onListRefetch={supplierList.getListByParams}
        onSuppliersDelete={handleSuppliersDelete}
        bulkActionLoading={suppliersDelete.isLoading}
      />
    </DashboardLayout>
  )
}

export default SupplierListContainer
