import React from 'react'
import PropTypes from 'prop-types'
import Divider from '@mui/material/Divider'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

import Tabs from '~/components/Tabs'
import { LISTING_UPDATE_PATH } from '~/constants/routes'
import { LISTING_TABS } from '~/constants/tabs'

const TABS = [
  { value: LISTING_TABS.VARIANT, label: 'Variant' },
  { value: LISTING_TABS.PRODUCT, label: 'Product' }
]

function ListingUpdateTabs ({ value }) {
  const navigate = useNavigate()
  const { guid } = useParams()
  const onChange = (event, tab) => {
    const redirect = generatePath(LISTING_UPDATE_PATH, { guid, tab })
    navigate(redirect)
  }

  return (
    <>
      <Tabs
        tabs={TABS}
        value={value}
        onChange={onChange}
      />
      <Divider />
    </>
  )
}

ListingUpdateTabs.propTypes = {
  value: PropTypes.string.isRequired,
}

export default ListingUpdateTabs
