import React from 'react'

import SelectField from '~/components/Fields/SelectField'

const OPTIONS = [
  {
    value: '100',
    name: 'PPD (Prepaid)'
  },
  {
    value: '200',
    name: 'COD (Cash On Delivery)'
  }
]

function PaymentMethodField (props) {
  return (
    <SelectField
      label="Payment method"
      options={OPTIONS}
      {...props}
    />
  )
}

export default PaymentMethodField
