import React from 'react'
import PropTypes from 'prop-types'
import { generatePath, useParams } from 'react-router-dom'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'

import { getRouteTab } from '~/utils'
import Container, { Content, Header } from '~/components/Container'
import PageTitle from '~/components/PageTitle'
import { EditButton } from '~/components/Buttons'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import { VARIANT_DETAIL_TABS, VARIANT_UPDATE_TABS } from '~/constants/tabs'
import History from '~/components/History'
import LoaderProvider from '~/components/Loader'

import VariantDetailGeneral from './General/VariantDetailGeneral'
import VariantDetailTabs from './VariantDetailTabs'
import VariantInternationalDetail from './International'
import VariantStock from './Stock/VariantStock'
import VariantSuppliersList from './Suppliers/VariantSuppliersList'
import VariantInventoryLog from './InventoryLog/VariantInventoryLog'

import VariantListItems from '../VariantUpdate/VariantListItems'

const ContainerBoxStyled = styled(Box, {
  shouldForwardProp: (propName) => propName !== 'isMultiVariant'
})(({ theme, isMultiVariant }) => ({
  width: '100%',
  ...(isMultiVariant && {
    paddingRight: 0,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 300,
      maxWidth: '100%'
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0
    }
  })
}))

function VariantDetail (props) {
  const {
    results,
    isLoading,
    variantId,
    pageTitle,
    pageTitleLoading,
    variantHistory,
    isMultiVariant,
    stockList,
    supplierList,
    id,
    productId,
    values,
    inventoryLog
  } = props
  const { tab } = useParams()

  const editTab = getRouteTab(tab, VARIANT_UPDATE_TABS, VARIANT_UPDATE_TABS.GENERAL)
  const editUrl = generatePath(ROUTES.VARIANT_UPDATE_PATH, { id, productId, tab: editTab })
  const editPath = `${editUrl}?isMultiVariant=${isMultiVariant}`

  return (
    <LoaderProvider isLoading={isLoading}>
      <Box height="100%" display="flex">
        {isMultiVariant && (
          <VariantListItems
            results={results}
            variantId={variantId}
            tab={tab}
          />
        )}
        <ContainerBoxStyled isMultiVariant={isMultiVariant}>
          <Container>
            <Header>
              <PageTitle
                pageTitle={pageTitle}
                pageTitleLoading={pageTitleLoading}
                parentTitle={NAV.VARIANTS}
                rightButton={<EditButton disabled={isLoading} path={editPath} />}
              />
            </Header>

            <Content>
              <VariantDetailTabs value={tab} isMultiVariant={isMultiVariant} />
              <Divider />
              <Box mb={3} />

              {tab === VARIANT_DETAIL_TABS.GENERAL && (
                <VariantDetailGeneral
                  values={values}
                  isMultiVariant={isMultiVariant}
                />
              )}
              {tab === VARIANT_DETAIL_TABS.STOCK && <VariantStock list={stockList} />}
              {tab === VARIANT_DETAIL_TABS.SUPPLIERS && <VariantSuppliersList list={supplierList} />}
              {tab === VARIANT_DETAIL_TABS.HISTORY && <History history={variantHistory} />}
              {tab === VARIANT_DETAIL_TABS.INTERNATIONAL && <VariantInternationalDetail values={values} />}
              {tab === VARIANT_DETAIL_TABS.INVENTORY_LOG && <VariantInventoryLog inventoryLog={inventoryLog} />}
            </Content>
          </Container>
        </ContainerBoxStyled>
      </Box>
    </LoaderProvider>
  )
}

VariantDetail.propTypes = {
  results: PropTypes.array.isRequired,
  stockList: PropTypes.object.isRequired,
  supplierList: PropTypes.object.isRequired,
  variantHistory: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  variantId: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  pageTitleLoading: PropTypes.bool.isRequired,
  isMultiVariant: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  productId: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  inventoryLog: PropTypes.object.isRequired
}

export default VariantDetail
