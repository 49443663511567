import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import { Divider } from '@mui/material'

import { CardHeader } from '~/components/Cards'
import { DiscardButton, SaveButton } from '~/components/Buttons'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import { withForm } from '~/components/Form'
import * as NAV from '~/constants/nav-titles'
import TextField from '~/components/Fields/TextField'
import StatusField from '~/components/Fields/StatusField'

import BatteryTypeField from '../IMile/Fields/BatteryTypeField'
import PaymentMethodField from '../IMile/Fields/PaymentMethodField'

function IMileIntegrationCreateForm ({ pageTitle, isLoading, form }) {
  const { t } = useTranslation()
  const { handleSubmit } = form

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            parentTitle={NAV.INTEGRATION}
            pageTitle={pageTitle}
            rightButton={
              <>
                <DiscardButton disabled={isLoading} />
                <SaveButton disabled={isLoading} />
              </>
            }
          />
        </Header>
        <Content>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={8}>
              <Box mb={3}>
                <Card>
                  <CardHeader title={t('Integration')} />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12} lg={4}>
                        <TextField
                          data-cy="name"
                          name="name"
                          label="Name"
                        />
                      </Grid>

                      <Grid item={true} xs={12} lg={4}>
                        <StatusField />
                      </Grid>

                      <Grid item={true} xs={12} lg={4}>
                        <TextField
                          name="configuration.consignor"
                          data-cy="consignor"
                          label="Consignor"
                        />
                      </Grid>

                      <Grid item={true} xs={12} lg={4}>
                        <BatteryTypeField name="configuration.batterType" />
                      </Grid>

                      <Grid item={true} xs={12} lg={4}>
                        <PaymentMethodField name="configuration.paymentMethod" />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <TextField
                          data-cy="description"
                          name="description"
                          label="Description"
                          multiline={true}
                          rows="4"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
            <Grid item={true} xs={4}>
              <Box mb={3}>
                <Card>
                  <CardHeader title={t('Credential')} />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12}>
                        <TextField
                          data-cy="customerId"
                          name="credential.customerId"
                          label="Customer id"
                        />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <TextField
                          data-cy="secretKey"
                          inputProps={{
                            type: 'password'
                          }}
                          name="credential.secretKey"
                          label="Secret Key"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Content>
      </Container>
    </form>
  )
}

IMileIntegrationCreateForm.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  form: PropTypes.object.isRequired,
}
export default withForm(IMileIntegrationCreateForm)
