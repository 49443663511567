import React from 'react'
import MUIDivider from '@mui/material/Divider'

function Divider () {
  return (
    <MUIDivider sx={{ mb: 3 }} />
  )
}

export default Divider
