import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Context from './context'

function TableHeightProvider ({ children }) {
  const [height, setHeight] = useState(0)

  const setTableHeight = (value) => {
    setHeight(value)
  }

  const value = {
    isHeightSet: Boolean(height),
    tableHeight: height,
    setTableHeight
  }

  return (
    <Context.Provider value={value}>{children}</Context.Provider>
  )
}

TableHeightProvider.propTypes = {
  children: PropTypes.any.isRequired
}

export default TableHeightProvider
