import React from 'react'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Collapse from '@mui/material/Collapse'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import { styled } from '@mui/material'

const TableCellStyled = styled(TableCell, {
  shouldForwardProp: propName => propName !== 'depth'
})(({ theme, depth }) => ({
  padding: 0,
  margin: 0,
  paddingRight: '0 !important',
  borderBottom: 'none',
  position: 'relative',
  zIndex: 2,
  ...(depth && {
    paddingLeft: `${depth * 8}px`,
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      width: '100%',
      height: '100%',
      background: theme.palette.primary.main,
      opacity: depth / 10,
      zIndex: 1
    }
  })
}))

const InnerTableCellStyled = styled(TableCell)(({ theme }) => ({
  padding: 0,
  margin: 0,
  paddingRight: '0 !important',
  borderBottom: 'none',
  position: 'relative',
  zIndex: 2,
  backgroundColor: theme.palette.background.paper
}))

function ExpandedTableWrapper (props) {
  const {
    children,
    open,
    colSpan,
    depth = 0
  } = props

  return (
    <TableRow>
      <TableCellStyled
        depth={depth}
        as={TableCell}
        colSpan={colSpan}
        sx={{ pl: depth ? 1 : 0 }}
      >
        <Collapse in={open} timeout="auto" unmountOnExit={true}>
          <InnerTableCellStyled as={Box} margin={1}>
            {children}
          </InnerTableCellStyled>
        </Collapse>
      </TableCellStyled>
    </TableRow>
  )
}

ExpandedTableWrapper.propTypes = {
  children: PropTypes.any.isRequired,
  open: PropTypes.bool.isRequired,
  depth: PropTypes.number,
  colSpan: PropTypes.number,
}

export default ExpandedTableWrapper
