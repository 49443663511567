import { useState } from 'react'
import { assocPath, reduce } from 'ramda'

import { toObject } from '~/utils'

function useStorfoxHistory () {
  const [changeList, setChangeList] = useState({})

  const changesHandler = changes => {
    const oldChanges = reduce((a, t) => {
      const value = toObject(t.oldValue)
      return assocPath(t.path, value, a)
    }, {}, changes)

    const newChanges = reduce((a, t) => {
      const value = toObject(t.newValue)
      return assocPath(t.path, value, a)
    }, {}, changes)

    setChangeList({ oldChanges, newChanges })
  }

  return { changeList, changesHandler }
}

export default useStorfoxHistory
