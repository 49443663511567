import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { generatePath, useParams } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import { path, prop, propOr } from 'ramda'
import { SingleImagePreview } from 'storfox-image-upload'

import PageTitle from '~/components/PageTitle'
import { EditButton } from '~/components/Buttons'
import Container, { Content, Header } from '~/components/Container'
import * as NAV from '~/constants/nav-titles'
import LoaderProvider from '~/components/Loader'
import * as ROUTES from '~/constants/routes'
import { CardHeader } from '~/components/Cards'
import TextLabelLoader from '~/components/TextLabelLoader'
import Tags from '~/components/Tags'

function CategoryDetail ({ title, isLoading, detail }) {
  const { id } = useParams()

  const editPath = generatePath(ROUTES.CATEGORY_UPDATE_PATH, { id })
  const { t } = useTranslation()

  const name = prop('name', detail)
  const slug = prop('slug', detail)
  const description = prop('detail', detail)
  const image = prop('imagePath', detail)
  const parent = path(['parent', 'name'], detail)
  const position = prop('position', detail)
  const tags = propOr([], 'tags', detail)
  const status = prop('status', detail)

  return (
    <LoaderProvider isLoading={isLoading}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={title}
            parentTitle={t(NAV.PRODUCTS)}
            pageTitleLoading={isLoading}
            rightButton={<EditButton disabled={isLoading} path={editPath} />}
          />
        </Header>

        <Content>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12} lg={8}>
              <Box mb={3}>
                <Card>
                  <CardHeader title="Details" />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12}>
                        <TextLabelLoader label="Name">
                          {name}
                        </TextLabelLoader>
                      </Grid>

                      <Grid item={true} xs={12}>
                        <TextLabelLoader label="Slug">
                          {slug}
                        </TextLabelLoader>
                      </Grid>

                      <Grid item={true} xs={12}>
                        <TextLabelLoader label="Detail">
                          {description}
                        </TextLabelLoader>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
            <Grid item={true} xs={12} lg={4}>
              <Box mb={3}>
                <Card>
                  <CardHeader title="Image" />
                  <Divider />
                  <CardContent>
                    <SingleImagePreview src={image} />
                  </CardContent>
                </Card>
              </Box>
              <Box mb={3}>
                <Card>
                  <CardHeader title="Organization" />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12}>
                        <TextLabelLoader label="Parent">
                          {parent}
                        </TextLabelLoader>
                      </Grid>

                      <Grid item={true} xs={12}>
                        <TextLabelLoader label="Position">
                          {position}
                        </TextLabelLoader>
                      </Grid>

                      <Grid item={true} xs={12}>
                        <TextLabelLoader label="Status">
                          {status}
                        </TextLabelLoader>
                      </Grid>

                      <Grid item={true} xs={12}>
                        <TextLabelLoader label="Tags">
                          <Tags items={tags} />
                        </TextLabelLoader>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Content>
      </Container>
    </LoaderProvider>
  )
}

CategoryDetail.propTypes = {
  title: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  detail: PropTypes.object.isRequired,
}

export default CategoryDetail
