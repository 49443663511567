import palette from '../palette'

export default {
  styleOverrides: {
    elevation1: {
      boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)'
    },
    elevation4: {
      boxShadow: 'none',
      borderBottom: `1px solid ${palette.divider}`
    }
  },
  defaultProps: {
    variant: 'outlined'
  }
}
