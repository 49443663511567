import React from 'react'
import PropTypes from 'prop-types'
import { SwitchField } from 'storfox-form-fields'
import Typography from '@mui/material/Typography'

import { AUTO_CREATE_SHIPMENT, AUTO_PROCESS, SPLIT_TO, TO_WAREHOUSE } from '../ActionFields/ActionSelectField'
import WarehouseActionMultiSelectField from '../ActionFields/WarehouseActionMultiSelectField'
import WarehouseActionSearchField from '../ActionFields/WarehouseActionSearchField'

export const ORDER_CHANGED_FIELDS = [
  'splitTo',
  'toWarehouse',
  'autoProcess',
  'autoCreateShipment'
]

function OrderChangedAction ({ action, actionName }) {
  return (
    <>
      {action === SPLIT_TO && <WarehouseActionMultiSelectField name={`${actionName}.warehouses`} />}
      {action === TO_WAREHOUSE && <WarehouseActionSearchField name={`${actionName}.warehouse`} />}
      {action === AUTO_PROCESS && (
        <>
          <SwitchField
            name={actionName}
            label={<Typography display="inline" align="center">Auto process</Typography>}
          />

        </>
      )}
      {action === AUTO_CREATE_SHIPMENT && (
        <SwitchField
          name={actionName}
          label={<Typography display="inline" align="center">Auto create shipment</Typography>}
        />
      )}
    </>
  )
}

OrderChangedAction.propTypes = {
  action: PropTypes.string.isRequired,
  actionName: PropTypes.string.isRequired
}

export default OrderChangedAction
