import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import { prop } from 'ramda'
import { Filter } from 'storfox-filter'

import { arrayToParams, escapeAtob, unescapeBtoa } from '~/utils'
import Accordion from '~/components/Accordion'
import MultiSelectSearchField from '~/components/Fields/MultiSelectSearchField'
import * as API from '~/constants/api'
import TagsField from '~/components/Fields/TagsField'
import WarehouseSearchField from '~/components/Fields/WarehouseSearchField'

import { FILTER_NAME as name } from '../../constants'
import ReorderSupplierField from '../Fields/ReorderSupplierField'

export const toParams = values => {
  const categories = arrayToParams('categories', values)
  const brands = arrayToParams('brands', values)
  const tagsList = prop('tags', values)
  const tags = tagsList ? tagsList.join(',') : null
  const suppliers = arrayToParams('suppliers', values)
  const comparisonWarehouse = unescapeBtoa(prop('comparisonWarehouse', values))

  return {
    categories,
    brands,
    tags,
    suppliers,
    comparisonWarehouse
  }
}

export const toValues = (params) => {
  const categories = escapeAtob(prop('categories', params))
  const brands = escapeAtob(prop('brands', params))
  const tagsList = prop('tags', params)
  const tags = tagsList ? tagsList.split(',') : null
  const suppliers = escapeAtob(prop('suppliers', params))
  const comparisonWarehouse = escapeAtob(prop('comparisonWarehouse', params))

  return {
    categories,
    brands,
    tags,
    suppliers,
    comparisonWarehouse
  }
}

export const transformer = { toParams, toValues }

export const reorderFilterOptions = {
  name,
  transformer
}

function ReorderFilterForm (props) {
  return (
    <Filter {...props}>
      <Accordion title="General" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <MultiSelectSearchField
              data-cy="categories"
              name="categories"
              label="Category"
              api={API.CATEGORY_LIST}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <MultiSelectSearchField
              data-cy="brands"
              name="brands"
              label="Brand"
              api={API.BRAND_LIST}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <TagsField
              data-cy="tags"
              label="Tags"
              name="tags"
            />
          </Grid>
          <Grid item={true} xs={12}>
            <ReorderSupplierField />
          </Grid>
          <Grid item={true} xs={12}>
            <WarehouseSearchField
              data-cy="comparisonWarehouse"
              name="comparisonWarehouse"
              label="Comparison warehouse"
            />
          </Grid>
        </Grid>
      </Accordion>
    </Filter>
  )
}

ReorderFilterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired
}

export default ReorderFilterForm
