import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { pathOr } from 'ramda'
import { useSnackbar } from 'storfox-snackbar'
import { useTranslation } from 'react-i18next'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'

import { ConditionCreateSerializer } from '../../serializers/Condition'
import { useConditionDetail, useConditionUpdate } from '../../hooks/Condition'
import ConditionUpdateForm from '../../components/Condition/ConditionUpdateForm'

function ConditionUpdateContainer () {
  const { t } = useTranslation()
  const { id } = useParams()
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const conditionDetail = useConditionDetail(id)
  const conditionUpdate = useConditionUpdate(id)

  const message = t('Successfully updated')

  const handleSubmit = formValues =>
    conditionUpdate.update(ConditionCreateSerializer(formValues))
      .then(() => snackbar({ message }))
      .then(() => navigate(ROUTES.CONDITION_LIST_PATH))

  const title = pathOr('Conditions', ['detail', 'code'], conditionDetail)
  const pageLoading = conditionDetail.isLoading || conditionUpdate.isLoading

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      isLoading={pageLoading}
      activeNav={NAV.CONFIGURATION}
      breadcrumbs={breadcrumbs}
    >
      <ConditionUpdateForm
        title={title}
        onSubmit={handleSubmit}
        pageTitleLoading={conditionDetail.isLoading}
        isLoading={conditionUpdate.isLoading}
        initialValues={conditionDetail.detail}
      />
    </DashboardLayout>
  )
}

export default ConditionUpdateContainer
