import React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

import Tabs from '~/components/Tabs'
import Divider from '~/components/Divider'
import * as ROUTES from '~/constants/routes'
import { SHIPMENT_DETAIL_TABS } from '~/constants/tabs'

const tabs = [
  { value: SHIPMENT_DETAIL_TABS.GENERAL, label: 'General' },
  { value: SHIPMENT_DETAIL_TABS.CONTENT, label: 'Content' },
  { value: SHIPMENT_DETAIL_TABS.HISTORY, label: 'History' },
  { value: SHIPMENT_DETAIL_TABS.SHIPPING_INFO, label: 'Shipping info' }
]

function ShipmentDetailTabs ({ value }) {
  const { guid } = useParams()
  const navigate = useNavigate()

  const onChange = (event, tab) => {
    const redirect = generatePath(ROUTES.SHIPMENT_DETAIL_PATH, { guid, tab })
    navigate(redirect, { replace: true })
  }

  return (
    <>
      <Tabs
        tabs={tabs}
        value={value}
        onChange={onChange}
      />
      <Divider />
    </>
  )
}

ShipmentDetailTabs.propTypes = {
  value: PropTypes.string.isRequired
}

export default ShipmentDetailTabs
