import axios from 'axios'
import { includes, is, path, prop } from 'ramda'

import { getToken } from './utils/token'
import { getApiUrl } from './utils/environment'
import toSnakeCase from './utils/toSnakeCase'
import toCamelCase from './utils/toCamelCase'

const CONTENT_TYPE_JSON = 'application/json'
const UNAUTHORIZED = 401
const FORBIDDEN = 403

export const transformResponse = (data, response) => {
  const responseContentType = path(['content-type'], response)
  if (responseContentType && includes(CONTENT_TYPE_JSON, responseContentType)) {
    return toCamelCase(JSON.parse(data))
  }

  if (is(Object, data) || is(Array, data)) {
    return toCamelCase(data)
  }

  return data
}

const onFulfilled = response => response
const onRejected = error => {
  const status = prop('status', error.response)

  if (error.message === 'Network Error') {
    const message = { response: { data: { nonFieldErrors: ['Check your network connection.'] } } }

    return Promise.reject(message)
  }

  if (status === FORBIDDEN) {
    const message = {
      response: { data: { nonFieldErrors:
            ['You do not have enough privilege, contact the account owner']
      } } }

    return Promise.reject(message)
  }

  if (status === UNAUTHORIZED) {
    localStorage.clear()
    window.location = '/signin/'
  }

  return Promise.reject(error)
}

const getHeaders = async () => {
  const token = getToken()

  return {
    Authorization: token
  }
}

export const getHttpRequest = () => {
  const config = {
    baseURL: getApiUrl(),
    transformResponse: [transformResponse]
  }

  const instance = axios.create(config)
  instance.interceptors.response.use(onFulfilled, onRejected)

  return instance
}

function Request () {
  return {
    get: async (url, params, options) => {
      const http = getHttpRequest()
      const headers = await getHeaders()

      return http({
        method: 'get',
        params: toSnakeCase(params),
        url,
        headers,
        ...options
      })
    },
    post: async (url, data, options) => {
      const http = getHttpRequest()
      const headers = await getHeaders()

      return http({
        method: 'post',
        data: toSnakeCase(data),
        url,
        headers,
        ...options
      })
    },
    put: async (url, data, options) => {
      const http = getHttpRequest()
      const headers = await getHeaders()

      return http({
        method: 'put',
        data: toSnakeCase(data),
        url,
        headers,
        ...options
      })
    },
    delete: async (url, data, options) => {
      const http = getHttpRequest()
      const headers = await getHeaders()

      return http({
        method: 'delete',
        data: toSnakeCase(data),
        url,
        headers,
        ...options
      })
    },
    upload: async (url, data, onUploadProgress, options) => {
      const http = getHttpRequest()
      const headers = await getHeaders()

      return http({
        method: 'post',
        data,
        url,
        headers: {
          ...headers,
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress,
        ...options
      })
    },
  }
}

export default Request
