import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Status, { ERROR, SUCCESS } from '../Status/Status'

export const ACTIVE = 'active'
export const INACTIVE = 'inactive'

export const ZONE_STATUS = {
  ACTIVE,
  INACTIVE,
}

export const ZONE_STATUS_LABEL = {
  [ACTIVE]: 'Active',
  [INACTIVE]: 'Inactive',
}

const STATUS_COLOR = {
  [ZONE_STATUS.ACTIVE]: SUCCESS,
  [ZONE_STATUS.INACTIVE]: ERROR,
}

function ZoneStatus ({ value }) {
  const { t } = useTranslation()

  return (
    <Status
      label={t(ZONE_STATUS_LABEL[value])}
      color={STATUS_COLOR[value]}
    />
  )
}

ZoneStatus.propTypes = {
  value: PropTypes.string.isRequired
}

export default ZoneStatus
