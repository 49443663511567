import React from 'react'
import Grid from '@mui/material/Grid'
import { useField } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import TextField from '~/components/Fields/TextField'
import { Button } from '~/components/Buttons'

function CommentField ({ name, isLoading, onChange, ...props }) {
  const { t } = useTranslation()
  const field = useField(name)

  const handleChange = event => {
    if (event.key === 'Enter') {
      event.preventDefault()
      return onChange(event.target.value)
        .then(() => field.input.onChange(''))
    }
  }

  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} lg={9} xs={7}>
        <TextField
          name={name}
          placeholder={t('Leave your note here...')}
          disabled={isLoading}
          onKeyPress={handleChange}
          fullWidth={true}
          {...props}
        />
      </Grid>
      <Grid item={true} lg={3} xs={5}>
        <Button
          variant="outlined"
          type="submit"
          fullWidth={true}
          disabled={isLoading}
        >
          Send
        </Button>
      </Grid>
    </Grid>
  )
}

CommentField.defaultProps = {
  name: 'comment'
}

CommentField.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired

}
export default CommentField
