import React from 'react'
import { PositiveNumberField as StorfoxPositiveNumberField } from 'storfox-form-fields'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

function PositiveNumberField ({ label, ...props }) {
  const { t } = useTranslation()
  return (
    <StorfoxPositiveNumberField
      label={t(label)}
      {...props}
    />
  )
}

PositiveNumberField.propTypes = {
  label: PropTypes.string
}

export default PositiveNumberField
