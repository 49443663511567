import React from 'react'
import PropTypes from 'prop-types'
import RemoveIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'

function RemoveButton ({ label, handleOnClick, ...props }) {
  return (
    <IconButton
      onClick={handleOnClick}
      size="small"
      {...props}
    >
      <RemoveIcon />
    </IconButton>
  )
}

RemoveButton.propTypes = {
  handleOnClick: PropTypes.func.isRequired,
  label: PropTypes.any.isRequired
}

export default RemoveButton
