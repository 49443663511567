import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import LotSheetsListContainer from './containers/LotSheetsListContainer'
import LotSheetsImportContainer from './containers/LotSheetsImportContainer'
import LotSheetsDetailContainer from './containers/LotSheetsDetailContainer'
import LotSheetsScanContainer from './containers/LotSheetsScanContainer'
import LotSheetsShipmentContainer from './containers/LotSheetsShipmentContainer'

export default () => {
  return [
    {
      layout: AuthLayout,
      path: ROUTES.LOT_SHEETS_LIST_PATH,
      component: LotSheetsListContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.LOT_SHEETS_IMPORT_PATH,
      component: LotSheetsImportContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.LOT_SHEETS_DETAIL_PATH,
      component: LotSheetsDetailContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.LOT_SHEETS_SCAN_PATH,
      component: LotSheetsScanContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.LOT_SHEETS_SHIPMENT_PATH,
      component: LotSheetsShipmentContainer
    }
  ]
}
