import PropTypes from 'prop-types'
import React, { useState } from 'react'
import TableCell from '@mui/material/TableCell'
import { prop, propOr } from 'ramda'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'

import { ExpandableTableRow, ExpandedTableWrapper } from '~/components/Table'
import SkeletonList from '~/components/Skeleton'
import { Volume } from '~/components/Converters'

import LocationAccordionTable from './LocationAccordionTable'

function WarehouseAccordionRow ({ warehouse, onLocationsLoad, onUnitsLoad }) {
  const [open, setOpen] = useState(false)

  const warehouseGuid = prop('guid', warehouse)
  const name = prop('name', warehouse)
  const label = prop('label', warehouse)
  const usedVolume = prop('usedVolume', warehouse)
  const unitCount = prop('unitCount', warehouse)
  const locations = propOr([], 'locations', warehouse)

  const handleExpandToggle = () => {
    if (!locations.length) {
      onLocationsLoad()
    }
    setOpen(prevState => !prevState)
  }

  const handleUnitsLoad = locationGuid =>
    onUnitsLoad(locationGuid)

  return (
    <>
      <ExpandableTableRow
        open={open}
        key={warehouseGuid}
        onExpandToggle={handleExpandToggle}
      >
        <TableCell>{name}</TableCell>
        <TableCell>{label}</TableCell>
        <TableCell align="center">
          <Volume value={usedVolume} />
        </TableCell>
        <TableCell align="center">{unitCount}</TableCell>
      </ExpandableTableRow>

      <ExpandedTableWrapper open={open} colSpan={5} depth={2}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Location</TableCell>
              <TableCell align="center">Used volume</TableCell>
              <TableCell align="center">Unit count</TableCell>
              <TableCell align="center">Units</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!locations.length && <SkeletonList rowCount={1} columnCount={4} />}
            {locations.map(location => {
              const locationGuid = prop('guid', location)

              return (
                <LocationAccordionTable
                  key={locationGuid}
                  location={location}
                  onUnitsLoad={() => handleUnitsLoad(locationGuid)}
                />
              )
            })}
          </TableBody>
        </Table>
      </ExpandedTableWrapper>
    </>
  )
}

WarehouseAccordionRow.propTypes = {
  onLocationsLoad: PropTypes.func.isRequired,
  onUnitsLoad: PropTypes.func.isRequired,
  warehouse: PropTypes.object.isRequired
}

export default WarehouseAccordionRow
