import React from 'react'
import { useField } from 'react-final-form'
import PropTypes from 'prop-types'
import { join, pipe, pluck, prop } from 'ramda'

import LocationField from '~/components/Fields/LocationField'

const getIds = pipe(
  pluck('id'),
  join(','),
)

const getOptionValue = item => {
  if (item) {
    const id = prop('id', item)
    const locationId = prop('locationId', item)

    return { guid: id, id, locationId }
  }

  return null
}

function LocationSearchField ({ parentName, ...props }) {
  const parentField = useField(parentName)
  const parentValue = parentField.input.value

  return (
    <LocationField
      name="toLocationId"
      label="To location"
      params={{ warehouseIds: getIds(parentValue) }}
      getOptionValue={getOptionValue}
      {...props}
    />
  )
}

LocationSearchField.propTypes = {
  parentName: PropTypes.string.isRequired
}
LocationSearchField.defaultProps = {
  parentName: 'warehouses'
}

export default LocationSearchField
