import React from 'react'
import { useField } from 'react-final-form'
import { filter, includes, propOr } from 'ramda'
import PropTypes from 'prop-types'

import SelectField from './SelectField'

import { TIMEZONES } from '../Timezone'

const options = [
  {
    value: -12,
    name: TIMEZONES[-12]
  },
  {
    value: -11,
    name: TIMEZONES[-11]
  },
  {
    value: -10,
    name: TIMEZONES[-10]
  },
  {
    value: -9,
    name: TIMEZONES[-9]
  },
  {
    value: -8,
    name: TIMEZONES[-8]
  },
  {
    value: -7,
    name: TIMEZONES[-7]
  },
  {
    value: -6,
    name: TIMEZONES[-6]
  },
  {
    value: -5,
    name: TIMEZONES[-5]
  },
  {
    value: -4,
    name: TIMEZONES[-4]
  },
  {
    value: -3.5,
    name: TIMEZONES[-3.5]
  },
  {
    value: -3,
    name: TIMEZONES[-3]
  },
  {
    value: -2,
    name: TIMEZONES[-2]
  },
  {
    value: -1,
    name: TIMEZONES[-1]
  },
  {
    value: 0,
    name: TIMEZONES[0]
  },
  {
    value: 1,
    name: TIMEZONES[1]
  },
  {
    value: 2,
    name: TIMEZONES[2]
  },
  {
    value: 3,
    name: TIMEZONES[3]
  },
  {
    value: 3.5,
    name: TIMEZONES[3.5]
  },
  {
    value: 4,
    name: TIMEZONES[4]
  },
  {
    value: 4.5,
    name: TIMEZONES[4.5]
  },
  {
    value: 5,
    name: TIMEZONES[5]
  },
  {
    value: 5.5,
    name: TIMEZONES[5.5]
  },
  {
    value: 5.75,
    name: TIMEZONES[5.75]
  },
  {
    value: 6,
    name: TIMEZONES[6]
  },
  {
    value: 7,
    name: TIMEZONES[7]
  },
  {
    value: 8,
    name: TIMEZONES[8]
  },
  {
    value: 9,
    name: TIMEZONES[9]
  },
  {
    value: 9.5,
    name: TIMEZONES[9.5]
  },
  {
    value: 10,
    name: TIMEZONES[10]
  },
  {
    value: 11,
    name: TIMEZONES[11]
  },
  {
    value: 12,
    name: TIMEZONES[12]
  }
]

function TimeZoneField ({ countryName, ...props }) {
  const countryField = useField(countryName)
  const country = countryField.input.value
  const timezones = propOr([], 'timezones', country)

  const getOptions = filter(({ value }) => includes(value, timezones))

  return (
    <SelectField
      data-cy="timezone"
      name="timezone"
      label="Timezone"
      options={getOptions(options)}
      {...props}
    />
  )
}

TimeZoneField.propTypes = {
  countryName: PropTypes.string.isRequired
}

export default TimeZoneField
