import { sprintf } from 'sprintf-js'
import { useAllSearchParams } from 'storfox-route-hooks'
import { useLimitedCountList, useCreate, useDelete, useDetail, useList, useUpdate } from 'storfox-api-hooks'

import { orderingToSnackCase, queryToParams } from '~/utils'
import * as API from '~/constants/api'

import { ContainerFilterSerializer, LocationFilterSerializer, WarehouseFilterSerializer } from '../serializers'

export const useWarehouseList = () => {
  const searchParams = useAllSearchParams()
  const params = queryToParams(WarehouseFilterSerializer, searchParams)
  return useLimitedCountList(API.WAREHOUSE_LIST, params)
}

export const useWarehouseDetail = guid => {
  const url = sprintf(API.WAREHOUSE_DETAIL, guid)
  return useDetail(url)
}

export const useWarehouseCreate = () => {
  return useCreate(API.WAREHOUSE_CREATE)
}

export const useWarehouseUpdate = (guid) => {
  const url = sprintf(API.WAREHOUSE_UPDATE, guid)
  return useUpdate(url)
}

export const useAreaList = (warehouseGuid, autoSend) => {
  const searchParams = useAllSearchParams()
  const params = { ...searchParams, warehouseGuid }

  return useList(API.AREA_LIST, orderingToSnackCase(params), autoSend)
}

export const useZoneList = (warehouseGuid, autoSend) => {
  const searchParams = useAllSearchParams()
  const params = { ...searchParams, warehouseGuid }

  return useList(API.ZONE_LIST, orderingToSnackCase(params), autoSend)
}

export const useLocationList = (warehouseGuid, autoSend) => {
  const searchParams = useAllSearchParams()
  const filteredParams = queryToParams(LocationFilterSerializer, searchParams, 'guid')

  return useList(API.LOCATION_LIST, { ...filteredParams, warehouseGuid }, autoSend)
}

export const useLocationsDelete = () => {
  return useDelete(API.LOCATIONS_DELETE)
}

export const useContainerList = (warehouseGuid, autoSend) => {
  const searchParams = useAllSearchParams()
  const params = queryToParams(ContainerFilterSerializer, searchParams, 'guid')

  return useList(API.CONTAINER_LIST, { ...params, warehouseGuid }, autoSend)
}

export const useWarehouseImport = () => {
  return useCreate(API.WAREHOUSE_FILE_UPLOAD)
}

export const useWarehousesDelete = (guid) => {
  const url = sprintf(API.WAREHOUSES_DELETE, guid)
  return useDelete(url)
}

export const useWarehouseLocationList = (warehouseGuid, autoSend = false) => {
  const defaultParams = { limit: 100000, ordering: 'location_id', warehouseGuid }
  const { getList, ...state } = useList(API.LOCATION_VISUALIZATION_LIST, defaultParams, autoSend)

  return { ...state, getList: params => getList({ ...defaultParams, ...params }) }
}
