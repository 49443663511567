import { pick, prop } from 'ramda'
import { DEFAULT_SEARCH_PARAMS } from 'storfox-route-hooks'

import { escapeAtob } from '~/utils'

export function InventoryLogFilterSerializer (params) {
  const defaultParams = pick(DEFAULT_SEARCH_PARAMS, params)
  const variantGuids = escapeAtob(prop('variants', params))
  const warehouseGuids = escapeAtob(prop('warehouses', params))
  const companyGuids = escapeAtob(prop('companies', params))

  const createdAtStart = prop('createdAtStart', params)
  const createdAtEnd = prop('createdAtEnd', params)

  return {
    ...defaultParams,
    variantGuids,
    warehouseGuids,
    companyGuids,
    createdAtStart,
    createdAtEnd
  }
}
