import { prop } from 'ramda'
import React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import Title from '~/components/Title'
import * as NAV from '~/constants/nav-titles'
import useMessages from '~/hooks/useMessages'
import * as ROUTES from '~/constants/routes'
import ErrorLink from '~/components/ErrorLink'
import { SUPPLIER_RETURN_TABS } from '~/constants/tabs'

import { useReturnDetail, useReturnUpdate, useReturnVariants } from '../hooks'
import { ReturnInitUpdateSerializer, ReturnCreateSerializer } from '../serializers'
import { NAMES } from '../constants'
import ReturnCreateForm from '../components/ReturnCreate/ReturnCreateForm'

function ReturnUpdateContainer () {
  const { guid } = useParams()
  const snackbar = useSnackbar()
  const messages = useMessages()
  const navigate = useNavigate()

  const returnDetail = useReturnDetail(guid)
  const returnUpdate = useReturnUpdate(guid)
  const returnVariants = useReturnVariants()

  const initialValues = ReturnInitUpdateSerializer(returnDetail.detail)

  const handleSubmit = formValues =>
    returnUpdate.update(ReturnCreateSerializer(formValues))
      .then(() => {
        const params = { guid, tab: SUPPLIER_RETURN_TABS.GENERAL }
        const redirect = generatePath(ROUTES.SUPPLIER_RETURN_DETAIL_PATH, params)
        snackbar({ message: messages.UPDATE_SUCCESS })
        navigate(redirect)
      })
      .catch(error => {
        snackbar({ type: ALTER_ERROR, message: <ErrorLink error={error} /> })
        throw error
      })

  const pageLoading =
    returnDetail.isLoading ||
    returnUpdate.isLoading

  const status = prop('status', returnDetail.detail)

  const title = prop('number', returnDetail.detail)
  const pageTitle = (
    <Title title="Return" number={title} status={NAMES[status]} />
  )

  const isLoading = returnDetail.isLoading || returnUpdate.isLoading

  const breadcrumbs = {
    title: 'Edit',
    params: [
      { guid, title, tab: SUPPLIER_RETURN_TABS.GENERAL }
    ]
  }

  return (
    <DashboardLayout
      title={title}
      isLoading={pageLoading}
      activeNav={NAV.SUPPLIER_RETURNS}
      breadcrumbs={breadcrumbs}
    >
      <ReturnCreateForm
        pageTitle={pageTitle}
        pageTitleLoading={returnDetail.isLoading}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        isLoading={isLoading}
        returnVariants={returnVariants}
      />
    </DashboardLayout>
  )
}

export default ReturnUpdateContainer
