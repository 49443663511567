import DashboardIcon from './svg/nav/dashboard.svg'
import CatalogIcon from './svg/nav/catalog.svg'
import PurchasesIcon from './svg/nav/purchases.svg'
import StockControlIcon from './svg/nav/stock-control.svg'
import FulfillmentIcon from './svg/nav/fulfillment.svg'
import WarehouseIcon from './svg/nav/warehouse.svg'
import SettingsIcon from './svg/nav/settings.svg'
import ReportIcon from './svg/nav/report.svg'
import CrossDockIcon from './svg/nav/cross-dock.svg'
import SalesIcon from './svg/nav/sales.svg'

export {
  DashboardIcon,
  CatalogIcon,
  WarehouseIcon,
  SettingsIcon,
  StockControlIcon,
  PurchasesIcon,
  FulfillmentIcon,
  ReportIcon,
  CrossDockIcon,
  SalesIcon
}
