import React from 'react'
import PropTypes from 'prop-types'
import Drawer, { drawerClasses } from '@mui/material/Drawer'
import LinearProgress from '@mui/material/LinearProgress'
import Paper from '@mui/material/Paper'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'
import Box from '@mui/material/Box'
import { styled } from '@mui/material'

import FiltersTab from './FiltersTab'

import { useOrderingReset } from '../../hooks/useOrderingReset'

const DrawerStyled = styled(Drawer)({
  [`& .${drawerClasses.paper}`]: {
    width: 420,
    maxWidth: '100%',
    overflow: 'hidden'
  }
})

function FilterDialog (props) {
  const {
    open,
    handleClose,
    backend
  } = props

  const orderingReset = useOrderingReset()
  const snackbar = useSnackbar()

  const handleReset = () =>
    orderingReset.reset()
      .then(() => snackbar({ message: 'Successfully reset.' }))
      .catch(() => snackbar({
        type: ALTER_ERROR,
        message: 'Reset failed.'
      }))

  return (
    <DrawerStyled
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      <Paper
        variant="outlined"
        square={true}
        sx={{
          zIndex: 10,
          width: '100%',
          position: 'relative',
        }}
      >
        {backend.isLoading && (
          <LinearProgress sx={{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            height: 2
          }} />
        )}
      </Paper>
      <Box sx={{ height: 'calc(100% - 50px)' }}>
        <Box sx={{ height: '100%' }}>
          <FiltersTab
            backend={backend}
            onClose={handleClose}
            onReset={handleReset}
          />
        </Box>
      </Box>
    </DrawerStyled>
  )
}

FilterDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  backend: PropTypes.object.isRequired
}

export default FilterDialog
