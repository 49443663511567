import React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useSnackbar } from 'storfox-snackbar'
import { useTranslation } from 'react-i18next'
import { prop } from 'ramda'

import { DashboardLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'
import { WAREHOUSE_TABS } from '~/constants/tabs'

import { useAreaCreate, useWarehouseDetail } from '../hooks'
import { AREA_STATUS } from '../constants'
import { AreaCreateSerializer } from '../serializers'
import { AreaCreateForm } from '../components/AreaCreate'

function AreaCreateContainer () {
  const { t } = useTranslation()
  const { warehouseGuid } = useParams()
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const areaCreate = useAreaCreate()
  const warehouseDetail = useWarehouseDetail(warehouseGuid)

  const warehouseName = prop('name', warehouseDetail.detail)

  const initialValues = {
    status: AREA_STATUS.ACTIVE,
    warehouse: { guid: warehouseGuid, name: warehouseName }
  }

  const message = t('Successfully created')

  const redirectPath = generatePath(ROUTES.WAREHOUSE_DETAIL_PATH, { guid: warehouseGuid, tab: WAREHOUSE_TABS.AREA })

  const handleSubmit = formValues =>
    areaCreate.create(AreaCreateSerializer(formValues))
      .then(() => snackbar({ message }))
      .then(() => navigate(redirectPath))

  const title = 'New Area'

  const breadcrumbs = {
    title,
    params: [
      { guid: warehouseGuid, tab: WAREHOUSE_TABS.AREA, title: warehouseName }
    ]
  }

  const isLoading = areaCreate.isLoading || warehouseDetail.isLoading

  return (
    <DashboardLayout
      title={title}
      isLoading={isLoading}
      activeNav={NAV.WAREHOUSE}
      breadcrumbs={breadcrumbs}
    >
      <AreaCreateForm
        pageTitle={title}
        pageTitleLoading={false}
        initialValues={initialValues}
        isLoading={isLoading}
        onSubmit={handleSubmit}
      />
    </DashboardLayout>
  )
}

export default AreaCreateContainer
