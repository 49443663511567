import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import LinearProgress from '@mui/material/LinearProgress'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'

import { SHRINK_MENU_WIDTH } from '~/constants/settings'
import { HtmlLayout } from '~/components/Layouts/index'

import Sidebar, { useSidebar } from '../Sidebar'
import TopBar from '../TopBar'
import Content from '../Content/Content'

const RootStyled = styled(Box, {
  shouldForwardProp: propName => propName !== 'isFullWidth'
})(({ theme, isFullWidth }) => ({
  flexGrow: 1,
  maxWidth: '100%',
  overflowX: 'hidden',
  transition: 'padding .25s ease',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 200
  },
  ...(isFullWidth && ({
    [theme.breakpoints.up('lg')]: {
      paddingLeft: SHRINK_MENU_WIDTH
    }
  }))
}))

function DashboardLayout ({ title, activeNav, isLoading, breadcrumbs, children }) {
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false)
  const { open, handleToggle } = useSidebar()
  const { t } = useTranslation()

  const handleMobileClose = useCallback(() => setOpenNavBarMobile(false), [])
  const handleMobileOpen = useCallback(() => setOpenNavBarMobile(true), [])

  return (
    <HtmlLayout title={t(title)}>
      <RootStyled isFullWidth={!open}>
        <TopBar
          id="header"
          onOpenNavBarMobile={() => setOpenNavBarMobile(true)}
          sidebarOpen={open}
          isLoading={isLoading}
          breadcrumbs={breadcrumbs}
        />

        <Sidebar
          activeNav={activeNav}
          openDesktop={open}
          openMobile={openNavBarMobile}
          onDesktopToggle={handleToggle}
          onMobileClose={handleMobileClose}
          onMobileOpen={handleMobileOpen}
        />

        <Content sx={{ minHeight: '100vh', backgroundColor: 'background.dark' }}>
          {isLoading && <LinearProgress sx={{ position: 'fixed', zIndex: 999, width: '100%' }} />}
          <div>{children}</div>
        </Content>
      </RootStyled>
    </HtmlLayout>
  )
}

DashboardLayout.propTypes = {
  children: PropTypes.node,
  activeNav: PropTypes.string,
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  breadcrumbs: PropTypes.object.isRequired
}

DashboardLayout.defaultProps = {
  title: 'Storfox',
  isLoading: false,
}

export default React.memo(DashboardLayout)
