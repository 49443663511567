import { useContext } from 'react'
import { useGetApi, usePostApi } from 'storfox-api-hooks'
import { filter, find, includes, map, not, path, pipe, prop, propEq, propOr } from 'ramda'

import * as cookies from '~/utils/cookies'
import { stringToNumbers } from '~/utils'
import * as API from '~/constants/api'
import { MARKETPLACE, RETAILER, THREE_PL } from '~/constants/companyTypes'
import { useTableValues } from '~/components/TableValues/hooks'

import Context from './context'

import { useToken } from '../Token'

export const useMe = () => {
  const { get, data, isLoading } = useGetApi(API.ME)

  return { me: data, isLoading, getMe: get }
}

export function useProfile () {
  return useContext(Context)
}

export const useCompany = () => {
  const { profile } = useProfile()
  const company = prop('company', profile)
  const companyId = path(['company', 'id'], profile)
  const companyType = path(['company', 'type'], profile)
  const isRetailer = companyType === RETAILER
  const isMarketplace = companyType === MARKETPLACE
  const isThreePl = companyType === THREE_PL

  const hasBelongCompany = id => companyId === id

  const hasBelongCompanyList = (select, list) => {
    const ids = stringToNumbers(select)

    return pipe(
      filter(item => includes(prop('id', item), ids)),
      map(item => hasBelongCompany(path(['company', 'id'], item))),
      includes(false),
      not
    )(list)
  }

  return {
    company,
    hasBelongCompany,
    hasBelongCompanyList,
    isRetailer,
    isMarketplace,
    isThreePl
  }
}

export function useLogout () {
  const { post, isLoading } = usePostApi(API.LOGOUT)
  const { setProfile } = useProfile()
  const { setToken } = useToken()
  const { resetTableValues } = useTableValues()

  const logout = () =>
    post()
      .then(() => {
        setToken(null)
        setProfile(null)
        resetTableValues()
        cookies.removeAll()
      })

  return { logout, isLoading }
}

export function useUpdateProfile () {
  const { getMe } = useMe()
  const { setProfile } = useProfile()

  const updateProfile = () =>
    getMe()
      .then(({ result }) => {
        setProfile(result)
        return result
      })
  return { updateProfile }
}

export function useWarehouse () {
  const { profile } = useProfile()
  const warehouses = propOr([], 'warehouses', profile)

  const hasOwnWarehouseById = id => {
    const foundWarehouse = find(propEq('id', id), warehouses)
    return Boolean(prop('isOwner', foundWarehouse))
  }

  const hasOwnWarehouseBuGuid = guid => {
    const foundWarehouse = find(propEq('guid', guid), warehouses)
    return Boolean(prop('isOwner', foundWarehouse))
  }

  return { hasOwnWarehouseById, hasOwnWarehouseBuGuid }
}
