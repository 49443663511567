import React from 'react'
import PropTypes from 'prop-types'
import { FieldArray } from 'react-final-form-arrays'

function FieldArrayWrapper (Component) {
  function F ({ name, ...props }) {
    return (
      <FieldArray
        {...props}
        name={name}
        component={Component}
      />
    )
  }

  F.propTypes = {
    name: PropTypes.string.isRequired,
  }

  return F
}

export default FieldArrayWrapper
