import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAllSearchParams } from 'storfox-route-hooks'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'

import CodeGenerator from '../components/CodeGenerator'
import { initialValuesSerializer } from '../serializers'

function CodeGeneratorContainer () {
  const { t } = useTranslation()
  const { barcodes, type } = useAllSearchParams()

  const breadcrumbs = { title: NAV.BARCODE }

  return (
    <DashboardLayout
      title={t(NAV.BARCODE)}
      activeNav={NAV.BARCODE}
      breadcrumbs={breadcrumbs}
    >
      <CodeGenerator initialValues={initialValuesSerializer(barcodes, type)} />
    </DashboardLayout>
  )
}

export default CodeGeneratorContainer
