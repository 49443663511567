import React from 'react'
import PropTypes from 'prop-types'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'

import EmailField from '~/components/Fields/EmailField'
import PhoneNumberField from '~/components/Fields/PhoneNumberField'
import SingleImageUploadField from '~/components/Fields/SingleImageUploadField'
import TextField from '~/components/Fields/TextField'
import { CardHeader } from '~/components/Cards'
import PageTitle from '~/components/PageTitle'
import { DiscardButton, SaveButton } from '~/components/Buttons'
import Container, { Content, Header } from '~/components/Container'
import { withForm } from '~/components/Form'
import * as NAV from '~/constants/nav-titles'

import CustomerAddress from './CustomerAddress'

export const CUSTOMER_CREATE_FIELDS = [
  'firstName',
  'lastName',
  'email',
  'mobile',
  'imagePath',
  'customerAddress',
  'deliveryAddresses',
  'billingAddresses',
  'status',
  'language',
  'tags',
  'imagePath'
]

function CustomerCreateForm ({ pageTitle, isLoading, form }) {
  const { handleSubmit } = form

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Container>
        <Header>
          <PageTitle
            pageTitle={pageTitle}
            parentTitle={NAV.CUSTOMERS}
            rightButton={(
              <>
                <DiscardButton disabled={isLoading} />
                <SaveButton disabled={isLoading} />
              </>
            )}
          />
        </Header>

        <Content>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12} lg={8}>
              <Box mb={3}>
                <Card>
                  <CardHeader title="Information" />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12} lg={6}>
                        <TextField
                          data-cy="firstName"
                          name="firstName"
                          label="First name"
                          required={true}
                        />
                      </Grid>

                      <Grid item={true} xs={12} lg={6}>
                        <TextField
                          data-cy="lastName"
                          name="lastName"
                          label="Last name"
                          required={true}
                        />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <EmailField
                          data-cy="email"
                          name="email"
                          label="Email"
                          required={true}
                        />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <PhoneNumberField
                          data-cy="mobile"
                          name="mobile"
                          label="Phone number"
                          country="uz"
                          required={true}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
              <CustomerAddress />
            </Grid>
            <Grid item={true} xs={12} lg={4}>
              <Box mb={3}>
                <Card>
                  <CardHeader title="Image" />
                  <Divider />
                  <CardContent>
                    <SingleImageUploadField name="imagePath" accept="image/png, image/jpeg" />
                  </CardContent>
                </Card>
              </Box>
            </Grid>

          </Grid>
          <Box mb={3} />
        </Content>
      </Container>
    </form>
  )
}

CustomerCreateForm.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func,
  form: PropTypes.object,
  isLoading: PropTypes.bool.isRequired
}

export default withForm(CustomerCreateForm)
