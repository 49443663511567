import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import IntegrationTypeContainer from './IntegrationTypeContainer'
import IntegrationListContainer from './IntegrationListContainer'

export default () => {
  return [
    {
      layout: AuthLayout,
      path: ROUTES.INTEGRATION_LIST_PATH,
      component: IntegrationListContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.INTEGRATION_TYPE_PATH,
      component: IntegrationTypeContainer
    }
  ]
}
