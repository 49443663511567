import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import { path, prop } from 'ramda'
import Box from '@mui/material/Box'
import CardContent from '@mui/material/CardContent'

import { CardHeader } from '~/components/Cards'

import OrderCard from './OrderCard'
import ShipmentCard from './ShipmentCard'
import ReturnDeliveryAddresses from './ReturnDeliveryAddresses'
import ReturnPickupAddresses from './ReturnPickupAddress'
import MethodCard from './MethodCard'
import ReturnStatuses from './ReturnStatuses'
import InvoiceSummary from './InvoiceSummary'
import LineItems from './LineItems'
import CustomerNotes from './CustomerNotes'

function ReturnDetailGeneral ({ detail, isLoading }) {
  const lineItems = prop('lineItems', detail)
  const customerNotes = prop('notes', detail)
  const pickupAddress = prop('pickupAddress', detail)
  const deliveryAddress = prop('deliveryAddress', detail)
  const warehouse = path(['warehouse', 'name'], detail)

  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12} lg={9}>
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={12}>
            <ReturnStatuses detail={detail} isLoading={isLoading} />
          </Grid>

          <Grid item={true} xs={12}>
            <Card>
              <CardHeader title="Line items" />
              <Divider />
              <LineItems lineItems={lineItems} isLoading={isLoading} />
            </Card>
          </Grid>

          <Grid item={true} xs={12} lg={6}>
            <Card>
              <CardHeader title="Invoice summary" />
              <Divider />
              <InvoiceSummary lineItems={lineItems} />
            </Card>
          </Grid>

          <Grid item={true} xs={12} lg={6}>
            <Card style={{ height: '100%' }}>
              <CardHeader title="Customer notes" />
              <Divider />
              <CardContent>
                <CustomerNotes notes={customerNotes} />
              </CardContent>
            </Card>
          </Grid>

          <Grid item={true} xs={12} lg={6}>
            <ReturnPickupAddresses fromAddress={pickupAddress} />
          </Grid>

          <Grid item={true} xs={12} lg={6}>
            <ReturnDeliveryAddresses
              warehouse={warehouse}
              fromAddress={deliveryAddress}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item={true} xs={12} lg={3}>
        <Box mb={3}>
          <OrderCard detail={detail} />
        </Box>

        <Box mb={3}>
          <MethodCard detail={detail} />
        </Box>

        <Box mb={3}>
          <ShipmentCard detail={detail} />
        </Box>
      </Grid>
    </Grid>
  )
}

ReturnDetailGeneral.propTypes = {
  detail: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default ReturnDetailGeneral
