import { pick, prop } from 'ramda'
import { DEFAULT_SEARCH_PARAMS } from 'storfox-route-hooks'

import { orderingToSnackCase, escapeAtob } from '~/utils'

export function shipmentFilterSerializer (params) {
  const defaultParams = pick(DEFAULT_SEARCH_PARAMS, params)
  const companyId = escapeAtob(prop('company', params))

  const createdAtStart = prop('createdAtStart', params)
  const createdAtEnd = prop('createdAtEnd', params)

  return orderingToSnackCase({
    ...defaultParams,
    companyId,
    createdAtStart,
    createdAtEnd
  })
}
