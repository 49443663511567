import PropTypes from 'prop-types'
import React from 'react'
import { Divider } from '@mui/material'
import CardContent from '@mui/material/CardContent'
import Barcode from 'react-barcode'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { CardHeader } from '~/components/Cards'
import { IGNORE_SCAN } from '~/constants/barcode'

function ActiveContainerCard ({ activeContainerNumber }) {
  return (
    <Card style={{ height: '100%' }}>
      <CardHeader title="Active container" />
      <Divider />
      <CardContent style={{ height: '100%' }}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={[
            !activeContainerNumber && {
              opacity: 0.5
            }
          ]}
        >
          <Barcode
            height={30}
            width={2}
            displayValue={false}
            value={activeContainerNumber || IGNORE_SCAN}
          />
          <Typography>
            {activeContainerNumber || 'No container.'}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  )
}

ActiveContainerCard.propTypes = {
  activeContainerNumber: PropTypes.string
}

export default ActiveContainerCard
