import React from 'react'
import Paper from '@mui/material/Paper'
import AddIcon from '@mui/icons-material/AddCircleOutline'
import { Divider, styled } from '@mui/material'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'

import SearchAutoClearField from './SearchAutoClearField'

const AddButtonStyled = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  zIndex: 1000
}))

function SearchCreateAutoClearField ({ addText, onAddClick, ...props }) {
  return (
    <SearchAutoClearField
      PaperComponent={({ children, ...rest }) => (
        <Paper {...rest}>
          <AddButtonStyled onMouseDown={onAddClick}>
            <AddIcon />&nbsp;{addText}
          </AddButtonStyled>
          <Divider />
          {children}
        </Paper>
      )}
      {...props}
    />
  )
}

SearchCreateAutoClearField.propTypes = {
  addText: PropTypes.string.isRequired,
  onAddClick: PropTypes.func.isRequired
}

export default SearchCreateAutoClearField
