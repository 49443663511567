import React from 'react'

import SelectField from '~/components/Fields/SelectField'

export const SALLA_TYPE = 'salla'
export const SALLA_APP_TYPE = 'salla_app'

const SALLA_APP_TYPES = {
  [SALLA_TYPE]: 'Salla integration',
  [SALLA_APP_TYPE]: 'Salla App'
}

export const options = [
  { value: SALLA_TYPE, name: SALLA_APP_TYPES[SALLA_TYPE] },
  { value: SALLA_APP_TYPE, name: SALLA_APP_TYPES[SALLA_APP_TYPE] },
]

function SallaCreateType ({ ...props }) {
  return (
    <SelectField
      name="type"
      data-cy="type"
      label="Save as"
      options={options}
      {...props}
    />
  )
}

export default SallaCreateType
