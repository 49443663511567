import React from 'react'

import SelectField from '~/components/Fields/SelectField'

import { CANCELLED, COMPLETED, DRAFT } from '../MovementStatus'

const OPTIONS = [
  { value: null, name: 'All' },
  { value: DRAFT, name: 'Draft' },
  { value: COMPLETED, name: 'Completed' },
  { value: CANCELLED, name: 'Cancelled' },
]
function MovementStatusField (props) {
  return (
    <SelectField
      name="status"
      label="Status"
      options={OPTIONS}
      {...props}
    />
  )
}

export default MovementStatusField
