import React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useSnackbar } from 'storfox-snackbar'
import { useTranslation } from 'react-i18next'
import { prop } from 'ramda'

import { DashboardLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'
import { WAREHOUSE_TABS } from '~/constants/tabs'

import { useWarehouseDetail, useZoneCreate } from '../hooks'
import { ZONE_STATUS } from '../constants'
import { ZoneCreateSerializer } from '../serializers'
import { ZoneCreateForm } from '../components/ZoneCreate'

function ZoneCreateContainer () {
  const { t } = useTranslation()
  const { warehouseGuid } = useParams()
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const zoneCreate = useZoneCreate()
  const warehouseDetail = useWarehouseDetail(warehouseGuid)

  const warehouseName = prop('name', warehouseDetail.detail)

  const initialValues = {
    status: ZONE_STATUS.ACTIVE,
    warehouse: { guid: warehouseGuid, name: warehouseName }
  }

  const redirectPath = generatePath(ROUTES.WAREHOUSE_DETAIL_PATH, { guid: warehouseGuid, tab: WAREHOUSE_TABS.ZONE })

  const handleSubmit = formValues =>
    zoneCreate.create(ZoneCreateSerializer(formValues))
      .then(() => snackbar({ message }))
      .then(() => navigate(redirectPath))

  const title = 'New Zone'
  const message = t('Successfully created')

  const breadcrumbs = {
    title,
    params: [
      { guid: warehouseGuid, tab: WAREHOUSE_TABS.ZONE, title: warehouseName }
    ]
  }

  const isLoading = zoneCreate.isLoading || warehouseDetail.isLoading

  return (
    <DashboardLayout
      title={title}
      isLoading={isLoading}
      activeNav={NAV.WAREHOUSE}
      breadcrumbs={breadcrumbs}
    >
      <ZoneCreateForm
        pageTitle={title}
        pageTitleLoading={false}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        isLoading={isLoading}
      />
    </DashboardLayout>
  )
}

export default ZoneCreateContainer
