import React from 'react'
import { SwitchField } from 'storfox-form-fields'
import PropTypes from 'prop-types'

function DelegatedAccessSwitchField ({ isClient }) {
  return (
    <>
      <SwitchField
        data-cy="delegatedAccess"
        name="delegatedAccess"
        disabled={!isClient}
      />
      Delegated Access
    </>

  )
}

DelegatedAccessSwitchField.propTypes = {
  isClient: PropTypes.bool.isRequired,
}

export default DelegatedAccessSwitchField
