import { assoc, defaultTo, map, path, pathOr, pick, pipe, prop, trim, uniq } from 'ramda'
import { DEFAULT_SEARCH_PARAMS } from 'storfox-route-hooks'

import { escapeAtob, getDateTimeMidnight, getFormattedDateTime } from '~/utils'
import { SALE_ORDER } from '~/constants/shipmentTypes'

import { SHIPMENT_UPDATE_FIELDS } from '../components/ShipmentUpdate'

const SHIPPING_DETAIL = [
  'containersCount',
  'vehicleNumber',
  'totalPrice',
  'totalWeight',
  'totalVolume',
  'service',
  'currency'
]

const ORDER_DETAIL = [
  'paymentType',
  'shippingMethod',
  'toObjectReferenceNumber',
  'deliveryMethod',
  'orderDate',
  'toObjectTotalPrice',
  'toObjectGuid',
  'toObjectType',
  'fromCompany',
  'currency'
]

const FROM_ADDRESS = [
  'firstName',
  'lastName',
  'address',
  'city',
  'email',
  'countryId',
  'countryName',
  'mobile',
  'region',
  'zipcode',
  'latitude',
  'longitude',
]

const SHIPPING_ADDRESS = [
  'firstName',
  'lastName',
  'address',
  'email',
  'city',
  'countryId',
  'countryName',
  'mobile',
  'region',
  'zipcode',
  'latitude',
  'longitude',
]

const getFromAddressFromValues = pipe(
  defaultTo([]),
  pick(FROM_ADDRESS),
  map(item => trim(item.toString()))
)

const getShippingAddressFromValues = pipe(
  defaultTo([]),
  pick(SHIPPING_ADDRESS),
  map(item => trim(item.toString()))
)

const getUniqueContainers = pipe(
  defaultTo([]),
  map(prop('container')),
  uniq
)

export function ShipmentInitSerializer (values) {
  const units = prop('units', values)
  const uniqueContainers = getUniqueContainers(units)
  const shippingDetail = pick(SHIPPING_DETAIL, values)
  const orderDetail = pick(ORDER_DETAIL, values)
  const shippingAddressValues = prop('shippingAddress', values)
  const shippingCountryId = prop('countryId', shippingAddressValues)
  const shippingCountryName = prop('countryName', shippingAddressValues)
  const shippingCountry = { id: shippingCountryId, name: shippingCountryName }
  const shippingAddress = { ...shippingAddressValues, country: shippingCountry }

  const fromAddressValues = prop('fromAddress', values)
  const fromCountryId = prop('countryId', fromAddressValues)
  const fromCountryName = prop('countryName', fromAddressValues)
  const fromCountry = { id: fromCountryId, name: fromCountryName }
  const fromAddress = { ...fromAddressValues, country: fromCountry }

  const toObjectType = prop('toObjectType', values)
  const toObjectId = prop('toObjectId', values)
  const isSaleOrder = toObjectType === SALE_ORDER

  return {
    ...values,
    uniqueContainers,
    shippingAddress,
    fromAddress,
    shippingDetail,
    orderDetail: {
      ...orderDetail,
      orderNumber: `${isSaleOrder ? 'SO' : 'RT'}-${toObjectId}`
    }
  }
}

export function ShipmentUpdateSerializer (formValues) {
  const shippingAddressField = prop('shippingAddress', formValues)
  const fromAddressField = prop('fromAddress', formValues)

  const values = pick(
    SHIPMENT_UPDATE_FIELDS,
    formValues
  )

  const shipmentAddressValues = getShippingAddressFromValues(shippingAddressField)
  const shippingCountryId = path(['shippingAddress', 'country', 'id'], formValues)
  const shippingWarehouseId = path(['shippingAddress', 'id'], formValues)
  const shippingCountryName = path(['shippingAddress', 'country', 'name'], formValues)
  const shippingLatitude = path(['shippingAddress', 'latitude'], formValues)
  const shippingLongitude = path(['shippingAddress', 'longitude'], formValues)
  const shippingAddress = {
    ...pick(SHIPPING_ADDRESS, shipmentAddressValues),
    id: shippingWarehouseId,
    countryId: shippingCountryId,
    countryName: shippingCountryName,
    longitude: shippingLongitude,
    latitude: shippingLatitude
  }

  const fromAddressValues = getFromAddressFromValues(fromAddressField)
  const fromCountryId = path(['fromAddress', 'country', 'id'], formValues)
  const fromCountryName = path(['fromAddress', 'country', 'name'], formValues)
  const fromAddressLatitude = path(['fromAddress', 'latitude'], formValues)
  const fromAddressLongitude = path(['fromAddress', 'longitude'], formValues)
  const fromAddress = {
    ...pick(FROM_ADDRESS, fromAddressValues),
    countryId: fromCountryId,
    countryName: fromCountryName,
    longitude: fromAddressLongitude,
    latitude: fromAddressLatitude
  }

  const replaceAddresses = pipe(
    assoc('shippingAddress', shippingAddress),
    assoc('fromAddress', fromAddress)
  )
  return replaceAddresses(values)
}

export function ShipmentUpdateContainerSerializer (guid, values) {
  const width = path(['containerType', 'width'], values)
  const height = path(['containerType', 'height'], values)
  const length = path(['containerType', 'length'], values)
  const weight = prop('weight', values)
  const containerType = { width, length, height }
  return { containerType, guid, weight }
}

export function ShipmentFilterSerializer (params) {
  const defaultParams = pick(DEFAULT_SEARCH_PARAMS, params)
  const direction = prop('direction', params)
  const status = prop('status', params)
  const isTracking = prop('isTracking', params)
  const objectIds = escapeAtob(prop('objects', params))
  const customerIds = escapeAtob(prop('customers', params))
  const warehouseIds = escapeAtob(prop('warehouses', params))
  const company = escapeAtob(prop('company', params))
  const orderedAtStart = prop('orderedAtStart', params)
  const orderedAtEnd = prop('orderedAtEnd', params)
  const shippedDateStart = prop('shippedDateStart', params)
  const shippedDateEnd = prop('shippedDateEnd', params)
  const deliveredDateStart = prop('deliveredDateStart', params)
  const deliveredDateEnd = prop('deliveredDateEnd', params)
  const createdAtStart = prop('createdAtStart', params)
  const createdAtEnd = prop('createdAtEnd', params)
  const updatedAtStart = prop('updatedAtStart', params)
  const updatedAtEnd = prop('updatedAtEnd', params)
  const totalPriceMin = prop('totalPriceMin', params)
  const totalPriceMax = prop('totalPriceMax', params)
  const clientCompanyId = prop('id', company)
  const trackingNumbers = prop('trackingNumbers', params)

  return {
    ...defaultParams,
    direction,
    objectIds,
    status,
    customerIds,
    warehouseIds,
    orderedAtStart,
    orderedAtEnd,
    shippedDateStart,
    shippedDateEnd,
    deliveredDateStart,
    deliveredDateEnd,
    createdAtStart,
    createdAtEnd,
    updatedAtStart,
    updatedAtEnd,
    totalPriceMin,
    totalPriceMax,
    clientCompanyId,
    trackingNumbers,
    isTracking
  }
}

export const ShipmentExportSerializer = values => {
  const fromDatetime = getDateTimeMidnight(prop('fromDatetime', values))
  const toDatetime = getDateTimeMidnight(prop('toDatetime', values))

  return { fromDatetime, toDatetime }
}

export const ShipmentGatePassGenerateSerializer = formValues => {
  const driver = prop('driver', formValues)
  const timeIn = getFormattedDateTime(path(['driver', 'timeIn'], formValues))
  const timeOut = getFormattedDateTime(path(['driver', 'timeOut'], formValues))

  return {
    ...formValues,
    driver: { ...driver, timeIn, timeOut }
  }
}

export const ShipmentSetCourierSerializer = formValues => {
  const service = prop('service', formValues)
  const addressCity = path(['shippingAddress', 'city'], formValues)
  const shippingCities = pathOr(addressCity, ['shippingCities', 'name'], formValues)
  const shippingMethod = prop('shippingMethod', formValues)
  return {
    service,
    shippingAddress: { city: shippingCities },
    shippingMethod
  }
}
