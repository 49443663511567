import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'
import PropTypes from 'prop-types'

function EditIcon (props) {
  const d = `M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 0
   0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z`

  return (
    <SvgIcon {...props} cursor={props.disabled ? 'not-allowed' : 'pointer'}>
      <path d={d} fill={props.disabled ? '#abb3ba' : '#6F7E8C'} />
    </SvgIcon>
  )
}

EditIcon.propTypes = {
  disabled: PropTypes.bool
}

export default EditIcon
