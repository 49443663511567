import React from 'react'
import PropTypes from 'prop-types'
import { map, path, prop, propOr } from 'ramda'
import Link from '@mui/material/Link'
import { generatePath, Link as ReceiveLink } from 'react-router-dom'

import { TableNoCache } from '~/components/Table'
import TableDateFormat from '~/components/TableDateFormat/TableDateFormat'
import * as ROUTES from '~/constants/routes'
import { RECEIVE_TABS } from '~/constants/tabs'
import { generateCdnUrl } from '~/utils'

const getRowsFromResults = map(item => {
  const id = prop('id', item)
  const number = prop('number', item)
  const createdAt = prop('createdAt', item)
  const invoiceNumber = prop('invoiceNumber', item)
  const numberPlate = path(['vehicle', 'numberPlate'], item)
  const fileUrl = prop('fileUrl', item)
  const receiptGuid = propOr('', 'receiptGuid', item)

  const receive = {
    guid: receiptGuid
  }

  const link = generatePath(ROUTES.RECEIVE_PURCHASE_ORDER_DETAIL_PATH, {
    guid: receiptGuid,
    tab: RECEIVE_TABS.GENERAL
  })

  return {
    id,
    number,
    createdAt,
    invoiceNumber,
    numberPlate,
    fileUrl,
    receive,
    link
  }
})

const columns = [
  {
    width: 200,
    headerName: 'Number',
    field: 'number'
  },
  {
    width: 200,
    headerName: 'Created At',
    field: 'createdAt',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  },
  {
    width: 200,
    headerName: 'Number Plate',
    field: 'numberPlate'
  },
  {
    width: 200,
    headerName: 'Invoice Number',
    field: 'invoiceNumber'
  },
  {
    width: 200,
    headerName: 'File Url',
    field: 'fileUrl',
    renderCell: ({ value }) => (
      <Link
        component="a"
        href={generateCdnUrl(value)}
        variant="subtitle2"
        underline="always"
      >
        Download
      </Link>
    )
  },
  {
    width: 200,
    headerName: 'Receive',
    field: 'receive',
    renderCell: ({ row }) => (
      <Link
        component={ReceiveLink}
        data-cy="receive"
        underline="always"
        to={row.link}
      >
        Receive
      </Link>
    )
  },
]

function PurchaseOrderGatePass ({ gatePassList }) {
  const isLoading = prop('isLoading', gatePassList)
  const results = prop('results', gatePassList)
  const count = prop('count', gatePassList)

  return (
    <>
      <TableNoCache
        isLoading={isLoading}
        columns={columns}
        rows={getRowsFromResults(results)}
        fixedColumnCount={1}
        count={count}
        onlyData={true}
        checkboxSelection={false}
      />
    </>
  )
}

PurchaseOrderGatePass.propTypes = {
  gatePassList: PropTypes.object.isRequired
}

export default PurchaseOrderGatePass
