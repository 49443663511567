import buckets from './modules/buckets'
import lotSheets from './modules/lot-sheets'
import shipment from './modules/shipment'

const CrossDocksRoutes = () => [
  ...buckets(),
  ...lotSheets(),
  ...shipment()
]

export default CrossDocksRoutes
