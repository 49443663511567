import React from 'react'
import { useTranslation } from 'react-i18next'

import SelectField from './SelectField'

import { NAMES, EN, RU, AR, UZ } from '../Language/Language'

const options = [
  { value: EN, name: NAMES[EN] },
  { value: RU, name: NAMES[RU] },
  { value: UZ, name: NAMES[UZ] },
  { value: AR, name: NAMES[AR] },
]

function LanguageField ({ ...props }) {
  const { t } = useTranslation()

  return (
    <SelectField
      data-cy="language"
      name="language"
      label={t('Language')}
      options={options}
      {...props}
    />
  )
}

export default LanguageField
