import React from 'react'
import PropTypes from 'prop-types'
import LinearProgress from '@mui/material/LinearProgress'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'

const LinearProgressStyled = styled(LinearProgress)(({ size }) => ({
  height: 8,
  marginLeft: 10,
  ...(size === SMALL && {
    width: 75
  }),
  ...(size === MEDIUM && {
    width: 150
  }),
  ...(size === LARGE && {
    width: 200
  }),
  ...(size === FULL_WIDTH && {
    width: '100%'
  }),
}))

const LARGE = 'lg'
const MEDIUM = 'md'
const SMALL = 'sm'
const FULL_WIDTH = 'fw'

function OrderProgress ({ ordered, received, size, progressClasses, className, textClasses }) {
  const progress = ordered >= received ? Math.ceil(received / (ordered * 0.01)) : 100

  return (
    <Box display="flex" alignItems="center" className={className}>
      <Box component="span" sx={{ marginRight: '5px' }} className={textClasses}>
        {received}/{ordered}
      </Box>
      <LinearProgressStyled
        size={size}
        className={progressClasses}
        variant="determinate"
        value={progress}
      />
    </Box>
  )
}

OrderProgress.defaultProps = {
  ordered: 1,
  received: 0,
  size: SMALL
}

OrderProgress.propTypes = {
  ordered: PropTypes.number.isRequired,
  received: PropTypes.number,
  className: PropTypes.any,
  progressClasses: PropTypes.any,
  textClasses: PropTypes.string,
  size: PropTypes.oneOf(['fw', 'lg', 'md', 'sm'])
}

export default OrderProgress
