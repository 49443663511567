import React from 'react'
import PropTypes from 'prop-types'

import Status from '~/components/Status'

export const NEW = 'new'
export const PENDING = 'pending'
export const IN_PROGRESS = 'in_progress'
export const CANCELLED = 'cancelled'
export const COMPLETED = 'completed'

export const PICKLIST_NAMES = {
  [NEW]: 'New',
  [PENDING]: 'Pending',
  [IN_PROGRESS]: 'In progress',
  [CANCELLED]: 'Cancelled',
  [COMPLETED]: 'Completed'
}

const STATUS_COLOR = {
  [NEW]: 'success',
  [PENDING]: 'warning',
  [IN_PROGRESS]: 'warning',
  [CANCELLED]: 'error',
  [COMPLETED]: 'success',
}

function PicklistStatus ({ value }) {
  return (
    <Status
      label={PICKLIST_NAMES[value] || value}
      color={STATUS_COLOR[value]}
    />
  )
}

PicklistStatus.propTypes = {
  value: PropTypes.oneOf([
    NEW,
    PENDING,
    IN_PROGRESS,
    COMPLETED,
    CANCELLED
  ]).isRequired
}

export default PicklistStatus
