import React from 'react'
import { SwitchField } from 'storfox-form-fields'
import { useField } from 'react-final-form'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDeepCompareEffect } from 'storfox-api-hooks'
import Box from '@mui/material/Box'

function PrimarySwitchField ({ name, onChange }) {
  const { t } = useTranslation()
  const field = useField(name)
  const value = field.input.value

  useDeepCompareEffect(() => {
    if (value) {
      onChange()
    }
  }, [value])

  return (
    <Box>
      <SwitchField name={name} />
      {t('Default address')}
    </Box>
  )
}

PrimarySwitchField.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

export default PrimarySwitchField
