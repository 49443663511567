import PropTypes from 'prop-types'
import React from 'react'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import CachedIcon from '@mui/icons-material/Cached'
import { styled } from '@mui/material'

const RootBoxStyled = styled(Box)({
  position: 'absolute',
  right: 0,
  top: 0,
  padding: '10px 10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
})

function GraphButtons ({ onReset }) {
  return (
    <RootBoxStyled>
      <IconButton size="small" title="Reset view" onClick={onReset}>
        <CachedIcon />
      </IconButton>
    </RootBoxStyled>
  )
}

GraphButtons.propTypes = {
  onReset: PropTypes.func.isRequired
}

export default GraphButtons
