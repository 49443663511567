import { path, pick } from 'ramda'

import { AREA_CREATE_FIELDS } from '../components/AreaCreate'

const getWarehouse = path(['warehouse', 'guid'])

export function AreaCreateSerializer (formValues) {
  const warehouseGuid = getWarehouse(formValues)
  const warehouse = warehouseGuid && { guid: warehouseGuid }
  const data = { ...formValues, warehouse }

  return pick(AREA_CREATE_FIELDS, data)
}
