import IconButton from '@mui/material/IconButton'
import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'

const DeleteIconStyled = styled(DeleteIcon)(({ disabled }) => ({
  ...(!disabled && ({
    backgroundColor: 'rgba(255, 0, 0, 0.08)',
    fill: 'red'
  }))
}))

function DeleteIconButton ({ disabled, ...props }) {
  return (
    <IconButton {...props} size="large">
      <DeleteIconStyled disabled={disabled} />
    </IconButton>
  )
}

DeleteIconButton.propTypes = {
  disabled: PropTypes.bool
}

export default DeleteIconButton
