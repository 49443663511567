import React from 'react'
import PropTypes from 'prop-types'

import * as NAV from '~/constants/nav-titles'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import LoaderProvider from '~/components/Loader'

import WarehouseContent from './WarehouseContent'

function WarehouseDetail (props) {
  const {
    pageTitle,
    pageTitleLoading,
    isLoading,
    warehouseDetail,
  } = props

  return (
    <LoaderProvider isLoading={isLoading}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={pageTitle}
            pageTitleLoading={pageTitleLoading}
            parentTitle={NAV.WAREHOUSES}
          />
        </Header>

        <Content>
          <WarehouseContent warehouse={warehouseDetail} />
        </Content>
      </Container>
    </LoaderProvider>
  )
}

WarehouseDetail.propTypes = {
  pageTitle: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  warehouseDetail: PropTypes.object.isRequired,
  pageTitleLoading: PropTypes.bool.isRequired,
}

export default WarehouseDetail
