import React from 'react'
import List from '@mui/material/List'
import ListItemText from '@mui/material/ListItemText'
import PropTypes from 'prop-types'
import { useConfirm } from 'storfox-confirm-dialog'
import { ALTER_ERROR, ALTER_SUCCESS, useSnackbar } from 'storfox-snackbar'
import { ListItemButton } from '@mui/material'

import { MoreButton } from '~/components/Buttons'
import ErrorLink from '~/components/ErrorLink'

function MoreDelete ({ onRemove, onProductsSync, ...props }) {
  const onConfirm = useConfirm()
  const snackbar = useSnackbar()

  const handleRemove = () => {
    const message = 'Are you sure, you want to delete selected integration?'

    onConfirm({ message })
      .agree(() => {
        onRemove()
          .then(() => snackbar({ message: 'Successfully deleted', type: ALTER_SUCCESS }))
          .catch(error => snackbar({ message: <ErrorLink error={error} />, type: ALTER_ERROR }))
      })
      .disagree()
  }

  return (
    <MoreButton>
      {() => (
        <List>
          <ListItemButton
            onClick={handleRemove}
            data-cy="delete"
            {...props}
          >
            <ListItemText primary="Delete" />
          </ListItemButton>
          <ListItemButton
            onClick={onProductsSync}
            data-cy="productsSync"
            {...props}
          >
            <ListItemText primary="Product import" />
          </ListItemButton>
        </List>
      )}
    </MoreButton>
  )
}

MoreDelete.propTypes = {
  onRemove: PropTypes.func.isRequired,
  onProductsSync: PropTypes.func.isRequired
}

export default MoreDelete
