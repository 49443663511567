import React from 'react'

import SelectField from '~/components/Fields/SelectField'

const OPTIONS = [
  {
    value: '1',
    name: 'Card on Delivery'
  },
  {
    value: '2',
    name: 'Cash'
  },
  {
    value: '3',
    name: 'In-App Payment'
  }
]

function PaymentModeField (props) {
  return (
    <SelectField
      label="Payment mode"
      options={OPTIONS}
      {...props}
    />
  )
}

export default PaymentModeField
