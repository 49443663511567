import { pick, prop } from 'ramda'
import { DEFAULT_SEARCH_PARAMS } from 'storfox-route-hooks'

import { escapeAtob, orderingToSnackCase } from '~/utils'

import { WAREHOUSE_CONTENT_FIELDS } from '../components/WarehouseContent'

export function WarehouseCreateSerializer (formValues) {
  return pick(WAREHOUSE_CONTENT_FIELDS, formValues)
}

export function WarehouseFilterSerializer (params) {
  const defaultParams = pick(DEFAULT_SEARCH_PARAMS, params)
  const status = prop('status', params)
  const countryId = escapeAtob(prop('country', params))
  const isDefault = prop('isDefault', params)

  return {
    ...defaultParams,
    status,
    countryId,
    isDefault,
  }
}

export function LocationFilterSerializer (params) {
  const defaultParams = pick(DEFAULT_SEARCH_PARAMS, params)
  const status = prop('status', params)
  const warehouseId = prop('warehouseId', params)
  const locationType = prop('locationType', params)
  const areaGuid = escapeAtob(prop('area', params))
  const warehouseGuids = escapeAtob(prop('warehouses', params)) || []
  const zoneGuid = escapeAtob(prop('zone', params))
  const maxLoadStart = prop('maxLoadStart', params)
  const maxLoadEnd = prop('maxLoadEnd', params)
  const usedVolumeStart = prop('usedVolumeStart', params)
  const usedVolumeEnd = prop('usedVolumeEnd', params)
  const utilizationStart = prop('utilizationStart', params)
  const utilizationEnd = prop('utilizationEnd', params)
  const containersCountStart = prop('containersCountStart', params)
  const containersCountEnd = prop('containersCountEnd', params)

  return {
    ...defaultParams,
    status,
    locationType,
    areaGuid,
    warehouseId,
    warehouseGuids,
    zoneGuid,
    maxLoadStart,
    maxLoadEnd,
    usedVolumeStart,
    usedVolumeEnd,
    utilizationStart,
    utilizationEnd,
    containersCountStart,
    containersCountEnd,
  }
}

export const ContainerFilterSerializer = params => {
  const defaultParams = pick(DEFAULT_SEARCH_PARAMS, params)

  const supplierId = escapeAtob(prop('supplier', params))
  const content = prop('content', params)
  const warehouseGuids = escapeAtob(prop('warehouses', params))
  const containerTypeGuids = escapeAtob(prop('containerTypes', params))
  const locationGuids = escapeAtob(prop('locations', params))
  const startDate = escapeAtob(prop('startDate', params))
  const endDate = escapeAtob(prop('endDate', params))
  const minTotal = escapeAtob(prop('minTotal', params))
  const maxTotal = escapeAtob(prop('maxTotal', params))

  return orderingToSnackCase({
    ...defaultParams,
    supplierId,
    content,
    containerTypeGuids,
    locationGuids,
    warehouseGuids,
    startDate,
    endDate,
    minTotal,
    maxTotal
  })
}
