import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import { Box, styled } from '@mui/material'

const DropZoneStyled = styled(Box)(({ theme }) => ({
  border: `1px dashed ${theme.palette.divider}`,
  borderRadius: 5,
  height: 100,
  width: '100%',
  outline: 'none',
  display: 'flex',
  textAlign: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
  alignItems: 'center',
  transition: '.25s ease all',
  '&:hover': {
    backgroundColor: theme.palette.background.dark,
    opacity: 0.5,
    cursor: 'pointer'
  }
}))

const TypographyStyled = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    fontSize: '12px',
    lineHeight: '0.7em'
  }
}))

function AttachButton ({ onClick }) {
  return (
    <DropZoneStyled onClick={() => onClick()}>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        height: '100% !important'
      }}>
        <div>
          <TypographyStyled align="center" variant="button">
            Attach files
          </TypographyStyled>
          <Typography align="center" variant="caption" display="block">
            from product images
          </Typography>
        </div>
      </Box>
    </DropZoneStyled>
  )
}

AttachButton.propTypes = {
  onClick: PropTypes.func
}

export default AttachButton
