import React, { useEffect } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { pathOr, prop } from 'ramda'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import AppContainer from '~/components/AppContainer'
import { useToken } from '~/components/Token'
import { useCompany, useMe, useProfile } from '~/components/Profile'
import { set as setLanguage } from '~/components/Language'
import * as ROUTES from '~/constants/routes'
import { OVERVIEW_TABS } from '~/constants/tabs'
import ErrorLink from '~/components/ErrorLink'

import { useVerify, useResendVerify } from '../hooks'
import Auth2Factor from '../components/Auth2Factor'

function Auth2FactorContainer () {
  const navigate = useNavigate()
  const { token: factorToken } = useParams()
  const { i18n } = useTranslation()
  const { verify, isLoading } = useVerify()
  const { resend, isLoading: resendLoading } = useResendVerify()
  const { setToken } = useToken()
  const snackbar = useSnackbar()

  const { getMe } = useMe()
  const { setProfile } = useProfile()
  const { isThreePl } = useCompany()

  const tab = isThreePl ? OVERVIEW_TABS.FULFILLMENT : OVERVIEW_TABS.SALES
  const redirectTo = generatePath(ROUTES.OVERVIEW_PATH, { tab })

  useEffect(() => {
    setTimeout(() => {
      navigate(ROUTES.SIGN_IN)
      snackbar({ message: 'Token expired', type: ALTER_ERROR })
    }, 300000)
  }, [navigate, snackbar])

  const handleResend = () =>
    resend({ token_2fa: factorToken })
      .catch(error => {
        const nonFieldError = pathOr('', ['nonFieldErrors', 0], error)
        if (nonFieldError === 'Token 2fa not found.') {
          navigate(ROUTES.SIGN_IN)
        }
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const handleSubmit = formValues =>
    verify({ ...formValues, token_2fa: factorToken })
      .then(({ token }) => {
        setToken(token)
        getMe().then(({ result }) => {
          const language = prop('language', result)
          i18n.changeLanguage(language)
            .then(() => setLanguage(language))
          setProfile(result)
          navigate(redirectTo, { replace: true })
        })
      })
      .catch(error => {
        const nonFieldError = pathOr('', ['nonFieldErrors', 0], error)
        if (nonFieldError === 'Token 2fa not found.' || nonFieldError === 'Authorization failed. Please try again!') {
          navigate(ROUTES.SIGN_IN)
        }
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  return (
    <AppContainer title="Sign In">
      <Auth2Factor
        isLoading={isLoading || resendLoading}
        onSubmit={handleSubmit}
        onResend={handleResend}
      />
    </AppContainer>
  )
}

export default Auth2FactorContainer
