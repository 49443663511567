import React from 'react'
import { useFilter, useOrdering } from 'storfox-filter'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import ErrorLink from '~/components/ErrorLink'
import useMessages from '~/hooks/useMessages'

import {
  StockAgingFilterForm,
  stockAgingFilterOptions,
  StockAgingOrderingForm,
  stockAgingOrderingOptions,
  StockAgingTable
} from '../../components/StockAging/StockAgingList'
import { useStockAgingExport, useStockAgingList } from '../../hooks/StockAging'

function StockAgingListContainer () {
  const stockAgingList = useStockAgingList()
  const stockAgingExport = useStockAgingExport()
  const messages = useMessages()
  const snackbar = useSnackbar()

  const handleStockAgingExport = () =>
    stockAgingExport.export()
      .then(() => snackbar({ message: messages.NOTIFICATION_WAIT }))
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const filter = useFilter(stockAgingFilterOptions)
  const ordering = useOrdering(stockAgingOrderingOptions)

  const breadcrumbs = { title: NAV.STOCK_AGING_REPORT }

  return (
    <DashboardLayout
      title={NAV.STOCK_AGING_REPORT}
      activeNav={NAV.REPORTS}
      breadcrumbs={breadcrumbs}
    >
      <StockAgingFilterForm {...filter} />
      <StockAgingOrderingForm {...ordering} />

      <StockAgingTable
        onListRefetch={stockAgingList.getListByParams}
        ordering={ordering}
        list={stockAgingList}
        filter={filter}
        onStockAgingExport={handleStockAgingExport}
      />
    </DashboardLayout>
  )
}

export default StockAgingListContainer
