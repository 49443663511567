import React from 'react'
import { path, prop, propOr } from 'ramda'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import Avatar from '~/components/Avatar/Avatar'
import Money from '~/components/Money'
import VerticalAlignment from '~/components/VerticalAlignment'
import TextOverflow from '~/components/TextOverflow'
import Subtext from '~/components/Subtext'

function LineItems ({ lineItems }) {
  const { t } = useTranslation()

  return (
    <TableContainer>
      <Table size="small" sx={{ minWidth: '700px' }}>
        <TableHead>
          <TableRow>
            <TableCell>{t('Image')}</TableCell>
            <TableCell sx={{ width: '350px' }}>{t('Product')}</TableCell>
            <TableCell>{t('Unit price')}</TableCell>
            <TableCell sx={{ minWidth: '200px' }}>{t('Condition code')}</TableCell>
            <TableCell>{t('Qty')}</TableCell>
            <TableCell>{t('Total')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lineItems.map(item => {
            const guid = prop('guid', item)
            const variantName = path(['variant', 'name'], item)
            const sku = path(['variant', 'sku'], item)
            const imagePath = path(['variant', 'defaultImage'], item)
            const unitCost = propOr(0, 'price', item)
            const quantity = propOr(0, 'quantity', item)
            const totalPrice = quantity * unitCost
            const condition = path(['condition', 'code'], item)

            return (
              <TableRow key={guid}>
                <TableCell>
                  <Avatar
                    alt={variantName}
                    src={imagePath}
                  />
                </TableCell>
                <TableCell>
                  <VerticalAlignment
                    sx={{ minWidth: 200 }}
                    primary={(
                      <TextOverflow selfTooltip={true}>
                        {variantName}
                      </TextOverflow>
                    )}
                    secondary={<Subtext lines={1}>{sku}</Subtext>}
                  />
                </TableCell>
                <TableCell>
                  <Money value={unitCost} />
                </TableCell>
                <TableCell>{condition}</TableCell>
                <TableCell sx={{ width: '150px' }}>{quantity}</TableCell>
                <TableCell>
                  <Money value={totalPrice} />
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>

  )
}

LineItems.propTypes = {
  lineItems: PropTypes.array.isRequired
}

LineItems.defaultProps = {
  lineItems: []
}

export default LineItems
