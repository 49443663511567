import adjustment from './modules/adjustment'
import putawayV2 from './modules/putaway-v2'
import receive from './modules/receive'
import transfer from './modules/transfer'
import movement from './modules/movement'
import stockCount from './modules/stock-count'
import inventoryLog from './modules/inventory-log'
import reorder from './modules/reorder'

const StockRoutes = () => [
  ...adjustment(),
  ...putawayV2(),
  ...receive(),
  ...transfer(),
  ...movement(),
  ...stockCount(),
  ...inventoryLog(),
  ...reorder()
]

export default StockRoutes
