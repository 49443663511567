import React, { useState } from 'react'
import { map, path, prop } from 'ramda'
import PropTypes from 'prop-types'
import Link from '@mui/material/Link'

import * as ROUTES from '~/constants/routes'
import Container, { Content, Header } from '~/components/Container'
import PageTitle from '~/components/PageTitle'
import TableDateFormat from '~/components/TableDateFormat'
import { Table, TableHeader } from '~/components/Table'
import * as NAV from '~/constants/nav-titles'
import VerticalAlignment from '~/components/VerticalAlignment'
import TextOverflow from '~/components/TextOverflow'
import Subtext from '~/components/Subtext'
import Avatar from '~/components/Avatar'
import SourceDestinationLink from '~/components/SourceDestinationLink'
import CurrencyMoney from '~/components/Money/CurrencyMoney'
import { MoreListButton } from '~/components/Buttons'

import QuantityChange from './QuantityChange'

export const INVENTORY_LOG_COLUMNS = [
  {
    width: 75,
    headerName: 'Image',
    field: 'image',
    renderCell: ({ value, row }) => (
      <Avatar alt={row.variant.name} src={value.src} />
    )
  },
  {
    width: 300,
    headerName: 'Variant',
    field: 'variant',
    valueFormatter: ({ value }) => value.name,
    renderCell: ({ value }) => (
      <VerticalAlignment
        primary={(
          <Link
            target="_blank"
            href={`${ROUTES.UNIT_HISTORY_PATH}?guid=${value.unitGuid}`}
            rel="noopener noreferrer"
            underline="always"
          >
            <TextOverflow selfTooltip={true}>{value.name}</TextOverflow>
          </Link>
        )}
        secondary={<Subtext>{value.sku}</Subtext>}
      />
    )
  },
  {
    width: 150,
    headerName: 'Supply rate',
    field: 'supplyRate',
    valueFormatter: ({ value }) => `${value.price} ${value.currency}`,
    renderCell: ({ value }) => (
      <CurrencyMoney
        currency={value.currency}
        value={value.price}
      />
    )
  },
  {
    width: 150,
    headerName: 'Change',
    field: 'quantityChange',
    headerAlign: 'center',
    align: 'center',
    renderCell: ({ value }) => (
      <QuantityChange>{value}</QuantityChange>
    )
  },
  {
    width: 150,
    headerName: 'Destination',
    field: 'destination',
    valueFormatter: ({ value }) => `${prop('type', value)} ${prop('number', value)}`,
    renderCell: ({ value }) => (
      <SourceDestinationLink
        name={prop('number', value)}
        id={prop('id', value)}
        guid={prop('guid', value)}
        type={prop('type', value)}
      />
    )
  },
  {
    width: 200,
    headerName: 'Location',
    field: 'location'
  },
  {
    width: 250,
    headerName: 'Warehouse',
    field: 'warehouse'
  },
  {
    width: 150,
    headerName: 'Available',
    field: 'stockAvailable'
  },
  {
    width: 150,
    headerName: 'On hand',
    field: 'stockOnHand'
  },
  {
    width: 150,
    headerName: 'Created date',
    field: 'createdAt',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  }
]

const getRowsFromResults = map(item => {
  const id = prop('id', item)
  const variantName = path(['variant', 'name'], item)
  const variantSku = path(['variant', 'sku'], item)
  const unitGuid = prop('unitGuid', item)
  const variant = { unitGuid, name: variantName, sku: variantSku }
  const image = path(['variant', 'image'], item)
  const quantityChange = prop('quantity', item)
  const price = prop('price', item)

  const currency = prop('currency', item)
  const supplyRate = { currency, price }
  const destination = prop('destination', item)
  const guid = prop('guid', item)
  const warehouse = path(['location', 'warehouse', 'name'], item)
  const location = path(['location', 'locationId'], item)
  const createdAt = prop('createdAt', item)
  const stockAvailable = prop('stockAvailable', item)
  const stockOnHand = prop('stockOnHand', item)

  return {
    id,
    guid,
    image,
    variant,
    quantityChange,
    supplyRate,
    destination,
    location,
    warehouse,
    stockAvailable,
    stockOnHand,
    createdAt
  }
})

function InventoryLogTable ({ list, filter, ordering, onListRefetch }) {
  const [tableRef, setTableRef] = useState(null)
  const results = prop('results', list)
  const count = prop('count', list)
  const isLoading = prop('isLoading', list)
  const columns = prop('filteredColumns', ordering)

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={NAV.INVENTORY_LOG}
          parentTitle={NAV.STOCK}
        />
      </Header>
      <Content>
        <TableHeader
          filter={filter}
          filterOpen={filter.handleOpen}
          orderingOpen={ordering.handleOpen}
          ordering={ordering}
        >
          <MoreListButton tableRef={tableRef} />
        </TableHeader>
        <Table
          columns={columns}
          getRowsFromResults={getRowsFromResults}
          results={results}
          isLoading={isLoading}
          checkboxSelection={true}
          count={count}
          ordering={ordering}
          pinnedColumns={['image', 'variant']}
          onColumnResize={ordering.handleResize}
          onRefetch={onListRefetch}
          setTableRef={setTableRef}
        />
      </Content>
    </Container>
  )
}

InventoryLogTable.propTypes = {
  list: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  onListRefetch: PropTypes.object.isRequired
}

export default InventoryLogTable
