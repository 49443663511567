import React from 'react'
import Link from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'

const BreadcrumbLink = ({ href, title }) => {
  return (
    <Link
      color="inherit"
      to={href}
      component={RouterLink}
      key={title}
    >
      {title}
    </Link>
  )
}

BreadcrumbLink.propTypes = {
  href: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

export default BreadcrumbLink
