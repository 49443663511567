import React from 'react'
import PropTypes from 'prop-types'

import SelectField from '~/components/Fields/SelectField'

import {
  DELIVERED,
  IN_TRANSIT,
  PRE_TRANSIT,
  RETURNED,
  SHIPMENT_STATUS_LABEL,
  UNSHIPPED,
  CANCELLED,
  FAILED
} from '../Statuses/ShipmentStatus'

export const OPTIONS = [
  { value: UNSHIPPED, name: SHIPMENT_STATUS_LABEL[UNSHIPPED] },
  { value: PRE_TRANSIT, name: SHIPMENT_STATUS_LABEL[PRE_TRANSIT] },
  { value: IN_TRANSIT, name: SHIPMENT_STATUS_LABEL[IN_TRANSIT] },
  { value: DELIVERED, name: SHIPMENT_STATUS_LABEL[DELIVERED] },
  { value: RETURNED, name: SHIPMENT_STATUS_LABEL[RETURNED] },
  { value: CANCELLED, name: SHIPMENT_STATUS_LABEL[CANCELLED] },
  { value: FAILED, name: SHIPMENT_STATUS_LABEL[FAILED] },
]

function ShipmentStatusField (props) {
  return (
    <SelectField
      options={OPTIONS}
      {...props}
    />
  )
}

ShipmentStatusField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
}

ShipmentStatusField.defaultProps = {
  name: 'shipmentStatus',
  label: 'Shipment status'
}

export default ShipmentStatusField
