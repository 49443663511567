import React from 'react'
import { Filter } from 'storfox-filter'
import Grid from '@mui/material/Grid'
import { prop } from 'ramda'

import { escapeAtob, unescapeBtoa } from '~/utils'
import WarehouseMultiSelectField from '~/components/Fields/WarehouseMultiSelectField'
import Accordion from '~/components/Accordion'
import { ActiveStatusField } from '~/components/ActiveStatus'
import CompanySearchField from '~/components/Fields/CompanySearchField'
import * as API from '~/constants/api'

import InvitationStatusField from '../Fields/InvitationStatusField'
import { FILTER_NAME as name } from '../../../constants/Contract'

export const toParams = values => {
  const status = prop('status', values)
  const invitationStatus = prop('invitationStatus', values)
  const company = unescapeBtoa(prop('company', values))
  const warehouses = unescapeBtoa(prop('warehouses', values))
  const minTotal = prop('minTotal', values)
  const maxTotal = prop('maxTotal', values)

  return {
    status,
    invitationStatus,
    company,
    warehouses,
    minTotal,
    maxTotal
  }
}

export const toValues = params => {
  const status = prop('status', params)
  const invitationStatus = prop('invitationStatus', params)
  const company = escapeAtob(prop('company', params))
  const warehouses = escapeAtob(prop('warehouses', params))
  const minTotal = prop('minTotal', params)
  const maxTotal = prop('maxTotal', params)

  return {
    status,
    invitationStatus,
    company,
    warehouses,
    minTotal,
    maxTotal
  }
}

export const transformer = { toParams, toValues }

export const contractFilterOptions = {
  name,
  transformer
}

function ContractFilterForm (props) {
  return (
    <Filter {...props}>
      <Accordion title="General" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <ActiveStatusField />
          </Grid>
          <Grid item={true} xs={12}>
            <InvitationStatusField />
          </Grid>
          <Grid item={true} xs={12}>
            <CompanySearchField api={API.ORDER_COMPANIES} />
          </Grid>
          <Grid item={true} xs={12}>
            <WarehouseMultiSelectField />
          </Grid>
        </Grid>
      </Accordion>
    </Filter>
  )
}

export default ContractFilterForm
