import { defaultTo, filter, find, isEmpty, map, path, pathOr, pipe, prop, propEq, toPairs } from 'ramda'

import { getTimeFromString } from '~/utils'
import { ACTIVE } from '~/components/Fields/StatusField'

import {
  FULFILLMENT_TYPES,
  GENERAL,
  PACKING_TYPES,
  RECEIVING_TYPES,
  STORAGE_TYPES,
  TABS_WITH_KEYS
} from '../../constants/BillingProfile'

export const getInitialValues = () => ({
  status: ACTIVE,
  storageBilling: {
    type: STORAGE_TYPES.FIXED
  },
  fulfillmentBilling: {
    type: FULFILLMENT_TYPES.FIXED
  },
  packingBilling: {
    type: PACKING_TYPES.PER_CONTAINER_TYPE
  },
  receivingBilling: {
    type: RECEIVING_TYPES.PER_CONTAINER_WEIGHT
  }
})

const getStorageBillingTiers = map(item => {
  const containerGuid = path(['containerType', 'guid'], item)
  const warehouseGuid = path(['warehouse', 'guid'], item)
  const areaGuid = path(['area', 'guid'], item)
  const zoneGuid = path(['zone', 'guid'], item)

  const containerType = containerGuid && { guid: containerGuid }
  const warehouse = warehouseGuid && { guid: warehouseGuid }
  const area = areaGuid && { guid: areaGuid }
  const zone = zoneGuid && { guid: zoneGuid }

  return {
    ...item,
    warehouse,
    area,
    zone,
    containerType
  }
})

const getStorageBilling = values => {
  const tiers = prop('tiers', values)
  const newTiers = tiers ? getStorageBillingTiers(tiers) : null

  return tiers ? { ...values, tiers: newTiers } : null
}

const getFulfillmentBilling = values => {
  const type = prop('type', values)
  const isFixed = type === FULFILLMENT_TYPES.FIXED

  const ranges = !isFixed ? prop('ranges', values) : null

  return !type || (isFixed && !prop('price', values)) ? null : { ...values, ranges }
}

const getArrayOrNull = pipe(
  defaultTo([]),
  filter(item => !isEmpty(item)),
  data => data.length ? data : null
)

const getPackingBilling = values => {
  const type = prop('type', values)
  const perContainerTypes = getArrayOrNull(prop('perContainerTypes', values))
  const isPerContainerType = type === PACKING_TYPES.PER_CONTAINER_TYPE

  const isEmpty = !type || (isPerContainerType && !perContainerTypes)

  return isEmpty ? null : { ...values, perContainerTypes }
}

const getReceivingBilling = values => {
  const type = prop('type', values)
  const conditions = getArrayOrNull(prop('conditions', values))
  const isPerContainerWeightType = type === RECEIVING_TYPES.PER_CONTAINER_WEIGHT

  const isEmpty = (
    !type ||
    (isPerContainerWeightType && !conditions)
  )

  return isEmpty ? null : { ...values, conditions }
}

export const BillingProfileCreateSerializer = values => ({
  ...values,
  period: 'daily',
  storageBilling: getStorageBilling(prop('storageBilling', values)),
  fulfillmentBilling: getFulfillmentBilling(prop('fulfillmentBilling', values)),
  packingBilling: getPackingBilling(prop('packingBilling', values)),
  receivingBilling: getReceivingBilling(prop('receivingBilling', values))
})

const getTiers = pipe(
  pathOr([], ['storageBilling', 'tiers']),
  map(item => {
    const fromTime = getTimeFromString(prop('fromTime', item))
    const toTime = getTimeFromString(prop('toTime', item))
    const boundaryTime = getTimeFromString(prop('boundaryTime', item))
    const perDuration = Number(prop('perDuration', item)) / 60

    return { ...item, perDuration, fromTime, toTime, boundaryTime }
  })
)

export const BillingProfileDetailSerializer = values => {
  const tiers = getTiers(values)
  const storageBilling = prop('storageBilling', values)

  return { ...values, storageBilling: { ...storageBilling, tiers } }
}

export const BillingProfileErrorSerializer = error => {
  const errorKey = pipe(
    toPairs,
    prop(0),
    ([key]) => key
  )(error)

  return pipe(
    find(propEq('key', errorKey)),
    prop('tab'),
    defaultTo(GENERAL)
  )(TABS_WITH_KEYS)
}
