import React, { useRef, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Typography from '@mui/material/Typography'
import { propOr } from 'ramda'
import PropTypes from 'prop-types'
import ReactToPrint from 'react-to-print'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import Content from './Content'
import BarcodeItems from './BarcodeItems'

import DialogTitle from '../DialogTitle/DialogTitle'
import Button from '../Buttons/Button'
import DiscardButton from '../Buttons/DiscardButton'

function BarcodeGeneratorDialogForm ({ open, onClose, onGenerate }) {
  const { t } = useTranslation()
  const theme = useTheme()

  const componentRef = useRef(null)
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const [list, setList] = useState([])

  const onGenerateList = quantity =>
    onGenerate(quantity)
      .then(res => {
        const unitNumbers = propOr([], 'unitNumbers', res)
        setList(unitNumbers)
      })

  const handleClose = () => {
    setList([])
    onClose()
  }

  return (
    <Dialog
      open={open}
      fullWidth={true}
      fullScreen={fullScreen}
      maxWidth="sm"
    >
      <DialogTitle>
        <Typography
          component="div"
          variant="h6"
        >
          {t('Barcode units')}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{
        overflow: 'visible',
        minHeight: '50vh'
      }}>
        <Content onGenerateList={onGenerateList} list={list} />
      </DialogContent>
      <DialogActions>
        <DiscardButton
          onClick={handleClose}
          fullWidth={true}
        >
          Discard
        </DiscardButton>

        <ReactToPrint
          trigger={() => (
            <Button
              disabled={!list.length}

              variant="contained"
              fullWidth={true}
              type="submit"
            >
              Print
            </Button>
          )}
          content={() => componentRef.current}
        />
      </DialogActions>
      <Box display="none">
        <BarcodeItems
          items={list}
          ref={componentRef}
        />
      </Box>
    </Dialog>
  )
}

BarcodeGeneratorDialogForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onGenerate: PropTypes.func.isRequired,
}

export default BarcodeGeneratorDialogForm
