import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import DialogContent from '@mui/material/DialogContent'
import Dialog from '@mui/material/Dialog'
import { prop, propOr } from 'ramda'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import Checkbox from '@mui/material/Checkbox/Checkbox'
import DialogActions from '@mui/material/DialogActions/DialogActions'
import InfiniteScroll from 'react-infinite-scroll-component'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'
import { useField } from 'react-final-form'
import TableHead from '@mui/material/TableHead'
import { useTranslation } from 'react-i18next'

import DialogTitle from '~/components/DialogTitle'
import TextOverflow from '~/components/TextOverflow'
import Avatar from '~/components/Avatar'
import { Button, DiscardButton } from '~/components/Buttons'
import CurrencyMoney from '~/components/Money/CurrencyMoney'
import InfinityScrollSearch from '~/components/InfinityScrollSearch'

import VariantTableSkeletonList from './VariantTableSkeletonList'

const TableRowStyled = styled(TableRow)({
  cursor: 'pointer',
  '&:hover': {
    background: '#0000000A'
  }
})

const DialogContentStyled = styled(DialogContent)({
  padding: '0',
  position: 'relative',
  height: 'calc(100vh / 2)'
})

function AdjustmentVariantAddDialog ({ open, onClose, onAddVariants, params, adjustmentVariant }) {
  const { t } = useTranslation()

  const [selected, setSelected] = useState([])

  const results = propOr([], 'results', adjustmentVariant)
  const count = prop('count', adjustmentVariant)

  const searchField = useField('search')
  const searchFieldValue = searchField.input.value

  const onEscape = useCallback(event => {
    if (event.code === 'Escape') {
      onClose()
    }
  }, [onClose])

  const handleCheck = (checked, variant) => {
    if (checked) {
      setSelected(prevSelected => prevSelected.filter(select => prop('id', select) !== prop('id', variant)))
    } else {
      setSelected(prevSelected => [...prevSelected, variant])
    }
  }

  return (
    <Dialog open={open} maxWidth="md" fullWidth={true} onKeyDown={onEscape}>
      <DialogTitle>
        {`${t('Browse Variants')} - ${t('Selected')} (${selected.length})`}
        <Box sx={{ padding: '24px 0' }}>
          <InfinityScrollSearch
            onEffect={adjustmentVariant.reset}
            getList={adjustmentVariant.getList}
            params={params}
          />
        </Box>
      </DialogTitle>
      <DialogContentStyled id="adjustmentVariantTable">
        <InfiniteScroll
          dataLength={results.length}
          hasMore={count > results.length}
          loader=""
          next={() => adjustmentVariant.getList({ search: searchFieldValue, ...params })}
          scrollableTarget="adjustmentVariantTable"
        >
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('Select')}</TableCell>
                  <TableCell>{t('Image')}</TableCell>
                  <TableCell>{t('Variant')}</TableCell>
                  <TableCell>{t('Price')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {results.map((variant, index) => {
                  const id = prop('id', variant)
                  const src = prop('defaultImage', variant)
                  const checked = selected.find(select => prop('id', select) === id)
                  return (
                    <TableRowStyled onClick={() => handleCheck(checked, variant)} key={id}>
                      <TableCell width={70} data-cy={`variant-${index}`}>
                        <Checkbox checked={Boolean(checked)} color="secondary" />
                      </TableCell>
                      <TableCell width={70}>
                        <Avatar
                          alt={prop('name', variant)}
                          src={src}
                          variant="rounded"
                        />
                      </TableCell>
                      <TableCell width={380}>
                        <TextOverflow
                          lines={1}
                          selfTooltip={true}
                        >
                          {prop('name', variant)}
                        </TextOverflow>
                      </TableCell>
                      <TableCell width={290}>
                        <CurrencyMoney value={prop('supplyPrice', variant)} />
                      </TableCell>
                    </TableRowStyled>
                  )
                })}
                {adjustmentVariant.isLoading && (
                  <VariantTableSkeletonList />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </InfiniteScroll>
      </DialogContentStyled>
      <DialogActions>
        <DiscardButton
          onClick={onClose}
          fullWidth={true}
        >
          Discard
        </DiscardButton>
        <Button
          data-cy="add"
          variant="contained"
          fullWidth={true}
          onClick={() => onAddVariants(selected)}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}

AdjustmentVariantAddDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAddVariants: PropTypes.func.isRequired,
  params: PropTypes.object,
  adjustmentVariant: PropTypes.object.isRequired
}

export default AdjustmentVariantAddDialog
