import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useAllSearchParams, useRoute } from 'storfox-route-hooks'
import { pathOr, prop } from 'ramda'
import { useRef, useState } from 'react'
import { useDeepCompareEffect } from 'storfox-api-hooks'

const DEFAULT_PAGE_SIZE = 10

function usePagination ({ prefix = '', defaultPageSize = DEFAULT_PAGE_SIZE, autoPageSize = true } = {}) {
  const { limit } = useAllSearchParams()
  const autoPageSizeRef = useRef(defaultPageSize)
  const [prevPageSize, setPrevPageSize] = useState(defaultPageSize)

  const pageKey = `${prefix}page`
  const pageSizeKey = `${prefix}limit`
  const selectKey = 'select'

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const params = useAllSearchParams()
  const { changeParams, replaceParams } = useRoute()

  const pageStr = prop(pageKey, params)
  const pageSizeStr = prop(pageSizeKey, params)

  const page = pageStr ? Number(pageStr) : 1
  const pageSize = pageSizeStr ? Number(pageSizeStr) : defaultPageSize

  useDeepCompareEffect(() => {
    if (!limit && autoPageSize) {
      onInitialPageSizeChange(prevPageSize)
    }
  }, [limit])

  const onDesktopPageChange = (event, number) => {
    changeParams({ [pageKey]: number, [selectKey]: null })
  }

  const onMobilePageChange = (event, number) => {
    changeParams({ [pageKey]: number, [selectKey]: null })
  }

  const onInitialPageSizeChange = size => {
    autoPageSizeRef.current = size

    setPrevPageSize(size)
    replaceParams({ [pageSizeKey]: size, [selectKey]: null })
  }

  const onPageSizeChange = (event, value) => {
    const size = pathOr(value, ['target', 'value'], event)

    setPrevPageSize(size)
    replaceParams({ [pageKey]: 1, [pageSizeKey]: size, [selectKey]: null })
  }

  const onPageSizeReset = () => {
    setPrevPageSize(autoPageSizeRef.current)
    replaceParams({ [pageKey]: 1, [pageSizeKey]: autoPageSizeRef.current, [selectKey]: null })
  }

  return {
    page,
    pageSize,
    isDesktop,
    onInitialPageSizeChange,
    onPageSizeChange,
    onPageSizeReset,
    onMobilePageChange,
    onDesktopPageChange
  }
}

export default usePagination
