import { path, pick, prop, propOr } from 'ramda'
import { DEFAULT_SEARCH_PARAMS } from 'storfox-route-hooks'

import { orderingToSnackCase, escapeAtob } from '~/utils'

import { SCAN_TYPE_FULL_BARCODE } from '../components/LotSheetScan/ScanTypeRadioButtons'

export function LotSheetsFilterSerializer (params) {
  const defaultParams = pick(DEFAULT_SEARCH_PARAMS, params)
  const companyId = escapeAtob(prop('company', params))
  const allocationMode = prop('allocationMode', params)
  const status = prop('status', params)

  const createdAtStart = prop('createdAtStart', params)
  const createdAtEnd = prop('createdAtEnd', params)

  return orderingToSnackCase({
    ...defaultParams,
    companyId,
    allocationMode,
    createdAtStart,
    createdAtEnd,
    status
  })
}

export function lotSheetsSaveSerializer (values) {
  const items = propOr([], 'items', values)
  const newItems = items.map((item) => {
    const containers = propOr([], 'containers', item)
    const guid = prop('guid', item)
    const inputBarcode = prop('inputBarcode', item)
    const newContainers = containers.filter((container) => prop('quantity', container))
    return {
      containers: newContainers,
      guid,
      inputBarcode
    }
  })
  const itemWithContainers = newItems.filter((item) => {
    const containers = propOr([], 'containers', item)
    return containers.length
  })
  return { items: itemWithContainers }
}

export function scanInitialValuesSerializer (results, lineItemGuid) {
  const lotItem = results.find(item => prop('guid', item) === lineItemGuid)
  const items = propOr([], 'items', lotItem)

  const newItems = items.map((item) => {
    const containers = propOr([], 'containers', item)
    const newContainers = containers.map((container) => {
      const quantity = propOr(0, 'quantity', container)
      return {
        ...container,
        quantity: 0,
        scannedQuantity: quantity
      }
    })
    return {
      ...item,
      containers: newContainers
    }
  }, [])

  return {
    ...lotItem,
    items: newItems,
    barcode: '',
    mainBarcode: prop('barcode', lotItem),
    scanType: SCAN_TYPE_FULL_BARCODE
  }
}

export function lotShipmentCreateSerializer (values, guid) {
  const bucketGuid = path(['bucket', 'guid'], values)
  const containers = propOr([], 'containers', values)
  return {
    bucket: { guid: bucketGuid },
    containers: containers,
    lot: { guid }
  }
}
