import React from 'react'
import { path, prop } from 'ramda'
import { MultiSelectSearchField } from 'storfox-form-fields'

import * as API from '~//constants/api'

const getOptionValue = value => {
  if (value) {
    const id = prop('id', value)
    const guid = prop('guid', value)
    const name = prop('name', value)
    const companyName = path(['company', 'name'], value)

    return { id, guid, name, companyName }
  }

  return null
}

function CompanyMultiSelectField (props) {
  return (
    <MultiSelectSearchField
      api={API.COMPANY_LIST}
      label="Company"
      name="company"
      getOptionValue={getOptionValue}
      {...props}
    />
  )
}

export default CompanyMultiSelectField
