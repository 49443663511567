import React from 'react'
import { prop } from 'ramda'

import WarehouseMultiSelectField from './WarehouseMultiSelectField'

const getOptionValue = value => {
  const guid = prop('guid', value)
  const name = prop('name', value)
  const label = prop('label', value)

  return { name, guid, label }
}

function WarehouseMinimalSearchField ({ ...props }) {
  return (
    <WarehouseMultiSelectField
      name="warehouses"
      getOptionValue={getOptionValue}
      {...props}
    />
  )
}

export default WarehouseMinimalSearchField
