import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { map, pathOr, prop } from 'ramda'
import { generatePath } from 'react-router-dom'

import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import Money from '~/components/Money'
import TableDateFormat from '~/components/TableDateFormat'
import TableNotes from '~/components/TableNotes'
import { AllocationStatus, SaleOrderStatus, ShipmentStatus } from '~/components/Statuses'
import { Table, TableHeader } from '~/components/Table'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import { MoreListButton, NewButton } from '~/components/Buttons'
import FulfillmentStatus from '~/components/Statuses/FulfillmentStatus'
import TableLink from '~/components/Link'
import { SUPPLIER_RETURN_TABS } from '~/constants/tabs'

import RefundStatus from '../RefundStatus'

const getPath = guid => {
  return generatePath(ROUTES.SUPPLIER_RETURN_DETAIL_PATH, { guid, tab: SUPPLIER_RETURN_TABS.GENERAL })
}

export const RETURN_COLUMNS = [
  {
    width: 125,
    headerName: 'ID',
    field: 'number',
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        {value}
      </TableLink>
    )
  },
  {
    width: 150,
    headerName: 'Line items',
    field: 'lineItems',
    headerAlign: 'center',
    align: 'center'
  },
  {
    width: 100,
    headerName: 'Qty',
    field: 'quantity',
    headerAlign: 'center',
    align: 'center'
  },
  {
    width: 150,
    headerName: 'Supplier',
    field: 'supplier'
  },
  {
    width: 150,
    headerName: 'Total',
    field: 'totalPrice',
    renderCell: ({ value }) => (
      <Money value={value} />
    )
  },
  {
    width: 150,
    headerName: 'Status',
    field: 'status',
    renderCell: ({ value }) => (
      <SaleOrderStatus value={value} />
    )
  },
  {
    width: 150,
    headerName: 'Allocation status',
    field: 'allocationStatus',
    renderCell: ({ value }) => (
      <AllocationStatus value={value} />
    )
  },
  {
    width: 150,
    headerName: 'Fulfillment status',
    field: 'fulfillmentStatus',
    renderCell: ({ value }) => (
      <FulfillmentStatus value={value} />
    )
  },
  {
    width: 150,
    headerName: 'Shipping status',
    field: 'shippingStatus',
    renderCell: ({ value }) => (
      <ShipmentStatus value={value} />
    )
  },
  {
    width: 150,
    headerName: 'Refund status',
    field: 'refundStatus',
    renderCell: ({ value }) => (
      <RefundStatus value={value} />
    )
  },
  {
    width: 200,
    headerName: 'Created date',
    field: 'createdAt',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  },
  {
    width: 200,
    headerName: 'Last updated',
    field: 'updatedAt',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  },
  {
    width: 125,
    headerName: 'Notes',
    field: 'notes',
    renderCell: ({ value }) => (
      <TableNotes>{value}</TableNotes>
    )
  }
]

const getRowsFromResults = map(item => {
  const id = prop('id', item)
  const guid = prop('guid', item)
  const number = prop('number', item)
  const supplier = pathOr('', ['supplier', 'companyName'], item)
  const lineItems = prop('lineItemsQty', item)
  const quantity = prop('itemsQty', item)
  const totalPrice = prop('totalPrice', item)
  const status = prop('status', item)
  const allocationStatus = prop('allocationStatus', item)
  const shippingStatus = prop('shipmentStatus', item)
  const fulfillmentStatus = prop('fulfillmentStatus', item)
  const refundStatus = prop('refundStatus', item)
  const createdAt = prop('createdAt', item)
  const updatedAt = prop('updatedAt', item)
  const notes = prop('notes', item)
  const link = getPath(guid)

  return {
    id,
    number,
    guid,
    lineItems,
    quantity,
    totalPrice,
    supplier,
    status,
    allocationStatus,
    shippingStatus,
    fulfillmentStatus,
    refundStatus,
    createdAt,
    updatedAt,
    notes,
    link
  }
})

function ReturnTable ({ list, filter, ordering, onListRefetch }) {
  const [tableRef, setTableRef] = useState(null)
  const isLoading = prop('isLoading', list)
  const results = prop('results', list)
  const count = prop('count', list)
  const columns = prop('filteredColumns', ordering)

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={NAV.SUPPLIER_RETURNS}
          parentTitle={NAV.PURCHASES}
          rightButton={(
            <NewButton path={ROUTES.SUPPLIER_RETURN_CREATE_PATH} />
          )}
        />
      </Header>

      <Content>
        <TableHeader
          filter={filter}
          filterOpen={filter.handleOpen}
          orderingOpen={ordering.handleOpen}
          ordering={ordering}
        >
          <MoreListButton tableRef={tableRef} />
        </TableHeader>
        <Table
          isLoading={isLoading}
          columns={columns}
          getRowsFromResults={getRowsFromResults}
          results={results}
          count={count}
          pinnedColumns={['number']}
          checkboxSelection={true}
          primaryKey="guid"
          ordering={ordering}
          onRefetch={onListRefetch}
          setTableRef={setTableRef}
        />
      </Content>
    </Container>
  )
}

ReturnTable.propTypes = {
  list: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  onListRefetch: PropTypes.func.isRequired
}

export default ReturnTable
