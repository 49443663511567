import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import DialogContent from '@mui/material/DialogContent'
import { Grid, styled } from '@mui/material'
import DialogActions from '@mui/material/DialogActions'
import { useTranslation } from 'react-i18next'
import Paper from '@mui/material/Paper'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'

import List from './List'

import DialogTitle from '../DialogTitle/DialogTitle'
import Button from '../Buttons/Button'
import MobileFullWidthDialog from '../MobileFullWidthDialog'

const WrapperBoxStyled = styled(Box)({
  padding: 1,
  minHeight: '60vh',
  height: '100%'
})

const BoxStyled = styled(Box)({
  overflowX: 'scroll',
  width: '100%',
  height: '100%'
})

function HistoryChangesDialog ({ oldChange, newChange, open, onClose }) {
  const { t } = useTranslation()
  return (
    <MobileFullWidthDialog open={open} onClose={onClose}>
      <DialogTitle>
        <Grid container={true}>
          <Grid item={true} xs={12}>
            <Typography variant="h4">
              {t('Changes')}
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12} md={6}>
            <WrapperBoxStyled>
              <BoxStyled>
                <Paper variant="outlined">
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ borderBottom: 'none' }}>{t('Changed from')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <List rowsObject={oldChange} />
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </BoxStyled>
            </WrapperBoxStyled>
          </Grid>
          <Grid item={true} xs={12} md={6}>
            <WrapperBoxStyled>
              <BoxStyled>
                <Paper variant="outlined">
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ borderBottom: 'none' }}>{t('Changed to')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <List rowsObject={newChange} />
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </BoxStyled>
            </WrapperBoxStyled>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          variant="outlined"

          onClick={onClose}
        >
          Close
        </Button>
      </DialogActions>
    </MobileFullWidthDialog>
  )
}

HistoryChangesDialog.propTypes = {
  oldChange: PropTypes.object,
  newChange: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default HistoryChangesDialog
