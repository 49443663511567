import React from 'react'
import Grid from '@mui/material/Grid'
import InputAdornment from '@mui/material/InputAdornment'
import { isProd } from 'storfox-api-hooks'
import FacebookIcon from '@mui/icons-material/Facebook'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card/Card'
import CardContent from '@mui/material/CardContent'
import { useTranslation } from 'react-i18next'

import StatusField from '~/components/Fields/StatusField'
import TagsField from '~/components/Fields/TagsField'
import TextField from '~/components/Fields/TextField'
import GoogleIcon from '~/components/Icons/GoogleIcon'
import Divider from '~/components/Divider'
import { CardHeader } from '~/components/Cards'

import TemplateField from './Fields/TemplateField'

function Storefront () {
  const { t } = useTranslation()
  return (
    <Grid container={true}>
      <Grid item={true} xs={12}>
        <Box mb={3}>
          <Card>
            <CardHeader title={t('Integration')} />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12} lg={6}>
                  <TextField
                    data-cy="name"
                    name="name"
                    label="Name"
                  />
                </Grid>

                <Grid item={true} xs={12} lg={6}>
                  <StatusField />
                </Grid>

                <Grid item={true} xs={12} lg={6}>
                  <TextField
                    data-cy="storeDomain"
                    name="credential.storeDomain"
                    label="Store domain"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          {isProd ? '.storfox.shop' : '.dev.storfox.shop'}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item={true} xs={12} lg={6}>
                  <TemplateField name="credential.template" />
                </Grid>

                <Grid item={true} xs={12}>
                  <TagsField
                    name="credential.customDomains"
                    data-cy="customDomains"
                    label="Custom domains"
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <TextField
                    data-cy="description"
                    name="description"
                    label="Description"
                    multiline={true}
                    rows="4"
                  />
                </Grid>

                <Grid item={true} lg={6} xs={12}>
                  <TextField
                    data-cy="credential.googleClientId"
                    name="credential.googleClientId"
                    label="Google Client ID"
                    InputProps={{
                      startAdornment: <GoogleIcon color="action" />
                    }}
                  />
                </Grid>

                <Grid item={true} lg={6} xs={12}>
                  <TextField
                    data-cy="credential.facebookAppId"
                    name="credential.facebookAppId"
                    label="Facebook App ID"
                    InputProps={{
                      startAdornment: <FacebookIcon color="action" />
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </Grid>
  )
}

export default Storefront
