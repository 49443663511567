import React from 'react'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material'

const RootStyled = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.dark,
  textAlign: 'center',
  height: 44,
  display: 'flex',
  justifyContent: 'flex-start',
  paddingLeft: theme.spacing(2),
  alignItems: 'center'
}))

function NavParentTitle ({ children }) {
  return (
    <RootStyled disableSticky={true}>
      <Typography
        variant="body1"
        color="white"
      >
        {children}
      </Typography>
    </RootStyled>
  )
}

NavParentTitle.propTypes = {
  children: PropTypes.string.isRequired
}

export default NavParentTitle
