import React from 'react'
import { map, range } from 'ramda'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Skeleton from '@mui/material/Skeleton'
import PropTypes from 'prop-types'

function ConditionSkeleton ({ quantity }) {
  return map(
    index => (
      <TableRow key={index}>
        <TableCell sx={{ minWidth: 250 }}>
          <Skeleton variant="rectangular" />
        </TableCell>
        <TableCell sx={{ minWidth: 250 }}>
          <Skeleton variant="rectangular" />
        </TableCell>
        <TableCell sx={{ minWidth: 250 }}>
          <Skeleton variant="rectangular" />
        </TableCell>
        <TableCell sx={{ minWidth: 250 }}>
          <Skeleton variant="rectangular" />
        </TableCell>
      </TableRow>
    ),
    range(0, quantity)
  )
}

ConditionSkeleton.defaultProps = {
  quantity: 4
}

ConditionSkeleton.propTypes = {
  quantity: PropTypes.number
}

export default ConditionSkeleton
