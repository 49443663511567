import React, { useCallback, useEffect } from 'react'
import { useField } from 'react-final-form'
import { prop, propOr } from 'ramda'
import { ConverterField } from 'storfox-form-fields'
import InputAdornment from '@mui/material/InputAdornment'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { useCompany } from '../Profile'

const ratios = {
  'cm': 1,
  'ft': 0.0328084,
  'in': 0.393701,
}

function VolumeField ({ label, ...rest }) {
  const { t } = useTranslation()
  const { company } = useCompany()
  const sizeUnit = propOr('kg', 'sizeUnit', company)
  const ratio = prop(sizeUnit, ratios)

  const volumeField = useField('vol')
  const onChange = useCallback(volumeField.input.onChange, [])
  const lengthField = useField('length')
  const length = lengthField.input.value
  const widthField = useField('width')
  const width = widthField.input.value
  const heightField = useField('height')
  const height = heightField.input.value

  useEffect(() => {
    if (length !== '' && width !== '' && height !== '') {
      onChange(length * width * height)
    }
  }, [length, width, height, onChange])

  return (
    <ConverterField
      data-cy="vol"
      name="vol"
      label={t(label)}
      ratio={ratio}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">{sizeUnit}</InputAdornment>
        )
      }}
      {...rest}
    />
  )
}

VolumeField.propTypes = {
  label: PropTypes.string
}

VolumeField.defaultProps = {
  label: 'Volume'
}

export default VolumeField
