import { equals, pick, prop } from 'ramda'

import { TYPES } from '../../constants/StorageReport'

const PICK_PARAMS = {
  [TYPES.WAREHOUSE]: ['clientGuid', 'type'],
  [TYPES.LOCATION]: ['clientGuid', 'warehouseGuid', 'type'],
  [TYPES.UNIT]: ['clientGuid', 'locationGuid', 'type']
}

export function StorageDetailSerializer (values) {
  const type = prop('type', values)
  if (equals(type, TYPES.COMPANY)) {
    return values
  }

  return pick(PICK_PARAMS[type], values)
}
