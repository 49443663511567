import React, { useCallback } from 'react'
import Grid from '@mui/material/Grid'
import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { FieldArray } from 'react-final-form-arrays'
import { length, path, pathEq, pathOr, pipe, prop, propOr, reduce } from 'ramda'
import IconButton from '@mui/material/IconButton'
import Delete from '@mui/icons-material/Delete'
import { useField } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import AddIcon from '@mui/icons-material/Add'
import Box from '@mui/material/Box'
import { useValueChanged } from 'storfox-tools'
import PropTypes from 'prop-types'

import VerticalAlignment from '~/components/VerticalAlignment'
import TextOverflow from '~/components/TextOverflow'
import Subtext from '~/components/Subtext'
import Money from '~/components/Money'
import Avatar from '~/components/Avatar/Avatar'
import EmptyRow from '~/components/EmptyRow'
import TextField from '~/components/Fields/TextField'
import { Button } from '~/components/Buttons'
import useDialog from '~/hooks/useDialog'
import { emptyArray } from '~/constants/empty'

import UnitsField from './TransferDialogForm/UnitsField'
import TransferVariantAddDialog from './TransferVariantAddDialog/TransferVariantAddDialog'

import { CANCELLED, COMPLETED } from '../constants'

const getQuantity = pipe(
  propOr([], 'barcodeItems'),
  reduce((acc, { quantity }) => Number(acc + quantity), 0)
)

function LineItems ({ transferOrderVariant }) {
  const { t } = useTranslation()
  const { handleClose, handleOpen, open } = useDialog()

  const lineItemsField = useField('lineItems')
  const lineItems = lineItemsField.input.value || emptyArray
  const lineItemsChange = lineItemsField.input.onChange
  const lineItemsFieldError = pathOr('', ['meta', 'submitError', 0], lineItemsField)
  const lineItemsError =
    typeof lineItemsFieldError === 'string' ? lineItemsFieldError : JSON.stringify(lineItemsFieldError)

  const statusField = useField('status')
  const isReceiverField = useField('isReceiver')
  const isReceiver = isReceiverField.input.value

  const status = statusField.input.value

  const fromWarehouseField = useField('fromWarehouse')
  const fromWarehouseValue = fromWarehouseField.input.value
  const fromWarehouseId = prop('id', fromWarehouseValue)

  const searchField = useField('search')
  const searchFieldChange = searchField.input.onChange

  const transferSearchParams = {
    availableWarehouseId: fromWarehouseId
  }

  const handleAddVariants = useCallback(variants => {
    const newVariants = variants.map(variant => {
      const id = path(['variant', 'id'], variant)
      const name = prop('name', variant)
      const price = prop('retailPrice', variant)
      const available = prop('available', variant)
      const sku = prop('sku', variant)
      const barcode = prop('barcode', variant)
      const total = prop('allocated', variant)
      const defaultImage = prop('defaultImage', variant)

      const newVariant = { id, name, defaultImage, sku, barcode }

      const isDuplicate = lineItems.find(item => path(['variant', 'id'], item) === id)

      if (!isDuplicate) {
        return { variant: newVariant, available, price, total }
      } else return null
    }).filter(variant => Boolean(variant))

    const otherLineItems = lineItems.filter((lineItem) => {
      return variants.find(pathEq(['variant', 'id'], path(['variant', 'id'], lineItem)))
    })

    lineItemsChange([...otherLineItems, ...newVariants])
    searchFieldChange('')
    handleClose()
  }, [searchFieldChange, handleClose, lineItems, lineItemsChange])

  const handleOnSearch = useCallback(value => {
    searchFieldChange(value)
    handleOpen()
  }, [handleOpen, searchFieldChange])

  const handleCloseSearch = useCallback(() => {
    searchFieldChange('')
    handleClose()
  }, [handleClose, searchFieldChange])

  useValueChanged(() => {
    return lineItemsChange([])
  }, fromWarehouseId)

  return (
    <FieldArray name="lineItems">
      {({ fields }) => {
        const info = !length(propOr([], 'value', fields))

        const disabledLineItems = (
          !fromWarehouseId ||
          status === CANCELLED ||
          status === COMPLETED
        )

        return (
          <>
            <Box sx={{ padding: '16px' }}>
              <Grid container={true} spacing={3}>
                <Grid item={true} lg={10} xs={12}>
                  <TextField
                    data-cy="variant"
                    name="variant"
                    label="Search"
                    onChange={event => !disabledLineItems && handleOnSearch(event.target.value)}
                    disabled={disabledLineItems}
                    error={lineItemsError}
                  />
                </Grid>
                <Grid item={true} lg={2} xs={12}>
                  <Button
                    fullWidth={true}
                    variant="outlined"
                    startIcon={<AddIcon />}
                    onClick={handleOpen}
                    type="button"
                    data-cy="browse"
                    disabled={disabledLineItems}
                  >
                    Browse
                  </Button>
                </Grid>
              </Grid>
              {open && (
                <TransferVariantAddDialog
                  open={open}
                  onClose={handleCloseSearch}
                  onAddVariants={handleAddVariants}
                  params={transferSearchParams}
                  transferOrderVariant={transferOrderVariant}
                />
              )}
            </Box>
            <TableContainer>
              <Table size="small" sx={{ minWidth: '700px' }}>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2} sx={{ minWidth: '330px' }}>{t('Product')}</TableCell>
                    <TableCell>{t('Qty')}</TableCell>
                    <TableCell>{t('Retail price')}</TableCell>
                    {!isReceiver && <TableCell>{t('Available')}</TableCell>}
                    {!isReceiver && <TableCell>{t('Stock after')}</TableCell>}
                    <TableCell>{t('Total')}</TableCell>
                    <TableCell colSpan={2}>{t('Add units')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fields.map((name, index) => {
                    const value = prop('value', fields)
                    const item = prop(index, value)
                    const variant = prop('variant', item)

                    const imagePath = prop('defaultImage', variant)
                    const variantName = prop('name', variant)
                    const sku = prop('sku', variant)
                    const quantity = getQuantity(item)
                    const retailPrice = prop('price', item)
                    const available = prop('available', item)
                    const stockAfter = available - quantity
                    const totalPrice = quantity * retailPrice

                    return (
                      <TableRow key={index}>
                        <TableCell sx={{ width: '50px' }}>
                          <Avatar
                            src={imagePath}
                            alt={variantName}
                          />
                        </TableCell>
                        <TableCell>
                          <VerticalAlignment
                            primary={(
                              <TextOverflow selfTooltip={true}>
                                {variantName}
                              </TextOverflow>
                            )}
                            secondary={(
                              <Subtext lines={1}>{sku}</Subtext>
                            )}
                          />
                        </TableCell>
                        <TableCell>{quantity}</TableCell>
                        <TableCell>
                          <Money value={retailPrice} />
                        </TableCell>
                        {!isReceiver && <TableCell>{available}</TableCell>}
                        {!isReceiver && <TableCell>{stockAfter}</TableCell>}
                        <TableCell>
                          <Money value={totalPrice} />
                        </TableCell>
                        <TableCell>
                          <UnitsField
                            name={`${name}.barcodeItems`}
                            dataCy={`${name}.barcodeItems`}
                            item={item}
                            warehouseId={fromWarehouseId}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <IconButton onClick={() => fields.remove(index)} size="large">
                            <Delete />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  })
                    .reverse()}
                  {info && <EmptyRow colSpan={9} />}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )
      }}
    </FieldArray>
  )
}

LineItems.propTypes = {
  transferOrderVariant: PropTypes.object.isRequired
}

export default LineItems
