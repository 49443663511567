import React from 'react'
import { prop } from 'ramda'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import { styled } from '@mui/material'

import CircularProgressWithLabel from '../CircularProgressWithLabel/CircularProgressWithLabel'

const CardContentStyled = styled(CardContent)({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
})

const CircularProgressWithLabelStyled = styled(CircularProgressWithLabel)({
  position: 'absolute',
  zIndex: 4,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
})

function UploadedFilePlaceholder ({ file, children }) {
  const start = prop('start', file)
  const finish = prop('finish', file)
  const progress = prop('progress', file)
  const loading = start && !finish

  return (
    <Card sx={{ height: '200px' }}>
      <CardContentStyled>
        {loading ? (
          <Box height="100%">
            <CircularProgressWithLabelStyled value={progress} />
          </Box>
        ) : (
          <>{children}</>
        )}
      </CardContentStyled>
    </Card>
  )
}

UploadedFilePlaceholder.propTypes = {
  children: PropTypes.any.isRequired,
  file: PropTypes.object.isRequired
}

export default UploadedFilePlaceholder
