import { defaultTo, map, pick, pipe, prop } from 'ramda'
import { DEFAULT_SEARCH_PARAMS } from 'storfox-route-hooks'

import { orderingToSnackCase, escapeAtob } from '~/utils'

import { USER_CREATE_FIELDS } from '../components/UserCreate'

const getRoles = pipe(
  prop('roles'),
  defaultTo([]),
  map(pick(['id']))
)

const getWarehouses = pipe(
  prop('warehouses'),
  defaultTo([]),
  map(pick(['id']))
)

export function UserCreateSerializer (formValues) {
  const roles = getRoles(formValues)
  const warehouses = getWarehouses(formValues)

  const values = { ...formValues, roles, warehouses }

  return pick(USER_CREATE_FIELDS, values)
}

export const UserFilterSerializer = params => {
  const defaultParams = pick(DEFAULT_SEARCH_PARAMS, params)
  const status = prop('status', params)
  const warehouseGuids = escapeAtob(prop('warehouses', params))
  const roleIds = escapeAtob(prop('roles', params))
  const updatedAtStart = prop('updatedAtStart', params)
  const updatedAtEnd = prop('updatedAtEnd', params)

  return orderingToSnackCase({
    ...defaultParams,
    status,
    warehouseGuids,
    roleIds,
    updatedAtStart,
    updatedAtEnd
  })
}
