import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import SupplierCreateContainer from './containers/SupplierCreateContainer'
import SupplierListContainer from './containers/SupplierListContainer'
import SupplierDetailContainer from './containers/SupplierDetailContainer'
import SupplierUpdateContainer from './containers/SupplierUpdateContainer'

export default () => {
  return [
    {
      layout: AuthLayout,
      path: ROUTES.SUPPLIER_CREATE_PATH,
      component: SupplierCreateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.SUPPLIER_DETAIL_PATH,
      component: SupplierDetailContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.SUPPLIER_UPDATE_PATH,
      component: SupplierUpdateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.SUPPLIER_LIST_PATH,
      component: SupplierListContainer
    },
  ]
}
