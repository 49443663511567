import React, { useState } from 'react'
import { generatePath } from 'react-router-dom'
import { map, prop } from 'ramda'
import PropTypes from 'prop-types'

import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import { MoreListButton, NewButton } from '~/components/Buttons'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import { Table, TableHeader } from '~/components/Table'
import TableLink from '~/components/Link'
import TextOverflow from '~/components/TextOverflow'

const getDetailPath = guid => {
  return generatePath(ROUTES.CURRENCY_RATE_UPDATE_PATH, { guid })
}

export const CURRENCY_RATE_COLUMNS = [
  {
    width: 250,
    headerName: 'Currency',
    field: 'currency',
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        <TextOverflow selfTooltip={true}>{value}</TextOverflow>
      </TableLink>
    )
  },
  {
    width: 250,
    headerName: 'Rate',
    field: 'rate'
  }
]

const getRowsFromResults = map(item => {
  const guid = prop('guid', item)
  const currency = prop('currency', item)
  const rate = prop('rate', item)
  const link = getDetailPath(guid)

  return {
    guid,
    currency,
    rate,
    link
  }
})

function CurrencyRateTable ({ list, ordering, onListRefetch }) {
  const [tableRef, setTableRef] = useState(null)
  const results = prop('results', list)
  const isLoading = prop('isLoading', list)
  const count = prop('count', list)
  const columns = prop('filteredColumns', ordering)

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle="Currency rate"
          parentTitle={NAV.CONFIGURATION}
          rightButton={(
            <NewButton path={ROUTES.CURRENCY_RATE_CREATE_PATH} />
          )}
        />
      </Header>

      <Content>
        <TableHeader
          orderingOpen={ordering.handleOpen}
          ordering={ordering}
        >
          <MoreListButton tableRef={tableRef} />
        </TableHeader>
        <Table
          isLoading={isLoading}
          columns={columns}
          getRowsFromResults={getRowsFromResults}
          results={results}
          count={count}
          primaryKey="guid"
          pinnedColumns={['currency']}
          checkboxSelection={true}
          ordering={ordering}
          onRefetch={onListRefetch}
          setTableRef={setTableRef}
        />
      </Content>
    </Container>
  )
}

CurrencyRateTable.propTypes = {
  list: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  onListRefetch: PropTypes.object.isRequired
}

export default CurrencyRateTable
