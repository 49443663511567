import React from 'react'
import ImageUpload from 'storfox-image-upload'
import PropTypes from 'prop-types'

import { useImageUpload } from '~/components/ImageUpload'

function MultiImageUploadField ({ name, accept }) {
  const { upload } = useImageUpload()

  const onUpload = (data, onProgress) =>
    upload(data, onProgress)
      .then(response => {
        const imagePath = response['ids'][0]
        return { imagePath, isDefault: false }
      })

  return (
    <ImageUpload onUpload={onUpload} name={name} accept={accept} />
  )
}

MultiImageUploadField.defaultProps = {
  name: 'images'
}

MultiImageUploadField.propTypes = {
  name: PropTypes.string,
  accept: PropTypes.string
}

export default MultiImageUploadField
