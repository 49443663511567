import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos'
import { styled } from '@mui/material'

const IconBoxStyled = styled(Box)(({ theme }) => ({
  width: 44,
  height: 44,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.primary.light
}))

function MenuItemContent ({ icon, children, hasArrow, ...props }) {
  return (
    <>
      <Box display="flex" alignItems="center" {...props}>
        <IconBoxStyled>
          {icon}
        </IconBoxStyled>
        <Box ml={2}>
          <Typography variant="subtitle1">{children}</Typography>
        </Box>
      </Box>
      {hasArrow && <ArrowForwardIos fontSize="small" sx={{ color: 'primary.light' }} />}
    </>
  )
}

MenuItemContent.propTypes = {
  children: PropTypes.any.isRequired,
  icon: PropTypes.any.isRequired,
  hasArrow: PropTypes.bool
}

export default MenuItemContent
