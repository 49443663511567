import React from 'react'
import { useTranslation } from 'react-i18next'
import MuiCardHeader from '@mui/material/CardHeader'
import PropTypes from 'prop-types'
import { is } from 'ramda'

function CardHeader ({ title, required, ...props }) {
  const { t } = useTranslation()

  const translatedTitle = is(String, title) ? t(title) : title
  const requiredTitle = is(String, title) ? required ? translatedTitle + ' *' : translatedTitle : title

  return (
    <MuiCardHeader title={requiredTitle} {...props} />
  )
}

CardHeader.propTypes = {
  title: PropTypes.any.isRequired,
  required: PropTypes.bool
}

export default CardHeader
