import React from 'react'
import * as colors from '@mui/material/colors'
import PropTypes from 'prop-types'
import { styled } from '@mui/material'

export const SUCCESS = 'success'
export const WARNING = 'warning'
export const ERROR = 'error'

const RootStyled = styled('span', {
  shouldForwardProp: propName => propName !== 'color'
})(({ theme, color }) => ({
  height: 20,
  display: 'inline-flex',
  padding: theme.spacing(1, 1),
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: '500',
  whiteSpace: 'nowrap',
  ...(color === SUCCESS && ({
    color: colors.green[500],
    backgroundColor: `${colors.green[500]}0F`,
  })),
  ...(color === WARNING && ({
    color: colors.orange[500],
    backgroundColor: `${colors.orange[500]}0F`
  })),
  ...(color === ERROR && ({
    color: colors.red[500],
    backgroundColor: `${colors.red[500]}0F`,
  }))
}))

function Chip ({ color, className, label, ...props }) {
  return (
    <RootStyled color={color} className={className} {...props}>
      {label}
    </RootStyled>
  )
}

Chip.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(['success', 'warning', 'error']),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
}

export default Chip
