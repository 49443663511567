import React from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'

import { CardHeader } from '~/components/Cards'
import CurrencyField from '~/components/Fields/CurrencyField'
import LanguageField from '~/components/Fields/LanguageField'
import StatusField from '~/components/Fields/StatusField'
import TextField from '~/components/Fields/TextField'
import TimeZoneField from '~/components/Fields/TimeZoneField'
import Image from '~/components/CompanyImage'

function CompanyUpdateFormGeneral () {
  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12} lg={8} >
        <Box mb={3}>
          <Card>
            <CardHeader title="Information" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <TextField
                    data-cy="name"
                    name="name"
                    label="Company Name"
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <TextField
                    data-cy="email"
                    name="email"
                    label="Email"
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <TextField
                    data-cy="phone"
                    name="phone"
                    label="Phone Number"
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <TextField
                    data-cy="address"
                    name="address"
                    label="Address"
                    multiline={true}
                    minRows={3}
                  />
                </Grid>

                <Grid item={true} xs={6}>
                  <TextField
                    data-cy="postalCode"
                    name="postalCode"
                    label="Zipcode"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Box mb={3}>
          <Card>
            <CardHeader title="Primary account holder" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={6}>
                  <TextField
                    data-cy="firstName"
                    name="firstName"
                    label="First name"
                  />
                </Grid>

                <Grid item={true} xs={6}>
                  <TextField
                    data-cy="lastName"
                    name="lastName"
                    label="Last name"
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <TextField
                    data-cy="userEmail"
                    name="userEmail"
                    label="Email"
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <TextField
                    data-cy="userPhone"
                    name="userPhone"
                    label="Phone number"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>

      <Grid item={true} xs={12} lg={4}>
        <Box mb={3}>
          <Card>
            <Image title="Add Logo" />
          </Card>
        </Box>
        <Box mb={3}>
          <Card>
            <CardHeader title="Settings" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <StatusField />
                </Grid>

                <Grid item={true} xs={12}>
                  <LanguageField />
                </Grid>

                <Grid item={true} xs={12}>
                  <TimeZoneField countryName="country" />
                </Grid>

                <Grid item={true} xs={12}>
                  <CurrencyField />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </Grid>

  )
}

export default CompanyUpdateFormGeneral
