import { pipe, replace } from 'ramda'

import capitalize from './capitalize'

const snakeCaseToReadable = pipe(
  replace(/_/g, ' '),
  capitalize
)

export default snakeCaseToReadable
