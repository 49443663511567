import PropTypes from 'prop-types'
import React from 'react'
import Card from '@mui/material/Card'
import Portal from '@mui/material/Portal'

function PreviewCard ({ element, children, className, positionX, positionY, ...props }) {
  return (
    <Portal container={element}>
      <Card
        className={className}
        sx={{
          position: 'absolute',
          width: 320,
          zIndex: 50
        }}
        style={{ left: positionX, top: positionY }}
        {...props}
      >
        {children}
      </Card>
    </Portal>
  )
}

PreviewCard.propTypes = {
  children: PropTypes.any.isRequired,
  positionX: PropTypes.number.isRequired,
  positionY: PropTypes.number.isRequired,
  className: PropTypes.string,
  element: PropTypes.any,
}

export default PreviewCard
