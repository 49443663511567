import React from 'react'
import { SelectField } from 'storfox-query-builder'

import { BANK_TRANSFER, CASH, CREDIT_CARD, PAYMENT_TYPES, TRADE_FINANCE } from '~/constants/paymentTypes'

const PAYMENT_OPTIONS = [
  { value: CASH, name: PAYMENT_TYPES[CASH] },
  { value: CREDIT_CARD, name: PAYMENT_TYPES[CREDIT_CARD] },
  { value: BANK_TRANSFER, name: PAYMENT_TYPES[BANK_TRANSFER] },
  { value: TRADE_FINANCE, name: PAYMENT_TYPES[TRADE_FINANCE] },
]

function PaymentMethodField (props) {
  return (
    <SelectField
      controlSx={{
        width: 'auto',
        minWidth: 160
      }}
      options={PAYMENT_OPTIONS}
      {...props}
    />
  )
}

export default PaymentMethodField
