import React from 'react'
import PropTypes from 'prop-types'

import { DiscardButton, SaveButton } from '~/components/Buttons'
import { withForm } from '~/components/Form'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import * as NAV from '~/constants/nav-titles'

import Title from '../Title'
import LocationGeneral from '../LocationGeneral'

export const LOCATION_CREATE_FIELDS = [
  'warehouse',
  'area',
  'zone',
  'aisle',
  'bay',
  'level',
  'bin',
  'length',
  'height',
  'width',
  'maxLoad',
  'volume',
  'locationAlias',
  'locationType',
  'allowedOperations',
  'status',
  'id',
  'x',
  'y',
  'z',
  'pickingOrder'
]

function LocationCreateForm (props) {
  const {
    pageTitleLoading,
    isLoading,
    form
  } = props

  return (
    <form onSubmit={form.handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={<Title titleValues={form.values} />}
            pageTitleLoading={pageTitleLoading}
            parentTitle={NAV.LOCATIONS}
            rightButton={(
              <>
                <DiscardButton disabled={isLoading} />
                <SaveButton disabled={isLoading} />
              </>
            )}
          />
        </Header>

        <Content>
          <LocationGeneral />
        </Content>
      </Container>
    </form>
  )
}

LocationCreateForm.propTypes = {
  pageTitleLoading: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
}

export default withForm(LocationCreateForm)
