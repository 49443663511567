import React from 'react'
import { Ordering } from 'storfox-filter'

import { LOCATION_COLUMNS as tableColumns } from './LocationTable'

import { LOCATION_FILTER_NAME as name } from '../../../constants'

export const locationOrderingOptions = {
  name,
  tableColumns
}

function LocationOrderingForm (props) {
  return (
    <Ordering {...props} />
  )
}

export default LocationOrderingForm
