import React from 'react'
import { useFilter, useOrdering } from 'storfox-filter'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import useMessages from '~/hooks/useMessages'
import ErrorLink from '~/components/ErrorLink'
import { useTableSelects } from '~/components/TableValues/hooks'

import {
  ReturnFilterForm,
  returnFilterOptions,
  ReturnOrderingForm,
  returnOrderingOptions,
  ReturnTable
} from '../components/ReturnList'
import { useReturnCancel, useReturnList } from '../hooks'

function ReturnListContainer () {
  const returnList = useReturnList()
  const returnCancel = useReturnCancel()
  const snackbar = useSnackbar()
  const messages = useMessages()
  const { selects, resetTableSelects } = useTableSelects()

  const filter = useFilter(returnFilterOptions)
  const ordering = useOrdering(returnOrderingOptions)
  const handleReturnsCancel = () =>
    returnCancel.cancel({ guids : selects })
      .then(() => resetTableSelects())
      .then(() => snackbar({ message: messages.CANCEL_SUCCESS }))
      .then(() => returnList.getList())
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
        Promise.reject(error)
      })

  const breadcrumbs = { title: NAV.SALE_RETURNS }

  return (
    <DashboardLayout
      title={NAV.SALE_RETURNS}
      activeNav={NAV.SALE_RETURNS}
      breadcrumbs={breadcrumbs}
    >
      <ReturnFilterForm {...filter} />
      <ReturnOrderingForm {...ordering} />

      <ReturnTable
        list={returnList}
        filter={filter}
        ordering={ordering}
        onListRefetch={returnList.getListByParams}
        onReturnsCancel={handleReturnsCancel}
        bulkActionsLoading={returnCancel.isLoading}
      />
    </DashboardLayout>
  )
}

export default ReturnListContainer
