import React from 'react'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'

function ChannelSkeleton () {
  return (
    <>
      <Grid item={true} sm={12} lg={6}>
        <Skeleton width="100%" height="78px" />
      </Grid>
      <Grid item={true} sm={12} lg={6}>
        <Skeleton width="100%" height="78px" />
      </Grid>
    </>
  )
}

export default ChannelSkeleton
