import React from 'react'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import { path, prop } from 'ramda'
import TableContainer from '@mui/material/TableContainer'
import PropTypes from 'prop-types'

import Capitalize from '~/components/Text/Capitalize'
import SkeletonList from '~/components/Skeleton'

function LocationsUpdateTable ({ locations, isLoading }) {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Location</TableCell>
            <TableCell>Location type</TableCell>
            <TableCell>Area</TableCell>
            <TableCell>Zone</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading
            ? <SkeletonList rowCount={5} columnCount={5} />
            : locations.map(location => {
              const locationId = prop('locationId', location)
              const area = path(['area', 'name'], location)
              const zone = path(['zone', 'name'], location)
              const locationType = <Capitalize>{prop('locationType', location)}</Capitalize>

              return (
                <TableRow key={locationId}>
                  <TableCell>{locationId}</TableCell>
                  <TableCell>{locationType}</TableCell>
                  <TableCell>{area}</TableCell>
                  <TableCell>{zone}</TableCell>
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

LocationsUpdateTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  locations: PropTypes.array
}
export default LocationsUpdateTable
