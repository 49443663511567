import React from 'react'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import PropTypes from 'prop-types'
import { prop } from 'ramda'
import { useTranslation } from 'react-i18next'

import VerticalAlignment from '~/components/VerticalAlignment'
import SkeletonList from '~/components/Skeleton'
import Avatar from '~/components/Avatar/Avatar'
import TextOverflow from '~/components/TextOverflow'
import Subtext from '~/components/Subtext'

function ContainerContent ({ contents, isLoading }) {
  const { t } = useTranslation()

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell width="50">{t('Image')}</TableCell>
            <TableCell>{t('Variant')}</TableCell>
            <TableCell>{t('Quantity')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading
            ? <SkeletonList columnCount={3} rowCount={5} />
            : (
              <>
                {contents.map(item => {
                  const id = prop('id', item)
                  const defaultImage = prop('defaultImage', item)
                  const name = prop('name', item)
                  const sku = prop('sku', item)
                  const quantity = prop('quantity', item)

                  return (
                    <TableRow key={id}>
                      <TableCell>
                        <Avatar src={defaultImage} alt={name} />
                      </TableCell>
                      <TableCell data-cy="variant">
                        <VerticalAlignment
                          primary={
                            <TextOverflow
                              selfTooltip={true}
                              lines={1}
                            >
                              {name}
                            </TextOverflow>
                          }
                          secondary={
                            <Subtext
                              textOverflow={true}
                              lines={1}>
                              {sku}
                            </Subtext>
                          }
                        />
                      </TableCell>
                      <TableCell data-cy="quantity">{quantity}</TableCell>
                    </TableRow>
                  )
                })}
                {!contents.length && (
                  <TableRow>
                    <TableCell colSpan={3}>Container is Empty</TableCell>
                  </TableRow>
                )}
              </>
            )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

ContainerContent.defaultProps = {
  contents: []
}

ContainerContent.propTypes = {
  contents: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default ContainerContent
