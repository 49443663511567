import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import AccountUpdateContainer from './containers/AccountUpdateContainer'

export default () => {
  return [
    {
      layout: AuthLayout,
      path: ROUTES.ACCOUNT_UPDATE_PATH,
      component: AccountUpdateContainer
    }
  ]
}
