import React from 'react'
import { SearchCreateField as StorfoxSearchCreateField } from 'storfox-form-fields'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

function SearchCreateField ({ label, addText, onAddClick, ...props }) {
  const { t } = useTranslation()

  return (
    <StorfoxSearchCreateField
      label={t(label)}
      addText={t(addText)}
      onAddClick={onAddClick}
      {...props}
    />
  )
}

SearchCreateField.propTypes = {
  addText: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onAddClick: PropTypes.func.isRequired
}

export default SearchCreateField
