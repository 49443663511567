import React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useSnackbar } from 'storfox-snackbar'
import { path, propOr } from 'ramda'

import { HtmlLayout } from '~/components/Layouts'
import useMessages from '~/hooks/useMessages'
import * as ROUTES from '~/constants/routes'
import { RECEIVE_TABS } from '~/constants/tabs'

import { ReceivePurchaseInitSerializer, ReceivingPurchaseSerializer } from '../serializers/purchaseSerializer'
import ReceivePurchaseBarcoding from '../components/ReceiveBarcoding/ReceivePurchaseBarcoding'
import {
  useReceiveBarcodeConditionList,
  useReceivePurchaseDetail,
  useReceivePurchaseScanReceive,
  useVariantValidate
} from '../hooks'

function ReceivePurchaseBarcodingContainer () {
  const { guid } = useParams()
  const snackbar = useSnackbar()
  const messages = useMessages()
  const navigate = useNavigate()
  const receiveConditionList = useReceiveBarcodeConditionList()
  const receivePurchaseDetail = useReceivePurchaseDetail(guid)
  const receivePurchaseScanReceive = useReceivePurchaseScanReceive(guid)
  const variantDetail = useVariantValidate(guid)

  const conditions = receiveConditionList.results
  const number = propOr('Receive PO', 'number', receivePurchaseDetail.detail)

  const handleSubmit = values =>
    receivePurchaseScanReceive.receive(ReceivingPurchaseSerializer(values, guid))
      .then((res) => {
        snackbar({ message: messages.RECEIVE_SUCCESS })
        receivePurchaseDetail.getDetail().then((response) => {
          const received = path(['result', 'received'], response)
          const ordered = path(['result', 'ordered'], response)
          if (received === ordered) {
            const url = generatePath(ROUTES.RECEIVE_PURCHASE_ORDER_DETAIL_PATH, { guid, tab: RECEIVE_TABS.GENERAL })
            navigate(url)
          }
        })
        return res
      })

  const handleBarcodeValidate = barcode => variantDetail.validate(barcode)

  const isLoading = receivePurchaseDetail.isLoading || receivePurchaseScanReceive.isLoading

  return (
    <HtmlLayout title={number}>
      <ReceivePurchaseBarcoding
        initialValues={ReceivePurchaseInitSerializer(receivePurchaseDetail.detail)}
        conditions={conditions}
        pageTitle={number}
        conditionListLoading={receiveConditionList.isLoading}
        isLoading={isLoading}
        detailLoading={isLoading}
        variantDetail={variantDetail}
        onBarcodeValidate={handleBarcodeValidate}
        onSubmit={handleSubmit}
      />
    </HtmlLayout>
  )
}

export default ReceivePurchaseBarcodingContainer
