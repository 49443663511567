import React from 'react'
import PropTypes from 'prop-types'

import Status from '~/components/Status'

import { PUTAWAY_STATUS, PUTAWAY_STATUS_LABEL } from '../../constants'

const COLOR = {
  [PUTAWAY_STATUS.NEW]: 'success',
  [PUTAWAY_STATUS.PENDING]: 'warning',
  [PUTAWAY_STATUS.IN_PROGRESS]: 'warning',
  [PUTAWAY_STATUS.COMPLETED]: 'success',
}

function PutawayStatus ({ value }) {
  return (
    <Status
      label={PUTAWAY_STATUS_LABEL[value]}
      color={COLOR[value]}
    />
  )
}

PutawayStatus.propTypes = {
  value: PropTypes.oneOf([
    '',
    PUTAWAY_STATUS.NEW,
    PUTAWAY_STATUS.PENDING,
    PUTAWAY_STATUS.IN_PROGRESS,
    PUTAWAY_STATUS.COMPLETED
  ]).isRequired
}

export default PutawayStatus
