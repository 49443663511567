import React from 'react'
import { Filter } from 'storfox-filter'
import Grid from '@mui/material/Grid'
import { DateTimeField } from 'storfox-form-fields'
import { map, pick, pipe, prop, propOr } from 'ramda'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

import { escapeAtob, unescapeBtoa } from '~/utils'
import Accordion from '~/components/Accordion'
import WarehouseMultiSelectField from '~/components/Fields/WarehouseMultiSelectField'
import UserMultiSelectField from '~/components/Fields/UserMultiSelectField'
import { getDateTimeGMTDifference, getDateTimeGMTUser } from '~/utils/date'

import ActionTypeField from './ActionTypeField'
import DeviceSelectField from './DeviceSelectField'

import { FILTER_NAME as name } from '../../../constants/TeamMetrics'

const getWarehouseGuids = pipe(
  propOr([], 'warehouses'),
  map(pick(['guid', 'name']))
)

const getUserGuids = pipe(
  propOr([], 'users'),
  map(pick(['guid', 'firstName', 'lastName']))
)

export const toParams = values => {
  const warehouseList = propOr([], 'warehouses', values)
  const warehouses = warehouseList.length ? unescapeBtoa(getWarehouseGuids(values)) : null
  const userList = propOr([], 'users', values)
  const users = userList.length ? unescapeBtoa(getUserGuids(values)) : null
  const device = prop('device', values)
  const action = prop('action', values)

  const createdAtStart = getDateTimeGMTDifference(prop('createdAtStart', values))
  const createdAtEnd = getDateTimeGMTDifference(prop('createdAtEnd', values))
  const startedAtStart = getDateTimeGMTDifference(prop('startedAtStart', values))
  const startedAtEnd = getDateTimeGMTDifference(prop('startedAtEnd', values))
  const completedAtStart = getDateTimeGMTDifference(prop('completedAtStart', values))
  const completedAtEnd = getDateTimeGMTDifference(prop('completedAtEnd', values))
  const assignedAtStart = getDateTimeGMTDifference(prop('assignedAtStart', values))
  const assignedAtEnd = getDateTimeGMTDifference(prop('assignedAtEnd', values))
  return {
    warehouses,
    users,
    device,
    action,
    startedAtStart,
    startedAtEnd,
    completedAtStart,
    completedAtEnd,
    createdAtStart,
    createdAtEnd,
    assignedAtStart,
    assignedAtEnd
  }
}

export const toValues = params => {
  const warehouses = escapeAtob(prop('warehouses', params))
  const users = escapeAtob(prop('users', params))
  const device = prop('device', params)
  const action = prop('action', params)

  const createdAtStart = getDateTimeGMTUser(prop('createdAtStart', params))
  const createdAtEnd = getDateTimeGMTUser(prop('createdAtEnd', params))
  const startedAtStart = getDateTimeGMTUser(prop('startedAtStart', params))
  const startedAtEnd = getDateTimeGMTUser(prop('startedAtEnd', params))
  const completedAtStart = getDateTimeGMTUser(prop('completedAtStart', params))
  const completedAtEnd = getDateTimeGMTUser(prop('completedAtEnd', params))
  const assignedAtStart = getDateTimeGMTUser(prop('assignedAtStart', params))
  const assignedAtEnd = getDateTimeGMTUser(prop('assignedAtEnd', params))
  return {
    warehouses,
    users,
    device,
    action,
    startedAtStart,
    startedAtEnd,
    completedAtStart,
    completedAtEnd,
    createdAtStart,
    createdAtEnd,
    assignedAtStart,
    assignedAtEnd
  }
}
export const transformer = { toParams, toValues }

export const teamMetricsFilterOptions = {
  name,
  transformer
}

function TeamMetricsFilterForm (props) {
  const { t } = useTranslation()

  return (
    <Filter {...props}>
      <Accordion title="General" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <WarehouseMultiSelectField />
          </Grid>
          <Grid item={true} xs={12}>
            <ActionTypeField />
          </Grid>
          <Grid item={true} xs={12}>
            <DeviceSelectField />
          </Grid>
          <Grid item={true} xs={12}>
            <UserMultiSelectField name="users" label="Users" />
          </Grid>

        </Grid>
      </Accordion>
      <Accordion title="Date">
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <Typography variant="body2" gutterBottom={true}>
              {t('Started')}:
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <DateTimeField name="startedAtStart" label="Start" />
              </Grid>
              <Grid item={true} xs={12}>
                <DateTimeField name="startedAtEnd" label="End" />
              </Grid>
            </Grid>
          </Grid>

          <Grid item={true} xs={12}>
            <Typography variant="body2" gutterBottom={true}>
              {t('Completed')}:
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <DateTimeField name="completedAtStart" label="Start" />
              </Grid>
              <Grid item={true} xs={12}>
                <DateTimeField name="completedAtEnd" label="End" />
              </Grid>
            </Grid>
          </Grid>

          <Grid item={true} xs={12}>
            <Typography variant="body2" gutterBottom={true}>
              {t('Created')}:
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <DateTimeField name="createdAtStart" label="Start" />
              </Grid>
              <Grid item={true} xs={12}>
                <DateTimeField name="createdAtEnd" label="End" />
              </Grid>
            </Grid>
          </Grid>

          <Grid item={true} xs={12}>
            <Typography variant="body2" gutterBottom={true}>
              {t('Assigned')}:
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <DateTimeField name="assignedAtStart" label="Start" />
              </Grid>
              <Grid item={true} xs={12}>
                <DateTimeField name="assignedAtEnd" label="End" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Accordion>
    </Filter>
  )
}

export default TeamMetricsFilterForm
