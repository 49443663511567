import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'
import { DialogActions } from '@mui/material'
import { prop } from 'ramda'

import DialogTitle from '~/components/DialogTitle'
import { Button, DiscardButton } from '~/components/Buttons'
import { withForm } from '~/components/Form'

import WarehouseSearchField from './WarehouseSearchField'

function SaleOrderWarehouseSetDialog (props) {
  const { open, onClose, form } = props
  const { t } = useTranslation()

  const { handleSubmit, values } = form

  const warehouse = prop('warehouse', values)

  return (
    <Dialog
      open={open}
      fullWidth={true}
      onClose={onClose}
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle component="div">
          <Typography
            component="h2"
            variant="h6"
          >
            {t('Set Warehouse')}
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ minHeight: '30vh', paddingTop: '10px !important' }}>
          <WarehouseSearchField
            name="warehouse"
            data-cy="warehouse"
            label="Warehouse"
          />
        </DialogContent>

        <DialogActions>
          <Grid container={true} spacing={1}>
            <Grid item={true} xs={6}>
              <DiscardButton
                onClick={onClose}
                fullWidth={true}
              >
                Discard
              </DiscardButton>
            </Grid>

            <Grid item={true} xs={6}>
              <Button
                disabled={!warehouse}
                fullWidth={true}
                variant="outlined"
                type="submit"
                data-cy="done"
              >
                Done
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  )
}

SaleOrderWarehouseSetDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  form: PropTypes.object
}

export default withForm(SaleOrderWarehouseSetDialog)
