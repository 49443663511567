import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { useDeepCompareEffect } from 'storfox-api-hooks'
import { find, map, is, propEq } from 'ramda'

import FieldWrapper from './FieldWrapper'

const getOptionsValues = (options, values) => map(value => {
  if (is(String, value)) {
    return find(propEq('value', value), options)
  }

  return value
})(values)

function MultiSelectField ({ disabled, options, input, meta, ...props }) {
  const [open, setOpen] = React.useState(false)
  const inputValue = input.value

  useDeepCompareEffect(() => {
    input.onChange(getOptionsValues(options, inputValue))
  }, [inputValue])

  return (
    <Autocomplete
      open={open}
      disabled={disabled}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      multiple={true}
      disableCloseOnSelect={true}
      value={input.value || []}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={option => option.name}
      options={options}
      onChange={(event, value) => input.onChange(value)}
      renderInput={defaultProps => (
        <TextField
          {...defaultProps}
          {...props}
          error={meta.invalid}
          helperText={meta.submitError || meta.error}
          InputProps={{
            ...defaultProps.InputProps
          }}
        />
      )}
    />
  )
}

MultiSelectField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  size: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
}

MultiSelectField.defaultProps = {
  params: { limit: 1000 },
  size: 'small',
  fullWidth: true,
  variant: 'outlined',
  disabled: false,
}

export default FieldWrapper(MultiSelectField)
