import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import { path, prop } from 'ramda'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box'

import { CardHeader } from '~/components/Cards'
import TextLabelLoader from '~/components/TextLabelLoader'

import ReturnStatuses from './ReturnStatuses'
import InvoiceSummary from './InvoiceSummary'
import CustomerNotes from './CustomerNotes'
import LineItems from './LineItems'

import ReturnPurchaseSupplierDetail from '../ReturnDetail/Supplier'

function ReturnDetailGeneral ({ detail, isLoading }) {
  const lineItems = prop('lineItems', detail)
  const customerNotes = prop('notes', detail)
  const billingAddress = prop('billingAddress', detail)
  const deliveryAddress = prop('deliveryAddress', detail)
  const warehouse = path(['warehouse', 'name'], detail)
  const supplier = prop('supplier', detail)

  return (
    <Grid container={true} spacing={3} alignItems="stretch">
      <Grid item={true} xs={12} lg={8}>
        <Box mb={3}>
          <ReturnStatuses detail={detail} isLoading={isLoading} />
        </Box>
        <Box>
          <Card>
            <CardHeader title="Supplier" />
            <Divider />
            <CardContent>
              <ReturnPurchaseSupplierDetail
                supplier={supplier}
                billingAddress={billingAddress}
                deliveryAddress={deliveryAddress}
              />
            </CardContent>
          </Card>
        </Box>
      </Grid>
      <Grid item={true} xs={12} lg={4}>
        <Box height="100%">
          <Card style={{ height: '100%' }}>
            <CardHeader title="Details" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Warehouse">
                    {warehouse}
                  </TextLabelLoader>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>
      <Grid item={true} xs={12}>
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={12}>
            <Card>
              <CardHeader title="Line items" />
              <Divider />
              <LineItems isLoading={isLoading} lineItems={lineItems} />
            </Card>
          </Grid>

          <Grid item={true} xs={12} lg={6}>
            <Card>
              <CardHeader title="Invoice Summary" />
              <Divider />
              <InvoiceSummary lineItems={lineItems} />
            </Card>
          </Grid>
          <Grid item={true} xs={12} lg={6}>
            <Card style={{ height: '100%' }}>
              <CardHeader title="Supplier notes" />
              <Divider />
              <CardContent style={{ height: '80%' }}>
                <CustomerNotes notes={customerNotes} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

ReturnDetailGeneral.propTypes = {
  detail: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default ReturnDetailGeneral
