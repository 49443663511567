import React, { useCallback } from 'react'
import { useField, useFormState } from 'react-final-form'
import Grid from '@mui/material/Grid'
import PropTypes from 'prop-types'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box'
import { prop } from 'ramda'

import Container, { Content, Header } from '~/components/Container'
import PageTitle from '~/components/PageTitle'
import { DiscardButton, SaveButton } from '~/components/Buttons'
import { withForm } from '~/components/Form'
import { BackgroundSingleFileUpload } from '~/components/BackgroundFileUpload'
import { TemplateCard } from '~/components/FileUpload'
import * as API from '~/constants/api'
import * as NAV from '~/constants/nav-titles'
import ProgressCard from '~/components/ProgressCard'
import { useGoogleEvent } from '~/components/GoogleAnalytics/GoogleAnalytics'
import { CardHeader } from '~/components/Cards'

import UploadedFile from './UploadedFile'
import UploadOptionField from './UploadOptionField'
import CompanySearchField from './CompanySearchField'

function LotSheetsImport ({ title, form, isLoading, progressCard, onSubscribe, onFileRemove }) {
  const { handleSubmit } = form
  const { sendEvent } = useGoogleEvent()
  const formState = useFormState()

  const allocationModeField = useField('allocationMode')
  const allocationModeFieldValue = allocationModeField.input.value

  const companyField = useField('company')
  const companyFieldValue = companyField.input.value
  const companyId = prop('id', companyFieldValue)

  const setAdditionalData = useCallback((data) => {
    data.append('company_id', companyId)
    data.append('allocation_mode', allocationModeFieldValue)
    return data
  }, [companyId, allocationModeFieldValue])

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={title}
            parentTitle={NAV.CROSS_DOCKS}
            rightButton={(
              <>
                <DiscardButton disabled={isLoading} />
                <SaveButton disabled={isLoading || !formState.values.file} />
              </>
            )}
          />
        </Header>

        <Content>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12} lg={8}>
              <BackgroundSingleFileUpload
                name="file"
                api={API.LOT_SHEETS_FILE_VALIDATE}
                onSubscribe={onSubscribe}
                Component={UploadedFile}
                onRemove={onFileRemove}
                setAdditionalData={setAdditionalData}
              />
            </Grid>
            <Grid item={true} xs={12} lg={4}>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12} lg={12}>
                  <Card>
                    <CardHeader title="Settings" />
                    <Divider />
                    <CardContent>
                      <Box>
                        <Grid container={true} spacing={2}>
                          <Grid item={true} lg={12}>
                            <UploadOptionField />
                          </Grid>
                          <Grid item={true} lg={12}>
                            <CompanySearchField />
                          </Grid>
                        </Grid>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item={true} xs={12}>
                  <TemplateCard
                    fileName="Lotsheet.xlsx"
                    onClick={() => sendEvent({
                      eventAction: 'Template File Download Lotsheet',
                      eventCategory: 'Lotsheet'
                    })}
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <ProgressCard
                    title="Progress"
                    validation={progressCard.validation}
                    completion={progressCard.completion}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Content>
      </Container>
    </form>
  )
}

LotSheetsImport.propTypes = {
  form: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  progressCard: PropTypes.object.isRequired,
  onSubscribe: PropTypes.func.isRequired,
  onFileRemove: PropTypes.func.isRequired
}

export default withForm(LotSheetsImport)
