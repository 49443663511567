import React from 'react'
import isEqual from 'react-fast-compare'
import PropTypes from 'prop-types'
import Checkbox from '@mui/material/Checkbox'
import { Field } from 'react-final-form'

function CheckboxField ({ name, ...props }) {
  return (
    <Field name={name} type="checkbox">
      {({ input }) => (
        <Checkbox
          checked={input.checked || false}
          onChange={input.onChange}
          {...props}
        />
      )}
    </Field>
  )
}

CheckboxField.propTypes = {
  name: PropTypes.string.isRequired
}

CheckboxField.defaultProps = {
  color: 'primary',
  size: 'small',
}

export default React.memo(CheckboxField, isEqual)
