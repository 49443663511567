import React from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'

import { CardHeader } from '~/components/Cards'
import TextField from '~/components/Fields/TextField'

function RoleUpdateFormGeneral () {
  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12} lg={6}>
        <Box mb={3}>
          <Card>
            <CardHeader title="Details" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <TextField
                    data-cy="name"
                    name="name"
                    label="Name"
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <TextField
                    data-cy="description"
                    name="description"
                    label="Description"
                    multiline={true}
                    rows="8"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </Grid>

  )
}

export default RoleUpdateFormGeneral
