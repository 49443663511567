import React from 'react'
import { useTranslation } from 'react-i18next'
import { useField } from 'react-final-form'
import { prop } from 'ramda'
import { usePrevious } from 'storfox-tools'
import { sprintf } from 'sprintf-js'
import { useDeepCompareEffect } from 'storfox-api-hooks'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'

import * as API from '~/constants/api'
import SearchField from '~/components/Fields/SearchField'

const getOptionValue = value => {
  const { id, email, firstName, lastName } = value
  const name = `${firstName} ${lastName}`

  return { id, email, name }
}

function ClientField ({ integrationName, name, disabled, ...props }) {
  const { t } = useTranslation()
  const integrationField = useField(integrationName)
  const integrationValue = integrationField.input.value
  const integrationGuid = prop('guid', integrationValue)

  const prevIntegrationId = usePrevious(integrationGuid)
  const clientField = useField(name)

  const url = sprintf(API.SHIPOX_CUSTOMERS, integrationGuid)

  useDeepCompareEffect(() => {
    const integrationChanged = (
      integrationGuid &&
      integrationGuid !== prevIntegrationId &&
      integrationField.meta.dirty
    )

    if (integrationChanged) {
      clientField.input.onChange('')
    }
  }, [integrationGuid])

  return (
    <Box position="relative">
      <SearchField
        api={url}
        disabled={!integrationValue || disabled}
        name="shipmentClient"
        getOptionValue={getOptionValue}
        label={t('Service')}
        {...props}
      />
    </Box>
  )
}

ClientField.propTypes = {
  integrationName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
}

export default ClientField
