import { curry, fromPairs, is, join, map, pipe, pluck, prop, toPairs } from 'ramda'

import orderingToSnackCase from './orderingToSnackCase'

export const queryToParams = curry((toValues, query, primaryKey = 'id') => {
  return pipe(
    toValues,
    toPairs,
    map(([key, value]) => {
      if (is(Array, value)) {
        return value.length ? [key, join(',', pluck(primaryKey, value))] : [key, undefined]
      }

      if (typeof value === 'object') {
        return [key, prop(primaryKey, value)]
      }

      return [key, value]
    }),
    fromPairs,
    orderingToSnackCase
  )(query)
})
