import { ascend, curry, filter, fromPairs, isNil, join, map, not, omit, pipe, prop, sortWith, toPairs } from 'ramda'

export const setParams = curry((params, newParams) =>
  pipe(
    toPairs,
    filter(pipe(prop(1), isNil, not)),
    map(([key, value]) => ({ key, value })),
    sortWith([ascend(prop('key'))]),
    map(({ key, value }) => [key, value]),
    fromPairs
  )({ ...params, ...newParams })
)

export const clearParams = curry((params, key) => omit(key, params))

export const paramsToSearch = pipe(
  toPairs,
  map(join('=')),
  join('&')
)

export const objectToSearchParams = (params) => {
  const newParams = setParams({}, params)
  return paramsToSearch(newParams)
}
