import React from 'react'
import AlertTitle from '@mui/material/AlertTitle'
import Alert from '@mui/material/Alert'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'

const InfoWrapperAlertStyled = styled(Alert)({
  maxWidth: '280px',
  backgroundColor: '#D9E8F3 !important',
  borderRadius: '4px',
  minHeight: '128px',
  marginLeft: '24px',
  marginRight: '11px',
  '& strong': {
    color: 'black'
  }
})

function ConditionInstruction () {
  return (
    <InfoWrapperAlertStyled severity="info" icon={false}>
      <AlertTitle>Change condition</AlertTitle>
      <Box
        component="span"
        sx={{
          color: '#273257B2',
          fontSize: '14px',
          lineHeight: '21px'
        }}
      >
        Scan condition code starting with {' '}
        <strong>"CD-"</strong> {' '}
        to change condition of the next item. {' '}
        Example: <strong>"CD-New"</strong> {' '}
        or <strong>"CD-Old"</strong>
      </Box>
    </InfoWrapperAlertStyled>
  )
}

export default ConditionInstruction
