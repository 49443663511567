import React, { useCallback } from 'react'
import { length, path, pathEq, pathOr, prop, propOr } from 'ramda'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import IconButton from '@mui/material/IconButton'
import Delete from '@mui/icons-material/Delete'
import { useField } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { useTranslation } from 'react-i18next'
import AddIcon from '@mui/icons-material/Add'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { useValueChanged } from 'storfox-tools'
import PropTypes from 'prop-types'

import Avatar from '~/components/Avatar/Avatar'
import Money from '~/components/Money'
import VerticalAlignment from '~/components/VerticalAlignment'
import TextOverflow from '~/components/TextOverflow'
import Subtext from '~/components/Subtext'
import EmptyRow from '~/components/EmptyRow'
import TextField from '~/components/Fields/TextField'
import { Button } from '~/components/Buttons'
import useDialog from '~/hooks/useDialog'
import { emptyArray } from '~/constants/empty'

import ReturnQuantityField from './Fields/ReturnQuantityField'

import { COMPLETED, PROCESSING } from '../constants'
import ReturnVariantAddDialog from '../components/ReturnVariantAdd/ReturnVariantAddDialog'

function OrderCreateFormLineItems ({ returnVariants }) {
  const { t } = useTranslation()
  const { handleClose, handleOpen, open } = useDialog()

  const supplierField = useField('supplier')
  const supplierId = prop('id', supplierField.input.value)

  const warehouseField = useField('warehouse')
  const warehouseGuid = prop('guid', warehouseField.input.value)

  const searchField = useField('search')
  const searchFieldChange = searchField.input.onChange

  const lineItemsField = useField('lineItems')
  const lineItemsChange = lineItemsField.input.onChange
  const lineItemsError = pathOr('', ['meta', 'submitError', 0], lineItemsField)
  const lineItems = lineItemsField.input.value || emptyArray

  const statusField = useField('status')

  const status = statusField.input.value
  const quantityDisabled = (
    status === COMPLETED || status === PROCESSING
  )

  const returnUnitsSearchParams = {
    limit: 10,
    warehouseGuid,
    supplierId
  }

  const handleOnSearch = useCallback(value => {
    searchFieldChange(value)
    handleOpen()
  }, [handleOpen, searchFieldChange])

  const handleOnAddClose = useCallback(() => {
    searchFieldChange('')
    handleClose()
  }, [handleClose, searchFieldChange])

  const handleVariantsAdd = useCallback(variants => {
    const newVariants = variants.map(variant => {
      const variantGuid = path(['unit', 'guid'], variant)
      const isDuplicate = lineItems.find(item => item.unit.guid === variantGuid)
      if (!isDuplicate) {
        return {
          price: prop('price', variant),
          guid: prop('guid', variant),
          id: prop('id', variant),
          unit: variant,
          totalQuantity: prop('quantity', variant),
          quantity: 1
        }
      } else return null
    }).filter(variant => Boolean(variant))

    const otherLineItems = lineItems.filter((lineItem) => {
      return variants.find(pathEq(['unit', 'guid'], path(['unit', 'guid'], lineItem)))
    })

    lineItemsChange([...otherLineItems, ...newVariants])
    searchFieldChange('')
    handleClose()
  }, [searchFieldChange, lineItems, handleClose, lineItemsChange])

  useValueChanged(() => {
    return lineItemsChange([])
  }, warehouseGuid)

  useValueChanged(() => {
    return lineItemsChange([])
  }, supplierId)

  const disabledSelect = !warehouseGuid || !supplierId

  return (
    <FieldArray name="lineItems">
      {({ fields }) => {
        const info = !length(propOr([], 'value', fields))

        return <>
          <Box>
            <Grid container={true} spacing={3}>
              <Grid item={true} lg={10} xs={12}>
                <TextField
                  data-cy="variant"
                  name="variant"
                  label="Search"
                  onChange={event => !disabledSelect && handleOnSearch(event.target.value)}
                  disabled={disabledSelect}
                  error={lineItemsError}
                />
              </Grid>
              <Grid item={true} lg={2} xs={12}>
                <Button
                  fullWidth={true}
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={handleOpen}
                  type="button"
                  data-cy="browse"
                  disabled={disabledSelect}
                >
                  Browse
                </Button>
              </Grid>
            </Grid>
          </Box>
          {open && (
            <ReturnVariantAddDialog
              onClose={handleOnAddClose}
              open={open}
              onAddVariants={handleVariantsAdd}
              params={returnUnitsSearchParams}
              returnVariants={returnVariants}
            />
          )}

          <br />

          <TableContainer>
            <Table size="small" sx={{ minWidth: '700px' }}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('Image')}</TableCell>
                  <TableCell sx={{ width: '200px' }}>{t('Product')}</TableCell>
                  <TableCell>{t('Unit price')}</TableCell>
                  <TableCell sx={{ minWidth: '100px' }}>{t('Condition')}</TableCell>
                  <TableCell>{t('Total Qty')}</TableCell>
                  <TableCell>{t('Return Qty')}</TableCell>
                  <TableCell>{t('Total')}</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {fields.map((name, index) => {
                  const value = prop('value', fields)
                  const item = prop(index, value)

                  const unit = prop('unit', item)
                  const variant = prop('variant', unit)
                  const variantName = prop('name', variant)
                  const sku = prop('sku', variant)
                  const imagePath = prop('defaultImage', variant)
                  const condition = pathOr('', ['condition', 'code'], unit)

                  const unitCost = propOr(0, 'price', item)
                  const quantity = propOr(0, 'quantity', item)
                  const totalPrice = quantity * unitCost

                  const totalQuantity = prop('totalQuantity', item)

                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <Avatar
                          alt={variantName}
                          src={imagePath}
                        />
                      </TableCell>
                      <TableCell>
                        <VerticalAlignment
                          sx={{ minWidth: '200px' }}
                          primary={(
                            <TextOverflow selfTooltip={true}>
                              {variantName}
                            </TextOverflow>
                          )}
                          secondary={<Subtext lines={1}>{sku}</Subtext>}
                        />
                      </TableCell>
                      <TableCell>
                        <Money value={unitCost} />
                      </TableCell>
                      <TableCell>
                        {condition}
                      </TableCell>
                      <TableCell align="center">{totalQuantity}</TableCell>
                      <TableCell sx={{ width: '100px' }}>
                        <ReturnQuantityField
                          conditionName={`${name}.condition`}
                          name={`${name}.quantity`}
                          data-cy={`${name}.quantity`}
                          disabled={quantityDisabled}
                          maxValue={totalQuantity}
                        />
                      </TableCell>
                      <TableCell>
                        <Money value={totalPrice} />
                      </TableCell>
                      <TableCell align="right">
                        <IconButton onClick={() => fields.remove(index)} size="large">
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                })}
                {info && <EmptyRow colSpan={7} />}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      }}
    </FieldArray>
  )
}

OrderCreateFormLineItems.propTypes = {
  returnVariants: PropTypes.object.isRequired
}

export default OrderCreateFormLineItems
