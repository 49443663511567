import React from 'react'
import { AsyncMultiSelectField as StorfoxAsyncMultiSelectField } from 'storfox-form-fields'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

function AsyncMultiSelectField ({ label, ...props }) {
  const { t } = useTranslation()

  return (
    <StorfoxAsyncMultiSelectField
      label={t(label)}
      {...props}
    />
  )
}

AsyncMultiSelectField.propTypes = {
  label: PropTypes.string
}

export default AsyncMultiSelectField
