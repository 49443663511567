import React from 'react'
import { useField } from 'react-final-form'
import PropTypes from 'prop-types'
import { prop } from 'ramda'
import Box from '@mui/material/Box'

import LocationMultiSelectField from '../ActionFields/LocationMultiSelectField'
import UserMultiSelectField from '../ActionFields/UserMultiSelectField'
import { ASSIGN_TO, TO_LOCATION } from '../ActionFields/ActionSelectField'
import WarehouseActionSearchField from '../ActionFields/WarehouseActionSearchField'
import ZoneActionSearchField from '../ActionFields/ZoneActionSearchField'

export const PUTAWAY_V2_CREATED_FIELDS = [
  'assignTo',
  'toLocation'
]

function PutawayV2CreatedAction ({ action, actionName }) {
  const warehouseField = useField(`${actionName}.warehouse`)
  const warehouse = warehouseField.input.value
  const warehouseGuid = prop('guid', warehouse)

  const zoneField = useField(`${actionName}.zone`)
  const zone = zoneField.input.value
  const zoneGuid = prop('guid', zone)

  return (
    <>
      {action === ASSIGN_TO && <UserMultiSelectField name={`${actionName}.users`} />}
      {action === TO_LOCATION && (
        <>
          <Box mb={2}>
            <WarehouseActionSearchField name={`${actionName}.warehouse`} />
          </Box>
          <Box mb={2}>
            <ZoneActionSearchField
              name={`${actionName}.zone`}
              parentName={`${actionName}.warehouse`}
              params={{
                limit: 1000,
                warehouseGuid
              }}
            />
          </Box>
          <LocationMultiSelectField
            parentName={`${actionName}.zone`}
            name={`${actionName}.locations`}
            params={{
              limit: 1000,
              zoneGuid
            }}
          />
        </>
      )}
    </>
  )
}

PutawayV2CreatedAction.propTypes = {
  action: PropTypes.string.isRequired,
  actionName: PropTypes.string.isRequired
}

export default PutawayV2CreatedAction
