import React from 'react'
import Card from '@mui/material/Card'
import Skeleton from '@mui/material/Skeleton'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'

import { CardHeader } from '~/components/Cards'

function SkeletonCard () {
  return (
    <Card>
      <CardHeader title={(
        <Skeleton variant="text" />
      )} />
      <Divider />
      <CardContent>
        <Box>
          <Skeleton variant="rectangular" height={40} />
        </Box>
        <Box mt={4}>
          <Skeleton variant="rectangular" width={60} height={25} />
        </Box>
      </CardContent>
    </Card>
  )
}

export default SkeletonCard
