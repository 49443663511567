import React from 'react'
import { Ordering } from 'storfox-filter'

import { ZONE_COLUMNS as tableColumns } from './ZoneTable'

import { ZONE_FILTER_NAME as name } from '../../../constants'

export const zoneOrderingOptions = {
  name,
  tableColumns
}

function ZoneOrderingForm (props) {
  return (
    <Ordering {...props} />
  )
}

export default ZoneOrderingForm
