import React from 'react'

import MultiSelectField from '~/components/Fields/MultiSelectField'

const options = [
  {
    name: 'Stock transfer',
    value: 'StockTransfer'
  },
  {
    name: 'Sale order',
    value: 'SaleOrder'
  },
  {
    name: 'Putaway',
    value: 'Putaway'
  }
]

function UnitReservedTypeField (props) {
  return (
    <MultiSelectField
      name="toObjectTypes"
      label="Reserved for types"
      options={options}
      {...props}
    />
  )
}

export default UnitReservedTypeField
