import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import PropTypes from 'prop-types'
import DialogActions from '@mui/material/DialogActions'
import { useTranslation } from 'react-i18next'
import { path, prop, propOr } from 'ramda'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material'

import { getFormattedDateTime, getFullName } from '~/utils'
import Divider from '~/components/Divider'
import { Button } from '~/components/Buttons'
import Avatar from '~/components/Avatar'
import TextOverflow from '~/components/TextOverflow'
import OrderProgress from '~/components/OrderProgress'
import TextLabelLoader from '~/components/TextLabelLoader'
import VerticalAlignment from '~/components/VerticalAlignment'
import Subtext from '~/components/Subtext'
import SkeletonList from '~/components/Skeleton'
import TableDateFormat from '~/components/TableDateFormat'
import { HISTORY_FORMAT } from '~/constants/dateFormat'
import { getDateTimeGMTUser } from '~/utils/date'

const BoxStyled = styled(Box)(({ theme }) => ({
  margin: theme.spacing(4, 0),
  display: 'flex',
  justifyContent: 'space-between',
  width: '60%',
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }
}))

function ReceiveItemDialog ({ open, isLoading, onClose, variantDetail }) {
  const { t } = useTranslation()

  const variant = prop('variant', variantDetail)
  const variantName = propOr('', 'name', variant)
  const image = prop('defaultImage', variant)
  const barcode = prop('barcode', variant)
  const sku = propOr('', 'sku', variant)
  const totalReceived = prop('totalReceived', variantDetail)
  const ordered = prop('ordered', variantDetail)
  const receivedItems = propOr([], 'receivedItems', variantDetail)

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth={true}
    >
      <Box display="flex" p={3}>
        <Box mr={1}>
          <Avatar src={image} alt={variantName} />
        </Box>
        <Box>
          <VerticalAlignment
            primary={(
              <Typography component="h6" variant="h6">
                <TextOverflow selfTooltip={true}>
                  {variantName}
                </TextOverflow>
              </Typography>
            )}
            secondary={<Subtext>{sku}</Subtext>}
          />
        </Box>
      </Box>
      <Divider />
      <DialogContent>
        <Typography variant="h4">
          {t('General information')}
        </Typography>
        <BoxStyled>
          <TextLabelLoader label="Barcode">
            {barcode}
          </TextLabelLoader>
          <TextLabelLoader label="Total Quantity">
            <OrderProgress
              ordered={ordered}
              received={totalReceived}
            />
          </TextLabelLoader>
        </BoxStyled>
        <Box>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('Location')}</TableCell>
                  <TableCell align="right">{t('Container')}</TableCell>
                  <TableCell align="right">{t('Quantity')}</TableCell>
                  <TableCell align="right">{t('Lot expiry date')}</TableCell>
                  <TableCell align="right">{t('Receive date')}</TableCell>
                  <TableCell align="right">{t('Receive by')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!isLoading
                  ? receivedItems.map((item, index) => {
                    const locationId = path(['location', 'locationId'], item)
                    const containerNumber = path(['container', 'number'], item)
                    const quantity = prop('quantity', item)
                    const receivedUser = prop('receivedUser', item)
                    const fullName = getFullName(receivedUser)
                    const timezoneDate = getDateTimeGMTUser(prop('receiveDate', item))
                    const receiveDate = getFormattedDateTime(timezoneDate, HISTORY_FORMAT)

                    return (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          <TextOverflow lines={2} selfTooltip={true}>
                            {locationId}
                          </TextOverflow>
                        </TableCell>
                        <TableCell align="right">{containerNumber}</TableCell>
                        <TableCell align="right">{quantity}</TableCell>
                        <TableCell align="right">
                          <TableDateFormat date={prop('expiresAt', item)} />
                        </TableCell>
                        <TableCell align="right">
                          {receiveDate}
                        </TableCell>
                        <TableCell align="right">{fullName}</TableCell>
                      </TableRow>
                    )
                  })
                  : <SkeletonList columnCount={5} rowCount={1} />}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={onClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ReceiveItemDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  variantDetail: PropTypes.object
}

export default ReceiveItemDialog
