import { useLocation, useNavigate } from 'react-router-dom'

import useAllSearchParams from './useAllSearchParams'
import { clearParams, paramsToSearch, setParams } from './utils'

function useRoute () {
  const navigate = useNavigate()
  const params = useAllSearchParams()
  const location = useLocation()
  const pathname = location.pathname

  const changeParams = values => {
    const newParams = setParams(params, values)
    const url = `${pathname}?${paramsToSearch(newParams)}`

    navigate(url)
  }

  const replaceParams = values => {
    const newParams = setParams(params, values)
    const url = `${pathname}?${paramsToSearch(newParams)}`

    navigate(url, { replace: true })
  }

  const removeParams = key => {
    const newParams = clearParams(params, [key])
    const url = `${pathname}?${paramsToSearch(newParams)}`
    navigate(url)
  }

  const getParams = () => params

  return { changeParams, replaceParams, removeParams, getParams }
}

export default useRoute
