import PropTypes from 'prop-types'
import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItem from '@mui/material/ListItem'
import { map, range } from 'ramda'
import Skeleton from '@mui/material/Skeleton'

function PackingListItemsLoading ({ numberOfItems }) {
  return <>
    {map(index => (
      <ListItem key={index}>
        <Grid container={true} alignItems="center">
          <Grid item={true} xs={12}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Box display="flex" alignItems="center" maxWidth="75%">
                <Box>
                  <ListItemAvatar>
                    <Skeleton variant="rectangular" width={40} height={40} />
                  </ListItemAvatar>
                </Box>

                <Box>
                  <Skeleton width={250} />
                  <Skeleton />
                  <Skeleton />
                </Box>

              </Box>

              <Box display="flex" alignItems="center">
                <Skeleton variant="rectangular" height={20} width={50} />
              </Box>
            </Box>
          </Grid>

        </Grid>
      </ListItem>
    ), range(0, numberOfItems))}
  </>
}

PackingListItemsLoading.propTypes = {
  numberOfItems: PropTypes.number.isRequired
}

PackingListItemsLoading.defaultProps = {
  numberOfItems: 8
}

export default PackingListItemsLoading
