import React from 'react'
import { prop } from 'ramda'
import PropTypes from 'prop-types'

import { unescapeBtoa } from '~/utils'
import {
  AllocationStatus,
  FulfillmentStatus,
  PaymentStatus,
  SaleOrderStatus,
  ShipmentStatus
} from '~/components/Statuses'
import StatusGrid, { StatusGridItem, StatusGridLink, StatusGridPopover } from '~/components/StatusGrid'
import * as ROUTES from '~/constants/routes'
import { SHIP, UNFULFILLED } from '~/components/Statuses/FulfillmentStatus'
import { FAILED, UNALLOCATED } from '~/components/Statuses/AllocationStatus'
import { useCompany } from '~/components/Profile'

function Statuses ({ order, isLoading }) {
  const { isRetailer } = useCompany()
  const orderStatus = prop('status', order)
  const allocationStatus = prop('allocationStatus', order)
  const fulfillmentStatus = prop('fulfillmentStatus', order)
  const shipmentStatus = prop('shipmentStatus', order)
  const paymentStatus = prop('paymentStatus', order)

  const isShip = fulfillmentStatus === SHIP
  const isUnallocated = allocationStatus === UNALLOCATED || allocationStatus === FAILED
  const isUnfulfilled = fulfillmentStatus === UNFULFILLED

  const number = prop('number', order)
  const id = prop('id', order)

  const orderObjectForUnit = unescapeBtoa([{ guid: id, number }])
  const orderObject = unescapeBtoa([{ id, number }])

  const unitListLink = `${ROUTES.UNIT_LIST_PATH}?objects=${orderObjectForUnit}`
  const picklistListLink = `${ROUTES.PICKLIST_LIST_PATH}?objects=${orderObject}`
  const packingListLink = `${ROUTES.PACKING_LIST_PATH}?objects=${orderObject}`
  const shipmentListLink = `${ROUTES.SHIPMENT_LIST_PATH}?objects=${orderObject}`

  const allocationPopover = (
    <StatusGridPopover title="Allocation">
      <StatusGridLink href={unitListLink}>
        Unit list
      </StatusGridLink>
    </StatusGridPopover>
  )

  const fulfillmentPopover = (
    <StatusGridPopover title="Fulfillment">
      <StatusGridLink href={picklistListLink}>
        Picklist
      </StatusGridLink>
      <StatusGridLink href={packingListLink}>
        Packing
      </StatusGridLink>
    </StatusGridPopover>
  )

  const shipmentPopover = (
    <StatusGridPopover title="Shipment">
      <StatusGridLink href={shipmentListLink}>
        Shipment
      </StatusGridLink>
    </StatusGridPopover>
  )

  return (
    <StatusGrid>
      <StatusGridItem
        title="Order"
        isLoading={isLoading}
      >
        {orderStatus && <SaleOrderStatus value={orderStatus} />}
      </StatusGridItem>

      <StatusGridItem
        title={isUnallocated ? 'Allocation' : allocationPopover}
        isLoading={isLoading}
      >
        {allocationStatus && <AllocationStatus value={allocationStatus} />}
      </StatusGridItem>

      <StatusGridItem
        title={isRetailer || isUnfulfilled ? 'Fulfillment' : fulfillmentPopover}
        isLoading={isLoading}
      >
        {fulfillmentStatus && <FulfillmentStatus value={fulfillmentStatus} />}
      </StatusGridItem>

      <StatusGridItem
        title={isShip ? shipmentPopover : 'Shipment'}
        isLoading={isLoading}
      >
        {shipmentStatus && <ShipmentStatus value={shipmentStatus} />}
      </StatusGridItem>

      <StatusGridItem
        title="Payment"
        isLoading={isLoading}
      >
        {paymentStatus && <PaymentStatus value={paymentStatus} />}
      </StatusGridItem>
    </StatusGrid>
  )
}

Statuses.propTypes = {
  order: PropTypes.object,
  isLoading: PropTypes.bool.isRequired
}

export default Statuses
