import React from 'react'
import PropTypes from 'prop-types'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

import Tabs from '~/components/Tabs'
import { ROLE_UPDATE_PATH } from '~/constants/routes'
import { ROLE_TABS } from '~/constants/tabs'

const tabs = [
  { value: ROLE_TABS.GENERAL, label: 'General' },
  { value: ROLE_TABS.PERMISSIONS, label: 'Permissions' }
]

function RoleUpdateFormTabs ({ value }) {
  const navigate = useNavigate()
  const { guid } = useParams()

  return (
    <Tabs
      tabs={tabs}
      value={value}
      onChange={(event, tab) => {
        const redirect = generatePath(ROLE_UPDATE_PATH, { guid, tab })

        navigate(redirect, { replace: true })
      }}
    />
  )
}

RoleUpdateFormTabs.propTypes = {
  value: PropTypes.string.isRequired
}

export default RoleUpdateFormTabs
