import { useCreate, useDetail, useScrollList } from 'storfox-api-hooks'

import * as API from '~/constants/api'

export const useNotificationList = () => {
  return useScrollList(API.NOTIFICATION_LIST)
}

export const useNotificationUnread = () => {
  return useDetail(API.UNREAD_NOTIFICATION_COUNT, {}, false)
}

export const useReadAllNotifications = () => {
  return useCreate(API.NOTIFICATIONS_MARK_READ)
}
