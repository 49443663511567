import { useCallback, useRef, useState } from 'react'
import uuid from 'uuid/v1'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import { toBase64 } from '../utils'

export function useUpload ({ onUpload, accept }) {
  const ref = useRef(true)
  const [files, setFiles] = useState([])
  const snackbar = useSnackbar()

  const handleUpload = useCallback(acceptedFiles => {
    ref.current = false
    const incorrectType = accept ? acceptedFiles.find((file) => !accept.includes(file.type)) : null

    if (!incorrectType) {
      const newItems = acceptedFiles.map(obj => ({
        obj,
        src: null,
        key: uuid(),
        start: false,
        finish: false,
        progress: 0
      }))

      setFiles(prevFiles => ([...prevFiles, ...newItems]))

      newItems.forEach(file => {
        const handleImage = (items, src) => items.map(item => {
          if (item.key === file.key) {
            return { ...item, src }
          }

          return item
        })

        toBase64(file.obj)
          .then(src => setFiles(files => handleImage(files, src)))
      })

      newItems.forEach(file => {
        const handleStart = items => items.map(item => {
          if (item.key === file.key) {
            return { ...item, start: true }
          }

          return item
        })

        const handleFinish = (items, data) => items.map(item => {
          if (item.key === file.key) {
            return { ...item, ...data, finish: true }
          }

          return item
        })

        const handleProgress = (items, { loaded, total }) => items.map(item => {
          const progress = Math.round((loaded * 100) / total)

          if (item.key === file.key) {
            return { ...item, progress }
          }

          return item
        })

        setFiles(files => handleStart(files))

        const data = new FormData()
        data.append('files', file.obj)
        data.append('resize', 'true')

        return onUpload(data, progressEvent => {
          const loaded = progressEvent.loaded
          const total = progressEvent.total

          setFiles(files => handleProgress(files, { loaded, total }))
        })
          .then(data => {
            setFiles(files => handleFinish(files, data))
          })
      })
    } else {
      snackbar({ message: 'Incorrect upload type', type: ALTER_ERROR })
    }
  }, [snackbar, onUpload, accept])

  return [handleUpload, files, setFiles]
}
