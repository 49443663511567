import React from 'react'
import { Ordering } from 'storfox-filter'

import { PURCHASE_ORDER_COLUMNS as tableColumns } from './PurchaseOrderTable'

import { FILTER_NAME as name } from '../../constants'

export const purchaseOrderOrderingOptions = {
  name,
  tableColumns
}

function PurchaseOrderOrderingForm (props) {
  return (
    <Ordering {...props} />
  )
}

export default PurchaseOrderOrderingForm
