import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { map, path, prop } from 'ramda'
import { generatePath, useNavigate } from 'react-router-dom'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import EditIcon from '@mui/icons-material/Edit'

import { MoreListButton } from '~/components/Buttons'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import TextOverflow from '~/components/TextOverflow'
import Subtext from '~/components/Subtext'
import VerticalAlignment from '~/components/VerticalAlignment'
import TableDateFormat from '~/components/TableDateFormat'
import Avatar from '~/components/Avatar/Avatar'
import Money from '~/components/Money'
import { IntegrationIcon } from '~/components/Icons'
import Tooltip from '~/components/HtmlTooltip'
import { Table, TableHeader } from '~/components/Table'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import { LISTING_TABS } from '~/constants/tabs'
import TableLink from '~/components/Link'
import ListingStatus from '~/components/Statuses/ListingStatus'

import BulkActionButton from './BulkActionButton'

const getDetailPath = guid => {
  return generatePath(ROUTES.LISTING_DETAIL_PATH, { guid, tab: LISTING_TABS.VARIANT })
}

export const LISTING_COLUMNS = [
  {
    width: 75,
    headerName: 'Image',
    field: 'image',
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        <Avatar src={value} alt={row.name} />
      </TableLink>
    )
  },
  {
    width: 400,
    headerName: 'SKU',
    field: 'product',
    renderCell: ({ value, row }) => (
      <VerticalAlignment
        primary={(
          <TableLink to={row.link}>
            <TextOverflow selfTooltip={true}>
              {value.name}
            </TextOverflow>
          </TableLink>
        )}
        secondary={(
          <Subtext lines={1}>
            {value.sku}
          </Subtext>
        )}
      />
    )
  },
  {
    width: 150,
    headerName: 'Channel',
    field: 'channel',
    renderCell: ({ value }) => (
      <Tooltip key={value.key} title={value.title}>
        <IntegrationIcon type={value.channelType} width={30} />
      </Tooltip>
    )
  },
  {
    width: 150,
    headerName: 'Status',
    field: 'status',
    renderCell: ({ value }) => (
      <ListingStatus value={value} />
    )
  },
  {
    width: 100,
    headerName: 'Qty',
    field: 'quantity',
    headerAlign: 'center',
    align: 'center'
  },
  {
    width: 200,
    headerName: 'Retail price',
    field: 'price',
    headerAlign: 'center',
    align: 'center',
    renderCell: ({ value }) => (
      <Money value={value} />
    )
  },
  {
    width: 200,
    headerName: 'M.R.P',
    field: 'mrp',
    headerAlign: 'center',
    align: 'center',
    renderCell: ({ value }) => (
      <Money value={value} />
    )
  },
  {
    width: 200,
    headerName: 'Brand',
    field: 'brand',
  },
  {
    width: 150,
    headerName: 'Category',
    field: 'category',
    renderCell: ({ value }) => (
      <TextOverflow selfTooltip={true}>{value}</TextOverflow>
    )
  },
  {
    width: 300,
    headerName: 'Tags',
    field: 'tags'
  },
  {
    width: 150,
    headerName: 'Last updated',
    field: 'updatedAt',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  }
]

const getRowsFromResults = map(item => {
  const guid = prop('guid', item)
  const id = prop('id', item)
  const variant = prop('variant', item)
  const name = prop('name', item)

  const channelObj = prop('channel', item)

  const channel = {
    key: prop('name', channelObj),
    title: prop('name', channelObj),
    channelType: prop('type', channelObj)
  }

  const brand = path(['brand', 'name'], item)
  const category = path(['category', 'name'], item)
  const product = {
    name: prop('name', item),
    sku: prop('sku', item)
  }

  const price = prop('retailPrice', item)
  const mrp = prop('maximumRetailPrice', item)
  const tags = prop('tags', variant)
  const status = prop('status', item)
  const quantity = prop('available', item)
  const image = prop('defaultImage', item)
  const updatedAt = prop('updatedAt', item)
  const link = getDetailPath(guid)

  return {
    guid,
    id,
    image,
    name,
    product,
    channel,
    price,
    mrp,
    brand,
    category,
    tags,
    status,
    quantity,
    updatedAt,
    link
  }
})

function ListingTable (props) {
  const { list, filter, onListRefetch, ordering, onPublish, onUnpublish, onPause, onReject, bulkActionLoading } = props
  const [tableRef, setTableRef] = useState(null)
  const navigate = useNavigate()
  const isLoading = prop('isLoading', list)
  const results = prop('results', list)
  const count = prop('count', list)
  const columns = prop('filteredColumns', ordering)

  const handleEdit = useCallback(guid => {
    const editPath = generatePath(ROUTES.LISTING_UPDATE_PATH, { guid, tab: LISTING_TABS.VARIANT })
    navigate(editPath)
  }, [navigate])

  const actionColumns = useMemo(() => [
    ...columns,
    {
      width: 50,
      resizable: false,
      field: 'actions',
      type: 'actions',
      getActions: ({ row }) => [
        <GridActionsCellItem
          label="Edit"
          onClick={() => handleEdit(row.guid)}
          showInMenu={true}
          icon={<EditIcon />}
        />
      ]
    }
  ], [columns, handleEdit])

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={NAV.LISTING}
          parentTitle={NAV.CATALOG}
          rightButton={(
            <BulkActionButton
              onPublish={onPublish}
              onUnpublish={onUnpublish}
              onPause={onPause}
              onReject={onReject}
              loading={bulkActionLoading}
            />
          )}
        />
      </Header>

      <Content>
        <TableHeader
          filter={filter}
          filterOpen={filter.handleOpen}
          orderingOpen={ordering.handleOpen}
          ordering={ordering}
        >
          <MoreListButton tableRef={tableRef} />
        </TableHeader>
        <Table
          isLoading={isLoading}
          columns={actionColumns}
          getRowsFromResults={getRowsFromResults}
          results={results}
          count={count}
          primaryKey="guid"
          checkboxSelection={true}
          ordering={ordering}
          pinnedColumns={['image', 'product']}
          onRefetch={onListRefetch}
          setTableRef={setTableRef}
        />
      </Content>
    </Container>
  )
}

ListingTable.propTypes = {
  list: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  onPublish: PropTypes.func.isRequired,
  onListRefetch: PropTypes.func.isRequired,
  onUnpublish: PropTypes.func.isRequired,
  onPause: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  bulkActionLoading: PropTypes.bool
}

export default ListingTable
