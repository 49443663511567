import React from 'react'
import { prop } from 'ramda'

import MultiSelectSearchField from '~/components/Fields/MultiSelectSearchField'
import * as API from '~/constants/api'

const getOptionLabel = item => {
  if (item) {
    return prop('number', item)
  }

  return ''
}

const getOptionValue = item => {
  if (item) {
    const guid = prop('id', item)
    const number = prop('number', item)

    return { guid, number }
  }

  return null
}

function InventoryOrderField (props) {
  return (
    <MultiSelectSearchField
      name="objects"
      label="Orders numbers"
      api={API.SALE_ORDER_LIST}
      params={{ limit: 10 }}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      {...props}
    />
  )
}

export default InventoryOrderField
