import { CenteredLayout } from '~/components/Layouts'

import Error404 from './containers/Error404'

export default () => {
  return [
    {
      layout: CenteredLayout,
      path: '*',
      component: Error404
    }
  ]
}
