import React from 'react'

import SelectField from '~/components/Fields/SelectField'
import { LOCATION_TYPES } from '~/constants/locationTypes'

function LocationTypeField ({ ...props }) {
  return (
    <SelectField
      data-cy="locationType"
      name="locationType"
      label="Location type"
      options={LOCATION_TYPES}
      MenuProps={{
        'data-cy': 'locationTypeMenu'
      }}
      SelectDisplayProps={{
        'data-cy': 'locationTypeField'
      }}
      {...props}
    />
  )
}

export default LocationTypeField
