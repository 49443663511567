import React from 'react'
import PropTypes from 'prop-types'
import { prop, propOr } from 'ramda'
import Grid from '@mui/material/Grid'
import Chart from 'react-apexcharts'

import OrderInformationCard from './OrderInformationCard'

const getChartOptions = (color) => {
  return {
    chart: {
      type: 'area',
      sparkline: {
        enabled: true
      },
    },
    stroke: {
      curve: 'smooth',
      width: 1,
    },
    colors: [color],
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 0,
        opacityFrom: 1,
        opacityTo: 0.7,
        stops: [0, 50]
      }
    },
    tooltip: { enabled: false },
    yaxis: {
      labels: { show: false },
      min: 0
    },
    xaxis: {
      labels: { show: false }
    }
  }
}

const getChartsSeries = (data) => {
  return [{ type: 'area', data }]
}

function OrdersGeneral ({ orderOverview, isLoading }) {
  const detail = prop('detail', orderOverview)

  const totalOptions = getChartOptions('#FFC42E')
  const totalSeries = getChartsSeries([5, 7, 3, 4, 2, 6])

  const draftOptions = getChartOptions('#3CC7F4')
  const draftSeries = getChartsSeries([3, 2, 4, 2])

  const newOptions = getChartOptions('#FFA12D')
  const newSeries = getChartsSeries([5, 1, 3, 2, 4, 3])

  const allocatedOptions = getChartOptions('#656EEA')
  const allocatedSeries = getChartsSeries([2, 5, 3, 4])

  const issueOptions = getChartOptions('#F9597D')
  const issueSeries = getChartsSeries([3, 5, 3, 4, 1, 5])
  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} md={12 / 5}>
        <OrderInformationCard
          title="Total orders"
          value={propOr(0, 'total', detail)}
          isLoading={isLoading}
          chart={<Chart options={totalOptions} series={totalSeries} height={120} />}
          tooltip="Total count of all orders in the system."
        />
      </Grid>
      <Grid item={true} md={12 / 5}>
        <OrderInformationCard
          title="Draft"
          value={propOr(0, 'draft', detail)}
          isLoading={isLoading}
          chart={<Chart options={draftOptions} series={draftSeries} height={120} />}
          tooltip="Orders in the initial stage, not yet finalized."
        />
      </Grid>
      <Grid item={true} md={12 / 5}>
        <OrderInformationCard
          title="New"
          value={propOr(0, 'new', detail)}
          isLoading={isLoading}
          chart={<Chart options={newOptions} series={newSeries} height={120} />}
          tooltip="Recently submitted orders awaiting processing"
        />
      </Grid>
      <Grid item={true} md={12 / 5}>
        <OrderInformationCard
          title="Allocated"
          value={propOr(0, 'allocated', detail)}
          isLoading={isLoading}
          chart={<Chart options={allocatedOptions} series={allocatedSeries} height={120} />}
          tooltip="Orders with inventory reserved for fulfillment."
        />
      </Grid>
      <Grid item={true} md={12 / 5}>
        <OrderInformationCard
          title="Issue"
          value={propOr(0, 'issues', detail)}
          isLoading={isLoading}
          chart={<Chart options={issueOptions} series={issueSeries} height={120} />}
          tooltip="Orders encountering processing complications"
        />
      </Grid>
    </Grid>
  )
}

OrdersGeneral.propTypes = {
  orderOverview: PropTypes.object,
  isLoading: PropTypes.bool.isRequired
}

export default OrdersGeneral
