import React from 'react'

import * as API from '~/constants/api'
import SearchField from '~/components/Fields/SearchField'

function UserSelectField (props) {
  return (
    <SearchField
      api={API.AUTOMATION_RULE_USER_LIST}
      label="Users"
      {...props}
    />
  )
}

export default UserSelectField
