import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@mui/material/Paper'

import TableToolbar from './TableToolbar'

import DataGrid from '../DataGrid'
import { styled } from "@mui/material";

const PaperRoot = styled(Paper)(({ theme }) => ({
  borderLeft: `1px solid ${theme.palette.background.tableBorder}`,
  borderRight: `1px solid ${theme.palette.background.tableBorder}`,
}))

function Table (props) {
  const {
    rows,
    columns,
    isLoading,
    count,
    rowHeight = 48,
    pagination = true,
    onRowClick,
    checkboxSelection,
    height,
    ordering,
    pinnedColumns = [],
    primaryKey,
    autoPageSize,
    setTableRef,
    isNewPagination,
    hasNextPage,
    hasPrevPage
  } = props

  return (
    <PaperRoot style={{ height: height || 'auto', borderTopLeftRadius: '0', borderTopRightRadius: '0' }}>
      <DataGrid
        isLoading={isLoading}
        rows={rows}
        count={count}
        columns={columns}
        ToolbarComponent={() => <TableToolbar setTableRef={setTableRef} />}
        onRowClick={onRowClick}
        rowHeight={rowHeight}
        checkboxSelection={checkboxSelection}
        pinnedColumns={pinnedColumns}
        height={height}
        pagination={pagination}
        space={!pagination}
        primaryKey={primaryKey}
        ordering={ordering}
        autoPageSize={autoPageSize}
        setTableRef={setTableRef}
        isNewPagination={isNewPagination}
        hasNextPage={hasNextPage}
        hasPrevPage={hasPrevPage}
      />
    </PaperRoot>
  )
}

Table.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  rowHeight: PropTypes.number.isRequired,
  ordering: PropTypes.object,
  count: PropTypes.number,
  onRowClick: PropTypes.func,
  pagination: PropTypes.bool,
  checkboxSelection: PropTypes.bool,
  autoPageSize: PropTypes.bool,
  pinnedColumns: PropTypes.array,
  primaryKey: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setTableRef: PropTypes.func,
  isNewPagination: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  hasPrevPage: PropTypes.bool
}

export default Table
