import React from 'react'
import PropTypes from 'prop-types'
import { prop } from 'ramda'
import { generatePath } from 'react-router-dom'
import Box from '@mui/material/Box'

import * as ROUTES from '~/constants/routes'
import { SUPPLIER_DETAIL_TABS } from '~/constants/tabs'

import SupplierAddresses from './SupplierAddresses'

import SupplierInfoRow from '../../SupplierInfoRow'

function PurchaseOrderSupplierDetail ({ supplier, billingAddress, deliveryAddress }) {
  const supplierGuid = prop('guid', supplier)
  const params = { guid: supplierGuid, tab: SUPPLIER_DETAIL_TABS.GENERAL }
  const link = supplierGuid && generatePath(ROUTES.SUPPLIER_DETAIL_PATH, params)

  return (
    <>
      <SupplierInfoRow supplier={supplier} link={link} />
      <Box mt={2}>
        <SupplierAddresses
          billingAddress={billingAddress}
          deliveryAddress={deliveryAddress}
        />
      </Box>
    </>
  )
}

PurchaseOrderSupplierDetail.propTypes = {
  supplier: PropTypes.object,
  billingAddress: PropTypes.object,
  deliveryAddress: PropTypes.object
}

export default PurchaseOrderSupplierDetail
