import React from 'react'
import { useField } from 'react-final-form'
import PropTypes from 'prop-types'
import InfoIcon from '@mui/icons-material/Info'
import InputAdornment from '@mui/material/InputAdornment'
import { IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { endsWith, startsWith } from 'ramda'
import LoopIcon from '@mui/icons-material/Loop'
import JsBarcode from 'jsbarcode'

import SubmitBlockedTextField from '~/components/Fields/SubmitBlockedTextField'
import HtmlTooltip from '~/components/HtmlTooltip'

import { generateBarcode } from '../../utils/barcode'

function SkuField ({ name = 'barcode', required }) {
  const { t } = useTranslation()
  const field = useField(name)
  const value = field.input.value || ''
  const isSpaces = startsWith(' ', value) || endsWith(' ', value)

  const handleBarcodeGenerate = () => {
    const barcode = generateBarcode()

    const someData = {}
    const randomBarcode = barcode.substr(0, 12)
    JsBarcode(someData, randomBarcode, { format: 'EAN13' })
    const newBarcode = someData.encodings.reduce((a, b) => a + b.text, '')

    field.input.onChange(newBarcode)
  }

  return (
    <>
      <SubmitBlockedTextField
        name={name}
        data-cy="barcode"
        label="Barcode"
        placeholder="UPC/EAN/GTIN/ISBN/ASIN"
        required={required}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {isSpaces && (
                <HtmlTooltip title="Leading and trailing spaces will be removed">
                  <IconButton size="small">
                    <InfoIcon color="warning" />
                  </IconButton>
                </HtmlTooltip>
              )}

              <IconButton
                size="small"
                title={t('Generate unique barcode')}
                onClick={handleBarcodeGenerate}
              >
                <LoopIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    </>
  )
}

SkuField.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool
}

export default SkuField
