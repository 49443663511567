import React from 'react'
import PropTypes from 'prop-types'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { prop } from 'ramda'

import { Button } from '~/components/Buttons'
import { withForm } from '~/components/Form'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import * as NAV from '~/constants/nav-titles'
import LoaderProvider from '~/components/Loader'
import * as ROUTES from '~/constants/routes'
import { PUTAWAY_DETAIL_TABS } from '~/constants/tabs'
import History from '~/components/History'

import PutawayDetailTabs from './PutawayDetailTabs'

import PutawayGeneral from '../PutawayPut/PutawayGeneral'
import { COMPLETED, IN_PROGRESS, PENDING } from '../../constants'

function PutawayDetail (props) {
  const {
    pageTitle,
    pageTitleLoading,
    isLoading,
    tab,
    form,
    onProgress,
    onComplete,
    onAssignTo,
    putawayHistory
  } = props

  const { guid } = useParams()
  const navigate = useNavigate()

  const { handleSubmit, initialValues } = form
  const status = prop('status', initialValues)
  const disabled = status === COMPLETED || status === PENDING
  const isPending = status === PENDING
  const isInProgress = status === IN_PROGRESS

  const handleClick = () => {
    const path = generatePath(ROUTES.V2_PUTAWAY_UPDATE_PATH, { guid })
    navigate(path)
  }

  return (
    <LoaderProvider isLoading={isLoading}>
      <form onSubmit={handleSubmit}>
        <Container>
          <Header>
            <PageTitle
              pageTitle={pageTitle}
              pageTitleLoading={pageTitleLoading}
              parentTitle={NAV.PUTAWAY}
              rightButton={(
                <>
                  {isPending && (
                    <Button
                      data-cy="progress"
                      variant="contained"
                      onClick={onProgress}
                    >
                      Progress
                    </Button>
                  )}
                  {isInProgress && (
                    <Button
                      data-cy="complete"
                      variant="contained"
                      onClick={onComplete}
                    >
                      Complete
                    </Button>
                  )}
                  <Button
                    data-cy="put"
                    variant="contained"
                    onClick={handleClick}
                    disabled={disabled}
                  >
                    Put
                  </Button>
                </>
              )}
            />
          </Header>
          <Content>
            <PutawayDetailTabs value={tab} />
            {tab === PUTAWAY_DETAIL_TABS.GENERAL && (
              <PutawayGeneral
                isLoading={isLoading}
                onAssignTo={onAssignTo}
                initialValues={initialValues} />
            )}
            {tab === PUTAWAY_DETAIL_TABS.HISTORY && (<History history={putawayHistory} />)}
          </Content>
        </Container>
      </form>
    </LoaderProvider>
  )
}
PutawayDetail.propTypes = {
  form: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  pageTitle: PropTypes.string.isRequired,
  pageTitleLoading: PropTypes.bool.isRequired,
  tab: PropTypes.string.isRequired,
  onProgress: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  onAssignTo: PropTypes.func.isRequired,
  putawayHistory: PropTypes.object.isRequired
}

export default withForm(PutawayDetail)
