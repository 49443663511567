import React from 'react'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import { Checkbox, styled } from '@mui/material'

const RootStyled = styled(Box, {
  shouldForwardProp: propName => propName !== 'isSelected' && propName !== 'isPadding'
})(({ theme, isSelected, isPadding }) => ({
  position: 'relative',
  border: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  padding: theme.spacing(0, 1),
  ...(isSelected && {
    border: `1px solid ${theme.palette.secondary.main}`,
    background: `${theme.palette.secondary.main}10`
  }),
  ...(isPadding && {
    padding: theme.spacing(2, 1)
  })
}))

function SelectableBox ({ children, selected, onClick, isPadding, className }) {
  return (
    <RootStyled
      borderRadius="4px"
      isSelected={selected}
      isPadding={isPadding}
      className={className}
      onClick={onClick}
    >
      <Checkbox size="small" checked={selected} />
      {children}
    </RootStyled>
  )
}

SelectableBox.propTypes = {
  children: PropTypes.any.isRequired,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  isPadding: PropTypes.bool,
  className: PropTypes.string
}

export default SelectableBox
