import React from 'react'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

function MovementSummary ({ lineItems }) {
  const { t } = useTranslation()
  const quantity = lineItems.reduce((old, current) => old + current.quantity, 0)

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>{t('Movement summary')}</TableCell>
          <TableCell />
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>{t('Line items')}</TableCell>
          <TableCell />
          <TableCell align="right">
            {lineItems.length}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell><strong>{t('Total quantity')}</strong></TableCell>
          <TableCell />
          <TableCell align="right">
            {quantity}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

MovementSummary.propTypes = {
  lineItems: PropTypes.array.isRequired
}

export default MovementSummary
