import React from 'react'
import { Ordering } from 'storfox-filter'

import { PUTAWAY_COLUMNS as tableColumns } from './PutawayTable'

import { FILTER_NAME as name } from '../../constants'

export const putawayOrderingOptions = {
  name,
  tableColumns
}

function PutawayOrderingForm (props) {
  return (
    <Ordering {...props} />
  )
}

export default PutawayOrderingForm
