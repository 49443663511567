import { useCallback, useState } from 'react'

export function useFileAttachment ({ onUpload }) {
  const [file, setFile] = useState({ loading: false })

  const handleUpload = useCallback(acceptedFiles => {
    const newItem = {
      obj: acceptedFiles[0],
      start: true,
      finish: false,
      progress: 0,
      loading: true
    }

    setFile(newItem)
    const data = new FormData()
    data.append('files', newItem.obj)
    data.append('resize', false)

    onUpload(data, progressEvent => {
      const loaded = progressEvent.loaded
      const total = progressEvent.total
      const progress = Math.round((loaded * 100) / total)
      setFile(file => ({ ...file, progress }))
    })
      .then(() => {
        setFile(file => ({ ...file, finish: true, loading: false }))
      })
  }, [onUpload])

  return [file, handleUpload, setFile]
}
