import palette from '~/theme/dark/palette'

export default {
  styleOverrides: {
    colorPrimary: {
      '&.Mui-checked': {
        color: palette.text.primary
      }
    }
  }
}
