import React from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'

import { CardHeader } from '~/components/Cards'
import StatusField from '~/components/Fields/StatusField'
import TextField from '~/components/Fields/TextField'

function BillingProfileGeneral () {
  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12} lg={3}>
        <Card>
          <CardHeader title="Settings" />
          <Divider />
          <CardContent>
            <Grid container={true} spacing={3}>
              <Grid item={true} xs={12}>
                <StatusField />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item={true} xs={12} lg={9}>
        <Card>
          <CardHeader title="Information" />
          <Divider />
          <CardContent>
            <Grid container={true} spacing={3}>
              <Grid item={true} xs={12}>
                <TextField
                  data-cy="name"
                  name="name"
                  label="Title"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default BillingProfileGeneral
