import React, { useState } from 'react'
import { generatePath } from 'react-router-dom'
import { map, prop } from 'ramda'
import PropTypes from 'prop-types'

import { MoreListButton, NewButton } from '~/components/Buttons'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import TableDateFormat from '~/components/TableDateFormat'
import { Table, TableHeader } from '~/components/Table'
import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'
import TableLink from '~/components/Link'
import TextOverflow from '~/components/TextOverflow'

import BulkActionButton from './BulkActionButton'

import { TYPE_NAMES } from '../Fields/TaxTypeField'

const getDetailPath = guid => {
  return generatePath(ROUTES.TAX_UPDATE_PATH, { guid })
}

export const TAX_COLUMNS = [
  {
    width: 250,
    headerName: 'Name',
    field: 'name',
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        <TextOverflow selfTooltip={true}>{value}</TextOverflow>
      </TableLink>
    )
  },
  {
    width: 150,
    headerName: 'Type',
    field: 'type'
  },
  {
    width: 150,
    headerName: 'Value',
    field: 'value'
  },
  {
    width: 150,
    headerName: 'Created Date',
    field: 'createdAt',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  },
  {
    width: 150,
    headerName: 'Last updated',
    field: 'updatedAt',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  }
]

const getRowsFromResults = map(item => {
  const guid = prop('guid', item)
  const name = prop('name', item)
  const value = prop('value', item)

  const taxType = prop('type', item)
  const type = TYPE_NAMES[taxType]

  const createdAt = prop('createdAt', item)
  const updatedAt = prop('updatedAt', item)

  const link = getDetailPath(guid)

  return {
    guid,
    name,
    type,
    value,
    createdAt,
    updatedAt,
    link
  }
})

function TaxTable ({ list, ordering, onListRefetch, onTaxesDelete, bulkActionLoading }) {
  const [tableRef, setTableRef] = useState(null)
  const columns = prop('filteredColumns', ordering)
  const results = prop('results', list)
  const isLoading = prop('isLoading', list)
  const count = prop('count', list)

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={NAV.TAX}
          parentTitle={NAV.CONFIGURATION}
          rightButton={(
            <>
              <BulkActionButton onTaxesDelete={onTaxesDelete} loading={bulkActionLoading} />
              <NewButton path={ROUTES.TAX_CREATE_PATH} />
            </>
          )}
        />
      </Header>

      <Content>
        <TableHeader>
          <MoreListButton tableRef={tableRef} />
        </TableHeader>
        <Table
          isLoading={isLoading}
          columns={columns}
          getRowsFromResults={getRowsFromResults}
          results={results}
          count={count}
          primaryKey="guid"
          ordering={ordering}
          checkboxSelection={true}
          pinnedColumns={['name']}
          onRefetch={onListRefetch}
          setTableRef={setTableRef}
        />
      </Content>
    </Container>
  )
}

TaxTable.propTypes = {
  list: PropTypes.object.isRequired,
  onTaxesDelete: PropTypes.func.isRequired,
  onListRefetch: PropTypes.func.isRequired,
  ordering: PropTypes.object.isRequired,
  bulkActionLoading: PropTypes.bool
}

export default TaxTable
