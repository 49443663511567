import React from 'react'
import PropTypes from 'prop-types'

import Status from '~/components/Status'

import { CANCELLED, COMPLETED, NAMES, NEW, PROCESSING } from '../constants'

const COLORS = {
  [NEW]: 'success',
  [PROCESSING]: 'warning',
  [COMPLETED]: 'success',
  [CANCELLED]: 'error',
}

function ReturnStatus ({ value }) {
  return (
    <Status label={NAMES[value]} color={COLORS[value]} />
  )
}

ReturnStatus.propTypes = {
  value: PropTypes.string.isRequired
}

export default ReturnStatus
