import PropTypes from 'prop-types'
import React from 'react'
import { styled } from '@mui/material'
import Card from '@mui/material/Card'

const CardStyled = styled(Card, {
  shouldForwardProp:  propName => propName !== 'isDisabled' && propName !== 'active'
})(({ theme, isDisabled, active }) => ({
  transition: 'background .25s ease',
  border: active ? `1px solid ${theme.palette.secondary.main}` : '1px solid transparent',
  background: active ? `${theme.palette.secondary.main}10` : 'unset',
  cursor: 'pointer',
  '&:hover': {
    border: `1px solid ${theme.palette.secondary.main}`,
    background: `${theme.palette.secondary.main}10`,
  },
  ...(isDisabled && {
    filter: 'grayscale(100%)',
    background: 'rgba(0, 0, 0, .02)',
    '&:hover': {
      background: 'rgba(0, 0, 0, .02)'
    },
    [theme.breakpoints.down('lg')]: {
      filter: 'grayscale(90%)',
    }
  })
}))

function ChoiceCard ({ children, disabled, ...props }) {
  return (
    <CardStyled isDisabled={disabled} {...props}>
      {children}
    </CardStyled>
  )
}

ChoiceCard.propTypes = {
  children: PropTypes.any.isRequired,
  disabled: PropTypes.bool
}

export default ChoiceCard
