import React from 'react'
import { Ordering } from 'storfox-filter'

import { SHIPMENT_COLUMNS as tableColumns } from './ShipmentTable'

import { FILTER_NAME as name } from '../../constants'

export const shipmentOrderingOptions = {
  name,
  tableColumns
}

function ShipmentOrderingForm (props) {
  return (
    <Ordering {...props} />
  )
}

export default ShipmentOrderingForm
