import React from 'react'
import PropTypes from 'prop-types'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import { useTranslation } from 'react-i18next'
import { IconButton, styled } from '@mui/material'
import Box from '@mui/material/Box'
import InfoIcon from '@mui/icons-material/Info'
import Typography from '@mui/material/Typography'

import { CardHeader } from '~/components/Cards'
import HtmlTooltip from '~/components/HtmlTooltip/HtmlTooltip'

import Chart from './Chart'
import Loader from './Loader'
import OrderTotalValue from './OrderTotalValue'

import SummarizeMoney from '../SummarizeMoney'
import TopInformationWrapper from '../TopInformation/TopInformationWrapper'

const CardContentStyled = styled(CardContent)({
  padding: 0,
  '&:last-child': {
    paddingBottom: 0
  }
})

function Orders ({ items, isLoading }) {
  const { t } = useTranslation()

  const channels = items.map((item, index) => ({
    id: index,
    label: item['channel'],
    value: item['gms'],
    orders: item['orders']
  }))

  const totalValue = channels.reduce((a, b) => a + b.value, 0)

  return (
    <Card>
      <CardHeader
        title={
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h4">{t('Orders')}</Typography>
            <HtmlTooltip title="Total gross value of orders against every channel">
              <IconButton size="small">
                <InfoIcon sx={{ color: '#BDBDBD' }} />
              </IconButton>
            </HtmlTooltip>
          </Box>
        }
      />
      <Divider />
      <CardContentStyled>
        {isLoading
          ? <Loader />
          : (
            <>
              <Box sx={{ padding: '24px 24px 0 24px' }} display="flex" justifyContent="center">
                <Chart data={channels} />
              </Box>
              <Divider />
              <Box>
                <TopInformationWrapper lg={12}>
                  <OrderTotalValue
                    title="Orders value"
                    value={<SummarizeMoney value={totalValue} />}
                    percent={0}
                  />
                </TopInformationWrapper>
              </Box>
            </>
          )
        }
      </CardContentStyled>
    </Card>
  )
}

Orders.propTypes = {
  items: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default Orders
