import React, { useRef } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'
import PropTypes from 'prop-types'
import { useField } from 'react-final-form'

import { Button } from '~/components/Buttons'
import TextField from '~/components/Fields/TextField'
import { withForm } from '~/components/Form'
import { Scanner } from '~/components/Icons'

function ContainerBarcodeScanDialog ({ handleClose, open, form }) {
  const barcodeRef = useRef(null)
  const containerNumberField = useField('containerNumber')
  const containerNumber = containerNumberField.input.value

  const { handleSubmit } = form

  const handleBarcodeFieldFocus = event => {
    if (event.key === 'Enter') {
      event.preventDefault()
      barcodeRef.current.focus()
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      fullWidth={true}
    >
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12}>
              <TextField
                autoFocus={true}
                name="containerNumber"
                label="Enter Container ID"
                data-cy="containerId"
                onKeyDown={handleBarcodeFieldFocus}
              />
            </Grid>

            <Grid item={true} xs={12}>
              <TextField
                name="barcode"
                label="Enter variant barcode"
                data-cy="barcode"
                inputRef={barcodeRef}
                disabled={!containerNumber}
              />
            </Grid>

            <Grid
              item={true}
              xs={12}
              sx={{
                padding: '50px',
                display:'flex',
                justifyContent:'center'
              }}
            >
              <Scanner />
            </Grid>
            <Grid
              item={true}
              xs={12}
              sx={{
                display:'flex',
                justifyContent:'center'
              }}
            >
              <Button type="submit">
                Scan container ID
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ margin: 'auto' }}>
        <Button onClick={handleClose} >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ContainerBarcodeScanDialog.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  form: PropTypes.object.isRequired
}

export default withForm(ContainerBarcodeScanDialog)
