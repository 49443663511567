import React from 'react'
import { FieldWrapper } from 'storfox-form-fields'
import { defaultTo, map, path, pathOr, pipe, prop, propOr, reduce } from 'ramda'
import PropTypes from 'prop-types'

import useDialog from '~/hooks/useDialog'
import { emptyArray } from '~/constants/empty'

import CountableBarcode from './CountableBarcode'

import DialogForm from '../AdjustmentForm/DialogForm'

const getItemsError = pipe(
  propOr([], 'lineItems'),
  map(pathOr([], ['units'])),
  prop(0)
)

const getItems = pipe(
  defaultTo(emptyArray),
  map(value => ({
    ...value,
    location: path(['unit', 'location'], value)
  }))
)

function UnitsField (props) {
  const {
    id,
    item,
    units,
    ownerId,
    input,
    meta,
    condition,
    removeOperation,
    reasonId,
    warehouseId,
    warehouseGuid,
    unitFieldMethods,
    buttonDataCy
  } = props

  const { unitCheckLoading, handleUnitCheck, handleBarcodeCheck } = unitFieldMethods

  const { open, handleClose, handleOpen } = useDialog()
  const items = getItems(input.value)

  const count = reduce((acc, item) => acc + item.quantity, 0, items)
  const variant = prop('variant', item)
  const trackSerial = prop('trackSerialNumbers', variant)
  const trackBatch = prop('trackBatchNumbers', variant)

  const image = prop('defaultImage', variant)
  const title = prop('name', variant)
  const sku = prop('sku', variant)
  const barcode = prop('barcode', variant)
  const dialogTitle = { image, title, sku, barcode }

  const handleUnitNumberCheck = unitNumber => {
    const data = { unitNumber, removeOperation, variant }

    return handleUnitCheck(data, warehouseGuid)
  }

  const handleBarcode = value => {
    const items = prop('items', value)
    const lineItems = [{ ...item, units: items, productionDate: null }]
    const data = { id, warehouseId, reasonId, lineItems, ownerId, removeOperation }

    return handleBarcodeCheck(data)
      .then(() => input.onChange(items))
      .then(() => handleClose())
      .catch(error => {
        const err = { ...error, items: getItemsError(error) }

        return Promise.reject(err)
      })
  }

  return (
    <>
      <CountableBarcode
        count={count}
        onOpen={handleOpen}
        isError={meta.invalid}
        data-cy={buttonDataCy}
      />
      <DialogForm
        open={open}
        isLoading={unitCheckLoading}
        trackSerial={trackSerial}
        condition={condition}
        units={units}
        initialValues={{ items, open }}
        dialogTitle={dialogTitle}
        ownerId={ownerId}
        removeOperation={removeOperation}
        onClose={handleClose}
        onUnitNumberCheck={handleUnitNumberCheck}
        onSubmit={handleBarcode}
        trackBatch={trackBatch}
      />
    </>
  )
}

UnitsField.propTypes = {
  id: PropTypes.number,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  removeOperation: PropTypes.bool.isRequired,
  unitFieldMethods: PropTypes.shape({
    unitCheckLoading: PropTypes.bool.isRequired,
    handleUnitCheck: PropTypes.func.isRequired,
    handleBarcodeCheck: PropTypes.func.isRequired
  }),
  ownerId: PropTypes.number.isRequired,
  units: PropTypes.array,
  condition: PropTypes.object,
  reasonId: PropTypes.number,
  warehouseId: PropTypes.number,
  warehouseGuid: PropTypes.string,
  buttonDataCy: PropTypes.string
}

export default FieldWrapper(UnitsField)
