import { map, pick, prop, propOr, trim } from 'ramda'
import { DEFAULT_SEARCH_PARAMS } from 'storfox-route-hooks'

import { escapeAtob } from '~/utils'

import { VARIANT_UPDATE_FIELDS } from '../components/VariantUpdate'

const getVariants = map(guid => ({ guid }))
const getChannels = map(guid => ({ guid }))

export function VariantUpdateSerializer (formValues) {
  const values = pick(VARIANT_UPDATE_FIELDS, formValues)
  const barcodeValue = prop('barcode', values)
  const barcode = barcodeValue && trim(barcodeValue)
  const skuValue = prop('sku', values)
  const sku = skuValue && trim(skuValue)
  const options = propOr([], 'options', formValues)
  const clearedOptions = options.filter(item => Boolean(item.option) && Boolean(item.name))
  return { ...values, barcode, sku, options: clearedOptions }
}

export function ProductImageUpdateSerializer (uploadedImage, ordering) {
  return { imagePath: uploadedImage.imagePath, ordering: ordering }
}

export function VariantListingAddSerializer (selectedGuids, channelGuids) {
  const variants = getVariants(selectedGuids)
  const channels = getChannels(channelGuids)

  return {
    channels,
    variants
  }
}

export function ConditionPricesSerializer (id, values) {
  return {
    prices: values,
    variant: { id: parseInt(id) }
  }
}

export const VariantFilterSerializer = params => {
  const defaultParams = pick(DEFAULT_SEARCH_PARAMS, params)
  const categoryIds = escapeAtob(prop('categories', params))
  const brandIds = escapeAtob(prop('brands', params))
  const companyId = escapeAtob(prop('company', params))
  const supplierPriceStart = prop('supplierPriceStart', params)
  const supplierPriceEnd = prop('supplierPriceEnd', params)
  const retailPriceStart = prop('retailPriceStart', params)
  const retailPriceEnd = prop('retailPriceEnd', params)
  const maximumRetailPriceStart = prop('maximumRetailPriceStart', params)
  const maximumRetailPriceEnd = prop('maximumRetailPriceEnd', params)
  const availableStart = prop('availableStart', params)
  const availableEnd = prop('availableEnd', params)
  const onHandStart = prop('onHandStart', params)
  const onHandEnd = prop('onHandEnd', params)
  const allocatedStart = prop('allocatedStart', params)
  const allocatedEnd = prop('allocatedEnd', params)
  const awaitingStart = prop('awaitingStart', params)
  const awaitingEnd = prop('awaitingEnd', params)
  const inTransferStart = prop('inTransferStart', params)
  const inTransferEnd = prop('inTransferEnd', params)

  return {
    ...defaultParams,
    categoryIds,
    brandIds,
    companyId,
    supplierPriceStart,
    supplierPriceEnd,
    retailPriceStart,
    retailPriceEnd,
    maximumRetailPriceStart,
    maximumRetailPriceEnd,
    availableStart,
    availableEnd,
    onHandStart,
    onHandEnd,
    allocatedStart,
    allocatedEnd,
    awaitingStart,
    awaitingEnd,
    inTransferStart,
    inTransferEnd
  }
}

export const VariantUpdateInitialSerializer = (data) => {
  const barcodeAliases = propOr([], 'barcodeAliases', data)
  const barcode = prop('barcode', data)
  const aliasesWithoutDefaultBarcode = barcodeAliases.filter(alias => alias !== barcode)
  return { ...data, barcodeAliases: aliasesWithoutDefaultBarcode }
}
