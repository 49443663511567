import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Status, { ERROR, SUCCESS, WARNING } from '../Status/Status'

export const UNSHIPPED = 'unshipped'
export const PRE_TRANSIT = 'pre_transit'
export const IN_TRANSIT = 'in_transit'
export const DELIVERED = 'delivered'
export const RETURNED = 'returned'
export const FAILED = 'failed'
export const CANCELLED = 'cancelled'

export const SHIPMENT_STATUS = {
  UNSHIPPED,
  PRE_TRANSIT,
  IN_TRANSIT,
  DELIVERED,
  RETURNED,
  FAILED,
  CANCELLED,
}

export const SHIPMENT_STATUS_LABEL = {
  [UNSHIPPED]: 'Unshipped',
  [PRE_TRANSIT]: 'Pre transit',
  [IN_TRANSIT]: 'In transit',
  [DELIVERED]: 'Delivered',
  [RETURNED]: 'Returned',
  [FAILED]: 'Failed',
  [CANCELLED]: 'Cancelled'
}

const STATUS_COLOR = {
  [SHIPMENT_STATUS.UNSHIPPED]: ERROR,
  [SHIPMENT_STATUS.PRE_TRANSIT]: WARNING,
  [SHIPMENT_STATUS.IN_TRANSIT]: WARNING,
  [SHIPMENT_STATUS.DELIVERED]: SUCCESS,
  [SHIPMENT_STATUS.RETURNED]: SUCCESS,
  [SHIPMENT_STATUS.FAILED]: ERROR,
  [SHIPMENT_STATUS.CANCELLED]: ERROR,
}

function ShipmentStatus ({ value }) {
  const { t } = useTranslation()

  return (
    <Status
      label={t(SHIPMENT_STATUS_LABEL[value])}
      color={STATUS_COLOR[value]}
    />
  )
}

ShipmentStatus.propTypes = {
  value: PropTypes.string.isRequired
}

export default ShipmentStatus
