import React from 'react'
import PropTypes from 'prop-types'
import AceEditor from 'react-ace'
import 'ace-builds/src-noconflict/mode-xml'
import 'ace-builds/src-noconflict/theme-tomorrow'
import 'ace-builds/src-noconflict/ext-language_tools'
import { useField } from 'react-final-form'

function DecoupledDocumentEditor ({ initialData, ...props }) {
  const html = useField('htmlBody')
  const onChangeHtml = html.input.onChange
  return (
    <AceEditor
      onChange={onChangeHtml}
      style={{ zIndex: 1 }}
      width="unset"
      value={html.input.value}
      mode="xml"
      theme="tomorrow"
      setOptions={{
        enableBasicAutocompletion: false,
        enableLiveAutocompletion: false,
        enableSnippets: false,
        showLineNumbers: true,
        tabSize: 2,
      }}
      {...props}
    />
  )
}

DecoupledDocumentEditor.propTypes = {
  initialData: PropTypes.string
}

export default DecoupledDocumentEditor
