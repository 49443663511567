import React from 'react'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material'

const RootStyled = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  height: '530px',
  width: '100%'
})

function SupplierEmptyContent ({ icon, text, ...props }) {
  return (
    <RootStyled {...props}>
      {icon}
      <Box mb={1} />
      <Typography>{text}</Typography>
    </RootStyled>
  )
}

export default SupplierEmptyContent

SupplierEmptyContent.propTypes = {
  icon: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired
}
