import { useCallback, useState } from 'react'
import { pathOr } from 'ramda'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

export function useFileUpload ({ onUpload, setAdditionalData }) {
  const [file, setFile] = useState({})
  const snackbar = useSnackbar()

  const handleUpload = useCallback(acceptedFiles => {
    const fileSize = pathOr(0, [0, 'size'], acceptedFiles)
    if (fileSize <= 8000000) {
      const newItem = {
        obj: acceptedFiles[0],
        start: true,
        finish: false,
        progress: 0
      }

      setFile(newItem)
      const data = new FormData()
      data.append('file', newItem.obj)
      const additionalData = setAdditionalData ? setAdditionalData(data) : data

      onUpload(additionalData, progressEvent => {
        const loaded = progressEvent.loaded
        const total = progressEvent.total
        const progress = Math.round((loaded * 100) / total)
        setFile(file => ({ ...file, progress }))
      })
        .then(() => {
          setFile(file => ({ ...file, finish: true }))
        })
    } else {
      snackbar({ message: 'File size is too large, should be less than 8MB', type: ALTER_ERROR })
    }
  }, [onUpload, snackbar, setAdditionalData])

  return [file, handleUpload, setFile]
}
