import React, { useCallback, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import { useField } from 'react-final-form'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import VerificationInput from 'react-verification-input'

import { withForm } from '~/components/Form'
import { Button } from '~/components/Buttons'

function Auth2FactorForm ({ isLoading, form, onResend }) {
  const { handleSubmit } = form
  const { t } = useTranslation()
  const [resendTimer, setResendTimer] = useState(60000)

  const codeField = useField('code')
  const codeFieldChange = codeField.input.onChange

  const handleResend = useCallback(() => {
    if (!resendTimer) {
      onResend().then(() => {
        setResendTimer(60000)
      })
    }
  }, [resendTimer, onResend])

  useEffect(() => {
    if (resendTimer) {
      setTimeout(() => setResendTimer(prev => prev - 1000), 1000)
    }
  }, [resendTimer])

  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} lg={12}>
        <VerificationInput
          placeholder="_"
          inputProps={{ inputMode: 'numeric' }}
          validChars="0-9"
          onChange={codeFieldChange}
        />
      </Grid>
      <Grid item={true} xs={12}>
        <Button
          type="submit"
          size="large"
          data-cy="submit"
          variant="contained"
          fullWidth={true}
          loading={isLoading}
          onClick={handleSubmit}
        >
          {t('Verify')}
        </Button>
      </Grid>
      <Grid item={true} lg={12} sx={{ justifyContent: 'center !important' }}>
        <Typography variant="h6" sx={{ textAlign: 'center', width: '75%' }}>
          <span>{t('Don\'t see the code in your inbox? Click here to')}</span>{' '}
          <span
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={handleResend}
          >
            {t('resend the code')}
          </span>
          {' '}
          {resendTimer ? `in ${resendTimer / 1000} seconds` : ''}
          {' '}
          <span>{t('or contact')}</span>{' '}
          <span style={{ textDecoration: 'underline' }}>{t('support@storfox.com')}</span>
        </Typography>
      </Grid>
    </Grid>
  )
}

Auth2FactorForm.propTypes = {
  form: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  onResend: PropTypes.func.isRequired
}

export default withForm(Auth2FactorForm)
