import React, { useState } from 'react'
import { map, path, prop } from 'ramda'
import PropTypes from 'prop-types'
import { generatePath } from 'react-router-dom'

import { MoreListButton, NewButton } from '~/components/Buttons'
import Container, { Content, Header } from '~/components/Container'
import PageTitle from '~/components/PageTitle'
import TableDateFormat from '~/components/TableDateFormat'
import { Table, TableHeader } from '~/components/Table'
import * as ROUTES from '~/constants/routes'
import TableLink from '~/components/Link'

import StockCountStatus from './StockCountStatus'

const getUpdatePath = guid => {
  return generatePath(ROUTES.STOCK_COUNT_UPDATE_PATH, { guid })
}

export const STOCK_COUNT_COLUMNS = [
  {
    width: 200,
    headerName: 'Number',
    field: 'number',
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        {value}
      </TableLink>
    )
  },
  {
    width: 200,
    headerName: 'Status',
    field: 'status',
    renderCell: ({ value }) => (
      <StockCountStatus value={value} />
    )
  },
  {
    width: 400,
    headerName: 'Warehouse',
    field: 'warehouse'
  },
  {
    width: 400,
    headerName: 'Created date',
    field: 'createdAt',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  },
]

const getRowsFromResults = map(item => {
  const guid = prop('guid', item)
  const createdAt = prop('createdAt', item)
  const number = prop('number', item)
  const status = prop('status', item)
  const warehouse = path(['warehouse', 'name'], item)
  const link = getUpdatePath(guid)

  return { guid, number, warehouse, status, createdAt, link }
})

function StockCountTable ({ list, filter, ordering, onListRefetch }) {
  const [tableRef, setTableRef] = useState(null)
  const results = prop('results', list)
  const count = prop('count', list)
  const isLoading = prop('isLoading', list)
  const columns = prop('filteredColumns', ordering)

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle="Stock Count"
          parentTitle="Stock"
          rightButton={(
            <NewButton path={ROUTES.STOCK_COUNT_CREATE_PATH} />
          )}
        />
      </Header>
      <Content>
        <TableHeader
          filter={filter}
          filterOpen={filter.handleOpen}
          orderingOpen={ordering.handleOpen}
          ordering={ordering}
        >
          <MoreListButton tableRef={tableRef} />
        </TableHeader>
        <Table
          columns={columns}
          getRowsFromResults={getRowsFromResults}
          results={results}
          isLoading={isLoading}
          checkboxSelection={true}
          count={count}
          pinnedColumns={['number']}
          primaryKey="guid"
          ordering={ordering}
          onRefetch={onListRefetch}
          setTableRef={setTableRef}
        />
      </Content>
    </Container>
  )
}

StockCountTable.propTypes = {
  list: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  onListRefetch: PropTypes.func.isRequired
}

export default StockCountTable
