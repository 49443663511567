import PropTypes from 'prop-types'
import React, { useState } from 'react'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import MenuIcon from '@mui/icons-material/Menu'
import ReorderIcon from '@mui/icons-material/Reorder'
import MenuItem from '@mui/material/MenuItem'
import { Menu } from '@mui/material'
import Box from '@mui/material/Box'
import ListItemText from '@mui/material/ListItemText'
import { useTranslation } from 'react-i18next'

import IconText from '~/components/IconText'

const COMPACT = 'compact'
const STANDARD = 'standard'
const COMFORTABLE = 'comfortable'

const Icons = {
  [COMPACT]: DragHandleIcon,
  [STANDARD]: MenuIcon,
  [COMFORTABLE]: ReorderIcon
}

function DensitySelector ({ anchorEl, onMenuClose, tableRef }) {
  const [currentDensity, setCurrentDensity] = useState(STANDARD)

  const { t } = useTranslation()
  const open = Boolean(anchorEl)

  const handleDensityChange = value => {
    tableRef.current.setDensity(value)
    setCurrentDensity(value)
  }

  return (
    <>
      <Box>
        <IconText Icon={Icons[currentDensity]}>
          <ListItemText primary={t('Density')} />
        </IconText>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onMenuClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <MenuItem onClick={() => handleDensityChange(COMPACT)}>
          <IconText Icon={DragHandleIcon}>
            <ListItemText primary={t('Compact')} />
          </IconText>
        </MenuItem>
        <MenuItem onClick={() => handleDensityChange(STANDARD)}>
          <IconText Icon={MenuIcon}>
            <ListItemText primary={t('Standard')} />
          </IconText>
        </MenuItem>
        <MenuItem onClick={() => handleDensityChange(COMFORTABLE)}>
          <IconText Icon={ReorderIcon}>
            <ListItemText primary={t('Comfortable')} />
          </IconText>
        </MenuItem>
      </Menu>
    </>
  )
}

DensitySelector.propTypes = {
  onMenuClose: PropTypes.any.isRequired,
  anchorEl: PropTypes.any,
  tableRef: PropTypes.object
}

export default DensitySelector
