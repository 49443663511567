import { useState } from 'react'
import { filter, find } from 'ramda'

export const useChannelSelect = () => {
  const [selectedChannelGuids, setSelectedChannelGuids] = useState([])

  const handleChannelSelect = guid => {
    const channel = find(channelGuid => channelGuid === guid, selectedChannelGuids)
    if (channel) {
      const newSelectedChannels = filter(channelGuid => channelGuid !== guid, selectedChannelGuids)
      setSelectedChannelGuids(newSelectedChannels)
    } else {
      const newSelectedChannels = [...selectedChannelGuids, guid]
      setSelectedChannelGuids(newSelectedChannels)
    }
  }

  return {
    selectedChannelGuids,
    handleChannelSelect
  }
}
