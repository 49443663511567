import React from 'react'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { path, pathOr, prop } from 'ramda'
import PropTypes from 'prop-types'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import TableContainer from '@mui/material/TableContainer'
import { generatePath, useNavigate } from 'react-router-dom'
import { styled } from '@mui/material'

import { toDuration } from '~/utils'
import EmptyNotesIcon from '~/components/Icons/EmptyNotesIcon'
import EmptyLineItems from '~/components/EmptyLineItems/EmptyLineItems'
import SkeletonList from '~/components/Skeleton'
import OrderProgress from '~/components/OrderProgress'
import TableDateFormat from '~/components/TableDateFormat/TableDateFormat'
import { PACKING_DETAIL_TABS } from '~/constants/tabs'
import * as ROUTES from '~/constants/routes'
import PackingStatus from '~/components/Statuses/PackingStatus'
import { HoverableRow } from '~/components/Table'

const TableRowStyled = styled(HoverableRow)({
  height: '70px'
})

const TableCellStyled = styled(TableCell)({
  minWidth: '150px'
})

function SaleOrderPackingTable ({ packingList }) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const isLoading = prop('isLoading', packingList)
  const results = prop('results', packingList)

  const renderEmptyLines = Boolean(!isLoading && !results.length)
  const renderLineItems = Boolean(!isLoading && results.length)

  return (
    <Box component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto' }}>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCellStyled>{t('Reference #')}</TableCellStyled>
              <TableCellStyled>{t('Line items')}</TableCellStyled>
              <TableCellStyled>{t('Qty')}</TableCellStyled>
              <TableCellStyled>{t('Picked Qty')}</TableCellStyled>
              <TableCellStyled>{t('Progress')}</TableCellStyled>
              <TableCellStyled>{t('Status')}</TableCellStyled>
              <TableCellStyled>{t('Packer')}</TableCellStyled>
              <TableCellStyled>{t('Packages')}</TableCellStyled>
              <TableCellStyled>{t('Method')}</TableCellStyled>
              <TableCellStyled>{t('Created at')}</TableCellStyled>
              <TableCellStyled>{t('Updated at')}</TableCellStyled>
              <TableCellStyled>{t('Service')}</TableCellStyled>
              <TableCellStyled>{t('Duration')}</TableCellStyled>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <SkeletonList
                rowCount={3}
                columnCount={13}
                height={20}
                rowHeight={70}
                columnWidth={165.39}
              />
            )}

            {renderEmptyLines && (
              <EmptyLineItems
                colSpan={13}
                icon={<EmptyNotesIcon />}
                text="No packing by sale order"
              />
            )}
            {renderLineItems && results.map((row, index) => {
              const id = prop('id', row)
              const referenceNumber = prop('toObjectReferenceNumber', row)
              const lineItems = prop('fromObjectLineItemsCount', row)
              const totalQuantity = prop('totalQuantity', row)
              const pickedQuantity = prop('pickedQuantity', row)
              const packedCount = prop('packedCount', row)
              const status = prop('status', row)
              const packages = prop('containersCount', row)
              const packerFirstName = pathOr('', ['packer', 'firstName'], row)
              const packerLastName = pathOr('', ['packer', 'lastName'], row)
              const method = path(['shippingMethod', 'name'], row)
              const service = path(['service', 'name'], row)
              const duration = toDuration(prop('duration', row))

              const packingUrl = generatePath(ROUTES.PACKING_DETAIL_PATH, { id, tab: PACKING_DETAIL_TABS.GENERAL })

              return (
                <TableRowStyled key={index} onClick={() => navigate(packingUrl)}>
                  <TableCell>{referenceNumber}</TableCell>
                  <TableCell>{lineItems}</TableCell>
                  <TableCell>{totalQuantity}</TableCell>
                  <TableCell>{pickedQuantity}</TableCell>
                  <TableCell>
                    <OrderProgress ordered={pickedQuantity} received={packedCount} />
                  </TableCell>
                  <TableCell>
                    <PackingStatus value={status} />
                  </TableCell>
                  <TableCell>{`${packerFirstName} ${packerLastName}`}</TableCell>
                  <TableCell>{packages}</TableCell>
                  <TableCell>{method}</TableCell>
                  <TableCell>
                    <TableDateFormat withTime={true} date={prop('createdAt', row)} />
                  </TableCell>
                  <TableCell>
                    <TableDateFormat withTime={true} date={prop('updatedAt', row)} />
                  </TableCell>
                  <TableCell>{service}</TableCell>
                  <TableCell>{duration}</TableCell>
                </TableRowStyled>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

SaleOrderPackingTable.propTypes = {
  packingList: PropTypes.object.isRequired
}

export default SaleOrderPackingTable
