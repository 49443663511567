import React from 'react'
import { Filter } from 'storfox-filter'
import Grid from '@mui/material/Grid'
import { DateTimeField, PositiveFloatField } from 'storfox-form-fields'
import { prop } from 'ramda'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

import { escapeAtob, unescapeBtoa } from '~/utils'
import WarehouseMultiSelectField from '~/components/Fields/WarehouseMultiSelectField'
import Accordion from '~/components/Accordion'
import TagsField from '~/components/Fields/TagsField'
import { getDateTimeGMTDifference, getDateTimeGMTUser } from '~/utils/date'

import ShipmentItemField from './ShipmentItemField'
import ShipmentCustomerField from './ShipmentCustomerField'
import ShipmentStatusField from './ShipmentStatusField'
import ShipmentDirectionField from './ShipmentDirectionField'
import ShipmentCompanyList from './ShipmentCompanyList'
import TrackingStatusField from './TrackingStatusField'

import { FILTER_NAME as name } from '../../constants'

export const toParams = values => {
  const direction = prop('direction', values)
  const companyObject = prop('company', values)
  const status = prop('status', values)
  const isTracking = prop('isTracking', values)

  const objects = unescapeBtoa(prop('objects', values))
  const customers = unescapeBtoa(prop('customers', values))
  const warehouses = unescapeBtoa(prop('warehouses', values))
  const company = unescapeBtoa(companyObject)

  const shippedDateStart = getDateTimeGMTDifference(prop('shippedDateStart', values))
  const shippedDateEnd = getDateTimeGMTDifference(prop('shippedDateEnd', values))
  const deliveredDateStart = getDateTimeGMTDifference(prop('deliveredDateStart', values))
  const deliveredDateEnd = getDateTimeGMTDifference(prop('deliveredDateEnd', values))
  const createdAtStart = getDateTimeGMTDifference(prop('createdAtStart', values))
  const createdAtEnd = getDateTimeGMTDifference(prop('createdAtEnd', values))
  const updatedAtStart = getDateTimeGMTDifference(prop('updatedAtStart', values))
  const updatedAtEnd = getDateTimeGMTDifference(prop('updatedAtEnd', values))
  const orderedAtStart = getDateTimeGMTDifference(prop('orderedAtStart', values))
  const orderedAtEnd = getDateTimeGMTDifference(prop('orderedAtEnd', values))

  const trackingNumbersList = prop('trackingNumbers', values)
  const trackingNumbers = trackingNumbersList ? trackingNumbersList.join(',') : null

  const totalPriceMin = prop('totalPriceMin', values)
  const totalPriceMax = prop('totalPriceMax', values)

  return {
    direction,
    objects,
    status,
    customers,
    warehouses,
    orderedAtStart,
    orderedAtEnd,
    shippedDateStart,
    shippedDateEnd,
    deliveredDateStart,
    deliveredDateEnd,
    createdAtStart,
    createdAtEnd,
    updatedAtStart,
    updatedAtEnd,
    totalPriceMin,
    totalPriceMax,
    company,
    trackingNumbers,
    isTracking
  }
}

export const toValues = params => {
  const direction = prop('direction', params)
  const objects = escapeAtob(prop('objects', params))
  const status = prop('status', params)
  const isTracking = prop('isTracking', params)
  const customers = escapeAtob(prop('customers', params))
  const warehouses = escapeAtob(prop('warehouses', params))
  const company = escapeAtob(prop('company', params))

  const shippedDateStart = getDateTimeGMTUser(prop('shippedDateStart', params))
  const shippedDateEnd = getDateTimeGMTUser(prop('shippedDateEnd', params))
  const deliveredDateStart = getDateTimeGMTUser(prop('deliveredDateStart', params))
  const deliveredDateEnd = getDateTimeGMTUser(prop('deliveredDateEnd', params))
  const createdAtStart = getDateTimeGMTUser(prop('createdAtStart', params))
  const createdAtEnd = getDateTimeGMTUser(prop('createdAtEnd', params))
  const updatedAtStart = getDateTimeGMTUser(prop('updatedAtStart', params))
  const updatedAtEnd = getDateTimeGMTUser(prop('updatedAtEnd', params))
  const orderedAtStart = getDateTimeGMTUser(prop('orderedAtStart', params))
  const orderedAtEnd = getDateTimeGMTUser(prop('orderedAtEnd', params))

  const totalPriceMin = prop('totalPriceMin', params)
  const totalPriceMax = prop('totalPriceMax', params)

  const trackingNumbersList = prop('trackingNumbers', params)
  const trackingNumbers = trackingNumbersList ? trackingNumbersList.split(',') : null

  return {
    direction,
    objects,
    status,
    customers,
    warehouses,
    orderedAtStart,
    orderedAtEnd,
    shippedDateStart,
    shippedDateEnd,
    deliveredDateStart,
    deliveredDateEnd,
    createdAtStart,
    createdAtEnd,
    updatedAtStart,
    updatedAtEnd,
    totalPriceMin,
    totalPriceMax,
    company,
    trackingNumbers,
    isTracking
  }
}
export const transformer = { toParams, toValues }

export const shipmentFilterOptions = {
  name,
  transformer
}

function ShipmentFilterForm (props) {
  const { t } = useTranslation()
  return (
    <Filter {...props}>
      <Accordion title="General" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <ShipmentDirectionField />
          </Grid>
          <Grid item={true} xs={12}>
            <ShipmentItemField />
          </Grid>
          <Grid item={true} xs={12}>
            <ShipmentStatusField />
          </Grid>
          <Grid item={true} xs={12}>
            <ShipmentCustomerField />
          </Grid>
          <Grid item={true} xs={12}>
            <WarehouseMultiSelectField />
          </Grid>
          <Grid item={true} xs={12}>
            <ShipmentCompanyList />
          </Grid>
          <Grid item={true} xs={12}>
            <TagsField
              data-cy="trackingNumbers"
              label="Tracking number"
              name="trackingNumbers"
            />
          </Grid>
          <Grid item={true} xs={12}>
            <TrackingStatusField />
          </Grid>
        </Grid>
      </Accordion>
      <Accordion title="Date">
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <Typography variant="body2" gutterBottom={true}>
              {t('Ordered')}:
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <DateTimeField name="orderedAtStart" label="Start" />
              </Grid>
              <Grid item={true} xs={12}>
                <DateTimeField name="orderedAtEnd" label="End" />
              </Grid>
            </Grid>
          </Grid>

          <Grid item={true} xs={12}>
            <Typography variant="body2" gutterBottom={true}>
              {t('Shipped')}:
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <DateTimeField name="shippedDateStart" label="Start" />
              </Grid>
              <Grid item={true} xs={12}>
                <DateTimeField name="shippedDateEnd" label="End" />
              </Grid>
            </Grid>
          </Grid>

          <Grid item={true} xs={12}>
            <Typography variant="body2" gutterBottom={true}>
              {t('Delivered')}:
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <DateTimeField name="deliveredDateStart" label="Start" />
              </Grid>
              <Grid item={true} xs={12}>
                <DateTimeField name="deliveredDateEnd" label="End" />
              </Grid>
            </Grid>
          </Grid>

          <Grid item={true} xs={12}>
            <Typography variant="body2" gutterBottom={true}>
              {t('Created')}:
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <DateTimeField name="createdAtStart" label="Start" />
              </Grid>
              <Grid item={true} xs={12}>
                <DateTimeField name="createdAtEnd" label="End" />
              </Grid>
            </Grid>
          </Grid>

          <Grid item={true} xs={12}>
            <Typography variant="body2" gutterBottom={true}>
              {t('Updated')}:
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <DateTimeField name="updatedAtStart" label="Start" />
              </Grid>
              <Grid item={true} xs={12}>
                <DateTimeField name="updatedAtEnd" label="End" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Accordion>
      <Accordion title="Total ($)">
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={6}>
            <PositiveFloatField
              data-cy="totalPriceMin"
              name="totalPriceMin"
              label="Min ($)"
            />
          </Grid>
          <Grid item={true} xs={6}>
            <PositiveFloatField
              data-cy="totalPriceMax"
              name="totalPriceMax"
              label="Max ($)"
            />
          </Grid>
        </Grid>
      </Accordion>
    </Filter>
  )
}

export default ShipmentFilterForm
