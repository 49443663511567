import React from 'react'
import { useTranslation } from 'react-i18next'
import SelectField from 'storfox-form-fields/src/SelectField'

export const INVOICING = '3'
export const REIMBURSEMENT = '4'

const options = [
  { value: INVOICING, name: 'Invoicing' },
  { value: REIMBURSEMENT, name: 'Reimbursement' },
]

function ChargesModeField ({ ...props }) {
  const { t } = useTranslation()

  return (
    <SelectField
      name="configuration.chargesModeId"
      label={t('Charges mode')}
      options={options}
      {...props}
    />
  )
}

export default ChargesModeField
