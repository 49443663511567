import React from 'react'

import SelectField from '~/components/Fields/SelectField'

const OPTIONS = [
  { value: null, name: 'All' },
  { value: 'new', name: 'New' },
  { value: 'closed', name: 'Closed' },
  { value: 'cancelled', name: 'Cancelled' },
  { value: 'progress', name: 'Progress' }
]

function PurchaseOrderStatusField (props) {
  return (
    <SelectField
      name="status"
      label="Status"
      options={OPTIONS}
      {...props}
    />
  )
}

export default PurchaseOrderStatusField
