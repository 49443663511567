import React from 'react'
import PropTypes from 'prop-types'

import { GROUPED_ORDER, PER_ORDER } from '../../Fields/PickingStrategyField'

const NAMES = {
  [PER_ORDER]: 'Create one picklist per order.',
  [GROUPED_ORDER]: 'Merge multiple orders to create one picklist.',
}

function PickingStrategy ({ value }) {
  return (
    <>{NAMES[value]}</>
  )
}

PickingStrategy.propTypes = {
  value: PropTypes.string.isRequired
}

export default PickingStrategy
