import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import { Card, Divider } from '@mui/material'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import { useField } from 'react-final-form'

import { DiscardButton, SaveButton } from '~/components/Buttons'
import Container, { Content, Header } from '~/components/Container'
import PageTitle from '~/components/PageTitle'
import * as NAV from '~/constants/nav-titles'
import LinkButton from '~/components/Buttons/LinkButton'
import { withForm } from '~/components/Form'
import TextField from '~/components/Fields/TextField'

import SallaCreateInstruction from './SallaCreateInstruction'

import SallaCreateType, { SALLA_APP_TYPE, SALLA_TYPE } from '../SallaCreateType'
import SallaInstructionCard from '../SallaInstructionCard'

function SallaInstruction ({ form, isLoading }) {
  const { handleSubmit } = form

  const appIdField = useField('appId')
  const appIdFieldValue = appIdField.input.value

  const typeField = useField('type')
  const typeFieldValue = typeField.input.value

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            parentTitle={NAV.INTEGRATION}
            pageTitle="New integration"
            rightButton={(
              <>
                <DiscardButton />
                {typeFieldValue === SALLA_TYPE && (
                  <LinkButton href={`https://s.salla.sa/apps/install/${appIdFieldValue}`} disabled={!appIdFieldValue}>
                    Install
                  </LinkButton>
                )}
                {typeFieldValue === SALLA_APP_TYPE && (
                  <SaveButton disabled={isLoading} />
                )}
              </>
            )}
          />
        </Header>
        <Content>
          <Grid container={true} spacing={3}>
            {typeFieldValue === SALLA_APP_TYPE && (
              <Grid item={true} xs={8}>
                <Box mb={3}>
                  <Card>
                    <CardHeader title="Instruction" />
                    <Divider />
                    <CardContent>
                      <Grid container={true} spacing={3}>
                        <SallaCreateInstruction />
                      </Grid>
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
            )}
            {typeFieldValue === SALLA_TYPE && (
              <Grid item={true} xs={8}>
                <Box mb={3}>
                  <Card>
                    <CardHeader title="Instruction" />
                    <Divider />
                    <CardContent>
                      <Grid item={true} xs={12}>
                        <SallaInstructionCard completedTasks={0} />
                      </Grid>
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
            )}
            <Grid item={true} xs={4}>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <Box mb={3}>
                    <Card>
                      <CardHeader title="Settings" />
                      <Divider />
                      <CardContent>
                        <Grid container={true} spacing={3}>
                          <Grid item={true} xs={12}>
                            <SallaCreateType />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Box>
                </Grid>
                {typeFieldValue === SALLA_TYPE && (
                  <Grid item={true} xs={12}>
                    <Box mb={3}>
                      <Card>
                        <CardHeader title="Application" />
                        <Divider />
                        <CardContent>
                          <Grid container={true} spacing={3}>
                            <Grid item={true} xs={12}>
                              <TextField
                                data-cy="appId"
                                name="appId"
                                label="ID"
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Content>
      </Container>
    </form>
  )
}

SallaInstruction.propTypes = {
  form: PropTypes.object,
  handleSubmit: PropTypes.func,
  isLoading: PropTypes.bool
}

export default withForm(SallaInstruction)
