import React from 'react'

import SelectField from '~/components/Fields/SelectField'

const OPTIONS = [
  {
    value: '01',
    name: 'Pick up at door'
  },
  {
    value: '02',
    name: 'Store delivery'
  }
]

function ServiceTypeField (props) {
  return (
    <SelectField
      name="configuration.serviceType"
      label="Service Type"
      options={OPTIONS}
      {...props}
    />
  )
}

export default ServiceTypeField
