import React from 'react'
import isEqual from 'react-fast-compare'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import MUITextField from '@mui/material/TextField'

function EmailField ({ name, ...props }) {
  return (
    <Field name={name}>
      {({ input, meta }) => {
        return (
          <MUITextField
            {...props}
            error={meta.invalid}
            helperText={meta.submitError || meta.error}
            value={input.value}
            onChange={event => {
              const value = event.target.value
              const trimmedValue = value.trim()
              input.onChange(trimmedValue)
            }}
          />
        )
      }}
    </Field>
  )
}

EmailField.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
}

EmailField.defaultProps = {
  size: 'small',
  fullWidth: true,
  variant: 'outlined'
}

export default React.memo(EmailField, isEqual)
