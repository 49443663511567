import React from 'react'

import MultiSelectField from '~/components/Fields/MultiSelectField'

import { DIGITAL, PHYSICAL, PRODUCT_TYPE_NAMES, SERVICE } from '../../constants'

export const options = [
  { value: DIGITAL, name: PRODUCT_TYPE_NAMES[DIGITAL] },
  { value: PHYSICAL, name: PRODUCT_TYPE_NAMES[PHYSICAL] },
  { value: SERVICE, name: PRODUCT_TYPE_NAMES[SERVICE] },
]
function ProductTypeFilterField ({ ...props }) {
  return (
    <MultiSelectField
      name="types"
      data-cy="types"
      label="Type"
      options={options}
      {...props}
    />
  )
}

export default ProductTypeFilterField
