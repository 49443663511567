import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useField } from 'react-final-form'
import { path } from 'ramda'
import { useValueChanged } from 'storfox-tools'

import * as API from '~/constants/api'
import SearchField from '~/components/Fields/SearchField'

const defaultGetOptionValue = value => {
  if (value) {
    const { id, guid, name, length, width, height, maxLoad, volume } = value
    return { id, guid, name, length, width, height, maxLoad, volume }
  }

  return null
}

function ContainerTypeField ({ warehouse, name, ...props }) {
  const warehouseField = useField(warehouse)
  const containerTypeField = useField(name)
  const warehouseId = path(['input', 'value', 'id'], warehouseField)
  const onChange = useCallback(
    path(['input', 'onChange'], containerTypeField),
    []
  )
  useValueChanged(() => {
    onChange(undefined)
  }, warehouseId)

  return (
    <SearchField
      data-cy="containerType"
      name="containerType"
      label="Container type"
      disabled={!warehouseId}
      params={{ warehouseId }}
      api={API.CONTAINER_TYPE_LIST}
      getOptionValue={defaultGetOptionValue}
      {...props}
    />
  )
}

ContainerTypeField.propTypes = {
  warehouse: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func
}

export default ContainerTypeField
