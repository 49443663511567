import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import Popover, { popoverClasses } from '@mui/material/Popover'
import { useTranslation } from 'react-i18next'
import { useField } from 'react-final-form'
import Box from '@mui/material/Box'
import { path } from 'ramda'
import { styled } from '@mui/material'
import { useDeepCompareEffect } from 'storfox-api-hooks'

import TextField from './TextField'

const PopoverStyled = styled(Popover, {
  shouldForwardProp: (propName) => propName !== 'width'
})(({ width }) => ({
  [`& .${popoverClasses.paper}`]:{
    width: width || 'auto'
  }
}))

function UnitPopoverField (props) {
  const {
    name = 'unitContainer',
    open,
    onClose,
    onChange,
    barcodeRef,
    popoverContent,
    error,
    ...rest
  } = props

  const { t } = useTranslation()
  const { input, meta } = useField(name)

  const anchorElRef = useRef(null)
  const width = path(['current', 'offsetWidth'], barcodeRef)

  useDeepCompareEffect(() => {
    anchorElRef.current = barcodeRef.current
  }, [])

  const handleChange = event => {
    if (event.key === 'Enter') {
      event.preventDefault()

      return onChange(event.target.value)
        .then(() => input.onChange(''))
    }
  }

  return (
    <Box style={{ position: 'relative' }}>
      <TextField
        name={name}
        inputProps={{ ref: barcodeRef }}
        autoFocus={true}
        label={t('Scan unit or container')}
        onKeyPress={handleChange}
        error={Boolean(meta.data.error) || error}
        helperText={meta.data.error || error}
        {...rest}
      />
      <PopoverStyled
        anchorEl={anchorElRef.current}
        open={open}
        onClose={onClose}
        width={width}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        {popoverContent}
      </PopoverStyled>
    </Box>
  )
}

UnitPopoverField.propTypes = {
  barcodeRef: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  popoverContent: PropTypes.object,
  error: PropTypes.string
}

export default UnitPopoverField
