import React from 'react'
import { useTranslation } from 'react-i18next'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'

import { CardHeader } from '~/components/Cards'
import TextField from '~/components/Fields/TextField'
import PhoneNumberField from '~/components/Fields/PhoneNumberField'

import CountryField from './Fields/CountryField'

function AddressCard () {
  const { t } = useTranslation()

  return (
    <Card style={{ height: '100%' }}>
      <CardHeader title={t('Pickup address')} />
      <Divider />
      <CardContent style={{ height: '100%' }}>
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={12} lg={12}>
            <TextField
              data-cy="pickupAddress.firstName"
              name="pickupAddress.firstName"
              label="First name"
            />
          </Grid>

          <Grid item={true} xs={12} lg={12}>
            <TextField
              data-cy="pickupAddress.lastName"
              name="pickupAddress.lastName"
              label="Last name"
            />
          </Grid>

          <Grid item={true} xs={12}>
            <PhoneNumberField
              data-cy="pickupAddress.mobile"
              name="pickupAddress.mobile"
              label="Phone number"
            />
          </Grid>

          <Grid item={true} xs={12}>
            <TextField
              data-cy="pickupAddress.address"
              name="pickupAddress.address"
              label="Address"
              multiline={true}
              minRows={3}
            />
          </Grid>

          <Grid item={true} xs={12}>
            <TextField
              data-cy="pickupAddress.region"
              name="pickupAddress.region"
              label="Region/Neighbouring/State"
            />
          </Grid>

          <Grid item={true} xs={12}>
            <TextField
              data-cy="pickupAddress.city"
              name="pickupAddress.city"
              label="City"
            />
          </Grid>

          <Grid item={true} xs={12}>
            <TextField
              data-cy="pickupAddress.zipcode"
              name="pickupAddress.zipcode"
              label="Zipcode"
            />
          </Grid>

          <Grid item={true} xs={12}>
            <CountryField
              data-cy="pickupAddress.country"
              name="pickupAddress.country"
              label="Country"
              ListboxProps={{ 'data-cy': 'countryList' }}
            />
          </Grid>

          <Grid item={true} xs={6}>
            <TextField
              data-cy="pickupAddress.latitude"
              name="pickupAddress.latitude"
              label="Latitude"
            />
          </Grid>

          <Grid item={true} xs={6}>
            <TextField
              data-cy="pickupAddress.longitude"
              name="pickupAddress.longitude"
              label="Longitude"
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default AddressCard
