import { sprintf } from 'sprintf-js'
import { useAllSearchParams } from 'storfox-route-hooks'
import { useDetail, useLimitedCountList, useList, useRequest, useUpdate } from 'storfox-api-hooks'

import { queryToParams } from '~/utils'
import * as API from '~/constants/api'

import { ReceiveFilterSerializer } from '../serializers'

export const useReceiveList = () => {
  const searchParams = useAllSearchParams()
  const params = queryToParams(ReceiveFilterSerializer, searchParams)
  const { getList, ...state } = useLimitedCountList(API.RECEIVE_LIST, params)

  return { ...state, getList: () => getList(params) }
}

export const useReceiveListComplete = () => {
  const { update, ...state } = useUpdate(API.RECEIVE_LIST_COMPLETE)

  return { ...state, complete: update }
}

export const useReceiveConditionList = () => {
  const params = { isDefault: true }
  return useList(API.CONDITION_LIST, params)
}

export const useReceiveHistory = (guid, autoSend) => {
  const url = sprintf(API.RECEIVE_HISTORY, guid)
  const searchParams = useAllSearchParams()

  return useList(url, searchParams, autoSend)
}

export const useReceiveUpdateDimensions = variantGuid => {
  const url = sprintf(API.RECEIVE_DIMENSIONS_UPDATE, variantGuid)
  const { update, ...state } = useUpdate(url)

  return { ...state, update }
}

// RETURNS

export const useReceiveReturnsDetail = guid => {
  const url = sprintf(API.RECEIVE_RETURN_ORDERS, guid)

  return useDetail(url)
}

export const useReceiveReturnsScanReceive = guid => {
  const url = sprintf(API.RECEIVE_RETURN_SCAN_RECEIVE, guid)
  const { update, ...state } = useUpdate(url)

  return { ...state, receive: update }
}

// STOCK TRANSFER

export const useReceiveStockDetail = guid => {
  const url = sprintf(API.RECEIVE_STOCK_TRANSFER_ORDERS, guid)

  return useDetail(url)
}

export const useReceiveStockScanReceive = guid => {
  const url = sprintf(API.RECEIVE_STOCK_SCAN_RECEIVE, guid)
  const { update, ...state } = useUpdate(url)

  return { ...state, receive: update }
}

// PURCHASE ORDER

export const useReceivePurchaseDetail = guid => {
  const url = sprintf(API.RECEIVE_PURCHASE_ORDERS, guid)

  return useDetail(url)
}

export const useReceivePurchaseDetailVariant = guid => {
  const { get } = useRequest()

  return {
    getDetail: variantGuid => {
      const api = sprintf(API.RECEIVE_PURCHASE_ORDERS_VARIANT_DETAIL, guid, variantGuid)
      return get(api)
    }
  }
}

export const useReceivePurchaseComplete = guid => {
  const url = sprintf(API.RECEIVE_COMPLETE, guid)
  const { update, ...state } = useUpdate(url)

  return { ...state, complete: update }
}

export const useReceivePurchaseReceive = guid => {
  const url = sprintf(API.RECEIVE_PURCHASE_RECEIVE, guid)
  const { update, ...state } = useUpdate(url)

  return { ...state, receive: update }
}

export const useReceivePurchaseScanReceive = guid => {
  const url = sprintf(API.RECEIVE_PURCHASE_SCAN_RECEIVE, guid)
  const { update, ...state } = useUpdate(url)

  return { ...state, receive: update }
}

export const useReceiveBarcodeConditionList = () => {
  return useList(API.CONDITION_LIST, { limit: 1000 })
}

export const useVariantValidate = guid => {
  const request = useRequest()

  return {
    validate: barcode => {
      const url = sprintf(API.RECEIVE_VARIANT_BY_BARCODE, guid, barcode)
      return request.get(url)
    }
  }
}

// TRANSFER

export const useReceiveTransferDetail = id => {
  const url = sprintf(API.TRANSFER_RECEIVE_DETAIL, id)
  return useDetail(url)
}

export const useReceiveTransferUpdate = () => {
  return useUpdate(API.TRANSFER_RECEIVE_UPDATE)
}

export const useStockDetailVariant = guid => {
  const { get } = useRequest()

  return {
    getDetail: variantGuid => {
      const api = sprintf(API.RECEIVE_STOCK_VARIANT_DETAIL, guid, variantGuid)
      return get(api)
    }
  }
}

// RETURN

export const useReceiveReturnDetail = guid => {
  const url = sprintf(API.RECEIVE_RETURN_DETAIL, guid)
  return useDetail(url)
}

export const useReceiveReturnUpdate = guid => {
  const url = sprintf(API.RECEIVE_RETURN_UPDATE, guid)
  return useUpdate(url)
}

export const useReturnDetailVariant = guid => {
  const { get } = useRequest()

  return {
    getDetail: variantGuid => {
      const api = sprintf(API.RECEIVE_RETURNS_VARIANT_DETAIL, guid, variantGuid)
      return get(api)
    }
  }
}
