import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { prop, propOr } from 'ramda'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'

import { ExpandableTableRow, ExpandedTableWrapper } from '~/components/Table'
import SkeletonList from '~/components/Skeleton'
import { Volume } from '~/components/Converters'

import WarehouseAccordionRow from './WarehouseAccordionRow'

function CompanyAccordionTable ({ company, onDetailLoad }) {
  const [open, setOpen] = useState(false)
  const clientGuid = prop('guid', company)
  const name = prop('name', company)
  const unitCount = prop('unitCount', company)
  const usedVolume = prop('usedVolume', company)

  const warehouses = propOr([], 'warehouses', company)

  const handleExpandToggle = () => {
    setOpen(prevState => !prevState)
    if (!warehouses.length) {
      onDetailLoad({ clientGuid, type: 'warehouse' })
    }
  }

  const handleLocationsLoad = warehouseGuid =>
    onDetailLoad({ clientGuid, warehouseGuid, type: 'location' })

  const handleUnitsLoad = (warehouseGuid, locationGuid) =>
    onDetailLoad({ clientGuid, warehouseGuid, locationGuid, type: 'unit' })

  return (
    <Paper sx={{ border: 'none' }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Company</TableCell>
              <TableCell>Used volume</TableCell>
              <TableCell>Unit count</TableCell>
              <TableCell sx={{ textAlign: 'center' }}>Warehouses</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <ExpandableTableRow onExpandToggle={handleExpandToggle} open={open}>
              <TableCell>{name}</TableCell>
              <TableCell>
                <Volume value={usedVolume} />
              </TableCell>
              <TableCell>{unitCount}</TableCell>
            </ExpandableTableRow>
            <ExpandedTableWrapper open={open} colSpan={4} depth={1}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Warehouse</TableCell>
                    <TableCell>Warehouse label</TableCell>
                    <TableCell align="center">Used volume</TableCell>
                    <TableCell align="center">Unit count</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>Locations</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!warehouses.length && <SkeletonList columnCount={5} rowCount={1} />}
                  {warehouses.map(warehouse => {
                    const warehouseGuid = prop('guid', warehouse)

                    return (
                      <WarehouseAccordionRow
                        key={warehouseGuid}
                        warehouse={warehouse}
                        onLocationsLoad={() => handleLocationsLoad(warehouseGuid)}
                        onUnitsLoad={locationGuid => handleUnitsLoad(warehouseGuid, locationGuid)}
                      />
                    )
                  })}
                </TableBody>
              </Table>

            </ExpandedTableWrapper>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

CompanyAccordionTable.propTypes = {
  company: PropTypes.object.isRequired,
  onDetailLoad: PropTypes.func.isRequired
}

export default CompanyAccordionTable
