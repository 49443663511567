import React from 'react'

import SelectField from '~/components/Fields/SelectField'
import { MARKETPLACE, RETAILER, THREE_PL, TYPES } from '~/constants/companyTypes'

const OPTIONS = [
  {
    value: RETAILER,
    name: TYPES[RETAILER]
  },
  {
    value: MARKETPLACE,
    name: TYPES[MARKETPLACE]
  },
  {
    value: THREE_PL,
    name: TYPES[THREE_PL]
  }
]

function AccountTypeField (props) {
  return (
    <SelectField name="type" options={OPTIONS} {...props} />
  )
}

export default AccountTypeField
