import React from 'react'
import PropTypes from 'prop-types'
import { path, prop, propOr } from 'ramda'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import { generatePath } from 'react-router-dom'

import { CardHeader } from '~/components/Cards'
import { useCompany } from '~/components/Profile'
import TextLabelLoader from '~/components/TextLabelLoader'
import MultiImagePreview from '~/components/ImageUpload/MultiImagePreview'
import { NAMES } from '~/constants/barcodingStrategies'
import Money from '~/components/Money'
import EmptyTextLabelLoader from '~/components/EmptyTextLabelLoader'
import { Dimension, Weight } from '~/components/Converters'
import BooleanLabel from '~/components/BooleanLabel'
import { capitalize } from '~/utils'
import * as ROUTES from '~/constants/routes'
import { PRODUCT_DETAIL_TABS } from '~/constants/tabs'
import Tags from '~/components/Tags'

function VariantDetailGeneral (props) {
  const { values, isMultiVariant } = props

  const { hasBelongCompany } = useCompany()

  const options = propOr([], 'options', values)

  const companyId = path(['company', 'id'], values)
  const isOwner = hasBelongCompany(companyId)

  const name = prop('name', values)
  const description = prop('description', values)
  const images = prop('images', values)
  const sku = prop('sku', values)
  const barcode = prop('barcode', values)
  const barcodingStrategy = prop('barcodingStrategy', values)
  const pieces = prop('pieces', values)
  const unitOfMeasurement = prop('unitOfMeasurement', values)
  const height = prop('height', values)
  const width = prop('width', values)
  const length = prop('length', values)
  const weight = prop('weight', values)
  const supplyPrice = prop('supplyPrice', values)
  const retailPrice = prop('retailPrice', values)
  const maximumRetailPrice = prop('maximumRetailPrice', values)
  const reorderPoint = prop('reorderPoint', values)
  const reorderQuantity = prop('reorderQuantity', values)
  const condition = path(['condition', 'code'], values)
  const minReplenishmentPoint = prop('minReplenishmentPoint', values)
  const maxReplenishmentPoint = prop('maxReplenishmentPoint', values)
  const isCrossDocking = prop('isCrossDocking', values)
  const syncSupplyPrice = prop('syncSupplyPrice', values)
  const supplier = path(['supplier', 'companyName'], values)
  const company = path(['company', 'name'], values)

  const productName = path(['product', 'name'], values)
  const productId = path(['product', 'id'], values)
  const barcodeAliases = propOr([], 'barcodeAliases', values)
  const productLink =
    productId && generatePath(ROUTES.PRODUCT_DETAIL_PATH, { id: productId, tab: PRODUCT_DETAIL_TABS.GENERAL })

  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12} lg={8}>
        {isMultiVariant && (
          <Box mb={3}>
            <Card>
              <CardHeader title="Options" />
              <Divider />
              <CardContent>
                <Grid container={true} spacing={3}>
                  {options.length ? (
                    options.map((option, index) => (
                      <Grid key={index} item={true} xs={6}>
                        <TextLabelLoader label={option.name}>
                          {option.option}
                        </TextLabelLoader>
                      </Grid>
                    ))
                  ) : (
                    <>
                      <Grid item={true} xs={6}>
                        <EmptyTextLabelLoader />
                      </Grid>
                      <Grid item={true} xs={6}>
                        <EmptyTextLabelLoader />
                      </Grid>
                    </>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Box>
        )}
        <Box mb={3}>
          <Card>
            <CardHeader title="Details" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                {productId && (
                  <Grid item={true} xs={12}>
                    <TextLabelLoader label="Product name">
                      <Link
                        color="secondary"
                        target="_blank"
                        rel="noreferrer noopener"
                        href={productLink}
                      >
                        {productName}
                      </Link>
                    </TextLabelLoader>
                  </Grid>
                )}
                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Name">
                    {name}
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Description">
                    {description}
                  </TextLabelLoader>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Box mb={3}>
          <Card>
            <CardHeader title="Images" />
            <Divider />
            <CardContent>
              <MultiImagePreview images={images} alt={name} />
            </CardContent>
          </Card>
        </Box>
      </Grid>

      <Grid item={true} xs={12} lg={4}>
        <Box mb={3}>
          <Card>
            <CardHeader title="Company" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Company">
                    {company}
                  </TextLabelLoader>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Box mb={3}>
          <Card>
            <CardHeader title="Supply" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Supplier">
                    {supplier}
                  </TextLabelLoader>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Box mb={3}>
          <Card>
            <CardHeader title="Tracking" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <TextLabelLoader label="SKU">
                    {sku}
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Barcode">
                    {barcode}
                  </TextLabelLoader>
                </Grid>
                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Unique Barcoding strategy">
                    {NAMES[barcodingStrategy]}
                  </TextLabelLoader>
                </Grid>
                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Barcode aliases">
                    {barcodeAliases.length > 0 ? <Tags items={barcodeAliases} /> : null}
                  </TextLabelLoader>
                </Grid>
                <Grid item={true} xs={12} lg={6}>
                  <TextLabelLoader label="Quantity">
                    {pieces}
                  </TextLabelLoader>
                </Grid>
                <Grid item={true} xs={12} lg={6}>
                  <TextLabelLoader label="UoM">
                    {capitalize(unitOfMeasurement)}
                  </TextLabelLoader>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Box mb={3}>
          <Card>
            <CardHeader title="Dimensions" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12} lg={6}>
                  <TextLabelLoader label="Height">
                    <Dimension value={height} />
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12} lg={6}>
                  <TextLabelLoader label="Width">
                    <Dimension value={width} />
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12} lg={6}>
                  <TextLabelLoader label="Length">
                    <Dimension value={length} />
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12} lg={6}>
                  <TextLabelLoader label="Weight">
                    <Weight value={weight} />
                  </TextLabelLoader>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Box mb={3}>
          <Card>
            <CardHeader
              title={
                <>
                  Pricing <br />
                  <Typography variant="caption">
                    Default condition: {condition}
                  </Typography>
                </>
              }
            />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                {isOwner && (
                  <Grid item={true} xs={12}>
                    <TextLabelLoader label="Supply price">
                      <Money value={supplyPrice} />
                    </TextLabelLoader>
                  </Grid>
                )}

                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Retail price">
                    <Money value={retailPrice} />
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Maximum Retail price">
                    <Money value={maximumRetailPrice} />
                  </TextLabelLoader>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Box mb={3}>
          <Card>
            <CardHeader title="Reorder" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Reorder Point">
                    {reorderPoint}
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Reorder Quantity">
                    {reorderQuantity}
                  </TextLabelLoader>
                </Grid>
                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Min Replenishment Point">
                    {minReplenishmentPoint}
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Max Replenishment Point">
                    {maxReplenishmentPoint}
                  </TextLabelLoader>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Box mb={3}>
          <Card>
            <CardHeader title="Fulfillment" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <BooleanLabel label="Cross docking">
                    {isCrossDocking}
                  </BooleanLabel>
                </Grid>
                <Grid item={true} xs={12}>
                  <BooleanLabel label="Sync Supply Price">
                    {syncSupplyPrice}
                  </BooleanLabel>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>
      <Box mb={3} />
    </Grid>
  )
}

VariantDetailGeneral.propTypes = {
  values: PropTypes.object.isRequired,
  isMultiVariant: PropTypes.bool
}

export default VariantDetailGeneral
