import palette from '~/theme/dark/palette'

export default {
  styleOverrides: {
    row: {
      color: 'rgba(255, 255, 255, .12)',
      hover: palette.hover.color,
      checked: palette.secondary.light
    },
    header: {
      backgroundColor: '#282C34'
    },
    shadow: {
      backgroundColor: '#1e1e1e'
    },
    border: {
      color: 'rgba(255, 255, 255, .12)'
    }
  }
}
