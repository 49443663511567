import React from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import { generatePath, useParams } from 'react-router-dom'
import { path, prop } from 'ramda'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'

import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import TextLabelLoader from '~/components/TextLabelLoader'
import { EditButton } from '~/components/Buttons'
import { CardHeader } from '~/components/Cards'
import AreaStatus from '~/components/Statuses/AreaStatus'
import LoaderProvider from '~/components/Loader'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'

import More from './More'

function AreaDetail ({ pageTitle, pageTitleLoading, detail, isLoading, onRemove }) {
  const { guid } = useParams()
  const updatePath = generatePath(ROUTES.AREA_UPDATE_PATH, { guid })

  const warehouse = path(['warehouse', 'name'], detail)
  const name = prop('name', detail)
  const label = prop('label', detail)
  const status = prop('status', detail)

  return (
    <LoaderProvider isLoading={isLoading}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={pageTitle}
            pageTitleLoading={pageTitleLoading}
            parentTitle={NAV.AREAS}
            rightButton={(
              <>
                <EditButton disabled={isLoading} path={updatePath} />
                <More onRemove={onRemove} />
              </>
            )}
          />
        </Header>

        <Content>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12} lg={9}>
              <Box mb={3}>
                <Card>
                  <CardHeader title="Details" />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12} lg={12}>
                        <TextLabelLoader label="Warehouse">{warehouse}</TextLabelLoader>
                      </Grid>

                      <Grid item={true} xs={12} lg={6}>
                        <TextLabelLoader label="Label">{label}</TextLabelLoader>
                      </Grid>

                      <Grid item={true} xs={12} lg={6}>
                        <TextLabelLoader label="Name">{name}</TextLabelLoader>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Grid>

            <Grid item={true} xs={12} lg={3}>
              <Box mb={3}>
                <Card>
                  <CardHeader title="Settings" />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12}>
                        <TextLabelLoader label="Status">
                          <Typography variant="subtitle1">
                            <AreaStatus value={status} />
                          </Typography>
                        </TextLabelLoader>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Content>
      </Container>
    </LoaderProvider>
  )
}

AreaDetail.propTypes = {
  detail: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onRemove: PropTypes.func.isRequired,
  pageTitle: PropTypes.string.isRequired,
  pageTitleLoading: PropTypes.bool.isRequired
}

export default AreaDetail
