import { useAllSearchParams } from 'storfox-route-hooks'
import { useLimitedCountList } from 'storfox-api-hooks'

import { queryToParams } from '~/utils'
import * as API from '~/constants/api'

import { ReorderFilterSerializer } from '../serializers'

export const useReorderList = (warehouse) => {
  const searchParams = useAllSearchParams()
  const params = queryToParams(ReorderFilterSerializer, { ...searchParams, warehouse }, 'id')

  return useLimitedCountList(API.REORDER_LIST, params)
}
