import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import ZoneDetailContainer from './containers/ZoneDetailContainer'
import ZoneCreateContainer from './containers/ZoneCreateContainer'
import ZoneUpdateContainer from './containers/ZoneUpdateContainer'

export default () => {
  return [
    {
      layout: AuthLayout,
      path: ROUTES.ZONE_CREATE_PATH,
      component: ZoneCreateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.ZONE_UPDATE_PATH,
      component: ZoneUpdateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.ZONE_DETAIL_PATH,
      component: ZoneDetailContainer
    }
  ]
}
