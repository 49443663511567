import React from 'react'
import PropTypes from 'prop-types'
import { prop, propOr } from 'ramda'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'

import OrderPercentage from './OrderPercentage'
import CircularOrderProgress from './CircularOrderProgress'

function OrdersPercentage ({ orderOverview, isLoading }) {
  const detail = prop('detail', orderOverview)
  const total = propOr(0, 'total', detail)

  const pending = propOr(0, 'pending', detail)
  const pendingPercentage = pending / total * 100

  const processing = propOr(0, 'processing', detail)
  const processingPercentage = processing / total * 100

  const completed = propOr(0, 'completed', detail)
  const completedPercentage = completed / total * 100

  return (
    <Card sx={{ padding: '16px 0' }}>
      <Grid container={true} spacing={3}>
        <Grid item={true} md={4}>
          <OrderPercentage
            title="Pending"
            value={pending}
            isLoading={isLoading}
            circle={
              <CircularOrderProgress
                value={pendingPercentage || 0}
                size={76}
                thickness={4}
                color="#656EEA"
              />
            }
            sx={{ borderRight: '1px solid #EEEEEE' }}
            tooltip="Orders awaiting further action or resolution."
          />
        </Grid>
        <Grid item={true} md={4}>
          <OrderPercentage
            title="In process"
            value={processing}
            isLoading={isLoading}
            circle={
              <CircularOrderProgress
                value={processingPercentage || 0}
                size={76}
                thickness={4}
                color="#FFA12D"
              />
            }
            sx={{ borderRight: '1px solid #EEEEEE' }}
            tooltip="Orders currently undergoing fulfillment."
          />
        </Grid>
        <Grid item={true} md={4}>
          <OrderPercentage
            title="Completed"
            value={completed}
            isLoading={isLoading}
            circle={
              <CircularOrderProgress
                value={completedPercentage || 0}
                size={76}
                thickness={4}
                color="#4BC0C0"
              />
            }
            tooltip="Orders successfully fulfilled and processed."
          />
        </Grid>
      </Grid>
    </Card>
  )
}

OrdersPercentage.propTypes = {
  orderOverview: PropTypes.object,
  isLoading: PropTypes.bool.isRequired
}

export default OrdersPercentage
