import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import { useField } from 'react-final-form'
import { pathOr } from 'ramda'

import CountryField from '~/components/Fields/CountryField'
import TextField from '~/components/Fields/TextField'
import { CardHeader } from '~/components/Cards'

function CustomerAddress () {
  const customerAddress = useField('customerAddress')
  const customerAddressFieldError = pathOr('', ['meta', 'submitError', 0], customerAddress)
  return (
    <Box mb={3}>
      <Card>
        <CardHeader title="Address" required={true} />
        <Divider />
        <CardContent>
          <Grid container={true} spacing={3}>

            <Grid item={true} xs={12}>
              <TextField
                data-cy="customerAddress.address"
                name="customerAddress.address"
                label="Address"
                multiline={true}
                minRows={3}
                error={customerAddressFieldError}
                required={true}
              />
            </Grid>
            <Grid item={true} xs={12}>
              <TextField
                data-cy="customerAddress.region"
                name="customerAddress.region"
                label="Region/Neighbouring/State"
              />
            </Grid>

            <Grid item={true} xs={6}>
              <TextField
                data-cy="customerAddress.city"
                name="customerAddress.city"
                label="City"
                country="customerAddress.country"
              />
            </Grid>

            <Grid item={true} xs={6}>
              <TextField
                data-cy="customerAddress.zipcode"
                name="customerAddress.zipcode"
                label="Zipcode"
              />
            </Grid>

            <Grid item={true} xs={12}>
              <CountryField
                data-cy="customerAddress.country"
                name="customerAddress.country"
                label="Country"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  )
}

export default CustomerAddress
