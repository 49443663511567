import React from 'react'
import { useFilter, useOrdering } from 'storfox-filter'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import useMessages from '~/hooks/useMessages'
import ErrorLink from '~/components/ErrorLink'
import { useTableSelects } from '~/components/TableValues/hooks'

import { useCategoriesDelete, useCategoryList } from '../hooks'
import {
  CategoryFilterForm,
  categoryFilterOptions,
  CategoryOrderingForm,
  categoryOrderingOptions,
  CategoryTable
} from '../components/CategoryList'

function CategoryListContainer () {
  const categoryList = useCategoryList()
  const categoriesDelete = useCategoriesDelete()
  const snackbar = useSnackbar()
  const messages = useMessages()
  const { selects, resetTableSelects } = useTableSelects()

  const filter = useFilter(categoryFilterOptions)
  const ordering = useOrdering(categoryOrderingOptions)

  const handleCategoriesDelete = () =>
    categoriesDelete.delete({ ids: selects })
      .then(() => categoryList.getList())
      .then(() => snackbar({ message: messages.DELETE_SUCCESS }))
      .then(() => resetTableSelects())
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const breadcrumbs = { title: NAV.CATEGORIES }

  return (
    <DashboardLayout
      title={NAV.CATEGORIES}
      activeNav={NAV.CATEGORIES}
      breadcrumbs={breadcrumbs}
    >
      <CategoryFilterForm {...filter} />
      <CategoryOrderingForm {...ordering} />

      <CategoryTable
        filter={filter}
        ordering={ordering}
        list={categoryList}
        onListRefetch={categoryList.getListByParams}
        onCategoriesDelete={handleCategoriesDelete}
        bulkActionLoading={categoriesDelete.isLoading}
      />
    </DashboardLayout>
  )
}

export default CategoryListContainer
