import React from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'

import PageTitle from '~/components/PageTitle'
import { DiscardButton, SaveButton } from '~/components/Buttons'
import Container, { Content, Header } from '~/components/Container'
import { withForm } from '~/components/Form'
import * as NAV from '~/constants/nav-titles'
import { COMPANY_TABS } from '~/constants/tabs'

import CompanyUpdateFormTabs from './CompanyUpdateFormTabs'
import CompanyUpdateFormGeneral from './CompanyUpdateFormGeneral'
import CompanyUpdateFormUsers from './CompanyUpdateFormUsers'

function CompanyUpdateForm ({ title, isLoading, form }) {
  const { tab } = useParams()
  const { handleSubmit } = form

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={title}
            parentTitle={NAV.COMPANIES}
            rightButton={(
              <>
                <DiscardButton disabled={isLoading} />
                <SaveButton disabled={isLoading} />
              </>
            )}
          />
        </Header>

        <Content>
          <CompanyUpdateFormTabs value={tab} />

          <Divider />
          <Box mb={3} />

          {COMPANY_TABS.GENERAL === tab && <CompanyUpdateFormGeneral />}
          {COMPANY_TABS.USERS === tab && <CompanyUpdateFormUsers />}
          <Box mb={3} />
        </Content>
      </Container>
    </form>
  )
}

CompanyUpdateForm.propTypes = {
  title: PropTypes.string.isRequired,
  form: PropTypes.object,
  isLoading: PropTypes.bool.isRequired
}

export default withForm(CompanyUpdateForm)
