import React from 'react'

import SelectField from '~/components/Fields/SelectField'
import { CONTAINER_STATUS } from '~/components/Statuses/ContainerStatus'

export const OPTIONS = [
  { value: CONTAINER_STATUS.ACTIVE, name: 'Active' },
  { value: CONTAINER_STATUS.INACTIVE, name: 'Inactive' }
]

function ContainerStatusField (props) {
  return (
    <SelectField
      name="status"
      label="Status"
      options={OPTIONS}
      {...props}
    />
  )
}

export default ContainerStatusField
