import React, { useCallback, useState } from 'react'
import { useFilter, useOrdering } from 'storfox-filter'
import { prop, propOr, pipe, defaultTo, find } from 'ramda'
import { useNavigate } from 'react-router-dom'
import { useAllSearchParams } from 'storfox-route-hooks'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import { useTableSelects } from '~/components/TableValues/hooks'
import { useProfile } from '~/components/Profile'
import { escapeAtob, unescapeBtoa } from '~/utils'

import { useReorderList } from '../hooks'
import {
  ReorderOrderingForm,
  reorderFilterOptions,
  ReorderFilterForm,
  reorderOrderingOptions,
  ReorderTable
} from '../components/ReorderList'

const getDefaultWarehouse = (warehouses) => {
  return pipe(
    find(prop('isDefault')),
    defaultTo(propOr({}, 0, warehouses))
  )(warehouses)
}

function ReorderListContainer () {
  const { profile } = useProfile()
  const navigate = useNavigate()
  const searchParams = useAllSearchParams()

  const warehouses = propOr([], 'warehouses', profile)
  const [warehouse, setWarehouse] = useState(getDefaultWarehouse(warehouses))

  const reorderList = useReorderList(warehouse)

  const filter = useFilter(reorderFilterOptions)
  const ordering = useOrdering(reorderOrderingOptions)

  const { selects } = useTableSelects()

  const title = 'Reorder'

  const breadcrumbs = { title }

  const comparisonWarehouse = escapeAtob(prop('comparisonWarehouse', searchParams))

  const handleWarehouseChange = useCallback(async (value) => {
    setWarehouse(value)
  }, [])

  const handleReorder = useCallback(route => {
    let variants = []
    selects.map(select => {
      const item = reorderList.results.find(item => item.guid === select)
      const variant = prop('variant', item)
      const reorderQuantity = propOr(0, 'reorderQuantity', variant)
      const available = prop('available', item)
      const awaiting = prop('awaiting', item)
      const needed = reorderQuantity - (available + awaiting)
      const id = prop('id', variant)
      variants.push({ id, needed })
    })
    const body = {
      warehouse,
      variants,
      comparisonWarehouse
    }
    const createPath = `${route}?body=${unescapeBtoa(body)}`
    navigate(createPath)
  }, [selects, warehouse, comparisonWarehouse, navigate, reorderList.results])

  return (
    <DashboardLayout
      title={title}
      activeNav={NAV.REORDER}
      breadcrumbs={breadcrumbs}
    >
      <ReorderFilterForm {...filter} />
      <ReorderOrderingForm {...ordering} />

      <ReorderTable
        handleReorder={handleReorder}
        pageTitle={title}
        initialValues={{ warehouse }}
        parentTitle={NAV.REORDER}
        onWarehouseChange={handleWarehouseChange}
        onSubmit={() => null}
        isLoading={reorderList.isLoading}
        filter={filter}
        ordering={ordering}
        reorderList={reorderList}
        onListRefetch={reorderList.getListByParams}
        comparisonWarehouse={comparisonWarehouse}
      />
    </DashboardLayout>
  )
}

export default ReorderListContainer
