import React from 'react'
import PropTypes from 'prop-types'
import { Sticky } from 'react-sticky'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'

const RootStyled = styled(Box, {
  shouldForwardProp: propName => propName !== 'isSticky'
})(({ theme, isSticky }) => ({
  top: '56px !important',
  zIndex: 4,
  paddingTop: 10,
  paddingBottom: 10,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  [theme.breakpoints.down('lg')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  [theme.breakpoints.up('sm')]: {
    top: '64px !important',
  },
  ...(isSticky && {
    borderTop: `1.5px solid ${theme.palette.divider}`,
    background: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
  })
}))

function Header ({ children }) {
  return (
    <Sticky>
      {({ style, isSticky }) => (
        <RootStyled
          style={style}
          isSticky={isSticky}
        >
          {React.cloneElement(children, { isSticky })}
        </RootStyled>
      )}
    </Sticky>
  )
}

Header.propTypes = {
  children: PropTypes.any.isRequired
}

export default Header
