import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Status, { ERROR, SUCCESS, WARNING } from '../Status/Status'

export const UNFULFILLED = 'unfulfilled'
export const PICK = 'pick'
export const PACK = 'pack'
export const SHIP = 'ship'
export const PARTIALLY_PICKED = 'partially_picked'
export const PARTIALLY_PACKED = 'partially_packed'
export const PARTIALLY_SHIPPED = 'partially_shipped'

export const FULFILLMENT_STATUS = {
  UNFULFILLED,
  PICK,
  PACK,
  SHIP,
  PARTIALLY_PICKED,
  PARTIALLY_SHIPPED,
  PARTIALLY_PACKED
}

export const FULFILLMENT_STATUS_LABEL = {
  [UNFULFILLED]: 'Unfulfilled',
  [PICK]: 'Pick',
  [PACK]: 'Pack',
  [SHIP]: 'Ship',
  [PARTIALLY_PICKED]: 'Partially Picked',
  [PARTIALLY_PACKED]: 'Partially Packed',
  [PARTIALLY_SHIPPED]: 'Partially Shipped',
}

const STATUS_COLOR = {
  [FULFILLMENT_STATUS.UNFULFILLED]: ERROR,
  [FULFILLMENT_STATUS.PARTIALLY_PICKED]: WARNING,
  [FULFILLMENT_STATUS.PICK]: SUCCESS,
  [FULFILLMENT_STATUS.PACK]: SUCCESS,
  [FULFILLMENT_STATUS.SHIP]: SUCCESS,
  [FULFILLMENT_STATUS.PARTIALLY_PICKED]: WARNING,
  [FULFILLMENT_STATUS.PARTIALLY_PACKED]: WARNING,
  [FULFILLMENT_STATUS.PARTIALLY_SHIPPED]: WARNING,
}

function FulfillmentStatus ({ value }) {
  const { t } = useTranslation()

  return (
    <Status
      label={t(FULFILLMENT_STATUS_LABEL[value])}
      color={STATUS_COLOR[value]}
    />
  )
}

FulfillmentStatus.propTypes = {
  value: PropTypes.string.isRequired
}

export default FulfillmentStatus
