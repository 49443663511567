import React from 'react'
import { FieldArray } from 'react-final-form-arrays'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { defaultTo, path, prop, propOr } from 'ramda'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import { styled } from '@mui/material'

const NoBorderTableRowStyled = styled(TableRow)({
  '& td': {
    height: '50px',
    borderBottom: 'none',
    padding: '6px 24px 6px 16px'
  }
})

function LineItems ({ detail }) {
  const { t } = useTranslation()

  return (
    <TableContainer sx={{ maxHeight: '300px' }}>
      <FieldArray
        name="items"
        render={({ fields }) => {
          const values = defaultTo([], fields.value)
          return (
            <Table>
              <TableHead sx={{ position: 'sticky', top: 0, zIndex: 1 }}>
                <TableRow>
                  <TableCell>{t('Lot Number')}</TableCell>
                  <TableCell>{t('Barcode')}</TableCell>
                  <TableCell>{t('Actual value')}</TableCell>
                  <TableCell>{t('Unscanned Quantity')}</TableCell>
                  <TableCell>{t('Scanned Quantity')}</TableCell>
                  <TableCell>{t('Quantity')}</TableCell>
                  <TableCell>{t('Input SKU')}</TableCell>
                  <TableCell>{t('Bucket')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.map((item) => {
                  const containers = propOr([], 'containers', item)
                  const lotNumber = path(['lot', 'number'], detail)
                  const barcode = prop('mainBarcode', detail)
                  const totalQuantity = prop('totalQuantity', item)
                  const scannedQuantity = prop('scannedQuantity', item)
                  const quantity = containers.reduce((acc, item) => acc + propOr(0, 'quantity', item), 0)
                  const inputBarcode = prop('inputBarcode', item)
                  const bucketName = path(['bucket', 'name'], item)
                  const unscannedQuantity = totalQuantity - scannedQuantity - quantity
                  return (
                    <NoBorderTableRowStyled>
                      <TableCell>{lotNumber}</TableCell>
                      <TableCell>{barcode}</TableCell>
                      <TableCell>{totalQuantity}</TableCell>
                      <TableCell>{unscannedQuantity}</TableCell>
                      <TableCell>{scannedQuantity}</TableCell>
                      <TableCell>{quantity}</TableCell>
                      <TableCell>{inputBarcode}</TableCell>
                      <TableCell>{bucketName}</TableCell>
                    </NoBorderTableRowStyled>
                  )
                })}
              </TableBody>
            </Table>
          )
        }}
      />
    </TableContainer>
  )
}

LineItems.propTypes = {
  detail: PropTypes.object
}

export default LineItems
