import React from 'react'
import { useTranslation } from 'react-i18next'

import SelectField from './SelectField'

export const ACTIVE = 'active'
export const INACTIVE = 'inactive'

const options = [
  { value: ACTIVE, name: 'Active' },
  { value: INACTIVE, name: 'Inactive' },
]

function StatusField ({ ...props }) {
  const { t } = useTranslation()

  return (
    <SelectField
      data-cy="status"
      name="status"
      label={t('Status')}
      defaultValue={ACTIVE}
      options={options}
      {...props}
    />
  )
}

export default StatusField
