import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import { prop } from 'ramda'
import Card from '@mui/material/Card'

import Container, { Content, Header } from '~/components/Container'
import PageTitle from '~/components/PageTitle'
import { Button, DiscardButton } from '~/components/Buttons'
import * as NAV from '~/constants/nav-titles'
import { PAID } from '~/components/Statuses/PaymentStatus'

import ClientCard from './ClientCard'
import TplBillingTable from './TplBillingTable'
import InvoiceSummary from './InvoiceSummary'

function TplBillingDetail ({ pageTitle, pageTitleLoading, isLoading, detail, onMarkAsPaid }) {
  const bills = prop('items', detail)
  const client = prop('client', detail)
  const summary = prop('summary', detail)
  const profile = prop('profile', detail)
  const paymentStatus = prop('paymentStatus', detail)

  const disabled = (
    isLoading ||
    paymentStatus === PAID
  )

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={pageTitle}
          pageTitleLoading={pageTitleLoading}
          parentTitle={NAV.CONFIGURATION}
          rightButton={(
            <>
              <DiscardButton disabled={isLoading} />
              <Button
                variant="contained"
                onClick={onMarkAsPaid}
                disabled={disabled}
              >
                Mark as paid
              </Button>
            </>
          )}
        />
      </Header>
      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={12} lg={8}>
            <Grid container={true} spacing={3}>
              <Grid item={true} xs={12}>
                <TplBillingTable bills={bills} isLoading={isLoading} />
              </Grid>
              <Grid item={true} xs={12}>
                <Card>
                  <InvoiceSummary summary={summary} />
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true} xs={12} lg={4}>
            <ClientCard client={client} profile={profile} />
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

TplBillingDetail.propTypes = {
  detail: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  pageTitle: PropTypes.object.isRequired,
  pageTitleLoading: PropTypes.bool.isRequired,
  onMarkAsPaid: PropTypes.func.isRequired
}

export default TplBillingDetail
