import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material'

import * as ROUTES from '~/constants/routes'

import NotificationSkeleton from './NotificationSkeleton'

import NotificationPopupItem from '../NotificationPopupItem'
import Menu from '../Menu'

const ShowMoreMenu = styled(MenuItem)({
  display: 'flex',
  justifyContent: 'center'
})

function NotificationPopup ({ open, anchorEl, onClose, notifications, isLoading }) {
  const navigate = useNavigate()

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
    >
      {isLoading && (
        <MenuItem>
          <NotificationSkeleton />
        </MenuItem>
      )}
      {notifications.map(notification => (
        <MenuItem key={notification.id}>
          <NotificationPopupItem item={notification} />
        </MenuItem>
      ))}
      <ShowMoreMenu onClick={() => navigate(ROUTES.NOTIFICATION_LIST_PATH)}>
        Show more
      </ShowMoreMenu>
    </Menu>
  )
}

NotificationPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.any,
  notifications: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default NotificationPopup
