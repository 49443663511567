import DashboardIcon from '@mui/icons-material/DashboardOutlined'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import TitleIcon from '@mui/icons-material/Title'
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket'
import AdjustIcon from '@mui/icons-material/Adjust'
import PeopleIcon from '@mui/icons-material/People'
import InboxIcon from '@mui/icons-material/Inbox'

import * as ROUTES from '~/constants/routes'
import { MARKETPLACE, RETAILER, THREE_PL } from '~/constants/companyTypes'
import {
  ADJUSTMENT_REASON,
  AUTOMATION_RULE,
  CONTAINER_TYPE,
  CONTRACT,
  CURRENCY_RATE,
  DELIVERY_METHOD,
  PRODUCT_CONDITION,
  TAX,
  TEMPLATE,
  BILLING_PROFILE
} from '~/constants/configuration-titles'

// TYPES
export const WELCOME = 'welcome'

export const menuItems = [
  {
    icon: AttachMoneyIcon,
    title: CURRENCY_RATE,
    description: 'Manage base currency and exchange rates',
    path: ROUTES.CURRENCY_RATE_LIST_PATH,
    disabled: false,
    companyTypes: [RETAILER, MARKETPLACE, THREE_PL]
  },
  {
    icon: TitleIcon,
    title: TAX,
    description: 'Set up multiple tax types',
    path: ROUTES.TAX_LIST_PATH,
    disabled: false,
    companyTypes: [RETAILER, MARKETPLACE, THREE_PL]
  },
  {
    icon: ShoppingBasketIcon,
    title: PRODUCT_CONDITION,
    description: 'Define product conditions and grades',
    path: ROUTES.CONDITION_LIST_PATH,
    disabled: false,
    companyTypes: [RETAILER, MARKETPLACE, THREE_PL]
  },
  {
    icon: AdjustIcon,
    title: ADJUSTMENT_REASON,
    description: 'Add custom reasons to your stock adjustments',
    path: ROUTES.ADJUSTMENT_REASON_LIST_PATH,
    disabled: false,
    companyTypes: [RETAILER, MARKETPLACE, THREE_PL]
  },
  {
    icon: InboxIcon,
    title: CONTAINER_TYPE,
    description: 'Create various containers such as cartons, pallets, totes...',
    path: ROUTES.CONTAINER_TYPE_LIST_PATH,
    disabled: false,
    companyTypes: [RETAILER, MARKETPLACE, THREE_PL]
  },
  {
    icon: DashboardIcon,
    title: TEMPLATE,
    description: 'Set up your custom templates for labels and documents',
    path: ROUTES.TEMPLATE_LIST_PATH,
    disabled: false,
    companyTypes: [RETAILER, MARKETPLACE, THREE_PL]
  },
  {
    icon: PeopleIcon,
    title: CONTRACT,
    description: 'Invite your merchants/clients to the system',
    path: ROUTES.CONTRACT_LIST_PATH,
    disabled: false,
    companyTypes: [RETAILER, MARKETPLACE, THREE_PL]
  },
  {
    icon: ShoppingBasketIcon,
    title: AUTOMATION_RULE,
    description: 'Create automation rules for your system',
    path: ROUTES.AUTOMATION_LIST_PATH,
    disabled: false,
    companyTypes: [RETAILER, MARKETPLACE, THREE_PL]
  },
  {
    icon: ShoppingBasketIcon,
    title: BILLING_PROFILE,
    description: 'Create billing profiles for your company',
    path: ROUTES.BILLING_PROFILE_LIST_PATH,
    disabled: false,
    companyTypes: [MARKETPLACE, THREE_PL]
  },
  {
    icon: ShoppingBasketIcon,
    title: DELIVERY_METHOD,
    description: 'Create delivery methods for your company',
    path: ROUTES.DELIVERY_METHOD_LIST_PATH,
    disabled: false,
    companyTypes: [RETAILER, MARKETPLACE, THREE_PL]
  }
]

export const NAMES = {
  [WELCOME]: 'welcome',
}
