import React from 'react'
import DialogContent from '@mui/material/DialogContent'
import Dialog from '@mui/material/Dialog'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell/TableCell'
import TableBody from '@mui/material/TableBody'
import { useTranslation } from 'react-i18next'
import { path, prop, propOr } from 'ramda'

import DialogTitle from '~/components/DialogTitle'
import Button from '~/components/Buttons/Button'

function UnitTableDialog ({ onClose, open, units, onScanClick, ...props }) {
  const { t } = useTranslation()
  return (
    <Dialog
      maxWidth="md"
      onClose={onClose}
      open={open}
      fullWidth={true}
      {...props}
    >
      <DialogTitle>
        <Typography component="h3" variant="h6">ASL Belgisi</Typography>
      </DialogTitle>
      <DialogContent dividers={true}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('Name')}</TableCell>
                <TableCell>{t('Quantity')}</TableCell>
                <TableCell>{t('Condition code')}</TableCell>
                <TableCell>{t('Location')}</TableCell>
                <TableCell>{t('Container')}</TableCell>
                <TableCell>{t('Action')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {units.map((item) => {
                const name = path(['variant', 'name'], item)
                const condition = path(['condition', 'code'], item)
                const container = path(['container', 'number'], item)
                const location = path(['location', 'locationId'], item)
                const quantity = prop('quantity', item)
                const aslNumbers = propOr([], 'aslNumbers', item)
                return (
                  <TableRow>
                    <TableCell>
                      {name}
                    </TableCell>
                    <TableCell>
                      {quantity}
                    </TableCell>
                    <TableCell>
                      {condition}
                    </TableCell>
                    <TableCell>
                      {location}
                    </TableCell>
                    <TableCell>
                      {container}
                    </TableCell>
                    <TableCell>
                      {Boolean(aslNumbers.length) && (
                        <Typography>
                          SCANNED
                        </Typography>
                      )}
                      {Boolean(aslNumbers.length === 0) && (
                        <Button onClick={() => onScanClick(item)}>Scan</Button>
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  )
}

UnitTableDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onScanClick: PropTypes.func.isRequired,
  units: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired
}

export default UnitTableDialog
