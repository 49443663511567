import { getCdnUrl } from 'storfox-api-hooks'

export const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

const createImageBySize = (src, size) => {
  const imagePath = src
    .replace(/\/(o)\//g, `/${size}/`)
    .substring(0, src.lastIndexOf('.'))

  return imagePath + '.jpg'
}

export const createSmallImageUrl = src => {
  return `${getCdnUrl()}/${createImageBySize(src, 's')}`
}

export const createMediumImageUrl = src => {
  return `${getCdnUrl()}/${createImageBySize(src, 'm')}`
}

export const createLargeImageUrl = src => {
  return `${getCdnUrl()}/${createImageBySize(src, 'l')}`
}

export const createOriginImageUrl = src => {
  return `${getCdnUrl()}/${src}`
}

export const cdnImageFilter = src => {
  const haveCdn = src.includes('cdn')
  if (haveCdn) {
    return src
  } else {
    return createMediumImageUrl(src)
  }
}
