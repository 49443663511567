import React, { useRef, useState } from 'react'
import { Grid } from '@mui/material'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'

import Button from '../Buttons/Button'
import BarcodeField from '../BarcodeGeneratorDialog/BarcodeField'

function Content ({ list, onGenerateList }) {
  const ref = useRef()
  const [quantity, setQuantity] = useState('')

  const disabled = (
    ref.current &&
    ref.current.value.length === 0
  )

  const handleChange = value => {
    setQuantity(value)
  }

  const handleGenerate = () =>
    onGenerateList(parseInt(quantity))
      .then(() => {
        setQuantity('')
      })

  return (
    <>
      <Grid container={true} spacing={3}>
        <Grid item={true} sm={6} xs={12}>
          <BarcodeField
            value={quantity}
            onChange={handleChange}
            fullWidth={true}
            autoFocus={true}
            name="quantity"
            label="Quantity"
            inputProps={{ ref, maxLength: 3 }}
          />
        </Grid>
        <Grid item={true} sm={6} xs={12}>
          <Button
            variant="contained"
            fullWidth={true}
            type="button"
            disabled={disabled}
            onClick={handleGenerate}
          >
            Generate
          </Button>
        </Grid>
      </Grid>
      <Grid
        container={true}
        spacing={3}
        sx={{
          marginTop: theme => theme.spacing(3),
          maxHeight: '60vh',
          overflow: 'auto'
        }}>
        <Grid item={true} xs={12}>
          <Typography sx={{ lineHeight: '24px' }}>{list.join(', ')}</Typography>
        </Grid>
      </Grid>
    </>
  )
}

Content.propTypes = {
  list: PropTypes.array.isRequired,
  onGenerateList: PropTypes.func.isRequired
}

export default Content
