import { defaultTo, map, pick, pipe, pluck, prop, propOr } from 'ramda'

export const CONTAINER_TYPE_CREATE_FIELDS = [
  'warehouses',
  'name',
  'label',
  'operations',
  'status',
  'isTrackable',
  'length',
  'width',
  'height',
  'maxLoad',
  'volume',
  'images',
  'isDefault'
]

const getOperations = pipe(
  prop('operations'),
  defaultTo([]),
  pluck('value')
)

const getWarehouses = pipe(
  propOr([], 'warehouses'),
  map(pick(['guid']))
)

export function ContainerTypeCreateSerializer (formValues) {
  const volume = prop('vol', formValues)
  const warehouses = getWarehouses(formValues)
  const operations = getOperations(formValues)
  const data = { ...formValues, operations, volume, warehouses }

  return pick(CONTAINER_TYPE_CREATE_FIELDS, data)
}
