import React, { useCallback } from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import { useField } from 'react-final-form'

import { CardHeader } from '~/components/Cards'
import useDidUpdateEffect from '~/hooks/useDidUpdateEffect'

import ReceivingChargeTypeField from './ReceivingChargeTypeField'
import ContainerWeightPricing from './ContainerWeightPricing'

import EmptyTypeCard from '../EmptyTypeCard'
import { RECEIVING_TYPES } from '../../../constants/BillingProfile'

function BillingProfileReceiving () {
  const receivingBillingField = useField('receivingBilling')
  const typeField = useField('receivingBilling.type')
  const type = typeField.input.value
  const typeFieldDirty = typeField.meta.dirty

  const handleReceivingBillingChange = useCallback(receivingBillingField.input.onChange, [])

  useDidUpdateEffect(() => {
    if (typeFieldDirty) {
      handleReceivingBillingChange({ type })
    }
  }, [handleReceivingBillingChange, type, typeFieldDirty])

  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12} lg={3}>
        <Card>
          <CardHeader title="Settings" />
          <Divider />
          <CardContent>
            <ReceivingChargeTypeField name="receivingBilling.type" />
          </CardContent>
        </Card>
      </Grid>
      <Grid item={true} xs={12} lg={9}>
        <Card>
          <CardHeader title="Receiving" />
          <Divider />
          <CardContent>
            {!type && <EmptyTypeCard />}
            {type === RECEIVING_TYPES.PER_CONTAINER_WEIGHT && <ContainerWeightPricing />}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default BillingProfileReceiving
