import { map, path, pick, pipe, prop, propOr } from 'ramda'
import { DEFAULT_SEARCH_PARAMS } from 'storfox-route-hooks'

import { escapeAtob } from '~/utils'
import { RETAILER, TYPES } from '~/constants/companyTypes'

const FIELDS = [
  'message',
  'toEmail',
  'warehouses',
  'storefronts',
  'status',
  'billingProfile',
  'delegatedAccess'
]

const getStorefrontIds = pipe(
  propOr([], 'storefronts'),
  map(storefront => ({ id: prop('id', storefront) }))
)

export function ContractDecideSerializer (formValues, action) {
  const contractNumber = prop('contractNumber', formValues)

  return { action, contractNumber }
}

export function ContractCreateSerializer (formValues) {
  const storefrontIds = getStorefrontIds(formValues)

  const storefronts = storefrontIds.length ? storefrontIds : null

  const shipmentIntegrationId = path(['shipmentIntegration', 'id'], formValues)

  const shipmentClientId = path(['shipmentClient', 'id'], formValues)
  const id = shipmentClientId && shipmentClientId.toString()

  const shipmentClientName = path(['shipmentClient', 'name'], formValues)
  const delegatedAccess = prop('delegatedAccess', formValues)
  const delegatedUserGuid = delegatedAccess ? path(['delegatedUser', 'guid'], formValues) : null

  const values = pick(FIELDS, formValues)

  const shipmentClient = shipmentClientId ? { id, name: shipmentClientName } : undefined
  const shipmentIntegration = shipmentIntegrationId && { id: shipmentIntegrationId }

  return { ...values, delegatedUserGuid, shipmentClient, shipmentIntegration, storefronts }
}

export function ContractDetailInitSerializer (values) {
  const isOwner = prop('isOwner', values)
  const company = prop('company', values)
  const client = prop('client', values)
  const backendType = isOwner
    ? propOr(RETAILER, 'type', client)
    : propOr(RETAILER, 'type', company)

  const type = TYPES[backendType]

  return {
    ...values,
    type: type || 'No type yet'
  }
}

export function ContractFilterSerializer (params) {
  const defaultParams = pick(DEFAULT_SEARCH_PARAMS, params)
  const status = prop('status', params)
  const invitationStatus = prop('invitationStatus', params)
  const warehouseIds = escapeAtob(prop('warehouses', params))
  const client = escapeAtob(prop('company', params))
  const clientId = prop('id', client)

  return {
    ...defaultParams,
    status,
    invitationStatus,
    clientId,
    warehouseIds

  }
}
