import { filter, find, map, not, path, pathEq, pipe, prop, propEq, propOr } from 'ramda'

// CONTAINER
export const getContainerByNumber = (number, containers) =>
  find(propEq('number', number), containers)

export const getNewExistingProductsForContainer = (containerProducts, products) => map(containerProduct => {
  const containerProductUnit = prop('unit', containerProduct)
  const containerProductUnitGuid = prop('guid', containerProductUnit)
  const containerProductQuantity = prop('quantity', containerProduct)

  const product = find(pathEq(['unit', 'guid'], containerProductUnitGuid), products)
  const quantity = propOr(0, 'selectedQuantity', product)

  return { ...containerProduct, quantity: containerProductQuantity + quantity }
}, containerProducts)

export const getNewProductsForContainer = (containerProducts, products) => pipe(
  filter(product => {
    const unitGuid = path(['unit', 'guid'], product)
    const isScanned = prop('isScanned', product)
    const productAlreadyInContainer = find(pathEq(['unit', 'guid'], unitGuid), containerProducts)

    return isScanned && !productAlreadyInContainer
  }),
  map(product => ({
    ...product,
    quantity: prop('selectedQuantity', product),
    isActiveLineItem: false,
    isPacked: true,
    isScanned: false
  }))
)(products)

export const getNewContainers = (newContainer, containers) => map(container => {
  const newContainerNumber = prop('number', newContainer)
  return propEq('number', newContainerNumber, container) ? newContainer : container
}, containers)

export const getContainerProductsAfterRemove = (unitGuid, container) =>
  pipe(
    prop('lineItems'),
    filter(pipe(pathEq(['unit', 'guid'], unitGuid), not))
  )(container)

// PRODUCTS
export const getPartiallyScannedProductByUnitNumber = (unitNumber, products) =>
  find(product => (
    pathEq(['unit', 'unitNumber'], unitNumber, product) &&
    propOr(0, 'selectedQuantity', product) !== prop('quantity', product)
  ), products)

export const getNewProducts = (newProduct, products) => map(product => {
  const newProductGuid = path(['unit', 'guid'], newProduct)
  return pathEq(['unit', 'guid'], newProductGuid, product) ? newProduct : product
}, products)

export const getSimilarUnscannedProduct = (unitNumber, unitGuid, products) => find(product => {
  const matchUnitNumber = pathEq(['unit', 'unitNumber'], unitNumber, product)
  const isGuidDifferent = !pathEq(['unit', 'guid'], unitGuid, product)
  const isNotFull = propOr(0, 'selectedQuantity', product) < prop('quantity', product)

  return (
    matchUnitNumber &&
    isGuidDifferent &&
    isNotFull
  )
}, products)

export const getProductsWithActiveItem = (activeLineItem, productUnitGuid, products) => map(product => {
  const selectedQuantity = propOr(0, 'selectedQuantity', product)
  const activeLineItemQuantity = prop('selectedQuantity', activeLineItem)
  const newSelectedQuantity = selectedQuantity + activeLineItemQuantity

  return pathEq(['unit', 'guid'], productUnitGuid, product)
    ? { ...product, selectedQuantity: newSelectedQuantity, isScanned: true }
    : product
}, products)

// PRODUCT WITH SERIAL
export const getNextProductWithSerial = (unitNumber, activeSerialNumber, products) =>
  find(product => {
    const unitNumberMatch = pathEq(['unit', 'unitNumber'], unitNumber, product)
    const unmatchedSerial = !pathEq(['unit', 'serialNumber'], activeSerialNumber, product)
    const unscanned = propOr(0, 'selectedQuantity', product) === 0

    return unitNumberMatch && unmatchedSerial && unscanned
  }, products)

export const getProductsWithResetQuantity = map(product => {
  const quantity = propOr(0, 'selectedQuantity', product)
  const totalQuantity = prop('quantity', product)
  const newTotalQuantity = totalQuantity - quantity
  const isPacked = newTotalQuantity === 0

  return {
    ...product,
    isPacked,
    selectedQuantity: 0,
    quantity: newTotalQuantity,
    isScanned: false,
    isActiveLineItem: false,
  }
})

export const getUnscannedProductMatchedSerial = (unitNumber, serialNumber, products) => find(product => {
  const matchUnitNumber = pathEq(['unit', 'unitNumber'], unitNumber, product)
  const matchSerialNumber = pathEq(['unit', 'serialNumber'], serialNumber, product)
  const unscanned = propOr(0, 'selectedQuantity', product) === 0

  return matchUnitNumber && matchSerialNumber && unscanned
}, products)

export const getProductMatchingSerialActiveItem = (barcode, serialNumber, products) => find(product => {
  const barcodeMatch = pathEq(['unit', 'unitNumber'], barcode, product)
  const serialNumberMatch = pathEq(['unit', 'serialNumber'], serialNumber, product)
  const matchesQuantity = propOr(0, 'selectedQuantity', product) < prop('quantity', product)

  return barcodeMatch && serialNumberMatch && matchesQuantity
}, products)

export const getProductMatchingActiveItem = (barcode, products) => find(product => {
  const barcodeMatch = pathEq(['unit', 'unitNumber'], barcode, product)
  const matchesQuantity = propOr(0, 'selectedQuantity', product) < prop('quantity', product)

  return barcodeMatch && matchesQuantity
}, products)
