import React from 'react'
import PropTypes from 'prop-types'
import Comment from '@mui/icons-material/Comment'

import HtmlTooltip from '../HtmlTooltip/HtmlTooltip'

function TableNotes ({ children }) {
  if (!children) {
    return <Comment color="disabled" />
  }

  return (
    <HtmlTooltip title={children} sx={{ width: 'auto' }}>
      <Comment color="action" />
    </HtmlTooltip>
  )
}

TableNotes.propTypes = {
  children: PropTypes.node.isRequired
}

export default TableNotes
