import React from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import { FloatField } from 'storfox-form-fields'
import { useField } from 'react-final-form'
import { path } from 'ramda'

import CardHeader from '~/components/Cards/CardHeader'
import Container, { Content, Header } from '~/components/Container'
import PageTitle from '~/components/PageTitle'
import * as NAV from '~/constants/nav-titles'
import DiscardButton from '~/components/Buttons/DiscardButton'
import SaveButton from '~/components/Buttons/SaveButton'
import withForm from '~/components/Form/withForm'
import CurrencyField from '~/components/Fields/CurrencyField'

function CurrencyRateCreateForm ({ title, isLoading, pageTitleLoading, form }) {
  const { handleSubmit } = form
  const currencyField = useField('currency')
  const currencyCode = path(['input', 'value'], currencyField)

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={title}
            parentTitle={NAV.CONFIGURATION}
            pageTitleLoading={pageTitleLoading}
            rightButton={(
              <>
                <DiscardButton disabled={isLoading} />
                <SaveButton disabled={isLoading} />
              </>
            )}
          />
        </Header>

        <Content bottomSpace={true}>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12} lg={8}>
              <Box>

                <Card>
                  <CardHeader title="Currency Rate" />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12}>
                        <CurrencyField />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <FloatField
                          data-cy="rate"
                          name="rate"
                          label="Rate"
                          InputProps={{
                            endAdornment: currencyCode && (
                              <span>({currencyCode})</span>
                            ),
                            startAdornment: (
                              <Box component="span" sx={{ width: '60px' }}>1 USD =</Box>
                            )
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Content>
      </Container>
    </form>
  )
}

CurrencyRateCreateForm.propTypes = {
  title: PropTypes.string.isRequired,
  form: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  pageTitleLoading: PropTypes.bool
}

export default withForm(CurrencyRateCreateForm)
