import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import { prop, propOr } from 'ramda'
import { useFilter } from 'storfox-filter'

import useDialog from '~/hooks/useDialog'
import { getDateTimeGMTDifference, getDateTimeGMTUser } from '~/utils/date'

import IntegrationHistoryTable from './IntegrationHistoryTable'
import IntegrationHistoryDialog from './IntegrationHistoryDialog'
import IntegrationHistoryFilterForm from './IntegrationHistoryFilterForm'

export const toParams = values => {
  const createdAtStart = getDateTimeGMTDifference(prop('createdAtStart', values))
  const createdAtEnd = getDateTimeGMTDifference(prop('createdAtEnd', values))

  return {
    createdAtStart,
    createdAtEnd
  }
}

export const toValues = params => {
  const createdAtStart = getDateTimeGMTUser(prop('createdAtStart', params))
  const createdAtEnd = getDateTimeGMTUser(prop('createdAtEnd', params))

  return {
    createdAtStart,
    createdAtEnd
  }
}
export const transformer = { toParams, toValues }

export const shopifyHistoryFilterOptions = {
  name: 'integration-history',
  transformer
}

function IntegrationHistory ({ history, integrationType, onRetry, retryLoading, onRefetch }) {
  const rows = prop('results', history)
  const isLoading = prop('isLoading', history)
  const count = propOr(0, 'count', history)

  const filter = useFilter(shopifyHistoryFilterOptions)

  const { open, handleClose, handleOpen } = useDialog()
  const [selected, setSelected] = useState({})

  const handleChangesOpen = values => {
    handleOpen()
    setSelected(values)
  }

  return (
    <Box width="100%">
      <IntegrationHistoryFilterForm {...filter} />
      <IntegrationHistoryTable
        rows={rows}
        count={count}
        isLoading={isLoading}
        onClick={handleChangesOpen}
        integrationType={integrationType}
        onRetry={onRetry}
        retryLoading={retryLoading}
        filterOpen={filter.handleOpen}
        onRefetch={onRefetch}
      />
      <IntegrationHistoryDialog
        open={open}
        onClose={handleClose}
        selected={selected}
      />
    </Box>
  )
}

IntegrationHistory.propTypes = {
  history: PropTypes.object,
  integrationType: PropTypes.string,
  onRetry: PropTypes.func,
  retryLoading: PropTypes.bool,
  onRefetch: PropTypes.func
}

export default IntegrationHistory
