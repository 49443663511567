import React from 'react'
import PropTypes from 'prop-types'

import Status from '~/components/Status'

import { CANCELLED, COMPLETED, DRAFT, IN_PROGRESS, STATUSES } from '../../constants'

const COLORS = {
  [DRAFT]: 'warning',
  [IN_PROGRESS]: 'warning',
  [COMPLETED]: 'success',
  [CANCELLED]: 'error'
}

function TransferStatus ({ status }) {
  return (
    <Status
      label={STATUSES[status]}
      color={COLORS[status]}
    />
  )
}

TransferStatus.propTypes = {
  status: PropTypes.oneOf([DRAFT, IN_PROGRESS, COMPLETED, CANCELLED])
}

export default TransferStatus
