import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import BrandListContainer from './containers/BrandListContainer'
import BrandCreateContainer from './containers/BrandCreateContainer'
import BrandUpdateContainer from './containers/BrandUpdateContainer'
import BrandDetailContainer from './containers/BrandDetailContainer'

export default () => {
  return [
    {
      layout: AuthLayout,
      path: ROUTES.BRAND_CREATE_PATH,
      component: BrandCreateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.BRAND_DETAIL_PATH,
      component: BrandDetailContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.BRAND_UPDATE_PATH,
      component: BrandUpdateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.BRAND_LIST_PATH,
      component: BrandListContainer
    }
  ]
}
