import { pathEq, prop } from 'ramda'

import { H_SPACE_BETWEEN_NODES, NODE_HEIGHT, NODE_WIDTH, V_SPACE_BETWEEN_NODES } from './constants'

export const getNodes = data => {
  const mainNodes = data.map((node, index) => {
    return {
      ...node,
      id: index,
      position: {
        x: NODE_WIDTH + H_SPACE_BETWEEN_NODES,
        y: (NODE_HEIGHT + V_SPACE_BETWEEN_NODES) * index
      }
    }
  })

  const rightNodes = mainNodes
    .filter(node => {
      return prop('mergedUnit', node) && pathEq(['mergedUnit', 'type'], 'increase', node)
    })
    .map(node => ({
      ...prop('mergedUnit', node),
      parentId: node.id,
      position: {
        x: (NODE_WIDTH + H_SPACE_BETWEEN_NODES) * 2,
        y: node.position.y
      }
    }))

  const leftNodes = mainNodes
    .filter(node => {
      return prop('mergedUnit', node) && pathEq(['mergedUnit', 'type'], 'decrease', node)
    })
    .map(node => ({
      ...prop('mergedUnit', node),
      childId: node.id,
      position: { x: 0, y: node.position.y }
    }))

  return { mainNodes, leftNodes, rightNodes }
}

export const getLeftLines = (leftNodes, mainNodes) => {
  return [...leftNodes]
    .map((leftNode) => {
      const childId = leftNode.childId
      const childNode = mainNodes.find(node => node.id === childId)

      return {
        position1: {
          x: leftNode.position.x + NODE_WIDTH,
          y: leftNode.position.y + NODE_HEIGHT / 2
        },
        position2: {
          x: childNode.position.x,
          y: childNode.position.y + NODE_HEIGHT / 2
        }
      }
    })
}

export const getRightLines = (rightNodes, mainNodes) => {
  return [...rightNodes]
    .map((rightNode) => {
      const parentId = rightNode.parentId
      const parentNode = mainNodes.find(node => node.id === parentId)

      return {
        position1: {
          x: parentNode.position.x + NODE_WIDTH,
          y: parentNode.position.y + NODE_HEIGHT / 2
        },
        position2: {
          x: rightNode.position.x,
          y: rightNode.position.y + NODE_HEIGHT / 2
        }
      }
    })
}

export const getVerticalLines = data => {
  return [...data]
    .map((node, index) => {
      const nextNode = data[index + 1]
      if (nextNode) {
        return {
          position1: {
            x: nextNode.position.x + NODE_WIDTH / 2,
            y: nextNode.position.y
          },
          position2: {
            x: node.position.x + NODE_WIDTH / 2,
            y: node.position.y + NODE_HEIGHT
          }
        }
      }
    })
    .slice(0, -1)
}
