import { styled } from '@mui/material'
import Box from '@mui/material/Box'

import { BlankLayout } from '~/components/Layouts'

export const BlankLayoutStyled = styled(BlankLayout)(({ theme }) => ({
  padding: theme.spacing(3),
  paddingTop: '10vh',
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center'
}))

export const ImageContainerStyled = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(6),
  display: 'flex',
  justifyContent: 'center',
  '& > *': {
    maxWidth: '100%',
    width: 560,
    maxHeight: 300,
    height: 'auto'
  }
}))

export const ButtonContainerStyled = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(6),
  display: 'flex',
  justifyContent: 'center'
}))
