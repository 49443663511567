import React from 'react'

import AppContainer from '~/components/AppContainer'

import Confirmation from '../components/Confirmation/Confirmation'
import { useContractDecide, useEmailCheck } from '../hooks'

function ConfirmationContainer () {
  const contractDecide = useContractDecide()
  const emailCheck = useEmailCheck()

  const handleSubmit = (contractNumber, action) =>
    contractDecide.update({ contractNumber, action })

  const handleEmailCheck = email =>
    emailCheck.check({ email })

  return (
    <AppContainer title="Confirm">
      <Confirmation
        onSubmit={handleSubmit}
        onEmailCheck={handleEmailCheck}
      />
    </AppContainer>
  )
}

export default ConfirmationContainer
