import React from 'react'
import PropTypes from 'prop-types'
import { DatePicker } from '@mui/x-date-pickers'
import { TextField } from '@mui/material'

function ActiveExpiresAtField (props) {
  const { value, onChange, mask = '__.__.____', inputFormat = 'dd.MM.yyyy', error, dataCy, ...restProps } = props
  return (
    <DatePicker
      value={value || null}
      onChange={onChange}
      inputFormat={inputFormat}
      mask={mask}
      data-cy={dataCy}
      OpenPickerButtonProps={{ sx: { mr: 0 } }}
      renderInput={props => <TextField {...props} data-cy={dataCy} error={error} size="small" />}
      InputProps={{ sx: { pr: 0 } }}
      closeOnSelect={true}
      {...restProps}
    />
  )
}

ActiveExpiresAtField.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  mask: PropTypes.string,
  inputFormat: PropTypes.string,
  error: PropTypes.string,
  dataCy: PropTypes.string
}

export default ActiveExpiresAtField
