import React from 'react'
import ImageUpload from 'storfox-image-upload'
import PropTypes from 'prop-types'

import { useImageUpload } from '~/components/ImageUpload'

function ContainerTypeImageUpload ({ name }) {
  const { upload } = useImageUpload()

  const onUpload = (data, onProgress) =>
    upload(data, onProgress)
      .then(response => {
        const imagePath = response['ids'][0]
        const isDefault = response['ids'].length === 0
        return { imagePath, isDefault }
      })

  return (
    <ImageUpload onUpload={onUpload} name={name} />
  )
}

ContainerTypeImageUpload.defaultProps = {
  name: 'images'
}

ContainerTypeImageUpload.propTypes = {
  name: PropTypes.string.isRequired
}

export default ContainerTypeImageUpload
