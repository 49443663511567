import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import { SingleImagePreview } from 'storfox-image-upload'
import { prop } from 'ramda'
import PropTypes from 'prop-types'

import { toNamesArray } from '~/utils'
import TextLabelLoader from '~/components/TextLabelLoader'
import Tags from '~/components/Tags'
import BooleanStatus from '~/components/BooleanStatus'
import Language from '~/components/Language/Language'
import { CardHeader } from '~/components/Cards'

function UserDetailGeneral ({ user }) {
  const firstName = prop('firstName', user)
  const lastName = prop('lastName', user)
  const email = prop('email', user)
  const phone = prop('phone', user)
  const warehouses = toNamesArray(prop('warehouses', user))
  const roles = toNamesArray(prop('roles', user))
  const imagePath = prop('imagePath', user)
  const status = prop('status', user)
  const language = prop('language', user)

  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12} lg={8}>
        <Box mb={3}>
          <Card>
            <CardHeader title="Information" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12} lg={6}>
                  <TextLabelLoader label="First name">
                    {firstName}
                  </TextLabelLoader>
                </Grid>
                <Grid item={true} xs={12} lg={6}>
                  <TextLabelLoader label="Last name">
                    {lastName}
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Email">
                    {email}
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Phone number">
                    {phone}
                  </TextLabelLoader>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Box mb={3}>
          <Card>
            <CardHeader title="Access" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Warehouses">
                    <Tags items={warehouses} size="medium" />
                  </TextLabelLoader>
                </Grid>
                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Roles">
                    <Tags items={roles} size="medium" />
                  </TextLabelLoader>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>
      <Grid item={true} xs={12} lg={4}>
        <Box mb={3}>
          <Card>
            <CardHeader title="Profile Photo" />
            <Divider />
            <CardContent>
              <SingleImagePreview src={imagePath} />
            </CardContent>
          </Card>
        </Box>
        <Box mb={3}>
          <Card>
            <CardHeader title="Organization" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Status">
                    <BooleanStatus value={status} />
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Language">
                    <Language value={language} />
                  </TextLabelLoader>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </Grid>
  )
}

UserDetailGeneral.propTypes = {
  user: PropTypes.object.isRequired
}

export default UserDetailGeneral
