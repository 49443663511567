import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { prop } from 'ramda'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { toCamelCase, useDeepCompareEffect } from 'storfox-api-hooks'

import { base64ToObject } from '~/utils'
import { useToken } from '~/components/Token'
import Logo from '~/components/Logo'
import * as ROUTES from '~/constants/routes'
import CenteredCard from '~/components/CenteredCard'

import { CardContentStyled } from '../styled/Auth.styled'

const ACCEPT = 'accept'

const toObject = data => {
  try {
    return toCamelCase(base64ToObject(data))
  } catch (e) {}
}

function Confirmation ({ onSubmit, onEmailCheck }) {
  const { t } = useTranslation()
  const { contract } = useParams()
  const { token } = useToken()
  const navigate = useNavigate()
  const timeoutRef = useRef(0)
  const contractInfo = toObject(contract)
  const contractNumber = prop('contractNumber', contractInfo)
  const email = prop('email', contractInfo)

  const redirectTimer = path => setTimeout(() => {
    navigate(path, { replace: true })
  }, 1500)

  useDeepCompareEffect(() => {
    if (token) {
      onSubmit(contractNumber, ACCEPT)
        .finally(() => {
          timeoutRef.current = redirectTimer(ROUTES.CONTRACT_LIST_PATH)
        })
    }
    if (!token) {
      onEmailCheck(email)
        .then(() => {
          const path = generatePath(ROUTES.SIGN_UP_CONTRACT_NUMBER, { contract })
          timeoutRef.current = redirectTimer(path)
        })
        .catch(() => {
          const path = generatePath(ROUTES.SIGN_IN_CONTRACT_NUMBER, { contract })
          timeoutRef.current = redirectTimer(path)
        })
    }
    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [token])

  return (
    <CenteredCard size="xs">
      <CardContentStyled>
        <Box>
          <Grid container={true} spacing={1} justifyContent="center">
            <Grid item={true} xs={12}>
              <Box sx={{ textAlign: 'center' }}>
                <Link
                  component="a"
                  href="https://storfox.com/"
                >
                  <Logo />
                </Link>
              </Box>
            </Grid>
            <Grid item={true} xs={12}>
              <Box mb={1}>
                <Typography align="center" variant="h4" color="primary">
                  {t('Please wait while redirecting...')}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </CardContentStyled>
    </CenteredCard>
  )
}
Confirmation.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onEmailCheck: PropTypes.func.isRequired
}
export default Confirmation
