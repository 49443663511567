import { prop } from 'ramda'
import React from 'react'
import { useParams } from 'react-router-dom'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import Title from '~/components/Title'
import * as NAV from '~/constants/nav-titles'
import ErrorLink from '~/components/ErrorLink'
import useMessages from '~/hooks/useMessages'
import { SALE_RETURN_TABS } from '~/constants/tabs'

import ReturnDetail from '../components/ReturnDetail/ReturnDetail'
import { useReturnDetail, useReturnHistory, useReturnProcess } from '../hooks'
import { ReturnInitUpdateSerializer } from '../serializers'

function ReturnDetailContainer () {
  const { guid, tab } = useParams()
  const snackbar = useSnackbar()
  const messages = useMessages()
  const returnDetail = useReturnDetail(guid)
  const returnProcess = useReturnProcess(guid)
  const orderReturnHistory = useReturnHistory(guid, tab === SALE_RETURN_TABS.HISTORY)

  const initialValues = ReturnInitUpdateSerializer(returnDetail.detail)

  const pageLoading = returnDetail.isLoading

  const title = prop('number', returnDetail.detail)
  const pageTitle = (
    <Title title="Return" number={title} />
  )

  const isLoading = returnDetail.isLoading

  const breadcrumbs = { title }

  const handleProcess = () =>
    returnProcess.process({ return: { guid } })
      .then(() => returnDetail.getDetail())
      .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
        return Promise.reject(error)
      })

  return (
    <DashboardLayout
      title={title}
      isLoading={pageLoading}
      activeNav={NAV.SALE_RETURNS}
      breadcrumbs={breadcrumbs}
    >
      <ReturnDetail
        pageTitle={pageTitle}
        pageTitleLoading={returnDetail.isLoading}
        detail={initialValues}
        isLoading={isLoading}
        onProcess={handleProcess}
        orderReturnHistory={orderReturnHistory}
      />
    </DashboardLayout>
  )
}

export default ReturnDetailContainer
