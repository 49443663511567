import React from 'react'
import { Ordering } from 'storfox-filter'

import { PACKING_COLUMNS as tableColumns } from './PackingTableForm'

import { FILTER_NAME as name } from '../../constants'

export const packingOrderingOptions = {
  name,
  tableColumns
}

function PackingOrderingForm (props) {
  return (
    <Ordering {...props} />
  )
}

export default PackingOrderingForm
