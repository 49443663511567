import React, { useCallback, useEffect, useRef } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import { equals, filter, find, isEmpty, path, pathEq, prop, propEq } from 'ramda'
import { useField } from 'react-final-form'
import { useConfirm } from 'storfox-confirm-dialog'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import { matchContainer } from '~/utils'
import { DONE } from '~/constants/barcode'
import BarcodeDoneInstruction from '~/components/Instructions/BarcodeDoneInstruction'
import BarcodeField from '~/components/BarcodeField'
import { useScreenOutline } from '~/components/ScreenOutline'
import { emptyArray } from '~/constants/empty'

import PutawayCreateTables from './PutawayCreateTables'
import DestinationPreview from './DestinationPreview'
import PutawayTitle from './PutawayTitle'

import ProductPreviewCard from '../ProductPreviewCard'
import {
  decreaseUnitQuantity,
  findUnitByGuid,
  getNewLocationUnits,
  getUnitsAfterSerialRemove,
  increaseUnitQuantity,
  removeUnitsFromSource
} from '../../utils'

const throwError = message => {
  throw Error(message)
}

function LineItemsGroup ({ isLoading, onScanItem, handleSubmit }) {
  const snackbar = useSnackbar()
  const barcodeRef = useRef(null)
  const { handleSoundedTrigger, handleSoundedErrorTrigger } = useScreenOutline()
  const onConfirm = useConfirm()

  const barcodeField = useField('barcode')
  const locationField = useField('location')
  const locationOnChange = locationField.input.onChange
  const fromLocation = locationField.input.value

  const sourceUnitsField = useField('locationContent')
  const sourceUnitsOnChange = sourceUnitsField.input.onChange
  const sourceUnits = sourceUnitsField.input.value

  const destUnitsField = useField('units')
  const destUnitsOnChange = destUnitsField.input.onChange
  const destUnits = destUnitsField.input.value || emptyArray

  const activeItemField = useField('activeLineItem')
  const activeItem = activeItemField.input.value || null
  const activeItemOnChange = activeItemField.input.onChange
  const activeItemQuantity = prop('quantity', activeItem)
  const activeItemTotalQuantity = prop('totalQuantity', activeItem)
  const activeItemUnitNumber = prop('unitNumber', activeItem)
  const activeItemTrackSerial = path(['variant', 'trackSerial'], activeItem)
  const activeItemSerialNumber = prop('serialNumber', activeItem)
  const isActiveItemFullyScanned = equals(activeItemQuantity, activeItemTotalQuantity)
  const isActiveItemWithSerialNumber = activeItemTrackSerial && activeItemSerialNumber

  const focusBarcodeField = () => barcodeRef.current.focus()
  const clearBarcodeField = () => barcodeField.input.onChange('')
  const clearActiveLineItemField = () => activeItemOnChange(null)
  const clearLocation = () => {
    locationOnChange(null)
    sourceUnitsOnChange(null)
    activeItemOnChange(null)
    destUnitsOnChange(null)
  }

  useEffect(() => {
    if (!isLoading) {
      focusBarcodeField()
    }
  }, [isLoading])

  const showError = message => {
    handleSoundedErrorTrigger()
    snackbar({ message, type: ALTER_ERROR })
    clearBarcodeField()
  }

  const removeAllUnitsInsideContainer = useCallback(containerNumber => {
    const newDestUnits = filter(unit => (
      !pathEq(['container', 'number'], containerNumber, unit)
    ), destUnits)

    const unitsInsideContainer = filter(pathEq(['container', 'number'], containerNumber), destUnits)
    const newSourceUnits = [...unitsInsideContainer, ...sourceUnits]

    sourceUnitsOnChange(newSourceUnits)
    destUnitsOnChange(newDestUnits)
  }, [destUnits, destUnitsOnChange, sourceUnits, sourceUnitsOnChange])

  const handleContainerUnitsRemove = useCallback(containerNumber =>
    onConfirm({ message: 'All the units inside this container will be removed' })
      .agree(() => {
        removeAllUnitsInsideContainer(containerNumber)
      })
      .disagree()
  , [onConfirm, removeAllUnitsInsideContainer])

  const handleUnitDelete = useCallback(item => {
    const itemGuid = prop('guid', item)
    const newDestUnits = filter(unit => !propEq('guid', itemGuid, unit), destUnits)
    const foundUnit = findUnitByGuid(itemGuid, sourceUnits)

    const trackSerial = path(['variant', 'trackSerial'], item)
    const containerNumber = path(['container', 'number'], item)

    if (containerNumber) {
      handleContainerUnitsRemove(containerNumber)
      return
    }

    if (foundUnit) {
      const newLocationUnits = getNewLocationUnits(item, foundUnit, sourceUnits)
      destUnitsOnChange(newDestUnits)
      sourceUnitsOnChange(newLocationUnits)
    }

    if (!foundUnit || trackSerial) {
      destUnitsOnChange(newDestUnits)
      sourceUnitsOnChange([item, ...sourceUnits])
    }
  }, [destUnits, destUnitsOnChange, handleContainerUnitsRemove, sourceUnits, sourceUnitsOnChange])

  const getNewActiveLineItem = unit => {
    const guid = prop('guid', unit)
    const unitNumber = prop('unitNumber', unit)
    const quantity = prop('quantity', unit)
    const variant = prop('variant', unit)

    return unit ? { guid, unitNumber, variant, quantity: 1, totalQuantity: quantity } : null
  }

  const scanUnitWithoutSerial = guid => {
    const fromUnit = findUnitByGuid(guid, sourceUnits)
    const fromUnitGuid = prop('guid', fromUnit)
    const activeLineItemGuid = prop('guid', activeItem)
    const newActiveLineItem = getNewActiveLineItem(fromUnit)

    if (equals(guid, activeLineItemGuid)) {
      const newQuantity = activeItemQuantity + 1
      activeItemOnChange({ ...activeItem, quantity: newQuantity })
      handleSoundedTrigger()
      return
    }

    if (equals(guid, fromUnitGuid)) {
      const unitsMergedActiveItem = getUnitsMergedActiveItem()
      if (!unitsMergedActiveItem) {
        return
      }

      sourceUnitsOnChange(unitsMergedActiveItem.sourceUnits)
      destUnitsOnChange(unitsMergedActiveItem.destUnits)
      activeItemOnChange(newActiveLineItem)
      handleSoundedTrigger()
    }
  }

  const scanContainer = barcode => {
    if (activeItem) {
      const unitsMergedActiveItem = getUnitsMergedActiveItem()

      clearActiveLineItemField()

      const scannedUnitsWithContainer = filter(
        pathEq(['container', 'number'], barcode), unitsMergedActiveItem.sourceUnits
      )
      const newSourceUnits = removeUnitsFromSource(barcode, unitsMergedActiveItem.sourceUnits)

      sourceUnitsOnChange(newSourceUnits)
      destUnitsOnChange([...unitsMergedActiveItem.destUnits, ...scannedUnitsWithContainer])
    }
    if (!activeItem) {
      const scannedUnitsWithContainer = filter(pathEq(['container', 'number'], barcode), sourceUnits)
      const newSourceUnits = removeUnitsFromSource(barcode, sourceUnits)

      sourceUnitsOnChange(newSourceUnits)
      destUnitsOnChange([...destUnits, ...scannedUnitsWithContainer])
    }

    handleSoundedTrigger()
  }

  const putActiveItemToDest = () => {
    const unitsMergedActiveItem = getUnitsMergedActiveItem()
    sourceUnitsOnChange(unitsMergedActiveItem.sourceUnits)
    destUnitsOnChange(unitsMergedActiveItem.destUnits)
    clearActiveLineItemField()
    handleSoundedTrigger()
  }

  const validateUnitSerialInSourceAndValid = () => {
    if (activeItemTrackSerial && !activeItemSerialNumber) {
      throwError('Check serial number in active unit.')
    }

    const isUnitSerialFoundOutsideContainer = find(unit => (
      propEq('serialNumber', activeItemSerialNumber, unit) &&
      propEq('unitNumber', activeItemUnitNumber, unit) &&
      !prop('container', unit)
    ), sourceUnits)

    if (isActiveItemWithSerialNumber && !isUnitSerialFoundOutsideContainer) {
      throwError('Wrong serial number in active unit.')
    }
  }

  const getUnitsMergedActiveItem = () => {
    validateUnitSerialInSourceAndValid()

    if (isActiveItemWithSerialNumber) {
      const newSourceUnits = getUnitsAfterSerialRemove(activeItem, sourceUnits)
      const newDestUnits = [activeItem, ...destUnits]

      return { sourceUnits: newSourceUnits, destUnits: newDestUnits }
    }

    const isInDestUnits = find(unit => {
      const activeLineItemGuid = prop('guid', activeItem)
      return propEq('guid', activeLineItemGuid, unit)
    }, destUnits)

    if (isInDestUnits) {
      const newSourceUnits = decreaseUnitQuantity(activeItem, sourceUnits)
      const newDestUnits = increaseUnitQuantity(activeItem, destUnits)

      return { sourceUnits: newSourceUnits, destUnits: newDestUnits }
    }

    const newSourceUnits = decreaseUnitQuantity(activeItem, sourceUnits)
    const newDestUnits = [activeItem, ...destUnits]

    return { sourceUnits: newSourceUnits, destUnits: newDestUnits }
  }

  const handleSerialNumberScan = serialNumber => {
    const matchedUnit = find(unit => (
      propEq('serialNumber', serialNumber, unit) &&
      propEq('unitNumber', activeItemUnitNumber, unit) &&
      !prop('container', unit)
    ), sourceUnits)

    if (matchedUnit) {
      const totalQuantity = prop('quantity', matchedUnit)
      activeItemOnChange({ ...matchedUnit, totalQuantity })
      handleSoundedTrigger()
      return
    }

    throwError('Invalid serial number.')
  }

  const getNextUnitToReplaceActiveItem = (unit, units) => {
    if (!activeItem) {
      return null
    }

    if (isActiveItemFullyScanned) {
      const activeLineItemGuid = prop('guid', activeItem)
      return find(item => !propEq('guid', activeLineItemGuid, item), units)
    }

    if (!propEq('unitNumber', activeItemUnitNumber, unit)) {
      return unit
    }

    return null
  }

  const handleUnitScan = barcode => {
    const unit = sourceUnits.find(propEq('unitNumber', barcode))
    const unitGuid = prop('guid', unit)
    const unitTrackSerial = path(['variant', 'trackSerial'], unit)
    const nextUnit = getNextUnitToReplaceActiveItem(unit, sourceUnits)

    if (!unit) {
      throwError('Unit not found.')
    }

    if (!activeItem) {
      const newActiveLineItem = getNewActiveLineItem(unit)
      activeItemOnChange(newActiveLineItem)
      handleSoundedTrigger()
      return
    }

    if (!unitTrackSerial && !isActiveItemFullyScanned) {
      scanUnitWithoutSerial(unitGuid)
      return
    }

    if (nextUnit) {
      const unitsMergedActiveItem = getUnitsMergedActiveItem()
      if (!unitsMergedActiveItem) {
        return
      }
      const activeItem = getNewActiveLineItem(nextUnit)
      sourceUnitsOnChange(unitsMergedActiveItem.sourceUnits)
      destUnitsOnChange(unitsMergedActiveItem.destUnits)
      activeItemOnChange(activeItem)
      handleSoundedTrigger()
      return
    }

    throwError('No such unit.')
  }

  const handleBarcodeScan = event => {
    try {
      const barcode = event.target.value.trim()
      event.preventDefault()

      if (barcode === DONE) {
        handleDone(barcode)
        clearBarcodeField()
        return
      }

      if (!sourceUnits) {
        onScanItem(barcode)
          .then(({ data }) => {
            const results = prop('results', data)
            if (results) {
              sourceUnitsOnChange(results)
              locationOnChange(barcode)
              handleSoundedTrigger()
            }

            if (!results) {
              showError('No units found in this location.')
            }
          })
          .catch(() => showError('No units found in this location.'))
        clearBarcodeField()
      }

      if (matchContainer(barcode)) {
        scanContainer(barcode)
        clearBarcodeField()
        return
      }

      const isSerialNumber = activeItemTrackSerial && !activeItemSerialNumber

      if (isSerialNumber) {
        handleSerialNumberScan(barcode)
        clearBarcodeField()
        return
      }

      if (sourceUnits) {
        handleUnitScan(barcode)
        clearBarcodeField()
      }
    } catch (error) {
      showError(error.message)
    }
  }

  const handleDone = () => {
    if (activeItem) {
      putActiveItemToDest()
    }

    if (!activeItem) {
      handleSubmit()
      clearActiveLineItemField()
      focusBarcodeField()
    }
  }

  const handleActiveItemPut = () => {
    try {
      putActiveItemToDest()
    } catch (error) {
      showError(error.message)
    }
  }

  return (
    <>
      <Grid container={true} spacing={2} alignItems="flex-end">
        <Grid item={true} lg={4} xs={12}>
          <PutawayTitle
            isLocationEmpty={isEmpty(fromLocation)}
            trackSerial={Boolean(activeItemTrackSerial)}
          />
          <Box mt={2}>
            <BarcodeField
              focusBarcodeField={focusBarcodeField}
              barcodeRef={barcodeRef}
              disabled={isLoading}
              onEnter={handleBarcodeScan}
            />
          </Box>
        </Grid>

        <Grid item={true} lg={4} xs={12}>
          <DestinationPreview
            sourceLocation={fromLocation}
            onSourceLocationRemove={clearLocation}
          />
        </Grid>

        <Grid item={true} lg={4} xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <BarcodeDoneInstruction doneText={DONE} />
          </Box>
        </Grid>
      </Grid>

      <Box my={2} />

      <Grid container={true} spacing={3}>
        <Grid item={true} xs={12}>
          <ProductPreviewCard
            onActiveItemPut={handleActiveItemPut}
            maxValue={activeItemTotalQuantity}
            fieldName="activeLineItem"
            onRemove={clearActiveLineItemField}
            onBarcodeFocus={focusBarcodeField}
            product={activeItem}
          />
        </Grid>
      </Grid>

      <Box mt={1}>
        <PutawayCreateTables
          onUnitDelete={handleUnitDelete}
          fromLocation={fromLocation}
          isLoading={isLoading}
          sourceUnits={sourceUnits}
          destUnits={destUnits}
        />
      </Box>
    </>
  )
}

LineItemsGroup.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onScanItem: PropTypes.func,
  handleSubmit: PropTypes.func
}

export default LineItemsGroup
