import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import DialogContent from '@mui/material/DialogContent'
import Dialog from '@mui/material/Dialog'
import { path, prop, propOr } from 'ramda'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import Checkbox from '@mui/material/Checkbox/Checkbox'
import DialogActions from '@mui/material/DialogActions/DialogActions'
import InfiniteScroll from 'react-infinite-scroll-component'
import { styled } from '@mui/material'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useField } from 'react-final-form'
import TableHead from '@mui/material/TableHead'
import { useTranslation } from 'react-i18next'

import DialogTitle from '~/components/DialogTitle'
import TextOverflow from '~/components/TextOverflow'
import { Button, DiscardButton } from '~/components/Buttons'
import InfinityScrollSearch from '~/components/InfinityScrollSearch'

import VariantTableSkeletonList from './VariantTableSkeletonList'

const TableRowStyled = styled(TableRow)({
  cursor: 'pointer',
  '&:hover': {
    background: '#0000000A'
  }
})

const DialogContentStyled = styled(DialogContent)({
  padding: '0',
  position: 'relative',
  height: 'calc(100vh / 2)'
})

function ReturnVariantAddDialog ({ open, onClose, onAddVariants, params, returnVariants }) {
  const { t } = useTranslation()
  const [selected, setSelected] = useState([])

  const results = propOr([], 'results', returnVariants)
  const count = prop('count', returnVariants)

  const handleCheck = (checked, variant) => {
    const id = prop('id', variant)
    const condition = prop('condition', variant)
    const conditionId = prop('id', condition)
    const variantWithKey = { ...variant, key: `${id}-${conditionId}` }

    if (checked) {
      setSelected(prevSelected => prevSelected.filter(select => prop('key', select) !== prop('key', variantWithKey)))
    } else {
      setSelected(prevSelected => [ ...prevSelected, variantWithKey ])
    }
  }

  const searchField = useField('search')
  const searchFieldValue = searchField.input.value

  const onEscape = useCallback(event => {
    if (event.code === 'Escape') {
      onClose()
    }
  }, [onClose])

  const handleAddVariants = useCallback(() => {
    onAddVariants(selected)
    setSelected([])
  }, [selected, onAddVariants])

  return (
    <Dialog open={open} maxWidth="md" fullWidth={true} onKeyDown={onEscape}>
      <DialogTitle>
        <Typography
          component="h2"
          variant="h6"
        >
          {`${t('Browse Variants')} - ${t('Selected')} (${selected.length})`}
        </Typography>
        <Box sx={{ padding: '24px 0' }}>
          <InfinityScrollSearch
            onEffect={returnVariants.reset}
            getList={returnVariants.getList}
            params={params}
          />
        </Box>
      </DialogTitle>
      <DialogContentStyled id="returnVariantTable">
        <InfiniteScroll
          dataLength={results.length}
          hasMore={count > results.length}
          loader=""
          next={() => returnVariants.getList({ search: searchFieldValue, ...params })}
          scrollableTarget="returnVariantTable"
        >
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width={70}>{t('Select')}</TableCell>
                  <TableCell>{t('Variant')}</TableCell>
                  <TableCell>{t('Condition')}</TableCell>
                  <TableCell>{t('Quantity')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {results.map((variant, index) => {
                  const checked = selected.find(select => prop('guid', variant) === prop('guid', select))
                  const quantity = prop('quantity', variant)
                  const condition = path(['condition', 'code'], variant)
                  return (
                    <TableRowStyled onClick={() => handleCheck(checked, variant)}>
                      <TableCell data-cy={`variant-${index}`}>
                        <Checkbox checked={Boolean(checked)} color="secondary" />
                      </TableCell>
                      <TableCell>
                        <TextOverflow
                          lines={1}
                          selfTooltip={true}
                        >
                          {prop('name', variant)}
                        </TextOverflow>
                      </TableCell>
                      <TableCell>{condition}</TableCell>
                      <TableCell>{quantity}</TableCell>
                    </TableRowStyled>
                  )
                })}
                {returnVariants.isLoading && (
                  <VariantTableSkeletonList />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </InfiniteScroll>
      </DialogContentStyled>
      <DialogActions>
        <DiscardButton
          onClick={onClose}
          fullWidth={true}
        >
          Discard
        </DiscardButton>
        <Button
          variant="contained"
          data-cy="add"
          fullWidth={true}
          onClick={handleAddVariants}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ReturnVariantAddDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAddVariants: PropTypes.func.isRequired,
  params: PropTypes.object,
  returnVariants: PropTypes.object.isRequired
}

export default ReturnVariantAddDialog
