import React from 'react'
import PropTypes from 'prop-types'

import Status from '~/components/Status'

export const NEW = 'new'
export const PENDING = 'pending'
export const IN_PROGRESS = 'in_progress'
export const CANCELLED = 'cancelled'
export const COMPLETED = 'completed'
export const ON_HOLD = 'on_hold'

export const PACKING_STATUS = {
  [NEW]: 'New',
  [IN_PROGRESS]: 'In progress',
  [CANCELLED]: 'Cancelled',
  [COMPLETED]: 'Completed',
  [ON_HOLD]: 'On hold'
}

const COLOR = {
  [NEW]: 'success',
  [IN_PROGRESS]: 'warning',
  [ON_HOLD]: 'warning',
  [CANCELLED]: 'error',
  [COMPLETED]: 'success'
}

function PackingStatus ({ value }) {
  return (
    <Status
      label={PACKING_STATUS[value] || value}
      color={COLOR[value]}
    />
  )
}

PackingStatus.propTypes = {
  value: PropTypes.string.isRequired
}

export default PackingStatus
