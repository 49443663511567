import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import { Divider } from '@mui/material'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import { sprintf } from 'sprintf-js'
import { useField } from 'react-final-form'
import { pathOr, prop } from 'ramda'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell/TableCell'
import TableBody from '@mui/material/TableBody'
import { useTranslation } from 'react-i18next'
import Checkbox from '@mui/material/Checkbox'

import PageTitle from '~/components/PageTitle'
import { withForm } from '~/components/Form'
import * as NAV from '~/constants/nav-titles'
import FluidContainer, { FluidContent, FluidHeader } from '~/components/FluidContainer'
import DiscardButton from '~/components/Buttons/DiscardButton'
import SaveButton from '~/components/Buttons/SaveButton'
import { CardHeader } from '~/components/Cards'
import * as API from '~/constants/api'
import PositiveNumberField from '~/components/Fields/PositiveNumberField'

import LotContainerTypeField from './LotContainerTypeField'
import BucketField from './BucketField'

function LotSheetsShipmentForm (props) {
  const { pageTitle, form, isLoading, guid, getBucketList } = props
  const { t } = useTranslation()

  const { handleSubmit } = form

  const bucketListUrl = sprintf(API.LOT_BUCKET_LIST, guid)
  const bucketsField = useField('buckets')
  const bucketsValue = bucketsField.input.value || []
  const bucketsChange = bucketsField.input.onChange
  const containersField = useField('containers')
  const containersValue = containersField.input.value || []
  const containersChange = containersField.input.onChange

  const onGetBucketList = useCallback((e, value) => {
    getBucketList(value).then((response) => {
      const results = pathOr([], ['data', 'results'], response)
      bucketsChange(results)
    })
  }, [bucketsChange, getBucketList])

  const onContainerCheck = useCallback((bucket) => {
    const foundBucket = containersValue.find(item => prop('number', item) === bucket)
    if (foundBucket) {
      containersChange(containersValue.filter(item => prop('number', item) !== bucket))
    } else {
      containersChange([...containersValue, { number: bucket }])
    }
  }, [containersChange, containersValue])

  return (
    <form onSubmit={handleSubmit}>
      <FluidContainer>
        <FluidHeader>
          <PageTitle
            parentTitle={NAV.LOT_SHEETS}
            pageTitle={pageTitle}
            pageTitleLoading={isLoading}
            rightButton={(
              <>
                <DiscardButton disabled={isLoading} />
                <SaveButton disabled={isLoading} />
              </>
            )}
          />
        </FluidHeader>
        <FluidContent>
          <Box mt={3}>
            <Grid container={true} spacing={3}>
              <Grid item={true} lg={6}>
                <Card style={{ height: '100%' }}>
                  <CardHeader title="Lot number" />
                  <Divider />
                  <CardContent style={{ height: '100%' }}>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} lg={12}>
                        <BucketField api={bucketListUrl} onChange={onGetBucketList} />
                      </Grid>
                      <Grid item={true} lg={12}>
                        {bucketsValue.map((bucket) => {
                          const checked = containersValue.find(item => prop('number', item) === bucket)
                          return (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Checkbox checked={checked} onChange={() => onContainerCheck(bucket)} />
                              <Typography>
                                {bucket}
                              </Typography>
                            </Box>
                          )
                        })}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item={true} lg={6}>
                <Card style={{ height: '100%' }}>
                  <CardHeader title="Container" />
                  <Divider />
                  <CardContent style={{ height: '100%', padding: 0 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>{t('Label')}</TableCell>
                          <TableCell>{t('Type')}</TableCell>
                          <TableCell>{t('Weight')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {containersValue.map((item, index) => {
                          const number = prop('number', item)
                          return (
                            <TableRow key={index}>
                              <TableCell>
                                {number}
                              </TableCell>
                              <TableCell>
                                <LotContainerTypeField name={`containers[${index}].type`} />
                              </TableCell>
                              <TableCell>
                                <PositiveNumberField name={`containers[${index}].weight`} />
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </FluidContent>
      </FluidContainer>
    </form>
  )
}

LotSheetsShipmentForm.propTypes = {
  form: PropTypes.object.isRequired,
  pageTitle: PropTypes.object,
  isLoading: PropTypes.isLoading,
  guid: PropTypes.string,
  getBucketList: PropTypes.func
}

export default withForm(LotSheetsShipmentForm)
