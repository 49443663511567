import React from 'react'
import { range } from 'ramda'
import Skeleton from '@mui/material/Skeleton'
import { styled } from '@mui/material'
import Grid from '@mui/material/Grid'

const GridStyled = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(3),
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    '&:not(:last-of-type)': {
      borderRight: `1px solid ${theme.palette.divider}`
    }
  },
  [theme.breakpoints.down('md')]: {
    '&:not(:last-of-type)': {
      borderBottom: `1px solid ${theme.palette.divider}`
    }
  }
}))

function Loader () {
  const items = range(0, 4)
  return (
    <>
      {items.map(item => (
        <GridStyled key={item} item={true} xs={12} sm={6} md={3}>
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </GridStyled>
      ))}
    </>
  )
}

export default Loader
