export const CURRENCY_RATE = 'Currency Rate'
export const TAX = 'Taxes'
export const PRODUCT_CONDITION = 'Product Conditions'
export const ADJUSTMENT_REASON = 'Adjustment Reasons'
export const CONTAINER_TYPE = 'Container types'
export const TEMPLATE = 'Templates'
export const CONTRACT = 'Contracts'
export const AUTOMATION_RULE = 'Automation rules'
export const BILLING_PROFILE = 'Billing profiles'
export const DELIVERY_METHOD = 'Delivery method'
