import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { map, path, pathOr, pipe, prop, propOr } from 'ramda'
import { generatePath, useNavigate } from 'react-router-dom'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import EditIcon from '@mui/icons-material/Edit'

import { capitalize } from '~/utils'
import { MoreListButton } from '~/components/Buttons'
import Avatar from '~/components/Avatar/Avatar'
import Money from '~/components/Money'
import PageTitle from '~/components/PageTitle'
import Tags from '~/components/Tags'
import TableDateFormat from '~/components/TableDateFormat'
import Container, { Content, Header } from '~/components/Container'
import { IntegrationIcon } from '~/components/Icons'
import Tooltip from '~/components/HtmlTooltip'
import { ChannelCompanyDialog } from '~/components/ChannelDialog'
import { useChannelSelect } from '~/components/ChannelDialog/hooks'
import { Table, TableHeader } from '~/components/Table'
import useDialog from '~/hooks/useDialog'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import { VARIANT_DETAIL_TABS, VARIANT_UPDATE_TABS } from '~/constants/tabs'
import TableLink from '~/components/Link'

import BulkActionButton from './BulkActionButton'

const getIsMultiVariant = pipe(prop('product'), prop('isMultiVariant'))

const getDetailPath = ({ id, productId, isMultiVariant }) => {
  const params = { id, productId, tab: VARIANT_DETAIL_TABS.GENERAL }
  const redirect = productId && generatePath(ROUTES.VARIANT_DETAIL_PATH, params)

  return `${redirect}?isMultiVariant=${isMultiVariant}`
}

export const VARIANT_COLUMNS = [
  {
    width: 75,
    headerName: 'Image',
    field: 'image',
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        <Avatar src={value} alt={row.variantName} />
      </TableLink>
    )
  },
  {
    width: 300,
    headerName: 'Variant',
    field: 'variantName',
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        {value}
      </TableLink>
    )
  },
  {
    width: 150,
    headerName: 'SKU',
    field: 'sku'
  },
  {
    width: 100,
    headerName: 'Pieces',
    field: 'pieces',
    headerAlign: 'center',
    align: 'center'
  },
  {
    width: 100,
    headerName: 'UoM',
    field: 'unitOfMeasurement'
  },
  {
    width: 200,
    headerName: 'Barcode',
    field: 'barcode'
  },
  {
    width: 200,
    headerName: 'Listed on',
    field: 'channels',
    renderCell: ({ value }) => map(channel => (
      <Tooltip key={prop('name', channel)} title={prop('name', channel)}>
        <IntegrationIcon type={prop('type', channel)} width={30} />
      </Tooltip>
    ), value)

  },
  {
    width: 100,
    headerName: 'Available',
    field: 'available',
    headerAlign: 'center',
    align: 'center'
  },
  {
    width: 150,
    headerName: 'Unavailable',
    field: 'unavailable',
    headerAlign: 'center',
    align: 'center'
  },
  {
    width: 100,
    headerName: 'On hand',
    field: 'onHand',
    headerAlign: 'center',
    align: 'center'
  },
  {
    width: 100,
    headerName: 'Allocated',
    field: 'allocated',
    headerAlign: 'center',
    align: 'center'
  },
  {
    width: 100,
    headerName: 'Awaiting',
    field: 'awaiting',
    headerAlign: 'center',
    align: 'center'
  },
  {
    width: 150,
    headerName: 'In transfer',
    field: 'inTransfer',
    headerAlign: 'center',
    align: 'center'
  },
  {
    width: 150,
    headerName: 'Supply price',
    field: 'supplyPrice',
    renderCell: ({ value }) => (
      <Money value={value} />
    )
  },
  {
    width: 150,
    headerName: 'Retail price',
    field: 'retailPrice',
    renderCell: ({ value }) => (
      <Money value={value} />
    )
  },
  {
    width: 150,
    headerName: 'M.R.P',
    field: 'mrp',
    renderCell: ({ value }) => (
      <Money value={value} />
    )
  },
  {
    width: 150,
    headerName: 'Category',
    field: 'category'
  },
  {
    width: 150,
    headerName: 'Brand',
    field: 'brand'
  },
  {
    width: 150,
    headerName: 'Enabled',
    field: 'enabled'
  },
  {
    width: 300,
    headerName: 'Tags',
    field: 'tags',
    renderCell: ({ value }) => (
      <Tags items={value} />
    )
  },
  {
    width: 150,
    headerName: 'Last updated',
    field: 'updatedAt',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  }
]

const getRowsFromResults = map(item => {
  const guid = prop('guid', item)
  const id = prop('id', item)
  const productId = path(['product', 'id'], item)
  const variantName = prop('name', item)
  const sku = prop('sku', item)
  const image = prop('defaultImage', item)
  const pieces = prop('pieces', item)
  const unitOfMeasurement = capitalize(prop('unitOfMeasurement', item))
  const barcode = prop('barcode', item)
  const channels = propOr([], 'channels', item)
  const available = prop('available', item)
  const unavailable = prop('unavailable', item)
  const onHand = prop('onHand', item)
  const awaiting = prop('awaiting', item)
  const inTransfer = prop('inTransfer', item)
  const allocated = prop('allocated', item)
  const supplyPrice = prop('supplyPrice', item)
  const isMultiVariant = getIsMultiVariant(item)
  const retailPrice = prop('retailPrice', item)
  const mrp = prop('maximumRetailPrice', item)
  const brand = path(['product', 'brand', 'name'], item)
  const category = path(['product', 'category', 'name'], item)
  const tags = pathOr([], ['product', 'tags'], item)
  const updatedAt = prop('updatedAt', item)
  const link = getDetailPath({
    id: id,
    productId: productId,
    isMultiVariant: isMultiVariant
  })
  const enabled = prop('enabled', item)

  return {
    variantName,
    sku,
    guid,
    id,
    image,
    pieces,
    productId,
    unitOfMeasurement,
    barcode,
    channels,
    supplyPrice,
    retailPrice,
    mrp,
    available,
    unavailable,
    onHand,
    allocated,
    inTransfer,
    awaiting,
    category,
    brand,
    tags,
    updatedAt,
    isMultiVariant,
    link,
    enabled: enabled ? 'True' : 'False'
  }
})

function VariantTable (props) {
  const {
    list,
    filter,
    ordering,
    onListRefetch,
    onListingAdd,
    onStockSync,
    channelList,
    bulkActionLoading,
    onVariantDelete
  } = props

  const [tableRef, setTableRef] = useState(null)
  const navigate = useNavigate()
  const { open, handleOpen, handleClose } = useDialog()
  const { selectedChannelGuids, handleChannelSelect } = useChannelSelect()

  const isLoading = prop('isLoading', list)
  const results = prop('results', list)
  const hasNextPage = prop('hasNextPage', list)
  const hasPrevPage = prop('hasPrevPage', list)
  const count = prop('count', list)
  const columns = prop('filteredColumns', ordering)

  const handleEdit = useCallback(({ id, productId, isMultiVariant }) => {
    const params = { id, productId, tab: VARIANT_UPDATE_TABS.GENERAL }
    const generatedPath = productId && generatePath(ROUTES.VARIANT_UPDATE_PATH, params)
    const editPath = `${generatedPath}?isMultiVariant=${isMultiVariant}`
    navigate(editPath)
  }, [navigate])

  const actionColumns = useMemo(() => [
    ...columns,
    {
      width: 50,
      resizable: false,
      field: 'actions',
      type: 'actions',
      getActions: ({ row }) => [
        <GridActionsCellItem
          label="Edit"
          onClick={() => handleEdit({
            id: row.id,
            productId: row.productId,
            isMultiVariant: row.isMultiVariant
          })}
          showInMenu={true}
          icon={<EditIcon />}
        />
      ]
    }
  ], [columns, handleEdit])
  const handleOpenDialog = () => {
    channelList.getList()
    handleOpen()
  }

  const handleListingAdd = () =>
    onListingAdd(selectedChannelGuids)
      .then(() => handleClose())

  return (
    <>
      <Container>
        <Header>
          <PageTitle
            pageTitle={NAV.VARIANTS}
            parentTitle={NAV.CATALOG}
            rightButton={(
              <BulkActionButton
                onStockSync={onStockSync}
                onAddToListingDialogOpen={handleOpenDialog}
                loading={bulkActionLoading}
                onVariantDelete={onVariantDelete}
              />
            )}

          />
        </Header>

        <Content>
          <TableHeader
            filter={filter}
            filterOpen={filter.handleOpen}
            orderingOpen={ordering.handleOpen}
            ordering={ordering}
          >
            <MoreListButton tableRef={tableRef} />
          </TableHeader>
          <Table
            isLoading={isLoading}
            columns={actionColumns}
            getRowsFromResults={getRowsFromResults}
            results={results}
            count={count}
            primaryKey="guid"
            checkboxSelection={true}
            pinnedColumns={['image', 'name']}
            ordering={ordering}
            onRefetch={onListRefetch}
            setTableRef={setTableRef}
            isNewPagination={true}
            hasNextPage={hasNextPage}
            hasPrevPage={hasPrevPage}
          />
        </Content>
      </Container>

      <ChannelCompanyDialog
        onSubmit={handleListingAdd}
        onClose={handleClose}
        open={open}
        selectedChannelGuids={selectedChannelGuids}
        onChannelSelect={handleChannelSelect}
        isLoading={channelList.isLoading}
        channelList={channelList.results}
      />
    </>
  )
}

VariantTable.propTypes = {
  list: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  onListingAdd: PropTypes.func.isRequired,
  onListRefetch: PropTypes.func.isRequired,
  onStockSync: PropTypes.func.isRequired,
  channelList: PropTypes.object.isRequired,
  bulkActionLoading: PropTypes.bool,
  onVariantDelete: PropTypes.func.isRequired
}

export default VariantTable
