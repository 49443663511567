import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'storfox-snackbar'
import { useTranslation } from 'react-i18next'

import { DashboardLayout } from '~/components/Layouts'
import { useUpdateProfile } from '~/components/Profile'
import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'

import { TaxCreateSerializer } from '../../serializers/Tax'
import { useTaxCreate } from '../../hooks/Tax'
import TaxCreateForm from '../../components/Tax/TaxCreateForm'

function TaxCreateContainer () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const taxCreate = useTaxCreate()
  const { updateProfile } = useUpdateProfile()
  const message = t('Successfully created')

  const handleSubmit = formValues =>
    taxCreate.create(TaxCreateSerializer(formValues))
      .then(() => navigate(ROUTES.TAX_LIST_PATH))
      .then(() => snackbar({ message }))
      .then(() => updateProfile())

  const title = 'New Tax'

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      activeNav={NAV.CONFIGURATION}
      breadcrumbs={breadcrumbs}
    >
      <TaxCreateForm
        title={title}
        onSubmit={handleSubmit}
        isLoading={taxCreate.isLoading}
      />
    </DashboardLayout>
  )
}

export default TaxCreateContainer
