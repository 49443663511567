export const ACTIONS = {
  OPEN_SCAN: 'openScan'
}

export const SAVE = 'save'
export const COMPLETE = 'complete'

export const CONTAINER = 'container'

export const FILTER_NAME = 'packing'
