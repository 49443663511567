import React from 'react'
import { Ordering } from 'storfox-filter'

import { STOCK_COUNT_COLUMNS as tableColumns } from './StockCountTable'

import { FILTER_NAME as name } from '../../constants'

export const stockCountOrderingOptions = {
  name,
  tableColumns
}

function StockCountOrderingForm (props) {
  return (
    <Ordering {...props} />
  )
}

export default StockCountOrderingForm
