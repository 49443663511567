import { useState } from 'react'

function usePreview (offsetY = -100, defaultElementWidth = 40) {
  const [isOpen, setIsOpen] = useState(false)
  const [previewPositionX, setPreviewPositionX] = useState(0)
  const [previewPositionY, setPreviewPositionY] = useState(0)

  const handleMouseEnter = event => {
    if (!isOpen) {
      const element = event.target
      const elementPosition = element.getBoundingClientRect()
      const elementWidth = element.width || defaultElementWidth

      setPreviewPositionX(elementPosition.x + elementWidth)
      setPreviewPositionY(event.clientY + window.scrollY + offsetY)
    }
    setIsOpen(true)
  }

  const handleMouseLeave = () => {
    setIsOpen(false)
  }

  return {
    isOpen,
    previewPositionX,
    previewPositionY,
    handleMouseEnter,
    handleMouseLeave
  }
}

export default usePreview
