import PropTypes from 'prop-types'
import React from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import { red } from '@mui/material/colors'
import { styled } from '@mui/material'

import Button from './Button'

const ButtonStyled = styled(Button)(({ theme }) => ({
  background: red[50],
  color: theme.palette.error.main,
  '&:hover': {
    background: red[100]
  },
  '&.Mui-disabled': {
    background: red[100],
    color: theme.palette.error.main,
    opacity: '0.4'
  }
}))

function DangerButton ({ children, ...props }) {
  return (
    <ButtonStyled
      startIcon={<DeleteIcon />}
      {...props}
    >
      {children}
    </ButtonStyled>
  )
}

DangerButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
}

DangerButton.defaultProps = {
  children: 'Delete'
}

export default DangerButton
