import { useConfirm } from 'storfox-confirm-dialog'

function useDeleteConfirm (message = 'Are you sure, you want to delete selected rows?') {
  const onConfirm = useConfirm()

  const handleClick = (onAgree = () => {}) =>
    onConfirm({ message })
      .agree(() => {
        onAgree()
      })
      .disagree()

  return { handleClick }
}

export default useDeleteConfirm
