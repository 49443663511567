import React from 'react'
import { Ordering } from 'storfox-filter'

import { CONTAINER_TYPE_COLUMNS as tableColumns } from './ContainerTypeTable'

import { FILTER_NAME as name } from '../../../constants/ContainerType'

export const containerTypeOrderingOptions = {
  name,
  tableColumns
}

function ContainerTypeOrderingForm (props) {
  return (
    <Ordering {...props} />
  )
}

export default ContainerTypeOrderingForm
