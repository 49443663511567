import React from 'react'
import Dialog from '@mui/material/Dialog/Dialog'
import DialogContent from '@mui/material/DialogContent/DialogContent'
import DialogActions from '@mui/material/DialogActions/DialogActions'
import { prop } from 'ramda'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import { dialogClasses, styled } from '@mui/material'
import Box from '@mui/material/Box'

import { Button } from '~/components/Buttons'
import Divider from '~/components/Divider'
import DiscardButton from '~/components/Buttons/DiscardButton'
import { withForm } from '~/components/Form'
import PositiveNumberField from '~/components/Fields/PositiveNumberField'

const DialogNameBoxStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '10px',
  justifyContent: 'space-between',
  '& span:first-of-type': {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '16px'
  },
  '& span:last-child': {
    color: 'rgba(39, 50, 87, 0.7)',
    fontSize: '12px'
  }
})

const DialogStyled = styled(Dialog)({
  [`& .${dialogClasses.paper}`]: {
    maxWidth: '910px'
  },
})

function LotModifyDialog ({ open, title, onClose, isLoading, ...props }) {
  const { form } = props

  const handleSubmit = prop('handleSubmit', form)

  return (
    <DialogStyled
      open={open}
      onClose={onClose}
      fullWidth={true}
    >
      <Box display="flex" p={3}>
        <DialogNameBoxStyled>
          {title}
        </DialogNameBoxStyled>
      </Box>
      <Divider />
      <DialogContent>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={6}>
            <PositiveNumberField
              name="damagedQuantity"
              label="Damaged"
            />
          </Grid>
          <Grid item={true} xs={6}>
            <PositiveNumberField
              name="lackedQuantity"
              label="lacked"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <DiscardButton onClick={onClose} />
        <Button
          variant="contained"
          type="button"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          Save
        </Button>
      </DialogActions>
    </DialogStyled>
  )
}

LotModifyDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  form: PropTypes.object,
  title: PropTypes.string,
  isLoading: PropTypes.bool,
}

export default withForm(LotModifyDialog)
