import React from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'

import EmailField from '~/components/Fields/EmailField'
import PhoneNumberField from '~/components/Fields/PhoneNumberField'
import SingleImageUploadField from '~/components/Fields/SingleImageUploadField'
import StatusField from '~/components/Fields/StatusField'
import TextField from '~/components/Fields/TextField'
import { CardHeader } from '~/components/Cards'

function SupplierUpdateFormGeneral () {
  const { t } = useTranslation()

  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12} lg={8}>
        <Box mb={3}>
          <Card>
            <CardHeader title={t('Information')} />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <TextField
                    data-cy="companyName"
                    name="companyName"
                    label={t('Company Name')}
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <TextField
                    data-cy="shortName"
                    name="shortName"
                    label={t('Short name')}
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <TextField
                    data-cy="referenceId"
                    name="referenceId"
                    label={t('Reference id')}
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <EmailField
                    data-cy="email"
                    name="email"
                    label={t('Email')}
                  />
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Box mb={3}>
          <Card>
            <CardHeader title={t('Primary Contact')} />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12} lg={6}>
                  <TextField
                    data-cy="contactFirstName"
                    name="contactFirstName"
                    label={t('First name')}
                  />
                </Grid>

                <Grid item={true} xs={12} lg={6}>
                  <TextField
                    data-cy="contactLastName"
                    name="contactLastName"
                    label="Last name"
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <EmailField
                    data-cy="contactEmail"
                    name="contactEmail"
                    label={t('Email')}
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <PhoneNumberField
                    data-cy="contactPhoneNumber"
                    name="contactPhoneNumber"
                    label={t('Phone number')}
                  />
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>
      <Grid item={true} xs={12} lg={4}>
        <Box mb={3}>
          <Card>
            <CardHeader title={t('Image')} />
            <Divider />
            <CardContent>
              <SingleImageUploadField name="imagePath" accept="image/png, image/jpeg" />
            </CardContent>
          </Card>
        </Box>
        <Box mb={3}>
          <Card>
            <CardHeader title={t('Settings')} />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <StatusField />
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>

    </Grid>
  )
}

export default SupplierUpdateFormGeneral
