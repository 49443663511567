import React from 'react'
import { map, range } from 'ramda'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Skeleton from '@mui/material/Skeleton'
import PropTypes from 'prop-types'
import { styled } from '@mui/material'

const TableCellStyled = styled(TableCell)({
  minWidth: 250
})

function ConditionSkeleton ({ quantity }) {
  return map(index => (
    <TableRow key={index}>
      <TableCellStyled>
        <Skeleton variant="rectangular" />
      </TableCellStyled>
      <TableCellStyled>
        <Skeleton variant="rectangular" />
      </TableCellStyled>
      <TableCellStyled>
        <Skeleton variant="rectangular" />
      </TableCellStyled>
      <TableCellStyled>
        <Skeleton variant="rectangular" />
      </TableCellStyled>
    </TableRow>
  ), range(0, quantity))
}

ConditionSkeleton.defaultProps = {
  quantity: 4
}

ConditionSkeleton.propTypes = {
  quantity: PropTypes.number
}

export default ConditionSkeleton
