import React from 'react'
import { Card } from '@mui/material'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import { path, prop, propOr } from 'ramda'
import PropTypes from 'prop-types'
import Link from '@mui/material/Link'
import { generatePath, Link as RouterLink } from 'react-router-dom'

import { toDuration } from '~/utils'
import TextLabelLoader from '~/components/TextLabelLoader'
import CardHeader from '~/components/Cards/CardHeader'
import OrderProgress from '~/components/OrderProgress'
import * as ROUTES from '~/constants/routes'
import { SHIPMENT_DETAIL_TABS } from '~/constants/tabs'
import PackingStatus from '~/components/Statuses/PackingStatus'

function PackingInformation ({ values }) {
  const status = propOr('', 'status', values)
  const totalQuantity = prop('totalQuantity', values)
  const duration = toDuration(propOr('', 'duration', values))
  const packedCount = propOr(0, 'packedCount', values)
  const guid = path(['shipment', 'guid'], values)
  const shipmentNumber = path(['shipment', 'number'], values)

  const params = { guid, tab: SHIPMENT_DETAIL_TABS.GENERAL }
  const shipmentPath = guid && generatePath(ROUTES.SHIPMENT_DETAIL_PATH, params)

  return (
    <Card>
      <CardHeader title="Packing Information" />
      <Divider />
      <CardContent>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={3} xs={12}>
            <TextLabelLoader label="Packed / Total Qty">
              <OrderProgress
                ordered={totalQuantity}
                received={packedCount}
                size="md"
              />
            </TextLabelLoader>
          </Grid>

          <Grid item={true} lg={3} xs={12}>
            <TextLabelLoader label="Shipment">
              {shipmentNumber && (
                <Link
                  component={RouterLink}
                  to={shipmentPath}
                  color="secondary"
                  underline="always"

                >
                  {shipmentNumber}
                </Link>
              )}
            </TextLabelLoader>
          </Grid>

          <Grid item={true} lg={3} xs={12}>
            <TextLabelLoader label="Status">
              <PackingStatus value={status} />
            </TextLabelLoader>
          </Grid>

          <Grid item={true} lg={3} xs={12}>
            <TextLabelLoader label="Duration">
              {duration}
            </TextLabelLoader>
          </Grid>

        </Grid>
      </CardContent>
    </Card>
  )
}

PackingInformation.propTypes = {
  values: PropTypes.object.isRequired
}

export default PackingInformation
