import React from 'react'
import PropTypes from 'prop-types'
import MUIPagination from '@mui/material/Pagination'
import TablePagination, { tablePaginationClasses } from '@mui/material/TablePagination'
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions'
import { useTheme, styled } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useRoute, useSearchParams } from 'storfox-route-hooks'

const TablePaginationStyled = styled(TablePagination)({
  [`& .${tablePaginationClasses.spacer}`]: {
    flex: 'auto'
  }
})

const rowsPerPageOptions = [10, 25, 50, 100, 250, 500, 1000]

const DEFAULT_PAGE_SIZE = 10

function Pagination ({ prefix, count }) {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const searchParams = useSearchParams()
  const { changeParams } = useRoute()

  const pageKey = `${prefix}page`
  const pageSizeKey = `${prefix}limit`
  const selectKey = 'select'

  const pageStr = searchParams.get(pageKey)
  const pageSizeStr = searchParams.get(pageSizeKey)

  const page = pageStr ? Number(pageStr) : 1
  const pageSize = pageSizeStr ? Number(pageSizeStr) : DEFAULT_PAGE_SIZE
  const pageCount = Math.ceil(count / pageSize)

  const ActionsComponent = () => {
    if (isDesktop) {
      return (
        <MUIPagination
          count={pageCount}
          page={page}
          onChange={(event, number) => changeParams({ [pageKey]: number, [selectKey]: null })}
        />
      )
    }

    return (
      <TablePaginationActions
        count={count}
        showFirstButton={true}
        showLastButton={true}
        page={page > 0 ? page - 1 : 0}
        getItemAriaLabel={() => {}}
        onPageChange={(event, number) => changeParams({ [pageKey]: number + 1, [selectKey]: null })}
        rowsPerPage={pageSize}
      />
    )
  }

  return (
    <TablePaginationStyled
      onPageChange={() => {}}
      count={count}
      page={count === 0 ? 0 : page - 1}
      component="div"
      getItemAriaLabel={() => {}}
      rowsPerPage={pageSize}
      onRowsPerPageChange={(event) => changeParams({ [pageSizeKey]: event.target.value, [selectKey]: null })}
      rowsPerPageOptions={rowsPerPageOptions}
      ActionsComponent={ActionsComponent}
    />
  )
}

Pagination.propTypes = {
  prefix: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired
}

export default Pagination
