import React from 'react'

import SelectField from '~/components/Fields/SelectField'

const OPTIONS = [
  {
    value: '1',
    name: 'Standard'
  },
  {
    value: '2',
    name: 'Hub to hub'
  },
  {
    value: '3',
    name: 'Reverse pickup'
  }
]

function DeliveryTypeField (props) {
  return (
    <SelectField
      label="Delivery type"
      options={OPTIONS}
      {...props}
    />
  )
}

export default DeliveryTypeField
