import React from 'react'
import Grid from '@mui/material/Grid'
import PropTypes from 'prop-types'

import EditableAddress from './EditableAddress'
import DeliveryAddressInfo from './DeliveryAddressInfo'
import BillingAddressInfo from './BillingAddressInfo'

function CustomerAddresses (props) {
  const {
    billingAddress,
    deliveryAddress,
    customerDeliveryAddresses,
    customerBillingAddresses,
    onBillingAddressChange,
    onDeliveryAddressChange
  } = props

  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12} lg={6}>
        <EditableAddress
          title="Delivery address"
          value={deliveryAddress}
          address={deliveryAddress}
          options={customerDeliveryAddresses}
          onChange={onDeliveryAddressChange}
          addressFields={<DeliveryAddressInfo name="deliveryAddress" />}
        />
      </Grid>
      <Grid item={true} xs={12} lg={6}>
        <EditableAddress
          title="Billing address"
          value={billingAddress}
          address={billingAddress}
          options={customerBillingAddresses}
          onChange={onBillingAddressChange}
          addressFields={<BillingAddressInfo name="billingAddress" />}
        />
      </Grid>
    </Grid>
  )
}

CustomerAddresses.propTypes = {
  billingAddress: PropTypes.object.isRequired,
  deliveryAddress: PropTypes.object.isRequired,
  customerDeliveryAddresses: PropTypes.array.isRequired,
  customerBillingAddresses: PropTypes.array.isRequired,
  onBillingAddressChange: PropTypes.func.isRequired,
  onDeliveryAddressChange: PropTypes.func.isRequired
}

export default CustomerAddresses
