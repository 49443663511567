import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import { Filter } from 'storfox-filter'
import { DateRangeField } from 'storfox-form-fields'
import { prop } from 'ramda'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

import { escapeAtob, getSeparatedDateRange, unescapeBtoa } from '~/utils'
import TextField from '~/components/Fields/TextField'
import WarehouseMultiSelectField from '~/components/Fields/WarehouseMultiSelectField'
import Accordion from '~/components/Accordion'
import * as API from '~/constants/api'

import ReturnCustomerField from './ReturnCustomerField'
import ReturnStatusField from './ReturnStatusField'

import { FILTER_NAME as name } from '../../constants'

export const toParams = values => {
  const status = prop('status', values)
  const customers = unescapeBtoa(prop('customers', values))
  const warehouses = unescapeBtoa(prop('warehouses', values))
  const [createdAtStart, createdAtEnd] = getSeparatedDateRange(prop('createdAt', values))
  const minTotal = prop('minTotal', values)
  const maxTotal = prop('maxTotal', values)

  return {
    status,
    customers,
    warehouses,
    createdAtStart,
    createdAtEnd,
    minTotal,
    maxTotal
  }
}

export const toValues = (params) => {
  const status = prop('status', params)
  const customers = escapeAtob(prop('customers', params))
  const warehouses = escapeAtob(prop('warehouses', params))
  const createdAt = [prop('createdAtStart', params), prop('createdAtEnd', params)]
  const minTotal = prop('minTotal', params)
  const maxTotal = prop('maxTotal', params)

  return {
    status,
    customers,
    warehouses,
    createdAt,
    minTotal,
    maxTotal
  }
}
export const transformer = { toParams, toValues }

export const returnFilterOptions = {
  name,
  transformer
}

function ReturnFilterForm (props) {
  const { t } = useTranslation()

  return (
    <Filter {...props}>
      <Accordion title="General" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <ReturnStatusField />
          </Grid>
          <Grid item={true} xs={12}>
            <ReturnCustomerField />
          </Grid>
          <Grid item={true} xs={12}>
            <WarehouseMultiSelectField api={API.RETURN_WAREHOUSE_LIST} />
          </Grid>
        </Grid>
      </Accordion>
      <Accordion title="Date">
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <Typography variant="body2" gutterBottom={true}>
              {t('Started')}:
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <DateRangeField name="createdAt" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Accordion>
      <Accordion title="Total ($)">
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={6}>
            <TextField
              data-cy="minTotal"
              name="minTotal"
              label="Min ($)"
            />
          </Grid>
          <Grid item={true} xs={6}>
            <TextField
              data-cy="maxTotal"
              name="maxTotal"
              label="Max ($)"
            />
          </Grid>
        </Grid>
      </Accordion>
    </Filter>
  )
}

ReturnFilterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
}

export default ReturnFilterForm
