import React from 'react'
import Barcode from 'react-barcode'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'

function BarcodePlaceholder ({ defaultValue }) {
  return (
    <Card>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        opacity={0.2}
      >
        <Barcode value={defaultValue} />
      </Box>
    </Card>
  )
}

BarcodePlaceholder.defaultProps = {
  defaultValue: '0000000000000'
}

BarcodePlaceholder.propTypes = {
  defaultValue: PropTypes.string.isRequired
}

export default BarcodePlaceholder
