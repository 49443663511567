import { useSnackbar } from 'storfox-snackbar'
import { startTransition, useCallback } from 'react'

import useOrderingList from './useOrderingList'
import { useColumnOrderingUpdate, useColumnWidthUpdate, useFilterOrderingUpdate } from './useFilterOrderingUpdate'
import { useStorage } from './useStorage'

import { ORDERING_KEY } from '../constants'
import {
  ColumnOrderSerializer,
  ColumnVisibilitySerializer,
  ColumnWidthUpdateSerializer,
  FilterOrderSerializer
} from '../serializers'
import { useColumnOrderingItems, useFilterOrderingItems, useOrderingItems } from '../components/OrderingProvider'

function useOrderingBackend ({ name }) {
  const snackbar = useSnackbar()
  const { setItems } = useOrderingItems()
  const { filterItems } = useFilterOrderingItems(name)
  const { columnItems, setColumnWidth, setColumnOrder } = useColumnOrderingItems(name)

  const orderingList = useOrderingList()
  const filterOrderingUpdate = useFilterOrderingUpdate()
  const columnOrderingUpdate = useColumnOrderingUpdate()
  const columnWidthUpdate = useColumnWidthUpdate()

  const orderingStorage = useStorage(ORDERING_KEY)

  const getOrderingList = useCallback(orderingList.getList, [])

  const isLoading = (
    filterOrderingUpdate.isLoading ||
    columnOrderingUpdate.isLoading
  )

  const update = () =>
    getOrderingList()
      .then(data => {
        startTransition(() => setItems(data.results))
        orderingStorage.set(data.results)
      })

  const onFiltersUpdate = values =>
    filterOrderingUpdate.update(FilterOrderSerializer(values))
      .then(() => update())
      .then(() => snackbar({ message: 'Successfully ordered' }))

  const onColumnVisibilityUpdate = (params, columns) =>
    columnOrderingUpdate.update(ColumnVisibilitySerializer({ name, params, columns }))
      .then(() => update())

  const onColumnsUpdate = columns => {
    setColumnOrder({ name, columns })
    return columnOrderingUpdate.update(ColumnOrderSerializer({ name, columns }))
      .then(() => update())
  }

  const onColumnResize = column => {
    setColumnWidth({ name, column })
    return columnWidthUpdate.update(ColumnWidthUpdateSerializer({ name, column }))
      .then(() => update())
  }

  return {
    isLoading,
    filterItems,
    columnItems,
    update,
    onFiltersUpdate,
    onColumnVisibilityUpdate,
    onColumnsUpdate,
    onColumnResize
  }
}

export default useOrderingBackend
