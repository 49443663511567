import { useState } from 'react'
import debounce from 'debounce-promise'

const initialState = {
  current: 0,
  total: 0
}

const simpleMemoize = fn => {
  let lastArg
  let lastResult
  return arg => {
    if (arg !== lastArg) {
      lastArg = arg
      lastResult = fn(arg)
    }
    return lastResult
  }
}
export function useProgressCard () {
  const [validation, setValidation] = useState(initialState)
  const [completion, setCompletion] = useState(initialState)

  const handleValidationChange = ({ current, total }) => setValidation({ current, total })

  const handleCompletionChange = ({ current, total }) => setCompletion({ current, total })

  const resetValues = () => {
    setValidation(initialState)
    setCompletion(initialState)
  }

  return {
    validation,
    completion,
    handleValidationChange: simpleMemoize(debounce(handleValidationChange, 200)),
    handleCompletionChange,
    resetValues
  }
}
