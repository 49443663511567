import { gridPaginationSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid-pro'
import MUIPagination from '@mui/material/Pagination'
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import TablePagination, { tablePaginationClasses } from '@mui/material/TablePagination'
import { styled } from '@mui/material/styles'
import { useAllSearchParams } from 'storfox-route-hooks'
import Box from '@mui/material/Box'

const TablePaginationStyled = styled(TablePagination)({
  [`& .${tablePaginationClasses.toolbar}`]: {
    marginLeft: 0,
    paddingLeft: 0
  },
  [`& .${tablePaginationClasses.spacer}`]: {
    flex: 'auto'
  }
})

const ROWS_PER_PAGE_OPTIONS = [10, 25, 50, 100, 250, 500, 1000]

const getRowsPerPageOptions = limit => {
  const limitInt = limit && parseInt(limit)
  const foundLimit = ROWS_PER_PAGE_OPTIONS.find(option => option === limitInt)

  if (limit && !foundLimit) {
    return [limitInt, ...ROWS_PER_PAGE_OPTIONS]
  }

  return ROWS_PER_PAGE_OPTIONS
}

const PaginationComponent = (props) => {
  const {
    isDesktop,
    onPageSizeChange,
    pageSize,
    onDesktopPageChange,
    onMobilePageChange,
    isNewPagination = false,
    hasNextPage = false,
    hasPrevPage = false
  } = props

  const { page = '1', limit } = useAllSearchParams()
  const pageNum = parseInt(page)

  const [currentPage, setCurrentPage] = useState(pageNum)

  const apiRef = useGridApiContext()

  const { rowCount } = useGridSelector(apiRef, gridPaginationSelector)

  const count = Math.ceil(rowCount / pageSize)

  const rowsPerPageOptions = getRowsPerPageOptions(limit)

  const ActionsComponent = () => {
    if (isNewPagination) {
      const possibleCount = hasNextPage ? (pageSize * (currentPage + 2)) : pageSize * currentPage
      return (
        <TablePaginationActions
          count={possibleCount}
          page={currentPage}
          getItemAriaLabel={() => {}}
          onPageChange={(event, value) => {
            const newPage = value > 0 ? value : 1
            setCurrentPage(newPage)
            apiRef.current.setPage(newPage)
            onMobilePageChange(event, newPage)
          }}
          rowsPerPage={pageSize}
          backIconButtonProps={{ disabled: !hasPrevPage }}
        />
      )
    }

    if (isDesktop) {
      return (
        <MUIPagination
          count={count}
          page={currentPage}
          onChange={(event, value) => {
            setCurrentPage(value)
            apiRef.current.setPage(value)
            onDesktopPageChange(event, value)
          }}
          backIconButtonProps={{ disabled: true }}
        />
      )
    }

    return (
      <TablePaginationActions
        count={rowCount}
        showFirstButton={true}
        showLastButton={true}
        page={currentPage}
        getItemAriaLabel={() => {}}
        onPageChange={(event, value) => {
          const newPage = value > 0 ? value : 1
          setCurrentPage(newPage)
          apiRef.current.setPage(newPage)
          onMobilePageChange(event, newPage)
        }}
        rowsPerPage={pageSize}
        backIconButtonProps={{ disabled: true }}
      />
    )
  }

  const countForNewPagination = isNewPagination ? hasNextPage ? -1 : pageSize * (currentPage - 1) + rowCount : rowCount

  return (
    <div data-cy="table-pagination">
      <Box ml={1} />

      <TablePaginationStyled
        component="div"
        count={countForNewPagination}
        page={rowCount === 0 ? 0 : currentPage - 1}
        rowsPerPage={pageSize}
        onPageChange={() => {}}
        getItemAriaLabel={() => {}}
        onRowsPerPageChange={onPageSizeChange}
        rowsPerPageOptions={rowsPerPageOptions}
        ActionsComponent={ActionsComponent}
        backIconButtonProps={{ disabled: true }}
      />
    </div>
  )
}

PaginationComponent.propTypes = {
  isDesktop: PropTypes.bool.isRequired,
  pageSize: PropTypes.number.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
  onDesktopPageChange: PropTypes.func.isRequired,
  onMobilePageChange: PropTypes.func.isRequired,
  isNewPagination: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  hasPrevPage: PropTypes.bool
}

export default PaginationComponent
