import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import AppleTouchIcon from '~/assets/images/favicon/apple-touch-icon.png'
import Favicon16X16 from '~/assets/images/favicon/favicon-16x16.png'
import Favicon32X32 from '~/assets/images/favicon/favicon-32x32.png'
import AndroidChrome192X192 from '~/assets/images/favicon/android-chrome-192x192.png'

function HtmlLayout ({ title, children }) {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link rel="apple-touch-icon" href={AppleTouchIcon} sizes="180x180" />
        <link rel="icon" type="image/png" href={Favicon16X16} sizes="16x16" />
        <link rel="icon" type="image/png" href={Favicon32X32} sizes="32x32" />
        <link rel="icon" type="image/png" href={AndroidChrome192X192} sizes="192x192" />
      </Helmet>
      {children}
    </>
  )
}

HtmlLayout.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default HtmlLayout
