import React from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { path } from 'ramda'
import { useSnackbar } from 'storfox-snackbar'
import { useTranslation } from 'react-i18next'

import { DashboardLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'
import { CUSTOMER_UPDATE_TABS } from '~/constants/tabs'

import { CustomerCreateForm } from '../components/CustomerCreate'
import { useCustomerCreate } from '../hooks'
import { CustomerCreateSerializer } from '../serializers'

function CustomerCreateContainer () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const customerCreate = useCustomerCreate()

  const message = t('Successfully created')

  const handleSubmit = formValues =>
    customerCreate.create(CustomerCreateSerializer(formValues))
      .then(data => {
        const params = {
          guid: path(['result', 'guid'], data),
          tab: CUSTOMER_UPDATE_TABS.GENERAL
        }
        const redirect = generatePath(ROUTES.CUSTOMER_UPDATE_PATH, params)

        navigate(redirect)
      })
      .then(() => snackbar({ message }))

  const title = 'New customer'

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      isLoading={customerCreate.isLoading}
      activeNav={NAV.CUSTOMERS}
      breadcrumbs={breadcrumbs}
    >

      <CustomerCreateForm
        pageTitle={title}
        onSubmit={handleSubmit}
        autoComplete={false}
        isLoading={customerCreate.isLoading}
      />
    </DashboardLayout>
  )
}

export default CustomerCreateContainer
