import React from 'react'
import List from '@mui/material/List'
import PropTypes from 'prop-types'
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom'
import { ListItemButton } from '@mui/material'

import ListItemText from '~/components/List/ListItemText'
import { MoreListButton } from '~/components/Buttons'
import IconText from '~/components/IconText'

function More ({ onTeamMetricsExport, tableRef }) {
  return (
    <MoreListButton tableRef={tableRef}>
      {onClose => (
        <List>
          <ListItemButton
            onClick={() =>
              onTeamMetricsExport()
                .finally(() => onClose())
            }
          >
            <IconText Icon={VerticalAlignBottomIcon}>
              <ListItemText primary="Export" />
            </IconText>
          </ListItemButton>
        </List>
      )}
    </MoreListButton>
  )
}

More.propTypes = {
  onTeamMetricsExport: PropTypes.func.isRequired,
  tableRef: PropTypes.object
}

export default More
