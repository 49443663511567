import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { filter, map, path, prop, propOr } from 'ramda'
import { generatePath, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import EditIcon from '@mui/icons-material/Edit'

import { DeleteRowsButton, NewButton } from '~/components/Buttons'
import Avatar from '~/components/Avatar/Avatar'
import PageTitle from '~/components/PageTitle'
import Tags from '~/components/Tags'
import TableDateFormat from '~/components/TableDateFormat'
import Container, { Content, Header } from '~/components/Container'
import TextOverflow from '~/components/TextOverflow'
import { useCompany } from '~/components/Profile'
import { Table, TableHeader } from '~/components/Table'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import { PRODUCT_DETAIL_TABS, PRODUCT_UPDATE_TABS } from '~/constants/tabs'
import TableLink from '~/components/Link'

import BulkActionButton from './BulkActionButton'
import More from './More'

const getRetailerColumns = filter(column => prop('field', column) !== 'company')

export const PRODUCT_COLUMNS = [
  {
    width: 75,
    headerName: 'Image',
    field: 'image',
    primary: true,
    sortable: false,
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        <Avatar alt={row.name} src={value} />
      </TableLink>
    )
  },
  {
    width: 250,
    headerName: 'Name',
    field: 'name',
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        <TextOverflow lines={1} selfTooltip={true}>
          {value}
        </TextOverflow>
      </TableLink>
    )
  },
  {
    width: 150,
    headerName: 'Variants',
    field: 'variantCount',
    headerAlign: 'center',
    align: 'center'
  },
  {
    width: 150,
    headerName: 'Category',
    field: 'category',
    renderCell: ({ value }) => (
      <TextOverflow selfTooltip={true}>{value}</TextOverflow>
    )
  },
  {
    width: 150,
    headerName: 'Brand',
    field: 'brand',
  },
  {
    width: 150,
    headerName: 'Company',
    field: 'company'
  },
  {
    width: 300,
    headerName: 'Tags',
    field: 'tags',
    renderCell: ({ value }) => (
      <Tags items={value} />
    )
  },
  {
    width: 150,
    headerName: 'Track SN',
    field: 'trackSerialNumbers'
  },
  {
    width: 150,
    headerName: 'Track expiry',
    field: 'trackExpiryDates'
  },
  {
    width: 150,
    headerName: 'Last updated',
    field: 'updatedAt',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  }
]

const getRowsFromResults = map(item => {
  const id = prop('id', item)
  const name = prop('name', item)

  const image = prop('defaultImage', item)

  const variantCount = prop('variantCount', item)
  const category = path(['category', 'name'], item)

  const brand = path(['brand', 'name'], item)
  const company = path(['company', 'name'], item)

  const tags = propOr([], 'tags', item)

  const trackSerialNumbers = prop('trackSerialNumbers', item) ? 'Yes' : 'No'
  const trackExpiryDates = prop('trackExpiryDates', item) ? 'Yes' : 'No'
  const updatedAt = prop('updatedAt', item)

  const link = generatePath(ROUTES.PRODUCT_DETAIL_PATH, { id, tab: PRODUCT_DETAIL_TABS.GENERAL })

  return {
    id,
    image,
    name,
    variantCount,
    category,
    brand,
    company,
    tags,
    trackSerialNumbers,
    trackExpiryDates,
    updatedAt,
    link
  }
})

const getColumns = (isRetailer, columns) => {
  const retailerColumns = getRetailerColumns(columns)

  return isRetailer ? retailerColumns : columns
}

function ProductTable (props) {
  const { list, filter, ordering, onListRefetch, onProductsDelete, onProductsExport, bulkActionLoading } = props
  const [tableRef, setTableRef] = useState(null)
  const { t } = useTranslation()
  const { isRetailer } = useCompany()
  const navigate = useNavigate()
  const isLoading = prop('isLoading', list)
  const results = prop('results', list)
  const hasNextPage = prop('hasNextPage', list)
  const hasPrevPage = prop('hasPrevPage', list)

  const count = prop('count', list)
  const columns = prop('filteredColumns', ordering)

  const handleEdit = useCallback(id => {
    const editPath = generatePath(ROUTES.PRODUCT_UPDATE_PATH, { id, tab: PRODUCT_UPDATE_TABS.GENERAL })
    navigate(editPath)
  }, [navigate])

  const actionColumns = useMemo(() => [
    ...columns,
    {
      width: 50,
      resizable: false,
      field: 'actions',
      type: 'actions',
      getActions: ({ row }) => [
        <GridActionsCellItem
          label="Edit"
          onClick={() => handleEdit(row.id)}
          showInMenu={true}
          icon={<EditIcon />}
        />
      ]
    }
  ], [columns, handleEdit])

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={t(NAV.PRODUCTS)}
          parentTitle={t(NAV.CATALOG)}
          rightButton={(
            <>
              <BulkActionButton onProductsDelete={onProductsDelete} loading={bulkActionLoading} />
              <NewButton path={ROUTES.PRODUCT_CREATE_PATH} />
            </>
          )}
        />
      </Header>

      <Content>
        <TableHeader
          filter={filter}
          filterOpen={filter.handleOpen}
          orderingOpen={ordering.handleOpen}
          ordering={ordering}
        >
          <More onProductsExport={onProductsExport} tableRef={tableRef} />
        </TableHeader>
        <Table
          isLoading={isLoading}
          columns={getColumns(isRetailer, actionColumns)}
          getRowsFromResults={getRowsFromResults}
          results={results}
          count={count}
          pinnedColumns={['image', 'name']}
          checkboxSelection={true}
          ordering={ordering}
          onRefetch={onListRefetch}
          selectComponent={<DeleteRowsButton onDelete={onProductsDelete} />}
          setTableRef={setTableRef}
          isNewPagination={true}
          hasNextPage={hasNextPage}
          hasPrevPage={hasPrevPage}
        />
      </Content>
    </Container>
  )
}

ProductTable.propTypes = {
  list: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  onProductsDelete: PropTypes.func.isRequired,
  onProductsExport: PropTypes.func.isRequired,
  onListRefetch: PropTypes.func.isRequired,
  bulkActionLoading: PropTypes.bool
}

export default ProductTable
