import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'
import { useTheme } from '@mui/material'

const d = 'M18.61 5.89c-.01-.1-.11-.16-.17-.16c-.07-.01-1.61-.' +
  '12-1.61-.12s-1.07-1.06-1.18-1.18c-.12-.12-.35-.08-.44-.06c-.01 ' +
  '0-.21.07-.6.18c-.36-1.05-.99-1.97-2.18-1.97c-.32-.4-.71-.58-1.05' +
  '-.58c-2.58 0-3.81 3.22-4.2 4.86l-1.81.56c-.55.18-.57.2-.64.72c-.06.4-1.52 ' +
  '11.72-1.52 11.72L14.61 22l6.18-1.34S18.62 6 18.61 5.89M14 4.76c-.31.09-.63.19-1 ' +
  '.3v-.21c0-.64-.07-1.15-.21-1.56c.56.07.94.71 1.21 1.47m-1.92-1.34c.16.4.26.97.26 ' +
  '1.74v.11c-.63.19-1.31.41-1.99.62c.38-1.49 1.1-2.2 1.73-2.47m-.77-.73c.11 0 ' +
  '.22.04.33.11c-.83.39-1.71 1.37-2.09 3.32c-.55.18-1.08.34-1.55.5c.42-1.5 1.46-3.93 ' +
  '3.31-3.93m1.19 6.46l-.74 2.27s-.67-.36-1.49-.36c-1.2 0-1.27.75-1.27.94c0 1.04 2.71' +
  ' 1.43 2.71 3.86c0 1.91-1.21 3.14-2.84 3.14c-1.96 0-2.96-1.22-2.96-1.22l.52-1.73s1.03.88 ' +
  '1.9.88c.57 0 .8-.43.8-.77c0-1.35-2.21-1.41-2.21-3.63c0-1.87 1.34-3.68 4.05-3.68c1.03 0 ' +
  '1.53.3 1.53.3m2.93-3.86l1.32 1.31l.96.08c.34 2.32 1.48 10.05 1.95 13.2l-5 1.09l.77-15.68z'

function Shopify (props) {
  const theme = useTheme()

  return (
    <SvgIcon style={{ color: theme.palette.icon.primary }} {...props}>
      <path d={d} />
    </SvgIcon>
  )
}

export default Shopify
