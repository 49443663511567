import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { styled } from '@mui/material'

import Status from '~/components/Status'

const StatusStyled = styled(Status)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  position: 'absolute',
  right: 16,
  top: 16
}))
const ACTIVE = 'active'
const INACTIVE = 'inactive'

const COLORS = {
  [ACTIVE]: 'success',
  [INACTIVE]: 'error'
}

function IntegrationStatus ({ status }) {
  const { t } = useTranslation()

  return (
    <StatusStyled
      label={t(status)}
      color={COLORS[status]}
    />
  )
}

IntegrationStatus.propTypes = {
  status: PropTypes.string.isRequired
}

export default IntegrationStatus
