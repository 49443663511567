import React from 'react'

import SelectField from '~/components/Fields/SelectField'
import { BARCODE_TYPE } from '~/constants/barcode'

const BARCODE_TYPE_OPTIONS = [
  { value: BARCODE_TYPE.CODE128, name: 'CODE128' },
  { value: BARCODE_TYPE.EON13, name: 'EON13' }
]

function BarcodeTypeSelectField () {
  return (
    <SelectField
      label="Barcode type"
      name="barcodeType"
      options={BARCODE_TYPE_OPTIONS}
    />
  )
}

export default BarcodeTypeSelectField
