import React from 'react'
import PropTypes from 'prop-types'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

import Tabs from '~/components/Tabs'
import { PRODUCT_UPDATE_PATH } from '~/constants/routes'
import { PRODUCT_UPDATE_TABS } from '~/constants/tabs'

function ProductUpdateFormTabs ({ count, value }) {
  const navigate = useNavigate()
  const { id } = useParams()
  const onChange = (event, tab) => {
    const redirect = generatePath(PRODUCT_UPDATE_PATH, { id, tab })

    navigate(redirect, { replace: true })
  }

  const tabs = [
    { value: PRODUCT_UPDATE_TABS.GENERAL, label: 'General' },
    { value: PRODUCT_UPDATE_TABS.VARIANTS, label: `Variants (${count})` }
  ]

  return (
    <Tabs
      tabs={tabs}
      value={value}
      onChange={onChange}
    />
  )
}

ProductUpdateFormTabs.propTypes = {
  value: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
}

export default ProductUpdateFormTabs
