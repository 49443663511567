import React from 'react'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import WbSunnyIcon from '@mui/icons-material/WbSunny'
import Brightness3Icon from '@mui/icons-material/Brightness3'
import CheckIcon from '@mui/icons-material/Check'
import { styled } from '@mui/material'

import Menu, { MenuHeader, MenuItem, MenuItemContent } from '../Menu'
import { DARK, LIGHT, useCurrentTheme } from '../../Theme'

const MenuItemStyled = styled(MenuItem, {
  shouldForwardProp: (propName) => propName !== 'isActive'
})(({ theme, isActive }) => ({
  ...(isActive && {
    '& *': {
      color: theme.palette.secondary.main
    }
  }),
}))

function AppearancePopup ({ open, anchorEl, onClose, onReturn, onChange }) {
  const currentTheme = useCurrentTheme()

  const isLight = currentTheme === LIGHT
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
    >
      <MenuHeader onClick={onReturn}>
        <ArrowBackIcon />
        <Box ml={2}>
          <Typography variant="subtitle1">Appearance</Typography>
        </Box>
      </MenuHeader>
      <Divider />
      <MenuItemStyled isActive={isLight} onClick={() => onChange(LIGHT)}>
        <MenuItemContent icon={<WbSunnyIcon />}>
          Light
        </MenuItemContent>
        {isLight && <CheckIcon />}
      </MenuItemStyled>
      <MenuItemStyled isActive={!isLight} onClick={() => onChange(DARK)}>
        <MenuItemContent icon={<Brightness3Icon sx={{ transform: 'rotate(180deg)' }} />}>
          Dark
        </MenuItemContent>
        {!isLight && <CheckIcon />}
      </MenuItemStyled>
    </Menu>
  )
}

AppearancePopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onReturn: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  anchorEl: PropTypes.any
}

export default AppearancePopup
