import React from 'react'
import { Ordering } from 'storfox-filter'

import { CONDITION_COLUMNS as tableColumns } from './ConditionTable'

import { FILTER_NAME as name } from '../../../constants/Condition'

export const conditionOrderingOptions = {
  name,
  tableColumns
}

function ConditionOrderingForm (props) {
  return (
    <Ordering {...props} />
  )
}

export default ConditionOrderingForm
