export const PENDING = 'PENDING'
export const SUCCESS = 'SUCCESS'
export const FAIL = 'FAIL'

export const initialState = {
  data: null,
  error: null,
  isSuccess: false,
  isFail: false,
  isLoading: false
}

export const reducer = (state, action) => {
  if (action.type === PENDING) {
    return { ...state, isLoading: true }
  }

  if (action.type === SUCCESS) {
    return {
      data: action.payload,
      error: null,
      isSuccess: true,
      isFail: false,
      isLoading: false
    }
  }

  if (action.type === FAIL) {
    return {
      data: null,
      error: action.payload,
      isSuccess: false,
      isFail: true,
      isLoading: false
    }
  }

  return state
}
