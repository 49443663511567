import React from 'react'
import PropTypes from 'prop-types'
import { map, path, prop } from 'ramda'
import { generatePath } from 'react-router-dom'

import { NewButton } from '~/components/Buttons'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import { Table, TableHeader } from '~/components/Table'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import TableLink from '~/components/Link'
import TextOverflow from '~/components/TextOverflow'

export const BUCKET_COLUMNS = [
  {
    width: 300,
    headerName: 'Name',
    field: 'name',
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        <TextOverflow lines={1} selfTooltip={true}>
          {value}
        </TextOverflow>
      </TableLink>
    )
  },
  {
    width: 300,
    headerName: 'Company',
    field: 'company'
  }
]

const getRowsFromResults = map(item => {
  const id = prop('id', item)
  const guid = prop('guid', item)
  const name = prop('name', item)
  const company = path(['company', 'name'], item)
  const link = generatePath(ROUTES.BUCKETS_UPDATE_PATH, { guid })

  return {
    id,
    guid,
    name,
    company,
    link
  }
})

function BucketTable ({ list, filter, ordering, onListRefetch }) {
  const isLoading = prop('isLoading', list)
  const results = prop('results', list)
  const count = prop('count', list)
  const columns = prop('filteredColumns', ordering)
  const hasNextPage = prop('hasNextPage', list)
  const hasPrevPage = prop('hasPrevPage', list)

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={NAV.BUCKETS}
          parentTitle={NAV.CROSS_DOCKS}
          rightButton={(
            <NewButton path={ROUTES.BUCKETS_CREATE_PATH} />
          )}
        />
      </Header>

      <Content>
        <TableHeader
          filter={filter}
          filterOpen={filter.handleOpen}
          orderingOpen={ordering.handleOpen}
          ordering={ordering}
        />
        <Table
          isLoading={isLoading}
          columns={columns}
          results={results}
          getRowsFromResults={getRowsFromResults}
          count={count}
          checkboxSelection={true}
          primaryKey="guid"
          ordering={ordering}
          onRefetch={onListRefetch}
          isNewPagination={true}
          hasNextPage={hasNextPage}
          hasPrevPage={hasPrevPage}
        />
      </Content>
    </Container>
  )
}

BucketTable.propTypes = {
  list: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  onListRefetch: PropTypes.func.isRequired,
}

export default BucketTable
