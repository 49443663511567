import React from 'react'
import PropTypes from 'prop-types'
import { map, path, prop, propOr } from 'ramda'
import { useTranslation } from 'react-i18next'

import PageTitle from '~/components/PageTitle'
import TableDateFormat from '~/components/TableDateFormat'
import Tags from '~/components/Tags'
import TableNotes from '~/components/TableNotes'
import Container, { Content, Header } from '~/components/Container'
import TextOverflow from '~/components/TextOverflow'
import { Table, TableHeader } from '~/components/Table'
import * as NAV from '~/constants/nav-titles'
import Button from '~/components/Buttons/Button'

import PurchaseReceiveStatus from '../../components/Purchase/Field/PurchaseReceiveStatus'

export const PURCHASE_ORDER_COLUMNS = [
  {
    width: 100,
    headerName: 'PO #',
    field: 'number',
    renderCell: ({ value }) => (
      <TextOverflow selfTooltip={true}>
        {value}
      </TextOverflow>
    )
  },
  {
    width: 200,
    headerName: 'Supplier',
    field: 'sourceName',
    renderCell: ({ value }) => (
      <TextOverflow selfTooltip={true}>
        {value}
      </TextOverflow>
    )
  },
  {
    width: 150,
    headerName: 'Status',
    field: 'status',
    renderCell: ({ value }) => (
      <PurchaseReceiveStatus value={value} />
    )
  },
  {
    width: 150,
    headerName: 'Quantity',
    field: 'totalQuantity'
  },
  {
    width: 150,
    headerName: 'Received',
    field: 'received'
  },
  {
    width: 150,
    headerName: 'Under received',
    field: 'underReceived'
  },
  {
    width: 150,
    headerName: 'Over received',
    field: 'overReceived'
  },
  {
    width: 200,
    headerName: 'Receiving warehouse',
    field: 'warehouse'
  },
  {
    width: 200,
    headerName: 'Expected date',
    field: 'expectedDate',
    renderCell: ({ value }) => (
      <TableDateFormat date={value} />
    )
  },
  {
    width: 300,
    headerName: 'Tags',
    field: 'tags',
    renderCell: ({ value }) => (
      <Tags items={value} />
    )
  },
  {
    width: 125,
    headerName: 'Notes',
    field: 'notes',
    renderCell: ({ value }) => (
      <TableNotes>
        {value}
      </TableNotes>
    )
  }
]

const getRowsFromResults = map(item => {
  const id = prop('id', item)
  const guid = prop('guid', item)
  const number = prop('number', item)
  const sourceName = prop('sourceName', item)
  const status = prop('status', item)
  const totalQuantity = prop('totalQuantity', item)
  const received = prop('received', item)
  const overReceived = received - totalQuantity
  const underReceived = totalQuantity - received
  const warehouse = path(['warehouse', 'name'], item)
  const expectedDate = prop('expectedDate', item)
  const tags = propOr([], 'tags', item)
  const notes = prop('notes', item)

  return {
    id,
    guid,
    number,
    sourceName,
    status,
    totalQuantity,
    warehouse,
    expectedDate,
    tags,
    notes,
    received,
    overReceived: overReceived > 0 ? overReceived : 0,
    underReceived
  }
})

function PurchaseTable (props) {
  const {
    list,
    filter,
    ordering,
    onReportGenerate,
    onListRefetch
  } = props

  const { t } = useTranslation()
  const isLoading = prop('isLoading', list)
  const results = prop('results', list)
  const count = prop('count', list)
  const columns = prop('filteredColumns', ordering)

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={NAV.PURCHASE_REPORT}
          parentTitle={NAV.REPORTS}
          rightButton={(
            <Button
              variant="contained"
              data-cy="create"
              type="button"
              onClick={onReportGenerate}
            >
              {t('Generate Report')}
            </Button>
          )}
        />
      </Header>

      <Content>
        <TableHeader
          filter={filter}
          ordering={ordering}
          isLoading={isLoading}
          filterOpen={filter.handleOpen}
          orderingOpen={ordering.handleOpen}
        />
        <Table
          isLoading={isLoading}
          columns={columns}
          getRowsFromResults={getRowsFromResults}
          results={results}
          count={count}
          pinnedColumns={['number']}
          primaryKey="guid"
          ordering={ordering}
          checkboxSelection={true}
          onRefetch={onListRefetch}
        />
      </Content>
    </Container>
  )
}

PurchaseTable.propTypes = {
  list: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  onReportGenerate: PropTypes.func.isRequired,
  onListRefetch: PropTypes.func.isRequired
}

export default PurchaseTable
