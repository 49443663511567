import React from 'react'
import Grid from '@mui/material/Grid'
import { SwitchField } from 'storfox-form-fields'
import { Typography } from '@mui/material'

import StatusField from '~/components/Fields/StatusField'
import TextField from '~/components/Fields/TextField'

function ShipoxCreate () {
  return (
    <>
      <Grid item={true} xs={12} lg={4}>
        <TextField
          data-cy="name"
          name="name"
          label="Name"
        />
      </Grid>

      <Grid item={true} xs={12} lg={4}>
        <TextField
          name="credential.username"
          data-cy="username"
          label="Username"
        />
      </Grid>

      <Grid item={true} xs={12} lg={4}>
        <TextField
          name="credential.storeDomain"
          data-cy="storeDomain"
          label="Domain"
        />
      </Grid>
      <Grid item={true} xs={12} lg={4}>
        <TextField
          data-cy="password"
          inputProps={{
            type: 'password'
          }}
          name="credential.password"
          label="Password"
        />
      </Grid>

      <Grid item={true} xs={12} lg={4}>
        <TextField
          data-cy="merchantId"
          name="credential.merchantId"
          label="Marketplace ID"
        />
      </Grid>

      <Grid item={true} xs={12} lg={4}>
        <StatusField />
      </Grid>

      <Grid item={true} xs={12}>
        <TextField
          data-cy="description"
          name="description"
          label="Description"
          multiline={true}
          rows="4"
        />
      </Grid>

      <Grid item={true} xs={12} sx={{ display: 'flex' }}>
        <SwitchField
          name="credential.trackingByReferenceNumber"
          label={
            <Typography>
              Use Sales Order "Reference Number" for tracking
              <Typography
                sx={{ color: '#ED9121' }}
                component="h6"
                variant="caption"
              >
                Not recommended
              </Typography>
            </Typography>
          } />
      </Grid>
    </>
  )
}

export default ShipoxCreate
