import React from 'react'
import Dialog from '@mui/material/Dialog/Dialog'
import DialogContent from '@mui/material/DialogContent/DialogContent'
import DialogActions from '@mui/material/DialogActions/DialogActions'
import { prop } from 'ramda'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import { dialogClasses, styled } from '@mui/material'
import Box from '@mui/material/Box'
import { useField } from 'react-final-form'

import { Button } from '~/components/Buttons'
import Divider from '~/components/Divider'
import DiscardButton from '~/components/Buttons/DiscardButton'
import { withForm } from '~/components/Form'
import DialogTitle from '~/components/DialogTitle'
import PositiveNumberField from '~/components/Fields/PositiveNumberField'
import Avatar from '~/components/Avatar'

const DialogNameBoxStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '10px',
  justifyContent: 'space-between',
  '& span:first-of-type': {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '16px'
  },
  '& span:last-child': {
    color: 'rgba(39, 50, 87, 0.7)',
    fontSize: '12px'
  }
})

const DialogStyled = styled(Dialog)({
  [`& .${dialogClasses.paper}`]: {
    maxWidth: '710px'
  },
})

function UnitsBarcodePrintDialogue ({ open, onClose, ...props }) {
  const { form } = props

  const handleSubmit = prop('handleSubmit', form)

  const lineItemsField = useField('lineItems')
  const lineItemsFieldValue = lineItemsField.input.value

  return (
    <DialogStyled
      open={open}
      onClose={onClose}
      fullWidth={true}
    >
      <DialogTitle>Units barcode print</DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container={true} spacing={2}>
          {lineItemsFieldValue.map((item, index) => {
            const productVariant = prop('variant', item)
            const image = prop('defaultImage', productVariant)
            const variantName = prop('name', productVariant)
            const barcode = prop('barcode', productVariant)
            return (
              <>
                <Grid item={true} xs={6}>
                  <Box display="flex">
                    <Avatar
                      alt="unit-barcode-print"
                      src={image}
                    />
                    <DialogNameBoxStyled>
                      <span>{variantName}</span>
                      <span>{barcode}</span>
                    </DialogNameBoxStyled>
                  </Box>
                </Grid>
                <Grid item={true} xs={6}>
                  <PositiveNumberField label="Quantity" name={`lineItems.${index}.printQuantity`} />
                </Grid>
              </>
            )
          })}
        </Grid>
      </DialogContent>
      <DialogActions>
        <DiscardButton onClick={onClose} />
        <Button
          variant="contained"
          type="button"
          onClick={handleSubmit}
          data-cy="barcodePrint"
        >
          Print
        </Button>
      </DialogActions>
    </DialogStyled>
  )
}

UnitsBarcodePrintDialogue.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  form: PropTypes.object,
}

export default withForm(UnitsBarcodePrintDialogue)
