import React from 'react'
import { prop } from 'ramda'
import Grid from '@mui/material/Grid'
import { generatePath } from 'react-router-dom'

import Container, { Content, Header } from '~/components/Container'
import PageTitle from '~/components/PageTitle'
import { DiscardButton } from '~/components/Buttons'
import { IntegrationIcon } from '~/components/Icons'
import { VerticalTypeCard } from '~/components/Cards/VerticalTypeCard'
import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'

import { INTEGRATIONS, NAMES } from '../../constants'

function IntegrationType () {
  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle="Choose integration"
          parentTitle={NAV.INTEGRATION}
          rightButton={(
            <DiscardButton to={ROUTES.INTEGRATION_LIST_PATH} />
          )}
        />
      </Header>
      <Content>

        <Grid container={true} spacing={3}>
          {INTEGRATIONS.map(integration => {
            const name = prop('name', integration)
            const type = prop('type', integration)
            const subtype = prop('subtype', integration)
            const disabled = prop('disabled', integration)
            const description = prop('description', integration)
            const path = prop('path', integration)
            const externalLink = prop('externalLink', integration)
            const generatedPath = generatePath(path, { type: subtype })
            const icon = <IntegrationIcon style={{ width: '150px', height: '150px' }} type={subtype} />

            return (
              <Grid item={true} xs={12} sm={6} md={4} lg={3} key={subtype}>
                <VerticalTypeCard
                  typeName={NAMES[type]}
                  subtype={subtype}
                  type={type}
                  name={name}
                  icon={icon}
                  disabled={disabled}
                  path={generatedPath}
                  externalLink={externalLink}
                  description={description}
                />
              </Grid>
            )
          }
          )}
        </Grid>
      </Content>
    </Container>
  )
}

export default IntegrationType
