import React from 'react'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'

import { getSxArray } from '~/utils'

function IconText ({ children, Icon, iconProps, sx, ...props }) {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={[
        {
          color: theme => theme.palette.icon.primary
        },
        ...getSxArray(sx)
      ]}
      {...props}
    >
      <Icon fontSize="small" {...iconProps} />
      <Box ml={1} />
      {children}
    </Box>
  )
}

IconText.propTypes = {
  Icon: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
  iconProps: PropTypes.object,
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
}

export default IconText
