import React from 'react'
import PropTypes from 'prop-types'
import List from '@mui/material/List'
import ListItemText from '@mui/material/ListItemText'
import { ListItemButton } from '@mui/material'

import { MoreButton } from '~/components/Buttons'

function More ({ readAllDisabled, onAllRead }) {
  return (
    <MoreButton>
      {onClose => (
        <List>
          <ListItemButton
            disabled={readAllDisabled}
            onClick={() =>
              onAllRead()
                .then(() => {
                  onClose()
                })
            }>
            <ListItemText primary="Mark all as read" />
          </ListItemButton>
        </List>
      )}
    </MoreButton>
  )
}

More.propTypes = {
  readAllDisabled: PropTypes.bool.isRequired,
  onAllRead: PropTypes.func.isRequired
}

export default More
