import React from 'react'
import Dialog from '@mui/material/Dialog'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'
import { DialogActions } from '@mui/material'

import DialogTitle from '~/components/DialogTitle'
import { DiscardButton, PrimaryButton } from '~/components/Buttons'
import { generateCdnUrl } from '~/utils'

function PrintDialog (props) {
  const {
    open,
    onClose,
    awbUrl,
    commercialInvoiceUrl,
    onScanNextOrder,
    onPrintInvoice
  } = props
  const { t } = useTranslation()

  return (
    <Dialog
      open={open}
      fullWidth={true}
      onClose={onClose}
      maxWidth="sm"
    >
      <DialogTitle component="div">
        <Typography
          component="h2"
          variant="h6"
        >
          {t('Print AWB and Commercial Invoice')}
        </Typography>
      </DialogTitle>

      <DialogActions>
        <Grid container={true} spacing={1}>
          {awbUrl && (
            <Grid item={true} xs={12}>
              <PrimaryButton
                fullWidth={true}
                onClick={() => window.open(generateCdnUrl(awbUrl), '_blank')}
              >
                Print AWB
              </PrimaryButton>
            </Grid>
          )}
          {commercialInvoiceUrl && (
            <Grid item={true} xs={12}>
              <PrimaryButton
                fullWidth={true}
                onClick={() => window.open(generateCdnUrl(commercialInvoiceUrl), '_blank')}
              >
                Print Commercial Invoice
              </PrimaryButton>
            </Grid>
          )}
          {!commercialInvoiceUrl && (
            <Grid item={true} xs={12}>
              <PrimaryButton
                fullWidth={true}
                onClick={onPrintInvoice}
              >
                Print Commercial Invoice
              </PrimaryButton>
            </Grid>
          )}

          <Grid item={true} xs={12}>
            <PrimaryButton
              fullWidth={true}
              onClick={onScanNextOrder}
            >
              Go To Scan Container
            </PrimaryButton>
          </Grid>

          <Grid item={true} xs={12}>
            <DiscardButton
              onClick={onClose}
              fullWidth={true}
            >
              Discard
            </DiscardButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

PrintDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  awbUrl: PropTypes.string,
  commercialInvoiceUrl: PropTypes.string,
  onScanNextOrder: PropTypes.func.isRequired,
  onPrintInvoice: PropTypes.func.isRequired
}

export default PrintDialog
