import React from 'react'
import { prop } from 'ramda'

import * as API from '~/constants/api'

import SearchField from './SearchField'

const getOptionLabel = item => {
  if (item) {
    return prop('locationId', item)
  }

  return ''
}

const getOptionValue = item => {
  if (item) {
    const id = prop('id', item)
    const guid = prop('guid', item)
    const locationId = prop('locationId', item)

    return { id, guid, locationId }
  }

  return null
}

function LocationField (props) {
  return (
    <SearchField
      name="location"
      label="Location"
      api={API.STOCKS_LOCATION_LIST}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      {...props}
    />
  )
}

export default LocationField
