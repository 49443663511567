import React from 'react'
import PropTypes from 'prop-types'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { useTranslation } from 'react-i18next'
import { ListItemButton } from '@mui/material'

import { MoreButton } from '~/components/Buttons'

function More (props) {
  const {
    pickerDialogOpenDisabled,
    onPickerDialogOpen,
    onGeneratePdf,
    onComplete,
    onCancel,
    cancelDisabled
  } = props

  const { t } = useTranslation()

  return (
    <MoreButton>
      {onClose => (
        <List>
          <ListItem
            button={true}
            disabled={pickerDialogOpenDisabled}
            onClick={() => {
              onPickerDialogOpen()
              onClose()
            }}
            data-cy="setPacker"
          >
            <ListItemText primary={t('Set picker')} />
          </ListItem>
          <ListItemButton onClick={onGeneratePdf}>
            <ListItemText primary={t('Generate Pdf')} />
          </ListItemButton>
          <ListItemButton onClick={onComplete}>
            <ListItemText primary={t('Complete Partial Picking')} />
          </ListItemButton>
          <ListItemButton onClick={onCancel} disabled={cancelDisabled}>
            <ListItemText primary={t('Cancel')} />
          </ListItemButton>
        </List>
      )}
    </MoreButton>
  )
}

More.propTypes = {
  pickerDialogOpenDisabled: PropTypes.bool.isRequired,
  onPickerDialogOpen: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  cancelDisabled: PropTypes.bool,
  onGeneratePdf: PropTypes.func.isRequired
}

export default More
