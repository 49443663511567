import React from 'react'
import { prop } from 'ramda'

import * as API from '~/constants/api'

import MultiSelectSearchField from './MultiSelectSearchField'

const getOptionLabel = item => {
  if (item) {
    const firstName = prop('firstName', item)
    const lastName = prop('lastName', item)
    return `${firstName} ${lastName}`
  }

  return ''
}

const getOptionValue = item => {
  if (item) {
    const id = prop('id', item)
    const guid = prop('guid', item)
    const firstName = prop('firstName', item)
    const lastName = prop('lastName', item)

    return { id, guid, firstName, lastName }
  }

  return null
}

function UserMultiSelectField (props) {
  return (
    <MultiSelectSearchField
      api={API.USER_LIST}
      data-cy="assignedTo"
      name="assignedTo"
      label="Assigned to"
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      {...props}
    />
  )
}

export default UserMultiSelectField
