import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { propOr } from 'ramda'
import { useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import useMessages from '~/hooks/useMessages'
import * as ROUTES from '~/constants/routes'

import BucketUpdateForm from '../components/BucketUpdateForm'
import { useBucketDetail, useBucketUpdate, useBucketDelete } from '../hooks'
import { BucketCreateSerializer } from '../serializers'

function BucketUpdateContainer () {
  const { guid } = useParams()
  const messages = useMessages()
  const snackbar = useSnackbar()
  const navigate = useNavigate()

  const bucketDetail = useBucketDetail(guid)
  const bucketUpdate = useBucketUpdate(guid)
  const bucketDelete = useBucketDelete(guid)

  const handleSubmit = formValue =>
    bucketUpdate.update(BucketCreateSerializer(formValue))
      .then(() => bucketDetail.getDetail())
      .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))

  const handleDelete = () =>
    bucketDelete.delete()
      .then(() => navigate(ROUTES.BUCKETS_LIST_PATH))
      .then(() => snackbar({ message: messages.DELETE_SUCCESS }))

  const name = propOr('', 'name', bucketDetail.detail)

  const breadcrumbs = {
    title: 'Edit',
    params: [
      { guid, title: name }
    ]
  }

  const isLoading = bucketDetail.isLoading || bucketUpdate.isLoading

  return (
    <DashboardLayout
      title={NAV.BUCKETS}
      activeNav={NAV.BUCKETS}
      isLoading={isLoading}
      breadcrumbs={breadcrumbs}
    >
      <BucketUpdateForm
        pageTitle={NAV.BUCKETS}
        pageTitleLoading={bucketDetail.isLoading}
        isLoading={isLoading}
        initialValues={bucketDetail.detail}
        onSubmit={handleSubmit}
        onDelete={handleDelete}
      />
    </DashboardLayout>
  )
}

export default BucketUpdateContainer
