import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import { prop, propOr } from 'ramda'
import Link from '@mui/material/Link'

import Avatar from '~/components/Avatar'
import TextLabelLoader from '~/components/TextLabelLoader'

function SupplierInfoRow ({ supplier, link }) {
  const avatar = prop('avatar', supplier)
  const shortName = propOr('', 'shortName', supplier)
  const companyName = prop('companyName', supplier)
  const phone = prop('phone', supplier)

  return (
    <Box display="flex" justifyContent="space-between">
      <Box display="flex">
        <Avatar src={avatar} alt={shortName} />
        <Box ml={1}>
          <TextLabelLoader label="Company name">
            {link && (
              <Link
                color="secondary"
                target="_blank"
                rel="noreferrer noopener"
                href={link}
              >
                {companyName}
              </Link>
            )}
          </TextLabelLoader>
        </Box>
      </Box>
      <TextLabelLoader label="Contact person">
        {shortName}
      </TextLabelLoader>
      <TextLabelLoader label="Phone number">
        {phone}
      </TextLabelLoader>
    </Box>
  )
}

SupplierInfoRow.propTypes = {
  supplier: PropTypes.object,
  link: PropTypes.string
}

export default SupplierInfoRow
