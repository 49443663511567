import PropTypes from 'prop-types'
import React from 'react'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { bindPopover, bindTrigger } from 'material-ui-popup-state/hooks'
import { Popover, styled } from '@mui/material'
import PopupState from 'material-ui-popup-state'
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded'

const IconButtonStyled = styled(IconButton)({
  background: 'rgba(25, 140, 255, 0.12)',
  borderRadius: 4,
  marginLeft: 5,
  '&:hover': {
    background: 'rgba(25, 140, 255, 0.12)'
  }
})

function StatusGridPopover ({ title, children, ...props }) {
  const transformOrigin = { vertical: 'top', horizontal: 'center' }
  const anchorOrigin = { vertical: 'bottom', horizontal: 'center' }

  return (
    <PopupState variant="popover">
      {popupState => (
        <Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            {title}
            <IconButtonStyled size="small" {...bindTrigger(popupState)}>
              <LaunchRoundedIcon style={{ color: '#198CFF' }} fontSize="inherit" />
            </IconButtonStyled>
          </Box>

          <Popover
            {...bindPopover(popupState)}
            disableScrollLock={false}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            {...props}
          >

            <Box sx={{ padding: theme => theme.spacing(1, 2) }}>
              {children}
            </Box>
          </Popover>
        </Box>
      )}
    </PopupState>
  )
}

StatusGridPopover.propTypes = {
  children: PropTypes.any.isRequired,
  title: PropTypes.any.isRequired
}

export default StatusGridPopover
