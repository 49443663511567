import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { join, map, pipe, pluck, prop, propOr } from 'ramda'
import { generatePath } from 'react-router-dom'

import { MoreListButton, NewButton } from '~/components/Buttons'
import Avatar from '~/components/Avatar/Avatar'
import PageTitle from '~/components/PageTitle'
import TextOverflow from '~/components/TextOverflow'
import Container, { Content, Header } from '~/components/Container'
import TableDateFormat from '~/components/TableDateFormat'
import { Table, TableHeader } from '~/components/Table'
import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'
import { USER_DETAIL_TABS } from '~/constants/tabs'
import BooleanStatus from '~/components/BooleanStatus'
import { getFullName } from '~/utils'
import TableLink from '~/components/Link'

import BulkActionButton from './BulkActionButton'

const joinName = (name, object) =>
  pipe(
    propOr([], name),
    pluck('name'),
    join(', ')
  )(object)

const getDetailPath = id => {
  return generatePath(ROUTES.USER_DETAIL_PATH, {
    id,
    tab: USER_DETAIL_TABS.GENERAL
  })
}

export const USER_COLUMNS = [
  {
    width: 150,
    headerName: 'Photo',
    field: 'image',
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        <Avatar alt={row.fullName} src={value} />
      </TableLink>
    )
  },
  {
    width: 150,
    headerName: 'Full name',
    field: 'fullName',
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        <TextOverflow selfTooltip={true}>
          {value}
        </TextOverflow>
      </TableLink>

    )
  },
  {
    width: 150,
    headerName: 'First name',
    field: 'firstName'
  },
  {
    width: 150,
    headerName: 'Last name',
    field: 'lastName'
  },
  {
    width: 200,
    headerName: 'Email',
    field: 'email',
    renderCell: ({ value }) => (
      <TextOverflow selfTooltip={true}>
        {value}
      </TextOverflow>
    )
  },
  {
    width: 200,
    headerName: 'Roles',
    field: 'roles',
    renderCell: ({ value }) => (
      <TextOverflow selfTooltip={true}>
        {value}
      </TextOverflow>
    )
  },
  {
    width: 200,
    headerName: 'Warehouses',
    field: 'warehouses',
    renderCell: ({ value }) => (
      <TextOverflow selfTooltip={true}>
        {value}
      </TextOverflow>
    )
  },
  {
    width: 200,
    headerName: 'Status',
    field: 'status',
    renderCell: ({ value }) => (
      <BooleanStatus value={value} />
    )
  },
  {
    width: 200,
    headerName: 'Last updated',
    field: 'updatedAt',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  },
]

const getRowsFromResults = map(item => {
  const id = prop('id', item)
  const fullName = getFullName(item)
  const firstName = prop('firstName', item)
  const lastName = prop('lastName', item)
  const image = prop('imagePath', item)
  const email = prop('email', item)
  const status = prop('status', item)
  const roles = joinName('roles', item)
  const warehouses = joinName('warehouses', item)
  const updatedAt = prop('updatedAt', item)
  const link = getDetailPath(id)

  return {
    id,
    image,
    fullName,
    firstName,
    lastName,
    email,
    status,
    roles,
    warehouses,
    updatedAt,
    link
  }
})

function UserTable ({ list, filter, ordering, onListRefetch, onUsersDelete, bulkActionLoading }) {
  const [tableRef, setTableRef] = useState(null)
  const isLoading = prop('isLoading', list)
  const results = prop('results', list)
  const count = prop('count', list)
  const columns = prop('filteredColumns', ordering)

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={NAV.USERS}
          parentTitle={NAV.SETTINGS}
          rightButton={(
            <>
              <BulkActionButton onUsersDelete={onUsersDelete} loading={bulkActionLoading} />
              <NewButton path={ROUTES.USER_CREATE_PATH} />
            </>
          )}
        />
      </Header>

      <Content>
        <TableHeader
          filter={filter}
          filterOpen={filter.handleOpen}
          orderingOpen={ordering.handleOpen}
          ordering={ordering}
        >
          <MoreListButton tableRef={tableRef} />
        </TableHeader>
        <Table
          isLoading={isLoading}
          columns={columns}
          getRowsFromResults={getRowsFromResults}
          results={results}
          count={count}
          checkboxSelection={true}
          pinnedColumns={['image', 'fullName']}
          ordering={ordering}
          onRefetch={onListRefetch}
          setTableRef={setTableRef}
        />
      </Content>
    </Container>
  )
}

UserTable.propTypes = {
  list: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  onUsersDelete: PropTypes.func.isRequired,
  onListRefetch: PropTypes.func.isRequired,
  bulkActionLoading: PropTypes.bool
}

export default UserTable
