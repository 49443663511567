import { pathOr } from 'ramda'
import React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useSnackbar } from 'storfox-snackbar'
import { useTranslation } from 'react-i18next'

import { getRouteTab } from '~/utils'
import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import { BUNDLE_DETAIL_TABS } from '~/constants/tabs'
import * as ROUTES from '~/constants/routes'

import { BundleUpdateForm } from '../components/BundleUpdate'
import { useBundleBrandCreate, useBundleCategoryCreate, useBundleDetail, useBundleUpdate } from '../hooks'
import { BundleUpdateInitialSerializer, BundleUpdateSerializer } from '../serializers'

function BundleUpdateContainer () {
  const { t } = useTranslation()
  const { guid, tab } = useParams()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const bundleDetail = useBundleDetail(guid)
  const bundleUpdate = useBundleUpdate(guid)
  const bundleCategoryCreate = useBundleCategoryCreate()
  const bundleBrandCreate = useBundleBrandCreate()

  const title = pathOr('Bundles', ['detail', 'name'], bundleDetail)
  const message = t('Successfully updated')

  const handleCategoryCreate = name =>
    bundleCategoryCreate.create({ name })

  const handleBrandCreate = name =>
    bundleBrandCreate.create({ name })

  const detailTab = getRouteTab(tab, BUNDLE_DETAIL_TABS, BUNDLE_DETAIL_TABS.GENERAL)
  const detailPath = generatePath(ROUTES.BUNDLE_DETAIL_PATH, { guid, tab: detailTab })

  const handleSubmit = formValues =>
    bundleUpdate.update(BundleUpdateSerializer(formValues))
      .then(() => navigate(detailPath))
      .then(() => snackbar({ message }))

  const pageLoading = bundleDetail.isLoading || bundleUpdate.isLoading

  const breadcrumbs = {
    title: 'Edit',
    params: [{ guid, tab: BUNDLE_DETAIL_TABS.GENERAL, title }]
  }

  return (
    <DashboardLayout
      title={title}
      isLoading={pageLoading}
      activeNav={t(NAV.BUNDLES)}
      breadcrumbs={breadcrumbs}
    >
      <BundleUpdateForm
        title={title}
        pageTitleLoading={bundleDetail.isLoading}
        initialValues={BundleUpdateInitialSerializer(bundleDetail.detail)}
        onSubmit={handleSubmit}
        isLoading={bundleUpdate.isLoading}
        categoryLoading={bundleCategoryCreate.isLoading}
        brandLoading={bundleBrandCreate.isLoading}
        onCategoryCreate={handleCategoryCreate}
        onBrandCreate={handleBrandCreate}
      />
    </DashboardLayout>
  )
}

export default BundleUpdateContainer
