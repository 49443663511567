import React from 'react'
import Builder from 'react-querybuilder'
import PropTypes from 'prop-types'
import { Box, styled } from '@mui/material'

import RemoveButton from './RemoveButton'

import { ActionButton, QueryBuilderSelectField, ToggleField, ValueEditorField } from './'

const RootStyled = styled(Box)(({ theme }) => ({
  '& .rule': {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1)
  },
  '& .queryBuilder > .ruleGroup': {
    margin: 0,
    padding: theme.spacing(2)
  },
  '& .ruleGroup': {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    padding: theme.spacing(1),
    border: `solid 1px ${theme.palette.divider}`,
    borderRadius: 3
  },
  '& .ruleGroup-addRule, & .ruleGroup-addGroup, & .ruleGroup-notToggle': {
    marginLeft: theme.spacing(1)
  },
  '& .rule-remove, & .ruleGroup-remove': {
    float: 'right'
  }
}))

const controlElements = {
  addGroupAction: ActionButton,
  addRuleAction: ActionButton,
  combinatorSelector: QueryBuilderSelectField,
  fieldSelector: QueryBuilderSelectField,
  notToggle: ToggleField,
  operatorSelector: QueryBuilderSelectField,
  removeGroupAction: RemoveButton,
  removeRuleAction: RemoveButton
}

function QueryBuilder (props) {
  const {
    fields,
    onQueryChange,
    valueEditor = ValueEditorField,
    ...rest
  } = props

  return (
    <RootStyled>
      <Builder
        fields={fields}
        onQueryChange={onQueryChange}
        controlElements={{ ...controlElements, valueEditor }}
        {...rest}
      />
    </RootStyled>
  )
}

QueryBuilder.propTypes = {
  fields: PropTypes.array,
  valueEditor: PropTypes.any.isRequired,
  onQueryChange: PropTypes.func.isRequired
}

export default QueryBuilder
