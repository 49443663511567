import React from 'react'
import { useTranslation } from 'react-i18next'
import { SelectField as StorfoxSelectField } from 'storfox-form-fields'
import PropTypes from 'prop-types'

function SelectField ({ label, options, ...props }) {
  const { t } = useTranslation()

  const translatedOptions = options.map(option => ({ ...option, name: t(option.name) }))

  return (
    <StorfoxSelectField label={t(label)} options={translatedOptions} {...props} />
  )
}

SelectField.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  variant: PropTypes.string,
  allowNull: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string
}

export default SelectField
