import { propOr } from 'ramda'

const symbol = {
  'EUR': '€',
  'GBP': '£',
  'USD': '$',
}

export const getCurrencySymbol = value => {
  return propOr(value, value, symbol)
}
