export const convertToSlug = (val, replaceBy) => {
  if (val) {
    replaceBy = replaceBy || '-'
    val = val.toLowerCase()
    val = val.replace(/[^a-z0-9-]+/g, replaceBy)
    return val
  }

  return ''
}
