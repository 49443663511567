import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import { Filter } from 'storfox-filter'
import { prop } from 'ramda'
import Typography from '@mui/material/Typography'
import { DateRangeField } from 'storfox-form-fields'
import { useTranslation } from 'react-i18next'

import Accordion from '~/components/Accordion'
import { escapeAtob, getSeparatedDateRange, unescapeBtoa } from '~/utils'

import ShipmentCompanySearchField from './ShipmentCompanySearchField'

import { FILTER_NAME as name } from '../../constants'

export const toParams = values => {
  const [createdAtStart, createdAtEnd] = getSeparatedDateRange(prop('createdAt', values))
  const company = unescapeBtoa(prop('company', values))

  return { company, createdAtStart, createdAtEnd }
}

export const toValues = (params) => {
  const company = escapeAtob(prop('company', params))
  const createdAt = [prop('createdAtStart', params), prop('createdAtEnd', params)]

  return { company, createdAt }
}

export const transformer = { toParams, toValues }

export const shipmentFilterOptions = {
  name,
  transformer
}

function ShipmentFilterForm (props) {
  const { t } = useTranslation()
  return (
    <Filter {...props}>
      <Accordion title="General" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <ShipmentCompanySearchField />
          </Grid>
        </Grid>
      </Accordion>
      <Accordion title="Date">
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <Typography variant="body2" gutterBottom={true}>
              {t('Created')}:
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <DateRangeField name="createdAt" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Accordion>
    </Filter>
  )
}

ShipmentFilterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
}

export default ShipmentFilterForm
