import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import NotificationListContainer from './containers/NotificationListContainer'

export default () => [
  {
    layout: AuthLayout,
    component: NotificationListContainer,
    path: ROUTES.NOTIFICATION_LIST_PATH
  }
]
