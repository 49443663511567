import React from 'react'

import SearchField from '~/components/Fields/SearchField'
import * as API from '~/constants/api'

const getOptionValue = value => {
  if (value) {
    const { id, name, operation } = value
    return { id, name, operation }
  }

  return null
}

function StockOwnerField (props) {
  return (
    <SearchField
      data-cy="owner"
      name="owner"
      label="Company"
      api={API.ADJUSTMENT_COMPANY_LIST}
      getOptionValue={getOptionValue}
      {...props}
    />
  )
}

export default StockOwnerField
