import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useField } from 'react-final-form'
import { path } from 'ramda'
import { useValueChanged } from 'storfox-tools'

import * as API from '~/constants/api'
import SearchField from '~/components/Fields/SearchField'

const getOptionValue = value => {
  if (value) {
    const { guid, name } = value
    return { id: guid, guid, name }
  }

  return null
}

function StockCountAreaField ({ warehouse, name, ...props }) {
  const warehouseField = useField(warehouse)
  const areaField = useField(name)

  const warehouseGuid = path(['input', 'value', 'guid'], warehouseField)

  const onChange = useCallback(
    path(['input', 'onChange'], areaField),
    []
  )

  useValueChanged(() => {
    onChange(undefined)
  }, warehouseGuid)

  return (
    <SearchField
      name={name}
      disabled={!warehouseGuid}
      getOptionValue={getOptionValue}
      api={API.AREA_LIST}
      params={{ warehouseGuid }}
      ListboxProps={{ 'data-cy': 'areaList' }}
      data-cy="area"
      {...props}
    />
  )
}

StockCountAreaField.propTypes = {
  name: PropTypes.string.isRequired,
  warehouse: PropTypes.string.isRequired
}

export default StockCountAreaField
