import React from 'react'
import PropTypes from 'prop-types'
import { map, pathOr, prop, propOr, path } from 'ramda'
import { generatePath } from 'react-router-dom'
import Grid from '@mui/material/Grid'

import Container, { Content, Header } from '~/components/Container'
import PageTitle from '~/components/PageTitle'
import Money from '~/components/Money'
import { Table, TableHeader } from '~/components/Table'
import * as ROUTES from '~/constants/routes'
import TableLink from '~/components/Link'
import { VARIANT_DETAIL_TABS } from '~/constants/tabs'
import { withForm } from '~/components/Form'

import BulkActionButton from './BulkActionButton'
import ReorderWarehouseSearchField from './ReorderWarehouseSearchField'

const getDetailPath = (id, productId, isMultiVariant = false) => {
  const path = generatePath(ROUTES.VARIANT_DETAIL_PATH, { id, productId, tab: VARIANT_DETAIL_TABS.GENERAL })
  return `${path}?isMultiVariant=${isMultiVariant}`
}

export const REORDER_COLUMNS = [
  {
    width: 250,
    headerName: 'Variant',
    field: 'name',
    valueGetter: ({ row }) => ({
      variantName: row.variantName,
      sku: row.sku,
      link: row.link
    }),
    valueFormatter: ({ value }) => `${value.variantName}/${value.sku}`,
    renderCell: ({ value, row }) => (
      <TableLink to={row.link} target="_blank">
        {`${value.variantName} - ${value.sku}`}
      </TableLink>
    )
  },
  {
    width: 200,
    headerName: 'Supplier',
    field: 'supplier'
  },
  {
    width: 200,
    headerName: 'Cost',
    field: 'supplierPrice',
    renderCell: ({ value }) => (
      <Money value={value} />
    )
  },
  {
    width: 200,
    headerName: 'Retail',
    field: 'retailPrice',
    renderCell: ({ value }) => (
      <Money value={value} />
    )
  },
  {
    width: 100,
    headerName: 'Needed',
    field: 'needed'
  },
  {
    width: 150,
    headerName: 'Reorder point',
    field: 'reorderPoint'
  },
  {
    width: 150,
    headerName: 'Desired level',
    field: 'reorderQuantity'
  },
  {
    width: 150,
    headerName: 'Available',
    field: 'available'
  },
  {
    width: 150,
    headerName: 'Awaiting',
    field: 'awaiting'
  },
]

const getColumnsFromParams = (columns, comparisonWarehouse) => {
  if (comparisonWarehouse) {
    return [
      ...columns,
      {
        width: 200,
        headerName: `Comparison available`,
        field: 'comparisonAvailable'
      }
    ]
  } else return columns
}

const getRowsFromResults = map(result => {
  const id = prop('id', result)
  const guid = prop('guid', result)
  const variant = prop('variant', result)
  const variantId = prop('id', variant)
  const productId = path(['product', 'id'], variant)
  const isMultiVariant = path(['product', 'isMultiVariant'], variant)
  const sku = prop('sku', variant)
  const variantName = prop('name', variant)
  const supplier = pathOr('', ['supplier', 'companyName'], variant)
  const supplyPrice = prop('supplyPrice', variant)
  const retailPrice = prop('retailPrice', variant)
  const reorderPoint = prop('reorderPoint', variant)
  const reorderQuantity = propOr(0, 'reorderQuantity', variant)
  const comparisonAvailable = prop('comparisonAvailable', result)
  const available = prop('available', result)
  const awaiting = prop('awaiting', result)
  const needed = reorderQuantity - (available + awaiting)
  const link = getDetailPath(variantId, productId, isMultiVariant)

  return {
    id,
    guid,
    sku,
    variantName,
    supplier,
    supplyPrice,
    retailPrice,
    reorderQuantity,
    reorderPoint,
    comparisonAvailable,
    available,
    awaiting,
    needed,
    link
  }
})

function ReorderTable (props) {
  const {
    filter,
    ordering,
    pageTitle,
    parentTitle,
    reorderList,
    onListRefetch,
    onWarehouseChange,
    handleReorder,
    comparisonWarehouse
  } = props

  const results = prop('results', reorderList)
  const isLoading = prop('isLoading', reorderList)
  const count = prop('count', reorderList)
  const filteredColumns = prop('filteredColumns', ordering)

  const columns = getColumnsFromParams(filteredColumns, comparisonWarehouse)

  return (
    <Container>
      <Header>
        <PageTitle
          parentTitle={parentTitle}
          pageTitle={pageTitle}
          rightButton={<BulkActionButton handleReorder={handleReorder} comparisonWarehouse={comparisonWarehouse} />}
        />
      </Header>
      <Content>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={3}>
            <ReorderWarehouseSearchField
              onValueChange={onWarehouseChange}
              searchFieldProps={{ onKeyDown: event => event.stopPropagation() }}
              ListboxProps={{ 'data-cy': 'warehouseList' }}
              data-cy="warehouse"
              sx={{ background: 'white' }}
            />
          </Grid>
          <Grid item={true} lg={12}>
            <TableHeader
              filter={filter}
              filterOpen={filter.handleOpen}
              orderingOpen={ordering.handleOpen}
              ordering={ordering}
            />
            <Table
              count={count}
              getRowsFromResults={getRowsFromResults}
              results={results}
              checkboxSelection={true}
              columns={columns}
              isLoading={isLoading}
              primaryKey="guid"
              ordering={ordering}
              pinnedColumns={['number']}
              onRefetch={onListRefetch}
            />
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

ReorderTable.propTypes = {
  filter: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  parentTitle: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  reorderList: PropTypes.object,
  onListRefetch: PropTypes.func.isRequired,
  onWarehouseChange: PropTypes.func.isRequired,
  handleReorder: PropTypes.func.isRequired,
  comparisonWarehouse: PropTypes.object
}

export default withForm(ReorderTable)
