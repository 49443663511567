import React from 'react'
import PropTypes from 'prop-types'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'

import { MoreButton } from '~/components/Buttons'

function More ({ cancelLoading, onCancel }) {
  const handleCancel = onClose =>
    onCancel()
      .then(() => onClose())

  return (
    <>
      <MoreButton>
        {onClose => (
          <List>
            <ListItem
              disabled={cancelLoading}
              button={true}
              onClick={() => handleCancel(onClose)}
            >
              <ListItemText primary="Cancel" />
            </ListItem>
          </List>
        )}
      </MoreButton>
    </>
  )
}

More.propTypes = {
  cancelLoading: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default More
