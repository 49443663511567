import React from 'react'
import PropTypes from 'prop-types'

import Status from '~/components/Status'

const INCREASE = 'increase'
const DECREASE = 'decrease'

const COLOR = {
  [INCREASE]: 'success',
  [DECREASE]: 'error',
}

function Type ({ value }) {
  return (
    <Status label={value} color={COLOR[value]} />
  )
}

Type.propTypes = {
  value: PropTypes.oneOf(['increase', 'decrease'])
}

export default Type
