import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { map, path, prop, propOr } from 'ramda'
import { generatePath } from 'react-router-dom'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'

import PageTitle from '~/components/PageTitle'
import { Table, TableHeader } from '~/components/Table'
import Tags from '~/components/Tags'
import TableNotes from '~/components/TableNotes'
import Container, { Content, Header } from '~/components/Container'
import { NewButton } from '~/components/Buttons'
import { PURCHASE_ORDER_DETAIL_TABS } from '~/constants/tabs'
import * as ROUTES from '~/constants/routes'
import TextOverflow from '~/components/TextOverflow'
import { PurchaseOrderStatus } from '~/components/Statuses'
import Money from '~/components/Money'
import OrderProgress from '~/components/OrderProgress'
import TableDateFormat from '~/components/TableDateFormat/TableDateFormat'
import TableLink from '~/components/Link'
import { PURCHASE_ORDER_STATUSES } from '~/constants/statuses'

import More from './More'
import BulkActionButton from './BulkActionButton'

export const PURCHASE_ORDER_COLUMNS = [
  {
    width: 100,
    headerName: 'PO #',
    field: 'number',
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        {value}
      </TableLink>
    )
  },
  {
    width: 200,
    headerName: 'Supplier',
    field: 'supplier',
    renderCell: ({ value }) => (
      <TextOverflow selfTooltip={true}>
        {value}
      </TextOverflow>
    )
  },
  {
    width: 150,
    headerName: 'Status',
    field: 'orderStatus',
    renderCell: ({ value }) => (
      <PurchaseOrderStatus value={value} />
    )
  },
  {
    width: 100,
    headerName: 'Products',
    field: 'products',
    style: {
      justifyContent: 'center'
    }
  },
  {
    width: 200,
    headerName: 'Total',
    field: 'totalPrice',
    renderCell: ({ value }) => (
      <Money value={value} />
    )
  },
  {
    width: 200,
    headerName: 'Received/Ordered',
    field: 'receivedStatus',
    valueFormatter: ({ value }) => `${value.received}-${value.ordered}`,
    renderCell: ({ value }) => (
      <OrderProgress
        ordered={value.ordered}
        received={value.received}
      />
    )
  },
  {
    width: 200,
    headerName: 'Receiving warehouse',
    field: 'warehouse'
  },
  {
    width: 200,
    headerName: 'Expected date',
    field: 'expectedDate',
    renderCell: ({ value }) => (
      <TableDateFormat date={value} />
    )
  },
  {
    width: 200,
    headerName: 'Created date',
    field: 'orderDate',
    renderCell: ({ value }) => (
      <TableDateFormat date={value} />
    )
  },
  {
    width: 200,
    headerName: 'Last updated',
    field: 'updatedAt',
    renderCell: ({ value }) => (
      <TableDateFormat date={value} />
    )
  },
  {
    width: 200,
    headerName: 'Supplier reference id',
    field: 'supplierReference'
  },
  {
    width: 300,
    headerName: 'Tags',
    field: 'tags',
    renderCell: ({ value }) => (
      <Tags items={value} />
    )
  },
  {
    width: 125,
    headerName: 'Notes',
    field: 'notes',
    renderCell: ({ value }) => (
      <TableNotes>
        {value}
      </TableNotes>
    )
  }
]

const getRowsFromResults = map(item => {
  const id = prop('id', item)
  const guid = prop('guid', item)
  const number = prop('number', item)
  const supplier = path(['supplier', 'shortName'], item)

  const receiptStatus = prop('receiptStatus', item)
  const orderStatus = prop('orderStatus', item)

  const products = prop('products', item)
  const totalPrice = prop('totalPrice', item)

  const ordered = prop('ordered', item)
  const received = prop('received', item)
  const receivedStatus = { ordered, received }

  const warehouse = path(['warehouse', 'name'], item)

  const expectedDate = prop('expectedDate', item)
  const orderDate = prop('orderDate', item)
  const updatedAt = prop('updatedAt', item)

  const tags = propOr([], 'tags', item)

  const supplierReference = prop('supplierReference', item)
  const notes = prop('notes', item)
  const link = generatePath(ROUTES.PURCHASE_ORDER_DETAIL_PATH, {
    guid,
    tab: PURCHASE_ORDER_DETAIL_TABS.GENERAL
  })

  return {
    id,
    guid,
    number,
    supplier,
    orderStatus,
    products,
    totalPrice,
    receivedStatus,
    warehouse,
    expectedDate,
    receiptStatus,
    orderDate,
    updatedAt,
    supplierReference,
    tags,
    notes,
    link
  }
})

function PurchaseOrderTable (props) {
  const {
    list,
    filter,
    ordering,
    onListRefetch,
    onPurchaseOrdersDelete,
    onGenerateExcelReport,
    onGeneratePdfReport,
    onCloseOrder,
    onCancelOrder,
    onDownloadOrder,
    onEmailSent,
    onInvoiceGenerate,
    onMarkPaid,
    onReturnCreate,
    bulkActionLoading
  } = props

  const [tableRef, setTableRef] = useState(null)
  const isLoading = prop('isLoading', list)
  const results = prop('results', list)
  const count = prop('count', list)
  const columns = prop('filteredColumns', ordering)

  const actionColumns = useMemo(() => [
    ...columns,
    {
      width: 80,
      resizable: false,
      headerName: 'Actions',
      field: 'actions',
      type: 'actions',
      getActions: ({ row }) => {
        const status = prop('status', row)
        const guid = prop('guid', row)

        const cancelDisabled = (
          status === PURCHASE_ORDER_STATUSES.CANCELLED ||
          status === PURCHASE_ORDER_STATUSES.CLOSED
        )

        const returnDisabled = (
          status === PURCHASE_ORDER_STATUSES.NEW ||
          status === PURCHASE_ORDER_STATUSES.PROGRESS ||
          status === PURCHASE_ORDER_STATUSES.CANCELLED
        )

        return [
          <GridActionsCellItem
            disabled={cancelDisabled}
            label="Close"
            onClick={() => onCloseOrder(guid)}
            showInMenu={true}
          />,
          <GridActionsCellItem
            disabled={cancelDisabled}
            label="Cancel"
            onClick={() => onCancelOrder(guid)}
            showInMenu={true}
          />,
          <GridActionsCellItem
            label="Download"
            onClick={() => onDownloadOrder(row.guid)}
            showInMenu={true}
          />,
          <GridActionsCellItem
            label="Email to supplier"
            onClick={() => onEmailSent(row.guid)}
            showInMenu={true}
          />,
          <GridActionsCellItem
            label="Generate Invoice Receipt"
            onClick={() => onInvoiceGenerate(row.guid)}
            showInMenu={true}
          />,
          <GridActionsCellItem
            disabled={cancelDisabled}
            label="Mark As Paid"
            onClick={() => onMarkPaid(row.guid)}
            showInMenu={true}
          />,
          <GridActionsCellItem
            disabled={returnDisabled}
            label="Request Return"
            onClick={() => onReturnCreate(row.guid)}
            showInMenu={true}
          />
        ]
      }
    }
  ], [
    columns,
    onCancelOrder,
    onCloseOrder,
    onDownloadOrder,
    onEmailSent,
    onInvoiceGenerate,
    onMarkPaid,
    onReturnCreate
  ])

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle="Purchase Orders"
          parentTitle="Purchases"
          rightButton={(
            <>
              <BulkActionButton
                onOrdersDelete={onPurchaseOrdersDelete}
                onGenerateExcelReport={onGenerateExcelReport}
                onGeneratePdfReport={onGeneratePdfReport}
                loading={bulkActionLoading}
              />
              <NewButton path={ROUTES.PURCHASE_ORDER_CREATE_PATH} />
            </>
          )}
        />
      </Header>

      <Content>
        <TableHeader
          isLoading={isLoading}
          filter={filter}
          filterOpen={filter.handleOpen}
          orderingOpen={ordering.handleOpen}
          ordering={ordering}
        >
          <More tableRef={tableRef} />
        </TableHeader>
        <Table
          isLoading={isLoading}
          columns={actionColumns}
          getRowsFromResults={getRowsFromResults}
          results={results}
          pinnedColumns={['number']}
          count={count}
          checkboxSelection={true}
          primaryKey="guid"
          ordering={ordering}
          onRefetch={onListRefetch}
          setTableRef={setTableRef}
        />
      </Content>
    </Container>
  )
}

PurchaseOrderTable.propTypes = {
  list: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  onPurchaseOrdersDelete: PropTypes.func.isRequired,
  onGenerateExcelReport: PropTypes.func.isRequired,
  onGeneratePdfReport: PropTypes.func.isRequired,
  ordering: PropTypes.object.isRequired,
  onListRefetch: PropTypes.func.isRequired,
  onCloseOrder: PropTypes.func.isRequired,
  onCancelOrder: PropTypes.func.isRequired,
  onDownloadOrder: PropTypes.func.isRequired,
  onEmailSent: PropTypes.func.isRequired,
  onInvoiceGenerate: PropTypes.func.isRequired,
  onMarkPaid: PropTypes.func.isRequired,
  onReturnCreate: PropTypes.func.isRequired,
  bulkActionLoading: PropTypes.bool
}

export default PurchaseOrderTable
