import React from 'react'
import { prop } from 'ramda'
import PropTypes from 'prop-types'

import { unescapeBtoa } from '~/utils'
import StatusGrid, { StatusGridItem, StatusGridLink, StatusGridPopover } from '~/components/StatusGrid'
import { PaymentStatus, PurchaseOrderStatus, ReceivingStatus } from '~/components/Statuses'
import * as ROUTES from '~/constants/routes'

function Statuses ({ detail, isLoading }) {
  const purchaseOrderStatus = prop('orderStatus', detail)
  const receivingStatus = prop('receiptStatus', detail)
  const paymentStatus = prop('paymentStatus', detail)

  const id = prop('id', detail)
  const number = prop('number', detail)

  const orderObject = unescapeBtoa([{ id, number }])
  const objectTypeForReceive = unescapeBtoa([{ value: 'PurchaseOrder', name: 'Purchase order' }])

  const receivingListLink = `${ROUTES.RECEIVE_LIST_PATH}?objects=${orderObject}&objectTypes=${objectTypeForReceive}`

  const receivingPopover = (
    <StatusGridPopover title="Receive">
      <StatusGridLink href={receivingListLink}>
        Receive list
      </StatusGridLink>
    </StatusGridPopover>
  )

  return (
    <StatusGrid>
      <StatusGridItem title="Purchase Order" isLoading={isLoading}>
        {purchaseOrderStatus && <PurchaseOrderStatus value={purchaseOrderStatus} />}
      </StatusGridItem>
      <StatusGridItem title={receivingPopover} isLoading={isLoading}>
        {receivingStatus && <ReceivingStatus value={receivingStatus} />}
      </StatusGridItem>
      <StatusGridItem title="Payment" isLoading={isLoading}>
        {paymentStatus && <PaymentStatus value={paymentStatus} />}
      </StatusGridItem>
    </StatusGrid>
  )
}

Statuses.propTypes = {
  detail: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default Statuses
