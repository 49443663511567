import React from 'react'
import { path } from 'ramda'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useSnackbar } from 'storfox-snackbar'
import { useTranslation } from 'react-i18next'

import { DashboardLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'
import { COMPANY_TABS } from '~/constants/tabs'

import { CompanyUpdateForm } from '../components/CompanyUpdate'
import { useCompanyDetail, useCompanyUpdate } from '../hooks'

function CompanyUpdateContainer () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { id } = useParams()
  const snackbar = useSnackbar()
  const companyDetail = useCompanyDetail(id)
  const companyUpdate = useCompanyUpdate(id)

  const message = t('Successfully updated')
  const handleSubmit = formValues =>
    companyUpdate.update(formValues)
      .then(() => companyDetail.getDetail())
      .then(data => {
        const params = {
          id: path(['result', 'id'], data),
          tab: COMPANY_TABS.GENERAL
        }
        const redirect = generatePath(ROUTES.COMPANY_UPDATE_PATH, params)

        navigate(redirect, { replace: true })
      })
      .then(() => snackbar({ message }))

  const title = companyDetail.isLoading ? 'Companies' : companyDetail.detail.name

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title="Update Company"
      activeNav={NAV.COMPANIES}
      breadcrumbs={breadcrumbs}
    >
      <CompanyUpdateForm
        title={title}
        initialValues={companyDetail.detail}
        isLoading={companyUpdate.isLoading}
        onSubmit={handleSubmit}
      />
    </DashboardLayout>
  )
}

export default CompanyUpdateContainer
