import React, { useCallback } from 'react'
import { useAllSearchParams, useRoute } from 'storfox-route-hooks'
import { always, path, prop, propOr } from 'ramda'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'
import { useFilter, useOrdering } from 'storfox-filter'
import { generatePath, useNavigate } from 'react-router-dom'
import { useRequest } from 'storfox-api-hooks'
import { sprintf } from 'sprintf-js'
import { getDataFromError } from 'storfox-api-hooks/src/api/utils'

import { DashboardLayout } from '~/components/Layouts'
import { DRAFT, NEW_ORDER } from '~/components/Statuses/SaleOrderStatus'
import { useCompany } from '~/components/Profile'
import ErrorLink from '~/components/ErrorLink'
import * as NAV from '~/constants/nav-titles'
import useMessages from '~/hooks/useMessages'
import { useTableSelects } from '~/components/TableValues/hooks'
import { useGoogleEvent } from '~/components/GoogleAnalytics/GoogleAnalytics'
import * as ROUTES from '~/constants/routes'
import * as API from '~/constants/api'
import useDialog from '~/hooks/useDialog'
import SaleOrderWarehouseSetDialog from '~/modules/sales/modules/sale-order/components/SaleOrderWarehousSetDialog'

import {
  useSaleOrderBulkCancel,
  useSaleOrderList,
  useSaleOrderListAllocate,
  useSaleOrderListProcess,
  useSaleOrderReportGenerate,
  useSaleOrdersDelete,
  useSaleOrderBulkWarehouse
} from '../hooks'
import {
  SaleOrderFilterForm,
  saleOrderFilterOptions,
  SaleOrderOrderingForm,
  saleOrderOrderingOptions,
  SaleOrderTable
} from '../components/SaleOrderList'

function SaleOrderListContainer () {
  const route = useRoute()
  const snackbar = useSnackbar()
  const messages = useMessages()
  const navigate = useNavigate()
  const request = useRequest()
  const warehouseDialog = useDialog()

  const { isRetailer } = useCompany()
  const { sendEvent } = useGoogleEvent()
  const { selects, resetTableSelects } = useTableSelects()

  const saleOrderList = useSaleOrderList()
  const saleOrderListAllocate = useSaleOrderListAllocate()
  const saleOrdersDelete = useSaleOrdersDelete()
  const saleOrderReportsGenerate = useSaleOrderReportGenerate()
  const saleOrderBulkCancel = useSaleOrderBulkCancel()
  const saleOrderBulkWarehouse = useSaleOrderBulkWarehouse()

  const saleOrderListProcess = useSaleOrderListProcess()
  const allSearchParams = useAllSearchParams()

  const filter = useFilter(saleOrderFilterOptions)
  const ordering = useOrdering(saleOrderOrderingOptions)

  const status = prop('status', allSearchParams)
  const page = prop('page', allSearchParams)

  const getOrderList = () => {
    if (page === '1') {
      saleOrderList.getList({ status, page: 1 })
      return
    }

    route.changeParams({ ...allSearchParams, page: 1 })
  }

  const handleCancel = () =>
    saleOrderBulkCancel.cancel({ guids: selects })
      .then(() => {
        sendEvent({ eventAction: 'Bulk Cancel Sale Order', eventCategory: 'Sale Order' })
        snackbar({ message: messages.CANCEL_SUCCESS })
      })
      .then(() => resetTableSelects())
      .catch(response => {
        const message = <ErrorLink error={response} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const handleReportGenerate = () =>
    saleOrderReportsGenerate.generate({ guids: selects.join(',') })
      .then(() => {
        sendEvent({ eventAction: 'Report Bulk Generate Sale Order', eventCategory: 'Sale Order' })
        snackbar({ message: messages.NOTIFICATION_WAIT })
      })
      .then(() => resetTableSelects())
      .catch(response => {
        const message = <ErrorLink error={response} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const handleAllocate = () =>
    saleOrderListAllocate.update({ guids: selects })
      .then(() => {
        sendEvent({ eventAction: 'Bulk Allocate Sale Order', eventCategory: 'Sale Order' })
        snackbar({ message: messages.ALLOCATE_SUCCESS })
      })
      .then(() => resetTableSelects())
      .then(() => getOrderList())
      .catch(response => {
        const message = <ErrorLink error={response} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const handleProcess = () =>
    saleOrderListProcess.update({ guids: selects })
      .then(() => {
        sendEvent({ eventAction: 'Bulk Process Sale Order', eventCategory: 'Sale Order' })
        snackbar({ message: messages.PROCESS_SUCCESS })
      })
      .then(() => resetTableSelects())
      .then(() => getOrderList())
      .catch(response => {
        const message = <ErrorLink error={response} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const handleOrdersDelete = () =>
    saleOrdersDelete.delete({ guids: selects })
      .then(() => {
        sendEvent({ eventAction: 'Bulk Delete Sale Order', eventCategory: 'Sale Order' })
        resetTableSelects()
      })
      .then(() => snackbar({ message: messages.DELETE_SUCCESS }))
      .then(() => saleOrderList.getList())
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const handleReturn = guid => {
    const path = generatePath(ROUTES.RETURN_CREATE_PATH, { guid })
    return navigate(path)
  }

  const handleRowCancel = id => {
    const url = sprintf(API.SALE_ORDER_CANCEL, id)
    return request.put(url)
      .then(() => saleOrderList.getList())
      .then(() => {
        sendEvent({ eventAction: 'Cancel Sale Order', eventCategory: 'Sale Order' })
        snackbar({ message: messages.CANCEL_SUCCESS })
      })
      .catch(response => {
        const error = getDataFromError(response)
        return Promise.reject(error)
      })
      .catch(error => snackbar({
        type: ALTER_ERROR,
        message: <ErrorLink error={error} />
      }))
  }

  const handleMarkAsPaid = guid => {
    const url = sprintf(API.SALE_ORDER_MARK_AS_PAID, guid)
    return request.put(url)
      .then(() => {
        sendEvent({ eventAction: 'Mark Paid Sale Order', eventCategory: 'Sale Order' })
        snackbar({ message: messages.UPDATE_SUCCESS })
      })
      .then(() => saleOrderList.getList())
      .catch(response => {
        const error = getDataFromError(response)
        return Promise.reject(error)
      })
      .catch(error => snackbar({
        type: ALTER_ERROR,
        message: <ErrorLink error={error} />
      }))
  }

  const handleInvoiceGenerate = guid => {
    const url = sprintf(API.ORDER_INVOICE_GENERATE, guid)
    return request.post(url)
      .then(() => snackbar({ message: messages.NOTIFICATION_WAIT }))
      .then(() => sendEvent({ eventAction: 'Invoice Generate Sale Order', eventCategory: 'Sale Order' }))
      .catch(error => snackbar({
        type: ALTER_ERROR,
        message: <ErrorLink error={error} />
      }))
  }

  const handleSetWarehouse = useCallback((values) => {
    const warehouseGuid = path(['warehouse', 'guid'], values)
    return saleOrderBulkWarehouse.create({ guids: selects, warehouse: { guid: warehouseGuid } })
      .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
      .then(() => resetTableSelects())
      .then(() => warehouseDialog.handleClose())
      .catch(error => snackbar({
        type: ALTER_ERROR,
        message: <ErrorLink error={error} />
      }))
  }, [resetTableSelects, warehouseDialog, messages, saleOrderBulkWarehouse, selects, snackbar])

  const actions = {
    [DRAFT]: handleAllocate,
    [NEW_ORDER]: handleProcess
  }

  const bulkActionLoading = (
    saleOrderListAllocate.isLoading ||
    saleOrderListProcess.isLoading ||
    saleOrderBulkCancel.isLoading ||
    saleOrderReportsGenerate.isLoading ||
    saleOrdersDelete.isLoading
  )

  const handleSubmit = propOr(always(null), status, actions)

  const breadcrumbs = { title: NAV.SALE_ORDERS }

  return (
    <DashboardLayout
      activeNav={NAV.SALE_ORDERS}
      title={NAV.SALE_ORDERS}
      breadcrumbs={breadcrumbs}
    >
      <SaleOrderFilterForm {...filter} companyDisabled={isRetailer} />
      <SaleOrderOrderingForm {...ordering} />

      <SaleOrderTable
        filter={filter}
        ordering={ordering}
        status={status}
        list={saleOrderList}
        onSubmit={handleSubmit}
        onProcess={handleProcess}
        onAllocate={handleAllocate}
        onReportGenerate={handleReportGenerate}
        onOrdersDelete={handleOrdersDelete}
        handleCancel={handleCancel}
        onListRefetch={saleOrderList.getListByParams}
        onReturn={handleReturn}
        onCancel={handleRowCancel}
        onMarkAsPaid={handleMarkAsPaid}
        onInvoiceGenerate={handleInvoiceGenerate}
        bulkActionLoading={bulkActionLoading}
        onSetWarehouse={warehouseDialog.handleOpen}
      />
      {warehouseDialog.open && (
        <SaleOrderWarehouseSetDialog
          open={warehouseDialog.open}
          onClose={warehouseDialog.handleClose}
          initialValues={{}}
          onSubmit={handleSetWarehouse}
        />
      )}
    </DashboardLayout>
  )
}

export default SaleOrderListContainer
