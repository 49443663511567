import React, { useRef, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import PropTypes from 'prop-types'
import DialogActions from '@mui/material/DialogActions'
import { useTranslation } from 'react-i18next'
import { pathOr, prop, propOr } from 'ramda'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useReactToPrint } from 'react-to-print'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText/ListItemText'

import Divider from '~/components/Divider'
import { Button, MoreButton } from '~/components/Buttons'
import TextOverflow from '~/components/TextOverflow'

import ShipmentViewPrint from './ShipmentViewPrint'
import ShipmentPortraitViewPrint from './ShipmentPortraitViewPrint'

function ShipmentViewDialog ({ open, onClose, shipment }) {
  const { t } = useTranslation()
  const componentRef = useRef(null)
  const landscapeComponentRef = useRef(null)
  const [container, setContainer] = useState(0)

  const lot = pathOr('', ['lot', 'number'], shipment)
  const containers = propOr([], 'containers', shipment)

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  const handleLandscapePrint = useReactToPrint({
    content: () => landscapeComponentRef.current,
  })

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="md"
        fullWidth={true}
      >
        <Box display="flex" p={3}>
          <Box>
            <Typography component="h6" variant="h6">
              <TextOverflow selfTooltip={true}>
                {lot}
              </TextOverflow>
            </Typography>
          </Box>
        </Box>
        <Divider />
        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('Container')}</TableCell>
                  <TableCell>{t('Type')}</TableCell>
                  <TableCell width={100}>{t('Actions')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {containers.map((item, index) => {
                  const number = prop('number', item)
                  const type = prop('type', item)

                  return (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {number}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {type}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <MoreButton>
                          {() => (
                            <List>
                              <ListItem
                                button={true}
                                onClick={() => {
                                  setContainer(index)
                                  handlePrint()
                                }}
                              >
                                <ListItemText primary={t('Print portrait')} />
                              </ListItem>
                              <ListItem
                                button={true}
                                onClick={() => {
                                  setContainer(index)
                                  handleLandscapePrint()
                                }}
                              >
                                <ListItemText primary={t('Print landscape')} />
                              </ListItem>
                            </List>
                          )}
                        </MoreButton>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={onClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <ShipmentViewPrint componentRef={componentRef} shipment={shipment} container={container} />
      <ShipmentPortraitViewPrint componentRef={landscapeComponentRef} shipment={shipment} container={container} />
    </>
  )
}

ShipmentViewDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  shipment: PropTypes.object
}

export default ShipmentViewDialog
