import { sprintf } from 'sprintf-js'
import { useCreate, useDetail, useList, useUpdate } from 'storfox-api-hooks'

import * as API from '~/constants/api'

export const useTemplateList = () => {
  return useList(API.TEMPLATE_LIST)
}

export const useTemplateDetail = (type) => {
  const url = sprintf(API.TEMPLATE_DETAIL, type)
  return useDetail(url)
}

export const useTemplateUpdate = (type) => {
  const url = sprintf(API.TEMPLATE_UPDATE, type)
  return useUpdate(url)
}

export const useTemplatePreview = () => {
  const { create, ...state } = useCreate(API.TEMPLATE_PREVIEW)
  return { ...state, preview: create }
}
