import { useCreate, useDetail, usePostApi, useUpdate } from 'storfox-api-hooks'
import { sprintf } from 'sprintf-js'

import * as API from '~/constants/api'

export const useLogin = () => {
  const { post, data, isLoading } = usePostApi(API.LOGIN)
  return { token: data, login: post, isLoading }
}

export const useVerify = () => {
  const { post, isLoading } = usePostApi(API.AUTH_2_FACTOR_VERIFY)
  return { verify: post, isLoading }
}

export const useResendVerify = () => {
  const { post, isLoading } = usePostApi(API.AUTH_2_FACTOR_RESEND)
  return { resend: post, isLoading }
}

export const useSignUp = () => {
  const { post, data, isLoading } = usePostApi(API.SIGN_UP)
  return { data, signUp: post, isLoading }
}

export const useProfileDetail = () => {
  const url = sprintf(API.ME)
  return useDetail(url)
}

export const useProfileUpdate = id => {
  const url = sprintf(API.USER_UPDATE, id)
  return useUpdate(url)
}

export const useForgotPassword = () => {
  const { post, isLoading } = usePostApi(API.FORGOT_PASSWORD)
  return { isLoading, forgotPassword: post }
}

export const useResetPassword = () => {
  const { post, isLoading } = usePostApi(API.RESET_PASSWORD)
  return { isLoading, resetPassword: post }
}

export const useContractDecide = () => {
  return useUpdate(API.CONTRACT_DECIDE)
}

export const useEmailCheck = () => {
  const { create, ...state } = useCreate(API.CHECK_EMAIL)
  return { ...state, check: create }
}
