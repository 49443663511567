import React from 'react'
import { useSnackbar } from 'storfox-snackbar'
import { generatePath, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { prop } from 'ramda'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import { INTEGRATION_TABS } from '~/constants/tabs'
import useMessages from '~/hooks/useMessages'

import { PostmenCreateSerializer } from '../../serializers'
import { POSTMEN_ENVIRONMENT, POSTMEN_TYPES } from '../../constants'
import PostmenIntegrationCreateForm from '../../components/Postmen/PostmenIntegrationCreateForm'
import { usePostmenCreate } from '../../hooks/'

function PostmenIntegrationCreateContainer () {
  const { t } = useTranslation()

  const postmenCreate = usePostmenCreate()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const messages = useMessages()

  const title = 'New Integration'

  const handleSubmit = formValues =>
    postmenCreate
      .create(PostmenCreateSerializer(formValues))
      .then(({ result }) => {
        const guid = prop('guid', result)
        const type = prop('type', result)
        const params = { guid, type, tab: INTEGRATION_TABS.GENERAL }
        const detailPath = generatePath(ROUTES.INTEGRATION_UPDATE_PATH, params)
        navigate(detailPath)
      })
      .then(() => snackbar({ message: messages.CREATE_SUCCESS }))

  const isLoading = postmenCreate.isLoading

  const initialValues = {
    credential: { type: POSTMEN_TYPES.FEDEX },
    region: POSTMEN_ENVIRONMENT.PRODUCTION
  }

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={t(title)}
      isLoading={isLoading}
      activeNav={NAV.INTEGRATION}
      breadcrumbs={breadcrumbs}
    >
      <PostmenIntegrationCreateForm
        onSubmit={handleSubmit}
        initialValues={initialValues}
        pageTitle={t(title)}
        parentTitle={t(NAV.SETTINGS)}
        isLoading={isLoading}
      />
    </DashboardLayout>
  )
}

export default PostmenIntegrationCreateContainer
