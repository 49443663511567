import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card/Card'
import CardContent from '@mui/material/CardContent'
import { useTranslation } from 'react-i18next'

import StatusField from '~/components/Fields/StatusField'
import TextField from '~/components/Fields/TextField'
import Divider from '~/components/Divider'
import { CardHeader } from '~/components/Cards'

import PostmenTypeField from './PostmenTypeField'

import BillingPaidField from '../../components/Postmen/BillingPaidField'
import TermsOfTradeField from '../../components/Postmen/TermsOfTradeField'
import EnvironmentSelectField from '../Postmen/EnviromentSelectField'

function PostmenUpdate () {
  const { t } = useTranslation()
  return (
    <Grid container={true}>
      <Grid item={true} xs={12}>
        <Box mb={3}>
          <Card>
            <CardHeader title={t('Integration')} />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>

                <Grid item={true} xs={12} lg={12}>
                  <TextField
                    data-cy="name"
                    name="name"
                    label="Name"
                  />
                </Grid>

                <Grid item={true} xs={6} lg={6}>
                  <EnvironmentSelectField
                    data-cy="region"
                    name="credential.region"
                    label="Environment"
                  />
                </Grid>

                <Grid item={true} xs={12} lg={6}>
                  <StatusField />
                </Grid>

                <Grid item={true} xs={12} lg={6}>
                  <PostmenTypeField disabled={true} />
                </Grid>

                <Grid item={true} xs={12}>
                  <TextField
                    data-cy="description"
                    name="description"
                    label="Description"
                    multiline={true}
                    rows="4"
                  />
                </Grid>

                <Grid item={true} lg={12}>
                  <Grid container={true} spacing={3}>
                    <Grid item={true} xs={12} lg={6}>
                      <TextField
                        data-cy="api"
                        name="credential.apiKey"
                        label="Postmen Api Key"
                      />
                    </Grid>
                    <Grid item={true} xs={12} lg={6}>
                      <TextField
                        data-cy="accountId"
                        name="credential.accountId"
                        label="Account Id"
                      />
                    </Grid>
                    <Grid item={true} xs={12} lg={6}>
                      <BillingPaidField
                        name="credential.billingPaidBy"
                      />
                    </Grid>
                    <Grid item={true} xs={12} lg={6}>
                      <TermsOfTradeField
                        name="credential.termsOfTrade"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </Grid>
  )
}

export default PostmenUpdate
