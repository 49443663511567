import React, { useCallback, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { always, compose, not, propEq } from 'ramda'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import {
  DataGridPro,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  gridClasses,
  useGridApiRef
} from '@mui/x-data-grid-pro'
import { isMobile } from 'storfox-tools'
import { useAllSearchParams } from 'storfox-route-hooks'

import { useTableSelects } from '~/components/TableValues/hooks'
import { emptyArray } from '~/constants/empty'

import useShadow from '../hooks/useShadow'
import useDebounce from '../hooks/useDebounce'
import usePagination from '../hooks/usePagination'
import useSorting from '../hooks/useSorting'
import CellComponent from '../components/CellComponent'
import PaginationComponent from '../components/PaginationComponent'
import GridToolbarComponent from '../components/GridToolbarComponent'
import NoRowsOverlayComponent from '../components/NoRowsOverlayComponent'
import RowComponent from '../components/RowComponent'
import useCalculatedHeight from '../hooks/useCalculatedHeight'

const RootStyled = styled(Box, {
  shouldForwardProp: propName => (
    propName !== 'isLeftShadow' &&
    propName !== 'isRightShadow' &&
    propName !== 'isScrolling'
  )
})(({ theme, isLeftShadow, isRightShadow }) => ({
  height: '100%',
  width: '100%',
  '& .ReactVirtualized__Grid': {
    outline: 'none'
  },
  '& .MuiDataGrid-row': {
    position: 'relative'
  },
  '& .MuiDataGrid-root': {
    border: '1px'
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontSize: '15px'
  },
  '& .MuiDataGrid-columnHeaders': {
    borderBottom: '1px solid',
    borderBottomColor: theme.components.StorfoxTable.styleOverrides.row.color + ' !important',
  },
  '& .MuiDataGrid-pinnedColumnHeaders': {
    background: theme.palette.background.paper,
    transition: '0.25s ease box-shadow'
  },
  '& .MuiDataGrid-pinnedColumns': {
    background: theme.palette.background.paper,
    transition: '0.25s ease box-shadow'
  },
  ...(!isLeftShadow && {
    '& .MuiDataGrid-pinnedColumnHeaders--left': {
      boxShadow: 'none'
    },
    '& .MuiDataGrid-pinnedColumns--left': {
      boxShadow: 'none'
    }
  }),
  ...(!isRightShadow && {
    '& .MuiDataGrid-pinnedColumnHeaders--right': {
      boxShadow: 'none'
    },
    '& .MuiDataGrid-pinnedColumns--right': {
      boxShadow: 'none'
    }
  }),
  [`& .${gridClasses.footerContainer}`]: {
    justifyContent: 'flex-end'
  }
}))

const tableRootId = 'table-root-id'

const getRowBufferAndThreshold = numOfRows =>
  numOfRows > 25
    ? { rowBuffer: 10, rowThreshold: 10 }
    : { rowBuffer: numOfRows, rowThreshold: numOfRows }

const defaultOrdering = {
  columnVisibilityModel: {},
  onColumnOrderChange: always(null),
  onColumnResize: always(null),
  onColumnVisibilityUpdate: always(null),
}

function MultiGrid (props) {
  const {
    rows,
    count,
    space,
    columns,
    className,
    rowHeight,
    checkboxSelection,
    primaryKey = 'id',
    height: customHeight,
    ordering = defaultOrdering,
    pinnedColumns = emptyArray,
    autoPageSize = true,
    pagination: isPagination = true,
    onRowClick = always(null),
    ToolbarComponent = GridToolbarComponent,
    isNewPagination,
    hasNextPage,
    hasPrevPage
  } = props

  const { limit } = useAllSearchParams()
  const ref = useRef(null)
  const apiRef = useGridApiRef()

  const initialValue = checkboxSelection ? 50 : 0
  const rowWidth = columns.reduce((acc, column) => acc + column.width, initialValue)

  const { calculatedTableHeight } = useCalculatedHeight(tableRootId, space)
  const sorting = useSorting()
  const pagination = usePagination({ autoPageSize })
  const { handleTableSelectsAdd, selects } = useTableSelects()
  const { isLeftShadow, isRightShadow } = useShadow(apiRef, { rowWidth })

  const sortModel = useMemo(() => sorting.sortModel, [sorting.sortModel])

  const handleColumnOrderChange = (params, event, details) => {
    const allColumns = details.api.getAllColumns()
      .filter(compose(not, propEq('field', GRID_CHECKBOX_SELECTION_COL_DEF.field)))
    ordering.onColumnOrderChange(allColumns)
  }

  const handleColumnVisibilityModelChange = params => {
    ordering.onColumnVisibilityUpdate(params, columns)
  }

  const { getDebouncedFunction } = useDebounce()

  const debounceColumnResize = useCallback(
    getDebouncedFunction(ordering.onColumnResize, 500),
    [ordering.onColumnResize]
  )

  const handleColumnResize = useCallback(({ colDef, width }) => {
    debounceColumnResize({ ...colDef, width })
  }, [debounceColumnResize])

  const heightAuto = customHeight || calculatedTableHeight

  const numOfRows = rows.length
  const { rowBuffer, rowThreshold } = useMemo(() => getRowBufferAndThreshold(numOfRows), [numOfRows])

  const onPageSizeChange = useCallback((size) => {
    if (!limit) {
      pagination.onInitialPageSizeChange(size)
    }
  }, [limit, pagination])

  return (
    <RootStyled
      ref={ref}
      id={tableRootId}
      isLeftShadow={isLeftShadow}
      isRightShadow={isRightShadow}
      style={{ height: heightAuto }}
      className={className}
    >
      <DataGridPro
        apiRef={apiRef}
        getRowId={row => row[primaryKey]}
        rows={rows}
        columns={columns}
        rowCount={count}
        rowHeight={rowHeight}
        onRowClick={onRowClick}

        onColumnResize={handleColumnResize}
        onColumnOrderChange={handleColumnOrderChange}

        columnVisibilityModel={ordering.columnVisibilityModel}
        onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={sorting.onSortModelChange}

        autoPageSize={autoPageSize}
        paginationMode={isPagination ? 'server' : 'client'}
        pagination={isPagination}
        onPageSizeChange={onPageSizeChange}

        checkboxSelection={checkboxSelection}
        disableSelectionOnClick={true}
        selectionModel={selects}
        onSelectionModelChange={handleTableSelectsAdd}
        columnBuffer={10}
        columnThreshold={10}
        rowBuffer={rowBuffer}
        rowThreshold={rowThreshold}
        keepNonExistentRowsSelected={true}

        components={{
          Cell: CellComponent,
          Row: RowComponent,
          Toolbar: ToolbarComponent,
          NoRowsOverlay: NoRowsOverlayComponent,
          Pagination: props => (
            <PaginationComponent
              {...props}
              pageSize={pagination.pageSize}
              isDesktop={pagination.isDesktop}
              onPageSizeChange={pagination.onPageSizeChange}
              onMobilePageChange={pagination.onMobilePageChange}
              onDesktopPageChange={pagination.onDesktopPageChange}
              isNewPagination={isNewPagination}
              hasNextPage={hasNextPage}
              hasPrevPage={hasPrevPage}
            />
          )
        }}
        initialState={{
          pinnedColumns:{
            left: isMobile() ? emptyArray : [GRID_CHECKBOX_SELECTION_COL_DEF.field, ...pinnedColumns],
            right: isMobile() ? emptyArray : ['actions']
          }
        }}
      />
    </RootStyled>
  )
}

MultiGrid.propTypes = {
  className: PropTypes.string,
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  space: PropTypes.bool,
  rowHeight: PropTypes.number,
  autoPageSize: PropTypes.bool,
  checkboxSelection: PropTypes.bool,
  pagination: PropTypes.bool,
  count: PropTypes.number,
  pinnedColumns: PropTypes.array,
  ToolbarComponent: PropTypes.any,
  onRowClick: PropTypes.func,
  primaryKey: PropTypes.oneOf(['guid', 'id']),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ordering: PropTypes.shape({
    columnVisibilityModel: PropTypes.object,
    onColumnOrderChange: PropTypes.func.isRequired,
    onColumnResize: PropTypes.func.isRequired,
    onColumnVisibilityUpdate: PropTypes.func.isRequired,
  }),
  isNewPagination: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  hasPrevPage: PropTypes.bool
}

export default React.memo(MultiGrid)
