import React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'
import { prop, propOr } from 'ramda'

import { DashboardLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'
import ErrorLink from '~/components/ErrorLink'
import useMessages from '~/hooks/useMessages'
import { INTEGRATION_TABS } from '~/constants/tabs'

import { useIntegrationHistory, useMagentoDetail, useMagentoRemove, useMagentoUpdate } from '../../hooks'
import MagentoIntegrationUpdateForm from '../../components/Magento/MagentoIntegrationUpdateForm'

function MagentoIntegrationUpdateContainer () {
  const { guid, tab } = useParams()

  const messages = useMessages()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const magentoDetail = useMagentoDetail(guid)
  const magentoUpdate = useMagentoUpdate(guid)
  const magentoRemove = useMagentoRemove()

  const historyAutoSend = Boolean(guid && tab === INTEGRATION_TABS.LOGS)
  const integrationHistory = useIntegrationHistory(guid, historyAutoSend)

  const title = prop('name', magentoDetail.detail)
  const type = propOr('', 'type', magentoDetail.detail)

  const detailPath = generatePath(ROUTES.INTEGRATION_UPDATE_PATH, { guid, type, tab: INTEGRATION_TABS.GENERAL })

  const handleSubmit = formValues =>
    magentoUpdate.update(formValues)
      .then(() => {
        snackbar({ message: messages.UPDATE_SUCCESS })
        navigate(detailPath)
      })
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const isLoading = (
    magentoDetail.isLoading ||
    magentoUpdate.isLoading
  )

  const handleRemove = () =>
    magentoRemove.delete({ guids: [guid] })
      .then(() => navigate(ROUTES.INTEGRATION_LIST_PATH, { replace: true }))

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      isLoading={magentoDetail.isLoading}
      activeNav={NAV.INTEGRATION}
      breadcrumbs={breadcrumbs}
    >
      <MagentoIntegrationUpdateForm
        isLoading={isLoading}
        pageTitle={title}
        pageTitleLoading={magentoDetail.isLoading}
        onSubmit={handleSubmit}
        onRemove={handleRemove}
        initialValues={magentoDetail.detail}
        history={integrationHistory}
      />
    </DashboardLayout>
  )
}

export default MagentoIntegrationUpdateContainer
