import { useContext } from 'react'

import Context from './context'

export function useScreenOutline () {
  const {
    handleTrigger,
    handleErrorTrigger,
    handleSoundedTrigger,
    handleSoundedErrorTrigger,
    handleColoredOutlineTrigger
  } = useContext(Context)

  return {
    handleTrigger,
    handleErrorTrigger,
    handleSoundedTrigger,
    handleSoundedErrorTrigger,
    handleColoredOutlineTrigger
  }
}
