import React from 'react'
import PropTypes from 'prop-types'
import InputAdornment from '@mui/material/InputAdornment'
import { prop, propOr } from 'ramda'
import { ConverterField } from 'storfox-form-fields'
import { useTranslation } from 'react-i18next'

import { useCompany } from '../Profile'

const ratios = {
  'cm': 1,
  'ft': 0.0328084,
  'in': 0.393701,
}

function DimensionField ({ name, label, ...rest }) {
  const { company } = useCompany()
  const sizeUnit = propOr('cm', 'sizeUnit', company)
  const ratio = prop(sizeUnit, ratios)
  const { t } = useTranslation()

  return (
    <ConverterField
      name={name}
      label={t(label)}
      ratio={ratio}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">{t(sizeUnit)}</InputAdornment>
        )
      }}
      {...rest}
    />
  )
}

DimensionField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
}

export default DimensionField
