import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import isEqual from 'react-fast-compare'
import { filter, includes, map, path, pluck, prop } from 'ramda'
import Grid from '@mui/material/Grid'
import { useFieldArray } from 'react-final-form-arrays'
import { useForm } from 'react-final-form'
import { Button, styled } from '@mui/material'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import DialogTitle from '~/components/DialogTitle'
import { ActionButtons, ScannerDialogContent, ScannerDialogTitle } from '~/components/ScannerDialog'
import MobileFullWidthDialog from '~/components/MobileFullWidthDialog'
import { withForm } from '~/components/Form'
import DuplicateUnitsTable from '~/components/DuplicateUnitsTable'
import UnitPopoverField from '~/components/Fields/UnitPopoverField'
import { useUnitsPopover } from '~/components/DuplicateUnitsTable/hooks'

import BarcodeList from './BarcodeList'

const FormStyled = styled('form')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    height: 'calc(100vh - 130px)'
  },
  [theme.breakpoints.down('sm')]: {
    height: 'calc(100vh - 170px)'
  },
}))

const getItemsWithTotalQuantity = map(item => ({ ...item, totalQuantity: prop('quantity', item) }))

function TransferDialogForm (props) {
  const {
    onClose,
    dialogTitle,
    open,
    isLoading,
    onUnitNumberCheck,
    form
  } = props

  const popover = useUnitsPopover()
  const barcodeRef = useRef({})
  const formInstance = useForm()
  const snackbar = useSnackbar()
  const { handleSubmit } = form

  const title = prop('title', dialogTitle)
  const image = prop('image', dialogTitle)
  const sku = prop('sku', dialogTitle)
  const barcode = prop('barcode', dialogTitle)

  const barcodeItems = useFieldArray('barcodeItems')
  const barcodeItemsValue = barcodeItems.fields.value

  const getItems = filter(({ id }) => !includes(id, pluck('id', barcodeItemsValue)))

  const cleanErrors = () => {
    setError(null)
  }

  const setError = (error) => {
    formInstance.mutators.setFieldData('unitNumber', { error })
  }

  const addUnitNumber = item => {
    const conditionCode = path(['condition', 'code'], item)
    const unitNumber = prop('unitNumber', item)
    const serialNumber = prop('serialNumber', item)
    const batchNumber = prop('batchNumber', item)
    const totalQuantity = prop('quantity', item)
    const id = prop('id', item)
    const value = {
      id,
      unitNumber,
      serialNumber,
      batchNumber,
      conditionCode,
      totalQuantity,
      quantity: 1
    }

    barcodeItems.fields.push(value)
  }

  const handleBarcodeFocus = () => {
    barcodeRef.current.focus()
  }

  const handleItemClick = item => {
    popover.handleClose()
    if (popover.open) {
      addUnitNumber(item)
      handleBarcodeFocus()
    }
  }

  const onChange = unitNumber => {
    cleanErrors()
    return onUnitNumberCheck(unitNumber)
      .then(({ results }) => {
        const items = getItems(results)
        if (items.length === 1) {
          addUnitNumber(items[0])
          handleBarcodeFocus()
        } else if (items.length > 1) {
          const newItems = getItemsWithTotalQuantity(items)
          popover.setItems(newItems)
          popover.handleOpen()
        } else if (results.length === 0) {
          snackbar({ message: 'No units found', type: ALTER_ERROR })
        } else {
          setError('Invalid data.')
        }
      })
      .catch(error => {
        const unitNumber = prop('unitNumber', error)
        setError(unitNumber)
      })
  }

  return (
    <MobileFullWidthDialog open={open}>
      <FormStyled onSubmit={handleSubmit}>
        <DialogTitle>
          <ScannerDialogTitle
            title={title}
            image={image}
            sku={sku}
            barcode={barcode}
          />
        </DialogTitle>

        <ScannerDialogContent>
          <Grid item={true} xs={11}>
            <UnitPopoverField
              barcodeRef={barcodeRef}
              name="unitNumber"
              data-cy="unitNumber"
              open={popover.open}
              label="Scan unit number"
              disabled={isLoading}
              onChange={onChange}
              onClose={popover.handleClose}
              popoverContent={(
                <DuplicateUnitsTable
                  items={popover.items}
                  onClick={handleItemClick}
                />
              )}
            />
          </Grid>
          <Grid item={true} xs={1}>
            <Button
              variant="outlined"
              onClick={() => onChange()}
              fullWidth={true}
            >
              Add item
            </Button>
          </Grid>
          <Grid item={true} xs={12}>
            <BarcodeList />
          </Grid>
        </ScannerDialogContent>

        <ActionButtons
          onClose={onClose}
          disabled={!barcodeItems.fields.value}
        />
      </FormStyled>
    </MobileFullWidthDialog>

  )
}

TransferDialogForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  form: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  dialogTitle: PropTypes.object.isRequired,
  onUnitNumberCheck: PropTypes.func.isRequired
}

export default React.memo(withForm(TransferDialogForm), isEqual)
