import { sprintf } from 'sprintf-js'
import { useAllSearchParams } from 'storfox-route-hooks'
import { useLimitedCountList, useCreate, useDelete, useDetail, useList, useUpdate } from 'storfox-api-hooks'

import { queryToParams } from '~/utils'
import * as API from '~/constants/api'

import { ProductFilterSerializer } from '../serializers/index'

export const useProductList = () => {
  const searchParams = useAllSearchParams()
  const params = queryToParams(ProductFilterSerializer, searchParams)
  const { getList, ...state } = useLimitedCountList(API.PRODUCT_LIST, params)

  return { ...state, getList: rest => getList({ ...rest, ...params }) }
}

export const useProductsDelete = () => {
  return useDelete(API.PRODUCTS_DELETE)
}

export const useProductDetail = id => {
  const url = sprintf(API.PRODUCT_DETAIL, id)
  return useDetail(url)
}

export const useProductHistory = (guid, autoSend) => {
  const searchParams = useAllSearchParams()
  const url = sprintf(API.PRODUCT_HISTORY, guid)

  return useList(url, searchParams, autoSend)
}

export const useProductCreate = () => {
  return useCreate(API.PRODUCT_CREATE)
}

export const useProductUpdate = id => {
  const url = sprintf(API.PRODUCT_UPDATE, id)
  return useUpdate(url)
}

export const useProductImport = () => {
  return useCreate(API.PRODUCT_FILE_UPLOAD)
}

export const useProductsExport = () => {
  const searchParams = useAllSearchParams()
  const params = queryToParams(ProductFilterSerializer, searchParams, 'guid')
  const { create, ...state } = useCreate(API.PRODUCT_EXPORT)

  return { ...state, export: () => create(params) }
}

export const useProductCategoryCreate = () => {
  return useCreate(API.CATEGORY_CREATE)
}

export const useProductBrandCreate = () => {
  return useCreate(API.BRAND_CREATE)
}

export const useProductBulkUpdate = () => {
  return useCreate(API.PRODUCT_FILE_UPDATE_UPLOAD)
}
