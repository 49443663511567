import React from 'react'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import Table from '@mui/material/Table'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { defaultTo, map, pluck, propOr, sum } from 'ramda'

import Money from '~/components/Money'

function InvoiceSummary ({ lineItems }) {
  const { t } = useTranslation()

  const unitsOrdered = pluck('quantity', lineItems)
  const unitsOrderedInt = map(defaultTo(0), unitsOrdered)
  const unitsOrderedTotal = sum(unitsOrderedInt)

  const subTotal = map(
    item => defaultTo(0, item.quantity) * propOr(0, 'price', item),
    lineItems
  )
  const subTotalAll = sum(subTotal)
  return (
    <Table size="small">
      <TableBody>
        <TableRow>
          <TableCell>{t('Line items')}</TableCell>
          <TableCell />
          <TableCell align="right">
            {lineItems.length}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('Units ordered')}</TableCell>
          <TableCell />
          <TableCell align="right">
            {unitsOrderedTotal}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('Subtotal')}</TableCell>
          <TableCell />
          <TableCell align="right">
            <Money value={subTotalAll} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('Shipping charges')}</TableCell>
          <TableCell />
          <TableCell align="right">
            <Money value={0} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <strong>{t('Total')}</strong>
          </TableCell>
          <TableCell />
          <TableCell align="right">
            <Money value={subTotalAll} />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

InvoiceSummary.propTypes = {
  lineItems: PropTypes.array.isRequired
}

InvoiceSummary.defaultProps = {
  lineItems: []
}

export default InvoiceSummary
