import React from 'react'
import PropTypes from 'prop-types'
import { pathOr, prop, propOr } from 'ramda'
import Barcode from 'react-barcode'

import Logo from '~/components/Logo'

import DarkLogo from '../../../../../../assets/images/storfox-logo.svg'

function ShipmentPortraitViewPrint ({ componentRef, shipment, container }) {
  const companyName = pathOr('', ['company', 'name'], shipment)
  const lotNumber = pathOr('', ['lot', 'number'], shipment)
  const bucket = pathOr('', ['bucket', 'name'], shipment)
  const destination = propOr('', 'destination', shipment)
  const containers = propOr([], 'containers', shipment)
  const currentContainer = containers[container]
  const lotVariantItems = propOr([], 'lotVariantItems', currentContainer)
  const containerNumber = propOr('', 'number', currentContainer)
  return (
    <div style={{ position: 'absolute' }}>
      <div ref={componentRef} style={{ padding: '50px 30px' }}>
        <table style={{ border: '1px solid black', borderCollapse: 'collapse', width: '100%' }}>
          <tr>
            <td rowSpan={2} style={{ height: '100px', textAlign: 'center', borderRight: '1px solid black' }}>
              <Logo />
            </td>
            <td style={{ textAlign: 'center', borderRight: '1px solid black', borderBottom: '1px solid black' }}>
              <span style={{ fontWeight: 600 }}>Customer Name:</span>{' '}<span>{companyName}</span>
            </td>
            <td style={{ textAlign: 'center', borderRight: '1px solid black', borderBottom: '1px solid black' }}>
              <span style={{ fontWeight: 600 }}>Lot Number:</span>{' '}<span>{lotNumber}</span>
            </td>
          </tr>
          <tr>
            <td style={{ textAlign: 'center', borderRight: '1px solid black' }}>
              <span style={{ fontWeight: 600 }}>Store Name:</span>{' '}<span>{bucket}</span>
            </td>
            <td style={{ textAlign: 'center', borderRight: '1px solid black' }}>
              <span style={{ fontWeight: 600 }}>Destination:</span>{' '}<span>{destination}</span>
            </td>
          </tr>
          <tr>
            <td style={{ textAlign: 'center', borderRight: '1px solid black', borderTop: '1px solid black' }}>
              <span style={{ fontWeight: 600 }}>Total Items:</span>{' '}<span>{lotVariantItems.length}</span>
            </td>
            <td style={{ textAlign: 'center', borderRight: '1px solid black', borderTop: '1px solid black' }}>
              <Barcode value={containerNumber} width={4} fontSize={20} />
            </td>
            <td style={{ textAlign: 'center', borderRight: '1px solid black', borderTop: '1px solid black' }}>
              <span style={{ fontWeight: 600 }}>Cartoons:</span>{' '}<span>1/{containers.length}</span>
            </td>
          </tr>
          <tr>
            <td
              style={{
                textAlign: 'center',
                borderRight: '1px solid black',
                borderTop: '1px solid black',
                height: '50px'
              }}
            >
              <span style={{ fontWeight: 600 }}>SKU</span>
            </td>
            <td colSpan={2} style={{ textAlign: 'center', borderTop: '1px solid black' }}>
              <span>{lotVariantItems.map(prop('inputBarcode')).join(',')}</span>
            </td>
          </tr>
          <tr>
            <td
              style={{
                textAlign: 'center',
                borderRight: '1px solid black',
                borderTop: '1px solid black',
                height: '50px'
              }}
            >
              <span style={{ textAlign: 'center', width: '150px' }}>Address</span>
            </td>
            <td style={{ textAlign: 'center', borderRight: '1px solid black', borderTop: '1px solid black' }}>
              -
            </td>
            <td rowSpan={2} style={{ textAlign: 'center', borderTop: '1px solid black' }}>
              <div style={{ width: '150px' }}>
                <DarkLogo />
              </div>
            </td>
          </tr>
          <tr>
            <td
              style={{
                textAlign: 'center',
                borderRight: '1px solid black',
                borderTop: '1px solid black',
                height: '50px'
              }}
            >
              <span style={{ textAlign: 'center', width: '150px' }}>Remarks</span>
            </td>
            <td style={{ textAlign: 'center', borderRight: '1px solid black', borderTop: '1px solid black' }}>
              -
            </td>
          </tr>
        </table>
      </div>
    </div>
  )
}

ShipmentPortraitViewPrint.propTypes = {
  componentRef: PropTypes.object,
  shipment: PropTypes.object,
  container: PropTypes.object
}

export default ShipmentPortraitViewPrint
