import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Status, { ERROR, SUCCESS, WARNING } from '../Status/Status'

export const DRAFT = 'draft'
export const NEW_ORDER = 'new'
export const RTO = 'rto'
export const PROCESSING = 'processing'
export const ON_HOLD = 'on_hold'
export const SPLIT = 'split'
export const COMPLETED = 'completed'
export const CANCELLED = 'cancelled'
export const UNFULFILLED = 'unfulfilled'

const STATUS_COLOR = {
  [DRAFT]: SUCCESS,
  [NEW_ORDER]: SUCCESS,
  [RTO]: ERROR,
  [CANCELLED]: ERROR,
  [SPLIT]: SUCCESS,
  [COMPLETED]: SUCCESS,
  [ON_HOLD]: WARNING,
  [PROCESSING]: WARNING,
  [UNFULFILLED]: ERROR,
}
export const STATUS_NAME = {
  [DRAFT]: DRAFT,
  [NEW_ORDER]: NEW_ORDER,
  [RTO]: RTO,
  [CANCELLED]: CANCELLED,
  [SPLIT]: SPLIT,
  [COMPLETED]: COMPLETED,
  [ON_HOLD]: 'On hold',
  [PROCESSING]: PROCESSING,
  [UNFULFILLED]: UNFULFILLED,
}

function SaleOrderStatus ({ value }) {
  const { t } = useTranslation()

  return (
    <Status
      label={t(STATUS_NAME[value])}
      color={STATUS_COLOR[value]}
    />
  )
}

SaleOrderStatus.propTypes = {
  value: PropTypes.string.isRequired
}

export default SaleOrderStatus
