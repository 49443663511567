import { Card } from '@mui/material'
import { path, prop } from 'ramda'
import React from 'react'
import PropTypes from 'prop-types'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { CardHeader } from '~/components/Cards'
import TextLabelLoader from '~/components/TextLabelLoader'

function SupplierAddresses ({ addresses, title }) {
  return (
    <>
      {addresses.map((address, index) => {
        const firstName = prop('firstName', address)
        const lastName = prop('lastName', address)
        const addressName = prop('addressName', address)
        const country = path(['country', 'name'], address)
        const city = prop('city', address)
        const zipcode = prop('zipcode', address)

        return (
          <Box mb={3} key={index}>
            <Card>
              <CardHeader
                title={(
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">{title}</Typography>
                  </Box>
                )}
              />
              <Divider />
              <CardContent>
                <Grid container={true} spacing={3} key={index}>
                  <Grid item={true} xs={12} lg={6}>
                    <TextLabelLoader label="First name">
                      {firstName}
                    </TextLabelLoader>
                  </Grid>

                  <Grid item={true} xs={12} lg={6}>
                    <TextLabelLoader label="Last name">
                      {lastName}
                    </TextLabelLoader>
                  </Grid>

                  <Grid item={true} xs={12}>
                    <TextLabelLoader label="Address">
                      {addressName}
                    </TextLabelLoader>
                  </Grid>

                  <Grid item={true} xs={6}>
                    <TextLabelLoader label="City">
                      {city}
                    </TextLabelLoader>
                  </Grid>

                  <Grid item={true} xs={6}>
                    <TextLabelLoader label="Zipcode">
                      {zipcode}
                    </TextLabelLoader>
                  </Grid>

                  <Grid item={true} xs={12}>
                    <TextLabelLoader label="Country">
                      {country}
                    </TextLabelLoader>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        )
      }
      )}
    </>
  )
}

SupplierAddresses.propTypes = {
  addresses: PropTypes.array.isRequired,
  title: PropTypes.string
}

export default SupplierAddresses
