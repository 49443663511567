import React from 'react'

import SelectField from '~/components/Fields/SelectField'

const OPTIONS = [
  {
    value: '1',
    name: 'Individual customer'
  },
  {
    value: '2',
    name: 'Monthly settlement'
  }
]

function OrderTypeField (props) {
  return (
    <SelectField
      name="configuration.orderType"
      label="Order Type"
      options={OPTIONS}
      {...props}
    />
  )
}

export default OrderTypeField
