import React from 'react'
import { Ordering } from 'storfox-filter'

import { CONTAINER_COLUMNS as tableColumns } from './ContainerTable'

import { CONTAINER_FILTER_NAME as name } from '../../../constants'

export const containerOrderingOptions = {
  name,
  tableColumns
}

function ContainerOrderingForm (props) {
  return (
    <Ordering {...props} />
  )
}

export default ContainerOrderingForm
