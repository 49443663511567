import PropTypes from 'prop-types'
import React from 'react'
import Box from '@mui/material/Box'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import ReactJson from 'react-json-view'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material'

const RootStyled = styled(Box)(({ theme }) => ({
  padding: theme => theme.spacing(1),
  minHeight: '60vh',
  height: '100%'
}))

function IntegrationHistoryDialogTable ({ header, url, jsonHeader, jsonBody, onJsonCopy }) {
  const { t } = useTranslation()
  const isBodyObject = typeof jsonBody === 'object'

  return (
    <RootStyled>
      <Box sx={{ overflowX: 'scroll', width: '100%', height: '100%' }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ borderBottom: 'none' }} colSpan={2}>
                  {header}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{t('Url')}</TableCell>
                <TableCell>{url}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('Header')}</TableCell>
                <TableCell sx={{ width: '100%' }}>
                  <ReactJson
                    displayObjectSize={false}
                    displayDataTypes={false}
                    src={jsonHeader}
                    collapsed={true}
                    name={null}
                    enableClipboard={onJsonCopy}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('Body')}</TableCell>
                <TableCell sx={{ width: '100%' }}>
                  {isBodyObject ? (
                    <ReactJson
                      displayObjectSize={false}
                      displayDataTypes={false}
                      src={jsonBody}
                      name={null}
                      enableClipboard={onJsonCopy}
                    />
                  ) : jsonBody}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </RootStyled>
  )
}

IntegrationHistoryDialogTable.propTypes = {
  jsonBody: PropTypes.any.isRequired,
  jsonHeader: PropTypes.any.isRequired,
  onJsonCopy: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired
}

export default IntegrationHistoryDialogTable
