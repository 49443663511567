import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import { prop, propOr } from 'ramda'
import { ClassNames } from '@emotion/react'

import WarehouseSearchField from '../RuleFields/WarehouseSearchField'
import CompanySearchField from '../RuleFields/CompanySearchField'
import ChannelField from '../RuleFields/ChannelField'
import StatusField from '../RuleFields/OrderFields/StatusField'
import AllocationStatusField from '../RuleFields/OrderFields/AllocationStatusField'
import FulfillmentStatusField from '../RuleFields/OrderFields/FulfillmentStatusField'
import PaymentStatusField from '../RuleFields/OrderFields/PaymentStatusField'
import ShipmentStatusField from '../RuleFields/OrderFields/ShipmentStatusField'
import CountrySearchField from '../RuleFields/CountrySearchField'
import DeliveryMethodField from '../RuleFields/DeliveryMethodField'
import ConditionSearchField from '../RuleFields/ConditionSearchField'
import ListingStatusField from '../RuleFields/ListingFields/ListingStatusField'
import ListingTypeField from '../RuleFields/ListingFields/ListingTypeField'
import CategorySearchField from '../RuleFields/CategorySearchField'
import VariantSearchField from '../RuleFields/VariantSearchField'
import PaymentMethodField from '../RuleFields/PaymentMethodField'
import PurchaseStatusField from '../RuleFields/PurchaseStatusField'
import SupplierSearchField from '../RuleFields/SupplierSearchField'
import CarrierSearchField from '../RuleFields/CarrierSearchField'
import CarrierCitySearchField from '../RuleFields/CarrierCitySearchField'
import * as TYPES from '../valueEditorTypes'

function ValueEditorField (props) {
  const {
    operator,
    value,
    handleOnChange,
    title,
    fieldData,
    className,
    type,
    inputType,
    values,
    variant,
    ...rest
  } = props

  if (operator === 'null' || operator === 'notNull') {
    return null
  }

  const label = prop('label', fieldData)
  const name = prop('name', fieldData)
  const fieldLabel = propOr(label, 'fieldLabel', fieldData)

  const handleChange = (e, value) => {
    return handleOnChange(value)
  }

  const handleTextFieldChange = e => {
    return handleOnChange(e.target.value)
  }

  switch (type) {
    case TYPES.WAREHOUSE:
      return (
        <WarehouseSearchField
          name={name}
          label={fieldLabel}
          onChange={handleChange}
          value={value}
        />
      )

    case TYPES.COMPANY:
      return (
        <CompanySearchField
          name={name}
          label={fieldLabel}
          onChange={handleChange}
          value={value}
        />
      )

    case TYPES.CHANNEL:
      return (
        <ClassNames>
          {({ css }) => (
            <ChannelField
              name={name}
              label={fieldLabel}
              onChange={handleChange}
              autocompleteProps={{
                className: css({
                  display: 'inline-flex',
                  width: '40%'
                })
              }}
              value={value}
            />
          )}
        </ClassNames>
      )

    case TYPES.CITY:
      return (
        <ClassNames>
          {({ css, theme }) => (
            <TextField
              label={title}
              className={className}
              type={inputType || 'text'}
              variant={variant}
              value={value}
              onChange={handleTextFieldChange}
              InputProps={{ classes: { root: css({ background: theme.palette.background.paper }) } }}
              {...rest}
            />
          )}
        </ClassNames>
      )

    case TYPES.DELIVERY_METHOD:
      return (
        <ClassNames>
          {({ css, theme }) => (
            <DeliveryMethodField
              name={name}
              className={className}
              label={label}
              autocompleteProps={{
                className: css({ background: theme.palette.background.paper })
              }}
              onChange={handleChange}
              value={value}
            />
          )}
        </ClassNames>
      )
    case TYPES.ORDER_STATUS:
      return (
        <StatusField
          name={name}
          label={fieldLabel}
          onChange={handleChange}
          value={value}
        />
      )

    case TYPES.ORDER_ALLOCATION_STATUS:
      return (
        <AllocationStatusField
          name={name}
          label={fieldLabel}
          onChange={handleChange}
          value={value}
        />
      )

    case TYPES.ORDER_PAYMENT_STATUS:
      return (
        <PaymentStatusField
          name={name}
          label={fieldLabel}
          onChange={handleChange}
          value={value}
        />
      )

    case TYPES.ORDER_FULFILLMENT_STATUS:
      return (
        <FulfillmentStatusField
          name={name}
          label={fieldLabel}
          onChange={handleChange}
          value={value}
        />
      )

    case TYPES.ORDER_SHIPMENT_STATUS:
      return (
        <ShipmentStatusField
          name={name}
          label={fieldLabel}
          onChange={handleChange}
          value={value}
        />
      )

    case TYPES.LISTING_STATUS:
      return (
        <ListingStatusField
          name={name}
          label={fieldLabel}
          onChange={handleChange}
          value={value}
        />
      )

    case TYPES.LISTING_TYPE:
      return (
        <ListingTypeField
          name={name}
          label={fieldLabel}
          onChange={handleChange}
          value={value}
        />
      )

    case TYPES.COUNTRY:
      return (
        <CountrySearchField
          name={name}
          label={fieldLabel}
          onChange={handleChange}
          value={value}
        />
      )

    case TYPES.CONDITION:
      return (
        <ConditionSearchField
          name={name}
          label={fieldLabel}
          onChange={handleChange}
          value={value}
        />
      )

    case TYPES.CATEGORY:
      return (
        <CategorySearchField
          name={name}
          label={fieldLabel}
          onChange={handleChange}
          value={value}
        />
      )

    case TYPES.VARIANT:
      return (
        <VariantSearchField
          name={name}
          label={fieldLabel}
          onChange={handleChange}
          value={value}
        />
      )

    case TYPES.PAYMENT_METHOD:
      return (
        <PaymentMethodField
          name={name}
          label={fieldLabel}
          onChange={handleChange}
          value={value}
        />
      )

    case TYPES.PURCHASE_STATUS:
      return (
        <PurchaseStatusField
          name={name}
          label={fieldLabel}
          onChange={handleChange}
          value={value}
        />
      )

    case TYPES.SUPPLIER:
      return (
        <SupplierSearchField
          name={name}
          label={fieldLabel}
          onChange={handleChange}
          value={value}
        />
      )

    case TYPES.SHIPMENT_CARRIER:
      return (
        <CarrierSearchField
          name={name}
          label={fieldLabel}
          onChange={handleChange}
          value={value}
        />
      )

    case TYPES.SHIPMENT_CARRIER_CITY:
      return (
        <CarrierCitySearchField
          name={name}
          label={fieldLabel}
          onChange={handleChange}
          value={value}
        />
      )

    case TYPES.SHIPMENT_STATUS:
      return (
        <ShipmentStatusField
          name={name}
          label={fieldLabel}
          onChange={handleChange}
          value={value}
        />
      )

    default:
      return (
        <ClassNames>
          {({ css, theme }) => (
            <TextField
              label={title}
              className={className}
              type={inputType || 'text'}
              variant={variant}
              value={value}
              onChange={handleChange}
              InputProps={{ classes: { root: css({ background: theme.palette.background.paper }) } }}
              {...rest}
            />
          )}
        </ClassNames>
      )
  }
}

ValueEditorField.defaultProps = {
  variant: 'outlined',
  size: 'small'
}

ValueEditorField.propTypes = {
  fieldData: PropTypes.object.isRequired,
  value: PropTypes.any.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  inputType: PropTypes.string,
  className: PropTypes.string,
  operator: PropTypes.string,
  values: PropTypes.array
}

export default ValueEditorField
