import React from 'react'
import PropTypes from 'prop-types'
import { generatePath, useParams } from 'react-router-dom'
import { propOr } from 'ramda'

import PageTitle from '~/components/PageTitle'
import { EditButton } from '~/components/Buttons'
import Container, { Content, Header } from '~/components/Container'
import * as NAV from '~/constants/nav-titles'
import LoaderProvider from '~/components/Loader'
import * as ROUTES from '~/constants/routes'
import { BUNDLE_DETAIL_TABS } from '~/constants/tabs'

import BundleDetailTabs from './BundleDetailTabs'
import BundleDetailGeneral from './BundleDetailGeneral'
import DetailLineItems from './DetailLineItems'

export const BUNDLE_UPDATE_FIELDS = [
  'name',
  'description',
  'images',
  'supplyPrice',
  'maximumRetailPrice',
  'retailPrice',
  'lineItems',
  'sku',
  'barcode',
  'category',
  'brand',
  'tags'
]

function BundleDetail ({ title, isLoading, bundle }) {
  const { guid, tab } = useParams()

  const updatePath = generatePath(ROUTES.BUNDLE_UPDATE_PATH, { guid, tab })

  const retailPrice = propOr(0, 'retailPrice', bundle)

  return (
    <LoaderProvider isLoading={isLoading}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={title}
            parentTitle={NAV.BUNDLES}
            rightButton={(
              <>
                <EditButton path={updatePath} />
              </>
            )}
          />
        </Header>

        <Content>
          <BundleDetailTabs />

          {tab === BUNDLE_DETAIL_TABS.GENERAL && (
            <BundleDetailGeneral
              retailPrice={retailPrice}
              bundle={bundle}
            />
          )}

          {tab === BUNDLE_DETAIL_TABS.COMPOSITION && <DetailLineItems bundle={bundle} />}

        </Content>
      </Container>
    </LoaderProvider>
  )
}

BundleDetail.propTypes = {
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  bundle: PropTypes.object.isRequired
}

export default BundleDetail
