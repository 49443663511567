import React, { useCallback, useMemo } from 'react'
import { pathEq, pathOr, prop, propOr } from 'ramda'
import PropTypes from 'prop-types'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { CheckboxField } from 'storfox-form-fields'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'storfox-snackbar'
import Badge from '@mui/material/Badge'
import { useField } from 'react-final-form'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import { styled } from '@mui/material'

import PositiveNumberField from '~/components/Fields/PositiveNumberField'
import TextField from '~/components/Fields/TextField'
import Avatar from '~/components/Avatar/Avatar'
import VerticalAlignment from '~/components/VerticalAlignment'
import Subtext from '~/components/Subtext'
import useDialog from '~/hooks/useDialog'
import Tooltip from '~/components/HtmlTooltip'
import DeleteIconButton from '~/components/DeleteIconButton'
import TextOverflow from '~/components/TextOverflow'
import useMessages from '~/hooks/useMessages'

import ActiveSerialNumberField from '../ReceiveBarcoding/ActiveSerialNumberField'
import ItemDimensionsEdit from '../ReceiveBarcoding/ItemDimensionsEdit'
import { useReceiveUpdateDimensions } from '../../hooks'
import { ReceiveDimensionsInitSerializer } from '../../serializers'

const TableRowStyled = styled(TableRow)({
  '& td': {
    borderBottom: 'none'
  }
})

function VariantPreview (props) {
  const { variant, fieldName, onBarcodeFocus, onPreviewDelete, editDimension } = props
  const { open, handleOpen, handleClose } = useDialog()
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const messages = useMessages()

  const activeLineItemField = useField('activeLineItem')
  const activeLineItem = activeLineItemField.input.value
  const handleActiveLineItemChange = activeLineItemField.input.onChange

  const lineItemsField = useField('lineItems')
  const lineItems = lineItemsField.input.value

  const name = prop('name', variant)
  const image = prop('defaultImage', variant)
  const barcode = prop('barcode', variant)
  const sku = prop('sku', variant)
  const trackSerialNumbers = prop('trackSerialNumbers', variant)
  const variantGuid = prop('guid', variant)

  const width = prop('width', variant)
  const height = prop('height', variant)
  const length = prop('length', variant)
  const weight = prop('weight', variant)

  const receiveUpdateDimensions = useReceiveUpdateDimensions(variantGuid)

  const handleEnter = event => {
    if (event.key === 'Enter') {
      onBarcodeFocus()
      event.preventDefault()
    }
  }

  const handleSubmit = useCallback((values) => {
    const newDimensions = ReceiveDimensionsInitSerializer(values)
    return receiveUpdateDimensions.update(newDimensions)
      .then(() => {
        handleClose()
        snackbar({ message: messages.UPDATE_SUCCESS })
        const newActiveItem = {
          ...activeLineItem,
          variant: {
            ...variant,
            ...newDimensions
          }
        }
        handleActiveLineItemChange(newActiveItem)
      })
  }, [
    receiveUpdateDimensions,
    handleClose,
    snackbar,
    messages,
    activeLineItem,
    variant,
    handleActiveLineItemChange
  ])

  const initialValues = { width, height, length, weight }

  const isFilled = Boolean(width || height || length || weight)

  const badgeColor = isFilled ? 'primary' : 'error'

  const toScanQuantity = useMemo(() => {
    const itemsAsActive = lineItems.filter(pathEq(['variant', 'guid'], prop('guid', variant)))
    const scannedQuantity = itemsAsActive.reduce((acc, item) => acc + pathOr(0, ['serial', 'quantity'], item), 0)
    const receivedQuantity = itemsAsActive.reduce((acc, item) => acc + propOr(0, 'received', item), 0)
    const orderedQuantity = pathOr(0, [0, 'quantity'], itemsAsActive)
    return orderedQuantity - (scannedQuantity + receivedQuantity)
  }, [lineItems, variant])

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('Image')}</TableCell>
              <TableCell>{t('Product')}</TableCell>
              <TableCell>{t('Barcode')}</TableCell>
              {trackSerialNumbers && (
                <TableCell>{t('Serial number')}</TableCell>
              )}
              <TableCell sx={{ minWidth: 130 }}>
                {t('Batch number')}
              </TableCell>
              <TableCell>{t('Condition code')}</TableCell>
              <TableCell>{t('Scanned')}</TableCell>
              <TableCell>{t('To scan')}</TableCell>
              <TableCell colSpan={2}>{t('Reject')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {variant ? (
              <TableRowStyled>
                <TableCell sx={{ cursor: 'pointer' }}>
                  <Badge
                    variant="dot"
                    color={badgeColor}
                    invisible={!editDimension}
                    onClick={() => editDimension && handleOpen()}
                  >
                    <Avatar src={image} alt="variant-preview" />
                  </Badge>
                </TableCell>
                <TableCell>
                  <Tooltip title={name}>
                    <VerticalAlignment
                      primary={<TextOverflow selfTooltip={true} lines={1}>{name}</TextOverflow>}
                      secondary={<Subtext lines={1}>{sku}</Subtext>}
                    />
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <TextOverflow selfTooltip={true} lines={1}>
                    {barcode}
                  </TextOverflow>
                </TableCell>
                {trackSerialNumbers && (
                  <TableCell>
                    <ActiveSerialNumberField
                      name={`${fieldName}.serialNumber`}
                      data-cy={`${fieldName}.serialNumber`}
                      fullWidth={true}
                      onKeyDown={handleEnter}
                      disabled={!trackSerialNumbers}
                    />
                  </TableCell>
                )}
                <TableCell>
                  <TextField
                    name={`${fieldName}.batchNumber`}
                    data-cy={`${fieldName}.batchNumber`}
                  />
                </TableCell>
                <TableCell>
                  <TextField name={`${fieldName}.conditionCode`} />
                </TableCell>
                <TableCell>
                  <PositiveNumberField
                    name={`${fieldName}.quantity`}
                    onKeyDown={handleEnter}
                    data-cy={`${fieldName}.quantity`}
                    disabled={trackSerialNumbers}
                  />
                </TableCell>
                <TableCell>
                  {toScanQuantity}
                </TableCell>
                <TableCell>
                  <CheckboxField
                    name={`${fieldName}.isRejected`}
                    data-cy={`${fieldName}.isRejected`}
                  />
                </TableCell>
                <TableCell align="right">
                  <DeleteIconButton onClick={() => onPreviewDelete(variant)} />
                </TableCell>
              </TableRowStyled>
            ) : (
              <TableRow>
                <TableCell height={56} colSpan={8}>Empty</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <ItemDimensionsEdit
        onSubmit={handleSubmit}
        initialValues={initialValues}
        variant={variant}
        onClose={handleClose}
        open={open}
        isLoading={receiveUpdateDimensions.isLoading}
      />
    </>
  )
}

VariantPreview.propTypes = {
  fieldName: PropTypes.string.isRequired,
  onBarcodeFocus: PropTypes.func.isRequired,
  variant: PropTypes.object,
  editDimension: PropTypes.bool,
  onPreviewDelete: PropTypes.func.isRequired
}

export default VariantPreview
