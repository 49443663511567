import React, { useRef } from 'react'
import IconButton from '@mui/material/IconButton'
import { prop } from 'ramda'
import { useNavigate } from 'react-router-dom'

import { useLogout, useProfile } from '~/components/Profile'
import * as ROUTES from '~/constants/routes'

import ProfileAvatar from './ProfileAvatar'
import { AppearancePopup, ProfilePopup, usePopup } from './Popups'

import { useTheme } from '../Theme'

function Profile () {
  const anchorRef = useRef(null)

  const navigate = useNavigate()
  const { profile } = useProfile()
  const { logout } = useLogout()
  const { handleThemeChange } = useTheme()

  const profilePopup = usePopup()
  const appearancePopup = usePopup()

  const imagePath = prop('imagePath', profile)

  const handleAppearancePopupClose = () => {
    appearancePopup.handleClose()
    profilePopup.handleOpen()
  }

  const handleClose = () => {
    appearancePopup.handleClose()
    profilePopup.handleClose()
  }

  const handleAppearanceChange = theme => {
    handleThemeChange(theme)
  }

  const handleAppearanceClick = () => {
    profilePopup.handleClose()
    appearancePopup.handleOpen(anchorRef.current)
  }

  const handleLogout = () => {
    logout()
      .then(() => navigate(ROUTES.SIGN_IN))
      .catch(error => {
        navigate(ROUTES.SIGN_IN)

        return Promise.reject(error)
      })
  }

  return (
    <>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        color="inherit"
        size="small"
        ref={anchorRef}
        onClick={profilePopup.handleOpen}
      >
        <ProfileAvatar
          alt="Person"
          src={imagePath}
          variant="circular"
        />
      </IconButton>

      <ProfilePopup
        open={profilePopup.open}
        anchorEl={anchorRef.current}
        profile={profile}
        avatar={imagePath}
        onAppearanceClick={handleAppearanceClick}
        onClose={handleClose}
        onLogout={handleLogout}
      />

      <AppearancePopup
        open={appearancePopup.open}
        anchorEl={anchorRef.current}
        onClose={handleClose}
        onReturn={handleAppearancePopupClose}
        onChange={handleAppearanceChange}
      />
    </>
  )
}

export default Profile
