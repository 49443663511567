import React from 'react'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import PropTypes from 'prop-types'
import InputLabel from '@mui/material/InputLabel'
import { styled } from '@mui/material'

import { BootstrapInput } from './InputBase'

const InputLabelStyled = styled(InputLabel)({
  '&:not(.Mui-focused):not(.MuiFormLabel-filled)': {
    transform: 'translate(14px, 10px) scale(1)',
    paddingLeft: '5px',
    paddingRight: '5px'
  }
})

function QueryBuilderSelectField (props) {
  const {
    variant = 'outlined',
    title,
    value,
    options,
    className,
    handleOnChange,
    ...rest
  } = props

  const handleChange = (e) => handleOnChange(e.target.value)

  return (
    <FormControl
      variant={variant}
      sx={{
        width: 'auto',
        minWidth: 120,
        marginRight: theme => theme.spacing(1)
      }}
      className={className}
    >
      <InputLabelStyled id={`${title}-label`}>
        {title}
      </InputLabelStyled>
      <Select
        value={value}
        onChange={handleChange}
        {...rest}
        input={<BootstrapInput />}
      >
        {options.map((option) => {
          const key = option.id ? `key-${option.id}` : `key-${option.name}`
          return (
            <MenuItem key={key} value={option.name}>
              {option.label}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

QueryBuilderSelectField.propTypes = {
  className: PropTypes.string,
  handleOnChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired
}

export default QueryBuilderSelectField
