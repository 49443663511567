import React from 'react'
import { Filter } from 'storfox-filter'
import Grid from '@mui/material/Grid'
import { prop } from 'ramda'
import { DateTimeField } from 'storfox-form-fields'

import Accordion from '~/components/Accordion'
import { getDateTimeGMTDifference, getDateTimeGMTUser } from '~/utils/date'
import AsyncMultiSelectField from '~/components/Fields/AsyncMultiSelectField'
import * as API from '~/constants/api'
import { arrayToParams, escapeAtob } from '~/utils'
import WarehouseMultiSelectField from '~/components/Fields/WarehouseMultiSelectField'

import { FILTER_NAME as name } from '../../constants/Unit'

export const toParams = values => {
  const datetime = getDateTimeGMTDifference(prop('datetime', values))
  const companies = arrayToParams('companies', values)
  const warehouses = arrayToParams('warehouses', values)

  return {
    datetime,
    companies,
    warehouses
  }
}

export const toValues = params => {
  const datetime = getDateTimeGMTUser(prop('datetime', params))
  const companies = escapeAtob(prop('companies', params))
  const warehouses = escapeAtob(prop('warehouses', params))

  return {
    datetime,
    companies,
    warehouses
  }
}

export const transformer = { toParams, toValues }

export const unitHistoryFilterOptions = {
  name,
  transformer
}

function UnitHistoryFilterForm (props) {
  return (
    <Filter {...props}>
      <Accordion title="General" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <DateTimeField name="datetime" label="Date" />
          </Grid>
          <Grid item={true} xs={12}>
            <AsyncMultiSelectField
              api={API.UNIT_COMPANY_LIST}
              label="Companies"
              name="companies"
            />
          </Grid>
          <Grid item={true} xs={12}>
            <WarehouseMultiSelectField name="warehouses" />
          </Grid>
        </Grid>
      </Accordion>
    </Filter>
  )
}

export default UnitHistoryFilterForm
