import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'storfox-snackbar'
import { useNavigate, useParams } from 'react-router-dom'
import { prop } from 'ramda'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'

import { useDeliveryMethodDetail, useDeliveryMethodUpdate } from '../../hooks/DeliveryMethod'
import DeliveryMethodUpdateForm from '../../components/DeliveryMethod/DeliveryMethodUpdate'

function DeliveryMethodUpdateContainer () {
  const { t } = useTranslation()
  const { guid } = useParams()
  const deliveryMethodDetail = useDeliveryMethodDetail(guid)
  const deliveryMethodUpdate = useDeliveryMethodUpdate(guid)
  const snackbar = useSnackbar()
  const navigate = useNavigate()

  const title = prop('name', deliveryMethodDetail.detail)
  const message = t('Successfully created')

  const handleSubmit = formValues => {
    return deliveryMethodUpdate.update(formValues)
      .then(() => snackbar({ message }))
      .then(() => navigate(ROUTES.DELIVERY_METHOD_LIST_PATH))
  }

  const isLoading = deliveryMethodDetail.isLoading || deliveryMethodUpdate.isLoading

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      isLoading={deliveryMethodDetail.isLoading}
      activeNav={NAV.CONFIGURATION}
      breadcrumbs={breadcrumbs}
    >
      <DeliveryMethodUpdateForm
        pageTitle={title}
        pageTitleLoading={deliveryMethodDetail.isLoading}
        initialValues={deliveryMethodDetail.detail}
        isLoading={isLoading}
        onSubmit={handleSubmit}
      />
    </DashboardLayout>
  )
}

export default DeliveryMethodUpdateContainer
