import React from 'react'
import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel'
import { SwitchField } from 'storfox-form-fields'

import TextField from '~/components/Fields/TextField'
import StatusField from '~/components/Fields/StatusField'
import TagsField from '~/components/Fields/TagsField'

function ShopifyCreate () {
  return (
    <>
      <Grid item={true} xs={12} lg={4}>
        <TextField
          data-cy="name"
          name="name"
          label="Name"
        />
      </Grid>
      <Grid item={true} xs={12} lg={4}>
        <TextField
          data-cy="storeDomain"
          name="credential.storeDomain"
          label="Shop"
          disabled={true}
        />
      </Grid>

      <Grid item={true} xs={12} lg={4}>
        <StatusField />
      </Grid>

      <Grid item={true} xs={12}>
        <TextField
          data-cy="description"
          name="description"
          label="Description"
          multiline={true}
          minRows={4}
        />
      </Grid>

      <Grid item={true} xs={12}>
        <TagsField
          data-cy="tags"
          label="Allowed order tags"
          name="configuration.allowedOrderTags"
        />
      </Grid>

      <Grid item={true} xs={12} sx={{ display: 'flex' }}>
        <FormControl style={{ padding: '0 12px' }}>
          <FormControlLabel
            data-cy="isProductWebhookEnabled"
            control={<SwitchField name="configuration.isProductWebhookEnabled" />}
            label="Enable product webhook"
          />
        </FormControl>
      </Grid>

      <Grid item={true} xs={12} sx={{ display: 'flex' }}>
        <FormControl style={{ padding: '0 12px' }}>
          <FormControlLabel
            data-cy="isStockSyncEnabled"
            control={<SwitchField name="configuration.isStockSyncEnabled" />}
            label="Enable stock sync"
          />
        </FormControl>
      </Grid>

      <Grid item={true} xs={12} sx={{ display: 'flex' }}>
        <FormControl style={{ padding: '0 12px' }}>
          <FormControlLabel
            data-cy="isFulfillmentEnabled"
            control={<SwitchField name="configuration.isFulfillmentEnabled" />}
            label="Enable fulfillment"
          />
        </FormControl>
      </Grid>

      <Grid item={true} xs={12} sx={{ display: 'flex' }}>
        <FormControl style={{ padding: '0 12px' }}>
          <FormControlLabel
            data-cy="isTagPostingEnabled"
            control={<SwitchField name="configuration.isTagPostingEnabled" />}
            label="Enable tag posting"
          />
        </FormControl>
      </Grid>
    </>
  )
}

export default ShopifyCreate
