import React from 'react'
import { Ordering } from 'storfox-filter'

import { BUCKET_COLUMNS as tableColumns } from './LotSheetsTable'

import { FILTER_NAME as name } from '../../constants'

export const lotSheetsOrderingOptions = {
  name,
  tableColumns
}

function LotSheetsOrderingForm (props) {
  return (
    <Ordering {...props} />
  )
}

export default LotSheetsOrderingForm
