import { sprintf } from 'sprintf-js'
import { useAllSearchParams } from 'storfox-route-hooks'
import { useCreate, useDetail, useList, useUpdate, useLimitedCountList } from 'storfox-api-hooks'

import { queryToParams } from '~/utils'
import * as API from '~/constants/api'

import { ShipmentFilterSerializer } from '../../serializers/Shipment'

export const useShipmentList = () => {
  const filterParams = useAllSearchParams()
  const filterValues = queryToParams(ShipmentFilterSerializer, filterParams)

  return useLimitedCountList(API.SHIPMENT_LIST, filterValues)
}

export const useShipmentDetail = id => {
  const url = sprintf(API.SHIPMENT_DETAIL, id)

  return useDetail(url)
}

export const useShipmentGenerateCommercialInvoice = id => {
  const url = sprintf(API.SHIPMENT_GENERATE_COMMERCIAL_INVOICE, id)

  return useDetail(url, null, false)
}
export const useShipmentUpdate = id => {
  const url = sprintf(API.SHIPMENT_UPDATE, id)

  return useUpdate(url)
}

export const useShipmentGatepassGenerate = () => {
  const { create, ...state } = useCreate(API.SHIPMENT_CONSOLIDATED_GATEPASS_GENERATE)

  return { ...state, generate: create }
}

export const useShipmentOutboundGatePassGenerate = guid => {
  const url = sprintf(API.SHIPMENT_OUTBOUND_GATE_PASS, guid)
  const { state, create } = useCreate(url)

  return { ...state, generate: create }
}

export const useShipmentCancelCarrier = guid => {
  const url = sprintf(API.SHIPMENT_CANCEL_CARRIER, guid)
  const { state, create } = useCreate(url)

  return { ...state, cancel: create }
}

export const useShipmentProcess = id => {
  const url = sprintf(API.SHIPMENT_PROCESS, id)
  const { update, ...state } = useUpdate(url)

  return { ...state, process: update }
}

export const useShipmentSaveProcess = id => {
  const url = sprintf(API.SHIPMENT_SAVE_PROCESS, id)
  const { update, ...state } = useUpdate(url)

  return { ...state, process: update }
}

export const useShipmentAwbGet = guid => {
  const url = sprintf(API.SHIPMENT_AWB_DETAIL, guid)

  return useDetail(url, {}, false)
}

export const useShipmentHistory = (guid, autoSend) => {
  const url = sprintf(API.SHIPMENT_HISTORY, guid)
  const searchParams = useAllSearchParams()

  return useList(url, searchParams, autoSend)
}

export const useShipmentReportGenerate = id => {
  const url = sprintf(API.SHIPMENT_REPORT_GENERATE, id)
  const { create, ...state } = useCreate(url)

  return { ...state, generate: create }
}

export const useShipmentRecalculate = guid => {
  const url = sprintf(API.SHIPMENT_RECALCULATE, guid)
  const { create, ...state } = useCreate(url)

  return { ...state, recalculate: create }
}

export const useShipmentSetStatus = guid => {
  const url = sprintf(API.SHIPMENT_SET_STATUS, guid)
  const { create, ...state } = useCreate(url)

  return { ...state, set: create }
}

export const useShipmentContainerUpdate = (guid) => {
  const url = sprintf(API.SHIPMENT_UPDATE_CONTAINER, guid)

  return useUpdate(url)
}

export const useShipmentsExport = () => {
  const { create, ...rest } = useCreate(API.SHIPMENT_EXCEL_EXPORT)

  return { ...rest, export: create }
}

export const useShipmentListSetStatus = () => {
  const { create, ...state } = useCreate(API.SHIPMENT_LIST_SET_STATUS)

  return { ...state, set: create }
}
