import React from 'react'
import { Filter } from 'storfox-filter'
import Grid from '@mui/material/Grid'
import { DateRangeField } from 'storfox-form-fields'
import { prop } from 'ramda'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

import { getSeparatedDateRange } from '~/utils'
import Accordion from '~/components/Accordion'

import CategoryStatusField from './CategoryStatusField'

import { FILTER_NAME as name } from '../../constants'

export const toParams = values => {
  const status = prop('status', values)
  const [updatedAtStart, updatedAtEnd] = getSeparatedDateRange(prop('updatedAt', values))

  return {
    status,
    updatedAtStart,
    updatedAtEnd
  }
}

export const toValues = params => {
  const status = prop('status', params)
  const updatedAt = [prop('updatedAtStart', params), prop('updatedAtEnd', params)]

  return {
    status,
    updatedAt
  }
}
export const transformer = { toParams, toValues }

export const categoryFilterOptions = {
  name,
  transformer
}

function CategoryFilterForm (props) {
  const { t } = useTranslation()
  return (
    <Filter {...props}>
      <Accordion title="General" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <CategoryStatusField />
          </Grid>
        </Grid>
      </Accordion>
      <Accordion title="Date">
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <Typography variant="body2" gutterBottom={true}>
              {t('Updated')}:
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <DateRangeField name="updatedAt" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Accordion>
    </Filter>
  )
}

export default CategoryFilterForm
