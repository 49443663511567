import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'
import { ClassNames } from '@emotion/react'

import TextOverflow from '../TextOverflow'
import OrderProgress from '../OrderProgress'
import Avatar from '../Avatar/Avatar'

const LeftBlockStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  overflow: 'hidden',
  width: '100%',
  paddingRight: 5
}
))

function ScannerDialogTitle ({ title = '', sku, barcode, count, image, quantity, actionButtons }) {
  const showProgress = quantity || count

  return (
    <Grid container={true} spacing={2} sx={{ borderBottom: '1px solid #eee' }}>
      <Grid item={true} lg={6} xs={12}>
        <LeftBlockStyled>
          <div>
            <Avatar src={image} alt={title} />
          </div>
          <Box sx={{ overflow: 'hidden', ml: 1 }}>
            <Typography component="h6" variant="h6">
              <TextOverflow tooltip={<>{title}<br /> {sku}</>}>
                {title}
              </TextOverflow>
            </Typography>
            <Typography
              component="h2"
              variant="body2"
            >
              <TextOverflow>
                {sku}{barcode ? ` / ${barcode}` : ''}
              </TextOverflow>
            </Typography>
          </Box>
        </LeftBlockStyled>
      </Grid>
      {showProgress && (
        <Grid item={true} lg={6} xs={12}>
          <ClassNames>
            {({ css }) => (
              <OrderProgress
                sx={{ width: '100%' }}
                received={count}
                ordered={quantity}
                progressClasses={css({ alignItems: 'center', width: '100%' })}
              />
            )}
          </ClassNames>
        </Grid>
      )}
    </Grid>
  )
}

ScannerDialogTitle.propTypes = {
  title: PropTypes.string,
  sku: PropTypes.string,
  barcode: PropTypes.string,
  count: PropTypes.number,
  quantity: PropTypes.number,
  image: PropTypes.string,
  actionButtons: PropTypes.node,
}

export default React.memo(ScannerDialogTitle)
