import React from 'react'
import PropTypes from 'prop-types'

import TableSplitButton from '~/components/TableSplitButton'

function BulkActionButton (props) {
  const {
    onPublish,
    onUnpublish,
    onPause,
    onReject,
    loading
  } = props

  const options = [
    {
      label: 'Publish',
      'data-cy': 'publishMenu',
      onMenuClick: onPublish
    },
    {
      label: 'Unpublish',
      'data-cy': 'unpublishMenu',
      onMenuClick: onUnpublish
    },
    {
      label: 'Pause',
      'data-cy': 'pauseMenu',
      onMenuClick: onPause
    },
    {
      label: 'Reject',
      'data-cy': 'rejectMenu',
      onMenuClick: onReject
    }
  ]

  return (
    <TableSplitButton
      options={options}
      name="listing"
      loading={loading}
    />
  )
}

BulkActionButton.propTypes = {
  onPublish: PropTypes.func.isRequired,
  onUnpublish: PropTypes.func.isRequired,
  onPause: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  loading: PropTypes.bool
}

export default BulkActionButton
