import React from 'react'

import SelectField from '~/components/Fields/SelectField'

import {
  CANCELLED,
  COMPLETED,
  DRAFT,
  NEW_ORDER,
  ON_HOLD,
  PROCESSING
} from '../Statuses/SaleOrderStatus'

export const OPTIONS = [
  { value: DRAFT, name: 'Draft' },
  { value: NEW_ORDER, name: 'New Order' },
  { value: PROCESSING, name: 'Processing' },
  { value: ON_HOLD, name: 'On Hold' },
  { value: COMPLETED, name: 'Completed' },
  { value: CANCELLED, name: 'Cancelled' }
]

function SaleOrderStatusField (props) {
  return (
    <SelectField
      name="status"
      label="Status"
      options={OPTIONS}
      {...props}
    />
  )
}

export default SaleOrderStatusField
