import { sprintf } from 'sprintf-js'
import { useCreate, useDelete, useDetail, useList, usePostApi, useUpdate } from 'storfox-api-hooks'
import { useAllSearchParams } from 'storfox-route-hooks'

import { queryToParams } from '~/utils'
import * as API from '~/constants/api'

import { ShopifyFilterSerializer } from '../serializers'

export const useShipoxCreate = () => {
  return useCreate(API.SHIPOX_CREATE)
}
export const useShipoxDetail = id => {
  const url = sprintf(API.SHIPOX_DETAIL, id)
  return useDetail(url)
}
export const useShipoxUpdate = id => {
  const url = sprintf(API.SHIPOX_UPDATE, id)
  return useUpdate(url)
}
export const useShipoxRemove = () => {
  return useDelete(API.SHIPOX_DELETE)
}

export const usePostmenCreate = () => {
  return useCreate(API.POSTMEN_CREATE)
}
export const usePostmenDetail = id => {
  const url = sprintf(API.POSTMEN_DETAIL, id)
  return useDetail(url)
}
export const usePostmenUpdate = id => {
  const url = sprintf(API.POSTMEN_UPDATE, id)
  return useUpdate(url)
}
export const usePostmenRemove = () => {
  return useDelete(API.POSTMEN_DELETE)
}

export const useZidCreate = () => {
  return useCreate(API.ZID_CREATE)
}

export const useZidDetail = id => {
  const url = sprintf(API.ZID_DETAIL, id)
  return useDetail(url)
}
export const useZidUpdate = id => {
  const url = sprintf(API.ZID_UPDATE, id)
  return useUpdate(url)
}
export const useZidRemove = () => {
  return useDelete(API.ZID_DELETE)
}

export const usePublicApiCreate = () => {
  return useCreate(API.PUBLIC_API_CREATE)
}

export const useTraxCreate = () => {
  return useCreate(API.TRAX_CREATE)
}
export const useTraxUpdate = id => {
  const url = sprintf(API.TRAX_UPDATE, id)
  return useUpdate(url)
}

export const useTraxDetail = id => {
  const url = sprintf(API.TRAX_DETAIL, id)
  return useDetail(url)
}

export const useTraxRemove = () => {
  return useDelete(API.TRAX_DELETE)
}

export const usePublicApiDetail = id => {
  const url = sprintf(API.PUBLIC_API_DETAIL, id)
  return useDetail(url)
}
export const useIntegrationHistory = (guid, autoSend) => {
  const { select, ...searchParams } = useAllSearchParams()
  const params = queryToParams(ShopifyFilterSerializer, searchParams)
  const url = sprintf(API.INTEGRATION_HISTORY, guid)

  const { getList, ...state } = useList(url, { ...params }, autoSend)
  return { ...state, getList: () => getList(params) }
}
export const usePublicApiUpdate = id => {
  const url = sprintf(API.PUBLIC_API_UPDATE, id)
  return useUpdate(url)
}
export const usePublicApiRemove = () => {
  return useDelete(API.PUBLIC_API_DELETE)
}

export const useMagentoCreate = () => {
  return useCreate(API.MAGENTO_CREATE)
}
export const useMagentoDetail = id => {
  const url = sprintf(API.MAGENTO_DETAIL, id)
  return useDetail(url)
}
export const useMagentoUpdate = id => {
  const url = sprintf(API.MAGENTO_UPDATE, id)
  return useUpdate(url)
}
export const useMagentoRemove = () => {
  return useDelete(API.MAGENTO_DELETE)
}

export const useStoreFrontCreate = () => {
  return useCreate(API.STOREFRONT_CREATE)
}
export const useStoreFrontDetail = id => {
  const url = sprintf(API.STOREFRONT_DETAIL, id)
  return useDetail(url)
}
export const useStoreFrontUpdate = id => {
  const url = sprintf(API.STOREFRONT_UPDATE, id)
  return useUpdate(url)
}
export const useStoreFrontRemove = () => {
  return useDelete(API.STOREFRONT_DELETE)
}

export const useShopifyIntegrationCreate = () => {
  return useCreate(API.SHOPIFY_CREATE)
}

export const useShopifyCallback = () => {
  const params = useAllSearchParams()

  return useCreate(API.INTEGRATION_SHOPIFY_CALLBACK, params, false)
}

export const useShopifyIntegrationDetail = id => {
  const url = sprintf(API.SHOPIFY_DETAIL, id)
  return useDetail(url)
}
export const useShopifyIntegrationRetryOrder = guid => {
  const url = sprintf(API.SHOPIFY_RETRY_ORDER, guid)
  return useCreate(url)
}
export const useShopifyIntegrationUpdate = id => {
  const url = sprintf(API.SHOPIFY_UPDATE, id)
  return useUpdate(url)
}
export const useShopifyIntegrationRemove = () => {
  return useDelete(API.SHOPIFY_DELETE)
}

export const useWooCommerceCreate = () => {
  return useCreate(API.INTEGRATION_WOO_COMMERCE_CREATE)
}
export const useWooCommerceReturn = () => {
  return useCreate(API.INTEGRATION_WOO_COMMERCE_RETURN)
}
export const useWooCommerceDetail = id => {
  const url = sprintf(API.WOOCOMMERCE_DETAIL, id)
  return useDetail(url)
}
export const useWooCommerceUpdate = id => {
  const url = sprintf(API.WOOCOMMERCE_UPDATE, id)
  return useUpdate(url)
}
export const useWooCommerceRetryOrder = guid => {
  const url = sprintf(API.WOOCOMMERCE_RETRY_ORDER, guid)
  return useCreate(url)
}
export const useWooCommerceRemove = () => {
  return useDelete(API.WOOCOMMERCE_DELETE)
}

export const useIntegrationList = () => {
  return useList(API.INTEGRATION_LIST, { limit: 1000 })
}

export const useShopifyInstall = () => {
  const params = useAllSearchParams()

  const { getDetail, ...state } = useDetail(API.SHOPIFY_INSTALL, params, false)

  return { ...state, getDetail: () => getDetail(params) }
}

export const useSallaIntegrationUpdate = id => {
  const url = sprintf(API.SALLA_UPDATE, id)
  return useUpdate(url)
}

export const useSallaUserInfo = () => {
  const params = useAllSearchParams()

  const { getDetail, ...state } = useDetail(API.SALLA_USER_INFO, params, false)

  return { ...state, getDetail: () => getDetail(params) }
}

export const useSignUp = () => {
  const { post, data, isLoading } = usePostApi(API.SIGN_UP)
  return { data, signUp: post, isLoading }
}

export const useEmailCheck = () => {
  const { create, ...state } = useCreate(API.CHECK_EMAIL)
  return { ...state, check: create }
}

export const useSallaIntegrationRemove = () => {
  return useDelete(API.SALLA_DELETE)
}

export const useSallaIntegrationDetail = id => {
  const url = sprintf(API.SALLA_DETAIL, id)
  return useDetail(url)
}

export const useSallaIntegrationCreate = () => {
  return useCreate(API.SALLA_CREATE)
}

export const useSallaIntegrationRetryOrder = guid => {
  const url = sprintf(API.SALLA_RETRY_ORDER, guid)
  return useCreate(url)
}

export const useShipRocketCreate = () => {
  return useCreate(API.SHIPROCKET_CREATE)
}

export const useJTExpressCreate = () => {
  return useCreate(API.JT_EXPRESS_CREATE)
}

export const useJTExpressDetail = guid => {
  const url = sprintf(API.JT_EXPRESS_DETAIL, guid)
  return useDetail(url)
}

export const useJTExpressUpdate = guid => {
  const url = sprintf(API.JT_EXPRESS_UPDATE, guid)
  return useUpdate(url)
}

export const useJTExpressRemove = () => {
  return useDelete(API.JT_EXPRESS_DELETE)
}

export const useShipRocketDetail = guid => {
  const url = sprintf(API.SHIPROCKET_DETAIL, guid)
  return useDetail(url)
}

export const useShipRocketRemove = () => {
  return useDelete(API.SHIPROCKET_DELETE)
}

export const useShipRocketUpdate = guid => {
  const url = sprintf(API.SHIPROCKET_UPDATE, guid)
  return useUpdate(url)
}

export const useShopifyProductsSync = id => {
  const url = sprintf(API.SHOPIFY_PRODUCTS_SYNC, id)
  return useCreate(url)
}

export const useIMileCreate = () => {
  return useCreate(API.IMILE_CREATE)
}
export const useIMileDetail = id => {
  const url = sprintf(API.IMILE_DETAIL, id)
  return useDetail(url)
}
export const useIMileUpdate = id => {
  const url = sprintf(API.IMILE_UPDATE, id)
  return useUpdate(url)
}
export const useIMileRemove = () => {
  return useDelete(API.IMILE_DELETE)
}

export const useEliteCreate = () => {
  return useCreate(API.ELITE_CREATE)
}
export const useEliteDetail = id => {
  const url = sprintf(API.ELITE_DETAIL, id)
  return useDetail(url)
}
export const useEliteUpdate = id => {
  const url = sprintf(API.ELITE_UPDATE, id)
  return useUpdate(url)
}
export const useEliteRemove = () => {
  return useDelete(API.ELITE_DELETE)
}

export const useTCSCreate = () => {
  return useCreate(API.TCS_CREATE)
}
export const useTCSDetail = id => {
  const url = sprintf(API.TCS_DETAIL, id)
  return useDetail(url)
}
export const useTCSUpdate = id => {
  const url = sprintf(API.TCS_UPDATE, id)
  return useUpdate(url)
}
export const useTCSRemove = () => {
  return useDelete(API.TCS_DELETE)
}

export const useCallCourierCreate = () => {
  return useCreate(API.CALL_COURIER_CREATE)
}
export const useCallCourierDetail = id => {
  const url = sprintf(API.CALL_COURIER_DETAIL, id)
  return useDetail(url)
}
export const useCallCourierUpdate = id => {
  const url = sprintf(API.CALL_COURIER_UPDATE, id)
  return useUpdate(url)
}
export const useCallCourierRemove = () => {
  return useDelete(API.CALL_COURIER_DELETE)
}

export const useLCSCreate = () => {
  return useCreate(API.LCS_CREATE)
}
export const useLCSDetail = id => {
  const url = sprintf(API.LCS_DETAIL, id)
  return useDetail(url)
}
export const useLCSUpdate = id => {
  const url = sprintf(API.LCS_UPDATE, id)
  return useUpdate(url)
}
export const useLCSRemove = () => {
  return useDelete(API.LCS_DELETE)
}

export const useRiderCreate = () => {
  return useCreate(API.RIDER_CREATE)
}
export const useRiderDetail = id => {
  const url = sprintf(API.RIDER_DETAIL, id)
  return useDetail(url)
}
export const useRiderUpdate = id => {
  const url = sprintf(API.RIDER_UPDATE, id)
  return useUpdate(url)
}
export const useRiderRemove = () => {
  return useDelete(API.RIDER_DELETE)
}
