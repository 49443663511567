import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'

function VariantOptionsLabel ({ options }) {
  return (
    <Box sx={{
      color: 'text.secondary',
      fontSize: '0.85em'
    }}>
      {options.map((option, index) => (
        <span key={index} title={option.name}>
          {option.option}&nbsp;
        </span>
      ))}
    </Box>
  )
}

VariantOptionsLabel.propTypes = {
  options: PropTypes.array.isRequired
}

export default VariantOptionsLabel
