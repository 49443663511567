import React from 'react'
import PropTypes from 'prop-types'
import { path, prop, propOr } from 'ramda'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'

import { CardHeader } from '~/components/Cards'
import LocationStatus from '~/components/Statuses/LocationStatus'
import TextLabelLoader from '~/components/TextLabelLoader'
import { Dimension, Volume, Weight } from '~/components/Converters'
import Tags from '~/components/Tags'

function LocationDetailGeneral ({ detail }) {
  const warehouse = path(['warehouse', 'name'], detail)
  const area = path(['area', 'name'], detail)
  const zone = path(['zone', 'name'], detail)
  const aisle = prop('aisle', detail)
  const bay = prop('bay', detail)
  const level = prop('level', detail)
  const bin = prop('bin', detail)
  const height = prop('height', detail)
  const width = prop('width', detail)
  const length = prop('length', detail)
  const maxLoad = prop('maxLoad', detail)
  const volume = prop('volume', detail)
  const locationAlias = prop('locationAlias', detail)
  const locationType = prop('locationType', detail)
  const allowedOperations = propOr([], 'allowedOperations', detail)
  const status = prop('status', detail)
  const x = prop('x', detail)
  const y = prop('y', detail)
  const z = prop('z', detail)
  const pickingOrder = prop('pickingOrder', detail)
  const isLocked = prop('isLocked', detail)

  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12} lg={9}>
        <Box mb={3}>
          <Card>
            <CardHeader title="Area/Zone" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12} lg={4}>
                  <TextLabelLoader label="Warehouse">{warehouse}</TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12} lg={4}>
                  <TextLabelLoader label="Area">{area}</TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12} lg={4}>
                  <TextLabelLoader label="Zone">{zone}</TextLabelLoader>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>

        <Box mb={3}>
          <Card>
            <CardHeader title="Shelf/Bin" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12} lg={3}>
                  <TextLabelLoader label="Aisle">{aisle}</TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12} lg={3}>
                  <TextLabelLoader label="Bay/Rack">{bay}</TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12} lg={3}>
                  <TextLabelLoader label="Level/Shelf">{level}</TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12} lg={3}>
                  <TextLabelLoader label="Bin/Position">{bin}</TextLabelLoader>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>

        <Box mb={3}>
          <Card>
            <CardHeader title="Dimension" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>

                <Grid item={true} xs={12} lg={4}>
                  <TextLabelLoader label="Height">
                    <Dimension value={height} />
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12} lg={4}>
                  <TextLabelLoader label="Width">
                    <Dimension value={width} />
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12} lg={4}>
                  <TextLabelLoader label="Length">
                    <Dimension value={length} />
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12} lg={4}>
                  <TextLabelLoader label="Max. Load">
                    <Weight value={maxLoad} />
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12} lg={4}>
                  <TextLabelLoader label="Volume">
                    <Volume value={volume} />
                  </TextLabelLoader>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>

      <Grid item={true} xs={12} lg={3}>
        <Box mb={3}>
          <Card>
            <CardHeader title="Settings" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Location alias">
                    {locationAlias}
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Location type">
                    {locationType}
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Operations">
                    <Tags size="medium" items={allowedOperations} />
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Status">
                    <LocationStatus value={status} />
                  </TextLabelLoader>
                </Grid>
                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Picking order">
                    {pickingOrder}
                  </TextLabelLoader>
                </Grid>
                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Is Locked">
                    {isLocked ? 'True' : 'False'}
                  </TextLabelLoader>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>

        <Box>
          <Card>
            <CardHeader title="Coordinates" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <TextLabelLoader label="X">{x}</TextLabelLoader>
                </Grid>
                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Y">{y}</TextLabelLoader>
                </Grid>
                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Z">{z}</TextLabelLoader>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </Grid>
  )
}

LocationDetailGeneral.propTypes = {
  detail: PropTypes.object.isRequired
}

export default LocationDetailGeneral
