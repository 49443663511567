import React from 'react'
import PropTypes from 'prop-types'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

import Tabs from '~/components/Tabs'
import { SUPPLIER_DETAIL_PATH } from '~/constants/routes'
import { SUPPLIER_DETAIL_TABS } from '~/constants/tabs'

const tabs = [
  { value: SUPPLIER_DETAIL_TABS.GENERAL, label: 'General' },
  { value: SUPPLIER_DETAIL_TABS.ADDRESS, label: 'Address' },
  { value: SUPPLIER_DETAIL_TABS.BANK_ACCOUNT, label: 'Bank account' },
  { value: SUPPLIER_DETAIL_TABS.PURCHASES, label: 'Purchases' },
  { value: SUPPLIER_DETAIL_TABS.HISTORY, label: 'History' },
]

function SupplierDetailTabs ({ value }) {
  const navigate = useNavigate()
  const { guid } = useParams()
  const onChange = (event, tab) => {
    const redirect = generatePath(SUPPLIER_DETAIL_PATH, { guid, tab })

    navigate(redirect, { replace: true })
  }

  return (
    <Tabs
      tabs={tabs}
      value={value}
      onChange={onChange}
    />
  )
}

SupplierDetailTabs.propTypes = {
  value: PropTypes.string.isRequired
}

export default SupplierDetailTabs
