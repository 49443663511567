import { join, map, pick, pipe, prop, propEq, propOr, trim } from 'ramda'
import { DEFAULT_SEARCH_PARAMS } from 'storfox-route-hooks'

import { escapeAtob } from '~/utils'

import { PRODUCT_UPDATE_FIELDS } from '../components/ProductUpdate'
import { PRODUCT_CREATE_FIELDS } from '../components/ProductCreate'

export function ProductUpdateInitialSerializer (detail) {
  return { ...detail, defaultValues: detail }
}

export function ProductCreateSerializer (formValues) {
  const product = pick(PRODUCT_CREATE_FIELDS, formValues)
  const sku = propOr('', 'sku', product)
  const barcode = propOr('', 'barcode', product)

  return { ...product, sku: trim(sku), barcode: trim(barcode) }
}

export function ProductUpdateSerializer (formValues) {
  return pick(
    PRODUCT_UPDATE_FIELDS,
    formValues
  )
}

export function ProductFilterSerializer (params) {
  const defaultParams = pick(DEFAULT_SEARCH_PARAMS, params)

  const types = pipe(
    prop('types'),
    types => types ? escapeAtob(types) : [],
    map(prop('value')),
    join(',')
  )(params)

  const categoryIds = escapeAtob(prop('categories', params))
  const brandIds = escapeAtob(prop('brands', params))
  const companyId = escapeAtob(prop('company', params))

  return {
    ...defaultParams,
    types: types || null,
    categoryIds,
    brandIds,
    companyId
  }
}

const getSelfOptions = (options, variantOptions) => {
  const result = []
  for (let i = 0; i < variantOptions.length; i++) {
    const currentOption = variantOptions[i]
    const foundOption = options.find(item => propEq('label', prop('name', currentOption), item))
    if (!foundOption) {
      result.push(currentOption)
    }
  }
  return result
}

const getVariantOptions = (options, variantOptions) => {
  const result = []
  for (let i = 0; i < options.length; i++) {
    const currentOption = options[i]
    const currentOptionLabel = prop('label', currentOption)
    const foundOption = variantOptions.find(propEq('name', currentOptionLabel))
    if (foundOption) {
      result.push(foundOption)
    } else {
      result.push({ name: currentOptionLabel, option: null })
    }
  }
  return result
}

export const VariantOptionsInitialSerializer = (options, variantOptions) => {
  const selfOptions = getSelfOptions(options, variantOptions)
  const defaultOptions = getVariantOptions(options, variantOptions)
  return { selfOptions, options: defaultOptions }
}

export const VariantOptionsUpdateSerializer = (values) => {
  const options = propOr([], 'options', values)
  const selfOptions = propOr([], 'selfOptions', values)
  const selfOptionsCleared = selfOptions.filter(item => Boolean(item.option) && Boolean(item.name))
  const optionsCleared = options.filter(item => Boolean(item.option))
  return [ ...selfOptionsCleared, ...optionsCleared ]
}
