import React from 'react'

import StorfoxZoneStatusField, { OPTIONS as BASE_OPTIONS } from '~/components/Fields/ZoneStatusField'

const OPTIONS = [{ value: '', name: 'All' }, ...BASE_OPTIONS]

function ZoneStatusField (props) {
  return (
    <StorfoxZoneStatusField
      name="status"
      label="Status"
      options={OPTIONS}
      {...props}
    />
  )
}

export default ZoneStatusField
