import { sprintf } from 'sprintf-js'
import { useCreate, useDelete, useDetail, useLimitedCountList, useUpdate } from 'storfox-api-hooks'
import useAllSearchParams from 'storfox-route-hooks/src/useAllSearchParams'

import { queryToParams } from '~/utils'
import * as API from '~/constants/api'

import { CategoryFilterSerializer } from '../serializers'

export const useCategoryList = () => {
  const searchParams = useAllSearchParams()
  const params = queryToParams(CategoryFilterSerializer, searchParams, 'guid')
  const { getList, ...state } = useLimitedCountList(API.CATEGORY_LIST, params)

  return { ...state, getList: rest => getList({ ...rest, ...params }) }
}

export const useCategoryDetail = id => {
  const url = sprintf(API.CATEGORY_DETAIL, id)
  return useDetail(url)
}

export const useCategoryCreate = () => {
  return useCreate(API.CATEGORY_CREATE)
}

export const useCategoryUpdate = (id) => {
  const url = sprintf(API.CATEGORY_UPDATE, id)
  return useUpdate(url)
}

export const useCategoriesDelete = () => {
  return useDelete(API.CATEGORIES_DELETE)
}
