import React from 'react'
import PropTypes from 'prop-types'
import { is, prop, propOr } from 'ramda'
import { useTranslation } from 'react-i18next'

import BrowserCodeSearchField from './BrowserCodeSearchField'

const getOptionLabel = item => {
  if (is(Object, item)) {
    return `${item.value}`
  }

  return `${item}`
}

const renderOption = (props, item) => {
  if (item) {
    const name = prop('name', item)
    const value = prop('value', item)
    return <li {...props}>{`${name} - ${value}%`}</li>
  }

  return <li {...props} />
}
const getOptionValue = item => {
  if (item) {
    const guid = prop('guid', item)
    const name = prop('name', item)
    const value = prop('value', item)
    return { guid, name, value }
  }

  return null
}
const getOnChangeValue = item => {
  if (is(Object, item)) {
    return propOr(0, 'value', item)
  }
}

function TaxField ({ api, name, ...props }) {
  const { t } = useTranslation()

  return (
    <BrowserCodeSearchField
      data-cy={name}
      name={name}
      placeholder={t('Taxes')}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      getOnChangeValue={getOnChangeValue}
      api={api}
      InputProps={{
        endAdornment: (
          <span>%</span>
        )
      }}
      {...props}
    />
  )
}

TaxField.propTypes = {
  name: PropTypes.string,
  api: PropTypes.string.isRequired
}

export default TaxField
