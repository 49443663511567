import React, { useCallback, useMemo, useState } from 'react'
import { generatePath, useLocation } from 'react-router-dom'
import { useAllSearchParams } from 'storfox-route-hooks'
import { always, path, prop, propOr } from 'ramda'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import { useField } from 'react-final-form'
import { useDeepCompareEffect } from 'storfox-api-hooks'

import Container, { Content, Header } from '~/components/Container'
import PageTitle from '~/components/PageTitle'
import * as NAV from '~/constants/nav-titles'
import { Button, MoreListButton } from '~/components/Buttons'
import { BarcodeScan } from '~/components/Icons'
import { Table, TableHeader } from '~/components/Table'
import * as ROUTES from '~/constants/routes'
import { PACKING_DETAIL_TABS } from '~/constants/tabs'
import OrderProgress from '~/components/OrderProgress'
import TextOverflow from '~/components/TextOverflow'
import UserUpdateSearchField from '~/components/Fields/UserUpdateSearchField'
import { withForm } from '~/components/Form'
import TableDateFormat from '~/components/TableDateFormat/TableDateFormat'
import TableLink from '~/components/Link'
import { getFullName, toDuration } from '~/utils'
import PackingStatus, { CANCELLED } from '~/components/Statuses/PackingStatus'
import { useTableValues } from '~/components/TableValues/hooks'
import { getTableKeyWithQuery } from '~/components/Table/Table'

import BulkActionButton from './BulkActionButton'

export const PACKING_COLUMNS = [
  {
    width: 125,
    headerName: 'SO #',
    field: 'number',
    renderCell: ({ value, row }) => (
      <TableLink to={row.link} disabled={row.status === CANCELLED}>
        {value}
      </TableLink>
    )
  },
  {
    width: 125,
    headerName: 'Reference #',
    field: 'referenceNumber',
    renderCell: ({ value, row }) => (
      <TableLink to={row.link} disabled={row.status === CANCELLED}>
        <TextOverflow selfTooltip={true} lines={1}>
          {value}
        </TextOverflow>
      </TableLink>
    )
  },
  {
    width: 100,
    headerName: 'Line items',
    field: 'lineItems',
    headerAlign: 'center',
    align: 'center'
  },
  {
    width: 100,
    headerName: 'Qty',
    field: 'totalQuantity',
    headerAlign: 'center',
    align: 'center'
  },
  {
    width: 100,
    headerName: 'Picked Qty',
    field: 'pickedQuantity',
    headerAlign: 'center',
    align: 'center'
  },
  {
    width: 200,
    headerName: 'Progress',
    field: 'packingStatus',
    valueFormatter: ({ value }) => `${value.packedCount}-${value.pickedQuantity}`,
    renderCell: ({ value }) => (
      <OrderProgress
        received={value.packedCount}
        ordered={value.pickedQuantity}
      />
    )
  },
  {
    width: 200,
    headerName: 'Status',
    field: 'status',
    renderCell: ({ value }) => (
      <PackingStatus value={value} />
    )
  },
  {
    width: 200,
    headerName: 'Packer',
    field: 'packer',
    valueGetter: ({ row }) => getFullName(row.packer),
    renderCell: ({ row }) => {
      const onPackerChange = propOr(always(null), 'onPackerChange', row.functions)
      return (
        <Box width="100%">
          <UserUpdateSearchField
            name={`${row.fieldName}.packer`}
            params={{ perms: 'packing.complete' }}
            label={null}
            style={{ width:'100%' }}
            onValueChange={inputValue => onPackerChange(row.id, inputValue)}
            ListboxProps={{ 'data-cy': 'userList' }}
          />
        </Box>
      )
    }
  },
  {
    width: 100,
    headerName: 'Packages',
    headerAlign: 'center',
    align: 'center',
    field: 'packages'
  },
  {
    width: 200,
    headerName: 'Method',
    field: 'method'
  },
  {
    width: 200,
    headerName: 'Created at',
    field: 'createdAt',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  },
  {
    width: 200,
    headerName: 'Updated at',
    field: 'updatedAt',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  },
  {
    width: 200,
    headerName: 'Started at',
    field: 'startTime',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  },
  {
    width: 200,
    headerName: 'Completed at',
    field: 'endTime',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  },
  {
    width: 200,
    headerName: 'Service',
    field: 'service'
  },
  {
    width: 200,
    headerName: 'Duration',
    field: 'duration'
  }
]

const getRowsFromResults = (results, functions) => {
  return results.map((item, index) => {
    const id = prop('id', item)
    const number = prop('toObjectNumber', item)
    const lineItems = prop('fromObjectLineItemsCount', item)
    const totalQuantity = prop('totalQuantity', item)
    const pickedQuantity = prop('pickedQuantity', item)
    const packedCount = prop('packedCount', item)
    const currentStatus = prop('status', item)
    const status = prop('status', item)

    const packingStatus = {
      packedCount,
      pickedQuantity
    }

    const packer = prop('packer', item)
    const referenceNumber = prop('toObjectReferenceNumber', item)

    const packages = prop('containersCount', item)
    const method = path(['shippingMethod', 'name'], item)
    const service = path(['service', 'name'], item)

    const duration = toDuration(prop('duration', item))

    const createdAt = prop('createdAt', item)
    const updatedAt = prop('updatedAt', item)
    const startTime = prop('startTime', item)
    const endTime = prop('endTime', item)
    const link = generatePath(ROUTES.PACKING_DETAIL_PATH, {
      id,
      tab: PACKING_DETAIL_TABS.GENERAL
    })

    return {
      id,
      number,
      referenceNumber,
      lineItems,
      totalQuantity,
      pickedQuantity,
      packingStatus,
      packages,
      packer,
      currentStatus,
      method,
      service,
      status,
      createdAt,
      duration,
      updatedAt,
      functions,
      fieldName: `packData[${index}]`,
      link,
      startTime,
      endTime
    }
  })
}

function PackingTableForm (props) {
  const {
    form,
    filter,
    ordering,
    pageTitle,
    packingList,
    onPackerChange,
    onRefetch,
    onContainerScanDialogOpen,
    bulkActionLoading
  } = props
  const [tableRef, setTableRef] = useState(null)
  const location = useLocation()
  const { getTableValues } = useTableValues()
  const params = useAllSearchParams()

  const { handleSubmit } = form

  const columns = prop('filteredColumns', ordering)
  const isLoading = prop('isLoading', packingList)
  const count = prop('count', packingList)
  const results = prop('results', packingList)
  const hasNextPage = prop('hasNextPage', packingList)
  const hasPrevPage = prop('hasPrevPage', packingList)

  const pickingJobsField = useField('packData')
  const pickingJobsFieldChange = pickingJobsField.input.onChange

  const tableKey = getTableKeyWithQuery(location.pathname, params)

  useDeepCompareEffect(() => {
    const tableOldValue = getTableValues(tableKey)
    pickingJobsFieldChange(tableOldValue.results)
  }, [])

  const handlePackerChange = useCallback(onPackerChange, [])

  const functions = useMemo(
    () => ({ onPackerChange: handlePackerChange }),
    [handlePackerChange]
  )

  const handleProcess = () => {
    form.form.submit()
  }

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            parentTitle={NAV.FULFILLMENT}
            pageTitle={pageTitle}
            rightButton={(
              <>
                <BulkActionButton onProcess={handleProcess} loading={bulkActionLoading} />
                <Button
                  variant="contained"
                  data-cy="scanContainer"
                  onClick={onContainerScanDialogOpen}
                  startIcon={<BarcodeScan />}
                >
                  Scan container
                </Button>
              </>
            )}
          />
        </Header>
        <Content>
          <TableHeader
            filter={filter}
            filterOpen={filter.handleOpen}
            orderingOpen={ordering.handleOpen}
            ordering={ordering}
          >
            <MoreListButton tableRef={tableRef} />
          </TableHeader>
          <Table
            getRowsFromResults={getRowsFromResults}
            results={results}
            count={count}
            initialRows={packingList.results}
            rowHeight={70}
            columns={columns}
            isLoading={isLoading}
            args={functions}
            pinnedColumns={['number']}
            checkboxSelection={true}
            pagination={true}
            ordering={ordering}
            onRefetch={onRefetch}
            setTableRef={setTableRef}
            isNewPagination={true}
            hasNextPage={hasNextPage}
            hasPrevPage={hasPrevPage}
          />
        </Content>
      </Container>
    </form>
  )
}

PackingTableForm.propTypes = {
  filter: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  onPackerChange: PropTypes.func.isRequired,
  onContainerScanDialogOpen: PropTypes.func.isRequired,
  pageTitle: PropTypes.string,
  packingList: PropTypes.object,
  onRefetch: PropTypes.func,
  bulkActionLoading: PropTypes.bool
}

export default withForm(PackingTableForm)
