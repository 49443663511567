import React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { map, prop, propOr } from 'ramda'
import { useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import Title from '~/components/Title'
import * as NAV from '~/constants/nav-titles'
import { RECEIVE_TABS } from '~/constants/tabs'
import * as ROUTES from '~/constants/routes'
import useMessages from '~/hooks/useMessages'

import ReceiveReturnDetail from '../components/ReceiveReturn/ReceiveReturnDetail'
import { ReceiveReturnDetailInitSerializer } from '../serializers/returnSerializer'
import {
  useReceiveHistory,
  useReceivePurchaseComplete,
  useReceiveReturnDetail,
  useReceiveReturnUpdate,
  useReturnDetailVariant
} from '../hooks'

const getErrors = data => {
  const lineItems = propOr([], 'lineItems', data)

  return {
    ...data,
    lineItems: map(
      item => ({ ...item, receiveNow: prop('quantity', item) }),
      lineItems
    )
  }
}

function ReceiveReturnContainer () {
  const messages = useMessages()
  const navigate = useNavigate()
  const showSnackbar = useSnackbar()
  const { guid, tab } = useParams()
  const receivingDetail = useReceiveReturnDetail(guid)
  const variantDetail = useReturnDetailVariant(guid)
  const receivingUpdate = useReceiveReturnUpdate(guid)
  const receiveComplete = useReceivePurchaseComplete(guid)

  const historyAutoSend = Boolean(guid && tab === RECEIVE_TABS.HISTORY)
  const receiveHistory = useReceiveHistory(guid, historyAutoSend)

  const handleReceive = () => {
    const path = generatePath(ROUTES.RECEIVE_RT_SCAN_DETAIL_PATH, { guid })
    navigate(path)
  }

  const handleComplete = () =>
    receiveComplete.complete()
      .then(() => showSnackbar({ message: messages.UPDATE_SUCCESS }))
      .then(() => receivingDetail.getDetail())
      .catch(data => Promise.reject(getErrors(data)))

  const isLoading = receivingDetail.isLoading || receivingUpdate.isLoading

  const number = prop('number', receivingDetail.detail)
  const orderNumber = propOr('', 'orderNumber', receivingDetail.detail)
  const status = prop('status', receivingDetail.detail)

  const title = `${number}/${orderNumber}`

  const pageTitle = <Title title="Receive" status={status} number={title} />
  const pageTitleLoading = receivingDetail.isLoading

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      activeNav={NAV.RECEIVE}
      isLoading={isLoading}
      breadcrumbs={breadcrumbs}
    >
      <ReceiveReturnDetail
        pageTitle={pageTitle}
        pageTitleLoading={pageTitleLoading}
        isLoading={isLoading}
        completeDisabled={receiveComplete.isLoading}
        receiveHistory={receiveHistory}
        values={ReceiveReturnDetailInitSerializer(receivingDetail.detail)}
        onComplete={handleComplete}
        handleReceive={handleReceive}
        getVariantDetail={variantDetail.getDetail}
      />
    </DashboardLayout>
  )
}

export default ReceiveReturnContainer
