import React from 'react'
import { MultiSelectSearchField as StorfoxMultiSelectSearchField } from 'storfox-form-fields'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

function MultiSelectSearchField ({ label, ...props }) {
  const { t } = useTranslation()

  return (
    <StorfoxMultiSelectSearchField
      label={t(label)}
      {...props}
    />
  )
}

MultiSelectSearchField.propTypes = {
  label: PropTypes.string.isRequired,
  api: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  params: PropTypes.object,
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func
}

export default MultiSelectSearchField
