import {
  defaultTo,
  filter,
  find,
  fromPairs,
  isEmpty,
  map,
  path,
  pick,
  pipe,
  prop,
  propEq,
  propOr,
  toPairs
} from 'ramda'
import { DEFAULT_SEARCH_PARAMS } from 'storfox-route-hooks'

import { getErrorKey, orderingToSnackCase } from '~/utils'
import { SUPPLIER_UPDATE_TABS } from '~/constants/tabs'

import { TABS_WITH_KEYS } from '../constants'
import { SUPPLIER_UPDATE_FIELDS } from '../components/SupplierUpdate'
import { SUPPLIER_CREATE_FIELDS } from '../components/SupplierCreate'

const getCountryGuid = path(['country', 'guid'])

const defaultValue = [{ isPrimary: true }]

const getNonEmptyAddresses = pipe(
  map(pipe(
    toPairs,
    filter(([, value]) => !isEmpty(value)),
    fromPairs
  )),
  filter(value => !isEmpty(value))
)

export const SupplierInitialSerializer = initialValues => {
  const deliveryAddresses = prop('deliveryAddresses', initialValues) || defaultValue
  const billingAddresses = prop('billingAddresses', initialValues) || defaultValue
  const bankAccounts = prop('bankAccounts', initialValues) || defaultValue

  return {
    ...initialValues,
    bankAccounts: bankAccounts.length ? bankAccounts : defaultValue,
    deliveryAddresses: deliveryAddresses.length ? deliveryAddresses : defaultValue,
    billingAddresses: billingAddresses.length ? billingAddresses : defaultValue
  }
}

export function SupplierCreateSerializer (formValues) {
  const countryGuid = getCountryGuid(formValues)
  const country = countryGuid && { guid: countryGuid }
  const data = { ...formValues, country }

  return pick(SUPPLIER_CREATE_FIELDS, data)
}

const getBankAccounts = pipe(
  propOr([], 'bankAccounts'),
  filter(prop('bankName'))
)

export function SupplierUpdateSerializer (formValues) {
  const countryGuid = getCountryGuid(formValues)
  const country = countryGuid && { guid: countryGuid }

  const data = { ...formValues, country }

  const fields = pick(SUPPLIER_UPDATE_FIELDS, data)
  const billingAddressesArray = prop('billingAddresses', fields)
  const deliveryAddressesArray = prop('deliveryAddresses', fields)

  const billingAddresses = getNonEmptyAddresses(billingAddressesArray)
  const deliveryAddresses = getNonEmptyAddresses(deliveryAddressesArray)

  const bankAccounts = getBankAccounts(fields)

  return { ...fields, billingAddresses, deliveryAddresses, bankAccounts }
}

export function SupplierErrorSerializer (error) {
  return pipe(
    find(propEq('key', getErrorKey(error))),
    prop('tab'),
    defaultTo(SUPPLIER_UPDATE_TABS.GENERAL)
  )(TABS_WITH_KEYS)
}

export function SupplierFilterSerializer (params) {
  const defaultParams = pick(DEFAULT_SEARCH_PARAMS, params)
  const status = prop('status', params)

  return orderingToSnackCase({ ...defaultParams, status })
}
