import React from 'react'
import PropTypes from 'prop-types'
import { map, path, prop, propOr } from 'ramda'
import { useTranslation } from 'react-i18next'
import uuidv4 from 'uuid/v4'

import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import { Table, TableHeader } from '~/components/Table'
import * as NAV from '~/constants/nav-titles'
import CurrencyMoney from '~/components/Money/CurrencyMoney'
import Button from '~/components/Buttons/Button'

export const SALES_MARGIN_COLUMNS = [
  {
    width: 300,
    headerName: 'Variant',
    field: 'variant'
  },
  {
    width: 150,
    headerName: 'SKU',
    field: 'sku'
  },
  {
    width: 150,
    headerName: 'Date',
    field: 'date'
  },
  {
    width: 200,
    headerName: 'Gross Sales',
    field: 'grossSales',
    renderCell: ({ value }) => (
      <CurrencyMoney value={value} />
    )
  },
  {
    width: 200,
    headerName: 'Net Sales',
    field: 'netSales',
    renderCell: ({ value }) => (
      <CurrencyMoney value={value} />
    )
  },
  {
    width: 200,
    headerName: 'Total Tax',
    field: 'totalTax',
    renderCell: ({ value }) => (
      <CurrencyMoney value={value} />
    )
  },
  {
    width: 200,
    headerName: 'Discount',
    field: 'discount',
    renderCell: ({ value }) => (
      <CurrencyMoney value={value} />
    )
  },
  {
    width: 200,
    headerName: 'Sales Cost',
    field: 'salesCost',
    renderCell: ({ value }) => (
      <CurrencyMoney value={value} />
    )
  },
  {
    width: 200,
    headerName: 'Gross Profit',
    field: 'grossProfit',
    renderCell: ({ value }) => (
      <CurrencyMoney value={value} />
    )
  },
  {
    width: 150,
    headerName: 'Profit %',
    field: 'profit'
  },
  {
    width: 150,
    headerName: 'Units Sold',
    field: 'unitsSold'
  },
]

const getRowsFromResults = map(item => {
  const guid = uuidv4()
  const variant = path(['variant', 'name'], item)
  const sku = path(['variant', 'sku'], item)
  const date = prop('date', item)
  const grossSales = prop('grossSales', item)
  const netSales = prop('netSales', item)
  const totalTax = prop('totalTax', item)
  const discount = prop('discount', item)
  const salesCost = prop('salesCost', item)
  const grossProfit = prop('grossProfit', item)
  const profit = propOr(0, 'profit', item)
  const unitsSold = propOr(0, 'unitsSold', item)

  return {
    guid,
    variant,
    sku,
    date,
    grossSales,
    netSales,
    totalTax,
    discount,
    salesCost,
    grossProfit,
    profit,
    unitsSold
  }
})

function SalesMarginTable ({ list, filter, ordering, onListRefetch, onSalesExport }) {
  const { t } = useTranslation()
  const isLoading = prop('isLoading', list)
  const results = prop('results', list)
  const count = prop('count', list)
  const columns = prop('filteredColumns', ordering)

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={t(NAV.SALES_MARGIN_REPORT)}
          parentTitle={t(NAV.REPORTS)}
          rightButton={(
            <Button
              variant="contained"
              data-cy="create"
              type="button"
              onClick={onSalesExport}
            >
              {t('Generate Report')}
            </Button>
          )}
        />
      </Header>

      <Content>
        <TableHeader
          filter={filter}
          filterOpen={filter.handleOpen}
          orderingOpen={ordering.handleOpen}
          ordering={ordering}
        />
        <Table
          isLoading={isLoading}
          columns={columns}
          getRowsFromResults={getRowsFromResults}
          results={results}
          count={count}
          pinnedColumns={['variant']}
          primaryKey="guid"
          ordering={ordering}
          onRefetch={onListRefetch}
        />
      </Content>
    </Container>
  )
}

SalesMarginTable.propTypes = {
  list: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  onSalesExport: PropTypes.func.isRequired,
  onListRefetch: PropTypes.func.isRequired
}

export default SalesMarginTable
