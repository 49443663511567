import React from 'react'
import Grid from '@mui/material/Grid'
import PropTypes from 'prop-types'

import MoneyField from '~/components/Fields/MoneyField'
import WeightField from '~/components/Fields/WeightField'

function StoragePerLocation ({ name }) {
  return (
    <Grid container={true} spacing={3}>

      <Grid item={true} xs={12} lg={4}>
        <WeightField
          label="Maximum weight"
          name={`${name}.maxWeight`}
        />
      </Grid>

      <Grid item={true} xs={12} lg={4}>
        <MoneyField
          label="Price"
          name={`${name}.price`}
        />
      </Grid>

      <Grid item={true} xs={12} lg={4}>
        <MoneyField
          label={`Additional price`}
          name={`${name}.additionalPrice`}
        />
      </Grid>

    </Grid>
  )
}

StoragePerLocation.propTypes = {
  name: PropTypes.string.isRequired
}

export default StoragePerLocation
