import React from 'react'
import { prop, propOr } from 'ramda'
import { generatePath } from 'react-router-dom'
import PropTypes from 'prop-types'
import Link from '@mui/material/Link'

import { unescapeBtoa } from '~/utils'
import * as ROUTES from '~/constants/routes'
import { useCompany } from '~/components/Profile'

function SaleOrderCompanyInfo ({ owner }) {
  const { hasBelongCompany } = useCompany()

  const ownerName = propOr('', 'name', owner)
  const ownerId = prop('id', owner)
  const isOwner = hasBelongCompany(ownerId)
  const ownerLink = generatePath(`${ROUTES.CONTRACT_LIST_PATH}/?company=${unescapeBtoa(owner)}`)
  const url = isOwner ? null : ownerLink

  return (
    <Link
      target="_blank"
      data-cy="customerName"
      href={url}
    >
      {ownerName}
    </Link>
  )
}

SaleOrderCompanyInfo.propTypes = {
  owner: PropTypes.object.isRequired
}
export default SaleOrderCompanyInfo
