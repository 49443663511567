import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import ReturnListContainer from './containers/ReturnListContainer'
import ReturnCreateContainer from './containers/ReturnCreateContainer'
import ReturnDetailContainer from './containers/ReturnDetailContainer'
import ReturnUpdateContainer from './containers/ReturnUpdateContainer'

export default () => {
  return [
    {
      layout: AuthLayout,
      path: ROUTES.SUPPLIER_RETURN_LIST_PATH,
      component: ReturnListContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.SUPPLIER_RETURN_CREATE_PATH,
      component: ReturnCreateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.SUPPLIER_RETURN_DETAIL_PATH,
      component: ReturnDetailContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.SUPPLIER_RETURN_UPDATE_PATH,
      component: ReturnUpdateContainer
    }
  ]
}
