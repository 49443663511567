import React from 'react'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { styled } from '@mui/material'
import Chip from '@mui/material/Chip'

const ChipStyled = styled(Chip, {
  shouldForwardProp: propName => propName !== 'isActive'
})({
  cursor: 'pointer',
  borderRadius: '5px',
  marginLeft: '5px',
  height: '20px',
  transition: '0.3s !important'
})

function LabelWithChip ({ label, activeTab, value, chipLabel }) {
  const { t } = useTranslation()
  return (
    <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
      {t(label)}
      <ChipStyled
        color={activeTab === value ? 'primary' : 'default'}
        label={chipLabel}
        size="small"
      />
    </Box>
  )
}

LabelWithChip.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  activeTab: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  chipLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired
}

export default LabelWithChip
