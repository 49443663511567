import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'

import { CODE_TYPE } from '~/constants/barcode'

import BarcodeQuantity from './Barcode/BarcodePrint'
import QrCodeGenerator from './QrCode/QrCodePrint'
import QrCodeItems from './QrCode/QrCodeItems'

import { getFontSize } from '../../utils'

function CodeVisualized ({ type, items, componentRef, quantity, fontSize, barcodeType }) {
  const fontSizeNumber = getFontSize(fontSize)

  return (
    <>
      {type === CODE_TYPE.BARCODE
        ? (
          <BarcodeQuantity
            items={items}
            ref={componentRef}
            fontSize={fontSizeNumber}
            quantity={quantity}
            barcodeType={barcodeType}
          />
        )
        : (
          <div>
            <Box display="none">
              <QrCodeGenerator
                items={items}
                fontSize={fontSizeNumber}
                ref={componentRef}
                quantity={quantity}
              />
            </Box>
            <QrCodeItems
              items={items}
              fontSize={fontSizeNumber}
              quantity={quantity}
            />
          </div>
        )
      }
    </>
  )
}

CodeVisualized.propTypes = {
  componentRef: PropTypes.any,
  items: PropTypes.array.isRequired,
  quantity: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  fontSize: PropTypes.string.isRequired,
  barcodeType: PropTypes.string.isRequired
}

export default CodeVisualized
