import { prop, propOr } from 'ramda'
import { DateTime } from 'luxon'

import { escapeAtob } from '~/utils'
import { DATE_FORMAT_ISO_8601 } from '~/constants/dateFormat'

import { getDateRangeFromParams } from '../utils'

const getRangeParamsPurchase = params => {
  const currentDate = DateTime.now()
  const beforeDate = DateTime.now().minus({ days: 12 })

  const dateRangeParam = prop('dateRange', params)
  const dateRange = getDateRangeFromParams(dateRangeParam)

  const createdAtStart = propOr(beforeDate, 0, dateRange).toFormat(DATE_FORMAT_ISO_8601)
  const createdAtEnd = propOr(currentDate, 1, dateRange).toFormat(DATE_FORMAT_ISO_8601)

  return { createdAtStart, createdAtEnd }
}

const getRangeParams = params => {
  const currentDate = DateTime.now()
  const beforeDate = DateTime.now().minus({ days: 12 })

  const dateRangeParam = prop('dateRange', params)
  const dateRange = getDateRangeFromParams(dateRangeParam)

  const startDate = propOr(beforeDate, 0, dateRange).toFormat(DATE_FORMAT_ISO_8601)
  const endDate = propOr(currentDate, 1, dateRange).toFormat(DATE_FORMAT_ISO_8601)

  return { startDate, endDate }
}

export function OverviewFilterSerializer (params) {
  const companyId = prop('companyId', params)
  const paramsCompany = escapeAtob(prop('company', params))
  const paramsCompanyId = prop('id', paramsCompany)
  const warehouseId = escapeAtob(prop('warehouse', params))
  const dateRange = getRangeParams(params)

  return { ...dateRange, warehouseId, companyId: paramsCompanyId || companyId }
}

export function OverviewPurchaseFilterSerializer (params) {
  const companyId = prop('companyId', params)
  const paramsCompany = escapeAtob(prop('company', params))
  const paramsCompanyId = prop('id', paramsCompany)
  const warehouseId = escapeAtob(prop('warehouse', params))
  const dateRange = getRangeParamsPurchase(params)

  return { ...dateRange, warehouseId, companyId: paramsCompanyId || companyId }
}
