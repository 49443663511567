import React from 'react'
import { prop } from 'ramda'
import { generatePath } from 'react-router-dom'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'

import * as ROUTES from '~/constants/routes'
import { CUSTOMER_DETAIL_TABS } from '~/constants/tabs'

import CustomerAddresses from './CustomerAddresses'

import CustomerInfoRow from '../../CustomerInfoRow'

function SaleOrderCustomerDetail ({ customer, billingAddress, deliveryAddress }) {
  const customerGuid = prop('guid', customer)
  const params = { guid: customerGuid, tab: CUSTOMER_DETAIL_TABS.GENERAL }
  const link = customerGuid && generatePath(ROUTES.CUSTOMER_DETAIL_PATH, params)

  return (
    <>
      <CustomerInfoRow
        customer={customer}
        link={link}
      />
      <Box mt={2}>
        <CustomerAddresses
          billingAddress={billingAddress}
          deliveryAddress={deliveryAddress}
        />
      </Box>
    </>
  )
}

SaleOrderCustomerDetail.propTypes = {
  customer: PropTypes.object.isRequired,
  billingAddress: PropTypes.object.isRequired,
  deliveryAddress: PropTypes.object.isRequired
}

export default SaleOrderCustomerDetail
