import React from 'react'
import { useFilter, useOrdering } from 'storfox-filter'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'

import {
  ContractFilterForm,
  contractFilterOptions,
  ContractOrderingForm,
  contractOrderingOptions,
  ContractTable
} from '../../components/Contract/ContractList'
import { useContractList } from '../../hooks/Contract'

const ContractCreateContainer = () => {
  const contractList = useContractList()

  const filter = useFilter(contractFilterOptions)
  const ordering = useOrdering(contractOrderingOptions)

  const breadcrumbs = { title: 'Contract' }

  return (
    <DashboardLayout
      title="Contract"
      activeNav={NAV.CONFIGURATION}
      breadcrumbs={breadcrumbs}
    >
      <ContractFilterForm {...filter} />
      <ContractOrderingForm {...ordering} />

      <ContractTable
        filter={filter}
        ordering={ordering}
        list={contractList}
        onListRefetch={contractList.getListByParams}
      />

    </DashboardLayout>
  )
}

export default ContractCreateContainer
