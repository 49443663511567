import PropTypes from 'prop-types'
import React from 'react'

import { Loader } from '~/components/Loader'
import Text from '~/components/Text/Text'

function TextLoader (props) {
  return (
    <Loader>
      {isLoading => (
        <Text isLoading={isLoading} {...props} />
      )}
    </Loader>
  )
}

TextLoader.propTypes = {
  children: PropTypes.any
}

export default TextLoader
