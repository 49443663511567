import React from 'react'
import { Ordering } from 'storfox-filter'

import { ADJUSTMENT_REASON_COLUMNS as tableColumns } from './AdjustmentReasonTable'

import { FILTER_NAME as name } from '../../../constants/AdjustmentReason'

export const adjustmentReasonOrderingOptions = {
  name,
  tableColumns
}

function AdjustmentReasonOrderingForm (props) {
  return (
    <Ordering {...props} />
  )
}

export default AdjustmentReasonOrderingForm
