import React from 'react'
import PropTypes from 'prop-types'
import { prop } from 'ramda'
import Box from '@mui/material/Box'

import { getCurrencySymbol } from '~/utils'
import { useCompany } from '~/components/Profile'

const toSummarize = (value) => {
  if (value >= 1e3) {
    const units = ['k', 'M', 'B']

    const unit = Math.floor(((value).toFixed(0).length - 1) / 3) * 3
    const num = (value / ('1e' + unit)).toFixed(2)
    const unitname = units[Math.floor(unit / 3) - 1]

    return num + unitname
  }

  return value.toLocaleString()
}
function SummarizeMoney ({ value }) {
  const { company } = useCompany()
  const currency = prop('currency', company)
  const symbol = getCurrencySymbol(currency)

  return (
    <Box component="span" sx={{ whiteSpace: 'nowrap' }}>
      {`${symbol} ${value ? toSummarize(value) : '0.00'}`}
    </Box>
  )
}

SummarizeMoney.propTypes = {
  value: PropTypes.number
}

export default SummarizeMoney
