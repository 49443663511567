import React from 'react'
import { useParams } from 'react-router-dom'
import { path } from 'ramda'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import { WAREHOUSE_TABS } from '~/constants/tabs'

import WarehouseDetail from '../components/WarehouseDetail/SharedWarehouseDetail/WarehouseDetail'
import { useWarehouseDetail } from '../hooks'

function WarehouseDetailContainer () {
  const { guid } = useParams()

  const retailerWarehouseDetail = useWarehouseDetail(guid)

  const name = path(['detail', 'name'], retailerWarehouseDetail)
  const title = retailerWarehouseDetail.isLoading ? 'Warehouses' : name

  const breadcrumbs = {
    title,
    params:[{ guid, tab: WAREHOUSE_TABS.GENERAL, title }]
  }

  return (
    <DashboardLayout
      title={NAV.WAREHOUSE}
      activeNav={NAV.WAREHOUSE}
      breadcrumbs={breadcrumbs}
      isLoading={retailerWarehouseDetail.isLoading}
    >
      <WarehouseDetail
        pageTitle={title}
        pageTitleLoading={retailerWarehouseDetail.isLoading}
        warehouseDetail={retailerWarehouseDetail.detail}
        isLoading={retailerWarehouseDetail.isLoading}
      />
    </DashboardLayout>
  )
}

export default WarehouseDetailContainer
