import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import PropTypes from 'prop-types'

import { CardHeader } from '~/components/Cards'
import SubmitBlockedTextField from '~/components/Fields/SubmitBlockedTextField'
import TagsField from '~/components/Fields/TagsField'
import TextField from '~/components/Fields/TextField'
import * as API from '~/constants/api'
import TextLabelLoader from '~/components/TextLabelLoader'
import Money from '~/components/Money'

import BundleImageUploadField from './Fields/BundleImageUploadField'
import SkuField from './Fields/SkuField'
import BundleSearchCreateField from './Fields/BundleSearchCreateField'

function BundleGeneral ({ onCategoryCreate, retailPrice, categoryLoading, onBrandCreate, brandLoading }) {
  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12} lg={8}>
        <Box mb={3}>
          <Card>
            <CardHeader title="Details" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <TextField
                    data-cy="name"
                    name="name"
                    label="Name"
                    required={true}
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <TextField
                    data-cy="description"
                    name="description"
                    label="Description"
                    multiline={true}
                    minRows={4}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>

        <Box mb={3}>
          <Card>
            <CardHeader title="Images" />
            <Divider />
            <CardContent>
              <BundleImageUploadField accept="image/png, image/jpeg" />
            </CardContent>
          </Card>
        </Box>

      </Grid>

      <Grid item={true} xs={12} lg={4}>
        <Box mb={3}>
          <Card>
            <CardHeader title="Tracking" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <SkuField required={true} />
                </Grid>

                <Grid item={true} xs={12}>
                  <SubmitBlockedTextField
                    data-cy="barcode"
                    name="barcode"
                    label="Barcode"
                    placeholder="UPC/EAN/GTIN/ISBN/ASIN"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>

        {retailPrice && (
          <Box mb={3}>
            <Card>
              <CardHeader title="Pricing" />
              <Divider />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={12}>
                    <TextLabelLoader label="Retail price">
                      <Money value={retailPrice} />
                    </TextLabelLoader>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        )}

        <Box mb={3}>
          <Card>
            <CardHeader title="Organization" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <BundleSearchCreateField
                    primaryKey="guid"
                    data-cy="category"
                    name="category"
                    label="Category"
                    api={API.CATEGORY_LIST}
                    isLoading={categoryLoading}
                    onClick={onCategoryCreate}
                    ListboxProps={{ 'data-cy': 'categoryList' }}
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <BundleSearchCreateField
                    primaryKey="guid"
                    data-cy="brand"
                    name="brand"
                    label="Brand"
                    api={API.BRAND_LIST}
                    isLoading={brandLoading}
                    onClick={onBrandCreate}
                    ListboxProps={{ 'data-cy': 'brandList' }}
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <TagsField
                    data-cy="tags"
                    label="Tags"
                    name="tags"
                    placeholder="Tags"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>

      </Grid>
    </Grid>
  )
}

BundleGeneral.propTypes = {
  onCategoryCreate: PropTypes.func.isRequired,
  onBrandCreate: PropTypes.func.isRequired,
  brandLoading: PropTypes.bool.isRequired,
  categoryLoading: PropTypes.bool.isRequired,
  retailPrice: PropTypes.number,
}

export default BundleGeneral
