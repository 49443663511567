import React from 'react'
import { SearchField } from 'storfox-query-builder'

import * as API from '~/constants/api'

function VariantSearchField (props) {
  return (
    <SearchField
      api={API.VARIANT_LIST}
      {...props}
    />
  )
}

export default VariantSearchField
