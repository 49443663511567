import React from 'react'
import { useFilter, useOrdering } from 'storfox-filter'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import ErrorLink from '~/components/ErrorLink'
import useMessages from '~/hooks/useMessages'

import {
  SalesOverviewFilterForm,
  salesOverviewFilterOptions,
  SalesOverviewOrderingForm,
  salesOverviewOrderingOptions,
  SalesOverviewTable
} from '../../components/SalesOverview/SalesOverviewList'
import { useSaleOverviewList, useSalesExport } from '../../hooks/SalesOverview'

function SalesOverviewListContainer () {
  const salesOverviewList = useSaleOverviewList()
  const salesExport = useSalesExport()
  const messages = useMessages()
  const snackbar = useSnackbar()

  const filter = useFilter(salesOverviewFilterOptions)
  const ordering = useOrdering(salesOverviewOrderingOptions)

  const breadcrumbs = { title: NAV.SALES_OVERVIEW }

  const handleSalesExport = () =>
    salesExport.export()
      .then(() => snackbar({ message: messages.NOTIFICATION_WAIT }))
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  return (
    <DashboardLayout
      title={NAV.SALES_OVERVIEW}
      activeNav={NAV.REPORTS}
      breadcrumbs={breadcrumbs}
    >
      <SalesOverviewFilterForm {...filter} />
      <SalesOverviewOrderingForm {...ordering} />

      <SalesOverviewTable
        onListRefetch={salesOverviewList.getListByParams}
        filter={filter}
        ordering={ordering}
        list={salesOverviewList}
        onSalesExport={handleSalesExport}
      />
    </DashboardLayout>
  )
}

export default SalesOverviewListContainer
