export function randStr (prefix) {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  const str = Math.random().toString(36).substr(2, 9)

  if (!prefix) {
    return str
  }

  return prefix + '_' + str
}

export function generatePassword (length = 8, number = false) {
  let letters = 'abcdefghijklmnopqrstuvwxyz1234567890'
  let upperLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  let password = []

  for (let i = 0; i < length - 1; i++) {
    password.push(letters.charAt(Math.floor(Math.random() * 36)))
  }
  if (number) {
    password.push(Math.floor(Math.random()).toString())
  }

  const upperLetter = upperLetters.charAt(Math.floor(Math.random() * 26))

  return `${upperLetter}${password.join('')}`
}
