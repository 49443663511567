import React from 'react'

import SelectField from '~/components/Fields/SelectField'

import { WAREHOUSE_STATUS_ACTIVE, WAREHOUSE_STATUS_INACTIVE } from '../../constants'

const OPTIONS = [
  { value: WAREHOUSE_STATUS_ACTIVE, name: 'Active' },
  { value: WAREHOUSE_STATUS_INACTIVE, name: 'Inactive' },
]
function WarehouseStatusField (props) {
  return (
    <SelectField
      name="status"
      label="Status"
      options={OPTIONS}
      {...props}
    />
  )
}

export default WarehouseStatusField
