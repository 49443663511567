import { useLimitedCountList, useCreate } from 'storfox-api-hooks'
import useAllSearchParams from 'storfox-route-hooks/src/useAllSearchParams'

import { queryToParams } from '~/utils'
import * as API from '~/constants/api'

import { SalesMarginFilterSerializer } from '../../serializers/SalesMargin'

export const useSaleMarginList = () => {
  const searchParams = useAllSearchParams()
  const params = queryToParams(SalesMarginFilterSerializer, searchParams, 'guid')
  const { getList, ...state } = useLimitedCountList(API.SALES_MARGIN_OVERVIEW, params)

  return { ...state, getList: rest => getList({ ...rest, ...params }) }
}

export const useSalesExport = () => {
  const searchParams = useAllSearchParams()
  const params = queryToParams(SalesMarginFilterSerializer, searchParams, 'guid')
  const { create, ...rest } = useCreate(API.SALES_MARGIN_EXPORT)

  return { ...rest, export: rest => create({ ...rest, ...params }) }
}
