import React from 'react'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell/TableCell'
import IconButton from '@mui/material/IconButton'
import { path, prop, propOr } from 'ramda'
import Delete from '@mui/icons-material/Delete'
import PropTypes from 'prop-types'
import EditIcon from '@mui/icons-material/Edit'
import Box from '@mui/material/Box'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import Avatar from '~/components/Avatar'
import VerticalAlignment from '~/components/VerticalAlignment'
import Subtext from '~/components/Subtext'
import PositiveNumberField from '~/components/Fields/PositiveNumberField'
import useDialog from '~/hooks/useDialog'
import TextOverflow from '~/components/TextOverflow'
import TextField from '~/components/Fields/TextField'
import MoneyField from '~/components/Fields/MoneyField'
import Money from '~/components/Money'
import Tooltip from '~/components/HtmlTooltip'

import LineItemDetailEdit from './LineItemDetailEdit'

function LineItem ({ fields, index, name }) {
  const { handleClose, handleOpen, open } = useDialog()

  const value = prop('value', fields)
  const item = prop(index, value)
  const variantName = path(['productVariant', 'name'], item)
  const sku = path(['productVariant', 'sku'], item)
  const imagePath = path(['productVariant', 'defaultImage'], item)
  const unitCost = propOr(0, 'price', item)
  const quantity = propOr(0, 'quantity', item)
  const discount = propOr(0, 'discount', item)
  const orderDiscount = unitCost * (1 - discount / 100)
  const tax = propOr(0, 'tax', item) / 100 * quantity * orderDiscount
  const totalPrice = quantity * orderDiscount + tax
  const lastRow = index === fields.length - 1

  const withDiscountOrTax = totalPrice !== unitCost * quantity

  return (
    <TableRow
      key={index}
      sx={[
        lastRow && {
          '& td': {
            borderBottom: 'none'
          }
        }
      ]}
    >
      <TableCell>
        <Avatar src={imagePath} alt={variantName} />
      </TableCell>

      <TableCell>
        <VerticalAlignment
          primary={(
            <TextOverflow selfTooltip={true}>
              {variantName}
            </TextOverflow>
          )}
          secondary={(
            <Subtext lines={1}>{sku}</Subtext>
          )}
        />
      </TableCell>

      <TableCell>
        <TextField
          name={`${name}.supplierSku`}
          data-cy={`${name}.supplierSku`}
        />
      </TableCell>

      <TableCell>
        <PositiveNumberField
          name={`${name}.quantity`}
          data-cy={`${name}.quantity`}
        />
      </TableCell>

      <TableCell>
        <MoneyField
          name={`${name}.price`}
          data-cy={`${name}.price`}
        />
      </TableCell>

      <TableCell>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Money value={totalPrice} />
          {withDiscountOrTax && (
            <Tooltip
              title="The final price is based on tax and discount"
              style={{ display: 'flex', marginLeft: '10px' }}
            >
              <InfoOutlinedIcon color="info" />
            </Tooltip>
          )}
        </Box>
      </TableCell>

      <TableCell align="right" data-cy={`variant-edit-${index}`}>
        <IconButton onClick={handleOpen} size="large">
          <EditIcon />
        </IconButton>
      </TableCell>

      <TableCell align="right">
        <IconButton onClick={() => fields.remove(index)} size="large">
          <Delete />
        </IconButton>
      </TableCell>

      <LineItemDetailEdit
        item={item}
        name={name}
        open={open}
        onClose={handleClose}
      />
    </TableRow>
  )
}

LineItem.propTypes = {
  fields: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired
}

export default LineItem
