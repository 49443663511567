import React from 'react'
import { Ordering } from 'storfox-filter'

import { BILLING_PROFILE_COLUMNS as tableColumns } from './BillingProfileList'

import { FILTER_NAME as name } from '../../../constants/BillingProfile'

export const billingProfileOrderingOptions = {
  name,
  tableColumns
}

function BillingProfileOrderingForm (props) {
  return (
    <Ordering {...props} />
  )
}

export default BillingProfileOrderingForm
