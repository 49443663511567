import React from 'react'
import { useField } from 'react-final-form'
import { any, defaultTo, length, map, path, pipe, prop, propOr, reduce } from 'ramda'
import PropTypes from 'prop-types'

import { unescapeBtoa } from '~/utils'
import { Button, DiscardButton } from '~/components/Buttons'
import PageTitle from '~/components/PageTitle'
import { withForm } from '~/components/Form'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import { CODE_TYPE, CONTAINER_TYPE_PREFIX } from '~/constants/barcode'
import FluidContainer, { FluidContent, FluidHeader } from '~/components/FluidContainer'
import { emptyArray } from '~/constants/empty'
import { COMPLETED, NEW, ON_HOLD } from '~/components/Statuses/PackingStatus'

import More from './More'
import PackingGeneral from './PackingGeneral'

import { COMPLETE, SAVE } from '../../constants'

const getContainerTypePrintPath = pipe(
  map(containerType => {
    const barcode = prop('guid', containerType)
    const name = prop('name', containerType)

    return { barcode: `${CONTAINER_TYPE_PREFIX}${barcode}|${name}` }
  }),
  unescapeBtoa,
  data => `${ROUTES.BARCODE_GENERATOR_PATH}?barcodes=${data}&type=${CODE_TYPE.QRCODE}`
)

const getLineItemsTotalQuantity = pipe(
  defaultTo([]),
  reduce((acc, item) => {
    const quantity = propOr(0, 'quantity', item)
    return acc + quantity
  }, 0)
)

const getPackedCount = reduce((acc, container) => {
  const quantity = getLineItemsTotalQuantity(prop('lineItems', container))
  return acc + quantity
}, 0)

function PackingBarcodingForm (props) {
  const {
    pageTitle,
    pageTitleLoading,
    isLoading,
    containerLoading,
    containerTypeList,
    onContainerCreate,
    form,
    onSkip,
    onGenerateSlip
  } = props

  const { initialValues, handleSubmit } = form
  const containerField = useField('containers')
  const containers = containerField.input.value || emptyArray

  const containerTypesPrintPath = getContainerTypePrintPath(containerTypeList.results)

  const status = prop('status', initialValues)
  const lineItems = propOr(emptyArray, 'lineItems', initialValues)
  const warehouseGuid = path(['warehouse', 'guid'], initialValues)

  const hasScannedItemsInContainer = pipe(
    map(propOr([], 'lineItems')),
    any(length)
  )(containers)

  const disabled = (
    isLoading ||
    status === NEW ||
    status === COMPLETED ||
    status === ON_HOLD
  )

  const packedCount = getPackedCount(containers)
  const totalQuantity = propOr(1, 'totalQuantity', initialValues)

  const completeDisabled = Boolean(
    disabled ||
    packedCount !== totalQuantity ||
    !hasScannedItemsInContainer
  )

  const handleSave = () => {
    form.form.change('type', SAVE)
    form.form.submit()
  }

  const handleComplete = () => {
    form.form.change('type', COMPLETE)
    form.form.submit()
  }

  return (
    <form onSubmit={handleSubmit} id="packingUpdateForm">
      <FluidContainer>
        <FluidHeader>
          <PageTitle
            parentTitle={NAV.PACKING}
            pageTitle={pageTitle}
            pageTitleLoading={pageTitleLoading}
            rightButton={(
              <>
                <DiscardButton />
                <Button
                  disabled={completeDisabled}
                  variant="contained"
                  data-cy="complete"
                  onClick={handleComplete}
                >
                  Complete
                </Button>

                <More
                  saveDisabled={disabled}
                  onSave={handleSave}
                  slipDisabled={isLoading}
                  onGenerateSlip={onGenerateSlip}
                />
              </>
            )}
          />
        </FluidHeader>
        <FluidContent>
          <PackingGeneral
            containerLoading={containerLoading}
            packedCount={packedCount}
            totalQuantity={totalQuantity}
            detailLoading={pageTitleLoading}
            onContainerCreate={onContainerCreate}
            containerTypesPrintPath={containerTypesPrintPath}
            initialLineItems={lineItems}
            containerTypesPathDisabled={containerTypeList.isLoading}
            warehouseGuid={warehouseGuid}
            canComplete={!completeDisabled}
            onComplete={handleComplete}
            onSave={handleSave}
            onSkip={onSkip}
          />
        </FluidContent>
      </FluidContainer>
    </form>
  )
}

PackingBarcodingForm.propTypes = {
  form: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  containerLoading: PropTypes.bool.isRequired,
  onContainerCreate: PropTypes.func.isRequired,
  containerTypeList: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    results: PropTypes.array.isRequired
  }).isRequired,
  pageTitle: PropTypes.object,
  pageTitleLoading: PropTypes.bool,
  onSkip: PropTypes.func.isRequired,
  onGenerateSlip: PropTypes.func.isRequired
}

export default withForm(PackingBarcodingForm)
