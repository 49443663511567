import React from 'react'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Card from '@mui/material/Card'
import { useTranslation } from 'react-i18next'
import Link from '@mui/material/Link'
import { generatePath, Link as RouterLink } from 'react-router-dom'
import { useField } from 'react-final-form'
import { prop, propOr } from 'ramda'

import { Volume, Weight } from '~/components/Converters'
import Money from '~/components/Money'
import { CardHeader } from '~/components/Cards'
import * as ROUTES from '~/constants/routes'
import TextLabelLoader from '~/components/TextLabelLoader'
import { SHIPMENT_DETAIL_TABS } from '~/constants/tabs'

function ShipmentDetail () {
  const { t } = useTranslation()

  const shipmentField = useField('shipment')
  const shipmentFieldValue = shipmentField.input.value

  const shipmentAddressField = useField('shipmentAddress')
  const shipmentAddressValue = shipmentAddressField.input.value

  const serviceField = useField('service')
  const serviceValue = serviceField.input.value

  const guid = prop('guid', shipmentFieldValue)
  const shipmentNumber = prop('shipmentNumber', shipmentFieldValue)

  const length = propOr(0, 'length', shipmentAddressValue)
  const width = propOr(0, 'width', shipmentAddressValue)
  const height = propOr(0, 'height', shipmentAddressValue)
  const weight = propOr(0, 'weight', shipmentAddressValue)
  const volume = Number(width) * Number(height) * Number(length)

  const shippingFee = propOr(0, 'price', serviceValue)

  const path = guid && generatePath(ROUTES.SHIPMENT_DETAIL_PATH, { guid, tab: SHIPMENT_DETAIL_TABS.GENERAL })

  return (
    <Card>
      <CardHeader title={t('Shipment')} />
      <Divider />
      <CardContent style={{ padding: 0 }}>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell>
                {t('Shipment number')}:
              </TableCell>
              <TableCell>
                {guid ? (
                  <Link
                    component={RouterLink}
                    to={path}
                    underline="always"
                  >
                    {shipmentNumber}
                  </Link>
                ) : (
                  <TextLabelLoader>
                    {shipmentNumber}
                  </TextLabelLoader>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {t('Net weight')}
              </TableCell>
              <TableCell>
                <Weight value={weight} />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                {t('Dimensions')}
              </TableCell>
              <TableCell>
                <Volume value={volume} />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                {t('Shipping fee')}
              </TableCell>
              <TableCell>
                <Money value={shippingFee} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}

export default ShipmentDetail
