import React from 'react'
import PropTypes from 'prop-types'
import DialogContent from '@mui/material/DialogContent'
import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import GridViewIcon from '@mui/icons-material/GridView'
import ViewInArIcon from '@mui/icons-material/ViewInAr'
import { generatePath } from 'react-router-dom'

import DialogTitle from '~/components/DialogTitle'
import * as ROUTES from '~/constants/routes'
import ChoiceCard, { ChoiceCardLink } from '~/components/Cards/ChoiceCard'

const MODES = [
  {
    name: '2D Visualization',
    value: '2d',
    icon: <GridViewIcon />
  },
  {
    name: '3D Visualization',
    value: '3d',
    icon: <ViewInArIcon />
  }
]

function WarehouseVisualizationModeDialog ({ open, onClose, guid }) {
  const { t } = useTranslation()

  const visualization2DPath = generatePath(ROUTES.WAREHOUSE_2D_VISUALIZATION_PATH, { guid })
  const visualization3DPath = generatePath(ROUTES.WAREHOUSE_VISUALIZATION_PATH, { guid })

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth={true}
      onClose={onClose}
    >
      <DialogTitle>{t('Choose mode')}</DialogTitle>

      <DialogContent dividers={true}>
        <Grid container={true} spacing={3}>
          {MODES.map(({ name, value, icon }) => {
            const is2D = value === '2d'
            const visualizationPath = is2D ? visualization2DPath : visualization3DPath

            return (
              <Grid
                key={value}
                item={true}
                xs={12}
                lg={6}
                data-cy={value}
              >
                <ChoiceCard>
                  <ChoiceCardLink to={visualizationPath}>
                    {icon}
                    <Typography ml={1} variant="h5">{name}</Typography>
                  </ChoiceCardLink>
                </ChoiceCard>
              </Grid>
            )
          })}
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

WarehouseVisualizationModeDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  guid: PropTypes.string.isRequired
}

export default WarehouseVisualizationModeDialog
