import React from 'react'
import PropTypes from 'prop-types'

import TableSplitButton from '~/components/TableSplitButton'

function BulkActionButton (props) {
  const {
    onStatusSet,
    onGatepassGenerate,
    statusSetDisabled,
    loading
  } = props

  const options = [
    {
      label: 'Set status',
      'data-cy': 'status-set',
      onMenuClick: onStatusSet,
      disabled: !statusSetDisabled,
      tooltip: 'Work only for 25 or less shipments'
    },
    {
      label: 'Generate gatepass',
      'data-cy': 'generate',
      onMenuClick: onGatepassGenerate
    }
  ]

  return (
    <TableSplitButton
      options={options}
      name="shipment"
      loading={loading}
    />
  )
}

BulkActionButton.propTypes = {
  onStatusSet: PropTypes.func.isRequired,
  onGatepassGenerate: PropTypes.func.isRequired,
  statusSetDisabled: PropTypes.bool,
  loading: PropTypes.bool
}

export default BulkActionButton
