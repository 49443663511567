import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import BillingListContainer from './containers/BillingListContainer'
import BillingDetailContainer from './containers/BillingDetailContainer'
import BillingItemContainer from './containers/BillingItemContainer'

export default () => [
  {
    component: BillingDetailContainer,
    path: ROUTES.BILLING_DETAIL_PATH,
    layout: AuthLayout
  },
  {
    component: BillingListContainer,
    path: ROUTES.BILLING_LIST_PATH,
    layout: AuthLayout
  },
  {
    component: BillingItemContainer,
    path: ROUTES.BILLING_ITEM_PATH,
    layout: AuthLayout
  }
]
