import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import AdjustmentListContainer from './containers/AdjustmentListContainer'
import AdjustmentCreateContainer from './containers/AdjustmentCreateContainer'
import AdjustmentUpdateContainer from './containers/AdjustmentUpdateContainer'
import AdjustmentImportContainer from './containers/AdjustmentImportContainer'
import AdjustmentDetailContainer from './containers/AdjustmentDetailContainer'

export default () => {
  return [
    {
      layout: AuthLayout,
      path: ROUTES.ADJUSTMENT_LIST_PATH,
      component: AdjustmentListContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.ADJUSTMENT_CREATE_PATH,
      component: AdjustmentCreateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.ADJUSTMENT_DETAIL_PATH,
      component: AdjustmentDetailContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.ADJUSTMENT_UPDATE_PATH,
      component: AdjustmentUpdateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.ADJUSTMENT_IMPORT_PATH,
      component: AdjustmentImportContainer
    }
  ]
}
