import React from 'react'
import Grid from '@mui/material/Grid'
import { Filter } from 'storfox-filter'
import { pick, pipe, prop, propOr } from 'ramda'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { DateRangeField } from 'storfox-form-fields'

import { escapeAtob, getSeparatedDateRange, omitEmptyObject, unescapeBtoa } from '~/utils'
import WarehouseMultiSelectField from '~/components/Fields/WarehouseMultiSelectField'
import Accordion from '~/components/Accordion'

import MovementStatusField from './MovementStatusField'

import LocationSearchField from '../Fields/LocationSearchField'
import { FILTER_NAME as name } from '../../constants'

const getToLocation = pipe(
  propOr({}, 'toLocationId'),
  pick(['id', 'guid', 'locationId']),
  omitEmptyObject,
  unescapeBtoa
)

export const toParams = values => {
  const status = prop('status', values)
  const toLocationId = getToLocation(values)
  const warehouseList = propOr([], 'warehouses', values)

  const warehouses = warehouseList.length > 0 ? unescapeBtoa(propOr([], 'warehouses', values)) : null

  const [updatedAtStart, updatedAtEnd] = getSeparatedDateRange(prop('updatedAt', values))

  return {
    status,
    warehouses,
    toLocationId,
    updatedAtStart,
    updatedAtEnd
  }
}

export const toValues = params => {
  const status = prop('status', params)
  const warehouses = escapeAtob(prop('warehouses', params))
  const toLocationId = escapeAtob(prop('toLocationId', params))
  const updatedAt = [prop('updatedAtStart', params), prop('updatedAtEnd', params)]

  return {
    status,
    warehouses,
    toLocationId,
    updatedAt
  }
}

export const transformer = { toParams, toValues }

export const movementFilterOptions = {
  name,
  transformer
}

function MovementFilterForm (props) {
  const { t } = useTranslation()

  return (
    <Filter {...props}>
      <Accordion title="General" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <MovementStatusField />
          </Grid>
          <Grid item={true} xs={12}>
            <WarehouseMultiSelectField />
          </Grid>
          <Grid item={true} xs={12}>
            <LocationSearchField />
          </Grid>
        </Grid>
      </Accordion>
      <Accordion title="Date" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <Typography variant="body2" gutterBottom={true}>
              {t('Updated')}:
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <DateRangeField name="updatedAt" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Accordion>
    </Filter>
  )
}

export default MovementFilterForm
