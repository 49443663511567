import { defaultTo, filter, join, map, pipe, replace, split } from 'ramda'

export const generateSkuFromString = (string, substrLength = 6) => pipe(
  defaultTo(''),
  replace(/-/g, ' '),
  split(' '),
  filter(item => item !== ' ' && item !== ''),
  map(item => item.substring(0, substrLength)),
  join(''),
)(string)
