import React from 'react'
import { useFilter, useOrdering } from 'storfox-filter'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'

import { useInventoryLogList } from '../hooks'
import InventoryLogTable, {
  InventoryLogFilterForm,
  inventoryLogFilterOptions,
  InventoryLogOrderingForm,
  inventoryLogOrderingOptions
} from '../components/InventoryLogList'

function InventoryLogListContainer () {
  const inventoryLogList = useInventoryLogList()

  const filter = useFilter(inventoryLogFilterOptions)
  const ordering = useOrdering(inventoryLogOrderingOptions)

  const breadcrumbs = { title: NAV.INVENTORY_LOG }

  return (
    <DashboardLayout
      title={NAV.INVENTORY_LOG}
      activeNav={NAV.INVENTORY_LOG}
      isLoading={inventoryLogList.isLoading}
      breadcrumbs={breadcrumbs}
    >
      <InventoryLogFilterForm {...filter} />
      <InventoryLogOrderingForm {...ordering} />

      <InventoryLogTable
        filter={filter}
        ordering={ordering}
        list={inventoryLogList}
        onListRefetch={inventoryLogList.getListByParams}
      />
    </DashboardLayout>
  )
}

export default InventoryLogListContainer
