import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { pathOr } from 'ramda'
import { useSnackbar } from 'storfox-snackbar'
import { useTranslation } from 'react-i18next'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'

import { useCategoryDetail, useCategoryUpdate } from '../hooks'
import { CategoryCreateSerializer } from '../serializers'
import { CategoryCreateForm } from '../components/CategoryCreate'

function CategoryUpdateContainer () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { id } = useParams()
  const snackbar = useSnackbar()
  const categoryDetail = useCategoryDetail(id)
  const categoryUpdate = useCategoryUpdate(id)

  const title = pathOr('Categories', ['detail', 'name'], categoryDetail)
  const message = t('Successfully updated')

  const handleSubmit = formValues =>
    categoryUpdate.update(CategoryCreateSerializer(formValues))
      .then(() => snackbar({ message }))
      .then(() => navigate(ROUTES.CATEGORY_LIST_PATH))

  const pageLoading = categoryDetail.isLoading || categoryUpdate.isLoading

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      isLoading={pageLoading}
      activeNav={NAV.CATEGORIES}
      breadcrumbs={breadcrumbs}
    >
      <CategoryCreateForm
        title={title}
        pageTitleLoading={categoryDetail.isLoading}
        initialValues={categoryDetail.detail}
        onSubmit={handleSubmit}
        isLoading={categoryUpdate.isLoading}
      />
    </DashboardLayout>
  )
}

export default CategoryUpdateContainer
