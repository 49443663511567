import React, { useRef } from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import { prop } from 'ramda'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import { useReactToPrint } from 'react-to-print'
import { useField } from 'react-final-form'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import VisibilityIcon from '@mui/icons-material/Visibility'
import CodeIcon from '@mui/icons-material/Code'
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner'
import IconButton from '@mui/material/IconButton'

import { CardHeader } from '~/components/Cards'
import Container, { Content, Header } from '~/components/Container'
import PageTitle from '~/components/PageTitle'
import { DiscardButton, SaveButton } from '~/components/Buttons'
import { withForm } from '~/components/Form'
import * as NAV from '~/constants/nav-titles'
import { RichTextField } from '~/components/AceEditor'
import SkeletonList from '~/components/Skeleton'

export const TEMPLATE_VIEW = {
  CODE: 'code',
  PREVIEW: 'preview'
}

function TemplateUpdateForm ({ title, isLoading, pageTitleLoading, form, type, onImageUpload, onPreview }) {
  const { initialValues, handleSubmit } = form
  const componentRef = useRef(null)

  const html = useField('htmlBody')
  const htmlBody = html.input.value

  const editor = useField('editor')
  const editorValue = editor.input.value
  const editorChange = editor.input.onChange

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  const handleClick = () => {
    return onPreview({ htmlBody, type })
      .then(() => handlePrint())
  }
  const keywords = prop('keywords', initialValues)
  const body = prop('htmlBody', initialValues)

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={title}
            parentTitle={NAV.CONFIGURATION}
            pageTitleLoading={pageTitleLoading}
            rightButton={(
              <>
                <DiscardButton disabled={isLoading} />
                <SaveButton disabled={isLoading} />
              </>
            )}
          />
        </Header>

        <Content bottomSpace={true}>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12} lg={8}>
              <Box>
                <Card>
                  <CardHeader
                    title="Editor"
                    action={
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <ToggleButtonGroup
                          color="primary"
                          value={editorValue}
                          exclusive={true}
                          onChange={(e, viewType) => editorChange(viewType)}
                          size="small"
                        >
                          <ToggleButton value={TEMPLATE_VIEW.CODE} key={TEMPLATE_VIEW.CODE} title="HTML">
                            <CodeIcon fontSize="small" />
                          </ToggleButton>
                          <ToggleButton value={TEMPLATE_VIEW.PREVIEW} key={TEMPLATE_VIEW.PREVIEW} title="Quick preview">
                            <VisibilityIcon fontSize="small" />
                          </ToggleButton>
                        </ToggleButtonGroup>
                        <Box pr={2} />
                        <IconButton
                          data-cy="submit"
                          onClick={handleClick}
                          title="Preview"
                        >
                          <DocumentScannerIcon />
                        </IconButton>
                      </Box>
                    }
                  />
                  <Divider />
                  <CardContent sx={{ padding: '10px' }}>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12} sx={{ display:'none' }}>
                        <div
                          ref={componentRef}
                          dangerouslySetInnerHTML={{ __html: htmlBody }}
                        />
                      </Grid>
                      <Grid item={true} xs={12}>
                        {editorValue === TEMPLATE_VIEW.PREVIEW ? (
                          <div dangerouslySetInnerHTML={{ __html: htmlBody }} />
                        ) : (
                          <RichTextField
                            name="htmlBody"
                            initialData={body}
                            onImageUpload={onImageUpload}
                            keywords={keywords}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
            <Grid item={true} xs={12} lg={4}>
              <Box>
                <Card>
                  <CardHeader sx={{ p: 2 }}
                    title="Variables" />
                  <Divider />
                  <CardContent sx={{
                    padding: 0,
                    '&:last-child': {
                      paddingBottom: 0
                    }
                  }}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Description</TableCell>
                          <TableCell>Name</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {isLoading
                          ? <SkeletonList rowCount={5} columnCount={2} />
                          : keywords && keywords.map((keyword, index) => {
                            const name = prop('name', keyword)
                            const description = prop('description', keyword)

                            return (
                              <TableRow key={index}>
                                <TableCell>{description}</TableCell>
                                <TableCell>{name}</TableCell>
                              </TableRow>
                            )
                          })}
                      </TableBody>
                    </Table>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Content>
      </Container>
    </form>
  )
}

TemplateUpdateForm.propTypes = {
  title: PropTypes.string.isRequired,
  form: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  pageTitleLoading: PropTypes.bool,
  onPreview: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  onImageUpload: PropTypes.func.isRequired
}

export default withForm(TemplateUpdateForm)
