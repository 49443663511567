import React, { useState } from 'react'
import { map, path, prop } from 'ramda'
import { generatePath } from 'react-router-dom'
import PropTypes from 'prop-types'

import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import Money from '~/components/Money'
import { Table, TableHeader } from '~/components/Table'
import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'
import TableDateFormat from '~/components/TableDateFormat'
import useDialog from '~/hooks/useDialog'
import TextOverflow from '~/components/TextOverflow'
import TableLink from '~/components/Link'

import TplBillingExportDialogForm from './TplBillingExportDialogForm'
import TplBillingStatus from './TplBillingStatus'
import More from './More'

export const TPL_BILLING_COLUMNS = [
  {
    width: 125,
    headerName: 'Number',
    field: 'number',
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        <TextOverflow selfTooltip={true} lines={1}>
          {value}
        </TextOverflow>
      </TableLink>
    )
  },
  {
    width: 200,
    headerName: 'Client',
    field: 'client'
  },
  {
    width: 150,
    headerName: 'Status',
    field: 'status',
    renderCell: ({ value }) => (
      <TplBillingStatus value={value} />
    )
  },
  {
    width: 200,
    headerName: 'Start period',
    field: 'periodStart',
    renderCell: ({ value }) => (
      <TableDateFormat date={value} withTime={true} />
    )
  },
  {
    width: 200,
    headerName: 'End period',
    field: 'periodEnd',
    renderCell: ({ value }) => (
      <TableDateFormat date={value} withTime={true} />
    )

  },
  {
    width: 150,
    headerName: 'Total price',
    field: 'totalPrice',
    renderCell: ({ value }) => (
      <Money value={value} />
    )
  },
  {
    width: 150,
    headerName: 'Total paid',
    field: 'totalPaid',
    renderCell: ({ value }) => (
      <Money value={value} />
    )
  },
]

const getRowsFromResults = map(item => {
  const guid = prop('guid', item)
  const number = prop('number', item)
  const client = path(['client', 'name'], item)
  const periodStart = prop('periodStart', item)
  const periodEnd = prop('periodEnd', item)
  const totalPrice = prop('totalPrice', item)
  const totalPaid = prop('totalPaid', item)
  const status = prop('status', item)
  const link = generatePath(ROUTES.TPL_BILLING_DETAIL_PATH, { guid })

  return {
    guid,
    number,
    client,
    periodStart,
    periodEnd,
    totalPrice,
    totalPaid,
    status,
    link
  }
})

function TplBillingList ({ list, filter, ordering, onListRefetch, onBillingsExport }) {
  const [tableRef, setTableRef] = useState(null)
  const exportDialog = useDialog()
  const results = prop('results', list)
  const isLoading = prop('isLoading', list)
  const count = prop('count', list)
  const columns = prop('filteredColumns', ordering)

  return (
    <>
      <Container>
        <Header>
          <PageTitle
            pageTitle={NAV.TPL_BILLING}
            parentTitle={NAV.REPORTS}
          />
        </Header>
        <Content>
          <TableHeader
            filter={filter}
            filterOpen={filter.handleOpen}
            orderingOpen={ordering.handleOpen}
            ordering={ordering}
          >
            <More onBillingsExport={exportDialog.handleOpen} tableRef={tableRef} />
          </TableHeader>
          <Table
            columns={columns}
            isLoading={isLoading}
            getRowsFromResults={getRowsFromResults}
            results={results}
            count={count}
            primaryKey="guid"
            checkboxSelection={true}
            ordering={ordering}
            onRefetch={onListRefetch}
            setTableRef={setTableRef}
          />
        </Content>
      </Container>
      <TplBillingExportDialogForm
        open={exportDialog.open}
        onClose={exportDialog.handleClose}
        initialValues={{ open: exportDialog.open }}
        onSubmit={(values) => {
          onBillingsExport(values)
          exportDialog.handleClose()
        }}
      />
    </>
  )
}

TplBillingList.propTypes = {
  list: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  onBillingsExport: PropTypes.func.isRequired,
  onListRefetch: PropTypes.func.isRequired
}

export default TplBillingList
