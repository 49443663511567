import React from 'react'
import PropTypes from 'prop-types'
import { prop } from 'ramda'

import Status from '~/components/Status'

function TrackingStatus ({ value, row }) {
  const trackingNumber = prop('trackingNumber', row)
  const label = trackingNumber ? 'SUCCESS' : value ? 'FAIL' : 'N/A'
  const color = trackingNumber ? 'success' : value ? 'error' : 'warning'

  return (
    <Status
      label={label}
      color={color}
    />
  )
}

TrackingStatus.propTypes = {
  value: PropTypes.string.isRequired,
  row: PropTypes.object.isRequired
}

export default TrackingStatus
