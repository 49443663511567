import React from 'react'
import PropTypes from 'prop-types'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Card from '@mui/material/Card'

import { CardHeader } from '~/components/Cards'
import TextField from '~/components/Fields/TextField'
import PageTitle from '~/components/PageTitle'
import { DiscardButton, SaveButton } from '~/components/Buttons'
import Container, { Content, Header } from '~/components/Container'
import { withForm } from '~/components/Form'
import * as NAV from '~/constants/nav-titles'
import WarehouseSearchField from '~/components/Fields/WarehouseSearchField'
import * as API from '~/constants/api'

import PurchaseReturnSupplierInfo from './Supplier/PurchaseReturnSupplierInfo'

import InvoiceSummary from '../InvoiceSummary'
import LineItems from '../LineItems'

function ReturnCreateForm ({ pageTitle, isLoading, form, onSupplierCreate, returnVariants }) {
  const { handleSubmit } = form

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={pageTitle}
            parentTitle={NAV.SUPPLIER_RETURNS}
            rightButton={(
              <>
                <DiscardButton disabled={isLoading} />
                <SaveButton disabled={isLoading} />
              </>
            )}
          />
        </Header>

        <Content>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12} lg={8}>
              <Card>
                <CardHeader title="Supplier" required={true} />
                <Divider />
                <CardContent>
                  <PurchaseReturnSupplierInfo onSupplierCreate={onSupplierCreate} />
                </CardContent>
              </Card>
            </Grid>
            <Grid item={true} xs={12} lg={4}>
              <Card style={{ height: '100%' }}>
                <CardHeader title="Details" />
                <Divider />
                <CardContent style={{ height: '100%' }}>
                  <Grid item={true} xs={12}>
                    <WarehouseSearchField
                      api={API.PURCHASE_ORDER_WAREHOUSE_LIST}
                      data-cy="warehouse"
                      ListboxProps={{ 'data-cy': 'warehouseList' }}
                      required={true}
                    />
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item={true} xs={12}>
              <Card>
                <CardHeader title="Line items" required={true} />
                <Divider />
                <CardContent>
                  <LineItems returnVariants={returnVariants} />
                </CardContent>

                <CardContent>
                  <Divider />
                  <Grid container={true} spacing={3} style={{ paddingTop: 20 }}>
                    <Grid item={true} xs={12} lg={6}>
                      <TextField
                        label="Notes"
                        name="notes"
                        data-cy="notes"
                        multiline={true}
                        minRows={11}
                        required={true}
                      />
                    </Grid>

                    <Grid item={true} xs={12} lg={6}>
                      <InvoiceSummary />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Content>
      </Container>
    </form>
  )
}

ReturnCreateForm.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func,
  form: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  onSupplierCreate: PropTypes.func,
  returnVariants: PropTypes.object.isRequired
}

export default withForm(ReturnCreateForm)
