import PropTypes from 'prop-types'
import React, { useEffect, useReducer, useRef } from 'react'
import Box from '@mui/material/Box'
import { colors, styled } from '@mui/material'

import { failAudio, successAudio } from '~/constants/sounds'

import Context from './context'

const BoxStyled = styled(Box, {
  shouldForwardProp: propName => propName !== 'isDanger'
})(({ isDanger }) => ({
  position: 'fixed',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  width: '100%',
  height: '100%',
  background: `${colors.green[500]}0F`,
  border: `5px solid ${colors.green[500]}`,
  zIndex: 99999,
  ...(isDanger && {
    background: `${colors.red[500]}0F`,
    borderColor: colors.red[500]
  })
}))

const SUCCESS = 'success'
const ERROR = 'error'
const CUSTOM = 'custom'
const CLOSE = 'close'

const DURATION = 200

const initialState = {
  open: false,
  color: SUCCESS
}

const reducer = (state, action) => {
  switch (action.type) {
    case SUCCESS:
      return { ...state, open: true, color: SUCCESS }
    case ERROR:
      return { ...state, open: true, color: ERROR }
    case CUSTOM:
      return { ...state, ...action.payload, open: true }
    case CLOSE:
      return { ...state, open: false }
    default:
      return state
  }
}

function ScreenOutlineProvider ({ children }) {
  const timeoutRef = useRef(null)

  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    if (state.open) {
      timeoutRef.current = setTimeout(() => {
        dispatch({ type: CLOSE })
      }, DURATION)
    }

    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [state])

  const handleTrigger = () => {
    dispatch({ type: SUCCESS })
  }

  const handleErrorTrigger = () => {
    dispatch({ type: ERROR })
  }

  const handleColoredOutlineTrigger = payload => dispatch({ type: CUSTOM, payload })

  const handleSoundedTrigger = () => {
    successAudio.play()
    dispatch({ type: SUCCESS })
  }

  const handleSoundedErrorTrigger = () => {
    failAudio.play()
    dispatch({ type: ERROR })
  }

  const value = {
    handleTrigger,
    handleErrorTrigger,
    handleSoundedTrigger,
    handleSoundedErrorTrigger,
    handleColoredOutlineTrigger
  }

  return (
    <Context.Provider value={value}>
      {state.open && (
        <BoxStyled isDanger={state.color === ERROR} />
      )}
      {children}

    </Context.Provider>
  )
}

ScreenOutlineProvider.propTypes = {
  children: PropTypes.any.isRequired
}

export default ScreenOutlineProvider
