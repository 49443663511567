import React, { useCallback, useRef } from 'react'
import { isEmpty, path, propOr } from 'ramda'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import IconButton from '@mui/material/IconButton'
import Delete from '@mui/icons-material/Delete'
import { useField } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { CheckboxField } from 'storfox-form-fields'
import { useConfirm } from 'storfox-confirm-dialog'
import { useTranslation } from 'react-i18next'

import { randStr } from '~/utils'
import TagsField from '~/components/Fields/TagsField'
import TextField from '~/components/Fields/TextField'
import { CardHeader } from '~/components/Cards'
import { Button } from '~/components/Buttons'
import { emptyArray } from '~/constants/empty'

import { addVariantName, getVariants } from '../../../utils/variants'

function ProductUpdateFormVariants () {
  const { t } = useTranslation()
  const onConfirm = useConfirm()

  const isInitiallyRendered = useRef(false)

  const defaultValuesField = useField('defaultValues')
  const defaultValues = path(['input', 'value'], defaultValuesField)
  const defaultOptions = propOr([], 'options', defaultValues)
  const defaultOptionsIsEmpty = isEmpty(defaultOptions)

  const optionsField = useField('options')
  const optionsValue = path(['input', 'value'], optionsField) || emptyArray
  const optionsOnChange = path(['input', 'onChange'], optionsField)

  const optionsRef = useRef(optionsValue)

  const nameField = useField('name')
  const nameValue = path(['input', 'value'], nameField) || emptyArray

  const variantsField = useField('variants')
  const variantsValue = variantsField.input.value || emptyArray
  const variantsOnChange = useCallback(path(['input', 'onChange'], variantsField), [])

  const multiVariantsField = useField('isMultiVariant')
  const multiVariantsChange = path(['input', 'onChange'], multiVariantsField)
  const multiVariantsChecked = defaultOptionsIsEmpty
    ? path(['input', 'value'], multiVariantsField)
    : true

  const onClickCheckbox = (event, value) => {
    const name = path(['variants', 0, 'name'], defaultValues)
    const sku = path(['variants', 0, 'sku'], defaultValues)

    const message = (
      <>
        Enabling a multi-variant option for this product will delete a default variant&nbsp;
        <strong>{name}</strong> with SKU <strong>{sku}</strong>.
      </>
    )

    if (value) {
      onConfirm({ message })
        .agree(() => {
          optionsOnChange([{ key: randStr(), name: '', options: [] }])
          multiVariantsChange(value)
        })
        .disagree()
    } else {
      optionsOnChange([])
      multiVariantsChange(value)
    }
  }

  const generateVariantsByOptions = () => {
    const variants = getVariants(optionsValue, variantsValue)
    variantsOnChange(addVariantName(nameValue, variants))

    optionsRef.current = optionsValue

    isInitiallyRendered.current = true
  }

  return (
    <FieldArray name="options">
      {({ fields }) => (
        <Card>
          <CardHeader
            title={
              <>
                {defaultOptionsIsEmpty ? (
                  <FormControlLabel
                    control={
                      <CheckboxField
                        size="small"
                        name="isMultiVariant"
                        data-cy="isMultiVariant"
                        onChange={onClickCheckbox}
                      />
                    }
                    label={t('This is a multi-variant product')}
                  />
                ) : t('Variant Options')}
              </>
            }
          />
          {multiVariantsChecked && (
            <>
              <Divider />
              {fields.map((name, index) => (
                <div key={index}>
                  <CardContent>
                    <Grid container={true} spacing={2}>
                      <Grid item={true} xs={4}>
                        <TextField
                          data-cy={`${name}.name`}
                          name={`${name}.name`}
                          label={t('Option name')}
                        />
                      </Grid>
                      <Grid item={true} xs={7}>
                        <TagsField
                          data-cy={`${name}.options`}
                          name={`${name}.options`}
                          label={t('Values')}
                        />
                      </Grid>
                      <Grid item={true} xs={1}>
                        <IconButton data-cy={`delete${index}`} size="small" onClick={() => fields.remove(index)}>
                          <Delete />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />
                </div>
              ))}
              <CardActions sx={{ py: 2 }}>
                <Button
                  data-cy="addOption"
                  size="small"
                  onClick={() => fields.push({ key: randStr(), name: '', options: [] })}
                >
                  Add another option
                </Button>
                <Button
                  data-cy="generateVariants"
                  size="small"
                  onClick={generateVariantsByOptions}
                  disabled={variantsValue.length > 1}
                >
                  Generate variants
                </Button>
              </CardActions>
            </>
          )}
        </Card>
      )}
    </FieldArray>
  )
}

export default ProductUpdateFormVariants
