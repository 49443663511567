import PropTypes from 'prop-types'
import React, { useState } from 'react'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import { always } from 'ramda'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import ListItemText from '@mui/material/ListItemText'
import { useTranslation } from 'react-i18next'

import IconText from '~/components/IconText'
import { MoreButton } from '~/components/Buttons/index'
import DensitySelector from '~/components/DensitySelector'

function MoreListButton ({ children = always(null), tableRef }) {
  const { t } = useTranslation()

  const [anchorEl, setAnchorEl] = useState(null)

  const handleDensityMenuOpen = event => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleDensityMenuClose = () => {
    setAnchorEl(null)
  }

  return (
    <MoreButton>
      {onClose => (
        <List>
          {children(onClose)}
          <ListItemButton onClick={handleDensityMenuOpen}>
            <DensitySelector
              anchorEl={anchorEl}
              onMenuClose={handleDensityMenuClose}
              tableRef={tableRef}
            />
          </ListItemButton>
          <ListItemButton
            sx={{ color: theme => theme.palette.text.primary }}
            onClick={() => tableRef.current.exportDataAsCsv({ utf8WithBom: true })}
          >
            <IconText Icon={CloudDownloadIcon}>
              <ListItemText primary={t('Download CSV')} />
            </IconText>
          </ListItemButton>
        </List>
      )}
    </MoreButton>
  )
}

MoreListButton.propTypes = {
  children: PropTypes.func,
  tableRef: PropTypes.object
}

export default MoreListButton
