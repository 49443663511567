import React from 'react'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import IconButton from '@mui/material/IconButton'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import Box from '@mui/material/Box'
import DeleteIcon from '@mui/icons-material/Delete'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import Card from '@mui/material/Card'

import HtmlTooltip from '~/components/HtmlTooltip/HtmlTooltip'
import { CardHeader } from '~/components/Cards'

function ContainerPreviewCard ({ containerNumber, containerPutDisabled, onContainerPut, onRemove }) {
  const containerPutTooltip = containerPutDisabled
    ? 'Cannot put whole container, because you have already started to put units from it.'
    : 'Put the container to destination.'

  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader
        sx={{ height: '52px' }}
        title={(
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h5">Selected container</Typography>
            {containerNumber && (
              <Box display="flex">
                <HtmlTooltip title={containerPutTooltip}>
                  <IconButton
                    size="small"
                    onClick={onContainerPut}
                    disabled={containerPutDisabled}
                  >
                    <ExitToAppIcon />
                  </IconButton>
                </HtmlTooltip>
                <IconButton onClick={onRemove} size="small">
                  <DeleteIcon />
                </IconButton>
              </Box>
            )}
          </Box>
        )}
      />
      <Divider />
      <CardContent sx={{ height: '100%' }}>
        {containerNumber ? (
          <Typography mt={2} variant="h4" align="center">
            {containerNumber}
          </Typography>
        ) : (
          <Typography mt={2} align="center" sx={{ opacity: 0.6 }}>
            No active container
          </Typography>
        )}
      </CardContent>
    </Card>
  )
}

ContainerPreviewCard.propTypes = {
  containerPutDisabled: PropTypes.bool.isRequired,
  onContainerPut: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  containerNumber: PropTypes.string
}

export default ContainerPreviewCard
