import { defaultTo, map, path, pipe, split, startsWith } from 'ramda'

export const stringToNumbers = string => {
  return string.split(',').map(el => parseInt(el))
}

export const stringToGuids = pipe(
  defaultTo(''),
  split(',')
)

export const toParamList = (list, params) => {
  return {
    ...list,
    getList: () => list.getList(params)
  }
}

export const toDuration = (string = '') => {
  return string.split('.')[0]
}

export const toObject = object => {
  try {
    return JSON.parse(object)
  } catch {
    return object
  }
}

export const toNamesArray = (
  array,
  keyPath = ['name']
) => pipe(
  defaultTo([]),
  map(path(keyPath))
)(array)

export const toHostnameProtocol = (urlName = '') => {
  if (!urlName) {
    return ''
  }

  const urlWithProtocol = startsWith('http://', urlName) || startsWith('https://', urlName)
  const newUrlName = urlWithProtocol ? urlName : `https://${urlName}`

  const url = document.createElement('a')
  url.href = newUrlName
  const protocol = url.protocol

  return `${protocol}//${url.hostname}`
}
