import React from 'react'
import { useTranslation } from 'react-i18next'
import { Divider, Grid } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import PropTypes from 'prop-types'

import { CardHeader } from '~/components/Cards'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import { withForm } from '~/components/Form'
import { DiscardButton } from '~/components/Buttons'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import TextField from '~/components/Fields/TextField'
import WarehouseSearchField from '~/components/Fields/WarehouseSearchField'
import * as API from '~/constants/api'

import LineItems from './LineItems'

import MovementSummary from '../../components/MovementSummary'
import MovementCreateButton from '../MovementCreateButton'

function MovementBrowseCreateForm ({ form, movementUnitList, isLoading }) {
  const { t } = useTranslation()

  const { handleSubmit } = form

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            parentTitle={NAV.MOVEMENT}
            pageTitle={t('New movement')}
            rightButton={
              <>
                <DiscardButton to={ROUTES.MOVEMENT_LIST_PATH} />
                <MovementCreateButton
                  onSubmit={handleSubmit}
                  isLoading={isLoading}
                />
              </>
            }
          />
        </Header>

        <Content>
          <Grid container={true} spacing={3}>
            <Grid item={true} lg={4}>
              <Card>
                <CardHeader title={t('Move in')} />
                <Divider />
                <CardContent>
                  <Grid container={true} spacing={3}>
                    <Grid item={true} lg={12}>
                      <WarehouseSearchField api={API.WAREHOUSE_LIST} required={true} />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item={true} lg={12}>
              <Card>
                <CardHeader title={t('Stock')} required={true} />
                <Divider />
                <LineItems movementUnitList={movementUnitList} />
              </Card>
            </Grid>
            <Grid item={true} lg={12}>
              <Grid container={true} spacing={3}>
                <Grid item={true} lg={6}>
                  <Card>
                    <CardHeader title={t('Summary')} />
                    <Divider />
                    <MovementSummary />
                  </Card>
                </Grid>
                <Grid item={true} lg={6}>
                  <Card>
                    <CardHeader title={t('Notes')} />
                    <Divider />
                    <CardContent>
                      <TextField
                        label="Notes"
                        name="notes"
                        multiline={true}
                        minRows={11}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Content>
      </Container>
    </form>
  )
}

MovementBrowseCreateForm.propTypes = {
  form: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  movementUnitList: PropTypes.object.isRequired
}

export default withForm(MovementBrowseCreateForm)
