import React from 'react'
import { useSnackbar } from 'storfox-snackbar'
import { generatePath, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { prop } from 'ramda'
import { useAllSearchParams } from 'storfox-route-hooks'

import { DashboardLayout } from '~/components/Layouts'
import { ACTIVE } from '~/components/Fields/StatusField'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import useMessages from '~/hooks/useMessages'
import { INTEGRATION_TABS } from '~/constants/tabs'

import { useSallaIntegrationCreate } from '../../hooks'
import SallaIntegrationCreateForm from '../../components/Salla/SallaCreate/SallaIntegrationCreateForm'

function SallaIntegrationCreateContainer () {
  const { t } = useTranslation()
  const params = useAllSearchParams()

  const messages = useMessages()
  const snackbar = useSnackbar()
  const navigate = useNavigate()

  const integrationCreate = useSallaIntegrationCreate()

  const channelAccessCode = prop('code', params)
  const appGuid = prop('appGuid', params)

  const title = 'New Integration'

  const handleSubmit = formValues =>
    integrationCreate
      .create(formValues)
      .then(({ result }) => {
        const guid = prop('guid', result)
        const type = prop('type', result)
        const params = { guid, type, tab: INTEGRATION_TABS.GENERAL }
        const detailPath = generatePath(ROUTES.INTEGRATION_UPDATE_PATH, params)
        navigate(detailPath)
      })
      .then(() => snackbar({ message: messages.CREATE_SUCCESS }))

  const initialValues = {
    status: ACTIVE,
    code: channelAccessCode,
    app: { guid: appGuid },
    type: 'salla'
  }

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={t(title)}
      isLoading={integrationCreate.isLoading}
      activeNav={NAV.INTEGRATION}
      breadcrumbs={breadcrumbs}
    >
      <SallaIntegrationCreateForm
        onSubmit={handleSubmit}
        initialValues={initialValues}
        pageTitle={t(title)}
        parentTitle={t(NAV.SETTINGS)}
        isLoading={integrationCreate.isLoading}
      />
    </DashboardLayout>
  )
}

export default SallaIntegrationCreateContainer
