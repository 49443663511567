import React from 'react'
import { useField } from 'react-final-form'
import { useDeepCompareEffect } from 'storfox-api-hooks'
import PropTypes from 'prop-types'

import * as API from '~/constants/api'
import SearchField from '~/components/Fields/SearchField'

const getOptionValue = value => {
  const { id, guid, name, label } = value
  return { id, guid, name, label }
}

function ZoneActionSearchField ({ name, parentName, ...props }) {
  const field = useField(name)
  const parentField = useField(parentName)
  const parent = parentField.input.value

  useDeepCompareEffect(() => {
    if (parentField.meta.dirty) {
      field.input.onChange(null)
    }
  }, [parent])

  return (
    <SearchField
      getOptionValue={getOptionValue}
      api={API.ZONE_LIST}
      disabled={!parent}
      data-cy="zones"
      name={name}
      label="Zones"
      ListboxProps={{ 'data-cy': 'zoneList' }}
      {...props}
    />
  )
}

ZoneActionSearchField.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired
}

export default ZoneActionSearchField
