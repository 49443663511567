import React from 'react'
import PropTypes from 'prop-types'
import DialogContent from '@mui/material/DialogContent'
import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner'
import ListAltIcon from '@mui/icons-material/ListAlt'

import DialogTitle from '~/components/DialogTitle'
import * as ROUTES from '~/constants/routes'
import ChoiceCard, { ChoiceCardLink } from '~/components/Cards/ChoiceCard'

const MODES = [
  {
    name: 'Browse',
    value: 'browse',
    icon: <ListAltIcon />
  },
  {
    name: 'Scan',
    value: 'scan',
    icon: <QrCodeScannerIcon />
  }
]

function MovementWarehouseDialog ({ open, onClose }) {
  const { t } = useTranslation()

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth={true}
      onClose={onClose}
    >
      <DialogTitle>{t('Choose mode')}</DialogTitle>

      <DialogContent dividers={true}>
        <Grid container={true} spacing={3}>
          {MODES.map(({ name, value, icon }) => {
            const isScanMode = value === 'scan'
            const updatePath = isScanMode
              ? ROUTES.MOVEMENT_SCAN_CREATE_PATH
              : ROUTES.MOVEMENT_BROWSE_CREATE_PATH

            return (
              <Grid
                key={value}
                item={true}
                xs={12}
                lg={6}
                data-cy={value}
              >
                <ChoiceCard>
                  <ChoiceCardLink to={updatePath}>
                    {icon}
                    <Typography ml={1} variant="h5">{name}</Typography>
                  </ChoiceCardLink>
                </ChoiceCard>
              </Grid>
            )
          })}
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

MovementWarehouseDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default MovementWarehouseDialog
