import React from 'react'

import * as API from '~/constants/api'
import SearchField from '~/components/Fields/SearchField'

function CompanySearchField ({ ...props }) {
  return (
    <SearchField
      label="Company"
      data-cy="company"
      name="company"
      api={API.VARIANT_COMPANIES_LIST}
      ListboxProps={{ 'data-cy': 'companyList' }}
      {...props}
    />
  )
}

export default CompanySearchField
