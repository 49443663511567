import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import { Divider } from '@mui/material'
import CardContent from '@mui/material/CardContent'
import { useTranslation } from 'react-i18next'
import { path, prop, propOr } from 'ramda'
import PropTypes from 'prop-types'

import { CardHeader } from '~/components/Cards'
import TextLabelLoader from '~/components/TextLabelLoader'
import TableDateFormat from '~/components/TableDateFormat'

import CustomerNotes from './CustomerNotes'
import InvoiceSummary from './InvoiceSummary'
import LineItems from './LineItems'

function TransferContent ({ content, isLoading }) {
  const { t } = useTranslation()

  const fromWarehouse = path(['fromWarehouse', 'name'], content)
  const toWarehouse = path(['toWarehouse', 'name'], content)
  const transferDate = prop('transferDate', content)
  const expectedDate = prop('expectedDate', content)
  const lineItems = propOr([], 'lineItems', content)
  const notes = prop('notes', content)

  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12}>
        <Box>
          <Card>
            <CardHeader title={t('Details')} />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12} lg={3}>
                  <TextLabelLoader label="From warehouse">
                    {fromWarehouse}
                  </TextLabelLoader>
                </Grid>
                <Grid item={true} xs={12} lg={3}>
                  <TextLabelLoader label="toWarehouse">
                    {toWarehouse}
                  </TextLabelLoader>
                </Grid>
                <Grid item={true} xs={12} lg={3}>
                  <TextLabelLoader label="Transfer date">
                    <TableDateFormat date={transferDate} />
                  </TextLabelLoader>
                </Grid>
                <Grid item={true} xs={12} lg={3}>
                  <TextLabelLoader label="Expected Date">
                    <TableDateFormat date={expectedDate} />
                  </TextLabelLoader>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>

      </Grid>
      <Grid item={true} xs={12}>
        <Card>
          <CardHeader title={t('Stock')} />
          <Divider />
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12}>
              <LineItems isLoading={isLoading} lineItems={lineItems} />
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item={true} xs={12} lg={6}>
        <Card style={{ height: '100%' }}>
          <CardHeader title="Transfer summary" />
          <Divider />
          <InvoiceSummary lineItems={lineItems} />
        </Card>
      </Grid>
      <Grid item={true} xs={12} lg={6}>
        <Card>
          <CardHeader title={t('Notes')} />
          <Divider />
          <CardContent>
            <Grid container={true} spacing={3}>
              <Grid item={true} xs={12}>
                <CustomerNotes notes={notes} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

TransferContent.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  content: PropTypes.object.isRequired
}

export default TransferContent
