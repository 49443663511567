import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { prop } from 'ramda'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'

import BarcodeInfo from '~/components/Icons/BarcodeInfo'

const RootStyled = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '24px',
  marginTop: '12px',
  '& span': {
    marginLeft: '14px',
    color: '#263238',
    fontSize: '24px',
    fontWeight: 500
  }
})

function BarcodeTitle ({ destination, isBarcodeMode, scanSerial }) {
  const scanTitle = useMemo(() => {
    const locationId = prop('locationId', destination)
    const containerCode = prop('containerCode', destination)

    const shouldScanContainer = !isBarcodeMode && locationId && !containerCode
    const shouldScanLocation = !isBarcodeMode && !locationId

    if (scanSerial) {
      return 'Scan serial number'
    }
    if (isBarcodeMode) {
      return 'Scan unit'
    }
    if (shouldScanLocation) {
      return 'Scan location'
    }
    if (shouldScanContainer) {
      return 'Scan container or DONE'
    }

    return 'Scan DONE'
  }, [destination, isBarcodeMode, scanSerial])

  return (
    <RootStyled>
      <BarcodeInfo />
      <span>{scanTitle}</span>
    </RootStyled>
  )
}

BarcodeTitle.propTypes = {
  destination: PropTypes.object.isRequired,
  isBarcodeMode: PropTypes.bool.isRequired,
  scanSerial: PropTypes.bool.isRequired
}

export default BarcodeTitle
