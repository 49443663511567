import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { path, prop } from 'ramda'
import PropTypes from 'prop-types'

import TextLabelLoader from '~/components/TextLabelLoader'
import { CardHeader } from '~/components/Cards'
import MultiImagePreview from '~/components/ImageUpload/MultiImagePreview'

function ListingVariantDetail ({ detail }) {
  const name = path(['variant', 'name'], detail)
  const type = prop('type', detail)
  const description = path(['variant', 'description'], detail)
  const slug = path(['variant', 'slug'], detail)
  const sku = path(['variant', 'sku'], detail)
  const channel = path(['channel', 'name'], detail)
  const conditonPrices = path(['variant', 'conditionPrices', 0], detail)
  const retailPrice = prop('retailPrice', conditonPrices)
  const maximumRetailPrice = prop('maximumRetailPrice', conditonPrices)
  const conditionCode = path(['condition', 'code'], conditonPrices)
  const imagePath = path(['variant', 'imagePath'], detail)
  const status = path(['variant', 'status'], detail)

  return (
    <Box mt={3} mb={3}>
      <Grid container={true} spacing={3}>
        <Grid item={true} xs={12} lg={8}>
          <Box mb={3}>
            <Card>
              <CardHeader title="Details" />
              <Divider />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={12}>
                    <TextLabelLoader label="Name">
                      {name}
                    </TextLabelLoader>
                  </Grid>

                  <Grid item={true} xs={12}>
                    <TextLabelLoader label="Slug">
                      {slug}
                    </TextLabelLoader>
                  </Grid>

                  <Grid item={true} xs={12}>
                    <TextLabelLoader label="Type">
                      {type}
                    </TextLabelLoader>
                  </Grid>

                  <Grid item={true} xs={12}>
                    <TextLabelLoader label="Description">
                      {description}
                    </TextLabelLoader>
                  </Grid>

                </Grid>
              </CardContent>
            </Card>
          </Box>
          <Box mb={3}>
            <Card>
              <CardHeader title="Image" />
              <Divider />
              <CardContent>
                <MultiImagePreview images={imagePath} alt="Image" />
              </CardContent>
            </Card>
          </Box>
        </Grid>
        <Grid item={true} xs={12} lg={4}>
          <Box mb={3}>
            <Card>
              <CardHeader title="Information" />
              <Divider />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={12} lg={12}>
                    <TextLabelLoader label="SKU">
                      {sku}
                    </TextLabelLoader>
                  </Grid>

                  <Grid item={true} xs={12} lg={12}>
                    <TextLabelLoader label="Channel">
                      {channel}
                    </TextLabelLoader>
                  </Grid>
                  <Grid item={true} xs={12} lg={12}>
                    <TextLabelLoader label="Status">
                      {status}
                    </TextLabelLoader>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
          <Box mb={3}>
            <Card>
              <CardHeader title={(
                <>
                  Pricing <br />
                  <Typography variant="caption">Default condition: {conditionCode}</Typography>
                </>
              )} />
              <Divider />
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={12}>
                    <TextLabelLoader label="Retail price">
                      {retailPrice}
                    </TextLabelLoader>
                  </Grid>
                  <Grid item={true} xs={12}>
                    <TextLabelLoader label="Maximum Retail price">
                      {maximumRetailPrice}
                    </TextLabelLoader>
                  </Grid>

                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Grid>

      </Grid>
    </Box>
  )
}

ListingVariantDetail.propTypes = {
  detail: PropTypes.object.isRequired
}

export default ListingVariantDetail
