import React from 'react'
import { defaultTo, filter, includes, length, map, path, pathEq, pathOr, pipe, prop, propOr } from 'ramda'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import IconButton from '@mui/material/IconButton'
import Delete from '@mui/icons-material/Delete'
import { useField } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { useTranslation } from 'react-i18next'
import { PositiveFloatField } from 'storfox-form-fields'

import Avatar from '~/components/Avatar/Avatar'
import Money from '~/components/Money'
import VerticalAlignment from '~/components/VerticalAlignment'
import TextOverflow from '~/components/TextOverflow'
import Subtext from '~/components/Subtext'
import PositiveNumberField from '~/components/Fields/PositiveNumberField'
import VariantsAutoClearField from '~/components/Fields/VariantsAutoClearField'
import EmptyRow from '~/components/EmptyRow'
import * as API from '~/constants/api'

import ConditionField from './Fields/ConditionField'
import BundleMoney from './BundleMoney'

function OrderCreateFormLineItems () {
  const { t } = useTranslation()

  const lineItemsField = useField('lineItems')
  const lineItemsValue = lineItemsField.input.value

  const getConditionFromLineItems = variantGuid => pipe(
    defaultTo([]),
    filter(pathEq(['variant', 'guid'], variantGuid)),
    map(item => path(['condition', 'guid'], item)),
  )(lineItemsValue)

  const getLineItem = (lineItems, variantGuid) => pipe(
    defaultTo([]),
    map(item => {
      const guid = prop('guid', item)
      const existingCondition = includes(guid, getConditionFromLineItems(variantGuid))

      return !existingCondition && item
    }),
    filter(item => item),
    map(({ code, ...rest }) => ({ ...rest, name: code })),
    prop(0)
  )(lineItems)

  return (
    <FieldArray name="lineItems">
      {({ fields, meta }) => {
        const info = !length(propOr([], 'value', fields))

        const push = value => {
          const guid = prop('guid', value)
          const conditions = prop('conditions', value)
          const condition = getLineItem(conditions, guid)

          fields.push({
            variant: value,
            quantity: 1,
            price: propOr(0, 'retailPrice', value),
            condition
          })
        }

        return (
          <>
            <VariantsAutoClearField
              primaryKey="guid"
              push={push}
              error={meta.invalid}
              helperText={meta.submitError}
              api={API.BUNDLE_VARIANT_LIST}
              params={{ limit: 30 }}
              ListboxProps={{ 'data-cy': 'variantList' }}
            />

            <br />

            <TableContainer>
              <Table size="small" sx={{ minWidth: '700px' }}>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('Image')}</TableCell>
                    <TableCell sx={{ width: '250px' }}>{t('Product')}</TableCell>
                    <TableCell>{t('Unit price')}</TableCell>
                    <TableCell sx={{ width: '200px' }}>{t('Condition code')}</TableCell>
                    <TableCell sx={{ width: '200px' }}>{t('Discount')}</TableCell>
                    <TableCell sx={{ width: '100px' }}>{t('Qty')}</TableCell>
                    <TableCell>{t('Total')}</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fields.map((name, index) => {
                    const value = prop('value', fields)
                    const item = prop(index, value)
                    const variantGuid = path(['variant', 'guid'], item)
                    const variantName = path(['variant', 'name'], item)
                    const sku = path(['variant', 'sku'], item)
                    const imagePath = path(['variant', 'defaultImage'], item)
                    const unitCost = pathOr(0, ['variant', 'retailPrice'], item)
                    const quantity = propOr(0, 'quantity', item)
                    const discount = propOr(0, 'discount', item)
                    const unitDiscount = unitCost * (1 - discount / 100)
                    const discountPrice = unitDiscount < 0 ? 0 : unitDiscount
                    const totalPriceValue = quantity * unitCost * (1 - discount / 100)
                    const totalPrice = totalPriceValue < 0 ? 0 : totalPriceValue

                    return (
                      <TableRow key={index}>
                        <TableCell>
                          <Avatar
                            alt={variantName}
                            src={imagePath}
                          />
                        </TableCell>
                        <TableCell>
                          <VerticalAlignment
                            sx={{ minWidth: '200px' }}
                            primary={(
                              <TextOverflow selfTooltip={true}>
                                {variantName}
                              </TextOverflow>
                            )}
                            secondary={<Subtext lines={1}>{sku}</Subtext>}
                          />
                        </TableCell>
                        <TableCell>
                          <BundleMoney
                            name={`${name}.price`}
                            conditionFieldName={`${name}.condition`}
                            value={unitCost}
                            discountPrice={discountPrice}
                          />
                        </TableCell>
                        <TableCell>
                          <ConditionField
                            name={`${name}.condition`}
                            variantGuid={variantGuid}
                            ListboxProps={{ 'data-cy': 'conditionList' }}
                          />
                        </TableCell>
                        <TableCell sx={{ width: 150 }}>
                          <PositiveFloatField
                            data-cy={`${name}.discount`}
                            name={`${name}.discount`}
                            inputProps={{ maxLength: 5 }}
                            InputProps={{
                              endAdornment: (
                                <span>%</span>
                              ) }
                            }
                          />
                        </TableCell>
                        <TableCell sx={{ width: 150 }}>
                          <PositiveNumberField
                            data-cy={`${name}.quantity`}
                            name={`${name}.quantity`}
                            inputProps={{ maxLength: 3 }}
                          />
                        </TableCell>
                        <TableCell>
                          <Money value={totalPrice} />
                        </TableCell>
                        <TableCell align="right">
                          <IconButton onClick={() => fields.remove(index)}>
                            <Delete />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                  {info && <EmptyRow colSpan={7} />}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )
      }}
    </FieldArray>
  )
}

export default OrderCreateFormLineItems
