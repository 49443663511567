import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { prop } from 'ramda'
import Divider from '@mui/material/Divider'
import { generatePath, Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import PropTypes from 'prop-types'

import { CardHeader } from '~/components/Cards'
import TableDateFormat from '~/components/TableDateFormat'
import * as ROUTES from '~/constants/routes'

import { RETURN_TYPE } from '../../constants'

function OrderCard ({ detail }) {
  const order = prop('order', detail)
  const guid = prop('guid', order) || 'unknown'

  const number = prop('number', order)
  const type = prop('returnType', order)
  const method = prop('paymentMethod', order)
  const returnTo = prop('returnTo', order)
  const orderDate = prop('createdAt', order)
  const path = generatePath(ROUTES.SALE_ORDER_DETAIL_PATH, { guid, tab: 'detail' })

  return (
    <Card>
      <CardHeader title="Order" />
      <Divider />
      <CardContent style={{ padding: 0 }}>
        {number && (
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell>
                  Sale order:
                </TableCell>
                <TableCell>
                  <Link
                    component={RouterLink}
                    to={path}
                    underline="always"
                  >
                    {number}
                  </Link>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  Return type:
                </TableCell>
                <TableCell>
                  {RETURN_TYPE[type]}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  Payment type:
                </TableCell>
                <TableCell>
                  {method}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  Return to:
                </TableCell>
                <TableCell>
                  {returnTo}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  Order date:
                </TableCell>
                <TableCell>
                  <TableDateFormat date={orderDate} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
      </CardContent>
    </Card>
  )
}

OrderCard.propTypes = {
  detail: PropTypes.object.isRequired
}

export default OrderCard
