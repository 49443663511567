import React from 'react'
import { Filter } from 'storfox-filter'
import Grid from '@mui/material/Grid'
import { map, pick, pipe, prop, propOr } from 'ramda'

import { escapeAtob, unescapeBtoa } from '~/utils'
import Accordion from '~/components/Accordion'
import WarehouseMultiSelectField from '~/components/Fields/WarehouseMultiSelectField'
import TextField from '~/components/Fields/TextField'

import CompanyMultiSelectField from './CompanyMultiSelectField'
import CategoryMultiSelectField from './CategoryMultiSelectField'
import ConditionMultiSelectField from './ConditionMultiSelectField'

import { FILTER_NAME as name } from '../../../constants/StockAging'

const getWarehouseGuids = pipe(
  prop('warehouses'),
  map(pick(['guid', 'name']))
)

const getCompanyGuids = pipe(
  prop('company'),
  map(pick(['guid', 'name']))
)

const getCategoryGuids = pipe(
  prop('category'),
  map(pick(['guid', 'name']))
)

const getConditionGuids = pipe(
  prop('condition'),
  map(pick(['guid', 'name']))
)

const getRangeParam = (fromDefault, toDefault, index, values) => {
  const range1From = propOr(fromDefault, `range${index}From`, values)
  const range1To = propOr(toDefault, `range${index}To`, values)
  return `${range1From}-${range1To}`
}

const getRangeValue = (defaultValue, index, params) => {
  const range = propOr(defaultValue, `range${index}`, params)
  const rangeSplit = range.split('-')
  const rangeFrom = rangeSplit[0]
  const rangeTo = rangeSplit[1]
  return [rangeFrom, rangeTo]
}

export const toParams = values => {
  const warehouseList = propOr([], 'warehouses', values)
  const warehouses = warehouseList.length ? unescapeBtoa(getWarehouseGuids(values)) : null
  const companyList = propOr([], 'company', values)
  const company = companyList.length ? unescapeBtoa(getCompanyGuids(values)) : null
  const categoryList = propOr([], 'condition', values)
  const category = categoryList.length ? unescapeBtoa(getCategoryGuids(values)) : null
  const conditionList = propOr([], 'condition', values)
  const condition = conditionList.length ? unescapeBtoa(getConditionGuids(values)) : null

  const range1 = getRangeParam('0', '30', 1, values)
  const range2 = getRangeParam('30', '60', 2, values)
  const range3 = getRangeParam('60', '90', 3, values)
  const range4 = getRangeParam('90', '120', 4, values)
  const range5 = getRangeParam('120', '150', 5, values)
  const range6 = getRangeParam('150', '1000', 6, values)

  return {
    warehouses,
    company,
    category,
    condition,
    range1,
    range2,
    range3,
    range4,
    range5,
    range6
  }
}

export const toValues = params => {
  const warehouses = escapeAtob(prop('warehouses', params))
  const company = escapeAtob(prop('company', params))
  const category = escapeAtob(prop('category', params))
  const condition = escapeAtob(prop('condition', params))

  const [range1From, range1To] = getRangeValue('0-30', 1, params)
  const [range2From, range2To] = getRangeValue('30-60', 2, params)
  const [range3From, range3To] = getRangeValue('60-90', 3, params)
  const [range4From, range4To] = getRangeValue('90-120', 4, params)
  const [range5From, range5To] = getRangeValue('120-150', 5, params)
  const [range6From, range6To] = getRangeValue('150-1000', 6, params)

  return {
    warehouses,
    company,
    category,
    condition,
    range1From,
    range1To,
    range2From,
    range2To,
    range3From,
    range3To,
    range4From,
    range4To,
    range5From,
    range5To,
    range6From,
    range6To
  }
}
export const transformer = { toParams, toValues }

export const stockAgingFilterOptions = {
  name,
  transformer
}

function StockAgingFilterForm (props) {
  return (
    <Filter {...props}>
      <Accordion title="General" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <WarehouseMultiSelectField />
          </Grid>
          <Grid item={true} xs={12}>
            <CompanyMultiSelectField />
          </Grid>
          <Grid item={true} xs={12}>
            <CategoryMultiSelectField />
          </Grid>
          <Grid item={true} xs={12}>
            <ConditionMultiSelectField />
          </Grid>
        </Grid>
      </Accordion>
      <Accordion title="Ranges">
        <Grid container={true} spacing={2} mb={2}>
          <Grid item={true} xs={6}>
            <TextField
              name="range1From"
              label="Range 1 from"
            />
          </Grid>
          <Grid item={true} xs={6}>
            <TextField
              name="range1To"
              label="Range 1 to"
            />
          </Grid>
        </Grid>
        <Grid container={true} spacing={2} mb={2}>
          <Grid item={true} xs={6}>
            <TextField
              name="range2From"
              label="Range 2 from"
            />
          </Grid>
          <Grid item={true} xs={6}>
            <TextField
              name="range2To"
              label="Range 2 to"
            />
          </Grid>
        </Grid>
        <Grid container={true} spacing={2} mb={2}>
          <Grid item={true} xs={6}>
            <TextField
              name="range3From"
              label="Range 3 from"
            />
          </Grid>
          <Grid item={true} xs={6}>
            <TextField
              name="range3To"
              label="Range 3 to"
            />
          </Grid>
        </Grid>
        <Grid container={true} spacing={2} mb={2}>
          <Grid item={true} xs={6}>
            <TextField
              name="range4From"
              label="Range 4 from"
            />
          </Grid>
          <Grid item={true} xs={6}>
            <TextField
              name="range4To"
              label="Range 4 to"
            />
          </Grid>
        </Grid>
        <Grid container={true} spacing={2} mb={2}>
          <Grid item={true} xs={6}>
            <TextField
              name="range5From"
              label="Range 5 from"
            />
          </Grid>
          <Grid item={true} xs={6}>
            <TextField
              name="range5To"
              label="Range 5 to"
            />
          </Grid>
        </Grid>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={6}>
            <TextField
              name="range6From"
              label="Range 6 from"
            />
          </Grid>
          <Grid item={true} xs={6}>
            <TextField
              name="range6To"
              label="Range 6 to"
            />
          </Grid>
        </Grid>
      </Accordion>
    </Filter>
  )
}

export default StockAgingFilterForm
