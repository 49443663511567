import { useCallback, useEffect, useState } from 'react'

const ON = 'on'
const OFF = 'off'

function useScannerMode (action) {
  const [scannerMode, setScannerMode] = useState(false)
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    const valueFromStorage = localStorage.getItem('scannerMode') === ON
    setScannerMode(valueFromStorage)
  }, [])

  useEffect(() => {
    if (scannerMode) {
      action()
      setTimeout(() => setRefresh(!refresh), 100)
    }
  }, [scannerMode, refresh, action])

  const onChangeScannerMode = useCallback(() => {
    const newMode = !scannerMode
    setScannerMode(newMode)
    localStorage.setItem('scannerMode', newMode ? ON : OFF)
  }, [scannerMode, setScannerMode])

  return { scannerMode, onChangeScannerMode }
}

export default useScannerMode
