import React from 'react'
import { Ordering } from 'storfox-filter'

import { RETURN_COLUMNS as tableColumns } from './ReturnTable'

import { FILTER_NAME as name } from '../../constants'

export const returnOrderingOptions = {
  name,
  tableColumns
}

function ReturnOrderingForm (props) {
  return (
    <Ordering {...props} />
  )
}

export default ReturnOrderingForm
