import React from 'react'
import Grid from '@mui/material/Grid'
import { useFieldArray } from 'react-final-form-arrays'
import equal from 'fast-deep-equal'
import { find, pick } from 'ramda'

import DeliveryAddressFields from './DeliveryAddressFields'
import BillingAddressFields from './BillingAddressFields'

export const ADDRESS_FIELDS = [
  'firstName',
  'lastName',
  'mobile',
  'address',
  'region',
  'city',
  'zipcode',
  'country'
]

function CustomerUpdateFormAddress () {
  const deliveryAddressesField = useFieldArray('deliveryAddresses')
  const billingAddressesField = useFieldArray('billingAddresses')

  const handleAddressCopy = index => {
    const deliveryAddress = deliveryAddressesField.fields.value[index]
    const billingAddresses = billingAddressesField.fields.value

    const deliveryAddressFields = pick(ADDRESS_FIELDS, deliveryAddress)

    const alreadyCopied = find(billingAddress => {
      const billingAddressFields = pick(ADDRESS_FIELDS, billingAddress)

      return equal(deliveryAddressFields, billingAddressFields)
    }, billingAddresses)

    if (!alreadyCopied) {
      billingAddressesField.fields.push(deliveryAddressFields)
    }
  }

  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12} lg={6}>
        <DeliveryAddressFields
          title="Shipping address"
          name="deliveryAddresses"
          onCopy={handleAddressCopy}
        />
      </Grid>
      <Grid item={true} xs={12} lg={6}>
        <BillingAddressFields
          title="Billing address"
          name="billingAddresses"
        />
      </Grid>
    </Grid>
  )
}

export default CustomerUpdateFormAddress
