import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import InventoryLogListContainer from './containers/InventoryLogListContainer'

export default () => [
  {
    layout: AuthLayout,
    path: ROUTES.INVENTORY_LOG_LIST_PATH,
    component: InventoryLogListContainer
  }
]
