import React from 'react'
import Grid from '@mui/material/Grid'
import PropTypes from 'prop-types'

import SupplierAddresses from './SupplierAddresses'

function SupplierAddress ({ deliveryAddresses, billingAddresses }) {
  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12} lg={6}>
        <SupplierAddresses
          title="Shipping address"
          addresses={deliveryAddresses}
        />
      </Grid>
      <Grid item={true} xs={12} lg={6}>
        <SupplierAddresses
          title="Billing address"
          addresses={billingAddresses}
        />
      </Grid>
    </Grid>
  )
}

SupplierAddress.propTypes = {
  billingAddresses: PropTypes.array.isRequired,
  deliveryAddresses: PropTypes.array.isRequired
}

SupplierAddress.defaultProps = {
  billingAddresses: [],
  deliveryAddresses: []
}

export default SupplierAddress
