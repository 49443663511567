import PropTypes from 'prop-types'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import MuiLink from '@mui/material/Link'

import { getSxArray } from '~/utils'

function TableLink ({ children, to, sx, ...props }) {
  return (
    <MuiLink
      component={RouterLink}
      to={to}
      underline="always"
      color="primary"
      data-cy="table-link"
      sx={[
        {
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          color: theme => theme.palette.text.primary,
          textDecorationColor: theme => theme.palette.text.primary
        },
        ...getSxArray(sx)
      ]}
      {...props}
    >
      {children}
    </MuiLink>
  )
}

TableLink.propTypes = {
  children: PropTypes.any,
  to: PropTypes.string.isRequired,
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
}

export default TableLink
