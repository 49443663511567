import React from 'react'
import PropTypes from 'prop-types'

import Status from '~/components/Status'

export const DRAFT = 'draft'
export const COMPLETED = 'completed'
export const CANCELLED = 'cancelled'

const NAMES = {
  [DRAFT]: 'Draft',
  [COMPLETED]: 'Completed',
  [CANCELLED]: 'Cancelled'
}

const COLORS = {
  [DRAFT]: 'warning',
  [COMPLETED]: 'success',
  [CANCELLED]: 'error'
}

function MovementStatus ({ value }) {
  return (
    <Status label={NAMES[value]} color={COLORS[value]} />
  )
}

export default MovementStatus

MovementStatus.propTypes = {
  value: PropTypes.string
}
