import auth from './auth'
import notification from './notification'
import dashboard from './dashboard'
import reports from './reports'
import purchases from './purchases'
import fulfillment from './fulfillment'
import sales from './sales'
import crossDocks from './cross-docks'
import catalog, { catalogMarketplace, catalogRetailer, catalogThreepl } from './catalog'
import stock from './stock'
import { retailerWarehouseRoutes, warehouseRoutes } from './warehouse'
import settings from './settings'
import error from './error'

export default () => [
  ...auth(),
  ...notification(),
  ...dashboard(),
  ...reports(),
  ...purchases(),
  ...fulfillment(),
  ...crossDocks(),
  ...catalog(),
  ...stock(),
  ...settings(),
  ...sales(),
  ...error(),
]

export const retailerRoutes = () => [
  ...catalogRetailer(),
  ...retailerWarehouseRoutes()
]

export const marketplaceRoutes = () => [
  ...catalogMarketplace(),
  ...warehouseRoutes(),
]

export const threeplRoutes = () => [
  ...catalogThreepl(),
  ...warehouseRoutes(),
]
