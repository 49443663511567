import React from 'react'
import PropTypes from 'prop-types'

import * as API from '~/constants/api'
import SearchField from '~/components/Fields/SearchField'

const defaultGetOptionValue = value => {
  if (value) {
    const { id, guid, name, length, width, height, maxLoad, volume } = value
    return { id, guid, name, length, width, height, maxLoad, volume }
  }

  return null
}

function ContainerTypeField (props) {
  return (
    <SearchField
      data-cy="containerType"
      name="containerType"
      label="Container type"
      api={API.CONTAINER_TYPE_LIST}
      getOptionValue={defaultGetOptionValue}
      {...props}
    />
  )
}

ContainerTypeField.propTypes = {
  onChange: PropTypes.func
}

export default ContainerTypeField
