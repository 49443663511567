import React from 'react'
import { useFilter, useOrdering } from 'storfox-filter'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import ErrorLink from '~/components/ErrorLink'
import useMessages from '~/hooks/useMessages'

import { useUnitList, useUnitReportGenerate } from '../../hooks/Inventory'
import UnitList from '../../components/Inventory/InventoryList'
import UnitOrderingForm, { inventoryOrderingOptions } from '../../components/Inventory/InventoryOrderingForm'
import UnitFilterForm, { inventoryFilterOptions } from '../../components/Inventory/InventoryFilterForm'

function InventoryListContainer () {
  const messages = useMessages()

  const unitList = useUnitList()
  const inventoryReportGenerate = useUnitReportGenerate()

  const snackbar = useSnackbar()

  const filter = useFilter(inventoryFilterOptions)
  const ordering = useOrdering(inventoryOrderingOptions)

  const handleReportGenerate = () =>
    inventoryReportGenerate.generate()
      .then(() => snackbar({ message: messages.NOTIFICATION_WAIT }))
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const breadcrumbs = { title: NAV.INVENTORY_REPORT }

  return (
    <DashboardLayout
      title={NAV.INVENTORY_REPORT}
      activeNav={NAV.REPORTS}
      breadcrumbs={breadcrumbs}
    >

      <UnitFilterForm {...filter} />
      <UnitOrderingForm {...ordering} />

      <UnitList
        list={unitList}
        filter={filter}
        ordering={ordering}
        onListRefetch={unitList.getListByParams}
        handleOpenFilter={filter.handleOpen}
        onReportGenerate={handleReportGenerate}
      />
    </DashboardLayout>
  )
}

export default InventoryListContainer
