import React, { useCallback, useMemo } from 'react'
import { flatten, map, pathOr, pipe, prop, propOr } from 'ramda'
import { useField } from 'react-final-form'
import { useFieldArray } from 'react-final-form-arrays'
import { useValueChanged } from 'storfox-tools'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import uuidv4 from 'uuid/v4'
import { useParams } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import Grid from '@mui/material/Grid'

import TextField from '~/components/Fields/TextField'
import { emptyArray } from '~/constants/empty'
import { Button } from '~/components/Buttons'
import { NAMES } from '~/constants/barcodingStrategies'
import useDialog from '~/hooks/useDialog'
import { useCompany } from '~/components/Profile'

import AdjustmentVariantAddDialog from '../AdjustmentVariantAddDialog/AdjustmentVariantAddDialog'
import ItemsTable from '../ItemsTable'

const REMOVE = 'remove'
const ADD_TYPE = 'adjustment_add'
const REMOVE_TYPE = 'adjustment_remove'

const getUnitsFromLineItems = pipe(
  map(propOr([], 'units')),
  flatten
)

export const getLineItem = item => {
  const variant = prop('variant', item)
  const variantName = prop('name', variant)
  const image = prop('defaultImage', variant)
  const trackExpiry = prop('trackExpiryDates', variant)
  const sku = prop('sku', variant)
  const barcodingStrategy = NAMES[prop('barcodingStrategy', variant)]
  const product = { variantName, sku }
  const price = prop('price', item)
  const containerNumber = prop('containerNumber', item)
  const location = prop('location', item)
  const expiryDate = prop('expiryDate', item)
  const productionDate = prop('productionDate', item)
  const units = propOr([], 'units', item)

  return {
    sku,
    image,
    variant,
    variantName,
    product,
    location,
    price,
    units,
    unitNumber: item,
    expiryDate,
    trackExpiry,
    containerNumber,
    barcodingStrategy,
    productionDate
  }
}

function AdjustmentUpdateLineItems ({ unitFieldMethods, adjustmentVariant }) {
  const { id } = useParams()
  const { company } = useCompany()
  const { handleClose, handleOpen, open } = useDialog()

  const companyId = prop('id', company)

  const conditionField = useField('condition')
  const conditionValue = conditionField.input.value
  const condition = conditionValue || null

  const reasonField = useField('reason')
  const reason = reasonField.input.value
  const reasonOperation = prop('operation', reason)
  const reasonId = prop('id', reason)
  const removeOperation = reasonOperation === REMOVE

  const warehouseField = useField('warehouse')
  const warehouse = warehouseField.input.value
  const warehouseId = prop('id', warehouse)
  const warehouseGuid = prop('guid', warehouse)

  const { fields } = useFieldArray('lineItems')
  const lineItemsField = useField('lineItems')
  const lineItemsFieldError = pathOr('', ['meta', 'submitError', 0], lineItemsField)
  const lineItemsOnChange = lineItemsField.input.onChange
  const lineItems = lineItemsField.input.value || emptyArray
  const units = getUnitsFromLineItems(lineItems)

  const ownerField = useField('owner')
  const ownerValue = ownerField.input.value
  const ownerId = propOr(companyId, 'id', ownerValue)
  const ownerCompanyId = prop('id', ownerValue)

  const searchField = useField('search')
  const searchFieldChange = searchField.input.onChange

  const paramValues = { limit: 30, companyId: ownerCompanyId, type: ADD_TYPE }

  const params = removeOperation
    ? { ...paramValues, type: REMOVE_TYPE, availableWarehouseId: warehouseId }
    : paramValues

  useValueChanged(() => lineItemsOnChange([]), warehouseId)
  useValueChanged(() => lineItemsOnChange([]), reasonOperation)
  useValueChanged(() => ownerField.meta.dirty && lineItemsOnChange([]), ownerId)

  const extraData = {
    id: id ? parseInt(id) : null,
    units,
    ownerId,
    reasonId,
    condition,
    warehouseId,
    warehouseGuid,
    removeOperation
  }

  const unitFieldFunctions = useMemo(() => unitFieldMethods, [unitFieldMethods])

  const handleRemove = useCallback(fields.remove, [])

  const handleAddVariants = useCallback(variants => {
    const newVariants = variants.map(variant => {
      const guid = uuidv4()
      const price = prop('supplyPrice', variant)
      const lineItem = getLineItem({ variant })
      return { guid, ...lineItem, quantity: 1, price }
    })

    lineItemsOnChange([...newVariants, ...lineItems])
    handleClose()
  }, [handleClose, lineItems, lineItemsOnChange])

  const handleOnSearch = useCallback(value => {
    searchFieldChange(value)
    handleOpen()
  }, [handleOpen, searchFieldChange])

  const handleCloseSearch = useCallback(() => {
    searchFieldChange('')
    handleClose()
  }, [handleClose, searchFieldChange])

  useValueChanged(() => {
    return lineItemsOnChange([])
  }, warehouseId)

  useValueChanged(() => {
    return lineItemsOnChange([])
  }, reasonOperation)

  const disabledField = !warehouseId || !reasonOperation

  return (
    <>
      <Box sx={{ padding: '16px' }}>
        <Grid container={true} spacing={3}>
          <Grid item={true} lg={10} xs={12}>
            <TextField
              data-cy="variant"
              name="variant"
              label="Search"
              onChange={event => !disabledField && handleOnSearch(event.target.value)}
              disabled={disabledField}
              error={lineItemsFieldError}
            />
          </Grid>
          <Grid item={true} lg={2} xs={12}>
            <Button
              fullWidth={true}
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={handleOpen}
              type="button"
              data-cy="browse"
              disabled={disabledField}
            >
              Browse
            </Button>
          </Grid>
        </Grid>
        {open && (
          <AdjustmentVariantAddDialog
            onAddVariants={handleAddVariants}
            onClose={handleCloseSearch}
            open={open}
            params={params}
            adjustmentVariant={adjustmentVariant}
          />
        )}
      </Box>

      <br />
      <ItemsTable
        extraData={extraData}
        handleRemove={handleRemove}
        unitFieldFunctions={unitFieldFunctions}
      />
    </>
  )
}

AdjustmentUpdateLineItems.propTypes = {
  unitFieldMethods: PropTypes.object.isRequired,
  adjustmentVariant: PropTypes.object.isRequired
}

export default AdjustmentUpdateLineItems
