import PropTypes from 'prop-types'
import React from 'react'
import { styled } from '@mui/material'
import Link from '@mui/material/Link'

const LinkStyled = styled(Link)({
  padding: '2px 6px',
  color: '#273257',
  backgroundColor: '#C9D6E0',
  borderRadius: '2px',
  fontWeight: 500,
  textDecoration: 'none !important',
  display: 'inline-block'
})

function PrintLink (props) {
  const {
    href,
    children,
    disabled = false
  } = props

  return (
    <LinkStyled
      href={href}
      rel="noreferrer noopener"
      target="_blank"
      underline="always"
      disabled={disabled}
    >
      {children}
    </LinkStyled>
  )
}

PrintLink.propTypes = {
  children: PropTypes.any.isRequired,
  href: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}

export default PrintLink
