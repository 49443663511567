import React from 'react'
import ReactNumberFormat from 'react-number-format'
import PropTypes from 'prop-types'

const NumberFormat = React.forwardRef(({ onChange, ...props }, ref) => {
  return (
    <ReactNumberFormat
      getInputRef={ref}
      onValueChange={values => onChange({ target: { value: values.floatValue } })}
      thousandSeparator={true}
      isNumericString={true}
      decimalScale={3}
      allowLeadingZeros={false}
      {...props}
    />
  )
})

NumberFormat.propTypes = {
  onChange: PropTypes.func.isRequired,
}

export default NumberFormat
