import React from 'react'
import PropTypes from 'prop-types'
import { prop, propOr } from 'ramda'
import Divider from '@mui/material/Divider'
import PerfectScrollbar from 'react-perfect-scrollbar'
import List from '@mui/material/List'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { styled } from '@mui/material'

import TextOverflow from '~/components/TextOverflow'
import { CardHeader } from '~/components/Cards'

import PackingListItemsLoading from './PackingListItemsLoading'

import AvatarProductPreview from '../AvatarProductPreview'

const CardStyled = styled(Card)(({ theme }) => ({
  ...theme.components.StorfoxListPanel.styleOverrides.root,
  minHeight: '100vh',
  height: '100%',
  flexBasis: 300,
  '@media (min-width: 864px)': {
    borderRight: `1px solid ${theme.palette.divider}`
  }
}))

function PackingListItems ({ lineItems, isLoading }) {
  return (
    <CardStyled>
      <CardHeader title="Line items" />

      <Divider />

      <Box sx={{
        overflowY: 'auto',
        height: '100%',
        flexShrink: 0
      }}>
        <PerfectScrollbar>
          <List component="div" disablePadding={true}>
            {!isLoading
              ? lineItems.map((item, index) => {
                const id = prop('id', item)
                const unit = prop('unit', item)
                const selectedQuantity = propOr(0, 'selectedQuantity', item)
                const totalQuantity = prop('quantity', item)
                const unitNumber = prop('unitNumber', unit)
                const variant = prop('variant', unit)
                const variantName = prop('name', variant)
                const src = prop('defaultImage', variant)
                const sku = prop('sku', variant)

                return (
                  <ListItem key={id}>
                    <Grid container={true} alignItems="center">
                      <Grid item={true} xs={12}>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                          <Box display="flex" alignItems="center" maxWidth="75%">
                            <Box>
                              <ListItemAvatar>
                                <AvatarProductPreview unit={unit} alt={variantName} src={src} />
                              </ListItemAvatar>
                            </Box>

                            <Box>
                              <Typography variant="body1" component="div">
                                <TextOverflow selfTooltip={true} lines={1}>
                                  {variantName}
                                </TextOverflow>
                              </Typography>
                              <Typography variant="caption" display="block">{sku}</Typography>
                              <Typography variant="caption">{unitNumber}</Typography>
                            </Box>

                          </Box>

                          <Box display="flex" alignItems="center">
                            <Typography align="right">
                              {selectedQuantity}/{totalQuantity}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>

                    </Grid>
                  </ListItem>
                )
              })
              : (
                <PackingListItemsLoading numberOfItems={8} />
              )}
          </List>
        </PerfectScrollbar>
      </Box>
    </CardStyled>
  )
}

PackingListItems.propTypes = {
  lineItems: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default PackingListItems
