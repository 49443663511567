import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { path, prop } from 'ramda'
import { useField } from 'react-final-form'
import { useValueChanged } from 'storfox-tools'

import { DiscardButton } from '~/components/Buttons'
import { withForm } from '~/components/Form'
import Container, { Content, Header } from '~/components/Container'
import PageTitle from '~/components/PageTitle'
import { useCompany } from '~/components/Profile'
import * as NAV from '~/constants/nav-titles'

import TransferUpdateButton from './TransferUpdateButton'

import TransferContent from '../TransferContent'
import { CANCELLED, COMPLETED, IN_PROGRESS } from '../../constants'

function TransferUpdateForm (props) {
  const {
    pageTitle,
    pageTitleLoading,
    isLoading,
    form,
    transferOrderVariant
  } = props

  const { hasBelongCompany } = useCompany()

  const { initialValues, handleSubmit } = form

  const companyId = path(['company', 'id'], initialValues)
  const status = prop('status', initialValues)
  const startedReceiving = prop('startedReceiving', initialValues)

  const fromWarehouseField = useField('fromWarehouse')
  const fromWarehouseValue = fromWarehouseField.input.value
  const fromWarehouseId = prop('id', fromWarehouseValue)

  const lineItemsField = useField('lineItems')
  const handleLineItemsChange = useCallback(lineItemsField.input.onChange, [])

  useValueChanged(() => {
    handleLineItemsChange([])
  }, fromWarehouseId)

  const disableSave = (
    status === CANCELLED ||
    status === COMPLETED ||
    status === IN_PROGRESS ||
    !hasBelongCompany(companyId) ||
    isLoading ||
    startedReceiving
  )

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            parentTitle={NAV.TRANSFER}
            pageTitle={pageTitle}
            pageTitleLoading={pageTitleLoading}
            rightButton={(
              <>
                <DiscardButton />
                <TransferUpdateButton
                  isLoading={disableSave || isLoading}
                  onSubmit={handleSubmit}
                />
              </>
            )}
          />
        </Header>
        <Content>
          <TransferContent transferOrderVariant={transferOrderVariant} />
        </Content>
      </Container>
    </form>
  )
}

TransferUpdateForm.propTypes = {
  pageTitleLoading: PropTypes.bool.isRequired,
  pageTitle: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  transferOrderVariant: PropTypes.object.isRequired
}

export default withForm(TransferUpdateForm)
