import React from 'react'
import { Ordering } from 'storfox-filter'

import { PICKLIST_COLUMNS as tableColumns } from './PicklistTable'

import { FILTER_NAME as name } from '../../constants'

export const picklistOrderingOptions = {
  name,
  tableColumns
}

function PicklistOrderingForm (props) {
  return (
    <Ordering {...props} />
  )
}

export default PicklistOrderingForm
