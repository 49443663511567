import React, { useState } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { path, prop, propOr } from 'ramda'
import { useTranslation } from 'react-i18next'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'
import { objectToSearchParams, useAllSearchParams } from 'storfox-route-hooks'
import { useDeepCompareEffect, useRequest } from 'storfox-api-hooks'
import { sprintf } from 'sprintf-js'

import Title from '~/components/Title'
import * as ROUTES from '~/constants/routes'
import { PACKING_DETAIL_TABS, SHIPMENT_DETAIL_TABS } from '~/constants/tabs'
import { useNotification } from '~/components/Notification'
import { SHIPMENT_CREATED } from '~/constants/notification-topics'
import useMessages from '~/hooks/useMessages'
import ErrorLink from '~/components/ErrorLink'
import { HtmlLayout } from '~/components/Layouts'
import { useGoogleEvent } from '~/components/GoogleAnalytics/GoogleAnalytics'
import * as API from '~/constants/api'

import { ACTIONS } from '../constants'
import {
  useContainerCreate,
  usePackingComplete,
  usePackingContainerBarcodeScan,
  usePackingContainerTypeList,
  usePackingDetail,
  usePackingHistory,
  usePackingSave,
  usePackingSkip,
  usePackingSlipPdf,
  useShipmentLocationList
} from '../hooks'
import PackingBarcoding from '../components/PackingBarcoding'
import { ContainerCreateSerializer, PackingCompleteSerializer, PackingSaveSerializer } from '../serializers'

function PackingBarcodingContainer () {
  const { t } = useTranslation()
  const { id, tab } = useParams()
  const [shipmentGuid, setShipmentGuid] = useState(null)
  const { initiallyScannedContainer } = useAllSearchParams()
  const messages = useMessages()
  const { sendEvent } = useGoogleEvent()
  const request = useRequest()

  const packingDetail = usePackingDetail(id, false)
  const packingSkip = usePackingSkip(id)

  const guid = prop('guid', packingDetail.detail)
  const historyAutoSend = Boolean(guid && tab === PACKING_DETAIL_TABS.HISTORY)

  const locationGuid = path(['fromLocation', 'guid'], packingDetail.detail)
  const warehouseId = path(['warehouse', 'id'], packingDetail.detail)

  const packingSave = usePackingSave(id)
  const packingComplete = usePackingComplete()
  const packingGeneratePDF = usePackingSlipPdf(guid)

  const containerCreate = useContainerCreate()
  const containerTypeList = usePackingContainerTypeList()
  const shipmentLocationList = useShipmentLocationList(warehouseId)
  const packingHistory = usePackingHistory(guid, historyAutoSend)
  const packingContainerBarcodeScan = usePackingContainerBarcodeScan()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const notification = useNotification(SHIPMENT_CREATED)

  const number = propOr('', 'toObjectNumber', packingDetail.detail)
  const referenceNumber = propOr('', 'toObjectReferenceNumber', packingDetail.detail)
  const refNumber = referenceNumber ? `/${referenceNumber}` : ''

  const titleName = `${t('Packing')} ${number}${refNumber}`
  const title = <Title title={titleName} />

  useDeepCompareEffect(() => {
    packingDetail.getDetail()
  }, [id])

  const handleSkip = () =>
    packingSkip.skip()
      .then(() => {
        sendEvent({ eventAction: 'Skip By Barcode Packing', eventCategory: 'Packing' })
        snackbar({ message: messages.UPDATE_SUCCESS })
      })
      .then(() => packingDetail.getDetail())
      .catch(error => {
        snackbar({
          message: <ErrorLink error={error} />,
          type: ALTER_ERROR
        })
        throw error
      })

  const handleContainerBarcodeScan = ({ containerNumber, barcode }) =>
    packingContainerBarcodeScan.scan(containerNumber, { unitNumber: barcode })
      .then(data => {
        sendEvent({ eventAction: 'Container Scan Packing', eventCategory: 'Packing' })
        const results = path(['data', 'results'], data)
        const id = path([0, 'id'], results)

        const url = generatePath(ROUTES.PACKING_UPDATE_PATH, { id })
        const queryParams = {
          initiallyScannedContainer: containerNumber,
          initiallyScannedBarcode: barcode
        }

        const urlPath = `${url}?${objectToSearchParams(queryParams)}`
        navigate(urlPath)
        return data
      })
      .catch((err) => {
        snackbar({
          message: messages.CONTAINER_FIND_FAIL,
          type: ALTER_ERROR
        })
        throw err
      })

  const handleContainerCreate = value =>
    containerCreate.create(ContainerCreateSerializer(locationGuid, value))
      .then(response => {
        snackbar({ message: messages.CREATE_SUCCESS })
        return response
      })
      .then(response => {
        sendEvent({
          eventAction: 'Container Create Packing',
          eventCategory: 'Packing'
        })
        return response
      })
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const handleSave = formValues =>
    packingSave.update(PackingSaveSerializer(formValues))
      .then(() => {
        sendEvent({ eventAction: 'Save After Packing', eventCategory: 'Packing' })
        snackbar({ message: messages.UPDATE_SUCCESS })
      })
      .then(() => packingDetail.getDetail())

  const completePacking = formValues =>
    packingComplete.update(PackingCompleteSerializer(formValues))
      .then(res => {
        snackbar({ message: messages.NOTIFICATION_WAIT })
        return res
      })
      .then(res => {
        sendEvent({
          eventAction: 'Complete Packing',
          eventCategory: 'Packing'
        })
        const guid = path(['result', 'key'], res)
        setShipmentGuid(guid)
        return res
      })

  const handleComplete = formValues =>
    completePacking(formValues)
      .then(() => {
        const searchParamsObject = initiallyScannedContainer ? { action: ACTIONS.OPEN_SCAN } : {}
        const url = `${ROUTES.PACKING_LIST_PATH}?${objectToSearchParams(searchParamsObject)}`
        navigate(url)
        return Promise.resolve()
      })

  const handleCompleteAndRedirect = formValues =>
    completePacking(formValues)
      .then(({ result }) => {
        const key = prop('key', result)
        notification.subscribe(key, () => {
          const route = generatePath(ROUTES.SHIPMENT_DETAIL_PATH, { guid: key, tab: SHIPMENT_DETAIL_TABS.GENERAL })
          navigate(route)
          return Promise.resolve()
        })
      })

  const handleCompleteAndPrintInvoice = formValues =>
    completePacking(formValues)
      .then(({ result }) => {
        snackbar({ message: messages.NOTIFICATION_WAIT })
        const key = prop('key', result)
        const url = sprintf(API.SHIPMENT_GENERATE_COMMERCIAL_INVOICE, key)
        notification.subscribe(key, () => {
          return request.get(url)
            .then(() => snackbar({ message: messages.GENERATE_SUCCESS }))
            .catch(error => {
              const message = <ErrorLink error={error} />
              snackbar({ message, type: ALTER_ERROR })
              return Promise.reject(error)
            })
        })
      })

  const handleGenerateSlipPdf = () =>
    packingGeneratePDF.generate()
      .then(() => snackbar({ message: messages.NOTIFICATION_WAIT }))
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const handlePrintInvoice = () => {
    const url = sprintf(API.SHIPMENT_GENERATE_COMMERCIAL_INVOICE, shipmentGuid)
    return request.get(url)
      .then(() => snackbar({ message: messages.GENERATE_SUCCESS }))
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
        return Promise.reject(error)
      })
  }

  const isLoading = (
    packingDetail.isLoading ||
    packingSave.isLoading ||
    notification.isLoading ||
    packingComplete.isLoading
  )

  return (
    <HtmlLayout title={titleName}>
      <PackingBarcoding
        pageTitle={title}
        pageTitleLoading={packingDetail.isLoading}
        initialPackingValues={packingDetail.detail}
        packingHistory={packingHistory}
        isLoading={isLoading}
        shipmentLocationList={shipmentLocationList.results}
        containerTypeList={containerTypeList}
        containerLoading={containerCreate.isLoading}
        onSave={handleSave}
        onComplete={handleComplete}
        onCompleteAndRedirect={handleCompleteAndRedirect}
        onContainerCreate={handleContainerCreate}
        completePacking={completePacking}
        scanContainerBarcode={packingContainerBarcodeScan.scan}
        onSkip={handleSkip}
        onGenerateSlip={handleGenerateSlipPdf}
        onContainerBarcodeScan={handleContainerBarcodeScan}
        handleCompleteAndPrintInvoice={handleCompleteAndPrintInvoice}
        onPrintInvoice={handlePrintInvoice}
      />
    </HtmlLayout>
  )
}

export default PackingBarcodingContainer
