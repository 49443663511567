import React from 'react'

import SelectField from '~/components/Fields/SelectField'

const SHIPPER = 'shipper'
const THIRD_PARTY = 'third_party'
const RECIPIENT = 'recipient'

const OPTIONS = [
  {
    value: SHIPPER,
    name: 'Shipper'
  },
  {
    value: THIRD_PARTY,
    name: 'Third Party'
  },
  {
    value: RECIPIENT,
    name: 'Recipient'
  }
]
function BillingPaidField (props) {
  return (
    <SelectField
      data-cy="billingPaid"
      label="Billing Paid By"
      options={OPTIONS}
      {...props}
    />
  )
}

export default BillingPaidField
