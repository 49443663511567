import React from 'react'
import PropTypes from 'prop-types'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import { useTranslation } from 'react-i18next'
import { IconButton, styled } from '@mui/material'
import { prop } from 'ramda'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import InfoIcon from '@mui/icons-material/Info'

import { CardHeader } from '~/components/Cards'
import HtmlTooltip from '~/components/HtmlTooltip/HtmlTooltip'

import Chart from './Chart'
import Loader from './Loader'

const CardContentStyled = styled(CardContent)({
  padding: 0,
  '&:last-child': {
    paddingBottom: 0
  }
})

function Shipments ({ shipmentOverview, isLoading }) {
  const { t } = useTranslation()
  const detail = prop('detail', shipmentOverview)

  return (
    <Card>
      <CardHeader
        title={
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h4">{t('Shipments')}</Typography>
            <HtmlTooltip title="Breakdown of shipment status">
              <IconButton size="small">
                <InfoIcon sx={{ color: '#BDBDBD' }} />
              </IconButton>
            </HtmlTooltip>
          </Box>
        }
      />
      <Divider />
      <CardContentStyled>
        {isLoading && <Loader />}
        {!isLoading && <Chart data={detail} />}
      </CardContentStyled>
    </Card>
  )
}

Shipments.propTypes = {
  shipmentOverview: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default Shipments
