import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import StorefrontIntegrationCreateContainer from './StorefrontIntegrationCreateContainer'
import StorefrontIntegrationUpdateContainer from './StorefrontIntegrationUpdateContainer'

export default () => {
  return [
    {
      layout: AuthLayout,
      path: ROUTES.STOREFRONT_INTEGRATION_CREATE_PATH,
      component: StorefrontIntegrationCreateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.STOREFRONT_INTEGRATION_UPDATE_PATH,
      component: StorefrontIntegrationUpdateContainer
    }
  ]
}
