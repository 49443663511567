import React from 'react'
import ListItemText from '@mui/material/ListItemText'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop'
import { ListItemButton } from '@mui/material'
import PropTypes from 'prop-types'

import { MoreListButton } from '~/components/Buttons'
import * as ROUTES from '~/constants/routes'
import IconText from '~/components/IconText'

function More ({ tableRef }) {
  const { t } = useTranslation()

  return (
    <MoreListButton tableRef={tableRef}>
      {() => (
        <>
          <ListItemButton
            disabled={false}
            component={Link}
            to={ROUTES.LOT_SHEETS_IMPORT_PATH}
          >
            <IconText Icon={VerticalAlignTopIcon}>
              <ListItemText primary={t('Import')} />
            </IconText>
          </ListItemButton>
        </>
      )}
    </MoreListButton>
  )
}

More.propTypes = {
  tableRef: PropTypes.object
}

export default More
