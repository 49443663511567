import React from 'react'
import { prop } from 'ramda'

import SearchField from '~/components/Fields/SearchField'
import * as API from '~/constants/api'

const getOptionLabel = item => {
  if (item) {
    return prop('name', item)
  }

  return ''
}

const getOptionValue = item => {
  if (item) {
    const id = prop('id', item)
    const guid = prop('guid', item)
    const name = prop('name', item)

    return { id, guid, name }
  }

  return null
}

function CustomerSearchField (props) {
  return (
    <SearchField
      name="client"
      label="Client"
      primaryKey="guid"
      api={API.TPL_BILLING_CUSTOMER_LIST}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      {...props}
    />
  )
}

export default CustomerSearchField
