import React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Label from '../Label'

function OrderTotalValue ({ title, value, percent, children }) {
  const { t } = useTranslation()

  return (
    <Box position="relative">
      {children}
      <Typography
        component="h2"
        gutterBottom={true}
        variant="overline"
        sx={{ textAlign: 'left' }}
      >
        {t(title)}
      </Typography>
      <Box display="flex" alignItems="center" justifyContent="start">
        <Typography variant="h3">
          {value}
        </Typography>
        <Label
          color="#203D77"
          variant="contained"
          sx={{ ml: 1 }}
        >
          +{percent}%
        </Label>
      </Box>
    </Box>
  )
}

OrderTotalValue.propTypes = {
  title: PropTypes.string.isRequired,
  percent: PropTypes.number.isRequired,
  children: PropTypes.any,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]).isRequired
}

export default OrderTotalValue
