import React from 'react'
import PropTypes from 'prop-types'

import { capitalize } from '~/utils'

function Capitalize ({ children }) {
  return (
    <>{capitalize(children)}</>
  )
}

Capitalize.propTypes = {
  children: PropTypes.string.isRequired
}

export default Capitalize
