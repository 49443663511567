import React from 'react'
import PropTypes from 'prop-types'

import { SEND_TO_USERS } from '../ActionFields/ActionSelectField'
import UserMultiSelectField from '../ActionFields/UserMultiSelectField'

export const SEND_TO_USERS_FIELDS = [
  'sendToUsers',
]

function LowStockAction ({ action, actionName }) {
  return (
    <>
      {action === SEND_TO_USERS && <UserMultiSelectField name={`${actionName}.users`} />}
    </>
  )
}

LowStockAction.propTypes = {
  action: PropTypes.string.isRequired,
  actionName: PropTypes.string.isRequired
}

export default LowStockAction
