import React from 'react'
import PropTypes from 'prop-types'
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom'
import { ListItemButton } from '@mui/material'

import ListItemText from '~/components/List/ListItemText'
import { MoreListButton } from '~/components/Buttons'
import IconText from '~/components/IconText'

function More ({ onShipmentExport, tableRef }) {
  return (
    <MoreListButton tableRef={tableRef}>
      {onClose => (
        <ListItemButton
          onClick={() =>
            onShipmentExport()
              .finally(() => onClose())
          }
        >
          <IconText Icon={VerticalAlignBottomIcon}>
            <ListItemText primary="Export" />
          </IconText>
        </ListItemButton>
      )}
    </MoreListButton>
  )
}

More.propTypes = {
  onShipmentExport: PropTypes.func.isRequired,
  tableRef: PropTypes.object
}

export default More
