import React, { useRef, useState } from 'react'
import { defaultTo, equals, find, path, pipe, prop, propOr } from 'ramda'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import { useDeepCompareEffect, useRequest, getCancelToken } from 'storfox-api-hooks'

import FieldWrapper from './FieldWrapper'

const defaultGetOptionValue = value => {
  if (value) {
    const { id, guid, name } = value
    return { id, guid, name }
  }

  return null
}

const defaultGetOptionLabel = value => {
  if (value) {
    return propOr('', 'name', value)
  }

  return ''
}

const getItemFromResults = (results, id, primaryKey) =>
  find(
    pipe(
      prop(primaryKey),
      equals(id)
    )
  )(results)

function ScannerClearSearchField (props) {
  const {
    api,
    params,
    disabled,
    input,
    meta,
    onChange,
    renderOption,
    getOptionValue,
    getOptionLabel,
    disableClearable,
    PaperComponent,
    ListboxComponent,
    InputProps,
    onInputChange,
    primaryKey,
    ListboxProps,
    ...defaultProps
  } = props

  const request = useRequest()
  const [value, setValue] = useState('')
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const ref = useRef([])
  const enterPressed = useRef(false)

  const handleChange = event => {
    if (event) {
      event.preventDefault()
    }
    if (ref.current.length) {
      input.onChange(null)
      onChange(null, ref.current[0])
      setOpen(false)
      setLoading(false)
      setValue('')
    }
  }

  useDeepCompareEffect(() => {
    let active = true

    const { token, cancel } = getCancelToken()

    if (open) {
      setLoading(true)
      onInputChange(value)
      request.get(api, { ...params, search: value }, { cancelToken: token })
        .then(response => {
          const results = defaultTo([], path(['data', 'results'], response))

          if (active) {
            ref.current = results
            setOptions(results.map(getOptionValue))
            setLoading(false)
          }
        })
        .then(() => {
          if (enterPressed.current) {
            handleChange()
            enterPressed.current = false
          }
        })
    }

    return () => {
      active = false
      cancel()
    }
  }, [open, value])

  React.useEffect(() => {
    if (!open) {
      ref.current = []
      setOptions([])
    }
  }, [open])

  return (
    <Autocomplete
      open={open}
      disabled={disabled}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      disableClearable={disableClearable}
      value={input.value}
      isOptionEqualToValue={(option, value) => option[primaryKey] === value[primaryKey]}
      getOptionLabel={getOptionLabel}
      inputValue={value}
      options={options.length === 0 && !open ? [input.value] : options}
      loading={loading}
      filterOptions={item => item}
      ListboxProps={ListboxProps}
      onKeyPress={(event) => {
        if (event.key === 'Enter' && loading) {
          enterPressed.current = true
        }

        if (event.key === 'Enter' && !loading) {
          handleChange(event)
        }

        if (event.key === 'Enter') {
          event.preventDefault()
        }
      }}
      onChange={(event, value) => {
        if (value) {
          onChange(event, getItemFromResults(ref.current, value[primaryKey], primaryKey))
        } else {
          onChange(event, null)
        }
        setValue('')
      }}
      PaperComponent={PaperComponent}
      ListboxComponent={ListboxComponent}
      renderOption={renderOption}
      onInputChange={(event, value) => setValue(value)}
      renderInput={params => {
        return (
          <TextField
            error={meta.invalid}
            helperText={meta.submitError || meta.error}
            {...params}
            {...defaultProps}
            InputProps={{
              ...params.InputProps,
              ...InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )
      }}
    />
  )
}

ScannerClearSearchField.propTypes = {
  primaryKey: PropTypes.string,
  api: PropTypes.string.isRequired,
  params: PropTypes.object.isRequired,
  size: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  input: PropTypes.object.isRequired,
  renderOption: PropTypes.func,
  meta: PropTypes.object.isRequired,
  getOptionValue: PropTypes.func.isRequired,
  getOptionLabel: PropTypes.func.isRequired,
  disableClearable: PropTypes.bool.isRequired,
  InputProps: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  PaperComponent: PropTypes.func,
  ListboxComponent: PropTypes.func,
  ListboxProps: PropTypes.object
}

ScannerClearSearchField.defaultProps = {
  primaryKey: 'id',
  params: { limit: 30 },
  size: 'small',
  fullWidth: true,
  variant: 'outlined',
  disabled: false,
  getOptionValue: defaultGetOptionValue,
  getOptionLabel: defaultGetOptionLabel,
  disableClearable: false,
  onChange: () => {},
  onInputChange: () => {}
}

export default FieldWrapper(ScannerClearSearchField)
