import React from 'react'
import PropTypes from 'prop-types'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import { path, prop } from 'ramda'

import { CardHeader } from '~/components/Cards'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import * as NAV from '~/constants/nav-titles'
import LoaderProvider from '~/components/Loader'
import TextLabelLoader from '~/components/TextLabelLoader'
import { Dimension, Volume, Weight } from '~/components/Converters'
import { DiscardButton, PrimaryButton } from '~/components/Buttons'

import ContainerContent from './ContainerContent'

function ContainerDetail (props) {
  const {
    title,
    isLoading,
    detail,
    onDelete,
    containerContent
  } = props

  const containerType = path(['containerType', 'name'], detail)
  const parent = prop('parent', detail)
  const height = prop('height', detail)
  const width = prop('width', detail)
  const length = prop('length', detail)
  const weight = prop('weight', detail)
  const volume = prop('volume', detail)

  return (
    <LoaderProvider isLoading={isLoading}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={title}
            parentTitle={NAV.CONTAINER}
            pageTitleLoading={isLoading}
            rightButton={(
              <>
                <DiscardButton />
                <PrimaryButton onClick={onDelete}>
                  Delete
                </PrimaryButton>
              </>
            )}
          />
        </Header>

        <Content>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12} lg={8}>
              <Box mb={3}>
                <Card>
                  <CardHeader title="Information" />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12} lg={12}>
                        <TextLabelLoader label="Container type">
                          {containerType}
                        </TextLabelLoader>
                      </Grid>

                      <Grid item={true} xs={12} lg={12}>
                        <TextLabelLoader label="Parent">
                          {parent}
                        </TextLabelLoader>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
              <Box mb={3}>
                <Card>
                  <CardHeader title="Content" />
                  <Divider />
                  <ContainerContent
                    contents={containerContent.results}
                    isLoading={containerContent.isLoading}
                  />
                </Card>
              </Box>
            </Grid>

            <Grid item={true} xs={12} lg={4}>
              <Card>
                <CardHeader title="Dimensions" />
                <Divider />
                <CardContent>
                  <Grid container={true} spacing={3}>
                    <Grid item={true} xs={12} lg={6}>
                      <TextLabelLoader label="Height">
                        <Dimension value={height} />
                      </TextLabelLoader>
                    </Grid>

                    <Grid item={true} xs={12} lg={6}>
                      <TextLabelLoader label="Width">
                        <Dimension value={width} />
                      </TextLabelLoader>
                    </Grid>

                    <Grid item={true} xs={12} lg={6}>
                      <TextLabelLoader label="Length">
                        <Dimension value={length} />
                      </TextLabelLoader>
                    </Grid>

                    <Grid item={true} xs={12} lg={6}>
                      <TextLabelLoader label="Weight">
                        <Weight value={weight} />
                      </TextLabelLoader>
                    </Grid>

                    <Grid item={true} xs={12} lg={12}>
                      <TextLabelLoader label="Volume">
                        <Volume value={volume} />
                      </TextLabelLoader>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Content>
      </Container>
    </LoaderProvider>
  )
}

ContainerDetail.propTypes = {
  title: PropTypes.string,
  detail: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  containerContent: PropTypes.object.isRequired
}

export default ContainerDetail
