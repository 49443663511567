import React from 'react'
import PropTypes from 'prop-types'
import { Beforeunload } from 'react-beforeunload'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'
import { useField } from 'react-final-form'
import { isEmpty } from 'ramda'
import { generatePath, useNavigate } from 'react-router-dom'
import { useConfirm } from 'storfox-confirm-dialog'

import { withForm } from '~/components/Form'
import PageTitle from '~/components/PageTitle'
import { Button } from '~/components/Buttons'
import FluidContainer, { FluidContent, FluidHeader } from '~/components/FluidContainer'
import * as NAV from '~/constants/nav-titles'
import { PUTAWAY_DETAIL_TABS } from '~/constants/tabs'
import * as ROUTES from '~/constants/routes'
import DiscardButtonClick from '~/components/Buttons/DiscardButtonClick'

import LineItemsGroup from './LineItemsGroup'

const message = 'You have active line item not proceeded'

function PutawayPutForm (props) {
  const {
    form,
    pageTitle,
    isLoading,
    guid,
    onGetSuggestedLocations
  } = props

  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const onConfirm = useConfirm()
  const activeLineItemField = useField('activeLineItem')
  const activeLineItem = activeLineItemField.input.value
  const { handleSubmit, initialValues } = form
  const handlePut = isEmpty(activeLineItem) ? handleSubmit : () => snackbar({ message, type: ALTER_ERROR })

  const detailLink = generatePath(ROUTES.V2_PUTAWAY_DETAIL_PATH, { guid, tab: PUTAWAY_DETAIL_TABS.GENERAL })

  const handleDiscard = () => {
    onConfirm({ title: 'Go back?', message: 'Leaving this page will discard unsaved changes.' })
      .agree(() => navigate(detailLink))
      .disagree()
  }

  return (
    <form onSubmit={handleSubmit}>
      <FluidContainer>
        <FluidHeader>
          <PageTitle
            pageTitle={pageTitle}
            parentTitle={NAV.PUTAWAY}
            rightButton={(
              <>
                <DiscardButtonClick onClick={handleDiscard} />
                <Button
                  variant="contained"
                  onClick={handlePut}
                  data-cy="submit"
                >
                  Put
                </Button>
              </>
            )}
          />
        </FluidHeader>
        <FluidContent>
          <LineItemsGroup
            isLoading={isLoading}
            initialValues={initialValues}
            handleSubmit={handleSubmit}
            onGetSuggestedLocations={onGetSuggestedLocations}
          />
        </FluidContent>
      </FluidContainer>
      <Beforeunload onBeforeunload={event => event.preventDefault()} />
    </form>
  )
}

PutawayPutForm.propTypes = {
  form: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  pageTitle: PropTypes.string.isRequired,
  guid: PropTypes.string.isRequired,
  onGetSuggestedLocations: PropTypes.func
}

export default withForm(PutawayPutForm)
