import React from 'react'
import DialogActions from '@mui/material/DialogActions'
import PropTypes from 'prop-types'

import Button from '../Buttons/Button'
import DiscardButton from '../Buttons/DiscardButton'

function ActionButtons ({ onClose, disabled }) {
  return (
    <DialogActions>
      <DiscardButton
        onClick={onClose}
        fullWidth={true}
      >
        Discard
      </DiscardButton>

      <Button
        disabled={disabled}
        data-cy="done"
        variant="contained"
        fullWidth={true}
        type="submit"
      >
        Done
      </Button>
    </DialogActions>
  )
}

ActionButtons.defaultProps = {
  disabled: false
}

ActionButtons.propTypes = {
  onClose: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
}

export default ActionButtons
