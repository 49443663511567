// ------------------------------- DASHBOARD --------------------------------------------

export const OVERVIEW_VIEW = 'dashboard.overview.overview-view'

// ------------------------------- PURCHASES --------------------------------------------

export const PURCHASE_VIEW = 'purchases.purchase-orders.purchase-order-view'
export const PURCHASE_EDIT = 'purchases.purchase-orders.purchase-order-edit'
export const PURCHASE_ADD = 'purchases.purchase-orders.purchase-order-add'
export const PURCHASE_DELETE = 'purchases.purchase-orders.purchase-order-delete'
export const PURCHASE_CLOSE = 'purchases.purchase-orders.purchase-order-close'
export const PURCHASE_CANCEL = 'purchases.purchase-orders.purchase-order-cancel'

export const SUPPLIER_VIEW = 'purchases.suppliers.supplier-view'
export const SUPPLIER_EDIT = 'purchases.suppliers.supplier-edit'
export const SUPPLIER_ADD = 'purchases.suppliers.supplier-add'
export const SUPPLIER_DELETE = 'purchases.suppliers.supplier-delete'

// ------------------------------- STOCK CONTROL --------------------------------------------

export const RECEIVE_VIEW = 'stock.receive.receipt-view'
export const RECEIVE_ADD = 'stock.receive.receipt-add'

export const PUTAWAY_VIEW = 'stock.putaway.putaway-job-view'
export const PUTAWAY_EDIT = 'stock.putaway.putaway-job-edit'
export const PUTAWAY_PROCESS = 'stock.putaway.putaway-job-process'
export const PUTAWAY_PROGRESS = 'stock.putaway.putaway-job-progress'
export const PUTAWAY_COMPLETE = 'stock.putaway.putaway-job-complete'

export const ADJUSTMENT_VIEW = 'stock.adjustments.adjustment-view'
export const ADJUSTMENT_EDIT = 'stock.adjustments.adjustment-edit'
export const ADJUSTMENT_ADD = 'stock.adjustments.adjustment-add'
export const ADJUSTMENT_DELETE = 'stock.adjustments.adjustment-delete'
export const ADJUSTMENT_COMPLETE = 'stock.adjustments.adjustment-complete'
export const ADJUSTMENT_CANCEL = 'stock.adjustments.adjustment-cancel'

export const TRANSFER_VIEW = 'stock.stock-transfers.stock-transfer-view'
export const TRANSFER_EDIT = 'stock.stock-transfers.stock-transfer-edit'
export const TRANSFER_ADD = 'stock.stock-transfers.stock-transfer-add'
export const TRANSFER_PROCESS = 'stock.stock-transfers.stock-transfer-process'
export const TRANSFER_CANCEL = 'stock.stock-transfers.stock-transfer-cancel'

export const UNIT_NUMBER_ADD = 'stock.unit-numbers.unit-number-add'

// ------------------------------- FULFILLMENT --------------------------------------------

export const CUSTOMER_VIEW = 'fulfillment.customers.customer-view'
export const CUSTOMER_EDIT = 'fulfillment.customers.customer-edit'
export const CUSTOMER_ADD = 'fulfillment.customers.customer-add'
export const CUSTOMER_DELETE = 'fulfillment.customers.customer-delete'

export const SALE_ORDER_VIEW = 'fulfillment.orders.order-view'
export const SALE_ORDER_EDIT = 'fulfillment.orders.order-edit'
export const SALE_ORDER_ADD = 'fulfillment.orders.order-add'
export const SALE_ORDER_DELETE = 'fulfillment.orders.order-delete'
export const SALE_ORDER_ALLOCATE = 'fulfillment.orders.order-allocate'
export const SALE_ORDER_PROCESS = 'fulfillment.orders.order-process'

export const PICKLIST_VIEW = 'fulfillment.picklists.picklist-view'
export const PICKLIST_PROCESS = 'fulfillment.picklists.picklist-process'
export const PICKLIST_PROGRESS = 'fulfillment.picklists.picklist-progress'
export const PICKLIST_COMPLETE = 'fulfillment.picklists.picklist-complete'
export const PICKLIST_GENERATE = 'fulfillment.picklists.picklist-generate'
export const PICKLIST_PICK = 'fulfillment.picklists.picklist-pick'

export const PACKING_COMPLETE = 'fulfillment.packing.packing-complete'
export const PACKING_VIEW = 'fulfillment.packing.packing-view'
export const PACKING_EDIT = 'fulfillment.packing.packing-edit'
export const PACKING_PROCESS = 'fulfillment.packing.packing-process'

export const SHIPMENT_VIEW = 'fulfillment.shipments.shipment-view'

// ------------------------------- CATALOG --------------------------------------------

export const PRODUCT_VIEW = 'catalog.products.product-view'
export const PRODUCT_EDIT = 'catalog.products.product-edit'
export const PRODUCT_ADD = 'catalog.products.product-add'
export const PRODUCT_DELETE = 'catalog.products.product-delete'

export const VARIANT_DELETE = 'catalog.variants.variant-delete'
export const VARIANT_VIEW = 'catalog.variants.variant-view'
export const VARIANT_EDIT = 'catalog.variants.variant-edit'

export const UNIT_VIEW = 'catalog.units.unit-view'

export const CATEGORY_VIEW = 'catalog.categories.category-view'
export const CATEGORY_EDIT = 'catalog.categories.category-edit'
export const CATEGORY_ADD = 'catalog.categories.category-add'
export const CATEGORY_DELETE = 'catalog.categories.category-delete'

export const BRAND_VIEW = 'catalog.brands.brand-view'
export const BRAND_EDIT = 'catalog.brands.brand-edit'
export const BRAND_ADD = 'catalog.brands.brand-add'
export const BRAND_DELETE = 'catalog.brands.brand-delete'

export const LISTING_VIEW = 'catalog.listing.listing-view'
export const LISTING_ADD = 'catalog.listing.listing-add'
export const LISTING_PAUSE = 'catalog.listing.listing-pause'
export const LISTING_REJECT = 'catalog.listing.listing-reject'
export const LISTING_PUBLISH = 'catalog.listing.listing-publish'
export const LISTING_UNPUBLISH = 'catalog.listing.listing-unpublish'

export const CHANNEL_VIEW = 'catalog.channels.channel-view'

// ------------------------------- GENERAL --------------------------------------------

export const WAREHOUSE_VIEW = 'warehouse.warehouses.warehouse-view'
export const WAREHOUSE_EDIT = 'warehouse.warehouses.warehouse-edit'
export const WAREHOUSE_ADD = 'warehouse.warehouses.warehouse-add'
export const WAREHOUSE_DELETE = 'warehouse.warehouses.warehouse-delete'

export const AREA_VIEW = 'warehouse.areas.area-view'
export const AREA_EDIT = 'warehouse.areas.area-edit'
export const AREA_ADD = 'warehouse.areas.area-add'
export const AREA_DELETE = 'warehouse.areas.area-delete'

export const ZONE_VIEW = 'warehouse.zones.zone-view'
export const ZONE_EDIT = 'warehouse.zones.zone-edit'
export const ZONE_ADD = 'warehouse.zones.zone-add'
export const ZONE_DELETE = 'warehouse.zones.zone-delete'

export const LOCATION_EDIT = 'warehouse.locations.location-edit'
export const LOCATION_ADD = 'warehouse.locations.location-add'
export const LOCATION_DELETE = 'warehouse.locations.location-delete'
export const LOCATION_VIEW = 'warehouse.locations.location-view'

export const CONTAINER_VIEW = 'warehouse.containers.container-view'
export const CONTAINER_EDIT = 'warehouse.containers.container-edit'
export const CONTAINER_ADD = 'warehouse.containers.container-add'
export const CONTAINER_DELETE = 'warehouse.containers.container-delete'

// ------------------------------- REPORTS --------------------------------------------

// ------------------------------- SETTINGS --------------------------------------------

export const ACCOUNT_VIEW = 'settings.accounts.account-view'
export const ACCOUNT_EDIT = 'settings.accounts.account-edit'

export const INTEGRATION_VIEW = 'settings.integrations.integration-view'
export const INTEGRATION_EDIT = 'settings.integrations.integration-edit'
export const INTEGRATION_ADD = 'settings.integrations.integration-add'
export const INTEGRATION_DELETE = 'settings.integrations.integration-delete'

export const CONDITION_VIEW = 'settings.conditions.condition-view'
export const CONDITION_EDIT = 'settings.conditions.condition-edit'
export const CONDITION_ADD = 'settings.conditions.condition-add'
export const CONDITION_DELETE = 'settings.conditions.condition-delete'

export const ADJUSTMENT_REASON_EDIT = 'settings.adjustment-reasons.adjustment-reason-edit'
export const ADJUSTMENT_REASON_ADD = 'settings.adjustment-reasons.adjustment-reason-add'
export const ADJUSTMENT_REASON_DELETE = 'settings.adjustment-reasons.adjustment-reason-delete'
export const ADJUSTMENT_REASON_VIEW = 'settings.adjustment-reasons.adjustment-reason-view'

export const CONTAINER_TYPE_VIEW = 'settings.container-types.container-type-view'
export const CONTAINER_TYPE_EDIT = 'settings.container-types.container-type-edit'
export const CONTAINER_TYPE_ADD = 'settings.container-types.container-type-add'
export const CONTAINER_TYPE_DELETE = 'settings.container-types.container-type-delete'

export const USER_VIEW = 'settings.users.user-view'
export const USER_EDIT = 'settings.users.user-edit'
export const USER_ADD = 'settings.users.user-add'
export const USER_DELETE = 'settings.users.user-delete'

export const ROLE_VIEW = 'settings.roles.role-view'
export const ROLE_EDIT = 'settings.roles.role-edit'
export const ROLE_ADD = 'settings.roles.role-add'
export const ROLE_DELETE = 'settings.roles.role-delete'

export const TYPE_VIEW = 'settings.payment-types.payment-type-view'
export const TYPE_EDIT = 'settings.payment-types.payment-type-edit'
export const TYPE_ADD = 'settings.payment-types.payment-type-add'
export const TYPE_DELETE = 'settings.payment-types.payment-type-delete'

export const FILTER_EDIT = 'settings.filters.filter-edit'
export const FILTER_ADD = 'settings.filters.filter-add'
export const FILTER_DELETE = 'settings.filters.filter-delete'

export const SETTINGS_FILE_UPLOAD = 'settings.files.file-upload'

// ------------------------------- ADMINISTRATOR --------------------------------------------

export const COMPANY_VIEW = 'administrator.companies.company-view'
export const COMPANY_EDIT = 'administrator.companies.company-edit'
export const COMPANY_ADD = 'administrator.companies.company-add'
export const COMPANY_DELETE = 'administrator.companies.company-delete'

export const REGION_EDIT = 'administrator.regions.region-edit'
export const REGION_ADD = 'administrator.regions.region-add'
export const REGION_DELETE = 'administrator.regions.region-delete'
