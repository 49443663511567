import React from 'react'
import PropTypes from 'prop-types'

import TableSplitButton from '~/components/TableSplitButton'

function BulkActionButton ({ printBarcode }) {
  const options = [
    {
      label: 'Print barcodes',
      'data-cy': 'printBarcodesMenu',
      onMenuClick: printBarcode
    }
  ]

  return (
    <TableSplitButton
      options={options}
      name="condition"
    />
  )
}

BulkActionButton.propTypes = {
  printBarcode: PropTypes.func.isRequired,
}

export default BulkActionButton
