import React from 'react'
import PropTypes from 'prop-types'

import Status from '~/components/Status'

import { ADD, NAMES, REMOVE } from '../../../constants/AdjustmentReason'

const COLOR = {
  [ADD]: 'success',
  [REMOVE]: 'error',
}

function AdjustmentReasonStatus ({ value }) {
  return (
    <Status
      label={NAMES[value]}
      color={COLOR[value]}
    />
  )
}

AdjustmentReasonStatus.propTypes = {
  value: PropTypes.oneOf([
    ADD,
    REMOVE,
  ]).isRequired
}

export default AdjustmentReasonStatus
