import React from 'react'
import PropTypes from 'prop-types'
import { map, pick, pipe, prop, propOr } from 'ramda'
import Grid from '@mui/material/Grid'
import { Filter } from 'storfox-filter'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { DateRangeField } from 'storfox-form-fields'

import { escapeAtob, getSeparatedDateRange, unescapeBtoa } from '~/utils'
import WarehouseMultiSelectField from '~/components/Fields/WarehouseMultiSelectField'
import Accordion from '~/components/Accordion'

import ReceivingStatusField from './ReceivingStatusField'
import ReceivingTypeField from './ReceivingTypeField'
import ReceivingItemField from './ReceivingItemField'

import { FILTER_NAME as name } from '../../constants'

const getWarehouseIds = pipe(
  prop('warehouses'),
  map(pick(['id', 'name']))
)

export const toParams = values => {
  const objectTypesList = propOr([], 'objectTypes', values)
  const objectsList = propOr([], 'objects', values)

  const objectTypes = objectTypesList.length ? unescapeBtoa(objectTypesList) : null
  const objects = objectsList.length ? unescapeBtoa(objectsList) : null

  const status = prop('status', values)
  const warehouseList = propOr([], 'warehouses', values)

  const warehouses = warehouseList.length ? unescapeBtoa(getWarehouseIds(values)) : null

  const [expectedAtStart, expectedAtEnd] = getSeparatedDateRange(prop('expectedAt', values))
  const [updatedAtStart, updatedAtEnd] = getSeparatedDateRange(prop('updatedAt', values))

  return {
    objectTypes,
    objects,
    status,
    warehouses,
    expectedAtStart,
    expectedAtEnd,
    updatedAtStart,
    updatedAtEnd
  }
}

export const toValues = params => {
  const objectTypes = escapeAtob(prop('objectTypes', params))
  const objects = escapeAtob(prop('objects', params))

  const status = prop('status', params)
  const warehouses = escapeAtob(prop('warehouses', params))

  const expectedAt = [prop('expectedAtStart', params), prop('expectedAtEnd', params)]
  const updatedAt = [prop('updatedAtStart', params), prop('updatedAtEnd', params)]

  return {
    objectTypes,
    objects,
    status,
    warehouses,
    expectedAt,
    updatedAt
  }
}

export const transformer = { toParams, toValues }

export const receiveFilterOptions = {
  name,
  transformer
}

function ReceiveFilterForm (props) {
  const { t } = useTranslation()

  return (
    <Filter {...props}>
      <Accordion title="General" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <ReceivingTypeField />
          </Grid>
          <Grid item={true} xs={12}>
            <ReceivingItemField />
          </Grid>
          <Grid item={true} xs={12}>
            <ReceivingStatusField />
          </Grid>
          <Grid item={true} xs={12}>
            <WarehouseMultiSelectField />
          </Grid>
        </Grid>
      </Accordion>
      <Accordion title="Date" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <Typography variant="body2" gutterBottom={true}>
              {t('Expected:')}:
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <DateRangeField name="expectedAt" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography variant="body2" gutterBottom={true}>
              {t('Updated')}:
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <DateRangeField name="updatedAt" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Accordion>
    </Filter>
  )
}

ReceiveFilterForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
}

export default ReceiveFilterForm
