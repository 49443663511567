import React, { useCallback } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { useField, useFormState } from 'react-final-form'
import Grid from '@mui/material/Grid'
import { DialogActions } from '@mui/material'

import DialogTitle from '~/components/DialogTitle'
import { Button, DiscardButton, PrimaryButton } from '~/components/Buttons'
import { withForm } from '~/components/Form'
import { SHIPPING } from '~/constants/locationTypes'
import { generateCdnUrl } from '~/utils'

import LocationField from '../Fields/LocationField'

const fieldName = 'location'

function CompleteDialogForm (props) {
  const {
    open,
    onClose,
    warehouseId,
    form,
    containerWithNextOrder,
    onNextSaleOrderMove,
    onCompleteAndRedirect,
    onCompleteAndPrint,
    awbUrl,
    commercialInvoiceUrl,
    onCompleteAndPrintInvoice
  } = props
  const { t } = useTranslation()

  const { handleSubmit } = form
  const { values } = useFormState()
  const locationField = useField(fieldName)
  const location = locationField.input.value

  const handlePrintAwb = useCallback(() => {
    onCompleteAndPrint(values).then(() => {
      window.open(generateCdnUrl(awbUrl), '_blank')
    })
  }, [awbUrl, onCompleteAndPrint, values])

  const handlePrintCommercial = useCallback(() => {
    onCompleteAndPrint(values).then(() => {
      window.open(generateCdnUrl(commercialInvoiceUrl), '_blank')
    })
  }, [commercialInvoiceUrl, onCompleteAndPrint, values])

  return (
    <Dialog
      open={open}
      fullWidth={true}
      onClose={onClose}
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle component="div">
          <Typography
            component="h2"
            variant="h6"
          >
            {t('Complete Packing')}
          </Typography>
          <Typography
            component="h6"
            variant="subtitle2"
          >
            {t('Move containers to shipment location and complete packing')}
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ minHeight: '30vh', paddingTop: '10px !important' }}>
          <LocationField
            name={fieldName}
            data-cy="toLocationId"
            label="Shipping location"
            params={{ warehouseId, locationType: SHIPPING }}
            ListboxProps={{ 'data-cy': 'locationList' }}
          />
        </DialogContent>

        <DialogActions>
          <Grid container={true} spacing={1}>
            <Grid item={true} xs={12}>
              <PrimaryButton
                fullWidth={true}
                disabled={!location}
                onClick={() => onCompleteAndRedirect(values)}
              >
                Done. Go to shipment
              </PrimaryButton>
            </Grid>
            {!commercialInvoiceUrl && (
              <Grid item={true} xs={12}>
                <PrimaryButton
                  fullWidth={true}
                  disabled={!location}
                  onClick={() => onCompleteAndPrintInvoice(values)}
                >
                  Done. Print commercial invoice
                </PrimaryButton>
              </Grid>
            )}
            {awbUrl && (
              <Grid item={true} xs={12}>
                <PrimaryButton
                  fullWidth={true}
                  disabled={!location}
                  onClick={handlePrintAwb}
                >
                  Done. Print AWB
                </PrimaryButton>
              </Grid>
            )}
            {commercialInvoiceUrl && (
              <Grid item={true} xs={12}>
                <PrimaryButton
                  fullWidth={true}
                  disabled={!location}
                  onClick={handlePrintCommercial}
                >
                  Done. Print Invoice
                </PrimaryButton>
              </Grid>
            )}
            {containerWithNextOrder && (
              <Grid item={true} xs={12}>
                <PrimaryButton
                  fullWidth={true}
                  disabled={!location}
                  onClick={() => onNextSaleOrderMove(values)}
                >
                  Move to next order in {containerWithNextOrder}
                </PrimaryButton>
              </Grid>
            )}
            <Grid item={true} xs={6}>
              <DiscardButton
                onClick={onClose}
                fullWidth={true}
              >
                Discard
              </DiscardButton>
            </Grid>

            <Grid item={true} xs={6}>
              <Button
                disabled={!location}
                fullWidth={true}
                variant="outlined"
                type="submit"
                data-cy="done"
              >
                Done
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  )
}

CompleteDialogForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCompleteAndRedirect: PropTypes.func.isRequired,
  form: PropTypes.object,
  warehouseId: PropTypes.number,
  containerWithNextOrder: PropTypes.string,
  awbUrl: PropTypes.string,
  onCompleteAndPrint: PropTypes.func.isRequired,
  onNextSaleOrderMove: PropTypes.func.isRequired,
  commercialInvoiceUrl: PropTypes.string,
  onCompleteAndPrintInvoice: PropTypes.func.isRequired
}

export default withForm(CompleteDialogForm)
