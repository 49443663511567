import React from 'react'
import { defaultTo, map, path, pluck, propOr, sum } from 'ramda'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import Table from '@mui/material/Table'
import { useField } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import Money from '~/components/Money'

function InvoiceSummary () {
  const { t } = useTranslation()
  const lineItemsField = useField('lineItems')
  const serviceField = useField('service')
  const serviceValue = serviceField.input.value
  const shipmentPrice = Number(propOr(0, 'price', serviceValue))

  const lineItemsValue = path(['input', 'value'], lineItemsField)
  const lineItems = lineItemsValue ? lineItemsValue.length : 0

  const unitsOrdered = pluck('quantity', lineItemsValue)
  const unitsOrderedInt = map(defaultTo(0), unitsOrdered)
  const unitsOrderedTotal = sum(unitsOrderedInt)

  const subTotal = map(
    item => defaultTo(0, item.quantity) * propOr(0, 'price', item),
    lineItemsValue
  )
  const subTotalAll = sum(subTotal)
  const total = subTotalAll + shipmentPrice
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>{t('Invoice Summary')}</TableCell>
          <TableCell />
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>{t('Line items returned')}</TableCell>
          <TableCell />
          <TableCell align="right">
            {lineItems}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('Units returned')}</TableCell>
          <TableCell />
          <TableCell align="right">
            {unitsOrderedTotal}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('Subtotal')}</TableCell>
          <TableCell />
          <TableCell align="right">
            <Money value={subTotalAll} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('Shipping charges')}</TableCell>
          <TableCell />
          <TableCell align="right">
            <Money value={shipmentPrice} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell><strong>{t('Total Refund Amount')}</strong></TableCell>
          <TableCell />
          <TableCell align="right">
            <Money value={total} />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export default InvoiceSummary
