import PropTypes from 'prop-types'
import React from 'react'
import AlertTitle from '@mui/material/AlertTitle'
import Alert from '@mui/material/Alert'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'

import PrintLink from '~/components/Instructions/PrintLink'

const InfoWrapperAlertStyled = styled(Alert)({
  maxWidth: '280px',
  backgroundColor: '#D9E8F3 !important',
  borderRadius: '4px',
  minHeight: '128px',
  marginLeft: '24px',
  marginRight: '11px',
  '& strong': {
    color: 'black'
  }
})

function ContainerTypeInstruction ({ containerTypePrintLink, disabled }) {
  return (
    <InfoWrapperAlertStyled severity="info" icon={false}>
      <AlertTitle>Create container</AlertTitle>
      <Box
        sx={{
          color: 'rgba(39, 50, 87, 0.7)',
          fontSize: '14px',
          lineHeight: '21px'
        }}
      >
        Scan container type starting with {' '}
        <strong>"CTT-"</strong> {' '}
        to create container with that container type. {' '} <br />

      </Box>
      <Box mt={1}>
        <PrintLink href={containerTypePrintLink} disabled={disabled}>
          Print container types
        </PrintLink>
      </Box>
    </InfoWrapperAlertStyled>
  )
}

ContainerTypeInstruction.propTypes = {
  containerTypePrintLink: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired
}

export default ContainerTypeInstruction
