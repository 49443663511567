import React from 'react'
import Grid from '@mui/material/Grid'
import { prop } from 'ramda'
import PropTypes from 'prop-types'

import ShipmentDetailMethodCard from './ShipmentDetailMethodCard'
import ShipmentDetailInfo from './ShipmentDetailInfo'
import ShipmentOrderDetails from './ShipmentOrderDetails'
import ShipmentDetailAddress from './ShipmentDetailAddress'
import ShipmentDetailFromAddress from './ShipmentDetailFromAddress'

function ShipmentDetailGeneral ({ shipment }) {
  const fromAddress = prop('fromAddress', shipment)
  const shippingAddress = prop('shippingAddress', shipment)
  const orderDetail = prop('orderDetail', shipment)

  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12} lg={8}>
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={12}>
            <ShipmentDetailMethodCard shipment={shipment} />
          </Grid>
          <Grid item={true} xs={6}>
            <ShipmentDetailFromAddress fromAddress={fromAddress} />
          </Grid>
          <Grid item={true} xs={6}>
            <ShipmentDetailAddress shippingAddress={shippingAddress} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item={true} xs={12} lg={4}>
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={12}>
            <ShipmentDetailInfo shipment={shipment} />
          </Grid>
          <Grid item={true} xs={12}>
            <ShipmentOrderDetails orderDetail={orderDetail} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

ShipmentDetailGeneral.propTypes = {
  shipment: PropTypes.object.isRequired
}

export default ShipmentDetailGeneral
