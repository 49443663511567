import React from 'react'
import PropTypes from 'prop-types'
import MuiAvatar from '@mui/material/Avatar'
import { createSmallImageUrl } from 'storfox-image-upload'

import blank from '~/assets/images/avatar/blank.png'

function Avatar ({ src, alt, ...props }) {
  return (
    <MuiAvatar
      variant="rounded"
      src={src ? createSmallImageUrl(src) : blank}
      alt={alt}
      {...props}
    />
  )
}

Avatar.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string.isRequired,
}

export default Avatar
