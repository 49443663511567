import React from 'react'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import { styled } from '@mui/material'

import TableDateFormat from '~/components/TableDateFormat'

const RootStyled = styled(Box)({
  display: 'flex',
  backgroundColor: 'rgb(244, 246, 248)',
  borderRadius: 10
})

function Comment ({ comment, author, date, className, ...rest }) {
  return (
    <RootStyled
      className={className}
      {...rest}
    >
      <Box sx={{
        ml: 2,
        p: 2,
        flexGrow: 1,
        backgroundColor: 'background.dark'
      }}>
        <Box display="flex" alignItems="center">
          <Link
            color="textPrimary"
            component={RouterLink}
            to="#"
            variant="h6"
          >
            {author}
          </Link>
          <Box flexGrow={1} />
          <Typography
            color="textSecondary"
            variant="caption"
          >
            <TableDateFormat date={date} />
          </Typography>
        </Box>
        <Typography
          variant="body1"
          color="textPrimary"
        >
          {comment}
        </Typography>
      </Box>
    </RootStyled>
  )
}

export default Comment

Comment.propTypes = {
  author: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  className: PropTypes.string
}
