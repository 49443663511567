import React from 'react'
import PropTypes from 'prop-types'
import Delete from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import { path, prop } from 'ramda'

import VerticalAlignment from '~/components/VerticalAlignment'
import Subtext from '~/components/Subtext'
import BorderTableRow from '~/components/BorderTableRow'

import AvatarProductPreview from '../AvatarProductPreview'

function PackingContainerUnitList ({ removable, containerNumber, products, onRemove }) {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell style={{ width: 75 }}>Image</TableCell>
            <TableCell style={{ minWidth: 250 }}>Variant</TableCell>
            <TableCell style={{ minWidth: 250 }}>Serial number</TableCell>
            <TableCell>Quantity</TableCell>
            <TableCell style={{ textAlign: 'right' }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((product, index) => {
            const id = prop('id', product)
            const unitNumber = path(['unit', 'unitNumber'], product)
            const quantity = prop('quantity', product)
            const serialNumber = path(['unit', 'serialNumber'], product)
            const unit = prop('unit', product)
            const variant = prop('variant', unit)
            const variantName = prop('name', variant)
            const sku = prop('sku', variant)
            const isLastRow = index === products.length - 1

            return (
              <BorderTableRow key={id} isLastRow={isLastRow}>
                <TableCell>
                  <AvatarProductPreview unit={unit} />
                </TableCell>
                <TableCell>
                  <VerticalAlignment
                    primary={variantName}
                    secondary={<Subtext>{sku} <br /> {unitNumber}</Subtext>}
                  />
                </TableCell>
                <TableCell>{serialNumber}</TableCell>
                <TableCell>{quantity}</TableCell>
                <TableCell>
                  {removable && (
                    <IconButton onClick={() => onRemove(containerNumber, product)} size="large">
                      <Delete />
                    </IconButton>
                  )}
                </TableCell>
              </BorderTableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

PackingContainerUnitList.defaultProps = {
  removable: true,
  products: []
}

PackingContainerUnitList.propTypes = {
  containerNumber: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  removable: PropTypes.bool
}

export default PackingContainerUnitList
