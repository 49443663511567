import React from 'react'
import PropTypes from 'prop-types'
import { useField } from 'react-final-form'
import { prop } from 'ramda'

import AreaField from '~/components/Fields/AreaField'
import * as API from '~/constants/api'

function WarehouseField ({ name, warehouseName, ...props }) {
  const warehouseField = useField(warehouseName)
  const warehouse = warehouseField.input.value
  const warehouseGuid = prop('guid', warehouse)

  return (
    <AreaField
      name={name}
      warehouse={warehouseName}
      disabled={!warehouseGuid}
      api={`${API.BILLING_PROFILE_AREA_LIST}?warehouse_guid=${warehouseGuid}`}
      {...props}
    />
  )
}

WarehouseField.propTypes = {
  name: PropTypes.string.isRequired,
  warehouseName: PropTypes.string.isRequired
}

export default WarehouseField
