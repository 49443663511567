import React from 'react'
import { useSnackbar } from 'storfox-snackbar'
import { useNavigate, useParams } from 'react-router-dom'
import { prop } from 'ramda'
import { useTranslation } from 'react-i18next'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'

import { useBillingProfileDetail, useBillingProfileUpdate } from '../../hooks/BillingProfile'
import { BillingProfileCreateSerializer, BillingProfileDetailSerializer } from '../../serializers/BillingProfile'
import BillingProfileUpdate from '../../components/BillingProfile/BillingProfileUpdate'

function BillingProfileUpdateContainer () {
  const { t } = useTranslation()
  const { guid } = useParams()
  const billingProfileDetail = useBillingProfileDetail(guid)
  const billingProfileUpdate = useBillingProfileUpdate(guid)
  const snackbar = useSnackbar()
  const navigate = useNavigate()

  const message = t('Successfully created')
  const handleSubmit = formValues => {
    return billingProfileUpdate.update(BillingProfileCreateSerializer(formValues))
      .then(() => snackbar({ message }))
      .then(() => navigate(ROUTES.BILLING_PROFILE_LIST_PATH))
  }

  const title = prop('name', billingProfileDetail.detail)

  const isLoading = billingProfileDetail.isLoading || billingProfileUpdate.isLoading

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title="Billing"
      activeNav={NAV.CONFIGURATION}
      isLoading={isLoading}
      breadcrumbs={breadcrumbs}
    >
      <BillingProfileUpdate
        initialValues={BillingProfileDetailSerializer(billingProfileDetail.detail)}
        onSubmit={handleSubmit}
        pageTitleLoading={billingProfileDetail.isLoading}
        pageTitle={title}
        isLoading={isLoading}
      />
    </DashboardLayout>
  )
}

export default BillingProfileUpdateContainer
