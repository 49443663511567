import React, { useCallback } from 'react'
import { path, prop, propOr } from 'ramda'
import isEqual from 'fast-deep-equal'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Link from '@mui/material/Link'
import { useParams } from 'react-router-dom'
import { useField } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { useTranslation } from 'react-i18next'
import { Card, styled } from '@mui/material'
import JsBarcode from 'jsbarcode'
import CardActions from '@mui/material/CardActions/CardActions'

import { CardHeader } from '~/components/Cards'
import { emptyArray } from '~/constants/empty'
import TextOverflow from '~/components/TextOverflow'
import { requiredText } from '~/utils/required'
import { Button } from '~/components/Buttons'

import VariantRow from './VariantRow'

import { generateBarcode } from '../../../utils/barcode'
import { generateSkuFromString } from '../../../utils/sku'

const getUniqueSku = (item, postfix) => {
  const variantStr = generateSkuFromString(item, 2)

  return `${variantStr}-${postfix}`.toUpperCase()
}

const getOptionSelect = (options) => {
  return options.map(item => {
    const optionOptions = propOr([], 'options', item)
    return {
      label: prop('name', item),
      values: optionOptions.map(option => ({ name: option, option }))
    }
  })
}

const TableStyled = styled(Table)({
  minWidth: 900,
  '& th': {
    whiteSpace: 'nowrap'
  }
})

function ProductUpdateFormVariantsTable () {
  const { t } = useTranslation()
  const { id: productId } = useParams()

  const multiVariantsField = useField('isMultiVariant')
  const multiVariantsValue = multiVariantsField.input.value

  const defaultValuesField = useField('defaultValues')
  const defaultValue = defaultValuesField.input.value

  const variantsField = useField('variants')
  const variantsValue = variantsField.input.value || emptyArray
  const variantsOnChange = useCallback(path(['input', 'onChange'], variantsField), [])

  const isMultiVariant = prop('isMultiVariant', defaultValue)

  const optionsField = useField('options')
  const optionsValue = path(['input', 'value'], optionsField) || emptyArray

  const nameField = useField('name')
  const nameValue = path(['input', 'value'], nameField) || emptyArray

  const multiVariant = (
    isMultiVariant ||
    multiVariantsValue
  )

  const handleSkuGenerate = event => {
    event.preventDefault()

    const maximumId = variantsValue.reduce((acc, variant) => {
      const id = prop('id', variant)

      return id && id > acc ? id : acc
    }, 0)

    const newVariantsValue = variantsValue.reduce((acc, variant) => {
      const { variants, currentIndex } = acc
      const name = prop('name', variant)
      const maximumGeneratedId = maximumId + currentIndex
      const variantId = prop('id', variant)
      const predefinedSku = prop('sku', variant)
      const postfixId = variantId || maximumGeneratedId
      const postfix = `${productId}-${postfixId}`

      const newCurrentIndex = variantId ? currentIndex : currentIndex + 1
      const sku = predefinedSku || getUniqueSku(name, postfix)

      return { variants: [...variants, { ...variant, sku }], currentIndex: newCurrentIndex }
    }, { variants: [], currentIndex: 1 })

    const newVariants = newVariantsValue.variants

    variantsField.input.onChange(newVariants)
  }

  const handleBarcodeGenerate = event => {
    event.preventDefault()

    const newVariantsValue = variantsValue.map(variant => {
      const variantId = prop('id', variant)
      const existingBarcode = prop('barcode', variant)
      if (variantId) {
        return { ...variant, barcode: existingBarcode }
      } else {
        const someData = {}
        const randomBarcode = generateBarcode().substr(0, 12)
        JsBarcode(someData, randomBarcode, { format: 'EAN13' })
        const newBarcode = someData.encodings.reduce((a, b) => a + b.text, '')

        return { ...variant, barcode: newBarcode }
      }
    })

    variantsField.input.onChange(newVariantsValue)
  }

  const handleSupplyPriceCopy = event => {
    event.preventDefault()

    if (variantsValue.length) {
      const supplyPrice = path([0, 'supplyPrice'], variantsValue)
      const newVariantsValue = variantsValue.map(variant => ({ ...variant, supplyPrice }))

      variantsField.input.onChange(newVariantsValue)
    }
  }

  const handleRetailPriceCopy = event => {
    event.preventDefault()

    if (variantsValue.length) {
      const retailPrice = path([0, 'retailPrice'], variantsValue)
      const newVariantsValue = variantsValue.map(variant => ({ ...variant, retailPrice }))

      variantsField.input.onChange(newVariantsValue)
    }
  }

  const optionSelectPickerValues = getOptionSelect(optionsValue)

  return (
    <>
      <Card>
        <CardHeader title="Items" />
        <TableContainer component={Paper}>
          <TableStyled>
            <TableHead>
              <TableRow>
                <TableCell>{t('Image')}</TableCell>
                <TableCell>{t('Variant')}</TableCell>
                <TableCell>
                  <div>{requiredText(t('SKU'))}</div>
                  {multiVariant && (
                    <Link
                      component="button"
                      underline="always"
                      onClick={handleSkuGenerate}>
                      {t('Generate sku')}
                    </Link>
                  )}
                </TableCell>
                <TableCell>
                  <div>{requiredText(t('Barcode'))}</div>
                  {multiVariant && (
                    <Link
                      component="button"
                      underline="always"
                      onClick={handleBarcodeGenerate}>
                      {t('Generate barcode')}
                    </Link>
                  )}
                </TableCell>
                <TableCell>
                  <div>{t('Supply price')}</div>
                  {multiVariant && (
                    <Link
                      component="button"
                      underline="always"
                      onClick={handleSupplyPriceCopy}
                    >
                      {t('Copy to all')}
                    </Link>
                  )}
                </TableCell>
                <TableCell>
                  <div>{t('Retail price')}</div>
                  {multiVariant && (
                    <Link
                      component="button"
                      underline="always"
                      onClick={handleRetailPriceCopy}>
                      {t('Copy to all')}
                    </Link>
                  )}
                </TableCell>
                <TableCell sx={{ maxWidth: '100px' }}>
                  <TextOverflow noWrap={true} selfTooltip={true}>{t('Options')}</TextOverflow>
                </TableCell>
                <TableCell>{t('Enabled')}</TableCell>
                <TableCell>{t('Remove')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <FieldArray name="variants">
                {({ fields }) => (
                  <>
                    {fields.map((name, index) => {
                      return (
                        <VariantRow
                          optionSelectPickerValues={optionSelectPickerValues}
                          index={index}
                          name={name}
                          variantsValue={variantsValue}
                          fields={fields}
                        />
                      )
                    })}
                  </>
                )}
              </FieldArray>
            </TableBody>
          </TableStyled>
        </TableContainer>
        {multiVariantsValue && (
          <CardActions sx={{ py: 2 }}>
            <Button
              data-cy="addVariant"
              size="small"
              onClick={() => variantsOnChange([...variantsValue, { name: nameValue, enabled: true }])}
            >
              Add variant
            </Button>
          </CardActions>
        )}
      </Card>
    </>
  )
}

export default React.memo(ProductUpdateFormVariantsTable, isEqual)
