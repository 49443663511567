import React from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import PropTypes from 'prop-types'
import { useConfirm } from 'storfox-confirm-dialog'
import { ALTER_ERROR, ALTER_SUCCESS, useSnackbar } from 'storfox-snackbar'

import { MoreButton } from '~/components/Buttons'
import ErrorLink from '~/components/ErrorLink'

function MoreDelete ({ onRemove, ...props }) {
  const onConfirm = useConfirm()
  const snackbar = useSnackbar()

  const handleRemove = () => {
    const message = 'Are you sure, you want to delete selected container type?'

    onConfirm({ message })
      .agree(() => {
        onRemove()
          .then(() => snackbar({ message: 'Successfully deleted', type: ALTER_SUCCESS }))
          .catch(error => snackbar({ message: <ErrorLink error={error} />, type: ALTER_ERROR }))
      })
      .disagree()
  }
  return (
    <MoreButton>
      {onClose => (
        <List>
          <ListItem
            disabled={false}
            button={true}
            onClick={handleRemove}
            {...props}
          >
            <ListItemText primary="Delete" />
          </ListItem>
        </List>
      )}
    </MoreButton>
  )
}
MoreDelete.propTypes = {
  onRemove: PropTypes.func
}

export default MoreDelete
