import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import SaleOrderListContainer from './containers/SaleOrderListContainer'
import SaleOrderCreateContainer from './containers/SaleOrderCreateContainer'
import SaleOrderDetailContainer from './containers/SaleOrderDetailContainer'
import SaleOrderUpdateContainer from './containers/SaleOrderUpdateContainer'
import SaleOrderImportContainer from './containers/SaleOrderImportContainer'

export default () => [
  {
    layout: AuthLayout,
    path: ROUTES.SALE_ORDER_LIST_PATH,
    component: SaleOrderListContainer
  },
  {
    layout: AuthLayout,
    path: ROUTES.SALE_ORDER_CREATE_PATH,
    component: SaleOrderCreateContainer
  },
  {
    layout: AuthLayout,
    path: ROUTES.SALE_ORDER_DETAIL_PATH,
    component: SaleOrderDetailContainer
  },
  {
    layout: AuthLayout,
    path: ROUTES.SALE_ORDER_UPDATE_PATH,
    component: SaleOrderUpdateContainer
  },
  {
    layout: AuthLayout,
    path: ROUTES.SALE_ORDER_IMPORT_PATH,
    component: SaleOrderImportContainer
  }
]
