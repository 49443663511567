import { Card } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'

import CountryField from '~/components/Fields/CountryField'
import EmailField from '~/components/Fields/EmailField'
import PhoneNumberField from '~/components/Fields/PhoneNumberField'
import SingleImageUploadField from '~/components/Fields/SingleImageUploadField'
import StatusField from '~/components/Fields/StatusField'
import TextField from '~/components/Fields/TextField'
import { CardHeader } from '~/components/Cards'
import PageTitle from '~/components/PageTitle'
import { DiscardButton, SaveButton } from '~/components/Buttons'
import Container, { Content, Header } from '~/components/Container'
import { withForm } from '~/components/Form'
import * as NAV from '~/constants/nav-titles'

export const SUPPLIER_CREATE_FIELDS = [
  'companyName',
  'shortName',
  'email',
  'phoneNumber',
  'address',
  'region',
  'country',
  'city',
  'imagePath',
  'contactFirstName',
  'contactLastName',
  'contactEmail',
  'contactPhoneNumber',
  'status',
  'zipcode',
  'referenceId'
]

function SupplierCreateForm ({ isLoading, form }) {
  const { t } = useTranslation()
  const { handleSubmit } = form

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Container>
        <Header>
          <PageTitle
            pageTitle={t('New Supplier')}
            parentTitle={t(NAV.SUPPLIERS)}
            rightButton={(
              <>
                <DiscardButton disabled={isLoading} />
                <SaveButton disabled={isLoading} />
              </>
            )}
          />
        </Header>

        <Content>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12} lg={8}>
              <Box mb={3}>
                <Card>
                  <CardHeader title={t('Information')} />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12}>
                        <TextField
                          data-cy="companyName"
                          name="companyName"
                          label={t('Company Name')}
                          required={true}
                        />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <TextField
                          data-cy="shortName"
                          name="shortName"
                          label={t('Short name')}
                          required={true}
                        />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <TextField
                          data-cy="referenceId"
                          name="referenceId"
                          label={t('Reference id')}
                        />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <EmailField
                          data-cy="email"
                          name="email"
                          label={t('Email')}
                        />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <PhoneNumberField
                          data-cy="phoneNumber"
                          name="phoneNumber"
                          label={t('Phone number')}
                        />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <TextField
                          data-cy="address"
                          name="address"
                          label={t('Address')}
                          multiline={true}
                          minRows={3}
                        />
                      </Grid>

                      <Grid item={true} xs={12} lg={6}>
                        <TextField
                          data-cy="region"
                          name="region"
                          label="Region/Neighborhood"
                        />
                      </Grid>

                      <Grid item={true} xs={12} lg={6}>
                        <TextField
                          data-cy="city"
                          name="city"
                          label="City"
                          country="country"
                        />
                      </Grid>

                      <Grid item={true} xs={12} lg={6}>
                        <TextField
                          data-cy="zipcode"
                          name="zipcode"
                          label={t('Zipcode')}
                        />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <CountryField
                          data-cy="country"
                          name="country"
                          label={t('Country')}
                        />
                      </Grid>

                    </Grid>
                  </CardContent>
                </Card>
              </Box>
              <Box mb={3}>
                <Card>
                  <CardHeader title={t('Primary Contact')} />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12} lg={6}>
                        <TextField
                          data-cy="contactFirstName"
                          name="contactFirstName"
                          label={t('First name')}
                        />
                      </Grid>

                      <Grid item={true} xs={12} lg={6}>
                        <TextField
                          data-cy="contactLastName"
                          name="contactLastName"
                          label="Last name"
                        />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <EmailField
                          data-cy="contactEmail"
                          name="contactEmail"
                          label={t('Email')}
                        />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <PhoneNumberField
                          data-cy="contactPhoneNumber"
                          name="contactPhoneNumber"
                          label={t('Phone number')}
                        />
                      </Grid>

                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
            <Grid item={true} xs={12} lg={4}>
              <Box mb={3}>
                <Card>
                  <CardHeader title={t('Image')} />
                  <Divider />
                  <CardContent>
                    <SingleImageUploadField name="imagePath" accept="image/png, image/jpeg" />
                  </CardContent>
                </Card>
              </Box>
              <Box mb={3}>
                <Card>
                  <CardHeader title={t('Settings')} />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12}>
                        <StatusField required={true} />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Grid>

          </Grid>
          <Box mb={3} />
        </Content>
      </Container>
    </form>
  )
}

SupplierCreateForm.propTypes = {
  form: PropTypes.object,
  isLoading: PropTypes.bool.isRequired
}

export default withForm(SupplierCreateForm)
