import React from 'react'
import QRCode from 'qrcode.react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material'

const SkuBoxStyled = styled(Box)({
  textAlign: 'center',
  paddingTop: 10,
  color:'black',
  fontSize: '16px',
  fontWeight:'bold'
})

function QrCodePlaceholder ({ defaultValue }) {
  return (
    <Grid container={true} spacing={2}>
      <Grid item={true} xs={12} lg={6}>
        <Card sx={{ textAlign: 'center', px: 0, py: 2 }}>
          <Box sx={{ opacity: 0.2, display: 'inline-block', textAlign: 'center' }}>
            <QRCode value={defaultValue} renderAs="svg" />
            <SkuBoxStyled>{defaultValue}</SkuBoxStyled>
          </Box>
        </Card>
      </Grid>
    </Grid>
  )
}

QrCodePlaceholder.defaultProps = {
  defaultValue: '0000000000000'
}

QrCodePlaceholder.propTypes = {
  defaultValue: PropTypes.string.isRequired
}

export default QrCodePlaceholder
