import React from 'react'
import { defaultTo, map, pluck, propOr, sum } from 'ramda'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import Table from '@mui/material/Table'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import CurrencyMoney from '~/components/Money/CurrencyMoney'

function InvoiceSummary ({ shippingFee, lineItems, currency, extraFees, totalPrice, updated }) {
  const { t } = useTranslation()
  const lineItemsQuantity = lineItems.length

  const unitsOrdered = pluck('quantity', lineItems)
  const unitsOrderedInt = map(defaultTo(0), unitsOrdered)
  const unitsOrderedTotal = sum(unitsOrderedInt)
  const discounts = map(
    item => {
      const quantity = defaultTo(0, item.quantity)
      const discount = propOr(0, 'discount', item)
      const price = propOr(0, 'price', item)
      const discountPrice = (discount / 100) * price

      return quantity * discountPrice
    },
    lineItems
  )

  const taxes = map(
    item => {
      const quantity = defaultTo(0, item.quantity)
      const discount = propOr(0, 'discount', item)
      const price = propOr(0, 'price', item)
      const unitCost = (price - (discount / 100) * price)
      const tax = propOr(0, 'tax', item) / 100

      return quantity * unitCost * tax
    },
    lineItems
  )

  const discountsAll = sum(discounts)
  const taxesAll = sum(taxes)

  const subTotal = map(
    item => {
      const quantity = defaultTo(0, item.quantity)
      const price = propOr(0, 'price', item)
      return quantity * price
    },
    lineItems
  )
  const subTotalSum = sum(subTotal)
  const subTotalAll = subTotalSum - discountsAll + taxesAll + shippingFee + extraFees

  return (
    <Table size="small">
      <TableBody>
        <TableRow>
          <TableCell>{t('Line items')}</TableCell>
          <TableCell />
          <TableCell align="right">
            {lineItemsQuantity}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('Units ordered')}</TableCell>
          <TableCell />
          <TableCell align="right">
            {unitsOrderedTotal}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('Subtotal')}</TableCell>
          <TableCell />
          <TableCell align="right">
            <CurrencyMoney currency={currency} value={subTotalSum} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('Discounts')}</TableCell>
          <TableCell />
          <TableCell align="right">
            <CurrencyMoney currency={currency} value={discountsAll} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('Taxes')}</TableCell>
          <TableCell />
          <TableCell align="right">
            <CurrencyMoney currency={currency} value={taxesAll} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('Extra Fees')}</TableCell>
          <TableCell />
          <TableCell align="right">
            <CurrencyMoney currency={currency} value={extraFees} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('Shipping charges')}</TableCell>
          <TableCell />
          <TableCell align="right">
            <CurrencyMoney currency={currency} value={shippingFee} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <strong>{t('Total')}</strong>
          </TableCell>
          <TableCell />
          <TableCell align="right">
            <CurrencyMoney currency={currency} value={updated ? subTotalAll : totalPrice} />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

InvoiceSummary.defaultProps = {
  lineItems: []
}

InvoiceSummary.propTypes = {
  currency: PropTypes.string,
  lineItems: PropTypes.array.isRequired,
  shippingFee: PropTypes.number.isRequired,
  extraFees: PropTypes.number.isRequired,
  totalPrice: PropTypes.number.isRequired,
  updated: PropTypes.bool
}

export default InvoiceSummary
