import React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'
import { prop } from 'ramda'

import { DashboardLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'
import useMessages from '~/hooks/useMessages'
import ErrorLink from '~/components/ErrorLink'
import { INTEGRATION_TABS } from '~/constants/tabs'

import {
  useIntegrationHistory,
  useSallaIntegrationDetail,
  useSallaIntegrationRemove,
  useSallaIntegrationUpdate,
  useSallaIntegrationRetryOrder
} from '../../hooks'
import SallaIntegrationUpdateForm from '../../components/Salla/SallaUpdate/SallaIntegrationUpdateForm'

function SallaIntegrationUpdateContainer () {
  const { guid, tab } = useParams()
  const messages = useMessages()

  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const integrationDetail = useSallaIntegrationDetail(guid)
  const integrationUpdate = useSallaIntegrationUpdate(guid)
  const integrationRetryOrder = useSallaIntegrationRetryOrder(guid)
  const integrationRemove = useSallaIntegrationRemove()

  const title = prop('name', integrationDetail.detail)

  const detailPath =
    generatePath(ROUTES.INTEGRATION_UPDATE_PATH, { guid, type: 'salla', tab: INTEGRATION_TABS.GENERAL })

  const historyAutoSend = Boolean(guid && tab === INTEGRATION_TABS.LOGS)
  const integrationHistory = useIntegrationHistory(guid, historyAutoSend)

  const handleSubmit = ({ credential, ...formValues }) =>
    integrationUpdate.update(formValues)
      .then(() => {
        snackbar({ message: messages.UPDATE_SUCCESS })
        navigate(detailPath)
      })
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const isLoading = (
    integrationDetail.isLoading ||
    integrationUpdate.isLoading
  )

  const handleRemove = () =>
    integrationRemove.delete({ guids: [guid] })
      .then(() => navigate(ROUTES.INTEGRATION_LIST_PATH, { replace: true }))

  const handleRetry = requestBody => {
    integrationRetryOrder.create(requestBody)
      .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })
  }

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      isLoading={integrationDetail.isLoading}
      breadcrumbs={breadcrumbs}
      activeNav={NAV.INTEGRATION}
    >
      <SallaIntegrationUpdateForm
        isLoading={isLoading}
        pageTitle={title}
        pageTitleLoading={integrationDetail.isLoading}
        onSubmit={handleSubmit}
        onRemove={handleRemove}
        initialValues={integrationDetail.detail}
        history={integrationHistory}
        onRetry={handleRetry}
        retryLoading={integrationRetryOrder.isLoading}
      />
    </DashboardLayout>
  )
}

export default SallaIntegrationUpdateContainer
