import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material'

import MinusCircle from './svg/minus-circle.svg'

const MinusCircleIconStyled = styled(MinusCircle, {
  shouldForwardProp: (propName) => propName !== 'color'
})(({ theme, color }) => ({
  ...(color === 'primary' && {
    fill: theme.palette.primary.main
  }),
  ...(color === 'secondary' && {
    fill: theme.palette.secondary.main
  })
}))

function MinusCircleIcon ({ color = 'primary', ...props }) {
  return (
    <MinusCircleIconStyled color={color} {...props} />
  )
}

MinusCircleIcon.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary'])
}

export default MinusCircleIcon
