export const errors = [
  {
    'id': 260,
    'code': 'ERC0048',
    'description': 'Unit already registered in the system.',
    'created_at': '2022-01-17T12:28:51.077533',
    'updated_at': '2022-01-17T12:28:51.077553',
    'title': 'Receive'
  },
  {
    'id': 259,
    'code': 'ERC0047',
    'description': "When barcoding strategy is EACH, unit's quantity cannot be greater than 1.",
    'created_at': '2022-01-17T12:28:27.750664',
    'updated_at': '2022-01-17T12:28:27.750677',
    'title': 'Receive'
  },
  {
    'id': 258,
    'code': 'ERC0046',
    'description': 'Serial number is already registered.',
    'created_at': '2022-01-17T12:28:11.335071',
    'updated_at': '2022-01-17T12:28:11.335085',
    'title': 'Recieve'
  },
  {
    'id': 257,
    'code': 'ERC0045',
    'description': 'Duplicate serial numbers found.',
    'created_at': '2022-01-17T12:27:50.698201',
    'updated_at': '2022-01-17T12:27:50.698215',
    'title': 'Receive'
  },
  {
    'id': 256,
    'code': 'ERC0044',
    'description': 'Serial Number is missing.',
    'created_at': '2022-01-17T12:27:12.929670',
    'updated_at': '2022-01-17T12:27:12.929683',
    'title': 'Receive'
  },
  {
    'id': 255,
    'code': 'ERC0043',
    'description': 'Rejected item should be received to Receiving or Quarantine location.',
    'created_at': '2022-01-17T12:26:52.288706',
    'updated_at': '2022-01-17T12:26:52.288720',
    'title': 'Receive'
  },
  {
    'id': 254,
    'code': 'ERC0042',
    'description': 'You cannot place variants with different expiration dates to the same container.',
    'created_at': '2022-01-17T12:26:32.988421',
    'updated_at': '2022-01-17T12:26:32.988453',
    'title': 'Receive'
  },
  {
    'id': 253,
    'code': 'ERC0041',
    'description': 'You cannot place expiring variants together with variants without expiration date.',
    'created_at': '2022-01-17T12:26:12.805503',
    'updated_at': '2022-01-17T12:26:12.805554',
    'title': 'Receive'
  },
  {
    'id': 252,
    'code': 'ERC0040',
    'description': 'You cannot place variants without expiration date together with expiring variants.',
    'created_at': '2022-01-17T12:25:54.750539',
    'updated_at': '2022-01-17T12:25:54.750553',
    'title': 'Receive'
  },
  {
    'id': 251,
    'code': 'ERC0039',
    'description': 'Place variants with expiration into different containers',
    'created_at': '2022-01-17T12:25:26.749103',
    'updated_at': '2022-01-17T12:25:26.749124',
    'title': 'Receive'
  },
  {
    'id': 250,
    'code': 'ERC0038',
    'description': 'Invalid Data.Variant in line items is deleted.',
    'created_at': '2022-01-17T12:25:06.837276',
    'updated_at': '2022-01-17T12:25:06.837289',
    'title': 'Receive'
  },
  {
    'id': 249,
    'code': 'ERC0037',
    'description': 'Invalid Data. Variant in line items not found',
    'created_at': '2022-01-17T12:24:40.212392',
    'updated_at': '2022-01-17T12:24:40.212406',
    'title': 'Receive'
  },
  {
    'id': 248,
    'code': 'ERC0036',
    'description': 'Invalid Data. Unit appeared more than once.',
    'created_at': '2022-01-17T12:24:19.164126',
    'updated_at': '2022-01-17T12:24:19.164144',
    'title': 'Receive'
  },
  {
    'id': 247,
    'code': 'ERC0035',
    'description': 'Invalid Data. Unit quantity not equality.',
    'created_at': '2022-01-17T12:24:02.047264',
    'updated_at': '2022-01-17T12:24:02.047277',
    'title': 'Receive'
  },
  {
    'id': 246,
    'code': 'ERC0034',
    'description': "Invalid Data. Units company_id doesn't match with units location company_id.",
    'created_at': '2022-01-17T12:23:40.617220',
    'updated_at': '2022-01-17T12:23:40.617233',
    'title': 'Receive'
  },
  {
    'id': 245,
    'code': 'ERC0033',
    'description': 'Invalid Data. Unit not found in unit map.',
    'created_at': '2022-01-17T12:23:22.611965',
    'updated_at': '2022-01-17T12:23:22.611978',
    'title': 'Receive'
  },
  {
    'id': 244,
    'code': 'ERC0032',
    'description': 'Invalid Data. Unit guid is not match with units in line items.',
    'created_at': '2022-01-17T12:23:06.238801',
    'updated_at': '2022-01-17T12:23:06.238815',
    'title': 'Receive'
  },
  {
    'id': 243,
    'code': 'ERC0031',
    'description': 'Invalid Action. Track Expiry disabled.',
    'created_at': '2022-01-17T12:22:49.329718',
    'updated_at': '2022-01-17T12:22:49.329732',
    'title': 'Receive'
  },
  {
    'id': 242,
    'code': 'ERC0030',
    'description': 'Invalid Data. Container Code not found.',
    'created_at': '2022-01-17T12:22:04.281670',
    'updated_at': '2022-01-17T12:22:04.281682',
    'title': 'Receive'
  },
  {
    'id': 241,
    'code': 'ERC0029',
    'description': 'Invalid Data. Expiry Date not found.',
    'created_at': '2022-01-17T12:21:17.984696',
    'updated_at': '2022-01-17T12:21:17.984709',
    'title': 'Receive'
  },
  {
    'id': 240,
    'code': 'ERC0028',
    'description': 'Invalid Data. Containers Expired.',
    'created_at': '2022-01-17T12:20:53.813840',
    'updated_at': '2022-01-17T12:20:53.813859',
    'title': 'Receive'
  },
  {
    'id': 239,
    'code': 'ERC0027',
    'description': 'Invalid Action. Container is assigned to different location.',
    'created_at': '2022-01-17T12:20:25.811234',
    'updated_at': '2022-01-17T12:20:25.811247',
    'title': 'Receive'
  },
  {
    'id': 238,
    'code': 'ERC0026',
    'description': 'Invalid Data. Container not found.',
    'created_at': '2022-01-17T12:20:05.185213',
    'updated_at': '2022-01-17T12:20:05.185227',
    'title': 'Receive'
  },
  {
    'id': 237,
    'code': 'ERC0025',
    'description': "Invalid Data. Line Item doesn't exists.",
    'created_at': '2022-01-17T12:19:19.291426',
    'updated_at': '2022-01-17T12:19:19.291440',
    'title': 'Receive'
  },
  {
    'id': 236,
    'code': 'ERC0024',
    'description': 'Invalid Data. Stock Transfer Not Found.',
    'created_at': '2022-01-17T12:18:57.315220',
    'updated_at': '2022-01-17T12:18:57.315234',
    'title': 'Receive'
  },
  {
    'id': 235,
    'code': 'ERC0023',
    'description': 'Received quantity is bigger than receiving quantity.',
    'created_at': '2022-01-17T12:18:42.578449',
    'updated_at': '2022-01-17T12:18:42.578461',
    'title': 'Receive'
  },
  {
    'id': 234,
    'code': 'ERC0022',
    'description': 'Invalid Data. Serial number is not match.',
    'created_at': '2022-01-17T12:18:26.512065',
    'updated_at': '2022-01-17T12:18:26.512095',
    'title': 'Receive'
  },
  {
    'id': 233,
    'code': 'ERC0021',
    'description': 'Invalid Data. Condition not found.',
    'created_at': '2022-01-17T12:17:44.898602',
    'updated_at': '2022-01-17T12:17:44.898617',
    'title': 'Receive'
  },
  {
    'id': 232,
    'code': 'ERC0020',
    'description': "Invalid Data. Unit number is not the same as variant's barcode.",
    'created_at': '2022-01-17T12:17:27.037375',
    'updated_at': '2022-01-17T12:17:27.037388',
    'title': 'Receive'
  },
  {
    'id': 231,
    'code': 'ERC0019',
    'description': 'Invalid Data. Unit not found.',
    'created_at': '2022-01-17T12:16:58.010174',
    'updated_at': '2022-01-17T12:16:58.010194',
    'title': 'Receive'
  },
  {
    'id': 230,
    'code': 'ERC0018',
    'description': 'Invalid Action. Location type is not Reciving.',
    'created_at': '2022-01-17T12:16:31.296737',
    'updated_at': '2022-01-17T12:16:31.296750',
    'title': 'Receive'
  },
  {
    'id': 229,
    'code': 'ERC0017',
    'description': 'Invalid Action. Rejected Units should received to QUARANTINE location.',
    'created_at': '2022-01-17T12:15:58.994047',
    'updated_at': '2022-01-17T12:15:58.994060',
    'title': 'Receive'
  },
  {
    'id': 228,
    'code': 'ERC0016',
    'description': 'Invalid Data. Location Not Found.',
    'created_at': '2022-01-17T12:15:38.982397',
    'updated_at': '2022-01-17T12:15:38.982419',
    'title': 'Receive'
  },
  {
    'id': 227,
    'code': 'ERC0015',
    'description': 'Container Number is not Match.',
    'created_at': '2022-01-17T12:13:33.358763',
    'updated_at': '2022-01-17T12:13:33.358777',
    'title': 'Receive'
  },
  {
    'id': 226,
    'code': 'ERC0014',
    'description': 'Invalid Data. Stock Receipt not found.',
    'created_at': '2022-01-17T12:13:06.426724',
    'updated_at': '2022-01-17T12:13:06.426737',
    'title': 'Receive'
  },
  {
    'id': 225,
    'code': 'ERC0013',
    'description': 'Invalid Data. Returns not found.',
    'created_at': '2022-01-17T12:12:45.462906',
    'updated_at': '2022-01-17T12:12:45.462919',
    'title': 'Receive'
  },
  {
    'id': 224,
    'code': 'ERC0012',
    'description': 'Invalid Data. PURCHASE_ORDER and GATEPASS not found.',
    'created_at': '2022-01-17T12:12:26.316706',
    'updated_at': '2022-01-17T12:12:26.316730',
    'title': 'Receive'
  },
  {
    'id': 223,
    'code': 'ERC0011',
    'description': 'Invalid Data. PURCHASE_ORDER not found.',
    'created_at': '2022-01-17T12:12:03.591769',
    'updated_at': '2022-01-17T12:12:03.591791',
    'title': 'Receive'
  },
  {
    'id': 222,
    'code': 'ERC0010',
    'description': 'Invalid Data. STOCK_RECEIPT not found.',
    'created_at': '2022-01-17T11:56:52.218746',
    'updated_at': '2022-01-17T11:56:52.218761',
    'title': 'Receive'
  },
  {
    'id': 221,
    'code': 'ERC0009',
    'description': 'Invalid Action. Receiving Type is BLIND_RECEIVE(product not found) and GatePass.',
    'created_at': '2022-01-17T11:56:33.807095',
    'updated_at': '2022-01-17T11:56:33.807108',
    'title': 'Receive'
  },
  {
    'id': 220,
    'code': 'ERC0008',
    'description': 'Invalid Action. Receiving could not be completed because receiving type is not UNDER_RECEIVE ' +
      'or OVER_AND_UNDER_RECEIVE.',
    'created_at': '2022-01-17T11:56:05.918122',
    'updated_at': '2022-01-17T11:56:05.918143',
    'title': 'Receive'
  },
  {
    'id': 219,
    'code': 'ERC0007',
    'description': 'Invalid Action. Receiving type is INVOICE_RECEIVE.',
    'created_at': '2022-01-17T11:55:46.286375',
    'updated_at': '2022-01-17T11:55:46.286387',
    'title': 'Receive'
  },
  {
    'id': 218,
    'code': 'ERC0006',
    'description': 'Invalid Action. Receiving type is not OVER_RECEIVE.',
    'created_at': '2022-01-17T11:55:21.096878',
    'updated_at': '2022-01-17T11:55:21.096892',
    'title': 'Receive'
  },
  {
    'id': 217,
    'code': 'ERC0005',
    'description': 'Invalid Action. Receiving type is not BLIND_RECEIVE.Product Not Found.',
    'created_at': '2022-01-17T11:55:03.322696',
    'updated_at': '2022-01-17T11:55:03.322710',
    'title': 'Receive'
  },
  {
    'id': 216,
    'code': 'ERC0004',
    'description': 'Gatepass number does not match with generated gatepasses.',
    'created_at': '2022-01-17T11:54:42.382430',
    'updated_at': '2022-01-17T11:54:42.382450',
    'title': 'Receive'
  },
  {
    'id': 215,
    'code': 'ERC0003',
    'description': 'Gatepass had been generated for purchase order.',
    'created_at': '2022-01-17T11:54:22.641296',
    'updated_at': '2022-01-17T11:54:22.641321',
    'title': 'Receive'
  },
  {
    'id': 214,
    'code': 'ERC0002',
    'description': 'Can not be received, Purchase Order status should be PROGRESS.',
    'created_at': '2022-01-17T11:53:43.898913',
    'updated_at': '2022-01-17T11:53:43.898926',
    'title': 'Receive'
  },
  {
    'id': 213,
    'code': 'ERC0001',
    'description': 'You have over receive.',
    'created_at': '2022-01-17T11:53:20.829592',
    'updated_at': '2022-01-17T11:53:20.829606',
    'title': 'Receive'
  },
  {
    'id': 212,
    'code': 'EF0016',
    'description': 'Listing variant with this slug already exists.',
    'created_at': '2021-11-17T07:53:00.509255',
    'updated_at': '2021-11-17T07:53:00.509267',
    'title': 'Listing variant with this slug already exists.'
  },
  {
    'id': 211,
    'code': 'EF0015',
    'description': 'Update is not allowed, you are not product owner.',
    'created_at': '2021-11-17T07:52:36.922103',
    'updated_at': '2021-11-17T07:52:36.922114',
    'title': 'Update is not allowed, you are not product owner.'
  },
  {
    'id': 210,
    'code': 'EF0014',
    'description': "You can't publish and reject listings as bundle is not in your listing.",
    'created_at': '2021-11-17T07:50:34.194122',
    'updated_at': '2021-11-17T07:50:34.194134',
    'title': "You can't publish and reject listings as bundle is not in your listing."
  },
  {
    'id': 209,
    'code': 'EF0013',
    'description': "You can't publish and reject listings as variant is not in your listing.",
    'created_at': '2021-11-17T07:50:09.053701',
    'updated_at': '2021-11-17T07:50:09.053712',
    'title': "You can't publish and reject listings as variant is not in your listing."
  },
  {
    'id': 208,
    'code': 'EF0010',
    'description': 'Brand is invalid',
    'created_at': '2021-11-17T07:49:32.715373',
    'updated_at': '2021-11-17T07:49:32.715385',
    'title': 'Brand is invalid'
  },
  {
    'id': 207,
    'code': 'EF0012',
    'description': 'Category is invalid.',
    'created_at': '2021-11-17T07:49:02.989141',
    'updated_at': '2021-11-17T07:49:02.989153',
    'title': 'Category is invalid.'
  },
  {
    'id': 206,
    'code': 'EF0011',
    'description': 'You have no contract to edit this listing.',
    'created_at': '2021-11-17T07:48:09.200245',
    'updated_at': '2021-11-17T07:48:09.200257',
    'title': 'You have no contract to edit this listing.'
  },
  {
    'id': 205,
    'code': 'EPA0018',
    'description': 'Packing job is started.',
    'created_at': '2021-11-03T12:36:42.094828',
    'updated_at': '2021-11-03T12:36:42.094840',
    'title': 'Invalid Action'
  },
  {
    'id': 204,
    'code': 'EWR0002',
    'description': 'Wrong UUID format.',
    'created_at': '2021-09-29T09:21:07.786518',
    'updated_at': '2021-09-29T09:21:07.786530',
    'title': 'Wrong UUID format.'
  },
  {
    'id': 203,
    'code': 'EPA0017',
    'description': 'Invalid Data. Packing job is COMPLETED.',
    'created_at': '2021-09-22T10:10:46.730557',
    'updated_at': '2021-09-22T10:10:46.730569',
    'title': 'Invalid Data. Packing job is COMPLETED.'
  },
  {
    'id': 202,
    'code': 'EPA0016',
    'description': 'Packing Location status is not ACTIVE.',
    'created_at': '2021-09-21T05:58:25.277525',
    'updated_at': '2021-09-21T05:58:25.277538',
    'title': 'Packing Location status is not ACTIVE.'
  },
  {
    'id': 201,
    'code': 'ERC002',
    'description': 'Can not be received, Purchase Order status should be "In progress".',
    'created_at': '2021-09-20T10:15:32.922940',
    'updated_at': '2021-11-03T12:40:32.259271',
    'title': 'Purchase order status should be IN PROGRESS.'
  },
  {
    'id': 168,
    'code': 'ESO0024',
    'description': 'Order cannot be updated while allocation status is PENDING.',
    'created_at': '2021-08-25T12:20:04.184549',
    'updated_at': '2021-08-25T12:20:04.184562',
    'title': 'Order cannot be updated while allocation status is PENDING.'
  },
  {
    'id': 167,
    'code': 'EWR0001',
    'description': 'Duplicate areas found in the list.',
    'created_at': '2021-08-20T13:17:38.878872',
    'updated_at': '2021-08-20T13:17:38.878884',
    'title': 'Duplicate areas found in the list.'
  },
  {
    'id': 166,
    'code': 'ESO0023',
    'description': 'Only Orders with status NEW can be split.',
    'created_at': '2021-08-19T12:24:48.806204',
    'updated_at': '2021-08-19T12:24:48.806216',
    'title': 'Only Orders with status NEW can be split.'
  },
  {
    'id': 165,
    'code': 'EMV0002',
    'description': 'IN_PROGRESS Movements cannot be deleted.',
    'created_at': '2021-08-16T12:52:54.422367',
    'updated_at': '2021-08-16T12:52:54.422378',
    'title': 'IN_PROGRESS Movements cannot be deleted.'
  },
  {
    'id': 164,
    'code': 'EG0033',
    'description': 'Some Movements not found. It is probably because there is a problem in server side.' +
      ' Contact with the admin at support@storfox.com',
    'created_at': '2021-08-16T12:50:56.591394',
    'updated_at': '2021-08-16T12:50:56.591411',
    'title': 'Some Movements not found. It is probably because there is a problem in server side.' +
      ' Contact with the admin at support@storfox.com'
  },
  {
    'id': 163,
    'code': 'EMV0001',
    'description': 'Duplicate Movements found in the list.',
    'created_at': '2021-08-16T12:19:39.251638',
    'updated_at': '2021-08-16T12:19:39.251650',
    'title': 'Duplicate Movements found in the list.'
  },
  {
    'id': 162,
    'code': 'EG0032',
    'description': 'Some Purchase Orders not found. It is probably because there is a problem in server side. Contact' +
      ' with the admin at support@storfox.com',
    'created_at': '2021-08-14T08:29:47.399988',
    'updated_at': '2021-08-14T08:29:47.400000',
    'title': 'Some Purchase Orders not found. It is probably because there is a problem in server side. Contact with' +
      ' the admin at support@storfox.com'
  },
  {
    'id': 161,
    'code': 'EPO0001',
    'description': 'Duplicate Purchase orders found in the list.',
    'created_at': '2021-08-14T08:13:48.678746',
    'updated_at': '2021-08-14T08:13:48.678758',
    'title': 'Duplicate Purchase orders found in the list.'
  },
  {
    'id': 160,
    'code': 'EST0002',
    'description': 'IN PROGRESS Stock Transfer cannot be deleted.',
    'created_at': '2021-08-12T13:02:08.656445',
    'updated_at': '2021-08-12T13:02:08.656457',
    'title': 'IN PROGRESS Stock Transfer cannot be deleted.'
  },
  {
    'id': 159,
    'code': 'EST0001',
    'description': 'Duplicate Stock Transfers found in the list.',
    'created_at': '2021-08-12T12:55:26.866206',
    'updated_at': '2021-08-12T12:55:26.866218',
    'title': 'Duplicate Stock Transfers found in the list.'
  },
  {
    'id': 158,
    'code': 'EAD0033',
    'description': 'Duplicate Stock Adjustments found in the list.',
    'created_at': '2021-08-12T07:17:34.909301',
    'updated_at': '2021-08-12T07:17:34.909313',
    'title': 'Duplicate Stock Adjustments found in the list.'
  },
  {
    'id': 157,
    'code': 'EG0030',
    'description': 'Some Stock Adjustments not found. It is probably because there is a problem in server side.' +
      ' Contact with the admin at support@storfox.com',
    'created_at': '2021-08-12T07:15:52.534961',
    'updated_at': '2021-08-12T07:15:52.534973',
    'title': 'Some Stock Adjustments not found. It is probably because there is a problem in server side. Contact' +
      ' with the admin at support@storfox.com'
  },
  {
    'id': 156,
    'code': 'ESO0022',
    'description': "Completed Order's payment type cannot be changed.",
    'created_at': '2021-08-11T06:55:11.842970',
    'updated_at': '2021-08-11T06:55:11.842982',
    'title': "Completed Order's payment type cannot be changed."
  },
  {
    'id': 155,
    'code': 'ESO0021',
    'description': 'Order does not have line items.',
    'created_at': '2021-08-11T06:54:17.723655',
    'updated_at': '2021-08-11T06:54:17.723667',
    'title': 'Order does not have line items.'
  },
  {
    'id': 154,
    'code': 'EAD0032',
    'description': 'Invalid unit number.',
    'created_at': '2021-08-02T11:17:27.565845',
    'updated_at': '2021-08-02T11:17:27.565865',
    'title': 'Invalid unit number.'
  },
  {
    'id': 153,
    'code': 'ESO0020',
    'description': 'Parent order not found. It seems there is a server side problem. Try contacting with the admin' +
      ' at support@storfox.com.',
    'created_at': '2021-07-27T10:59:12.137530',
    'updated_at': '2021-07-27T10:59:19.613018',
    'title': 'Parent order not found. It seems there is a server side problem. Try contacting with the admin at ' +
      'support@storfox.com.'
  },
  {
    'id': 152,
    'code': 'EF0009',
    'description': 'Only pending variants and bundles can be rejected.',
    'created_at': '2021-07-27T06:49:01.657104',
    'updated_at': '2021-07-27T06:49:01.657115',
    'title': 'Only pending variants and bundles can be rejected.'
  },
  {
    'id': 151,
    'code': 'EF0008',
    'description': 'Listing product not found.',
    'created_at': '2021-07-27T06:48:40.063198',
    'updated_at': '2021-11-17T07:47:38.082662',
    'title': 'Listing product not found.'
  },
  {
    'id': 150,
    'code': 'EF0007',
    'description': 'Only pending listings can be rejected.',
    'created_at': '2021-07-27T06:48:00.447629',
    'updated_at': '2021-11-17T07:46:10.930598',
    'title': 'Only pending listings can be rejected.'
  },
  {
    'id': 149,
    'code': 'EF0006',
    'description': 'Only published listings can be paused and unpublished.',
    'created_at': '2021-07-27T06:47:35.397807',
    'updated_at': '2021-11-17T07:45:41.846518',
    'title': 'Only published listings can be paused and unpublished.'
  },
  {
    'id': 148,
    'code': 'EF0005',
    'description': 'Only published variants and bundles can be paused.',
    'created_at': '2021-07-27T06:46:48.139585',
    'updated_at': '2021-07-27T06:46:48.139596',
    'title': 'Only published variants and bundles can be paused.'
  },
  {
    'id': 147,
    'code': 'EF0004',
    'description': 'Only pending, paused, unpublished listings can be published',
    'created_at': '2021-07-27T06:46:32.087334',
    'updated_at': '2021-11-17T07:45:14.967003',
    'title': 'Only pending, paused, unpublished listings can be published'
  },
  {
    'id': 146,
    'code': 'EF0003',
    'description': 'Not all bundles found.',
    'created_at': '2021-07-27T06:46:10.936310',
    'updated_at': '2021-07-27T06:46:10.936326',
    'title': 'Not all bundles found.'
  },
  {
    'id': 145,
    'code': 'EF0002',
    'description': 'Channel not found.',
    'created_at': '2021-07-27T06:45:27.680018',
    'updated_at': '2021-11-17T07:44:03.284503',
    'title': 'Channel not found.'
  },
  {
    'id': 144,
    'code': 'EF0001',
    'description': 'Listing variant not found.',
    'created_at': '2021-07-27T06:44:44.030316',
    'updated_at': '2021-11-17T07:43:25.657603',
    'title': 'Listing variant not found.'
  },
  {
    'id': 143,
    'code': 'EG0029',
    'description': 'Payment type not found. It is probably because there is a problem in server side. Contact with' +
      ' the admin at support@storfox.com',
    'created_at': '2021-07-14T11:59:35.343821',
    'updated_at': '2021-07-14T11:59:35.343842',
    'title': 'Payment type not found. It is probably because there is a problem in server side' +
      '. Contact with the admin at support@storfox.com'
  },
  {
    'id': 142,
    'code': 'EG0028',
    'description': 'Customer not found. It is probably because there is a problem in server side. Contact with the' +
      ' admin at support@storfox.com',
    'created_at': '2021-07-14T11:59:15.619495',
    'updated_at': '2021-07-14T11:59:15.619516',
    'title': 'Customer not found. It is probably because there is a problem in server side. Contact with the admin' +
      ' at support@storfox.com'
  },
  {
    'id': 141,
    'code': 'EG0027',
    'description': 'Delivery address not found. It is probably because there is a problem in server side.' +
      ' Contact with the admin at support@storfox.com',
    'created_at': '2021-07-14T11:59:02.383171',
    'updated_at': '2021-07-14T11:59:02.383187',
    'title': 'Delivery address not found. It is probably because there is a problem in server side.' +
      ' Contact with the admin at support@storfox.com'
  },
  {
    'id': 140,
    'code': 'EG0026',
    'description': 'Billing address not found. It is probably because there is a problem in server side.' +
      ' Contact with the admin at support@storfox.com',
    'created_at': '2021-07-14T11:58:50.403809',
    'updated_at': '2021-07-14T11:58:50.403838',
    'title': 'Billing address not found. It is probably because there is a problem in server side.' +
      ' Contact with the admin at support@storfox.com'
  },
  {
    'id': 139,
    'code': 'ECS0003',
    'description': 'Customer with this phone number has already been registered.',
    'created_at': '2021-07-14T11:57:40.687826',
    'updated_at': '2021-07-14T11:57:40.687838',
    'title': 'Customer with this phone number has already been registered.'
  },
  {
    'id': 138,
    'code': 'ECS0002',
    'description': 'Customer with this email has already been registered.',
    'created_at': '2021-07-14T11:56:57.674222',
    'updated_at': '2021-07-14T11:56:57.674233',
    'title': 'Customer with this email has already been registered.'
  },
  {
    'id': 137,
    'code': 'ECS0001',
    'description': 'Invalid data. There should be only one primary billing address.',
    'created_at': '2021-07-14T11:56:41.891039',
    'updated_at': '2021-07-14T11:56:41.891059',
    'title': 'Invalid data. There should be only one primary billing address.'
  },
  {
    'id': 136,
    'code': 'EPA0012',
    'description': 'There is an error in unit. Unit does not belong to the packing.',
    'created_at': '2021-07-13T14:29:52.046445',
    'updated_at': '2021-07-13T14:29:52.046462',
    'title': 'There is an error in unit. Unit does not belong to the packing.'
  },
  {
    'id': 135,
    'code': 'EPA0011',
    'description': 'Duplicate units found.',
    'created_at': '2021-07-13T14:29:38.625949',
    'updated_at': '2021-07-13T14:29:38.625963',
    'title': 'Duplicate units found.'
  },
  {
    'id': 134,
    'code': 'EPA0010',
    'description': 'Invalid action. Packing job status should be either PENDING or IN_PROGRESS.',
    'created_at': '2021-07-13T14:29:09.888851',
    'updated_at': '2021-07-13T14:29:09.888870',
    'title': 'Invalid action. Packing job status should be either PENDING or IN_PROGRESS.'
  },
  {
    'id': 133,
    'code': 'EPA0009',
    'description': 'Packer not found. It is probably because there is a problem in server side.' +
      ' Contact with the admin at support@storfox.com',
    'created_at': '2021-07-13T14:28:53.477014',
    'updated_at': '2021-07-13T14:28:53.477026',
    'title': 'Packer not found. It is probably because there is a problem in server side.' +
      ' Contact with the admin at support@storfox.com'
  },
  {
    'id': 132,
    'code': 'EPA0008',
    'description': 'Invalid unit.  There is a unit whose volume is less than or equal to 0.',
    'created_at': '2021-07-13T14:28:40.386323',
    'updated_at': '2021-07-13T14:28:40.386335',
    'title': 'Invalid unit.  There is a unit whose volume is less than or equal to 0.'
  },
  {
    'id': 131,
    'code': 'EPA0007',
    'description': "Invalid units. Units' total volume cannot be less than or equal to 0.",
    'created_at': '2021-07-13T14:28:28.711931',
    'updated_at': '2021-07-13T14:28:28.711943',
    'title': "Invalid units. Units' total volume cannot be less than or equal to 0."
  },
  {
    'id': 130,
    'code': 'EPA0006',
    'description': "Invalid units. Units' total weight cannot be less than or equal to 0.",
    'created_at': '2021-07-13T14:28:15.583992',
    'updated_at': '2021-07-13T14:28:15.584005',
    'title': "Invalid units. Units' total weight cannot be less than or equal to 0."
  },
  {
    'id': 129,
    'code': 'EPA0005',
    'description': 'No valid container type available for packing job. This might be due to the smallest container' +
      " type's volume is more than 1.1 times of total volume of items. You have to do it manually.",
    'created_at': '2021-07-13T14:27:58.787140',
    'updated_at': '2021-07-13T14:27:58.787152',
    'title': 'No valid container type available for packing job. This might be due to the smallest container' +
      " type's volume is more than 1.1 times of total volume of items. You have to do it manually."
  },
  {
    'id': 128,
    'code': 'EPA0004',
    'description': 'Invalid action. Packing job should have a packer assigned to it before processing.',
    'created_at': '2021-07-13T14:27:43.986883',
    'updated_at': '2021-07-13T14:27:43.986895',
    'title': 'Invalid action. Packing job should have a packer assigned to it before processing.'
  },
  {
    'id': 127,
    'code': 'EPA0003',
    'description': 'Invalid action. Packing job status should be either NEW or PENDING to process.',
    'created_at': '2021-07-13T14:27:13.422028',
    'updated_at': '2021-07-13T14:27:13.422052',
    'title': 'Invalid action. Packing job status should be either NEW or PENDING to process.'
  },
  {
    'id': 126,
    'code': 'EPA0002',
    'description': 'Invalid action. Packing job status should be IN_PROGRESS to complete.',
    'created_at': '2021-07-13T14:27:00.305951',
    'updated_at': '2021-07-13T14:27:00.305969',
    'title': 'Invalid action. Packing job status should be IN_PROGRESS to complete.'
  },
  {
    'id': 125,
    'code': 'EPA0001',
    'description': 'Packing job cannot be completed. There are items not packed yet.',
    'created_at': '2021-07-13T14:26:47.398510',
    'updated_at': '2021-07-13T14:26:47.398526',
    'title': 'Packing job cannot be completed. There are items not packed yet.'
  },
  {
    'id': 124,
    'code': 'EG0025',
    'description': 'Packing job not found. It is probably because there is a problem in server side. ' +
      'Contact with the admin at support@storfox.com',
    'created_at': '2021-07-13T14:26:27.561023',
    'updated_at': '2021-07-13T14:26:27.561118',
    'title': 'Packing job not found. It is probably because there is a problem in server side.' +
      ' Contact with the admin at support@storfox.com'
  },
  {
    'id': 123,
    'code': 'ESH0003',
    'description': 'Shipment cannot be updated. Shipment status should be either UNSHIPPED or PRE_TRANSIT.',
    'created_at': '2021-07-13T10:57:06.450645',
    'updated_at': '2021-07-13T10:57:06.450659',
    'title': 'Shipment cannot be updated. Shipment status should be either UNSHIPPED or PRE_TRANSIT.'
  },
  {
    'id': 122,
    'code': 'ESH0002',
    'description': 'Invalid action. Shipment tracking number has to be set before processing.',
    'created_at': '2021-07-13T10:56:54.226431',
    'updated_at': '2021-07-13T10:56:54.226450',
    'title': 'Invalid action. Shipment tracking number has to be set before processing.'
  },
  {
    'id': 121,
    'code': 'ESH0001',
    'description': 'Delivery method code has already been registered. Please choose another.',
    'created_at': '2021-07-13T10:56:42.700641',
    'updated_at': '2021-07-13T10:56:42.700658',
    'title': 'Delivery method code has already been registered. Please choose another.'
  },
  {
    'id': 120,
    'code': 'EG0024',
    'description': 'Shipping method not found. It is probably because there is a problem in server side.' +
      ' Contact with the admin at support@storfox.com',
    'created_at': '2021-07-13T10:56:23.091382',
    'updated_at': '2021-07-13T10:56:23.091404',
    'title': 'Shipping method not found. It is probably because there is a problem in server side.' +
      ' Contact with the admin at support@storfox.com'
  },
  {
    'id': 119,
    'code': 'EG0023',
    'description': 'Delivery method not found. It is probably because there is a problem in server side.' +
      ' Contact with the admin at support@storfox.com',
    'created_at': '2021-07-13T10:56:13.564409',
    'updated_at': '2021-07-13T10:56:13.564423',
    'title': 'Delivery method not found. It is probably because there is a problem in server side.' +
      ' Contact with the admin at support@storfox.com'
  },
  {
    'id': 118,
    'code': 'EG0022',
    'description': 'Shipment not found. It is probably because there is a problem in server side.' +
      ' Contact with the admin at support@storfox.com',
    'created_at': '2021-07-13T10:56:03.669691',
    'updated_at': '2021-07-13T10:56:03.669706',
    'title': 'Shipment not found. It is probably because there is a problem in server side.' +
      ' Contact with the admin at support@storfox.com'
  },
  {
    'id': 117,
    'code': 'EG0021',
    'description': 'Some delivery methods not found. It is probably because there is a problem in server side.' +
      ' Contact with the admin at support@storfox.com.',
    'created_at': '2021-07-13T10:55:51.410395',
    'updated_at': '2021-07-13T10:55:51.410407',
    'title': 'Some delivery methods not found. It is probably because there is a problem in server side.' +
      ' Contact with the admin at support@storfox.com.'
  },
  {
    'id': 116,
    'code': 'EG0020',
    'description': 'Container not found. It is probably because there is a problem in server side.' +
      ' Contact with the admin at support@storfox.com.',
    'created_at': '2021-07-10T08:04:24.713322',
    'updated_at': '2021-07-10T08:04:24.713335',
    'title': 'Container not found. It is probably because there is a problem in server side.' +
      ' Contact with the admin at support@storfox.com.'
  },
  {
    'id': 115,
    'code': 'EG0019',
    'description': 'Location not found. It is probably because there is a problem in server side. ' +
      'Contact with the admin at support@storfox.com.',
    'created_at': '2021-07-10T08:04:09.941743',
    'updated_at': '2021-07-10T08:04:09.941755',
    'title': 'Location not found. It is probably because there is a problem in server side.' +
      ' Contact with the admin at support@storfox.com.'
  },
  {
    'id': 114,
    'code': 'EG0018',
    'description': 'Unit not found. It is probably because there is a problem in server side.' +
      ' Contact with the admin at support@storfox.com.',
    'created_at': '2021-07-10T08:03:54.881479',
    'updated_at': '2021-07-10T08:03:54.881491',
    'title': 'Unit not found. It is probably because there is a problem in server side.' +
      ' Contact with the admin at support@storfox.com.'
  },
  {
    'id': 113,
    'code': 'EG0017',
    'description': 'Stock Adjustment not found. It is probably because there is a problem in server side.' +
      ' Contact with the admin at support@storfox.com.',
    'created_at': '2021-07-10T08:03:39.217287',
    'updated_at': '2021-07-10T08:03:39.217301',
    'title': 'Stock Adjustment not found. It is probably because there is a problem in server side.' +
      ' Contact with the admin at support@storfox.com.'
  },
  {
    'id': 112,
    'code': 'EAD0031',
    'description': 'Some Stock Adjustment Reasons not found. It is probably' +
      ' because there is a problem in the server side. Contact with the admin at support@storfox.com.',
    'created_at': '2021-07-10T08:02:44.743651',
    'updated_at': '2021-07-10T08:02:44.743674',
    'title': 'Some Stock Adjustment Reasons not found. It is probably' +
      ' because there is a problem in the server side. Contact with the admin at support@storfox.com.'
  },
  {
    'id': 111,
    'code': 'EAD0030',
    'description': 'There is an error in a line item. Reduntant unit serial number.',
    'created_at': '2021-07-10T08:02:31.088445',
    'updated_at': '2021-07-10T08:02:31.088457',
    'title': 'There is an error in a line item. Reduntant unit serial number.'
  },
  {
    'id': 110,
    'code': 'EAD0029',
    'description': "There is an error in a line item. Unit's quantity cannot be greater than 1.",
    'created_at': '2021-07-10T08:02:16.512293',
    'updated_at': '2021-07-10T08:02:16.512309',
    'title': "There is an error in a line item. Unit's quantity cannot be greater than 1."
  },
  {
    'id': 109,
    'code': 'EAD0028',
    'description': 'There is an error in a line item. Duplicate variants found to the same location.',
    'created_at': '2021-07-10T08:02:02.556069',
    'updated_at': '2021-07-10T08:02:02.556088',
    'title': 'There is an error in a line item. Duplicate variants found to the same location.'
  },
  {
    'id': 108,
    'code': 'EAD0027',
    'description': 'There is an error in a line item. When Track Serial' +
      ' Numbers is set, you should specify serial number of the unit.',
    'created_at': '2021-07-10T08:01:50.776196',
    'updated_at': '2021-07-10T08:01:50.776208',
    'title': 'There is an error in a line item. When Track Serial' +
      ' Numbers is set, you should specify serial number of the unit.'
  },
  {
    'id': 107,
    'code': 'EAD0026',
    'description': 'There is an error in a line item. When expiration date of a' +
      ' line item is set, you should enable Track Expiry Date too.',
    'created_at': '2021-07-10T08:01:31.977286',
    'updated_at': '2021-07-10T08:01:31.977298',
    'title': 'There is an error in a line item. When expiration date of a' +
      ' line item is set, you should enable Track Expiry Date too.'
  },
  {
    'id': 106,
    'code': 'EAD0025',
    'description': 'There is an error in a line item. When Track Expiry' +
      ' Data is set, you should specify expiration date of the line item.',
    'created_at': '2021-07-10T08:01:17.765829',
    'updated_at': '2021-07-10T08:01:17.765843',
    'title': 'There is an error in a line item. When Track Expiry Data' +
      ' is set, you should specify expiration date of the line item.'
  },
  {
    'id': 105,
    'code': 'EAD0024',
    'description': 'There is an error in a line item. When Track Expiry Date is set, you should specify a container.',
    'created_at': '2021-07-10T08:01:04.766792',
    'updated_at': '2021-07-10T08:01:04.766804',
    'title': 'There is an error in a line item. When Track Expiry Date is set, you should specify a container.'
  },
  {
    'id': 104,
    'code': 'EAD0023',
    'description': 'There is an error in a line item. Container does not exist in that warehouse.',
    'created_at': '2021-07-10T08:00:49.454828',
    'updated_at': '2021-07-10T08:00:49.454839',
    'title': 'There is an error in a line item. Container does not exist in that warehouse.'
  },
  {
    'id': 103,
    'code': 'EAD0022',
    'description': "There is an error in a line item. Stock Adjustment's" +
      " warehouse and a line item's warehouse do not match.",
    'created_at': '2021-07-10T08:00:36.123597',
    'updated_at': '2021-07-10T08:00:36.123609',
    'title': "There is an error in a line item. Stock Adjustment's warehouse and a line item's warehouse do not match."
  },
  {
    'id': 102,
    'code': 'EAD0021',
    'description': 'Stock Adjustment Reason not found. It is probably because' +
      ' there is a problem in the server side. Contact with the admin at support@storfox.com.',
    'created_at': '2021-07-10T08:00:20.853183',
    'updated_at': '2021-07-10T08:00:20.853196',
    'title': 'Stock Adjustment Reason not found. It is probably because' +
      ' there is a problem in the server side. Contact with the admin at support@storfox.com.'
  },
  {
    'id': 101,
    'code': 'EAD0020',
    'description': "Invalid unit number. Variant's barcode is not the same with unit number.",
    'created_at': '2021-07-10T08:00:07.552445',
    'updated_at': '2021-07-10T08:00:07.552461',
    'title': "Invalid unit number. Variant's barcode is not the same with unit number."
  },
  {
    'id': 100,
    'code': 'EAD0019',
    'description': 'Invalid unit number. A Unit already exists with that unit number.',
    'created_at': '2021-07-10T07:59:52.192465',
    'updated_at': '2021-07-10T07:59:52.192478',
    'title': 'Invalid unit number. A Unit already exists with that unit number.'
  },
  {
    'id': 99,
    'code': 'EAD0018',
    'description': 'There is an error in a line item. Stock adjustment does not have this line item.',
    'created_at': '2021-07-10T07:59:36.310898',
    'updated_at': '2021-07-10T07:59:36.310915',
    'title': 'There is an error in a line item. Stock adjustment does not have this line item.'
  },
  {
    'id': 98,
    'code': 'EAD0017',
    'description': 'There is an error in a line item. A unit has already been assigned' +
      ' to an order and not available to remove.',
    'created_at': '2021-07-10T07:59:18.193338',
    'updated_at': '2021-07-10T07:59:18.193357',
    'title': 'There is an error in a line item. A unit has already been assigned' +
      ' to an order and not available to remove.'
  },
  {
    'id': 97,
    'code': 'EAD0016',
    'description': 'There is an error in a line item. Duplicate units found.',
    'created_at': '2021-07-10T07:59:03.172516',
    'updated_at': '2021-07-10T07:59:03.172537',
    'title': 'There is an error in a line item. Duplicate units found.'
  },
  {
    'id': 96,
    'code': 'EAD0015',
    'description': 'There is an error in a line item. A unit not found by its unit number.',
    'created_at': '2021-07-10T07:58:44.905425',
    'updated_at': '2021-07-10T07:58:44.905440',
    'title': 'There is an error in a line item. A unit not found by its unit number.'
  },
  {
    'id': 95,
    'code': 'EAD0014',
    'description': "There is an error in a line item. Unit's variant is not the" +
      " same as corresponding line item's variant.",
    'created_at': '2021-07-10T07:58:29.163339',
    'updated_at': '2021-07-10T07:58:29.163358',
    'title': "There is an error in a line item. Unit's variant is not the" +
      " same as corresponding line item's variant."
  },
  {
    'id': 94,
    'code': 'EAD0013',
    'description': 'Serial number of a unit is already in use.',
    'created_at': '2021-07-10T07:58:14.804061',
    'updated_at': '2021-07-10T07:58:14.804074',
    'title': 'Serial number of a unit is already in use.'
  },
  {
    'id': 93,
    'code': 'EAD0012',
    'description': 'Owner not found. It is probably because there is a problem ' +
      'in server side. Contact with the admin at support@storfox.com',
    'created_at': '2021-07-10T07:57:59.817933',
    'updated_at': '2021-07-10T07:57:59.817945',
    'title': 'Owner not found. It is probably because there is a problem' +
      ' in server side. Contact with the admin at support@storfox.com'
  },
  {
    'id': 92,
    'code': 'EAD0011',
    'description': "Stock Adjustment's warehouse cannot be changed.",
    'created_at': '2021-07-10T07:57:42.264945',
    'updated_at': '2021-07-10T07:57:42.264957',
    'title': "Stock Adjustment's warehouse cannot be changed."
  },
  {
    'id': 91,
    'code': 'EAD0010',
    'description': 'There is an error in a line item. Unit does not exist in that warehouse.',
    'created_at': '2021-07-10T07:57:18.420503',
    'updated_at': '2021-07-10T07:57:18.420520',
    'title': 'There is an error in a line item. Unit does not exist in that warehouse.'
  },
  {
    'id': 90,
    'code': 'EAD0009',
    'description': "There is an error in a line item. Unit's total quantity is less than the quantity to be removed.",
    'created_at': '2021-07-10T07:57:03.240728',
    'updated_at': '2021-07-10T07:57:03.240740',
    'title': "There is an error in a line item. Unit's total quantity is less than the quantity to be removed."
  },
  {
    'id': 89,
    'code': 'EAD0008',
    'description': 'There is an error in a line item. Unit  to be removed does not exist.',
    'created_at': '2021-07-10T07:56:40.555802',
    'updated_at': '2021-07-10T07:56:40.555820',
    'title': 'There is an error in a line item. Unit  to be removed does not exist.'
  },
  {
    'id': 88,
    'code': 'EAD0007',
    'description': 'Container of a line item is not assigned to selected location.',
    'created_at': '2021-07-10T07:56:20.822296',
    'updated_at': '2021-07-10T07:56:20.822314',
    'title': 'Container of a line item is not assigned to selected location.'
  },
  {
    'id': 87,
    'code': 'EAD0006',
    'description': 'Invalid container for units found. Container has already units inside.',
    'created_at': '2021-07-10T07:56:01.175987',
    'updated_at': '2021-07-10T07:56:01.175999',
    'title': 'Invalid container for units found. Container has already units inside.'
  },
  {
    'id': 86,
    'code': 'EAD0005',
    'description': 'Serial number of a line item is invalid. Unit already exists with that serial number.',
    'created_at': '2021-07-10T07:55:43.066239',
    'updated_at': '2021-07-10T07:55:43.066257',
    'title': 'Serial number of a line item is invalid. Unit already exists with that serial number.'
  },
  {
    'id': 85,
    'code': 'EAD0004',
    'description': 'There is a line item whose barcoding strategy is not SET.',
    'created_at': '2021-07-10T07:51:51.267855',
    'updated_at': '2021-07-10T07:51:51.267868',
    'title': 'There is a line item whose barcoding strategy is not SET.'
  },
  {
    'id': 84,
    'code': 'EAD0003',
    'description': 'Some line items not found.',
    'created_at': '2021-07-10T07:51:29.128566',
    'updated_at': '2021-07-10T07:51:29.128582',
    'title': 'Some line items not found.'
  },
  {
    'id': 83,
    'code': 'EAD0002',
    'description': "Stock Adjustment's reason cannot be changed when completing.",
    'created_at': '2021-07-10T07:51:12.417895',
    'updated_at': '2021-07-10T07:51:12.417912',
    'title': "Stock Adjustment's reason cannot be changed when completing."
  },
  {
    'id': 82,
    'code': 'EAD0001',
    'description': 'Stock Adjustment status should be DRAFT.',
    'created_at': '2021-07-10T07:50:52.441730',
    'updated_at': '2021-07-10T07:50:52.441742',
    'title': 'Stock Adjustment status should be DRAFT.'
  },
  {
    'id': 81,
    'code': 'ECT0012',
    'description': 'Duplicated product image.',
    'created_at': '2021-07-07T07:32:52.621308',
    'updated_at': '2021-07-07T07:32:52.621321',
    'title': 'Duplicated product image.'
  },
  {
    'id': 80,
    'code': 'ECT0011',
    'description': 'Some stocks are not empty.',
    'created_at': '2021-07-07T07:32:39.281946',
    'updated_at': '2021-07-07T07:32:39.281963',
    'title': 'Some stocks are not empty.'
  },
  {
    'id': 79,
    'code': 'ECT0010',
    'description': 'You cannot delete this product since stock is not empty yet.',
    'created_at': '2021-07-07T07:32:26.679398',
    'updated_at': '2021-07-07T07:32:26.679411',
    'title': 'You cannot delete this product since stock is not empty yet.'
  },
  {
    'id': 78,
    'code': 'ECT0009',
    'description': "Image not found. Probably server's error.",
    'created_at': '2021-07-07T07:32:11.662393',
    'updated_at': '2021-07-07T07:32:11.662406',
    'title': "Image not found. Probably server's error."
  },
  {
    'id': 77,
    'code': 'ECT0008',
    'description': 'Invalid Option.',
    'created_at': '2021-07-07T07:31:59.461131',
    'updated_at': '2021-07-07T07:31:59.461142',
    'title': 'Invalid Option.'
  },
  {
    'id': 76,
    'code': 'ECT0007',
    'description': 'Option with that name does not exist',
    'created_at': '2021-07-07T07:31:28.930821',
    'updated_at': '2021-07-07T07:31:28.930832',
    'title': 'Option with that name does not exist'
  },
  {
    'id': 75,
    'code': 'ECT0006',
    'description': 'There is an another variant with the same barcode. Please provide another.',
    'created_at': '2021-07-07T07:31:16.613343',
    'updated_at': '2021-07-07T07:31:16.613356',
    'title': 'There is an another variant with the same barcode. Please provide another.'
  },
  {
    'id': 74,
    'code': 'ECT0005',
    'description': 'There is an another variant with the same sku. Please provide another.',
    'created_at': '2021-07-07T07:31:02.986757',
    'updated_at': '2021-07-07T07:31:02.986774',
    'title': 'There is an another variant with the same sku. Please provide another.'
  },
  {
    'id': 73,
    'code': 'ECT0004',
    'description': 'When creating a product in FEFO(First Expired First Out)' +
      ' strategy, you should enable Track expiry dates option.',
    'created_at': '2021-07-07T07:30:19.308591',
    'updated_at': '2021-07-07T07:30:19.308603',
    'title': 'When creating a product in FEFO(First Expired First Out)' +
      ' strategy, you should enable Track expiry dates option.'
  },
  {
    'id': 72,
    'code': 'ECT0003',
    'description': 'Duplicate image paths found. Check product images one more time. ',
    'created_at': '2021-07-07T07:30:05.133201',
    'updated_at': '2021-07-07T07:30:05.133214',
    'title': 'Duplicate image paths found. Check product images one more time. '
  },
  {
    'id': 71,
    'code': 'ECT0002',
    'description': 'Duplicate default images found. Please specify only one image as default.',
    'created_at': '2021-07-07T07:29:54.690959',
    'updated_at': '2021-07-07T07:29:54.690971',
    'title': 'Duplicate default images found. Please specify only one image as default.'
  },
  {
    'id': 70,
    'code': 'ECT0001',
    'description': 'Sku is not valid. Please provide another.',
    'created_at': '2021-07-07T07:29:44.446250',
    'updated_at': '2021-07-07T07:29:44.446262',
    'title': 'Sku is not valid. Please provide another.'
  },
  {
    'id': 69,
    'code': 'EG0016',
    'description': 'Product not found. It is probably because there is a problem' +
      ' in server side. Contact with the admin at support@storfox.com',
    'created_at': '2021-07-07T07:17:44.360973',
    'updated_at': '2021-07-07T07:17:44.360984',
    'title': 'Product not found. It is probably because there is a problem' +
      ' in server side. Contact with the admin at support@storfox.com'
  },
  {
    'id': 68,
    'code': 'EG0015',
    'description': 'Brand not found. It is probably because there is a problem' +
      ' in server side. Contact with the admin at support@storfox.com',
    'created_at': '2021-07-07T07:17:16.698333',
    'updated_at': '2021-07-07T07:17:16.698351',
    'title': 'Brand not found. It is probably because there is a problem' +
      ' in server side. Contact with the admin at support@storfox.com'
  },
  {
    'id': 67,
    'code': 'EG0014',
    'description': 'Category not found. It is probably because there is a problem' +
      ' in server side. Contact with the admin at support@storfox.com',
    'created_at': '2021-07-07T07:17:02.561938',
    'updated_at': '2021-07-07T07:17:02.561954',
    'title': 'Category not found. It is probably because there is a problem ' +
      'in server side. Contact with the admin at support@storfox.com'
  },
  {
    'id': 66,
    'code': 'ESO0019',
    'description': 'You should fill all Customer details before resolving order.',
    'created_at': '2021-07-05T07:02:59.084510',
    'updated_at': '2021-07-05T07:02:59.084523',
    'title': 'You should fill all Customer details before resolving order.'
  },
  {
    'id': 65,
    'code': 'ESO0018',
    'description': 'Order should be either UNALLOCATED or PARTIALLY ALLOCATED.',
    'created_at': '2021-07-02T11:20:34.877954',
    'updated_at': '2021-07-02T11:20:34.877965',
    'title': 'Order should be either UNALLOCATED or PARTIALLY ALLOCATED.'
  },
  {
    'id': 64,
    'code': 'ESO0017',
    'description': 'Duplicate variants with same condition found.',
    'created_at': '2021-07-02T11:20:20.365717',
    'updated_at': '2021-07-02T11:20:20.365734',
    'title': 'Duplicate variants with same condition found.'
  },
  {
    'id': 63,
    'code': 'ESO0016',
    'description': 'Condition Price of a line item not found. It is probably because there' +
      ' is a problem in server. Contact with the admin at support@storfox.com.',
    'created_at': '2021-07-02T11:20:07.698746',
    'updated_at': '2021-07-02T11:20:07.698763',
    'title': 'Condition Price of a line item not found. It is probably because there' +
      ' is a problem in server. Contact with the admin at support@storfox.com.'
  },
  {
    'id': 62,
    'code': 'ESO0015',
    'description': 'Order status should be ON_HOLD.',
    'created_at': '2021-07-02T11:19:36.685644',
    'updated_at': '2021-07-02T11:19:36.685658',
    'title': 'Order status should be ON_HOLD.'
  },
  {
    'id': 61,
    'code': 'ESO0014',
    'description': 'Invalid action. Order status is ON_HOLD.',
    'created_at': '2021-07-02T11:19:26.013380',
    'updated_at': '2021-07-02T11:19:26.013391',
    'title': 'Invalid action. Order status is ON_HOLD.'
  },
  {
    'id': 60,
    'code': 'ESO0013',
    'description': "Order Line Item's variant cannot be changed in PROCESSING order.",
    'created_at': '2021-07-02T11:19:14.226281',
    'updated_at': '2021-07-02T11:19:14.226299',
    'title': "Order Line Item's variant cannot be changed in PROCESSING order."
  },
  {
    'id': 59,
    'code': 'ESO0012',
    'description': 'Paid orders cannot be cancelled.',
    'created_at': '2021-07-02T11:19:00.204044',
    'updated_at': '2021-07-02T11:19:00.204059',
    'title': 'Paid orders cannot be cancelled.'
  },
  {
    'id': 58,
    'code': 'ESO0011',
    'description': 'Order status should be either DRAFT or NEW to do this action.',
    'created_at': '2021-07-02T11:18:48.486163',
    'updated_at': '2021-07-02T11:18:48.486174',
    'title': 'Order status should be either DRAFT or NEW to do this action.'
  },
  {
    'id': 57,
    'code': 'ESO0010',
    'description': 'Delivery method not found. It is probably because there is a problem' +
      ' in server. Contact with the admin at support@storfox.com.',
    'created_at': '2021-07-02T11:18:36.975351',
    'updated_at': '2021-07-02T11:20:46.905462',
    'title': 'Delivery method not found. It is probably because there is a problem' +
      ' in server. Contact with the admin at support@storfox.com.'
  },
  {
    'id': 56,
    'code': 'ESO0009',
    'description': 'Invalid action. Order is already allocated.',
    'created_at': '2021-07-02T11:18:16.374794',
    'updated_at': '2021-07-02T11:18:16.374805',
    'title': 'Invalid action. Order is already allocated.'
  },
  {
    'id': 55,
    'code': 'ESO0008',
    'description': 'Duplicate Orders found.',
    'created_at': '2021-07-02T11:18:01.159194',
    'updated_at': '2021-07-02T11:18:01.159206',
    'title': 'Duplicate Orders found.'
  },
  {
    'id': 54,
    'code': 'ESO0007',
    'description': 'Order should be ALLOCATED to do this action.',
    'created_at': '2021-07-02T11:17:39.106210',
    'updated_at': '2021-07-02T11:17:39.106231',
    'title': 'Order should be ALLOCATED to do this action.'
  },
  {
    'id': 53,
    'code': 'ESO0006',
    'description': 'Order status should be either NEW or PROCESSING to do this action.',
    'created_at': '2021-07-02T11:17:24.735194',
    'updated_at': '2021-07-02T11:20:54.075700',
    'title': 'Order status should be either NEW or PROCESSING to do this action.'
  },
  {
    'id': 52,
    'code': 'ESO0005',
    'description': 'Order not found. It is probably because there is a problem in server.' +
      ' Contact with the admin at support@storfox.com',
    'created_at': '2021-07-02T11:17:11.521611',
    'updated_at': '2021-07-02T11:17:11.521624',
    'title': 'Order not found. It is probably because there is a problem in server.' +
      ' Contact with the admin at support@storfox.com'
  },
  {
    'id': 51,
    'code': 'ESO0004',
    'description': "Line Item's condition cannot be changed in PROCESSING order.",
    'created_at': '2021-07-02T11:16:50.218988',
    'updated_at': '2021-07-02T11:16:50.219003',
    'title': "Line Item's condition cannot be changed in PROCESSING order."
  },
  {
    'id': 50,
    'code': 'ESO0003',
    'description': "Line Item's quantity cannot be changed in PROCESSING order.",
    'created_at': '2021-07-02T11:16:37.046948',
    'updated_at': '2021-07-02T11:16:37.046960',
    'title': "Line Item's quantity cannot be changed in PROCESSING order."
  },
  {
    'id': 49,
    'code': 'ESO0002',
    'description': 'Order Line Item not found. It is probably because there is a problem' +
      ' in server. Contact with the admin at support@storfox.com',
    'created_at': '2021-07-02T11:16:18.669396',
    'updated_at': '2021-07-02T11:16:18.669408',
    'title': 'Order Line Item not found. It is probably because there is a problem' +
      ' in server. Contact with the admin at support@storfox.com'
  },
  {
    'id': 48,
    'code': 'ESO0001',
    'description': 'Customer not found. It is probably because there is a problem in server.' +
      ' Contact with the admin at support@storfox.com',
    'created_at': '2021-07-02T11:15:53.115881',
    'updated_at': '2021-07-02T11:15:53.115903',
    'title': 'Customer not found. It is probably because there is a problem in server.' +
      ' Contact with the admin at support@storfox.com'
  },
  {
    'id': 47,
    'code': 'EC0039',
    'description': 'Some users not found. It is probably because there is a problem in server.' +
      ' Contact with the admin at support@storfox.com',
    'created_at': '2021-07-02T11:03:05.588970',
    'updated_at': '2021-07-02T11:03:05.588982',
    'title': 'Some users not found. It is probably because there is a problem in server.' +
      ' Contact with the admin at support@storfox.com'
  },
  {
    'id': 46,
    'code': 'EC0038',
    'description': 'User not found. Contact with the admin at support@storfox.com',
    'created_at': '2021-07-02T11:02:47.355081',
    'updated_at': '2021-07-02T11:02:47.355093',
    'title': 'User not found. Contact with the admin at support@storfox.com'
  },
  {
    'id': 45,
    'code': 'EC0037',
    'description': 'Some roles not found. It is probably because there is a problem in server.' +
      ' Contact with the admin at support@storfox.com',
    'created_at': '2021-07-02T11:02:26.178591',
    'updated_at': '2021-07-02T11:02:26.178606',
    'title': 'Some roles not found. It is probably because there is a problem in server.' +
      ' Contact with the admin at support@storfox.com'
  },
  {
    'id': 44,
    'code': 'EC0036',
    'description': 'Default tax can not be deleted. To delete it make another tax default first.',
    'created_at': '2021-07-02T11:01:51.639989',
    'updated_at': '2021-07-02T11:01:51.640000',
    'title': 'Default tax can not be deleted. To delete it make another tax default first.'
  },
  {
    'id': 43,
    'code': 'EC0035',
    'description': 'Some taxes not found. It is probably because there is a problem in server.' +
      ' Contact with the admin at support@storfox.com',
    'created_at': '2021-07-02T11:01:36.398960',
    'updated_at': '2021-07-02T11:01:36.398971',
    'title': 'Some taxes not found. It is probably because there is a problem in server.' +
      ' Contact with the admin at support@storfox.com'
  },
  {
    'id': 42,
    'code': 'EC0034',
    'description': 'Tax with this name already exists. Try another.',
    'created_at': '2021-07-02T11:01:04.759603',
    'updated_at': '2021-07-02T11:01:04.759614',
    'title': 'Tax with this name already exists. Try another.'
  },
  {
    'id': 41,
    'code': 'EC0032',
    'description': 'Some errors not found.',
    'created_at': '2021-07-02T11:00:54.402630',
    'updated_at': '2021-07-02T11:00:54.402641',
    'title': 'Some errors not found.'
  },
  {
    'id': 40,
    'code': 'EC0031',
    'description': 'Bad request. Select at least an error to delete.',
    'created_at': '2021-07-02T11:00:43.521802',
    'updated_at': '2021-07-02T11:00:43.521813',
    'title': 'Bad request. Select at least an error to delete.'
  },
  {
    'id': 39,
    'code': 'EC0030',
    'description': 'There is an existing error with the same code. Try another.',
    'created_at': '2021-07-02T11:00:31.376428',
    'updated_at': '2021-07-02T11:00:31.376443',
    'title': 'There is an existing error with the same code. Try another.'
  },
  {
    'id': 38,
    'code': 'EC0029',
    'description': 'Error not found.',
    'created_at': '2021-07-02T11:00:19.682142',
    'updated_at': '2021-07-02T11:00:19.682154',
    'title': 'Error not found.'
  },
  {
    'id': 37,
    'code': 'EC0028',
    'description': 'You cannot decide on your contract.',
    'created_at': '2021-07-02T10:59:58.925107',
    'updated_at': '2021-07-02T10:59:58.925118',
    'title': 'You cannot decide on your contract.'
  },
  {
    'id': 36,
    'code': 'EC0027',
    'description': 'Contract invitation status is not PENDING.',
    'created_at': '2021-07-02T10:59:46.769141',
    'updated_at': '2021-07-02T10:59:46.769157',
    'title': 'Contract invitation status is not PENDING.'
  },
  {
    'id': 35,
    'code': 'EC0026',
    'description': 'Invalid company type to decide on contract.',
    'created_at': '2021-07-02T10:59:25.309282',
    'updated_at': '2021-07-02T10:59:25.309293',
    'title': 'Invalid company type to decide on contract.'
  },
  {
    'id': 34,
    'code': 'EC0024',
    'description': 'Some warehouses are inaccessible.',
    'created_at': '2021-07-02T10:59:13.477593',
    'updated_at': '2021-07-02T10:59:13.477604',
    'title': 'Some warehouses are inaccessible.'
  },
  {
    'id': 33,
    'code': 'EC0018',
    'description': 'Retailer company cannot create a contract.',
    'created_at': '2021-07-02T10:59:03.277738',
    'updated_at': '2021-07-02T10:59:03.277753',
    'title': 'Retailer company cannot create a contract.'
  },
  {
    'id': 32,
    'code': 'EC0015',
    'description': 'Recipient’s company type is not retailer.',
    'created_at': '2021-07-02T10:58:50.118980',
    'updated_at': '2021-07-02T10:58:50.118992',
    'title': 'Recipient’s company type is not retailer.'
  },
  {
    'id': 31,
    'code': 'EC0014',
    'description': 'Contract already exists with this email.',
    'created_at': '2021-07-02T10:49:09.954644',
    'updated_at': '2021-07-02T10:49:09.954666',
    'title': 'Contract already exists with this email.'
  },
  {
    'id': 30,
    'code': 'EC0012',
    'description': 'There is no default condition. Please, specify one as a default.',
    'created_at': '2021-07-02T10:48:47.880815',
    'updated_at': '2021-07-02T10:48:47.880827',
    'title': 'There is no default condition. Please, specify one as a default.'
  },
  {
    'id': 29,
    'code': 'EC0011',
    'description': 'Condition code has already been registered.',
    'created_at': '2021-07-02T10:48:34.787344',
    'updated_at': '2021-07-02T10:48:34.787355',
    'title': 'Condition code has already been registered.'
  },
  {
    'id': 28,
    'code': 'EC0010',
    'description': 'Invite has been sent to this email. Company type can be only retailer.',
    'created_at': '2021-07-02T10:48:24.521500',
    'updated_at': '2021-07-02T10:48:24.521512',
    'title': 'Invite has been sent to this email. Company type can be only retailer.'
  },
  {
    'id': 27,
    'code': 'EC0009',
    'description': 'Contract type is not in available contract types.',
    'created_at': '2021-07-02T10:48:04.449246',
    'updated_at': '2021-07-02T10:48:04.449258',
    'title': 'Contract type is not in available contract types.'
  },
  {
    'id': 26,
    'code': 'EC0008',
    'description': 'Contract not found. It is probably because there is a problem in server side.' +
      ' Contact with the admin at support@storfox.com',
    'created_at': '2021-07-02T10:47:50.564586',
    'updated_at': '2021-07-02T10:47:50.564598',
    'title': 'Contract not found. It is probably because there is a problem in server side.' +
      ' Contact with the admin at support@storfox.com'
  },
  {
    'id': 25,
    'code': 'EC0006',
    'description': 'This subdomain is already used',
    'created_at': '2021-07-02T10:46:23.582236',
    'updated_at': '2021-07-02T10:46:23.582248',
    'title': 'This subdomain is already used'
  },
  {
    'id': 24,
    'code': 'EC0005',
    'description': 'Retailer company cannot change its company type',
    'created_at': '2021-07-02T10:46:01.161755',
    'updated_at': '2021-07-02T10:46:01.161771',
    'title': 'Retailer company cannot change its company type'
  },
  {
    'id': 23,
    'code': 'EC0004',
    'description': 'This email is used by another account. Make sure you correctly entered it.',
    'created_at': '2021-07-02T10:45:27.985379',
    'updated_at': '2021-07-02T10:45:27.985390',
    'title': 'This email is used by another account. Make sure you correctly entered it.'
  },
  {
    'id': 22,
    'code': 'EC0003',
    'description': 'Timezone you have chosen is not in the list of region timezones. Try rechecking it.',
    'created_at': '2021-07-02T10:41:22.018242',
    'updated_at': '2021-07-02T10:41:22.018263',
    'title': 'Timezone you have chosen is not in the list of region timezones. Try rechecking it.'
  },
  {
    'id': 21,
    'code': 'EC0002',
    'description': 'Currency you have chosen is not in the list of region currencies. Try rechecking it.',
    'created_at': '2021-07-02T10:41:09.636212',
    'updated_at': '2021-07-02T10:41:09.636223',
    'title': 'Currency you have chosen is not in the list of region currencies. Try rechecking it.'
  },
  {
    'id': 20,
    'code': 'EC0001',
    'description': 'Invalid request.',
    'created_at': '2021-07-02T10:40:49.480217',
    'updated_at': '2021-07-02T10:40:49.480264',
    'title': 'Invalid request.'
  },
  {
    'id': 19,
    'code': 'EG0013',
    'description': 'Billing profile not found. It is probably because there is a problem in server side.' +
      ' Contact with the admin at support@storfox.com',
    'created_at': '2021-07-02T10:39:48.703856',
    'updated_at': '2021-07-02T10:39:48.703874',
    'title': 'Billing profile not found. It is probably because there is a problem in server side.' +
      ' Contact with the admin at support@storfox.com'
  },
  {
    'id': 18,
    'code': 'EG0012',
    'description': 'Condition not found. It is probably because there is a problem in server side.' +
      ' Contact with the admin at support@storfox.com',
    'created_at': '2021-07-02T10:39:16.320326',
    'updated_at': '2021-07-02T10:39:16.320338',
    'title': 'Condition not found. It is probably because there is a problem in server side.' +
      ' Contact with the admin at support@storfox.com'
  },
  {
    'id': 17,
    'code': 'EG0011',
    'description': 'Bundle not found. It is probably because there is a problem in server side.' +
      ' Contact with the admin at support@storfox.com',
    'created_at': '2021-07-02T10:38:08.392164',
    'updated_at': '2021-07-02T10:38:08.392176',
    'title': 'Bundle not found. It is probably because there is a problem in server side.' +
      ' Contact with the admin at support@storfox.com'
  },
  {
    'id': 16,
    'code': 'EG0010',
    'description': 'Some warehouses not found. It is probably because there is a problem in server side.' +
      ' Contact with the admin at support@storfox.com',
    'created_at': '2021-07-02T10:37:40.943760',
    'updated_at': '2021-07-02T10:37:40.943772',
    'title': 'Some warehouses not found. It is probably because there is a problem in server side.' +
      ' Contact with the admin at support@storfox.com'
  },
  {
    'id': 15,
    'code': 'EG0009',
    'description': 'User not found. It is probably because there is a problem in server side.' +
      ' Contact with the admin at support@storfox.com',
    'created_at': '2021-07-02T10:37:12.270502',
    'updated_at': '2021-07-02T10:37:12.270518',
    'title': 'User not found. It is probably because there is a problem in server side.' +
      ' Contact with the admin at support@storfox.com'
  },
  {
    'id': 14,
    'code': 'EG0008',
    'description': 'Tax not found. It is probably because there is a problem in server side.' +
      ' Contact with the admin at support@storfox.com',
    'created_at': '2021-07-02T10:36:27.615408',
    'updated_at': '2021-07-02T10:36:27.615424',
    'title': 'Tax not found. It is probably because there is a problem in server side.' +
      ' Contact with the admin at support@storfox.com'
  },
  {
    'id': 13,
    'code': 'EG0007',
    'description': 'Warehouse not found. It is probably because there is a problem in server side.' +
      ' Contact with the admin at support@storfox.com',
    'created_at': '2021-07-02T10:35:50.209495',
    'updated_at': '2021-07-02T10:35:50.209506',
    'title': 'Warehouse not found. It is probably because there is a problem in server side.' +
      ' Contact with the admin at support@storfox.com'
  },
  {
    'id': 12,
    'code': 'EG0006',
    'description': 'Condition not found. It is probably because there is a problem in server side.' +
      ' Contact with the admin at support@storfox.com',
    'created_at': '2021-07-02T10:35:19.637315',
    'updated_at': '2021-07-02T10:35:19.637327',
    'title': 'Condition not found. It is probably because there is a problem in server side.' +
      ' Contact with the admin at support@storfox.com'
  },
  {
    'id': 11,
    'code': 'EG0005',
    'description': 'Variant not found. It is probably because there is a problem in server side.' +
      ' Contact with the admin at support@storfox.com',
    'created_at': '2021-07-02T10:34:25.339904',
    'updated_at': '2021-07-02T10:34:25.339916',
    'title': 'Variant not found. It is probably because there is a problem in server side.' +
      ' Contact with the admin at support@storfox.com'
  },
  {
    'id': 10,
    'code': 'EG0004',
    'description': 'Integration not found. It is probably because there is a problem in server side.' +
      ' Contact with the admin at support@storfox.com',
    'created_at': '2021-07-02T10:33:55.963952',
    'updated_at': '2021-07-02T10:33:55.963964',
    'title': 'Integration not found. It is probably because there is a problem in server side.' +
      ' Contact with the admin at support@storfox.com'
  },
  {
    'id': 9,
    'code': 'EG0003',
    'description': 'You have missing field(s) in the form. Please check it one more time before submitting.',
    'created_at': '2021-07-02T10:32:11.791550',
    'updated_at': '2021-07-02T10:32:11.791562',
    'title': 'You have missing field(s) in the form. Please check it one more time before submitting.'
  },
  {
    'id': 8,
    'code': 'EG0002',
    'description': 'Country not found. It seems there is a server side problem.' +
      ' Try contacting with the admin at support@storfox.com.',
    'created_at': '2021-07-02T10:31:59.301151',
    'updated_at': '2021-07-02T10:31:59.301162',
    'title': 'Country not found. It seems there is a server side problem.' +
      ' Try contacting with the admin at support@storfox.com.'
  },
  {
    'id': 7,
    'code': 'EG0001',
    'description': 'It seems there is a problem with synchronization.' +
      ' Go to Settings -\u003e Account and try pressing Save button. If this problem still occurs,' +
      ' contact with the admin at support@storfox.com.',
    'created_at': '2021-07-02T10:31:36.072743',
    'updated_at': '2021-07-02T10:31:36.072761',
    'title': 'It seems there is a problem with synchronization.' +
      ' Go to Settings -\u003e Account and try pressing Save button. If this problem still occurs,' +
      ' contact with the admin at support@storfox.com.'
  }
]
