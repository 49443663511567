import { useContext, useCallback } from 'react'

import Context from './context'

export function useFilterItems () {
  const { items, setItems } = useContext(Context)

  const onSet = useCallback(items => {
    const values = items.map(
      item => ({ ...item, values: JSON.parse(atob(item.values)) })
    )

    setItems(values)
  }, [setItems])

  return { items, setItems: onSet }
}
