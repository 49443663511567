import React from 'react'
import Grid from '@mui/material/Grid'
import PropTypes from 'prop-types'

import DeliveryAddressDetail from './DeliveryAddressDetail'
import BillingAddressDetail from './BillingAddressDetail'

function CustomerDetailAddress ({ deliveryAddresses, billingAddresses }) {
  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12} lg={6}>
        <DeliveryAddressDetail
          title="Shipping address"
          addresses={deliveryAddresses}
        />
      </Grid>
      <Grid item={true} xs={12} lg={6}>
        <BillingAddressDetail
          title="Billing address"
          addresses={billingAddresses}
        />
      </Grid>
    </Grid>
  )
}

CustomerDetailAddress.propTypes = {
  billingAddresses: PropTypes.array,
  deliveryAddresses: PropTypes.array
}

export default CustomerDetailAddress
