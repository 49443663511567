import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { prop } from 'ramda'
import { useField } from 'react-final-form'
import { useValueChanged } from 'storfox-tools'

import { withForm } from '~/components/Form'
import Container, { Content, Header } from '~/components/Container'
import PageTitle from '~/components/PageTitle'
import { DiscardButton } from '~/components/Buttons'
import * as NAV from '~/constants/nav-titles'

import TransferCreateButton from './TransferCreateButton'

import TransferContent from '../TransferContent'

function TransferCreateForm (props) {
  const {
    pageTitle,
    isLoading,
    form,
    transferOrderVariant
  } = props

  const { handleSubmit } = form

  const fromWarehouseField = useField('fromWarehouse')
  const fromWarehouseValue = fromWarehouseField.input.value
  const fromWarehouseId = prop('id', fromWarehouseValue)

  const lineItemsField = useField('lineItems')
  const handleLineItemsChange = useCallback(lineItemsField.input.onChange, [])

  useValueChanged(() => {
    handleLineItemsChange([])
  }, fromWarehouseId)

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            parentTitle={NAV.TRANSFER}
            pageTitle={pageTitle}
            rightButton={(
              <>
                <DiscardButton />
                <TransferCreateButton
                  isLoading={isLoading}
                  onSubmit={handleSubmit}
                />
              </>
            )}
          />
        </Header>
        <Content>
          <TransferContent transferOrderVariant={transferOrderVariant} />
        </Content>
      </Container>
    </form>
  )
}

TransferCreateForm.propTypes = {
  form: PropTypes.object.isRequired,
  pageTitle: PropTypes.string,
  isLoading: PropTypes.bool,
  transferOrderVariant: PropTypes.object.isRequired
}

export default withForm(TransferCreateForm)
