import React, { useRef } from 'react'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import { styled } from '@mui/material'

import DeleteIconButton from '~/components/DeleteIconButton'
import TextField from '~/components/Fields/TextField'

const TableRowStyled = styled(TableRow)({
  '& td': {
    borderBottom: 'none'
  }
})

function DestinationPreview (props) {
  const {
    name,
    onRemove,
    onBarcodeFocus,
  } = props
  const { t } = useTranslation()

  const containerRef = useRef(null)
  const locationRef = useRef(null)

  const handleLocationEnter = event => {
    if (event.key === 'Enter') {
      containerRef.current.focus()
      event.preventDefault()
    }
  }

  const handleContainerEnter = event => {
    if (event.key === 'Enter') {
      onBarcodeFocus()
    }
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('Location')}</TableCell>
            <TableCell>{t('Container')}</TableCell>
            <TableCell width={50} />
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRowStyled>
            <TableCell>
              <TextField
                name={`${name}.locationId`}
                data-cy={`${name}.locationId`}
                onKeyDown={handleLocationEnter}
                inputProps={{
                  ref: locationRef
                }}
              />
            </TableCell>

            <TableCell>
              <TextField
                name={`${name}.containerCode`}
                data-cy={`${name}.containerCode`}
                label="Container"
                fullWidth={true}
                onKeyDown={handleContainerEnter}
                inputProps={{
                  ref: containerRef
                }}
              />
            </TableCell>
            <TableCell align="right">
              <DeleteIconButton onClick={onRemove} />
            </TableCell>
          </TableRowStyled>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

DestinationPreview.propTypes = {
  name: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  onBarcodeFocus: PropTypes.func.isRequired
}

export default DestinationPreview
