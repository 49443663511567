import React, { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { pathOr, propOr } from 'ramda'
import { useSnackbar } from 'storfox-snackbar'

import { HtmlLayout } from '~/components/Layouts'
import useMessages from '~/hooks/useMessages'

import LotSheetScanForm from '../components/LotSheetScan/LotSheetScanForm'
import { useLotSheetsDetail, useLotSheetsSave } from '../hooks'
import { lotSheetsSaveSerializer, scanInitialValuesSerializer } from '../serializers'

function LotSheetsDetailContainer () {
  const { guid, lineItemGuid } = useParams()
  const snackbar = useSnackbar()
  const messages = useMessages()

  const lotSheetsDetailList = useLotSheetsDetail(guid)
  const lotSheetsSave = useLotSheetsSave(guid)

  const results = propOr([], 'results', lotSheetsDetailList)

  const onSave = useCallback((values) =>
    lotSheetsSave.create(lotSheetsSaveSerializer(values))
      .then(() => lotSheetsDetailList.getList())
      .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
  , [lotSheetsDetailList, lotSheetsSave, messages, snackbar])

  const initialValues = useMemo(() => {
    return scanInitialValuesSerializer(results, lineItemGuid)
  }, [lineItemGuid, results])

  const lotNumber = pathOr('', ['lot', 'number'], initialValues)

  return (
    <HtmlLayout title="Lot sheets scan">
      <LotSheetScanForm
        pageTitle={lotNumber}
        initialValues={initialValues}
        isLoading={lotSheetsDetailList.isLoading}
        onSubmit={onSave}
      />
    </HtmlLayout>
  )
}

export default LotSheetsDetailContainer
