import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { DataGrid as StorfoxDataGrid } from 'storfox-table'

function DataGrid (props) {
  const {
    onRowClick,
    rows,
    columns,
    rowHeight,
    pinnedColumns,
    isLoading,
    headerColor,
    primaryKey,
    space,
    pagination,
    ordering,
    ...rest
  } = props

  const { t } = useTranslation()

  const translatedColumns = columns.map(column => ({
    ...column,
    label: t(column.label)
  }))

  return (
    <StorfoxDataGrid
      onRowClick={onRowClick}
      rows={rows}
      rowHeight={rowHeight}
      pinnedColumns={pinnedColumns}
      isLoading={isLoading}
      headerColor={headerColor}
      space={space}
      columns={translatedColumns}
      ordering={ordering}
      primaryKey={primaryKey}
      pagination={pagination}
      {...rest}
    />
  )
}

DataGrid.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  pinnedColumns: PropTypes.array,
  onRowClick: PropTypes.func,
  pagination: PropTypes.bool,
  primaryKey: PropTypes.string,
  headerColor: PropTypes.bool,
  autoPageSize: PropTypes.bool,
  rowHeight: PropTypes.number,
  space: PropTypes.bool,
  ordering: PropTypes.object
}

export default DataGrid
