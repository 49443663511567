export const DRAFT = 'draft'
export const CLOSED = 'closed'

export const TPL_BILLING_STATUS_NAMES = {
  [DRAFT]: 'Draft',
  [CLOSED]: 'Closed'
}

export const STORAGE_BILLS = 'storage_bills'
export const FULFILLMENT_BILLS = 'fulfillment_bills'
export const PACKING_BILLS = 'packing_bills'
export const ADHOC_BILL = 'adhoc_bill'
export const RECEIVING_BILLS = 'receiving_bills'

export const TPL_BILLING_TYPES = {
  [STORAGE_BILLS]: 'Storage bills',
  [FULFILLMENT_BILLS]: 'Fulfillment bills',
  [PACKING_BILLS]: 'Packing bills',
  [ADHOC_BILL]: 'Ad hoc bills',
  [RECEIVING_BILLS]: 'Receiving bills'
}

export const STORAGE_TYPE = 'storage'
export const FULFILLMENT_TYPE = 'fulfillment'
export const PACKING_TYPE = 'packing'
export const ADHOC_TYPE = 'adhoc'
export const RECEIVING_TYPE = 'receiving'

export const TPL_BILLING_URL_TYPES = {
  [STORAGE_BILLS]: STORAGE_TYPE,
  [FULFILLMENT_BILLS]: FULFILLMENT_TYPE,
  [PACKING_BILLS]: PACKING_TYPE,
  [ADHOC_BILL]: ADHOC_TYPE,
  [RECEIVING_BILLS]: RECEIVING_TYPE
}

export const FILTER_NAME = 'tpl_billing'
