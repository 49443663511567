import React from 'react'
import { Ordering } from 'storfox-filter'

import { USER_COLUMNS as tableColumns } from './UserTable'

export const name = 'user'

export const userOrderingOptions = {
  name,
  tableColumns
}

function UserOrderingForm (props) {
  return (
    <Ordering {...props} />
  )
}

export default UserOrderingForm
