import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material'
import Card from '@mui/material/Card'

const CardStyled = styled(Card, {
  shouldForwardProp:  propName => propName !== 'isDisabled'
})(({ theme, isDisabled }) => ({
  alignItems: 'flex-start',
  height: '100%',
  transition: 'background .25s ease',
  padding: '0',
  '&:hover': {
    backgroundColor: theme.components.StorfoxMenuCard.styleOverrides.hover.backgroundColor,
    '& $icon': {
      opacity: '.8'
    },
    '& $content *': {
      color: theme.components.StorfoxMenuCard.styleOverrides.hover.color
    },
  },
  ...(isDisabled && {
    filter: 'grayscale(100%)',
    background: 'rgba(0, 0, 0, .02)',
    '&:hover': {
      background: 'rgba(0, 0, 0, .02)'
    },
    [theme.breakpoints.down('lg')]: {
      filter: 'grayscale(90%)',
    }
  })
}))

function TileCard ({ children, disabled, className, ...props }) {
  return (
    <CardStyled
      className={className}
      isDisabled={disabled}
      {...props}
    >
      {children}
    </CardStyled>
  )
}

TileCard.propTypes = {
  children: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string
}

export default TileCard
