import React, { useMemo, useState } from 'react'
import { map, prop, propOr } from 'ramda'
import PropTypes from 'prop-types'
import { generatePath } from 'react-router-dom'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'

import { NewButton } from '~/components/Buttons'
import Container, { Content, Header } from '~/components/Container'
import PageTitle from '~/components/PageTitle'
import TableDateFormat from '~/components/TableDateFormat'
import { Table, TableHeader } from '~/components/Table'
import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'
import TableLink from '~/components/Link'
import { MOVEMENT_DETAIL_TABS } from '~/constants/tabs'

import BulkActionButton from './BulkActionButton'
import More from './More'

import MovementStatus, { DRAFT } from '../MovementStatus'

const getDetailPath = id => {
  return generatePath(ROUTES.MOVEMENT_DETAIL_PATH, { id, tab: MOVEMENT_DETAIL_TABS.GENERAL })
}

export const MOVEMENT_COLUMNS = [
  {
    width: 100,
    headerName: '#',
    field: 'number',
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        {value}
      </TableLink>
    )
  },
  {
    width: 150,
    headerName: 'Line items',
    field: 'lineItems',
    headerAlign: 'center',
    align: 'center'
  },
  {
    width: 150,
    headerName: 'Qty',
    field: 'totalQuantity',
    headerAlign: 'center',
    align: 'center'
  },
  {
    width: 125,
    headerName: 'Status',
    field: 'status',
    renderCell: ({ value }) => (
      <MovementStatus value={value} />
    )
  },
  {
    width: 150,
    headerName: 'Last updated',
    field: 'updatedAt',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  },
  {
    width: 125,
    headerName: 'Notes',
    field: 'notes'
  }
]

const getRowsFromResults = map(item => {
  const id = prop('id', item)
  const guid = prop('guid', item)
  const number = prop('number', item)
  const lineItems = prop('lineItemsCount', item)
  const totalQuantity = prop('totalQuantity', item)
  const status = prop('status', item)
  const updatedAt = prop('updatedAt', item)
  const notes = prop('notes', item)
  const link = getDetailPath(id)

  return {
    id,
    guid,
    number,
    lineItems,
    totalQuantity,
    status,
    updatedAt,
    notes,
    link
  }
})

function MovementList ({
  movementList,
  filter,
  ordering,
  onListRefetch,
  onMovementsDelete,
  onNewCreate,
  onCancel,
  bulkActionLoading
}) {
  const [tableRef, setTableRef] = useState(null)
  const count = prop('count', movementList)
  const results = propOr([], 'results', movementList)
  const isLoading = prop('isLoading', movementList)
  const columns = prop('filteredColumns', ordering)

  const actionColumns = useMemo(() => [
    ...columns,
    {
      width: 80,
      resizable: false,
      headerName: 'Actions',
      field: 'actions',
      type: 'actions',
      getActions: ({ row }) => {
        const status = prop('status', row)
        const cancelDisabled = status !== DRAFT
        return [
          <GridActionsCellItem
            label="Cancel"
            disabled={cancelDisabled}
            onClick={() => onCancel(row.id)}
            showInMenu={true}
          />
        ]
      }
    }
  ], [columns, onCancel])

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle="Movement"
          parentTitle={NAV.MOVEMENT}
          rightButton={(
            <>
              <BulkActionButton onMovementsDelete={onMovementsDelete} loading={bulkActionLoading} />
              <NewButton onClick={onNewCreate} />
            </>
          )}
        />
      </Header>
      <Content>
        <TableHeader
          filter={filter}
          filterOpen={filter.handleOpen}
          orderingOpen={ordering.handleOpen}
          ordering={ordering}
        >
          <More tableRef={tableRef} />
        </TableHeader>
        <Table
          checkboxSelection={true}
          getRowsFromResults={getRowsFromResults}
          results={results}
          columns={actionColumns}
          pinnedColumns={['number']}
          isLoading={isLoading}
          count={count}
          primaryKey="guid"
          ordering={ordering}
          onRefetch={onListRefetch}
          setTableRef={setTableRef}
        />
      </Content>
    </Container>
  )
}

MovementList.propTypes = {
  movementList: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  onMovementsDelete: PropTypes.func.isRequired,
  onNewCreate: PropTypes.func.isRequired,
  onListRefetch: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  bulkActionLoading: PropTypes.bool
}

export default MovementList
