import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import { length, path, propOr } from 'ramda'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { FieldArray } from 'react-final-form-arrays'
import PropTypes from 'prop-types'
import DialogActions from '@mui/material/DialogActions'
import TableBody from '@mui/material/TableBody'
import { CheckboxField } from 'storfox-form-fields'

import DialogTitle from '~/components/DialogTitle'
import { Button, DiscardButton } from '~/components/Buttons'
import MoneyField from '~/components/Fields/MoneyField'

import ConditionSkeleton from './ConditionSkeleton'

function ConditionModal ({ open, onClose, isLoading }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth="lg"
    >
      <DialogTitle>
        Other conditions
      </DialogTitle>
      <DialogContent>
        <FieldArray name="variant.conditionPrices">
          {({ fields }) => {
            const isEmpty = !length(propOr([], 'value', fields))

            return (
              <TableContainer>
                <Table>
                  <TableBody>
                    { fields.map((name, index) => {
                      const item = fields.value[index]
                      const conditionName = path(['condition', 'code'], item)
                      const isDefault = path(['condition', 'isDefault'], item)

                      return (
                        <TableRow key={index}>
                          <TableCell>
                            <CheckboxField
                              size="small"
                              name={`${name}.enabled`}
                              data-cy={`${name}.enabled`}
                            />
                          </TableCell>
                          <TableCell>
                            {isDefault ? <strong>{conditionName}</strong> : conditionName}
                          </TableCell>
                          <TableCell sx={{ minWidth: 250 }}>
                            <MoneyField
                              data-cy="retailPrice"
                              name={`${name}.retailPrice`}
                              label="Retail price"
                            />
                          </TableCell>
                          <TableCell sx={{ minWidth: 250 }}>
                            <MoneyField
                              data-cy="maximumRetailPrice"
                              name={`${name}.maximumRetailPrice`}
                              label="Maximum Retail price"
                            />
                          </TableCell>
                        </TableRow>
                      )
                    })}
                    {isLoading && isEmpty && (
                      <ConditionSkeleton />
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )
          }}
        </FieldArray>
      </DialogContent>
      <DialogActions>
        <DiscardButton
          onClick={onClose}
          fullWidth={true}
        >
          Discard
        </DiscardButton>

        <Button
          variant="contained"
          fullWidth={true}
          onClick={onClose}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ConditionModal.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
}

export default ConditionModal
