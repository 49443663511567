import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

function Title ({ number }) {
  const { t } = useTranslation()

  return (
    <div>
      {t('Purchase Order')} {number}
    </div>
  )
}

Title.propTypes = {
  number: PropTypes.string
}

export default Title
