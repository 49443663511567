import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card/Card'
import { Divider } from '@mui/material'
import CardContent from '@mui/material/CardContent'
import { useTranslation } from 'react-i18next'

import { CardHeader } from '~/components/Cards'
import TextField from '~/components/Fields/TextField'
import StatusField from '~/components/Fields/StatusField'

function WooCommerce () {
  const { t } = useTranslation()
  return (
    <Grid container={true}>
      <Grid item={true} xs={12}>
        <Box mb={3}>
          <Card>
            <CardHeader title={t('Integration')} />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12} lg={4}>
                  <TextField
                    data-cy="name"
                    name="name"
                    label="Name"
                  />
                </Grid>
                <Grid item={true} xs={12} lg={4}>
                  <TextField
                    data-cy="storeDomain"
                    name="credential.storeDomain"
                    label="Shop"
                    disabled={true}
                    inputProps={{
                      placeholder: 'https://woocommerce.example.app'
                    }}
                  />
                </Grid>

                <Grid item={true} xs={12} lg={4}>
                  <StatusField />
                </Grid>

                <Grid item={true} xs={12}>
                  <TextField
                    data-cy="description"
                    name="description"
                    label="Description"
                    multiline={true}
                    minRows={4}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </Grid>
  )
}

export default WooCommerce
