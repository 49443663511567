import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import { path, prop } from 'ramda'

import DialogTitle from '~/components/DialogTitle/DialogTitle'
import Button from '~/components/Buttons/Button'
import TextLabel from '~/components/TextLabel'

import SelectableAddressCard from './SelectableAddressCard'

function AddressDialog ({ title, open, handleClose, options, value, onChange }) {
  const handleChange = (event, option) => {
    onChange(option)
    handleClose()
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth={true}>
      <DialogTitle>{title}</DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container={true} spacing={2}>
          {options.map((option, index) => {
            const guid = prop('guid', option)
            const address = prop('address', option)
            const zipcode = prop('zipcode', option)
            const country = path(['country', 'name'], option)
            const city = prop('city', option)

            return (
              <Grid item={true} xs={12} lg={6} key={index}>
                <SelectableAddressCard
                  title={`Address ${index + 1}`}
                  selected={guid === value.guid}
                  onClick={event => handleChange(event, option)}
                >
                  <Grid container={true} spacing={2}>
                    <Grid item={true} xs={12}>
                      <TextLabel label="Address">
                        {address}
                      </TextLabel>
                    </Grid>
                    <Grid item={true} xs={12}>
                      <TextLabel label="City">
                        {city}
                      </TextLabel>
                    </Grid>
                    <Grid item={true} xs={12}>
                      <TextLabel label="Zip code">
                        {zipcode}
                      </TextLabel>
                    </Grid>
                    <Grid item={true} xs={12}>
                      <TextLabel label="Country">
                        {country}
                      </TextLabel>
                    </Grid>
                  </Grid>
                </SelectableAddressCard>
              </Grid>
            )
          })}
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose} >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

AddressDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
}

export default AddressDialog
