import React, { useState } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'
import { prop, propOr } from 'ramda'
import { useDeepCompareEffect } from 'storfox-api-hooks'

import { DashboardLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'
import ErrorLink from '~/components/ErrorLink'
import { INTEGRATION_TABS } from '~/constants/tabs'
import useMessages from '~/hooks/useMessages'
import { useNotification } from '~/components/Notification'
import { SHOPIFY_PRODUCTS_SYNC_STATUS } from '~/constants/notification-topics'

import {
  useIntegrationHistory,
  useShopifyIntegrationDetail,
  useShopifyIntegrationRetryOrder,
  useShopifyIntegrationRemove,
  useShopifyIntegrationUpdate, useShopifyProductsSync
} from '../../hooks'
import ShopifyIntegrationUpdateForm from '../../components/Shopify/ShopifyUpdate/ShopifyIntegrationUpdateForm'

function ShopifyIntegrationUpdateContainer () {
  const { guid, tab } = useParams()

  const [syncData, setSyncData] = useState({ fetched: 0, totalCount: 0 })

  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const messages = useMessages()
  const integrationDetail = useShopifyIntegrationDetail(guid)
  const integrationUpdate = useShopifyIntegrationUpdate(guid)
  const integrationRetryOrder = useShopifyIntegrationRetryOrder(guid)
  const integrationRemove = useShopifyIntegrationRemove()
  const integrationProductSync = useShopifyProductsSync(guid)

  const title = prop('name', integrationDetail.detail)
  const type = propOr('', 'type', integrationDetail.detail)

  const productSync = useNotification(SHOPIFY_PRODUCTS_SYNC_STATUS)

  const detailPath = generatePath(ROUTES.INTEGRATION_UPDATE_PATH, { guid, type, tab: INTEGRATION_TABS.GENERAL })

  const historyAutoSend = Boolean(guid && tab === INTEGRATION_TABS.LOGS)
  const integrationHistory = useIntegrationHistory(guid, historyAutoSend)

  useDeepCompareEffect(() => {
    productSync.subscribe(guid, ({ payload }) => {
      const result = prop('result', payload)
      const fetched = prop('fetched', result)
      const totalCount = prop('totalCount', result)
      setSyncData({ fetched, totalCount })
    })
  }, [guid])

  const handleSubmit = formValues =>
    integrationUpdate.update(formValues)
      .then(() => {
        snackbar({ message: messages.UPDATE_SUCCESS })
        navigate(detailPath)
      })
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const isLoading = (
    integrationDetail.isLoading ||
    integrationUpdate.isLoading
  )

  const handleRemove = () =>
    integrationRemove.delete({ guids: [guid] })
      .then(() => navigate(ROUTES.INTEGRATION_LIST_PATH, { replace: true }))

  const handleProductsSync = () =>
    integrationProductSync.create()
      .then(() => snackbar({ message: messages.NOTIFICATION_WAIT }))
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })

  const handleRetry = requestBody => {
    integrationRetryOrder.create(requestBody)
      .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
      .catch(error => {
        const message = <ErrorLink error={error} />
        snackbar({ message, type: ALTER_ERROR })
      })
  }

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      isLoading={integrationDetail.isLoading}
      breadcrumbs={breadcrumbs}
      activeNav={NAV.INTEGRATION}
    >
      <ShopifyIntegrationUpdateForm
        isLoading={isLoading}
        pageTitle={title}
        pageTitleLoading={integrationDetail.isLoading}
        onSubmit={handleSubmit}
        onRemove={handleRemove}
        onProductsSync={handleProductsSync}
        initialValues={integrationDetail.detail}
        history={integrationHistory}
        onRetry={handleRetry}
        retryLoading={integrationRetryOrder.isLoading}
        syncData={syncData}
      />
    </DashboardLayout>
  )
}

export default ShopifyIntegrationUpdateContainer
