import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { map, path, pathOr, prop, propOr } from 'ramda'
import { generatePath } from 'react-router-dom'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'

import PageTitle from '~/components/PageTitle'
import TableDateFormat from '~/components/TableDateFormat'
import Tags from '~/components/Tags'
import TableNotes from '~/components/TableNotes'
import Container, { Content, Header } from '~/components/Container'
import Channel from '~/components/Channel'
import { NewButton } from '~/components/Buttons'
import TextOverflow from '~/components/TextOverflow'
import { Table, TableHeader } from '~/components/Table'
import { AllocationStatus, FulfillmentProgress, SaleOrderStatus, ShipmentStatus } from '~/components/Statuses'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import CurrencyMoney from '~/components/Money/CurrencyMoney'
import TableLink from '~/components/Link'
import { SALE_ORDER_DETAIL_TABS } from '~/constants/tabs'
import { useGoogleEvent } from '~/components/GoogleAnalytics/GoogleAnalytics'
import { CANCELLED, COMPLETED } from '~/components/Statuses/SaleOrderStatus'
import { DELIVERED, RETURNED } from '~/components/Statuses/ShipmentStatus'

import BulkActionButton from './BulkActionButton'
import More from './More'

export const SALE_ORDER_COLUMNS = [
  {
    width: 125,
    headerName: 'SO #',
    field: 'number',
    renderCell: ({ value, row }) => {
      return (
        <TableLink to={row.link}>
          <TextOverflow lines={1} selfTooltip={true}>
            {value}
          </TextOverflow>
        </TableLink>
      )
    }
  },
  {
    width: 125,
    headerName: 'Reference #',
    field: 'referenceNumber',
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        <TextOverflow
          tooltip={`Reference number from ${row.channelName}`}
          lines={1}
        >
          {value}
        </TextOverflow>
      </TableLink>
    )
  },
  {
    width: 75,
    headerName: 'Channel',
    field: 'channel',
    ordering: true,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    renderCell: ({ value }) => (
      <Channel value={value} />
    ),
  },
  {
    width: 150,
    headerName: 'Line items',
    field: 'lineItemsCount',
    headerAlign: 'center',
    align: 'center',
  },
  {
    width: 150,
    headerName: 'Total units count',
    field: 'totalUnitsCount',
    headerAlign: 'center',
    align: 'center',
  },
  {
    width: 175,
    headerName: 'Total',
    field: 'totalPrice',
    renderCell: ({ value, row }) => (
      <CurrencyMoney currency={row.currency} value={value} />
    )
  },
  {
    width: 125,
    headerName: 'Status',
    field: 'status',
    renderCell: ({ value }) => (
      <SaleOrderStatus value={value} />
    )
  },
  {
    width: 200,
    headerName: 'Allocation status',
    field: 'allocationStatus',
    renderCell: ({ value }) => (
      <AllocationStatus value={value} />
    )
  },
  {
    width: 200,
    headerName: 'Shipment status',
    field: 'shipmentStatus',
    renderCell: ({ value }) => (
      <ShipmentStatus value={value} />
    )
  },
  {
    width: 300,
    headerName: 'Fulfillment progress',
    field: 'progress',
    renderCell: ({ value }) => (
      <FulfillmentProgress status={value} />
    ),
    sortable: false
  },
  {
    width: 200,
    headerName: 'Customer',
    field: 'customer',
    renderCell: ({ value }) => (
      <TextOverflow selfTooltip={true}>
        {value}
      </TextOverflow>
    )
  },
  {
    width: 150,
    headerName: 'City',
    field: 'city'
  },
  {
    width: 200,
    headerName: 'Warehouse',
    field: 'warehouse'
  },
  {
    width: 150,
    headerName: 'Company',
    field: 'company',
    renderCell: ({ value }) => (
      <TextOverflow selfTooltip={true}>{value}</TextOverflow>
    )
  },
  {
    width: 200,
    headerName: 'Order date',
    field: 'createdAt',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  },
  {
    width: 200,
    headerName: 'Last updated',
    field: 'updatedAt',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  },
  {
    width: 200,
    headerName: 'Confirmation date',
    field: 'confirmedAt',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  },
  {
    width: 300,
    headerName: 'Tags',
    field: 'tags',
    sortable: false,
    valueFormatter: ({ value }) => value.length > 0 ? value.join(',') : '',
    renderCell: ({ value }) => (
      <Tags items={value} />
    )
  },
  {
    width: 125,
    field: 'notes',
    headerAlign: 'center',
    align: 'center',
    headerName: 'Notes',
    sortable: false,
    renderCell: ({ value }) => (
      <TableNotes>
        {value}
      </TableNotes>
    )
  },
  {
    width: 150,
    headerName: 'Payment type',
    field: 'paymentType'
  },
  {
    width: 150,
    headerName: 'Delivery method',
    field: 'deliveryMethod'
  },
  {
    width: 150,
    headerName: 'Shipping method',
    field: 'shippingMethod'
  },
  {
    width: 150,
    headerName: 'Tracking number',
    field: 'trackingNumber'
  },
]

const getRowsFromResults = map(result => {
  const id = prop('id', result)
  const guid = prop('guid', result)
  const number = prop('number', result)

  const channelName = pathOr('channel', ['channel', 'name'], result)
  const referenceNumber = prop('referenceNumber', result)

  const totalPrice = prop('totalPrice', result)
  const currency = prop('currency', result)

  const channel = path(['channel', 'type'], result)
  const lineItemsCount = prop('lineItemsCount', result)
  const totalUnitsCount = prop('totalUnitsCount', result)
  const progress = prop('fulfillmentStatus', result)
  const status = prop('status', result)
  const allocationStatus = prop('allocationStatus', result)
  const shipmentStatus = prop('shipmentStatus', result)
  const warehouse = path(['warehouse', 'name'], result)
  const company = path(['company', 'name'], result)
  const customerFirstName = path(['customer', 'firstName'], result)
  const customerLastName = path(['customer', 'lastName'], result)
  const customer = `${customerFirstName} ${customerLastName}`
  const city = path(['deliveryAddress', 'city'], result)
  const createdAt = prop('createdAt', result)
  const updatedAt = prop('updatedAt', result)
  const confirmedAt = prop('confirmedAt', result)
  const notes = prop('customerNotes', result)
  const tags = propOr([], 'tags', result)
  const paymentType = prop('paymentType', result)
  const trackingNumber = prop('trackingNumber', result)
  const deliveryMethod = path(['deliveryMethod', 'name'], result)
  const shippingMethod = path(['shippingMethod', 'name'], result)

  const link = generatePath(ROUTES.SALE_ORDER_DETAIL_PATH, {
    guid,
    tab: SALE_ORDER_DETAIL_TABS.GENERAL
  })

  return {
    id,
    guid,
    number,
    channelName,
    referenceNumber,
    channel,
    lineItemsCount,
    totalUnitsCount,
    currency,
    totalPrice,
    status,
    allocationStatus,
    shipmentStatus,
    progress,
    customer,
    city,
    warehouse,
    company,
    createdAt,
    updatedAt,
    tags,
    notes,
    link,
    paymentType,
    deliveryMethod,
    trackingNumber,
    confirmedAt,
    shippingMethod
  }
})

function SaleOrderTable (props) {
  const {
    list,
    filter,
    ordering,
    onProcess,
    onAllocate,
    onReportGenerate,
    onOrdersDelete,
    handleCancel,
    onListRefetch,
    onReturn,
    onCancel,
    onMarkAsPaid,
    onInvoiceGenerate,
    bulkActionLoading,
    onSetWarehouse
  } = props

  const { sendEvent } = useGoogleEvent()
  const [tableRef, setTableRef] = useState(null)
  const isLoading = prop('isLoading', list)
  const results = prop('results', list)
  const hasNextPage = prop('hasNextPage', list)
  const hasPrevPage = prop('hasPrevPage', list)
  const count = prop('count', list)
  const columns = prop('filteredColumns', ordering)

  const actionColumns = useMemo(() => [
    ...columns,
    {
      width: 80,
      resizable: false,
      headerName: 'Actions',
      field: 'actions',
      type: 'actions',
      getActions: ({ row }) => {
        const status = prop('status', row)
        const shipmentStatus = prop('shipmentStatus', row)
        const returnDisabled = status !== COMPLETED
        const cancelDisabled = (
          isLoading ||
          shipmentStatus === DELIVERED ||
          shipmentStatus === RETURNED ||
          status === CANCELLED
        )
        const invoiceDisabled = status === CANCELLED
        return [
          <GridActionsCellItem
            label="Request return"
            disabled={returnDisabled}
            onClick={() => onReturn(row.guid)}
            showInMenu={true}
          />,
          <GridActionsCellItem
            label="Cancel"
            disabled={cancelDisabled}
            onClick={() => onCancel(row.id)}
            showInMenu={true}
          />,
          <GridActionsCellItem
            label="Mark as paid"
            onClick={() => onMarkAsPaid(row.guid)}
            showInMenu={true}
          />,
          <GridActionsCellItem
            label="Generate invoice"
            disabled={invoiceDisabled}
            onClick={() => onInvoiceGenerate(row.guid)}
            showInMenu={true}
          />
        ]
      }
    }
  ], [columns, isLoading, onCancel, onInvoiceGenerate, onMarkAsPaid, onReturn])

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={NAV.SALE_ORDERS}
          parentTitle={NAV.SALES}
          rightButton={(
            <>
              <BulkActionButton
                onProcess={onProcess}
                onAllocate={onAllocate}
                onCancel={handleCancel}
                onReportGenerate={onReportGenerate}
                onOrdersDelete={onOrdersDelete}
                loading={bulkActionLoading}
                onSetWarehouse={onSetWarehouse}
              />
              <NewButton
                path={ROUTES.SALE_ORDER_CREATE_PATH}
                onClick={() => sendEvent({ eventAction: 'New Sale Order', eventCategory: 'Sale Order' })}
              />
            </>
          )}
        />
      </Header>

      <Content>
        <TableHeader
          filter={filter}
          ordering={ordering}
          isLoading={isLoading}
          filterOpen={filter.handleOpen}
          orderingOpen={ordering.handleOpen}
        >
          <More tableRef={tableRef} />
        </TableHeader>
        <Table
          isLoading={isLoading}
          columns={actionColumns}
          getRowsFromResults={getRowsFromResults}
          results={results}
          count={count}
          pinnedColumns={['number']}
          primaryKey="guid"
          ordering={ordering}
          checkboxSelection={true}
          onRefetch={onListRefetch}
          setTableRef={setTableRef}
          isNewPagination={true}
          hasNextPage={hasNextPage}
          hasPrevPage={hasPrevPage}
        />
      </Content>
    </Container>
  )
}

SaleOrderTable.propTypes = {
  list: PropTypes.object.isRequired,
  onProcess: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  onAllocate: PropTypes.func.isRequired,
  onOrdersDelete: PropTypes.func.isRequired,
  onReportGenerate: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  onListRefetch: PropTypes.func.isRequired,
  onReturn: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onMarkAsPaid: PropTypes.func.isRequired,
  onInvoiceGenerate: PropTypes.func.isRequired,
  onSetWarehouse: PropTypes.func.isRequired,
  bulkActionLoading: PropTypes.bool
}

export default SaleOrderTable
