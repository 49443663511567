import React from 'react'
import { useTranslation } from 'react-i18next'
import { defaultTo, groupBy, map, pipe, prop, uniq } from 'ramda'
import TableCell from '@mui/material/TableCell'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import PropTypes from 'prop-types'

import { ExpandableTableRow, ExpandedTableWrapper } from '~/components/Table'

import LocationRow from './LocationRow'

import { getStockTotalValues } from '../../../utils'

function ConditionRow ({ children, conditions }) {
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(false)

  const groupedLocations = groupBy(prop('locationId'), conditions)

  const locationIds = pipe(
    defaultTo([]),
    map(prop('locationId')),
    uniq
  )(conditions)

  return (
    <>
      <ExpandableTableRow open={open} onExpandToggle={() => setOpen(!open)}>
        {children}
      </ExpandableTableRow>

      <ExpandedTableWrapper open={open} colSpan={6}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell style={{ minWidth: 400 }}>{t('Location')}</TableCell>
              <TableCell align="center">{t('Available')}</TableCell>
              <TableCell align="center">{t('Unavailable')}</TableCell>
              <TableCell align="center">{t('On Hand')}</TableCell>
              <TableCell align="center">{t('Allocated')}</TableCell>
              <TableCell align="center">{t('Action')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {locationIds.map(locationId => {
              const locations = groupedLocations[locationId]
              const { allocated, available, unavailable, onHand } = getStockTotalValues(locations)

              return (
                <LocationRow locations={locations}>
                  <TableCell>{locationId}</TableCell>
                  <TableCell align="center">{available}</TableCell>
                  <TableCell align="center">{unavailable}</TableCell>
                  <TableCell align="center">{onHand}</TableCell>
                  <TableCell align="center">{allocated}</TableCell>
                </LocationRow>
              )
            })}
          </TableBody>
        </Table>
      </ExpandedTableWrapper>
    </>
  )
}

ConditionRow.propTypes = {
  children: PropTypes.any.isRequired,
  conditions: PropTypes.array.isRequired
}

export default ConditionRow
