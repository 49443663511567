import React from 'react'
import PropTypes from 'prop-types'

import { withForm } from '~/components/Form'
import PageTitle from '~/components/PageTitle'
import { DiscardButton, SaveButton } from '~/components/Buttons'
import Container, { Content, Header } from '~/components/Container'
import * as NAV from '~/constants/nav-titles'

import PurchaseOrderForm from '../PurchaseOrderForm/PurchaseOrderForm'

export const PURCHASE_ORDER_CREATE_FIELDS = [
  'supplier',
  'company',
  'billingAddress',
  'deliveryAddress',
  'lineItems',
  'notes',
  'warehouse',
  'orderDate',
  'expectedDate',
  'paymentType',
  'paymentTerm',
  'notifySupplier',
  'supplierReference',
  'tags'
]

function PurchaseOrderCreateForm ({ pageTitle, onSupplierCreate, isLoading, purchaseOrderVariant, form }) {
  return (
    <form onSubmit={form.handleSubmit} autoComplete="off">
      <Container>
        <Header>
          <PageTitle
            pageTitle={pageTitle}
            parentTitle={NAV.PURCHASE_ORDER}
            rightButton={(
              <>
                <DiscardButton disabled={isLoading} />
                <SaveButton disabled={isLoading} />
              </>
            )}
          />
        </Header>

        <Content>
          <PurchaseOrderForm
            isLoading={false}
            onSupplierCreate={onSupplierCreate}
            purchaseOrderVariant={purchaseOrderVariant}
          />
        </Content>
      </Container>
    </form>
  )
}

PurchaseOrderCreateForm.propTypes = {
  form: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSupplierCreate: PropTypes.func.isRequired,
  pageTitle: PropTypes.string,
  purchaseOrderVariant: PropTypes.object.isRequired
}

export default withForm(PurchaseOrderCreateForm)
