import { useState } from 'react'
import { useDeepCompareEffect } from 'storfox-api-hooks'
import { defaultTo, filter, flatten, includes, map, pipe, pluck, prop, propOr, reduce, sortBy } from 'ramda'
import { useAllSearchParams } from 'storfox-route-hooks'

import useOrderingBackend from './useOrderingBackend'
import { useStorage } from './useStorage'
import useFilterItemsCount from './useFilterItemsCount'

import { useOrderingItems } from '../'
import { ORDERING_KEY } from '../constants'

const defaultsProps = {
  autoClose: true
}

const getColumnKeys = pipe(
  defaultTo([]),
  pluck('key'),
  flatten
)

const getBackendColumnsObject = pipe(
  defaultTo([]),
  reduce((acc, item) => {
    const key = prop('key', item)
    return { ...acc, [key]: item }
  }, {})
)

const getColumns = ({ tableColumns, backendColumns }) => {
  const backendColumnKeys = getColumnKeys(backendColumns)
  const backendColumnsObject = getBackendColumnsObject(backendColumns)

  return pipe(
    map(column => {
      return includes(column.field, backendColumnKeys)
        ? { ...column, ...backendColumnsObject[column.field] }
        : { ...column, visible: true }
    }),
    sortBy(prop('position'))
  )(tableColumns)
}

function useOrdering (props) {
  const { name, tableColumns } = { ...defaultsProps, ...props }

  const [open, setOpen] = useState(false)
  const backend = useOrderingBackend({ name })
  const { setItems } = useOrderingItems()
  const { count, ...params } = useAllSearchParams()

  const filterItemsCount = useFilterItemsCount(name)
  const countResults = propOr([], 'results', filterItemsCount)

  useDeepCompareEffect(() => {
    filterItemsCount.getList()
  }, [params])

  const orderingStorage = useStorage(ORDERING_KEY)
  const orderingList = orderingStorage.get

  useDeepCompareEffect(() => {
    setItems(orderingList || [])
  }, [])

  const values = { tableColumns, backendColumns: backend.columnItems }
  const columns = getColumns(values)

  const visibleFilters = filter(prop('visible'), backend.filterItems)

  const columnVisibilityModel = columns.reduce((acc, column) => ({
    ...acc, [column.field]: column.visible
  }), {})

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const itemsWithCount = visibleFilters.map(item => {
    const foundCountResult = countResults.find(countResult => countResult.id === item.id)
    return { ...item, count: propOr(0, 'count', foundCountResult) }
  })

  return {
    name,
    countResults,
    open,
    backend,
    filteredColumns: columns,
    columnVisibilityModel,
    visibleFilters: itemsWithCount,
    handleOpen,
    handleClose,
    onColumnVisibilityUpdate: backend.onColumnVisibilityUpdate,
    onColumnOrderChange: backend.onColumnsUpdate,
    onColumnResize: backend.onColumnResize
  }
}

export default useOrdering
