import React from 'react'
import { pathOr, prop } from 'ramda'
import Typography from '@mui/material/Typography'

import * as API from '~/constants/api'
import SearchField from '~/components/Fields/SearchField'

const getOptionValue = value => {
  if (value) {
    const id = prop('id', value)
    const guid = prop('guid', value)
    const name = prop('name', value)
    const companyName = pathOr('Unknown', ['company', 'name'], value)

    return { id, guid, name, companyName }
  }

  return null
}

function SaleOrderDeliveryMethodField (props) {
  return (
    <SearchField
      api={API.ORDER_DELIVERY_METHOD}
      getOptionValue={getOptionValue}
      data-cy="deliveryMethod"
      name="deliveryMethod"
      label="Delivery Method"
      renderOption={(props, option) => (
        <li {...props} style={{ display: 'block' }}>
          <Typography>
            {option.name}
          </Typography>
          <Typography variant="body2">
            {option.companyName}
          </Typography>
        </li>
      )}
      {...props}
    />
  )
}

export default SaleOrderDeliveryMethodField
