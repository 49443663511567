import React from 'react'
import DialogContent from '@mui/material/DialogContent'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useField } from 'react-final-form'
import { filter, isEmpty, isNil } from 'ramda'
import { DateTimeField } from 'storfox-form-fields'

import DialogTitle from '~/components/DialogTitle'
import { Button, DiscardButton } from '~/components/Buttons'
import TextField from '~/components/Fields/TextField'
import { withForm } from '~/components/Form'
import PhoneNumberField from '~/components/Fields/PhoneNumberField'

import GatePassLineItems from './GatePassLineItems'

function GatePassCreateDialog ({ onClose, form, open, isLoading, ...props }) {
  const { handleSubmit } = form
  const lineItems = useField('lineItems')
  const lineItemsValue = lineItems.input.value
  const disabledCreate =
    isEmpty(filter(item => !(item.quantity === 0 || isNil(item.quantity)), lineItemsValue)) ||
    isLoading

  return (
    <Dialog
      maxWidth="xl"
      onClose={onClose}
      open={open}
      fullWidth={true}
      {...props}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          <Typography component="h3" variant="h6">Create gate pass</Typography>
        </DialogTitle>
        <DialogContent dividers={true}>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12} lg={6}>
              <TextField label="Invoice Number" name="invoiceNumber" />
            </Grid>
            <Grid item={true} xs={12} lg={6}>
              <TextField label="Vehicle Number Plate" name="vehicle.numberPlate" />
            </Grid>
            <Grid item={true} xs={12} lg={6}>
              <TextField name="driver.name" label="Driver Name" />
            </Grid>
            <Grid item={true} xs={12} lg={6}>
              <PhoneNumberField name="driver.mobile" label="Driver Mobile" />
            </Grid>
            <Grid item={true} xs={12} lg={6}>
              <DateTimeField name="driver.timeIn" label="Time in" />
            </Grid>
            <Grid item={true} xs={12} lg={6}>
              <DateTimeField name="driver.timeOut" label="Time out" />
            </Grid>
            <Grid item={true} xs={12} lg={12}>
              <GatePassLineItems />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DiscardButton
            fullWidth={true}
            onClick={onClose}
          >
            Discard
          </DiscardButton>
          <Button
            variant="contained"
            type="submit"
            disabled={disabledCreate}
            fullWidth={true}
          >
            Create
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

GatePassCreateDialog.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
}

export default withForm(GatePassCreateDialog)
