import React from 'react'
import { useTranslation } from 'react-i18next'

import SelectField from '~/components/Fields/SelectField'

export const MANUAL = 'manual'
export const NEXT_EMPTY_BIN = 'next_empty_bin'
export const NEAR_PACKING_LOCATION = 'near_packing_location'

const options = [
  { value: MANUAL, name: 'Manual' },
  { value: NEXT_EMPTY_BIN, name: 'Next Empty Bin' },
  { value: NEAR_PACKING_LOCATION, name: 'Near Packing Location' },
]

function PutawayStrategyField ({ ...props }) {
  const { t } = useTranslation()

  return (
    <SelectField
      data-cy="putawayStrategy"
      label={t('Putaway Strategy')}
      defaultValue={MANUAL}
      options={options}
      {...props}
    />
  )
}

export default PutawayStrategyField
