import React from 'react'
import PropTypes from 'prop-types'

import TextLabel from '../TextLabel'
import { Loader } from '../Loader'

function TextLabelLoader (props) {
  return (
    <Loader>
      {isLoading => (
        <TextLabel isLoading={isLoading} {...props} />
      )}
    </Loader>
  )
}

TextLabelLoader.propTypes = {
  children: PropTypes.any,
  label: PropTypes.string
}

export default TextLabelLoader
