import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import useMessages from '~/hooks/useMessages'

import BucketCreateForm from '../components/BucketCreateForm'
import { useBucketCreate } from '../hooks'
import { BucketCreateSerializer } from '../serializers'

function BucketCreateContainer () {
  const navigate = useNavigate()
  const messages = useMessages()
  const snackbar = useSnackbar()
  const bucketCreate = useBucketCreate()

  const handleSubmit = formValue =>
    bucketCreate.create(BucketCreateSerializer(formValue))
      .then(() => navigate(ROUTES.BUCKETS_LIST_PATH))
      .then(() => snackbar({ message: messages.CREATE_SUCCESS }))

  const breadcrumbs = { title: NAV.BUCKETS }

  return (
    <DashboardLayout
      title={NAV.BUCKETS}
      activeNav={NAV.BUCKETS}
      isLoading={bucketCreate.isLoading}
      breadcrumbs={breadcrumbs}
    >
      <BucketCreateForm
        pageTitle="New Bucket"
        initialValues={{}}
        isLoading={bucketCreate.isLoading}
        onSubmit={handleSubmit}
      />
    </DashboardLayout>
  )
}

export default BucketCreateContainer
