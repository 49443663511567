import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import WarehouseListContainer from './containers/WarehouseListContainer'
import OwnWarehouseDetailContainer from './containers/OwnWarehouseDetailContainer'
import RetailerWarehouseDetailContainer from './containers/SharedWarehouseDetailContainer'
import WarehouseCreateContainer from './containers/WarehouseCreateContainer'
import WarehouseUpdateContainer from './containers/WarehouseUpdateContainer'
import WarehouseImportContainer from './containers/WarehouseImportContainer'
import WarehouseVisualizationContainer from './containers/WarehouseVisualizationContainer'
import Warehouse2DVisualizationContainer from './containers/Warehouse2DVisualizationContainer'

export default () => [
  {
    layout: AuthLayout,
    path: ROUTES.WAREHOUSE_LIST_PATH,
    component: WarehouseListContainer
  },
  {
    layout: AuthLayout,
    path: ROUTES.WAREHOUSE_CREATE_PATH,
    component: WarehouseCreateContainer
  },
  {
    layout: AuthLayout,
    path: ROUTES.SHARED_WAREHOUSE_DETAIL_PATH,
    component: RetailerWarehouseDetailContainer
  },
  {
    layout: AuthLayout,
    path: ROUTES.WAREHOUSE_DETAIL_PATH,
    component: OwnWarehouseDetailContainer
  },
  {
    layout: AuthLayout,
    path: ROUTES.WAREHOUSE_UPDATE_PATH,
    component: WarehouseUpdateContainer
  },
  {
    layout: AuthLayout,
    path: ROUTES.WAREHOUSE_IMPORT_PATH,
    component: WarehouseImportContainer
  },
  {
    layout: AuthLayout,
    path: ROUTES.WAREHOUSE_VISUALIZATION_PATH,
    component: WarehouseVisualizationContainer
  },
  {
    layout: AuthLayout,
    path: ROUTES.WAREHOUSE_2D_VISUALIZATION_PATH,
    component: Warehouse2DVisualizationContainer
  }
]
