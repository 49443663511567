import React from 'react'
import PropTypes from 'prop-types'

import Tabs from '~/components/Tabs'

export const ORDER_BY_UNITS_DATE_TAB = 'soView'
export const PO_BY_UNITS_DATE_TAB = 'poView'

const tabs = [
  { value: ORDER_BY_UNITS_DATE_TAB, label: 'Orders By Dates & Units' },
  { value: PO_BY_UNITS_DATE_TAB, label: 'PO By Dates & Units' }
]

function OrderTabs ({ value, onChange }) {
  return (
    <Tabs
      tabs={tabs}
      value={value}
      onChange={onChange}
    />
  )
}

OrderTabs.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func
}

export default OrderTabs
