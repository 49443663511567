import React from 'react'
import { propOr } from 'ramda'

import * as API from '~/constants/api'

import SearchField from './SearchField'

const getOptionLabel = propOr('', 'companyName')

const getOptionValue = item => {
  if (item) {
    const { id, companyName } = item

    return { id, companyName }
  }

  return null
}

function SupplierSearchField (props) {
  return (
    <SearchField
      name="supplier"
      label="Supplier"
      api={API.SUPPLIER_LIST}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      {...props}
    />
  )
}

export default SupplierSearchField
