import React from 'react'
import { useTranslation } from 'react-i18next'
import SelectField from 'storfox-form-fields/src/SelectField'

const options = [
  { value: 'bora', name: 'Bora' },
  { value: 'carton', name: 'Carton' },
  { value: 'shopper', name: 'Shopper' },
  { value: 'pallet', name: 'Pallet' },
  { value: 'bundle', name: 'Bundle' },
  { value: 'bulk', name: 'Bulk' },
  { value: 'roll', name: 'Roll' },
]

function LotContainerTypeField ({ ...props }) {
  const { t } = useTranslation()

  return (
    <SelectField
      name="type"
      label={t('Container type')}
      options={options}
      {...props}
    />
  )
}

export default LotContainerTypeField
