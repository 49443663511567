import { PACKING_CREATED_FIELDS } from './Actions/PackingCreatedAction'
import { PUTAWAY_CREATED_FIELDS } from './Actions/PutawayCreatedAction'
import { PUTAWAY_V2_CREATED_FIELDS } from './Actions/PutawayV2CreatedAction'
import { PICKING_CREATED_FIELDS } from './Actions/PickingCreatedAction'
import { ORDER_CREATED_FIELDS } from './Actions/OrderCreatedAction'
import { SHIPMENT_CREATED_FIELDS } from './Actions/ShipmentCreatedAction'
import { SEND_TO_USERS_FIELDS } from './Actions/LowStockAction'
import { ORDER_CHANGED_FIELDS } from './Actions/OrderChangedAction'
import { REPLENISHMENT_FIELDS } from './Actions/ReplenishmentAction'
import { LISTING_CREATED_FIELDS } from './Actions/RuleListingCreatedAction'
import { PURCHASE_CHANGE_FIELDS } from './Actions/PurchaseChangeAction'
import { SHIPMENT_STATUS } from './Actions/ShipmentStatusChangeAction'

import {
  LISTING_CREATED,
  LOW_STOCK,
  ORDER_CHANGED,
  ORDER_CREATED,
  PACKING_CREATED,
  PICKING_CREATED,
  PUTAWAY_CREATED,
  PUTAWAY_V2_CREATED,
  REPLENISHMENT,
  SHIPMENT_CREATED,
  PURCHASE_CHANGE,
  SHIPMENT_SET_STATUS
} from '../../constants/AutomationRule'

export const FIELDS_TO_PICK = {
  [ORDER_CREATED]: ORDER_CREATED_FIELDS,
  [ORDER_CHANGED]: ORDER_CHANGED_FIELDS,
  [PICKING_CREATED]: PICKING_CREATED_FIELDS,
  [PUTAWAY_CREATED]: PUTAWAY_CREATED_FIELDS,
  [PUTAWAY_V2_CREATED]: PUTAWAY_V2_CREATED_FIELDS,
  [PACKING_CREATED]: PACKING_CREATED_FIELDS,
  [SHIPMENT_CREATED]: SHIPMENT_CREATED_FIELDS,
  [LOW_STOCK]: SEND_TO_USERS_FIELDS,
  [REPLENISHMENT]: REPLENISHMENT_FIELDS,
  [LISTING_CREATED]: LISTING_CREATED_FIELDS,
  [PURCHASE_CHANGE]: PURCHASE_CHANGE_FIELDS,
  [SHIPMENT_SET_STATUS]: SHIPMENT_STATUS
}
