import React from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import { path, prop, propOr } from 'ramda'
import PropTypes from 'prop-types'
import Link from '@mui/material/Link'
import { useTranslation } from 'react-i18next'

import { generateCdnUrl, getFormattedDate } from '~/utils'
import { CardHeader } from '~/components/Cards'
import { useLoader } from '~/components/Loader'
import TextLabelLoader from '~/components/TextLabelLoader'
import Tags from '~/components/Tags'
import { PAYMENT_TYPES } from '~/constants/paymentTypes'
import { PAYMENT_TERMS } from '~/constants/paymentTerms'
import NotesCard from '~/components/NotesCard'
import TextOverflow from '~/components/TextOverflow'

import PurchaseOrderSupplierDetail from './Supplier/PurchaseOrderSupplierDetail'
import LineItems from './LineItems'
import Statuses from './Statuses'

import InvoiceSummary from '../InvoiceSummary'

function PurchaseOrderGeneral ({ detail }) {
  const { t } = useTranslation()
  const isLoading = useLoader()
  const supplier = prop('supplier', detail)
  const deliveryAddress = prop('deliveryAddress', detail)
  const billingAddress = prop('billingAddress', detail)
  const orderDate = prop('orderDate', detail)
  const expectedDate = prop('expectedDate', detail)
  const paymentType = prop('paymentType', detail)
  const paymentTerm = prop('paymentTerm', detail)
  const supplierReference = prop('supplierReference', detail)
  const warehouse = path(['warehouse', 'name'], detail)
  const company = path(['company', 'name'], detail)
  const tags = propOr([], 'tags', detail)
  const notes = prop('notes', detail)
  const attachmentUrl = prop('attachmentUrl', detail)
  const lineItems = propOr([], 'lineItems', detail)

  return (
    <Grid container={true} spacing={3} alignItems="stretch">
      <Grid item={true} xs={12} lg={8}>
        <Box mb={3}>
          <Statuses detail={detail} isLoading={isLoading} />
        </Box>
        <Box>
          <Card>
            <CardHeader title="Supplier" />
            <Divider />
            <CardContent>
              <PurchaseOrderSupplierDetail
                supplier={supplier}
                billingAddress={billingAddress}
                deliveryAddress={deliveryAddress}
              />
            </CardContent>
          </Card>
        </Box>
      </Grid>

      <Grid item={true} xs={12} lg={4}>
        <Box height="100%">
          <Card style={{ height: '100%' }}>
            <CardHeader title="Details" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Ship to warehouse">
                    {warehouse}
                  </TextLabelLoader>
                </Grid>
                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Company">
                    {company}
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Order date">
                    {getFormattedDate(orderDate)}
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Expected date">
                    {getFormattedDate(expectedDate)}
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Payment type">
                    {PAYMENT_TYPES[paymentType]}
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Payment term">
                    {PAYMENT_TERMS[paymentTerm]}
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Reference id">
                    {supplierReference}
                  </TextLabelLoader>
                </Grid>

                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Tags">
                    <Tags items={tags} />
                  </TextLabelLoader>
                </Grid>

                {attachmentUrl && (
                  <Grid item={true} xs={12}>
                    <TextLabelLoader label="Attached file">
                      <Link
                        href={generateCdnUrl(attachmentUrl)}
                        rel="noopener noreferrer"
                        underline="always"
                        target="_blank"
                      >
                        <TextOverflow selfTooltip={true} lines={1}>
                          {t('File')}
                        </TextOverflow>
                      </Link>
                    </TextLabelLoader>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>

      <Grid item={true} xs={12}>
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={12}>
            <Card>
              <CardHeader title="Line items" />
              <Divider />
              <LineItems isLoading={isLoading} lineItems={lineItems} />
            </Card>
          </Grid>

          <Grid item={true} xs={12} lg={6}>
            <Card>
              <CardHeader title="Invoice Summary" />
              <Divider />
              <InvoiceSummary lineItems={lineItems} />
            </Card>
          </Grid>
          <Grid item={true} xs={12} lg={6}>
            <NotesCard notes={notes} title="Supplier notes" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

PurchaseOrderGeneral.propTypes = {
  detail: PropTypes.object.isRequired
}

export default PurchaseOrderGeneral
