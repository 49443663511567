import React from 'react'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { prop, path, propOr, pathOr } from 'ramda'
import PropTypes from 'prop-types'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import TableContainer from '@mui/material/TableContainer'
import { generatePath, useNavigate } from 'react-router-dom'
import { styled } from '@mui/material'

import EmptyNotesIcon from '~/components/Icons/EmptyNotesIcon'
import EmptyLineItems from '~/components/EmptyLineItems/EmptyLineItems'
import SkeletonList from '~/components/Skeleton'
import TableDateFormat from '~/components/TableDateFormat/TableDateFormat'
import { PUTAWAY_DETAIL_TABS } from '~/constants/tabs'
import * as ROUTES from '~/constants/routes'
import { HoverableRow } from '~/components/Table'
import { toDuration } from '~/utils'

import PutawayStatus from './PutawayStatus'

const TableRowStyled = styled(HoverableRow)({
  height: '70px'
})

const TableCellStyled = styled(TableCell)({
  minWidth: '150px'
})

function SaleOrderPutawayTable ({ putawayList }) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const isLoading = prop('isLoading', putawayList)
  const results = prop('results', putawayList)

  const renderEmptyLines = Boolean(!isLoading && !results.length)
  const renderLineItems = Boolean(!isLoading && results.length)

  return (
    <Box component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto' }}>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCellStyled>{t('ID')}</TableCellStyled>
              <TableCellStyled>{t('Line items')}</TableCellStyled>
              <TableCellStyled>{t('Qty')}</TableCellStyled>
              <TableCellStyled>{t('Status')}</TableCellStyled>
              <TableCellStyled>{t('From location')}</TableCellStyled>
              <TableCellStyled>{t('Assigned to')}</TableCellStyled>
              <TableCellStyled>{t('Duration')}</TableCellStyled>
              <TableCellStyled>{t('Created date')}</TableCellStyled>
              <TableCellStyled>{t('Updated date')}</TableCellStyled>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <SkeletonList
                rowCount={3}
                columnCount={13}
                height={20}
                rowHeight={70}
              />
            )}

            {renderEmptyLines && (
              <EmptyLineItems
                colSpan={13}
                icon={<EmptyNotesIcon />}
                text="No return by sale order"
              />
            )}
            {renderLineItems && results.map((row, index) => {
              const id = prop('id', row)
              const guid = prop('guid', row)
              const lineItems = propOr(0, 'lineItemCount', row)
              const qty = propOr(0, 'lineItemQuantity', row)
              const status = prop('status', row)
              const fromLocation = path(['fromLocation', 'locationId'], row)
              const assignedTo = pathOr('Not Assigned', ['assignedTo', 'firstName'], row)
              const duration = toDuration(prop('duration', row))
              const createdAt = prop('createdAt', row)
              const updatedAt = prop('updatedAt', row)

              const params = { guid, tab: PUTAWAY_DETAIL_TABS.GENERAL }
              const putawayUrl = generatePath(ROUTES.V2_PUTAWAY_DETAIL_PATH, params)

              return (
                <TableRowStyled key={index} onClick={() => navigate(putawayUrl)}>
                  <TableCell>{id}</TableCell>
                  <TableCell>{lineItems}</TableCell>
                  <TableCell>{qty}</TableCell>
                  <TableCell><PutawayStatus value={status} /></TableCell>
                  <TableCell>{fromLocation}</TableCell>
                  <TableCell>{assignedTo}</TableCell>
                  <TableCell>{duration}</TableCell>
                  <TableCell><TableDateFormat withTime={true} date={createdAt} /></TableCell>
                  <TableCell><TableDateFormat withTime={true} date={updatedAt} /></TableCell>
                </TableRowStyled>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

SaleOrderPutawayTable.propTypes = {
  putawayList: PropTypes.object.isRequired
}

export default SaleOrderPutawayTable
