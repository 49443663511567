import React from 'react'
import PropTypes from 'prop-types'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ListItemButton } from '@mui/material'

import { MoreButton } from '~/components/Buttons'

function More (props) {
  const {
    onGenerateSlip,
    slipDisabled,
    packerDialogOpenDisabled,
    onPackerDialogOpen,
    containerTypesPrintPath,
    containerTypesPathDisabled
  } = props

  const { t } = useTranslation()

  return (
    <MoreButton>
      {onClose => (
        <List>
          <ListItem
            button={true}
            disabled={packerDialogOpenDisabled}
            onClick={() => {
              onPackerDialogOpen()
              onClose()
            }}
            data-cy="setPacker"
          >
            <ListItemText primary={t('Set packer')} />
          </ListItem>

          <ListItem
            disabled={containerTypesPathDisabled}
            button={true}
            component={Link}
            to={containerTypesPrintPath}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ListItemText primary={t('Print container types')} />
          </ListItem>
          <ListItemButton onClick={onGenerateSlip} disabled={slipDisabled}>
            <ListItemText primary={t('Print packing slip')} />
          </ListItemButton>
        </List>
      )}
    </MoreButton>
  )
}

More.propTypes = {
  containerTypesPathDisabled: PropTypes.bool.isRequired,
  containerTypesPrintPath: PropTypes.string.isRequired,
  onPackerDialogOpen: PropTypes.func.isRequired,
  packerDialogOpenDisabled: PropTypes.bool.isRequired,
  slipDisabled: PropTypes.bool.isRequired,
  onGenerateSlip: PropTypes.func.isRequired
}

export default More
