import React from 'react'
import PropTypes from 'prop-types'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { Grid, styled } from '@mui/material'
import Box from '@mui/material/Box'

import * as NAV from '~/constants/nav-titles'
import SaveButton from '~/components/Buttons/SaveButton'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import withForm from '~/components/Form/withForm'
import DiscardButton from '~/components/Buttons/DiscardButton'
import LoaderProvider from '~/components/Loader'
import { LISTING_TABS } from '~/constants/tabs'
import * as ROUTES from '~/constants/routes'

import ListingProductUpdate from './ListingProductUpdate'
import ListingUpdateTabs from './ListingUpdateTabs'
import ListingVariantUpdate from './ListingVariantUpdate'
import ListingUpdateListItems from './ListingUpdateListItems'

export const LISTING_CREATE_FIELDS = [
  'name'
]

const ContainerBoxStyled = styled(Box)(({ theme }) => ({
  paddingRight: 0,
  [theme.breakpoints.up('md')]: {
    paddingLeft: 300,
    width: '100%',
    maxWidth: '100%'
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: 0
  }
}))

function ListingUpdateForm (props) {
  const {
    title,
    form,
    detail,
    isLoading,
    pageTitleLoading
  } = props
  const { guid, tab } = useParams()
  const navigate = useNavigate()

  const { handleSubmit, initialValues } = form

  const handleVariantOptionChange = guid => {
    const path = generatePath(ROUTES.LISTING_UPDATE_PATH, { guid, tab: LISTING_TABS.VARIANT })
    navigate(path, { replace: true })
  }

  return (
    <LoaderProvider isLoading={isLoading}>
      <div>
        <Box height="100%" display="flex">
          <ListingUpdateListItems
            detail={detail}
            listingGuid={guid}
            onVariantOptionChange={handleVariantOptionChange}
          />
        </Box>
        <ContainerBoxStyled>
          <form onSubmit={handleSubmit}>
            <Container>
              <Header>
                <PageTitle
                  pageTitle={title}
                  parentTitle={NAV.LISTING}
                  pageTitleLoading={pageTitleLoading}
                  rightButton={(
                    <>
                      <DiscardButton disabled={isLoading} />
                      <SaveButton disabled={isLoading} />
                    </>
                  )}
                />
              </Header>

              <Content>
                <Grid>
                  <ListingUpdateTabs value={tab} />
                  {tab === LISTING_TABS.VARIANT && (
                    <ListingVariantUpdate
                      isLoading={isLoading}
                      initialValues={initialValues}
                    />
                  )
                  }
                  {tab === LISTING_TABS.PRODUCT && <ListingProductUpdate />}
                </Grid>
              </Content>
            </Container>
          </form>
        </ContainerBoxStyled>
      </div>
    </LoaderProvider>
  )
}

ListingUpdateForm.propTypes = {
  title: PropTypes.string,
  pageTitleLoading: PropTypes.bool,
  form: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  detail: PropTypes.object.isRequired
}

export default withForm(ListingUpdateForm)
