import React from 'react'

import SelectField from '~/components/Fields/SelectField'

import { PAID, PARTIALLY_REFUNDED, REFUNDED, UNPAID } from '../Statuses/PaymentStatus'

export const OPTIONS = [
  {
    value: UNPAID,
    name: 'Unpaid'
  },
  {
    value: PAID,
    name: 'Paid'
  },
  {
    value: REFUNDED,
    name: 'Refunded'
  },
  {
    value: PARTIALLY_REFUNDED,
    name: 'Partially refunded'
  }
]

function PaymentStatusField (props) {
  return (
    <SelectField
      name="paymentStatus"
      label="Payment status"
      options={OPTIONS}
      {...props}
    />
  )
}

export default PaymentStatusField
