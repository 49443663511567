import React from 'react'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'

import TemplateList from '../../components/Templates/TemplateList'
import { useTemplateList } from '../../hooks/Template'

function TemplateListContainer () {
  const breadcrumbs = { title:'Template' }
  const templateList = useTemplateList()
  return (
    <DashboardLayout
      activeNav={NAV.CONFIGURATION}
      breadcrumbs={breadcrumbs}
      title="Template"
    >
      <TemplateList
        list={templateList}
      />
    </DashboardLayout>
  )
}

export default TemplateListContainer
