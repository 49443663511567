import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'

const RootStyled = styled(Box)(({ theme }) => ({
  zIndex: 2,
  padding: theme.spacing(1, 4),
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(1, 2)
  },
}))

function FluidHeader ({ children }) {
  return (
    <RootStyled>
      {children}
    </RootStyled>
  )
}

FluidHeader.propTypes = {
  children: PropTypes.any.isRequired
}

export default FluidHeader
