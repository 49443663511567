import React from 'react'
import { generatePath, Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import { styled } from '@mui/material'
import { ClassNames } from '@emotion/react'

import { TOP_BAR_HEIGHT } from '~/constants/settings'
import Logo from '~/components/Logo/Logo'
import * as ROUTES from '~/constants/routes'
import { useCompany } from '~/components/Profile/hooks'
import { OVERVIEW_TABS } from '~/constants/tabs'

const RootStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(1, 2),
  minHeight: TOP_BAR_HEIGHT.sm,
  maxHeight: TOP_BAR_HEIGHT.sm
}))

function MobileTopMenu () {
  const { isThreePl } = useCompany()

  const tab = isThreePl ? OVERVIEW_TABS.FULFILLMENT : OVERVIEW_TABS.SALES
  const to = generatePath(ROUTES.OVERVIEW_PATH, { tab })

  return (
    <RootStyled>
      <Link
        component={RouterLink}
        to={to}
        underline="none"
        color="initial"
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <ClassNames>
          {({ css }) => (
            <Logo className={css({
              width: 125,
              '& .st8': {
                fill: 'white'
              }
            })} />
          )}
        </ClassNames>
      </Link>
    </RootStyled>
  )
}

export default MobileTopMenu
