import React from 'react'
import PropTypes from 'prop-types'
import Divider from '@mui/material/Divider'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

import Tabs from '~/components/Tabs'
import { VARIANT_UPDATE_PATH } from '~/constants/routes'
import { VARIANT_UPDATE_TABS } from '~/constants/tabs'

function VariantUpdateTabs ({ value, isMultiVariant }) {
  const navigate = useNavigate()
  const { id, productId } = useParams()
  const onChange = (event, tab) => {
    const redirect = generatePath(VARIANT_UPDATE_PATH, { id, productId, tab })
    navigate({
      pathname: redirect,
      search: `?isMultiVariant=${isMultiVariant}`
    }, { replace: true })
  }

  const tabs = [
    { value: VARIANT_UPDATE_TABS.GENERAL, label: 'General' },
    { value: VARIANT_UPDATE_TABS.INTERNATIONAL, label: 'International' }
  ]

  return (
    <>
      <Tabs
        tabs={tabs}
        value={value}
        onChange={onChange}
      />
      <Divider />
    </>
  )
}

VariantUpdateTabs.propTypes = {
  value: PropTypes.string.isRequired,
  isMultiVariant: PropTypes.bool.isRequired
}

export default VariantUpdateTabs
