import React from 'react'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import PropTypes from 'prop-types'
import { path, prop, propOr } from 'ramda'
import { useTranslation } from 'react-i18next'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material'

import SkeletonList from '~/components/Skeleton'
import { Duration, Volume, Weight } from '~/components/Converters'
import Money from '~/components/Money'
import TableDateFormat from '~/components/TableDateFormat'

const TableCellStyled = styled(TableCell)({
  minWidth: 150
})

function TplBillingStorageTable ({ detail, isLoading }) {
  const { t } = useTranslation()
  const billItems = propOr([], 'billItems', detail)

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('Warehouse')}</TableCell>
              <TableCellStyled>{t('Location')}</TableCellStyled>
              <TableCell>{t('Volume')}</TableCell>
              <TableCell>{t('Max. weight')}</TableCell>
              <TableCell>{t('Weight')}</TableCell>
              <TableCell>{t('Price')}</TableCell>
              <TableCell>{t('Additional price')}</TableCell>
              <TableCell>{t('Interval')}</TableCell>
              <TableCell>{t('Per duration')}</TableCell>
              <TableCellStyled>{t('From')}</TableCellStyled>
              <TableCellStyled>{t('To')}</TableCellStyled>
              <TableCell>{t('Total price')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? <SkeletonList rowCount={5} columnCount={11} />
              : billItems.map((item, index) => {
                const location = path(['location', 'locationId'], item)
                const fromDatetime = prop('fromDatetime', item)
                const interval = prop('interval', item)
                const perDuration = prop('perDuration', item)
                const price = prop('price', item)
                const additionalPrice = prop('additionalPrice', item)
                const toDatetime = prop('toDatetime', item)
                const totalPrice = prop('totalPrice', item)
                const volume = prop('volume', item)
                const warehouse = path(['warehouse', 'label'], item)
                const maxWeight = prop('maxWeight', item)
                const weight = prop('weight', item)

                return (
                  <TableRow key={index}>
                    <TableCell>{warehouse}</TableCell>
                    <TableCellStyled>{location}</TableCellStyled>
                    <TableCell>
                      <Volume value={volume} />
                    </TableCell>
                    <TableCell>
                      <Weight value={maxWeight} />
                    </TableCell>
                    <TableCell>
                      <Weight value={weight} />
                    </TableCell>
                    <TableCell>
                      <Money value={price} />
                    </TableCell>
                    <TableCell>
                      <Money value={additionalPrice} />
                    </TableCell>
                    <TableCell>{interval}</TableCell>
                    <TableCell>
                      <Duration value={perDuration} />
                    </TableCell>
                    <TableCellStyled>
                      <TableDateFormat date={fromDatetime} withTime={true} />
                    </TableCellStyled>
                    <TableCellStyled>
                      <TableDateFormat date={toDatetime} withTime={true} />
                    </TableCellStyled>
                    <TableCell>
                      <Money value={totalPrice} />
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

TplBillingStorageTable.propTypes = {
  detail: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default TplBillingStorageTable
