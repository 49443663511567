import React from 'react'
import { sprintf } from 'sprintf-js'
import PropTypes from 'prop-types'
import { useField } from 'react-final-form'
import { prop } from 'ramda'
import { usePrevious } from 'storfox-tools'
import { useDeepCompareEffect } from 'storfox-api-hooks'
import Box from '@mui/material/Box'

import SearchField from '~/components/Fields/SearchField'

const getOptionValue = value => {
  const { id, name, code, price } = value
  return { id, name, code, price }
}

function ShippingServiceField ({ integrationName, api, ...props }) {
  const field = useField('service')

  const integrationField = useField(integrationName)
  const integrationValue = integrationField.input.value
  const integrationGuid = prop('guid', integrationValue)

  const prevIntegrationGuid = usePrevious(integrationGuid)

  const url = sprintf(api, integrationGuid)

  useDeepCompareEffect(() => {
    const integrationChanged = (
      integrationGuid &&
      integrationGuid !== prevIntegrationGuid &&
      integrationField.meta.dirty
    )

    if (integrationChanged || !integrationGuid) {
      field.input.onChange('')
    }
  }, [integrationGuid])

  return (
    <Box position="relative">
      <SearchField
        api={url}
        name="service"
        label="Service"
        disabled={!integrationGuid}
        getOptionValue={getOptionValue}
        {...props}
      />
    </Box>
  )
}

ShippingServiceField.propTypes = {
  api: PropTypes.string.isRequired,
  integrationName: PropTypes.string.isRequired
}

export default ShippingServiceField
