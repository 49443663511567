import React, { startTransition, useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { useToken } from '~/components/Token'

import Context from './context'

import { useOrderingList, useStorage } from '../..'
import { ORDERING_KEY } from '../../constants'

function OrderingProvider ({ children }) {
  const [state, setState] = useState([])
  const orderingList = useOrderingList()
  const orderingStorage = useStorage(ORDERING_KEY)
  const { token } = useToken()
  const getOrderingList = useCallback(orderingList.getList, [])
  const setOrderingStorage = useCallback(orderingStorage.set, [])

  const value = {
    items: state,
    setItems: setState
  }

  useEffect(() => {
    if (token) {
      getOrderingList()
        .then(({ results }) => {
          startTransition(() => setOrderingStorage(results))
        })
    } else {
      startTransition(() => setOrderingStorage(null))
    }
  }, [getOrderingList, setOrderingStorage, token])

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  )
}

OrderingProvider.propTypes = {
  children: PropTypes.any.isRequired
}

export default OrderingProvider
