import React from 'react'
import PropTypes from 'prop-types'
import { Card, Divider } from '@mui/material'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'

import Instruction from '~/components/Instruction'

export const SHOPIFY_INSTRUCTIONS = [
  <>1. Add Storfox app to your Shopify account by clicking on
    &nbsp;<strong>"Install"</strong> button on top-right corner.</>,
  <>2. Choose store, to which you want to integrate Storfox app.</>,
  <>3. Install the app.</>,
  <>4. After installation, you will be redirected to the app's page.</>,
  <>5. Now, you need to fill up all the fields.</>,
  <>6. Click save button on the top-right corner.</>
]

function ShopifyInstructionCard ({ completedTasks }) {
  return (
    <Card>
      <CardHeader title="Instruction" />
      <Divider />
      <CardContent>
        <Instruction
          completedTasks={completedTasks}
          instructions={SHOPIFY_INSTRUCTIONS}
        />
      </CardContent>
    </Card>
  )
}

ShopifyInstructionCard.propTypes = {
  completedTasks: PropTypes.number.isRequired
}

ShopifyInstructionCard.defaultProps = {
  completedTasks: 0
}

export default ShopifyInstructionCard
