import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'storfox-snackbar'
import { useTranslation } from 'react-i18next'

import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'
import { DashboardLayout } from '~/components/Layouts'

import { useCurrencyRateCreate } from '../../hooks/CurrencyRate'
import CurrencyRateCreateForm from '../../components/CurrencyRate/CurrencyRateCreate'
import { CurrencyRateSerializer } from '../../serializers/CurrencyRate'

function CurrencyRateCreateContainer () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const currencyRateCreate = useCurrencyRateCreate()
  const message = t('Successfully created')

  const handleSubmit = formValues =>
    currencyRateCreate.create(CurrencyRateSerializer(formValues))
      .then(() => navigate(ROUTES.CURRENCY_RATE_LIST_PATH))
      .then(() => snackbar({ message }))

  const title = 'New Currency Rate'

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      activeNav={NAV.CONFIGURATION}
      breadcrumbs={breadcrumbs}
    >
      <CurrencyRateCreateForm
        title={title}
        onSubmit={handleSubmit}
        isLoading={currencyRateCreate.isLoading}
      />
    </DashboardLayout>
  )
}

export default CurrencyRateCreateContainer
