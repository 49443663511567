import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import { Filter } from 'storfox-filter'
import { prop, propOr } from 'ramda'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { PositiveFloatField, DateRangeField } from 'storfox-form-fields'

import { escapeAtob, getSeparatedDateRange, unescapeBtoa } from '~/utils'
import WarehouseMultiSelectField from '~/components/Fields/WarehouseMultiSelectField'
import Accordion from '~/components/Accordion'

import InvoiceCustomerField from './InvoiceCustomerField'

import { FILTER_NAME as name } from '../../constants'

export const toParams = values => {
  const warehouses = unescapeBtoa(propOr([], 'warehouses', values))
  const customers = unescapeBtoa(propOr([], 'customers', values))

  const [createdAtStart, createdAtEnd] = getSeparatedDateRange(prop('createdAt', values))
  const [updatedAtStart, updatedAtEnd] = getSeparatedDateRange(prop('updatedAt', values))
  const totalPriceStart = prop('totalPriceStart', values)
  const totalPriceEnd = prop('totalPriceEnd', values)

  return {
    warehouses,
    customers,
    createdAtStart,
    createdAtEnd,
    updatedAtStart,
    updatedAtEnd,
    totalPriceStart,
    totalPriceEnd
  }
}

export const toValues = params => {
  const warehouses = escapeAtob(prop('warehouses', params))
  const customers = escapeAtob(prop('customers', params))

  const createdAt = [prop('createdAtStart', params), prop('createdAtEnd', params)]
  const updatedAt = [prop('updatedAtStart', params), prop('updatedAtEnd', params)]
  const totalPriceStart = prop('totalPriceStart', params)
  const totalPriceEnd = prop('totalPriceEnd', params)

  return {
    warehouses,
    customers,
    createdAt,
    updatedAt,
    totalPriceStart,
    totalPriceEnd
  }
}

export const transformer = { toParams, toValues }

export const invoiceFilterOptions = {
  name,
  transformer
}

function InvoiceFilterForm ({ companyDisabled, ...props }) {
  const { t } = useTranslation()
  return (
    <Filter {...props}>
      <Accordion title="General" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <WarehouseMultiSelectField />
          </Grid>
          <Grid item={true} xs={12}>
            <InvoiceCustomerField />
          </Grid>
        </Grid>
      </Accordion>

      <Accordion title="Date">
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <Typography variant="body2" gutterBottom={true}>
              {t('Created')}:
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <DateRangeField name="createdAt" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography variant="body2" gutterBottom={true}>
              {t('Updated')}:
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <DateRangeField name="updatedAt" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Accordion>
      <Accordion title="Total ($)">
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={6}>
            <PositiveFloatField
              data-cy="totalPriceStart"
              name="totalPriceStart"
              label="Min ($)"
            />
          </Grid>
          <Grid item={true} xs={6}>
            <PositiveFloatField
              data-cy="totalPriceEnd"
              name="totalPriceEnd"
              label="Max ($)"
            />
          </Grid>
        </Grid>
      </Accordion>
    </Filter>
  )
}

InvoiceFilterForm.propTypes = {
  companyDisabled: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default InvoiceFilterForm
