import React from 'react'
import PropTypes from 'prop-types'

import * as API from '~/constants/api'
import WarehouseMultiSelectField from '~/components/Fields/WarehouseMultiSelectField'

function WarehouseActionMultiSelectField ({ name, ...props }) {
  return (
    <WarehouseMultiSelectField
      label="Warehouses"
      api={API.AUTOMATION_RULE_WAREHOUSE_LIST}
      name={name}
      {...props}
    />
  )
}

WarehouseActionMultiSelectField.propTypes = {
  name: PropTypes.string.isRequired
}

export default WarehouseActionMultiSelectField
