import React from 'react'
import { Filter } from 'storfox-filter'
import Grid from '@mui/material/Grid'
import { prop } from 'ramda'

import Accordion from '~/components/Accordion'

import SupplierStatusField from './SupplierStatusField'

import { FILTER_NAME as name } from '../../constants'

export const toParams = values => {
  const status = prop('status', values)

  return { status }
}

export const toValues = params => {
  const status = prop('status', params)

  return { status }
}

export const transformer = { toParams, toValues }

export const supplierFilterOptions = {
  name,
  transformer
}

function SupplierFilterForm (props) {
  return (
    <Filter {...props}>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <Accordion title="General" defaultExpanded={true}>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <SupplierStatusField />
              </Grid>
            </Grid>
          </Accordion>
        </Grid>
      </Grid>
    </Filter>
  )
}

export default SupplierFilterForm
