import React from 'react'
import Grid from '@mui/material/Grid'
import { propOr } from 'ramda'
import { useField } from 'react-final-form'
import PropTypes from 'prop-types'

import ShipmentMethodCard from './ShipmentMethodCard'
import ShipmentAddress from './ShipmentAddress'
import ShipmentFromAddress from './ShipmentFromAddress'

function ShipmentGeneral ({ shippingAddress }) {
  const detailField = useField('shippingDetail')
  const detail = detailField.input.value

  const currency = propOr('', 'currency', detail)

  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12} lg={12}>
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={12}>
            <ShipmentMethodCard currency={currency} address={shippingAddress} />
          </Grid>
          <Grid item={true} xs={6}>
            <ShipmentFromAddress />
          </Grid>
          <Grid item={true} xs={6}>
            <ShipmentAddress />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

ShipmentGeneral.propTypes = {
  shippingAddress: PropTypes.object.isRequired,
}

export default ShipmentGeneral
