import palette from '~/theme/light/palette'

export default {
  styleOverrides: {
    hover: {
      backgroundColor: palette.hover.color,
      color: palette.text.primary
    },
    icon: {
      color: palette.icon.primary
    }
  }
}
