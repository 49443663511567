import React from 'react'

import StorfoxShipmentStatusField, { OPTIONS as BASE_OPTIONS } from '~/components/Fields/ShipmentStatusField'

const OPTIONS = [ { value: null, name: 'All' }, ...BASE_OPTIONS ]

function ShipmentStatusField (props) {
  return (
    <StorfoxShipmentStatusField
      name="status"
      label="Status"
      options={OPTIONS}
      {...props}
    />
  )
}

export default ShipmentStatusField
