import { styled } from '@mui/material'
import MUIInputBase, { inputBaseClasses } from '@mui/material/InputBase'

export const BootstrapInput = styled(MUIInputBase)(({ theme }) => ({
  [`& .${inputBaseClasses.input}`]: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    padding: '10px 12px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}))
