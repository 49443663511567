import React from 'react'
import { useParams } from 'react-router-dom'
import { prop } from 'ramda'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import Title from '~/components/Title'
import ErrorLink from '~/components/ErrorLink'
import * as NAV from '~/constants/nav-titles'

import TplBillingDetail from '../components/TplBillingDetail'
import { useTplBillingDetail, useTplBillingMarkAsPaid } from '../hooks'
import { TplBillingDetailSerializer } from '../serializers'

const message = 'Successfully message'

function BillingDetailContainer () {
  const { guid } = useParams()
  const snackbar = useSnackbar()
  const billingDetail = useTplBillingDetail(guid)
  const billingMarkAsPaid = useTplBillingMarkAsPaid(guid)

  const title = prop('number', billingDetail.detail)
  const status = prop('status', billingDetail.detail)
  const paymentStatus = prop('paymentStatus', billingDetail.detail)

  const pageTitle = (
    <Title
      title={title}
      status={status}
      additionalStatuses={[ paymentStatus ]}
    />
  )

  const handleMarkAsPaid = () =>
    billingMarkAsPaid.mark()
      .then(() => snackbar({ message }))
      .then(() => billingDetail.getDetail())
      .catch(error => snackbar({ type: ALTER_ERROR, message: <ErrorLink error={error} /> }))

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      activeNav={NAV.BILLING}
      isLoading={billingDetail.isLoading}
      breadcrumbs={breadcrumbs}
    >
      <TplBillingDetail
        detail={TplBillingDetailSerializer(billingDetail.detail)}
        pageTitleLoading={billingDetail.isLoading}
        pageTitle={pageTitle}
        isLoading={billingDetail.isLoading}
        onMarkAsPaid={handleMarkAsPaid}
      />
    </DashboardLayout>
  )
}

export default BillingDetailContainer
