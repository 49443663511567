import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Typography from '@mui/material/Typography'
import DialogContentText from '@mui/material/DialogContentText'

import useDialog from '~/hooks/useDialog'

import DialogTitle from '../DialogTitle/DialogTitle'
import Button from '../Buttons/Button'
import DangerButton from '../Buttons/DangerButton'

function DeleteConfirmButton ({ dialogTitle, dialogContent, onClick, ...props }) {
  const { open, handleOpen, handleClose } = useDialog()

  const onSubmit = () => {
    handleClose()
    onClick()
  }

  return (
    <>
      <DangerButton {...props} onClick={handleOpen} />

      <Dialog open={open} onClose={handleClose} maxWidth="lg">
        <DialogTitle>
          <Typography variant="h6">{dialogTitle}</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography>{dialogContent}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button color="secondary" onClick={onSubmit}>Yes</Button>
        </DialogActions>
      </Dialog>

    </>
  )
}

DeleteConfirmButton.defaultProps = {
  dialogTitle: 'Delete item',
  dialogContent: 'Are you sure you want to delete this item?'
}

DeleteConfirmButton.propTypes = {
  dialogTitle: PropTypes.string,
  dialogContent: PropTypes.any,
  onClick: PropTypes.func.isRequired
}

export default DeleteConfirmButton
