import { MARKETPLACE, RETAILER, THREE_PL } from '~/constants/companyTypes'

import sharedRoutes, { retailerRoutes, marketplaceRoutes, threeplRoutes } from './modules'

const routes = () => ({
  [RETAILER]: [
    ...retailerRoutes(),
    ...sharedRoutes()
  ],
  [MARKETPLACE]: [
    ...marketplaceRoutes(),
    ...sharedRoutes()
  ],
  [THREE_PL]: [
    ...threeplRoutes(),
    ...sharedRoutes()
  ]
})

export default routes
