import React from 'react'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'

import { useIntegrationList } from '../../hooks'
import IntegrationList from '../../components/IntegrationList/IntegrationList'

function IntegrationListContainer () {
  const integrationList = useIntegrationList()

  const breadcrumbs = { title: NAV.INTEGRATION }

  return (
    <DashboardLayout
      title={NAV.INTEGRATION}
      isLoading={integrationList.isLoading}
      activeNav={NAV.INTEGRATION}
      breadcrumbs={breadcrumbs}
    >
      <IntegrationList list={integrationList} />
    </DashboardLayout>
  )
}

export default IntegrationListContainer
