import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { prop, propOr } from 'ramda'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'

import HtmlTooltip from '~/components/HtmlTooltip/HtmlTooltip'

import TopChangeableInformationItem from '../../TopInformation/TopChangeableInformationItem'
import Loader from '../../Loader'
import TopInformationWrapper from '../../TopInformation/TopInformationWrapper'
import TopInformationItem from '../../TopInformation/TopInformationItem'
import SummarizeMoney from '../../SummarizeMoney'

function RetailerGeneral ({ data, isLoading }) {
  const [isAvgBasketQty, setIsAvgBasketQty] = useState(false)

  const handleAvgBucketViewChange = () => {
    setIsAvgBasketQty(prevIsAvgBucketQty => !prevIsAvgBucketQty)
  }

  return (
    <Card>
      <Grid
        alignItems="center"
        container={true}
        justifyContent="space-between"
      >
        {isLoading && <Loader />}
        {!isLoading && (
          <>
            <TopInformationWrapper md={12 / 5} sm={6} xs={12}>
              <TopInformationItem
                title="GMS"
                value={<SummarizeMoney value={prop('gms', data)} />}
                percent={0}
                tooltip="Total value of goods sold on Storfox, excluding shipping fees and refunds."
              />
            </TopInformationWrapper>

            <TopInformationWrapper md={12 / 5} sm={6} xs={12}>
              <TopInformationItem
                title="Orders"
                value={prop('orders', data)}
                percent={0}
                tooltip="Total number of orders."
              />
            </TopInformationWrapper>

            <TopInformationWrapper md={12 / 5} sm={6} xs={12}>
              <HtmlTooltip title="Average cost of orders.">
                {isAvgBasketQty && (
                  <TopChangeableInformationItem
                    title="Avg. basket qty"
                    value={propOr(0, 'avgBasketQuantity', data).toFixed(3)}
                    percent={0}
                    onChange={handleAvgBucketViewChange}
                  />
                )}
                {!isAvgBasketQty && (
                  <TopChangeableInformationItem
                    title="Avg. basket cost"
                    value={<SummarizeMoney value={prop('avgBasketCost', data)} />}
                    percent={0}
                    onChange={handleAvgBucketViewChange}
                  />
                )}
              </HtmlTooltip>
            </TopInformationWrapper>

            <TopInformationWrapper md={12 / 5} sm={6} xs={12}>
              <TopInformationItem
                title="Fulfillment rate"
                value={prop('rate', data)}
                percent={0}
                tooltip="Fulfilment Rate = (Completed Orders / Total Orders) *100"
              />
            </TopInformationWrapper>

            <TopInformationWrapper md={12 / 5} sm={6} xs={12}>
              <TopInformationItem
                title="Rejection ratio"
                value={prop('rejectionRate', data)}
                percent={0}
                tooltip="Rejected orders = (Rejected orders/total orders for that day)"
              />
            </TopInformationWrapper>
          </>
        )}
      </Grid>
    </Card>
  )
}

RetailerGeneral.propTypes = {
  data: PropTypes.object,
  isLoading: PropTypes.bool.isRequired
}

export default RetailerGeneral
