import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'

import VerticalAlignment from '~/components/VerticalAlignment'
import TextOverflow from '~/components/TextOverflow'
import Subtext from '~/components/Subtext'

export default function StockAgingRangeRow ({ children, isTotal }) {
  if (isTotal) {
    return (
      <VerticalAlignment
        primary={
          <TextOverflow selfTooltip={true} lines={1}>
            <Box sx={{ display: 'flex' }}>
              {children}
            </Box>
          </TextOverflow>
        }
        secondary={<Subtext lines={1}>Total</Subtext>}
      />
    )
  } else {
    return (
      <Box sx={{ display: 'flex' }}>
        {children}
      </Box>
    )
  }
}

StockAgingRangeRow.propTypes = {
  children: PropTypes.node.isRequired,
  isTotal: PropTypes.bool.isRequired
}
