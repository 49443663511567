import { sprintf } from 'sprintf-js'
import { useCreate, useDelete, useDetail, useList, useUpdate } from 'storfox-api-hooks'

import * as API from '~/constants/api'

export const useTaxList = () => {
  return useList(API.TAX_LIST)
}
export const useTaxCreate = () => {
  return useCreate(API.TAX_CREATE)
}

export const useTaxUpdate = guid => {
  const api = sprintf(API.TAX_UPDATE, guid)
  return useUpdate(api)
}

export const useTaxDetail = guid => {
  const api = sprintf(API.TAX_DETAIL, guid)
  return useDetail(api)
}
export const useTaxRemove = guid => {
  const api = sprintf(API.TAX_REMOVE, guid)
  return useDelete(api)
}
export const useTaxesDelete = () => {
  return useDelete(API.TAXES_DELETE)
}
