import { pick, prop } from 'ramda'
import { DEFAULT_SEARCH_PARAMS } from 'storfox-route-hooks'

import { escapeAtob } from '~/utils'

export const ReorderFilterSerializer = params => {
  const defaultParams = pick(DEFAULT_SEARCH_PARAMS, params)
  const categoryIds = escapeAtob(prop('categories', params))
  const brandIds = escapeAtob(prop('brands', params))
  const tags = prop('tags', params)
  const comparisonWarehouseId = escapeAtob(prop('comparisonWarehouse', params))
  const suppliersIds = escapeAtob(prop('suppliers', params))
  const warehouseId = prop('warehouse', params)

  return {
    ...defaultParams,
    categoryIds,
    brandIds,
    tags,
    comparisonWarehouseId,
    suppliersIds,
    warehouseId
  }
}
