import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { pathOr } from 'ramda'
import { useSnackbar } from 'storfox-snackbar'
import { useTranslation } from 'react-i18next'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'

import { BrandCreateForm } from '../components/BrandCreate'
import { useBrandDetail, useBrandUpdate } from '../hooks'
import { BrandUpdateSerializer } from '../serializers'

function BrandUpdateContainer () {
  const { t } = useTranslation()
  const { id } = useParams()
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const brandDetail = useBrandDetail(id)
  const brandUpdate = useBrandUpdate(id)

  const message = t('Successfully updated')
  const handleSubmit = formValues =>
    brandUpdate.update(BrandUpdateSerializer(formValues))
      .then(() => snackbar({ message }))
      .then(() => navigate(ROUTES.BRAND_LIST_PATH))

  const title = pathOr('Brands', ['detail', 'name'], brandDetail)
  const pageLoading = brandDetail.isLoading || brandUpdate.isLoading

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      isLoading={pageLoading}
      activeNav={NAV.BRANDS}
      breadcrumbs={breadcrumbs}
    >
      <BrandCreateForm
        title={title}
        pageTitleLoading={brandDetail.isLoading}
        initialValues={brandDetail.detail}
        onSubmit={handleSubmit}
        isLoading={brandUpdate.isLoading}
      />
    </DashboardLayout>
  )
}

export default BrandUpdateContainer
