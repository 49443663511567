import { sprintf } from 'sprintf-js'
import { useAllSearchParams } from 'storfox-route-hooks'
import { useCreate, useDetail, useList, useRequest, useUpdate, useLimitedCountList } from 'storfox-api-hooks'

import { queryToParams } from '~/utils'
import * as API from '~/constants/api'
import { SHIPPING } from '~/constants/locationTypes'

import { PackingFilterSerializer } from '../serializers'

export const usePackingList = () => {
  const searchParams = useAllSearchParams()
  const params = queryToParams(PackingFilterSerializer, searchParams)
  const { getList, ...state } = useLimitedCountList(API.PACKING_LIST, params)

  return { ...state, getList: () => getList({ ...searchParams, ...params }) }
}

export const usePackingProcess = (id) => {
  const url = sprintf(API.PACKING_PROCESS, id)
  return useUpdate(url)
}

export const usePackingProcessBulk = () => {
  return useUpdate(API.PACKING_PROCESS_BULK)
}

export const usePackingDetail = (id, autoSend = true) => {
  const url = sprintf(API.PACKING_DETAIL, id)
  return useDetail(url, {}, autoSend)
}

export const useContainerCreate = () => {
  return useCreate(API.CONTAINER_CREATE)
}

export const usePackingComplete = () => {
  return useUpdate(API.PACKING_COMPLETE)
}

export const usePackingSave = id => {
  const url = sprintf(API.PACKING_UPDATE, id)
  return useUpdate(url)
}

export const usePackingContainerTypeList = () => {
  return useList(API.CONTAINER_TYPE_LIST, { limit: 1000, operation: 'packing' })
}

export const usePackingSlipPdf = (guid) => {
  const url = sprintf(API.PACKING_SLIP_PDF_REPORT, guid)
  const { create, ...state } = useCreate(url)
  return { ...state, generate: create }
}

export const usePackingSkip = id => {
  const url = sprintf(API.PACKING_SKIP_PROCESS, id)
  const { update, ...state } = useUpdate(url)
  return { ...state, skip: update }
}

export const usePackingHistory = (guid, autoSend) => {
  const url = sprintf(API.PACKING_HISTORY, guid)
  const searchParams = useAllSearchParams()

  return useList(url, searchParams, autoSend)
}

export const useShipmentLocationList = warehouseId => {
  const params = { warehouseId, locationType: SHIPPING, status: 'active' }

  return useList(API.LOCATION_LIST, params, warehouseId)
}

export const usePackingContainerBarcodeScan = () => {
  const request = useRequest()
  return {
    scan: (containerNumber, params) => {
      const url = sprintf(API.PACKING_BY_CONTAINER_NUMBER, containerNumber)
      return request.get(url, params)
    }
  }
}

export const usePackingJobCreate = () => {
  return useCreate(API.PACKING_JOB_CREATE)
}

export const usePackingOptimalContainerList = id => {
  const url = sprintf(API.PACKING_OPTIMAL_CONTAINER_LIST, id)

  return useList(url)
}
