import React, { useState } from 'react'
import { sprintf } from 'sprintf-js'
import PropTypes from 'prop-types'
import { useField } from 'react-final-form'
import { path, prop } from 'ramda'
import { usePrevious } from 'storfox-tools'
import { useDeepCompareEffect } from 'storfox-api-hooks'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import { styled } from '@mui/material'

import SearchField from '~/components/Fields/SearchField'

const getOptionValue = value => {
  const { id, name, code, price } = value
  return { id, name, code, price }
}

const CircularProgressStyled = styled(CircularProgress)({
  position: 'absolute',
  right: 30,
  top: 0,
  bottom: 0,
  margin: 'auto'
})

function ShippingServiceField ({ guid, integrationName, api, onIntegrationCheck, ...props }) {
  const [disabled, setDisabled] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const serviceField = useField('service')

  const integrationField = useField(integrationName)
  const integrationValue = integrationField.input.value
  const integrationGuid = prop('guid', integrationValue)

  const pickupAddressField = useField('pickupAddress')
  const pickupAddressCountryName = path(['country', 'name'], pickupAddressField.input.value)
  const pickupAddressCityName = prop('city', pickupAddressField.input.value)

  const prevIntegrationGuid = usePrevious(integrationGuid)

  const url = sprintf(api, integrationGuid)

  useDeepCompareEffect(() => {
    const integrationChanged = (
      integrationGuid &&
      integrationGuid !== prevIntegrationGuid &&
      integrationField.meta.dirty
    )

    if (integrationChanged) {
      serviceField.input.onChange('')
      setIsLoading(true)
      setDisabled(true)
      const fromAddress = {
        country: pickupAddressCountryName,
        city: pickupAddressCityName
      }
      onIntegrationCheck({ fromAddress, integrationGuid, guid })
        .then(() => setDisabled(false))
        .catch(() => setDisabled(true))
        .finally(() => setIsLoading(false))
    }
  }, [integrationGuid])

  return (
    <Box position="relative">
      <SearchField
        api={url}
        name="service"
        label="Service"
        disabled={disabled || !integrationGuid}
        getOptionValue={getOptionValue}
        {...props}
      />
      {isLoading && (
        <CircularProgressStyled color="inherit" size={20} />
      )}
    </Box>
  )
}

ShippingServiceField.propTypes = {
  guid: PropTypes.string,
  api: PropTypes.string.isRequired,
  integrationName: PropTypes.string.isRequired,
  onIntegrationCheck: PropTypes.func.isRequired
}

export default ShippingServiceField
