import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import { Filter } from 'storfox-filter'
import { map, pick, pipe, prop, propOr } from 'ramda'
import Typography from '@mui/material/Typography'
import { DateTimeField } from 'storfox-form-fields'
import { useTranslation } from 'react-i18next'

import { escapeAtob, unescapeBtoa } from '~/utils'
import Accordion from '~/components/Accordion'
import WarehouseMultiSelectField from '~/components/Fields/WarehouseMultiSelectField'
import { getDateTimeGMTDifference, getDateTimeGMTUser } from '~/utils/date'

import CompanyMultiSelectField from './Fields/CompanyMultiSelectField'

import { FILTER_NAME as name } from '../../constants/OrderUnits'
import OrderItemField from '../../components/OrderUnits/Fields/OrderItemField'

const getCompanyGuids = pipe(
  prop('companies'),
  map(pick(['guid', 'name']))
)

const getWarehouseGuids = pipe(
  prop('warehouses'),
  map(pick(['guid', 'name']))
)

export const toParams = values => {
  const warehouseList = propOr([], 'warehouses', values)
  const warehouses = warehouseList.length ? unescapeBtoa(getWarehouseGuids(values)) : null

  const toObjectGuids = unescapeBtoa(prop('toObjectGuids', values))

  const companyList = propOr([], 'company', values)
  const companies = companyList.length ? unescapeBtoa(getCompanyGuids(values)) : null

  const createdAtStart = getDateTimeGMTDifference(prop('createdAtStart', values))
  const createdAtEnd = getDateTimeGMTDifference(prop('createdAtEnd', values))

  return {
    warehouses,
    companies,
    createdAtStart,
    createdAtEnd,
    toObjectGuids
  }
}

export const toValues = params => {
  const warehouses = escapeAtob(prop('warehouses', params))
  const companies = escapeAtob(prop('companies', params))
  const toObjectGuids = escapeAtob(prop('toObjectGuids', params))

  const createdAtStart = getDateTimeGMTUser(prop('createdAtStart', params))
  const createdAtEnd = getDateTimeGMTUser(prop('createdAtEnd', params))

  return {
    warehouses,
    companies,
    createdAtStart,
    createdAtEnd,
    toObjectGuids
  }
}

export const transformer = { toParams, toValues }

export const orderUnitsFilterOptions = {
  name,
  transformer
}

function OrderUnitsFilterForm (props) {
  const { t } = useTranslation()
  return (
    <Filter {...props}>
      <Accordion title="General" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <WarehouseMultiSelectField />
          </Grid>
          <Grid item={true} xs={12}>
            <CompanyMultiSelectField />
          </Grid>
          <Grid item={true} xs={12}>
            <OrderItemField />
          </Grid>
        </Grid>
      </Accordion>
      <Accordion title="Date">
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <Typography variant="body2" gutterBottom={true}>
              {t('Created')}:
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <DateTimeField name="createdAtStart" label="Start" />
              </Grid>
              <Grid item={true} xs={12}>
                <DateTimeField name="createdAtEnd" label="End" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Accordion>
    </Filter>
  )
}

OrderUnitsFilterForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default OrderUnitsFilterForm
