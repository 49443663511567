import React from 'react'
import { Filter } from 'storfox-filter'
import Grid from '@mui/material/Grid'
import { prop } from 'ramda'
import { SwitchField } from 'storfox-form-fields'

import { arrayToParams, escapeAtob } from '~/utils'
import Accordion from '~/components/Accordion'
import LocationWarehouseMultiSelectField from '~/components/Fields/LocationWarehouseMultiSelectField'
import VariantsMultiSelectField from '~/components/Fields/VariantsMultiSelectField'
import * as API from '~/constants/api'
import AsyncMultiSelectField from '~/components/Fields/AsyncMultiSelectField'
import WarehouseMultiSelectField from '~/components/Fields/WarehouseMultiSelectField'

import UnitOrderField from './UnitOrderField'
import UnitReservedTypeField from './UnitReservedTypeField'

import { FILTER_NAME as name } from '../../constants/Unit'

export const toParams = values => {
  const isAvailable = prop('isAvailable', values)
  const isArchived = prop('isArchived', values)
  const locations = arrayToParams('locations', values)
  const warehouses = arrayToParams('warehouses', values)
  const variants = arrayToParams('variants', values)
  const companies = arrayToParams('companies', values)
  const objects = arrayToParams('objects', values)
  const toObjectTypes = arrayToParams('toObjectTypes', values)

  return {
    isAvailable,
    isArchived,
    locations,
    companies,
    warehouses,
    variants,
    objects,
    toObjectTypes
  }
}

export const toValues = params => {
  const locations = escapeAtob(prop('locations', params))
  const isAvailable = prop('isAvailable', params)
  const isArchived = prop('isArchived', params)
  const companies = escapeAtob(prop('companies', params))
  const warehouses = escapeAtob(prop('warehouses', params))
  const variants = escapeAtob(prop('variants', params))
  const objects = escapeAtob(prop('objects', params))
  const toObjectTypes = escapeAtob(prop('toObjectTypes', params))

  return {
    isAvailable,
    isArchived,
    locations,
    companies,
    warehouses,
    variants,
    objects,
    toObjectTypes
  }
}

export const transformer = { toParams, toValues }

export const inventoryFilterOptions = {
  name,
  transformer
}

function UnitFilterForm (props) {
  return (
    <Filter {...props}>
      <Accordion title="General" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <WarehouseMultiSelectField name="warehouses" api={API.WAREHOUSE_LIST} />
          </Grid>
          <Grid item={true} xs={12}>
            <LocationWarehouseMultiSelectField warehousesName="warehouses" name="locations" />
          </Grid>
          <Grid item={true} xs={12}>
            <AsyncMultiSelectField
              api={API.UNIT_COMPANY_LIST}
              label="Companies"
              name="companies"
            />
          </Grid>
          <Grid item={true} xs={12}>
            <VariantsMultiSelectField label="Variants" name="variants" />
          </Grid>
          <Grid item={true} xs={12}>
            <UnitReservedTypeField />
          </Grid>
          <Grid item={true} xs={12}>
            <UnitOrderField />
          </Grid>
          <Grid item={true} xs={12}>
            <SwitchField
              data-cy="isAvailable"
              name="isAvailable"
            />
            Available
          </Grid>
          <Grid item={true} xs={12}>
            <SwitchField
              data-cy="isArchived"
              name="isArchived"
            />
            Archived
          </Grid>
        </Grid>
      </Accordion>
    </Filter>
  )
}

export default UnitFilterForm
