import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import { Filter } from 'storfox-filter'
import { prop } from 'ramda'

import { arrayToParams, escapeAtob } from '~/utils'
import Accordion from '~/components/Accordion'
import AsyncMultiSelectField from '~/components/Fields/AsyncMultiSelectField'
import * as API from '~/constants/api'
import WarehouseMultiSelectField from '~/components/Fields/WarehouseMultiSelectField'

import PurchaseOrderStatusField from './Field/PurchaseOrderStatusField'

import { FILTER_NAME as name } from '../../constants/Purchase'

export const toParams = values => {
  const status = prop('status', values)
  const companies = arrayToParams('companies', values)
  const warehouses = arrayToParams('warehouses', values)

  return {
    status,
    companies,
    warehouses
  }
}

export const toValues = params => {
  const status = prop('status', params)
  const companies = escapeAtob(prop('companies', params))
  const warehouses = escapeAtob(prop('warehouses', params))

  return {
    status,
    companies,
    warehouses
  }
}
export const transformer = { toParams, toValues }

export const purchaseFilterOptions = {
  name,
  transformer
}

function PurchaseFilterForm ({ companyDisabled, ...props }) {
  return (
    <Filter {...props}>
      <Accordion title="General" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={6}>
            <PurchaseOrderStatusField />
          </Grid>
          <Grid item={true} xs={12}>
            <AsyncMultiSelectField
              api={API.UNIT_COMPANY_LIST}
              label="Companies"
              name="companies"
            />
          </Grid>
          <Grid item={true} xs={12}>
            <WarehouseMultiSelectField name="warehouses" />
          </Grid>
        </Grid>
      </Accordion>
    </Filter>
  )
}

PurchaseFilterForm.propTypes = {
  companyDisabled: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default PurchaseFilterForm
