import React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { path } from 'ramda'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import { WAREHOUSE_TABS } from '~/constants/tabs'

import { useAreaDetail, useAreaRemove } from '../hooks'
import AreaDetail from '../components/AreaDetail'

function AreaUpdateContainer () {
  const navigate = useNavigate()
  const { guid } = useParams()
  const areaDetail = useAreaDetail(guid)
  const areaRemove = useAreaRemove(guid)

  const warehouseGuid = path(['warehouse', 'guid'], areaDetail.detail)
  const warehouseName = path(['warehouse', 'name'], areaDetail.detail)

  const handleRemove = () =>
    areaRemove.delete()
      .then(() => {
        const params = { guid: warehouseGuid, tab: WAREHOUSE_TABS.AREA }
        const areaListPath = generatePath(ROUTES.WAREHOUSE_DETAIL_PATH, params)

        return navigate(areaListPath, { replace: true })
      })

  const pageLoading = areaDetail.isLoading

  const name = path(['detail', 'name'], areaDetail)
  const title = areaDetail.isLoading ? 'Areas' : name

  const breadcrumbs = {
    title,
    params: [
      { guid: warehouseGuid, tab: WAREHOUSE_TABS.AREA, title: warehouseName }
    ]
  }

  return (
    <DashboardLayout
      title={title}
      isLoading={pageLoading}
      activeNav={NAV.WAREHOUSE}
      breadcrumbs={breadcrumbs}
    >
      <AreaDetail
        pageTitle={title}
        pageTitleLoading={areaDetail.isLoading}
        detail={areaDetail.detail}
        isLoading={areaDetail.isLoading}
        onRemove={handleRemove}
      />
    </DashboardLayout>
  )
}

export default AreaUpdateContainer
