import React from 'react'
import PropTypes from 'prop-types'
import { StickyContainer } from 'react-sticky'

function Container ({ children }) {
  return (
    <StickyContainer>
      {children}
    </StickyContainer>
  )
}

Container.propTypes = {
  children: PropTypes.any.isRequired
}

export default Container
