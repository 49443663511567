import { useState } from 'react'
import { filter, join, map, pathOr, pipe, pluck, prop } from 'ramda'

export function useSearchListItems (results) {
  const [search, setSearch] = useState('')

  const handleSearch = event => setSearch(event.target.value)

  const filteredResults = pipe(
    map(listing => {
      const options = pipe(
        pathOr([], ['options']),
        pluck('option'),
        join(' ')
      )(listing)
      const sku = prop('sku', listing)
      const barcode = prop('barcode', listing)
      const fullName = `${listing.name} ${options} ${sku} ${barcode}`.toLowerCase()
      return { ...listing, fullName }
    }),
    filter(item => item.fullName.search(search.toLowerCase()) !== -1)
  )(results)

  return { search, handleSearch, filteredResults }
}
