export default {
  styleOverrides: {
    elevation1: {
      boxShadow: '0px 0px 0px 1px rgba(63,63,68,0.05), 0px 1px 3px 0px rgba(63,63,68,0.15)',
    },
    elevation4: {
      boxShadow: 'none'
    }
  },
  defaultProps: {
    variant: 'outlined'
  }
}
